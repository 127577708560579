import { Injectable } from "@angular/core";
import { ActividadCliente } from "../enums/ActividadCliente";
import { AgrupacionRemitos } from "../enums/agrupacionRemitos";
import { CondicionIIBB } from "../enums/condicionIIBB";
import { CondicionIVA } from "../enums/condicionIVA";
import { TipoCuenta } from "../enums/tipoCuenta";
import { TipoDocumento } from "../enums/tipoDocumento";
import { TipoEntregaFactura } from "../enums/tipoEntregaFactura";
import { TipoMoneda } from "../enums/tipoMoneda";
import { Actividad } from "../models/cliente/Actividad";
import { Ubicacion } from 'src/app/shared/models/cliente/Ubicacion';
import { Cuenta } from 'src/app/shared/models/cliente/Cuenta';
import { Responsable } from 'src/app/shared/models/cliente/Responsable';
import { Cliente } from "../models/cliente/Cliente";
import { formatDate } from '@angular/common';
import { EstadoModificacion } from "../enums/EstadoModificacion";
import { CBU } from "../models/cliente/CBU";
import { CodigoUbicacion } from "../enums/codigoUbicacion";

@Injectable({
  providedIn: 'root'
})
export class UtilClientes {
  obtenerDescripcionTipoDocumento(tipoDoc) {
    if (tipoDoc == TipoDocumento.DNI) {
      return "DNI";
    } else if (tipoDoc == TipoDocumento.CUIL) {
      return "CUIL";
    } else if (tipoDoc == TipoDocumento.CUIT) {
      return "CUIT";
    } else if (tipoDoc == TipoDocumento.Extranjero) {
      return "EXTRANJERO";
    } else {
      return "-";
    }
  }

  obtenerDescripcionCondicionIva(condIva) {
    if (condIva == CondicionIVA.ConsumidorFinal) {
      return "Consumidor Final";
    } else if (condIva == CondicionIVA.ResponsableInscripto) {
      return "Responsable Inscripto";
    } else {
      return "-";
    }
  }

  obtenerDescripcionCondicionVenta(tipoCuenta) {
    if (tipoCuenta == TipoCuenta.PrevioPago) {
      return "Previo Pago";
    } else if (tipoCuenta == TipoCuenta.CuentaCorriente) {
      return "Cuenta Corriente";
    } else if (tipoCuenta == TipoCuenta.Donacion) {
      return "Donación";
    } else if (tipoCuenta == TipoCuenta.ConsumoInterno) {
      return "Consumo Interno";
    } else {
      return "Contado";
    }
  }

  obtenerDescripcionMoneda(tipoMoneda) {
    if (tipoMoneda == TipoMoneda.PES) {
      return "Pesos $";
    } else if (tipoMoneda == TipoMoneda.USD) {
      return "Dolares USD";
    } else {
      return "-";
    }
  }

  obtenerDescripcionTipoEntregaFactura(tipoEntregaFactura) {
    if (tipoEntregaFactura == TipoEntregaFactura.Fisico) {
      return "Fisico";
    } else if (tipoEntregaFactura == TipoEntregaFactura.Email) {
      return "Mail";
    } else if (tipoEntregaFactura == TipoEntregaFactura.Ambos) {
      return "Mail y fisico";
    } else {
      return "-";
    }
  }

  obtenerDescripcionAgrupacionRemitos(tipoAgrupacionRemitos) {
    if (tipoAgrupacionRemitos == AgrupacionRemitos.Aeroplanta) {
      return "Aeroplanta";
    } else if (tipoAgrupacionRemitos == AgrupacionRemitos.Provincia) {
      return "Provincia";
    } else if (tipoAgrupacionRemitos == AgrupacionRemitos.DireccionEntrega) {
      return "Direccion de entrega";
    } else if (tipoAgrupacionRemitos == AgrupacionRemitos.Remito) {
      return "Remitos";
    } else {
      return "-";
    }
  }

  obtenerDescripcionActividadPad(actividadPad) {
    return ActividadCliente[actividadPad];
  }

  obtenerDescripcionCondicionIIBB(condicionIIBB) {
    return CondicionIIBB[condicionIIBB];
  }

  obtenerDescripcionEstadoModificacion(estado) {
    if (estado == EstadoModificacion.Aprobado) {
      return "APROBADO";
    } else if (estado == EstadoModificacion.Rechazado) {
      return "RECHAZADO";
    } else if (estado == EstadoModificacion.Pendiente) {
      return "PENDIENTE";
    }else{
      return "-";
    }
  }

  cambioResponsable(viejo: Responsable, nuevo: Responsable): boolean {

    if (viejo == null || viejo.nombreCompleto != nuevo.nombreCompleto) {
      return true;
    }

    if (viejo == null || viejo.email != nuevo.email) {
      return true;
    }

    if (viejo == null || viejo.telefono != nuevo.telefono) {
      return true;
    }

    return false;
  }

  cambioCbu(viejo: CBU, nuevo: CBU): boolean {
    if(viejo.cbu != nuevo.cbu){
      return true;
    }
    if(viejo.enUso != nuevo.enUso){
      return true;
    }
    if (viejo == null && nuevo !=null){
      return true;
    }
    return false;
  }

  cambioDireccionFiscal(viejo: Ubicacion, nuevo: Ubicacion): boolean {
    if(viejo?.ciudad === null){
      return true
    }
    if (viejo?.ciudad.codigoPostal != nuevo.ciudad.codigoPostal) {
      return true;
    }
    if (viejo?.ciudad.provincia != nuevo.ciudad.provincia) {
      return true;
    }
    if (viejo?.calle != nuevo.calle) {
      return true;
    }

    if (viejo?.numero != nuevo.numero) {
      return true;
    }

    if (viejo?.piso != nuevo.piso) {
      return true;
    }

    if (viejo?.departamento != nuevo.departamento) {
      return true;
    }

    return false;
  }

  cambioPeriodoFacturacion(viejo, nuevo) {
    if (viejo == null) {
      return true;
    }

    if (nuevo == null) {
      return true;
    }

    if (viejo.id != nuevo.id) {
      return true;
    }

    return false;
  }

  cambioCuentaSAP(viejo, nuevo) {
    if (viejo == null && nuevo != null) {
      return true;
    }

    if (nuevo == null && viejo != null) {
      return true;
    }

    if (viejo != nuevo) {
      return true;
    }

    return false;
  }

  checkAmbosNulos(viejo, nuevo) {
    if (viejo == null && nuevo == null) {
      return true;
    }

    return false;
  }

  checkAmbosVacios(viejo, nuevo) {
    if (viejo == null && nuevo === null) {
      return true;
    }

    if (viejo != null && nuevo != null && viejo.length == 0 && nuevo.length == 0) {
      return true;
    }

    if (viejo == null && nuevo.lenght == 0) {
      return true;
    }

    if (nuevo == null && viejo.lenght == 0) {
      return true;
    }

    return false;
  }

  checkCBUEnUso(c:CBU): string{
    var sEnUso :string = "";
    sEnUso = c.enUso ? " - En Uso" : " - No usado";
    return sEnUso
  }

  compararClientes(viejo: Cliente, nuevo: Cliente){
    var modificaciones: { valorViejo: string, valorNuevo: string } [] = [];    
    
    if(viejo.razonSocial != nuevo.razonSocial){
      var valorViejo = "Razon Social: " + viejo.razonSocial;
      var valorNuevo = "Razon Social: " + nuevo.razonSocial;
      modificaciones.push({valorViejo: valorViejo, valorNuevo: valorNuevo})
    }
    
    if(viejo.tipoDocumento != nuevo.tipoDocumento){
      var valorViejo = "Tipo Doc.: " + this.obtenerDescripcionTipoDocumento(viejo.tipoDocumento);
      var valorNuevo = "Tipo Doc.: " + this.obtenerDescripcionTipoDocumento(nuevo.tipoDocumento);
      modificaciones.push({valorViejo: valorViejo, valorNuevo: valorNuevo})
    }

    if(viejo.numeroDocumento != nuevo.numeroDocumento){
      var valorViejo = "Nro. Doc.: " + viejo.numeroDocumento;
      var valorNuevo = "Nro. Doc.: " + nuevo.numeroDocumento;
      modificaciones.push({valorViejo: valorViejo, valorNuevo: valorNuevo})
    }

    if( (! this.checkAmbosNulos(viejo.responsable, nuevo.responsable)) && this.cambioResponsable(viejo.responsable, nuevo.responsable)){
      var valorViejo = "Responsable: " + (viejo.responsable == null ? "-" : viejo.responsable.nombreCompleto + " / " + viejo.responsable.email + " / " + viejo.responsable.telefono) ;
      var valorNuevo = "Responsable: " + nuevo.responsable.nombreCompleto + " / " + nuevo.responsable.email + " / " + nuevo.responsable.telefono ;
      modificaciones.push({valorViejo: valorViejo, valorNuevo: valorNuevo})
    }

    if( (! this.checkAmbosNulos(viejo.condicionIva, nuevo.condicionIva)) && viejo.condicionIva.id != nuevo.condicionIva.id){
      var valorViejo = "Condición IVA: " + viejo.condicionIva.descripcion;
      var valorNuevo = "Condición IVA: " + nuevo.condicionIva.descripcion;
      modificaciones.push({valorViejo: valorViejo, valorNuevo: valorNuevo})
    }

    if(viejo.ctaSap != nuevo.ctaSap){
      var valorViejo = "Nro. cuenta SAP.: " + (viejo.ctaSap ? viejo.ctaSap : "-");
      var valorNuevo = "Nro. cuenta SAP.: " + nuevo.ctaSap;
      modificaciones.push({valorViejo: valorViejo, valorNuevo: valorNuevo})
    }

    if(viejo.ctA_CTC != nuevo.ctA_CTC){
      var valorViejo = "Nro. cuenta CTC.: " + (viejo.ctA_CTC ? viejo.ctA_CTC : "-");
      var valorNuevo = "Nro. cuenta CTC.: " + nuevo.ctA_CTC;
      modificaciones.push({valorViejo: valorViejo, valorNuevo: valorNuevo})
    }

    if( (! this.checkAmbosNulos(viejo.subArea, nuevo.subArea)) && viejo.subArea?.area?.id != nuevo.subArea?.area?.id){
      var valorViejo = "Área: " + (viejo.subArea?.area?.descripcion != null ? viejo.subArea.area.descripcion : "-");
      var valorNuevo = "Área: " + nuevo.subArea?.area?.descripcion;
      modificaciones.push({valorViejo: valorViejo, valorNuevo: valorNuevo})
    }

    if( (! this.checkAmbosNulos(viejo.subArea, nuevo.subArea)) && viejo.subArea?.id != nuevo.subArea?.id){
      var valorViejo = "Segmento: " + (viejo.subArea?.descripcion != null ? viejo.subArea.descripcion : "-");
      var valorNuevo = "Segmento: " + nuevo.subArea?.descripcion;
      modificaciones.push({valorViejo: valorViejo, valorNuevo: valorNuevo})
    }

    if( (! this.checkAmbosNulos(viejo.direccionFiscal, nuevo.direccionFiscal)) && this.cambioDireccionFiscal(viejo.direccionFiscal, nuevo.direccionFiscal)){
      var valorViejo = "Dirección fiscal: " + (viejo.direccionFiscal?.calle != null ? viejo.direccionFiscal?.calle : "") + " " + (viejo.direccionFiscal?.numero != null ? viejo.direccionFiscal?.numero : "")  + " " + (viejo.direccionFiscal?.piso != null ? viejo.direccionFiscal?.piso : "") + " " + (viejo.direccionFiscal?.departamento != null ? viejo.direccionFiscal?.departamento : "") + ", " + (viejo.direccionFiscal?.ciudad === null ? "" : viejo.direccionFiscal?.ciudad.nombre) + " (" + ((viejo.direccionFiscal?.ciudad === null) ? "" : viejo.direccionFiscal?.ciudad?.codigoPostal === null ? "" : viejo.direccionFiscal?.ciudad.codigoPostal ) + ")" ;
      var valorNuevo = "Dirección fiscal: " + nuevo.direccionFiscal.calle + " " + nuevo.direccionFiscal.numero  + " " + nuevo.direccionFiscal.piso + " " + nuevo.direccionFiscal.departamento + ", " + nuevo.direccionFiscal.ciudad.nombre + " (" + nuevo.direccionFiscal.ciudad.codigoPostal + ")";
      modificaciones.push({valorViejo: valorViejo, valorNuevo: valorNuevo})
    }

    if(viejo.actividadPAD != nuevo.actividadPAD){
      var valorViejo = "Actividad PAD: " + this.obtenerDescripcionActividadPad(viejo.actividadPAD);
      var valorNuevo = "Actividad PAD: " + this.obtenerDescripcionActividadPad(nuevo.actividadPAD);
      modificaciones.push({valorViejo: valorViejo, valorNuevo: valorNuevo})
    }
/*
    if( (! this.checkAmbosNulos(viejo.grupoCliente, nuevo.grupoCliente)) && viejo.grupoCliente.id != nuevo.grupoCliente.id){
      var valorViejo = "Segmento: " + viejo.grupoCliente.descripcion;
      var valorNuevo = "Segmento: " + nuevo.grupoCliente.descripcion;
      modificaciones.push({valorViejo: valorViejo, valorNuevo: valorNuevo})
    }
*/
    if(viejo.percepcionIVADif != nuevo.percepcionIVADif){
      var valorViejo = "Percep. IVA dif.: " + viejo.percepcionIVADif;
      var valorNuevo = "Percep. IVA dif.: " + nuevo.percepcionIVADif;
      modificaciones.push({valorViejo: valorViejo, valorNuevo: valorNuevo})
    }

    if(viejo.fechaPercepcionIVADif != nuevo.fechaPercepcionIVADif){
      var valorViejo = "Fecha Percep. IVA dif.: " + formatDate(viejo.fechaPercepcionIVADif, 'yyyy/MM/dd', 'en');
      var valorNuevo = "Fecha Percep. IVA dif.: " + formatDate(nuevo.fechaPercepcionIVADif, 'yyyy/MM/dd', 'en');
      modificaciones.push({valorViejo: valorViejo, valorNuevo: valorNuevo})
    }

    if(viejo.clienteObservado != nuevo.clienteObservado){
      var valorViejo = "Cliente observado: " + (viejo.clienteObservado ? "Si" : "No");
      var valorNuevo = "Cliente observado: " + (nuevo.clienteObservado ? "Si" : "No");
      modificaciones.push({valorViejo: valorViejo, valorNuevo: valorNuevo})
    }

    if(viejo.activo != nuevo.activo){
      var valorViejo = "Activo: " + (viejo.activo ? "Si" : "No");
      var valorNuevo = "Activo: " + (nuevo.activo ? "Si" : "No");
      modificaciones.push({valorViejo: valorViejo, valorNuevo: valorNuevo})
    }

    if( (! this.checkAmbosNulos(viejo.grupoCliente, nuevo.grupoCliente)) && (viejo.grupoCliente?.codigo != nuevo.grupoCliente?.codigo)){
      var valorViejo = "Agrupacion Serc: " + (viejo.grupoCliente != null ? viejo.grupoCliente.descripcion : "null");
      var valorNuevo = "Agrupacion Serc: " + (nuevo.grupoCliente != null ? nuevo.grupoCliente.descripcion : "null");
      modificaciones.push({valorViejo: valorViejo, valorNuevo: valorNuevo})
    }
    // Direcciones entrega
    if((! this.checkAmbosNulos(viejo.direccionesEntrega, nuevo.direccionesEntrega)) && (! this.checkAmbosVacios(viejo.direccionesEntrega, nuevo.direccionesEntrega)) ){

      var valorViejo = "Dir. Entrega: ";
      if(viejo.direccionesEntrega == null || viejo.direccionesEntrega.length == 0){
        valorViejo = "Sin direcciones entrega";
      }else{
        for(let dirEntrega of viejo.direccionesEntrega){
          valorViejo += this.descripcionCodigoUbicacion(dirEntrega.codigoUbicacion) + " " + dirEntrega.calle + " " + dirEntrega.numero + " " + dirEntrega.piso + " " + dirEntrega.departamento +", " + dirEntrega.ciudad.nombre + " (" + dirEntrega.ciudad.codigoPostal + ")<br/>";
        }          
      }   

      var valorNuevo = "Dir. Entrega: ";
      if(nuevo.direccionesEntrega == null || nuevo.direccionesEntrega.length == 0){
        valorNuevo = "Sin direcciones entrega";
      }else{
        for(let dirEntrega of nuevo.direccionesEntrega){
          valorNuevo += this.descripcionCodigoUbicacion(dirEntrega.codigoUbicacion) + " " + dirEntrega.calle + " " + dirEntrega.numero + " " + dirEntrega.piso + " " + dirEntrega.departamento + ", " + dirEntrega.ciudad.nombre +" (" + dirEntrega.ciudad.codigoPostal + ")<br/>";
        }          
      }   

      modificaciones.push({valorViejo: valorViejo, valorNuevo: valorNuevo})
    }

    // Comerciales
    if( (! this.checkAmbosNulos(viejo.comerciales, nuevo.comerciales)) && (! this.checkAmbosVacios(viejo.comerciales, nuevo.comerciales)) ){
      var valorViejo = "";
      if(viejo.comerciales == null || viejo.comerciales.length == 0){
        valorViejo = "Sin comerciales";
      }else{
        for(let c of viejo.comerciales){
          valorViejo += c + ". ";
        }          
      }   

      var valorNuevo = "";
      if(nuevo.comerciales == null || nuevo.comerciales.length == 0){
        valorNuevo = "Sin comerciales";
      }else{
        for(let c of nuevo.comerciales){
          valorNuevo += c + ". ";
        }          
      }   

      modificaciones.push({valorViejo: valorViejo, valorNuevo: valorNuevo})
    }

     // IIBB
    if((! this.checkAmbosNulos(viejo.configuracionProvincialImp, nuevo.configuracionProvincialImp)) && (! this.checkAmbosVacios(viejo.configuracionProvincialImp, nuevo.configuracionProvincialImp)) ){

      var valorViejo = "";
      if(viejo.configuracionProvincialImp == null || viejo.configuracionProvincialImp.length == 0){
        valorViejo = "Sin config. IIBB";
      }else{
        for(let config of viejo.configuracionProvincialImp){
          valorViejo += config.provincia.codigo + ": Num. IIBB: " + config.numeroIIBB + " - " + this.obtenerDescripcionCondicionIIBB(config.condicionIIBB) + " - Alicuota: " + (config.alicuotaDiferencial == null ? " SIN ALICUOTA <br/>" : (config.alicuotaDiferencial.alicuota + " (" + formatDate(config.alicuotaDiferencial.fechaDesde, 'yyyy/MM/dd', 'en') + "-" + formatDate(config.alicuotaDiferencial.fechaHasta, 'yyyy/MM/dd', 'en') +  ") <br/>")) ;
          //valorViejo += config.provincia.codigo + ": Num. IIBB: " + config.numeroIIBB + " - " + this.obtenerDescripcionCondicionIIBB(config.condicionIIBB) + " - Alicuota: " + (config.alicuotaDiferencial == null ? " SIN ALICUOTA <br/>" : (config.alicuotaDiferencial.alicuota + " - (" + formatDate(config.alicuotaDiferencial.fechaDesde, 'yyyy/MM/dd', 'en') + " - " + formatDate(config.alicuotaDiferencial.fechaHasta, 'yyyy/MM/dd', 'en') + ") <br/>")) ;
        }          
      }   

      var valorNuevo = "";
      if(nuevo.configuracionProvincialImp == null || nuevo.configuracionProvincialImp.length == 0){
        valorNuevo = "Sin  config. IIBB";
      }else{
        for(let config of nuevo.configuracionProvincialImp){
          valorNuevo += config.provincia.codigo +  ": Num. IIBB: "  + config.numeroIIBB + " - " + this.obtenerDescripcionCondicionIIBB(config.condicionIIBB) + " - Alicuota: " + (config.alicuotaDiferencial == null ? " SIN ALICUOTA <br/>" : (config.alicuotaDiferencial.alicuota + " (" + formatDate(config.alicuotaDiferencial.fechaDesde, 'yyyy/MM/dd', 'en') + "-" + formatDate(config.alicuotaDiferencial.fechaHasta, 'yyyy/MM/dd', 'en') + ") <br/>")) ;
          //valorNuevo += config.provincia.codigo +  ": Num. IIBB: "  + config.numeroIIBB + " - " + this.obtenerDescripcionCondicionIIBB(config.condicionIIBB) + " - Alicuota: " + (config.alicuotaDiferencial == null ? " SIN ALICUOTA <br/>" : (config.alicuotaDiferencial.alicuota + " - (" + formatDate(config.alicuotaDiferencial.fechaDesde, 'yyyy/MM/dd', 'en') + " - " + formatDate(config.alicuotaDiferencial.fechaHasta, 'yyyy/MM/dd', 'en') + ") <br/>")) ;
        }          
      }   

      modificaciones.push({valorViejo: valorViejo, valorNuevo: valorNuevo});
    }  
    
    // CBU
    viejo.cbUs.sort((a , b) => a.tipoBancoInterlocutor - b.tipoBancoInterlocutor);
    nuevo.cbUs.sort((a , b) => a.tipoBancoInterlocutor - b.tipoBancoInterlocutor);
    if((! this.checkAmbosNulos(viejo.cbUs, nuevo.cbUs)) && (! this.checkAmbosVacios(viejo.cbUs, nuevo.cbUs)) ){
      for (let c = 0; c < nuevo.cbUs.length; c++){
        var valorViejo = "";
        var valorNuevo = "";
        if(viejo.cbUs[c] == null || viejo.cbUs.length == 0){
          valorViejo = "Sin CBU";
          valorNuevo += "CBU: " + nuevo.cbUs[c].cbu + " - Banco interlocutor: " + nuevo.cbUs[c].tipoBancoInterlocutor + this.checkCBUEnUso(nuevo.cbUs[c]);
          modificaciones.push({valorViejo: valorViejo, valorNuevo: valorNuevo});
        }else{
          if(this.cambioCbu(viejo.cbUs[c],nuevo.cbUs[c])){       
            valorViejo += "CBU: " + viejo.cbUs[c].cbu + " - Banco interlocutor: " + viejo.cbUs[c].tipoBancoInterlocutor + this.checkCBUEnUso(viejo.cbUs[c]);     
            valorNuevo += "CBU: " + nuevo.cbUs[c].cbu + " - Banco interlocutor: " + nuevo.cbUs[c].tipoBancoInterlocutor + this.checkCBUEnUso(nuevo.cbUs[c]);
            modificaciones.push({valorViejo: valorViejo, valorNuevo: valorNuevo});
          }
        }
      }
    }

    if(viejo.viaDePago != nuevo.viaDePago){
      var valorViejo="";
      if (viejo.viaDePago == null)
      {
        valorViejo = "Sin Via de Pago";
      }else{
        valorViejo = viejo.viaDePago.descripcion;
      }

      var valorNuevo = "";
      if (nuevo.viaDePago == null)
      {
        valorNuevo = "Sin Via de Pago";
      }else{
        valorNuevo = nuevo.viaDePago.descripcion;
      }

      modificaciones.push({valorViejo: valorViejo, valorNuevo: valorNuevo})
    }

    return modificaciones;
  }

  compararCuentas(cuentaViejo: Cuenta, cuentaNuevo: Cuenta){
    var modificaciones: { valorViejo: string, valorNuevo: string } [] = [];       

    if(cuentaViejo == null || cuentaViejo.ctA_SGC != cuentaNuevo.ctA_SGC){
      var valorViejo = "Nro. Cuenta: " + (cuentaViejo == null ? "-" : cuentaViejo.ctA_SGC);
      var valorNuevo = "Nro. Cuenta: " + cuentaNuevo.ctA_SGC;
      modificaciones.push({valorViejo: valorViejo, valorNuevo: valorNuevo})
    }

    if(cuentaViejo == null || cuentaViejo.descripcion != cuentaNuevo.descripcion){
      var valorViejo = "Identificador: " + (cuentaViejo == null ? "-" : cuentaViejo.descripcion);
      var valorNuevo = "Identificador: "  + cuentaNuevo.descripcion;
      modificaciones.push({valorViejo: valorViejo, valorNuevo: valorNuevo})
    }

    if(cuentaViejo == null || cuentaViejo.condicionPago != cuentaNuevo.condicionPago){
      var valorViejo = "Plazo pago : " + (cuentaViejo?.condicionPago?.codigo == null ? "-" : cuentaViejo.condicionPago.codigo + " - " + cuentaViejo.condicionPago.descripcion);
      var valorNuevo = "Plazo pago : "  + cuentaNuevo.condicionPago.codigo + " - " + cuentaNuevo.condicionPago.descripcion;
      modificaciones.push({valorViejo: valorViejo, valorNuevo: valorNuevo})
    }

    if(cuentaViejo == null || cuentaViejo.tipoCuenta != cuentaNuevo.tipoCuenta){
      var valorViejo = "Condición venta: " + (cuentaViejo == null ? "-" : this.obtenerDescripcionCondicionVenta(cuentaViejo.tipoCuenta));
      var valorNuevo = "Condición venta: "  + this.obtenerDescripcionCondicionVenta(cuentaNuevo.tipoCuenta);
      modificaciones.push({valorViejo: valorViejo, valorNuevo: valorNuevo})
    }

    if(cuentaViejo == null || cuentaViejo.moneda != cuentaNuevo.moneda){
      var valorViejo = "Moneda: " + (cuentaViejo == null ? "-" : this.obtenerDescripcionMoneda(cuentaViejo.moneda));
      var valorNuevo = "Moneda: "  + this.obtenerDescripcionMoneda(cuentaNuevo.moneda);
      modificaciones.push({valorViejo: valorViejo, valorNuevo: valorNuevo})
    }

    if(cuentaViejo == null || cuentaViejo.tipoEntregaFactura != cuentaNuevo.tipoEntregaFactura){
      var valorViejo = "Tipo entrega: " + (cuentaViejo == null ? "-" : this.obtenerDescripcionTipoEntregaFactura(cuentaViejo.tipoEntregaFactura));
      var valorNuevo = "Tipo entrega: "  + this.obtenerDescripcionTipoEntregaFactura(cuentaNuevo.tipoEntregaFactura);
      modificaciones.push({valorViejo: valorViejo, valorNuevo: valorNuevo})
    }

    if(cuentaViejo == null || cuentaViejo.agrupacionRemitos != cuentaNuevo.agrupacionRemitos){
      var valorViejo = "Agrupación remitos: " + (cuentaViejo == null ? "-" : this.obtenerDescripcionAgrupacionRemitos(cuentaViejo.agrupacionRemitos));
      var valorNuevo = "Agrupación remitos: "  + this.obtenerDescripcionAgrupacionRemitos(cuentaNuevo.agrupacionRemitos);
      modificaciones.push({valorViejo: valorViejo, valorNuevo: valorNuevo})
    }

    // Periodo facturacion puede ser nulo
    if(cuentaViejo == null || ((! this.checkAmbosNulos(cuentaViejo.periodoFacturacion, cuentaNuevo.periodoFacturacion))  && this.cambioPeriodoFacturacion(cuentaViejo.periodoFacturacion, cuentaNuevo.periodoFacturacion)) ){
      var valorViejo = "Periodo facturación: " + (cuentaViejo == null || cuentaViejo.periodoFacturacion == null ? "-" : cuentaViejo.periodoFacturacion.descripcion);
      var valorNuevo = "Periodo facturación: "  + (cuentaNuevo.periodoFacturacion == null ? "-" : cuentaNuevo.periodoFacturacion.descripcion);
      modificaciones.push({valorViejo: valorViejo, valorNuevo: valorNuevo})
    }

    if(cuentaViejo == null || cuentaViejo.notificaPorEmail != cuentaNuevo.notificaPorEmail){
      var valorViejo = "Notificar por mail: " + (cuentaViejo == null ? "-" : cuentaViejo.notificaPorEmail ? "Si" : "No");
      var valorNuevo = "Notificar por mail: "  + (cuentaNuevo.notificaPorEmail ? "Si" : "No");
      modificaciones.push({valorViejo: valorViejo, valorNuevo: valorNuevo})
    }

    if(cuentaViejo == null || cuentaViejo.email != cuentaNuevo.email){
      var valorViejo = "Email: " + (cuentaViejo == null ? "-" : cuentaViejo.email);
      var valorNuevo = "Email: "  + cuentaNuevo.email;
      modificaciones.push({valorViejo: valorViejo, valorNuevo: valorNuevo})
    }
    
    if(cuentaViejo == null || (! this.checkAmbosNulos(cuentaViejo.ctaSap, cuentaNuevo.ctaSap)  && this.cambioCuentaSAP(cuentaViejo.ctaSap, cuentaNuevo.ctaSap)) ){
      var valorViejo = "Cuenta SAP: " + (cuentaViejo == null || cuentaViejo.ctaSap == null ? "-" : cuentaViejo.ctaSap);
      var valorNuevo = "Cuenta SAP: "  + (cuentaNuevo.ctaSap == null ? "-" : cuentaNuevo.ctaSap);
      modificaciones.push({valorViejo: valorViejo, valorNuevo: valorNuevo})
    }

    if(cuentaViejo == null || cuentaViejo.activo != cuentaNuevo.activo){
      var valorViejo = "Activo: " + (cuentaViejo == null ? "-" : cuentaViejo.activo ? "Si" : "No");
      var valorNuevo = "Activo: " + (cuentaNuevo.activo ? "Si" : "No");
      modificaciones.push({valorViejo: valorViejo, valorNuevo: valorNuevo})
    }

    if(cuentaViejo == null || cuentaViejo.soloPAD != cuentaNuevo.soloPAD){
      var valorViejo = "Solo PAD: " + (cuentaViejo == null ? "-" : cuentaViejo.soloPAD ? "Si" : "No");
      var valorNuevo = "Solo PAD: " + (cuentaNuevo == null ? "-" :cuentaNuevo.soloPAD ? "Si" : "No");
      modificaciones.push({valorViejo: valorViejo, valorNuevo: valorNuevo})
    }

    return modificaciones;
  }

  descripcionCodigoUbicacion(codigoUbicacion: number){
    if(codigoUbicacion == CodigoUbicacion.entregaFactura){
      return "Entrega Factura";
    }else if(codigoUbicacion == CodigoUbicacion.entregaProducto){
      return "Entrega Producto";
    }else if(codigoUbicacion == CodigoUbicacion.entregaProductoFactura){
      return "Entrega ProductoFactura";
    }else if(codigoUbicacion == CodigoUbicacion.fiscal){
      return "Fiscal";
    }else if(codigoUbicacion == CodigoUbicacion.fiscalEntregaProductoFactura){
      return "Fiscal Entrega Producto Factrua";
    }else {
      return "";
    }
  }
}

