import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FacturacionIndexComponent } from './facturacion-index/facturacion-index.component';
import { FacturacionRoutingModule } from './facturacion-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ImportacionPadronesComponent } from './importacion-padrones/importacion-padrones.component';
import { SharedModule } from '../shared/shared.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DebitMemoPanelComponent } from './debit-memo-panel/debit-memo-panel.component';
import { CreditMemoPanelComponent } from './credit-memo-panel/credit-memo-panel.component';
import { NgxBarcode6Module } from 'ngx-barcode6';
import { BrowserModule } from '@angular/platform-browser';
import {NgxPrintModule} from 'ngx-print';
import { ParametrosImpositivosIndexComponent } from './parametros-impositivos-v2/parametros-impositivos-index.component';
import { ListadoProvinciasComponent } from './parametros-impositivos-v2/listado-provincias/listado-provincias.component';
import { ListadoParametrosImpositivosComponent } from './parametros-impositivos-v2/listado-parametros-impositivos/listado-parametros-impositivos.component';
import { ParametrosImpositivosAgrupadosComponent } from './parametros-impositivos-v2/parametros-impositivos-agrupados/parametros-impositivos-agrupados.component';
import { ModalProvinciaComponentV2 } from './parametros-impositivos-v2/modal-provincia/modal-provincia-v2.component';
import { ModalCiudadComponentV2 } from './parametros-impositivos-v2/modal-ciudad/modal-ciudad-v2.component';
import { ParametroImpositivoComponent } from '../shared/parametro-impositivo/parametro-impositivo.component';
import { RemitosListaComponent } from './Remitos/remitos-lista/remitos-lista.component';
import { RemitosSeleccionComponent } from './Remitos/remitos-seleccion/remitos-seleccion.component';
import { RemitosFacturacionComponent } from './Remitos/remitos-facturacion/remitos-facturacion.component';
import { RemitoComponent } from './Remitos/remito/remito.component';
import { CorreccionPreciosComponent } from './correccion-precios/correccion-precios.component';
import { RemitosNoAsociadosFacturaComponent } from './remitos-no-asociados-factura/remitos-no-asociados-factura.component';
import { AprobacionRefacturacionComponent } from './aprobacion-refacturacion/aprobacion-refacturacion.component';
import { ModalObservacionRefacturacionComponent } from './aprobacion-refacturacion/modal-observacion-refacturacion/modal-observacion-refacturacion.component';
import { BitacoraRefacturacionComponent } from './bitacora-refacturacion/bitacora-refacturacion.component';
import { HoldeoAeroplantaComponent } from './holdeo/holdeo-aeroplanta/holdeo-aeroplanta.component';
import { HoldeoCuentasComponent } from './holdeo/holdeo-cuentas/holdeo-cuentas.component';
import { HoldeoAerovalesComponent } from './holdeo/holdeo-aerovales/holdeo-aerovales.component';
import { NuevoHoldeoComponent } from './holdeo/nuevo-holdeo/nuevo-holdeo.component';
import { PrevisualizacionComprobanteComponent } from '../shared/previsualizacion-comprobante/previsualizacion-comprobante.component';
import { VisorComprobanteComponent } from '../shared/visor-comprobante/visor-comprobante.component';
import { DespachoRefacturacionComponent } from './despacho-refacturacion/despacho-refacturacion.component';
import { VisorAerovalesComponent } from '../despacho/visor-aerovales/visor-aerovales.component';
import { AerovaleDetalleComponent } from '../despacho/aerovale-detalle/aerovale-detalle.component';
import { DespachoModule } from '../despacho/despacho.module';
import { RefacturacionIndexComponent } from './refacturacion-index/refacturacion-index.component';
import { HoldeoPrincipalComponent } from './holdeo/holdeo-principal/holdeo-principal.component';
import { RevisionRefacturacionComponent } from './revision-refacturacion/revision-refacturacion.component';
import { SimularFacturacionComponent } from './simular-facturacion/simular-facturacion.component';
import { NivelesRefacturacionComponent } from './niveles-refacturacion/niveles-refacturacion.component';
import { ModalNivelRefacturacionComponent } from './niveles-refacturacion/modal-nivel-refacturacion/modal-nivel-refacturacion.component';
import { ReporteRemitosVencidosComponent } from './reportes/reporte-remitos-vencidos/reporte-remitos-vencidos.component';
import { ReportadorRemitosComponent } from './reportes/reporte-remitos/reportador-remitos/reportador-remitos.component';
import { RefacturacionHistorialComponent } from './refacturacion-historial/refacturacion-historial.component';
import { CorreccionDespachoComponent } from './despacho-refacturacion/correccion-despacho/correccion-despacho.component';
import { ComprobanteComponent } from './reportes/comprobante/comprobante.component';
import { ReporteFacturasComponent } from './reportes/reporte-facturas/reporte-facturas.component';
import { ReportesIndexComponent } from './reportes/reportes-index/reportes-index.component';
import { EjecucionesFacturacionComponent } from './reportes/ejecuciones/ejecuciones-facturacion/ejecuciones-facturacion.component';
import { DetalleEjecucionComponent } from './reportes/ejecuciones/detalle-ejecucion/detalle-ejecucion.component';
import { RefacturacionManualComponent } from './refacturacion-manual/refacturacion-manual.component';
import { ModalSolicitudRefacturacionManualComponent } from './refacturacion-manual/modal-solicitud-refacturacion-manual/modal-solicitud-refacturacion-manual.component';
import { VisorDocumentosComponent } from './documentos/visor-documentos/visor-documentos.component';
import { DocumentosIndexComponent } from './documentos/documentos-index/documentos-index.component'
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { TipoMovimientoPipe } from '../shared/pipes/tipo-movimiento.pipe';
import { NombreTipoComprobantePipe } from '../shared/pipes/nombre-tipo-comprobante.pipe';
import { FacturaNumeroPipe } from '../shared/pipes/factura-numero.pipe';
import { FacturaPrefijoPipe } from '../shared/pipes/factura-prefijo.pipe';
import { CondicionVentaPipe } from '../shared/pipes/condicion-venta.pipe';

@NgModule({
  declarations: [
    FacturacionIndexComponent,
    ImportacionPadronesComponent,
    ModalProvinciaComponentV2,
    ModalCiudadComponentV2,
    ComprobanteComponent,
    DebitMemoPanelComponent,
    CreditMemoPanelComponent,
    ParametrosImpositivosIndexComponent,
    ListadoProvinciasComponent,
    ParametrosImpositivosAgrupadosComponent,
    ListadoParametrosImpositivosComponent,
    RemitosListaComponent,
    RemitosSeleccionComponent,
    RemitosFacturacionComponent,
    RemitoComponent,
    ReportadorRemitosComponent,
    HoldeoAeroplantaComponent,
    HoldeoCuentasComponent,
    HoldeoAerovalesComponent,
    NuevoHoldeoComponent,
    EjecucionesFacturacionComponent,
    CorreccionPreciosComponent,
    AprobacionRefacturacionComponent,
    RemitosNoAsociadosFacturaComponent,
    ModalObservacionRefacturacionComponent,
    BitacoraRefacturacionComponent,
    ReportadorRemitosComponent,
    DespachoRefacturacionComponent,
    RefacturacionIndexComponent,
    DetalleEjecucionComponent,
    HoldeoPrincipalComponent,
    ReporteFacturasComponent,
    RevisionRefacturacionComponent,
    SimularFacturacionComponent,
    NivelesRefacturacionComponent,
    ModalNivelRefacturacionComponent,
    ReporteRemitosVencidosComponent,
    ReportesIndexComponent,
    RefacturacionManualComponent,
    ModalSolicitudRefacturacionManualComponent,
    RefacturacionHistorialComponent,
    CorreccionDespachoComponent,
    VisorDocumentosComponent,
    DocumentosIndexComponent    
  ],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    FacturacionRoutingModule,
    NgbModule,
    SharedModule,
    NgxSpinnerModule,
    NgbModule,
    NgxBarcode6Module,
    BrowserModule,
    NgxPrintModule,
    DespachoModule,
    InfiniteScrollModule
  ],
  providers: [
    TipoMovimientoPipe,
    NombreTipoComprobantePipe,
    FacturaPrefijoPipe, 
    FacturaNumeroPipe,
    CondicionVentaPipe
  ]
})
export class FacturacionModule { }
