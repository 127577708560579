import { Component, Input } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.css']
})
export class ModalConfirmComponent {

  @Input() textoSi: string;
  @Input() textoNo: string;

  //Si no se pasa el activeModal, da error que no existe "dismiss" al seleccionar NO
  constructor(private activeModal: NgbActiveModal) { }


  titulo: string;
  mensaje: string;
  danger: boolean;

  ngOnInit(): void {
    if (!this.textoSi || this.textoSi.length == 0)
      this.textoSi = "Si"
    if (!this.textoNo || this.textoNo.length == 0)
      this.textoNo = "No"
  }

  close()
  {
    this.activeModal.close('Si')
  }

  dismiss()
  {
    this.activeModal.dismiss('NO clicked')
  }
}
