import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, FormGroup } from '@angular/forms';
import { PreciosService } from 'src/app/precios/precios.service';
import { Cliente } from 'src/app/shared/models/cliente/Cliente';

@Component({
  selector: 'app-alijes-saldos',
  templateUrl: './alijes-saldos.component.html',
  styleUrls: ['./alijes-saldos.component.scss']
})
export class AlijesSaldosComponent implements OnInit {

  clienteSeleccionado : Cliente;
  clienteBuscado:string;    

  busquedaFormControl = new UntypedFormControl();

  constructor(
    private precioService: PreciosService
  ) { }

  ngOnInit(): void {
  }

  usuarioSeleccionoCliente(cliente: Cliente) {
    this.clienteSeleccionado = cliente;
  }

  filtrarClientes() {
    this.clienteBuscado = this.busqueda.value;
  }

  get busqueda() {
    return this.busquedaFormControl;
  }  
}
