<div class="card-body">
  <div *ngIf="cargandoArchivo">
    <span>{{ cargandoArchivo.fileName }} - {{cargandoArchivo.estado}}</span>
    <ng-container *ngIf="cargandoArchivo.estado === EstadoUpload.Subiendo">
      <span> - {{ cargandoArchivo.progress }}%</span>
      <span *ngIf="cargandoArchivo.segundosRestantes >= 60">
        ({{ cargandoArchivo.segundosRestantes / 60  | number: '1.0-0'}}</span>
      <span *ngIf="cargandoArchivo.segundosRestantes <= 60">
        ({{ cargandoArchivo.segundosRestantes | number: '1.0-0'}}</span>
      <span> {{ cargandoArchivo.segundosRestantes > 60? 'minuto/s' : 'segundo/s' }} restantes) </span>
    </ng-container>
    <div class="progress" style="height: 20px;margin-bottom:20px;">
      <div
        *ngIf="cargandoArchivo.estado == EstadoUpload.Preparando || cargandoArchivo.estado == EstadoUpload.Importando"
        class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
        [style.width.%]="cargandoArchivo.estado == EstadoUpload.Preparando? 0 : 100" aria-valuenow="25"
        aria-valuemin="0" aria-valuemax="100"></div>
      <div *ngIf="cargandoArchivo.estado == EstadoUpload.Subiendo"
        class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" [style.width.%]="cargandoArchivo.progress"
        aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
    </div>
    <button type="button" *ngIf="cargandoArchivo.estado === EstadoUpload.Subiendo" class="btn btn-danger btn-sm"
      (click)="onCancelarCarga(cargandoArchivo)">Cancelar</button>
  </div>
  <div style="margin-top: 1.5em;">
    <form [formGroup]="profileForm" (ngSubmit)="onSubmit()">
      <div class="mb-3">
        <input class="form-control" type="file" id="formFile" name="profile" (change)="onSelectedFile($event)" formControlName="inputFile" required>
        <!--<div class="custom-file">
          <input type="file" class="custom-file-input" name="profile" (change)="onSelectedFile($event)"
            id="validatedCustomFile" formControlName="inputFile" required>
          <label class="custom-file-label" for="validatedCustomFile">{{ fileName }}</label>
          <div class="invalid-feedback">Example invalid custom file feedback</div>
        </div>-->
      </div>
      <div class="mb-3">
        <button type="submit" [disabled]="!file || preparandoCarga" class="btn btn-success">Cargar</button>
        <button type="button" class="btn btn-secondary ms-2" (click)="reset()">Cancelar</button>
      </div>
    </form>
  </div>
  <ngb-alert [dismissible]="false" type="success" *ngIf="cargaArchivoTerminada && cargaArchivoTerminada.finalizada">
    {{ cargaArchivoTerminada.mensajeOk }}</ngb-alert>
  <ngb-alert [dismissible]="false" type="danger" *ngIf="cargaArchivoTerminada && cargaArchivoTerminada.error">Error
    al cargar el archivo {{ cargaArchivoTerminada.fileName }}: {{ cargaArchivoTerminada.error }}</ngb-alert>
  <ngb-alert [dismissible]="false" type="danger"
    *ngIf="cargaArchivoTerminada && cargaArchivoTerminada.estado == EstadoUpload.Cancelado">Carga anulada por el
    usuario</ngb-alert>
</div>