<nav class="navbar navbar-light bg-light navbar-expand-lg mb-3">
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <div style="align-items: center;" class="d-flex" [formGroup]="impuestoForm">
      <b style="width: 300px;">Seleccione tipo de impuesto a cargar:</b>
      <select style="width: 100px;" class="form-select ms-2" formControlName="seleccionImpuesto">
        <option value="4">IDC</option>
        <option value="5">ITC</option>
      </select>
    </div>
  </div>
</nav>
<div class="card mt-3">
  <div class="card-header">
    <b>Carga masiva de impuestos.</b>
  </div>
  <div class="card-body">
    <h5 class="card-title">Utilice este importador para cargar masivamente el impuesto seleccionado utilizando un archivo de tipo Excel <i
        class="fa fa-file-excel-o" aria-hidden="true"></i></h5>
    <p class="card-text"><b>Importante:&nbsp;</b>Debe tener el formato y ordenamiento de columnas que se estableció en
      la muestra.</p>
    <table class="table-bordered table-condensed text-center letra" style="border: solid; border-width: 2px;">
      <tr>
        <td class="py-2 px-2"><b>Tipo Impuesto</b></td>
        <td class="py-2 px-2">ITC/IDC</td>
        <td class="py-2 px-2"></td>
        <td class="py-2 px-2"></td>
        <td class="py-2 px-2"></td>
        <td class="py-2 px-2"></td>
      </tr>
      <tr>
        <td class="py-2 px-2"><b>Codigo Producto</b></td>
        <td class="py-2 px-2"><b>Leyenda</b></td>
        <td class="py-2 px-2"><b>Valor</b></td>
        <td class="py-2 px-2"><b>Fecha Inicio</b></td>
        <td class="py-2 px-2"><b>Fecha Fin</b></td>
        <td class="py-2 px-2"><b>Zona</b></td>
      </tr>
    </table>    
    <form [formGroup]="ITCForm" class="mt-3">
      <div class="row">
        <div class="input-group mb-3">
          <input type="file" class="form-control" id="inputGroupFile02" formControlName="inputFile" (change)="onSelectedFile($event)">
          <label class="input-group-text" for="inputGroupFile02">Examinar</label>
        </div>
        <div *ngIf="fileUpload.status==='error'">
          <div [innerHTML]="fileUpload.message"></div>
        </div>
      </div>
      <div *ngIf="fileUpload.status==='progress'">
        <div class="progress" style="height: 20px;margin-bottom:20px;">
          <div class="progress-bar" role="progressbar" [style.width.%]="fileUpload.message" aria-valuenow="25"
            aria-valuemin="0" aria-valuemax="100">{{fileUpload.message}}%</div>
        </div>
      </div>
      <div *ngIf="fileUpload.length > 0" class="alert alert-warning" role="alert">
        <div *ngFor="let msg of fileUpload">
          <p>{{ msg.description }}</p>
        </div>
      </div>
    </form>
  </div>
  <div class="card-footer">
    <button class="btn btn-primary me-2" id="aceptar" [disabled]="!ITCForm.valid || guardandoExcel"
      (click)="cargarITC()">
      <span *ngIf="!guardandoExcel">Cargar</span>
      <div *ngIf="guardandoExcel" class="d-flex align-items-center">
        <div class="spinner-border text-light spinner-border-sm" role="status"></div>
        <span>Cargando...</span>
      </div>
    </button>
    <button type="button" class="btn btn-secondary ms-2" (click)="reset(true, true)">Cancelar</button>
  </div>
</div>