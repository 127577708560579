<div class="modal-header bg-light">
  <h4 class="modal-title">{{ editando ? "Modificar cañeria" : "Nueva cañeria" }}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close(false)"></button>
</div>
<div class="modal-body" [formGroup]="caneriasForm">
  <!--CODIGO-->
  <div class="row">
    <div class="mb-3 row col-md-12">
      <label for="codigoFormControl" class="col-sm-5 col-form-label">*Codigo:</label>
      <div class="col-sm-7">
        <input class="form-control" formControlName="codigoFormControl" maxlength="50" [readOnly]="this.editando">
        <div
          *ngIf="codigoFormControl.invalid && codigoFormControl.errors.required && (codigoFormControl.dirty || codigoFormControl.touched)"
          class="alert alert-danger">Requerido</div>
      </div>
    </div>
  </div>
  <!--NOMBRE-->
  <div class="row">
    <div class="mb-3 row col-md-12">
      <label for="nombreFormControl" class="col-sm-5 col-form-label">*Nombre:</label>
      <div class="col-sm-7">
        <input class="form-control" formControlName="nombreFormControl" maxlength="50">
        <div
          *ngIf="nombreFormControl.invalid && nombreFormControl.errors.required && (nombreFormControl.dirty || nombreFormControl.touched)"
          class="alert alert-danger">Requerido</div>
      </div>
    </div>
  </div>
  <!--PRODUCTO-->
  <div class="row">
    <div class="mb-3 row col-md-12">
      <label for="productoFormControl" class="col-md-5 col-form-label">Producto</label>
      <div class="col-md-7">
        <select id="productoFormControl" class="form-select"
          ngbTooltip="Seleccione el producto." formControlName="productoFormControl">
          <option *ngFor="let producto of productos" [ngValue]="producto">
            ({{ producto.codigoProducto }}) - {{ producto.nombreProducto }}
          </option>
        </select>
        <loading-text-spinner *ngIf="cargandoProductos" [error]="errorProductos" message="Obteniendo productos..."
          color="primary">
        </loading-text-spinner>
        <div class="alert alert-danger"
          *ngIf="(this.productoFormControl.invalid && this.productoFormControl.errors.required && (this.productoFormControl.dirty || this.productoFormControl.touched))">
          Debe seleccionar un producto obligatoriamente.
        </div>
      </div>
    </div>
  </div>
  <!--VOLUMEN-->
  <div class="row">
    <div class="mb-3 row col-md-12">
      <label for="volumenFormControl" class="col-sm-5 col-form-label">*Volumen:</label>
      <div class="col-sm-7">
        <input onkeypress="return (event.charCode != 8 && event.charCode == 0 || (event.charCode >= 48 && event.charCode <= 57))" class="form-control" formControlName="volumenFormControl" maxlength="10">
        <div
          *ngIf="volumenFormControl.invalid && volumenFormControl.errors.required && (volumenFormControl.dirty || volumenFormControl.touched)"
          class="alert alert-danger">Requerido</div>
      </div>
    </div>
  </div>
  <!--COMENTARIO-->
  <div class="row">
    <div class="mb-3 row col-md-12">
      <label for="comentarioFormControl" class="col-sm-5 col-form-label">Comentario:</label>
      <div class="col-sm-7">
        <!-- <input class="form-control" formControlName="comentarioFormControl" maxlength="50"> -->
        <textarea class="form-control" formControlName="comentarioFormControl" rows='5'></textarea>
        <div
          *ngIf="comentarioFormControl.invalid && comentarioFormControl.errors.required && (comentarioFormControl.dirty || volumenFormControl.touched)"
          class="alert alert-danger">Requerido</div>
      </div>
    </div>
  </div>
  <small class="text-danger">*Campos obligatorios.</small>
</div>
<div class="modal-footer justify-content-center">
  <button class="btn btn-primary me-2" id="aceptar" [disabled]="!caneriasForm.valid || creandoCaneria" (click)="agregarStockCanerias()">
    <span *ngIf="!creandoCaneria">Guardar</span>
    <div *ngIf="creandoCaneria" class="d-flex align-items-center">
      <div class="spinner-border text-light spinner-border-sm" role="status"></div>
      <span>Guardando...</span>
    </div>
  </button>
  <button type="button" class="btn btn-secondary" (click)="this.activeModal.close(false)">Cancelar</button>
</div>
