import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductoService } from 'src/app/abm/producto/producto.service';
import { UsuarioService } from 'src/app/account/usuario.service';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { DetalleIngresoDucto } from 'src/app/shared/models/stock/DetalleIngresoDucto';
import { Ducto } from 'src/app/shared/models/stock/Ducto';
import { IngresoDucto } from 'src/app/shared/models/stock/IngresoDucto';
import { Producto as ProductoDespacho } from 'src/app/shared/models/despacho/Producto';
import { Producto } from 'src/app/shared/models/stock/Producto';
import { Responsable } from 'src/app/shared/models/stock/Responsable';
import { TanqueGeneral } from 'src/app/shared/models/stock/TanqueGeneral';
import { Terminal } from 'src/app/shared/models/stock/Terminal';
import { MessageService } from 'src/app/shell/message.service';
import { StockService } from '../../stock.service';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-ingreso-ducto',
  templateUrl: './ingreso-ducto.component.html',
  styleUrls: ['./ingreso-ducto.component.css']
})
export class IngresoDuctoComponent implements OnInit {

  cargandoContenedores: boolean = false;
  cargandoTerminales: boolean = false;
  cargandoDuctos: boolean = false;
  guardandoDatos: boolean = false;
  error: string;
  procesandoRemitoFisico: boolean;

  fileRemitoFisico: any;

  contenedores: TanqueGeneral[] = [];
  contenedoresFiltrados: TanqueGeneral[] = [];
  terminales: Terminal[] = []
  ductos: Ducto[] = [];

  form: UntypedFormGroup;
  listaDetalleFormControl: UntypedFormGroup[] = [];

  productos: ProductoDespacho[] = [];
  productoSeleccionado: ProductoDespacho;

  decimalRegex = /^[0-9]+(.[0-9]{0,4})?$/;
  positiveInteger = /^\+?(0|[1-9]\d*)$/;

  constructor(
    private messageService: MessageService,
    private formBuilder: UntypedFormBuilder,
    public activeModal: NgbActiveModal,
    private stockService: StockService,
    private usuarioService: UsuarioService,    
    private productoService: ProductoService,
    private sharedService: SharedService) { }

  ngOnInit() {
    this.getProductos();
    this.crearFormulario();
    this.inicializarComponente();
    this.obtenerDuctos();
    this.obtenerContenedores();
    this.obtenerTerminales();
  }

  // form

  crearFormulario() {
    this.form = this.formBuilder.group({
      ductoFormControl: ['', Validators.required],
      terminalFormControl: ['', Validators.required],
      certificadoFormControl: ['', Validators.required],
      prefijoRemitoFromControl: ['', Validators.required],
      nroRemitoFromControl: ['', Validators.required],
      cantidad15FromControl: ['', Validators.required],
      densidadFormControl: ['', Validators.required],
      cantidad15TurbinaFromControl: ['', Validators.required],
      comentarioFormControl: [''],
      contenedorFormControl: [''],
      detalleFormControlArray: this.formBuilder.array([], Validators.required),
      remitoFisicoFormControl: [''],
    });
  }

  inicializarComponente() {
    this.procesandoRemitoFisico = false;
  }

  filtrarTanques() {
    let contenedoresAux = this.contenedores;
    this.contenedoresFiltrados = contenedoresAux.filter(c => c.productoActual.codigoProducto == this.ductoFormControl.value.producto.codigoProducto);
  }

  agregarContenedorSeleccionado() {
    if (this.contenedorFormControl.value) {
      this.detalleFormControlArray.push(this.nuevoDetalleItem());
      this.contenedoresFiltrados = this.contenedoresFiltrados.filter(c => c.codigo != this.contenedorFormControl.value.codigo);
      if (this.contenedoresFiltrados.length > 0) {
        this.contenedorFormControl.setValue(this.contenedoresFiltrados[0]);
      } else {
        this.contenedorFormControl.setValue(null);
      }
    }
  }

  onDetalleEliminado(i: number) {
    var codigoContenedor = this.detalleFormControlArray.controls[i].value.codigoTanque;
    var contenedor = this.contenedores.filter(c => c.codigo == codigoContenedor);

    this.detalleFormControlArray.removeAt(i);
    this.contenedoresFiltrados.push(contenedor[0]);
    this.contenedorFormControl.setValue(this.contenedoresFiltrados[0]);
  }

  nuevoDetalleItem(): UntypedFormGroup {
    return this.formBuilder.group({
      codigoTanque: [this.contenedorFormControl.value.codigo, Validators.required],
      nombreTanque: [this.contenedorFormControl.value.nombre, Validators.required],
      cantidad15: ['', Validators.required],
    })
  }


  armarDetalleIngreso() {
    var listaDetalle: DetalleIngresoDucto[] = [];

    var array = (this.form.controls.detalleFormControlArray as UntypedFormArray);

    for (let i = 0; i < this.detalleFormControlArray.controls.length; i++) {
      let codigoTanque = this.detalleFormControlArray.controls[i].value.codigoTanque;
      let cantidad15 = this.detalleFormControlArray.controls[i].value.cantidad15;

      var detalle = new DetalleIngresoDucto();
      detalle.codigoTanque = codigoTanque;
      detalle.volumen = cantidad15;
      detalle.volumen15 = cantidad15;

      listaDetalle.push(detalle);
    }

    return listaDetalle;
  }

  armarResponsable() {
    var r = new Responsable();
    r.UserName = this.usuarioService.getUsername();
    r.NombreCompleto = this.usuarioService.getNombresCompletosUsuario();
    r.DNI = this.usuarioService.getDniUsuario();

    return r;
  }

  compareDuctos(d1: any, d2: any): boolean {
    return d1 && d2 ? d1.id === d2.id : d1 === d2;
  }

  compareTerminales(d1: any, d2: any): boolean {
    return d1 && d2 ? d1.id === d2.id : d1 === d2;
  }
  
  onSelectedFileRemitoFisico(files: any[]) {
    if (files && files.length > 0) {
      this.guardandoDatos = true;
      this.procesandoRemitoFisico = true;
      let file = files[0];

      this.sharedService.resizeImage(file, 1500).then(blob => {
        this.fileRemitoFisico = blob;
        this.procesandoRemitoFisico = false;
        this.guardandoDatos = false;
      }, err => {
        this.procesandoRemitoFisico = false;
        this.guardandoDatos = false;
      });
/*
      this.ng2ImgMax.resizeImage(file, 800, 10000).subscribe(result => {
        this.fileRemitoFisico = result;
        this.procesandoRemitoFisico = false;
        this.guardandoDatos = false;
      },
        error => {
          this.procesandoRemitoFisico = false;
          this.guardandoDatos = false;
        });
*/        
    }
  }

  formatDensidad(event) {
    if (event.charCode == 8 || event.charCode == 0) return null;

    if (event.charCode >= 48 && event.charCode <= 57) {
      this.densidadFormControl.setValue(this.sharedService.formatDensidad(event));
    }
    return false;
  }

  obtenerExtensionDeArchivo(firma: string): string {
    let extension = firma.split('.').pop();
    console.log("la extension que obtengo es " + extension);
    return extension;
  }


  // validadores

  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  onlyDecimalNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode == 44 || event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57);
  }

  validarDatos() {
    this.error = null;

    var cantidad15Total = Number(this.cantidad15FromControl.value);

    var cantidad15TotalTurbina = Number(this.cantidad15TurbinaFromControl.value);

    var sumaCantidad15 = 0;
    for (let i = 0; i < this.detalleFormControlArray.controls.length; i++) {
      sumaCantidad15 += Number(this.detalleFormControlArray.controls[i].value.cantidad15);
    }

    if (cantidad15Total != sumaCantidad15 || cantidad15Total != cantidad15TotalTurbina || sumaCantidad15 != cantidad15TotalTurbina) {
      this.error = "Verificar que las cantidades totales a 15º coincidan.";
      return false;
    }

    if (!this.positiveInteger.test(this.prefijoRemitoFromControl.value)) {
      this.error = "El campo prefijo remito debe ser numerico.";
      return false;
    }

    if (!this.positiveInteger.test(this.nroRemitoFromControl.value)) {
      this.error = "El campo número remito debe ser numerico.";
      return false;
    }

    if (!this.decimalRegex.test(this.densidadFormControl.value)) {
      this.error = "El campo densidad debe ser decimal valido.";
      return false;
    }

    if (this.remitoFisicoFormControl.value != null) {
      let extensionRemitoFisico = this.obtenerExtensionDeArchivo(this.remitoFisicoFormControl.value);
      if (!this.esFormatoImagen(extensionRemitoFisico)) {
        this.error = "El formato del archivo del remito fisico debe ser tipo imagen.";
        return false;
      }
    }

    if (!this.validarDensidad(this.densidadFormControl.value)) {
      this.error = "La densidad debe estar entre el rango [" + this.productoSeleccionado.densidadMin + "," + this.productoSeleccionado.densidadMax + "]";
      return false;
    }

    return true;
  }

  validarDensidad(densidad) {
    try {
      if (densidad != null) {
        let esValido = true;

        this.productoSeleccionado = this.productos.find(element => element.codigoProducto === this.ductoFormControl.value.producto.codigoProducto);
        esValido = (densidad >= this.productoSeleccionado.densidadMin && densidad <= this.productoSeleccionado.densidadMax);

        return esValido;
      }
    } catch (ex) {
      console.log("Excepcion validacion densidad.");
    }
  }

  esFormatoImagen(extension: string) {
    let es: boolean = false;
    es = (extension === 'jpeg' || extension === 'jpg' || extension === 'png' || extension === 'jpe' || extension === 'jfi' || extension === 'jfif');
    return es;
  }


  // llamadas al service

  onSubmit() {

    this.error = null;

    if (this.validarDatos()) {

      var dto = new IngresoDucto();
      dto.Num_certificado_analisis = this.certificadoFormControl.value;
      dto.prefijo = this.prefijoRemitoFromControl.value;
      dto.numeroRemito = this.nroRemitoFromControl.value;
      dto.codigoDucto = this.ductoFormControl.value.codigo;
      dto.codigoTerminal = this.terminalFormControl.value.codigoTerminal;

      dto.cantidad15 = this.cantidad15FromControl.value;
      dto.cantidad = dto.cantidad15;
      dto.densidad = Number(this.densidadFormControl.value);
      dto.temperatura = 15;

      dto.cantidad15Turbina = this.cantidad15TurbinaFromControl.value;
      dto.cantidadTurbina = dto.cantidad15Turbina;

      dto.comentario = this.comentarioFormControl.value;

      dto.recepcionCombustibleDetalles = this.armarDetalleIngreso();

      dto.ResponsableDTO = this.armarResponsable();

      this.guardandoDatos = true;
      this.stockService.ingresoPorDucto(dto, this.fileRemitoFisico)
        .subscribe(rta => {
          this.guardandoDatos = false;
          this.activeModal.close(true);
        },
          (err: ErrorModel) => {
            this.messageService.showErrorMessage("Error al guardar el ingreso por ducto");
            this.guardandoDatos = false;
          });
      }

  }

  obtenerTerminales() {
    this.cargandoTerminales = true;

    this.stockService.getTerminales()
      .subscribe(listaTerminales => {
        this.terminales = listaTerminales.sort((a, b) => { if (a.nombre > b.nombre) { return 1 } else { return -1 } });
        this.cargandoTerminales = false;
      }, () => { this.cargandoTerminales = false; });
  }

  getProductos() {
    this.productoService.obtenerProductos()
      .subscribe(result => {
        this.productos = result;
      }, () => {

      });
  }

  obtenerDuctos() {
    this.cargandoDuctos = true;

    this.stockService.getDuctos(this.usuarioService.getAeroplantaActual().codigoAeroplanta)
      .subscribe(listaDuctos => {
        this.ductos = listaDuctos;
        this.cargandoDuctos = false;
      }, () => {
        this.cargandoDuctos = false;
      });
  }

  obtenerContenedores() {
    this.cargandoContenedores = true;

    this.stockService.getTanquesGenericos(this.usuarioService.getAeroplantaActual().codigoAeroplanta)
      .subscribe(listaContenedores => {
        this.contenedores = listaContenedores;
        this.cargandoContenedores = false;
      }, () => { this.cargandoContenedores = false; });
  }

  // getters

  get remitoFisicoFormControl() {
    return this.form.controls.remitoFisicoFormControl;
  }

  get ductoFormControl() {
    return this.form.controls.ductoFormControl;
  }

  get terminalFormControl() {
    return this.form.controls.terminalFormControl;
  }

  get certificadoFormControl() {
    return this.form.controls.certificadoFormControl;
  }

  get prefijoRemitoFromControl() {
    return this.form.controls.prefijoRemitoFromControl;
  }

  get nroRemitoFromControl() {
    return this.form.controls.nroRemitoFromControl;
  }

  get cantidad15FromControl() {
    return this.form.controls.cantidad15FromControl;
  }


  get densidadFormControl() {
    return this.form.controls.densidadFormControl;
  }

  get cantidad15TurbinaFromControl() {
    return this.form.controls.cantidad15TurbinaFromControl;
  }

  get contenedorFormControl() {
    return this.form.controls.contenedorFormControl;
  }

  get comentarioFormControl() {
    return this.form.controls.comentarioFormControl;
  }

  get detalleFormControlArray(): UntypedFormArray {
    return this.form.controls.detalleFormControlArray as UntypedFormArray;
  }

}
