<div class="card">
    <div class="card-header">
      <nav class="navbar navbar-light bg-light navbar-expand-lg">
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
  
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav">
            <!-- desde -->
            <li class="nav-item me-2">
              <div class="input-group">
  
                <input type="date" [ngModel]="model" class="fechaFormControlDesdeHasta" placeholder="aaaa-mm-dd"
                  (ngModelChange)="model = $event" name="dt" ngbDatepicker #d="ngbDatepicker" value="model"
                  class="form-control form-control-rounded" #id="ngbDatepicker" #searchDate required readonly>
  
                <div class="input-group-append">
                  <button id="botonDesde" type="button" class="input-group-btn btn btn-secondary" (click)="toggleDatepicker(d)">
                    <i class="fa fa-calendar" aria-hidden="true"></i></button>
                </div>
              </div>
            </li>
            <!-- hasta -->
            <li class="nav-item">
              <div class="input-group">
                <input type="date" class="fechaFormControlDesdeHasta" [ngModel]="model1" placeholder="aaaa-mm-dd"
                  name="dp" value="model1" (ngModelChange)="model1 = $event" class="form-control form-control-rounded"
                  ngbDatepicker #h="ngbDatepicker" #searchDate required readonly>
  
                <div class="input-group-append">
                  <button id="botonHasta" type="button" class="input-group-btn btn btn-secondary" (click)="toggleDatepicker(h)"><i
                      class="fa fa-calendar" aria-hidden="true"></i></button>
                </div>
              </div>
            <li class="nav-item">
              <button (click)="this.accionReiniciarBusqueda()" class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2"
                type="submit"><i class="fa fa-search" aria-hidden="true"></i>
              </button>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  
    <div class="col col-md-12" *ngIf="mostrarContenedorDatos">
        
      <div class="spinner-container" *ngIf="obteniendoDatos">        
        <ngx-spinner name="spinnerGrafico" bdOpacity=0.3bd
          Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
          <p style="color: white">C a r g a n d o . . . </p>
        </ngx-spinner>        
      </div>
  
      <div class="row" *ngIf="!obteniendoDatos" id="contenedor">
        <div class="col col-12" id="contenedor-botones">
            <button type="button" class="btn me-2" [ngClass]="{'btn-primary': reporteSeleccionado==1, 'btn-outline-primary': reporteSeleccionado!=1}" (click)="mostrarUltimasMediciones()"  [disabled]="obteniendoDatos">
                Ultimas mediciones
            </button>

            <button type="button" class="btn me-2" [ngClass]="{'btn-primary': reporteSeleccionado==2, 'btn-outline-primary': reporteSeleccionado!=2}" (click)="mostrarComparativas()"  [disabled]="obteniendoDatos">
              Comparativas
            </button>

            <button type="button" class="btn me-2" [ngClass]="{'btn-primary': reporteSeleccionado==3, 'btn-outline-primary': reporteSeleccionado!=3}" (click)="mostrarReporteMovimientos()"  [disabled]="obteniendoDatos">
                Reportes movimiento productos
            </button>

            <button type="button" class="btn me-2" [ngClass]="{'btn-primary': reporteSeleccionado==4, 'btn-outline-primary': reporteSeleccionado!=4}" (click)="mostrarGenerarActa()"  [disabled]="obteniendoDatos">
                Generar acta
            </button>
        </div>

        <div class="col col-12" id="contenedor-datos">

          <app-ultimas-mediciones *ngIf="reporteSeleccionado == 1" [mediciones]="mediciones"></app-ultimas-mediciones>
  
          <app-comparativas *ngIf="reporteSeleccionado == 2" [comparativas]="comparativas"></app-comparativas>
  
          <app-reportes-movimientos *ngIf="this.reporteSeleccionado == 3" [codigoAeroplanta]="this.getCodigoAeroplanta()" [fechaDesdeMov]="this.getFechaDesde()" [fechaHastaMov]="this.getFechaHasta()"></app-reportes-movimientos>
  
          <app-generar-acta *ngIf="reporteSeleccionado == 4" [datosGeneralesActa]="datosGeneralesActa" [datosFacturacionActa]="datosFacturacionActa" [mes]="this.fechaDesde.getMonth()+1" [anio]="this.fechaDesde.getFullYear()"></app-generar-acta>        
  
        </div>
      </div>
  </div>