import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { NgbCalendar, NgbDatepickerConfig, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { UsuarioService } from 'src/app/account/usuario.service';
import { ClienteService } from 'src/app/cliente/cliente.service';
import { PreciosService } from 'src/app/precios/precios.service';
import { PreautorizacionDTO } from 'src/app/shared/models/cliente/PreautorizacionDTO';
import { Aeroplanta } from 'src/app/shared/models/despacho/Aeroplanta';
import { MessageService } from 'src/app/shell/message.service';
import { ModalPreautorizacionComponent } from './modal-preautorizacion/modal-preautorizacion.component';

@Component({
  selector: 'app-reporte-preautorizaciones',
  templateUrl: './reporte-preautorizaciones.component.html',
  styleUrls: ['./reporte-preautorizaciones.component.scss']
})
export class ReportePreautorizacionesComponent implements OnInit {

  busquedaFormControl = new UntypedFormControl('');  
  aeroplantaFormControl = new UntypedFormControl(null);

  preauto: PreautorizacionDTO[] = [];
  preautoFiltradas: PreautorizacionDTO[] = [];
  preautorizacionSeleccionada: number=null;

  aeroplantas:Aeroplanta[];
  cargandoAeroplanta: boolean = false;  
  errorAeroplantas: boolean = false;

  obteniendoPreAutorizaciones: boolean = false;  
  anulando: boolean = false;  

  model: NgbDateStruct;
  model1: NgbDateStruct;
  fechaDesde: Date;
  fechaHasta: Date;

  constructor(    
    public modal:NgbModal,
    private messageService:MessageService,
    private clienteService:ClienteService,
    private spinner: NgxSpinnerService,
    private precioService: PreciosService,
    private datepickerConfig: NgbDatepickerConfig,
    private calendar: NgbCalendar) {}

  ngOnInit(): void {
    this.model = this.calendar.getToday();
    this.model1 = this.calendar.getToday();
    let inicio: any = { day: 1, month: 1, year: 2019 };
    this.datepickerConfig.minDate = inicio;
    let fin: any = { day: 31, month: 12, year: 2050 };
    this.datepickerConfig.maxDate = fin;
    this.setearFechaDesdeHasta();
    this.obtenerAeroplantas();
    this.obtenerPreautorizaciones();
  }

  ngOnDestroy(): void {

  }

  mostrarSpinner() {
    this.obteniendoPreAutorizaciones = true;
    this.spinner.show('spinnerGrafico');
  }
  
  ocultarSpinner() {
    this.obteniendoPreAutorizaciones = false;
    this.spinner.hide('spinnerGrafico');
  }

  changeDesdeHasta() {
    this.setearFechaDesdeHasta();
    this.obtenerPreautorizaciones();
  }
  
  filtrarPreautorizaciones() {
    this.preautoFiltradas = this.preauto.filter(a => {
      let agregar = true;
      if (this.busqueda.value) {
        agregar = a.ctA_SGC && a.ctA_SGC.toLocaleLowerCase().indexOf(this.busqueda.value.toLocaleLowerCase()) > -1;
        agregar = agregar || (a.cuentaDescripcion && a.cuentaDescripcion.toLocaleLowerCase().indexOf(this.busqueda.value.toLocaleLowerCase()) > -1);        
        agregar = agregar || (a.matricula && a.matricula.toLocaleLowerCase().indexOf(this.busqueda.value.toLocaleLowerCase()) > -1);        
      }

      if (this.busqueda.value == '') {
        this.preautoFiltradas = this.preauto;
      }

      return agregar;
    });
  }

  formatDate(year, month, day, hour, minute, seconds): string {
    return year + '-' + month.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + '-' + day.toLocaleString(undefined, { minimumIntegerDigits: 2 })
      + 'T' + hour.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + minute.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + seconds.toLocaleString(undefined, { minimumIntegerDigits: 2 });
  }

  setearFechaDesdeHasta() {
    this.fechaDesde = new Date(this.model.year, this.model.month - 1, this.model.day);
    this.fechaHasta = new Date(this.model1.year, this.model1.month - 1, this.model1.day);
    this.fechaHasta.setHours(23, 59, 59, 99);
  }

  getFechaDesde(){
    return this.formatDate(this.fechaDesde.getFullYear(), this.fechaDesde.getMonth() + 1, this.fechaDesde.getDate(), 0, 0, 0);
  }

  getFechaHasta(){
    return this.formatDate(this.fechaHasta.getFullYear(), this.fechaHasta.getMonth() + 1, this.fechaHasta.getDate(), 23, 59, 59);
  }

  fechaDesdeMayorQueHasta() {
    let esMayor: boolean;
    let desde = new Date(this.model.year, this.model.month - 1, this.model.day);
    let hasta = new Date(this.model1.year, this.model1.month - 1, this.model1.day);
    (desde > hasta) ? esMayor = true : esMayor = false;
    return esMayor;
  }

  crearPreautorizacion() {
    const modalRef = this.modal.open(ModalPreautorizacionComponent, { backdrop: "static", centered: true });
    modalRef.componentInstance.aeroplantas = this.aeroplantas;
    modalRef.result.then((preautorizacion) => {
      (preautorizacion) ? this.changeDesdeHasta() : null;
    });    
  }

  /** servicios */

  obtenerAeroplantas() {
    this.cargandoAeroplanta = true;
    this.precioService.obtenerTodasLasAeroplantas()
      .subscribe(result => {
        this.aeroplantas = result.sort((a, b) => { if (a.nombre > b.nombre) { return 1 } else { return -1 } });
        this.cargandoAeroplanta = false;
      },()=>{
        this.errorAeroplantas = true;
      });
  }

  async obtenerPreautorizaciones() {

    this.mostrarSpinner();
    
    try {
      this.preauto = await this.clienteService.getPreautorizaciones(this.getFechaDesde(), this.getFechaHasta(), this.aeroplanta.value);
      this.preautoFiltradas = this.preauto.sort((a, b) => new Date(b.fechaHora).getTime() - new Date(a.fechaHora).getTime());
      this.filtrarPreautorizaciones();
      this.ocultarSpinner();    
    } catch (error) {
      this.messageService.showErrorMessage('Hubo un error al obtener los datos. ' + error.message);      
      this.ocultarSpinner();
    }    
  }

  async anularPreautorizacion(id:string, index) {
    
    this.anulando=true;
    this.preautorizacionSeleccionada=index;
    
    try {
      let a = await this.clienteService.anularPreAutorizaciones(id);
      if (a) {
        this.messageService.showSuccessMessage("Preautorización anulada con éxito.");
        this.changeDesdeHasta()
      }
      this.preautorizacionSeleccionada=null;
      this.anulando=false;
    } catch (error) {
      this.messageService.showErrorMessage('Hubo un error al obtener los datos. ' + error.message);   
      this.preautorizacionSeleccionada=null;  
      this.anulando=false; 
    }    
  }

  /** getters */
  
  get busqueda() {
    return this.busquedaFormControl;
  }

  get aeroplanta() {
    return this.aeroplantaFormControl;
  }

}
