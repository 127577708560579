import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { of, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AzureBlobUploadService } from '../shared/azure-blob-upload.service';
import { ResultadoFacturacionCtaCteDto } from '../shared/models/facturacion/ResultadoFacturacionCtaCteDto';
import { RefacturacionFactura } from '../shared/models/facturacion/RefacturacionFactura';
import { RefacturacionManual } from '../shared/models/facturacion/RefacturacionManual';
import { NivelAprobacion } from '../shared/models/precios/NivelAprobacion';
import { HandleError, HttpErrorHandler } from '../http-error-handler.service';
import { Comprobante } from '../shared/models/stock/Comprobante';
import { Factura } from '../shared/models/facturacion/Factura';
import { UsuarioNiveles } from '../shared/models/precios/UsuarioNiveles';
import { TipoNivelUsuario } from '../shared/enums/tipoNivelUsuario';
import { tipoMonedaTransformada } from '../shared/enums/tipoMonedaTransformada';
import { UsuarioService } from '../account/usuario.service';


@Injectable({ providedIn: 'root' })
export class FacturacionService {
  facturacionBaseUrl = environment.apiServer.facturacionBaseUrl;
  usuariosBaseUrl = environment.apiServer.usuariosBaseUrl;
  usuariosUrl = this.usuariosBaseUrl + "/UsuariosV1";
  nivelesUrl = this.usuariosBaseUrl + "/NivelesV1";
  private handleError: HandleError;

  private nivelesUsuario: UsuarioNiveles;

  constructor(private azureBlobUpload: AzureBlobUploadService, private http: HttpClient, httpErrorHandler: HttpErrorHandler, private usuarioService: UsuarioService) { 
    this.handleError = httpErrorHandler.createHandleError('Error');
  }

  consutarPorCodigoAeroplanta(codAero:String) {
    return this.http.get(`${this.facturacionBaseUrl}/AeroplantasV1?codigoAeroplanta=${codAero}`);
  }

  consultarFacturacionSimulada(fecha): Observable<ResultadoFacturacionCtaCteDto[]> {
    return this.http.post<ResultadoFacturacionCtaCteDto[]>(this.facturacionBaseUrl + '/FacturacionV1/FacturacionCtaCte?fecha=' + fecha, null)
      .pipe(
        tap((provincia) => console.log("Se consulta facturacion simulada para fecha: " + fecha))
      );
  }

  emitirFacturacionSimulada(fecha, listaResultados: ResultadoFacturacionCtaCteDto[]): Observable<ResultadoFacturacionCtaCteDto[]> {
    return this.http.put<ResultadoFacturacionCtaCteDto[]>(this.facturacionBaseUrl + '/FacturacionV1/FacturacionCtaCte/Emitir?fecha=' + fecha + '&ReEjecucion=false', listaResultados)
      .pipe(
        tap((provincia) => console.log("Se emite facturacion simulada para fecha: " + fecha))
      );
  }

  emitirRefacturacionManual(dto: RefacturacionFactura): Observable<RefacturacionManual> {
    return this.http.post<RefacturacionManual>(this.facturacionBaseUrl + '/RefacturacionV1/Manual', dto)
  }

  emitirRefacturacionPorDespacho(dto: RefacturacionFactura): Observable<RefacturacionManual> {
    return this.http.post<RefacturacionManual>(this.facturacionBaseUrl + '/RefacturacionV1/Despacho', dto)
  }


  getRefacturacionesPorFechaYEstado(estadoRefacturacion: number, fechaDesde: string, fechaHasta: string): Observable<RefacturacionFactura[]> {
    return this.http.get<RefacturacionFactura[]>(this.facturacionBaseUrl + '/RefacturacionV1/?estadoRefacturacion='
      + estadoRefacturacion + '&fechaDesde=' + fechaDesde + '&fechaHasta=' + fechaHasta + '&historial=' + true)
  }


  getHistorialRefacturacionesPorFechaYEstado(estadoRefacturacion: number, fechaDesde: string, fechaHasta: string): Observable<RefacturacionFactura[]> {
    return this.http.get<RefacturacionFactura[]>(this.facturacionBaseUrl + '/RefacturacionV1/?estadoRefacturacion='
      + estadoRefacturacion + '&fechaDesde=' + fechaDesde + '&fechaHasta=' + fechaHasta + '&historial=' + true)
  }

  getRefacturacionesPendientes(fechaDesde: string, fechaHasta: string): Observable<RefacturacionFactura[]> {
    return this.http.get<RefacturacionFactura[]>(this.facturacionBaseUrl + '/RefacturacionV1/?estadoRefacturacion='
      + 1 + '&fechaDesde=' + fechaDesde + '&fechaHasta=' + fechaHasta + '&historial=' + false)
  }

  getRefacturacionesRevisadas(fechaDesde: string, fechaHasta: string): Observable<RefacturacionFactura[]> {
    return this.http.get<RefacturacionFactura[]>(this.facturacionBaseUrl + '/RefacturacionV1/?estadoRefacturacion='
      + 2 + '&fechaDesde=' + fechaDesde + '&fechaHasta=' + fechaHasta + '&historial=' + false)
  }


  getCorreccionesDePrecios(fechaDesde: string, fechaHasta: string): Observable<RefacturacionFactura[]> {
    return this.http.get<RefacturacionFactura[]>(this.facturacionBaseUrl + '/RefacturacionV1/Motivo?motivo='
      + 3 + '&estadoRefacturacion='
      + 0 + '&fechaDesde=' + fechaDesde + '&fechaHasta=' + fechaHasta + '&historial=' + false)
  }

  crearNivelAprobacion(nivelAprobacion: NivelAprobacion): Observable<NivelAprobacion> {
    return this.http.post<NivelAprobacion>(this.nivelesUrl + '/nivelAprobacion', nivelAprobacion)
      .pipe(
        tap(_ => console.log('Creacion de nivel aprobacion'))
      );
  }

  getNivelesRefacturacionAprobacion(tipo: number): Observable<NivelAprobacion[]> {
    return this.http.get<NivelAprobacion[]>(this.nivelesUrl + "/NivelesAprobacion?tipoNivel=" + tipo)
  }

  getNuevoComprobante(id: string, esRefacturacion: boolean): Observable<Factura[]> {
    return this.http.get<Factura[]>(this.facturacionBaseUrl + "/RefacturacionV1/FacturasNuevas?refacturacionId=" + id
      + '&esRefacturacionTotal=' + esRefacturacion);
  }

  actualizarRefacturacion(dto: RefacturacionFactura): Observable<any> {
    return this.http.put(this.facturacionBaseUrl + '/RefacturacionV1', dto , {responseType: 'text'})
    .pipe(
      tap(_ => console.log('Refacturacion por correccion de precios actualizada'))
    );
  }

  getNivelesUsuario(username: string) {
    return this.http.get<UsuarioNiveles>(this.usuariosUrl + "/" + encodeURIComponent(username) + "/Niveles?tipoNivel=" + TipoNivelUsuario.Refacturacion)
      .pipe(
        tap(niveles => { this.nivelesUsuario = niveles; }
        ));
  }

  //https://facturacion-dev.aeroplantas.com.ar/api/FacturacionV1/SimularPrecio?codigoAeroplanta=AEP&numeroAeroplanta=0096&codigoProducto=205000
  simuladorPrecio(codAeroplanta:string, numAeroplanta:string, codProducto:string) {
    return this.http.get(this.facturacionBaseUrl + `/FacturacionV1/SimularPrecio?codigoAeroplanta=${codAeroplanta}&numeroAeroplanta=${numAeroplanta}&codigoProducto=${codProducto}`, {responseType: 'text'}).toPromise();
  }

  getNivelUsuarioLogueado() {
    return this.nivelesUsuario;
  }

  formatearComprobante(prefijo, num) {
    return prefijo + "-" + num;
  }

  obtenerStringSolicitador(){
    return '{"Email": "'+this.usuarioService.getEmailUsuario()+'" ,' + '"nombresCompletos": "'+this.usuarioService.getNombresCompletosUsuario()+'" ,' + '"dni": "'+this.usuarioService.getDniUsuario()+'" ,' + '"Id": "'+this.usuarioService.getIdUsuario()+'"}';
  }
}
