<ul class="nav nav-tabs justify-content-start">
  <!--<li  class="nav-item"><a
    class="nav-link" routerLink="Cliente" routerLinkActive="active"><b>Cliente</b></a>
  </li>-->
  <li  class="nav-item"><a
      class="nav-link" routerLink="Sistema" routerLinkActive="active"><b>Sistema</b></a>
  </li>
  <li  class="nav-item" *ngIf="authService.hasRole(TipoUsuario.OPERADORDIRECTO) === false" ><a
    class="nav-link" routerLink="abm-grupos" routerLinkActive="active"><b>Grupos</b></a>
</li>
</ul>
<router-outlet></router-outlet>
