<div class="modal-header bg-light">
    <h4 class="modal-title">Aerovale Nº: {{ aerovale.aerovalePrefijo | aerovalePrefijo }}{{aerovale.aerovaleNumero | aerovaleNumero }}</h4>   
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.close()">
            
        </button>
    </div>   
    <div class="modal-body">
        <p class="mb-1"> Cliente: {{ aerovale.cliente }}</p>
        <p *ngIf="aerovale.volumen != null"> Volumen: {{ aerovale.volumen | number : '0.0-0'}} LTS</p>
        <small> Fecha: {{ aerovale.fecha | date: "d'/'MM'/'y' 'hh:mm a" }}</small>
        <div style="text-align-last: end;">
            <button type="button" class="btn btn-primary" (click)="verAerovale( aerovale.aerovaleId )">
                <ng-container *ngIf="!obteniendoAerovale">
                    <i class="fa fa-eye" aria-hidden="true"></i>Ver Aerovale
                </ng-container>                
                <ng-container *ngIf="obteniendoAerovale">
                    <div class="spinner-border text-light spinner-border-sm" role="status"></div>
                    <span>Obteniendo aerovale...</span>
                </ng-container>                
            </button>
        </div>
    </div>
    <div class="modal-footer bg-light justify-content-center">
        <button [disabled]="this.marcando" class="btn btn-success me-2" (click)="marcandoConsumo(EstadoConsumo.Reconocido)">
            <span *ngIf="!reconocido">Reconocido</span>
            <div *ngIf="reconocido" class="d-flex align-items-center">
                <div class="spinner-border text-light spinner-border-sm" role="status"></div>
                <span>Marcando consumo...</span>
            </div>
        </button>        
        <button [disabled]="this.marcando" class="btn btn-danger me-2" (click)="marcandoConsumo(EstadoConsumo.Desconocido)">
            <span *ngIf="!desconocido">Desconocido</span>
            <div *ngIf="desconocido" class="d-flex align-items-center">
                <div class="spinner-border text-light spinner-border-sm" role="status"></div>
                <span>Marcando consumo...</span>
            </div>
        </button>        
        <button [disabled]="this.marcando" class="btn btn-secondary" (click)="cerrarModal()">Cancelar</button>
</div>