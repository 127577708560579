<nav class="navbar navbar-light bg-light navbar-expand-lg">
  <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <form class="form-inline my-2 my-lg-0 me-2">
      <input id="busquedaFormControl" type="text" class="form-control" [formControl]="busquedaFormControl"
        (input)="filtrarFacturas()" placeholder="Factura / Fecha / Cliente" />
    </form>
    <ul class="navbar-nav me-auto">
      <!-- desde -->
      <li class="nav-item me-2">
        <div class="input-group">
          <input type="date" [ngModel]="model" class="fechaFormControlDesdeHasta" placeholder="aaaa-mm-dd"
            (ngModelChange)="model = $event" name="dt" ngbDatepicker #d="ngbDatepicker" value="model"
            class="form-control form-control-rounded" #searchDate required readonly>
          <div class="input-group-append">
            <button id="botonDesde" type="button" class="input-group-btn btn btn-secondary" (click)="d.toggle()">
              <i class="fa fa-calendar" aria-hidden="true"></i></button>
          </div>
        </div>
      </li>
      <!-- hasta -->
      <li class="nav-item">
        <div class="input-group">
          <input type="date" class="fechaFormControlDesdeHasta" [ngModel]="model1" placeholder="aaaa-mm-dd" name="dp"
            value="model1" (ngModelChange)="model1 = $event" class="form-control form-control-rounded" ngbDatepicker
            #h="ngbDatepicker" #searchDate required readonly>
          <div class="input-group-append">
            <button id="botonHasta" type="button" class="input-group-btn btn btn-secondary" (click)="h.toggle()"><i
                class="fa fa-calendar" aria-hidden="true"></i></button>
          </div>
        </div>
      <li class="nav-item">
        <button (click)="buscarDesdeHasta()" class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2" type="submit"><i
            class="fa fa-search" aria-hidden="true"></i></button>
      </li>
      <li *ngIf="usuarioPuedeDelegar()" class="nav-item" style="float:right">
        <button [disabled]="!usuarioPuedeDelegar()" type="button" (click)="delegarNivelNativoRefacturacion()"
          class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2">
          Delegar Nivel Usuario</button>
      </li>
      <li class="nav-item">
        <div class="input-group">
          <button type="button" (click)="aprobarRefactura()" class="btn btn-success me-2"
            [disabled]="this.facturasSeleccionadas.length===0 || aceptandoRefacturacion">
            <span *ngIf="!aceptandoRefacturacion">Aceptar</span>
            <div *ngIf="aceptandoRefacturacion" class="d-flex align-items-center">
              <div class="spinner-border text-light spinner-border-sm" role="status"></div>
              <span>Aceptando...</span>
            </div>
          </button>
        </div>
      </li>

      <li class="nav-item">
        <div class="input-group">
          <button type="button" (click)="rechazarRefactura()" class="btn btn-danger"
            [disabled]="this.facturasSeleccionadas.length===0 || rechazandoRefacturacion">
            <span *ngIf="!rechazandoRefacturacion">Rechazar</span>
            <div *ngIf="rechazandoRefacturacion" class="d-flex align-items-center">
              <div class="spinner-border text-light spinner-border-sm" role="status"></div>
              <span>Rechazando...</span>
            </div>
          </button>
        </div>
      </li>
    </ul>
  </div>
</nav>
<div class="col col-md-12">
  <div class="spinner-container" *ngIf="this.obteniendoAerovales">
    <ngx-spinner name="spinnerGrafico" bdOpacity=0.3bd
      Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
      <p style="color: white">C a r g a n d o . . . </p>
    </ngx-spinner>    
  </div>
  
  <div class="abs-center" *ngIf="this.obteniendoAerovales===false && this.facturas.length==0">
    <ngb-alert type="warning"> No se encontraron facturas a aprobar registradas para el rango de fechas seleccionado.
    </ngb-alert>
  </div>

  <div class="abs-center" *ngIf="this.errorNiveles != null">
    <ngb-alert type="warning"> {{ this.errorNiveles }}
    </ngb-alert>
  </div>
  
  <div class="row mt-3" *ngIf="this.obteniendoAerovales===false && this.facturas.length>0">
    <div class="card-body">
      <div *ngIf="mensajeNivelUsuario != null">
        {{mensajeNivelUsuario}}
      </div>
      <table class="table table-striped table-hover" [formGroup]="tablaComprobantes"
        *ngIf="this.facturasFiltradas.length>0">
        <thead class="text-center">
          <td><b>Factura</b></td>
          <td><b>Tipo</b></td>
          <td><b>Fecha/Hora solicitud</b></td>
          <td><b>Cliente</b></td>
          <td><b>Importe</b></td>
          <td><b>Motivo</b></td>
          <td><b>Usuario solicitante</b></td>
          <td><b>Usuario revisador </b></td>
          <td><b>Adjunto</b></td>
          <td><b>Observacion</b></td>
          <td><b>Comparativas</b></td>
          <td><b>Selección</b></td>
        </thead>
        <tbody class="text-center letra">
          <tr formArrayName="aerovalesFiltrados" *ngFor="let s of this.facturasFiltradas let i = index">
            <td>{{ s.factura.prefijo | facturaPrefijo }}-{{ s.factura.numeroComprobante | facturaNumero }}</td>
            <td>{{ s.factura.tipoMovimiento | tipoMovimiento }}</td>
            <td>{{ s.fechaHora | date: "d'/'MM'/'y' 'hh:mm a" }}</td>
            <td>{{ s.factura.razonSocialCliente}}
            </td>
            <td>{{ s.factura.tipoMoneda | tipoMoneda }} {{s.factura.total | number:'0.2-2'}}</td>
            <td>{{this.obtenerMotivoRefacturacion(s.motivo)}}</td>
            <td>{{s.usuarioSolicitador}}</td>
            <td>{{s.usuarioRevisador}}</td>
            <td> <button *ngIf="s.pathAdjunto!=null" class="btn" placement="bottom" ngbTooltip="Descargar Adjunto"
                container="body">
                <a class="fa fa-download" [href]="s.pathAdjunto"></a>
              </button>
              <div *ngIf="s.pathAdjunto===null">--</div>
            </td>
            <td>
              <button *ngIf="s.detalle!=null" (click)="this.verObservaciones(s.detalle)" class="btn" placement="bottom"
                ngbTooltip="Ver Observaciones" container="body">
                <i class="fa fa-eye" aria-hidden="true"></i>
              </button>
              <div *ngIf="s.detalle===null">--</div>
            </td>
            <td> 
              <button class="btn" (click)="verComparativasFacturas(content,s, i)" ngbTooltip="Ver Factura/Refactura">
                <i *ngIf="!cargandoPrevisualizacion || cargandoPrevisualizacion && itemSeleccionado!=i" class="fa fa-eye" aria-hidden="true"></i>
                <div *ngIf="cargandoPrevisualizacion && itemSeleccionado==i" class="spinner-border text-primary spinner-border-sm" role="status"></div>
              </button> 
            </td>
            <td>
              <div class="form-check">
                <input type="checkbox" (change)="seleccionarFactura(s.id, $event)" class="custom-control-input"
                  id="customCheckAccion{{[i]}}{{[i]}}" />
                <label class="custom-control-label" for="customCheckAccion{{[i]}}{{[i]}}"></label>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <ng-template #content style="overflow-y:scroll">
    <div class="row">
      <div class="col-md-6" style="text-align:center">
        <b>Factura original</b>
      </div>
      <div class="col-md-6" style="text-align:center">
        <b>Nuevo Comprobante</b>
      </div>
    </div>
    <div class="row" id="print">
      <app-visor-comprobante [botones]="false" *ngIf="this.facturaObtenida!=null" class="col-md-6"
        [facturaObtenida]='this.facturaObtenida'></app-visor-comprobante>
      <app-previsualizacion-comprobante class="col-md-6" *ngIf="this.facturaCalculada!=null"
        [facturaCalculada]='this.facturaCalculada'></app-previsualizacion-comprobante>
    </div>
    <div class="col-4">
      <button styleSheetFile="assets/css/printRefacturacion.css,assets/css/bootstrap.min.css" printSectionId="print"
        useExistingCss="true" class="btn btn-sm btn-outline-secondary" printTitle="{{tituloFactura}}" ngxPrint>
        <i class="fa fa-print" aria-hidden="true"></i> Imprimir </button>
    </div>
  </ng-template>
</div>