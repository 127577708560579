export class AeroEspecialidadItem{  
  // id: string;
  nombreProducto: string;
  productoCodigo: string;
  cantidad: number;

  constructor(cantidad, nombre, codigo){
    // this.id = id;
    this.cantidad = cantidad;
    this.nombreProducto = nombre;
    this.productoCodigo = codigo;
  }
}
