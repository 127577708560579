import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { UploadFileService } from '../upload-file.service';
import { UrlApi } from 'src/app/shared/enums/urlApi';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';


@Component({
  selector: 'app-laboratorios-import',
  templateUrl: './laboratorios-import.component.html',
  styleUrls: ['./laboratorios-import.component.css']
})
export class LaboratoriosImportComponent implements OnInit {
  userFileName: string;
  profileForm: UntypedFormGroup;
  error: string;
  fileUpload = { status: '', message: '', filePath: '', length: 0 };
  archivoCargado: boolean = false;
  cargadoCorrectamente = false;
  cargando: boolean = false;

  constructor(private fb: UntypedFormBuilder, private fileUploadService: UploadFileService) { }

  ngOnInit() {
    this.profileForm = this.fb.group({
      name: [''],
      profile: [''],
      inputFile: ['']
    });
  }
  onSelectedFile(event) {
    if (event.target.files.length > 0) {
      this.reset(true);
      //this.fileUpload = { status: '', message: '', filePath: '', length: 0 };
      const file = event.target.files[0];
      this.profileForm.get('profile').setValue(file);
      this.profileForm.get('name').setValue(file.name);
      this.userFileName = file.name;
    }
  }

  onSubmit() {
    this.cargando = true;
    const formData = new FormData();
    formData.append('name', this.profileForm.get('name').value);
    formData.append('profile', this.profileForm.get('profile').value);

    this.fileUploadService.upload(formData, UrlApi.laboratorios).subscribe(
      res => {
        if (Array.isArray(res)) {
          this.cargando = false;
          this.cargadoCorrectamente = true;
        }
        this.fileUpload = res;
      },
      (err: ErrorModel) => { this.error = err.description; this.cargando = false; }
    );
  }

  /**
   * Limpiar pantalla
   * @param limpiarMensajes Determina si se limpia el div de mensajes
   */

  reset(limpiarMensajes: boolean = false, limpiarInput: boolean = false) {
    this.cargando = false;
    this.archivoCargado = true;
    this.error = '';
    this.cargadoCorrectamente = false;
    if (limpiarInput) {
      this.archivoCargado = false;
      this.userFileName = '';
      this.profileForm.get('profile').setValue('');
      this.profileForm.get('name').setValue('');
      this.profileForm.get('inputFile').setValue('');
    }
    if (limpiarMensajes)
      this.fileUpload = { status: '', message: '', filePath: '', length: 0 };

  }

}
