import { Component, OnInit, Input, LOCALE_ID, Inject, Output, EventEmitter } from '@angular/core';
import { Remito } from 'src/app/shared/models/facturacion/Remito';
import { FormGroup, FormControl } from '@angular/forms';
import { ComprobanteService } from '../../comprobante.service';
import { formatDate } from '@angular/common';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Cliente } from 'src/app/shared/models/cliente/Cliente';
import { RemitoEnCuenta } from 'src/app/shared/models/facturacion/RemitoEnCuenta';
import { RemitosFacturacionComponent } from '../remitos-facturacion/remitos-facturacion.component';

@Component({
  selector: 'app-remitos-seleccion',
  templateUrl: './remitos-seleccion.component.html',
  styleUrls: ['./remitos-seleccion.component.css']
})
export class RemitosSeleccionComponent implements OnInit {
  @Input() clienteSeleccionado: Cliente;
  @Input() aeroplantaFiltrada;
  @Input() fechaFiltrada: NgbDateStruct;
  @Input() productoFiltrado;
  @Input() seleccionado: boolean;
  fec: string;

  remitosPorCuenta: RemitoEnCuenta[] = [];
  // tablaRemitos = new FormGroup({
  //   checkRemito: new FormControl()
  // })

  obteniendoRemitos: boolean;
  errorRemitos: any;
  remitosSinFacturar: Remito[] = [];
  remitosSeleccionados: Remito[] = [];
  remitosSeleccionadosAux: Remito[] = [];
  constructor(private facturacionService: ComprobanteService, @Inject(LOCALE_ID) public locale: string, public modalService: NgbModal) { }

  ngOnInit() {
    this.obteniendoRemitos = true;
    if (this.productoFiltrado == 'null') {
      this.productoFiltrado = null;
    }
    this.getRemitos();
    // if(this.productoFiltrado != null){
    //   this.selectProducto();
    // }
  }

  ngOnChanges(): void {
    if (this.seleccionado || this.productoFiltrado != null) {
      this.ngOnInit();
    }
  }

  getRemitos() {
    // this.obteniendoRemitos = true;
    this.remitosSeleccionados = [];
    this.remitosSeleccionadosAux = [];
    this.remitosSinFacturar = [];
    this.remitosPorCuenta = [];
    this.fec = this.formatDate(this.fechaFiltrada.year, this.fechaFiltrada.month, this.fechaFiltrada.day, 23, 59);
    if (this.aeroplantaFiltrada == null || this.aeroplantaFiltrada == 'null') {
      for (let i = 0; this.clienteSeleccionado.cuentas.length > i; i++) {
        this.obteniendoRemitos = true;
        this.remitosPorCuenta.push(new RemitoEnCuenta());
        this.remitosPorCuenta[i].remitos = [];
        this.facturacionService.obtenerRemitosSinFacturarPorSGC(this.clienteSeleccionado.cuentas[i].ctA_SGC, this.fec)
          .subscribe(result => {
            this.obteniendoRemitos = true;
            this.remitosPorCuenta[i].cTA_SGC = this.clienteSeleccionado.cuentas[i].ctA_SGC;
            for (let j = 0; result.length > j; j++) {
              if (result[j].tipoPago != 1) {
                this.remitosPorCuenta[i].remitos.push(result[j]);
              }
            }
            if (this.remitosPorCuenta.length > 0) {
              this.remitosPorCuenta[i].remitos = this.remitosPorCuenta[i].remitos.sort((a, b) => { if (a.fechaHoraCreacion > b.fechaHoraCreacion) { return 1 } else { return -1 } });
            }
            // if (this.clienteSeleccionado.cuentas.length - 1 == i) {
            this.selectProducto();
            this.obteniendoRemitos = false;
            // }
          });
      };
    } else {
      for (let i = 0; this.clienteSeleccionado.cuentas.length > i; i++) {
        this.obteniendoRemitos = true;
        this.remitosPorCuenta.push(new RemitoEnCuenta());
        this.remitosPorCuenta[i].remitos = [];
        this.facturacionService.obtenerRemitosSinFacturarPorSGCYAeroplanta(this.clienteSeleccionado.cuentas[i].ctA_SGC, this.aeroplantaFiltrada, this.fec)
          .subscribe(result => {
            this.obteniendoRemitos = true;
            this.remitosPorCuenta[i].cTA_SGC = this.clienteSeleccionado.cuentas[i].ctA_SGC;
            for (let j = 0; result.length > j; j++) {
              this.remitosPorCuenta[i].remitos.push(result[j]);
            }
            if (this.remitosPorCuenta.length > 0) {
              this.remitosPorCuenta[i].remitos = this.remitosPorCuenta[i].remitos.sort((a, b) => { if (a.fechaHoraCreacion > b.fechaHoraCreacion) { return 1 } else { return -1 } });
            }
            // if (this.clienteSeleccionado.cuentas.length - 1 == i) {
            this.selectProducto();
            this.obteniendoRemitos = false;
            // }
          });
      };
    }
  }

  agregarRemito(i: number, j: number, event) {
    var y: number = 0;
    if (event.target.checked === true) {
      this.remitosSeleccionados.push(this.remitosPorCuenta[j].remitos[i]);
      this.remitosPorCuenta[j].remitos[i].checked = true;
      this.chequearMismaCuenta(this.remitosPorCuenta[j].remitos[i], y);
    }
    if (event.target.checked === false && y !== -1) {
      y = this.remitosSeleccionados.indexOf(this.remitosPorCuenta[j].remitos[i]);
      this.remitosSeleccionados.splice(y, 1);
      this.remitosPorCuenta[j].remitos[i].checked = false;
    }
  }

  chequearMismaCuenta(remito: Remito, y: number) {
    this.remitosSeleccionadosAux = this.remitosSeleccionados;
    for (let i = 0; this.remitosPorCuenta.length > i; i++) {
      for (let j = 0; this.remitosPorCuenta[i].remitos.length > j; j++) {
        if (this.remitosPorCuenta[i].remitos[j].clienteCtaSGC != remito.clienteCtaSGC) {
          this.remitosPorCuenta[i].remitos[j].checked = false;
        }
      }
    }

    for (let i = 0; this.remitosPorCuenta.length > i; i++) {
      for (let j = 0; this.remitosPorCuenta[i].remitos.length > j; j++) {
        if (this.remitosPorCuenta[i].remitos[j].checked == false) {
          y = this.remitosSeleccionados.indexOf(this.remitosPorCuenta[i].remitos[j]);
          if (y !== -1) {
            this.remitosSeleccionados.splice(y, 1);
          }
        }
      }
    }
  }

  formatDate(year: number, month: number, day: number, hour: number, minute: number): string {
    return formatDate(new Date(year, month - 1, day, hour, minute, 0), 'y-MM-ddTHH:mm:ss', this.locale);
  }

  mostrarRemitosAFacturar() {
    let modalRef = this.modalService.open(RemitosFacturacionComponent, { centered: true, size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.remitosSeleccionados = this.remitosSeleccionados;
    modalRef.result.then(result => {
      if (result == true) {
        this.getRemitos();
      }
    });
  }

  selectProducto() {
    if ((this.productoFiltrado != null)) {
      this.obteniendoRemitos = true;
      var aux: RemitoEnCuenta[] = this.remitosPorCuenta;
      this.remitosPorCuenta = [];
      for (let i = 0; aux.length > i; i++) {
        this.remitosPorCuenta.push(new RemitoEnCuenta());
        this.remitosPorCuenta[i].remitos = [];
        if (this.remitosPorCuenta[i].cTA_SGC == null || this.remitosPorCuenta[i].cTA_SGC == '') {
          this.remitosPorCuenta[i].cTA_SGC = aux[i].cTA_SGC;
        }
        for (let j = 0; aux[i].remitos.length > j; j++) {
          for (let y = 0; aux[i].remitos[j].detalles.length > y; y++) {
            if (aux[i].remitos[j].detalles[y].codigoProducto == this.productoFiltrado) {
              this.remitosPorCuenta[i].remitos.push(aux[i].remitos[j]);
            }
          }
        }
      }
    }
    this.obteniendoRemitos = false;
  }

}
