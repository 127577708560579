<div class="modal-header bg-light">
  <h4 class="modal-title">Direccion Fiscal del cliente</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismissAll()"></button>
</div>
<div class="modal-body">
  <form [formGroup]="direccionForm">
    <div class="row">
      <!--CALLE-->
      <div class="mb-3 col-12">
        <div class="mb-3 row">
          <div class="col-4">
            <label class="col-form-label" for="calleFormControl">*Direccion:</label>
          </div>
          <div class="col-8">
            <input class="form-control" id="calleFormControl" formControlName="calleFormControl" maxlength="50"/>
            <div *ngIf="calle.invalid && calle.errors.required && (calle.dirty || calle.touched)"
              class="alert alert-danger">
              Requerido
            </div>
          </div>
        </div>
      </div>
      <!--NUMERO-->
      <div class="mb-3 col-12">
        <div class="mb-3 row">
          <div class="col-4">
            <label class="col-form-label" for="numeroFormControl">*Numero:</label>
          </div>
          <div class="col-3">
            <input class="form-control" id="numeroFormControl" formControlName="numeroFormControl" (keypress)="this.onlyDecimalPositivo($event)" maxlength="5"/>
            <div *ngIf="numero.invalid && numero.errors.required && (numero.dirty || numero.touched)"
              class="alert alert-danger">
              Requerido
            </div>
          </div>
          <div class="col-5"></div>
        </div>
      </div>
      <!--PISO DEPTO-->
      <div class="mb-3 col-12">
        <div class="mb-3 row">
          <div class="col-4">
            <label class="col-form-label" for="pisoFormControl">Piso:</label>
          </div>
          <div class="col-3">
            <input class="form-control" id="pisoFormControl" formControlName="pisoFormControl" maxlength="2"/>
         </div>
          <div class="col-2">
            <label class="col-form-label" for="deptoFormControl">Depto:</label>
          </div>
          <div class="col-3">
            <input class="form-control" id="deptoFormControl" formControlName="deptoFormControl" maxlength="2"/>
         </div>
        </div>
      </div>
      
      <!--PAIS-->
      <div class="mb-3 col-12">
        <div class="mb-3 row">
          <div class="col-4">
            <label class="col-form-label" for="paisFormControl">País:</label>
          </div>
          <div class="col-8">
            <input id="paisFormControl" type="text" class="form-control" formControlName="paisFormControl"
              placeholder="Seleccione una opcion..." [inputFormatter]="formatterPais" [(ngModel)]="modelPais"
              [resultFormatter]="formatterPais" [ngbTypeahead]="searchPais" (selectItem)="selectPais($event)" 
              [readonly]="campoPaisReadonly"/>
            <loading-text-spinner *ngIf="this.cargandoPaises" message="Obteniendo paises..." color="primary">
            </loading-text-spinner>
          </div>
        </div>
      </div>
      <!--PROVINCIA-->
      <div class="mb-3 col-12">
        <div class="mb-3 row">
          <div class="col-4">
            <label class="col-form-label" for="provinciaFormControl">*Provincia:</label>
          </div>
          <div class="col-8">
            <input id="provinciaFormControl" type="text" class="form-control" formControlName="provinciaFormControl"
              placeholder="Seleccione una opcion..." [inputFormatter]="formatterProvincia" [(ngModel)]="modelProvincia"
              [resultFormatter]="formatterProvincia" [ngbTypeahead]="searchProvincia"
              (selectItem)="selectProvincia($event)"/>
            <div *ngIf="provincia.invalid && provincia.errors.required && (provincia.dirty || provincia.touched)"
              class="alert alert-danger">
              Requerido
            </div>
            <loading-text-spinner *ngIf="this.cargandoProvincias" message="Obteniendo provincias..." color="primary">
            </loading-text-spinner>
          </div>
        </div>
      </div>
      <!--CODIGO POSTAL-->
      <div class="mb-3 col-12">
        <div class="mb-3 row">
          <div class="col-4">
            <label class="col-form-label" for="codigoPostalFormControl">*Ciudad:</label>
          </div>
          <div class="col-8">
            <input class="form-control" id="codigoPostalFormControl" formControlName="codigoPostalFormControl"
              placeholder="Seleccione una opcion..." [inputFormatter]="formatterCiudad" [(ngModel)]="modelCiudad"
              [resultFormatter]="formatterCiudad" [ngbTypeahead]="searchCiudad" (selectItem)="selectCiudades($event)"/>
            <div
              *ngIf="codigoPostal.invalid && codigoPostal.errors.required && (codigoPostal.dirty || codigoPostal.touched)"
              class="alert alert-danger">
              Requerido
            </div>
            <loading-text-spinner *ngIf="this.cargandoCiudades" message="Obteniendo ciudades..." color="primary">
            </loading-text-spinner>
          </div>
        </div>
      </div>

    </div>
  </form>
  <small class="text text-danger">*Campos obigatorios.</small>

  <div *ngIf="this.errorDatos != null" style="color:red">
    {{this.errorDatos}}
  </div>
</div>
<div class="modal-footer bg-light justify-content-center">
  <button class="btn btn-primary me-2" (click)="guardarDireccion()" [disabled]="guardando || !this.usuarioModificaCampos()">
    <span *ngIf="!guardando">Guardar</span>
    <div *ngIf="guardando" class="d-flex align-items-center">
      <div class="spinner-border text-light spinner-border-sm" role="status"></div>
      <span>Guardando...</span>
    </div>
  </button>
  <button class="btn btn-secondary" (click)="activeModal.dismissAll()">Cancelar</button>
</div>
