import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PedidoService } from 'src/app/despacho/pedido.service';
import { Cuenta } from 'src/app/shared/models/cliente/Cuenta';
import { AerolineaDTO } from 'src/app/shared/models/despacho/AerolineaDTO';
import { AerolineaDestinoDTO } from 'src/app/shared/models/despacho/AerolineaDestinoDTO';
import { MessageService } from 'src/app/shell/message.service';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AeropuertoService } from 'src/app/despacho/aeropuerto.service';
import { Aeropuerto } from 'src/app/shared/models/despacho/Aeropuerto';
import { AerolineaEncabezadoDTO } from 'src/app/shared/models/despacho/AerolineaEncabezadoDTO';

@Component({
  selector: 'app-modal-destinos-tams',
  templateUrl: './modal-destinos-tams.component.html',
  styleUrls: ['./modal-destinos-tams.component.scss']
})
export class ModalDestinosTamsComponent implements OnInit {

  @Input() cuentas: Cuenta[];
  @Input() destinos: AerolineaDestinoDTO[];
  @Input() destinosOtrasAeroplantas: AerolineaDestinoDTO[];
  @Input() aerolineaSeleccionada: AerolineaEncabezadoDTO;
  @Input() codAeroplanta: string;
  @Input() nombreAeroplanta: string;

  listaAeropuertos: Aeropuerto[];   

  destinoForm: UntypedFormGroup;
  guardandoDatos: boolean = false;
  cargandoAeropuertos: boolean = false;

  modal: NgbModal;

  constructor(    
    modal: NgbModal,
    private formBuilder: UntypedFormBuilder,
    private pedidoService: PedidoService,
    private messageService: MessageService,
    private aeropuertoService: AeropuertoService) { 
      this.modal = modal;
    }

  ngOnInit(): void {    
    this.crearFormulario();
    this.getAeropuertos();
  }

  crearFormulario() {
    this.destinoForm = this.formBuilder.group({
      codigoFormControl: ['', Validators.required],
      cuentaFormControl: [null]
    });
  }

  /** servicios */

  getAeropuertos() {
    this.cargandoAeropuertos = true;
    this.aeropuertoService.getAeropuertos()
      .subscribe(res => {
        this.listaAeropuertos = res;        
        this.cargandoAeropuertos = false;
      },()=>{
        this.cargandoAeropuertos = false;
      });
  }  

  /** aeropuertos */
  
  formatterAeropuerto = (op: { codigoAeropuerto: string, nombre: string }) => {
    return (op != null && op.codigoAeropuerto != null) ? `${op.codigoAeropuerto} - ${op.nombre}` : "";
  } 

  searchAeropuerto = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    map(term => this.listaAeropuertos == null || term.length < 2 || this.cargandoAeropuertos ? [] :
      this.listaAeropuertos.filter(op => this.containsString(this.codigoFormControl.value, op.codigoAeropuerto)).slice(0, 10))
  )

  containsString(searchValue: string, ...searchIn: string[]): boolean {
    const concat = searchIn.toString();
    return concat.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
  }

  selectAeropuerto(event: any): void {
    this.codigoFormControl.setValue(event.item);
  }

  confirmAeropuerto() {    
    if (typeof (this.codigoFormControl.value) === 'string') {
      this.messageService.showErrorMessage("El código destino ingresado no es válido.")
      this.codigoFormControl.setValue('');
    }
  }

  yaExisteDestino(codAero:string) {
    return this.destinos.find( d => d.codigoAeropuerto == codAero);
  }

  guardarDestinos() {
    
    if(!this.destinoForm.valid){
      this.messageService.showErrorLongMessage("Revise los campos incompletos");
      return;
    }

    if(this.yaExisteDestino(this.codigoFormControl.value.codigoAeropuerto)) {
      this.messageService.showErrorLongMessage("El Aeropuerto ingresado ya se encuentra cargado como destinos.");
      return;
    }

    this.guardandoDatos = true;

    // armo el destino
    let destino = new AerolineaDestinoDTO;
    destino.codigoAeroplanta = this.codAeroplanta;    
    destino.cuenta = this.cuentaFormControl.value;
    destino.codigoAeropuerto = this.codigoFormControl.value.codigoAeropuerto;
    this.destinos.push(destino);

    // armo aerolinea y le paso el destino nuevo + lo que ya tenia
    let aerolineaDTO = new AerolineaDTO;
    aerolineaDTO.id = this.aerolineaSeleccionada.id;
    aerolineaDTO.cuit = this.aerolineaSeleccionada.cuit;
    aerolineaDTO.razonSocial = this.aerolineaSeleccionada.razonSocial;
    aerolineaDTO.codigoAerolinea = this.aerolineaSeleccionada.codigoAerolinea;
    aerolineaDTO.cuentaNacional = this.aerolineaSeleccionada.cuentaNacional;
    aerolineaDTO.cuentaInternacional = this.aerolineaSeleccionada.cuentaInternacional;
    aerolineaDTO.destinos = this.destinosOtrasAeroplantas.concat(this.destinos);
    
    this.pedidoService.putAerolineas(this.aerolineaSeleccionada.id, aerolineaDTO)
    .subscribe(result => {
      console.log('Entro modificar ' + result);
      this.messageService.showSuccessMessage('Destino agregado con éxito.');
      this.guardandoDatos = false;
      this.modal.dismissAll();
    }, (err: ErrorModel) => {       
      this.messageService.showErrorLongMessage(err.description);
      this.guardandoDatos = false;
      this.modal.dismissAll();
    });    
  }

  /** getters */

  get codigoFormControl() {
    return this.destinoForm.get('codigoFormControl');
  }

  get cuentaFormControl() {
    return this.destinoForm.get('cuentaFormControl');
  }
  
}
