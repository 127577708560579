import { DecimalPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { UsuarioService } from 'src/app/account/usuario.service';
import { ParametrosImpositivosService } from 'src/app/facturacion/parametros-impositivos.service';
import { CondicionVentaFactura } from 'src/app/shared/enums/condicionVentaFactura';
import { Balance7ActaDocumentosDTO } from 'src/app/shared/models/cierreTurno/Balance7ActaDocumentosDTO';
import { Balance7ActaDTO } from 'src/app/shared/models/cierreTurno/FechaDto';
import { TipoMovimiento } from 'src/app/shared/models/facturacion/TipoMovimiento';
import { CierreTurnoService } from '../../cierre-turno.service';
import { Localidad } from 'src/app/shared/models/despacho/Localidad';
@Component({
  selector: 'app-generar-acta',
  templateUrl: './generar-acta.component.html',
  styleUrls: ['./generar-acta.component.scss'],
  providers: [DecimalPipe]
})
export class GenerarActaComponent implements OnInit {

  @Input() datosGeneralesActa: Balance7ActaDTO[];
  @Input() datosFacturacionActa: Balance7ActaDocumentosDTO[];
  @Input() mes: number;
  @Input() anio: number;

  obteniendoDatos: boolean ;

  nombreAeroplanta: string;
  fechaHoraActual: Date;
  nombreCiudad: string;

  constructor(private spinner: NgxSpinnerService,
              private usuarioService: UsuarioService,
              private decimalPipe: DecimalPipe,
              private parametroService: ParametrosImpositivosService,
              ) { }

  ngOnInit(): void {
    this.nombreAeroplanta = this.usuarioService.getAeroplantaActual().nombre;

    this.obtenerCiudadAeroplanta();

    this.fechaHoraActual = new Date();
  }

  obtenerCiudadAeroplanta(){
    var ciudadId = this.usuarioService.getAeroplantaActual().ciudadId;
    
    this.mostrarLoading();

    this.parametroService.obtenerLocalidades()
    .subscribe(localidades => {
      var localidad = localidades.filter(l => l.id.toUpperCase() == ciudadId.toUpperCase())[0];

      this.nombreCiudad = localidad.nombre;
      
      this.ocultarLoading();
    }, () => {this.ocultarLoading();});
  }

  getDescripcionUnidadMedida(unMed: number){
    var descripcion = "";

    if(unMed == 0){
      descripcion = "Unidades";
    } else if(unMed == 1 ){
      descripcion = "Litros";
    }    else if(unMed == 2 ){
      descripcion = "Kilogramos";
    } else if(unMed == 3 ){
      descripcion = "Libras";
    } else if(unMed == 4 ){
      descripcion = "Galon";
    }
    return descripcion;
  }

  getDescripcionComprobante(dato: Balance7ActaDocumentosDTO){
    var descripcion = "";

    if(dato.tipoMovimiento == TipoMovimiento.FAA){
      descripcion += "Factura tipo 'A' ";
    }else if(dato.tipoMovimiento == TipoMovimiento.FAB){
      descripcion += "Factura tipo 'B' ";
    }else if(dato.tipoMovimiento == TipoMovimiento.NCA){
      descripcion += "Nota Credito tipo 'A' ";
    }else if(dato.tipoMovimiento == TipoMovimiento.NCB){
      descripcion += "Nota Credito tipo 'B' ";
    }else if(dato.tipoMovimiento == TipoMovimiento.NDA){
      descripcion += "Nota Debito tipo 'A' ";
    }else if(dato.tipoMovimiento == TipoMovimiento.NDB){
      descripcion += "Nota Debito tipo 'B' ";
    }else{
      descripcion += "Documento ";
    }

    if(dato.condicionVenta == CondicionVentaFactura.contado){
      descripcion += "Contado";
    }else{
      descripcion += "Cuenta Corriente"
    }

    return descripcion;
  }

  getNumeroCompletoComprobante(dato: Balance7ActaDocumentosDTO){
    return dato.prefijo + "-" + dato.proximoNumero;
  }

  getNombreCiudad(){
    return this.nombreCiudad;
  }

  calcularNumeroMes(mes: number){
    if(mes > 12){
      return mes - 12;
    }else{
      return mes;
    }
  }

  calcularProximoCierre(mes: number, anio: number){
    if(mes > 12){
      anio++;
    }

    return anio;
  }


  calculaUltimoDiaMes(){
    switch(this.mes){
      case 1: case 3: case 5: case 7: case 8: case 10: case 12:
        return 31;      
      case 2:
        return 28;
      default:
        return 30;
    }
  }

  print(): void {
    var divToPrint = document.getElementById('print');    
    var htmlToPrint = '' +
    '<link rel="stylesheet" type="text/css" href="assets/css/bootstrap.min.css">' +
    '<link rel="stylesheet" type="text/css" href="assets/css/print.css">' +
    '<style type="text/css">' +
      '#print{' +
      'width: 800px;' +
      'padding: 2em 5em;' +
      'border: 1px solid #000;' +
      '}' +
      '' +
      'table{' +
      'width: 90%;' +
      'margin-left: 10%;' +
      '}' +
      '' +
      'td.primera{' +
      'width: 65%;' +
      '}' +
      '' +
      'td.segunda{' +
      'width: 35%;' +
      '}' +
      '</style>';
    htmlToPrint += divToPrint.outerHTML;
    let newWin = window.open("");
    newWin.document.write(htmlToPrint);
    newWin.print();
    newWin.close();
  }

  mostrarLoading(){
    this.obteniendoDatos = true;
    this.spinner.show('spinnerActa');
  }

  ocultarLoading(){
    this.obteniendoDatos = false;
    this.spinner.hide('spinnerActa');
  }
}
