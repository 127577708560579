import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbCalendar, NgbDateStruct, NgbModal, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ProductoService } from 'src/app/abm/producto/producto.service';
import { ClienteService } from 'src/app/cliente/cliente.service';
import { Producto } from 'src/app/shared/models/abm/Producto';
import { Aeroplanta } from 'src/app/shared/models/despacho/Aeroplanta';
import { BonificacionRecargo } from 'src/app/shared/models/precios/BonificacionyRecargo';
import { Cliente } from 'src/app/shared/models/precios/Cliente';
import { ProductoEncabezado } from 'src/app/shared/models/precios/ProductoEncabezado';
import { MessageService } from 'src/app/shell/message.service';
import { PreciosService } from '../../precios.service';

@Component({
  selector: 'app-modal-nueva-bor',
  templateUrl: './modal-nueva-bor.component.html',
  styleUrls: ['./modal-nueva-bor.component.css']
})
export class ModalNuevaBorComponent implements OnInit {

  @ViewChild('fechaHora', {static: true}) fechaHoraElement: ElementRef;
  @ViewChild('fechaHoraFin', {static: true}) fechaHoraFinEl: ElementRef;
  @Input() seleccionadaBoR: BonificacionRecargo;

  guardadoBoR: boolean = false;




  aeroplantas: Aeroplanta[];
  productos: ProductoEncabezado[] = [];
  clientes: any[];

  listaDeAeroplantasSeleccionadas: Aeroplanta[] = [];
  listaDeClientesSeleccionados: Cliente[] = [];
  listaDeProductosSeleccionados: Producto[] = [];

  modelCliente: any;
  modelAeroplanta: any;
  model: NgbDateStruct;
  modelVuelo: NgbDateStruct;
  modelProducto: any;


  cargandoProductos: boolean = false;
  cargandoClientes: boolean = false;
  cargandoAeroplantas: boolean = false;
  errorProductos: boolean = false;
  errorClientes: boolean;
  errorAeroplantas: boolean;

  editando: boolean;

  nuevoBoR: BonificacionRecargo;


  form = new UntypedFormGroup({
    clienteFormControl: new UntypedFormControl('', Validators.required),
    aeroplantaFormControl: new UntypedFormControl('', Validators.required),
    productoFormControl: new UntypedFormControl('', Validators.required),
    tipoFormControl: new UntypedFormControl('', Validators.required),
    valorFormControl: new UntypedFormControl('', Validators.required),
    fechaFinFormControl: new UntypedFormControl('', Validators.required),
    fechaFormControl: new UntypedFormControl('', Validators.required),
    motivoFormControl: new UntypedFormControl('', Validators.required)
  });
  constructor(public activeModal: NgbActiveModal, private clienteService: ClienteService, private productoService: ProductoService,
    private calendar: NgbCalendar, private precioService: PreciosService, private messageService: MessageService,
    private changeDetector: ChangeDetectorRef) { }

  // hooks

  ngOnInit() {
    if (this.seleccionadaBoR == null) {

      this.limpiarForm();
      this.editando = false;
    } else {
      this.editando = true;



      this.valor.setValue(this.seleccionadaBoR.valor);
      this.motivo.setValue(this.seleccionadaBoR.motivo);
      let fechaInit = new Date(this.seleccionadaBoR.fechaDesde);
      this.model = { day: fechaInit.getUTCDate(), month: fechaInit.getUTCMonth() + 1, year: fechaInit.getFullYear() };
      let fechaFinal = new Date(this.seleccionadaBoR.fechaHasta);
      this.modelVuelo = { day: fechaFinal.getUTCDate(), month: fechaFinal.getUTCMonth() + 1, year: fechaFinal.getFullYear() };
      this.tipo.setValue(this.seleccionadaBoR.tipo);
    }
    this.getTodosLosClientes();
    this.getTodosLosProductos();
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  // llamadas al service


  getTodosLosClientes() {
    this.cargandoClientes = true;
    this.clientes = [];
    this.clienteService.getClientesEncabezado()
      .subscribe(result => {
        this.clientes = (result as unknown as Cliente[]);

        if (this.editando) {
          for (let a of this.clientes) {
            if (a.ctA_SGC == this.seleccionadaBoR.ctA_SGC) {
              this.clienteFormControl.setValue(a);
            }
          }
        }
        this.cargandoClientes = false;
      }, () => this.errorClientes = true);
  }

  getTodasLasAeroplantas() {
    this.cargandoAeroplantas = true;
    this.aeroplantas = [];
    this.precioService.obtenerTodasLasAeroplantas()
      .subscribe(result => {
        this.aeroplantas = result;
        if (this.editando) {
          for (let b of this.aeroplantas) {
            if (b.codigoAeroplanta == this.seleccionadaBoR.codigoAeroplanta) {
              this.aeroplantaFormControl.setValue(b);
            }
          }
        }
        this.cargandoAeroplantas = false;
      }, () => this.errorAeroplantas = true);
  }

  getTodosLosProductos() {
    this.cargandoProductos = true;
    this.cargandoAeroplantas = true;
    this.productos = [];
    this.productoService.obtenerProductos()
      .subscribe(result => {
        this.productos = result;
        if (this.editando) {
          for (let c of this.productos) {
            if (c.codigoProducto == this.seleccionadaBoR.codigoProducto) {
              this.productoFormControl.setValue(c);
            }
          }
        }
        this.cargandoProductos = false;
        this.getTodasLasAeroplantas();
      }, () => this.errorProductos = true);
  }

  guardarBoR() {
    console.log(JSON.stringify(this.seleccionadaBoR))
    this.guardadoBoR = true;
    this.nuevoBoR = new BonificacionRecargo();
    if (this.camposCompletos()) {

      if (!this.editando) {
        this.nuevoBoR.activo = 1;
        // listas multiseleccion
        this.nuevoBoR.clientes = this.convertirListaDeClientes();
        this.nuevoBoR.codigosAeroplantas = this.convertirListaDeAeroplantasEnListaDeString();
        this.nuevoBoR.productos = this.listaDeProductosSeleccionados;
      } else { // si estoy editando

        // listas multiseleccion
        this.nuevoBoR.clientes = this.seleccionadaBoR.clientes;
        this.nuevoBoR.codigosAeroplantas = this.seleccionadaBoR.codigosAeroplantas;
        this.nuevoBoR.productos = this.seleccionadaBoR.productos;

        this.nuevoBoR.activo = this.seleccionadaBoR.activo;
        this.nuevoBoR.estado = this.seleccionadaBoR.estado;
        this.nuevoBoR.solicitadoPor = this.seleccionadaBoR.solicitadoPor;
        this.nuevoBoR.id = this.seleccionadaBoR.id;
      }
      this.nuevoBoR.motivo = this.motivo.value;
      this.nuevoBoR.tipo = parseInt(this.tipo.value);
      this.nuevoBoR.valor = parseInt(this.valor.value);
      this.nuevoBoR.fechaDesde = this.formatDate(this.fecha.value.year, this.fecha.value.month,
        this.fecha.value.day, 0, 0);
      this.nuevoBoR.fechaHasta = this.formatDate(this.fechaFin.value.year, this.fechaFin.value.month,
        this.fechaFin.value.day, 23, 59);
      if (!this.editando && this.listaDeAeroplantasSeleccionadas.length > 0 && this.listaDeClientesSeleccionados.length > 0
        && this.listaDeProductosSeleccionados.length > 0) {
        this.precioService.postBonificacionesyRecargos(this.nuevoBoR)
          .subscribe(result => {
            this.activeModal.close(result);
            this.emitirCartelAgregacion(this.nuevoBoR.tipo);
            this.guardadoBoR = false;
          }, () => {
            this.messageService.showErrorMessage('Hubo un error al crear la bonificación o recargo, por favor reintente nuevamente.');
            this.guardadoBoR = false;
          });
      }
      if (this.editando  && this.listaDeAeroplantasSeleccionadas.length > 0 && this.listaDeClientesSeleccionados.length > 0
        && this.listaDeProductosSeleccionados.length > 0) {
        this.precioService.putBonificacionesyRecargos(this.nuevoBoR)
          .subscribe(result => {
            this.activeModal.close(result);
            this.emitirCartelEditado(this.nuevoBoR.tipo)
            this.guardadoBoR = false;
            this.seleccionadaBoR=null;
          }, () => {
            this.messageService.showErrorMessage('Hubo un error al editar la bonificación o recargo, por favor reintente nuevamente.');
            this.guardadoBoR = false;
            this.seleccionadaBoR=null;
          });
      }
    } else {
      this.guardadoBoR = false;
      this.messageService.showErrorMessage('Por favor complete todos los campos.');
    }
  }




  // form

  /**
   * Convierte una NgbDateStruct y una NgbTimeStruct en un Date
   */
   dateFromDateTimeStruct(date: NgbDateStruct): Date {
    if (date == null)
      return null;
    let dateReturn = new Date();
    dateReturn.setDate(date.day);
    dateReturn.setMonth(date.month - 1);
    dateReturn.setFullYear(date.year);
    return dateReturn;
  }

  convertirListaDeAeroplantasEnListaDeString() {
    let listaResultado: string[] = [];
    this.listaDeAeroplantasSeleccionadas.forEach(e => listaResultado.push(e.codigoAeroplanta));
    return listaResultado;
  }

  convertirListaDeClientes() {
    let listaResultado: Cliente[] = [];

    for (let entry of this.listaDeClientesSeleccionados) {
      let agregar: Cliente = new Cliente();
      agregar.cta_SGC = entry.ctaSap;
      agregar.id = entry.id;
      agregar.razonSocial = entry.razonSocial;
      listaResultado.push(agregar);
    }
    return listaResultado;
  }



  emitirCartelAgregacion(tipo: number) {
    let cartelActivo: string = "cread";
    if (tipo === 0) {
      cartelActivo = cartelActivo.concat('a');
      this.messageService.showSuccessLongMessage('La bonificación fue ' + cartelActivo + ' con exito.');
    }
    else {
      cartelActivo = cartelActivo.concat('o');
      this.messageService.showSuccessLongMessage('El recargo fue ' + cartelActivo + ' con exito.');
    }
  }

  emitirCartelEditado(tipo: number) {
    let cartelEdicion: string = "editad";
    if (tipo === 0) {
      cartelEdicion = cartelEdicion.concat('a');
      this.messageService.showSuccessLongMessage('La bonificación fue ' + cartelEdicion + ' con exito.');
    }
    else {
      cartelEdicion = cartelEdicion.concat('o');
      this.messageService.showSuccessLongMessage('El recargo fue ' + cartelEdicion + ' con exito.');
    }
  }

  limpiarForm() {
    this.clienteFormControl.setValue(null);
    this.aeroplantaFormControl.setValue(null);
    this.productoFormControl.setValue(null);
    this.valor.setValue(0);
    this.tipo.setValue(0);
    this.modelVuelo = this.calendar.getToday();
    this.model = this.calendar.getToday();
    this.motivo.setValue('');
  }

  formatDate(year, month, day, hour, minute): string {
    return year + '-' + month.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + '-' + day.toLocaleString(undefined, { minimumIntegerDigits: 2 })
      + 'T' + hour.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + minute.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':00';
  }

  asignarCliente() {
    let esta: number = this.listaDeClientesSeleccionados.indexOf(this.clienteFormControl.value)
    if (esta === -1 && (this.clienteFormControl.value != '' || this.clienteFormControl.value != null)) {
      this.listaDeClientesSeleccionados.push(this.clienteFormControl.value);
    }
  }

  desasignarCliente(index: number) {
    if (index !== -1) {
      this.listaDeClientesSeleccionados.splice(index, 1);
    }
  }

  asignarAeroplanta() {
    let esta: number = this.listaDeAeroplantasSeleccionadas.indexOf(this.aeroplantaFormControl.value)
    if (esta === -1 && (this.aeroplantaFormControl.value != '' || this.aeroplantaFormControl.value != null)) {
      this.listaDeAeroplantasSeleccionadas.push(this.aeroplantaFormControl.value);
    }
  }

  desasignarAeroplanta(index: number) {
    if (index !== -1) {
      this.listaDeAeroplantasSeleccionadas.splice(index, 1);
    }
  }

  asignarProducto() {
    let esta: number = this.listaDeProductosSeleccionados.indexOf(this.productoFormControl.value)
    if (esta === -1 && (this.productoFormControl.value != '' || this.productoFormControl.value != null)) {
      this.listaDeProductosSeleccionados.push(this.productoFormControl.value);
    }
  }

  desasignarProducto(index: number) {
    if (index !== -1) {
      this.listaDeProductosSeleccionados.splice(index, 1);
    }
  }

  formatter = (x: { razonSocial: string, ctaSap: string }) => `${x.razonSocial} - ${x.ctaSap}`;
  formatterAeroplanta = (x: { codigoAeroplanta: string, nombre: string }) => `${x.codigoAeroplanta} - ${x.nombre}`;
  formatterProducto = (x: { codigoProducto: string, nombreProducto: string }) => `${x.codigoProducto} - ${x.nombreProducto}`;

  selectCustomer(e: any): void {
    this.clienteFormControl.setValue(e.item);
  }

  selectAeroplanta(e: any): void {
    this.aeroplantaFormControl.setValue(e.item);
  }

  selectProducto(e: any): void {
    this.productoFormControl.setValue(e.item);
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => this.clientes == null || term.length < 2 || this.cargandoClientes ? [] :
        this.clientes.filter(v => this.containsString(this.clienteFormControl.value, v.ctaSap, v.razonSocial, v.numeroDocumento)).slice(0, 10))
    )

  searchAeroplanta = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => this.aeroplantas == null || term.length < 2 || this.cargandoAeroplantas ? [] :
        this.aeroplantas.filter(v => this.containsString(this.aeroplantaFormControl.value, v.nombre, v.codigoAeroplanta)).slice(0, 10))
    )

  searchProducto = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => this.productos == null || term.length < 2 || this.cargandoProductos ? [] :
        this.productos.filter(v => this.containsString(this.productoFormControl.value, v.nombreProducto, v.codigoProducto)).slice(0, 10))
    )


  // validaciones

  onlyDecimalNumberKey(event) {
    let charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57))
      return false;
    return true;
  }
  /**
   * Determina si una cadena se encuentra dentro de otra
   * @param searchValue Valor a buscar
   * @param searchIn Valores sobre los que buscar searchValue
   */
  containsString(searchValue: string, ...searchIn: string[]): boolean {
    const concat = searchIn.toString();
    return concat.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
  }

  //#region Validación de fechas

  /**
   * Valida la fecha del pedido y, si es correcta, la del vuelo
   */
  validarFecha(timeStruct: NgbTimeStruct) {
    if (!this.fecha.dirty)
      return;
    let fechaEstimada = this.dateFromDateTimeStruct(this.model);

    /** Esto está porque el timepicker si le ponés dos veces mal la hora (por ej. 57), se queda mal en el input */
    if (timeStruct) {
      let horaStruct = timeStruct.hour;
      let minutesStruct = timeStruct.minute;
      horaStruct = horaStruct == 0 ? 12 : (horaStruct > 12 ? horaStruct - 12 : horaStruct);
      this.fechaHoraElement.nativeElement.querySelector('[aria-label="Hours"]').value = horaStruct.toLocaleString(undefined, { minimumIntegerDigits: 2 });
      this.fechaHoraElement.nativeElement.querySelector('[aria-label="Minutes"]').value = minutesStruct.toLocaleString(undefined, { minimumIntegerDigits: 2 });
    }

    if (fechaEstimada == null || fechaEstimada.toString() == 'Invalid Date') {
      this.addValidationError(this.fecha, 'fechaValida');
    }
    else {
      this.fecha.updateValueAndValidity();
      if (fechaEstimada <= new Date())
        this.addValidationError(this.fecha, 'fechaFutura')
      else {
        this.fecha.updateValueAndValidity();
        this.validarFechaFin(fechaEstimada);
      }
    }
  }

  /**
   * Valida la fecha del vuelo
   * @param fechaEstimada Fecha estimada del pedido
   */
  validarFechaFin(fechaEstimada?: Date, timeStruct?: NgbTimeStruct) {
    if (!this.fecha.dirty && !this.fechaFin.dirty)
      return;
    if (timeStruct) {
      let horaStruct = timeStruct.hour;
      let minutesStruct = timeStruct.minute;
      horaStruct = horaStruct == 0 ? 12 : (horaStruct > 12 ? horaStruct - 12 : horaStruct);
      this.fechaHoraFinEl.nativeElement.querySelector('[aria-label="Hours"]').value = horaStruct.toLocaleString(undefined, { minimumIntegerDigits: 2 });
      this.fechaHoraFinEl.nativeElement.querySelector('[aria-label="Minutes"]').value = minutesStruct.toLocaleString(undefined, { minimumIntegerDigits: 2 });
    }
    this.fechaFin.updateValueAndValidity(); //sacar cualquier cosa
    let fechaFin = this.dateFromDateTimeStruct(this.modelVuelo);
    if (fechaEstimada == null)
      fechaEstimada = this.dateFromDateTimeStruct(this.model);
    if (fechaEstimada == null || fechaEstimada.toString() == 'Invalid Date') {
      this.addValidationError(this.fecha, 'fechaValida');
    }
    if (fechaFin == null || fechaFin.toString() == 'Invalid Date') {
      this.addValidationError(this.fechaFin, 'fechaValida');
    }
    if (this.fechaFin.valid && this.fecha.valid) {

      if (fechaFin > fechaEstimada) {
        this.fechaFin.updateValueAndValidity();
      }
      else {
        this.addValidationError(this.fechaFin, 'fechaFin');
      }
    }
  }
  /**
   * Agrega un error de validación en el AbstractControl y lo marca como Dirty
   */
  addValidationError(control: AbstractControl, validationName: string) {
    let errors = control.errors;
    if (errors == null)
      errors = { [validationName]: true };
    else
      errors[validationName] = true;
    control.setErrors(errors);
    control.markAsDirty();
  }


  camposCompletos(): boolean {
    let completos: boolean = false;
    completos = (this.valor.value != "") && (this.motivo.value != "");
    if (this.editando === false) {
      completos = this.listaDeClientesSeleccionados.length > 0 && this.listaDeAeroplantasSeleccionadas.length > 0
        && this.listaDeProductosSeleccionados.length > 0 && this.valor.value>0
    }
    return completos;
  }

  // getters
  get clienteFormControl() {
    return this.form.get('clienteFormControl');
  }

  get aeroplantaFormControl() {
    return this.form.get('aeroplantaFormControl');
  }

  get productoFormControl() {
    return this.form.get('productoFormControl');
  }

  get tipo() {
    return this.form.get('tipoFormControl');
  }

  get valor() {
    return this.form.get('valorFormControl');
  }

  get fecha() {
    return this.form.get('fechaFormControl');
  }

  get fechaFin() {
    return this.form.get('fechaFinFormControl');
  }

  get motivo() {
    return this.form.get('motivoFormControl');
  }
}
