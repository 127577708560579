import { Conductor } from "./Conductor";
import { Responsable } from "./Responsable";
import { SalidaDetalleCombustible } from "./SalidaDetalleCombustible";
import { Terminal } from "./Terminal";
import { Transportista } from "./Transportista";

export class RemitoSalida{
    numeroONU: string;
    comentarioOnu: string;
    comentario: string;
    prSup: string;
    numeroCertificadoAnalisis: string;
    codigoTerminalDestino: string;
    terminal: Terminal;
    salidaCombustibleDetalles: SalidaDetalleCombustible[]; //
    conductor: Conductor;
    transportista: Transportista;
    capacidadCamion: number;
    precintoDesde: string;
    precintoHasta: string;
    patentes: string[];
    temperatura: number;
    densidad: number;
    responsable: Responsable ;
    cai: string;
    prefijoFOX: number;
    numeroFOX: number;
    detalleAeroespecialidades: DetalleAeroespecialidad[];
    codigoAeroplanta: string;
}
export class DetalleAeroespecialidad{
    cantidad: number;
    codigoProducto: string;
}