import { TipoPersona } from '../../enums/TipoPersona';
import { SubArea } from '../abm/SubArea';
import { CondicionIVA } from '../facturacion/CondicionIVA';
import { ActividadAFIP } from './AcividadAFIP';
import { Actividad } from './Actividad';
import { CBU } from './CBU';
import { ConfigClienteProvincia } from './ConfigClienteProvincia';
import { Contacto } from './Contacto';
import { Cuenta } from './Cuenta';
import { DireccionFiscal } from './DireccionFiscal';
import { GrupoCliente } from './GrupoCliente';
import { PeriodosFacturacion } from './PeriodosFacturacion';
import { Responsable } from './Responsable';
import { Ubicacion } from './Ubicacion';
import { ViaDePago } from './ViaDePago';

export class Cliente{
    id: string;
    razonSocial: string;
    numeroDocumento: string;
    tipoDocumento: number;
    condicionPrecio: number;
    direccionFiscal: Ubicacion;
    responsable: Responsable;
    condicionIva: CondicionIVA;
    direccionesEntrega: Ubicacion[];
    ctaSap: string;
    ctA_CTC: string;
    esNuevo: boolean;
    configuracionProvincialImp: ConfigClienteProvincia[];
    // actividad: Actividad;
    esTrader: boolean;
    permiteRanchos: boolean;
    comerciales: string[];
    percepcionIVADif: number;
    fechaPercepcionIVADif: string;
    activo: boolean;
    clienteObservado: boolean;
    grupoCliente: GrupoCliente;
    cuentas: Cuenta[];
    subArea: SubArea;
    actividadPAD: number;
    actividadAFIP: ActividadAFIP;
    tipoPersona: TipoPersona;
    cbUs: CBU[];
    viaDePago: ViaDePago;

    detalle: string;
    usuarioCreo: string;
    usuarioAprobo: string;

    modificacionPendiente: boolean;

    archivosAdjuntos: string[];
}
