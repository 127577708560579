import { Component, OnInit } from '@angular/core';
import { ProductoService } from '../producto.service';
import { Producto } from 'src/app/shared/models/abm/Producto';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TipoProducto } from 'src/app/shared/enums/tipoProducto';
import { UntypedFormControl } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { parametroImpositivo } from 'src/app/shared/models/abm/ParametroImpositivo';
import { TipoNeto } from 'src/app/shared/enums/tipoNeto';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';
@Component({
  selector: 'app-productos-lista',
  templateUrl: './productos-lista.component.html', 
  styleUrls: ['./productos-lista.component.css']
})
export class ProductosListaComponent implements OnInit {

  TipoUsuario = TipoUsuario;

  //se utilizan para el check de activos/inactivos
  activos = { nombre: "Activo", seleccionado: false };
  inactivos = { nombre: "Inactivo", seleccionado: false };
  estados = [this.activos, this.inactivos];
  productos: Producto[];
  productosFiltrados: Producto[];
  tipoProducto = TipoProducto;
  tipoRubro = TipoNeto;
  productoSeleccionado: Producto; 
  netoCombustible = {nombre: "NetoCombustible", seleccionado: false}
  tiposRubro = [this.netoCombustible , this.netoCombustible];
  esperandoCarga: boolean= true;

  busquedaFormControl = new UntypedFormControl();
  impuestos: parametroImpositivo[];
  datePipe: any;
  procesandoExport: boolean;
  mensajeErrorExportar: string;
  constructor(private productoService: ProductoService, private modalService: NgbModal,
     private spinner: NgxSpinnerService, private http: HttpClient, public authService: AuthService) { }

  ngOnInit() {
    this.spinner.show('spinnerGrafico');
    this.getProductos();
    this.cargarCheckTipos();
  }

  ngOnChanges(): void {
    setTimeout(() => {
      this.spinner.show('spinnerLista');
    });
    this.getProductos();
    this.cargarCheckTipos();
  }

  keysTipos(): Array<string> {
    var keys = Object.keys(this.tipoRubro);
    keys = keys.slice(keys.length / 2);
    return keys;
  }

  cargarCheckTipos() {
    this.tiposRubro = [];
    let i = 0;
    for (let tipo of this.keysTipos()) {
      let t: string = tipo;
      this.tiposRubro.push({"nombre": t, "seleccionado": false });
    }
  }

  filtrarProductos() {
    this.productosFiltrados = this.productos.filter(a => {
      let agregar = true;
      if (this.busqueda.value) {
        agregar = a.nombreProducto.toLowerCase().indexOf(this.busqueda.value.toLowerCase()) > -1;
        agregar = agregar || a.codigoProducto.toLowerCase().indexOf(this.busqueda.value.toLowerCase()) > -1;
      }
      if (this.estados.some(e => e.seleccionado)) {
        agregar = agregar &&
          (
            (this.activos.seleccionado === true && a.activo === true) ||
            (this.inactivos.seleccionado === true && a.activo == false)
          );
      }
      if (this.tiposRubro.some(t => t.seleccionado)) {
        agregar = agregar &&
          (
            (this.tiposRubro[0].seleccionado === true && a.rubro.tipoNeto === 0)
          ||     (this.tiposRubro[1].seleccionado === true && a.rubro.tipoNeto === 1)
          ||     (this.tiposRubro[2].seleccionado === true && a.rubro.tipoNeto === 2)
          ||     (this.tiposRubro[3].seleccionado === true && a.rubro.tipoNeto === 3)
          ||     (this.tiposRubro[4].seleccionado === true && a.rubro.tipoNeto === 4)
          ||     (this.tiposRubro[5].seleccionado === true && a.rubro.tipoNeto === 5)
            );
      }
      return agregar;
    });
  }

  getProductos() {
    this.esperandoCarga = true;
    this.productoService.obtenerProductos()
      .subscribe(result => {
        this.productos = result;
        this.productosFiltrados = this.productos;
        this.spinner.hide('spinnerGrafico');
        this.esperandoCarga = false;
      }, () => {

      });
  }
  mostrarDetalle(i: number) {
    this.productoSeleccionado = this.productosFiltrados[i];
  }

  mostrarNuevoProducto(content) {
    this.modalService.open(content, { centered: true, size: 'lg', backdrop: 'static' });
  }

  exportarProductos()
  {
      const url = `${this.productoService.baseUrl}/ProductosV1/Exportar`;
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      });
      // Process the file downloaded
      this.http.get(url, { headers: headers, responseType: 'blob' as 'json' })
        .subscribe((res: Blob) => {
          const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
          saveAs(blob, "ListadoDeProductos");
        }, () => {
         console.log("No se encontraron productos para exportar");
        });

  }

  get busqueda() {
    return this.busquedaFormControl;
  }

}
