import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { NgbCalendar, NgbDatepickerConfig, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Pedido } from 'src/app/shared/models/stock/Pedido';
import { MessageService } from 'src/app/shell/message.service';
import { StockService } from '../../stock.service';
import { NuevoPedidoCombustibleComponent } from './nuevo-pedido-combustible/nuevo-pedido-combustible.component';
import { EstadoPedido } from 'src/app/shared/enums/estadoPedido';
import { ProductoService } from 'src/app/abm/producto/producto.service';
import { Producto } from 'src/app/shared/models/abm/Producto';
import { UltimoEstado } from 'src/app/shared/models/stock/UltimoEstado';
import { Subscription } from 'rxjs';
import { UsuarioService } from 'src/app/account/usuario.service';
import { ModalConfirmComponent } from 'src/app/shared/generic-modals/modal-confirm.component';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { DatePipe } from '@angular/common';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-pedidos',
  templateUrl: './pedidos.component.html',
  styleUrls: ['./pedidos.component.scss']
})
export class PedidosComponent implements OnInit {

  modelDesde: NgbDateStruct;
  modelHasta: NgbDateStruct;
  fechaDesde: Date;
  fechaHasta: Date;

  busquedaFormControl = new UntypedFormControl();

  pedidos:Pedido[];
  pedidosFiltrados:any[] = [];
  obteniendoPedidos:boolean=false;
  cargandoProductos:boolean=false;
  anulandoPedido:boolean=false;
  EstadoPedido=EstadoPedido;
  productos:Producto[] = [];
  productosParaCheckear: any[] = [];
  pedidoSeleccionado:number;
  cambiandoEstado:boolean=false;
  change$: Subscription;
  msgEstado:string="";

  bloquerBusqueda

  constructor(
    private calendar: NgbCalendar,
    private modal: NgbModal,
    private datepickerConfig: NgbDatepickerConfig,
    private messageService: MessageService,
    private usuarioService: UsuarioService,
    private productoService: ProductoService,
    private spinner: NgxSpinnerService,
    private stockService: StockService,
    private messageServices: MessageService,
    private stockSerivce: StockService,
    sharedService: SharedService) {
      this.change$ = sharedService.aeroplantaSeleccionada.subscribe(_=>{
        this.cambiarDeAeroplanta()
      })
    }

  ngOnInit(): void {
    this.setearFechas();
    this.cargarProductos();
    this.obtenerPedidos();
    this.datepickerConfig.minDate = { day: 1, month: 1, year: 2019 };    
    this.datepickerConfig.maxDate = { day: 31, month: 12, year: 2050 };
  }

  /** utilidades */

  mostrarSpinner() {
    this.obteniendoPedidos = true;
    this.spinner.show('spinnerLista');    
  }
  
  ocultarSpinner() {
    this.obteniendoPedidos = false;
    this.spinner.hide('spinnerLista');    
  }

  cambiarDeAeroplanta(){
    this.ngOnInit();
  }

  ngOnDestroy() {
    this.change$.unsubscribe();
  }

  getCodigoAeroplanta(){
    return this.usuarioService.getAeroplantaActual().codigoAeroplanta;
  }

  getFechaDesde(){
    return this.formatDate(this.fechaDesde.getFullYear(), this.fechaDesde.getMonth() + 1, this.fechaDesde.getDate(), 0, 0, 0);
  }

  getFechaHasta(){
    return this.formatDate(this.fechaHasta.getFullYear(), this.fechaHasta.getMonth() + 1, this.fechaHasta.getDate(), 23, 59, 59);
  }

  setearFechas() {
    this.modelDesde = this.calendar.getToday();
    this.fechaDesde = new Date(this.modelDesde.year, this.modelDesde.month-1, this.modelDesde.day);
    this.modelHasta = this.calendar.getToday();
    this.fechaHasta = new Date(this.modelHasta.year, this.modelHasta.month-1, this.modelHasta.day);
  }

  keysEstadoPedido() {
    var keys = Object.keys(this.EstadoPedido);
    keys = keys.slice(keys.length / 2);
    return keys;
  }

  cambiarEstado(p:Pedido, i:number) {
    let msg:string="";
    let estado = p.ultimoEstado.estado;
    switch (estado) {
      case EstadoPedido.Abierto:
        msg='abrir'; this.msgEstado="Abriendo...";
        break;
      case EstadoPedido.Anulado:
        msg='anular'; this.msgEstado="Anulando...";
        break;
      case EstadoPedido.Cerrado:
        msg='cerrar'; this.msgEstado="Cerrando...";
        break;
      default:        
        break;
    }    
    let modalRef = this.modal.open(ModalConfirmComponent, { centered: true, backdrop: 'static' });
    modalRef.componentInstance.mensaje = `¿Esta seguro que desea ${msg} el pedido seleccionado?`;
    modalRef.result.then(result => {
      if (result == 'Si') {
        let ultEstado= new UltimoEstado();
        ultEstado.estado = estado;
        let ahora = new Date();
        ultEstado.fechaHora = this.formatDate(ahora.getFullYear(), ahora.getMonth() + 1, ahora.getDate(), ahora.getHours(), ahora.getMinutes(), 0);
        ultEstado.responsable = this.stockService.obtenerStringResponsable();

        try {
          this.pedidoSeleccionado=i;
          this.cambiandoEstado=true;
          let r = this.stockSerivce.postPedidosAdicionarEstado(p.id, ultEstado);
          r.then(() => {            
            this.messageService.showSuccessMessage(`El pedido fue ${EstadoPedido[estado].toLowerCase()} correctamente.`);
            this.pedidoSeleccionado=-1;
            this.cambiandoEstado=false;   
            this.filtrarMovimientosPorFecha();        
          }, (error) => {
            this.messageService.showErrorMessage(`Hubo un error al ${msg} el pedido, por favor reintentelo.` + `\n` + error.message);
            this.pedidoSeleccionado=-1;
            this.cambiandoEstado=false;            
          });        
        } catch (error) {
          this.messageService.showErrorMessage(`Hubo un error al ${msg} el pedido, por favor reintentelo.`);
          this.pedidoSeleccionado=-1;
          this.cambiandoEstado=false; 
        }
      }              
    }, () => {
      this.filtrarMovimientosPorFecha();
    })
  }

  /** servicios */

  cargarProductos(): void {
    this.cargandoProductos = true;
    this.productoService.obtenerProductos()
      .subscribe(productos => {
        this.productos = productos.filter(p=>p.rubro.aerocombustible || p.rubro.combustible).filter(p=>p.activo);
        this.productosParaCheckear = [];
        for (let p of this.productos) {
          this.productosParaCheckear.push({ "nombre": p.nombreProducto, "codigo": p.codigoProducto, "seleccionado": false });
        }
        this.cargandoProductos = false;
      }, ()=>{
        this.messageService.showErrorMessage("Hubo un error al cargar los productos.")
        this.cargandoProductos = false;
      });
  }
  
  obtenerPedidos = async () => {
    try {
      this.mostrarSpinner();
      this.pedidos = await this.stockSerivce.getPedidos(this.getCodigoAeroplanta(), this.getFechaDesde(), this.getFechaHasta());
      this.pedidosFiltrados = this.pedidos;
      this.bloquerBusqueda = false;
      this.ocultarSpinner();
    } catch (error) {
      this.messageServices.showErrorMessage("Hubo un error al obtener pedidos.");
      this.pedidos = [];
      this.pedidosFiltrados = [];
      this.bloquerBusqueda = false;
      this.ocultarSpinner();      
    }    
  }

  abmPedidos(p:Pedido) {
    let modalRef = this.modal.open(NuevoPedidoCombustibleComponent, { centered: true, backdrop: 'static' });
    modalRef.componentInstance.productos = this.productos;
    p!=null ? modalRef.componentInstance.pedidosCombustible=p : null; 
    modalRef.result.then((r) => {
      if (r) {
          this.obtenerPedidos();
        }
      })
  }

  filtrarMovimientos() {
    this.pedidosFiltrados = this.pedidos.filter(p => {
      let agregar = true;
      if (this.busquedaFormControl.value) {
        agregar = p.producto.nombreProducto && p.producto.nombreProducto.toLocaleLowerCase().indexOf(this.busquedaFormControl.value.toLocaleLowerCase()) > -1; 
      }

      if (this.productosParaCheckear.some(b => b.seleccionado))
        agregar = agregar && this.productosParaCheckear.some(b => b.seleccionado && b.codigo == p.producto.codigoProducto);

      return agregar;
    });    
  }

  filtrarMovimientosPorFecha() {
    this.bloquerBusqueda = true;
    this.fechaDesde = new Date(this.modelDesde.year, this.modelDesde.month-1, this.modelDesde.day);
    this.fechaHasta = new Date(this.modelHasta.year, this.modelHasta.month-1, this.modelHasta.day);
    this.busquedaFormControl.setValue("");
    this.obtenerPedidos();
  }

  formatDate(year, month, day, hour, minute, seconds): string {
    return year + '-' + month.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + '-' + day.toLocaleString(undefined, { minimumIntegerDigits: 2 })
      + 'T' + hour.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + minute.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + seconds.toLocaleString(undefined, { minimumIntegerDigits: 2 });
  }

  toggleDatepicker(e: any){
    e.toggle();
  }

  obtenerEstado(estado: number) {
    return estado!=null ? EstadoPedido[estado] : null;
  }

}
