import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ProductoService } from 'src/app/abm/producto/producto.service';
import { UsuarioService } from 'src/app/account/usuario.service';
import { TipoBonificacionRecargo } from 'src/app/shared/enums/tipoBonificacionRecargo';
import { BonificacionRecargo } from 'src/app/shared/models/precios/BonificacionyRecargo';
import { Expendedora } from 'src/app/shared/models/precios/Expendedora';
import { MessageService } from 'src/app/shell/message.service';
import { StockService } from 'src/app/stock/stock.service';
import { PreciosService } from '../../precios.service';

@Component({
  selector: 'app-modal-nueva-byr-por-surtidor',
  templateUrl: './modal-nueva-byr-por-surtidor.component.html',
  styleUrls: ['./modal-nueva-byr-por-surtidor.component.css']
})
export class ModalNuevaByrPorSurtidorComponent implements OnInit {

  @Input() BonifRecargoSeleccionada: BonificacionRecargo;

  guardadoBoR: boolean = false;
  errorValidacion: string;

  expendedoras: Expendedora[] = []
  expendedorasSeleccionadas: Expendedora[] = [];

  cargandoExpendedoras: boolean = false;
  errorExpendedoras: boolean;

  editando: boolean;

  form = new UntypedFormGroup({
    expendedoraFormControl: new UntypedFormControl(''),
    tipoFormControl: new UntypedFormControl('', Validators.required),
    valorFormControl: new UntypedFormControl('', Validators.required),
    motivoFormControl: new UntypedFormControl('', Validators.required)
  });
  constructor(public activeModal: NgbActiveModal, private stockService: StockService,private productoService: ProductoService,
    private precioService: PreciosService, private messageService: MessageService, private usuarioService: UsuarioService,
    private changeDetector: ChangeDetectorRef) { }


  // hooks

  ngOnInit() {
    if (this.BonifRecargoSeleccionada == null) {

      this.limpiarForm();
      this.editando = false;
    } else {
      this.editando = true;
      this.valor.setValue(this.BonifRecargoSeleccionada.valor);
      this.motivo.setValue(this.BonifRecargoSeleccionada.motivo);
      this.tipo.setValue(this.BonifRecargoSeleccionada.tipo);
    }
    this.getTodasLasExpendedoras();
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }


  // llamadas al service

  getTodasLasExpendedoras() {
    this.cargandoExpendedoras = true;
    this.expendedoras = [];
    this.stockService.getSurtidores(this.usuarioService.getAeroplantaActual().codigoAeroplanta)
      .subscribe(result => {
        var surtidores = result;

        this.expendedoras = surtidores.map(s => {
          var e = new Expendedora();
          e.codigoExpendedora = s.codigo;
          e.nombreExpendedora = s.nombre;
          return e;
        });

        if (this.editando) {
          this.agregarSurtidoresVinculados();
        }
        this.cargandoExpendedoras = false;
      }, () => this.errorExpendedoras = true);
  }


  guardarBoR() {
    this.errorValidacion = null;
    this.guardadoBoR = true;
    let nuevoBoR = new BonificacionRecargo();

    this.accionarForm();

    if (this.validarDatos()) {

      if (!this.editando) {
        nuevoBoR.activo = 1;
        nuevoBoR.expendedoras = this.expendedorasSeleccionadas;
        nuevoBoR.tipo = parseInt(this.tipo.value);
      } else {
        nuevoBoR.activo = this.BonifRecargoSeleccionada.activo;
        nuevoBoR.estado = this.BonifRecargoSeleccionada.estado;
        nuevoBoR.solicitadoPor = this.BonifRecargoSeleccionada.solicitadoPor;
        nuevoBoR.id = this.BonifRecargoSeleccionada.id;
        nuevoBoR.expendedoras=this.BonifRecargoSeleccionada.expendedoras;
        nuevoBoR.tipo = this.BonifRecargoSeleccionada.tipo;
      }

      nuevoBoR.valor = parseInt(this.valor.value);
      nuevoBoR.motivo = this.motivo.value;



      nuevoBoR.tipoBonificacionRecargo = TipoBonificacionRecargo.Expendedora;

      if (!this.editando) {
        this.precioService.postBonificacionesyRecargos(nuevoBoR)
          .subscribe(result => {
            this.activeModal.close(nuevoBoR);
            this.emitirCartelAgregacion(nuevoBoR.tipo);
            this.guardadoBoR = false;
          }, () => {
            this.messageService.showErrorMessage('Hubo un error al crear la bonificación o recargo, por favor reintente nuevamente.');
            this.guardadoBoR = false;
          });
      } else {
        this.precioService.putBonificacionesyRecargos(nuevoBoR)
          .subscribe(result => {
            this.activeModal.close(nuevoBoR);
            this.emitirCartelEditado(nuevoBoR.tipo)
            this.guardadoBoR = false;
          }, () => {
            this.messageService.showErrorMessage('Hubo un error al editar la bonificación o recargo, por favor reintente nuevamente.');
            this.guardadoBoR = false;
          });
      }
    } else {
      this.guardadoBoR = false;
    }
  }

  validarDatos(){
    // if(this.editando!=false && this.expendedorasSeleccionadas == null || this.expendedorasSeleccionadas.length == 0){
    //   this.errorValidacion = "Debe seleccionar al menos un surtidor para aplicar la ByR.";
    //   return false;
    // }

    return true;
  }



  onlyDecimalNumberKey(event) {
    let charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57))
      return false;
    return true;
  }

  // form

  agregarSurtidoresVinculados(){

    for (let expendedora of this.expendedoras) {
      for (let expendedoraVinculada of this.BonifRecargoSeleccionada.expendedoras) {
        if (expendedora.codigoExpendedora == expendedoraVinculada.codigoExpendedora) {
          this.expendedorasSeleccionadas.push(expendedoraVinculada);
        }
      }
    }
  }


  formatter = (x: { codigoExpendedora: string, nombreExpendedora: string }) => `${x.nombreExpendedora}`

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => this.expendedoras == null || term.length < 2 || this.cargandoExpendedoras ? [] :
        this.expendedoras.filter(e => this.containsString(this.expendedoraFormControl.value, e.codigoExpendedora, e.nombreExpendedora)).slice(0, 10))
    )

  /**
   * Determina si una cadena se encuentra dentro de otra
   * @param searchValue Valor a buscar
   * @param searchIn Valores sobre los que buscar searchValue
   */
  containsString(searchValue: string, ...searchIn: string[]): boolean {
    const concat = searchIn.toString();
    return concat.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
  }

  agregarExpendedora(e: any): void {
    e.preventDefault();

    const index: number = this.expendedoras.indexOf(e.item);
    if (index !== -1) {
      this.expendedoras.splice(index, 1);
    }

    this.expendedorasSeleccionadas.push(e.item);

    this.expendedoraFormControl.setValue("");
  }

  desasignarExpendedora(expendedora: Expendedora) {
    const index: number = this.expendedorasSeleccionadas.indexOf(expendedora);

    if (index !== -1) {
      this.expendedorasSeleccionadas.splice(index, 1);
    }
    this.expendedoras.push(expendedora);
  }

  accionarForm(){
    Object.keys(this.form.controls).forEach(key => {
      this.form.get(key).markAsDirty();
    });
  }



  emitirCartelAgregacion(tipo: number) {
    let cartelActivo: string = "cread";
    if (tipo === 0) {
      cartelActivo = cartelActivo.concat('a');
      this.messageService.showSuccessLongMessage('La bonificación fue ' + cartelActivo + ' con exito.');
    }
    else {
      cartelActivo = cartelActivo.concat('o');
      this.messageService.showSuccessLongMessage('El recargo fue ' + cartelActivo + ' con exito.');
    }
  }

  emitirCartelEditado(tipo: number) {
    let cartelEdicion: string = "editad";
    if (tipo === 0) {
      cartelEdicion = cartelEdicion.concat('a');
      this.messageService.showSuccessLongMessage('La bonificación fue ' + cartelEdicion + ' con exito.');
    }
    else {
      cartelEdicion = cartelEdicion.concat('o');
      this.messageService.showSuccessLongMessage('El recargo fue ' + cartelEdicion + ' con exito.');
    }
  }

  limpiarForm() {
    this.expendedoraFormControl.setValue(null);
    this.valor.setValue(0);
    this.tipo.setValue(0);
    this.motivo.setValue('');
  }

  // getters
  get expendedoraFormControl() {
    return this.form.get('expendedoraFormControl');
  }

  get tipo() {
    return this.form.get('tipoFormControl');
  }

  get valor() {
    return this.form.get('valorFormControl');
  }

  get motivo() {
    return this.form.get('motivoFormControl');
  }
}
