import { Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, UntypedFormArray } from '@angular/forms';
import { UsuarioService } from 'src/app/account/usuario.service';
import { StockService } from '../../stock.service';
import { Contenedor } from 'src/app/shared/models/stock/Contenedor';
import { StockPorContenedores } from 'src/app/shared/models/stock/StockPorContenedor';
import { AjusteManual } from 'src/app/shared/models/stock/AjusteManual';
import { Guid } from 'guid-typescript';
import { Producto } from 'src/app/shared/models/stock/Producto';
import { NgxSpinnerService } from 'ngx-spinner';
import { Rebombeo } from 'src/app/shared/models/stock/Rebombeo';
import { Transferencia } from 'src/app/shared/models/stock/Transferencia';
import { Subscription } from 'rxjs';
import { trigger, transition, animate, style, state } from '@angular/animations';
import { EstadoContenedor } from 'src/app/shared/enums/estadoContenedor';
import { Cano } from 'src/app/shared/models/stock/Cano';
import { DecimalPipe } from '@angular/common';
import { SharedService } from 'src/app/shared/shared.service';


@Component({
  selector: 'app-tablero',
  templateUrl: './tablero.component.html',
  styleUrls: ['./tablero.component.css'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0
      })),
      transition('void <=> *', animate(1000)),
    ])
  ]
})
export class TableroComponent implements OnInit {


  contenedores: Contenedor;
  detalle: boolean = false;
  stockContenedores: StockPorContenedores[] = [];
  productoSeleccionado: Producto;
  esperandoCarga: boolean = true;
  messageStock: string = "C a r g a n d o . . ."
  Highcharts: typeof Highcharts = Highcharts;
  graficasProducto: Highcharts.Options[] = [];
  canerias: Cano[] = [];
  change$: Subscription;
  ajusteContenedor: AjusteManual = new AjusteManual();
  rebombeo: Rebombeo = new Rebombeo;
  transferencia: Transferencia = new Transferencia;  
  obteniendoStockContenedores: boolean = true;
  armandoGrafico: boolean = true;
  estadoContenedor: EstadoContenedor;

  formGrafico = new UntypedFormGroup({
    formBotones: new UntypedFormControl(''),
    formGraficoStock: new UntypedFormControl('')
  })


  constructor(public modalService: NgbModal,
    private usuarioService: UsuarioService, 
    private stockService: StockService,
    private formBuilder: UntypedFormBuilder,
    private spinner: NgxSpinnerService,
    private decimalPipe: DecimalPipe,
    sharedService: SharedService) {
      this.change$ = sharedService.aeroplantaSeleccionada.subscribe(_=>{
        this.cambiarDeAeroplanta()
      })
    }    

  // hooks

  ngOnInit() {
    this.ajusteContenedor = new AjusteManual();
    this.transferencia = new Transferencia();
    this.rebombeo = new Rebombeo();
    this.obteniendoStockContenedores = true;
    this.esperandoCarga = true;
    this.spinner.show('spinnerGrafico');
    this.getStockContenedores(this.usuarioService.getAeroplantaActual().codigoAeroplanta);
    if (this.armandoGrafico) {
      this.formGrafico = this.formBuilder.group({
        graficosArray: this.formBuilder.array([
          // load first row at start
          this.armarNuevoGrafico()
        ])
      });
    }
  }

  ngOnDestroy() {
    this.change$.unsubscribe();    
    this.stockContenedores = [];
    this.contenedores = null;
  }

  // Form.

  private armarNuevoGrafico() {
    return this.formBuilder.group({
      formGraficoStock: [],
      formBotones1: [],
      formBotones2: []
    })
  }

  stringPipeToNumber(data){
    return Number(this.decimalPipe.transform(data, '1.0-0').toString().replace(/\./g,""));
  }

  llenarLista() {
    this.graficasProducto = [];

    for (let i = 0; i < this.stockContenedores.length; i++) {
      if (this.stockContenedores[i].producto != null) {
        if (this.stockContenedores[i].volumenEnAbastecedoras > 0 || this.stockContenedores[i].volumenEnCanerias > 0 || this.stockContenedores[i].volumenEnTanques > 0) {
          let volumenTotal = Number(this.stockContenedores[i].volumenEnTanques) + Number(this.stockContenedores[i].volumenEnCanerias) + Number(this.stockContenedores[i].volumenEnAbastecedoras);
          var datos = [
            { name: "Abastecedora - (" + this.stringPipeToNumber(this.stockContenedores[i].volumenEnAbastecedoras) + ')', y: this.stringPipeToNumber(this.stockContenedores[i].volumenEnAbastecedoras), color: '#4BB0E3' },
            { name: "Cañeria - (" + this.stringPipeToNumber(this.stockContenedores[i].volumenEnCanerias) + ')', y: this.stringPipeToNumber(this.stockContenedores[i].volumenEnCanerias), color: '#E62CF2' },
            { name: "Tanque - (" + this.stringPipeToNumber(this.stockContenedores[i].volumenEnTanques)+ ')', y: this.stringPipeToNumber(this.stockContenedores[i].volumenEnTanques), color: '#62F22C' },
            { name: "Total - (" + this.stringPipeToNumber(volumenTotal) + ')', y: 0, color: '#FF0000' }
          ];
        } else {
          var datos = [{ name: "Vacio", y: 1, color: '#919192' }]
        }
        var war: any = '';
        for (let j = 0; this.stockContenedores[i].warnings.length > j; j++) {
          war += this.stockContenedores[i].warnings[j];
        }
        let graficaProducto: Highcharts.Options = {
          chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                enabled: false
              },
              showInLegend: true
            }
          },
          title: {
            text: '<span style="width: 17em; box-sizing: unset; display: block;">Producto: ' + this.stockContenedores[i].producto.nombreProducto + '</span>',
            useHTML: true,
            style: { backgroundColor: this.stockContenedores[i].producto.color ? this.stockContenedores[i].producto.color : '#995C5C', color: '#FFFFFF', borderRadius: 20, textAlign: 'center' }
          },
          subtitle: {
            text: war
          },
          credits: {
            enabled: false
          },
          series: [
            {
              name: 'Lts',
              data: datos,
              type: 'pie'
            }

          ]
        };

        if (i != 0 && this.esperandoCarga) {
          const control = <UntypedFormArray>this.formGrafico.controls['graficosArray'];
          control.push(this.armarNuevoGrafico());
        }

        this.graficasProducto.push(graficaProducto);
      }
    }
    this.spinner.hide('spinnerGrafico');
    this.esperandoCarga = false;
  }

  limpiarForm() {
    this.esperandoCarga = true;
    this.spinner.show('spinnerGrafico');
    this.ngOnInit();
  }


  cambiarDeAeroplanta() {   
    this.ngOnInit();
  }

  // llamadas al service

  getStockContenedores(aeroplantaCodActual: string) {
    this.stockService.getStockContenedores(aeroplantaCodActual)
      .subscribe(result => {
        this.stockContenedores = result
        this.getCanerias(aeroplantaCodActual);
      },
        () => {
          this.obteniendoStockContenedores = false;
          this.spinner.hide('spinnerGrafico');
          this.stockContenedores = [];
          this.esperandoCarga = false;
        }
      )
  }

  getCanerias(codAeroplanta: string) {
    this.stockService.getCanerias(codAeroplanta)
      .subscribe(result => {
        this.canerias = result.filter(a =>a.activo == true );
        this.armarCanerias();
        this.spinner.hide('spinnerGrafico');
        this.llenarLista();
        this.obteniendoStockContenedores = false;
      },
        () => {
          this.obteniendoStockContenedores = false;
          this.spinner.hide('spinnerGrafico');
          this.stockContenedores = [];
          this.esperandoCarga = false;
        }
      )
  }

  armarCanerias(){
    this.stockContenedores.forEach((value, index)=>{
      this.stockContenedores[index].volumenEnCanerias = 0;
      this.canerias.forEach((value2, index2) =>{
        if(this.canerias[index2].producto.codigoProducto == this.stockContenedores[index].producto.codigoProducto){
          this.stockContenedores[index].volumenEnCanerias += this.canerias[index2].stock;
          this.canerias[index2].esta = true;
        }
      });
    });
    //Por si solo existe la cañeria (y no abast o tanque del producto) se arma el DTO para que muestre el grafico
    this.canerias.forEach((value, index) => {
      if(this.canerias[index].esta != true){
        let nuevoStock = new StockPorContenedores();
        nuevoStock.producto = new Producto();
        nuevoStock.volumenEnCanerias = this.canerias[index].stock;
        nuevoStock.volumenEnAbastecedoras = 0;
        nuevoStock.volumenEnTanques = 0;
        nuevoStock.warnings = [];
        nuevoStock.producto.codigoProducto = this.canerias[index].producto.codigoProducto;
        nuevoStock.producto.nombreProducto = this.canerias[index].producto.nombreProducto;
        this.stockContenedores.push(nuevoStock);
      }
    });
  }


  mostrarDetalle(i: number) {
    this.detalle = true;
    this.productoSeleccionado = this.stockContenedores[i].producto;
  }

  // getters

  get botonesFormulario() {
    return this.formGrafico.get('formBotones');
  }
  get graficoFormulario() {
    return this.formGrafico.get('formGraficoStock');
  }

}
