<form [formGroup]="form" (ngSubmit)="onSubmit()">

  <div class="modal-header">
    <h4 class="modal-title">{{ editando ? "Modificar grupo" : "Nuevo grupo" }}</h4>
  </div>

  <div class="modal-body">

      <div class="row">
        <div class="mb-3 row col-md-12">
          <label for="codigoFormControl" class="col-sm-5 col-form-label">*Código del grupo:</label>
          <div class="col-sm-7">
            <input class="form-control" formControlName="codigoFormControl" maxlength="40" [attr.disabled]="editando ? '' : null">
            <div *ngIf="codigoFormControl.invalid && codigoFormControl.errors.required && (codigoFormControl.dirty || codigoFormControl.touched)"
              class="alert alert-danger">Requerido</div>
              <div *ngIf="chequearCodigo()" class="alert alert-danger"> El codigo del grupo ya está en uso</div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="mb-3 row col-md-12">
          <label for="nombreFormControl" class="col-sm-5 col-form-label">*Nombre del grupo:</label>
          <div class="col-sm-7">
            <input class="form-control" formControlName="nombreFormControl" maxlength="40">
            <div *ngIf="nombreFormControl.invalid && nombreFormControl.errors.required && (nombreFormControl.dirty || nombreFormControl.touched)"
              class="alert alert-danger">Requerido</div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="mb-3 row col-md-12">
          <label for="productoFormControl" class="col-sm-5 col-form-label">Producto</label>
          <div class="col-sm-7">
            <select [compareWith]="compareProductos" id="productoFormControl" class="form-select" ngbTooltip="Seleccione el producto." formControlName="productoFormControl">
              <option *ngFor="let producto of productos" [ngValue]="producto">
                ({{ producto.codigoProducto }}) - {{ producto.nombreProducto }}
              </option>
            </select>

            <loading-text-spinner *ngIf="cargandoProductos" [error]="errorProductos" message="Obteniendo productos..."color="primary">
            </loading-text-spinner>
            <div class="alert-danger"
              *ngIf="(this.productoFormControl.invalid && this.productoFormControl.errors.required && (this.productoFormControl.dirty || this.productoFormControl.touched))">
              Debe seleccionar un producto obligatoriamente.
            </div>
          </div>
        </div>
      </div>

    <div class="modal-footer">
      <span class="border">
        <button class="btn btn-primary" [disabled]="!form.valid || guardando" type="submit">
          {{ guardando? "Guardando..." : "Guardar cambios" }}
        </button>
      </span>
      <span class="border">
        <button type="button" class="btn btn-secondary" [disabled]="guardando" (click)="activeModal.dismiss('Close clicked')">Cancelar</button>
      </span>
    </div>
  </div>
</form>
