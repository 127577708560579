export class ErrorModel {
  code: string;
  description: string;
  message: string;

  constructor(code: string, description: string, message: string) {
    this.code = code;
    this.description = description;
    this.message = message;
  }
}