import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { TipoNivelAprobacion } from 'src/app/shared/enums/tipoNivelAprobacion';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { NivelAprobacion } from 'src/app/shared/models/precios/NivelAprobacion';
import { MessageService } from 'src/app/shell/message.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Usuario } from 'src/app/shared/models/despacho/Usuario';

import { switchMap, tap } from 'rxjs/operators';
import { PreciosService } from 'src/app/precios/precios.service';
import { ModalNivelAprobacionComponent } from 'src/app/precios/niveles-aprobacion/modal-nivel-aprobacion/modal-nivel-aprobacion.component';
import { ModalSeleccionarUsuarioComponent } from 'src/app/precios/niveles-aprobacion/modal-seleccionar-usuario/modal-seleccionar-usuario.component';
import { ModalNivelRefacturacionComponent } from './modal-nivel-refacturacion/modal-nivel-refacturacion.component';
import { FacturacionService } from '../facturacion.service';


@Component({
  selector: 'app-niveles-refacturacion',
  templateUrl: './niveles-refacturacion.component.html',
  styleUrls: ['./niveles-refacturacion.component.css']
})
export class NivelesRefacturacionComponent implements OnInit {

  [x: string]: any;

  enumTipoNivel = TipoNivelAprobacion;

  tipoNivelAprobacion: TipoNivelAprobacion;
  niveles: NivelAprobacion[];
  nivelSeleccionado: NivelAprobacion;
  error: string;
  esperandoCarga: boolean = true;

  constructor(
    private preciosService: PreciosService,
    private spinner: NgxSpinnerService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private router: Router,
    private facturacionService: FacturacionService
  ) {

    this.route.queryParams.pipe(
      tap(params => { this.tipoNivelAprobacion = params['tipoNivel']; this.initNivelesRequest() }),
      switchMap(params => this.preciosService.getNivelesAprobacion(2))
    ).subscribe(result => {
      this.niveles = result;
      this.spinner.hide('spinnerGrafico');
      this.esperandoCarga = false;
    },
      (err: ErrorModel) => {
        this.niveles = [];
        this.error = err.description;
        this.esperandoCarga = false;
      });
  }

  // hooks

  ngOnInit() {
  }

  // llamadas al service

  getNivelesAprobacion() {
    this.initNivelesRequest();

    this.facturacionService.getNivelesRefacturacionAprobacion(2)
      .subscribe(result => {
        this.niveles = result;
        this.spinner.hide('spinnerGrafico');
        this.esperandoCarga = false;
      },
        (err: ErrorModel) => {
          this.niveles = [];
          this.error = err.description;
          this.esperandoCarga = false;
        });
  }

  // form

  initNivelesRequest() {
    this.niveles = [];
    this.nivelSeleccionado = null;
    this.esperandoCarga = true;
    this.spinner.show("spinnerGrafico");
  }

  mostrarDetalleNivel(index) {
    this.nivelSeleccionado = this.niveles[index];
  }

  nuevoNivel() {
    let modalRef = this.modalService.open(ModalNivelRefacturacionComponent, { centered: true, backdrop: 'static' });
    modalRef.componentInstance.tipoNivel = this.tipoNivelAprobacion;
    modalRef.result.then((result: Usuario) => {
      this.messageService.showSuccessMessage("Se agregó el nivel de aprobacion");
      this.getNivelesAprobacion();
    }, () => { });
  }

  editarNivel() {
    let modalRef = this.modalService.open(ModalNivelRefacturacionComponent, { centered: true, backdrop: 'static' });
    modalRef.componentInstance.tipoNivel = this.tipoNivelAprobacion;
    modalRef.componentInstance.nivelAprobacionSeleccionado = this.nivelSeleccionado;
    modalRef.result.then((result: Usuario) => {
      this.messageService.showSuccessMessage("Se editó el nivel de aprobacion");
      this.getNivelesAprobacion();
    }, () => { });
  }

  agregarDelegacion() {
    if (this.nivelSeleccionado == null) {
      this.messageService.showErrorMessage("Seleccionar nivel para delegar usuario");
      return;
    }

    let modalRef = this.modalService.open(ModalSeleccionarUsuarioComponent, { centered: true, backdrop: 'static' });
    modalRef.componentInstance.esDelegacion = true;
    modalRef.componentInstance.nivelAprobacion = this.nivelSeleccionado;
    modalRef.result.then((result: Usuario) => {
      this.getNivelesAprobacion();
    }, () => { });
  }

  agregarUsuario() {
    if (this.nivelSeleccionado == null) {
      this.messageService.showErrorMessage("Seleccionar nivel para agregar usuario");
      return;
    }

    let modalRef = this.modalService.open(ModalSeleccionarUsuarioComponent, { centered: true, backdrop: 'static' });
    modalRef.componentInstance.esDelegacion = false;
    modalRef.componentInstance.nivelAprobacion = this.nivelSeleccionado;
    modalRef.result.then((result: Usuario) => {
      this.getNivelesAprobacion();
    }, () => { });
  }

}
