import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ExportExcelServiceService } from 'src/app/shared/exportar-excel/export-excel-service.service';
import { CierreTurno } from 'src/app/shared/models/cierreTurno/CierreTurno';
import { ReporteMovimiento } from 'src/app/shared/models/cierreTurno/ReporteMovimiento';
import { AerovaleNumeroPipe } from 'src/app/shared/pipes/aerovale-numero.pipe';
import { AerovalePrefijoPipe } from 'src/app/shared/pipes/aerovale-prefijo.pipe';
import { RemitoNumeroPipe } from 'src/app/shared/pipes/remito-numero.pipe';
import { RemitoPrefijoPipe } from 'src/app/shared/pipes/remito-prefijo.pipe';

@Component({
  selector: 'app-reporte-movimiento-productos-cierre',
  templateUrl: './reporte-movimiento-productos-cierre.component.html',
  styleUrls: ['./reporte-movimiento-productos-cierre.component.css']
})
export class ReporteMovimientoProductosCierreComponent implements OnInit {
  @Input() reporteSeleccionado: number;
  @Input() reporteMovimientoProd: ReporteMovimiento[];
  @Input() cierreSeleccionado: CierreTurno;
  @Output() termino: EventEmitter<boolean> = new EventEmitter();
  
  @Input()
  set ejecutarMovimientosProducto(b: boolean){
    if(b){
      this.exportar();
    }
    this.termino.emit(true);
  }

  cargando: boolean = false;
  productos = [];
  primerItem: ReporteMovimiento;
  ultimoItem: ReporteMovimiento;

  constructor(
    private spinner: NgxSpinnerService, 
    private excelService: ExportExcelServiceService,
    private aerovalePrefijoPipe: AerovalePrefijoPipe,
    private aerovaleNumeroPipe: AerovaleNumeroPipe,
    private remitoPrefijoPipe: RemitoPrefijoPipe,
    private remitoNumeroPipe: RemitoNumeroPipe) { }

  ngOnInit() {
    this.cargando = true;
    this.spinner.show('spinnerGrafico');
    for (let prod of this.reporteMovimientoProd) {
      if (!this.productos.find(a => a.name == prod.codigoProducto + '-' + prod.nombreProducto)) {
        this.productos.push({
          name: prod.codigoProducto + '-' + prod.nombreProducto,
          seleccionado: true
        })
      }
    }
    this.spinner.hide('spinnerGrafico');
    this.cargando = false;
  }

  seleccionarTodos(e){
    if(e.target.checked){
      for(let p of this.productos){
        p.seleccionado = true;
      }
    } else {
      for(let p of this.productos){
        p.seleccionado = false;
      }
    }
  }

  sumar(dist: string, otro: string): number {
    let cant: number = 0;
    for (let com of this.reporteMovimientoProd) {
      if (dist == com.codigoProducto + '-' + com.nombreProducto) {
        cant += com[otro];
      }
    }
    return cant;
  }

  arregloDistintoProd(dist: string):ReporteMovimiento[]{
    let comp : ReporteMovimiento[] = [];    
    let j: number = 0
    for(let p of this.reporteMovimientoProd){
      if(p.codigoProducto + '-' + p.nombreProducto == dist){
        if(j == 0){
          this.primerItem = p;
        }
        j = 1;
        comp.push(p)
        this.ultimoItem = p;
      }
    }
    return comp
  }

  armarArrayExcel(){
    var listaReporte : ReporteMovimientoCierre[] = [];
    
    for(let r of this.reporteMovimientoProd){     
      var item = new ReporteMovimientoCierre();
      item.producto = r.codigoProducto + "-" + r.nombreProducto;
      item.fecha = r.fechaHora;
      item.titulo = r.titulo;
      item.entradaNatural = r.entradaNatural? r.entradaNatural : 0;
      item.salidaNatural = r.salidaNatural? r.salidaNatural : 0;
      item.entrada15 = r.entrada15? r.entrada15 : 0;
      item.salida15 = r.salida15? r.salida15 : 0;
      item.stockNatural = r.stock? r.stock : 0;
      item.stock15 = r.stock15? r.stock15 : 0;      

      if(r.comprobante == null){
        item.comprobante = "-";
      }else if(r.comprobante.remito != null){
        item.comprobante = "AEV-" + this.aerovalePrefijoPipe.transform(r.comprobante.remito.aerovalePrefijo)+"-"+this.aerovaleNumeroPipe.transform(r.comprobante.remito.aerovaleNumero);
      }else if(r.comprobante.remitoCargamento != null){
        item.comprobante = "REM-" + this.remitoPrefijoPipe.transform(r.comprobante.prefijo)+"-"+this.remitoNumeroPipe.transform(r.comprobante.numeroRemito);
      }else if(r.comprobante.remitoSalida != null){
        item.comprobante = "REM-" + this.remitoPrefijoPipe.transform(r.comprobante.remitoSalida.prefijoFOX)+"-"+this.remitoNumeroPipe.transform(r.comprobante.remitoSalida.numeroFOX);
      }else if(r.comprobante.remitoAeroespecialidad != null){      
        item.comprobante = "REM-" + this.remitoPrefijoPipe.transform(r.comprobante.prefijo)+"-"+this.remitoNumeroPipe.transform(r.comprobante.numeroRemito);
      }else{
        item.comprobante = "-";
      }       

      listaReporte.push(item)
    }

    return listaReporte;
  }

  exportar(){
    this.excelService.exportAsExcelFile(this.armarArrayExcel(), 'Reporte movimientos cierre');
  }
}

export class ReporteMovimientoCierre{
  producto: string;
  fecha: string;  
  titulo: string;
  entradaNatural: number;
  salidaNatural: number;
  entrada15: number;
  salida15: number;
  stockNatural: number;
  stock15: number;
  comprobante: string;
}
