import { Destino } from "../despacho/Destino";
import { AeroplantaRancho } from "./AeroplantaRancho";
import { ClienteRancho } from "./ClienteRancho";
import { ConsumoRancho } from "./ConsumoRancho";
import { ProductoRancho } from "./ProductoRancho";
import { TipoEstadoRancho } from "../../enums/TipoEstadoRancho";
import { Usuario } from "./Usuario";

export class RanchoV2 {
   
    id:                string;
    codigoRancho:      string;
    aeroplanta:        AeroplantaRancho;
    cliente:           ClienteRancho;
    fechaInicio:       Date;
    fechaVencimiento:  Date;
    destino:           Destino;
    producto:          ProductoRancho;
    volumen:           number;
    precio:            number;
    consumos:          ConsumoRancho[];
    estado:            TipoEstadoRancho;
    vigente:           number;
    volumenDisponible: number;
    moneda:            string;
    ctA_ParaFacturar:  string;
    seFactura:         boolean;
    numeroLote:        number;
    concatenado:       string;
    fob:               string;
    bandera:           string;
    codigoBandera:     string;
    comentarios:       string;
    archivosAdjuntos:  string[];
    usuarioDTO:        Usuario;    
    nombrePaisFacturacion: string;
}