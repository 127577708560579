import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';
import { timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UsuarioService } from '../account/usuario.service';

enum TipoNotificacionSignarR{
  NuevasNotificacionesNavbar = 0,    
}

export class NotificacionSignalR{
  tipoNotificacionSignalR: TipoNotificacionSignarR; // TipoMensajeSignalR
  link?: string;
  usuarios: string[];  // Array de ids de usuarios
  detalle: string;
}

@Injectable({
  providedIn: 'root'
})
export class SignalRService {

  private hubConnection: signalR.HubConnection;
  private urlConexion = environment.signalR.signalRUrl;

  private navbarCantidadNuevasNotificaciones$ = new Subject<NotificacionSignalR>();

  n = 1;

  constructor(private usuarioService: UsuarioService) {

  }

  conectarServidorSignalR(){
    this.hubConnection = new signalR.HubConnectionBuilder().withUrl(this.urlConexion).build();

    this.hubConnection.start().then(() => {
      this.iniciarHubEvents();
    }).catch(err => {
      console.log(err)
    });
  }

  iniciarHubEvents(){
    this.procesarRecepcionNotificacionSignalR();
  }

  procesarRecepcionNotificacionSignalR(){
    this.hubConnection.on("Receive", function (message: NotificacionSignalR) {
      if(!environment.production) console.log("  -->  onReceive ejecutado por SIGNALR -> " + message);
      
      if(message.tipoNotificacionSignalR == TipoNotificacionSignarR.NuevasNotificacionesNavbar){
        if(!environment.production) console.log(" --> tipo notificacion = 0 (NuevasNotificacionesNavbar)");
        this.informarNavbarNotificacionRecibida(message);
      }      
    }.bind(this));
  }

  informarNavbarNotificacionRecibida(notificacionRecibida: NotificacionSignalR){
    if(!environment.production) console.log(" -->  se procesa notificacion notificacion");
    this.navbarCantidadNuevasNotificaciones$.next(notificacionRecibida);
  }

  cantidadNuevasNotificaciones$(): Observable<NotificacionSignalR>{
    return this.navbarCantidadNuevasNotificaciones$.asObservable();
  }
}
