import { Component, OnInit, ViewChildren } from '@angular/core';
import { Subscription } from 'rxjs';
import { ParametrosImpositivosService } from '../../parametros-impositivos.service';
import { Provincia } from 'src/app/shared/models/facturacion/Provincia';
import { ParametroImpositivoEncabezado } from 'src/app/shared/models/facturacion/ParametroImpositivoEncabezado';
import { ProvinciaParametroImpositivo } from 'src/app/shared/models/facturacion/ProvinciaParametroImpositivo';
import { ParametroImpositivoDetalle } from 'src/app/shared/models/facturacion/ParametroImpositivoDetalle';
import { TipoValorParametroImpositivo } from 'src/app/shared/enums/TipoValorParametroImpositivo';
import { TipoCodigoParametroImpositivo } from 'src/app/shared/enums/TipoCodigoParametroImpositivo';
import { TipoBaseCalculoParametroImpositivo } from 'src/app/shared/enums/TipoBaseCalculoParametroImpositivo';
import { CiudadParametroImpositivo } from 'src/app/shared/models/facturacion/CiudadParametroImpositivo';
import { TipoPadronV2 } from 'src/app/shared/enums/tiposPadronV2';
import { ModalCiudadComponentV2 } from '../modal-ciudad/modal-ciudad-v2.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Localidad } from 'src/app/shared/models/facturacion/Localidad';
import { ViewportScroller } from '@angular/common';
import { AlicuotasIIBB } from 'src/app/shared/models/facturacion/AlicuotasIIBB';
import { Impuestos } from 'src/app/shared/models/facturacion/Impuesto';

@Component({
  selector: 'app-listado-parametros-impositivos',
  templateUrl: './listado-parametros-impositivos.component.html',
  styleUrls: ['./listado-parametros-impositivos.component.css']
})
export class ListadoParametrosImpositivosComponent implements OnInit {

  private provinciaSeleccionadaSubscription: Subscription;
  private cambiosEnParametrosImpRealizados: Subscription;
  
  obteniendoProvincia = false;
  errorProvincia: boolean = false;
  provinciaParamImpositivo: ProvinciaParametroImpositivo;
  alicuotasIIBB: AlicuotasIIBB[];

  constructor(
    private modalService: NgbModal,
    private parametrosService: ParametrosImpositivosService) { }

  ngOnInit() {
    this.provinciaSeleccionadaSubscription = this.parametrosService.provinciaEncabezadoSeleccionada.subscribe(
      (provincia) => {
        if(provincia){
          // Obtener parametros impositivos para la provincia
          this.obtenerParametrosImpositivos(provincia.provinciaId);
        }        
      }
    );

    this.cambiosEnParametrosImpRealizados = this.parametrosService.cambiosEnParametrosImp.subscribe(
      (hayCambios) => {
        if(hayCambios && this.provinciaParamImpositivo){
          this.obtenerParametrosImpositivos(this.provinciaParamImpositivo.provinciaId);
        }                
      }
    );
  }

  onAgregarCiudad(){
    const modalRef = this.modalService.open(ModalCiudadComponentV2, { centered: true, backdrop: 'static' });
    modalRef.componentInstance.provinciaCodigo = this.provinciaParamImpositivo.provinciaCodigo;
    modalRef.result.then((ciudad: Localidad) => {
      let ciudadParamImp = new CiudadParametroImpositivo();
      ciudadParamImp.ciudadCodigo = ciudad.codigoPostal;
      ciudadParamImp.ciudadId = ciudad.id;
      ciudadParamImp.ciudadNombre = ciudad.nombre;
      ciudadParamImp.parametros = [];

      if( ! this.provinciaParamImpositivo.ciudades ){
        this.provinciaParamImpositivo.ciudades = [];
      }
      
      this.provinciaParamImpositivo.ciudades.push(ciudadParamImp);
      
      setTimeout(() => {
        const itemToScrollTo = document.getElementById(ciudad.id);
        if (itemToScrollTo) { 
          itemToScrollTo.scrollIntoView(true);
        }
      }, 400);
      
    }).catch(() => { });    
  }

  // ---------------------------------------------------------

  obtenerParametrosImpositivos(provinciaId){
    this.obteniendoProvincia = true;

    this.parametrosService.obtenerParametrosImpositivosPorProvincia(provinciaId).subscribe(
      (p: Impuestos) => {        
        this.obteniendoProvincia = false;
        if(p.parametroProvincia.ciudades){
          p.parametroProvincia.ciudades = p.parametroProvincia.ciudades.sort((a, b) => a.ciudadNombre.localeCompare(b.ciudadNombre));
        }        
        this.provinciaParamImpositivo = p.parametroProvincia;
        this.alicuotasIIBB = p.alicuotasIIBB;
      },
      () => { this.errorProvincia = true; }
    );
  }
}
