<nav class="navbar navbar-light bg-light navbar-expand-lg ">
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <!--Buscar notificaciones-->
    <div class="form-inline me-2">
        <input id="busquedaFormControl" type="text" class="form-control" placeholder="Buscador"
          [formControl]="buscadorFormControl" (input)="filtrarNotificaciones()"/>
    </div>
    <!---->
    <ul class="navbar-nav me-auto">    
      <!--Nuevo Notificacion Sistema-->
      <li class="nav-item float-end me-2">
        <button type="button" class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2" (click)="mostrarNuevaNotificacionSistema()"> Agregar</button>
      </li>
      <!--Mostrar Historial -->
      <li class="nav-item float-end">
         <button type="button" class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2" (click)="mostrarHistorialNotificaciones()"
          [disabled]="this.notificacionSistemaSeleccionada==null" > Historial de envios</button>
      </li>
    </ul>
  </div>
</nav>

<div class="row">  
  <div class="col col-3">
    <div class="list-group py-3 menu-izq">
      <app-notificaciones-lista-sistema [notificacionBuscada]="this.notificacionBuscada" (notificacionSistemaEncabezado)="usuarioSeleccionoNotificacio($event)"></app-notificaciones-lista-sistema>
    </div>
  </div>
  <!-- informacion de las notificaciones -->
  <div class="col col-9 py-3">
    <div *ngIf="this.notificacionSistemaSeleccionada!=null" id="tablaInformacion">
      <app-notificacion-detalle-sistema (notificacionDuplicar)="mostrarNuevaNotificacionSistema($event)" [notificacionSistema]="this.notificacionSistemaSeleccionada"></app-notificacion-detalle-sistema>
    </div>
  </div>
</div>
  
  <ng-template let-modal>
    <app-nueva-notificacion></app-nueva-notificacion>
  </ng-template>
