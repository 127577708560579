<nav class="navbar navbar-light bg-light navbar-expand-lg mb-3">
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav me-auto">
      <li class="nav-item">
        <button type="button" (click)="nuevoNivel()" class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2">
         Nuevo nivel
        </button>
      </li>
      <li class="nav-item">
        <button type="button" (click)="editarNivel()" class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2"
          [disabled]="this.nivelSeleccionado == null">
          Editar nivel
        </button>
      </li>
    </ul>
    <ul class="navbar-nav">
      <li class="nav-item" style="float:right">
        <button type="button" (click)="agregarDelegacion()" class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2">
          Agregar delegación</button>
      </li>
      <li class="nav-item" style="float:right">
        <button type="button" (click)="agregarUsuario()" class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2"> Agregar
          usuario</button>
      </li>
    </ul>
  </div>
</nav>

<div class="col col-md-12">
  <div class="spinner-container" *ngIf="esperandoCarga">
    <ngx-spinner name="spinnerGrafico" bdOpacity=0.3bd
      Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
      <p style="color: white">C a r g a n d o . . . </p>
    </ngx-spinner>
  </div>

  <div class="row">
    <div class="col col-3">

      <ngb-alert type="warning" [dismissible]="false"
        *ngIf="!esperandoCarga && this.niveles && this.niveles.length == 0">
        No se obtuvieron niveles para Refacturación
      </ngb-alert>

      <div class="list-group menu-izq" *ngIf="!esperandoCarga && this.niveles && this.niveles.length > 0">
        <div *ngFor="let nivel of niveles; let i=index">
          <button type="button" (click)="mostrarDetalleNivel(i)"
            [class.seleccionado]="nivelSeleccionado && nivelSeleccionado.codigo == nivel.codigo"
            class="list-group-item list-group-item-action" [ngClass]="{desactivado: nivel.activo == false}">
            <div class="d-flex w-100 justify-content-between">
              <p>{{ nivel.nombre}} </p>
            </div>
          </button>
        </div>
      </div>
    </div>

    <div id="detalleNivel" class="col-9">
      <div class="car-body" *ngIf="!esperandoCarga && nivelSeleccionado != null">
        <app-detalle-nivel [nivelSeleccionado]='nivelSeleccionado'></app-detalle-nivel>
      </div>
    </div>
  </div>
</div>
