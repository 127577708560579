import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { NgbCalendar, NgbDatepickerConfig, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Aeroplanta } from 'src/app/shared/models/despacho/Aeroplanta';
import { PreciosService } from 'src/app/precios/precios.service';
import { TipoMovimiento } from 'src/app/shared/enums/TipoMovimiento';
import { PedidoService } from '../../pedido.service';
import { UsuarioService } from 'src/app/account/usuario.service';
import { MessageService } from 'src/app/shell/message.service';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { ReporteSaldosAlijeDTO } from 'src/app/shared/models/despacho/ReporteSaldosAlijeDTO';
import { AerovaleDetalleComponent } from '../../aerovale-detalle/aerovale-detalle.component';
import { AerovaleDetalle } from 'src/app/shared/models/despacho/AerovaleDetalle';
import { AerovalesService } from '../../aerovales.service';
import { PedidoDetalle } from 'src/app/shared/models/despacho/PedidoDetalle';
import { PedidoDetalleComponent } from '../../pedido-detalle/pedido-detalle.component';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-alijes-reportes',
  templateUrl: './alijes-reportes.component.html',
  styleUrls: ['./alijes-reportes.component.scss']
})
export class AlijesReportesComponent implements OnInit {

  model: NgbDateStruct;
  model1: NgbDateStruct;
  fechaDesde: Date;
  fechaHasta: Date;
  TipoUsuario = TipoUsuario;

  busquedaFormControl = new UntypedFormControl('');
  aeroplantaFormControl = new UntypedFormControl(null);

  obteniendoDatos: boolean;

  // aerovales
  aerovaleDetalle: AerovaleDetalle;
  obteniendoAerovale: boolean = false;
  aerovaleSeleccionado: number = null;
  
  // pedidos
  pedidoDetalle: PedidoDetalle;
  obteniendoPedido: boolean = false;
  pedidoSeleccionado: number = null;  

  /** filtros */
  aeroplantas: Aeroplanta[];
  cargandoAeroplantas: boolean = false;
  TipoMovimiento = TipoMovimiento;
  
  saldosAlije:ReporteSaldosAlijeDTO[]=[];
  saldosAlijeFiltrados:ReporteSaldosAlijeDTO[]=[];

  /** scroll infinito */
  throttle = 500;
  distance = 2;
  page = 1;
  pageSize = 20;
  obteniendoDatosScroll:boolean;

  constructor(
    private ngbDatepicker: NgbDatepickerConfig,
    private modal: NgbModal,
    private calendar: NgbCalendar,
    private spinner: NgxSpinnerService,
    private precioService: PreciosService,
    private pedidoService: PedidoService,
    private usuarioService: UsuarioService,
    private messageService:MessageService,
    private aerovaleService: AerovalesService,
    private authService:AuthService
  ) { }

  ngOnInit(): void {
    this.model = this.calendar.getToday();
    this.model1 = this.calendar.getToday();
    let inicio: any = { day: 1, month: 1, year: 2019 };
    this.ngbDatepicker.minDate = inicio;
    let fin: any = { day: 31, month: 12, year: 2050};
    this.model1 = this.calendar.getToday();
    this.ngbDatepicker.maxDate = fin;

    this.getAeroplantas();    
    this.setearFechaDesdeHasta();
    this.page=1
    this.obtenerConsumosSaldosAlije(this.page, false);
  }

  /** servicios */

  async obtenerConsumosSaldosAlije(page: number, isScroll:boolean) {

    if(!isScroll) {
      this.mostrarSpinner();
    } else {
      this.mostrarSpinnerScroll();
    }
    
    let saldo:ReporteSaldosAlijeDTO[];
    try {
      if(this.mostrarFiltroAeroplanta()) {
        saldo = await this.pedidoService.getObtenerSaldosAlijeDetallados(null, this.getFechaDesde(), this.getFechaHasta(), page, this.pageSize);
      } else {
        saldo = await this.pedidoService.getObtenerSaldosAlijeDetallados(this.getCodigoAeroplanta(), this.getFechaDesde(), this.getFechaHasta(), page, this.pageSize);      
      }
      
      if(!isScroll) {
        this.saldosAlije = saldo;
      } else {
        this.saldosAlije = this.saldosAlije.concat(saldo);
      }
      
      for (let i = 0; i < this.saldosAlije.length; i++) {
        this.saldosAlije[i].detalle = this.saldosAlije[i].detalle.sort((a, b) => new Date(a.fecha).getTime() - new Date(b.fecha).getTime());
      }       
      this.saldosAlijeFiltrados = this.saldosAlije;
      if(this.saldosAlijeFiltrados.length>0) {
        this.calcularSaldosPorCuentas();
      } 
      if(!isScroll) {
        this.ocultarSpinner();
      } else {
        this.ocultarSpinnerScroll();
      } 
    } catch (error) {
      this.messageService.showErrorMessage('Hubo un error al obtener los datos. ' + error.description);      
      this.ocultarSpinner();
    }    
  }

  onScrollSaldos(){    
    this.obtenerConsumosSaldosAlije(++this.page, true);
    this.filtrarMovimientos(); // si el usuario esta filtrando mientras scrollea
  }

  getAeroplantas() {
    this.cargandoAeroplantas = true;
    this.precioService.obtenerTodasLasAeroplantas()
      .subscribe(result => {
        this.aeroplantas = result.sort((a, b) => { if (a.nombre > b.nombre) { return 1 } else { return -1 } });        
        this.cargandoAeroplantas = false;
      });
  }

  /** Visualizar Aerovales */
  obtenerAerovale(id: string, i:number) {
    this.aerovaleDetalle = null;    
    this.obteniendoAerovale = true;    
    this.aerovaleSeleccionado=i;
    this.aerovaleService.getAerovalePorIdyTipo(id)
      .subscribe(result => {
        this.aerovaleDetalle = result;           
        this.aerovaleSeleccionado= null;  
        this.obteniendoAerovale = false;
        if(this.aerovaleDetalle.id) {
          this.mostrarAerovale();
        } else {
          this.messageService.showErrorMessage("Hubo un error al obtener el Aerovale");   
        }
      }, (err: ErrorModel) => {
          this.messageService.showErrorMessage('Hubo un error al obtener el Aerovale' + err.description);   
          this.aerovaleSeleccionado=null;  
          this.obteniendoAerovale = false;  
        });
  }

  mostrarAerovale() {
    let modalRef = this.modal.open(AerovaleDetalleComponent, { size: "lg", centered: true })
    modalRef.componentInstance.aerovaleDetalle = this.aerovaleDetalle;  
  }

  /** Visualizar Pedidos */
  mostrarPedido(item:any, i:number) {
    this.obteniendoPedido = true;    
    this.pedidoSeleccionado=i;
    let modalRef = this.modal.open(PedidoDetalleComponent, { centered: true, size: "lg", windowClass: 'myCustomModalClass', backdrop: "static"})
    modalRef.componentInstance.pedidoId = item.movimientoId; 
    modalRef.componentInstance.numeroComprobante = item.numero; 
    modalRef.componentInstance.visualizarPedido = true;   
    this.pedidoSeleccionado= null;  
    this.obteniendoPedido = false;
  }

  /** utiles */
  obtenerTipoMovimiento(tipo: number) {
    return TipoMovimiento[tipo];
  }

  formatDate(year, month, day, hour, minute, seconds): string {
    return year + '-' + month.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + '-' + day.toLocaleString(undefined, { minimumIntegerDigits: 2 })
      + 'T' + hour.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + minute.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + seconds.toLocaleString(undefined, { minimumIntegerDigits: 2 });
  }

  filtrarMovimientosPorFecha() {
    this.setearFechaDesdeHasta();
    this.busqueda.setValue('');
    this.aeroplanta.setValue(null);
    this.page=1;
    this.obtenerConsumosSaldosAlije(this.page, false);
  }

  setearFechaDesdeHasta() {
    this.fechaDesde = new Date(this.model.year, this.model.month - 1, this.model.day);
    this.fechaHasta = new Date(this.model1.year, this.model1.month - 1, this.model1.day);
    this.fechaHasta.setHours(23, 59, 59, 99);
  }

  getCodigoAeroplanta(){
    return this.usuarioService.getAeroplantaActual().codigoAeroplanta;
  }

  getFechaDesde(){
    return this.formatDate(this.fechaDesde.getFullYear(), this.fechaDesde.getMonth() + 1, this.fechaDesde.getDate(), 0, 0, 0);
  }

  getFechaHasta(){
    return this.formatDate(this.fechaHasta.getFullYear(), this.fechaHasta.getMonth() + 1, this.fechaHasta.getDate(), 23, 59, 59);
  }

  calcularSaldosPorCuentas() {
    for (let i = 0; i < this.saldosAlijeFiltrados.length; i++) {
      let acum=0;
      this.saldosAlijeFiltrados[i].detalle.forEach(e => {        
        if(e.tipoMovimiento==TipoMovimiento.SaldoAnterior || e.tipoMovimiento==TipoMovimiento.Alije) {
          acum = acum + e.cantidad
        } else {
          acum = acum - e.cantidad
        }
        e.saldo=acum;
      });
      this.saldosAlijeFiltrados[i].saldoFinal = acum;
    }    
  }

  mostrarSpinner() {
    this.obteniendoDatos = true;
    this.spinner.show('spinnerGrafico');
  }
  
  ocultarSpinner() {
    this.obteniendoDatos = false;
    this.spinner.hide('spinnerGrafico');
  }

  mostrarSpinnerScroll() {
    this.obteniendoDatosScroll = true; 
  }
  
  ocultarSpinnerScroll() {
    this.obteniendoDatosScroll = false;    
  }

  toggleDatepicker(e: any){
    e.toggle();
  }

  filtrarMovimientos() {
    this.saldosAlijeFiltrados = this.saldosAlije.filter(a => {
      let agregar = true;
      if (this.busqueda.value) {
        agregar = a.cliente && a.cliente.toLocaleLowerCase().indexOf(this.busqueda.value.toLocaleLowerCase()) > -1;
        agregar = agregar || (a.nroCuenta && a.nroCuenta.toLocaleLowerCase().indexOf(this.busqueda.value.toLocaleLowerCase()) > -1);
        if (this.aeroplanta.value!=null) {
          agregar = agregar && (a.codigoAeroplanta && a.codigoAeroplanta.toLocaleLowerCase().indexOf(this.aeroplanta.value.toLocaleLowerCase()) > -1);
        }
      } 
      
      if (this.busqueda.value == '' && this.aeroplanta.value != null) {
          agregar = a.codigoAeroplanta && a.codigoAeroplanta.toLocaleLowerCase().indexOf(this.aeroplanta.value.toLocaleLowerCase()) > -1;
      }

      return agregar;
    });    

    if (this.busqueda.value == '' && this.aeroplanta.value == null || this.aeroplanta.value == 'null') {
      this.saldosAlijeFiltrados = this.saldosAlije;
    } 
  }

  hasRole(...roles: TipoUsuario[]) {
    return this.authService.hasRole(...roles);
  }

  mostrarFiltroAeroplanta() {
    return !this.authService.hasRole(TipoUsuario.SUPERVISOR, TipoUsuario.OPERADOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO);
  }
 
  /** getters */

  get busqueda() {
    return this.busquedaFormControl;
  }

  get aeroplanta() {
    return this.aeroplantaFormControl;
  }
}
