import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { UrlApi } from '../shared/enums/urlApi';
import { Provincia } from '../shared/models/facturacion/Provincia';
import { tap } from 'rxjs/operators';
import { ParametroImpositivoEncabezado } from '../shared/models/facturacion/ParametroImpositivoEncabezado';
import { ProvinciaParametroImpositivo } from '../shared/models/facturacion/ProvinciaParametroImpositivo';
import { Localidad } from '../shared/models/despacho/Localidad';
import { ParametroImpositivoDetalle } from '../shared/models/facturacion/ParametroImpositivoDetalle';
import { environment } from 'src/environments/environment';
import { Impuestos } from '../shared/models/facturacion/Impuesto';
import { AlicuotasIIBB } from '../shared/models/facturacion/AlicuotasIIBB';


@Injectable({
  providedIn: 'root'
})
export class ParametrosImpositivosService {
  private provincias: Provincia[];
  private facturacionUrl = environment.apiServer.facturacionBaseUrl;
  private despachoUrl = environment.apiServer.baseUrl;

  listadoProvinciasEncabezados = new BehaviorSubject<ParametroImpositivoEncabezado[]>(null);
  provinciaEncabezadoSeleccionada = new BehaviorSubject<ParametroImpositivoEncabezado>(null);
  cambiosEnParametrosImp = new BehaviorSubject<Boolean>(null);

  constructor(private http: HttpClient) { }

  obtenerProvinciasEncabezadoV2(): Observable<ParametroImpositivoEncabezado[]> {
    return this.http.get<ParametroImpositivoEncabezado[]>(this.facturacionUrl + UrlApi.facturacionParametrosImpositivos)
      .pipe(
        tap((listadoProvinciasEncabezados) => this.listadoProvinciasEncabezados.next(listadoProvinciasEncabezados))
      );  
  }

  obtenerParametrosImpositivosPorProvincia(codigoProvincia) : Observable<Impuestos>{
    return this.http.get<Impuestos>(this.facturacionUrl + UrlApi.facturacionParametrosImpositivos + "/provincia" + `/${codigoProvincia}` + '/Impuestos')
      .pipe(
        tap( (provincia) => console.log("Se obtuvieron los parametros impositivos de :" + provincia.parametroProvincia.provinciaNombre ))
      );
  }

  crearParametroImpositivoEncabezado(provincia: Provincia){
    let provinciaParam = new ParametroImpositivoEncabezado();
    provinciaParam.provinciaId = provincia.id;
    provinciaParam.provinciaCodigo = provincia.codigo;
    provinciaParam.provinciaNombre = provincia.nombre;

    let listaProvinciasEncabezado = this.listadoProvinciasEncabezados.value;
    listaProvinciasEncabezado.push(provinciaParam);
    
    this.listadoProvinciasEncabezados.next(listaProvinciasEncabezado);
  }

  crearParametroImpositivo(provinciaId: string, ciudadId: string, parametroImpositivo: ParametroImpositivoDetalle): Observable<any>{
    return this.http.post<string>(      
      this.facturacionUrl + UrlApi.facturacionParametrosImpositivos + "?provinciaId="+provinciaId+"&ciudadId="+ciudadId,
      parametroImpositivo
    ).pipe(tap(() => {
      this.cambiosEnParametrosImp.next(true);
    }));    
  }

  modificarParametroImpositivo(parametroImpositivo: ParametroImpositivoDetalle, actualizarPantalla: boolean): Observable<string>{
    return this.http.put<string>(      
      this.facturacionUrl + UrlApi.facturacionParametrosImpositivos + `/${parametroImpositivo.parametroId}`,
      parametroImpositivo
    ).pipe(tap(() => {
      if(actualizarPantalla){
        this.cambiosEnParametrosImp.next(true);
      }      
    }));  
  }

  seleccionarProvinciaEncabezado(provincia: ParametroImpositivoEncabezado){
    // notificar a los observers
    this.provinciaEncabezadoSeleccionada.next(provincia)
  }

  obtenerProvincias(codigoPais: string): Observable<Provincia[]> {
    if (this.provincias)
      return of(this.provincias);
    else
      return this.http.get<Provincia[]>(this.despachoUrl + UrlApi.configuracion + `/${codigoPais}/Provincias`).pipe(
        tap((provincias) => this.provincias = provincias)
      );
  }

  obtenerLocalidades( ): Observable<Localidad[]> {
      return this.http.get<Localidad[]>(this.despachoUrl + UrlApi.configuracion + `/Ciudades`);
  }

  actualizarAlicuotasIIBB(alicuotas: AlicuotasIIBB[], provinciaCod: string): Observable<AlicuotasIIBB>{
    return this.http.put<AlicuotasIIBB>(this.facturacionUrl + UrlApi.facturacionParametrosImpositivos + '/AlicuotaIIBB/' + provinciaCod  , alicuotas)
  }
}
