<div class="modal-header">
  <h5>Leyendas del emisor: {{this.emisorSeleccionado.razonSocial}}.</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="this.modal.dismissAll()">
    
  </button>
</div>
<div class="modal-body">
  <div class="mb-3 row">
    <div class="list-group  menu-izq col-4">
      <button type="button" [class.active]="" class="btn btn-primary" (click)="nuevaLeyenda()">
        <div class="texto py-3">
          <b>Nueva leyenda</b>
        </div>
      </button>
      <div *ngFor="let ley of emisorSeleccionado.leyendas let i=index" class="">
        <button type="button" [class.active]="" class="list-group-item list-group-item-action"
          (click)="modificarLeyenda(i)">
          <div class="texto py-3">
            {{ley.descripcion}}
          </div>
        </button>
      </div>
    </div>
    <form [formGroup]="detalleLeyenda" class="col-8">
      <div class="col-12">
        <div class="row">
          <div class="col">
            <div class="mb-3">
              <div class="form-check">
                <input type="checkbox" class="form-check-input" id="checkActivo" formControlName="checkActivo">
                <label class="form-check-label" for="checkActivo">Activa</label>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="mb-3">
              <label>Posición:&nbsp;</label>
              <select class="form-select" formControlName="selecPosicion" id="selecActivo">
                <option value="0">Superior</option>
                <option value="1">Inferior</option>
              </select>
            </div>
            <!-- </div> -->
          </div>
          <!-- <div class="row"> -->
          <div class="col">
            <div class="mb-3">
              <label>Orden:&nbsp;</label>
              <input id="numIIBB" type="text" class="form-control" (keypress)="onlyNumberKey($event)"
                formControlName="inputOrden" id="inputOrden">
            </div>
          </div>
          <!-- </div> -->
          <!-- <div class="row"> -->
          <div class="col">
            <div class="mb-3">
              <label>Tipo Factura:&nbsp;</label>
              <select class="form-select" formControlName="selecFactura" id="selecFactura" >
                <option *ngFor="let ley of keysLeyendas()" [ngValue]="this.obtenerTipoFactura(ley)">{{ley}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="mb-3">
              <label>Descripción:&nbsp;</label>
              <textarea class="form-control" aria-label="With textarea" formControlName="textDescripcion"
                id="textDescripcion" rows="5" [(ngModel)]="descValue" [maxlength]="descLength" required></textarea>
                <span *ngIf="descValue?.length">{{descValue ? descValue.length : 0}}/{{descLength ? descLength : 255}}</span>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="modal-footer bg-light justify-content-center">
  <button class="btn btn-primary me-2" id="aceptar" [disabled]="!detalleLeyenda.valid || actualizandoLeyenda"
    (click)="modificar()" *ngIf="this.nuevo == false">
    <span *ngIf="!actualizandoLeyenda">Guardar</span>
    <div *ngIf="actualizandoLeyenda" class="d-flex align-items-center">
      <div class="spinner-border text-light spinner-border-sm" role="status"></div>
      <span>Guardando...</span>
    </div>
  </button>
  <button class="btn btn-primary me-2" id="aceptar" [disabled]="!detalleLeyenda.valid || actualizandoLeyenda"
    (click)="crearNuevaLeyenda()" *ngIf="this.nuevo == true">
    <span *ngIf="!actualizandoLeyenda">Guardar</span>
    <div *ngIf="actualizandoLeyenda" class="d-flex align-items-center">
      <div class="spinner-border text-light spinner-border-sm" role="status"></div>
      <span>Guardando...</span>
    </div>
  </button>
  <!-- <button type="submit" class="btn btn-primary btn-sm mt-2" (click)="cargarPrecios()">Cargar</button> -->
  <button type="button" class="btn btn-secondary" (click)="this.modal.dismissAll()">Cancelar</button>
</div>