<div [formGroup]="form">
  <div class="mb-3 col-md-6">
    <div class="mb-3 row">
      <label for="aforadorInicialFormControl" class="col-sm-4 col-form-label">Aforador Inicial</label>
      <div class="col-sm-6">
        <input id="aforadorInicialFormControl" type="number" min="0" (keypress)="this.onlyNumberKey($event)" class="form-control mb-2 me-sm-2" title="Campo obligatorio, debe ingresar aforador inicial." formControlName="aforadorInicialFormControl" placeholder="####"(change)="obtenerCantidadDespachada(); litrosA15Grados()" />
      </div>
      <div class="alert-danger" *ngIf="this.aforadorInicialFormControl.invalid && this.aforadorInicialFormControl.errors.required && (this.aforadorInicialFormControl.dirty || this.aforadorInicialFormControl.touched)">
        Debe completar el campo aforador inicial.
      </div>
    </div>

    <div class="mb-3 row">
        <label for="aforadorFinalFormControl" class="col-sm-4 col-form-label">Aforador Final:</label>
        <div class="col-sm-6">
          <input id="aforadorFinalFormControl" type="number" min="0" (keypress)="this.onlyNumberKey($event)" class="form-control mb-2 me-sm-2" title="Campo obligatorio, debe ingresar aforador final." formControlName="aforadorFinalFormControl" placeholder="####" (change)="obtenerCantidadDespachada(); litrosA15Grados()" />
        </div>
        <div class="alert alert-danger" *ngIf="this.aforadorFinalFormControl.invalid && this.aforadorFinalFormControl.errors.required && (this.aforadorFinalFormControl.dirty || this.aforadorFinalFormControl.touched)">
          Debe completar el campo aforador final.
        </div>
        <div class="alert alert-danger" *ngIf="form.invalid && this.form.hasError('aforadorInicialMayorAforadorFinal') && (aforadorInicialFormControl.dirty || aforadorInicialFormControl.touched) && (aforadorFinalFormControl.dirty || aforadorFinalFormControl.touched)">
          El aforador final debe ser mayor que el aforador inicial.
        </div>
    </div>

    <div class="mb-3 row">
      <label for="temperaturaFormControl" class="col-sm-4 col-form-label">Temperatura</label>
      <div class="col-sm-6">
        <input title="Campo obligatorio." id="temperaturaFormControl" type="number" class="form-control mb-2 me-sm-2" formControlName="temperaturaFormControl" placeholder="grados" (change)="litrosA15Grados()" />
      </div>
      <div class="alert alert-danger"
        *ngIf="(this.temperaturaFormControl.invalid && this.temperaturaFormControl.errors.required && (this.temperaturaFormControl.dirty || this.temperaturaFormControl.touched))">
        Requerido
      </div>
      <div class="alert alert-danger"
        *ngIf="temperaturaFormControl.invalid && (temperaturaFormControl.errors.temperaturaFueraDeRango) && (temperaturaFormControl.dirty || temperaturaFormControl.touched)">
        La temperatura esta fuera de rango permitido.
      </div>
    </div>

    <div class="mb-3 row">
      <label for="densidadFormControl" class="col-sm-4 col-form-label">Densidad</label>
      <div class="col-sm-6">
        <input id="densidadFormControl" type="number" class="form-control mb-2 me-sm-2" formControlName="densidadFormControl" (keypress)="formatDensidad($event)"
         (input)="this.litrosA15Grados()"/>
      </div>
      <div class="alert alert-danger"
        *ngIf="(this.densidad.invalid && this.densidad.errors.required && (this.densidad.dirty || this.densidad.touched))">
        Requerido
       </div>  
       <div class="alert alert-danger"
        *ngIf="(this.densidad.dirty || this.densidad.touched) && !this.esValidaDensidad">
        La densidad debe estar dentro del rango del producto. [{{this.esDensidadMin}} - {{this.esDensidadMax}}]
      </div>
    </div>

    <div class="mb-3 row">
      <label for="cantidadDespachadaFormControl" class="col-sm-4 col-form-label">Volumen despachado</label>
      <div class="col-sm-6">
        <input id="cantidadDespachadaFormControl" type="text" class="form-control mb-2 me-sm-2" formControlName="cantidadDespachadaFormControl" placeholder="####"  readonly/>
      </div>
    </div>

    <div class="mb-3 row">
      <label for="volumen15GradosFormControl" class="col-sm-4 col-form-label">Volumen 15°</label>
      <div class="col-sm-6">
        <input id="volumen15GradosFormControl" type="text" class="form-control mb-2 me-sm-2" formControlName="volumen15GradosFormControl" placeholder="####" readonly/>
      </div>
    </div>
  </div>
</div>
