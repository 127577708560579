<ul class="nav nav-tabs justify-content-start">
  <li  class="nav-item" *ngIf="hasRole(TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.FACTURACION, TipoUsuario.FINANZAS, TipoUsuario.SOPORTE)">
    <a class="nav-link" routerLink="emisor" routerLinkActive="active">Emisor</a>
  </li>
  <li  class="nav-item" *ngIf="hasRole(TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.TUTOR, TipoUsuario.SOPORTE)">
    <a class="nav-link" routerLink="aeroespecialidades" routerLinkActive="active">Aeroespecialidades</a>
  </li>
  <li  class="nav-item" *ngIf="hasRole(TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.TUTOR, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL)">
    <a class="nav-link" routerLink="asignarAeroplantas" routerLinkActive="active">Asignar Aeroplantas</a>
  </li>
  <li  class="nav-item" *ngIf="false &&  hasRole(TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA)">
    <a class="nav-link" routerLink="importarUsuarios" routerLinkActive="active">Importar Usuarios</a>
  </li>
  <li  class="nav-item" *ngIf="hasRole(TipoUsuario.COMERCIAL, TipoUsuario.SUPERVISOR, TipoUsuario.ADMCLIENTES, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.APROBADORBYR, TipoUsuario.FACTURACION, TipoUsuario.REFACTURACION, TipoUsuario.SOPORTE)">
    <a class="nav-link" routerLink="clientes" routerLinkActive="active">Clientes</a>
  </li>
  <li  class="nav-item" *ngIf="false && hasRole(TipoUsuario.SUPERVISOR, TipoUsuario.COMERCIAL, TipoUsuario.JEFEDEAEROPLANTA)">
    <a class="nav-link" routerLink="importarLaboratorios" routerLinkActive="active">Importar Laboratorios</a>
  </li>
  <li  class="nav-item" *ngIf="hasRole(TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.SUPERVISOR, TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.APROBADORBYR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.SEGURIDADTABLAS, TipoUsuario.IMPUESTOS, TipoUsuario.SOPORTE)">
    <a class="nav-link" routerLink="aeroplantas" routerLinkActive="active">Aeroplantas</a>
  </li>
  <li  class="nav-item" *ngIf="hasRole(TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_LN, TipoUsuario.COMERCIAL_AV, TipoUsuario.TUTOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.SOPORTE)">
    <a class="nav-link" routerLink="matriculas" routerLinkActive="active">Matriculas</a>
  </li>
  
  <!-- <li  class="nav-item" *ngIf="hasRole(TipoUsuario.SUPERVISOR, TipoUsuario.COMERCIAL, TipoUsuario.JEFEDEAEROPLANTA)">
    <a class="nav-link" routerLink="abmAeroplanta" routerLinkActive="active">ABM Aeroplantas</a>
  </li> -->
  </ul>
  <router-outlet></router-outlet>
