import { NgModule } from '@angular/core';
import { Shell } from '../shell/shell.service';
import { Router, RouterModule, Routes } from '@angular/router';
import { CierreTurnoIndexComponent } from './cierre-turno-index/cierre-turno-index.component';
import { authGuard } from '../core/authentication/auth.guard';
import { TipoUsuario } from '../shared/enums/tipoUsuario';
import { RealizarCierreTurnoComponent } from './cierre-diario/realizar-cierre-turno/realizar-cierre-turno.component';
import { CargaDatosCierreTurnoComponent } from './cierre-diario/carga-datos-cierre-turno/carga-datos-cierre-turno.component';
import { ComparativasCierreTurnoComponent } from './cierre-diario/comparativas-cierre-turno/comparativas-cierre-turno.component';
import { ReimputadorDocumentosCierreTurnoComponent } from './reimputador-documentos-cierre-turno/reimputador-documentos-cierre-turno.component';
import { RetiroEfectivoCierreTurnoComponent } from './retiro-efectivo-cierre-turno/retiro-efectivo-cierre-turno.component';
import { CierreCajaCierreTurnoComponent } from './cierre-caja-cierre-turno/cierre-caja-cierre-turno.component';
import { CierresCajaComponent } from './cierres-caja/cierres-caja.component';
import { ReportesCierreTurnoComponent } from './reportes-cierre-turno/reportes-cierre-turno.component';
import { ReportesIndexComponent } from './reportes-index/reportes-index.component';
import { ReporteAerovaleCierreComponent } from './reporte-aerovale-cierre/reporte-aerovale-cierre.component';
import { ReporteRetiroEfectivoComponent } from './reporte-retiro-efectivo/reporte-retiro-efectivo.component';
import { AuthService } from '../core/authentication/auth.service';
import { ReporteBalance7Component } from './reporte-balance7/reporte-balance7.component';



const routes: Routes = [
  Shell.childRoutes([
    { path: 'cierreTurno', redirectTo: 'cierreTurno/RealizarCierre', pathMatch: 'full' },
    { path: 'cierreTurno/Reportes', redirectTo: 'cierreTurno/Reportes/reporteCierre', pathMatch: 'full'},
    {
      path: 'cierreTurno', component: CierreTurnoIndexComponent, canActivate: [authGuard], children: [
        {
          path: 'RealizarCierre', component: RealizarCierreTurnoComponent, canActivate: [authGuard],
        },
        {
          path: 'CargaDatos', component: CargaDatosCierreTurnoComponent, canActivate: [authGuard],
        },
        {
          path: 'Comparativas', component: ComparativasCierreTurnoComponent, canActivate: [authGuard],          
          children: [

          ]
        },
        {
          path: 'ReimputadorDocumentos', component: ReimputadorDocumentosCierreTurnoComponent, canActivate: [authGuard], 
        },
        {
          path: 'RetiroEfectivo', component: RetiroEfectivoCierreTurnoComponent, canActivate: [authGuard], 
        },
        {
          path: 'CierreCaja', component: CierreCajaCierreTurnoComponent, canActivate: [authGuard],
        },
        {
          path: 'CierresCaja', component: CierresCajaComponent, canActivate: [authGuard], 
        },
        {
          path: 'Reportes', component: ReportesIndexComponent, canActivate: [authGuard], children: [
            {
              path: 'reporteCierre',
              component: ReportesCierreTurnoComponent,
              canActivate: [authGuard],              
            },
            {
              path: 'reporteAerovales',
              component: ReporteAerovaleCierreComponent,
              canActivate: [authGuard],              
            },
          ]
        },
        {      
          path: 'reportesEfectivo', component: ReporteRetiroEfectivoComponent, canActivate: [authGuard], 
        },
        {      
          path: 'reporteBalance7', component: ReporteBalance7Component, canActivate: [authGuard], 
        }        
      ]
    },
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})

export class CierreTurnoRoutingModule {
  constructor( private authService: AuthService, private router: Router ) {
    this.router.events.subscribe((event) => {
      if(event['url'] && event['url'] == '/cierreTurno') {
        this.redireccionar();
      }
    });
  }

  redireccionar() {
    if(this.authService.hasRole(TipoUsuario.SUPERVISOR, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL_AV)){
      this.router.navigate(['/cierreTurno/RealizarCierre']);
    }else if(this.authService.hasRole(TipoUsuario.RESPRODUCTO, TipoUsuario.JEFEDEAEROPLANTA)){
      this.router.navigate(['/cierreTurno/RetiroEfectivo']);
    }else if(this.authService.hasRole(TipoUsuario.OPERADOR, TipoUsuario.TUTOR, TipoUsuario.CONSCIERRECAJA, TipoUsuario.APROBADORBYR, TipoUsuario.FINANZAS, TipoUsuario.SOPORTE)){
      this.router.navigate(['/cierreTurno/CierresCaja']);
    }
  }
}
