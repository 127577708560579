<div *ngIf="obteniendoRemitos" class="border p-3">
  <loading-text-spinner [error]="errorRemitos" message="Obteniendo los remitos del cliente..."
    orientation="vertical" class="list-group-item" size="large" color="primary" textColor="dark">
  </loading-text-spinner>
</div>
<div *ngIf="!obteniendoRemitos">
  <div *ngIf="remitosPorCuenta != null && remitosPorCuenta.length > 0" class="contenedor-der">
    <div *ngFor="let cuen of remitosPorCuenta; let j = index">
      <form class="mb-3">
        <div class="col col-md-12">
          <div id="tablaDetalle" class="tabla-precios">
            <label class="form-label"><b>Cuenta N°: {{cuen.cTA_SGC}}</b></label>
            <table id="tabla" class="table table-sm table-striped table-hover">
              <thead class="text-center">
                <tr>
                  <th scope="col"> Selección </th>
                  <th scope="col"> Remito </th>
                  <th scope="col"> Fecha-Hora </th>
                  <th scope="col"> Aeroplanta </th>
                  <th scope="col"> Productos </th>
                </tr>
              </thead>
              <ng-container *ngIf="remitosPorCuenta[j].remitos.length > 0">
                <tbody class="text-center">
                  <tr *ngFor="let cli of cuen.remitos; let i = index">
                    <td>                      
                      <input style="cursor: pointer;" type="checkbox" class="form-check-input" id="customCheck{{[i]}}{{[j]}}"
                        (change)="agregarRemito(i, j, $event)" [checked]="cli.checked" />
                      <label hidden class="form-check-label" for="customCheck{{[i]}}{{[j]}}"></label>                      
                    </td>
                    <td>{{cli.remitoPrefijo}}-{{cli.remitoNumero}}</td>
                    <td>{{cli.fechaHoraCreacion | date: "d'/'MM'/'y' 'hh:mm a"}}</td>
                    <td>{{cli.codigoAeroplanta}}</td>
                    <td><ul *ngFor="let prod of cli.detalles" class="list-unstyled"><li>{{prod.producto}}</li></ul></td>
                  </tr>
                </tbody>
              </ng-container>
              <ng-container *ngIf="remitosPorCuenta[j].remitos.length == 0">
                <tbody>
                  <tr>
                    <td colspan="5">
                      <div class="alert alert-warning mt-3" role="alert">
                        No hay remitos pendientes para esta cuenta del cliente.
                      </div>
                    </td>
                  </tr>
                </tbody>
              </ng-container>
            </table>
          </div>
        </div>
      </form>
    </div>
  </div>
  <button type="button" class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2" *ngIf="remitosPorCuenta.length > 0" [disabled]="this.remitosSeleccionados.length == 0"
    (click)="mostrarRemitosAFacturar()">Facturar</button>
</div>