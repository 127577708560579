import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CondicionVenta } from 'src/app/shared/enums/condicionVenta';
import { TipoObservacionExcelPrecios } from 'src/app/shared/enums/tipoObservacionExcelPrecios';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { PrecioConsulta } from 'src/app/shared/models/precios/PrecioConsulta';
import { MessageService } from 'src/app/shell/message.service';
import { PreciosService } from '../../precios.service';
import html2canvas from 'html2canvas';
import { ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';
import { saveAs } from 'file-saver';
import { Precio } from 'src/app/shared/models/precios/Precio';
import { formatDate } from '@angular/common';
import { ModalConfirmComponent } from 'src/app/shared/generic-modals/modal-confirm.component';
import { EstadoPrecioCargado } from 'src/app/shared/enums/EstadoPrecioCargado';
import { TransferFileComponent } from 'src/app/shared/transfer-file/transfer-file.component';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-comparativa-subir-precios',
  templateUrl: './comparativa-subir-precios.component.html',
  styleUrls: ['./comparativa-subir-precios.component.css']
})
export class ComparativaSubirPreciosComponent implements OnInit {

  @Input() guidLote ;
  @Input() nivelPrecio ;
  @Input() preciosConsulta : PrecioConsulta[] ;

  @ViewChild('contenedorPreciosLote', {static: true}) contenedorPreciosLote: ElementRef;

  enumTipoObservacionExcelPrecios = TipoObservacionExcelPrecios;
  enumCondicionVenta = CondicionVenta;

  error: string = null;
  errorExportacion: string;
  guardandoPrecios: boolean = false;
  descargandoExcel: boolean = false;
  mostrarResumenCompleto: boolean = false;

  constructor(public modal: NgbActiveModal,
              private preciosService: PreciosService,
              private messageService: MessageService,
              private modalService:NgbModal) { }

  ngOnInit() {
    this.preciosConsulta.map(x => x.precio.moneda.nombre == 'PES' ? x.precio.moneda.nombre = 'Pesos' : null );
  }

  esPrecioRetroactivo(precio: Precio){
    let fechaDesde = new Date(precio.fechaDesde);
    var ahora = new Date();

    if(fechaDesde < ahora){
      return true;
    }

    return false;
  }

  descargarExcelComparativa(){
    this.descargandoExcel = true;
    this.errorExportacion = null;

    this.preciosService.consultaExportarV2(this.guidLote)
      .subscribe((result: Blob) => {
        const blob = new Blob([result], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
        saveAs(blob, "comparativa.xlsx");
        this.descargandoExcel = false;
      },
        (err: ErrorModel) => {
          this.errorExportacion = err.description + ' ' + err.message;
          this.descargandoExcel = false;
        }
      );
  }

  cargarPrecios() {

    let indice=this.preciosConsulta.findIndex(p=>this.esPrecioRetroactivo(p.precio)===true)

    if (indice!=-1){
      console.log("hay retroactivos")
      let modalRef = this.modalService.open(ModalConfirmComponent, { centered: true });
      modalRef.componentInstance.mensaje="Se están cargando precios retroactivos, ¿Está seguro?"
      modalRef.componentInstance.textoSi="Si"
      modalRef.componentInstance.textoNo="No"
      modalRef.result.then((result) => {
        this.accionCargar();
      }, () => { });

      this.error = null;
    } else {
      this.accionCargar();
    }
  }

  cancelarCargaPrecios(){
    if(this.guidLote){
      this.preciosService.uploadV2(this.guidLote, EstadoPrecioCargado.Cancelado)
      .subscribe(result => {
            this.guardandoPrecios = false;
            this.messageService.showSuccessLongMessage("Se proceso la cancelación de la carga de precios correctamente.");
            this.modal.close(false);
        },
          (err: ErrorModel) => {
            this.error = err.description + ' ' + err.message;
            this.messageService.showErrorMessage("Error al cancelar carga: " + this.error);
            this.guardandoPrecios = false;
            this.modal.close(false);
          }
      );
    }else{
      this.modal.close(false);
    }    
  }

  accionCargar(){
    let modalRef = this.modalService.open(ModalConfirmComponent, { centered: true });
      modalRef.componentInstance.mensaje="Desea proceder con la carga de " + this.preciosConsulta.length + " registros?";
      modalRef.componentInstance.textoSi="Si"
      modalRef.componentInstance.textoNo="No"
      modalRef.result.then((result) => {
      
      this.guardandoPrecios = true;
      
      this.mostrarResumenCompleto = false;
      
      this.preciosService.uploadV2(this.guidLote, EstadoPrecioCargado.Aprobado)
      .subscribe(result => {
            this.guardandoPrecios = false;
            this.messageService.showSuccessLongMessage("Se proceso la solicitud de carga de precios correctamente.");
            this.modal.close(true);
        },
          (err: ErrorModel) => {
            this.error = err.description + ' ' + err.message;
            this.guardandoPrecios = false;
          }
      );
      }, () => { });

    console.log("no hay retroactivos")    
  }

  // validaciones

  hayErroresEnPrecios(){

    var errores = this.preciosConsulta.filter(p => p.tipoObservacion == TipoObservacionExcelPrecios.Error );
    return errores.length > 0;
  }

  calcularPreciosConErrores(lista: PrecioConsulta[]){
    return lista.filter(p => p.tipoObservacion == TipoObservacionExcelPrecios.Error ).length;
  }

  calcularRetroactivosConConflicto(lista: PrecioConsulta[]){
    return lista.filter(p => this.esPrecioRetroactivo(p.precio) && p.tipoObservacion == TipoObservacionExcelPrecios.Conflicto ).length;
  }

  calcularRetroactivosSinConflicto(lista: PrecioConsulta[]){
    return lista.filter(p => this.esPrecioRetroactivo(p.precio) && p.tipoObservacion == TipoObservacionExcelPrecios.SinConflicto ).length;
  }

  calcularNoRetroactivosConConflicto(lista: PrecioConsulta[]){
    return lista.filter(p => (!this.esPrecioRetroactivo(p.precio)) && p.tipoObservacion == TipoObservacionExcelPrecios.Conflicto ).length;
  }

  calcularNoRetroactivosSinConflicto(lista: PrecioConsulta[]){
    return lista.filter(p => (!this.esPrecioRetroactivo(p.precio)) && p.tipoObservacion == TipoObservacionExcelPrecios.SinConflicto ).length;
  }

  usuarioCambioResumen(event){
    if(event.target.checked){
      this.mostrarResumenCompleto = true;
    }else{
      this.mostrarResumenCompleto = false;
    }
  }

}

