import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificacionSistemaEncabezadoDTO } from 'src/app/shared/models/notificaciones/NotificacionSistemaEncabezadoDTO';
import { NotificacionesService } from '../../notificaciones.service';
import { UsuarioService } from 'src/app/account/usuario.service';
@Component({
  selector: 'app-notificaciones-lista-sistema',
  templateUrl: './notificaciones-lista-sistema.component.html',
  styleUrls: ['./notificaciones-lista-sistema.component.css']
})
export class NotificacionesListaSistemaComponent implements OnInit {

  cargandoLista: boolean = true;  
  notificaciones: NotificacionSistemaEncabezadoDTO[] = [];
  notificacionesFiltradas: NotificacionSistemaEncabezadoDTO[];
  notificacionSeleccionada: NotificacionSistemaEncabezadoDTO;

  @Output() notificacionSistemaEncabezado: EventEmitter<NotificacionSistemaEncabezadoDTO> = new EventEmitter;
  @Input() set notificacionBuscada(texto: string) {
    this.filtrarNotificaciones(texto);
  };

  constructor(
    private notificacionService: NotificacionesService,
    private spinner: NgxSpinnerService,    
    private usuarioService: UsuarioService) { }

  // hooks

  ngOnInit() {
    this.cargandoLista = false;
    this.obtenerNotificacionesSistema();
  }

  ngOnChanges(): void {    
    
  }

  mostrarSpinner() {
    this.spinner.show('spinnerGrafico');
    this.cargandoLista = true;
  }
  
  ocultarSpinner() {    
    this.spinner.hide('spinnerGrafico');
    this.cargandoLista = false;
  }

  //llamadas al service

  obtenerNotificacionesSistema() {    
    this.mostrarSpinner();
    this.notificacionService.getNotificacionesSistemasEncabezado(this.usuarioService.getRoles())
      .subscribe(
        res => {
          this.notificaciones = res;
          this.notificacionesFiltradas = this.notificaciones.filter(notificacion => notificacion.titulo !== 'Cliente excedido');
          this.notificacionSeleccionada = this.notificaciones[0];
          this.ocultarSpinner();        
        }, () => {        
          this.ocultarSpinner();        
        }      
    ) 
  }
    
  mostrarDetalle(i: number) {
    this.notificacionSeleccionada = this.notificacionesFiltradas[i];
    this.notificacionSistemaEncabezado.emit(this.notificacionSeleccionada);   
  }

  filtrarNotificaciones(texto: string) {
    this.notificacionesFiltradas = this.notificaciones.filter(a => {
      let agregar = true;
      if (texto && texto.length>2) {
        agregar = a.titulo.toLowerCase().indexOf(texto.toLowerCase()) > -1;
      }
      return agregar;
    });
  }

}
