import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rubros',
  templateUrl: './rubros.component.html',
  styleUrls: ['./rubros.component.css']
})
export class RubrosComponent implements OnInit {

  constructor() { }

  ngOnInit() {

  }

}
