import { Pipe, PipeTransform } from '@angular/core';
import { TipoMoneda } from '../enums/tipoMoneda';

@Pipe({
  name: 'tipoMoneda'
})
export class TipoMonedaPipe implements PipeTransform {

  transform(tipo: number): string {
    return TipoMoneda[tipo];
  }

}
