<div class="card" id="islas-card">
  <div class="card-header">
    <h4 style="display: inline;" class="card-title">Lista de facturas del cierre</h4>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-12">

        <div class="spinner-container" *ngIf="this.obteniendoCanerias">
          <ngx-spinner name="spinnerCanerias" bdOpacity=0.3bd
            Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
            <p style="color: white">{{messageCanerias}}</p>
          </ngx-spinner>
        </div>
        <div *ngIf="this.canerias.length == 0 && !obteniendoCanerias" class="alert alert-warning">No hay cañerias
          para la aeroplanta seleccionada.</div>

        <table *ngIf="this.canerias.length>0 && !obteniendoCanerias" class="table table-striped table-hover"
          [formGroup]="tablaCanerias">
          <thead>
            <th class="text-center">Fecha</th>
            <th class="text-center">Prefijo</th>
            <th class="text-center">Numero</th>
            <th class="text-center">Tipo</th>
            <th class="text-center">Cliente</th>
            <th class="text-center">Productos</th>
            <th class="text-center">Rancho</th>
            <th class="text-center">Importe</th>
            <th class="text-center">Ver</th>
          </thead>
          <tbody>

            <tr formArrayName="caneria" *ngFor="let c of canerias; let i = index">
              <td class="text-center">{{ c.fechaModificacion | date:'dd/MM/yyyy hh:mm'}}</td>
              <td class="text-center">{{ c.codigo }}</td>
              <td class="text-center">{{ c.codigo }}+1</td>
              <td class="text-center">A </td>
              <td class="text-center">Juan Perez </td>
              <td class="text-center"> JetA1 </td>
              <td class="text-center">r1231512316 </td>

              <td class="text-center">
                $100
              </td>
              <td class="text-center">
                <button type="button" class="btn btn-primary btn-sm me-1" (click)="this.verFactura(content)"><i class="fa fa-eye"
                    aria-hidden="true" ngbTooltip="Ver Factura"></i></button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<ng-template #content let-modal>
  <div class="visorFacturaComoModal">
  <app-visor-comprobante [facturaObtenida]='this.facturaObtenida'></app-visor-comprobante>
 </div>
</ng-template>
