<ng-container *ngIf="!exportarPrecios">
  <nav class="navbar navbar-light bg-light navbar-expand-lg mb-3">
    <form class="flex-fill" id="formularioFiltros">
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <ul class="navbar-nav me-auto flex-row">
        <div class="nav-item" [formGroup]="searchForm">
          <input id="busquedaFormControl" type="text" class="form-control input-sm" placeholder="Búsqueda"
            formControlName="busqueda" (input)="filtrarPrecios()" placeholder="Buscar" />
        </div>
        <div ngbDropdown autoClose="outside">
          <button class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2" id="dropdownForm3" ngbDropdownToggle> Nivel
          </button>
          <div ngbDropdownMenu aria-labelledby="dropdownForm3">
            <div class="px-4 py-3">
              <div *ngFor="let nivel of niveles">
                <div class="form-check">
                  <ng-container *ngIf="this.hasRole(TipoUsuario.SUPERVISOR, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.MANDATARIO, 
                    TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.RESPRODUCTO, TipoUsuario.TUTOR)">
                    <input type="checkbox" class="form-check-input" id="{{ 'dropdownCheck' + nivel.nombre}}"
                      [checked]="defaultCheckedNivel(nivel)" [disabled]="bloquearNiveles()" name="{{nivel.nombre}}" (change)="filtrarPrecios()">
                  </ng-container>
                  <ng-container *ngIf="!this.hasRole(TipoUsuario.SUPERVISOR, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.MANDATARIO, 
                    TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.RESPRODUCTO, TipoUsuario.TUTOR)">
                    <input type="checkbox" class="form-check-input" id="{{ 'dropdownCheck' + nivel.nombre}}"
                      [(ngModel)]="nivel.seleccionado" name="{{nivel.nombre}}" (change)="filtrarPrecios()">
                  </ng-container>
                  <label class="form-check-label text-nowrap" style="text-align: left;"
                    for="{{ 'dropdownCheck' + nivel.nombre}}">
                    {{ nivel.nombre}}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div ngbDropdown autoClose="outside">
          <button class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2" id="dropdownForm2" ngbDropdownToggle> Vigencias
            al
          </button>
          <div ngbDropdownMenu aria-labelledby="dropdownForm2">
            <div class="px-4 py-3">
              <div *ngFor="let vigencia of vigencias">
                <div class="form-check">
                  <input type="checkbox" class="form-check-input" id="{{ 'dropdownCheck' + vigencia.nombre}}"
                    [(ngModel)]="vigencia.seleccionado" name="{{vigencia.nombre}}" (change)="filtrarPrecios()">
                  <label class="form-check-label text-nowrap" for="{{ 'dropdownCheck' + vigencia.nombre}}">
                    {{ vigencia.nombre}}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="">
          <div class="input-group">
            <input type="date" name="modelFecha" [(ngModel)]="modelFecha" placeholder="aaaa-mm-dd" name="dt"
              ngbDatepicker class="form-control form-control-rounded" #d="ngbDatepicker" ngbTooltip="Seleccione una fecha" (ngModelChange)="filtrarPrecios()"
              #searchDate readonly>

            <div class="input-group-append">
              <button id="botonFecha" type="button" class="input-group-btn btn btn-secondary" (click)="d.toggle()">
                <i class="fa fa-calendar" aria-hidden="true"></i></button>
            </div>
          </div>
        </div>


        <div class="nav-item">
          <div ngbDropdown autoClose="outside">
            <button class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2" id="dropdownForm1"
              ngbDropdownToggle>Estados</button>
            <div ngbDropdownMenu aria-labelledby="dropdownForm1">
              <div class="px-4 py-3">
                <div *ngFor="let estado of estados">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="{{ 'dropdownCheck' + estado.nombre}}"
                      [(ngModel)]="estado.seleccionado" name="{{estado.nombre}}" (change)="filtrarPrecios()">
                    <label class="form-check-label text-nowrap" for="{{ 'dropdownCheck' + estado.nombre}}">
                      {{ estado.nombre}}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <li class="nav-item"><button [disabled]="esperandoCarga" class="btn btn-primary" (click)="onRefreshClick()">
          Aplicar filtros</button></li>
        
        <li class="nav-item" *ngIf="procesandoFiltros" style="font-size: 16px; font-weight: 400; line-height: 24px; padding-left: 16px; padding-top: 8px;">
          <loading-text-spinner message="Filtrando ..." color="primary"></loading-text-spinner>
        </li>     
      </ul>
    </form>

    <div class="mt-2 float-end">
      <!--<button *ngIf="hasRole(TipoUsuario.PRECIOS)" type="button" class="btn btn-outline-primary me-2" (click)="desactivarPrecios()" [disabled]="actualizando">
        <span *ngIf="!this.actualizando">Pedir desactivación</span>
            <div *ngIf="this.actualizando" class="d-flex align-items-center">
              <div class="spinner-border text-light spinner-border-sm" role="status"></div>
              <span>Realizando petición...</span>
            </div>
      </button>-->

      <button type="button" class="btn btn-outline-primary" (click)="exportarPantalla()" [disabled]="expPrecios">
        <span *ngIf="!expPrecios">Exportar</span>
        <div *ngIf="expPrecios" class="d-flex align-items-center">
          <div class="spinner-border text-light spinner-border-sm" role="status"></div>
          <span>Exportando...</span>
        </div>
      </button>
      &nbsp;      
    </div>
  </nav>

  <ngb-alert [dismissible]="false" type="warning" *ngIf="this.preciosFiltradosMultifiltro && cantPrecios > 0"
    class="col col-md-12" style="display: block; margin-top: -0.5em; font-size: 0.75em; padding: 0.25rem 1.25rem">
    Se muestran {{ this.preciosFiltradosMultifiltro.length }} precio/s de {{cantPrecios}} obtenidos.
  </ngb-alert>

  <form class="mb-3">
    <div class="col col-md-12">
      <div class="spinner-container" *ngIf="esperandoCarga || actualizandoPrecios">
        <div class="form-group" style="height: 10cm;">
          <ngx-spinner name="spinnerGrafico" [ngStyle]="{'height': '400px', 'z-index':'0'}" bdOpacity=0.3bd
            Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
            <p style="color: white">{{messagePrecios}}</p>
          </ngx-spinner>
        </div>
      </div>
      
      <div id="tablaDetalle" class="tabla-precios" *ngIf="!esperandoCarga">
        <ngb-pagination *ngIf="this.paginado && this.cantPrecios" class="d-flex justify-content-center"
          (pageChange)="onPageChange($event)" [collectionSize]="collectionSize" [maxSize]="10" [(page)]="page"
          [pageSize]="pageSize">
        </ngb-pagination>
        <table id="tabla" *ngIf="this.cantPrecios" class="table table-sm table-hover">          
          <thead class="text-center letra">
            <tr>
              <th scope="col"> Nivel </th>

              <th scope="col">
                <app-item-multifiltro [lista]="preciosFiltrados" [elementos]="lotesMultiFiltro" (elementosChange)="recargarLotes($event)" [nombreAtributo]="'numeroLote'" [encabezadoColumna]="'Lotes'"></app-item-multifiltro>
              </th>

              <th scope="col">
                <app-item-multifiltro [lista]="preciosFiltrados" [elementos]="codigosMultiFiltro" (elementosChange)="recargarCodigos($event)" [nombreAtributo]="'producto.codigoProducto'"  [encabezadoColumna]="'Codigos'"></app-item-multifiltro>
              </th>
              
              <th scope="col">
                <app-item-multifiltro [lista]="preciosFiltrados" [elementos]="productosMultiFiltro" (elementosChange)="recargarProductos($event)" [nombreAtributo]="'producto.nombreProducto'" [encabezadoColumna]="'Productos'"></app-item-multifiltro>
              </th>
              
              <th scope="col"> Desde-Hasta</th>
              <th scope="col">
                <app-item-multifiltro [lista]="preciosFiltrados" [elementos]="monedasMultiFiltro" (elementosChange)="recargarMonedas($event)" [nombreAtributo]="'moneda.nombre'" [encabezadoColumna]="'Moneda'"></app-item-multifiltro>
              </th>
              <th scope="col">
                <app-item-multifiltro [lista]="preciosFiltrados" [elementos]="clientesMultiFiltro" (elementosChange)="recargarClientes($event)" [nombreAtributo]="'cliente.razonSocial'" [encabezadoColumna]="'Cliente'"></app-item-multifiltro>
              </th>
              <th scope="col">
                <app-item-multifiltro [decoratorFn]="getDescripcionTipoVenta" [lista]="preciosFiltrados" [elementos]="tiposVentaMultiFiltro" (elementosChange)="recargarTiposVenta($event)" (elementosChange)="recargarAeroplantas($event)" [nombreAtributo]="'tipoVenta'" [encabezadoColumna]="'Tipo Venta'" ></app-item-multifiltro>
              </th>
              <th scope="col"> Precio </th>
              <th scope="col">
                <app-item-multifiltro [decoratorFn]="getDescripcionEntregas" [lista]="preciosFiltrados" [elementos]="entregasMultiFiltro" (elementosChange)="recargarEntregas($event)" [nombreAtributo]="'direccionEntrega'" [encabezadoColumna]="'Entrega'"></app-item-multifiltro>
              </th>
              <th scope="col">
                <app-item-multifiltro [lista]="preciosFiltrados" [elementos]="areasMultiFiltro" (elementosChange)="recargarAreas($event)" [nombreAtributo]="'areaNegocio.nombreArea'" [encabezadoColumna]="'Area'"></app-item-multifiltro>
              </th>
              <th scope="col">
                <app-item-multifiltro [lista]="preciosFiltrados" [elementos]="segmentosMultiFiltro" (elementosChange)="recargarSegmentos($event)" [nombreAtributo]="'segmento.nombreSegmento'" [encabezadoColumna]="'Segmento'"></app-item-multifiltro>
              </th>
              <th scope="col">
                <app-item-multifiltro [decoratorFn]="getDescripcionAeroplantas" [lista]="preciosFiltrados" [elementos]="aeroplantasMultiFiltro" (elementosChange)="recargarAeroplantas($event)" [nombreAtributo]="'codigoAeroplanta'" [encabezadoColumna]="'Aeroplantas'"></app-item-multifiltro>
              </th>
              <th scope="col"> Fecha/Usuario importación</th>
              <th scope="col"> Fecha/Usuario aprobación</th>
              <th scope="col"> Historial </th>
              <th scope="col" *ngIf="hasRole(TipoUsuario.PRECIOS)"> Corrección </th>
              <th scope="col" *ngIf="hasRole(TipoUsuario.PRECIOS)"> Seleccionar</th>
            </tr>
          </thead>
          <tbody class="text-center letra">
            <tr [ngClass]="{'inactivo': !p.activo}" *ngFor="let p of preciosFiltradosMultifiltro; let i = index">

              <td>{{ p.nivelPrecio }}</td>

              <td>L{{ p.numeroLote }}</td>

              <td>{{p.producto.codigoProducto != null?p.producto.codigoProducto:
                "--"}} </td>

              <td> {{p.producto.nombreProducto != null?p.producto.nombreProducto:
                "--"}} </td>

              <td *ngIf="p.fechaDesde != null && p.fechaHasta != null">
                {{p.fechaDesde | date: "dd'/'MM'/'yy HH:mm:ss"}} -
                {{p.fechaHasta | date: "dd'/'MM'/'yy HH:mm:ss"}}</td>

              <td *ngIf="p.fechaHasta == null && p.fechaDesde"> -- </td>

              <td> {{p.moneda != null?obtenerDescripcionMoneda(p.moneda): "--"}}</td>

              <td> {{p.cliente != null?p.cliente.razonSocial: "--"}}</td>


              <td *ngIf="p.tipoVenta == null || p.tipoVenta == 0"> - </td>
              <td *ngIf="p.tipoVenta != null && p.tipoVenta == 2"> Internacional
              </td>
              <td *ngIf="p.tipoVenta != null && p.tipoVenta == 1"> Cabotaje </td>

              <td> {{p.precioContado != null?p.precioContado: "--"}}</td>

              <td> {{p.direccionEntrega != null?p.direccionEntrega: "--"}}</td> 

              <td> {{p.areaNegocio != null?p.areaNegocio.nombreArea: "--"}}</td>

              <td> {{p.segmento != null?p.segmento.nombreSegmento: "--"}}</td>

              <td>{{this.aeroplantaService.obtenerDescripcionAeroplantaPrecios(p.codigoAeroplanta)}}</td>

              <td>{{p.importoFecha | date: "dd'/'MM'/'yy"}} - {{p.importoUserName}}
              </td>

              <td *ngIf="p.aprobadoFecha != null">
                {{p.aprobadoFecha | date: "dd'/'MM'/'yy"}} - {{p.aprobadoUserName}}
              </td>
              <td *ngIf="p.aprobadoFecha == null">--</td>

              <td id="link-historial" (click)="mostrarHistorial(p)">
                <i ngbTooltip="Ver historial." class="fa fa-eye"></i>
              </td>
              <td *ngIf="p.estadoPrecio===2 && hasRole(TipoUsuario.PRECIOS)" id="link-correccion" (click)="mostrarModalCorreccion(p)">
                <i ngbTooltip="Corregir el precio." class="fa fa-edit"></i>
              </td>
              <td *ngIf="p.estadoPrecio!=2 && hasRole(TipoUsuario.PRECIOS)" id="link-correccion">
                -
              </td>
              <td class="justify-content-center" *ngIf="p.estadoPrecio == enumEstadoPrecio.Aprobado && p.activo == TipoActivoPrecio.Activo && hasRole(TipoUsuario.PRECIOS)">
                <input id="{{preciosFiltradosMultifiltro[i].id}}" [checked]="p.seleccionadoUi" 
                  (click)="agregarPrecios(preciosFiltradosMultifiltro[i])" type="checkbox" >
              </td>
            </tr>
          </tbody>
        </table>
        <ngb-pagination *ngIf="this.paginado && this.cantPrecios" class="d-flex justify-content-center"
          (pageChange)="onPageChange($event)" [collectionSize]="collectionSize" [maxSize]="10" [(page)]="page"
          [pageSize]="pageSize">
        </ngb-pagination>
      </div>
    </div>
  </form>
</ng-container>
<!-- <ng-container *ngIf="exportarPrecios">
  <app-descarga-precios (cancelado)="recargarForm()"></app-descarga-precios>
</ng-container> -->