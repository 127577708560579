import { Component, OnInit } from '@angular/core';
import { ProductoService } from 'src/app/abm/producto/producto.service';
import { UsuarioService } from 'src/app/account/usuario.service';
import { ClienteService } from 'src/app/cliente/cliente.service';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';
import { TipoDocumento } from 'src/app/shared/enums/tipoDocumento';
import { SaldoCliente } from 'src/app/shared/models/controlCredito/SaldoCliente';
import { ControlCreditoService } from '../control-credito.service';
import { saveAs } from 'file-saver';
import { FormControl } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'src/app/shell/message.service';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { ModalNotificacionComponent } from '../modal-notificacion/modal-notificacion.component';
import { ModalHistorialEstadosComponent } from '../modal-historial-estados/modal-historial-estados.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReportePendientesPadComponent } from '../reporte-pendientes-pad/reporte-pendientes-pad.component';
import { ModalCrearNotificacionComponent } from '../modal-crear-notificacion/modal-crear-notificacion.component';
import { EstadoCliente } from 'src/app/shared/enums/EstadoCliente';
import { ModalActualizarEstadoComponent } from '../modal-actualizar-estado/modal-actualizar-estado.component';
import { Estado } from 'src/app/shared/models/controlCredito/Estado';
import { NotificacionesService } from 'src/app/abm/notificacion/notificaciones.service';
import { Comercial } from 'src/app/shared/models/controlCredito/Comercial';
import { AuthService } from 'src/app/core/authentication/auth.service';

@Component({
  selector: 'app-reporte-saldos',
  templateUrl: './reporte-saldos.component.html',
  styleUrls: ['./reporte-saldos.component.scss']
})
export class ReporteSaldosComponent implements OnInit {

  saldoClientes: SaldoCliente[];
  areaFiltros = [];
  comercialesFiltros = [];
  segmentosFiltros = [];
  estadosFiltros;
  saldosFiltrados = [];
  errorEnExportacion: boolean = false;
  tipoDocumento = TipoDocumento;
  busquedaFormControl = new FormControl();
  obteniendoSaldosScroll: boolean = false;
  ocultarTabla: boolean = false;
  throttle: number = 500;
  distance: number = 2;
  page: number = 0;
  pageSize: number = 20;
  filtrando: boolean;
  jsonFiltros;
  clickeado: string;
  EstadoCliente = EstadoCliente;
  cargandoPad: boolean = false;
  bloquear: boolean = false;
  descargandoClientes: boolean = false;
  actualiza: boolean = false;
  cargandoHistorial: boolean = false;
  idNotificacionSaldos: string;
  actualizandoMonto: boolean = false;
  excedidoFiltro: boolean = false;
  obteniendoInfo: boolean = false;
  seleccionado: number;
  TipoUsuario = TipoUsuario;
  stopScroll: boolean;

  constructor(private controlCreditoService: ControlCreditoService, public authService: AuthService, private clienteService: ClienteService,
    private usuarioService: UsuarioService, private spinner: NgxSpinnerService,
    private messageService: MessageService, private modal: NgbModal, private notificacionesService: NotificacionesService) { }

  ngOnInit(): void {
    this.getSaldos();
    this.getComerciales();
    this.getSegmento();
    this.getAreas();
    this.getTipoNovedad();
    this.estadosFiltros = [{ descripcion: 'Habilitado', seleccionado: false }, { descripcion: 'Excedido', seleccionado: false }, { descripcion: 'Bloqueado', seleccionado: false }]
  }

  mostrarSpinner(){
    this.ocultarTabla = true;
    this.spinner.show('spinnerLista');
  }

  ocultarSpinner(){
    this.ocultarTabla = false;
    this.spinner.hide('spinnerLista');
  }

  getSaldos() {
    this.mostrarSpinner();
    this.controlCreditoService.getSaldosClientes(this.busqueda.value, this.page, this.pageSize).subscribe(saldos => {
      saldos.forEach(saldo => {
        if (saldo.notificacionSaldo) {
          saldo.notificacionSaldo.filtrosSeleccionados.forEach(filtro => {
            if (filtro.clave === "Monto") saldo.monto = filtro.valor;
          })
        };
      });
      this.saldoClientes = saldos;
      this.saldosFiltrados = this.saldoClientes;
      this.ocultarSpinner();
    });
  }

  verAerovales(cliente, index) {
    this.seleccionado = index
    this.bloquear = true;
    this.cargandoPad = true;
    this.controlCreditoService.getAerovales(cliente.clienteId).subscribe(aerovales => {
      if (aerovales.length > 0) {
        //se transforma la lista de aerovales en un array de productos donde el primer producto de cada aerovale contiene tambien los datos del aerovale, luego se unen los array de productos en uno.
        let aerovalesLista = [];
        let modalRef = this.modal.open(ReportePendientesPadComponent, { centered: true, backdrop: 'static', size: 'xl' });
        aerovales.forEach(aerovale => {
          aerovale.productos = aerovale.nombreProducto ? [{nombreProducto: aerovale.nombreProducto, codigoProducto: aerovale.codigoProducto, unidadMedida: aerovale.unidadMedida, volumenNaturales: aerovale.volumenNaturales}].concat(aerovale.aeroespecialidades) : aerovale.aeroespecialidades;
          aerovale.productos[0].numeroAerovale = aerovale.numeroAerovale;
          aerovale.productos[0].prefijo = aerovale.prefijo;
          aerovale.productos[0].matricula = aerovale.matricula;
          aerovale.productos[0].tipoVuelo = aerovale.tipoVuelo;
          aerovale.productos[0].rancho = aerovale.rancho;
          aerovalesLista.push(aerovale.productos)
        });
        aerovalesLista = [].concat.apply([], aerovalesLista);
        console.log(aerovalesLista);
        modalRef.componentInstance.aerovales = aerovalesLista;
        this.cargandoPad = false;
        this.bloquear = false;
        this.seleccionado = null;
      } else {
        this.cargandoPad = false;
        this.bloquear = false;
        this.messageService.showWarningMessage('El cliente no posee aerovales pendientes de facturar', false)
        this.seleccionado = null;
      }
    })
  }

  verHistorial(cliente, index) {
    this.seleccionado = index;
    this.bloquear = true;
    this.cargandoHistorial = true;
    let estados: Estado[];
    this.controlCreditoService.getHistorialBloqueos(cliente.clienteId).subscribe(estados => {
      estados.forEach(estado => {
        estado.responsable = JSON.parse(estado.responsable)
      })
      let modalRef = this.modal.open(ModalHistorialEstadosComponent, { centered: true, backdrop: 'static', size: 'xl' });
      modalRef.componentInstance.estados = estados;
      this.bloquear = false;
      this.cargandoHistorial = false;
      this.seleccionado = null;
    })

  }

  agregarNotificacion(cliente) {
    let modalRef = this.modal.open(ModalCrearNotificacionComponent, { centered: true, backdrop: 'static' });
    modalRef.componentInstance.cliente = cliente;
    modalRef.componentInstance.idNotificacionSaldos = this.idNotificacionSaldos;
  }

  agregarMailNotificacion(cliente: SaldoCliente) {
    let modalRef = this.modal.open(ModalNotificacionComponent, { centered: true, backdrop: 'static' });
    modalRef.componentInstance.cliente = cliente;
    modalRef.componentInstance.idNotificacionSaldos = this.idNotificacionSaldos;
  }


  initRequest(isScroll: boolean) {
    this.mostrarSpinnerScroll();
  }

  mostrarSpinnerScroll() {
    this.obteniendoSaldosScroll = true;
  }

  ocultarSpinnerScroll() {
    this.obteniendoSaldosScroll = false;
  }

  procesarResult(saldos, isScroll: boolean) {
    saldos?.length==0 ? this.stopScroll=true : this.stopScroll=false;
    console.log('stop scrolling: ', this.stopScroll);
    if (!isScroll) {
      this.saldoClientes = saldos;
      this.saldosFiltrados = saldos;
    } else {
      this.saldosFiltrados ? this.saldosFiltrados = this.saldosFiltrados.concat(saldos) : this.saldosFiltrados = saldos;
      this.ocultarSpinnerScroll();
    }
  }

  procesarError(error, isScroll: boolean) {
    if (!isScroll) {
      this.messageService.showErrorMessage('Hubo un error al obtener los datos. ' + error.description);
    } else if (isScroll) {
      this.messageService.showErrorMessage('Hubo un error al obtener los datos en el scroll. ' + error.description);
      this.ocultarSpinnerScroll();
    }
  }

  onScrollSaldos() {
    if (this.filtrando) {
      this.initRequest(true)
      this.controlCreditoService.getSaldosFiltro(this.jsonFiltros,
        ++this.page, this.pageSize, this.excedidoFiltro, this.busqueda.value)
        .subscribe(res => {
          this.procesarResult(res, true);
        }, (err: ErrorModel) => {
          this.procesarError(err, true);
        });
    } else {
      this.initRequest(true);
      this.controlCreditoService.getSaldosClientes(this.busqueda.value,
        ++this.page, this.pageSize)
        .subscribe(res => {
          this.procesarResult(res, true);
        }, (err: ErrorModel) => {
          this.procesarError(err, true);
        }
        );
    }
  }

  aplicarFiltros() {
    this.stopScroll = false;
    this.filtrando = true;
    this.bloquear = true;
    this.mostrarSpinner();
    this.page = 0;
    let segmentos = [];
    let areas = [];
    let comerciales: Comercial[] = [];
    let estados = [];
    this.segmentosFiltros.forEach(segmento => {
      if (segmento.seleccionado) segmentos.push(segmento.codigo);
    });
    this.areaFiltros.forEach(area => {
      if (area.seleccionado) areas.push(area.codigo);
    });
    this.comercialesFiltros.forEach(comercial => {
      if(!comercial.email) comercial.email = ''; 
      if(!comercial.nombresCompletos) comercial.nombresCompletos = '';
      if (comercial.seleccionado) comerciales.push({email: comercial.email, nombreCompleto: comercial.nombresCompletos});
    });
    this.estadosFiltros.forEach(estado => {
      if (estado.seleccionado && estado.descripcion != 'Excedido') estados.push(estado.descripcion);
      if(estado.descripcion == 'Excedido') estado.seleccionado ? this.excedidoFiltro = true : this.excedidoFiltro = false;
    })

    if (!segmentos.length && !areas.length && !comerciales.length && !estados.length && !this.estadosFiltros) {
      this.messageService.showErrorMessage('Debe seleccionar por lo menos un filtro');
      this.bloquear = false;
      this.ocultarSpinner();
    } else {
      if (!segmentos.length) {
        this.segmentosFiltros.forEach(segmento => segmentos.push(segmento.codigo));
      }
      if (!areas.length) {
        this.areaFiltros.forEach(area => areas.push(area.codigo));
      }
      if (!estados.length) {
        this.estadosFiltros.forEach(estado =>{ 
          if(estado.descripcion != 'Excedido') estados.push(estado.descripcion);
        });
      }
      /*if (!comerciales.length) {
        this.comercialesFiltros.forEach(comercial => comerciales.push(comercial.email));
      }*/
      this.jsonFiltros = {
        "AreaCodigo": areas,
        "SubAreaCodigo": segmentos,
        "Estado": estados,
        "Comerciales": comerciales,
      }
      this.controlCreditoService.getSaldosFiltro(this.jsonFiltros, this.page, this.pageSize, this.excedidoFiltro, this.busqueda.value).subscribe(saldos => {
        saldos.forEach(saldo => {
          if (saldo.notificacionSaldo) {
            saldo.notificacionSaldo.filtrosSeleccionados.forEach(filtro => {
              if(filtro.clave === "Monto") saldo.monto = filtro.valor;
            })
          }
        });
        this.ocultarSpinner();
        this.saldosFiltrados = saldos;
        this.bloquear = false;
      }, (err: ErrorModel) => {
        this.messageService.showErrorMessage('Hubo un error al obtener los datos. ' + err.description);
      });
    }
  }

  buscarSaldos() {
    this.stopScroll = false;
    this.bloquear = true;
    this.mostrarSpinner();
    this.resetFiltros();
    this.page = 0;
    this.controlCreditoService.getSaldosClientes(this.busqueda.value, this.page, this.pageSize).subscribe(res => {
      res.forEach(saldo => {
        if (saldo.notificacionSaldo) {
          saldo.notificacionSaldo.filtrosSeleccionados.forEach(filtro => {
            if (filtro.clave === "Monto") saldo.monto = filtro.valor;
          })
        }
      })
      this.saldosFiltrados = res;
      this.ocultarSpinner();
      this.bloquear = false;
    }, (err: ErrorModel) => {
      this.procesarError(err, false);
      this.ocultarSpinner();
      this.bloquear = false;
    });
  }

  resetFiltros() {
    this.filtrando = false;
    this.jsonFiltros = null;
    this.excedidoFiltro = false;
    this.segmentosFiltros.forEach(filtro => filtro.seleccionado = false);
    this.comercialesFiltros.forEach(filtro => filtro.seleccionado = false);
    this.areaFiltros.forEach(filtro => filtro.seleccionado = false);
    this.estadosFiltros.forEach(estado => estado.seleccionado = false);
  }

  actualizarSaldo(clienteSaldo, index): void {
    this.seleccionado = index;
    this.bloquear = true;
    this.actualiza = true;
    let tipoDocumento = clienteSaldo.tipoDocumento;
    let numeroDocumento = clienteSaldo.clienteCuit;
    this.controlCreditoService.actualizarSaldo(tipoDocumento, numeroDocumento).subscribe(cliente => {
      clienteSaldo.limite = cliente.limite;
      clienteSaldo.saldoPAD = cliente.saldoPAD;
      clienteSaldo.saldo = cliente.saldo;
      clienteSaldo.fechaHoraLecturaLimite = cliente.fechaHoraLecturaLimite;
      clienteSaldo.fechaHoraLecturaSaldo = cliente.fechaHoraLecturaSaldo;
      this.bloquear = false;
      this.actualiza = false;
      this.seleccionado = null;
    }, (err: ErrorModel) => {
      this.procesarError(err, false);
      this.bloquear = false;
      this.actualiza = false;
      this.seleccionado = null;
    });
  }

  actualizarMonto(cliente: SaldoCliente, index) {
    this.seleccionado = index;
    this.bloquear = true;
    this.actualizandoMonto = true;
    let notificacion = {
      "Activo": true,
      "Emails": cliente.notificacionSaldo.emails,
      "TipoNovedad": {
        "Id": this.idNotificacionSaldos,
        "Descripcion": "Saldos"
      },
      "Mensaje": "El cliente " + cliente.clienteRazonSocial + " supero el monto asignado de " + cliente.monto,
      "Titulo": "Cliente excedido",
      "Usuarios": [{
        "Id": this.usuarioService.getIdUsuario(),
        "NombresCompleto": this.usuarioService.getNombresCompletosUsuario(),
        "DNI": this.usuarioService.getDniUsuario(),
        "Email": this.usuarioService.getEmailUsuario()
      }],
      "FiltrosSeleccionados": []
    }

    cliente.notificacionSaldo.filtrosSeleccionados.forEach(filtro => {
      if (filtro.clave === "Monto") {
        filtro.valor = cliente.monto.toString()
        notificacion.FiltrosSeleccionados.push(filtro)
      } else {
        notificacion.FiltrosSeleccionados.push(filtro)
      }
    })

    this.controlCreditoService.actualizarNotificacion(notificacion, cliente.notificacionSaldo.id).subscribe(notificacion => {
      this.bloquear = false;
      this.actualizandoMonto = false;
      this.messageService.showSuccessMessage('El monto fue actualizado exitosamente.')
      this.seleccionado = null;
    }, (err: ErrorModel) => {
      this.messageService.showErrorMessage('Hubo un error al actualizar el monto.' + err.description);
      this.bloquear = false;
      this.actualizandoMonto = false;
      this.seleccionado = null;
    });
  }

  cambiarEstado(cliente): void {
    //if (cliente?.notificacionSaldo?.usuarios[0].email.includes('%comercial%')){
      let modalRef = this.modal.open(ModalActualizarEstadoComponent, { centered: true, backdrop: 'static' });
      modalRef.componentInstance.cliente = cliente;
    //}else{
      //console.log("prueba");
    //}
  }

  mostrarDatos(cliente: string) {
    this.clickeado = cliente;
  }

  getComerciales() {
    this.usuarioService.getUsuariosPorRolYAeroplanta(TipoUsuario.COMERCIAL, "", true).subscribe(comerciales => {
      comerciales.sort(function(a, b) {
        let x = a.nombresCompletos.toUpperCase();
        let y = b.nombresCompletos.toUpperCase();
        return (x < y) ? -1 : (x > y) ? 1 : 0;
    });
      comerciales.forEach(comercial => this.comercialesFiltros.push({ ...comercial, seleccionado: false }));
    });
  }

  getSegmento() {
    this.clienteService.getSubAreas().subscribe(segmentos => {
      segmentos.forEach(segmento => this.segmentosFiltros.push({ ...segmento, seleccionado: false }));
    });
  }

  getAreas() {
    this.clienteService.getAreas().subscribe(areas => {
      areas.forEach(area => this.areaFiltros.push({ ...area, seleccionado: false }));
    });
  }

  getTipoNovedad() {
    //mandando 15 trae la lista de novedades donde se encuentra "Saldo"
    this.notificacionesService.getTipoNovedadPorRoles([15]).subscribe(tiposNovedad => {
      let novedadSaldo = tiposNovedad.find(saldo => saldo.descripcion === 'Saldos');
      this.idNotificacionSaldos = novedadSaldo.id;
    })
  }

  descargarExcel(saldoClientes) {
    saldoClientes.forEach(saldo => {
      saldo.TotalDeuda = Math.round(saldo.saldo + saldo.saldoPAD);
      saldo.SaldoFinal = Math.round(saldo.limite - saldo.saldo - saldo.saldoPAD);
      saldo.comerciales = saldo.comerciales[0] ? saldo.comerciales[0] : "";
    })
    this.descargandoClientes = true;
    this.bloquear = true;
    this.mostrarSpinner();
    this.errorEnExportacion = false;
    this.controlCreditoService.descargarExcelActualizacionSaldosClientes(saldoClientes)
      .subscribe((res: Blob) => {
        const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
        saveAs(blob, "ListadoDeSaldoClientes");
        this.ocultarSpinner();
        this.descargandoClientes = false;
        this.bloquear = false;
      }, (err: ErrorModel) => {
        this.errorEnExportacion = true;
        this.descargandoClientes = false;
        this.messageService.showErrorMessage('Hubo un error al obtener los datos. ' + err.description);
        this.bloquear = false;
        this.ocultarSpinner();
      });
  }

  get busqueda() {
    return this.busquedaFormControl;
  }

}
