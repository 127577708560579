<div class="modal-header">
  <h5 class="modal-title justify-content-center">Consulta de precios.</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="consultaFormGroup">
    <div class="row">

      <!-- PRODUCTO -->
      <div class="mb-3 col-md-6">
        <div class="mb-3 row">
          <div class="col-md-4">
            <label for="productoFormControl" id="labelProducto" class="col-form-label">
              *Producto:
            </label>
          </div>
          <div class="col-md-8">
            <div class="input-group mb-2">
              <input id="productoFormControl" type="text" class="form-control" formControlName="productoFormControl" placeholder="Seleccione una opcion..."
                  [inputFormatter]="formatterProducto" [(ngModel)]="modelProducto" [resultFormatter]="formatterProducto" title="Campo obligatorio, debe seleccionar un producto."
                  (change)="consultarPreciosBase()" [ngbTypeahead]="searchProducto" (selectItem)="selectProducto($event)" />
            </div>
            <loading-text-spinner *ngIf="cargandoProductos" [error]="errorDestinos" message="Obteniendo productos..."
              color="primary">
            </loading-text-spinner>
          </div>
        </div>
      </div>

      <!-- FECHAS-->

      <div class="mb-3 col-md-6">
        <div class="mb-3 row">
          <div class="col-md-4">
            <label for="productoFormControl" id="labelProducto" class="col-form-label">
              Fecha:
            </label>
          </div>
          <div class="col-md-8">
            <div class="input-group mb-2">
              <input type="date" class="form-control" placeholder="aaaa-mm-dd" formControlName="fechaFormControl"
                name="dt" ngbDatepicker #d="ngbDatepicker" value="modelDesde" [ngModel]="modelDesde"
                (change)="consultarPreciosBase()" ngbTooltip="Seleccione una fecha de vencimiento del precio"
                class="form-control form-control-rounded" #searchDate readonly>
              <div class="input-group-append">
                <button id="botonDesde" type="button" class="input-group-btn btn btn-secondary" (click)="d.toggle()">
                  <i class="fa fa-calendar" aria-hidden="true"></i></button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Moneda -->

      <div class="mb-3 col-md-6">
        <div class="mb-3 row">
          <div class="col-md-4">
            <label for="monedaFormControl" id="labelMoneda" class="col-form-label">
              *Moneda:
            </label>
          </div>
          <div class="col-md-8">
            <div class="input-group mb-2">
              <select id="monedaFormControl" type="text" class="form-select" formControlName="monedaFormControl"
                title="Seleccione un tipo de moneda" (change)="consultarPreciosBase()">
                <option [value]="null" disabled defaultselected>Seleccione una opción...</option>
                <option *ngFor="let mon of moneda" [value]="mon.id">
                  {{ mon.nombre }}
                </option>
              </select>
            </div>
            <loading-text-spinner *ngIf="cargandoMonedas" [error]="errorDestinos" message="Obteniendo monedas..."
              color="primary">
            </loading-text-spinner>
          </div>
        </div>
      </div>

      <!-- Aeroplanta -->

      <div class="mb-3 col-md-6">
        <div class="mb-3 row">
          <div class="col-md-4">
            <label for="aeroplantaFormControl" id="labelAeroplanta" class="col-form-label">
              Aeroplanta:
            </label>
          </div>
          <div class="col-md-8">
            <div class="input-group mb-2">
              <select id="aeroplantaFormControl" type="text" class="form-select"
                formControlName="aeroplantaFormControl" title="Seleccione una aeroplanta"
                (change)="consultarPreciosBase()">
                <option [value]="null" disabled defaultselected>Seleccione una opción...</option>
                <option *ngFor="let aero of aeroplantas" [value]="aero.codigoAeroplanta">
                  {{aero.nombre}}
                </option>
                <option [value]="null">Sin aeroplanta</option>
              </select>
            </div>
            <loading-text-spinner *ngIf="cargandoAeroplantas" [error]="errorDestinos"
              message="Obteniendo aeroplantas..." color="primary">
            </loading-text-spinner>
          </div>
        </div>
      </div>

      <!-- Cliente-->

      <div class="mb-3 col-md-6">
        <div class="mb-3 row">
          <div class="col-md-4">
            <label for="clienteFormControl" id="labelCliente" class="col-form-label">
              Cliente:
            </label>
          </div>
          <div class="col-md-8">
            <div class="input-group mb-2">
              <input id="clienteFormControl" type="text" class="form-control" formControlName="clienteFormControl" placeholder="Seleccione una opcion..."
                  [inputFormatter]="formatter" [(ngModel)]="modelCliente" [resultFormatter]="formatter" title="Seleccione un cliente."
                  (change)="consultarPreciosBase()" [ngbTypeahead]="search" (selectItem)="selectCustomer($event)" />
            </div>
            <loading-text-spinner *ngIf="cargandoClientes" [error]="errorDestinos" message="Obteniendo clientes..."
              color="primary">
            </loading-text-spinner>
          </div>
        </div>
      </div>

      <!-- Condicion Venta -->

      <div class="mb-3 col-md-6">
        <div class="mb-3 row">
          <div class="col-md-4">
            <label for="condicionFormControl" id="labelCondicionVenta" class="col-form-label">
              Cond. venta:
            </label>
          </div>
          <div class="col-md-8">
            <div class="input-group mb-2">
              <select id="condicionFormControl" type="text" class="form-select" formControlName="condicionFormControl"
                title="Seleccione una condicion de venta" (change)="consultarPreciosBase()">
                <option [value]="null" disabled defaultselected>Seleccione una opción...</option>
                <option *ngFor="let cCondicion of keysCondicionVenta()" [ngValue]="condicionVenta[cCondicion]">
                  {{cCondicion}}
                </option>
              </select>
            </div>
            <loading-text-spinner *ngIf="cargandoClientes" [error]="errorDestinos" message="Obteniendo clientes..."
              color="primary">
            </loading-text-spinner>
          </div>
        </div>
      </div>

      <!-- Tipo Venta -->

      <div class="mb-3 col-md-6">
        <div class="mb-3 row">
          <div class="col-md-4">
            <label for="ventaFormControl" id="labelVenta" class="col-form-label">
              Tipo venta:
            </label>
          </div>
          <div class="col-md-8">
            <div class="input-group mb-2">
              <select id="ventaFormControl" type="text" class="form-select" formControlName="ventaFormControl"
                title="Seleccione un tipo de venta" (change)="consultarPreciosBase()">
                <option [value]="null" disabled defaultselected>Seleccione una opción...</option>
                <option *ngFor="let cVentas of keysTipoVenta()" [ngValue]="tipoVenta[cVentas]">
                  {{ cVentas }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <!-- Area -->

      <div class="mb-3 col-md-6">
        <div class="mb-3 row">
          <div class="col-md-4">
            <label for="areaFormControl" id="labelArea" class="col-form-label">
              Area:
            </label>
          </div>
          <div class="col-md-8">
            <div class="input-group mb-2">
              <select id="areaFormControl" type="text" class="form-select" formControlName="areaFormControl"
                title="Seleccione un area" (change)="consultarPreciosBase()">
                <option [value]="null" disabled defaultselected>Seleccione una opción...</option>
                <option *ngFor="let a of area" [value]="a.codigoArea">
                  {{a.nombreArea}}
                </option>
                <option [value]="null">Sin área</option>
              </select>
            </div>
            <loading-text-spinner *ngIf="cargandoAreas" [error]="errorDestinos" message="Obteniendo areas..."
              color="primary">
            </loading-text-spinner>
          </div>
        </div>
      </div>

      <!-- Segmento -->

      <div class="mb-3 col-md-6">
        <div class="mb-3 row">
          <div class="col-md-4">
            <label for="segmentoFormControl" id="labelArea" class="col-form-label">
              Segmento:
            </label>
          </div>
          <div class="col-md-8">
            <div class="input-group mb-2">
              <select id="segmentoFormControl" type="text" class="form-select" formControlName="segmentoFormControl"
                title="Seleccione un segmento" (change)="consultarPreciosBase()">
                <option [value]="null" disabled defaultselected>Seleccione una opción...</option>
                <option *ngFor="let seg of segmento" [value]="seg.codigoSegmento">
                  {{seg.nombreSegmento}}
                </option>
                <option [value]="null">Sin segmento</option>
              </select>
            </div>
            <loading-text-spinner *ngIf="cargandoSegmentos" [error]="errorDestinos" message="Obteniendo segmentos..."
              color="primary">
            </loading-text-spinner>
          </div>
        </div>
      </div>

      <!-- Tipo Entrega -->

      <div class="mb-3 col-md-6">
        <div class="mb-3 row">
          <div class="col-md-4">
            <label for="entregaFormControl" id="labelEntrega" class="col-form-label">
              Tipo entrega:
            </label>
          </div>
          <div class="col-md-8">
            <div class="input-group mb-2">
              <select id="entregaFormControl" type="text" class="form-select" formControlName="entregaFormControl"
                title="Seleccione un tipo de entrega" (change)="consultarPreciosBase()">
                <option [value]="null" disabled defaultselected>Seleccione una opción...</option>
                <option *ngFor="let ubi of ubicacion" [value]="ubi.direccion">
                  {{ubi.direccion}}
                </option>
                <option [value]="0">Sin ubicación</option>
              </select>
            </div>
            <loading-text-spinner *ngIf="cargandoUbicaciones" [error]="errorDestinos" message="Obteniendo segmentos..."
              color="primary">
            </loading-text-spinner>
          </div>
        </div>
      </div>

      <div class="col col-md-3"></div>
      <div class="col col-md-6">
        <table id="tabla" class="table table-sm table-striped table-hover">
          <thead class="text-center">
            <tr>
              <th>Orden</th>
              <th>Precio</th>
            </tr>
          </thead>
          <tbody class="text-center">
            <tr *ngFor="let p of precios; let i = index">
              <th>{{i}}</th>
              <th>{{p.precioContado}}</th>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col col-md-3"></div>
    </div>
  </form>
</div>
