import { Component, Input, OnInit } from '@angular/core';
import { HistorialNotificacionDTO } from 'src/app/shared/models/notificaciones/HistorialNotificacionDTO';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';
import { NotificacionesService } from '../../notificaciones.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MessageService } from 'src/app/shell/message.service';

@Component({
  selector: 'app-notificaciones-historial-sistema',
  templateUrl: './notificaciones-historial-sistema.component.html',
  styleUrls: ['./notificaciones-historial-sistema.component.scss']
})
export class NotificacionesHistorialSistemaComponent implements OnInit {

  @Input() notificacionSistemaId:string;
  @Input() notificacionSistemaTitulo:string;

  historialNotificacionDTO: HistorialNotificacionDTO[] = [];
  obteniendoDatos:boolean=false;

  constructor(private spinner: NgxSpinnerService,
    private datePipe: DatePipe,
    public activeModal: NgbActiveModal,
    private notificacionService: NotificacionesService,
    private messageService:MessageService
    ) { }

  ngOnInit(): void {
    this.obteniendoInformacionHistorial();
  }

  obteniendoInformacionHistorial() {
    this.mostrarSpinner();
    this.notificacionService.getHistorialEnvio(this.notificacionSistemaId)
      .subscribe(result => {
        this.historialNotificacionDTO = result;
        this.ocultarSpinner();
        if(this.historialNotificacionDTO.length < 1) {          
          this.messageService.showErrorMessage('Notificación ' + this.notificacionSistemaTitulo + ' sin historial para mostrar.');
          this.activeModal.dismiss();
        }
      }, ()=>{
        this.ocultarSpinner();
      });    
  }

  mostrarSpinner(){
    this.obteniendoDatos = true;
    this.spinner.show('spinnerGrafico');
  }

  ocultarSpinner(){
    this.obteniendoDatos = false;
    this.spinner.hide('spinnerGrafico');
  }

}
