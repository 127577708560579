<div class="col-md-6 offset-md-3 mt-2">
  <div style="padding: 1rem 1rem; border-bottom: 1px solid #dee2e6; border-top-left-radius: calc(0.3rem - 1px); border-top-right-radius: calc(0.3rem - 1px);" class="modal-header">
    <h5 class="modal-title justify-content-center">Precios Actuales</h5>
  </div>
  <div style="padding: 1rem" class="modal-body">
    <div class="row">
      <div class="col-md-2 offset-md-2">
        <label for="productoFormControl" id="productoLabel" class="col-form-label">Producto:</label>
      </div>
      <div class="col col-md-6">
        <select id="productoFormControl" type="text" class="form-select" [formControl]="productoFormControl">
          <option [value]="null" selected disabled >Seleccione un producto</option>
          <option *ngFor="let p of productos" [ngValue]="p.codigoProducto">
              {{ p.nombreProducto }}
          </option>
        </select>            
        <loading-text-spinner *ngIf="obteniendoProductos" message="Obteniendo productos..." color="primary">
        </loading-text-spinner>
      </div>
    </div>  
    
    <div class="mt-4" style="text-align: center;">
      <p style="font-size: 24px;"><b>Precio: ${{ this.precio | number: '1.5-5' }}</b></p>
    </div>

    <div class="form-text">
      *Precio final para el producto seleccionado con impuestos incluidos para cliente Consumidor Final.
    </div>
  </div>
  <div class="modal-footer justify-content-center mt-3">
    <button class="btn btn-primary" id="aceptar" [disabled]="obteniendoProductos || this.producto.value ==null" (click)="consultarPrecio()">
      <span *ngIf="!obteniendoPrecio">Consultar</span>
      <div *ngIf="obteniendoPrecio" class="d-flex align-items-center">
          <div class="spinner-border text-light spinner-border-sm" role="status"></div>
          <span>Consultando...</span>
      </div>
    </button>
  </div>
 </div>