import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Aeroplanta } from 'src/app/shared/models/despacho/Aeroplanta';
import { MessageService } from 'src/app/shell/message.service';
import { ComprobanteService } from 'src/app/facturacion/comprobante.service';
@Component({
  selector: 'app-aeroplantas-lista',
  templateUrl: './aeroplantas-lista.component.html',
  styleUrls: ['./aeroplantas-lista.component.scss']
})
export class AeroplantasListaComponent implements OnInit {

  cargandoLista:boolean = false;
  aeroplantas: Aeroplanta[];
  aeroplantasFiltradas: Aeroplanta[];
  aeroplantaSeleccionada: Aeroplanta;

  constructor(
    private messageService: MessageService,
    private comprobanteService: ComprobanteService,
    private spinner: NgxSpinnerService) { 
  }

  ngOnInit(): void {
    this.obtenerAeroplantas();
  }

  mostrarSpinner() {
    this.spinner.show('spinnerGrafico');
    this.cargandoLista = true;
  }
  
  ocultarSpinner() {    
    this.spinner.hide('spinnerGrafico');
    this.cargandoLista = false;
  }

  obtenerAeroplantas() {
    this.mostrarSpinner();
    this.comprobanteService.getDatosAeroplanta(null)
      .subscribe(result => {
        this.aeroplantas = result.sort((a, b) => { if (a.nombre > b.nombre) { return 1 } else { return -1 } });
        this.aeroplantasFiltradas = this.aeroplantas;
        this.ocultarSpinner();
      });
  }
  
  /*obtenerAeroplantas = async () => {
    try {
      this.mostrarSpinner();
      this.aeroplantas = await this.cierreTurnoService.getAeroplantas();
      this.aeroplantasFiltradas = this.aeroplantas;
      this.ocultarSpinner();
    } catch (error) {
      this.mostrarSpinner();
      this.messageService.showErrorMessage('Error al obtener los datos de las aeroplantas');
      this.aeroplantas = await this.cierreTurnoService.getAeroplantasFake();
      this.aeroplantasFiltradas = this.aeroplantas;
      this.ocultarSpinner();
    }
  }*/

  usuarioSeleccionoAeroplanta(i:number) {    
    this.aeroplantaSeleccionada = this.aeroplantasFiltradas[i];
  }
}