import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'comprobanteSap'
})
export class ComprobanteSapPipe implements PipeTransform {

  transform(pref:number, numero:number): string {
    if (pref !== undefined && pref !== null && numero !== undefined && numero !== null) {
      return pref.toString().padStart(4, '0') + '_' + numero.toString().padStart(15, '0');
    } else {
      return "";
    }
  }

}
