import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { UsuarioService } from 'src/app/account/usuario.service';
import { estadoSistemaSeg } from 'src/app/shared/enums/estadoSistemaSeg';
import { Usuario } from 'src/app/shared/models/despacho/Usuario';
import { EstadoSistemaSeguridad } from 'src/app/shared/models/stock/EstadoSistemaSeguridad';
import { SistemaSeguridad } from 'src/app/shared/models/stock/SistemaSeguridad';
import { MessageService } from 'src/app/shell/message.service';
import { StockService } from '../../../stock.service';

@Component({
  selector: 'app-nuevo-sistema',
  templateUrl: './nuevo-sistema.component.html',
  styleUrls: ['./nuevo-sistema.component.scss']
})
export class NuevoSistemaComponent implements OnInit {

  @Input() sistema: SistemaSeguridad;
  @Input() editar: boolean;
  @Input() nuevoEstado: number;
  @Input() cambioEstado: boolean;
  
  sistemasForm = new UntypedFormGroup({
    nombreFormControl: new UntypedFormControl(''),
    observacionesFormControl: new UntypedFormControl(''),
    //estadoFormControl: new FormControl(''),
    fechaFSFormControl: new UntypedFormControl(''),
    horaFSFormControl: new UntypedFormControl(''),
    usuarioFormControl: new UntypedFormControl(null)
  })

  grabando: boolean = false;
  estSistema = estadoSistemaSeg;
  model: NgbDateStruct;
  horaAct: any;
  cambioFueraServicio: boolean;
  editando: boolean;
  nuevo: boolean;
  usuarios: Usuario[] = [];
  obteniendoUsuarios: boolean = false;
  usuarioSeleccionado = null;
  errorUsuarios: boolean = false;

  constructor(public activeModal: NgbActiveModal, private usuarioService: UsuarioService, private stockService: StockService, 
    private calendar: NgbCalendar, private messageService: MessageService) { }

  ngOnInit(): void {
    var horaActual = new Date();
    this.model = this.calendar.getToday();
    this.horaAct = { hour: horaActual.getHours(), minute: horaActual.getMinutes(), second: horaActual.getSeconds() };
    this.editando = this.editar;
    
    if(this.sistema != null) {
      !this.editar && this.nuevoEstado == 1 ? this.cambioFueraServicio = true : false;
    } else {
      !this.editar ? this.nuevo = true : false;
    }

    if(this.nuevo) {
      this.sistemasForm.controls.nombreFormControl.addValidators([Validators.required, Validators.maxLength(20)]);
      //this.sistemasForm.controls.estadoFormControl.addValidators([Validators.required]);
    }

    if(this.editando) {
      this.sistemasForm.controls.nombreFormControl.addValidators([Validators.required, Validators.maxLength(20)]);
      this.sistemasForm.controls.nombreFormControl.setValue(this.sistema.nombre);
    }

    if(this.cambioFueraServicio) {
      this.sistemasForm.controls.fechaFSFormControl.addValidators([Validators.required]);
      this.sistemasForm.controls.horaFSFormControl.addValidators([Validators.required]);
      //this.sistemasForm.controls.usuarioFormControl.addValidators([Validators.required]);
    }

    this.getUsuarios();
    
  }

  agregarSistema(){

    if(this.nuevo) {
      this.grabando = true;
      let sistema = new SistemaSeguridad();
      let est = new EstadoSistemaSeguridad();
      let codAeroplanta = this.usuarioService.getAeroplantaActual().codigoAeroplanta;

      est.estado = 0 //this.estadoFormControl.value;
      est.responsable = this.usuarioService.getNombresCompletosUsuario();
      est.observaciones = '';
      est.fechaHoraUltimoCambio = "2020-01-15T21:00:00.00";
      est.fechaHoraFS = null;
      est.id = Guid.EMPTY;
      est.autorizoFS = '';
      sistema.nombre = this.nombreFormControl.value;
      sistema.ultimoEstado = est;
      sistema.id = Guid.EMPTY;

      this.stockService.crearSistemaSeguridad(codAeroplanta, sistema)
        .subscribe(result => {
          this.messageService.showSuccessMessage('El sistema de seguridad fue creado con exito.');
          this.grabando = false;
          this.activeModal.close(true);
        }, () => {
          this.messageService.showErrorMessage('Ocurrio un error, vuelva a intentar.');
          this.grabando = false;
        })
    }

    if(this.editando) {
      this.grabando = true;
      this.sistema.nombre = this.nombreFormControl.value;

      this.stockService.editarSistemaSeguridad(this.sistema.id, this.sistema)
        .subscribe(result => {
          this.messageService.showSuccessMessage('El sistema de seguridad fue actualizado con exito.');
          this.grabando = false;
          this.activeModal.close(true);
        }, () => {
          this.messageService.showErrorMessage('Ocurrio un error, vuelva a intentar.');
          this.grabando = false;
        })

    }

    if(this.cambioEstado && !this.cambioFueraServicio) {
      this.grabando = true;
      this.sistema.ultimoEstado.estado = this.nuevoEstado;
      this.sistema.ultimoEstado.observaciones = this.observacionesFormControl.value;
      const fecha = this.fechaFSFormControl.value;
      const hora = this.horaFSFormControl.value;
      this.sistema.ultimoEstado.responsable = this.usuarioService.getNombresCompletosUsuario();
      this.sistema.ultimoEstado.fechaHoraFS = this.formatDate(fecha['year'], fecha['month'], fecha['day'], hora['hour'], hora['minute']);
      
      this.stockService.cambiarEstadoSistemaSeguridad(this.sistema.id, this.sistema.ultimoEstado)
        .subscribe(result => {
          this.messageService.showSuccessMessage('El sistema de seguridad fue actualizado con exito.');
          this.grabando = false;
          this.activeModal.close(true);
        }, () => {
          this.messageService.showErrorMessage('Ocurrio un error, vuelva a intentar.');
          this.grabando = false;
        })
    }
    
    if(this.cambioFueraServicio) {
      
      if(this.usuarioSeleccionado == null) {
        this.messageService.showErrorMessage('Debe seleccionar usuario que autorizo FS');
        this.usuarioFormControl.markAsTouched();
        return;
      }
      
      this.grabando = true;
      this.sistema.ultimoEstado.estado = 1;
      this.sistema.ultimoEstado.observaciones = this.observacionesFormControl.value;
      this.sistema.ultimoEstado.responsable = this.usuarioService.getNombresCompletosUsuario();
      this.sistema.ultimoEstado.autorizoFS = JSON.stringify(this.usuarioSeleccionado);
      
      const fecha = this.fechaFSFormControl.value;
      const hora = this.horaFSFormControl.value;
      this.sistema.ultimoEstado.fechaHoraFS = this.formatDate(fecha['year'], fecha['month'], fecha['day'], hora['hour'], hora['minute']);
      
      this.stockService.cambiarEstadoSistemaSeguridad(this.sistema.id, this.sistema.ultimoEstado)
        .subscribe(result => {
          this.messageService.showSuccessMessage('El sistema de seguridad fue actualizado con exito.');
          this.grabando = false;
          this.activeModal.close(true);
        }, () => {
          this.messageService.showErrorMessage('Ocurrio un error, vuelva a intentar.');
          this.grabando = false;
        })

    }

  }

  searchUsuario = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => this.usuarios == null || term.length < 2 || this.obteniendoUsuarios ? [] :
        this.usuarios.filter(u => this.containsString(this.usuarioFormControl.value, u.nombresCompletos)).slice(0, 10))
    );
  
  formatterUsuario = (u: Usuario) => `${u.nombresCompletos}`;
    
  selectUsuario(event: any): void {
      this.usuarioFormControl.setValue(event.item);
      this.usuarioSeleccionado = this.usuarioFormControl.value;
    }

  getUsuarios() {
    this.obteniendoUsuarios = true;
    this.usuarioService.obtenerTodosLosUsuarios(true, false)
      .subscribe(result => {
        this.usuarios = result;
        this.obteniendoUsuarios = false;
      }, () => {
        this.obteniendoUsuarios = false;
        this.messageService.showErrorMessage('Error al obtener usuarios.');
        this.errorUsuarios = true;
      })
  }

  containsString(searchValue: string, ...searchIn: string[]): boolean {
    const concat = searchIn.toString();
    return concat.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
  }

  borrarUsuario() {
    this.usuarioFormControl.setValue(null);
    this.usuarioSeleccionado = null;
  }

  formatDate(year, month, day, hour, minute): string {
    return year + '-' + month.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + '-' + day.toLocaleString(undefined, { minimumIntegerDigits: 2 })
      + 'T' + hour.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + minute.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':00';
  }

  obtenerEstado(est) {
    return Number(this.estSistema[est]);
  }

  keysEstados(): Array<string> {
    var keys = Object.keys(estadoSistemaSeg);
    keys = keys.slice(keys.length / 2);
    return keys;
  }

  get nombreFormControl() {
    return this.sistemasForm.get('nombreFormControl');
  }

  //get estadoFormControl() {
  //  return this.sistemasForm.get('estadoFormControl');
  //}

  get observacionesFormControl() {
    return this.sistemasForm.get('observacionesFormControl');
  }

  get fechaFSFormControl() {
    return this.sistemasForm.get('fechaFSFormControl');
  }

  get horaFSFormControl() {
    return this.sistemasForm.get('horaFSFormControl');
  }

  get usuarioFormControl() {
    return this.sistemasForm.get('usuarioFormControl');
  }

}
