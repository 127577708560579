import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { tap } from 'rxjs/operators';
import { ModalMotivoRechazoComponent } from 'src/app/cliente/clientes-aprobar-modificaciones/modal-motivo-rechazo/modal-motivo-rechazo.component';
import { TipoActivoPrecio } from 'src/app/shared/enums/tipoActivoPrecio';
import { TipoDesactivacion } from 'src/app/shared/enums/tipoDesactivacion';
import { TipoEstadoPrecio } from 'src/app/shared/enums/tipoEstadoPrecio';
import { TipoPrecioReporte } from 'src/app/shared/enums/TipoPrecioReporte';
import { TipoPrecioRetroactivo } from 'src/app/shared/enums/TipoPrecioRetroactivo';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { EstadoPrecio } from 'src/app/shared/models/precios/EstadoPrecio';
import { ModificacionDto } from 'src/app/shared/models/precios/ModificacionDto';
import { MessageService } from 'src/app/shell/message.service';
import { PreciosService } from '../precios.service';
import { PrecioFacturaComponent } from './precio-factura/precio-factura.component';

@Component({
  selector: 'app-aprobar-modificacion',
  templateUrl: './aprobar-modificacion.component.html',
  styleUrls: ['./aprobar-modificacion.component.css']
})
export class AprobarModificacionComponent implements OnInit {
  esperandoCarga: boolean = false;
  messagePrecios: string = "C a r g a n d o . . ."
  preciosFiltrados: ModificacionDto[];
  listaPrecios: ModificacionDto[];
  preciosEnviar: ModificacionDto[] = [];
  enumTipoDesactivacion = TipoDesactivacion;
  enumTipoActivoPrecio = TipoActivoPrecio;
  actualizando: boolean = false;

  mensajeAccesoInsuficiente: string = null;

  searchForm = new UntypedFormGroup({
    busqueda: new UntypedFormControl(''),
  });

  tipoDesactivacion: TipoDesactivacion;

  usuarioApruebaRetroactivos: boolean;

  constructor(
    private preciosService: PreciosService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private modalService: NgbModal) {    
  }

  // hooks

  ngOnInit() {
    this.usuarioApruebaRetroactivos = this.usuarioPuedeAprobarPreciosRetroactivos();

    this.obtenerModificaciones();
  }

  // llamadas al service

  obtenerModificaciones(){
    if(this.usuarioApruebaRetroactivos){
      this.getModificaciones(TipoPrecioRetroactivo.Retroactivos);
    }else{
      this.getModificaciones(TipoPrecioRetroactivo.NoRetroactivos);
    }
  }

  usuarioPuedeAprobarModificacion() {

    return true;
/*
    var usuarioNiveles = this.preciosService.getNivelUsuarioLogueado();

// Ver condicion para poder aprobar modificaciones precios
    if (usuarioNiveles && usuarioNiveles.nivelNativo.) {
      return true;
    }

    if(usuarioNiveles){
      for (let nivelDelegado of usuarioNiveles.nivelesDelegados) {
        if (nivelDelegado.) {
          return true;
        }
      }
    }    

    return false;
    */
  }

  getModificaciones(tipo: TipoPrecioRetroactivo) {
    this.initPreciosRequest();

    if (!this.usuarioPuedeAprobarModificacion()) {
      this.spinner.hide('spinnerGrafico');
      this.esperandoCarga = false;
      this.mensajeAccesoInsuficiente = "No posee acceso para aprobar modificacioens de precios.";
      return;
    }

    this.preciosService.getPreciosModificacionPendiente(tipo)
      .subscribe(result => {

        result = this.procesarResult(result);

        for (let i = 0; result.length > i; i++) {
          this.preciosFiltrados.push(result[i]);
          this.listaPrecios.push(result[i]);
        }

        this.spinner.hide('spinnerGrafico');
        this.esperandoCarga = false;
        this.filtrarPrecios();
      })
  }

  // form

  aprobarModificaciones() {
    if (this.preciosEnviar.length == 0) {
      this.messageService.showErrorMessage('No se seleccionó ningún precio para aprobar.');
      return;
    } else {
      var listaIdsSeleccionados = this.getCheckedItemList();

      var estado = new EstadoPrecio();
      estado.responsable = this.preciosService.obtenerStringResponsableV2();
      estado.activo = TipoActivoPrecio.Activo;

      if(this.usuarioApruebaRetroactivos){
        estado.estadoPrecio = TipoEstadoPrecio.AprobadoRetroactivo;
      }else{
        estado.estadoPrecio = TipoEstadoPrecio.Aprobado;
      }

      this.actualizando = true;

      this.preciosService.cambiarEstadoModificacion(estado, listaIdsSeleccionados)
        .subscribe(nivelAprobacion => {
          this.messageService.showSuccessLongMessage('Se proceso la solicitud de aprobación de precios correctamente.');
          this.actualizando = false;
          this.preciosEnviar = [];
          this.obtenerModificaciones();
        },
          (err: ErrorModel) => {
            this.messageService.showErrorMessage('Hubo un problema al aprobar los precios, por favor intente nuevamente.')
          });
    }
  }

  rechazarModificaciones() {
    if (this.preciosEnviar.length == 0) {
      this.messageService.showErrorMessage('No se seleccionó ningún precio para rechazar.');
      return;
    } else {

      const modalRef = this.modalService.open(ModalMotivoRechazoComponent, { size: 'lg', backdrop: 'static' });
      modalRef.result.then((motivo) => {
        if(motivo == null){
          return;
        }

        this.accionRechazar(motivo);
      }).catch((error) => {
        return;
      });
    }
  }

  accionRechazar(motivo){
    var listaIdsSeleccionados = this.getCheckedItemList();

    var estado = new EstadoPrecio();
    estado.responsable = this.preciosService.obtenerStringResponsableV2();
    estado.estadoPrecio = TipoEstadoPrecio.Rechazado;
    estado.motivoRechazo = motivo;

    this.actualizando = true;

    this.preciosService.cambiarEstadoModificacion(estado, listaIdsSeleccionados)
      .subscribe(nivelAprobacion => {
        this.messageService.showSuccessLongMessage('Se proceso la solicitud de rechazar los precios.');
        this.actualizando = false;
        this.preciosEnviar = [];
        this.obtenerModificaciones();
      },
        (err: ErrorModel) => {
          this.messageService.showErrorMessage('Hubo un problema al rechazar los precios, por favor intente nuevamente.')
        });
  }

  getCheckedItemList() {
    var listaIdsSeleccionados = [];

    for (var i = 0; i < this.preciosEnviar.length; i++) {
      listaIdsSeleccionados.push(this.preciosEnviar[i].id);
    }

    return listaIdsSeleccionados;
  }

  onRefreshClick() {
    this.obtenerModificaciones();
  }

  initPreciosRequest() {
    this.preciosFiltrados = [];
    this.listaPrecios = [];
    this.esperandoCarga = true;
    this.spinner.show('spinnerGrafico');
  }

  procesarResult(result: ModificacionDto[]) {
    result.forEach(p => {
      if(p.precio.username && p.precio.username.indexOf(',') > -1) p.precio.username = JSON.parse(p.precio.username.replace(/'/g,'"')).nombresCompletos;
    });

    return result;
  }

  filtrarPrecios() {

    if (this.preciosFiltrados != null) {
      this.preciosFiltrados = this.listaPrecios.filter(m => {
        let agregar = true;
        if (this.busquedas.value) {

          if (m.precio.cliente != null) {
            agregar = m.precio.cliente.razonSocial && m.precio.cliente.razonSocial.toLocaleLowerCase().indexOf(this.busquedas.value.toLocaleLowerCase()) > -1;
          } else {
            agregar = false;
          }

          agregar = agregar || (m.precio.nombreProducto && m.precio.nombreProducto.toLocaleLowerCase().indexOf(this.busquedas.value.toLocaleLowerCase()) > -1);
          agregar = agregar || (m.precio.codigoProducto && m.precio.codigoProducto.toLocaleLowerCase().indexOf(this.busquedas.value.toLocaleLowerCase()) > -1);
          agregar = agregar || (m.precio.precioContado.toString() && m.precio.precioContado.toString().toLocaleLowerCase().indexOf(this.busquedas.value.toLocaleLowerCase()) > -1);
          //agregar = agregar || (a.numeroLote && ("l" + a.numeroLote).toString().toLocaleLowerCase().indexOf(this.busquedas.value.toLocaleLowerCase()) > -1);

        }

        if (this.busquedas.value == '') {
          this.preciosFiltrados = this.listaPrecios;
        }
        return agregar;
      });
    }
  }

  agregarPrecios(precioSeleccionado: ModificacionDto) {
    if (!precioSeleccionado.seleccionadoUi) {
      precioSeleccionado.seleccionadoUi = true;
      this.preciosEnviar.push(precioSeleccionado);
    } else {
      const index: number = this.preciosEnviar.indexOf(precioSeleccionado);
      if (index !== -1) {
        this.preciosEnviar.splice(index, 1);
      }
      precioSeleccionado.seleccionadoUi = false;
    }
  }

  usuarioPuedeAprobarPreciosRetroactivos() {

    try{
      var usuarioNiveles = this.preciosService.getNivelUsuarioLogueado();

      if(usuarioNiveles?.nivelNativo != null)
      {
        if (usuarioNiveles && usuarioNiveles.nivelNativo.apruebaRetroactivos) {
          return true;
        }
      }
      

      if(usuarioNiveles){
        for (let nivelDelegado of usuarioNiveles.nivelesDelegados) {
          if (nivelDelegado.apruebaRetroactivos) {
            return true;
          }
        }
      }

      return false;
    }catch(ex){
      console.log(ex);
      return false;
    }
  }

  onSeleccionarTodosPrecios(isChecked: boolean){
      
    for(let p of this.preciosFiltrados){
      if(isChecked){
        p.seleccionadoUi = true;        
        this.preciosEnviar.push(p);
      }else{
        p.seleccionadoUi = false;
        this.preciosEnviar = [];
      }        
    }      
  }

  obtenerModificacionesPrecios(p: ModificacionDto ){
    var modificaciones: { valorViejo: string, valorNuevo: string } [] = []; 

    if(p.precioContadoViejo != p.precioContadoNuevo){
      var valorViejo = "<b>Precio:</b> " + p.precioContadoViejo;
      var valorNuevo = "<b>Precio:</b> " + p.precioContadoNuevo;
      modificaciones.push({valorViejo: valorViejo, valorNuevo: valorNuevo})
    }
  
    if(p.fechaDesdeVieja != p.fechaDesdeNueva){
      var valorViejo = "<b>Precio:</b> " + p.fechaDesdeVieja;
      var valorNuevo = "<b>Precio:</b> " + p.fechaDesdeNueva;
      modificaciones.push({valorViejo: valorViejo, valorNuevo: valorNuevo})
    }

    if(p.fechaHastaVieja != p.fechaDesdeNueva){
      var valorViejo = "<b>Precio:</b> " + p.fechaHastaVieja;
      var valorNuevo = "<b>Precio:</b> " + p.fechaDesdeNueva;
      modificaciones.push({valorViejo: valorViejo, valorNuevo: valorNuevo})
    }
    
    return modificaciones;
  }

  verFacturasAfectadas(precio: ModificacionDto){
    let modalRef = this.modalService.open(PrecioFacturaComponent, {centered: true, size: 'lg', backdrop: 'static'});
    modalRef.componentInstance.precioBuscado = precio;
  }

  // getters

  get busquedas() {
    return this.searchForm.get('busqueda');
  }

}
