import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { Pedido } from '../shared/models/despacho/Pedido';
import { Precarga } from '../shared/models/despacho/Precarga';
import { Producto } from '../shared/models/despacho/Producto';
import { PedidoDetalle } from '../shared/models/despacho/PedidoDetalle';
import { Abastecedora } from '../shared/models/despacho/Abastecedora';
import { HttpErrorHandler, HandleError } from '../http-error-handler.service';
import { environment } from 'src/environments/environment';
import { PagedList } from '../shared/models/despacho/PagedList';
import { UsuarioService } from '../account/usuario.service';
import { Rancho } from '../shared/models/ranchos/Rancho';
import { SaldoCuentaDTO } from '../shared/models/despacho/SaldoCuentaDTO';
import { ReporteSaldosAlijeDTO } from '../shared/models/despacho/ReporteSaldosAlijeDTO';
import { ClientesSaldos } from '../shared/models/despacho/ClientesSaldos';
import { VuelosTamsDTO } from '../shared/models/despacho/VuelosTamsDTO';
import { AerolineaDTO } from '../shared/models/despacho/AerolineaDTO';
import { AerolineaEncabezadoDTO } from '../shared/models/despacho/AerolineaEncabezadoDTO';
import { AerolineaCuentaDTO } from '../shared/models/despacho/AerolineaCuentaDTO';
import { PedidoMonitoreo } from '../shared/models/despacho/PedidoMonitoreo';
import { MonitoreoConfiguracion } from '../shared/models/despacho/MonitoreoConfiguracion';


@Injectable({
  providedIn: 'root'
})

export class PedidoService {

  baseUrl = environment.apiServer.baseUrl;
  private aerolineasUrl = this.baseUrl + '/AerolineasV1';
  private pedidosUrl = this.baseUrl + '/PedidosV2/';
  private monitoreoUrl = this.baseUrl + '/MonitoreoConfiguracionV1/';
  private saldosAlijeUrl = this.baseUrl + '/SaldosAlijeV1/';
  private productosUrl =environment.apiServer.baseUrl + '/ProductosV1/';
  private handleError: HandleError;
  private pedidos: Pedido[];
  private aerocombustibles: Producto[];

  constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler, private usuarioService: UsuarioService) {
    this.handleError = httpErrorHandler.createHandleError('Pedidos');
  }


  getPedidos(): Observable<Pedido[]> {
    return this.http.get<Pedido[]>(this.pedidosUrl)
      .pipe(
        tap(pedidos => {
          this.pedidos = pedidos;
        }
        ));
  }

  getPedidosPagina(page : number, pageSize : number): Observable<PagedList> {
    return this.http.get<PagedList>(this.pedidosUrl, {
      params: {
        page: page.toString(),
        pageSize: pageSize.toString(),
        codigoAeroplanta: this.usuarioService.getAeroplantaActual().codigoAeroplanta
      }})
    .pipe(
     catchError(this.handleError('Error obteniendo pedidos paginados', new PagedList()))
    );
  }

  getPedidoPorId(id: string): Observable<PedidoDetalle> {
    return this.http.get<PedidoDetalle>(this.pedidosUrl + id)
      .pipe(
       catchError(this.handleError('Obtener pedido', new PedidoDetalle()))
      );
  }

  /** Se crea un pedido */
  CrearPedido(pedido: PedidoDetalle): Observable<string> {

    pedido.codigoAeroplanta = this.usuarioService.getAeroplantaActual().codigoAeroplanta;

    return this.http.post<string>(this.pedidosUrl, pedido)
      .pipe(
        tap((pedidoId: string) => {
          console.log(`Added pedido w/ id=${pedidoId}`);
        }),
        catchError(this.handleError('Crear pedido', ''))
      );
  }

  ModificarPedido(pedido: PedidoDetalle): Observable<string> {
    return this.http.put<string>(this.pedidosUrl, pedido)
      .pipe(
        tap((pedidoId: string) => {
          console.log(`Pedido modificado w/ id=${JSON.stringify(pedido)}`);
        }),
        catchError(this.handleError('Modificar pedido', ''))
      );
  }

  AgregarPrecarga(pedidoId: string, precarga: Precarga): Observable<string> {
    return this.http.post<string>(this.pedidosUrl + pedidoId + '/Precargas', precarga).pipe(
      tap((newPrecarga: string) => console.log(`added precarga w/ id=${newPrecarga}`)),
      catchError(this.handleError('Agregar precarga', ""))
    );
  }

  getAerocombustible(): Observable<Producto[]> {
   return this.http.get<Producto[]>(this.productosUrl + 'Aerocombustibles')
        .pipe(
          tap(a => this.aerocombustibles = a)
        );
  }

  getAbastecedoras(codigoAeroplanta: string): Observable<Abastecedora[]> {
    return this.http.get<Abastecedora[]>(environment.apiServer.stockBaseUrl +'/AeroplantasV1/'+codigoAeroplanta+"/Abastecedoras")
      .pipe(
        catchError(this.handleError('Obtener Abastecedoras', []))
      );
  }

  anularPedido(id: string): Observable<any> {
    return this.http.delete(this.pedidosUrl + 'AnularPedido/' + id)
      .pipe(
        tap(_ => console.log(`Anular pedido id=${id}`)),
        catchError(this.handleError('Anular pedido', new PedidoDetalle()))
      );
  }

  anularPrecarga(precargaId: string): Observable<any> {
    return this.http.delete(this.pedidosUrl + 'AnularPrecarga/' + precargaId)
      .pipe(
        tap(_ => console.log(`Anular precarga id=${precargaId}`)),
        catchError(this.handleError('Anular pedido', new PedidoDetalle()))
      );
  }

  cerrarPedido(id: string): Observable<any> {
    return this.http.put(this.pedidosUrl + 'Cerrar/' + id, null)
      .pipe(
        tap(_ => console.log(`Cerrar pedido id=${id}`))
      );
  }
  ModificarPedidoConAerovales(pedido: PedidoDetalle, rancho: string): Observable<any>{
    return this.http.put(this.pedidosUrl + pedido.id + '/ConAerovales?rancho=' + rancho , pedido)
    .pipe(
      tap(_ => console.log(`Pedido anulado id=${pedido.id}`)),
      catchError(this.handleError('Anular pedido', new PedidoDetalle()))
    );
  }

  //https://despacho-dev.aeroplantas.com.ar:80/api/SaldosAlijeV1/30710979088/SaldosAlije
  getObtenerSaldosAlijePorCliente(cuit:string, codigoAeroplanta:string) : Promise<SaldoCuentaDTO[]> {
    return this.http.get<SaldoCuentaDTO[]>(this.saldosAlijeUrl + cuit + "/SaldosAlije?codigoAeroplanta=" + codigoAeroplanta).toPromise();      
  }

  //https://despacho-dev.aeroplantas.com.ar:80/api/SaldosAlijeV1/SaldosAlijeDetallados
  getObtenerSaldosAlijeDetallados(codigoAeroplanta:string, desde: string, hasta:string, page: number, pageSize:number) : Promise<ReporteSaldosAlijeDTO[]> {
    return this.http.get<ReporteSaldosAlijeDTO[]>(this.saldosAlijeUrl + "SaldosAlijeDetallados?codigoAeroplanta=" + codigoAeroplanta + "&fechaDesde=" + desde + "&fechaHasta=" + hasta + "&page=" + page.toString() + "&pageSize=" + pageSize.toString()).toPromise();    
  }
  
  //https://despacho-dev.aeroplantas.com.ar:80/api/SaldosAlijeV1/Clientes
  getObtenerClientesSaldos(codigoAeroplanta:string) : Observable<ClientesSaldos[]> {
    return this.http.get<ClientesSaldos[]>(this.saldosAlijeUrl + "Clientes?codigoAeroplanta=" + codigoAeroplanta);    
  }

  //PedidosV2/Tams?movtp={movtp}&idarpt={CodigoAeroplanta}
  //movtp => A: Arribos D:Partidas
  getObtenerPedidosTams(movtp:string, codigoAeroplanta:string) : Observable<VuelosTamsDTO[]> {
    return this.http.get<VuelosTamsDTO[]>(this.pedidosUrl + "Tams?movtp=" + movtp + "&idarpt=" + codigoAeroplanta);      
  }
  
  getObtenerPedidosTamsAsync(movtp:string, codigoAeroplanta:string) : Promise<VuelosTamsDTO[]> {
    return this.http.get<VuelosTamsDTO[]>(this.pedidosUrl + "Tams?movtp=" + movtp + "&idarpt=" + codigoAeroplanta).toPromise();      
  }

  //https://despacho-dev.aeroplantas.com.ar/api/AerolineasV1
  getAerolineas():Observable<AerolineaDTO[]> {
    return this.http.get<AerolineaDTO[]>(this.aerolineasUrl)
  }

  getAerolinea(id:string):Observable<AerolineaDTO> {
    return this.http.get<AerolineaDTO>(this.aerolineasUrl + "/" + id);
  }

  getAerolineasEncabezado():Observable<AerolineaEncabezadoDTO[]> {
    return this.http.get<AerolineaEncabezadoDTO[]>(this.aerolineasUrl + "/AerolineasEncabezado");
  }
 
  //https://despacho-dev.aeroplantas.com.ar/api/AerolineasV1/CuentasAerolinea/D2C6BD9C-C3CA-4255-A0B6-1137B5534C81?codigoOrigen=AEP&codigoDestino=HOO
  getCuentasAerolinea(id:string, origen:string, destino:string):Promise<AerolineaCuentaDTO[]> {
    return this.http.get<AerolineaCuentaDTO[]>(this.aerolineasUrl + "/CuentasAerolinea/" + id + "?codigoOrigen="+origen+"&codigoDestino="+destino).toPromise();
  }

  putAerolineas(id:string, aerolinea:AerolineaDTO):Observable<AerolineaDTO> {
    return this.http.put<AerolineaDTO>(this.aerolineasUrl + '/' + id, aerolinea)
  }

  postAerolineas(aerolinea:AerolineaDTO):Observable<AerolineaDTO> {
    return this.http.post<AerolineaDTO>(this.aerolineasUrl, aerolinea)
  }

  // https://despachos.aeroplantas.com.ar/api/PedidosV2/MonitoreoPedidos/?codigoAeroplanta=AEP
  getMonitoreoPedidos(codigoAeroplanta:string) : Promise<PedidoMonitoreo[]>{
    return this.http.get<PedidoMonitoreo[]>(this.pedidosUrl + "MonitoreoPedidos/?codigoAeroplanta=" + codigoAeroplanta).toPromise();  
  }  

  //https://despacho-dev.aeroplantas.com.ar/api/MonitoreoConfiguracionV1/MonitoreoConfiguracion/?codigoAeroplanta=AEP
  getMonitoreoConfiguracion(codigoAeroplanta:string) : Observable<MonitoreoConfiguracion>{
    return this.http.get<MonitoreoConfiguracion>(this.monitoreoUrl + "MonitoreoConfiguracion/?codigoAeroplanta=" + codigoAeroplanta);  
  }

  //https://despacho-dev.aeroplantas.com.ar/api/MonitoreoConfiguracionV1/ModificarMonitoreoConfiguracion
  putMonitoreoConfiguracion(configuracion:MonitoreoConfiguracion) : Observable<MonitoreoConfiguracion>{
    return this.http.put<MonitoreoConfiguracion>(this.monitoreoUrl + "ModificarMonitoreoConfiguracion", configuracion);  
  }
}

