import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Cliente } from 'src/app/shared/models/cliente/Cliente';
import { ClienteEncabezado } from 'src/app/shared/models/cliente/ClienteEncabezado';
import { ClienteService } from '../cliente.service';

@Component({
  selector: 'app-clientes-lista',
  templateUrl: './clientes-lista.component.html',
  styleUrls: ['./clientes-lista.component.css']
})
export class ClientesListaComponent implements OnInit {
  @Output() clienteSeleccionado: EventEmitter<ClienteEncabezado> = new EventEmitter;
  @Output() clienteVerificar: EventEmitter<ClienteEncabezado[]> = new EventEmitter;
  @Output() nuevo: EventEmitter<boolean> = new EventEmitter;
  @Output() terminoCargaClientes: EventEmitter<boolean> = new EventEmitter;

  @Input()
  set clienteParaAgregar(cli: ClienteEncabezado){
    this.clientesFiltrados.push(cli);
    
  }

  @Input()
  set clienteBuscado(texto: string) {
    this.filtrarClientes(texto);
  };

  cargandoClientes: boolean;
  clientes: ClienteEncabezado[] = []; //luego cambiar al cliente del alta nuevo.
  clientesFiltrados: ClienteEncabezado[] = [];
  

  constructor(private clienteService: ClienteService, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.getClientes();
  }

  getClientes() {
    this.cargandoClientes = true;
    this.spinner.show('spinnerGrafico');
    this.clienteService.getClientesEncabezado()
      .subscribe(result => {
        this.clientes = result;
        this.clientesFiltrados = result;
        this.spinner.hide('spinnerGrafico');
        this.cargandoClientes = false;
        this.terminoCargaClientes.emit(true)
      }
      )
  }

  mostrarDetalle(i: number) {
    this.clienteSeleccionado.emit(this.clientesFiltrados[i]);
    this.clienteVerificar.emit(this.clientes);
    this.nuevo.emit(false);

  }

  filtrarClientes(texto: string) {
    this.clientesFiltrados = this.clientes.filter(a => {
      let agregar = true;
      if (texto) {
        agregar = a.razonSocial.toLowerCase().indexOf(texto.toLowerCase()) > -1; // Razón Social
        agregar = agregar || a.numeroDocumento.toLowerCase().indexOf(texto.toLowerCase()) > -1; // CUIT
        if(a.ctaSap != null){
          agregar = agregar || a.ctaSap.toLowerCase().indexOf(texto.toLowerCase()) > -1; // Nro Cuenta
        }
      }
      return agregar;
    });
  }

}
