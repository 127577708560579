<nav class="navbar navbar-light bg-light navbar-expand-lg mb-3">
    <div class="collapse navbar-collapse" id="navbarSupportedContent" [formGroup]="checkForm">
        <ul class="navbar-nav ms-4 mt-2">
            <li class="nav-item">
                <b>Seleccione el tipo de niveles a listar:</b>
                &nbsp; &nbsp; &nbsp; &nbsp; 
                <div class="form-check form-check-inline">
                    <input id="checkComercialControl" class="form-check-input" type="radio" [value]="1" formControlName="checkTipoControl" name="checkTipoControl" [checked]="true"> 
                    <label class="form-check-label" for="checkComercialControl">Niveles Comerciales</label>
                </div>
                &nbsp; &nbsp; &nbsp; &nbsp; 
                <div class="form-check form-check-inline">
                    <input id="checkPreciosControl" class="form-check-input" type="radio" [value]="0" formControlName="checkTipoControl" name="checkTipoControl">  
                    <label class="form-check-label" for="checkPreciosControl">Niveles de Precios</label>
                </div>
                &nbsp; &nbsp; &nbsp; &nbsp; 
                <button class="btn btn-outline-primary" (click)="this.getNivelesAprobacion()">Actualizar</button>
            </li>
        </ul>
    </div>
    <div class="mt-2 float-end" *ngIf="!this.esperandoCarga">
        <button type="button" class="btn btn-outline-primary me-2" (click)="print()">
          <span>Imprimir</span>                        
        </button>
    </div>
</nav>
<div class="col col-md-12">
    <div class="spinner-container" *ngIf="esperandoCarga">
        <ngx-spinner name="spinnerGrafico" bdOpacity=0.3bd
            Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
            <p style="color: white">C a r g a n d o . . . </p>
        </ngx-spinner>
    </div>

    <div class="row">
        <div class="col-1"></div>
        <div id="detalleNivel" class="col-10">
            <div class="car-body" *ngIf="!esperandoCarga">
                <div class="titulo-niveles">
                    {{this.tipoNivelElegido == 0 ? "Listando niveles de Precios" : "Listando niveles comerciales" }}
                </div>

                <div *ngFor="let nivel of niveles; let i=index">
                    <div class="rotulo-nivel" *ngIf="!esperandoCarga && this.niveles && this.niveles.length > 0">
                        <div >
                            <p>{{ nivel.nombre}} <button id="boton-excel" style="float:right" class="btn btn-primary me-2" (click)="this.exportarNivel(nivel)">Exportar</button> </p> 
                        </div>
                    </div>
                    <div id="contenedorListadoUsuariosAsignados" *ngIf="nivel.usuariosNivel.length > 0">
                        <table id="tablaUsuariosAsignados" class="table table-xl table-striped table-hover menu-izq">
                            <thead class="text-center letra">
                                <tr>
                                    <th scope="col"> Usuario </th>
                                    <th scope="col"> Desde </th>
                                    <th scope="col"> Hasta</th>
                                    <th scope="col"> Otorgado por </th>
                                </tr>
                            </thead>

                            <tbody class="text-center">
                                <tr style="font-size: 13px;" *ngFor="let usuario of nivel.usuariosNivel; let i=index ">
                                    <td> {{usuario.nombreCompleto}}</td>
                                    <td> {{usuario.fechaDesdeAsignado | date: 'dd/MM/yyyy' }}</td>
                                    <td> {{usuario.fechaFinAsignado != "0001-01-01T00:00:00" ? (usuario.fechaFinAsignado |
                                        date: 'dd/MM/yyyy') :
                                        "-"}}</td>
                                    <td> {{usuario.otorgadoPorNombre}}</td>
                                </tr>
                            </tbody>

                        </table>
                    </div>
                    <div id="contenedorListadoUsuariosAsignados" *ngIf="nivel.usuariosNivel.length == 0" style="margin-top: 1em; padding-left: 5em;">
                        El nivel no tiene usuarios asignados.
                    </div>
                </div>
            </div>
        </div>
        <div class="col-1"></div>
    </div>
</div>