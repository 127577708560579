<div class="card" id="card">
  <div class="card-header">    
    <nav class="navbar navbar-light bg-light navbar-expand-lg">
      <form class="form-inline flex-fill" id="formularioFiltros">
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

        <ul class="navbar-nav me-auto">

          <ng-container *ngIf="this.modificaciones && this.modificaciones.length > 0">
            <li class="nav-item me-2" >
              <div class="nav-item">                
                <input id="busquedaFormControl" [formControl]="buscadorFormControl" type="text" class="form-control" (input)="filtrarModificaciones()"
                  placeholder="Cliente" />
              </div>
            </li>
            
            <!--ESTADOS-->
            <li class="nav-item me-2">       
              <div ngbDropdown autoClose="outside">
                <button class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2" id="dropdownForm1"
                  ngbDropdownToggle>Estados</button>
                  
                <div ngbDropdownMenu aria-labelledby="dropdownForm1">
                  <div class="px-4 py-3">
                    <div *ngFor="let estado of estados; let i=index">
                      <div class="form-check">
                        <input type="checkbox" class="form-check-input" id="{{ 'dropdownCheck' + estado.nombre}}"
                          [(ngModel)]="estado.seleccionado" name="{{ 'estado' + i }}" (change)="filtrarModificaciones()">
                        <label class="form-check-label text-nowrap" for="{{ 'dropdownCheck' + estado.nombre}}">
                          {{ estado.nombre }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ng-container>

          <!-- desde -->
          <li class="nav-item me-2">
            <div class="input-group">
              <input type="date" [ngModel]="modelDesde" class="fechaFormControlDesdeHasta" placeholder="aaaa-mm-dd"
                (ngModelChange)="modelDesde = $event" name="dt" ngbDatepicker #d="ngbDatepicker" value="modelDesde"
                ngbTooltip="Seleccione una fecha de inicio" class="form-control form-control-rounded"
                #searchDate readonly>
              <div class="input-group-append">
                <button id="botonDesde" type="button" class="input-group-btn btn btn-secondary" (click)="d.toggle()">
                  <i class="fa fa-calendar" aria-hidden="true"></i></button>
              </div>
            </div>
          </li>
          <!-- hasta -->
          <li class="nav-item">
            <div class="input-group">
              <input type="date" [ngModel]="modelHasta" class="fechaFormControlDesdeHasta" placeholder="aaaa-mm-dd"
                (ngModelChange)="modelHasta = $event" name="dt" ngbDatepicker #h="ngbDatepicker" value="modelHasta"
                ngbTooltip="Seleccione una fecha de fin" class="form-control form-control-rounded"
                #searchDate readonly>
              <div class="input-group-append">
                <button id="botonHasta" type="button" class="input-group-btn btn btn-secondary" (click)="h.toggle()"><i
                    class="fa fa-calendar" aria-hidden="true"></i></button>
              </div>
            </div>
          </li>
          <li class="nav-item">
            <button (click)="buscarDesdeHasta()" class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2" type="submit"><i
              class="fa fa-search" aria-hidden="true"></i></button>
          </li>
        </ul>
      </form>
      <div class="mt-2 float-end">
          <button type="button" [disabled]="obteniendoInformacion" styleSheetFile="assets/css/print.css,assets/css/bootstrap.min.css"
          printTitle="" printSectionId="contenedorPrint" [useExistingCss]="true"
          class="btn btn-outline-secondary" ngxPrint>
          <i class="fa fa-print" aria-hidden="true"></i> Imprimir</button>
      </div>
    </nav>
    <div class="row">
      <div class="col-md-12">
        <ngb-alert type="danger" [dismissible]="false" *ngIf="fechaDesdeMayorQueHasta()==true">
          La fecha inicial debe ser anterior a la fecha final.
        </ngb-alert>
      </div>
    </div>
  </div>
  <div class="card-body" id="contenedorPrint">
    <div class="row">
      <div class="col-md-12">
        <div class="spinner-container" *ngIf="obteniendoInformacion">          
          <ngx-spinner name="spinnerReporteClientes" bdOpacity=0.3bd
            Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
            <p style="color: white">Obteniendo datos ...</p>
          </ngx-spinner>
        </div>

        <div class="card-header print col-md-12 subtitulo">
          Listado de modificaciones entre {{fechaDesde | date: "d'/'MM'/'y'"}} y {{fechaHasta | date: "d'/'MM'/'y'"}} (Estados {{obtenerDescripcionEstadosSeleccionados()}}).
        </div>
        
        <div *ngIf="this.modificacionesFiltradas && this.modificacionesFiltradas.length == 0 && !obteniendoInformacion" class="alert alert-warning">
          No hay registros para el criterio seleccionado.
        </div>

        <div *ngIf="!obteniendoInformacion && this.modificacionesFiltradas.length > 0">
          <div *ngFor="let m of modificacionesFiltradas" class="contenedorModificacion">

            <div *ngIf="m.estadoCambio == EstadoCambio.Nuevo && m.tipoModificacion == TipoModificacion.Cliente" class="no-break">
              <div class="col-md-10 offset-md-1">
                <div class="row">
                  <div class="titulo col-md-9" style="margin-top: 1em;">NUEVO CLIENTE CREADO - Estado: {{this.utilClientes.obtenerDescripcionEstadoModificacion(m.estado)}}.</div>
                  <div class="col-md-3" style="margin-top: 1em;">
                    <button type="button" (click)="mostrarOcultarModificacionesCuenta(m.id)" class="btn btn-primary btn-sm  no-print">{{mostrarModificacionesCuenta(m.id) ? "Ocultar" : "Ver"}}  modificaciones</button>
                  </div>
                </div>   
                
                <div *ngIf="m.archivosAdjuntos && m.archivosAdjuntos.length > 0" style="margin-bottom: 1em;">
                  <div class="col-md-12 bloque-datos" style="font-weight: bold; margin-top: 0.75em">
                    Listado de adjuntos:
                  </div>
        
                  <div class="col-md-11 offset-md-1 bloque-datos" style="padding-top: 0em">
                    <div style="margin-top: 0.5em;" *ngFor="let archivo of m.archivosAdjuntos"><a href="{{archivo}}"><i class="fa fa-cloud-download" aria-hidden="true"></i> {{obtenerNombreArchivo(archivo)}}</a></div>
                  </div>
                </div>   

                <div class="motivo-rechazo" *ngIf="m.modificacionCliente != null && m.modificacionCliente.motivoRechazo != null">
                  Motivo de rechazo: {{ m.modificacionCliente.motivoRechazo}}
                </div>

                <div class="subtitulo">
                  <span *ngIf="m.tipoModificacion == TipoModificacion.Cliente">{{m.modificacionCliente.clienteNuevo.razonSocial}} ({{m.modificacionCliente.clienteNuevo.numeroDocumento}})</span>                    
                </div>
                <div style="margin-bottom: 1em;"  class="detalle">
                  <span style="">Creado: {{m.fechaModificacion  | date: "d'/'MM'/'y' 'hh:mm a"}} por {{m.usuarioModificacion}}</span>
                  <span style="margin-bottom: 1em;" *ngIf="m.estado == EstadoModificacion.Aprobado || m.estado == EstadoModificacion.Rechazado">
                    &nbsp; | &nbsp; 
                    <span *ngIf="m.estado == EstadoModificacion.Aprobado">Aprobado: {{m.fechaAprobacion | date: "d'/'MM'/'y' 'hh:mm a"}} por {{m.usuarioAprobador}}.</span>
                    <span *ngIf="m.estado == EstadoModificacion.Rechazado">Rechazado: {{m.fechaAprobacion | date: "d'/'MM'/'y' 'hh:mm a"}} por {{m.usuarioAprobador}}.</span>
                  </span>
                </div>

                <div class="row col-md-11 offset-md-1">
                      <table id="nuevo-cliente" class="table col-md-6 reducida" *ngIf="m.modificacionCliente != null && mostrarModificacionesCuenta(m.id)"> 
                        <tr>
                          <td class="rotulo">Razon Social:</td>
                          <td class="">{{m.modificacionCliente.clienteNuevo.razonSocial}}</td>
                        </tr>
                        <tr>
                          <td class="rotulo">{{
                            utilClientes.obtenerDescripcionTipoDocumento(m.modificacionCliente.clienteNuevo.tipoDocumento)}}</td>
                          <td class="">{{m.modificacionCliente.clienteNuevo.numeroDocumento}}</td>
                        </tr>
                        <tr>
                          <td class="rotulo">Responsable</td>
                          <td class="">
                            Nombre: {{m.modificacionCliente.clienteNuevo.responsable.nombreCompleto}}<br/>
                            Email: {{m.modificacionCliente.clienteNuevo.responsable.email}}<br/>
                            Telefono: {{m.modificacionCliente.clienteNuevo.responsable.telefono}}<br/>
                          </td>                          
                        </tr>
                        <tr>
                          <td class="rotulo">Condición IVA:</td>
                          <td class="">{{ m.modificacionCliente.clienteNuevo.condicionIva.descripcion}}</td>
                        </tr>
                        <tr>
                          <td class="rotulo">Cuenta SAP:</td>
                          <td class="">{{ m.modificacionCliente.clienteNuevo.ctaSap}}</td>
                        </tr>
                        <tr>
                          <td class="rotulo">Área:</td>
                          <td class="">{{ m.modificacionCliente.clienteNuevo.subArea.area == null ? "-" :
                            m.modificacionCliente.clienteNuevo.subArea.area.descripcion}}</td>
                        </tr>
                        <tr>
                          <td class="rotulo">Sub Área:</td>
                          <td class="">{{ m.modificacionCliente.clienteNuevo.subArea.descripcion}}</td>
                        </tr>
                        <tr>
                          <td class="rotulo">Dirección fiscal:</td>
                          <td class="">
                            {{ m.modificacionCliente.clienteNuevo.direccionFiscal.calle }} {{
                            m.modificacionCliente.clienteNuevo.direccionFiscal.numero }} {{
                            m.modificacionCliente.clienteNuevo.direccionFiscal.piso }} {{
                            m.modificacionCliente.clienteNuevo.direccionFiscal.departamento }}<br />
                            {{ m.modificacionCliente.clienteNuevo.direccionFiscal.ciudad.nombre}} ({{
                            m.modificacionCliente.clienteNuevo.direccionFiscal.ciudad.codigoPostal}})
                          </td>
                        </tr>
                        <tr>
                          <td class="rotulo">Actividad:</td>
                          <td class="">{{
                            utilClientes.obtenerDescripcionActividadPad(m.modificacionCliente.clienteNuevo.actividadPAD)}}</td>
                        </tr>
                        <tr>
                        <tr>
                          <td class="rotulo">Segmento:</td>
                          <td class="">{{ m.modificacionCliente.clienteNuevo.grupoCliente == null ? "-" :
                            m.modificacionCliente.clienteNuevo.grupoCliente.descripcion}}</td>
                        </tr>
                        <tr>
                        <tr>
                          <td class="rotulo">Percep. Iva:</td>
                          <td class="">{{ m.modificacionCliente.clienteNuevo.percepcionIVADif}}</td>
                        </tr>
                        <tr>
                          <td class="rotulo">Vigente:</td>
                          <td class="">{{ m.modificacionCliente.clienteNuevo.fechaPercepcionIVADif | date: "d'/'MM'/'y' 'hh:mm a"
                            }}</td>
                        </tr>
                        <tr *ngIf="m.modificacionCliente.clienteNuevo.direccionesEntrega != null">
                          <td class="rotulo">Direcciones entrega:</td>
                          <td class="">
                            <div *ngFor="let dirEntrega of m.modificacionCliente.clienteNuevo.direccionesEntrega"
                              style="margin-bottom: 1em; border-bottom: 1px solid #ccc;">
                              {{ dirEntrega.calle }} {{ dirEntrega.numero }} {{ dirEntrega.piso }} {{ dirEntrega.departamento }} <br />
                              {{ dirEntrega.ciudad.nombre}} ({{ dirEntrega.ciudad.codigoPostal}})
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td class="rotulo">Comerciales:</td>
                          <td class=""><span *ngFor="let comercial of m.modificacionCliente.clienteNuevo.comerciales">{{
                              comercial}} . </span></td>
                        </tr>
                        <tr>
                          <td class="rotulo">Cliente observado:</td>
                          <td class="">{{ m.modificacionCliente.clienteNuevo.clienteObservado ? "Si" : "No"}} </td>
                        </tr>
                        <tr>
                          <td class="rotulo">Activo:</td>
                          <td class="">{{ m.modificacionCliente.clienteNuevo.activo ? "Si" : "No"}} </td>
                        </tr>                        
                      </table>

                      <table class="col-md-6 reducida"  *ngIf="m.modificacionCliente != null && mostrarModificacionesCuenta(m.id)"> 
                        <tr>
                          <td>
                            <b>Condición IIBB:</b>
                          </td>
                        </tr>
                        <tr *ngFor="let iibb of m.modificacionCliente.clienteNuevo.configuracionProvincialImp">
                          <td>

                              {{ iibb.provincia.codigo }}: Num. IIBB:{{ iibb.numeroIIBB }} - {{
                              this.utilClientes.obtenerDescripcionCondicionIIBB(iibb.condicionIIBB) }} - Alicuota: {{ iibb.alicuotaDiferencial
                              == null ? " SIN ALICUOTA " : iibb.alicuotaDiferencial.alicuota }} {{ iibb.alicuotaDiferencial == null ? "" :
                              iibb.alicuotaDiferencial.fechaHasta | date: "d'/'MM'/'y' 'hh:mm a" }}

                          </td>
                        </tr>
                     </table>
                  </div>
              </div>
            </div>
            
            <div *ngIf="!(m.estadoCambio == EstadoCambio.Nuevo && m.tipoModificacion == TipoModificacion.Cliente)" class="no-break">     
              <div class="col-md-10 offset-md-1">
                <div class="row">
                  <div class="titulo  col-md-9" style="margin-top: 1em;">{{m.estadoCambio == EstadoCambio.Nuevo?"CREACIÓN DE":"MODIFICACION EN"}} {{m.tipoModificacion == TipoModificacion.Cliente ? "CLIENTE" : "CUENTA"}} -  Estado: {{this.utilClientes.obtenerDescripcionEstadoModificacion(m.estado)}}.</div>
                  <div class="col-md-3" style="margin-top: 1em;">
                    <button type="button" (click)="mostrarOcultarModificacionesCuenta(m.id)" class="btn btn-primary btn-sm no-print">{{mostrarModificacionesCuenta(m.id) ? "Ocultar" : "Ver"}}  modificaciones</button>
                  </div>
                </div>

                <div *ngIf="m.archivosAdjuntos && m.archivosAdjuntos.length > 0" style="margin-bottom: 1em;">
                  <div class="col-md-12 bloque-datos" style="font-weight: bold; margin-top: 0.75em">
                    Listado de adjuntos:
                  </div>
        
                  <div class="col-md-11 offset-md-1 bloque-datos" style="padding-top: 0em">
                    <div style="margin-top: 0.5em;" *ngFor="let archivo of m.archivosAdjuntos"><a href="{{archivo}}"><i class="fa fa-cloud-download" aria-hidden="true"></i> {{obtenerNombreArchivo(archivo)}}</a></div>
                  </div>
                </div>   

                <div class="motivo-rechazo" *ngIf="m.tipoModificacion == TipoModificacion.Cliente && m.modificacionCliente != null && m.modificacionCliente.motivoRechazo != null">
                  Motivo de rechazo: {{ m.modificacionCliente.motivoRechazo}}
                </div>
    
                <div class="motivo-rechazo" *ngIf="m.tipoModificacion == TipoModificacion.Cuenta && m.modificacionCuenta != null && m.modificacionCuenta.motivoRechazo != null">
                  Motivo de rechazo: {{ m.modificacionCuenta.motivoRechazo}}
                </div>

                <div class="subtitulo">
                  <span *ngIf="m.tipoModificacion == TipoModificacion.Cliente">{{m.modificacionCliente.clienteNuevo.razonSocial}} ({{m.modificacionCliente.clienteNuevo.numeroDocumento}})</span>       
      
                  <span *ngIf="m.tipoModificacion == TipoModificacion.Cuenta">{{m.modificacionCuenta.razonSocial}}</span> &nbsp; | &nbsp;        
                  <span *ngIf="m.tipoModificacion == TipoModificacion.Cuenta">Cuenta: {{m.modificacionCuenta.cuentaNuevo.ctA_SGC}} ({{m.modificacionCuenta.cuentaNuevo.descripcion}})</span>    
                </div>

                <div class="detalle">
                  <span style="">Creación: {{m.fechaModificacion | date: "d'/'MM'/'y' 'hh:mm a"}} por {{m.usuarioModificacion}}.</span>
                  <span style="margin-bottom: 1em;" *ngIf="m.estado == EstadoModificacion.Aprobado || m.estado == EstadoModificacion.Rechazado">
                    &nbsp; | &nbsp; 
                    <span *ngIf="m.estado == EstadoModificacion.Aprobado">Aprobado: {{m.fechaAprobacion | date: "d'/'MM'/'y' 'hh:mm a"}} por {{m.usuarioAprobador}}</span> 
                    <span *ngIf="m.estado == EstadoModificacion.Rechazado">Rechazado: {{m.fechaAprobacion | date: "d'/'MM'/'y' 'hh:mm a"}} por {{m.usuarioAprobador}}</span>
                  </span>
                </div>
                <div  class="col-md-12 motivo-modificacion" style="margin-top:0.75em">
                  <b>Motivos de modificacion:</b>
                  <br/>
                  <div class="detalle-motivo-modificacion">
                    {{ m.detalle }}
                  </div>            
                </div>
                
                <div class="row col-md-11 offset-md-1 cliente" *ngIf="mostrarModificacionesCuenta(m.id)">                              
                  <div *ngIf="m.tipoModificacion == TipoModificacion.Cliente" class="row contenedor-modificacion">
                    <table id="modificacion-cliente" class="table reducida">
                      <tr>
                        <th class="">Datos viejos</th>
                        <th class="">Datos Nuevos</th>
                      </tr>
                      <tr *ngFor="let item of obtenerModificacionesCliente(m.modificacionCliente)">
                        <td class="" [innerHTML]="item.valorViejo"></td>
                        <td class="" [innerHTML]="item.valorNuevo"></td>
                      </tr>
                    </table>
                  </div>

                  <div *ngIf="m.tipoModificacion == TipoModificacion.Cuenta" class="row contenedor-modificacion">
                    <table id="modificacion-cuenta" class="table reducida">
                      <tr>
                        <th class="">Datos viejos</th>
                        <th class="">Datos Nuevos</th>
                      </tr>
                      <tr *ngFor="let c of compararCuentas(m.modificacionCuenta)">
                        <td class="" [innerHTML]="c.valorViejo"></td>
                        <td class="" [innerHTML]="c.valorNuevo"></td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>