<div *ngIf="this.emails!=null">
<div class="modal-header bg-light">
  <h4 class="modal-title"><b>Historial de Envios del Cliente: {{this.clienteSeleccionado.razonSocial}}</b></h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismissAll()">
    
  </button>
</div>
<nav class="navbar navbar-light bg-light navbar-expand-lg">
  <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <form class="form-inline my-2 my-lg-0">
      <input id="busquedaFormControl" type="text" class="form-control" [formControl]="busquedaFormControl"
        (input)="filtrarEmails()" placeholder="Búsqueda" />
    </form>
     <ul class="navbar-nav">
      <!-- desde -->
      <li class="nav-item me-2">
        <div class="input-group">

          <input type="date" [ngModel]="modelDesde" class="fechaFormControlDesdeHasta" placeholder="aaaa-mm-dd"
            (ngModelChange)="modelDesde = $event" name="dt" ngbDatepicker #d="ngbDatepicker" value="modelDesde"
            class="form-control form-control-rounded" #searchDate required readonly>

          <div class="input-group-append">
            <button id="botonDesde" type="button" class="input-group-btn btn btn-secondary" (click)="d.toggle()">
              <i class="fa fa-calendar" aria-hidden="true"></i></button>
          </div>
        </div>
      </li>
      <!-- hasta -->
      <li class="nav-item">
        <div class="input-group">
          <input type="date" class="fechaFormControlDesdeHasta" [ngModel]="modelHasta" placeholder="aaaa-mm-dd" name="dp"
            value="modelHasta" (ngModelChange)="modelHasta = $event" class="form-control form-control-rounded" ngbDatepicker
            #h="ngbDatepicker" #searchDate required readonly>

          <div class="input-group-append">
            <button id="botonHasta" type="button" class="input-group-btn btn btn-secondary" (click)="h.toggle()"><i
                class="fa fa-calendar" aria-hidden="true"></i></button>
          </div>
        </div>
      <li class="nav-item">

        <button (click)="this.getMailsPorCliente()" class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2" type="submit"><i
            class="fa fa-search" aria-hidden="true"></i></button>

      </li>


    </ul>

  </div>

</nav>
  <ngb-alert type="warning" *ngIf="this.emails.length===0" class="alert alert warning">
    No existen envios para el cliente seleccionado
   </ngb-alert>
  <div class="row" *ngIf="this.emails.length>0">
   <div  class="card-body">
      <table  class="table table-striped table-hover" [formGroup]="tablaComprobantes">
        <thead>
          <td class="text-center"><b>Fecha</b></td>
          <td class="text-center"><b>Destinatarios</b></td>
          <td class="text-center"><b>Aeroplanta</b></td>
          <td class="text-center"><b>Tipo de Notificacion</b></td>
       </thead>
        <tbody >
          <tr  *ngFor="let email of this.emailsFiltrados let i = index">
            <td class="text-center">{{email.fechaHoraEnvio | date: "d'/'MM'/'y' 'hh:mm a" }}</td>
            <td class="text-center">{{email.destinatarios}}</td>
            <td class="text-center">{{email.codigoAeroplanta}}</td>
            <td class="text-center">{{email.tipoNotificacion}}</td>
          </tr>
        </tbody>
      </table>

    </div>

  </div>
</div>




