<div *ngIf="obteniendoSaldos" class="border p-3">
    <loading-text-spinner message="Obteniendo los saldos del cliente..."
        orientation="vertical" size="large" color="primary" textColor="dark">
    </loading-text-spinner>
</div>
<div *ngIf="!obteniendoSaldos">
  <div *ngIf="saldosPorCuenta != null && saldosPorCuenta.length > 0" class="contenedor-der">
    <div *ngFor="let cuen of saldosPorCuenta; let i = index">
        <div class="col col-md-12">
            <div class="card border-item">
                <div class="card-header bg-light">
                    <h4 class="modal-title">Cuenta N°: {{cuen.nroCuenta}}</h4>                    
                </div>
                <div class="card-body">                    
                    <ng-container *ngIf="saldosPorCuenta[i].saldosAlije.length > 0">                            
                        <div class="row">
                            <div class="mb-3 row" style="width: -webkit-fill-available;">
                                <div *ngFor="let item of cuen.saldosAlije" class="col-4">                                                                 
                                    <div class="card border-primary text-center border-item" style="margin-top: 0.5rem;">
                                        <div class="card-block" style="padding: 1rem;">                                                                    
                                            <h6 class="card-title">Producto: {{ item.productoCodigo }} - {{ item.productoNombre }}</h6>
                                            <p class="card-text"> <b> Saldo Actual: </b> {{ item.saldoActual | number: "1.0-0" }} LTS.</p>                               
                                            <button 
                                                type="button"
                                                class="btn btn-primary"
                                                (click)="generarDevolucion(cuen, item)">Generar Devolución
                                            </button>                                                                                                   
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>    
                    </ng-container>
                    <ng-container *ngIf="saldosPorCuenta[i].saldosAlije.length == 0">
                        <div class="alert alert-warning mt-3" role="alert">
                            No hay saldos para esta cuenta del cliente.
                        </div>            
                    </ng-container>                                
                </div>                                     
            </div>
        </div>      
    </div>
</div>