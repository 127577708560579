import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Aeroplanta } from 'src/app/shared/models/stock/Aeroplanta';
import { Cliente } from '../models/facturacion/Cliente';

@Component({
  selector: 'app-lista-aeroplantas',
  templateUrl: './lista-aeroplantas.component.html',
  styleUrls: ['./lista-aeroplantas.component.css']
})
export class ListaAeroplantasComponent implements OnInit {

  [x: string]: any;


  @Input() aeroplantas: Aeroplanta[];


  clientes: Cliente [];
  clienteSeleccionado: Cliente;
  busquedaFormControl = new UntypedFormControl();


  cargandoClientes:boolean=true;
  constructor(public activeModal:NgbActiveModal)//, private aeroplantaService:AeroplantasService) { }
  {

  }
  ngOnInit() {
    //this.getAeroplantas();
  }

  getAeroplantas() {
    // this.cargandoAeroplanta = true;
    // this.aeroplantaService.obtenerTodasLasAeroplantas()
    //   .subscribe(result => {
    //     this.aeroplantas = result.sort((a, b) => { if (a.nombre > b.nombre) { return 1 } else { return -1 } });
    //    // this.spinner.hide('spinnerReporte')

    //   });
  }


}
