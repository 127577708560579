<div class="modal-header bg-light">
  <h5 class="modal-title ">Ajuste manual de aeroespecialidad</h5>
  <button type="button" class="btn-close" aria-label="Close" data-dismiss="modal" (click)="activeModal.close(false)"></button>
</div>
<div class="modal-body" [formGroup]="ajusteForm">
  
  <div class="mb-3 col-12">
    <div class="mb-3 row">
      <label for="aeroespecialidadFormControl" id="aeroespecialidadFormControl" class="col-12 col-form-label">* Aeroespecialidad:</label>
    </div>
  </div>

  <div class="mb-3 col-12">
    <div class="mb-3 row">
      <div class="col-12">
        <div class="input-group mb-2">
          <select id="aeroespecialidadFormControl" type="text" class="form-select"
            title="Seleccione aeroespecialidad a ajustar." formControlName="aeroespecialidadFormControl">
            <option [ngValue]="a" *ngFor="let a of aeroespecialidades">
              ({{a.aeroespecialidad.codigoProducto}}) {{a.aeroespecialidad.nombreProducto}}
            </option>
          </select>
        </div>
        <loading-text-spinner *ngIf="obteniendoAeroespecialidades" 
          message="Obteniendo aeroespecialidades..." color="primary">
        </loading-text-spinner>
      </div>
    </div>
  </div>
  <!-- NUEVA CANTIDAD-->
  <div class="mb-3 col-12">
    <div class="mb-3 row">
      <label for="nuevaCantidadFormControl" id="labelCantidad" class="col-4 col-form-label">* Cantidad:</label>
      <div class="col-8">
        <input class="form-control" formControlName="nuevaCantidadFormControl" (keypress)="this.onlyDecimalPositivo($event)">
      </div>
    </div>
  </div>

  <div class="mb-3 col-md-12">
    <div class="mb-3 row">
      <label for="motivoFormControl" class="col-md-4 col-form-label">* Motivo:</label>
      <div class="col-md-8">
        <select id="motivoFormControl" class="form-select"
          ngbTooltip="Seleccione un motivo." formControlName="motivoFormControl" >
          <option *ngFor="let m of motivos" [ngValue]="m">
            ({{ m.descripcion }}
          </option>
        </select>
        <loading-text-spinner *ngIf="obteniendoMotivos" message="Obteniendo motivos..." color="primary">
        </loading-text-spinner>
        <div class="alert alert-danger"
          *ngIf="(this.motivoFormControl.invalid && this.motivoFormControl.errors.required && (this.motivoFormControl.dirty || this.motivoFormControl.touched))">
          Debe seleccionar una terminal obligatoriamente.
        </div>
      </div>
    </div>
  </div>

  <div class="mb-3 col-12">
    <div class="mb-3 row">
      <div class="col-12 col-form-label">Comentario</div>
    </div>
  </div>

  <div class="mb-3 col-12">
    <div class="mb-3 row">
      <textarea class="form-control col-form-label col-md-12" formControlName="comentarioFormControl" rows='5'></textarea>
    </div>
  </div>
</div>
<div class="modal-footer bg-light">
  <div class="alert alert-danger" *ngIf="error != null">
    {{ error }}
  </div>
  <div class="mb-3 col-12">
    <div class="d-flex justify-content-md-center">
      <button class="btn btn-primary me-2" id="aceptar" [disabled]="!ajusteForm.valid || guardandoMovimiento"
        (click)="guardarAjusteManual()">
        <span *ngIf="!guardandoMovimiento">Guardar</span>
        <div *ngIf="guardandoMovimiento" class="d-flex align-items-center">
          <div class="spinner-border text-light spinner-border-sm" role="status"></div>
          <span>Guardando...</span>
        </div>
      </button>
      <button class="btn btn-secondary" id="cancelar" (click)="activeModal.close(false)"
        [disabled]="guardandoMovimiento" data-dismiss="modal">Cancelar</button>
    </div>
  </div>
</div>