import { Abastecedora } from './Abastecedora';
import { Cliente } from './Cliente';
import { Aeroespecialidad } from './Aeroespecialidad';
import { Remanente } from './Remanente';
import { Observacion } from './Observacion';
import { Producto } from './Producto';
import { Aeroplanta } from './Aeroplanta';
import { Usuario } from './Usuario';
import { Servicio } from './AerovaleAlije';
import { AerovaleDetalleAerocombustible } from './AerovaleDetalleAerocombustible';
import { AerovaleDetalleCombustible } from './AerovaleDetalleCombustible';
import { VehiculoDTO } from '../cliente/VehiculoDTO';

export class AerovaleDetalle {
  id: string;
  numeroAerovale: string;
  prefijo: string;
  origen: Aeroplanta;
  cliente: Cliente;
  tipoPago: number;
  estado: string;
  aeroespecialidades: Aeroespecialidad[];
  servicios: Servicio[];
  remanentes: Remanente[];
  fechaHoraInicioDespacho: string;
  fechaHoraFinDespacho: string;
  fechaHoraLlegada: string;
  observaciones: Observacion[];
  comentarios: string;
  vehiculo: VehiculoDTO;
  operador: Usuario;
  supervisor: Usuario;
  operadorDTO: Usuario;
  supervisorDTO: Usuario;
  volumen: number; // si es diferente a aeroespecialidad
  producto: Producto; // si es diferente a aeroespecialidad
  abastecedora: Abastecedora;
  firmaOperador: string;
  firmaCliente: string;
  pathFirmaOperador: string;
  pathFirmaCliente: string;
  observacionId: string;
  tipoAerovale: number; /* 0: AerovaleAerocombustible | 1: AerovaleAeroespecialidad | 2: AerovaleCombustible | 3: AerovaleAlije */
  detalleAerocombustible: AerovaleDetalleAerocombustible;
  vuelo: string;
  detalleCombustible: AerovaleDetalleCombustible;   
  ciudadIdAerovale:string;  
  idAerovaleReemplaza: string;
  idAerovaleReemplazado: string;
  estadoAutorizacion: number;
}
