<div class="modal-header bg-light">
    <h4 class="modal-title">Asignando Rancho</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.close()"></button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col col-12">
                <small><b> Cliente: </b>{{ rancho.cliente.razonSocial }} </small> <br> 
                <small><b> Producto: </b>{{ rancho.producto.codigoProducto }} - {{ nombreProducto }} </small> <br> 
                <small><b> Aeroplanta: </b>{{ rancho.aeroplanta.codigoAeroplanta }} - {{ rancho.aeroplanta.nombreAeroplanta }}</small> <br>                
                <small><b> Fecha: </b>{{ rancho.fechaInicio | date: "d'/'MM'/'y'" }} - {{ rancho.fechaVencimiento | date: "d'/'MM'/'y'" }}</small> <br> 
                <small><b> Destino: </b>{{ rancho.destino.nombre }}</small> <br> 
                <small><b> Volumen: </b>{{ rancho.volumen | number: '1.0-0'}} LTS. </small> <br> 
                <small><b> Precio: </b> {{rancho.moneda}} {{rancho.precio | number: "1.2-2"}}</small> <br> 
                <ng-container *ngIf="rancho.ctA_ParaFacturar">
                    <small><b> Cta. A Facturar: </b> {{rancho.ctA_ParaFacturar}}</small> <br> 
                </ng-container>                
            </div>    
        </div>                        
        <br>            
        <div class="row">
            <div class="col col-4">
                <label class="pt-2 fw-bold">Rancho asignado:</label>
            </div>
            <div class="col col-6">
                <input type="text" class="form-control" [formControl]="codigoRanchoFormControl" 
                    placeholder="Ingrese el nº de rancho" style="text-transform: uppercase;" 
                    [minlength]="16" [maxLength]="16" required (keypress)="formatNumeroRancho($event)" />
                <div *ngIf="codigoRanchoFormControl.invalid && codigoRanchoFormControl.errors.required 
                        && (codigoRanchoFormControl.dirty || codigoRanchoFormControl.touched)" class="alert alert-danger">
                    Requerido
                </div>
            </div>            
        </div>
        <div class="row">
            <div class="col col-11">
                <label *ngIf="mensaje.length>0"
                    [ngClass]="{
                        'text-success':!errorAFIP,                        
                        'text-danger':errorAFIP}"> 
                        {{ mensaje }}
                </label>
            </div>
        </div>

        <div class="mb-3 col-10 offset-1 mt-2" style="border: 1px solid #ccc; padding: 1em;">
            <div class="mb-3 row">
                <div class="col-md-11" style="font-weight: bold;">Subir archivos adjuntos:</div>
                <div class="col-md-1"></div>
            </div>

            <div class="mb-3 row">
                <div class="col-md-1"></div>
                <div class="col-md-10">
                    <app-upload-file class="d-flex" [url]="this.ranchosBaseUrl" (archivoSubido)="onArchivoSubido($event)"></app-upload-file>
                </div>
                <div class="col-md-1"></div>
            </div>

            <div class="mb-3 row" style="margin-bottom: 1em;">
                <div class="col-md-1"></div>
                <div class="col-md-10" style="font-weight: bold;">Lista de adjuntos a subir:</div>
                <div class="col-md-1"></div>
            </div>

            <div class="mb-3 row" *ngIf="archivosSubidos && archivosSubidos.length > 0">
                <div class="col-md-1"></div>
                <div class="col-md-10">
                    <span style="margin-bottom: 0.5em;" *ngFor="let filename of archivosSubidos">{{filename}}<br /></span>
                </div>
                <div class="col-md-1"></div>
            </div>

            <div class="mb-3 row" *ngIf="archivosSubidos && archivosSubidos.length == 0">
                <div class="col-md-1"></div>
                <div class="col-md-10">
                    No hay archivos adjuntados para enviar con las modificaciones.
                </div>
                <div class="col-md-1"></div>
            </div>
        </div>

    </div>
    <div class="modal-footer bg-light justify-content-center">
        <button [disabled]="guardando || validando || !codigoRanchoFormControl.valid" *ngIf="sePuedeGuardar" class="btn btn-primary me-2" (click)="guardarRancho()">
            <span *ngIf="!guardando">Guardar</span>
            <div *ngIf="guardando" class="d-flex align-items-center">
                <div class="spinner-border text-light spinner-border-sm" role="status"></div>
                <span>Guardando...</span>
            </div>
        </button>
        <button [disabled]="guardando || validando || !codigoRanchoFormControl.valid" class="btn btn-primary me-2" (click)="validarRanchoWebService()">
            <span *ngIf="!validando">Validar</span>
            <div *ngIf="validando" class="d-flex align-items-center">
                <div class="spinner-border text-light spinner-border-sm" role="status"></div>
                <span>Validando...</span>
            </div>
        </button>
        <button class="btn btn-secondary" (click)="cancelarModal()">Cancelar</button>
</div>