<nav class="navbar navbar-light bg-light navbar-expand-lg mb-3">
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav me-auto">
      <li class="nav-item">
        <div class="nav-item" [formGroup]="searchForm">
          <input id="busquedaFormControl" type="text" class="form-control input-sm" placeholder="Búsqueda"
            formControlName="busquedaFormControl" (input)="filtrarPrecios()" placeholder="Buscar" />
        </div>
      </li>

      <li class="nav-item">
        <div class="nav-item" style="padding-left: 5em; padding-top: 0.35em; font-weight: bold;">
          <div *ngIf="this.usuarioApruebaRetroactivos">Trabajando precios retroactivos.</div>
          <div *ngIf="!this.usuarioApruebaRetroactivos">Trabajando precios.</div>
        </div>
      </li>
    </ul>
    <ul class="navbar-nav">
      <li *ngIf="hasRole(TipoUsuario.APROBADORPRECIOS)" class="nav-item" style="float:right">
        <button [disabled]="!usuarioPuedeDelegar()" type="button" (click)="delegarNivel()" class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2">
          Delegar</button>
      </li>
      <li *ngIf="hasRole(TipoUsuario.PRECIOS)" class="nav-item" style="float:right">
        <button [disabled]="this.cargandoPreciosDeLote || this.precios==null || this.precios.length == 0" type="button" (click)="eliminarLote()" class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2"> Eliminar
          lote</button>
      </li>
      <li *ngIf="hasRole(TipoUsuario.APROBADORPRECIOS)" class="nav-item" style="float:right">
        <button [disabled]="this.cargandoPreciosDeLote || this.precios==null || this.precios.length == 0" type="button" (click)="aprobarPrecios()" class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2">
          Aprobar Lote</button>
      </li>
      <li *ngIf="hasRole(TipoUsuario.APROBADORPRECIOS)" class="nav-item" style="float:right">
        <button [disabled]="this.cargandoPreciosDeLote || this.precios==null || this.precios.length == 0" type="button" (click)="rechazarPrecios()" class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2">
          Rechazar Lote</button>
      </li>
    </ul>
  </div>
</nav>

<div class="col col-md-12">
  <div class="spinner-container" *ngIf="cargandoLotes">
    <ngx-spinner name="spinnerLotes" bdOpacity=0.3bd
      Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
      <p style="color: white">C a r g a n d o . . . </p>
    </ngx-spinner>
  </div>

  <div class="row" *ngIf="!cargandoLotes">

    <div class="col col-3">

      <ngb-alert type="warning" [dismissible]="false" *ngIf="this.lotes && this.lotes.length == 0">
        No hay lotes con precios pendientes.
      </ngb-alert>

      <div class="list-group menu-izq">
        <div *ngFor="let lote of lotes; let i=index">
          <button type="button" (click)="mostrarDetalleLote(i)" class="list-group-item list-group-item-action"
            [class.seleccionado]="loteSeleccionado && loteSeleccionado.id == lote.id">
            <div class="d-flex w-100 text-center">
              <p>
                <b>Lote {{ lote.numeroLote }}</b> | var. max. {{lote.variacionMaxima}} % | Registros:
                {{lote.cantidadRegistros}}
                <br />
                {{ lote.responsable }}
              </p>
            </div>
          </button>
        </div>
      </div>
    </div>
    <!-- informacion de precios de lote -->
    <div id="detalleNivel" class="col-9 nopadding">
      <ngb-alert type="warning" [dismissible]="false" *ngIf="this.loteSeleccionado == null && this.mensajeAccesoInsuficiente == null">
        No hay ningún nivel seleccionado.
      </ngb-alert>

      <ngb-alert type="danger" [dismissible]="false" *ngIf="this.mensajeAccesoInsuficiente != null">
        {{ this.mensajeAccesoInsuficiente }}
      </ngb-alert>

      <div class="car-body" *ngIf="loteSeleccionado != null">
        <div class="col col-md-12  nopadding">

          <div *ngIf="cargandoPreciosDeLote" class="row">
            <loading-text-spinner class="col-md-12" message="Obteniendo precios del lote..." color="primary">
            </loading-text-spinner>
          </div>

        </div>
        <div class="card add-margin" *ngIf="!this.cargandoPreciosDeLote">
          <div>

            <ngb-alert type="warning" [dismissible]="false"
              *ngIf="this.loteSeleccionado != null &&  this.mensajeAccesoInsuficiente == null && this.precios.length == 0">
              No hay precios para el lote seleccionado.
            </ngb-alert>

            <div id="listadoPrecios" *ngIf="this.loteSeleccionado != null && this.precios.length > 0">
              <div class="row">
                <div class="col-md-3" style="padding-top: 0.5em; padding-left: 2em;">
                  <loading-text-spinner *ngIf="actualizandoPrecios" [error]="errorPrecios" color="primary"
                    textColor="dark" message="Obteniendo pagina {{this.page}}..."></loading-text-spinner>
                </div>

                <ngb-pagination (pageChange)="onPageChange($event)" [collectionSize]="collectionSize" [maxSize]="8"
                  [(page)]="page" [pageSize]="pageSize" *ngIf="this.preciosFiltrados!=null"
                  class="col-md-7 d-flex justify-content-center">
                </ngb-pagination>
                <span *ngIf="this.loteSeleccionado.pathExcelComparativa == null"style="float:right; font-weight: bold; line-height: 1em; margin-top: 1rem;">
                  Lote sin comparativa
                </span>
                <a *ngIf="this.loteSeleccionado.pathExcelComparativa != null" style="color:#FFF;" [href]="this.loteSeleccionado.pathExcelComparativa">
                  <button style="float:right" type="button" class="btn btn-primary ms-1 fa fa-download" style="line-height: 1em; margin-bottom: 1rem;">                    
                    &nbsp;Comparativa</button>
                </a>
              </div>

              <table id="tablaUsuariosAsignados" class="table table-xl table-hover">
                <thead class="text-center fuente-chica">
                  <tr>
                    <th scope="col"> Nivel </th>
                    <th scope="col"> Lote </th>
                    <th scope="col"> Codigo </th>
                    <th scope="col"> Producto </th>
                    <th scope="col"> Desde-Hasta </th>
                    <th scope="col"> Moneda</th>
                    <th scope="col"> Cliente </th>
                    <th scope="col"> Tipo Vta </th>
                    <th scope="col"> Precio </th>
                    <th scope="col"> Entrega </th>
                    <th scope="col"> Area </th>
                    <th scope="col"> Segmento </th>
                    <th scope="col"> Aeroplanta </th>
                  </tr>
                </thead>

                <tbody class="text-center fuente-chica">
                  <ng-container *ngFor="let precio of preciosFiltrados; let i=index">
                    <tr class="{{i%preciosFiltrados.length == 0 ? 'par' : 'impar'}}">
                      <td> {{precio.nivel}}</td>
                      <td> {{loteSeleccionado.numeroLote}}</td>
                      <td> {{precio.codigoProducto}}</td>
                      <td> {{precio.nombreProducto}}</td>
                      <td> {{precio.fechaDesde | date: "dd'/'MM'/'yy HH:mm:ss" }} - {{precio.fechaHasta | date:
                        "dd'/'MM'/'yy HH:mm:ss" }}</td>
                      <td> {{precio.moneda.nombre}}</td>
                      <td> {{precio.cliente ? precio.cliente.razonSocial : "-"}}</td>
                      <td> {{descripcionTipoVenta(precio.tipoVenta)}}</td>
                      <td> {{ precio.precioContado | number : '1.5-5'}}</td>
                      <td> {{precio.direccionEntrega}}</td>
                      <td> {{precio.areaNegocio ? precio.areaNegocio.codigoArea : "-"}}</td>
                      <td> {{precio.segmento ? precio.segmento.codigoSegmento : "-"}}</td>
                      <td> {{precio.codigoAeroplanta}}</td>
                    </tr>
                    <tr class="resumen {{i%preciosFiltrados.length == 0 ? 'par' : 'impar'}}">
                      <td colspan="13">
                        <div *ngIf="!precio.precioViejos || precio.precioViejos.length == 0">Este precio no presenta
                          conflictos</div>
                        <div *ngFor="let precioViejo of precio.precioViejos; let i=index">
                          <ng-container *ngIf="precioViejo.precio == null">
                            <div class="row  mb-5">
                              <div class="col-md-4 justify-content-center">
                                <i *ngIf="precioViejo.tipoObservacion == enumTipoObservacionExcelPrecios.Conflicto"
                                  class="fa fa-exclamation-triangle fa-3x"
                                  style="color:yellow; background-color: black; padding: 10px;"></i>
                                <i *ngIf="precioViejo.tipoObservacion == enumTipoObservacionExcelPrecios.SinConflicto"
                                  class="fa fa-check-circle fa-3x"
                                  style="color:green; background-color: white; padding: 10px; float: left;"></i>
                                <i *ngIf="precioViejo.tipoObservacion == enumTipoObservacionExcelPrecios.SinConflicto && esPrecioRetroactivo(precio)"
                                  class="fa fa-clock-o fa-3x" style=" padding: 10px; float: left;"></i>
                              </div>
                            </div>
                          </ng-container>
                          <ng-container *ngIf="precioViejo.precio != null">
                            <div class="row  mb-5">
                              <div class="col-md-4 justify-content-center">
                                <i *ngIf="precioViejo.tipoObservacion == enumTipoObservacionExcelPrecios.Conflicto"
                                  class="fa fa-exclamation-triangle fa-3x"
                                  style="color:yellow; background-color: black; padding: 10px"></i>
                                <i *ngIf="precioViejo.tipoObservacion == enumTipoObservacionExcelPrecios.SinConflicto"
                                  class="fa fa-check-circle fa-3x"
                                  style="color:green; background-color: white; padding: 10px; float: left;"></i>
                                <i *ngIf="precioViejo.tipoObservacion == enumTipoObservacionExcelPrecios.SinConflicto && esPrecioRetroactivo(precio)"
                                  class="fa fa-clock-o fa-3x" style="padding: 10px; float: left;"></i>
                              </div>

                              <div class="col-sm-11">
                                <div class="col-sm-12 mt-4">
                                  <div><b>Precio viejo</b>: Desde {{precioViejo.precio.fechaDesde | date: "dd'/'MM'/'yy
                                    HH':'mm"}} hasta {{precioViejo.precio.fechaHasta | date: "dd'/'MM'/'yy HH':'mm"}}
                                    &nbsp; | &nbsp; Moneda: {{precioViejo.precio.moneda.nombre}} &nbsp; | &nbsp; Precio
                                    ${{precioViejo.precio.precioContado}} &nbsp; | &nbsp; Lote:
                                    {{precioViejo.precio.numeroLote}}</div>
                                  <div><b>Precio nuevo</b>: Desde {{precio.fechaDesde | date: "dd'/'MM'/'yy HH':'mm"}}
                                    hasta {{precio.fechaHasta | date: "dd'/'MM'/'yy HH':'mm"}} &nbsp; | &nbsp; Moneda:
                                    {{precio.moneda.nombre}} &nbsp; | &nbsp; Precio ${{precio.precioContado}} &nbsp; |
                                    &nbsp; Lote: {{precio.numeroLote}}</div>
                                </div>
                                <div class="col-sm-12">
                                  <div><b>Variación</b>: <b>{{precioViejo.variacionPorcentual}}%</b> &nbsp; | &nbsp;
                                    <b>$ {{precioViejo.variacionImporte}}</b>
                                  </div>
                                  <div><b>Acción</b>: <b>{{precioViejo.observacion}}</b></div>
                                </div>
                              </div>
                            </div>
                          </ng-container>
                        </div>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>

              <div class="row">
                <div class="col-md-4" style="padding-top: 1em; padding-left: 2em;">
                </div>

                <ngb-pagination (pageChange)="onPageChange($event)" [collectionSize]="collectionSize" [maxSize]="10"
                  [(page)]="page" [pageSize]="pageSize"
                  *ngIf="this.preciosFiltrados!=null && this.preciosFiltrados.length > 0"
                  class="col-md-8 d-flex justify-content-center">
                </ngb-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>