<form [formGroup]="formSelec">
  <div class="modal-header">
      <h5 class="modal-title">Seleccion Cliente Matricula</h5>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
  </div>
  <div class="modal-body">
    <div class="card-body">        
      <div style="display: block;" class="mb-3 row text-center btn-group" role="group" aria-label="Basic example">
        <button [disabled]="this.autorizacionDto!=null" [ngClass]="{'marcar': this.busquedaPorMatriculas }" 
          style="width: 100px;margin-right: 1em;" type="button" class="btn btn-secondary"
          (click)="changeTipoSeleccion(TipoSeleccion.Matriculas)">Buscar por Matriculas</button>
        <button [disabled]="this.autorizacionDto!=null" [ngClass]="{'marcar': this.busquedaPorClientes }" 
          style="width: 100px;" type="button" class="btn btn-secondary" 
          (click)="changeTipoSeleccion(TipoSeleccion.Clientes)">Buscar por Clientes</button>          
      </div>
      
      <ng-container *ngIf="this.busquedaPorMatriculas">
        <!-- buscador de todas las matriculas -->
        <div class="mb-3 row">
          <label for="aeronaveFormControl" class="col-form-label col-sm-3">Aeronave:</label>                        
          <div class="col-sm-9">              
            <div class="input-group">
              <input id="aeronaveFormControl" type="text" class="form-control" formControlName="aeronaveFormControl"
                  [inputFormatter]="formatterAeronave" 
                  [resultFormatter]="formatterAeronave"
                  [readonly]="this.autorizacionDto!=null"
                  (change)="changeAeronave()"
                  [ngbTypeahead]="searchAeronave" 
                  (selectItem)="selectAeronave($event)" />
                  <div class="input-group-append">
                    <button 
                      type="button" class="input-group-btn btn btn-secondary"   
                      [disabled]="this.autorizacionDto!=null" 
                      (click)="nuevoAeronave(aeronaveFormControl)"
                      tabindex=-1><i class="fa fa-plus-square" aria-hidden="true"></i>
                    </button>
                  </div>
            </div>              
            <div class="limitadas" *ngIf="this.matriculaTipo=='Limitado' " >Matricula limitada</div>
            <div class="libres" *ngIf="this.matriculaTipo=='Libre' " >Matricula libre</div>          
            <div *ngIf="aeronaveFormControl.invalid && aeronaveFormControl.errors.required && (aeronaveFormControl.dirty || aeronaveFormControl.touched)"
              class="alert alert-danger">Requerido</div>
            <div *ngIf="(aeronaveFormControl.invalid && aeronaveFormControl.errors.aeronaveInvalid && (aeronaveFormControl.dirty || aeronaveFormControl.touched))"
              class="alert alert-danger">La matricula ingresada no es válida.</div>  
          </div>
        </div>
        
        <!-- clientes asociados a matriculas -->
        <div *ngIf="!this.cargandoAeronavesCliente && this.clientesAeronave && this.mostrarClienteMatriculas" class="mb-3 row">
          <label for="relacionesFormControl" class="col-form-label col-sm-3">Cliente:</label>
          <div class="col-sm-9">                            
            <div class="input-group">
              <select id="relacionesFormControl" class="form-select" type="text" formControlName="relacionesFormControl"
                [readonly]="this.autorizacionDto!=null"
                (change)="changeClienteMatricula()">
                <option [value]="null" selected disabled> Seleccione un Cliente </option>
                <option *ngFor="let cliente of clientesAeronave" [ngValue]="cliente">
                  {{cliente.nombre}} - {{cliente.cuenta}}
                </option>
              </select>
            </div>
            <div class="limitadas" *ngIf="this.clienteTipo=='Limitado' ">Cliente limitado</div>
            <div class="libres" *ngIf="this.clienteTipo=='Libre' ">Cliente libre</div>  
            <div *ngIf="relacionesFormControl.invalid && relacionesFormControl.errors.required && (relacionesFormControl.dirty || relacionesFormControl.touched)"
              class="alert alert-danger">Requerido</div>
            <div *ngIf="(relacionesFormControl.invalid && relacionesFormControl.errors.relacionInvalid && (relacionesFormControl.dirty || relacionesFormControl.touched))"
              class="alert alert-danger">El cliente seleccionado no es válido.</div>
          </div>
        </div>
        
        <!-- buscador de todas los clientes -->
        <div *ngIf="this.forzarBusquedaPorClientes" class="mb-3 row">
          <label for="clienteFormControl" class="col-sm-3 col-form-label">Cliente:</label>
          <div class="col-sm-9">
              <div class="input-group">
                <input id="clienteFormControl" type="text" class="form-control" formControlName="clienteFormControl"
                    [inputFormatter]="formatter" [resultFormatter]="formatter"
                    [readonly]="this.autorizacionDto!=null"
                    (change)="changeCliente()"
                    [ngbTypeahead]="search" 
                    (selectItem)="selectCliente($event)" />
                    <div class="input-group-append">
                      <button type="button" class="input-group-btn btn btn-secondary" 
                        [disabled]="this.autorizacionDto!=null" 
                        (click)="nuevoCliente(clienteFormControl)"
                        tabindex=-1><i class="fa fa-plus-square" aria-hidden="true"></i>
                      </button>
                    </div>
              </div>
              <div class="limitadas" *ngIf="this.clienteTipo=='Limitado' ">Cliente limitado</div>
              <div class="libres" *ngIf="this.clienteTipo=='Libre' ">Cliente libre</div>  
              <div *ngIf="clienteFormControl.invalid && clienteFormControl.errors.required && (clienteFormControl.dirty || clienteFormControl.touched)"
                  class="alert alert-danger">Requerido</div>
              <div *ngIf="(clienteFormControl.invalid && clienteFormControl.errors.clienteInvalid && (clienteFormControl.dirty || clienteFormControl.touched))"
                class="alert alert-danger">El cliente ingresado no es válido.</div>               
          </div>
        </div>
      </ng-container>
          
      <ng-container *ngIf="this.busquedaPorClientes"> 
        <!-- buscador de todas los clientes -->       
        <div class="mb-3 row">
            <label for="clienteFormControl" class="col-sm-3 col-form-label">Cliente:</label>
            <div class="col-sm-9">
                <div class="input-group">
                <input id="clienteFormControl" type="text" class="form-control" formControlName="clienteFormControl"
                    [inputFormatter]="formatter" [resultFormatter]="formatter"
                    [readonly]="this.autorizacionDto!=null"
                    (change)="changeCliente()"
                    [ngbTypeahead]="search" (selectItem)="selectCliente($event)" />
                    <div class="input-group-append">
                        <button 
                          type="button" class="input-group-btn btn btn-secondary" 
                          [disabled]="this.autorizacionDto!=null"
                          (click)="nuevoCliente(clienteFormControl)"
                          tabindex=-1><i class="fa fa-plus-square" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
                <div class="limitadas" *ngIf="this.clienteTipo=='Limitado' ">Cliente limitado</div>
                <div class="libres" *ngIf="this.clienteTipo=='Libre' ">Cliente libre</div>   
                <div *ngIf="clienteFormControl.invalid && clienteFormControl.errors.required && (clienteFormControl.dirty || clienteFormControl.touched)"
                    class="alert alert-danger">Requerido</div> 
                <div *ngIf="(clienteFormControl.invalid && clienteFormControl.errors.clienteInvalid && (clienteFormControl.dirty || clienteFormControl.touched))"
                  class="alert alert-danger">El cliente ingresado no es válido.</div>
            </div>
        </div>

        <!-- matriculas asociados a clientes -->          
        <div *ngIf="!this.cargandoAeronavesCliente && this.aeronavesCliente && this.mostrarClienteMatriculas" class="mb-3 row">
          <label for="relacionesFormControl" class="col-form-label col-sm-3">Aeronave:</label>
          <div class="col-sm-9">                            
            <div class="input-group">
              <select id="relacionesFormControl" class="form-select" type="text" formControlName="relacionesFormControl" 
                [readonly]="this.autorizacionDto!=null"
                (change)="changeClienteMatricula()">
                <option [value]="null" selected disabled> Seleccione una Matricula </option>
                <option *ngFor="let aeronave of aeronavesCliente" [ngValue]="aeronave">
                  {{ aeronave.matricula }}
                </option>
              </select>
            </div>
            <div class="limitadas" *ngIf="this.matriculaTipo=='Limitado' " >Matricula limitada</div>
            <div class="libres" *ngIf="this.matriculaTipo=='Libre' " >Matricula libre</div>         
            <div *ngIf="relacionesFormControl.invalid && relacionesFormControl.errors.required && (relacionesFormControl.dirty || relacionesFormControl.touched)"
              class="alert alert-danger">Requerido</div>
            <div *ngIf="(relacionesFormControl.invalid && relacionesFormControl.errors.relacionInvalid && (relacionesFormControl.dirty || relacionesFormControl.touched))"
              class="alert alert-danger">La matricula seleccionada no es válida.</div>
          </div>
        </div>
        <loading-text-spinner *ngIf="this.cargandoAeronavesCliente" [error]="errorAeronavesCliente" message="Obteniendo aeronaves..."
          color="primary">
        </loading-text-spinner>

        <!-- buscador de todas las matriculas --> 
        <div *ngIf="this.forzarBusquedaPorMatriculas" class="mb-3 row">          
          <label for="aeronaveFormControl" class="col-form-label col-sm-3">Aeronave:</label>                        
          <div class="col-sm-9">              
            <div class="input-group">
              <input id="aeronaveFormControl" type="text" class="form-control" formControlName="aeronaveFormControl"
                  [inputFormatter]="formatterAeronave" [resultFormatter]="formatterAeronave"
                  [readonly]="this.autorizacionDto!=null"
                  (change)="changeAeronave()"
                  [ngbTypeahead]="searchAeronave" (selectItem)="selectAeronave($event)" />
                  <div class="input-group-append">
                      <button 
                        type="button" class="input-group-btn btn btn-secondary" 
                        [disabled]="this.autorizacionDto!=null" 
                        (click)="nuevoAeronave(aeronaveFormControl)"
                        tabindex=-1><i class="fa fa-plus-square" aria-hidden="true"></i>
                      </button>
                  </div>
            </div>              
            <div class="limitadas" *ngIf="this.matriculaTipo=='Limitado' " >Matricula limitada</div>
            <div class="libres" *ngIf="this.matriculaTipo=='Libre' " >Matricula libre</div>              
            <div *ngIf="aeronaveFormControl.invalid && aeronaveFormControl.errors.required && (aeronaveFormControl.dirty || aeronaveFormControl.touched)"
              class="alert alert-danger">Requerido</div>  
            <div *ngIf="(aeronaveFormControl.invalid && aeronaveFormControl.errors.aeronaveInvalid && (aeronaveFormControl.dirty || aeronaveFormControl.touched))"
              class="alert alert-danger">La matricula ingresada no es válida.</div>            
          </div>
        </div>
      </ng-container>      
    </div> 
    <ng-container *ngIf="this.mostrarClienteMatriculas">
      <button style="width: 100px;" type="button" class="btn btn-secondary" (click)="habilitarBusqueda()">Usar {{ busquedaPorClientes ? ' otra matricula' : ' otro cliente' }}</button>
    </ng-container>
    <ng-container *ngIf="!this.autorizando && this.autorizacionDto!=null">
      <div *ngIf="this.estadoResultado=='Pendiente' " class="alert alert-primary text-center" role="alert">Solicitud enviada, espere por favor...</div>
      <div *ngIf="this.estadoResultado=='Aprobado' " class="alert alert-success text-center" role="alert">Solicitud aprobada</div>
      <div *ngIf="this.estadoResultado=='Rechazado' " class="alert alert-danger text-center" role="alert">Solicitud rechazada</div>    
    </ng-container>  
  </div>

  <div style="display: block;" class="modal-footer text-center">    
    <ng-container *ngIf="this.requiereAutorizacion">
      <div *ngIf="!this.requiereVerificacion">
        <button 
          class="btn btn-primary" type="button" 
          [disabled]="autorizando || formSelec.invalid"
          (click)="autorizarPeticion()">
          <span *ngIf="!autorizando">Solicitar autorización para utilizar {{ busquedaPorClientes ? ' esta matricula' : ' este cliente' }}</span>
          <div *ngIf="autorizando" class="d-flex align-items-center">
            <div class="spinner-border text-light spinner-border-sm" role="status"></div>
            <span>Solicitando autorización...</span>
          </div>
        </button>
      </div>
      <div *ngIf="this.requiereVerificacion ">
        <button 
          class="btn btn-primary" type="button"  
          *ngIf="this.estadoResultado=='Pendiente' " 
          [disabled]="verificando || formSelec.invalid"        
          (click)="verificarPeticion()">
          <span *ngIf="!verificando"> Verificar autorización </span>
          <div *ngIf="verificando" class="d-flex align-items-center">
            <div class="spinner-border text-light spinner-border-sm" role="status"></div>
            <span>Vericando autorización...</span>
          </div>
        </button>
        <button *ngIf="this.autorizacionDto!=null && this.estadoResultado=='Aprobado' " 
          [disabled]="formSelec.invalid"
          class="btn btn-primary" (click)="onClick()" >
          Aceptar
        </button>
        <button *ngIf="this.autorizacionDto!=null && this.estadoResultado=='Rechazado' " 
          class="btn btn-danger" (click)="changeTipoSeleccion(TipoSeleccion.Matriculas)" >
          Reiniciar
        </button>
      </div>       
    </ng-container>    
    <ng-container *ngIf="!this.requiereAutorizacion">
      <button [disabled]="formSelec.invalid" class="btn btn-success" (click)="onClick()">
          Aceptar</button>
      <button class="btn btn-danger" (click)="cerrar()">
          Cancelar</button>
    </ng-container>
  </div>
</form>