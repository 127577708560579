<app-tablero *ngIf="this.productoSeleccionado==null"></app-tablero>

<nav *ngIf="this.productoSeleccionado!=null" class="navbar navbar-light bg-light navbar-expand-lg mb-3">

  <form *ngIf="this.productoSeleccionado!=null" class="form-inline flex-fill">

    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <label *ngIf="this.productoSeleccionado!=null && !this.obteniendoDetalleDeProducto" class="fw-bold">Detalle
      del producto: {{this.productoSeleccionado.nombreProducto}}</label> 


    <div ngbDropdown autoClose="outside" *ngIf="this.productoSeleccionado!=null && !this.obteniendoDetalleDeProducto">
      <button class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2" id="dropdownTipoOrigen"
        ngbDropdownToggle>Contenedores</button>
      <div ngbDropdownMenu aria-labelledby="dropdownTipoOrigen" id="origen">
        <div class="px-4 py-3">
          <div *ngFor="let cont of contenedoresParaCheckear; let i = index">
            <div class="form-check">
              <input type="checkbox" name="tipoOrigen{{i}}" class="form-check-input"
                id="{{ 'dropdownCheck' + cont.nombre}}" [(ngModel)]="cont.seleccionado"
                (ngModelChange)="cont.seleccionadoOrigen = $event" (change)="filtrarMovimientos($event)">
              <label class="form-check-label text-nowrap" for="{{ 'dropdownCheck' + cont.nombre}}">
                {{cont.nombre}}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>


  <div class="container" style="height: 10cm;" *ngIf="this.obteniendoDetalleDeProducto">
    <ngx-spinner *ngIf="this.productoSeleccionado!=null" name="spinnerLista"
        [ngStyle]="{'height': '180px', 'z-index':'0'}" bdOpacity=0.3bd Color="rgba(193,193,193,0)" size="medium"
      color="#185db5" type="ball-grid-beat" [fullScreen]="false">
      <p style="color: white">C a r g a n d o . . . </p>
    </ngx-spinner>
  </div>
  <button *ngIf="this.productoSeleccionado!=null && !this.obteniendoDetalleDeProducto"
    class="btn btn-primary float-end" (click)="goBack()">Volver</button>
</nav>

<ngb-alert [dismissible]="false"
  *ngIf="!obteniendoDetalleDeProducto && listaDetalleStockFiltrado!=null && listaDetalleStockFiltrado.length==0">
  No existen detalles para la busqueda seleccionada</ngb-alert>

<div [formGroup]="formDetalle" *ngIf="this.productoSeleccionado!=null && listaDetalleStockFiltrado.length>0">
  <div formArrayName="graficosArray">
    <div id="controlDinamico" *ngFor="let u of graficasProducto; let index=index;" class="col col-md-3"
      style="display: inline-block;">
      <div [formGroupName]="index">
        <div class="mb-3 card" *ngIf="!this.obteniendoDetalleDeProducto">
          <div class="card-body">
            <highcharts-chart [Highcharts]="Highcharts" [options]="graficasProducto[index]" id="formGraficoStock"
              style="width: 100%; height: 400px; display: block;">
            </highcharts-chart>
          </div>
          <table class="table table-striped">
            <tbody> 
              <tr>
                <th scope="row">Capacidad</th>
                <td class="text-end">{{listaDetalleStockFiltrado[index].contenedor.capacidad | number: '1.0-0'}}</td>
              </tr> 
              <tr>
                <th scope="row">Stock</th>
                <td class="text-end">{{listaDetalleStockFiltrado[index].stock | number: '1.0-0'}}</td>
              </tr>
              <tr>
                <th scope="row">Stock 15°</th>
                <td class="text-end">{{listaDetalleStockFiltrado[index].stock15 | number: '1.0-0'}}</td>
              </tr>
              <!-- <tr *ngIf="listaDetalleStockFiltrado[index].aforador != null">
                <th scope="row">Aforador</th>
                <td class="text-end">{{listaDetalleStockFiltrado[index].aforador}}</td>
              </tr>
              <tr *ngIf="listaDetalleStockFiltrado[index].aforador == null">
                <th scope="row">Aforador</th>
                <td class="text-end">--------</td>
              </tr> -->
              <tr>
                <th scope="row">Vacio</th>
                <td class="text-end">
                  {{listaDetalleStockFiltrado[index].contenedor.capacidad - listaDetalleStockFiltrado[index].stock | number: '1.0-0'}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>