<form [formGroup]="envioMail">
  <div class="modal-header bg-light">
    <h4 class="modal-title">Enviar mail al cliente</h4>
  </div>

  <div class="modal-body">
    <div class="mb-3">
      <label class="col-form-label">E-mails:</label>
      <div class="row">
        <div class="col-8">
          <input class="form-control" formControlName="mail">
          <div
                *ngIf="mail.invalid && mail.errors.pattern && (mail.dirty || mail.touched)"
                class="alert alert-danger">
                El E-mail no tiene el formato correcto.
              </div>
        </div>
        <div class="col-4">
          <button class="btn btn-outline-info" (click)="agregarMail()">Agregar</button>
        </div>
      </div>
    </div>
    <div *ngIf="this.arrayMails.length > 0">
      <div *ngFor="let m of this.arrayMails">
        <div class="row">
          <div class="col-9">
            <label class="col-form-label">{{m}}</label>
          </div>
          <div class="col-3"><button type="button" (click)="desasignarMail(m)" class="btn btn-danger btn-sm col-12"><i
                class="fa fa-trash col-12" aria-hidden="true"></i></button></div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer bg-light">
    <span class="border">
      <button type="button" class="btn btn-primary" [disabled]="this.arrayMails.length == 0" (click)="enviarMail()">
        <span *ngIf="!enviandoMail">Enviar
          datos por mail</span>
          <div *ngIf="enviandoMail" class="d-flex align-items-center">
            <div class="spinner-border text-light spinner-border-sm" role="status"></div>
            <span>Enviando...</span>
          </div>
        </button>
    </span>
    <span class="border">
      <button type="button" class="btn btn-secondary" (click)="this.activeModal.dismissAll()">Cancelar</button>
    </span>
  </div>
</form>