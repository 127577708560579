<div class="modal-header bg-light">
    <h5 class="modal-title">Nuevo destino para Aeroplanta: {{ this.nombreAeroplanta | uppercase }}</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismissAll()"></button>
</div>

<div class="modal-body">
    <form [formGroup]="destinoForm">
        <div class="row">
            <div class="mb-3 row col-md-12">
            <label for="codigoFormControl" class="col-sm-5 col-form-label">*Código Destino:</label>
                <div class="col-sm-7">
                    <input  id="codigoFormControl" type="text" class="form-control" formControlName="codigoFormControl" placeholder="Codigo Destino"
                        [inputFormatter]="formatterAeropuerto" [resultFormatter]="formatterAeropuerto"
                        (change)="confirmAeropuerto()" (selectItem)="selectAeropuerto($event)" [ngbTypeahead]="searchAeropuerto" />                      
                    <div *ngIf="codigoFormControl.invalid && codigoFormControl.errors.required && (codigoFormControl.dirty || codigoFormControl.touched)"
                        class="alert alert-danger">Requerido
                    </div>
                    <loading-text-spinner *ngIf="cargandoAeropuertos" message="Obteniendo aeropuertos..."
                        color="primary">
                    </loading-text-spinner>
                </div>
            </div>        
        </div>
        <br>

        <!-- Cuentas -->
        <div class="row">
            <div class="mb-3 row col-md-12">
                <label for="cuentaFormControl" class="col-sm-5 col-form-label">Cuenta:</label>
                <div class="col-sm-7">
                    <select class="form-select" formControlName="cuentaFormControl" id="cuentaFormControl">
                        <option [ngValue]="null" [selected]="false">Seleccione una Cuenta</option>
                        <option *ngFor="let c of this.cuentas" [value]="c.ctA_SGC">
                            {{c.ctA_SGC}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
    
        <div class="modal-footer d-flex justify-content-center">
            <span class="border">
                <button type="button" class="btn btn-primary" (click)="guardarDestinos()" [disabled]="guardandoDatos || destinoForm.invalid">
                    <span *ngIf="!guardandoDatos">Guardar</span>          
                    <div *ngIf="guardandoDatos" class="d-flex align-items-center">
                        <div class="spinner-border text-light spinner-border-sm" role="status"></div>
                        <span>Guardando...</span>
                    </div>
                </button>
            </span>
        </div>    
    </form>  
</div>