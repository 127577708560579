<div class="modal-header">
  <h5 class="modal-title">Anulacion de aerovale N°: {{completarPrefijo(detalle.prefijo)}}-{{completarNumero(detalle.numeroAerovale)}}</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="anularForm">
    <div class="card-body">
      <div class="mb-3 row">
        <label class="col-md-4 col-form-label" for="motivoFormControl">*Motivo de anulacion:</label>
        <div class="col col-md-6">
          <select formControlName="motivoFormControl" id="motivoAnulacion" class="form-select">
            <option [value]="null" disabled defaultselected>Seleccione un motivo...</option>
            <option *ngFor="let mot of motivos" [value]="mot.id">
              {{mot.nombre}}
            </option>
          </select>
          <loading-text-spinner *ngIf="cargandoObservaciones" [error]="errorObservaciones"
            message="Obteniendo observaciones..." color="primary">
          </loading-text-spinner>
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-md-4 col-form-label" for="descripcionFormControl">*Descripción:</label>
        <div class="col col-md-6">
          <textarea id="descripcionFormControl" class="form-control" formControlName="descripcionFormControl"
            maxlength="255" placeholder="Ingrese una descripción de la anulacion..." rows="6"></textarea>
        </div>
      </div>
    </div>
  </form>
</div>
<small class="text-danger">*Campos obligatorios.</small>
<div class="modal-footer text-center">
  <button class="btn btn-primary" [disabled]="!anularForm.valid || anulando" (click)="anularAerovale()">
    <span *ngIf="!anulando">Anular</span>
    <div *ngIf="anulando" class="d-flex aling-intems-center">
      <div class="spinner-border text-light spinner-border-sm" role="status"></div>
      <span>Anulando...</span>
    </div>
  </button>
  <button class="btn btn-secondary" (click)="modal.dismiss()" [disabled]="anulando">
    Cancelar</button>
</div>
