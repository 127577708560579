import { Component, Inject, Input, LOCALE_ID, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Guid } from 'guid-typescript';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { isEmpty } from 'rxjs/operators';
import { ProductoService } from 'src/app/abm/producto/producto.service';
import { UsuarioService } from 'src/app/account/usuario.service';
import { Aeroespecialidad } from 'src/app/shared/models/cierreTurno/aeroespecialidad';
import { CargaDatos } from 'src/app/shared/models/cierreTurno/CargaDatos';
import { CierreTurno } from 'src/app/shared/models/cierreTurno/CierreTurno';
import { Comparativa } from 'src/app/shared/models/cierreTurno/Comparativa';
import { ComparativaExpendedora } from 'src/app/shared/models/cierreTurno/ComparativaExpendedora';
import { Comparativas } from 'src/app/shared/models/cierreTurno/Comparativas';
import { MedicionTanque } from 'src/app/shared/models/cierreTurno/MedicionTanque';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { Abastecedora } from 'src/app/shared/models/stock/Abastecedora';
import { Medicion } from 'src/app/shared/models/stock/Medicion';
import { StockAeroespecialidad } from 'src/app/shared/models/stock/StockAeroespecialidad';
import { Tanque } from 'src/app/shared/models/stock/Tanque';
import { MessageService } from 'src/app/shell/message.service';
import { StockService } from 'src/app/stock/stock.service';
import { TanqueService } from 'src/app/stock/tanques.service';
import { CierreTurnoService } from '../../cierre-turno.service';
import { Producto } from 'src/app/shared/models/despacho/Producto';
import { SharedService } from 'src/app/shared/shared.service';
import { formatDate } from '@angular/common';
import { CalculosService } from 'src/app/shared/calculos.service';
import { EtapasCierreTurno } from 'src/app/shared/enums/EtapasCierreTurno';

@Component({
  selector: 'app-carga-datos-cierre-turno',
  templateUrl: './carga-datos-cierre-turno.component.html',
  styleUrls: ['./carga-datos-cierre-turno.component.css']
})
export class CargaDatosCierreTurnoComponent implements OnInit {

  @Input() cierreRealizoContinuado: CierreTurno;
  @Input() aeroplantaCodigo: string;

  cuadroComparativas: Comparativas;

  tanques: Tanque[];
  aeroespecialidades: StockAeroespecialidad[];
  calculandoCantidadNeta: boolean = false;
  medicionesParaTanque: boolean = false;
  guardandoMediciones: boolean = false;


  horaCarga: UntypedFormControl;

  change$: Subscription;
  aeroplantaCod: string;
  mensajeFaltaGraduacion: string = '';

  tablaTanques: UntypedFormGroup
  tablaAeroespecialidades: UntypedFormGroup;
  guardando: boolean = false;
  cargandoProductos: boolean = false;

  productos: Producto[] = [];
  productoSeleccionado: Producto;
  terminoCarga: boolean = false;

  obteniendoTanques: boolean;
  hayComparativas: boolean = false;
  tanquesGraduaciones: Tanque[];
  noPuedeContinuar: boolean = false;
  esperandoCarga: boolean = false;
  message: string = 'C A R G A N D O . . .';
  constructor(private fb: UntypedFormBuilder, private stockService: StockService, private spinner: NgxSpinnerService,
    private usuarioService: UsuarioService, private tanquesService: TanqueService, private sharedService: SharedService,
    private router: Router, private cierreService: CierreTurnoService, private activeModel: NgbModal,
    @Inject(LOCALE_ID) public locale: string,
    private messageService: MessageService, private productosService: ProductoService, private calculoService: CalculosService) {
    this.tablaTanques = this.fb.group({
    });
    this.tablaAeroespecialidades = this.fb.group({
    })
    this.change$ = sharedService.aeroplantaSeleccionada.subscribe(_=>{ 
      this.recargarPagina()
    });
  }

  subscripcionConversion15;

  // hooks

  ngOnInit() {
    this.obteniendoTanques = true;
    this.aeroplantaCod = this.usuarioService.getAeroplantaActual().codigoAeroplanta;
    this.cierreRealizoContinuado.aeroespecialidades.sort((a , b) => a.codigoAeroespecialidad.toLocaleUpperCase() > b.codigoAeroespecialidad.toLocaleUpperCase() ? 1 : -1);
    this.cierreRealizoContinuado.medicionesTanques.forEach((value, index) => {
      this.cierreRealizoContinuado.medicionesTanques[index].densidadString = (this.cierreRealizoContinuado.medicionesTanques[index].densidad).toString();
    });
    this.cierreRealizoContinuado.medicionesTanques.sort((a , b) => a.nombreTanque.toLocaleUpperCase() > b.nombreTanque.toLocaleUpperCase() ? 1 : -1);

    this.getProductos();
    this.getTanques();
  }

  ngOnDestroy() {
    this.change$.unsubscribe();
  }

  getProductos() {
    this.terminoCarga = false;
    this.cargandoProductos = true;
    this.productosService.obtenerProductos()
      .subscribe(result => {
        this.productos = result;
        this.cargandoProductos = false;
        if (!this.esperandoCarga) {
          this.terminoCarga = true;
          this.spinner.hide('spinnerCargaDatos');
        }
      }, () => {

      });
  }

  getTanques() {
    this.terminoCarga = false;
    this.esperandoCarga = true;
    this.spinner.show('spinnerCargaDatos');
    this.tanquesService.getTanquesPorAeroplanta(this.aeroplantaCodigo)
      .subscribe(result => {
        this.tanquesGraduaciones = result.filter(a => a.activo == true);
        this.verificarGraduacionesTanques();
      })
  }

  verificarGraduacionesTanques() {
    var nuevasMediciones : MedicionTanque[] = [];

    for (let tanque of this.tanquesGraduaciones) {
      var esNuevaMedicion = true;
      for (let tanqueCierre of this.cierreRealizoContinuado.medicionesTanques) {
        if (tanque.codigo == tanqueCierre.codigoTanque) {
          esNuevaMedicion = false;
          break;
        }
      }

      if(esNuevaMedicion){
        
        var m = new MedicionTanque();
        m.codigoTanque = tanque.codigo;
        m.nombreTanque = tanque.nombre;
        m.codigoProducto = tanque.productoActual.codigoProducto;
        m.nombreProducto = tanque.productoActual.nombreProducto;
        m.varillado = 0;
        m.nivel = 0;
        m.nivelNatural = 0;
        m.temperatura = 0;
        m.densidad = 0;
        m.nivelAgua = 0;
        m.esNuevo = true;
        m.userName = this.usuarioService.getUsernameByName();
  
        m.roleStatus = false;
        var d = new Date();
        m.fechaHora = formatDate(new Date(), 'y-MM-ddTHH:mm:ss', this.locale);        
  
        nuevasMediciones.push(m);
      }      
    }

    if(nuevasMediciones.length > 0){
      this.cierreRealizoContinuado.medicionesTanques = this.cierreRealizoContinuado.medicionesTanques.concat(nuevasMediciones);
    }

    this.cierreRealizoContinuado.medicionesTanques.forEach(m => {
      m.desactivarUI = true;
      m.desactivarTanque = true;
    });
    
    for (let tanque of this.tanquesGraduaciones) {
      if( tanque.tieneGraduacion || tanque.activo){
        // Habilito campos
        for (let tanqueCierre of this.cierreRealizoContinuado.medicionesTanques) {
          if (tanque.codigo == tanqueCierre.codigoTanque) {
            (tanque.tieneGraduacion) ? tanqueCierre.desactivarUI = false : null;
            (tanque.activo) ? tanqueCierre.desactivarTanque = false : null;
            break;
          }
        }
      }      
    }

    this.esperandoCarga = false;
    if (!this.cargandoProductos) {
      this.spinner.hide('spinnerCargaDatos');
      this.terminoCarga = true;
    }
  }

  //form

  recargarPagina() {
    this.ngOnInit();
  }

  changeValue(id: number, property: string, event: any) {
    if (this.cierreRealizoContinuado.medicionesTanques[id][property] != event.target.value) {
      this.cierreRealizoContinuado.medicionesTanques[id][property] = event.target.value;
      this.cierreRealizoContinuado.medicionesTanques[id].esNuevo = true;

      if(property != 'densidad'){
        this.recalcularVolumenes(property, this.cierreRealizoContinuado.medicionesTanques[id]);
      }      
    }
  }

  changeValue2(id: number, property: string, event: any) {
    event.target.value != '' ?  this.cierreRealizoContinuado.aeroespecialidades[id][property] = parseInt(event.target.value) : this.cierreRealizoContinuado.aeroespecialidades[id][property] = null;
  }

  recalcularVolumenes(property, t: MedicionTanque) {
    if(this.subscripcionConversion15 != null){ this.subscripcionConversion15.unsubscribe(); }

    if (t != null && t.varillado.toString() != "" && t.densidad.toString() != '' && t.temperatura.toString() != '') {
      this.medicionesParaTanque = true;

      var tanque = t;
      var varillado = t.varillado.toString();

      var dens = t.densidad.toString();
      var temp = t.temperatura.toString();
      if(property == 'varillado'){
        t.roleStatus = true;
        this.subscripcionConversion15 = this.stockService.getConversionLitrosPorVarillado(t.codigoTanque, varillado)
          .subscribe(volumenLts => {
            t.nivelNatural = parseInt(volumenLts);
  
            if (dens != "" && temp != "") {
              t.nivel = this.calculoService.obtenerCTLNuevo(temp, dens, volumenLts);
  
            }
  
            t.roleStatus = false;
          },
            () => {
              t.roleStatus = false;
              this.medicionesParaTanque = false;
            });
      }else{
        setTimeout(() => {
          var dens = t.densidad.toString();
          var temp = t.temperatura.toString();

          if (dens != "" && temp != "") {
            t.nivel = this.calculoService.obtenerCTLNuevo(temp, dens,  t.nivelNatural);
          }
        }, 200);        
      } 
    }
  }


  recalcularVolumenNaturalAbastecedora(t: Abastecedora) {
    var volumenLts = Number(t.mediciones.varillado);
    var dens = Number(t.mediciones.densidad);
    var temp = Number(t.mediciones.temperatura);

    if (volumenLts != null && dens != null && temp != null) {
      t.mediciones.nivel = this.calculoService.obtenerCTLNuevo(temp, dens, volumenLts);
    }
  }

  formatDensidad(event, i: number) {
    if (event.charCode == 8 || event.charCode == 0) return null;

    if (event.charCode >= 48 && event.charCode <= 57) {
      this.cierreRealizoContinuado.medicionesTanques[i].densidadString = this.sharedService.formatDensidad(event);  
    }    
    this.recalcularVolumenes('densidad', this.cierreRealizoContinuado.medicionesTanques[i]);
    return false;
  }

  verificarstocknegativo():boolean {
    let stockCorrecto:boolean=true;
    for (let index = 0; index < this.cierreRealizoContinuado.aeroespecialidades.length; index++) {
      const element:number = this.cierreRealizoContinuado.aeroespecialidades[index].stock;
      if (element<0) {        
        stockCorrecto=false;
        break;        
      }
    }
    return stockCorrecto;    
  }

  confirmarDatos() {

    if(this.verificarstocknegativo()) {
      this.hayComparativas = false;
      this.guardando = true;
      this.cuadroComparativas = null;
      let stringMensaje: string = '';
      let carga = new CargaDatos();
      carga.aeroespecialidades = [];
      carga.medicionesTanques = [];
      //Recorrido de mediciones y carga de datos.
      // this.cierreRealizoContinuado.medicionesTanques.forEach((value, index) => {
      for (let i = 0; this.cierreRealizoContinuado.medicionesTanques.length > i; i++) {
        carga.medicionesTanques.push(new MedicionTanque);
        if (this.cierreRealizoContinuado.medicionesTanques[i].varillado >= 0 && this.cierreRealizoContinuado.medicionesTanques[i].nivel >= 0, this.cierreRealizoContinuado.medicionesTanques[i].nivelNatural >= 0, this.cierreRealizoContinuado.medicionesTanques[i].densidad >= 0) {
          if (this.validarDensidad(this.cierreRealizoContinuado.medicionesTanques[i]) && this.validarTemperatura(this.cierreRealizoContinuado.medicionesTanques[i].temperatura.toString())) {
            // this.cierreRealizoContinuado.medicionesTanques[i].fechaHora = this.formatDate(this.tanques[i].fechaProvisoria.year, this.tanques[i].fechaProvisoria.month, this.tanques[i].fechaProvisoria.day, this.tanques[i].horaCarga.hour - 3, this.tanques[i].horaCarga.minute);
            carga.medicionesTanques[i].codigoTanque = this.cierreRealizoContinuado.medicionesTanques[i].codigoTanque;
            carga.medicionesTanques[i].nombreTanque = this.cierreRealizoContinuado.medicionesTanques[i].nombreTanque;
            carga.medicionesTanques[i].nombreProducto = this.cierreRealizoContinuado.medicionesTanques[i].nombreProducto;
            carga.medicionesTanques[i].codigoProducto = this.cierreRealizoContinuado.medicionesTanques[i].codigoProducto;
            carga.medicionesTanques[i].varillado = Number(this.cierreRealizoContinuado.medicionesTanques[i].varillado);
            carga.medicionesTanques[i].nivel = this.cierreRealizoContinuado.medicionesTanques[i].desactivarTanque ? 0 : Number(this.cierreRealizoContinuado.medicionesTanques[i].nivel);
            carga.medicionesTanques[i].nivelNatural = this.cierreRealizoContinuado.medicionesTanques[i].desactivarTanque ? 0 : Number(this.cierreRealizoContinuado.medicionesTanques[i].nivelNatural);
            carga.medicionesTanques[i].temperatura = Number(this.cierreRealizoContinuado.medicionesTanques[i].temperatura);
            carga.medicionesTanques[i].densidad = Number(this.cierreRealizoContinuado.medicionesTanques[i].densidadString);
            carga.medicionesTanques[i].nivelAgua = Number(this.cierreRealizoContinuado.medicionesTanques[i].nivelAgua);
            carga.medicionesTanques[i].userName = this.usuarioService.getUsernameByName();
            carga.medicionesTanques[i].esNuevo = this.cierreRealizoContinuado.medicionesTanques[i].esNuevo;
          } else {
            stringMensaje += 'El tanque ' + this.cierreRealizoContinuado.medicionesTanques[i].codigoTanque + ' tiene errores en su carga.';
          }
        } else {
          stringMensaje += 'El tanque ' + this.cierreRealizoContinuado.medicionesTanques[i].codigoTanque + ' no tiene cargado datos.';
        }
      };
      let x=0;
      this.cierreRealizoContinuado.aeroespecialidades.forEach((value2, index2) => {
        if (this.cierreRealizoContinuado.aeroespecialidades[index2].stock != null) {
          carga.aeroespecialidades.push(new Aeroespecialidad)
          carga.aeroespecialidades[x].stock = this.cierreRealizoContinuado.aeroespecialidades[index2].stock;
          carga.aeroespecialidades[x].nombreAeroespecialidad = this.cierreRealizoContinuado.aeroespecialidades[index2].nombreAeroespecialidad;
          carga.aeroespecialidades[x].codigoAeroespecialidad = this.cierreRealizoContinuado.aeroespecialidades[index2].codigoAeroespecialidad;
          x++;
        } else {
          stringMensaje += 'La aeroespecialidad ' + this.cierreRealizoContinuado.aeroespecialidades[index2].nombreAeroespecialidad + ' no tiene cargado datos.';
        }
      });
      if (stringMensaje == '') {
        this.cierreService.postCierreCargarDatos(this.aeroplantaCodigo, carga, this.cierreRealizoContinuado)
          .subscribe(result => {
            if (result != null) {
              this.hayComparativas = true;
              this.cuadroComparativas = result;
              this.guardando = false;
              this.completeStep(Object.keys(EtapasCierreTurno).indexOf('Etp2'));
              this.messageService.showSuccessMessage('La carga de datos se envió correctamente.');
            }
  
          }, (err: ErrorModel) => {
            this.hayComparativas = false;
            this.guardando = false;
            this.messageService.showErrorMessage('Hubo un error al enviar la información' + err.description);
          });
      } else {
        this.guardando = false;
        this.messageService.showErrorMessage('Por favor complete correctamente los datos.' + stringMensaje)
      }
    }else{
      this.messageService.showErrorMessage("La cantidad del conteo de aeroespecialidad debe ser mayor/igual a cero");
    }
  }

  completeStep(index: number): void {
    this.cierreService.updateStepProgress(index, true);
  }

  // validaciones

  validarDensidad(medicion: MedicionTanque) {
    if (medicion.densidad != null) {
      this.productoSeleccionado = this.productos.find(element => element.codigoProducto === medicion.codigoProducto);
      let esValido = true;
      esValido = (medicion.densidad >= this.productoSeleccionado?.densidadMin && medicion.densidad <= this.productoSeleccionado?.densidadMax);
      return esValido;
    }
  }

  validarTemperatura(temp: string): boolean {
    let valida = true;
    if (parseFloat(temp) > 100) {
      valida = false;
    }

    if (parseFloat(temp) < -50) {
      valida = false;
    }
    return valida;
  }


  onlyDecimalPositivo(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46 || event.charCode == 45;
  }

  formatDate(year, month, day, hour, minute): Date {
    return new Date(year + '-' + month.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + '-' + day.toLocaleString(undefined, { minimumIntegerDigits: 2 })
      + 'T' + hour.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + minute.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':00');
  }
}
