<nav class="navbar navbar-light bg-light navbar-expand-lg">
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav">
            <li class="nav-item me-2">
                <input id="busquedaFormControl" type="text" class="form-control" placeholder="Cliente/Cuenta"
                    [formControl]="busquedaFormControl" (input)="filtrarMovimientos()" />
            </li>
            <!-- desde -->
            <li class="nav-item me-2">
            <div class="input-group">

                <input type="date" [ngModel]="model" class="fechaFormControlDesdeHasta" placeholder="aaaa-mm-dd"
                (ngModelChange)="model = $event" name="dt" ngbDatepicker #d="ngbDatepicker" value="model"
                class="form-control form-control-rounded" #id="ngbDatepicker" #searchDate required readonly>

                <div class="input-group-append">
                <button id="botonDesde" type="button" class="input-group-btn btn btn-secondary" (click)="toggleDatepicker(d)">
                    <i class="fa fa-calendar" aria-hidden="true"></i></button>
                </div>
            </div>
            </li>
            <!-- hasta -->
            <li class="nav-item">
            <div class="input-group">
                <input type="date" class="fechaFormControlDesdeHasta" [ngModel]="model1" placeholder="aaaa-mm-dd"
                name="dp" value="model1" (ngModelChange)="model1 = $event" class="form-control form-control-rounded"
                ngbDatepicker #h="ngbDatepicker" #searchDate required readonly>

                <div class="input-group-append">
                <button id="botonHasta" type="button" class="input-group-btn btn btn-secondary" (click)="toggleDatepicker(h)"><i
                    class="fa fa-calendar" aria-hidden="true"></i></button>
                </div>
            </div>
            <li class="nav-item  me-2">
                <button (click)="this.filtrarMovimientosPorFecha()" class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2"
                    type="button"><i class="fa fa-search" aria-hidden="true"></i>
                </button>
            </li>

            <!-- Aeroplantas -->
            <li *ngIf="this.mostrarFiltroAeroplanta()" class="nav-item">
                <select class="form-select" id="aeroplantaFormControl" [formControl]="aeroplantaFormControl"
                    (change)="this.filtrarMovimientos()">
                    <option [value]="null" defaultSelected>Todas las aeroplantas</option>
                    <option *ngFor="let aer of aeroplantas" [value]="aer.codigoAeroplanta">
                        {{aer.nombre}}
                    </option>
                </select>
                <loading-text-spinner *ngIf="cargandoAeroplantas" 
                    message="Obteniendo aeroplantas..."color="primary">
                </loading-text-spinner>
            </li>                                     
        </ul>
    </div>
</nav>
    
<div class="col-12 container-fluid">

    <div class="spinner-container" *ngIf="obteniendoDatos">
        <ngx-spinner name="spinnerGrafico" [ngStyle]="{'height': '400px', 'z-index':'0'}" bdOpacity=0.3bd
            Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
            <p style="color: white">C a r g a n d o . . . </p>
        </ngx-spinner>
    </div>

    <ngb-alert class="alert alert-warning mt-2" *ngIf="this.saldosAlije.length == 0 && !this.obteniendoDatos">
        No existen movimientos entre fechas seleccionadas.
    </ngb-alert>
    
    <ngb-alert class="alert alert-warning mt-2" *ngIf="this.saldosAlijeFiltrados.length == 0 && !this.obteniendoDatos">
        No existen movimientos para la busqueda seleccionada.
    </ngb-alert>

    <div *ngIf="!obteniendoDatos && saldosAlijeFiltrados.length>0"
        id="scroll-infinito" 
        infinite-scroll
        [infiniteScrollDistance]="distance"
        [infiniteScrollThrottle]="throttle" 
        [scrollWindow]="false"
        [infiniteScrollDisabled]="this.obteniendoDatosScroll" 
        (scrolled)="onScrollSaldos()"        
        >

        <div *ngFor="let cli of saldosAlijeFiltrados">
            <h5 class="modal-title">Cliente: {{ cli.cliente +" - "+ cli.nroCuenta + " | Producto: " + cli.productoNombre + " | Aeroplanta: " + cli.nombreAeroplanta +" - "+cli.codigoAeroplanta}}</h5>
            <table class="table table-striped table-hover" id="tablaInformacion"
                *ngIf="this.cli.detalle.length > 0">
                <thead class="titulo text-center">
                    <th>Movimiento</th>
                    <th>Comprobante</th>
                    <th>Fecha</th>
                    <th>Alije</th>
                    <th>Devolucion</th>
                    <th>Saldo</th>
                    <th>Ver</th>
                </thead>
                <tbody class="text-center ">
                    <tr *ngFor="let item of cli.detalle; let i=index"
                        [ngClass]="{'negrita': item.tipoMovimiento==TipoMovimiento.SaldoAnterior }">
                        <td>{{obtenerTipoMovimiento(item.tipoMovimiento)}}</td>
                        <td>{{ item.tipoMovimiento!=TipoMovimiento.SaldoAnterior ? item.numero : null }}</td>
                        <td>{{item.fecha | date: "d'/'MM'/'y' 'hh:mm a" }}</td>
                        <td>{{item.tipoMovimiento==TipoMovimiento.Alije ? item.cantidad : null | number:"1.0-0" }}</td>        
                        <td>{{item.tipoMovimiento==TipoMovimiento.Devolucion || item.tipoMovimiento==TipoMovimiento.Pedido ? item.cantidad : null | number:"1.0-0" }}</td> <!-- devolucion o pedido-->
                        <td>{{item.saldo | number:"1.0-0"}}</td> 
                        <td>
                            <ng-container *ngIf="item.tipoMovimiento==TipoMovimiento.Alije || item.tipoMovimiento==TipoMovimiento.Devolucion">
                                <button class="btn"  (click)="obtenerAerovale(item.movimientoId, i)" ngbTooltip="Ver aerovale">
                                    <i *ngIf="!obteniendoAerovale || obteniendoAerovale && aerovaleSeleccionado!=i" class="fa fa-eye" aria-hidden="true"></i>
                                    <div *ngIf="aerovaleSeleccionado==i && obteniendoAerovale">
                                        <div class="spinner-border text-primary spinner-border-sm" role="status"></div>
                                        <span><i class="fa fa-eye" aria-hidden="true"></i></span>
                                    </div>
                                </button>
                            </ng-container>                        
                            <ng-container *ngIf="item.tipoMovimiento==TipoMovimiento.Pedido">
                                <button class="btn"  (click)="mostrarPedido(item, i)" ngbTooltip="Ver pedido">
                                    <i *ngIf="!obteniendoPedido || obteniendoPedido && pedidoSeleccionado!=i" class="fa fa-eye" aria-hidden="true"></i>
                                    <div *ngIf="pedidoSeleccionado==i && obteniendoPedido">
                                        <div class="spinner-border text-primary spinner-border-sm" role="status"></div>
                                        <span><i class="fa fa-eye" aria-hidden="true"></i></span>
                                    </div>
                                </button>
                            </ng-container>
                        </td>                    
                    </tr>
                    <tr class="resaltado">
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>Saldo Actual</td>
                        <td>{{ cli.saldoFinal | number: '1.0-0' }}</td>
                        <td></td>                        
                    </tr>
                </tbody>
            </table>
        </div> 
        <div style="justify-content: center;" *ngIf="this.obteniendoDatosScroll" class="d-flex align-items-center text-primary">
            <div class="spinner-border" role="status"></div><span>Cargando mas datos..</span>
        </div>  
    </div> <!--Scroll --> 
</div>