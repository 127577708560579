import { Aeroplanta } from '../despacho/Aeroplanta';

export class Provincia {
    id: string;
    nombre: string;
    codigo: string;
    codigoNumerico: number;
    aeroplantas: Aeroplanta[];
    pais: string;
    // pais: {
    //     id: string,
    //     nombre: string,
    //     codigo: string
    // };
};