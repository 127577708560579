import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { environment } from 'src/environments/environment';
import { NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ProductoService } from 'src/app/abm/producto/producto.service';
import { Lote } from 'src/app/shared/models/precios/Lote';
import { PrecioAprobacion } from 'src/app/shared/models/precios/PrecioAprobacion';
import { UntypedFormGroup } from '@angular/forms';
import { TipoPrecioReporte } from 'src/app/shared/enums/TipoPrecioReporte';
import { ConsultaPrecioFacturaDTO } from 'src/app/shared/models/precios/ConsultaPrecioFacturaDTO';
import { UtilPrecio } from 'src/app/shared/utiles/UtilPrecios';

@Component({
  selector: 'app-descarga-precios',
  templateUrl: './descarga-precios.component.html',
  styleUrls: ['./descarga-precios.component.css']
})
export class DescargaPreciosComponent implements OnInit {

  @Output() cancelado = new EventEmitter();

  TipoPrecioReporte = TipoPrecioReporte;

  messageDescarga: string = "C a r g a n d o . . . ";
  esperandoCarga: boolean = false;
  descargando: boolean = false;

  tipoPrecioReporte: TipoPrecioReporte;
  listadoPreciosSubidos: Lote[] = [];
  listadoPreciosAprobados: PrecioAprobacion[] = [];
  listadoPreciosFacturados: ConsultaPrecioFacturaDTO[] = [];

  modelDesde: NgbDateStruct;
  modelHasta: NgbDateStruct;
  fechaDesde: Date;
  fechaHasta: Date;

  tablaPreciosAprobados = new UntypedFormGroup({ });
  tablaPreciosSubidos = new UntypedFormGroup({ });

  constructor(
      private spinner: NgxSpinnerService, 
      private http: HttpClient, 
      private calendar: NgbCalendar, 
      private productoService: ProductoService) { }

  ngOnInit() {
    this.modelDesde = this.calendar.getToday();
    this.fechaDesde = new Date(this.modelDesde.year, this.modelDesde.month - 1, this.modelDesde.day);
    this.modelHasta = this.calendar.getToday();
    this.fechaHasta = new Date(this.modelHasta.year, this.modelHasta.month - 1, this.modelHasta.day);
    this.getLotes();
  }

  getLotes() {
    this.esperandoCarga = true;
    this.spinner.show('spinnerLista');
   
    this.productoService.getLotesPorFecha(this.stringFechaDesde(), this.stringFechaHasta())
      .subscribe(result => {
        this.listadoPreciosSubidos = result;
        this.esperandoCarga = false;
        this.spinner.hide('spinnerLista');
      });
  }

  getLotesAprobacion() {
    this.esperandoCarga = true;
    this.spinner.show('spinnerLista');
    
    this.productoService.getLotesAprobacion(this.stringFechaDesde(), this.stringFechaHasta())
      .subscribe(result => {
        this.listadoPreciosAprobados = result;
        this.esperandoCarga = false;
        this.spinner.hide('spinnerLista');
      });
  }

  getPreciosFacturados() {
    this.esperandoCarga = true;
    this.spinner.show('spinnerLista');
    
    this.productoService.getPreciosFacturados(this.stringFechaDesde(), this.stringFechaHasta())
      .subscribe(result => {
        this.listadoPreciosFacturados = result;
        this.esperandoCarga = false;
        this.spinner.hide('spinnerLista');
      }, () => {
        this.spinner.hide('spinnerLista');
        this.esperandoCarga = false;
      });
  }

  stringFechaDesde(){
    this.fechaDesde = new Date(this.modelDesde.year, this.modelDesde.month - 1, this.modelDesde.day);    
    let desde: string = this.formatDate(this.fechaDesde.getUTCFullYear(), this.fechaDesde.getMonth() + 1, this.fechaDesde.getDate(), 0, 0);    

    return desde;
  }

  stringFechaHasta(){
    this.fechaHasta = new Date(this.modelHasta.year, this.modelHasta.month - 1, this.modelHasta.day);
    let hasta: string = this.formatDate(this.fechaHasta.getUTCFullYear(), this.fechaHasta.getMonth() + 1, this.fechaHasta.getDate(), 23, 59);

    return hasta;
  }

  cambiarTabla(tipoPrecio: number){
    if(tipoPrecio == TipoPrecioReporte.PreciosSubidos){
      this.tipoPrecioReporte =  TipoPrecioReporte.PreciosSubidos;
      this.getLotes();
    }else if(tipoPrecio == TipoPrecioReporte.PreciosAprobados){
      this.tipoPrecioReporte =  TipoPrecioReporte.PreciosAprobados;
      this.getLotesAprobacion();
    }else if(tipoPrecio == TipoPrecioReporte.PreciosFacturados){
      this.tipoPrecioReporte =  TipoPrecioReporte.PreciosFacturados;
      //this.getPreciosFacturados(); Se muestra grafana por lo que se ignora la obtencion de datos
    }
  }

  buscardatos(){
    if(this.tipoPrecioReporte == TipoPrecioReporte.PreciosSubidos){
      this.getLotes();
    }else if(this.tipoPrecioReporte == TipoPrecioReporte.PreciosAprobados){
      this.getLotesAprobacion();
    }
  }

  fechaDesdeMayorQueHasta() {
    let esMayor: boolean = false;
    this.fechaDesde = new Date(this.modelDesde.year, this.modelDesde.month - 1, this.modelDesde.day);
    this.fechaHasta = new Date(this.modelHasta.year, this.modelHasta.month - 1, this.modelHasta.day);
    if (this.fechaDesde > this.fechaHasta) { esMayor = true; }
    return esMayor;
  }

  formatDate(year, month, day, hour, minute): string {
    return year + '/' + month.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + '/' + day.toLocaleString(undefined, { minimumIntegerDigits: 2 })
      + 'T' + hour.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + minute.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':00';
  }

  volver() {
    this.cancelado.emit(true);
  }

  descargarPreciosAprobados(i: number) {
    this.descargando = true;
    this.listadoPreciosAprobados[i].loadIcon = true;
    ///api/ExportarV1/ReportePreciosPDF?aprobacion=false
    const url = `${environment.apiServer.productosUrl}/ExportarV1/ReportePreciosPDF?aprobacion=true`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });
    let loteADescargar: string = "";
    loteADescargar = "\"" + this.listadoPreciosAprobados[i].id +  "\"";
    // Process the file downloaded
    this.http.put(url, loteADescargar, { headers: headers, responseType: 'blob' as 'json' })
      .subscribe((res: Blob) => {
        const blob = new Blob([res], { type: 'application/pdf;' });
        saveAs(blob, "reporte-precios-aprobados-" + this.listadoPreciosAprobados[i].fechaAprobacion + ".pdf")
        this.spinner.hide('spinnerGrafico');
        this.listadoPreciosAprobados[i].loadIcon = false;
        this.descargando = false;
      }, () => {
        console.log("No se encontraron lotes para exportar");
        this.spinner.hide('spinnerGrafico');
        this.descargando = false;
      });
  }

  descargarPreciosSubidos(i: number) {
    this.descargando = true;
    this.listadoPreciosSubidos[i].loadIcon = true;
    const url = `${environment.apiServer.productosUrl}/ExportarV1/ReportePreciosPDF?aprobacion=false`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });
    let loteADescargar: string = "";
    loteADescargar = "\"" + this.listadoPreciosSubidos[i].id +  "\"";
    // Process the file downloaded
    this.http.put(url, loteADescargar, { headers: headers, responseType: 'blob' as 'json' })
      .subscribe((res: Blob) => {
        const blob = new Blob([res], { type: 'application/pdf;' });
        saveAs(blob, "reporte-precios-importados-LoteN°" + this.listadoPreciosSubidos[i].numeroLote + ".pdf")
        this.spinner.hide('spinnerGrafico');
        this.listadoPreciosSubidos[i].loadIcon = false;
        this.descargando = false;
      }, () => {
        console.log("No se encontraron precios para exportar");
        this.spinner.hide('spinnerGrafico');
        this.listadoPreciosSubidos[i].loadIcon = false;
        this.descargando = false;
      });
  }

}
