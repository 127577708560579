<div class="modal-header">
  <h5 class="modal-title justify-content-center">Carga de precios.</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="this.modal.close()">
    
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="productoForm">
    <div class="row">
      <div class="col col-2"></div>
      <div class="col col-4">
        <label class="pt-2">Seleccionar archivo:</label>
      </div>
      <div *ngIf="fileUpload.status==='error'">
        <div [innerHTML]="fileUpload.message"></div>
      </div> 
      <div class="mb-3">
        <div class="custom-file">
          <input type="file" class="custom-file-input" name="profile" (change)="onSelectedFile($event)" placeholder="Archivo.xls"
            id="validatedCustomFile" formControlName="inputFile" required>
          <label class="custom-file-label" for="validatedCustomFile">{{ userFileName }}</label>
          <div class="invalid-feedback">Example invalid custom file feedback</div>
        </div>
      </div>
      <div class="col col-2"></div>
    </div> 
    <div *ngIf="fileUpload.status==='progress'">
      <div class="progress" style="height: 20px;margin-bottom:20px;">
        <div class="progress-bar" role="progressbar" [style.width.%]="fileUpload.message" aria-valuenow="25"
          aria-valuemin="0" aria-valuemax="100">{{fileUpload.message}}%</div>
      </div>
    </div>
    <div *ngIf="fileUpload.length>0" class="alert alert-warning" role="alert">
      <div *ngFor="let msg of fileUpload">
        <p>{{ msg.description }}</p>
      </div>
    </div>
    <div *ngIf="error" > 
      <p style="color: red; font-weight: bold;"> {{error}} </p>
    </div>  
  </form>
</div>
<div class="modal-footer justify-content-center">
  <button class="btn btn-primary me-2" id="aceptar" [disabled]="!productoForm.valid || guardandoExcel" (click)="cargarPrecios()">
    <span *ngIf="!guardandoExcel">Cargar</span>
    <div *ngIf="guardandoExcel" class="d-flex align-items-center">
      <div class="spinner-border text-light spinner-border-sm" role="status"></div>
      <span>Cargando...</span>
    </div>
  </button>
  <!-- <button type="submit" class="btn btn-primary btn-sm mt-2" (click)="cargarPrecios()">Cargar</button> -->
  <button type="button" class="btn btn-secondary btn-sm mt-2" (click)="this.modal.close()">Cancelar</button>
</div>
