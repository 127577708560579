<nav class="navbar navbar-light bg-light navbar-expand-lg">
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav">
            <li class="nav-item me-2">
                <input id="clienteFormControl" type="text" class="form-control" placeholder="Razón social"
                    [formControl]="busquedaFormControl" 
                    [readonly]="this.matriculaBuscada!='' "
                    (input)="filtrarClientes()" />
            </li>                       
            <li class="nav-item me-2">
                <button [disabled]="cargandoLista" *ngIf="this.matriculaBuscada=='' " class="btn btn-outline-primary"                    
                    (click)="buscarMatricula()">                    
                    Buscar matrícula
                    <i class="fa fa-search" aria-hidden="true"></i>
                </button>
                <button [disabled]="cargandoLista" *ngIf="this.matriculaBuscada!='' " class="btn btn-outline-danger"                    
                    (click)="cancelarBusqueda()">                    
                    Cancelar busqueda
                    <i class="fa fa-ban" aria-hidden="true"></i>
                </button>                
            </li>                      
        </ul>
    </div>
</nav>

<div class="row">
    <div class="col col-3">
        <div class="list-group py-3 menu-izq">
            <app-cliente-lista
                [clienteBuscado]="this.clienteBuscado"                
                [matriculaBuscada]="this.matriculaBuscada"                
                (eventClienteSeleccionado)="usuarioSeleccionoCliente($event)"
                (eventErrorBusqueda)="errorDeBusqueda($event)"
                (eventCargandoLista)="cargandoListaClientes($event)"
            ></app-cliente-lista>            
        </div>
    </div>

    <div class="col col-9 py-3">
        <div *ngIf="this.clienteSeleccionado!=null" id="tablaInformacion">
            <app-cliente-detalle 
                [cliente]="this.clienteSeleccionado"
            ></app-cliente-detalle>
        </div>
    </div>
</div>