import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { TipoPersona } from 'src/app/shared/enums/TipoPersona';
import { Cliente } from 'src/app/shared/models/cliente/Cliente';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ConfiguracionService } from 'src/app/despacho/configuracion.service';
import { Pais } from 'src/app/shared/models/despacho/Pais';
import { Ubicacion } from 'src/app/shared/models/cliente/Ubicacion';
import { Ciudad } from 'src/app/shared/models/cliente/Ciudad';
import { Provincia } from 'src/app/shared/models/cliente/Provincia';
import { MessageService } from 'src/app/shell/message.service';
import { CuentasService } from '../cuentas.service';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { UsuarioService } from 'src/app/account/usuario.service';
import { ClienteService } from '../cliente.service';
import { ClienteEncabezado } from 'src/app/shared/models/cliente/ClienteEncabezado';

@Component({
  selector: 'app-modal-cliente-extranjero',
  templateUrl: './modal-cliente-extranjero.component.html',
  styleUrls: ['./modal-cliente-extranjero.component.css']
})
export class ModalClienteExtranjeroComponent implements OnInit {
  
  @Input() clienteVerificar: ClienteEncabezado[];
  @Output() clienteObtenido: EventEmitter<Cliente> = new EventEmitter<Cliente>();
  @Output() cerrarInformacion: EventEmitter<boolean> = new EventEmitter<boolean>();

  clienteExtrajero = new UntypedFormGroup({
    //razonSocialFormControl: new FormControl(null, [Validators.required]),
    cuitFormControl: new UntypedFormControl(null, [Validators.required]),
    tipoPersonaFormControl: new UntypedFormControl(null, [Validators.required]),
    paisFormControl: new UntypedFormControl(null, [Validators.required])
  });

  modelPais: any;
  cargandoPaises: boolean = false;
  errorDestinos: boolean = false;
  consultando: boolean = false;
  invalidPais: boolean = false;
  tipoPersona = TipoPersona;
  paises: Pais[] = [];
  constructor(
    public modal: NgbActiveModal, 
    private cuentasService: CuentasService, 
    private clienteService: ClienteService,
    private usuarioService: UsuarioService,
    private messageService: MessageService) { }

  ngOnInit() {
    this.getPaises();
  }

  keysTipoPersona(): Array<string> {
    var keys = Object.keys(this.tipoPersona);
    keys = keys.slice(keys.length / 2);
    return keys;
  }

  estaCliente(): boolean {
    let esta: boolean = false;
    try{
      for (let cli of this.clienteVerificar) {
        if (this.cuitFormControl.value == cli.numeroDocumento) {
          esta = true;
        }
      }
      return esta;
    }catch(exc){
      return false;
    }    
  }
  
  buscarClienteExtranjero() {
    if (this.clienteExtrajero.valid) {
      if (this.estaCliente()) {
        this.messageService.showErrorMessage('El cliente ya se encuentra agregado.');
      } else {
        this.consultando = true;
        // solo comercial y abmClientes mandan FALSE en el flag "puedeConsultarAFIP"
        this.clienteService.validarClienteCenit(this.usuarioService.getAeroplantaActual().codigoAeroplanta, this.cuitFormControl.value, false, true)
          .subscribe(result => {
            let cli = new Cliente();
            cli.razonSocial = result.razonSocial;
            cli.tipoDocumento = 1;
            cli.numeroDocumento = this.cuitFormControl.value;
            cli.ctaSap = result.ctaSap;
            cli.ctA_CTC = result.ctA_CTC;
            cli.tipoPersona = this.tipoPersonaFormControl.value;
            cli.direccionFiscal = new Ubicacion();
            cli.direccionFiscal.ciudad = new Ciudad();
            cli.direccionFiscal.ciudad.provincia = new Provincia();
            cli.direccionFiscal.ciudad.provincia.pais = new Pais();
            cli.direccionFiscal.ciudad.provincia.pais = this.paisFormControl.value;
            this.clienteObtenido.emit(cli);
            this.modal.close();        
            this.consultando = false;
          }, (err:ErrorModel) => {                    
            this.messageService.showErrorMessage(err.description);
            this.modal.close();
            this.consultando = false;
            this.cerrarInformacion.emit(true);
          })  
        }
    }else{
      this.messageService.showErrorMessage('Por favor complete todos los datos antes de continuar.');
    }
      
  }

  getPaises() {
    this.cargandoPaises = true;
    this.cuentasService.getPaises()
      .subscribe(result => {
        this.paises = result;
        this.paises = this.paises.filter(p => p.codigo != "0303");
        this.cargandoPaises = false;
      }, () => this.errorDestinos = true)
  }

  formatterPais = (x: { nombre: string, codigo: string }) => `${x.nombre} - ${x.codigo}`;

  searchPais = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 || this.cargandoPaises ? [] :
        this.paises.filter(v => this.containsString(this.paisFormControl.value, v.nombre, v.codigo)).slice(0, 10))
    )

  /**
     * Determina si una cadena se encuentra dentro de otra
     * @param searchValue Valor a buscar
     * @param searchIn Valores sobre los que buscar searchValue
     */
  containsString(searchValue: string, ...searchIn: string[]): boolean {
    const concat = searchIn.toString();
    return concat.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
  }

  selectPais(e: any): void {
    this.paisFormControl.setValue(e.item.codigo);
    this.invalidPais=false;
  }

  changePais(): void {
    if (typeof (this.paisFormControl.value) === 'string') {
      this.invalidPais=true;
      this.paisFormControl.setValue(null);
    }
  }

  /*
  get razonSocial() {
    return this.clienteExtrajero.get('razonSocialFormControl');
  }
  */
  get cuitFormControl () {
    return this.clienteExtrajero.get('cuitFormControl');
  }

  get tipoPersonaFormControl () {
    return this.clienteExtrajero.get('tipoPersonaFormControl')
  }

  get paisFormControl () {
    return this.clienteExtrajero.get('paisFormControl');
  }
}
