<form [formGroup]="preautorizacionForm">

    <div class="modal-header">
        <h4 class="modal-title">Crear Preautorizaciones</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="this.activeModal.close()"></button>
    </div>
        
    <div class="modal-body">

        <div class="mb-3 row">
        <label for="matriculaFormControl" class="col-sm-4 col-form-label">Cliente:</label>
            <div class="col-sm-8">
                <input id="clienteFormControl" type="text" class="form-control" formControlName="clienteFormControl"
                    [inputFormatter]="formatterCliente" [(ngModel)]="modelCliente" [resultFormatter]="formatterCliente"
                    placeholder="Seleccione un cliente" (change)="changeCliente()"
                    [ngbTypeahead]="searchCliente" (selectItem)="selectCliente($event)" />
                <div *ngIf="clienteFormControl.invalid && clienteFormControl.errors.required && (clienteFormControl.dirty || clienteFormControl.touched)"
                    class="alert alert-danger">Requerido
                </div>
                <div class="alert alert-danger" *ngIf="clienteNoEncontrado">
                    Cliente no encontrado
                </div>  
                <loading-text-spinner *ngIf="cargandoClientes" [error]="errorClientes" message="Obteniendo clientes..."
                    color="primary">
                </loading-text-spinner>
            </div>
        </div>

        <div class="mb-3 row">
            <label for="vehiculoFormControl" class="col-sm-4 col-form-label">Vehiculo:</label>
            <div class="col-sm-8">
                <div class="input-group">
                    <ng-template #matri let-r="result" let-t="term">
                        <ngb-highlight [result]="r.matricula" [term]="t"></ngb-highlight>
                    </ng-template>
                    <input id="vehiculoFormControl" type="text" class="form-control" formControlName="vehiculoFormControl"
                        [resultTemplate]="matri" [inputFormatter]="formatterVehiculo" maxlength="10" placeholder="Seleccione una matrícula"
                        [ngbTypeahead]="searchVehiculo"
                        (change)="changeVehiculo()" />                    
                </div>
                <loading-text-spinner *ngIf="cargandoVehiculosDeCliente" message="Obteniendo vehiculos del cliente..." [error]="errorVehiculos || errorVehiculosDeCliente"
                    color="primary">
                </loading-text-spinner>
                <div class="alert alert-danger"
                    *ngIf="vehiculoFormControl.invalid && vehiculoFormControl.errors.required && (vehiculoFormControl.dirty || vehiculoFormControl.touched)">
                    Requerido
                </div>                
                <div class="alert alert-danger" *ngIf="vehiculoNoEncontrado">
                    Vehículo no encontrado
                </div>                
            </div>
        </div>

        <div class="mb-3 row">
            <label for="vehiculoFormControl" class="col-sm-4 col-form-label">Aeroplantas:</label>
            <div class="col-sm-8">
                <select class="form-select" id="aeroplantaFormControl" name="aeroplantaFormControl"
                    formControlName="aeroplantaFormControl">
                    <option [value]="null" defaultSelected>Seleccione una aeroplanta</option>
                    <option *ngFor="let aer of aeroplantas" [value]="aer.codigoAeroplanta">
                        {{aer.nombre}}
                    </option>
                </select>
                <div class="alert alert-danger"
                    *ngIf="aeroplantaFormControl.value=='null' || aeroplantaFormControl.invalid && aeroplantaFormControl.errors.required && (aeroplantaFormControl.dirty || aeroplantaFormControl.touched)">
                    Requerido
                </div> 
            </div>             
        </div>

         <!-- Fecha desde -->
         <div class="mb-3 row">
            <label for="fechaDesdeFormControl" class="col-sm-4 col-form-label">Fecha desde:</label>
            <div class="col-sm-6">
                <div class="input-group">
                    <input type="date" class="form-control" 
                        formControlName="fechaDesdeFormControl" placeholder="aaaa-mm-dd"
                        name="dp" #d="ngbDatepicker"
                        ngbDatepicker 
                        [(ngModel)]="modelFechaDesde" 
                        readonly/>
                        <div class="input-group-append">
                            <button type="button" class="input-group-btn btn btn-secondary" (click)="d.toggle()">
                                <i class="fa fa-calendar" aria-hidden="true"></i>
                            </button>
                        </div>
                </div>
                <ngb-timepicker 
                    [(ngModel)]="modelHoraDesde" 
                    (ngModelChange)="validarFecha(modelHoraDesde)"
                    [meridian]="true"
                    [readonlyInputs]="false"
                    formControlName="horaDesdeFormControl">
                </ngb-timepicker>
                <div *ngIf="(fechaDesdeFormControl.invalid && fechaDesdeFormControl.errors.fechaFutura && (fechaDesdeFormControl.dirty || fechaDesdeFormControl.touched))"
                    class="alert alert-danger">La fecha y hora deben ser posterior a la actual</div>
            </div>
        </div>

        <!-- Fecha hasta -->
        <div class="mb-3 row">
            <label for="fechaHastaFormControl" class="col-sm-4 col-form-label">Fecha hasta:</label>
            <div class="col-sm-6">
                <div class="input-group">
                    <input type="date" class="form-control" placeholder="aaaa-mm-dd"
                        formControlName="fechaHastaFormControl" 
                        name="dp" #h="ngbDatepicker"
                        ngbDatepicker 
                        [(ngModel)]="modelFechaHasta" 
                        readonly/>
                    <div class="input-group-append">
                        <button type="button" class="input-group-btn btn btn-secondary" (click)="h.toggle()">
                            <i class="fa fa-calendar" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
                <ngb-timepicker 
                    [(ngModel)]="modelHoraHasta" 
                    [meridian]="true"                     
                    [readonlyInputs]="false"
                    formControlName="horaHastaFormControl" >
                </ngb-timepicker>
                <div class="alert alert-danger" *ngIf="preautorizacionForm.invalid && this.preautorizacionForm.hasError('fechaDesdeMayorHasta')">
                    Esta cargando una fecha desde mayor a la fecha hasta.
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <span class="border">
            <button class="btn btn-primary" [disabled]="guardando || preautorizacionForm.invalid" (click)="crearPreautorizacion()">
            {{ guardando? "Guardando..." : "Guardar" }}
            </button>
        </span>
        <span class="border">
            <button type="button" class="btn btn-secondary" [disabled]="guardando" (click)="activeModal.dismiss('Close clicked')">Cancelar</button>
        </span>
    </div>       
</form>

