import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { ConfiguracionService } from 'src/app/despacho/configuracion.service';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';
import { CBU } from 'src/app/shared/models/cliente/CBU';
import { Cliente } from 'src/app/shared/models/cliente/Cliente';
import { ClienteService } from '../cliente.service';
import { MessageService } from 'src/app/shell/message.service';

@Component({
  selector: 'app-modal-cbu',
  templateUrl: './modal-cbu.component.html',
  styleUrls: ['./modal-cbu.component.scss']
})
export class ModalCBUComponent implements OnInit {

  @Input() clienteCBU: Cliente;
  @Output() clienteAMostrar: Cliente;
  @Output() todosCBU: EventEmitter<CBU[]> = new EventEmitter();

  guardando: boolean = false;
  checkedCBU: any;
  checkedEnUso: any;
  editField: string;
  agregarCBUNuevo: boolean = false;
  nuevosCBU: CBU[]=[];/*
  tablaCBU = new UntypedFormGroup({
    enUsoCheckFormControl: new UntypedFormControl('', Validators.required),
  });*/
  agregarCBUForm = new UntypedFormGroup({
    nuevoTipoBancoInterlocutorFormControl: new UntypedFormControl('', Validators.required),
    nuevoCBUFormControl: new UntypedFormControl('', Validators.required),
  });

  constructor(
    private formBuilder: UntypedFormBuilder,
    public activeModal: NgbModal,
    private configuracionService: ConfiguracionService,
    private clienteService: ClienteService,
    private authService:AuthService,
    private changeDetector: ChangeDetectorRef,
    private messageService: MessageService) {
  }

  ngOnInit() {
    if (this.clienteCBU.cbUs != null)
    {
      this.clienteCBU.cbUs.sort((a , b) => a.tipoBancoInterlocutor - b.tipoBancoInterlocutor);
      this.clienteCBU.cbUs.forEach((element) => {
          if(element.enUso === true)
            this.checkedCBU = element
      })
    }
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }


  esSupervisor()
  {
   return this.authService.hasRole(TipoUsuario.SUPERVISOR)
  }

  usuarioHabilitado()
  {
   return this.authService.hasRole(TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.APROBADORBYR, TipoUsuario.ADMCLIENTES);
  }

  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0 ) ? null : (event.charCode >= 48 && event.charCode <= 57);
  }

  /**
   * Determina si una cadena se encuentra dentro de otra
   * @param searchValue Valor a buscar
   * @param searchIn Valores sobre los que buscar searchValue
   */
  containsString(searchValue: string, ...searchIn: string[]): boolean {
    const concat = searchIn.toString();
    return concat.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
  }

  changeValue(id: number, event: any) {
    this.editField = event.target.textContent;
  }

  actualizar(id: number, event: any) {
      const editField = event.target.textContent.trim();   
      if (this.cbuRepetidos(editField) || editField == '') {
        this.messageService.showErrorMessage('No puede haber CBUs repetidos o vacíos.')
      }
      else{
        this.clienteCBU.cbUs[id].cbu = editField;
      }  
  }

  CBUsDuplicadosAlGuardar(arr) {
    return new Set(arr).size !== arr.length
  }

  guardarCBU() {
    let arr = []
    this.clienteCBU.cbUs.forEach((element) => arr.push(element.cbu))
    if (this.checkearEnUso()){
      if (this.CBUsDuplicadosAlGuardar(arr)) {
        this.messageService.showErrorMessage('No puede haber CBUs repetidos.')
      }else{
        Array.prototype.push.apply(this.clienteCBU.cbUs, this.nuevosCBU);
        this.messageService.showWarningMessage('Se modificó el CBU correctamente. \n Recuerde guardar los cambios del cliente.', false)
        this.todosCBU.emit(this.clienteCBU.cbUs);
        this.activeModal.dismissAll();
      } 
    }else{
      this.messageService.showErrorMessage('Debe haber al menos un CBU en uso.')
    }
  }

  cancelar(){
    let arr = []
    this.clienteCBU.cbUs.forEach((element) => arr.push(element.enUso === true ? 1 : 0))
    if (this.clienteCBU.cbUs.length > 0){
      if (arr.some(function(e){ return e === 1;})){
        this.clienteCBU.cbUs.forEach(element => element.cbu === this.checkedCBU.cbu ? element.enUso = true : element.enUso = false)
        this.activeModal.dismissAll();
      }
      else
          this.messageService.showErrorMessage('Debe asignar un cbu en uso en la tabla')
    } else{
      this.activeModal.dismissAll();
    }
    
  }

  usuarioModificaCampos(): boolean {
    return this.authService.hasRole(TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.APROBADORBYR, TipoUsuario.ADMCLIENTES);
  }

  agregarCBU(){
    this.agregarCBUNuevo = true;
  }

  asignarCBU(){
    if (!this.agregarCBUForm.valid) {
      this.messageService.showErrorMessage('Por favor revise lo datos necesarios para el CBU.')
    }else if (this.cbuRepetidos(this.nuevoCBU.value)) 
      {
        this.messageService.showErrorMessage('No puede haber CBUs repetidos.') 
      }else {
        let c = new CBU();
        c.tipoBancoInterlocutor = parseInt( this.nuevoTipoBancoInterlocutor.value);
        c.cbu = this.nuevoCBU.value;
        c.enUso = false;
        if (c.enUso) this.hayPorDefecto(c);
        this.nuevosCBU.push(c);
        this.nuevosCBU.sort((a , b) => a.tipoBancoInterlocutor - b.tipoBancoInterlocutor);
        this.nuevoCBU.setValue('');
        this.nuevoTipoBancoInterlocutor.setValue('');  
        //this.enUsoCheck.setValue(false);
      }
  }

  desasignarCBU(c: CBU) {
    const index: number = this.nuevosCBU.indexOf(c);
    if (index !== -1 && c.enUso === false) {
      this.nuevosCBU.splice(index, 1);
    } else if(c.enUso !== false){
      this.messageService.showErrorMessage('No se puede eliminar un cbu que esta en uso') 
    }
  }

  cbuRepetidos(cbu: string){
    if(this.clienteCBU && cbu){
      if(this.clienteCBU.cbUs.find(v => v.cbu == cbu))
      return true;
    }
    if(this.nuevosCBU != null && cbu){
      if(this.nuevosCBU.find(v => v.cbu == cbu))
      return true;
    }
    return false;
  }

  checkearEnUso(){
    if(this.clienteCBU){
      if(this.clienteCBU.cbUs.find(v => v.enUso == true))
      return true;
    }
    if(this.nuevosCBU != null){
      if(this.nuevosCBU.find(v => v.enUso == true))
      return true;
    }
    return false;
  }

  hayPorDefecto(c: CBU) {
    this.clienteCBU.cbUs.forEach((value, index) => {
      if (this.clienteCBU.cbUs[index] != c) {
        this.clienteCBU.cbUs[index].enUso = false;
      }
      if (this.nuevosCBU[index] != null && this.nuevosCBU[index] != c) {
        this.nuevosCBU[index].enUso = false;
      }
    })
  }

  checkearDefecto(c: CBU) {
    if (c.enUso != true) {
      c.enUso = true;
      this.hayPorDefecto(c);    
    } else{
      this.hayPorDefecto(c);
    }
  }

  //Getters
  /*get enUsoCheck() {
    return this.tablaCBU.controls.enUsoCheckFormControl;
  }
*/
  get nuevoCBU() {
    return this.agregarCBUForm.controls.nuevoCBUFormControl;
  }

  get nuevoTipoBancoInterlocutor() {
    return this.agregarCBUForm.controls.nuevoTipoBancoInterlocutorFormControl;
  }

  

}
