<ng-container *ngIf="!this.pasaPagina">
  <div class="card" id="RealizarCierre-card">
    <div class="card-header">
      <!--<div class="row">
        <div class="text-start col-10">
          <b class="text-start">Etapa 1: Realizar Cierre</b>
        </div>
        <div class="float-end col-2">
          <button class="d-flex btn btn-primary" type="button" [disabled]="this.obteniendoPrecierre"
            (click)="this.modalGenerarInforme()">Informe de aerovales</button>
        </div>
      </div>-->
      <app-cierre-nav-bar></app-cierre-nav-bar>
    </div>

    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="spinner-container" *ngIf="this.obteniendoPrecierre">
            <ngx-spinner name="spinnerAbastecedoras" bdOpacity=0.3bd
              Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
              <p style="color: white">{{messagePrecierre}}</p>
            </ngx-spinner>
          </div>
          <div *ngIf="!obteniendoPrecierre" class="row" style="float: right; margin-bottom: 1em; margin-right: 1em;">          
            <button class="d-flex btn btn-primary" type="button" [disabled]="this.obteniendoPrecierre"
              (click)="this.modalGenerarInforme()">Informe de aerovales</button>          
          </div>
          <table *ngIf="!obteniendoPrecierre" class="table table-striped table-hover"
            [formGroup]="tablaAbastecedoras">
            <thead class="text-center">
              <th>Expendedora</th>
              <!-- <th class="text-center">Aforador leido</th> -->
              <th>Estado</th>
              <th>Fecha/Hora precierre</th>
            </thead>
            <tbody class="text-center" *ngIf="encontroCierres()">
              <tr *ngFor="let exp of hayCierre?.precierres; let i = index">
                <td>{{exp.nombreExpendedora}}</td>
                <!-- <td class="text-center"> 1250</td> -->
                <td *ngIf="this.exp.estadoDeCierre == 1"> Pendiente <button class="btn btn-primary ms-2" (click)="precerrarAbastecedora(exp)">Precerrar abastecedora</button></td>
                <td *ngIf="this.exp.estadoDeCierre != 1"> Precerrada <button class="btn btn-primary ms-2" (click)="precerrarAbastecedora(exp)">Modificar Precierre</button></td>
                <td> {{exp.fechaHoraPrecierre | date: "d'/'MM'/'y' 'hh:mm a"}} </td>
              </tr>
            </tbody>
          </table>

          <div class="alert alert-danger text-center col-7" *ngIf="this.hayCierre?.estadoCierre == 1 && hayRemitoVencidos && mostrarTablaRemitosContado">Debe facturar los Remitos de Contado
            para Realizar el Cierre de Turno.</div>

          <loading-text-spinner *ngIf="consultandoSiHayRemitosSF" message="Consultando remitos..." color="primary"></loading-text-spinner>

          <div *ngIf="mostrarTablaRemitosContado && !this.obteniendoPrecierre" class="text-start col-10">
            <b class="text-start">Remitos contado sin facturar</b>
          </div>
          <table id="tablaRemitos" *ngIf="mostrarTablaRemitosContado && !this.obteniendoPrecierre" class="table table-striped table-hover letra" style="width:60%">
            <thead class="text-center">
              <tr>
                <th>Remito</th>
                <th>Fecha</th>
                <th>Cliente</th>
                <th>Aerovale</th>
                <th>Ver</th>
                <th>Fecha límite a facturar</th>
              </tr>
            </thead>
            <tbody class="text-center">
              <tr 
                *ngFor="let rem of remitosContadoSF; let i = index"                  
                [ngClass]="{'remarcar': rem.vencido}">
                <td style="width: 13%;">{{rem.remitoPrefijo | remitoPrefijo }}-{{rem.remitoNumero | remitoNumero }}</td>
                <td>{{rem.fechaHoraCreacion | date: "dd'/'MM'/'y' 'hh:mm a"}}</td>                
                <td>{{rem.clienteCtaSGC}} - {{rem.clienteNombre}}</td>
                <td style="width: 12%;">{{rem.aerovalePrefijo | aerovalePrefijo }}{{rem.aerovaleNumero | aerovaleNumero}}</td>                                              
                <td>
                  <button [disabled]="bloquearBotones" type="button" ngbTooltip="Ver Aerovales" (click)="obtenerAerovale(rem.aerovaleId, i)" class="btn">
                    <i class="fa fa-eye" aria-hidden="true"></i>
                  </button>
                  <div *ngIf="aerovaleSeleccionado==i">
                    <loading-text-spinner message="Obteniendo aerovale..." color="primary"></loading-text-spinner>
                  </div>
                </td>
                <td>{{rem.fechaLimite | date: "dd'/'MM'/'y' 'hh:mm a"}}</td>
              </tr>
            </tbody>
          </table>    
          <span *ngIf="hayRemitoVencidos && mostrarTablaRemitosContado" style="color: red; display: block; font-size: 10pt;">
            *Remitos de Contado sin facturar con mas de 48hrs.
          </span>
          <div class="modal-body" *ngIf="!encontroCierres() && !this.obteniendoPrecierre">
            <div class="alert alert-warning">No hay precierres para la aeroplanta seleccionada.</div>
          </div>
        </div>
      </div>
      <div class="float-end" *ngIf="this.hayCierre != null">
        <button class="d-flex btn btn-primary" (click)="realizarCierre()"
          [disabled]="this.obteniendoPrecierre || realizandoCierre || cargandoProductos || noContinua || (this.hayCierre?.estadoCierre == 1 && hayRemitoVencidos)"> 
          <span *ngIf="!realizandoCierre">{{this.hayCierre?.estadoCierre == 1 ? 'Realizar cierre de turno' : 'Continuar cierre de turno'}}</span>
          <div *ngIf="realizandoCierre" class="d-flex align-items-center">
            <div class="spinner-border text-light spinner-border-sm" role="status"></div>
            <span>{{this.hayCierre?.estadoCierre == 1 ? 'Realizando cierre...' : 'Continuando cierre...'}}</span>
          </div>
        </button>        
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="this.pasaPagina">
  <app-carga-datos-cierre-turno [cierreRealizoContinuado]="cierreRealizoContinuado"
    [aeroplantaCodigo]="codigoAeroplanta"></app-carga-datos-cierre-turno>
</ng-container>