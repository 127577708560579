<ul class="nav nav-tabs justify-content-start">

  <li *ngIf="hasRole(TipoUsuario.OPERADOR, TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.APROBADORBYR, TipoUsuario.SOPORTE)"
    class="nav-item"><a class="nav-link" class="nav-link" routerLink="tableros" routerLinkActive="active">Tableros</a>
  </li>

  <li *ngIf="hasRole(TipoUsuario.OPERADOR, TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.SOPORTE)"
    class="nav-item"><a class="nav-link" class="nav-link" routerLink="listados" routerLinkActive="active">Listados</a>
          </li>

  <li *ngIf="hasRole(TipoUsuario.OPERADOR, TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.SOPORTE)"
    class="nav-item"><a class="nav-link" class="nav-link" routerLink="configuracion" routerLinkActive="active">Configuración</a>
  </li>
</ul>

<router-outlet></router-outlet>
