import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { ComprobanteService } from 'src/app/facturacion/comprobante.service';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { Comprobante } from 'src/app/shared/models/facturacion/Comprobante';
import { Ejecucion } from 'src/app/shared/models/facturacion/Ejecucion';
import { EjecucionCliente } from 'src/app/shared/models/facturacion/EjecucionCliente';
import { EjecucionFacturacion } from 'src/app/shared/models/facturacion/EjecucionFacturacion';
import { Factura } from 'src/app/shared/models/facturacion/Factura';
import { Remito } from 'src/app/shared/models/facturacion/Remito';
import { PrevisualizacionComprobanteComponent } from 'src/app/shared/previsualizacion-comprobante/previsualizacion-comprobante.component';
import { VisorComprobanteComponent } from 'src/app/shared/visor-comprobante/visor-comprobante.component';
import { MessageService } from 'src/app/shell/message.service';

@Component({
  selector: 'app-detalle-ejecucion',
  templateUrl: './detalle-ejecucion.component.html',
  styleUrls: ['./detalle-ejecucion.component.css']
})
export class DetalleEjecucionComponent implements OnInit {
  @Output() cancelado = new EventEmitter();
  @Input() ejecucionSeleccionada: EjecucionFacturacion;
  @Input() tipoReporte: boolean;

  ejecucion: EjecucionFacturacion;
  ejecucionCliente: EjecucionCliente[] = [];
  ejecucionFinal: Ejecucion[];

  obteniendoComprobante: boolean = false;

  rechazo: boolean = false;
  numeroRechazo: number = 0;

  comprobante: Comprobante;
  obteniendo: boolean = false;

  mostrarTablaClientesSeleccionados = false;

  constructor(private comprobanteService: ComprobanteService, private spinner: NgxSpinnerService, private modal: NgbModal, private messageService: MessageService) { }

  ngOnInit() {
    this.obteniendo = true;
    this.spinner.show('spinnerLista')
    this.rechazo = false;
    this.numeroRechazo = 0;
    this.getEjecucionFacturacionPorId();
  }

  ngAfterViewInit() {
    this.rechazo = false;
    this.numeroRechazo = 0;
  }

  getEjecucionFacturacionPorId() {
    
    this.obteniendo = true;
    this.comprobanteService.getEjecucionesPorId(this.ejecucionSeleccionada.id, this.tipoReporte)
      .subscribe(result => {
        this.ejecucion = result;
        this.ejecucion.mensajeCortado = this.cortarCadena(this.ejecucion.mensaje);
        for (let i = 0; this.ejecucion.ejecuciones.length > i; i++) {
          this.ejecucion.ejecuciones[i].mensajeAgrupacionCliente2 = this.cortarCadena(this.ejecucion.ejecuciones[i].mensajeAgrupacionCliente);
          for (let p = 0; this.ejecucion.ejecuciones[i].ejecuciones.length > p; p++) {
            this.ejecucion.ejecuciones[i].ejecuciones[p].mensajeAgrupacion2 = this.cortarCadena(this.ejecucion.ejecuciones[i].ejecuciones[p].mensajeAgrupacion);
            this.ejecucion.ejecuciones[i].ejecuciones[p].mensajeError2 = this.cortarCadena(this.ejecucion.ejecuciones[i].ejecuciones[p].mensajeError);
          }
        }
        this.spinner.hide('spinnerLista');
        this.obteniendo = false;
      })
  }

  cortarCadena(cadena: string): string[] {
    if (cadena != null || cadena == '') {
      return cadena.split('*|*');
    } else {
      return ['-'];
    }
  }

  mostrarFacrtura(factura: Factura) {
    this.obteniendoComprobante = true;
    factura.loadIcon = true;
    this.comprobanteService.obtenerComprobantePorId(factura.id)
      .subscribe(result => {
        this.comprobante = result;
        this.obteniendoComprobante = false;
        if (this.comprobante != null) {
          let modalRef = this.modal.open(VisorComprobanteComponent, { centered: true, size: 'lg' });
          modalRef.componentInstance.facturaObtenida = this.comprobante;
          factura.loadIcon = false;
        }else{
          this.messageService.showErrorMessage('Error al obtener la factura');
          factura.loadIcon = false;
        }
      }, (err: ErrorModel) =>{
        this.messageService.showErrorMessage('Error al obtener la factura');
        factura.loadIcon = false;
      });
  }

  verificarNoEmitidas(ejecucion: Ejecucion[]): boolean{
    let hay: boolean = false;
    for(let eje of ejecucion){
      if(eje.factura != null){
        hay = true;        
      }
    }
    return hay;
  }

  verificarRechazadas(ejecucion: Ejecucion[]): boolean{
    let hay: boolean = false;
    for(let eje of ejecucion){
      if(eje.estadoFactura == 4){
        hay = true;        
      }
    }
    return hay;
  }

  goBack() {
    this.cancelado.emit(true);
  }

  CompletarPrefijo(prefijo: number) {
    let p = prefijo.toString()
    return p.padStart(5, '0')
  }

  CompletarNumero(numero: number) {
    let n = numero.toString()
    return n.padStart(8, '0')
  }


  obtenerTipoAgrupacion(tipo: number) {
    switch (tipo) {
      case 0:
        return 'Direccion de entrega';
      case 1:
        return 'Aeroplanta';
      case 2:
        return 'Provincia';
      default:
        return 'Sin agrupacion';
    }
  }

  facturaRechazadas(): boolean {
    if (this.numeroRechazo == 0) {
      this.numeroRechazo = 1;
    } else {
      this.rechazo = true;
    }
    return this.rechazo;
  }

  CompletarPrefijo2(prefijo: number) {
    let p = prefijo.toString()
    return p.padStart(3, '0')
  }

  CompletarNumero2(numero: number) {
    let n = numero.toString()
    return n.padStart(5, '0')
  }

  buscarMensajeError(msj: string, remito: Remito){
    let str =  this.CompletarPrefijo2(remito.prefijo) + this.CompletarNumero2(remito.numeroComprobante) + ':';
    if(msj.includes(str)){
      return msj;
    }
  }

  remitoAusenteEnErrores(errores: string[], remito: Remito){    
    let str =  this.CompletarPrefijo2(remito.prefijo) + this.CompletarNumero2(remito.numeroComprobante) + ':';

    for(let i=0 ; i < errores.length ; i++){
      if(errores[i].includes(str)){
        return false;
      }
    }
    return true;
  }

  toggleMostrarTablaCliente(){
    this.mostrarTablaClientesSeleccionados = ! this.mostrarTablaClientesSeleccionados;
  }
}
