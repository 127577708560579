<form [formGroup]="form" >

  <div class="card-header bg-light">
    <h4 class="modal-title">Notificaciones Comerciales: {{this.cliente.razonSocial}}</h4>

  </div>

  <div class="card-body" *ngIf="!this.obteniendoNotificaciones && this.notificacionesDelClienteSeleccionado!=null">

    <div class="row" formControlName="documentosFormControl" [formGroup]="checkMultiplesDocumentos">

      <div class="col-sm-2">
      <div class="form-check">
         <input (change)="this.seleccionarDocumentos(1, $event.target.checked)" type="checkbox" class="form-check-input" id="exampleCheck1"
        value="1" />
         <label class="form-check-label" for="exampleCheck1">Aerovales</label>
       </div>
       </div>
       <div class="col-sm-2">
       <div class="form-check">
         <input (change)="this.seleccionarDocumentos(2, $event.target.checked)" type="checkbox" class="form-check-input" id="exampleCheck2"
      value="2" />
         <label class="form-check-label" for="exampleCheck2">Facturas</label>
       </div>
      </div>
      <div class="col-sm-2">
       <div class="form-check">
         <input (change)="this.seleccionarDocumentos(3, $event.target.checked)" type="checkbox" class="form-check-input" id="exampleCheck3"
 value="3" />
         <label class="form-check-label" for="exampleCheck3">Resumenes</label>
       </div>
     </div>
     <div class="col-sm-2">
      <div class="form-check">
        <input type="checkbox" class="form-check-input" id="exampleCheck4"
   />
        <label class="form-check-label" for="exampleCheck4">Activo</label>
      </div>
    </div>
  </div>

     <table  class="table table-striped table-hover" *ngIf="this.notificacionesDelClienteSeleccionado!=null && this.notificacionesDelClienteSeleccionado.notificaciones!=null">
      <thead>
        <td class="text-center"><b>Email</b></td>
        <td class="text-center"><b>Tipo de Notificación</b></td>
        <td class="text-center"><b>Aeroplantas</b></td>

      </thead>
      <tbody>
        <tr *ngFor="let notificacion of this.notificacionesDelClienteSeleccionado.notificaciones let i=index" >
          <td class="text-center">{{notificacion.emails}} </td>
          <td class="text-center">{{this.procesarDocumentos(notificacion.documentos)}}</td>
          <td class="text-center">
            <button class="btn" (click)="this.mostrarAeroplantas(notificacion.aeroplantas)" placement="bottom" ngbTooltip="Ver Aeroplantas" container="body">
          <i class="fa fa-eye" aria-hidden="true"></i>
        </button>

      </td>


        </tr>
      </tbody>
    </table>



  </div>

  <ng-template #contentAeroplantas let-modal>
    <app-notificaciones-historial></app-notificaciones-historial>
   </ng-template>

   <ng-template #contentEditarNotificacion let-modal>
    <app-nueva-notificacion [notificacionSeleccionada]="this.notificacionesDelClienteSeleccionado"></app-nueva-notificacion>
   </ng-template>

  <div class="col-sm-8" *ngIf="this.obteniendoNotificaciones">
    <div class="spinner-container">
      <ngx-spinner name="spinnerDetalle" bdOpacity=0.3bd
        Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
        <p style="color: white">C a r g a n d o . . . </p>
      </ngx-spinner>
    </div>
  </div>






  <div class="modal-footer bg-light">
    <span class="border">
      <button (click)="this.mostrarNuevaNotificacion(contentEditarNotificacion)" class="btn btn-primary"  type="submit">
        Editar <!-- {{ Editar? "Editando..." : "Guardar cambios" }} -->
      </button>
    </span>
  </div>


</form>
