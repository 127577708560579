import { Responsable } from './Responsable';

export class AjusteManualCombustible{
    codigoContenedor: string;
    motivoId: string;
    observacion: string;
    cantidad: number;
    cantidad15: number;
    temperatura: number;
    densidad: number;
    warnings: string[];
    ResponsableDTO: Responsable ;
}