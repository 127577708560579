import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType, HttpEvent } from '@angular/common/http';
import { HandleError, HttpErrorHandler } from '../http-error-handler.service';
import { Observable } from 'rxjs';
import { Aerovale } from '../shared/models/despacho/Aerovale';
import { environment } from 'src/environments/environment';
import { Producto } from '../shared/models/despacho/Producto';
import { Contenedor } from '../shared/models/stock/Contenedor';
import { Emisor } from '../shared/models/facturacion/Emisor';
import { CondicionIVA } from '../shared/models/facturacion/CondicionIVA';
import { UrlApi } from '../shared/enums/urlApi';
import { Aeroplanta } from '../shared/models/despacho/Aeroplanta';
import { catchError } from 'rxjs/internal/operators/catchError';
import { tap } from 'rxjs/operators';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ManagementService {
  [x: string]: any;

  despachoBaseUrl = environment.apiServer.baseUrl;
  stockBaseUrl = environment.apiServer.stockBaseUrl;
  facturacionBaseURL = environment.apiServer.facturacionBaseUrl;
  private productosUrl = this.despachoBaseUrl + '/ProductosV1/';
  private aeroplantasUrl = this.despachoBaseUrl + '/AeroplantasV2/';
  private facturacionURL = this.facturacionBaseURL;

  private handleError: HandleError;

  constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError('management-aeroespecialidades');
  }

  getAllAeroespecialidades(codigoAeroplanta): Observable<Producto[]> {
    return this.http.get<Producto[]>(this.productosUrl + "TodasAeroespecialidades?codigoAeroplanta="+codigoAeroplanta)
      .pipe(
        tap(() => {
        },
          catchError(this.handleError('Obtener Aerovales', new Aerovale()))
        ));
  }

  setAeroespecialidades(listaAeroespecialidades : string[], aeroplantaCodigo : string) : Observable<string[]> {
    return this.http.put<string[]>(this.aeroplantasUrl + aeroplantaCodigo + "/Aeroespecialidades ", listaAeroespecialidades)
      .pipe(
        tap(_ => console.log('Se setearon aeroespecialidades')),
        catchError(this.handleError('Actualizacion aeroespecialidades activas', [] ))
    );
  }

  getContenedores(aeroplantaCodActual: string): Observable<Contenedor[]>{
    return this.http.get<Contenedor[]>(this.stockBaseUrl + '/AeroplantasV1/' + aeroplantaCodActual+"/Contenedores")
    .pipe(tap( () => {} , catchError(this.handleError('Obtener contenedores', new Aerovale())) ));
  }

  getAllProductos(): Observable<Producto[]>{
    return this.http.get<Producto[]>(this.productosUrl)
    .pipe(tap( () => {} , catchError(this.handleError('Obtener productos', new Aerovale())) ));
  }

  asignarProductoEnContenedor(contenedor: Contenedor) : Observable<string[]> {
    return this.http.put<string[]>(this.stockBaseUrl + "/ContenedoresV1/AsignarProducto/", contenedor)
      .pipe(
        tap(_ => console.log('Se asigno producto en contenedor'))
    );
  }

  asignarProductoEnAbastecedora(contenedor: Contenedor) : Observable<string[]> {
    return this.http.put<string[]>(this.despachoBaseUrl + "/ExpendedorasV1/" + contenedor.nombre + "/Producto/", contenedor)
      .pipe(
        tap(_ => console.log('Se asigno producto en abastecedora'))
    );
  }

  getEmisores(): Observable<Emisor[]>{
    return this.http.get<Emisor[]>(this.facturacionURL + '/EmisoresV1/');
  }

  getEmisorPorId(id: string): Observable<Emisor>{
    return this.http.get<Emisor>(this.facturacionURL + '/EmisoresV1/' + id)
  }

  actualizarEmisor(emisor: Emisor): Observable<Emisor>{
    return this.http.put<Emisor>(this.facturacionURL + '/EmisoresV1/' + emisor.id, emisor)
    .pipe(
      catchError(this.handleError('Actualizacion de emisor', new Emisor() ))
    );
  }

  crearEmisor(emisor: Emisor): Observable<Emisor>{
    return this.http.post<Emisor>(this.facturacionURL + '/EmisoresV1', emisor)
    .pipe(
      tap(_ => console.log('Se creo el emisor')),
      catchError(this.handleError('Creacion de Emisor', new Emisor ))
    );
  }

  getCondicionesIVA(): Observable<CondicionIVA[]>{
    return this.http.get<CondicionIVA[]>(this.facturacionURL + '/ConfiguracionesV1/CondicionesIva');
  }

  uploadCertificado(formData, importador: UrlApi, id: string) {
    let resultado = importador.toString().substring(1, importador.toString().length - 2);
    return this.http.put<any>(this.facturacionURL + '/EmisoresV1/'+ id +'/CargarCertificado', formData)
    .pipe(
      map(event => this.getEventMessage(event, formData)),
      catchError(this.handleError('al cargar ' + resultado))

    );
  }

  getapiUrl(resource: UrlApi, id:string) {
    switch (resource) {
      case UrlApi.emisores:
        return this.facturacionURL + '/EmisoresV1/'+ id +'/CargarCertificado';
    }
  }

  private getEventMessage(event: HttpEvent<any>, formData) {

    switch (event.type) {

      case HttpEventType.UploadProgress:
        return this.fileUploadProgress(event);

      case HttpEventType.Response:
        return this.apiResponse(event);

      // default:
      //   return `File "${formData.get('profile').name}" surprising upload event: ${event.type}.`;
    }
  }
  private fileUploadProgress(event) {
    const percentDone = Math.round(100 * event.loaded / event.total);
    return { status: 'progress', message: percentDone };
  }

  private apiResponse(event) {
    console.log('apiresponse:' + event.body);
    return event.body;
  }

  getTodasLasAeroplantas():Observable<Aeroplanta[]>{
    return this.http.get<Aeroplanta[]>(this.aeroplantasUrl);
  }

  putAeroplantas(aeroplanta: Aeroplanta): Observable<Aeroplanta>{
    return this.http.put<Aeroplanta>(this.aeroplantasUrl + aeroplanta.id, aeroplanta);
  }
}
