import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Equivalencia } from 'src/app/shared/models/stock/Equivalencia';
import { Tanque } from 'src/app/shared/models/stock/Tanque';
import { TanqueService } from '../../../tanques.service';

@Component({
  selector: 'app-visor-equivalencias',
  templateUrl: './visor-equivalencias.component.html',
  styleUrls: ['./visor-equivalencias.component.css']
})
export class VisorEquivalenciasComponent implements OnInit {
  @Input() tanque: Tanque;
  obteniendoMediciones: boolean;
  equivalencia: Equivalencia[];
  messageEquivalencias: string = 'C a r g a n d o . . .';
  constructor(public activeModal: NgbActiveModal,
    private tanqueService: TanqueService,
    private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.spinner.show('spinnerEquivalencia');
    this.getGraduaciones();
  }

  getGraduaciones() {
    this.obteniendoMediciones = true;
    this.tanqueService.getGraduaciones(this.tanque.codigo)
      .subscribe(result => {
        console.log(result.length);
        this.equivalencia = result.sort((a, b) => new Date(a.altura).getTime() - new Date(b.altura).getTime());
        this.spinner.hide('spinnerEquivalencia');
        this.obteniendoMediciones = false;
      })
  }

}
