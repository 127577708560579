
import { Cliente } from "./Cliente";
import { CuponPagoTarjeta } from "./CuponPagoTarjeta";

export class EmitirFactura
{
  facturaId: string;
  cupones: CuponPagoTarjeta [];
  observaciones: string;
  cliente: Cliente;

}
