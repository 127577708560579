<nav class="navbar navbar-light bg-light navbar-expand-lg mb-3" style="margin-bottom: 0 !important;">
  <form class="form-inline flex-fill" id="formularioFiltros">
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <ul class="navbar-nav me-auto">
      <div style="width:auto" class="input-group me-2">
        <li class="nav-item">
          <input type="date" [ngModel]="modelDesde" class="fechaFormControlDesdeHasta" placeholder="aaaa-mm-dd"
            (ngModelChange)="modelDesde = $event" name="dt" ngbDatepicker #d="ngbDatepicker" value="modelDesde"
            ngbTooltip="Seleccione una fecha de inicio" class="form-control form-control-rounded"
            #searchDate readonly>
        </li>
        <li class="nav-item me-2">
          <div style="width:auto" class="input-group-append">
            <button id="botonDesde" type="button" class="input-group-btn btn btn-secondary" (click)="d.toggle()">
              <i class="fa fa-calendar" aria-hidden="true"></i></button>
          </div>
        </li>
      </div>
      <div style="width:auto" class="input-group me-2">
        <li class="nav-item">
          <input type="date" [ngModel]="modelHasta" class="fechaFormControlDesdeHasta" placeholder="aaaa-mm-dd"
            (ngModelChange)="modelHasta = $event" name="dt" ngbDatepicker #h="ngbDatepicker" value="modelHasta"
            ngbTooltip="Seleccione una fecha de fin" class="form-control form-control-rounded"
            #searchDate readonly>
        </li>
        <li class="nav-item me-2">
          <div class="input-group-append">
            <button id="botonHasta" type="button" class="input-group-btn btn btn-secondary" (click)="h.toggle()">
              <i class="fa fa-calendar" aria-hidden="true"></i></button>
          </div>
        </li>
        <li class="nav-item">
          <button (click)="buscardatos()" [disabled]="fechaDesdeMayorQueHasta()==true || this.esperandoCarga"
            class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2" type="submit"><i class="fa fa-search"
              aria-hidden="true"></i></button>
        </li>
        <li class="nav-item">
          <button (click)="cambiarTabla(TipoPrecioReporte.PreciosSubidos)" [disabled]="fechaDesdeMayorQueHasta()==true || this.esperandoCarga"
            class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2" type="submit"><i
              aria-hidden="true"></i>Precios subidos</button>
        </li>

        <li class="nav-item">
          <button (click)="cambiarTabla(TipoPrecioReporte.PreciosAprobados)" [disabled]="fechaDesdeMayorQueHasta()==true || this.esperandoCarga"
            class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2" type="submit"><i
              aria-hidden="true"></i>Gestión de lotes</button>
        </li>
      </div>


      <div class="input-group" style=" width:auto;">
          <button (click)="cambiarTabla(TipoPrecioReporte.PreciosFacturados)" [disabled]="fechaDesdeMayorQueHasta()==true || this.esperandoCarga"
            class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2" type="submit"><i
              aria-hidden="true"></i>Precios utilizados</button>
      </div>
    </ul>
  </form>
</nav>

<div class="card-body">
  <form class="mb-3">
    <div class="spinner-container" *ngIf="esperandoCarga">
      <ngx-spinner name="spinnerLista" bdOpacity=0.3bd
        Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
        <p style="color: white">{{messageDescarga}}</p>
      </ngx-spinner>
    </div>

    <ng-container *ngIf="this.tipoPrecioReporte == TipoPrecioReporte.PreciosSubidos">
      <ngb-alert *ngIf="!esperandoCarga && this.listadoPreciosSubidos.length == 0">No hay lotes para busqueda seleccionada.
      </ngb-alert>
      <table id="tabla" class="table table-sm table-hover text-center"
        *ngIf="!esperandoCarga && this.listadoPreciosSubidos.length > 0 && this.listadoPreciosSubidos != null">
        <thead>
          <tr>
            <th>Lote</th>
            <th>Subido</th>
            <th>Fecha</th>
            <th>Descarga</th>
          </tr>
        </thead>
        <tbody [formGroup]="tablaPreciosSubidos">
          <tr *ngFor="let l of listadoPreciosSubidos; let i = index">
            <td>{{l.numeroLote}}</td>
            <td>{{l.responsable}}</td>
            <td>{{l.fechaPublicacion | date: 'dd/MM/yyyy hh:mm a'}}</td>
            <td *ngIf="!this.descargando" (click)="descargarPreciosSubidos(i)"><i class="fa fa-download"></i></td>
            <td *ngIf="this.descargando" [ngClass]="[l.loadIcon ? 'fa fa-spinner fa-spin' : 'fa fa-download']">
            </td>
          </tr>
        </tbody>
      </table>
    </ng-container>

    <ng-container *ngIf="this.tipoPrecioReporte == TipoPrecioReporte.PreciosAprobados">
      <ngb-alert *ngIf="!esperandoCarga && this.listadoPreciosAprobados.length == 0">No hay precios para busqueda seleccionada.
      </ngb-alert>
      <table id="tabla" class="table table-sm table-hover text-center"
        *ngIf="!esperandoCarga && this.listadoPreciosAprobados.length > 0 && this.listadoPreciosAprobados != null">
        <thead>
          <tr>
            <th>Num. lote</th>
            <th>Cantidad</th>
            <th>Usuario</th>
            <th>Fecha</th>
            <th>Descarga</th>
          </tr>
        </thead>
        <tbody [formGroup]="tablaPreciosAprobados">
          <tr *ngFor="let l of listadoPreciosAprobados; let i = index">
            <td>{{l.numeroLote}}</td>
            <td>{{l.cantidadRegistros}}</td>
            <td>{{l.usuarioAprobador}}</td>
            <td>{{l.fechaAprobacion | date: 'dd/MM/yyyy hh:mm a'}}</td>
            <td *ngIf="!this.descargando" (click)="descargarPreciosAprobados(i)"><i class="fa fa-download"></i></td>
            <td *ngIf="this.descargando" [ngClass]="[l.loadIcon ? 'fa fa-spinner fa-spin' : 'fa fa-download']">
            </td>
          </tr>
        </tbody>
      </table>
    </ng-container>


    <ng-container *ngIf="this.tipoPrecioReporte == TipoPrecioReporte.PreciosFacturados">
      <app-listado-precios-facturados 
        [fechaDesde]="this.fechaDesde"
        [fechaHasta]="this.fechaHasta"
        [listadoPrecios]="listadoPreciosFacturados" 
        [esperandoCarga]="esperandoCarga">
      </app-listado-precios-facturados>
    </ng-container>
  </form>
</div>

