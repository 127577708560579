export class Producto {
    //id: string;
    nombreProducto: string;
    codigoProducto: string;
    tipoProducto: number; //    Indeterminado = 0,    Aerocombustible = 1, Aeroespecialidad = 2,    Combustible = 3,
    unidadMedida: number;
    activo: boolean;
    esUtilizable: boolean;
    densidadMin:number;
    densidadMax:number;

    toString() {
        return this.codigoProducto + " | " + this.nombreProducto;
    }
}
