<nav class="navbar navbar-light bg-light navbar-expand-lg ">
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <!--Buscar cliente-->
    <div class="form-inline me-2">
      <input id="busquedaFormControl" [formControl]="buscadorFormControl" type="text" class="form-control" (input)="filtrarClientes()"
        placeholder="Razon social/cuenta/cuit" />
    </div>
    <ul class="navbar-nav me-auto">
      
      <!--Nuevo cliente-->
      <li class="nav-item float-end me-2">
        <button type="button" class="btn btn-outline-primary" [disabled]="!terminoCargaClientes || !usuarioPuedeCrearClienteExtranjero()" (click)="nuevoClienteExtranjero()">Nuevo Cliente Extranjero</button>
      </li>
      <!--Nuevo cliente-->
      <li class="nav-item float-end">
        <button type="button" class="btn btn-outline-primary" [disabled]="!terminoCargaClientes || !usuarioPuedeCrearClienteAfip()" (click)="nuevoClienteAfip()">Nuevo Cliente</button>
      </li>
    </ul>
  </div>
</nav>
<div class="row">
  <div class="col col-3">
    <div class="list-group py-3 menu-izq">
      <app-clientes-lista [clienteBuscado]="clienteBuscado" [clienteParaAgregar]="clienteParaAgregar" (clienteSeleccionado)="mostrarCliente($event)" (clienteVerificar)="enviarCliente($event)" (terminoCargaClientes)="termino()"></app-clientes-lista>
    </div>
  </div>
  <div class="col col-9 py-3" *ngIf="abrirCliente">
    <app-clientes-informacion [clienteAFIP]="clienteBuscadoAFIP" [clienteExtranjero]="clienteExtranjero" [clienteSeleccionadoParaEditar]="clienteABuscar" [todosLosClientesEncabezado]="clientesVerificar" (clienteCreado)="agregarCliente($event)" (terminoCargaClientes)="termino()"></app-clientes-informacion>
  </div>
</div>
