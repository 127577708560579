<div class="card">
    <div class="card-header">
      <label><b>{{titulo}}</b></label>
      <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close(false)"> </button>
    </div>
    <div class="card-body">
      
        <div class="mb-3 row">
            <label for="fechaInicioDespachoFormControl" class="col-sm-4 col-form-label">Fecha:</label>
            <div #fechaHora class="col-sm-6">
              <div class="input-group">
                <input type="date" class="form-control" [formControl]="fechaFormControl" placeholder="aaaa-mm-dd"
                  name="dp" title="Campo obligatorio." container="body" [(ngModel)]="modelFecha" ngbDatepicker #d="ngbDatepicker"
                  readonly/>
                <div class="input-group-append">
                  <button type="button" class="input-group-btn btn btn-secondary" (click)="toggleDatepicker(d)">
                    <i class="fa fa-calendar" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
              <ng-container *ngIf="this.mostrarHora">
                <ngb-timepicker [(ngModel)]="modelHora" [meridian]="true" [formControl]="horaFormControl"
                  [readonlyInputs]="true">
                </ngb-timepicker>
              </ng-container>
            </div>
        </div>

    </div>
    <div class="card-footer text-center">
      <button class="btn btn-primary me-2" (click)="this.onAceptar()">Aceptar</button>
      <button class="btn btn-secondary" (click)="onCancelar()">Cancelar</button>
    </div>
  </div>