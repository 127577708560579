export enum TipoUsuario {
  OPERADOR = 0, 
  SUPERVISOR = 1, 
  ADMINISTRADOR = 2,
  JEFEDEAEROPLANTA = 3, 
  LABORATORIO = 4, 
  TUTOR = 5, 
  COMERCIAL = 6, 
  DESPACHANTE = 7, 
  ADMCLIENTES = 8, 
  TRADERS = 9, 
  MANTENIMIENTO = 10, 
  MONITOREO = 11, 
  PRECIOS = 12, 
  APROBADORPRECIOS = 13,  // IDM-PAD-APROBADORDEPRECIOS
  APROBADORBYR = 14,  // IDM-PAD-APROBADORCOMERCIAL
  SEGURIDADTABLAS = 15,  // IDM-PAD-SEGURIDAD
  COMERCIAL_LN = 16,  // IDM-PAD-COMERCIAL-LN
  COMERCIAL_AV = 17,  // IDM-PAD-COMERCIAL-AV
  FACTURACION = 18, 
  IMPUESTOS = 19, 
  MANDATARIO = 20,
  OPERADOR_3 = 21 ,  // IDM-PAD-OPERADOR-3
  PRODUCTOS = 22,
  REFACTURACION = 23, 
  RESPRODUCTO = 24,  // IDM-PAD-RESPPRODUCTO
  CONSPRECIOS = 25, // IDM-PAD-CONSPRECIOS
  CONSAEROVALES = 26, // IDM-PAD-CONSAEROVALES
  CONSCIERRECAJA = 27, // IDM-PAD-CONSCIERRECAJA  
  FINANZAS = 28, // IDM-PAD-FINANZAS
  SOPORTE = 29, // IDM-PAD-SOPORTE
  CONTROLCREDITO = 30, //IDM-PAD-CONTROLCREDITO
  OPERADORDIRECTO = 31, //IDM-PAD-OPERADORDIRECTO
  APPSTORE = 32 //IDM-PAD-APPSTORE
}
