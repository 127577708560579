import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { UsuarioService } from 'src/app/account/usuario.service';
import { AerovaleDetalleComponent } from 'src/app/despacho/aerovale-detalle/aerovale-detalle.component';
import { AerovalesService } from 'src/app/despacho/aerovales.service';
import { CierreTurno } from 'src/app/shared/models/cierreTurno/CierreTurno';
import { ReimputarMovimiento } from 'src/app/shared/models/cierreTurno/ReimputarMovimiento';
import { ReporteMovimiento } from 'src/app/shared/models/cierreTurno/ReporteMovimiento';
import { AerovaleDetalle } from 'src/app/shared/models/despacho/AerovaleDetalle';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { Contenedor } from 'src/app/shared/models/stock/Contenedor';
import { SharedService } from 'src/app/shared/shared.service';
import { VisorComprobanteCargamentoComponent } from 'src/app/shared/visor-comprobante-cargamento/visor-comprobante-cargamento.component';
import { MessageService } from 'src/app/shell/message.service';
import { StockService } from 'src/app/stock/stock.service';
import { CierreTurnoService } from '../cierre-turno.service';
import { SetFechaReimputacionComponent } from './set-fecha-reimputacion/set-fecha-reimputacion.component';

@Component({
  selector: 'app-reimputador-documentos-cierre-turno',
  templateUrl: './reimputador-documentos-cierre-turno.component.html',
  styleUrls: ['./reimputador-documentos-cierre-turno.component.css']
})
export class ReimputadorDocumentosCierreTurnoComponent implements OnInit {

  cambiandoAeroplanta: boolean = false;
  obteniendoCierre: boolean = false;
  obteniendoCierre2: boolean = false;
  buscandoDetalle: boolean = false;
  buscandoDetalle2: boolean = false;
  terminoCarga: boolean = false;
  guardando: boolean = false;
  obteniendoAerovale: boolean = false;

  aeroplantaCodigo: string;

  change$: Subscription;

  cierresDeTurnoAbierto: CierreTurno;
  cierresDeTurnoCerrado: CierreTurno;
  movimientosCierreAbierto: ReporteMovimiento[] = [];
  movimientosCierreCerrado: ReporteMovimiento[] = [];
  movimientosCierreAbiertoFiltrado: ReporteMovimiento[] = [];
  movimientosCierreCerradoFiltrado: ReporteMovimiento[] = [];
  contenedores: Contenedor[] = [];
  movimientoSeleccionado: ReporteMovimiento;
  movimientoAReimputar: ReimputarMovimiento;

  fechaMaximaCerrado: string;
  fechaMinimaCerrado: string;
  fechaMinimaAbierto: string;
  fechaMaximaAbierto: string;

  fechaMaximaTurnoCerrado: any;
  fechaMinimaTurnoCerrado: any;
  fechaMaximaTurnoAbierto: any;
  fechaMinimaTurnoAbierto: any;

  cierreTurnoForm = new UntypedFormGroup({
    cierreTurnoFormControl: new UntypedFormControl(''),
    contenedorFormControl: new UntypedFormControl(''),
  });

  constructor(private messageService: MessageService,
    private cierreDeTurnoService: CierreTurnoService,
    private usuarioService: UsuarioService,
    private spinner: NgxSpinnerService,
    private stockService: StockService,
    private activeModal: NgbModal,
    private aerovaleService: AerovalesService,
    sharedService: SharedService) {
      this.change$ = sharedService.aeroplantaSeleccionada.subscribe(_=>{ 
        this.cambiarDeAeroplanta()
      });
  }

  ngOnInit() {
    this.terminoCarga = false;
    this.spinner.show('spinnerLista');
    this.contenedores = [];
    this.cierresDeTurnoAbierto = null;
    this.cierresDeTurnoCerrado = null;
    this.movimientosCierreAbierto = [];
    this.movimientosCierreAbiertoFiltrado = [];
    this.movimientosCierreCerrado = [];
    this.movimientosCierreCerradoFiltrado = [];
    this.aeroplantaCodigo = this.usuarioService.getAeroplantaActual().codigoAeroplanta;
    this.cierreTurnoFormControl.setValue(0);
    this.getCierresDeTurnoAbierto();
    this.getCierresDeTurnoCerrado();    
  }

  ngOnDestroy() {
    this.change$.unsubscribe();
  }
  
  cambiarDeAeroplanta() {
    this.ngOnInit();
  }

  getCierresDeTurnoAbierto() {
    this.obteniendoCierre = true;
    this.spinner.show('spinnerLista');
    this.cierreDeTurnoService.getCierreSegunEstado(this.aeroplantaCodigo, 1)
      .subscribe(result => {
        this.cierresDeTurnoAbierto = result;
        this.obteniendoCierre = false;
        this.getMovimientosAbierto();
      }, (err: ErrorModel) => {
        this.messageService.showErrorMessage('Hubo un error al obtener los cierres de turno.' + err);
        this.obteniendoCierre = false;
      });
  }

  getCierresDeTurnoCerrado() {
    this.obteniendoCierre2 = true;
    this.cierreDeTurnoService.getCierreSegunEstado(this.aeroplantaCodigo, 2)
      .subscribe(result => {
        this.cierresDeTurnoCerrado = result;
        this.getMovimientosCerrado();
        this.obteniendoCierre2 = false;
      }, (err: ErrorModel) => {
        this.messageService.showErrorMessage('Hubo un error al obtener los cierres de turno.' + err);
        this.obteniendoCierre2 = false;
      });
  }

  getMovimientosAbierto() {
    this.buscandoDetalle = true;
    if (this.cierresDeTurnoAbierto != null) {
      this.stockService.getMovimientos(this.cierresDeTurnoAbierto)
        .subscribe(result => {
          this.movimientosCierreAbierto = result;
          if (this.cierresDeTurnoAbierto != null) {
            this.getContenedores();
          }
          this.buscandoDetalle = false;
        }, (err: ErrorModel) => {
          this.messageService.showErrorMessage('Hubo un error al obtener los movimientos del cierre. ' + err.description);
          this.buscandoDetalle = false;
        });
    } else {
      this.buscandoDetalle = false;
      if (!this.buscandoDetalle && !this.buscandoDetalle2) {
        this.terminoCarga = true;
      }
    }
  }

  getMovimientosCerrado() {
    this.buscandoDetalle2 = true;
    if (this.cierresDeTurnoCerrado != null) {
      this.stockService.getMovimientos(this.cierresDeTurnoCerrado)
        .subscribe(result => {
          this.movimientosCierreCerrado = result;
          if (this.cierresDeTurnoCerrado != null) {
            this.getContenedores();
          }
          this.buscandoDetalle2 = false;
        }, (err: ErrorModel) => {
          this.messageService.showErrorMessage('Hubo un error al obtener los movimientos del cierre. ' + err.description);
          this.buscandoDetalle2 = false;
        });
    } else {
      this.buscandoDetalle2 = false;
      if (!this.buscandoDetalle && !this.buscandoDetalle2) {
        this.terminoCarga = true;
      }
    }
  }

  getContenedores() {
    if(this.contenedores.length>0) {
      this.movimientosCierreAbiertoFiltrado = this.movimientosCierreAbierto.filter(a => a.titulo != 'Saldo anterior' && a.codigoContenedor + a.nombreContenedor == this.contenedores[0].codigo + this.contenedores[0].nombre);
      this.movimientosCierreCerradoFiltrado = this.movimientosCierreCerrado.filter(a => a.titulo != 'Saldo anterior' && a.codigoContenedor + a.nombreContenedor == this.contenedores[0].codigo + this.contenedores[0].nombre);
    } else {
      this.terminoCarga = false;
      this.stockService.getTodosLosContenedores(this.aeroplantaCodigo)
        .subscribe(result => {
          this.contenedores = result;
          this.contenedorFormControl.setValue(this.contenedores[0]);
          this.movimientosCierreAbiertoFiltrado = this.movimientosCierreAbierto.filter(a => a.titulo != 'Saldo anterior' && a.codigoContenedor + a.nombreContenedor == this.contenedores[0].codigo + this.contenedores[0].nombre);
          this.movimientosCierreCerradoFiltrado = this.movimientosCierreCerrado.filter(a => a.titulo != 'Saldo anterior' && a.codigoContenedor + a.nombreContenedor == this.contenedores[0].codigo + this.contenedores[0].nombre);
          this.spinner.hide('spinnerLista');
          this.terminoCarga = true;
        }, (err: ErrorModel) => {
          this.messageService.showErrorMessage('Hubo un error al obtener los contenedores. ' + err.description);
          this.terminoCarga = true;
        });
    }    
  }

  filtrarPorContenedor() {
    for (let a of this.movimientosCierreAbiertoFiltrado) {
      a.booleanAux = false;
    }
    for (let b of this.movimientosCierreCerradoFiltrado) {
      b.booleanAux = false;
    }
    this.movimientoSeleccionado = null;
    this.movimientosCierreAbiertoFiltrado = this.movimientosCierreAbierto.filter(a => a.titulo != 'Saldo anterior' && a.codigoContenedor + a.nombreContenedor == this.contenedorFormControl.value.codigo + this.contenedorFormControl.value.nombre);
    this.movimientosCierreCerradoFiltrado = this.movimientosCierreCerrado.filter(a => a.titulo != 'Saldo anterior' && a.codigoContenedor + a.nombreContenedor == this.contenedorFormControl.value.codigo + this.contenedorFormControl.value.nombre);
  }

  obtenerFechaMaximaMinima() {
    this.fechaMinimaCerrado = this.cierresDeTurnoCerrado.fechaHora;
    this.fechaMaximaCerrado = this.cierresDeTurnoAbierto.fechaHora;
    this.fechaMinimaAbierto = this.cierresDeTurnoAbierto.fechaHora;

    this.fechaMinimaTurnoCerrado = new Date(this.cierresDeTurnoCerrado.fechaHora);
    this.fechaMaximaTurnoCerrado = new Date(this.cierresDeTurnoAbierto.fechaHora);
    this.fechaMinimaTurnoAbierto = new Date(this.cierresDeTurnoAbierto.fechaHora);
  }

  limpiarSeleccionados() {
    for (let a of this.movimientosCierreAbiertoFiltrado) {
      a.booleanAux = false;
    }
    for (let b of this.movimientosCierreCerradoFiltrado) {
      b.booleanAux = false;
    }
    this.movimientoSeleccionado = null;
  }

  agregarMovimiento(posicion: number, movimiento: ReporteMovimiento) {
    if (movimiento.booleanAux) {
      movimiento.booleanAux = false;
      this.movimientoSeleccionado = null
    } else {
      movimiento.booleanAux = true;
      this.movimientoSeleccionado = movimiento
    }
    for (let a of this.movimientosCierreAbiertoFiltrado) {
      if (a.id != movimiento.id) {
        a.booleanAux = false;
      }
    }
    for (let b of this.movimientosCierreCerradoFiltrado) {
      if (b.id != movimiento.id) {
        b.booleanAux = false;
      }
    }
  }

  reimputarMovimiento() {
    let fechaAModificar: Date;
    this.guardando = true;
    this.obtenerFechaMaximaMinima();
    this.movimientoAReimputar = new ReimputarMovimiento();
    //Si el cierre de turno esta abierto le asigno el id del turno cerrado, sino el id del turno abierto
    if (this.cierreTurnoFormControl.value == 0){
      this.movimientoAReimputar.cierreTurnoId = this.cierresDeTurnoCerrado.id;
    }else{
      this.movimientoAReimputar.cierreTurnoId = this.cierresDeTurnoAbierto.id;
    }
    this.movimientoAReimputar.movimientoId = this.movimientoSeleccionado.id;  
    if (this.cierreTurnoFormControl.value == 0) {
      fechaAModificar = new Date(this.fechaMaximaTurnoCerrado);
      fechaAModificar.setMinutes(fechaAModificar.getMinutes() - 1);
    }
    if (this.cierreTurnoFormControl.value == 1) {
      fechaAModificar = new Date(this.fechaMinimaTurnoAbierto);
      fechaAModificar.setMinutes(fechaAModificar.getMinutes() + 1);
    }
    this.movimientoAReimputar.fecha = this.formatDate(fechaAModificar.getFullYear(), fechaAModificar.getMonth() + 1, fechaAModificar.getDate(), fechaAModificar.getHours(), fechaAModificar.getMinutes());
    let modalRef = this.activeModal.open(SetFechaReimputacionComponent, { centered: true, size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.fechaHoraMaximaCerrado = this.fechaMaximaTurnoCerrado;
    modalRef.componentInstance.fechaHoraMinimaCerrado = this.fechaMinimaTurnoCerrado;
    modalRef.componentInstance.fechaHoraMinimaAbierto = this.fechaMinimaTurnoAbierto;
    modalRef.componentInstance.fechaSugerida = this.movimientoAReimputar.fecha;
    modalRef.componentInstance.tipoImputacion = parseInt(this.cierreTurnoFormControl.value);
    modalRef.result.then(result2 => {
      if (result2) {
        this.movimientoAReimputar.fecha = result2;
        this.stockService.reimputarDocumentoCierreDeTurno(this.movimientoAReimputar)
          .subscribe(result => {
            this.messageService.showSuccessMessage('El movimiento fue reimputado correctamente');
            this.ngOnInit();
            this.guardando = false;
          }, (err: ErrorModel) => {
            this.messageService.showErrorMessage('Hubo un error al reimputar el documento. ' + err.description);
            this.guardando = false;
          });
      } else {
        this.messageService.showErrorMessage('Se cancelo la reimputacion del documento.');
        this.guardando = false;
      }
    });
  }

  formatDate(year, month, day, hour, minute): string {
    return year + '-' + month.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + '-' + day.toLocaleString(undefined, { minimumIntegerDigits: 2 })
      + 'T' + hour.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + minute.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':00';
  }

  verRemitoSalida(mov: ReporteMovimiento, i: number) {
    let modalRef = this.activeModal.open(VisorComprobanteCargamentoComponent, { centered: true, size: 'lg' });
    modalRef.componentInstance.comprobanteEnviado = mov;
    modalRef.componentInstance.comp = i;
  }

  verAerovale(mov: ReporteMovimiento) {
    this.obteniendoAerovale = true;
    mov.abrirMovimiento = true;
    this.aerovaleService.getAerovalePorIdyTipo(mov.comprobante.remito.aerovaleId)
      .subscribe(result => {
        let aerovaleDetalle: AerovaleDetalle = result;
        this.obteniendoAerovale = false;
        mov.abrirMovimiento = false;
        let modalRef = this.activeModal.open(AerovaleDetalleComponent, { size: "lg", centered: true })
        modalRef.componentInstance.aerovaleDetalle = aerovaleDetalle;
      });
  }

  verRemitoCargamento(mov: ReporteMovimiento, i: number) {
    let modalRef = this.activeModal.open(VisorComprobanteCargamentoComponent, { centered: true, size: 'lg' });
    modalRef.componentInstance.comprobanteEnviado = mov;
    modalRef.componentInstance.comp = i;
  }

  get cierreTurnoFormControl() {
    return this.cierreTurnoForm.get('cierreTurnoFormControl');
  }

  get contenedorFormControl() {
    return this.cierreTurnoForm.get('contenedorFormControl');
  }
}