import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { UsuarioService } from 'src/app/account/usuario.service';
import { CierreTurnoService } from 'src/app/cierreTurno/cierre-turno.service';
import { TipoReporteMovimientos } from 'src/app/shared/enums/TipoReporteMovimientos';
import { ReporteMovimientoDetalleBalance7 } from 'src/app/shared/models/cierreTurno/ReporteMovimientoDetalleBalance7';
import { ReporteMovimientoPorTipoBalance7 } from 'src/app/shared/models/cierreTurno/ReporteMovimientoPorTipoBalance7';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { MessageService } from 'src/app/shell/message.service';
import { ReporteDetalleComponent } from '../reporte-detalle/reporte-detalle.component';
import { DatePipe, DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-reporte-agrupado-por-tipo',
  templateUrl: './reporte-agrupado-por-tipo.component.html',
  styleUrls: ['./reporte-agrupado-por-tipo.component.scss']
})

export class ReporteAgrupadoPorTipoComponent implements OnInit {

  @Input() listaIdsCierreTurno: string[];
  @Input() tipoReporte: number;

  @Input() codigoProducto: string;
  @Input() nombreProducto: string;
  @Input() codigoAeroplanta: string;
  @Input() fechaDesde: string;
  @Input() fechaHasta: string;
  @Input() incluirAjustes: boolean;
  @Input() tipoNatural: boolean;
  
  @Input() saldoInicial: number;
  @Input() saldoFinal: number;

  movimientosPorTipo: ReporteMovimientoPorTipoBalance7[];
  obteniendoDatos: boolean;
  totalEntradas: number;
  totalSalidas: number;

  constructor(private spinner: NgxSpinnerService,
    private cierreTurnoService: CierreTurnoService,
    private messageService: MessageService,  
    public activeModal: NgbActiveModal, 
    public usuarioService: UsuarioService,
    private formBuilder: UntypedFormBuilder,
    private modalService: NgbModal,
    private decimalPipe: DecimalPipe,
    private datePipe: DatePipe
    ) { 

    }

  ngOnInit(): void {
    this.obtenerMovimientosPorTipo(); 
  }

  obtenerMovimientosPorTipo(){

    if(this.tipoReporte == TipoReporteMovimientos.Fecha){
      this.obtenerMovimietosPorTipoFecha();
    }else{
      this.obtenerMovimietosPorTipoCierreTurno();
    }
  }

  obtenerMovimietosPorTipoCierreTurno() {

    this.mostrarLoading();

    this.cierreTurnoService.obtenerReporteMovimientoAgrupadoPorTipoCierreTurno(this.listaIdsCierreTurno, this.codigoProducto, this.codigoAeroplanta, this.tipoNatural, this.incluirAjustes)
      .subscribe(result => {
        console.log(result);
        this.movimientosPorTipo = result;
        this.calcularTotales(this.movimientosPorTipo);
        this.ocultarLoading();
      }, (err: ErrorModel) => {
        this.messageService.showErrorMessage('Hubo un error al obtener los datos. ' + err.description);
        this.movimientosPorTipo = null;
        this.ocultarLoading();
      });
  }

  obtenerMovimietosPorTipoFecha(){
    this.mostrarLoading();

    this.cierreTurnoService.obtenerReporteMovimientoAgrupadoPorTipoFecha(this.codigoProducto, this.codigoAeroplanta, this.tipoNatural, this.fechaDesde, this.fechaHasta, this.incluirAjustes)
      .subscribe(result => {
        console.log(result);
        this.movimientosPorTipo = result;
        this.calcularTotales(this.movimientosPorTipo);
        this.ocultarLoading();
      }, (err: ErrorModel) => {
        this.messageService.showErrorMessage('Hubo un error al obtener los datos. ' + err.description);
        this.movimientosPorTipo = null;
        this.ocultarLoading();
      });
  }

  calcularTotales(movimientos: ReporteMovimientoPorTipoBalance7[]){
    this.totalEntradas = 0;
    this.totalSalidas = 0;
    for(var i=0 ; i < movimientos.length; i++){
      this.totalEntradas += movimientos[i].entradas;
      this.totalSalidas += movimientos[i].salidas;
    }
  }

  // Reporte 3   
  obtenerMovimientosPorTipoDetalle(mt: ReporteMovimientoPorTipoBalance7) {

    const modalRef = this.modalService.open(ReporteDetalleComponent, { centered: true, size: "lg", windowClass: 'myCustomModalClass', backdrop: "static" });
    modalRef.componentInstance.listaIdsCierreTurno = this.listaIdsCierreTurno; 
    modalRef.componentInstance.tipoReporte = this.tipoReporte; 

    modalRef.componentInstance.nombreProducto = this.nombreProducto;
    modalRef.componentInstance.codigoProducto = this.codigoProducto;
    modalRef.componentInstance.codigoAeroplanta = this.codigoAeroplanta; 
    modalRef.componentInstance.fechaDesde = this.fechaDesde; 
    modalRef.componentInstance.fechaHasta = this.fechaHasta; 
    modalRef.componentInstance.incluirAjustes = this.incluirAjustes; 
    modalRef.componentInstance.tipoNatural = this.tipoNatural; 
    modalRef.componentInstance.tipoTransaccion = mt.tipoTransaccion; 
    modalRef.componentInstance.esAnulacion = mt.esAnulacion; 
    modalRef.componentInstance.concepto = this.obtenerDescripcionTipoTransaccion(mt.tipoTransaccion, mt.esAnulacion); 
    
    modalRef.result.then((result) => {

    }, () => {  });
  }

  mostrarLoading(){
    this.obteniendoDatos = true;
    this.spinner.show('spinnerGrafico');
  }

  ocultarLoading(){
    this.obteniendoDatos = false;
    this.spinner.hide('spinnerGrafico');
  }

  obtenerCodigoAeroplanta(){
    if(this.usuarioService.getAeroplantaActual().numeroAeroplanta != null){
      return  this.usuarioService.getAeroplantaActual().numeroAeroplanta;
    }else{
      return this.codigoAeroplanta;
    } 
  }

  obtenerDescripcionTipoTransaccion(tipoTransaccion: number, esAnulacion: boolean){
    return this.cierreTurnoService.obtenerDescripcionTipoTransaccion(tipoTransaccion,esAnulacion);
  }

  exportarMovimientos(){
    var dataArray = [
        ["Producto: " + this.codigoProducto + " - " + this.nombreProducto + "(" + (this.tipoNatural ? 'Natural' : '15º') + ")"],
        ["Fecha desde: " + this.datePipe.transform(this.fechaDesde, 'dd/MM/yyy HH:mm a') + " | Fecha hasta: " + this.datePipe.transform(this.fechaHasta, 'dd/MM/yyy HH:mm a')],
        ["Dependencia: " + this.obtenerCodigoAeroplanta() + " - " + this.usuarioService.getAeroplantaActual().nombre],
        [""],
        ["Concepto", "Saldo inicial", "Entradas", "Salidas", "Saldo final"],
        ["", this.decimalPipe.transform(this.saldoInicial, '1.0-0'), "", "", this.decimalPipe.transform(this.saldoFinal, '1.0-0')]
    ];

    for(var i=0 ; i < this.movimientosPorTipo.length; i++){
      var m = this.movimientosPorTipo[i];
      var concepto = this.cierreTurnoService.obtenerDescripcionTipoTransaccion(m.tipoTransaccion, m.esAnulacion);
      var nuevaFila = [concepto, "", this.decimalPipe.transform(m.entradas, '1.0-0'), this.decimalPipe.transform(m.salidas, '1.0-0'), ""];

      dataArray.push(nuevaFila);
    }

    var filaFooter = ["", "TOTALES", this.decimalPipe.transform(this.totalEntradas, '1.0-0'), this.decimalPipe.transform(this.totalSalidas, '1.0-0'), ""];
    dataArray.push(filaFooter);

    var filename = this.obtenerCodigoAeroplanta() + " - " + this.codigoProducto + " - " + this.nombreProducto + "(" + (this.tipoNatural ? 'Natural' : '15º') + ")";
    this.cierreTurnoService.exportAsExcelFile(dataArray, filename);
  }

}
