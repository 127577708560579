import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HandleError, HttpErrorHandler } from 'src/app/http-error-handler.service';
import { Observable } from 'rxjs';
import { HttpClient, HttpEvent, HttpEventType,HttpHeaders } from '@angular/common/http';
import { tap, catchError, map } from 'rxjs/operators';
import { Producto } from 'src/app/shared/models/abm/Producto';
import { parametroImpositivo } from 'src/app/shared/models/abm/ParametroImpositivo';
import { Area } from 'src/app/shared/models/abm/Area';
import { Segmento } from 'src/app/shared/models/abm/Segmento';
import { Precio } from 'src/app/shared/models/abm/Precio';
import { UrlApi } from 'src/app/shared/enums/urlApi';
import { Rubro } from 'src/app/shared/models/abm/Rubro';
import { FiltroPrecio } from 'src/app/shared/models/abm/FiltroPrecio';
import { Moneda } from 'src/app/shared/models/abm/Moneda';
import { Cliente } from 'src/app/shared/models/abm/Cliente';
import { Ubicacion } from 'src/app/shared/models/abm/Ubicacion';
import { PrecioEntrada } from 'src/app/shared/models/abm/PrecioEntrada';
import { Lote } from 'src/app/shared/models/precios/Lote';
import { PrecioAprobacion } from 'src/app/shared/models/precios/PrecioAprobacion';
import { ConsultaPrecioFacturaDTO } from 'src/app/shared/models/precios/ConsultaPrecioFacturaDTO';
import { PagedPrecios } from 'src/app/shared/models/precios/PagedPrecios';
import { MultiFiltroPrecioByrDto } from 'src/app/shared/models/precios/MultiFiltroPrecioByrDto';


@Injectable({
  providedIn: 'root'
})
export class ProductoService {

  baseUrl = environment.apiServer.productosUrl;
  facturacionUrl= environment.apiServer.facturacionBaseUrl;
  private handleError: HandleError;
  private rubrosUrl = this.baseUrl + '/RubrosV1';

  constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError('Productos');
  }

  obtenerProductos(): Observable<Producto[]> {
    return this.http.get<Producto[]>(this.baseUrl + '/ProductosV1/')
    .pipe(
      catchError(this.handleError('al obtener productos', null))
    );
  }
  
  obtenerProductosPromise(): Promise<Producto[]> {
    return this.http.get<Producto[]>(this.baseUrl + '/ProductosV1/').toPromise();
  }

  obtenerProducto(id: string): Observable<Producto> {
    return this.http.get<Producto>(this.baseUrl + '/ProductosV1/' + id)
      .pipe(
        catchError(this.handleError('al obtener producto', null))
      );
  }

  agregarProducto(producto: Producto): Observable<Producto[]> {
    return this.http.post<Producto[]>(this.baseUrl + '/ProductosV1/', producto)
      .pipe(
        tap(_ => console.log(` producto id=${producto.codigoProducto}`)),
      );
  }

  modificarProducto(producto: Producto): Observable<string> {
    return this.http.put<Producto[]>(this.baseUrl + '/ProductosV1/', producto)
      .pipe(
        tap(_ => console.log(` producto id=${producto.codigoProducto}`)),
        catchError(this.handleError('al modificar producto', null))
      );
  }

  agregarImpuesto(impuesto: parametroImpositivo): Observable<parametroImpositivo[]> {
    return this.http.post<parametroImpositivo[]>(this.baseUrl + '/ImpuestosV1/', impuesto)
      .pipe(
        tap(_ => console.log(` impuesto id=${impuesto.id}`)),
        catchError(this.handleError('al crear impuesto', null))
      );
  }

  modificarImpuesto(impuesto: parametroImpositivo): Observable<parametroImpositivo> {
    return this.http.put<parametroImpositivo>(this.baseUrl + '/ImpuestosV1/', impuesto)
      .pipe(
        tap(_ => console.log(` impuesto id=${impuesto.id}`)),
        catchError(this.handleError('al modificar impuesto', null))
      );
  }

  exportarProductos()
  {
    const url = `${this.baseUrl}/Exportar`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });
    // Process the file downloaded
    return this.http.get(url, { headers: headers, responseType: 'blob' as 'json' })
      .pipe(
        tap(_ => console.log(`Descargando excel`))
      );

  }

  getRubros(): Observable<Rubro[]> {
    return this.http.get<Rubro[]>(this.rubrosUrl)
  }

  getRubroPorId(id: string): Observable<Rubro> {
    return this.http.get<Rubro>(this.rubrosUrl + '/' + id);
  }

  getAreas(): Observable<Area[]> {
    return this.http.get<Area[]>(this.baseUrl + '/preciosV2/Area')
  }

  getSegmentos(): Observable<Segmento[]> {
    return this.http.get<Segmento[]>(this.baseUrl + '/preciosV2/Segmento')
  }

  getPreciosViejos(): Observable<PrecioEntrada[]> {
    return this.http.get<PrecioEntrada[]>(this.baseUrl + '/PreciosV1')
  }

  getPrecios(): Observable<Precio[]> {
    return this.http.get<Precio[]>(this.baseUrl + '/preciosV2')
  }

  getPreciosPaginados(page : number, pageSize : number, niveles: number[], estados: number[], vigencias: number[], fechaVigencia: string): Observable<PagedPrecios>{
    var filtro = new MultiFiltroPrecioByrDto();
    filtro.niveles = niveles;
    filtro.estados = estados;
    filtro.vigencias = vigencias;
    filtro.fechaVigencia = fechaVigencia;
    
    return this.http.put<PagedPrecios>(this.baseUrl + '/preciosV3/CargarPreciosFiltrados?page='+page+'&pageSize='+pageSize, filtro);
  }

  getPreciosPorFecha(fecha: string): Observable<Precio[]> {
    return this.http.get<Precio[]>(this.baseUrl + '/preciosV2/Aplicables' + '?fechaHora=' + fecha );
  }

  getapiUrl(resource: UrlApi) {
    switch (resource) {
      case UrlApi.precios:
        return this.baseUrl + '/preciosV1/ExcelArchivo';
    }
  }

  upload(formData, importador: UrlApi) {
    return this.http.post<any>(this.getapiUrl(importador), formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map(event => this.getEventMessage(event, formData)),
    );
  }

  private getEventMessage(event: HttpEvent<any>, formData) {

    switch (event.type) {

      case HttpEventType.UploadProgress:
        return this.fileUploadProgress(event);

      case HttpEventType.Response:
        return this.apiResponse(event);

     }
  }

  private fileUploadProgress(event) {
    const percentDone = Math.round(100 * event.loaded / event.total);
    return { status: 'progress', message: percentDone };
  }

  private apiResponse(event) {
    console.log('apiresponse:' + event.body);
    return event.body;
  }

  actualizarPreciosViejo(precioId: string[]): Observable<string[]> {
    return this.http.put<string[]>(this.baseUrl + '/PreciosV1/' + precioId + '/Actualizar', null)
    .pipe(
      tap(_ => console.log("Se activaron/desactivaron precios"),
      catchError(this.handleError("Actualizacion precios", [])))
    );
  }

  actualizarPrecios(precio: Precio[]): Observable<string> {
    return this.http.put<string>(this.baseUrl + '/preciosV2' + '/Actualizar', precio)
    .pipe(
      tap(_ => console.log("Se activaron/desactivaron precios"),
      catchError(this.handleError("Actualizacion precios", [])))
    );
  }

  consultarPrecioBase(filtro: FiltroPrecio): Observable<Precio[]>{
    return this.http.post<Precio[]>(this.baseUrl + '/preciosV2/FiltroBase', filtro)
    .pipe(
      tap(_ => console.log("Se obtuvieron los precios"),
      catchError(this.handleError("Obtencion de filtro precios", [])))
    );
  }

  obtenerMonedas(): Observable<Moneda[]>{
    return this.http.get<Moneda[]>(this.baseUrl + '/preciosV2/Moneda')
    .pipe(
      tap(_ => console.log("Se obtuvieron las monedas"),
      catchError(this.handleError("Obtencion de monedas", [])))
    );
  }

  getClientes(): Observable<Cliente[]>{
    return this.http.get<Cliente[]>(this.baseUrl + '/preciosV2/Cliente')
    .pipe(
      tap(_ => console.log("Se obtuvieron los clientes"),
      catchError(this.handleError("Obtencion de clientes", [])))
    );
  }

  getUbicaciones(): Observable<Ubicacion[]>{
    return this.http.get<Ubicacion[]>(this.baseUrl + '/preciosV2/Ubicacion')
    .pipe(
      tap(_ => console.log("Se obtuvieron las ubicaciones"),
      catchError(this.handleError("Obtencion de ubicaciones", [])))
    );
  }

  getLotesPorFecha(inicio: string, fin: string): Observable<Lote[]>{
    return this.http.get<Lote[]>(this.baseUrl + '/PreciosV2/Lotes/Fechas?fechaDesde='+ inicio +'&fechaHasta=' + fin);
  }

  getLotesAprobacion(inicio: string, fin: string): Observable<PrecioAprobacion[]>{
    return this.http.get<PrecioAprobacion[]>(this.baseUrl + '/PreciosV2/PreciosAprobaciones?fechaDesde='+ inicio +'&fechaHasta=' + fin);
  }

  getPreciosFacturados(inicio: string, fin: string): Observable<ConsultaPrecioFacturaDTO[]>{
    return this.http.get<ConsultaPrecioFacturaDTO[]>(this.facturacionUrl + '/FacturacionV1/PreciosUtilizados?fechaDesde='+ inicio +'&fechaHasta=' + fin);
  }

  exportarListadoPrecios(listaIds) {
    const url = this.baseUrl + '/PreciosV2/ExportarAuditoria';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });
    return this.http.post(url, listaIds, { headers: headers, responseType: 'blob' as 'json' })
  }

  descargarExcelActualizacionPrecios(listaIds) {
    const url = this.baseUrl + '/PreciosV3/ExcelArchivo/Descargar';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });
    return this.http.post(url, listaIds, { headers: headers, responseType: 'blob' as 'json' })
  }

  getCotizacionDolar(): Observable<number>{
    return this.http.get<number>(this.baseUrl + '/CotizacionesV1/Actual');
  }

}
