import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Cliente } from 'src/app/shared/models/facturacion/Cliente';
import { NotificacionesService } from '../../notificaciones.service';
import { NuevaNotificacionComponent } from '../nueva-notificacion/nueva-notificacion.component';
import { NotificacionesHistorialComponent } from '../notificaciones-historial/notificaciones-historial.component';



@Component({
  selector: 'app-notificaciones-lista',
  templateUrl: './notificaciones-lista.component.html',
  styleUrls: ['./notificaciones-lista.component.css']
})
export class NotificacionesListaComponent implements OnInit {
  [x: string]: any;

  //se utilizan para el check de activos/inactivos
  clientes: Cliente[];
  clientesFiltrados: Cliente[];
  clienteSeleccionado: Cliente;
  busquedaFormControl = new UntypedFormControl();

  cargandoClientes: boolean = true;

  constructor(private notificacionService: NotificacionesService,
    private modalService: NgbModal, private spinner: NgxSpinnerService) { }


  // hooks

  ngOnInit() {
    this.getClientes();
    setTimeout(() => {
      this.spinner.show('spinnerLista');
    });

  }

  ngOnChanges(): void {
    this.getClientes();
    setTimeout(() => {
      this.spinner.show('spinnerLista');
    });

  }

  //llamadas al service

  getClientes() {
    this.cargandoClientes = true;
    this.notificacionService.obtenerClientes()
      .subscribe(result => {
        this.clientes = result;
        this.clientesFiltrados = this.clientes;
        this.clienteSeleccionado = this.clientes[0]; //incializo con la primera fila selecccionada
        this.cargandoClientes = false;
        this.spinner.hide();

      }, () => {
        this.cargandoClientes = false;
        this.spinner.hide();
      });
  }

  // form

  filtrarClientes(texto: string) {
    this.clientesFiltrados = this.clientes.filter(a => {
      let agregar = true;
      if (texto) {
        agregar = a.razonSocial.toLowerCase().indexOf(texto.toLowerCase()) > -1;
        if (a.sgc != null) {
          agregar = agregar || a.sgc.toLowerCase().indexOf(texto.toLowerCase()) > -1;
        }
      }
      return agregar;
    });
  }



  mostrarDetalle(cliente: Cliente) {
    this.spinner.show('spinnerDetalle');
    this.clienteSeleccionado = cliente;
    this.spinner.hide('spinnerDetalle');
  }

  mostrarNuevaNotificacion(content) {
    this.modalService.open(NuevaNotificacionComponent, { centered: true, size: 'lg' });
  }

  mostrarHistorialNotificaciones(content) {
   let modalRef= this.modalService.open(content, { centered: true, size: 'lg' });
  }

  // getters

  get busqueda() {
    return this.busquedaFormControl;
  }

}
