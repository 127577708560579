import { Component, OnInit } from '@angular/core';
import { NotificacionSistemaEncabezadoDTO } from 'src/app/shared/models/notificaciones/NotificacionSistemaEncabezadoDTO';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormControl } from '@angular/forms';
import { NuevaNotificacionSistemaComponent } from './nueva-notificacion-sistema/nueva-notificacion-sistema.component';
import { NotificacionesHistorialSistemaComponent } from './notificaciones-historial-sistema/notificaciones-historial-sistema.component';
import { NotificacionSistemaDTO } from 'src/app/shared/models/notificaciones/NotificacionSistemaDTO';

@Component({
  selector: 'app-notificaciones-sistema',
  templateUrl: './notificaciones-sistema.component.html',
  styleUrls: ['./notificaciones-sistema.component.css']
})
export class NotificacionesSistemaComponent implements OnInit {

  notificacionSistemaSeleccionada : NotificacionSistemaEncabezadoDTO;
  notificacionBuscada:string;
  buscadorFormControl = new UntypedFormControl();

  constructor(private modalService: NgbModal) { }

  ngOnInit() {
  }

  usuarioSeleccionoNotificacio(notificacionSistemaEncabezado: NotificacionSistemaEncabezadoDTO) {
    this.notificacionSistemaSeleccionada = notificacionSistemaEncabezado;
  }
  
  mostrarNuevaNotificacionSistema(notificacionDuplicar?:NotificacionSistemaDTO) {
    let modalRef = this.modalService.open(NuevaNotificacionSistemaComponent, { centered: true, size: 'lg' });
    modalRef.componentInstance.notificacionDuplicar = notificacionDuplicar;
  }

  mostrarHistorialNotificaciones() {
    const modalRef = this.modalService.open(NotificacionesHistorialSistemaComponent, { centered: true, size: 'lg', windowClass: 'myCustomModalClass', backdrop: "static" });
    modalRef.componentInstance.notificacionSistemaId = this.notificacionSistemaSeleccionada.id; 
    modalRef.componentInstance.notificacionSistemaTitulo = this.notificacionSistemaSeleccionada.titulo; 
    modalRef.result.then((result) => {

    }, () => {  });
  }

  filtrarNotificaciones() {
    this.notificacionBuscada = this.buscador.value;
  }

  get buscador() {
    return this.buscadorFormControl;
  }


}
