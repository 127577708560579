import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { UrlApi } from 'src/app/shared/enums/urlApi';
import { ProductoService } from '../../producto/producto.service';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';

@Component({
  selector: 'app-modal-exportacion',
  templateUrl: './modal-exportacion.component.html',
  styleUrls: ['./modal-exportacion.component.css']
})
export class ModalExportacionComponent implements OnInit {
 
  constructor(public modal: NgbActiveModal, private fb: UntypedFormBuilder,
    private productoService: ProductoService) { }

  fileUpload = { status: '', message: '', filePath: '', length: 0 };
  userFileName: string;
  productoForm: UntypedFormGroup;
  selectedFile: boolean = false;
  error: string;
  guardandoExcel: boolean = false;
 
  ngOnInit() {
    this.productoForm = this.fb.group({
      name: [''],
      profile: [''],
      inputFile: ['', Validators.required]
    });
  }

  onSelectedFile(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.productoForm.get('profile').setValue(file);
      this.productoForm.get('name').setValue(file.name);
      this.userFileName = file.name;
      this.selectedFile = true;
    }
  }

  cargarPrecios() {
    const formData = new FormData();
    formData.append('name', this.productoForm.get('name').value);
    formData.append('profile', this.productoForm.get('profile').value);
    this.guardandoExcel = true;
    this.productoService.upload(formData, UrlApi.precios)
      .subscribe(result => {
        if (Array.isArray(result)) {
          this.guardandoExcel = false;
        }
        if (result) this.fileUpload = result;
      },
        (err: ErrorModel) => { this.error = err.description; this.guardandoExcel = false; });
  }
}
