<div class="p-2 modal-header bg-light">
    <h4 class="modal-title">Monitor Configuración:</h4>
</div>

<div class="p-4 modal-body">
    <form [formGroup]="configuracionForm">       
        <div style="margin-bottom: 1rem;" class="row col-md-12">
            <div style="padding-left: 1rem;" class="col-md-5 py-3 border">
                <div class="mb-3 row">                
                    <div class="col-sm-2">
                        <label for="minutosFormControl" class="col-form-label">Minutos:</label>
                    </div>
                    <div class="col-sm-2">
                        <input id="minutosFormControl" 
                            step="10" type="number" required [min]="10"
                            class="form-control" formControlName="minutosFormControl"/>                      
                        <div *ngIf="minutosFormControl.invalid && minutosFormControl.errors.required && (minutosFormControl.dirty || minutosFormControl.touched)"
                            class="alert alert-danger">Requerido
                        </div>                    
                    </div>                                       
                </div>        
                
                <br>
    
                <!--COLOR-->
                <div class="mb-3 row">
                    <div class="col-md-2">
                        <label for="colorFormControl" class="col-form-label">Color: </label>
                    </div>
                    <div class="col-md-4">
                        <select class="form-select" id="paleta-color" (change)="onChange($event.target.value, null)" [ngStyle]="{'background-color':selectedColor}">                    
                            <option *ngFor="let color of colores" 
                                [selected]="color.value == selectedColor"    
                                [value]="color.value" 
                                [ngStyle]="{'background-color':color.value}"
                                >{{color.name}}</option>
                        </select>
                    </div>
                    <div class="col-md-1">
                        <button (click)="agregarNuevaConfiguracion()" type="button" class="btn btn-primary">
                            Agregar
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-5 py-3">                
                <label class="col-form-label"><b>Opciones de monitoreo:</b></label>                
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" formControlName="permiteActualizacionFormControl" id="permiteActualizacionFormControl">
                    <label class="form-check-label" for="permiteActualizacionFormControl" ngbTooltip="Permite la actualizaón automática de pedidos creados">
                        Actualización automática de pedidos
                    </label>
                </div>                    
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" formControlName="permiteCreacionFormControl" id="permiteCreacionFormControl">
                    <label class="form-check-label" for="permiteCreacionFormControl" ngbTooltip="Permite la creación automática de nuevos pedidos">
                        Creación automática de pedidos
                    </label>
                </div> 
            </div>
        </div>  
        
        <div style="justify-content: center;" *ngIf="this.obteniendoConfiguracion" class="d-flex align-items-center text-primary">
            <loading-text-spinner message="Obteniendo configuración..." color="primary"></loading-text-spinner>
        </div>
        
        <div *ngIf="!obteniendoConfiguracion" class="row col-md-12">
            <div  class="mb-3 border col-md-5">
                <div class="bg-light text-center">
                    <label style="font-weight: bold;" class=" col-form-label">Pedidos Pendientes de Carga</label>
                </div> 
                <ngb-alert class="alert alert-warning mt-2" *ngIf="this.configuracion?.configuracionesTiempo.length==0">
                    No hay configuración para la aeroplanta seleccionada.
                </ngb-alert>   
                <div *ngIf="this.configuracion?.configuracionesTiempo.length>0">
                    <table class="table table-striped table-hover">
                        <thead class="text-center">
                            <th>Detalle</th>
                            <th>Color</th>
                            <th>Acción</th>
                        </thead>
                        <tbody class="text-center">
                            <tr *ngFor="let item of this.configuracion?.configuracionesTiempo">
                                <td>Minutos antes de la partida {{item.minutos}}</td>
                                <td>
                                    <span id="fila-color" 
                                        [ngStyle]="{'background-color':item.color}">
                                        <!--<i class="fa fa-square" aria-hidden="true">-->
                                        {{this.obtenerNombreColor(item.color)}}
                                    </span>
                                </td>        
                                <td>
                                    <button type="button" ngbTooltip="Quitar configuracion" (click)="borrarConfiguracion(item)" class="btn btn-danger ms-2 px-1">
                                        <i class="fa fa-trash"></i>
                                    </button>
                                </td>                      
                            </tr>
                        </tbody>
                    </table>
                </div>                
            </div>
            <div class="col-md-1"></div>    
            <div class="mb-3 border col-md-5">
                <div class="bg-light text-center">
                    <label style="font-weight: bold;" class="col-form-label">Pedidos con Acción</label>
                </div>                
                <table class="table table-striped table-hover">
                    <thead class="text-center">
                        <th>Detalle</th>
                        <th>Color</th>
                        <th>Accion</th>
                    </thead>
                    <tbody class="text-center">
                        <tr>
                            <td>Precargado</td>
                            <td>                                
                                <ng-container *ngIf="!editarColorPrecargado">
                                    <span
                                        id="fila-color" 
                                        [ngStyle]="{'background-color':this.configuracion.colorPrecargado}">
                                        {{this.obtenerNombreColor(this.configuracion.colorPrecargado)}} 
                                    </span>
                                </ng-container>                              
                                <ng-container *ngIf="editarColorPrecargado">
                                    <select class="form-select" style="width: 7rem;" id="paleta-color" 
                                        (change)="onChange($event.target.value, EstadoPedidoMonitoreo.Precargado)" 
                                        [ngStyle]="{'background-color':selectedColorPrecargado}">                    
                                        <option *ngFor="let color of colores" 
                                            [selected]="color.value == selectedColorPrecargado"    
                                            [value]="color.value" 
                                            [ngStyle]="{'background-color':color.value}"
                                            >{{color.name}}
                                        </option>
                                    </select> 
                                </ng-container> 
                            </td> 
                            <td>
                                <button type="button" ngbTooltip="Modificar color de precargado"                                    
                                    (click)="modificarConfiguracion(EstadoPedidoMonitoreo.Precargado)"
                                    class="btn btn-danger ms-2 px-1">
                                    <i class="fa fa-wrench" aria-hidden="true"></i>
                                </button>
                            </td>                                                        
                        </tr>
                        <tr>
                            <td>Cargado</td>
                            <td>  
                                <ng-container *ngIf="!editarColorCargado">
                                    <span 
                                        id="fila-color" 
                                        [ngStyle]="{'background-color':this.configuracion.colorCargado}">
                                        {{this.obtenerNombreColor(this.configuracion.colorCargado)}}
                                    </span>
                                </ng-container>                              
                                <ng-container *ngIf="editarColorCargado">
                                    <select class="form-select" style="width: 7rem;" id="paleta-color" 
                                        (change)="onChange($event.target.value, EstadoPedidoMonitoreo.Cargado)" 
                                        [ngStyle]="{'background-color':selectedColorCargado}">                    
                                        <option *ngFor="let color of colores" 
                                            [selected]="color.value == selectedColorCargado"    
                                            [value]="color.value" 
                                            [ngStyle]="{'background-color':color.value}"
                                            >{{color.name}}
                                        </option>
                                    </select> 
                                </ng-container> 
                            </td>
                            <td>
                                <button type="button" ngbTooltip="Modificar color de cargado" 
                                    (click)="modificarConfiguracion(EstadoPedidoMonitoreo.Cargado)" 
                                    class="btn btn-danger ms-2 px-1">
                                    <i class="fa fa-wrench" aria-hidden="true"></i>
                                </button>
                            </td>                                                      
                        </tr>
                        <tr>
                            <td>Prioritarios</td>
                            <td>  
                                <ng-container *ngIf="!editarColorPrioridad">
                                    <span 
                                        id="fila-color" 
                                        [ngStyle]="{'background-color':this.configuracion.colorPrioridad}">
                                        {{this.obtenerNombreColor(this.configuracion.colorPrioridad)}}
                                    </span>
                                </ng-container>                              
                                <ng-container *ngIf="editarColorPrioridad">
                                    <select class="form-select" style="width: 7rem;" id="paleta-color" 
                                        (change)="onChange($event.target.value, EstadoPedidoMonitoreo.Prioridad)" 
                                        [ngStyle]="{'background-color':selectedColorPrioridad}">                    
                                        <option *ngFor="let color of colores" 
                                            [selected]="color.value == selectedColorPrioridad"    
                                            [value]="color.value" 
                                            [ngStyle]="{'background-color':color.value}"
                                            >{{color.name}}
                                        </option>
                                    </select> 
                                </ng-container> 
                            </td>
                            <td>
                                <button type="button" ngbTooltip="Modificar color de prioridad" 
                                    (click)="modificarConfiguracion(EstadoPedidoMonitoreo.Prioridad)" 
                                    class="btn btn-danger ms-2 px-1">
                                    <i class="fa fa-wrench" aria-hidden="true"></i>
                                </button>
                            </td>                                                      
                        </tr>
                    </tbody>
                </table>              
            </div>   
        </div>   
    </form>
</div>

<div class="modal-footer bg-light d-flex justify-content-center">
    <span class="border">
        <button type="button" class="btn btn-primary" (click)="guardarConfiguracion()" [disabled]="guardandoDatos">
            <span *ngIf="!guardandoDatos">Guardar</span>          
            <div *ngIf="guardandoDatos" class="d-flex align-items-center">
                <div class="spinner-border text-light spinner-border-sm" role="status"></div>
                <span>Guardando...</span>
            </div>
        </button>
    </span>
</div>  