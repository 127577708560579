<div class="card">
  <div class="card-header">
    <h4 class="card-title">Ingreso de numeración según FOX</h4>
  </div>
  <div class="card-body" [formGroup]="numeracionFox">
    <div class="col-12" *ngIf="this.obteniendoAeroplanta">
      <ngx-spinner name="spinnerLista" [ngStyle]="{'height': '180px', 'z-index':'0'}"
        [ngClass]="{'list-group-item list-group-item-action': obteniendoAeroplanta}" bdOpacity=0.3bd
        Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
        <p style="color: white"> C A R G A N D O . . . </p>
      </ngx-spinner>
    </div>
    <div class="row" *ngIf="!this.obteniendoAeroplanta && this.aeroplanta[0].cai != null && !this.fechaVencida">
      <div class="col-12">
        <div class="row">
          <div class="col-6">
            <label>Prefijo:</label>
          </div>
          <div class="col-6">
            <input class="form-control" formControlName="prefijoFormControl" (keypress)="onlyNumberKey($event)">
            <div
              *ngIf="prefijoFormControl.invalid && prefijoFormControl.errors.required && (prefijoFormControl.dirty || prefijoFormControl.touched)"
              class="alert alert-danger">Requerido</div>
          </div>
        </div>
      </div>
      <div class="col-12 mt-2">
        <div class="row">
          <div class="col-6">
            <label>Numero:</label>
          </div>
          <div class="col-6">
            <input class="form-control" formControlName="numeroFormControl" (keypress)="onlyNumberKey($event)">
            <div
              *ngIf="numeroFormControl.invalid && numeroFormControl.errors.required && (numeroFormControl.dirty || numeroFormControl.touched)"
              class="alert alert-danger">Requerido</div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!this.obteniendoAeroplanta">
      <ngb-alert class="alert-warning" *ngIf="this.fechaVencida">La fecha del CAI esta vencida, debera actualizarla
        antes de continuar.</ngb-alert>
      <ngb-alert class="alert-warning" *ngIf="this.aeroplanta[0].cai == null">La aeroplanta no tiene un CAI ingresado,
        debera actualizarla antes de continuar.</ngb-alert>
    </div>
  </div>
  <div class="card-footer" *ngIf="!this.obteniendoAeroplanta">
    <button class="btn btn-primary me-2" *ngIf="this.aeroplanta[0].cai != null && !this.fechaVencida" [disabled]="guardando" (click)="setearNumeracion()">
      <span *ngIf="!guardando">Guardar</span>
      <div *ngIf="guardando" class="d-flex align-items-center">
        <div class="spinner-border text-light spinner-border-sm" role="status"></div>
        <span>Guardando...</span>
      </div>
    </button>
    <button class="btn btn-secondary" (click)="this.activeModal.close(false)">Cancelar</button>
  </div>
</div>