import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { UserManager, User, UserManagerSettings } from 'oidc-client';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseService } from "../../shared/base.service";
import { environment } from 'src/environments/environment';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';
import { Usuario } from 'src/app/shared/models/despacho/Usuario';
import { UsuarioService } from 'src/app/account/usuario.service';
import { AccionLogin } from 'src/app/shared/enums/AccionLogin';
import { MessageService } from 'src/app/shell/message.service';
import { from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseService {

  private _authNavStatusSource = new BehaviorSubject<boolean>(false);
  private _authAccionLoginSource = new BehaviorSubject<AccionLogin>(null);
  private _authProcesandoLoginSource = new BehaviorSubject<boolean>(false);
  private _usuarioSource = new BehaviorSubject<Usuario>(null);

  // Observable navItem stream
  authNavStatus$ = this._authNavStatusSource.asObservable();
  authAccionLogin$ = this._authAccionLoginSource.asObservable();
  usuario$ = this._usuarioSource.asObservable();
  procesandoLogin$ = this._authProcesandoLoginSource.asObservable();

  currentUser: User;

  private _manager = new UserManager(getClientSettings());
  private _user: User | null;
  private _usernameHeaderValue: string;

  constructor(
    private http: HttpClient, 
    private usuarioService: UsuarioService, 
    private messageService: MessageService) {
      super();

      /*
        Este evento se dispara cuando se hace el silentRenew
      */
  
      this._manager.getUser()
        .then((user) => {
          if (user) {
            this._authProcesandoLoginSource.next(true);

            this._user = user;

            this.handleUser(user);
          }
        })
        .catch((err) => {
          this.messageService.showErrorMessage(err)
        });
  
      this._manager.events.addUserLoaded((user) => {
        this._user = user;
      });
  }

  public get getUsername(): string {
    return this._usuarioSource.value? this._usuarioSource.value.nombresCompletos : "";
  }

  login() {
    return this._manager.signinRedirect();
  }

  isLoggedInObs(): Observable<boolean> {
    return from(this._manager.getUser()).pipe(map((user: User) => {
      if (user) {
        return true;
      } else {
        return false;
      }
    })
    );
  }

  startSigninMainWindow() {
    this._manager.signinRedirect({ data: 'some data' }).then(function () {
      console.log('signinRedirect done');
    }).catch(function (err) {
      console.log(err);
    });
  }

  handleUser = (user) => {
    if ( ! user.scope.includes("jwt") && user.access_token) {
      // Se obtienen datos por el profile o el accesstoken.
      // Si es login aoniken se decodifica el token y ya tenemos el usuario completo
      // Si es login YPF se decodifica parte para obtener profile encriptado.
      //    Se llama a usuarioActual de backend para que backend desencripte el profile y devuelva el usuario completo
      //    con sus roles.
      this.processLoginPorLibraryProfileAoniken();
    }else{
      this.processLoginPorAccessTokenYpf();
    }
  }

  convertirStringRoles(sRoles: string){
    //IDM-PAD-TEST-JEFEDEAEROPLANTA
    var arrayRoles = [];

    arrayRoles = sRoles.split(',')
      .map(r => {
        r = r.trim();
        r = r.replace("-", "_").replace("IDM_PAD_", "");
        var rolRecortado = r.toUpperCase();

        return TipoUsuario[rolRecortado];
      });

    return arrayRoles;
  }

  completarDatosUsuarioPorAccessTokenYpf(accessToken: string){
    let usuario = new Usuario();

    var tokenInfo = JSON.parse(atob(accessToken.split('.')[1]));
    var profileInfo = tokenInfo.token_details.Profile;

    usuario.nombresCompletos = profileInfo.last_name + ", " +profileInfo.first_name;
    usuario.email = profileInfo.mail;
    usuario.userName = tokenInfo.token_details.preferred_username;

    return usuario;
  }

  completarDatosUsuarioPorLibraryProfileAoniken(accessToken){
    let usuario = new Usuario();

    var tokenInfo = JSON.parse(atob(accessToken.split('.')[1]));

    usuario.nombresCompletos = tokenInfo.name;
    usuario.email = tokenInfo.email;
    usuario.userName = tokenInfo.unique_name;
    usuario.roles = this.convertirStringRoles(tokenInfo.roles.toString());
    usuario.sRoles = tokenInfo.roles.toString();

    return usuario;
  }

  completeAuthentication(): Promise<void> {
    return this._manager.signinRedirectCallback().then(user => {
      this._authProcesandoLoginSource.next(this.isAuthenticated())
      this.handleUser(user);      
    });
  }

  processLoginPorLibraryProfileAoniken(){
    var accessToken = this._user.access_token;

    let usuario = this.completarDatosUsuarioPorLibraryProfileAoniken(accessToken);

    this.loginUsuarioPad(usuario);
  }

  processLoginPorAccessTokenYpf(){
    var accessToken = this._user.access_token;

    let usuario = this.completarDatosUsuarioPorAccessTokenYpf(accessToken);

    this.loginUsuarioPad(usuario);
  }

  loginUsuarioPad(usuario){

    this.usuarioService.loginUsuario(usuario).subscribe(
      usuarioAccionDto => {
        usuario = usuarioAccionDto.usuarioDTO;

        // Aqui vuelven los roles. Tomarlos y asignarlos a usuario.
        usuario.sRoles = this.usuarioService.convertirRolesIntEnRolesString(usuario.roles);

        this._usuarioSource.next(usuario);
        this._usernameHeaderValue = usuario.userName;
        this._authAccionLoginSource.next(usuarioAccionDto.accion);
        this._authNavStatusSource.next(this.isAuthenticated());
        this._authProcesandoLoginSource.next(false);
      },
      (err) => {
        this.messageService.showErrorMessage(err.message + "\n" + err.description);
        console.log(err);
        this._authProcesandoLoginSource.next(false);
        this._authNavStatusSource.next(this.isAuthenticated());
      });
  }

  register(userRegistration: any) {
    return this.http.post(environment.authentication.authority + '/account', userRegistration).pipe(catchError(this.handleError));
  }

  get userRoles(): string[] {

    if (this._user && this._user.profile && this._user.profile.roles) {
      // Autenticación Microsoft
      this._user.profile.roles = this._user.profile.roles.map(r => {
        r = r.trim();
        r = r.replace("-", "_").replace("IDM_PAD_", "");
        return r.toUpperCase();
      });
      return Array.isArray(this._user.profile.roles) ? this._user.profile.roles : this.recortarPrefijoRol(this._user.profile.roles);
    }else{
      // Autenticacion ypf
      return this.usuarioService.getArrayStringRoles();
    }
  }

  /**
   * Recorta "IDM-PAD-TEST-" de los roles
   * @param roles El string de roles como viene desde el servidor de autenticación
   */
  recortarPrefijoRol(roles: string): string[] {
    return roles.split(', ')
      .map(r => {
        r = r.substring(r.lastIndexOf('-') + 1).toLowerCase();
        return r.charAt(0).toUpperCase() + r.slice(1);
      });
  }

  hasRole(...roles: TipoUsuario[]): boolean {
    var hasRole = this.userRoles.some(r => roles.indexOf(TipoUsuario[r]) !== -1);

    return hasRole;
  }

  isAuthenticated(): boolean {
    return this._user != null && !this._user.expired;
  }

  get authorizationHeaderValue(): string {
    return `${this._user.token_type} ${this._user.access_token}`;
  }

  get usernameHeaderValue(): string {
    if( ! this._usernameHeaderValue ) return "";
    return this._usernameHeaderValue;
  }

  signout() {
    console.log('closing session...');
    this._manager.signoutRedirect();
  }
}

export function getClientSettings(): UserManagerSettings {
  return {
    client_id: environment.authentication.client_id,
    client_secret: environment.authentication.client_secret,
    authority: environment.authentication.authority,
    redirect_uri: environment.authentication.redirect_uri,
    post_logout_redirect_uri: environment.authentication.post_logout_redirect_uri,
    response_type: environment.authentication.response_type,
    loadUserInfo: environment.authentication.loadUserInfo,
    scope: environment.authentication.scope,
    automaticSilentRenew: environment.authentication.automaticSilentRenew,
    silent_redirect_uri: environment.authentication.silent_redirect_uri
  };
}

