import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { CuentasService } from 'src/app/cliente/cuentas.service';
import { ComprobanteService } from 'src/app/facturacion/comprobante.service';
import { Aeroplanta } from 'src/app/shared/models/despacho/Aeroplanta';
import { Pais } from 'src/app/shared/models/despacho/Pais';
import { Ciudad } from 'src/app/shared/models/facturacion/Ciudad';
import { Provincia } from 'src/app/shared/models/facturacion/Provincia';
import { ManagementService } from "../../management.service";
import { Emisor } from "src/app/shared/models/facturacion/Emisor";
import { UsuarioService } from 'src/app/account/usuario.service';
import { Usuario } from 'src/app/shared/models/despacho/Usuario';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { MessageService } from 'src/app/shell/message.service';
import { Ubicacion } from 'src/app/shared/models/despacho/Ubicacion';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';
import { AerovalesService } from 'src/app/despacho/aerovales.service';

@Component({
  selector: 'app-detalle-abm-aeroplanta',
  templateUrl: './detalle-abm-aeroplanta.component.html',
  styleUrls: ['./detalle-abm-aeroplanta.component.css']
})
export class DetalleAbmAeroplantaComponent implements OnInit {
  @Input() aeroplantaSeleccionada: Aeroplanta;
  @Input() editando: boolean;

  paises: Pais[] = [];
  provincias: Provincia[] = [];
  ciudades: Ciudad[] = [];
  ciudadBuscada: Ciudad;
  modelCiudad: any;
  modelProvincia: any;
  modelPais: any;
  emisores: Emisor[] = [];
  aeroplantaDetalle: Aeroplanta[] = [];
  obteniendoEmisores: boolean = false;
  usuariosComerciales: Usuario[] = [];
  guardando: boolean = false;

  cargandoPaises: boolean = false;
  cargandoProvincias: boolean = false;
  cargandoCiudades: boolean = false;
  obteniendoComerciales: boolean = false;

  aeroplantaFormGroup = new UntypedFormGroup({
    nombreFormControl: new UntypedFormControl('', Validators.required),
    paisFormControl: new UntypedFormControl(''),
    provinciaFormControl: new UntypedFormControl(''),
    codigoPostalFormControl: new UntypedFormControl(''),
    soloPADFormControl: new UntypedFormControl(''),
    direccionFormControl: new UntypedFormControl('', Validators.required),
    direccionComplementoFormControl: new UntypedFormControl(''),
    mandatarioFormControl: new UntypedFormControl('', Validators.required),
    codigoFormControl: new UntypedFormControl('', Validators.required),
    emisorFormControl: new UntypedFormControl(''),
    comercialFormControl: new UntypedFormControl(''),
    numeroCentroEmisorFormControl: new UntypedFormControl('', Validators.required),
    numeroAeroplantaFormControl: new UntypedFormControl('', Validators.required),
  });

  constructor(private authService: AuthService,private cuentasService: CuentasService, private comprobanteService: ComprobanteService,
    private managementService: ManagementService, private usuarioService: UsuarioService, private messageservice: MessageService,
    private aerovaleService:AerovalesService) { }

  TipoUsuario = TipoUsuario;

  ngOnInit() {
    this.pais.setValue(null);
    this.provincia.setValue(null);
    this.codigoPostal.setValue(null);
    if (this.editando) {
      this.nombreFormControl.setValue(this.aeroplantaSeleccionada.nombre);
      this.codigoFormControl.setValue(this.aeroplantaSeleccionada.codigoAeroplanta);      
      this.mandatarioFormControl.setValue(this.aeroplantaSeleccionada.mandatarioSAP);
      this.numeroAeroplantaFormControl.setValue(this.aeroplantaSeleccionada.numeroAeroplanta);
      this.numeroCentroEmisorFormControl.setValue(this.aeroplantaSeleccionada.numeroCentroEmisor);
      this.direccionFormControl.setValue(this.aeroplantaSeleccionada.ciudad.nombre);
      this.soloPADFormControl.setValue(this.aeroplantaSeleccionada.soloPAD);
      // this.direccionComplementoFormControl.setValue(this.aeroplantaSeleccionada.ubicacion.direccionComplemento);
    } else {
      this.nombreFormControl.setValue('');
      this.codigoFormControl.setValue('');
      this.mandatarioFormControl.setValue('');
    }
    this.getPaises();
    this.getEmisores();
    //this.getComerciales();
    this.obtenerComerciales();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.aeroplantaSeleccionada.firstChange) {
      this.ngOnInit();
    }
  }

  getEmisores() {
    this.obteniendoEmisores = true;
    this.managementService.getEmisores()
      .subscribe((result) => {
        this.emisores = result;
        if (!this.editando) {
          this.emisorFormControl.setValue(result[0]);
        } else {
          let emi: Emisor[] = this.emisores.filter(a => a.id == this.aeroplantaSeleccionada.emisor.id)
          this.emisorFormControl.setValue(emi[0]);
        }
        this.obteniendoEmisores = false;
      }, (err: ErrorModel) => {
        this.messageservice.showErrorMessage('Hubo un error al obtener los emisores. ' + err.description + ' ' + err.message);
        this.obteniendoEmisores = false;
      });
  }

  getComerciales() {
    /*this.usuarioService.obtenerComercialPorAeroplanta()
      .subscribe(result => {
        this.usuariosComerciales.push(result);
        if (!this.editando) {
          this.comercialFormControl.setValue(this.usuariosComerciales[0]);
        } else {
          let comer: Usuario[] = this.usuariosComerciales.filter(a => a.id = this.aeroplantaSeleccionada.comercial);
          this.comercialFormControl.setValue(comer[0]);
        }
        this.obteniendoComerciales = false;
      }, (err: ErrorModel) => {
        this.messageservice.showErrorMessage('Hubo un error al obtener los comerciales. ' + err.description + ' ' + err.message);
        this.obteniendoComerciales = false;
      });*/
  }

  async obtenerComerciales() {    
    this.obteniendoComerciales = true;
    this.usuariosComerciales = [];
    let roles = this.usuarioService.getRolesComerciales();
    let codAero = this.aeroplantaSeleccionada.codigoAeroplanta!=undefined ? this.aeroplantaSeleccionada.codigoAeroplanta : this.usuarioService.getAeroplantaActual().codigoAeroplanta;
    try {
      let c = await this.usuarioService.aysncObtenerComercialPorAeroplanta(codAero);
      if (c) {
        this.usuariosComerciales.push(c);
        this.comercialFormControl.setValue(this.usuariosComerciales.find(c=>c.id = this.aeroplantaSeleccionada.comercial));
      }

      let u = await this.usuarioService.obtenerUsuariosConRoles(roles, codAero);
      if (u) {
        this.usuariosComerciales = this.usuariosComerciales.concat(u);        
        this.obteniendoComerciales = false;
      }  
    } catch (error) {
      this.messageservice.showErrorMessage("Hubo un error al obtener los datos." + error.message);
      this.obteniendoComerciales = false;
    }
    
  }

  getPaises() {
    this.cargandoPaises = true;
    this.cargandoProvincias = true;
    this.cargandoCiudades = true;
    this.cuentasService.getPaises()
      .subscribe(result => {
        this.paises = result;
        this.cargandoPaises = false;
        this.obtenerPaisSeleccionado();
      }, () => '')
  }
  obtenerPaisSeleccionado() {
    if (this.aeroplantaSeleccionada.ciudad == null) {
      for (let i = 0; this.paises.length > i; i++) {
        if (this.paises[i].nombre == 'Argentina') {
          this.pais.setValue(this.paises[i]);
          this.getProvinciasPorPais(this.paises[i].codigo);
        }
      }
    } else {
      for (let i = 0; this.paises.length > i; i++) {
        if (this.paises[i].nombre == this.aeroplantaSeleccionada.ciudad.provincia.pais) {
          this.pais.setValue(this.paises[i]);
          this.getProvinciasPorPais(this.paises[i].codigo);
        }
      }
    }
  }

  getProvinciasPorPais(codigo: string) {
    this.provincia.setValue(null);
    this.cuentasService.getProvinciasPorPais(codigo)
      .subscribe(result => {
        this.provincias = result;
        this.cargandoProvincias = false;
        this.obtenerProvinciaSeleccionada();
      }, () => '')
  }
  obtenerProvinciaSeleccionada() {
    if (this.aeroplantaSeleccionada.ciudad == null) {
      for (let i = 0; this.provincias.length > i; i++) {
        if (this.provincias[i].codigo == 'BSAS') {
          this.provincia.setValue(this.provincias[i]);
          this.getLocalidadPorProvincia(this.provincias[i].codigo);
        }
      }
    } else {
      for (let i = 0; this.provincias.length > i; i++) {
        if (this.provincias[i].codigo == this.aeroplantaSeleccionada.ciudad.provincia.codigo) {
          this.provincia.setValue(this.provincias[i]);
          this.getLocalidadPorProvincia(this.provincias[i].codigo);
        }
      }
    }
  }
  getLocalidadPorProvincia(codigo: string) {
    this.ciudades = [];
    this.cuentasService.getCiudadPorProvincia(codigo)
      .subscribe(result => {
        this.ciudades = result;
        this.cargandoCiudades = false;
        this.obtenerLocalidadSeleccionada();
      }, () => '')
  }

  obtenerLocalidadSeleccionada() {
    if (this.aeroplantaSeleccionada.ciudad == null) {
      this.codigoPostal.setValue(this.ciudades[0]);
    } else {
      for (let i = 0; this.ciudades.length > i; i++) {
        if (this.ciudades[i].id == this.aeroplantaSeleccionada.ciudad.id) {
          this.codigoPostal.setValue(this.ciudades[i])
        }
      }
    }

  }

  searchPais = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 || this.cargandoPaises ? [] :
        this.paises.filter(v => this.containsString(this.pais.value, v.nombre, v.codigo)).slice(0, 10))
    )
  searchProvincia = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 || this.cargandoProvincias ? [] :
        this.provincias.filter(v => this.containsString(this.provincia.value, v.nombre, v.codigo)).slice(0, 10))
    )
  searchCiudad = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 || this.cargandoCiudades ? [] :
        this.ciudades.filter(v => this.containsString(this.codigoPostal.value, v.nombre, v.codigoPostal)).slice(0, 10))
    )
  /**
   * Determina si una cadena se encuentra dentro de otra
   * @param searchValue Valor a buscar
   * @param searchIn Valores sobre los que buscar searchValue
   */
  containsString(searchValue: string, ...searchIn: string[]): boolean {
    const concat = searchIn.toString();
    return concat.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
  }
  selectPais(e: any): void {
    console.log('e:' + e.item);
    this.pais.setValue(e.item.codigo);
  }
  selectProvincia(e: any): void {
    console.log('e:' + e.item);
    this.provincia.setValue(e.item.codigo);
    this.codigoPostal.setValue(null);
    this.getLocalidadPorProvincia(e.item.codigo);
  }
  selectCiudades(e: any): void {
    this.codigoPostal.setValue(e.item.nombre);
    this.ciudadBuscada = e.item.nombre;
  }

  compararEmisor(e1: any, e2: any): boolean {  
    return e1 && e2 ? e1.id == e2.id : e1 == e2;
  }

  formatterPais = (x: { nombre: string, codigo: string }) => `${x.nombre} - ${x.codigo}`;
  formatterProvincia = (x: { nombre: string, codigo: string }) => `${x.nombre} - ${x.codigo}`;
  formatterCiudad = (x: { nombre: string, codigoPostal: string }) => `${x.nombre} - ${x.codigoPostal}`;

  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46;
  }

  guardar() {
    if (this.aeroplantaFormGroup.valid) {
      this.guardando = true;
      let aeropla = new Aeroplanta();
      aeropla.ubicacion = new Ubicacion();
      aeropla.nombre = this.nombreFormControl.value;
      aeropla.codigoAeroplanta = this.codigoFormControl.value;
      aeropla.mandatarioSAP = this.mandatarioFormControl.value;
      //cuando backend actualice stage el emisor sacar el emisorId 31/03/22
      aeropla.emisorId = this.emisorFormControl.value.id;
      aeropla.emisor = this.emisorFormControl.value;
      //aeropla.comercial = this.comercialFormControl.value.id;
      aeropla.numeroCentroEmisor = this.numeroCentroEmisorFormControl.value;
      aeropla.numeroAeroplanta = this.numeroAeroplantaFormControl.value;
      aeropla.soloPAD = this.soloPADFormControl.value;
      aeropla.ubicacion.codigoPostal = this.codigoPostal.value.codigoPostal;
      aeropla.ubicacion.direccion = this.direccionFormControl.value;
      aeropla.ubicacion.direccionComplemento = this.direccionComplementoFormControl.value;
      if (!this.editando) {
        // this.comprobanteService.postAeroplanta(aeropla)
        //   .subscribe(result => {
        //     this.messageservice.showSuccessMessage('Se creó la aeroplanta correctamente');
        //   }, (err: ErrorModel) => {
        //     this.messageservice.showErrorMessage('Hubo un error al crear la aeroplanta. ' + err.message + ' ' + err.description)
        //   });
      } else {
        this.comprobanteService.putAeroplanta(aeropla)
          .subscribe(result => {
            this.messageservice.showSuccessMessage('Se actualizó la aeroplanta correctamente');
            this.guardando = false;
            this.ngOnInit();
          }, (err: ErrorModel) => {
            this.messageservice.showErrorMessage('Hubo un error al actualizar la aeroplanta. ' + err.message + ' ' + err.description)
            this.guardando = false;
          });
      }
    } else {
      this.messageservice.showErrorMessage('Por favor complete los datos solicitados');
    }
  }

  hasRole(...roles: TipoUsuario[]) {
    return this.authService.hasRole(...roles);
  }

  get nombreFormControl() {
    return this.aeroplantaFormGroup.controls.nombreFormControl;
  }

  get pais() {
    return this.aeroplantaFormGroup.controls.paisFormControl;
  }

  get provincia() {
    return this.aeroplantaFormGroup.controls.provinciaFormControl;
  }

  get codigoPostal() {
    return this.aeroplantaFormGroup.controls.codigoPostalFormControl;
  }

  get soloPADFormControl() {
    return this.aeroplantaFormGroup.controls.soloPADFormControl;
  }

  get direccionComplementoFormControl() {
    return this.aeroplantaFormGroup.controls.direccionComplementoFormControl;
  }

  get direccionFormControl() {
    return this.aeroplantaFormGroup.controls.direccionFormControl;
  }

  get codigoFormControl() {
    return this.aeroplantaFormGroup.controls.codigoFormControl;
  }

  get mandatarioFormControl() {
    return this.aeroplantaFormGroup.controls.mandatarioFormControl;
  }

  get emisorFormControl() {
    return this.aeroplantaFormGroup.controls.emisorFormControl;
  }

  get comercialFormControl() {
    return this.aeroplantaFormGroup.controls.comercialFormControl;
  }

  get numeroCentroEmisorFormControl() {
    return this.aeroplantaFormGroup.controls.numeroCentroEmisorFormControl;
  }

  get numeroAeroplantaFormControl() {
    return this.aeroplantaFormGroup.controls.numeroAeroplantaFormControl;
  }
}
