<div class="card" id="holdeoAerovale-card">
  <div class="card-header">
    <h4 style="display: inline;" class="card-title">Holdeo de aerovales.</h4>
    <button style="margin-left: 4em;" class="btn btn-primary" [disabled]="obteniendo" (click)="nuevoHoldeoAerovle()"
      type="button">Agregar
      Aerovale</button>
    <button style="margin-left: 4em;" class="btn btn-primary float-end" type="button" [disabled]="this.obteniendo"
      (click)="goBack()">Volver</button>
  </div>
  <div class="card-body">
    <div class="col-12">
      <ngx-spinner name="spinnerLista" [ngStyle]="{'height': '400px', 'z-index':'0'}"
        [ngClass]="{'list-group-item list-group-item-action': obteniendo}" bdOpacity=0.3bd Color="rgba(193,193,193,0)"
        size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
        <p style="color: white"> O b t e n i e n d o H o l d e o s . . . </p>
      </ngx-spinner>
    </div>
    <div *ngIf="!obteniendo">
      <ngb-alert *ngIf="cuentasHoldeadas.length == 0">No hay aerovales holdeados activos para la fecha.</ngb-alert>
      <table id="tabla" class="table table-sm table-striped table-hover" *ngIf="cuentasHoldeadas.length != 0">
        <thead class="text-center">
          <th>Cliente / N° aerovale</th>
          <th>Motivo</th>
          <th>Fecha desde</th>
          <th>Fecha hasta</th>
          <th>Responsable</th>
          <th>Ver aerovale</th>
          <th>Acción</th>
        </thead>
        <tbody *ngFor="let c of cuentasHoldeadas; let i = index" class="text-center">
          <tr>
            <td>{{c.ctA_Descripcion}}</td>
            <td>{{c.detalle}}</td>
            <td>{{c.fechaDesde | date: 'dd/MM/yyyy'}}</td>
            <td>{{c.fechaHasta | date: 'dd/MM/yyyy'}}</td>
            <td>{{c.responsable}}</td>
            <td class="text-center">
              <button class="btn btn-primary btn-sm col-sm-8" (click)="obtenerAerovale(c.aerovaleId, i)" [disabled]="obteniendoAerovale">
                <i class="fa fa-eye" aria-hidden="true" *ngIf="aerovaleSeleccionado != i"></i>                
                <div *ngIf="obteniendoAerovale && aerovaleSeleccionado == i">
                  <div class="spinner-border text-light spinner-border-sm" role="status"></div>
                </div>
              </button>
            </td>
            <td>
              <button type="button" (click)="quitarCuentas(i)" [disabled]="desactivandoCuenta"
                class="btn btn-danger btn-sm col-sm-12">
                <i class="fa fa-trash col-sm-12" aria-hidden="true" *ngIf="cuentaSeleccionada != i"></i>
                <div *ngIf="desactivandoCuenta && cuentaSeleccionada==i">
                  <div class="spinner-border text-light spinner-border-sm" role="status"></div>
                  <span><i class="fa fa-trash" aria-hidden="true"></i></span>
                </div>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>