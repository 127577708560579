<ul class="nav nav-tabs justify-content-start">
  <li class="nav-item" *ngIf="false && this.hasRole(TipoUsuario.COMERCIAL)">
    <a class="nav-link" routerLink="importarClientes" routerLinkActive="active"><b>Importar Clientes</b></a>
  </li>
  <li  class="nav-item" >
    <a class="nav-link" *ngIf="this.hasRole(TipoUsuario.COMERCIAL, TipoUsuario.SUPERVISOR, TipoUsuario.ADMCLIENTES, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.FACTURACION, TipoUsuario.REFACTURACION, TipoUsuario.SOPORTE, TipoUsuario.APROBADORBYR)" routerLink="nuevoCliente" routerLinkActive="active"><b>Clientes</b></a>
  </li>
  <li  class="nav-item" >
    <a class="nav-link" *ngIf="this.hasRole(TipoUsuario.ADMCLIENTES)" routerLink="aprobarClientes" routerLinkActive="active"><b>Aprobar cambios clientes</b></a>
  </li>
  <li  class="nav-item" >
    <a class="nav-link" *ngIf="this.hasRole(TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.ADMCLIENTES, TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.SUPERVISOR)" routerLink="reporteIndex" routerLinkActive="active"><b>Reportes</b></a>
  </li>

  </ul>
  <router-outlet></router-outlet>

