import { Aeroplanta } from '../despacho/Aeroplanta';
import { TipoValorParametroImpositivo } from '../../enums/TipoValorParametroImpositivo';
import { TipoBaseCalculoParametroImpositivo } from '../../enums/TipoBaseCalculoParametroImpositivo';
import { TipoCodigoParametroImpositivo } from '../../enums/TipoCodigoParametroImpositivo';

export class ParametroImpositivoDetalle {
    parametroId: string;
    fechaActualizacion: string;
    activa: boolean;
    leyenda: string;
    // valor: number;
    // tipoValor: TipoValorParametroImpositivo;
    // baseCalculo: TipoBaseCalculoParametroImpositivo;
    // minimo: number;
    codigo: TipoCodigoParametroImpositivo;
    // parametroEsImpuesto: boolean;
    // alicuotaIIBBDefecto : number;
    // alicuotaIIBBDefectoInscriptos : number;
    // alicuotaIIBBMultilateral : number;
    // alicuotaIIBBMayorista : number;
    // importeMinimoPercepcion : number;
};