import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe, DecimalPipe } from '@angular/common';
import { CierreTurnoService } from 'src/app/cierreTurno/cierre-turno.service';
import { RanchoService } from '../../rancho.service';
import { RanchoV2 } from 'src/app/shared/models/ranchos/RanchoV2';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { MessageService } from 'src/app/shell/message.service';
import { RemitosRanchos } from 'src/app/shared/models/ranchos/RemitosRanchos';
import { AerovaleDetalle } from 'src/app/shared/models/despacho/AerovaleDetalle';
import { AerovalesService } from 'src/app/despacho/aerovales.service';
import { AerovaleDetalleComponent } from 'src/app/despacho/aerovale-detalle/aerovale-detalle.component';
import { ComprobanteService } from 'src/app/facturacion/comprobante.service';
import { Comprobante } from 'src/app/shared/models/facturacion/Comprobante';
import { VisorComprobanteComponent } from 'src/app/shared/visor-comprobante/visor-comprobante.component';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';
import { ModalMarcarRanchoComponent } from './modal-marcar-rancho/modal-marcar-rancho.component';
import { EstadoConsumo } from 'src/app/shared/enums/EstadoConsumo';
import { ConsumoRancho } from 'src/app/shared/models/ranchos/ConsumoRancho';
import { NotificacionesService } from 'src/app/abm/notificacion/notificaciones.service';
import { UsuarioDTO } from 'src/app/shared/models/notificaciones/UsuarioDTO';
import { UsuarioService } from 'src/app/account/usuario.service';
import { Response } from 'src/app/shared/models/Response';
import { FormControl } from '@angular/forms';
@Component({
  selector: 'app-modal-bitacora-rancho',
  templateUrl: './modal-bitacora-rancho.component.html',
  styleUrls: ['./modal-bitacora-rancho.component.scss']
})
export class ModalBitacoraRanchoComponent implements OnInit {

  // entradas
  @Input() rancho: RanchoV2;

  mostrarAnulados:boolean=true;

  //variables locales
  obteniendoDatos: boolean = false;
  TipoUsuario = TipoUsuario;
  EstadoConsumo = EstadoConsumo;
  remitosRanchos: RemitosRanchos[] = [];   // class Bitacora
  remitosFiltrados: RemitosRanchos[] = [];   // class Bitacora
  consumo: ConsumoRancho;
  estados: any[] = [
    {codigo:0,nombre:"Pendiente",seleccionado: false},
    {codigo:1,nombre:"Desconocido",seleccionado: false},
    {codigo:2,nombre:"Reconocido",seleccionado: false}
  ];

  // ver aerovale
  aerovaleDetalle: AerovaleDetalle;
  obteniendoAerovale: boolean = false;
  aerovaleSeleccionado: number = null;
  
  facturaObtenida: Comprobante;
  facturaSeleccionada: number = null;
  obteniendoFactura: boolean = false;
  descargandoPdf: boolean = false;  

  constructor(private spinner: NgxSpinnerService, 
    private activeModal: NgbActiveModal,
    private modal: NgbModal,
    private datePipe: DatePipe,
    private messageService: MessageService,
    private decimalPipe: DecimalPipe,
    private ranchoService: RanchoService,
    private cierreTurnoService: CierreTurnoService,
    private aerovaleService: AerovalesService,
    private comprobanteService: ComprobanteService,   
    private notificacionesService: NotificacionesService, 
    private usuarioService: UsuarioService,
    private authService: AuthService) { }

  ngOnInit(): void {    
    this.obtenerBitacoraPorConsumosRanchos();
  }

  mostrarSpinner(){
    this.obteniendoDatos = true;
    this.spinner.show('spinnerGrafico');
  }

  ocultarSpinner(){
    this.obteniendoDatos = false;
    this.spinner.hide('spinnerGrafico');
  }

  obtenerBitacoraPorConsumosRanchos() {
    // llamar al servicio GET para traer los movimientos de un rancho
    let listaIds:string[] = [];
    listaIds = this.rancho.consumos.map(c => c.aerovaleId);
        
    this.mostrarSpinner();
    this.ranchoService.putConsumosRanchosV2(listaIds)
      .subscribe(result => {
        this.remitosRanchos = result.sort((a, b) => new Date(b.fecha).getTime() - new Date(a.fecha).getTime());
        this.remitosRanchos = this.completarVolumenes();  
        this.remitosFiltrados = this.remitosRanchos;    
        this.ocultarSpinner();
      },
      (err: ErrorModel) => {
        this.messageService.showErrorMessage('Hubo un error al obtener los datos. ' + err.description);
        this.ocultarSpinner();
      });
  }

  // el volumen de los remitos viene en 0, lo completamos con los consumos
  completarVolumenes() {
    for (let r of this.remitosRanchos) 
      for (let c of this.rancho.consumos)
        if (r.aerovaleId == c.aerovaleId) {
          r.volumen = c.volumen;
          r.estado = c.estadoConsumo; // le paso el estado del consumo, Ver que pasa si los marcan y no actualiza
          break
        }        
    return this.remitosRanchos;    
  }

  /** Visualizar comprobantes */
  verAerovale(id: string, i:number) {
    this.aerovaleDetalle = null;    
    this.obteniendoAerovale = true;    
    this.aerovaleSeleccionado=i;
    this.aerovaleService.getAerovalePorIdyTipo(id)
      .subscribe(result => {
        this.aerovaleDetalle = result;           
        this.aerovaleSeleccionado= null;  
        this.obteniendoAerovale = false;
        if(this.aerovaleDetalle != null) {
          this.mostrarAerovale();
        }}, (err: ErrorModel) => {
          this.messageService.showErrorMessage('Hubo un error al obtener el Aerovale' + err.description);   
          this.aerovaleSeleccionado=null;  
          this.obteniendoAerovale = false;  
        });
  }

  mostrarAerovale() {
    let modalRef = this.modal.open(AerovaleDetalleComponent, { size: "lg", centered: true })
    modalRef.componentInstance.aerovaleDetalle = this.aerovaleDetalle;  
  }

  verFactura(id: string, i:number) {
    this.obteniendoFactura = true;
    this.facturaSeleccionada=i;
    this.comprobanteService.obtenerComprobantePorId(id)
      .subscribe(result => {
        this.facturaObtenida = result; 
        this.facturaSeleccionada=null;
        this.obteniendoFactura = false;
        this.mostrarFactura();
      }, (err: ErrorModel) => {
        this.messageService.showErrorMessage('Hubo un error al obtener la factura' + err.description);   
        this.facturaSeleccionada=null;   
        this.obteniendoFactura = false; 
      });
  }

  mostrarFactura() {
    if (this.facturaObtenida != null) {
      const modalRef = this.modal.open(VisorComprobanteComponent, { centered: true, size: 'lg' });
      modalRef.componentInstance.facturaObtenida = this.facturaObtenida;
    }
  }

  marcarRancho(aerovale:RemitosRanchos) {
    let modalRef = this.modal.open(ModalMarcarRanchoComponent, { centered: true, backdrop: "static" });
    modalRef.componentInstance.codigoRancho = this.rancho.codigoRancho;
    modalRef.componentInstance.consumoRanchoid = this.rancho.consumos.filter( r=>r.aerovaleId == aerovale.aerovaleId)[0].id;
    modalRef.componentInstance.aerovale = aerovale;
    modalRef.result.then((consumo) => {
      if (consumo) {
        for (let r of this.remitosRanchos)           
          if (r.aerovaleId == consumo.aerovaleId) {
              r.estado = consumo.estadoConsumo; 
            break
          }        
        return this.remitosRanchos;    
      }
    })
  }

  hasRole(...roles: TipoUsuario[]) {
    return this.authService.hasRole(...roles);
  }

  filtrarMovimientos() {
    this.remitosFiltrados = this.remitosRanchos.filter(r => {
      let agregar = true;

      if(this.estados.some(b => b.seleccionado))
        agregar = agregar && this.estados.some(
          b => (b.seleccionado && b.codigo == r.estado))  
      
      if(!this.mostrarAnulados) {
        agregar = agregar && r.fechaAnulacion==null;
      }
      return agregar;
    });
  }

  /** descargar pdf */

  descargarPdf() {    
    if(this.remitosFiltrados.length==0) {
      this.messageService.showErrorMessage("No hay información para descargar del Rancho "+this.rancho.codigoRancho);
      return
    }else{
      this.descargandoPdf=true;
      this.notificacionesService.descargarPdfAerovales(this.rancho.codigoRancho, this.obtenerUsuario())
        .subscribe(resp=> {
          this.messageService.showSuccessMessage(resp);
          this.descargandoPdf=false;
        }, (err: any)=>{
          let descripcion=JSON.parse(err.error.replace(/'/g,'"')).description;
          this.messageService.showErrorMessage(descripcion);
          this.descargandoPdf=false;
        });
    }
  }

  obtenerUsuario() {
    let u = new UsuarioDTO();
    u.nombresCompletos = this.usuarioService.getNombresOperador();
    u.dni = this.usuarioService.getDniOperador();
    u.email = this.usuarioService.getEmailUsuario();
  
    return u;    
  }

  /** Exportar excel */

  exportarExcel() {

    if(this.remitosFiltrados.length==0) {
      this.messageService.showErrorMessage("No hay información para exportar del Rancho "+this.rancho.codigoRancho);
      return
    }

    var dataArray=[
      ["Bitacora de Rancho: "+this.rancho.codigoRancho],      
      ["Listado de movimientos de stock para este rancho"],      
      [],
      this.mostrarAnulados ? ["Fecha", "Hora", "Nº Remito", "Nº Aerovale", "Cliente", "Volumen", "Fecha Anulación", "Estado"] : ["Fecha", "Hora", "Nº Remito", "Nº Aerovale", "Cliente", "Volumen", "Estado"]
    ];

    let volDespachado = 0;
    let estadoConsumo : string;

    for (let i=0; i < this.remitosFiltrados.length; i++) {
      var m = this.remitosFiltrados[i];
      switch (m.estado) {
        case EstadoConsumo.Pendiente:
          estadoConsumo="Pendiente";
          break;    
        case EstadoConsumo.Desconocido:
          estadoConsumo="Desconocido";
          break;
        case EstadoConsumo.Reconocido:
          estadoConsumo="Reconocido";
          break;    
        default: estadoConsumo="";
          break;
      }
      var fila = this.mostrarAnulados ? [this.datePipe.transform(m.fecha, "dd/MM/yyy"), this.datePipe.transform(m.fecha, "hh:mm a"), m.remitoPrefijo +"-"+ m.remitoNumero, m.aerovalePrefijo +"-"+ m.aerovaleNumero, m.cliente, this.decimalPipe.transform(m.volumen, '1.0-0'), this.datePipe.transform(m.fechaAnulacion, "dd/MM/yyy"), estadoConsumo] : [this.datePipe.transform(m.fecha, "dd/MM/yyy"), this.datePipe.transform(m.fecha, "hh:mm a"), m.remitoPrefijo +"-"+ m.remitoNumero, m.aerovalePrefijo +"-"+ m.aerovaleNumero, m.cliente, this.decimalPipe.transform(m.volumen, '1.0-0'), estadoConsumo];
      volDespachado += this.remitosFiltrados[i].volumen;      
      dataArray.push(fila);
    }

    var filaFooter = ["", "", "", "", "Total Volumen", this.decimalPipe.transform(volDespachado, '1.0-0')];
    dataArray.push(filaFooter);

    var filename = "Bitacora de Rancho "+this.rancho.codigoRancho;
    this.cierreTurnoService.exportAsExcelFile(dataArray, filename);
  }
}
