import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProductoService } from 'src/app/abm/producto/producto.service';
import { Producto } from 'src/app/shared/models/abm/Producto';

@Component({
  selector: 'app-consolidado-lista',
  templateUrl: './consolidado-lista.component.html',
  styleUrls: ['./consolidado-lista.component.scss']
})
export class ConsolidadoListaComponent implements OnInit {

  @Output() productoSeleccionado: EventEmitter<Producto> = new EventEmitter;
  @Input() set productoBuscado(texto: string) {
    this.filtrarProductos(texto);
  };

  productos: Producto[];
  productosFiltrados: Producto[];
  prodSeleccionado: Producto;
  cargandoLista: boolean;
  constructor(
    private productoService: ProductoService, 
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.mostrarLoading();
    this.productoService.obtenerProductos()
      .subscribe(resp=>{
        this.productos=resp.filter(p=>p.rubro.aerocombustible).filter(p=>p.activo); //|| p.rubro.combustible 
        this.productosFiltrados = this.productos;       
        this.cargandoLista = false;
        this.ocultarLoading();
      }, ()=>{
        this.cargandoLista = false;
        this.ocultarLoading();
      })
  }

  filtrarProductos(texto:string) {
    this.productosFiltrados = this.productos.filter(a => {
      let agregar = true;
      if (texto && texto.length>2) {
        agregar = a.nombreProducto.toLowerCase().indexOf(texto.toLowerCase()) > -1;
      }
      return agregar;
    });
  }

  mostrarDetalle(i: number) {
    this.prodSeleccionado = this.productos[i];
    this.productoSeleccionado.emit(this.productos[i]);     
  }

  mostrarLoading(){
    this.cargandoLista = true;
    this.spinner.show('spinnerGrafico');
  }

  ocultarLoading(){
    this.cargandoLista = false;
    this.spinner.hide('spinnerGrafico');
  }

}
