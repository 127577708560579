import { Component, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { GrupoDeTanques } from 'src/app/shared/models/stock/GrupoDeTanques';
import { MessageService } from 'src/app/shell/message.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NuevoGrupoComponent } from './nuevo-grupo/nuevo-grupo.component';
import { ModalConfirmComponent } from 'src/app/shared/generic-modals/modal-confirm.component';
import { NuevaIslaComponent } from './nueva-isla/nueva-isla.component';
import { ModalOkComponent } from 'src/app/shared/generic-modals/modal-ok.component';
import { UsuarioService } from 'src/app/account/usuario.service';
import { IslaService } from '../../islas.service';
import { TanqueService } from '../../tanques.service';
import { GrupoDeTanquesService } from '../../grupos.service';
import { Tanque } from 'src/app/shared/models/stock/Tanque';
import { ProductoService } from 'src/app/abm/producto/producto.service';
import { Producto } from 'src/app/shared/models/abm/Producto';
import { Subscription } from 'rxjs';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { forEach } from '@angular-devkit/schematics';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-grupos-tanques',
  templateUrl: './grupos-tanques.component.html',
  styleUrls: ['./grupos-tanques.component.css']
})
export class GruposTanquesComponent implements OnInit {

  TipoUsuario = TipoUsuario;
  
  productoSeleccionado: Producto;
  productos: Producto[] = [];
  grupos: GrupoDeTanques[] = [];
  gruposFiltrados: GrupoDeTanques[] = [];
  tanquesLibres: Tanque[] = [];
  islas: string[] = [];

  hayModificacion: boolean = false;
  change$: Subscription;

  procesandoIslas: boolean = false;
  procesandoProductos: boolean = false;
  procesandoGrupos: boolean = false;
  guardandoAsignaciones: boolean = false;

  constructor(
    private authService: AuthService, 
    private messageService: MessageService,
    private modalService: NgbModal,
    private gruposService: GrupoDeTanquesService,
    private tanquesService: TanqueService,
    private productoService: ProductoService,
    private usuarioService: UsuarioService,
    private islaService: IslaService,
    sharedService: SharedService
  ) {
    this.change$ = sharedService.aeroplantaSeleccionada.subscribe(_=>{
      this.cambiarDeAeroplanta()
    })
  }

  ngOnInit() {
    this.getTanquesLibres();
    this.getIslas();
    this.getProductos();
  }

  ngOnDestroy(): void {
    this.change$.unsubscribe(); 
  }

  cambiarDeAeroplanta() {
    this.ngOnInit()
  }

  cancelar()
  {
    this.modalService.dismissAll();
  }

  cambiarProducto(p: Producto) {
    this.productoSeleccionado = p;

    this.gruposFiltrados = this.filtrarGruposPorProductoActual();
  }

  filtrarGruposPorProductoActual(): GrupoDeTanques[] {
    if (this.productoSeleccionado == null) {
      return this.grupos;
    }

    if (this.grupos == null) {
      return [];
    }

    return this.grupos.filter(g => g.productoActual.codigoProducto == this.productoSeleccionado.codigoProducto)
  }

  getProductos() {
    this.procesandoProductos = true;
    this.productoService.obtenerProductos()
      .subscribe(result => {
        this.productos = result
        this.productos = this.productos.filter(p => p.rubro.aerocombustible || p.rubro.combustible);
        this.productos = this.productos.filter(a => a.activo)
        this.productoSeleccionado = this.productos[0];
        this.procesandoProductos = false;

        this.getGrupos();
      },
        () => {

        })
  }

  getGrupos() {
    this.procesandoGrupos = true;
    this.gruposService.getGrupoDeTanquesPorAeroplanta(this.usuarioService.getAeroplantaActual().codigoAeroplanta)
      .subscribe(result => {
        this.grupos = result;
        this.gruposFiltrados = this.filtrarGruposPorProductoActual();
        this.procesandoGrupos = false;
      },
        () => {
          this.messageService.showErrorMessage("Error al obtener grupos")
        })
  }

  agregarGrupo() {
    let modalRef = this.modalService.open(NuevoGrupoComponent, { centered: true });
    modalRef.componentInstance.productos = this.productos;
    modalRef.result.then((result: GrupoDeTanques) => {
      this.getGrupos();
    }, () => { });
  }

  editarGrupo(grupo: GrupoDeTanques) {
    if (this.hayModificacion) {
      let modalRef = this.modalService.open(ModalConfirmComponent, { centered: true });
      (<ModalConfirmComponent>modalRef.componentInstance).mensaje = "Debe guardar los cambios antes de modificar el grupo si no lo cambios se perderan, ¿desea continuar?"
      modalRef.result.then(() => {
        let modalRef = this.modalService.open(NuevoGrupoComponent, { centered: true });
        modalRef.componentInstance.grupo = grupo;
        modalRef.componentInstance.productos = this.productos;
        modalRef.result.then((result: GrupoDeTanques) => {
          this.getGrupos();
        }, () => { });
      });
    } else {
      let modalRef = this.modalService.open(NuevoGrupoComponent, { centered: true });
      modalRef.componentInstance.grupo = grupo;
      modalRef.componentInstance.productos = this.productos;
      modalRef.result.then((result: GrupoDeTanques) => {
        this.getGrupos();
      }, () => { });
    }
  }

  eliminarGrupo(g: GrupoDeTanques) {
    if (g.tanques.length > 0) {
      let modalRefConfirm = this.modalService.open(ModalOkComponent, { centered: true });
      modalRefConfirm.componentInstance.mensaje = "Para eliminar un grupo debe desasignar sus tanques primero.";
      modalRefConfirm.result.then(() => {
      });
      return;
    }

    let modalRef = this.modalService.open(ModalConfirmComponent, { centered: true });
    (<ModalConfirmComponent>modalRef.componentInstance).mensaje = "¿Está seguro de eliminar este grupo? Esta acción no se puede deshacer"
    modalRef.result.then(() => {
      this.procesandoIslas = true;
      this.gruposService.eliminarGrupo(g)
        .subscribe(result => {
          this.procesandoIslas = false;
          this.getGrupos();
        }, () => this.messageService.showErrorMessage("No puede eliminar grupos con surtidores asociados"));
    });
  }

  getTanquesLibres() {
    this.tanquesService.getTanquesLibresPorAeroplanta(this.usuarioService.getAeroplantaActual().codigoAeroplanta)
      .subscribe(result => {
        this.tanquesLibres = result
      },
        () => {
          this.messageService.showErrorMessage("Error al obtener tanques libres");
        })
  }

  getIslas() {
    this.procesandoIslas = true;
    this.islaService.getIslasPorAeroplanta(this.usuarioService.getAeroplantaActual().codigoAeroplanta)
      .subscribe(result => {
        this.islas = result
        this.procesandoIslas = false;
      },
        () => {
          this.messageService.showErrorMessage("Error al obtener islas");
        })
  }

  agregarIsla() {
    let modalRef = this.modalService.open(NuevaIslaComponent, { centered: true });
    modalRef.result.then((result: string) => {
      this.getIslas();
    }, () => { this.messageService.showErrorMessage("Error al guardar isla"); });
  }

  eliminarIsla(isla: string) {
    let modalRef = this.modalService.open(ModalConfirmComponent, { centered: true });
    (<ModalConfirmComponent>modalRef.componentInstance).mensaje = "¿Está seguro de eliminar esta isla? Esta acción no se puede deshacer"
    modalRef.result.then(() => {
      this.procesandoIslas = true;
      this.islaService.eliminarIsla(isla, this.usuarioService.getAeroplantaActual().codigoAeroplanta)
        .subscribe(result => {
          this.procesandoIslas = false;
          this.getIslas();
        }, () => this.messageService.showErrorMessage("Error al eliminar la isla"));
    });
  }

  guardarAsignaciones() {
    this.guardandoAsignaciones = true;
    let nombreDelGrupo = this.validarGrupos(this.grupos);
    if (nombreDelGrupo == null) {
      this.gruposService.asociarTanquesGrupos(this.grupos, this.usuarioService.getAeroplantaActual().codigoAeroplanta)
        .subscribe(result => {
         this.messageService.showSuccessMessage("Se guardaron las asignaciones.");
         this.guardandoAsignaciones = false;
         this.hayModificacion = false;
        },
         () => {
            this.messageService.showErrorMessage("Error al guardar asignaciones.");
            this.guardandoAsignaciones = false;
          });
    } else{
      this.messageService.showErrorMessage("No puede haber grupos sin tanques en " + nombreDelGrupo);
      this.guardandoAsignaciones = false;
    }
  }

  validarGrupos(grupos: GrupoDeTanques[]){
    let nombreGrupo;
    for(let i = 0; i < grupos.length; i++){
        if (grupos[i].tanques.length == 0) {
          nombreGrupo = grupos[i].productoActual.nombreProducto;
        }
    }
    return nombreGrupo;
  }
  onDrop(event: CdkDragDrop<string[]>) {
    if (this.productoSeleccionado == null) {
      this.messageService.showErrorMessage("Si se muestran todos los productos no se puede realizar asignaciones.");
      return;
    }

    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      this.hayModificacion = true;
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
      this.hayModificacion = true;
    }
  }

  hasRole(...roles: TipoUsuario[]) {
    return this.authService.hasRole(...roles);
  }
}
