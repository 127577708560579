import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { HandleError, HttpErrorHandler } from '../http-error-handler.service';
import { Laboratorio } from '../shared/models/despacho/Laboratorio';
import { Observable, of } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LaboratorioService {

  baseUrl = environment.apiServer.baseUrl;
  private laboratoriosUrl = this.baseUrl + '/LaboratoriosV1/';
  private handleError: HandleError;

  private laboratorios: Laboratorio[];

  constructor(private http: HttpClient, private httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError('Laboratorios');
  }

  getLaboratorios(): Observable<Laboratorio[]> {
    if (this.laboratorios) {
      return of(this.laboratorios);
    }
    else {
      return this.http.get<Laboratorio[]>(this.laboratoriosUrl)
        .pipe(
          map(a => a.map<Laboratorio>(b => Object.assign(new Laboratorio(), b))),
          tap(a => this.laboratorios = a)
        );
    }
  }
}
