export enum CalculoBaseImponible {
    /// B1 => Total facturado-IVA-IVA percecpion = 0
    /// B2 => Total facturado-IVA-IVA percepcion-ITC-Internos (incluye TGO, H, GLP, GNC, FF de gas) = 1
    /// B3 => Total facturado-IVA discriminado-IVA percecpion discriminado = 2
    /// B4 => Total facturado = 3
    /// B5 => Total facturado-IVA-IVA percecpion-Fondo Fiduciario de Gas = 4
    /// B6 => Total facturado-IVA discriminado-IVA percepcion discriminado-ITC discriminado-Internos discriminados (incluye TGO, H, GLP, GNC, FF de g = 5
    B1,
    B2,
    B3,
    B4    
}