<ul class="nav nav-tabs justify-content-start">
  <li class="nav-item" >
    <a class="nav-link" *ngIf="this.hasRole(TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.SUPERVISOR, TipoUsuario.IMPUESTOS, TipoUsuario.TUTOR, TipoUsuario.SEGURIDADTABLAS, TipoUsuario.SOPORTE )" routerLink="abmAeroplanta" routerLinkActive="active"><b>ABM</b></a>
  </li>
  <li class="nav-item">
    <a class="nav-link" *ngIf="this.hasRole(TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.SUPERVISOR, TipoUsuario.IMPUESTOS, TipoUsuario.TUTOR, TipoUsuario.SEGURIDADTABLAS, TipoUsuario.SOPORTE)"
      routerLink="caiAeroplanta" routerLinkActive="active"><b>CAI</b></a>
  </li>

</ul>
<router-outlet></router-outlet>