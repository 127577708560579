import { estadoEquipo } from "../../enums/estadoEquipo";

export class EstadoEquipo {
    id: string;
    estado: number;
    fechaHoraUltimoCambio: string;
    responsable: string;
    fechaHoraFS?: string;
    autorizoFS?: string;
    observaciones: string;
}