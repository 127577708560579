import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { NgbCalendar, NgbDatepickerConfig, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Producto } from 'src/app/shared/models/abm/Producto';
import { MessageService } from 'src/app/shell/message.service';
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript';

@Component({
  selector: 'app-consolidado',
  templateUrl: './consolidado.component.html',
  styleUrls: ['./consolidado.component.scss']
})
export class ConsolidadoComponent implements OnInit {

  productoSeleccionado : Producto;
  obteniendoDatos: boolean;
  busquedaFormControl = new UntypedFormControl();
  productoBuscado:string;

  constructor(
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
  }

  filtrarProductos() {
    this.productoBuscado = this.busqueda.value;
  }

  usuarioSeleccionoProducto(producto: Producto) {
    this.productoSeleccionado = producto;
  }

  mostrarLoading(){
    this.obteniendoDatos = true;
    this.spinner.show('spinnerGrafico');
  }

  ocultarLoading(){
    this.obteniendoDatos = false;
    this.spinner.hide('spinnerGrafico');
  }

  get busqueda() {
    return this.busquedaFormControl;

  }
}
