import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { UsuarioService } from 'src/app/account/usuario.service';
import { StockAeroespecialidad } from 'src/app/shared/models/stock/StockAeroespecialidad';
import { SharedService } from 'src/app/shared/shared.service';
import { StockService } from '../../stock.service';

@Component({
  selector: 'app-aeroespecialidades',
  templateUrl: './aeroespecialidades.component.html',
  styleUrls: ['./aeroespecialidades.component.css']
})
export class AeroespecialidadesComponent implements OnInit {
  aeroespecialidades: StockAeroespecialidad[] = [];
  aeroplantaCodActual: string;
  esperandoCarga: boolean;

  change$: Subscription;

  constructor(private stockService: StockService,
    private usuarioService: UsuarioService,
    private spinner: NgxSpinnerService,
    sharedService: SharedService) {
      this.change$ = sharedService.aeroplantaSeleccionada.subscribe(_=>{
        this.cambiarDeAeroplanta()
      })
    }

  // hooks

  ngOnInit() {    
    this.getAeroespecialidades();
  }

  ngOnDestroy() {
    this.change$.unsubscribe();    
    this.aeroespecialidades = [];
  }

  // form

  cambiarDeAeroplanta() {
    this.ngOnInit();
  }

  // llamadas al service

  getAeroespecialidades(){
    this.spinner.show('spinnerGrafico');
    this.esperandoCarga = true;
    this.stockService.getAeroespecialidades(this.usuarioService.getAeroplantaActual().codigoAeroplanta)
      .subscribe(result => {
        this.aeroespecialidades = result;
        this.spinner.hide('spinnerGrafico');
        this.esperandoCarga = false;
      });
  }

}
