import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tipoMovimiento'
})
export class TipoMovimientoPipe implements PipeTransform {

  transform(tipo: number): string {
    switch (tipo) {
      case (1):
      case (3):
      case (2): {
        return 'A'
      }
      case (6):
      case (8):
      case (7): {
        return 'B';
      }
      case (9): {
        return 'R';
      }
      case (19):
      case (20):
      case (21): {
        return 'E'
      }
    }
  }

}
