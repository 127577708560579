<nav class="navbar navbar-light bg-light navbar-expand-lg">
  <div class="container-fluid">
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <form class="d-flex" role="search">
        <input type="text" class="form-control" [formControl]="busquedaFormControl" (input)="filtrarComprobantes()" placeholder="Búsqueda" 
          ngbTooltip="Cliente/Comprobante"/>
      </form>

      <loading-text-spinner *ngIf="cargandoFiltros" message="Obteniendo filtros..."
        color="primary" class="ms-2">
      </loading-text-spinner> 

      <ul *ngIf="!cargandoFiltros" class="navbar-nav me-auto">
        
        <li class="nav-item ms-4 me-2">
          <div class="input-group">
            <input class="form-control form-control-rounded" name="dp" readonly type="date"
              [(ngModel)]="modelDesde" ngbDatepicker #d="ngbDatepicker" (change)="handleToggle()"/>
              <button class="btn btn-outline-primary" (click)="d.toggle()" type="button">
                <i class="fa fa-calendar" aria-hidden="true"></i>
              </button>
          </div>
        </li>

        <li class="nav-item">
          <div class="input-group">
            <input class="form-control form-control-rounded" name="dp" readonly type="date"
              [(ngModel)]="modelHasta" ngbDatepicker #h="ngbDatepicker" (change)="handleToggle()"/>
              <button class="btn btn-outline-primary" (click)="h.toggle()" type="button">
                <i class="fa fa-calendar" aria-hidden="true"></i>
              </button>
          </div>
        </li>

        <li class="nav-item">
          <button (click)="this.initialCharge()" class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2"
            type="button"><i class="fa fa-search" aria-hidden="true"></i>
          </button>
        </li>

        <!-- Aeroplantas -->
        <li class="nav-item ms-2">
          <div ngbDropdown autoClose="outside">
            <button class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2" id="dropdownForm1"
              ngbDropdownToggle>Aeroplantas</button>
            <div ngbDropdownMenu aria-labelledby="dropdownForm1">
              <div class="px-4 py-3">
                <div *ngFor="let aeroplanta of aeroplantaParaCheckear">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="{{ 'dropdownCheck' + aeroplanta.codigo}}"
                      [(ngModel)]="aeroplanta.seleccionado" name="{{aeroplanta.codigo}}" >
                    <label class="form-check-label text-nowrap" for="{{ 'dropdownCheck' + aeroplanta.codigo}}">
                      {{ aeroplanta.nombre}}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>
  
<ngb-alert type="danger" [dismissible]="false" *ngIf="fechaDesdeMayorQueHasta()==true">
  La fecha inicial debe ser anterior a la fecha final.
</ngb-alert>

<ngb-alert type="warning" *ngIf="!showLista && !showDetalle && this.facturas.length < 1">
  No se encontraron comprobantes emitidos para el rango de fechas seleccionado.
</ngb-alert>
  
<div class="container-fluid text-center">
  <div class="row">
    <div class="col col-4">
      <div class="spinner-container" *ngIf="showLista">
        <ngx-spinner name="spinnerLista" bdOpacity=0.3bd
          Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
          <p style="color: white"> C a r g a n d o . . . </p>
        </ngx-spinner>
      </div>

      <div *ngIf="!showLista" class="list-group py-3 menu-izq"
          id="scroll-infinito"
          infinite-scroll
          [infiniteScrollDistance]="distance"
          [infiniteScrollThrottle]="throttle"
          [scrollWindow]="false"
          [infiniteScrollDisabled]="this.showScroll || this.stopScroll" 
          (scrolled)="onScrollDown()"
        >
                 
        <div> <!-- *ngIf="this.facturas!=null"-->
          <div *ngFor="let item of facturasFiltradas; trackBy:trackByFn">
            <button (click)="onClick(item)" class="list-group-item list-group-item-action">
              
              <div class="d-flex w-100 justify-content-between">
                <p><b>{{ item.tipoMovimiento | nombreTipoComprobante:condicionVta }} {{ item.tipoMovimiento | tipoMovimiento }} - N°: {{ item.prefijo | facturaPrefijo }}-{{ item.numeroComprobante | facturaNumero }}</b></p>
                <p>{{ item.fechaEmision | date: "d'/'MM'/'y'" }}</p>
              </div>

              <p class="mb-3" style="text-align: left;"> {{ item.razonSocialCliente }} </p>
              
              <ng-template #detail let-clases="clases">
                <p class="mb-0" [ngClass]="clases"><b>Aerovales incluidos: </b>{{ (item.aerovales.length > 0) ? item.aerovales : "-" }}</p>
                <p class="mb-0" [ngClass]="clases"><b>Matriculas involucradas: </b>{{ (item.matriculas.length > 0) ? item.matriculas : "-" }}</p>
              </ng-template>

              <div class="row">
                <div class="col-11" style="font-size: 13px; text-align: start;">
                  <ng-container *ngTemplateOutlet="detail; context: { clases: 'text-truncate' }"></ng-container>
                </div>
                <div class="col-1" *ngIf="item.aerovales.length >= 50 || item.matriculas.length >= 53">
                  <!--$event.stopPropagation() => Detiene la propagación del evento (click) button popover del evento onClick(item) linea 99 -->
                  <button class="btn btn-sm" [popoverTitle]="titulo" [ngbPopover]="contenido" (click)="$event.stopPropagation()">
                    <i ngbTooltip="Ver información completa" class="fa fa-eye" aria-hidden="true"></i>    
                  </button>
              
                  <ng-template #titulo>Información completa:</ng-template>
                  <ng-template #contenido>
                    <ng-container *ngTemplateOutlet="detail; context: { clases: '' }"></ng-container>
                  </ng-template>
                </div>
              </div>
            </button>
          </div> 
        </div>
        <div *ngIf="showScroll" class="d-flex align-items-center justify-content-center">
          <div class="spinner-border" role="status"></div><span>Cargando mas datos..</span>
        </div> 
      </div>
    </div>
    <div class="col col-6 py-3">
      <app-visor-comprobante *ngIf="!showDetalle && this.facturaObtenida!=null" id="invoicingPrint"
        [facturaObtenida]="this.facturaObtenida"
        [botones]="false"
      ></app-visor-comprobante>
      <div class="spinner-container" *ngIf="showDetalle">
        <ngx-spinner name="spinnerDetalle" bdOpacity=0.3bd
          Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
          <p style="color: white"> {{ messageChange }} </p>
        </ngx-spinner>
      </div>
    </div>
    <div class="col col-2 py-3 ms-0 me-0 ps-0 pe-0">
      <nav *ngIf="this.facturaObtenida" class="navbar navbar-light bg-light btn-group-vertical">
        
        <button *ngIf="facturaObtenida.id != null" class="btn btn-outline-secondary mt-2" type="button"
          styleSheetFile="assets/css/print.css,assets/css/bootstrap.min.css"
          printTitle="{{this.facturaObtenida.prefijo}}-{{this.facturaObtenida.numeroComprobante}} {{this.facturaObtenida.fechaHoraCreacion}}"
          [printStyle]="{'#invoicingPrint': { 'border': 'none' , 'margin-top': 0, 'margin-left': 1 }}"
          printSectionId="invoicingPrint" useExistingCss="true" ngxPrint>
          <i class="fa fa-print" aria-hidden="true"></i> Imprimir
        </button>

        <button *ngIf="facturaObtenida?.factura!=null && facturaObtenida?.factura?.remitos?.length>0" 
          class="btn btn-outline-secondary mt-2" type="button" (click)="onClickExport(this.facturaObtenida)">
          <i class="fa fa-file-excel-o" aria-hidden="true"></i> Exportar remitos
        </button>

        <button type="button" class="btn btn-outline-secondary mt-2" (click)="onClickView(this.facturaObtenida)">
          <i class="fa fa-usd" aria-hidden="true"></i> Ver Impuestos
        </button>

        <button *ngIf="this.facturaObtenida.factura.linkPDFOriginal == null" 
          type="button" class="btn btn-outline-secondary mt-2" (click)="onClickDownload(this.facturaObtenida)">
          <i class="fa fa-download" aria-hidden="true"></i> Descargar comprobante
        </button>

        <a *ngIf="this.facturaObtenida.factura.linkPDFOriginal!=null" class="btn btn-outline-secondary mt-2" 
          href="{{this.facturaObtenida.factura.linkPDFOriginal}}">
          <i class="fa fa-download" aria-hidden="true"></i> Descargar comprobante original
        </a>

        <button
          class="btn btn-outline-secondary mt-2" type="button" (click)="resendEmail(this.facturaObtenida)">
          <i class="fa fa-share" aria-hidden="true"></i> Reenviar comprobante
        </button>
      </nav>
    </div>
  </div>
</div>

<div class="modal fade" id="detail" tabindex="-1" role="dialog" aria-labelledby="miModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="miModalLabel">Aerovales y matriculas involucradas</h5>
      </div>
      <div class="modal-body">
        <p class="mb-0"><b>Aerovales incluidos: </b>{{ facturaObtenida?.factura?.numerosAerovales}}</p>
        <p class="mb-0"><b>Matriculas involucradas: </b>{{ facturaObtenida?.factura?.patentes}}</p>
      </div>
    </div>
  </div>
</div>