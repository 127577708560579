<div *ngIf="!buscandoComprobante" class="card">
  <div class="card-body" id="aerovaleRemitoPrint">
    <div class="d-flex justify-content-between">
      <div class="p-2">
        <img id="logo-ypf" src="../../../assets/images/logoypf.png" />
      </div>
      <div class="p-2 text-center">
        <div class="col-md-auto border text-center line-small">
          <p class="h3">R</p>
        </div>
        <h6>{{ getTipoAerovale(aerovaleDetalle.tipoAerovale) }}</h6>
      </div>
      <div class="p-2 text-end me-2">N°:
        <b>{{ remitoFox.prefijoFox | remitoPrefijo }} - {{ remitoFox.numeroFox | remitoNumero }}</b>
        <br> Fecha:
        <b>{{ aerovaleDetalle.fechaHoraFinDespacho | date: "d'/'MM'/'y' HH:mm a" }} </b>
      </div>
    </div>

    <!--EMISOR-->
    <div class="row col-12" style="padding-right: 0px;">
      <ul class="list-unstyled js col-6 text-start">
        <li class="letra">{{remitoFox.emisor.razonSocial}}</li>
        <li class="letra">
          {{remitoFox.emisor.direccion}}&nbsp;{{remitoFox.emisor.ciudad.nombre}}&nbsp;{{remitoFox.emisor.ciudad.codigoPostal}}
        </li>
        <li class="letra">
          Capital Federal,&nbsp;Argentina
        </li>
        <li class="letra">T.E.: {{remitoFox.emisor.telefono}}</li>
        <li class="letra">{{remitoFox.emisor.email}}</li>
      </ul>
      <ul class="list-unstyled js col-6 text-end" style="padding-right: 0px;">
        <li class="letra">Original</li>
        <li class="letra">CUIT: {{remitoFox.emisor.numeroDocumento}}</li>
        <li class="letra">IIBB CM {{remitoFox.emisor.numeroIIBB}}</li>
        <li class="letra">Inicio Actividades:
          {{remitoFox.emisor.fechaInicioActividades | date: 'dd/MM/yyyy'}}</li>
        <li class="letra">RESPONSABLE INSCRIPTO</li>
      </ul>
    </div>
    <hr>



    <div *ngIf="aerovaleDetalle.origen" class="d-flex flex-column">
      <div class="p-2">Aeroplanta: <b> {{ aerovaleDetalle.origen.nombre }} </b> </div>
    </div>
    <div class="d-flex flex-column">
      <div class="p-2">Cliente: <b>{{ aerovaleDetalle.cliente.razonSocial }} </b> </div>
    </div>
    <div class="d-flex flex-column">
      <div class="p-2">Nro. cuenta: <b>{{ aerovaleDetalle.cliente.numeroCuenta }} </b> </div>
    </div>
    <div class="d-flex">
      <div>
        <div class="d-flex ">

          <div *ngIf="aerovaleDetalle.vehiculo" class="p-2">Matrícula: <b>{{ aerovaleDetalle.vehiculo.matricula }} </b>

          </div>
          <div *ngIf="aerovaleDetalle.vuelo" class="p-2">Vuelo:
            <b>{{ aerovaleDetalle.vuelo ? aerovaleDetalle.vuelo : '----' }}
            </b>
          </div>

        </div>
        <div class="d-flex">
          <div *ngIf="aerovaleDetalle.detalleAerocombustible && !esAlije()" class="p-2">Destino:
            <b>{{this.getTipoVuelo(this.aerovaleDetalle.detalleAerocombustible.tipoVuelo).toUpperCase()}}</b>

            <b *ngIf="aerovaleDetalle.detalleAerocombustible.destino"> -
              [{{ aerovaleDetalle.detalleAerocombustible.codigoAeropuerto }}]
              {{aerovaleDetalle.detalleAerocombustible.destino }}
            </b>
          </div>
        </div>
        <div class="d-flex">
          <div class="p-2" *ngIf="!esAlije()">Condición de Venta: <b>{{ this.getTipoPago(aerovaleDetalle.tipoPago) }}
            </b> </div>
        </div>
        <div class="d-flex">
          <div *ngIf="aerovaleDetalle.detalleAerocombustible && !esAlije()" class="p-2">Rancho N°:
            <b>{{ aerovaleDetalle.detalleAerocombustible.rancho? aerovaleDetalle.detalleAerocombustible.rancho : '----'
              }}
            </b>
          </div>
        </div>
      </div>
      <div *ngIf="aerovaleDetalle.estado === 'Anulado'" class="me-auto">
        <img id="logo-ypf" src="../../../assets/images/Anulado.png" />
      </div>
    </div>
    <hr>
    <div class="d-flex">
      <div *ngIf="aerovaleDetalle.operadorDTO" class="me-auto p-2">Operador:
        <b>{{ aerovaleDetalle.operadorDTO.nombreCompleto }} </b>
      </div>
      <div *ngIf="aerovaleDetalle.operadorDTO" class="p-2">Documento Nº:
        <b>{{ aerovaleDetalle.operadorDTO.dni ? aerovaleDetalle.operadorDTO.dni : '----' }} </b>
      </div>
    </div>
    <div class="d-flex flex-column">
      <div *ngIf="aerovaleDetalle.abastecedora" class="p-2">Abastecedora interna Nº:
        <b>{{ aerovaleDetalle.abastecedora.nombre }}
        </b>
      </div>
    </div>
    <div class="d-flex flex-column">
      <div class="p-2">Observaciones:
        <b *ngIf="ObserNormal.length == 0">----</b>
        <b *ngFor="let observacion of ObserNormal">{{ observacion.nombre }} </b>
      </div>
    </div>
    <div class="d-flex flex-column" *ngIf="aerovaleDetalle.estado === 'Anulado'">
      <div class="p-2 text-danger">Observaciones de anulación:
        <b *ngFor="let observacionA of ObserAnulado" class="text-danger">{{ observacionA.nombre }}</b>
      </div>
    </div>
    <hr>
    <div class="d-flex flex-column"
      *ngIf="aerovaleDetalle.tipoAerovale == enumTipoAerovale.Aerocombustible || aerovaleDetalle.tipoAerovale == enumTipoAerovale.Alije">
      <div class="p-2">Hora de llegada: <b>{{ aerovaleDetalle.fechaHoraLlegada | date: "hh:mm a" }} </b> </div>
    </div>
    <div class="d-flex flex-column">
      <div class="p-2">Hora de inicio: <b>{{ aerovaleDetalle.fechaHoraInicioDespacho | date: "hh:mm a"}} </b> </div>
    </div>
    <div class="d-flex flex-column">
      <div class="p-2">Hora de finalizacion: <b>{{ aerovaleDetalle.fechaHoraFinDespacho | date: "hh:mm a" }} </b>
      </div>
    </div>
    <div *ngIf="aerovaleDetalle.detalleAerocombustible" class="d-flex justify-content-between">
      <div class="p-2">Prueba de Agua: <b>{{ aerovaleDetalle.detalleAerocombustible.pruebaAgua? 'NEG' : 'POS' }}</b>.
      </div>
      <div class="p-2">Temperatura: <b>{{ aerovaleDetalle.detalleAerocombustible.temperatura | number : '0.1-1' }} °C
        </b></div>
      <div class="p-2">Densidad: <b>{{ aerovaleDetalle.detalleAerocombustible.densidad.toFixed(3) }} g/cm3 </b> </div>
    </div>
    <div *ngIf="aerovaleDetalle.detalleAerocombustible" class="d-flex justify-content-between">
      <div class="p-2">Volumen 15º: <b>{{ aerovaleDetalle.detalleAerocombustible.volumen15grados | number : '0.0-0' }}
          LTS </b></div>
    </div>


    <div *ngIf="aerovaleDetalle.tipoAerovale===2" class="d-flex justify-content-between">
      <div class="p-2">Volumen 15º: <b> {{aerovaleDetalle.detalleCombustible.volumen15grados}} LTS </b>.
      </div>
      <div class="p-2">Temperatura: <b>{{ aerovaleDetalle.detalleCombustible.temperatura}}°C
        </b></div>
      <div class="p-2">Densidad: <b> {{ aerovaleDetalle.detalleCombustible.densidad.toFixed(3)}} g/cm3 </b> </div>
    </div>

    <hr>
    <div *ngIf="aerovaleDetalle.tipoAerovale == 3">
      <div class="me-auto p-2">LABORATORIO</div>
      <div class="d-flex justify-content-between">
        <div class="p-2">Fecha:
          <b>{{aerovaleDetalle.detalleAerocombustible.aerovaleDetalleAlije.fechaPruebaLaboratorio | date: "dd/MM/y"
            }}</b>
        </div>
        <div class="p-2">Código de prueba:
          <b>{{aerovaleDetalle.detalleAerocombustible.aerovaleDetalleAlije.codigoPruebaLaboratorio}}</b>
        </div>
        <div class="p-2">
          <b>{{
            getEstadoPruebaLaboratorio(aerovaleDetalle.detalleAerocombustible.aerovaleDetalleAlije.estadoPruebaLaboratorio)
            }}</b>
        </div>
      </div>
      <hr>
    </div>
    <div *ngIf="aerovaleDetalle.tipoAerovale===0 || aerovaleDetalle.tipoAerovale===2">
      <div class="d-flex">
        <div class="me-auto p-2">TOTALIZADOR INICIO: </div>
        <div class="p-2">{{ obtenerAforadorInicioDeAerovale() | number : '0.0-0' }}</div>
      </div>
      <div class="d-flex">
        <div class="me-auto p-2">TOTALIZADOR FINAL: </div>
        <div class="p-2">{{ obtenerAforadorFinDeAerovale() | number : '0.0-0' }}</div>
      </div>
      <div class="d-flex">
        <div class="me-auto p-2">VOLUMEN BRUTO FINAL LTS: </div>
        <div class="p-2">{{ aerovaleDetalle.volumen | number : '0.0-0' }}</div>
      </div>
      <div *ngIf="aerovaleDetalle.tipoAerovale===3">
        <div class="d-flex">
          <div class="me-auto p-2">Aforador Inicial: </div>
          <div class="p-2">
            {{ aerovaleDetalle.detalleAerocombustible.aerovaleDetalleAlije.varilladoInicio | number : '0.0-0' }}</div>
        </div>
        <div class="d-flex">
          <div class="me-auto p-2">Aforador Final: </div>
          <div class="p-2">
            {{ aerovaleDetalle.detalleAerocombustible.aerovaleDetalleAlije.varilladoFinal | number : '0.0-0' }}</div>
        </div>
      </div>
    </div>
    <hr *ngIf="aerovaleDetalle.detalleAerocombustible">
    <div *ngIf="aerovaleDetalle.producto" class="d-flex bg-dark text-white">
      <div class="p-2 col col-md-6">
        <b>Producto</b>
      </div>
      <div class="p-2 col col-md-3 text-end">
        <b>Código</b>
      </div>
      <div class="p-2 col col-md-3 text-end">
        <b
          *ngIf="aerovaleDetalle.detalleAerocombustible && aerovaleDetalle.detalleAerocombustible.aerovaleDetalleAlije; else abastecida">
          Cant. de alije</b>
        <ng-template #abastecida>
          <b>Cant. abastecida</b>
        </ng-template>
      </div>
    </div>
    <div *ngIf="aerovaleDetalle.producto" class="d-flex">
      <div class="p-2 col col-md-6">
        {{ aerovaleDetalle.producto.nombreProducto }}
      </div>
      <div class="p-2 col col-md-3 text-end">
        {{ aerovaleDetalle.producto.codigoProducto }}
      </div>
      <div class="p-2 col col-md-3 text-end">
        {{ aerovaleDetalle.volumen | number : '0.0-0'}} LTS
      </div>
    </div>
    <div *ngIf="aerovaleDetalle.aeroespecialidades && aerovaleDetalle.aeroespecialidades.length > 0"
      class="d-flex bg-dark text-white">
      <div class="p-2">
        <b>Aeroespecialidades</b>
      </div>
    </div>
    <div *ngFor="let aesp of aerovaleDetalle.aeroespecialidades" class="d-flex">
      <div class="me-auto p-2"> {{ aesp.nombreProducto }}: </div>
      <div class="p-2">{{ aesp.cantidad }} unidad/es</div>
    </div>
    <hr>
    <div *ngIf="aerovaleDetalle.comentarios">
      <div class="form-check">
        <input type="checkbox"  [(ngModel)]="mostrarComentario" class="form-check-input" id="defaultChecked2"/>
        <label class="form-check-label" for="defaultChecked2" style="font-weight: bold; padding-left: 0.5em;">Imprimir comentario</label>
      </div>
      <div *ngIf="mostrarComentario" class="d-flex flex-column">
        <div class="p-2">Comentarios:
        </div>
        <div class="p-2 me-2">
          <b>{{ aerovaleDetalle.comentarios }}</b>
        </div>
      </div>
    </div>
    <hr *ngIf="aerovaleDetalle.comentarios && mostrarComentario">
    <ng-container>
      <div class="d-flex justify-content-between" *ngIf="this.esContingencia===false && mostrarFirma()===true">
        <ng-container *ngIf="!this.esReemplazo">
          <img
            *ngIf="this.aerovaleDetalle.firmaOperador!=null || this.aerovaleDetalle.firmaOperador!='' || this.aerovaleDetalle.pathFirmaOperador!=null || this.aerovaleDetalle.pathFirmaOperador!=''"
            class="flex-fill w-50 img-fluid print-resize" [src]="this.ImagenFirmaOperador()" />
          <img *ngIf="this.aerovaleDetalle.firmaCliente!=null || this.aerovaleDetalle.pathFirmaCliente!=null"
            class="flex-fill w-50 img-fluid print-resize" [src]="this.ImagenFirmaCliente()" />
        </ng-container>
      </div>
      <div class="d-flex justify-content-between" *ngIf="(this.esContingencia===true || this.esReemplazo===true) && mostrarFirma()===true">
        <img
          *ngIf="this.aerovaleDetalle.firmaOperador!=null || this.aerovaleDetalle.firmaOperador!='' || this.aerovaleDetalle.pathFirmaOperador!=null || this.aerovaleDetalle.pathFirmaOperador!=''"
          class="flex-fill w-50 img-fluid print-resize" [src]="this.ImagenFirmaOperador()" />
      </div>
      <div class="d-flex justify-content-between" *ngIf="this.esContingencia===false && this.esReemplazo===false && mostrarFirma()===true">

        <div *ngIf="ImagenFirmaOperador()!=''" class="flex-fill w-50  text-center">Firma del operador</div>

        <div *ngIf="this.aerovaleDetalle.firmaCliente!=null || this.aerovaleDetalle.pathFirmaCliente!=null"
          class="flex-fill w-50  text-center">Firma del cliente</div>

      </div>

      <div class="d-flex justify-content-between" *ngIf="(this.esContingencia===true || this.esReemplazo===true)  && mostrarFirma()===true">

        <div *ngIf="ImagenFirmaOperador()!=''" class="flex-fill w-50  text-center">Remito Físico</div>

      </div>

      <br>
      <div class="row col-12" *ngIf="!this.obteniendoAeroplanta">
        <ul class="list-unstyled text-start col-6">
          <li>{{remitoFox.emisor.imprentaRazonSocial}}</li>
          <li>CUIT: {{remitoFox.emisor.imprentaCUIT}}</li>
          <li>Fecha Impresión: {{aerovaleDetalle.fechaHoraFinDespacho | date: "d'/'MM'/'y' "}}</li>
        </ul>
        <ul class="list-unstyled text-end col-6" style="padding-right: 0px;">
          <li>CAI: {{remitoFox.cai}}</li>
          <li>Fecha venc.: {{aeroplanta[0].fechaVencimientoCAI | date: 'dd/MM/yyyy'}}</li>
        </ul>
      </div>
      <div class="text-center"><small><b>Aerovale original generado por Sistema Electronico</b></small></div>
    </ng-container>
  </div>
  <div class="card-footer">
    <button (click)="print()" class="btn btn-outline-secondary mb-2 print" type="button"><i class="fa fa-print"
        aria-hidden="true"></i> Imprimir</button>
  </div>
</div>