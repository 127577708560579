import { Component, OnInit } from '@angular/core';
import { NgbCalendar, NgbDatepickerConfig, NgbDateStruct, NgbModal, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subscription } from 'rxjs';
import { UsuarioService } from 'src/app/account/usuario.service';
import { Balance7ActaDocumentosDTO } from 'src/app/shared/models/cierreTurno/Balance7ActaDocumentosDTO';
import { FechaDTO } from 'src/app/shared/models/cierreTurno/Balance7ActaDTO';
import { Balance7ComparativaEncabezadoDTO } from 'src/app/shared/models/cierreTurno/Balance7ComparativaEncabezadoDTO';
import { Balance7MedicionesDTO } from 'src/app/shared/models/cierreTurno/Balance7MedicionesDTO';
import { Balance7ActaDTO } from 'src/app/shared/models/cierreTurno/FechaDto';
import { ReporteMovimientoBalance7 } from 'src/app/shared/models/cierreTurno/ReporteMovimientoBalance7';
import { Aeroplanta } from 'src/app/shared/models/despacho/Aeroplanta';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { SharedService } from 'src/app/shared/shared.service';
import { MessageService } from 'src/app/shell/message.service';
import { CierreTurnoService } from '../cierre-turno.service';
@Component({
  selector: 'app-reporte-balance7',
  templateUrl: './reporte-balance7.component.html',
  styleUrls: ['./reporte-balance7.component.scss'],
})
export class ReporteBalance7Component implements OnInit {

  model: NgbDateStruct;
  model1: NgbDateStruct;
  fechaDesde: Date;
  fechaHasta: Date;
  change$: Subscription;

  obteniendoDatos: boolean ;
  mostrarContenedorDatos: boolean ;

  mediciones: Balance7MedicionesDTO;
  comparativas: Balance7ComparativaEncabezadoDTO;
  movimientos: ReporteMovimientoBalance7[];
  
  datosGeneralesActa: Balance7ActaDTO[];
  datosFacturacionActa: Balance7ActaDocumentosDTO[];

  reporteSeleccionado: number = -1;

  constructor(private datepickerConfig2: NgbDatepickerConfig,
    private cierreTurnoService: CierreTurnoService,
    private usuarioService: UsuarioService,
    private calendar: NgbCalendar,
    private spinner: NgxSpinnerService,
    private messageService: MessageService,
    sharedService: SharedService) {
      this.change$ = sharedService.aeroplantaSeleccionada.subscribe(_=>{ 
        this.cambiarDeAeroplanta()
      });
  }

  ngOnInit() {

    this.model = this.calendar.getToday();
    this.model1 = this.calendar.getToday();
    let inicio: any = { day: 1, month: 1, year: 2019 };
    this.datepickerConfig2.minDate = inicio;
    let fin: any = { day: this.model.day, month: this.model.month, year: this.model.year };
    this.model1 = this.calendar.getToday();
    this.datepickerConfig2.maxDate = fin;
  }

  ngOnChanges(): void {
    this.ngOnInit();
  }

  cambiarDeAeroplanta() {
    this.ngOnInit();
  }

  ngOnDestroy() {
    this.change$.unsubscribe();
  }
  
  formatDate(year, month, day, hour, minute, seconds): string {
    return year + '-' + month.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + '-' + day.toLocaleString(undefined, { minimumIntegerDigits: 2 })
      + 'T' + hour.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + minute.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + seconds.toLocaleString(undefined, { minimumIntegerDigits: 2 });
  }

  accionReiniciarBusqueda() {    
    this.setearFechaDesdeHasta();
    this.mostrarContenedorDatos = true    
  }

  setearFechaDesdeHasta() {
    this.fechaDesde = new Date(this.model.year, this.model.month - 1, this.model.day);
    this.fechaHasta = new Date(this.model1.year, this.model1.month - 1, this.model1.day);
    this.fechaHasta.setHours(23, 59, 59, 99);
  }

  getCodigoAeroplanta(){
    return this.usuarioService.getAeroplantaActual().codigoAeroplanta;
  }

  getFechaDesde(){
    return this.formatDate(this.fechaDesde.getFullYear(), this.fechaDesde.getMonth() + 1, this.fechaDesde.getDate(), 0, 0, 0);
  }

  getFechaHasta(){
    return this.formatDate(this.fechaHasta.getFullYear(), this.fechaHasta.getMonth() + 1, this.fechaHasta.getDate(), 23, 59, 59);
  }

  mostrarUltimasMediciones(){
    this.mostrarLoading();
    this.reporteSeleccionado = 1;

    this.cierreTurnoService.getUltimasMedicionesBalance7(this.getCodigoAeroplanta(), this.getFechaDesde(), this.getFechaHasta())
      .subscribe(result => {
        this.mediciones = result;
        this.mediciones.medicionesContenedores.sort((a , b) => a.nombreProducto.localeCompare(b.nombreProducto) || a.nombreContenedor.localeCompare(b.nombreContenedor) );
        this.mediciones.medicionesAeroespecialidades.sort((a , b) => a.nombreProducto.localeCompare(b.nombreProducto) );
        this.ocultarLoading();
      }, (err: ErrorModel) => {
        this.messageService.showErrorMessage('Hubo un error al obtener los datos. ' + err.description);
        this.mediciones = null;
        this.ocultarLoading();
      });
  }  
  
  mostrarComparativas(){
    this.mostrarLoading();
    this.reporteSeleccionado = 2;

    this.cierreTurnoService.getComparativasBalance7(this.getCodigoAeroplanta(), this.getFechaDesde(), this.getFechaHasta())
      .subscribe(result => {
        this.comparativas = result;
        this.ocultarLoading();
      }, (err: ErrorModel) => {
        this.messageService.showErrorMessage('Hubo un error al obtener los datos. ' + err.description);
        this.comparativas = null;
        this.ocultarLoading();
      });
  }    

  mostrarReporteMovimientos(){
    this.reporteSeleccionado = 3;
  } 
  
  fechasValidasParaActa(){
    if(this.fechaDesde.getFullYear() == this.fechaHasta.getFullYear() && this.fechaDesde.getMonth() == this.fechaHasta.getMonth()){
      return true;
    }else{
      this.messageService.showErrorMessage("El mes de fecha desde no es el mismo que el mes de fecha hasta. Esto es necesario para generar el acta.");
      return false;
    }    
  }

  mostrarGenerarActa(){
    if( ! this.fechasValidasParaActa()){      
      return;
    }
    
    this.mostrarLoading();
    this.reporteSeleccionado = 4;

    this.cierreTurnoService.getDatosGeneralesActaBalance7(this.getCodigoAeroplanta(), this.getFechaDesde(), this.getFechaHasta())
      .subscribe(result => {
        this.datosGeneralesActa = result;

        this.obtenerDatosFechaBalance7();

      }, (err: ErrorModel) => {
        this.messageService.showErrorMessage('Hubo un error al obtener los datos. ' + err.description);
        this.datosGeneralesActa = null;
        this.ocultarLoading();
      });
  }

  obtenerDatosFechaBalance7(){
    this.cierreTurnoService.getDatosFechaActaBalance7(this.getCodigoAeroplanta(), this.getFechaDesde(), this.getFechaHasta())
    .subscribe(result => {
      var datosFechaActa = result;

      this.obtenerDatosFacturacionBalance7(datosFechaActa.fechaHasta, datosFechaActa.fechaDesde);

    }, (err: ErrorModel) => {
      this.messageService.showErrorMessage('Hubo un error al obtener los datos. ' + err.description);
      this.ocultarLoading();
    });
  }

  obtenerDatosFacturacionBalance7(fechaHasta: string, fechaDesde: string){
    this.cierreTurnoService.getDatosFacturacionActaBalance7(this.getCodigoAeroplanta(), fechaHasta, fechaDesde)
    .subscribe(result => {
      this.datosFacturacionActa = result;

      this.ocultarLoading();

    }, (err: ErrorModel) => {
      this.messageService.showErrorMessage('Hubo un error al obtener los datos. ' + err.description);
      this.datosFacturacionActa = null;
      this.ocultarLoading();
    });
  }

  mostrarLoading(){
    this.obteniendoDatos = true;
    this.spinner.show('spinnerGrafico');
  }

  ocultarLoading(){
    this.obteniendoDatos = false;
    this.spinner.hide('spinnerGrafico');
  }

  toggleDatepicker(e: any){
    this.reporteSeleccionado = -1;
    this.mostrarContenedorDatos = false;

    e.toggle();
  }

}
