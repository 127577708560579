import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { refCount } from 'rxjs/operators';
import { ExportExcelServiceService } from 'src/app/shared/exportar-excel/export-excel-service.service';
import { CierreTurno } from 'src/app/shared/models/cierreTurno/CierreTurno';
import { ReporteMovimiento } from 'src/app/shared/models/cierreTurno/ReporteMovimiento';
import { AerovaleNumeroPipe } from 'src/app/shared/pipes/aerovale-numero.pipe';
import { AerovalePrefijoPipe } from 'src/app/shared/pipes/aerovale-prefijo.pipe';
import { RemitoNumeroPipe } from 'src/app/shared/pipes/remito-numero.pipe';
import { RemitoPrefijoPipe } from 'src/app/shared/pipes/remito-prefijo.pipe';
import { StockService } from 'src/app/stock/stock.service';

@Component({
  selector: 'app-dettale-movimientos-cierre',
  templateUrl: './detalle-movimientos-cierre.component.html',
  styleUrls: ['./detalle-movimientos-cierre.component.css']
})
export class DettaleMovimientosCierreComponent implements OnInit {
  
  @Input() cierreSeleccionado: CierreTurno;
  @Input() reporteSeleccionado: number;
  @Input() movimientosCierre: ReporteMovimiento[];
  @Input() generandoReporte: boolean;
  @Output() termino: EventEmitter<boolean> = new EventEmitter();

  @Input() 
  set ejecutarStock(b: boolean){
    if (b){
      this.exportar()
    }
    this.termino.emit(true);
  }

  // movimientosCierre: ReporteMovimiento[] = [];
  cargando: boolean = false;
  contenedores = [];

  constructor(private stockService: StockService, 
    private spinner: NgxSpinnerService, 
    private excelService: ExportExcelServiceService,
    private aerovalePrefijoPipe: AerovalePrefijoPipe,
    private aerovaleNumeroPipe: AerovaleNumeroPipe,
    private remitoPrefijoPipe: RemitoPrefijoPipe,
    private remitoNumeroPipe: RemitoNumeroPipe) { }

  ngOnInit() {
    if (this.cierreSeleccionado != null) {
      this.buscarDetalleMovimientos();
    }
  }

  ngOnChanges() {
    this.ngOnInit();
  }

  buscarDetalleMovimientos() {
    this.cargando = true;
    this.contenedores = [];
    this.spinner.show('spinnerGrafico');
    for (let prod of this.movimientosCierre) {
      if (!this.contenedores.find(a => a.name == prod.codigoContenedor + '-' + prod.nombreContenedor)) {
        this.contenedores.push({
          name: prod.codigoContenedor + '-' + prod.nombreContenedor,
          seleccionado: true
        })
      }
    }
    this.spinner.hide('spinnerGrafico');
    this.cargando = false;
  }

  seleccionarTodos(e){
    if(e.target.checked){
      for(let c of this.contenedores){
        c.seleccionado = true;
      }
    } else {
      for(let c of this.contenedores){
        c.seleccionado = false;
      }
    }
  }

  arregloDistintoCont(dist: string):ReporteMovimiento[]{
    let comp : ReporteMovimiento[] = [];    
    for(let p of this.movimientosCierre){
      if(p.codigoContenedor + '-' + p.nombreContenedor == dist){
        // comp.push(new Comparativa());
        comp.push(p)
      }
    }
    return comp
  }

  armarArrayExcel(){
    var listaReporte : ReporteRanchoCierre[] = [];
    
    for(let r of this.movimientosCierre){     
      var item = new ReporteRanchoCierre();
      item.producto = r.codigoProducto + "-" + r.nombreProducto;
      item.fecha = r.fechaHora;
      item.titulo = r.titulo;
      item.entradaNatural = r.entradaNatural? r.entradaNatural : 0;
      item.salidaNatural = r.salidaNatural? r.salidaNatural : 0;
      item.entrada15 = r.entrada15? r.entrada15 : 0;
      item.salida15 = r.salida15? r.salida15 : 0;
      item.stockNatural = r.stock? r.stock : 0;
      item.stock15 = r.stock15? r.stock15 : 0;
      item.contenedor = "("+r.codigoContenedor+") " + r.nombreContenedor;        

      if(r.comprobante == null){
        item.comprobante = "-";
      }else if(r.comprobante.remito != null){
        item.comprobante = "AEV-" + this.aerovalePrefijoPipe.transform(r.comprobante.remito.aerovalePrefijo)+"-"+this.aerovaleNumeroPipe.transform(r.comprobante.remito.aerovaleNumero);
      }else if(r.comprobante.remitoCargamento != null){
        //item.comprobante = "REM-" + r.comprobante.remitoCargamento.reciboCargamento.prefijo+"-"+r.comprobante.remitoCargamento.reciboCargamento.numeroRecibo;
        item.comprobante = "REM-" + this.remitoPrefijoPipe.transform(r.comprobante.prefijo)+"-"+this.remitoNumeroPipe.transform(r.comprobante.numeroRemito);
      }else if(r.comprobante.remitoSalida != null){
        item.comprobante = "REM-" + this.remitoPrefijoPipe.transform(r.comprobante.remitoSalida.prefijoFOX)+"-"+this.remitoNumeroPipe.transform(r.comprobante.remitoSalida.numeroFOX);
      }else{
        item.comprobante = "-";
      }       

      listaReporte.push(item)
    }

    return listaReporte;
  }
  
  exportar(){
    this.excelService.exportAsExcelFile(this.armarArrayExcel(), 'Reporte movimientos cierre');
  }

}

export class ReporteRanchoCierre{
  producto: string;
  fecha: string;  
  titulo: string;
  entradaNatural: number;
  salidaNatural: number;
  entrada15: number;
  salida15: number;
  stockNatural: number;
  stock15: number;
  comprobante: string;
  contenedor: string;
}