<div class="col-12 mt-2" *ngIf="cargando && this.cierreSeleccionado != null">
  <div class="container">
    <div class="mb-3" style="height: 10cm;">
      <ngx-spinner name="spinnerGrafico" [ngStyle]="{'height': '400px', 'z-index':'0'}" bdOpacity=0.3bd
        Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
        <p style="color: white">C a r g a n d o . . . </p>
      </ngx-spinner>
    </div>
  </div>
</div>

<div class="card-body" *ngIf="this.cierreSeleccionado != null && !cargando && this.reporteSeleccionado == 5">
  <!-- contenedores -->
  <div ngbDropdown class="noImprimir" autoClose="outside">
    <button class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2" id="dropdownForm1"
      ngbDropdownToggle>Seleccionar Expendedora</button>
    <div ngbDropdownMenu aria-labelledby="dropdownForm1" style="z-index: 50">
      <div class="px-4 py-3" style="z-index: 50">
        <div class="form-check mb-2">
          <input type="checkbox" checked class="custom-control-input" id="Todos"
            (change)="seleccionarTodos($event)">
          <label class="custom-control-label text-nowrap" for="Todos">
            Todas las expendedoras
          </label>
        </div>
        <div *ngFor="let ex of expendedoras">
          <div class="form-check">
            <input type="checkbox" class="custom-control-input" id={{ex.name}}
              [(ngModel)]="ex.seleccionado">
            <label class="custom-control-label text-nowrap" for={{ex.name}}>
              {{ ex.name }}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 mt-2" *ngFor="let dist of expendedoras">
    <div class="border border-solid saltoPagImp" *ngIf="dist.seleccionado">
      <label class="ms-1"><b>Movimientos realizados de la expendedora: {{dist.name}}</b></label>
      <!--ABASTECEDORAS-->
      <table class="table table-striped table-hover letra">
        <thead class="text-center">
          <tr>
            <th>Contenedor</th>
            <th>Producto</th>
            <th>Fecha</th>
            <th>Titulo</th>
            <th>Nombre aforador</th>
            <th>Aforador inicial</th>
            <th>Aforador final</th>
            <th>Comprobante</th>
            <th class="noImprimir">Ver</th>
          </tr>
        </thead>
        <tbody class="text-center">
          <tr *ngFor="let mov of this.arregloDistintoExp(dist.name); let i = index" [ngClass]="{'resaltado': !mov.hayCorrelatividad}">
            <!-- <ng-container *ngIf="dist == mov.codigoExpendedora + '-' + mov.nombreExpendedora"> -->
              <td>{{mov.nombreExpendedora}}</td>
              <td>{{mov.nombreProducto}}</td>
              <td>{{mov.fechaHora | date: 'dd/MM/yyyy HH:mm a'}}</td>
              <td>{{mov.titulo}}</td>
              <td>{{mov.nombrePico}}</td>
              <td>{{mov.aforadorInicial}}</td>
              <td>{{mov.aforadorFinal}}</td>
              <ng-container *ngIf="mov.comprobante != null">
                <td *ngIf="mov.comprobante.remito == null">-----</td>                
                <td *ngIf="mov.comprobante.remito == null"></td>                
                <td *ngIf="mov.comprobante.remito != null">
                  AEV-{{mov.comprobante.remito.aerovalePrefijo | aerovalePrefijo}}-{{mov.comprobante.remito.aerovaleNumero | aerovaleNumero}}
                </td>        
                <td class="noImprimir" *ngIf="mov.comprobante.remito != null">
                  <button *ngIf="mov.comprobante.remito.aerovaleId != null" class="btn" (click)="obtenerAerovale(mov.comprobante.remito.aerovaleId, i)" ngbTooltip="Ver aerovale">
                    <i *ngIf="!obteniendoAerovale || obteniendoAerovale && aerovaleSeleccionado!=i" class="fa fa-eye" aria-hidden="true"></i>
                    <div *ngIf="aerovaleSeleccionado==i && obteniendoAerovale">
                        <div class="spinner-border text-primary spinner-border-sm" role="status"></div>
                        <span><i class="fa fa-eye" aria-hidden="true"></i></span>
                    </div>
                  </button> 
                </td>        
              </ng-container>  
              <ng-container *ngIf="mov.comprobante == null">
                <td>-----</td>
                <td></td>
              </ng-container>  
            <!-- </ng-container> -->
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- <div class="row">
    <div class="col-md-12">
      <button style="float:right;" *ngIf="cierreSeleccionado.id != null" (click)="exportar()" class="btn btn-primary">
        <i class="fa fa-file-excel-o" aria-hidden="true"></i> Exportar 
      </button>
    </div>
  </div> -->
</div>