import { NgModule } from "@angular/core";
import { Routes, RouterModule, Router } from "@angular/router";
import { Shell } from "../shell/shell.service";
import { authGuard } from "../core/authentication/auth.guard";
import { TipoUsuario } from "../shared/enums/tipoUsuario";
import { ReportsIndexComponent } from "./reports-index/reports-index.component";
import { StockIndexComponent } from "./stock/stock-index/stock-index.component";
import { StockActualComponent } from "./stock/stock-actual/stock-actual.component";
import { ConsolidadoComponent } from "./informes/consolidado/consolidado.component";
import { ResolucionMensualComponent } from "./informes/resolucion-mensual/resolucion-mensual.component";
import { InformesIndexComponent } from "./informes/informes-index/informes-index.component";
import { AuthService } from "../core/authentication/auth.service";

const routes: Routes = [
  Shell.childRoutes([    
    { path: 'reportes', redirectTo: 'reportes/informes', pathMatch: 'full'},
    {
      path: "reportes",
      component: ReportsIndexComponent,
      canActivate: [authGuard],
      data: { roles: [TipoUsuario.TUTOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.RESPRODUCTO, TipoUsuario.SOPORTE] },
      children: [
        {
          path: 'stock',
          component: StockIndexComponent,
          canActivate: [authGuard],
          data: { roles: [TipoUsuario.TUTOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.SOPORTE] },
          children: [
            {
              path: 'stockActual',
              component: StockActualComponent,
              canActivate: [authGuard],
              data: { roles: [TipoUsuario.TUTOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.SOPORTE] }
            }
          ]
        },
        {
          path: 'informes', 
          component: InformesIndexComponent,
          canActivate: [authGuard], 
          data: { roles: [TipoUsuario.TUTOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.SOPORTE, TipoUsuario.RESPRODUCTO] },
          children: [
            {
              path: 'consolidado',
              component: ConsolidadoComponent,
              canActivate: [authGuard],
              data: { roles: [TipoUsuario.TUTOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.SOPORTE] }
            },
            {
              path: 'resolucionMensual',
              component: ResolucionMensualComponent,
              canActivate: [authGuard],
              data: { roles: [TipoUsuario.RESPRODUCTO, TipoUsuario.SOPORTE] }
            }
          ]
        }
      ]
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class ReportesRoutingModule {
  constructor(
    private authService: AuthService,
    private router: Router) 
    {
      this.router.events
        .subscribe((e)=>{
          if (e['url'] && e['url'] == '/reportes' || e['url'] == '/reportes/stock') {
            this.redireccionarStock()
          } else if (e['url'] && e['url'] == '/reportes' || e['url'] == '/reportes/informes') {
            this.redireccionarInformes()
          }
        });
    }

  redireccionarStock() {
    if (this.authService.hasRole(TipoUsuario.TUTOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.SOPORTE)) {
      this.router.navigate(['reportes/stock/stockActual']);
    } else if (this.authService.hasRole(TipoUsuario.RESPRODUCTO)) {
      this.router.navigate(['reportes/informes/resolucionMensual'])
    } else {
      this.router.navigate(['reportes/informes/consolidado']);
    }
  }

  redireccionarInformes() {
    //if (!this.authService.hasRole(TipoUsuario.RESPRODUCTO)) {
      this.router.navigate(['reportes/informes/consolidado']);      
    /*} else {
      this.router.navigate(['reportes/informes/resolucionMensual'])
    }*/
  }
}
