<nav class="navbar navbar-light bg-light navbar-expand-lg">
  <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <form class="form-inline my-2 my-lg-0 me-2 ms-3">
      <input id="busquedaFormControl" type="text" class="form-control" [formControl]="busquedaFormControl"
        (input)="filtrarFacturas()" placeholder="Factura / Fecha / Cliente" />
    </form>
    <ul class="navbar-nav me-auto">
      <!-- desde -->
      <li class="nav-item me-2">
        <div class="input-group">
          <input type="date" [ngModel]="modelDesde" class="fechaFormControlDesdeHasta" placeholder="aaaa-mm-dd"
            (ngModelChange)="modelDesde = $event" name="dt" ngbDatepicker #d="ngbDatepicker" value="modelDesde"
            class="form-control form-control-rounded" #searchDate required readonly>
          <div class="input-group-append">
            <button id="botonDesde" type="button" class="input-group-btn btn btn-secondary" (click)="d.toggle()">
              <i class="fa fa-calendar" aria-hidden="true"></i></button>
          </div>
        </div>
      </li>
      <!-- hasta -->
      <li class="nav-item">
        <div class="input-group">
          <input type="date" class="fechaFormControlDesdeHasta" [ngModel]="modelHasta" placeholder="aaaa-mm-dd"
            name="dp" value="modelHasta" (ngModelChange)="modelHasta = $event" class="form-control form-control-rounded"
            ngbDatepicker #h="ngbDatepicker" #searchDate required readonly>

          <div class="input-group-append">
            <button id="botonHasta" type="button" class="input-group-btn btn btn-secondary" (click)="h.toggle()"><i
                class="fa fa-calendar" aria-hidden="true"></i></button>
          </div>
        </div>
      <li class="nav-item">
        <button (click)="buscarDesdeHasta()" class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2" type="submit"><i
            class="fa fa-search" aria-hidden="true"></i></button>
      </li>
      <li class="nav-item">
        <div class="input-group">
          <button type="button" (click)="aceptarRefacturacion()" class="btn btn-success me-2"
            [disabled]="this.facturasSeleccionadas.length===0 || aceptandoRefacturacion">
            <span *ngIf="!aceptandoRefacturacion">Aceptar</span>
            <div *ngIf="aceptandoRefacturacion" class="d-flex align-items-center">
              <div class="spinner-border text-light spinner-border-sm" role="status"></div>
              <span>Aceptando...</span>
            </div>
          </button>
        </div>
      </li>
      <li class="nav-item">
        <div class="input-group">
          <button type="button" (click)="rechazarRefacturacion()" class="btn btn-danger"
            [disabled]="this.facturasSeleccionadas.length===0 || rechazandoRefacturacion">
            <span *ngIf="!rechazandoRefacturacion">Rechazar</span>
            <div *ngIf="rechazandoRefacturacion" class="d-flex align-items-center">
              <div class="spinner-border text-light spinner-border-sm" role="status"></div>
              <span>Rechazando...</span>
            </div>
          </button>
        </div>
      </li>
    </ul>
  </div>
</nav>
<div class="col col-md-12">
  <div class="spinner-container" *ngIf="this.obteniendoFacturas">
    <ngx-spinner name="spinnerGrafico" bdOpacity=0.3bd
      Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
      <p style="color: white">C a r g a n d o . . . </p>
    </ngx-spinner>
  </div>
  <div class="abs-center" *ngIf="this.obteniendoFacturas===false && this.facturasFiltrados.length==0">
    <ngb-alert type="warning"> No se encontraron facturas en cuenta corriente registradas para el rango de fechas
      seleccionado.</ngb-alert>
  </div>
  <div class="row">
    <div class="card-body" *ngIf="this.obteniendoFacturas===false && this.facturasFiltrados.length>0">
      <table class="table table-striped table-hover" [formGroup]="tablaComprobantes">
        <thead class="text-center">
          <td><b>Factura</b></td>
          <td><b>Tipo</b></td>
          <td><b>Fecha</b></td>
          <td><b>Cliente</b></td>
          <td><b>Importe</b></td>
          <td><b>Comparativas</b></td>
          <td><b>Corrección</b></td>
          <td><b>Accion</b></td>
        </thead>
        <tbody class="text-center">
          <tr formArrayName="facturasFiltrados" *ngFor="let factura of this.facturasFiltrados let i = index">
            <td>{{ factura.factura.prefijo | facturaPrefijo }}-{{ factura.factura.numeroComprobante | facturaNumero }}</td>
            <td>{{ factura.factura.tipoMovimiento | tipoMovimiento }}
            </td>
            <td>{{ factura.fechaHora | date: "d'/'MM'/'y' 'hh:mm a" }}</td>
            <td>{{ factura.factura.razonSocialCliente}}</td>
            <td>{{ factura.factura.tipoMoneda | tipoMoneda }} {{ factura.factura.total | number:'0.2-2' }}</td>
            <td>
              <button class="btn" (click)="verComparativasFacturas(content,factura,i)" ngbTooltip="Ver Factura/Refactura">
                <i *ngIf="!previsualizando || previsualizando && itemSeleccionado!=i" class="fa fa-eye" aria-hidden="true"></i>
                <div *ngIf="previsualizando && itemSeleccionado==i" class="spinner-border text-primary spinner-border-sm" role="status"></div>
              </button> 
            </td>
            <td>
              <div class="form-switch">
                <input style="cursor: pointer;" type="checkbox" (value)="!factura.esRefacturacionTotal" class="form-check-input"
                  id="customSwitch{{[i]}}" (change)="changeValue(i, $event)">
                <label hidden class="form-check-label" for="customSwitch{{[i]}}"></label>
              </div>
            </td>
            <td>            
              <input style="cursor: pointer;" type="checkbox" (change)="seleccionarFactura(factura, $event)" class="form-check-input"
                id="customCheck{{[i]}}" />
              <label hidden class="form-check-label" for="customCheck{{[i]}}"></label>              
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <ng-template #content let-modal style="overflow-y:scroll">
    <div class="row">
      <div class="col-md-6" style="text-align:center">
        <b>Factura original</b>
      </div>
      <div class="col-md-6" style="text-align:center">
        <b> Comprobante Corregido</b>
      </div>
    </div>
    <div class="row" id="print">
      <app-visor-comprobante [botones]="false" *ngIf="this.facturaObtenida!=null" class="col-md-6"
        [facturaObtenida]='this.facturaObtenida'></app-visor-comprobante>
      <app-previsualizacion-comprobante *ngIf="this.facturaCalculada!=null" [facturaCalculada]="this.facturaCalculada"
        class="col-md-6"></app-previsualizacion-comprobante>
    </div>
    <div class="col-4">
      <button styleSheetFile="assets/css/print.css,assets/css/bootstrap.min.css" printSectionId="print"
        useExistingCss="true" class="btn btn-sm btn-outline-secondary" printTitle="{{tituloFactura}}" ngxPrint>
        <i class="fa fa-print" aria-hidden="true"></i> Imprimir </button>
    </div>
  </ng-template>