import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { url } from 'inspector';
import { EstadoUpload } from 'src/app/shared/enums/EstadoUpload';
import { CargaArchivo } from 'src/app/shared/models/cliente/CargaArchivo';
import { UploadService } from '../../cliente/Upload.service';

@Component({
  selector: 'app-transfer-file',
  templateUrl: './transfer-file.component.html',
  styleUrls: ['./transfer-file.component.css']
})
export class TransferFileComponent implements OnInit {
  @Input() url: string;
  @Input() file: File;
  @Output() archivoSubido: EventEmitter<String> = new EventEmitter<String>();

  profileForm: UntypedFormGroup;
  EstadoUpload = EstadoUpload;
  preparandoCarga: boolean;
  mensajeError: string;
  mensajeOk: string;

  archivoCargado: CargaArchivo;

  constructor(private fb: UntypedFormBuilder, private uploadService: UploadService) { }

  ngOnInit() {
    this.profileForm = this.fb.group({
      inputFile: [''],
    });
  }
/*
  onSelectedFile(event) {
    if (event.target.files.length > 0) {
      var fileOriginal = event.target.files[0];

      var filename = fileOriginal.name;
      var timestamp = "_" + Date.now();
      var position = filename.indexOf(".");
      var outputFilename = [filename.slice(0, position), timestamp, filename.slice(position)].join('');

      this.file = new File([fileOriginal], outputFilename , {type: fileOriginal.type});
    }
  }
*/

  processFile(file: File) {
    var fileOriginal = file;

    var filename = fileOriginal.name;
    var timestamp = "_" + Date.now();
    var position = filename.indexOf(".");
    var outputFilename = [filename.slice(0, position), timestamp, filename.slice(position)].join('');

    this.file = new File([fileOriginal], outputFilename , {type: fileOriginal.type});

    this.onSubmit();
  }

  onSubmit() {
    const carga: CargaArchivo = new CargaArchivo(this.file.name);
    this.archivoCargado = carga;

    this.uploadService.cargarArchivo(this.file, carga, this.archivoSubido,this.url);
    this.reset();
  }

  onCancelarCarga(cargaArchivo: CargaArchivo) {
    this.uploadService.cancelarCarga(cargaArchivo);
    this.reset();
    this.archivoSubido.emit(null);
  }

  get cargandoArchivo(): CargaArchivo {
    return this.archivoCargado;
  }

  get cargaArchivoTerminada(): CargaArchivo {
    const carga = this.archivoCargado;
    if (carga)
      this.mensajeError = carga.error;
    return carga;
  }

  get fileName(): string {
    if (this.file) {
      return this.file.name;
    }
    return '';
  }

  /**
  * Limpiar pantalla
  */
  reset() {
    this.file = null;
    this.profileForm.reset();
  }

}
