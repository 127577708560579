<form [formGroup]="form" (ngSubmit)="submit()">
  <table class="table table-striped">
    <thead>
      <tr>
        <th scope="col">Activo</th>
        <th scope="col">Nombre</th>
        <th scope="col">Codigo</th>
        <th scope="col">Unidad de Medida</th>
      </tr>
    </thead>
    <tbody *ngIf="!cargandoAeroespecialidades">
      <tr formArrayName="aeroespecialidades"
        *ngFor="let aeroespecialidad of form.controls.aeroespecialidades.controls; let i = index">
        <td>
          <div class="form-check form-switch">
            <input type="checkbox" [formControlName]="i" class="form-check-input" id="{{aeroespecialidades[i].codigoProducto}}">
            <label class="form-check-label" for="{{aeroespecialidades[i].codigoProducto}}"></label>
          </div>
        </td>
        <td>{{ aeroespecialidades[i].nombreProducto }}</td>
        <td>{{ aeroespecialidades[i].codigoProducto }}</td>
        <td>{{ aeroespecialidades[i].unidadMedida }}</td>
      </tr>
    </tbody>
  </table>
  <div *ngIf="!cargandoAeroespecialidades" class="text-center">
    <span class="border">
      <button class="btn btn-primary" [disabled]="actualizandoAeroespecialidades" type="submit">
        <span *ngIf="!actualizandoAeroespecialidades">Actualizar</span>
        <span *ngIf="actualizandoAeroespecialidades">Actualizando...</span>
      </button>
    </span>
    <span class="border">
      <button type="button" class="btn btn-secondary" (click)="cancelar()">Descartar</button>
    </span>
  </div>
</form>
<div class="col col-md-12">
  <div class="spinner-container" *ngIf="cargandoAeroespecialidades">
    <ngx-spinner name="spinnerLista" bdOpacity=0.3bd
      Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
      <p style="color: white"> C a r g a n d o . . . </p>
    </ngx-spinner>
  </div>

