import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';
import { Shell } from '../shell/shell.service';
import { ManagementIndexComponent } from './management-index/management-index.component';
import { authGuard } from '../core/authentication/auth.guard';
import { TipoUsuario } from '../shared/enums/tipoUsuario';
import { ListEmisorComponent } from './list-emisor/list-emisor.component';
import { SetAeroespecialidadesComponent } from './set-aeroespecialidades/set-aeroespecialidades.component';
import { ClientesIndexComponent } from './clientes-index/clientes-index.component';
import { CustomersImportComponent } from './customers-import/customers-import.component';
import { ClientesComponent } from '../cliente/clientes/clientes.component';
import { LaboratoriosImportComponent } from './laboratorios-import/laboratorios-import.component';
import { UsuariosImportComponent } from './usuarios-import/usuarios-import.component';
import { SetAeroplantasComponent } from './set-aeroplantas/set-aeroplantas.component';
import { SetEmisorComponent } from './set-emisor/set-emisor.component';
import { SetCaiAeroplantaComponent } from './set-cai-aeroplanta/set-cai-aeroplanta.component';
import { ClientesAprobarModificacionesComponent } from '../cliente/clientes-aprobar-modificaciones/clientes-aprobar-modificaciones.component';
import { ReporteClientesComponent } from '../cliente/reporte-clientes/reporte-clientes.component';
import { AbmAeroplantasComponent } from './abm-aeroplantas/abm-aeroplantas.component';
import { AuthService } from '../core/authentication/auth.service';
import { AeroplantaIndexComponent } from './aeroplanta-index/aeroplanta-index/aeroplanta-index.component';
import { ReportesIndexComponent } from './reportes-index/reportes-index.component';
import { DescargarDatosComponent } from './descargar-datos/descargar-datos.component';
import { AbmMatriculasComponent } from './matriculas/abm-matriculas/abm-matriculas.component';
import { MatriculasIndexComponent } from './matriculas/matriculas-index/matriculas-index.component';
import { ReporteAutorizacionesComponent } from './matriculas/reporte-autorizaciones/reporte-autorizaciones.component';
import { ReportePreautorizacionesComponent } from './matriculas/reporte-preautorizaciones/reporte-preautorizaciones.component';
import { TipoSolapaAutorizacion } from '../shared/enums/TipoSolapaAutorizacion';

const routes: Routes = [

  Shell.childRoutes([
    { path: 'management/aeroplantas', redirectTo: 'management/aeroplantas/abmAeroplanta', pathMatch: 'full'},
    {
      path: 'management', component: ManagementIndexComponent, canActivate: [authGuard], data: { roles: [TipoUsuario.COMERCIAL, TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.ADMCLIENTES, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.FACTURACION, TipoUsuario.REFACTURACION, TipoUsuario.SEGURIDADTABLAS, TipoUsuario.IMPUESTOS, TipoUsuario.FINANZAS, TipoUsuario.SOPORTE, TipoUsuario.APROBADORBYR] }, children: [
        {
          path: "emisor",
          component: ListEmisorComponent,
          canActivate: [authGuard],
          data: { roles: [TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.FACTURACION, TipoUsuario.FINANZAS, TipoUsuario.SOPORTE ] },
        },
        {
          path: "aeroespecialidades",
          component: SetAeroespecialidadesComponent,
          canActivate: [authGuard],
          data: { roles: [TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.SUPERVISOR, TipoUsuario.TUTOR, TipoUsuario.SOPORTE] },
        },
        {
          path: 'asignarAeroplantas',
          component: SetAeroplantasComponent,
          canActivate: [authGuard],
          data: { roles: [TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.SUPERVISOR, TipoUsuario.TUTOR, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL] }
        },
        {
          path: 'importarUsuarios',
          component: UsuariosImportComponent,
          canActivate: [authGuard],
          data: { roles: [TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.SUPERVISOR] }
        },
        {
          path: 'clientes',
          component: ClientesIndexComponent,
          canActivate: [authGuard],
          data: { roles: [TipoUsuario.COMERCIAL, TipoUsuario.SUPERVISOR, TipoUsuario.ADMCLIENTES, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.FACTURACION, TipoUsuario.REFACTURACION, TipoUsuario.SOPORTE, TipoUsuario.APROBADORBYR ] },
          children: [
            {
              path: 'importarClientes',
              component: CustomersImportComponent,
              canActivate: [authGuard],
              data: { roles: [TipoUsuario.COMERCIAL] }
            },
            {
              path: 'nuevoCliente',
              component: ClientesComponent,
              canActivate: [authGuard],
              data: { roles: [TipoUsuario.COMERCIAL, TipoUsuario.SUPERVISOR, TipoUsuario.ADMCLIENTES, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.FACTURACION, TipoUsuario.REFACTURACION, TipoUsuario.SOPORTE, TipoUsuario.APROBADORBYR] }
            },
            {
              path: 'aprobarClientes',
              component: ClientesAprobarModificacionesComponent,
              canActivate: [authGuard],
              data: { roles: [TipoUsuario.ADMCLIENTES] }
            },
            {
              path: 'reporteIndex',
              component: ReportesIndexComponent,
              canActivate: [authGuard],
              data: { roles: [TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.ADMCLIENTES, TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.APROBADORBYR, TipoUsuario.SUPERVISOR] },
              children: [
                {
                  path: 'reporteClientes',
                  component: ReporteClientesComponent,
                  canActivate: [authGuard],
                  data: { roles: [TipoUsuario.OPERADOR_3,TipoUsuario.OPERADORDIRECTO, TipoUsuario.ADMCLIENTES, TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.APROBADORBYR, TipoUsuario.SUPERVISOR] }
                },
                {
                  path: 'descargarDatos',
                  component: DescargarDatosComponent,
                  canActivate: [authGuard],
                  data: { roles: [TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.ADMCLIENTES, TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.SUPERVISOR] }
                },
              ]
            }
          ]
        },

        {
          path: 'importarLaboratorios',
          component: LaboratoriosImportComponent,
          canActivate: [authGuard],
          data: { roles: [TipoUsuario.COMERCIAL, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.SUPERVISOR] }
        },
        // {
        //   path: 'caiAeroplanta',
        //   component: SetCaiAeroplantaComponent,
        //   canActivate: [authGuard],
        //   data: { roles: [TipoUsuario.COMERCIAL, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.SUPERVISOR] }
        // },
        // {
        //   path: 'abmAeroplanta',
        //   component: AbmAeroplantasComponent,
        //   canActivate: [authGuard],
        //   data: { roles: [TipoUsuario.COMERCIAL, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.SUPERVISOR] }
        // },
        {
          path: 'aeroplantas',
          component: AeroplantaIndexComponent,
          canActivate: [authGuard],
          data: { roles: [TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.APROBADORBYR, TipoUsuario.SUPERVISOR, TipoUsuario.SEGURIDADTABLAS, TipoUsuario.IMPUESTOS, TipoUsuario.SOPORTE ] },
          children: [
            {
                
              path: 'abmAeroplanta',
              component: AbmAeroplantasComponent,
              canActivate: [authGuard],
              data: { roles: [TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.APROBADORBYR, TipoUsuario.SUPERVISOR, TipoUsuario.SEGURIDADTABLAS, TipoUsuario.IMPUESTOS, TipoUsuario.SOPORTE ] }
            },
            {
              path: 'caiAeroplanta',
              component: SetCaiAeroplantaComponent,
              canActivate: [authGuard],
              data: { roles: [TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.SUPERVISOR, TipoUsuario.SEGURIDADTABLAS, TipoUsuario.IMPUESTOS, TipoUsuario.TUTOR, TipoUsuario.SOPORTE ] }
            }]
        },
        {
          path: 'matriculas',
          component: MatriculasIndexComponent,
          canActivate: [authGuard],
          data: { roles: [TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_LN, TipoUsuario.COMERCIAL_AV, TipoUsuario.TUTOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.SOPORTE] },
          children: [
            {
              path: "abmMatriculas", component: AbmMatriculasComponent, canActivate: [authGuard], data: { 
                roles: [TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_LN, TipoUsuario.COMERCIAL_AV, TipoUsuario.SOPORTE]
              },
            },            
            { 
              path: "autorizacionesPendientes", component: ReporteAutorizacionesComponent, canActivate: [authGuard], data: { 
                roles: [TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_LN, TipoUsuario.COMERCIAL_AV, TipoUsuario.SOPORTE]
              },
            },
            {
              path: "autorizacionesHistorial", component: ReporteAutorizacionesComponent, canActivate: [authGuard], data: {
                roles: [TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_LN, TipoUsuario.COMERCIAL_AV, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.TUTOR, TipoUsuario.SOPORTE]
              },
            },
            {
              path: "preautorizaciones", component: ReportePreautorizacionesComponent, canActivate: [authGuard], data: { 
                roles: [TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_LN, TipoUsuario.COMERCIAL_AV, TipoUsuario.SOPORTE]
              },
            }
          ]
        },        
      ]
    },
  ]),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class ManagementRoutingModule {
  constructor(private authService: AuthService, private router: Router) {
    this.router.events.subscribe((event) => {
      if (event['url'] && event['url'] == '/management') {
        this.redireccionar();
      }else if(event['url'] && event['url'] == '/management/clientes') {
        this.redireccionarClientes();
      }else if(event['url'] && event['url'] == '/management/aeroplantas') {
        this.redireccionarAeroplantas();
      }else if(event['url'] && event['url'] == '/management/matriculas') {
        this.redireccionarMatriculas();
      }else if(event['url'] && event['url'] == '/management/clientes/reporteIndex') {
        this.redireccionarReportes();
      } 
    });
  }

  redireccionar() {
    if (this.authService.hasRole(TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.FACTURACION, TipoUsuario.FINANZAS, TipoUsuario.SOPORTE )) {
      this.router.navigate(['/management/emisor']);
    } else if (this.authService.hasRole(TipoUsuario.COMERCIAL, TipoUsuario.ADMCLIENTES, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.APROBADORBYR, TipoUsuario.FACTURACION, TipoUsuario.SOPORTE, TipoUsuario.REFACTURACION)) {
      this.router.navigate(['/management/clientes']);
    }  else if (this.authService.hasRole(TipoUsuario.SEGURIDADTABLAS, TipoUsuario.IMPUESTOS)) {
      this.router.navigate(['/management/aeroplantas']);
    } 
  }

  redireccionarClientes() {
    if (this.authService.hasRole(TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.SUPERVISOR, TipoUsuario.SOPORTE, TipoUsuario.ADMCLIENTES, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.APROBADORBYR, TipoUsuario.FACTURACION, TipoUsuario.REFACTURACION)) {
      this.router.navigate(['/management/clientes/nuevoCliente']);
    }
  }

  redireccionarAeroplantas() {
    if (this.authService.hasRole(TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.APROBADORBYR, TipoUsuario.SUPERVISOR, TipoUsuario.SEGURIDADTABLAS, TipoUsuario.IMPUESTOS, TipoUsuario.SOPORTE )) {
      this.router.navigate(['/management/aeroplantas/abmAeroplanta']);
    }
  }

  redireccionarMatriculas() {
    if (this.authService.hasRole(TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_LN, TipoUsuario.COMERCIAL_AV, TipoUsuario.SOPORTE)) {
      this.router.navigate(['/management/matriculas/abmMatriculas']);
    }else if (this.authService.hasRole(TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_LN, TipoUsuario.COMERCIAL_AV, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.TUTOR)) {
      this.router.navigate(['/management/matriculas/autorizacionesHistorial'], {queryParams : {solapaAutorizacion: TipoSolapaAutorizacion.Historial}});
    }
  }

  redireccionarReportes() {
    this.router.navigate(['/management/clientes/reporteIndex/reporteClientes']);
  }
}

