import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Precio } from 'src/app/shared/models/abm/Precio';


@Component({
  selector: 'app-modal-historico',
  templateUrl: './modal-historico.component.html',
  styleUrls: ['./modal-historico.component.css']
})
export class ModalHistoricoComponent implements OnInit {

  @Input() precio : Precio;

  arregloHistorico: string[];

  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.arregloHistorico = this.cortarCadena(this.precio.historico);
  }

  onCerrar(){
    this.activeModal.dismiss();
  }

  cortarCadena(cadena: string): string[] {
    if (cadena != null || cadena == '') {
      return cadena.split('*|*');
    } else {
      return ['-'];
    }
  }

}
