<div class="modal-header bg-light">
  <h4 class="modal-title">Corrección de precio</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close(false)">
    
  </button>
</div>
<div class="modal-body" [formGroup]="correccionForm">
  <!--PRECIO-->
  <div class="row">
    <div class="mb-3 row col-md-12">
      <label for="precioFormControl" class="col-sm-5 col-form-label">*Precio:</label>
      <div class="col-sm-7">
        <input class="form-control" formControlName="precioFormControl" maxlength="50">
        <div
        *ngIf="precioFormControl.invalid && precioFormControl.errors.required && (precioFormControl.dirty || precioFormControl.touched)"
        class="alert alert-danger">Requerido</div>
      </div>
    </div>
  </div>

  <!-- fecha y hora Desde -->
  <div class="row">
    <div class="mb-3 row col-md-12">
      <label for="fechaDesdeFormControl" class="col-sm-5 col-form-label" style="display: block;">
        Fecha Desde:
      </label>
      <div #fechaHora class="col-sm-7">
        <div class="input-group mb-2">
          <input type="date" class="form-control" [ngModel]="modelDesde" 
            formControlName="fechaDesdeFormControl" placeholder="aaaa-mm-dd" name="dp" title="Campo obligatorio."
            ngbDatepicker #d="ngbDatepicker" readonly>
          <div class="input-group-append">
            <button type="button" class="input-group-btn btn btn-secondary" (click)="d.toggle()"><i
                class="fa fa-calendar" aria-hidden="true"></i></button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="mb-3 row col-md-12">
      <label for="horaDesdeFormControl" class="col-sm-5 col-form-label" style="display: block;">
        Hora Desde:
      </label>
      <div class="col-sm-7">
        <div class="input-group mb-2">
          <ngb-timepicker [(ngModel)]="modelHoraDesde" [meridian]="false" [seconds]="true" formControlName="horaDesdeFormControl" [readonlyInputs]="true"></ngb-timepicker>
        </div>
      </div>
    </div>
  </div>
  
  <!-- fecha y hora Hasta -->
  <div class="row">
    <div class="mb-3 row col-md-12">
      <label for="fechaHastaFormControl" class="col-sm-5 col-form-label">Fecha Hasta: </label>
      <div #fechaHoraVuelo class="col-sm-7">
        <div class="input-group mb-2">
          <input type="date" class="form-control" [ngModel]="modelHasta" 
            formControlName="fechaHastaFormControl" placeholder="aaaa-mm-dd" name="dp" title="Campo obligatorio."
            ngbDatepicker #dv="ngbDatepicker" readonly>
          <div class="input-group-append">
            <button type="button" class="input-group-btn btn btn-secondary" (click)="dv.toggle()"><i
                class="fa fa-calendar" aria-hidden="true"></i></button>
          </div>
        </div>
         <div *ngIf="this.fechaDesdeMayorAHasta()===true"
                class="alert alert-danger">La fecha fin debe ser menor que la inicial</div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="mb-3 row col-md-12">
      <label for="horaHastaFormControl" class="col-sm-5 col-form-label" style="display: block;">
        Hora Hasta:
      </label>
      <div class="col-sm-7">
        <div class="input-group mb-2">
          <ngb-timepicker [(ngModel)]="modelHoraHasta" [meridian]="false" [seconds]="true" formControlName="horaHastaFormControl" [readonlyInputs]="true"></ngb-timepicker>
        </div>
      </div>
    </div>
  </div>

  <!--DETALLE-->
  <div class="row">
    <div class="mb-3 row col-md-12">
      <label for="detalleFormControl" class="col-sm-5 col-form-label">*Detalle:</label>
      <div class="col-sm-7">
        <textarea rows='5' class="form-control" formControlName="detalleFormControl"></textarea>
        <div
          *ngIf="detalleFormControl.invalid && detalleFormControl.errors.required && (detalleFormControl.dirty || detalleFormControl.touched)"
          class="alert alert-danger">Requerido</div>
      </div>
    </div>
  </div>


  <small class="text-danger">*Campos obligatorios.</small>

  <div *ngIf="error != null" class="alert alert-danger">{{error}}</div>
</div>
<div class="modal-footer justify-content-center">
  <button class="btn btn-primary me-2" id="aceptar" [disabled]="!correccionForm.valid || this.corrigiendoPrecio"
    (click)="corregirPrecio()">
    <span *ngIf="!corrigiendoPrecio">Guardar</span>
    <div *ngIf="corrigiendoPrecio" class="d-flex align-items-center">
      <div class="spinner-border text-light spinner-border-sm" role="status"></div>
      <span>Guardando...</span>
    </div>
  </button>
  <button type="button" class="btn btn-secondary" (click)="this.activeModal.close(false)">Cancelar</button>
</div>
