<div class="modal-header bg-light">
  <h4 class="modal-title">Comerciales</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismissAll()"></button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col col-1"></div>
    <div class="col col-2">
      <label class="col-form-label">Comercial:</label>
    </div>
    <div class="col col-6" *ngIf="this.usuariosComerciales!=null">
      <select class="form-select" [formControl]="comercialFormControl">
        <option *ngFor="let usu of usuariosComerciales" [ngValue]="usu">
          {{ usu.nombresCompletos }}
        </option>
      </select>
      <loading-text-spinner *ngIf="this.cargandoComerciales" message="Obteniendo comerciales..." color="primary">
      </loading-text-spinner>
      <div *ngIf="comercial.invalid && comercial.errors.required && (comercial.dirty || comercial.touched)"
        class="alert alert-danger">
        Requerido
      </div>
    </div>
    <div class="col col-2" *ngIf="!yaAsigneTodos()">
      <button type="button" (click)="this.asignarUsuarioSeleccionado()" class="btn btn-primary" [disabled]="!usuarioModificaCampos()">Asignar</button>
    </div>
  </div>
  <div class="row justify-content-center mt-3">
    <div class="mb-3 row col-12">
      <div class="col-4"></div>
      <div *ngIf="this.usuariosDelCliente!=null && this.usuariosDelCliente.length>0"><b>Usuarios asignados</b></div>
      <div class="row" *ngIf="this.usuariosDelCliente!=null">
        <div class="mb-3 row col-12"
          *ngFor="let c of this.usuariosDelCliente | paginate: { itemsPerPage: 1, currentPage: this.paginaActual }">
          <div class="col-4"></div>
          <div class="col-6">{{c}}</div>
          <div class="col-2"></div>
          <div class="col-4"></div>
          <div class="col-6"><button type="button" [disabled]="!usuarioModificaCampos()" (click)="this.desasignarUsuarioSeleccionado(c)"
              class="btn btn-danger btn-sm col-12"><i class="fa fa-trash col-12" aria-hidden="true"></i></button>
          </div>

        </div>
        <div class="col-2"></div>
        <pagination-controls *ngIf="this.usuariosDelCliente == null || this.usuariosDelCliente.length > 0" (pageChange)="this.cambiarPagina($event)" previousLabel="Anterior" nextLabel="Siguiente">
        </pagination-controls>
      </div>
    </div>
  </div>

  <div *ngIf="this.usuariosDelCliente == null || this.usuariosDelCliente.length == 0" class="row">
    <div class=" col-1"></div>
    <div class="mb-3 row col-10">
      <div *ngIf="!this.cargandoComerciales && this.usuariosDelCliente!=null && this.usuariosDelCliente.length===0" class="alert alert-warning">No hay usuarios comerciales cargados para el cliente seleccionado.</div>
    </div>
  </div>
</div>
<div class="modal-footer bg-light justify-content-center">
  <button class="btn btn-primary me-2" (click)="agregarComercial()" [disabled]="!usuarioModificaCampos()">Guardar</button>
  <button class="btn btn-secondary" (click)="activeModal.dismissAll()">Cancelar</button>
</div>
