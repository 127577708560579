import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ExportExcelServiceService } from 'src/app/shared/exportar-excel/export-excel-service.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ComprobanteService } from 'src/app/facturacion/comprobante.service';
import { MessageService } from 'src/app/shell/message.service';
import { environment } from 'src/environments/environment';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TipoReporteMovimientos } from 'src/app/shared/enums/TipoReporteMovimientos';
import { NgxSpinnerService } from 'ngx-spinner';
import { ReporteMovimientoBalance7 } from 'src/app/shared/models/cierreTurno/ReporteMovimientoBalance7';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { CierreTurnoService } from '../../cierre-turno.service';
import { ReporteAgrupadoPorTipoComponent } from './reporte-agrupado-por-tipo/reporte-agrupado-por-tipo.component';
import { TipoReporteBalance7 } from 'src/app/shared/enums/TipoReporteBalance7';
import { DecimalPipe } from '@angular/common';
import { UsuarioService } from 'src/app/account/usuario.service';
@Component({
  selector: 'app-reportes-movimientos',
  templateUrl: './reportes-movimientos.component.html',
  styleUrls: ['./reportes-movimientos.component.scss']
})
export class ReportesMovimientosComponent implements OnInit {
  
  @Input() codigoAeroplanta: string;
  @Input() fechaDesdeMov: string;
  @Input() fechaHastaMov: string;

  TipoReporteMovimientos = TipoReporteMovimientos;
  TipoReporteBalance7 = TipoReporteBalance7;
  movimientosNaturales: ReporteMovimientoBalance7[];
  movimientos15Grados: ReporteMovimientoBalance7[];
  listaIdsCierreTurno: string[];
  
  formTipoReporte: UntypedFormGroup;
  formNatural15: UntypedFormGroup;
  obteniendoDatos: boolean;

  mostrarTablaNatural: boolean;
  mostrarTabla15Grados: boolean;

  constructor(
    private cierreTurnoService: CierreTurnoService,
    private spinner: NgxSpinnerService,
    private formBuilder: UntypedFormBuilder,
    private messageService: MessageService,    
    private modalService: NgbModal,
    private decimalPipe: DecimalPipe,
    private usuarioService: UsuarioService) { 

      this.formTipoReporte = this.formBuilder.group({
        tipoReporteFormControl: ['', Validators.required],
        incluirAjustesFormControl: []
      });
  }

  ngOnInit(): void {  
    this.tipoReporteFormControl.setValue(TipoReporteMovimientos.CierreTurno);
    this.incluirAjustesFormControl.setValue(false);   
    
    this.mostrarTabla15Grados = true;
    this.mostrarTablaNatural = false;
  }

  buscarReporteMovimientos(){
    var tipoReporte = this.tipoReporteFormControl.value;
    var incluirAjustes = this.incluirAjustesFormControl.value;

    if(tipoReporte == TipoReporteMovimientos.Fecha){
      this.obtenerReporteFecha(incluirAjustes);
    }else{
      this.obtenerReporteCierreTurno(incluirAjustes);
    }
  }

  obtenerReporteFecha(incluirAjustes){
    this.mostrarLoading();

    this.cierreTurnoService.getMovimientosBalance7PorFecha(this.listaIdsCierreTurno, this.codigoAeroplanta, this.fechaDesdeMov, this.fechaHastaMov, incluirAjustes)
          .subscribe(result => {
            this.separarMovimientosNaturales15Grados(result);
            this.ocultarLoading();
          }, (err: ErrorModel) => {
            this.messageService.showErrorMessage('Hubo un error al obtener los datos. ' + err.description);
            this.movimientosNaturales = null;
            this.movimientos15Grados = null;
            this.ocultarLoading();
          });
  }

  obtenerReporteCierreTurno(incluirAjustes){
    this.mostrarLoading();    

    this.cierreTurnoService.getIdsCierreTurnoBalance7PorCierre(this.codigoAeroplanta, this.fechaDesdeMov, this.fechaHastaMov)
      .subscribe(result => {
        this.listaIdsCierreTurno = result;
        
        if(this.listaIdsCierreTurno){
          this.cierreTurnoService.getMovimientosBalance7(this.listaIdsCierreTurno, this.codigoAeroplanta, incluirAjustes)
          .subscribe(result => {
            this.separarMovimientosNaturales15Grados(result);
            this.ocultarLoading();
          }, (err: ErrorModel) => {
            this.messageService.showErrorMessage('Hubo un error al obtener los datos. ' + err.description);
            this.movimientosNaturales = null;
            this.movimientos15Grados = null;
            this.ocultarLoading();
          });
        }else{
          this.movimientosNaturales = null;
          this.movimientos15Grados = null;
          this.ocultarLoading();
        }
      }, (err: ErrorModel) => {
        this.messageService.showErrorMessage('Hubo un error al obtener los datos. ' + err.description);
        this.movimientosNaturales = null;
        this.movimientos15Grados = null;
        this.ocultarLoading();
      });
  }

  separarMovimientosNaturales15Grados( movimientos: ReporteMovimientoBalance7[]){
    this.movimientosNaturales = movimientos.filter(m => m.tipoReporte == TipoReporteBalance7.ContenedorNatural);
    this.movimientos15Grados = movimientos.filter(m => m.tipoReporte == TipoReporteBalance7.Contenedor15);
  }

  obtenerMovimientosPorTipo(m: ReporteMovimientoBalance7){
    var tipoReporte = this.tipoReporteFormControl.value;

    const modalRef = this.modalService.open(ReporteAgrupadoPorTipoComponent, { centered: true, size: "lg", windowClass: 'myCustomModalClass', backdrop: "static" });
    modalRef.componentInstance.listaIdsCierreTurno = this.listaIdsCierreTurno; 
    modalRef.componentInstance.tipoReporte = tipoReporte; 

    modalRef.componentInstance.codigoProducto = m.codigoProducto; 
    modalRef.componentInstance.nombreProducto = m.nombreProducto; 
    modalRef.componentInstance.codigoAeroplanta = this.codigoAeroplanta; 
    modalRef.componentInstance.fechaDesde = this.fechaDesdeMov; 
    modalRef.componentInstance.fechaHasta = this.fechaHastaMov; 
    modalRef.componentInstance.incluirAjustes = this.incluirAjustesFormControl.value; 
    modalRef.componentInstance.tipoNatural = (m.tipoReporte == 1 ? true : false); 

    modalRef.componentInstance.saldoInicial = m.anterior; 
    modalRef.componentInstance.saldoFinal = m.final; 

    modalRef.result.then((result) => {

    }, () => {  });
  }

  getFecha(fecha){
    return this.formatDate(fecha.getFullYear(), fecha.getMonth() + 1, fecha.getDate(), 0, 0, 0);
  }

  formatDate(year, month, day, hour, minute, seconds): string {
    return year + '-' + month.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + '-' + day.toLocaleString(undefined, { minimumIntegerDigits: 2 })
      + 'T' + hour.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + minute.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + seconds.toLocaleString(undefined, { minimumIntegerDigits: 2 });
  }

  mostrarLoading(){
    this.obteniendoDatos = true;
    this.spinner.show('spinnerGrafico');
  }

  ocultarLoading(){
    this.obteniendoDatos = false;
    this.spinner.hide('spinnerGrafico');
  }

  usuarioTildoNatural(checked){
    if(checked){
      this.mostrarTablaNatural = true;
    }else{
      this.mostrarTablaNatural = false;
    }
  }

  usuarioTildo15Grados(checked){
    if(checked){
      this.mostrarTabla15Grados = true;
    }else{
      this.mostrarTabla15Grados = false;
    }
  }

  get tipoReporteFormControl(){     
    return this.formTipoReporte.controls.tipoReporteFormControl;
  }

  get incluirAjustesFormControl() {
    return this.formTipoReporte.controls.incluirAjustesFormControl;
  }

  exportarListados(tipoContenedor: TipoReporteBalance7) {
    var tipoReporte = this.tipoReporteFormControl.value;
    var incluirAjustes = this.incluirAjustesFormControl.value;
    var arreglo = [];
    if(tipoContenedor == TipoReporteBalance7.ContenedorNatural) {
      arreglo = this.movimientosNaturales
    } else {
      arreglo = this.movimientos15Grados
    }
    
    var dataArray=[
      ["Movimientos por: " + (tipoReporte == TipoReporteMovimientos.CierreTurno ? 'Cierres Turno' : 'Fecha')],      
      [ incluirAjustes ? "Se incluyen ajustes" : "No se incluyen ajustes"],
      ["Listado movimientos " + (tipoContenedor == TipoReporteBalance7.ContenedorNatural ? 'Natural' : '15º')],
      [],
      ["Codigo", "Nombre producto", "Saldo inicial", "Entrada", "Salida", "Saldo final"]
    ];

    for (let i=0; i < arreglo.length; i++) {
      var m = arreglo[i];
      var fila = [m.codigoProducto, m.nombreProducto, this.decimalPipe.transform(m.anterior, "1.0-0"), this.decimalPipe.transform(m.entradas, "1.0-0"), this.decimalPipe.transform(m.salidas, "1.0-0"), this.decimalPipe.transform(m.final, "1.0-0")];
      dataArray.push(fila);
    }

    var filename = this.obtenerCodigoAeroplanta() + " - Listado movimientos (" + (tipoContenedor == TipoReporteBalance7.ContenedorNatural ? 'Natural' : '15º') + ")";
    this.cierreTurnoService.exportAsExcelFile(dataArray, filename);
  }
  
  obtenerCodigoAeroplanta(){
    if(this.usuarioService.getAeroplantaActual().numeroAeroplanta != null){
      return this.usuarioService.getAeroplantaActual().numeroAeroplanta;
    }else{
      return this.codigoAeroplanta;
    } 
  }
 /*  armarKeyMovimiento(m: ReporteMovimientoBalance7){
    return m.codigoProducto + " - " + m.nombreProducto;
  } */

  /* exportarMovimientos(producto: string) { 
    this.excelService.exportAsExcelFile(this.armarArrayExcel(), 'Mediciones '+ producto);
  } */
  
  /* armarArrayExcel() {
    let excelMovimiento: ExcelMovimiento[] = [];

    this.movimientos = this.movimientos.sort((a,b) => a.codigoProducto.localeCompare(b.codigoProducto) || a.nombreContenedor.localeCompare(b.nombreContenedor));

    for(var i=0; i < this.movimientos.length; i++){
      var item = new ExcelMovimiento();
      item.codigoProducto = this.movimientos[i].codigoProducto;
      item.nombreProducto = this.movimientos[i].nombreProducto;
      item.fecha = this.movimientos[i].fechaHora;
      item.titulo = this.movimientos[i].titulo;
      item.entradaNatural = this.movimientos[i].entradaNatural;
      item.salidaNatural = this.movimientos[i].salidaNatural;
      item.entrada15 = this.movimientos[i].entrada15;
      item.salida15 = this.movimientos[i].salida15;
      item.stockNatural = this.movimientos[i].stock;
      item.stock15 = this.movimientos[i].stock15;
      item.comprobante = this.movimientos[i].comprobante.prefijo + '-' + this.movimientos[i].comprobante.numeroComprobante;
      excelMovimiento.push(item);
    }
    return excelMovimiento;
  } */

}
