<form [formGroup]="form" id="islas-card">

  <div class="modal-header bg-light">
    <h4 class="modal-title">Emisión de remito</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close(false)"></button>
  </div>

  <div class="modal-body">

    <div class="row" *ngIf="!this.aeroplantaSoloPad">
      <div class="mb-3 row col-6">
        <label for="prefijoFoxFormControl" class="col-5 col-form-label">*Prefijo Fox:</label>
        <div class="col-7">
          <input class="form-control" formControlName="prefijoFoxFormControl">
          <div class="alert alert-danger"
            *ngIf="(this.prefijoFoxFormControl.invalid && this.prefijoFoxFormControl.errors.required && (this.prefijoFoxFormControl.dirty || this.prefijoFoxFormControl.touched))">
            Debe ingresar el prefijo obligatoriamente.
          </div>
        </div>
      </div>
      <div class="mb-3 row col-6">
        <label for="numeroFoxFormControl" class="col-5 col-form-label">*Numero Fox:</label>
        <div class="col-7">
          <input class="form-control" formControlName="numeroFoxFormControl">
          <div class="alert alert-danger"
            *ngIf="(this.numeroFoxFormControl.invalid && this.numeroFoxFormControl.errors.required && (this.numeroFoxFormControl.dirty || this.numeroFoxFormControl.touched))">
            Debe ingresar el numero obligatoriamente.
          </div>
        </div>
      </div>

    </div>

    <div class="row">

    </div>

    <div class="row">
      <div class="mb-3 row col-6">
        <label for="terminalDestinoFormControl" class="col-5 col-form-label">Terminal</label>
        <div class="col-7">
          <select [compareWith]="compareTerminales" id="terminalDestinoFormControl" class="form-select"
            ngbTooltip="Seleccione la terminal." formControlName="terminalDestinoFormControl"
            (change)="onTerminalChange($event.target.value)">
            <option *ngFor="let terminal of terminales" [ngValue]="terminal">
              ({{ terminal.codigoTerminal }}) - {{ terminal.nombre }}
            </option>
          </select>
          <loading-text-spinner *ngIf="cargandoTerminales" message="Obteniendo terminales..." color="primary">
          </loading-text-spinner>
          <div class="alert alert-danger"
            *ngIf="(this.terminalDestinoFormControl.invalid && this.terminalDestinoFormControl.errors.required && (this.terminalDestinoFormControl.dirty || this.terminalDestinoFormControl.touched))">
            Debe seleccionar una terminal obligatoriamente.
          </div>
        </div>
      </div>


      <div class="mb-3 row col-6">
        <label for="certificadoFormControl" class="col-5 col-form-label">*Nro. certificado analisis:</label>
        <div class="col-7">
          <input class="form-control" formControlName="certificadoFormControl" maxlength="50">
          <div *ngIf="certificadoFormControl.invalid && certificadoFormControl.errors.required &&
                        (certificadoFormControl.dirty || certificadoFormControl.touched)" class="alert alert-danger">
            Requerido</div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="mb-3 row col-6">
        <label for="nroOnuFromControl" class="col-5 col-form-label">*Nro. Riesgo/ONU:</label>
        <div class="col-7">
          <input class="form-control" formControlName="nroOnuFromControl" maxlength="50">
          <div *ngIf="nroOnuFromControl.invalid && nroOnuFromControl.errors.required &&
            (nroOnuFromControl.dirty || nroOnuFromControl.touched)" class="alert alert-danger">Requerido
          </div>
        </div>
      </div>

      <div class="mb-3 row col-6">
        <div class="col-5">Comentario Riesgo/ONU:</div>
        <textarea style="width: 12em;" class="form-control col-7" formControlName="comentarioOnuFormControl" rows='3'></textarea>
      </div>
    </div>

    <div class="row">
      <div class="mb-3 row col-6">
        <label for="prSupFromControl" class="col-5 col-form-label">*PR. SUP.:</label>
        <div class="col-7">
          <textarea class="form-control" formControlName="prSupFromControl" rows='3'></textarea>
          <div *ngIf="prSupFromControl.invalid && prSupFromControl.errors.required &&
              (prSupFromControl.dirty || prSupFromControl.touched)" class="alert alert-danger">Requerido
          </div>
        </div>
      </div>
    </div>

    <div class="row contenedor-form">
      <div class="col-md-3 mb-2">
        <b> Seleccionar que datos cargar</b>
      </div>
      <div class="col-md-3 offset-md-1">
        <input style="cursor: pointer;" type="checkbox" class="form-check-input" id="combustibleFormControl" (change)="this.usuarioTildoCombustible($event.target.checked)" checked/>
        <label class="ms-2 form-check-label" for="combustibleFormControl">Combustible</label>
      </div>  
      
      <div class="col-md-3">
        <input style="cursor: pointer;" type="checkbox" class="form-check-input" id="aeroespecialidadesFormControl" (change)="this.usuarioTildoAeroespecialidades($event.target.checked)"/>
        <label class="ms-2 form-check-label" for="aeroespecialidadesFormControl">Aeroespecialidades</label>
      </div>  
    </div>
  
    <!--COMBUSTIBLES-->
    <div *ngIf="this.mostrarCombustible" style="padding: 1em; border: 1px solid #ccc">
      
      <div class="row">
        <div class="mb-3 row col-6 mt-2">
          <label for="cantidadFromControl" class="col-5 col-form-label">*Cantidad natural:</label>
          <div class="col-7">
            <input class="form-control" formControlName="cantidadFromControl" maxlength="50"
              (keypress)="onlyNumberKey($event)" (change)="litrosA15GradosTotal()">
            <div *ngIf="cantidadFromControl.invalid && cantidadFromControl.errors.required &&
            (cantidadFromControl.dirty || cantidadFromControl.touched)" class="alert alert-danger">Requerido</div>
          </div>
        </div>
        <div class="mb-3 row col-6">
          <label for="cantidad15FromControl" class="col-5 col-form-label">*Cantidad 15º:</label>
          <div class="col-7">
            <input class="form-control disabled" formControlName="cantidad15FromControl" maxlength="50" readonly> 
            <div *ngIf="cantidad15FromControl.invalid && cantidad15FromControl.errors.required &&
            (cantidad15FromControl.dirty || cantidad15FromControl.touched)" class="alert alert-danger">Requerido</div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="mb-3 row col-6">
          <label for="temperaturaFromControl" class="col-5 col-form-label">*Temperatura:</label>
          <div class="col-7">
            <input class="form-control" formControlName="temperaturaFromControl" maxlength="50"
              (change)="litrosA15GradosTodosLosElementos()">
            <div *ngIf="temperaturaFromControl.invalid && temperaturaFromControl.errors.required &&
              (temperaturaFromControl.dirty || temperaturaFromControl.touched)" class="alert alert-danger">Requerido
            </div>
          </div>
        </div>
  
        <div class="mb-3 row col-6">
          <label for="densidadFormControl" class="col-5 col-form-label">*Densidad:</label>
          <div class="col-7">
            <input class="form-control" formControlName="densidadFormControl" maxlength="50"
            (keypress)="formatDensidad($event)" (blur)="litrosA15GradosTodosLosElementos()">
            <div *ngIf="densidadFormControl.invalid && densidadFormControl.errors.required &&
            (densidadFormControl.dirty || densidadFormControl.touched)" class="alert alert-danger">Requerido</div>
            <div class="alert alert-danger"
              *ngIf="(this.densidadFormControl.dirty || this.densidadFormControl.touched) && (this.productoSeleccionado && !this.validarDensidad(this.densidadFormControl.value))">
              La densidad debe estar entre el rango [ {{this.productoSeleccionado.densidadMin}} ,
              {{this.productoSeleccionado.densidadMax}} ]</div>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="this.contenedoresFiltrados!=null">
        <div class="mb-3 row col-12">
          <label for="tanqueGeneralFormControl" class="col-12 col-form-label">*Contenedores</label>
          <div class="col-9">
            <select *ngIf="this.contenedoresFiltrados!=null" id="contenedorFormControl" class="form-select"
              ngbTooltip="Seleccione el contenedor." formControlName="contenedorFormControl">
              <option *ngFor="let c of contenedoresFiltrados" [ngValue]="c">
                {{ c.nombre }} ({{ c.productoActual.codigoProducto }} - {{ c.productoActual.nombreProducto }})
              </option>
            </select>
            <loading-text-spinner *ngIf="this.contenedoresFiltrados == null" message="Obteniendo contenedores.."
              color="primary">
            </loading-text-spinner>
          </div>
          <div class="col-3">
            <button (click)="agregarContenedorSeleccionado()" type="button" class="btn btn-primary">Agregar</button>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="mb-3 row col-12">
          <div class="col-12"><b>Contenedores seleccionados</b></div>
        </div>
      </div>
      <div *ngIf="(this.detalleFormControlArray.controls.length > 0)" class="row ms-3"
        formArrayName="detalleFormControlArray">
        <div class="mb-3 row col-12" style="border: 1px solid #ccc; padding: 1em;"
          *ngFor="let c of contenedoresSeleccionados; let i=index">
          <div [formGroupName]="i">
            <button type="button" (click)="onContenedorSeleccionadoEliminado(i)" class="btn btn-danger ms-3 px-1"
              style="float: right;"><i class="fa fa-trash"></i> Quitar</button>

            <div class="row">
              <div class="mb-3 row col-12">
                <label for="nombreTanque" class="col-6 col-form-label">*Contenedor:</label>
                <div class="col-6">
                  <input class="form-control" formControlName="nombreTanque" maxlength="50">
                </div>
              </div>
            </div>

            <div class="row" *ngIf="c.tipoContenedor == enumTipoContendor.Abastecedora">
              <div class="mb-3 row col-12">
                <label for="picoInicialFormControl" class="col-6 col-form-label">Aforador utilizado:</label>
                <div class="col-6">
                  <select class="form-select" formControlName="picoInicialFormControl">
                    <option *ngFor="let p of this.c.picos" [value]="p">
                      {{p.nombre}}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div class="row" *ngIf="c.tipoContenedor == enumTipoContendor.Abastecedora">
              <div class="mb-3 row col-12">
                <label for="aforadorInicial" class="col-6 col-form-label">Aforador inicial:</label>
                <div class="col-6">
                  <input class="form-control" formControlName="aforadorInicial" maxlength="50"
                    (keypress)="onlyNumberKey($event)" (blur)="calcularCantidad(i)"
                    [value]="this.detalleFormControlArray.controls[i].value.aforadorInicial">
                </div>
              </div>
            </div>

            <div class="row" *ngIf="c.tipoContenedor == enumTipoContendor.Abastecedora">
              <div class="mb-3 row col-12">
                <label for="aforadorFinal" class="col-6 col-form-label">Aforador final:</label>
                <div class="col-6">
                  <input class="form-control" formControlName="aforadorFinal" maxlength="50"
                    (keypress)="onlyNumberKey($event)" (blur)="calcularCantidad(i)"
                    [value]="this.detalleFormControlArray.controls[i].value.aforadorFinal">
                  <div class="alert alert-danger" *ngIf="esMayor">
                    El aforador final debe ser mayor al inicial.
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="mb-3 row col-12">
                <label for="cantidad" class="col-6 col-form-label">Cantidad:</label>
                <div class="col-6">
                  <input class="form-control" formControlName="cantidad" maxlength="50"
                    [readOnly]="c.tipoContenedor == enumTipoContendor.Abastecedora" (keypress)="onlyNumberKey($event)"
                    (change)="litrosA15GradosPorElemento(i)"
                    [value]="this.detalleFormControlArray.controls[i].value.cantidad">
                </div>
              </div>
            </div>

            <div class="row">
              <div class="mb-3 row col-12">
                <label for="cantidad15" class="col-6 col-form-label">Cantidad 15º:</label>
                <div class="col-6">
                  <input class="form-control disabled" formControlName="cantidad15" maxlength="50" readonly>
                </div>
              </div>
            </div>

            <div class="alert alert-danger" *ngIf="!detalleFormControlArray.controls[i].valid">
              Debe cargar datos para este contenedor.
            </div>

          </div>
        </div>
      </div>

      <div class="alert alert-danger" *ngIf="!this.cargandoContenedores && contenedores.length == 0">
        No se obtuvo ningun contenedor, por lo que no se puede realizar la operación.
      </div>

      <div class="alert alert-danger"
        *ngIf="this.contenedores.length > 0 && this.contenedoresSeleccionados.length == 0">
        Debe agregar al menos un contenedor.
      </div>

    </div>

    <!--AEROESPECIALIDADES-->
    <div *ngIf="this.mostrarAeroespecialidades" class="mt-2" style="padding: 1em; border: 1px solid #ccc;">
      <div class="row" *ngIf="this.aeroespecialidades!=null">
        <div class="mb-3 row col-12">
          <label for="aeroespecialidadFormControl" class="col-12 col-form-label">*Aeroespecialidades</label>
          <div class="col-9">
            <select id="aeroespecialidadFormControl" *ngIf="!this.cargandoAeroespecialidades" class="form-select"
              ngbTooltip="Seleccione una aeroespecialidad" formControlName="aeroespecialidadFormControl">
              <option *ngFor="let aeroesp of aeroespecialidades" [ngValue]="aeroesp">
                {{ aeroesp.aeroespecialidad.nombreProducto }} ({{ aeroesp.aeroespecialidad.codigoProducto }})
              </option>
            </select>
            <loading-text-spinner *ngIf="cargandoAeroespecialidades" message="Obteniendo aeroespecialidades.."
              color="primary">
            </loading-text-spinner>
          </div>
          <div class="col-3">
            <button (click)="agregarAeroespeciliadadSeleccionada()" [disabled]="this.cargandoAeroespecialidades"
              type="button" class="btn btn-primary">Agregar</button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="mb-3 row col-12">
          <div class="col-12"><b>Aeroespecialidades seleccionadas</b></div>
        </div>
      </div>
      <div class="alert alert-danger" *ngIf="this.aeroespecialidadesFormControlArray.controls.length == 0">No hay
        aeroespecialidades seleccionadas</div>
      <div *ngIf="(this.aeroespecialidadesFormControlArray.controls.length > 0)" class="row ms-3"
        formArrayName="aeroespecialidadesFormControlArray">

        <div class="mb-3 row col-12" style="border: 1px solid #ccc; padding: 1em;"
          *ngFor="let aep of aeroespecialidadesSeleccionadas; let i=index">
          <div [formGroupName]="i">
            
            <div class="row">
              <div class="mb-3 row col-12">
                <label for="codigoAeroespecialidad" class="col-6 col-form-label">*Aeroespecialidad:</label>
                
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <label class="col-12 col-form-label">
                  <b>{{aep.aeroespecialidad.nombreProducto}}-{{aep.aeroespecialidad.codigoProducto}}</b>
                </label>
                <input type="hidden" formControlName="codigoAeroespecialidad" >
                &nbsp; &nbsp; Cantidad:
                <input type="text" formControlName="cantidad" (keypress)="onlyNumberKey($event)" style="width: 3em; text-align: right; padding-right: 1em;">

                <button type="button" (click)="eliminarAeroespecialidad(i)" class="btn btn-danger ms-3 px-1" style="float: right;"><i class="fa fa-trash"></i> 
                  Quitar
                </button>
              </div>
            </div>
            
            <div class="alert alert-danger" *ngIf="!aeroespecialidadesFormControlArray.controls[i].valid">
              Debe cargar datos para esta aeroespecialidad.
            </div>

          </div>    
        </div>

      </div>
    </div>

    <div class="row mt-3">
      <div class="mb-3 row col-12">
        <label for="cuitTransportistaFromControl" class="col-5 col-form-label">*Cuit transportista:</label>
        <div class="col-7">
          <input id="cuitTransportistaFromControl" type="text" class="form-control"
            formControlName="cuitTransportistaFromControl" [inputFormatter]="formatterTransportistaInput"
            [resultFormatter]="formatterTransportistaResult"
            title="Campo obligatorio, debe seleccionar o ingresar un transportista."
            (selectItem)="selectTransportista($event)" [ngbTypeahead]="searchTransportista"
            [(ngModel)]="modelTransportista" />

          <loading-text-spinner *ngIf="cargandoTransportistas" [error]="errorTransportistas"
            message="Obteniendo Transportistas..." color="primary">
          </loading-text-spinner>

          <div
            *ngIf="cuitTransportistaFromControl.invalid && cuitTransportistaFromControl.errors.required && (cuitTransportistaFromControl.dirty || cuitTransportistaFromControl.touched) "
            class="alert alert-danger">
            Requerido
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-2">
      <div class="mb-3 row col-12">
        <label for="nombreTransportistaFromControl" class="col-5 col-form-label">*Nombre Transportista:</label>
        <div class="col-7">
          <input class="form-control" formControlName="nombreTransportistaFromControl" maxlength="50">
          <div *ngIf="nombreTransportistaFromControl.invalid && nombreTransportistaFromControl.errors.required &&
          (nombreTransportistaFromControl.dirty || nombreTransportistaFromControl.touched)" class="alert alert-danger">
            Requerido
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="mb-3 row col-12">
        <label for="domicilioTransportistaFromControl" class="col-5 col-form-label">*Domicilio
          Transportista:</label>
        <div class="col-7">
          <input class="form-control" formControlName="domicilioTransportistaFromControl" maxlength="50">
          <div *ngIf="domicilioTransportistaFromControl.invalid && domicilioTransportistaFromControl.errors.required &&
          (domicilioTransportistaFromControl.dirty || domicilioTransportistaFromControl.touched)"
            class="alert alert-danger">Requerido</div>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="mb-3 row col-12">
        <label for="documentoConductorFromControl" class="col-5 col-form-label">*DNI conductor:</label>
        <div class="col-7">
          <input id="documentoConductorFromControl" type="text" class="form-control"
            formControlName="documentoConductorFromControl" [inputFormatter]="formatterConductorInput"
            [resultFormatter]="formatterConductorResult"
            title="Campo obligatorio, debe seleccionar o ingresar un conductor." (selectItem)="selectConductor($event)"
            [ngbTypeahead]="searchConductor" (keypress)="onlyNumberKey($event)" [(ngModel)]="modelConductor" />

          <loading-text-spinner *ngIf="cargandoConductores" [error]="errorConductores"
            message="Obteniendo Conductores..." color="primary">
          </loading-text-spinner>

          <div
            *ngIf="documentoConductorFromControl.invalid && documentoConductorFromControl.errors.required && (documentoConductorFromControl.dirty || documentoConductorFromControl.touched) "
            class="alert alert-danger">
            Requerido
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="mb-3 row col-12">
        <label for="nombreConductorFromControl" class="col-5 col-form-label">*Nombre conductor:</label>
        <div class="col-7">
          <input class="form-control" formControlName="nombreConductorFromControl" maxlength="50">
          <div *ngIf="nombreConductorFromControl.invalid && nombreConductorFromControl.errors.required &&
          (nombreConductorFromControl.dirty || nombreConductorFromControl.touched)" class="alert alert-danger">
            Requerido</div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="mb-3 row col-12">
        <label for="precintoDesdeFromControl" class="col-5 col-form-label">*Precinto desde:</label>
        <div class="col-7">
          <input class="form-control" formControlName="precintoDesdeFromControl" maxlength="50"
            (keypress)="onlyNumberKey($event)">
          <div *ngIf="precintoDesdeFromControl.invalid && precintoDesdeFromControl.errors.required &&
          (precintoDesdeFromControl.dirty || precintoDesdeFromControl.touched)" class="alert alert-danger">
            Requerido</div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="mb-3 row col-12">
        <label for="precintoHastaFromControl" class="col-5 col-form-label">*Precinto hasta:</label>
        <div class="col-7">
          <input class="form-control" formControlName="precintoHastaFromControl" maxlength="50"
            (keypress)="onlyNumberKey($event)">
          <div *ngIf="precintoHastaFromControl.invalid && precintoHastaFromControl.errors.required &&
          (precintoHastaFromControl.dirty || precintoHastaFromControl.touched)" class="alert alert-danger">
            Requerido</div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="mb-3 row col-12">
        <label for="capacidadFromControl" class="col-5 col-form-label">*Capacidad Camión:</label>
        <div class="col-7">
          <input class="form-control" formControlName="capacidadFromControl" maxlength="50"
            (keypress)="onlyNumberKey($event)">
          <div *ngIf="capacidadFromControl.invalid && capacidadFromControl.errors.required &&
          (capacidadFromControl.dirty || capacidadFromControl.touched)" class="alert alert-danger">
            Requerido</div>
        </div>
      </div>
    </div>

    <div style="padding: 1em; border: 1px solid #ccc; margin-top: 2em;">
      <div class="row" *ngIf="this.patentes!=null">
        <div class="mb-3 row col-12">
          <label for="patenteFormControl" class="col-12 col-form-label">Agregar patente</label>
          <div class="col-9">
            <input class="form-control" formControlName="patenteFormControl" maxlength="50">
          </div>
          <div class="col-3">
            <button (click)="agregarPatente()" type="button" class="btn btn-primary">Agregar</button>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="mb-3 row col-12">
          <div class="col-12"><b>Patentes agregadas</b></div>
        </div>
      </div>
      <div *ngIf="(patentes.length > 0)" class="row">
        <div class="d-flex" style="padding: 0.25em; margin-left: 4em;" *ngFor="let p of patentes">
          <label class="col-8 col-form-label">{{p}}</label>
          <button type="button" (click)="onEliminarPatente(p)" ngbTooltip="Quitar patente" class="btn btn-danger ms-2 px-1">
            <i class="fa fa-trash"></i>
          </button>
        </div>
      </div>

      <div class="alert alert-danger" *ngIf="this.patentes.length == 0">
        Debe agregar al menos una patente.
      </div>
    </div>

    <div class="row">
      <div class="mb-3 row col-12 mt-2">
        <div class="col-12">Comentario</div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <textarea class="form-control col-12" formControlName="comentarioFormControl" rows='5'></textarea>
      </div>
    </div>



  </div><!-- Del modal body -->

  <div class="modal-footer bg-light">
    <div class="row">
      <div class="alert alert-danger" *ngIf="error != null">
        {{ error }}
      </div>
    </div>

    <span class="border">
      <button class="btn btn-primary" (click)="onSubmit()"
        [disabled]="this.guardandoDatos" type="submit">
        {{ this.guardandoDatos? "Guardando..." : "Guardar cambios" }}
      </button>
    </span>

    <span class="border">
      <button type="button" class="btn btn-secondary" [disabled]="this.guardandoDatos"
        (click)="activeModal.close(false)">Cancelar</button>
    </span>
  </div>
</form>