<div class="modal-header">
  <h5 class="modal-title">Motivo de rechazo</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="modal.close(null)"></button>
</div>
<div class="modal-body">
  <form [formGroup]="form">
    <div class="card-body">
      <div class="mb-3 row">
        <label class="col-md-3 col-form-label" for="motivoFormControl">*Motivo:</label>
        <div class="col col-md-8">
          <textarea id="motivoFormControl" class="form-control" formControlName="motivoFormControl"
            maxlength="255" placeholder="Ingrese el motivo de rechazo..." rows="6"></textarea>
        </div>
      </div>
    </div>
  </form>
</div>
<small class="text-danger">*Campos obligatorios.</small>
<div class="modal-footer text-center">
  <button class="btn btn-primary" [disabled]="!form.valid" (click)="rechazar()"> Rechazar</button>
  <button class="btn btn-secondary" (click)="modal.close(null)">Cancelar</button>
</div>
