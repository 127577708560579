import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { TipoContenedor } from '../../enums/tipoContenedor';
import { Aeroplanta } from '../despacho/Aeroplanta';

export class Medicion{
  id: string;
  codigoContenedor: string;
  nombreContenedor: string;
  tipoContenedor: TipoContenedor;
  varillado: number;
  nivel: number;
  nivelNatural: number;
  temperatura: number;
  densidad: number;
  nivelAgua: number;
  fechaHora: Date;
  username: string;
}
