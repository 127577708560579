import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { CondicionVenta } from 'src/app/shared/enums/condicionVenta';
import { TipoVenta } from 'src/app/shared/enums/tipoVenta';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { ActualizarFecha } from 'src/app/shared/models/precios/ActualizarFecha';
import { PrecioLote } from 'src/app/shared/models/precios/PrecioLote';
import { MessageService } from 'src/app/shell/message.service';
import { PreciosService } from '../../precios.service';

@Component({
  selector: 'app-modal-cambiar-fecha-desde',
  templateUrl: './modal-cambiar-fecha-desde.component.html',
  styleUrls: ['./modal-cambiar-fecha-desde.component.css']
})
export class ModalCambiarFechaDesdeComponent implements OnInit {

  @Input() precio: PrecioLote;

  modelFechaDesde: NgbDateStruct;
  modelHora: any;
  errorFecha: string = "";
  guardandoFechaInicio: boolean = false;

  constructor(
    private preciosService: PreciosService,
    private activeModal: NgbActiveModal,
    private calendar: NgbCalendar,
    private messageService: MessageService){

  }

  // hooks

  ngOnInit() {
    console.log("el id que seleccione del precio es "+this.precio.id);
    this.modelFechaDesde = this.convertStringToModel(this.precio.fechaDesde);
    var hora = this.precio.fechaDesde.split("T")[1];
    this.modelHora = { hour: +hora.split(":")[0], minute: +hora.split(":")[1], second: +hora.split(":")[2] };
  }


  // llamadas al service

  onSubmit() {
    this.errorFecha = "";

    var desde =  new Date(this.formatDate(this.modelFechaDesde.year, this.modelFechaDesde.month, this.modelFechaDesde.day, this.modelHora.hour, this.modelHora.minute));

    // validar que la nueva fecha sea mayor a la fechaDesde anterior y sea menor a la fecha hasta.
    if( this.fechaDesfazada(desde) ){
      return;
    }

    this.guardandoFechaInicio = true;

    var dto = new ActualizarFecha();
    dto.fechaHora = this.formatDate(this.modelFechaDesde.year, this.modelFechaDesde.month, this.modelFechaDesde.day, this.modelHora.hour, this.modelHora.minute);
    dto.responsable = this.preciosService.obtenerStringResponsableV2();
    this.preciosService.actualizarFechaDesdePrecio(this.precio.id, dto)
      .subscribe(result => {
        this.activeModal.close(this.precio);
        this.messageService.showSuccessLongMessage("Se actualizo la fecha desde.");
        this.guardandoFechaInicio = false;
      },
        (err: ErrorModel) => {
          this.messageService.showErrorMessage("Error al actualizar fecha desde.");
          this.errorFecha = "Error al actualizar fecha desde.";
          this.guardandoFechaInicio = false;
        });
  }

  // form

  convertStringToModel(value: string): NgbDateStruct{
    if (!value)
     return null
    let parts=value.split('-');
    return {year:+parts[0],month:+parts[1],day:+parts[2].split('T')[0]}
 }

 formatDate(year, month, day, hour, minute): string {
   return year + '-' + month.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + '-' + day.toLocaleString(undefined, { minimumIntegerDigits: 2 })
     + 'T' + hour.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + minute.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':00';
 }

  fechaDesfazada(fechaDesde){
    var fechaDesdeOrig = this.precio.fechaDesde.split("T")[0].split("-");
    var horaDesdeOrig = this.precio.fechaDesde.split("T")[1].split(":");
    var fechaHastaOrig = this.precio.fechaHasta.split("T")[0].split("-");
    var horaHastaOrig = this.precio.fechaHasta.split("T")[1].split(":");

    try{
      if (fechaDesde <= new Date(+fechaDesdeOrig[0], +fechaDesdeOrig[1]-1, +fechaDesdeOrig[2], +horaDesdeOrig[0], +horaDesdeOrig[1], +horaDesdeOrig[2])) {
        this.errorFecha = "La fecha Desde no puede ser menor a la fecha desde anterior ni mayor a la fecha Hasta.";
        return true;
      }

      if (fechaDesde >= new Date(+fechaHastaOrig[0], +fechaHastaOrig[1]-1, +fechaHastaOrig[2], +horaHastaOrig[0], +horaHastaOrig[1], +horaHastaOrig[2])) {
        this.errorFecha = "La fecha Desde no puede ser menor a la fecha desde anterior ni mayor a la fecha Hasta.";
        return true;
      }
    }catch(ex){
      this.errorFecha = "Ingreso una fecha hora invalida.";
      return true;
    }

    return false;
  }

  onCancelar() {
    this.activeModal.dismiss();
  }

  // getters

  descripcionTipoVenta(tipoVenta){
    if(tipoVenta == TipoVenta.cabotaje){
      return "Cabotaje";
    }else if(tipoVenta == TipoVenta.internacional){
      return "Internacional";
    }else{
      return "-";
    }
  }

  descripcionCondicionVenta(condicionVenta){
    if(condicionVenta == CondicionVenta.contado){
      return "Contado";
    }else if(condicionVenta == CondicionVenta.cuentaCorriente){
      return "Cta. Cte.";
    }else{
      return "-";
    }
  }

}
