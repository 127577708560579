import { Component, OnInit, Input } from '@angular/core';
import { ParametrosImpositivosService } from '../../parametros-impositivos.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Localidad } from 'src/app/shared/models/despacho/Localidad';
import { Provincia } from 'src/app/shared/models/facturacion/Provincia';

@Component({
  selector: 'app-modal-ciudad-v2',
  templateUrl: './modal-ciudad-v2.component.html',
  styleUrls: ['./modal-ciudad-v2.component.css']
})
export class ModalCiudadComponentV2 implements OnInit {

  @Input() provinciaCodigo: string;

  ciudades: Localidad[];
  ciudadSeleccionada: Localidad;
  obteniendoCiudades: boolean = true;

  constructor(private parametrosService: ParametrosImpositivosService,
    private activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.parametrosService.obtenerLocalidades().subscribe(
      (ciudades: Localidad[]) => {
        // Todo: filtrar las ciudades que ya estan asociadas a la localidad
        //const codigosProvExistentes = this.parametrosService.provinciaEncabezadoSeleccionada.value.map(p => p.ciudadCodigo);
        //this.ciudades = ciudades.filter(p => !codigosProvExistentes.includes(p.codigo));

        this.parametrosService.obtenerProvincias('0303'/*ARG*/).subscribe(
          (provincias: Provincia[]) => {
            let provincia = provincias.find(p => p.codigo === this.provinciaCodigo);
            this.ciudades = ciudades.filter(c => c.provinciaId == provincia.id);
            this.ciudades.sort((a,b) => (a.nombre > b.nombre) ? 1 : -1);
            this.obteniendoCiudades = false;
        });        
      }
    );
  }

  onVolverClicked() {
    this.activeModal.dismiss();
  }

  onCrearClicked() {
    this.activeModal.close(this.ciudadSeleccionada);
  }
}
