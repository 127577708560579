import { Component, OnInit, Input, SimpleChanges, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { AerovaleDetalle } from 'src/app/shared/models/despacho/AerovaleDetalle';
import { AerovaleDetalleAlije } from 'src/app/shared/models/despacho/AerovaleDetalleAlije';
import { TipoPago } from 'src/app/shared/enums/tipoPago';
import { DomSanitizer } from '@angular/platform-browser';
import { EstadoAerovale } from 'src/app/shared/enums/estadoAerovale';
import { EstadoPruebaLaboratorio } from 'src/app/shared/enums/estadoPruebaLaboratorio';
import { TipoVuelo } from 'src/app/shared/enums/tipoVuelo';
import { Observacion } from 'src/app/shared/models/despacho/Observacion';
import { TipoAerovale } from 'src/app/shared/enums/tipoAerovale';
import { Laboratorio } from 'src/app/shared/models/despacho/Laboratorio';
import { LaboratorioService } from '../Laboratorio.service';
import { Subscription } from 'rxjs';
import { VisorAerovalesComponent } from '../visor-aerovales/visor-aerovales.component';
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';
@Component({
  selector: 'app-aerovale-detalle',
  templateUrl: './aerovale-detalle.component.html',
  styleUrls: ['./aerovale-detalle.component.css']
})
export class AerovaleDetalleComponent implements OnInit {

  @Input() aerovaleDetalle: AerovaleDetalle;
  @Input() aerovaleDetalleAlije: AerovaleDetalleAlije;
  @Output() fileAerovaleFisico: EventEmitter<File> = new EventEmitter;
  capturarAerovale$: Subscription;

  mostrarComentario = false;
  mostrarDDJJGranel = false;

  TipoVuelo = TipoVuelo;
  TipoAerovale = TipoAerovale;
  ObserNormal: Observacion[] = [];
  ObserAnulado: Observacion[] = [];
  esContingencia: boolean = false;
  laboratorios: Laboratorio[]=[];

  constructor(
    private sanitizer: DomSanitizer, 
    private laboratorioService: LaboratorioService,
    private visorAerovales: VisorAerovalesComponent
  ) { }

  operadorImagePath;
  clienteImagePath;


  // hooks

  ngOnInit() {
    this.getLaboratorios();
    this.esContingencia = Number(this.aerovaleDetalle.prefijo) < 200;    
    if (this.aerovaleDetalle) {
      this.generarResourceUrls();
    }
    this.armarObservaciones();
    this.capturarAerovale$ = this.visorAerovales.captureImage.subscribe(changed => {
      this.capturarImagenAerovale();
    });
  }

  ngOnDestroy(): void {
    this.capturarAerovale$.unsubscribe(); 
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (this.aerovaleDetalle) {
      this.generarResourceUrls();
    }
  }

  getLaboratorios() {
    this.laboratorioService.getLaboratorios()
      .subscribe(result => {
        this.laboratorios = result;
      }, );
  }

  // armado de campos

  armarObservaciones() {
    let j = 0;
    let h = 0;
    for (let i = 0; this.aerovaleDetalle.observaciones.length > i; i++) {
      if (this.aerovaleDetalle.observaciones[i].tipoObservacion == 0) {
        this.ObserNormal[j] = this.aerovaleDetalle.observaciones[i];
        j += j;
      } else {
        this.ObserAnulado[h] = this.aerovaleDetalle.observaciones[i];
        h += h;
      }
    }
  }


  generarResourceUrls() {
    this.operadorImagePath = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,' + this.aerovaleDetalle.firmaOperador);
    this.clienteImagePath = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,' + this.aerovaleDetalle.firmaCliente);
  }

  ImagenFirmaOperador(): string {
    let firma: string = ""
    if (this.aerovaleDetalle.firmaOperador != null && this.aerovaleDetalle.firmaOperador != "") { firma = this.operadorImagePath; }
    if (this.aerovaleDetalle.pathFirmaOperador != null && this.aerovaleDetalle.pathFirmaOperador != "") { firma = this.aerovaleDetalle.pathFirmaOperador; }
    return firma;
  }

  ImagenFirmaCliente(): string {
    let firma: string = ""
    if (this.aerovaleDetalle.firmaCliente != null && this.aerovaleDetalle.firmaCliente != "") { firma = this.clienteImagePath; }
    if (this.aerovaleDetalle.pathFirmaCliente != null && this.aerovaleDetalle.pathFirmaCliente != "") { firma = this.aerovaleDetalle.pathFirmaCliente; }
    return firma;
  }

  esAevReemplazo() {
    if(this.aerovaleDetalle)
      return Number(this.aerovaleDetalle.prefijo) >=900
    else 
      return null;    
  }

  obtenerAforadorInicioDeAerovale() {
    /* 0: AerovaleAerocombustible | 1: AerovaleAeroespecialidad | 2: AerovaleCombustible | 3: AerovaleAlije | 4: Servicio | 5: Devolucion */
    let resultado = 0;
    if (this.aerovaleDetalle.tipoAerovale == TipoAerovale.Aerocombustible || this.aerovaleDetalle.tipoAerovale == TipoAerovale.Devolucion) {
      resultado = this.aerovaleDetalle.detalleAerocombustible.aforadorInicio;
    }
    if (this.aerovaleDetalle.tipoAerovale == TipoAerovale.Combustible) {
      resultado = this.aerovaleDetalle.detalleCombustible.aforadorInicio;
    }
    if (this.aerovaleDetalle.tipoAerovale == TipoAerovale.Alije) {
      resultado = this.aerovaleDetalle.detalleAerocombustible.aerovaleDetalleAlije.varilladoInicio;
    }    
    return resultado;
  }
  obtenerAforadorFinDeAerovale() {
    /* 0: AerovaleAerocombustible | 1: AerovaleAeroespecialidad | 2: AerovaleCombustible | 3: AerovaleAlije | 4: Servicio | 5: Devolucion */
    let resultado = 0;
    if (this.aerovaleDetalle.tipoAerovale == TipoAerovale.Aerocombustible || this.aerovaleDetalle.tipoAerovale == TipoAerovale.Devolucion) {
      resultado = this.aerovaleDetalle.detalleAerocombustible.aforadorFinal;
    }
    if (this.aerovaleDetalle.tipoAerovale == TipoAerovale.Combustible) {
      resultado = this.aerovaleDetalle.detalleCombustible.aforadorFinal;
    }
    if (this.aerovaleDetalle.tipoAerovale == TipoAerovale.Alije) {
      resultado = this.aerovaleDetalle.detalleAerocombustible.aerovaleDetalleAlije.varilladoFinal;
    }        
    return resultado;
  }

  mostrarFirma(): boolean {
    let mostrar: boolean = false;
    mostrar = (EstadoAerovale[this.aerovaleDetalle.estado] == EstadoAerovale.Finalizado || 
              EstadoAerovale[this.aerovaleDetalle.estado] == EstadoAerovale.FinalizadoSinFirma || 
              EstadoAerovale[this.aerovaleDetalle.estado] == EstadoAerovale.Sincronizado ||
              EstadoAerovale[this.aerovaleDetalle.estado] == EstadoAerovale.Anulado);
    return mostrar;
  }

  esAlije() {
    return this.aerovaleDetalle.tipoAerovale == TipoAerovale.Alije;
  }

  esAerovaleAdjuntado() {
    let es: boolean = false;

    let nombreArchivoOperador: string = 'o';
    let nombreArchivoCliente: string = 'c';
    if (this.aerovaleDetalle.pathFirmaOperador != null) {
      let pos1: number = this.aerovaleDetalle.pathFirmaOperador.indexOf('_');
      let posFinal1: number = this.aerovaleDetalle.pathFirmaOperador.indexOf('?')
      nombreArchivoOperador = this.aerovaleDetalle.pathFirmaOperador.substring(pos1, posFinal1);
    }
    if (this.aerovaleDetalle.pathFirmaCliente != null) {
      let pos2: number = this.aerovaleDetalle.pathFirmaCliente.indexOf('_');
      let posFinal2: number = this.aerovaleDetalle.pathFirmaCliente.indexOf('?')
      nombreArchivoCliente = this.aerovaleDetalle.pathFirmaCliente.substring(pos2, posFinal2);
    }
    es = nombreArchivoCliente.toString() === nombreArchivoOperador.toString();
    return es;
  }


  // getters

  getLaboratorio(codigo:string): string
  {
    try{
      if (codigo!=null)
      {
        let l:Laboratorio= this.laboratorios.find(l=>l.codigoLaboratorio===codigo);
        return l.nombre;
      }
      return "";
    }catch(Exception){
      return "";
    }    
  }

  getTipoPago(tipo: number) {
    return TipoPago[tipo];
  }

  getTipoAerovale() {
    return TipoAerovale[this.aerovaleDetalle.tipoAerovale];
  }

  getTipoVuelo(tipo: number) {
    return TipoVuelo[tipo];
  }

  getEstadoPruebaLaboratorio(estado: EstadoPruebaLaboratorio): string {
    return EstadoPruebaLaboratorio[estado];
  }

  capturarImagenAerovale() {
    let element = document.getElementById("aerovalePrint");

    html2canvas(element, {
        allowTaint: false,
        useCORS: true
      }).then( canvas => {
        canvas.toBlob( blob => {
            let base64 = canvas.toDataURL("image/jpg");
            this.uploadImage(base64);                            
        },'image/jpg');
      });
  }


  async uploadImage(b64img: string) {
    let archivo:string='AevAnulado_'+this.aerovaleDetalle.id+'.jpg'
    var file = await this.urltoFile(b64img,archivo,this.base64MimeType(b64img));

    // autosave
    /*const blob = new Blob([file], { type: 'application/jpg;' });
      saveAs(blob, 'AevAnulado_'+this.aerovaleDetalle.id+'.jpg');*/

    this.fileAerovaleFisico.emit(file);
    console.log(file);
  }
  
  //return a promise that resolves with a File instance
  urltoFile(url, filename, mimeType){
      return (fetch(url)
          .then(function(res){return res.arrayBuffer();})
          .then(function(buf){return new File([buf], filename,{type:mimeType});})
      );
  }
  
  //return mime Type of bs64
  base64MimeType(encoded) {
      var result = null;
    
      if (typeof encoded !== 'string') {
        return result;
      }
    
      var mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
    
      if (mime && mime.length) {
        result = mime[1];
      }
    
      return result;
  }
}




