<div class="modal-header bg-light">
  <h4 class=" modal-title text-center">Nuevo Producto </h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismissAll()"></button>
</div>
<div class="modal-body">
  <form [formGroup]="nuevoProductoForm">
    <div class="row">

      <!-- codigo -->
      <div class="mb-3 col-md-6">
        <div class="mb-3 row">
          <div class="col-md-5">
            <label for="codigoControlInput" class="col-form-label"> <span
                *ngIf="esCampoVacio(this.codigo.value)">*</span> Codigo:</label>
          </div>
          <div class="col-md-4">
            <div class="input-group mb-2">
              <input id="codigoControlInput" type="text" class="form-control mb-2 me-sm-2"
                title="Campo obligatorio, debe ingresar un codigo de producto." formControlName="codigoFormControl"
                placeholder="###" />
            </div>
            <div *ngIf="this.productos!=null && this.estaCodigoProducto()===true" class="alert alert-danger">
              El codigo ya existe
            </div>
          </div>
        </div>
      </div>

      <!--TIPO DE RUBRO-->
      <div class="mb-3 col-md-6">
        <div class="mb-3 row">
          <div class="col-md-5">
            <label for="tipoProductoControlInput" class="col-form-label">Tipo/Rubro</label>
          </div>
          <div class="col-md-7">
            <div class="input-group mb-2">
              <select type="text" class="form-select" name="tipoID" formControlName="tipoProductoFormControl"
                title="Campo obligatorio, seleccione un tipo de rubro.">
                <option id="optionUnidadMedida" *ngFor="let tipo of rubros" [selected]="0" [ngValue]="tipo"
                  ([change])="limpiarValidacion()">
                  {{ tipo.nombre }}
                </option>
              </select>
            </div>
            <loading-text-spinner *ngIf="this.cargandoRubros===true" message="Obteniendo rubros..." color="primary">
            </loading-text-spinner>
          </div>
        </div>
      </div>

      <!-- Nombre -->
      <div class="mb-3 col-md-6">
        <div class="mb-3 row">
          <div class="col-md-5">
            <label for="prefijoControlSelect" class="col-form-label"><span
                *ngIf="esCampoVacio(this.descripcion.value)">*</span> Nombre: </label>
          </div>
          <div class="col-md-7">
            <div class="input-group mb-2">
              <input id="prefijoControlInput" type="text" class="form-control mb-2 me-sm-2"
                title="Campo obligatorio, debe ingresar un nombre del producto."
                formControlName="descripcionFormControl" placeholder="###" />
            </div>
          </div>
        </div>
      </div>

      <!-- Unidad de medida -->
      <div class="mb-3 col-md-6">
        <div class="mb-3 row">
          <div class="col-md-5">
            <label for="observacionesControlSelect" class="col-form-label">Unidad de medida:</label>
          </div>
          <div class="col-md-7">
            <div class="input-group mb-2">
              <select id="unidadMedidaFormControl" type="text" class="form-select" name="unidadDeMedidaID"
                formControlName="unidadMedidaFormControl" title="Campo obligatorio, Seleccione una unidad de medida.">
                <option id="optionUnidadMedida" *ngFor="let unidad of keysUnidadDeMedida()"
                  [ngValue]="unidadMedida[unidad]">
                  {{ unidad }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <!--TIPO PRECIO-->
      <div class="mb-3 col-md-6">
        <div class="mb-3 row">
          <div class="col-md-5">
            <label for="tipoPrecioControlInput" class="col-form-label"> Tipo de precio: </label>
          </div>
          <div class="col-md-7">
            <div class="input-group mb-2">
              <select id="tipoPrecioFormControl" type="number" class="form-select" name="tipoPrecioID"
                formControlName="tipoPrecioFormControl" title="Campo obligatorio, Seleccione un tipo de precio.">
                <option id="optionUnidadMedida" *ngFor="let precio of keysTipoPrecio()" [ngValue]="tipoPrecio[precio]">
                  {{ precio }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <!--COLOR-->
      <div class="mb-3 col-md-6">
        <div class="mb-3 row">
          <div class="col-md-5">
            <label for="colorFormControl" class="col-form-label">Color: </label>
          </div>
          <div class="col-md-7">
              <select class="form-select" id="product-color" (change)="onChange($event.target.value)" [ngStyle]="{'background-color':selectedColor}">
                <option *ngFor="let color of colores" [value]="color.value" [ngStyle]="{'background-color':color.value}">{{color.name}}</option>
            </select>
          </div>
        </div>
      </div>

      <!--DENSIDADES-->
      <div class="mb-3 col-md-6"
        *ngIf="this.tipoDeProducto.value != null && this.tipoDeProducto.value?.aerocombustible || this.tipoDeProducto.value?.combustible">
        <div class="mb-3 row">
          <div class="col-md-5">
            <label for="densidadMinimaFormControl" class="col-form-label">Densidad mínima: </label>
          </div>
          <div class="col-md-7">
            <div class="input-group mb-2">
              <input id="densidadMinimaFormControl" type="text" class="form-control mb-2 me-sm-2"
                title="Campo obligatorio, debe ingresar la densidad." placeholder="####"
                formControlName="densidadMinimaFormControl" (keypress)="onlyDecimalNumberKey($event)"
                (change)="densidadMenor()" />
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3 col-md-6"
        *ngIf="this.tipoDeProducto.value != null && this.tipoDeProducto.value?.aerocombustible || this.tipoDeProducto.value?.combustible">
        <div class="mb-3 row">
          <div class="col-md-5">
            <label for="densidadMaximaFormControl" class="col-form-label">Densidad máxima: </label>
          </div>
          <div class="col-md-7">
            <div class="input-group mb-2">
              <input id="densidadMaximaFormControl" type="text" class="form-control mb-2 me-sm-2"
                title="Campo obligatorio, debe ingresar la densidad." placeholder="####"
                formControlName="densidadMaximaFormControl" (keypress)="onlyDecimalNumberKey($event)"
                (change)="densidadMenor()" />
            </div>
            <div class="alert alert-danger" *ngIf="this.densidadMenor()">La densidad máxima no puede ser menor que la
              mínima</div>
          </div>
        </div>
      </div>

      <!--PORC ALIJE EN AEROCOMBUSTIBLES-->
      <div class="mb-3 col-md-6"
        *ngIf="this.tipoDeProducto.value != null && this.tipoDeProducto.value?.aerocombustible">
        <div class="mb-3 row">
          <div class="col-md-5">
            <label for="porcentajeAlijeFormControl" class="col-form-label">Porcentaje Alije: </label>
          </div>
          <div class="col-md-7">
            <div class="input-group mb-2">
              <input id="porcentajeAlijeFormControl" type="number" class="form-control mb-2 me-sm-2"
                title="Campo obligatorio, debe ingresar el porcentaje de Alije." placeholder="%"
                formControlName="porcentajeAlijeFormControl" (keypress)="onlyDecimalNumberKey($event)"
                [min]="0" [max]="100" />
            </div>
          </div>
        </div>
      </div>

      <!--TIPO COMBUSTIBLE-->
      <div class="mb-3 col-md-6"
        *ngIf="this.tipoDeProducto.value != null && this.tipoDeProducto.value?.combustible">
        <div class="mb-3 row">
          <div class="col-md-5">
            <label for="tipoCombustibleControlInput" class="col-form-label">Tipo combustible:</label>
          </div>
          <div class="col-md-7">
            <div class="input-group mb-2">
              <select id="tipoCombustibleFormControl" type="text" class="form-select" name="tipoPrecioID"
                formControlName="tipoCombustibleFormControl" (change)="this.obtenerRangoDensidad()"
                title="Campo obligatorio, Seleccione un tipo de combustible." [selectedIndex]="0">
                <option id="optionTipoCombustible" *ngFor="let comb of keysTipoCombustible()" [ngValue]="this.tipoCombustible[comb]">
                  {{ comb }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>

       <!--TIPO AEROCOMBUSTIBLE-->
      <div class="mb-3 col-md-6"
        *ngIf="this.tipoDeProducto.value != null && this.tipoDeProducto.value?.aerocombustible">
        <div class="mb-3 row">
          <div class="col-md-5">
            <label for="tipoAerocombustibleControlInput" class="col-form-label">Tipo aerocombustible:</label>
          </div>
          <div class="col-md-7">
            <div class="input-group mb-2">
              <select id="tipoAerocombustibleFormControl" type="text" class="form-select" name="tipoPrecioID"
                formControlName="tipoAerocombustibleFormControl" (change)="this.obtenerRangoDensidad()"
                title="Campo obligatorio, Seleccione un tipo de aerocombustible." [selectedIndex]="0">
                <option id="optionTipoAerocombustible" *ngFor="let aerocomb of keysTipoAerocombustible()" [ngValue]="this.tipoAerocombustible[aerocomb]">
                  {{ aerocomb }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <!--TIPO SERVICIO-->
      <div class="mb-3 col-md-6"
        *ngIf="this.tipoDeProducto.value != null && this.tipoDeProducto.value?.servicios">
        <div class="mb-3 row">
          <div class="col-md-5">
            <label class="col-form-label">Tipo de servicio:</label>
          </div>
          <div class="col-md-7">
            <div class="input-group mb-2">
              <select id="tipoServicioFormControl" type="text" class="form-select" name="tipoServicioID"
                formControlName="tipoServicioFormControl"
                title="Campo obligatorio, Seleccione un tipo de servicio." [selectedIndex]="0">
                <option id="optionTipoServicio" *ngFor="let servicio of keysTipoServicio()" [ngValue]="this.tipoServicio[servicio]">
                  {{ servicio }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <!--COMIENZO NUEVOS DATOS PRODUCTO-->
      <!--PELIGROSIDAD-->
      <div class="mb-3 col-md-6">
        <div class="mb-3 row">
          <div class="col-md-5">
            <label for="peligrosidadFormControl" class="col-form-label">*Peligrosidad: </label>
          </div>
          <div class="col-md-7">
            <div class="input-group mb-2">
              <input id="peligrosidadFormControl" type="text" class="form-control"
                formControlName="peligrosidadFormControl" placeholder="Peligrosidad" maxlength="6" />
            </div>
          </div>
        </div>
      </div>
      <!--AGRUPACION CONTABLE-->
      <div class="mb-3 col-md-6">
        <div class="mb-3 row">
          <div class="col-md-5">
            <label for="contableFormControl" class="col-form-label">*Agrupación contable: </label>
          </div>
          <div class="col-md-7">
            <div class="input-group mb-2">
              <input id="contableFormControl" type="text" class="form-control" formControlName="contableFormControl"
                placeholder="Contable" maxlength="2" />
            </div>
          </div>
        </div>
      </div>
      <!--PARTIDA-->
      <div class="mb-3 col-md-6">
        <div class="mb-3 row">
          <div class="col-md-5">
            <label for="partidaFormControl" class="col-form-label">*Partida: </label>
          </div>
          <div class="col-md-7">
            <div class="input-group mb-2">
              <input id="partidaFormControl" type="text" class="form-control" formControlName="partidaFormControl"
                placeholder="Partida" maxlength="20" />
            </div>
          </div>
        </div>
      </div>
      <!--PRODUCTOSAP-->
      <div class="mb-3 col-md-6">
        <div class="mb-3 row">
          <div class="col-md-5">
            <label for="productoSAPFormControl" class="col-form-label">*Producto SAP: </label>
          </div>
          <div class="col-md-7">
            <div class="input-group mb-2">
              <input id="productoSAPFormControl" type="text" class="form-control"
                formControlName="productoSAPFormControl" placeholder="Producto SAP"  maxlength="20"/>
            </div>
          </div>
        </div>
      </div>
      <!--UNIDADMEDIDASAP-->
      <div class="mb-3 col-md-6">
        <div class="mb-3 row">
          <div class="col-md-5">
            <label for="unidadSAPFormControl" class="col-form-label">*U. medida SAP: </label>
          </div>
          <div class="col-md-7">
            <div class="input-group mb-2">
              <input id="unidadSAPFormControl" type="text" class="form-control" formControlName="unidadSAPFormControl"
                placeholder="Unidad medida SAP" maxlength="20"/>
            </div>
          </div>
        </div>
      </div>
      <!--UNIDAD NEGOCIO GERENCIAL-->
      <div class="mb-3 col-md-6">
        <div class="mb-3 row">
          <div class="col-md-5">
            <label for="negocioGerencialFormControl" class="col-form-label">*U. negocio gerencial: </label>
          </div>
          <div class="col-md-7">
            <div class="input-group mb-2">
              <input id="negocioGerencialFormControl" type="text" class="form-control"
                formControlName="negocioGerencialFormControl" placeholder="Unidad negocio gerencial" maxlength="2" />
            </div>
          </div>
        </div>
      </div>
      <!--UNIDAD MEDIDA STOCK-->
      <div class="mb-3 col-md-6">
        <div class="mb-3 row">
          <div class="col-md-5">
            <label for="unidadStockFormControl" class="col-form-label">*U. medida stock: </label>
          </div>
          <div class="col-md-7">
            <div class="input-group mb-2">
              <input id="unidadStockFormControl" type="text" class="form-control"
                formControlName="unidadStockFormControl" placeholder="Unidad medida Stock" maxlength="2" />
            </div>
          </div>
        </div>
      </div>
      <!--UNIDAD MEDIDA COMERCIAL-->
      <div class="mb-3 col-md-6">
        <div class="mb-3 row">
          <div class="col-md-5">
            <label for="unidadComercialFormControl" class="col-form-label">*U. medida comercial: </label>
          </div>
          <div class="col-md-7">
            <div class="input-group mb-2">
              <input id="unidadComercialFormControl" type="text" class="form-control"
                formControlName="unidadComercialFormControl" placeholder="Unidad medida comercial" maxlength="2" />
            </div>
          </div>
        </div>
      </div>
      <!--UNIDAD MEDIDA PRODUCCION-->
      <div class="mb-3 col-md-6">
        <div class="mb-3 row">
          <div class="col-md-5">
            <label for="unidadProduccionFormControl" class="col-form-label">*U. medida producción: </label>
          </div>
          <div class="col-md-7">
            <div class="input-group mb-2">
              <input id="unidadProduccionFormControl" type="text" class="form-control"
                formControlName="unidadProduccionFormControl" placeholder="Unidad medida producción" maxlength="2" />
            </div>
          </div>
        </div>
      </div>
      <!--GRUPO ENVASE-->
      <div class="mb-3 col-md-6">
        <div class="mb-3 row">
          <div class="col-md-5">
            <label for="grupoEnvaseFormControl" class="col-form-label">*Grupo envase: </label>
          </div>
          <div class="col-md-7">
            <div class="input-group mb-2">
              <input id="grupoEnvaseFormControl" type="text" class="form-control"
                formControlName="grupoEnvaseFormControl" placeholder="Grupo envase" maxlength="2"/>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!--CHECKS DE PRODUCTO-->
    <div class="row">
      <!-- CONTROL CALIDAD -->
      <div class="mb-3 col-3 ms-3">
        <input type="checkbox" class="form-check-input" id="controlCalidad2FormControl"
          formControlName="controlCalidad2FormControl" />
        <label class="form-check-label ms-2" for="controlCalidad2FormControl">Control calidad</label>
      </div>
      <!-- CONTROL STOCK -->
      <div class="mb-3 col-3">
        <input type="checkbox" class="form-check-input" id="controlStock2FormControl"
          formControlName="controlStock2FormControl" />
        <label class="form-check-label ms-2" for="controlStock2FormControl">Control Stock</label>
      </div>
      <!-- PRODUCTO APROBADO -->
      <div class="mb-3 col-3">
        <input type="checkbox" class="form-check-input" id="productoAprobado2FormControl"
          formControlName="productoAprobado2FormControl" />
        <label class="form-check-label ms-2" for="productoAprobado2FormControl">Producto aprobado</label>
      </div>
    </div>

    <!--CHECK DE IMPUESTOS-->
    <div class="row">
      <!-- APLICA IDC -->
      <div class="mb-3 col-3 ms-3">
        <input type="checkbox" class="form-check-input" id="aplicatIDC2FormControl"
          formControlName="aplicatIDC2FormControl" />
        <label class="form-check-label ms-2" for="aplicatIDC2FormControl">Aplica IDC</label>
      </div>
      <!-- APLICA ITC -->
      <div class="mb-3 col-3">
        <input type="checkbox" class="form-check-input" id="aplicaITC2FormControl"
          formControlName="aplicaITC2FormControl" />
        <label class="form-check-label ms-2" for="aplicaITC2FormControl">Aplica ITC</label>
      </div>
      <!-- IMPUESTO APROBADO -->
      <div class="mb-3 col-3">
        <input type="checkbox" class="form-check-input" id="impuestoAprobado2FormControl"
          formControlName="impuestoAprobado2FormControl" />
        <label class="form-check-label ms-2" for="impuestoAprobado2FormControl">Impuesto aprobado</label>
      </div>
    </div>


    <small class="text-danger">*Campos obligatorios.</small>
    <div class="alert alert-danger"
      *ngIf="this.messaggeError===true && (this.esCampoVacio(this.codigo.value) || this.esCampoVacio(this.descripcion.value))">
      Por favor revise los campos a completar. </div>
    <div class="alert alert-danger"
      *ngIf="(this.tipoDeProducto.value!=null && this.tipoDeProducto.value?.aerocombustible && !this.guardadoProducto && this.messaggeError===true && this.densidadErronea===true)">
      Por favor revise los campos densidad. Densidad máxima debe ser mayor a densidad mímima y ambas positivas </div>
  </form>
</div>
<div class="modal-footer bg-light d-flex justify-content-center">
  <button class="btn btn-primary" type="button" [disabled]="guardadoProducto" (click)="crearProducto()">
    <span *ngIf="!guardadoProducto">Guardar</span>
    <div *ngIf="guardadoProducto" class="d-flex align-items-center">
      <div class="spinner-border text-light spinner-border-sm" role="status"></div>
      <span>Guardando...</span>
    </div>
  </button>
  <button type="button" class="btn btn-secondary" [disabled]="guardadoProducto"
    (click)="activeModal.dismissAll()">Cancelar</button>
</div>
