  <nav class="navbar navbar-light bg-light navbar-expand-lg" *ngIf="tipoNuevoAerovale == null">
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <form class="form-inline my-2 my-lg-0 ms-3 me-2">
        <input id="busquedaFormControl" type="text" class="form-control" [formControl]="busquedaFormControl"
          placeholder="Factura / Fecha / Cliente" (input)="filtrarFacturas()" />
      </form>
      <ul class="navbar-nav me-auto">
        <!-- desde -->
        <li class="nav-item me-2">
          <div class="input-group">
            <input type="date" [ngModel]="modelDesde" class="fechaFormControlDesdeHasta" placeholder="aaaa-mm-dd"
              (ngModelChange)="modelDesde = $event" name="dt" ngbDatepicker #d="ngbDatepicker" value="modelDesde"
              class="form-control form-control-rounded" #searchDate required readonly>
            <div class="input-group-append">
              <button id="botonDesde" type="button" class="input-group-btn btn btn-secondary" (click)="d.toggle()">
                <i class="fa fa-calendar" aria-hidden="true"></i></button>
            </div>
          </div>
        </li>
        <!-- hasta -->
        <li class="nav-item">
          <div class="input-group">
            <input type="date" class="fechaFormControlDesdeHasta" [ngModel]="modelHasta" placeholder="aaaa-mm-dd"
              name="dp" value="modelHasta" (ngModelChange)="modelHasta = $event"
              class="form-control form-control-rounded" ngbDatepicker #h="ngbDatepicker" #searchDate required readonly>
            <div class="input-group-append">
              <button id="botonHasta" type="button" class="input-group-btn btn btn-secondary" (click)="h.toggle()"><i
                  class="fa fa-calendar" aria-hidden="true"></i></button>
            </div>
          </div>
        <li class="nav-item">
          <button (click)="this.buscarDesdeHasta()" class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2"
            type="submit"><i class="fa fa-search" aria-hidden="true"></i></button>
        </li>
      </ul>
    </div>
  </nav>
  <div class="col col-md-12">
    <div class="spinner-container" *ngIf="this.obteniendoFacturas">
        <ngx-spinner name="spinnerGrafico" bdOpacity=0.3bd
          Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
          <p style="color: white">C a r g a n d o . . . </p>
        </ngx-spinner>
    </div>
    <div class="abs-center" *ngIf="this.obteniendoFacturas===false && this.facturasFiltrados.length==0">
      <ngb-alert type="warning"> No se encontraron facturas en cuenta corriente registradas para el rango de fechas
        seleccionado.</ngb-alert>
    </div>
    <div class="row mt-3">
      <div class="card-body" *ngIf="this.obteniendoFacturas===false && this.facturasFiltrados.length>0">
        <table class="table table-striped table-hover" [formGroup]="tablaComprobantes">
          <thead class="text-center">
            <td><b>Fecha</b></td>
            <td><b>Factura</b></td>
            <td><b>Tipo</b></td>
            <td><b>Cliente</b></td>
            <td><b>Importe</b></td>
            <td><b>Accion</b></td>
          </thead>
          <tbody class="text-center">
            <tr formArrayName="facturasFiltradas" *ngFor="let factura of this.facturasFiltrados let i = index">
              <td>{{ factura.fechaEmision | date: "d'/'MM'/'y'"}}</td>
              <td>{{ factura.prefijo | facturaPrefijo }}-{{ factura.numeroComprobante | facturaNumero }}</td>
              <td>{{ factura.tipoMovimiento | tipoMovimiento }}</td>
              <td>{{ factura.razonSocialCliente }}
              </td>
              <td>{{ factura.tipoMoneda | tipoMoneda }} {{factura.total | number:'0.2-2'}}
              </td>
              <td><button (click)="this.solicitarRefacturacion(factura)"
                  class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2">Solicitar Refacturación </button></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>