import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RanchoIndexComponent } from './rancho-index/rancho-index.component';
import { RanchosImportComponent } from './ranchos-import/ranchos-import.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RanchoRoutingModule } from './rancho-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';
import { OrderModule } from 'ngx-order-pipe';
import { RanchosSolicitudesComponent } from './ranchos-solicitudes/ranchos-solicitudes.component';
import { ModalAsignarRanchoComponent } from './ranchos-solicitudes/modal-asignar-rancho/modal-asignar-rancho.component';
import { ModalBitacoraRanchoComponent } from './ranchos-solicitudes/modal-bitacora-rancho/modal-bitacora-rancho.component';
import { ModalMarcarRanchoComponent } from './ranchos-solicitudes/modal-bitacora-rancho/modal-marcar-rancho/modal-marcar-rancho.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SharedModule } from '../shared/shared.module';
import { ModalAdjuntosComponent } from './ranchos-solicitudes/modal-adjuntos/modal-adjuntos.component';


@NgModule({
  declarations: [
    RanchoIndexComponent, 
    RanchosImportComponent, 
    RanchosSolicitudesComponent, 
    ModalAsignarRanchoComponent, 
    ModalBitacoraRanchoComponent, 
    ModalMarcarRanchoComponent,
    ModalAdjuntosComponent    
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RanchoRoutingModule,
    NgbModule,
    NgxSpinnerModule,
    SharedModule,
    OrderModule,
    InfiniteScrollModule
  ]
})
export class RanchoModule { }
