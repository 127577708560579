import { TipoPago } from '../../enums/tipoPago';
import { Ciudad } from '../facturacion/Ciudad';
import { Abastecedora } from './Abastecedora';
import { Aeroplanta } from './Aeroplanta';
import { Aerovale_Producto } from './Aerovale_Producto';
import { Cliente } from './Cliente';
import { Observacion } from './Observacion';
import { UsuarioAerovale } from './UsuarioAerovale';

export class Aerovale {
  id : string;
  prefijo: number;
  numeroAerovale: string;
  origen:Aeroplanta;
  cliente: Cliente;
  tipoPago: TipoPago;
  operadorDTO: UsuarioAerovale;
  supervisorDTO: UsuarioAerovale;
  estado: string;
  estadoFinal: string;
  aeroespecialidades: Aerovale_Producto;
  fechaHoraInicioDespacho: string;
  fechaHoraFinDespacho:string;
  observaciones: Observacion[];
  comentarios: string;
  synchronized: boolean;
  datos_app: string;
  firmaOperador: string;
  firmaCliente: string;
  pathFirmaOperador: string;
  pathFirmaCliente: string;
  lastUpadted: string;
  ciudad: Ciudad;

  codigoAeroplanta: string;
  clienteRazonSocial: string;
  matricula: string;
  nombreProducto: string;
  tipoProducto: string;
  vuelo: string;
  abastecedora: Abastecedora;
  codigoAbastecedora: string;
  pedidoId: string;
  volumen: number;
  tipoAerovale: number;
  nombreCiudadAeropuerto: string;

  razonSocial: string;
  ctc: string;
  sgc:string;
  numeroDocumento: string;
  numeroIIBB: string;
  condicionPrecio: string;
  domicilio: string;
  nombreLocalidad: string;
  codigoPostalLocalidad: string;
  nombreProvincia: string;
  condicionIVA: string;


}
