import { UpperCasePipe } from '@angular/common';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ClienteService } from 'src/app/cliente/cliente.service';
import { PedidoService } from 'src/app/despacho/pedido.service';
import { ClienteEncabezado } from 'src/app/shared/models/cliente/ClienteEncabezado';
import { Cuenta } from 'src/app/shared/models/cliente/Cuenta';
import { AerolineaDestinoDTO } from 'src/app/shared/models/despacho/AerolineaDestinoDTO';
import { AerolineaDTO } from 'src/app/shared/models/despacho/AerolineaDTO';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { MessageService } from 'src/app/shell/message.service';

@Component({
  selector: 'app-aerolineas-tams-abm',
  templateUrl: './aerolineas-tams-abm.component.html',
  styleUrls: ['./aerolineas-tams-abm.component.scss']
})
export class AerolineasTamsAbmComponent implements OnInit {

  @Input() clienteEncabezadoSeleccionado: ClienteEncabezado;
  
  aerolineaDTO: AerolineaDTO;
  cargandoDatos: boolean = false; 
  cargandoCuentas: boolean = false;
  guardandoDatos: boolean = false;
  editarAerolinea: boolean = false;
  cuentasCliente: Cuenta[];

  aerolineaForm = new UntypedFormGroup({
    codigoFormControl: new UntypedFormControl(null, Validators.required),
    cuentaNacionalFormControl: new UntypedFormControl(null),
    cuentaInternacionalFormControl: new UntypedFormControl(null)
  });

  constructor(
    private spinner: NgxSpinnerService,
    private pedidoService: PedidoService,
    private messageService: MessageService,
    private clienteService: ClienteService
  ) { }

  ngOnInit(): void {   
    this.guardandoDatos=false;
  }

  ngOnChanges(){
    this.mostrarSpinner();
    this.codigoFormControl.setValue(null);
    this.cuentaNacionalFormControl.setValue(null);
    this.cuentaInternacionalFormControl.setValue(null);    
    this.obtenerCuentasPorCUIT(this.clienteEncabezadoSeleccionado);
    this.obtenerAerolineasPorCUIT(this.clienteEncabezadoSeleccionado.numeroDocumento);
  }
  
  obtenerCuentasPorCUIT(cliente:ClienteEncabezado) {
    this.cargandoCuentas = true;
    this.clienteService.getClientePorDocumento(cliente.tipoDocumento, cliente.numeroDocumento)
      .subscribe(resp => {
        this.cuentasCliente = resp.cuentas.filter( c => c.activo == true);
        this.cargandoCuentas = false;
      });
  }

  obtenerAerolineasPorCUIT(cuit:string) {
    this.pedidoService.getAerolineas()
      .subscribe(res => {
        this.aerolineaDTO = res.filter( a => a.cuit == cuit)[0];
        if(this.aerolineaDTO!=undefined || this.aerolineaDTO!=null) {
          this.editarAerolinea = true;
          this.cargarDatosAerolineas();
        } else {
          this.editarAerolinea = false;
        }
        
        this.ocultarSpinner();
      },
      (err: ErrorModel) => {
        console.log(err);
        this.ocultarSpinner();
      })
  }

  cargarDatosAerolineas() {
    this.codigoFormControl.setValue(this.aerolineaDTO.codigoAerolinea);
    this.cuentaNacionalFormControl.setValue(this.aerolineaDTO.cuentaNacional);
    this.cuentaInternacionalFormControl.setValue(this.aerolineaDTO.cuentaInternacional);
  }

  compararCuentas(c1:any, c2: any) : boolean {
    return c1 && c2 ? c1.ctA_SGC == c2.ctA_SGC : c1 == c2;
  }
  
  mostrarSpinner() {
    this.spinner.show('spinnerABM');
    this.cargandoDatos = true;
  }

  ocultarSpinner() {
    this.spinner.hide('spinnerABM');
    this.cargandoDatos = false;
  }

  guardarCambios() {

    if(!this.aerolineaForm.valid){
      this.messageService.showErrorMessage("Revise los campos incompletos");
      return;
    }

    this.guardandoDatos = true;
    let nuevaAerolinea = new AerolineaDTO;

    if(this.editarAerolinea) {
      nuevaAerolinea.id = this.aerolineaDTO.id;
      nuevaAerolinea.cuit = this.aerolineaDTO.cuit;
      nuevaAerolinea.razonSocial = this.aerolineaDTO.razonSocial;
      nuevaAerolinea.destinos = this.aerolineaDTO.destinos;
    } else {
      nuevaAerolinea.cuit = this.clienteEncabezadoSeleccionado.numeroDocumento;
      nuevaAerolinea.razonSocial = this.clienteEncabezadoSeleccionado.razonSocial;
    }
    
    nuevaAerolinea.codigoAerolinea = this.codigoFormControl.value.toUpperCase();
    nuevaAerolinea.cuentaNacional = this.cuentaNacionalFormControl.value;
    nuevaAerolinea.cuentaInternacional = this.cuentaInternacionalFormControl.value;
        
    if(this.editarAerolinea) {
      this.pedidoService.putAerolineas(this.aerolineaDTO.id, nuevaAerolinea)
      .subscribe(result => {
        this.messageService.showSuccessMessage('Aerolinea modificada con éxito.');
        this.guardandoDatos = false;
      }, (err: ErrorModel) => { 
        this.messageService.showErrorMessage('Hubo un error al modificar la Aerolinea. ' + err.description);
        this.guardandoDatos = false;
      });
    } else {
      this.pedidoService.postAerolineas(nuevaAerolinea)
      .subscribe(result => {
        this.messageService.showSuccessMessage('Aerolinea agregada con éxito.');
        this.guardandoDatos = false;
      }, (err: ErrorModel) => { 
        this.messageService.showErrorMessage('Hubo un error al agregar la Aerolinea. ' + err.description);
        this.guardandoDatos = false;
      });
    }    
  }

  /** getters */

  get codigoFormControl() {
    return this.aerolineaForm.get('codigoFormControl');
  }

  get cuentaNacionalFormControl() {
    return this.aerolineaForm.get('cuentaNacionalFormControl');
  }

  get cuentaInternacionalFormControl() {
    return this.aerolineaForm.get('cuentaInternacionalFormControl');
  }


}
