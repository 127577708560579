<div class="card add-margin">
  <form>

    <h4 class="card-header card-title ">
      <div class="text-center">

        Modificar fecha desde de precio
        <button type="button" class="btn-close" aria-label="Close" (click)="this.onCancelar()">
          <span >&times;</span>
        </button>

      </div>

    </h4>

    <div class="card-body">
      <div class="row">
        <div class="col-sm-12">
          <table>
            <tr>
              <td><b>Aeroplanta:</b> {{precio.codigoAeroplanta}}</td>
              <td><b>Nivel:</b> {{precio.nivel}}</td>
              <td><b>Lote:</b> {{precio.numeroLote}}</td>
            </tr>
            <tr>
              <td colspan="3"><b>Desde/Hasta:</b> {{precio.fechaDesde | date: 'dd/MM/yyyy' }} - {{precio.fechaHasta | date:'dd/MM/yyyy' }}</td>
            </tr>
            <tr>
              <td><b>Cod:</b> {{precio.codigoProducto}}</td>
              <td colspan="2"><b>Producto:</b> {{precio.nombreProducto}}</td>
            </tr>
            <tr>
              <td colspan="3"><b>Cliente:</b> {{precio.cliente ? precio.cliente.razonSocial : "-"}}</td>
            </tr>
            <tr>
              <td><b>Tipo Venta:</b> {{descripcionTipoVenta(precio.tipoVenta)}}</td>
              <td><b>Dir. entrega:</b> {{precio.direccionEntrega}}</td>
              <td></td>
            </tr>
            <tr>
              <td><b>Precio:</b> {{precio.moneda.nombre}}  {{precio.precioContado}}</td>
              <td><b>Area:</b> {{precio.areaNegocio ? precio.areaNegocio.codigoArea : "-"}}</td>
              <td><b>Segmento:</b> {{precio.segmento ? precio.segmento.codigoSegmento : "-"}}</td>
            </tr>
          </table>
        </div>
      </div>

      <div class="row mt-5">
        <div class="mb-3 col-sm-12">
          <label style="float:left;" for="fechaDesdeFormControl" class="col-sm-4 col-form-label">Nueva Fecha Desde:</label>
          <div class="col-sm-4" style="float:left;">
            <div class="input-group">

              <input  type="date"
                      name="fechaDesde"
                      class="form-control form-control-rounded mb-2"
                      placeholder="aaaa-mm-dd"
                      ngbDatepicker
                      [(ngModel)]="modelFechaDesde"
                      #desde="ngbDatepicker" readonly>

              <div class="input-group-append">
                <button id="botonDesde" type="button" class="input-group-btn btn btn-secondary"
                  (click)="desde.toggle()">
                  <i class="fa fa-calendar" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="col-sm-4" style="float:left;">
            <ngb-timepicker  name="modelHora" [spinners]="false" [(ngModel)]="modelHora"></ngb-timepicker>
          </div>
        </div>
      </div>

      <div class="alert alert-danger" *ngIf="errorFecha.length > 0">
        {{ errorFecha }}
      </div>

    </div>

    <div class="card-footer d-flex justify-content-center">
      <span class="border">
        <button class="btn btn-primary" type="button" (click)="onSubmit()"
          [disabled]="guardandoFechaInicio">
          <span *ngIf="!guardandoFechaInicio">Guardar</span>
          <div *ngIf="guardandoFechaInicio" class="d-flex align-items-center">
            <div class="spinner-border text-light spinner-border-sm" role="status"></div>
            <span>Guardando...</span>
          </div>
        </button>
      </span>
      <span class="border">
        <button type="button" class="btn btn-secondary" (click)="onCancelar()">Cancelar</button>
      </span>
    </div>
  </form>
</div>
