import { Pico } from "../despacho/Pico"
import { Responsable } from "../stock/Responsable";

export class Precierre {
    codigoExpendedora: string;
    codigoProducto: string;
    densidad: number;
    fechaHoraPrecierre: string;
    id: string;
    nivel: number;
    nivel15: number;
    nombreExpendedora: string;
    nombreProducto: string;
    picos: Pico[];
    responsable: string;
    responsableDTO: Responsable;
    temperatura: number;
    estadoDeCierre: number;
    tipoExpendedora: number; 
}