import { UsuarioDTO } from "../notificaciones/UsuarioDTO";
import { Contacto } from "./Contacto";
import { DetalleProducto } from "./DetalleProducto";

export class Aeroplanta {
    id: string;
    codigo: string;
    tipoAeroplanta: number;
    productos: DetalleProducto[];
    firmaOperadora: Contacto;
    referenteTecnico: Contacto;
    referenteGestion: Contacto;
    referenteComercial: Contacto;
    ruteador: Contacto;
    horarioOperacion: string;
    responsable: UsuarioDTO;
    sustituto: UsuarioDTO;
}