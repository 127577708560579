import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-set-general',
  templateUrl: './set-general.component.html',
  styleUrls: ['./set-general.component.css']
})
export class SetGeneralComponent implements OnInit {

  constructor()
  {  }

  ngOnInit() 
  {  }
}
