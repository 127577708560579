import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Estado } from 'src/app/shared/models/controlCredito/Estado';
import { ControlCreditoService } from '../control-credito.service';
import { EstadoCliente } from 'src/app/shared/enums/EstadoCliente';

@Component({
  selector: 'app-modal-historial-estados',
  templateUrl: './modal-historial-estados.component.html',
  styleUrls: ['./modal-historial-estados.component.scss']
})
export class ModalHistorialEstadosComponent implements OnInit {

  @Input() estados: Estado[];
  EstadoCliente = EstadoCliente;
  constructor(public controlCreditoService: ControlCreditoService, public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }


}
