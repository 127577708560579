import { Component, OnInit } from '@angular/core';
import { UnidadMedida } from 'src/app/shared/enums/unidadMedida';
import { TipoProducto } from 'src/app/shared/enums/tipoProducto';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MessageService } from 'src/app/shell/message.service';
import { ProductoService } from '../producto.service';
import { Producto } from 'src/app/shared/models/abm/Producto';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductosListaComponent } from '../productos-lista/productos-lista.component';
import { Rubro } from 'src/app/shared/models/abm/Rubro';
import { TipoNeto } from 'src/app/shared/enums/tipoNeto';
import { TipoPrecio } from 'src/app/shared/enums/TipoPrecio';
import { TipoCombustible } from 'src/app/shared/enums/tipoCombustible';
import { TipoAerocombustible } from 'src/app/shared/enums/tipoAerocombustible';
import { TipoServicio } from 'src/app/shared/enums/tipoServicio';


@Component({
  selector: 'app-nuevo-producto',
  templateUrl: './nuevo-producto.component.html',
  styleUrls: ['./nuevo-producto.component.css']
})
export class NuevoProductoComponent implements OnInit {

  esaero: boolean;
  nuevoProductoForm = new UntypedFormGroup({
    codigoFormControl: new UntypedFormControl(null, [Validators.required]),
    tipoProductoFormControl: new UntypedFormControl(null, [Validators.required]),
    descripcionFormControl: new UntypedFormControl(null, [Validators.required]),
    activoFormControl: new UntypedFormControl(null),
    unidadMedidaFormControl: new UntypedFormControl(null, [Validators.required]),
    densidadFormControl: new UntypedFormControl(null),
    tipoPrecioFormControl: new UntypedFormControl(null, [Validators.required]),
    tipoCombustibleFormControl: new UntypedFormControl(null),
    tipoAerocombustibleFormControl: new UntypedFormControl(null),
    tipoServicioFormControl: new UntypedFormControl(null),
    densidadMinimaFormControl: new UntypedFormControl(null),
    densidadMaximaFormControl: new UntypedFormControl(null),
    porcentajeAlijeFormControl: new UntypedFormControl(null),
    peligrosidadFormControl: new UntypedFormControl(null, [Validators.required]),
    contableFormControl: new UntypedFormControl(null, [Validators.required]),
    partidaFormControl: new UntypedFormControl(null, [Validators.required]),
    productoSAPFormControl: new UntypedFormControl(null, [Validators.required]),
    unidadSAPFormControl: new UntypedFormControl(null, [Validators.required]),
    negocioGerencialFormControl: new UntypedFormControl(null, [Validators.required]),
    unidadStockFormControl: new UntypedFormControl(null, [Validators.required]),
    unidadComercialFormControl: new UntypedFormControl(null, [Validators.required]),
    unidadProduccionFormControl: new UntypedFormControl(null, [Validators.required]),
    grupoEnvaseFormControl: new UntypedFormControl(null, [Validators.required]),
    controlCalidad2FormControl: new UntypedFormControl(null),
    controlStock2FormControl: new UntypedFormControl(null),
    productoAprobado2FormControl: new UntypedFormControl(null),
    aplicatIDC2FormControl: new UntypedFormControl(null),
    aplicaITC2FormControl: new UntypedFormControl(null),
    impuestoAprobado2FormControl: new UntypedFormControl(null)   
  });
  unidadMedida = UnidadMedida;
  tipoProducto = TipoProducto;
  tipoCombustible = TipoCombustible;
  tipoAerocombustible = TipoAerocombustible;
  tipoServicio = TipoServicio;
  tipoRubro = TipoNeto;
  tipoPrecio = TipoPrecio;
  productos: Producto[];
  rubros: Rubro[];
  cargandoRubros: boolean;
  activeModal: NgbModal;
  guardadoProducto: boolean = false;
  densidadErronea: boolean = false;
  messaggeError: boolean = false;

  rangoDensidad:string="";

  colores = [
    {
      name: 'NEGRO',
      value: '#000000'
    },
    {
      name: 'ROJO',
      value: '#EF3D42'
    },
    {
      name: 'VERDE',
      value: '#009D57'
    },
    {
      name: 'AMARILLO',
      value: '#FFF200'
    },
    {
      name: 'AZUL',
      value: '#0079C2'
    }
  ];

  selectedColor = '#000000';



  constructor(activeModal: NgbModal,
    private productoService: ProductoService,
    private messageService: MessageService,
    private listaDeProductos: ProductosListaComponent) {
    this.activeModal = activeModal;
  }


  // hooks

  ngOnInit() {
    this.messaggeError = false;
    this.cargandoRubros = true;
    this.getProductos();
    this.getRubros();
    this.unidadDeMedida.setValue(1);
    this.tipoDePrecio.setValue(1);
    this.densidadMinima.setValue(0);
    this.densidadMaxima.setValue(0);
    this.porcentajeAlijeFormControl.setValue(0);
    this.selectedColor = '#000000';
    this.controlCalidad.setValue(false);
    this.controlStock.setValue(false);
    this.productoAprobado.setValue(false);
    this.aplicaIDC.setValue(false);
    this.aplicaITC.setValue(false);
    this.combustible.setValue(1);
    this.aerocombustible.setValue(1);
    this.tipoServicioFormControl.setValue(0);
    this.impuestoAprobado.setValue(false);
    console.log("El combustible es "+this.combustible.value);
  }

  // llamadas al service

  getRubros() {
    this.productoService.getRubros()
      .subscribe(result => {
        this.rubros = result;        
        this.cargandoRubros = false;
        this.tipoDeProducto.setValue(this.rubros[0]); //dejo por defecto seleccionado el primer rubro
      });
  }

  getProductos() {
    this.productoService.obtenerProductos()
      .subscribe(result => {
        this.productos = result;
      }, () => {

      });
  }

  crearProducto() {
    this.densidadMenor();
    this.messaggeError = false;
    this.guardadoProducto = true;
    if (this.camposVacios()) {
      this.messaggeError = true;
      this.guardadoProducto = false;
    }
    else {
      let p: Producto = new Producto();
      p.color='#000000';
      p.codigoProducto = this.codigo.value;
      p.nombreProducto = this.descripcion.value;
      p.unidadMedida = this.unidadDeMedida.value;
      p.color = this.selectedColor;
      p.tipoPrecio = this.tipoDePrecio.value;
      console.log("El tipo de producto es "+JSON.stringify(this.tipoProducto));

      if (this.tipoDeProducto.value.aerocombustible===true) { p.tipoProducto = 1 };      
      if (this.tipoDeProducto.value.aeroespecialidad===true) { p.tipoProducto = 2 };
      if (this.tipoDeProducto.value.combustible===true) { p.tipoProducto = 3 };
      if (this.tipoDeProducto.value.combustible) {
      p.tipoCombustible=Number(this.combustible.value);
      p.densidadMin=this.densidadMinima.value;
      p.densidadMax=this.densidadMaxima.value;
      } else {
        p.tipoCombustible = 0;
      }
      //solo para aero
      if (this.tipoDeProducto.value.aerocombustible) {
        p.tipoAerocombustible=Number(this.combustible.value);
        p.porcentajeAlije=Number(this.porcentajeAlijeFormControl.value);
      }
      if (this.densidadMinima.value < this.densidadMaxima.value) {
        p.densidadMin = parseFloat(this.densidadMinima.value);
        p.densidadMax = parseFloat(this.densidadMaxima.value);
      }
      //solo para combustible
      if (this.tipoDeProducto.value.aerocombustible) { p.tipoProducto = 1 };
      if (this.tipoDeProducto.value.aeroespecialidad) { p.tipoProducto = 2 };
      if (this.tipoDeProducto.value.combustible) { p.tipoProducto = 3 };
      if (this.tipoDeProducto.value.servicios) { 
        p.tipoProducto = 4;
        p.tipoServicio = this.tipoServicioFormControl.value;
      };

      p.rubro = this.tipoDeProducto.value;
      p.rubroId= this.tipoDeProducto.value.id;

      p.activo = true;

      p.peligrosidad = this.peligrosidad.value;
      p.agrupacionContable = this.contable.value;
      p.partida = this.partida.value;
      p.productoSAP = this.productoSAP.value;
      p.unidadMedidaSAP = this.unidadSAP.value;
      p.unidadNegocioGerencial = this.negocioGerencial.value;
      p.unidadMedidaStock = this.unidadStock.value;
      p.unidadMedidaComercial = this.unidadComercial.value;
      p.unidadMedidaProduccion = this.unidadProduccion.value;
      p.grupoEnvase = this.grupoEnvase.value;
      p.controlCalidad = this.controlCalidad.value;
      p.controlStock = this.controlStock.value;
      p.aprobadoProducto = this.productoAprobado.value;
      p.aplicaIDC = this.aplicaIDC.value;
      p.aplicaITC = this.aplicaITC.value;
      p.aprobadoImpuestos = this.impuestoAprobado.value;      

      if (this.nuevoProductoForm.valid && this.validarCampos()){    
        if (this.estaCodigoProducto()) {
          this.messageService.showErrorMessage("El campo codigo no debe ser uno existente");
          this.guardadoProducto = false;
        }
        else {
          console.log("producto "+JSON.stringify(p))
           this.productoService.agregarProducto(p)
            .subscribe(result => {
              this.messageService.showSuccessMessage("Se ha agregado el producto correctamente");
              this.listaDeProductos.ngOnInit();
              this.listaDeProductos.esperandoCarga = false; // se setea en false para que no muestre el spinner de fondo
              this.guardadoProducto = false;
              this.activeModal.dismissAll();
            }, () => {
              if (p.tipoProducto === 1) { this.messaggeError = true; }
              this.guardadoProducto = false;
            });
        }
      } else {
        this.messageService.showErrorMessage('Por favor revise los campos a completar.');
        this.guardadoProducto = false;
      }
    }
  }

  // validaciones

  onlyDecimalNumberKey(event) {
    let charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57))
      return false;
    return true;
  }

  densidadMenor() {
    if (parseFloat(this.densidadMinima.value) === 0 || parseFloat(this.densidadMaxima.value) === 0
      || parseFloat(this.densidadMinima.value) > parseFloat(this.densidadMaxima.value)) {
      this.densidadErronea = true;
    } else {
      this.densidadErronea = false;
    }
  }

  validarCampos() {
    if (this.tipoDeProducto.value.servicios && this.tipoServicioFormControl.value==this.tipoServicio.Indeterminado) {
      return false;
    }

    return true;
  }

  /*ValidoDensidad(){
    if (parseFloat(this.densidadMinima.value)!=0 || parseFloat(this.densidadMaxima.value)!=0)
      {
      if (this.tipoDeProducto.value.combustible && (this.combustible.value==this.tipoCombustible.Nafta))
      {
        return (parseFloat(this.densidadMinima.value)>=0.665 && parseFloat(this.densidadMaxima.value)<=0.73);
      }
      if (this.tipoDeProducto.value.combustible && (this.combustible.value==this.tipoCombustible.GasOil))
      {
        return (parseFloat(this.densidadMinima.value)>=0.675 && parseFloat(this.densidadMaxima.value)<=0.905);
      }
      return true;
    }
  }*/

  /*obtenerRangoDensidad()
  {
    this.rangoDensidad="";

    if (this.combustible.value==this.tipoCombustible.Nafta)
    {
      this.rangoDensidad="[0.665,0.730]";
    }

    if (this.combustible.value==this.tipoCombustible.GasOil)
    {
      this.rangoDensidad="[0.675,0.905]";
    }
   // console.log("la densidad del combustible "+this.obtenerTipoCombustible()+" es "+JSON.stringify(this.rangoDensidad));
    return this.rangoDensidad;
  }*/

  obtenerTipoCombustible(): string
  {
    return TipoCombustible[this.combustible.value];
  }

  obtenerTipoAerocombustible(): string
  {
    return TipoAerocombustible[this.aerocombustible.value];
  }

  esCampoVacio(campo: any): boolean {
    return (campo == null || (campo != null && campo.length == 0));
  }

  camposVacios() {
    return this.esCampoVacio(this.codigo.value) || this.esCampoVacio(this.descripcion.value);
  }

  estaCodigoProducto(): boolean {
    let esta: boolean = false;
    for (let entry of this.productos) {
      if (entry.codigoProducto === this.codigo.value) {
        esta = true;
        break;
      }
    }
    return esta;
  }

  limpiarValidacion() {
    this.messaggeError = false;
    this.densidadErronea = false;
  }




  // form

  keysTipoCombustible(): Array<string> {
    var keys = Object.keys(this.tipoCombustible);
    return keys.slice(keys.length / 2);
  }

  keysTipoAerocombustible(): Array<string> {
    var keys = Object.keys(this.tipoAerocombustible);
    return keys.slice(keys.length / 2);
  }

  keysTipoServicio(): Array<string> {
    var keys = Object.keys(this.tipoServicio);
    keys = keys.slice(keys.length / 2);
    keys = keys.slice(1, 4); // 1=Alije 2=Rancho - descarto indeterminado
    return keys;
  }

  keysUnidadDeMedida(): Array<string> {
    var keys = Object.keys(this.unidadMedida);
    return keys.slice(keys.length / 2);
  }

  keysTipoPrecio(): Array<string> {
    var keys = Object.keys(this.tipoPrecio);
    return keys.slice(keys.length / 2);
  }

  keysTipos(): Array<string> {
    var keys = Object.keys(this.tipoRubro);
    keys = keys.slice(keys.length / 2);
    keys.shift();
    return keys;
  }

  onChange(value){
    this.selectedColor = value;
  }

  // getters

  get codigo() {
    return this.nuevoProductoForm.get('codigoFormControl');
  }

  get descripcion() {
    return this.nuevoProductoForm.get('descripcionFormControl');
  }

  get densidades() {
    return this.nuevoProductoForm.get('densidadFormControl');
  }

  get unidadDeMedida() {
    return this.nuevoProductoForm.get('unidadMedidaFormControl');
  }

  get tipoDeProducto() {
    return this.nuevoProductoForm.get('tipoProductoFormControl');
  }

  get tipoDePrecio() {
    return this.nuevoProductoForm.get('tipoPrecioFormControl');
  }

  get combustible() {
    return this.nuevoProductoForm.get('tipoCombustibleFormControl');
  }
  get aerocombustible() {
    return this.nuevoProductoForm.get('tipoAerocombustibleFormControl');
  }
  get densidadMinima() {
    return this.nuevoProductoForm.get('densidadMinimaFormControl');
  }
  get densidadMaxima() {
    return this.nuevoProductoForm.get('densidadMaximaFormControl');
  }
  get peligrosidad() {
    return this.nuevoProductoForm.get('peligrosidadFormControl');
  }
  get contable() {
    return this.nuevoProductoForm.get('contableFormControl');
  }
  get partida() {
    return this.nuevoProductoForm.get('partidaFormControl');
  }
  get productoSAP() {
    return this.nuevoProductoForm.get('productoSAPFormControl');
  }
  get unidadSAP() {
    return this.nuevoProductoForm.get('unidadSAPFormControl');
  }
  get negocioGerencial() {
    return this.nuevoProductoForm.get('negocioGerencialFormControl');
  }
  get unidadStock() {
    return this.nuevoProductoForm.get('unidadStockFormControl');
  }
  get unidadComercial() {
    return this.nuevoProductoForm.get('unidadComercialFormControl');
  }
  get unidadProduccion() {
    return this.nuevoProductoForm.get('unidadProduccionFormControl');
  }
  get grupoEnvase() {
    return this.nuevoProductoForm.get('grupoEnvaseFormControl');
  }
  get controlCalidad() {
    return this.nuevoProductoForm.get('controlCalidad2FormControl');
  }
  get controlStock() {
    return this.nuevoProductoForm.get('controlStock2FormControl');
  }
  get productoAprobado() {
    return this.nuevoProductoForm.get('productoAprobado2FormControl');
  }
  get aplicaITC(){
    return this.nuevoProductoForm.get('aplicaITC2FormControl');
  }
  get aplicaIDC(){
    return this.nuevoProductoForm.get('aplicatIDC2FormControl');
  }
  get impuestoAprobado(){
    return this.nuevoProductoForm.get('impuestoAprobado2FormControl');
  }
  get porcentajeAlijeFormControl(){
    return this.nuevoProductoForm.get('porcentajeAlijeFormControl');
  }
  get tipoServicioFormControl() {
    return this.nuevoProductoForm.get('tipoServicioFormControl');
  }
}
