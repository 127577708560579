<div class="modal-header bg-light">
  <h4 class=" modal-title text-center">Impuestos</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close(true)">
    
  </button>
</div>
<div class="modal-body">
  <table class="table table-striped table-hover text-center">
    <thead>
      <th>Renglon</th>
      <th>Leyenda</th>
      <th>Codigo</th>
      <th>Valor</th>
      <th>Producto</th>
    </thead>
    <tbody *ngFor="let renglon of renglones, let j = index, let totalRen = count;">
      <tr *ngFor="let imp of renglones[j].impuestosPercepciones,let i = index, let totalImp = count">
        <td>{{this.incrementarContador(totalRen*totalImp)}}</td>
        <td>{{imp.leyenda}}</td>
        <td>{{obtenerBaseCalculo(imp.baseCalculo)}}</td>
        <td>{{imp.valor}}</td>
        <td>{{renglon.productoNombre}}</td>
      </tr>
    </tbody>
  </table>
  <ul class="col-md-12 text-danger letra2">
    <li>*B1) Total Facturado - IVA - IVA percep - IVA No Cat. - Tasa Vial.
    </li>
    <li>*B2) (Si discrimina IVA, IMPINT, CO2) Total facturado - IVA Insc - IVA No Cat - IVA Percep - Tasa Vial.</li>
      <li>*B3) Total Facturado.</li>
      <li>*B4) (Si discrimina IVA, IMPINT, CO2) Total Facturado - IVA Insc - IVA No Cat - IVA Percep - CO2 - Tasa Hidrica - Fondo Fiduciario - Fondo Gas - Fonno Nacional Energia - Tasa Vial.</li>
  </ul>
</div>
<div class="modal-footer bg-light d-flex justify-content-center">
  <button type="button" class="btn btn-secondary"
    (click)="activeModal.close(true)">Cancelar</button> 
</div>