<div class="card" id="islas-card">
  <div class="card-header">
    <h4 style="display: inline;" class="card-title">ABM de Cañerias.</h4>
    <button *ngIf="this.hasRole(TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.RESPRODUCTO, TipoUsuario.TUTOR)" style="margin-left: 4em;" class="btn btn-primary" type="button" [disabled]="this.obteniendoCanerias"
      (click)="agregarCaneria()">Agregar nueva cañeria</button>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="spinner-container" *ngIf="obteniendoCanerias">    
        <ngx-spinner name="spinnerCanerias" bdOpacity=0.3bd
          Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
          <p style="color: white">{{messageCanerias}}</p>
        </ngx-spinner>
      </div>
      <div *ngIf="!this.obteniendoCanerias"  class="col-md-12">
        <div *ngIf="this.canerias.length == 0" class="alert alert-warning">No hay cañerias
          para la aeroplanta seleccionada.</div>

        <table *ngIf="this.canerias.length>0" class="table table-striped table-hover"
          [formGroup]="tablaCanerias">
          <thead>
            <th class="text-center">Código</th>
            <th class="text-center">Nombre</th>
            <th class="text-center">Producto</th>
            <th class="text-center">Stock</th>
            <th class="text-center">Descripcion</th>
            <th class="text-center">Fecha de modificacion</th>
            <th class="text-center">Responsable</th>
            <th class="text-center">Activa</th>
            <th class="text-center">Editar</th>
          </thead>
          <tbody>

            <tr formArrayName="caneria" *ngFor="let c of canerias; let i = index">
              <td class="text-center">{{ c.codigo }}</td>
              <td class="text-center">{{ c.nombre }}</td>
              <td class="text-center">{{ c.producto.codigoProducto }} - {{ c.producto.nombreProducto }}
              </td>
              <td class="text-center">{{ c.stock }} </td>
              <td class="text-center">{{ c.descripcion }} </td>
              <td class="text-center">{{ c.fechaModificacion | date:'dd/MM/yyyy hh:mm'}} </td>
              <td class="text-center">{{ c.responsable }} </td>

              <td class="text-center">
                <div *ngIf="this.hasRole(TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.RESPRODUCTO, TipoUsuario.TUTOR)" class="form-check form-check-inline form-switch">
                  <input type="checkbox" [formControlName]="i" class="form-check-input" id="{{canerias[i].id}}"
                    (click)="cambiarEstadoCaneria(canerias[i])">
                  <label class="form-check-label" for="{{canerias[i].id}}"></label>
                </div>
                <div *ngIf="!this.hasRole(TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.RESPRODUCTO, TipoUsuario.TUTOR)" class="form-check form-check-inline form-switch">
                  {{canerias[i].activo ? "Si" : "No"}}
                </div>  
              </td>
              <td class="text-center">
                <button *ngIf="this.hasRole(TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.RESPRODUCTO, TipoUsuario.TUTOR)" type="button" class="btn btn-primary btn-sm me-1" (click)="editarCaneria(canerias[i])"><i class="fa fa-pencil fa-lg"
                    aria-hidden="true" ngbTooltip="Editar"></i></button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>