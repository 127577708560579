import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { BehaviorSubject, Subject, throwError } from 'rxjs';
import { Aeroplanta } from './models/despacho/Aeroplanta';
@Injectable({
  providedIn: 'root',
})

export class SharedService {

  private changeAeroplanta: Subject<boolean> = new Subject;
  private managerState: Subject<boolean> = new Subject;

  /**
   * Genera un popup de impresión de "printContents"
   * @param printContents el template a imprimir
   */
  print(printContents: string): void {
    let popupWin;
    // printContents = document.getElementById('aerovalePrint').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
          <html>
            <head>
              <title>Aerovale</title>
              <link rel="stylesheet" type="text/css" href="../../../assets/css/bootstrap.min.css">
              <link rel="stylesheet" type="text/css" href="../../../assets/css/print.css">
              <style>
              </style>
            </head>
            <body onload="window.print();window.close()"><div class="card">${printContents}</div></body>
          </html>`
    );
    popupWin.document.close();
  }

  /**
   * Genera un popup de impresión de "printContents"
   * @param printContents el template a imprimir
   */
   print2(printContents: string): void {
    let popupWin;
    // printContents = document.getElementById('aerovalePrint').innerHTML;
    popupWin = window.open();
    popupWin.document.open();
    popupWin.document.write(`
          <html>
            <head>
              <title>Remito</title>
              <link rel="stylesheet" type="text/css" href="../../../assets/css/bootstrap.min.css">
              <link rel="stylesheet" type="text/css" href="../../../assets/css/print.css">
              <style>
              </style>
            </head>
            <body onload="window.print();window.close()"><div class="card">${printContents}</div></body>
          </html>`
    );
    popupWin.document.close();
  }

  /**
   * Verifica que el cuit ingresado pase la prueba con el dígito verificador
   * @param cuit String a verificar
   */
  verificarCUIT(cuit: string): boolean {
    let esCuit = false;
    var vec = new Array(10);
    let cuit_rearmado = "";
    for (let i = 0; i < cuit.length; i++) {
      let caracter = cuit.charAt(i);
      if (caracter.charCodeAt(0) >= 48 && caracter.charCodeAt(0) <= 57) {
        cuit_rearmado += caracter;
      }
    }
    cuit = cuit_rearmado;
    if (cuit.length != 11) {  // si no estan todos los digitos 
      esCuit = false;
    } else {
      let x = 0;
      let dv = 0
      // Multiplico los dígitos. 
      vec[0] = (cuit.charCodeAt(0) - 48) * 5;
      vec[1] = (cuit.charCodeAt(1) - 48) * 4;
      vec[2] = (cuit.charCodeAt(2) - 48) * 3;
      vec[3] = (cuit.charCodeAt(3) - 48) * 2;
      vec[4] = (cuit.charCodeAt(4) - 48) * 7;
      vec[5] = (cuit.charCodeAt(5) - 48) * 6;
      vec[6] = (cuit.charCodeAt(6) - 48) * 5;
      vec[7] = (cuit.charCodeAt(7) - 48) * 4;
      vec[8] = (cuit.charCodeAt(8) - 48) * 3;
      vec[9] = (cuit.charCodeAt(9) - 48) * 2;

      // Suma cada uno de los resultado. 
      for (let i = 0; i <= 9; i++) {
        x += vec[i];
      }
      dv = (11 - (x % 11)) % 11;
      if (dv == (cuit.charCodeAt(10) - 48)) {
        esCuit = true;
      }
    }

    return esCuit;
  }

  /**
   * Convierte un date a string aceptado por backend (sin información de zona horaria)
   * @param date Date a convertir
   */
  dateToFormattedString(date: Date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toLocaleString(undefined, { minimumIntegerDigits: 2 });
    const day = date.getDate().toLocaleString(undefined, { minimumIntegerDigits: 2 });
    const hours = date.getHours().toLocaleString(undefined, { minimumIntegerDigits: 2 });
    const minutes = date.getMinutes().toLocaleString(undefined, { minimumIntegerDigits: 2 });
    const seconds = date.getSeconds().toLocaleString(undefined, { minimumIntegerDigits: 2 });
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  }

  formatDensidad(event): string{
    var valorTexto = event.target.value;
      valorTexto = ("" + valorTexto).replace(".", "");
      var valorNumero = (Number(valorTexto) * 10) + Number(event.key);
      valorNumero = valorNumero / 1000;
      var textoSalida = valorNumero.toFixed(3);
      let indice = textoSalida.indexOf('.');
      let cadenaInicial = textoSalida.substring(-1, indice);
      let cadenaFinal = textoSalida.substr(-3);
      if (cadenaInicial.length == 2) {
        let digitosInicio = cadenaInicial.substr(1, 1);
        textoSalida = digitosInicio + '.' + cadenaFinal;
      }else{
        textoSalida = cadenaInicial + '.' + cadenaFinal;
      }
      return textoSalida;
  }

  resizeImage(file:File, maxWidth:number):Promise<Blob> {
    return new Promise((resolve, reject) => {
        let image = new Image();
        image.src = URL.createObjectURL(file);
        image.onload = () => {
            let width = image.width;
            let height = image.height;
            
            if (width <= maxWidth) {
                resolve(file);
            }

            let newHeight = height * (maxWidth / width);
            let newWidth = maxWidth;

            let canvas = document.createElement('canvas');
            canvas.width = newWidth;
            canvas.height = newHeight;

            let context = canvas.getContext('2d');

            context.drawImage(image, 0, 0, newWidth, newHeight);

            canvas.toBlob(resolve, file.type);
        };
        image.onerror = reject;
    });
  }

  decryptJSON(data) {
    const res = 'AonikenBQ8000ftq';
    const decrypted = CryptoJS.AES.decrypt(data, res).toString(CryptoJS.enc.Utf8);
    return JSON.parse(decrypted);
  }

  get aeroplantaSeleccionada() {
    return this.changeAeroplanta.asObservable();
  }

  set aeroplantaSeleccionadaData(data: boolean) {
    this.changeAeroplanta.next(data);
  }

  get manager() {
    return this.managerState.asObservable();
  }

  set managerData(data: boolean) {
    this.managerState.next(data);
  }
}