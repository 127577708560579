import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';

@Component({
  selector: 'app-aeroplanta-index',
  templateUrl: './aeroplanta-index.component.html',
  styleUrls: ['./aeroplanta-index.component.css']
})
export class AeroplantaIndexComponent implements OnInit {

  constructor(private authService: AuthService) { }

  TipoUsuario = TipoUsuario;

  ngOnInit() {
  }

  hasRole(...roles: TipoUsuario[]) {
    return this.authService.hasRole(...roles);
  }
}
