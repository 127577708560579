import { formatDate } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, LOCALE_ID, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbCalendar, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { ConfiguracionService } from 'src/app/despacho/configuracion.service';
import { CondicionIIBB } from 'src/app/shared/enums/condicionIIBB';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';
import { AlicuotaDiferencial } from 'src/app/shared/models/cliente/AlicuotaDiferencial';
import { Cliente } from 'src/app/shared/models/cliente/Cliente';
import { ConfigClienteProvincia } from 'src/app/shared/models/cliente/ConfigClienteProvincia';
import { Provincia } from 'src/app/shared/models/facturacion/Provincia';
import { ClienteService } from '../cliente.service';
import { MessageService } from 'src/app/shell/message.service';

@Component({
  selector: 'app-modal-condicion-iibb',
  templateUrl: './modal-condicion-iibb.component.html',
  styleUrls: ['./modal-condicion-iibb.component.css']
})
export class ModalCondicionIIBBComponent implements OnInit {
  @Input() clienteIIBB: Cliente;
  @Output() condicionIIBB: EventEmitter<ConfigClienteProvincia[]> = new EventEmitter();

  condIIBB = CondicionIIBB;

  provincias: Provincia[];
  guardando: boolean = false;
  tablaIIBB: UntypedFormGroup;
  fechaDesde: UntypedFormControl;
  fechaHasta: UntypedFormControl;

  distintosIIBB: any[];
  constructor(
    public activeModal: NgbModal,
    private authService:AuthService, 
    @Inject(LOCALE_ID) public locale: string, 
    private changeDetector: ChangeDetectorRef, 
    private fb: UntypedFormBuilder, 
    private messageService: MessageService) {
      this.tablaIIBB = this.fb.group({
        fechaDesdeFormControl: new UntypedFormArray([]),
        fechaHastaFormControl: new UntypedFormArray([])
      })
    }

  ngOnInit() {
    this.distintosIIBB=[];
    for (let i = 0; i < this.clienteIIBB.configuracionProvincialImp.length; i++) {
      let iibb= new ConfigClienteProvincia();
      iibb.alicuotaDiferencial=this.clienteIIBB.configuracionProvincialImp[i].alicuotaDiferencial;
      iibb.condicionIIBB=this.clienteIIBB.configuracionProvincialImp[i].condicionIIBB;
      if(this.clienteIIBB.configuracionProvincialImp[i].alicuotaDiferencial==null) {
        iibb.fechaDesde={ day: 1, month: 1, year: 1901 }
        iibb.fechaHasta={ day: 1, month: 1, year: 1901 }
      }else{
        const fd = new Date(this.clienteIIBB.configuracionProvincialImp[i].alicuotaDiferencial.fechaDesde);
        iibb.fechaDesde = { day: fd.getDate(), month: fd.getMonth() + 1, year: fd.getFullYear() }
        const fh = new Date(this.clienteIIBB.configuracionProvincialImp[i].alicuotaDiferencial.fechaHasta);
        iibb.fechaHasta = { day: fh.getDate(), month: fh.getMonth() + 1, year: fh.getFullYear() }
      }
      iibb.id=this.clienteIIBB.configuracionProvincialImp[i].id;
      iibb.numeroIIBB=this.clienteIIBB.configuracionProvincialImp[i].numeroIIBB;
      iibb.provincia=this.clienteIIBB.configuracionProvincialImp[i].provincia;
      this.distintosIIBB.push(iibb);      
    }
    this.distintosIIBB.sort((a, b) => a.provincia.nombre.localeCompare(b.provincia.nombre));
    this.tablaIIBB.setControl('fechaDesdeFormControl', this.fb.array([]));
    this.distintosIIBB.map((o, i) => {
      const control = new UntypedFormControl(i === 0); // if first item set to true, else false
      control.setValue(o.fechaDesde);
      (this.tablaIIBB.controls.fechaDesdeFormControl as UntypedFormArray).push(control);
    });
    this.tablaIIBB.setControl('fechaHastaFormControl', this.fb.array([]));
    this.distintosIIBB.map((o, i) => {
      const control = new UntypedFormControl(i === 0); // if first item set to true, else false
      control.setValue(o.fechaHasta);
      (this.tablaIIBB.controls.fechaHastaFormControl as UntypedFormArray).push(control);
    });
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  esSupervisor()
  {
   return this.authService.hasRole(TipoUsuario.SUPERVISOR)
  }

  usuarioHabilitado()
  {
   return this.authService.hasRole(TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.APROBADORBYR, TipoUsuario.ADMCLIENTES);
  }

  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46;
  }

  keysCondicionIIBB(): Array<string> {
    var keys = Object.keys(this.condIIBB);
    keys = keys.slice(keys.length / 2);
    return keys;
  }

  actualizar(id: number, property: string, event: any) {
    if (property == 'condicionIIBB') {
      const editField = event.target.selectedIndex;
      this.distintosIIBB[id][property] = editField;
    } else {
      const editField = event.target.value;
      this.distintosIIBB[id][property] = editField;
    }
  }

  actualizar2(id: number, property: string, event: any) {
    if (this.distintosIIBB[id].alicuotaDiferencial == null) {
      this.distintosIIBB[id].alicuotaDiferencial = new AlicuotaDiferencial;
      this.distintosIIBB[id].alicuotaDiferencial.alicuota = 0;
      this.distintosIIBB[id].alicuotaDiferencial.fechaDesde = this.formatDate(1901, 1, 1, 0, 0, 0)
      this.distintosIIBB[id].alicuotaDiferencial.fechaHasta = this.formatDate(1901, 1, 1, 0, 0, 0)
    }
    if (property == 'alicuota') {
      const editField = event.target.textContent;
      this.distintosIIBB[id].alicuotaDiferencial[property] = parseFloat(event.target.textContent);
    } else if (property=='fechaHasta'){
      const editField = new Date(event.target.valueAsDate);
      const fh = this.formatDate(editField.getFullYear(), editField.getUTCMonth() + 1, editField.getUTCDate(), 23, 59, 59);
      this.distintosIIBB[id].alicuotaDiferencial[property] = fh;
    } else {
      var editField = new Date(event.target.valueAsDate);
      var fd = this.formatDate(editField.getFullYear(), editField.getUTCMonth() + 1, editField.getUTCDate(), 0, 0, 0);
      this.distintosIIBB[id].alicuotaDiferencial[property] = fd;
    }
  }

  guardarCondicion() {
    this.clienteIIBB.configuracionProvincialImp=this.distintosIIBB;
    this.messageService.showWarningMessage('Se modificaron correctamente las condiciones de IIBB. \n Recuerde guardar los cambios del cliente.', false)
    this.condicionIIBB.emit(this.clienteIIBB.configuracionProvincialImp);
    this.activeModal.dismissAll();
  }

  formatDate(year: number, month: number, day: number, hour: number, minute: number, second: number): string {
    let date = formatDate(new Date(year, month - 1, day, hour, minute, second), 'y-MM-ddTHH:mm:ss', this.locale);
    return date;
  }

  usuarioModificaCampos(): boolean {
    return this.authService.hasRole(TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.APROBADORBYR, TipoUsuario.ADMCLIENTES);
  }
}
