import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, UntypedFormArray } from '@angular/forms';
import { PrecioEntrada } from 'src/app/shared/models/abm/PrecioEntrada';
import { ProductoService } from '../../producto/producto.service';
import { TipoVenta } from 'src/app/shared/enums/tipoVenta';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalExportacionComponent } from '../modal-exportacion/modal-exportacion.component';
import { ModalConsultaComponent } from '../modal-consulta/modal-consulta.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { formatDate } from '@angular/common';
import { MessageService } from 'src/app/shell/message.service';


@Component({
  selector: 'app-detalle-precios',
  templateUrl: './detalle-precios.component.html',
  styleUrls: ['./detalle-precios.component.css']
})
export class DetallePreciosComponent implements OnInit {

  constructor(private productoService: ProductoService, private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private fb: UntypedFormBuilder,
    private messageService: MessageService) {
    this.tablaDetalles = this.fb.group({
      preciosFiltrados: new UntypedFormArray([])
    })
  }
  vigencia: any[] = [
    { nombre: "Vigentes", seleccionado: false },
    { nombre: "No Vigentes", seleccionado: false }
  ]
  tipoVenta: TipoVenta;
  tablaDetalles: UntypedFormGroup;
  preciosFiltrados: PrecioEntrada[];
  precioE: PrecioEntrada[];
  actualizandoPrecios: boolean = false;
  // todos = { nombre: "Todos", seleccionado: true };
  // vigentes = { nombre: "Vigentes", seleccionado: false };
  // noVigentes = { nombre: "No Vigentes", seleccionado: false };
  // vigencias = [this.vigentes, this.noVigentes];
  esperandoCarga: boolean = true;
  messagePrecios: string = "C a r g a n d o . . ."
  searchForm = new UntypedFormGroup({
    activo: new UntypedFormControl(-1),
    busqueda: new UntypedFormControl(''),
    precioVigente: new UntypedFormControl()
  })


  ngOnInit() {
    this.getPrecios();
    this.searchForm.valueChanges.subscribe(() => this.filtrarPrecios());
    this.spinner.show('spinnerGrafico');

  }

  keysTipoVenta() {
    var keys = Object.keys(this.tipoVenta);
    keys.shift();
    return keys.slice(keys.length / 2);
  }

  getPrecios() {
    this.esperandoCarga = true;
    this.productoService.getPreciosViejos()
      .subscribe(result => {
        this.preciosFiltrados = result;
        this.precioE = result;
        this.addCheckboxes();
        this.spinner.hide('spinnerGrafico');
        this.esperandoCarga = false;
      })
  }
  private addCheckboxes() {
    // reinicio el formulario
    this.tablaDetalles.setControl('preciosFiltrados', this.fb.array([]));
    this.preciosFiltrados.map((o, i) => {
      const control = new UntypedFormControl(i === 0); // if first item set to true, else false
      control.setValue(o.activo);
      (this.tablaDetalles.controls.preciosFiltrados as UntypedFormArray).push(control);
    });
  }

  importarPrecios() {
    const modalRef = this.modalService.open(ModalExportacionComponent, { centered: true, size: "lg", backdrop: "static" });
    modalRef.result.then((result => {
      this.ngOnInit();
    }))
  }

  consultarProducto() {
    const modalRef = this.modalService.open(ModalConsultaComponent, { centered: true, size: "lg", backdrop: "static" });
  }

  filtrarPrecios() {
    if (this.vigencia != null && this.preciosFiltrados != null) {
      this.preciosFiltrados = this.precioE.filter(a => {
        let agregar = true;
        if (this.busquedas.value) {
          if (a.cliente != null) {
            agregar = a.cliente.razonSocial.toLocaleLowerCase().indexOf(this.busquedas.value.toLocaleLowerCase()) > -1;
          } else { agregar = false; }
          agregar = agregar || (a.producto.nombreProducto && a.producto.nombreProducto.toLocaleLowerCase().indexOf(this.busquedas.value.toLocaleLowerCase()) > -1);
          agregar = agregar || (a.producto.codigoProducto && a.producto.codigoProducto.toLocaleLowerCase().indexOf(this.busquedas.value.toLocaleLowerCase()) > -1);
        }
        if (this.busquedas.value == '') {
          this.preciosFiltrados = this.precioE;
        }
        if (this.vigencia.some(e => e.seleccionado)) {
          agregar = agregar && (
            (this.vigencia[0].seleccionado == true
              && formatDate(a.fechaHasta, 'yyyy/MM/dd', 'en') >= formatDate(new Date(), 'yyyy/MM/dd', 'en'))
            ||
            (this.vigencia[1].seleccionado == true
              && formatDate(a.fechaHasta, 'yyyy/MM/dd', 'en') < formatDate(new Date(), 'yyyy/MM/dd', 'en')

            )
          )
        }
        return agregar;
      });
    }
  }

  actualizarPrecios(idprecio: string[]) {
    this.actualizandoPrecios = true;
    this.productoService.actualizarPreciosViejo(idprecio)
      .subscribe(response => {
        console.log(response);
        this.actualizandoPrecios = false;
        this.messageService.showSuccessMessage("Se actualizó el precio correctamente");
      });
  }

  get busquedas() {
    return this.searchForm.get("busqueda");
  }
}

