import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { StockService } from '../../../stock.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Abastecedora } from 'src/app/shared/models/stock/Abastecedora';
import { UsuarioService } from 'src/app/account/usuario.service';
import { Aeroplanta } from 'src/app/shared/models/despacho/Aeroplanta';
import { AbastecedoraService } from '../../../abastecedoras.service';
import { ProductoService } from 'src/app/abm/producto/producto.service';
import { Producto } from 'src/app/shared/models/abm/Producto';
import { Producto as ProductoStock } from 'src/app/shared/models/stock/Producto';
import { MessageService } from 'src/app/shell/message.service';
import { Pico } from 'src/app/shared/models/despacho/Pico';

@Component({
  selector: 'app-nueva-abastecedora',
  templateUrl: './nueva-abastecedora.component.html',
  styleUrls: ['./nueva-abastecedora.component.css']
})
export class NuevaAbastecedoraComponent implements OnInit {

  @Input() abastecedora: Abastecedora;
  @Input() arrayAbastecedora: Abastecedora[];

  codigoAutogenerado: string='';
  productos: Producto[] = [];
  cargandoProductos: boolean;
  errorProductos: boolean;
  aeroplanta: Aeroplanta;
  picos: Pico[] = [];

  guardando: boolean;
  editando: boolean;
  existeCodigo: boolean = false;

  form: UntypedFormGroup;
  picoForm: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public activeModal: NgbActiveModal,
    private abastecedoraService: AbastecedoraService,
    private usuarioService: UsuarioService,
    private stockService: StockService,
    private changeDetector: ChangeDetectorRef,
    private productosService: ProductoService,
    private messageService: MessageService) {

  }

  // hooks
  ngOnInit() {
    this.aeroplanta = this.usuarioService.getAeroplantaActual();
    this.crearFormulario();
    this.inicializarComponente();
    this.cargarProductos();

  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  // form
  crearFormulario() {
    this.form = this.formBuilder.group({
      codigoFormControl: ['', Validators.required],
      nombreFormControl: ['', Validators.required],
      capacidadFormControl: ['', Validators.required],
      productoFormControl: ['', Validators.required],
      patenteFormControl: ['', Validators.required],
      activoFormControl: ['', Validators.required],
    });
    this.picoForm = this.formBuilder.group({
      nombrePicoFormControl: ['', Validators.required],
      aforadorPicoFormControl: ['', Validators.required],
      tipoPicoFormControl: ['', Validators.required],
    });
  }

  inicializarComponente() {
    if (this.abastecedora == null) {
      this.obtenerProximoCodigoAbastecedora();
      this.editando = false;
      this.cargarDatosDefecto();
   } else {
      this.editando = true;
      this.cargarDatosAbastecedora();
    }

    this.guardando = false;
    this.cargandoProductos = false;
    this.errorProductos = false;
  }

  cargarDatosDefecto() {
    this.productoFormControl.setValue(this.productos[0]);
    this.activoFormControl.setValue(true);
  }

  cargarDatosAbastecedora() {
    this.codigoFormControl.setValue(this.abastecedora.codigo);
    this.codigoFormControl.disable();
    this.nombreFormControl.setValue(this.abastecedora.nombre);
    this.productoFormControl.setValue(this.abastecedora.productoActual);
    this.capacidadFormControl.setValue(this.abastecedora.capacidad);
    this.patenteFormControl.setValue(this.abastecedora.patente);
    this.activoFormControl.setValue(this.abastecedora.activo);
    this.picos = this.abastecedora.picos;

  }


  // llamadas al service
  cargarProductos(): void {
    this.cargandoProductos = true;
    this.productosService.obtenerProductos()
      .subscribe(productos => {
        this.productos = productos;
        this.productos = this.productos.filter(p => p.rubro.aerocombustible || p.rubro.combustible);
        this.productos = this.productos.filter(a => a.activo)
        if (!this.editando) { this.productoFormControl.setValue(this.productos[0]) }
        this.cargandoProductos = false;
      }, () => this.errorProductos = true);
  }

  // validaciones
  onlyDecimalPositivo(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46;
  }

  chequearCodigo() {
    this.existeCodigo = false;
    if (!this.editando) {
      for (let i = 0; this.arrayAbastecedora.length > i; i++) {
        if (this.arrayAbastecedora[i].codigo == this.codigoFormControl.value) {
          this.existeCodigo = true;
          this.codigoFormControl.setErrors({ 'incorrect': true });
        }
      }
    }
    return this.existeCodigo;
  }

  chequearPatente() {
    let existepatente = false;
    if (!this.editando) {
      for (let i = 0; this.arrayAbastecedora.length > i; i++) {
        if (this.arrayAbastecedora[i].patente == this.patenteFormControl.value) {
          existepatente = true;
          this.patenteFormControl.setErrors({ 'incorrect': true });
        }
      }
    }
    return existepatente;
  }

  checkearDefecto(p: Pico) {
    if (p.porDefecto == true) {
      p.porDefecto = false;
    } else {
      p.porDefecto = true;
    }
    this.hayPorDefecto(p);
  }

  hayPorDefecto(p: Pico) {
    this.picos.forEach((value, index) => {
      if (this.picos[index] != p) {
        this.picos[index].porDefecto = false;
      }

    })
  }

  compareProductos(p1: any, p2: any): boolean {
    return p1 && p2 ? p1.codigoProducto === p2.codigoProducto : p1 === p2;
  }

  asignarPico() {
    if (!this.picoForm.valid) {
      this.messageService.showErrorMessage('Por favor revise lo datos necesarios para el pico.')
    } else {
      let p = new Pico();
      p.nombre = this.nombrePicoFormControl.value;
      p.aforadorActual = parseInt(this.aforadorPicoFormControl.value);
      p.tipoPico = parseInt(this.tipoPicoFormControl.value);
      p.activo = true;
      p.porDefecto = false;
      this.picos.push(p);
      if (this.picos.length > 0 && this.picos.length < 2 && this.picos != null) {
        this.picos[0].porDefecto = true;
      }
      this.nombrePicoFormControl.setValue('');
      this.aforadorPicoFormControl.setValue(0);
    }
  }

  desasignarPico(p: Pico) {
    const index: number = this.picos.indexOf(p);
    if (index !== -1) {
      this.picos.splice(index, 1);
    }
  }


  // crear/editar

  onSubmit() {
    this.guardando = true;

    if (!this.editando) {
      this.abastecedora = new Abastecedora();
      this.abastecedora.productoActual = new ProductoStock();
      this.abastecedora.picos = [];
    }

    this.abastecedora.productoActual = this.stockService.convertirProducto(this.productoFormControl.value);
    this.abastecedora.picos = this.picos;
    this.abastecedora.nombre = this.nombreFormControl.value;
    this.abastecedora.codigo = this.codigoFormControl.value;
    this.abastecedora.capacidad = parseFloat(this.capacidadFormControl.value);
    this.abastecedora.patente = this.patenteFormControl.value;
    this.abastecedora.stockActual = 0;
    this.abastecedora.activo = this.activoFormControl.value;

    if (!this.editando) {
      this.abastecedoraService.crearAbastecedora(this.aeroplanta.codigoAeroplanta, this.abastecedora)
        .subscribe(abastecedora => {
          this.activeModal.close(abastecedora);
          this.guardando = false;
        });
    } else {
      this.abastecedoraService.editarAbastecedora(this.aeroplanta.codigoAeroplanta, this.abastecedora)
        .subscribe(abastecedora => {
          this.activeModal.close(abastecedora);
          this.guardando = false;
        });
    }
  }

  obtenerProximoCodigoAbastecedora(){
    this.abastecedoraService.obtenerProximoCodigoAbastecedora(this.aeroplanta.codigoAeroplanta,"4")
    .subscribe(result => {
      this.codigoAutogenerado=result.codigo;
      this.codigoFormControl.setValue(this.codigoAutogenerado);
    });
  }


  //getters
  get codigoFormControl() {
    return this.form.controls.codigoFormControl;
  }

  get nombreFormControl() {
    return this.form.controls.nombreFormControl;
  }

  get productoFormControl() {
    return this.form.controls.productoFormControl;
  }

  get capacidadFormControl() {
    return this.form.controls.capacidadFormControl;
  }

  get patenteFormControl() {
    return this.form.controls.patenteFormControl;
  }

  get activoFormControl() {
    return this.form.controls.activoFormControl;
  }

  get nombrePicoFormControl() {
    return this.picoForm.controls.nombrePicoFormControl;
  }

  get aforadorPicoFormControl() {
    return this.picoForm.controls.aforadorPicoFormControl;
  }

  get tipoPicoFormControl() {
    return this.picoForm.controls.tipoPicoFormControl;
  }
}
