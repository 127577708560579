import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ProductoService } from 'src/app/abm/producto/producto.service';
import { CierreTurnoService } from 'src/app/cierreTurno/cierre-turno.service';
import { ComprobanteService } from 'src/app/facturacion/comprobante.service';
import { TipoProducto } from 'src/app/shared/enums/tipoProducto';
import { Producto } from 'src/app/shared/models/abm/Producto';
import { ReporteMovimientoBalance7 } from 'src/app/shared/models/cierreTurno/ReporteMovimientoBalance7';
import { NgModel } from '@angular/forms';
import { MessageService } from 'src/app/shell/message.service';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';

enum TipoFiltro {
  a15Grados = 0,
  natural = 1
}
@Component({
  selector: 'app-stock-actual',
  templateUrl: './stock-actual.component.html',
  styleUrls: ['./stock-actual.component.scss']
})
export class StockActualComponent implements OnInit {

  TipoProducto = TipoProducto;
  TipoFiltro = TipoFiltro;

  cancelAsyncsRequests = false;
  soloPAD = true;
  checkEnabled = true;
  listaProductos: Producto[] = [];
  stockAeroplantas = [];
  stockAeroplantasFiltradas = [];

  tipoFiltroSeleccionado = TipoFiltro.natural;
  obteniendoStockContenedores: boolean = false;
  obteniendoFiltro: boolean = false;

  /**
   * cuando el usuario destilda/tilda soloPAD, resetea la consulta asincrona de todas las aeroplantas, 
   * si quedo un hilo ejecutandose lo cancela y arranca de nuevo
   */ 
  indexContador:number=1;
  requestCanceladas:number[]=[]; 
  aeroplantas

  constructor(private cierreTurnoService: CierreTurnoService,
    private productoService: ProductoService,
    private comprobanteService: ComprobanteService,
    private messageService: MessageService) { }

  async ngOnInit() {    
    this.obteniendoFiltro = true;

    this.listaProductos = await this.productoService.obtenerProductosPromise();
    this.listaProductos = this.listaProductos
      .filter(p => p.tipoProducto != TipoProducto.Servicios)
      .sort((a,b) => this.productoComparator(a, b)) ;

    this.procesarAerolantas()
  }

  initArray() {
    this.stockAeroplantas=[];
    this.stockAeroplantasFiltradas=[];
    this.cancelAsyncsRequests=false;
    this.requestCanceladas=[];
    this.indexContador = 1;
    this.checkEnabled=true;
  }

  changeSoloPAD() {    
    this.checkEnabled=false;
    setTimeout(() => {
      this.checkEnabled=true;
    }, 15000);      
    
    if(this.obteniendoStockContenedores) {
      this.cancelAsyncsRequests = true;      
      this.requestCanceladas.push(this.indexContador-1);
    } else {
      this.initArray();
    }   
    this.procesarAerolantas();
  }

  procesarAerolantas() {    
    this.obteniendoStockContenedores = true;
    this.comprobanteService.getDatosAeroplanta(null)
      .subscribe(aeroplantas => {
        aeroplantas= aeroplantas.filter( a => a.codigoAeroplanta != "SCL" && a.codigoAeroplanta != "SCE")
        aeroplantas= this.soloPAD ? aeroplantas.filter( a => a.soloPAD ) : aeroplantas
        aeroplantas = aeroplantas.sort( (a, b) => a.nombre < b.nombre ? -1 : 1 )
        console.log(aeroplantas.length);
        this.obteniendoFiltro = false;
        this.procesarStockAeroplantas(aeroplantas, this.indexContador++);
      });
  }

  async procesarStockAeroplantas(aeroplantas, index) {
    this.obteniendoStockContenedores = true;
    let indexInterno=index;
    console.log("procesando request "+indexInterno);
    for (let aeroplanta of aeroplantas) {
      if(this.soloPAD) {
        if(aeroplanta.soloPAD==false) {
          continue
        }
      }
      // Al destruir el componente este metodo asincrono sigue corriendo hasta finalizar, por eso la 
      // condición de corte que se activa en el ngOnDestroy
      if(this.requestCanceladas.includes(indexInterno) && this.cancelAsyncsRequests) {        
        this.initArray();
        console.log("cancelando request "+indexInterno);
        break; 
      }      

      try {
        let stockDataAeroplanta: ReporteMovimientoBalance7[] = [];
        let fecha = new Date();
        let fechaDesde = this.formatDate(fecha.getFullYear(), fecha.getMonth() + 1, fecha.getDate(), fecha.getHours(), fecha.getMinutes());
        stockDataAeroplanta = await this.cierreTurnoService.getMovimientosBalance7PorFechaPromise(null, aeroplanta.codigoAeroplanta, fechaDesde, fechaDesde, true);

        let aeroplantaData = { aeroplanta: this.armarRotuloAeroplanta(aeroplanta), stock: stockDataAeroplanta };
        this.stockAeroplantas.push(aeroplantaData);
        this.stockAeroplantasFiltradas.push(this.filtrarAeroplantaDataPorTipo(aeroplantaData, this.tipoFiltroSeleccionado));

      } catch (Ex) {
        this.messageService.showErrorMessage("Error de backend: "+Ex.description);
        this.initArray();
        this.procesarStockAeroplantas(aeroplantas, this.indexContador);
        return;
      }
    }

    this.obteniendoStockContenedores = false;
  }

  formatDate(year, month, day, hour, minute): string {
    return year + '-' + month.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + '-' + day.toLocaleString(undefined, { minimumIntegerDigits: 2 })
      + 'T' + hour.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + minute.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':00';
  }

  armarRotuloAeroplanta(aeroplanta) {
    return aeroplanta.codigoAeroplanta + " - " + aeroplanta.nombre;
  }

  filtrarNatural() {
    this.tipoFiltroSeleccionado = TipoFiltro.natural;
    this.filtrar();
  }

  filtrar15() {
    this.tipoFiltroSeleccionado = TipoFiltro.a15Grados;
    this.filtrar();
  }

  filtrar() {
    this.stockAeroplantasFiltradas = this.stockAeroplantas.map( aeroplantaData => {
      return this.filtrarAeroplantaDataPorTipo(aeroplantaData, this.tipoFiltroSeleccionado);
    });
  }

  filtrarAeroplantaDataPorTipo(aeroplantaData, tipo){
    let aux = {...aeroplantaData};
    aux.stock = aeroplantaData.stock.filter( s => s.tipoReporte == tipo );
    return aux;
  }

  renderizarStockPorProducto(codigoProducto, stock: ReporteMovimientoBalance7[]){
    return stock.find(s => s.codigoProducto == codigoProducto)?.final || "x";
  }

  productoComparator(a, b){
    // Primero Aerocombustible, luego Combustible y finalmente Aeroespecialidad
    if(a.tipoProducto == TipoProducto.Aerocombustible && b.tipoProducto != TipoProducto.Aerocombustible){
      return -1;
    }else if(a.tipoProducto == TipoProducto.Aerocombustible && b.tipoProducto == TipoProducto.Aerocombustible){
      return a.codigo < b.codigo ? -1 : 1;
    }else if(a.tipoProducto == TipoProducto.Combustible && b.tipoProducto == TipoProducto.Aerocombustible){
      return 1;
    }else if(a.tipoProducto == TipoProducto.Combustible && b.tipoProducto != TipoProducto.Combustible){
      return -1;
    }else if(a.tipoProducto == TipoProducto.Combustible && b.tipoProducto == TipoProducto.Combustible){
      return a.codigo < b.codigo ? -1 : 1;
    } else if(a.tipoProducto == TipoProducto.Aeroespecialidad && b.tipoProducto != TipoProducto.Aeroespecialidad){
      return 1;
    }else if(a.tipoProducto == TipoProducto.Aeroespecialidad && b.tipoProducto == TipoProducto.Aeroespecialidad){
      return a.codigo < b.codigo ? -1 : 1;
    }else{
      return -1;
    }
  }

  ngOnDestroy(): void {
    this.requestCanceladas.push(this.indexContador-1);
    this.cancelAsyncsRequests = true;    
  }
}
