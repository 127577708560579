import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { UsuarioService } from 'src/app/account/usuario.service';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { ComprobanteService } from 'src/app/facturacion/comprobante.service';
import { PreciosService } from 'src/app/precios/precios.service';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';
import { UsuarioAeroplanta } from 'src/app/shared/models/abm/UsuarioAeroplanta';
import { Aeroplanta } from 'src/app/shared/models/despacho/Aeroplanta';
import { Usuario } from 'src/app/shared/models/despacho/Usuario';
import { ManagementService } from '../management.service';

@Component({
  selector: 'app-abm-aeroplantas',
  templateUrl: './abm-aeroplantas.component.html',
  styleUrls: ['./abm-aeroplantas.component.css']
})
export class AbmAeroplantasComponent implements OnInit {
  obteniendo: boolean;
  aeroplantas: Aeroplanta[] = [];
  aeroplantaSeleccionada: Aeroplanta;
  editando: boolean = false;
  usuarios: Usuario[] = [];
  usuariosFiltrados: Usuario[] = [];

  aeroplantasFiltrarId: string[];

  constructor(
    private authService: AuthService,
    private spinner: NgxSpinnerService, 
    private usuarioService: UsuarioService,
    private comprobanteService: ComprobanteService) { }

    TipoUsuario = TipoUsuario;

  ngOnInit() {
    if(this.authService.hasRole(TipoUsuario.JEFEDEAEROPLANTA)) {
      this.aeroplantasFiltrarId=[];
      this.usuarioService.getAeroplantasCurrentUser().forEach(a=>{
        this.aeroplantasFiltrarId.push(a.codigoAeroplanta)
      });
    }
    this.getAeroplantas();
  }

  getAeroplantas() {
    this.mostrarSpinner();
    this.comprobanteService.getDatosAeroplanta(null)
      .subscribe(result => {
        if(this.authService.hasRole(TipoUsuario.JEFEDEAEROPLANTA)) {
          this.aeroplantas = result.filter(a=> this.aeroplantasFiltrarId.includes(a.codigoAeroplanta)).sort((a, b) => { if (a.nombre > b.nombre) { return 1 } else { return -1 } });
        }else{
          this.aeroplantas = result.sort((a, b) => { if (a.nombre > b.nombre) { return 1 } else { return -1 } });
        }
        this.ocultarSpinner();
      });
  }

  mostrarSpinner() {
    this.obteniendo = true;
    this.spinner.show('spinnerGrafico');
  }

  ocultarSpinner() {
    this.obteniendo = false;
    this.spinner.hide('spinnerGrafico');
  }  

  mostrarDetalle(i: number) {
    this.aeroplantaSeleccionada = this.aeroplantas[i];
    this.editando = true;
  }

  nuevaAeroplanta(){
    this.aeroplantaSeleccionada = new Aeroplanta();
    this.editando = false;
  }

  hasRole(...roles: TipoUsuario[]) {
    return this.authService.hasRole(...roles);
  }

}
