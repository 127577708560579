import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';

/** servicios */
import { PedidoService } from '../../../pedido.service';

/** clases */
import { ClientesSaldos } from 'src/app/shared/models/despacho/ClientesSaldos';
import { UsuarioService } from 'src/app/account/usuario.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-alijes-devolucion-lista',
  templateUrl: './alijes-devolucion-lista.component.html',
  styleUrls: ['./alijes-devolucion-lista.component.scss']
})
export class AlijesDevolucionListaComponent implements OnInit {

  /** locales */
  cargandoLista: boolean;
  seleccionado: boolean = false;
  clientes: ClientesSaldos[] = [];
  clientesFiltrados: ClientesSaldos[] = [];
  clienteSeleccionado: ClientesSaldos;

  /** salidas */
  @Output() cuentasSeleccionadas: EventEmitter<ClientesSaldos> = new EventEmitter;
  /** entradas */
  @Input() set clienteBuscado(texto: string) { this.filtrarClientes(texto) };

  constructor(
    private pedidoService: PedidoService,
    private spinner: NgxSpinnerService,
    private usuarioService:UsuarioService
  ) {}

  ngOnInit(): void {    
    this.getClientes(); 
  }

  getCodigoAeroplanta(){
    return this.usuarioService.getAeroplantaActual()?.codigoAeroplanta;
  }
  
  mostrarSìnner() {
    this.spinner.show('spinnerGrafico')
    this.cargandoLista = true;
  }

  ocultarSpinner() {
    this.spinner.hide('spinnerGrafico')
    this.cargandoLista = false;
  }

  getClientes() {
    this.mostrarSìnner();
    this.pedidoService.getObtenerClientesSaldos(this.getCodigoAeroplanta())
      .subscribe(result => {
        this.clientes = result.sort((a, b) => { if (a.razonSocial > b.razonSocial) { return 1 } else { return -1 } });
        this.clientesFiltrados = this.clientes;
        this.clienteSeleccionado = this.clientes[0];
        this.ocultarSpinner();
      }, () => {
        this.ocultarSpinner();
      });
  }

  mostrarDetalle(i: number) {
    this.clienteSeleccionado = this.clientesFiltrados[i];
    this.cuentasSeleccionadas.emit(this.clienteSeleccionado); 
  }

  filtrarClientes(texto: string) {
    this.clientesFiltrados = this.clientes.filter(a => {
      let agregar = true;
      if (texto && texto.length>2) {
        agregar = a.razonSocial.toLowerCase().indexOf(texto.toLowerCase()) > -1;
      }
      return agregar;
    });
  }

}
