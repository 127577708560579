<div class="p-2 add-margin">
  <div class="card">
    <div class="card-header">
      <b>Carga masiva de Laboratorios</b>
    </div>
    <div class="card-body">
      <h5 class="card-title">Utilice este importador para cargar masivamente los Laboratorios utilizando un archivo de
        tipo Excel <i class="fa fa-file-excel-o" aria-hidden="true"></i></h5>
      <p class="card-text"><b>Importante:</b> Debe tener el formato y ordenamiento que se muestra abajo:</p>

      <table class="table-bordered table-condensed text-center letra" style="border: solid; border-width: 2px;">
        <tr>
          <td class="py-2 px-2"><b>Codigo</b></td>
          <td class="py-2 px-2"><b>Nombre </b></td>
          <td class="py-2 px-2"><b>Referencia</b></td>
        </tr>
      </table>
     <br>

      <div *ngIf="fileUpload.status==='error'">
        <div [innerHTML]="fileUpload.message"></div>
      </div>
      <div *ngIf="fileUpload.status==='progress'">
        <div class="progress" style="height: 20px;margin-bottom:20px;">
          <div class="progress-bar" role="progressbar" [style.width.%]="fileUpload.message" aria-valuenow="25"
            aria-valuemin="0" aria-valuemax="100">{{fileUpload.message}}%</div>
        </div>
      </div>
      <form [formGroup]="profileForm" (ngSubmit)="onSubmit()">
        <div class="mb-3">
          <div class="custom-file">
            <input type="file" class="custom-file-input" name="profile" (change)="onSelectedFile($event)"
              id="validatedCustomFile" formControlName="inputFile" required>
            <label class="custom-file-label" for="validatedCustomFile">{{ userFileName }}</label>
            <div class="invalid-feedback">Example invalid custom file feedback</div>
          </div>
        </div>
        <div class="mb-3">
          <button type="submit" [disabled]="!archivoCargado || cargando" class="btn btn-primary">
            <span *ngIf="cargando">Cargando...</span>
            <span *ngIf="!cargando">Cargar</span>
          </button>
          <button type="button" class="btn btn-secondary ms-2" (click)="reset(true, true)">Cancelar</button>
        </div>
      </form>
      <div *ngIf=cargadoCorrectamente>
        <div *ngIf="fileUpload.length == 1" class="alert alert-success">
          <p>{{ fileUpload[0].message  }}</p>
          <p>{{ fileUpload[0].description  }}</p>
        </div>
        <ng-template #varios>
          <div *ngIf="fileUpload.length > 1" class="alert alert-warning" role="alert">
            <div *ngFor="let msg of fileUpload">
              <p>{{ msg.description }}</p>
            </div>
          </div>
        </ng-template>
      </div>
      <div *ngIf="error" class="alert alert-danger" role="alert">{{ error }}</div>
    </div>
  </div>
</div>
