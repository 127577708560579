import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { VehiculoDTO } from '../shared/models/cliente/VehiculoDTO';
import { isNullOrUndefined } from '../shared/compat-tools';
import { SharedService } from '../shared/shared.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CuentaVehiculo } from '../shared/models/abm/CuentaVehiculo';

@Injectable({
  providedIn: 'root'
})
export class VehiculoService {

  baseURL = environment.apiServer.clientesBaseUrl;
  private clienteV2URL = this.baseURL + '/ClientesV2';
  private aeronavesUrl = this.baseURL + '/VehiculosV1/Aeronaves';
  private automotoresUrl = this.baseURL + '/VehiculosV1/Automotores';

  private lastUpdatedAeronaves: Date;
  private lastUpdatedAutomotores: Date;
  private aeronaves: VehiculoDTO[];
  private automotores: VehiculoDTO[];

  constructor(
    private sharedService: SharedService,
    private httpClient: HttpClient
  ) { }
  
  getAeronaves(): Observable<VehiculoDTO[]> {
    return this.httpClient.get<VehiculoDTO[]>(this.aeronavesUrl, {
      params: {
        lastUpdated: this.lastUpdatedAeronaves ? this.sharedService.dateToFormattedString(this.lastUpdatedAeronaves) : ''
      }
    })
      .pipe(
        map(nuevos => {
          if (this.lastUpdatedAeronaves) {
            if (nuevos) {
              const idsNuevos = nuevos.map(c => c.id);
              let filtered = this.aeronaves.filter(c => isNullOrUndefined(idsNuevos.find(idNuevo => idNuevo == c.id)));
              this.aeronaves = filtered;
              this.aeronaves.push(...nuevos);
            }
          } else {
            this.aeronaves = nuevos;
          }
          this.lastUpdatedAeronaves = new Date();
          return this.aeronaves;
        })
      );
  }

  getAutomotores(): Observable<VehiculoDTO[]> {
    return this.httpClient.get<VehiculoDTO[]>(this.automotoresUrl, {
      params: {
        lastUpdated: this.lastUpdatedAutomotores ? this.sharedService.dateToFormattedString(this.lastUpdatedAutomotores) : ''
      }
    })
      .pipe(
        map(nuevos => {
          if (this.lastUpdatedAutomotores) {
            if (nuevos) {
              const idsNuevos = nuevos.map(c => c.id);
              let filtered = this.automotores.filter(c => isNullOrUndefined(idsNuevos.find(idNuevo => idNuevo == c.id)));
              this.automotores = filtered;
              this.automotores.push(...nuevos);
            }
          } else {
            this.automotores = nuevos;
          }
          this.lastUpdatedAutomotores = new Date();
          return this.automotores;
        })
      );
  }

  getCuentasVehiculos(tipoDocumento: number, numeroDocumento: string): Observable<CuentaVehiculo[]> { 
    return this.httpClient.get<CuentaVehiculo[]>(this.clienteV2URL + `/CuentasVehiculos?tipoDocumento=${tipoDocumento}&numeroDocumento=${numeroDocumento}`);
  }

}
