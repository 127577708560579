import { Responsable } from './Responsable';

export class AjusteManualAeroespecialidad{
    codigoProducto: string;
    motivoId: string;
    observacion: string;
    cantidadNueva: number;
    fechaHora: string;
    warnings: string[];
    ResponsableDTO: Responsable;
}