import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PedidoDetalleComponent } from './pedido-detalle/pedido-detalle.component';
import { NuevaPrecargaComponent } from './nueva-precarga/nueva-precarga.component';
import { NuevoClienteComponent } from '../shared/nuevo-cliente/nuevo-cliente.component';
import { NuevoPedidoComponent } from './nuevo-pedido/nuevo-pedido.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DespachoRoutingModule } from './despacho-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { VisorAerovalesComponent } from './visor-aerovales/visor-aerovales.component';
import { AerovaleDetalleComponent } from './aerovale-detalle/aerovale-detalle.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DespachoIndexComponent } from './despacho-index/despacho-index.component';
import { SharedModule } from '../shared/shared.module';
import {NgxPaginationModule} from 'ngx-pagination';
import { PrecargasComponent } from './precargas/precargas.component';
import { NuevoAerovaleComponent } from './nuevo-aerovale/nuevo-aerovale.component';
import { ModificarPedidoComponent } from './modificar-pedido/modificar-pedido.component';
import { ModalAnulacionAerovaleComponent } from './modal-anulacion-aerovale/modal-anulacion-aerovale.component';
import { AerovaleAeroespecialidadComponent } from './aerovales/aerovale-aeroespecialidad/aerovale-aeroespecialidad.component';
import { AerovaleCombustibleComponent } from './aerovales/aerovale-combustible/aerovale-combustible.component';
import { AerovaleCommonComponent } from './aerovales/aerovale-common/aerovale-common.component';
import { NuevoAerovaleComponentV2 } from './aerovales/nuevo-aerovale-v2.component';
import { SeleccionTipoAerovaleComponent } from './aerovales/seleccion-tipo-aerovale/seleccion-tipo-aerovale.component';
import { AerovaleAlijeComponent } from './aerovales/aerovale-alije/aerovale-alije.component';
import { VisorComprobanteComponent } from '../shared/visor-comprobante/visor-comprobante.component';
import { FacturacionModule } from '../facturacion/facturacion.module';
import { NgxBarcode6Module } from 'ngx-barcode6';
import { NgxPrintModule } from 'ngx-print';
import { ImporteFacturaComponent } from '../shared/importe-factura/importe-factura.component';
import { VisorRemitoAerovaleComponent } from './visor-remito-aerovale/visor-remito-aerovale.component';
import { ModalCreacionFoxComponent } from './modal-creacion-fox/modal-creacion-fox.component';
import { DireccionEntregaComponent } from './direccion-entrega/direccion-entrega.component';
import { AlijesSaldosComponent } from './alijes/alijes-saldos/alijes-saldos.component';
import { AlijesDevolucionListaComponent } from './alijes/alijes-saldos/alijes-devolucion-lista/alijes-devolucion-lista.component';
import { AlijesDevolucionSaldosComponent } from './alijes/alijes-saldos/alijes-devolucion-saldos/alijes-devolucion-saldos.component';
import { AlijesReportesComponent } from './alijes/alijes-reportes/alijes-reportes.component';
import { VuelosTamsComponent } from './tams/vuelos-tams/vuelos-tams.component';
import { TamsIndexComponent } from './tams/tams-index/tams-index.component';
import { AerolineasTamsListaComponent } from './tams/aerolineas/aerolineas-tams-lista/aerolineas-tams-lista.component';
import { AerolineasTamsAbmComponent } from './tams/aerolineas/aerolineas-tams-abm/aerolineas-tams-abm.component';
import { DestinosTamsListaComponent } from './tams/destinos/destinos-tams-lista/destinos-tams-lista.component';
import { DestinosTamsAbmComponent } from './tams/destinos/destinos-tams-abm/destinos-tams-abm.component';
import { ModalDestinosTamsComponent } from './tams/destinos/destinos-tams-abm/modal-destinos-tams/modal-destinos-tams.component';
import { ScreenMonitorComponent } from './tams/screen-monitor/screen-monitor.component';
import { ScreenConfiguracionComponent } from './tams/screen-configuracion/screen-configuracion.component';
import { AlijesIndexComponent } from './alijes/alijes-index/alijes-index.component';
import { VuelosIndexComponent } from './tams/vuelos-tams/vuelos-index/vuelos-index.component';
import { AerovaleUpdatedCommonComponent } from './aerovales-update/aerovale-updated-common/aerovale-updated-common.component';
import { AerovaleUpdatedAerocombustibleComponent } from './aerovales-update/aerovale-updated-aerocombustible/aerovale-updated-aerocombustible.component';
import { AerovaleUpdatedAlijeComponent } from './aerovales-update/aerovale-updated-alije/aerovale-updated-alije.component';
import { ModalSeleccionMatriculasComponent } from './modal-seleccion-matriculas/modal-seleccion-matriculas.component';
@NgModule({
  declarations: [
    DespachoIndexComponent,
    PedidoDetalleComponent,
    NuevaPrecargaComponent,
    NuevoClienteComponent,    
    NuevoPedidoComponent,
    VisorAerovalesComponent,
    AerovaleDetalleComponent,
    PrecargasComponent,
    NuevoAerovaleComponent,
    ModificarPedidoComponent,
    ModalAnulacionAerovaleComponent,
    AerovaleAeroespecialidadComponent,
    AerovaleCombustibleComponent,
    AerovaleCommonComponent,
    NuevoAerovaleComponentV2,
    SeleccionTipoAerovaleComponent,
    AerovaleAlijeComponent,
    VisorRemitoAerovaleComponent,
    ModalCreacionFoxComponent,
    DireccionEntregaComponent,
    AlijesDevolucionListaComponent,
    AlijesDevolucionSaldosComponent,
    AlijesReportesComponent,
    VuelosTamsComponent,
    TamsIndexComponent,
    AerolineasTamsListaComponent,
    AerolineasTamsAbmComponent,
    DestinosTamsListaComponent,
    DestinosTamsAbmComponent,
    ModalDestinosTamsComponent,
    ScreenMonitorComponent,
    ScreenConfiguracionComponent,
    AlijesIndexComponent,
    AlijesSaldosComponent,
    VuelosIndexComponent,
    AerovaleUpdatedCommonComponent,
    AerovaleUpdatedAerocombustibleComponent,
    AerovaleUpdatedAlijeComponent,
    ModalSeleccionMatriculasComponent
  ],
  entryComponents: [
    NuevaPrecargaComponent,
    NuevoClienteComponent,    
    ModalAnulacionAerovaleComponent,
    SeleccionTipoAerovaleComponent,
    VisorComprobanteComponent,
    ImporteFacturaComponent,
    VisorRemitoAerovaleComponent,
    ModalCreacionFoxComponent
  ],
  exports: [
    AerovaleDetalleComponent,
    VisorAerovalesComponent
  ],
  imports: [
    CommonModule,
    DespachoRoutingModule,
    NgbModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientInMemoryWebApiModule,
    HttpClientModule,
    NgxSpinnerModule,
    NgxPaginationModule,
    SharedModule,
    NgxBarcode6Module,
    NgxPrintModule    
  ]
})
export class DespachoModule { }
