  <nav class="navbar navbar-light bg-light navbar-expand-lg">
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
  
    <ul class="navbar-nav">
      <!-- desde -->
      <li class="nav-item me-2">
        <div class="input-group">
  
          <input type="date" [ngModel]="model" class="fechaFormControlDesdeHasta" placeholder="aaaa-mm-dd"
            (ngModelChange)="model = $event" name="dt" ngbDatepicker #d="ngbDatepicker" value="model"
            class="form-control form-control-rounded" #searchDate required readonly>
  
          <div class="input-group-append">
            <button id="botonDesde" type="button" class="input-group-btn btn btn-secondary" (click)="d.toggle()">
              <i class="fa fa-calendar" aria-hidden="true"></i></button>
          </div>
        </div>
      </li>
      <!-- hasta -->
      <li class="nav-item">
        <div class="input-group">
          <input type="date" class="fechaFormControlDesdeHasta" [ngModel]="model1" placeholder="aaaa-mm-dd" name="dp"
            value="model1" (ngModelChange)="model1 = $event" class="form-control form-control-rounded" ngbDatepicker
            #h="ngbDatepicker" #searchDate required readonly>
  
          <div class="input-group-append">
            <button id="botonHasta" type="button" class="input-group-btn btn btn-secondary" (click)="h.toggle()"><i
                class="fa fa-calendar" aria-hidden="true"></i></button>
          </div>
        </div>
      </li>
      <li class="nav-item">
        <button class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2" type="submit" (click)="buscarDesdeHasta()"><i class="fa fa-search"
            aria-hidden="true"></i></button>
      </li>
    </ul>
  </nav>

    <div class="col col-md-12">
      <div class="spinner-container" *ngIf="esperandoCarga">        
        <ngx-spinner name="spinnerGrafico" bdOpacity=0.3bd
          Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
          <p style="color: white">C a r g a n d o . . . </p>
        </ngx-spinner>        
      </div>       
    
      <ngb-alert class="alert-warning mt-2" *ngIf="!esperandoCarga && this.retirosCierre.length == 0">No hay retiros de efectivo para la fecha solicitada.</ngb-alert>

      <div class="row" *ngIf="this.retirosCierre.length > 0">
        <div class="col col-3">
          <div class="list-group py-3  menu-izq">
            <div *ngFor="let retiro of retirosCierre; let i=index">
              <button type="button" [class.active]="" class="list-group-item list-group-item-action">
                <div class="d-flex w-100 justify-content-between" (click)="mostrarDetalle(i)">
                  <ul class="list-unstyled">
                    <li>Retiro: {{ retiro.numero }} - {{ retiro.fechaRetiro | date: "dd'/'MM'/'y hh:mm a"}} </li>
                    <li>{{ retiro.responsable }}</li>
                  </ul>
                </div>
              </button>
            </div>
          </div>
        </div>

        <div class="col col-9" *ngIf="esperandoCargaRetiro">
          <div class="container">   
            <div class="mb-3" style="height: 10cm;">
                <ngx-spinner name="spinnerLinea" [ngStyle]="{'height': '400px', 'z-index':'0'}" bdOpacity=0.3bd
                Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
                <p style="color: white">C a r g a n d o . . . </p>
                </ngx-spinner> 
            </div>
          </div>
        </div>
      


        <div class="col col-9" *ngIf="!esperandoCargaRetiro && this.retiroSeleccionado != null" id="invoicingPrint"> 
          <div id="tituloPrint">          
            <h5>Retiro: {{this.retiroSeleccionado.numero}} - {{ this.retiroSeleccionado.fechaRetiro | date: "dd'/'MM'/'y hh:mm a"}}</h5>
            <h5>{{ this.retiroSeleccionado.responsable }}</h5>
          </div>                             
          <form id="formularioTablaMovimientos">
            <div class="col-md-12">
              <div id="tablaMovimientos" *ngIf="!obteniendoCierres && this.retiroSeleccionado.cierresCajas.length > 0">                
                <table id="tablaMovimientosDesdeHasta" class="table table-striped table-hover text-center">
                  <thead>
                    <tr>
                      <th>Fecha Cierre de Caja</th>
                      <th>Numero Cierre de caja</th>
                      <th>Supervisor</th>
                      <th>Efectivo</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let c of retiroSeleccionado.cierresCajas; let i = index" style="cursor: pointer;">
                      <td>{{c.fechaHora | date: 'dd/MM/yyyy HH:mm'}}</td>
                      <td>{{c.numero}}</td>
                      <td>{{c.responsable}}</td>
                      <td>{{c.totalEfectivoIngresado}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div style="text-align: right;" class="mt-2" *ngIf="this.retiroSeleccionado != null">
              <div class="row">
                <label style="margin-right: 5em;" class="col col-8" type="text">
                  <b>Total de efectivo retirado: </b>
                </label>
                <div class="border col">
                  {{this.totalRetiro | number: '0.2'}}
                </div>
              </div>
            </div>
            <br>
            <div *ngIf="this.retiroSeleccionado.linkRecibo != null">
              <img src="{{this.retiroSeleccionado.linkRecibo}}" alt="" width="90%">
            </div>
            <div class="card-footer" *ngIf="this.retiroSeleccionado != null">
              <button class="btn btn-outline-primary me-2" (click)="print()"><i class="fa fa-print" aria-hidden="true"></i>
                Imprimir</button>
            </div>
            
          </form>
        </div>
      </div>
    </div>