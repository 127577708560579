import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { HandleError, HttpErrorHandler } from '../http-error-handler.service';
import { Aeropuerto } from '../shared/models/despacho/Aeropuerto';
import { Observable, of } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AeropuertoService {

  baseUrl = environment.apiServer.baseUrl;
  private aerovalesUrl = this.baseUrl + '/AeropuertosV1/';
  private handleError: HandleError;

  private aeropuertos: Aeropuerto[];

  constructor(private http: HttpClient, private httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError('Pedidos');
  }

  getAeropuertos(): Observable<Aeropuerto[]> {
    if (this.aeropuertos) {
      return of(this.aeropuertos);
    }
    else {
      return this.http.get<Aeropuerto[]>(this.aerovalesUrl)
        .pipe(
          map(a => a.map<Aeropuerto>(b => Object.assign(new Aeropuerto(), b))),
          tap(a => this.aeropuertos = a)
        );
    }
  }
}
