<div class="container">
  <div class="row mt-4">
    <div class="col-sm-12 card-header fw-bold">
      Altas de nuevos usuarios pendientes de aprobación para aeroplanta {{this.aeroplantaActual.nombre}}
    </div>
  </div>

  <div class="row mt-3" *ngIf="this.usuariosPendientesValidacion.length == 0">
    <p *ngIf="!cargandoUsuariosPendientes" class="col-sm-10 ps-4">No hay usuarios pendientes de aprobación.</p>
  </div>
  <div class="row mt-3">
    <loading-text-spinner *ngIf="cargandoUsuariosPendientes" message="Obteniendo usuarios pendientes de validación..."color="primary"></loading-text-spinner>
    <loading-text-spinner *ngIf="procesandoValidacion" message="Procesando operación..."color="primary"></loading-text-spinner>
  </div>

  <div *ngIf="!procesandoValidacion && !cargandoUsuariosPendientes">
    <div class="row mt-3" *ngFor="let usuarioPendiente of usuariosPendientesValidacion; let i=index">
      <i class="fa fa-warning ms-5" style="color:#faf05f;"></i>      
      <div class="col-sm-4">Nombre completo:{{ usuarioPendiente.nombresCompletos}}</div> 
      <div class="col-sm-4">DNI: {{ usuarioPendiente.dni}}</div>        
      <button (click)="validarAsignacionUsuarioAeroplanta(usuarioPendiente)" class="btn btn-outline-success me-3 col" ><i class="fa fa-check-square" style="color:#8afdba;"></i>&nbsp; &nbsp;Aceptar</button> 
      <button (click)="descartarAsignacionUsuarioAeroplanta(usuarioPendiente.id)" class="btn btn-outline-danger col" ><i class="fa fa-window-close" style="color:#f64141;"></i>&nbsp; &nbsp;Descartar</button>
    </div>
  </div>

  <div *ngIf="this.hasRole(TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.MANDATARIO, TipoUsuario.TUTOR)" class="row mt-4">
    <div class="col-sm-12 card-header fw-bold">
      Asignación manual de usuario a aeroplanta {{this.aeroplantaActual.nombre}}
    </div>
  </div>

  <div *ngIf="this.hasRole(TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.MANDATARIO, TipoUsuario.TUTOR)" class="row mt-3">
    <div class="col-sm-7" [formGroup]="usuarioForm">
      <input #input  type="text" class="form-control" formControlName="usuarioFormControl" name="usuarioFormControl"
        id="usuarioFormControl" placeholder="Buscar usuarios" [ngbTypeahead]="searchUsuario"
        [inputFormatter]="usuarioFormatter" [resultFormatter]="usuarioFormatter" (selectItem)="onUsuarioSelectItem($event)"
        (change)="onChangeUsuario()">
    </div>
    <button class="btn btn-primary col-sm-3" [disabled]="(!usuarioSeleccionadoValido()) || guardandoAsignacion" type="submit" (click)="guardarAsignacionUsuarioAeroplanta(input)">
      <span *ngIf="!guardandoAsignacion">Asignar a aeroplanta {{aeroplantaActual.nombre}}</span>
      <div *ngIf="guardandoAsignacion" class="d-flex align-items-center">
        <div class="spinner-border text-light spinner-border-sm" role="status"></div>
        <span>&nbsp; &nbsp;Realizando asignación...</span>
      </div>
    </button>
  </div>

  <div class="row mt-4">
    <div class="col-sm-12 card-header fw-bold">
      Usuarios actualmente asignados a aeroplanta {{this.aeroplantaActual.nombre}}
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-sm-12">
      <loading-text-spinner *ngIf="cargandoTodosLosUsuarios" message="Obteniendo usuarios de la aeroplanta..."color="primary"></loading-text-spinner>

      <loading-text-spinner *ngIf="guardandoEliminacion" message="Eliminando asignación de usuario en aeroplanta..."color="primary"></loading-text-spinner>

      <table *ngIf="!cargandoTodosLosUsuarios && !guardandoEliminacion" class="table table-striped table-hover">
        <thead>
          <td class="text-center"><b>Usuario</b></td>
          <td class="text-center"><b>DNI</b></td>
          <td class="text-end"><b>Acciones</b></td>
        </thead>
        <tbody>
          <tr *ngFor="let usuario of filtrarUsuariosDeAeroplanta(true)">
            <td class="text-center">{{ usuario.nombresCompletos }}</td>
            <td class="text-center">{{ usuario.dni }}</td>
            <td class="text-end">
              <button *ngIf="usuario.id!=this.obtenerIdUsuarioLogueado()" type="button" (click)="eliminarAsignacionUsuarioAeroplanta(usuario.id)" class="btn btn-outline-danger"><i class="fa fa-trash" aria-hidden="true" style="color:#f64141"></i>&nbsp; &nbsp;Eliminar</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
