import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, Input } from '@angular/core';
import { PedidoService } from '../pedido.service';
import { NgbDateStruct, NgbCalendar, NgbModal, NgbDatepickerConfig, NgbTimeStruct, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormControl, AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, timestamp } from 'rxjs/operators';
import { Producto } from '../../shared/models/despacho/Producto';
import { PedidoDetalle } from '../../shared/models/despacho/PedidoDetalle';
import { Validators } from '@angular/forms';
import { ClienteService } from 'src/app/cliente/cliente.service';
import { NuevoClienteComponent } from '../../shared/nuevo-cliente/nuevo-cliente.component';
import { ModalConfirmComponent } from '../../shared/generic-modals/modal-confirm.component';
import { MessageService } from 'src/app/shell/message.service';
import { TipoVuelo } from 'src/app/shared/enums/tipoVuelo';
import { AeropuertoService } from '../aeropuerto.service';
import { Aeropuerto } from 'src/app/shared/models/despacho/Aeropuerto';
import { RanchoService } from '../../rancho/rancho.service';
import { UsuarioService } from 'src/app/account/usuario.service';
import { Rancho } from 'src/app/shared/models/ranchos/Rancho';
import { Autorizacion } from 'src/app/shared/models/traders/Autorizacion';
import { isNullOrUndefined } from 'src/app/shared/compat-tools';
import { TipoVehiculo } from 'src/app/shared/enums/tipoVehiculo';
import { CuentaCliente } from 'src/app/shared/models/despacho/CuentaCliente';
import { SaldoCuentaDTO } from 'src/app/shared/models/despacho/SaldoCuentaDTO';
import { TipoPedido } from 'src/app/shared/enums/TipoPedido';
import { VuelosTamsDTO } from 'src/app/shared/models/despacho/VuelosTamsDTO';
import { AerolineaCuentaDTO } from 'src/app/shared/models/despacho/AerolineaCuentaDTO';
import { VehiculoService } from 'src/app/cliente/vehiculo.service';
import { VehiculoDTO } from 'src/app/shared/models/cliente/VehiculoDTO';
import { ModalVehiculoComponent } from 'src/app/management/matriculas/abm-matriculas/modal-vehiculo/modal-vehiculo.component';
import { ModalSeleccionMatriculasComponent } from '../modal-seleccion-matriculas/modal-seleccion-matriculas.component';
import { AutorizacionDTO } from 'src/app/shared/models/cliente/AutorizacionDTO';

export enum TipoSeleccion {
  Matriculas,
  Clientes 
}
@Component({
  selector: 'app-nuevo-pedido',
  templateUrl: './nuevo-pedido.component.html',
  styleUrls: ['./nuevo-pedido.component.css']
})

export class NuevoPedidoComponent implements OnInit {

  @ViewChild('fechaHora', {static: true}) fechaHoraElement: ElementRef;
  @ViewChild('fechaHoraVuelo', {static: true}) fechaHoraVueloEl: ElementRef;
  
  @Input() tipoModalPedido:TipoPedido;

  // entradas desde devolucion de alijes
  @Input() cuentaSaldoAlije: SaldoCuentaDTO;  
  @Input() clienteSeleccionado: CuentaCliente;
  @Input() itemSaldoAlije: any;

  // entradas desde vuelos Tams
  @Input() vueloTams: VuelosTamsDTO;
  
  @Output() cancelado = new EventEmitter();
  @Output() nuevoPedidoCreado = new EventEmitter();

  titulo: string;
  model: NgbDateStruct;
  modelCliente: any;
  time: NgbTimeStruct;
  modelVuelo: NgbDateStruct;
  timeVuelo: NgbTimeStruct;
  clientes: CuentaCliente[];
  clientesAeronave: string[];
  aerocombustibles: Producto[];
  aeronaves: VehiculoDTO[] = [];
  aeronavesCliente: VehiculoDTO[];
  pedidoDetalle: PedidoDetalle;
  pedidosForm = new UntypedFormGroup({
    clienteFormControl: new UntypedFormControl(null),
    clienteAlijeFormControl: new UntypedFormControl(null),
    cuentaTamsFormControl: new UntypedFormControl(null),
    aeronaveFormControl: new UntypedFormControl(null),
    aerocombustibleFormControl: new UntypedFormControl(null),
    productoAlijeFormControl: new UntypedFormControl(null),
    numeroVueloFormControl: new UntypedFormControl(null),
    cantidadEstimadaFormControl: new UntypedFormControl(null),
    //porcentajeCierreFormControl: new FormControl(80, [Validators.required, Validators.min(1), Validators.max(100)]),
    fechaFormControl: new UntypedFormControl(null, [Validators.required]),
    horaFormControl: new UntypedFormControl(null, [Validators.required]),
    fechaVueloFormControl: new UntypedFormControl(null, [Validators.required]),
    horaVueloFormControl: new UntypedFormControl(null, [Validators.required]),
    posicionFormControl: new UntypedFormControl(null),
    tipoVueloFormControl: new UntypedFormControl(null),
    destinoFormControl: new UntypedFormControl(null)
  });
  cargandoClientes: boolean = true;
  guardandoPedido: boolean = false;
  cargandoAeronavesCliente: boolean = false;
  cargandoAerocombustibles: boolean = true;
  cargandoAeronaves: boolean = true;
  combustibleNoCoincide: boolean = false;
  aeropuertos: Aeropuerto[];
  cargandoAeropuertos: boolean = false;
  cargandoRancho: boolean = true;
  cargandoAutorizacion: boolean = false;
  fechayhoraArriboTams: boolean = false;
  rancho: Rancho;
  autorizacionTrader: Autorizacion | boolean = null;

  errorClientes: boolean = false;
  errorAeronaves: boolean = false;
  errorAerocombustibles: boolean = false;
  errorAeronavesCliente: boolean = false;
  errorDestinos: boolean = false;
  destinovalid: boolean;

  TipoVuelo=TipoVuelo;

  // Vuelos Tams
  cuentasTams: AerolineaCuentaDTO[]=[];
  cuentaFlag: AerolineaCuentaDTO;
  cargandoFechas: boolean = false;
  cargandoCuentas: boolean = false;
  existenCuentasTams: boolean = false;

  TipoPedido = TipoPedido;
  cargoModalSeleccion: boolean = false;
  isDisabled: boolean = false;
  autorizacion: AutorizacionDTO;

  TipoSeleccion = TipoSeleccion;
  
  constructor(private datepickerConfig: NgbDatepickerConfig,
    private calendar: NgbCalendar,
    private pedidoService: PedidoService,
    private clienteService: ClienteService,    
    private modalService: NgbModal,
    private messageService: MessageService,
    private aeropuertoService: AeropuertoService,
    private ranchoService: RanchoService,
    private usuarioService: UsuarioService,
    private activeModal: NgbActiveModal,
    private vehiculosService: VehiculoService,
    private pedidosService: PedidoService) { }

  ngOnInit() {
    let horaActual = new Date();
    let hora = new Date(horaActual.setMinutes(horaActual.getMinutes()));
    this.model = this.calendar.getToday();
    this.time = { hour: hora.getHours(), minute: hora.getMinutes(), second: hora.getSeconds() };
    
    if(this.tipoModalPedido==TipoPedido.Tams) {
      this.onCreateScreenTams(horaActual)
    } else {
      this.modelVuelo = this.calendar.getToday();
      this.timeVuelo = { hour: horaActual.getHours(), minute: horaActual.getMinutes(), second: horaActual.getSeconds() };
    }      

    if(this.tipoModalPedido==TipoPedido.Alije) {
      this.onCreateScreenAlije();
    } 
    
    if(this.tipoModalPedido==TipoPedido.Manual) {
      this.onCreateScreenManual();
    }
    
    this.getAeropuertos();
    this.getAeronaves();
  }

  ngOnDestroy(): void {
    if(!this.cargoModalSeleccion)
      this.cargoModalSeleccion=true; 
  }

  onCreateScreenManual() {
    this.titulo = "Nueva Programación de Pedido";
    this.pedidosForm.controls.clienteFormControl.setValidators(Validators.required);
    this.pedidosForm.controls.aerocombustibleFormControl.setValidators(Validators.required);
    this.pedidosForm.controls.aeronaveFormControl.setValidators([Validators.required, this.existeAeronave]);
    this.pedidosForm.controls.numeroVueloFormControl.setValidators([Validators.required,
                                                        Validators.minLength(3),
                                                        Validators.pattern('(([A-Za-z]{2}|[A-Za-z]\\d|\\d[A-Za-z])([1-9]|\\s)(\\d{1,4})|(((\W|^)FFAA(\W|$))|((\W|^)GOB(\W|$))|((\W|^)PRIV(\W|$))|((\W|^)SANI(\W|$))|((\W|^)ffaa(\W|$))|((\W|^)gob(\W|$))|((\W|^)priv(\W|$))|((\W|^)sani(\W|$))|((\W|^)YPF(\W|$))|((\W|^)ypf(\W|$))))')
                                                        ]);
    this.pedidosForm.controls.cantidadEstimadaFormControl.setValidators([Validators.required, Validators.min(1)]);
    this.getClientes();
    this.getAerocombustibles();        
    this.tipoVuelo.setValue(1);   
    this.isDisabled=true;
  }

  onCreateScreenTams(horaActual:Date) {
    this.cargandoFechas = true;      
    let dia = this.vueloTams.stda.slice(0,2);
    let mes = this.vueloTams.stda.slice(3,5);
    let anio = this.vueloTams.stda.slice(6,10);
    this.modelVuelo = { day: Number(dia), month: Number(mes), year: Number(anio)};
    let hr = this.vueloTams.stda.slice(11,13);
    let mm = this.vueloTams.stda.slice(14,16);
    this.timeVuelo = { hour: Number(hr), minute: Number(mm), second: 0 };

    /** se busca la fecha de arribo con el nro de matricula */
    /** Si hay matricula, la fecha es mayor a la actual y menor a la del vuelo */
    if(this.vueloTams.matricula) {
      this.searchFechaDeArribo(this.vueloTams, horaActual);
    } else {
      this.fechayhoraArriboTams = false;
      this.cargandoFechas = false;
    } 

    this.searchCuentasDeAerolineas(this.vueloTams);

    /**
     * VUELOS TAMS
     * Si no existen cuentas en aerolineas, mostramos clientes para seleccionar
     * Si existen cuentas en aerolineas, mostramos las cuentas (flag predeterminada)
     */
    this.titulo = "Nuevo Pedido Tams";      
    this.pedidosForm.controls.productoAlijeFormControl.setValidators(Validators.required);      
    this.pedidosForm.controls.numeroVueloFormControl.setValidators([Validators.required,
                                                        Validators.minLength(3),
                                                        Validators.pattern('(([A-Za-z]{2}|[A-Za-z]\\d|\\d[A-Za-z])([1-9]|\\s)(\\d{1,4})|(((\W|^)FFAA(\W|$))|((\W|^)GOB(\W|$))|((\W|^)PRIV(\W|$))|((\W|^)SANI(\W|$))|((\W|^)ffaa(\W|$))|((\W|^)gob(\W|$))|((\W|^)priv(\W|$))|((\W|^)sani(\W|$))|((\W|^)YPF(\W|$))|((\W|^)ypf(\W|$))))')
                                                        ]);
    this.pedidosForm.controls.cantidadEstimadaFormControl.setValidators([Validators.required, Validators.min(0)]);
    this.numeroVuelo.setValue(this.vueloTams.nro);
    this.productoAlije.setValue("AEROKEROSENE JET A-1  - 205000"); // siempre jetA-1  
    this.cantidadEstimada.setValue(0);
    this.cargandoAerocombustibles = false;
  }

  async searchFechaDeArribo(vuelo: VuelosTamsDTO, horaActual:Date) {
    try {
      let r = await this.pedidoService.getObtenerPedidosTamsAsync('A', vuelo.arpt);
      if(r) {
        let arribo = r.filter( v => v.matricula == vuelo.matricula)[0];
        if(arribo!=undefined) {
          let dia = arribo.stda.slice(0,2);
          let mes = arribo.stda.slice(3,5);
          let anio = arribo.stda.slice(6,10);
          this.model = { day: Number(dia), month: Number(mes), year: Number(anio)};
          let hr = arribo.stda.slice(11,13);
          let mm = arribo.stda.slice(14,16);
          this.time = { hour: Number(hr), minute: Number(mm), second: 0 };

          let ahora = new Date();     
          let fechaArribo = new Date(this.model.year, this.model.month-1, this.model.day, this.time.hour, this.time.minute);
          let fechaVuelo = new Date(this.modelVuelo.year, this.modelVuelo.month-1, this.modelVuelo.day, this.timeVuelo.hour, this.timeVuelo.minute);
          if(fechaArribo <= ahora && fechaArribo > fechaVuelo){
            this.fechayhoraArriboTams = true;
          } else {
            let hora = new Date(horaActual.setMinutes(horaActual.getMinutes()));
            this.model = this.calendar.getToday();
            this.time = { hour: hora.getHours(), minute: hora.getMinutes(), second: hora.getSeconds() };
          }
          this.cargandoFechas = false;            
        } else {
          this.cargandoFechas = false;    
        }
      }
    } catch (error) {
      console.log(error);
      this.cargandoFechas = false;
    }
  }

  async searchCuentasDeAerolineas(vuelo:VuelosTamsDTO) {
    try {
      this.cargandoCuentas = true; 
      this.cuentasTams = await this.pedidosService.getCuentasAerolinea(vuelo.idaerolinea, vuelo.arpt, vuelo.iatAdestorig);
      if(this.cuentasTams.length>0) {
        this.existenCuentasTams= true;
        this.cuentaFlag = this.cuentasTams.filter( c => c.flagUsar == true)[0];              
        this.pedidosForm.controls.cuentaTamsFormControl.setValidators(Validators.required);
        this.cuentaFlag != undefined? this.cuentaTams.setValue(this.cuentaFlag.cuenta) : this.cuentaFlag = { razonSocial:"", cuenta:"", flagUsar: false };
        this.cargandoClientes = false;
        this.cargandoCuentas = false;
      } else {
        this.pedidosForm.controls.clienteAlijeFormControl.setValidators(Validators.required);
        this.cargandoCuentas = false; 
        this.getClientes();
      }
    } catch (error) {
      console.log(error);
      this.cargandoCuentas = false; 
    }
  }

  onCreateScreenAlije() {
    /**
     * SALDOS ALIJES
     * el modal de nuevos-pedidos es reutilizable desde pedidos o devolución de alijes, depende el origen se valida o no algunos campos
     * la cantidad estimada la obtenemos de la cuenta del cliente con saldo pendiente a favor.
     * los campos aeronave y numero de vuelo son opcionales
     */
    this.titulo = "Nueva Devolución de Litros";
    this.pedidosForm.controls.clienteAlijeFormControl.setValidators(Validators.required);
    this.pedidosForm.controls.productoAlijeFormControl.setValidators(Validators.required);
    this.clienteAlije.setValue(this.cuentaSaldoAlije.razonSocial + " - " + this.cuentaSaldoAlije.nroCuenta);      
    this.cargandoClientes = false;
    this.productoAlije.setValue(this.itemSaldoAlije.productoNombre + " - " + this.itemSaldoAlije.productoCodigo);
    this.cargandoAerocombustibles = false;
    this.pedidosForm.controls.cantidadEstimadaFormControl.setValidators([Validators.min(1), Validators.max(this.itemSaldoAlije.saldoActual)]);
    this.cantidadEstimada.setValue(this.itemSaldoAlije.saldoActual);
    this.cargandoAeronaves = false;
    this.tipoVuelo.setValue(1);    
  }

  get traderBorderColor(): string {
    if (this.autorizacionTrader) {
      return 'border-success'
    } else if (this.autorizacionTrader == false) {
      return 'border-danger';
    }
    return '';
  }

  seleccionClienteMatricula(tipo:TipoSeleccion) {
    this.cargoModalSeleccion = true;
    const modalRef = this.modalService.open(ModalSeleccionMatriculasComponent, { centered: true });
    modalRef.componentInstance.clientes = this.clientes;
    modalRef.componentInstance.aeronaves = this.aeronaves;
    modalRef.componentInstance.tipo = tipo;
    modalRef.result.then((autorizacion:AutorizacionDTO) => {
      if(autorizacion) {
        this.cliente.setValue(this.clientes.find(c=>c.ctA_SGC==autorizacion.ctA_SGC));      
        this.aeronave.setValue(this.aeronaves.find(a=>a.matricula.toLowerCase().trim() == autorizacion.matricula.toLowerCase().trim()));
        this.aerocombustible.setValue(this.aerocombustibles.find(a=>a.codigoProducto==this.aeronave.value.codigoProducto));
        this.autorizacion=autorizacion;
      }else{
        this.messageService.showErrorMessage("Debe seleccionar un cliente/matrícula.");
      }      
    });
  }

  showAutorizacion() {
    return this.autorizacion instanceof AutorizacionDTO;
  }

  //#region Validación de aeronave
  existeAeronave(control: AbstractControl): { [key: string]: any } | null {
    if (control.value == null || control.value.length === 0) {
      return null; // don't validate empty values to allow optional controls
    }
    const esVehiculo = control.value instanceof Object;
    return control.valid && esVehiculo ? null : { 'existeAeronave': { value: control.value } };
  }

  //#endregion

  //#region Validación de fechas

  /**
   * Valida la fecha del pedido y, si es correcta, la del vuelo
   */
  validarFecha(timeStruct: NgbTimeStruct) {
    if (!this.fecha.dirty && !this.hora.dirty)
      return;
    let fechaEstimada = this.dateFromDateTimeStruct(this.model, this.time);

    /** Esto está porque el timepicker si le ponés dos veces mal la hora (por ej. 57), se queda mal en el input */
    if (timeStruct) {
      let horaStruct = timeStruct.hour;
      let minutesStruct = timeStruct.minute;
      horaStruct = horaStruct == 0 ? 12 : (horaStruct > 12 ? horaStruct - 12 : horaStruct);        
    }

    if (fechaEstimada == null || fechaEstimada.toString() == 'Invalid Date') {
      this.addValidationError(this.fecha, 'fechaValida');
    }
    else {
      this.fecha.updateValueAndValidity();
      if (fechaEstimada <= new Date())
        this.addValidationError(this.fecha, 'fechaFutura')
      else {
        this.fecha.updateValueAndValidity();
        this.validarFechaVuelo(fechaEstimada);
      }
    }
  }

  /**
   * Valida la fecha del vuelo
   * @param fechaEstimada Fecha estimada del pedido
   */
  validarFechaVuelo(fechaEstimada?: Date, timeStruct?: NgbTimeStruct) {
    if (!this.fecha.dirty && !this.hora.dirty && !this.fechaVuelo.dirty && !this.horaVuelo.dirty)
      return;
    if (timeStruct) {
      let horaStruct = timeStruct.hour;
      let minutesStruct = timeStruct.minute;
      horaStruct = horaStruct == 0 ? 12 : (horaStruct > 12 ? horaStruct - 12 : horaStruct);      
    }
    this.fechaVuelo.updateValueAndValidity(); //sacar cualquier cosa
    let fechaVuelo = this.dateFromDateTimeStruct(this.modelVuelo, this.timeVuelo);
    if (fechaEstimada == null)
      fechaEstimada = this.dateFromDateTimeStruct(this.model, this.time);
    if (fechaEstimada == null || fechaEstimada.toString() == 'Invalid Date') {
      this.addValidationError(this.fecha, 'fechaValida');
    }
    if (fechaVuelo == null || fechaVuelo.toString() == 'Invalid Date') {
      this.addValidationError(this.fechaVuelo, 'fechaValida');
    }
    if (this.fechaVuelo.valid && this.fecha.valid) {

      if (fechaVuelo > fechaEstimada) {
        this.fechaVuelo.updateValueAndValidity();
      }
      else {
        this.addValidationError(this.fechaVuelo, 'fechaVuelo');
      }
    }
  }
  /**
   * Agrega un error de validación en el AbstractControl y lo marca como Dirty
   */
  addValidationError(control: AbstractControl, validationName: string) {
    let errors = control.errors;
    if (errors == null)
      errors = { [validationName]: true };
    else
      errors[validationName] = true;
    control.setErrors(errors);
    control.markAsDirty();
  }
  /**
   * Convierte una NgbDateStruct y una NgbTimeStruct en un Date
   */
  dateFromDateTimeStruct(date: NgbDateStruct, hour: NgbTimeStruct): Date {
    if (date == null || hour == null)
      return null;
    let dateReturn = new Date();
    dateReturn.setDate(date.day);
    dateReturn.setMonth(date.month - 1);
    dateReturn.setFullYear(date.year);
    dateReturn.setHours(hour.hour);
    dateReturn.setMinutes(hour.minute);
    return dateReturn;
  }

  //#endregion

  //#region Typeahead

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 || this.cargandoClientes ? [] :
        this.clientes.filter(v => this.containsString(this.cliente.value, v.ctA_SGC, v.razonSocial)).slice(0, 10))
    )

  /**
   * Determina si una cadena se encuentra dentro de otra
   * @param searchValue Valor a buscar
   * @param searchIn Valores sobre los que buscar searchValue
   */
  containsString(searchValue: string, ...searchIn: string[]): boolean {
    const concat = searchIn.toString();
    return concat.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
  }

  formatter = (x: { razonSocial: string, ctA_SGC: string }) => `${x.razonSocial} - ${x.ctA_SGC}`;

  searchAeronave = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 || this.cargandoAeronavesCliente || isNullOrUndefined(this.aeronavesCliente) ? []
        : this.aeronavesCliente.filter(v => v.matricula.toLowerCase().indexOf(this.aeronave.value.toLowerCase()) > -1).slice(0, 10))
    )

  formatterAeronave = (x: { matricula: string }) => x.matricula;

  searchAeropuerto = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 || this.cargandoAeropuertos ? []
        : this.aeropuertos.filter(v => v.cabotaje == (this.tipoVuelo.value === TipoVuelo.Cabotaje) &&
          (v.codigoAeropuerto + ' - ' + v.nombre + ' - ' + v.ciudadNombre).toLowerCase().indexOf(this.destino.value.toLowerCase()) > -1).slice(0,20))
    )

  formatterAeropuerto = (x: { nombre: string, codigoAeropuerto: string}) => x.codigoAeropuerto + ' - ' + x.nombre;


  changeDestino(): void {
    if (typeof (this.destino.value) === 'string') {
      this.destino.setValue(null);
      this.destinovalid = true;
    }else{
      this.destinovalid = false;
    }
  }
  
  controlarCantidadEstimada() {
    if(this.tipoModalPedido==TipoPedido.Alije) {
      if(this.itemSaldoAlije.saldoActual<this.cantidadEstimada.value) {
        return false
      } 
      return true;      
    }
    return true;
  }

  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : (event.charCode >= 48 && event.charCode <= 57);
  }

  cargarPedido() {

    //** Esto es para que valide la fecha, por si no se "tocó" porque queda como válida */
    this.fechaVuelo.markAsDirty();
    this.fecha.markAsDirty();
    this.validarFecha(this.time);
    
    if (this.pedidosForm.valid) {
      this.guardandoPedido = true;
      this.pedidoDetalle = new PedidoDetalle();

      if(this.tipoModalPedido==TipoPedido.Alije) {
        this.pedidoDetalle.ctA_SGC = this.cuentaSaldoAlije.nroCuenta
      } else if(this.tipoModalPedido==TipoPedido.Tams) {
        if(this.existenCuentasTams) {
          this.pedidoDetalle.ctA_SGC = this.cuentaTams.value
        } else {
          this.pedidoDetalle.ctA_SGC = this.cliente.value.ctA_SGC
        } 
      } else {
        this.pedidoDetalle.ctA_SGC = this.cliente.value.ctA_SGC
      }
      
      if(this.aeronave.value) {
        this.pedidoDetalle.aeronaveId = this.aeronave.value.id;
        this.pedidoDetalle.aeronaveMatricula = this.aeronave.value.matricula.toUpperCase();
      }      
      if(this.tipoModalPedido==TipoPedido.Alije) {
        this.pedidoDetalle.codigoAerocombustible = this.itemSaldoAlije.productoCodigo;
      } else if(this.tipoModalPedido==TipoPedido.Tams) {
        this.pedidoDetalle.codigoAerocombustible = "205000"; // jet a-1
      } else {
        this.pedidoDetalle.codigoAerocombustible = (this.aerocombustible.value as Producto).codigoProducto;
      } 
      if(this.numeroVuelo.value) {
        this.pedidoDetalle.vuelo = this.numeroVuelo.value.toUpperCase();
      }      
      this.pedidoDetalle.cantidadEstimada = this.cantidadEstimada.value;
      // this.pedidoDetalle.porcentajeCierre = this.porcentajeCierre.value;
      this.pedidoDetalle.fechaHoraEstimada = this.formatDate(
        this.fecha.value.year, this.fecha.value.month,
        this.fecha.value.day, this.hora.value.hour, this.hora.value.minute);
      this.pedidoDetalle.fechaHoraVuelo = this.formatDate(this.fechaVuelo.value.year, this.fechaVuelo.value.month,
        this.fechaVuelo.value.day, this.horaVuelo.value.hour, this.horaVuelo.value.minute);
      this.pedidoDetalle.posicion = this.posicion.value;
      if (this.destino.value && this.destino.value.id) {
        this.pedidoDetalle.destinoId = this.destino.value.id;
      }
      
      this.pedidoDetalle.tipoPedido = this.tipoModalPedido
      if(this.tipoModalPedido==TipoPedido.Tams) {
        this.pedidoDetalle.vuelotams=this.vueloTams;
      }      

      // volver a controlar el saldo de alije
      this.pedidoService.CrearPedido(this.pedidoDetalle)
        .subscribe(pedido => {

          if (pedido) {
            if(this.tipoModalPedido==TipoPedido.Manual) {
              this.nuevoPedidoCreado.emit(pedido);
              this.pedidosForm.reset();
            } else {
              this.messageService.showSuccessMessage("Pedido generado exitosamente");
              this.tipoModalPedido==TipoPedido.Tams ? this.activeModal.close(true) : this.activeModal.close();
            }
            
          }
          else {
            this.guardandoPedido = false;
            this.messageService.showErrorMessage("Se ha producido un error al generar el pedido");
          }
      });
    }
    else
    {
      this.messageService.showErrorMessage("Faltan completar campos");
    }
  }

  formatDate(year, month, day, hour, minute): string {
    return year + '-' + month.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + '-' + day.toLocaleString(undefined, { minimumIntegerDigits: 2 })
      + 'T' + hour.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + minute.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':00';
  }

  //setea el aerocombustible de acuerdo a la aeronave seleccionada
  changeAeronave(): void {
    let buscaAeronave = this.aeronave.value;
    //Esto es por si ingresan el valor abc123 pero existe una aeronave ABC123, entonces elige esta última
    if (typeof (this.modelAeronave) === 'string') {
      buscaAeronave = this.aeronaves.find(p => p.matricula.toLowerCase().trim() == this.modelAeronave.toLowerCase().trim());
    }

    //Si no encuentro una aeronave, ofrecemos crear una
    if (!buscaAeronave) {
      this.nuevoAeronave(this.modelAeronave as string);
    }
    else {
      this.aeronave.setValue(buscaAeronave);
      // la idea seria buscar solo los cliente (cuentas) que tiene una matricula ingresada
      
      /* determinar mas adelante que va pasar con esto
      if (!this.aeronave.value.aerocombustible) {
        this.aerocombustible.enable();
        this.combustibleNoCoincide = false;
      }
      else {
        this.aerocombustible.disable();
        this.combustibleNoCoincide = true;

      }*/
      //Si no, me fijo si no es del cliente seleccionado, si está seguro de querer utilizarlo
      this.verificarAeronave();      
    }
    //this.getAutorizacionTrader();
  }

  nuevoAeronave(aeronaveText: any) {
    const modalRef = this.modalService.open(ModalVehiculoComponent, { backdrop: "static", centered: true });
    if (typeof (aeronaveText) === 'string') {
      modalRef.componentInstance.matriculaIngresada = aeronaveText.toUpperCase();
    }
    modalRef.componentInstance.tipoVehiculoParaFiltrar = TipoVehiculo.Aeronave;
    modalRef.componentInstance.vehiculos = this.aeronaves;    
    modalRef.result.then((vehiculo) => {
      if(vehiculo) {
        this.messageService.showSuccessMessage("Vehiculo creado");
        this.aeronaves.push(vehiculo);
        this.aeronave.setValue(vehiculo);
      }
    });
  }

  verificarAeronave() {
    let cliente:any;
    if (this.aeronave.value!=null) {
      this.tipoModalPedido==TipoPedido.Alije ? cliente=this.clienteAlije.value : cliente=this.cliente.value 
      if (cliente!=null && this.aeronavesCliente!=null
        && this.aeronavesCliente.filter(value => value.matricula.toLowerCase().trim() === this.aeronave.value.matricula.toLowerCase().trim()).length == 0) {
          const modalRef = this.modalService.open(ModalConfirmComponent, { centered: true });
          modalRef.componentInstance.mensaje = "La aeronave no pertenece al cliente, ¿desea asignarla de todas formas?"; // should be the id
          modalRef.result.then(() => {
          },
            () => {
              this.aeronave.setValue(null);
            }
          );        
      }      
    }
  }

  controlVehiculoProducto() {
    if (this.aerocombustible.value instanceof Object && this.aeronave.value instanceof Object && !this.cargandoAerocombustibles && !this.cargandoAeronaves) {      
      if(this.aeronave.value.codigoProducto != this.aerocombustible.value.codigoProducto)
        this.combustibleNoCoincide = true;
      else 
        this.combustibleNoCoincide = false;
    }
  }  

  changeAerocombustible(e): void {
    this.controlVehiculoProducto();
    this.getRancho();
  }

  changeTipoVuelo(e: any): void {
    this.destino.reset();
  }

  selectCustomer(e: any): void {

    this.cliente.setValue(e.item);
    this.buscarAeronave(e.item);
    this.getRancho();
  }

  buscarAeronave(cuenta: any) {
    if (cuenta.esTrader) {
      this.aeronavesCliente = [];
    } else {
      this.getAeronavesByCliente((cuenta as unknown as CuentaCliente).ctA_SGC, (cuenta as unknown as CuentaCliente).tipoDocumento, (cuenta as unknown as CuentaCliente).numeroDocumento);
    }
  }

  selectAeropuerto(e: any): void {
    this.destino.setValue(e.item);
    this.getRancho();
  }

  selectAeronave(e: any): void {
    // #region Se agrega para EDGE ya que no funcionaba el (change) en el input de aeronave
    // cuando se seleccionaba una aeronave de la lista de predicciones
    const isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    if (isIEOrEdge) {
      this.aeronave.setValue(e.item);
      this.changeAeronave();
    }
    //#endregion
  }

  confirmCustomer(): void {
    let buscaCliente = this.cliente.value;
    if (typeof (this.cliente.value) === 'string') {
      this.nuevoCliente(this.cliente.value);
    }
  }

  nuevoCliente(clientText): void {
    const modalRef = this.modalService.open(NuevoClienteComponent);
    if (typeof (clientText) === 'string') {
      modalRef.componentInstance.nombreCliente = clientText; // should be the id
    }
    modalRef.result.then((cliente) => {

      this.messageService.showSuccessMessage("Cliente generado exitosamente")
      this.clientes.push(cliente);

      this.cliente.setValue(cliente);
    }).catch((error) => {
      console.log(error);
    });
  }

  cancelar() {
    if(this.tipoModalPedido!=TipoPedido.Manual) {
      this.activeModal.close();
    } else {
      this.cancelado.emit();
    }    
  }

  //#region Gets de datos de servicios
  getClientes(): void {
    this.clienteService.getClientesActivos()
      .subscribe(clientes => {
        //clientes = clientes.map(a => Object.assign(new CuentaCliente(), a));
        this.clientes = clientes;
        this.cargandoClientes = false;
        if (!this.cargandoAeronaves && !this.cargoModalSeleccion && this.tipoModalPedido==TipoPedido.Manual) {
          this.seleccionClienteMatricula(null)
        }
      }, () => this.errorClientes = true);
  }

  getAerocombustibles(): void {
    this.pedidoService.getAerocombustible()
      .subscribe(result => {
        this.aerocombustibles = result;
        this.aerocombustible.enable();
        this.cargandoAerocombustibles = false;
      }, () => this.errorAerocombustibles = true);
  }

  getAeronaves() {
    this.vehiculosService.getAeronaves()
    .subscribe( aeronaves => {
      this.aeronaves = aeronaves;
      this.cargandoAeronaves = false;
      if(this.tipoModalPedido==TipoPedido.Tams) {
        let a = this.aeronaves.filter( a => a.matricula == this.vueloTams.matricula)[0];
        if(a!=undefined) {
          this.aeronave.setValue(a);
        } else {
          /** habilito para crear una nueva aeronave */
          if(this.vueloTams.matricula) {
            this.nuevoAeronave(this.vueloTams.matricula)
          }          
          //this.aeronave.setValue();
        }
      }
      if(this.tipoModalPedido==TipoPedido.Alije) {
        this.getAeronavesByCliente(this.cuentaSaldoAlije.nroCuenta, this.cuentaSaldoAlije.tipoDocumento, this.cuentaSaldoAlije.nroDocumento);
      }      
      if (!this.cargandoClientes && !this.cargoModalSeleccion && this.tipoModalPedido==TipoPedido.Manual) {
        this.seleccionClienteMatricula(null)
      } 
    }, () => this.errorAeronaves = true)   
  }

  getAeronavesByCliente(ctaSgc: string, tipoDoc:number, nroDoc:string) {
    this.cargandoAeronavesCliente = true;
    this.vehiculosService.getCuentasVehiculos(tipoDoc, nroDoc)
    .subscribe( resp => {
      this.aeronavesCliente = resp.filter( v => v.ctA_SGC == ctaSgc)[0].vehiculos.filter( a => a.tipoVehiculo==TipoVehiculo.Aeronave);      
      if (typeof (this.aeronave.value) != 'string')
        this.verificarAeronave();
      this.cargandoAeronavesCliente = false;
    }, () => {
      this.errorAeronavesCliente = true;
      this.cargandoAeronavesCliente = false;
    });
  }

  getAeropuertos(): void {
    this.cargandoAeropuertos = true;
    this.aeropuertoService.getAeropuertos().subscribe(aeropuertos => {
      this.aeropuertos = aeropuertos;
      this.cargandoAeropuertos = false;
      if(this.tipoModalPedido==TipoPedido.Tams) {
        let d = this.aeropuertos.filter( a => a.codigoAeropuerto == this.vueloTams.iatAdestorig)[0];
        if(d!=undefined) {
          this.destino.setValue(d);
          if(d.cabotaje) {
            this.tipoVuelo.setValue(1)
          } else {
            this.tipoVuelo.setValue(2)
          }
        }
      }
    }, () => this.errorDestinos = true)
  }

  getRancho(): void {
    if (this.mostrarRancho) {
      this.cargandoRancho = true;
      this.ranchoService.getRanchoPorCUIT(
        this.usuarioService.getAeroplantaActual().codigoAeroplanta,
        (this.destino.value as Aeropuerto).codigoPais,
        (this.aerocombustible.value as Producto).codigoProducto,
        (this.cliente.value as CuentaCliente).numeroDocumento,
        this.formatDate(
          this.fechaVuelo.value.year, this.fechaVuelo.value.month,
          this.fechaVuelo.value.day, this.horaVuelo.value.hour, this.horaVuelo.value.minute)
      ).subscribe(rancho => {
        this.rancho = rancho;
        this.cargandoRancho = false;
      })
    }
    else {
      this.rancho = null;
    }
  }

  noEstaCargandoInfo() {
    return  this.cargandoAeropuertos==false && this.cargandoAeronavesCliente==false &&
            this.cargandoAeronaves==false && this.cargandoAerocombustibles==false && this.cargandoClientes==false
  }

  esTrader() {
    return this.cliente.value != undefined && this.cliente.value != null && this.cliente.value.esTrader==true;
  }

  get vencida(): boolean {
    return new Date(this.rancho.fechaVencimiento) < new Date();
  }

  //#endregion

  //#region Gets de este component

  get mostrarRancho() {
    return (this.cliente.value instanceof Object && this.aerocombustible.value
      && this.tipoVuelo.value == TipoVuelo.Internacional && this.destino.value instanceof Aeropuerto);
  }

  get modelAeronave() {
    return this.aeronave.value;
  }

  get cliente() {
    return this.pedidosForm.get('clienteFormControl');
  }

  get clienteAlije() {
    return this.pedidosForm.get('clienteAlijeFormControl');
  }

  get cuentaTams() {
    return this.pedidosForm.get('cuentaTamsFormControl');
  }

  get aeronave() {
    return this.pedidosForm.get('aeronaveFormControl');
  }

  get aerocombustible() {
    return this.pedidosForm.get('aerocombustibleFormControl');
  }

  get productoAlije() {
    return this.pedidosForm.get('productoAlijeFormControl');
  }

  get numeroVuelo() {
    return this.pedidosForm.get('numeroVueloFormControl');
  }

  get cantidadEstimada() {
    return this.pedidosForm.get('cantidadEstimadaFormControl');
  }

  get porcentajeCierre() {
    return this.pedidosForm.get('porcentajeCierreFormControl');
  }

  get fecha() {
    return this.pedidosForm.get('fechaFormControl');
  }

  get hora() {
    return this.pedidosForm.get('horaFormControl');
  }

  get fechaVuelo() {
    return this.pedidosForm.get('fechaVueloFormControl');
  }

  get horaVuelo() {
    return this.pedidosForm.get('horaVueloFormControl');
  }

  get posicion() {
    return this.pedidosForm.get('posicionFormControl');
  }

  get tipoVuelo() {
    return this.pedidosForm.get('tipoVueloFormControl');
  }

  get destino() {
    return this.pedidosForm.get('destinoFormControl');
  }

  //#endregion
}
