import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-modal-observacion-refacturacion',
  templateUrl: './modal-observacion-refacturacion.component.html',
  styleUrls: ['./modal-observacion-refacturacion.component.css']
})
export class ModalObservacionRefacturacionComponent implements OnInit {

  //debemos declarar un input que traiga
  //la seleccion para que se obtenga la observacion

  @Input() detalle: string;

  form: UntypedFormGroup;

  constructor(
    public activeModal: NgbActiveModal) {
  }

  // hooks

  ngOnInit() {
    console.log(JSON.stringify("La observacion es "+this.detalle))
  }



}
