<nav class="navbar navbar-light bg-light navbar-expand-lg">
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav">
            <li class="nav-item me-2">
                <input id="busquedaFormControl" type="text" class="form-control" 
                    placeholder="Cía/Vuelo/Matrícula" [formControl]="busquedaFormControl" (input)="filtrarVuelos()" />
            </li>

            <!-- Tipo Movimientos 
            <li class="nav-item">
                <select class="form-select" id="tipoMovimientoFormControl" [formControl]="tipoMovimientoFormControl">
                    <option *ngFor="let tm of tipoMovimientos" [value]="tm.codigo">
                        {{tm.nombre}}
                    </option>
                </select>
            </li>  -->                    
        </ul>
    </div>
</nav>
    
<div class="col-12 container-fluid">
    
    <div class="spinner-container" *ngIf="obteniendoDatos">
        <ngx-spinner name="spinnerGrafico" [ngStyle]="{'height': '400px', 'z-index':'0'}" bdOpacity=0.3bd
            Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
            <p style="color: white">C a r g a n d o . . . </p>
        </ngx-spinner>
    </div>

    <ngb-alert class="alert alert-warning mt-2" *ngIf="this.vuelos.length == 0 && !this.obteniendoDatos">
        No hay Vuelos Tams para mostrar.
    </ngb-alert>
    
    <table class="table table-striped table-hover" id="tablaInformacion"
        *ngIf="this.vuelos.length > 0">
        <thead class="titulo text-center">
            <th>Compañía</th>
            <th>Vuelo</th>
            <th>STD</th>
            <th>ETD</th>
            <th>Matrícula</th>
            <th *ngIf="this.solapaVuelosTams == tipoVuelosTams.Partidas">Destino</th>
            <th *ngIf="this.solapaVuelosTams == tipoVuelosTams.Arribos">Origen</th>
            <th>Escala</th>
            <th>Posición</th>
            <th>Estado</th>            
            <th *ngIf="this.solapaVuelosTams == tipoVuelosTams.Partidas">Acción</th>
        </thead>
        <tbody class="text-center">
            <tr *ngFor="let item of vuelosFiltrados; let i=index"
                [ngClass]="{'remarcar': item.pedidoId!=null}"
            >
                <td>{{ item.idaerolinea +" - "+ item.aerolinea }}</td>
                <td>{{ item.nro }}</td>
                <td>{{ item.stda }}</td> 
                <td>{{ item.etda }}</td> 
                <td>{{ item.matricula ? item.matricula.toUpperCase() : "-"}}</td>        
                <td>{{ item.iatAdestorig + " - " + item.destorig }}</td>
                <td>-</td> 
                <th>{{ item.posicion ? item.posicion : "Sin posición"}}</th>
                <td>{{ item.estes ? item.estes : "-"}}</td> 
                <td *ngIf="this.solapaVuelosTams == tipoVuelosTams.Partidas">
                    <ng-container *ngIf="item.pedidoId==null">
                        <button class="btn" (click)="crearPedido(item)" ngbTooltip="Crear Pedido">
                            <i *ngIf="!obteniendoPedido || obteniendoPedido && pedidoSeleccionado!=i" class="fa fa-plus-circle" aria-hidden="true"></i>
                            <div *ngIf="pedidoSeleccionado==i && obteniendoPedido">
                                <div class="spinner-border text-primary spinner-border-sm" role="status"></div>
                                <span><i class="fa fa-plus-circle" aria-hidden="true"></i></span>
                            </div>
                        </button>
                    </ng-container>                        
                    <ng-container *ngIf="item.pedidoId!=null">
                        <button class="btn" (click)="mostrarPedido(item.pedidoId, i)" ngbTooltip="Ver pedido">
                            <i *ngIf="!obteniendoPedido || obteniendoPedido && pedidoSeleccionado!=i" class="fa fa-eye" aria-hidden="true"></i>
                            <div *ngIf="pedidoSeleccionado==i && obteniendoPedido">
                                <div class="spinner-border text-primary spinner-border-sm" role="status"></div>
                                <span><i class="fa fa-eye" aria-hidden="true"></i></span>
                            </div>
                        </button>
                    </ng-container>
                </td>                    
            </tr>
        </tbody>
    </table>
</div>