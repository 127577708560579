<nav class="navbar navbar-light bg-light navbar-expand-lg mb-3">
  <form class="form-inline flex-fill" id="formularioFiltros" [formGroup]="searchForm">
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <ul class="navbar-nav me-auto flex-row">
      <div class="nav-item">
        <input id="busquedaFormControl" type="text" class="form-control input-sm" placeholder="Búsqueda"
          formControlName="busqueda" (input)="filtrarPrecios()" placeholder="Producto/Cod. de Producto"/>
      </div>
      <!-- <input class="form-control flex-fill" (input)="filtrarRanchos()" (mouseup)="detectSearchClear($event)"
      [(ngModel)]="busqueda" name="busqueda" placeholder=" Filtrar por rancho / cliente / aeroplanta / producto " /> -->
      <!-- <div class="nav-item">
        <label for="selectMostrar" class="ms-2 me-2 pt-2">Mostrar</label>
      </div>
      <select class="form-select ms-sm-3" formControlName="activo" (change)="vigenciaPrecios()" id="activo">
        <option selected value="-1">Todos</option>
        <option value="1">Vigentes</option>
        <option value="0">No Vigentes</option>
      </select> -->
      <div class="nav-item">
      <div ngbDropdown autoClose="outside">
        <button class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2" id="dropdownForm2"
          ngbDropdownToggle>Estados</button>
        <div ngbDropdownMenu aria-labelledby="dropdownForm2">
          <div class="px-4 py-3">
            <div *ngFor="let estado of vigencia">
              <div class="form-check">
                <input type="checkbox" class="custom-control-input" id="{{ 'dropdownCheck' + estado.nombre}}" formControlName="precioVigente"
                  [(ngModel)]="estado.seleccionado" (ngModelChange)="estado.seleccionado = $event"
                  (change)="filtrarPrecios()">
                <label class="custom-control-label text-nowrap" for="{{ 'dropdownCheck' + estado.nombre}}">
                  {{ estado.nombre}}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </ul>
    <!-- <div class="nav-item float-end">
      <button type="button" class="btn btn-outline-primary" (click)="consultarProducto()"  [disabled]="esperandoCarga"><a
          class=" float-end">Consultar</a></button>
    </div> -->
  </form>
</nav>

<form class="mb-3" [formGroup]="tablaDetalles">
  <div class="col col-md-12">
    <div class="spinner-container" *ngIf="esperandoCarga">
      <ngx-spinner name="spinnerGrafico" bdOpacity=0.3bd
        Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
        <p style="color: white">{{messagePrecios}}</p>
      </ngx-spinner>
    </div>
    <div id="tablaDetalle" class="tabla-precios" *ngIf="!esperandoCarga">
      <table id="tabla" class="table table-sm table-striped table-hover">
        <thead class="text-center">
          <tr>
            <th scope="col"> Codigo </th>
            <th scope="col"> Producto </th>
            <th scope="col"> Desde-Hasta</th>
            <th scope="col"> Moneda </th>
            <th scope="col"> Cliente </th>
            <th scope="col"> Tipo Venta </th>
            <th scope="col"> Precio </th>
            <th scope="col"> Entrega </th>
            <th scope="col"> Area Negociación </th>
            <th scope="col"> Segmento </th>
            <th scope="col"> Condición venta </th>
            <th scope="col"> Aeroplanta</th>
            <th scope="col"> Activo </th>
          </tr>
        </thead>
        <tbody class="text-center">
          <tr formArrayName="preciosFiltrados"
            *ngFor="let p of preciosFiltrados; let i = index">
            <td *ngIf="preciosFiltrados[i].producto.codigoProducto != null">
              {{preciosFiltrados[i].producto.codigoProducto}} </td>
            <td *ngIf="preciosFiltrados[i].producto.codigoProducto == null"> -- </td>
            <td *ngIf="preciosFiltrados[i].producto.nombreProducto != null">
              {{preciosFiltrados[i].producto.nombreProducto}} </td>
            <td *ngIf="preciosFiltrados[i].producto.nombreProducto == null"> -- </td>
            <td *ngIf="preciosFiltrados[i].fechaDesde != null && preciosFiltrados[i].fechaHasta != null">
              {{preciosFiltrados[i].fechaDesde | date: "dd'/'MM'/'yy"}} -
              {{preciosFiltrados[i].fechaHasta | date: "dd'/'MM'/'yy"}}</td>
            <td *ngIf="preciosFiltrados[i].fechaHasta == null && preciosFiltrados[i].fechaDesde"> -- </td>
            <td *ngIf="preciosFiltrados[i].moneda != null"> {{preciosFiltrados[i].moneda.nombre}} </td>
            <td *ngIf="preciosFiltrados[i].moneda == null"> -- </td>
            <td *ngIf="preciosFiltrados[i].cliente != null"> {{preciosFiltrados[i].cliente.razonSocial}}
            </td>
            <td *ngIf="preciosFiltrados[i].cliente == null"> -- </td>
            <td *ngIf="preciosFiltrados[i].condicionVenta != null && preciosFiltrados[i].tipoVenta == 2"> Internacional
            </td>
            <td *ngIf="preciosFiltrados[i].condicionVenta != null && preciosFiltrados[i].tipoVenta == 1"> Cabotaje </td>
            <td *ngIf="preciosFiltrados[i].tipoVenta == null"> -- </td>
            <td *ngIf="preciosFiltrados[i].precioContado != null"> {{preciosFiltrados[i].precioContado}} </td>
            <td *ngIf="preciosFiltrados[i].ubicacionentrega != null"> {{preciosFiltrados[i].ubicacionentrega.direccion}}
            </td>
            <td *ngIf="preciosFiltrados[i].ubicacionentrega == null"> {{preciosFiltrados[i].codigoAeroplanta}} </td>
            <td *ngIf="preciosFiltrados[i].areaNegocio != null"> {{preciosFiltrados[i].areaNegocio.nombreArea}} </td>
            <td *ngIf="preciosFiltrados[i].areaNegocio == null"> -- </td>
            <td *ngIf="preciosFiltrados[i].segmento != null"> {{preciosFiltrados[i].segmento.nombreSegmento}} </td>
            <td *ngIf="preciosFiltrados[i].segmento == null"> -- </td>
            <td *ngIf="preciosFiltrados[i].condicionVenta != null && preciosFiltrados[i].condicionVenta == 1"> Contado
            </td>
            <td *ngIf="preciosFiltrados[i].condicionVenta != null && preciosFiltrados[i].condicionVenta == 2"> Cuenta
              Corriente </td>
            <td *ngIf="preciosFiltrados[i].condicionVenta == null"> -- </td>
            <td>{{preciosFiltrados[i].codigoAeroplanta}}</td>
            <td>
              <div class="form-check form-switch">
                <input type="checkbox" [formControlName]="i" class="custom-control-input"
                  (click)="actualizarPrecios(preciosFiltrados[i].id)" id="{{preciosFiltrados[i].id}}">
                <label class="custom-control-label" for="{{preciosFiltrados[i].id}}"></label>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</form>
<div class="mt-2 float-end">
  <button type="button" class="btn btn-outline-primary" (click)="importarPrecios()" [disabled]="esperandoCarga">Importar precios</button>
</div>
