<div style="margin-top: 2em; margin-bottom: 2em;">Datos del cliente creado:</div>
<table id="nuevo-cliente" class="table" *ngIf="modificacionesCliente != null">
  <tr>
    <td class="rotulo">Razon Social:</td>
    <td class="" colspan="3">{{modificacionesCliente.clienteNuevo.razonSocial}}</td>
  </tr>
  <tr>
    <td class="rotulo">{{ utilClientes.obtenerDescripcionTipoDocumento(modificacionesCliente.clienteNuevo.tipoDocumento)}}</td>
    <td class="" colspan="3">{{modificacionesCliente.clienteNuevo.numeroDocumento}}</td>
  </tr>
  <tr>
    <td class="rotulo">Responsable</td>
    <td class="">Nombre: {{modificacionesCliente.clienteNuevo.responsable.nombreCompleto}}</td>
    <td class="">Email: {{modificacionesCliente.clienteNuevo.responsable.email}}</td>
    <td class="">Telefono: {{modificacionesCliente.clienteNuevo.responsable.telefono}}</td>
  </tr>
  <tr>
    <td class="rotulo">Condición IVA:</td>
    <td class="" colspan="3">{{ modificacionesCliente.clienteNuevo.condicionIva.descripcion}}</td>
  </tr>
  <tr>
    <td class="rotulo">Cuenta SAP:</td>
    <td class="" colspan="3">{{ modificacionesCliente.clienteNuevo.ctaSap}}</td>
  </tr>
  <tr>
    <td class="rotulo">Área:</td>
    <td class="" colspan="3">{{ modificacionesCliente.clienteNuevo.subArea.area == null ? "-" : modificacionesCliente.clienteNuevo.subArea.area.descripcion}}</td>
  </tr>
  <tr>
    <td class="rotulo">Segmento:</td>
    <td class="" colspan="3">{{ modificacionesCliente.clienteNuevo.subArea.descripcion}}</td>
  </tr>
  <tr>
    <td class="rotulo">Dirección fiscal:</td>
    <td class="" colspan="3">
      {{ modificacionesCliente.clienteNuevo.direccionFiscal.calle }} {{ modificacionesCliente.clienteNuevo.direccionFiscal.numero }} {{ modificacionesCliente.clienteNuevo.direccionFiscal.piso }} {{ modificacionesCliente.clienteNuevo.direccionFiscal.departamento }}<br/> 
      {{ modificacionesCliente.clienteNuevo.direccionFiscal.ciudad.nombre}} ({{ modificacionesCliente.clienteNuevo.direccionFiscal.ciudad.codigoPostal}})
    </td>
  </tr>
  <tr>
    <td class="rotulo">Actividad:</td>
    <td class="" colspan="3">{{ utilClientes.obtenerDescripcionActividadPad(modificacionesCliente.clienteNuevo.actividadPAD)}}</td>
  </tr>
  <tr>
  <tr *ngIf="false">
    <td class="rotulo">Segmento:</td>
    <td class="" colspan="3">{{ modificacionesCliente.clienteNuevo.grupoCliente == null ? "-" : modificacionesCliente.clienteNuevo.grupoCliente.descripcion}}</td>
  </tr>
  <tr>
  <tr>
    <td class="rotulo">Percep. Iva:</td>
    <td class="" colspan="3">{{ modificacionesCliente.clienteNuevo.percepcionIVADif}}</td>
  </tr>
  <tr>
    <td class="rotulo">Vigente:</td>
    <td class="" colspan="3">{{ modificacionesCliente.clienteNuevo.fechaPercepcionIVADif | date: "d'/'MM'/'y' 'hh:mm a" }}</td>
  </tr>  
  <tr *ngIf="modificacionesCliente.clienteNuevo.direccionesEntrega != null">
    <td class="rotulo">Direcciones entrega:</td>
    <td class="" colspan="3">
      <div  *ngFor="let dirEntrega of modificacionesCliente.clienteNuevo.direccionesEntrega" style="margin-bottom: 1em; border-bottom: 1px solid #ccc;">
        {{ dirEntrega.calle }} {{ dirEntrega.numero }} {{ dirEntrega.piso }} {{ dirEntrega.departamento }} <br/>
        {{ dirEntrega.ciudad.nombre}} ({{ dirEntrega.ciudad.codigoPostal}})
      </div>      
    </td>
  </tr>  
  <tr>
    <td class="rotulo">Comerciales:</td>
    <td class="" colspan="3"><span *ngFor="let comercial of modificacionesCliente.clienteNuevo.comerciales">{{ comercial}} . </span></td>
  </tr>
  <tr>
    <td class="rotulo">Cliente observado:</td>
    <td class="" colspan="3">{{ modificacionesCliente.clienteNuevo.clienteObservado ? "Si" : "No"}} </td>
  </tr>  
  <tr>
    <td class="rotulo">Activo:</td>
    <td class="" colspan="3">{{ modificacionesCliente.clienteNuevo.activo ? "Si" : "No"}} </td>
  </tr>
  <tr>
    <td class="rotulo">Condición IIBB:</td>
    <td class="" colspan="3">
      <div *ngFor="let iibb of modificacionesCliente.clienteNuevo.configuracionProvincialImp">
        {{ iibb.provincia.codigo }}: Num. IIBB:{{ iibb.numeroIIBB }} - {{ this.utilClientes.obtenerDescripcionCondicionIIBB(iibb.condicionIIBB) }} - Alicuota: {{ iibb.alicuotaDiferencial == null ? " SIN ALICUOTA " : iibb.alicuotaDiferencial.alicuota }} {{ iibb.alicuotaDiferencial == null ? "" : iibb.alicuotaDiferencial.fechaHasta | date: "d'/'MM'/'y' 'hh:mm a" }}
      </div>      
    </td>
  </tr>        
  <tr>
    <td class="rotulo">Cuentas</td>
    <td class="" colspan="3">
      <div *ngFor="let mc of modificacionesCliente.modificacionesCuenta" style="margin-bottom: 1em; border-bottom: 1px solid #ccc;">
        Nro cuenta: {{ mc.cuentaNuevo.ctA_SGC }}<br/>
        Identificador: {{ mc.cuentaNuevo.descripcion }}<br/>
        Condicion venta: {{ utilClientes.obtenerDescripcionCondicionVenta(mc.cuentaNuevo.tipoCuenta) }}<br/>
        Moneda: {{ utilClientes.obtenerDescripcionMoneda(mc.cuentaNuevo.moneda) }}<br/>
        Tipo entrega de factura: {{ utilClientes.obtenerDescripcionTipoEntregaFactura(mc.cuentaNuevo.tipoEntregaFactura) }}<br/>
        Agrupación de remitos: {{ utilClientes.obtenerDescripcionAgrupacionRemitos(mc.cuentaNuevo.agrupacionRemitos) }}<br/>
        Periodo de facturación: {{ mc.cuentaNuevo.periodoFacturacion == null ? "-" : mc.cuentaNuevo.periodoFacturacion.descripcion }}<br/>
        Notificar por mail: {{ mc.cuentaNuevo.notificaPorEmail ? "Si" : "No" }}<br/>
        Mail: {{ mc.cuentaNuevo.email }}<br/>
        Plazo pago (días): {{ mc.cuentaNuevo.plazoPago }}<br/>
      </div>
    </td>
  </tr>
</table>