import { Component, OnInit } from '@angular/core';
import { NgbCalendar, NgbDatepickerConfig, NgbDateStruct, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { UsuarioService } from 'src/app/account/usuario.service';
import { CierreCaja } from 'src/app/shared/models/cierreTurno/CierreCaja';
import { CierreTurno } from 'src/app/shared/models/cierreTurno/CierreTurno';
import { ComprobanteService } from 'src/app/facturacion/comprobante.service';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { MessageService } from 'src/app/shell/message.service';
import { CierreTurnoService } from '../cierre-turno.service';
import { RetiroEfectivo } from 'src/app/shared/models/cierreTurno/RetiroEfectivo';
import { environment } from 'src/environments/environment';
import { RetiroEfectivoCierreTurnoComponent } from '../retiro-efectivo-cierre-turno/retiro-efectivo-cierre-turno.component';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-reporte-retiro-efectivo',
  templateUrl: './reporte-retiro-efectivo.component.html',
  styleUrls: ['./reporte-retiro-efectivo.component.scss']
})
export class ReporteRetiroEfectivoComponent implements OnInit {

  facturacionBaseURL = environment.apiServer.facturacionBaseUrl;

  model: NgbDateStruct;
  model1: NgbDateStruct;
  fechaDesde: Date;
  fechaHasta: Date;
  esperandoCarga: boolean = false;
  esperandoCargaRetiro: boolean = false;
  change$: Subscription;
  totalRetiro:number = 0;
  mensajeSpinner: string = "D e s c a r g a n d o";

  retirosCierre: RetiroEfectivo[] = [];
  retiroSeleccionado: RetiroEfectivo;

  constructor(private calendar: NgbCalendar,
    private datepickerConfig2: NgbDatepickerConfig,
    private spinner: NgxSpinnerService,
    private messageService: MessageService,
    private usuarioService: UsuarioService,
    private comprobanteService: ComprobanteService,
    sharedService: SharedService) {
      this.change$ = sharedService.aeroplantaSeleccionada.subscribe(_=>{ 
        this.cambiarDeAeroplanta()
      });
  } 
  


  ngOnInit(): void {
    this.mensajeSpinner = "C a r g a n d o . . .";
    this.model = this.calendar.getToday();
    this.model1 = this.calendar.getToday();
    let inicio: any = { day: 1, month: 1, year: 2019 };
    this.datepickerConfig2.minDate = inicio;
    let fin: any = { day: this.model.day, month: this.model.month, year: this.model.year };
    this.model1 = this.calendar.getToday();
    this.datepickerConfig2.maxDate = fin;
    setTimeout(() => {
      this.spinner.show('spinnerGrafico');
    });
    this.buscarDesdeHasta();    
  }

  cambiarDeAeroplanta() {
    this.ngOnInit();
  }

  ngOnChanges(){
    this.ngOnInit();
  }

  ngOnDestroy() {
    this.change$.unsubscribe();
  }

  formatDate(year, month, day, hour, minute): string {
    return year + '-' + month.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + '-' + day.toLocaleString(undefined, { minimumIntegerDigits: 2 })
      + 'T' + hour.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + minute.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':00';
  }

  buscarDesdeHasta() {
      this.retirosCierre = [];
      this.reinicioFechaInicioFechaFin();
      this.buscarCierresPorFecha(this.usuarioService.getAeroplantaActual().codigoAeroplanta, this.fechaDesde, this.fechaHasta);
  }

  reinicioFechaInicioFechaFin() {
    this.fechaDesde = new Date(this.model.year, this.model.month - 1, this.model.day);
    this.fechaHasta = new Date(this.model1.year, this.model1.month - 1, this.model1.day);
    this.fechaHasta.setHours(23, 59, 59, 99);
  }

  buscarCierresPorFecha(codigoAeroplanta: string, fechaInicio: Date, fechaFin: Date) {
    this.esperandoCarga = true;
    this.spinner.show('spinnerGrafico');
    let desde: string = this.formatDate(fechaInicio.getUTCFullYear(), fechaInicio.getMonth() + 1, fechaInicio.getDate(), 0, 0);
    let hasta: string = this.formatDate(fechaFin.getUTCFullYear(), fechaFin.getMonth() + 1, fechaFin.getDate(), 23, 59);
    this.comprobanteService.getRetiros(codigoAeroplanta, desde, hasta)
      .subscribe(result => {
        this.retirosCierre = result;
        this.spinner.hide('spinnerGrafico');
        this.esperandoCarga = false;
      }, (err: ErrorModel) => {
        this.messageService.showErrorMessage('Hubo un error al obtener los retiros');
        this.retirosCierre = [];
        this.spinner.hide('spinnerGrafico');
        this.esperandoCarga = false;
      });
  }
  
  mostrarDetalle(i: number) { 
    this.esperandoCargaRetiro = true; 
    this.spinner.show('spinnerLinea');
    this.retiroSeleccionado = this.retirosCierre[i]; 
    this.comprobanteService.getRetiroPorId(this.retiroSeleccionado.id)
      .subscribe(result => {
        this.retiroSeleccionado = result;
        this.totalRetiro=0;
        this.retiroSeleccionado.cierresCajas.forEach((value, index) => {
          this.totalRetiro += this.retiroSeleccionado.cierresCajas[index].totalEfectivoIngresado;
        });
        this.spinner.hide('spinnerLinea');
        this.esperandoCargaRetiro = false;        
      }, (err: ErrorModel) => {
        this.messageService.showErrorMessage('Hubo un error al obtener los cierres de caja');
        this.retiroSeleccionado = null; 
        this.spinner.hide('spinnerLinea');
        this.esperandoCargaRetiro = false;        
      });
      
  }

  /**
 * Convierte una NgbDateStruct y una NgbTimeStruct en un Date
 */
   dateFromDateTimeStruct(date: NgbDateStruct, hour: NgbTimeStruct): Date {
    if (date == null || hour == null)
      return null;
    let dateReturn = new Date();
    dateReturn.setDate(date.day);
    dateReturn.setMonth(date.month - 1);
    dateReturn.setFullYear(date.year);
    dateReturn.setHours(hour.hour);
    dateReturn.setMinutes(hour.minute);
    return dateReturn;
  }

  print(): void {
    var divToPrint = document.getElementById('invoicingPrint');
    var htmlToPrint = '' +
      '<style type="text/css">' +
      'table, table td, table th  {' +
      'border:1px solid #000;' +
      'text-align: center;' +
      'padding:0.5em;' +
      '}' +
      'button{' +
      'display: none;' +
      '}' +
      'label  {' +
      'margin-top: 0.5cm;' +
      'display: inline-block;' +
      '}' +
      '#noPrint {' +
      'display: none;' +
      '}' +
      '#tituloPrint {' +
      'display: block;' +
      '}'+
      '</style>';
    htmlToPrint += divToPrint.outerHTML;
    let newWin = window.open("");
    newWin.document.write(htmlToPrint);
    newWin.print();
    newWin.close();
  }

}
