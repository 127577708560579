<div class="card" id="islas-card">
  <div class="card-header">
    <h4 style="display: inline;" class="card-title">ABM de abastecedoras.</h4>
    <button *ngIf="this.hasRole(TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.RESPRODUCTO, TipoUsuario.TUTOR)" style="margin-left: 4em;" class="btn btn-primary" [disabled]="obteniendoAbastecedoras" type="button"
      (click)="agregarAbastecedora(abastecedoras)">Agregar nueva abastecedora</button>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="spinner-container" *ngIf="obteniendoAbastecedoras">    
        <ngx-spinner name="spinnerAbastecedora" bdOpacity=0.3bd
          Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
          <p style="color: white">{{messageAbastecedoras}}</p>
        </ngx-spinner>
      </div>
      <div *ngIf="!obteniendoAbastecedoras" class="col-md-12">
        <div *ngIf="this.abastecedoras===null || this.abastecedoras.length == 0" class="alert alert-warning">No hay
          abastecedoras para la aeroplanta seleccionada.</div>
        <table class="table table-striped table-hover" [formGroup]="tablaAbastecedora"
          *ngIf="this.abastecedoras.length > 0">
          <thead>
            <td class="text-center"><b>Código</b></td>
            <td class="text-center"><b>Nombre</b></td>
            <td class="text-center"><b>Patente</b></td>
            <td class="text-center"><b>Producto</b></td>
            <td class="text-center"><b>Aforadores</b></td>
            <td class="text-center"><b>Aforador actual</b></td>
            <td class="text-center"><b>Capacidad</b></td>
            <td class="text-center"><b>Activa</b></td>
            <td class="text-center"><b>Editar</b></td>
          </thead>
          <tbody>
            <tr formArrayName="abastecedoras" *ngFor="let ab of abastecedoras; let i = index">
              <td class="text-center">{{ abastecedoras[i].codigo }}</td>
              <td class="text-center">{{ abastecedoras[i].nombre }}</td>
              <td class="text-center">{{ abastecedoras[i].patente }}</td>
              <td class="text-center">{{ abastecedoras[i].productoActual.codigoProducto }} -
                {{ abastecedoras[i].productoActual.nombreProducto }}</td>
              <td class="text-center">
                <button class="btn" (click)="verPicos(ab)" placement="bottom" ngbTooltip="Ver aforadores relacionados"
                  container="body">
                  <i class="fa fa-eye" aria-hidden="true"></i>
                </button>
              </td>
              <td class="text-center" *ngIf="abastecedoras[i].picos.length == 0">No hay aforadores asignados</td>
              <td class="text-center" *ngIf="abastecedoras[i].picos.length == 1">{{abastecedoras[i].picos[0].aforadorActual}}</td>
              <td class="text-center" *ngIf="abastecedoras[i].picos.length > 1">Consultar aforadores</td>
              <td class="text-center">{{ abastecedoras[i].capacidad }}</td>
              <td class="text-center">
                <div *ngIf="this.hasRole(TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.RESPRODUCTO, TipoUsuario.TUTOR)" class="form-check form-check-inline form-switch">
                  <input type="checkbox" [formControlName]="i" class="form-check-input" id="{{abastecedoras[i].id}}"
                    (click)="actualizarAbastecedora(abastecedoras[i])">
                  <label class="form-check-label" for="{{abastecedoras[i].id}}"></label>
                </div>
                <div *ngIf="!this.hasRole(TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.RESPRODUCTO, TipoUsuario.TUTOR)" class="form-check form-check-inline form-switch">
                  {{abastecedoras[i].activo ? "Si" : "No"}}
                </div> 
              </td>
              <td class="text-center">
                <button *ngIf="this.hasRole(TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.RESPRODUCTO, TipoUsuario.TUTOR)" type="button" (click)="editarAbastecedora(abastecedoras[i])"
                  class="btn btn-primary btn-sm me-1"><i class="fa fa-pencil fa-lg" aria-hidden="true"
                    ngbTooltip="Editar"></i></button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
