<ul class="nav nav-tabs justify-content-start">
  <li class="nav-item" *ngIf="hasRole(TipoUsuario.MANDATARIO, TipoUsuario.PRECIOS, TipoUsuario.APROBADORPRECIOS, TipoUsuario.SEGURIDADTABLAS, TipoUsuario.APROBADORBYR, TipoUsuario.COMERCIAL, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.COMERCIAL_LN, TipoUsuario.APROBADORBYR, TipoUsuario.COMERCIAL_AV, TipoUsuario.FACTURACION, TipoUsuario.REFACTURACION, TipoUsuario.CONSPRECIOS, TipoUsuario.SOPORTE )"><a class="nav-link" routerLink="vigenciasListados" routerLinkActive="active">Vigentes y Reportes</a></li>
  
  <li class="nav-item" *ngIf="cargandoNiveles" style="font-size: 16px; font-weight: 400; line-height: 24px; padding-left: 16px; padding-top: 8px;">
    <loading-text-spinner message="Cargando información ..." color="primary"></loading-text-spinner>
  </li>
  
  <li class="nav-item" *ngIf="!cargandoNiveles && hasRole(TipoUsuario.APROBADORPRECIOS, TipoUsuario.PRECIOS)"><a class="nav-link" routerLink="lotes" routerLinkActive="active">Gestion Lotes</a></li>
  <li class="nav-item" *ngIf="!cargandoNiveles && hasRole(TipoUsuario.PRECIOS)"><a class="nav-link" routerLink="gestionPrecios" routerLinkActive="active">Gestión de precios</a></li>
  <li class="nav-item" *ngIf="!cargandoNiveles && hasRole(TipoUsuario.APROBADORPRECIOS)"><a class="nav-link" routerLink="aprobarModificacion" routerLinkActive="active">Aprobar modificacion</a></li>  
  <li class="nav-item" *ngIf="!cargandoNiveles && hasRole(TipoUsuario.APROBADORPRECIOS)"><a class="nav-link" routerLink="aprobacionDesactivacion" routerLinkActive="active">Aprobacion Desactivación</a></li>
  <li class="nav-item" *ngIf="!cargandoNiveles && hasRole(TipoUsuario.APROBADORPRECIOS, TipoUsuario.PRECIOS, TipoUsuario.COMERCIAL, TipoUsuario.APROBADORBYR, TipoUsuario.COMERCIAL_LN, TipoUsuario.COMERCIAL_AV)"><a class="nav-link" routerLink="historialDesactivacion" routerLinkActive="active">Historial Desactivación</a></li>
  <li class="nav-item" *ngIf="!cargandoNiveles && hasRole(TipoUsuario.SEGURIDADTABLAS)"><a class="nav-link" routerLink="nivelesAprobacionPrecios" [queryParams]="{tipoNivel:0}" routerLinkActive="active">Niveles PRECIOS</a></li>
  <li class="nav-item" *ngIf="!cargandoNiveles && hasRole(TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_LN, TipoUsuario.APROBADORBYR, TipoUsuario.COMERCIAL_AV)"><a class="nav-link" routerLink="listadoByR" routerLinkActive="active">ByR</a></li>
  <li class="nav-item" *ngIf="!cargandoNiveles && hasRole(TipoUsuario.APROBADORBYR)"><a class="nav-link" routerLink="aprobarByRProductos" routerLinkActive="active">Aprobar ByR Productos</a></li>
  <li class="nav-item" *ngIf="!cargandoNiveles && hasRole(TipoUsuario.APROBADORBYR)"><a class="nav-link" routerLink="aprobarByRExpendedora" [queryParams]="{tipoBonificacionRecargo:1}" routerLinkActive="active">Aprobar ByR Surtidores</a></li>
  <li class="nav-item" *ngIf="!cargandoNiveles && hasRole(TipoUsuario.SEGURIDADTABLAS)"><a class="nav-link" routerLink="nivelesAprobacionByr" [queryParams]="{tipoNivel:1}" routerLinkActive="active">Niveles COMERCIAL</a></li>
  <li class="nav-item" *ngIf="!cargandoNiveles && hasRole(TipoUsuario.APROBADORPRECIOS, TipoUsuario.PRECIOS, TipoUsuario.SEGURIDADTABLAS, TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_LN, TipoUsuario.COMERCIAL_AV, TipoUsuario.APROBADORBYR)"><a class="nav-link" routerLink="reporteNiveles" routerLinkActive="active">Reporte niveles</a></li>
  <li class="nav-item" *ngIf="!cargandoNiveles && hasRole(TipoUsuario.SUPERVISOR, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.OPERADOR)"><a class="nav-link" routerLink="simuladorPrecio" routerLinkActive="active">Precios Actuales</a></li>
  
  <!-- se ocullta notificaciones -->
  <!-- <li class="nav-item"><a class="nav-link" routerLink="notificaciones" routerLinkActive="active"> Notificaciones</a></li> -->
</ul>
<router-outlet></router-outlet>
