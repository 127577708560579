import { Notificacion } from "./Notificacion";

export class NotificacionesCliente
    {
        id:string;
        numeroDocumento:string;
        tipoDocumento:number;
        razonSocial:string;
        notificaciones:Notificacion[];
        email:string;
    }
