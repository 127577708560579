<ngx-spinner bdColor="rgba(19,123,198,0.7)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">
    <br />
    Aguarde a que se procese el aerovale y sera redirigido automáticamente.
    <br />
    No salga de esta pagina.
  </p>
</ngx-spinner>

<div class="card add-margin">
  <div class="card-header">
    <div class="row">
      <div class="col-sm-10">
        <h4 class="card-title ">{{ this.pedidoDevolucion ? 'Devolucion de alije' : 'Nuevo Aerovale' }}</h4>
      </div>
      <div class="col-sm-2">
        <button class="btn btn-secondary float-end" (click)="cancelar()">Volver</button>
      </div>
    </div>
  </div>
  <div class="card-body">
    <form [formGroup]="aerovalesForm">
      <!--GRANEL-->
      <div class="row"> <!--*ngIf="this.pedidoDevolucion"-->
        <div class="mb-3 col-md-2"
          style="padding-top: 0.4em; padding-bottom: 0.2em; border: 2px solid #999; padding-left: 1em;">
          <div class="form-check">
            <input type="checkbox" (change)="usuarioCambioEsGranel(esAerovaleGranel)" [(ngModel)]="esAerovaleGranel"
              class="form-check-input" id="granelCheckbox" formControlName="granelFormControl" />
            <label class="form-check-label" for="granelCheckbox" style="font-weight: bold; padding-left: 1em;">Es
              Granel</label>
          </div>
        </div>
      </div>

      <div class="row">
        <!--OPERADOR-->
        <div class="mb-3 col-md-6">
          <div class="mb-3 row">
            <div class="col-md-4">
              <label for="operadorFormControl" class="col-form-label"> <span
                  *ngIf="esCampoVacio(this.operadorFormControl.value)">*</span>Operadores:</label>
            </div>
            <div class="col-md-8">
              <div class="input-group mb-2">
                <input id="operadorFormControl" type="text" class="form-control" formControlName="operadorFormControl"
                  [inputFormatter]="formatterOperador" [resultFormatter]="formatterOperador" [(ngModel)]="modelOperador"
                  title="Campo obligatorio, debe seleccionar o ingresar un operador."
                  (selectItem)="selectOperador($event)" [ngbTypeahead]="searchOperador" />
              </div>
              <loading-text-spinner *ngIf="cargandoOperadores" [error]="errorOperadores"
                message="Obteniendo operadores..." color="primary">
              </loading-text-spinner>
              <div
                *ngIf="operadorFormControl.invalid && operadorFormControl.errors.required && (operadorFormControl.dirty || operadorFormControl.touched)"
                class="alert alert-danger">
                Requerido
              </div>
            </div>
          </div>
        </div>
        <!--NUMERO AEROVALE-->
        <div class="mb-3 col-md-6">
          <div class="mb-3 row">
            <div class="col-md-4">
              <label for="numeroAerovaleControlInput" class="col-form-label"><span
                  *ngIf="esCampoVacio(this.numeroAerovale.value)">*</span>Número de Aerovale:</label>
            </div>
            <div class="col-md-8">
              <div class="input-group mb-2">
                <input id="numeroAerovaleFormControl" (keypress)="onlyNumberKey($event)" type="text" maxlength="8"
                  class="form-control mb-2 mr-sm-2" title="Campo obligatorio, debe ingresar un número de aerovale."
                  formControlName="numeroAerovaleFormControl" placeholder="Número aerovale" />
              </div>
              <div
                *ngIf="numeroAerovale.invalid && numeroAerovale.errors.required && (numeroAerovale.dirty || numeroAerovale.touched) "
                class="alert alert-danger">
                Requerido
              </div>
              <div class="alert alert-danger"
                *ngIf="numeroAerovale.invalid && (numeroAerovale.errors.nroAerovaleFueraDeRango) && (numeroAerovale.dirty || numeroAerovale.touched)">
                La numeración de aerovales de contingencia debe ser inferior al número 19999999
              </div>
            </div>
          </div>
        </div>
        <!--ABASTECEDORA-->
        <div class="mb-3 col-md-6">
          <div class="mb-3 row">
            <div class="col-md-4">
              <label for="abastecedoraFormControl" class="col-form-label"> <span
                  *ngIf="esCampoVacio(this.abastecedora.value)">*</span>Abastecedora:</label>
            </div>
            <div class="col-md-8">
              <div class="input-group mb-2">
                <select id="abastecedoraFormControl" class="form-select" title="Seleccione una abastecedora."
                  formControlName="abastecedoraFormControl" (change)="buscarTanques_y_Picos()">
                  <option *ngFor="let abastecedora of abastecedoras" [ngValue]="abastecedora"> {{abastecedora.nombre}}
                  </option>
                </select>
              </div>
              <loading-text-spinner *ngIf="cargandoAbastecedoras" [error]="errorOperadores"
                message="Obteniendo abastecedoras..." color="primary">
              </loading-text-spinner>
              <div class="alert alert-danger"
                *ngIf="(this.abastecedora.invalid && this.abastecedora.errors.required && (this.abastecedora.dirty || this.abastecedora.touched))">
                Debe seleccionar una opcion de abastecedora
              </div>
            </div>
          </div>
        </div>
        <!--AEROCOMBUSTIBLE-->
        <div class="mb-3 col-md-6">
          <div class="mb-3 row">
            <div class="col-md-4">
              <label for="aerocombustibleControlInput" class="col-form-label">
                <p> Aerocombustible: </p>
              </label>
            </div>
            <div class="col-md-8">
              <div class="input-group mb-2"
                *ngIf="this.abastecedora.value != null && this.abastecedora.value.producto!=null">
                <input id="aerocombustibleFormControl" class="form-control mb-2"
                  formControlName="aerocombustibleFormControl"
                  value="{{this.abastecedora.value.producto.nombreProducto}}" readonly>
              </div>
            </div>
          </div>
        </div>

        <!--TANQUE SELECCIONADO-->
        <div class="mb-3 col-md-12" *ngIf="this.abastecedora.value.tipoExpendedora == TipoExpendedora.SURTIDOR">
          <div class="mb-3 row">
            <div class="col-md-2">
              <label for="tanquesAsociadosFormControl" class="col-form-label">Tanque de extracción:</label>
            </div>
            <div class="col-md-4">
              <div class="input-group mb-2">
                <select id="tanquesAsociadosFormControl" class="form-select" title="Seleccione un tanque."
                  *ngIf="surtidores != null" formControlName="tanquesAsociadosFormControl">
                  <option *ngFor="let tanques of surtidores.tanques" [ngValue]="tanques.codigo">{{ tanques.nombre }}
                  </option>
                </select>
              </div>
              <loading-text-spinner *ngIf="obteniendoTanquesSurtidor" [error]="errorOperadores"
                message="Obteniendo tanques asociados..." color="primary">
              </loading-text-spinner>
              <div class="alert alert-warning" *ngIf="!obteniendoTanquesSurtidor && surtidores.tanques.length == 0">
                Se debe asignar al menos un tanque al surtidor para poder realizar el despacho. Avise a su supervisor.
              </div>
              <div
                *ngIf="tanqueAsociado.invalid && tanqueAsociado.errors.required &&(tanqueAsociado.dirty || tanqueAsociado.touched)"
                class="alert alert-danger">
                Requerido
              </div>
            </div>
          </div>
        </div>
        <div class="mb-3 col-md-12" *ngIf="this.abastecedora.value.tipoExpendedora != TipoExpendedora.SURTIDOR"></div>

        <!--PICO SELECCIONADO-->
        <div class="mb-3 col-md-6" *ngIf="this.abastecedora.value!= null">
          <div class="mb-3 row">
            <div class="col-md-4">
              <label for="picosAsociadosFormControl" class="col-form-label">Aforador utilizado:</label>
            </div>
            <div class="col-md-8">
              <div class="input-group mb-2">
                <select id="picosAsociadosFormControl" class="form-select" title="Seleccione un aforador."
                  formControlName="picosAsociadosFormControl" (click)="usuarioCambioComboPico($event)">
                  <option *ngFor="let pico of this.abastecedora.value.picos" [ngValue]="pico">
                    {{ pico.nombre }}
                  </option>
                </select>
              </div>
              <div class="alert alert-warning"
                *ngIf="this.abastecedora.value.picos != null && this.abastecedora.value.picos.length == 0">
                La abastecedora no tiene aforador y se debe asignar al menos un aforador para poder realizar el
                despacho. Avise a su supervisor.
              </div>
              <div
                *ngIf="picosAsociadosFormControl.invalid && picosAsociadosFormControl.errors.required &&(picosAsociadosFormControl.dirty || picosAsociadosFormControl.touched)"
                class="alert alert-danger">
                Requerido
              </div>
            </div>
          </div>
        </div>

        <!--TIPO PICO-->
        <div class="mb-3 col-md-6" *ngIf="this.abastecedora.value!= null">
          <div class="mb-3 row">
            <label class="col-form-label col-sm-4">Tipo de pico: </label>
            <section class="btn-group col-sm-8">
              <input type="radio" class="btn-check" formControlName="tipoPicoFormControl" name="tipoPicoFormControl"
                id="tpg" [value]="1">
              <label class="btn btn-outline-primary" for="tpg">Pico gatillo</label>
              <input type="radio" class="btn-check" formControlName="tipoPicoFormControl" name="tipoPicoFormControl"
                id="tpc" [value]="0">
              <label class="btn btn-outline-primary" for="tpc">Pico carter</label>
            </section>
          </div>
        </div>

        <!-- CLINTE -->
        <div class="mb-3 col-md-6">
          <div class="mb-3 row">
            <div class="col-md-4">
              <label for="clienteFormControl" class="col-form-label"><span
                  *ngIf="esCampoVacio(this.cliente.value)">*</span>Cliente:</label>
            </div>
            <div class="col-md-8">
              <div class="input-group mb-2">
                <input id="clienteFormControl" type="text" class="form-control" formControlName="clienteFormControl"
                  [inputFormatter]="formatter" [(ngModel)]="modelCliente" [resultFormatter]="formatter"
                  title="Campo obligatorio, debe seleccionar o ingresar un cliente." (change)="confirmCustomer()"
                  [ngbTypeahead]="search" (selectItem)="selectCustomer($event)" [readonly]="this.clienteNoEditable" />
                <div class="input-group-append">
                  <button type="button" class="input-group-btn btn btn-secondary" (click)="nuevoCliente(modelCliente)"
                    tabindex="-1" [disabled]="this.clienteNoEditable">
                    <i class="fa fa-plus-square" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
              <loading-text-spinner *ngIf="cargandoClientes" [error]="errorClientes" message="Obteniendo clientes..."
                color="primary">
              </loading-text-spinner>
              <div *ngIf="cliente.invalid && cliente.errors.required &&(cliente.dirty || cliente.touched)"
                class="alert alert-danger">
                Requerido
              </div>
            </div>
          </div>
        </div>
        <!--AERONAVE-->
        <div [ngClass]="{'elementoNoVisible': this.esAerovaleGranel}" class="mb-3 col-md-6">
          <div class="mb-3 row">
            <div class="col-md-4">
              <label for="aeronaveFormControl" class="col-form-label"><span
                  *ngIf="esCampoVacio(this.numeroAerovale.value)">*</span>Aeronave:</label>
            </div>
            <div class="col-md-8">
              <div class="input-group mb-2">
                <ng-template #aeronaveRt let-r="result" let-t="term">
                  <ngb-highlight [result]="r.matricula" [term]="t"></ngb-highlight>
                </ng-template>
                <input id="aeronaveFormControl" type="text" class="form-control" formControlName="aeronaveFormControl"
                  [resultTemplate]="aeronaveRt" [inputFormatter]="formatterAeronave" maxlength="10"
                  title="Campo obligatorio, debe seleccionar una aeronave." [ngbTypeahead]="searchAeronave"
                  (change)="changeAeronave()" (selectItem)="selectAeronave($event)" />
                <div class="input-group-append">
                  <button type="button" class="input-group-btn btn btn-secondary" (click)="nuevoAeronave(modelAeronave)"
                    tabindex="-1">
                    <i class="fa fa-plus-square" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
              <loading-text-spinner *ngIf="cargandoAeronavesCliente" [error]="errorAeronavesCliente"
                message="Obteniendo aeronaves..." color="primary">
              </loading-text-spinner>
              <div class="alert alert-danger"
                *ngIf="aeronave.invalid && aeronave.errors.required && (aeronave.dirty || aeronave.touched)">
                Requerido
              </div>
              <div class="alert alert-warning" *ngIf="this.combustibleNoCoincide">
                La abastecedora y el vehiculo tienen distinto producto.
              </div>
            </div>
          </div>
        </div>
        <!--TIPO DE PAGO-->
        <div class="col-md-6">
          <div class="mb-3 row">
            <div class="col-md-4">
              <label for="tipoPagoControlInput" class="col-form-label">Tipo de pago:</label>
            </div>
            <div class="col-md-8">
              <div id="tipoPagoFormControl" class="input-group mb-2" *ngIf="this.cliente.value != null">
                <select id="tipoPagoControlSelect" type="text" class="form-select" name="tipoPagoID"
                  formControlName="tipoPagoFormControl" title="Seleccione un tipo de movimiento. Campo requerido.">
                  <option id="optionTipoPago" *ngFor="
                  let tipo of obtenerTipoDePago(this.cliente.value.tipoCuenta)" [ngValue]="tipoPago[tipo]">
                    {{ tipo }}
                  </option>
                </select>
              </div>
              <div class="input-group mb-2" *ngIf="this.cliente.value == null">
                <input class="form-control" readonly>
              </div>
            </div>
          </div>
        </div>
        <!--ESPACIO-->
        <div class="col-md-6">
        </div>
        <!--Tipo de Vuelo-->
        <div [ngClass]="{'elementoOculto': this.esAerovaleGranel}" class="mb-3 col-md-6">
          <div class="mb-3 row">
            <label class="col-form-label col-sm-4">Tipo de vuelo: </label>
            <section class="btn-group col-sm-8" (change)="vaciarDestino()">
              <input type="radio" class="btn-check" name="tipoVueloFormControl" id="tvc"
                formControlName="tipoVueloFormControl" [value]="1">
              <label class="btn btn-outline-primary" for="tvc">Cabotaje</label>
              <input type="radio" class="btn-check" name="tipoVueloFormControl" id="tvi"
                formControlName="tipoVueloFormControl" [value]="2">
              <label class="btn btn-outline-primary" for="tvi">Internacional</label>
            </section>
          </div>
        </div>
        <!--NUMERO DE VUELO-->
        <div [ngClass]="{'elementoOculto': this.esAerovaleGranel}" class="mb-3 col-md-6">
          <div class="mb-3 row">
            <div class="col-md-4">
              <label for="vueloControlInput" class="col-form-label"> <span
                  *ngIf="esCampoVacio(this.numeroVuelo.value)">*</span>Número de Vuelo:</label>
            </div>
            <div class="col-md-8">
              <div class="input-group mb-2">
                <input id="vueloControlInput" type="text" class="form-control mb-2 mr-sm-2"
                  title="Campo obligatorio, debe ingresar un número de vuelo." formControlName="numeroVueloFormControl"
                  placeholder="XY ####" ngbTooltip="Permite FFAA, SANI, PRIV, YPF, GOB, MANT."
                  style="text-transform:uppercase" />
              </div>
              <div
                *ngIf="numeroVuelo.invalid && numeroVuelo.errors.required && (numeroVuelo.dirty || numeroVuelo.touched)"
                class="alert alert-danger">
                Requerido
              </div>
              <div
                *ngIf="numeroVuelo.invalid && numeroVuelo.errors.pattern && (numeroVuelo.dirty || numeroVuelo.touched)"
                class="alert alert-danger">
                El número de vuelo no tiene el formato correcto
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="false" class="mb-3 col-md-6">

        </div>
        <!--DESTINO-->
        <div [ngClass]="{'elementoOculto': this.esAerovaleGranel}" class="mb-3 col-md-6">
          <div class="mb-3 row">
            <div class="col-md-4">
              <label class="col-form-label"> <span
                  *ngIf="esCampoVacio(this.destino.value) && this.tipoVuelo.value==TipoVuelo.Internacional">*</span>Destino:</label>
            </div>
            <div class="col-md-8">
              <div class="input-group mb-2">
                <ng-template #aeropuertoRt let-r="result" let-t="term">
                  <ngb-highlight [result]="r.codigoAeropuerto + ' ' + r.nombre" [term]="t"></ngb-highlight>
                </ng-template>
                <input id="destinoFormControl" type="text" class="form-control" formControlName="destinoFormControl"
                  [resultTemplate]="aeropuertoRt" [inputFormatter]="formatterAeropuerto"
                  [ngbTypeahead]="searchAeropuerto" placeholder="Destino" (selectItem)="selectAeropuerto($event)"
                  (change)="changeDestino()" (keyup)="verificarRancho()" />
              </div>
              <loading-text-spinner *ngIf="cargandoAeropuertos" [error]="errorDestinos" message="Obteniendo destinos..."
                color="primary">
              </loading-text-spinner>
              <div *ngIf="this.destinovalid  && (destino.dirty || destino.touched)" class="alert alert-danger">Debe
                seleccionar un destino de la lista.</div>
            </div>
          </div>
        </div>
        <!--RANCHO-->
        <div [ngClass]="{'elementoOculto': this.esAerovaleGranel}" class="mb-3 col-md-6">
          <div class="mb-3-row">
            <label *ngIf="tipoVuelo.value==2" for="numeroRanchoControlInput" class="col-form-label">
              <div *ngIf="this.rancho!=null" class="alert alert-success">Numero de rancho: {{this.rancho.codigoRancho}}
              </div>
              <div *ngIf="this.rancho==null" class="alert alert-warning">Numero de rancho: No tiene ranchos</div>
            </label>
            <label *ngIf="tipoVuelo.value==1" for="numeroRanchoControlInput" class="col-form-label">
              <div class="alert alert-warning">Numero de rancho: Cabotaje no admite ranchos</div>
            </label>
          </div>
        </div>
        <!--LLEGADA ABASTECEDORA-->
        <div class="mb-3 col-md-6">
          <div class="mb-3 row">
            <div class="col-md-4">
              <label for="fechaLlegadaFormControl" class="col-form-label">
                Llegada de abastecedora:</label>
            </div>
            <div #fechaHora class="col-md-8">
              <div class="input-group mb-2">
                <input type="date" class="form-control" formControlName="fechaLlegadaFormControl"
                  placeholder="aaaa-mm-dd" name="dp" title="Campo obligatorio." [(ngModel)]="modelLlegada" ngbDatepicker
                  #l="ngbDatepicker" readonly />
                <div class="input-group-append">
                  <button type="button" class="input-group-btn btn btn-secondary" (click)="l.toggle()">
                    <i class="fa fa-calendar" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
              <ngb-timepicker [(ngModel)]="timeVuelo" [meridian]="true" formControlName="horaLlegadaFormControl"
                [readonlyInputs]="true">
              </ngb-timepicker>
              <div class="alert alert-danger"
                *ngIf="aerovalesForm.invalid && this.aerovalesForm.hasError('fechaLlegadaMayorAhora')">
                Esta cargando una fecha de llegada en el futuro.
              </div>
            </div>
          </div>
        </div>
        <!--ESPACIO-->
        <div class="col-md-6">
        </div>
        <!--AFORADOR INICIAL-->
        <div class="mb-3 col-md-6">
          <div class="mb-3 row">
            <div class="col-md-4">
              <label for="aerocombustibleControlSelect" class="col-form-label">Aforador Inicial</label>
            </div>
            <div class="col-md-8">
              <div class="input-group mb-2">
                <input id="aforadorInicialControlInput" type="number" class="form-control mb-2 mr-sm-2"
                  title="Campo obligatorio, debe ingresar el aforador inicial." min="0"
                  formControlName="aforadorInicialFormControl" placeholder="####" (change)="obtenerVolumenDespachado()"
                  (keypress)="this.onlyNumberKey($event)" />
              </div>
              <div class="alert-danger"
                *ngIf="this.aforadorInicial.invalid && this.aforadorInicial.errors.required && (this.aforadorInicial.dirty || this.aforadorInicial.touched)">
                Debe completar el campo aforador inicial.
              </div>
            </div>
          </div>
        </div>
        <!--AFORADOR FINAL-->
        <div class="mb-3 col-md-6">
          <div class="mb-3 row">
            <div class="col-md-4">
              <label for="aforadorFinalControlInput" class="col-form-label">Aforador Final:</label>
            </div>
            <div class="col-md-8">
              <div class="input-group mb-2">
                <input id="aforadorFinalControlInput" type="number" class="form-control mb-2 mr-sm-2" min="0"
                  title="Campo obligatorio, debe ingresar el aforador final." formControlName="aforadorFinalFormControl"
                  placeholder="####" (change)="obtenerVolumenDespachado()" (keypress)="this.onlyNumberKey($event)" />
              </div>
              <div class="alert alert-danger"
                *ngIf="this.aforadorFinal.invalid && this.aforadorFinal.errors.required && (this.aforadorFinal.dirty || this.aforadorFinal.touched)">
                Debe completar el campo aforador final.
              </div>
              <div class="alert alert-danger"
                *ngIf="aerovalesForm.invalid && this.aerovalesForm.hasError('aforadorInicialMayorAforadorFinal') && (aforadorInicial.dirty || aforadorInicial.touched) && (aforadorFinal.dirty || aforadorFinal.touched)">
                El aforador final debe ser mayor que el aforador inicial.
              </div>
            </div>
          </div>
        </div>
        <div class="mb-3 col-md-12" *ngIf="this.tipoPicoFormControl.value == 0">
          <div class="mb-3 row">
            <!--PRESION PUNTA MANGUERA-->
            <div class="mb-3 col-md-4">
              <div class="mb-3 row">
                <div class="col-md-6">
                  <label for="presionMangueraFormControl" class="col-form-label">Presión punta manguera:</label>
                </div>
                <div class="col-md-6">
                  <div class="input-group mb-2">
                    <input id="presionMangueraFormControl" type="number" class="form-control"
                      (keypress)="this.onlyDecimalPositivo($event)"
                      title="Campo obligatorio, debe ingresar la presion en punta de manguera."
                      formControlName="presionMangueraFormControl" placeholder="####" />
                    <div class="input-group-append">
                      <div class="input-group-text">Psi</div>
                    </div>
                  </div>
                  <div class="alert alert-danger"
                    *ngIf="this.presionManguera.invalid && this.presionManguera.errors.required && (this.presionManguera.dirty || this.presionManguera.touched)">
                    Debe completar la presión en punta de manguera.
                  </div>
                  <div class="alert alert-danger"
                    *ngIf="this.presionManguera.value < 0 || this.presionManguera.value > 50">
                    La presión de punta de manguera debe estar en el rango de 0 a 50 Psi.
                  </div>
                  <div class="alert alert-warning"
                    *ngIf="this.presionManguera.value >= 40 && this.presionManguera.value <= 50">
                    La UUAA debe ir a mantenimiento y permanecer fuera de servicio.
                  </div>
                </div>
              </div>
            </div>
            <!--PRESION DIFERENCIAL-->
            <div class="mb-3 col-md-4">
              <div class="mb-3 row">
                <div class="col-md-6">
                  <label for="presionDiferencialFormControl" class="col-form-label">Presión diferencial:</label>
                </div>
                <div class="col-md-6">
                  <div class="input-group mb-2">
                    <input id="presionDiferencialFormControl" type="number" class="form-control"
                      (keypress)="this.onlyDecimalPositivo($event)"
                      title="Campo obligatorio, debe ingresar la presion diferencial con un rango entre 0 y 15 Psi ."
                      formControlName="presionDiferencialFormControl" placeholder="####" />
                    <div class="input-group-append">
                      <div class="input-group-text">Psi</div>
                    </div>
                  </div>
                  <div class="alert alert-danger"
                    *ngIf="this.presionManguera.invalid && this.presionManguera.errors.required && (this.presionManguera.dirty || this.presionManguera.touched)">
                    Debe completar la presión diferencial.
                  </div>
                  <div class="alert alert-danger"
                    *ngIf="this.presionDiferencial.value < 0 || this.presionDiferencial.value > 15">
                    La presión diferencial debe estar en el rango de 0 a 15 Psi.
                  </div>
                </div>
              </div>
            </div>
            <!--CAUDAL-->
            <div class="mb-3 col-md-4">
              <div class="mb-3 row">
                <div class="col-md-6">
                  <label for="caudalFormControl" class="col-form-label">Caudal observado:</label>
                </div>
                <div class="col-md-6">
                  <div class="input-group mb-2">
                    <input id="caudalFormControl" type="number" class="form-control"
                      (keypress)="this.onlyDecimalPositivo($event)"
                      title="Campo obligatorio, debe ingresar el caudal observado en un rango de 0 a 5000 Lts./Min."
                      formControlName="caudalFormControl" placeholder="####" />
                    <div class="input-group-append">
                      <div class="input-group-text">L/Min</div>
                    </div>
                  </div>
                  <div class="alert alert-danger"
                    *ngIf="this.caudal.invalid && this.caudal.errors.required && (this.caudal.dirty || this.caudal.touched)">
                    Debe completar el caudal observado.
                  </div>
                  <div class="alert alert-danger" *ngIf="this.caudal.value < 0 || this.caudal.value >5000">
                    El caudal debe de estar en el rango de 0 a 5000 Lts/Min.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--TEMPERATURA-->
        <div class="mb-3 col-md-4">
          <div class="mb-3 row">
            <div class="col-md-4">
              <label for="cantidadEstimadaControlInput" class="col-form-label"><span
                  *ngIf="esCampoVacio(this.temperaturaEnGrados.value)">*</span>Temperatura(C):</label>
            </div>
            <div class="col-md-8">
              <div class="input-group mb-2">
                <input id="cantidadEstimadaControlInput" type="number" class="form-control" title="Campo obligatorio."
                  formControlName="temperaturaFormControl" placeholder="grados" (change)="litrosA15Grados()" />
                <div class="input-group-append">
                  <div class="input-group-text">Grados</div>
                </div>
              </div>
              <div class="alert alert-danger"
                *ngIf="(this.temperaturaEnGrados.invalid && this.temperaturaEnGrados.errors.required && (this.temperaturaEnGrados.dirty || this.temperaturaEnGrados.touched))">
                Requerido
              </div>
              <div class="alert alert-danger"
                *ngIf="temperaturaEnGrados.invalid && (temperaturaEnGrados.errors.temperaturaFueraDeRango) && (temperaturaEnGrados.dirty || temperaturaEnGrados.touched)">
                La temperatura esta fuera de rango permitido.
              </div>
            </div>
          </div>
        </div>
        <!--DENSIDAD-->
        <div class="mb-3 col-md-4">
          <div class="mb-3 row">
            <div class="col-md-4">
              <label for="densidadControlInput" class="col-form-label"> <span
                  *ngIf="esCampoVacio(this.densidad.value)">*</span>Densidad:</label>
            </div>
            <div class="col-md-8">
              <div class="input-group mb-2">
                <input id="densidadFormControl" class="form-control" (keypress)="formatDensidad($event)"
                  title="Campo obligatorio, debe ingresar un valor mayor a 0" formControlName="densidadFormControl"
                  triggers="input:blur" (blur)="litrosA15Grados()" />
                <div class="input-group-append">
                  <div class="input-group-text">Gr/cm</div>
                </div>
              </div>
              <div class="alert alert-danger"
                *ngIf="(this.densidad.invalid && this.densidad.errors.required && (this.densidad.dirty || this.densidad.touched))">
                Requerido
              </div>
              <div class="alert alert-danger"
                *ngIf="(this.densidad.dirty || this.densidad.touched) && !this.validarDensidad(this.densidad.value)">
                La densidad no está en el rango del producto seleccionado. [{{this.esDensidadMin}} -
                {{this.esDensidadMax}}]
              </div>
            </div>
          </div>
        </div>
        <!--PRUEBA DE AGUA-->
        <div class="mb-3 col-md-4">
          <div class="mb-3 row">
            <div class="col-md-4">
              <label class="col-form-label">Prueba de agua: </label>
            </div>
            <section class="col-md-8">
              <div class="btn-group btn-group-toggle col-md-12" ngbRadioGroup name="radioPruebaAgua"
                ngbTooltip="Recuerde controlar la prueba de agua, debe ser negativa para la creacion del aerovale">
                <input ngbButton type="radio" class="btn-check"  [value]="enumResultadoPruebaDeAgua.Positiva"
                    formControlName="pruebaDeAguaFormControl" id="pap" />
                <label ngbButtonLabel class="btn-outline-primary btn col-md-6" for="pap">
                  Positiva
                </label>
                <input ngbButton type="radio" class="btn-check" [value]="enumResultadoPruebaDeAgua.Negativa"
                    formControlName="pruebaDeAguaFormControl" id="pan" />
                <label ngbButtonLabel class="btn-outline-primary btn col-md-6" for="pan">
                  Negativa
                </label>
              </div>
              <div class="alert alert-danger"
                *ngIf="pruebaDeAgua.invalid && (pruebaDeAgua.errors.required || pruebaDeAgua.errors.negativoRequired)">
                El resultado de la prueba de agua debe ser Negativo para poder continuar.
              </div>
            </section>
          </div>
        </div>
        <!--VOLUMEN DESPACHADO-->
        <div class="mb-3 col-md-4">
          <div class="mb-3 row">
            <div class="col-md-6">
              <label for="volumenDespachadoControlInput" class="col-form-label">Volumen despachado:</label>
            </div>
            <div class="col-md-6">
              <div class="input-group mb-2">
                <input id="volumenDespachadoControlInput" type="text" class="form-control"
                  formControlName="volumenDespachadoFormControl" placeholder="####" readonly />
              </div>
              <div class="alert alert-danger"
                *ngIf="this.pedidoDevolucion ? this.aforadorFinal.value - this.aforadorInicial.value > this.pedidoDevolucion.cantidadEstimada : false">
                El volumen despachado supera la cantidad estimada ({{this.pedidoDevolucion.cantidadEstimada}} L) del
                pedido.
              </div>
            </div>
          </div>
        </div>
        <!--VOLUMEN A 15°-->
        <div class="mb-3 col-md-6">
          <div class="mb-3 row">
            <div class="col-md-6">
              <label for="volumen15ControlInput" class="col-form-label">Volumen despachado a 15
                grados (litros):</label>
            </div>
            <div class="col-md-6">
              <div class="input-group mb-2">
                <input id="volumen15ControlInput" type="text" class="form-control"
                  formControlName="volumen15FormControl" placeholder="####" readonly>
              </div>
            </div>
          </div>
        </div>
        <!--ESPACIO-->
        <div class="mb-3 col-md-2">
        </div>
        <!--RECARGA-->
        <div class="mb-3 col-md-2">
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="exampleCheck1" formControlName="recargaFormControl" />
            <label class="form-check-label" for="exampleCheck1">Recarga</label>
          </div>
        </div>
        <!--ESPACIO-->
        <div class="mb-3 col-md-2">
        </div>
        <!--UNIDAD MEDIDA-->
        <div class="mb-3 col-md-6">
          <div class="mb-3 row">
            <div class="col-md-3">
              <label for="unidadMedidaControlInput" class="col-form-label">Mostrar
                en:</label>
            </div>
            <div class="col-md-6">
              <div class="input-group mb-2">
                <select id="unidadMedidaFormControl" type="text" class="form-select" name="unidadDeMedidaID"
                  formControlName="unidadMedidaFormControl" title="Seleccione un tipo de movimiento. Campo requerido.">
                  <option id="optionUnidadMedida" *ngFor="let unidad of keysUnidadDeMedida()"
                    [ngValue]="unidadMedida[unidad]">
                    {{ unidad }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <!--ESPACIO-->
        <div class="mb-3 col-md-2">
        </div>
        <!--INICIO DESPACHO-->
        <div class="mb-3 col-md-6">
          <div class="mb-3 row">
            <div class="col-md-4">
              <label for="fechaInicioDespachoFormControl" class="col-form-label" style="display: block;">
                Fecha y hora inicio de despacho:
              </label>
            </div>
            <div #fechaHora class="col-md-6">
              <div class="input-group mb-2">
                <input id="fechaInicioDespachoFormControl" type="date" class="form-control"
                  formControlName="fechaInicioDespachoFormControl" placeholder="aaaa-mm-dd" name="dp"
                  title="Campo obligatorio." [(ngModel)]="modelInicioDespacho" ngbDatepicker #d="ngbDatepicker"
                  readonly />
                <div class="input-group-append">
                  <button type="button" class="input-group-btn btn btn-secondary" (click)="d.toggle()">
                    <i class="fa fa-calendar" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
              <ngb-timepicker [(ngModel)]="time" [meridian]="true" formControlName="horaInicioDespachoFormControl"
                [readonlyInputs]="true">
              </ngb-timepicker>
              <div class="alert alert-danger"
                *ngIf="aerovalesForm.invalid && this.aerovalesForm.hasError('fechaInicioMayorAhora')">
                Esta cargando una fecha de inicio de despacho en el futuro.
              </div>
            </div>
          </div>
        </div>
        <!--FIN DESPACHO-->
        <div class="mb-3 col-md-6">
          <div class="mb-3 row">
            <div class="col-md-4">
              <label for="labelFechaFinDespacho" class="col-form-label">Fecha y hora fin de despacho:
              </label>
            </div>
            <div #fechaHoraVuelo class="col-md-6">
              <div class="input-group mb-2">
                <input id="fechaFinDespachoFormControl" type="date" class="form-control"
                  formControlName="fechaFinDespachoFormControl" placeholder="aaaa-mm-dd" name="dp"
                  title="Campo obligatorio." [(ngModel)]="modelFinDespacho" ngbDatepicker #dv="ngbDatepicker"
                  readonly />
                <div class="input-group-append">
                  <button type="button" class="input-group-btn btn btn-secondary" (click)="dv.toggle()">
                    <i class="fa fa-calendar" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
              <ngb-timepicker [meridian]="true" [(ngModel)]="timeFin" formControlName="horaFinDespachoFormControl"
                [readonlyInputs]="true">
              </ngb-timepicker>
              <div class="alert alert-danger"
                *ngIf="aerovalesForm.invalid && this.aerovalesForm.hasError('fechaFinMayorAhora')">
                Esta cargando una fecha de fin de despacho en el futuro.
              </div>
              <div class="alert alert-danger"
                *ngIf="aerovalesForm.invalid && this.aerovalesForm.hasError('fechaInicioMayorFin')">
                La fecha de inicio de despacho debe ser menor a la fecha de fin de despacho.
              </div>
            </div>
          </div>
        </div>
        <!--OBSERVACIONES-->
        <div class="mb-3 col-md-6">
          <div class="mb-3 row">
            <div class="col-md-4">
              <label for="observacionesControlSelect" class="col-form-label">Observaciones:</label>
            </div>
            <div class="col-md-8">
              <div class="input-group mb-2">
                <select id="observacionesControlSelect" class="form-select" type="number"
                  title="Campo obligatorio, debe seleccionar una observacion." formControlName="observacionFormControl">
                  <option *ngFor="let observacion of observaciones" [ngValue]="observacion">
                    {{ observacion.nombre }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <!--COMENTARIOS-->
        <div class="mb-3 col-md-6">
          <div class="input-group mb-2">
            <label for="comentariosFormControl" class="col-sm-3 col-form-label">Comentarios:</label>
            <textarea id="comentariosFormControl" formControlName="comentariosFormControl" type="text" class="col-sm-9"
              mdbInput></textarea>
          </div>
        </div>

        <!--Direccion de Entrega-->
        <div class="mb-3 col-md-6" *ngIf="true"><!--this.cliente.value!=null-->
          <div class="mb-3 row">
            <div class="col-md-12">
              <app-direccion-entrega (provVal)="receiveMessage($event)" (ciuVal)="receiveMessage($event)"
                (ciudadId)="setCiudadAerovaleSeleccionada($event)" [clienteSeleccionado]="this.cliente.value">
              </app-direccion-entrega>
            </div>
          </div>
        </div>

        <br>
        <br>
        <div class="mb-3 col-md-6">
          <div class="mb-3 row">
            <div class="col-md-12">
              <div class="col-md-4">
                <label class="form-label" for="firmaCliente">Aerovale físico</label>
              </div>
              <div class="col-md-8">
                <div class="input-group mb-2">
                  <form [formGroup]="firmasForm">
                    <input id="aerovaleFisico" type="file" class="form-control-file" name="profile"
                      (change)="onSelectedFileFirmaCliente($event.target.files)" id="validatedCustomFileCliente"
                      formControlName="firmaClienteFormControl" required />
                  </form>

                  <br />

                  <div *ngIf="procesandoFirmaCliente" class="d-flex align-items-center">
                    <div class="spinner-border spinner-border-sm" role="status"></div>
                    <span>&nbsp; &nbsp; Procesando imagen...</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <small class="text-danger">*Campos obligatorios.</small>
      </div>
    </form>
  </div>
  <div class="card-footer d-flex justify-content-center">
    <span class="border">
      <button id="guardarAerovale" class="btn btn-primary" type="button" (click)="crearAerovale()">
        <span *ngIf="!guardandoAerovale">Guardar</span>
        <div *ngIf="guardandoAerovale" class="d-flex align-items-center">
          <div class="spinner-border text-light spinner-border-sm" role="status"></div>
          <span>Guardando...</span>
        </div>
      </button>
    </span>
    <span class="border">
      <button type="button" class="btn btn-secondary" [disabled]="guardandoAerovale"
        (click)="cancelar()">Cancelar</button>
    </span>
  </div>
</div>