<ul class="nav nav-tabs justify-content-start">
  <li *ngIf="authService.hasRole(TipoUsuario.SUPERVISOR, TipoUsuario.OPERADOR_3,TipoUsuario.OPERADORDIRECTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL_AV)" class="nav-item"><a class="nav-link" routerLink="RealizarCierre" routerLinkActive="active">Realizar Cierre Diario</a></li>

  <li *ngIf="authService.hasRole(TipoUsuario.SUPERVISOR, TipoUsuario.OPERADOR_3,TipoUsuario.OPERADORDIRECTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL_AV)" class="nav-item"><a class="nav-link" routerLink="ReimputadorDocumentos" routerLinkActive="active">Reimputador de
      Movimientos</a></li>

  <li *ngIf="authService.hasRole(TipoUsuario.SUPERVISOR, TipoUsuario.OPERADOR_3,TipoUsuario.OPERADORDIRECTO, TipoUsuario.RESPRODUCTO, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL_AV)" class="nav-item"><a class="nav-link" routerLink="RetiroEfectivo" routerLinkActive="active">Retiro de efectivo</a>
  </li>

  <li *ngIf="authService.hasRole(TipoUsuario.SUPERVISOR, TipoUsuario.OPERADOR_3,TipoUsuario.OPERADORDIRECTO, TipoUsuario.RESPRODUCTO, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL_AV)" class="nav-item"><a class="nav-link" routerLink="CierreCaja" routerLinkActive="active">Cierre de caja</a></li>

  <li *ngIf="authService.hasRole(TipoUsuario.OPERADOR, TipoUsuario.OPERADOR_3,TipoUsuario.OPERADORDIRECTO, TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.CONSCIERRECAJA, TipoUsuario.COMERCIAL_AV, TipoUsuario.APROBADORBYR, TipoUsuario.FINANZAS, TipoUsuario.SOPORTE)" class="nav-item"><a class="nav-link" routerLink="CierresCaja" routerLinkActive="active"> Reporte de caja</a></li>

  <li *ngIf="authService.hasRole(TipoUsuario.OPERADOR, TipoUsuario.OPERADOR_3,TipoUsuario.OPERADORDIRECTO, TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.CONSCIERRECAJA, TipoUsuario.COMERCIAL_AV, TipoUsuario.APROBADORBYR,TipoUsuario.SOPORTE)" class="nav-item"><a class="nav-link" routerLink="Reportes" routerLinkActive="active">Reportes Cierre Diario</a></li>

  <li *ngIf="authService.hasRole(TipoUsuario.OPERADOR_3,TipoUsuario.OPERADORDIRECTO, TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.APROBADORBYR)" class="nav-item"><a class="nav-link" routerLink="reporteBalance7" routerLinkActive="active">Balance 7</a></li>

  <li *ngIf="authService.hasRole(TipoUsuario.OPERADOR, TipoUsuario.OPERADOR_3,TipoUsuario.OPERADORDIRECTO, TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.CONSCIERRECAJA, TipoUsuario.COMERCIAL_AV, TipoUsuario.APROBADORBYR, TipoUsuario.SOPORTE )" class="nav-item"><a class="nav-link" routerLink="reportesEfectivo" routerLinkActive="active">Reportes Retiro de Efectivo</a></li>


</ul>

<router-outlet></router-outlet>