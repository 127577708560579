import { TipoHoldeo } from "../../enums/TipoHoldeo";

export class Holdeo {
    id: string;
    tipoHoldeo: TipoHoldeo;
    aerovaleId: string;
    ctA_SGC: string;
    fechaDesde: string;
    fechaHasta: string;
    responsable: string;
    activo: boolean;
    ctA_Descripcion: string;
    detalle: string;
    holdearFinDeMes: boolean;
}