
import { Comprobante } from "./Comprobante";
import { TuplaRefacturacion } from "./TuplaRefacturacion";

export class RefacturacionFactura {
  id: string;
  motivo: number;
  facturaId: string;
  detalle: string;
  plazo: number;
  usuarioSolicitador: string;
  nombreArchivoAdjunto: string

  // para devolver las candidatas
  esRefacturacionTotal: boolean;
  estado: number;
  factura: Comprobante;
  fechaHora: Date;
  motivoRechazo: any;
  usuarioAprobador: string;
  usuarioRevisador: string;
  pathAdjunto: string;
  valorAAnular: number;


  // para la actualizacion
  refacturacionesFacturasIds: string[];
  responsable: string;
  estadoFinal: number;
  tupla: TuplaRefacturacion[];

  // para la correccion de despacho
  aerovaleId: string;

}


