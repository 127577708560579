import { Aeroplanta } from '../despacho/Aeroplanta';
import { TipoPadronV2 } from '../../enums/tiposPadronV2';
import { ParametroImpositivoDetalle } from './ParametroImpositivoDetalle';

export class CiudadParametroImpositivo {
    ciudadId: string;
    ciudadNombre: string;
    ciudadCodigo: string;
    tipoPadron: TipoPadronV2;
    tipoPadronCombustible: TipoPadronV2;
    parametros: ParametroImpositivoDetalle[];
};