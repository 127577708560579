import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Cliente } from 'src/app/shared/models/despacho/Cliente';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ListaAeroplantasComponent } from 'src/app/shared/lista-aeroplantas/lista-aeroplantas.component';
import { NotificacionesService } from '../../notificaciones.service';
import { NotificacionesCliente } from 'src/app/shared/models/notificaciones/NotificacionCliente';
import { Aeroplanta } from 'src/app/shared/models/despacho/Aeroplanta';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-notificacion-detalle',
  templateUrl: './notificacion-detalle.component.html',
  styleUrls: ['./notificacion-detalle.component.css']
})
export class NotificacionDetalleComponent implements OnInit {

  @Input() cliente:Cliente;


  guardando: boolean;
  editando: boolean;
  obteniendoNotificaciones:boolean;

  form: UntypedFormGroup;

  mensajeError: boolean = false;

  checkMultiplesDocumentos:UntypedFormGroup;
  notificacionesCliente:NotificacionesCliente[]=[]

  notificacionesDelClienteSeleccionado:NotificacionesCliente;

  listaDeDocumentosSeleccionadas: string[] = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    public activeModal: NgbActiveModal,
    private changeDetector: ChangeDetectorRef,
    private modalService: NgbModal,
    private notificacionesService:NotificacionesService,
    private spinner: NgxSpinnerService) {

  }

  //hooks

  ngOnInit() {
    this.obteniendoNotificaciones=true;
    this.spinner.show("spinnerDetalle")
    this.mensajeError = false;
    this.crearFormulario();
    this.inicializarComponente();
    this.getNotificaciones();

    // this.obtenerNotificacionDelClienteSeleccionado();

  }

  ngOnChanges(): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    // this.getNotificaciones();

    this.obtenerNotificacionDelClienteSeleccionado();
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  // form

  crearFormulario() {
    this.form = this.formBuilder.group({
      documentosFormControl:[this.listaDeDocumentosSeleccionadas, Validators.required],

    });

    this.checkMultiplesDocumentos = this.formBuilder.group({
      numero: this.formBuilder.array([])

    });


  }

  procesarDocumentos(lista:any[]):string[]
  {
    var index = lista.indexOf(0);
    if (index!=-1)
    {
      lista.splice(lista.indexOf(0),1,'Aerovale');
    }
    index=lista.indexOf(1);
    if (index!=-1)
    {
    lista.splice(lista.indexOf(1),1,'Factura');
    }
    return lista;
  }


  inicializarComponente() {

  }

  cargarDatosDefecto() {

  }

  mostrarNuevaNotificacion(content) {
   let modalRef= this.modalService.open(content, { centered: true, size: 'lg' });
  // modalRef.componentInstance.notificacionSeleccionada=this.notificacionesDelClienteSeleccionado;
  }

  mostrarAeroplantas(aeroplantas:Aeroplanta[])
  {
    let modalRef = this.modalService.open(ListaAeroplantasComponent, { centered: true, size: 'lg' });
    modalRef.componentInstance.aeroplantas=aeroplantas
  }

  seleccionarDocumentos(numero: string, isChecked: boolean) {
    const listaFormArray = <UntypedFormArray>this.checkMultiplesDocumentos.controls.numero;

    if (isChecked) {
      listaFormArray.push(new UntypedFormControl(numero));
      this.listaDeDocumentosSeleccionadas.push(numero);
    } else {
      let index = listaFormArray.controls.findIndex(x => x.value == numero)
      this.listaDeDocumentosSeleccionadas.splice(index, 1);
      listaFormArray.removeAt(index);
    }
  }


  // llamadas al service


  getNotificaciones()
  {
       this.obteniendoNotificaciones = true;
      // this.aerovales = [];
      // this.aerovalesFiltrados = [];
      // this.spinner.show('spinnerLista');
      this.notificacionesService.obtenerNotificaciones()
        .subscribe(result => {
          //this.aerovales = result.sort((a, b) => new Date(b.fechaHoraFinDespacho).getTime() - new Date(a.fechaHoraFinDespacho).getTime());
          this.notificacionesCliente=result;
          this.obteniendoNotificaciones = false;
          //this.aerovalesFiltrados = result;
          //this.spinner.hide('spinnerLista');
          //this.obteniendoAerovales = false;
          this.obtenerNotificacionDelClienteSeleccionado();
        }, () => {
          //this.obteniendoAerovales = false;
        });
  }

  obtenerNotificacionDelClienteSeleccionado()
  {
   this.notificacionesDelClienteSeleccionado= this.notificacionesCliente.find(n=> n.numeroDocumento===this.cliente.numeroDocumento);
  //console.log("La notificacion del cliente "+this.cliente.razonSocial+" es "+JSON.stringify(this.notificacionesDelClienteSeleccionado));
  this.obteniendoNotificaciones=false;
  this.spinner.hide();
  }

  onSubmit() {


  }

  filtrarNotificaciones()
  {
    //se filtran las notificaciones segun la
    //lista que manejará con un enum aerovale,factura,resumen
   //console.log("los documentos seleccionados son: "+JSON.stringify(this.listaDeDocumentosSeleccionadas));

  }

}
