import { EstadoPruebaLaboratorio } from '../../enums/estadoPruebaLaboratorio';

export class AerovaleDetalleAlije {
  varilladoInicio: number;
  varilladoFinal: number;
  laboratorioId: string;
  codigoPruebaLaboratorio: string;
  codigoLaboratorio:string;
  fechaPruebaLaboratorio: string;
  estadoPruebaLaboratorio: EstadoPruebaLaboratorio
}
