

  <div class="modal-header bg-light">
    <h4 class="modal-title">Observaciones</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()"></button>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="mb-3 row col-md-12">
           <div class="col-md-7">
          <textarea class="text-align" rows="4" cols="55" disabled [innerHTML]="this.detalle">

            </textarea>
        </div>
      </div>
    </div>

  </div>
  <div class="modal-footer bg-light">
    <span class="border">
      <button type="button" class="btn btn-secondary"
        (click)="activeModal.dismiss('Close clicked')">Cancelar</button>
    </span>
  </div>

