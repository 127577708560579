import { Injectable } from '@angular/core';
import { UrlApi } from 'src/app/shared/enums/urlApi';
import { tap, catchError, map } from 'rxjs/operators';
import { HttpEventType, HttpEvent, HttpClient } from '@angular/common/http';
import { HandleError, HttpErrorHandler } from 'src/app/http-error-handler.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ImpuestoService {

  private handleError: HandleError;
  baseUrl = environment.apiServer.productosUrl;

  constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler) { this.handleError = httpErrorHandler.createHandleError('Productos') }

  uploadImpuestoMasivos(formData, importador: UrlApi, imp : number){
    let resultado = importador.toString().substring(1, importador.toString().length - 2);
    return this.http.post<any>(this.getapiUrl(importador) +'/'+ imp, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map(event => this.getEventMessage(event, formData)),
      catchError(this.handleError('al cargar ' + resultado))

    );
  }

  getapiUrl(resource: UrlApi) {
    switch (resource) {
      case UrlApi.impuestos:
        return this.baseUrl + '/ImpuestosV1/Masivos';
    }
  }

  private getEventMessage(event: HttpEvent<any>, formData) {

    switch (event.type) {

      case HttpEventType.UploadProgress:
        return this.fileUploadProgress(event);

      case HttpEventType.Response:
        return this.apiResponse(event);

      // default:
      //   return `File "${formData.get('profile').name}" surprising upload event: ${event.type}.`;
    }
  }

  private fileUploadProgress(event) {
    const percentDone = Math.round(100 * event.loaded / event.total);
    return { status: 'progress', message: percentDone };
  }

  private apiResponse(event) {
    console.log('apiresponse:' + event.body);
    return event.body;
  }
}
