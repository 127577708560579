
<nav class="navbar navbar-light bg-light navbar-expand-lg mb-3">
  <h5>CAI por aeroplanta.</h5>
</nav>
<div class="col col-md-12">
  <div class="spinner-container" *ngIf="obteniendo">
    <ngx-spinner name="spinnerGrafico" bdOpacity=0.3bd
      Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
      <p style="color: white">C a r g a n d o . . . </p>
    </ngx-spinner>
  </div>
  <!--SELECT AEROPLANTA-->
  <div class="row" *ngIf="!this.obteniendo">
    <div class="col col-3">
      <div class="list-group py-3  menu-izq">
        <div *ngFor="let aep of aeroplantas; let i=index">
          <button type="button" [class.active]="" class="list-group-item list-group-item-action"
            (click)="mostrarDetalle(i)">

            <div class="d-flex w-100 justify-content-between">
              <p>{{ aep.nombre}} </p>
            </div>
          </button>
        </div>
      </div>
    </div>
    <div class="col col-9 border-dark" *ngIf="this.aeroplantaSeleccionada != null">
      <app-informacion-cai [aeroplantaSeleccionada]="aeroplantaSeleccionada"></app-informacion-cai>
    </div>
  </div>
</div>