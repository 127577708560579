import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Surtidor } from 'src/app/shared/models/stock/Surtidor';
import { MessageService } from 'src/app/shell/message.service';
import { NuevoSurtidorComponent } from './nuevo-surtidor/nuevo-surtidor.component';
import { TanqueGeneral } from 'src/app/shared/models/stock/TanqueGeneral';
import { Aeroplanta } from 'src/app/shared/models/despacho/Aeroplanta';
import { UsuarioService } from 'src/app/account/usuario.service';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { SurtidorService } from '../../surtidor.service';
import { VisorTanquesRelacionadosComponent } from './visor-tanques-relacionados/visor-tanques-relacionados.component';
import { Tanque } from 'src/app/shared/models/stock/Tanque';
import { Subscription } from 'rxjs';
import { Guid } from 'guid-typescript';
import { VisorPicosComponent } from '../abastecedoras/nueva-abastecedora/visor-picos/visor-picos.component';
import { ActivatedRoute } from '@angular/router';
import { switchMap, tap } from 'rxjs/operators';
import { TipoSurtidor } from 'src/app/shared/enums/TipoSurtidor';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-surtidores',
  templateUrl: './surtidores.component.html',
  styleUrls: ['./surtidores.component.css']
})
export class SurtidoresComponent implements OnInit {

  TipoUsuario = TipoUsuario;
  
  surtidores: Surtidor[] = [];
  tanquesGenerales : TanqueGeneral[] = [];

  tablaSurtidores: UntypedFormGroup;

  tipoSurtidor: TipoSurtidor;

  eliminando: boolean = false;
  messageSurtidores: string = "C a r g a n d o . . ."
  obteniendoSurtidores: boolean = false;
  change$: Subscription;

  constructor( private authService: AuthService, 
    private messageService: MessageService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private usuarioService: UsuarioService,
    private fb: UntypedFormBuilder,
    private spinner: NgxSpinnerService,
    private surtidorService: SurtidorService,
    sharedService: SharedService) {
      this.tablaSurtidores = this.fb.group({
        abastecedoras: new UntypedFormArray([])
      });

      this.route.queryParams.pipe(
        tap(params => {
          this.tipoSurtidor = Number(params['tipoSurtidor']);

          this.initRequest();
        }),
        switchMap(params => this.surtidorService.getSurtidoresPorAeroplanta(this.codigoAeroplanta))
      ).subscribe(result => {
        this.procesarResult(result);        
      }, (err: ErrorModel) => {
        this.procesarError(err);
      });
      this.change$ = sharedService.aeroplantaSeleccionada.subscribe(_=>{
        this.recargarPagina()
      })

    }

  ngOnInit() { 
  }

  // llamadas al service

  initRequest(){
    this.obteniendoSurtidores = true;
    this.spinner.show('spinnerAbastecedora');
  }

  procesarResult(result){
    this.surtidores = result.filter(a => a.tipoSurtidor==this.tipoSurtidor);
    this.addCheckboxes();
    this.spinner.hide('spinnerAbastecedora');
    this.obteniendoSurtidores = false;
  }

  procesarError(error: ErrorModel){
    this.messageService.showErrorMessage("Error al obtener " + this.obtenerDescripcionSurtidorDispenser() + ".");
    this.obteniendoSurtidores = false;
    this.spinner.hide('spinnerAbastecedora');
    this.addCheckboxes();
  }

  getSurtidores(){
    this.initRequest();
    
    this.surtidorService.getSurtidoresPorAeroplanta(this.codigoAeroplanta)
      .subscribe(result => {
        this.procesarResult(result);
      },
      (err: ErrorModel) => {
        this.procesarError(err);
      })
  }

  cambiarEstadoSurtidor(s: Surtidor){

    s.codigoAeroplanta= this.codigoAeroplanta;

    if(s.activo == true){
      s.activo = false;
    }else{
      s.activo = true;
    }
    this.surtidorService.editarSurtidor(s)
      .subscribe(result => {
        if(result.activo == true){
          this.messageService.showSuccessMessage('Se activo el ' + this.obtenerDescripcionSurtidorDispenser() + ' correctamente.')
        }else{
          this.messageService.showErrorMessage('Se desactivo el ' + this.obtenerDescripcionSurtidorDispenser() + ' correctamente.')
        }
      })
  }


   recargarPagina() {
    setTimeout(() => {
     this.spinner.show('spinnerAbastecedora');
    });

    this.getSurtidores();
  }

  // form

  private addCheckboxes() {
    // reinicio el formulario
    this.tablaSurtidores.setControl('surtidores', this.fb.array([]));
    this.surtidores.map((o, i) => {
      const control = new UntypedFormControl(i === 0); // if first item set to true, else false
      control.setValue(o.activo);
      (this.tablaSurtidores.controls.surtidores as UntypedFormArray).push(control);
    });
  }


  // modals

  agregarSurtidor(){
    let modalRef = this.modalService.open(NuevoSurtidorComponent, { centered: true });
    modalRef.componentInstance.tanquesGenerales = this.tanquesGenerales;
    modalRef.componentInstance.tipoSurtidor = this.tipoSurtidor;
    modalRef.componentInstance.obtenerNumero = this.tipoSurtidor;
    modalRef.componentInstance.surtidores = this.surtidores;
    modalRef.result.then((result: Surtidor) => {
      if(result) this.messageService.showSuccessMessage("Se creó correctamente el " + this.obtenerDescripcionSurtidorDispenser() + ".");
      this.getSurtidores();
    }, () => { });
  }

  editarSurtidor(surtidor: Surtidor){
    let modalRef = this.modalService.open(NuevoSurtidorComponent, { centered: true });
    modalRef.componentInstance.surtidor = surtidor;
    modalRef.componentInstance.tipoSurtidor = this.tipoSurtidor;
    modalRef.componentInstance.obtenerNumero = this.tipoSurtidor;
    modalRef.componentInstance.vaciarTanques = false;
    modalRef.componentInstance.tanquesGenerales = this.tanquesGenerales;
    modalRef.result.then((result: Surtidor) => {
      if(result) this.messageService.showSuccessMessage("Se modificó correctamente el " + this.obtenerDescripcionSurtidorDispenser() + ".");
    }, () => { });
  }

  irATanquesRelacionados(surtidor: Surtidor, listaDeTanques: Tanque[] )
  {
    let modalRef = this.modalService.open(VisorTanquesRelacionadosComponent, { centered: true });
    modalRef.componentInstance.surtidor = surtidor;
    modalRef.componentInstance.listaDeTanques = listaDeTanques;
  }

  verPicos(s: Surtidor){
    let modalRef = this.modalService.open(VisorPicosComponent, {centered: true, backdrop: 'static'});
    modalRef.componentInstance.nombreContenedor = s.nombre;
    modalRef.componentInstance.picos = s.picos;
  }

  obtenerDescripcionSurtidorDispenser(){
    if(this.tipoSurtidor == TipoSurtidor.surtidor){
      return "Surtidor";
    }else if(this.tipoSurtidor == TipoSurtidor.dispenser){
      return "Dispenser";
    }else{
      return "-";
    }
  }

  hasRole(...roles: TipoUsuario[]) {
    return this.authService.hasRole(...roles);
  }

  get codigoAeroplanta() {
    return this.usuarioService.getAeroplantaActual().codigoAeroplanta;
  }
}
