<div class="modal-header bg-light">
  <h4 class="modal-title">Nuevo cupon tarjeta de Crédito/Débito</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close(false)">
    
  </button>
</div>

<div class="modal-body" [formGroup]="cuponesForm">

  <!--TARJETA-->
  <div class="row">
    <div class="mb-3 row col-md-12">
      <label for="tarjetaFormControl" class="col-md-5 col-form-label">*Tarjeta</label>
      <div class="col-md-7">
        <select id="tarjetaFormControl" class="form-select"
          ngbTooltip="Seleccione el producto." formControlName="tarjetaFormControl">
          <option *ngFor="let tarjeta of tarjetas" [ngValue]="tarjeta">
            ({{ tarjeta.codigo }}) - {{ tarjeta.nombre }}
          </option>
        </select>
        <loading-text-spinner *ngIf="cargandoTarjetas" message="Obteniendo tarjetas..."
          color="primary">
        </loading-text-spinner>
      </div>
    </div>
  </div>

  <!--NUMERO TARJETA-->
  <div class="row">
    <div class="mb-3 row col-md-12">
      <label for="numeroTarjetaFormControl" class="col-sm-5 col-form-label">*Número de tarjeta</label>
      <div class="col-sm-7">
        <input class="form-control" appOnlyNumber formControlName="numeroTarjetaFormControl" placeholder="Últimos 4 dígitos" maxlength="4">
        <div *ngIf="numeroTarjetaFormControl.invalid && numeroTarjetaFormControl.errors.required && (numeroTarjetaFormControl.dirty || numeroTarjetaFormControl.touched)" 
          class="alert alert-danger">Requerido
        </div>        
      </div>
    </div>
  </div>

  <!--LOTE CUPON-->
  <div class="row">
    <div class="mb-3 row col-md-12">
      <label for="loteCuponFormControl" class="col-sm-5 col-form-label">*Lote cupón</label>
      <div class="col-sm-7">
        <input class="form-control" appOnlyNumber formControlName="loteCuponFormControl" placeholder="3 dígitos" maxlength="3">
        <div *ngIf="loteCuponFormControl.invalid && loteCuponFormControl.errors.required && (loteCuponFormControl.dirty || loteCuponFormControl.touched)" 
          class="alert alert-danger">Requerido
        </div>
      </div>
    </div>
  </div>

  <!--NUMERO CUPON-->
  <div class="row">
    <div class="mb-3 row col-md-12">
      <label for="numeroCuponFormControl" class="col-sm-5 col-form-label">*Numero cupón:</label>
      <div class="col-sm-7">
        <input class="form-control" appOnlyNumber formControlName="numeroCuponFormControl" placeholder="5 dígitos" maxlength="5">
        <div *ngIf="numeroCuponFormControl.invalid && numeroCuponFormControl.errors.required && (numeroCuponFormControl.dirty || numeroCuponFormControl.touched)" 
          class="alert alert-danger">Requerido
        </div>
      </div>
    </div>
  </div>

  <!--IMPORTE-->
  <div class="row">
    <div class="mb-3 row col-md-12">
      <label for="importeFormControl" class="col-sm-5 col-form-label">*Importe</label>
      <div class="col-sm-7">
        <input class="form-control" appOnlyDecimal formControlName="importeFormControl" maxlength="50">
        <div *ngIf="importeFormControl.invalid && importeFormControl.errors.required && (importeFormControl.dirty || importeFormControl.touched)"
          class="alert alert-danger">Requerido
        </div>
      </div>
    </div>
  </div>

  <!--ARCHIVO-->
  <div class="row"> 
    <div class="mb-3 row col-md-12">
      <label for="importeFormControl" class="col-sm-5 form-label">*Adjuntar archivo imagen</label>
      <div class="col-sm-7">
        <input accept="image/*" class="form-control" id="importeFormControl" type="file" (change)="onSelectedFile($event)">
        <label class="custom-file-label mt-1" for="importeFormControl">{{ fileName }}</label>
      </div>
    </div>
  </div>

  <div class="alert alert-danger" *ngIf="error != null">
    {{error}}
  </div>

  <small class="text-danger">*Campos obligatorios.</small>
</div>
<div class="modal-footer justify-content-center">
  <button class="btn btn-primary me-2" id="aceptar" [disabled]="!cuponesForm.valid || creandoCaneria" (click)="this.agregarCupon()">
    <span *ngIf="!creandoCaneria">Cargar cupón</span>
    <div *ngIf="creandoCaneria" class="d-flex align-items-center">
      <div class="spinner-border text-light spinner-border-sm" role="status"></div>
      <span>Guardando...</span>
    </div>
  </button>
  <button type="button" class="btn btn-secondary" (click)="this.activeModal.close(false)">Cancelar</button>
</div>

