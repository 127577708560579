import { DatePipe } from '@angular/common';
import { Component, HostBinding, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbCalendar, NgbDatepickerConfig, NgbDateStruct, NgbModal, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { UsuarioService } from 'src/app/account/usuario.service';
import { ModalMotivoRechazoComponent } from 'src/app/cliente/clientes-aprobar-modificaciones/modal-motivo-rechazo/modal-motivo-rechazo.component';
import { AerovalesService } from 'src/app/despacho/aerovales.service';
import { EstadoRefacturacion } from 'src/app/shared/enums/estadoRefacturacion';
import { Motivo } from 'src/app/shared/enums/Motivo';
import { TipoAerovale } from 'src/app/shared/enums/tipoAerovale';
import { FechasService } from 'src/app/shared/fechas.service';
import { ModalConfirmComponent } from 'src/app/shared/generic-modals/modal-confirm.component';
import { Aeroplanta } from 'src/app/shared/models/despacho/Aeroplanta';
import { Aerovale } from 'src/app/shared/models/despacho/Aerovale';
import { AerovaleDetalle } from 'src/app/shared/models/despacho/AerovaleDetalle';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { Comprobante } from 'src/app/shared/models/facturacion/Comprobante';
import { Factura } from 'src/app/shared/models/facturacion/Factura';
import { RefacturacionFactura } from 'src/app/shared/models/facturacion/RefacturacionFactura';
import { TuplaRefacturacion } from 'src/app/shared/models/facturacion/TuplaRefacturacion';
import { TipoMovimientoPipe } from 'src/app/shared/pipes/tipo-movimiento.pipe';
import { SharedService } from 'src/app/shared/shared.service';
import { MessageService } from 'src/app/shell/message.service';
import { ModalObservacionRefacturacionComponent } from '../aprobacion-refacturacion/modal-observacion-refacturacion/modal-observacion-refacturacion.component';
import { ComprobanteService } from '../comprobante.service';
import { FacturacionService } from '../facturacion.service';

@Component({
  selector: 'app-revision-refacturacion',
  templateUrl: './revision-refacturacion.component.html',
  styleUrls: ['./revision-refacturacion.component.css']
})
export class RevisionRefacturacionComponent implements OnInit {

  @HostBinding('class.changed')

  tablaComprobantes: UntypedFormGroup;
  aeroplanta: Aeroplanta;
  tituloFactura: string;
  facturas: RefacturacionFactura[] = [];
  facturasFiltradas: RefacturacionFactura[] = [];

  facturasSeleccionadas: string[] = [];
  aceptandoRefacturacion: boolean = false;
  rechazandoFacturacion: boolean = false;

  facturaCalculada: Factura[];
  facturaObtenida: Comprobante;
  remitosId: string[] = [];
  aerovaleDetalle: AerovaleDetalle;
  previsualizacion: boolean = false;
  cargandoPrevisualizacion: boolean = false;

  aerovales: Aerovale[] = [];
  aerovalesFiltrados: Aerovale[] = [];
  aerovaleActual: Aerovale;
  fechaHasta = new UntypedFormControl();
  fechaFormControlDesdeHasta = new UntypedFormControl();
  mensajeErrorExportar = "";
  model: NgbDateStruct;
  model1: NgbDateStruct;
  time: NgbTimeStruct;
  startDate: Date;
  endDate: Date;
  buscando: boolean = false;
  mensajeSpinner: string = "D e s c a r g a n d o";
  obteniendoAerovales = true;
  procesandoExport: boolean = false;
  cambiandoAeroplanta: boolean = false;
  pendiente = { valor: "Pendiente", descripcion: "Pendiente", seleccionado: false };
  finalizado = { valor: "Finalizado", descripcion: "Finalizado", seleccionado: false };
  finalizadoSinFirma = { valor: "FinalizadoSinFirma", descripcion: "Finalizado sin firma", seleccionado: false };
  estadoSincronizado = { valor: "Sincronizado", descripcion: "Sincronizado", seleccionado: false };
  anulado = { valor: "Anulado", descripcion: "Anulado", seleccionado: false };

  notaCredito = { tipo: 0, valor: "notaCredito", descripcion: "Nota de credito", seleccionado: false };
  notaDebito = { tipo: 1, valor: "notaDebito", descripcion: "Nota de debito", seleccionado: false };

  tipos = [
    this.notaCredito, this.notaDebito
  ];

  estados = [
    this.pendiente,
    this.finalizado,
    this.estadoSincronizado,
    this.anulado
  ];

  busquedaFormControl = new UntypedFormControl();
  change$: Subscription;
  errorAerovales: boolean = false;

  tipoNuevoAerovale: TipoAerovale;
  enumTipoAerovale = TipoAerovale;
  idComprobanteAsociado: string;
  botonAnular: boolean = false;
  itemSeleccionado: number = null;

  constructor(private datepickerConfig2: NgbDatepickerConfig,
    private messageService: MessageService,
    private fechaService: FechasService,
    private usuarioService: UsuarioService,
    private calendar: NgbCalendar,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private activeModal: NgbModal,
    private fb: UntypedFormBuilder,
    private comprobanteService: ComprobanteService,
    public facturacionService: FacturacionService,
    private datePipe: DatePipe,
    private tipoMovimientoPipe: TipoMovimientoPipe,
    sharedService: SharedService) {
      this.change$ = sharedService.aeroplantaSeleccionada.subscribe(_=>{
        this.cambiarDeAeroplanta()
      })

  }

  ngOnInit() {

    this.tablaComprobantes = this.fb.group({
      aerovalesFiltrados: new UntypedFormArray([])
    });
    this.addCheckboxes();
    //this.obtenerComprobantePorPrefijoNumeroTipo();
    this.remitosId.push("fccc76af-b073-4b75-8a02-ad1d00eeaba5");
    //this.obtenerFacturaCalculada(this.remitosId);

    this.idComprobanteAsociado = '';
    this.botonAnular = false;
    this.mensajeSpinner = "C a r g a n d o . . .";

    this.model = this.calendar.getToday();
    this.model1 = this.calendar.getToday();
    let inicio: any = { day: 1, month: 1, year: 2019 };
    this.datepickerConfig2.minDate = inicio;
    let fin: any = { day: this.model.day, month: this.model.month, year: this.model.year };
    this.model1 = this.calendar.getToday();
    this.datepickerConfig2.maxDate = fin;
    setTimeout(() => {
      this.spinner.show('spinnerGrafico');
    });
    this.reinicioFechaInicioFechaFin();
    this.getRevisionesPorRango(this.startDate, this.endDate);
  }

  ngOnDestroy() {
    this.change$.unsubscribe();
    this.aerovalesFiltrados = [];
  }

  filtrarAerovales() {
    this.aerovalesFiltrados = this.aerovales.filter(a => {
      let agregar = true;
      if (this.busqueda.value) {
        agregar = a.clienteRazonSocial.toLowerCase().indexOf(this.busqueda.value.toLowerCase()) > -1;
        agregar = agregar || (a.nombreCiudadAeropuerto &&
          a.nombreCiudadAeropuerto.toLowerCase().indexOf(this.busqueda.value.toLowerCase()) > -1);
        agregar = agregar || (a.vuelo && a.vuelo.toLowerCase().indexOf(this.busqueda.value.toLowerCase()) > -1);
        agregar = agregar || (a.matricula && a.matricula.toLowerCase().indexOf(this.busqueda.value.toLowerCase()) > -1);
        agregar = agregar || (a.numeroAerovale && a.numeroAerovale.toString().indexOf(this.busqueda.value) > -1);

      }
      if (this.estados.some(b => b.seleccionado))
        agregar = agregar && this.estados.some(
          b => (b.seleccionado && b.valor == a.estado) ||
            (b.seleccionado && b == this.finalizado && this.finalizadoSinFirma.valor == a.estado));
      if (this.tipos.some(b => b.seleccionado))
        agregar = agregar && this.tipos.some(
          b => (b.seleccionado && b.tipo == a.tipoAerovale));
      return agregar;
    });
    if (this.aerovaleActual) {
      if (!this.aerovalesFiltrados.includes(this.aerovaleActual)) {
      }
    }
  }

  filtrarFacturas() {
    this.facturasFiltradas = this.facturas.filter(a => {
      let agregar = true;
      if (this.busqueda.value) {
        agregar = a.factura.razonSocialCliente.toLowerCase().indexOf(this.busqueda.value.toLowerCase()) > -1;

        agregar = agregar || (this.facturacionService.formatearComprobante(a.factura.prefijo,a.factura.numeroComprobante).indexOf(this.busqueda.value) > -1);

        agregar = agregar || (this.datePipe.transform(a.factura.fechaEmision, "d/MM/y").indexOf(this.busqueda.value) > -1);

        agregar = agregar || (this.tipoMovimientoPipe.transform(a.factura.tipoMovimiento).indexOf(this.busqueda.value) > -1);
      }

      return agregar;
    });
  }

  get busqueda() {
    return this.busquedaFormControl;
  }


  getRevisionesPorRango(fechaInicio: Date, fechaFin: Date) {
    this.obteniendoAerovales = true;
    this.aerovales = [];
    this.aerovalesFiltrados = [];
    this.spinner.show('spinnerGrafico');
    let desde: string = this.fechaService.convertirDateUtcALocal(fechaInicio.getUTCFullYear(), fechaInicio.getMonth() + 1, fechaInicio.getDate(), 0, 0);
    let hasta: string = this.fechaService.convertirDateUtcALocal(fechaFin.getUTCFullYear(), fechaFin.getMonth() + 1, fechaFin.getDate(), 23, 59);
    this.facturacionService.getRefacturacionesPendientes(desde, hasta)
      .subscribe(result => {
        this.facturas = result;
        this.facturasFiltradas = this.facturas;
        this.spinner.hide('spinnerGrafico');
        this.obteniendoAerovales = false;
      }, () => {
        this.errorAerovales = true;
        this.obteniendoAerovales = false;
      });
  }

  addValidationError(control: AbstractControl, validationName: string) {
    let errors = control.errors;
    if (errors == null)
      errors = { [validationName]: true };
    else
      errors[validationName] = true;
    control.setErrors(errors);
    control.markAsDirty();
  }

  /**
   * Convierte una NgbDateStruct y una NgbTimeStruct en un Date
   */
  dateFromDateTimeStruct(date: NgbDateStruct, hour: NgbTimeStruct): Date {
    if (date == null || hour == null)
      return null;
    let dateReturn = new Date();
    dateReturn.setDate(date.day);
    dateReturn.setMonth(date.month - 1);
    dateReturn.setFullYear(date.year);
    dateReturn.setHours(hour.hour);
    dateReturn.setMinutes(hour.minute);
    return dateReturn;
  }

  fechaDesdeMayorQueHasta() {
    let esMayor: boolean = false;
    let extraerHora: Date = new Date();
    this.startDate = new Date(this.model.year, this.model.month - 1);
    this.endDate = new Date(this.model1.year, this.model1.month - 1);
    if (this.startDate > this.endDate) { esMayor = true; }
    return esMayor;
  }


  reinicioFechaInicioFechaFin() {
    let extraerHora: Date = new Date();
    this.startDate = new Date(this.model.year, this.model.month - 1, this.model.day);
    this.endDate = new Date(this.model1.year, this.model1.month - 1, this.model1.day);
    this.endDate.setHours(23, 59, 59, 99);
  }

  buscarDesdeHasta() {
    this.mensajeErrorExportar = "";
    this.aerovalesFiltrados = null;
    this.aerovaleActual = null;
    this.mensajeSpinner = "C a r g a n d o . . .";
    this.buscando = true;
    this.reinicioFechaInicioFechaFin();
    this.getRevisionesPorRango(this.startDate, this.endDate);

    //this.aerovalesFiltrados.splice(0, this.aerovalesFiltrados.length);

    //this.buscando = false;
  }

  verObservaciones(detalle: string) {
    console.log("El datelle es " + JSON.stringify(detalle));
    let modalRef = this.modalService.open(ModalObservacionRefacturacionComponent, { centered: true, backdrop: 'static' });
    modalRef.componentInstance.detalle = detalle;
    modalRef.result.then(() => {
      console.log("S");
    }, () => { });
  }

  verComparativasFacturas(content, factura: RefacturacionFactura, index) {
    this.cargandoPrevisualizacion = true;
    this.itemSeleccionado = index;
    this.facturaObtenida = factura.factura;
    this.tituloFactura = 'Factura: ' + factura.factura.prefijo + '-' + factura.factura.numeroComprobante + ' Fecha: ' + new Date().toLocaleDateString();
    this.comprobanteService.obtenerComprobantePorPrefijoNumeroTipo(this.facturaObtenida.prefijo, this.facturaObtenida.numeroComprobante, this.facturaObtenida.tipoMovimiento)
      .subscribe(result => {
        this.facturaObtenida = result;
        if (result != null) {
          this.previsualizacion = false;
          this.facturacionService.getNuevoComprobante(factura.id, factura.esRefacturacionTotal)
            .subscribe(result => {
              this.facturaCalculada = result;
              this.previsualizacion = true;

              this.cargandoPrevisualizacion = false;
              this.itemSeleccionado = -1;
              this.obteniendoAerovales = false;
              this.spinner.hide('spinnerGrafico');

              const modalRef = this.modalService.open(content, { windowClass: 'custom-modal', centered: true, size: 'lg' });
            })
        }
        else {
          this.cargandoPrevisualizacion = false;
          this.itemSeleccionado = -1;
          this.facturaObtenida = null; //se limpia el comprobante.
        }
      },
        err => {
          // Entra aquí si el servicio entrega un código http de error EJ: 404,
          //500
          this.cargandoPrevisualizacion = false;
          this.itemSeleccionado = -1;
          this.facturaObtenida = null; //se limpia el comprobante.
        });
  }

  verComparativasAerovales(content) {
    //this.activeModal.dismissAll();
    let modalRef = this.activeModal.open(content, { size: "lg", centered: true })
    //modalRef.componentInstance.aerovaleDetalle = this.aerovaleDetalle;

  }


  limpiarCampos() {
    this.aerovalesFiltrados = [];
    this.spinner.hide("spinnerLista");
    this.spinner.hide("spinnerDetalle");
    this.buscando = false;
    this.busquedaFormControl.reset();
    this.model = this.calendar.getToday();
    this.model1 = this.calendar.getToday();
    //limpio filtro estados
    for (let estado of this.estados) {
      estado.seleccionado = false;
    }
    //limpio filtro tipos
    for (let tipo of this.tipos) {
      tipo.seleccionado = false;
    }
  }

  cambiarDeAeroplanta() {
    this.cambiandoAeroplanta = false;
    this.limpiarCampos();
    this.cambiandoAeroplanta = true;
    this.ngOnInit();
  }

  private addCheckboxes() {
    // reinicio el formulario
    this.tablaComprobantes.setControl('aerovalesFiltrados', this.fb.array([]));
    this.aerovalesFiltrados.map((o, i) => {
      const control = new UntypedFormControl(i === 0); // if first item set to true, else false
      control.setValue(o);
      (this.tablaComprobantes.controls.aerovalesFiltrados as UntypedFormArray).push(control);
    });
  }



  irAlConfirmDialog() {
    let modalRef = this.modalService.open(ModalConfirmComponent, { centered: true, backdrop: 'static' });
    modalRef.componentInstance.mensaje = "¿Desea confirmar?";
    modalRef.result.then((result) => {
      this.messageService.showSuccessMessage('Se refacturó correctamente.');
    }, () => { });
  }

  verFacturaCalculada(content) {
    const modalRef = this.modalService.open(content, { centered: true, size: 'lg' });
  }

  obtenerNuevoComprobante(id: string) {
    this.previsualizacion = false;
    // this.obteniendoFactura = true;
    this.facturacionService.getNuevoComprobante(id, true)
      .subscribe(result => {
        this.facturaCalculada = result;
        //this.spinner.hide('spinnerFactura');
        //this.obteniendoFactura = false;
        this.previsualizacion = true;
      })

  }


  seleccionarFactura(factura, $event) {
    console.log("Lo que selecciono es " + JSON.stringify(factura))
    const indice = this.facturasSeleccionadas.indexOf(factura);
    if (indice != -1) {
      this.facturasSeleccionadas.splice(indice, 1);
    }
    else {
      this.facturasSeleccionadas.push(factura);
    }
    console.log("Facturas seleccionadas " + JSON.stringify(this.facturasSeleccionadas))
  }

  revisarRefactura() {
    this.aceptandoRefacturacion = true;

    let refacturacion = new RefacturacionFactura();
    let tupla: TuplaRefacturacion[] = [];
    for (var id of this.facturasSeleccionadas) {
      let t = new TuplaRefacturacion();
      t.esRefacturacionTotal = true;
      t.refacturacionFacturaId = id
      tupla.push(t);
    }
    refacturacion.tupla = tupla;
    refacturacion.responsable = this.obtenerStringUsuarioSolicitador();
    refacturacion.estado = 1 // pendiente
    refacturacion.estadoFinal = 2 // revisado
    this.facturacionService.actualizarRefacturacion(refacturacion)
      .subscribe(result => {
        this.messageService.showSuccessMessage("Se ha realizado correctamente la refacturacion de la factura ");
        this.facturasSeleccionadas = [];
        this.ngOnInit();
        this.aceptandoRefacturacion = false;
      }, (err: ErrorModel) => {
        this.aceptandoRefacturacion = false;
        this.facturasSeleccionadas = [];
        this.messageService.showErrorMessage("Hubo un error al realizar la refacturacion. " + err.description + ' ' + err.message);
      });

  }

  rechazarRefactura() {
    if (this.facturasSeleccionadas.length == 0) {
      this.messageService.showErrorMessage('No se seleccionó ninguna factura para rechazar.');
      return;
    } else {

      const modalRef = this.modalService.open(ModalMotivoRechazoComponent, { size: 'lg', backdrop: 'static' });
      modalRef.result.then((motivo) => {
        if (motivo == null) {
          return;
        }

        this.accionRechazar(motivo);
      }).catch((error) => {
        return;
      });
    }
  }

  accionRechazar(motivo) {
    this.rechazandoFacturacion = true;

    let refacturacion = new RefacturacionFactura();
    let tupla: TuplaRefacturacion[] = [];

    for (var id of this.facturasSeleccionadas) {
      let t = new TuplaRefacturacion();
      t.esRefacturacionTotal = true;
      t.refacturacionFacturaId = id
      tupla.push(t);
    }
    refacturacion.tupla = tupla;
    refacturacion.responsable = this.obtenerStringUsuarioSolicitador();
    refacturacion.estado = 1 // pendiente
    refacturacion.estadoFinal = 4 // rechazado
    refacturacion.motivoRechazo = motivo;

    this.facturacionService.actualizarRefacturacion(refacturacion)
      .subscribe(result => {
        this.facturasSeleccionadas = [];
        this.rechazandoFacturacion = false;
        this.messageService.showSuccessMessage("Se ha realizado correctamente el rechazo de la revision");
        this.ngOnInit();
      }, (err:ErrorModel) => {
        this.facturasSeleccionadas = [];
        this.rechazandoFacturacion = false;
        this.messageService.showErrorMessage("Hubo un error al realizar el rechazo de la revision. " + err.description + ' ' + err.message);
        
      });
  }


  // getters

  obtenerMotivoRefacturacion(numero: string) {
    return Motivo[numero];
  }

  obtenerEstadoRefacturacion(numero: string) {
    return EstadoRefacturacion[numero];
  }

  obtenerStringUsuarioSolicitador() {
    return this.facturacionService.obtenerStringSolicitador();
  }



}
