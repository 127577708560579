import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-set-fecha',
  templateUrl: './modal-set-fecha.component.html',
  styleUrls: ['./modal-set-fecha.component.scss']
})
export class ModalSetFechaComponent implements OnInit {

  @Input() setFecha:string;

  fechaFormControl = new UntypedFormControl();
  horaFormControl = new UntypedFormControl();

  modelFecha: NgbDateStruct;
  modelHora: any;

  titulo:string;

  mostrarHora:boolean;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.titulo = "Editar fecha y hora del reporte";
    let fecha = new Date(this.setFecha);
    this.modelFecha = { day: fecha.getDate(), month: fecha.getMonth() + 1, year: fecha.getFullYear() };
    this.modelHora = { hour: 23, minute: 59, second: 59 };
    this.mostrarHora = false;
  }

  toggleDatepicker(e: any) {
    e.toggle();
  }

  formatDate(year, month, day, hour, minute): string {
    return year + '-' + month.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + '-' + day.toLocaleString(undefined, { minimumIntegerDigits: 2 })
      + 'T' + hour.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + minute.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':59';
  }

  onAceptar() {
    let fechaHoraCierre = this.formatDate(this.fecha.value.year, this.fecha.value.month,
      this.fecha.value.day, 23, 59); // a pedido de MB harcodear 23.59.59
    this.activeModal.close(fechaHoraCierre)
  }

  onCancelar() {
    this.activeModal.close(false)
  }

  get fecha() {
    return this.fechaFormControl;
  }

  get hora() {
    return this.horaFormControl;
  }
}
