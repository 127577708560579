<div class="nav-item">
  <div ngbDropdown autoClose="outside">
    <button class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2" id="{{idElemento}}"
      ngbDropdownToggle>{{encabezadoColumna}}</button>
    <div class="contenedorFiltro" ngbDropdownMenu>
      <div class="px-4 py-3">
        <div *ngFor="let e of elementos; let i = index">
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="{{ idElemento + '_' + i }}"
              [(ngModel)]="e.seleccionado" name="{{e.nombre}}" (change)="filtrar()">
            <label class="form-check-label text-nowrap" for="{{ idElemento + '_' + i }}">
              {{ e.nombre}}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>