<div class="container-fluid spinner-container" *ngIf="obteniendoDatos">
    <ngx-spinner name="spinnerGrafico" bdOpacity=0.3bd
        Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
        <p style="color: white">C a r g a n d o . . . </p>
    </ngx-spinner>
</div>

<div *ngIf="!obteniendoDatos">
    <div class="modal-header bg-light">
        <h4 class="modal-title">Bitacora de Rancho: {{this.rancho.codigoRancho}}</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="this.activeModal.dismiss()"></button>
    </div>

    <div class="modal-body">
        Listado de movimientos de stock para este rancho

        <nav class="navbar navbar-light bg-light navbar-expand-lg justify-content-end">

            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <ul class="navbar-nav">
                <!--buscador -->
                <li class="nav-item me-2">

                    <div style="margin-top: 6px;" class="form-check">
                        <input type="checkbox" class="form-check-input"  mm id="mostrarAnuladosFormControl" (change)="filtrarMovimientos()"
                            [(ngModel)]="mostrarAnulados">
                        <label class="form-check-label" for="mostrarAnuladosFormControl">Mostrar anulados</label>
                    </div>
                </li>
                            
                <!--boton producto-->
                <li class="nav-item me-2">
                    <div class="me-2" ngbDropdown autoClose="outside">
                        <button class="btn btn-outline-primary" id="dropdownEstados"
                            ngbDropdownToggle>Estados</button>
                        <div ngbDropdownMenu aria-labelledby="dropdownEstados">
                            <div class="px-4 py-3">
                                <div *ngFor="let estado of estados;let i = index">
                                    <div class="form-check">
                                        <input type="checkbox" name="estado+{{i}}" class="form-check-input"
                                            id="{{ 'dropdownCheck' + estado.codigo}}" [(ngModel)]="estado.seleccionado"
                                            (change)="filtrarMovimientos()">
                                        <label class="form-check-label text-nowrap" for="{{ 'dropdownCheck' + estado.codigo}}">
                                            {{estado.nombre}}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>

                <li class="nav-item me-2">
                    <button class="btn btn-outline-success me-2" ngbTooltip="Exportar excel" (click)="exportarExcel()">
                        <i class="fa fa-file-excel-o" aria-hidden="true"></i>
                        Exportar
                    </button>
                </li>

                <li class="nav-item me-2">
                    <button [disabled]="this.descargandoPdf" ngbTooltip="Descargar Pdf" class="btn btn-outline-danger" (click)="descargarPdf()">
                        <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
                        Descargar
                    </button>
                </li>                
            </ul>            
        </nav>

        <div class="col-12" id="contenedor" *ngIf="this.remitosRanchos != null">
            <table class="table table-hover">
                <thead class="text-center">
                    <th>Fecha</th>
                    <th>Hora</th>
                    <th>Nº Remito</th>
                    <th>Nº Aerovale</th>
                    <th>Cliente</th>                    
                    <th>Volumen</th>
                    <th *ngIf="mostrarAnulados">Fecha Anulación</th>
                    <th>Ver Aerovale</th>                    
                    <th>Ver Factura</th>  
                    <th *ngIf="this.hasRole(TipoUsuario.DESPACHANTE)">Accion</th>                  
                </thead>
                <tbody class="text-center">
                    <tr [ngClass]="{
                            'rojo': r.estado==EstadoConsumo.Desconocido,  
                            'verde': r.estado==EstadoConsumo.Reconocido }" 
                        *ngFor="let r of this.remitosFiltrados; let i = index">
                        <td>{{r.fecha | date: "dd'/'MM'/'y" }}</td>                    
                        <td>{{r.fecha | date: "hh:mm a" }}</td>
                        <td>{{r.remitoPrefijo | remitoPrefijo }}-{{r.remitoNumero | remitoNumero }}</td>
                        <td>{{r.aerovalePrefijo | aerovalePrefijo }}{{r.aerovaleNumero | aerovaleNumero }}</td>
                        <td>{{r.cliente }}</td>                                                 
                        <td>{{r.volumen | number: '1.0-0' }} LTS</td>
                        <td *ngIf="mostrarAnulados">{{r.fechaAnulacion | date: "dd'/'MM'/'y" }}</td>   
                        <td>
                            <button *ngIf="r.aerovaleId != null" class="btn" (click)="verAerovale(r.aerovaleId, i)" ngbTooltip="Ver aerovale">
                                <i *ngIf="!obteniendoAerovale || obteniendoAerovale && aerovaleSeleccionado!=i" class="fa fa-eye" aria-hidden="true"></i>
                                <div *ngIf="aerovaleSeleccionado==i && obteniendoAerovale">
                                    <div class="spinner-border text-primary spinner-border-sm" role="status"></div>
                                    <span><i class="fa fa-eye" aria-hidden="true"></i></span>
                                </div>
                            </button>                            
                        </td>                                  
                        <td>
                            <button *ngIf="r.facturaId != null" class="btn" (click)="verFactura(r.facturaId, i)" ngbTooltip="Ver factura">
                                <i *ngIf="!obteniendoFactura || obteniendoFactura && facturaSeleccionada!=i" class="fa fa-eye" aria-hidden="true"></i>
                                <div *ngIf="facturaSeleccionada==i && obteniendoFactura">
                                    <div class="spinner-border text-primary spinner-border-sm" role="status"></div>
                                    <span><i class="fa fa-eye" aria-hidden="true"></i></span>
                                </div>
                            </button>                             
                        </td>
                        <td *ngIf="this.hasRole(TipoUsuario.DESPACHANTE)">                        
                            <button class="btn btn-warning my-2 my-lg-0 mx-lg-2" (click)="marcarRancho(r)">
                                <i ngbTooltip="Marcar Reconocido/NO reconocido" class="fa fa-pencil" aria-hidden="true"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

