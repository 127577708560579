import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { PedidoService } from '../pedido.service';
import { UsuarioService } from 'src/app/account/usuario.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'src/app/shell/message.service';
import { Pedido } from 'src/app/shared/models/despacho/Pedido';
import { PedidoDetalle } from 'src/app/shared/models/despacho/PedidoDetalle';
import { EstadoPedido } from 'src/app/shared/enums/estadoPedido';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { fadeAnimationTrigger } from 'src/app/shared/animations';
import { NuevaPrecargaComponent } from '../nueva-precarga/nueva-precarga.component';
import { Subscription } from 'rxjs';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { NuevoAerovaleComponent } from '../nuevo-aerovale/nuevo-aerovale.component';
import { TipoPedido } from 'src/app/shared/enums/TipoPedido';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-precargas',
  templateUrl: './precargas.component.html',
  styleUrls: ['./precargas.component.css'],
  animations: [fadeAnimationTrigger]
})
export class PrecargasComponent implements OnInit, OnDestroy {

  @Output() cancelado = new EventEmitter();
  @Output() nuevoPedidoCreado = new EventEmitter();
  @Output() nuevoPedidoModificado = new EventEmitter();

  TipoUsuario = TipoUsuario;
  TipoPedido = TipoPedido;
  
  showOrderForm: boolean;
  showPedidoDetalle: boolean;
  showOrderModify: boolean = false; 

  pedidos: Pedido[];
  obteniendoPedidos: boolean = true;
  actualizandoPedidos: boolean = false;
  errorPedidos: boolean = false;
  pedidoDetalle: PedidoDetalle;
  recienAnulado: boolean;
  recienCreado: boolean;
  page = 1;
  pageSize = 20;
  collectionSize = 0;
  change$: Subscription;
  @Output() hayPedidos = new EventEmitter<string>();

  EstadoPedido = EstadoPedido;

  constructor(private pedidoService: PedidoService,
    private usuarioService: UsuarioService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private messageService: MessageService,
    private authService: AuthService,
    sharedService: SharedService
  ) { 
    this.change$ = sharedService.aeroplantaSeleccionada.subscribe(_=>{ 
      this.cambiarDeAeroplanta()
    });
  }

  ngOnInit() {
    this.pedidos = [];
    this.getPedidosPage(this.page, this.pageSize);
    this.spinner.show('spinnerDetalle');    
  }

  ngOnDestroy(): void {
    this.change$.unsubscribe();
    this.messageService.clear();
  }

  cambiarDeAeroplanta() {
    this.reload(false);
    this.obteniendoPedidos = true;
    this.page = 1;
    this.spinner.show('spinnerDetalle');
  }

  public onPageChange = (pageNumber) => {
    this.pedidos = null;
    this.getPedidosPage(pageNumber, this.pageSize, true);
  }

  nuevoPedido() {
    this.showOrderForm = true;
    this.pedidoDetalle = null;
    this.recienAnulado = false;
    this.recienCreado = false;
  }

  onClick(pedidoId: string) {
    this.spinner.show('spinnerDetalle');
    this.recienAnulado = false;
    this.showOrderForm = false;
    this.showPedidoDetalle = true;
    this.pedidoService.getPedidoPorId(pedidoId)
      .subscribe(pedidoDetalle => {
        this.spinner.hide('spinnerDetalle');
        this.pedidoDetalle = pedidoDetalle;
        this.pedidoDetalle.precargas = this.pedidoDetalle.precargas.filter(a => a.estado != EstadoPedido.Anulado)
      });
  }

  onSelect(pedidoId: string) {
    this.recienAnulado = false;
    this.showOrderForm = false;
    this.showPedidoDetalle = true;
    this.spinner.show('spinnerDetalle');
    this.pedidoService.getPedidoPorId(pedidoId)
      .subscribe(pedidoDetalle => {
        this.pedidoDetalle = pedidoDetalle;
        this.pedidoDetalle.precargas = this.pedidoDetalle.precargas.filter(a => a.estado != EstadoPedido.Anulado)
        this.spinner.hide('spinnerDetalle');
      });
  }

  getPedidosPage(page: number, size: number, actualizacion?: boolean): void {
    if (actualizacion) {
      this.actualizandoPedidos = true;
    }
    this.errorPedidos = false;
    this.pedidoService.getPedidosPagina(page, size)
      .subscribe(
        pageList => {
          if (actualizacion)
            this.actualizandoPedidos = false;
          else
            this.spinner.hide('spinnerDetalle');
          this.page = pageList.pageNumber;
          this.pageSize = pageList.pageSize;
          this.collectionSize = pageList.totalItemCount;
          this.pedidos = pageList.items;
          this.obteniendoPedidos = false;
        }, //Que no estén anulados
        err => {
          this.messageService.showErrorMessage("Hubo un error obteniendo los pedidos. Intenta de nuevo");
          this.obteniendoPedidos = false;
          this.errorPedidos = true;
          console.log(err);
        });
  }

  onNuevoPedidoCreado(pedido) {
    this.messageService.showSuccessMessage("Pedido generado exitosamente");
    console.log('pedido nuevo: ' + pedido);
    this.pedidos = [];
    this.obteniendoPedidos = true;
    this.getPedidosPage(1, this.pageSize);
    this.showOrderForm = false;
    this.recienAnulado = false;
    this.recienCreado = true;
  }

  onAgregarPrecargaClick(pedido: Pedido, event: MouseEvent) {
    const modalRef = this.modalService.open(NuevaPrecargaComponent, { centered: true, backdrop: 'static' });
    modalRef.componentInstance.pedido = pedido;
    modalRef.componentInstance.cantidadMaxima = pedido.cantidadFaltante;
    modalRef.componentInstance.cantidadSugerida = pedido.cantidadFaltante;
    modalRef.result.then((precarga) => {
      this.getPedidosPage(this.page, this.pageSize, true);
    }
    ).catch(() => { });
    event.stopPropagation();
  }

  onNuevoPedidoCancelado() {
    this.reload(true);
  }

  onNuevoPedidoModificadoCancelado() {
    this.showOrderModify = false;
    this.reload(true);
  }

  onPrecargaVolver() {
    this.reload(true);
  }

  reload(actualizacion: boolean) {
    this.showOrderForm = false;
    this.recienAnulado = false;
    this.recienCreado = false;
    this.pedidoDetalle = null;
    this.pedidos = null;
    this.getPedidosPage(this.page, this.pageSize, actualizacion);
  }

  onPedidoCerradoSuccess() {
    this.messageService.showSuccessMessage("Pedido cerrado exitosamente ");
    this.reload(true);
  }

  hasRole(...roles: TipoUsuario[]) {
    return this.authService.hasRole(...roles);
  }

  noEsAlije(tipo:TipoPedido) {
    return tipo != TipoPedido.Alije;
  }

  getDescripcionTipo(tipoPedido){
    if(tipoPedido == TipoPedido.Alije){
      return "Devolución Alije";
    }else if(tipoPedido == TipoPedido.Tams){
      return "Tams";
    }else if(tipoPedido == TipoPedido.Manual){
      return "Manual";
    }else{
      return "-";
    }
  }
}
