<nav class="navbar navbar-light bg-light navbar-expand-lg mb-3">
  <div class="col-sm-12">
    <button class="btn btn-secondary float-end" (click)="cancelar()">Volver</button>
  </div>
</nav>
<form [formGroup]="tablaTanques">
  <label class="col-form-label"><b>Mediciones de tanques.</b></label>
  <table id="tabla" class="table table-sm table-striped table-hover header_fijo">
    <thead class="text-center">
      <tr>
        <th>Tanque</th>
        <th [width]="150">Fecha</th>
        <th>Hora</th>
        <th>Varillado MM.</th>
        <th>Cant. Natural</th>
        <th>Cant. 15°</th>
        <th>Temperatura</th>
        <th>Densidad</th>
        <th>Nivel de agua</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let t of tanques; let i = index; let j = index" class="text-center">
        <td>{{t.nombre}} - {{t.productoActual.nombreProducto}}</td>
        <td formArrayName="tanques">
          <input type="date" name="dt" [(ngModel)]="tanques[i].fechaProvisoria" formControlName="{{i}}"
            value="tanques[i].fechaProvisoria" max="{{fechaLimite | date:'yyyy-MM-dd'}}"  ngbDatepicker #d="ngbDatepicker" class="form-control" Datepicker />
        </td>
        <td formArrayName="hora">
          <ngb-timepicker [(ngModel)]="this.tanques[i].horaCarga" formControlName="{{i}}" [meridian]="true"
            [readonlyInputs]="false" [spinners]="false">
          </ngb-timepicker>
        </td>
        <td>
          <input class="form-control" (keyup)="changeValue(i, 'varillado', $event)"
            (blur)="recalcularVolumenes(this.tanques[i])" appOnlyDecimal
            [value]="this.tanques[i].mediciones.varillado ? this.tanques[i].mediciones.varillado : 0" maxlength="10">
        </td>
        <td>
          <input class="form-control" [disabled]="true"
            [value]="this.tanques[i].mediciones.nivelNatural ? this.tanques[i].mediciones.nivelNatural : 0">
        </td>
        <td>
          <input class="form-control" [disabled]="true"
            [value]="this.tanques[i].mediciones.nivel ? this.tanques[i].mediciones.nivel : 0">
        </td>
        <td>
          <input class="form-control" (keyup)="changeValue(i, 'temperatura', $event)"
            (blur)="recalcularVolumenNatural(this.tanques[i])" appOnlyDecimalNegativa
            [value]="this.tanques[i].mediciones.temperatura ? this.tanques[i].mediciones.temperatura : 0" maxlength="3">
          <div class="alert alert-danger letra" *ngIf="!validarTemperatura(this.tanques[i].mediciones.temperatura)">
            La temperatura no puede ser mayor a 100° ó menor a -50°.
          </div>
        </td>
        <td>
          <input class="form-control" 
            (input)="onBlurDensidad($event.target, i)" 
            (paste)="onPaste($event, i)"
            ngbTooltip="La densidad debe ser un valor entre {{this.esDensidadTanqueMin[i]}} y {{this.esDensidadTanqueMax[i]}}" 
            (keypress)="formatDensidad($event, i)" 
            [value]="this.cadenaTanqueDensidad[i] ? this.cadenaTanqueDensidad[i] : 0" maxlength="10">
          <div class="alert alert-danger letra" *ngIf="!this.validarDensidadCambios(this.tanques[i].mediciones.densidad, i)">
            La densidad debe estar en el rango [{{this.esDensidadTanqueMin[i]}} , {{this.esDensidadTanqueMax[i]}}]
          </div>
        </td>
        <td>
          <input class="form-control" (keyup)="changeValue(i, 'nivelAgua', $event)"
            (blur)="recalcularVolumenes(this.tanques[i])" appOnlyDecimal
            [value]="this.tanques[i].mediciones.nivelAgua ? this.tanques[i].mediciones.nivelAgua : 0" maxlength="10">
        </td>
      </tr>
    </tbody>
  </table>

  <form [formGroup]="tablaAbastecedora">
    <label class="col-form-label"><b>Mediciones de abastecedoras.</b></label>
    <table id="tabla" class="table table-sm table-striped table-hover header_fijo">
      <thead class="text-center">
        <tr>
          <th>Abastecedora</th>
          <th [width]="150">Fecha</th>
          <th>Hora</th>
          <th>Varillado Lts.</th>
          <th>Cant. Natural</th>
          <th>Cant. 15°</th>
          <th>Temperatura</th>
          <th>Densidad</th>
          <th>Nivel de agua</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let a of abastecedoras; let i = index; let j = index" class="text-center">
          <td>{{abastecedoras[i].nombre}} - {{abastecedoras[i].productoActual.nombreProducto}}</td>
          <td formArrayName="abastecedoras">
            <input type="date" name="dt" [(ngModel)]="abastecedoras[i].fechaProvisoria" formControlName="{{i}}"
              value="abastecedoras[i].fechaProvisoria" max="{{fechaLimite | date:'yyyy-MM-dd'}}" ngbDatepicker #d="ngbDatepicker" class="form-control"
              Datepicker />
          </td>
          <td formArrayName="horaAbastecedora">
            <ngb-timepicker [(ngModel)]="this.abastecedoras[i].horaCarga" formControlName="{{i}}" [meridian]="true"
              [readonlyInputs]="false" [spinners]="false">
            </ngb-timepicker>
          </td>
          <td>
            <input class="form-control" (keyup)="changeValue2(i, 'varillado', $event)"
              (blur)="recalcularVolumen15Abastecedora(this.abastecedoras[i])" appOnlyDecimal
              [value]="this.abastecedoras[i].mediciones.varillado ? this.abastecedoras[i].mediciones.varillado : 0" maxlength="10">
          </td>
          <td>
            <input class="form-control" [disabled]="true"
              [value]="this.abastecedoras[i].mediciones.nivelNatural ? this.abastecedoras[i].mediciones.nivelNatural : 0">
          </td>
          <td>
            <input class="form-control" [disabled]="true"
              [value]="this.abastecedoras[i].mediciones.nivel ? this.abastecedoras[i].mediciones.nivel : 0">
          </td>
          <td>
            <input class="form-control" (keyup)="changeValue2(i, 'temperatura', $event)"
              (blur)="recalcularVolumen15Abastecedora(this.abastecedoras[i])" appOnlyDecimalNegativa
              [value]="this.abastecedoras[i].mediciones.temperatura ? this.abastecedoras[i].mediciones.temperatura : 0" maxlength="2">
            <div class="alert alert-danger letra"
              *ngIf="!validarTemperatura(this.abastecedoras[i].mediciones.temperatura)">
              La temperatura no puede ser mayor a 40°.
            </div>
          </td>
          <td>
            <input class="form-control" 
              (input)="onBlurDensidadAbastecedora($event.target, i)"
              (paste)="onPasteAbastecedora($event, i)"
              ngbTooltip="La densidad debe ser un valor entre {{this.esDensidadMin[i]}} y {{this.esDensidadMax[i]}}"
              (keypress)="formatDensidadAbastecedora($event, i)"
              [value]="this.cadenaAbastecedoraDensidad[i] ? this.cadenaAbastecedoraDensidad[i] : 0">
            <div class="alert alert-danger letra" *ngIf="!this.validarDensidadCambiosAbastecedora(this.abastecedoras[i].mediciones.densidad, i)" maxlength="10">
              La densidad debe estar en el rango [{{this.esDensidadMin[i]}} , {{this.esDensidadMax[i]}}]
            </div>
          </td>
          <td>
            <input class="form-control" (keyup)="changeValue2(i, 'nivelAgua', $event)"
              (blur)="recalcularVolumen15Abastecedora(this.abastecedoras[i])" appOnlyDecimal
              [value]="this.abastecedoras[i].mediciones.nivelAgua ? this.abastecedoras[i].mediciones.nivelAgua : 0" maxlength="10">
          </td>
        </tr>
      </tbody>
    </table>
    <div class="float-end">
      <button class="btn btn-primary" (click)="guardarMedicionesMasivas()">
        <span *ngIf="!guardandoMediciones">Guardar</span>
        <div *ngIf="guardandoMediciones" class="d-flex align-items-center">
          <div class="spinner-border text-light spinner-border-sm" role="status"></div>
          <span>Guardando...</span>
        </div>
      </button>
    </div>
  </form>