<div class="modal-header">Nueva configuración</div>
<div class="modal-body">
    <div>Seleccione una ciudad:</div>
    <select [disabled]="obteniendoCiudades" class="form-select" [(ngModel)]="ciudadSeleccionada">
        <option *ngFor="let ciudad of ciudades" [ngValue]="ciudad">{{ciudad.nombre}}</option>
    </select>
    <loading-text-spinner textColor="dark" color="primary" *ngIf="obteniendoCiudades" message="Obteniendo ciudades"></loading-text-spinner>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" [disabled]="!ciudadSeleccionada" (click)="onCrearClicked()">
        Agregar ciudad</button>
    <button type="button" class="btn btn-secondary" (click)="onVolverClicked()">Volver</button>
</div>