import { Component, OnInit, Input, NgModule, SimpleChanges } from '@angular/core';
import { formatDate, Location } from '@angular/common';
import { Comprobante } from 'src/app/shared/models/facturacion/Comprobante';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { HttpHeaders } from '@angular/common/http';
import { ComprobanteService } from 'src/app/facturacion/comprobante.service';
import { SharedService } from '../shared.service';
import { Rubro } from '../models/despacho/Rubro';
import { NgbDateStruct, NgbDropdownItem, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MessageService } from 'src/app/shell/message.service';
import { VisorImpuestoComponent } from '../visor-impuesto/visor-impuesto.component';
import { ImpuestoPercepcion } from '../models/facturacion/ImpuestoPercepcion';
import { TipoMoneda } from '../enums/tipoMoneda';
import { UsuarioService } from 'src/app/account/usuario.service';
import { Aeroplanta } from '../models/despacho/Aeroplanta';
import { CondicionVentaFactura } from '../enums/condicionVentaFactura';
import { TipoDocumento } from '../enums/tipoDocumento';
import { NotificacionesService } from 'src/app/abm/notificacion/notificaciones.service';
import { NotificacionesComprobantes } from '../models/notificaciones/NotificacionesComprobantes';
import { ErrorModel } from '../models/ErrorModel';
import { EstadoFactura } from '../enums/EstadoFactura';
import { TipoMovimiento } from '../models/facturacion/TipoMovimiento';
import { Renglon } from '../models/facturacion/Renglon';
import { VisorCuponComponent } from '../visor-cupon/visor-cupon.component';

@Component({
  selector: 'app-visor-comprobante',
  templateUrl: './visor-comprobante.component.html',
  styleUrls: ['./visor-comprobante.component.css']
})
export class VisorComprobanteComponent implements OnInit {

  cuit: string;
  columnas = [];
  mensajeSpinner: string;
  esDolar: boolean = false;
  monedaPalabra: string = '';
  rubros: Rubro[] = [];
  array: any[] = [];
  string: string = '';
  efectivo: number;
  elementType = 'url';
  
  @Input() facturaObtenida: Comprobante;
  @Input() botones: boolean = true;

  impresionTermica: boolean = true;
  mostrarMensajeFacturacionEnPruebas: boolean = false;
  descargando: boolean = false;
  reenviando: boolean = false;
  mensajeError: string;

  aeroplanta: Aeroplanta[] = [];
  EstadoFactura=EstadoFactura;
  visualizarCupones=false;

  constructor(
    private comprobanteService: ComprobanteService,
    private notificacionesService: NotificacionesService, 
    private messageService: MessageService,
    private location: Location, 
    public activeModal: NgbModal,
    private spinner: NgxSpinnerService,
    private usuarioService: UsuarioService) { }

  ngOnInit() {
    this.mostrarMensajeFacturacionEnPruebas = this.usuarioService.getAeroplantaActual().habilitarFacturacion && (! this.usuarioService.getAeroplantaActual().soloPAD);
    
    setTimeout(() => {
    }, 3000);
    this.spinner.show('spinnerLista');
    switch (this.facturaObtenida.tipoMovimiento) {
      case (2):
      case (7): {
        this.columnas = [
          { field: 'Rubro', header: 'Rubro' },
          { field: 'Detalle', header: 'Descripción' },
          { field: 'Cantidad', header: 'Cant.' },
          { field: 'UMedida', header: 'UM' },
          { field: 'PUnitario', header: 'Valor Unitario' },
          { field: 'Total', header: 'Total' },
        ];
        this.getRubros();
        break;
      }
      default: {
        this.columnas = [
          { field: 'Codigo', header: 'Cod.' },
          { field: 'Detalle', header: 'Descripción' },
          { field: 'Cantidad', header: 'Cant.' },
          { field: 'UMedida', header: 'UM' },
          { field: 'PUnitario', header: 'Valor Unitario' },
          { field: 'Total', header: 'Total' },
        ];
      }
    }
    this.facturaObtenida.tipoMoneda == 0 ? this.monedaPalabra = 'PESOS' : this.monedaPalabra = 'DOLARES';
    this.mensajeSpinner = "Descargando..."
    this.renglones();
    this.calcularEfectivo();
    this.visualizarCupones=this.facturaObtenida.factura.pago[0].cupones.filter(c=>c.pathCuponFisico!='').length>0;
    setTimeout(() => {
      this.spinner.hide('spinnerLista');
    }, 5000);
  }

  renglones() {
    this.string = '';
    for (let i = 0; this.facturaObtenida.factura.leyendas.length > i; i++) {
      if (this.facturaObtenida.factura.leyendas[i].frame == 0 && this.facturaObtenida.factura.leyendas[i].activo) {
        this.string = `${this.string}` + `${this.facturaObtenida.factura.leyendas[i].descripcion}` + '\n';
      }
    }
    this.string = this.string.slice(0, 884);
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.facturaObtenida.firstChange) {
      this.ngOnInit();
    }

    this.efectivo = 0;
    this.calcularEfectivo();
  }


  calcularEfectivo() {
    this.efectivo = 0;
    this.efectivo = this.facturaObtenida.factura.total;
    if (this.facturaObtenida.factura.pago.length > 0) {
      for (let i = 0; this.facturaObtenida.factura.pago.length > i; i++) {
        for (let j = 0; this.facturaObtenida.factura.pago[i].cupones.length > j; j++) {
          this.efectivo = this.efectivo - this.facturaObtenida.factura.pago[i].cupones[j].importe;
        }
      }
    }
  }

  getRubros() {
    this.comprobanteService.getRubros()
      .subscribe(result => {
        (this.rubros = result);
        for (let i = 0; this.rubros.length > i; i++) {
          for (let j = 0; this.facturaObtenida.factura.renglones.length > j; j++) {
            if (this.rubros[i].id == this.facturaObtenida.renglones[j].rubro.id) {
              this.facturaObtenida.renglones[j].rubro.nombre = this.rubros[i].nombre;
            }
          }
        }
      });
  }

  formatearNroDocumento(tipoDoc: number, nroDoc: string) {
    if(tipoDoc == TipoDocumento.CUIL || tipoDoc == TipoDocumento.CUIT){
      if (nroDoc.includes("-")){
        return (nroDoc)
      }else{
        nroDoc = nroDoc.substr(0, 2) + - + nroDoc.substr(2, 8) + - + nroDoc.substr(10);
        return (nroDoc)
      }    
    }else{
      return nroDoc;
    }
  }

  leyendaTipoDocumento(tipoDoc: number){
    if(tipoDoc == TipoDocumento.CUIT){
      return "CUIT";
    }else if(tipoDoc == TipoDocumento.CUIL){
      return "CUIL";
    }else if(tipoDoc == TipoDocumento.DNI){
      return "DNI";
    }else if(tipoDoc == TipoDocumento.Extranjero){
      return "DOCUMENTO";
    }
  }

  obtenerTipoComprobante(tipoMovimiento: number) {
    switch (tipoMovimiento) {
      case (1):
      case (3):
      case (2): {
        return 'A'
      }
      case (6):
      case (8):
      case (7): {
        return 'B';
      }
      case (9): {
        return 'R';
      }
      case (19):
      case (20):
      case (21): {
        return 'E'
      }
    }
  }

  goBack() {
    this.location.back(); // <-- go back to previous location on cancel
  }

  obtenerTipos(tipoRubro: number) {
    switch (tipoRubro) {
      case (0):
        return 'Conceptos financieros';
      case (1):
        return 'Combustible';
      case (2):
        return 'Lubricante';
      case (3):
        return 'Mercaderia';
      case (4):
        return 'Cigarrillos';
      case (5):
        return 'No Gravado';
    }

  }

  onClickExport(f:Comprobante) {
    this.spinner.show('spinnerExportar');
    this.comprobanteService.exportarRemitosDeFactura(f.prefijo,f.numeroComprobante,f.tipoMovimiento)
      .subscribe((res: Blob) => {
        const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
        saveAs(blob, "factura" + f.prefijo + "-" + f.numeroComprobante + ".xlsx");
        this.spinner.hide('spinnerExportar');
      }, ()=> {
        this.spinner.hide('spinnerExportar');
      });
  }

  clienteDiscriminaImpuestos() {
    if (this.facturaObtenida.cliente.condicionIvaDiscrimina) {
      return true;
    } else {
      return false;
    }
  }

  clienteExento() {
    if (this.facturaObtenida != null && this.facturaObtenida.cliente.condicionIvaCodigoAfip == '02') {
      return true
    } else {
      return false
    }
  }

  obtenerPorcentaje(leyenda: string): number {
    for (let reng of this.facturaObtenida.factura.renglones) {
      for (let imp of reng.impuestosPercepciones) {
        if (imp.leyenda == leyenda) {
          return imp.valor;
        }
      }
    }
  }

  onClickView() {
    let renglones: Renglon[] = [];
    let impuestos: ImpuestoPercepcion[] = [];
    
    this.facturaObtenida.factura.renglones.forEach((value, index) => {
      renglones.push(this.facturaObtenida.factura.renglones[index]);
      this.facturaObtenida.factura.renglones[index].impuestosPercepciones.forEach((value2, index2) => {
        impuestos.push(this.facturaObtenida.factura.renglones[index].impuestosPercepciones[index2]);
      });
    });
    let modalRef = this.activeModal.open(VisorImpuestoComponent, { size: 'lg', centered: true, backdrop: 'static' })
    modalRef.componentInstance.renglones = renglones;
  }

  verCupones() {
    let modalRef = this.activeModal.open(VisorCuponComponent, { size: 'lg', centered: true, backdrop: 'static' })
    modalRef.componentInstance.cupones = this.facturaObtenida.factura.pago[0].cupones;
  }

  incluirPorcentajeImpuesto(leyenda: string) {
    if(leyenda.includes('ITC') || leyenda.includes('IDC')){
      return false;
    }else{
      return true;
    }
  }


  onClickDownload(f:Comprobante) {
    this.descargando = true;
    this.comprobanteService.descargarFactura(f)
      .subscribe((res: Blob) => {
        const blob = new Blob([res], { type: 'application/pdf' });
        saveAs(blob, f.prefijo + '-' + f.numeroComprobante + ' ' + f.fechaHoraCreacion + '.pdf');
        this.descargando = false;
      }, () => {
        this.descargando = false;
      });
  }

  reenviarCorreo(){
    let email: any;
    this.mensajeError = null;
    this.reenviando = true;
    let comprobanteNotificaciones = new NotificacionesComprobantes();
    comprobanteNotificaciones.facturaId = this.facturaObtenida.id;
    comprobanteNotificaciones.numeroDocumento = this.facturaObtenida.cliente.numeroDocumento;
    comprobanteNotificaciones.codigoAeroplanta = this.facturaObtenida.codigoAeroplanta;
    comprobanteNotificaciones.tipoMovimiento = this.facturaObtenida.tipoMovimiento;
    comprobanteNotificaciones.prefijo = this.facturaObtenida.prefijo;
    comprobanteNotificaciones.numero = this.facturaObtenida.numeroComprobante;
    this.notificacionesService.enviarFactura(comprobanteNotificaciones)
      .subscribe((result) => {
        email = result;
        this.messageService.showSuccessLongMessage('El comprobante se reenvío a los correos: ' + email.item1);
        this.reenviando = false;
      }, (err: ErrorModel) => {
        this.messageService.showErrorLongMessage(err.description);
        this.reenviando = false;
      });
  }

  get esElectrica() {
    return environment.electrica
  }
}

