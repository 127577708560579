export enum TipoMovimientoStock {
    Despacho = 0,
    DeapchoInterno = 1,
    AjusteManual = 2,
    Transferencia = 3,
    Rebombeo = 4,
    Alije = 5,
    Recepcion = 6,
    Salida = 8
}
