<!--ventana nuevo movimiento.-->
<div class="modal bd-modal-xl" tabindex="-1" role="dialog" aria-labelledby="myExtraLargeModalLabel"
  [formGroup]="movimientoForm" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title ">Nuevo Movimiento</h5>
        <button type="button" class="btn-close" aria-label="Close" data-dismiss="modal" (click)="limpiarForm()">
          
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="movimientoForm">
          <div class="row">
            <!-- tipo movimiento -->
            <div class="mb-3 col-md-6">
              <div class="mb-3 row">
                <label for="tipoMovimientoFormControl" id="labelTipoMovimiento" class="col-sm-6 col-form-label">*Tipo de
                  movimiento:</label>
                <div class="col-sm-6">
                  <div class="input-group mb-2">
                    <select id="tipoMovimientoFormControl" type="text" class="form-select" name="tipoMovimientoID"
                      formControlName="tipoMovimientoFormControl" (change)="ocultarElementos()"
                      title="Seleccione un tipo de movimiento.">
                      <option [value]="null" disabled defaultselected>Seleccione una opción...</option>
                      <option id="optionTipoMovimiento" *ngFor="let tipo of keys()" [ngValue]="tipoMovimiento[tipo]">
                        {{tipo}}
                      </option>
                      <!-- <div *ngIf="movimientoCarga.invalid && movimientoCarga.errors.required && (movimientoCarga.dirty ||
                        movimientoCarga.touched)" class="alert alert-danger">Requerido
                      </div> -->
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <!-- remito cargamento -->
            <div class="mb-3 col-md-6">
              <div class="mb-3 row">
                <label for="remitoFormControl" id="labelNumeroRemito" class="col-sm-6 col-form-label">*N° Remito
                  cargamento:</label>
                <label for="remitoFormControl" id="labelAjusteNumeroRemito" class="col-sm-6 col-form-label"
                  style="display: none;">N° Remito
                  cargamento:</label>
                <label for="aforadorInicialFormControl" id="labelAforadorInicial" class="col-sm-6 col-form-label"
                  style="display: none;">*Aforador inicial:</label>
                <div class="col-sm-6">
                  <div class="input-group mb-2">
                    <input placeholder="Numero de remito" id="remitoFormControl" class="form-control"
                      formControlName="remitoFormControl" value="numRemito"
                      title="Ingrese el numero de remito. Campo requerido." />
                    <input (keypress)="onlyDecimalNumberKey($event)" id="aforadorInicialFormControl" type="text"
                      placeholder="Aforador inicial" value="numeroAforadorInicial" class="form-control"
                      title="Ingrese el aforador inicial." (blur)="chequearDisponibilidadOrigen()"
                      formControlName="aforadorInicialFormControl" style="display: none;" />
                  </div>
                  <!-- <loading-text-spinner *ngIf="obteniendoAforador" [error]="errorDestinos"
                    message="Obteniendo aforador..." color="primary">
                  </loading-text-spinner> -->
                </div>
              </div>
            </div>
            <!-- origen movimiento-->
            <div class="mb-3 col-md-6">
              <div class="mb-3 row">
                <label for="origenFormControl" id="origenFormControl" class="col-sm-6 col-form-label">*Origen:</label>
                <label for="destinoAjusteFormControl" id="destinoAjusteFormControl" class="col-sm-6 col-form-label"
                  style="display: none;">*Destino:</label>
                <div class="col-sm-6">
                  <div class="input-group mb-2">
                    <select id="origenFormControl" type="text" class="form-select"
                      title="Seleccione el origen desde el cual enviar el combustible."
                      formControlName="origenFormControl" (change)="chequearDisponibilidadOrigen()"
                      (blur)="filtrarContenedores()">
                      <option [value]="null" disabled defaultselected>Seleccione una opción...</option>
                      <option [value]="conten.contenedor.id" *ngFor="let conten of contenedoresDeUnProducto">
                        {{conten.contenedor.nombre}}
                      </option>
                      <!-- <div *ngIf="origen.invalid && origen.errors.required && (origen.dirty || origen.touched)"
                        class="alert alert-danger">Requerido</div> -->
                    </select>
                  </div>
                  <!-- <loading-text-spinner *ngIf="cargandoOrigen" [error]="errorDestinos"
                    message="Obteniendo contenedores..." color="primary">
                  </loading-text-spinner> -->
                </div>
              </div>
            </div>
            <!-- destino movimiento-->
            <div class="mb-3 col-md-6">
              <div class="mb-3 row">
                <label for="destinoFormControl" id="labelDestino" class="col-sm-6 col-form-label">*Destino:</label>
                <label for="aforadorFinalFormControl" id="labelAforadorFinal" class="col-sm-6 col-form-label"
                  style="display: none;">*Aforador final:</label>
                <label for="aforadorFinalFormControl" id="labelAjusteAforadorFinal" class="col-sm-6 col-form-label"
                  style="display: none;">Aforador final:</label>
                <div class="col-sm-6">
                  <div class="input-group mb-2">
                    <select id="destinoFormControl" type="text" class="form-select"
                      title="Seleccione el destino al cual enviar el combustible."
                      (change)="chequearDisponibilidadOrigen()" formControlName="destinoFormControl">
                      <option [value]="null" disabled defaultselected>Seleccione una opción...</option>
                      <option [value]="cont.contenedor.id"
                        *ngFor="let cont of contenedoresDeUnProductoDestino">
                        {{cont.contenedor.nombre}}
                      </option>
                    </select>
                    <input (keypress)="onlyDecimalNumberKey($event)" id="aforadorFinalFormControl" type="text"
                      title="Ingrese el numero del afordor final." placeholder="Aforador Final" class="form-control"
                      formControlName="aforadorFinalFormControl" style="display: none;" value="numeroAforadorFinal"
                      (blur)="chequearDisponibilidadOrigen()" />
                  </div>
                  <!-- <div *ngIf="destino.invalid && destino.errors.required && (destino.dirty || destino.touched)"
                    class="alert alert-danger">Requerido</div>
                  <loading-text-spinner *ngIf="cargandoDestinos" [error]="errorDestinos"
                    message="Obteniendo contenedores..." color="primary">
                  </loading-text-spinner> -->
                </div>
              </div>
            </div>
            <!-- cantidad lts -->
            <div class="mb-3 col-md-6">
              <div class="mb-3 row">
                <label for="cantidadFormControl" class="col-sm-6 col-form-label">*Cantidad (Lts):</label>
                <div class="col-sm-3">
                  <div class="input-group mb-2">
                    <input (keypress)="onlyDecimalNumberKey($event)" id="cantidadFormControl" type="text"
                      placeholder="Litros" value="LtsCantidad" (blur)="chequearDisponibilidadOrigen()"
                      title="Ingrese la cantidad de combustible para ajustar, transferir o rebombear."
                      class="form-control" formControlName="cantidadFormControl" />
                    <!-- <div
                      *ngIf="cantidadLts.invalid && cantidadLts.errors.required && (cantidadLts.dirty || cantidadLts.touched)"
                      class="alert alert-danger">Requerido</div> -->
                  </div>
                </div>
              </div>
            </div>
            <!-- Temperatura -->
            <div class="mb-3 col-md-6">
              <div class="mb-3 row">
                <label for="temperaturaFormControl" id="temperaturaAjusteFormControl"
                  class="col-sm-6 col-form-label">Temperatura (°C):</label>
                <div class="col-sm-3">
                  <div class="input-group mb-2">
                    <input id="temperaturaFormControl" type="text" (keypress)="onlyDecimalNumberKey($event)"
                      title="Ingrese la temperatura entre 1° y 100°." value="numTemperatura" class="form-control"
                      formControlName="temperaturaFormControl" placeholder="°C" />
                  </div>
                </div>
              </div>
            </div>
            <!-- Descripción en ajuste manual-->
            <div class="mb-3 col-md-12">
              <div class="mb-3 row">
                <div class="col-sm-3">
                  <label for="motivoFormControl" class="col-sm-6 col-form-label" id="labelmotivoFormControl"
                    style="display: none;">*Motivo:</label>
                </div>
                <div class="col-sm-8">
                  <div class="input-group mb-2">
                    <textarea id="motivoFormControl" type="text" value="motivoAjuste" class="form-control"
                      style="display: none;" maxlength="254" formControlName="motivoFormControl"
                      placeholder="Motivo del ajuste manual...">
                        </textarea>
                  </div>
                  <!-- <div *ngIf="motivo.invalid && motivo.errors.required && (motivo.dirty || motivo.touched)"
                    class="alert alert-danger">Requerido
                  </div> -->
                </div>
              </div>
            </div>
            <!--Cuadro de error-->
            <div class="mb-3 col-md-12">
              <div class="mb-3 row justify-content-md-center">
                <div class="col-md-6">
                  <!-- <ul id="cuandroError"
                    *ngIf="(this.origen.value == this.destino.value && this.movimientoCarga.value == 1)||
                      esMayorOrigen || ((this.aforadorInicial.value > 0 && this.aforadorFinal.value > 0) && this.aforadorInicial.value > this.aforadorFinal.value)
                      || (this.temperaturaMaxMin.value > 100 || this.temperaturaMaxMin.value < 0) || esRebombeoMayor || tanqueRecibiendo"
                    [@fadeInOut]>
                    <li
                      *ngIf="this.origen.value == this.destino.value && this.movimientoCarga.value == 1 && this.origen.value != null "
                      [@fadeInOut] class="list-unstyled alert alert-danger">El
                      contenedor origen y el destino no pueden ser el mismo, debe cambiar uno para proseguir.</li>
                    <li
                      *ngIf="(this.aforadorInicial.value > 0 && this.aforadorFinal.value > 0) && this.cantidadLts.value < 0"
                      [@fadeInOut] class="list-unstyled alert alert-danger">
                      El aforador inicial no puede ser
                      mayor al aforador final, verifique los datos ingresados.</li>
                    <li *ngIf="this.temperaturaMaxMin.value > 100 || this.temperaturaMaxMin.value < 0" [@fadeInOut]
                      class="unstyled text-danger">
                      La
                      temperatura
                      debe de estar entre 1° y 100°, debe corregir el valor.</li>
                    <li *ngIf="esRebombeoMayor" [@fadeInOut] class="list-unstyled alert alert-danger">La cantidad
                      para rebombear es mayor al stock que posee
                      el contenedor.
                    </li>
                    <li *ngIf="tanqueRecibiendo" [@fadeInOut] class="list-unstyled alert alert-danger">No
                      hay tanques en estado "Recepción", debe cambiar el estado del tanque destino para continuar.
                    </li>
                  </ul>
                  <ul id="cuadroAdvertencia" *ngIf="esAjusteMayor || esMayorDestino || esMayorOrigen" [@fadeInOut]>
                    <li *ngIf="esAjusteMayor" [@fadeInOut] class="list-unstyled alert alert-warning">La cantidad
                      ingresada sobrepasara el stock del
                      contenedor origen en {{sobrecarga}}.</li>
                    <li *ngIf="esMayorDestino" [@fadeInOut] class="list-unstyled alert alert-warning">La cantidad
                      ingresada sobrepasara la capacidad del
                      contenedor destino en {{destinoSobrecarga}} .</li>
                    <li *ngIf="esMayorOrigen" [@fadeInOut] class="list-unstyled alert alert-warning">El contenedor
                      origen no tiene stock suficiente, quedara stock en negativo {{origenSobrecarga}}.</li>
                  </ul> -->
                </div>
              </div>
            </div>
          </div>
          <small class="text-danger">*Campos obligatorios.</small>
        </form>
        <div class="modal-footer">
          <div class="mb-3 col-12">
            <div class="mb-3 row justify-content-md-center">
              <button class="btn btn-primary me-2" id="aceptar"
                [disabled]="!movimientoForm.valid || guardandoMovimiento" (click)="generarMovimiento()">
                <span *ngIf="!guardandoMovimiento">Guardar</span>
                <div *ngIf="guardandoMovimiento" class="d-flex align-items-center">
                  <div class="spinner-border text-light spinner-border-sm" role="status"></div>
                  <span>Guardando...</span>
                </div>
              </button>
              <button class="btn btn-secondary" id="cancelar" (click)="limpiarForm()" [disabled]="guardandoMovimiento"
                data-dismiss="modal">Cancelar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

