<div class="modal-header bg-light">
  <h5 class="modal-title justify-content-center">Resumen de impacto de carga de precios.</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="cancelarCargaPrecios()">
    
  </button>
</div>
<div class="modal-body">

  <div class="col-md-8" id="contenedorTituloResumen">
    <label class="titulo-switch">Informe resumido</label>
    <div id="contenedorSwitchTipoResumen" class="form-switch col-md-2">
      <input (change)="usuarioCambioResumen($event)" type="checkbox" class="form-check-input"
        id="switchTipoResumen">
      <label class="form-check-label" for="switchTipoResumen"></label>
    </div>
    <label class="titulo-switch">Informe completo</label>
  </div>

  <div #contenedorPreciosLote id="resumen-minimo" [ngClass]="mostrarResumenCompleto ? 'no-visible' : 'visible'"
    class="col-md-12">
    <div id="titulo">
      Recibimos {{this.preciosConsulta.length}} precios
    </div>

    <table>
      <tr>
        <td>
          <i class="fa fa-close fa-2x" style="color:red"></i>
        </td>
        <td>
          <div class="item-resumen">
            <span> Precios con errores: {{calcularPreciosConErrores(this.preciosConsulta)}}</span>
          </div>
        </td>
      </tr>
      <tr>
        <td>
          <i class="fa fa-clock-o fa-2x" style="padding: 0 10px;"></i>
          <i class="fa fa-exclamation-triangle fa-2x" style="color:yellow;"></i>
        </td>
        <td>
          <div class="item-resumen">
            <span>Retroactivos con conflicto: {{calcularRetroactivosConConflicto(this.preciosConsulta)}}</span>
          </div>
        </td>
      </tr>
      <tr>
        <td>
          <i class="fa fa-clock-o fa-2x" style="padding: 0 10px;"></i>
          <i class="fa fa-check-circle fa-2x" style="color:green;"></i>
        </td>
        <td>
          <div class="item-resumen">
            <span>Retroactivos sin conflicto: {{calcularRetroactivosSinConflicto(this.preciosConsulta)}}</span>
          </div>
        </td>
      </tr>
      <tr>
        <td>
          <i class="fa fa-exclamation-triangle fa-2x" style="color:yellow;"></i>
        </td>
        <td>
          <div class="item-resumen">
            <span>No retroactivos con conflicto: {{calcularNoRetroactivosConConflicto(this.preciosConsulta)}}</span>
          </div>
        </td>
      </tr>
      <tr>
        <td>
          <i class="fa fa-check-circle fa-2x" style="color:green;"></i>
        </td>
        <td>
          <div class="item-resumen">
            <span>No retroactivos sin conflicto: {{calcularNoRetroactivosSinConflicto(this.preciosConsulta)}}</span>
          </div>
        </td>
      </tr>
    </table>
  </div>

  <div id="resumen-completo" [ngClass]="mostrarResumenCompleto ? 'visible' : 'no-visible'">
    <div *ngFor="let precioNuevo of this.preciosConsulta; let i=index" id="contenedor" class="item">

      <div *ngIf="precioNuevo.tipoObservacion == enumTipoObservacionExcelPrecios.Error">
        <div class="row mb-5">
          <div class="col-sm-1 justify-content-center">
            <i class="fa fa-close fa-3x" style="color:red"></i>
          </div>

          <div class="col-sm-11">
            <table class="col-sm-12">
              <thead>
                <tr>
                  <th>Codigo</th>
                  <th>Producto</th>
                  <th>Moneda</th>
                  <th>Cliente</th>
                  <th>Tipo venta</th>
                  <th>Entrega</th>
                  <th>Area</th>
                  <th>Seg</th>
                  <th>Aeroplanta</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ precioNuevo.precio.codigoProducto }}</td>
                  <td>{{ precioNuevo.precio.nombreProducto }}</td>
                  <td>{{precioNuevo.precio.moneda != null ? precioNuevo.precio.moneda.nombre: "--"}}</td>
                  <td>{{precioNuevo.precio.cliente != null ? precioNuevo.precio.cliente.razonSocial: "--"}}</td>

                  <td *ngIf="precioNuevo.precio.tipoVenta == null || precioNuevo.precio.tipoVenta == 0"> - </td>
                  <td *ngIf="precioNuevo.precio.tipoVenta != null && precioNuevo.precio.tipoVenta == 2"> Internacional
                  </td>
                  <td *ngIf="precioNuevo.precio.tipoVenta != null && precioNuevo.precio.tipoVenta == 1"> Cabotaje </td>

                  <td> {{precioNuevo.precio.direccionEntrega != null?precioNuevo.precio.direccionEntrega: "--"}}</td>

                  <td> {{precioNuevo.precio.areaNegocio != null ? precioNuevo.precio.areaNegocio.nombreArea: "--"}}</td>

                  <td> {{precioNuevo.precio.segmento != null ? precioNuevo.precio.segmento.nombreSegmento: "--"}}</td>

                  <td>{{precioNuevo.precio.codigoAeroplanta}}</td>
                </tr>
              </tbody>
            </table>

            <b>{{ precioNuevo.observacion }}</b>
          </div>
        </div>

        <div class="row  mb-5">
          <div class="col-sm-12 mt-4">
            <div><b>Precio nuevo</b>: Desde {{precioNuevo.precio.fechaDesde | date: "dd'/'MM'/'yy HH':'mm"}} hasta
              {{precioNuevo.precio.fechaHasta | date: "dd'/'MM'/'yy HH':'mm"}} &nbsp; | &nbsp; Moneda:
              {{(precioNuevo.precio.moneda != null ? precioNuevo.precio.moneda.nombre : "-" )}} &nbsp; | &nbsp; Precio
              ${{precioNuevo.precio.precioContado}}</div>
          </div>
        </div>
      </div>

      <div *ngIf="precioNuevo.tipoObservacion != enumTipoObservacionExcelPrecios.Error">
        <div class="row mb-5">
          <div class="col-sm-2 justify-content-center">
            <i *ngIf="precioNuevo.tipoObservacion == enumTipoObservacionExcelPrecios.Conflicto"
              class="fa fa-exclamation-triangle fa-3x" style="color:yellow; float: left;"></i>
            <i *ngIf="precioNuevo.tipoObservacion == enumTipoObservacionExcelPrecios.SinConflicto"
              class="fa fa-check-circle fa-3x" style="color:green; float: left;"></i>
            <i *ngIf="esPrecioRetroactivo(precioNuevo.precio)" class="fa fa-clock-o fa-3x"
              style="padding: 0 10px; float: left;"></i>
          </div>

          <div class="col-sm-10">
            <table class="col-sm-12">
              <thead>
                <tr>
                  <th>Codigo</th>
                  <th>Producto</th>
                  <th>Moneda</th>
                  <th>Cliente</th>
                  <th>Tipo venta</th>
                  <th>Entrega</th>
                  <th>Area</th>
                  <th>Seg</th>
                  <th>Aeroplanta</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ precioNuevo.precio.codigoProducto }}</td>
                  <td>{{ precioNuevo.precio.nombreProducto }}</td>
                  <td> {{precioNuevo.precio.moneda != null?precioNuevo.precio.moneda.nombre: "--"}}</td>
                  <td> {{precioNuevo.precio.cliente != null?precioNuevo.precio.cliente.razonSocial: "--"}}</td>

                  <td *ngIf="precioNuevo.precio.tipoVenta == null || precioNuevo.precio.tipoVenta == 0"> - </td>
                  <td *ngIf="precioNuevo.precio.tipoVenta != null && precioNuevo.precio.tipoVenta == 2"> Internacional
                  </td>
                  <td *ngIf="precioNuevo.precio.tipoVenta != null && precioNuevo.precio.tipoVenta == 1"> Cabotaje </td>

                  <td> {{precioNuevo.precio.direccionEntrega != null?precioNuevo.precio.direccionEntrega: "--"}}</td>

                  <td> {{precioNuevo.precio.areaNegocio != null?precioNuevo.precio.areaNegocio.nombreArea: "--"}}</td>

                  <td> {{precioNuevo.precio.segmento != null?precioNuevo.precio.segmento.nombreSegmento: "--"}}</td>

                  <td>{{precioNuevo.precio.codigoAeroplanta}}</td>
                </tr>
              </tbody>
            </table>

            <b>{{ precioNuevo.observacion }}</b>
          </div>
        </div>

        <div *ngIf="precioNuevo.preciosViejos != null && precioNuevo.preciosViejos.length > 0">
          <div *ngFor="let precioViejo of precioNuevo.preciosViejos; let j=index">

            <div class="row  mb-5" *ngIf="precioViejo.precio != null">
              <div class="col-sm-12 mt-4">
                <div><b>Precio viejo</b>: Desde {{precioViejo.precio.fechaDesde | date: "dd'/'MM'/'yy HH':'mm"}} hasta
                  {{precioViejo.precio.fechaHasta | date: "dd'/'MM'/'yy HH':'mm"}} &nbsp; | &nbsp; Moneda:
                  {{precioViejo.precio.moneda.nombre}} &nbsp; | &nbsp; Precio ${{precioViejo.precio.precioContado}}
                  &nbsp;
                  | &nbsp; Lote: {{precioViejo.precio.numeroLote}}</div>
                <div><b>Precio nuevo</b>: Desde {{precioNuevo.precio.fechaDesde | date: "dd'/'MM'/'yy HH':'mm"}} hasta
                  {{precioNuevo.precio.fechaHasta | date: "dd'/'MM'/'yy HH':'mm"}} &nbsp; | &nbsp; Moneda:
                  {{precioNuevo.precio.moneda.nombre}} &nbsp; | &nbsp; Precio ${{precioNuevo.precio.precioContado}}
                </div>
              </div>
              <div class="col-sm-12">
                <div><b>Variación</b>: <b>{{precioViejo.variacionPorcentual | number: '1.2-2' }}%</b> &nbsp; | &nbsp;
                  <b>$
                    {{precioViejo.variacionImporte}}</b></div>
                <div><b>Acción</b>: <b>{{precioViejo.observacion}}</b></div>
              </div>
            </div>

            <div class="row  mb-5" *ngIf="precioViejo.precio == null">
              <div class="col-sm-12 mt-4">
                <div><b>Precio nuevo</b>: Desde {{precioNuevo.precio.fechaDesde | date: "dd'/'MM'/'yy HH':'mm"}} hasta
                  {{precioNuevo.precio.fechaHasta | date: "dd'/'MM'/'yy HH':'mm"}} &nbsp; | &nbsp; Moneda:
                  {{precioNuevo.precio.moneda.nombre}} &nbsp; | &nbsp; Precio ${{precioNuevo.precio.precioContado}}
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>
  </div>
</div>
<div class="modal-footer justify-content-center bg-light">
  <button class="btn btn-primary me-2" id="aceptar" [disabled]="guardandoPrecios || hayErroresEnPrecios()"
    (click)="cargarPrecios()">
    <span *ngIf="!guardandoPrecios">Cargar</span>
    <div *ngIf="guardandoPrecios" class="d-flex align-items-center">
      <div class="spinner-border text-light spinner-border-sm" role="status"></div>
      <span> Cargando...</span>
    </div>
  </button>

  <button type="button" class="btn btn-secondary ms-2" (click)="cancelarCargaPrecios()">Cancelar</button>

  <button class="btn btn-primary" style="float:right" id="descargar" *ngIf="!descargandoExcel"
    [disabled]="descargandoExcel" (click)="descargarExcelComparativa()">
    <span>Excel comparativa</span>
  </button>
  <loading-text-spinner *ngIf="descargandoExcel" message="Descargando Excel..." color="primary">
  </loading-text-spinner>
  <div *ngIf="errorExportacion != null && errorExportacion != ''">
    <ngb-alert class="alert alert-danger">{{errorExportacion}}
    </ngb-alert>
  </div>
</div>