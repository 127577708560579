import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { UploadFileService } from '../../management/upload-file.service';
import { UrlApi } from 'src/app/shared/enums/urlApi';
import { MessageService } from 'src/app/shell/message.service';
import { read, utils, WorkBook, WorkSheet } from 'xlsx'
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
@Component({
  selector: 'app-ranchos-import',
  templateUrl: './ranchos-import.component.html',
  styleUrls: ['./ranchos-import.component.css']
})
export class RanchosImportComponent implements OnInit {

  @ViewChild('inputFile')
  inputFileForm: ElementRef;

  timestampUltimaCarga: number = 0;
  timestampCargaActual: number = 0;
  userFileName: string;
  profileForm: UntypedFormGroup;
  error: string;
  fileUpload = { status: '', message: '', filePath: '', length: 0 };
  cargando: boolean = false;
  columnaCUIT: number = 10;

  constructor(private fb: UntypedFormBuilder,
    private fileUploadService: UploadFileService,
    private messageService: MessageService) { }

  ngOnInit() {
    this.profileForm = this.fb.group({
      name: [''],
      profile: [''],
      inputFile: ['', Validators.required]
    });
  }

  limpiarErrores()
  {
    this.messageService.clear();
  }

  resetearArchivo(){
    this.inputFileForm.nativeElement.value = "";
    this.userFileName = '';
  }

  onSelectedFile(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.profileForm.get('profile').setValue(file);
      this.profileForm.get('name').setValue(file.name);
      this.userFileName = file.name;
      this.timestampCargaActual = + new Date();
    }
  }

  /**
   * Limpiar pantalla
   * @param limpiarMensajes Determina si se limpia el div de mensajes
   */

  reset(limpiarMensajes: boolean = false, limpiarInput: boolean = false) {
    this.cargando = false;    
    if (limpiarInput) {
      this.userFileName = '';
    }
    if (limpiarMensajes) {
      this.fileUpload = { status: '', message: '', filePath: '', length: 0 };
      this.error="";
    }
      
  }

  cargarRanchos() {

    if(this.timestampUltimaCarga == this.timestampCargaActual){
      this.messageService.showErrorMessage("Esta volviendo a cargar el mismo archivo. Si lo modifico, vuelva a seleccionarlo con el boton examinar para tomar los cambios.");
      return;
    }

    this.timestampUltimaCarga = this.timestampCargaActual;

    this.cargando = true;
    this.fileUpload = { status: '', message: '', filePath: '', length: 0 };
    const archivo = this.profileForm.get('profile').value;
    if (archivo) {
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        /* read workbook */
        const bstr: string = e.target.result;
        const wb: WorkBook = read(bstr, { type: 'binary' });

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: WorkSheet = wb.Sheets[wsname];

        /* save data */
        let rows: any[][] = utils.sheet_to_json(ws, { header: 1 });
        if (rows.length == 0 || rows[1] == undefined) {
          this.messageService.showErrorMessage("El archivo está vacío.");
          this.reset();
        } else {
          if (rows[0][this.columnaCUIT] != 'NumeroDocumento') {
            this.messageService.showErrorMessage("Formato u ordenamiento de archivo incorrecto");
            this.reset();
          } else {
            const formData = new FormData();
            //formData.append('name', this.profileForm.get('name').value);
            formData.append('profile', this.profileForm.get('profile').value);
            
            this.fileUploadService.upload2(formData, UrlApi.ranchos) // retorna una promesa
              .then((data) => {
                if (Array.isArray(data)) {
                  this.cargando = false;             
                  this.profileForm.get('profile').setValue("");
                  this.profileForm.get('name').setValue("");     
                }
                if (typeof (data) != 'string') this.fileUpload = data;                
              })
              .catch((error: ErrorModel) => {
                this.reset(false)
                this.error = error.description;
                //this.messageService.showErrorMessage(error.message + " - " + error.description);                
            });
          }
        }
      }
    reader.readAsBinaryString(this.profileForm.get('profile').value);
    } else {
      this.messageService.showErrorMessage("No se seleccionó un archivo");  
    }          
  }       
}
