<div class="col col-md-12">
  <div class="spinner-container" *ngIf="cargandoInformacion">
    <ngx-spinner name="spinnerGrafico" bdOpacity=0.3bd
      Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
      <p style="color: white">C a r g a n d o . . . </p>
    </ngx-spinner>
  </div>
</div>
<div class="card add-margin" *ngIf="!this.cargandoInformacion">
  <div>

    <ngb-alert type="warning" [dismissible]="false" *ngIf="this.nivelSeleccionado == null">
      No hay ningún nivel seleccionado.
    </ngb-alert>

    <ngb-alert type="warning" [dismissible]="false"
      *ngIf="this.nivelSeleccionado != null && this.usuariosAsignados.length == 0">
      No hay usuarios cargados para el nivel seleccionado.
    </ngb-alert>

    <div id="contenedorListadoUsuariosAsignados"
      *ngIf="this.nivelSeleccionado != null && this.usuariosAsignados.length > 0">
      <table id="tablaUsuariosAsignados" class="table table-xl table-striped table-hover menu-izq">
        <thead class="text-center letra">
          <tr>
            <th scope="col"> Usuario </th>
            <th scope="col"> Desde </th>
            <th scope="col"> Hasta</th>
            <th scope="col"> Otorgado por </th>
            <th scope="col"> Eliminar </th>
          </tr>
        </thead>

        <tbody class="text-center">
          <tr style="font-size: 13px;" *ngFor="let usuario of usuariosAsignados; let i=index ">
            <td> {{usuario.nombreCompleto}}</td>
            <td> {{usuario.fechaDesdeAsignado | date: 'dd/MM/yyyy' }}</td>
            <td> {{usuario.fechaFinAsignado != "0001-01-01T00:00:00" ? (usuario.fechaFinAsignado | date: 'dd/MM/yyyy') :
              "-"}}</td>
            <td> {{usuario.otorgadoPorNombre}}</td>
            <td style="cursor: pointer;" *ngIf="!this.esperandoEliminacion">
              <button class="btn btn-danger" (click)="eliminarLinea(i)"><i class="fa fa-trash" ></i></button>
            </td>
            <td *ngIf="this.esperandoEliminacion" class="spinner-border text-primary mt-2"></td>
          </tr>
        </tbody>

      </table>
    </div>
  </div>
</div>
