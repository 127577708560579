import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { BlobUploadCommonResponse } from '@azure/storage-blob';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HandleError, HttpErrorHandler } from '../http-error-handler.service';
import { AzureBlobUploadService } from '../shared/azure-blob-upload.service';
import { UrlApi } from '../shared/enums/urlApi';
import { CargaArchivo } from '../shared/models/cliente/CargaArchivo';
import { ErrorModel } from '../shared/models/ErrorModel';
import { Sas } from '../shared/models/padrones/Sas';


@Injectable({
  providedIn: 'root'
})
export class UploadService {

  private sas: Sas;

  clientesBaseUrl = environment.apiServer.clientesBaseUrl;
  facturacionBaseUrl = environment.apiServer.facturacionBaseUrl;

  constructor(private azureBlobUpload: AzureBlobUploadService, private http: HttpClient) { }

  cargarArchivo(file: File, carga: CargaArchivo, outputArchivo: EventEmitter<String>, url:string) {
      this.obtenerSas(url).subscribe((sas: Sas) => {
          let aborter = this.azureBlobUpload.uploadFileToBlobStorage(file, sas.containerUri, sas.sas,
              (pro) => {
                  carga.progress = pro;
              },
              (val: BlobUploadCommonResponse) => {
                  if (val) {
                      carga.progress = 100;

                      carga.finalizar(`Se proceso el archivo correctamente.`);

                      outputArchivo.emit(file.name);
                      /*
                      let url = this.clientesBaseUrl + "/upload-file/"+ encodeURIComponent(file.name);
                      this.http.post<number>(url, null).subscribe(
                          (rta) => {
                              carga.finalizar(`Se cargaron ${rta} registros satisfactoriamente`);
                          },
                          (error: ErrorModel) => {
                              carga.error = error.description;
                          }
                      );
                      */
                  }
              });
          carga.setAborter(aborter);
      });
  }

  cancelarCarga(carga: CargaArchivo) {
      carga.cancelarCarga();
  }

  obtenerSas(url): Observable<Sas> {
      /* verificar si está vencida antes de pedir uno nuevo */
      if (this.sas) {
          const decodedUri = decodeURIComponent(this.sas.sas);
          const fechaSas = new Date(decodedUri.substr(decodedUri.indexOf('&se=') + 4, 20));
          if (fechaSas > new Date())
              return of(this.sas);
      }
      const ruta = url + '/ConfiguracionesV1/SAS?blobContainerName=files';
      return this.http.get<Sas>(ruta).pipe(tap((sas: Sas) => this.sas = sas));
  }

}
