import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbCalendar, NgbDatepickerConfig, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { UsuarioService } from 'src/app/account/usuario.service';
import { TipoNivelAprobacion } from 'src/app/shared/enums/tipoNivelAprobacion';
import { TipoNivelUsuario } from 'src/app/shared/enums/tipoNivelUsuario';
import { tipoNivelUsuarioSeparados } from 'src/app/shared/enums/tipoNivelUsuarioSeparados';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';
import { Usuario } from 'src/app/shared/models/despacho/Usuario';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { NivelAprobacion } from 'src/app/shared/models/precios/NivelAprobacion';
import { NivelDelegado } from 'src/app/shared/models/precios/NivelDelegado';
import { MessageService } from 'src/app/shell/message.service';
import { PreciosService } from '../../precios.service';

@Component({
  selector: 'app-modal-seleccionar-usuario',
  templateUrl: './modal-seleccionar-usuario.component.html',
  styleUrls: ['./modal-seleccionar-usuario.component.css']
})
export class ModalSeleccionarUsuarioComponent implements OnInit {

  @Input() esDelegacion: boolean;
  @Input() nivelAprobacion: NivelAprobacion;

  form: UntypedFormGroup;
  guardandoUsuarioNivel: boolean = false;
  usuarios: Usuario[] = [];
  cargandoUsuarios: boolean;
  errorUsuarios: boolean;
  modelFechaDesde: NgbDateStruct;
  modelFechaHasta: NgbDateStruct;

  errorDeNivel: boolean = false;

  constructor(
    private preciosService: PreciosService,
    private usuarioService: UsuarioService,
    private activeModal: NgbActiveModal,
    private calendar: NgbCalendar,
    private datepickerConfig: NgbDatepickerConfig,
    private messageService: MessageService,
  ) { }

  // hooks

  ngOnInit() {

    let inicio: any = { day: 1, month: 1, year: 2019 };
    this.datepickerConfig.minDate = inicio;    
    let fin: any = { day: 31, month: 12, year: 2050 };
    this.datepickerConfig.maxDate = fin;

    this.crearFormulario();

    this.modelFechaDesde = this.calendar.getToday();
    this.modelFechaHasta = this.calendar.getToday();

    this.getUsuarios();
  }



  // llamadas al service

  getUsuarios(): void {

    if(this.nivelAprobacion == null){
      this.errorDeNivel = true;
      return;
    }

    this.cargandoUsuarios = true;
    this.errorUsuarios = false;


      var tipoRol;

      if(this.nivelAprobacion.tipoNivel == tipoNivelUsuarioSeparados.Precio){
        tipoRol=TipoUsuario.APROBADORPRECIOS;
      }else if(this.nivelAprobacion.tipoNivel == tipoNivelUsuarioSeparados.Comercial){
        tipoRol=TipoUsuario.APROBADORBYR;
      }else if(this.nivelAprobacion.tipoNivel == tipoNivelUsuarioSeparados.Refacturacion){
        tipoRol=TipoUsuario.REFACTURACION;
      }

      this.preciosService.getUsuariosPorRol(tipoRol)
        .subscribe(usuarios => {
          usuarios = usuarios.map(a => Object.assign(new Usuario(), a));
          this.usuarios = usuarios;
          this.cargandoUsuarios = false;
        }, () => { this.errorUsuarios = true; this.cargandoUsuarios = false; });
  }

  onSubmit() {
    if(this.nivelAprobacion == null){
      this.errorDeNivel = true;
      return;
    }

    this.guardandoUsuarioNivel = true;

    var id = this.usuarioFormControl.value.id;
    var nivel = this.nivelAprobacion;

    if (this.esDelegacion) {
      // llama a endpoint para delegar un nivel a un username por un determinado tiempo
      var otorgadoPor = this.usuarioService.getUsername();
      var desde = this.formatDate(this.modelFechaDesde.year, this.modelFechaDesde.month, this.modelFechaDesde.day, 0, 0, 0);
      var hasta = this.formatDate(this.modelFechaHasta.year, this.modelFechaHasta.month, this.modelFechaHasta.day, 23, 59, 59);
      var detalle = this.detalleFormControl.value;

      var nivelDelegado = new NivelDelegado();
      nivelDelegado.otorgadoPor = otorgadoPor;
      nivelDelegado.fechaDesde = desde;
      nivelDelegado.fechaHasta = hasta;
      nivelDelegado.codigoNivelAprobacion = nivel.codigo;
      nivelDelegado.detalle = detalle;

      this.preciosService.asignarNivelDelegadoUsuario(id, nivelDelegado)
        .subscribe(nivelAprobacion => {
          this.activeModal.close(nivelAprobacion);
          if (nivelAprobacion != null) // para el caso que no salta la validacion
          {
             this.messageService.showSuccessLongMessage("Se delego el nivel correctamente el usuario");
             this.guardandoUsuarioNivel = false;
          }
        },
          (err: ErrorModel) => {
            this.guardandoUsuarioNivel = false;
          });
    } else {
      // llama a un endpoint para asignar un nivel nativo a un username
      this.preciosService.asignarNivelNativoUsuario(id, nivel)
        .subscribe(nivelAprobacion => {
          this.activeModal.close(nivelAprobacion);
          if (nivelAprobacion != null) // para el caso que no salta la validacion
          {
            this.messageService.showSuccessLongMessage("Se asigno el nivel nativo al usuario correctamente");
            this.guardandoUsuarioNivel = false;
          }
        },
          (err: ErrorModel) => { // para el caso que salta la validacion
            this.guardandoUsuarioNivel = false;
          });
    }

  }


  // form

  crearFormulario() {
    this.form = new UntypedFormGroup({
      usuarioFormControl: new UntypedFormControl('', Validators.required),
      fechaDesdeFormControl: new UntypedFormControl('', this.esDelegacion ? Validators.required : null),
      fechaHastaFormControl: new UntypedFormControl('', this.esDelegacion ? Validators.required : null),
      detalleFormControl: new UntypedFormControl('',  this.esDelegacion ? Validators.required : null),
    });

    this.form.setValidators(this.formValidator);
  }

  selectUsuario(event: any): void {
    this.usuarioFormControl.setValue(event.item);
  }

  confirmUsuario(): void {
    let usuario = this.usuarioFormControl.value;
    if (typeof (this.usuarioFormControl.value) === 'string') {
      this.usuarioFormControl.setValue("");
    }
  }


  searchUsuario = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term =>
        this.usuarios == null || term.length < 2 || this.cargandoUsuarios ? [] :
          this.usuarios.filter(u => this.containsString(this.usuarioFormControl.value, u.nombresCompletos, u.dni)).slice(0, 10))
    )

  containsString(searchValue: string, ...searchIn: string[]): boolean {
    const concat = searchIn.toString();
    return concat.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
  }

  formatterUsuario = (op: { nombresCompletos: string, dni: string }) => op.nombresCompletos || op.dni ? `${op.nombresCompletos ? op.nombresCompletos : "SIN NOMBRE"} - ${op.dni ? op.dni : "SIN DNI"}` : "";

  formatDate(year, month, day, hour, minute, seconds): string {
    return year + '-' + month.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + '-' + day.toLocaleString(undefined, { minimumIntegerDigits: 2 })
    + 'T' + hour.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + minute.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + seconds.toLocaleString(undefined, { minimumIntegerDigits: 2 });
  }

  onCancelar() {
    this.activeModal.dismiss();
  }

  formValidator(group: UntypedFormGroup): any {
    if (group) {

      let fechaDesde = group.get("fechaDesdeFormControl").value;
      let fechaHasta = group.get("fechaHastaFormControl").value;

      if (fechaDesde && fechaHasta) {

        if (new Date(fechaDesde.year, fechaDesde.month - 1, fechaDesde.day) > new Date(fechaHasta.year, fechaHasta.month - 1, fechaHasta.day)) {
          return { fechaDesdeMayorHasta: true };
        }
      }

      return null;
    }
  }


  // getters

  get usuarioFormControl() {
    return this.form.controls.usuarioFormControl;
  }
  get fechaDesdeFormControl() {
    return this.form.controls.fechaDesdeFormControl;
  }
  get fechaHastaFormControl() {
    return this.form.controls.fechaHastaFormControl;
  }
  get detalleFormControl() {
    return this.form.controls.detalleFormControl;
  }


}
