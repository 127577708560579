<div class="spinner-container" *ngIf="obteniendoDatos">        
  <ngx-spinner name="spinnerGrafico" bdOpacity=0.3bd
    Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
    <p style="color: white">C a r g a n d o . . . </p>
  </ngx-spinner>        
</div>

<div *ngIf="!obteniendoDatos">
  <div class="modal-header bg-light">
    <h4 class="modal-title">Concepto: {{this.concepto}} | Producto: {{this.codigoProducto}} - {{this.nombreProducto}} ({{this.tipoNatural ? "Natural" : "15º"}})</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="this.activeModal.dismiss()"></button>
  </div>

  <div class="modal-body">
    Fecha desde: {{ this.fechaDesde | date: "dd/MM/yyy HH:mm a"  }} | Fecha hasta: {{ this.fechaHasta  | date: "dd/MM/yyy HH:mm a" }}
    <br/>
    Dependencia: {{ this.usuarioService.getAeroplantaActual().codigoAeroplanta + " - " + this.usuarioService.getAeroplantaActual().nombre}}
    <div class="col-12" id="contenedor" *ngIf="this.movimientosDetalle != null">
      <button class="btn btn-outline-success" style="float: right; margin-bottom: 0.25em;" (click)="exportarMovimientosDetalle()">
        <i class="fa fa-file-excel-o" aria-hidden="true"></i>
        Exportar
      </button>
      
      <div>
        <table class="table table-striped table-hover">
          <thead class="text-center">
            <th>Comprobante</th>
            <th *ngIf="this.tipoTransaccion == TipoMovimientoStock.Despacho">Aerovale</th>
            <th>Comprobante SAP</th>
            <th>Fecha</th>
            <th>Detalle</th>
            <th>Cantidad</th>
          </thead>
          <tbody class="text-center">
            <tr *ngFor="let md of movimientosDetalle">
              <td>{{md.numeroComprobante}}</td>
              <td *ngIf="this.tipoTransaccion == TipoMovimientoStock.Despacho">{{md.numeroAerovale }}</td>
              <td>{{this.comprobanteSAP(md.iD_CENIT)}}</td>
              <td>{{md.fechaHora | date: "d'/'MM'/'y' 'hh:mm a" }}</td>
              <td>{{md.detalle}}</td>
              <td>{{md.cantidad | number: '1.0-0' }}</td>              
            </tr>
            <tr class="resaltado">
              <td></td>
              <td *ngIf="this.tipoTransaccion == TipoMovimientoStock.Despacho"></td>
              <td></td>
              <td>Total Concepto</td>
              <td>{{ this.totalConcepto | number: '1.0-0' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
  