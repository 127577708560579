import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UsuarioService } from 'src/app/account/usuario.service';
import { RefacturacionFactura } from 'src/app/shared/models/facturacion/RefacturacionFactura';
import { MessageService } from 'src/app/shell/message.service';
import { environment } from 'src/environments/environment';
import { FacturacionService } from '../../facturacion.service';


@Component({
  selector: 'app-modal-solicitud-refacturacion-manual',
  templateUrl: './modal-solicitud-refacturacion-manual.component.html',
  styleUrls: ['./modal-solicitud-refacturacion-manual.component.css']
})
export class ModalSolicitudRefacturacionManualComponent implements OnInit {

  @Input() factura: any;

  clientesBaseUrl = environment.apiServer.clientesBaseUrl;

  guardando: boolean = false;
  archivoSubido : string;
  error: string;
  // forms
  form: UntypedFormGroup;
  checkMultiplesMotivos: UntypedFormGroup;

  //lista de selección
  listaDeMotivosSeleccionados: any[]=[];

  plazo = { nombre: "Plazo", codigo: 0 };
  erroresImpositivos = { nombre: "Errores Impositivos", codigo: 1 };
  otros={ nombre: "Otros", codigo: 2 };
  motivosParaCheckear = [this.plazo, this.erroresImpositivos,this.otros];

   constructor(
    private formBuilder: UntypedFormBuilder,
    public activeModal: NgbActiveModal,
    private changeDetector: ChangeDetectorRef,
    private facturacionService: FacturacionService,
    private fb: UntypedFormBuilder,
    private usuarioService:UsuarioService,
    private messageService:MessageService) {
   }

  // hooks

  ngOnInit() {
    this.crearFormulario();
    this.inicializarComponente();
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  // form

  crearFormulario(){
    this.form = this.formBuilder.group({
      plazoFormControl:[''],
      motivoFormControl: ['', Validators.required],
      comentariosFormControl: ['', Validators.required],
    });

  }

  seleccionoPlazo(): boolean
  {
    return (this.motivoFormControl.value.codigo===0);
  }


  inicializarComponente(){
    this.checkMultiplesMotivos = this.formBuilder.group({
      nombreCondicion: this.fb.array([])
    });
    this.guardando = false;
  }




 // llamadas al service

  onSubmit() {
    this.guardando = true;
    if(this.archivoSubido == null){
      this.error = "Debe adjuntar un archivo obligatoriamente.";
      return;
    }

    let refacturacion=new RefacturacionFactura();
    refacturacion.motivo=this.motivoFormControl.value.codigo;
    refacturacion.nombreArchivoAdjunto=this.archivoSubido;
    refacturacion.facturaId=this.factura.id;
    if (this.motivoFormControl.value.codigo===0)
    {
      refacturacion.plazo=Number(this.plazoFormControl.value);
    }
    refacturacion.usuarioSolicitador=this.obtenerStringUsuarioSolicitador();
    refacturacion.detalle=this.comentariosFormControl.value;





    this.facturacionService.emitirRefacturacionManual(refacturacion)
     .subscribe(result => {
      this.messageService.showSuccessMessage("Se ha realizado correctamente la refacturacion de la factura "+this.obtenerPrefijoNumeroDeFactura());
      this.guardando=false;
      this.activeModal.close();
   }, () => {
      this.messageService.showErrorMessage("Hubo un error al realizar la refacturacion");
      this.guardando=false;
      this.activeModal.close();
    });

  }

  // validaciones

   puedeSolicitarRefactura(): boolean
   {
   let puede=false;
   if (this.motivoFormControl.value.codigo===0)
   {
    puede=(this.form.valid && this.archivoSubido!=null);
   }
   if (this.motivoFormControl.value.codigo==1 || this.motivoFormControl.value.codigo==2)
   {
     puede=(this.archivoSubido!=null && this.comentariosFormControl.value!=null)
   }
   return puede;
   }


   onArchivoSubido(event){
    this.archivoSubido = event;
  }

  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46;
  }

  // getters

  obtenerPrefijoNumeroDeFactura(){
    return "Prefijo: "+this.factura.prefijo+" ," + "Número: "+ this.factura.numeroComprobante;
  }

  obtenerStringUsuarioSolicitador(){
    return this.facturacionService.obtenerStringSolicitador();
  }

  get motivoFormControl(){
    return this.form.controls.motivoFormControl;
  }

  get plazoFormControl(){
    return this.form.controls.plazoFormControl;
  }

  get archivoFormControl(){
    return this.form.controls.archivoFormControl;
  }

  get comentariosFormControl(){
    return this.form.controls.comentariosFormControl;
  }



}
