<div *ngIf="accionLogin == enumAccionLogin.AccesoHabilitado || accionLogin == null">
  <div id="bloque" class="text-center">
    <img src="../../../assets/images/logo_pad.svg" width="300">
    <p class="mt-4 lead text-info">Movilidad Electrica</p>
  </div>
  <div class="text-center">
    <p class="mb-2 mt-4 powered">Powered by</p>
    <img src="../../../assets/images/logo_aoniken.svg" class="mb-4" width="140">
  </div>
</div>

<div *ngIf="accionLogin == enumAccionLogin.ConfiguracionInicial" class="jumbotron">
  <app-configuracion-inicial></app-configuracion-inicial>
</div>

<div *ngIf="accionLogin == enumAccionLogin.AguardandoValidacion" class="jumbotron">
  <div class="mb-3 row">
    <div class="col-sm-12 col-form-label">
      Bienvenido {{username}}.
    </div>
  </div>
  <div class="mb-3 row">
    <div class="col-sm-12 col-form-label">
      Tu solicitud de alta esta siendo gestionada por los jefe de aeroplanta, cuyas aeroplantas incluiste en la
      solicitud.
    </div>
  </div>
  <div class="mb-3 row">
    <div class="col-sm-12 col-form-label">
      Aún no ha sido revisada/aprobada.
    </div>
  </div>
  <div class="mb-3 row">
    <div class="col-sm-12 col-form-label">
      Consultar con los jefes de aeroplanta correspondientes, por el estado del tramite.
    </div>
  </div>
  <div class="mb-3 row">
    <div class="col-sm-12 col-form-label">
      <button class="btn btn-primary" type="button" (click)="onCerrarSesionClick()">Cerrar sesión</button>
    </div>
  </div>
  <div class="mb-3 row">
    <div class="col-sm-12 col-form-label">
      Atte, Sistema PAD.
    </div>
  </div>
</div>

<div *ngIf="accionLogin == enumAccionLogin.AccesoProhibido" class="jumbotron">
  Acceso Prohibido
  <div class="mb-3 row">
    <div class="col-sm-12 col-form-label">
      <button class="btn btn-primary" type="button" (click)="onCerrarSesionClick()">Cerrar sesión</button>
    </div>
  </div>
</div>
