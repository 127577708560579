import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TipoNivelAprobacion } from 'src/app/shared/enums/tipoNivelAprobacion';
import { ModalConfirmComponent } from 'src/app/shared/generic-modals/modal-confirm.component';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { NivelAprobacion } from 'src/app/shared/models/precios/NivelAprobacion';
import { MessageService } from 'src/app/shell/message.service';
import { PreciosService } from '../../precios.service';

@Component({
  selector: 'app-modal-nivel-aprobacion',
  templateUrl: './modal-nivel-aprobacion.component.html',
  styleUrls: ['./modal-nivel-aprobacion.component.css']
})
export class ModalNivelAprobacionComponent implements OnInit {

  @Input() tipoNivel: TipoNivelAprobacion;
  @Input() nivelAprobacionSeleccionado: NivelAprobacion;

  enumTipoNivel = TipoNivelAprobacion;
  form: UntypedFormGroup;

  editando: boolean;
  guardandoNivel: boolean = false;
  tildoPreciosRetroactivos: boolean = false;

  constructor(
    private preciosService: PreciosService,
    private messageService: MessageService,
    private activeModal: NgbActiveModal,
    private modalAceptar: NgbModal) {
  }

  // hooks

  ngOnInit() {
    this.crearFormulario();

    if (this.nivelAprobacionSeleccionado == null) {
      this.editando = false;
      this.puedeDesactivarFormControl.setValue(false);
      this.apruebaRetroactivosFormControl.setValue(false);
      this.activoFormControl.setValue(true);
    } else {
      this.editando = true;
      this.cargarInfoNivelAprobacion();
    }
  }

  // forms

  crearFormulario() {
    this.form = new UntypedFormGroup({
      codigoFormControl: new UntypedFormControl('', Validators.required),
      nombreFormControl: new UntypedFormControl('', Validators.required),
      nivelFormControl: new UntypedFormControl('', Validators.required),
      maximaVariacionFormControl: new UntypedFormControl('', (this.tipoNivel == TipoNivelAprobacion.Precios ? Validators.required : null)),
      maximoPorcentajeAprobacionFormControl: new UntypedFormControl('', (this.tipoNivel == TipoNivelAprobacion.Bonif_y_recargos ? Validators.required : null)),
      puedeDesactivarFormControl: new UntypedFormControl(),
      apruebaRetroactivosFormControl:new UntypedFormControl(),
      activoFormControl: new UntypedFormControl()
    });
  }

  cargarInfoNivelAprobacion() {
    this.codigoFormControl.setValue(this.nivelAprobacionSeleccionado.codigo);
    this.nombreFormControl.setValue(this.nivelAprobacionSeleccionado.nombre);
    this.activoFormControl.setValue(this.nivelAprobacionSeleccionado.activo);

    if (this.tipoNivel == TipoNivelAprobacion.Precios) {
      this.maximaVariacionFormControl.setValue(this.nivelAprobacionSeleccionado.maximoVariacion);
      this.puedeDesactivarFormControl.setValue(this.nivelAprobacionSeleccionado.puedeDesactivar);
      this.apruebaRetroactivosFormControl.setValue(this.nivelAprobacionSeleccionado.apruebaRetroactivos);
    } else {
      this.maximoPorcentajeAprobacionFormControl.setValue(this.nivelAprobacionSeleccionado.maximoPorcentajeAprobacion);
    }

    if(this.nivelAprobacionSeleccionado.apruebaRetroactivos){
      this.tildoPreciosRetroactivos = true;
      this.maximaVariacionFormControl.setValue("0");
    }
  }

  // conexiones al service

  onSubmit() {
    this.guardandoNivel = true;

    if (!this.editando) {
      this.nivelAprobacionSeleccionado = new NivelAprobacion();
    }

    this.nivelAprobacionSeleccionado.codigo = this.codigoFormControl.value;
    this.nivelAprobacionSeleccionado.nombre = this.nombreFormControl.value;
    console.log(this.puedeDesactivarFormControl.value);
    console.log(this.activoFormControl.value);

    this.nivelAprobacionSeleccionado.activo = this.activoFormControl.value;
    this.nivelAprobacionSeleccionado.tipoNivel = Number(this.tipoNivel);

    if (this.tipoNivel == TipoNivelAprobacion.Precios) {
      console.log(JSON.stringify(Number(this.maximaVariacionFormControl.value)));
      this.nivelAprobacionSeleccionado.maximoVariacion = Number(this.maximaVariacionFormControl.value);
      this.nivelAprobacionSeleccionado.puedeDesactivar = this.puedeDesactivarFormControl.value;
      this.nivelAprobacionSeleccionado.apruebaRetroactivos= this.apruebaRetroactivosFormControl.value;
    } else {
      this.nivelAprobacionSeleccionado.maximoPorcentajeAprobacion = Number(this.maximoPorcentajeAprobacionFormControl.value);
    }

    if (!this.editando) {
      this.preciosService.crearNivelAprobacion(this.nivelAprobacionSeleccionado)
        .subscribe(nivelAprobacion => {
          this.guardandoNivel = false;
          this.activeModal.close(nivelAprobacion);
        },
          (err: ErrorModel) => {
            this.messageService.showErrorMessage("Error al guardar el nivel");
            this.guardandoNivel = false;
          });
    } else {
      this.guardandoNivel= false;
      if (this.nivelAprobacionSeleccionado.activo == false) {
        let usuarios: string = '';
        for(let usu of this.nivelAprobacionSeleccionado.usuariosNivel){
          usuarios += usu.nombreCompleto +', ';
        }
        const modalRef = this.modalAceptar.open(ModalConfirmComponent, { centered: true });
        modalRef.componentInstance.mensaje = 'Los usuarios: ' + usuarios + ' quedaran sin efecto.';
        modalRef.result.then(() => {
          this.guardandoNivel = true;
          this.preciosService.editarNivelAprobacion(this.nivelAprobacionSeleccionado)
            .subscribe(nivelAprobacion => {
              this.guardandoNivel = false;
              this.activeModal.close(nivelAprobacion);
            },
              (err: ErrorModel) => {
                this.messageService.showErrorMessage("Error al editar el nivel");
                this.guardandoNivel = false;
              });
        });
      } else {
        this.preciosService.editarNivelAprobacion(this.nivelAprobacionSeleccionado)
          .subscribe(nivelAprobacion => {
            this.guardandoNivel = false;
            this.activeModal.close(nivelAprobacion);
          },
            (err: ErrorModel) => {
              this.messageService.showErrorMessage("Error al editar el nivel");
              this.guardandoNivel = false;
            });
      }
     this.guardandoNivel = false;
    }
  }

  usuarioPulsaPreciosRetroactivos(checked){
    this.tildoPreciosRetroactivos = checked;

    if(this.tildoPreciosRetroactivos){
      this.maximaVariacionFormControl.setValue("0");
    }else{
      this.maximaVariacionFormControl.setValue("");
    }    
  }

  // modals

  onCancelar() {
    this.activeModal.dismiss();
  }

  // validaciones

  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0 || event.charCode == 46) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  // getters

  get codigoFormControl() {
    return this.form.controls.codigoFormControl;
  }

  get nombreFormControl() {
    return this.form.controls.nombreFormControl;
  }

  get nivelFormControl() {
    return this.form.controls.nivelFormControl;
  }

  get puedeDesactivarFormControl() {
    return this.form.controls.puedeDesactivarFormControl;
  }

  get apruebaRetroactivosFormControl(){
    return this.form.controls.apruebaRetroactivosFormControl;
  }

  get activoFormControl() {
    return this.form.controls.activoFormControl;
  }

  get maximaVariacionFormControl() {
    return this.form.controls.maximaVariacionFormControl;
  }

  get maximoPorcentajeAprobacionFormControl() {
    return this.form.controls.maximoPorcentajeAprobacionFormControl;
  }


}
