<div class="card" id="holdeoCuenta-card">
  <div class="card-header">
    <h4 style="display: inline;" class="card-title">Holdeo de cuentas.</h4>
    <button style="margin-left: 4em;" class="btn btn-primary" type="button" [disabled]="this.obteniendo"
      (click)="agregarCuenta()">Agregar
      cuenta</button>
    <button style="margin-left: 4em;" class="btn btn-primary float-end" type="button" [disabled]="this.obteniendo"
      (click)="goBack()">Volver</button>
  </div>
  <div class="card-body">
    <div class="col-12">
      <ngx-spinner name="spinnerLista" [ngStyle]="{'height': '400px', 'z-index':'0'}"
        [ngClass]="{'list-group-item list-group-item-action': obteniendo}" bdOpacity=0.3bd Color="rgba(193,193,193,0)"
        size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
        <p style="color: white"> O b t e n i e n d o H o l d e o s . . . </p>
      </ngx-spinner>
    </div>
    <div *ngIf="!this.obteniendo">
      <ngb-alert *ngIf="cuentasHoldeadas.length == 0">No hay cuentas holdeadas activas para la fecha.</ngb-alert>
      <table id="tabla" class="table table-sm table-striped table-hover" *ngIf="cuentasHoldeadas.length != 0">
        <thead class="text-center">
          <tr>
            <th>N° cuenta</th>
            <th>Identificador</th>
            <th>Motivo</th>
            <th>Fecha desde</th>
            <th>Fecha hasta</th>
            <th>Responsable</th>
            <th>Acción</th>
          </tr>
        </thead>
        <tbody class="text-center">
          <tr *ngFor="let hc of cuentasHoldeadas; let i = index">
            <td>{{hc.ctA_SGC}}</td>
            <td>{{hc.ctA_Descripcion}}</td>
            <td>{{hc.detalle}}</td>
            <td>{{hc.fechaDesde | date: 'dd/MM/yyyy'}}</td>
            <td>{{hc.fechaHasta | date: 'dd/MM/yyyy'}}</td>
            <td>{{hc.responsable}}</td>
            <td>
              <button type="button" (click)="quitarCuentas(i)" [disabled]="desactivandoCuenta"
                class="btn btn-danger btn-sm col-sm-12">
                <i class="fa fa-trash" aria-hidden="true" *ngIf="!desactivandoCuenta"></i>
                <div *ngIf="desactivandoCuenta && cuentaSeleccionada==i">
                  <div class="spinner-border text-light spinner-border-sm" role="status"></div>
                  <span><i class="fa fa-trash" aria-hidden="true"></i></span>
                </div>
                <div *ngIf="desactivandoCuenta && cuentaSeleccionada!=i">                  
                  <span><i class="fa fa-trash" aria-hidden="true"></i></span>
                </div>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>