import { Cliente } from './Cliente';
import { Observacion } from './Observacion';
import { UsuarioAerovale } from './UsuarioAerovale';

export class AerovaleCommon {
  public id: string;
  public numeroAerovale: number;
  public prefijo: number;
  public codigoAeroplanta: string;
  // abastecedoraId: string;
  public cliente: Cliente;
  public tipoPago: string;
  public ciudadIdAerovale: string;  
  public observaciones: Observacion[];
  public comentarios: string;  
  public firmaOperador: string;
  public firmaCliente: string;
  public tipoAerovale: number; /* 0: AerovaleAerocombustible | 1: AerovaleAeroespecialidad | 2: AerovaleCombustible | 3: AerovaleAlije */    
  fechaHoraInicioDespacho: string;
  fechaHoraFinDespacho: string;
  public estado: string;
  public datos_App: string;
  public syncronized: boolean;
  supervisorDto: UsuarioAerovale;
  operadorDto: UsuarioAerovale; 
}
