import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, pipe } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { SaldoCliente } from '../shared/models/controlCredito/SaldoCliente';
import { Area } from '../shared/models/abm/Area';
import { environment } from 'src/environments/environment';
import { Estado } from '../shared/models/controlCredito/Estado';
import { NotificacionSistemaDTO } from '../shared/models/notificaciones/NotificacionSistemaDTO';

@Injectable({
  providedIn: 'root'
})
export class ControlCreditoService {

  constructor(private http: HttpClient) { }

  baseURL = environment.apiServer.clientesBaseUrl;
  notificacionesURL = environment.apiServer.notificacionesBaseUrl
  private saldoURL = this.baseURL + '/SaldosV1';

  getSaldosClientes(busqueda: string, page: number, pageSize: number): Observable<SaldoCliente[]> {
    if (!busqueda) busqueda = ""; //De esta forma en vez de un null le envia a back un string vacio    
    return this.http.get<SaldoCliente[]>(this.saldoURL + "/SaldoCliente?buscarCliente=" + busqueda + "&page=" + page + "&limit=" + pageSize);  
  }
  
  getSaldosFiltro(filtros, page: number, pageSize: number, excedido: boolean, busqueda: string): Observable<SaldoCliente[]> {
    if (!busqueda) busqueda = "";
    return this.http.post<SaldoCliente[]>(`${this.baseURL}/SaldosV1/SaldoClientes/filtros?page=${page}&limit=${pageSize}&buscarCliente=${busqueda}&excedido=${excedido}`, filtros)
  }

  putEstadoCuenta(estado, clienteId): Observable<Estado> {
    return this.http.put<Estado>(`${this.baseURL}/ClientesV2/CambiarEstado/${clienteId}`, estado);   
  }

  actualizarSaldo(tipoDoc, numDoc) { 
    return this.http.put<SaldoCliente>(`${this.baseURL}/SaldosV1/ActualizarSaldo?tipoDocumento=${tipoDoc}&numeroDocumento=${numDoc}`, {})
  }

  getAerovales(id) {
    return this.http.get<any[]>(`${this.saldoURL}/Aerovales/Clientes?ClienteId=${id}`);
  }

  getHistorialBloqueos(clienteId) {
    return this.http.get<Estado[]>(`${this.baseURL}/ClientesV2/EstadosCliente/${clienteId}`)
  }

  crearNotificacion(notificacion) {
    return this.http.post<NotificacionSistemaDTO>(`${this.notificacionesURL}/NotificacionesSistemasV1`, notificacion)
  }

  actualizarNotificacion(notificacion, id) {
    return this.http.put<NotificacionSistemaDTO>(`${this.notificacionesURL}/NotificacionesSistemasV1/${id}`, notificacion)
  }

  descargarExcelActualizacionSaldosClientes(saldoClientes) {
    const url = this.baseURL + '/ExportarV1/SaldosClientes';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });
    return this.http.put(url, saldoClientes, { headers: headers, responseType: 'blob' as 'json' });
  }

  descargarExcelActualizacionAerovales(aerovales) {
    const url = this.baseURL + '/ExportarV1/Aerovales';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });
    return this.http.put(url, aerovales, { headers: headers, responseType: 'blob' as 'json' });
  }
}
