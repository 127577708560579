<div class="card add-margin">

  <h4 class="card-header card-title ">
    <div class="text-center"> {{ parametroImpositivo ? "Modificando parametro impositivo" : "Creando parametro impositivo" }} </div>
  </h4>
  <div class="card-header">
    
    <form [formGroup]="parametroImpositivoForm">

      <div class="row">
        <div class="mb-3 col-sm-6">
          <label for="tipoCodigoFormControl" class="col-sm-8 col-form-label"> Codigo:</label>
          <div class="col-sm-8">
            <select type="text" class="form-select" name="tipoCodigoFormControl"
              formControlName="tipoCodigoFormControl" (change)="onUsuarioSeleccionoCodigo()">
              <option id="optionTipoCodigo" *ngFor="let tipo of  keysTipoCodigoParamImp()"
                [ngValue]="enumTipoCodigoParamImp[tipo]">
                {{ tipo }}
              </option>
            </select>
          </div>
        </div>

        <div class="mb-3 col-sm-6">
          <label for="leyendaFormControl" class="col-sm-8 col-form-label"> Leyenda: </label>
          <div class="col-sm-8">
            <input id="leyendaFormControl" type="text" class="form-control mb-2" formControlName="leyendaFormControl" />
          </div>
        </div>
      </div>

      <!-- <div class="row">
        <div class="mb-3 col-sm-6" [ngStyle]="{'visibility': (soloMostrarLeyenda) ? 'hidden' : 'visible'}">
          <label for="tipoValorFormControl" class="col-sm-8 col-form-label"> Tipo de valor:</label>
          <div class="col-sm-8">
            <select type="text" class="form-control" name="tipoValorFormControl" 
            formControlName="tipoValorFormControl">
              <option id="optionTipoValor" *ngFor="let tipo of  keysTipoValorParamImp()"
                [ngValue]="enumTipoValorParamImp[tipo]">
                {{ getDescripcionTipoValorParametroImpositivo(enumTipoValorParamImp[tipo]) }}
              </option>
            </select>
          </div>
        </div>

        <div class="mb-3 col-sm-6" [ngStyle]="{'visibility': (soloMostrarLeyenda || !mostrarCampoValor) ? 'hidden' : 'visible'}">
          <label for="valorFormControl" class="col-sm-8 col-form-label"> Valor: </label>
          <div class="col-sm-8">
            <input id="valorFormControl" type="number" class="form-control mb-2 me-sm-2" (keypress)="onlyNumberKey($event)"
              formControlName="valorFormControl" name="valorFormControl" />
            <div class="alert-danger"
              *ngIf="(this.valorFormControl.invalid && this.valorFormControl.errors.required && (this.valorFormControl.dirty || this.valorFormControl.touched))">
              El valor debe ser numérico.
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="mb-3 col-sm-6" [ngStyle]="{'visibility': (soloMostrarLeyenda) ? 'hidden' : 'visible'}">
          <label for="tipoBaseFormControl" class="col-sm-8 col-form-label"> Base de Calculo:</label>
          <div class="col-sm-8">
            <select type="text" class="form-control" name="tipoBaseFormControl" formControlName="tipoBaseFormControl">
              <option id="optionTipoBase" *ngFor="let tipo of  keysTipoBaseParamImp()"
                [ngValue]="enumTipoBaseParamImp[tipo]">
                {{ getDescripcionTipoBaseCalculoParametroImpositivo(enumTipoBaseParamImp[tipo]) }}
              </option>
            </select>
          </div>
        </div>

        <div class="mb-3 col-sm-6" [ngStyle]="{'visibility': (soloMostrarLeyenda) ? 'hidden' : 'visible'}">
          <label for="importeMinimoFormControl" class="col-sm-8 col-form-label"> Importe mínimo: </label>
          <div class="col-sm-8">
            <input id="importeMinimoFormControl" type="number" class="form-control mb-2 me-sm-2" (keypress)="onlyNumberKey($event)"
              formControlName="importeMinimoFormControl" name="importeMinimoFormControl" />
            <div class="alert-danger"
              *ngIf="(this.importeMinimoFormControl.invalid && this.importeMinimoFormControl.errors.required && (this.importeMinimoFormControl.dirty || this.importeMinimoFormControl.touched))">
              El valor debe ser numérico.
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="mb-3 col-sm-6" [ngStyle]="{'display': (soloMostrarLeyenda || soloMostrarCamposBasicos) ? 'none' : 'inline'}">
          <label for="alicuotaIBDefectoFormControl" class="col-sm-8 col-form-label"> Alicuota IIBB defecto:</label>
          <div class="col-sm-8">
            <input id="alicuotaIBDefectoFormControl" type="number" class="form-control mb-2 me-sm-2" (keypress)="onlyNumberKey($event)"
              formControlName="alicuotaIBDefectoFormControl" name="alicuotaIBDefectoFormControl" />
            <div class="alert-danger"
              *ngIf="(this.alicuotaIBDefectoFormControl.invalid && this.alicuotaIBDefectoFormControl.errors.required && (this.alicuotaIBDefectoFormControl.dirty || this.alicuotaIBDefectoFormControl.touched))">
              El valor debe ser numérico.
            </div>
          </div>
        </div>

        <div class="mb-3 col-sm-6" [ngStyle]="{'display': (soloMostrarLeyenda || soloMostrarCamposBasicos) ? 'none' : 'inline'}">
          <label for="alicuotaIBDefectoInscriptoFormControl" class="col-sm-8 col-form-label"> Alic. IIBB defecto insc.:</label>
          <div class="col-sm-8">
            <input id="alicuotaIBDefectoInscriptoFormControl" type="number" class="form-control mb-2 me-sm-2" (keypress)="onlyNumberKey($event)"
              formControlName="alicuotaIBDefectoInscriptoFormControl" name="alicuotaIBDefectoInscriptoFormControl" />
            <div class="alert-danger"
              *ngIf="(this.alicuotaIBDefectoInscriptoFormControl.invalid && this.alicuotaIBDefectoInscriptoFormControl.errors.required && (this.alicuotaIBDefectoInscriptoFormControl.dirty || this.alicuotaIBDefectoInscriptoFormControl.touched))">
              El valor debe ser numérico.
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="mb-3 col-sm-6" [ngStyle]="{'display': (soloMostrarLeyenda || soloMostrarCamposBasicos) ? 'none' : 'inline'}">
          <label for="alicuotaIBMultilateralFormControl" class="col-sm-8 col-form-label"> Alicuota IIBB C. Multilateral:</label>
          <div class="col-sm-8">
            <input id="alicuotaIBMultilateralFormControl" type="number" class="form-control mb-2 me-sm-2" (keypress)="onlyNumberKey($event)"
              formControlName="alicuotaIBMultilateralFormControl" name="alicuotaIBMultilateralFormControl" />
            <div class="alert-danger"
              *ngIf="(this.alicuotaIBMultilateralFormControl.invalid && this.alicuotaIBMultilateralFormControl.errors.required && (this.alicuotaIBMultilateralFormControl.dirty || this.alicuotaIBMultilateralFormControl.touched))">
              El valor debe ser numérico.
            </div>
          </div>
        </div>

        <div class="mb-3 col-sm-6" [ngStyle]="{'display': (soloMostrarLeyenda || soloMostrarCamposBasicos) ? 'none' : 'inline'}">
          <label for="alim" class="col-sm-8 col-form-label"> Alicuota IIBB mayorista: </label>
          <div class="col-sm-8">
            <input id="alicuotaIBMayoristaFormControl" type="number" class="form-control mb-2 me-sm-2" (keypress)="onlyNumberKey($event)"
              formControlName="alicuotaIBMayoristaFormControl" name="alicuotaIBMayoristaFormControl" />
            <div class="alert-danger"
              *ngIf="(this.alicuotaIBMayoristaFormControl.invalid && this.alicuotaIBMayoristaFormControl.errors.required && (this.alicuotaIBMayoristaFormControl.dirty || this.alicuotaIBMayoristaFormControl.touched))">
              El valor debe ser numérico.
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="mb-3 col-sm-6" [ngStyle]="{'display': (soloMostrarLeyenda || soloMostrarCamposBasicos) ? 'none' : 'inline'}">
          <label for="alicuotaIBMultilateralFormControl" class="col-sm-8 col-form-label"> Imp. mínimo de percep.:</label>
          <div class="col-sm-8">
            <input id="importeMinimoPercepcionFormControl" type="number" class="form-control mb-2 me-sm-2" (keypress)="onlyNumberKey($event)"
              formControlName="importeMinimoPercepcionFormControl" name="importeMinimoPercepcionFormControl" />
            <div class="alert-danger"
              *ngIf="(this.importeMinimoPercepcionFormControl.invalid && this.importeMinimoPercepcionFormControl.errors.required && (this.importeMinimoPercepcionFormControl.dirty || this.importeMinimoPercepcionFormControl.touched))">
              El valor debe ser numérico.
            </div>
          </div>
        </div>

        <div class="mb-3 col-sm-6">
          
        </div> -->
      <!-- </div> -->

      <div class="card-footer d-flex justify-content-center">
        <span class="border">
          <button class="btn btn-primary" type="button" 
            (click)="onCrearParametroImpositivo()"
            [disabled]="(!parametroImpositivoForm.valid) || guardandoParametroImp">
            <span>Guardar</span>
            <div *ngIf="guardandoParametroImp" class="d-flex align-items-center">
              <div class="spinner-border text-light spinner-border-sm" role="status"></div>
              <span>Guardando...</span>
            </div>
          </button>
        </span>
        <span class="border">
          <button type="button" class="btn btn-secondary" (click)="onCancelar()">Cancelar</button>
        </span>
      </div>

    </form>
  </div>

</div>