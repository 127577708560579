import { Injectable } from '@angular/core';
import { ManagementService } from '../management/management.service';
import { StockService } from '../stock/stock.service';
import { Aeroplanta } from './models/stock/Aeroplanta';

@Injectable({
  providedIn: 'root'
})
export class AeroplantasService {

  aeroplantas: Aeroplanta[]=[];


  constructor( private stockService: StockService) {
    this.getAeroplantasStock();
  }

  getAeroplantasStock() {
    this.stockService.obtenerAeroplantasStock()
      .subscribe(result => {
        this.aeroplantas = result;
        });
  }

  obtenerDescripcionAeroplantaPrecios(codigo: string){
    try{
      var aeroplanta = this.aeroplantas.find(a => a.codigoAeroplanta == codigo);

      return aeroplanta.numeroAeroplanta + " - " + aeroplanta.nombre;
    }catch(Ex){
      return "-";
    }
    
/*
    if(isNaN(Number(codigo))){
      agregar = this.aeroplantas.find(e=>e.codigoAeroplanta===codigo);
    }else{
      agregar = this.aeroplantas.find(e=>e.numeroAeroplanta===codigo);
    }
*/    
  }

  obtenerNombreAeroplanta(codigo: string){
    var aeroplanta = this.aeroplantas.find(a => a.codigoAeroplanta == codigo);

    return aeroplanta.nombre;
  }

}
