import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { ConfiguracionService } from 'src/app/despacho/configuracion.service';
import { CodigoUbicacion } from 'src/app/shared/enums/codigoUbicacion';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';
import { Cliente } from 'src/app/shared/models/cliente/Cliente';
import { Ubicacion } from 'src/app/shared/models/cliente/Ubicacion';
import { Pais } from 'src/app/shared/models/despacho/Pais';
import { Ciudad } from 'src/app/shared/models/facturacion/Ciudad';
import { Provincia } from 'src/app/shared/models/facturacion/Provincia';
import { MessageService } from 'src/app/shell/message.service';
import { ClienteService } from '../cliente.service';
import { CuentasService } from '../cuentas.service';

@Component({
  selector: 'app-modal-direccion-entrega',
  templateUrl: './modal-direccion-entrega.component.html',
  styleUrls: ['./modal-direccion-entrega.component.css']
})
export class ModalDireccionEntregaComponent implements OnInit {
  @Input() clienteDireccionEntrega: Cliente;
  @Output() dirEntrega: EventEmitter<Ubicacion> = new EventEmitter<Ubicacion>();
  errorDestinos: boolean = false;
  ubicacionSeleccionada: string = '';
  
  direccionForm = new UntypedFormGroup({
    idFormControl: new UntypedFormControl('00000000-0000-0000-0000-000000000000'),
    calleFormControl: new UntypedFormControl({value: null, disabled: !this.usuarioModificaCampos()}, [Validators.required]),
    pisoFormControl: new UntypedFormControl({value: null, disabled: !this.usuarioModificaCampos()}),
    deptoFormControl: new UntypedFormControl({value: null, disabled: !this.usuarioModificaCampos()}),
    codigoPostalFormControl: new UntypedFormControl({value: null, disabled: !this.usuarioModificaCampos()}, [Validators.required]),
    provinciaFormControl: new UntypedFormControl({value: null, disabled: !this.usuarioModificaCampos()}, [Validators.required]),
    paisFormControl: new UntypedFormControl({value: null, disabled: !this.usuarioModificaCampos()}, [Validators.required]),
    codigoFormControl: new UntypedFormControl({value: null, disabled: !this.usuarioModificaCampos()}, [Validators.required]),
    numeroFormControl: new UntypedFormControl({value: null, disabled: !this.usuarioModificaCampos()}, [Validators.required]),
    entregaFormControl: new UntypedFormControl({value: null, disabled: true })
  });

  modelPais: any;
  modelProvincia: any;

  modelCiudad: any;
  paises: Pais[];
  provincias: Provincia[];
  ciudades: Ciudad[];
  cargandoPaises: boolean;
  cargandoProvincias: boolean;
  guardando: boolean = false;
  paginaActual: number = 1;
  paginaPrevia: number = 1;
  cargandoCiudades: boolean = false;
  ciudadBuscada: Ciudad;
  editando: boolean = false;
  tipoUbicacion = CodigoUbicacion;
  ubicacionActual: Ubicacion;

  texto: 'Cancelar' | 'Cerrar' = 'Cancelar';

  constructor(public activeModal: NgbModal, private configuracionService: ConfiguracionService, private clienteService: ClienteService,
    private messageService: MessageService, private authService: AuthService, private cuentasService: CuentasService) { }

  ngOnInit() {
    if(this.clienteDireccionEntrega.direccionesEntrega == null){
      this.clienteDireccionEntrega.direccionesEntrega = [];
    }
    this.getPaises();
    if (this.editando && this.clienteDireccionEntrega.direccionesEntrega.length > 0) {
      this.id.setValue(this.clienteDireccionEntrega.direccionesEntrega[this.paginaActual - 1].id);
      this.calle.setValue(this.clienteDireccionEntrega.direccionesEntrega[this.paginaActual - 1].direccion);
      this.codigo.setValue(this.clienteDireccionEntrega.direccionesEntrega[this.paginaActual - 1].tipoUbicacion);
      this.piso.setValue(this.clienteDireccionEntrega.direccionesEntrega[this.paginaActual - 1].piso);
      this.depto.setValue(this.clienteDireccionEntrega.direccionesEntrega[this.paginaActual - 1].departamento);
      this.numero.setValue(this.clienteDireccionEntrega.direccionesEntrega[this.paginaActual - 1].numero);
      this.obtenerEnum();
    }
    
  }

  getPaises() {
    this.cargandoCiudades = true;
    this.cargandoPaises = true;
    this.cargandoProvincias = true;
    this.cuentasService.getPaises()
      .subscribe(result => {
        this.paises = result;
        this.cargandoPaises = false;
        this.obtenerPaisSeleccionado();
      }, () => this.errorDestinos = true)
  }

  obtenerPaisSeleccionado() {
    if (this.clienteDireccionEntrega.direccionesEntrega.length > 0 && this.editando) {
      for (let i = 0; this.paises.length > i; i++) {
        if (this.paises[i].nombre == this.clienteDireccionEntrega.direccionesEntrega[this.paginaActual - 1].ciudad.provincia.pais.nombre) {
          this.pais.setValue(this.paises[i]);
          this.getProvinciasPorPais(this.paises[i].codigo);
        }
      }
    } else {
      for (let i = 0; this.paises.length > i; i++) {
        if (this.paises[i].nombre == 'Argentina') {
          this.pais.setValue(this.paises[i]);
          this.getProvinciasPorPais(this.paises[i].codigo);
        }
      }
    }
  }

  getProvinciasPorPais(codigo: string) {
    this.provincia.setValue(null);
    this.cuentasService.getProvinciasPorPais(codigo)
      .subscribe(result => {
        this.provincias = result;
        this.obtenerProvinciaSeleccionada();
        this.cargandoProvincias = false;
       }, () => this.errorDestinos = true)
  }

  usuarioModificaCampos(): boolean {
    return this.authService.hasRole(TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.APROBADORBYR, TipoUsuario.ADMCLIENTES);
  }

  obtenerProvinciaSeleccionada() {
    if (this.clienteDireccionEntrega.direccionesEntrega.length > 0 && this.editando) {
      for (let i = 0; this.provincias.length > i; i++) {
        if (this.provincias[i].codigo == this.clienteDireccionEntrega.direccionesEntrega[this.paginaActual - 1].ciudad.provincia.codigo) {
          this.provincia.setValue(this.provincias[i]);
          this.getLocalidadPorProvincia(this.provincias[i].codigo);
        }
      }
    } else {
      for (let i = 0; this.provincias.length > i; i++) {
        if (this.provincias[i].codigo == 'BSAS') {
          this.provincia.setValue(this.provincias[i]);
          this.getLocalidadPorProvincia(this.provincias[i].codigo);
        }
      }
    }
  }

  getLocalidadPorProvincia(codigo: string) {
    this.ciudades = [];
    this.cuentasService.getCiudadPorProvincia(codigo)
      .subscribe(result => {
        this.ciudades = result;
        this.cargandoCiudades = false;
        this.obtenerLocalidadSeleccionada();
      }, () => this.errorDestinos = true)
  }

  obtenerLocalidadSeleccionada() {
    if (this.clienteDireccionEntrega.direccionesEntrega.length > 0 && this.editando) {
      for (let i = 0; this.ciudades.length > i; i++) {
        if (this.ciudades[i].codigoPostal == this.clienteDireccionEntrega.direccionesEntrega[this.paginaActual - 1].ciudad.codigoPostal) {
          this.codigoPostal.setValue(this.ciudades[i]);
          // this.codigoPostal.setValue(this.ciudades[i].codigoPostal);
        }
      }
    } else {
      for (let i = 0; this.ciudades.length > i; i++) {
        if (this.ciudades[i].codigoPostal == '1405') {
          this.codigoPostal.setValue(this.ciudades[i]);
          // this.codigoPostal.setValue(this.ciudades[i].codigoPostal);
        }
      }
    }
    // this.spinner.hide('spinnerGrafico');
    this.cargandoCiudades = false;
  }

  searchPais = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 || this.cargandoPaises ? [] :
        this.paises.filter(v => this.containsString(this.pais.value, v.nombre, v.codigo)).slice(0, 10))
    )
  searchProvincia = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 || this.cargandoProvincias ? [] :
        this.provincias.filter(v => this.containsString(this.provincia.value, v.nombre, v.codigo)).slice(0, 10))
    )
  searchCiudad = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 || this.cargandoCiudades ? [] :
        this.ciudades.filter(v => this.containsString(this.codigoPostal.value, v.nombre, v.codigoPostal)).slice(0, 10))
    )
  /**
   * Determina si una cadena se encuentra dentro de otra
   * @param searchValue Valor a buscar
   * @param searchIn Valores sobre los que buscar searchValue
   */
  containsString(searchValue: string, ...searchIn: string[]): boolean {
    const concat = searchIn.toString();
    return concat.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
  }
  
  selectPais(e: any): void {
    console.log('e:' + e.item);
    this.pais.setValue(e.item.codigo);
    this.provincia.setValue(null);
    this.codigoPostal.setValue(null);
    this.getProvinciasPorPais(e.item.codigo);
  }

  selectProvincia(e: any): void {
    console.log('e:' + e.item);
    this.provincia.setValue(e.item.codigo);
    this.codigoPostal.setValue(null);
    this.getLocalidadPorProvincia(e.item.codigo);
  }

  selectCiudades(e: any): void {
    this.codigoPostal.setValue(e.item.nombre);
    this.ciudadBuscada = e.item.nombre;
    this.codigoPostal.setValue(e.item.codigoPostal);
  }

  formatterPais = (x: { nombre: string, codigo: string }) => `${x.nombre} - ${x.codigo}`;
  formatterProvincia = (x: { nombre: string, codigo: string }) => `${x.nombre} - ${x.codigo}`;
  formatterCiudad = (x: { nombre: string, codigoPostal: string }) => `${x.nombre} - ${x.codigoPostal}`;

  guardarDireccion() {
    this.guardando = true;
    let p = new Ubicacion();
    p.id = this.id.value;
    p.direccion = this.calle.value;
    p.piso = this.piso.value;
    p.departamento = this.depto.value;
    p.numero = this.numero.value;
    p.ciudad = this.codigoPostal.value;
    p.ciudad.provincia = this.provincia.value;
    p.ciudad.provincia.pais = this.pais.value;
    p.tipoUbicacion = parseInt(this.codigo.value);
    p.codigoUbicacion = parseInt(this.codigo.value);

    if (this.editando) {
      if (this.direccionForm) {
        this.clienteDireccionEntrega.direccionesEntrega[this.paginaActual - 1] = p;
        this.messageService.showWarningMessage('Se modificó la dirección correctamente. \n Recuerde guardar los cambios del cliente.', false)
        this.guardando = false;
        this.texto = 'Cerrar';
      } else {
        this.messageService.showErrorMessage('Por favor revise los campos a completar.');
        this.guardando = false;        
      }
    }
    else // si estás creando
    {
      this.dirEntrega.emit(p);
      this.activeModal.dismissAll();
    }
  }

  cambiarPagina(event) {
    this.paginaActual = event;
    this.id.setValue(this.clienteDireccionEntrega.direccionesEntrega[this.paginaActual - 1].id); 
    this.codigoPostal.setValue(null);
    this.provincia.setValue(null);
    this.pais.setValue(null);
    this.calle.setValue(this.clienteDireccionEntrega.direccionesEntrega[this.paginaActual - 1].direccion);
    this.numero.setValue(this.clienteDireccionEntrega.direccionesEntrega[this.paginaActual - 1].numero)
    this.codigo.setValue(this.clienteDireccionEntrega.direccionesEntrega[this.paginaActual - 1].tipoUbicacion);
    this.piso.setValue(this.clienteDireccionEntrega.direccionesEntrega[this.paginaActual - 1].piso);
    this.depto.setValue(this.clienteDireccionEntrega.direccionesEntrega[this.paginaActual - 1].departamento);
    this.obtenerEnum();
    this.getPaises();
    this.texto = 'Cancelar';
  }

  keysCodigoUbicacion() {
    var keys = Object.keys(this.tipoUbicacion);
    keys = keys.slice(1, 5);
    return keys;
  }

  onlyDecimalPositivo(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46;
  }

  obtenerEnum() {
    this.ubicacionSeleccionada = CodigoUbicacion[this.codigo.value];
    this.entrega.setValue(this.ubicacionSeleccionada);
  }

  get id() {
    return this.direccionForm.get('idFormControl');
  }

  get calle() {
    return this.direccionForm.get('calleFormControl');
  }
  get piso() {
    return this.direccionForm.get('pisoFormControl');
  }
  get codigoPostal() {
    return this.direccionForm.get('codigoPostalFormControl');
  }
  get provincia() {
    return this.direccionForm.get('provinciaFormControl');
  }
  get pais() {
    return this.direccionForm.get('paisFormControl');
  }
  get codigo() {
    return this.direccionForm.get('codigoFormControl');
  }
  get entrega() {
    return this.direccionForm.get('entregaFormControl');
  }
  get depto(){
    return this.direccionForm.get('deptoFormControl');
  }
  get numero(){
    return this.direccionForm.get('numeroFormControl');
  }

}
