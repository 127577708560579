<div *ngIf="obteniendoCuentas" class="border p-3">
    <loading-text-spinner message="Obteniendo cuentas del cliente..."
        orientation="vertical" class="list-group-item" size="large" color="primary" textColor="dark">
    </loading-text-spinner>
</div>

<div *ngIf="!cargandoDatos()">
    <h4 class="mb-3">
        {{ cliente.razonSocial }} {{ cliente.numeroDocumento }}
    </h4>
    <div *ngIf="cuentas != null && cuentas.length > 0" class="contenedor-der">
        <div *ngFor="let fc of cuentasArrayFormControl.controls; let i = index">
            <div class="col col-md-12"  [formGroup]="form">
                <div class="card border-item" formArrayName="cuentasArrayFormControl">
                    <div [formGroupName]="i" class="card-header bg-light mb-1" [ngClass]="{'cuenta-modificada' : cuentas[i]?._local_modificada}">
                        <h4 class="modal-title">
                            Cuenta N°: {{cuentas[i]?.ctA_SGC}} - {{cuentas[i]?.descripcion}}
                            <ng-container style="margin-left: -1em;" *ngIf="cuentas[i]?.tipoCuenta != TipoCuenta.Contado">
                                <input type="checkbox" id="checkboxLimitada" formControlName="limitada" style="margin-left: 2.5em" 
                                    (change)="onCambioLimitada(cuentas[i], false)"/> 
                                <label style="margin-left: 0.5em; font-size: 0.8em;">Limitada</label>
                                <input type="checkbox" id="checkboxLimitadaGranel" formControlName="limitadaGranel" style="margin-left: 3em" 
                                    (change)="onCambioLimitada(cuentas[i], true)"/> 
                                <label style="margin-left: 0.5em; font-size: 0.8em;">Limitada Granel</label>
                            </ng-container> 
                            <label 
                                *ngIf="cuentas[i]?.tipoCuenta == TipoCuenta.Contado"                                 
                                style="font-size: 12px; margin-left: 1em" class="alert alert-warning">Cuentas de contado no tienen limitación.
                            </label>                             
                            <button class="btn btn-primary" style="float: right;" (click)="onMatriculaAgregada(cuentas[i], i)">                                
                                <span *ngIf="!agregandoCuenta">Agregar matricula</span>
                                <div *ngIf="agregandoCuenta" class="d-flex align-items-center">
                                    <div class="spinner-border text-light spinner-border-sm" role="status"></div>
                                    <span>Aguarde...</span>
                                </div>                                
                            </button>
                            <div class="col-md-4" style="width: 200px; float: right;">
                                <div class="input-group">
                                  <input class="form-control" type="text" size="12" maxlength="10"  formControlName="matricula"/>        
                                  <div *ngIf="errorMensage != '' " class="alert alert-danger fs-6" role="alert">
                                    {{ errorMensage }}
                                  </div>
                                </div>
                            </div>                                                     
                        </h4>                    
                    </div>
                    <div class="containter">                    
                        <ng-container *ngIf="cuentas[i]?.vehiculos.length > 0">                            
                            <div class="row">
                                <table class="tabla-matriculas col-4 mt-2 mb-2">
                                    <tr *ngFor="let v of cuentas[i].vehiculos; let j = index" [ngClass]="{'vehiculo-modificada' : cuentas[i]?.vehiculos[j]._editado }" >
                                        <td ngbTooltip="Click para editar matricula" (click)="onMatriculaEditar(cuentas[i], v, j)">{{ v.matricula }} [{{ v.tipoVehiculo == TipoVehiculo.Aeronave ? "Aeronave": "Automotor"}}]</td>
                                        <td style="float: right;"><button type="button" (click)="onMatriculaEliminada(cuentas[i], v, j)" class="btn btn-sm btn-danger ms-3"><i class="fa fa-trash"></i> Quitar</button></td>
                                    </tr>
                                </table>
                            </div>    
                        </ng-container>
                        <ng-container *ngIf="cuentas[i]?.vehiculos.length == 0">  
                            <div class="alert alert-warning m-3" role="alert">
                                No hay matriculas en esta cuenta del cliente.
                            </div>            
                        </ng-container>     
                        <button *ngIf="cuentas[i]?._local_modificada || seEditoVehiculo(cuentas[i])" class="btn btn-primary" style="float: right;" (click)="guardarCambios(cuentas[i])" [disabled]="guardandoCambios">                            
                            <span *ngIf="!guardandoCambios">Guardar cambios</span>
                            <div *ngIf="guardandoCambios" class="d-flex align-items-center">
                                <div class="spinner-border text-light spinner-border-sm" role="status"></div>
                                <span>Guardando...</span>
                            </div>
                        </button>                                               
                    </div>                                     
                </div>
            </div>      
        </div>        
    </div>
</div>