<div [formGroup]="form">
  <div class="mb-3 col-md-6">

    <div class="mb-3 row">
      <label for="numeroAerovaleFormControl" class="col-sm-4 col-form-label"><span
          *ngIf="esCampoVacio(this.numeroAerovaleFormControl.value)">*</span> Número de
        Aerovale:</label>
      <div class="col-sm-6">
        <input id="numeroAerovaleFormControl" (keypress)="onlyNumberKey($event)" type="text" maxlength="8"
          class="form-control mb-2 me-sm-2" title="Campo obligatorio, debe ingresar un número de aerovale."
          formControlName="numeroAerovaleFormControl" placeholder="########" />
        <div class="alert alert-danger"
          *ngIf="numeroAerovaleFormControl.invalid && numeroAerovaleFormControl.errors.required && (numeroAerovaleFormControl.dirty || numeroAerovaleFormControl.touched)">
          Requerido
        </div>
        <div class="alert alert-danger"
          *ngIf="numeroAerovaleFormControl.invalid && (numeroAerovaleFormControl.errors.nroAerovaleFueraDeRango) && (numeroAerovaleFormControl.dirty || numeroAerovaleFormControl.touched)">
          La numeración de aerovales de contingencia debe ser inferior al número 19999999
        </div>
      </div>
    </div>

    <div class="mb-3 row">

      <label for="operadorFormControl" class="col-sm-4 col-form-label"> <span
          *ngIf="esCampoVacio(this.operadorFormControl.value)">*</span>Operadores:</label>
      <div class="col-sm-6">
        <input id="operadorFormControl" type="text" class="form-control" formControlName="operadorFormControl"
          [inputFormatter]="formatterOperador" [resultFormatter]="formatterOperador"
          title="Campo obligatorio, debe seleccionar o ingresar un operador." (selectItem)="selectOperador($event)"
          [ngbTypeahead]="searchOperador" />

        <loading-text-spinner *ngIf="cargandoOperadores" [error]="errorOperadores" message="Obteniendo operadores..."
          color="primary">
        </loading-text-spinner>

        <div *ngIf="
                            operadorFormControl.invalid &&
                            operadorFormControl.errors.required &&
                            (operadorFormControl.dirty || operadorFormControl.touched)
                          " class="alert alert-danger">
          Requerido
        </div>
      </div>
    </div>

    <div class="mb-3 row" *ngIf="tipoAerovale != enumTipoAerovale.Aeroespecialidad">
      <label for="abastecedoraFormControl" class="col-sm-4 col-form-label">
        <span
          *ngIf="tipoAerovale != enumTipoAerovale.Aeroespecialidad && esCampoVacio(this.abastecedoraFormControl.value)">*</span>Abastecedora:</label>
      <div class="col-sm-6">
        <select id="abastecedoraFormControl" class="form-select" type="number"
          ngbTooltip="Seleccione una abastecedora." formControlName="abastecedoraFormControl"
          (change)="buscarTanques()">
          <option *ngFor="let abastecedora of abastecedoras" [ngValue]="abastecedora">{{ abastecedora.nombre }}
          </option>
        </select>
        <loading-text-spinner *ngIf="cargandoAbastecedoras" [error]="errorClientes"
          message="Obteniendo abastecedoras..." color="primary">
        </loading-text-spinner>
        <div class="alert alert-danger"
          *ngIf="(this.abastecedoraFormControl.invalid && this.abastecedoraFormControl.errors.required && (this.abastecedoraFormControl.dirty || this.abastecedoraFormControl.touched))">
          Debe seleccionar una opcion de abastecedora
        </div>
      </div>
    </div>

    <div class="mb-3 row" *ngIf="this.abastecedoraFormControl.value != null">
      <label for="productoFormControl" class="col-sm-4 col-form-label"> Producto:</label>
      <div class="col-sm-6">
        <input class="form-control mb-2" formControlName="productoFormControl"
          value="{{this.abastecedoraFormControl.value.productoActual.nombreProducto}}" readonly>
      </div>
    </div>

    <!--TANQUE SELECCIONADO-->
    <div class="mb-3 row"
      *ngIf="this.abastecedoraFormControl.value != null && this.abastecedoraFormControl.value.tipoExpendedora == 1">
      <label for="tanquesAsociadosFormControl" class="col-sm-4 col-form-label">Tanque de extracción:</label>
      <div class="col-md-6">
        <select id="tanquesAsociadosFormControl" class="form-select" title="Seleccione un tanque."
          *ngIf="!obteniendoTanquesSurtidor && surtidores != null" formControlName="tanquesAsociadosFormControl">
          <option *ngFor="let tanques of surtidores.tanques" [ngValue]="tanques.codigo">{{ tanques.nombre }}
          </option>
        </select>
        <loading-text-spinner *ngIf="obteniendoTanquesSurtidor" [error]="errorOperadores"
          message="Obteniendo tanques asociados..." color="primary">
        </loading-text-spinner>
        <div class="alert alert-warning" *ngIf="!obteniendoTanquesSurtidor && surtidores != null && surtidores.tanques.length == 0">
          Se debe asignar al menos un tanque al surtidor para poder realizar el despacho. Avise a su supervisor.
        </div>
        <div class="alert alert-danger" *ngIf="form.invalid && this.form.hasError('noSeleccionoTanque')">
          Debe seleccionar un tanque.
        </div>
      </div>
    </div>

    <!--PICO SELECCIONADO-->
    <div class="mb-3 row"
      *ngIf="this.abastecedoraFormControl.value != null">
      <label for="picosAsociadosFormControl" class="col-sm-4 col-form-label">Aforador utilizado:</label>
      <div class="col-md-6">
        <select id="picosAsociadosFormControl" class="form-select" title="Seleccione un pico." formControlName="picosAsociadosFormControl">
          <option *ngFor="let pico of this.abastecedoraFormControl.value.picos" [ngValue]="pico">{{ pico.nombre }}
          </option>
        </select>
        <div class="alert alert-warning" *ngIf="this.abastecedoraFormControl.value.picos.length == 0">
          Se debe asignar al menos un pico a la abastecedora para poder realizar el despacho. Avise a su supervisor.
        </div>
        <div class="alert alert-danger" *ngIf="form.invalid && this.form.hasError('noSeleccionoPico')">
          Debe seleccionar un pico.
        </div>
      </div>
    </div>

    <div class="mb-3 row">
      <label for="fechaLlegadaFormControl" class="col-sm-4 col-form-label">
        <span *ngIf="tipoAerovale == enumTipoAerovale.Alije || tipoAerovale == enumTipoAerovale.Aerocombustible">Fecha y
          hora de llegada a posición:</span>
        <span
          *ngIf="tipoAerovale == enumTipoAerovale.Combustible || tipoAerovale == enumTipoAerovale.Aeroespecialidad">Fecha
          y hora de despacho:</span>
      </label>
      <div #fechaHora class="col-sm-6">
        <div class="input-group mb-2">
          <input type="date" class="form-control" formControlName="fechaLlegadaFormControl" placeholder="aaaa-mm-dd"
            name="dp" title="Campo obligatorio." [(ngModel)]="modelFechaLlegada" ngbDatepicker #l="ngbDatepicker"
            readonly />
          <div class="input-group-append">
            <button type="button" class="input-group-btn btn btn-secondary" (click)="l.toggle()">
              <i class="fa fa-calendar" aria-hidden="true"></i>
            </button>
          </div>
        </div>
        <ngb-timepicker [(ngModel)]="modelHoraLlegada" [meridian]="true" formControlName="horaLlegadaFormControl"
          [readonlyInputs]="true"></ngb-timepicker>
        <div class="alert alert-danger" *ngIf="form.invalid && this.form.hasError('fechaLlegadaMayorAhora')">
          Esta cargando una fecha de llegada en el futuro.
        </div>
      </div>
    </div>

    <div class="mb-3 row">
      <label for="clienteFormControl" class="col-sm-4 col-form-label"><span
          *ngIf="esCampoVacio(this.clienteFormControl.value)">*</span> Cliente:</label>
      <div class="col-sm-6">
        <div class="input-group mb-2">
          <input id="clienteFormControl" type="text" class="form-control" formControlName="clienteFormControl"
            [inputFormatter]="formatter" [resultFormatter]="formatter"
            title="Campo obligatorio, debe seleccionar o ingresar un cliente." (change)="confirmCustomer()"
            [ngbTypeahead]="search" (selectItem)="selectCustomer($event)" />
          <div class="input-group-append">
            <button type="button" class="input-group-btn btn btn-secondary"
              (click)="nuevoCliente(clienteFormControl.value)" tabindex="-1">
              <i class="fa fa-plus-square" aria-hidden="true"></i>
            </button>
          </div>
        </div>
        <loading-text-spinner *ngIf="cargandoClientes" [error]="errorClientes" message="Obteniendo clientes..."
          color="primary">
        </loading-text-spinner>

        <div class="alert alert-danger"
          *ngIf="clienteFormControl.invalid && clienteFormControl.errors.required && (clienteFormControl.dirty || clienteFormControl.touched)">
          Requerido
        </div>
      </div>
    </div>

    <div class="mb-3 row">
      <label for="vehiculoFormControl" class="col-sm-4 col-form-label"><span
          *ngIf="esCampoVacio(vehiculoFormControl.value) && this.tipoAerovale!=TipoAerovale.Aeroespecialidad">*</span> Vehiculo:</label>
      <div class="col-sm-6">
        <div class="input-group mb-2">
          <ng-template #aeronaveRt let-r="result" let-t="term">
            <ngb-highlight [result]="r.matricula" [term]="t"></ngb-highlight>
          </ng-template>
          <input id="vehiculoFormControl" type="text" class="form-control" formControlName="vehiculoFormControl"            
            [resultTemplate]="aeronaveRt" [inputFormatter]="formatterVehiculo" maxlength="10"
            title="Campo obligatorio, debe seleccionar una aeronave." [ngbTypeahead]="searchVehiculo"
            (change)="changeVehiculo()" (selectItem)="selectVehiculo($event)" />
          <div class="input-group-append">
            <button type="button" class="input-group-btn btn btn-secondary" (click)="nuevoVehiculo(vehiculoFormControl)"
              tabindex="-1">
              <i class="fa fa-plus-square" aria-hidden="true"></i>
            </button>
          </div>
        </div>
        <loading-text-spinner *ngIf="cargandoVehiculosDeCliente" message="Obteniendo vehiculos del cliente..."
          color="primary">
        </loading-text-spinner>
        <div class="alert alert-danger"
          *ngIf="vehiculoFormControl.invalid && vehiculoFormControl.errors.required && (vehiculoFormControl.dirty || vehiculoFormControl.touched)">
          Requerido
        </div>
        <div class="alert alert-warning" *ngIf="this.combustibleNoCoincide">
          La abastecedora y el vehiculo tienen distinto producto.
        </div>
      </div>
    </div>
    <div class="mb-3 row" *ngIf="this.clienteFormControl.value != null">
      <label for="tipoPagoFormControl" class="col-sm-4 col-form-label"><span
        *ngIf="esCampoVacio(tipoPagoFormControl.value) && this.tipoAerovale!=TipoAerovale.Aeroespecialidad">*</span>Tipo de pago:</label>
      <div class="col-sm-6">
        <select id="tipoPagoFormControl" type="text" class="form-select" name="tipoPagoFormControl"
          formControlName="tipoPagoFormControl" title="Seleccione un tipo de movimiento. Campo requerido.">
          <option id="optionTipoPago" *ngFor="let tipo of obtenerTipoDePago(this.clienteFormControl.value.tipoCuenta)"
            [ngValue]="tipoPago[tipo]">
            {{ tipo }}
          </option>
        </select>
        <div class="alert alert-danger"
          *ngIf="(this.tipoPagoFormControl.invalid)">
          Debe seleccionar un tipo de pago
        </div>
      </div>
    </div>

    <div class="mb-3 row">
      <label for="observacionesFormControl" class="col-sm-4 col-form-label">Observaciones:</label>
      <div class="col-sm-6">
        <select id="observacionesFormControl" class="form-select" type="number"
          title="Campo obligatorio, debe seleccionar una observacion." formControlName="observacionesFormControl">
          <option *ngFor="let observacion of observaciones" [ngValue]="observacion">
            {{ observacion.nombre }}</option>
        </select>
      </div>
    </div>

    <div class="mb-3 row">
      <label for="comentariosFormControl" class="col-sm-4 col-form-label">Comentarios:</label>
      <div class="col-sm-6">
        <div class="input-group mb-2">
          <textarea id="comentariosFormControl" formControlName="comentariosFormControl" type="text"
            style="min-width: 100%" rows="6" mdbInput></textarea>
        </div>
      </div>
    </div>

  </div>
</div>
