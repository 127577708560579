import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UsuarioService } from 'src/app/account/usuario.service';
import { MessageService } from 'src/app/shell/message.service';
import { ControlCreditoService } from '../control-credito.service';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { Usuario } from 'src/app/shared/models/despacho/Usuario';
import { TipoUsuario } from '../../shared/enums/tipoUsuario';
import { AuthService } from 'src/app/core/authentication/auth.service';

@Component({
  selector: 'app-modal-crear-notificacion',
  templateUrl: './modal-crear-notificacion.component.html',
  styleUrls: ['./modal-crear-notificacion.component.scss']
})
export class ModalCrearNotificacionComponent implements OnInit {

  crearNotificacionForm: FormGroup;
  mailsNotificacion: string[] = [];
  montoNotificacion: number;
  @Input() cliente;  
  @Input() idNotificacionSaldos;
  editandoContacto: boolean = false;
  grabando: boolean = false;
  TipoUsuario = TipoUsuario;
  constructor(private formBuilder:FormBuilder, public authService: AuthService, public activeModal: NgbActiveModal, public messageService: MessageService,private controlCreditoService: ControlCreditoService, private usuarioService: UsuarioService) { }

  ngOnInit(): void {
    this.crearFormulario();
  }
  
  crearFormulario() {
    this.crearNotificacionForm = this.formBuilder.group({
      emailFormControl: ['', Validators.email],
      montoFormControl: [0]
    });
  }

  agregarNotificacion() {
    this.grabando = true;
    if(this.mailsNotificacion.length > 0) {
      //chequear esto
      let notificacion = {
        "Activo": true,
        "Emails": this.mailsNotificacion,
        "TipoNovedad": {
          "Id": this.idNotificacionSaldos,
          "Descripcion": "Saldos"
        },
        "Mensaje": "El cliente " + this.cliente.clienteRazonSocial + " supero el monto asignado de " + this.montoFormControl.value,
        "Titulo": "Cliente excedido",
        "Usuarios": [{
          "Id": this.usuarioService.getIdUsuario(),
          "NombresCompleto": this.usuarioService.getNombresCompletosUsuario(),
          "DNI": this.usuarioService.getDniUsuario(),
          "Email": this.usuarioService.getEmailUsuario()
        }],
        "FiltrosSeleccionados": [{
          "Clave": "ClienteId",
          "Valor": this.cliente.clienteId,
          "OperadorLogico": 0,
          "Activo": true
        },
        {
          "Clave": "Monto",
          "Valor": this.montoFormControl.value.toString(),
          "OperadorLogico": 2,
          "Activo": true
        }]
      }
      
      this.controlCreditoService.crearNotificacion(notificacion).subscribe(notificacion => {
        this.cliente.notificacionSaldo = notificacion;
        notificacion.filtrosSeleccionados.forEach(filtro => {
          if(filtro.clave === "Monto") this.cliente.monto = filtro.valor;
          this.grabando = false;
          this.activeModal.close(false);
        })
      }, (err: ErrorModel) => {
        this.messageService.showErrorMessage('Hubo un error al obtener los datos. ' + err.description);
        this.grabando = false;
        this.activeModal.close(false);
      });
    }
  }

  cargarFormulario() {
    
  }

  borrarMail(i) {
    this.mailsNotificacion.splice(i, 1)
  }

  agregarMail() {
    if(this.emailFormControl.valid && this.emailFormControl.value) {
      this.mailsNotificacion.push(this.emailFormControl.value);
      this.emailFormControl.setValue('');
    }
  }

  /** getters */

  get emailFormControl() {
    return this.crearNotificacionForm.get('emailFormControl');
  }

  get montoFormControl() {
    return this.crearNotificacionForm.get('montoFormControl');
  }
}
