import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Balance7ComparativaEncabezadoDTO } from '../shared/models/cierreTurno/Balance7ComparativaEncabezadoDTO';
import { Balance7MedicionesDTO } from '../shared/models/cierreTurno/Balance7MedicionesDTO';
import { CargaDatos } from '../shared/models/cierreTurno/CargaDatos';
import { CierreTurno } from '../shared/models/cierreTurno/CierreTurno';
import { Comparativas } from '../shared/models/cierreTurno/Comparativas';
import { contenedorCierre } from '../shared/models/cierreTurno/contenedorCierre'
import { Precierre } from '../shared/models/cierreTurno/Precierre';
import { ReporteMovimientoBalance7 } from '../shared/models/cierreTurno/ReporteMovimientoBalance7';
import { Responsable } from '../shared/models/stock/Responsable';
import { Comparativa } from '../shared/models/cierreTurno/Comparativa';
import { Balance7ActaDTO } from '../shared/models/cierreTurno/FechaDto';
import { FechaDTO } from '../shared/models/cierreTurno/Balance7ActaDTO';
import { Balance7ActaDocumentosDTO } from '../shared/models/cierreTurno/Balance7ActaDocumentosDTO';
import { ReporteMovimientoPorTipoBalance7 } from '../shared/models/cierreTurno/ReporteMovimientoPorTipoBalance7';
import { ReporteMovimientoDetalleBalance7 } from '../shared/models/cierreTurno/ReporteMovimientoDetalleBalance7';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { DetalleParteDiario } from '../shared/models/cierreTurno/DetalleParteDiario';
import { ParteDiario } from '../shared/models/cierreTurno/ParteDiario';
import { SistemaSeguridad } from '../shared/models/stock/SistemaSeguridad';
import { EquipoFijo } from '../shared/models/stock/EquipoFijo';
import { isThisTypeNode } from 'typescript';
import { EstadoTanque } from '../shared/models/cierreTurno/EstadoTanque';
import { EstadoExpendedora } from '../shared/models/cierreTurno/EstadoExpendedora';
import { Aeroplanta } from '../shared/models/cierreTurno/Aeroplanta';
import { Contacto } from '../shared/models/cierreTurno/Contacto';
import { ResolucionConfiguracion } from '../shared/models/cierreTurno/ResolucionConfiguracion';
import { Step } from '../shared/models/cierreTurno/step';
import { EtapasCierreTurno } from '../shared/enums/EtapasCierreTurno';

export interface step {
  label: string, 
  completed: boolean
}
@Injectable({
  providedIn: 'root'
})
export class CierreTurnoService {

  cierreTurnoBase = environment.apiServer.cierreTurnoBaseUrl;
  stockBase = environment.apiServer.stockBaseUrl;
  facturacionBase = environment.apiServer.facturacionBaseUrl;
  private cierreURL = this.cierreTurnoBase + '/CierresTurnosV1/';
  private precierreURL = this.cierreTurnoBase + '/PrecierresV1/';
  private balance7URL = this.cierreTurnoBase + '/Balance7V1/';
  private reportesUrl = this.stockBase + '/ReportesV1/'; 
  private facturacionUrl = this.facturacionBase + '/FacturacionV1/';
  private aeroplantasUrl = this.cierreTurnoBase + '/AeroplantasV1';

  estadoTanque: EstadoTanque;
  estadoExpendedora: EstadoExpendedora;  
  EtapasCierreTurno=EtapasCierreTurno;

  private configuracionesURL = this.cierreTurnoBase + '/ConfiguracionesV1/';

  private steps: Step[] = [
    { label: EtapasCierreTurno.Etp1, completed: false },
    { label: EtapasCierreTurno.Etp2, completed: false },
    { label: EtapasCierreTurno.Etp3, completed: false },
    { label: EtapasCierreTurno.Etp4, completed: false },
    { label: EtapasCierreTurno.Fin, completed: false }
  ];

  private stepsSubject = new BehaviorSubject<Step[]>(this.steps);
  steps$ = this.stepsSubject.asObservable();

  constructor(private http: HttpClient) {
  }

  updateStepProgress(index: number, completed: boolean): void {
    this.steps[index].completed = completed;
    this.stepsSubject.next(this.steps);
  }

  initStepProgress() {
    this.steps.forEach(s=>s.completed=false);    
  }
  
  ObtenerCierresTurnos(): Observable<CierreTurno[]> {
    return this.http.get<CierreTurno[]>(this.cierreTurnoBase + "/CierresTurnosV1");
  }

  // UltimoMovimientoContenedores(cierreTurnoId: string): Observable<movimiento> {
  //   return this.http.get<movimiento>(this.cierreTurnoBase + "/MovimientosV1/" + cierreTurnoId)
  // }

  ObtenerMovimientosDeContenedoresCierreDeTurno(cierreTurnoId: string): Observable<contenedorCierre[]> {
    return this.http.get<contenedorCierre[]>(this.cierreTurnoBase + "/MovimientosV1/" + cierreTurnoId)
  }


  getCierreTurno(codigoAeroplanta: string): Observable<CierreTurno> {
    return this.http.get<CierreTurno>(this.cierreURL + codigoAeroplanta + '/Cerrado');
  }

  getCierreSegunEstado(codigoAeroplanta: string, estado: number): Observable<CierreTurno> {
    return this.http.get<CierreTurno>(this.cierreURL + 'Estado?codigoAeroplanta=' + codigoAeroplanta + '&estado=' + estado)
  }

  postCierreIniciacion(codigoAeroplanta: string, responsable: Responsable): Observable<CierreTurno> {
    return this.http.post<CierreTurno>(this.cierreURL + codigoAeroplanta + '/Iniciar', responsable);
  }

  postCierreCargarDatos(codigoAeroplanta: string, carga: CargaDatos, cierre: CierreTurno): Observable<Comparativas> {
    return this.http.post<Comparativas>(this.cierreURL + cierre.id + '/CargarDatos', carga);
  }

  getCierresPorFecha(codigoAeroplanta: string, desde: string, hasta: string): Observable<CierreTurno[]> {
    return this.http.get<CierreTurno[]>(this.cierreTurnoBase + '/CierresTurnosV1?codigoAeroplanta=' + codigoAeroplanta + '&fechaDesde=' + desde + '&fechaHasta=' + hasta)
  }

  precerrarAbastecedora(cierreId: string, precierre: Precierre){
    return this.http.post<Comparativas>(this.precierreURL + cierreId, precierre);
  }

  actualizarPrecierreAbastecedora(cierreId: string, precierre: Precierre){
    return this.http.put<Comparativas>(this.precierreURL + cierreId, precierre);
  }

  getUltimasMedicionesBalance7(codigoAeroplanta: string, desde: string, hasta: string): Observable<Balance7MedicionesDTO> {
    return this.http.get<Balance7MedicionesDTO>(this.balance7URL + 'UltimasMediciones?codigoAeroplanta=' + codigoAeroplanta + '&fechaDesde=' + desde + '&fechaHasta=' + hasta)
  }

  getComparativasBalance7(codigoAeroplanta: string, desde: string, hasta: string): Observable<Balance7ComparativaEncabezadoDTO> {
    return this.http.get<Balance7ComparativaEncabezadoDTO>(this.balance7URL + 'Comparativas?codigoAeroplanta=' + codigoAeroplanta + '&fechaDesde=' + desde + '&fechaHasta=' + hasta)
  }

  getComparativaDetalleBalance7(cierreTurnoId: string, codigoProducto: string, tipoComparativa: number): Observable<Comparativa[]> {
    return this.http.get<Comparativa[]>(this.balance7URL + 'ComparativasPorCierreDeTurno?cierreTurnoId=' + cierreTurnoId + '&codigoProducto=' + codigoProducto + '&tipoComparativa=' + tipoComparativa)
  }

  getIdsCierreTurnoBalance7PorCierre(codigoAeroplanta: string, desde: string, hasta: string): Observable<string[]> {
    return this.http.get<string[]>(this.cierreURL + 'CierresIds?codigoAeroplanta=' + codigoAeroplanta + '&fechaDesde=' + desde + '&fechaHasta=' + hasta)
  }

  getMovimientosBalance7PorFecha(listaIdsCierreTurno: string[], codigoAeroplanta: string, desde: string, hasta: string, incluirAjustes: boolean): Observable<ReporteMovimientoBalance7[]> {   
    return this.http.put<ReporteMovimientoBalance7[]>(this.reportesUrl + 'Fechas/ReporteMovimientos?codigoAeroplanta=' + codigoAeroplanta + '&fechaDesde=' + desde + '&fechaHasta=' + hasta +'&incluirAjustes=' + incluirAjustes, listaIdsCierreTurno)
  }

  getMovimientosBalance7PorFechaPromise(listaIdsCierreTurno: string[], codigoAeroplanta: string, desde: string, hasta: string, incluirAjustes: boolean): Promise<ReporteMovimientoBalance7[]> {   
    return this.http.put<ReporteMovimientoBalance7[]>(this.reportesUrl + 'Fechas/ReporteMovimientos?codigoAeroplanta=' + codigoAeroplanta + '&fechaDesde=' + desde + '&fechaHasta=' + hasta +'&incluirAjustes=' + incluirAjustes, listaIdsCierreTurno).toPromise();
  }
  
  obtenerReporteMovimientoAgrupadoPorTipoCierreTurno(listaIdsCierreTurno:string[], codigoProducto:string, codigoAeroplanta: string, tipoReporte: boolean, incluirAjustes: boolean): Observable<ReporteMovimientoPorTipoBalance7[]> {
    return this.http.put<ReporteMovimientoPorTipoBalance7[]>(this.reportesUrl + 'CierreTurno/ReporteMovimientos/AgrupadoPorTipo?codigoProducto=' + codigoProducto + '&codigoAeroplanta=' + codigoAeroplanta + '&tipoReporte=' + tipoReporte + '&incluirAjustes=' + incluirAjustes, listaIdsCierreTurno)
  }

  obtenerReporteMovimientoAgrupadoPorTipoFecha(codigoProducto:string, codigoAeroplanta: string, tipoReporte: boolean, desde: string, hasta: string, incluirAjustes: boolean): Observable<ReporteMovimientoPorTipoBalance7[]> {
    return this.http.put<ReporteMovimientoPorTipoBalance7[]>(this.reportesUrl + 'Fechas/ReporteMovimientos/AgrupadoPorTipo?codigoProducto=' + codigoProducto + '&codigoAeroplanta=' + codigoAeroplanta + '&tipoReporte=' + tipoReporte + '&incluirAjustes='+incluirAjustes+'&fechaDesde='+desde+'&fechaHasta='+hasta, null)
  }

  getMovimientosBalance7(listaIdsCierreTurno: string[], codigoAeroplanta: string, incluirAjustes: boolean): Observable<ReporteMovimientoBalance7[]> {
    return this.http.put<ReporteMovimientoBalance7[]>(this.reportesUrl + 'CierreTurno/ReporteMovimientos?codigoAeroplanta='+codigoAeroplanta+'&incluirAjustes=' + incluirAjustes, listaIdsCierreTurno);
  }

  getDatosGeneralesActaBalance7(codigoAeroplanta: string, desde: string, hasta: string): Observable<Balance7ActaDTO[]> {
    return this.http.get<Balance7ActaDTO[]>(this.balance7URL + 'acta?codigoAeroplanta=' + codigoAeroplanta + '&fechaDesde=' + desde + '&fechaHasta=' + hasta)
  }

  getDatosFechaActaBalance7(codigoAeroplanta: string, desde: string, hasta: string): Observable<FechaDTO> {
    return this.http.get<FechaDTO>(this.cierreURL + 'CierresFechas?codigoAeroplanta=' + codigoAeroplanta + '&fechaDesde=' + desde + '&fechaHasta=' + hasta)
  }

  getDatosFacturacionActaBalance7(codigoAeroplanta: string, hasta: string, desde:string): Observable<Balance7ActaDocumentosDTO[]> {
    return this.http.get<Balance7ActaDocumentosDTO[]>(this.facturacionUrl + 'acta?codigoAeroplanta=' + codigoAeroplanta + '&fechaHasta=' + hasta + '&fechaDesde=' + desde)
  }

  obtenerReporteMovimientoDetalleFecha(codigoProducto:string, codigoAeroplanta: string, natural: boolean, tipoTransaccion:number, esAnulacion:boolean, desde: string, hasta: string): Observable<ReporteMovimientoDetalleBalance7[]> {
    return this.http.put<ReporteMovimientoDetalleBalance7[]>(this.reportesUrl + 'Fechas/ReporteMovimientos/Detalle?codigoProducto=' + codigoProducto + '&codigoAeroplanta=' + codigoAeroplanta + '&tipoReporte=' + natural + '&tipoTransaccion=' + tipoTransaccion + '&esAnulacion=' + esAnulacion + '&fechaDesde=' + desde + '&fechaHasta=' + hasta, null)
  }
    
  obtenerReporteMovimientoDetalleCierreTurno(listaIdsCierreTurno: string[], codigoProducto:string, codigoAeroplanta: string, natural: boolean, tipoTransaccion:number, esAnulacion:boolean): Observable<ReporteMovimientoDetalleBalance7[]> {
    return this.http.put<ReporteMovimientoDetalleBalance7[]>(this.reportesUrl + 'CierreTurno/ReporteMovimientos/Detalle?codigoProducto=' + codigoProducto + '&codigoAeroplanta=' + codigoAeroplanta + '&tipoReporte=' + natural + '&tipoTransaccion=' + tipoTransaccion + '&esAnulacion=' + esAnulacion, listaIdsCierreTurno)
  }

  obtenerParteDiario(cierreTurnoId:string) : Promise<ParteDiario>{
    return this.http.get<ParteDiario>(`${this.cierreURL}ParteDiario?cierreTurnoId=${cierreTurnoId}`).toPromise();    
  }

  modificarParteDiario(parte: ParteDiario, finaliza:boolean) : Promise<ParteDiario>{
    return this.http.put<ParteDiario>(`${this.cierreURL}ParteDiario?finalizaTurno=${finaliza}`, parte).toPromise();
  }

  //https://cierreturno-dev.aeroplantas.com.ar/api/CierresTurnosV1/AsignarFechaReporte?cierreId=b7adffa4-0b7f-4fa3-a207-addb00b87318&fechadeReporte=2023-01-18T00%3A00%3A00
  putAsignarFechaReporte(cierreId:string, fechadeReporte:string):Observable<any> {
    return this.http.put<any>(this.cierreURL + `AsignarFechaReporte?cierreId=${cierreId}&fechadeReporte=${fechadeReporte}`, null)
  }

  finalizarTurno(cierreId: string): Promise<string> {
    return this.http.put<string>(this.cierreTurnoBase + '/CierresTurnosV1/' + cierreId + '/Finalizar', '').toPromise();
  }

  obtenerResolucionConfiguracion(id:string): Observable<ResolucionConfiguracion> {
    return this.http.get<ResolucionConfiguracion>(this.configuracionesURL + `${id}/ObtenerResolucionConfiguracion`);
  }

  // UTILIDADES

  obtenerDescripcionTipoTransaccion(tipoTransaccion : Number, esAnulacion : boolean){
     // Despacho = 0, DespachoInterno = 1, AjusteManual = 2, Transferencia = 3, Rebombeo = 4, Alije = 5, Recepcion = 6, RecepcionDucto = 7, Salida = 8

    if(tipoTransaccion == 0){
      return (esAnulacion ? "Anulación " : "") + "Despacho";
    }else if(tipoTransaccion == 1){
      return (esAnulacion ? "Anulación " : "") + "Despacho Interno";
    }else if(tipoTransaccion == 2){
      return (esAnulacion ? "Anulación " : "") + "Ajuste";
    }else if(tipoTransaccion == 3){
      return (esAnulacion ? "Anulación " : "") + "Transferencia";
    }else if(tipoTransaccion == 4){
      return (esAnulacion ? "Anulación " : "") + "Rebombeo";
    }else if(tipoTransaccion == 5){
      return (esAnulacion ? "Anulación " : "") + "Alije";
    }else if(tipoTransaccion == 6){
      return (esAnulacion ? "Anulación " : "") + "Recepcion";
    }else  if(tipoTransaccion == 7){
      return (esAnulacion ? "Anulación " : "") + "Recepción por Ducto";
    }else  if(tipoTransaccion == 8){
      return (esAnulacion ? "Anulación " : "") + "Remito Salida";
    }else{
      return "";
    }
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {   
    const worksheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'Movimientos': worksheet }, SheetNames: ['Movimientos'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    var EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    var EXCEL_EXTENSION = '.xlsx';

    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });

    FileSaver.saveAs(data, fileName + '_' + new Date().getTime() + EXCEL_EXTENSION);
  }
      
  /** Aeroplanta -> msCierreTurno */

  getAeroplantas(codigoAeroplanta:string):Promise<Aeroplanta>{
    return this.http.get<Aeroplanta>(`${this.aeroplantasUrl}/${codigoAeroplanta}/ObtenerAeroplanta`).toPromise();
  }

  //api/AeroplantasV1/ActualizarAeroplanta
  putAeroplantas(body:Aeroplanta):Promise<Aeroplanta>{
    return this.http.put<Aeroplanta>(`${this.aeroplantasUrl}/ActualizarAeroplanta`, body).toPromise();
  }

  /** Contactos */

  getContactos():Promise<Contacto[]>{
    return this.http.get<Contacto[]>(`${this.aeroplantasUrl}/ObtenerContactos`).toPromise();
  }
  
  postContactos(body:Contacto):Promise<Contacto>{
    return this.http.post<Contacto>(`${this.aeroplantasUrl}/CrearContacto`, body).toPromise();
  }

  putContactos(body:Contacto):Promise<Contacto>{
    return this.http.put<Contacto>(`${this.aeroplantasUrl}/EditarContacto`, body).toPromise();
  }

  get stepEtapasCierreTurno() {
    return 
  }
}
