import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { GrupoDeTanques } from 'src/app/shared/models/stock/GrupoDeTanques';
import { StockService } from '../../../stock.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalOkComponent } from 'src/app/shared/generic-modals/modal-ok.component';
import { Producto } from 'src/app/shared/models/abm/Producto';
import { GrupoDeTanquesService } from '../../../grupos.service';
import { UsuarioService } from 'src/app/account/usuario.service';


@Component({
  selector: 'app-nuevo-grupo',
  templateUrl: './nuevo-grupo.component.html',
  styleUrls: ['./nuevo-grupo.component.css']
})
export class NuevoGrupoComponent implements OnInit {

  @Input() grupo : GrupoDeTanques;
  @Input() productos : Producto[];
  listaDeGrupos: GrupoDeTanques[];

  cargandoProductos: boolean;
  errorProductos: boolean;

  guardando: boolean;
  editando: boolean;
  codigoProductoAlIngreso: string;

  form: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private usuarioService: UsuarioService,
    private stockService: StockService,
    private grupoService: GrupoDeTanquesService) {

  }

  ngOnInit() {
    this.getListaDeGrupos();
    this.crearFormulario();
    this.inicializarComponente();
  }

  crearFormulario(){
    this.form = this.formBuilder.group({
      codigoFormControl: ['', Validators.required],
      nombreFormControl: ['', Validators.required],
      productoFormControl: ['', Validators.required],
    });
  }

  inicializarComponente(){
    if(this.grupo == null){
      this.editando = false;
      this.productoFormControl.setValue(this.productos[0]);

    }else{
      this.editando = true;
      this.codigoProductoAlIngreso = this.grupo.productoActual.codigoProducto;
      this.productoFormControl.disable();
      this.cargarDatosGrupo();
    }

    this.guardando = false;
    this.cargandoProductos = false;
    this.errorProductos = false;
  }

  cargarDatosGrupo(){
    this.codigoFormControl.setValue(this.grupo.codigo);
    this.nombreFormControl.setValue(this.grupo.nombre);
    this.productoFormControl.setValue(this.grupo.productoActual);
  }

  compareProductos(p1: any, p2: any): boolean {
    return p1 && p2 ? p1.codigoProducto === p2.codigoProducto : p1 === p2;
 }

 chequearCodigo(){
   if (this.listaDeGrupos != null)
   {
    let existepatente = false;
    if (!this.editando) {
      for (let i = 0; this.listaDeGrupos.length > i; i++) {
        if (this.listaDeGrupos[i].codigo === this.codigoFormControl.value) {
          existepatente = true;
          break;
        }
      }
    }
    existepatente ? this.productoFormControl.setErrors({ 'incorrect': true }) : this.productoFormControl.setErrors(null);
    return existepatente;
   }

  }

  getListaDeGrupos()
  {
    this.grupoService.getGrupoDeTanquesPorAeroplanta(this.usuarioService.getAeroplantaActual().codigoAeroplanta)
    .subscribe(result => {
      this.listaDeGrupos = result;
    },
      () => {
      })
  }

  onSubmit() {

    if(this.editando && this.codigoProductoAlIngreso != this.productoFormControl.value.codigoProducto && this.grupo.tanques.length > 0){
        let modalRefConfirm = this.modalService.open(ModalOkComponent, { centered: true });
        modalRefConfirm.componentInstance.mensaje = "Para modificar el producto el grupo no debe tener tanques asociados.";
        modalRefConfirm.result.then(() => { });
        return;
    }

    this.guardando = true;

    if(!this.editando){
      this.grupo = new GrupoDeTanques();
      this.grupo.capacidad = 0;
      this.grupo.activo = true;
    }

    this.grupo.codigo = this.codigoFormControl.value;
    this.grupo.nombre = this.nombreFormControl.value;
    this.grupo.productoActual = this.stockService.convertirProducto(this.productoFormControl.value);

    if(!this.editando){
      this.grupoService.crearGrupoDeTanques(this.usuarioService.getAeroplantaActual().codigoAeroplanta, this.grupo)
      .subscribe(grupo => {
        this.activeModal.close(grupo);
      });
    }else{
      this.grupoService.editarGrupoDeTanques(this.usuarioService.getAeroplantaActual().codigoAeroplanta, this.grupo)
      .subscribe(grupo => {
        this.activeModal.close(grupo);
      });
    }
  }

  // getters

  get codigoFormControl(){
    return this.form.controls.codigoFormControl;
  }
  get nombreFormControl(){
    return this.form.controls.nombreFormControl;
  }

  get productoFormControl(){
    return this.form.controls.productoFormControl;
  }
}
