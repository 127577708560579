<div class="modal-header">
    <h4>Informe de resolución mensual</h4>
</div>
<div class="modal-body" id="informeResolucion">
    <div class="container">
        <br>
        <div class="modal-header text-secondary"
            style="padding: 1rem 1rem; border-bottom: 2px solid #dee2e6; border-top: 2px solid #dee2e6;">
            <h3 style="display: inline!important" class="col">
                <img src="assets/images/LogoResolucion.png" width="120" height="70">
            </h3>
            <div style="display: inline!important" class="text-center col">
                <p class="modal-title">Registros del sistema</p>
                <p>G.E. Logística</p>
            </div>
            <div style="display: inline!important" class="col text-sm-end">
                <p>Revision: 0 Documento YPF privado.</p>
                <p>Fecha: {{this.mesYAno}}</p>
            </div>
        </div>
        <div class="modal-title text-center text-secondary" style="border-bottom: 4px solid #dee2e6;">
            <h2><i>Resolución {{this.mesYAno}}</i></h2>
        </div>
        <div class="modal-body">
            <br>
            <h4>I. ASUNTO: </h4>
            <p>Aprobar los cargos y descargos de productos a granel inherentes a los ajustes de existencias
                correspondientes al período {{this.mesYAno}} en las Aeroplantas con
                operación tercerizada de la Red.</p>
            <h4>II. FUNDAMENTO: </h4>
            <p>En los folios anexos se agregan los antecedentes que cumplimentan el período mencionado, arrojando los
                resultados que se detallan en el formulario “Ajustes de existencias de productos a granel”.</p>
            <ul>
                <li><b><u>Detalle de diferencias operativas (superávit/déficit) por dependencia:</u></b></li>
            </ul>

            <div>

                <table class="table table-bordered">
                    <thead class="text-center">
                        <th>Dependencia Origen</th>
                        <th>Producto</th>
                        <th>Removido [lt]</th>
                        <th>Sup / Déf [lt]</th>
                        <th>Porcentual [%]</th>
                        <th>Tolerancia M.O. [%]</th>
                        <th>Resultado</th>
                        <th>% FT</th>
                    </thead>
    
                    <tbody>
                        <tr *ngFor="let renglon of this.renglones">
                            <td class="primerNivel text-center">{{renglon.nombreAeroplanta}}</td>
                            <td class="primerNivel text-center">{{renglon.nombreProducto}}</td>
                            <td class="tercerNivel text-center">{{renglon.removido}}</td>
                            <td class="tercerNivel text-center">{{renglon.diferencia}}</td>
                            <td class="tercerNivel text-center">{{renglon.porcentual | number : '1.2-2'}} % </td>
    
                            <td *ngIf="renglon.toleranciaMensualOperativa" class="tercerNivel text-center">
                                {{renglon.toleranciaMensualOperativa | number : '1.2-2'}} %</td>
                            <td *ngIf="renglon.toleranciaMensualOperativa" class="text-center" [ngClass]="[renglon.resultado=='FT' ? 'colorRojo' : 'colorVerde']"> {{renglon.resultado}}</td>
                            <td *ngIf="renglon.toleranciaMensualOperativa" class="tercerNivel text-center">
                                {{renglon.resultadoTolerancia| number : '1.2-2' }} %</td>
    
    
                            <td *ngIf="!renglon.toleranciaMensualOperativa" class="tercerNivel text-center border"
                                style="background-color: #DC3545;">Error</td>
                            <td *ngIf="!renglon.toleranciaMensualOperativa" class="text-center border"
                                style="background-color: #DC3545;">Error</td>
                            <td *ngIf="!renglon.toleranciaMensualOperativa" class="tercerNivel text-center border"
                                style="background-color: #DC3545;">Error</td>
    
                        </tr>
                    </tbody>
                </table>

            </div>

            <h4>III. JUSTIFICACIONES: </h4>

            <div *ngFor="let renglon of this.renglones">
                
                <ul *ngIf="renglon.justificacion">
                    <li><b>{{renglon.codigoAeroplanta}}-<u>{{renglon.nombreResponsable}}</u>-{{renglon.nombreProducto}}: </b> <p style="word-break: break-all; display: inline;">{{renglon.justificacion}}</p></li>
                </ul>
            </div>

            
            <h4>IV. SOLUCIÓN PROPUESTA</h4>
            <p>Aprobar los cargos y descargos de productos a granel elevados en los ajustes de existencias por
                AEROPLANTAS correspondiente a {{this.mesYAno}} según lo expuesto en el
                PE-63.</p>
            <h4>V. ANEXOS: </h4>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;<b>Anexo 1 - Acta 108 Diferencias de Stock PAD Vs Cenit/SCP</b></p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;<b>Anexo 2 - Certificación de Stock</b></p>
        </div>
        <div class="modal-footer">
            <p class="text-end text-secondary">Documento YPF privado.</p>
        </div>
    </div>
</div>
<div class="modal-footer">
    <br>
    <button (click)="descargarPdf()" class="btn btn-primary">Descargar PDF</button>
    <button (click)="this.activeModal.close(false)" class="btn btn-secondary">Cerrar</button>
    <br>
</div>