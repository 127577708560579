import { Routes, Route } from '@angular/router';
import { ShellComponent } from './shell.component';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

/**
 * Provides helper methods to create routes.
 */
export class Shell {

  /**
   * Creates routes using the shell component and authentication.
   * @param routes The routes to add.
   * @return {Route} The new route using shell as the base.
   */
  static reuseRoute;

  static childRoutes(routes: Routes): Route {
    this.reuseRoute = true;

    if(routes.find(r => r.path == "")){
      this.reuseRoute = false;
    }

    return {
      path: '', component: ShellComponent, children: routes,
      // =canActivate: [AuthenticationGuard],
      // Reuse ShellComponent instance when navigating between child views
      data: { reuse: this.reuseRoute }
    };
  }
}
