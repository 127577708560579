import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';

@Component({
  selector: 'app-refacturacion-index',
  templateUrl: './refacturacion-index.component.html',
  styleUrls: ['./refacturacion-index.component.css']
})
export class RefacturacionIndexComponent implements OnInit {

  TipoUsuario = TipoUsuario;

  constructor(private router: Router, private authService: AuthService) {
  }

  ngOnInit() {
    //this.router.navigate(['/facturacion/refacturacion']);
  }

  hasRole(...roles: TipoUsuario[]) {
    return this.authService.hasRole(...roles);
  }

}
