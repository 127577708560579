import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AerovaleDetalleComponent } from 'src/app/despacho/aerovale-detalle/aerovale-detalle.component';
import { AerovalesService } from 'src/app/despacho/aerovales.service';
import { ExportExcelServiceService } from 'src/app/shared/exportar-excel/export-excel-service.service';
import { CierreTurno } from 'src/app/shared/models/cierreTurno/CierreTurno';
import { ClienteCierre } from 'src/app/shared/models/cierreTurno/ClienteCierre';
import { ReporteMovimiento } from 'src/app/shared/models/cierreTurno/ReporteMovimiento';
import { AerovaleDetalle } from 'src/app/shared/models/despacho/AerovaleDetalle';
import { Producto } from 'src/app/shared/models/despacho/Producto';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { MessageService } from 'src/app/shell/message.service';

@Component({
  selector: 'app-reporte-rancho-cierre',
  templateUrl: './reporte-rancho-cierre.component.html',
  styleUrls: ['./reporte-rancho-cierre.component.css']
})
export class ReporteRanchoCierreComponent implements OnInit {
  @Input() cierreSeleccionado: CierreTurno;
  @Input() reporteSeleccionado: number;
  @Input() movimientosCierre: ReporteMovimiento[];
  @Output() termino: EventEmitter<boolean> = new EventEmitter();

  @Input()
  set ejecutarRanchos(b: boolean) {
    if (b) {
      this.exportar();
    }
    this.termino.emit(true);
  }

  busquedaForm = new UntypedFormGroup({
    clienteFormControl: new UntypedFormControl(),
    productoFormControl: new UntypedFormControl()
  })

  cargando: boolean;
  registrosAProcesar: number=0;
  registrosProcesados: number=0;
  reporteMovimientosAnulados: ReporteMovimiento[] = [];
  reporteFiltrado: ReporteMovimiento[] = [];
  distintosRanchos = [];
  distintosRanchosFiltrado = [];
  obteniendoAerovale: boolean = false;
  aerovaleSeleccionado: number = null;
  clientes = [];
  productos = [];
  clientesFiltrados: ClienteCierre[] = [];
  productosFiltrados: string[] = [];
  mostrarAnulados: boolean = true;

  constructor(
    private aerovaleService: AerovalesService, 
    private activeModal: NgbModal, 
    private messageService: MessageService,
    private excelService: ExportExcelServiceService) { }

  ngOnInit() {
    this.cargando = true;
    this.clientes = [];
    this.productos = [];
    this.distintosRanchos = [];
    this.reporteFiltrado = this.movimientosCierre.filter(a => a.comprobante != null && a.comprobante.remito != null && a.comprobante.remito.esAnulacion == false);
    this.reporteMovimientosAnulados = this.movimientosCierre.filter(a => a.comprobante != null && a.comprobante.remito != null && a.comprobante.remito.esAnulacion == true);
    for (let movimiento of this.reporteFiltrado) {
      if (movimiento.comprobante.remito.aerovaleDTO != null) {
        movimiento.comprobante.remito.clienteCierre = JSON.parse(movimiento.comprobante.remito.aerovaleDTO);
      } else {
        movimiento.comprobante.remito.clienteCierre = new ClienteCierre();
        movimiento.comprobante.remito.clienteCierre.ClienteNombre = '-----';
        movimiento.comprobante.remito.clienteCierre.ClienteCUIT = '-----';
        movimiento.comprobante.remito.clienteCierre.Cuenta_SGC = '-----';
        movimiento.comprobante.remito.clienteCierre.Rancho = null;
      }
    }
    for (let com of this.reporteFiltrado) {
      if (com.comprobante.remito.clienteCierre != null) {
        if (com.comprobante.remito.clienteCierre.Rancho != null) {
          if (!this.distintosRanchos.find(a => a.rancho.comprobante.remito.clienteCierre.Rancho == com.comprobante.remito.clienteCierre.Rancho)) {
            this.distintosRanchos.push({
              rancho: com,
              seleccionado: true,
            });
          }

          if (!this.clientes.find(a => a.cliente == com.comprobante.remito.clienteCierre.ClienteNombre)) {
            this.clientes.push({
              cliente: com.comprobante.remito.clienteCierre.ClienteNombre,
              seleccionado: true
            });
          }

          if (com.nombreProducto != null) {
            if (!this.productos.find(a => a.producto == com.nombreProducto)) {
              this.productos.push({
                producto: com.nombreProducto,
                seleccionado: true
              });
            }
          }
        }
      }
    }
    this.distintosRanchosFiltrado = this.distintosRanchos;
    this.cargando = false;    
  }

  revisarAnulados(mov: ReporteMovimiento): boolean {
    let p: boolean = false;
    for (let anulado of this.reporteMovimientosAnulados) {
      if (anulado.comprobante.remito.aerovaleId == mov.comprobante.remito.aerovaleId) {
        p = true;
      }
    }
    return p;
  }

  seleccionarTodosRanchos(e){
    if(e.target.checked){
      for(let r of this.distintosRanchosFiltrado){
        r.seleccionado = true;
      }
    } else {
      for(let r of this.distintosRanchosFiltrado){
        r.seleccionado = false;
      }
    }
  }

  seleccionarTodosClientes(e){
    if(e.target.checked){
      for(let c of this.clientes){
        c.seleccionado = true;
      }
    } else {
      for(let c of this.clientes){
        c.seleccionado = false;
      }
    }
  }

  seleccionarTodosProductos(e){
    if(e.target.checked){
      for(let p of this.productos){
        p.seleccionado = true;
      }
    } else {
      for(let p of this.productos){
        p.seleccionado = false;
      }
    }
  }

  mostrarAerovale(i: number) {
    this.obteniendoAerovale = true;
    this.reporteFiltrado[i].booleanAux = true;
    this.aerovaleService.getAerovalePorIdyTipo(this.reporteFiltrado[i].comprobante.remito.aerovaleId)
      .subscribe(result => {
        let aerovaleDetalle: AerovaleDetalle = result;
        this.reporteFiltrado[i].booleanAux = false;
        this.obteniendoAerovale = false;
        let modalRef = this.activeModal.open(AerovaleDetalleComponent, { size: "lg", centered: true })
        modalRef.componentInstance.aerovaleDetalle = aerovaleDetalle;
      });
  }

  obtenerAerovale(id: string, i:number) {
    this.obteniendoAerovale = true;    
    this.aerovaleSeleccionado=i;
    this.aerovaleService.getAerovalePorIdyTipo(id)
      .subscribe(result => {
        let aerovaleDetalle: AerovaleDetalle = result;           
        this.aerovaleSeleccionado= null;  
        this.obteniendoAerovale = false;
        if(aerovaleDetalle != null) {
          let modalRef = this.activeModal.open(AerovaleDetalleComponent, { size: "lg", centered: true })
          modalRef.componentInstance.aerovaleDetalle = aerovaleDetalle; 
        }}, (err: ErrorModel) => {
          this.messageService.showErrorMessage('Hubo un error al obtener el Aerovale' + err.description);   
          this.aerovaleSeleccionado=null;    
          this.obteniendoAerovale = false;
      });
  }

  arregloDistintoProd(dist: string): ReporteMovimiento[] {
    let comp: ReporteMovimiento[] = [];
    for (let r of this.reporteFiltrado) {
      if (r.comprobante.remito.clienteCierre.Rancho == dist
        && this.clientes.find(c => c.cliente == r.comprobante.remito.clienteCierre.ClienteNombre && c.seleccionado)
        && this.productos.find(p => p.producto == r.nombreProducto && p.seleccionado)) {
        comp.push(r)
      }
    }
    
    if(!this.mostrarAnulados){
      comp = comp.filter(m => !this.revisarAnulados(m))
    }
    
    return comp    
  }

  armarArrayExcel() {
    var listaReporte: ReporteRanchoCierre[] = [];

    for (let r of this.movimientosCierre) {
      if (r.comprobante != null && r.comprobante.remito != null && r.comprobante.remito.clienteCierre && r.comprobante.remito.clienteCierre.Rancho != null) {
        var item = new ReporteRanchoCierre();
        item.aerovale = r.comprobante.remito.aerovalePrefijo + "-" + r.comprobante.remito.aerovaleNumero;
        item.cliente = r.comprobante.remito.clienteCierre.ClienteNombre + "-" + r.comprobante.remito.clienteCierre.ClienteCUIT;
        item.cuenta = r.comprobante.remito.clienteCierre.Cuenta_SGC;
        item.producto = r.codigoProducto + "-" + r.nombreProducto;
        item.cantidad = r.salidaNatural ? r.salidaNatural : 0;
        item.cantidad15 = r.salida15 ? r.salida15 : 0;
        item.rancho = r.comprobante.remito.clienteCierre.Rancho;
        item.anulado = this.revisarAnulados(r)? "Si":"No";
        
        listaReporte.push(item);
      }
    }

    return listaReporte;
  }

  exportar() {
    this.excelService.exportAsExcelFile(this.armarArrayExcel(), 'Reporte ranchos cierre');
  }

  get clienteFormControl() {
    return this.busquedaForm.get('clienteFormControl');
  }
  get productoFormControl() {
    return this.busquedaForm.get('productoFormControl');
  }
}
export class ReporteRanchoCierre {
  aerovale: string;
  cliente: string;
  cuenta: string;
  producto: string;
  cantidad: number;
  cantidad15: number;
  rancho: string;
  anulado: string;
}
