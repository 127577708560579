import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ClienteService } from 'src/app/cliente/cliente.service';
import { AbmMatriculasService } from 'src/app/management/abm-matriculas.service';
import { isNullOrUndefined } from 'src/app/shared/compat-tools';
import { ClienteConCuentasEncabezadoDTO } from 'src/app/shared/models/cliente/ClienteConCuentaEncabezado';
import { CuentaCliente } from 'src/app/shared/models/despacho/CuentaCliente';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { MessageService } from 'src/app/shell/message.service';

@Component({
  selector: 'app-cliente-lista',
  templateUrl: './cliente-lista.component.html',
  styleUrls: ['./cliente-lista.component.scss']
})
export class ClienteListaComponent implements OnInit {

  /** locales */
  cargandoLista: boolean;
  buscandoLista: boolean;
  seleccionado: boolean = false;
  clientes: ClienteConCuentasEncabezadoDTO[] = [];
  clientesFiltrados: ClienteConCuentasEncabezadoDTO[];
  clienteSeleccionado: ClienteConCuentasEncabezadoDTO;

  /** salidas */
  @Output() eventClienteSeleccionado: EventEmitter<ClienteConCuentasEncabezadoDTO> = new EventEmitter;
  @Output() eventErrorBusqueda: EventEmitter<Boolean> = new EventEmitter;
  @Output() eventCargandoLista: EventEmitter<Boolean> = new EventEmitter;

  /** entradas */
  @Input() set clienteBuscado(texto: string) { this.filtrarClientes(texto) };
  @Input() set matriculaBuscada(texto: string) { this.filtrarMatricula(texto) };

  constructor(    
    private clienteService: ClienteService,
    private messageService: MessageService,
    private abmMatriculasService: AbmMatriculasService,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {    
    this.getClientes();
  }

  ngOnChanges() {
    
  }
  
  mostrarSìnner(s:boolean) {
    this.spinner.show('spinnerGrafico')
    s ? this.cargandoLista = true : this.buscandoLista = true
  }

  ocultarSpinner(s:boolean) {
    this.spinner.hide('spinnerGrafico')
    s ? this.cargandoLista = false : this.buscandoLista = false
  }

  async getClientes() {
    this.mostrarSìnner(true);
    this.eventCargandoLista.emit(true);
    try {
      let c = await this.clienteService.getClienteConCuentas();
      if (c) {
        this.clientes = c.filter(c=>c.activo==true).sort((a, b) => { if (a.razonSocial > b.razonSocial) { return 1 } else { return -1 } });
        this.clientesFiltrados = this.clientes;
        this.clienteSeleccionado = this.clientes[0];
        this.ocultarSpinner(true);
        this.eventCargandoLista.emit(false);
      }
    } catch (error) {
      this.ocultarSpinner(true);
    }    
  }

  mostrarDetalle(i: number) {
    this.clienteSeleccionado = this.clientesFiltrados[i];
    this.eventClienteSeleccionado.emit(this.clienteSeleccionado); 
  }

  filtrarClientes(texto: string) {
    this.clientesFiltrados = this.clientes.filter(a => {
      let agregar = true;
      if (texto && texto.length>2) {
        agregar = a.razonSocial.toLowerCase().indexOf(texto.toLowerCase()) > -1;
      }
      return agregar;
    });
  }

  filtrarMatricula(texto:string) {
    if(texto!='') {
      this.mostrarSìnner(false);
      this.abmMatriculasService.getVehiculoPorMatricula(texto)
      .subscribe( v=> {
        const y = v.ctas_SGC.map(c => c);
        this.clientesFiltrados=this.clientes.filter ( c => c.cuentas.some( x => y.includes( x.ctA_SGC )) );
        this.ocultarSpinner(false);
      }, (err:ErrorModel)=> {
        this.messageService.showErrorMessage("La matricula " + texto + " no existe");
        this.eventErrorBusqueda.emit(true);
        this.ocultarSpinner(false)
      });
    }else{
      this.clientesFiltrados=this.clientes;
    }    
  }

}
