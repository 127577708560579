import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AnonymousCredential } from '@azure/storage-blob';
import { NgbActiveModal, NgbDateStruct, NgbModal, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { UsuarioService } from 'src/app/account/usuario.service';
import { VehiculoService } from 'src/app/cliente/vehiculo.service';
import { isNullOrUndefined } from 'src/app/shared/compat-tools';
import { ModalConfirmComponent } from 'src/app/shared/generic-modals/modal-confirm.component';
import { PreautorizacionDTO } from 'src/app/shared/models/cliente/PreautorizacionDTO';
import { VehiculoDTO } from 'src/app/shared/models/cliente/VehiculoDTO';
import { CuentaCliente } from 'src/app/shared/models/despacho/CuentaCliente';
import { Responsable } from 'src/app/shared/models/stock/Responsable';
import { ClienteService } from 'src/app/cliente/cliente.service';
import { MessageService } from 'src/app/shell/message.service';
import { Aeroplanta } from 'src/app/shared/models/despacho/Aeroplanta';
import { PreciosService } from 'src/app/precios/precios.service';

@Component({
  selector: 'app-modal-preautorizacion',
  templateUrl: './modal-preautorizacion.component.html',
  styleUrls: ['./modal-preautorizacion.component.scss']
})
export class ModalPreautorizacionComponent implements OnInit {

  modelFechaDesde: NgbDateStruct;
  modelFechaHasta: NgbDateStruct;
  modelHoraDesde: any;
  modelHoraHasta: any;
  guardando:boolean=false;

  /** clientes */
  clientes: CuentaCliente[];
  modelCliente:any;
  cargandoClientes:boolean=false;
  errorClientes:boolean=false;

  /** vehiculos */
  cargandoVehiculos:boolean=false;
  cargandoVehiculosDeCliente:boolean=false;
  todosLosVehiculos: VehiculoDTO[]=[];
  vehiculosDeCliente: VehiculoDTO[]=[];
  errorVehiculos:boolean=false;
  errorVehiculosDeCliente:boolean=false;
  vehiculoNoEncontrado:boolean=false;
  clienteNoEncontrado:boolean=false;

  /** aeroplantas */
  @Input() aeroplantas:Aeroplanta[];

  preautorizacion:PreautorizacionDTO;

  /** formulario */
  preautorizacionForm = this.formBuilder.group({
    clienteFormControl: ['', [Validators.required] ],
    vehiculoFormControl: ['', [Validators.required] ],   
    aeroplantaFormControl: [null, [Validators.required] ],
    fechaDesdeFormControl: [''],
    horaDesdeFormControl: [''],
    fechaHastaFormControl: [''], 
    horaHastaFormControl: ['']    
  });

  constructor(
    public activeModal: NgbActiveModal,
    public modal: NgbModal,
    private usuarioService: UsuarioService,
    private clienteService:ClienteService,
    private messageService:MessageService,
    private vehiculosService:VehiculoService,
    private formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.setearFechas();
    this.obtenerClientes();
    this.obtenerVehiculos();
    this.preautorizacionForm.setValidators(this.preautorizacionValidator.bind(this)); 
  }

  setearFechas() {
    let fecha: Date;
  
    fecha = new Date();
    this.modelFechaDesde = { day: fecha.getDate(), month: fecha.getMonth() + 1, year: fecha.getFullYear() };
    this.modelHoraDesde = { hour: fecha.getHours(), minute: fecha.getMinutes() + 1, second: fecha.getSeconds() };
    this.modelFechaHasta = { day: fecha.getDate(), month: fecha.getMonth() + 1, year: fecha.getFullYear() };
    this.modelHoraHasta = { hour: fecha.getHours(), minute: fecha.getMinutes() + 2, second: fecha.getSeconds() };
  }

  preautorizacionValidator(group: UntypedFormGroup): any{
    if (group) {
 
      let ahora = new Date();     
 
      let fechaDesde = group.get("fechaDesdeFormControl").value;
      let horaDesde = group.get("horaDesdeFormControl").value;
  
      if(fechaDesde && horaDesde){
        var fecha = new Date(fechaDesde.year, fechaDesde.month-1,  fechaDesde.day, horaDesde.hour, horaDesde.minute);
        if(fecha < ahora){
          return { fechaDesdeMenorAhora: true };
        }
      }  
  
      let fechaHasta = group.get("fechaHastaFormControl").value;
      let horaHasta = group.get("horaHastaFormControl").value;
  
      if(fechaDesde && horaDesde && fechaHasta && horaHasta){
        let desde = new Date(fechaDesde.year, fechaDesde.month-1,  fechaDesde.day, horaDesde.hour, horaDesde.minute);
        let hasta = new Date(fechaHasta.year, fechaHasta.month-1,  fechaHasta.day, horaHasta.hour, horaHasta.minute);
        if(desde >= hasta){
          return { fechaDesdeMayorHasta: true };
        }
      }  
  
      return null;
    }
  }

  /**
   * Agrega un error de validación en el AbstractControl y lo marca como Dirty
   */
   addValidationError(control: AbstractControl, validationName: string) {
    let errors = control.errors;
    if (errors == null)
      errors = { [validationName]: true };
    else
      errors[validationName] = true;
    control.setErrors(errors);
    control.markAsDirty();
  }
  /**
   * Convierte una NgbDateStruct y una NgbTimeStruct en un Date
   */
  dateFromDateTimeStruct(date: NgbDateStruct, hour: NgbTimeStruct): Date {
    if (date == null || hour == null)
      return null;
    let dateReturn = new Date();
    dateReturn.setDate(date.day);
    dateReturn.setMonth(date.month - 1);
    dateReturn.setFullYear(date.year);
    dateReturn.setHours(hour.hour);
    dateReturn.setMinutes(hour.minute);
    return dateReturn;
  }

  validarFecha(timeStruct: NgbTimeStruct) {
    if (!this.fechaDesdeFormControl.dirty && !this.horaDesdeFormControl.dirty)
      return;
    let fDesde = this.dateFromDateTimeStruct(this.modelFechaDesde, this.modelHoraDesde);

    /** Esto está porque el timepicker si le ponés dos veces mal la hora (por ej. 57), se queda mal en el input */
    if (timeStruct) {
      let horaStruct = timeStruct.hour;
      let minutesStruct = timeStruct.minute;
      horaStruct = horaStruct == 0 ? 12 : (horaStruct > 12 ? horaStruct - 12 : horaStruct);        
    }

    if (fDesde == null || fDesde.toString() == 'Invalid Date') {
      this.addValidationError(this.fechaDesdeFormControl, 'fechaValida');
    }
    else {
      this.fechaDesdeFormControl.updateValueAndValidity();
      if (fDesde <= new Date())
        this.addValidationError(this.fechaDesdeFormControl, 'fechaFutura')
      else {
        this.fechaDesdeFormControl.updateValueAndValidity();
      }
    }
  }

  /** services */
  
  obtenerClientes() {
    this.cargandoClientes = true;
    this.clienteService.getClientesActivos()
      .subscribe(clientes => {
        clientes = clientes.map(a => Object.assign(new CuentaCliente(), a));
        this.clientes = clientes;
        this.cargandoClientes = false;        
      }, () => this.errorClientes = true);
  }

  obtenerVehiculos() {
    this.todosLosVehiculos = []; 
    this.cargandoVehiculos = true; 
    let carga:boolean[]=[false,false];

    this.vehiculosService.getAeronaves()
      .subscribe( ae => {
        this.todosLosVehiculos = this.todosLosVehiculos.concat(ae);
        carga[0]=true;
      }, ()=> {        
        this.errorVehiculos = true;
      });

    this.vehiculosService.getAutomotores()
      .subscribe( au => {
        this.todosLosVehiculos = this.todosLosVehiculos.concat(au);
        carga[1]=true;
      }, ()=> {
        this.errorVehiculos = true;
      });
    
    (carga[0]==true && carga[1]==true) ? this.cargandoVehiculos = false : null;
  }

  obtenerVehiculosCliente(c: CuentaCliente): void {
    this.cargandoVehiculosDeCliente = true;  
    this.vehiculosService.getCuentasVehiculos(c.tipoDocumento, c.numeroDocumento)
      .subscribe( resp => {
        this.vehiculosDeCliente = resp.filter( v => v.ctA_SGC == c.ctA_SGC)[0].vehiculos;
                
        if (typeof (this.vehiculoFormControl.value) != 'string')
          this.verificarVehiculo();
        this.cargandoVehiculosDeCliente = false;
      }, () => {
        this.errorVehiculosDeCliente = true;
        this.cargandoVehiculosDeCliente = false;
      });
  }

  async crearPreautorizacion() {
    
    this.validarFecha(this.modelHoraDesde);

    if(this.preautorizacionForm.valid && this.aeroplantaFormControl.value!='null') {
      
      this.guardando = true;

      let preauto = new PreautorizacionDTO();
      preauto.codigoAeroplanta = this.aeroplantaFormControl.value;
      preauto.ctA_SGC = this.clienteFormControl.value.ctA_SGC;
      preauto.cuentaDescripcion = this.clienteFormControl.value.razonSocial;
      let fecha: Date = new Date();
      preauto.fechaHora = this.formatDate(fecha.getFullYear(), fecha.getMonth()+1, fecha.getDate(), fecha.getHours(), fecha.getMinutes());
      preauto.fechaHoraDesde = this.formatDate(
        this.fechaDesdeFormControl.value.year, this.fechaDesdeFormControl.value.month,
        this.fechaDesdeFormControl.value.day, this.horaDesdeFormControl.value.hour, this.horaDesdeFormControl.value.minute);
      preauto.fechaHoraHasta = this.formatDate(
        this.fechaHastaFormControl.value.year, this.fechaHastaFormControl.value.month,
        this.fechaHastaFormControl.value.day, this.horaHastaFormControl.value.hour, this.horaHastaFormControl.value.minute);
      preauto.matricula = this.vehiculoFormControl.value.matricula;
      preauto.usuarioResponsable = this.usuarioService.getNombresCompletosUsuario();

      try {
        let R = await this.clienteService.postPreAutorizaciones(preauto);  
        if (R) {
          this.messageService.showSuccessMessage("Preautorización cargada con éxito.");
          this.guardando = false;
          this.activeModal.close(R);
        }
      } catch (err) {
        if (typeof(err)=='string')
          this.messageService.showErrorMessage(err)
        else
          this.messageService.showErrorMessage(err.message);
        this.guardando = false;
      }      
    }
  }

  obtenerUsuarioLogueado() {
    var u = new Responsable();
    u.DNI = this.usuarioService.getDniOperador();
    u.NombreCompleto = this.usuarioService.getNombresOperador();
    u.UserName = this.usuarioService.getUsername();     
  
    return u;
  }
  
  /** input vehiculo && utils */

  formatterVehiculo = (x: { matricula: string }) => x.matricula;

  searchVehiculo = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    map(term => term.length < 2 || this.cargandoVehiculosDeCliente || isNullOrUndefined(this.vehiculosDeCliente) ? []
      : this.vehiculosDeCliente.filter(v => true && (v.matricula.toLowerCase().indexOf(this.vehiculoFormControl.value.toLowerCase()) > -1) ).slice(0, 10))
  )

  changeVehiculo(): void {
    let encuentraVehiculo = this.vehiculoFormControl.value;    
    if (typeof (this.vehiculoFormControl.value) === 'string') {
      encuentraVehiculo = this.todosLosVehiculos.find(p => p.matricula.toLowerCase().trim() == this.vehiculoFormControl.value.toLowerCase().trim());
    }
  
    //Si no encuentro una aeronave, ofrecemos crear una
    if (!encuentraVehiculo) {
      this.vehiculoNoEncontrado=true;
      this.addValidationError(this.vehiculoFormControl,null);
    }else {
      this.vehiculoFormControl.setValue(encuentraVehiculo);
      this.vehiculoNoEncontrado=false;
      this.verificarVehiculo();      
    }
  }

  verificarVehiculo() {
    if (this.vehiculoFormControl.value) {
      if (this.clienteFormControl.value && this.clienteFormControl.value.id &&
        this.vehiculosDeCliente && this.vehiculosDeCliente.filter(value => value.matricula.toLowerCase() === this.vehiculoFormControl.value.matricula.toLowerCase()).length == 0) {
        const modalRef = this.modal.open(ModalConfirmComponent, { centered: true });
        modalRef.componentInstance.mensaje = "El vehiculo no pertenece al cliente, ¿desea asignarlo de todas formas?"; // should be the id
        modalRef.result.then(() => {
        },
          () => {
            this.vehiculoFormControl.setValue(null);
          }
        );
      }
    }
  }

  /** input cliente && utils */

  searchCliente = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    map(term => term.length < 2 || this.cargandoClientes ? [] :
      this.clientes.filter(v => this.containsString(this.clienteFormControl.value, v.ctA_SGC, v.razonSocial)).slice(0, 10))
  )

  changeCliente(): void {    
    let buscaCliente = this.clienteFormControl.value;
    if (typeof (this.clienteFormControl.value) === 'string') {
      buscaCliente = this.clientes.find(c => c.ctA_SGC.toLowerCase().trim() == this.clienteFormControl.value.ctA_SGC.toLowerCase().trim());
    }

    if(!buscaCliente) {
      this.clienteNoEncontrado=true;
    }else{
      this.clienteNoEncontrado=false;
    }
  }

  selectCliente(event: any): void {
    this.clienteFormControl.setValue(event.item);
    this.obtenerVehiculosCliente((event.item as unknown as CuentaCliente));
  }

  /**
   * Determina si una cadena se encuentra dentro de otra
   * @param searchValue Valor a buscar
   * @param searchIn Valores sobre los que buscar searchValue
   */
  containsString(searchValue: string, ...searchIn: string[]): boolean {
    const concat = searchIn.toString();
    return concat.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
  }

  formatterCliente = (x: { razonSocial: string, ctA_SGC: string }) => `${x.razonSocial} - ${x.ctA_SGC}`;

  /** utiles */

  formatDate(year, month, day, hour, minute): string {
    return year + '-' + month.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + '-' + day.toLocaleString(undefined, { minimumIntegerDigits: 2 })
      + 'T' + hour.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + minute.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':00';
  }

  /** getters */

  get clienteFormControl() {
    return this.preautorizacionForm.get('clienteFormControl');
  }

  get vehiculoFormControl() {
    return this.preautorizacionForm.get('vehiculoFormControl');
  }

  get aeroplantaFormControl() {
    return this.preautorizacionForm.get('aeroplantaFormControl');
  }

  get fechaDesdeFormControl() {
    return this.preautorizacionForm.get('fechaDesdeFormControl');
  }

  get horaDesdeFormControl() {
    return this.preautorizacionForm.get('horaDesdeFormControl');
  }

  get fechaHastaFormControl() {
    return this.preautorizacionForm.get('fechaHastaFormControl');
  }

  get horaHastaFormControl() {
    return this.preautorizacionForm.get('horaHastaFormControl');
  }
    
}
