<div class="card">
  <div class="card-header">
    <nav class="navbar navbar-light bg-light navbar-expand-lg">
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse">
        <ul class="navbar-nav">
          <li class="nav-item me-2">
          <button class="btn btn-primary" (click)="print()"><i class="fa fa-print"></i>Imprimir</button>
        </li>
        <li class="nav-item me-2">
          <button class="btn btn-success" (click)="exportar()"><i class="fa fa-file-excel-o"></i>Exportar</button>
        </li>
        </ul>
      </div>
    </nav>
  </div>

  <div class="card-body">
    <div class="col-12">
      <div class="spinner-container" *ngIf="this.obteniendoRemitos">
        <ngx-spinner name="spinnerGrafico" bdOpacity=0.3bd
          Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
          <p style="color: white">C a r g a n d o . . . </p>
        </ngx-spinner>    
      </div>
      <ngb-alert class="alert alert-warning mt-2" *ngIf="this.remitosVencidos.length == 0 && !this.obteniendoRemitos">No hay remitos vencidos para la
        fecha seleccionada.</ngb-alert>
        <table class="table table-striped table-hover text-center letra col-12" id="printSection"
          *ngIf="this.remitosVencidos.length > 0 && !obteniendoRemitos">
          <thead>
            <th>Cliente</th>
            <th>Cuenta</th>
            <th>Remito</th>
            <th>Producto</th>
            <th>Cantidad</th>
            <th>Fecha/Hora</th>
            <th>Aeroplanta</th>
            <th>Ultima ejecucion</th>
            <th>Ultimo Error</th>
            <th id="noPrint">Ver aerovale</th>
          </thead>
          <tbody>
            <tr *ngFor="let remito of remitosVencidos">
              <td>{{remito.cliente.razonSocial}}</td>
              <td>{{remito.cliente.sgc}}</td>
              <td>{{remito.prefijo | remitoPrefijo }}-{{remito.numeroComprobante | remitoNumero}}</td>
              <td><ul class="list-unstyled" *ngFor="let prod of remito.remito.detalles"><li>{{prod.codigoProducto}}-{{prod.producto}}</li></ul></td>
              <td><ul class="list-unstyled" *ngFor="let prod of remito.remito.detalles"><li>{{prod.cantidad}}</li></ul></td>
              <td>{{remito.fechaEmision | date: 'dd/MM/yyyy HH:mm a'}}</td>
              <td>{{remito.nombreAeroplanta}}</td>
              <td>{{remito.remito.ultimaFechaEjecucion | date: 'dd/MM/yyyy HH:mm a'}}</td>        
              <td>
                <span *ngIf="remitoAusenteEnErrores(remito.remito.ultimoErrorEjecucion2, remito)">
                  {{ remito.remito.ultimoErrorEjecucion }}
                </span>

                <span *ngIf="!remitoAusenteEnErrores(remito.remito.ultimoErrorEjecucion2, remito)">
                  <span *ngFor="let err of remito.remito.ultimoErrorEjecucion2">
                    <div *ngIf="err.includes(obtener(remito))">{{err}}</div>
                  </span>
                </span>
              </td>                  
              <td id="noPrint"><button class="btn btn-primary mb-2" type="button"
                [disabled]="remito.verComprobante" (click)="mostrarRemito(remito)">
                <span *ngIf="!remito.verComprobante"><i class="fa fa-eye" aria-hidden="true"></i></span>
                <div *ngIf="remito.verComprobante" class="d-flex align-items-center">
                  <div class="spinner-border text-light spinner-border-sm" role="status"></div>
                  <span><i class="fa fa-eye" aria-hidden="true"></i></span>
                </div>
              </button></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>