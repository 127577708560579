
import { Precarga } from './Precarga';
import { Aeropuerto } from './Aeropuerto';
import { Cliente } from './Cliente';
import { Producto } from './Producto';
import { VuelosTamsDTO } from './VuelosTamsDTO';
import { VehiculoDTO } from '../cliente/VehiculoDTO';
export class PedidoDetalle {
  id: string;
  cliente: Cliente;
  aeronaveId: string;
  //aerocombustible: string;
  codigoAerocombustible: string;
  codigoAeroplanta: string;
  vuelo: string;
  cantidadEstimada: number;
  porcentajeCierre: number;
  fechaHoraEstimada: string;
  posicion: string;
  estado: number;
  numeroPedido: number;
  fechaHoraVuelo: string;
  destinoId: string;
  tipoPedido: number;
  vuelotams: VuelosTamsDTO;
  ctA_SGC:string;
  
  clienteRazonSocial: string;
  clienteCUIT: string;
  aeronave: VehiculoDTO;
  aeronaveMatricula: string;
  producto: Producto;
  aerocombustibleId: string;
  precargas: Precarga[];
  cantidadFaltante: 10000
  clienteCuit: string;
  destinoNombre: string;
  prefijo: number;
  destino: Aeropuerto;
  esPrioridad: boolean;
}
