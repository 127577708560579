export class MedicionTanque{
    id: string;
    codigoTanque: string;
    nombreTanque: string;
    codigoProducto: string;
    nombreProducto: string;
    varillado: number;
    nivel: number;
    nivelNatural: number;
    temperatura: number;
    densidad: number;
    nivelAgua: number;
    fechaHora: string;
    userName: string;
    esNuevo: boolean;
    roleStatus: boolean;
    densidadString: string;

    desactivarUI: boolean = false;
    desactivarTanque: boolean = false;
}
