import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbCalendar, NgbDatepickerConfig, NgbDateStruct, NgbModal, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ProductoService } from 'src/app/abm/producto/producto.service';
import { UsuarioService } from 'src/app/account/usuario.service';
import { CalculosService } from 'src/app/shared/calculos.service';
import { TipoAerovale } from 'src/app/shared/enums/tipoAerovale';
import { TipoCuenta } from 'src/app/shared/enums/tipoCuenta';
import { TipoExpendedora } from 'src/app/shared/enums/tipoExpendedora';
import { TipoPago } from 'src/app/shared/enums/tipoPago';
import { TipoProducto } from 'src/app/shared/enums/tipoProducto';
import { TipoVehiculo } from 'src/app/shared/enums/tipoVehiculo';
import { ModalConfirmComponent } from 'src/app/shared/generic-modals/modal-confirm.component';
import { Abastecedora } from 'src/app/shared/models/despacho/Abastecedora';
import { AerovaleDetalle } from 'src/app/shared/models/despacho/AerovaleDetalle';
import { CuentaCliente } from 'src/app/shared/models/despacho/CuentaCliente';
import { Operador } from 'src/app/shared/models/despacho/Operador';
import { Producto } from 'src/app/shared/models/despacho/Producto';
import { Surtidor } from 'src/app/shared/models/stock/Surtidor';
import { NuevoClienteComponent } from 'src/app/shared/nuevo-cliente/nuevo-cliente.component';
import { SharedService } from 'src/app/shared/shared.service';
import { MessageService } from 'src/app/shell/message.service';
import { SurtidorService } from 'src/app/stock/surtidor.service';
import { isNullOrUndefined } from 'src/app/shared/compat-tools';
import { Laboratorio } from 'src/app/shared/models/despacho/Laboratorio';
import { AerovalesService } from '../../aerovales.service';
import { ClienteService } from 'src/app/cliente/cliente.service';
import { ProductosService } from '../../productos.service';
import { Cliente } from 'src/app/shared/models/despacho/Cliente';
import { NgxSpinnerService } from 'ngx-spinner';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { Usuario } from 'src/app/shared/models/despacho/Usuario';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';
import { Aeroespecialidad } from 'src/app/shared/models/despacho/Aeroespecialidad';
import { AerovaleDetalleCombustible } from 'src/app/shared/models/despacho/AerovaleDetalleCombustible';
import { CargaArchivo } from 'src/app/shared/models/cliente/CargaArchivo';
import { StockService } from 'src/app/stock/stock.service';
import { TipoBlobStorage } from 'src/app/shared/enums/TipoBlobStorage';
import { Sas } from 'src/app/shared/models/padrones/Sas';
import { AzureBlobUploadService } from 'src/app/shared/azure-blob-upload.service';
import { BlobUploadCommonResponse } from '@azure/storage-blob';
import { environment } from 'src/environments/environment';
import { VehiculoService } from 'src/app/cliente/vehiculo.service';
import { ModalVehiculoComponent } from 'src/app/management/matriculas/abm-matriculas/modal-vehiculo/modal-vehiculo.component';
import { VehiculoDTO } from 'src/app/shared/models/cliente/VehiculoDTO';

@Component({
  selector: 'app-aerovale-updated-common',
  templateUrl: './aerovale-updated-common.component.html',
  styleUrls: ['./aerovale-updated-common.component.scss']
})
export class AerovaleUpdatedCommonComponent implements OnInit {

  @Input() aerovaleReemplaza: AerovaleDetalle;
  @Input() fileAerovaleFisico: File;
  @ViewChild('typeaheadInstance')
  private typeaheadInstance: NgbTypeahead;

  aerovalesForm: UntypedFormGroup;
  guardandoAerovale: boolean = false;
    
  /** common */
  clientes: CuentaCliente[];
  operadores:Operador[]=[];
  abastecedoras: Abastecedora[]=[];
  productos: Producto[]=[];
  todosLosVehiculos: any;
  vehiculosDeCliente: VehiculoDTO[];
  surtidores: Surtidor;

  cargandoInformacion: boolean = false;  
  cargandoClientes: boolean;  
  cargandoOperadores: boolean;
  cargandoAbastecedoras: boolean;  
  cargandoAeronaves: boolean;
  cargandoAeronavesCliente: boolean;
  cargandoVehiculos: boolean;
  cargandoVehiculosDeCliente: boolean;
  obteniendoTanquesSurtidor: boolean = false;

  errorClientes: boolean;
  errorOperadores: boolean;
  errorDestinos:boolean=false;
  errorVehiculos:boolean=false;
  errorVehiculosDeCliente: boolean;

  timeVuelo: any;
  time: any;
  timeFin: any;

  modelHoraLlegada: any;
  modelFechaLlegada: NgbDateStruct;
  modelOperador: any;

  fechaHoraAev: Date;

  destinovalid:boolean;
  densidadMin:number;
  densidadMax:number;

  /** Aeroespecialidades */
  aeroespecialidadesSeleccionables: Producto[];
  arreglo : any[] = [];
  cargandoAeroespecialidades: boolean;
  errorAeroespecialidades: boolean;

  /** Alije */
  laboratorios: Laboratorio[];
  cargandoLaboratorios: boolean;
  errorLaboratorios: boolean;
  modelFechaPruebaLaboratorio: NgbDateStruct;
  modelFechaInicioAlije: NgbDateStruct;
  modelFechaFinAlije: NgbDateStruct;
  modelHoraInicioAlije: any;
  modelHoraFinAlije: any;

  TipoAerovale = TipoAerovale;
  TipoExpendedora = TipoExpendedora;
  TipoPago = TipoPago;

  archivoCargado: CargaArchivo;
  archivoSubido: any;
  clientesBaseUrl = environment.apiServer.clientesBaseUrl;
  aerovaleCreadoCorrectamente: boolean = false;

  readonly entidadesTotales = 6;
  entidadesDescargadas = 0;
  private entidadesDescargadas$ = new Subject<number>();
  private subscription: Subscription;

  combustibleNoCoincide: boolean = false;

  tiempo = environment.frontdoor.timeout;

  constructor(public modal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private datepickerConfig: NgbDatepickerConfig,
    private calendar: NgbCalendar,
    private aerovaleService:AerovalesService,
    private usuarioService:UsuarioService,
    private modalService: NgbModal,    
    private messageService:MessageService,
    private clienteService: ClienteService,
    private productoService: ProductoService,
    private despachoService: ProductosService,
    private surtidorService: SurtidorService,
    private calculoService: CalculosService,
    private sharedService: SharedService,
    private spinner: NgxSpinnerService,
    private stockService:StockService,
    private vehiculosService: VehiculoService,
    private azureBlobUpload: AzureBlobUploadService) { }


ngOnInit() {    

  this.modelFechaLlegada = this.calendar.getToday();
  let inicio: any = { day: 1, month: 1, year: 2019 };
  this.datepickerConfig.minDate = inicio;
  let fin: any = { day: this.modelFechaLlegada.day, month: this.modelFechaLlegada.month , year: this.modelFechaLlegada.year };
  this.datepickerConfig.maxDate = fin;

  this.crearFormulario();
  this.iniciarComponentes();  
}

crearFormulario(){
  this.aerovalesForm = this.fb.group({
    clienteFormControl: [null, Validators.required],
    operadorFormControl: [null, Validators.required],
    vehiculoFormControl: [null, (this.aerovaleReemplaza.tipoAerovale != TipoAerovale.Aeroespecialidad ? Validators.required : null)],
    productoFormControl: [null],
    abastecedoraFormControl: [null, (this.aerovaleReemplaza.tipoAerovale != TipoAerovale.Aeroespecialidad ? Validators.required : null)],
    numeroVueloFormControl: [null, (this.aerovaleReemplaza.tipoAerovale == TipoAerovale.Aerocombustible ? Validators.required : null)],
    fechaLlegadaFormControl: [null, Validators.required],
    horaLlegadaFormControl: [null, Validators.required],    
    tipoPagoFormControl: [null],    
    tanquesAsociadosFormControl: [null],
    picosAsociadosFormControl: [null, (this.aerovaleReemplaza.tipoAerovale != TipoAerovale.Aeroespecialidad ? Validators.required : null)],
    aforadorInicialFormControl: [null, (this.aerovaleReemplaza.tipoAerovale != TipoAerovale.Aeroespecialidad ? Validators.required : null)],
    aforadorFinalFormControl: [null, (this.aerovaleReemplaza.tipoAerovale != TipoAerovale.Aeroespecialidad ? [ Validators.required, Validators.min(1) ]: null)],
    cantidadDespachadaFormControl: [null, (this.aerovaleReemplaza.tipoAerovale != TipoAerovale.Aeroespecialidad ? Validators.required : null)],
    temperaturaFormControl:[null, (this.aerovaleReemplaza.tipoAerovale != TipoAerovale.Aeroespecialidad ? [ Validators.required, this.temperaturaValidator] : null)],
    densidadFormControl:[null, (this.aerovaleReemplaza.tipoAerovale != TipoAerovale.Aeroespecialidad ? Validators.required : null)],
    volumen15GradosFormControl:[null, (this.aerovaleReemplaza.tipoAerovale != TipoAerovale.Aeroespecialidad ? Validators.required : null)],

    aeroespecialidadesSeleccionablesFormControl: [null],
    aeroespecialidadesSeleccionadasFormControlArray: this.fb.array([], (this.aerovaleReemplaza.tipoAerovale == TipoAerovale.Aeroespecialidad ? Validators.required : null)),
  });

  this.aerovalesForm.setValidators(this.aerovaleValidator.bind(this));
}

iniciarComponentes() {

  this.mostrarSpinner2();
  
  this.entidadesDescargadas = 0;

  this.subscription = this.entidadesDescargadas$.subscribe( () => {
    this.entidadesDescargadas++; 
    if(this.entidadesDescargadas == this.entidadesTotales){
      this.precargarDatos();
    }
  }); 

  this.getClientes();
  this.getVehiculos();
  this.getOperadoresConRoles();
  switch (this.aerovaleReemplaza.tipoAerovale) {
    case TipoAerovale.Aeroespecialidad:
      this.getAeroespecialidades()
      break;  
    default:
      this.getAbastecedoras();
      this.getProductos();
      break;
  }  
}

nuevaAeroespecialidadItem(item: any, valor:number): UntypedFormGroup {
  return this.fb.group({
    nombreProducto: [item.nombreProducto, Validators.required],      
    cantidad: [valor, [Validators.required, Validators.min(1)]],
    codigoProducto: [item.codigoProducto],
  })
}  

precargarDatos() {
  if(this.aerovaleReemplaza.vehiculo) {
    this.vehiculoFormControl.setValue(this.aerovaleReemplaza.vehiculo);
  }  
  
  this.fechaHoraAev = new Date(this.aerovaleReemplaza.fechaHoraFinDespacho);
  this.modelFechaLlegada = { day: this.fechaHoraAev.getDate(), month: this.fechaHoraAev.getMonth() + 1, year: this.fechaHoraAev.getFullYear() };
  this.modelHoraLlegada = { hour: this.fechaHoraAev.getHours(), minute: this.fechaHoraAev.getMinutes(), second: this.fechaHoraAev.getSeconds() };

  this.tipoPagoFormControl.setValue(this.aerovaleReemplaza.tipoPago);

  if(this.aerovaleReemplaza.tipoAerovale == TipoAerovale.Combustible) {
    this.aforadorInicialFormControl.setValue(this.aerovaleReemplaza.detalleCombustible.aforadorInicio);
    this.aforadorFinalFormControl.setValue(this.aerovaleReemplaza.detalleCombustible.aforadorFinal);
    this.temperaturaFormControl.setValue(this.aerovaleReemplaza.detalleCombustible.temperatura);
    this.densidadFormControl.setValue(this.aerovaleReemplaza.detalleCombustible.densidad);
    this.volumen15GradosFormControl.setValue(this.aerovaleReemplaza.detalleCombustible.volumen15grados);
    this.picosAsociadosFormControl.setValue(this.aerovaleReemplaza.detalleCombustible.picoId);
    this.obtenerCantidadDespachada();     
  }
  this.ocultarSpinner2();
}

mostrarSpinner2() {
  this.spinner.show('spinnerGrafico');
  this.cargandoInformacion = true;
}

ocultarSpinner2() {
  this.spinner.hide('spinnerGrafico');
  this.cargandoInformacion = false;
}

aerovaleValidator(group: UntypedFormGroup): any{
  if (group) {

    let fechaLlegada = group.get("fechaLlegadaFormControl").value;
    let horaLlegada = group.get("horaLlegadaFormControl").value;

    if(fechaLlegada && horaLlegada){
      var fecha = new Date(fechaLlegada.year, fechaLlegada.month-1,  fechaLlegada.day, horaLlegada.hour, horaLlegada.minute);
      var ahora = new Date();
      if(fecha > ahora){
        return { fechaLlegadaMayorAhora: true };
      }
    }

    let abastecedora = group.get("abastecedoraFormControl").value;

    if(abastecedora != null && abastecedora.tipoExpendedora == TipoExpendedora.SURTIDOR){
      let tanque = group.get("tanquesAsociadosFormControl").value;
      if(tanque == null){
        return { noSeleccionoTanque: true };
      }
    }

    if(this.aerovaleReemplaza.tipoAerovale && this.aerovaleReemplaza.tipoAerovale != TipoAerovale.Aeroespecialidad){

      let aforadoInicial = group.get("aforadorInicialFormControl").value;
      let aforadorFinal = group.get("aforadorFinalFormControl").value;
  
      if (aforadoInicial != null && aforadorFinal != null) {
        if (aforadoInicial >= aforadorFinal) {
          return { aforadorInicialMayorAforadorFinal: true };
        }
      }

      let pico = group.get("picosAsociadosFormControl").value;
      if(pico == null){
        return { noSeleccionoPico: true };
      }
    }

    return null;
  }
}

/** Utilidades */

getTipoAerovale() {
  if (this.aerovaleReemplaza!=null && this.aerovaleReemplaza!=undefined) {
    return TipoAerovale[this.aerovaleReemplaza.tipoAerovale];
  }else{
    return null;
  }  
}

onlyNumberKey(event) {
  return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
}

onlyDecimalPositivo(event) {
  return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46;
}

keysTipoDePago(): Array<string> {
  var keys = Object.keys(this.TipoPago);
  return keys.slice(keys.length / 2);
}

obtenerTipoDePago(tipoCuentaCliente: number): Array<string> {
  if(!tipoCuentaCliente) return [];
  let lista: Array<string> = this.keysTipoDePago();
  lista = lista.filter(e => TipoCuenta[e] == TipoCuenta.Contado || TipoCuenta[e] == tipoCuentaCliente);
  return lista;
}

obtenerCantidadDespachada() {
  let res: number = this.aforadorFinalFormControl.value - this.aforadorInicialFormControl.value;
  if (this.aforadorFinalFormControl.value < this.aforadorInicialFormControl.value) { res = 0; }
  this.cantidadDespachadaFormControl.setValue(res);
  if(this.temperaturaFormControl.value != null && this.densidadFormControl.value != null) {
    this.litrosA15Grados();
  }
}

litrosA15Grados() {  
  let res:number=this.calculoService.convertirLitrosNaturalesEn15Grados(this.temperaturaFormControl.value,this.densidadFormControl.value,this.aforadorInicialFormControl.value,this.aforadorFinalFormControl.value);
  this.volumen15GradosFormControl.setValue(res);
}

nuevoItemCompletar(item: any): UntypedFormGroup {
  var a = this.fb.group({
    nombreAeroplanta: [item.nombreProducto, Validators.required],
    idAeroplanta: [item.cantidad, Validators.required],
    codigoAeroplanta: [item.codigoAeroplanta, Validators.required]
  })

  return a;
}

completarAeroespecialidades() {   
  this.aeroespecialidadesSeleccionadasFormControlArray.clear();
  let item:any;
  for(let ua of this.aerovaleReemplaza.aeroespecialidades) {     
    item = { nombreProducto: ua.nombreProducto, codigoProducto: ua.productoCodigo }
    this.aeroespecialidadesSeleccionadasFormControlArray.push(this.nuevaAeroespecialidadItem(item, ua.cantidad));      
    this.arreglo.push(this.aeroespecialidadesSeleccionables.find( a => a.codigoProducto == ua.productoCodigo ));
    this.aeroespecialidadesSeleccionables=this.aeroespecialidadesSeleccionables.filter( a => a.codigoProducto != ua.productoCodigo );
  }
}

onAeroespecialidadSeleccionada(){
  if(this.aeroespecialidadesSeleccionablesFormControl.value){
    this.aeroespecialidadesSeleccionadasFormControlArray.push(this.nuevaAeroespecialidadItem(this.aeroespecialidadesSeleccionablesFormControl.value, null));
    let p = this.aeroespecialidadesSeleccionables.indexOf(this.aeroespecialidadesSeleccionablesFormControl.value);
    this.arreglo.push(this.aeroespecialidadesSeleccionables[p]);
    this.aeroespecialidadesSeleccionables.splice(p, 1);      
  }
}

onAeroespecialidadEliminada(i:number) {
  let aero = this.arreglo.filter(x => x.codigoProducto == this.aeroespecialidadesSeleccionadasFormControlArray.value[i].codigoProducto); 
  this.aeroespecialidadesSeleccionables.push(aero[0]);
  let pos = this.arreglo.findIndex(x => x.codigoProducto == this.aeroespecialidadesSeleccionadasFormControlArray.value[i].codigoProducto );
  this.arreglo.splice(pos, 1);
  this.aeroespecialidadesSeleccionadasFormControlArray.removeAt(i);
  this.aeroespecialidadesSeleccionablesFormControl.setValue(null);
}

formatDate(year, month, day, hour, minute): string {
  return year + '-' + month.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + '-' + day.toLocaleString(undefined, { minimumIntegerDigits: 2 })
    + 'T' + hour.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + minute.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':00';
}

/** Servicios */

getClientes(): void {
  this.cargandoClientes = true;
  this.clienteService.getClientesActivos()
    .subscribe(clientes => {
      clientes = clientes.map(a => Object.assign(new CuentaCliente(), a));
      this.clientes = clientes;
      this.cargandoClientes = false;
      this.entidadesDescargadas$.next();
      if(this.clientes.length>0) {                
        this.clienteFormControl.setValue(this.clientes.find(c => c.ctA_SGC == this.aerovaleReemplaza.cliente.numeroCuenta));
        this.getVehiculosByCliente(this.aerovaleReemplaza.cliente);
      }
    }, () => this.errorClientes = true);
}

getProductos() {
  this.productoService.obtenerProductos()
    .subscribe(result => {
      this.productos = result;
      this.entidadesDescargadas$.next();
    }, () => {

    });
}

getOperadores(): void {
  this.cargandoOperadores = true;
  this.aerovaleService.getOperadores(0, this.usuarioService.getAeroplantaActual().codigoAeroplanta)
    .subscribe(result => {
      this.operadores = result.map(a => Object.assign(new Operador(), a));
      //this.operadores = operadores;
      this.cargandoOperadores = false;
      if(this.operadores.length>0) {        
        this.operadorFormControl.setValue(this.operadores.find(o => o.nombresCompletos.toLowerCase().trim() == this.aerovaleReemplaza.operadorDTO.nombreCompleto.toLowerCase().trim()));
      }
    }
  );
}
getOperadoresConRoles(): void {
  this.cargandoOperadores = true;
  var roles = this.usuarioService.getRolesEmitirAerovales();
  this.aerovaleService.getOperadoresConRoles(this.operadores, roles, this.usuarioService.getAeroplantaActual().codigoAeroplanta)
    .subscribe(result => {      
      //this.operadores = result.map(a => Object.assign(new Operador(), a));
      this.operadores = result;
      this.cargandoOperadores = false;
      this.entidadesDescargadas$.next();
      if(this.operadores.length>0) {      
        let ope = this.operadores.find(o => o.nombresCompletos.toLowerCase().trim() == this.aerovaleReemplaza.operadorDTO.nombreCompleto.toLowerCase().trim());  
        this.operadorFormControl.setValue(ope);
      }
    }    
  );
}

getAbastecedoras(): void {
  this.cargandoAbastecedoras = true;
  this.aerovaleService.getTodasLasExpendedoras(this.usuarioService.getAeroplantaActual().codigoAeroplanta)
    .subscribe(result => {
      this.aerovaleReemplaza.tipoAerovale == TipoAerovale.Alije ? this.abastecedoras = result.filter(a => a.tipoExpendedora == TipoExpendedora.ABASTECEDORA_CAMION) : this.abastecedoras = result;       
      this.abastecedoras = this.filtrarAbastecedorasPorTipoDeCombustible(this.obtenerTipoProductoPorTipoAerovale());
      this.cargandoAbastecedoras = false;
      if(this.abastecedoras.length>0) {
        this.abastecedoraFormControl.setValue(this.abastecedoras.find(a => a.codigo == this.aerovaleReemplaza.abastecedora.codigo));
        this.productoFormControl.setValue(this.abastecedoraFormControl.value.productoActual.nombreProducto);
        if(this.abastecedoraFormControl.value.tipoExpendedora == TipoExpendedora.SURTIDOR) {
          this.obteniendoTanquesSurtidor = true;
          this.surtidorService.getSurtidoresPorCodigo(this.aerovaleReemplaza.abastecedora.codigo)
            .subscribe(result => {
              this.surtidores = result;
              this.obteniendoTanquesSurtidor = false;
              this.tanquesAsociadosFormControl.setValue( this.aerovaleReemplaza.detalleCombustible.codigoTanque );
            });          
        }
      }
      this.cargandoAbastecedoras = false;
      this.entidadesDescargadas$.next();
    });
}

getAeroespecialidades(): void {
  this.cargandoAeroespecialidades = true;

  this.despachoService.getAeroespecialidadesParaAeroplantaActual(this.aerovaleReemplaza.origen.codigoAeroplanta)
    .subscribe(aeroespecialidades => {
      this.aeroespecialidadesSeleccionables = aeroespecialidades;
      if(this.aeroespecialidadesSeleccionables.length>0) {
        this.completarAeroespecialidades();
      }
      this.cargandoAeroespecialidades = false;
      this.entidadesDescargadas$.next();      
      this.entidadesDescargadas$.next(); // 6º entidad     
    }, () => {
      this.errorAeroespecialidades = true;
      this.cargandoAeroespecialidades = false;
    });
}

buscarTanques(){
  this.tanquesAsociadosFormControl.setValue(null);
  if(this.abastecedoraFormControl.value.tipoExpendedora == TipoExpendedora.SURTIDOR){ 
    this.obteniendoTanquesSurtidor = true;
    this.surtidorService.getSurtidoresPorCodigo(this.abastecedoraFormControl.value.codigo)
      .subscribe(result => {
        this.surtidores = result;
        this.obteniendoTanquesSurtidor = false;
      })
  }
  this.seleccionarPicoDefecto();
  this.tanquesAsociadosFormControl.updateValueAndValidity();
  this.productoFormControl.setValue(this.abastecedoraFormControl.value.producto.nombreProducto);
  this.validarDensidad(this.densidadFormControl.value);
  this.controlVehiculoProducto();
}

seleccionarPicoDefecto() {
  this.abastecedoraFormControl.value.picos.forEach(function (value) {
    if (value.porDefecto) {
      this.picosAsociadosFormControl.setValue(value);
      this.picosAsociadosFormControl.updateValueAndValidity();
    }
  }, this);
}

obtenerTipoProductoPorTipoAerovale(): TipoProducto{
  if(this.aerovaleReemplaza.tipoAerovale == TipoAerovale.Combustible){
    return TipoProducto.Combustible;
  }else if(this.aerovaleReemplaza.tipoAerovale == TipoAerovale.Aeroespecialidad){
    return TipoProducto.Aeroespecialidad;
  }
}

filtrarAbastecedorasPorTipoDeCombustible(tipoProducto: TipoProducto): Abastecedora[] {
  let filtrados: Abastecedora[] = [];
  for (let a of this.abastecedoras) {
    if (a.activo && a.producto.tipoProducto === tipoProducto) {
      filtrados.push(a);
    }
  }
  return filtrados;
}

/**
 * Determina si una cadena se encuentra dentro de otra
 * @param searchValue Valor a buscar
 * @param searchIn Valores sobre los que buscar searchValue
 */
 containsString(searchValue: string, ...searchIn: string[]): boolean {
  const concat = searchIn.toString();
  return concat.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
}

getVehiculos() {
  this.todosLosVehiculos = []; 
  this.cargandoVehiculos = true;   
  if(this.aerovaleReemplaza.tipoAerovale != TipoAerovale.Combustible){
    this.vehiculosService.getAeronaves()
      .subscribe( a => {
        this.todosLosVehiculos = this.todosLosVehiculos.concat(a);
        this.cargandoVehiculos = false;
        this.entidadesDescargadas$.next();
      }, ()=> {
        this.cargandoVehiculos = false;
        this.errorVehiculos = true;
      })
    }
  if(this.aerovaleReemplaza.tipoAerovale == TipoAerovale.Combustible || this.aerovaleReemplaza.tipoAerovale == TipoAerovale.Aeroespecialidad){
    this.cargandoVehiculos = true;
    this.vehiculosService.getAutomotores()
    .subscribe( a => {
      this.todosLosVehiculos = this.todosLosVehiculos.concat(a);
      this.cargandoVehiculos = false;
      this.entidadesDescargadas$.next();
    }, ()=> {
      this.cargandoVehiculos = false;
      this.errorVehiculos = true;
    })
  }
}

getVehiculosByCliente(cte: Cliente): void {
  this.cargandoVehiculosDeCliente = true;  
  this.vehiculosService.getCuentasVehiculos(cte.tipoDocumento, cte.nroDocumento)
    .subscribe( resp => {
      if (this.aerovaleReemplaza.tipoAerovale == TipoAerovale.Combustible) {
        this.vehiculosDeCliente = resp.filter( v => v.ctA_SGC == cte.numeroCuenta)[0].vehiculos.filter( a => a.tipoVehiculo==TipoVehiculo.Automotor);
      }else{
        this.vehiculosDeCliente = resp.filter( v => v.ctA_SGC == cte.numeroCuenta)[0].vehiculos;
      }        
      if (typeof (this.vehiculoFormControl.value) != 'string' && !this.cargandoInformacion){
        this.verificarVehiculo();
      }
      this.cargandoVehiculosDeCliente = false;
      this.entidadesDescargadas$.next();
    }, () => {
      this.errorVehiculosDeCliente = true;
      this.cargandoVehiculosDeCliente = false;
    });
}

/** Input Operador */

searchOperador = (text$: Observable<string>) =>
text$.pipe(
  debounceTime(200),
  distinctUntilChanged(),
  map(term => this.operadores == null || term.length < 2 || this.cargandoOperadores ? [] :
    this.operadores.filter(op => this.containsString(this.operadorFormControl.value, op.nombresCompletos, op.dni)).slice(0, 10))
);

formatterOperador = (op: { nombresCompletos: string, dni: string }) => `${op.nombresCompletos} - ${op.dni == null ? 'SIN DNI' : op.dni}`;

selectOperador(event: any): void {
  this.operadorFormControl.setValue(event.item);
}

/** Input Clientes */

searchCliente = (text$: Observable<string>) =>
text$.pipe(
  debounceTime(200),
  distinctUntilChanged(),
  map(term => this.clientes == null || term.length < 2 || this.cargandoClientes ? [] :
    this.clientes.filter(v => this.containsString(this.clienteFormControl.value, v.ctA_SGC, v.razonSocial)).slice(0, 10))
)

confirmCliente(): void {
  if (typeof (this.clienteFormControl.value) === 'string') {
    this.nuevoCliente(this.clienteFormControl.value);
  }
}

formatterCliente = (x: { razonSocial: string, ctA_SGC: string }) => `${x.razonSocial} - ${x.ctA_SGC == null ? 'SIN CTA SGC' : x.ctA_SGC}`;

selectCliente(event: any): void {
  this.clienteFormControl.setValue(event.item);
}

nuevoCliente(clientText): void {
  const modalRef = this.modalService.open(NuevoClienteComponent);
  if (typeof (clientText) === 'string') {
    modalRef.componentInstance.nombreCliente = clientText; // should be the id
  }
  modalRef.result.then((cliente) => {
    this.clientes.push(cliente);
    this.clienteFormControl.setValue(cliente);
  }).catch((error) => {
    this.clienteFormControl.setValue(null);
  });
}

/** Input vehiculo o automotor */

//setea el aerocombustible de acuerdo a la aeronave seleccionada
changeVehiculo(): void {
  if ((this.aerovaleReemplaza.tipoAerovale==TipoAerovale.Aeroespecialidad && this.vehiculoFormControl.value.length>0) 
    || this.aerovaleReemplaza.tipoAerovale!=TipoAerovale.Aeroespecialidad) {
    let encuentraVehiculo = this.vehiculoFormControl.value;
    //Esto es por si ingresan el valor abc123 pero existe una aeronave ABC123, entonces elige esta última
    if (typeof (this.vehiculoFormControl.value) === 'string') {
      encuentraVehiculo = this.todosLosVehiculos.filter(v => this.vehiculoHabilitado(v) ).find(p => p.matricula.toLowerCase().trim() == this.vehiculoFormControl.value.toLowerCase().trim());
    }
  
    //Si no encuentro una aeronave, ofrecemos crear una
    if (!encuentraVehiculo) {
      this.nuevoVehiculo(this.vehiculoFormControl.value);
    }else {
      this.vehiculoFormControl.setValue(encuentraVehiculo);  
      this.verificarVehiculo();      
    }
  } 
}
//Función para agregarle un scroll a agregar cliente en reemplazo aerovale
typeaheadKeydown($event: KeyboardEvent) {
  if (this.typeaheadInstance.isPopupOpen()) {
    setTimeout(() => {
      const popup = document.getElementById(this.typeaheadInstance.popupId);
      const activeElements = popup.getElementsByClassName('active');
      if (activeElements.length === 1) {
        // activeElements[0].scrollIntoView();
        const elem = (activeElements[0] as any);
        popup.style.position = 'relative';
        popup.style.zIndex = '99999';
        popup.style.height = '200px'; 
        popup.style.overflowY = 'scroll';
        
        if (typeof elem.scrollIntoViewIfNeeded === 'function') {
          // funciona en chrome y en edge seguro...
          elem.scrollIntoViewIfNeeded();
        } 
      }
    });
  }
}	
vehiculoHabilitado(v : VehiculoDTO){
  if(this.aerovaleReemplaza.tipoAerovale == TipoAerovale.Combustible){
    if(v.tipoVehiculo == TipoVehiculo.Automotor){
      return true;
    }else{
      return false;
    }
  }else{
    return true;
  }
}

verificarVehiculo() {
  if (this.vehiculoFormControl.value) {
    if (this.clienteFormControl.value && this.clienteFormControl.value.id &&
      this.vehiculosDeCliente && this.vehiculosDeCliente.filter(value => value.matricula.toLowerCase().trim() === this.vehiculoFormControl.value.matricula.toLowerCase().trim()).length == 0) {
      const modalRef = this.modalService.open(ModalConfirmComponent, { centered: true });
      modalRef.componentInstance.mensaje = "El vehiculo no pertenece al cliente, ¿desea asignarlo de todas formas?"; // should be the id
      modalRef.result.then(() => {
      },
        () => {
          this.vehiculoFormControl.setValue(null);
        }
      );
    }
  }
}

nuevoVehiculo(matriculaText: any): void {
  const modalRef = this.modalService.open(ModalVehiculoComponent, { backdrop: "static", centered: true });
  modalRef.componentInstance.matriculaAeronave = matriculaText ? matriculaText : ''; // should be the id
  modalRef.componentInstance.tipoVehiculoParaFiltrar = this.obtenerTipoVehiculoPorTipoAerovale();
  modalRef.componentInstance.vehiculos = this.todosLosVehiculos;
     
  modalRef.result.then((vehiculo) => {
    this.messageService.showSuccessMessage("Vehiculo creado");
    this.todosLosVehiculos.push(vehiculo);
    this.vehiculoFormControl.setValue(vehiculo);
  }); 
}

obtenerTipoVehiculoPorTipoAerovale(): TipoVehiculo{
  if(this.aerovaleReemplaza.tipoAerovale == TipoAerovale.Aerocombustible || this.aerovaleReemplaza.tipoAerovale == TipoAerovale.Alije){
    return TipoVehiculo.Aeronave;
  }else if(this.aerovaleReemplaza.tipoAerovale == TipoAerovale.Combustible){
    return TipoVehiculo.Automotor;
  }else {
    return TipoVehiculo.Indistinto;
  }
}

formatterVehiculo = (x: { matricula: string }) => x.matricula;

searchVehiculo = (text$: Observable<string>) =>
text$.pipe(
  debounceTime(200),
  distinctUntilChanged(),
  map(term => term.length < 2 || this.cargandoVehiculosDeCliente || isNullOrUndefined(this.vehiculosDeCliente) ? []
    : this.vehiculosDeCliente.filter(v => this.vehiculoHabilitado(v) && (v.matricula.toLowerCase().indexOf(this.vehiculoFormControl.value.toLowerCase()) > -1) ).slice(0, 10))
)

/** Validaciones */

controlVehiculoProducto() {
  if (this.abastecedoraFormControl.value instanceof Object && this.vehiculoFormControl.value instanceof Object && !this.cargandoAbastecedoras && !this.cargandoVehiculos) {      
    if(this.vehiculoFormControl.value.codigoProducto != this.abastecedoraFormControl.value.producto.codigoProducto)
      this.combustibleNoCoincide = true;
    else 
      this.combustibleNoCoincide = false;
  }
}

esCampoVacio(campo: any): boolean {
  return (campo == null || (campo != null && campo.length == 0));
}

validarDensidad(densidad) {
  if (densidad!=null){
    var productoSeleccionado = this.productos.find(element => element.codigoProducto===this.abastecedoraFormControl.value.producto.codigoProducto);
    let esValido = true;
    esValido = (densidad >= productoSeleccionado.densidadMin && densidad <= productoSeleccionado.densidadMax);
    this.densidadMin = productoSeleccionado.densidadMin;
    this.densidadMax = productoSeleccionado.densidadMax;
    return esValido;
  }
 }

 formatDensidad(event) {
  if (event.charCode == 8 || event.charCode == 0) return null;

  if (event.charCode >= 48 && event.charCode <= 57) {
    this.densidadFormControl.setValue(this.sharedService.formatDensidad(event));
  }
  return false;
}

temperaturaValidator(control: UntypedFormControl) {
  if (control.value > 100) {
    return { temperaturaFueraDeRango: true };
  }

  if (control.value <= -50) {
    return { temperaturaFueraDeRango: true };
  }

  return null;
}

cantidadDeCaracteres(n: any): number {
  if (n != null) {
    return n.length;
  }
  return 0;
}


obtenerUsuarioLogueado() {
  var u = new Usuario();
  u.dni = this.usuarioService.getDniUsuario();
  u.nombreCompleto = this.usuarioService.getNombresCompletosUsuario();
  u.userName = this.usuarioService.getUsername();
  u.tipoUsuario = TipoUsuario[this.usuarioService.getTipoUsuario()];

  return u;
}

obtenerUsuario(usuario: Operador) {
  var u = new Usuario();
  u.dni = usuario.dni
  //Se agrega a "nombreCompleto" el nombre del usuario logueado con el fin de que aparezca como responsable en la tabla de Movimientos 
  // u.nombreCompleto = this.usuarioService.getNombresCompletosUsuario();
  u.nombreCompleto = usuario.nombresCompletos
  u.userName = usuario.userName;
  u.tipoUsuario = usuario.tipoUsuario;

  return u;
}

obtenerNumeracionAerovale() {
  if (this.aerovaleReemplaza!=null && this.aerovaleReemplaza!=undefined) {
    return this.aerovaleReemplaza.prefijo+'-'+this.aerovaleReemplaza.numeroAerovale
  }else{
    return null
  }  
}

procesarAeroespecialidades() {
  let listaAeroespecialidades = new Array<Aeroespecialidad>();

    for (let i = 0; i < this.aeroespecialidadesSeleccionadasFormControlArray.length; i++) {
      let cantidad = this.aeroespecialidadesSeleccionadasFormControlArray.value[i].cantidad;
      let nombre = this.aeroespecialidadesSeleccionadasFormControlArray.value[i].nombreProducto;
      let codigo = this.aeroespecialidadesSeleccionadasFormControlArray.value[i].codigoProducto;

      let item = new Aeroespecialidad(cantidad, nombre, codigo);
      listaAeroespecialidades.push(item);
    }  

  return listaAeroespecialidades;
}

accionarForm() {
  Object.keys(this.aerovalesForm.controls).forEach(key => {
    this.aerovalesForm.get(key).markAsDirty();
  });
}

reemplazarAerovale(tipoAev:TipoAerovale) {

  this.messageService.clear();

  if(this.aerovaleReemplaza.tipoAerovale == TipoAerovale.Combustible) {
    if (this.abastecedoraFormControl.value.tipoExpendedora == TipoExpendedora.SURTIDOR && this.tanquesAsociadosFormControl.value == null) { // abastecedora surtidor
      this.messageService.showErrorMessage("Debe completar el tanque de extracción del combustible.");
      return;
    }
  
    if (this.picosAsociadosFormControl.value == null) {
      this.messageService.showErrorMessage("Debe seleccionar un aforador de la abastecedora.");
      return;
    }

    if (this.aforadorInicialFormControl.value && this.aforadorFinalFormControl.value == 0) {
      this.messageService.showErrorMessage("El aforador inicial debe ser mayor al aforador final.");
      return;
    }

    if (!this.validarDensidad(this.densidadFormControl.value)) {
      this.messageService.showErrorMessage("La densidad debe estar dentro del rango del producto. ["+ this.densidadMin +"-"+ this.densidadMax + "]");
      return;
    }
  } else {
    if (this.aeroespecialidadesSeleccionadasFormControlArray.length == 0) {
      this.messageService.showErrorMessage("Debe seleccionar una aeroespecialidad.");
      return;
    }
  }
  

  this.accionarForm();

  if (this.aerovalesForm.valid) {
    try {
      this.spinner.show();
      this.aerovaleCreadoCorrectamente = false;
      //Si pasa un minuto se esconde el cartel azul (spinner)
      setTimeout(() => {
        this.spinner.hide();
        this.guardandoAerovale = false;
        if (!this.aerovaleCreadoCorrectamente) {
          this.messageService.showErrorMessage("No se pudo procesar el aerovale, por favor vuelva a intentar");
        }        
      }, this.tiempo); // frontdoor

      let aerovale = new AerovaleDetalle();
  
      let cuentaCliente: CuentaCliente = this.clienteFormControl.value;
      let clienteDespacho: Cliente = new Cliente();
      clienteDespacho.numeroCuenta = cuentaCliente.ctA_SGC;
      clienteDespacho.razonSocial = cuentaCliente.razonSocial;
      clienteDespacho.tipoDocumento = cuentaCliente.tipoDocumento;
      clienteDespacho.nroDocumento = cuentaCliente.numeroDocumento;
      clienteDespacho.condicionIva = cuentaCliente.tipoCliente;
      clienteDespacho.tipoPago = cuentaCliente.tipoCuenta;
  
      aerovale.origen = this.aerovaleReemplaza.origen;
      aerovale.cliente = clienteDespacho;
      aerovale.tipoPago = this.tipoPagoFormControl.value;
      aerovale.estado = "Anulado";
      aerovale.aeroespecialidades = [];
      aerovale.remanentes = this.aerovaleReemplaza.remanentes;
      
      aerovale.fechaHoraInicioDespacho = this.formatDate(
        this.fechaLlegadaFormControl.value.year, this.fechaLlegadaFormControl.value.month,
        this.fechaLlegadaFormControl.value.day, this.horaLlegadaFormControl.value.hour, this.horaLlegadaFormControl.value.minute);
      
      aerovale.fechaHoraFinDespacho = this.formatDate(
        this.fechaLlegadaFormControl.value.year, this.fechaLlegadaFormControl.value.month,
        this.fechaLlegadaFormControl.value.day, this.horaLlegadaFormControl.value.hour, this.horaLlegadaFormControl.value.minute);
        
      aerovale.fechaHoraLlegada = null;
      aerovale.vehiculo = this.vehiculoFormControl.value;
  
      aerovale.operador = null;
      aerovale.supervisor = null;
      aerovale.operadorDTO = this.obtenerUsuario(this.operadorFormControl.value);
      aerovale.supervisorDTO = this.obtenerUsuarioLogueado();
  
      if(tipoAev==TipoAerovale.Combustible) {
        aerovale.volumen = this.cantidadDespachadaFormControl.value;
        aerovale.producto = this.abastecedoraFormControl.value.producto;
        aerovale.abastecedora = this.abastecedoraFormControl.value;
  
        let detalle = new AerovaleDetalleCombustible();
  
        detalle.aforadorInicio = this.aforadorInicialFormControl.value;
        detalle.aforadorFinal = this.aforadorFinalFormControl.value;
        detalle.densidad = this.densidadFormControl.value;
        detalle.temperatura = this.temperaturaFormControl.value;
        detalle.volumen15grados = this.volumen15GradosFormControl.value;
        if(typeof (this.picosAsociadosFormControl.value) === 'string') {
          detalle.picoId = this.picosAsociadosFormControl.value
        } else {
          detalle.picoId = this.picosAsociadosFormControl.value.id
        }        
        if(this.abastecedoraFormControl.value.tipoExpendedora == TipoExpendedora.SURTIDOR) {
          detalle.codigoTanque = this.tanquesAsociadosFormControl.value;
        }
        
        aerovale.detalleCombustible = detalle;
      } else if(tipoAev==TipoAerovale.Aeroespecialidad) {
        aerovale.aeroespecialidades = this.procesarAeroespecialidades();
        aerovale.vuelo = this.numeroVueloFormControl.value;
      }
          
      aerovale.tipoAerovale = tipoAev;
      aerovale.vuelo = this.numeroVueloFormControl.value;
      aerovale.detalleAerocombustible = null;
      aerovale.servicios = null;
      aerovale.ciudadIdAerovale = this.aerovaleReemplaza.origen.ciudadId;      

      if(this.fileAerovaleFisico) {
        this.guardandoAerovale = true;

        const carga: CargaArchivo = new CargaArchivo(this.fileAerovaleFisico.name);
        this.stockService.obtenerSas(TipoBlobStorage.firmaoperador, true)
          .subscribe((sas: Sas) => {
            let aborter = this.azureBlobUpload.uploadFileToBlobStorage(this.fileAerovaleFisico, sas.containerUri, sas.sas,
              (pro) => {
                carga.progress = pro;
              },
              (val: BlobUploadCommonResponse) => {
                  if (val) {
                    carga.progress = 100;                    
                    
                    aerovale.firmaOperador = "";
                    aerovale.firmaCliente = "";
                    aerovale.pathFirmaOperador = this.fileAerovaleFisico.name;
                    aerovale.pathFirmaCliente = this.fileAerovaleFisico.name;

                    this.aerovaleService.putReemplazarAerovales(this.aerovaleReemplaza.id, aerovale)
                    .subscribe(respuesta => {
                      if (respuesta.warnings != null && respuesta.warnings.length > 0) {
                        for (let entry of respuesta.warnings) {                
                          this.messageService.showWarningMessage(entry, true);
                        }
                      }
                      if(respuesta) {
                        this.messageService.showSuccessMessage("El Aerovale fue reemplazo con éxito. Nro.: "+respuesta.prefijo+"-"+respuesta.numeroAerovale);
                        this.ocultarSpinner(true);
                        this.modal.close(true);
                      }                      
                    }, (err:ErrorModel) => {
                      this.messageService.showErrorMessage("Error al reemplazar el aev. " + err.description);
                      this.ocultarSpinner(false);
                    });
                  }
              });  
              carga.setAborter(aborter);   
          }, (err:ErrorModel) => {
            this.messageService.showErrorMessage("Error al obtener el SAS - " + err.description);
            this.ocultarSpinner(false);
          });          
      }else{
        // al momento de abrir la modal valido el archivo fisico, pero cierro la llamada por las dudas
        this.messageService.showErrorMessage("El archivo no se capturo correctamente, vuelva intentarlo.");
        this.ocultarSpinner(false);
      }     
    } catch (error) {
      this.messageService.showErrorMessage("Error en proceso "+error);
      this.ocultarSpinner(false);
    }
  }
}

ocultarSpinner(result:boolean) {
  this.guardandoAerovale = false;
  this.spinner.hide();
  result ? this.aerovaleCreadoCorrectamente = true : this.aerovaleCreadoCorrectamente = false;
}

 /** getters */

 get mostrarAbastecedora() {
  return (this.abastecedoraFormControl.value != null && this.abastecedoras.length>0);
}

 get numeroAerovaleFormControl() {
  return this.aerovalesForm.get('numeroAerovaleFormControl');
}

get clienteFormControl() {
  return this.aerovalesForm.get('clienteFormControl');
}

get operadorFormControl() {
  return this.aerovalesForm.get('operadorFormControl');
}

get vehiculoFormControl() {
  return this.aerovalesForm.get('vehiculoFormControl');
}

get productoFormControl() {
  return this.aerovalesForm.get('productoFormControl');
}

get tipoPagoFormControl() {
  return this.aerovalesForm.get('tipoPagoFormControl');
}

get tipoVueloFormControl() {
  return this.aerovalesForm.get('tipoVueloFormControl');
}

get destinoFormControl() {
  return this.aerovalesForm.get('destinoFormControl');
}

get numeroVueloFormControl() {
  return this.aerovalesForm.get('numeroVueloFormControl');
}

get temperaturaFormControl() {
  return this.aerovalesForm.get('temperaturaFormControl');
}

get densidadFormControl() {
  return this.aerovalesForm.get('densidadFormControl');
}

get aforadorInicialFormControl() {
  return this.aerovalesForm.get('aforadorInicialFormControl');
}

get aforadorFinalFormControl() {
  return this.aerovalesForm.get('aforadorFinalFormControl');
}

get cantidadDespachadaFormControl() {
  return this.aerovalesForm.get('cantidadDespachadaFormControl');
}

get volumen15GradosFormControl() {
  return this.aerovalesForm.get('volumen15GradosFormControl');
}

get fechaLlegadaFormControl() {
  return this.aerovalesForm.get('fechaLlegadaFormControl');
}

get horaLlegadaFormControl() {
  return this.aerovalesForm.get('horaLlegadaFormControl')
}

get fechaInicioDespachoFormControl() {
  return this.aerovalesForm.get('fechaInicioDespachoFormControl');
}
get horaInicioDespachoFormControl() {
  return this.aerovalesForm.get('horaInicioDespachoFormControl');
}

get fechaFinDespachoFormControl() {
  return this.aerovalesForm.get('fechaFinDespachoFormControl');
}

get horaFinDespachoFormControl() {
  return this.aerovalesForm.get('horaFinDespachoFormControl');
}

get abastecedoraFormControl() {
  return this.aerovalesForm.get('abastecedoraFormControl');
}

get tanquesAsociadosFormControl() {
  return this.aerovalesForm.get('tanquesAsociadosFormControl');
}

get picosAsociadosFormControl() {
  return this.aerovalesForm.get('picosAsociadosFormControl');
}

get tipoPicoFormControl() {
  return this.aerovalesForm.get('tipoPicoFormControl');
}

/** getters Aeroespecialidades */
get aeroespecialidadesSeleccionablesFormControl() {
  return this.aerovalesForm.get('aeroespecialidadesSeleccionablesFormControl');
}

get aeroespecialidadesSeleccionadasFormControlArray() : UntypedFormArray {
  return this.aerovalesForm.get('aeroespecialidadesSeleccionadasFormControlArray') as UntypedFormArray;
}

}