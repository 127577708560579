export class Cliente
{
  id: string;
  razonSocial: string;
  ctc: string;
  sgc:string;
  tipoDocumento: number;
  numeroDocumento: string;
  numeroIIBB: string;
  condicionPrecio: string;
  direccion:string;
  nombreCiudad:string;
  codigoPostalCiudad:string;
  nombreProvincia:string;
  condicionIVA: string;
  condicionIVACodigoAfip:string;
  condicionIVADiscrimina:boolean;
  domicilio: string;
  nombreLocalidad: string;
  codigoPostalLocalidad: string;
  agrupacionFacturacion: number;

}
