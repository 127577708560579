<div class="col-12 mt-2" *ngIf="cargando && this.cierreSeleccionado != null">
  <div class="container">
    <div class="mb-3" style="height: 10cm;">
      <ngx-spinner name="spinnerGrafico" [ngStyle]="{'height': '400px', 'z-index':'0'}" bdOpacity=0.3bd
        Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
        <p style="color: white">C a r g a n d o . . . </p>
      </ngx-spinner>
    </div>
  </div>
</div>

<div class="card-body" *ngIf="this.cierreSeleccionado != null && !cargando && this.reporteSeleccionado == 3">
  <div class="noImprimir" *ngIf="this.distintosRanchos.length > 0">
    <div class="row ms-2 mb-2">
      <!-- ranchos -->
    <div ngbDropdown autoClose="outside">
      <button class="btn btn-outline-primary my-2 my-lg-20 mx-lg-2" id="dropdownForm1"
        ngbDropdownToggle>Seleccionar Ranchos</button>
      <div ngbDropdownMenu aria-labelledby="dropdownForm1" style="z-index: 50">
        <div class="px-4 py-3" style="z-index: 50">
          <div class="form-check mb-2">
            <input type="checkbox" checked class="custom-control-input" id="Todos"
              (change)="seleccionarTodosRanchos($event)">
            <label class="custom-control-label text-nowrap" for="Todos">
              Todos los ranchos
            </label>
          </div>
          <div *ngFor="let r of distintosRanchosFiltrado">
            <div class="form-check">
              <input type="checkbox" class="custom-control-input" id={{r.rancho.comprobante.remito.clienteCierre.Rancho}}
                [(ngModel)]="r.seleccionado">
              <label class="custom-control-label text-nowrap" for={{r.rancho.comprobante.remito.clienteCierre.Rancho}}>
                {{ r.rancho.comprobante.remito.clienteCierre.Rancho }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- clientes -->
    <div ngbDropdown autoClose="outside">
      <button class="btn btn-outline-primary my-2 my-lg-20 mx-lg-2" id="dropdownForm1"
        ngbDropdownToggle>Seleccionar Clientes</button>
      <div ngbDropdownMenu aria-labelledby="dropdownForm1" style="z-index: 50">
        <div class="px-4 py-3" style="z-index: 50">
          <div class="form-check mb-2">
            <input type="checkbox" checked class="form-check-input" id="TodosCli"
              (change)="seleccionarTodosClientes($event)">
            <label class="form-check-label text-nowrap" for="TodosCli">
              Todos los clientes
            </label>
          </div>
          <div *ngFor="let c of clientes">
            <div class="form-check">
              <input type="checkbox" class="custom-control-input" id={{c.cliente}}
                [(ngModel)]="c.seleccionado">
              <label class="custom-control-label text-nowrap" for={{c.cliente}}>
                {{ c.cliente }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- productos -->
    <div ngbDropdown autoClose="outside">
      <button class="btn btn-outline-primary my-2 my-lg-20 mx-lg-2" id="dropdownForm1"
        ngbDropdownToggle>Seleccionar Productos</button>
      <div ngbDropdownMenu aria-labelledby="dropdownForm1" style="z-index: 50">
        <div class="px-4 py-3" style="z-index: 50">
          <div class="form-check mb-2">
            <input type="checkbox" checked class="custom-control-input" id="TodosPro"
              (change)="seleccionarTodosProductos($event)">
            <label class="custom-control-label text-nowrap" for="TodosPro">
              Todos los productos
            </label>
          </div>
          <div *ngFor="let p of productos">
            <div class="form-check">
              <input type="checkbox" class="custom-control-input" id={{p.producto}}
                [(ngModel)]="p.seleccionado">
              <label class="custom-control-label text-nowrap" for={{p.producto}}>
                {{ p.producto }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    <div class="form-check mb-2 ms-3">
      <input type="checkbox" class="custom-control-input" id="anulados"
        [(ngModel)]="mostrarAnulados">
      <label class="custom-control-label text-nowrap" for="anulados">
        Mostrar anulados
      </label>
    </div>
  </div>
  <ngb-alert class="alert-warning" *ngIf="this.distintosRanchos.length == 0">No hay ranchos consumidos en este
    turno.
  </ngb-alert>
  <div class="col-12 mt-2" *ngFor="let dist of distintosRanchosFiltrado">
    <div class="saltoPagImp" *ngIf="dist.seleccionado">
      <div class="border border-solid">
        <label class="ms-1"><b>Movimientos realizados del rancho:
            {{dist.rancho.comprobante.remito.clienteCierre.Rancho}}</b></label>    
        <ngb-alert class="alert-warning" *ngIf="this.arregloDistintoProd(dist.rancho.comprobante.remito.clienteCierre.Rancho).length==0">
          Sin movimientos.
        </ngb-alert>    
        <div *ngIf="this.arregloDistintoProd(dist.rancho.comprobante.remito.clienteCierre.Rancho).length>0">
          <!--ABASTECEDORAS-->
          <table class="table table-hover letra">
            <thead class="text-center">
              <tr>
                <th>Aerovale</th>
                <th style="width:30%">Cliente</th>
                <th>Cuenta</th>
                <th>Producto</th>
                <th>Cantidad</th>
                <th>Cantidad 15°</th>
                <th class="noImprimir">Ver</th>
              </tr>
            </thead>
            <tbody class="text-center">
              <tr *ngFor="let mov of this.arregloDistintoProd(dist.rancho.comprobante.remito.clienteCierre.Rancho); let i = index"
                [ngClass]="{'resaltado': this.revisarAnulados(mov)}">
                <td>{{mov.comprobante.remito.aerovalePrefijo}}-{{mov.comprobante.remito.aerovaleNumero}}</td>
                <td>
                  {{mov.comprobante.remito.clienteCierre.ClienteNombre}}-{{mov.comprobante.remito.clienteCierre.ClienteCUIT}}
                </td>
                <td>{{mov.comprobante.remito.clienteCierre.Cuenta_SGC}}</td>
                <td>{{mov.codigoProducto}}-{{mov.nombreProducto}}</td>
                <td>{{mov.salidaNatural? mov.salidaNatural : 0}}</td>
                <td>{{mov.salida15? mov.salida15 : 0}}</td>
                <td class="noImprimir">
                  <button *ngIf="mov.comprobante.remito.aerovaleId != null" class="btn" (click)="obtenerAerovale(mov.comprobante.remito.aerovaleId, i)" ngbTooltip="Ver aerovale">
                    <i *ngIf="!obteniendoAerovale || obteniendoAerovale && aerovaleSeleccionado!=i" class="fa fa-eye" aria-hidden="true"></i>
                    <div *ngIf="aerovaleSeleccionado==i && obteniendoAerovale">
                        <div class="spinner-border text-primary spinner-border-sm" role="status"></div>
                        <span><i class="fa fa-eye" aria-hidden="true"></i></span>
                    </div>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
  
        <!-- <div class="row">
          <div class="col-md-12">
            <button style="float:right;" *ngIf="cierreSeleccionado.id != null" (click)="exportar()" class="btn btn-primary">
              <i class="fa fa-file-excel-o" aria-hidden="true"></i> Exportar 
            </button>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</div>