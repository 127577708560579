<ngx-spinner 
  bdColor="rgba(19,123,198,0.7)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">
    <br/>
    Aguarde a que se procese el aerovale y sera redirigido automáticamente.
    <br/>
    No salga de esta pagina.
  </p>
</ngx-spinner>

<div class="card add-margin">
  <div class="card-header">
    <div class="row">
      <div class="col-sm-10">
        <h4 class="card-title ">Nuevo Aerovale {{getDescripcionAerovale()}}</h4>
      </div>
      <div class="col-sm-2">
        <button class="btn btn-secondary float-end" (click)="cancelar()">Volver</button>
      </div>
    </div>
  </div>
  <div class="card-body">
    <form [formGroup]="aerovaleForm" (ngSubmit)="onSubmit()">

      <aerovale-common-form formControlName="aerovaleCommon" 
        [tipoAerovale]="tipoAerovale"
        (formularioValido)="onFormularioCommonValido($event)" 
        (objetCommonForm)="obtenerObjectCommonForm($event)">
      </aerovale-common-form>

      <aerovale-combustible-form *ngIf="tipoAerovale === enumTipoAerovale.Combustible" formControlName="aerovaleCombustible" 
        [esValidaDensidad]="esValidaDensidad"
        [esDensidadMin]="esDensidadMin" 
        [esDensidadMax]="esDensidadMax"
        (envioDensidad)="validarDensidad($event)"  
        (formularioValido)="onFormularioCombustibleValido($event)"  
        (objetCombustibleForm)="obtenerObjectCombustibleForm($event)">
      </aerovale-combustible-form>

      <aerovale-aeroespecialidad-form
        *ngIf="tipoAerovale === enumTipoAerovale.Aeroespecialidad" [tipoAerovale]="tipoAerovale" formControlName="aerovaleAeroespecialidad"
        (formularioValido)="onFormularioAeroespecialidadValido($event)" 
        (objetAeroespecialidadForm)="obtenerObjectAeroespecialidadForm($event)">
      </aerovale-aeroespecialidad-form>

      <aerovale-alije-form *ngIf="tipoAerovale === enumTipoAerovale.Alije" formControlName="aerovaleAlije" 
        [esValidaDensidad]="esValidaDensidad"
        [esDensidadMin]="esDensidadMin" 
        [esDensidadMax]="esDensidadMax"
        (envioDensidad)="validarDensidad($event)"
        (formularioValido)="onFormularioAlijeValido($event)"
        (objetAlijeForm)="obtenerObjectAlijeForm($event)">
      </aerovale-alije-form>

      <div class="row mt-2" *ngIf="true"><!--this.aerovaleCommonForm.clienteFormControl!=null-->
        <div class="mb-3 col-md-6">
          <app-direccion-entrega (provVal)="receiveMessage($event)" (ciuVal)="receiveMessage($event)" (ciudadId)="setCiudadAerovaleSeleccionada($event)" [clienteSeleccionado]="this.aerovaleCommonForm != null ? this.aerovaleCommonForm.clienteFormControl : null">
          </app-direccion-entrega>
        </div>              
      </div>
      
      <div class="row mt-5">
        <div class="mb-3 col-md-6">
          <div class="mb-3-row">
            <label class="form-label" for="firmaOperador">Aerovale Físico</label>
            <input (change)="onSelectedFileFirmaOperador($event.target.files)" id="firmaOperadorFormControl" type="file"
              class="form-control-file" name="firmaOperadorFormControl" formControlName="firmaOperadorFormControl"
              required />
            <br />
            <div *ngIf="procesandoFirmaOperador" class="d-flex align-items-center">
              <div class="spinner-border spinner-border-sm" role="status"></div>
              <span>&nbsp; &nbsp; Procesando imagen...</span>
            </div>
          </div>
        </div>
      </div>

      <div class="card-footer d-flex justify-content-center">
        <span class="border">
          <button class="btn btn-primary" [disabled]="guardandoAerovale" type="submit">
            <span *ngIf="!guardandoAerovale">Guardar</span>
            <div *ngIf="guardandoAerovale" class="d-flex align-items-center">
              <div class="spinner-border text-light spinner-border-sm" role="status"></div>
              <span>Guardando...</span>
            </div>
          </button>
        </span>
        <span class="border">
          <button type="button" class="btn btn-secondary" [disabled]="guardandoAerovale"
            (click)="cancelar()">Cancelar</button>
        </span>
      </div>
    </form>
  </div>
</div>
