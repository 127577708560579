import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { switchMap, tap } from 'rxjs/operators';
import { CondicionVenta } from 'src/app/shared/enums/condicionVenta';
import { TipoActivoPrecio } from 'src/app/shared/enums/tipoActivoPrecio';
import { TipoDesactivacion } from 'src/app/shared/enums/tipoDesactivacion';
import { TipoEstadoPrecio } from 'src/app/shared/enums/tipoEstadoPrecio';
import { PrecioDesactivacion } from 'src/app/shared/models/abm/PrecioDesactivacion';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { EstadoPrecio } from 'src/app/shared/models/precios/EstadoPrecio';
import { PrecioLote } from 'src/app/shared/models/precios/PrecioLote';
import { MessageService } from 'src/app/shell/message.service';
import { PreciosService } from '../precios.service';

@Component({
  selector: 'app-precios-desactivacion',
  templateUrl: './precios-desactivacion.component.html',
  styleUrls: ['./precios-desactivacion.component.css']
})
export class PreciosDesactivacionComponent implements OnInit {
  esperandoCarga: boolean = false;
  messagePrecios: string = "C a r g a n d o . . ."
  preciosFiltrados: PrecioDesactivacion[];
  listaPrecios: PrecioDesactivacion[];
  preciosEnviar: PrecioDesactivacion[] = [];
  enumCondicionVenta = CondicionVenta;
  enumTipoDesactivacion = TipoDesactivacion;
  enumTipoActivoPrecio = TipoActivoPrecio;
  actualizando: boolean = false;

  mensajeAccesoInsuficiente: string = null;

  searchForm = new UntypedFormGroup({
    busqueda: new UntypedFormControl(''),
  });

  constructor(
    private preciosService: PreciosService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private messageService: MessageService) {

  }

  // hooks

  ngOnInit() {
    this.getPrecios();
  }

  // llamadas al service

  usuarioPuedeAprobarDesactivacion() {

    var usuarioNiveles = this.preciosService.getNivelUsuarioLogueado();
    
    if(usuarioNiveles?.nivelNativo != null)
    {
      if (usuarioNiveles && usuarioNiveles.nivelNativo.puedeDesactivar) {
        return true;
      }
    }
    
    if(usuarioNiveles){
      for (let nivelDelegado of usuarioNiveles.nivelesDelegados) {
        if (nivelDelegado.puedeDesactivar) {
          return true;
        }
      }
    }    

    return false;
  }

  getPrecios() {
    if (!this.usuarioPuedeAprobarDesactivacion()) {
      this.spinner.hide('spinnerGrafico');
      this.esperandoCarga = false;
      this.mensajeAccesoInsuficiente = "No posee acceso para aprobar desactivación de precios.";
      return;
    }

    this.initPreciosRequest();

    this.preciosService.getPreciosAprobadosVigentes()
      .subscribe(result => {

        result = this.procesarResult(result);

        for (let i = 0; result.length > i; i++) {
          this.preciosFiltrados.push(result[i]);
          this.listaPrecios.push(result[i]);
        }

        this.spinner.hide('spinnerGrafico');
        this.esperandoCarga = false;
        this.filtrarPrecios();
      },
        (err: ErrorModel) => {
          this.listaPrecios = [];
          this.preciosEnviar = [];
          this.preciosFiltrados = [];
          this.esperandoCarga = false;
      });
  }

  // form

  desactivarPrecios() {
    if (this.preciosEnviar.length == 0) {
      this.messageService.showErrorMessage('No se seleccionó ningún precio para desactivar.');
    } else {
      var listaIdsSeleccionados = this.getCheckedItemList();

      var estado = new EstadoPrecio();
      estado.responsable = this.preciosService.obtenerStringResponsableV2();
      estado.estadoPrecio = TipoEstadoPrecio.Aprobado;
      estado.activo = TipoActivoPrecio.Inactivo;

      this.actualizando = true;

      this.preciosService.cambiarEstadoPrecio(estado, listaIdsSeleccionados)
        .subscribe(nivelAprobacion => {
          this.messageService.showSuccessLongMessage('Se proceso la solicitud de desactivación de los precios correctamente.');
          this.actualizando = false;
          this.getPrecios();
        },
          (err: ErrorModel) => {
            this.messageService.showErrorMessage('Hubo un problema al desactivar los precios, por favor intente nuevamente.')
          });
    }

  }

  rechazarDesactivarPrecios(){
    if (this.preciosEnviar.length == 0) {
      this.messageService.showErrorMessage('No se seleccionó ningún precio para rechazar desactivacion.');
    } else {
      var listaIdsSeleccionados = this.getCheckedItemList();

      var estado = new EstadoPrecio();
      estado.responsable = this.preciosService.obtenerStringResponsableV2();
      estado.estadoPrecio = TipoEstadoPrecio.Aprobado;
      estado.activo = TipoActivoPrecio.Activo;

      this.actualizando = true;

      this.preciosService.cambiarEstadoPrecio(estado, listaIdsSeleccionados)
        .subscribe(nivelAprobacion => {
          this.messageService.showSuccessLongMessage('Se proceso la solicitud de rechazar la desactivacion de los precios correctamente.');
          this.actualizando = false;
          this.getPrecios();
        },
          (err: ErrorModel) => {
            this.messageService.showErrorMessage('Hubo un problema al rechazar desactivacion de los precios, por favor intente nuevamente.')
          });
    }
  }

  getCheckedItemList() {
    var listaIdsSeleccionados = [];

    for (var i = 0; i < this.preciosEnviar.length; i++) {
      listaIdsSeleccionados.push(this.preciosEnviar[i].id);
    }

    return listaIdsSeleccionados;
  }

  onRefreshClick() {
    this.getPrecios();
  }

  initPreciosRequest() {
    this.mensajeAccesoInsuficiente = null;
    this.listaPrecios = [];
    this.preciosEnviar = [];
    this.preciosFiltrados = [];

    this.esperandoCarga = true;
    this.spinner.show('spinnerGrafico');
  }

  procesarResult(result: PrecioDesactivacion[]) {
    result.forEach(p => {
      if(p.aprobadoUserName && p.aprobadoUserName.indexOf(',') > -1) p.aprobadoUserName = JSON.parse(p.aprobadoUserName.replace(/'/g,'"')).nombresCompletos;

      if(p.importoUserName && p.importoUserName.indexOf(',') > -1) p.importoUserName = JSON.parse(p.importoUserName.replace(/'/g,'"')).nombresCompletos;
    });

    return result.filter(p => p.estadoPrecio.estadoPrecio == TipoEstadoPrecio.Aprobado && p.estadoPrecio.activo == TipoActivoPrecio.PendienteDesactivacion);
  }

  filtrarPrecios() {

    if (this.preciosFiltrados != null) {
      this.preciosFiltrados = this.listaPrecios.filter(a => {
        let agregar = true;
        if (this.busquedas.value) {

          if (a.cliente != null) {
            agregar = a.cliente.razonSocial && a.cliente.razonSocial.toLocaleLowerCase().indexOf(this.busquedas.value.toLocaleLowerCase()) > -1;
          } else {
            agregar = false;
          }

          agregar = agregar || (a.producto.nombreProducto && a.producto.nombreProducto.toLocaleLowerCase().indexOf(this.busquedas.value.toLocaleLowerCase()) > -1);
          agregar = agregar || (a.producto.codigoProducto && a.producto.codigoProducto.toLocaleLowerCase().indexOf(this.busquedas.value.toLocaleLowerCase()) > -1);
          agregar = agregar || (a.precioContado.toString() && a.precioContado.toString().toLocaleLowerCase().indexOf(this.busquedas.value.toLocaleLowerCase()) > -1);
          agregar = agregar || (a.numeroLote && ("l" + a.numeroLote).toString().toLocaleLowerCase().indexOf(this.busquedas.value.toLocaleLowerCase()) > -1);

        }

        if (this.busquedas.value == '') {
          this.preciosFiltrados = this.listaPrecios;
        }
        return agregar;
      });
    }
  }

  agregarPrecios(precioSeleccionado: PrecioDesactivacion) {
    if (!precioSeleccionado.seleccionadoUi) {
      precioSeleccionado.seleccionadoUi = true;
      this.preciosEnviar.push(precioSeleccionado);
    } else {
      const index: number = this.preciosEnviar.indexOf(precioSeleccionado);
      if (index !== -1) {
        this.preciosEnviar.splice(index, 1);
      }
      precioSeleccionado.seleccionadoUi = false;
    }

  }

  // getters
  get busquedas() {
    return this.searchForm.get('busqueda');
  }

}
