import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup,Validators } from '@angular/forms';
import { NgbActiveModal, NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Precio } from 'src/app/shared/models/abm/Precio';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { ActualizarFecha } from 'src/app/shared/models/precios/ActualizarFecha';
import { MessageService } from 'src/app/shell/message.service';
import { PreciosService } from '../../precios.service';

@Component({
  selector: 'app-modal-correccion',
  templateUrl: './modal-correccion.component.html',
  styleUrls: ['./modal-correccion.component.css']
})
export class ModalCorreccionComponent implements OnInit {

  @Input() precioSeleccionado: Precio;

  corrigiendoPrecio: boolean = false;

  modelDesde:NgbDateStruct;
  modelHasta:NgbDateStruct;

  modelHoraDesde:any;
  modelHoraHasta:any;

  error: string;

  correccionForm = new UntypedFormGroup({
    precioFormControl: new UntypedFormControl('', [Validators.required]),
     fechaDesdeFormControl: new UntypedFormControl(new Date()),
     horaDesdeFormControl: new UntypedFormControl(null, [Validators.required]),     
     fechaHastaFormControl: new UntypedFormControl(new Date()),
     horaHastaFormControl: new UntypedFormControl(null, [Validators.required]),
     detalleFormControl:new UntypedFormControl('', [Validators.required]),
  })
  constructor(public activeModal: NgbActiveModal, private precioService:PreciosService,
    private calendar: NgbCalendar,public datePipe:DatePipe,private messageService:MessageService) { }


  redondearSegundos(date){
    return Math.round(Number(date.getSeconds() + "." + date.getMilliseconds()));
  }

  // hooks

  ngOnInit() {
    let desde = new Date(this.precioSeleccionado.fechaDesde);
    let hasta = new Date(this.precioSeleccionado.fechaHasta);

    this.modelDesde = { day: desde.getDate(), month: desde.getMonth() + 1, year: desde.getFullYear() };
    this.modelHasta = { day: hasta.getDate(), month: hasta.getMonth() + 1, year: hasta.getFullYear() };
    
    this.modelHoraDesde = { hour: desde.getHours(), minute: desde.getMinutes(), second: this.redondearSegundos(desde) };
    this.modelHoraHasta = { hour: hasta.getHours(), minute: hasta.getMinutes(), second: this.redondearSegundos(hasta) };

    this.precioFormControl.setValue(this.precioSeleccionado.precioContado);
    //this.fechaDesdeFormControl.setValue(this.modelDesde)
    //this.fechaHastaFormControl.setValue(this.modelHasta)
  }

  // form

  formatDate(year, month, day, hour, minute, seconds): string {
    return year + '-' + month.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + '-' + day.toLocaleString(undefined, { minimumIntegerDigits: 2 })
      + 'T' + hour.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + minute.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + seconds.toLocaleString(undefined, { minimumIntegerDigits: 2 });
  }


  convertStringToModel(value: string): NgbDateStruct{
    if (!value)
     return null
    let parts=value.split('-');
    return {year:+parts[0],month:+parts[1],day:+parts[2].split('T')[0]}
 }

 // validaciones

 fechaDesdeMayorAHasta():boolean
 {
  let esMayor: boolean = false;
  let desde=new Date(this.modelDesde.year, this.modelDesde.month - 1, this.modelDesde.day);
  let hasta=new Date(this.modelHasta.year, this.modelHasta.month - 1, this.modelHasta.day);
  if (desde > hasta) { esMayor = true; console.log("la fecha desde mayor a hasta") }
  return esMayor;
 }

 // llamadas al service

  corregirPrecio() {
    this.error = null;

    if (!this.fechaDesdeMayorAHasta()) {

      this.corrigiendoPrecio = true;

      let datosAactualizar = new ActualizarFecha();
      datosAactualizar.fechaHora = this.formatDate(this.fechaDesdeFormControl.value.year, this.fechaDesdeFormControl.value.month, this.fechaDesdeFormControl.value.day, this.horaDesdeFormControl.value.hour, this.horaDesdeFormControl.value.minute, this.horaDesdeFormControl.value.second);
      datosAactualizar.fechaHoraHasta = this.formatDate(this.fechaHastaFormControl.value.year, this.fechaHastaFormControl.value.month, this.fechaHastaFormControl.value.day, this.horaHastaFormControl.value.hour, this.horaHastaFormControl.value.minute, this.horaHastaFormControl.value.second);
      datosAactualizar.precio = Number(this.precioFormControl.value);
      datosAactualizar.responsable = this.precioService.obtenerStringResponsableV2();
      datosAactualizar.detalle = this.detalleFormControl.value;

      this.precioService.actualizarPreciosPorId(this.precioSeleccionado.id, datosAactualizar)
        .subscribe(result => {
          this.corrigiendoPrecio = false;
          this.activeModal.dismiss(true);
          if (result != null) {
            this.messageService.showSuccessLongMessage("Se proceso la solicitud de corrección de precios correctamente");
          }
        }, (error: ErrorModel) => {
          this.corrigiendoPrecio = false;
          this.error = error.description;
        });
    }
    else {
      this.messageService.showErrorMessage("La fecha desde no debe ser mayor que la fecha hasta");
    }
  }

  // getters

  get precioFormControl() {
    return this.correccionForm.get('precioFormControl');
  }

  get fechaDesdeFormControl() {
    return this.correccionForm.get('fechaDesdeFormControl');
  }

  get fechaHastaFormControl() {
    return this.correccionForm.get('fechaHastaFormControl');
  }

  get detalleFormControl() {
    return this.correccionForm.get('detalleFormControl');
  }

  get horaDesdeFormControl() {
    return this.correccionForm.get('horaDesdeFormControl');
  }

  get horaHastaFormControl() {
    return this.correccionForm.get('horaHastaFormControl');
  }
}
