<div class="step-progress">
    <div *ngFor="let step of steps; let i = index" class="step">
      <div class="step-square d-flex justify-content-center align-items-center" [ngClass]="{'bg-success': step.completed, 'bg-primary': i === currentStepIndex}">
        {{ i + 1 }}
      </div>
      <div class="step-label mt-2 text-center">{{ step.label }}</div>
    </div>
    <div class="progress-bar-container">
      <div class="progress-bar" [style.width]="progressWidth"></div>
    </div>
</div>
  