import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Aeroplanta as AeroplantaDespacho } from 'src/app/shared/models/despacho/Aeroplanta';
import { Aeroplanta } from 'src/app/shared/models/cierreTurno/Aeroplanta';
import { TipoAeroplanta } from 'src/app/shared/enums/TipoAeroplanta';
import { UntypedFormArray, UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ProductoService } from 'src/app/abm/producto/producto.service';
import { Producto } from 'src/app/shared/models/abm/Producto';
import { DetalleProducto } from 'src/app/shared/models/cierreTurno/DetalleProducto';
import { StockService } from 'src/app/stock/stock.service';
import { Terminal } from 'src/app/shared/models/stock/Terminal';
import { Contacto } from 'src/app/shared/models/cierreTurno/Contacto';
import { CierreTurnoService } from 'src/app/cierreTurno/cierre-turno.service';
import { MessageService } from 'src/app/shell/message.service';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { ModalContactosComponent } from '../../modal-contactos/modal-contactos.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { disableDebugTools } from '@angular/platform-browser';
import { UsuarioService } from 'src/app/account/usuario.service';
import { UsuarioDTO } from 'src/app/shared/models/notificaciones/UsuarioDTO';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';

export enum TipoResponsable {
  _firmaOperadora,
  _referenteComercial,
  _referenteGestion,
  _referenteTecnico,
  _ruteador
}
@Component({
  selector: 'app-aeroplantas-detalle',
  templateUrl: './aeroplantas-detalle.component.html',
  styleUrls: ['./aeroplantas-detalle.component.scss']
})
export class AeroplantasDetalleComponent implements OnInit {

  aeroplantaForm: UntypedFormGroup;
  aeroplantas;
  aeroplanta: Aeroplanta;
  cargandoDetalle: boolean = false;
  cargandoTerminales: boolean = false;
  cargandoProductos: boolean = false;
  cargandoContactos: boolean = false;
  cargandoResponsables: boolean = false;
  guardando: boolean = false;
  productos: Producto[];
  TipoAeroplanta=TipoAeroplanta;
  TipoUsuario = TipoUsuario;
  @Input() aeroplantaSeleccionada: AeroplantaDespacho;
  terminales: Terminal[] = [];
  detalleProductos: DetalleProducto[];
  detalleProductosSeleccionados: Producto[] = [];
  contactos: Contacto[] = [];
  usuariosResponsableProducto: UsuarioDTO[] = [];

  TipoResponsable=TipoResponsable;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private modal:NgbModal,
    private cierreTurnoService: CierreTurnoService,
    private productoService: ProductoService,
    private stockService: StockService,
    private messageService: MessageService,
    private spinner:NgxSpinnerService,
    private usuarioService: UsuarioService,
    private authService: AuthService) { }

  ngOnInit(): void {    
    this.crearFormulario();
    this.inicializarDatos();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.aeroplantaSeleccionada.firstChange) {
      this.cargarFormulario();
      this.usuariosResponsableProducto = [];
    }
  }

  mostrarSpinner() {
    this.spinner.show('spinnerDetalle');
    this.cargandoDetalle = true;
  }
  
  ocultarSpinner() {    
    this.spinner.hide('spinnerDetalle');
    this.cargandoDetalle = false;
  }
  
  crearFormulario() {
    this.aeroplantaForm = this.formBuilder.group({
      tipoAeroplantaFormControl: ['', Validators.required],
      productoFormControl: [''],
      detalleProductosFormControlArray: this.formBuilder.array([]),
      firmaOperadorFormControl: ['', Validators.required],
      referenteTecnicoFormControl: ['', Validators.required],
      referenteGestionFormControl: ['', Validators.required],
      referenteComercialFormControl: ['', Validators.required],
      ruteadorFormControl: ['', Validators.required],
      horarioOperacionFormControl: [''],
      responsableFormControl: [''],
      suplenteFormControl: ['']
    });
  }
  
  inicializarDatos = async () => {
    this.mostrarSpinner();
    this.terminales = await this.stockService.getTerminalesPromise();
    this.terminales.sort((a, b) => a.nombre > b.nombre ? 1 : -1);
    this.contactos = await this.cierreTurnoService.getContactos();
    this.contactos.sort((a, b) => a.nombre > b.nombre ? 1 : -1);
    this.cargarAeroplantas();
    this.getUsuariosResponsableProducto();
    this.cargarFormulario();
    
  }

  getUsuariosResponsableProducto = async () => {
    this.cargandoResponsables = true
    let usuarios = await this.usuarioService.obtenerUsuariosConRoles([24, 11], this.aeroplantaSeleccionada.codigoAeroplanta);
    this.usuariosResponsableProducto = usuarios.map(usuario => {
      return {
        nombresCompletos: usuario.nombresCompletos,
        dni: usuario.dni,
        userName: usuario.userName
      }
    })
    this.aeroplanta.responsable ? this.responsableFormControl.setValue(this.usuariosResponsableProducto.find(r => r.dni == this.aeroplanta.responsable.dni)) : this.responsableFormControl.setValue(this.usuariosResponsableProducto[0]);
    this.aeroplanta.sustituto ? this.suplenteFormControl.setValue(this.usuariosResponsableProducto.find(s => s.dni == this.aeroplanta.sustituto.dni)) : this.suplenteFormControl.setValue(this.usuariosResponsableProducto[0]);
    this.cargandoResponsables = false;
  }

  cargarAeroplantas() {
    this.stockService.getAeroplantas(null).subscribe(aeroplantas => {
      this.aeroplantas = aeroplantas;
      let terminalesConCodigo = this.terminales.map(terminal => {
        let aeroplanta = this.aeroplantas.find(aeroplanta => aeroplanta.numeroAeroplanta == terminal.codigoTerminal);
        if (aeroplanta) terminal.codigoLetras = aeroplanta.codigoAeroplanta;
        else terminal.codigoLetras = terminal.codigoTerminal;
        return terminal;
      })
      this.terminales = terminalesConCodigo;
    });
  }

  cargarFormulario = async () => {
    try {      
      this.mostrarSpinner();
      const resp = await this.productoService.obtenerProductosPromise(); // reset array productos...
      this.getUsuariosResponsableProducto();
      this.productos = resp.filter(p=>p.rubro.aerocombustible || p.rubro.combustible).filter(p=>p.activo);
      this.productos.sort((a,b) => a.nombreProducto > b.nombreProducto ? 1 : -1);
      this.aeroplanta = await this.cierreTurnoService.getAeroplantas(this.aeroplantaSeleccionada.codigoAeroplanta);
      if (this.aeroplanta != null) {
        this.tipoAeroplantaFormControl.setValue(this.aeroplanta.tipoAeroplanta);
        this.firmaOperadorFormControl.setValue(this.aeroplanta.firmaOperadora);
        this.referenteTecnicoFormControl.setValue(this.aeroplanta.referenteTecnico);
        this.referenteGestionFormControl.setValue(this.aeroplanta.referenteGestion);
        this.referenteComercialFormControl.setValue(this.aeroplanta.referenteComercial);
        this.ruteadorFormControl.setValue(this.aeroplanta.ruteador);
        this.horarioOperacionFormControl.setValue(this.aeroplanta.horarioOperacion);
        this.detalleProductosSeleccionados = [];
        this.detalleProductosFormControlArray.clear();
        for (const item of this.aeroplanta.productos) {
          let prod = this.productos.find(p=>p.codigoProducto == item.codigoProducto);
          this.detalleProductosSeleccionados.push(prod);
          this.detalleProductosFormControlArray.push(this.cargarDetalleProducto(item));
          let p = this.productos.indexOf(prod);
          this.productos.splice(p, 1);
    
          if (this.productos.length > 0) {
            this.productoFormControl.setValue(this.productos[0]);
          } else {
            this.productoFormControl.setValue(null);
          }          
        }
        this.ocultarSpinner();
      }
    } catch (error) {
      this.messageService.showErrorMessage('Hubo un error al obtener los datos de la aeroplanta.');
      this.aeroplanta = null;
      this.ocultarSpinner();
    }
  }

  agregarProductoSeleccionada() {
    if (this.productoFormControl.value) {
      this.detalleProductosSeleccionados.push(this.productoFormControl.value);
      this.detalleProductosFormControlArray.push(this.nuevoDetalleProducto());
      let p = this.productos.indexOf(this.productoFormControl.value);
      this.productos.splice(p, 1);

      if (this.productos.length > 0) {
        this.productoFormControl.setValue(this.productos[0]);
      } else {
        this.productoFormControl.setValue(null);
      }
    }
  }

  nuevoDetalleProducto(): UntypedFormGroup {
    return this.formBuilder.group({
      codigoTerminal: ['', Validators.required],
      codigoProducto: [this.productoFormControl.value.codigoProducto, Validators.required],
      distanciaEnKM: ['', Validators.required],
      toleranciaMensualOperativa: ['', Validators.required],
      diasPromedio: ['', Validators.required],
      diasDemora: ['', Validators.required],
      stockSanitario: ['', Validators.required]
    });
  }

  depurarCodigoTerminal(str:string) {
    let posInicial = str.indexOf('(');
    let posFinal = str.indexOf(')');
    if(posInicial && posFinal == -1) {
      return str
    } else {
      return str.substring(posInicial+1, posFinal)
    }
  }

  cargarDetalleProducto(x:DetalleProducto): UntypedFormGroup {
    return this.formBuilder.group({
      codigoTerminal: [this.depurarCodigoTerminal(x.codigoTerminal), Validators.required],
      codigoProducto: [x.codigoProducto, Validators.required],
      distanciaEnKM: [x.distanciaEnKM, Validators.required],
      toleranciaMensualOperativa: [x.toleranciaMensualOperativa, [Validators.required, Validators.min(0), Validators.max(99.99)]],
      diasPromedio: [x.diasPromedio, Validators.required],
      diasDemora: [x.diasDemora, Validators.required],
      stockSanitario: [x.stockSanitario, Validators.required]
    });
  }

  eliminarDetalle(i:number) {
    let codigo = this.detalleProductosFormControlArray.value[i].codigoProducto;
    let dp = this.detalleProductosSeleccionados.filter(p=>p.codigoProducto == codigo);
    //this.productos.push(dp[0]);
    this.detalleProductosFormControlArray.removeAt(i);
    let p = this.detalleProductosSeleccionados.indexOf(dp[0]);
    this.detalleProductosSeleccionados.splice(p, 1);
    if (this.productos.length > 0) {
      this.productoFormControl.setValue(this.productos[0]);
    }
  }

  obtenerNombreTerminal(cod) {
    return this.terminales.find(t => t.codigoTerminal===cod).nombre;
  }

  procesarDetalleProductos() {
    let listaDetalleProductos = new Array<DetalleProducto>();

    if (this.aeroplantaForm != null) {
      for (let i = 0; i < this.detalleProductosFormControlArray.length; i++) {
        let terminal = '('+this.detalleProductosFormControlArray.value[i].codigoTerminal+') ' + this.obtenerNombreTerminal(this.detalleProductosFormControlArray.value[i].codigoTerminal);
        let producto = this.detalleProductosFormControlArray.value[i].codigoProducto;        
        let distancia = this.detalleProductosFormControlArray.value[i].distanciaEnKM;
        let tolerancia = this.detalleProductosFormControlArray.value[i].toleranciaMensualOperativa;
        let dias = this.detalleProductosFormControlArray.value[i].diasPromedio;
        let demora = this.detalleProductosFormControlArray.value[i].diasDemora;
        let stock = this.detalleProductosFormControlArray.value[i].stockSanitario;

        let detalle = new DetalleProducto();
        detalle.codigoTerminal = terminal;
        detalle.codigoProducto = producto;
        detalle.distanciaEnKM = distancia;
        detalle.toleranciaMensualOperativa = tolerancia;
        detalle.diasPromedio = dias;
        detalle.diasDemora = demora;
        detalle.stockSanitario = stock;
        
        listaDetalleProductos.push(detalle);
      }
    }

    return listaDetalleProductos;
  }

  compareContactos(n1: any, n2: any): boolean {  
    return n1 && n2 ? n1.id == n2.id : n1 == n2;
  }

  compareTerminal(n1: any, n2: any): boolean {  
    return n1 && n2 ? n1.codigoTerminal == n2 : n1 == n2;
  }

  accionarForm(){
    Object.keys(this.aeroplantaForm.controls).forEach(key => {
      this.aeroplantaForm.get(key).markAsDirty();
    });
  }
  
  onChangeDistancia(index:number) {
    let distancia = Number(this.detalleProductosFormControlArray.value[index].distanciaEnKM);
    this.detalleProductosFormControlArray.controls[index].get('diasDemora').setValue((distancia>0) ? Math.ceil(distancia/600)+1 : 0);
    this.detalleProductosFormControlArray.controls[index].get('distanciaEnKM').setValue(distancia);
  }

  /** boton guardar cambios */

  onClick = async () => {
    this.accionarForm();

    if(this.aeroplantaForm.valid) {
      this.guardando = true;

      let aeroplanta = new Aeroplanta();
      aeroplanta.codigo = this.aeroplantaSeleccionada.codigoAeroplanta;
      aeroplanta.tipoAeroplanta = Number(this.tipoAeroplantaFormControl.value);
      aeroplanta.productos = this.procesarDetalleProductos();
      aeroplanta.firmaOperadora = this.firmaOperadorFormControl.value;
      aeroplanta.referenteComercial = this.referenteComercialFormControl.value;
      aeroplanta.referenteGestion = this.referenteGestionFormControl.value;
      aeroplanta.referenteTecnico = this.referenteTecnicoFormControl.value;
      aeroplanta.ruteador = this.ruteadorFormControl.value;
      aeroplanta.horarioOperacion = this.horarioOperacionFormControl.value;
      aeroplanta.responsable = this.responsableFormControl.value;
      aeroplanta.sustituto = this.suplenteFormControl.value;

      try {
        this.editarAeroplanta(aeroplanta)
          .then(_=>{
            this.messageService.showSuccessMessage(`La aeroplanta fue editada correctamente.`);
            this.guardando = false;
          }).catch((err:ErrorModel)=>{
            this.messageService.showErrorMessage(err.message);
            this.guardando = false;
          });
      } catch (error) {
        this.guardando = false;
      }
    }

  }

  editarAeroplanta(aero) {
    const e = new Promise((resolve, reject)=>{
      const resp = this.cierreTurnoService.putAeroplantas(aero);
      if (typeof(resp) === 'object') {
        resolve(resp);
      }else {
        reject('Hubo un error al editar la aeroplanta.');
      }
    });
    return e;
  }

  abmContacto(contacto:Contacto, tipo:TipoResponsable) {
    let modalRef = this.modal.open(ModalContactosComponent, { centered: true, backdrop: 'static' });
    modalRef.componentInstance.contacto = contacto;
    modalRef.result.then(contacto => {
      if(contacto!=false) {
        if(contacto._editando==false){
          this.contactos.push(contacto);
          // seteando el nuevo
        } else {
          let index = this.contactos.findIndex(c=>c.id == contacto.id);
          if (index!=-1) {
            this.contactos[index].nombre = contacto.nombre;
            this.contactos[index].email = contacto.email;
            this.contactos[index].numero = contacto.numero;
            
            switch (tipo) {
              case TipoResponsable._firmaOperadora:
                if(this.referenteComercialFormControl.value === this.firmaOperadorFormControl.value) this.referenteComercialFormControl.setValue(contacto);
                if(this.referenteGestionFormControl.value === this.firmaOperadorFormControl.value) this.referenteGestionFormControl.setValue(contacto);
                if(this.referenteTecnicoFormControl.value === this.firmaOperadorFormControl.value) this.referenteTecnicoFormControl.setValue(contacto);
                if(this.ruteadorFormControl.value === this.firmaOperadorFormControl.value) this.ruteadorFormControl.setValue(contacto);
                this.firmaOperadorFormControl.setValue(contacto); 
                break;
              case TipoResponsable._referenteComercial:
                if(this.firmaOperadorFormControl.value.id === this.referenteComercialFormControl.value.id) this.firmaOperadorFormControl.setValue(contacto);
                if(this.referenteGestionFormControl.value.id === this.referenteComercialFormControl.value.id) this.referenteGestionFormControl.setValue(contacto);
                if(this.referenteTecnicoFormControl.value.id === this.referenteComercialFormControl.value.id) this.referenteTecnicoFormControl.setValue(contacto);
                if(this.ruteadorFormControl.value.id === this.referenteComercialFormControl.value.id) this.ruteadorFormControl.setValue(contacto);
                this.referenteComercialFormControl.setValue(contacto);
                break;
              case TipoResponsable._referenteGestion:
                if(this.firmaOperadorFormControl.value.id === this.referenteGestionFormControl.value.id) this.firmaOperadorFormControl.setValue(contacto);
                if(this.referenteComercialFormControl.value.id === this.referenteGestionFormControl.value.id) this.referenteComercialFormControl.setValue(contacto);
                if(this.referenteTecnicoFormControl.value.id === this.referenteGestionFormControl.value.id) this.referenteTecnicoFormControl.setValue(contacto);
                if(this.ruteadorFormControl.value.ide === this.referenteGestionFormControl.value.id) this.ruteadorFormControl.setValue(contacto);
                this.referenteGestionFormControl.setValue(contacto);
                break;
              case TipoResponsable._referenteTecnico:
                if(this.firmaOperadorFormControl.value.id === this.referenteTecnicoFormControl.value.id) this.firmaOperadorFormControl.setValue(contacto);
                if(this.referenteComercialFormControl.value.id === this.referenteTecnicoFormControl.value.id) this.referenteComercialFormControl.setValue(contacto);
                if(this.referenteGestionFormControl.value.id === this.referenteTecnicoFormControl.value.id) this.referenteGestionFormControl.setValue(contacto);
                if(this.ruteadorFormControl.value.id === this.referenteTecnicoFormControl.value.id) this.ruteadorFormControl.setValue(contacto);
                this.referenteTecnicoFormControl.setValue(contacto);
                break;
              case TipoResponsable._ruteador:
                if(this.firmaOperadorFormControl.value.id === this.ruteadorFormControl.value.id) this.firmaOperadorFormControl.setValue(contacto);
                if(this.referenteComercialFormControl.value.id === this.ruteadorFormControl.value.id) this.referenteComercialFormControl.setValue(contacto);
                if(this.referenteGestionFormControl.value.id === this.ruteadorFormControl.value.id) this.referenteGestionFormControl.setValue(contacto);
                if(this.referenteTecnicoFormControl.value.id === this.ruteadorFormControl.value.id) this.referenteTecnicoFormControl.setValue(contacto);
                this.ruteadorFormControl.setValue(contacto);
                break;
              default:
                break;
            }
            
            this.referenteTecnicoFormControl.setValue;
            this.referenteGestionFormControl.setValue;
            this.referenteComercialFormControl;
            this.ruteadorFormControl;
          }        
        }
      }    
    }, () => {
      // error
    });
  }

  get tipoAeroplantaFormControl () {
    return this.aeroplantaForm.controls.tipoAeroplantaFormControl;
  }

  get productoFormControl() {
    return this.aeroplantaForm.controls.productoFormControl;
  }
  
  get detalleProductosFormControlArray(): UntypedFormArray {
    return this.aeroplantaForm.controls.detalleProductosFormControlArray as UntypedFormArray;
  }

  get firmaOperadorFormControl() {
    return this.aeroplantaForm.controls.firmaOperadorFormControl;
  }
  
  get referenteTecnicoFormControl() {
    return this.aeroplantaForm.controls.referenteTecnicoFormControl;
  }
  
  get referenteGestionFormControl() {
    return this.aeroplantaForm.controls.referenteGestionFormControl;
  }
  
  get referenteComercialFormControl() {
    return this.aeroplantaForm.controls.referenteComercialFormControl;
  }

  get ruteadorFormControl() {
    return this.aeroplantaForm.controls.ruteadorFormControl;
  }

  get horarioOperacionFormControl() {
    return this.aeroplantaForm.controls.horarioOperacionFormControl;
  }

  get responsableFormControl() {
    return this.aeroplantaForm.controls.responsableFormControl;
  }
  
  get suplenteFormControl() {
    return this.aeroplantaForm.controls.suplenteFormControl;
  }
}
