<!-- <ngx-spinner name="spinnerLista" bdOpacity=0.3bd Color="rgba(193,193,193,0)" size="medium" color="#185db5"
  [ngStyle]="{'height': this.comprobante? '130px': '0px', 'z-index':'0'}" type="ball-grid-beat"
  [fullScreen]="false">
  <p style="color: white"> Obteniendo Comprobante . . .
</ngx-spinner> -->


<div class="card list-group facturaf">
  <ngx-spinner name="spinnerComprobante" [ngStyle]="{'height': '400px', 'z-index':'0'}"
    [ngClass]="{'list-group-item list-group-item-action': obteniendoComprobante}" bdOpacity=0.3bd
    Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
    <p style="color: white"> O b t e n i e n d o C O M P R O B A N T E . . . </p>
  </ngx-spinner>

  <div id="invoicingPrint" *ngIf="this.comprobante != null && this.comprobante.remitoCargamento && comp == 1">
    <div class="card-body" id="facturaPrint">
      <div class="container">
        <div class="row justify-content-md-center">
          <div class="col-md-auto border text-center line-small mb-1">
            <p class="h3">X</p>
            <small></small>
          </div>
        </div>
        <div class="justify-content-md-center d-flex">
          <div id="emisorData" class="col border me-1">
            <ul class="list-unstyled js">
              <li class="letra">
                <img id="logo-ypf" src="/assets/images/logoypf.png" />
              </li>
              <li class="letra"><b>BV. MACACHA GOMEZ - CAPITAL FEDERAL (1001)</b></li>
              <li class="letra"></li>
              <li class="letra"><b>T.E.: 0800-1222671</b></li>
              <li class="letra"><b>YPFAVIACION@YPF.COM</b></li>
            </ul>
          </div>
          <div id="comprobanteInfo" class="col border ms-1">
            <ul class="list-unstyled js">
              <li class="text-start"><b class="mpalabra">RECIBO CARGAMENTO</b>
                <b class="pull-right mpalabra">Nro.
                  {{CompletarPrefijo(comprobante.remitoCargamento.reciboCargamento.prefijo)}}-{{CompletarNumero(comprobante.remitoCargamento.reciboCargamento.numeroRecibo)}}</b>
              </li>
              <li class="text-start ms-1 letra"><b>FECHA:</b> {{this.movimiento.fechaHora | date: 'dd/MM/yyy'}} <span
                  class="pull-right"><b>HORA:</b> {{this.movimiento.fechaHora | date: 'HH:mm a'}} Hs.</span></li>
              <li class="text-end ms-1 letra"><b>CUIT: 20-95455466-0</b></li>
              <li class="text-end ms-1 letra"><b>IIBB CM 901-915025-3</b></li>
              <li class="text-end ms-1 letra"><b>INICIO ACTIVIDADES:</b> 01/01/91</li>
              <li class="text-end ms-1 letra"><b>RESPONSABLE INSCRIPTO</b></li>
            </ul>
          </div>
        </div>
        <div class="border justify-content-md-center mb-1 mt-2" id="aeroplantaInfo">
          <div class="letra text-center">
            <b> Aeroplanta:</b> {{this.aeroplantaActual.nombre}} - ({{this.aeroplantaActual.codigoAeroplanta}})            
          </div>
        </div>
        <div id="compradorInfo" class="justify-content-md-start border mt-2">
          <ul class="list-unstyled js mb-2">
            <li class="text-start letra"><b>SRES Terminal:</b> {{this.comprobante.remitoCargamento.terminal.nombre}} ({{this.comprobante.remitoCargamento.terminal.codigoTerminal}})</li>
            <li class="text-start letra"><b>Dirección:</b> {{this.comprobante.remitoCargamento.terminal.direccion}}</li>
          </ul>
        </div>
        <div *ngIf="this.comprobante.remitoCargamento.reciboCargamento" id="transportista"
          class="justify-content-md-start border mt-2">
          <ul class="list-unstyled js" *ngIf="this.comprobante.remitoCargamento.reciboCargamento.transportista != null">
            <li class="letra mt-1" style="column-count: 3;">
              <p class="text-start"><b>TRANSPORTISTA:</b>
                {{this.comprobante.remitoCargamento.reciboCargamento.transportista.nombre}}</p>
              <p class="text-center"><b>DIRECCIÓN:</b>
                {{this.comprobante.remitoCargamento.reciboCargamento.transportista.domicilio}}</p>
              <p class="text-end"><b>CUIT:</b>
                {{this.comprobante.remitoCargamento.reciboCargamento.transportista.cuit}}</p>
            </li>
            <li class="letra" style="column-count: 3;">
              <p class="text-start"><b>CONDUCTOR:</b>
                {{this.comprobante.remitoCargamento.reciboCargamento.conductor.nombre}}</p>
              <p class="text-center"><b>DNI CONDUCTOR:</b>
                {{this.comprobante.remitoCargamento.reciboCargamento.conductor.documento}}</p>
              <p class="text-end"><b>PRECINTO DESDE:</b>
                {{comprobante.remitoCargamento.reciboCargamento.precintoDesde}}</p>
            </li>
            <li class="letra" style="column-count: 3;">
              <p class="text-start"><b>PATENTE CAMION:</b> {{comprobante.remitoCargamento.reciboCargamento.patente}}</p>
              <p class="text-center"><b>CAPACIDAD CAMION:</b>
                {{comprobante.remitoCargamento.reciboCargamento.capacidadCamion}}</p>
              <p class="text-end"><b>PRECINTO HASTA:</b>
                {{comprobante.remitoCargamento.reciboCargamento.precintoHasta}}</p>
            </li>
          </ul>
        </div>
        <div id="ProductoRecibido" class="justify-content-md-start border mt-2">
          <ul class="list-unstyled js mb-2" *ngFor="let prod of this.comprobante.detalles; let i = index">
            <li class="text-start mb-1 mt-1 letra"><b>PRODUCTO RECIBIDO</b></li>
            <li class="letra" style="column-count: 3;">
              <p class="text-start"><b>CÓDIGO:</b> {{this.comprobante.detalles[i].codigoProducto}}</p>
              <p class="text-center"><b>DESCRIPCIÓN:</b> {{this.comprobante.detalles[i].nombreProducto}}</p>
              <p class="text-end"><b>NRO CERTIF. ANALISIS:</b>
                {{this.comprobante.remitoCargamento.numeroCertificadoAnalisis}}</p>
            </li>
            <li class="letra mb-2 " style="column-count: 3;"
              *ngIf="this.comprobante.detalles[i].detalleCombustible != null">
              <p class="text-start"><b>DENSIDAD:</b> {{this.comprobante.detalles[i].detalleCombustible.densidad | number:
                '0.3'}} Gr/cm</p>
              <p class="text-center"><b>TEMPERATURA:</b> {{this.comprobante.detalles[i].detalleCombustible.temperatura |
                number: '0.2'}}°C</p>
            </li>
            <li class="letra" style="column-count: 3;" *ngIf="this.comprobante.detalles[i].detalleCombustible != null">
              <p class="text-start"><b>CANTIDAD:</b> {{this.comprobante.detalles[i].cantidad | number : '0.0-0'}} Lts.</p>
              <p class="text-center"><b>CANTIDAD 15º:</b> {{this.comprobante.detalles[i].detalleCombustible.cantidad15 | number : '0.0-0'}} Lts.</p>
            </li>
          </ul>
        </div>

        <div id="comentario" class="justify-content-md-start border mt-2">
          <ul class="list-unstyled js mb-2 mt-1">
            <li class="text-start letra"><b>Comentario:</b></li>
            <li class="text-start letra">{{this.comprobante.remitoCargamento.comentario}}</li>
          </ul>
        </div>

        <div id="recibioConforme" class="justify-content-md-center d-flex border mt-2">
          <b class="text-center letraImpuesto">Recibi conforme los productos y/o servicios detallados en el presente
            documento y
            original del mismo.</b>
        </div>
        <div id="firmas" *ngIf="this.comprobante.remitoCargamento.reciboCargamento"
          class="justify-content-md-center d-flex border mt-2">
          <div class="d-flex justify-content-between">
            <img class="flex-fill w-50 img-fluid print-resize" [src]="this.ImagenFirmaOperador()" />
            <img class="flex-fill w-50 img-fluid print-resize" [src]="this.ImagenFirmaConductor()" />
          </div>
        </div>
      </div>
    </div>
    <button *ngIf="comprobante.id != null" styleSheetFile="assets/css/print.css,assets/css/bootstrap.min.css"
      printTitle="" printSectionId="facturaPrint" [useExistingCss]="true"
      class="btn btn-sm btn-outline-secondary mb-2 ms-2" ngxPrint>
      <i class="fa fa-print" aria-hidden="true"></i> Imprimir </button>
  </div>

  <div id="invoicingPrint" *ngIf="this.comprobante != null && this.comprobante.remitoAeroespecialidad && comp == 1">
    <div class="card-body" id="facturaPrint">
      <div class="container">
        <div class="row justify-content-md-center">
          <div class="col-md-auto border text-center line-small mb-1">
            <p class="h3">X</p>
            <small></small>
          </div>
        </div>
        <div class="justify-content-md-center d-flex">
          <div id="emisorData" class="col border me-1">
            <ul class="list-unstyled js">
              <li class="letra">
                <img id="logo-ypf" src="/assets/images/logoypf.png" />
              </li>
              <li class="letra"><b>BV. MACACHA GOMEZ - CAPITAL FEDERAL (1001)</b></li>
              <li class="letra"></li>
              <li class="letra"><b>T.E.: 0800-1222671</b></li>
              <li class="letra"><b>YPFAVIACION@YPF.COM</b></li>
            </ul>
          </div>
          <div id="comprobanteInfo" class="col border ms-1">
            <ul class="list-unstyled js">
              <li class="text-start"><b class="mpalabra">RECIBO CARGAMENTO</b>
                <b class="pull-right mpalabra">Nro.
                  {{CompletarPrefijo(comprobante.remitoAeroespecialidad.reciboCargamento.prefijo)}}-{{CompletarNumero(comprobante.remitoAeroespecialidad.reciboCargamento.numeroRecibo)}}</b>
              </li>
              <li class="text-start ms-1 letra"><b>FECHA:</b> {{this.movimiento.fechaHora | date: 'dd/MM/yyy'}} <span
                  class="pull-right"><b>HORA:</b> {{this.movimiento.fechaHora | date: 'mm:mm'}} Hs.</span></li>
              <li class="text-end ms-1 letra"><b>CUIT: 20-95455466-0</b></li>
              <li class="text-end ms-1 letra"><b>IIBB CM 901-915025-3</b></li>
              <li class="text-end ms-1 letra"><b>INICIO ACTIVIDADES:</b> 01/01/91</li>
              <li class="text-end ms-1 letra"><b>RESPONSABLE INSCRIPTO</b></li>
            </ul>
          </div>
        </div>
        <div class="border justify-content-md-center mb-1 mt-2" id="aeroplantaInfo">
          <div class="letra text-center">
            <b>Aeroplanta:</b> {{this.aeroplantaActual.nombre}} - ({{this.aeroplantaActual.codigoAeroplanta}})            
          </div>
        </div>
        <div id="compradorInfo" class="justify-content-md-start border mt-2">
          <ul class="list-unstyled js mb-2">
            <li class="text-start letra">
              <b>SRES Terminal:</b> {{this.comprobante.remitoAeroespecialidad.terminal.nombre}} ({{this.comprobante.remitoAeroespecialidad.terminal.codigoTerminal}})
            </li>
            <li class="text-start letra">
              <b>Dirección:</b> {{this.comprobante.remitoAeroespecialidad.terminal.direccion}}
            </li>
          </ul>
        </div>
        
        <div id="ProductoRecibido" class="justify-content-md-start border mt-2">
          <ul class="list-unstyled js mb-2" *ngFor="let prod of this.comprobante.detalles; let i = index">
            <li class="text-start mb-1 mt-1 letra"><b>PRODUCTO RECIBIDO</b></li>
            <li class="letra" style="column-count: 3;">
              <p class="text-start"><b>CÓDIGO:</b> {{this.comprobante.detalles[i].codigoProducto}}</p>
              <p class="text-center"><b>DESCRIPCIÓN:</b> {{this.comprobante.detalles[i].nombreProducto}}</p>
              <p class="text-end"><b>CANTIDAD:</b> {{this.comprobante.detalles[i].cantidad | number : '0.0-0'}} U.</p>              
            </li>
          </ul>
        </div>

        <div id="comentario" class="justify-content-md-start border mt-2">
          <ul class="list-unstyled js mb-2 mt-1">
            <li class="text-start letra"><b>Comentario:</b></li>
            <li class="text-start letra">{{this.comprobante.remitoAeroespecialidad.comentario}}</li>
          </ul>
        </div>

        <div id="recibioConforme" class="justify-content-md-center d-flex border mt-2">
          <b class="text-center letraImpuesto">Recibi conforme los productos y/o servicios detallados en el presente
            documento y
            original del mismo.</b>
        </div>
        <div id="firmas" *ngIf="this.comprobante.remitoAeroespecialidad.reciboCargamento"
          class="justify-content-md-center d-flex border mt-2">
          <div class="d-flex justify-content-between">
            <img class="flex-fill w-50 img-fluid print-resize" [src]="this.ImagenFirmaOperador()" />
            <img class="flex-fill w-50 img-fluid print-resize" [src]="this.ImagenFirmaConductor()" />
          </div>
        </div>
      </div>
    </div>
    <button *ngIf="comprobante.id != null" styleSheetFile="assets/css/print.css,assets/css/bootstrap.min.css"
      printTitle="" printSectionId="facturaPrint" [useExistingCss]="true"
      class="btn btn-sm btn-outline-secondary mb-2 ms-2" ngxPrint>
      <i class="fa fa-print" aria-hidden="true"></i> Imprimir </button>
  </div>

  <div id="invoicingPrint" *ngIf="this.comprobante != null && this.comprobante.remitoCargamento && comp == 0">
    <div class="card-body" id="facturaPrint">
      <div class="container">
        <div class="row justify-content-md-center">
          <div class="col-md-auto border text-center line-small mb-1">
            <p class="h3">R</p>
            <small>Cod. 91</small>
          </div>
        </div>
        <div class="justify-content-md-center d-flex">
          <div id="emisorData" class="col border me-1">
            <ul class="list-unstyled js">
              <li class="letra">
                <img id="logo-ypf" src="/assets/images/logoypf.png" />
              </li>
              <li class="letra"><b>BV. MACACHA GOMEZ - CAPITAL FEDERAL (1001)</b></li>
              <li class="letra"></li>
              <li class="letra"><b>T.E.: 0800-1222671</b></li>
              <li class="letra"><b>YPFAVIACION@YPF.COM</b></li>
            </ul>
          </div>
          <div id="comprobanteInfo" class="col border ms-1">
            <ul class="list-unstyled js">
              <li class="text-start"><b class="mpalabra">REMITO CARGAMENTO</b>
                <b class="pull-right mpalabra">Nro.
                  {{CompletarPrefijo(comprobante.prefijo)}}-{{CompletarNumero(comprobante.numeroRemito)}}</b>
              </li>
              <li class="text-start ms-1 letra"><b>FECHA:</b> {{this.movimiento.fechaHora | date: 'dd/MM/yyy'}} <span
                  class="pull-right"><b>HORA:</b> {{this.movimiento.fechaHora | date: 'HH:mm'}} Hs.</span></li>
              <li class="text-end ms-1 letra"><b>CUIT: 20-95455466-0</b></li>
              <li class="text-end ms-1 letra"><b>IIBB CM 901-915025-3</b></li>
              <li class="text-end ms-1 letra"><b>INICIO ACTIVIDADES:</b> 01/01/91</li>
              <li class="text-end ms-1 letra"><b>RESPONSABLE INSCRIPTO</b></li>
            </ul>
          </div>
        </div>
        <div class="border justify-content-md-center mb-1 mt-2" id="aeroplantaInfo">
          <div class="letra text-center">
            <b>Terminal:</b> {{this.comprobante.remitoCargamento.terminal.nombre}}
            ({{this.comprobante.remitoCargamento.terminal.codigoTerminal}})
          </div>
          <div class="letra text-center">
            <b>Dirección:</b> {{this.comprobante.remitoCargamento.terminal.direccion}}
          </div>
        </div>
        <div id="compradorInfo" class="justify-content-md-start border mt-2">
          <ul class="list-unstyled js mb-2">
            <li class="text-start letra"><b>SRES:</b> Aeroplanta: {{this.aeroplantaActual.nombre}} -
              ({{this.aeroplantaActual.codigoAeroplanta}})</li>
          </ul>
        </div>
        <div *ngIf="this.comprobante.remitoCargamento.reciboCargamento" id="transportista"
          class="justify-content-md-start border mt-2">
          <ul class="list-unstyled js" *ngIf="this.comprobante.remitoCargamento.reciboCargamento.transportista != null">
            <li class="letra mt-1" style="column-count: 3;">
              <p class="text-start"><b>TRANSPORTISTA:</b>
                {{this.comprobante.remitoCargamento.reciboCargamento.transportista.nombre}}</p>
              <p class="text-center"><b>DIRECCIÓN:</b>
                {{this.comprobante.remitoCargamento.reciboCargamento.transportista.domicilio}}</p>
              <p class="text-end"><b>CUIT:</b>
                {{this.comprobante.remitoCargamento.reciboCargamento.transportista.cuit}}</p>
            </li>
            <li class="letra" style="column-count: 3;">
              <p class="text-start"><b>CONDUCTOR:</b>
                {{this.comprobante.remitoCargamento.reciboCargamento.conductor.nombre}}</p>
              <p class="text-center"><b>DNI CONDUCTOR:</b>
                {{this.comprobante.remitoCargamento.reciboCargamento.conductor.documento}}</p>
              <p class="text-end"><b>PRECINTO DESDE:</b>
                {{comprobante.remitoCargamento.reciboCargamento.precintoDesde}}</p>
            </li>
            <li class="letra" style="column-count: 3;">
              <p class="text-start"><b>PATENTE CAMION:</b> {{comprobante.remitoCargamento.reciboCargamento.patente}}</p>
              <p class="text-center"><b>CAPACIDAD CAMION:</b>
                {{comprobante.remitoCargamento.reciboCargamento.capacidadCamion}}</p>
              <p class="text-end"><b>PRECINTO HASTA:</b>
                {{comprobante.remitoCargamento.reciboCargamento.precintoHasta}}</p>
            </li>
          </ul>
        </div>
        <div id="ProductoRecibido" class="justify-content-md-start border mt-2">
          <ul class="list-unstyled js mb-2" *ngFor="let prod of this.comprobante.detalles; let i = index">
            <li class="text-start mb-1 mt-1 letra"><b>PRODUCTO RECIBIDO</b></li>
            <li class="letra" style="column-count: 3;">
              <p class="text-start"><b>CÓDIGO:</b> {{this.comprobante.detalles[i].codigoProducto}}</p>
              <p class="text-center"><b>DESCRIPCIÓN:</b> {{this.comprobante.detalles[i].nombreProducto}}</p>
              <p class="text-end"><b>NRO CERTIF. ANALISIS:</b>
                {{this.comprobante.remitoCargamento.numeroCertificadoAnalisis}}</p>
            </li>
            <li class="letra mb-2 " style="column-count: 3;"
              *ngIf="this.comprobante.detalles[i].detalleCombustible != null">
              <p class="text-start"><b>DENSIDAD:</b> {{this.comprobante.detalles[i].detalleCombustible.densidad | number:
                '0.3'}} Gr/cm</p>
              <p class="text-center"><b>TEMPERATURA:</b> {{this.comprobante.detalles[i].detalleCombustible.temperatura |
                number: '0.2'}}°C</p>
            </li>
            <li class="letra" style="column-count: 3;" *ngIf="this.comprobante.detalles[i].detalleCombustible != null">
              <p class="text-start"><b>CANTIDAD:</b> {{this.comprobante.detalles[i].cantidad | number : '0.0-0'}} Lts.</p>
              <p class="text-center"><b>CANTIDAD 15º:</b> {{this.comprobante.detalles[i].detalleCombustible.cantidad15 | number : '0.0-0'}} Lts.</p>
            </li>
          </ul>
        </div>

        <div id="turbina" *ngIf="this.comprobante.remitoCargamento.recepcionDucto != null"
          class="justify-content-md-start border mt-2">
          <ul class="list-unstyled js mb-2">
            <li class="text-start letra"><b>Cantidad turbina:</b>{{
              this.comprobante.remitoCargamento.recepcionDucto.cantidadTurbina  | number : '0.0-0'}} &nbsp; &nbsp; &nbsp; <b>Cantidad 15º
                turbina:</b> {{ this.comprobante.remitoCargamento.recepcionDucto.cantidad15Turbina  | number : '0.0-0'}}</li>
          </ul>
        </div>

        <div id="comentario" class="justify-content-md-start border mt-2">
          <ul class="list-unstyled js mb-2 mt-1">
            <li class="text-start letra"><b>Comentario:</b></li>
            <li class="text-start letra">{{this.comprobante.remitoCargamento.comentario}}</li>
          </ul>
        </div>

        <div id="recibioConforme" class="justify-content-md-center d-flex border mt-2">
          <b class="text-center letraImpuesto">Recibi conforme los productos y/o servicios detallados en el presente
            documento y
            original del mismo.</b>
        </div>
        <div id="firmas" class="justify-content-md-center d-flex border mt-2">
          <div class="d-flex justify-content-between">
            <img class="flex-fill w-50 img-fluid print-resize" [src]="this.ImagenRemito()" />
          </div>
        </div>
      </div>
    </div>
    <button *ngIf="comprobante.id != null" styleSheetFile="assets/css/print.css,assets/css/bootstrap.min.css"
      printTitle="" printSectionId="facturaPrint" [useExistingCss]="true"
      class="btn btn-sm btn-outline-secondary mb-2 ms-2" ngxPrint>
      <i class="fa fa-print" aria-hidden="true"></i> Imprimir </button>
  </div>

  <div id="invoicingPrint" *ngIf="this.comprobante != null && this.comprobante.remitoAeroespecialidad && comp == 0">
    <div class="card-body" id="facturaPrint">
      <div class="container">
        <div class="row justify-content-md-center">
          <div class="col-md-auto border text-center line-small mb-1">
            <p class="h3">R</p>
            <small>Cod. 91</small>
          </div>
        </div>
        <div class="justify-content-md-center d-flex">
          <div id="emisorData" class="col border me-1">
            <ul class="list-unstyled js">
              <li class="letra">
                <img id="logo-ypf" src="/assets/images/logoypf.png" />
              </li>
              <li class="letra"><b>BV. MACACHA GOMEZ - CAPITAL FEDERAL (1001)</b></li>
              <li class="letra"></li>
              <li class="letra"><b>T.E.: 0800-1222671</b></li>
              <li class="letra"><b>YPFAVIACION@YPF.COM</b></li>
            </ul>
          </div>
          <div id="comprobanteInfo" class="col border ms-1">
            <ul class="list-unstyled js">
              <li class="text-start"><b class="mpalabra">REMITO CARGAMENTO</b>
                <b class="pull-right mpalabra">Nro.
                  {{CompletarPrefijo(comprobante.prefijo)}}-{{CompletarNumero(comprobante.numeroRemito)}}</b>
              </li>
              <li class="text-start ms-1 letra"><b>FECHA:</b> {{this.movimiento.fechaHora | date: 'dd/MM/yyy'}} <span
                  class="pull-right"><b>HORA:</b> {{this.movimiento.fechaHora | date: 'mm:mm'}} Hs.</span></li>
              <li class="text-end ms-1 letra"><b>CUIT: 20-95455466-0</b></li>
              <li class="text-end ms-1 letra"><b>IIBB CM 901-915025-3</b></li>
              <li class="text-end ms-1 letra"><b>INICIO ACTIVIDADES:</b> 01/01/91</li>
              <li class="text-end ms-1 letra"><b>RESPONSABLE INSCRIPTO</b></li>
            </ul>
          </div>
        </div>
        <div class="border justify-content-md-center mb-1 mt-2" id="aeroplantaInfo">
          <div class="letra text-center">
            <b>Terminal:</b> {{this.comprobante.remitoAeroespecialidad.terminal.nombre}}
            ({{this.comprobante.remitoAeroespecialidad.terminal.codigoTerminal}})
          </div>
          <div class="letra text-center">
            <b>Dirección:</b> {{this.comprobante.remitoAeroespecialidad.terminal.direccion}}
          </div>
        </div>
        <div id="compradorInfo" class="justify-content-md-start border mt-2">
          <ul class="list-unstyled js mb-2">
            <li class="text-start letra"><b>SRES:</b> Aeroplanta: {{this.aeroplantaActual.nombre}} -
              ({{this.aeroplantaActual.codigoAeroplanta}})</li>
          </ul>
        </div>        
        <div id="ProductoRecibido" class="justify-content-md-start border mt-2">
          <ul class="list-unstyled js mb-2" *ngFor="let prod of this.comprobante.detalles; let i = index">
            <li class="text-start mb-1 mt-1 letra"><b>PRODUCTO RECIBIDO</b></li>
            <li class="letra" style="column-count: 3;">
              <p class="text-start"><b>CÓDIGO:</b> {{this.comprobante.detalles[i].codigoProducto}}</p>
              <p class="text-center"><b>DESCRIPCIÓN:</b> {{this.comprobante.detalles[i].nombreProducto}}</p>
              <p class="text-end"><b>CANTIDAD:</b> {{this.comprobante.detalles[i].cantidad | number : '0.0-0'}} U.</p>
            </li>            
          </ul>
        </div>

        <div id="comentario" class="justify-content-md-start border mt-2">
          <ul class="list-unstyled js mb-2 mt-1">
            <li class="text-start letra"><b>Comentario:</b></li>
            <li class="text-start letra">{{this.comprobante.remitoAeroespecialidad.comentario}}</li>
          </ul>
        </div>

        <div id="recibioConforme" class="justify-content-md-center d-flex border mt-2">
          <b class="text-center letraImpuesto">Recibi conforme los productos y/o servicios detallados en el presente
            documento y
            original del mismo.</b>
        </div>
        <div id="firmas" class="justify-content-md-center d-flex border mt-2">
          <div class="d-flex justify-content-between">
            <img class="flex-fill w-50 img-fluid print-resize" [src]="this.ImagenRemito()" />
          </div>
        </div>
      </div>
    </div>
    <button *ngIf="comprobante.id != null" styleSheetFile="assets/css/print.css,assets/css/bootstrap.min.css"
      printTitle="" printSectionId="facturaPrint" [useExistingCss]="true"
      class="btn btn-sm btn-outline-secondary mb-2 ms-2" ngxPrint>
      <i class="fa fa-print" aria-hidden="true"></i> Imprimir </button>
  </div>

  <!-- REMITO SALIDA -->
  <div id="invoicingPrint" *ngIf="this.comprobante != null && comp == 2">
    <div class="card-body" id="facturaPrint">
      <div class="container">
        <div class="row justify-content-md-center">
          <div class="col-md-auto border text-center line-small mb-1">
            <p class="h3">R</p>
            <small>Cod. 91</small>
          </div>
        </div>
        <div class="justify-content-md-center d-flex" *ngIf="!this.obteniendoAeroplanta">
          <div id="emisorData" class="col border me-1">
            <ul class="list-unstyled js">
              <li class="letra">
                <img id="logo-ypf" src="/assets/images/logoypf.png" />
              </li>
              <li class="letra"><b>{{aeroplanta[0].emisor.razonSocial}} - CAPITAL FEDERAL (1001)</b></li>
              <li class="letra">{{aeroplanta[0].emisor.direccion}}&nbsp;{{aeroplanta[0].emisor.ciudad.nombre}}&nbsp;{{aeroplanta[0].emisor.ciudad.codigoPostal}}</li>
              <li class="letra"><b>T.E.: {{aeroplanta[0].emisor.telefono}}</b></li>
              <li class="letra"><b>{{aeroplanta[0].emisor.email}}</b></li>
            </ul>
          </div>
          <div id="comprobanteInfo" class="col border ms-1">
            <ul class="list-unstyled js">
              <li class="text-start"><b class="mpalabra">REMITO CARGAMENTO</b>
                <b class="pull-right mpalabra">Nro.
                  {{CompletarPrefijo(comprobante.remitoSalida.prefijoFOX)}}-{{CompletarNumero(comprobante.remitoSalida.numeroFOX)}}</b>
              </li>
              <li class="text-start ms-1 letra"><b>FECHA:</b> {{this.movimiento.fechaHora | date: 'dd/MM/yyy'}} <span
                  class="pull-right"><b>HORA:</b> {{this.movimiento.fechaHora | date: 'HH:mm'}} Hs.</span></li>
              <li class="text-end ms-1 letra"><b>CUIT: {{aeroplanta[0].emisor.numeroDocumento}}</b></li>
              <li class="text-end ms-1 letra"><b>IIBB CM {{aeroplanta[0].emisor.numeroIIBB}}</b></li>
              <li class="text-end ms-1 letra"><b>INICIO ACTIVIDADES:</b> {{aeroplanta[0].emisor.fechaInicioActividades | date: 'dd/MM/yyy'}}</li>
              <li class="text-end ms-1 letra"><b>RESPONSABLE INSCRIPTO</b></li>
            </ul>
          </div>
        </div>
        <div class="border justify-content-md-center mb-1 mt-2" id="aeroplantaInfo">
          <div class="letra text-center">
            <b>AEROPLANTA:</b> {{this.aeroplantaActual.nombre}} - ({{this.aeroplantaActual.codigoAeroplanta}})
          </div>
          <div class="letra text-center">
            <b>Buenos Aires - Buenos Aires</b>
          </div>
        </div>
        <div id="compradorInfo" class="justify-content-md-start border mt-2">
          <ul class="list-unstyled js mb-2">
            <li class="text-start letra">
              <b>Sres:</b> {{this.comprobante.remitoSalida.terminal.nombre}}
              (({{this.comprobante.remitoSalida.terminal.codigoTerminal}}))
            </li>
            <li class="text-start letra">
              <b>DIRECCIÓN:</b> {{this.comprobante.remitoSalida.terminal.direccion}}
            </li>
          </ul>
        </div>

        <div id="compradorInfo" class="justify-content-md-start border mt-2">
          <ul class="list-unstyled js mb-2">
            <li class="text-start letra"><b>Nro. CERTIFICADO ANALISIS:</b>
              {{this.comprobante.remitoSalida.numeroCertificadoAnalisis}}</li>
            <li class="text-start letra"><b>Pr. Sup.:</b> {{this.comprobante.remitoSalida.prSup }}</li>
            <li class="text-start letra"><b>Nro. ONU:</b> {{this.comprobante.remitoSalida.numeroONU}}</li>
            <li class="text-start letra">{{this.comprobante.remitoSalida.comentarioOnu}}</li>
          </ul>
        </div>

        <div id="transportista" class="justify-content-md-start border mt-2">
          <ul class="list-unstyled js mb-2" *ngIf="this.comprobante.remitoSalida.transportista != null">
            <li class="letra mt-1" style="column-count: 3;">
              <p class="text-start"><b>TRANSPORTISTA:</b>
                {{this.comprobante.remitoSalida.transportista.nombre}}</p>
              <p class="text-center"><b>DIRECCIÓN:</b>
                {{this.comprobante.remitoSalida.transportista.domicilio}}</p>
              <p class="text-end"><b>CUIT:</b>
                {{this.comprobante.remitoSalida.transportista.cuit}}</p>
            </li>
            <li class="letra" style="column-count: 2;">
              <p class="text-start"><b>CONDUCTOR:</b>
                {{this.comprobante.remitoSalida.conductor.nombre}}</p>
              <p class="text-center"><b>DNI CONDUCTOR:</b>
                {{this.comprobante.remitoSalida.conductor.documento}}</p>            
            </li>
            <li class="letra" style="column-count: 3;">
              <p class="text-start"><b>CAPACIDAD CAMION:</b>
                {{comprobante.remitoSalida.capacidadCamion | number : '0.0-0'}}</p>       
              <p class="text-center"><b>PRECINTO DESDE:</b>
                {{comprobante.remitoSalida.precintoDesde}}</p>
              <p class="text-end"><b>PRECINTO HASTA:</b>
                {{comprobante.remitoSalida.precintoHasta}}</p>
            </li>
            <li class="text-start letra">
              <b>PATENTES:</b>
              <br/>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 
              <span *ngFor="let p of comprobante.remitoSalida.patentes"> 
                {{p}} &nbsp; &nbsp; &nbsp;
              </span>
            </li>
          </ul>
        </div>

        <div id="ProductoRecibido" class="justify-content-md-start border mt-2">
          <ul class="list-unstyled js mb-2" *ngFor="let prod of this.comprobante.detalles; let i = index">
            <li class="text-start mb-1 mt-1 letra"><b>PRODUCTO</b></li>
            <li class="letra" style="column-count: 3;">
              <p class="text-start"><b>CÓDIGO:</b> {{this.comprobante.detalles[i].codigoProducto}}</p>
              <p class="text-center"><b>DESCRIPCIÓN:</b> {{this.comprobante.detalles[i].nombreProducto}}</p>
              <p class="text-center"><b>CANTIDAD:</b> {{this.comprobante.detalles[i].cantidad | number : '0.0-0'}} {{this.comprobante.detalles[i].tipoProducto == 2 ? "Unid." : "Lts."}}</p>
            </li>
            <li class="letra mb-2 " style="column-count: 3;"
              *ngIf="this.comprobante.detalles[i].detalleCombustible != null">
              <p class="text-start"><b>DENSIDAD:</b> {{this.comprobante.detalles[i].detalleCombustible.densidad | number:
                '0.3'}} Gr/cm</p>
                <p class="text-end"><b>TEMPERATURA:</b> {{this.comprobante.detalles[i].detalleCombustible.temperatura |
                  number: '0.2'}}°C</p>              
              <p class="text-end"><b>CANTIDAD 15º:</b> {{this.comprobante.detalles[i].detalleCombustible.cantidad15 | number : '0.0-0'}} Lts.</p>
            </li>
          </ul>
        </div>

        <div id="comentario" class="justify-content-md-start border mt-2">
          <ul class="list-unstyled js mb-2">
            <li class="text-start letra"><b>COMENTARIO:</b></li>
            <li class="text-start letra">{{this.comprobante.remitoSalida.comentario}}</li>
          </ul>
        </div>

        <div id="recibioConforme" class="justify-content-md-center d-flex border mt-2">
          <b class="text-center letraImpuesto">Recibi conforme los productos y/o servicios detallados en el presente
            documento y
            original del mismo.</b>
        </div>
        <div class="row col-12 mt-2 letra" *ngIf="!this.obteniendoAeroplanta">
          <ul class="list-unstyled text-start col-6">
            <li>{{this.aeroplanta[0].emisor.imprentaRazonSocial}}</li>
            <li>CUIT: {{this.aeroplanta[0].emisor.imprentaCUIT}}</li>
            <li>Fecha Impresión: {{this.movimiento.fechaHora | date: "d'/'MM'/'y' "}}</li>
          </ul>
          <ul class="list-unstyled text-end col-6">
            <li>CAI: {{this.comprobante.remitoSalida.cai}}</li>
            <li>Fecha venc.: {{this.aeroplanta[0].fechaVencimientoCAI | date: 'dd/MM/yyyy'}}</li>
          </ul>
        </div>
      </div>
    </div>
    <button *ngIf="comprobante.id != null" styleSheetFile="assets/css/print.css,assets/css/bootstrap.min.css"
      printTitle="" printSectionId="facturaPrint" [useExistingCss]="true"
      class="btn btn-sm btn-outline-secondary mb-2 ms-2" ngxPrint>
      <i class="fa fa-print" aria-hidden="true"></i> Imprimir </button>
  </div>
</div>