import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificacionesService } from 'src/app/abm/notificacion/notificaciones.service';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { NotificacionSistemaEncabezadoDTO } from 'src/app/shared/models/notificaciones/NotificacionSistemaEncabezadoDTO';
import { NotificacionUsuario } from 'src/app/shared/models/notificaciones/NotificacionUsuario';

@Component({
  selector: 'app-notificacion-detalle',
  templateUrl: './notificacion-detalle.component.html',
  styleUrls: ['./notificacion-detalle.component.scss']
})
export class NotificacionDetalleComponent implements OnInit {

  @Input() encabezado: NotificacionSistemaEncabezadoDTO;

  notificacion: NotificacionUsuario;
  obteniendoDatos: boolean = false;

  constructor( public activeModal: NgbActiveModal,
               private notificacionesService: NotificacionesService) { }

  ngOnInit(): void {
    this.obteniendoDatos = true;

    this.notificacionesService.getNotificacionPorId(this.encabezado.id)
      .subscribe(
        notificacion => {
          this.notificacion = notificacion;
          this.obteniendoDatos = false;
        },
        (error: ErrorModel) => {
          console.log(error);
          this.obteniendoDatos = false;
        }
      );
  }

}

