import { Usuario } from "../despacho/Usuario";
import { FiltroSeleccionado } from "./FiltroSeleccionado";
import { GrupoEncabezado } from "./GrupoEncabezado";
import { TipoNovedadEncabezadoDTO } from "./TipoNovedadEncabezadoDTO";

export class NotificacionSistemaDTO {
    id:string;
    titulo:string; 
    tipoNovedad:TipoNovedadEncabezadoDTO; 
    emails:string[];
    usuarios: Usuario[];
    gruposUsuarios: GrupoEncabezado[];
    filtrosSeleccionados: FiltroSeleccionado[];
    usuariosAccionSeleccionado: string[];
    mensaje:string;
    activo:boolean;
  }