<div class="modal-header bg-light">
  <h4 class="modal-title">Tanques relacionados:  {{this.surtidor.nombre}}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()">
    
  </button>
</div>
<div class="modal-body">
  <ngb-alert type="warning" *ngIf="this.listaDeTanques===null || this.listaDeTanques.length===0"> No existen taques relacionados al surtidor </ngb-alert>
  <table class="table table-striped table-hover" *ngIf="this.listaDeTanques!=null && this.listaDeTanques.length>0">
    <thead>
      <td class="text-center"><b>Codigo</b></td>
      <td class="text-center"><b>Nombre</b></td>
    </thead>
    <tbody>
      <tr *ngFor="let e of this.listaDeTanques">
        <td class="text-center">{{e.codigo}}</td>
        <td class="text-center">{{e.nombre}}</td>
      </tr>
    </tbody>
  </table>
</div>
<div class="modal-footer bg-light">
  <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss('Close clicked')">Cancelar</button>
</div>
