import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ShellComponent } from './shell.component';
import { NavbarComponent } from './navbar/navbar.component';
import { MessagesComponent } from './messages/messages.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../shared/shared.module';
import { ModalAsociarAeroplantaComponent } from '../shared/components/modal-asociar-aeroplanta/modal-asociar-aeroplanta.component';
import { NotificacionesUsuarioComponent } from './notificaciones-usuario/notificaciones-usuario.component';
import { NotificacionDetalleComponent } from './notificacion-detalle/notificacion-detalle.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NgbModule,
    InfiniteScrollModule
  ],
  declarations: [
    ShellComponent,
    NavbarComponent,
    MessagesComponent,
    NotificacionesUsuarioComponent,
    NotificacionDetalleComponent,
  ],
  entryComponents: [ModalAsociarAeroplantaComponent]
})
export class ShellModule { }
