<div class="this.esperandoCarga===false">
  <ngb-alert type="danger" [dismissible]="false" *ngIf="fechaDesdeMayorQueHasta()==true">
    La fecha inicial debe ser anterior a la fecha final.
  </ngb-alert>
  <ngb-alert type="warning" [dismissible]="false" *ngIf="this.errorEnExportacion==true">
    No se han obtenido datos para exportar, revise los filtros de busqueda.
  </ngb-alert>


  <nav class="navbar navbar-light bg-light navbar-expand-lg" [formGroup]="busquedasCliente">
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav">
        <li class="nav-item me-2">
          <input id="clienteFormControl" type="text" class="form-control" formControlName="clienteFormControl"
            placeholder="Cliente/CtaSap" [inputFormatter]="formatter" [(ngModel)]="modelCliente"
            [resultFormatter]="formatter" title="Seleccione un cliente." [ngbTypeahead]="search"
            (selectItem)="selectCustomer($event)" (change)="filtroClientes()" />
        </li>

        <li class="nav-item me-2">
          <input id="ranchoFormControl" type="text" class="form-control" formControlName="ranchoFormControl"
            placeholder="Rancho" [inputFormatter]="formatterRancho" [(ngModel)]="modelRancho"
            [resultFormatter]="formatterRancho" title="Seleccione un rancho." [ngbTypeahead]="searchRancho"
            (selectItem)="selectRancho($event)" (change)="filtroRanchos()" />
        </li>
        <!-- Aeroplantas -->
        <form [formGroup]="checkMultiplesAeroplantas">
          <li class="nav-item me-2">
            <div ngbDropdown autoClose="outside">
              <button class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2" id="dropdownProductos"
                ngbDropdownToggle>Aeroplantas</button>
              <div ngbDropdownMenu aria-labelledby="dropdownProductos" style="z-index: 50; overflow-y: scroll; height: 30em;">
                <div class="px-4 py-3" style="z-index: 50">
                  <div *ngFor="let aero of aeroplantasParaCheckear;let i = index">
                    <div class="form-check">
                      <input type="checkbox" name="aeroplantas+{{i}}" class="form-check-input"
                        id="{{ 'dropdownCheck' + aero.nombre}}" (change)="this.seleccionarAeroplanta(aero.codigo, $event.target.checked)">
                      <label class="form-check-label text-nowrap" for="{{ 'dropdownCheck' + aero.nombre}}">
                        {{aero.nombre}}
                      </label> </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
      </form>
        <!-- Productos -->
        <form [formGroup]="checkMultiplesProductos">
          <li class="nav-item me-2">
            <div ngbDropdown autoClose="outside">
              <button class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2" id="dropdownAeroplantas"
                ngbDropdownToggle>Productos</button>
              <div ngbDropdownMenu aria-labelledby="dropdownAeroplantas" style="z-index: 50; overflow-y: scroll; height: 30em;">
                <div class="px-4 py-3" style="z-index: 50">
                  <div *ngFor="let prod of productosParaCheckear;let i = index">
                    <div class="form-check">
                      <input type="checkbox" name="productos+{{i}}" class="form-check-input"
                        id="{{ 'dropdownCheck' + prod.nombre}}" (change)="this.seleccionarProducto(prod.codigo, $event.target.checked)"
                      >
                      <label class="form-check-label text-nowrap" for="{{ 'dropdownCheck' + prod.nombre}}">
                        {{prod.nombre}}
                      </label> </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
      </form>
      <!--Estados-->
      <form [formGroup]="checkMultiplesEstados">
        <li class="nav-item me-2">
          <div ngbDropdown autoClose="outside">
            <button class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2" id="dropdownEstados"
              ngbDropdownToggle>Estado</button>
            <div ngbDropdownMenu aria-labelledby="dropdownEstados" style="z-index: 50">
              <div class="px-4 py-3" style="z-index: 50">
                <div *ngFor="let prod of this.estadosParaCheckear;let i = index">
                  <div class="form-check">
                    <input value="i" type="checkbox" name="Estado+{{i}}" class="form-check-input"
                      id="{{ 'dropdownCheck' + prod.nombre}}" (change)="this.seleccionarEstados(prod.codigo, $event.target.checked)"
                    >
                    <label class="form-check-label text-nowrap" for="{{ 'dropdownCheck' + prod.nombre}}">
                      {{prod.nombre}}
                    </label> </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </form>
        <!-- Condiciones de Venta -->
        <form [formGroup]="checkMultiplesCondicionesDeVenta">
          <li class="nav-item me-2">
            <div ngbDropdown autoClose="outside">
              <button class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2" id="dropdownAeroplantas"
                ngbDropdownToggle>Condiciones de Venta</button>
              <div ngbDropdownMenu aria-labelledby="dropdownAeroplantas" style="z-index: 50">
                <div class="px-4 py-3" style="z-index: 50">
                  <div *ngFor="let prod of condicionesDeVentaParaCheckear;let i = index">
                    <div class="form-check">
                      <input type="checkbox" name="productos+{{i}}" class="form-check-input"
                        id="{{ 'dropdownCheck' + prod.nombre}}" (change)="this.seleccionarCondicionDeVenta(prod.codigo, $event.target.checked)"
                      >
                      <label class="form-check-label text-nowrap" for="{{ 'dropdownCheck' + prod.nombre}}">
                        {{prod.nombre}}
                      </label> </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
      </form>



      </ul>
    </div>
  </nav>

      <!-- Segunda fila de botones -->
      <nav  class="navbar navbar-light bg-light navbar-expand-lg mb-3">
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav" [formGroup]="fechaForm">



        <!-- fecha desde-->

            <li class="nav-item me-2">
          <div class="input-group">
            <input type="date"  placeholder="aaaa-mm-dd" formControlName="fechaDesdeFormControl" name="dt"
              ngbDatepicker value="modelDesde" (ngModelChange)="modelDesde = $event" [ngModel]="modelDesde"
              ngbTooltip="Seleccione una fecha." class="form-control form-control-rounded" #d="ngbDatepicker" value="modelDesde" #searchDate
              readonly>
            <div class="input-group-append">
              <button id="botonDesde" type="button" class="input-group-btn btn btn-secondary" (click)="d.toggle()">
                <i class="fa fa-calendar" aria-hidden="true"></i></button>
            </div>
          </div>

        <!-- fecha hasta-->
        <li class="nav-item me-2" >
          <div class="input-group">
            <input type="date"  placeholder="aaaa-mm-dd" formControlName="fechaHastaFormControl" name="dt"
              ngbDatepicker value="modelHasta" (ngModelChange)="modelHasta = $event" [ngModel]="modelHasta" value="modelHasta"
              ngbTooltip="Seleccione una fecha." class="form-control form-control-rounded" #h="ngbDatepicker" #searchDate
              readonly>
            <div class="input-group-append">
              <button id="botonHasta" type="button" class="input-group-btn btn btn-secondary" (click)="h.toggle()">
                <i class="fa fa-calendar" aria-hidden="true"></i></button>
            </div>
          </div>




     </li>
        <form [formGroup]="checkOrden">
          <li class="nav-item me-2">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <label class="input-group-text" for="inputGroupOrden">Ordenar por</label>
              </div>
              <select class="form-select" id="inputGroupOrden" formControlName="nombreOrden">
                <option value="0" selected>Cliente</option>
                <option value="1">Cuenta</option>
                <option value="2">Fecha</option>
              </select>
            </div>
          </li>
        </form>

        <li  class="nav-item me-2">
          <button [disabled]="this.fechaDesdeMayorQueHasta()===true" (click)="this.descargar()"
           class="btn btn-outline-success my-2 my-lg-0 mx-lg-2">
           <i class="fa fa-file-excel-o fa-lg" aria-hidden="true"></i> Descargar </button>
        </li>


      </ul>
  </div>
  </nav>
  </div>
