import { Component, OnInit, Input } from '@angular/core';
import { jsPDF } from 'jspdf';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Renglones, ResolucionMensual } from 'src/app/shared/models/cierreTurno/ResolucionMensual';

@Component({
  selector: 'app-visor-informe-resolucion',
  templateUrl: './visor-informe-resolucion.component.html',
  styleUrls: ['./visor-informe-resolucion.component.scss']  
})
export class VisorInformeResolucionComponent {

  @Input() resoluciones;
  @Input() fileName;
  renglones: Renglones[] = [];
  mesYAno: string;
  esTercera;

  constructor(public activeModal: NgbActiveModal) { 
      
  }

  ngOnInit() {
      console.log(this.resoluciones)
      this.renglones = this.resoluciones.renglonesResolucion;
      this.mesYAno = `${this.resoluciones.mes}/${this.resoluciones.ano}` 
  }

  descargarPdf() {
    const html = document.querySelector('#informeResolucion') as HTMLElement;
    html.setAttribute("width", '595');
    html.setAttribute("height", '980');
    this.exportAllToPDF(html);
  }

exportAllToPDF(pages: HTMLElement) {
  const doc = new jsPDF({
    unit: 'pt',
    format: 'a4'
  });

  //chequeo de la cantidad de paginas necesarias
  let pag = this.renglones.length > 4 ? 10 : 1;

  doc.html(pages, {
    callback: (doc: jsPDF) => {
      while (doc.getNumberOfPages() > pag ) doc.deletePage(doc.getNumberOfPages());
      doc.save(this.fileName);
    },
    html2canvas:{scale: 0.52},
    margin: [10, 5]
  });
}

}