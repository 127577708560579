import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { NgbModal, NgbPagination } from '@ng-bootstrap/ng-bootstrap';
import { PaginatePipe } from 'ngx-pagination';
import { UsuarioService } from 'src/app/account/usuario.service';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';
import { Cliente } from 'src/app/shared/models/cliente/Cliente';
import { Usuario } from 'src/app/shared/models/despacho/Usuario';
import { MessageService } from 'src/app/shell/message.service';


@Component({
  selector: 'app-modal-comerciales',
  templateUrl: './modal-comerciales.component.html',
  styleUrls: ['./modal-comerciales.component.css']
})
export class ModalComercialesComponent implements OnInit {
  @Input() clienteSeleccionado: Cliente;
  @Output() comercialAgregado: EventEmitter<string> = new EventEmitter();


  comercialFormControl = new UntypedFormControl({value: null, disabled: !this.usuarioModificaCampos()});
  //usuarios: any[];
  usuariosComerciales: Usuario[]; // se usa para solamente se seleccionen los que no estan asigandos.  
  usuariosDelCliente: string[] = [];
  usuariosSeleccionado: string;//Usuario = null;
  cargandoComerciales: boolean = false;

  paginaActual: number = 1;
  paginaPrevia: number = 1;

  constructor(public activeModal: NgbModal, private usuarioService: UsuarioService, private pagination: PaginatePipe, private authService: AuthService, private messageService: MessageService) { }

  ngOnInit() {
    this.getComerciales();
    this.usuariosDelCliente = [];
  }

  getComerciales() {
    this.cargandoComerciales = true;
    //this.usuarios = [];
    this.usuariosComerciales = [];      
    if (this.usuarioModificaCampos()){
      //Si el usuario modifica campos es porque es comercial llamo a este metodo para que traiga los usuarios con los roles de comercial
      this.usuarioService.getUsuariosPorRolYAeroplanta(TipoUsuario.COMERCIAL,"",true)
      .subscribe(result => {
        //this.usuarios.push(result);
        this.usuariosComerciales = result;
        let comercialesFiltrados = this.usuariosComerciales.filter(comercial => comercial.nombresCompletos == "TRECARICHI, CARLOS" || comercial.nombresCompletos == "SEGOVIA, LUCAS" || comercial.nombresCompletos == "LEONE, MARIA EMILIA" || comercial.nombresCompletos == "CARBAJO, MARIANO" || comercial.nombresCompletos == "PEDEFLOUS, JOSE");
        this.usuariosComerciales = comercialesFiltrados;
        // genera la lista sin lo asignado para que no lo asigne 2 veces
        this.listaDeComercialesNoAsignados();
      });
    }else{
      //Si el usuario no modifica cambios trae el comercial que tiene asignado la aeroplanta.
      this.usuarioService.obtenerComercialPorAeroplanta(this.usuarioService.getAeroplantaActual().codigoAeroplanta)
        .subscribe(result => {
          //this.usuarios.push(result);
          this.usuariosComerciales.push(result);
          // genera la lista sin lo asignado para que no lo asigne 2 veces
          this.listaDeComercialesNoAsignados();
        });
    }
    
  }

  usuarioModificaCampos(): boolean {
    return this.authService.hasRole(TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.APROBADORBYR, TipoUsuario.ADMCLIENTES);
  }

  agregarComercial() {
    this.clienteSeleccionado.comerciales = this.usuariosDelCliente;
    this.messageService.showWarningMessage('Se modifico el comercial correctamente. \n Recuerde guardar los cambios del cliente.', false)
    this.activeModal.dismissAll();
  }

  listaDeComercialesNoAsignados() {
    let lista : Usuario[] = [];
    let i = 0;
    if (this.clienteSeleccionado != null) {

      if(this.clienteSeleccionado.comerciales!=null)
        this.usuariosDelCliente = this.clienteSeleccionado.comerciales;

      for (let usuario of this.usuariosComerciales) {
        if (!this.estaEnLaListaDeComerciales(usuario)) {
          lista.push(usuario);
        }
      }
      this.usuariosComerciales = lista;
      this.comercialFormControl.setValue(lista[0]);
    }
    this.cargandoComerciales = false;
  }

  estaEnLaListaDeComerciales(usuario: Usuario) {
    for (let entry of this.usuariosDelCliente) {

      if (usuario.nombresCompletos === entry) {
        return true;
        break;
      }

    }
    return false;
  }

  asignarUsuarioSeleccionado() {
    if (this.comercial.value && !this.estaAsignadoUsuario()) {
      this.usuariosSeleccionado = this.comercial.value.nombresCompletos;
      this.usuariosDelCliente.push(this.usuariosSeleccionado);
      let index = 0;
      for(let sacar of this.usuariosComerciales){
        if(sacar.nombresCompletos == this.usuariosSeleccionado){
          if (index !== -1) {
            this.usuariosComerciales.splice(index, 1);
          }
        }
        index++;
      }
      this.comercialFormControl.setValue(this.usuariosComerciales[0]);
    }
    
  }

  estaAsignadoUsuario() {
    for (let entry of this.usuariosDelCliente) {
      {
        if (this.comercial.value.nombresCompletos === entry) {
          return true;
          break;
        }

      }
      return false;
    }    
  }


  desasignarUsuarioSeleccionado(usuario) {
    this.usuariosComerciales.push(usuario);
    this.usuariosSeleccionado = usuario; // asigno el desagninado al seleccionado
    const index: number = this.usuariosDelCliente.indexOf(usuario);
    if (index !== -1) {
      this.usuariosDelCliente.splice(index, 1);
    }
  }

  get comercial() {
    return this.comercialFormControl;
  }

  cambiarPagina(event) {
    if (this.paginaActual !== this.paginaPrevia) {
      this.paginaPrevia = this.paginaActual;
      this.paginaActual = event;
    }
    else {
      this.paginaActual = event;
    }
  }

  yaAsigneTodos() {
    let es: boolean = false;
    es = this.usuariosComerciales.length === 0;
    return es;
  }

}
