import { DatePipe } from '@angular/common';
import { Component, HostBinding, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbCalendar, NgbDatepickerConfig, NgbDateStruct, NgbModal, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { ProductoService } from 'src/app/abm/producto/producto.service';
import { UsuarioService } from 'src/app/account/usuario.service';
import { ModalMotivoRechazoComponent } from 'src/app/cliente/clientes-aprobar-modificaciones/modal-motivo-rechazo/modal-motivo-rechazo.component';
import { ModalSeleccionarUsuarioComponent } from 'src/app/precios/niveles-aprobacion/modal-seleccionar-usuario/modal-seleccionar-usuario.component';
import { PreciosService } from 'src/app/precios/precios.service';
import { EstadoRefacturacion } from 'src/app/shared/enums/estadoRefacturacion';
import { Motivo } from 'src/app/shared/enums/Motivo';
import { TipoMoneda } from 'src/app/shared/enums/tipoMoneda';
import { tipoNivelUsuarioSeparados } from 'src/app/shared/enums/tipoNivelUsuarioSeparados';
import { FechasService } from 'src/app/shared/fechas.service';
import { ModalConfirmComponent } from 'src/app/shared/generic-modals/modal-confirm.component';
import { ModalOkComponent } from 'src/app/shared/generic-modals/modal-ok.component';
import { Aeroplanta } from 'src/app/shared/models/despacho/Aeroplanta';
import { Aerovale } from 'src/app/shared/models/despacho/Aerovale';
import { AerovaleDetalle } from 'src/app/shared/models/despacho/AerovaleDetalle';
import { Usuario } from 'src/app/shared/models/despacho/Usuario';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { Comprobante } from 'src/app/shared/models/facturacion/Comprobante';
import { Factura } from 'src/app/shared/models/facturacion/Factura';
import { RefacturacionFactura } from 'src/app/shared/models/facturacion/RefacturacionFactura';
import { TuplaRefacturacion } from 'src/app/shared/models/facturacion/TuplaRefacturacion';
import { TipoMovimientoPipe } from 'src/app/shared/pipes/tipo-movimiento.pipe';
import { SharedService } from 'src/app/shared/shared.service';
import { MessageService } from 'src/app/shell/message.service';
import { ComprobanteService } from '../comprobante.service';
import { FacturacionService } from '../facturacion.service';
import { ModalObservacionRefacturacionComponent } from './modal-observacion-refacturacion/modal-observacion-refacturacion.component';

@Component({
  selector: 'app-aprobacion-refacturacion',
  templateUrl: './aprobacion-refacturacion.component.html',
  styleUrls: ['./aprobacion-refacturacion.component.css']
})
export class AprobacionRefacturacionComponent implements OnInit {

  @HostBinding('class.changed')

  tablaComprobantes: UntypedFormGroup;
  aeroplanta: Aeroplanta;

  tituloFactura: string;


  facturas: RefacturacionFactura[] = [];
  facturasFiltradas: RefacturacionFactura[] = [];

  facturasSeleccionadas: string[] = [];

  facturaCalculada: Factura[];
  facturaObtenida: Comprobante;
  remitosId: string[] = [];
  aerovaleDetalle: AerovaleDetalle;
  previsualizacion: boolean = false;

  aerovales: Aerovale[] = [];
  aerovalesFiltrados: Aerovale[] = [];
  aerovaleActual: Aerovale;
  fechaHasta = new UntypedFormControl();
  fechaFormControlDesdeHasta = new UntypedFormControl();
  mensajeErrorExportar = "";
  model: NgbDateStruct;
  model1: NgbDateStruct;
  time: NgbTimeStruct;
  startDate: Date;
  endDate: Date;
  buscando: boolean = false;
  mensajeSpinner: string = "D e s c a r g a n d o";
  obteniendoAerovales = true;
  procesandoExport: boolean = false;
  codigoAeroplanta: string;
  cambiandoAeroplanta: boolean = false;
  pendiente = { valor: "Pendiente", descripcion: "Pendiente", seleccionado: false };
  finalizado = { valor: "Finalizado", descripcion: "Finalizado", seleccionado: false };
  finalizadoSinFirma = { valor: "FinalizadoSinFirma", descripcion: "Finalizado sin firma", seleccionado: false };
  estadoSincronizado = { valor: "Sincronizado", descripcion: "Sincronizado", seleccionado: false };
  anulado = { valor: "Anulado", descripcion: "Anulado", seleccionado: false };

  notaCredito = { tipo: 0, valor: "notaCredito", descripcion: "Nota de credito", seleccionado: false };
  notaDebito = { tipo: 1, valor: "notaDebito", descripcion: "Nota de debito", seleccionado: false };

  tipos = [
    this.notaCredito, this.notaDebito
  ];

  cargandoPrevisualizacion: boolean = false;
  descargaArchivo: boolean = false;

  estados = [
    this.pendiente,
    this.finalizado,
    this.estadoSincronizado,
    this.anulado
  ];

  busquedaFormControl = new UntypedFormControl();
  change$: Subscription;
  errorAerovales: boolean = false;


  idComprobanteAsociado: string;
  botonAnular: boolean = false;
  cargandoNiveles: boolean;
  errorNiveles: string;
  mensajeNivelUsuario: string;
  cotizacionDolar: number = null;

  aceptandoRefacturacion: boolean = false;
  rechazandoRefacturacion: boolean = false;
  itemSeleccionado: number = null;

  constructor(private datepickerConfig2: NgbDatepickerConfig,
    private messageService: MessageService,
    private usuarioService: UsuarioService,
    private calendar: NgbCalendar,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private activeModal: NgbModal,
    private fb: UntypedFormBuilder,
    private comprobanteService: ComprobanteService,
    public facturacionService: FacturacionService,
    private productosService: ProductoService,
    private fechaService: FechasService,
    private datePipe: DatePipe,
    private tipoMovimientoPipe: TipoMovimientoPipe,
    sharedService: SharedService) {
      this.change$ = sharedService.aeroplantaSeleccionada.subscribe(_=>{
        this.cambiarDeAeroplanta();
      });
  }

  ngOnInit() {
    this.model = this.calendar.getToday();
    this.model1 = this.calendar.getToday();
    let inicio: any = { day: 1, month: 1, year: 2019 };
    this.datepickerConfig2.minDate = inicio;
    let fin: any = { day: this.model.day, month: this.model.month, year: this.model.year };
    this.model1 = this.calendar.getToday();
    this.datepickerConfig2.maxDate = fin;

    this.productosService.getCotizacionDolar()
      .subscribe(result => {
        this.cotizacionDolar = result;
        this.errorNiveles = null;

        this.facturacionService.getNivelesUsuario(this.usuarioService.getUsername())
          .subscribe(result => {
            console.log("Se cargaron los niveles del usuario");
            this.cargandoNiveles = false;
    
            this.continuarCarga();
          },
            (err: ErrorModel) => {
              console.log("Error al cargar los niveles del usuario");
              this.cargandoNiveles = false;
              this.errorNiveles = "Error al cargar los niveles del usuario";
    
              this.continuarCarga();
            });
      },
      (err: ErrorModel) => {
        console.log("Error al obtener cotizacion dolar.");
        this.errorNiveles = "No se pudo obtener la cotización del dolar, por lo que no podra aprobar refacturación.";
        this.obteniendoAerovales = false;
      });    
  }

  continuarCarga() {
    //this.seleccionAerovale();

    this.tablaComprobantes = this.fb.group({
      aerovalesFiltrados: new UntypedFormArray([])
    });
    this.addCheckboxes();


    this.codigoAeroplanta = this.usuarioService.getAeroplantaActual().codigoAeroplanta;
    this.idComprobanteAsociado = '';
    this.botonAnular = false;
    this.mensajeSpinner = "C a r g a n d o . . .";
    
    setTimeout(() => {
      this.spinner.show('spinnerGrafico');
    });
    this.obtenerFechasDeFormulario();
    this.getAprobacionesPorRango();
  }

  filtrarFacturas() {
    this.facturasFiltradas = this.filtrarFacturasPorNivelPermitidoDeUsuario(this.facturas);

    this.facturasFiltradas = this.facturasFiltradas.filter(a => {
      let agregar = true;
      if (this.busqueda.value) {
        agregar = a.factura.razonSocialCliente.toLowerCase().indexOf(this.busqueda.value.toLowerCase()) > -1;

        agregar = agregar || (this.facturacionService.formatearComprobante(a.factura.prefijo,a.factura.numeroComprobante).indexOf(this.busqueda.value) > -1);

        agregar = agregar || (this.datePipe.transform(a.factura.fechaEmision, "d/MM/y").indexOf(this.busqueda.value) > -1);

        agregar = agregar || (this.tipoMovimientoPipe.transform(a.factura.tipoMovimiento).indexOf(this.busqueda.value) > -1);
      }

      return agregar;
    });
  }

  getAprobacionesPorRango() {
    this.obteniendoAerovales = true;
    this.aerovales = [];
    this.aerovalesFiltrados = [];
    this.spinner.show('spinnerGrafico');
    let desde: string = this.fechaService.convertirDateUtcALocal(this.startDate.getUTCFullYear(), this.startDate.getMonth() + 1, this.startDate.getDate(), 0, 0);
    let hasta: string = this.fechaService.convertirDateUtcALocal(this.endDate.getUTCFullYear(), this.endDate.getMonth() + 1, this.endDate.getDate(), 23, 59);
    this.facturacionService.getRefacturacionesRevisadas(desde, hasta)
      .subscribe(result => {
        //this.aerovales = result.sort((a, b) => new Date(b.fechaHoraFinDespacho).getTime() - new Date(a.fechaHoraFinDespacho).getTime());
        //this.aerovalesFiltrados = result;
        this.facturas = result;
        this.spinner.hide('spinnerGrafico');
        this.obteniendoAerovales = false;
        this.filtrarFacturas();
      }, () => {
        this.errorAerovales = true;
        this.obteniendoAerovales = false;
      });
  }

  validaMotivos(f: RefacturacionFactura, motivos: number[]){
    if( motivos.find(m => m == f.motivo) == null){
      console.log("No se encontro el motivo de la factura " + f.factura.prefijo + "-" + f.factura.numeroComprobante + " (" + f.motivo + ") en los motivos del usuario: " + JSON.stringify(motivos) + ".")
      return false;
    }else{
      return true;
    }
  }

  filtrarFacturasPorNivelPermitidoDeUsuario(facturas: RefacturacionFactura[]) {
    this.mensajeNivelUsuario = null;
    var usuarioNiveles = this.facturacionService.getNivelUsuarioLogueado();

    var totalFacturas = facturas.length;

    var facturasFiltradas = facturas.filter(f => {
    var valorValidarPesos = (f.factura.tipoMoneda == TipoMoneda.PES ? f.valorAAnular : f.valorAAnular * this.cotizacionDolar) ;

    var valorMaximoAprobacionPesos = usuarioNiveles?.nivelNativo? usuarioNiveles?.nivelNativo.montoMaximoAprobacion * this.cotizacionDolar : 0;

    if (usuarioNiveles && valorMaximoAprobacionPesos >= valorValidarPesos) {

      if(this.validaMotivos(f, usuarioNiveles?.nivelNativo.motivos)){
        return true;
      }        
    }

    if (usuarioNiveles) {
      for (let nivelDelegado of usuarioNiveles?.nivelesDelegados) {
        
        var valorMaximoAprobacionPesos = nivelDelegado.montoMaximoAprobacion * this.cotizacionDolar;

        if (valorMaximoAprobacionPesos >= valorValidarPesos) {
          if(this.validaMotivos(f, nivelDelegado.motivos)){
            return true;
          }     
        }
      }
    }

      return false;
    });

    var totalFiltradas = facturasFiltradas.length;

    if (usuarioNiveles == null) {
      this.mensajeNivelUsuario = "Se obtuvieron " + totalFacturas + " registros pero no puede visualizarlos porque no se obtuvo su nivel de usuario para verificar si puede aprobar estos registros.";
    } else {
      if (totalFiltradas < totalFacturas) {
        this.mensajeNivelUsuario = "Podrá trabajar " + totalFiltradas + " registros de un total de " + totalFacturas + " obtenidos. El resto de registros no los puede visualizar porque su nivel de aprobación no lo permite (su monto maximo de aprobación es: USD " + this.calcularMaximoAprobacionNivelUsuario(usuarioNiveles) + ").";
      }
    }

    return facturasFiltradas;
  }

  calcularMaximoAprobacionNivelUsuario(usuarioNiveles) {
    var maximo = 0;

    if(usuarioNiveles?.nivelNativo !=null)
    if (usuarioNiveles && usuarioNiveles.nivelNativo.montoMaximoAprobacion > maximo) {
      maximo = usuarioNiveles.nivelNativo.montoMaximoAprobacion;
    }

    if (usuarioNiveles) {
      for (let nivelDelegado of usuarioNiveles.nivelesDelegados) {
        if (nivelDelegado.montoMaximoAprobacion > maximo) {
          maximo = nivelDelegado.montoMaximoAprobacion;
        }
      }
    }

    return maximo;
  }

  ngOnDestroy() {
    this.change$.unsubscribe();
    this.aerovalesFiltrados = [];
  }

  addValidationError(control: AbstractControl, validationName: string) {
    let errors = control.errors;
    if (errors == null)
      errors = { [validationName]: true };
    else
      errors[validationName] = true;
    control.setErrors(errors);
    control.markAsDirty();
  }

  /**
   * Convierte una NgbDateStruct y una NgbTimeStruct en un Date
   */
  dateFromDateTimeStruct(date: NgbDateStruct, hour: NgbTimeStruct): Date {
    if (date == null || hour == null)
      return null;
    let dateReturn = new Date();
    dateReturn.setDate(date.day);
    dateReturn.setMonth(date.month - 1);
    dateReturn.setFullYear(date.year);
    dateReturn.setHours(hour.hour);
    dateReturn.setMinutes(hour.minute);
    return dateReturn;
  }

  fechaDesdeMayorQueHasta() {
    let esMayor: boolean = false;
    this.startDate = new Date(this.model.year, this.model.month - 1, this.model.day);
    this.endDate = new Date(this.model1.year, this.model1.month - 1, this.model1.day);
    if (this.startDate > this.endDate) { esMayor = true; }
    return esMayor;
  }


  obtenerFechasDeFormulario() {
    this.startDate = new Date(this.model.year, this.model.month - 1, this.model.day);
    this.endDate = new Date(this.model1.year, this.model1.month - 1, this.model1.day);
    this.endDate.setHours(23, 59, 59, 99);
  }

  buscarDesdeHasta() {
    this.mensajeErrorExportar = "";
    this.aerovalesFiltrados = null;
    this.aerovaleActual = null;
    this.mensajeSpinner = "C a r g a n d o . . .";
    this.buscando = true;
    this.obtenerFechasDeFormulario();
    this.getAprobacionesPorRango();

    //this.aerovalesFiltrados.splice(0, this.aerovalesFiltrados.length);

    //this.buscando = false;
  }

  verObservaciones(detalle: string) {
    console.log("El datelle es " + JSON.stringify(detalle));
    let modalRef = this.modalService.open(ModalObservacionRefacturacionComponent, { centered: true, backdrop: 'static' });
    modalRef.componentInstance.detalle = detalle;
  }

  verComparativasFacturas(content, s: RefacturacionFactura, index) {
    this.cargandoPrevisualizacion = true;
    this.itemSeleccionado = index;    
    this.tituloFactura = 'Factura: ' + s.factura.prefijo + '-' + s.factura.numeroComprobante + ' Fecha: ' + new Date().toLocaleDateString();
    this.comprobanteService.obtenerComprobantePorPrefijoNumeroTipo(s.factura.prefijo, s.factura.numeroComprobante, s.factura.tipoMovimiento)
      .subscribe(result => {
        this.facturaObtenida = result;
        if (result != null) {
          this.previsualizacion = false;
          this.facturacionService.getNuevoComprobante(s.id, s.esRefacturacionTotal)
            .subscribe(result => {
              this.facturaCalculada = result;
              this.previsualizacion = true;
              this.itemSeleccionado = -1;
              this.cargandoPrevisualizacion = false;
              const modalRef = this.modalService.open(content, { windowClass: 'custom-modal', centered: true, size: 'lg' });
            }, (err: ErrorModel) =>{
              this.previsualizacion = true;
              this.itemSeleccionado = -1;
              this.cargandoPrevisualizacion = false;
              this.messageService.showErrorMessage('Hubo un error al obtener la comparativa. ' + err.description + ' ' + err.message);
            });
        }
        else {
          this.cargandoPrevisualizacion = false;
          this.itemSeleccionado = -1;
          this.facturaObtenida = null; //se limpia el comprobante.
        }
      },
        err => {
          // Entra aquí si el servicio entrega un código http de error EJ: 404,
          //500
          this.cargandoPrevisualizacion = false;
          this.itemSeleccionado = -1;
          this.facturaObtenida = null; //se limpia el comprobante.
        });
  }


  verComparativasAerovales(content) {
    //this.activeModal.dismissAll();
    let modalRef = this.activeModal.open(content, { size: "lg", centered: true })
    //modalRef.componentInstance.aerovaleDetalle = this.aerovaleDetalle;

  }

  delegarNivelNativoRefacturacion() {
    let modalRef = this.modalService.open(ModalSeleccionarUsuarioComponent, { centered: true });
    modalRef.componentInstance.esDelegacion = true;
    modalRef.componentInstance.nivelAprobacion = this.facturacionService.getNivelUsuarioLogueado() != null ? this.facturacionService.getNivelUsuarioLogueado().nivelNativo : null;
    modalRef.result.then((result: Usuario) => {
      this.messageService.showSuccessMessage("Se delego su nivel correctamente al usuario");
    }, () => { });
  }

  usuarioPuedeDelegar() {
    try {
      var usuarioNiveles = this.facturacionService.getNivelUsuarioLogueado();

      if(usuarioNiveles?.nivelNativo != null)
      {
        //Solo se puede delegar si se tiene nivel nativo de refacturacion
        if (usuarioNiveles && usuarioNiveles.nivelNativo.tipoNivel == tipoNivelUsuarioSeparados.Refacturacion) {
          return true;
        }
      }

      return false;
    } catch (ex) {
      console.log(ex);
      return false;
    }
  }

  limpiarCampos() {
    this.aerovalesFiltrados = [];
    this.spinner.hide("spinnerGrafico");
    this.spinner.hide("spinnerDetalle");
    this.buscando = false;
    this.busquedaFormControl.reset();
    this.model = this.calendar.getToday();
    this.model1 = this.calendar.getToday();

  }

  cambiarDeAeroplanta() {
    this.cambiandoAeroplanta = false;
    this.limpiarCampos();
    this.cambiandoAeroplanta = true;
    this.ngOnInit();
  }

  private addCheckboxes() {
    // reinicio el formulario
    this.tablaComprobantes.setControl('aerovalesFiltrados', this.fb.array([]));
    this.aerovalesFiltrados.map((o, i) => {
      const control = new UntypedFormControl(i === 0); // if first item set to true, else false
      control.setValue(o);
      (this.tablaComprobantes.controls.aerovalesFiltrados as UntypedFormArray).push(control);
    });
  }





  irAlConfirmDialog() {
    let modalRef = this.modalService.open(ModalConfirmComponent, { centered: true, backdrop: 'static' });
    modalRef.componentInstance.mensaje = "¿Desea confirmar?";
    modalRef.result.then((result) => {
      this.messageService.showSuccessMessage('Se refacturó correctamente.');
    }, () => { });
  }

  seleccionarFactura(idRefactura, $event) {
    const indice = this.facturasSeleccionadas.indexOf(idRefactura);
    if (indice != -1) {
      this.facturasSeleccionadas.splice(indice, 1);
    }
    else {
      this.facturasSeleccionadas.push(idRefactura);
    }
    console.log("Facturas seleccionadas " + JSON.stringify(this.facturasSeleccionadas))
  }



  aprobarRefactura() {
    this.aceptandoRefacturacion = true;

    let refacturacion = new RefacturacionFactura();
    let tupla: TuplaRefacturacion[] = [];

    for (var id of this.facturasSeleccionadas) {
      let t = new TuplaRefacturacion();
      t.esRefacturacionTotal = true;
      t.refacturacionFacturaId = id
      tupla.push(t);
    }
    refacturacion.tupla = tupla;
    refacturacion.responsable = this.obtenerStringUsuarioSolicitador();
    refacturacion.estado = 2 // revisado
    refacturacion.estadoFinal = 3 // aprobado
    this.facturacionService.actualizarRefacturacion(refacturacion)
      .subscribe(result => {       
        this.aceptandoRefacturacion = false;
        this.facturasSeleccionadas = [];
        try{
          let modalRef_ = this.activeModal.open(ModalOkComponent, { centered: true, backdrop: 'static' });
          modalRef_.componentInstance.titulo = 'Resultado del proceso de aprobar refacturación:';
          var respuesta = result.split('*|*').join('<br/>');
          modalRef_.componentInstance.mensaje = respuesta;        
        }catch(ex){
          this.messageService.showSuccessMessage("Se ha realizado correctamente el proceso de aprobado de la refacturacion");
        }    
        this.ngOnInit();
      }, (err: ErrorModel) => {
        this.facturasSeleccionadas = [];
        this.messageService.showErrorMessage("Hubo un error al realizar la refacturacion. " + err.description + ' ' + err.message);
        this.aceptandoRefacturacion = false;
      });

  }

  rechazarRefactura() {
    if (this.facturasSeleccionadas.length == 0) {
      this.messageService.showErrorMessage('No se seleccionó ninguna factura para rechazar.');
      return;
    } else {

      const modalRef = this.modalService.open(ModalMotivoRechazoComponent, { size: 'lg', backdrop: 'static' });
      modalRef.result.then((motivo) => {
        if (motivo == null) {
          return;
        }

        this.accionRechazar(motivo);
      }).catch((error) => {
        return;
      });
    }
  }

  accionRechazar(motivo) {
    this.rechazandoRefacturacion = true;

    let refacturacion = new RefacturacionFactura();
    let tupla: TuplaRefacturacion[] = [];

    for (var id of this.facturasSeleccionadas) {
      let t = new TuplaRefacturacion();
      t.esRefacturacionTotal = true;
      t.refacturacionFacturaId = id
      tupla.push(t);
    }
    refacturacion.tupla = tupla;
    refacturacion.responsable = this.obtenerStringUsuarioSolicitador();
    refacturacion.estado = 2 // revisado
    refacturacion.estadoFinal = 4 // rechazado
    refacturacion.motivoRechazo = motivo;
    this.facturacionService.actualizarRefacturacion(refacturacion)
      .subscribe(result => {
        this.facturasSeleccionadas = [];
        this.rechazandoRefacturacion = false;
        this.messageService.showSuccessMessage("Se ha realizado correctamente el aprobado de la refacturacion");
        this.ngOnInit();
      }, (err: ErrorModel) => {
        this.facturasSeleccionadas = [];
        this.messageService.showErrorMessage("Hubo un error al realizar el aprobado de la refacturacion. " + err.description + ' ' + err.message);
        this.rechazandoRefacturacion = false;
      });
  }


  obtenerComprobantePorPrefijoNumeroTipo() {
    //this.mensajeError = "";
    setTimeout(() => {

      this.spinner.show('spinnerGrafico');
      setTimeout(() => {
        /** spinner ends after 2 seconds */
        this.spinner.hide('spinnerGrafico');
      }, 4000);
    });

    this.comprobanteService.obtenerComprobantePorPrefijoNumeroTipo(1, 80, 1)
      .subscribe(result => {
        this.facturaObtenida = result;
        if (result != null) {
          //this.mensajeError = "";
        }
        else {
          this.facturaObtenida = null; //se limpia el comprobante.
          //this.mensajeError = "No se encontraron comprobantes con busqueda seleccionada. " + this.prefijo.value + '-' + this.numero.value;
        }

      },
        err => {
          // Entra aquí si el servicio entrega un código http de error EJ: 404,
          //500
          this.facturaObtenida = null; //se limpia el comprobante.
          //this.mensajeError = "No se encontraron comprobantes con busqueda seleccionada. " + this.prefijo.value + '-' + this.numero.value;
        });

  }

  // getters

  obtenerMotivoRefacturacion(numero: string) {
    return Motivo[numero];
  }

  obtenerEstadoRefacturacion(numero: string) {
    return EstadoRefacturacion[numero];
  }

  obtenerStringUsuarioSolicitador() {
    return this.facturacionService.obtenerStringSolicitador();
  }

  obtenerNuevoComprobante(id: string) {
    this.previsualizacion = false;
    // this.obteniendoFactura = true;
    this.facturacionService.getNuevoComprobante(id, true)
      .subscribe(result => {
        this.facturaCalculada = result;
        //this.spinner.hide('spinnerFactura');
        //this.obteniendoFactura = false;
        this.previsualizacion = true;
      })

  }

  get busqueda() {
    return this.busquedaFormControl;
  }

}