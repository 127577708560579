<div class="modal-header bg-light">
  <h4 class="modal-title" class="text-center"><b>Motivos</b></h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="this.activeModal.dismiss()">
    
  </button>
</div>

<div class="modal-body">
{{this.motivos}}
</div>
