import { ChangeDetectorRef, Component, HostBinding, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbCalendar, NgbDatepickerConfig, NgbDateStruct, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { UsuarioService } from 'src/app/account/usuario.service';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { TipoAerovale } from 'src/app/shared/enums/tipoAerovale';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';
import { FechasService } from 'src/app/shared/fechas.service';
import { Comprobante } from 'src/app/shared/models/facturacion/Comprobante';
import { ComprobanteService } from '../comprobante.service';
import { FacturacionService } from '../facturacion.service';
import { ModalSolicitudRefacturacionManualComponent } from './modal-solicitud-refacturacion-manual/modal-solicitud-refacturacion-manual.component';
import { DatePipe } from '@angular/common';
import { SharedService } from 'src/app/shared/shared.service';
import { TipoMovimientoPipe } from 'src/app/shared/pipes/tipo-movimiento.pipe';


@Component({
  selector: 'app-refacturacion-manual',
  templateUrl: './refacturacion-manual.component.html',
  styleUrls: ['./refacturacion-manual.component.css']
})
export class RefacturacionManualComponent implements OnInit {

  @HostBinding('class.changed')

  TipoUsuario = TipoUsuario;

  tablaComprobantes: UntypedFormGroup;

  facturasFiltradasForm: any[] = [];

  facturas: Comprobante[]=[];
  facturasPP: Comprobante[]=[];
  facturasCC: Comprobante[]=[];
  facturasFiltrados: Comprobante[]=[];

  fechaFormControlDesdeHasta = new UntypedFormControl();
  busquedaFormControl = new UntypedFormControl();

  modelDesde: NgbDateStruct;
  modelHasta: NgbDateStruct;

  startDate: Date;
  endDate: Date;

  buscando: boolean = false;
  obteniendoFacturas = true;

  mensajeSpinner: string = "D e s c a r g a n d o";

  codigoAeroplanta: string;
  cambiandoAeroplanta: boolean = false;

  change$: Subscription;

  tipoNuevoAerovale: TipoAerovale;
  enumTipoAerovale = TipoAerovale;

  constructor(private formateadorDeFechas: NgbDatepickerConfig,
    private usuarioService: UsuarioService,
    private calendar: NgbCalendar,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private fb: UntypedFormBuilder,
    private comprobanteService: ComprobanteService,
    private fechaService:FechasService,
    private changeDetector: ChangeDetectorRef,
    public facturacionService:FacturacionService,
    private authService: AuthService,
    private datePipe: DatePipe,
    private tipoMovimientoPipe: TipoMovimientoPipe,
    sharedService: SharedService) {
      this.change$ = sharedService.aeroplantaSeleccionada.subscribe(_=>{
        this.cambiarDeAeroplanta();
      });
  }

  // hooks

  ngOnInit() {

    this.tablaComprobantes = this.fb.group({
      facturasFiltradasForm: new UntypedFormArray([])
    });
    this.addCheckboxes();
    this.codigoAeroplanta = this.usuarioService.getAeroplantaActual().codigoAeroplanta;
    this.mensajeSpinner = "C a r g a n d o . . .";

    this.modelDesde = this.calendar.getToday();
    this.modelHasta = this.calendar.getToday();
    let inicio: any = { day: 1, month: 1, year: 2019 };
    this.formateadorDeFechas.minDate = inicio;
    let fin: any = { day: this.modelHasta.day, month: this.modelHasta.month, year: this.modelHasta.year };
    this.modelHasta = this.calendar.getToday();
    this.formateadorDeFechas.maxDate = fin;
    setTimeout(() => {
      this.spinner.show('spinnerGrafico');
    });
    this.reinicioFechaInicioFechaFin();
    this.getFacturasCC(this.startDate,this.endDate);
  }

  ngOnDestroy(): void {
    this.change$.unsubscribe();
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  // form

  filtrarFacturas() {
    this.facturasFiltrados = this.facturas.filter(a => {
      let agregar = true;
      if (this.busqueda.value) {
        agregar = a.razonSocialCliente.toLowerCase().indexOf(this.busqueda.value.toLowerCase()) > -1;

        agregar = agregar || (this.facturacionService.formatearComprobante(a.prefijo,a.numeroComprobante).indexOf(this.busqueda.value) > -1);

        agregar = agregar || (this.datePipe.transform(a.fechaEmision, "d/MM/y").indexOf(this.busqueda.value) > -1);

        agregar = agregar || (this.tipoMovimientoPipe.transform(a.tipoMovimiento).indexOf(this.busqueda.value) > -1);

      }
      return agregar;
    });

  }


  fechaDesdeMayorQueHasta() {
    let esMayor: boolean = false;
    this.startDate = new Date(this.modelDesde.year, this.modelDesde.month - 1, this.modelDesde.day);
    this.endDate = new Date(this.modelHasta.year, this.modelHasta.month - 1, this.modelHasta.day);
    if (this.startDate > this.endDate) { esMayor = true; }
    return esMayor;
  }


  reinicioFechaInicioFechaFin() {
    this.startDate = new Date(this.modelDesde.year, this.modelDesde.month - 1, this.modelDesde.day);
    this.endDate = new Date(this.modelHasta.year, this.modelHasta.month - 1, this.modelHasta.day);
    this.endDate.setHours(23, 59, 59, 99);
  }

  buscarDesdeHasta() {
    this.facturasFiltradasForm = null;
    this.mensajeSpinner = "C a r g a n d o . . .";
    this.buscando = true;
    this.reinicioFechaInicioFechaFin();
    this.getFacturasCC(this.startDate,this.endDate);
    this.facturasFiltrados.splice(0,this.facturasFiltrados.length);

  }


  limpiarCampos() {
    this.facturasFiltradasForm = [];
    this.spinner.hide("spinnerGrafico");
    this.spinner.hide("spinnerGrafico");
    this.buscando = false;
    this.busquedaFormControl.reset();
    this.modelDesde = this.calendar.getToday();
    this.modelHasta = this.calendar.getToday();

  }

  cambiarDeAeroplanta() {
    this.cambiandoAeroplanta = false;
    this.limpiarCampos();
    this.cambiandoAeroplanta = true;
    this.ngOnInit();
  }

  private addCheckboxes() {
    // reinicio el formulario
    this.tablaComprobantes.setControl('facturasFiltradas', this.fb.array([]));
    this.facturasFiltrados.map((o, i) => {
      const control = new UntypedFormControl(i === 0); // if first item set to true, else false
      control.setValue(o);
      (this.tablaComprobantes.controls.facturasFiltradas as UntypedFormArray).push(control);
    });
  }

  solicitarRefacturacion(s) {
    let modalRef = this.modalService.open(ModalSolicitudRefacturacionManualComponent, { centered: true, backdrop: 'static' });
    modalRef.componentInstance.factura = s
    modalRef.result.then(() => {
      this.buscarDesdeHasta();
    }, () => { });
  }


  // llamadas al service


  getFacturasCC(fechaInicio: Date, fechaFin: Date) {
    this.obteniendoFacturas = true;
    this.facturasFiltradasForm = [];
    this.spinner.show('spinnerGrafico');
    let desde: string = this.fechaService.convertirDateUtcALocal(fechaInicio.getUTCFullYear(), fechaInicio.getMonth() + 1, fechaInicio.getDate(), 0, 0);
    let hasta: string =  this.fechaService.convertirDateUtcALocal(fechaFin.getUTCFullYear(), fechaFin.getMonth() + 1, fechaFin.getDate(), 23, 59);
    this.comprobanteService.getFacturasCC(2,1,desde,hasta) //Se buscan las factura Cuenta Corriente, TipoPago:2
      .subscribe(result => {
        this.facturasCC= result.sort((a, b) => (b.prefijo + b.numeroComprobante) - (a.prefijo + a.numeroComprobante));

        this.comprobanteService.getFacturasCC(3,1,desde,hasta)//Se buscan las factura PrevioPago, TipoPago:3
        .subscribe(resultPP => {
          this.facturasPP= resultPP.sort((a, b) => (b.prefijo + b.numeroComprobante) - (a.prefijo + a.numeroComprobante));
          //Se concatenan lo dos arreglos obtenidos
          this.facturas = this.facturasCC.concat(this.facturasPP).sort((a, b) => new Date(b.fechaEmision).getTime() - new Date(a.fechaEmision).getTime());
          this.spinner.hide('spinnerGrafico');
          this.obteniendoFacturas = false;
          this.filtrarFacturas();
        }, () => {
          this.obteniendoFacturas = false;
          this.spinner.hide('spinnerGrafico');
        });
      }, () => {
        this.obteniendoFacturas = false;
        this.spinner.hide('spinnerGrafico');
      });
  }

  // getters

  get busqueda() {
    return this.busquedaFormControl;
  }

  obtenerStringUsuarioSolicitador(){
    return this.facturacionService.obtenerStringSolicitador();
  }

  hasRole(...roles: TipoUsuario[]) {
    return this.authService.hasRole(...roles);
  }

}
