import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbCalendar, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProductoService } from 'src/app/abm/producto/producto.service';
import { TipoEstadoPrecio } from 'src/app/shared/enums/tipoEstadoPrecio';
import { Producto } from 'src/app/shared/models/abm/Producto';
import { Aeroplanta } from 'src/app/shared/models/stock/Aeroplanta';
import { BonificacionRecargo } from 'src/app/shared/models/precios/BonificacionyRecargo';
import { MessageService } from 'src/app/shell/message.service';
import { PreciosService } from '../../precios.service';
import { ModalNuevaBorComponent } from '../modal-nueva-bor/modal-nueva-bor.component';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { EstadoPrecio } from 'src/app/shared/models/precios/EstadoPrecio';

import { ModalNuevaByrPorSurtidorComponent } from '../modal-nueva-byr-por-surtidor/modal-nueva-byr-por-surtidor.component';



import { ListaAeroplantasComponent } from 'src/app/shared/lista-aeroplantas/lista-aeroplantas.component';
import { ProductosByrComponent } from './productos-byr/productos-byr.component';
import { ClientesByrComponent } from './clientes-byr/clientes-byr.component';
import { ModalImportarByrComponent } from '../modal-importar-byr/modal-importar-byr.component';
import { TipoBonificacionRecargo } from 'src/app/shared/enums/tipoBonificacionRecargo';
import { MotivosObservacionesComponent } from 'src/app/shared/motivos-observaciones/motivos-observaciones.component';
import { ListaExpendedorasComponent } from 'src/app/shared/lista-expendedoras/lista-expendedoras.component';
import { StockService } from 'src/app/stock/stock.service';
import { TipoVigencia } from 'src/app/shared/enums/tipoVigencia';
import { TipoCantidadPaginado } from 'src/app/shared/enums/TipoCantidadPaginado';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-listado-byr',
  templateUrl: './listado-byr.component.html',
  styleUrls: ['./listado-byr.component.css']
})
export class ListadoByrComponent implements OnInit {
  esperandoCarga: boolean = false;
  messageByR: string = 'C A R G A N D O . . .';
  aeroplantas: Aeroplanta[] = [];
  checkAeroplanta: any[] = [];
  productosParaCheckear: any[] = [];
  productos: Producto[];
  desactivandoBoR: boolean = false;
  seleccionadaBor: BonificacionRecargo;

  vigencias = [
    { nombre: "Vigentes", seleccionado: true },
    { nombre: "Vencidos", seleccionado: false },
    { nombre: "Futuros", seleccionado: false }
  ];
  estados = [
    { nombre: "Pendiente", seleccionado: true },
    { nombre: "Aprobado", seleccionado: true },
    { nombre: "Rechazado", seleccionado: false }
  ];

  searchForm = new UntypedFormGroup({
    busqueda: new UntypedFormControl(''),
  });

  bonificacionyRecargo: BonificacionRecargo[];
  bonificacionyRecargoFiltrado: BonificacionRecargo[];

  modelFecha: NgbDateStruct;
  modelHora: any;

  page = 1;
  pageSize = TipoCantidadPaginado.NORMAL;
  collectionSize = 0;
  actualizandoByr: boolean = false;
  errorByr: boolean = false;

  TipoUsuario=TipoUsuario;
  
  constructor(
    private spinner: NgxSpinnerService, 
    private calendar: NgbCalendar, 
    private preciosService: PreciosService,
    private productoService: ProductoService, 
    private messageService: MessageService, 
    private activeModal: NgbModal,
    private modalService: NgbModal, 
    private stockService: StockService, 
    public authService: AuthService) { }

  ngOnInit() {

    this.modelFecha = this.calendar.getToday();
    var horaActual = new Date();
    this.modelHora = { hour: horaActual.getHours(), minute: horaActual.getMinutes(), second: horaActual.getSeconds() };

    this.getTodasLasAeroplantas();
    this.getTodosLosProductos();
    this.getBonificacionesyRecargosPaginadas(this.page, this.pageSize)
    this.searchForm.valueChanges.subscribe(() => this.filtrarByR());    
  }

  // llamadas al service

  getTodasLasAeroplantas() {
    this.stockService.obtenerAeroplantasStock()
      .subscribe(result => {
        this.aeroplantas = result;
        for (let a of this.aeroplantas) {
          this.checkAeroplanta.push({ "codigo": a.codigoAeroplanta, "seleccionado": false });
        }
      });
  }

  getTodosLosProductos() {
    this.productos = [];
    this.productoService.obtenerProductos()
      .subscribe(result => {
        this.productos = result;
        this.productosParaCheckear = [];
        for (let prod of this.productos) {
          this.productosParaCheckear.push({ "nombre": prod.nombreProducto, "codigo": prod.codigoProducto, "seleccionado": false });
        }
      });
  }
/*
  getBonificacionesyRecargos() {
    this.esperandoCarga = true;
    this.bonificacionyRecargo = [];
    this.bonificacionyRecargoFiltrado = [];
    this.spinner.show('spinnerGrafico');
    this.preciosService.getBonificacionesyRecargos(null)
      .subscribe(result => {

        result.forEach(b => {

          if(b.aprobadoPor && b.aprobadoPor.indexOf(',') > -1) b.aprobadoPor = JSON.parse(b.aprobadoPor.replace(/'/g,'"')).nombresCompletos;

          if(b.solicitadoPor && b.solicitadoPor.indexOf(',') > -1) b.solicitadoPor = JSON.parse(b.solicitadoPor.replace(/'/g,'"')).NombresCompletos;
        });

        this.bonificacionyRecargo = result;
        this.bonificacionyRecargoFiltrado = result;
        this.filtrarByR();
        this.spinner.hide('spinnerGrafico');
        this.esperandoCarga = false;
      }, (err: ErrorModel) => {
        this.esperandoCarga = false;
        this.spinner.hide("spinnerGrafico");
        this.messageService.showErrorMessage("Error al obtener bonificaciónes y recargos.");
      });
  }
*/
  getBonificacionesyRecargosPaginadas(page: number, size: number, actualizacion?: boolean) {

    if (actualizacion){
      this.esperandoCarga = false;
      this.actualizandoByr = true;
    }else{
      this.esperandoCarga = true;
    }

    this.spinner.show('spinnerGrafico');
    this.bonificacionyRecargo = [];
    this.bonificacionyRecargoFiltrado = [];
   
    this.preciosService.getBonificacionesyRecargos( page, size, 
                                                    this.estadosSeleccionado(), 
                                                    this.aeroplantasSeleccionada(), 
                                                    this.productosSeleccionado(), 
                                                    this.vigenciasSeleccionada(),
                                                    this.formatDate(this.modelFecha.year, this.modelFecha.month, this.modelFecha.day, 23, 59),
                                                    null )
      .subscribe(pageList => {

        this.page = pageList.pageNumber;
        this.pageSize = pageList.pageSize;
        this.collectionSize = pageList.totalItemCount;
        var result = pageList.items;

        result.forEach(b => {

          if(b.aprobadoPor && b.aprobadoPor.indexOf(',') > -1) b.aprobadoPor = JSON.parse(b.aprobadoPor.replace(/'/g,'"')).nombresCompletos;

          if(b.solicitadoPor && b.solicitadoPor.indexOf(',') > -1) b.solicitadoPor = JSON.parse(b.solicitadoPor.replace(/'/g,'"')).NombresCompletos;
        });

        if (actualizacion){
          this.actualizandoByr = false;
        }else{
          this.esperandoCarga = false;
        }

        this.bonificacionyRecargo = result;
        this.bonificacionyRecargoFiltrado = result;
        this.spinner.hide('spinnerGrafico');        
      }, (err: ErrorModel) => {
        if (actualizacion){
          this.actualizandoByr = false;
        }else{
          this.esperandoCarga = false;
        }
        this.spinner.hide('spinnerGrafico');  
        this.messageService.showErrorMessage("Error al obtener bonificaciónes y recargos.");
      });
  }

  estadosSeleccionado(){
    var estadosSeleccionados :number[] = [];

    if(this.estados.some(e => e.seleccionado)){
      if(this.estados[0].seleccionado){
        estadosSeleccionados.push(TipoEstadoPrecio.Pendiente);
      }
      if(this.estados[1].seleccionado){
        estadosSeleccionados.push(TipoEstadoPrecio.Aprobado);
      }
      if(this.estados[2].seleccionado){
        estadosSeleccionados.push(TipoEstadoPrecio.Rechazado);
      }

      return estadosSeleccionados;
    }else{
      return null;
    }
  }

  aeroplantasSeleccionada(){
    if(this.checkAeroplanta.some(a => a.seleccionado)){
      return this.checkAeroplanta.filter(a => a.seleccionado).map(a => a.codigo);
    }else{
      return null;
    }
  }

  productosSeleccionado(){
    if(this.productosParaCheckear.some(p => p.seleccionado)){
      return this.productosParaCheckear.filter(p => p.seleccionado).map(p => p.codigo);
    }else{
      return null;
    }
  }
  
  vigenciasSeleccionada(){
    var vigenciasSeleccionadas :number[] = [];

    if(this.vigencias.some(v => v.seleccionado)){
      if(this.vigencias[0].seleccionado){
        vigenciasSeleccionadas.push(TipoVigencia.Vigente);
      }
      if(this.vigencias[1].seleccionado){
        vigenciasSeleccionadas.push(TipoVigencia.Vencido);
      }
      if(this.vigencias[2].seleccionado){
        vigenciasSeleccionadas.push(TipoVigencia.Futuro);
      }

      return vigenciasSeleccionadas;
    }else{
      return null;
    }
  }

  desactivarBoR(seleccion: BonificacionRecargo, i) {

    this.desactivandoBoR = true;
    let listaIdsSeleccionados:string[]=[];
    listaIdsSeleccionados.push(seleccion.id);    

    let estado = new EstadoPrecio();
    estado.responsable = this.preciosService.obtenerStringResponsableV2();
    estado.activo = seleccion.estado.activo == 1 ? 0 : 1;
    estado.estadoPrecio = seleccion.estado.estadoPrecio;

    this.preciosService.cambiarEstadoByR(estado, listaIdsSeleccionados)
      .subscribe(nivelAprobacion => {
        let estadoNuevo = seleccion.estado.activo == 1 ? 0 : 1;
        this.bonificacionyRecargoFiltrado[i].estado.activo = estadoNuevo;
        this.esperandoCarga = false;        
        let estado = seleccion.estado.activo == 1 ? 'activo' : 'desactivo';
        this.messageService.showSuccessLongMessage( "Se "+estado+" la bonificacion y recargo." );
        this.desactivandoBoR = false;
      },
      (err: ErrorModel) => {
        this.desactivandoBoR = false;        
        this.messageService.showErrorMessage( "Error al cambiar estado de bonificación y recargo." );
      });


    /*this.desactivandoBoR = true;
    let estadoPrecioTipo = seleccion.estado.estadoPrecio;
    let activo: number;
    seleccion.estado.activo == 1 ? activo = 0 : activo = 1;

    seleccion.estado = new EstadoPrecio;
    seleccion.estado.activo = activo;
    seleccion.estado.estadoPrecio = estadoPrecioTipo;
    seleccion.estado.responsable = this.preciosService.obtenerStringResponsableV2();
    this.preciosService.putBonificacionesyRecargos(seleccion)
      .subscribe(result => {
        this.emitirCartelActivacion(seleccion.estado.activo, seleccion.tipo);
        this.desactivandoBoR = false;
      }, () => {
        this.messageService.showErrorMessage('Hubo un error al desactivar la bonificación o recargo.');
        this.desactivandoBoR = false;
      });*/
  }


  // form

  filtrarByR() {
    
    this.bonificacionyRecargoFiltrado = this.bonificacionyRecargo.filter(a => {
      let agregar = true;
      if (this.busquedas.value) {

        if (a.nombreCliente != null) {
          agregar = a.nombreCliente && a.nombreCliente.toLocaleLowerCase().indexOf(this.busquedas.value.toLocaleLowerCase()) > -1;
        } else {
          agregar = false;
        }

        agregar = agregar || (a.codigoProducto && a.codigoProducto.toLocaleLowerCase().indexOf(this.busquedas.value.toLocaleLowerCase()) > -1);
        agregar = agregar || ((a.valor).toString() && (a.valor).toString().toLocaleLowerCase().indexOf(this.busquedas.value.toLocaleLowerCase()) > -1);
      }

      return agregar;
    });

  }

  formatDate(year, month, day, hour, minute): string {
    return year + '-' + month.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + '-' + day.toLocaleString(undefined, { minimumIntegerDigits: 2 })
      + 'T' + hour.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + minute.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':00';
  }

  emitirCartelActivacion(activo: number, tipo: number) {
    let cartelActivo: string ="desactivad";
    if (activo===1)
    {
      cartelActivo= "activad";
    }
    if (tipo===0)
    {
      cartelActivo=cartelActivo.concat('a');
      this.messageService.showSuccessLongMessage('La bonificación fue '+cartelActivo +' con exito.');
    }
    else
    {
      cartelActivo=cartelActivo.concat('o');
      this.messageService.showSuccessLongMessage('El recargo fue '+cartelActivo +' con exito.');
    }
  }

  modificacionBoR(i: number) {
    this.seleccionadaBor = this.bonificacionyRecargoFiltrado[i];
    if (this.seleccionadaBor.tipoBonificacionRecargo===0)
    {
      this.modificarBonificacionORecargo();
    }
    else
    {
      this.modificarBonificacionORecargoSurtidor();
    }

  }

  modificarBonificacionORecargo() {
    let modalRef = this.activeModal.open(ModalNuevaBorComponent, { centered: true, backdrop: 'static' });
    modalRef.componentInstance.seleccionadaBoR = this.seleccionadaBor;
    if (this.seleccionadaBor != null) {
      modalRef.result.then((result:BonificacionRecargo) => {
        this.page = 1;
        this.getBonificacionesyRecargosPaginadas(this.page, this.pageSize)        
      }, () => {

      }
      );
    } else {
      modalRef.result.then((result:BonificacionRecargo) => {
        this.page = 1;
        this.getBonificacionesyRecargosPaginadas(this.page, this.pageSize)        
      }, () => {

      }
      );
    }

  }

  modificarBonificacionORecargoSurtidor() {
    let modalRef = this.activeModal.open(ModalNuevaByrPorSurtidorComponent, { centered: true, backdrop: 'static' });
    modalRef.componentInstance.BonifRecargoSeleccionada = this.seleccionadaBor;
    if (this.seleccionadaBor != null) {
      modalRef.result.then((result:BonificacionRecargo) => {
        this.page = 1;
        this.getBonificacionesyRecargosPaginadas(this.page, this.pageSize)        
      }, () => {

      }
      )
    } else {
      modalRef.result.then((result:BonificacionRecargo) => {
        this.page = 1;
        this.getBonificacionesyRecargosPaginadas(this.page, this.pageSize)        
      }, () => {

      }
      )
    }
  }

  agregarBonificacionORecargoProducto(){
    this.seleccionadaBor=null;
    let modalRef = this.activeModal.open(ModalNuevaBorComponent, { centered: true, backdrop: 'static' });
    modalRef.componentInstance.seleccionadaBoR = this.seleccionadaBor;
    if (this.seleccionadaBor != null) {
      modalRef.result.then((result:BonificacionRecargo) => {
        this.page = 1;
        this.getBonificacionesyRecargosPaginadas(this.page, this.pageSize)        
      }, () => {

      }
      );
    } else {
      modalRef.result.then((result:BonificacionRecargo) => {
        this.page = 1;
        this.getBonificacionesyRecargosPaginadas(this.page, this.pageSize)        
      }, () => {

      }
      );
    }

  }

  agregarBonificacionORecargoSurtidor(){
    this.seleccionadaBor=null;
    let modalRef = this.activeModal.open(ModalNuevaByrPorSurtidorComponent, { centered: true, backdrop: 'static' });
    modalRef.componentInstance.BonifRecargoSeleccionada = this.seleccionadaBor;
    if (this.seleccionadaBor != null) {
      modalRef.result.then((result:BonificacionRecargo) => {
        this.page = 1;
        this.getBonificacionesyRecargosPaginadas(this.page, this.pageSize)        
      }, () => {

      }
      )
    } else {
      modalRef.result.then((result:BonificacionRecargo) => {
        this.page = 1;
        this.getBonificacionesyRecargosPaginadas(this.page, this.pageSize)        
      }, () => {

      }
      )
    }
  }

  importarByR() {
    const modalRef = this.activeModal.open(ModalImportarByrComponent, { centered: true, size: "lg",  backdrop: "static" });
    modalRef.result.then((result => {
      this.ngOnInit();
    }))
  }

  onRefreshClick() {
    this.getBonificacionesyRecargosPaginadas(this.page, this.pageSize)        
  }

  verClientes(listaDeClientes)
  {
    let modalRef = this.modalService.open(ClientesByrComponent, { centered: true, backdrop: 'static' });
    modalRef.componentInstance.clientes=listaDeClientes;
    modalRef.result.then(() => {

    }, () => {   });
  }

  verProductos(listaDeProductos)
  {
    let modalRef = this.modalService.open(ProductosByrComponent, { centered: true, backdrop: 'static' });
    modalRef.componentInstance.productos=listaDeProductos;
    modalRef.result.then(() => {

    }, () => {   });
  }

  verAeroplantas(listaDeAeroplantas)
  {
    let modalRef = this.modalService.open(ListaAeroplantasComponent, { centered: true, backdrop: 'static' });

    let lista=this.convertirListaDeCodigosEnListaDeAeroplantas(listaDeAeroplantas);
    modalRef.componentInstance.aeroplantas=lista;
    modalRef.result.then(() => {
    }, () => {   });
  }

  verSurtidores(listaDeSurtidores)
  {
    let modalRef = this.modalService.open(ListaExpendedorasComponent, { centered: true, backdrop: 'static' });

    modalRef.componentInstance.aeroplantas=listaDeSurtidores;
    modalRef.result.then(() => {
    }, () => {   });
  }

  verMotivos(motivos)
  {
    let modalRef = this.modalService.open(MotivosObservacionesComponent, { centered: true, backdrop: 'static' });

    modalRef.componentInstance.motivos=motivos;
    modalRef.result.then(() => {
    }, () => {   });
  }

  convertirListaDeCodigosEnListaDeAeroplantas(lista:string[]): Aeroplanta[]
  {
    let resultado:Aeroplanta[]=[];

    if (lista!=null)
    {
      lista.forEach(codigo => {
        let agregar: Aeroplanta ;
        if(isNaN(Number(codigo))){
          agregar = this.aeroplantas.find(e=>e.codigoAeroplanta===codigo);
        }else{
          agregar = this.aeroplantas.find(e=>e.numeroAeroplanta===codigo);
        }
      
      resultado.push(agregar);
    });

    }
    return resultado;
  }



  // getters

  obtenerLeyendaEstado(estado) {
    if (estado!=null) {
      if (estado.estadoPrecio == TipoEstadoPrecio.Aprobado) {
        return "Aprobado";
      } else if (estado.estadoPrecio == TipoEstadoPrecio.Rechazado) {
        return "Rechazado";
      } else if (estado.estadoPrecio == TipoEstadoPrecio.Pendiente) {
        return "Pendiente";
      }
    }
  }

  obtenerLeyendasBonificacion(bonif: number) {
    if (bonif == 0) {
      return 'Bonificación';
    } else {
      return 'Recargo';
    }
  }

  get busquedas() {
    return this.searchForm.get("busqueda");
  }

  get habilitarBotones() {
    return !this.authService.hasRole(TipoUsuario.COMERCIAL) && (environment.name == 'production') || (environment.name != 'production')
  }

  public onPageChange = (pageNumber) => {
    this.bonificacionyRecargoFiltrado = [];
    this.getBonificacionesyRecargosPaginadas(pageNumber, this.pageSize, true);
  }
}
