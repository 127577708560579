<ul class="nav nav-tabs justify-content-start">
  <li class="nav-item">
    <a class="nav-link" routerLink="reporteCierre" routerLinkActive="active"><b>Reporte cierre</b></a>
  </li>
  <!-- <li class="nav-item">
    <a class="nav-link" *ngIf="this.hasRole(TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.OPERADOR)" routerLink="reporteStock"
      routerLinkActive="active"><b>Reporte Stock</b></a>
  </li>
  <li class="nav-item">
    <a class="nav-link" *ngIf="this.hasRole(TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.OPERADOR)" routerLink="reporteAerovales"
      routerLinkActive="active"><b>Reporte Aerovales</b></a>
  </li> -->
</ul>
<router-outlet></router-outlet>