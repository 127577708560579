<div class="card add-margin">
  <div class="card-header">
    <div class="row">
      <div class="col-sm-10">
        <h4 class="card-title">{{ titulo }}</h4>
      </div>
      <div class="col-sm-2">
      <button class="btn btn-secondary float-end" (click)="cancelar()">Volver</button>
      </div>
    </div>
  </div>
  <loading-text-spinner *ngIf="!this.cargoModalSeleccion && this.tipoModalPedido==TipoPedido.Manual" message="Obteniendo clientes/matriculas..."
    orientation="vertical" class="list-group-item mt-2 mb-2" size="large" color="primary" textColor="dark">
  </loading-text-spinner>
  <div *ngIf="(this.cargoModalSeleccion && this.tipoModalPedido==TipoPedido.Manual) || this.tipoModalPedido!=TipoPedido.Manual" class="card-body">
    <form [formGroup]="pedidosForm">
      <div class="row">
        <!-- cliente -->
        <div class="mb-3 col-md-6">
          <div class="mb-3 row">
            <ng-container *ngIf="!cargandoCuentas && existenCuentasTams">
              <label for="cuentaTamsFormControl" class="col-sm-4 col-form-label">*Cuentas:</label> 
              <div class="col-sm-8">
                <select id="cuentaTamsFormControl" class="form-select" type="text"
                  formControlName="cuentaTamsFormControl"  title="Campo obligatorio, debe seleccionar una cuenta.">
                  <option *ngFor="let ct of cuentasTams" [value]="ct.cuenta" 
                    [selected]="ct.cuenta == cuentaFlag.cuenta">
                    {{ct.razonSocial}} - {{ct.cuenta}}
                  </option>
                </select>
              </div>
            </ng-container>
            <ng-container *ngIf="!cargandoCuentas && !existenCuentasTams">
              <label for="clienteFormControl" class="col-sm-4 col-form-label">*Cliente:</label>
              <div class="col-sm-8" *ngIf="this.tipoModalPedido!=TipoPedido.Alije">
                <div class="input-group mb-2">
                  <input id="clienteFormControl" type="text" class="form-control" formControlName="clienteFormControl"
                    [attr.disabled]="isDisabled ? true : null"
                    [inputFormatter]="formatter"
                    [resultFormatter]="formatter" 
                    [(ngModel)]="modelCliente" 
                    (change)="confirmCustomer()"                    
                    [ngbTypeahead]="search" 
                    (selectItem)="selectCustomer($event)" /> 
                    <ng-container *ngIf="!isDisabled">
                      <div class="input-group-append">
                        <button type="button" class="input-group-btn btn btn-secondary" 
                          (click)="nuevoCliente(modelCliente)"
                          tabindex=-1><i class="fa fa-plus-square" aria-hidden="true"></i></button>
                      </div>                  
                    </ng-container>  
                    <!--<ng-container *ngIf="isDisabled">
                      <div class="input-group-append">
                        <button type="button" class="input-group-btn btn btn-primary" 
                          (click)="seleccionClienteMatricula(TipoSeleccion.Clientes)"
                          tabindex=-1><i class="fa fa-pencil" aria-hidden="true"></i>
                        </button>
                      </div>
                    </ng-container>-->
                </div>
                <loading-text-spinner *ngIf="cargandoClientes" [error]="errorClientes" message="Obteniendo clientes..."
                  color="primary">
                </loading-text-spinner>
                <div *ngIf="cliente.invalid && cliente.errors.required && (cliente.dirty || cliente.touched)"
                  class="alert alert-danger">
                  Requerido
                </div>              
              </div>
            
              <div class="col-sm-8" *ngIf="this.tipoModalPedido==TipoPedido.Alije">
                <div class="input-group mb-2">
                  <input id="clienteAlijeFormControl" readonly type="text" class="form-control" formControlName="clienteAlijeFormControl"/>
                </div>
              </div>
            </ng-container>        
            <loading-text-spinner *ngIf="cargandoCuentas" message="Obteniendo cuentas Tams..."
              color="primary">
            </loading-text-spinner>    
          </div>
        </div>
        <!-- aeronave -->
        <div class="mb-3 col-md-6">
          <div class="mb-3 row">
            <label for="aeronaveFormControl" class="col-form-label col-sm-4"><span *ngIf="this.tipoModalPedido==TipoPedido.Manual">*</span>Aeronave:</label>            
            <div class="col-sm-8">
              <div class="input-group mb-2">
                <ng-template #aeronaveRt let-r="result" let-t="term">
                  <ngb-highlight [result]="r.matricula" [term]="t"></ngb-highlight>
                </ng-template>

                <input id="aeronaveFormControl" type="text" class="form-control" formControlName="aeronaveFormControl"
                  [attr.disabled]="isDisabled ? true : null"
                  [resultTemplate]="aeronaveRt" 
                  [inputFormatter]="formatterAeronave" 
                  maxlength="10"
                  [ngbTypeahead]="searchAeronave"
                  (change)="changeAeronave()" 
                  (selectItem)="selectAeronave($event)" />
                  <ng-container *ngIf="!isDisabled">
                    <div class="input-group-append">
                      <button type="button" class="input-group-btn btn btn-secondary" (click)="nuevoAeronave(modelAeronave)"
                        tabindex=-1><i class="fa fa-plus-square" aria-hidden="true"></i></button>
                    </div>
                  </ng-container>
                  <!--<ng-container *ngIf="isDisabled">
                    <div class="input-group-append">
                      <button type="button" class="input-group-btn btn btn-primary" 
                        (click)="seleccionClienteMatricula(TipoSeleccion.Matriculas)"
                        tabindex=-1><i class="fa fa-pencil" aria-hidden="true"></i>
                      </button>
                    </div>
                  </ng-container>-->
              </div>
              <loading-text-spinner *ngIf="cargandoAeronavesCliente" message="Obteniendo aeronaves del cliente..."
                color="primary" [error]="errorAeronavesCliente">
              </loading-text-spinner>
              <loading-text-spinner *ngIf="cargandoAeronaves" message="Obteniendo aeronaves..."
                color="primary" [error]="errorAeronaves">
              </loading-text-spinner>

              <div *ngIf="aeronave.invalid && aeronave.errors.required && (aeronave.dirty || aeronave.touched)"
                class="alert alert-danger">
                Requerido
              </div>
              <div *ngIf="aeronave.invalid && aeronave.errors.existeAeronave && (aeronave.dirty || aeronave.touched)"
                class="alert alert-danger">
                La aeronave no existe
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="esTrader()" class="row">
        <div class="mb-3 col-md-12">
          <div class="border form-input {{ traderBorderColor }}">
            <div *ngIf="!cargandoAutorizacion; else tCargandoAutorizacion">
              <label *ngIf="autorizacionTrader == null"
                class="col-form-label col-sm-12 text-md-center text-sm-start">Seleccione una aeronave y/o aerocombustible
                para verificar su autorización</label>
              <label *ngIf="autorizacionTrader" class="col-form-label col-sm-12 text-md-center text-sm-start">Trader
                autorizado</label>
              <label *ngIf="autorizacionTrader == false"
                class="col-form-label col-sm-12 text-md-center text-sm-start text-danger">
                Trader no autorizado</label>
            </div>
            <ng-template #tCargandoAutorizacion>
              <div class="col-form-label">
                <loading-text-spinner message="Obteniendo autorización" content-align="center" color="primary"
                  textColor="dark">
                </loading-text-spinner>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
      <div class="row">
        <!-- aerocombustible -->
        <div class="mb-3 col-md-6">
          <div class="mb-3 row">
            <label for="aerocombustibleControlSelect" class="col-sm-4 col-form-label">*Aerocombustible:</label>
            <div class="col-sm-8" *ngIf="this.tipoModalPedido==TipoPedido.Manual">
              <select id="aerocombustibleControlSelect" class="form-select" type="number"
                title="Campo obligatorio, debe seleccionar un aerocombustible."
                formControlName="aerocombustibleFormControl" (change)="changeAerocombustible($event)">
                <option *ngFor="let aerocombustible of aerocombustibles" [ngValue]="aerocombustible">
                  {{aerocombustible.nombreProducto}}</option>
              </select>
              <loading-text-spinner *ngIf="cargandoAerocombustibles" [error]="errorAerocombustibles"
                message="Obteniendo aerocombustibles" color="primary">
              </loading-text-spinner>

              <div *ngIf="aerocombustible.invalid && aerocombustible.errors.required && (aerocombustible.dirty || aerocombustible.touched)"
                class="alert alert-danger">
                Requerido
              </div>
              <div class="alert alert-warning" *ngIf="this.combustibleNoCoincide">
                La abastecedora y el vehiculo tienen distinto producto.
              </div>
            </div>
            <div class="col-sm-8" *ngIf="this.tipoModalPedido!=TipoPedido.Manual">              
                <input id="productoAlijeFormControl" readonly type="text" class="form-control" formControlName="productoAlijeFormControl"/>
            </div>
          </div>
        </div>
        <!-- numero de vuelo -->
        <div class="mb-3 col-md-6">
          <div class="mb-3 row">
            <label for="vueloControlInput" class="col-form-label col-sm-4"><span *ngIf="this.tipoModalPedido!=TipoPedido.Alije">*</span>Número de Vuelo:</label>            
            <div class="col-sm-8">         
              <input [readonly]="this.tipoModalPedido==TipoPedido.Tams" id="vueloControlInput" type="text" class="form-control mb-2 me-sm-2"
                title="Campo obligatorio, debe ingresar un número de vuelo."
                ngbTooltip="Permite FFAA, SANI, PRIV, YPF, GOB." formControlName="numeroVueloFormControl"
                placeholder="XY ####" style="text-transform:uppercase">              
              <div
                *ngIf="numeroVuelo.invalid && numeroVuelo.errors.required && (numeroVuelo.dirty || numeroVuelo.touched)"
                class="alert alert-danger">
                Requerido
              </div>
              <div
                *ngIf="numeroVuelo.invalid && numeroVuelo.errors.pattern && (numeroVuelo.dirty || numeroVuelo.touched)"
                class="alert alert-danger">
                El número de vuelo no tiene el formato correcto
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="mb-3 col-md-6">
          <div class="mb-3 row">  
            <ng-container *ngIf="this.tipoModalPedido!=TipoPedido.Alije">          
              <label class="col-form-label col-sm-4">Tipo de vuelo: </label>
              <section class="btn-group col-sm-8">
                <input type="radio" class="btn-check" name="tipoVueloFormControl" id="tvc" formControlName="tipoVueloFormControl" [value]="1">
                <label class="btn btn-outline-primary" for="tvc">Cabotaje</label>
                <input type="radio" class="btn-check" name="tipoVueloFormControl" id="tvi" formControlName="tipoVueloFormControl" [value]="2">
                <label class="btn btn-outline-primary" for="tvi">Internacional</label>
              </section>
            </ng-container>
          </div>
        </div>

        <div class="mb-3 col-md-6">
          <div class="mb-3 row">
            <label class="col-form-label col-sm-4 text-sm-start">Destino: </label>
            <div class="col-sm-8">
              <ng-template #aeropuertoRt let-r="result" let-t="term">
                <ngb-highlight [result]="r.codigoAeropuerto + ' ' + r.nombre" [term]="t"></ngb-highlight>
              </ng-template>
              <input id="destinoFormControl" type="text" class="form-control" formControlName="destinoFormControl"
                [resultTemplate]="aeropuertoRt" [inputFormatter]="formatterAeropuerto" [ngbTypeahead]="searchAeropuerto"
                placeholder="Destino" (selectItem)="selectAeropuerto($event)" (change)="changeDestino()">
              <loading-text-spinner *ngIf="cargandoAeropuertos" [error]="errorDestinos" message="Obteniendo destinos..."
                color="primary">
              </loading-text-spinner>
              <div *ngIf="this.destinovalid  && (destino.dirty || destino.touched)" class="alert alert-danger">Debe
                seleccionar un destino de la lista.</div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="mostrarRancho" class="row">
        <div class="mb-3 col-md-12">
          <div class="border">
            <div *ngIf="!cargandoRancho; else tCargandoRancho">
              <ng-container *ngIf="rancho; else noVigente">
                <label class="col-form-label col-sm-4 text-md-center text-sm-start">Rancho: <span
                    class="fw-bold">{{rancho.codigoRancho}}</span></label>
                <label class="col-form-label col-sm-4 text-md-center text-sm-start">Vencimiento: <span
                    [ngClass]="{'text-danger': rancho.vencido}">{{ rancho.fechaVencimiento | date: "dd'/'MM'/'y" }}</span>
                </label>
                <label class="col-form-label col-sm-4 text-md-center text-sm-start">Disponible:
                  {{rancho.volumenDisponible | number}}
                  LTS.</label>
              </ng-container>
              <ng-template #noVigente>
                <label class="col-form-label col-sm-12 text-md-center text-sm-start text-danger">
                  No se encontró un rancho vigente</label>
              </ng-template>
            </div>
            <ng-template #tCargandoRancho>
              <div class="col-form-label">
                <loading-text-spinner message="Obteniendo rancho" content-align="center" color="primary">
                </loading-text-spinner>
              </div>
            </ng-template>
          </div>
        </div>
      </div>

      <div class="row">
        <!-- cantidad estimada -->
        <div class="mb-3 col-md-6">
          <div class="mb-3 row">
            <label for="cantidadEstimadaControlInput" class="col-form-label col-sm-4">*Cantidad Estimada:</label>
            <div class="col-sm-6">
              <div class="input-group mb-2">
                <input id="cantidadEstimadaControlInput" type="number" class="form-control"                  
                  formControlName="cantidadEstimadaFormControl" placeholder="Litros"
                  (change)="controlarCantidadEstimada()" (keypress)="onlyNumberKey($event)">
                <span class="input-group-text">Lts.</span>
              </div>
              <div
                *ngIf="this.tipoModalPedido==TipoPedido.Manual && cantidadEstimada.invalid && cantidadEstimada.errors.min && (cantidadEstimada.dirty || cantidadEstimada.touched)"
                class="alert alert-danger">La cantidad debe ser mayor a 0
              </div>
              <div
                *ngIf="this.tipoModalPedido==TipoPedido.Tams && cantidadEstimada.invalid && cantidadEstimada.errors.min && (cantidadEstimada.dirty || cantidadEstimada.touched)"
                class="alert alert-danger">La cantidad debe ser mayor/igual a 0
              </div>
              <div
                *ngIf="this.tipoModalPedido==TipoPedido.Alije && !controlarCantidadEstimada() && (cantidadEstimada.dirty || cantidadEstimada.touched)"
                class="alert alert-danger">La cantidad no puede superar el saldo disponible</div>
              <div
                *ngIf="cantidadEstimada.invalid && cantidadEstimada.errors.required && (cantidadEstimada.dirty || cantidadEstimada.touched)"
                class="alert alert-danger">Requerido</div>
            </div>
          </div>
        </div>
        
        <!-- posicion -->
        <div class="mb-3 col-md-6">
          <div class="mb-3 row">
            <label for="posicionFormControl" class="col-form-label col-sm-4">Posición:</label>
            <div class="col-sm-8">
              <input id="posicionFormControl" type="text" class="form-control" formControlName="posicionFormControl"
                ngbTooltip="Solo permite Pista o Hangar" placeholder="Pista / Hangar">
              <div *ngIf="posicion.invalid && posicion.errors.pattern && (posicion.dirty || posicion.touched)"
                class="alert alert-danger">
                La posición no tiene el formato correcto.
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div class="row">
        <!-- fecha y hora estimada  -->
        <div class="mb-3 col-md-6">          
          <div class="mb-3 row">
            <label for="fechaFormControl" class="col-sm-4 col-form-label" style="display: block;">
              *Fecha y hora estimada de llegada del avión:
              <br />
              <span *ngIf="!fechayhoraArriboTams" style="color: red; display: block; font-size: 14px;">Atención: No se podrán crear predespachos antes
                de esta hora.</span>
              <span *ngIf="fechayhoraArriboTams" style="color: green; display: block; font-size: 14px;">Atención: Fecha y hora de arribo vuelo Tams.</span>                
            </label>
            <div *ngIf="!cargandoFechas" #fechaHora class="col-sm-7">
              <div class="input-group mb-2">
                <input type="date" class="form-control" formControlName="fechaFormControl" placeholder="aaaa-mm-dd"
                  name="dp" title="Campo obligatorio." [(ngModel)]="model" ngbDatepicker #d="ngbDatepicker"
                  readonly>                  
                <div class="input-group-append">
                  <button type="button" class="input-group-btn btn btn-secondary" (click)="d.toggle()"><i
                      class="fa fa-calendar" aria-hidden="true"></i></button>
                </div>
              </div>
              <ngb-timepicker [(ngModel)]="time" [meridian]="true" formControlName="horaFormControl"
                (ngModelChange)="validarFecha(time)"></ngb-timepicker>                
              <div *ngIf="(fecha.invalid && fecha.errors.fechaFutura && (fecha.dirty || fecha.touched))"
                class="alert alert-danger">La fecha y hora estimados deben ser posterior a la actual</div>
              <div *ngIf="(fecha.invalid && fecha.errors.fechaValida && (fecha.dirty || fecha.touched))"
                class="alert alert-danger">La fecha y hora no son válidas</div>
            </div>
            <loading-text-spinner *ngIf="cargandoFechas" [error]="errorAerocombustibles"
              message="Cargando fecha arribo" color="primary">
            </loading-text-spinner>
          </div>          
        </div>
        
        <!-- fecha y hora de vuelo -->
        <div class="mb-3 col-md-6">                     
          <div class="mb-3 row">
            <label for="fechaVueloFormControl" class="col-sm-4 col-form-label">*Fecha y hora del vuelo: </label>            
            <div *ngIf="!cargandoFechas" #fechaHoraVuelo class="col-sm-7">
              <div class="input-group mb-2">
                <input type="date" class="form-control" formControlName="fechaVueloFormControl" placeholder="aaaa-mm-dd"
                  name="dp" title="Campo obligatorio." [(ngModel)]="modelVuelo" ngbDatepicker #dv="ngbDatepicker"
                  readonly>
                <div class="input-group-append">
                  <button type="button" class="input-group-btn btn btn-secondary" (click)="dv.toggle()"><i
                      class="fa fa-calendar" aria-hidden="true"></i></button>
                </div>
              </div>
              <ngb-timepicker [(ngModel)]="timeVuelo" [meridian]="true" formControlName="horaVueloFormControl"
                (ngModelChange)="validarFecha(timeVuelo)"></ngb-timepicker>
              <div *ngIf="
                      (fechaVuelo.invalid && fechaVuelo.errors.fechaVuelo && (fechaVuelo.dirty || fechaVuelo.touched))"
                class="alert alert-danger">La fecha y hora del vuelo debe ser posterior a la estimada</div>
              <div
                *ngIf="(fechaVuelo.invalid && fechaVuelo.errors.fechaValida && (fechaVuelo.dirty || fechaVuelo.touched))"
                class="alert alert-danger">La fecha y hora no son válidas</div>
            </div>
            <loading-text-spinner *ngIf="cargandoFechas" [error]="errorAerocombustibles"
              message="Cargando fecha vuelo" color="primary">
            </loading-text-spinner> 
          </div>
        </div>
      </div>

      <small class="text-danger">*Campos obligatorios.</small>

      <div  class="card-footer d-flex justify-content-center">
        <span class="border">
          <button [disabled]="guardandoPedido || pedidosForm.invalid || !noEstaCargandoInfo()" class="btn btn-primary" type="button" (click)="cargarPedido()">
            <span *ngIf="!guardandoPedido">Guardar</span>
            <div *ngIf="guardandoPedido" class="d-flex align-items-center">
              <div class="spinner-border text-light spinner-border-sm" role="status"></div>
              <span>Guardando...</span>
            </div>
          </button>
        </span>
        <span class="border">
          <button type="button" class="btn btn-secondary" [disabled]="guardandoPedido"
            (click)="cancelar()">Cancelar</button>
        </span>
      </div>
    </form>
  </div>
</div>
