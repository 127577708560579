<div class="" *ngIf="!hayComparativas">
  <div class="card">
    <div class="card-header">
      <app-cierre-nav-bar></app-cierre-nav-bar>
    </div>

    <div class="card-body">
      <div class="col-12">
        <div class="row">
          <div class="spinner-container" *ngIf="!this.terminoCarga">
            <ngx-spinner name="spinnerCargaDatos" bdOpacity=0.3bd
              Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
              <p style="color: white">{{message}}</p>
            </ngx-spinner>
          </div>
          <div class="col-8" *ngIf="this.terminoCarga">
            <label class="col-form-label"><b>Mediciones de tanques</b></label>
            <table id="tabla" class="table table-sm table-striped table-hover header_fijo">
              <thead class="text-center">
                <tr>
                  <th>Tanque</th>
                  <th>Producto</th>
                  <th>Cant. Natural</th>
                  <th>Cant. 15°</th>
                  <th>Varillado(mm)</th>
                  <th>Temperatura</th>
                  <th>Densidad</th>
                </tr>
              </thead>
              <tbody [formGroup]="tablaTanques">
                <tr class="text-center" *ngFor="let tanque of cierreRealizoContinuado.medicionesTanques; let i = index">
                  <td>{{tanque.nombreTanque}}</td>
                  <td>{{tanque.codigoProducto}}</td>
                  <td>
                    {{tanque.desactivarTanque ? 0 : tanque.nivelNatural | number: '1.0-0'}}
                    <div *ngIf="tanque.desactivarTanque" class="dialog-error alert-danger" role="alert">
                      Tanque {{tanque.nombreTanque}} inactivo - Niveles en Cero
                    </div>
                  </td>
                  <td><span *ngIf="tanque.roleStatus" class="spinner-border text-dark spinner-border-sm" role="status"></span>{{tanque.desactivarTanque ? 0 : tanque.nivel | number: '1.0-0'}}</td>
                  <td>
                    <input class="form-control" (keyup)="changeValue(i, 'varillado', $event)"
                      (keypress)="this.onlyDecimalPositivo($event)"
                      [value]="this.cierreRealizoContinuado.medicionesTanques[i].varillado" maxlength="10"
                      [disabled]="tanque.desactivarUI || tanque.desactivarTanque">
                  </td>
                  <td>
                    <input class="form-control" (keyup)="changeValue(i, 'temperatura', $event)"
                      (keypress)="this.onlyDecimalPositivo($event)" 
                      [value]="this.cierreRealizoContinuado.medicionesTanques[i].temperatura"
                      [disabled]="tanque.desactivarUI || tanque.desactivarTanque">
                    <div class="alert alert-danger letra"
                      *ngIf="!validarTemperatura(this.cierreRealizoContinuado.medicionesTanques[i].temperatura)">
                      La temperatura no puede ser mayor a 100° ó menor a -50°.
                    </div>
                  </td>
                  <td>
                    <input class="form-control" (keyup)="changeValue(i, 'densidad', $event)"
                    (keypress)="formatDensidad($event, i)"
                    [value]="this.cierreRealizoContinuado.medicionesTanques[i].densidadString == null ? '' : this.cierreRealizoContinuado.medicionesTanques[i].densidadString" maxlength="10" 
                    [disabled]="tanque.desactivarUI || tanque.desactivarTanque">
                  <div class="alert alert-danger letra"
                    *ngIf="!validarDensidad(this.cierreRealizoContinuado.medicionesTanques[i])">
                    La densidad debe estar dentro del rango del producto. [{{this.productoSeleccionado?.densidadMin}}] - [{{this.productoSeleccionado?.densidadMax}}]
                  </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <ngb-alert *ngIf="this.noPuedeContinuar && !this.esperandoCarga">Falta la graduación en los tanques:
              {{mensajeFaltaGraduacion}}
            </ngb-alert>
          </div>
          <div class="col-4" *ngIf="this.terminoCarga">
            <label class="col-form-label"><b>Conteo de aeroespecialidades</b></label>
            <table style="width:100%" id="tabla" class="table table-sm table-striped table-hover header_fijo" *ngIf="!this.esperandoCarga && cierreRealizoContinuado.aeroespecialidades.length > 0">
              <thead class="text-center">
                <tr>
                  <th>Codigo</th>
                  <th>Producto</th>
                  <th>Cantidad</th>
                </tr>
              </thead>
              <tbody class="text-center">
                <tr *ngFor="let aeroespecialidad of cierreRealizoContinuado.aeroespecialidades; let i = index">
                  <td>{{aeroespecialidad.codigoAeroespecialidad}}</td>
                  <td>{{aeroespecialidad.nombreAeroespecialidad}}</td>
                  <td>
                    <input class="form-control" appOnlyNumber size="1"
                      (keyup)="changeValue2(i, 'stock', $event)" [value]="aeroespecialidad.stock">
                  </td>
                </tr>
              </tbody>
            </table>
            <ngb-alert *ngIf="!this.esperandoCarga && cierreRealizoContinuado.aeroespecialidades.length == 0">No hay aeroespecialidades en la aeroplanta
            </ngb-alert>
          </div>
        </div>
      </div>
    </div>

    <div class="card-footer">
      <div class="float-end">
        <!-- <button style="margin-right: 4em;" class="btn btn-primary" type="button" (click)="this.anterior()">Anterior</button> -->
        <button class="btn btn-primary" type="button" (click)="this.confirmarDatos()"
          [disabled]="noPuedeContinuar || guardando || esperandoCarga">
          <span *ngIf="!guardando">Cargar datos</span>
          <div *ngIf="guardando" class="d-flex align-items-center">
            <div class="spinner-border text-light spinner-border-sm" role="status"></div>
            <span>Cargando...</span>
          </div></button>
      </div>
    </div>

  </div>
</div>

<div *ngIf="this.hayComparativas">
  <app-comparativas-cierre-turno [cuadroComparativas]="cuadroComparativas" [cierreTurnoContinuo]="cierreRealizoContinuado"></app-comparativas-cierre-turno>
</div>