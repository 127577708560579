import { Component, forwardRef, OnDestroy, Output, EventEmitter, Host, Input } from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  UntypedFormGroup,
  UntypedFormBuilder,
  ControlValueAccessor,
  Validators,
  NG_VALIDATORS,
  UntypedFormControl
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { Laboratorio } from 'src/app/shared/models/despacho/Laboratorio';
import { LaboratorioService } from '../../Laboratorio.service';
import { ResultadoPruebaDeAgua } from 'src/app/shared/enums/resultadoPruebaDeAgua';
import { ResultadoPruebaLab } from 'src/app/shared/enums/resultadoPruebaLab';
import { Producto } from 'src/app/shared/models/despacho/Producto';
import { ProductoService } from 'src/app/abm/producto/producto.service';
import { SharedService } from 'src/app/shared/shared.service';
import { CalculosService } from 'src/app/shared/calculos.service';

export interface AerovaleAlijeFormValues {
  aforadorInicialFormControl: string;
  aforadorFinalFormControl: string;
  cantidadAlijeFormControl: string;
  cantidadAlije15FormControl: string;
}

@Component({
  selector: 'aerovale-alije-form',
  templateUrl: './aerovale-alije.component.html',
  styleUrls: ['./aerovale-alije.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AerovaleAlijeComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AerovaleAlijeComponent),
      multi: true
    }
  ]
})

export class AerovaleAlijeComponent implements ControlValueAccessor, OnDestroy {
  [x: string]: any;

  enumResultadoPruebaDeAgua = ResultadoPruebaDeAgua;
  enumResultadoPruebaLaboratorio = ResultadoPruebaLab;

  @Output() formularioValido = new EventEmitter();
  @Output() objetAlijeForm = new EventEmitter();
  @Output() envioDensidad = new EventEmitter();
  @Input() esValidaDensidad: boolean;
  @Input() esDensidadMin: any;
  @Input() esDensidadMax: any;

  form: UntypedFormGroup;
  subscriptions: Subscription[] = [];

  laboratorios: Laboratorio[];
  cargandoLaboratorios: boolean;
  errorLaboratorios: boolean;
  modelHoraInicioAlije: any;
  modelHoraFinAlije: any;

  productos: Producto[] = [];
  productoSeleccionado: Producto;  

  modelFechaPruebaLaboratorio: NgbDateStruct;
  modelFechaInicioAlije: NgbDateStruct;
  modelFechaFinAlije: NgbDateStruct;
  
  constructor(
    private formBuilder: UntypedFormBuilder,
    private calendar: NgbCalendar,
    private laboratorioService: LaboratorioService,
    private productoService: ProductoService,
    private sharedService: SharedService,
    private calculoService: CalculosService) {

  }
 
  ngOnInit() {
    this.crearFormulario();

    this.inicializarComponente();   
    
    this.getLaboratorios();
  }

  crearFormulario(){
    this.form = this.formBuilder.group(
      {
        laboratorioFormControl: ['', Validators.required],
        fechaPruebaLaboratorioFormControl: ['', Validators.required],
        fechaInicioAlijeFormControl: ['', Validators.required],
        horaInicioAlijeFormControl: ['', Validators.required],
        fechaFinAlijeFormControl: ['', Validators.required],
        horaFinAlijeFormControl: ['', Validators.required],
        resultadoPruebaLaboratorioFormControl: ['', [Validators.required, this.pruebaDeLaboratorioValidator]], 
        varilladoInicialFormControl: ['', Validators.required],
        varilladoFinalFormControl: ['', [Validators.required, Validators.min(1)]],
        cantidadAlijeFormControl: ['', Validators.required],
        cantidadAlije15FormControl: ['', Validators.required],
        aforadorInicialFormControl: ['', Validators.required],
        aforadorFinalFormControl: ['', Validators.required],        
        temperaturaFormControl: ['', [Validators.required, this.temperaturaValidator]],
        densidadFormControl: ['', Validators.required], // entre valores
        resultadoPruebaDeAguaFormControl: ['', [Validators.required, this.pruebaDeAguaValidator]],           
      }
    );

    this.objetAlijeForm.emit(this.form);
  }

  inicializarComponente(){
    this.modelFechaPruebaLaboratorio = this.calendar.getToday();

    this.modelFechaInicioAlije = this.calendar.getToday();
    this.modelFechaFinAlije = this.calendar.getToday();

    var horaActual = new Date();
    this.modelHoraInicioAlije = { hour: horaActual.getHours(), minute: horaActual.getMinutes(), second: horaActual.getSeconds() };
    this.modelHoraFinAlije = { hour: horaActual.getHours(), minute: horaActual.getMinutes(), second: horaActual.getSeconds() };

    this.subscriptions.push(
      this.form.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      })
    );
    
    this.form.valueChanges.subscribe(val => {
      this.formularioValido.emit(this.form.valid);
    });

    this.form.setValidators(this.aerovaleValidator);
  }

  aerovaleValidator(group: UntypedFormGroup): any{
    if (group) {
      let varilladoInicial = group.get("varilladoInicialFormControl").value;
      let varilladoFinal = group.get("varilladoFinalFormControl").value;

      if(varilladoInicial != null && varilladoFinal != null){
        if( varilladoInicial >= varilladoFinal ){
          return { varilladoInicialMayorVarilladoFinal: true };
        }
      }

      var ahora = new Date();     

      let fechaInicio = group.get("fechaInicioAlijeFormControl").value;
      let horaInicio = group.get("horaInicioAlijeFormControl").value;

      if(fechaInicio && horaInicio){
        var fecha = new Date(fechaInicio.year, fechaInicio.month-1,  fechaInicio.day, horaInicio.hour, horaInicio.minute);
        if(fecha > ahora){
          return { fechaInicioMayorAhora: true };
        }
      }  

      let fechaFin = group.get("fechaFinAlijeFormControl").value;
      let horaFin = group.get("horaFinAlijeFormControl").value;

      if(fechaFin && horaFin){
        var fecha = new Date(fechaFin.year, fechaFin.month-1,  fechaFin.day, horaFin.hour, horaFin.minute);
        if(fecha > ahora){
          return { fechaFinMayorAhora: true };
        }
      }  
  
      if(fechaInicio && horaInicio && fechaFin && horaFin){
        var inicio = new Date(fechaInicio.year, fechaInicio.month-1,  fechaInicio.day, horaInicio.hour, horaInicio.minute);
        var fin = new Date(fechaFin.year, fechaFin.month-1,  fechaFin.day, horaFin.hour, horaFin.minute);
        if(inicio >= fin){
          return { fechaInicioMayorFin: true };
        }
      }  

      return null;
    }
  }

  formatDensidad(event) {
    if (event.charCode == 8 || event.charCode == 0) return null;
  
    if (event.charCode >= 48 && event.charCode <= 57) {
      this.densidadFormControl.setValue(this.sharedService.formatDensidad(event));
      this.validarDensidad(this.densidadFormControl.value);
    }
    
    return false;
  }

  validarDensidad(densidad) {
    if (densidad != null) {
      this.envioDensidad.emit(densidad);
    }
  }

  pruebaDeLaboratorioValidator(control: UntypedFormControl) { (1)
    if(control.value == ResultadoPruebaLab.Aprobado){
      return null;
    }
    
    return { aprobadoRequired: true };
  }

  pruebaDeAguaValidator(control: UntypedFormControl) {
    if(control.value == ResultadoPruebaDeAgua.Negativa){
      return null;
    }
    
    return { negativoRequired: true };
  }

  temperaturaValidator(control: UntypedFormControl){
    var temperatura = control.value;

    if (temperatura > 100) {
      return { temperaturaFueraDeRango: true };
    }

    if (temperatura <= -50) {
      return { temperaturaFueraDeRango: true };
    }

    return null;
  }

  getLaboratorios(): void {
    this.cargandoLaboratorios = true;
    this.laboratorioService.getLaboratorios()
      .subscribe(laboratorios => {
        laboratorios = laboratorios.map(l => Object.assign(new Laboratorio(), l));
        this.laboratorios = laboratorios;
        this.cargandoLaboratorios = false;
      }, () => this.errorLaboratorios = true);
  }
 
  

  obtenerCantidadAlijada() {
    let res: number = this.varilladoFinalFormControl.value - this.varilladoInicialFormControl.value;
    if (this.varilladoFinalFormControl.value < this.varilladoInicialFormControl.value) { res = 0; }
    this.cantidadAlijeFormControl.setValue(res);    
  }

  obtenerCantidadAlijada15() {
    let res: number = this.calculoService.convertirLitrosNaturalesEn15Grados(this.temperaturaFormControl.value, this.densidadFormControl.value, this.varilladoInicialFormControl.value, this.varilladoFinalFormControl.value);
    if(!isNaN(res)){
      this.cantidadAlije15FormControl.setValue(res);
    }else{
      this.cantidadAlije15FormControl.setValue(0);
    }
    return res;    
  }

  keysResultadosPruebaLaboratorio(): Array<string> {
    var keys = Object.keys(this.enumResultadoPruebaLaboratorio);
    return keys.slice(keys.length / 2);
  }

  esCampoVacio(campo: any): boolean {
    return (campo == null || (campo != null && campo.length == 0));
  }

  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }
  
  onlyDecimalPositivo(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46;
  }
  
  /* -------------------------------------------------------------------------------------------------------- */

  get laboratorioFormControl() {
    return this.form.controls.laboratorioFormControl;
  }

  get fechaPruebaLaboratorioFormControl() {
    return this.form.controls.fechaPruebaLaboratorioFormControl;
  }

  get fechaInicioAlijeFormControl() {
    return this.form.controls.fechaInicioAlijeFormControl;
  }
  get horaInicioAlijeFormControl() {
    return this.form.controls.horaInicioAlijeFormControl;
  }

  get fechaFinAlijeFormControl() {
    return this.form.controls.fechaFinAlijeFormControl;
  }

  get horaFinAlijeFormControl() {
    return this.form.controls.horaFinAlijeFormControl;
  }

  get resultadoPruebaLaboratorioFormControl() {
    return this.form.controls.resultadoPruebaLaboratorioFormControl;
  }

  get varilladoInicialFormControl() {
    return this.form.controls.varilladoInicialFormControl;
  }

  get varilladoFinalFormControl() {
    return this.form.controls.varilladoFinalFormControl;
  }

  get aforadorInicialFormControl() {
    return this.form.controls.aforadorInicialFormControl;
  }

  get aforadorFinalFormControl() {
    return this.form.controls.aforadorFinalFormControl;
  }

  get cantidadAlijeFormControl() {
    return this.form.controls.cantidadAlijeFormControl;
  }

  get temperaturaFormControl() {
    return this.form.controls.temperaturaFormControl;
  }

  get densidadFormControl() {
    return this.form.controls.densidadFormControl;
  }

  get resultadoPruebaDeAguaFormControl() {
    return this.form.controls.resultadoPruebaDeAguaFormControl;
  }

  get cantidadAlije15FormControl() {
    return this.form.controls.cantidadAlije15FormControl;
  }

  /* -------------------------------------------------------------------------------------------------------- */

  get value(): AerovaleAlijeFormValues {
    return this.form.value;
  }

  set value(value: AerovaleAlijeFormValues) {
    this.form.setValue(value);
    this.onChange(value);
    this.onTouched();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }

    if (value === null) {
      this.form.reset();
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  validate(_: UntypedFormControl) {
    return this.form.valid ? null : { aerovaleAlije: { valid: false } };
  }
}
