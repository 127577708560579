import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-holdeo-principal',
  templateUrl: './holdeo-principal.component.html',
  styleUrls: ['./holdeo-principal.component.css']
})
export class HoldeoPrincipalComponent implements OnInit {

  tipoHoldeo: number = 4;

  constructor() { }

  ngOnInit() {
  }

  abrirTipoHoldeo(i: number){
    this.tipoHoldeo = i;
  }

  volver(){
    this.tipoHoldeo = 4;
  }

}
