<div class="card" id="RealizarCierre-card">
  <div class="card-header">
    <div class="row">
      <label><b class="text-start">Reimputador de documentos.</b></label>
    </div>
  </div>
  <div class="card-body">
    <div class="col-12">
      <div class="spinner-container" *ngIf="!this.terminoCarga">
        <ngx-spinner name="spinnerLista" bdOpacity=0.3bd
          Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
          <p style="color: white">C A R G A N D O . . .</p>
        </ngx-spinner>
      </div>
      <div class="row" *ngIf="this.terminoCarga" [formGroup]="cierreTurnoForm">
        <div class="mb-3 col-5">
          <label><b>Cierre de turno</b></label>
          <select *ngIf="this.cierresDeTurnoAbierto != null && this.cierresDeTurnoCerrado != null" class="form-select"
            formControlName="cierreTurnoFormControl" (change)="limpiarSeleccionados()">
            <option [value]="0" [selected]="true">{{cierresDeTurnoAbierto.numero}} - Abierto
            </option>
            <option [value]="1">{{cierresDeTurnoCerrado.numero}} - Cerrado
            </option>
          </select>
          <ngb-alert *ngIf="this.cierresDeTurnoAbierto == null && !this.obteniendoCierre && !this.obteniendoCierre2"
            class="alert alert-warning">No existe un cierre abierto al cual reimputar los documentos</ngb-alert>
          <ngb-alert *ngIf="this.cierresDeTurnoCerrado == null && !this.obteniendoCierre && !this.obteniendoCierre2"
            class="alert alert-warning">No existe un cierre cerrado al cual reimputar los documentos</ngb-alert>
        </div>
        <div class="col-7">
          <label><b>Contenedor:</b></label>
          <select *ngIf="this.contenedores.length > 0" class="form-select" formControlName="contenedorFormControl"
            (change)="this.filtrarPorContenedor()">
            <option *ngFor="let con of contenedores" [ngValue]="con">{{con.codigo}}-{{con.nombre}}</option>
          </select>
          <ngb-alert class="alert alert-warning" *ngIf="this.contenedores.length == 0">No existen contenedores en la
            aeroplanta seleccionada</ngb-alert>
        </div>
        <table class="table table-striped table-hover text-center"
          *ngIf="this.cierreTurnoFormControl.value == 0 && !this.buscandoDetalle">
          <thead *ngIf="movimientosCierreAbierto != null">
            <tr>
              <th>Producto</th>
              <th>Fecha/Hora</th>
              <th>Entrada</th>
              <th>Entrada 15°</th>
              <th>Salida</th>
              <th>Salida 15°</th>
              <th>stock</th>
              <th>stock 15°</th>
              <th>Ver comprobante</th>
              <th>Título</th>
              <th>Seleccionar</th>
            </tr>
          </thead>
          <tbody *ngIf="movimientosCierreAbiertoFiltrado != null && this.cierreTurnoFormControl.value == 0">
            <tr *ngFor="let mov of movimientosCierreAbiertoFiltrado; let i = index">
              <td>{{mov.codigoProducto}}-{{mov.nombreProducto}}</td>
              <td>{{mov.fechaHora | date: "d'/'MM'/'y' 'hh:mm a"}}</td>
              <td>{{mov.entradaNatural? mov.entradaNatural : 0}}</td>
              <td>{{mov.entrada15? mov.entrada15 : 0}}</td>
              <td>{{mov.salidaNatural? mov.salidaNatural : 0}}</td>
              <td>{{mov.salida15? mov.salida15 :0}}</td>
              <td>{{mov.stock? mov.stock : 0}}</td>
              <td>{{mov.stock15? mov.stock15 : 0}}</td>
              <td *ngIf="mov.comprobante == null">-----</td>
              <td *ngIf="mov.comprobante != null">
                <ng-container *ngIf="mov.comprobante.remitoSalida != null">
                  <button *ngIf="!mov.abrirMovimiento" class="btn btn-primary" (click)="verRemitoSalida(mov, 2)"><i
                      class="fa fa-eye"> Remito salida</i></button>
                  <button *ngIf="mov.abrirMovimiento" class="btn btn-primary mt-2 mb-2" type="button">
                    <div class="spinner-border text-light spinner-border" role="status"></div> Remito salida
                  </button>
                </ng-container>
                <ng-container *ngIf="mov.comprobante.remito != null">
                  <button *ngIf="!mov.abrirMovimiento" class="btn btn-primary" (click)="verAerovale(mov)"><i
                      class="fa fa-eye"> Ver aerovale</i></button>
                  <button *ngIf="mov.abrirMovimiento" class="btn btn-primary mt-2 mb-2" type="button">
                    <div class="spinner-border text-light spinner-border" role="status"></div> Ver aerovale
                  </button>
                </ng-container>
                <ng-container *ngIf="mov.comprobante.remitoCargamento != null">
                  <button class="btn btn-primary" *ngIf="!mov.abrirMovimiento" (click)="verRemitoCargamento(mov, 1)"><i
                      class="fa fa-eye"> Remito cargamento</i></button>
                  <button *ngIf="mov.abrirMovimiento" class="btn btn-primary mt-2 mb-2" type="button">
                    <div class="spinner-border text-light spinner-border" role="status"></div> Remito cargamento
                  </button>
                </ng-container>
                <ng-container *ngIf="mov.comprobante.remitoCargamento == null && mov.comprobante.remito == null && mov.comprobante.remitoSalida == null">
                  -----
                </ng-container>
              </td>
              <td>{{mov.titulo}}</td>
              <td>
                <div class="form-check">
                  <input type="checkbox" class="custom-control-input" id="customCheck{{i}}"
                    (click)="agregarMovimiento(i, mov)" [checked]="mov.booleanAux">
                  <label class="custom-control-label" for="customCheck{{i}}"></label>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <ngb-alert class="alert alert-warning"
          *ngIf="this.movimientosCierreAbiertoFiltrado.length == 0 &&  this.cierreTurnoFormControl.value == 0">
          No hay movimientos para el turno abierto y contenedor seleccionado
        </ngb-alert>
        <table class="table table-striped table-hover text-center"
          *ngIf="movimientosCierreCerradoFiltrado != null && this.cierreTurnoFormControl.value == 1 && !this.buscandoDetalle2">
          <thead>
            <tr>
              <th>Producto</th>
              <th>Fecha/Hora</th>
              <th>Entrada</th>
              <th>Entrada 15°</th>
              <th>Salida</th>
              <th>Salida 15°</th>
              <th>stock</th>
              <th>stock 15°</th>
              <th>Ver comprobante</th>
              <th>Título</th>
              <th>Seleccionar</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let mov of movimientosCierreCerradoFiltrado; let j = index">
              <td>{{mov.codigoProducto}}-{{mov.nombreProducto}}</td>
              <td>{{mov.fechaHora | date: "d'/'MM'/'y' 'hh:mm a"}}</td>
              <td>{{mov.entradaNatural? mov.entradaNatural : 0}}</td>
              <td>{{mov.entrada15? mov.entrada15 : 0}}</td>
              <td>{{mov.salidaNatural? mov.salidaNatural : 0}}</td>
              <td>{{mov.salida15? mov.salida15 :0}}</td>
              <td>{{mov.stock? mov.stock : 0}}</td>
              <td>{{mov.stock15? mov.stock15 : 0}}</td>
              <td *ngIf="mov.comprobante == null">-----</td>
              <td *ngIf="mov.comprobante != null">
                <ng-container *ngIf="mov.comprobante.remitoSalida != null">
                  <button *ngIf="!mov.abrirMovimiento" class="btn btn-primary" (click)="verRemitoSalida(mov, 2)"><i
                      class="fa fa-eye"> Remito salida</i></button>
                  <button *ngIf="mov.abrirMovimiento" class="btn btn-primary mt-2 mb-2" type="button">
                    <div class="spinner-border text-light spinner-border" role="status"></div> Remito salida
                  </button>
                </ng-container>
                <ng-container *ngIf="mov.comprobante.remito != null">
                  <button *ngIf="!mov.abrirMovimiento" class="btn btn-primary" (click)="verAerovale(mov)"><i
                      class="fa fa-eye"> Ver aerovale</i></button>
                  <button *ngIf="mov.abrirMovimiento" class="btn btn-primary mt-2 mb-2" type="button">
                    <div class="spinner-border text-light spinner-border" role="status"></div> Ver aerovale
                  </button>
                </ng-container>
                <ng-container *ngIf="mov.comprobante.remitoCargamento != null">
                  <button class="btn btn-primary" *ngIf="!mov.abrirMovimiento" (click)="verRemitoCargamento(mov, 1)"><i
                      class="fa fa-eye"> Remito cargamento</i></button>
                  <button *ngIf="mov.abrirMovimiento" class="btn btn-primary mt-2 mb-2" type="button">
                    <div class="spinner-border text-light spinner-border" role="status"></div> Remito cargamento
                  </button>
                </ng-container>
              </td>
              <td>{{mov.titulo}}</td>
              <td>
                <div class="form-check">
                  <input type="checkbox" class="custom-control-input" (click)="agregarMovimiento(j, mov)"
                    id="customCheck{{j}}" [checked]="mov.booleanAux">
                  <label class="custom-control-label" for="customCheck{{j}}"></label>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <ngb-alert class="alert alert-warning"
          *ngIf="this.movimientosCierreCerradoFiltrado.length == 0 &&  this.cierreTurnoFormControl.value == 1 && !this.buscandoDetalle2">
          No hay movimientos para el turno cerrado y contenedor seleccionado
        </ngb-alert>
      </div>
    </div>
  </div>
  <div class="card-footer text-center">
    <button class="btn btn-primary" (click)="reimputarMovimiento()" [disabled]="this.movimientoSeleccionado == null || this.cierresDeTurnoAbierto == null || this.cierresDeTurnoCerrado == null">
      <span *ngIf="!guardando">Reimputar documentos a turno {{this.cierreTurnoFormControl.value == 0 ? 'cerrado' :
        'abierto'}}</span>
      <div *ngIf="guardando" class="d-flex align-items-center">
        <div class="spinner-border text-light spinner-border-sm" role="status"></div>
        <span>Reimputando documentos a turno {{this.cierreTurnoFormControl.value == 0 ? 'cerrado...' :
          'abierto...'}}</span>
      </div>
    </button>
  </div>