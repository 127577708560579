<div class="modal-header bg-light">
  <h4 class="modal-title">Contacto Responsable</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismissAll()"></button>
</div>
<div class="modal-body">
  <form [formGroup]="responsableForm">
    <div class="row">
      <!--nombre-->
      <div class="mb-3 col-12">
        <div class="mb-3 row">
          <div class="col-4">
            <label class="col-form-label" for="nombreFormControl">*Nombre Completo:</label>
          </div>
          <div class="col-8">
            <input class="form-control" id="nombreFormControl" formControlName="nombreFormControl" required/>
            <div
              *ngIf="nombre.invalid && nombre.errors.required && (nombre.dirty || nombre.touched)"
              class="alert alert-danger">
              Requerido
            </div>
          </div>
        </div>
      </div>
      <!--Telefono-->
      <div class="mb-3 col-12">
        <div class="mb-3 row">
          <div class="col-4">
            <label class="col-form-label" for="telefonoFormControl">Teléfono:</label>
          </div>
          <div class="col-8">
            <input class="form-control" id="telefonoFormControl" formControlName="telefonoFormControl" />
          </div>
        </div>
      </div>
      <!--Email-->
      <div class="mb-3 col-12">
        <div class="mb-3 row">
          <div class="col-4">
            <label class="col-form-label" for="mailFormControl">E-mail:</label>
          </div>
          <div class="col-8">
            <input class="form-control" id="mailFormControl" formControlName="mailFormControl" />
          </div>
        </div>
      </div>
      

    </div>
  </form>
  <small class="text text-danger">*Campos obligatorios.</small>
</div>
<div class="modal-footer bg-light justify-content-center">
  <button class="btn btn-primary me-2" (click)="guardarResponsable()" [disabled]="guardando">
    <span *ngIf="!guardando">Guardar</span>
          <div *ngIf="guardando" class="d-flex align-items-center">
            <div class="spinner-border text-light spinner-border-sm" role="status"></div>
            <span>Guardando...</span>
          </div>
  </button>
  <button class="btn btn-secondary" (click)="activeModal.dismissAll()">Cancelar</button>
</div>