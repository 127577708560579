import { HttpClient} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { HandleError, HttpErrorHandler } from 'src/app/http-error-handler.service';
import { AuthService } from '../core/authentication/auth.service';
import { Injectable } from '@angular/core';
import { Abastecedora } from '../shared/models/stock/Abastecedora';
import { Surtidor } from '../shared/models/stock/Surtidor';
import { Contenedor } from '../shared/models/stock/Contenedor';

@Injectable({
    providedIn: 'root'
  })
export class AbastecedoraService{
  [x: string]: any;
  private handleError: HandleError;
    stockbaseUrl = environment.apiServer.stockBaseUrl;

    constructor(private httpClient: HttpClient, private httpErrorHandler: HttpErrorHandler, private authservice: AuthService){
      this.handleError = httpErrorHandler.createHandleError('Abastecedoras')
    }

  getAbastecedoras(codigo: string): Observable<Abastecedora[]> {
    return this.httpClient.get<Abastecedora[]>(this.stockbaseUrl + '/AeroplantasV1/' + codigo + '/Abastecedoras')
      .pipe(
        tap(_ => console.log('Se obtuvieron las abastecedoras.')),
        catchError(this.handleError('Al obtener abastecedoras', null))
      );
  }

  crearAbastecedora(aeroplantaCodigo: string, abast: Abastecedora): Observable<Abastecedora> {
    return this.httpClient.post<Abastecedora>(this.stockbaseUrl + '/ContenedoresV1/Abastecedora?codigoAeroplanta=' + aeroplantaCodigo, abast)
      .pipe(
        tap(_ => console.log('Se creo la abastecedora correctamente.')),
        catchError(this.handleError('Al crear abastecedoras', null))
      );
  }

  editarAbastecedora(aeroplantaCodigo: string, abast: Abastecedora): Observable<Abastecedora> {
    return this.httpClient.put<Abastecedora>(this.stockbaseUrl + '/ContenedoresV1/Abastecedora/' + abast.codigo + '?codigoAeroplanta=' + aeroplantaCodigo, abast)
      .pipe(
        tap(_ => console.log('Se actualizó la abastecedora correctamente.')),
        catchError(this.handleError('Al editar abastecedoras', null))
      );
  }

    getSurtidoresPorAeroplanta(codigoAeroplanta: string): Observable<Surtidor[]>{
      return this.httpClient.get<Surtidor[]>(this.stockbaseUrl + '/AeroplantasV1/Surtidores?codigoAeroplanta=' + codigoAeroplanta)
      .pipe(
        tap(_ => console.log('getSurtidoresPorAeroplanta')),
          catchError(this.handleError('Al obtener surtidores', null))
        );
    }

    crearSurtidor(codigoAeroplanta: string, surtidor: Surtidor): Observable<Surtidor>{
      return this.httpClient.post<Surtidor>(this.stockbaseUrl + '/ContenedoresV1/Abastecedora?codigoAeroplanta=' + codigoAeroplanta, surtidor)
      .pipe(
        tap(_ => console.log('Creacion de surtidor')),
        catchError(this.handleError('Al crear surtidor', null))
        );
    }

    editarSurtidor(codigoAeroplanta: string, surtidor: Surtidor): Observable<Surtidor>{
      return this.httpClient.put<Surtidor>(this.stockbaseUrl + '/ContenedoresV1/Abastecedora/' + surtidor.codigo + '?codigoAeroplanta=' + codigoAeroplanta, surtidor)
      .pipe(
        tap(_ => console.log('Edición de surtidor')),
        catchError(this.handleError('Al editar surtidor', null))
        );
    }

    obtenerProximoCodigoAbastecedora(codigoAeroplanta:string, tipoDeContenedor: string): Observable<Contenedor>
    {
      return this.httpClient.get<Contenedor>(this.stockbaseUrl + '/ContenedoresV1/ProximoCodigoContenedor/'+tipoDeContenedor+'/'+ codigoAeroplanta)
      .pipe(
        tap(_ => console.log('getProximoCodigoAbastecedora')),
          catchError(this.handleError('Al obtener proximo codigo abastecedora', null))
        );

    }

    getExpendedorasPorCierre(idCierre: string, codigoAeroplanta: string):Observable<Contenedor[]>{
      return this.httpClient.get<Contenedor[]>(this.stockbaseUrl + '/ContenedoresV1/' + idCierre + '/' + codigoAeroplanta + '/Expendedoras');
    }
  }
