import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductoService } from 'src/app/abm/producto/producto.service';
import { UsuarioService } from 'src/app/account/usuario.service';
import { Producto } from 'src/app/shared/models/despacho/Producto';
import { Contenedor } from 'src/app/shared/models/stock/Contenedor';
import { Responsable } from 'src/app/shared/models/stock/Responsable';
import { Trasvase } from 'src/app/shared/models/stock/Trasvase';
import { SharedService } from 'src/app/shared/shared.service';
import { MessageService } from 'src/app/shell/message.service';
import { StockService } from '../../../stock.service';
import { TransaccionesService } from '../../../transacciones.service';
import { CalculosService } from 'src/app/shared/calculos.service';
@Component({
  selector: 'app-modal-transfenrencia',
  templateUrl: './modal-transfenrencia.component.html',
  styleUrls: ['./modal-transfenrencia.component.css']
})
export class ModalTransfenrenciaComponent implements OnInit {
  @Output() resultado: EventEmitter<boolean> = new EventEmitter();

  errorPicoNoSeleccionado = "Debe seleccionar un aforador.";

  negativeDecimalRegex = /^[+-]?[0-9]+(.[0-9]{0,4})?$/;
  positiveInteger = /^\+?(0|[1-9]\d*)$/;

  obteniendoContenedores: boolean = false;
  aeroplantaCod: string;
  contenedorOrigen: Contenedor[] = [];
  contenedorDestino: Contenedor[] = [];
  contenedorDestinoFiltrado: Contenedor[] = [];
  errorDestinos: string;
  guardandoMovimiento: boolean = false;

  productos: Producto[] = [];
  productoSeleccionado: Producto;
  error: string;

  ocultarAforadores = false;

  trasvaseForm = new UntypedFormGroup({
    origenFormControl: new UntypedFormControl(),
    destinoFormControl: new UntypedFormControl(),
    cantidadFormControl: new UntypedFormControl(),
    densidadFormControl: new UntypedFormControl(),
    volumen15FormControl: new UntypedFormControl({value: '', disabled: true}),
    temperaturaFormControl: new UntypedFormControl("", [Validators.required, this.temperaturaValidator]),
    aforadorInicialFormControl: new UntypedFormControl(),
    aforadorFinalFormControl: new UntypedFormControl(),
    picoInicialFormControl: new UntypedFormControl()
  });

  constructor(public activeModal: NgbModal, private stockService: StockService, private usuarioService: UsuarioService, private transaccionesService: TransaccionesService,
    private messageService: MessageService, private productoService: ProductoService, private sharedService: SharedService,
    private calculoService: CalculosService) { }


  ngOnInit() {
    this.temperaturaFormControl.setValue(0);
    this.densidadFormControl.setValue('0.000');
    this.cantidadFormControl.setValue(0);
    this.volumen15FormControl.setValue(0);
    this.aforadorInicialFormControl.setValue(0);
    this.aforadorFinalFormControl.setValue(0);
    this.aeroplantaCod = this.usuarioService.getAeroplantaActual().codigoAeroplanta;
    this.getContenedores();
    this.getProductos();
  }


  // form

  filtrarContenedorDestino() {
    if(this.error == this.errorPicoNoSeleccionado) this.error = null;

    if(this.origenFormControl.value.tipoContenedor != 4) this.ocultarAforadores = false;

    this.picoInicialFormControl.setValue(null);

    this.contenedorDestinoFiltrado = [];
    let p = this.origenFormControl.value;
    this.contenedorDestino.forEach((value, index) => {
      if (this.contenedorDestino[index].productoActual != null) {
        if (p.productoActual.codigoProducto == this.contenedorDestino[index].productoActual.codigoProducto) {
          if (p.codigo != this.contenedorDestino[index].codigo) {
            this.contenedorDestinoFiltrado.push(this.contenedorDestino[index]);
          }
        }
      }
    });
    this.destinoFormControl.setValue(this.contenedorDestinoFiltrado[0]);
    if (p.tipoContenedor != 4) {
      this.cantidadFormControl.setValue(0);
      this.aforadorInicialFormControl.setValue(0);
      this.aforadorFinalFormControl.setValue(0);
    }
  }

  recalcularVolumenNatural() {
    var volumenLts = Number(this.cantidadFormControl.value);
    var dens = Number(this.densidadFormControl.value);
    var temp = Number(this.temperaturaFormControl.value);

    if (volumenLts != null && dens != null && temp != null) {
      let vol15 = this.calculoService.obtenerCTLNuevo(temp, dens, volumenLts);
      if (!isNaN(vol15)) {
        this.volumen15FormControl.setValue(Math.round(vol15));
      } else {
        this.volumen15FormControl.setValue(0);
      }
    }
  }

  calcularCantidad() {
    this.cantidadFormControl.setValue(Number(this.aforadorFinalFormControl.value) - Number(this.aforadorInicialFormControl.value));
    this.recalcularVolumenNatural();
  }

  resetForm() {
    this.temperaturaFormControl.setValue(0);
    this.densidadFormControl.setValue('0.000');
    this.cantidadFormControl.setValue(0);
    this.volumen15FormControl.setValue(0);
    this.aforadorInicialFormControl.setValue(0);
    this.aforadorFinalFormControl.setValue(0);
    this.error = null;
  }

  armarResponsable() {
    var r = new Responsable();
    r.UserName = this.usuarioService.getUsername();
    r.NombreCompleto = this.usuarioService.getNombresCompletosUsuario();
    r.DNI = this.usuarioService.getDniUsuario();

    return r;
  }

  // validaciones

  validarDensidad(densidad) {
    try {
      if (densidad != null) {
        let esValido = true;
        let p = this.origenFormControl.value;
        this.productoSeleccionado = this.productos.find(element => element.codigoProducto === this.origenFormControl.value.productoActual.codigoProducto);
        esValido = (densidad >= this.productoSeleccionado.densidadMin && densidad <= this.productoSeleccionado.densidadMax);

        return esValido;
      }
    } catch (ex) {
      console.log("Excepcion validacion densidad.");
    }
  }

  formatDensidad(event) {
    if (event.charCode == 8 || event.charCode == 0) return null;

    if (event.charCode >= 48 && event.charCode <= 57) {
      this.densidadFormControl.setValue(this.sharedService.formatDensidad(event));
    }
    this.recalcularVolumenNatural()
    return false;
  }

  onlyDecimalPositivo(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46;
  }

  validarAforador() {
    return Number(this.aforadorInicialFormControl.value) < Number(this.aforadorFinalFormControl.value)
  }

  // llamadas al service

  guardarTrasvase() {

    if(this.origenFormControl.value.tipoContenedor == 4 && !this.ocultarAforadores){
      if(this.picoInicialFormControl.value == null){
        this.error = this.errorPicoNoSeleccionado;
        return;
      }

      if (!this.positiveInteger.test(this.aforadorInicialFormControl.value)) {
        this.error = "El aforador inicial debe ser entero.";
        return;
      }

      if (!this.positiveInteger.test(this.aforadorFinalFormControl.value)) {
        this.error = "El aforador inicial debe ser entero.";
        return;
      }

      if( ! this.validarAforador()){
        this.error = "El aforador final debe ser mayor que el inicial.";
        return;
      }
    }else{
      if (!this.positiveInteger.test(this.cantidadFormControl.value) || this.cantidadFormControl.value == 0) {
        this.error = "El valor de cantidad debe ser positivo.";
        return;
      }
    }

    if (!this.negativeDecimalRegex.test(this.temperaturaFormControl.value)) {
      this.error = "El campo temperatura debe ser decimal valido.";
      return;
    }

    if (!this.validarDensidad(this.densidadFormControl.value)) {
      this.error = "La densidad debe estar entre el rango [" + this.productoSeleccionado.densidadMin + "," + this.productoSeleccionado.densidadMax + "]";
      return;
    }   

    this.guardandoMovimiento = true;
    let trasvase = new Trasvase();
    trasvase.responsable = new Responsable();
    trasvase.codigoContenedorOrigen = this.origenFormControl.value.codigo;
    trasvase.codigoContenedorDestino = this.destinoFormControl.value.codigo;
    trasvase.densidad = Number(this.densidadFormControl.value);
    trasvase.temperatura = Number(this.temperaturaFormControl.value);
    trasvase.volumen = Number(this.cantidadFormControl.value);
    trasvase.volumen15 = Number(this.volumen15FormControl.value);       
    trasvase.responsable = this.armarResponsable();
    
    if(this.origenFormControl.value.tipoContenedor == 4 && !this.ocultarAforadores){
      trasvase.aforadorInicialOrigen = Number(this.aforadorInicialFormControl.value);
      trasvase.aforadorFinalOrigen = Number(this.aforadorFinalFormControl.value);
      trasvase.picoId = this.picoInicialFormControl.value; 
    }

    this.transaccionesService.postTrasvase(trasvase)
      .subscribe(result => {
        this.messageService.showSuccessMessage('Se generó de manera correcta el trasvase');
        this.guardandoMovimiento = false;
        this.resultado.emit(true);
        this.resetForm();
        this.activeModal.dismissAll();
      }, () => {
        this.messageService.showErrorMessage('Hubo un error al crear el trasvase, intentelo en un momento.')
        this.guardandoMovimiento = false;
      });
  }

  getContenedores() {
    this.obteniendoContenedores = true;
    this.stockService.getTodosLosContenedores(this.aeroplantaCod)
      .subscribe(result => {
        this.contenedorOrigen = result;
        this.origenFormControl.setValue(this.contenedorOrigen[0]);
        this.contenedorDestino = result;
        this.obteniendoContenedores = false;
        this.filtrarContenedorDestino();
      })
  }

  getProductos() {
    this.productoService.obtenerProductos()
      .subscribe(result => {
        this.productos = result;
      }, () => {

      });
  }

  temperaturaValidator(control: UntypedFormControl) {
    var temperatura = control.value;

    if (temperatura > 100) {
      return { temperaturaFueraDeRango: true };
    }

    if (temperatura <= -50) {
      return { temperaturaFueraDeRango: true };
    }

    return null;
  }

  usuarioPulsaCheckSinAforadores(){
    this.ocultarAforadores = !this.ocultarAforadores;
  }

  usuarioSeleccionoPico(event){
    this.picoInicialFormControl.setValue(event.target.value == 'null' ? null : event.target.value);
  }

  get origenFormControl() {
    return this.trasvaseForm.get('origenFormControl');
  }

  get destinoFormControl() {
    return this.trasvaseForm.get('destinoFormControl');
  }

  get cantidadFormControl() {
    return this.trasvaseForm.get('cantidadFormControl');
  }

  get densidadFormControl() {
    return this.trasvaseForm.get('densidadFormControl');
  }

  get volumen15FormControl() {
    return this.trasvaseForm.get('volumen15FormControl');
  }

  get temperaturaFormControl() {
    return this.trasvaseForm.get('temperaturaFormControl');
  }

  get aforadorInicialFormControl() {
    return this.trasvaseForm.get('aforadorInicialFormControl');
  }
  get aforadorFinalFormControl() {
    return this.trasvaseForm.get('aforadorFinalFormControl');
  }
  get picoInicialFormControl() {
    return this.trasvaseForm.get('picoInicialFormControl');
  }
}
