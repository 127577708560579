<nav class="navbar navbar-light bg-light navbar-expand-lg mb-3" [formGroup]="busquedasCliente">
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav">
      <li class="nav-item ms-3 me-2">
        <input id="clienteFormControl" type="text" class="form-control" formControlName="clienteFormControl"
          placeholder="Razón social" (keydown)="filtroClientes()" />
      </li>
      <!-- Aeroplantas -->
      <li class="nav-item me-2">
        <select class="form-select" id="aeroplantaFormControl" name="aeroplantaFormControl"
          formControlName="aeroplantaFormControl">
          <option [value]="null" defaultSelected>Todas las aeroplantas</option>
          <option *ngFor="let aer of aeroplantas" [value]="aer.codigoAeroplanta">
            {{aer.nombre}}
          </option>
        </select>
      </li>
      <!-- Productos -->
      <li class="nav-item me-2">
        <select class="form-select" id="productoFormControl" name="productoFormControl"
          formControlName="productoFormControl">
          <option [value]='null' defaultSelected>Todos los productos</option>
          <option *ngFor="let prod of productos" [value]="prod.codigoProducto">
            {{prod.nombreProducto}}
          </option>
        </select>
      </li>
      <!-- fecha-->
      <li class="nav-item me-2">
        <div class="input-group">
          <input type="date" class="form-control" placeholder="aaaa-mm-dd" formControlName="fechaFormControl" name="dt"
            ngbDatepicker #d="ngbDatepicker" value="modelDesde" [ngModel]="modelDesde"
            ngbTooltip="Seleccione una fecha." class="form-control form-control-rounded" #searchDate
            readonly>
          <div class="input-group-append">
            <button id="botonDesde" type="button" class="input-group-btn btn btn-secondary" (click)="d.toggle()">
              <i class="fa fa-calendar" aria-hidden="true"></i></button>
          </div>
        </div>
      </li>
    </ul>
  </div>
</nav>
<div class="col col-md-12">
  <div class="spinner-container" *ngIf="esperandoCarga">    
    <ngx-spinner name="spinnerGrafico" bdOpacity=0.3bd
      Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
      <p style="color: white">C a r g a n d o . . . </p>
    </ngx-spinner>    
  </div>
  <!-- </form> -->
  <div class="row">
    <div class="col col-4" *ngIf="!esperandoCarga">
      <div class="list-group py-3  menu-izq">
        <div *ngFor="let clien of clientesFiltrados; let i = index">
          <button type="button" [class.active]="" class="list-group-item list-group-item-action"
            (click)="mostrarDetalle(i)">
            <div class="d-flex w-100 justify-content-between">
              <ul class="list-unstyled">
                <li>{{ clien.razonSocial}}</li>
                <li *ngFor="let c of clien.cuentas">{{c.ctA_SGC}}<br></li>
              </ul>
            </div>
          </button>
        </div>
      </div>
    </div>
    <!-- informacion del remitos -->
    <div id="tablaSeleccionRemitos" class="col-sm-8">
      <div *ngIf="this.seleccionado == true || this.cuentasSeleccionadas">
        <app-remitos-seleccion [clienteSeleccionado]='cuentasSeleccionadas' [seleccionado]="seleccionado"
          [aeroplantaFiltrada]='this.aeroplantaFormControl.value' [fechaFiltrada]='this.fechaFormControl.value'
          [productoFiltrado]='this.productoFormControl.value' #selectRemitos>
        </app-remitos-seleccion>
      </div>
      <br>
    </div>
  </div>
</div>