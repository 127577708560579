import { Guid } from 'guid-typescript';

export class Transferencia {
    codigoAeroplanta: string;
    contenedorIdOrigen: Guid;
    contenedorIdDestino: Guid;
    cantidad: number;
    productoCodigo: string;
    numeroRemito: string;
    fechaHota: Date;
    temperatura: number;
    aforadorOrigenInicio: number;
    aforadorDestino: number;
    tipoMovimiento: number;
}