import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormControl } from '@angular/forms';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { PreciosService } from '../../precios.service';
import { read, utils, WorkBook, WorkSheet } from 'xlsx'
import { Precio } from 'src/app/shared/models/abm/Precio';
import { PrecioConsulta } from 'src/app/shared/models/precios/PrecioConsulta';
import { PrecioExcel } from 'src/app/shared/models/precios/PrecioExcel';
import { Guid } from 'guid-typescript';
import { TipoMoneda } from 'src/app/shared/enums/tipoMoneda';
import { EstadoPrecioCargado } from 'src/app/shared/enums/EstadoPrecioCargado';
import { StockService } from 'src/app/stock/stock.service';
import { Aeroplanta } from 'src/app/shared/models/stock/Aeroplanta';
import { ComparativaSubirPreciosComponent } from '../../precio/comparativa-subir-precios/comparativa-subir-precios.component';
import { MessageService } from 'src/app/shell/message.service';

@Component({
  selector: 'app-modal-subir-excel-modificacion-precios',
  templateUrl: './modal-subir-excel-modificacion-precios.component.html',
  styleUrls: ['./modal-subir-excel-modificacion-precios.component.scss']
})

export class ModalSubirExcelModificacionPreciosComponent implements OnInit {
  constructor(public modal: NgbActiveModal,
    private stockService: StockService,
    private fb: UntypedFormBuilder,
    private precioService: PreciosService,
    private modalService: NgbModal,
    private messageService: MessageService) { }

  fileUpload = { status: '', message: '', filePath: '', length: 0 };
  userFileName: string;
  selectedFile: boolean = false;
  mensajeError: string;
  subiendoPrecios: boolean = false;
  errorAlSubirPrecios: boolean = false;
  mensajeProcesamiento: string = null;
  mensajeErrorProcesamiento: string = "";
  mensajeFinProcesamiento: string = "";
  guidLote: Guid;
  CANTIDAD_PRECIOS_ENVIO: number = 100;
  cargaCancelada: boolean = false;
  obteniendoAeroplantas: boolean = false;
  errorObtenerAeroplantas: string;

  porcentajeCarga: number = 0;
  porcentajeMax: number = 100;
  idInterval;
  bloqueCompletado = 0;
  totalBloques = 0;

  comparativasRecibidas : PrecioConsulta[] = [];

  aeroplantasStock: Aeroplanta[] = [];

  listaNombreColumnasValidas: string[];

  productoForm: UntypedFormGroup;

  nivelDelExcel: number;
  readonly CAMPO_AEROPLANTA : number = 18;

  // hooks

  ngOnInit() {
    this.productoForm = this.fb.group({
      name: [''],
      profile: [''],
      inputFile: ['', Validators.required]
    });

    this.obteniendoAeroplantas = true;
    this.stockService.obtenerAeroplantasStock().subscribe(result => {
      this.aeroplantasStock = result;
      
      this.obteniendoAeroplantas = false;
    },
    (err: ErrorModel) => {    
      this.errorObtenerAeroplantas = err.message;
      this.obteniendoAeroplantas = false;
    }
  );
  }

  // form

  onSelectedFile(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.productoForm.get('profile').setValue(file);
      this.productoForm.get('name').setValue(file.name);
      this.userFileName = file.name;
      this.selectedFile = true;
      this.mensajeErrorProcesamiento = "";
    }
  }

  reset(limpiarMensajes: boolean = false, limpiarInput: boolean = false) {
    this.subiendoPrecios = false;
    this.selectedFile = true;
    if (limpiarInput) {
      this.selectedFile = false;
      this.userFileName = '';
    }
    if (limpiarMensajes)
      this.fileUpload = { status: '', message: '', filePath: '', length: 0 };
  }

  // llamadas al service

  cargarPrecios() {
    
    this.porcentajeCarga = 0;
    this.subiendoPrecios = true;
    this.fileUpload = { status: '', message: '', filePath: '', length: 0 };
    const archivo = this.productoForm.get('profile').value;

    if (archivo) {
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        /* read workbook */
        const bstr: string = e.target.result;
        const wb: WorkBook = read(bstr, { type: 'binary' });

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: WorkSheet = wb.Sheets[wsname];

        /* save data */
        let rows: any[][] = utils.sheet_to_json(ws, { header: 1 });
        if (rows.length == 0) {
          this.mensajeError = "El archivo está vacío";
          this.reset();
        } else {         

          if( ! this.validarColumnas(rows[0]) ){ 
            this.subiendoPrecios = false;
            return;
          }

          if( ! this.validarPreciosTodosMismoNivel(rows)){
            this.subiendoPrecios = false;
           return;
          }

          this.nivelDelExcel = Number(rows[1][0]);

          rows = this.filtrarFilasDatosNuevosCompletos(rows);

          if(rows.length == 1){
            this.mensajeErrorProcesamiento = "El excel no contiene ninguna fila con precios para modificar" ;
            this.subiendoPrecios = false;
            return false;
          }

          if( ! this.validarColumnasVacias(rows) ){ 
            this.subiendoPrecios = false;
            return;
          }          

          if( ! this.validarPreciosTodosMismoTipo(rows)){
            this.subiendoPrecios = false;
           return;
          }

          if( ! this.validarFechasLegibles(rows)){
            this.subiendoPrecios = false;
           return;
          }

          var registros : any[][] = [];
          
          for (var i = 1; i <= this.cantidadFilasValidas(rows); i++) {  
            registros.push(this.convertirValoresEnString(rows[i]));
          }          

          this.iniciarProcesamientoPrecios(registros);
        }
      }

      reader.readAsBinaryString(this.productoForm.get('profile').value);
    } else {
      this.mensajeError = "No se seleccionó un archivo";
    }
  }

  obtenerNumeroAeroplantaPorCodigo(row: any[]){   
    var codigo = row[this.CAMPO_AEROPLANTA];
    
    var aeroplanta = this.aeroplantasStock.filter(a => a.codigoAeroplanta == codigo)[0];

    if(aeroplanta != null){
      return aeroplanta.numeroAeroplanta;
    }else{
      return "";
    }    
  }

  convertirValoresEnString(fila: any[]){
    var registro = fila.map(e => e.toString());

    return this.convertirAeroplantaNumeroEnCodigo(registro);
  }

  convertirAeroplantaNumeroEnCodigo(registro: any[]){

    var campoAeroplanta = registro[this.CAMPO_AEROPLANTA];

    if( ! isNaN(campoAeroplanta) ){
      // aeroplanta es un numero
      var aeroplanta = this.aeroplantasStock.filter(a => Number(a.numeroAeroplanta) == Number(campoAeroplanta))[0];

      if(aeroplanta != null){
        registro[this.CAMPO_AEROPLANTA] = aeroplanta.codigoAeroplanta;
      }else{
        registro[this.CAMPO_AEROPLANTA] = "" + campoAeroplanta;
      }
      
      return registro;
    }else{
      return registro;
    }
  }

  obtenerCantColumansValidas(row: any[]){
    var cantColumnasValidas = 0;

    for(var i=0 ; i < row.length ; i++){
      if(row[i] == "" || row[i] == " " || row[i] == null){
        break;
      }
      cantColumnasValidas++;
    }

    return cantColumnasValidas;
  }

  validarFechasLegibles(rows: any[][]){
    var columnaFechadesde = this.listaNombreColumnasValidas.length - 2;

    for (var indexRow = 1; indexRow < rows.length; indexRow++) {  
      var fechadesde = rows[indexRow][columnaFechadesde];
      var fechaHasta = rows[indexRow][columnaFechadesde + 1];
      var fechaHoraRegex = /^\d{2}\/\d{2}\/\d{4} +\d{2}:\d{2}:\d{2} *(am|pm)?$/;

      if(fechadesde != null){
        if( ! fechaHoraRegex.test(fechadesde) ){
          this.mensajeErrorProcesamiento = "Hay un error en el formato de nueva fecha desde, fila: " + (indexRow+1) + ". El formato esperado es dd/mm/yyyy hh:mm:ss [am/pm opcional] como por ejemplo 18/06/2021 05:45 am y se encontro " + fechadesde +"." ;
          return false;      
        }
      }

      if(fechaHasta != null){
        if( ! fechaHoraRegex.test(fechaHasta) ){
          this.mensajeErrorProcesamiento = "Hay un error en el formato de nueva fecha hasta, fila: " + (indexRow+1) + ". El formato esperado es dd/mm/yyyy hh:mm:ss [am/pm opcional] como por ejemplo 18/06/2021 05:45 am y se encontro " + fechaHasta +"." ;
          return false;      
        }
      }
    }

    return true;
  }

  getCantidadColumnasValidas(){
    return this.listaNombreColumnasValidas.length;
  }

  cantidadFilasValidas(rows: any[][]){
    // Busca la primera fila con todas las columnas vacias.
    for (var indexRow = 1; indexRow < rows.length; indexRow++) {   
      for(var indexCol = 0; indexCol < this.getCantidadColumnasValidas(); indexCol++){
        if(rows[indexRow][indexCol] != null){
          break;
        }
      }

      if(indexCol == this.getCantidadColumnasValidas()){
        return indexRow-1;
      }
    }

    return indexRow - 1;
  }

  filtrarFilasDatosNuevosCompletos(rows: any[][]){

    var filasCompletas: any[][] = [];

    filasCompletas.push(rows[0]);

    for (var indexRow = 1; indexRow <= this.cantidadFilasValidas(rows); indexRow++) {  

      var columnasNuevasCompletas = true;
      for(var indexCol = this.getCantidadColumnasValidas() - 3 ; indexCol < this.getCantidadColumnasValidas(); indexCol++){
        if(rows[indexRow][indexCol] == null){
          columnasNuevasCompletas = false;
          break;
        }  
      }

      if(columnasNuevasCompletas){
        filasCompletas.push(rows[indexRow]);
      }
    }

    return filasCompletas;
  }

  validarColumnasVacias(rows: any[][]){
  // Tengo que validar que para el nivel, los datos de las columnas que corresponden no esten vacios.
    var tipoNivel = this.nivelDelExcel;
    var columnasObligatorias: number[];

    switch(tipoNivel){
      case 1:{
        //this.listaNombreColumnasValidas = ["CodigoProducto", "CodigoCliente", "CodigoAeroplanta", (tipoOperacion ? "TipoOperacion" : "TipoVenta"), "CodigoArea", "CodigoSegmento", "Direccion", "TipoMoneda", "FechaDesde", "FechaHasta", "Precio"] ;        
        columnasObligatorias = [0, 1, 2, 8, 18, 10, 15, 17, 12, 6, 22, 23, 21];  
        break;
      }
      case 2:{
        //this.listaNombreColumnasValidas = ["CodigoProducto", "CodigoCliente", "CodigoAeroplanta", (tipoOperacion ? "TipoOperacion" : "TipoVenta"), "CodigoArea", "CodigoSegmento", "TipoMoneda", "FechaDesde", "FechaHasta", "Precio"] ;        
        columnasObligatorias = [0, 1, 2, 8, 18, 10, 15, 17, 6, 22, 23, 21];
        break;
      }
      case 3:{
        //this.listaNombreColumnasValidas = ["CodigoProducto", "CodigoCliente", "CodigoAeroplanta", "CodigoArea", "CodigoSegmento", "TipoMoneda", "FechaDesde", "FechaHasta", "Precio"] ;        
        columnasObligatorias = [0, 1, 2, 8, 18, 15, 17, 6, 22, 23, 21];
        break;
      }
      case 4:{
        //this.listaNombreColumnasValidas = ["CodigoProducto", "CodigoAeroplanta", "CodigoArea", "CodigoSegmento", "TipoMoneda", "FechaDesde", "FechaHasta", "Precio"] ;        
        columnasObligatorias = [0, 1, 2, 18, 15, 17, 6, 22, 23, 21];
        break;
      }
      case 5:{
        //this.listaNombreColumnasValidas = ["CodigoProducto", "CodigoAeroplanta", "CodigoArea", "TipoMoneda", "FechaDesde", "FechaHasta", "Precio"] ;        
        columnasObligatorias = [0, 1, 2, 18, 15, 6, 22, 23, 21];
        break;
      }
      case 6:{
        //this.listaNombreColumnasValidas = ["CodigoProducto", "CodigoAeroplanta", "TipoMoneda", "FechaDesde", "FechaHasta", "Precio"] ;   
        columnasObligatorias = [0, 1, 2, 18, 6, 22, 23, 21];
        break;
      }
    }

    for (var indexRow = 1; indexRow <= this.cantidadFilasValidas(rows); indexRow++) {   
      for(var indexCol = 0; indexCol < columnasObligatorias.length; indexCol++){
        if(rows[indexRow][columnasObligatorias[indexCol]] == null){
          this.mensajeErrorProcesamiento = "El excel posee datos vacios en la fila: " + (indexRow + 1) + ", columna: " + String.fromCharCode(columnasObligatorias[indexCol] + 65) +"." ;
          return false;       
        }        
      }       
    }

    return true;
  }

  validarPreciosTodosMismoNivel(rows: any[][]){
    var nivelLeido;
    var columnaNivel = 0;

    for (var indexRow = 1; indexRow <= this.cantidadFilasValidas(rows); indexRow++) {            
      try{
        var nivel = rows[indexRow][columnaNivel];

        if(indexRow == 1){
          nivelLeido = nivel;
          continue;
        }

        if(nivel != nivelLeido){
          this.mensajeErrorProcesamiento = "El excel no tiene todas las filas del mismo nivel: Fila 1 -> Nivel " + nivelLeido + " -  Fila " + indexRow + " -> " + nivel ;
          return false;
        }
      }catch(e){
        console.log(e);
      }        
    } 

    return true;
  }

  validarPreciosTodosMismoTipo(rows: any[][]){
    var registrosRetroactivos = 0;
    var registrosNoRetroactivos = 0;
    var columnaFechadesde = this.listaNombreColumnasValidas.length - 2;

    for (var indexRow = 1; indexRow < rows.length; indexRow++) {            
      if(rows[indexRow][columnaFechadesde] != null){
        try{
          var fechaDesdeFila = new Date( this.convertirCadenaFechaHora(rows[indexRow][columnaFechadesde]) ).getTime();
          var ahora = new Date().getTime();
  
          if(fechaDesdeFila < ahora){
            registrosRetroactivos++;
          }else{
            registrosNoRetroactivos++;
          }
        }catch(e){
          console.log(e);
        }        
      }
    } 

    if(registrosRetroactivos != 0 && registrosNoRetroactivos != 0){      
      this.mensajeErrorProcesamiento = "El excel posee precios retroactivos mezclados con no retroactivos. Precios retroactivos: " + registrosRetroactivos + ". Precios no retroactivos: " + registrosNoRetroactivos +"." ;
      return false;            
    }

    return true;
  }

  validarColumnas(row: any[]){
    // Valido todo el excel ya que cualquier nivel sube el mismo formato de excel
    var cantColumnasValidad = this.obtenerCantColumansValidas(row);
    
    this.listaNombreColumnasValidas = ["Nivel", "Lote", "CodigoProducto", "Nombre producto", "FechaHora desde", "FechaHora hasta", "TipoMoneda", "Moneda descripción", "CodigoCliente", "Razon social", "TipoOperacion", "Precio", "Direccion", "Direccion Descripcion", "Area", "CodigoArea", "Segmento", "CodigoSegmento", "CodigoAeroplanta", "Nombre aeroplanta", "-", "Precio nuevo", "FechaDesde nueva", "FechaHasta nueva"] ;        
      

    if(cantColumnasValidad != this.listaNombreColumnasValidas.length){
      this.mensajeErrorProcesamiento = "Error en encabezado de columna del excel. <br/>Se esperaban " + this.listaNombreColumnasValidas.length + " columnas pero el excel tiene " + cantColumnasValidad + " columnas" ;
      return false;      
    }

    for(var i = 0; i < cantColumnasValidad; i++){
      if(row[i] != this.listaNombreColumnasValidas[i]){
        this.mensajeErrorProcesamiento = "Error en encabezado de columna del excel. Se esperaba '" + this.listaNombreColumnasValidas[i] + "' pero se encontro '" + row[i] +"' en la columna " + i ;
        return false;
      }
    }

    return true;
  }

  iniciarProcesamientoPrecios(rows : any[][]){
    this.guidLote = Guid.create();
    this.totalBloques = this.obtenerTotalBloques(rows.length);
    this.informarInicioDeProcesamiento(rows);
    this.procesarPrecios(rows, 1);   
  }

  calcularPorcentaje(rows: any[][], bloque){
    var totalBloques = this.totalBloques;

    return (bloque / totalBloques) * 100;
  }

  intervalPorcentajeCarga(rows: any[][], bloque){
    var self = this;
    var inicial = this.calcularPorcentaje(rows, bloque-1);
    var final = this.calcularPorcentaje(rows, bloque);

    var delta = (final - inicial) / 60;

    this.idInterval = setInterval(() => {
      self.porcentajeCarga += delta;
    }, 900);
  }

  procesarPrecios(rows: any[][], bloque: number){

    var precios = this.armarListaDePreciosPorBloque(rows, bloque);

    this.intervalPorcentajeCarga(rows, bloque);

    console.log("Subiendo bloque: " + bloque );

    this.errorAlSubirPrecios = false;

    this.precioService.subirPrecioLista(precios)
    .subscribe(
      rta => {
        if(this.cargaCancelada){
           // La llamada recursiva continuaba llamandese incluso al cerrarse la ventana. Con esto corta las llamadas recursivas.
           this.cancelarCarga();
           return;
        }

        this.informarBloqueProcesado(rows, bloque);

        clearInterval(this.idInterval);
        this.porcentajeCarga = this.calcularPorcentaje(rows, bloque);
        
        this.comparativasRecibidas = this.comparativasRecibidas.concat(rta);

        if( this.todosLosBloquesProcesados(rows, bloque) ){
          this.informarFinProcesamiento(rows);
          this.subiendoPrecios = false;
          this.mostrarVentanaComparativa();
          return;
        }    

        this.procesarPrecios(rows, bloque+1);
      },
      (err: ErrorModel) => {
        console.log('Se ha producido un error', err);        
        clearInterval(this.idInterval);
        this.informarBloqueProcesado(rows,bloque);
        this.informarBloqueErroneo(rows, bloque, err.description);  
        this.errorAlSubirPrecios = true;      

        this.subiendoPrecios = false;
      }
    );

  }

  armarListaDePreciosPorBloque(rows: any[][], bloque){
    // rows es la lista de datos del excel sin encabezado
    // bloque 1 -> filas 0 a 99, bloque 2 -> filas 100 a 199
    
    var lista : PrecioExcel[] = [];

    var totalFilas = rows.length;

    for(var i=((bloque-1) * this.CANTIDAD_PRECIOS_ENVIO); (i < (this.CANTIDAD_PRECIOS_ENVIO * bloque)) && i < totalFilas; i++){
      
      var p = new PrecioExcel();
   
      p.id = this.guidLote.toString();
      p.nivel = this.nivelDelExcel;
      p.indice = (i+1);
      p.total = rows.length;
      p.filaExcel = this.armarFilaPorNivel(rows[i]);
      p.responsable = this.precioService.obtenerStringResponsableV2();
      p.numeroAeroplanta = this.obtenerNumeroAeroplantaPorCodigo(rows[i])

      p.observacion = this.verificarDuplicados(rows.slice(0, i), rows[i]);

      lista.push(p);
    }

    return lista;
  }

  armarFilaPorNivel(row: any[]){
    var filaNivel: any[] = [];

    var tipoNivel = this.nivelDelExcel;
    var columnasObligatorias: number[];
  
    switch(tipoNivel){
      case 1:{
        //this.listaNombreColumnasValidas = ["CodigoProducto", "CodigoCliente", "CodigoAeroplanta", (tipoOperacion ? "TipoOperacion" : "TipoVenta"), "CodigoArea", "CodigoSegmento", "Direccion", "TipoMoneda", "FechaDesde", "FechaHasta", "Precio"] ;        
        columnasObligatorias = [2, 8, 18, 10, 15, 17, 12, 6, 22, 23, 21];
        break;
      }
      case 2:{
        //this.listaNombreColumnasValidas = ["CodigoProducto", "CodigoCliente", "CodigoAeroplanta", (tipoOperacion ? "TipoOperacion" : "TipoVenta"), "CodigoArea", "CodigoSegmento", "TipoMoneda", "FechaDesde", "FechaHasta", "Precio"] ;        
        columnasObligatorias = [2, 8, 18, 10, 15, 17, 6, 22, 23, 21];
        break;
      }
      case 3:{
        //this.listaNombreColumnasValidas = ["CodigoProducto", "CodigoCliente", "CodigoAeroplanta", "CodigoArea", "CodigoSegmento", "TipoMoneda", "FechaDesde", "FechaHasta", "Precio"] ;        
        columnasObligatorias = [2, 8, 18, 15, 17, 6, 22, 23, 21];
        break;
      }
      case 4:{
        //this.listaNombreColumnasValidas = ["CodigoProducto", "CodigoAeroplanta", "CodigoArea", "CodigoSegmento", "TipoMoneda", "FechaDesde", "FechaHasta", "Precio"] ;        
        columnasObligatorias = [2, 18, 15, 17, 6, 22, 23, 21];
        break;
      }
      case 5:{
        //this.listaNombreColumnasValidas = ["CodigoProducto", "CodigoAeroplanta", "CodigoArea", "TipoMoneda", "FechaDesde", "FechaHasta", "Precio"] ;        
        columnasObligatorias = [2, 18, 15, 6, 22, 23, 21];
        break;
      }
      case 6:{
        //this.listaNombreColumnasValidas = ["CodigoProducto", "CodigoAeroplanta", "TipoMoneda", "FechaDesde", "FechaHasta", "Precio"] ;   
        columnasObligatorias = [2, 18, 6, 22, 23, 21];
        break;
      }
    }

    for(var indexCol = 0; indexCol < columnasObligatorias.length; indexCol++){
      filaNivel.push(row[columnasObligatorias[indexCol]]);
    } 

    return filaNivel;
  }

  convertirCadenaFechaHora(cadenaOriginal: string){

    var dia = cadenaOriginal.substring(0, 2);
    var mes = cadenaOriginal.substring(3, 5);    

    return mes + "/" + dia + "/" + cadenaOriginal.substring(6, cadenaOriginal.length);
  }

  verificarDuplicados(rows: any[][], fila: string[]){

    var cantColumnas = this.listaNombreColumnasValidas.length - 3;

    for(var indexRow = 0; indexRow < rows.length; indexRow++){
      for(var i=0 ; i < cantColumnas; i++){
        if(rows[indexRow][i] != fila[i]){
          break;
        }

        if( i == (cantColumnas-1) ){
          try{
            var fechaDesdePrecio = new Date( this.convertirCadenaFechaHora(rows[indexRow][i+1]) ).getTime();
            var fechaDesdeFila = new Date( this.convertirCadenaFechaHora(fila[i+1]) ).getTime();
  
            var fechaHastaPrecio = new Date( this.convertirCadenaFechaHora(rows[indexRow][i+2]) ).getTime();
            var fechaHastaFila = new Date( this.convertirCadenaFechaHora(fila[i+2]) ).getTime();
  
            // Comparamos y es igual en todo salvo fechaDesde, fechaHasta y precio (hasta ahora)
            if(fechaDesdePrecio == fechaDesdeFila && fechaHastaPrecio == fechaHastaFila && Number(rows[indexRow][i+3]) == Number(fila[i+3])){
              //Camparamos y es exactamente igual el precio
              return "Precio duplicado dentro del archivo excel con la linea " + (indexRow+2);
            }
  
            if(fechaDesdePrecio == fechaDesdeFila && fechaHastaPrecio == fechaHastaFila){
              //Camparamos y es igual en todo menos en precio
              return "Fechas superpuestas dentro del archivo excel con la linea " + (indexRow+2);
            }
  
            if(fechaDesdeFila <= fechaHastaPrecio && fechaHastaFila >= fechaDesdePrecio){
              return "Fechas superpuestas dentro del archivo excel con la linea " + (indexRow+2);
            }
          }catch(ex){
            console.log("excepcion al verificar precio:" + ex);            
          }      
        }
      }
    }

    return "";     
  }

  todosLosBloquesProcesados(rows: any[][], bloque){

    var totalBloques = (rows.length / this.CANTIDAD_PRECIOS_ENVIO);
    var totalEnviosNumeroRedondo = ( totalBloques % 1) == 0;

    if( !totalEnviosNumeroRedondo ){
      totalBloques = Math.floor(totalBloques) + 1;
    }

    if( bloque >= totalBloques ){
      return true;
    }else{
      return false
    }
  }

  obtenerTotalBloques(cantFilas){
    var totalBloques = (cantFilas / this.CANTIDAD_PRECIOS_ENVIO);
    var totalEnviosNumeroRedondo = ( totalBloques % 1) == 0;

    if( !totalEnviosNumeroRedondo ){
      totalBloques = Math.floor(totalBloques) + 1;
    }

    return totalBloques;
  }

  informarInicioDeProcesamiento(rows: any[][]){
    this.mensajeProcesamiento = "Se inicia el procesamiento de " + rows.length + " precios.";
  }

  informarBloqueProcesado(rows: any[][], bloque){
    var registrosProcesados;
    if(bloque == this.totalBloques){
      registrosProcesados = rows.length;
    }else{
      registrosProcesados = (bloque * this.CANTIDAD_PRECIOS_ENVIO);
    }

    this.bloqueCompletado = bloque; console.log("Bloque completado: " + this.bloqueCompletado );

    this.mensajeProcesamiento = "Se procesaron " + registrosProcesados + " precios de un total de " + rows.length + " precios del excel.";
  }

  informarFinProcesamiento(rows: any[][]){
    this.mensajeProcesamiento = null;
    this.mensajeFinProcesamiento = "Se ha terminado de procesar los " + rows.length + " precios del excel.";
    // Mostrar icono OK verde
  }

  informarBloqueErroneo(rows: any[][], bloque, descripcionErrorHttp){
    this.mensajeErrorProcesamiento = "Error al enviar un bloque de precios al servidor. Se interrumpe el procesamiento.<br/>";
    this.mensajeErrorProcesamiento += "El bloque" + bloque + " del excel dio el siguiente error: " + descripcionErrorHttp;
  }

  obtenerTipoMoneda(textoSimboloMoneda){
    if(textoSimboloMoneda == "ARP"){
      return TipoMoneda.PES;
    }else{
      return TipoMoneda.USD;
    }
  }

  mostrarVentanaComparativa(){
    let modalRef = this.modalService.open(ComparativaSubirPreciosComponent, { centered: true, size: "lg", windowClass: 'myCustomModalClass', backdrop: "static" });
    modalRef.componentInstance.guidLote = this.guidLote;
    modalRef.componentInstance.nivelPrecio =  this.nivelDelExcel;
    modalRef.componentInstance.preciosConsulta =  this.comparativasRecibidas;
    modalRef.result.then((result) => { this.modal.close(result) }, () => { this.modal.close(true) });
  }
/* 20/10/2021
  reiniciarVentana(){
    this.guidLote = null;
    this.selectedFile = false;
    this.fileUpload = { status: '', message: '', filePath: '', length: 0 };
    this.userFileName = null;
    this.porcentajeCarga = 0;
    this.bloqueCompletado = 0;
    this.totalBloques = 0;
    this.comparativasRecibidas = [];
    this.errorAlSubirPrecios = false;
    this.mensajeProcesamiento = null;
    this.mensajeErrorProcesamiento = "";
    this.mensajeFinProcesamiento = "";
    this.subiendoPrecios = false;

    this.ngOnInit();
  }
*/
  usuarioCancelaCarga(){
    // Si no se esta subiendo precios llamar a cancelarCarga
    if(this.bloqueCompletado == this.totalBloques){
      this.cancelarCarga();
      return;
    }

    if(this.errorAlSubirPrecios){
      this.cancelarCarga();
      return;
    }

    // Si se esta subiendo precios
    this.cargaCancelada = true;
    this.modal.close(false);
  }

  cancelarCarga(){
    if(this.guidLote){
      this.precioService.uploadV2(this.guidLote, EstadoPrecioCargado.Cancelado)
      .subscribe(result => {
          this.modal.close(false);
        },
        (err: ErrorModel) => {
          this.mensajeError = err.description + ' ' + err.message;
          setInterval(() => {
            this.modal.close(false);
          }, 2500);    
        }
      );    
    }else{
      this.modal.close(false);
    }
  }

  ngOnDestroy(){
   
  }
}




