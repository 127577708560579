import { Component, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ReportesService } from 'src/app/reportes/reportes.service';
import { Justificacion } from 'src/app/shared/models/cierreTurno/Justificacion';
import { Renglones } from 'src/app/shared/models/cierreTurno/ResolucionMensual';

@Component({
  selector: 'app-modal-justificacion-resolucion',
  templateUrl: './modal-justificacion-resolucion.component.html',
  styleUrls: ['./modal-justificacion-resolucion.component.scss']
})
export class ModalJustificacionResolucionComponent {

  constructor(private formBuilder: UntypedFormBuilder, public activeModal: NgbActiveModal, private reportesService: ReportesService) { }

  form: UntypedFormGroup;
  @Input() renglon: Renglones;
  @Input() nombreResponsable: string;
  fecha: Date;


  ngOnInit() {
    this.crearFormulario();
    if (this.renglon.justificacion) this.justificacionFormControl.setValue(this.renglon.justificacion);
  }

  guardarJustificacion(tieneJustificacion) {
    if (tieneJustificacion) this.renglon.justificacion = this.justificacionFormControl.value;
    let justificacion: Justificacion = {codigoAeroplanta: this.renglon.codigoAeroplanta, codigoProducto: this.renglon.codigoProducto, justificacion: this.justificacionFormControl.value, fechaHasta: this.fecha, nombreResponsable: this.nombreResponsable}
    this.reportesService.justificarRenglonResolucion(justificacion).subscribe(res => {
      this.renglon.aceptado = true;
      this.renglon.justificacion = res.justificacion;
    })
    this.renglon.aceptado = true;
    this.activeModal.close(true)
  }

  crearFormulario(){
    this.form = this.formBuilder.group({
      justificacionFormControl: ['', Validators.required],
    });
  }

  get justificacionFormControl(){
    return this.form.controls.justificacionFormControl;
  }

}
