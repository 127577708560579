import { Component, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-busqueda-matricula',
  templateUrl: './modal-busqueda-matricula.component.html',
})
export class ModalBusquedaMatriculaComponent implements OnInit {

  matriculaFormControl=new UntypedFormControl();

  constructor(     
    public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.matriculaFormControl.setValidators(Validators.required); 
  }

  onClick() {
    if(this.matricula.valid) {
      this.activeModal.close(this.matricula.value)
    }else{
      this.activeModal.close('');
    }    
  }

  get matricula() {
    return this.matriculaFormControl;
  }

}
