<div [formGroup]="form">
  <div class="mb-3 col-md-6">

    <div class="mb-3 row">
      <label for="laboratorioFormControl" class="col-sm-4 col-form-label">
        <span *ngIf="esCampoVacio(this.laboratorioFormControl.value)">*</span>Laboratorio:
      </label>
      <div class="col-sm-6">
        <select id="laboratorioFormControl" class="form-select" type="text" ngbTooltip="Seleccione un laboratorio."
          formControlName="laboratorioFormControl">
          <option *ngFor="let laboratorio of laboratorios" [ngValue]="laboratorio.id">{{ laboratorio.nombre }}
          </option>
        </select>
        <loading-text-spinner *ngIf="cargandoLaboratorios" [error]="errorLaboratorios" message="Obteniendo laboratorios.."
          color="primary">
        </loading-text-spinner>
        <div class="alert-danger"
          *ngIf="(this.laboratorioFormControl.invalid && this.laboratorioFormControl.errors.required && (this.laboratorioFormControl.dirty || this.laboratorioFormControl.touched))">
          Debe seleccionar un laboratorio
        </div>
      </div>
    </div>

    <div class="mb-3 row">
      <label for="fechaPruebaLaboratorioFormControl" class="col-sm-4 col-form-label">
        Fecha de prueba:
      </label>
      <div class="col-sm-6">
        <div class="input-group mb-2">
          <input type="date" 
            class="form-control" 
            formControlName="fechaPruebaLaboratorioFormControl"
            placeholder="aaaa-mm-dd" 
            name="dp" 
            title="Campo obligatorio." 
            [(ngModel)]="modelFechaPruebaLaboratorio" 
            ngbDatepicker
            #l="ngbDatepicker" 
            readonly />
          <div class="input-group-append">
            <button type="button" class="input-group-btn btn btn-secondary" (click)="l.toggle()">
              <i class="fa fa-calendar" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="mb-3 row">
      <label for="resultadoPruebaLaboratorioFormControl" class="col-sm-4 col-form-label">
        <span *ngIf="esCampoVacio(this.resultadoPruebaLaboratorioFormControl.value)">*</span>Resultado de la prueba:
      </label>
      <div class="col-sm-6">
        <select id="resultadoPruebaLaboratorioFormControl" type="text" class="form-select" name="resultadoPruebaLaboratorioFormControl"
          formControlName="resultadoPruebaLaboratorioFormControl">

          <option id="optionResultadoPruebaLaboratorio" 
            *ngFor="let resultado of keysResultadosPruebaLaboratorio()"
            [ngValue]="enumResultadoPruebaLaboratorio[resultado]">
            {{ resultado }}
          </option>
        </select>      
        <div class="alert alert-danger"
            *ngIf="resultadoPruebaLaboratorioFormControl.invalid && (resultadoPruebaLaboratorioFormControl.errors.required || resultadoPruebaLaboratorioFormControl.errors.aprobadoRequired)">
            El resultado de la prueba debe ser Aprobado para poder continuar.
        </div> 
      </div>   
    </div>

    <div class="mb-3 row">
      <label for="fechaInicioAlijeFormControl" class="col-sm-4 col-form-label">
        Fecha y hora inicio de alije:
      </label>
      <div class="col-sm-6">
        <div class="input-group mb-2">
          <input type="date" class="form-control" formControlName="fechaInicioAlijeFormControl"
            placeholder="aaaa-mm-dd" name="dp" title="Campo obligatorio." [(ngModel)]="modelFechaInicioAlije"
            ngbDatepicker #datepickerInicioAlije="ngbDatepicker" readonly />
          <div class="input-group-append">
            <button type="button" class="input-group-btn btn btn-secondary" (click)="datepickerInicioAlije.toggle()">
              <i class="fa fa-calendar" aria-hidden="true"></i>
            </button>
          </div>
        </div>
        <ngb-timepicker [(ngModel)]="modelHoraInicioAlije" [meridian]="true" formControlName="horaInicioAlijeFormControl" [readonlyInputs]="true"></ngb-timepicker>
        <div class="alert alert-danger" *ngIf="form.invalid && this.form.hasError('fechaInicioMayorAhora')">
          Esta cargando una fecha de inicio de alije en el futuro.
        </div>
      </div>
    </div>

    <div class="mb-3 row">
      <label for="fechaFinAlijeFormControl" class="col-sm-4 col-form-label">
        Fecha y hora fin de alije:
      </label>
      <div class="col-sm-6">
        <div class="input-group mb-2">
          <input type="date" class="form-control" formControlName="fechaFinAlijeFormControl"
            placeholder="aaaa-mm-dd" name="dp" title="Campo obligatorio." [(ngModel)]="modelFechaFinAlije"
            ngbDatepicker #datepickerFinAlije="ngbDatepicker" readonly />
          <div class="input-group-append">
            <button type="button" class="input-group-btn btn btn-secondary" (click)="datepickerFinAlije.toggle()">
              <i class="fa fa-calendar" aria-hidden="true"></i>
            </button>
          </div>
        </div>
        <ngb-timepicker [(ngModel)]="modelHoraFinAlije" [meridian]="true" formControlName="horaFinAlijeFormControl" [readonlyInputs]="true"></ngb-timepicker>
        <div class="alert alert-danger" *ngIf="form.invalid && this.form.hasError('fechaFinMayorAhora')">
          Esta cargando una fecha de fin de alije en el futuro.
        </div>
        <div class="alert alert-danger" *ngIf="form.invalid && this.form.hasError('fechaInicioMayorFin')">
          La fecha de inicio de alije debe ser menor a la fecha de fin de alije.
        </div>
      </div>
    </div>

    <div class="mb-3 row">
      <label for="varilladoInicialFormControl" class="col-sm-4 col-form-label">Varillado Inicial</label>
      <div class="col-sm-6">
        <input id="varilladoInicialFormControl" type="number" min="0" class="form-control mb-2 me-sm-2" title="Campo obligatorio, debe ingresar varillado inicial." formControlName="varilladoInicialFormControl" placeholder="####"(change)="obtenerCantidadAlijada(); obtenerCantidadAlijada15()" (keypress)="this.onlyNumberKey($event)"/>
      </div>
      <div class="alert-danger" *ngIf="this.varilladoInicialFormControl.invalid && this.varilladoInicialFormControl.errors.required && (this.varilladoInicialFormControl.dirty || this.varilladoInicialFormControl.touched)">
        Debe completar el campo varillado inicial.
      </div>
    </div>

    <div class="mb-3 row">
        <label for="varilladoFinalFormControl" class="col-sm-4 col-form-label">Varillado Final:</label>
        <div class="col-sm-6">
          <input id="varilladoFinalFormControl" type="number" min="0" class="form-control mb-2 me-sm-2" title="Campo obligatorio, debe ingresar varillado final." formControlName="varilladoFinalFormControl" placeholder="####" (change)="obtenerCantidadAlijada(); obtenerCantidadAlijada15()" (keypress)="this.onlyNumberKey($event)"/>
        </div>
        <div class="alert alert-danger" *ngIf="this.varilladoFinalFormControl.invalid && this.varilladoFinalFormControl.errors.required && (this.varilladoFinalFormControl.dirty || this.varilladoFinalFormControl.touched)">
          Debe completar el campo varillado final.
        </div>
        <div class="alert alert-danger" *ngIf="form.invalid && this.form.hasError('varilladoInicialMayorVarilladoFinal') && (varilladoInicialFormControl.dirty || varilladoInicialFormControl.touched) && (varilladoFinalFormControl.dirty || varilladoFinalFormControl.touched)">
          El varillado final debe ser mayor que el varillado inicial.
        </div>
    </div>

    <div class="mb-3 row">
      <label for="cantidadAlijeFormControl" class="col-sm-4 col-form-label">Volumen alijado:</label>
      <div class="col-sm-6">
        <input id="cantidadAlijeFormControl" type="text" class="form-control mb-2 me-sm-2" formControlName="cantidadAlijeFormControl" placeholder="####" readonly/>
      </div>
    </div>

    <div class="mb-3 row">
      <label for="aforadorInicialFormControl" class="col-sm-4 col-form-label">Aforador Inicial</label>
      <div class="col-sm-6">
        <input id="aforadorInicialFormControl" type="number" min="0" class="form-control mb-2 me-sm-2" title="Campo obligatorio, debe ingresar aforador inicial." formControlName="aforadorInicialFormControl" placeholder="####" (keypress)="this.onlyNumberKey($event)"/>
      </div>
      <div class="alert-danger" *ngIf="(this.aforadorInicialFormControl.invalid && this.aforadorInicialFormControl.errors.required && (this.aforadorInicialFormControl.dirty || this.aforadorInicialFormControl.touched) || (this.aforadorInicialFormControl.invalid && this.aforadorInicialFormControl.errors.required && (this.aforadorInicialFormControl.dirty || this.aforadorInicialFormControl.touched)))">
        Debe completar los campos aforadores
      </div>
    </div>

    <div class="mb-3 row">
      <label for="aforadorFinalFormControl" class="col-sm-4 col-form-label">Aforador Final:</label>
      <div class="col-sm-6">
        <input id="aforadorFinalFormControl" type="number" min="0" class="form-control mb-2 me-sm-2"
          title="Campo obligatorio, debe ingresar aforador final." formControlName="aforadorFinalFormControl"
          placeholder="####" (keypress)="this.onlyNumberKey($event)"/>
      </div>
    </div>
    
    <div class="mb-3 row">
      <label for="temperaturaFormControl" class="col-form-label col-sm-4"><span
          *ngIf="esCampoVacio(this.temperaturaFormControl.value)">*</span> Temperatura(C):</label>
      <div class="col-sm-6">
        <div class="input-group mb-2">
          <input id="temperaturaFormControl" type="number" class="form-control"
            title="Campo obligatorio."
            formControlName="temperaturaFormControl" (change)="obtenerCantidadAlijada15()" placeholder="grados" />
          <div class="input-group-append">
            <div class="input-group-text">Grados</div>
          </div>
        </div>
        <div class="alert alert-danger"
          *ngIf="(this.temperaturaFormControl.invalid && this.temperaturaFormControl.errors.required && (this.temperaturaFormControl.dirty || this.temperaturaFormControl.touched))">
          Requerido
        </div>
        <div class="alert alert-danger"
          *ngIf="temperaturaFormControl.invalid && (temperaturaFormControl.errors.temperaturaFueraDeRango) && (temperaturaFormControl.dirty || temperaturaFormControl.touched)">
          La temperatura esta fuera de rango permitido.
        </div>
      </div>
    </div>
    
    <div class="mb-3 row">
      <label for="densidadFormControl" class="col-form-label col-sm-4"> <span
          *ngIf="esCampoVacio(this.densidadFormControl.value)">*</span> Densidad:</label>
      <div class="col-sm-6">
        <ng-template #tipContent>
        </ng-template>
        <div class="input-group mb-2">
          <input id="densidadFormControl" type="number" class="form-control"
            title="Campo obligatorio, debe ingresar un valor mayor a 0" (keypress)="formatDensidad($event)"
            formControlName="densidadFormControl"
            (blur)="obtenerCantidadAlijada15()"
            triggers="input:blur" />
          <div class="input-group-append">
            <div class="input-group-text">Gr/cm</div>
          </div>
        </div>
        <div class="alert alert-danger"
          *ngIf="(this.densidadFormControl.invalid && this.densidadFormControl.errors.required && (this.densidadFormControl.dirty || this.densidadFormControl.touched))">
          Requerido
        </div>
        <div class="alert alert-danger"
          *ngIf="(this.densidadFormControl.dirty || this.densidadFormControl.touched) && !this.esValidaDensidad">
          La densidad debe estar dentro del rango del producto. [{{this.esDensidadMin}} - {{this.esDensidadMax}}]
        </div>
      </div>
    </div>
    
    <div class="mb-3">
      <div class="row">
        <label class="col-form-label col-sm-4">Prueba de agua: </label>
        <section class="col-md-8">
        <div class="btn-group btn-group-toggle col-md-8" ngbRadioGroup name="radioPruebaAgua"
                ngbTooltip="Recuerde controlar la prueba de agua, debe ser negativa para la creacion del aerovale">
                <input ngbButton type="radio" class="btn-check"  [value]="enumResultadoPruebaDeAgua.Positiva"
                    formControlName="resultadoPruebaDeAguaFormControl" id="pap" />
                <label ngbButtonLabel class="btn-outline-primary btn col-md-4" for="pap">
                  Positiva
                </label>
                <input ngbButton type="radio" class="btn-check" [value]="enumResultadoPruebaDeAgua.Negativa"
                    formControlName="resultadoPruebaDeAguaFormControl" id="pan" />
                <label ngbButtonLabel class="btn-outline-primary btn col-md-4" for="pan">
                  Negativa
                </label>
          </div>
        <div class="alert alert-danger col-md-8"
          *ngIf="resultadoPruebaDeAguaFormControl.invalid && (resultadoPruebaDeAguaFormControl.errors.required || resultadoPruebaDeAguaFormControl.errors.negativoRequired)">
         El resultado de la prueba de agua debe ser Negativo para poder continuar.
        </div> 
        </section>
      </div> 
    </div>
    
    <div class="mb-3 row">
      <label for="cantidadAlije15FormControl" class="col-sm-4">Volumen alijado a 15 grados (litros):</label>
      <div class="col-sm-6">        
        <input id="cantidadAlije15FormControl" type="text" class="form-control mb-2 me-sm-2" formControlName="cantidadAlije15FormControl" placeholder="####" readonly>         
      </div>
    </div>

  </div>
</div>

