<div class="col col-md-12" *ngIf="cargandoDatos">
    <div class="spinner-container">
        <ngx-spinner name="spinnerABM" [ngStyle]="{'height': '400px', 'z-index':'0'}" bdOpacity=0.3bd
            Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
            <p style="color: white">C a r g a n d o . . . </p>
        </ngx-spinner>
    </div>
</div>

<div *ngIf="!cargandoDatos" class="border">
    <form [formGroup]="aerolineaForm">
        <div class="modal-header bg-light">
            <h4 class="p-2 modal-title">Cliente: {{ this.clienteEncabezadoSeleccionado.razonSocial }}</h4>
        </div>
    
        <div class="p-4 modal-body">
            <!-- Codigo de Aerolinea -->
            <div class="row">
                <div class="mb-3 row col-8">
                    <label for="codigoFormControl" class="col-4 col-form-label">*Código Aerolínea:</label>
                    <div class="col-4">
                        <input id="codigoFormControl" formControlName="codigoFormControl" type="text"
                            class="form-control"  style="text-transform:uppercase" maxlength="3"/>
                        <div *ngIf="codigoFormControl.invalid && codigoFormControl.errors.required && (codigoFormControl.dirty || codigoFormControl.touched)"
                            class="alert alert-danger">Requerido
                        </div>
                    </div>
                </div>
            </div>
            <br>

            <!-- Cuenta Nacional -->
            <div class="row">
                <div class="mb-3 row col-8">
                    <label for="cuentaNacionalFormControl" class="col-4 col-form-label">Cuenta para vuelos Nacionales:</label>
                    <div class="col-4">
                        <select class="form-select" formControlName="cuentaNacionalFormControl" id="cuentaNacionalFormControl">
                            <option *ngIf="!this.cargandoCuentas" [value]="null">Seleccione una cuenta</option>
                            <option *ngFor="let c of cuentasCliente" [value]="c.ctA_SGC">
                                {{c.ctA_SGC}}
                            </option>
                        </select>
                        <loading-text-spinner *ngIf="this.cargandoCuentas" message="Obteniendo cuentas..." color="primary">
                        </loading-text-spinner>
                    </div>
                </div>
            </div>
            <br>

            <!-- Cuenta Internacional -->
            <div class="row">
                <div class="mb-3 row col-8">
                    <label for="cuentaInternacionalFormControl" class="col-4 col-form-label">Cuenta para vuelos Internacionales:</label>
                    <div class="col-4">
                        <select class="form-select" formControlName="cuentaInternacionalFormControl" id="cuentaInternacionalFormControl">
                            <option *ngIf="!this.cargandoCuentas" [value]="null">Seleccione una cuenta</option>
                            <option *ngFor="let c of cuentasCliente" [value]="c.ctA_SGC">
                                {{c.ctA_SGC}}
                            </option>
                        </select>
                        <loading-text-spinner *ngIf="this.cargandoCuentas" message="Obteniendo cuentas..." color="primary">
                        </loading-text-spinner>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <div class="card-footer p-2 d-flex justify-content-center">
        <span class="border">
            <button type="button" class="btn btn-primary" (click)="guardarCambios()"
                [disabled]="guardandoDatos || aerolineaForm.invalid">
                <span *ngIf="!guardandoDatos">Guardar cambios</span>
                <div *ngIf="guardandoDatos" class="d-flex align-items-center">
                    <div class="spinner-border text-light spinner-border-sm" role="status"></div>
                    <span>Guardando...</span>
                </div>
            </button>
        </span>
    </div>    
</div>