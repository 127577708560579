<div class="card">
  <div class="card-header">
    <h4 style="display: inline;" class="card-title">ABM de grupos / Asignación de tanques a grupos.</h4>
    <button *ngIf="this.hasRole(TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.RESPRODUCTO, TipoUsuario.TUTOR)" style="margin-left: 4em;" class="btn btn-primary" type="button" (click)="agregarGrupo()">Agregar nuevo
      grupo</button>
    <div style="padding: 0.5em; margin-top: 1em; border: 1px solid #666666">
      <span>Productos disponibles:</span>
      <ul *ngIf="!procesandoProductos" style="display:inline-flex; margin-left: 2em;" class="nav nav-pills">
        <li style="margin-right: 4em;" class="nav-item" *ngFor="let p of productos; index as i">
          <a [ngClass]="{'active': productoSeleccionado!=null && productoSeleccionado.codigoProducto==p.codigoProducto}"
            class="nav-link" (click)="cambiarProducto(p)" style="cursor: pointer;">{{ p.codigoProducto }} -
            {{ p.nombreProducto }}</a>
        </li>
        <li style="margin-right: 4em;" class="nav-item">
          <a [ngClass]="{'active': productoSeleccionado==null}" class="nav-link" (click)="cambiarProducto(null)"
            style="cursor: pointer;">Mostrar todos los grupos</a>
        </li>
      </ul>
      <div *ngIf="procesandoProductos" class="row">
        <loading-text-spinner class="col-md-12" message="Obteniendo productos..." color="primary">
        </loading-text-spinner>
      </div>
    </div>
  </div>

  <div class="card-body">
    <div class="subtitulo" *ngIf="productoSeleccionado!=null">
      Producto: {{productoSeleccionado.codigoProducto}} - {{productoSeleccionado.nombreProducto}}
      <span class="tip-drag">Atención: Arrastre y suelte los tanques para asignarlos a grupos.</span>
    </div>
    <div class="subtitulo" *ngIf="productoSeleccionado==null">Todos los grupos</div>
    <div cdkDropListGroup>
      <div class="row" style="margin: 0px;">
        <div *ngIf="productoSeleccionado != null" class="col-md-3 col-lg-3 col-xl-3">
          <div class="card" style="margin-bottom: 12px;">
            <div class="card-header bg-primary">
              <h5 class="mb-0">Tanques sin asignar a grupo</h5>
            </div>
            <div class="card-body">
              <div cdkDropList [cdkDropListData]="tanquesLibres" class="item-list"
                (cdkDropListDropped)="onDrop($event)">
                <ng-container *ngFor="let t of tanquesLibres">
                  <div class="item-block"
                    [class.item-oculto]="t.productoActual.codigoProducto!=productoSeleccionado.codigoProducto" cdkDrag>
                    {{t.nombre}}
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-9 col-lg-9 col-xl-9">
          <div *ngIf="!procesandoGrupos && !procesandoProductos" class="row" style="margin: 0px;">
            <ng-container *ngFor="let g of gruposFiltrados">
              <div class="col-md-6 col-lg-6 col-xl-4">
                <div class="card">
                  <div class="card-header bg-primary">
                    <h5 class="mb-0">
                      {{g.nombre}}
                      <button *ngIf="this.hasRole(TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.RESPRODUCTO, TipoUsuario.TUTOR) && g.tanques.length===0" type="button" style="float:right" (click)="eliminarGrupo(g)"
                        class="btn btn-danger btn-sm"><i class="fa fa-trash fa-lg"></i></button>
                      <button  *ngIf="this.hasRole(TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.RESPRODUCTO, TipoUsuario.TUTOR)" type="button" style="float:right; margin-right: 1em;" (click)="editarGrupo(g)"
                        class="btn btn-light btn-sm" ngbTooltip="Editar"><i class="fa fa-pencil fa-lg"></i></button>
                      &nbsp;
                    </h5>
                    <h6 class="mb-0">{{g.productoActual.codigoProducto}} - {{g.productoActual.nombreProducto}}</h6>
                  </div>
                  <div class="card-body">
                    <div cdkDropList [cdkDropListData]="g.tanques" class="item-list"
                      (cdkDropListDropped)="onDrop($event)">
                      <div class="item-block" *ngFor="let t of g.tanques" cdkDrag>
                        {{t.codigo}} - {{t.nombre}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <div *ngIf="productoSeleccionado != null && gruposFiltrados.length == 0">
              No se han creado grupos aún para <b>{{productoSeleccionado.nombreProducto}}</b>.
            </div>
          </div>
          <div *ngIf="procesandoGrupos || procesandoProductos" class="row">
            <loading-text-spinner class="col-md-12" class="col-md-12" message="Obteniendo grupos..." color="primary">
            </loading-text-spinner>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="this.hasRole(TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.RESPRODUCTO, TipoUsuario.TUTOR)" class="card-footer d-flex justify-content-center">
    <span class="border">
      <button class="btn btn-primary" type="button" (click)="guardarAsignaciones()" [disabled]="guardandoAsignaciones">
        <span *ngIf="!guardandoAsignaciones">Guardar asignaciones realizadas</span>
        <div *ngIf="guardandoAsignaciones" class="d-flex align-items-center">
          <div class="spinner-border text-light spinner-border-sm" role="status"></div>
          <span>Guardando asignaciones ...</span>
        </div>
      </button>
    </span>
    <span class="border">
      <button type="button" class="btn btn-secondary" [disabled]="guardandoAsignaciones"
        (click)="cancelar()">Cancelar</button>
    </span>
  </div>
</div>

<div class="card" id="islas-card">
  <div class="card-header">
    <h4 style="display: inline;" class="card-title">Alta y baja de islas.</h4>
    <button  *ngIf="this.hasRole(TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.RESPRODUCTO, TipoUsuario.TUTOR)" style="margin-left: 4em;" class="btn btn-primary" type="button" (click)="agregarIsla()">Agregar nueva
      isla</button>
  </div>
  <div class="card-body">

    <div *ngIf="!procesandoIslas" class="row">
      <ngb-alert [dismissible]="false" type="warning" *ngIf="!this.procesandoIslas && this.islas.length===0">
        No se encontraron islas para la aeroplanta seleccionada.
      </ngb-alert>
      <div *ngFor="let i of islas" class="col-md-4 col-lg-3 col-xl-2 contenedor-isla">
        {{i}}
        <button  *ngIf="this.hasRole(TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.RESPRODUCTO, TipoUsuario.TUTOR)" type="button" (click)="eliminarIsla(i)" class="btn btn-danger btn-sm"><i
            class="fa fa-trash fa-lg"></i></button>
      </div>
    </div>
    <div *ngIf="procesandoIslas" class="row">
      <loading-text-spinner message="Obteniendo islas..." color="primary"></loading-text-spinner>
    </div>
  </div>
</div>
