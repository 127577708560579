import { Component, OnInit, Input } from '@angular/core';
import { fadeAnimationTrigger } from '../../../shared/animations';
import { Provincia } from 'src/app/shared/models/facturacion/Provincia';
import { ParametrosImpositivosService } from '../../parametros-impositivos.service';
import { ParametroImpositivoEncabezado } from 'src/app/shared/models/facturacion/ParametroImpositivoEncabezado';
import { Subscription, PartialObserver } from 'rxjs';
import { MessageService } from 'src/app/shell/message.service';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';

@Component({
  selector: 'app-listado-provincias',
  templateUrl: './listado-provincias.component.html',
  styleUrls: ['./listado-provincias.component.css'],
  animations: fadeAnimationTrigger
})
export class ListadoProvinciasComponent implements OnInit {

  private _textoBusqueda;

  get textoBusqueda(): string { 
    return this._textoBusqueda;
  }
  @Input()
  set textoBusqueda(texto: string) {
    // Evento para recibir notificaciones al haber cambios en el input textoBusqueda
    this.filtrarProvincias(texto); 
  }

  obteniendoProvincias: boolean;
  errorProvincias: boolean = false;
  
  private provinciasEncabezadoSubscription: Subscription;
  listaProvinciasEncabezado: ParametroImpositivoEncabezado[] = [];
  listaProvinciasEncabezadoFiltradas: ParametroImpositivoEncabezado[] = [];

  private provinciasSeleccionadaSubscription: Subscription;
  provinciaEncabezadoSeleccionada: ParametroImpositivoEncabezado;

  constructor(
    private parametrosService: ParametrosImpositivosService,
    private messageService: MessageService) { }

  ngOnInit() {
    this.provinciasEncabezadoSubscription = this.parametrosService.listadoProvinciasEncabezados.subscribe(
      (listadoProvinciasEncabezadosRx) => {
        if(listadoProvinciasEncabezadosRx){
          listadoProvinciasEncabezadosRx = listadoProvinciasEncabezadosRx.sort((a, b) => a.provinciaNombre.localeCompare(b.provinciaNombre));
          this.listaProvinciasEncabezado = listadoProvinciasEncabezadosRx;
          this.listaProvinciasEncabezadoFiltradas = listadoProvinciasEncabezadosRx;
        }        
      }
    );

    this.provinciasSeleccionadaSubscription = this.parametrosService.provinciaEncabezadoSeleccionada.subscribe(
      (provinciaEncabezadoSeleccionada) => {
        if (provinciaEncabezadoSeleccionada) {
          this.provinciaEncabezadoSeleccionada = provinciaEncabezadoSeleccionada;
        }
      }
    );

    this.obtenerProvinciasEncabezados();
  }

  obtenerProvinciasEncabezados(){
    this.obteniendoProvincias = true;

    this.parametrosService.obtenerProvinciasEncabezadoV2().subscribe(
        (provincias: ParametroImpositivoEncabezado[]) => {        
          this.obteniendoProvincias = false;
      },
      () => { this.errorProvincias = true; }
    );
  }

  onProvinciaSeleccionada(provincia){
    this.parametrosService.seleccionarProvinciaEncabezado(provincia);
  }

  filtrarProvincias(texto){
    if(this.listaProvinciasEncabezado){
      this.listaProvinciasEncabezadoFiltradas = this.listaProvinciasEncabezado.filter(p => {
        let resultadoValido = true;
        if (texto) {
          resultadoValido = p.provinciaNombre.toLowerCase().indexOf(texto.toLowerCase()) > -1;        
        }
  
        return resultadoValido;
      });  
      let a = 0;    
    }    
  }
}
