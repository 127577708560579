<loading-text-spinner *ngIf="obteniendoProvincias" [error]="errorProvincias" message="Obteniendo listado de provincias..."
  orientation="vertical" class="list-group-item" size="large" color="primary" textColor="dark">
</loading-text-spinner>
<div *ngIf="!obteniendoProvincias">
  <div *ngFor="let provincia of listaProvinciasEncabezadoFiltradas">
    <button [@fadeIn] type="button" 
      (click)="onProvinciaSeleccionada(provincia)"
      [class.active]="provinciaEncabezadoSeleccionada && provincia.provinciaCodigo === provinciaEncabezadoSeleccionada.provinciaCodigo"
      class="list-group-item list-group-item-action">
  
    <div class="text-center"><b> {{ provincia.provinciaNombre }}</b></div>
  </button>
  </div>
</div>
