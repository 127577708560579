
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { AlicuotaDiferencial } from './AlicuotaDiferencial';
import { Provincia } from './Provincia';

export class ConfigClienteProvincia{
    id: string;
    provincia: Provincia;
    numeroIIBB: string;
    condicionIIBB: number;
    alicuotaDiferencial: AlicuotaDiferencial;
    // fecha: string;
    fechaDesde: NgbDateStruct;
    fechaHasta: NgbDateStruct;
}