<div class="card add-margin">
  <form [formGroup]="form">
    <div class="card-header">
      <button type="button" class="btn-close float-end" aria-label="Close" (click)="this.onCancelar()"></button>
      <h4 class="card-title text-center">
        {{ editando ? "Edición nivel de aprobación" : "Nuevo nivel" }}
      </h4>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="mb-3 row col-md-12">
          <label for="codigoFormControl" class="col-sm-5 col-form-label">*Codigo:</label>
          <div class="col-sm-7">
            <input class="form-control" formControlName="codigoFormControl" maxlength="50">
            <div *ngIf="codigoFormControl.invalid && codigoFormControl.errors.required &&
                          (codigoFormControl.dirty || codigoFormControl.touched)" class="alert alert-danger">
              Requerido</div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="mb-3 row col-md-12">
          <label for="nombreFormControl" class="col-sm-5 col-form-label"> *Nombre: </label>
          <div class="col-sm-7">
            <input id="nombreFormControl" type="text" class="form-control mb-2" formControlName="nombreFormControl" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="mb-3 row col-md-12">
          <label for="montoMaximoFormControl" class="col-sm-5 col-form-label"> *Monto Máximo:</label>
          <div class="col-sm-7">
            <input min="0" id="montoMaximoFormControl" type="number" class="form-control mb-2"
              formControlName="montoMaximoFormControl" (keypress)="onlyNumberKey($event)" />
          </div>
        </div>
      </div>
      <div>
        <div class="row">
          <div class="col-sm-4">
            <div class="form-check">
              <input (change)="this.seleccionarDocumentos(0, $event)" type="checkbox" class="form-check-input"
                id="exampleCheck" [value]='0' formControlName="plazoCheckFormControl" />
              <label class="form-check-label" for="exampleCheck">Plazo</label>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-check">
              <input (change)="this.seleccionarDocumentos(1, $event)" type="checkbox" class="form-check-input"
                id="exampleCheck1" [value]='1' formControlName="impuestosCheckFormControl" />
              <label class="form-check-label" for="exampleCheck1">Impuestos</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4">
            <div class="form-check">
              <input (change)="this.seleccionarDocumentos(2, $event)" type="checkbox" class="form-check-input"
                id="exampleCheck2" [value]='2' formControlName="otrosCheckFormControl" />
              <label class="form-check-label" for="exampleCheck2">Otros</label>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-check">
              <input (change)="this.seleccionarDocumentos(3, $event)" type="checkbox" class="form-check-input"
                id="exampleCheck3" [value]='3' formControlName="preciosCheckFormControl" />
              <label class="form-check-label" for="exampleCheck3">Precios</label>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-check">
              <input (change)="this.seleccionarDocumentos(4, $event)" type="checkbox" class="form-check-input"
                id="exampleCheck4" [value]='4' formControlName="despachoCheckFormControl" />
              <label class="form-check-label" for="exampleCheck4">Despachos</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer d-flex justify-content-center">
      <button class="btn btn-primary me-2" type="button" (click)="onSubmit()" [disabled]="this.noPuedeGuardarNivel()">
        <span *ngIf="!guardandoNivel">Guardar</span>
        <div *ngIf="guardandoNivel" class="d-flex align-items-center">
          <div class="spinner-border text-light spinner-border-sm" role="status"></div>
          <span>Guardando...</span>
        </div>
      </button>
      <button type="button" class="btn btn-secondary" (click)="onCancelar()">Cancelar</button>
    </div>
  </form>
</div>