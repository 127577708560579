import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AgrupacionRemitos } from 'src/app/shared/enums/agrupacionRemitos';
import { TipoCuenta } from 'src/app/shared/enums/tipoCuenta';
import { TipoEntregaFactura } from 'src/app/shared/enums/tipoEntregaFactura';
import { TipoMoneda } from 'src/app/shared/enums/tipoMoneda';
import { Cliente } from 'src/app/shared/models/cliente/Cliente';
import { Cuenta } from 'src/app/shared/models/cliente/Cuenta';
import { PeriodosFacturacion } from 'src/app/shared/models/cliente/PeriodosFacturacion';
import { PlazoPagoCliente } from 'src/app/shared/models/cliente/PlazoPagoCliente';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { MessageService } from 'src/app/shell/message.service';
import { ClienteService } from '../cliente.service';
import { CuentasService } from '../cuentas.service';

@Component({
  selector: 'app-modal-nueva-cuenta',
  templateUrl: './modal-nueva-cuenta.component.html',
  styleUrls: ['./modal-nueva-cuenta.component.css']
})
export class ModalNuevaCuentaComponent implements OnInit {
  @Input() cliente: Cliente;
  @Output() cuentaNueva: EventEmitter<Cuenta> = new EventEmitter();
  creandoCuenta: boolean = false;
  tipoEntregaFacturaEnum = TipoEntregaFactura;
  agrupacionRemitosEnum = AgrupacionRemitos;
  tipoMoneda = TipoMoneda;
  tipoCuenta = TipoCuenta;
  perFacturacion: PeriodosFacturacion[];
  existeCuenta: boolean = false;
  verificandoCuenta: boolean = false;
  cuentaObtenida: string;
  cuentaMalSeteada: boolean = false;
  condicionesPago: PlazoPagoCliente[];

  error: string;

  nuevaCuentaForm = new UntypedFormGroup({
    numeroCuentaFormControl: new UntypedFormControl(null, [Validators.required]),
    condicionVentaFormControl: new UntypedFormControl(null, [Validators.required]),
    monedaFormControl: new UntypedFormControl(null, [Validators.required]),
    entregaFacturaFormControl: new UntypedFormControl(null),
    identificadorFormControl: new UntypedFormControl(null, [Validators.required]),
    emailFormControl: new UntypedFormControl(null),
    periodoFormControl: new UntypedFormControl(null, [Validators.required]),
    notificacionFormControl: new UntypedFormControl(null),
    agrupacionRemitosFormControl: new UntypedFormControl(null),
    diasFormControl: new UntypedFormControl(null),
    plazoPagoFormControl: new UntypedFormControl(null), 
    soloPADRemitosFormControl: new UntypedFormControl(null)
  });


  constructor(public activeModal: NgbModal, private clienteService: ClienteService, private messageService: MessageService, private cuentasService: CuentasService) { }

  ngOnInit() {
    this.getPeriodosFacturacion();
    this.moneda.setValue(0);
    this.entregaFactura.setValue(0);
    this.agrupacionRemitos.setValue(0);
    this.condicionVenta.setValue("Contado");
    this.email.setValue('');
    this.soloPADRemitosFormControl.setValue(false);
    this.notificacion.setValue(false);
    this.obtenerNumeroCuenta();
    this.getCondicionesPago();
  }

  obtenerNumeroCuenta() {
    this.verificandoCuenta = true;
    this.cuentasService.obtenerProximoCuenta()
      .subscribe(result => {
        this.numeroCuenta.setValue(result);
        this.cuentaObtenida = result;
        this.verificandoCuenta = false;
      });
  }

  obtenerCond(cond: string) {
    switch (cond) {
      case 'Indeterminado':
        return 0;
      case 'Contado':
        return 1;
      case 'CuentaCorriente':
        return 2;
      case 'PrevioPago':
        return 3;
      case 'Donacion':
        return 4;
      case 'ConsumoInterno':
        return 5;
      default:
        return 0;
    }
  }

  obtenerMoneda(cond: string) {
    switch (cond) {
      case 'PES':
        return 0;
      case 'USD':
        return 1;
      default:
        return 0;
    }
  }

  obtenerEntrega(cond: string) {
    switch (cond) {
      case 'Email':
        return 0;
      case 'Fisico':
        return 1;
      case 'Ambos':
        return 2;
      default:
        return 0;
    }
  }

  obtenerAgrupacion(cond: string) {
    switch (cond) {
      case 'DireccionEntrega':
        return 0;
      case 'Aeroplanta':
        return 1;
      case 'Provincia':
        return 2;
      default:
        return 0;
    }
  }

  getPeriodosFacturacion() {
    this.cuentasService.getPeriodosFacturacion()
      .subscribe(result => {
        this.perFacturacion = result;
        this.periodo.setValue("Diario");
      });
  }

  getCondicionesPago(){
    this.condicionesPago = [];
    this.clienteService.getCondicionesPago()
      .subscribe(result => {
        this.condicionesPago = result;   
        this.condicionesPago = this.condicionesPago.sort(function(a, b) {
          return a.codigo.localeCompare(b.codigo, undefined, {
            numeric: true,
            sensitivity: 'base'
          });
        }); 

        this.plazoPagoFormControl.setValue(this.condicionesPago[0]);
      }, (err: ErrorModel) => {
      });
  }

  cuentaValida(){
    if(this.numeroCuenta.value == null || this.numeroCuenta.value == ""){
      this.error = "Debe ingresar un numero de cuenta.";
      return false;
    }

    if(this.identificador.value == null || this.identificador.value == ""){
      this.error = "Debe ingresar el identificador de cuenta.";
      return false;
    }

    if(this.plazoPagoFormControl.value == null){
      this.error = "Debe seleccionar un plazo de pago.";
      return false;
    }

    return true;
  }

  crearCuenta() {
    this.creandoCuenta = true;

    if( ! this.cuentaValida() ){
      this.creandoCuenta = false;
      return;
    }

    if (this.nuevaCuentaForm.valid && !this.existeCuenta) {

      let cuen = new Cuenta();
      cuen.ctA_SGC = (this.numeroCuenta.value);
      cuen.descripcion = this.identificador.value; //ver que onda
      cuen.email = this.email.value;
      cuen.tipoEntregaFactura = this.obtenerEntrega(this.entregaFactura.value);
      cuen.notificaPorEmail = this.notificacion.value;
      cuen.moneda = this.obtenerMoneda(this.moneda.value);
      // cuen.periodoFacturacion = this.periodo.value;
      cuen.agrupacionRemitos = this.obtenerAgrupacion(this.agrupacionRemitos.value);
      cuen.tipoCuenta = this.obtenerCond(this.condicionVenta.value);
      cuen.esNueva = true;
      cuen.condicionPago = this.plazoPagoFormControl.value;
      cuen.soloPAD = this.soloPADRemitosFormControl.value;

      for (let p of this.perFacturacion) {
        if (p.descripcion == this.periodo.value) {
          cuen.periodoFacturacion = p;
        }
        if (p.descripcion.startsWith('Semanal') && this.periodo.value == 'Semanal') {
          if (p.diaEjecucion == this.diasFormControl.value) {
            cuen.periodoFacturacion = p;
          }
        }
      }
      if (cuen.periodoFacturacion != null) {
        cuen.activo = true;
        this.cuentaNueva.emit(cuen);
        this.creandoCuenta = false;
        this.activeModal.dismissAll();
      } else {
        this.creandoCuenta = false;
        this.messageService.showErrorMessage('Por favor revise los datos a completar.');
      }

    } else {
      this.creandoCuenta = false;
      this.messageService.showErrorMessage('Por favor revise los datos a completar.');
    }
  }

  keysTipoEntrega(): Array<string> {
    var keys = Object.keys(this.tipoEntregaFacturaEnum);
    keys = keys.slice(keys.length / 2);
    return keys;
  }

  keysAgrupacion(): Array<string> {
    var keys = Object.keys(this.agrupacionRemitosEnum);
    keys = keys.slice(keys.length / 2);
    return keys;
  }

  keysMonedas(): Array<string> {
    var keys = Object.keys(this.tipoMoneda);
    keys = keys.slice(keys.length / 2);
    return keys;
  }

  onlyNumberAndLetterKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : (event.charCode >= 48 && event.charCode <= 57) || (event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122);
  }

  keysTipoCuenta(): Array<string> {
    var keys = Object.keys(this.tipoCuenta);
    keys = keys.slice(keys.length / 2);
    return keys;
  }

  checkerDisponibilidad() {
    this.cuentaMalSeteada = false;
    this.verificandoCuenta = true;
    this.clienteService.getClientesPorCtaSap(this.numeroCuenta.value)
      .subscribe(result => {
        if (result != null) {
          this.existeCuenta = true;
        } else {
          this.existeCuenta = false;
          this.checkearCuentasCliente();
        }
        this.verificandoCuenta = false;
      })
  }
  checkearCuentasCliente() {
    for (let cuen of this.cliente.cuentas) {
      if (cuen.ctA_SGC == this.numeroCuenta.value) {
        this.existeCuenta = true;
      }
    }
    let p: string = this.numeroCuenta.value;
    if (p != this.cuentaObtenida) {
      if (p.startsWith('PAD')) {
        this.numeroCuenta.setValue(this.cuentaObtenida);
        this.cuentaMalSeteada = true;
      }
    }
    this.verificandoCuenta = false;
  }

  get numeroCuenta() {
    return this.nuevaCuentaForm.get('numeroCuentaFormControl');
  }
  get condicionVenta() {
    return this.nuevaCuentaForm.get('condicionVentaFormControl');
  }
  get moneda() {
    return this.nuevaCuentaForm.get('monedaFormControl');
  }
  get entregaFactura() {
    return this.nuevaCuentaForm.get('entregaFacturaFormControl');
  }
  get identificador() {
    return this.nuevaCuentaForm.get('identificadorFormControl');
  }
  get email() {
    return this.nuevaCuentaForm.get('emailFormControl');
  }
  get periodo() {
    return this.nuevaCuentaForm.get('periodoFormControl');
  }
  get notificacion() {
    return this.nuevaCuentaForm.get('notificacionFormControl');
  }
  get agrupacionRemitos() {
    return this.nuevaCuentaForm.get('agrupacionRemitosFormControl');
  }
  get diasFormControl() {
    return this.nuevaCuentaForm.get('diasFormControl');
  }
  get plazoPagoFormControl() {
    return this.nuevaCuentaForm.get('plazoPagoFormControl');
  }
  get soloPADRemitosFormControl(){
    return this.nuevaCuentaForm.get('soloPADRemitosFormControl');
  }
}
