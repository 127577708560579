<nav class="navbar navbar-light bg-light navbar-expand-lg ">
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <!--Filtrar grupos-->
      <div class="form-inline me-2">
          <input id="busquedaFormControl" type="text" class="form-control" placeholder="Buscador"
            [formControl]="buscadorFormControl" (input)="filtrarGruposPorTextoBusqueda()"/>
      </div>
      <!---->
      <ul class="navbar-nav me-auto">    
        <!--Nuevo Grupo-->
        <li *ngIf="this.authService.hasRole(TipoUsuario.SEGURIDADTABLAS, TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.IMPUESTOS, TipoUsuario.PRECIOS, TipoUsuario.APROBADORPRECIOS, TipoUsuario.FACTURACION, TipoUsuario.REFACTURACION)" class="nav-item float-end me-2">
          <button type="button" class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2" (click)="crearNuevoGrupo()"> Agregar</button>
        </li>
      </ul>
    </div>
  </nav>
  
  <div class="row">  
    <div class="col col-3">
      <div class="list-group py-3 menu-izq">
        <div class="col col-md-12" *ngIf="this.cargandoLista">
            <div class="spinner-container" >
              <ngx-spinner name="spinnerGrafico" bdOpacity=0.3bd
                Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
                <p style="color: white">C a r g a n d o . . . </p>
              </ngx-spinner>
            </div>
          </div>
          <!-- lista de notificaciones -->
          <div *ngIf="!this.cargandoLista">
            <div *ngFor="let g of listaGruposFiltrados; let i=index">
              <button type="button" class="list-group-item list-group-item-action" (click)="usuarioSeleccionoGrupo(g)">
                <div class="d-flex w-100 justify-content-between">
                  <p >{{ g.titulo}} </p>
                </div>
              </button>
            </div>
          </div>        
      </div>
    </div>
    <!-- informacion de las notificaciones -->
    <div class="col col-9 py-3">
      <div *ngIf="this.grupoSeleccionado != null" id="tablaInformacion">
        <app-upsert-form-grupo [grupoEncabezadoSeleccionado]="this.grupoSeleccionado" (usuarioGuardoGrupo)="usuarioGuardoGrupo()"></app-upsert-form-grupo>
      </div>
    </div>
  </div>
    
    <ng-template #content let-modal>
        <app-upsert-form-grupo></app-upsert-form-grupo>
    </ng-template>
  