import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ClienteService } from 'src/app/cliente/cliente.service';
import { Aeroplanta } from 'src/app/shared/models/despacho/Aeroplanta';
import { ClienteEncabezado } from 'src/app/shared/models/cliente/ClienteEncabezado';
//import { AeroplantasService } from 'src/app/trader/aeroplantas.service';
import { NotificacionesService } from '../../notificaciones.service';
import { NotificacionesCliente } from 'src/app/shared/models/notificaciones/NotificacionCliente';
import { Notificacion } from 'src/app/shared/models/notificaciones/Notificacion';


@Component({
  selector: 'app-nueva-notificacion',
  templateUrl: './nueva-notificacion.component.html',
  styleUrls: ['./nueva-notificacion.component.css']
})
export class NuevaNotificacionComponent implements OnInit {

  @Input() notificacionSeleccionada:NotificacionesCliente;

  activeModal: NgbModal
  form:UntypedFormGroup;
  emailsForm: UntypedFormGroup;
  cargandoClientes:boolean;
  cargandoAeroplanta:boolean;
  clientes: ClienteEncabezado[]=[];
  clientesFiltrados: ClienteEncabezado[]=[];
  clienteSeleccionado: ClienteEncabezado;
  aeroplantas:Aeroplanta[]=[];

  aeroplantasParaCheckear: any[] = [];

  listaDeAeroplantasSeleccionadas: string[] = [];

  listaDeDocumentosSeleccionadas: any[] = [];

  listaDeEmails: string[]=[];

  listaDocumentosSeleccionados = [
    { id: 0, name: 'Aerovales' },
    { id: 1, name: 'Facturas' },
    { id: 2, name: 'Resumenes' }
  ];

  modelCliente:any;



  // formularios para seleccion múltiple

  checkMultiplesAeroplantas: UntypedFormGroup;
  checkMultiplesDocumentos: UntypedFormGroup;




  constructor(activeModal: NgbModal,private formBuilder: UntypedFormBuilder,
    private notificacionService:NotificacionesService,
    private ClienteService:ClienteService,
  //  private aeroplantaService:AeroplantasService) {
  )
  {
    this.activeModal = activeModal;
  }



  ngOnInit() {
    this.crearFormulario();
    this.iniciarFormulario();
    this.getClientes();
    this.getAeroplantas();
  }

  crearFormulario() {


    this.form = this.formBuilder.group({
      clienteFormControl: ['', Validators.required],
      emailFormControl: new UntypedFormControl(this.listaDeEmails, [
        Validators.required,
        Validators.minLength(12),
        Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')
      ]),
     // documentosFormControl:['', Validators.required],
      aeroplantasFormControl:['', Validators.required]
    });
    this.checkMultiplesAeroplantas = this.formBuilder.group({
      nombreAeroplanta: this.formBuilder.array([])

    });
    this.checkMultiplesDocumentos = this.formBuilder.group({
      numero: this.formBuilder.array([])

    });

    this.emailsForm = this.formBuilder.group({

     });
      // this.clienteFormControl.setValue(this.notificacionSeleccionada.razonSocial)
      // this.listaDocumentosSeleccionados=[];
      // this.listaDocumentosSeleccionados.push(this.notificacionSeleccionada.notificaciones[0].documentos);
      // this.listaDeEmails=[];
      // this.listaDeEmails.push(this.notificacionSeleccionada.email);
      // this.listaDeAeroplantasSeleccionadas=[];
      // this.listaDeAeroplantasSeleccionadas.push(this.notificacionSeleccionada.notificaciones[0].aeroplantas)
    console.log("notificacion seleccionada "+JSON.stringify(this.notificacionSeleccionada))

  }

  iniciarFormulario(){
    if (this.notificacionSeleccionada===null) // creando
    {

    }
    else // modificando
    {
     this.selectCustomer(this.notificacionSeleccionada.razonSocial);
    // this.checkMultiplesDocumentos=[0,1];
     this.listaDeDocumentosSeleccionadas.push(0)
    }
  }

   // obtencion de seleccion de checks

   seleccionarAeroplanta(codigoAeroplanta: string, isChecked: boolean) {
    const listaFormArray = <UntypedFormArray>this.checkMultiplesAeroplantas.controls.nombreAeroplanta;

    if (isChecked) {
      listaFormArray.push(new UntypedFormControl(codigoAeroplanta));
      this.listaDeAeroplantasSeleccionadas.push(codigoAeroplanta);
    } else {
      let index = listaFormArray.controls.findIndex(x => x.value == codigoAeroplanta)
      this.listaDeAeroplantasSeleccionadas.splice(index, 1);

      listaFormArray.removeAt(index);
    }
    console.log(JSON.stringify("Aeroplantas "+this.listaDeAeroplantasSeleccionadas))
  }

  seleccionarDocumentos(numero: number, isChecked: boolean) {
    const listaFormArray = <UntypedFormArray>this.checkMultiplesDocumentos.controls.numero;

    if (isChecked) {
      listaFormArray.push(new UntypedFormControl(numero));
      this.listaDeDocumentosSeleccionadas.push(numero);
    } else {
      let index = listaFormArray.controls.findIndex(x => x.value == numero)
      this.listaDeDocumentosSeleccionadas.splice(index, 1);
      listaFormArray.removeAt(index);
    }
  }


  formatter = (x: { razonSocial: string, ctA_SGC: string }) => `${x.razonSocial}`;

  formatterRancho = (x: { codigoRancho: string }) => `${x.codigoRancho}`;

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 || this.cargandoClientes ? [] :
         this.clientes.filter(v => this.containsString(this.clienteFormControl.value,v.ctaSap, v.razonSocial)).slice(0, 10))

    )


  selectCustomer(e: any): void {
    this.filtroClientes();
  }

  containsString(searchValue: string, ...searchIn: string[]): boolean {
    const concat = searchIn.toString();
    return concat.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
  }

  cantidadCaracteres(campo):number
  {
    let cantidad=0;
    if (campo!=null)
    {return campo.length};
    return cantidad;
  }

  convertirListaDeStringEnListaDeAeroplantas(lista:string[]):Aeroplanta[]
  {
    let listaResultado:Aeroplanta[]=[];


    for (let codigoAep of lista) {
      let a=new Aeroplanta()
      a.codigoAeroplanta=codigoAep;
      listaResultado.push(a);

    }
    return listaResultado;
  }

  filtroClientes() {
    this.clientesFiltrados = this.clientes.filter(a => {
      let agregar = true;
      if (this.clienteFormControl.value!=null) {
        agregar = a.razonSocial.toLowerCase().indexOf(this.clienteFormControl.value.razonSocial) > -1;
       // agregar = a.===this.clienteFormControl.value.ctaSap;
      } else {
        this.clientesFiltrados = this.clientes;
      }
      return agregar;
    });
  }





  // llamadas al service

  getAeroplantas() {
    this.cargandoAeroplanta = true;
    // this.aeroplantaService.obtenerTodasLasAeroplantas()
    //   .subscribe(result => {
    //     this.aeroplantas = result.sort((a, b) => { if (a.nombre > b.nombre) { return 1 } else { return -1 } });
    //     for (let aero of this.aeroplantas) {
    //       this.aeroplantasParaCheckear.push({ "nombre": aero.nombre, "codigo": aero.codigoAeroplanta });
    //     }
    //     this.cargandoAeroplanta = false;
    //     this.cargandoClientes = false;
    //    // this.spinner.hide('spinnerReporte')

    //   });
  }

  getClientes() {
    this.cargandoClientes = true;
    this.ClienteService.getClientesEncabezado()
      .subscribe(result => {
        this.clientes = result.sort((a, b) => { if (a.razonSocial > b.razonSocial) { return 1 } else { return -1 } });
        this.clientesFiltrados = this.clientes;
        this.cargandoClientes=false;

      })
  }

  guardarNotificacion(){
    let notificacion=new NotificacionesCliente();
    // guardar notificación
    notificacion.numeroDocumento=this.clienteFormControl.value.numeroDocumento;
    notificacion.tipoDocumento=this.clienteFormControl.value.tipoDocumento;
    notificacion.razonSocial=this.clienteFormControl.value.razonSocial;
    let n:Notificacion=new Notificacion();
    notificacion.notificaciones=[];
    notificacion.notificaciones.push(n);
    notificacion.notificaciones[0].aeroplantas=this.convertirListaDeStringEnListaDeAeroplantas(this.listaDeAeroplantasSeleccionadas);
    notificacion.notificaciones[0].activo=true;
    notificacion.notificaciones[0].documentos=this.listaDeDocumentosSeleccionadas;
    notificacion.notificaciones[0].emails=this.listaDeEmails;

   console.log("La notificacion a guardar es "+JSON.stringify(notificacion))
    this.notificacionService.crearNotificacion(notificacion)
    .subscribe(result => {
      //this.activeModal.close(surtidor);
      //this.messagge.showSuccessMessage("Se ha agregado la notificación correctamente");
    this.form.reset();
    }),
    () => {
      //this.mensajeError = true;
      //this.guardando = false;
    }
  }



  activarGuardar()
  {
    return (this.clienteFormControl.valid && this.listaDeDocumentosSeleccionadas!=null
      && this.listaDeDocumentosSeleccionadas.length>0 && this.listaDeEmails!=null
      &&  this.listaDeEmails.length>0 && this.listaDeAeroplantasSeleccionadas!=null &&
       this.listaDeAeroplantasSeleccionadas.length>0);
  }


  asignarMail()
  {
    let esta :number= this.listaDeEmails.indexOf(this.emailFormControl.value)
    console.log("la caja email tiene "+JSON.stringify(this.emailFormControl.value))
    console.log("El valor a buscar está en el indice "+JSON.stringify(esta))
    if (esta===-1 && (this.emailFormControl.value!='' || this.emailFormControl.value!=null ))
    {
    console.log("asignando mail");
    this.listaDeEmails.push(this.emailFormControl.value);
    }
  }

  desasignarMail(index:number) {
    // const index: number = this.picos.indexOf(p);
    if (index !== -1) {
      this.listaDeEmails.splice(index, 1);
    }
  }

  desactivarBotonAgregarMail():boolean
  {
   return   this.cantidadCaracteres(this.emailFormControl.value)>2 && this.emailFormControl.invalid
    && this.emailFormControl.errors.pattern && (this.emailFormControl.dirty
      || this.emailFormControl.touched) && this.emailFormControl.value==='' || this.emailFormControl.value===null;
  }

  // getters

  get clienteFormControl() {
    return this.form.get('clienteFormControl');
  }

  // get documentosFormControl() {
  //   return this.form.get('documentosFormControl');
  // }

  get emailFormControl() {
    return this.form.get('emailFormControl');
  }

  get nombreFormControl() {
    return this.form.get('nombreFormControl');
  }

  get telefonoFormControl() {
    return this.form.get('telefonoFormControl');
  }

  get aeroplantasFormControl() {
    return this.form.get('aeroplantasFormControl');
  }







}
