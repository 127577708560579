import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HandleError, HttpErrorHandler } from '../http-error-handler.service';
import { Cliente } from '../shared/models/cliente/Cliente';
import { Cuenta } from '../shared/models/cliente/Cuenta';
import { PeriodosFacturacion } from '../shared/models/cliente/PeriodosFacturacion';
import { Pais } from '../shared/models/despacho/Pais';
import { Ciudad } from '../shared/models/facturacion/Ciudad';
import { Provincia } from '../shared/models/facturacion/Provincia';

@Injectable({
  providedIn: 'root'
})
export class CuentasService {

  baseURL = environment.apiServer.clientesBaseUrl;
  private cuentaURL = this.baseURL + '/CuentasV1';
  private clienteURL = this.baseURL + '/ClientesV1';
  private configuracionV1 = this.baseURL + '/UbicacionesV1/';
  private handleError: HandleError;

  constructor(private httpClient: HttpClient, private httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError('Cuentas');
  }


  crearCuenta(cuenta: Cuenta, cliente: Cliente): Observable<Cuenta> {
    return this.httpClient.post<Cuenta>(this.cuentaURL + '/Cuentas/' + cliente.tipoDocumento + '-' + cliente.numeroDocumento, cuenta)
      .pipe(
        tap(_ => console.log(`Cuenta`)),
        catchError(this.handleError('al crear Cuenta', null))
      )
  };


  modificarCuenta(cuenta: Cuenta): Observable<Cuenta> {
    return this.httpClient.put<Cuenta>(this.cuentaURL + '/Cuentas', cuenta)
      .pipe(
        tap(_ => console.log(`Cuenta`)),
        catchError(this.handleError('al modificar Cuenta', null))
      )
  };

  getPeriodosFacturacion(): Observable<PeriodosFacturacion[]> {
    return this.httpClient.get<PeriodosFacturacion[]>(this.cuentaURL + '/PeriodosFacturacion')
      .pipe(
        tap(_ => console.log(`Periodos de facturacion`)),
        catchError(this.handleError('al obtener periodos', null))
      )
  };

  obtenerProximoCuenta(){
    return this.httpClient.get(this.clienteURL + '/ProximoNroCuentaSGC', {responseType: 'text'});
  }


  //Obtener paises ciudades provincias
  getPaises(): Observable<Pais[]> {
    return this.httpClient.get<Pais[]>(this.configuracionV1 + 'Paises')
      .pipe(
        map(a => a.map<Pais>(b => Object.assign(new Pais(), b))),
        catchError(this.handleError('getPaises', []))
      );
  }

  getProvincias(): Observable<Provincia[]> {
    return this.httpClient.get<Provincia[]>(this.configuracionV1 + 'Provincias')
      .pipe(
        map(a => a.map<Provincia>(b => Object.assign(new Provincia(), b))),
        catchError(this.handleError('getProvincias', []))
      );
  }

  getProvinciasPorPais(codigo: string): Observable<Provincia[]> {
    return this.httpClient.get<Provincia[]>(this.configuracionV1 + codigo + '/Provincias')
      .pipe(
        map(a => a.map<Provincia>(b => Object.assign(new Provincia(), b))),
        catchError(this.handleError('getProvinciasPorPais', []))
      );
  }

  getCiudadPorProvincia(codigo: string): Observable<Ciudad[]> {
    return this.httpClient.get<Ciudad[]>(this.configuracionV1 + codigo + '/Ciudades')
      .pipe(
        map(a => a.map<Ciudad>(b => Object.assign(new Ciudad(), b))),
        catchError(this.handleError('getCiudadesPorPais', []))
      );
  }

  getCiudad(): Observable<Ciudad[]> {
    return this.httpClient.get<Ciudad[]>(this.configuracionV1 + 'Ciudades')
      .pipe(
        map(a => a.map<Ciudad>(b => Object.assign(new Ciudad(), b))),
        catchError(this.handleError('getCiudades', []))
      );
  }
}
