export enum CodigoImpuesto {
  Iva = 0,
  PerIva = 1,
  IIBB = 2,
  PerIIBB = 3,
  IDC = 4,
  ITC = 5,
  Otro = 6,
  PerMun = 7
}
