import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreciosComponent } from './precio/precios/precios.component';
import { DetallePreciosComponent } from './precio/detalle-precios/detalle-precios.component';
import { ModalExportacionComponent } from './precio/modal-exportacion/modal-exportacion.component';
import { PrecioPorFechaComponent } from './precio/precio-por-fecha/precio-por-fecha.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PreciosIndexComponent } from './precios-index/precios-index.component';
import { PreciosRoutingModule } from './precios-routing.module';
import { PreciosDesactivacionComponent } from './precios-desactivacion/precios-desactivacion.component';
import { NivelesAprobacionComponent } from './niveles-aprobacion/niveles-aprobacion.component';
import { DetalleNivelComponent } from './niveles-aprobacion/detalle-nivel/detalle-nivel.component';
import { ModalNivelAprobacionComponent } from './niveles-aprobacion/modal-nivel-aprobacion/modal-nivel-aprobacion.component';
import { ModalSeleccionarUsuarioComponent } from './niveles-aprobacion/modal-seleccionar-usuario/modal-seleccionar-usuario.component';
import { SharedModule } from '../shared/shared.module';
import { LotesComponent } from './lotes/lotes.component';
import { ModalHistoricoComponent } from './precio/modal-historico/modal-historico.component';
import { ListadoByrComponent } from './bonificacion-recargo/listado-byr/listado-byr.component';
import { ModalResumenPreciosComponent } from './precio/modal-resumen-precios/modal-resumen-precios.component';
import { ModalNuevaBorComponent } from './bonificacion-recargo/modal-nueva-bor/modal-nueva-bor.component';
import { AprobarByrComponent } from './bonificacion-recargo/aprobar-byr/aprobar-byr.component';
import { ModalCambiarFechaDesdeComponent } from './lotes/modal-cambiar-fecha-desde/modal-cambiar-fecha-desde.component';
import { DescargaPreciosComponent } from './precio/descarga-precios/descarga-precios.component';
import { VigenciasListadosIndexComponent } from './vigencias-listados-index/vigencias-listados-index.component';
import { ModalCorreccionComponent } from './precio/modal-correccion/modal-correccion.component';
import { ModalImportarByrComponent } from './bonificacion-recargo/modal-importar-byr/modal-importar-byr.component';
import { ModalNuevaByrPorSurtidorComponent } from './bonificacion-recargo/modal-nueva-byr-por-surtidor/modal-nueva-byr-por-surtidor.component';
import { ListadoPreciosFacturadosComponent } from './precio/listado-precios-facturados/listado-precios-facturados.component';
import { NgxPrintModule } from 'ngx-print';
import { ClientesByrComponent } from './bonificacion-recargo/listado-byr/clientes-byr/clientes-byr.component';
import { ProductosByrComponent } from './bonificacion-recargo/listado-byr/productos-byr/productos-byr.component';
import { MotivosObservacionesComponent } from '../shared/motivos-observaciones/motivos-observaciones.component';
import { ListaExpendedorasComponent } from '../shared/lista-expendedoras/lista-expendedoras.component';
import { AprobarModificacionComponent } from './aprobar-modificacion/aprobar-modificacion.component';
import { PrecioFacturaComponent } from './aprobar-modificacion/precio-factura/precio-factura.component';
import { ComparativaSubirPreciosComponent } from './precio/comparativa-subir-precios/comparativa-subir-precios.component';
import { SafePipe } from '../shared/pipes/safePipe';
import { ItemMultifiltroComponent } from '../shared/multifiltro/item-multifiltro/item-multifiltro.component';
import { TransferFileComponent } from '../shared/transfer-file/transfer-file.component';
import { GestionPreciosComponent } from './gestion-precios/gestion-precios.component';
import { ImportacionMasivaComponent } from './gestion-precios/importacion-masiva/importacion-masiva.component';
import { HistorialDesactivacionComponent } from './historial-desactivacion/historial-desactivacion.component';
import { ActualizacionPreciosComponent } from './gestion-precios/actualizacion-precios/actualizacion-precios.component';
import { ModalSubirExcelModificacionPreciosComponent } from './gestion-precios/modal-subir-excel-modificacion-precios/modal-subir-excel-modificacion-precios.component';
import { AprobarByrPorLoteComponent } from './bonificacion-recargo/aprobar-byr-por-lote/aprobar-byr-por-lote.component';
import { ReporteNivelesComponent } from './reporte-niveles/reporte-niveles.component';
import { SimuladorPrecioComponent } from './simulador-precio/simulador-precio.component';
import { FacturaPrefijoPipe } from '../shared/pipes/factura-prefijo.pipe';
import { FacturaNumeroPipe } from '../shared/pipes/factura-numero.pipe';
import { TipoMovimientoPipe } from '../shared/pipes/tipo-movimiento.pipe';
import { NombreTipoComprobantePipe } from '../shared/pipes/nombre-tipo-comprobante.pipe';
@NgModule({
  declarations: [
    PreciosComponent,
    DetallePreciosComponent,
    ModalExportacionComponent,
    PrecioPorFechaComponent,
    PreciosIndexComponent,
    PreciosDesactivacionComponent,
    NivelesAprobacionComponent,
    ModalNivelAprobacionComponent,
    ModalSeleccionarUsuarioComponent,
    LotesComponent,
    ModalHistoricoComponent,
    ListadoByrComponent,
    ModalResumenPreciosComponent,
    ModalNuevaBorComponent,
    AprobarByrComponent,
    ModalCambiarFechaDesdeComponent,
    DescargaPreciosComponent,
    VigenciasListadosIndexComponent,
    ModalCorreccionComponent,
    ModalImportarByrComponent,
    ModalNuevaByrPorSurtidorComponent,
    ListadoPreciosFacturadosComponent,
    ClientesByrComponent,
    ProductosByrComponent,
    AprobarModificacionComponent,
    PrecioFacturaComponent,
    ComparativaSubirPreciosComponent,
    SafePipe,
    GestionPreciosComponent,
    ImportacionMasivaComponent,
    HistorialDesactivacionComponent,
    ActualizacionPreciosComponent,
    ModalSubirExcelModificacionPreciosComponent,
    AprobarByrPorLoteComponent,
    ReporteNivelesComponent,
    SimuladorPrecioComponent,
  ],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    PreciosRoutingModule,
    SharedModule,
    NgxPrintModule
  ],
  providers: [
    FacturaPrefijoPipe,
    FacturaNumeroPipe,
    TipoMovimientoPipe,
    NombreTipoComprobantePipe
  ]
})
export class PreciosModule { }
