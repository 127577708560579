import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';

@Component({
  selector: 'app-informes-index',
  templateUrl: './informes-index.component.html',
  styleUrls: ['./informes-index.component.scss']
})
export class InformesIndexComponent implements OnInit {

  TipoUsuario = TipoUsuario;
  
  constructor(private authService: AuthService) { }

  ngOnInit(): void {
  }

  hasRole(...roles: TipoUsuario[]) {
    return this.authService.hasRole(...roles);
  }
}
