import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UsuarioService } from 'src/app/account/usuario.service';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { RanchoV2 } from 'src/app/shared/models/ranchos/RanchoV2';
import { Usuario } from 'src/app/shared/models/ranchos/Usuario';
import { MessageService } from 'src/app/shell/message.service';
import { environment } from 'src/environments/environment';
import { RanchoService } from '../../rancho.service';

@Component({
  selector: 'app-modal-asignar-rancho',
  templateUrl: './modal-asignar-rancho.component.html',
  styleUrls: ['./modal-asignar-rancho.component.scss']
})
export class ModalAsignarRanchoComponent implements OnInit {

  // datos de entrada
  @Input() rancho: RanchoV2;
  @Input() nombreProducto: string;

  ranchosBaseUrl = environment.apiServer.ranchosBaseUrl;

  // variables  
  codigoRanchoFormControl = new UntypedFormControl(null, [Validators.required]);
  ranchoAFIP: RanchoV2;
  validando:boolean = false;
  guardando:boolean = false;
  errorAFIP:boolean = false;
  sePuedeGuardar:boolean = false;
  mensaje: string;

  archivosSubidos: string[] = [];
  ranchoDTO: RanchoV2;

  constructor(public modal: NgbActiveModal,
    private ranchoService: RanchoService,
    private messageService: MessageService,
    private usuarioService: UsuarioService) { }

  ngOnInit(): void {
    this.mensaje = "";
    this.validando = false
    this.guardando = false;
    this.errorAFIP = false;
    this.sePuedeGuardar = false;
  }

  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  cantidadCaracteres() {
    return this.codigoRancho.value.length == 16; 
  }

  formatNumeroRancho(event) {
    const regex = new RegExp("^[a-zA-Z0-9]+$");
    let key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    }
  }

  validarRanchoWebService() {
    this.mensaje = "";
    this.validando = true;
    this.errorAFIP = false;
    this.ranchoService.validarAFIP(this.rancho.id, this.codigoRancho.value.toUpperCase(), this.rancho.aeroplanta.codigoAeroplanta, this.armarUsuario())
      .subscribe(result => {
        if(result=="Rancho Correcto"){
          this.mensaje = "El nº de rancho es valido. Puede guardarlo.";
          this.errorAFIP = false; 
        } else {
          this.mensaje = "El nº de rancho ingresado no es valido. Revisar por favor ¿Está seguro que desea guardar el rancho?";          
          this.errorAFIP = true;   
        }        
        this.validando = false;
        this.sePuedeGuardar = true;         
      }, () => {
        this.mensaje = "El nº de rancho ingresado no es valido. Revisar por favor ¿Está seguro que desea guardar el rancho?";
        this.validando = false;      
        this.sePuedeGuardar = true; 
        this.errorAFIP = true; 
      })
  }

  guardarRancho() {
    this.guardando = true;
    //Se genera un ranchoDTo que solo contiene la lista de Adjuntos y los datos del usuario
    this.ranchoDTO = new RanchoV2;
    this.ranchoDTO.usuarioDTO = this.armarUsuario();
    this.ranchoDTO.archivosAdjuntos = this.archivosSubidos;
    this.ranchoDTO.id = this.rancho.id;
    this.ranchoService.putAsignarNumeroRancho(this.rancho.id, this.codigoRancho.value.toUpperCase(), this.ranchoDTO)
      .subscribe(result => { 
        this.ranchoAFIP = result;
        this.guardando = false;
        this.messageService.showSuccessMessage("El número de rancho asignado " + this.ranchoAFIP.codigoRancho + " se guardo con exito");
        this.modal.close(this.ranchoAFIP)
      }, (err: ErrorModel) => {
        this.messageService.showErrorMessage('Hubo un error al obtener los datos. ' + err.description);
        this.guardando = false;
        this.modal.close()        
      })  
  } 
  
  armarUsuario() : Usuario {
    let usuario = {
                  id: this.usuarioService.getIdUsuario(),
                  nombresCompletos: this.usuarioService.getNombresCompletosUsuario(),
                  dni: this.usuarioService.getDniUsuario(),
                  email: this.usuarioService.getEmailUsuario()
                  }      
    return usuario;
  }

  onArchivoSubido(event) {
    this.archivosSubidos.push(event);
  }

  cancelarModal() {
    this.modal.close();
  } 

  get codigoRancho() {
    return this.codigoRanchoFormControl;
  }
}
