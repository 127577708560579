<ul class="nav nav-tabs justify-content-start">
  
  <li class="nav-item">
    <a class="nav-link" routerLink="precios" routerLinkActive="active"><b>Vigencias</b></a>
  </li>

  <li class="nav-item" *ngIf="authService.hasRole(TipoUsuario.PRECIOS, TipoUsuario.APROBADORPRECIOS, TipoUsuario.RESPRODUCTO, TipoUsuario.SEGURIDADTABLAS, TipoUsuario.APROBADORBYR, TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_LN, TipoUsuario.COMERCIAL_AV, TipoUsuario.FACTURACION, TipoUsuario.REFACTURACION, TipoUsuario.CONSPRECIOS, TipoUsuario.SOPORTE, TipoUsuario.SOPORTE)">
    <a class="nav-link" routerLink="preciosReporte" routerLinkActive="active"><b>Reportes</b></a>
  </li>


</ul>
<router-outlet></router-outlet>