<div class="card">
  <div class="modal-header">
    <h4 class="modal-title">Crear nuevo aerovale de contingencia.</h4>
  </div>

  <div class="card-body">
    <div class="row">
      <div class="col-md-8 col-md-offset-2">
        Seleccione el tipo de aerovale a crear.
      </div>
    </div>
    <div class="row my-3"></div>
    <div class="row">
      <div class="col-md-12 col-my-3 center-block">
        <span class="border mx-2">
          <button id="botonAerovaleAerocombustible" class="btn btn-primary"
            (click)="onTipoAerovaleSeleccionado(enumTipoAerovale.Aerocombustible)">Aerocombustible</button>
        </span>
        <span class="border mx-2">
          <button class="btn btn-primary"
            (click)="onTipoAerovaleSeleccionado(enumTipoAerovale.Combustible)">Combustible</button>
        </span>
        <span class="border mx-2">
          <button class="btn btn-primary"
            (click)="onTipoAerovaleSeleccionado(enumTipoAerovale.Aeroespecialidad)">Aeroespecialidad</button>
        </span>
        <span class="border mx-2">
          <button class="btn btn-primary" (click)="onTipoAerovaleSeleccionado(enumTipoAerovale.Alije)">Alije</button>
        </span>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <div class="row">
      <div class="col-md-8 col-md-offset-2">
        <span class="border">
          <button type="button" class="btn btn-secondary"
            (click)="activeModal.dismiss('Close clicked')">Cancelar</button>
        </span>
      </div>
    </div>
  </div>
</div>
