<div class="modal-header bg-light">
  <h4 class="modal-title">Consultar Cliente</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="modal.close()"></button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col col-2"></div>
    <div class="col col-4">
      <label class="pt-2">CUIT:</label>
    </div>
    <div class="col col-4">
      <input type="text" (keypress)="onlyNumberKey($event)" class="form-control" [formControl]="cuitFormControl" 
        [maxLength]="maximoNumero" (blur)="this.validarFormatoCuit()" placeholder="CUIT sin guiones" required />      
      <div *ngIf="cuit.invalid && cuit.errors.required && (cuit.dirty || cuit.touched)" class="alert alert-danger">
        Requerido
      </div>
      <div *ngIf="cuit.invalid  && (cuit.dirty || cuit.touched)" class="alert alert-danger">
        El número de CUIT no tiene el formato correcto.
      </div>
    </div>
    <div class="col col-2"></div>
  </div>
</div>
<div class="modal-footer bg-light justify-content-center">
  <button class="btn btn-primary me-2" (click)="buscarCliente()" [disabled]="guardando || ! cuitFormControl.valid">
    <span *ngIf="!guardando">Consultar</span>
    <div *ngIf="guardando" class="d-flex align-items-center">
      <div class="spinner-border text-light spinner-border-sm" role="status"></div>
      <span>Consultando...</span>
    </div>
  </button>
  <button class="btn btn-secondary" (click)="modal.close()">Cancelar</button>
</div>