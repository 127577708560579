<div *ngIf="configuracionInicialProcesada" class="mb-3 col-md-10 jumbotron">
  <div class="mb-3 row">
    <div class="col-sm-12 col-form-label">
      !Bien hecho {{username}}!
    </div>
  </div>
  <div class="mb-3 row">
    <div class="col-sm-12 col-form-label">
      Ya estas muy cerca de poder operar en PAD.
    </div>
  </div>
  <div class="mb-3 row">
    <div class="col-sm-12 col-form-label">
      Tu solicitud esta pendiente de aprobación.
    </div>
  </div>
  <div class="mb-3 row">
    <div class="col-sm-12 col-form-label">
      Cuando el supervisor de tu aeroplanta apruebe tu alta, se te informará por mail y podrás ingresar al sistema.
    </div>
  </div>
  <div class="mb-3 row">
    <div class="col-sm-12 col-form-label">
      <button class="btn btn-primary" type="button" (click)="onCerrarSesionClick()">Cerrar sesión</button>
    </div>
  </div>
  <div class="mb-3 row">
    <div class="col-sm-12 col-form-label">
      Atte, Sistema PAD.
    </div>
  </div>

</div>

<div *ngIf="!configuracionInicialProcesada" class="mb-3 col-md-10 border jumbotron" [formGroup]="configuracionForm">
  <div class="mb-3 row">
    <div class="col-sm-12 col-form-label">
      Bienvenido al sistema PAD {{username}}.
    </div>
  </div>

  <div class="mb-3 row">
    <div class="col-sm-12 col-form-label">
      Este es tu primer acceso al sistema.
    </div>
  </div>

  <div class="mb-3 row">
    <div class="col-sm-4 col-form-label">
      {{ dniUsuario ? "Verifique su DNI" : "Ingrese su DNI" }}
    </div>
    <div class="col-sm-5 ">
      <input class="form-control" formControlName="dniFormControl" maxlength="10">
      <div
        *ngIf="dniFormControl.invalid && dniFormControl.errors.required && (dniFormControl.dirty || dniFormControl.touched)"
        class="alert alert-danger">Requerido</div>
    </div>
  </div>

  <div class="mb-3 row">
    <div class="col-sm-12 col-form-label">
      Debes seleccionar las aeroplantas en las que vas a trabajar, para que un superior apruebe tu alta de usuario en
      PAD.
    </div>
  </div>

  <form>

    <div class="mb-3 row">
      <label class="col-sm-3 col-form-label" for="aeroplantaFormControl">Aeroplantas disponibles:</label>
      <div class="col-sm-9" [formGroup]="configuracionForm">
        <input #input type="text" class="form-control" formControlName="aeroplantaFormControl"
          name="aeroplantaFormControl" id="aeroplantaFormControl" placeholder="Buscar aeroplantas para asignar"
          [ngbTypeahead]="searchAeroplanta" [inputFormatter]="aeroplantaFormatter"
          [resultFormatter]="aeroplantaFormatter" (selectItem)="onAeroplantaSelectItem($event, input)"
          (change)="onChangeAeroplanta()">
      </div>

      <loading-text-spinner *ngIf="aeroplantas == null" color="primary" textColor="dark"
        message="Obteniendo aeroplantas"></loading-text-spinner>
    </div>

    <div class="form-group row">
      <div class="col-sm-3"></div>
      <div class="col-sm-9" style="padding-left: 2.2em;">
        <input style="cursor: pointer;" type="checkbox" class="form-check-input" id="checkTodasAeroplantas"
          (change)="clickTodasLasAeroplantas()"
          [disabled]="this.aeroplantasSeleccionadasFormControlArray.controls.length>0" />
        <label class="form-check-label" for="checkTodasAeroplantas">Todas las aeroplantas</label>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-sm-12 col-form-label">
        Aeroplantas seleccionadas
      </div>
    </div>

    <div class="mb-3 row" [formGroup]="configuracionForm">
      <div class="col-sm-10" formArrayName="aeroplantasSeleccionadasFormControlArray">
        <div *ngFor="let aeroplanta of aeroplantasSeleccionadasFormControlArray.controls; let i=index"
          style="margin-bottom: 1em;">
          <div [formGroupName]="i">
            <input type="text" class="label-aeroespecialidad-item" formControlName="nombreAeroplanta" readonly>
            <input type="hidden" formControlName="idAeroplanta">
            <button type="button" (click)="onAeroplantaEliminada(i, aeroplanta.value)"
              class="btn btn-danger ms-3 px-1"><i class="fa fa-trash"></i> Quitar</button>
          </div>
        </div>
        <div style="color: #842029; background-color: #f5c2c7;" *ngIf="(this.aeroplantasSeleccionadasFormControlArray.controls.length == 0)">
          No ha seleccionado aeroplantas.
        </div>
      </div>
    </div>

    <div class="mb-3 row">
      <div class="col-sm-12 col-form-label">
        <button class="btn btn-primary" [disabled]="configuracionForm.invalid || guardandoConfiguracion" type="submit"
          col-sm-5 (click)="guardarConfiguracionInicial()">
          <span *ngIf="!guardandoConfiguracion">Confirmar alta en aeroplantas seleccionadas</span>
          <div *ngIf="guardandoConfiguracion" class="d-flex align-items-center">
            <div class="spinner-border text-light spinner-border-sm" role="status"></div>
            <span>Guardando...</span>
          </div>
        </button>
        <button class="btn btn-secondary" style="float:right;" type="button" (click)="onCerrarSesionClick()">Cerrar
          sesión</button>
      </div>
    </div>

  </form>
</div>