// include directives/components commonly used in features modules in this shared modules
// and import me into the feature module
// importing them individually results in: Type xxx is part of the declarations of 2 modules: ... Please consider moving to a higher module...
// https://github.com/angular/angular/issues/10646

import { NgModule }           from '@angular/core';
import { CommonModule }       from '@angular/common';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AutofocusDirective } from './directives/auto-focus.directive';
import { LoadingTextSpinnerComponent } from './components/loading-text-spinner/loading-text-spinner.component';
import { ModalAsociarAeroplantaComponent } from './components/modal-asociar-aeroplanta/modal-asociar-aeroplanta.component';
import { ParametroImpositivoComponent } from './parametro-impositivo/parametro-impositivo.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VisorComprobanteComponent } from './visor-comprobante/visor-comprobante.component';
import { NgxBarcode6Module } from 'ngx-barcode6';
import { NgxPrintModule } from 'ngx-print';
import { VisorComprobanteCargamentoComponent } from './visor-comprobante-cargamento/visor-comprobante-cargamento.component';
import { VisorImpuestoComponent } from './visor-impuesto/visor-impuesto.component';
import { ImporteFacturaComponent } from './importe-factura/importe-factura.component';
import { SeleccionClienteFacturacionComponent } from './seleccion-cliente-facturacion/seleccion-cliente-facturacion.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AgregarCuponComponent } from './agregar-cupon/agregar-cupon.component';
import { PrevisualizacionComprobanteComponent } from './previsualizacion-comprobante/previsualizacion-comprobante.component';
import { ListadorFacturasComponent } from './listador-facturas/listador-facturas.component';
import { MotivosObservacionesComponent } from './motivos-observaciones/motivos-observaciones.component';
import { UploadFileComponent } from './upload-file/upload-file.component';
import { ListaExpendedorasComponent } from './lista-expendedoras/lista-expendedoras.component';
import { QRCodeModule } from 'angularx-qrcode';
import { DetalleNivelComponent } from '../precios/niveles-aprobacion/detalle-nivel/detalle-nivel.component';
import { ContenedorMultifiltroComponent } from './multifiltro/contenedor-multifiltro/contenedor-multifiltro.component';
import { ItemMultifiltroComponent } from './multifiltro/item-multifiltro/item-multifiltro.component';
import { TransferFileComponent } from './transfer-file/transfer-file.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AerovalePrefijoPipe } from './pipes/aerovale-prefijo.pipe';
import { AerovaleNumeroPipe } from './pipes/aerovale-numero.pipe';
import { RemitoPrefijoPipe } from './pipes/remito-prefijo.pipe';
import { RemitoNumeroPipe } from './pipes/remito-numero.pipe';
import { OnlyNumberDirective } from './directives/only-number.directive';
import { OnlyDecimalDirective } from './directives/only-decimal.directive';
import { ModalSetFechaComponent } from './generic-modals/modal-set-fecha.component';
import { OnlyDecimalNegativaDirective } from './directives/only-decimal-negativa.directive';
import { RefreshPageDirective } from './directives/refresh-page.directive';
import { ComprobanteSapPipe } from './pipes/comprobante-sap.pipe';
import { FacturaPrefijoPipe } from './pipes/factura-prefijo.pipe';
import { FacturaNumeroPipe } from './pipes/factura-numero.pipe';
import { TipoMonedaPipe } from './pipes/tipo-moneda.pipe';
import { TipoMovimientoPipe } from './pipes/tipo-movimiento.pipe';
import { NombreTipoComprobantePipe } from './pipes/nombre-tipo-comprobante.pipe';
import { CondicionVentaPipe } from './pipes/condicion-venta.pipe';
import { VisorCuponComponent } from './visor-cupon/visor-cupon.component';

//https://stackoverflow.com/questions/41433766/directive-doesnt-work-in-a-sub-module
//https://stackoverflow.com/questions/45032043/uncaught-error-unexpected-module-formsmodule-declared-by-the-module-appmodul/45032201

@NgModule({
  imports:      [
    CommonModule,
    NgxSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    NgxBarcode6Module,
    NgxPrintModule,
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    QRCodeModule,
    InfiniteScrollModule
  ],
  declarations: [AutofocusDirective, LoadingTextSpinnerComponent, ModalAsociarAeroplantaComponent, ParametroImpositivoComponent, VisorComprobanteComponent, VisorComprobanteCargamentoComponent, VisorImpuestoComponent, ImporteFacturaComponent, SeleccionClienteFacturacionComponent,AgregarCuponComponent, PrevisualizacionComprobanteComponent, ListadorFacturasComponent, MotivosObservacionesComponent, ListaExpendedorasComponent, UploadFileComponent, DetalleNivelComponent, ContenedorMultifiltroComponent, ItemMultifiltroComponent, TransferFileComponent, 
                  AerovalePrefijoPipe, AerovaleNumeroPipe, RemitoPrefijoPipe, RemitoNumeroPipe, OnlyNumberDirective, OnlyDecimalDirective, ModalSetFechaComponent, OnlyDecimalNegativaDirective, RefreshPageDirective, ComprobanteSapPipe, FacturaPrefijoPipe, FacturaNumeroPipe, TipoMonedaPipe, TipoMovimientoPipe, NombreTipoComprobantePipe, CondicionVentaPipe, VisorCuponComponent],
  exports:      [NgxSpinnerModule, AutofocusDirective, LoadingTextSpinnerComponent, ModalAsociarAeroplantaComponent,VisorComprobanteComponent,PrevisualizacionComprobanteComponent, UploadFileComponent, DetalleNivelComponent, ItemMultifiltroComponent, TransferFileComponent, AerovalePrefijoPipe, AerovaleNumeroPipe, RemitoPrefijoPipe, RemitoNumeroPipe, OnlyNumberDirective, OnlyDecimalDirective, RefreshPageDirective, ComprobanteSapPipe, FacturaPrefijoPipe, FacturaNumeroPipe, TipoMonedaPipe, TipoMovimientoPipe, NombreTipoComprobantePipe, CondicionVentaPipe, VisorCuponComponent],

  entryComponents: [VisorImpuestoComponent, SeleccionClienteFacturacionComponent,AgregarCuponComponent],
  providers:    []
})
export class SharedModule { }
