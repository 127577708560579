<nav class="navbar navbar-light bg-light navbar-expand-lg mb-3">
  <form class="form-inline flex-fill" id="formularioFiltros">
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <ul class="navbar-nav me-auto flex-row">
      <div class="nav-item" [formGroup]="searchForm">
        <input id="busquedaFormControl" type="text" class="form-control " style="width: 25em;"
          formControlName="busqueda" (input)="filtrarPrecios()" placeholder="Filtrar cliente / producto / precio" />
      </div>

      <button class=" navbar-right btn btn-sm p-0 border-0 ps-3" [disabled]="esperandoCarga" (click)="onRefreshClick()">
        <i class="fa fa-refresh fa-2x me-3 text-primary" ngbTooltip="Actualizar lista"></i>
      </button>

      <li class="nav-item">
        <div class="nav-item" style="padding-left: 5em; padding-top: 0.35em; font-weight: bold;">
          <div *ngIf="this.usuarioApruebaRetroactivos">Trabajando precios retroactivos.</div>
          <div *ngIf="!this.usuarioApruebaRetroactivos">Trabajando precios.</div>
        </div>
      </li>  

    </ul>
  </form>

  <div class="mt-2 me-2 float-end">
    <button type="button" class="btn btn-outline-primary me-2" (click)="aprobarModificaciones()" [disabled]="actualizando">
      <span *ngIf="!this.actualizando">Aprobar modificaciones</span>
          <div *ngIf="this.actualizando" class="d-flex align-items-center">
            <div class="spinner-border text-light spinner-border-sm" role="status"></div>
            <span>Realizando petición...</span>
          </div>
    </button>

    <button type="button" class="btn btn-outline-primary" (click)="rechazarModificaciones()" [disabled]="actualizando">
      <span *ngIf="!this.actualizando">Rechazar</span>
          <div *ngIf="this.actualizando" class="d-flex align-items-center">
            <div class="spinner-border text-light spinner-border-sm" role="status"></div>
            <span>Realizando petición...</span>
          </div>
    </button>
  </div>
</nav>

<form class="mb-3">
  <div class="col col-md-12">
    <div class="spinner-container" *ngIf="esperandoCarga">
      <ngx-spinner name="spinnerGrafico" bdOpacity=0.3bd
        Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
        <p style="color: white">{{messagePrecios}}</p>
      </ngx-spinner>
    </div>

    <ngb-alert type="danger" [dismissible]="false" *ngIf="this.mensajeAccesoInsuficiente != null && !esperandoCarga">
      {{ this.mensajeAccesoInsuficiente }}
    </ngb-alert>

    <div id="tablaDetalle" class="tabla-precios" *ngIf="this.mensajeAccesoInsuficiente == null && !esperandoCarga">
      <table id="tabla" class="table table-sm table-hover">        
        <tbody class="text-center letra">
          <tr>
            <td scope="col"></td>
            <td scope="col"></td>
            <td scope="col"></td>
            <td scope="col"></td>
            <td scope="col"></td>
            <td scope="col"></td>
            <td scope="col"></td>
            <td scope="col"></td>
            <td scope="col"></td>
            <td scope="col"></td>
            <td scope="col"></td>
            <td scope="col"></td>
            <td scope="col"></td>
            <td scope="col" *ngIf="this.preciosFiltrados.length > 0" ><input style="cursor: pointer;"  type="checkbox" (change)="onSeleccionarTodosPrecios($event.target.checked)" id="seleccionar-todos-check" /></td>
          </tr>
          <ng-container *ngFor="let p of preciosFiltrados; let i = index">
            <tr>
              <td> 
                <span class="rotulo">Codigo<br/></span>
                {{p.precio.codigoProducto != null?p.precio.codigoProducto: "--"}}</td>

              <td> 
                <span class="rotulo">Producto<br/></span>
                {{p.precio.nombreProducto != null?p.precio.nombreProducto: "--"}}</td>

              <td *ngIf="p.precio.fechaDesde != null && p.precio.fechaHasta != null">
                <span class="rotulo">Desde-Hasta<br/></span>
                {{p.precio.fechaDesde | date: "dd'/'MM'/'yy HH:mm:ss"}} -
                {{p.precio.fechaHasta | date: "dd'/'MM'/'yy HH:mm:ss"}}</td>

              <td *ngIf="p.precio.fechaHasta == null && p.precio.fechaDesde">
                <span class="rotulo">Desde-Hasta<br/></span>
                 -- </td>

              <td> <span class="rotulo">Moneda<br/></span>
                {{p.precio.moneda != null?p.precio.moneda.nombre: "--"}}</td>

              <td> <span class="rotulo">Cliente<br/></span>
                {{p.precio.cliente != null?p.precio.cliente.razonSocial: "--"}}</td>

              <td *ngIf="p.precio.tipoVenta == null || p.precio.tipoVenta == 0"> <span class="rotulo">Tipo Venta<br/></span> - </td>
              <td *ngIf="p.precio.tipoVenta != null && p.precio.tipoVenta == 2"> <span class="rotulo">Tipo Venta<br/></span> Internacional</td>
              <td *ngIf="p.precio.tipoVenta != null && p.precio.tipoVenta == 1"> <span class="rotulo">Tipo Venta<br/></span> Cabotaje </td>

              <td> 
                <span class="rotulo">Precio<br/></span>
                {{p.precio.precioContado != null?p.precio.precioContado: "--"}}</td>

              <td> 
                <span class="rotulo">Entrega<br/></span>
                {{p.precio.direccionEntrega != null?p.precio.direccionEntrega: "--"}}</td>

              <td> 
                <span class="rotulo">Area<br/></span>
                {{p.precio.areaNegocio != null?p.precio.areaNegocio.nombreArea: "--"}}</td>

              <td>
                 <span class="rotulo">Segmento<br/></span>
                {{p.precio.segmento != null?p.precio.segmento.nombreSegmento: "--"}}</td>

              <td>
                <span class="rotulo">Aeroplanta<br/></span>
                {{p.precio.codigoAeroplanta}}</td>

              <td>
                <span class="rotulo">Usuario<br/></span>{{p.precio.username | date: "dd'/'MM'/'yy"}} - {{p.precio.username}}</td>

              <td>
                <span class="rotulo">Ver<br/></span>
                <span class="fa fa-eye" style="cursor: pointer;" (click)="verFacturasAfectadas(p)"></span>
              </td>

              <td class="justify-content-center">
                  <input  [checked]="p.seleccionadoUi"  (click)="agregarPrecios(p)" type="checkbox" id="{{p.precio.id}}" >
              </td>
            </tr>
            <tr>
              <td colspan="1"></td>
              <td colspan="4" class="dato">
                <b>Detalle:</b> 
                <br/>
                {{p.detalle}}
              </td>

              <td colspan="7">
                <div class="row col-md-12 contenedor-modificacion">
                  <table id="modificacion-precio" class="table">
                    <tr>
                      <th class="">Datos viejos</th>
                      <th class="">Datos Nuevos</th>
                    </tr>
                    <tr *ngIf="p.precioContadoViejo != p.precioContadoNuevo">
                      <td class="dato"><b>Precio:</b> {{p.precioContadoViejo}}</td>
                      <td class="dato"><b>Precio:</b> {{p.precioContadoNuevo}}</td>
                    </tr>
                    <tr *ngIf="p.fechaDesdeVieja != p.fechaDesdeNueva">
                      <td class="dato"><b>Desde:</b> {{p.fechaDesdeVieja | date: "dd'/'MM'/'yy HH:mm:ss"}}</td>
                      <td class="dato"><b>Desde:</b> {{p.fechaDesdeNueva | date: "dd'/'MM'/'yy HH:mm:ss"}}</td>
                    </tr>
                    <tr *ngIf="p.fechaHastaVieja != p.fechaHastaNueva">
                      <td class="dato"><b>Hasta:</b> {{p.fechaHastaVieja | date: "dd'/'MM'/'yy HH:mm:ss"}}</td>
                      <td class="dato"><b>Hasta:</b> {{p.fechaHastaNueva | date: "dd'/'MM'/'yy HH:mm:ss"}}</td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</form>
