import { Frame } from '../../enums/frame';
import { Guid } from 'guid-typescript';

export class Leyenda{
    id: Guid;
    descripcion: string;
    tipoLeyenda: number;
    frame: Frame;
    posicion: number;
    orden: number;
    activo: boolean;
    tipoFactura: number;
}