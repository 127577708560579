import { CondicionVenta } from '../../enums/condicionVenta';
import { TipoVenta } from '../../enums/tipoVenta';

export class FiltroPrecio{
    productoCodigo: string;
    monedaId: string;
    fecha: string;
    aeroplantaCodigo: string;
    condicionVenta: CondicionVenta;
    areaCodigo: string;
    segmentoCodigo: string;
    clienteCta_SGC: string;
    tipoVenta: TipoVenta;
    direccionEntrega: string;
}