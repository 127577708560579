import { Pedido } from './Pedido';

export class PagedList {
	count: number;
	firstItemOnPage: number;
	hasNextPage: boolean;
	hasPreviousPage: boolean;
	isFirstPage: boolean;
	isLastPage: boolean;
	lastItemOnPage: number;
	pageCount: number;
	pageNumber: number;
	pageSize: number;
	totalItemCount: number;
    items: Pedido[];
}