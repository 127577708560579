import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HandleError, HttpErrorHandler } from '../http-error-handler.service';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Aerovale } from '../shared/models/despacho/Aerovale';
import { catchError, map, tap } from 'rxjs/operators';
import { AerovaleDetalle } from '../shared/models/despacho/AerovaleDetalle';
import { AuthService } from '../core/authentication/auth.service';
import { AerovaleRespuesta } from '../shared/models/despacho/AerovaleRespuesta';
import { DatePipe } from '@angular/common';
import { AerovaleAerocombustible } from '../shared/models/despacho/AerovaleAerocombustible';
import { Abastecedora } from '../shared/models/despacho/Abastecedora';
import { Observacion } from '../shared/models/despacho/Observacion';
import { AerovaleCombustible } from '../shared/models/despacho/AerovaleCombustible';
import { AerovaleAeroespecialidad } from '../shared/models/despacho/AerovaleAeroespecialidad';
import { AerovaleAlije } from '../shared/models/despacho/AerovaleAlije';
import { UsuarioService } from '../account/usuario.service';
import { Operador } from '../shared/models/despacho/Operador';
import { RemitoFox } from '../shared/models/despacho/RemitoFox';
import { Aeroplanta } from '../shared/models/despacho/Aeroplanta';
import { Usuario } from '../shared/models/despacho/Usuario';
import { Precarga } from '../shared/models/despacho/Precarga';
@Injectable({
  providedIn: 'root'
})
export class AerovalesService {

  baseUrl = environment.apiServer.baseUrl;
  stockUrl = environment.apiServer.stockBaseUrl;
  usuariosUrl = environment.apiServer.usuariosBaseUrl;
  private aerovalesV2Url = this.baseUrl + '/AerovalesV2/';
  private productosUrl = this.baseUrl + '/ProductosV1/';
  private observacionesUrl = this.baseUrl + '/ObservacionesV1';
  private remitosURL = this.baseUrl + '/RemitosV1/';
  private aeroplantasUrl = this.baseUrl + '/AeroplantasV1/';
  private usuarioUrl = this.usuariosUrl + '/UsuariosV1'
  private pedidosV2Url = this.baseUrl + '/PedidosV2/';
  private handleError: HandleError;

  constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler, private authService: AuthService, private datepipe: DatePipe, private usuarioService: UsuarioService) {
    this.handleError = httpErrorHandler.createHandleError('Aerovales');
  }

 getAerovales(): Observable<Aerovale[]> {
    return this.http.get<Aerovale[]>(this.aerovalesV2Url + this.usuarioService.getAeroplantaActual().codigoAeroplanta)
      .pipe(
        tap(aerovales => {
          _ => console.log(`Obteniendo aerovales`)
        },
          catchError(this.handleError('Obtener Aerovales', new Aerovale()))
        ));
  }

  getAerovalesPorRangoEnAeroplantas(fechaInicio: string, fechaFin: string): Observable<Aerovale[]> {
    return this.http.get<Aerovale[]>(
      this.aerovalesV2Url+"Aeroplanta/"+this.usuarioService.getAeroplantaActual().codigoAeroplanta, {
      params: {
        fechaDesde: fechaInicio,
        fechaHasta: fechaFin,
        sincronizados: 'true',
      }
    })
      .pipe(
        tap(aerovales => {
          _ => console.log(`obteniendo aerovales por rango en aeroplantas`)
        },
          catchError(this.handleError('Obtener Aerovales', new Aerovale()))
        ));
  }

  getMotivosAnulacion(obs: number): Observable<Observacion[]> {
    return this.http.get<Observacion[]>(this.observacionesUrl + '?tipoObservacion=' + obs)
      .pipe(
        catchError(this.handleError('Obtener Observaciones', []))
      );
  }

  anularAerovale(aerovaleId: string, comentarios: string, observacionId: string, usuario: Usuario): Observable<boolean> {
    
    let headers = new HttpHeaders().set('usuarioDto', JSON.stringify(usuario));

    return this.http.delete<boolean>(this.aerovalesV2Url + '?aerovaleId=' + aerovaleId + '&comentario=' + comentarios + '&observacion=' + observacionId,  { headers: headers})
  }

  exportarAerovalesPorRango(fechaInicio: Date, fechaFin: Date): Observable<any> {
    const url = `${this.baseUrl}/AerovalesV2/${this.usuarioService.getAeroplantaActual().codigoAeroplanta}/Exportar&fechaDesde=${fechaInicio.toISOString()}&fechaHasta=${fechaFin.toISOString()}`;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });

    return this.http.get(url, { headers: headers, responseType: 'blob' as 'json' })
      .pipe(
        tap(_ => console.log(`Descargando excel`))
      );
  }

  exportarAerovalesPorId(aerovalesId: string[]): Observable<any>{
    const url = `${this.baseUrl}/AerovalesV2/Exportar`;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });

    return this.http.post(url,aerovalesId,{ headers: headers, responseType: 'blob' as 'json' })
      .pipe(
        tap(_ => console.log(`Descargando excel`))
      );
  }

  getAbastecedoras(productoId: string): Observable<Abastecedora[]> {
    return this.http.get<Abastecedora[]>(this.productosUrl + productoId + '/Abastecedoras')
      .pipe(
        catchError(this.handleError('Obtener Abastecedoras', []))
      );
  }

  getTodasLasExpendedoras(codigoAeroplanta: string): Observable<Abastecedora[]> {
    return this.http.get<Abastecedora[]>(environment.apiServer.stockBaseUrl +'/AeroplantasV1/'+codigoAeroplanta+"/Expendedoras")
      .pipe(
        catchError(this.handleError('Obtener Abastecedoras', []))
      );
  }

  getAerovalePorIdyTipo(id: string): Observable<AerovaleDetalle> {
    return this.http.get<AerovaleDetalle>(this.aerovalesV2Url + id)
      .pipe(
        catchError(this.handleError('Obtener aerovale', new AerovaleDetalle()))
      );
  }

  sincronizar(id: string): Observable<AerovaleRespuesta> {
    return this.http.put<AerovaleRespuesta>(this.aerovalesV2Url + id + "/Sincronizar  ", null)
      .pipe(
        tap(_ => console.log(`syncronized aerovale id=${id}`)),
        catchError(this.handleError('Obtener aerovale', new AerovaleRespuesta()))
      );
  }

  CrearAerovaleAerocombustible(aerovale: AerovaleAerocombustible): Observable<AerovaleRespuesta> {
    return this.http.post<AerovaleRespuesta>(this.aerovalesV2Url + "Aerocombustible", aerovale)
      .pipe(
        tap(_ => console.log(` aerovale id=${aerovale.id}`)),
        catchError(this.handleError('al crear aerovale de aerocombustible', null))
      );
  }

  ActualizarAerovaleAerocombustible(aerovale: AerovaleAerocombustible, firmaCliente, firmaOperador): Observable<AerovaleRespuesta> {
    let input = new FormData();
    input.append('firmaCliente', firmaCliente ? firmaCliente : null)
    input.append('firmaOperador', firmaOperador ? firmaOperador : null);
    input.append('aerovaleDTO', JSON.stringify(aerovale));

    return this.http.put<AerovaleRespuesta>(this.aerovalesV2Url + aerovale.id + "/" + "Aerocombustible", input);
  }
  
  CrearAerovaleAerocombustibleDesdePrecarga(precargaId: string, aerovale: AerovaleAerocombustible): Observable<AerovaleRespuesta> {
    return this.http.post<AerovaleRespuesta>(this.aerovalesV2Url + precargaId , aerovale)
      .pipe(
        tap(_ => console.log(` aerovale id=${aerovale.id}`)),
        catchError(this.handleError('al crear aerovale de aerocombustible', null))
      );
  }

  CrearPrecargaDesdePedido(pedidoId: string, precargaDto: Precarga): Promise<string> {
    return this.http.post<string>(this.pedidosV2Url + pedidoId + "/Precargas" , precargaDto).toPromise();
  }

  CrearAerovaleCombustible(aerovale: AerovaleCombustible): Observable<AerovaleRespuesta> {
    return this.http.post<AerovaleRespuesta>(this.aerovalesV2Url + "Combustible", aerovale)
      .pipe(
        tap(_ => console.log(` aerovale id=${aerovale.id}`)),
        catchError(this.handleError('al crear aerovale de combustible', null))
      );
  }

  ActualizarAerovaleCombustible(aerovale: AerovaleCombustible, firmaCliente, firmaOperador): Observable<AerovaleRespuesta> {
    let input = new FormData();
    input.append('firmaCliente', firmaCliente ? firmaCliente : null)
    input.append('firmaOperador', firmaOperador ? firmaOperador : null);
    input.append('combustibleDTO', JSON.stringify(aerovale));

    return this.http.put<AerovaleRespuesta>(this.aerovalesV2Url + aerovale.id + "/" + "Combustible", input);
  }

  CrearAerovaleAeroespecialidad(aerovale: AerovaleAeroespecialidad): Observable<AerovaleRespuesta> {
    return this.http.post<AerovaleRespuesta>(this.aerovalesV2Url + "Aeroespecialidad", aerovale)
      .pipe(
        tap(_ => console.log(` aerovale id=${aerovale.id}`)),
        catchError(this.handleError('al crear aerovale de aeroespecialidad', null))
      );
  }

  ActualizarAerovaleAeroespecialidad(aerovale: AerovaleAeroespecialidad, firmaCliente, firmaOperador): Observable<AerovaleRespuesta> {
    let input = new FormData();
    input.append('firmaCliente', firmaCliente ? firmaCliente : null)
    input.append('firmaOperador', firmaOperador ? firmaOperador : null);
    input.append('aeroespecialidadDTO', JSON.stringify(aerovale));

    return this.http.put<AerovaleRespuesta>(this.aerovalesV2Url + aerovale.id + "/" + "Aeroespecialidad", input)
      .pipe(
        tap(_ => console.log(` aerovale id=${aerovale.id}`)),
        catchError(this.handleError('al actualizar aerovale de Aeroespecialidad', null))
      );
  }

  CrearAerovaleAlije(aerovale: AerovaleAlije): Observable<AerovaleRespuesta> {
    return this.http.post<AerovaleRespuesta>(this.aerovalesV2Url + "Alije", aerovale)
      .pipe(
        tap(_ => console.log(` aerovale id=${aerovale.id}`)),
        catchError(this.handleError('al crear aerovale de alije', null))
      );
  }

  ActualizarAerovaleAlije(aerovale: AerovaleAlije, firmaCliente, firmaOperador): Observable<AerovaleRespuesta> {
    let input = new FormData();
    input.append('firmaCliente', firmaCliente ? firmaCliente : null)
    input.append('firmaOperador', firmaOperador ? firmaOperador : null);
    input.append('aerovaleDTO', JSON.stringify(aerovale));

    return this.http.put<AerovaleRespuesta>(this.aerovalesV2Url + aerovale.id + "/" + "Alije", input)
      .pipe(
        tap(_ => console.log(` aerovale id=${aerovale.id}`)),
        catchError(this.handleError('al actualizar aerovale de Alije', null))
      );
  }

  obtenerTodasLasObservaciones(): Observable<Observacion[]> {
    return this.http.get<Observacion[]>(this.observacionesUrl)
      .pipe(
        catchError(this.handleError('Obtener observaciones', []))
      );
  }

  getRemitoPorAerovaleId(id: string): Observable<RemitoFox>
  {
    return this.http.get<RemitoFox>(this.remitosURL + id)
  }

  getOperadores(rol?: number, codigoAeroplanta?: string): Observable<Operador[]> {
    return this.http.get<Operador[]>(this.usuarioUrl + '?rol=' + rol + '&codigoAeroplanta=' + codigoAeroplanta)
    .pipe(
        tap(_ => console.log(`fetched operadores`)),
      );
  }

  getOperadoresConRoles(operador: Operador[], roles: number[], codigoAeroplanta?: string): Observable<Operador[]> {
    return this.http.put<Operador[]>(this.usuarioUrl + '/Roles?listaRoles=' + roles + '&codigoAeroplanta=' + codigoAeroplanta, roles)
  }

  getPorPrefijoYPorNumero(prefijo: number, numero: number): Observable<AerovaleDetalle>
  {
    return this.http.get<AerovaleDetalle>(this.aerovalesV2Url+"?prefijo="+prefijo+"&numeroAerovale="+numero)
    .pipe(
      catchError(this.handleError('Obtener aerovale', new AerovaleDetalle()))
    );
  }

  putNumeracionFox(remito: RemitoFox): Observable<RemitoFox>{
    return this.http.put<RemitoFox>(this.remitosURL, remito);
  }

  putAeroplanta(aep: Aeroplanta): Observable<Aeroplanta>{
    return this.http.put<Aeroplanta>(this.baseUrl + '/AeroplantasV2/' + aep.id, aep);
  }

  putReemplazarAerovales(aerovaleId:string, aerovale: AerovaleDetalle): Observable<AerovaleRespuesta> {
    return this.http.put<AerovaleRespuesta>(this.aerovalesV2Url + 'Reemplazar/' + aerovaleId, aerovale);
  }
}
