<ul class="nav nav-tabs justify-content-start">
  <li *ngIf="authService.hasRole(TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.APROBADORBYR)"
    class="nav-item"><a class="nav-link" routerLink="importRanchos" routerLinkActive="active">Importar ranchos</a>
  </li>
  <!-- SOLO ADUANA (DESPACHANTE)-->
  <li *ngIf="authService.hasRole(TipoUsuario.COMERCIAL, TipoUsuario.DESPACHANTE)"
    class="nav-item"><a class="nav-link" [queryParams]="{solapaRanchosDestino:TipoSolapaRancho.SolicitudRancho}" routerLink="SolicitudRancho" routerLinkActive="active">Solicitud de Rancho</a>
  </li>
  <!-- SOLO COMERCIAL-->
  <li *ngIf="authService.hasRole(TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.APROBADORBYR, TipoUsuario.SOPORTE)"      
    class="nav-item"><a class="nav-link" [queryParams]="{solapaRanchosDestino:TipoSolapaRancho.ListadoRanchoSolicitados}" routerLink="ListadoRanchoSolicitados" routerLinkActive="active">Listado de Ranchos Solicitados</a>
  </li>
  <!-- TODOS-->
  <li *ngIf="authService.hasRole(TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.APROBADORBYR, TipoUsuario.DESPACHANTE, TipoUsuario.OPERADOR, TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.FACTURACION, TipoUsuario.REFACTURACION, TipoUsuario.CONSAEROVALES, TipoUsuario.CONSPRECIOS, TipoUsuario.SOPORTE)"
    class="nav-item"><a class="nav-link" [queryParams]="{solapaRanchosDestino:TipoSolapaRancho.ListadoRanchoConsumidos}" routerLink="ListadoRanchoConsumidos" routerLinkActive="active">Listado de Ranchos Consumidos</a>
  </li>

</ul>

<router-outlet></router-outlet>