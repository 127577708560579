import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManagementIndexComponent } from './management-index/management-index.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from '../app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { HttpClientModule } from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ManagementRoutingModule } from './management-routing.module';
import { SetAeroespecialidadesComponent } from './set-aeroespecialidades/set-aeroespecialidades.component';
import { SetGeneralComponent } from './set-general/set-general.component';
import { UsuariosImportComponent } from './usuarios-import/usuarios-import.component';
import { CustomersImportComponent } from './customers-import/customers-import.component';
import { LaboratoriosImportComponent } from './laboratorios-import/laboratorios-import.component';
import { SetContenedoresComponent } from './set-contenedores/set-contenedores.component';
import { SetAeroplantasComponent } from './set-aeroplantas/set-aeroplantas.component';
import { SharedModule } from '../shared/shared.module';
import { SetEmisorComponent } from './set-emisor/set-emisor.component';
import { ListEmisorComponent } from './list-emisor/list-emisor.component';
import { ModalLeyendasComponent } from './modal-leyendas/modal-leyendas.component';
import { LoadingTextSpinnerComponent } from '../shared/components/loading-text-spinner/loading-text-spinner.component';
import { ModalCertificadoComponent } from './modal-certificado/modal-certificado.component';
import { ClientesComponent } from '../cliente/clientes/clientes.component';
import { ClientesListaComponent } from '../cliente/clientes-lista/clientes-lista.component';
import { ModalWebServiceComponent } from '../cliente/modal-web-service/modal-web-service.component';
import { ClientesInformacionComponent } from '../cliente/clientes-informacion/clientes-informacion.component';
import { ModalDireccionFiscalComponent } from '../cliente/modal-direccion-fiscal/modal-direccion-fiscal.component';
import { ModalCondicionIIBBComponent } from '../cliente/modal-condicion-iibb/modal-condicion-iibb.component';
import { ModalResponsableComponent } from '../cliente/modal-responsable/modal-responsable.component';
import { ModalDireccionEntregaComponent } from '../cliente/modal-direccion-entrega/modal-direccion-entrega.component';
import { ModalNuevaCuentaComponent } from '../cliente/modal-nueva-cuenta/modal-nueva-cuenta.component';
import { ModalComercialesComponent } from '../cliente/modal-comerciales/modal-comerciales.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ModalEnvioMailComponent } from '../cliente/modal-envio-mail/modal-envio-mail.component';
import { ModalClienteExtranjeroComponent } from '../cliente/modal-cliente-extranjero/modal-cliente-extranjero.component';
import { ClientesIndexComponent } from './clientes-index/clientes-index.component';
import { SetCaiAeroplantaComponent } from './set-cai-aeroplanta/set-cai-aeroplanta.component';
import { ClientesAprobarModificacionesComponent } from '../cliente/clientes-aprobar-modificaciones/clientes-aprobar-modificaciones.component';
import { DatosNuevoClienteComponent } from '../cliente/clientes-aprobar-modificaciones/datos-nuevo-cliente/datos-nuevo-cliente.component';
import { InformacionCaiComponent } from './set-cai-aeroplanta/informacion-cai/informacion-cai.component';
import { ReporteClientesComponent } from '../cliente/reporte-clientes/reporte-clientes.component';
import { NgxPrintModule } from 'ngx-print';
import { UploadFileComponent } from '../shared/upload-file/upload-file.component';
import { ModalMotivoRechazoComponent } from '../cliente/clientes-aprobar-modificaciones/modal-motivo-rechazo/modal-motivo-rechazo.component';
import { AbmAeroplantasComponent } from './abm-aeroplantas/abm-aeroplantas.component';
import { DetalleAbmAeroplantaComponent } from './abm-aeroplantas/detalle-abm-aeroplanta/detalle-abm-aeroplanta.component';
import { AeroplantaIndexComponent } from './aeroplanta-index/aeroplanta-index/aeroplanta-index.component';
import { ModalCBUComponent } from '../cliente/modal-cbu/modal-cbu.component';
import { ReportesIndexComponent } from './reportes-index/reportes-index.component';
import { DescargarDatosComponent } from './descargar-datos/descargar-datos.component';
import { AbmMatriculasComponent } from './matriculas/abm-matriculas/abm-matriculas.component';
import { ClienteListaComponent } from './matriculas/abm-matriculas/clientes-lista/cliente-lista.component';
import { MatriculasIndexComponent } from './matriculas/matriculas-index/matriculas-index.component';
import { ClienteDetalleComponent } from './matriculas/abm-matriculas/cliente-detalle/cliente-detalle.component';
import { ModalVehiculoComponent } from './matriculas/abm-matriculas/modal-vehiculo/modal-vehiculo.component';
import { ReporteAutorizacionesComponent } from './matriculas/reporte-autorizaciones/reporte-autorizaciones.component';
import { ModalBusquedaMatriculaComponent } from './matriculas/abm-matriculas/modal-busqueda-matricula/modal-busqueda-matricula.component';
import { ReportePreautorizacionesComponent } from './matriculas/reporte-preautorizaciones/reporte-preautorizaciones.component';
import { ModalPreautorizacionComponent } from './matriculas/reporte-preautorizaciones/modal-preautorizacion/modal-preautorizacion.component';
@NgModule({
  declarations: [
    ManagementIndexComponent,
    SetAeroespecialidadesComponent,
    SetGeneralComponent,
    UsuariosImportComponent,
    CustomersImportComponent,
    LaboratoriosImportComponent,
    SetContenedoresComponent,
    SetAeroplantasComponent,
    SetEmisorComponent,
    ListEmisorComponent,
    ModalLeyendasComponent,
    ModalCertificadoComponent,
    ClientesComponent,
    ClientesListaComponent,
    ClientesInformacionComponent,
    ClientesAprobarModificacionesComponent,
    ModalWebServiceComponent,
    ModalDireccionFiscalComponent,
    ModalCondicionIIBBComponent,
    ModalResponsableComponent,
    ModalDireccionEntregaComponent,
    ModalNuevaCuentaComponent,
    ModalComercialesComponent,
    ModalEnvioMailComponent,
    ModalClienteExtranjeroComponent,
    ClientesIndexComponent,
    SetCaiAeroplantaComponent,
    DatosNuevoClienteComponent,
    InformacionCaiComponent,
    ReporteClientesComponent,
    ModalMotivoRechazoComponent,
    AbmAeroplantasComponent,
    DetalleAbmAeroplantaComponent,
    AeroplantaIndexComponent,
    ModalCBUComponent,
    ReportesIndexComponent,    
    DescargarDatosComponent,
    AbmMatriculasComponent,
    ClienteListaComponent,
    ClienteDetalleComponent,
    ModalVehiculoComponent,
    MatriculasIndexComponent,    
    ReporteAutorizacionesComponent, 
    ModalBusquedaMatriculaComponent, 
    ModalPreautorizacionComponent, 
    ReportePreautorizacionesComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    BrowserModule,
    ManagementRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientInMemoryWebApiModule,
    HttpClientModule,
    NgxSpinnerModule,
    SharedModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    NgxPrintModule
  ],
  entryComponents: [
    ModalWebServiceComponent,
    ModalDireccionFiscalComponent,
    ModalCondicionIIBBComponent,
    ModalResponsableComponent,
    ModalDireccionEntregaComponent,
    ModalNuevaCuentaComponent,
    ModalComercialesComponent,
    ModalEnvioMailComponent,
    ModalClienteExtranjeroComponent,
    ModalMotivoRechazoComponent,
    ModalCBUComponent
  ]
})
export class ManagementModule { }
