import { Component, OnInit } from '@angular/core';
import { ManagementService } from '../management.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UntypedFormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { Producto } from 'src/app/shared/models/despacho/Producto';
import { Producto as ProductoStock} from 'src/app/shared/models/stock/Producto';
import { UsuarioService } from 'src/app/account/usuario.service';
import { Guid } from 'guid-typescript';
import { MessageService } from 'src/app/shell/message.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { Contenedor } from 'src/app/shared/models/stock/Contenedor';
import { TipoContenedor } from 'src/app/shared/enums/tipoContenedor';
import { empty } from 'rxjs';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-set-contenedores',
  templateUrl: './set-contenedores.component.html',
  styleUrls: ['./set-contenedores.component.css']
})
export class SetContenedoresComponent implements OnInit {

  cargandoProductos: boolean = false;
  cargandoContenedores: boolean = false;
  actualizandoContenedores: boolean = false;
  contenedores: Contenedor[];
  contenedoresValoresAnteriores: Contenedor[];
  productos: Producto[];
  change$: Subscription;
  obteniendoPedidos: boolean;
  page: number;

  constructor(private managementService: ManagementService, 
    private spinner: NgxSpinnerService, 
    private usuarioService: UsuarioService, 
    private messageService: MessageService,
    sharedService: SharedService) {
      this.change$ = sharedService.aeroplantaSeleccionada.subscribe(_=>{
        this.reload()
      })
  }

  ngOnInit() {
    setTimeout(() => {
      this.spinner.show('spinnerLista');
    });

    this.cargandoContenedores = true;

    try{
      this.managementService.getContenedores(this.usuarioService.getAeroplantaActual().codigoAeroplanta)
        .subscribe(result => {
          this.contenedores = result;
          this.actualizarContenedoresAnteriores(this.contenedores);
          this.spinner.hide('spinnerLista');
          this.cargandoContenedores = false;
        });
    }catch(ex){
      console.log(ex);
    }

    this.cargandoProductos = true;
    this.managementService.getAllProductos()
      .subscribe(result => {
        this.productos = result;
        this.spinner.hide('spinnerLista');
        this.cargandoProductos = false;
      });
  }

  ngOnDestroy(): void {
    this.change$.unsubscribe(); 
  }

  reload() {
    this.contenedores = [];
    this.spinner.show('spinnerLista');
    //let a = this.cambioAeroplanta$;
    //this.usuarioService.setAeroplanta(this.usuarioService.getAeroplantaActual().codigoAeroplanta);
    this.cargandoProductos = true;
    
    this.managementService.getContenedores(this.usuarioService.getAeroplantaActual().codigoAeroplanta)
      .subscribe(result => {
        this.contenedores = result;
        this.actualizarContenedoresAnteriores(this.contenedores);
        this.spinner.hide('spinnerLista');
        this.cargandoContenedores = false;
      });
  }

  limpiarColumnas() {
    document.getElementById("primerFila").remove();
    document.getElementById("segundaFila").remove();
    document.getElementById("tercerFila").remove();

  }

  getDescripcionTipoContenedor(tipoContenedor){
    if(tipoContenedor == TipoContenedor.Abastecedora){
      return "Abastecedora";
    }else if(tipoContenedor == TipoContenedor.Cisterna){
      return "Cisterna";
    }else if(tipoContenedor == TipoContenedor.Deposito){
      return "Deposito";
    }else if(tipoContenedor == TipoContenedor.Poliducto){
      return "Poliducto";
    }else if(tipoContenedor == TipoContenedor.Tanque){
      return "Tanque";
    }else if(tipoContenedor == TipoContenedor.Indeterminado){
      return "No determinado";
    }else{
      return "-";
    }
  }

  compararProductos(p1: Producto, p2: Producto) {
      if (p1==null || p2==null) {
        return false;
      }

      return p1.codigoProducto===p2.codigoProducto;
  }

  onProductoSeleccionado(target, contenedor:Contenedor){ 
    //var productoAnterior = Object.assign({}, contenedor.productoActual); // clonar
    //var producto = this.productos[target.selectedIndex];

    this.spinner.show('spinnerLista');
    this.cargandoProductos = true;

    // Llamar a endpoints para asignar producto a contenedor (stock) y producto a abastecedora (despacho).
    /*
    var productoStock = new ProductoStock();
    productoStock.id = Guid.parse(producto.id);
    productoStock.codigoProducto = producto.codigoProducto;
    productoStock.nombreProducto = producto.nombreProducto;
    productoStock.tipoProducto = producto.tipoProducto;
    productoStock.unidadMedida = producto.unidadMedida;
    */
    contenedor.aeroplantaCodigo = this.usuarioService.getAeroplantaActual().codigoAeroplanta;
    //contenedor.productoActual = productoStock;
    
    this.managementService.asignarProductoEnContenedor(contenedor)
    .subscribe(response => {
      console.log(response);           

      this.managementService.asignarProductoEnAbastecedora(contenedor)
      .subscribe(response => {
        console.log(response);
        this.cargandoProductos = false;
        this.spinner.hide('spinnerLista');
        this.messageService.showSuccessMessage("Se asigno producto en contenedor."); 
        this.actualizarContenedoresAnteriores(this.contenedores);       
      },
      err => {
        this.cargandoProductos = false;
        this.spinner.hide('spinnerLista');
        this.messageService.showErrorMessage("Error al asignar producto a contenedor: " + err.description);
        this.reasignarVisualmenteProductoAnterior(contenedor);   
        this.rollbackAsignacionServidor(contenedor);     
      });
    },
    err => {
      this.cargandoProductos = false;
      this.spinner.hide('spinnerLista');
      this.messageService.showErrorMessage("Error al asignar producto a contenedor: " + err.description);
      this.reasignarVisualmenteProductoAnterior(contenedor);
    });
  }

  rollbackAsignacionServidor(contenedor: Contenedor){
    for (var valorContenedorAnterior of this.contenedoresValoresAnteriores) {
      if(contenedor.codigo == valorContenedorAnterior.codigo){
        this.managementService.asignarProductoEnContenedor(valorContenedorAnterior)
        .subscribe(response => {
          console.log(response); 
        },
        err => {
          console.log(err); 
        });
        break;
      }
    }
  }

  reasignarVisualmenteProductoAnterior(contenedor: Contenedor){
    for (var valorContenedorAnterior of this.contenedoresValoresAnteriores) {
      if(contenedor.codigo == valorContenedorAnterior.codigo){
        contenedor.productoActual = valorContenedorAnterior.productoActual;
        break;
      }
    }
  }

  actualizarContenedoresAnteriores(contenedores){
    this.contenedoresValoresAnteriores = JSON.parse(JSON.stringify(contenedores));
  }
}
