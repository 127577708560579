<ul class="nav nav-tabs justify-content-start">
  <li *ngIf="hasRole(TipoUsuario.IMPUESTOS, TipoUsuario.APROBADORBYR)" class="nav-item"><a
      class="nav-link" routerLink="parametrosV2" routerLinkActive="active">Parámetros impositivos</a>
  </li>
  <li    
  *ngIf="hasRole(TipoUsuario.IMPUESTOS, TipoUsuario.APROBADORBYR)"
    class="nav-item"><a class="nav-link" routerLink="padrones" routerLinkActive="active">Importar padrón</a>
  </li>
  <li
    *ngIf="hasRole(TipoUsuario.FACTURACION)"
    class="nav-item"><a class="nav-link" routerLink="remitos" routerLinkActive="active">Facturar Remitos</a>
  </li>
  <li
    *ngIf="hasRole(TipoUsuario.FACTURACION)"
    class="nav-item"><a class="nav-link" routerLink="holdeoPrincipal" routerLinkActive="active">Holdeo</a>
  </li>
  <li
    *ngIf="hasRole(TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_LN, TipoUsuario.COMERCIAL_AV, TipoUsuario.APROBADORBYR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.TUTOR, TipoUsuario.FACTURACION, TipoUsuario.REFACTURACION, TipoUsuario.CONSPRECIOS, TipoUsuario.SUPERVISOR, TipoUsuario.SEGURIDADTABLAS, TipoUsuario.RESPRODUCTO, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO)"
    class="nav-item"><a class="nav-link" routerLink="refacturacion" routerLinkActive="active">Refacturacion</a>
  </li>
  <li
    *ngIf="hasRole(TipoUsuario.COMERCIAL, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.SUPERVISOR, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.OPERADOR, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.APROBADORBYR, TipoUsuario.ADMCLIENTES, TipoUsuario.IMPUESTOS, TipoUsuario.SEGURIDADTABLAS, TipoUsuario.FACTURACION, TipoUsuario.REFACTURACION, TipoUsuario.CONSPRECIOS, TipoUsuario.CONSAEROVALES, TipoUsuario.SOPORTE)"
    class="nav-item"><a class="nav-link" routerLink="reportes" routerLinkActive="active">Reportes</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLink="documentos" routerLinkActive="active">Documentos</a>
  </li>
</ul>
<router-outlet></router-outlet>
