<nav class="navbar navbar-light ms-2 bg-light navbar-expand-lg ">
    <div class="collapse navbar-collapse" id="navbarSupportedContent">        
        <!--<div class="form-inline me-2">
            <input id="busquedaFormControl" type="text" class="form-control" placeholder="Buscador"/>
        </div>-->
        <h4 style="display: inline;">Configuración aeroplantas.</h4>
    </div>
</nav>

<div class="row">  
    <div class="col col-3">
        <div class="list-group py-3 menu-izq">
            <div class="col col-md-12" *ngIf="this.cargandoLista">
                <div class="spinner-container" >
                    <ngx-spinner name="spinnerGrafico" bdOpacity=0.3bd
                        Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
                        <p style="color: white">C a r g a n d o . . . </p>
                    </ngx-spinner>
                </div>
            </div>
            <!-- lista de aeroplantas -->
            <div *ngIf="!this.cargandoLista">
                <div *ngFor="let a of aeroplantas; let i=index">
                    <button type="button" class="list-group-item list-group-item-action" (click)="usuarioSeleccionoAeroplanta(i)">
                    <div class="d-flex w-100 justify-content-between">
                        <p >{{ a.nombre }} </p>
                    </div>
                    </button>
                </div>
            </div>        
        </div>
    </div>
    <!-- informacion de las lineas aereas -->
    <div class="col col-9 py-3">
        <div *ngIf="this.aeroplantaSeleccionada != null" id="tablaInformacion">
            <app-aeroplantas-detalle [aeroplantaSeleccionada]="this.aeroplantaSeleccionada"></app-aeroplantas-detalle>
        </div>
    </div>
</div>
    
<ng-template #content let-modal>
    <app-aeroplantas-detalle></app-aeroplantas-detalle>
</ng-template>