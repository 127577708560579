import { Component, OnInit, ViewChild, HostListener, Inject } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { UsuarioService } from 'src/app/account/usuario.service';
import { EstadoPedidoMonitoreo } from 'src/app/shared/enums/EstadoPedidoMonitoreo';
import { MonitoreoConfiguracion } from 'src/app/shared/models/despacho/MonitoreoConfiguracion';
import { PedidoMonitoreo } from 'src/app/shared/models/despacho/PedidoMonitoreo';
import { MessageService } from 'src/app/shell/message.service';
import { PedidoService } from '../../pedido.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-screen-monitor',
  templateUrl: './screen-monitor.component.html',
  styleUrls: ['./screen-monitor.component.scss']
})
export class ScreenMonitorComponent implements OnInit {

  obteniendoPedidos:boolean=false;
  pedidos: PedidoMonitoreo[];
  
  pedidosAux: PedidoMonitoreo[];
 
  @HostListener('document:fullscreenchange', ['$event'])
  @HostListener('document:webkitfullscreenchange', ['$event'])
  @HostListener('document:mozfullscreenchange', ['$event'])
  @HostListener('document:MSFullscreenChange', ['$event'])
  elem: any; 
  isFullScreen: boolean; 

  configuracion:MonitoreoConfiguracion;
  
  EstadoPedidoMonitoreo = EstadoPedidoMonitoreo;
  fechaHoy:Date;
  seg = 70;
  segundos:number=this.seg;
  idInterval;
  idInterval2;

  constructor(
    private modal: NgbModal,
    private spinner: NgxSpinnerService,
    private pedidoService: PedidoService,
    private usuarioService:UsuarioService,
    private messageService: MessageService,
    @Inject(DOCUMENT) private document: any
    ) {}

  ngOnInit(): void {    
    this.obtenerConfiguracion();
    this.obtenerMonitorPedidos(true);
    this.countDown();    
    this.countDown2();    
  }

  ngOnDestroy() {
    if (this.idInterval) {
      clearInterval(this.idInterval);
    }
    if (this.idInterval2) {
      clearInterval(this.idInterval2);
    }
  }

  /** full screen */
  ngAfterViewInit() {
    this.getFullScreenMonitor();
  }

  fullscreenmodes(event){
    this.chkScreenMode();
  }

  chkScreenMode(){
    if(document.fullscreenElement){
      //fullscreen
      this.isFullScreen = true;
    }else{
      //not in full screen
      this.isFullScreen = false;
    }
  }

  getFullScreenMonitor() {        
    this.elem = document.getElementById("monitorPedidos");
    console.log(this.elem)
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
    } else if (this.elem.mozRequestFullScreen) {
      /* Firefox */
      this.elem.mozRequestFullScreen();
    } else if (this.elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) {
      /* IE/Edge */
      this.elem.msRequestFullscreen();
    }    
  }

  /** utiles */
  countDown() {
    this.idInterval = setInterval(() => {
      this.segundos--;
      if(this.segundos==0) {
        this.controlarTiempoTranscurrido();
        this.segundos=this.seg;
        /*this.obteniendoPedidos=false;
        this.spinner.hide();*/
      }
      /**
       * en un futuro agregar signal R para refrescar el monitor
       */
      if(this.segundos==10) {
        /*this.obteniendoPedidos=true;
        this.spinner.show();*/
        this.obtenerMonitorPedidos(false);
      }
    }, 1000);
  }

  countDown2() {
    this.idInterval2 = setInterval(() => {
      this.fechaHoy = new Date();
    }, 1000);
  }

  controlarTiempoTranscurrido() {
    if(this.pedidos.length>0) {
      this.pedidos.forEach( p => {
        p.minutos = this.calcularDiferenciaMinutos(new  Date(p.fechaHoraVuelo), new Date());
        p.color='#FFFFFF';
        if(p.esPrioridad && p.estado != EstadoPedidoMonitoreo.Cargado) {
          p.color=this.configuracion.colorPrioridad;
        } else {
          if(p.estado==EstadoPedidoMonitoreo.Pendiente || p.estado==EstadoPedidoMonitoreo.Asignado) {
            for(let x of this.configuracion.configuracionesTiempo.sort( (a,b) => a.minutos - b.minutos)) {
              if(p.minutos<=x.minutos) {
                p.color=x.color;
                break;
              }
            }          
          } else { 
            p.estado==EstadoPedidoMonitoreo.Precargado ? p.color=this.configuracion.colorPrecargado : p.color=this.configuracion.colorCargado
          } 
        }
      }); 
      this.pedidosAux = this.pedidos;
    }    
  }

  calcularDiferenciaMinutos(fecha1, fecha2) {
    let diferencia = (fecha1.getTime() - fecha2.getTime()) / 1000 / 60;
    //return Math.round(Math.abs(diferencia));
    return Math.round(diferencia);
  }

  getCodigoAeroplanta(){
    return this.usuarioService.getAeroplantaActual().codigoAeroplanta;
  }

  obtenerTipoEstado(tipo){
    return EstadoPedidoMonitoreo[tipo];
  }

  transformarTexto(estado:EstadoPedidoMonitoreo, texto:string) {
    //",456,457"
    let newTexto:string="";
    if(estado==EstadoPedidoMonitoreo.Asignado) {      
      for (let i = 0; i < texto.length; i++) {
        let element = texto.charAt(i);
        if(element == ",") {
          newTexto = newTexto.concat(element.concat(" "))
        } else {
          newTexto = newTexto.concat(element);
        }
      }      
    } else if(texto.length>0) {
      newTexto = " "+texto;
    } else {
      newTexto = texto;
    }      
    return newTexto;   
  }

  /** servicios */
  obtenerConfiguracion() {
    this.pedidoService.getMonitoreoConfiguracion(this.getCodigoAeroplanta())
    .subscribe( c => {
      this.configuracion=c;
      this.configuracion.configuracionesTiempo=this.configuracion.configuracionesTiempo.sort( (a,b) => a.minutos - b.minutos );
    });
  }

  async obtenerMonitorPedidos(controlar:boolean) {

    try {
      let r = await this.pedidoService.getMonitoreoPedidos(this.getCodigoAeroplanta());        
      this.pedidos = r.sort((a,b) => new Date(a.fechaHoraVuelo).getTime() - new Date(b.fechaHoraVuelo).getTime())                
      if(this.pedidos.length>0 && controlar) {
        this.pedidosAux = this.pedidos;
        this.controlarTiempoTranscurrido();
      }
    } catch (error) {      
      this.messageService.showErrorMessage("Error: " + error.message + " descripcion: " + error.descripcion);
    }    
  }
}
