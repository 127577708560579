import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbmIndexComponent } from './abm-index/abm-index.component';
import { ImpuestosComponent } from './producto/impuestos/impuestos.component';
import { InformacionComponent } from './producto/informacion/informacion.component';
import { ProductosComponent } from './producto/productos/productos.component';
import { ProductosListaComponent } from './producto/productos-lista/productos-lista.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from '../shared/shared.module';
import { AbmRoutingModule } from './abm-routing.module';
import { NuevoImpuestoComponent } from './producto/nuevo-impuesto/nuevo-impuesto.component';
import { NuevoProductoComponent } from './producto/nuevo-producto/nuevo-producto.component';
import { NotificacionesIndexComponent } from './notificacion/notificaciones-index/notificaciones-index.component';
import { NotificacionesListaComponent } from './notificacion/notificaciones-clientes/notificaciones-lista/notificaciones-lista.component';
import { NuevaNotificacionComponent } from './notificacion/notificaciones-clientes/nueva-notificacion/nueva-notificacion.component';
import { InformacionRubroComponent } from './rubro/informacion-rubro/informacion-rubro.component';
import { NuevoRubroComponent } from './rubro/nuevo-rubro/nuevo-rubro.component';
import { RubrosComponent } from './rubro/rubros/rubros.component';
import { RubrosListaComponent } from './rubro/rubros-lista/rubros-lista.component';
import { ImpuestosMasivosComponent } from './impuesto/impuestos-masivos/impuestos-masivos.component';
import { PreciosComponent } from './precio/precios/precios.component';
import { DetallePreciosComponent } from './precio/detalle-precios/detalle-precios.component';
import { ModalConsultaComponent } from './precio/modal-consulta/modal-consulta.component';
import { ModalExportacionComponent } from './precio/modal-exportacion/modal-exportacion.component';
import { NotificacionDetalleComponent } from './notificacion/notificaciones-clientes/notificacion-detalle/notificacion-detalle.component';
import { NotificacionesHistorialComponent } from './notificacion/notificaciones-clientes/notificaciones-historial/notificaciones-historial.component';
import { ListaAeroplantasComponent } from '../shared/lista-aeroplantas/lista-aeroplantas.component';
import { NotificacionesListaSistemaComponent } from './notificacion/notificaciones-sistema/notificaciones-lista-sistema/notificaciones-lista-sistema.component';
import { NotificacionDetalleSistemaComponent } from './notificacion/notificaciones-sistema/notificacion-detalle-sistema/notificacion-detalle-sistema.component';
import { NuevaNotificacionSistemaComponent } from './notificacion/notificaciones-sistema/nueva-notificacion-sistema/nueva-notificacion-sistema.component';
import { NotificacionesHistorialSistemaComponent } from './notificacion/notificaciones-sistema/notificaciones-historial-sistema/notificaciones-historial-sistema.component';
import { GruposListaComponent } from './notificacion/grupos/grupos-lista/grupos-lista.component';
import { NotificacionesClienteComponent } from './notificacion/notificaciones-clientes/notificaciones-cliente.component';
import { NotificacionesSistemaComponent } from './notificacion/notificaciones-sistema/notificaciones-sistema.component';
import { UpsertFormGrupoComponent } from './notificacion/grupos/upsert-form-grupo/upsert-form-grupo.component';

@NgModule({
  declarations: [
    AbmIndexComponent,
    ImpuestosComponent,
    InformacionComponent,
    ProductosComponent,
    ProductosListaComponent,
    NuevoImpuestoComponent,
    NuevoProductoComponent,
    NotificacionesIndexComponent,
    NotificacionesListaComponent,
    NuevaNotificacionComponent,
    InformacionRubroComponent,
    NuevoRubroComponent,
    RubrosComponent,
    RubrosListaComponent,
    ImpuestosMasivosComponent,
    PreciosComponent,
    DetallePreciosComponent,
    ModalConsultaComponent,
    ModalExportacionComponent,
    NotificacionDetalleComponent,
    NotificacionesHistorialComponent,
    NotificacionesClienteComponent,
    NotificacionesSistemaComponent,
    NotificacionesListaSistemaComponent,
    NotificacionDetalleSistemaComponent,
    NuevaNotificacionSistemaComponent,
    ListaAeroplantasComponent,
    NotificacionesHistorialSistemaComponent,
    GruposListaComponent,
    UpsertFormGrupoComponent
  ],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    AbmRoutingModule,
    SharedModule,
    NgxSpinnerModule,
    NgbModule,
    BrowserAnimationsModule
  ],
  entryComponents: [
    ModalConsultaComponent,
    ModalExportacionComponent,
    NuevaNotificacionComponent,
    NuevaNotificacionSistemaComponent,
    NotificacionesHistorialComponent,
    ListaAeroplantasComponent
  ]
})
export class AbmModule {
}
