<div class="col col-md-12" *ngIf="cargandoLista">
    <div class="spinner-container" >
        <ngx-spinner name="spinnerGrafico" bdOpacity=0.3bd
            Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
            <p style="color: white">C a r g a n d o . . . </p>
        </ngx-spinner>
    </div>
</div>
<div *ngIf="!cargandoLista">
    <div *ngFor="let p of productos; let i = index">
        <button type="button" 
            [ngClass]="{'seleccionado': this.prodSeleccionado && prodSeleccionado.id == p.id}"
            class="list-group-item list-group-item-action"    
            (click)="mostrarDetalle(i)">
            <div class="d-flex w-100 justify-content-between">
                <ul class="list-unstyled">
                    <li>{{ p.nombreProducto}}</li>                    
                </ul>
            </div>
        </button>    
    </div>
</div>