<div class="card mt-2" *ngIf="this.cierreSeleccionado != null" id="invoicingPrint">
  <div class="card-header">
    <label><b>Cierre Seleccionado: {{cierreSeleccionado.fechaHora | date: "d'/'MM'/'y' 'hh:mm a"}}</b></label>
  </div>
  <div class="card-body">
    <div class="col-12">
      <table class="table table-striped table-hover text-center letra"
        *ngIf="this.cierreSeleccionado.facturas.length > 0 && !obteniendoFacturas">
        <thead>
          <th>Cliente</th>
          <th>Factura</th>
          <th>Producto</th>
          <th>Fecha/Hora</th>
          <th>Pago con tarjeta</th>
          <th>Importe en Efectivo</th>
          <th>Usuario</th>
          <th id="noPrint">Ver</th>
        </thead>
        <tbody>
          <tr *ngFor="let factura of this.cierreSeleccionado.facturas; let i = index">
            <td>{{factura.cliente}}</td>
            <td>{{factura.prefijo}} - {{factura.numeroComprobante}}</td>
            <td>
              <ul *ngFor="let prod of factura.renglones" class="list-unstyled">
                <li>{{prod.productoCodigo}} - {{prod.productoNombre}}</li>
              </ul>
            </td>
            <td>{{ factura.fechaEmision | date: "dd/MM/yyy HH:mm a" }}</td>
            <td>{{totalCupones(factura.pago) | number: '0.2'}}</td>
            <td>{{factura.pago[0].totalPago - totalCupones(factura.pago) | number: '0.2'}}</td>
            <td>{{factura.responsable}}</td>
            <td id="noPrint">
              <button class="btn btn-primary mb-2" type="button" (click)="mostrarFacrtura(factura)"
                [disabled]="factura.buscarComprobante">
                <span *ngIf="!factura.buscarComprobante"><i class="fa fa-eye" aria-hidden="true"></i></span>
                <div *ngIf="factura.buscarComprobante" class="d-flex align-items-center">
                  <div class="spinner-border text-light spinner-border-sm" role="status"></div>
                  <span><i class="fa fa-eye" aria-hidden="true"></i></span>
                </div>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <form>
      <div style="text-align: right;" class="mt-2">
        <div class="row">
          <label style="margin-right: 5em;" class="col col-8" type="text">
            <b>Total ingreso en efectivo: </b>
          </label>
          <div class="border col">
            {{this.cierreSeleccionado.totalEfectivoIngresado | number: '0.2'}}
          </div>
        </div>
      </div>

      <div style="text-align: right;" class="mt-2">
        <div class="row">
          <label style="margin-right: 5em;" class="col col-8" type="text">
            <b>Total ingreso calculado por pad: </b>
          </label>
          <div class="border col">
            {{this.totalDeFacturas | number: '0.2'}}
          </div>
        </div>
      </div>

      <div style="text-align: right;" class="mt-2">
        <div class="row">
          <label style="margin-right: 5em;" class="col col-8" type="text">
            <b>Diferencia: </b>
          </label>
          <div class="border col">
            {{this.diferencia | number: '0.2'}}
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="card-footer">
    <button class="btn btn-outline-primary me-2" (click)="print()"><i class="fa fa-print" aria-hidden="true"></i>
      Imprimir</button>
    <button class="btn btn-outline-success" (click)="exportar()"><i class="fa fa-file-excel-o" aria-hidden="true"></i>
      Exportar</button>
  </div>
</div>