<div class="col col-md-12">
  <div class="spinner-container" *ngIf="cargandoInfoProducto">
    <ngx-spinner name="spinnerGrafico" bdOpacity=0.3bd
      Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
      <p style="color: white">C a r g a n d o . . . </p>
    </ngx-spinner>
  </div>
</div>
<div class="card add-margin" *ngIf="this.productoSeleccionado!=null && !this.cargandoInfoProducto">
  <form [formGroup]="informacionForm">
    <h4 class="card-header card-title ">Informacion
    </h4>

    <div class="card-body">
      <div class="row">
        <!-- codigo -->
        <div class="mb-3 col-md-6">
          <div class="mb-3 row">
            <div class="col-md-4">
              <label for="codigoControlInput" class="col-form-label"> <span
                  *ngIf="campoInicializadoEnVacio(this.codigo.value)">*</span> Codigo:</label>
            </div>
            <div class="col-md-4">
              <div class="input-group mb-2">
                <input  [readonly]="ocultarInputGeneral()" id="codigoControlInput" type="text" class="form-control mb-2 me-sm-2"
                  title="El campo codigo no se puede modificar" formControlName="codigoFormControl" placeholder="###"
                  [value]="this.productoSeleccionado.codigoProducto" />
              </div>
            </div>
          </div>
        </div>
        <!-- Nombre -->
        <div class="mb-3 col-md-6">
          <div class="mb-3 row">
            <div class="col-md-5">
              <label for="descripcionControlInput" class="col-form-label"> <span
                  *ngIf="campoInicializadoEnVacio(this.descripcion.value)">*</span> Nombre: </label>
            </div>
            <div class="col-md-7">
              <div class="input-group mb-2">
                <input [readonly]="ocultarInputGeneral()" id="descripcionControlInput" type="text" class="form-control mb-2 me-sm-2"
                  title="Campo obligatorio, debe ingresar un nombre del producto."
                  formControlName="descripcionFormControl" placeholder="###"
                  [value]="this.productoSeleccionado.nombreProducto" />
              </div>
            </div>
          </div>
        </div>

        <div class="mb-3 col-md-6">
          <div class="mb-3 row">
            <div class="col-md-4">
              <label for="tipoProductoControlInput" class="col-form-label">Tipo/Rubro:</label>
            </div>
            <div class="col-md-7">
              <div class="input-group mb-2">
                <select [readonly]="ocultarInputGeneral()"  type="text" class="form-select" name="tipoID" formControlName="tipoProductoFormControl"
                  title="Seleccione un tipo de rubro. Campo requerido." (change)="esAerocombustible()">
                  <option id="optionUnidadMedida" *ngFor="let rubro of  rubros" [ngValue]="rubro">
                    {{ rubro.nombre }}
                  </option>
                </select>
              </div>
              <loading-text-spinner *ngIf="cargandoRubros" [error]="errorRubros" message="Obteniendo rubro..."
                color="primary">
              </loading-text-spinner>
            </div>
          </div>
        </div>
        <!-- Unidad de medida -->
        <div class="mb-3 col-md-6">
          <div class="mb-3 row">
            <div class="col-md-5">
              <label for="observacionesControlSelect" class="col-form-label">Unidad de medida:</label>
            </div>
            <div class="col-md-7">
              <div class="input-group mb-2">
                <select  [readonly]="ocultarInputGeneral()" id="unidadMedidaFormControl" type="text" class="form-select" name="unidadDeMedidaID"
                  formControlName="unidadMedidaFormControl" title="Seleccione una unidad de medida. Campo requerido.">
                  <option id="optionUnidadMedida" *ngFor="let unidad of keysUnidadDeMedida()"
                    [ngValue]="this.obtenerUnidadMedida(unidad)">
                    {{ unidad }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div class="mb-3 col-md-6">
          <div class="mb-3 row">
            <div class="col-md-4">
              <label for="tipoPrecioControlInput" class="col-form-label">Tipo de precio:</label>
            </div>
            <div class="col-md-7">
              <div class="input-group mb-2">
                <select  [readonly]="ocultarInputGeneral()" id="tipoPrecioFormControl" type="text" class="form-select" name="tipoPrecioID"
                  formControlName="tipoPrecioFormControl" title="Campo obligatorio, Seleccione un tipo de precio.">
                  <option id="optionUnidadMedida" *ngFor="let precio of keysTipoPrecio()"
                    [ngValue]="this.obtenerTipoPrecio(precio)">
                    {{ precio }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <!--COLOR-->
        <div class="mb-3 col-md-6">
          <div class="mb-3 row">
            <div class="col-md-5">
              <label for="colorFormControl" class="col-form-label">Color: </label>
            </div>
            <div class="col-md-7">
              <select [readonly]="ocultarInputGeneral()" class="form-select" id="product-color" (change)="onChange($event.target.value)" [ngStyle]="{'background-color':selectedColor}">
                <option [selected]="color.value == selectedColor" *ngFor="let color of colores" [value]="color.value" [ngStyle]="{'background-color':color.value}">{{color.name}}</option>
              </select>
            </div>
          </div>
        </div>

        <!--TIPO COMBUSTIBLE-->
        <div class="mb-3 col-md-6"
          *ngIf="mostrarCampoTipoCombustible">
          <div class="mb-3 row">
            <div class="col-md-4">
              <label for="tipoCombustibleControlInput" class="col-form-label">Tipo combustible:</label>
            </div>
            <div class="col-md-7">
              <div class="input-group mb-2">
                <select  [readonly]="ocultarInputGeneral()" id="tipoCombustibleFormControl" type="text" class="form-select" name="tipoPrecioID"
                  formControlName="tipoCombustibleFormControl"
                  title="Campo obligatorio, Seleccione un tipo de combustible.">
                  <option id="optionTipoCombustible" *ngFor="let combustible of keysTipoCombustible()"
                    [ngValue]="this.obtenerCombustible(combustible)" [readonly]>
                    {{ combustible }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <!--TIPO AEROCOMBUSTIBLE-->
        <div class="mb-3 col-md-6"
          *ngIf="mostrarCampoTipoAerocombustible">
          <div class="mb-3 row">
            <div class="col-md-4">
              <label for="tipoAerocombustibleControlInput" class="col-form-label">Tipo aerocombustible:</label>
            </div>
            <div class="col-md-7">
              <div class="input-group mb-2">
                <select  [readonly]="ocultarInputGeneral()" id="tipoAerocombustibleFormControl" type="text" class="form-select" name="tipoPrecioID"
                  formControlName="tipoAerocombustibleFormControl"
                  title="Campo obligatorio, Seleccione un tipo de aeroombustible.">
                  <option id="optionTipoAerocombustible" *ngFor="let aerocombustible of keysTipoAerocombustible()"
                    [ngValue]="this.obtenerAerocombustible(aerocombustible)" [readonly]>
                    {{ aerocombustible }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <!--TIPO SERVICIO-->
        <div class="mb-3 col-md-6"
          *ngIf="mostrarCampoTipoServicio">
          <div class="mb-3 row">
            <div class="col-md-4">
              <label class="col-form-label">Tipo de servicio:</label>
            </div>
            <div class="col-md-7">
              <div class="input-group mb-2">
                <select  [readonly]="ocultarInputGeneral()" id="tipoServicioFormControl" type="text" class="form-select" name="tipoServicioID"
                  formControlName="tipoServicioFormControl"
                  title="Campo obligatorio, Seleccione un tipo de servicio.">
                  <option *ngFor="let servicio of keysTipoServicio()"
                    [ngValue]="this.obtenerServicio(servicio)">
                    {{ servicio }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <!--PORC ALIJE EN AEROCOMBUSTIBLES-->
        <div class="mb-3 col-md-6"
          *ngIf="this.tipoDeProducto.value != null && this.tipoDeProducto.value.aerocombustible">
          <div class="mb-3 row">
            <div class="col-md-5">
              <label for="porcentajeAlijeFormControl" class="col-form-label">Porcentaje Alije: </label>
            </div>
            <div class="col-md-7">
              <div class="input-group mb-2">
                <input [readonly]="ocultarInputGeneral()" id="porcentajeAlijeFormControl" type="number" class="form-control mb-2 me-sm-2"
                  title="Campo obligatorio, debe ingresar el porcentaje de Alije." placeholder="%"
                  formControlName="porcentajeAlijeFormControl" (keypress)="onlyDecimalNumberKey($event)"/>
              </div>
            </div>
          </div>
        </div>
        
        <div class="mb-3 col-md-6"
          *ngIf="this.tipoDeProducto.value != null && !esAeroespecialidad() && !esServicio()">
          <div class="mb-3 row">
            <div class="col-md-4">
              <label for="densidadMinimaFormControl" class="col-form-label">Densidad mínima: </label>
            </div>
            <div class="col-md-7">
              <div class="input-group mb-2">
                <input [readonly]="ocultarInputGeneral()"  id="densidadMinimaFormControl" type="text" class="form-control mb-2 me-sm-2"
                  title="Campo obligatorio, debe ingresar la densidad." placeholder="####"
                  formControlName="densidadMinimaFormControl" (keypress)="formatDensidad($event, 0)"
                  (change)="densidadMenor()" [value]="this.productoSeleccionado.densidadMin" />
              </div>
            </div>
          </div>
        </div>
        <div class="mb-3 col-md-6"
          *ngIf="this.tipoDeProducto.value != null && !esAeroespecialidad() && !esServicio()">
          <div class="mb-3 row">
            <div class="col-md-5">
              <label for="densidadMaximaFormControl" class="col-form-label">Densidad máxima: </label>
            </div>
            <div class="col-md-7">
              <div class="input-group mb-2">
                <input  [readonly]="ocultarInputGeneral()" id="densidadMaximaFormControl" type="text" class="form-control mb-2 me-sm-2"
                  title="Campo obligatorio, debe ingresar la densidad." placeholder="####"
                  formControlName="densidadMaximaFormControl" (keypress)="formatDensidad($event, 1)"
                  (change)="densidadMenor()" [value]="this.productoSeleccionado.densidadMax" />
              </div>
              <div class="alert alert-danger" *ngIf="this.densidadErronea">La densidad máxima no puede ser menor que la
                mínima</div>
            </div>
          </div>
        </div>
        <!--COMIENZO NUEVOS DATOS PRODUCTO-->
        <!--PELIGROSIDAD-->
        <div class="mb-3 col-md-6">
          <div class="mb-3 row">
            <div class="col-md-4">
              <label for="peligrosidadFormControl" class="col-form-label">*Peligrosidad: </label>
            </div>
            <div class="col-md-7">
              <div class="input-group mb-2">
                <input [readonly]="ocultarInputGeneral()"  id="peligrosidadFormControl" type="text" class="form-control"
                  formControlName="peligrosidadFormControl" placeholder="Peligrosidad" maxlength="6" />
              </div>
            </div>
          </div>
        </div>
        <!--AGRUPACION CONTABLE-->
        <div class="mb-3 col-md-6">
          <div class="mb-3 row">
            <div class="col-md-5">
              <label for="contableFormControl" class="col-form-label">*Agrupación contable: </label>
            </div>
            <div class="col-md-7">
              <div class="input-group mb-2">
                <input  [readonly]="ocultarInputGeneral()" id="contableFormControl" type="text" class="form-control" formControlName="contableFormControl"
                  placeholder="Contable" maxlength="2" />
              </div>
            </div>
          </div>
        </div>
        <!--PARTIDA-->
        <div class="mb-3 col-md-6">
          <div class="mb-3 row">
            <div class="col-md-4">
              <label for="partidaFormControl" class="col-form-label">*Partida: </label>
            </div>
            <div class="col-md-7">
              <div class="input-group mb-2">
                <input [readonly]="ocultarInputGeneral()"  id="partidaFormControl" type="text" class="form-control" formControlName="partidaFormControl"
                  placeholder="Partida" maxlength="20"/>
              </div>
            </div>
          </div>
        </div>
        <!--PRODUCTOSAP-->
        <div class="mb-3 col-md-6">
          <div class="mb-3 row">
            <div class="col-md-5">
              <label for="productoSAPFormControl" class="col-form-label">*Producto SAP: </label>
            </div>
            <div class="col-md-7">
              <div class="input-group mb-2">
                <input  [readonly]="ocultarInputGeneral()" id="productoSAPFormControl" type="text" class="form-control"
                  formControlName="productoSAPFormControl" placeholder="Producto SAP" maxlength="20" />
              </div>
            </div>
          </div>
        </div>
        <!--UNIDADMEDIDASAP-->
        <div class="mb-3 col-md-6">
          <div class="mb-3 row">
            <div class="col-md-4">
              <label for="unidadSAPFormControl" class="col-form-label">*U. medida SAP: </label>
            </div>
            <div class="col-md-7">
              <div class="input-group mb-2">
                <input  [readonly]="ocultarInputGeneral()" id="unidadSAPFormControl" type="text" class="form-control" formControlName="unidadSAPFormControl"
                  placeholder="Unidad medida SAP" maxlength="20" />
              </div>
            </div>
          </div>
        </div>
        <!--UNIDAD NEGOCIO GERENCIAL-->
        <div class="mb-3 col-md-6">
          <div class="mb-3 row">
            <div class="col-md-5">
              <label for="negocioGerencialFormControl" class="col-form-label">*U. negocio gerencial: </label>
            </div>
            <div class="col-md-7">
              <div class="input-group mb-2">
                <input [readonly]="ocultarInputGeneral()"  id="negocioGerencialFormControl" type="text" class="form-control"
                  formControlName="negocioGerencialFormControl" placeholder="Unidad negocio gerencial" maxlength="2" />
              </div>
            </div>
          </div>
        </div>
        <!--UNIDAD MEDIDA STOCK-->
        <div class="mb-3 col-md-6">
          <div class="mb-3 row">
            <div class="col-md-4">
              <label for="unidadStockFormControl" class="col-form-label">*U. medida stock: </label>
            </div>
            <div class="col-md-7">
              <div class="input-group mb-2">
                <input  [readonly]="ocultarInputGeneral()" id="unidadStockFormControl" type="text" class="form-control"
                  formControlName="unidadStockFormControl" placeholder="Unidad medida Stock" maxlength="2" />
              </div>
            </div>
          </div>
        </div>
        <!--UNIDAD MEDIDA COMERCIAL-->
        <div class="mb-3 col-md-6">
          <div class="mb-3 row">
            <div class="col-md-5">
              <label for="unidadComercialFormControl" class="col-form-label">*U. medida comercial: </label>
            </div>
            <div class="col-md-7">
              <div class="input-group mb-2">
                <input  [readonly]="ocultarInputGeneral()" id="unidadComercialFormControl" type="text" class="form-control"
                  formControlName="unidadComercialFormControl" placeholder="Unidad medida comercial" maxlength="2" />
              </div>
            </div>
          </div>
        </div>
        <!--UNIDAD MEDIDA PRODUCCION-->
        <div class="mb-3 col-md-6">
          <div class="mb-3 row">
            <div class="col-md-4">
              <label for="unidadProduccionFormControl" class="col-form-label">*U. medida producción: </label>
            </div>
            <div class="col-md-7">
              <div class="input-group mb-2">
                <input  [readonly]="ocultarInputGeneral()" id="unidadProduccionFormControl" type="text" class="form-control"
                  formControlName="unidadProduccionFormControl" placeholder="Unidad medida producción" maxlength="2" />
              </div>
            </div>
          </div>
        </div>
        <!--GRUPO ENVASE-->
        <div class="mb-3 col-md-6">
          <div class="mb-3 row">
            <div class="col-md-5">
              <label for="grupoEnvaseFormControl" class="col-form-label">*Grupo envase: </label>
            </div>
            <div class="col-md-7">
              <div class="input-group mb-2">
                <input  [readonly]="ocultarInputGeneral()" id="grupoEnvaseFormControl" type="text" class="form-control"
                  formControlName="grupoEnvaseFormControl" placeholder="Grupo envase" maxlength="2" />
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="row">
        <!-- CONTROL CALIDAD -->
        <div class="mb-3 col-3 ms-3">
          <input  [readonly]="ocultarInputGeneral()" type="checkbox" class="form-check-input" id="controlCalidadFormControl"
            formControlName="controlCalidadFormControl" />
          <label class="form-check-label ms-2" for="controlCalidadFormControl">Control calidad</label>
        </div>
        <!-- CONTROL STOCK -->
        <div class="mb-3 col-3">
          <input [readonly]="ocultarInputGeneral()"  type="checkbox" class="form-check-input" id="controlStockFormControl"
            formControlName="controlStockFormControl" />
          <label class="form-check-label ms-2" for="controlStockFormControl">Control Stock</label>
        </div>
        <!-- PRODUCTO APROBADO -->
        <div class="mb-3 col-3">
          <input type="checkbox" class="form-check-input" id="productoAprobadoFormControl"
            formControlName="productoAprobadoFormControl" [readonly]="deshabilitarProducto() || !authService.hasRole(TipoUsuario.PRODUCTOS)" />
          <label class="form-check-label ms-2" for="productoAprobadoFormControl">Producto aprobado</label>
        </div>
      </div>

      <!--CHECK DE IMPUESTOS-->
      <div class="row">
        <!-- APLICA IDC -->
        <div class="mb-3 col-3 ms-3">
          <input type="checkbox" class="form-check-input" id="aplicatIDCFormControl"
            formControlName="aplicatIDCFormControl" [readonly]="!authService.hasRole(TipoUsuario.IMPUESTOS)"/>
          <label class="form-check-label ms-2" for="aplicatIDCFormControl">Aplica IDC</label>
        </div>
        <!-- APLICA ITC -->
        <div class="mb-3 col-3">
          <input type="checkbox" class="form-check-input" id="aplicaITCFormControl"
            formControlName="aplicaITCFormControl" [readonly]="!authService.hasRole(TipoUsuario.IMPUESTOS)"/>
          <label class="form-check-label ms-2" for="aplicaITCFormControl">Aplica ITC</label>
        </div>
        <!-- IMPUESTO APROBADO -->
        <div class="mb-3 col-3">
          <input type="checkbox" class="form-check-input" id="impuestoAprobadoFormControl"
            formControlName="impuestoAprobadoFormControl" [readonly]="deshabilitarImpuesto() || !authService.hasRole(TipoUsuario.IMPUESTOS)"/>
          <label class="form-check-label ms-2" for="impuestoAprobadoFormControl">Impuesto aprobado</label>
        </div>
      </div>

      <!-- Activo . Se oculta porque es una funcionalidad problematica, que realmente no se usa.-->
      <div *ngIf="false" class="mb-3 col-md-6">
        <input type="checkbox" class="form-check-input" id="exampleCheck1" formControlName="activoFormControl"
          [checked]="this.productoSeleccionado.activo" />
        <label class="form-check-label" for="exampleCheck1">Activo</label>
      </div>
    </div>

    <div class="card-footer">
      <span class="border">
        <button *ngIf="authService.hasRole(TipoUsuario.RESPRODUCTO, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.IMPUESTOS, TipoUsuario.PRODUCTOS)"  class="btn btn-primary" type="button" (click)="modificarProducto()" [readonly]="modificandoProducto">
          <span *ngIf="!modificandoProducto">Modificar</span>
          <div *ngIf="modificandoProducto" class="d-flex align-items-center">
            <div class="spinner-border text-light spinner-border-sm" role="status"></div>
            <span>Modificando...</span>
          </div>
        </button>
      </span>
    </div>
  </form>
</div>
