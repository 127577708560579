import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UsuarioService } from 'src/app/account/usuario.service';
import { AjusteManualAeroespecialidad } from 'src/app/shared/models/stock/AjusteManualAeroespecialidad';
import { Motivo } from 'src/app/shared/models/stock/Motivo';
import { StockAeroespecialidad } from 'src/app/shared/models/stock/StockAeroespecialidad';
import { MessageService } from 'src/app/shell/message.service';
import { TransaccionesService } from '../../transacciones.service';
import { Responsable } from 'src/app/shared/models/stock/Responsable';

@Component({
  selector: 'app-ajuste-manual-aeroespecialidades',
  templateUrl: './ajuste-manual-aeroespecialidades.component.html',
  styleUrls: ['./ajuste-manual-aeroespecialidades.component.css']
})
export class AjusteManualAeroespecialidadesComponent implements OnInit {

  decimalRegex = /^[0-9]+(.[0-9]{0,4})?$/;
  negativeDecimalRegex = /^[+-]?[0-9]+(.[0-9]{0,4})?$/;
  positiveInteger = /^\+?(0|[1-9]\d*)$/;

  aeroplantaCod: string;
  guardandoMovimiento: boolean = false;
  error: string;

  obteniendoAeroespecialidades: boolean = false;
  aeroespecialidades: StockAeroespecialidad[] = [];

  obteniendoMotivos: boolean;
  motivos: Motivo[] = [];

  ajusteForm = new UntypedFormGroup({
    aeroespecialidadFormControl: new UntypedFormControl("", Validators.required),
    nuevaCantidadFormControl: new UntypedFormControl("", Validators.required),
    motivoFormControl: new UntypedFormControl("", Validators.required),
    comentarioFormControl: new UntypedFormControl()
  });

  constructor(public activeModal: NgbActiveModal,
    private usuarioService: UsuarioService,
    private transaccionesService: TransaccionesService,
    private messageService: MessageService) { }


  // hooks

  ngOnInit() {
    this.aeroplantaCod = this.usuarioService.getAeroplantaActual().codigoAeroplanta;
    this.getAeroespecialidades();
    this.getMotivos();
  }

  // llamadas al service

  getAeroespecialidades() {
    this.obteniendoAeroespecialidades = true;
    this.transaccionesService.getAeroespecialidades(this.aeroplantaCod)
      .subscribe(result => {
        this.aeroespecialidades = result.sort((a, b) => a.aeroespecialidad.nombreProducto > b.aeroespecialidad.nombreProducto ? 1 : -1);
        this.aeroespecialidadFormControl.setValue(this.aeroespecialidades[0]);
        this.obteniendoAeroespecialidades = false;
      }, () => {
        this.obteniendoAeroespecialidades = false;
        this.error = "Error al obtener aeroespecialidades.";
      });
  }

  getMotivos() {
    this.obteniendoMotivos = true;
    this.transaccionesService.getMotivos()
      .subscribe(result => {
        this.motivos = result;
        this.motivoFormControl.setValue(this.motivos[0]);
        this.obteniendoMotivos = false;
      }, () => {
        this.obteniendoMotivos = false;
        this.error = "Error al obtener motivos.";
      });
  }

  guardarAjusteManual() {
    if (!this.validarFormulario) {
      return;
    }

    this.guardandoMovimiento = true;
    let ajuste = new AjusteManualAeroespecialidad();
    ajuste.ResponsableDTO = this.armarResponsable();
    ajuste.cantidadNueva = Number(this.nuevaCantidadFormControl.value);
    ajuste.codigoProducto = this.aeroespecialidadFormControl.value.aeroespecialidad.codigoProducto;
    ajuste.observacion = this.comentarioFormControl.value;
    ajuste.motivoId = this.motivoFormControl.value.id;

    this.transaccionesService.realizarAjusteManualAeroespecialidad(this.aeroplantaCod, ajuste)
      .subscribe(result => {
        this.messageService.showSuccessMessage('Se generó de manera correcta el ajuste manual de aeroespecialidad');
        this.guardandoMovimiento = false;
        this.activeModal.close(true);
      }, () => {
        this.messageService.showErrorMessage('Hubo un error al crear el ajuste manual, intentelo en un momento.')
        this.guardandoMovimiento = false;
      });
  }

  // form


  armarResponsable() {
    var r = new Responsable();
    r.UserName = this.usuarioService.getUsername();
    r.NombreCompleto = this.usuarioService.getNombresCompletosUsuario();
    r.DNI = this.usuarioService.getDniUsuario();

    return r;
  }

  // validaciones

  validarFormulario() {
    if (!this.positiveInteger.test(this.nuevaCantidadFormControl.value)) {
      this.error = "El campo cantidad debe ser un valor positivo valido.";
      return false;
    }

    return true;
  }

  onlyDecimalPositivo(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46;
  }

  // getters

  get aeroespecialidadFormControl() {
    return this.ajusteForm.get('aeroespecialidadFormControl');
  }

  get nuevaCantidadFormControl() {
    return this.ajusteForm.get('nuevaCantidadFormControl');
  }

  get comentarioFormControl() {
    return this.ajusteForm.get('comentarioFormControl');
  }

  get motivoFormControl() {
    return this.ajusteForm.get('motivoFormControl');
  }
}
