<div class="card-body" *ngIf="this.cierreSeleccionado != null && !cargando && this.reporteSeleccionado == 2">
  <div class="col-12 mt-2">
    <div class="border border-solid">
      <div style="display: inline" class="row">
        <label class="col-5 ms-1"><b>Aerovales</b></label>
        <div style="display: inline"class="mb-3 row noImprimir input-group" [formGroup]="ordenamientoForm">
          <div style="display: inline">
            <input type="radio" class="form-check-input me-1" id="checkCliente" [value]="1" (click)="ordenar(1)"
              formControlName="clienteAerovaleFormControl" />
            <label class="form-check-label me-1" for="checkCliente">Cliente</label> 
            
            <input type="radio" class="me-1 form-check-input" id="checkAerovle" [value]="0" (click)="ordenar(0)"
              formControlName="clienteAerovaleFormControl" />
            <label class="me-1 form-check-label" for="checkAerovle">Aerovale</label>
          </div>
        </div>
      </div><br>
      
      <div class="row ms-2 mb-2">
        <div style="width: auto;" ngbDropdown class="noImprimir" autoClose="outside">
          <button class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2" id="dropdownForm1"
            ngbDropdownToggle>Seleccionar Cliente</button>
          <div style="display: inline" ngbDropdownMenu aria-labelledby="dropdownForm1" style="z-index: 50">
            <div style="display: inline" class="px-4 py-3" style="z-index: 50">
              <div class="form-check mb-2">
                <!--checked -->
                <input type="checkbox" checked class="form-check-input" id="TodosCli"
                  (change)="seleccionarTodosClientes($event)" (change)="filtrar()">
                <label class="form-check-label text-nowrap" for="TodosCli">
                  Todos los clientes
                </label>
              </div>
              <div *ngFor="let c of clientes">
                <div class="form-check">
                  <input type="checkbox" class="form-check-input" id={{c.cierre.ClienteNombre}}
                    [(ngModel)]="c.seleccionado" (change)="filtrar()">
                  <label class="form-check-label text-nowrap" for={{c.cierre.ClienteNombre}}>
                    {{ c.cierre.ClienteNombre }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="width: auto;" ngbDropdown class="noImprimir" autoClose="outside">
          <button class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2" id="dropdownForm1"
            ngbDropdownToggle>Seleccionar Contenedor</button>
          <div ngbDropdownMenu aria-labelledby="dropdownForm1" style="z-index: 50">
            <div class="px-4 py-3" style="z-index: 50">
              <div class="form-check mb-2">                
                <!--checked -->
                <input type="checkbox" checked class="form-check-input" id="TodosCon" 
                  (change)="seleccionarTodosContenedores($event)" (change)="filtrar()">
                <label class="form-check-label text-nowrap" for="TodosCon">
                  Todos los contenedores
                </label>
              </div>
              <div *ngFor="let con of contenedores">
                <div class="form-check">
                  <input type="checkbox" class="form-check-input" id={{con.name}}
                    [(ngModel)]="con.seleccionado" (change)="filtrar()">
                  <label class="form-check-label text-nowrap" for={{con.name}}>
                    {{ con.name }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div *ngIf="this.reportesFiltrados.length>0">
        <!--AEROVALES-->
        <table class="table table-striped table-hover letra">
          <thead class="text-center">
            <tr>
              <th>Aerovale</th>
              <th>Cliente</th>
              <th>Cuenta</th>
              <th>Titulo</th>
              <th>Contenedor</th>
              <th>Producto</th>
              <th>Cantidad</th>
              <th>Cantidad 15°</th>
              <th>Caudal Observado</th>
              <th>Presion Diferencial</th>
              <th>Presion Punta Manguera</th>
              <th class="noImprimir">Ver</th>
            </tr>
          </thead>
          <tbody class="text-center">
            <tr *ngFor="let mov of reportesFiltrados; let i = index"
              [ngClass]="{'resaltado': this.revisarAnulados(mov)}">
                <td>{{mov.comprobante.remito.aerovalePrefijo}}-{{mov.comprobante.remito.aerovaleNumero}}</td>
                <td>{{mov.comprobante.remito.clienteCierre.ClienteNombre}}-{{mov.comprobante.remito.clienteCierre.ClienteCUIT}}</td>
                <td>{{mov.comprobante.remito.clienteCierre.Cuenta_SGC? mov.comprobante.remito.clienteCierre.Cuenta_SGC : "--"}}</td>
                <td>{{mov.titulo? mov.titulo : "--"}}</td>
                <td>{{mov.codigoContenedor}}-{{mov.nombreContenedor}}</td>
                <td>{{mov.codigoProducto}}-{{mov.nombreProducto}}</td>
                <td>{{mov.salidaNatural? mov.salidaNatural : mov.entradaNatural? mov.entradaNatural : 0}}</td>
                <td>{{mov.salida15? mov.salida15 : mov.entrada15? mov.entrada15 : 0}}</td>
                <td>{{mov.comprobante.remito.caudalObservado ? mov.comprobante.remito.caudalObservado : 0}}</td>
                <td>{{mov.comprobante.remito.presionDiferencial? mov.comprobante.remito.presionDiferencial : 0}}</td>
                <td>{{mov.comprobante.remito.presionPuntaManguera? mov.comprobante.remito.presionPuntaManguera : 0}}</td>
                <td class="noImprimir">
                  <button *ngIf="mov.comprobante.remito.aerovaleId != null" class="btn" (click)="mostrarAerovale(mov.comprobante.remito.aerovaleId, i)" ngbTooltip="Ver aerovale">
                    <i *ngIf="!obteniendoAerovale || obteniendoAerovale && aerovaleSeleccionado!=i" class="fa fa-eye" aria-hidden="true"></i>
                    <div *ngIf="aerovaleSeleccionado==i && obteniendoAerovale">
                        <div class="spinner-border text-primary spinner-border-sm" role="status"></div>
                        <span><i class="fa fa-eye" aria-hidden="true"></i></span>
                    </div>
                  </button> 
                </td>  
            </tr>
          </tbody>
        </table>
      </div>

      <!-- <div class="row">
        <div class="col-md-12">
          <button style="float:right;" *ngIf="cierreSeleccionado.id != null" (click)="exportar()" class="btn btn-primary">
            <i class="fa fa-file-excel-o" aria-hidden="true"></i> Exportar 
          </button>
        </div>
      </div> -->
    </div>
    <div class="card-footer">
      <button class="btn btn-primary me-2" type="button" (click)="exportarExcel()" [disabled]="cargando || exportando || (this.reporteMovimientosAnulados.length == 0 && this.reporteMovimientosFiltrado.length == 0)"><i class="fa fa-file-excel-o" aria-hidden="true"></i> Descargar resumen </button>
        <button type="button" (click)="export()" class="btn btn-primary me-2" [disabled]="cargando || exportando || (this.reporteMovimientosAnulados.length == 0 && this.reporteMovimientosFiltrado.length == 0)"><i class="fa fa-file-excel-o" aria-hidden="true"></i> Descargar detalle de aerovales </button>
    </div>
  </div>
</div>