import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EtapasCierreTurno } from 'src/app/shared/enums/EtapasCierreTurno';
import { TipoComparativa } from 'src/app/shared/enums/TipoComparativa';
import { ModalConfirmComponent } from 'src/app/shared/generic-modals/modal-confirm.component';
import { CierreTurno } from 'src/app/shared/models/cierreTurno/CierreTurno';
import { Comparativa } from 'src/app/shared/models/cierreTurno/Comparativa';
import { ComparativaExpendedora } from 'src/app/shared/models/cierreTurno/ComparativaExpendedora';
import { Comparativas } from 'src/app/shared/models/cierreTurno/Comparativas';
import { TotalProducto } from 'src/app/shared/models/cierreTurno/TotalProducto';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { MessageService } from 'src/app/shell/message.service';
import { CierreTurnoService } from '../../cierre-turno.service';

@Component({
  selector: 'app-comparativas-cierre-turno',
  templateUrl: './comparativas-cierre-turno.component.html',
  styleUrls: ['./comparativas-cierre-turno.component.scss']
})
export class ComparativasCierreTurnoComponent implements OnInit {

  @Input() cuadroComparativas: Comparativas;
  @Input() cierreTurnoContinuo: CierreTurno;

  comparativasTanques15: Comparativa[] = [];
  comparativaTanqueNatural: Comparativa[] = [];
  comparativaAeroespecialidades: Comparativa[] = [];
  comparativaAbastecedora: ComparativaExpendedora[] = [];
  totalProductos15: TotalProducto[] = [];
  totalProductosNatural: TotalProducto[] = [];
  distintosProducto: string[] = [];
  pasarEtapa: boolean = false;

  natural: boolean[]=[];
  grado15: boolean[]=[];

  guardando: boolean = false;
  constructor(private modal: NgbModal, private messageService: MessageService,
    private cierresService: CierreTurnoService) { }

  ngOnInit() {
    this.pasarEtapa = false;
    this.comparativasTanques15 = this.cuadroComparativas.comparativas.filter(a => a.tipoComparativa == 0);
    this.comparativasTanques15.sort((a , b) => a.nombreContenedor.toLocaleUpperCase() > b.nombreContenedor.toLocaleUpperCase() ? 1 : -1);
    this.comparativaTanqueNatural = this.cuadroComparativas.comparativas.filter(a => a.tipoComparativa == TipoComparativa.TanquesNaturales);
    this.comparativaTanqueNatural.sort((a , b) => a.nombreContenedor.toLocaleUpperCase() > b.nombreContenedor.toLocaleUpperCase() ? 1 : -1);
    this.comparativaAeroespecialidades = this.cuadroComparativas.comparativas.filter(a => a.tipoComparativa == TipoComparativa.Aeroespecialidades);
    this.comparativaAeroespecialidades.sort((a , b) => a.codigoProducto.toLocaleUpperCase() > b.codigoProducto.toLocaleUpperCase() ? 1 : -1);
    this.comparativaAbastecedora = this.cuadroComparativas.comparativasExpendedoras;
    this.comparativaAbastecedora.sort((a , b) => a.nombreExpendedora.toLocaleUpperCase() > b.nombreExpendedora.toLocaleUpperCase() ? 1 : -1);
    this.totalProductos15 = this.cuadroComparativas.totalesProductos.filter( p => p .tipoComparativa == TipoComparativa.Tanque15);
    this.totalProductos15.sort((a , b) => a.nombreProducto.toLocaleUpperCase() > b.nombreProducto.toLocaleUpperCase() ? 1 : -1);
    this.totalProductosNatural = this.cuadroComparativas.totalesProductos.filter( p => p .tipoComparativa == TipoComparativa.TanquesNaturales);
    this.totalProductosNatural.sort((a , b) => a.nombreProducto.toLocaleUpperCase() > b.nombreProducto.toLocaleUpperCase() ? 1 : -1);
    for (let com of this.cuadroComparativas.comparativas) {
      if (com.tipoComparativa != TipoComparativa.Aeroespecialidades) {
        if (!this.distintosProducto.find(a => a == com.codigoProducto + ' - ' + com.nombreProducto)) {
          this.distintosProducto.push(com.codigoProducto + ' - ' + com.nombreProducto);
          this.natural.push(true);
          this.grado15.push(true);
        }
      }
    }
  }

  sumar(dist: string, otro: string): number {
    let cant: number = 0;
    for (let com of this.comparativaTanqueNatural) {
      if (dist == com.codigoProducto + ' - ' + com.nombreProducto) {
        cant += com[otro];
      }
    }
    return cant;
  }

  sumar2(dist: string, otro: string): number {
    let cant: number = 0;
    for (let com of this.comparativasTanques15) {
      if (dist == com.codigoProducto + ' - ' + com.nombreProducto) {
        cant += com[otro];
      }
    }
    return cant;
  }

  continuarCierreTurno() {
    this.guardando = true;
    let stringDiferencias: string = '';
    let comDiferencia: Comparativa[] = [];
    for (let t15 of this.comparativasTanques15) {
      if (t15.diferencia != 0) {
        comDiferencia.push(new Comparativa());
        comDiferencia.push(t15);
        stringDiferencias += t15.codigoContenedor + '. ';
      }
    }
    for (let tn of this.comparativaTanqueNatural) {
      if (tn.diferencia != 0 && !comDiferencia.find(a => a.codigoContenedor == tn.codigoContenedor)) {
        stringDiferencias += tn.codigoContenedor +  '. ';
      }
    }

    for (let aep of this.comparativaAeroespecialidades) {
      if (aep.diferencia != 0 ) {
        stringDiferencias += aep.codigoProducto + '-' + aep.nombreProducto + '. ';
      }
    }

    if (stringDiferencias != '') {
      let modalRef = this.modal.open(ModalConfirmComponent, { centered: true, backdrop: 'static' });
      modalRef.componentInstance.mensaje = 'A los siguientes contenedores y/o aeroespecialidades se le van a realizar ajustes, ' + stringDiferencias + '¿desea continuar?';
      modalRef.componentInstance.titulo = 'Contenedores con diferencias.';
      modalRef.result.then(() => {
          this.pasarEtapa = true;
          this.guardando = false;
          this.completeStep(Object.keys(EtapasCierreTurno).indexOf('Etp3'));
      }, (err: ErrorModel) => {
        this.messageService.showErrorMessage('Se canceló la operación del turno.');
        this.guardando = false;
      })
    } else {
      this.pasarEtapa = true;
      this.completeStep(Object.keys(EtapasCierreTurno).indexOf('Etp3'));
    }
  }

  completeStep(index: number): void {
    this.cierresService.updateStepProgress(index, true);
  }
}
