<iframe id="grafana" [src]="this.linkGrafana | safe" frameborder="0"></iframe>


<!--div id="contenedorPrint">

  <div *ngIf="!esperandoCarga" class="col-md-12 no-print" style="margin-bottom: 0 !important;">
    <input id="busquedaFormControl" type="text" class="form-control input-sm" style="width: 30em !important;"
        [formControl]="busquedaFormControl" (input)="filtrarPrecios()" placeholder="Cuit/Razon social/Nro. factura" />
  </div>

  <div class="print" style="margin-bottom: 2em;">
    Listado de precios utilizados en facturacion entre  {{fechaDesde | date: "d'/'MM'/'y'"}} y {{fechaHasta | date: "d'/'MM'/'y'"}}.
  </div>

  <ngb-alert *ngIf="!esperandoCarga && this.listadoPreciosFiltrados.length == 0">No hay precios para busqueda seleccionada.
  </ngb-alert>

  <div *ngIf="!esperandoCarga && this.listadoPreciosFiltrados != null && this.listadoPreciosFiltrados.length > 0" >
    <div *ngFor="let f of listadoPreciosFiltrados; let i = index">
      <div class="row data-row">
        <div class="col head-cel primaria">
          <span class="rotulo">
            Nro. factura:
            <button class="btn btn-primary mb-2" type="button" (click)="mostrarFactura(f.id)">
              <span><i class="fa fa-eye" aria-hidden="true"></i></span>            
            </button>
          </span> 
          <br /> 
          <span class="data">{{f.prefijo}}-{{f.numeroComprobante}}</span>
        </div>
        <div class="col head-cel primaria"><span class="rotulo">Razon social: </span> <br /> <span class="data">{{f.razonSocial}}</span>
        </div>
        <div class="col head-cel primaria"><span class="rotulo">CUIT: </span> <br /> <span class="data">{{f.numeroDocumento}}</span></div>
        <div class="col head-cel primaria"><span class="rotulo">Nro. cuenta: </span> <br /> <span class="data">{{f.sGC}}</span>
        </div>
        <div class="col head-cel primaria"><span class="rotulo">Fecha: </span> <br /> <span class="data">{{f.fechaEmision | date: 'dd/MM/yyyy'}}</span></div>
      </div>

      <div *ngIf="f!=null">
      <div class="contenedorRenglon" *ngFor="let r of f.renglones">
        <div class="row data-row">
          <div class="col col-md-1">&nbsp;</div>
          <div class="col col-md-2 head-cel secundaria"><span class="rotulo">Codigo:</span> <span class="data"> {{r.productoCodigo}}</span></div>
          <div class="col head-cel secundaria"><span class="rotulo">Nombre producto: </span><span class="data">{{r.productoNombre}}</span></div>
          <div class="col col-md-2 head-cel secundaria"><span class="rotulo">Cantidad: </span> <span class="data">{{r.cantidad}}</span>
          </div>
          <div class="col col-md-2 head-cel secundaria"><span class="rotulo">Aeroplanta: </span> <span class="data">{{this.aeroplantaService.obtenerNombreAeroplanta(r.aeroplantaCodigo)}}</span>
          </div>
        </div>
        <div class="row data-row">

          <div class="col col-md-1">&nbsp;</div>
          <div class="col">
            <div class="table-responsive">
              <table class="table" >
                <thead>
                  <tr>
                    <th>Nivel</th>
                    <th>Lote</th>
                    <th>Cod.</th>
                    <th>Desde/Hasta</th>
                    <th>Moneda</th>
                    <th>Cliente</th>
                    <th>Tipo venta</th>
                    <th>Precio</th>
                    <th>Entrega</th>
                    <th>Area</th>
                    <th>Segmento</th>
                    <th>Aeroplanta</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ r.precioDTO!=null ? r.precioDTO.nivel : "-"}}</td>
                    <td> {{ r.precioDTO!=null ? r.precioDTO.numeroLote : "-"}} </td>
                    <td>{{r.productoCodigo}}</td>
                    <td>{{r.precioDTO.fechaDesde | date: 'dd/MM/yy'}}-{{r.precioDTO.fechaHasta | date: 'dd/MM/yy'}}</td>
                    <td>{{ utilPrecio.obtenerDescripcionMoneda(r.precioDTO.tipoMoneda) }}</td>
                    <td> {{ r.precioDTO!=null && r.precioDTO.cliente!=null ? r.precioDTO.cliente : "-"}} </td>
                    <td >{{ this.obtenerTipoVenta(r.precioDTO.tipoVenta) }}</td>
                    <td> {{ r.precioDTO!=null && r.precioDTO.precio!=null ? r.precioDTO.precio : "-"}} </td>
                    <td> {{ r.precioDTO!=null && r.precioDTO.direccionEntrega!=null ? r.precioDTO.direccionEntrega : "-"}} </td>
                    <td> {{ r.precioDTO!=null && r.precioDTO.areaNegocio!=null ? r.precioDTO.areaNegocio : "-"}} </td>
                    <td> {{ r.precioDTO!=null && r.precioDTO.segmento!=null ? r.precioDTO.segmento : "-"}} </td>
                    <td> {{ r.precioDTO!=null && r.precioDTO.aeroplanta!=null ? this.aeroplantaService.obtenerNombreAeroplanta(r.precioDTO.aeroplanta) : "-"}} </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div *ngIf="r.bonificacionRecargoDTO != null">
          <div class="row data-row">
            <div class="col col-md-1">&nbsp;</div>
            <div class="col"><span class="rotulo">Bonificación / Recargo</span></div>
          </div>
          <div class="row">
            <div class="col col-md-1">&nbsp;</div>
            <div class="col">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Cliente</th>
                      <th>Producto</th>
                      <th>Aeroplanta</th>
                      <th>Valor porcentual</th>
                      <th>Valor aplicado</th>
                      <th>Desde/Hasta</th>
                      <th>Tipo</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><span *ngIf="r.bonificacionRecargoDTO.clientes.length == 0">-</span><span *ngFor="let c of r.bonificacionRecargoDTO.clientes">{{c}} &nbsp;</span> </td>
                      <td><span *ngIf="r.bonificacionRecargoDTO.productos.length == 0">-</span><span *ngFor="let p of r.bonificacionRecargoDTO.productos">{{p}} &nbsp;</span> </td>
                      <td><span *ngIf="r.bonificacionRecargoDTO.aeroplantas.length == 0">-</span><span *ngFor="let a of r.bonificacionRecargoDTO.aeroplantas">{{a}} &nbsp;</span> </td>
                      <td>{{ r.bonificacionRecargoDTO.valorPorcentual }}</td>
                      <td>{{ r.bonificacionRecargoDTO.valorAplicado }}</td>
                      <td>{{r.bonificacionRecargoDTO.fechaDesde | date: "dd'/'MM'/'yy HH':'mm"}} - {{r.bonificacionRecargoDTO.fechaHasta | date: "dd'/'MM'/'yy HH':'mm"}}</td>
                      <td>{{ utilPrecio.obtenerDescripcionBonificacionRecrgo(r.bonificacionRecargoDTO.tipo) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>

  <div class="no-print" style="float: right;">
    <button type="button" [disabled]="esperandoCarga" styleSheetFile="assets/css/print.css,assets/css/bootstrap.min.css"
      printTitle="" printSectionId="contenedorPrint" [useExistingCss]="true"
      class="btn btn-outline-secondary" ngxPrint>
      <i class="fa fa-print" aria-hidden="true"></i> Imprimir
    </button>
  </div>
</div-->
