import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Surtidor } from 'src/app/shared/models/stock/Surtidor';
import { TanqueGeneral } from 'src/app/shared/models/stock/TanqueGeneral';
import { isNullOrUndefined } from 'src/app/shared/compat-tools';
import { MessageService } from 'src/app/shell/message.service';
import { Aeroplanta } from 'src/app/shared/models/despacho/Aeroplanta';
import { UsuarioService } from 'src/app/account/usuario.service';
import { TanqueService } from '../../../tanques.service';
import { SurtidorService } from '../../../surtidor.service';
import { GrupoDeTanquesService } from '../../../grupos.service';
import { ProductoService } from 'src/app/abm/producto/producto.service';
import { Producto } from 'src/app/shared/models/abm/Producto';
import { Producto as ProductoStock } from 'src/app/shared/models/stock/Producto';
import { Pico } from 'src/app/shared/models/despacho/Pico';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';



@Component({
  selector: 'app-nuevo-surtidor',
  templateUrl: './nuevo-surtidor.component.html',
  styleUrls: ['./nuevo-surtidor.component.css']
})

export class NuevoSurtidorComponent implements OnInit {

  @Input() surtidor: Surtidor;
  @Input() tanquesGenerales: TanqueGeneral[];
  @Input() surtidores: Surtidor[];
  @Input() tipoSurtidor: number;
  @Input() vaciarTanques: boolean;
  @Input() obtenerNumero: number;

  codigoAutogenerado: string = "";
  productos: Producto[] = [];
  cargandoProductos: boolean;
  errorProductos: boolean;
  tanqueSeleccionado: TanqueGeneral;
  tanquesDelSurtidor: TanqueGeneral[] = [];
  tanquesDelSurtidorFiltrado: TanqueGeneral[] = [];

  guardando: boolean;
  editando: boolean;

  aeroplanta: Aeroplanta;

  picos: Pico[] = [];

  form: UntypedFormGroup;
  picoForm: UntypedFormGroup;
  obteniendoTanques: boolean = true;
  productoSeleccionado: boolean = false;
  productoAux: ProductoStock;

  mensajeError: boolean = false;

  constructor(
    private messageService: MessageService,
    private formBuilder: UntypedFormBuilder,
    public activeModal: NgbActiveModal,
    private usuarioService: UsuarioService,
    private tanquesService: TanqueService,
    private gruposService: GrupoDeTanquesService,
    private surtidorService: SurtidorService,
    private productosService: ProductoService,
    private changeDetector: ChangeDetectorRef) {

  }

  ngOnInit() {

    this.productoAux = new ProductoStock();
    this.mensajeError = false;
    this.aeroplanta = this.usuarioService.getAeroplantaActual();
    this.crearFormulario();
    this.inicializarComponente();
    this.getGruposyTanques();
    this.cargarProductos();
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  // form

  crearFormulario() {
    this.form = this.formBuilder.group({
      codigoFormControl: ['', Validators.required],
      nombreFormControl: ['', Validators.required],
      productoFormControl: ['', Validators.required],
      activoFormControl: ['', Validators.required],
      tanqueGeneralFormControl: ['']
    });

    this.picoForm = this.formBuilder.group({
      nombrePicoFormControl: ['', Validators.required],
      aforadorPicoFormControl: ['', Validators.required],
      tipoPicoFormControl: ['', Validators.required],
    });
  }

  inicializarComponente() {
    if (this.surtidor == null) {
      this.editando = false;
      this.obtenerProximoCodigoSurtidor();
      this.cargarDatosDefecto();
    } else {
      this.editando = true;
      this.cargarDatosSurtidor();
    }

    this.guardando = false;

    if (this.surtidor != null) {
      this.surtidor.tanques.forEach(t => {
        this.tanquesDelSurtidor.push({ ...t });
      });
    }
  }

  cargarDatosDefecto() {
    this.productoFormControl.setValue(this.productos[0]);
    this.productoAux = this.productos[0];
    this.activoFormControl.setValue(true);
  }

  // llamadas al service
  cargarProductos(): void {
    this.cargandoProductos = true;
    this.productosService.obtenerProductos()
      .subscribe(productos => {
        this.productos = productos;
        // se filtran por aerocombustibles y por combustibles
        this.productos = this.productos.filter(p => p.rubro.aerocombustible || p.rubro.combustible);
        this.productos = this.productos.filter(a => a.activo)
        if (!this.editando) {
          this.productoFormControl.setValue(this.productos[0]);
          this.productoAux = this.productos[0];
        }
        this.filtrarTanque();
        this.productoSeleccionado = true;
        this.cargandoProductos = false;
      }, () => this.errorProductos = true);
  }

  cargarDatosSurtidor() {
    this.codigoFormControl.setValue(this.surtidor.codigo);
    this.codigoFormControl.disable();
    this.nombreFormControl.setValue(this.surtidor.nombre);
    this.activoFormControl.setValue(this.surtidor.activo);
    this.productoFormControl.setValue(this.surtidor.productoActual);
    this.tanqueGeneralFormControl.setValue(this.surtidor.tanques);
    this.picos = this.surtidor.picos;
    this.productoAux = this.surtidor.productoActual;
    this.filtrarTanque();
  }

  // llamadas al service


  getTanques() {
    this.tanquesService.getTanquesLibresPorAeroplanta(this.aeroplanta.codigoAeroplanta)
      .subscribe(result => {
        this.tanquesGenerales = result;
        this.tanquesDelSurtidorFiltrado = result;
        this.tanqueSeleccionado = result[0];
        this.obteniendoTanques = false;
      })
  }

  eliminarTanquesAsignados() {
    if (this.editando) {
      if (this.surtidor.tanques.length > 0) {
        this.surtidor.tanques.forEach((value, index) => {
          this.tanquesGenerales.forEach((value2, index2) => {
            if (this.surtidor.tanques[index].codigo == this.tanquesGenerales[index2].codigo) {
              this.tanquesGenerales.splice(index2, 1);
            }
          });
        });
      }
    }
  }

  getGruposyTanques() {
    this.gruposService.getGrupoDeTanquesyTanquesIndividualesPorAeroplanta(this.usuarioService.getAeroplantaActual().codigoAeroplanta)
      .subscribe(result => {
        this.tanquesGenerales = result;
        this.tanquesDelSurtidorFiltrado = result;
        this.tanqueSeleccionado = result[0];
        this.obteniendoTanques = false;
      },
        () => {
          this.messageService.showErrorMessage("Error al obtener grupos")
        })
  }

  asignarPico() {
    if (!this.picoForm.valid) {
      this.messageService.showErrorMessage('Por favor revise lo datos necesarios para el pico.')
    } else {
      let p = new Pico();
      p.nombre = this.nombrePicoFormControl.value;
      p.aforadorActual = parseInt(this.aforadorPicoFormControl.value);
      p.tipoPico = parseInt(this.tipoPicoFormControl.value);
      p.activo = true;
      p.porDefecto = false;
      this.picos.push(p);
      if (this.picos.length > 0 && this.picos.length < 2 && this.picos != null) {
        this.picos[0].porDefecto = true;
      }
      this.nombrePicoFormControl.setValue('');
      this.aforadorPicoFormControl.setValue(0);
    }
  }

  desasignarPico(p: Pico) {
    const index: number = this.picos.indexOf(p);
    if (index !== -1) {
      this.picos.splice(index, 1);
    }
  }

  filtrarTanque() {
    let tanquesAux = this.tanquesDelSurtidorFiltrado;
    this.tanquesGenerales = tanquesAux.filter(a => a.productoActual.codigoProducto == this.productoFormControl.value.codigoProducto);
    if (this.tanquesGenerales.length > 0) {
      this.tanqueSeleccionado = this.tanquesGenerales[0];
    }

    if (this.editando) {
      this.eliminarTanquesAsignados();
    }

    if (this.productoAux.codigoProducto != this.productoFormControl.value.codigoProducto) {
      this.tanquesDelSurtidor = [];
      this.surtidor.tanques = [];
      this.productoAux = this.productoFormControl.value;
    }
    this.productoSeleccionado = true;
  }

  onSubmit() {

    this.mensajeError = false;
    this.guardando = true;

    if (!this.editando) {
      this.surtidor = new Surtidor();
      this.surtidor.productoActual = new ProductoStock();
      this.surtidor.picos = [];
    }

    this.surtidor.productoActual = this.productoFormControl.value//this.surtidorService.convertirProducto(this.productoFormControl.value);
    this.surtidor.picos = this.picos;
    this.surtidor.nombre = this.nombreFormControl.value;
    this.surtidor.codigo = this.codigoFormControl.value;
    this.surtidor.activo = this.activoFormControl.value;
    this.surtidor.tanques = this.tanquesDelSurtidor;
    this.surtidor.codigoAeroplanta = this.aeroplanta.codigoAeroplanta;
    this.surtidor.tipoSurtidor = this.tipoSurtidor; //

    if (this.tanquesDelSurtidor != null && this.tanquesDelSurtidor.length > 0) {
      if (!this.editando) {
        this.surtidorService.crearSurtidor(this.surtidor)
          .subscribe(surtidor => {
            this.activeModal.close(surtidor);
            // this.messageService.showSuccessMessage("Se ha agregado el surtidor correctamente");
          },
          (err: ErrorModel) => {
            this.messageService.showErrorMessage(err.description);
            this.activeModal.close(false);
          }),
          () => {
            this.mensajeError = true;
            this.guardando = false;
          }

      } else {
        this.surtidorService.editarSurtidor(this.surtidor)
          .subscribe(surtidor => {
            this.activeModal.close(surtidor);
            // this.messageService.showSuccessMessage("Se ha modificado el surtidor correctamente");
          },
          (err: ErrorModel) => {
            this.messageService.showErrorMessage(err.description);
            this.activeModal.close(false);
          }),
          () => {
            this.mensajeError = true;
            this.guardando = false;
          };
      }
    }
    else {
      this.guardando = false;
      this.mensajeError = true;
    }
  }

  obtenerProximoCodigoSurtidor() {
    this.surtidorService.obtenerProximoCodigoDeSurtidor(this.aeroplanta.codigoAeroplanta, this.obtenerNumero)
      .subscribe(result => {
        this.codigoAutogenerado = result;
        this.codigoFormControl.setValue(this.codigoAutogenerado);
      });
  }


  // validaciones

  camposValidados() {
    return (this.form.controls.codigoFormControl.value != '' && this.form.controls.nombreFormControl.value != '');
  }


  formValidado() {
    let es: boolean = (this.comboVacio()) || (this.form.valid);
    return es;
  }

  comboVacio() {
    // valido si el combo está vacio pero asignaste tanques
    let comboTanquesVacio: boolean = (this.filtrarTanquesAsignados().length >= 0 && this.tanquesDelSurtidor.length > 0);
    return comboTanquesVacio;
  }

  checkearDefecto(p: Pico) {
    if (p.porDefecto == true) {
      p.porDefecto = false;
    } else {
      p.porDefecto = true;
    }
    this.hayPorDefecto(p);
  }

  hayPorDefecto(p: Pico) {
    this.picos.forEach((value, index) => {
      if (this.picos[index] != p) {
        this.picos[index].porDefecto = false;
      }

    })
  }

  compareProductos(p1: any, p2: any): boolean {
    return p1 && p2 ? p1.codigoProducto === p2.codigoProducto : p1 === p2;
  }

  onlyDecimalPositivo(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46;
  }

filtrarTanquesAsignados(){
    if(this.tanquesDelSurtidor.length > 0){
      let filtered = this.tanquesGenerales.filter(t => isNullOrUndefined( this.tanquesDelSurtidor.find(tAsign => tAsign.id == t.id)) );
      filtered= filtered.filter(t=>t.productoActual.codigoProducto===this.productoFormControl.value.codigoProducto);

    } else {
      return this.tanquesGenerales;
    }
  }

  asignarTanqueSeleccionado() {
    if (this.tanqueSeleccionado == null) {
      this.messageService.showErrorMessage("Debe seleccionar un tanque para asignar.");
    } else {

      if (this.tanquesDelSurtidor.length === 0) // la primera vez que se asigna
      {
        this.tanquesDelSurtidor.push(this.tanqueSeleccionado);
      }

      else if (this.tanquesDelSurtidor[0].productoActual.codigoProducto === this.tanqueSeleccionado.productoActual.codigoProducto) // la segunda vez en adelante que se asigna y se puede
      {
        this.tanquesDelSurtidor.push(this.tanqueSeleccionado);
      }

      else if (this.tanquesDelSurtidor[0].productoActual.codigoProducto != this.tanqueSeleccionado.productoActual.codigoProducto) // la segunda vez en adelante que se asigna y no se puede
      {
        this.messageService.showErrorMessage("No puede asignar tanques de distintos productos");
      }

      let filtered = this.tanquesGenerales.filter(t => isNullOrUndefined(this.tanquesDelSurtidor.find(tAsign => tAsign.id == t.id)));
      const index: number = this.tanquesGenerales.indexOf(this.tanqueSeleccionado);
      if (index !== -1) {
        this.tanquesGenerales.splice(index, 1);
      }

      if (filtered.length > 0) {
        this.tanqueSeleccionado = filtered[0];
      } else {
        this.tanqueSeleccionado = null;
      }
    }
  }

  desasignarTanque(tanque) {
    const index: number = this.tanquesDelSurtidor.indexOf(tanque);
    if (index !== -1) {
      this.tanquesDelSurtidor.splice(index, 1);
    }
    this.tanquesGenerales.push(tanque);
    this.tanqueSeleccionado = this.tanquesGenerales[0];
  }

  chequearCodigo() {
    let existeCodigo: boolean = false;
    if (!this.editando) {
      for (let i = 0; this.surtidores.length > i; i++) {
        if (this.surtidores[i].codigo === this.codigoFormControl.value) {
          existeCodigo = true;
          this.codigoFormControl.setErrors({ 'incorrect': true });
        }
      }
    }
    return existeCodigo;
  }

  // getters

  get codigoFormControl() {
    return this.form.controls.codigoFormControl;
  }

  get nombreFormControl() {
    return this.form.controls.nombreFormControl;
  }

  get activoFormControl() {
    return this.form.controls.activoFormControl;
  }

  get tanqueGeneralFormControl() {
    return this.form.controls.tanqueGeneralFormControl;
  }

  get productoFormControl() {
    return this.form.controls.productoFormControl;
  }

  get nombrePicoFormControl() {
    return this.picoForm.controls.nombrePicoFormControl;
  }

  get aforadorPicoFormControl() {
    return this.picoForm.controls.aforadorPicoFormControl;
  }

  get tipoPicoFormControl() {
    return this.picoForm.controls.tipoPicoFormControl;
  }
}

