<div class="row card-header" id="{{ciudad ? ciudad.ciudadId : provincia.provinciaId}}">
  <div class="col-md-6" style="font-weight: bold; font-size: 1.1em;">
    {{labelCiudad}}
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <table class="table table-striped table-hover">
      <thead>
        <td class="text-center"><b>Código</b></td>
        <td class="text-center"><b>Leyenda</b></td>
        <td class="text-center"><b>Activa</b></td>
        <td class="text-center"></td>
      </thead>
      <tbody>
        <tr *ngFor="let param of parametros">
          <td class="text-center">{{ enumTipoCodigoParametroImpositivo[param.codigo] }}</td>
          <td class="text-center">{{ param.leyenda }}</td>
          <td class="text-center">
            <div class="form-switch">
              <input type="checkbox" class="form-check-input" id="{{param.parametroId}}" [checked]="param.activa"
                (change)="onParametroEstadoActivoChange(param, $event)">
              <label for="{{param.parametroId}}" class="custom-control-label"></label>
            </div>
          </td>
          <td>
            <button type="button" (click)="editarParametroImp(param)" class="btn btn-default btn-sm"><i
                class="fa fa-edit fa-2x" aria-hidden="true"></i></button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="row mb-4">
  <div class="col-md-12">
    <button class="btn btn-primary pull-right" type="button" (click)="agregarParametroImpostivo()">
      Agregar parámetro
    </button>
  </div>
</div>

<ng-container *ngIf="this.ingresosBrutos == 0">
  <div class="row card-header card-title">
    <div class="col-md-6" style="font-weight: bold; font-size: 1.1em;">
      Ingresos Brutos Provinciales.
    </div>
  </div>
  <ngb-alert *ngIf="this.alicuotasIIBB.length == 0">No hay IIBB cargados para la provincia actual.</ngb-alert>
  <table class="table table-striped table-hover text-center letra" *ngIf="this.alicuotasIIBB.length > 0">
    <thead>
      <tr>
        <th [width]="250">Nombre</th>
        <th [width]="130">Inscripto</th>
        <th [width]="130">No Insc</th>
        <th [width]="130">Conv. mult</th>
        <th [width]="130">Total mín</th>
        <th [width]="130">Monto perc mín.</th>
        <th [width]="130">Base imp mín</th>
        <th [width]="150">*Calc base imp</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let ing of alicuotasIIBB; let i = index">
        <td>
          <textarea name="text" class="form-control" rows="1" style="resize: vertical;" cols="40" wrap="soft"
            id="nombreAlicuotaIIBB" [value]="ing.nombreAlicuota"
            (change)="cambiarNombre(i, 'nombreAlicuota', $event)"> </textarea>
        </td>
        <td>
          <input class="form-control" size="3" (keypress)="this.onlyDecimalPositivo($event)" id="valorInscriptoIIBB"
            [value]="ing.valorInscripto" (change)="cambiarValor(i, 'valorInscripto', $event)">
        </td>
        <td>
          <input class="form-control" size="3" (keypress)="this.onlyDecimalPositivo($event)" id="valorNoInscriptoIIBB"
            [value]="ing.valorNoInscripto" (change)="cambiarValor(i, 'valorNoInscripto', $event)">
        </td>
        <td>
          <input class="form-control" size="3" (keypress)="this.onlyDecimalPositivo($event)"
            id="valorConvenioMultilateralIIBB" [value]="ing.valorConvenioMultilateral"
            (change)="cambiarValor(i, 'valorConvenioMultilateral', $event)">
        </td>
        <td>
          <input class="form-control" size="3" (keypress)="this.onlyDecimalPositivo($event)" id="totalMinimoIIBB"
            [value]="ing.totalMinimo" (change)="cambiarValor(i, 'totalMinimo', $event)">
        </td>
        <td>
          <input class="form-control" size="3" (keypress)="this.onlyDecimalPositivo($event)"
            id="montoPercibidoMinimoIIBB" [value]="ing.montoPercibidoMinimo"
            (change)="cambiarValor(i, 'montoPercibidoMinimo', $event)">
        </td>
        <td>
          <input class="form-control" size="3" (keypress)="this.onlyDecimalPositivo($event)"
            id="baseImponibleMinimaIIBB" [value]="ing.baseImponibleMinimo"
            (change)="cambiarValor(i, 'baseImponibleMinimo', $event)">
        </td>
        <td>
          <select type="text" class="form-select" id="baseCalculoIIBB"
            (change)="cambiarEnumerado(i, 'baseCalculo', $event)" [(ngModel)]="alicuotasIIBB[i].baseCalculo" >
            <option id="optionBaseCalculo" *ngFor="let base of keysBasoCalculo()" [ngValue]="calculo[base]">
              {{ base}}
            </option>
          </select>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="row" *ngIf="this.alicuotasIIBB.length > 0">
    <ul class="col-md-12 text-danger letra2">
      <li>*B1) Total Facturado - IVA - IVA percecpion - IVA No Cat. - Tasa Vial.
        <button class="btn btn-primary pull-right" type="button" (click)="guardarIIBB()" [disabled]="guardandoIIBB">
          <span *ngIf="!guardandoIIBB">Guardar IIBB</span>
          <div *ngIf="guardandoIIBB" class="d-flex align-items-center">
            <div class="spinner-border text-light spinner-border-sm" role="status"></div>
            <span>Guardando...</span>
          </div>
        </button>
      </li>
      <li>*B2) (Si discrimina IVA, IMPINT, CO2) Total facturado - IVA Insc - IVA No Cat - IVA Percep - Tasa Vial.</li>
      <li>*B3) Total Facturado.</li>
      <li>*B4) (Si discrimina IVA, IMPINT, CO2) Total Facturado - IVA Insc - IVA No Cat - IVA Percep - CO2 - Tasa Hidrica - Fondo Fiduciario - Fondo Gas - Fonno Nacional Energia - Tasa Vial.</li>
    </ul>
  </div>
  
</ng-container>