<div class="container-fluid spinner-container" *ngIf="obteniendoPedidos">    
  <ngx-spinner name="spinnerDetalle" bdOpacity=0.3bd
    Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
    <p style="color: white"> C a r g a n d o . . . </p>
  </ngx-spinner>
</div>

<ngb-alert *ngIf="!showOrderForm && this.obteniendoPedidos===false && this.pedidos!=null && this.pedidos.length===0"> No se encontraron
  pedidos 
</ngb-alert>

<nav *ngIf="!obteniendoPedidos && !showOrderForm && !pedidoDetalle"
  class="navbar navbar-light bg-light navbar-expand-lg">
  <ul class="navbar-nav me-auto">
    <li class="nav-item">
      <button *ngIf="hasRole(TipoUsuario.OPERADOR, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL_AV)" type="button" 
        class="btn btn-outline-primary" (click)="nuevoPedido()">Crear nuevo pedido
      </button>
    </li>
  </ul>
  <loading-text-spinner class="pe-3" *ngIf="actualizandoPedidos" [error]="errorPedidos" size="large" color="primary"
    textColor="dark" message="Actualizando pedidos"></loading-text-spinner>
  <ngb-pagination (pageChange)="onPageChange($event)" [collectionSize]="collectionSize" [maxSize]="10" [(page)]="page"
    [pageSize]="pageSize" *ngIf="this.pedidos!=null && this.pedidos.length>0">
  </ngb-pagination>
</nav>
<form *ngIf="!obteniendoPedidos" class="mb-3">
  <div class="col col-md-12">
    <div *ngIf="!showOrderForm && !pedidoDetalle && this.pedidos!=null">
      <table class="table table-sm table-striped table-hover" *ngIf="this.pedidos.length > 0">
        <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col">Tipo</th>
            <th scope="col">Fecha / Hora Vuelo</th>
            <th scope="col" style="text-align: center;">Fecha / Hora <br />llegada avión</th>
            <th scope="col">Vuelo</th>
            <th scope="col">Cliente</th>
            <th scope="col">Estimado</th>
            <th scope="col">Cargado</th>
            <th scope="col">Faltante</th>
            <th scope="col">Acción</th>
          </tr>
        </thead>
        <tbody>
          <tr [@fadeIn] *ngFor="let pedido of pedidos" style="cursor: pointer;" (click)="onClick(pedido.id)" [ngClass]="{'alije': pedido.tipoPedido == TipoPedido.Alije}">
            <td class="px-2">
              <i ngbTooltip="Abierto" *ngIf="pedido.estado === EstadoPedido.Abierto"
                class="fa fa-envelope-open text-primary"></i>
              <i ngbTooltip="Cerrado" *ngIf="pedido.estado !== EstadoPedido.Abierto"
                class="fa fa-envelope text-secondary"></i>
            </td>
            <td>{{ getDescripcionTipo(pedido.tipoPedido) }}</td>
            <td>{{pedido.fechaHoraVuelo | date: "dd'/'MM'/'y hh:mm a" }}</td>
            <td style="font-size: 0.8rem; text-align: center; padding-top: 8px;">
              {{pedido.fechaHoraEstimada | date: "dd'/'MM'/'y hh:mm a" }} </td>
            <td>{{ pedido.vuelo }}</td>
            <td>{{ pedido.clienteRazonSocial }}</td>
            <td>{{ pedido.cantidadEstimada }}</td>
            <td [@fadeIn]>{{ pedido.cantidadEstimada - pedido.cantidadFaltante }}</td>
            <td>{{ pedido.cantidadFaltante }}</td>
            <td>
              <a *ngIf="noEsAlije(pedido.tipoPedido) && hasRole(TipoUsuario.OPERADOR, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL_AV)" ngbTooltip="Agregar predespacho" (click)="onAgregarPrecargaClick(pedido, $event)"><i
                  class="fa fa-plus-square fa-2x text-primary ps-2"></i></a>
            </td>
          </tr>
        </tbody>
      </table>
      <ngb-pagination *ngIf="this.pedidos.length > 0" class="d-flex justify-content-center"
        (pageChange)="onPageChange($event)" [collectionSize]="collectionSize" [maxSize]="10" [(page)]="page"
        [pageSize]="pageSize">
      </ngb-pagination>
    </div>
  </div>
</form>

<div [@fadeIn] *ngIf="showOrderForm">
  <app-nuevo-pedido [tipoModalPedido]="TipoPedido.Manual" (nuevoPedidoCreado)="onNuevoPedidoCreado($event)" (cancelado)="onNuevoPedidoCancelado()">
  </app-nuevo-pedido>
</div>

<div [@fadeIn] *ngIf="pedidoDetalle">
  <app-pedido-detalle [pedidoDetalle]="pedidoDetalle" (volver)="onPrecargaVolver()"
    (pedidoCerradoSuccess)="onPedidoCerradoSuccess()"></app-pedido-detalle>
</div>
