<form [formGroup]="busqueda">
    <nav class="navbar navbar-light bg-light navbar-expand-lg mb-3">
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto">
                <!-- Lista de Meses -->
                <li class="nav-item me-2">
                    <select [attr.disabled]="this.cargandoResolucion ? '': null" id="mesesFormControl" class="form-select" formControlName="mesesFormControl"
                        ngbTooltip="Seleccione el mes a filtrar." (change)="this.onChange($event)">
                        <option [value]="null" disabled defaultselected>Seleccione un mes...</option>
                        <option *ngFor="let m of keysMeses()" [ngValue]="m">
                            {{ m }}
                        </option>
                    </select>
                </li>
                <!-- Año -->
                <li class="nav-item me-2">
                    <input [attr.disabled]="this.cargandoResolucion ? '': null"  type="number" formControlName="anioFormControl" class="form-control" style="width: 100px;">
                </li>
                <!-- Lista de Aeroplantas -->

                <li class="nav-item me-2">
                    <!--<select id="aeroplantaFormControl" class="form-select" formControlName="aeroplantaFormControl" ngbTooltip="Seleccione la aeroplanta a filtrar">
                        <option [value]="null" disabled defaultselected>Seleccione una aeroplanta...</option>
                        <option *ngFor="let a of this.aeroplantas" [ngValue]="a.codigoAeroplanta">
                            {{a.nombre}}
                        </option
                    </select>-->

                    <div ngbDropdown autoClose="outside">
                        <button [disabled]="this.cargandoResolucion" type="button" class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2" id="dropdownAreas"
                            ngbDropdownToggle>Aeroplantas</button>
                        <div ngbDropdownMenu aria-labelledby="dropdownAreas">
                            <div class="px-4 py-3">


                                <div class="form-check">

                                    <input type="checkbox" name="Terceras" id="terceras" class="form-check-input"
                                        (click)="seleccionarTerceras()">
                                    <label for="terceras">Aeroplantas terceras</label>
                                    <div *ngFor="let a of this.aeroplantas; let i = index;">
                                        <input type="checkbox" name="Aeroplantas+{{i}}" class="form-check-input"
                                            id="{{ 'dropdownCheck' + a.codigoAeroplanta}}" [(ngModel)]="a.seleccionada"
                                            [ngModelOptions]="{standalone: true}">
                                        <label class="form-check-label text-nowrap"
                                            for="{{ 'dropdownCheck' + a.codigoAeroplanta}}">
                                            {{ a.nombre }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <loading-text-spinner *ngIf="this.cargandoAeroplantas" message="Obteniendo aeroplantas..."
                        color="primary">
                    </loading-text-spinner>

                </li>
                <!-- Boton Consultar -->
                <li class="nav-item">
                    <button [disabled]="busqueda.invalid || this.cargandoAeroplantas || this.cargandoResolucion" class="btn btn-primary my-2 my-lg-0 mx-lg-2"
                        (click)="filtrarMovimientos();"><i class="fa fa-search" aria-hidden="true"></i>
                    </button>
                </li>
            </ul>
            <ul class="navbar-nav">
                <li class="nav-item">
                    <button *ngIf="this.resolucionMensual" [disabled]="grabando || busqueda.invalid"
                        style="float: right;" (click)="this.exportarExcel()" type="button"
                        class="btn btn-outline-success my-2 my-lg-0 mx-lg-2">
                        Exportar <i class="fa fa-file-excel-o"></i>
                    </button>
                </li>
                <!-- Boton Generacion -->
                <li *ngIf="puedeGenerarse" class="nav-item">
                    <button *ngIf="!this.resolucionMensual.generada"
                        [disabled]="grabando || busqueda.invalid" style="float: right;"
                        (click)="this.generarResolucion()" type="button" class="btn btn-primary my-2 my-lg-0 mx-lg-2">
                        Generar Informe
                    </button>
                    <button *ngIf="this.resolucionMensual.generada" [disabled]="grabando || busqueda.invalid"
                        style="float: right;" (click)="this.verResolucion()" type="button"
                        class="btn btn-primary my-2 my-lg-0 mx-lg-2">
                        Ver Informe  
                    </button>
                </li>
            </ul>
        </div>
    </nav>
</form>

<div class="col container-fluid">

    <div class="spinner-container" *ngIf="obteniendoResolucion || grabando">
        <ngx-spinner name="spinnerGrafico" bdOpacity=0.3bd Color="rgba(193,193,193,0)" size="medium" color="#185db5"
            type="ball-grid-beat" [fullScreen]="false">
            <p style="color: white"> C o n s u l t a n d o . . . </p>
        </ngx-spinner>
    </div>

    <ngb-alert [dismissible]="false"
        *ngIf="!obteniendoResolucion && this.resolucionMensual?.renglonesResolucion!=null && this.resolucionMensual?.renglonesResolucion.length==0">
        No existe información para la busqueda seleccionada
    </ngb-alert>

    <table class="table" *ngIf="this.resolucionMensual?.renglonesResolucion!=null && this.resolucionMensual.renglonesResolucion.length > 0 && !grabando">
        <thead class="text-center">
            <th class="primerNivel">Código Aeroplanta</th>
            <th class="primerNivel">Dependencia Origen</th>
            <th class="primerNivel">Producto</th>
            <th class="primerNivel">Fecha Ult Cierre</th>
            <th class="segundoNivel">Existencia Real</th>
            <th class="segundoNivel">Entradas</th>
            <th class="segundoNivel">Salidas</th>
            <th class="segundoNivel">Existencia Libros</th>
            <th class="segundoNivel">Existencia Final</th>
            <th class="tercerNivel">Removido [lt]</th>
            <th class="tercerNivel">Sup / Déf [lt]</th>
            <th class="tercerNivel">Porcentual [%]</th>
            <th class="tercerNivel">Tolerancia M.O. [%]</th>
            <th class="tercerNivel">Resultado</th>
            <th class="tercerNivel">% FT</th>
            <th class="tercerNivel">Justificacion</th>
        </thead>
        <tbody>
            <tr *ngFor="let r of this.resolucionMensual.renglonesResolucion; let i=index">
                <td [ngClass]="[!r.aceptado && r.puedeAceptar ? 'primerNivel' : '', r.aceptado && r.puedeAceptar ? 'aceptada' : '', !r.puedeAceptar ? 'noEdita' : '']"
                    class="text-center">{{r.codigoAeroplanta}}</td>
                <td [ngClass]="[!r.aceptado && r.puedeAceptar ? 'primerNivel' : '', r.aceptado && r.puedeAceptar ? 'aceptada' : '', !r.puedeAceptar ? 'noEdita' : '']"
                    class="text-center">{{r.nombreAeroplanta}}</td>
                <td [ngClass]="[!r.aceptado && r.puedeAceptar ? 'primerNivel' : '', r.aceptado && r.puedeAceptar ? 'aceptada' : '', !r.puedeAceptar ? 'noEdita' : '']"
                    class="text-center">{{r.nombreProducto}}</td>
                <td [ngClass]="[!r.aceptado && r.puedeAceptar ? 'primerNivel' : '', r.aceptado && r.puedeAceptar ? 'aceptada' : '', !r.puedeAceptar ? 'noEdita' : '']"
                    class="text-center">{{r.fechaUltimoCierre | date:'dd/MM/yyyy hh:mm a'}}</td>
                <td [ngClass]="[!r.aceptado && r.puedeAceptar ? 'segundoNivel' : '', r.aceptado && r.puedeAceptar ? 'aceptada' : '', !r.puedeAceptar ? 'noEdita' : '']"
                    class="text-center">{{r.existenciaReal}}</td>
                <td [ngClass]="[!r.aceptado && r.puedeAceptar ? 'segundoNivel' : '', r.aceptado && r.puedeAceptar ? 'aceptada' : '', !r.puedeAceptar ? 'noEdita' : '']"
                    class="text-center">{{r.entradas}}</td>
                <td [ngClass]="[!r.aceptado && r.puedeAceptar ? 'segundoNivel' : '', r.aceptado && r.puedeAceptar ? 'aceptada' : '', !r.puedeAceptar ? 'noEdita' : '']"
                    class="text-center">{{r.salidas}}</td>
                <td [ngClass]="[!r.aceptado && r.puedeAceptar ? 'segundoNivel' : '', r.aceptado && r.puedeAceptar ? 'aceptada' : '', !r.puedeAceptar ? 'noEdita' : '']"
                    class="text-center">{{r.existenciaAnterior + r.entradas - r.salidas}}</td>
                <td [ngClass]="[!r.aceptado && r.puedeAceptar ? 'segundoNivel' : '', r.aceptado && r.puedeAceptar ? 'aceptada' : '', !r.puedeAceptar ? 'noEdita' : '']"
                    class="text-center">{{r.existeciaFinal}}</td>
                <td [ngClass]="[!r.aceptado && r.puedeAceptar ? 'tercerNivel' : '', r.aceptado && r.puedeAceptar ? 'aceptada' : '', !r.puedeAceptar ? 'noEdita' : '']"
                    class="text-center">{{r.removido}}</td>
                <td [ngClass]="[!r.aceptado && r.puedeAceptar ? 'tercerNivel' : '', r.aceptado && r.puedeAceptar ? 'aceptada' : '', !r.puedeAceptar ? 'noEdita' : '']"
                    class="text-center">{{r.diferencia | number:'0.2-2'}}</td>
                <td [ngClass]="[!r.aceptado && r.puedeAceptar ? 'tercerNivel' : '', r.aceptado && r.puedeAceptar ? 'aceptada' : '', !r.puedeAceptar ? 'noEdita' : '']"
                    class="text-center">{{r.porcentual | number:'0.2-3'}}%</td>
                <td [ngClass]="[!r.aceptado && r.puedeAceptar ? 'tercerNivel' : '', r.aceptado && r.puedeAceptar ? 'aceptada' : '', !r.puedeAceptar ? 'noEdita' : '']"
                    class="text-center">{{r.toleranciaMensualOperativa | number:'0.2-3'}}%</td>
                <td [ngClass]="[r.resultado=='FT' ? 'colorRojo' : 'colorVerde']" class="text-center">{{r.resultado }}
                </td>
                <td [ngClass]="[!r.aceptado && r.puedeAceptar ? 'tercerNivel' : '', r.aceptado && r.puedeAceptar ? 'aceptada' : '', !r.puedeAceptar ? 'noEdita' : '']"
                    class="text-center">{{r.ft | number:'0.2-2'}}%</td>
                <td [ngClass]="[!r.aceptado && r.puedeAceptar ? 'tercerNivel' : '', r.aceptado && r.puedeAceptar ? 'aceptada' : '', !r.puedeAceptar ? 'noEdita' : '']"
                    class="text-center">
                    <button [disabled]="!r.puedeAceptar || this.aeroplantasGeneradas.includes(r.codigoAeroplanta)" class="btn btn-primary"
                        (click)="abrirModalJustificacion(r)">Justificación</button>
                </td>
            </tr>
        </tbody>
    </table>
</div>