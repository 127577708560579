import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NgbCalendar, NgbDatepickerConfig, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { UsuarioService } from 'src/app/account/usuario.service';
import { CierreTurno } from 'src/app/shared/models/cierreTurno/CierreTurno';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { SharedService } from 'src/app/shared/shared.service';
import { MessageService } from 'src/app/shell/message.service';
import { CierreTurnoService } from '../cierre-turno.service';

@Component({
  selector: 'app-reportes-cierre-turno',
  templateUrl: './reportes-cierre-turno.component.html',
  styleUrls: ['./reportes-cierre-turno.component.css']
})
export class ReportesCierreTurnoComponent implements OnInit {

  model: NgbDateStruct;
  model1: NgbDateStruct;
  fechaDesde: Date;
  fechaHasta: Date;
  esperandoCarga: boolean = false;
  change$: Subscription;
  cierreSeleccionado: CierreTurno;
  cierresObtenidos: CierreTurno[] = [];

  constructor(private calendar: NgbCalendar, 
    private datePipe: DatePipe, 
    private datepickerConfig2: NgbDatepickerConfig, 
    private spinner: NgxSpinnerService, 
    private messageService: MessageService,
    private usuarioService: UsuarioService, 
    private cierresTurnos: CierreTurnoService,
    sharedService: SharedService) { 
      this.change$ = sharedService.aeroplantaSeleccionada.subscribe(_=>{ 
        this.cambiarDeAeroplanta()
      });
    }

  ngOnInit() {
    localStorage.removeItem('Reportes');
    this.model = this.calendar.getToday();
    this.model1 = this.calendar.getToday();
    let inicio: any = { day: 1, month: 1, year: 2019 };
    this.datepickerConfig2.minDate = inicio;
    let fin: any = { day: this.model.day, month: this.model.month, year: this.model.year };
    this.model1 = this.calendar.getToday();
    this.datepickerConfig2.maxDate = fin;
    this.buscarDesdeHasta();
  }

  cambiarDeAeroplanta() {
    this.ngOnInit();
  }

  ngOnChanges(){
    this.ngOnInit();
  }

  ngOnDestroy() {
    this.change$.unsubscribe();
  }
  
  formatDate(year, month, day, hour, minute): string {
    return year + '-' + month.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + '-' + day.toLocaleString(undefined, { minimumIntegerDigits: 2 })
      + 'T' + hour.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + minute.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':00';
  }

  obtenerFechaDeCierre(cierre:CierreTurno) {
    return cierre.fechaHoraFinalizacion==null ? this.datePipe.transform(cierre.fechaHoraCierre, "dd'/'MM'/'y hh:mm a") : this.datePipe.transform(cierre.fechaHoraFinalizacion, "dd'/'MM'/'y hh:mm a");
  }

  buscarDesdeHasta() {
    this.cierreSeleccionado = null;
    this.cierresObtenidos = [];
    this.reinicioFechaInicioFechaFin();
    this.buscarCierresPorFecha(this.usuarioService.getAeroplantaActual().codigoAeroplanta, this.fechaDesde, this.fechaHasta);
  }

  reinicioFechaInicioFechaFin() {
    this.fechaDesde = new Date(this.model.year, this.model.month - 1, this.model.day);
    this.fechaHasta = new Date(this.model1.year, this.model1.month - 1, this.model1.day);
    this.fechaHasta.setHours(23, 59, 59, 99);
  }

  mostrarDetalle(i: number) {
    this.cierreSeleccionado = this.cierresObtenidos[i];
  }

  buscarCierresPorFecha(codigoAeroplanta: string, fechaInicio: Date, fechaFin: Date) {
    this.esperandoCarga = true;
    this.spinner.show('spinnerGrafico');
    let desde: string = this.formatDate(fechaInicio.getUTCFullYear(), fechaInicio.getMonth() + 1, fechaInicio.getDate(), 0, 0);
    let hasta: string = this.formatDate(fechaFin.getUTCFullYear(), fechaFin.getMonth() + 1, fechaFin.getDate(), 23, 59);
    this.cierresTurnos.getCierresPorFecha(codigoAeroplanta, desde, hasta)
      .subscribe(result => {
        this.cierresObtenidos = result;
        this.cierresObtenidos = this.cierresObtenidos.filter(a => a.estadoCierre == 3 || a.estadoCierre == 2);
        this.cierresObtenidos = this.cierresObtenidos.filter(a => a.numero > 0);
        this.spinner.hide('spinnerGrafico');
        this.esperandoCarga = false;
      }, (err: ErrorModel) => {
        this.messageService.showErrorMessage('Hubo un error al obtener ');
        this.spinner.hide('spinnerGrafico');
        this.esperandoCarga = false;
      });
  }

}
