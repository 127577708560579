<div class="card">
    <div class="card-header">
      <h5 class="card-title">Remitos de contado sin facturar</h5>
      <button type="button" class="btn-close" aria-label="Close" (click)="this.modal.close()">
        
      </button>
    </div>
    <div class="card-body">
      <div class="col-12">
        <div class="alert alert-danger" *ngIf="this.remitosContado.length == 0">
          No hay remitos de contado sin facturar con mas de 48hs.
        </div>
        <div *ngIf="this.remitosContado.length > 0">
          <div class="row">
            <label class="col-5 ms-1"></label>
            <div class="mb-3 row" id="noPrint" [formGroup]="ordenamientoForm">
              <div class="input-group col-3">
                <input type="radio" class="form-check-input" id="checkCliente" [value]="1" (click)="ordenar(1)"
                  formControlName="clienteAerovaleFormControl" />
                <label class="form-check-label letra" for="checkCliente">Cliente</label>
              </div>
              <div class="input-group col-3 ms-5">
                <input type="radio" class="form-check-input" id="checkAerovle" [value]="0" (click)="ordenar(0)"
                  formControlName="clienteAerovaleFormControl" />
                <label class="form-check-label letra" for="checkAerovle">Remito</label>
              </div>
            </div>
          </div>
  
          <!--AEROVALES-->
          <table class="table table-striped table-hover letra">
            <thead class="text-center">
              <tr>
                <th>Remito</th>
                <th>Cliente</th>
                <th>Cuenta</th>
                <th>Aerovale</th>
                <th>Ver</th>
              </tr>
            </thead>
            <tbody class="text-center">
              <tr *ngFor="let rem of remitosContado; let i = index">
                <td>{{rem.remitoPrefijo}}-{{rem.remitoNumero}}</td>
                <td>{{rem.clienteNombre}}</td>
                <td>{{rem.clienteCtaSGC}}</td>
                <td>{{rem.aerovalePrefijo}}-{{rem.aerovaleNumero}}</td>
                <td><button *ngIf="!rem.booleanAux" (click)="mostrarAerovale(i)" class="btn btn-primary btn-sm t-2 mb-2"
                    type="button">
                    <i class="fa fa-eye" aria-hidden="true"></i> Ver aerovale</button>
                  <button *ngIf="rem.booleanAux" class="btn btn-primary btn-sm mt-2 mb-2" type="button">
                    <div class="spinner-border text-light spinner-border" role="status"></div> Ver aerovale
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <button type="button" class="btn btn-secondary" (click)="this.modal.close()">Cancelar</button>
    </div>
  </div>
