import { Guid } from 'guid-typescript';
import { Contenedor } from './Contenedor';
import { Producto } from './Producto';
import { Pico } from '../despacho/Pico'
import { Medicion } from './Medicion';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export class Responsable {
  NombreCompleto: string;
  UserName: string;
  DNI: string;
  codigoUsuario: string;
}
