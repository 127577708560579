import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { UsuarioService } from 'src/app/account/usuario.service';
import { AerovaleDetalleComponent } from 'src/app/despacho/aerovale-detalle/aerovale-detalle.component';
import { AerovalesService } from 'src/app/despacho/aerovales.service';
import { ExportExcelServiceService } from 'src/app/shared/exportar-excel/export-excel-service.service';
import { AerovaleDetalle } from 'src/app/shared/models/despacho/AerovaleDetalle';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { Comprobante } from 'src/app/shared/models/facturacion/Comprobante';
import { Remito } from 'src/app/shared/models/facturacion/Remito';
import { RemitoNumeroPipe } from 'src/app/shared/pipes/remito-numero.pipe';
import { RemitoPrefijoPipe } from 'src/app/shared/pipes/remito-prefijo.pipe';
import { MessageService } from 'src/app/shell/message.service';
import { ComprobanteService } from '../../comprobante.service';

@Component({
  selector: 'app-reporte-remitos-vencidos',
  templateUrl: './reporte-remitos-vencidos.component.html',
  styleUrls: ['./reporte-remitos-vencidos.component.css']
})
export class ReporteRemitosVencidosComponent implements OnInit {

  remitosVencidos: Comprobante[] = [];
  aerovaleDetalle: AerovaleDetalle;
  obteniendoRemitos: boolean = false;

  constructor(private usuarioService: UsuarioService, 
    private spinner: NgxSpinnerService, 
    private comprobanteService: ComprobanteService,
    private messageService: MessageService, 
    private activeModal: NgbModal, 
    private aerovaleService: AerovalesService, 
    private excelService: ExportExcelServiceService,
    private remitoPrefijoPipe: RemitoPrefijoPipe,
    private remitoNumeroPipe: RemitoNumeroPipe,
    private datePipe: DatePipe) {}

  ngOnInit() {    
    this.buscarRemitosPorFecha();
  }

  ngOnChanges(): void {
    this.ngOnInit();
  }

  cambiarDeAeroplanta() {
    this.ngOnInit();
  }

  buscarRemitosPorFecha() {
    this.obteniendoRemitos = true;
    this.spinner.show('spinnerGrafico');
    // let desde: string = this.formatDate(fechaInicio.getUTCFullYear(), fechaInicio.getMonth() + 1, fechaInicio.getDate(), 0, 0);
    // let hasta: string = this.formatDate(fechaFin.getUTCFullYear(), fechaFin.getMonth() + 1, fechaFin.getDate(), 23, 59);
    this.comprobanteService.getRemitosVencidos()
      .subscribe(result => {
        this.remitosVencidos = result;
        for (let i = 0; this.remitosVencidos.length > i; i++) {
            this.remitosVencidos[i].remito.ultimoErrorEjecucion2 = this.cortarCadena(this.remitosVencidos[i].remito.ultimoErrorEjecucion);
        }
        this.obteniendoRemitos = false;
        this.spinner.hide('spinnerGrafico');
      }, (err: ErrorModel) => {
        this.messageService.showErrorMessage('Hubo un problema al obtener los remitos vencidos. ' + err.description);
        this.obteniendoRemitos = false;
        this.spinner.hide('spinnerGrafico');
      });
  }

  cortarCadena(cadena: string): string[] {
    if (cadena != null || cadena == '') {
      return cadena.split('*|*');
    } else {
      return ['-'];
    }
  }

  remitoAusenteEnErrores(errores: string[], remito: Remito){    
    let str =  this.remitoPrefijoPipe.transform(remito.prefijo) + this.remitoNumeroPipe.transform(remito.numeroComprobante);

    for(let i=0 ; i < errores.length ; i++){
      if(errores[i].includes(str)){
        return false;
      }
    }
    return true;
  }

  mostrarRemito(remito: Comprobante) {
    remito.verComprobante = true;
    this.aerovaleService.getAerovalePorIdyTipo(remito.remito.aerovaleId)
      .subscribe(result => {
        this.aerovaleDetalle = result;
        if (this.aerovaleDetalle != null) {
          let modalRef = this.activeModal.open(AerovaleDetalleComponent, { centered: true, size: 'lg' });
          modalRef.componentInstance.aerovaleDetalle = this.aerovaleDetalle;
          remito.verComprobante = false;
        }
      }, (err: ErrorModel) => {
        this.messageService.showErrorMessage('Hubo un error al obtener el aerovale. ' + err.description);
        remito.verComprobante = false;
      });
  }

  print(): void {
    var divToPrint = document.getElementById('printSection');
    var htmlToPrint = '' +
      '<style type="text/css">' +
      '@media print{'+
      '@page {'+
      'size: landscape'+
      '}'+
      '}'+
      'table, table td, table th  {' +
      'border:1px solid #000;' +
      'text-align: center;' +
      'padding:0.5em;' +
      '}' +
      'button{' +
      'display: none;' +
      '}' +
      'label  {' +
      'margin-top: 0.5cm;' +
      'display: inline-block;' +
      '}' +
      '#noPrint {' + 'display: none' + '}' +
      '</style>';
    htmlToPrint += divToPrint.outerHTML;
    let newWin = window.open("");
    newWin.document.write(htmlToPrint);
    newWin.print();
    newWin.close();
  }

  exportar() {
    this.excelService.exportAsExcelFile(this.armarReporte(), 'Remitos Vencidos');
  }

  armarReporte(): ExportarVencidos[] {
    let vencidos: ExportarVencidos[] = [];
    for (let ven of this.remitosVencidos) {
      for (let p of ven.remito.detalles) {
        let aux = new ExportarVencidos();
        aux.Cliente = ven.cliente.razonSocial;
        aux.Cuenta = ven.cliente.sgc;
        aux.Remito = this.remitoPrefijoPipe.transform(ven.prefijo) + '-' + this.remitoNumeroPipe.transform(ven.numeroComprobante);
        aux.Producto = p.codigoProducto + '-' + p.producto;
        aux.Cantidad = p.cantidad;
        aux.FechaHora = this.datePipe.transform(ven.fechaEmision, 'dd/MM/yyyy HH:mm a');
        aux.Aeroplanta = ven.nombreAeroplanta;
        aux.UltimaEjecucion = this.datePipe.transform(ven.remito.ultimaFechaEjecucion, 'dd/MM/yyyy HH:mm a');
        aux.UltimoError = ven.remito.ultimoErrorEjecucion;
        vencidos.push(aux);
      }
    }
    return vencidos
  }
}

export class ExportarVencidos {
  Cliente: string;
  Cuenta: string;
  Remito: string;
  Producto: string;
  Cantidad: number;
  FechaHora: string;
  Aeroplanta: string;
  UltimaEjecucion: string;
  UltimoError: string;
}
