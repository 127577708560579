import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ProductoService } from 'src/app/abm/producto/producto.service';
import { UsuarioService } from 'src/app/account/usuario.service';
import { EstadoPedido } from 'src/app/shared/enums/estadoPedido';
import { Producto } from 'src/app/shared/models/abm/Producto';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { Pedido } from 'src/app/shared/models/stock/Pedido';
import { Responsable } from 'src/app/shared/models/stock/Responsable';
import { UltimoEstado } from 'src/app/shared/models/stock/UltimoEstado';
import { MessageService } from 'src/app/shell/message.service';
import { StockService } from '../../../stock.service';

@Component({
  selector: 'app-nuevo-pedido-combustible',
  templateUrl: './nuevo-pedido-combustible.component.html',
  styleUrls: ['./nuevo-pedido-combustible.component.scss']
})
export class NuevoPedidoCombustibleComponent implements OnInit {

  @Input() pedidosCombustible: Pedido;
  @Input() productos: Producto[];

  form: UntypedFormGroup;
  guardandoDatos: boolean=false;
  editando: boolean=false;
  modelFecha: NgbDateStruct;
  modelHora: any;
  fecha: Date;
  _productos: Producto[];

  constructor(
    public activeModal: NgbActiveModal,
    private calendar: NgbCalendar,
    private usuarioService: UsuarioService,
    private messageService: MessageService,
    private stockService: StockService,
    private formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {
    this._productos = this.productos;
    this.editando = this.pedidosCombustible!=null;
    this.crearFormulario();
    if(this.editando) {
      this.precargarDatos();
    }else{
      this.setearFechas();
    }
  }

  getCodigoAeroplanta(){
    return this.usuarioService.getAeroplantaActual().codigoAeroplanta;
  }

  precargarDatos() {  
    this.fecha = new Date(this.pedidosCombustible.fechaHora);
    this.modelFecha = { day: this.fecha.getDate(), month: this.fecha.getMonth() + 1, year: this.fecha.getFullYear() };
    this.modelHora = { hour: this.fecha.getHours(), minute: this.fecha.getMinutes(), second: this.fecha.getSeconds() }; 
    this.productoFormControl.setValue(this.pedidosCombustible.producto);
    this.cantidadFormControl.setValue(this.pedidosCombustible.cantidad); 
    this.editando ? this.productoFormControl.disable() : null;
  }  

  setearFechas() {
    this.modelFecha = this.calendar.getToday();
    this.modelHora = this.calendar.getToday();
    this.fecha = new Date(this.modelFecha.year, this.modelFecha.month-1, this.modelFecha.day);
    var hora = new Date();
    this.modelHora = { hour: hora.getHours(), minute: hora.getMinutes(), second: hora.getSeconds() };
  }

  crearFormulario() {
    this.form = this.formBuilder.group({
      fechaFormControl: [''],
      horaFormControl: [''],
      productoFormControl: ['', Validators.required],      
      cantidadFormControl: [null, Validators.required]
    });
    this.form.setValidators(this.formValidator.bind(this));
  }

  formValidator(group: UntypedFormGroup): any {
    if (group) {
      if (this.cantidadFormControl.value != null && this.cantidadFormControl.value < 1) {
        return { cantidadMayorACeroValidator: true }
      }

      return null;
    }
  }

  async onClick() {
    if (this.form.valid) {
      this.guardandoDatos = true;

      if(!this.editando) {
        let pedido = new Pedido();
        pedido.fechaHora = this.formatDate(
          this.fechaFormControl.value.year, this.fechaFormControl.value.month,
          this.fechaFormControl.value.day, this.horaFormControl.value.hour, this.horaFormControl.value.minute, 0);
  
        pedido.producto = this.productoFormControl.value; //.codigoProducto
        pedido.cantidad = Number(this.cantidadFormControl.value);
  
        let estado = new UltimoEstado();
        estado.fechaHora = "2020-01-15T21:00:00.00";
        estado.estado = EstadoPedido.Abierto;
        estado.responsable = this.stockService.obtenerStringResponsable();
  
        pedido.ultimoEstado = estado;

        this.crearPedido(pedido);
      }else{
        let pedido = new Pedido();
        pedido.fechaHora = this.formatDate(
          this.fechaFormControl.value.year, this.fechaFormControl.value.month,
          this.fechaFormControl.value.day, this.horaFormControl.value.hour, this.horaFormControl.value.minute, 0);
        pedido.producto = this.pedidosCombustible.producto; // no editable
        pedido.cantidad = Number(this.cantidadFormControl.value);
        pedido.ultimoEstado = this.pedidosCombustible.ultimoEstado;
  
        this.editarPedido(this.pedidosCombustible.id, pedido);
      }
    }else{
      this.messageService.showErrorMessage('Los datos ingresados no son válidos.');
      this.guardandoDatos = false;
    }
  }

  crearPedido(pedido) {
    const c = new Promise((resolve, reject)=>{
      let r = this.stockService.postPedidos(this.getCodigoAeroplanta(), pedido);
      r.then(() => {
          this.messageService.showSuccessMessage(`El pedido fue creado correctamente.`);
          this.activeModal.close(pedido);
          this.guardandoDatos = false;
          resolve(r);
        }, (error:ErrorModel) => {
          this.messageService.showErrorMessage('Hubo un error al crear un pedido.'+'\n'+error.message);
          this.guardandoDatos = false;
          reject(error);
        })
    });
    return c;
  }

  editarPedido(id, pedido) {
    const e = new Promise((resolve, reject)=>{
      let r = this.stockService.putPedidos(id, pedido);
      r.then(() => {
          this.messageService.showSuccessMessage(`El pedido fue actualizado correctamente.`);
          this.activeModal.close(pedido);
          this.guardandoDatos = false;
          resolve(r);
        }, (error:ErrorModel) => {
          this.messageService.showErrorMessage('Hubo un error al editar un pedido.'+'\n'+error.message);
          this.guardandoDatos = false;
          reject(error);
        })
    });
    return e;
  }

  /** Utiles */

  formatDate(year, month, day, hour, minute, seconds): string {
    return year + '-' + month.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + '-' + day.toLocaleString(undefined, { minimumIntegerDigits: 2 })
      + 'T' + hour.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + minute.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + seconds.toLocaleString(undefined, { minimumIntegerDigits: 2 });
  }

  compareProductos(p1: any, p2: any): boolean {
    return p1 && p2 ? p1.codigoProducto === p2.codigoProducto : p1 === p2;
  }

  get fechaFormControl() {
    return this.form.controls.fechaFormControl;
  }
  
  get horaFormControl() {
    return this.form.controls.horaFormControl;
  }

  get productoFormControl() {
    return this.form.controls.productoFormControl;
  }

  get cantidadFormControl() {
    return this.form.controls.cantidadFormControl;
  }


}
