import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { ConfiguracionService } from 'src/app/despacho/configuracion.service';
import { TipoDocumento } from 'src/app/shared/enums/tipoDocumento';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';
import { Cliente } from 'src/app/shared/models/cliente/Cliente';
import { Ubicacion } from 'src/app/shared/models/cliente/Ubicacion';
import { Pais } from 'src/app/shared/models/despacho/Pais';
import { Ciudad } from 'src/app/shared/models/facturacion/Ciudad';
import { Provincia } from 'src/app/shared/models/facturacion/Provincia';
import { MessageService } from 'src/app/shell/message.service';
import { ClienteService } from '../cliente.service';
import { CuentasService } from '../cuentas.service';

@Component({
  selector: 'app-modal-direccion-fiscal',
  templateUrl: './modal-direccion-fiscal.component.html',
  styleUrls: ['./modal-direccion-fiscal.component.css']
})
export class ModalDireccionFiscalComponent implements OnInit {


  @Input() clienteSeleccionado: Cliente;
  @Input() creandoCliente: boolean;

  @Output() dirFiscal: EventEmitter<Ubicacion> = new EventEmitter();
  errorDestinos: boolean = false;
  guardando: boolean = false;

  campoPaisReadonly = false;

  direccionForm = new UntypedFormGroup({
    calleFormControl: new UntypedFormControl({value: null, disabled: !this.usuarioModificaCampos()}, [Validators.required]),
    pisoFormControl: new UntypedFormControl({value: null, disabled: !this.usuarioModificaCampos()}),
    deptoFormControl: new UntypedFormControl({value: null, disabled: !this.usuarioModificaCampos()}),
    codigoPostalFormControl: new UntypedFormControl({value: null, disabled: !this.usuarioModificaCampos()}, [Validators.required]),
    provinciaFormControl: new UntypedFormControl({value: null, disabled: !this.usuarioModificaCampos()}, [Validators.required]),
    numeroFormControl: new UntypedFormControl({value: null, disabled: !this.usuarioModificaCampos()}, [Validators.required]),
    paisFormControl: new UntypedFormControl({value: null, disabled: !this.usuarioModificaCampos()})
  });

  modelPais: any;
  modelProvincia: any;
  modelCiudad: any;
  paises: Pais[];
  provincias: Provincia[];
  ciudades: Ciudad[];
  cargandoPaises: boolean = false;
  cargandoProvincias: boolean = false;
  ciudadBuscada: Ciudad;
  cargandoCiudades: boolean = false;

  errorDatos: string = null;

  constructor(public activeModal: NgbModal,
    private authService: AuthService, 
    private cuentasService: CuentasService, private messageService: MessageService) { }


  ngOnInit() {

    if( this.creandoCliente ){
      this.campoPaisReadonly = true;
    }

    this.getPaises();
    if (this.clienteSeleccionado.direccionFiscal != null) {
      this.calle.setValue(this.clienteSeleccionado.direccionFiscal.direccion);
      this.numero.setValue(this.clienteSeleccionado.direccionFiscal.numero);
      this.depto.setValue(this.clienteSeleccionado.direccionFiscal.departamento);
      this.piso.setValue(this.clienteSeleccionado.direccionFiscal.piso);
    }
  }

  onlyDecimalPositivo(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46;
  }

  getPaises() {
    this.cargandoPaises = true;
    this.cargandoProvincias = true;
    this.cargandoCiudades = true;
    this.cuentasService.getPaises()
      .subscribe(result => {
        this.paises = result;
        if(this.esClienteExtranjero()){
          this.paises = this.paises.filter(p => p.codigo != "0303");
        }
        this.cargandoPaises = false;        
        this.obtenerPaisSeleccionado();
      }, () => {
        this.errorDestinos = true;
        this.cargandoPaises = false;        
      })
  }

  esClienteExtranjero(){
    if(this.clienteSeleccionado.tipoDocumento == TipoDocumento.Extranjero){
      return true;
    }else{
      return false;
    }    
  }

  obtenerPaisSeleccionado() {
    let entro:boolean=false;
    if (this.clienteSeleccionado.direccionFiscal != null && (this.clienteSeleccionado.direccionFiscal.ciudad != null && this.clienteSeleccionado.direccionFiscal.ciudad.provincia != null)) {
      for (let i = 0; this.paises.length > i; i++) {
        if (this.paises[i].nombre == this.clienteSeleccionado.direccionFiscal.ciudad?.provincia?.pais.nombre) {          
          this.pais.setValue(this.paises[i]);
          this.getProvinciasPorPais(this.paises[i].codigo);
          entro=true;
        }
      }
    } else {
      for (let i = 0; this.paises.length > i; i++) {
        if (this.paises[i].nombre == 'Argentina') {
          this.pais.setValue(this.paises[i]);
          this.getProvinciasPorPais(this.paises[i].codigo);
          entro=true;
        }
      }
    }
    if (!entro)  {
      this.cargandoProvincias = false;
      this.cargandoCiudades = false;
    }
  }

  getProvinciasPorPais(codigo: string) {     
    this.provincia.setValue(null);
    this.cuentasService.getProvinciasPorPais(codigo)
      .subscribe(result => {
        this.provincias = result;
        this.cargandoProvincias = false;
        this.obtenerProvinciaSeleccionada();
      }, () => {
        this.errorDestinos = true
        this.cargandoProvincias = false;
      })
  }

  obtenerProvinciaSeleccionada() {
    let entro:boolean=false;
    if (this.clienteSeleccionado.direccionFiscal != null && this.clienteSeleccionado.direccionFiscal.ciudad != null) {
      for (let i = 0; this.provincias.length > i; i++) {
        if (this.provincias[i].codigo == this.clienteSeleccionado.direccionFiscal.ciudad.provincia.codigo) {
          this.provincia.setValue(this.provincias[i]);
          this.getLocalidadPorProvincia(this.provincias[i].codigo);
          entro=true;
        } else {
          if (this.clienteSeleccionado.direccionFiscal.ciudad.provincia.codigo == null) {
            this.provincia.setValue(this.provincias[0]);
            this.getLocalidadPorProvincia(this.provincias[0].codigo);
            entro=true;
          }
        }
      }
    } else {
      for (let i = 0; this.provincias.length > i; i++) {
        if (this.provincias[i].codigo == 'BSAS') {
          this.provincia.setValue(this.provincias[i]);
          this.getLocalidadPorProvincia(this.provincias[i].codigo);
          entro=true;
        }
      }
    }
    if (!entro)  {      
      this.cargandoCiudades = false;
    }
  }

  getLocalidadPorProvincia(codigo: string) {
    this.ciudades = [];
    this.cuentasService.getCiudadPorProvincia(codigo)
      .subscribe(result => {
        this.ciudades = result;
        this.cargandoCiudades = false;
        this.obtenerLocalidadSeleccionada();
      }, () => {
        this.errorDestinos = true;
        this.cargandoCiudades = true;
      })
  }

  obtenerLocalidadSeleccionada() {
    if (this.clienteSeleccionado.direccionFiscal != null && this.clienteSeleccionado.direccionFiscal.ciudad != null) {
      for (let i = 0; this.ciudades.length > i; i++) {
        if (this.ciudades[i].codigoPostal == this.clienteSeleccionado.direccionFiscal.ciudad.codigoPostal) {
          this.codigoPostal.setValue(this.ciudades[i]);
        }else{
          if (this.clienteSeleccionado.direccionFiscal.ciudad.codigoPostal == null) {
            this.codigoPostal.setValue(this.ciudades[0]);
          }
        }
      }
    } else {
      for (let i = 0; this.ciudades.length > i; i++) {
        if (this.ciudades[i].codigoPostal == this.clienteSeleccionado.direccionFiscal.ciudad.codigoPostal) {
          this.codigoPostal.setValue(this.ciudades[i]);
        }
      }
    }
  }

  searchPais = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 || this.cargandoPaises ? [] :
        this.paises.filter(v => this.containsString(this.pais.value, v.nombre, v.codigo)).slice(0, 10))
    )
  searchProvincia = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 || this.cargandoProvincias ? [] :
        this.provincias.filter(v => this.containsString(this.provincia.value, v.nombre, v.codigo)).slice(0, 10))
    )
  searchCiudad = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 || this.cargandoCiudades ? [] :
        this.ciudades.filter(v => this.containsString(this.codigoPostal.value, v.nombre, v.codigoPostal)).slice(0, 10))
    )
  /**
   * Determina si una cadena se encuentra dentro de otra
   * @param searchValue Valor a buscar
   * @param searchIn Valores sobre los que buscar searchValue
   */
  containsString(searchValue: string, ...searchIn: string[]): boolean {
    const concat = searchIn.toString();
    return concat.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
  }
  selectPais(e: any): void {
    console.log('e:' + e.item);
    this.pais.setValue(e.item.codigo);
    this.provincia.setValue(null);
    this.codigoPostal.setValue(null);
    this.getProvinciasPorPais(e.item.codigo);

  }
  selectProvincia(e: any): void {
    console.log('e:' + e.item);
    this.provincia.setValue(e.item.codigo);
    this.codigoPostal.setValue(null);
    this.getLocalidadPorProvincia(e.item.codigo);
  }
  selectCiudades(e: any): void {
    this.codigoPostal.setValue(e.item.nombre);
    this.ciudadBuscada = e.item.nombre;
  }

  formatterPais = (x: { nombre: string, codigo: string }) => `${x.nombre} - ${x.codigo}`;
  formatterProvincia = (x: { nombre: string, codigo: string }) => `${x.nombre} - ${x.codigo}`;
  formatterCiudad = (x: { nombre: string, codigoPostal: string }) => `${x.nombre} - ${x.codigoPostal}`;

  accionarForm() {
    Object.keys(this.direccionForm.controls).forEach(key => {
      this.direccionForm.get(key).markAsDirty();
    });
  }

  guardarDireccion() {
    this.errorDatos = null;
    this.accionarForm();
    
    if (this.direccionForm.valid) {

      var pais = this.pais.value;
      if(typeof pais === 'string'){
        this.errorDatos = "Debe seleccionar un pais de la lista que aparece al tipear.";
        return;
      }

      var provincia = this.provincia.value;
      if(typeof provincia === 'string' || provincia instanceof String){
        this.errorDatos = "Debe buscar una provincia en el campo correspondiente. Se filtrarán las provincias por el pais que seleccione.";
        return;
      }

      var ciudad = this.codigoPostal.value;
      if(typeof ciudad === 'string' || ciudad instanceof String){
        this.errorDatos = "Debe buscar una ciudad en el campo correspondiente. Se filtrarán las ciudades por el pais/provincia que seleccione.";
        return;
      }

      this.guardando = true;
      let p = new Ubicacion();
      p.direccion = this.calle.value;
      p.piso = this.piso.value;
      p.ciudad = this.codigoPostal.value;
      p.departamento = this.depto.value;
      p.ciudad.provincia = this.provincia.value;
      p.ciudad.provincia.pais = this.pais.value;
      p.tipoUbicacion = 0;
      p.numero = this.numero.value;
      this.messageService.showWarningMessage('Se modificó la dirección fiscal correctamente. \n Recuerde guardar los cambios del cliente.', false)
      this.dirFiscal.emit(p);
      this.guardando = false;
      this.activeModal.dismissAll();
    } else {
      this.errorDatos = "Verificar los errores en los campos cargados.";
    }
  }

  usuarioModificaCampos(): boolean {
    return this.authService.hasRole(TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.APROBADORBYR, TipoUsuario.ADMCLIENTES);
  }

  get calle() {
    return this.direccionForm.get('calleFormControl');
  }
  get piso() {
    return this.direccionForm.get('pisoFormControl');
  }
  get codigoPostal() {
    return this.direccionForm.get('codigoPostalFormControl');
  }
  get provincia() {
    return this.direccionForm.get('provinciaFormControl');
  }
  get pais() {
    return this.direccionForm.get('paisFormControl');
  }
  get depto() {
    return this.direccionForm.get('deptoFormControl');
  }
  get numero() {
    return this.direccionForm.get('numeroFormControl');
  }
}
