<div *ngIf="isAuthenticated && accionLogin == enumAccionLogin.AccesoHabilitado" class="navbar-header">

  <nav style="padding: 0.5rem 1rem;" class="navbar navbar-expand-md navbar-dark bg-dark">

      <a class="navbar-brand" href="#"><img src="../../../assets/images/pad_home.svg"></a>

    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarColor01"
      aria-controls="navbarColor01" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarColor01">
      <ul class="navbar-nav me-auto">
        <li *ngIf="!mostrarMenu" class="nav-item">
          <a class="nav-link">
            <loading-text-spinner message="completando carga de datos ..."></loading-text-spinner>
          </a>
        </li>
        <li *ngIf="false && isAuthenticated" class="nav-item">
          <a class="nav-link" routerLink="/login" (click)='authService.signout()'><i class="fa fa-times"
              aria-hidden="true"></i> Se ha producido un
            error al obtener los permisos, click aquí para volver a autenticarse </a>
        </li>
        <ng-container *ngIf="mostrarMenu">
          <li class="nav-item">
            <a class="nav-link" routerLink="/home" routerLinkActive="active" id="navBarInicio"><i class="fa fa-home"
                aria-hidden="true"></i> Inicio <span class="sr-only">(current)</span></a>
          </li>
          <li *ngIf="authService.hasRole(TipoUsuario.SUPERVISOR, TipoUsuario.OPERADOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.COMERCIAL, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.APROBADORBYR, TipoUsuario.FACTURACION, TipoUsuario.REFACTURACION, TipoUsuario.CONSAEROVALES, TipoUsuario.CONSPRECIOS, TipoUsuario.FINANZAS, TipoUsuario.SOPORTE) " class="nav-item">
            <a class="nav-link" routerLink="/despachos" routerLinkActive="active" id="navBarDespachos"><i
                class="fa fa-truck" aria-hidden="true"></i> Despacho </a>
          </li>
          <li *ngIf="authService.hasRole(TipoUsuario.OPERADOR, TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.APROBADORBYR, TipoUsuario.SOPORTE)" class="nav-item">
            <a class="nav-link" routerLink="/stock" routerLinkActive="active" id="navBarStock"> <i class="fa fa-calculator">
            </i>  Stock </a>
          </li>
          <li *ngIf="authService.hasRole(TipoUsuario.COMERCIAL, TipoUsuario.OPERADOR, TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.APROBADORBYR, TipoUsuario.DESPACHANTE, TipoUsuario.FACTURACION, TipoUsuario.REFACTURACION, TipoUsuario.CONSAEROVALES, TipoUsuario.CONSPRECIOS, TipoUsuario.SOPORTE)" class="nav-item">
            <a class="nav-link" routerLink="/ranchos" routerLinkActive="active" id="navBarRanchos"><i class="fa fa-align-justify"
                aria-hidden="true"></i> Ranchos
            </a>
          </li>
          <li *ngIf="authService.hasRole(TipoUsuario.SUPERVISOR, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.OPERADOR, TipoUsuario.MANDATARIO, TipoUsuario.PRECIOS, TipoUsuario.APROBADORPRECIOS, TipoUsuario.SEGURIDADTABLAS, TipoUsuario.APROBADORBYR, TipoUsuario.COMERCIAL, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.COMERCIAL_LN, TipoUsuario.COMERCIAL_AV, TipoUsuario.FACTURACION, TipoUsuario.REFACTURACION, TipoUsuario.CONSPRECIOS, TipoUsuario.SOPORTE)" class="nav-item">
            <a class="nav-link" routerLink="/precios" routerLinkActive="active" id="navBarPrecios"><i class="fa fa-tag"
                aria-hidden="true"></i> Precios
            </a>
          </li>
          <li *ngIf="authService.hasRole(TipoUsuario.COMERCIAL, TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.OPERADOR, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.APROBADORBYR, TipoUsuario.PRODUCTOS, TipoUsuario.IMPUESTOS, TipoUsuario.FACTURACION, TipoUsuario.REFACTURACION, TipoUsuario.PRECIOS, TipoUsuario.APROBADORPRECIOS, TipoUsuario.CONSPRECIOS, TipoUsuario.SEGURIDADTABLAS, TipoUsuario.SOPORTE)" class="nav-item">
            <a class="nav-link" routerLink="/abm" routerLinkActive="active" id="navBarABM"><i class="fa fa-folder-open"></i> ABM
            </a>
          </li>
            <li *ngIf="authService.hasRole(TipoUsuario.COMERCIAL, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.SUPERVISOR, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO,TipoUsuario.OPERADOR, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.APROBADORBYR, TipoUsuario.ADMCLIENTES, TipoUsuario.IMPUESTOS, TipoUsuario.SEGURIDADTABLAS, TipoUsuario.FACTURACION, TipoUsuario.REFACTURACION, TipoUsuario.CONSPRECIOS, TipoUsuario.CONSAEROVALES, TipoUsuario.SOPORTE)" class="nav-item">
            <a class="nav-link" routerLink="/facturacion" id="nvBarFacturacion" routerLinkActive="active"><i
                class="fa fa-dollar" aria-hidden="true"></i>Facturación
            </a>
          </li>
          <li  *ngIf="authService.hasRole(TipoUsuario.OPERADOR, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.CONSCIERRECAJA, TipoUsuario.COMERCIAL_AV, TipoUsuario.APROBADORBYR, TipoUsuario.FINANZAS, TipoUsuario.SOPORTE)" class="nav-item">
            <a class="nav-link" routerLink="/cierreTurno" id="nvBarCierreDeTurno" routerLinkActive="active"><i class="fa fa-calendar"></i> Cierre
            </a>
          </li>
          <li *ngIf="authService.hasRole(TipoUsuario.TUTOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.RESPRODUCTO, TipoUsuario.SOPORTE)" class="nav-item">
            <a class="nav-link" routerLink="/reportes" id="nvBarReports" routerLinkActive="active"><i
                class="fa fa-print" aria-hidden="true"></i> Reportes
            </a>
          </li>
          <li *ngIf="authService.hasRole(TipoUsuario.COMERCIAL, TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.ADMCLIENTES, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.APROBADORBYR, TipoUsuario.FACTURACION, TipoUsuario.REFACTURACION, TipoUsuario.SEGURIDADTABLAS, TipoUsuario.IMPUESTOS, TipoUsuario.FINANZAS, TipoUsuario.SOPORTE)" class="nav-item">
            <a class="nav-link" routerLink="/management" id="navBarManagement" routerLinkActive="active"><i
                class="fa fa-wrench" aria-hidden="true"></i> Ajustes </a>
          </li>
          <li *ngIf="authService.hasRole(TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_LN, TipoUsuario.COMERCIAL_AV, TipoUsuario.CONTROLCREDITO, TipoUsuario.APROBADORBYR)" class="nav-item">
            <a class="nav-link" routerLink="/controlCredito" id="navBarcontrolCredito" routerLinkActive="active"><i
                class="fa fa-dollar" aria-hidden="true"></i> Control de Crédito </a>
          </li>
        </ng-container>
      </ul>
      <ul class="navbar-nav">

        <li *ngIf="isAuthenticated" class="nav-item me-4">

          <a id="navBarNotificaciones" class="notification" (click)="verNotificaciones()">
            <i class="fa fa-2x fa-envelope  text-light" aria-hidden="true" title="Ver notificaciones"></i>
            <span *ngIf="this.notificacionesNoLeidas > 0" class="badge">{{this.notificacionesNoLeidas}}</span>
          </a>
        </li>

        <li *ngIf="isAuthenticated" class="nav-item dropdown align-items-center">
          <div class="position-absolute w-100" *ngIf="seteandoAeroplanta">
            <loading-text-spinner orientation="vertical" class="position-relative" color="primary" style="top: 6px "
              size="large">
            </loading-text-spinner>
          </div>
          <form *ngIf="showAeroplants()" [formGroup]="navBarForm">
            <select formControlName="aeroplantaFormControl" class="form-select my-1 me-sm-2"
              (change)="setAeroplanta($event)" [style.color]="seteandoAeroplanta? 'white' : 'black'"
              id="navBarAeroplantas">
              <option *ngFor="let aeroplanta of aeroplantas" value="{{aeroplanta.codigoAeroplanta}}">
                {{ aeroplanta.codigoAeroplanta }}
              </option>
            </select>
          </form>
        </li>

        <li *ngIf="!isAuthenticated" class="my-2 my-md-0 me-md-3 ">
          <a class="p-2 text-light nav-link d-flex align-items-center" routerLink="/login" id="navBarLogin"><span
              class="me-3">Iniciar
              sesión</span><i class="fa fa-sign-in fa-2x" aria-hidden="true" title="Iniciar Sesión"></i></a>
        </li>
        <li *ngIf="isAuthenticated" class="nav-item dropdown">
          <a class="nav-link navbar-toggler d-flex align-items-center" id="navbarDropdown" id="navBarDrop"
            data-bs-toggle="dropdown" title="{{ this.tipoUsuario }}" role="button" data-bs-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <i class="fa fa-user-circle-o fa-2x" aria-hidden="true"></i><span class="ms-2">{{ nombreOperador }}
            </span></a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <a (click)="signout()" class="dropdown-item" id="navBarCerrarSesion">Cerrar sesión</a>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</div>
