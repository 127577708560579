import { Abastecedora } from './Abastecedora';
import { Producto } from './Producto';
import { Aeroplanta } from './Aeroplanta';
import { AerovaleCommon } from './AerovaleCommon';
import { AeroEspecialidadItem } from './AeroespecialidadItem';
import { VehiculoDTO } from '../cliente/VehiculoDTO';
export class AerovaleCombustible extends AerovaleCommon{
  aforadorInicio: number;
  aforadorFinal: number;
  fechaHoraInicioDespacho: string;
  fechaHoraFinDespacho: string;
  volumen: number;
  automotor: VehiculoDTO;
  combustible: Producto;
  aeroespecialidades: AeroEspecialidadItem[];
  codigoTanque: string;
  codigoAbastecedora: Abastecedora;
  origen: Aeroplanta;
  picoId: string;
  Volumen15grados: number;
  Temperatura: number;
  Densidad: number;
}
