import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';

@Component({
  selector: 'app-reportes-index',
  templateUrl: './reportes-index.component.html',
  styleUrls: ['./reportes-index.component.css']
})
export class ReportesIndexComponent implements OnInit {

  TipoUsuario = TipoUsuario;

  constructor(private authService: AuthService) { }

  ngOnInit() {
  }

  hasRole(...roles: TipoUsuario[]) {
    return this.authService.hasRole(...roles);
  }

}
