<!-- <div class="modal add-margin"> -->

<div class="modal-header ">
  <h4 class=" modal-title text-center">Nuevo Rubro </h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismissAll()"></button>
</div>
<div class="modal-body">
  <form [formGroup]="nuevoRubroForm">
    <div class="d-flex">
      <!-- codigo -->
      <div class="mb-3 row">
        <label for="codigoControlInput" class="col-sm-4 col-form-label"> <span
            *ngIf="esCampoVacio(this.codigo.value)">*</span> Codigo:</label>
        <div class="col-sm-8">
          <input id="codigoControlInput" type="text" class="form-control mb-2 me-sm-2"
            title="Campo obligatorio, debe ingresar un codigo de rubro." formControlName="codigoFormControl"
            placeholder="###" />
          <div *ngIf="this.rubros!=null && this.estaCodigoRubro()===true" class="alert alert-danger">
            El codigo ya existe
          </div>
        </div> 
      </div>
      
      <div class="mb-3 row ms-2">
        <label for="tipoProductoControlInput" class="col-sm-4 col-form-label">Tipo Neto</label>
        <div class="col-sm-8">
          <select type="text" class="form-select" name="tipoID" formControlName="tipoNetoRubroFormControl"
            title="Campo obligatorio, seleccione un tipo de Neto.">
            <option id="optionUnidadMedida" *ngFor="let neto of keysTiposNeto()" [ngValue]="tipoNeto[neto]">
              {{ neto }}
            </option>
          </select>
        </div>
      </div>
      
    </div>
    <!-- Nombre -->
    <div class="row">
      <div class="mb-3 row">
        <label for="prefijoControlSelect" class="col-sm-4 col-form-label"><span
            *ngIf="esCampoVacio(this.descripcion.value)">*</span> Nombre: </label>
        <div class="col-sm-8">
          <input id="prefijoControlInput" type="text" class="form-control mb-2 me-sm-2"
            title="Campo obligatorio, debe ingresar un nombre del rubro." formControlName="descripcionFormControl"
            placeholder="###" />
        </div>
      </div>
    </div>
    <!-- Combustible -->
    <div class="row">
      <div class="col-sm-3">
        <div class="mb-3 row">
          <div class="col-sm-8">
            <div class="form-check">
              <input type="radio" class="form-check-input" id="exampleCheck1" name="combustibleFormControl"
                formControlName="combustibleFormControl" value="combustible"/>
              <label class="form-check-label" for="exampleCheck1">Combustible</label>
            </div>
          </div>
        </div>
      </div>
      <!--Aerocombustible-->
      <div class="col-sm-3">
        <div class="mb-3 row">
          <div class="col-sm-8">
            <div class="form-check">
              <input type="radio" class="form-check-input" id="exampleCheck2" name="combustibleFormControl"
                formControlName="combustibleFormControl" value="aerocombustible"/>
              <label class="form-check-label" for="exampleCheck2">Aerocombustible</label>
            </div>
          </div>
        </div>
      </div>
      <!--Aeroespecialidad-->
      <div class="col-sm-3">
        <div class="mb-3 row">
          <div class="col-sm-8">
            <div class="form-check">
              <input type="radio" class="form-check-input" id="exampleCheck3" name="combustibleFormControl"
                formControlName="combustibleFormControl" value="aeroespecialidad"/>
              <label class="form-check-label" for="exampleCheck3">Aeroespecialidad</label>
            </div>
          </div>
        </div>
      </div>
      <!--Servicios-->
      <div class="col-sm-3">
        <div class="mb-3 row">
          <div class="col-sm-8">
            <div class="form-check">
              <input type="radio" class="form-check-input" id="exampleCheck4" name="combustibleFormControl"
                formControlName="combustibleFormControl" value="servicios"/>
              <label class="form-check-label" for="exampleCheck4">Servicios</label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <small class="text-danger">*Campos obligatorios.</small>

    <div class="modal-footer d-flex justify-content-center">
      <span class="border">
        <button class="btn btn-primary" type="button" (click)="crearRubro()">
          <span>Guardar</span>
        </button>
      </span>
      <span class="border">
        <button type="button" class="btn btn-secondary" (click)="activeModal.dismissAll()">Cancelar</button>
      </span>
    </div>
  </form>
</div>
<!-- </div> -->
