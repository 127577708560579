<div class="modal-header bg-light">
  <h5 class="modal-title ">Nueva transferencia</h5>
  <button type="button" class="btn-close" aria-label="Close" data-dismiss="modal" (click)="activeModal.dismissAll()"></button>
</div>
<div class="modal-body" [formGroup]="trasvaseForm">
  <!-- origen movimiento-->
  <div class="mb-3 col-12">
    <div class="mb-3 row">
      <label for="origenFormControl" id="origenFormControl" class="col-4 col-form-label">*Origen:</label>
      <label for="destinoAjusteFormControl" id="destinoAjusteFormControl" class="col-sm-6 col-form-label"
        style="display: none;">*Destino:</label>
      <div class="col-8">
        <div class="input-group mb-2">
          <select id="origenFormControl" type="text" class="form-select"
            title="Seleccione el origen desde el cual enviar el combustible." formControlName="origenFormControl"
            (change)="filtrarContenedorDestino()">
            <option [ngValue]="conten" *ngFor="let conten of contenedorOrigen">
              {{conten.nombre}}
            </option>
          </select>
        </div>
        <loading-text-spinner *ngIf="obteniendoContenedores" [error]="errorDestinos"
          message="Obteniendo contenedores..." color="primary">
        </loading-text-spinner>
      </div>
    </div>
  </div>
  <!-- destino movimiento-->
  <div class="mb-3 col-12">
    <div class="mb-3 row">
      <label for="destinoFormControl" id="labelDestino" class="col-4 col-form-label">*Destino:</label>
      <div class="col-8">
        <div class="input-group mb-2">
          <select id="destinoFormControl" type="text" class="form-select"
            title="Seleccione el destino al cual enviar el combustible." formControlName="destinoFormControl">
            <option [ngValue]="cont" *ngFor="let cont of contenedorDestinoFiltrado">
              {{cont.nombre}}
            </option>
          </select>
        </div>
        <loading-text-spinner *ngIf="obteniendoContenedores" [error]="errorDestinos"
          message="Obteniendo contenedores..." color="primary">
        </loading-text-spinner>
      </div>
    </div>
  </div>

  <ng-container *ngIf="!this.obteniendoContenedores && this.origenFormControl.value.tipoContenedor == 4">
    <div class="mb-3 col-12 mt-3">
      <input id="chkSinAforadores" type="checkbox" (click)="usuarioPulsaCheckSinAforadores()"/> 
      <label for="chkSinAforadores" class="col-form-label"> Trasvase sin aforadores</label>
    </div>  
  </ng-container>
  
  <!-- Pico-->
  <ng-container *ngIf="!this.obteniendoContenedores">
    <div class="mb-3 col-12"
      *ngIf="this.origenFormControl.value.tipoContenedor == 4 && !this.ocultarAforadores  && !this.obteniendoContenedores">
      <div class="mb-3 row">
        <label for="picoInicialFormControl" id="labelCantidad" class="col-4 col-form-label">*Aforador utilizado:</label>
        <div class="col-8">
          <select class="form-select" formControlName="picoInicialFormControl" (change)="usuarioSeleccionoPico($event)">
            <option [value]="null">
              Seleccione un aforador.
            </option>
            <option *ngFor="let p of this.origenFormControl.value.picos" [value]="p.id">
              {{p.nombre}}
            </option>
          </select>
        </div>
      </div>
    </div>
  </ng-container>
  <!-- Aforador Inicial-->
  <ng-container *ngIf="!this.obteniendoContenedores">
    <div class="mb-3 col-12"
      *ngIf="this.origenFormControl.value.tipoContenedor == 4 && !this.ocultarAforadores  && !this.obteniendoContenedores">
      <div class="mb-3 row">
        <label for="aforadorInicialFormControl" id="labelCantidad" class="col-4 col-form-label">*Aforador
          inicial:</label>
        <div class="col-8">
          <input class="form-control" formControlName="aforadorInicialFormControl"
            (keypress)="this.onlyDecimalPositivo($event)" (blur)="calcularCantidad()">
        </div>
      </div>
    </div>
  </ng-container>
  <!-- Aforador Final-->
  <ng-container *ngIf="!this.obteniendoContenedores">
    <div class="mb-3 col-12"
      *ngIf="this.origenFormControl.value.tipoContenedor == 4 && !this.ocultarAforadores  && !this.obteniendoContenedores">
      <div class="mb-3 row">
        <label for="aforadorFinalFormControl" id="labelCantidad" class="col-4 col-form-label">*Aforador final:</label>
        <div class="col-8">
          <input class="form-control" formControlName="aforadorFinalFormControl"
            (keypress)="this.onlyDecimalPositivo($event)" (blur)="calcularCantidad()">
            <div class="alert alert-danger letra" *ngIf="!validarAforador()">
              El aforador final debe ser mayor que el inicial.
            </div>
        </div>
      </div>
    </div>
  </ng-container>
  <!-- CANTIDAD-->
  <div class="mb-3 col-12">
    <div class="mb-3 row">
      <label for="cantidadFormControl" id="labelCantidad" class="col-4 col-form-label">*Cantidad:</label>
      <div class="col-8">
        <input class="form-control" formControlName="cantidadFormControl" (keypress)="this.onlyDecimalPositivo($event)"
          (blur)="recalcularVolumenNatural()"
          [readOnly]="!this.obteniendoContenedores && (this.origenFormControl.value.tipoContenedor == 4 && !this.ocultarAforadores)">
      </div>
    </div>
  </div>
  <!--TEMPERATURA-->
  <div class="mb-3 col-12">
    <div class="mb-3 row">
      <label for="temperaturaFormControl" id="labelDensidad" class="col-4 col-form-label">*Temperatura:</label>
      <div class="col-8">
        <input class="form-control" formControlName="temperaturaFormControl"
          (blur)="recalcularVolumenNatural()">
          <div *ngIf="temperaturaFormControl.invalid && temperaturaFormControl.errors.required &&
            (temperaturaFormControl.dirty || temperaturaFormControl.touched)" class="alert alert-danger">Requerido
          </div>
          <div class="alert alert-danger"
            *ngIf="temperaturaFormControl.invalid && (temperaturaFormControl.errors.temperaturaFueraDeRango) && (temperaturaFormControl.dirty || temperaturaFormControl.touched)">
            La temperatura esta fuera de rango permitido.
          </div>
      </div>
    </div>
  </div>
  <!--DENSIDAD-->
  <div class="mb-3 col-12">
    <div class="mb-3 row">
      <label for="densidadFormControl" id="labelDensidad" class="col-4 col-form-label">*Densidad:</label>
      <div class="col-8">
        <input class="form-control" formControlName="densidadFormControl" (keypress)="formatDensidad($event)"
          (input)="recalcularVolumenNatural()">        
      </div>
    </div>
  </div>
  <!--VOLUMEN 15°-->
  <div class="mb-3 col-12">
    <div class="mb-3 row">
      <label for="volumen15FormControl" id="labelDensidad" class="col-4 col-form-label">Volumen 15°:</label>
      <div class="col-8">
        <input class="form-control" formControlName="volumen15FormControl">
      </div>
    </div>
  </div>
</div>
<div class="modal-footer bg-light">
  <div class="alert alert-danger" *ngIf="error != null">
    {{ error }}
  </div>
  <div class="mb-3 col-12">
    <div class="d-flex justify-content-md-center">
      <button class="btn btn-primary me-2" id="aceptar" [disabled]="!trasvaseForm.valid || guardandoMovimiento"
        (click)="guardarTrasvase()">
        <span *ngIf="!guardandoMovimiento">Guardar</span>
        <div *ngIf="guardandoMovimiento" class="d-flex align-items-center">
          <div class="spinner-border text-light spinner-border-sm" role="status"></div>
          <span>Guardando...</span>
        </div>
      </button>
      <button class="btn btn-secondary" id="cancelar" (click)="this.activeModal.dismissAll()"
        [disabled]="guardandoMovimiento" data-dismiss="modal">Cancelar</button>
    </div>
  </div>
</div>