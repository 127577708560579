import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, ObservableLike, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { Producto } from '../shared/models/despacho/Producto';
import { HttpErrorHandler } from '../http-error-handler.service';
import { AuthService } from '../core/authentication/auth.service';
import { Injectable } from '@angular/core';
import { Contenedor } from '../shared/models/stock/Contenedor';
import { Movimiento } from '../shared/models/stock/Movimiento';
import { StockPorContenedores } from '../shared/models/stock/StockPorContenedor';
import { AjusteManual } from '../shared/models/stock/AjusteManual';
import { DetalleStockPorContenedor } from '../shared/models/stock/DetalleStockPorContenedor';
import { Rebombeo } from '../shared/models/stock/Rebombeo';
import { Transferencia } from '../shared/models/stock/Transferencia';
import { ContenedorAbastecedora } from 'src/app/shared/models/stock/ContenedorAbastecedora';
import { ContenedorTanque } from '../shared/models/stock/ContenedorTanque';
import { Producto as ProductoStock } from '../shared/models/stock/Producto';
import { StockAeroespecialidad } from '../shared/models/stock/StockAeroespecialidad';
import { Terminal } from '../shared/models/stock/Terminal';
import { TanqueGeneral } from '../shared/models/stock/TanqueGeneral';
import { Ducto } from '../shared/models/stock/Ducto';
import { IngresoDucto } from '../shared/models/stock/IngresoDucto';
import { Transportista } from '../shared/models/stock/Transportista';
import { Conductor } from '../shared/models/stock/Conductor';
import { RemitoSalida } from '../shared/models/stock/RemitoSalida';
import { Cano } from '../shared/models/stock/Cano';
import { Aeroplanta } from '../shared/models/despacho/Aeroplanta';
import { AerovalesService } from '../despacho/aerovales.service';
import { ReporteMovimiento } from '../shared/models/cierreTurno/ReporteMovimiento';
import { CierreTurno } from '../shared/models/cierreTurno/CierreTurno';
import { MovimientoAforador } from '../shared/models/cierreTurno/MovimientoAforador';
import { Surtidor } from '../shared/models/stock/Surtidor';
import { Transaccion } from '../shared/models/stock/Transaccion';
import { ReimputarMovimiento } from '../shared/models/cierreTurno/ReimputarMovimiento';
import { Expendedora } from '../shared/models/stock/Expendedora';
import {Aeroplanta as AeroplantaStock} from '../shared/models/stock/Aeroplanta'
import { TipoBlobStorage } from '../shared/enums/TipoBlobStorage';
import { Sas } from '../shared/models/padrones/Sas';
import { EquipoFijo } from '../shared/models/stock/EquipoFijo';
import { EstadoEquipo } from '../shared/models/stock/EstadoEquipo';
import { SistemaSeguridad } from '../shared/models/stock/SistemaSeguridad';
import { EstadoSistemaSeguridad } from '../shared/models/stock/EstadoSistemaSeguridad';
import { Pedido } from '../shared/models/stock/Pedido';
import { UsuarioService } from '../account/usuario.service';
import { UltimoEstado } from '../shared/models/stock/UltimoEstado';
import { EstadoServicio } from '../shared/models/stock/EstadoServicio';
import { TipoExpendedora } from '../shared/enums/tipoExpendedora';

@Injectable({
  providedIn: 'root'
})
export class StockService {
  [x: string]: any;

  movimientobaseUrl = environment.apiServer.stockBaseUrl;
  private productoURL = this.productobaseUrl + '/ProductosV1';
  stockbaseUrl = environment.apiServer.stockBaseUrl;
  private contenedoresUrl = this.stockbaseUrl + '/ContenedoresV1';
  private surtidoresUrl = this.stockbaseUrl + '/SurtidoresV1';
  private stockContenedores = this.stockbaseUrl + '/MovimientosV1/StockContenedores';
  private DetalleMovimiento = this.stockbaseUrl + '/MovimientosV1/';

  private movimientosURL = this.movimientobaseUrl + '/MovimientosV1';
  private ajusteManualUrl = this.movimientosURL + '/AjusteManual';
  private rebombeoUrl = this.movimientosURL + '/Rebombeo';
  private transferenciaUrl = this.movimientosURL + '/TransferenciaStock';
  private terminalesUrl = this.stockbaseUrl + '/TerminalesV1/Terminales';
  private ingresoDuctoUrl = this.stockbaseUrl + '/TransaccionesV1/recepcionDucto';
  private ingresoDuctoV2Url = this.stockbaseUrl + '/TransaccionesV2/recepcionDucto';
  private transportistasUrl = this.stockbaseUrl + '/TerminalesV1/Transportistas';
  private conductoressUrl = this.stockbaseUrl + '/TerminalesV1/Conductores';
  private remitoSalidaUrl = this.stockbaseUrl + '/TransaccionesV1/salida';
  private pedidosUrl = this.stockbaseUrl + '/PedidosV1';
  private aeroplantasUrl = this.stockbaseUrl + '/AeroplantasV1';

  reporteMovimientos: ReporteMovimiento[] = [];
  pedidosFake: Pedido;

  constructor(
    private httpClient: HttpClient, 
    private httpErrorHandler: HttpErrorHandler,
    private usuarioService: UsuarioService,    
    private authservice: AuthService) {
    // this.reporteMovimientos = this.getMovimientos();
  }

  getContenedores(aeroplantaCodActual: string): Observable<Contenedor> {
    return this.httpClient.get<Contenedor>(this.contenedoresUrl + '/' + aeroplantaCodActual)
  }

  ingresarRebombeo(rebom: Rebombeo): Observable<string> {
    return this.httpClient.post<string>(this.rebombeoUrl, rebom)
      .pipe(
        tap(_ => console.log(`fetched numero= ${rebom.cantidad}`)),
        catchError(this.handleError)
      );
  }

  ingresarTransferencia(trans: Transferencia): Observable<string> {
    return this.httpClient.post<string>(this.transferenciaUrl, trans)
      .pipe(
        tap(_ => console.log(`fetched numero= ${trans.numeroRemito}`)),
        catchError(this.handleError)
      );
  }



  ingresarAjusteManual(ajuste: AjusteManual): Observable<string> {
    return this.httpClient.post<string>(this.ajusteManualUrl, ajuste)
      .pipe(
        tap(_ => console.log(`fetched numero= ${ajuste.nroRemitoCargamento}`)),
        catchError(this.handleError)
      );
  }


  getStockContenedores(aeroplantaId: string): Observable<StockPorContenedores[]> {
    return this.httpClient.get<StockPorContenedores[]>(this.stockContenedores + '?codigoAeroplanta=' + aeroplantaId);
  }

  getDetalleProducto(aeroplantaCod: string, codProducto: string): Observable<DetalleStockPorContenedor[]> {
    return this.httpClient.get<DetalleStockPorContenedor[]>(this.DetalleMovimiento + aeroplantaCod + '/DetalleStockContenedores/' + codProducto)
  }

  getTanques(id: string) {
    return this.httpClient.get<ContenedorTanque>(this.contenedoresUrl + '/Tanque/' + id);
  }

  getContenedorAbastecedora(abastecedoraId: string): Observable<ContenedorAbastecedora> {
    return this.httpClient.get<ContenedorAbastecedora>(this.contenedoresUrl + '/Abastecedora/' + abastecedoraId)
  }

  getSurtidores(codigoAeroplanta: string): Observable<Surtidor[]> {
    // Hay que traer todos los surtidores sin importar aeroplanta.
    var parametro = "";
    if(codigoAeroplanta != null){
      parametro = '?codigoAeroplanta=' + codigoAeroplanta;
    }
    return this.httpClient.get<Surtidor[]>(this.stockbaseUrl + '/AeroplantasV1/Surtidores' + parametro);
  }

  getDetalleMovimiento(idMovimiento: string): Observable<Movimiento> {
    return this.httpClient.get<Movimiento>(this.movimientosURL + '/' + idMovimiento);
  }
  getTodosLosContenedores(codigoAeroplanta: string): Observable<Contenedor[]> {
    return this.httpClient.get<Contenedor[]>(this.stockbaseUrl + '/AeroplantasV1/' + codigoAeroplanta + '/Contenedores');
  }

  getTodosLosProductosStock(): Observable<ProductoStock[]> {
    return this.httpClient.get<ProductoStock[]>(this.productoURL)
      .pipe(
        tap(_ => console.log('getTodosLosProductosStock')),
        catchError(this.handleError)
      );
  }

  getConversionLitrosPorVarillado(codigoTanque: string, varillado: string): Observable<string> {
    return this.httpClient.get<string>(this.contenedoresUrl + "/Tanques/" + codigoTanque + "/Nivel/" + varillado)
      .pipe(
        tap(_ => console.log('Conversión litros por varillado')),
        catchError(this.handleError)
      );
  }


  getAeroespecialidades(aeroplantaCod: string): Observable<StockAeroespecialidad[]> {
    return this.httpClient.get<StockAeroespecialidad[]>(this.stockbaseUrl + '/AeroplantasV1/' + aeroplantaCod + '/StockAeroespecialidades')
      .pipe(
        tap(_ => console.log('Obtencion de aeroespecialidades.')),
        catchError(this.handleError)
      );
  }

  convertirProducto(p: Producto) {
    let prod = new ProductoStock();
    //prod.id = p.id;
    prod.codigoProducto = p.codigoProducto;
    prod.nombreProducto = p.nombreProducto;
    prod.unidadMedida = Number(p.unidadMedida);
    return prod;
  }

  //#region conversion de volumen en 15 grados

  validarDensidad(): boolean {
    let esValido = true;
    esValido = (this.densidad.value >= 0.665 && this.densidad.value <= 0.840);
    return esValido;
  }

  getTerminales(): Observable<Terminal[]> {
    return this.httpClient.get<Terminal[]>(this.terminalesUrl)
      .pipe(
        tap(_ => console.log('getTerminales')),
        catchError(this.handleError)
      );
  }

  getTerminalesPromise(): Promise<Terminal[]> {
    return this.httpClient.get<Terminal[]>(this.terminalesUrl).toPromise();      
  }

  getTanquesGenericos(codAeroplanta): Observable<TanqueGeneral[]> {
    return this.httpClient.get<TanqueGeneral[]>(this.stockbaseUrl + "/AeroplantasV1/" + codAeroplanta + "/Tanques")
      .pipe(
        tap(_ => console.log('getTanquesGenericos')),
        catchError(this.handleError)
      );
  }

  getDuctos(codAeroplanta): Observable<Ducto[]> {
    return this.httpClient.get<Ducto[]>(this.stockbaseUrl + "/AeroplantasV1/" + codAeroplanta + "/Ductos")
      .pipe(
        tap(_ => console.log('getDuctos')),
        catchError(this.handleError)
      );
  }

  ingresoPorDucto(dto: IngresoDucto, fileRemitoFisico): Observable<string> {
    let input = new FormData();
    input.append('remitoFisico', fileRemitoFisico ? fileRemitoFisico : null)
    input.append('recepcionDTO', JSON.stringify(dto));

    return this.httpClient.post<string>(this.ingresoDuctoUrl, input)
      .pipe(
        tap(_ => console.log("POST ingresoPorDucto")),
        catchError(this.handleError)
      );
  }

  ingresoPorDuctoV2(dto: IngresoDucto): Observable<string> {
    return this.httpClient.post<string>(this.ingresoDuctoV2Url, dto);      
  }

  getTransportistas(): Observable<Transportista[]> {
    return this.httpClient.get<Transportista[]>(this.transportistasUrl)
      .pipe(
        tap(_ => console.log('getTransportistas')),
        catchError(this.handleError)
      );
  }

  getConductores(): Observable<Conductor[]> {
    return this.httpClient.get<Conductor[]>(this.conductoressUrl)
      .pipe(
        tap(_ => console.log('getConductores')),
        catchError(this.handleError)
      );
  }


  emisionRemitoSalida(dto: RemitoSalida): Observable<string> {
    return this.httpClient.post<string>(this.remitoSalidaUrl, dto);
  }

  getCanerias(aeroplantaCod: string): Observable<Cano[]> {
    return this.httpClient.get<Cano[]>(this.stockbaseUrl + '/AeroplantasV1/' + aeroplantaCod + '/Canos')
      .pipe(
        tap(_ => console.log('Get Caños')),
        catchError(this.handleError)
      );
  }

  crearCaneria(c: Cano): Observable<Cano> {
    return this.httpClient.post<Cano>(this.stockbaseUrl + '/AeroplantasV1/Cano', c)
      .pipe(
        tap(_ => console.log('POST Caños')),
        catchError(this.handleError)
      );
  }

  editarCaneria(c: Cano): Observable<Cano> {
    return this.httpClient.put<Cano>(this.stockbaseUrl + '/AeroplantasV1/Cano', c)
      .pipe(
        tap(_ => console.log('PUT Caños')),
        catchError(this.handleError)
      );
  }

  crearEquipoFijo(codigoAeroplanta: string, e: EquipoFijo): Observable<EquipoFijo> {
    return this.httpClient.post<EquipoFijo>(this.stockbaseUrl + '/EquiposFijosV1/' + codigoAeroplanta, e)
      .pipe(
        tap(_ => console.log('POST EquipoFijo')),
        catchError(this.handleError)
      );
  }

  obtenerEquiposFijos(codigoAeroplanta: string): Observable<EquipoFijo[]> {
    return this.httpClient.get<EquipoFijo[]>(this.stockbaseUrl + '/AeroplantasV1/' + codigoAeroplanta + '/EquiposFijos')
      .pipe(
        tap(_ => console.log('GET EquipoFijo')),
        catchError(this.handleError)
      );
  }

  cambiarEstadoEquipo(equipoFijoId: string, e: EstadoEquipo): Observable<EstadoEquipo> {
    return this.httpClient.post<EstadoEquipo>(this.stockbaseUrl + '/EquiposFijosV1/' + equipoFijoId + '/AdicionarEstado', e)
      .pipe(
        tap(_ => console.log('POST EstadoEquipo')),
        catchError(this.handleError)
      )
  }

  editarEquipoFijo(equipoFijoId: string, e: EquipoFijo): Observable<EquipoFijo> {
    return this.httpClient.put<EquipoFijo>(this.stockbaseUrl + '/EquiposFijosV1/' + equipoFijoId, e)
    .pipe(
      tap(_ => console.log('PUT EquipoFijo')),
      catchError(this.handleError)
    )
  }

  obtenerSistemasSeguridad(codigoAeroplanta: string): Observable<SistemaSeguridad[]> {
    return this.httpClient.get<SistemaSeguridad[]>(this.stockbaseUrl + '/AeroplantasV1/' + codigoAeroplanta + '/SistemasSeguridad')
      .pipe(
        tap(_ => console.log('GET SistemaSeguridad')),
        catchError(this.handleError)
      );
  }

  crearSistemaSeguridad(codigoAeroplanta: string, s: SistemaSeguridad): Observable<SistemaSeguridad> {
    return this.httpClient.post<SistemaSeguridad>(this.stockbaseUrl + '/SistemasSeguridadV1/' + codigoAeroplanta, s)
      .pipe(
        tap(_ => console.log('POST SistemaSeguridad')),
        catchError(this.handleError)
      );
  }

  cambiarEstadoSistemaSeguridad(sistemaId: string, e: EstadoSistemaSeguridad): Observable<SistemaSeguridad> {
    return this.httpClient.post<SistemaSeguridad>(this.stockbaseUrl + '/SistemasSeguridadV1/' + sistemaId + '/AdicionarEstado', e)
      .pipe(
        tap(_ => console.log('POST SistemaSeguridad')),
        catchError(this.handleError)
      )
  }

  editarSistemaSeguridad(sistemaId: string, s: SistemaSeguridad): Observable<SistemaSeguridad> {
    return this.httpClient.put<SistemaSeguridad>(this.stockbaseUrl + '/SistemasSeguridadV1/' + sistemaId, s)
    .pipe(
      tap(_ => console.log('PUT SistemaSeguridad')),
      catchError(this.handleError)
    )
  }

  cambiarEstadoServicioTanque(codigoContenedor: string, es: EstadoServicio): Observable<EstadoServicio> {
    return this.httpClient.post<EstadoServicio>(this.contenedoresUrl + `/Tanques/EstadoServicio/${codigoContenedor}`, es)
      .pipe(
        tap(_ => console.log('POST EstadoServicioTanques')),
        catchError(this.handleError)
      )
  }

  cambiarEstadoServicioExpendedora(codigoContenedor: string, es: EstadoServicio, tipo:TipoExpendedora): Observable<EstadoServicio> {
    const url = tipo==TipoExpendedora.ABASTECEDORA_CAMION ? `${this.contenedoresUrl}/Abastecedoras/EstadoServicio` : `${this.surtidoresUrl}/EstadoServicio`
    return this.httpClient.post<EstadoServicio>(`${url}/${codigoContenedor}`, es)
      .pipe(
        tap(_ => console.log('POST EstadoServicioExpendedoras')),
        catchError(this.handleError)
      )
  }

  cambiarEstadoTelemedicionTanque(codigoContenedor: string, es: EstadoServicio): Observable<EstadoServicio> {
    return this.httpClient.post<EstadoServicio>(this.contenedoresUrl + `/EstadoTelemedicion/${codigoContenedor}`, es)
      .pipe(
        tap(_ => console.log('POST EstadoTelemedicionTanques')),
        catchError(this.handleError)
      )
  }

  verificarTipoMedicionTanque(codigoTanque: string, tipoMedicionEsVacio: boolean): Observable<any> {
    return this.httpClient.get<any>(this.contenedoresUrl + `/Tanques/ValidarTipoMedicion?codigoTanque=${codigoTanque}&tipoMedicionVacio=${tipoMedicionEsVacio}` ).pipe(
        tap(_ => console.log('GET VerificarTipoMedicionTanque')),
        catchError(this.handleError)
    )
  }

  putAeroplanta(aep: Aeroplanta): Observable<Aeroplanta> {
    return this.httpClient.put<Aeroplanta>(this.stockbaseUrl + '/AeroplantasV1/' + aep.id, aep);
  }

  getAeroplantas(codigoAeroplanta: string): Observable<Aeroplanta[]> {
    if (codigoAeroplanta != null) {
      return this.httpClient.get<Aeroplanta[]>(this.stockbaseUrl + '/AeroplantasV1?codigoAeroplanta=' + codigoAeroplanta);
    } else {
      return this.httpClient.get<Aeroplanta[]>(this.stockbaseUrl + '/AeroplantasV1');
    }
  }

  getMovimientos(cierre: CierreTurno): Observable<ReporteMovimiento[]> {
    return this.httpClient.get<ReporteMovimiento[]>(this.stockbaseUrl + '/ReportesV1/CierreTurno/' + cierre.id + '/ReporteMovimientos')
  }
  
  getMovimientosAsync(cierre: CierreTurno): Promise<ReporteMovimiento[]> {
    return this.httpClient.get<ReporteMovimiento[]>(this.stockbaseUrl + '/ReportesV1/CierreTurno/' + cierre.id + '/ReporteMovimientos').toPromise();
  }

  getReporteMovimientoProducto(cierre: CierreTurno, aeroplantaCod: string): Observable<ReporteMovimiento[]> {
    return this.httpClient.get<ReporteMovimiento[]>(this.stockbaseUrl + '/ReportesV1/CierreTurno/ReporteMovimientos/Productos?cierreTurnoId=' + cierre.id + '&fechaCierre=' + cierre.fechaHora + '&codigoAeroplanta=' + aeroplantaCod);
  }

  getReporteMovimientoProductoAsync(cierre: CierreTurno, aeroplantaCod: string): Promise<ReporteMovimiento[]> {
    return this.httpClient.get<ReporteMovimiento[]>(this.stockbaseUrl + '/ReportesV1/CierreTurno/ReporteMovimientos/Productos?cierreTurnoId=' + cierre.id + '&fechaCierre=' + cierre.fechaHora + '&codigoAeroplanta=' + aeroplantaCod).toPromise();
  }

  getReporteCorrelatividad(cierre: CierreTurno, aeroplantaCod: string): Observable<MovimientoAforador[]> {
    return this.httpClient.get<MovimientoAforador[]>(this.stockbaseUrl + '/ReportesV1/CierreTurno/' + cierre.id + '/CorrelatividadAforadores/' + aeroplantaCod);
  }
  
  getReporteCorrelatividadAsync(cierre: CierreTurno, aeroplantaCod: string): Promise<MovimientoAforador[]> {
    return this.httpClient.get<MovimientoAforador[]>(this.stockbaseUrl + '/ReportesV1/CierreTurno/' + cierre.id + '/CorrelatividadAforadores/' + aeroplantaCod).toPromise();
  }

  getMovimientosDeUnCierreTurno(id: string, contenedor: string): Observable<Transaccion> {
    return this.httpClient.get<Transaccion>(this.stockbaseUrl + '/ContenedoresV1/CierreTurno?codigoContenedor=' + contenedor + '&cierreTurnoId=' + id);
  }

  reimputarDocumentoCierreDeTurno(reimputacion: ReimputarMovimiento): Observable<ReimputarMovimiento> {
    return this.httpClient.put<ReimputarMovimiento>(this.stockbaseUrl + '/MovimientosV1/ReimputarMovimiento?movimientoId=' + reimputacion.movimientoId +
      '&fecha=' + reimputacion.fecha + '&cierreTurnoId=' + reimputacion.cierreTurnoId, reimputacion);
  }

  getExpendedorasPorAeroplanta(codigoAeroplanta){
    return this.httpClient.get<Expendedora[]>(this.stockbaseUrl + "/AeroplantasV1/"+codigoAeroplanta+"/Expendedoras");
  }

  // Arriba hay un get que mete aeroplanta del modelo de despacho. Por eso meto esta otra llamada para obtener de stock. Tiene mas datos, pero traigo solo lo que necesito por ahora.
  obtenerAeroplantasStock(): Observable<AeroplantaStock[]> {
    return this.httpClient.get<AeroplantaStock[]>(this.stockbaseUrl + '/AeroplantasV1');
  }

  getStockContenedoresPromise(aeroplantaId: string): Promise<StockPorContenedores[]> {
    return this.httpClient.get<StockPorContenedores[]>(this.stockContenedores + '?codigoAeroplanta=' + aeroplantaId).toPromise();
  }

  getCaneriasPromise(aeroplantaCod: string): Promise<Cano[]> {
    return this.httpClient.get<Cano[]>(this.stockbaseUrl + '/AeroplantasV1/' + aeroplantaCod + '/Canos').toPromise();
  }
  
  obtenerSas(blob:number, firmas:boolean): Observable<Sas> {
    if (this.sas) {
        const decodedUri = decodeURIComponent(this.sas.sas);
        const fechaSas = new Date(decodedUri.substr(decodedUri.indexOf('&se=') + 4, 20));
        if (fechaSas > new Date())
            return of(this.sas);
    }
    const ruta = this.stockbaseUrl + '/ConfiguracionesV1/SAS?blobContainerName='+TipoBlobStorage[blob]+"&firmas="+firmas;
    return this.httpClient.get<Sas>(ruta)
      .pipe(
        tap((sas: Sas) => this.sas = sas)
      );
  }

  getPedidos(codigoAeroplanta:string, fechaInicial:string, fechaFinal:string):Promise<Pedido[]> {
    return this.httpClient.get<Pedido[]>(`${this.aeroplantasUrl}/${codigoAeroplanta}/${fechaInicial}/${fechaFinal}/Pedidos`).toPromise();    
  }

  getPedido(idPedido:string):Promise<Pedido[]> {
    return this.httpClient.get<Pedido[]>(`${this.pedidosUrl}/${idPedido}`).toPromise();    
  }

  postPedidos(codigoAeroplanta:string, body:Pedido):Promise<Pedido> {
    return this.httpClient.post<Pedido>(`${this.pedidosUrl}/${codigoAeroplanta}`, body).toPromise();    
  }

  putPedidos(idPedido:string, body:Pedido):Promise<Pedido> {
    return this.httpClient.put<Pedido>(`${this.pedidosUrl}/${idPedido}`, body).toPromise();    
  }

  postPedidosAdicionarEstado(idPedido:string, body:UltimoEstado):Promise<Pedido> {
    return this.httpClient.post<Pedido>(`${this.pedidosUrl}/${idPedido}/AdicionarEstado`, body).toPromise();    
  }

  obtenerStringResponsable(){
    return '{"Email": "'+this.usuarioService.getEmailUsuario()+'" ,' + '"nombresCompletos": "'+this.usuarioService.getNombresCompletosUsuario()+'" ,' + '"dni": "'+this.usuarioService.getDniUsuario()+'" ,' + '"Id": "'+this.usuarioService.getIdUsuario()+'"}';
  }

  /*
  getPedidosFake():Promise<Pedido[]> {
    return this.httpClient.get<Pedido[]>('https://637fc0378efcfcedacf6e8c9.mockapi.io/cierreTurno/Pedidos').toPromise();
  }  

  postPedidosFake($mockData):Promise<Pedido[]> {
    return this.httpClient.post<Pedido[]>('https://637fc0378efcfcedacf6e8c9.mockapi.io/cierreTurno/Pedidos', $mockData).toPromise();
  }  

  putPedidosFake(id, $mockData):Promise<Pedido[]> {
    return this.httpClient.put<Pedido[]>('https://637fc0378efcfcedacf6e8c9.mockapi.io/cierreTurno/Pedidos/'+id, $mockData).toPromise();
  }  
  */
}