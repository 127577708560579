import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Guid } from 'guid-typescript';
import { TipoMedicion } from '../../enums/TipoMedicion';
import { Medicion } from './Medicion';
import { Producto } from './Producto';
import { TanqueGeneral } from './TanqueGeneral';

export class Tanque extends TanqueGeneral{
    aeroplantaCodigo: string;
    tipoTanque: number;
    formaTanque: number;
    numeroCertificado: string;
    volumenInextraible: number;
    toleranciaMaxima: number;
    minimoHorasReposo: number;
    tieneGraduacion: boolean;
    tipoMedicion: TipoMedicion;

    //atributos para las mediciones masivas.
    fechaProvisoria: NgbDateStruct;
    horaCarga: any;
    mediciones: Medicion;
  }