<ul class="nav nav-tabs justify-content-start">
  <li *ngIf="hasRole(TipoUsuario.TUTOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.SOPORTE)" class="nav-item">
    <a class="nav-link" routerLink="stock" routerLinkActive="active">Stock</a>
  </li>
  <li *ngIf="hasRole(TipoUsuario.TUTOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.SOPORTE, TipoUsuario.SOPORTE, TipoUsuario.RESPRODUCTO)" class="nav-item">
    <a class="nav-link" routerLink="informes" routerLinkActive="active">Informes</a>
  </li>
</ul>

<router-outlet></router-outlet>
