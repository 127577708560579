<div class="modal-header bg-light">
    <h4 class="modal-title">Historial de estado</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close(false)"></button>
</div>
<div class="modal-body">
    <div class="container">
        <table class="table table-striped">
            <thead>
                <tr>
                    <th style="width: 20%;">
                        <p class="text-center text-primary">
                            Estado
                        </p>
                    </th>

                    <th style="width: 20%;">
                        <p class="text-center text-primary">
                            Fecha
                        </p>
                    </th>

                    <th style="width: 20%;">
                        <p class="text-center text-primary">
                            Responsable
                        </p>
                    </th>

                    <th style="width: 40%;">
                        <p class="text-center text-primary">
                            Observaciones
                        </p>
                    </th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let estado of estados">
                    <th class="text-center">
                        <p *ngIf="estado.estadoCliente == EstadoCliente.Habilitado">Habilitado</p>
                        <p *ngIf="estado.estadoCliente == EstadoCliente.Bloqueado">Bloqueado</p>
                    </th>
                    <td class="text-center">
                        {{estado.fechaHora | date: 'short'}}
                    </td>
                    <td class="text-center">
                        {{estado.responsable.nombresCompletos}} <br>
                        <small>{{estado.responsable.email}}</small>
                    </td>
                    <td class="text-center">{{estado.observaciones}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>