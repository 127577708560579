<div class="modal-header bg-light">
  <h5 class="modal-title justify-content-center">Previsualización.</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="this.activeModal.close(this.emitida)">
    
  </button>
</div>
<ngx-spinner name="spinnerFactura" [ngStyle]="{'height': '400px', 'z-index':'0'}"
  [ngClass]="{'list-group-item list-group-item-action': obteniendoFactura && this.calcFact}" bdOpacity=0.3bd
  Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
  <p style="color: white"> O b t e n i e n d o D E T A L L E . . . </p>
</ngx-spinner>
<!--<div class="modal-body" *ngIf="!this.obteniendoFactura && this.facturaCalculada.length == 0">
  <div class="alert alert-danger">Hubo un error al calcular la factura. Por favor revise que tenga precios cargados y la cuenta del cliente.</div>
</div>-->
<div class="modal-body" *ngIf="!this.obteniendoFactura && this.facturaEmitida.length == 0">
  <div *ngFor="let factura of facturaCalculada; let t = index">
    <form [formGroup]="facturaAEmitir">
      <div class="mb-3 col-md-12">
        <div class=" mb-3 row">
          <div class="col-md-8  menu-izq">
            <app-visor-comprobante [facturaObtenida]="factura" [botones]="false"></app-visor-comprobante>
          </div>
          <div class="mb-3 col-md-4 bottom-align-text" *ngIf="t == 0">
            <p>Se va a emitir la factura visualizada.</p>
            <p>Al confirmar, va a facturar con los datos que se muestran al lado izquierdo.
              Reviselo cuidadosamente, puede cancelar el proceso.
            </p>
            <button class="btn btn-primary btn-sm me-2" id="aceptar" (click)="aceptarFactura()">
              <span *ngIf="!this.facturando">Facturar</span>
              <div class="d-flex align-items-center" *ngIf="this.facturando">
                <div class="spinner-border text-light spinner-border-sm" role="status"></div>
                <span>Facturando...</span>
              </div>
            </button>
            <button type="button" class="btn btn-secondary btn-sm me-2"
              (click)="this.activeModal.close(this.emitida)">Cancelar</button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div *ngIf="this.error != null" class="row col-md-12 alert alert-danger">
    {{this.error}} 
  </div>
</div>
<!--FACTURA YA CALCULADA-->
<div *ngIf="this.facturaEmitida.length > 0 && emitida">
  <div *ngFor="let facturaObtenida of facturaEmitida">
    <app-visor-comprobante [facturaObtenida]="facturaObtenida" [botones]="true"></app-visor-comprobante>
  </div>
</div>


<!-- <button class="btn btn-primary btn-sm me-2" id="aceptar" (click)="aceptarFactura()">
  <span *ngIf="!this.facturando">Facturar</span>
  <div class="d-flex align-items-center" *ngIf="this.facturando">
    <div class="spinner-border text-light spinner-border-sm" role="status"></div>
    <span>Facturando...</span>
  </div>
</button> -->