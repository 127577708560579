import { Component, OnInit, HostBinding, EventEmitter, Input, Output } from '@angular/core';
import { AerovalesService } from '../aerovales.service';
import { Aerovale } from 'src/app/shared/models/despacho/Aerovale';
import { NgxSpinnerService } from 'ngx-spinner';
import { AerovaleDetalle } from 'src/app/shared/models/despacho/AerovaleDetalle';
import { EstadoAerovale } from 'src/app/shared/enums/estadoAerovale';
import { UsuarioService } from 'src/app/account/usuario.service';
import { UntypedFormControl, AbstractControl } from '@angular/forms';
import { NgbDateStruct, NgbCalendar, NgbModal, NgbDatepickerConfig, NgbTimeStruct, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/shared/shared.service';
import { fadeAnimationTrigger } from 'src/app/shared/animations';
import { DatePipe } from "@angular/common";
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { ModalAnulacionAerovaleComponent } from '../modal-anulacion-aerovale/modal-anulacion-aerovale.component';
import { MessageService } from 'src/app/shell/message.service';
import { SeleccionTipoAerovaleComponent } from '../aerovales/seleccion-tipo-aerovale/seleccion-tipo-aerovale.component';
import { TipoAerovale } from 'src/app/shared/enums/tipoAerovale';
import { Comprobante } from 'src/app/shared/models/facturacion/Comprobante';
import { Remito } from 'src/app/shared/models/facturacion/Remito';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { ComprobanteService } from 'src/app/facturacion/comprobante.service';
import { ImporteFacturaComponent } from 'src/app/shared/importe-factura/importe-factura.component';
import { ImporteFactura } from 'src/app/shared/models/facturacion/ImporteFactura';
import { EstadoFactura } from 'src/app/shared/enums/EstadoFactura';
import { ModalOkComponent } from 'src/app/shared/generic-modals/modal-ok.component';
import { TipoPago } from 'src/app/shared/enums/tipoPago';
import { VisorRemitoAerovaleComponent } from '../visor-remito-aerovale/visor-remito-aerovale.component';
import { RemitoFox } from 'src/app/shared/models/despacho/RemitoFox';
import { ModalCreacionFoxComponent } from '../modal-creacion-fox/modal-creacion-fox.component';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { ModalConfirmComponent } from 'src/app/shared/generic-modals/modal-confirm.component';
import { AerovaleUpdatedCommonComponent } from '../aerovales-update/aerovale-updated-common/aerovale-updated-common.component';
import { AerovaleUpdatedAerocombustibleComponent } from '../aerovales-update/aerovale-updated-aerocombustible/aerovale-updated-aerocombustible.component';
import { AerovaleUpdatedAlijeComponent } from '../aerovales-update/aerovale-updated-alije/aerovale-updated-alije.component';
import { EstadoAutorizacionAev } from 'src/app/shared/enums/EstadoAutorizacionAev';


@Component({
  selector: 'app-visor-aerovales',
  templateUrl: './visor-aerovales.component.html',
  styleUrls: ['./visor-aerovales.component.css'],
  animations: [fadeAnimationTrigger]
})

export class
  VisorAerovalesComponent implements OnInit {
  @Output() captureImage: EventEmitter<boolean> = new EventEmitter();

  @HostBinding('class.changed')

  aeroplantaPuedeFacturar: boolean = false;
  fileAerovaleFisico:File;
  tipoPago = TipoPago;
  TipoUsuario = TipoUsuario;

  importeFactura: ImporteFactura;

  baseUrl = environment.apiServer.baseUrl;
  aerovales: Aerovale[] = [];
  aerovalesFiltrados: Aerovale[] = [];
  aerovaleActual: Aerovale;
  aerovaleDetalle: AerovaleDetalle;
  fechaHasta = new UntypedFormControl();
  fechaFormControlDesdeHasta = new UntypedFormControl();
  mensajeErrorExportar = "";
  model: NgbDateStruct;
  model1: NgbDateStruct;
  time: NgbTimeStruct;
  startDate: Date;
  endDate: Date;
  buscando: boolean = false;
  mensajeSpinner: string = "D e s c a r g a n d o";
  obteniendoAerovales = true;
  obteniendoComprobante = true;
  procesandoExport: boolean = false;
  codigoAeroplanta: string;
  cambiandoAeroplanta: boolean = false;
  pendiente = { valor: "Pendiente", descripcion: "Pendiente", seleccionado: false };
  finalizado = { valor: "Finalizado", descripcion: "Finalizado", seleccionado: false };
  finalizadoSinFirma = { valor: "FinalizadoSinFirma", descripcion: "Finalizado sin firma", seleccionado: false };
  estadoSincronizado = { valor: "Sincronizado", descripcion: "Sincronizado", seleccionado: false };
  anulado = { valor: "Anulado", descripcion: "Anulado", seleccionado: false };

  aerovaleAerocombustible = { tipo: 0, valor: "AerovaleAerocombustible", descripcion: "Aerovale Aerocombustible", seleccionado: false };
  aerovaleAeroespecialidad = { tipo: 1, valor: "AerovaleAeroespecialidad", descripcion: "Aerovale Aeroespecialidad", seleccionado: false };
  aerovaleCombustible = { tipo: 2, valor: "AerovaleCombustible", descripcion: "Aerovale Combustible", seleccionado: false };
  aerovaleAelije = { tipo: 3, valor: "AerovaleAlije", descripcion: "Aerovale Alije", seleccionado: false };
  aerovaleDevolucion = { tipo: 5, valor: "AerovaleDevolucion", descripcion: "Aerovale Devolucion", seleccionado: false };
  tipos = [
    this.aerovaleAerocombustible, this.aerovaleAelije, this.aerovaleCombustible, this.aerovaleAeroespecialidad, this.aerovaleDevolucion,
  ];

  estados = [
    this.pendiente,
    this.finalizado,
    this.estadoSincronizado,
    this.anulado
  ];
  busquedaFormControl = new UntypedFormControl();
  change$: Subscription;
  errorAerovales: boolean = false;
  tipoNuevoAerovale: TipoAerovale;
  TipoAerovale = TipoAerovale;
  EstadoAerovale = EstadoAerovale;
  EstadoAutorizacionAev = EstadoAutorizacionAev;
  @Input() facturaObtenida: Comprobante;
  remito: Remito;
  mostrarBotonFacturarAerovale: boolean;
  botonAnular: boolean = false;
  verFactura: boolean = false;
  facturar: boolean = false;

  remitoObtenido: Comprobante;
  remitoFox: RemitoFox;
  buscandoComprobante: boolean = false;
  capturandoAevAnulado: boolean = false;
  //puedeFacturarAerovale: boolean = false;

  constructor(private datepickerConfig2: NgbDatepickerConfig,
    private messageService: MessageService,
    private aerovaleService: AerovalesService,
    private usuarioService: UsuarioService,
    private calendar: NgbCalendar,
    private spinner: NgxSpinnerService,
    private sharedService: SharedService,
    private modalService: NgbModal,
    private http: HttpClient,
    private datePipe: DatePipe,
    private modalAnulacion: NgbModal,
    private comprobanteService: ComprobanteService,
    public authService: AuthService) {
      this.change$ = sharedService.aeroplantaSeleccionada.subscribe(_=>{
        this.cambiarDeAeroplanta();
      });
  }

  ngOnInit() {
    this.verFactura = false;
    this.facturar = false;
    this.buscandoComprobante = false;
    this.codigoAeroplanta = this.usuarioService.getAeroplantaActual().codigoAeroplanta;
    this.aeroplantaPuedeFacturar = this.usuarioService.getAeroplantaActual().habilitarFacturacion || this.usuarioService.getAeroplantaActual().soloPAD;
    this.mostrarBotonFacturarAerovale = true;
    this.botonAnular = false;
    this.mensajeSpinner = "C a r g a n d o . . .";

    this.model = this.calendar.getToday();
    this.model1 = this.calendar.getToday();
    let inicio: any = { day: 1, month: 1, year: 2019 };
    this.datepickerConfig2.minDate = inicio;
    let fin: any = { day: this.model.day, month: this.model.month, year: this.model.year };
    this.model1 = this.calendar.getToday();
    this.datepickerConfig2.maxDate = fin;
    setTimeout(() => {
      this.spinner.show('spinnerLista');
    });
    this.reinicioFechaInicioFechaFin();
    this.getAerovalesPorRango(this.startDate, this.endDate);
    this.obtenerRemitos();
  }

  ngOnChanges(): void {
    this.ngOnInit();
  }
  onSelect(aerovale: Aerovale): void {
    this.remitoFox = null;
    this.facturar = false;
    this.buscandoComprobante = true;
    this.obteniendoComprobante = true;
    setTimeout(() => {
      this.spinner.show('spinnerLista');
    });
    this.facturaObtenida = null; //inicializo el comprobante en null en cada seleccion
    this.spinner.show('spinnerDetalle');
    this.aerovaleDetalle = null;
    this.aerovaleActual = aerovale;
    this.aerovaleService.getAerovalePorIdyTipo(this.aerovaleActual.id)
      .subscribe(aerovaleDetalle => {
        this.aerovaleDetalle = aerovaleDetalle;
        console.log(this.aerovaleDetalle);
        this.spinner.hide('spinnerDetalle');
        this.obtenerRemitos();
      }, () => {

      });
  }

  sincronizable(aerovale: AerovaleDetalle): boolean {
    var estadoAev: EstadoAerovale = EstadoAerovale[aerovale.estado];
    switch (estadoAev) {
      case EstadoAerovale.Precargado:
      case EstadoAerovale.DespachoIniciado:
      case EstadoAerovale.DespachoFinalizado:
      case EstadoAerovale.Pendiente:
      case EstadoAerovale.Sincronizado:
      case EstadoAerovale.Anulado:
        return false;

      case EstadoAerovale.Finalizado:
      case EstadoAerovale.FinalizadoSinFirma:
        return true;
    }
  }

  sincronizado(aerovale: AerovaleDetalle): boolean {
    var estadoAev: EstadoAerovale = EstadoAerovale[aerovale.estado];
    if (estadoAev === EstadoAerovale.Sincronizado) {
      return true;
    }
    else {
      return false;
    }
  }

  sincronizar(aev: AerovaleDetalle): void {
    this.spinner.show('spinnerDetalle');

    this.aerovaleService.sincronizar(aev.id)
      .subscribe(response => {
        console.log(response);
        this.aerovaleActual.estado = response.estado;
        this.aerovaleDetalle.estado = response.estado;
        this.spinner.hide('spinnerDetalle');
      });
  }

  aeroplantaSoloPad(){    
    return this.usuarioService.getAeroplantaActual().soloPAD;
  }

  print(): void {
    let printContents = document.getElementById('aerovalePrint').innerHTML;
    this.sharedService.print(printContents);
  }

  filtrarAerovales() {
    this.aerovalesFiltrados = this.aerovales.filter(a => {
      let agregar = true;
      if (this.busqueda.value) {
        agregar = a.clienteRazonSocial.toLowerCase().indexOf(this.busqueda.value.toLowerCase()) > -1;
        agregar = agregar || (a.nombreCiudadAeropuerto &&
          a.nombreCiudadAeropuerto.toLowerCase().indexOf(this.busqueda.value.toLowerCase()) > -1);
        agregar = agregar || (a.vuelo && a.vuelo.toLowerCase().indexOf(this.busqueda.value.toLowerCase()) > -1);
        agregar = agregar || (a.matricula && a.matricula.toLowerCase().indexOf(this.busqueda.value.toLowerCase()) > -1);
        agregar = agregar || (a.numeroAerovale && a.numeroAerovale.toString().indexOf(this.busqueda.value) > -1);

      }
      if (this.estados.some(b => b.seleccionado))
        agregar = agregar && this.estados.some(
          b => (b.seleccionado && b.valor == a.estado) ||
            (b.seleccionado && b == this.finalizado && this.finalizadoSinFirma.valor == a.estado));
      if (this.tipos.some(b => b.seleccionado))
        agregar = agregar && this.tipos.some(
          b => (b.seleccionado && b.tipo == a.tipoAerovale));
      return agregar;
    });
    if (this.aerovaleActual) {
      if (!this.aerovalesFiltrados.includes(this.aerovaleActual)) {
        this.aerovaleDetalle = null;
      }
    }
  }

  get busqueda() {
    return this.busquedaFormControl;
  }

  getAerovalesPorRango(fechaInicio: Date, fechaFin: Date) {
    this.obteniendoAerovales = true;
    this.aerovales = [];
    this.aerovalesFiltrados = [];
    this.spinner.show('spinnerLista');
    let desde: string = this.formatDate(fechaInicio.getUTCFullYear(), fechaInicio.getMonth() + 1, fechaInicio.getDate(), 0, 0);
    let hasta: string = this.formatDate(fechaFin.getUTCFullYear(), fechaFin.getMonth() + 1, fechaFin.getDate(), 23, 59);
    this.aerovaleService.getAerovalesPorRangoEnAeroplantas(desde, hasta)
      .subscribe(result => {
        this.aerovales = result.sort((a, b) => new Date(b.fechaHoraFinDespacho).getTime() - new Date(a.fechaHoraFinDespacho).getTime());
        this.aerovalesFiltrados = result;
        this.spinner.hide('spinnerLista');
        this.obteniendoAerovales = false;
        this.filtrarAerovales();
      }, () => {
        this.errorAerovales = true;
        this.obteniendoAerovales = false;
      });
  }

  formatDate(year, month, day, hour, minute): string {
    return year + '-' + month.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + '-' + day.toLocaleString(undefined, { minimumIntegerDigits: 2 })
      + 'T' + hour.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + minute.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':00';
  }

  export() {

    this.spinner.show('spinnerLista');
    this.obteniendoAerovales = true;
    this.procesandoExport = true;
    this.aerovalesFiltrados = null;
    this.aerovaleActual = null;
    this.aerovaleDetalle = null;
    this.mensajeSpinner = " D e s c a r g a n d o ...";
    this.startDate = new Date(this.model.year, this.model.month - 1, this.model.day);
    this.endDate = new Date(this.model1.year, this.model1.month - 1, this.model1.day);
    this.endDate.setHours(23, 59, 59, 99);

    if (this.mostrarResultados() === true) {

      this.exportarAerovalesPorRango(this.startDate, this.endDate);
      this.spinner.hide('spinnerLista');
    }
    else {

      this.aerovalesFiltrados.splice(0, this.aerovalesFiltrados.length);
    }
    this.obteniendoAerovales = false;
    this.procesandoExport = false;
  }

  exportarAerovalesPorRango(fechaInicio: Date, fechaFin: Date) {
    this.mensajeErrorExportar = "";
    let fechaDesde = this.formatDate(fechaInicio.getFullYear(), fechaInicio.getMonth() + 1, fechaInicio.getDate(), fechaInicio.getHours(), fechaInicio.getMinutes());
    let fechaHasta = this.formatDate(fechaFin.getFullYear(), fechaFin.getMonth() + 1, fechaFin.getDate(), fechaFin.getHours(), fechaFin.getMinutes());
    const url = `${this.baseUrl}/AerovalesV2/${this.codigoAeroplanta}/Exportar?fechaDesde=${fechaDesde}&fechaHasta=${fechaHasta}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });
    // Process the file downloaded
    this.http.get(url, { headers: headers, responseType: 'blob' as 'json' })
      .subscribe((res: Blob) => {
        const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
        let fInicio = this.datePipe.transform(fechaInicio, 'dd-MM-yyyy');
        let fFin = this.datePipe.transform(fechaFin, 'dd-MM-yyyy');
        saveAs(blob, "aerovalesDesde" + fInicio + "al" + fFin);
        this.procesandoExport = false;
        this.spinner.hide('spinnerLista');
      }, () => {
        console.log("No se encontraron aerovales para exportar");
        this.mensajeErrorExportar = "No puede exportar excel sin aerovales";
      });
  }

  ngOnDestroy() {
    this.change$.unsubscribe();
    this.aerovalesFiltrados = [];
  }

  addValidationError(control: AbstractControl, validationName: string) {
    let errors = control.errors;
    if (errors == null)
      errors = { [validationName]: true };
    else
      errors[validationName] = true;
    control.setErrors(errors);
    control.markAsDirty();
  }

  /**
   * Convierte una NgbDateStruct y una NgbTimeStruct en un Date
   */
  dateFromDateTimeStruct(date: NgbDateStruct, hour: NgbTimeStruct): Date {
    if (date == null || hour == null)
      return null;
    let dateReturn = new Date();
    dateReturn.setDate(date.day);
    dateReturn.setMonth(date.month - 1);
    dateReturn.setFullYear(date.year);
    dateReturn.setHours(hour.hour);
    dateReturn.setMinutes(hour.minute);
    return dateReturn;
  }

  fechaDesdeMayorQueHasta() {
    let esMayor: boolean = false;
    this.startDate = new Date(this.model.year, this.model.month - 1, this.model.day);
    this.endDate = new Date(this.model1.year, this.model1.month - 1, this.model1.day);
    if (this.startDate > this.endDate) { esMayor = true; }
    return esMayor;
  }


  reinicioFechaInicioFechaFin() {
    this.startDate = new Date(this.model.year, this.model.month - 1, this.model.day);
    this.endDate = new Date(this.model1.year, this.model1.month - 1, this.model1.day);
    this.endDate.setHours(23, 59, 59, 99);
  }

  buscarDesdeHasta() {
    this.mensajeErrorExportar = "";
    this.aerovalesFiltrados = null;
    this.aerovaleActual = null;
    this.aerovaleDetalle = null;
    this.mensajeSpinner = "C a r g a n d o . . .";
    this.buscando = true;
    this.reinicioFechaInicioFechaFin();
    if (this.mostrarResultados() === true) {
      this.getAerovalesPorRango(this.startDate, this.endDate);
    }
    else {
      this.aerovalesFiltrados.splice(0, this.aerovalesFiltrados.length);
    }
    this.buscando = false;
  }

  mostrarResultados() {
    let mostrar: Boolean;
    mostrar = false;
    mostrar = (this.startDate < this.endDate);
    return mostrar;
  }

  limpiarCampos() {
    this.aerovalesFiltrados = [];
    this.aerovaleDetalle = null;
    this.spinner.hide("spinnerLista");
    this.spinner.hide("spinnerDetalle");
    this.buscando = false;
    this.busquedaFormControl.reset();
    this.model = this.calendar.getToday();
    this.model1 = this.calendar.getToday();
    //limpio filtro estados
    for (let estado of this.estados) {
      estado.seleccionado = false;
    }
    //limpio filtro tipos
    for (let tipo of this.tipos) {
      tipo.seleccionado = false;
    }
  }

  cambiarDeAeroplanta() {
    this.cambiandoAeroplanta = false;
    this.limpiarCampos();
    this.cambiandoAeroplanta = true;
    this.ngOnInit();
  }

  crearNuevoAerovale() {
    const modalRef = this.modalService.open(SeleccionTipoAerovaleComponent, { size: 'lg', backdrop: 'static' });
    modalRef.result.then((tipoAerovale) => {
      this.tipoNuevoAerovale = tipoAerovale;
      this.aerovales = null;
      this.aerovalesFiltrados = null;
    }).catch((error) => {
      console.log(error);
    });
  }

  irAImporteDeFactura() {
    const modalRef = this.modalService.open(ImporteFacturaComponent, { centered: true, size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.aerovaleAfacturar = this.aerovaleDetalle;
    modalRef.result.then(() => {
    });
  }

  auditoriaAforadores() {
    const modalRef = this.modalService.open(ModalOkComponent, { centered: true });
    modalRef.componentInstance.titulo = "Auditoria de aforadores";

    var mensaje = "";

    if (this.aerovaleDetalle.detalleAerocombustible.aforadorInicialEditado) {
      mensaje += "Aforador Inicial: EDITADO MANUALMENTE<br/><br/>";
    } else {
      mensaje += "Aforador Inicial: LEIDO DE CAUDALIMETRO<br/><br/>";
    }

    if (this.aerovaleDetalle.detalleAerocombustible.aforadorFinalEditado) {
      mensaje += "Aforador final: EDITADO MANUALMENTE";
    } else {
      mensaje += "Aforador final: LEIDO DE CAUDALIMETRO";
    }

    modalRef.componentInstance.mensaje = mensaje;
  }

  auditoriaAutorizaciones() {
    const modalRef = this.modalService.open(ModalOkComponent, { centered: true });
    modalRef.componentInstance.titulo = "Auditoria de autorizaciones";
    var mensaje = EstadoAutorizacionAev[this.aerovaleDetalle.estadoAutorizacion];
    modalRef.componentInstance.mensaje = "Estado de autorización: " + mensaje;
  }

  anularAerovale(detalle: AerovaleDetalle) {    
    if(this.aerovaleDetalle.estado == "DespachoIniciado" || this.aerovaleDetalle.estado == "DespachoFinalizado" || this.aerovaleDetalle.estado == "Pendiente"){
      const modalRef = this.modalService.open(ModalConfirmComponent, { centered: true });
      modalRef.componentInstance.mensaje = "Esta anulando un aerovale en estado " + this.aerovaleDetalle.estado + ". Este aerovale aún no genero remito de stock ni de facturacion. ¿Esta seguro de anularlo?"; 
      modalRef.result.then(() => {
        this.accionAnularAerovale(detalle);
      });
    }else{
      this.accionAnularAerovale(detalle);
    }
  }

  accionAnularAerovale(detalle: AerovaleDetalle){
    this.obtenerAerovalePorId(detalle.id);
        const modalRef = this.modalAnulacion.open(ModalAnulacionAerovaleComponent, { centered: true, size: 'lg', backdrop: 'static' });
        modalRef.componentInstance.detalle = detalle;
        modalRef.result.then(() => {
          this.onSelect(this.aerovaleActual);
        });
  }

  modificarAerovale(detalle: AerovaleDetalle) {
    this.capturandoAevAnulado=true;
    this.spinner.show('spinnerDetalle');
    this.captureImage.emit(true);
    setTimeout(() => {
      this.spinner.hide('spinnerDetalle');
      let modalRef;
      if(this.fileAerovaleFisico!=undefined) {
        switch (detalle.tipoAerovale) {
          case TipoAerovale.Combustible:
              modalRef = this.modalService.open(AerovaleUpdatedCommonComponent, { centered: true, size: 'lg', backdrop: 'static' });
            break;
          case TipoAerovale.Aeroespecialidad:
              modalRef = this.modalService.open(AerovaleUpdatedCommonComponent, { centered: true, size: 'lg', backdrop: 'static' });
            break;
          case (TipoAerovale.Aerocombustible):
              modalRef = this.modalService.open(AerovaleUpdatedAerocombustibleComponent, { centered: true, size: 'lg', backdrop: 'static' });
            break;
          case (TipoAerovale.Alije):
              modalRef = this.modalService.open(AerovaleUpdatedAlijeComponent, { centered: true, size: 'lg', backdrop: 'static' });
            break;
          default:
              this.messageService.showErrorMessage("El tipo aerovale "+TipoAerovale[detalle.tipoAerovale]+" no puede modificarse.");
            break;
        }
        this.capturandoAevAnulado=false;             
        modalRef.componentInstance.aerovaleReemplaza = detalle;
        modalRef.componentInstance.fileAerovaleFisico = this.fileAerovaleFisico;
        modalRef.result.then((result) => {
          if(result) {
            this.fileAerovaleFisico=null;
            this.onSelect(this.aerovaleActual);
          }
        }, ()=> {});
      }else{
        this.messageService.showErrorMessage("El archivo no se capturo correctamente, vuelva intentarlo.");
        this.capturandoAevAnulado=false;
      }      
    }, 3000);
   
  }

  verificarEstadoFactura(): boolean {
    let visualizar: boolean = false;
    visualizar = this.facturaObtenida != null && this.facturaObtenida.factura.estado == EstadoFactura.Emitido;
    if (visualizar === true) {
      this.verFactura = true;
      this.facturar = false;
    }
    return visualizar;
  }

  mostrarFactura(content) {
    const modalRef = this.modalService.open(content, { centered: true, size: 'xl' });
  }

  obtenerComprobanteIdPorAerovaleId(id: string) {
    this.importeFactura == null;

    this.obteniendoComprobante = true;
    console.log("obtener si tiene un comprobante asociado");
    this.comprobanteService.obtenerComprobanteIdSegunAerovale(id)
      .subscribe(result => {
        this.importeFactura = result;

        if (result == null) {
          this.botonAnular = true;
          this.mostrarBotonFacturarAerovale = (this.aerovaleDetalle.estado != "Anulado" && this.aerovaleDetalle.tipoAerovale != TipoAerovale.Devolucion);
          this.obteniendoComprobante = false;
          this.facturar = true;
        } else {
          this.mostrarBotonFacturarAerovale = (!this.importeFactura.facturado) && this.aerovaleDetalle.estado != "Anulado" && this.aerovaleDetalle.tipoAerovale != TipoAerovale.Devolucion; 
          this.obtenerComprobantePorId(this.importeFactura.facturaId);
          this.facturar = false;
          if (this.importeFactura.estado == EstadoFactura.Calculado || this.importeFactura.estado == EstadoFactura.Anulado || this.importeFactura.estado == EstadoFactura.Error) {
            this.facturar = true;
          }
        }
      }, () => {
        this.botonAnular = true;
        if(this.aerovaleDetalle) {
          this.mostrarBotonFacturarAerovale = (this.aerovaleDetalle.estado != "Anulado" && this.aerovaleDetalle.tipoAerovale != TipoAerovale.Devolucion);
        }        
        this.obteniendoComprobante = false;
      });
  }

  obtenerComprobantePorId(id: string) {
    console.log("obtener el comprobante según id");
    this.comprobanteService.obtenerComprobantePorId(id)
      .subscribe(result => {
        console.log(result);
        this.facturaObtenida = result; //obtengo el comprobante segun id
        this.obteniendoComprobante = false;
      });
  }

  obtenerAerovalePorId(id: string) {
    console.log("obtengo aerovale con id" + id);
    this.aerovaleDetalle = null;
    this.aerovaleService.getAerovalePorIdyTipo(id)
      .subscribe(aerovaleDetalle => {
        this.aerovaleDetalle = aerovaleDetalle;

      }, () => {

      });
  }

  onNuevoAerovaleCancelado() {
    this.tipoNuevoAerovale = null;
    this.buscarDesdeHasta();
  }

  onNuevoAerovaleCreado(aerovale) {
    if(aerovale != null){
      this.tipoNuevoAerovale = null;
      this.messageService.showSuccessMessage("Aerovale creado exitosamente.");
      this.buscarDesdeHasta();
    }    
  }

  verRemito() {
    if (this.remitoFox.numeroFox != 0 && this.remitoFox.prefijoFox != 0 && this.remitoFox.numeroFox != null && this.remitoFox.prefijoFox != null) {
      let modalRef = this.modalService.open(VisorRemitoAerovaleComponent, { centered: true, size: 'lg' });
      modalRef.componentInstance.aerovaleDetalle = this.aerovaleDetalle;
      modalRef.componentInstance.aerovale = this.aerovaleActual;
      modalRef.componentInstance.remitoFox = this.remitoFox;
      // modalRef.componentInstance.remitoObtenido = this.remitoObtenido;
    } else {
      let modalRef = this.modalService.open(ModalCreacionFoxComponent, { centered: true });
      modalRef.componentInstance.remitoFox = this.remitoFox;
      modalRef.result.then((result) => {
        if (result) {
          this.verRemito();
        }
      });
    }
  }

  obtenerRemitos() {
    this.buscandoComprobante = true;
    if (this.aerovaleActual != null) {
      this.comprobanteService.getRemitoAerovale(this.aerovaleActual.id)
        .subscribe(result => {
          this.remitoFox = result;
          this.buscandoComprobante = false;
          //Se cambia el lugar de la llamada porque si se llaman los dos endpoints de facturacion en el ngOnInit se duplicaba el aerovale
          this.obtenerComprobanteIdPorAerovaleId(this.aerovaleDetalle.id);
        }, (err: ErrorModel) => {
          this.messageService.showErrorMessage('Hubo un error al obtener el remito de facturación');
          this.buscandoComprobante = false;
          //Se cambia el lugar de la llamada porque si se llaman los dos endpoints de facturacion en el ngOnInit se duplicaba el aerovale
          this.obtenerComprobanteIdPorAerovaleId(this.aerovaleDetalle.id);
        });
    }
  }

  rolesParaAnularOk(){
    return this.authService.hasRole(TipoUsuario.SUPERVISOR, TipoUsuario.OPERADOR, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.TUTOR, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL_AV);
  }

  logicaParaMostrarBotonAnularAerovaleOk(){
    if(this.aerovaleDetalle.estado == 'Anulado'){
      return false;
    }

    if(this.aerovaleDetalle.estado == "DespachoIniciado" || this.aerovaleDetalle.estado == "DespachoFinalizado" || this.aerovaleDetalle.estado == "Pendiente"){
      return true;
    }

    if(this.aerovaleDetalle.estado == "FinalizadoSinFirma" || this.aerovaleDetalle.estado == "Finalizado" || this.aerovaleDetalle.estado == "Sincronizado"){
      //Si es FinSinFirma, FinConFirma o sincronizado chequear que esta facturado.
	    //  Que este facturado significa que al obtener obtenerComprobanteIdPorAerovaleId, el atributo facturado del dto respuesta
      //  sea true y tener atributo facturaId != null. 

      if(this.importeFactura == null || (this.importeFactura.facturaId != null && this.importeFactura.estado != EstadoFactura.Emitido)){
        return true;
      }
    }

    return false;
  }

  usuarioCapturoAerovale(fileAerovaleFisico: File) {
    this.fileAerovaleFisico = fileAerovaleFisico;
  }
}
