<!-- modal para editar o ver -->
<div *ngIf="this.editando">
  <div class="modal-header bg-light">
    <h4 class="modal-title">Direccion de entrega</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismissAll()">
      
    </button>
  </div>
  <div class="modal-body"
    *ngIf="this.clienteDireccionEntrega!=null && this.clienteDireccionEntrega.direccionesEntrega.length > 0">
    <form [formGroup]="direccionForm">
      <div class="row"
        *ngFor="let dir of this.clienteDireccionEntrega.direccionesEntrega | paginate: { itemsPerPage: 1, currentPage: paginaActual }">
        <input class="form-control" id="idFormControl" formControlName="idFormControl" id="idFormControl" [value]="this.clienteDireccionEntrega.direccionesEntrega.id"/>
        <!--CODIGO-->
        <div class="mb-3 col-6">
          <div class="mb-3 row">
            <div class="col-5">
              <label for="codigoFormControl" class="col-form-label">*Código:</label>
            </div>
            <div class="col-7">
              <select class="form-select" id="codigoFormControl" formControlName="codigoFormControl" 
                (change)="obtenerEnum()">
                <option *ngFor="let cod of this.keysCodigoUbicacion()" [value]="cod">
                  {{ cod }}
                </option>
              </select>
              <div *ngIf="codigo.invalid && codigo.errors.required && (codigo.dirty || codigo.touched)"
                class="alert alert-danger">
                Requerido
              </div>
            </div>
          </div>
        </div>
        <!--ENTREGA PRODUCTO-->
        <div class="mb-3 col-6">
          <div class="mb-3 row">
            <div class="col-12">
              <input class="form-control" value="{{this.ubicacionSeleccionada}}" id="entregaFormControl"
                formControlName="entregaFormControl"/>
            </div>
          </div>
        </div>
        <!--CALLE-->
        <div class="mb-3 col-12">
          <div class="mb-3 row">
            <div class="col-4">
              <label class="col-form-label" for="calleFormControl">*Dirección:</label>
            </div>
            <div class="col-8">
              <input class="form-control" id="calleFormControl" formControlName="calleFormControl"/>
              <div *ngIf="calle.invalid && calle.errors.required && (calle.dirty || calle.touched)"
                class="alert alert-danger">
                Requerido
              </div>
            </div>
          </div>
        </div>
        <!--NUMERO-->
      <div class="mb-3 col-12">
        <div class="mb-3 row">
          <div class="col-4">
            <label class="col-form-label" for="numeroFormControl">*Numero:</label>
          </div>
          <div class="col-3">
            <input class="form-control" id="numeroFormControl" formControlName="numeroFormControl" (keypress)="this.onlyDecimalPositivo($event)" maxlength="5"/>
            <div *ngIf="numero.invalid && numero.errors.required && (numero.dirty || numero.touched)"
              class="alert alert-danger">
              Requerido
            </div>
          </div>
          <div class="col-5"></div>
        </div>
      </div>
        <!--NUMERO-->
        <div class="mb-3 col-12">
          <div class="mb-3 row">
            <div class="col-4">
              <label class="col-form-label" for="pisoFormControl">Piso:</label>
            </div>
            <div class="col-3">
              <input class="form-control" id="pisoFormControl" formControlName="pisoFormControl"/>
              <div *ngIf="piso.invalid && piso.errors.required && (piso.dirty || piso.touched)"
                class="alert alert-danger">
                Requerido
              </div> 
            </div>
            <div class="col-2">
              <label class="col-form-label" for="deptoFormControl">Depto:</label>
            </div>
            <div class="col-3">
              <input class="form-control" id="deptoFormControl" formControlName="deptoFormControl"/>
              <div *ngIf="depto.invalid && depto.errors.required && (depto.dirty || depto.touched)"
                class="alert alert-danger">
                Requerido
              </div>
            </div>
          </div>
        </div>

        <!--PAIS-->
        <div class="mb-3 col-12">
          <div class="mb-3 row">
            <div class="col-4">
              <label class="col-form-label" for="paisFormControl">*País:</label>
            </div>
            <div class="col-8">
              <input id="paisFormControl" type="text" class="form-control" formControlName="paisFormControl"
                placeholder="Seleccione una opcion..." [inputFormatter]="formatterPais" [(ngModel)]="modelPais"
                [resultFormatter]="formatterPais" [ngbTypeahead]="searchPais" (selectItem)="selectPais($event)"/>
              <loading-text-spinner *ngIf="this.cargandoPaises" message="Obteniendo paises..." color="primary">
              </loading-text-spinner>
            </div>
          </div>
        </div>

        <!--PROVINCIA-->
        <div class="mb-3 col-12">
          <div class="mb-3 row">
            <div class="col-4">
              <label class="col-form-label" for="provinciaFormControl">*Provincia:</label>
            </div>
            <div class="col-8">
              <input id="provinciaFormControl" type="text" class="form-control" formControlName="provinciaFormControl"
                placeholder="Seleccione una opcion..." [inputFormatter]="formatterProvincia"
                [(ngModel)]="modelProvincia" [resultFormatter]="formatterProvincia" [ngbTypeahead]="searchProvincia"
                (selectItem)="selectProvincia($event)"/>
              <div *ngIf="provincia.invalid && provincia.errors.required && (provincia.dirty || provincia.touched)"
                class="alert alert-danger">
                Requerido
              </div>
              <loading-text-spinner *ngIf="this.cargandoProvincias" message="Obteniendo provincias..." color="primary">
              </loading-text-spinner>
            </div>
          </div>
        </div>
        
        <!--CODIGO POSTAL-->
        <div class="mb-3 col-12">
          <div class="mb-3 row">
            <div class="col-4">
              <label class="col-form-label" for="codigoPostalFormControl">*Ciudad:</label>
            </div>
            <div class="col-8">
              <input class="form-control" id="codigoPostalFormControl" formControlName="codigoPostalFormControl"
                placeholder="Seleccione una opcion..." [inputFormatter]="formatterCiudad" [(ngModel)]="modelCiudad"
                [resultFormatter]="formatterCiudad" [ngbTypeahead]="searchCiudad"
                (selectItem)="selectCiudades($event)"/>
              <div
                *ngIf="codigoPostal.invalid && codigoPostal.errors.required && (codigoPostal.dirty || codigoPostal.touched)"
                class="alert alert-danger">
                Requerido
              </div>
              <loading-text-spinner *ngIf="this.cargandoCiudades" message="Obteniendo ciudades..." color="primary">
              </loading-text-spinner>
            </div>
          </div>
        </div>
        

      </div>
    </form>
    <small class="text text-danger">*Campos obligatorios</small>
    <div class="justify-content-center">
      <pagination-controls previousLabel="Prev" (autohide)="!this.cargandoCiudades"
        (pageChange)="this.cambiarPagina($event)"></pagination-controls>
    </div>
  </div>
  <div class="modal-body" *ngIf="this.clienteDireccionEntrega.direccionesEntrega.length == 0">
    <div class="alert alert-warning">No hay direcciones cargadas para el cliente seleccionado.</div>
  </div>
  <div class="modal-footer bg-light justify-content-center">
    <button class="btn btn-primary me-2" (click)="guardarDireccion()"
      [disabled]="!usuarioModificaCampos() || !this.direccionForm.valid">
      <span *ngIf="!guardando">Guardar pagina {{this.paginaActual}}</span>
      <div *ngIf="guardando" class="d-flex align-items-center">
        <div class="spinner-border text-light spinner-border-sm" role="status"></div>
        <span>Guardando...</span>
      </div>
    </button>
    <button class="btn btn-secondary" (click)="activeModal.dismissAll()">{{ this.texto }}</button>
  </div>
</div>
<!-- modal para crear -->
<div *ngIf="!this.editando">
  <input class="form-control" id="idFormControl" formControlName="idFormControl" id="idFormControl" [value]="'00000000-0000-0000-0000-000000000000'"/>
  <div class="modal-header bg-light">
    <h4 class="modal-title">Direccion de entrega</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismissAll()">
      
    </button>
  </div>
  <div class="modal-body" *ngIf="this.clienteDireccionEntrega!=null">
    <form [formGroup]="direccionForm">
      <div class="row">
        <!--CODIGO-->
        <div class="mb-3 col-6">
          <div class="mb-3 row">
            <div class="col-5">
              <label for="codigoFormControl" class="col-form-label">*Código:</label>
            </div>
            <div class="col-7">
              <select class="form-select" type="text" formControlName="codigoFormControl" (change)="obtenerEnum()">
                <option *ngFor="let cod of keysCodigoUbicacion()" [ngValue]="cod">
                  <!-- this.keysCodigoUbicacion() -->
                  {{ cod }}
                </option>
              </select>
              <div *ngIf="codigo.invalid && codigo.errors.required && (codigo.dirty || codigo.touched)"
                class="alert alert-danger">
                Requerido
              </div>
            </div>
          </div>
        </div>
        <!--ENTREGA PRODUCTO-->
        <div class="mb-3 col-6">
          <div class="mb-3 row">
            <div class="col-12">
              <input class="form-control" value="{{this.ubicacionSeleccionada}}" id="entregaFormControl"
                formControlName="entregaFormControl"/>
            </div>
          </div>
        </div>
        <!--CALLE-->
        <div class="mb-3 col-12">
          <div class="mb-3 row">
            <div class="col-4">
              <label class="col-form-label" for="calleFormControl">*Dirección:</label>
            </div>
            <div class="col-8">
              <input class="form-control" id="calleFormControl" formControlName="calleFormControl" maxlength="25"/>
              <div *ngIf="calle.invalid && calle.errors.required && (calle.dirty || calle.touched)"
                class="alert alert-danger">
                Requerido
              </div>
            </div>
          </div>
        </div>
        <!--NUMERO-->
        <div class="mb-3 col-12">
          <div class="mb-3 row">
            <div class="col-4">
              <label class="col-form-label" for="numeroFormControl">*Numero:</label>
            </div>
            <div class="col-3">
              <input class="form-control" id="numeroFormControl" formControlName="numeroFormControl"
                (keypress)="this.onlyDecimalPositivo($event)" maxlength="5"/>
              <div *ngIf="numero.invalid && numero.errors.required && (numero.dirty || numero.touched)"
                class="alert alert-danger">
                Requerido
              </div>
            </div>
            <div class="col-5"></div>
          </div>
        </div>
        <!--PISO DEPTO-->
        <div class="mb-3 col-12">
          <div class="mb-3 row">
            <div class="col-4">
              <label class="col-form-label" for="pisoFormControl">Piso:</label>
            </div>
            <div class="col-3">
              <input class="form-control" id="pisoFormControl" formControlName="pisoFormControl" maxlength="2"/>
            </div>
            <div class="col-2">
              <label class="col-form-label" for="deptoFormControl">Depto:</label>
            </div>
            <div class="col-3">
              <input class="form-control" id="deptoFormControl" formControlName="deptoFormControl" maxlength="2"/>
            </div>
          </div>
        </div>
        
        <!--PAIS-->
        <div class="mb-3 col-12">
          <div class="mb-3 row">
            <div class="col-4">
              <label class="col-form-label" for="paisFormControl">*País:</label>
            </div>
            <div class="col-8">
              <input id="paisFormControl" type="text" class="form-control" formControlName="paisFormControl"
                placeholder="Seleccione una opcion..." [inputFormatter]="formatterPais" [(ngModel)]="modelPais"
                [resultFormatter]="formatterPais" [ngbTypeahead]="searchPais" (selectItem)="selectPais($event)"/>
              <loading-text-spinner *ngIf="this.cargandoPaises" message="Obteniendo paises..." color="primary">
              </loading-text-spinner>
            </div>
          </div>
        </div>

        <!--PROVINCIA-->
        <div class="mb-3 col-12">
          <div class="mb-3 row">
            <div class="col-4">
              <label class="col-form-label" for="provinciaFormControl">*Provincia:</label>
            </div>
            <div class="col-8">
              <input id="provinciaFormControl" type="text" class="form-control" formControlName="provinciaFormControl"
                placeholder="Seleccione una opcion..." [inputFormatter]="formatterProvincia"
                [(ngModel)]="modelProvincia" [resultFormatter]="formatterProvincia" [ngbTypeahead]="searchProvincia"
                (selectItem)="selectProvincia($event)"/>
              <div *ngIf="provincia.invalid && provincia.errors.required && (provincia.dirty || provincia.touched)"
                class="alert alert-danger">
                Requerido
              </div>
              <loading-text-spinner *ngIf="this.cargandoProvincias" message="Obteniendo provincias..." color="primary">
              </loading-text-spinner>
            </div>
          </div>
        </div>
        <!--CODIGO POSTAL-->
          <div class="mb-3 col-12">
          <div class="mb-3 row">
            <div class="col-4">
              <label class="col-form-label" for="codigoPostalFormControl">*Ciudad:</label>
            </div>
            <div class="col-8">
              <input class="form-control" id="codigoPostalFormControl" formControlName="codigoPostalFormControl"
                placeholder="Seleccione una opcion..." [inputFormatter]="formatterCiudad" [(ngModel)]="modelCiudad"
                [resultFormatter]="formatterCiudad" [ngbTypeahead]="searchCiudad"
                (selectItem)="selectCiudades($event)"/>
              <div
                *ngIf="codigoPostal.invalid && codigoPostal.errors.required && (codigoPostal.dirty || codigoPostal.touched)"
                class="alert alert-danger">
                Requerido
              </div>
              <loading-text-spinner *ngIf="this.cargandoCiudades" message="Obteniendo ciudades..." color="primary">
              </loading-text-spinner>
            </div>
          </div>
        </div>        

      </div>
    </form>
    <small class="text text-danger">*Campos obligatorios</small>
  </div>

  <div class="modal-footer bg-light justify-content-center">
    <button class="btn btn-primary me-2" (click)="guardarDireccion()" [disabled]="!usuarioModificaCampos() || !this.direccionForm.valid">
      <span *ngIf="!guardando">Guardar</span>
      <div *ngIf="guardando" class="d-flex align-items-center">
        <div class="spinner-border text-light spinner-border-sm" role="status"></div>
        <span>Guardando...</span>
      </div>
    </button>
    <button class="btn btn-secondary" [disabled]="guardando" (click)="activeModal.dismissAll()">Cancelar</button>
  </div>
</div>