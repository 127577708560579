import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbCalendar, NgbDatepickerConfig, NgbDateStruct, NgbModal, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ProductoService } from 'src/app/abm/producto/producto.service';
import { UsuarioService } from 'src/app/account/usuario.service';
import { CalculosService } from 'src/app/shared/calculos.service';
import { TipoAerovale } from 'src/app/shared/enums/tipoAerovale';
import { TipoCuenta } from 'src/app/shared/enums/tipoCuenta';
import { TipoExpendedora } from 'src/app/shared/enums/tipoExpendedora';
import { TipoPago } from 'src/app/shared/enums/tipoPago';
import { TipoProducto } from 'src/app/shared/enums/tipoProducto';
import { TipoVehiculo } from 'src/app/shared/enums/tipoVehiculo';
import { TipoVuelo } from 'src/app/shared/enums/tipoVuelo';
import { ModalConfirmComponent } from 'src/app/shared/generic-modals/modal-confirm.component';
import { Abastecedora } from 'src/app/shared/models/despacho/Abastecedora';
import { Aeropuerto } from 'src/app/shared/models/despacho/Aeropuerto';
import { AerovaleDetalle } from 'src/app/shared/models/despacho/AerovaleDetalle';
import { CuentaCliente } from 'src/app/shared/models/despacho/CuentaCliente';
import { Operador } from 'src/app/shared/models/despacho/Operador';
import { Producto } from 'src/app/shared/models/despacho/Producto';
import { Surtidor } from 'src/app/shared/models/stock/Surtidor';
import { NuevoClienteComponent } from 'src/app/shared/nuevo-cliente/nuevo-cliente.component';
import { SharedService } from 'src/app/shared/shared.service';
import { MessageService } from 'src/app/shell/message.service';
import { SurtidorService } from 'src/app/stock/surtidor.service';
import { isNullOrUndefined } from 'src/app/shared/compat-tools';
import { AerovalesService } from '../../aerovales.service';
import { ClienteService } from 'src/app/cliente/cliente.service';
import { Cliente } from 'src/app/shared/models/despacho/Cliente';
import { NgxSpinnerService } from 'ngx-spinner';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { TipoPico } from 'src/app/shared/enums/tipoPico';
import { RanchoService } from 'src/app/rancho/rancho.service';
import { Rancho } from 'src/app/shared/models/ranchos/Rancho';
import { ResultadoPruebaDeAgua } from 'src/app/shared/enums/resultadoPruebaDeAgua';
import { UnidadMedida } from 'src/app/shared/enums/unidadMedida';
import { Usuario } from 'src/app/shared/models/despacho/Usuario';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';
import { AerovaleDetalleAerocombustible } from 'src/app/shared/models/despacho/AerovaleDetalleAerocombustible';
import { AeropuertoService } from '../../aeropuerto.service';
import { BlobUploadCommonResponse } from '@azure/storage-blob';
import { CargaArchivo } from 'src/app/shared/models/cliente/CargaArchivo';
import { TipoBlobStorage } from 'src/app/shared/enums/TipoBlobStorage';
import { AzureBlobUploadService } from 'src/app/shared/azure-blob-upload.service';
import { StockService } from 'src/app/stock/stock.service';
import { Sas } from 'src/app/shared/models/padrones/Sas';
import { DecimalPipe } from '@angular/common';
import { Pico } from 'src/app/shared/models/despacho/Pico';
import { ProductosService } from '../../productos.service';
import { Aeroespecialidad } from 'src/app/shared/models/despacho/Aeroespecialidad';
import { VehiculoService } from 'src/app/cliente/vehiculo.service';
import { VehiculoDTO } from 'src/app/shared/models/cliente/VehiculoDTO';
import { ModalVehiculoComponent } from 'src/app/management/matriculas/abm-matriculas/modal-vehiculo/modal-vehiculo.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-aerovale-updated-aerocombustible',
  templateUrl: './aerovale-updated-aerocombustible.component.html',
  styleUrls: ['./aerovale-updated-aerocombustible.component.scss']
})
export class AerovaleUpdatedAerocombustibleComponent implements OnInit {

  @Input() aerovaleReemplaza: AerovaleDetalle;
  @Input() fileAerovaleFisico: File;
  @ViewChild('typeaheadInstance')
  private typeaheadInstance: NgbTypeahead;
  

  aerovalesForm: UntypedFormGroup;
  guardandoAerovale: boolean = false;
    
  /** common */
  clientes: CuentaCliente[];
  operadores:Operador[]=[];
  abastecedoras: Abastecedora[]=[];
  productos: Producto[]=[];
  
  todosLosVehiculos: VehiculoDTO[];
  vehiculosDeCliente: VehiculoDTO[];
  surtidores: Surtidor;
  ranchoCodigo: string;
  aeropuertos: Aeropuerto[];

  /** Aeroespecialidades */
  aeroespecialidadesSeleccionables: Producto[];
  arreglo : any[] = [];
  cargandoAeroespecialidades: boolean;
  errorAeroespecialidades: boolean;

  cargandoInformacion: boolean = false;
  cargandoClientes: boolean;  
  cargandoOperadores: boolean;
  cargandoAbastecedoras: boolean;  
  cargandoVehiculos: boolean;
  cargandoVehiculosDeCliente: boolean;  
  obteniendoTanquesSurtidor: boolean = false;
  cargandoRancho: boolean = false;
  cargandoAeropuertos: boolean = false;

  errorClientes: boolean;
  errorOperadores: boolean;
  errorDestinos:boolean=false;
  errorVehiculos:boolean=false;
  errorVehiculosDeCliente: boolean;

  timeVuelo: any;
  time: any;
  timeFin: any;

  /** horas */
  modelHoraLlegada: any;  
  modelHoraInicioDespacho: any;  
  modelHoraFinDespacho: any;  
  /** fechas  */
  modelFechaLlegada: NgbDateStruct;
  modelInicioDespacho: NgbDateStruct;
  modelFinDespacho: NgbDateStruct;

  fechaHoraAev: Date;
  fechaHoraInicioDespacho: Date;
  fechaHoraFinDespacho: Date;

  destinoinvalid:boolean;
  densidadMin: number;
  densidadMax: number;

  TipoAerovale = TipoAerovale;
  TipoExpendedora = TipoExpendedora;
  TipoPago = TipoPago;
  TipoPico = TipoPico;
  TipoVuelo = TipoVuelo;
  UnidadMedida = UnidadMedida;
  ResultadoPruebaDeAgua = ResultadoPruebaDeAgua;
  combustibleNoCoincide: boolean = false;
  aerovaleCreadoCorrectamente: boolean = false;
  
  readonly entidadesTotales = 7;
  entidadesDescargadas = 0;
  private entidadesDescargadas$ = new Subject<number>();
  private subscription: Subscription;

  tiempo = environment.frontdoor.timeout;

  constructor(public modal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private datepickerConfig: NgbDatepickerConfig,
    private calendar: NgbCalendar,
    private spinner: NgxSpinnerService,
    private aerovaleService:AerovalesService,
    private usuarioService:UsuarioService,
    private modalService: NgbModal,    
    private messageService:MessageService,
    private clienteService: ClienteService,
    private productoService: ProductoService,
    private ranchoService: RanchoService,
    private surtidorService: SurtidorService,
    private calculoService: CalculosService,
    private sharedService: SharedService,
    private aeropuertoService: AeropuertoService,
    private azureBlobUpload: AzureBlobUploadService,
    private stockService: StockService,
    private despachoService: ProductosService,
    private vehiculosService:VehiculoService) { }

ngOnInit() {    

  this.modelFechaLlegada = this.calendar.getToday();
  let inicio: any = { day: 1, month: 1, year: 2019 };
  this.datepickerConfig.minDate = inicio;
  let fin: any = { day: this.modelFechaLlegada.day, month: this.modelFechaLlegada.month , year: this.modelFechaLlegada.year };
  this.datepickerConfig.maxDate = fin;

  this.crearFormulario();
  this.iniciarComponentes();
}

crearFormulario(){
  this.aerovalesForm = this.fb.group({
    clienteFormControl: new UntypedFormControl(null, Validators.required),    
    aerocombustibleFormControl: new UntypedFormControl(null),
    vehiculoFormControl: new UntypedFormControl(null, Validators.required),
    abastecedoraFormControl: new UntypedFormControl({ value: null }, Validators.required),
    numeroVueloFormControl: new UntypedFormControl(null, [
      Validators.required,
      Validators.minLength(3),
      Validators.pattern('(([A-Za-z]{2}|[A-Za-z]\\d|\\d[A-Za-z])([1-9]|\\s)(\\d{1,4}[A-Za-z]?)|((\W|^)MANT(\W|$))|((\W|^)mant(\W|$))|(((\W|^)FFAA(\W|$))|((\W|^)GOB(\W|$))|((\W|^)PRIV(\W|$))|((\W|^)SANI(\W|$))|((\W|^)ffaa(\W|$))|((\W|^)gob(\W|$))|((\W|^)priv(\W|$))|((\W|^)sani(\W|$))|((\W|^)YPF(\W|$))|((\W|^)ypf(\W|$))))')
    ]),
    temperaturaFormControl: new UntypedFormControl(null, [Validators.required, this.temperaturaValidator]),
    densidadFormControl: new UntypedFormControl(null, [Validators.required]),
    fechaLlegadaFormControl: new UntypedFormControl(null, [Validators.required]),
    horaLlegadaFormControl: new UntypedFormControl(null, [Validators.required]),
    fechaInicioDespachoFormControl: new UntypedFormControl(null, [Validators.required]),
    horaInicioDespachoFormControl: new UntypedFormControl(null, [Validators.required]),
    fechaFinDespachoFormControl: new UntypedFormControl(null, [Validators.required]),
    horaFinDespachoFormControl: new UntypedFormControl(null, [Validators.required]),
    posicionFormControl: new UntypedFormControl(null),
    cabotajeFormControl: new UntypedFormControl(null),
    internacionalFormControl: new UntypedFormControl(null),
    tipoVueloFormControl: new UntypedFormControl(null),
    destinoFormControl: new UntypedFormControl(null, [Validators.nullValidator]),
    tipoPagoFormControl: new UntypedFormControl(null),
    aforadorInicialFormControl: new UntypedFormControl(null, Validators.required),
    aforadorFinalFormControl: new UntypedFormControl(null, [Validators.required, Validators.min(1)]),
    volumenDespachadoFormControl: new UntypedFormControl(null),
    volumen15FormControl: new UntypedFormControl(null),
    operadorFormControl: new UntypedFormControl(null, Validators.required),
    presionMangueraFormControl: new UntypedFormControl(null),
    presionDiferencialFormControl: new UntypedFormControl(null),
    caudalFormControl: new UntypedFormControl(null),
    tanquesAsociadosFormControl: new UntypedFormControl(null),
    picosAsociadosFormControl: new UntypedFormControl(null, Validators.required),
    tipoPicoFormControl: new UntypedFormControl(null, Validators.required),

    aeroespecialidadesSeleccionablesFormControl: new UntypedFormControl(null),
    aeroespecialidadesSeleccionadasFormControlArray: new UntypedFormArray([])
  });

  this.aerovalesForm.setValidators(this.aerovaleValidator.bind(this));
}

iniciarComponentes() {

  this.mostrarSpinner2();
  
  this.entidadesDescargadas = 0;

  this.subscription = this.entidadesDescargadas$.subscribe( () => {
    this.entidadesDescargadas++; 
    if(this.entidadesDescargadas == this.entidadesTotales){
      this.precargarDatos();
    }
  }); 

  this.getClientes();
  this.getVehiculos();
  this.getOperadoresConRoles();
  this.getAbastecedoras();
  this.getProductos();
  this.getAeropuertos();
}

precargarDatos() {
  this.vehiculoFormControl.setValue(this.aerovaleReemplaza.vehiculo);
  this.tipoVueloFormControl.setValue(this.aerovaleReemplaza.detalleAerocombustible.tipoVuelo == TipoVuelo.Indeterminado ? TipoVuelo.Cabotaje : this.aerovaleReemplaza.detalleAerocombustible.tipoVuelo);
  this.numeroVueloFormControl.setValue(this.aerovaleReemplaza.vuelo);

  this.fechaHoraAev = new Date(this.aerovaleReemplaza.fechaHoraLlegada);
  this.modelFechaLlegada = { day: this.fechaHoraAev.getDate(), month: this.fechaHoraAev.getMonth() + 1, year: this.fechaHoraAev.getFullYear() };
  this.modelHoraLlegada = { hour: this.fechaHoraAev.getHours(), minute: this.fechaHoraAev.getMinutes(), second: this.fechaHoraAev.getSeconds() };

  this.fechaHoraInicioDespacho = new Date(this.aerovaleReemplaza.fechaHoraInicioDespacho);
  this.modelInicioDespacho = { day: this.fechaHoraInicioDespacho.getDate(), month: this.fechaHoraInicioDespacho.getMonth() + 1, year: this.fechaHoraInicioDespacho.getFullYear() };
  this.modelHoraInicioDespacho = { hour: this.fechaHoraInicioDespacho.getHours(), minute: this.fechaHoraInicioDespacho.getMinutes(), second: this.fechaHoraInicioDespacho.getSeconds() };
 
  this.fechaHoraFinDespacho = new Date(this.aerovaleReemplaza.fechaHoraFinDespacho);
  this.modelFinDespacho = { day: this.fechaHoraFinDespacho.getDate(), month: this.fechaHoraFinDespacho.getMonth() + 1, year: this.fechaHoraFinDespacho.getFullYear() };
  this.modelHoraFinDespacho = { hour: this.fechaHoraFinDespacho.getHours(), minute: this.fechaHoraFinDespacho.getMinutes(), second: this.fechaHoraFinDespacho.getSeconds() };

  this.tipoPagoFormControl.setValue(this.aerovaleReemplaza.tipoPago);

  this.aforadorInicialFormControl.setValue(this.aerovaleReemplaza.detalleAerocombustible.aforadorInicio);
  this.aforadorFinalFormControl.setValue(this.aerovaleReemplaza.detalleAerocombustible.aforadorFinal);
  this.temperaturaFormControl.setValue(this.aerovaleReemplaza.detalleAerocombustible.temperatura);
  this.densidadFormControl.setValue(this.aerovaleReemplaza.detalleAerocombustible.densidad);
  this.picosAsociadosFormControl.setValue(this.aerovaleReemplaza.detalleAerocombustible.picoId);
  this.ranchoCodigo = this.aerovaleReemplaza.detalleAerocombustible.rancho;
  this.obtenerCantidadDespachada();
  if(this.aerovaleReemplaza.aeroespecialidades.length>0) {
    this.getAeroespecialidades()
  }
  this.ocultarSpinner2();
}

mostrarSpinner2() {
  this.spinner.show('spinnerGrafico');
  this.cargandoInformacion = true;
}

ocultarSpinner2() {
  this.spinner.hide('spinnerGrafico');
  this.cargandoInformacion = false;
}

aerovaleValidator(group: UntypedFormGroup): any{
  if (group) {
    let aforadoInicial = group.get("aforadorInicialFormControl").value;
    let aforadorFinal = group.get("aforadorFinalFormControl").value;

    if (aforadoInicial != null && aforadorFinal != null) {
      if (aforadoInicial >= aforadorFinal) {
        return { aforadorInicialMayorAforadorFinal: true };
      }
    }

    var ahora = new Date();

    let fechaLlegada = group.get("fechaLlegadaFormControl").value;
    let horaLlegada = group.get("horaLlegadaFormControl").value;

    if (fechaLlegada && horaLlegada) {
      var fecha = new Date(fechaLlegada.year, fechaLlegada.month - 1, fechaLlegada.day, horaLlegada.hour, horaLlegada.minute);
      var ahora = new Date();
      if (fecha > ahora) {
        return { fechaLlegadaMayorAhora: true };
      }
    }

    let fechaInicio = group.get("fechaInicioDespachoFormControl").value;
    let horaInicio = group.get("horaInicioDespachoFormControl").value;

    if (fechaInicio && horaInicio) {
      var fecha = new Date(fechaInicio.year, fechaInicio.month - 1, fechaInicio.day, horaInicio.hour, horaInicio.minute);
      if (fecha > ahora) {
        return { fechaInicioMayorAhora: true };
      }
    }

    let fechaFin = group.get("fechaFinDespachoFormControl").value;
    let horaFin = group.get("horaFinDespachoFormControl").value;

    if (fechaFin && horaFin) {
      var fecha = new Date(fechaFin.year, fechaFin.month - 1, fechaFin.day, horaFin.hour, horaFin.minute);
      if (fecha > ahora) {
        return { fechaFinMayorAhora: true };
      }
    }

    if (fechaInicio && horaInicio && fechaFin && horaFin) {
      var inicio = new Date(fechaInicio.year, fechaInicio.month - 1, fechaInicio.day, horaInicio.hour, horaInicio.minute);
      var fin = new Date(fechaFin.year, fechaFin.month - 1, fechaFin.day, horaFin.hour, horaFin.minute);
      if (inicio >= fin) {
        return { fechaInicioMayorFin: true };
      }
    }

    return null;
  }
}

/** Utilidades */

getTipoAerovale() {
  if (this.aerovaleReemplaza) {
    return TipoAerovale[this.aerovaleReemplaza.tipoAerovale];
  }else{
    return null;
  }
}

onlyNumberKey(event) {
  return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
}

onlyDecimalPositivo(event) {
  return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46;
}

keysTipoDePago(): Array<string> {
  var keys = Object.keys(this.TipoPago);
  return keys.slice(keys.length / 2);
}

obtenerTipoDePago(tipoCuentaCliente: number): Array<string> {
  if(!tipoCuentaCliente) return [];
  let lista: Array<string> = this.keysTipoDePago();
  lista = lista.filter(e => TipoCuenta[e] == TipoCuenta.Contado || TipoCuenta[e] == tipoCuentaCliente);
  return lista;
}

obtenerCantidadDespachada() {
  let res: number = this.aforadorFinalFormControl.value - this.aforadorInicialFormControl.value;
  if (this.aforadorFinalFormControl.value < this.aforadorInicialFormControl.value) { res = 0; }
  this.volumenDespachadoFormControl.setValue(res);
  if(this.temperaturaFormControl.value != null && this.densidadFormControl.value != null) {
    this.litrosA15Grados();
  }
}

litrosA15Grados() {  
  let res:number=this.calculoService.convertirLitrosNaturalesEn15Grados(this.temperaturaFormControl.value,this.densidadFormControl.value,this.aforadorInicialFormControl.value,this.aforadorFinalFormControl.value);
  this.volumen15FormControl.setValue(res);
}

usuarioCambioComboPico(event){
  let pico = this.picosAsociadosFormControl.value;
  this.tipoPicoFormControl.setValue(pico.tipoPico ? pico.tipoPico : null);
}

formatDate(year, month, day, hour, minute): string {
  return year + '-' + month.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + '-' + day.toLocaleString(undefined, { minimumIntegerDigits: 2 })
    + 'T' + hour.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + minute.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':00';
}

obtenerUsuario(usuario: Operador) {
  var u = new Usuario();
  u.dni = usuario.dni
  //Se agrega a "nombreCompleto" el nombre del usuario logueado con el fin de que aparezca como responsable en la tabla de Movimientos 
  //u.nombreCompleto = this.usuarioService.getNombresCompletosUsuario();
  u.nombreCompleto = usuario.nombresCompletos;
  u.userName = usuario.userName;
  u.tipoUsuario = usuario.tipoUsuario;

  return u;
}

obtenerUsuarioLogueado() {
  var u = new Usuario();
  u.dni = this.usuarioService.getDniUsuario();
  u.nombreCompleto = this.usuarioService.getNombresCompletosUsuario();
  u.userName = this.usuarioService.getUsername();  
  u.tipoUsuario = TipoUsuario[this.usuarioService.getTipoUsuario()];

  return u;
}

/** Servicios */

getAeroespecialidades(): void {
  this.cargandoAeroespecialidades = true;

  this.despachoService.getAeroespecialidadesParaAeroplantaActual(this.aerovaleReemplaza.origen.codigoAeroplanta)
    .subscribe(aeroespecialidades => {
      this.aeroespecialidadesSeleccionables = aeroespecialidades;
      if(this.aeroespecialidadesSeleccionables.length>0) {
        this.completarAeroespecialidades();
      }
      this.cargandoAeroespecialidades = false;

    }, () => {
      this.errorAeroespecialidades = true;
      this.cargandoAeroespecialidades = false;
    });
}

getClientes(): void {
  this.cargandoClientes = true;
  this.clienteService.getClientesActivos()
    .subscribe(clientes => {
      clientes = clientes.map(a => Object.assign(new CuentaCliente(), a));
      this.clientes = clientes;
      this.cargandoClientes = false;
      this.entidadesDescargadas$.next();
      if(this.clientes.length>0) {        
        this.clienteFormControl.setValue(this.clientes.find(c => c.ctA_SGC == this.aerovaleReemplaza.cliente.numeroCuenta));
        this.getVehiculosByCliente(this.aerovaleReemplaza.cliente);
      }
    }, () => this.errorClientes = true);
}

getProductos() {
  this.productoService.obtenerProductos()
    .subscribe(result => {
      this.productos = result;
      this.entidadesDescargadas$.next();
    });
}

getOperadores(): void {
  this.cargandoOperadores = true;
  this.aerovaleService.getOperadores(0, this.usuarioService.getAeroplantaActual().codigoAeroplanta)
    .subscribe(result => {
      this.operadores = result.map(a => Object.assign(new Operador(), a));
      //this.operadores = operadores;
      this.cargandoOperadores = false;
      if(this.operadores.length>0) {        
        this.operadorFormControl.setValue(this.operadores.find(o => o.nombresCompletos.toLowerCase().trim() == this.aerovaleReemplaza.operadorDTO.nombreCompleto.toLowerCase().trim()));
      }
    }
  );
}
getOperadoresConRoles(): void {
  this.cargandoOperadores = true;
  var roles = this.usuarioService.getRolesEmitirAerovales();
  this.aerovaleService.getOperadoresConRoles(this.operadores, roles, this.usuarioService.getAeroplantaActual().codigoAeroplanta)
    .subscribe(result => {
      this.cargandoOperadores = true;
      this.operadores = result.map(a => Object.assign(new Operador(), a));
      //this.operadores = operadores;
      this.cargandoOperadores = false;
      this.entidadesDescargadas$.next();
      if(this.operadores.length>0) {        
        this.operadorFormControl.setValue(this.operadores.find(o => o.nombresCompletos.toLowerCase().trim() == this.aerovaleReemplaza.operadorDTO.nombreCompleto.toLowerCase().trim()));
      }
    }
  );
}

getAbastecedoras(): void {
  this.cargandoAbastecedoras = true;
  this.aerovaleService.getTodasLasExpendedoras(this.usuarioService.getAeroplantaActual().codigoAeroplanta)
    .subscribe(result => {
      this.abastecedoras = result;
      this.abastecedoras = this.filtrarAbastecedorasPorTipoDeCombustible(TipoProducto.Aerocombustible);
      this.cargandoAbastecedoras = false;
      if(this.abastecedoras.length>0) {
        this.abastecedoraFormControl.setValue(this.abastecedoras.find(a => a.codigo == this.aerovaleReemplaza.abastecedora.codigo));
        this.aerocombustibleFormControl.setValue(this.abastecedoraFormControl.value.productoActual.nombreProducto);
        let picoAsociado:Pico = this.abastecedoras.filter(a => a.codigo == this.aerovaleReemplaza.abastecedora.codigo)[0].picos.find( p=> p.id == this.aerovaleReemplaza.detalleAerocombustible.picoId);
        if(picoAsociado!=null) {
          this.tipoPicoFormControl.setValue(picoAsociado.tipoPico);
          if(this.tipoPicoFormControl.value==TipoPico.Carter) {
            this.presionMangueraFormControl.setValue(this.aerovaleReemplaza.detalleAerocombustible.presionPuntaManguera);
            this.presionDiferencialFormControl.setValue(this.aerovaleReemplaza.detalleAerocombustible.presionDiferencial);
            this.caudalFormControl.setValue(this.aerovaleReemplaza.detalleAerocombustible.caudalObservado);
          }
        }
        if(this.abastecedoraFormControl.value.tipoExpendedora == TipoExpendedora.SURTIDOR) {
          this.obteniendoTanquesSurtidor = true;
          this.surtidorService.getSurtidoresPorCodigo(this.aerovaleReemplaza.abastecedora.codigo)
            .subscribe(result => {
              this.surtidores = result;
              this.obteniendoTanquesSurtidor = false;
              this.tanquesAsociadosFormControl.setValue( this.aerovaleReemplaza.detalleAerocombustible.codigoTanque );
            });          
        }
      }
      this.cargandoAbastecedoras = false;
      this.entidadesDescargadas$.next();
    });
}

buscarTanques_y_Picos(){
  if (this.abastecedoraFormControl.value.tipoExpendedora == TipoExpendedora.SURTIDOR) {
    this.obteniendoTanquesSurtidor = true;
    this.surtidorService.getSurtidoresPorCodigo(this.abastecedoraFormControl.value.codigo)
      .subscribe(result => {
        this.surtidores = result;
        this.obteniendoTanquesSurtidor = false;
      })
  } else if (this.abastecedoraFormControl.value.tipoExpendedora != TipoExpendedora.SURTIDOR) { // Camion
    this.tanquesAsociadosFormControl.setValue(null);
  }

  for (let pico of this.abastecedoraFormControl.value.picos) {
    if (pico.porDefecto) {
      this.picosAsociadosFormControl.setValue(pico);
      this.tipoPicoFormControl.setValue(pico.tipoPico);
    }
  }

  this.tanquesAsociadosFormControl.updateValueAndValidity();
  this.aerocombustibleFormControl.setValue(this.abastecedoraFormControl.value.producto.nombreProducto);
  this.validarDensidad(this.densidadFormControl.value);
  if(this.aerovaleReemplaza.detalleAerocombustible.tipoVuelo == TipoVuelo.Internacional && 
    this.destinoFormControl.value != undefined && this.abastecedoraFormControl.value != undefined && this.clienteFormControl.value != undefined) {
      this.getRancho();
  }
  this.controlVehiculoProducto(); 
}

getRancho() { 
  this.cargandoRancho = true;
  this.ranchoService.getRanchoPorCUIT(
    this.usuarioService.getAeroplantaActual().codigoAeroplanta,
    this.destinoFormControl.value.codigoPais,
    this.abastecedoraFormControl.value.productoActual.codigoProducto,
    this.clienteFormControl.value.numeroDocumento,
    this.formatDate(
      this.fechaFinDespachoFormControl.value.year, this.fechaFinDespachoFormControl.value.month,
      this.fechaFinDespachoFormControl.value.day, this.horaFinDespachoFormControl.value.hour, this.horaFinDespachoFormControl.value.minute)
        ).subscribe(rancho => {
          (rancho!=null) ? this.ranchoCodigo = rancho.codigoRancho : this.ranchoCodigo = null;
          this.cargandoRancho = false;
        },
        (error: ErrorModel) => {
          this.messageService.showErrorMessage("Error al buscar el rancho " + error.description);
          this.cargandoRancho = false;
        })
}

/** aerovales de aerocombustibles c/eroespecialidades incluidas */

nuevaAeroespecialidadItem(item: any, valor:number): UntypedFormGroup {
  return this.fb.group({
    nombreProducto: [item.nombreProducto, Validators.required],      
    cantidad: [valor, [Validators.required, Validators.min(1)]],
    codigoProducto: [item.codigoProducto],
  })
}  

completarAeroespecialidades() {   
  this.aeroespecialidadesSeleccionadasFormControlArray.clear();
  let item:any;
  for(let ua of this.aerovaleReemplaza.aeroespecialidades) {     
    item = { nombreProducto: ua.nombreProducto, codigoProducto: ua.productoCodigo }
    this.aeroespecialidadesSeleccionadasFormControlArray.push(this.nuevaAeroespecialidadItem(item, ua.cantidad));      
    this.arreglo.push(this.aeroespecialidadesSeleccionables.find( a => a.codigoProducto == ua.productoCodigo ));
    this.aeroespecialidadesSeleccionables=this.aeroespecialidadesSeleccionables.filter( a => a.codigoProducto != ua.productoCodigo );
  }
}

onAeroespecialidadSeleccionada(){
  if(this.aeroespecialidadesSeleccionablesFormControl.value){
    this.aeroespecialidadesSeleccionadasFormControlArray.push(this.nuevaAeroespecialidadItem(this.aeroespecialidadesSeleccionablesFormControl.value, null));
    let p = this.aeroespecialidadesSeleccionables.indexOf(this.aeroespecialidadesSeleccionablesFormControl.value);
    this.arreglo.push(this.aeroespecialidadesSeleccionables[p]);
    this.aeroespecialidadesSeleccionables.splice(p, 1);      
  }
}

onAeroespecialidadEliminada(i:number) {
  let aero = this.arreglo.filter(x => x.codigoProducto == this.aeroespecialidadesSeleccionadasFormControlArray.value[i].codigoProducto); 
  this.aeroespecialidadesSeleccionables.push(aero[0]);
  let pos = this.arreglo.findIndex(x => x.codigoProducto == this.aeroespecialidadesSeleccionadasFormControlArray.value[i].codigoProducto );
  this.arreglo.splice(pos, 1);
  this.aeroespecialidadesSeleccionadasFormControlArray.removeAt(i);
  this.aeroespecialidadesSeleccionablesFormControl.setValue(null);
}

procesarAeroespecialidades() {
  let listaAeroespecialidades = new Array<Aeroespecialidad>();

    for (let i = 0; i < this.aeroespecialidadesSeleccionadasFormControlArray.length; i++) {
      let cantidad = this.aeroespecialidadesSeleccionadasFormControlArray.value[i].cantidad;
      let nombre = this.aeroespecialidadesSeleccionadasFormControlArray.value[i].nombreProducto;
      let codigo = this.aeroespecialidadesSeleccionadasFormControlArray.value[i].codigoProducto;

      let item = new Aeroespecialidad(cantidad, nombre, codigo);
      listaAeroespecialidades.push(item);
    }  

  return listaAeroespecialidades;
}

filtrarAbastecedorasPorTipoDeCombustible(tipoProducto: TipoProducto): Abastecedora[] {
  let filtrados: Abastecedora[] = [];
  for (let a of this.abastecedoras) {
    if (a.activo && a.producto.tipoProducto === tipoProducto) {
      filtrados.push(a);
    }
  }
  return filtrados;
}
//Función para agregarle un scroll a destino en reemplazo aerovale
typeaheadKeydown($event: KeyboardEvent) {
  if (this.typeaheadInstance.isPopupOpen()) {
    setTimeout(() => {
      const popup = document.getElementById(this.typeaheadInstance.popupId);
      const activeElements = popup.getElementsByClassName('active');
      if (activeElements.length === 1) {
        // activeElements[0].scrollIntoView();
        const elem = (activeElements[0] as any);
        popup.style.position = 'relative';
        popup.style.zIndex = '99999';
        popup.style.height = '80px'; 
        popup.style.overflowY = 'scroll';
        
        if (typeof elem.scrollIntoViewIfNeeded === 'function') {
          // funciona en chrome y en edge seguro...
          elem.scrollIntoViewIfNeeded();
        } 
      }
    });
  }
}
/**
 * Determina si una cadena se encuentra dentro de otra
 * @param searchValue Valor a buscar
 * @param searchIn Valores sobre los que buscar searchValue
 */
 containsString(searchValue: string, ...searchIn: string[]): boolean {
  const concat = searchIn.toString();
  return concat.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
}

getVehiculos(): void {
  this.cargandoVehiculos = true;
  this.vehiculosService.getAeronaves()
  .subscribe( a => {
    this.todosLosVehiculos = a;
    this.cargandoVehiculos = false;
    this.entidadesDescargadas$.next();
  }, ()=> {
    this.cargandoVehiculos = false;
    this.errorVehiculos = true
  })
}

getVehiculosByCliente(cliente: any): void {
  this.cargandoVehiculosDeCliente = true;
  let doc = cliente.nroDocumento==undefined ? cliente.numeroDocumento : cliente.nroDocumento;
  let cuenta = cliente.numeroCuenta==undefined ? cliente.ctA_SGC : cliente.numeroCuenta;
  this.vehiculosService.getCuentasVehiculos(cliente.tipoDocumento, doc)
  .subscribe( resp => {    
    this.vehiculosDeCliente = resp.filter( v => v.ctA_SGC == cuenta)[0].vehiculos.filter( a => a.tipoVehiculo==TipoVehiculo.Aeronave);    
    if (typeof (this.vehiculoFormControl.value) != 'string' && !this.cargandoInformacion){
      this.verificarVehiculo();
    }
    this.cargandoVehiculosDeCliente = false;
    this.entidadesDescargadas$.next();
  },  () => {
    this.cargandoVehiculosDeCliente = false;
    this.errorVehiculosDeCliente = true
  });
}

getAeropuertos(): void {
  this.cargandoAeropuertos = true;
  this.aeropuertoService.getAeropuertos()
    .subscribe(aeropuertos => {
      this.aeropuertos = aeropuertos;
      if(this.aeropuertos.length>0) {        
        this.destinoFormControl.setValue(this.aeropuertos.find( p => p.codigoAeropuerto == this.aerovaleReemplaza.detalleAerocombustible.codigoAeropuerto));        
      }
      this.cargandoAeropuertos = false;
      this.entidadesDescargadas$.next();
    }, () => this.errorDestinos = true)
}

/** Input Operador */

searchOperador = (text$: Observable<string>) =>
text$.pipe(
  debounceTime(200),
  distinctUntilChanged(),
  map(term => this.operadores == null || term.length < 2 || this.cargandoOperadores ? [] :
    this.operadores.filter(op => this.containsString(this.operadorFormControl.value, op.nombresCompletos, op.dni)).slice(0, 10))
);

formatterOperador = (op: { nombresCompletos: string, dni: string }) => `${op.nombresCompletos} - ${op.dni}`;

selectOperador(event: any): void {
  this.operadorFormControl.setValue(event.item);
}

/** Input Clientes */

searchCliente = (text$: Observable<string>) =>
text$.pipe(
  debounceTime(200),
  distinctUntilChanged(),
  map(term => this.clientes == null || term.length < 2 || this.cargandoClientes ? [] :
    this.clientes.filter(v => this.containsString(this.clienteFormControl.value, v.ctA_SGC, v.razonSocial)).slice(0, 10))
)

confirmCliente(): void {
  if (typeof (this.clienteFormControl.value) === 'string') {
    this.nuevoCliente(this.clienteFormControl.value);
  }else{
    if(this.aerovaleReemplaza.detalleAerocombustible.tipoVuelo == TipoVuelo.Internacional && 
      this.destinoFormControl.value != undefined && this.abastecedoraFormControl.value != undefined && this.clienteFormControl.value != undefined) {
        this.getRancho();
    } 
  }
}

formatterCliente = (x: { razonSocial: string, ctA_SGC: string }) => `${x.razonSocial} - ${x.ctA_SGC == null ? 'SIN CTA SGC' : x.ctA_SGC}`;

selectCliente(e: any): void {
  this.clienteFormControl.setValue(e.item);
  this.tipoPagoFormControl.setValue(this.clienteFormControl.value.tipoCuenta);
  this.getVehiculosByCliente((e.item as unknown as CuentaCliente));
}

nuevoCliente(clientText): void {
  const modalRef = this.modalService.open(NuevoClienteComponent);
  if (typeof (clientText) === 'string') {
    modalRef.componentInstance.nombreCliente = clientText; // should be the id
  }
  modalRef.result.then((cliente) => {
    this.clientes.push(cliente);
    this.clienteFormControl.setValue(cliente);
  }).catch((error) => {
    this.clienteFormControl.setValue(null);
  });
}

/** Input vehiculo o automotor */

//setea el aerocombustible de acuerdo a la aeronave seleccionada
changeVehiculo(): void {
  let encuentraVehiculo = this.vehiculoFormControl.value;
  //Esto es por si ingresan el valor abc123 pero existe una aeronave ABC123, entonces elige esta última
  if (typeof (this.vehiculoFormControl.value) === 'string') {
    encuentraVehiculo = this.todosLosVehiculos.filter(v => this.vehiculoHabilitado(v) ).find(p => p.matricula.toLowerCase().trim() == this.vehiculoFormControl.value.toLowerCase().trim());
  }

  //Si no encuentro una aeronave, ofrecemos crear una
  if (!encuentraVehiculo) {
    this.nuevoVehiculo(this.vehiculoFormControl.value);
  }else {
    this.vehiculoFormControl.setValue(encuentraVehiculo);
    this.verificarVehiculo();    
  }
}

vehiculoHabilitado(v : VehiculoDTO){
  if(v?.tipoVehiculo == TipoVehiculo.Aeronave){
    return true;
  }else{
    return false;
  } 
}

verificarVehiculo() {
  if (this.vehiculoFormControl.value) {
    if (this.clienteFormControl.value && this.clienteFormControl.value.id &&
      this.vehiculosDeCliente && this.vehiculosDeCliente.filter(value => value.matricula.toLowerCase() === this.vehiculoFormControl.value.matricula.toLowerCase()).length == 0) {
      const modalRef = this.modalService.open(ModalConfirmComponent, { centered: true });
      modalRef.componentInstance.mensaje = "El vehiculo no pertenece al cliente, ¿desea asignarlo de todas formas?"; // should be the id
      modalRef.result.then(() => {
      },
        () => {
          this.vehiculoFormControl.setValue(null);
        }
      );
    }
  }
}

obtenerTipoVehiculoPorTipoAerovale(): TipoVehiculo{
  return TipoVehiculo.Aeronave;
}

obtenerNumeracionAerovale() {
  if (this.aerovaleReemplaza) {
    return this.aerovaleReemplaza.prefijo+'-'+this.aerovaleReemplaza.numeroAerovale
  }else{
    return null
  }  
}

nuevoVehiculo(matriculaText: any): void {
  const modalRef = this.modalService.open(ModalVehiculoComponent, { backdrop: "static", centered: true });
  if (typeof (matriculaText) === 'string') {
    modalRef.componentInstance.matriculaIngresada = matriculaText.toUpperCase();
  }
  modalRef.componentInstance.tipoVehiculoParaFiltrar = TipoVehiculo.Aeronave;
  modalRef.componentInstance.vehiculos = this.todosLosVehiculos;    
  modalRef.result.then((vehiculo) => {
    this.messageService.showSuccessMessage("Vehiculo creado");
    this.todosLosVehiculos.push(vehiculo);
    this.vehiculoFormControl.setValue(vehiculo); 
  });
}

formatterVehiculo = (x: { matricula: string }) => x.matricula;

searchVehiculo = (text$: Observable<string>) =>
text$.pipe(
  debounceTime(200),
  distinctUntilChanged(),
  map(term => term.length < 2 || this.cargandoVehiculosDeCliente || isNullOrUndefined(this.vehiculosDeCliente) ? []
    : this.vehiculosDeCliente.filter(v => this.vehiculoHabilitado(v) && (v.matricula.toLowerCase().indexOf(this.vehiculoFormControl.value.toLowerCase()) > -1) ).slice(0, 10))
)

/** Destinos */

changeDestino(): void {
  if (typeof (this.destinoFormControl.value) === 'string' || this.destinoFormControl.value == null) {
    this.destinoFormControl.setValue(null);
    (this.tipoVueloFormControl.value == TipoVuelo.Internacional) ? this.destinoinvalid = true : this.destinoinvalid = false;
  } else {
    this.destinoinvalid = false;
    if(this.aerovaleReemplaza.detalleAerocombustible.tipoVuelo == TipoVuelo.Internacional && 
      this.destinoFormControl.value != undefined && this.abastecedoraFormControl.value != undefined && this.clienteFormControl.value != undefined) {
        this.getRancho();
    }      
  }
}

resetDestino(): void{
  this.destinoFormControl.setValue(null);
  this.ranchoCodigo = null;
  this.destinoinvalid = true;
}

verificarRancho(){
  if (this.destinoFormControl.value === ''){
    this.ranchoCodigo = null;
  }
}

selectAeropuerto(e: any): void {
  this.destinoFormControl.setValue(e.item);
  this.getRancho();
}

searchAeropuerto = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 || this.cargandoAeropuertos ? []
        : this.aeropuertos.filter(v => v.cabotaje == (this.tipoVueloFormControl.value === TipoVuelo.Cabotaje) &&
          (v.codigoAeropuerto + ' - ' + v.nombre + ' - ' + v.ciudadNombre).toLowerCase().indexOf(this.destinoFormControl.value.toLowerCase()) > -1).slice(0, 20))
    )

formatterAeropuerto = (x: { nombre: string, codigoAeropuerto: string }) => x.codigoAeropuerto + ' - ' + x.nombre;

/** Validaciones */

esCampoVacio(campo: any): boolean {
  return (campo == null || (campo != null && campo.length == 0));
}

validarDensidad(densidad) {
  if (densidad!=null){
    let productoSeleccionado = this.productos.find(element => element.codigoProducto===this.abastecedoraFormControl.value.producto.codigoProducto);
    let esValido = true;
    esValido = (densidad >= productoSeleccionado.densidadMin && densidad <= productoSeleccionado.densidadMax);
    this.densidadMin = productoSeleccionado.densidadMin;
    this.densidadMax = productoSeleccionado.densidadMax;
    return esValido;
  }
}

formatDensidad(event) {
  if (event.charCode == 8 || event.charCode == 0) return null;

  if (event.charCode >= 48 && event.charCode <= 57) {
    this.densidadFormControl.setValue(this.sharedService.formatDensidad(event));
  }
  return false;
}

temperaturaValidator(control: UntypedFormControl) {
  if (control.value > 100) {
    return { temperaturaFueraDeRango: true };
  }

  if (control.value <= -50) {
    return { temperaturaFueraDeRango: true };
  }

  return null;
}

controlVehiculoProducto() {
  if (this.abastecedoraFormControl.value instanceof Object && this.vehiculoFormControl.value instanceof Object && !this.cargandoAbastecedoras && !this.cargandoVehiculos) {      
    if(this.vehiculoFormControl.value.codigoProducto != this.abastecedoraFormControl.value.producto.codigoProducto)
      this.combustibleNoCoincide = true;
    else 
      this.combustibleNoCoincide = false;
  }
}

/*
pruebaDeAguaValidator(control: FormControl) {
  if (control.value == ResultadoPruebaDeAgua.Negativa) {
    return null;
  }

  return { negativoRequired: true };
}
*/
cantidadDeCaracteres(n: any): number {
  if (n != null) {
    return n.length;
  }
  return 0;
}

accionarForm() {
  Object.keys(this.aerovalesForm.controls).forEach(key => {
    this.aerovalesForm.get(key).markAsDirty();
  });
}

reemplazarAerovale() {

  this.messageService.clear();

  if (new Date(this.fechaFinDespachoFormControl.value.year, this.fechaFinDespachoFormControl.value.month, this.fechaFinDespachoFormControl.value.day, this.horaFinDespachoFormControl.value.hour, this.horaFinDespachoFormControl.value.minute) <= new Date(this.fechaInicioDespachoFormControl.value.year, this.fechaInicioDespachoFormControl.value.month, this.fechaInicioDespachoFormControl.value.day, this.horaInicioDespachoFormControl.value.hour, this.horaInicioDespachoFormControl.value.minute)) {
    this.messageService.showErrorMessage("La fecha/hora de fin de despacho debe ser posterior a la fecha/hora de inicio de despacho.");
    return;
  }
  if ((this.destinoFormControl.value === "" || this.destinoFormControl.value === null) && this.tipoVueloFormControl.value === TipoVuelo.Internacional) {
    this.messageService.showErrorMessage("Debe completar el destino si el TipoVuelo es Internacional.");
    return;
  }

  if (this.abastecedoraFormControl.value.tipoExpendedora == TipoExpendedora.SURTIDOR && this.tanquesAsociadosFormControl.value == null) { // abastecedora surtidor
    this.messageService.showErrorMessage("Debe completar el tanque de extracción del combustible.");
    return;
  }

  if (this.picosAsociadosFormControl.value == null) {
    this.messageService.showErrorMessage("Debe seleccionar un aforador de la abastecedora.");
    return;
  }
  
  if (this.tipoPicoFormControl.value == null) {
    this.messageService.showErrorMessage("Debe seleccionar el tipo de pico (CARTER/GATILLO).");
    return;
  }

  if (this.tipoPicoFormControl.value == TipoPico.Carter) {
    if (this.presionMangueraFormControl.value == null || this.presionMangueraFormControl.value == "") {
      this.messageService.showErrorMessage("Debe completar el campo presion punta manguera.");
      return;
    } else {
      if (Number(this.presionMangueraFormControl.value) < 0 || Number(this.presionMangueraFormControl.value) > 50) {
        this.messageService.showErrorMessage("La presión de punta de manguera debe estar en el rango de 0 a 50 Psi.");
        return;
      }
    }

    if (this.presionDiferencialFormControl.value == null || this.presionDiferencialFormControl.value == "") {
      this.messageService.showErrorMessage("Debe completar el campo presion diferencial.");
      return;
    } else {
      if (Number(this.presionDiferencialFormControl.value) < 0 || Number(this.presionDiferencialFormControl.value) > 15) {
        this.messageService.showErrorMessage("La presión diferencial debe estar en el rango de 0 a 15 Psi.");
        return;
      }
    }

    if (this.caudalFormControl.value == null || this.caudalFormControl.value == "") {
      this.messageService.showErrorMessage("Debe completar el campo caudal observado.");
      return;
    } else {
      if (Number(this.caudalFormControl.value) < 0 || Number(this.caudalFormControl.value) > 5000) {
        this.messageService.showErrorMessage("El caudal debe estar en el rango de 0 a 5000 Lts/Min.");
        return;
      }
    }
  }

  if (!this.validarDensidad(this.densidadFormControl.value)) {
    this.messageService.showErrorMessage("La densidad debe estar dentro del rango del producto. ["+ this.densidadMin +"-"+ this.densidadMax + "]");
    return;
  }

  this.accionarForm();

  if (this.aerovalesForm.valid) {
    try {
      this.spinner.show();
      this.aerovaleCreadoCorrectamente = false;
      //Si pasa un minuto se esconde el cartel azul (spinner)
      setTimeout(() => {
        this.spinner.hide();
        this.guardandoAerovale = false;
        if (!this.aerovaleCreadoCorrectamente) {
          this.messageService.showErrorMessage("No se pudo procesar el aerovale, por favor vuelva a intentar");
        }        
      }, this.tiempo); // frontdoor
      
      let aerovale = new AerovaleDetalle();

      let cuentaCliente: CuentaCliente = this.clienteFormControl.value;
      let clienteDespacho: Cliente = new Cliente();
      clienteDespacho.numeroCuenta = cuentaCliente.ctA_SGC;
      clienteDespacho.razonSocial = cuentaCliente.razonSocial;
      clienteDespacho.tipoDocumento = cuentaCliente.tipoDocumento;
      clienteDespacho.nroDocumento = cuentaCliente.numeroDocumento;
      clienteDespacho.condicionIva = cuentaCliente.tipoCliente;
      clienteDespacho.tipoPago = cuentaCliente.tipoCuenta;

      aerovale.origen = this.aerovaleReemplaza.origen;
      aerovale.cliente = clienteDespacho;
      aerovale.tipoPago = this.tipoPagoFormControl.value;
      
      aerovale.aeroespecialidades = this.procesarAeroespecialidades();
      //aerovale.aeroespecialidades = [];      
      
      aerovale.fechaHoraInicioDespacho = this.formatDate(
        this.fechaInicioDespachoFormControl.value.year, this.fechaInicioDespachoFormControl.value.month,
        this.fechaInicioDespachoFormControl.value.day, this.horaInicioDespachoFormControl.value.hour, this.horaInicioDespachoFormControl.value.minute);
      
        aerovale.fechaHoraFinDespacho = this.formatDate(
        this.fechaFinDespachoFormControl.value.year, this.fechaFinDespachoFormControl.value.month,
        this.fechaFinDespachoFormControl.value.day, this.horaFinDespachoFormControl.value.hour, this.horaFinDespachoFormControl.value.minute);

      aerovale.fechaHoraLlegada = this.formatDate(
        this.fechaLlegadaFormControl.value.year, this.fechaLlegadaFormControl.value.month,
        this.fechaLlegadaFormControl.value.day, this.horaLlegadaFormControl.value.hour, this.horaLlegadaFormControl.value.minute);

      aerovale.vehiculo = this.vehiculoFormControl.value;

      aerovale.operador = null;      
      aerovale.supervisor = null;
      aerovale.operadorDTO = this.obtenerUsuario(this.operadorFormControl.value);
      aerovale.supervisorDTO = this.obtenerUsuarioLogueado();

      aerovale.volumen = this.volumenDespachadoFormControl.value;
      aerovale.producto = this.abastecedoraFormControl.value.producto;
      aerovale.abastecedora = this.abastecedoraFormControl.value;

      aerovale.tipoAerovale = TipoAerovale.Aerocombustible;
      aerovale.vuelo = this.numeroVueloFormControl.value;

      let detalle = new AerovaleDetalleAerocombustible();
      detalle.vuelo = this.numeroVueloFormControl.value;
      detalle.tipoVuelo = this.tipoVueloFormControl.value;
      if(this.destinoFormControl.value) {
        detalle.codigoAeropuerto = this.destinoFormControl.value.codigoAeropuerto;
        detalle.destino = this.destinoFormControl.value.nombre;
        detalle.nombreCiudadAeropuerto = this.destinoFormControl.value.nombre;
      }
      
      detalle.temperatura = this.temperaturaFormControl.value;
      detalle.densidad = this.densidadFormControl.value;
      detalle.pruebaAgua = true; // negativa
      if(this.ranchoCodigo && this.tipoVueloFormControl.value == TipoVuelo.Internacional) {
        detalle.rancho = this.ranchoCodigo;
      }      
      detalle.aforadorInicio = this.aforadorInicialFormControl.value;
      detalle.aforadorFinal = this.aforadorFinalFormControl.value;
      detalle.aforadorInicialEditado = this.aerovaleReemplaza.detalleAerocombustible.aforadorInicialEditado;
      detalle.aforadorFinalEditado = this.aerovaleReemplaza.detalleAerocombustible.aforadorFinalEditado;
      detalle.volumen15Grados = this.volumen15FormControl.value;
      if(this.tipoPicoFormControl.value == TipoPico.Carter) {
        detalle.presionPuntaManguera = this.presionMangueraFormControl.value !=null ? this.presionMangueraFormControl.value : 0;
        detalle.presionDiferencial = this.presionDiferencialFormControl.value !=null ? this.presionDiferencialFormControl.value : 0;
        detalle.caudalObservado = this.caudalFormControl.value !=null ? this.caudalFormControl.value : 0;
      }      
      if(typeof (this.picosAsociadosFormControl.value) === 'string') {
        detalle.picoId = this.picosAsociadosFormControl.value
      } else {
        detalle.picoId = this.picosAsociadosFormControl.value.id
      }   
      if(this.abastecedoraFormControl.value.tipoExpendedora == TipoExpendedora.SURTIDOR) {
        detalle.codigoTanque = this.tanquesAsociadosFormControl.value;
      }

      aerovale.detalleAerocombustible = detalle;
      aerovale.detalleCombustible = null;
      aerovale.servicios = [];

      aerovale.ciudadIdAerovale = this.aerovaleReemplaza.origen.ciudadId;      

      if(this.fileAerovaleFisico) {
        this.guardandoAerovale = true;
        const carga: CargaArchivo = new CargaArchivo(this.fileAerovaleFisico.name);
        this.stockService.obtenerSas(TipoBlobStorage.firmaoperador, true).subscribe((sas: Sas) => {
          let aborter = this.azureBlobUpload.uploadFileToBlobStorage(this.fileAerovaleFisico, sas.containerUri, sas.sas,
            (pro) => {
              carga.progress = pro;
            },
            (val: BlobUploadCommonResponse) => {
                if (val) {
                  carga.progress = 100;                  
                  //let url = val?._response?.request?.url;

                  aerovale.firmaOperador = "";
                  aerovale.firmaCliente = "";
                  aerovale.pathFirmaOperador = this.fileAerovaleFisico.name;
                  aerovale.pathFirmaCliente = this.fileAerovaleFisico.name;
                  
                  this.aerovaleService.putReemplazarAerovales(this.aerovaleReemplaza.id, aerovale)
                  .subscribe(respuesta => {
                    if (respuesta.warnings != null && respuesta.warnings.length > 0) {
                      for (let entry of respuesta.warnings) {                
                        this.messageService.showWarningMessage(entry, true);
                      }
                    }
                    if(respuesta) {
                      this.messageService.showSuccessMessage("El Aerovale fue reemplazo con éxito. Nro.: "+respuesta.prefijo+"-"+respuesta.numeroAerovale);
                      this.ocultarSpinner(true);
                      this.modal.close(true);
                    }                    
                  }, (err:ErrorModel) => {
                    // si falla el PUT de aerovales
                    this.messageService.showErrorMessage("Error al reemplazar el aev. " + err.description);
                    this.ocultarSpinner(false);
                  });
                }
            });  
            carga.setAborter(aborter);            
        }, (error)=> {
            // si falla el obtenerSas            
            this.messageService.showErrorMessage("No se pudo procesar el aerovale, por favor vuelva a intentar");
            this.ocultarSpinner(false);
        }); 
      }else{
        // al momento de abrir la modal valido el archivo fisico, pero cierro la llamada por las dudas
        this.messageService.showErrorMessage("El archivo no se capturo correctamente, vuelva intentarlo.");
        this.ocultarSpinner(false);
      }      
    } catch (error) {
      this.messageService.showErrorMessage("Error en proceso "+error);      
      this.ocultarSpinner(false);
    }
  }
}

ocultarSpinner(result:boolean) {
  this.guardandoAerovale = false;
  this.spinner.hide();
  result ? this.aerovaleCreadoCorrectamente = true : this.aerovaleCreadoCorrectamente = false;
}

 /** getters */

 get mostrarAbastecedora() {
  return (this.abastecedoraFormControl.value != null && this.abastecedoras.length>0);
}

 get numeroAerovaleFormControl() {
  return this.aerovalesForm.get('numeroAerovaleFormControl');
}

get clienteFormControl() {
  return this.aerovalesForm.get('clienteFormControl');
}

get operadorFormControl() {
  return this.aerovalesForm.get('operadorFormControl');
}

get vehiculoFormControl() {
  return this.aerovalesForm.get('vehiculoFormControl');
}

get productoFormControl() {
  return this.aerovalesForm.get('productoFormControl');
}

get tipoPagoFormControl() {
  return this.aerovalesForm.get('tipoPagoFormControl');
}

get tipoVueloFormControl() {
  return this.aerovalesForm.get('tipoVueloFormControl');
}

get destinoFormControl() {
  return this.aerovalesForm.get('destinoFormControl');
}

get numeroVueloFormControl() {
  return this.aerovalesForm.get('numeroVueloFormControl');
}

get aerocombustibleFormControl() {
  return this.aerovalesForm.get('aerocombustibleFormControl');
}

get temperaturaFormControl() {
  return this.aerovalesForm.get('temperaturaFormControl');
}

get densidadFormControl() {
  return this.aerovalesForm.get('densidadFormControl');
}

get aforadorInicialFormControl() {
  return this.aerovalesForm.get('aforadorInicialFormControl');
}

get aforadorFinalFormControl() {
  return this.aerovalesForm.get('aforadorFinalFormControl');
}

get presionMangueraFormControl() {
  return this.aerovalesForm.get('presionMangueraFormControl');
}

get presionDiferencialFormControl() {
  return this.aerovalesForm.get('presionDiferencialFormControl');
}

get caudalFormControl() {
  return this.aerovalesForm.get('caudalFormControl');
}

get volumenDespachadoFormControl() {
  return this.aerovalesForm.get('volumenDespachadoFormControl');
}

get volumen15FormControl() {
  return this.aerovalesForm.get('volumen15FormControl');
}

/*
get pruebaDeAguaFormControl() {
  return this.aerovalesForm.get('pruebaDeAguaFormControl');
}
*/

get fechaLlegadaFormControl() {
  return this.aerovalesForm.get('fechaLlegadaFormControl');
}

get horaLlegadaFormControl() {
  return this.aerovalesForm.get('horaLlegadaFormControl')
}

get fechaInicioDespachoFormControl() {
  return this.aerovalesForm.get('fechaInicioDespachoFormControl');
}

get horaInicioDespachoFormControl() {
  return this.aerovalesForm.get('horaInicioDespachoFormControl');
}

get fechaFinDespachoFormControl() {
  return this.aerovalesForm.get('fechaFinDespachoFormControl');
}

get horaFinDespachoFormControl() {
  return this.aerovalesForm.get('horaFinDespachoFormControl');
}

get abastecedoraFormControl() {
  return this.aerovalesForm.get('abastecedoraFormControl');
}

get tanquesAsociadosFormControl() {
  return this.aerovalesForm.get('tanquesAsociadosFormControl');
}

get picosAsociadosFormControl() {
  return this.aerovalesForm.get('picosAsociadosFormControl');
}

get tipoPicoFormControl() {
  return this.aerovalesForm.get('tipoPicoFormControl');
}

get cabotajeFormControl() {
  return this.aerovalesForm.get('cabotajeFormControl');
}

get internacionalFormControl() {
  return this.aerovalesForm.get('internacionalFormControl');
}

/** getters Aeroespecialidades */
get aeroespecialidadesSeleccionablesFormControl() {
  return this.aerovalesForm.get('aeroespecialidadesSeleccionablesFormControl');
}

get aeroespecialidadesSeleccionadasFormControlArray() : UntypedFormArray {
  return this.aerovalesForm.get('aeroespecialidadesSeleccionadasFormControlArray') as UntypedFormArray;
}
}