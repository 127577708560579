import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TipoAerovale } from 'src/app/shared/enums/tipoAerovale';

@Component({
  selector: 'app-seleccion-tipo-aerovale',
  templateUrl: './seleccion-tipo-aerovale.component.html',
  styleUrls: ['./seleccion-tipo-aerovale.component.css']
})
export class SeleccionTipoAerovaleComponent implements OnInit {

  enumTipoAerovale = TipoAerovale;

  constructor(public activeModal: NgbActiveModal) {

  }

  ngOnInit() {
  }

  onTipoAerovaleSeleccionado(tipoAerovale: TipoAerovale) {
    this.activeModal.close(tipoAerovale);
  }

}
