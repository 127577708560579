import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HandleError, HttpErrorHandler } from '../http-error-handler.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Rancho } from '../shared/models/ranchos/Rancho';
import { catchError, map, tap } from 'rxjs/operators';
import { UrlApi } from '../shared/enums/urlApi';
import { RanchoV2 } from '../shared/models/ranchos/RanchoV2';
import { RemitosRanchos } from '../shared/models/ranchos/RemitosRanchos';
import { ConsumoRancho } from '../shared/models/ranchos/ConsumoRancho';
import { Usuario } from '../shared/models/ranchos/Usuario';

@Injectable({
  providedIn: 'root'
})
export class RanchoService {

  baseUrl = environment.apiServer.ranchosBaseUrl;
  private ranchosUrl = this.baseUrl + UrlApi.ranchos + '/';
  private ranchosV2Url = this.baseUrl + UrlApi.ranchosV2;
  private consumosRanchosUrl = this.baseUrl + UrlApi.consumos + '/';
  private facturacionUrl = environment.apiServer.facturacionBaseUrl + "/ComprobantesV1";
  private handleError: HandleError;

  constructor(private httpClient: HttpClient, private httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError("Ranchos")
  }

  getRancho(codigoAeroplanta: string, codigoPais: string, codigoProducto: string, cuentaSGC: string, fecha?: string): Observable<Rancho> {
    return this.httpClient.get<Rancho>(this.ranchosUrl + 'Rancho', {
      params: {
        CodigoAeroplanta: codigoAeroplanta,
        CodigoDestino: codigoPais,
        CTA_SGC: cuentaSGC,
        CodigoProducto: codigoProducto,
        fecha: fecha? fecha : ''
      }
    })
      .pipe(
        tap(a => console.log(a)),
        map(a => a == null ? null : Object.assign(new Rancho(), a)),
        catchError(this.handleError('Obtener rancho', null))
      );
  }

  getRanchoPorCUIT(codigoAeroplanta: string, codigoPais: string, codigoProducto: string, cuit: string, fecha?: string): Observable<Rancho> {
    return this.httpClient.get<Rancho>(this.ranchosUrl + 'RanchoPorCuit', {
      params: {
        CodigoAeroplanta: codigoAeroplanta,
        CodigoDestino: codigoPais,
        cuit: cuit,
        CodigoProducto: codigoProducto,
        fecha: fecha? fecha : ''
      }
    })
      .pipe(
        tap(a => console.log(a)),
        map(a => a == null ? null : Object.assign(new Rancho(), a)),
        catchError(this.handleError('Obtener rancho', null))
      );
  }

  getRanchos(fechaDesde: string, fechaHasta: string, codigoAeroplanta: string): Observable<Rancho[]> {
    return this.httpClient.get<Rancho[]>(this.ranchosUrl, {
      params: {
        fechaDesde: fechaDesde,
        fechaHasta: fechaHasta,
        codigoAeroplanta: codigoAeroplanta,
      }
    })
    .pipe(
      map(rta => rta.map(r => Object.assign(new Rancho(), r)),
        catchError(this.handleError('Obtener Ranchos', new Rancho()))
      ));
  }
  
  getRanchosPendientes(page: number, pageSize:number, codigoAeroplanta: string): Observable<RanchoV2[]> {
    return this.httpClient.get<RanchoV2[]>(this.ranchosV2Url + "/RanchosPendientes?page=" + page.toString() + "&pageSize=" + pageSize.toString() + "&codigoAeroplanta=" + codigoAeroplanta);
  }

  getRanchosConsumidos(page: number, pageSize:number, codigoAeroplanta: string, fecha: string): Observable<RanchoV2[]> {
    return this.httpClient.get<RanchoV2[]>(this.ranchosV2Url + "/RanchosConsumidos?page=" + page.toString() + "&pageSize=" + pageSize.toString() + "&codigoAeroplanta=" + codigoAeroplanta + "&fecha=" + fecha);
  }

  putConsumosRanchosV2(listaIds: string[]): Observable<RemitosRanchos[]> {
    return this.httpClient.put<RemitosRanchos[]>(this.facturacionUrl +  "/RemitosParaRanchos", listaIds);
  }

  validarAFIP(ranchoId: string, codigoRancho:string, codigoAeroplanta:string, usuario: Usuario) : Observable<string> {
    return this.httpClient.put<string>(this.ranchosUrl + ranchoId + "/ValidarRanchoAFIP?codigoRancho=" + codigoRancho + "&codigoAeroplanta=" + codigoAeroplanta, usuario);
  }

  putAsignarNumeroRancho(ranchoId: string, codigoRancho:string, rancho: RanchoV2) : Observable<RanchoV2> {  
    return this.httpClient.put<RanchoV2>(this.ranchosUrl + ranchoId + "/AsignarNumeroDeRancho?codigoRancho=" + codigoRancho,rancho)    
  }

  eliminarRanchosInvidual(ranchoId:string) {
    return this.httpClient.delete(this.ranchosUrl + ranchoId + "/EliminarRancho", {responseType: 'text'})
  }

  putDesactivarRanchos(ranchoId:string, usuario: Usuario) {
    return this.httpClient.put(this.ranchosUrl + ranchoId + "/DesactivarRancho", usuario, {responseType: 'text'})
  }

  eliminarRanchosMasivos(numeroLote:string) {
    return this.httpClient.delete(this.ranchosUrl + numeroLote + "/EliminarLoteRancho", {responseType: 'text'});
  }

  putActualizarEstadoConsumosRanchosV2(aerovaleId:string, estado:number, usuario: Usuario) : Observable<ConsumoRancho> {
    return this.httpClient.put<ConsumoRancho>(this.consumosRanchosUrl + aerovaleId + "/ActualizarEstadoConsumoRancho?estadoConsumo=" + estado, usuario);
  }

}