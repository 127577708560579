import { Producto } from './Producto';
import { Contenedor } from './Contenedor';

export class TanqueGeneral extends Contenedor{
    // id: string;
    // nombre: string;
    // codigo: string;
    // productoActual: Producto;
    // activo: boolean;
    // capacidad: number;
    tanques;
}
