<div class="modal-header bg-light">
  <h4 class="modal-title">Selección de cliente para factura contado</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close(null)">
    
  </button>
</div>

<div class="modal-body">

  <div id="contenedor-seleccion-cliente" *ngIf="mostrarBuscadorClientes" [formGroup]="formBuscador">
    
    <div class="row">
      <div class="mb-3 row col-md-12">
        <label class="col-sm-12 col-form-label">El importe de la factura hace necesario identificar al cliente explicitamente.</label>
        <label class="col-sm-12 col-form-label">Se listarán aqui solo clientes "Consumidor Final" de "Contado".</label>
      </div>
    </div>
  
    <div class="row">
      <div class="mb-3 row col-md-12">
        <label class="col-sm-12 col-form-label">Busque al cliente por razon social / CUIT / cta. SGC:</label>
      </div>
    </div>
  
    <div class="row">
      <div class="mb-3 row col-md-12">
        <label for="clienteFormControl" class="col-sm-4 col-form-label">Cliente:</label>
        <div class="col-sm-8">
          <input id="clienteFormControl" [(ngModel)]="modelCliente" type="text" class="form-control" formControlName="clienteFormControl"
            [inputFormatter]="formatterCliente" [resultFormatter]="formatterCliente"
            title="Busque al cliente por razon social / CUIT / cta. SGC." (change)="inputClienteTextChange()"
            [ngbTypeahead]="searchCliente" (selectItem)="seleccionoCliente($event)" />
          <loading-text-spinner *ngIf="cargandoClientes" [error]="errorClientes" message="Obteniendo clientes..."
            color="primary">
          </loading-text-spinner>
          <div class="alert alert-danger" *ngIf="errorClientes">
            {{this.errorClientes}}
          </div>
          <div class="alert alert-danger"
            *ngIf="clienteFormControl.invalid && clienteFormControl.errors.required && (clienteFormControl.dirty || clienteFormControl.touched)">
            Requerido
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <button class="btn btn-primary btn-block" (click)="onSeleccionoCliente()" [disabled]="!this.formBuscador.valid || this.cargandoClientes">Facturar al cliente seleccionado.</button>
    </div>
       
    <div class="row">
      <div class="mb-3 row col-md-12 mt-4">
        <label class="col-sm-12 col-form-label">Si no lo encuentra, puede cargar los datos del cliente para su posterior alta.</label>
      </div>
    </div>

    <div class="row">
      <button class="btn btn-primary btn-block" (click)="onCargarDatosCliente()" [disabled]="this.cargandoClientes">Cargar datos del cliente.</button>
    </div>
  </div>

  <div id="contenedor-cargar-cliente" *ngIf="mostrarCargaDatosClientes"  [formGroup]="formCarga">

    <div class="row">
      <div class="mb-3 row col-md-12">
        <label class="col-sm-12 col-form-label">Ingresar los datos del cliente:</label>        
      </div>
    </div>

    <div class="row">
      <div class="mb-3 row col-md-12">
        <label for="razonSocialFormControl" class="col-sm-4 col-form-label">Razon Social:</label>
        <div class="input-group col-sm-8">
          <input id="razonSocialFormControl" type="text" class="form-control mb-2 me-sm-2"
            formControlName="razonSocialFormControl">
        </div>
        <div class="alert alert-danger"
        *ngIf="razonSocialFormControl.invalid && razonSocialFormControl.errors.required && (razonSocialFormControl.dirty || razonSocialFormControl.touched)">
        Requerido
      </div>
      </div>
    </div>

    <div class="row">
      <div class="mb-3 row col-md-12">
        <label for="tipoDocumentoFormControl" class="col-sm-4 col-form-label">Tipo documento:</label>

        <div class="col-sm-8">

          <select id="tipoDocumentoFormControl" class="form-select mb-2 me-sm-2" type="number"
            formControlName="tipoDocumentoFormControl" placeholder="Seleccione">
            <option [ngValue]="96">DNI</option>
            <option [ngValue]="80">CUIT</option>
            <option [ngValue]="86">CUIL</option>
          </select>
          <div
            *ngIf="tipoDocumentoFormControl.invalid && tipoDocumentoFormControl.errors.required && (tipoDocumentoFormControl.dirty || tipoDocumentoFormControl.touched)"
            class="alert alert-danger">
            Requerido
          </div>
        </div>

      </div>
    </div>

    <div class="row">
      <div class="mb-3 row col-md-12">
        <label for="nroDocumentoFormControl" class="col-sm-4 col-form-label">Nro. {{ obtenerLeyendaDocumento() }}:</label>
        <div class="col-sm-8">
          <input id="nroDocumentoFormControl" type="text" class="form-control mb-2 me-sm-2"
            formControlName="nroDocumentoFormControl" placeholder="Solo numeros" (keypress)="onlyNumberKey($event)">
          <div *ngIf="nroDocumentoFormControl.invalid && nroDocumentoFormControl.errors.required && (nroDocumentoFormControl.dirty || nroDocumentoFormControl.touched)"
            class="alert alert-danger">Requerido</div>
            <div class="alert alert-danger" *ngIf="(nroDocumentoFormControl.dirty || nroDocumentoFormControl.touched) && formCarga.invalid && this.formCarga.hasError('nroInvalido')">
              El nro no es valido.
            </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="mb-3 row col-md-12">
        <label for="provinciaFormControl" class="col-sm-4 col-form-label">Provincia:</label>
        <div class="col-sm-8">
          <input id="provinciaFormControl" [(ngModel)]="modelProvincia" type="text" class="form-control" formControlName="provinciaFormControl"
            [inputFormatter]="formatterProvincia" [resultFormatter]="formatterProvincia"
            title="Busque la provincia." (change)="inputProvinciaTextChange()"
            [ngbTypeahead]="searchProvincia" (selectItem)="seleccionoProvincia($event)" />
          <loading-text-spinner *ngIf="cargandoProvincias" [error]="errorProvincias" message="Obteniendo provincias..."
            color="primary">
          </loading-text-spinner>
          <div class="alert alert-danger" *ngIf="errorProvincias">
            {{this.errorProvincias}}
          </div>
          <div class="alert alert-danger"
            *ngIf="provinciaFormControl.invalid && provinciaFormControl.errors.required && (provinciaFormControl.dirty || provinciaFormControl.touched)">
            Requerido
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="mb-3 row col-md-12">
        <label for="localidadFormControl" class="col-sm-4 col-form-label">Localidad:</label>
        <div class="col-sm-8">
          <input id="localidadFormControl" [(ngModel)]="modelLocalidad" type="text" class="form-control" formControlName="localidadFormControl"
            [inputFormatter]="formatterLocalidad" [resultFormatter]="formatterLocalidad"
            title="Busque la localidad." (change)="inputLocalidadTextChange()"
            [ngbTypeahead]="searchLocalidad" (selectItem)="seleccionoLocalidad($event)" />
          <loading-text-spinner *ngIf="cargandoLocalidades" [error]="errorLocalidades" message="Obteniendo localidades..."
            color="primary">
          </loading-text-spinner>
          <div class="alert alert-danger" *ngIf="errorLocalidades">
            {{this.errorLocalidades}}
          </div>
          <div class="alert alert-danger"
            *ngIf="localidadFormControl.invalid && localidadFormControl.errors.required && (localidadFormControl.dirty || localidadFormControl.touched)">
            Requerido
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="mb-3 row col-md-12">
        <label for="domicilioFormControl" class="col-sm-4 col-form-label">Domicilio:</label>
        <div class="input-group col-sm-8">
          <input id="domicilioFormControl" type="text" class="form-control mb-2 me-sm-2"
            formControlName="domicilioFormControl" placeholder="Domicilio del cliente">
        </div>
        <div class="alert alert-danger"
        *ngIf="domicilioFormControl.invalid && domicilioFormControl.errors.required && (domicilioFormControl.dirty || domicilioFormControl.touched)">
        Requerido
      </div>
      </div>
    </div>

    <div class="row">
      <button class="btn btn-primary btn-block" [disabled]="!this.formCarga.valid || this.cargandoClientes" (click)="onDatosCargadosCliente()">Confirmar datos del cliente</button>
    </div>

  </div>
  
</div>

<div class="modal-footer bg-light">
  <div class="row">
    <div class="alert alert-danger" *ngIf="errorGeneral != null">
      {{ errorGeneral }}
    </div>
  </div>
  

  <span class="border">
    <button type="button" class="btn btn-secondary" (click)="activeModal.close(null)">Cancelar</button>
  </span>
</div>