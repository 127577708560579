import { Producto } from './Producto';
import { UltimoEstado } from './UltimoEstado';

export class Pedido {
    id:           string;
    fechaHora:    string;
    producto:     Producto;
    cantidad:     number;
    ultimoEstado: UltimoEstado;
}
