import { Guid } from 'guid-typescript';
import { Aeroplanta } from '../despacho/Aeroplanta';
import { Cliente } from '../despacho/Cliente';
import { Producto } from '../despacho/Producto';
import { Destino } from '../despacho/Destino';
import { ConsumoRancho } from './ConsumoRancho';


export class Rancho {
    id: Guid;
    codigoRancho: string;
    aeroplanta: Aeroplanta;
    cliente: Cliente;
    fechaInicio: Date;
    fechaVencimiento: Date;
    destino: Destino;
    producto: Producto;
    volumenDisponible: number;
    volumen: number;
    precio: number;
    concatenado: string;
    consumos: ConsumoRancho[]
    estado: number;
    vigente: number;

    get vencido(): Boolean
    {
        return (new Date()>new Date(this.fechaVencimiento));
    }
}
