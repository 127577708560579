import { Component, Input, OnInit } from '@angular/core';
import { ExportExcelServiceService } from 'src/app/shared/exportar-excel/export-excel-service.service';
import { Balance7MedicionesDTO } from 'src/app/shared/models/cierreTurno/Balance7MedicionesDTO';

@Component({
  selector: 'app-ultimas-mediciones',
  templateUrl: './ultimas-mediciones.component.html',
  styleUrls: ['./ultimas-mediciones.component.scss']
})
export class UltimasMedicionesComponent implements OnInit {

  @Input() mediciones:Balance7MedicionesDTO;

  constructor(private excelService: ExportExcelServiceService) { }

  ngOnInit(): void {

  }

  armarArrayExcel() {
    let excelMovimiento: ExcelMedicionesContenedores[] = [];

    for(var i=0; i < this.mediciones.medicionesContenedores.length; i++){
      var item = new ExcelMedicionesContenedores();
      item.nombreContenedor = this.mediciones.medicionesContenedores[i].nombreContenedor;
      item.codigoProducto = this.mediciones.medicionesContenedores[i].codigoProducto;
      item.nombreProducto = this.mediciones.medicionesContenedores[i].nombreProducto;   
      item.volumen15 = this.mediciones.medicionesContenedores[i].volumen15;
      item.volumenNatural = this.mediciones.medicionesContenedores[i].volumenNatural;
      item.temperatura = this.mediciones.medicionesContenedores[i].temperatura;
      item.densidad = this.mediciones.medicionesContenedores[i].densidad;
      item.medicion = this.mediciones.medicionesContenedores[i].medicion ? this.mediciones.medicionesContenedores[i].medicion.toString() : "--";
      excelMovimiento.push(item);
    }
    return excelMovimiento;
  }

  

  exportarContenedores() { 
    this.excelService.exportAsExcelFile(this.mediciones?.medicionesContenedores ? this.armarArrayExcel() : [], 'Mediciones contenedores cierre '+ this.mediciones.fechahoraCierre);
  }

  exportarAeroespecialidades() { 
    this.excelService.exportAsExcelFile(this.mediciones?.medicionesAeroespecialidades ? this.mediciones?.medicionesAeroespecialidades : [], 'Mediciones aeroespecialidades cierre ' + this.mediciones.fechahoraCierre);
  }
}

export class ExcelMedicionesContenedores {
    nombreContenedor: string;
    codigoProducto: string;
    nombreProducto: string;
    volumen15: number;
    volumenNatural: number;
    temperatura: number;
    densidad: number;
    medicion: string;
}
