import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';
import { Cliente } from 'src/app/shared/models/cliente/Cliente';
import { ClienteEncabezado } from 'src/app/shared/models/cliente/ClienteEncabezado';
import { ClienteService } from '../cliente.service';
import { ClientesInformacionComponent } from '../clientes-informacion/clientes-informacion.component';
import { ModalClienteExtranjeroComponent } from '../modal-cliente-extranjero/modal-cliente-extranjero.component';
import { ModalWebServiceComponent } from '../modal-web-service/modal-web-service.component';

@Component({
  selector: 'app-clientes',
  templateUrl: './clientes.component.html',
  styleUrls: ['./clientes.component.css']
})
export class ClientesComponent implements OnInit {


  clienteParaAgregar: ClienteEncabezado;
  clienteBuscado: string;
  buscadorFormControl = new UntypedFormControl();
  clienteABuscar: Cliente;
  clienteExtranjero: Cliente;
  clienteBuscadoAFIP: Cliente;
  abrirCliente: boolean;
  clientesVerificar: ClienteEncabezado[];
  terminoCargaClientes: boolean = false;
  bit: number = 0;


  constructor(private activeModal: NgbModal, private clienteService: ClienteService, private authService: AuthService) { }

  ngOnInit() {
    this.getClientes();
  }

  nuevoClienteExtranjero() {
    let modalRef = this.activeModal.open(ModalClienteExtranjeroComponent, { centered: true, backdrop: 'static'});
    modalRef.componentInstance.clienteVerificar = this.clientesVerificar;
    modalRef.componentInstance.clienteObtenido.subscribe(result => {
      this.clienteExtranjero = result;
      this.clienteBuscadoAFIP = null
      this.clienteABuscar = null;
      this.abrirCliente = true;
    });
  }

  nuevoClienteAfip() {
    let modalRef = this.activeModal.open(ModalWebServiceComponent, { centered: true, backdrop: 'static' });
    modalRef.componentInstance.clienteVerificar = this.clientesVerificar;
    modalRef.componentInstance.clienteObtenido.subscribe(result => {
      this.clienteBuscadoAFIP = result;
      this.clienteExtranjero = null;
      this.clienteABuscar = null;
      this.abrirCliente = true;
    })
    modalRef.componentInstance.cerrarInformacion.subscribe( r=> this.cerrarFormulario())
  }

  filtrarClientes() {
    this.clienteBuscado = this.buscador.value;
  }

  mostrarCliente(event) {    
    this.clienteABuscar = event;
    this.clienteBuscadoAFIP = null;
    this.clienteExtranjero = null;
    this.abrirCliente = true;
    this.terminoCargaClientes = false;
  }

  enviarCliente(event) {
    this.clientesVerificar = event;
  }

  getClientes() {
    this.clienteService.getClientesEncabezado()
      .subscribe(result => {
        this.clientesVerificar = result;
      }
      )
  }

  termino() {
    this.terminoCargaClientes = true;
  }


  agregarCliente(event) {
    let p = new Cliente();
    p = event;
    let enc = new ClienteEncabezado();
    enc.razonSocial = p.razonSocial;
    enc.tipoDocumento = p.tipoDocumento;
    enc.numeroDocumento = p.numeroDocumento;
    enc.ctaSap = p.ctaSap;
    enc.percepcionIVADif = p.percepcionIVADif;
    enc.fechaPercepcionIVADif = p.fechaPercepcionIVADif;
    enc.clienteObservado = p.clienteObservado;
    this.clienteParaAgregar = enc;
    this.mostrarCliente(event);
  }

  // TipoUsuario.ADMCLIENTES puede dar de alta cliente o modificarlos como el COMERCIAL, pero este manda el usuarioAprob en el DTO mientras que el otro no.
  usuarioPuedeCrearClienteExtranjero(): boolean {
    return this.authService.hasRole(TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.APROBADORBYR, TipoUsuario.ADMCLIENTES);
  }

  usuarioPuedeCrearClienteAfip(): boolean{
    return this.authService.hasRole(TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.APROBADORBYR, TipoUsuario.ADMCLIENTES, TipoUsuario.OPERADOR_3,TipoUsuario.OPERADORDIRECTO, TipoUsuario.MANDATARIO, TipoUsuario.SUPERVISOR);     
  }

  cerrarFormulario(){
    this.abrirCliente = false;
  }

  get buscador() {
    return this.buscadorFormControl;
  }

}
