import { Component, OnInit, Input, Output, EventEmitter, } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { StockService } from '../../../stock.service';
import { NgbActiveModal, NgbCalendar, NgbDatepickerConfig, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Medicion } from 'src/app/shared/models/stock/Medicion';
import { MessageService } from 'src/app/shell/message.service';
import { Tanque } from 'src/app/shared/models/stock/Tanque';
import { UsuarioService } from 'src/app/account/usuario.service';
import { MedicionesService } from '../../../mediciones.service';
import { timingSafeEqual } from 'crypto';
import { AbastecedoraService } from 'src/app/stock/abastecedoras.service';
import { SharedService } from 'src/app/shared/shared.service';
import { CalculosService } from 'src/app/shared/calculos.service';
import { TipoProducto } from 'src/app/shared/enums/tipoProducto';
import { Producto } from 'src/app/shared/models/abm/Producto';
import { ProductoService } from 'src/app/abm/producto/producto.service';
import { Abastecedora } from 'src/app/shared/models/stock/Abastecedora';
import { AerovalesService } from 'src/app/despacho/aerovales.service';
import { AeropuertoService } from 'src/app/despacho/aeropuerto.service';
import { threadId } from 'worker_threads';

@Component({
  selector: 'app-nueva-medicion',
  templateUrl: './nueva-medicion.component.html',
  styleUrls: ['./nueva-medicion.component.css']
})
export class NuevaMedicionComponent implements OnInit {
  [x: string]: any;

  @Input() tanques: Tanque[];

  esValidaDensidad: boolean = true;
  esDensidadMin: any;
  esDensidadMax: any;

  calculandoCantidadNeta: boolean = false;
  guardando: boolean;
  modelFechaMedicion: NgbDateStruct;
  modelHoraLlegada: any;
  aeroplantaCod: string;

  tanqueGraduacion: boolean = false;

  form: UntypedFormGroup;
  abastecedoras: Abastecedora[];
  medicionesParaTanque: boolean = true;
  productos: Producto[]=[];
  obteniendoAbastecedoras: boolean = false;
  productoSeleccionado: Producto;
  constructor(
    private datepickerConfig: NgbDatepickerConfig,
    private calendar: NgbCalendar,
    private aerovaleService: AerovalesService,
    private aeropuertoService: AeropuertoService,
    private abastecedoraService: AbastecedoraService,
    private messageService: MessageService,
    private formBuilder: UntypedFormBuilder,
    public activeModal: NgbActiveModal,
    private usuarioService: UsuarioService,
    private stockService: StockService,
    private medicionesService: MedicionesService,
    private productoService: ProductoService,
    private sharedService: SharedService,
    private calculoService: CalculosService) {

  }

  ngOnInit() {
    this.getProductos();
    this.medicionesParaTanque = true;
    this.getAbastecedoras();
    this.archivoDeDensidad = this.leerArchivo();
    this.crearFormulario();
    this.inicializarComponente();
    this.vaciarCampos();
  }

  // form

  crearFormulario() {
    this.form = this.formBuilder.group({
      tanqueFormControl: ['', Validators.required],
      cantidadNaturalFormControl: ['', Validators.required],
      cantidad15FormControl: [''],
      nivelVarilladoFormControl: ['', Validators.required],
      temperaturaFormControl: new UntypedFormControl(null, [Validators.required, this.temperaturaValidator]),
      densidadFormControl: ['', Validators.required],
      nivelDeAguaFormControl: ['', Validators.required],
      fechaFormControl: ['', Validators.required],
      abastecedoraFormControl: new UntypedFormControl({ value: null }, Validators.required),
      horaFormControl: ['', Validators.required],
    });

    this.form.setValidators(this.formValidator);
  }

  inicializarComponente() {
    this.tanqueFormControl.setValue(this.tanques[0]);
    this.tanqueGraduacion = this.tanques[0].tieneGraduacion;
    this.verificarGraduacion(0);
    this.modelFechaMedicion = this.calendar.getToday();
    var horaActual = new Date();
    this.modelHoraLlegada = { hour: horaActual.getHours(), minute: horaActual.getMinutes(), second: horaActual.getSeconds() };
    this.guardando = false;
  }
  getProductos() {
    this.productoService.obtenerProductos()
      .subscribe(result => {
        this.productos = result;
      }, () => {

      });
  }
  // validaciones

  formValidator(group: UntypedFormGroup): any {
    if (group) {
      let fechaRegistro = group.get("fechaFormControl").value;
      let horaRegistro = group.get("horaFormControl").value;

      var fecha = new Date(fechaRegistro.year, fechaRegistro.month - 1, fechaRegistro.day, horaRegistro.hour, horaRegistro.minute);
      var ahora = new Date();
      if (fecha > ahora) {
        return { fechaMedicionMayorAhora: true };
      }
      return null;
    }
    
  }

  onlyDecimalPositivo(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46 || event.charCode == 45;
  }

  compareProductos(p1: any, p2: any): boolean {
    return p1 && p2 ? p1.codigoProducto === p2.codigoProducto : p1 === p2;
  }

  temperaturaValidator(control: UntypedFormControl) {
    if (control.value > 100) {
      return { temperaturaFueraDeRango: true };
    }

    if (control.value < -50) {
      return { temperaturaFueraDeRango: true };
    }

    return null;
  }

  // llamadas al service

  onSubmit() {
    
    this.guardando = true;
    if (!this.densidadValida()) {
      this.messageService.showErrorMessage("La densidad debe estar entre el rango [" + this.productoSeleccionado.densidadMin + "," + this.productoSeleccionado.densidadMax + "]");
      this.guardando = false;
      return;
    }
    this.aeroplantaCod=this.usuarioService.getAeroplantaActual().codigoAeroplanta;
    var medicion = new Medicion();

    medicion.codigoContenedor = this.tanqueFormControl.value.codigo;
    medicion.nivelNatural = Number((this.cantidadNaturalFormControl.value == '' ? 0 : this.cantidadNaturalFormControl.value));
    medicion.varillado = Number(this.nivelVarilladoFormControl.value);
    medicion.temperatura = Number(this.temperaturaFormControl.value);
    medicion.densidad = Number(this.densidadFormControl.value);
    medicion.nivelAgua = Number(this.nivelDeAguaFormControl.value);
    medicion.fechaHora = this.convertUTCDateToLocalDate(this.formatDate(this.fechaFormControl.value.year, this.fechaFormControl.value.month, this.fechaFormControl.value.day, this.horaFormControl.value.hour, this.horaFormControl.value.minute));
    medicion.username = this.usuarioService.getUsernameByName();

    medicion.nivel = Number(this.calculoService.obtenerCTLNuevo(medicion.temperatura, medicion.densidad, medicion.nivelNatural));

    this.medicionesService.crearMedicion(medicion)
      .subscribe(medicion => {
        this.activeModal.close(medicion);
        this.guardando = false;
        this.messageService.showSuccessMessage("Se registró correctamente la medición");
      },
        () => {
          this.guardando = false;
          this.messageService.showErrorMessage("Error al guardar medicion.");
        });

       
  }

  formatDate(year, month, day, hour, minute): Date {
    return new Date(year + '-' + month.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + '-' + day.toLocaleString(undefined, { minimumIntegerDigits: 2 })
      + 'T' + hour.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + minute.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':00');
  }

  convertUTCDateToLocalDate(date) {
    var newDate = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
    return newDate;
  }

  onSeleccionoTanque(event) {
    this.vaciarCampos();
    this.verificarGraduacion(event.target.selectedIndex)
    this.recalcularVolumenes();    
  }

  vaciarCampos(): void{
    this.cantidad15FormControl.setValue('');
    this.cantidadNaturalFormControl.setValue('');
    this.nivelVarilladoFormControl.setValue('');
    this.temperaturaFormControl.setValue('');
    this.densidadFormControl.setValue('');
    this.nivelDeAguaFormControl.setValue('');
  }

  verificarGraduacion(i: number) {
    if (!this.tanques[i].tieneGraduacion) {
      this.form.controls.nivelVarilladoFormControl.setValidators(null);
      this.tanqueGraduacion = false;
    } else {
      this.form.controls.nivelVarilladoFormControl.setValidators([Validators.required]);
      this.tanqueGraduacion = true;
    }
    this.form.controls.nivelVarilladoFormControl.updateValueAndValidity();
  }

  onBlurNivelVarillado(target) {
    this.recalcularVolumenes();
  }

  onBlurTemperatura(target) {
    this.recalcularVolumenNatural();
  }
 
  getAbastecedoras(){
    this.obteniendoAbastecedoras = true;
    this.abastecedoraService.getAbastecedoras(this.usuarioService.getAeroplantaActual().codigoAeroplanta)
      .subscribe(result => {
        this.abastecedoras = result;
        this.obteniendoAbastecedoras = false;
      })
  }
  leerArchivo(): string {
    let archivoTXT = new XMLHttpRequest();
    let fileRuta: string = 'assets/json/1.txt';
    archivoTXT.open("GET", fileRuta, false);
    archivoTXT.send(null);
    let response: any = archivoTXT.responseText;
    return response;
  }

  onBlurDensidad(target) {
    this.recalcularVolumenNatural();
  }
  
  densidadValida() {
    if (this.densidadFormControl != null && this.productoSeleccionado){
      this.productoSeleccionado = this.productos.find(element => element.codigoProducto===this.tanqueFormControl.value.productoActual.codigoProducto);
      let esValido = true;
      let valorDensidad = Number(this.densidadFormControl.value);
      if (valorDensidad >= this.productoSeleccionado.densidadMin && valorDensidad <= this.productoSeleccionado.densidadMax) 
        esValido = true
      else esValido = false;
     
      return esValido;
    }
  }
  
  formatDensidad(event) {
    if (event.charCode == 8 || event.charCode == 0) return null;

    if (event.charCode >= 48 && event.charCode <= 57) {
      this.densidadFormControl.setValue(this.sharedService.formatDensidad(event));
      this.validarDensidad(this.densidadFormControl.value);
    }
    this.onBlurDensidad(event.target);
    return false;
  }

  validarDensidad(densidadFormControl) {
    if (densidadFormControl != null){
      this.productoSeleccionado = this.productos.find(element => element.codigoProducto===this.tanqueFormControl.value.productoActual.codigoProducto);
      this.esValidaDensidad = true;
      if (this.productoSeleccionado) {
        this.esValidaDensidad = (densidadFormControl >= this.productoSeleccionado.densidadMin && densidadFormControl <= this.productoSeleccionado.densidadMax);
        this.esDensidadMin = this.productoSeleccionado.densidadMin;
        this.esDensidadMax = this.productoSeleccionado.densidadMax;
      }
      
      return this.esValidaDensidad;
    }
  }

  recalcularVolumenes() {
    this.medicionesParaTanque = true;

    let varillado = this.nivelVarilladoFormControl.value;
    let dens = this.densidadFormControl.value;
    let temp = this.temperaturaFormControl.value;

    if (this.tanqueFormControl.value != null && varillado != '') {
      this.calculandoCantidadNeta = true;
      this.stockService.getConversionLitrosPorVarillado(this.tanqueFormControl.value.codigo, varillado)
        .subscribe(volumenLts => {
          this.cantidadNaturalFormControl.setValue(volumenLts);

          if (dens != '' && temp != '') {
            this.cantidad15FormControl.setValue(this.calculoService.obtenerCTLNuevo(temp, dens, volumenLts));
          }

          this.calculandoCantidadNeta = false;
        },
          () => {
            this.cantidadNaturalFormControl.setValue('');
            this.calculandoCantidadNeta = false;
            this.medicionesParaTanque = false;
          });
    }
  }

  recalcularVolumenNatural() {
    let volumenLts = Number(this.cantidadNaturalFormControl.value);
    let dens = this.densidadFormControl.value;
    let temp = this.temperaturaFormControl.value;

    if (typeof volumenLts == 'number' && dens != '' && temp != '') {
      this.cantidad15FormControl.setValue(this.calculoService.obtenerCTLNuevo(temp, dens, volumenLts));
    }
  }

  // getters
  get formMedicion() {
    return this.form.value; 
  }

  get tanqueFormControl() {
    return this.form.controls.tanqueFormControl;
  }
  get abastecedora() {
    return this.form.controls.abastecedoraFormControl;
  }

  get cantidad15FormControl() {
    return this.form.controls.cantidad15FormControl;
  }

  get cantidadNaturalFormControl() {
    return this.form.controls.cantidadNaturalFormControl;
  }

  get nivelVarilladoFormControl() {
    return this.form.controls.nivelVarilladoFormControl;
  }

  get temperaturaFormControl() {
    return this.form.controls.temperaturaFormControl;
  }

  get densidadFormControl() {
    return this.form.controls.densidadFormControl;
  }

  get nivelDeAguaFormControl() {
    return this.form.controls.nivelDeAguaFormControl;
  }

  get fechaFormControl() {
    return this.form.controls.fechaFormControl;
  }

  get horaFormControl() {
    return this.form.controls.horaFormControl;
  }
}