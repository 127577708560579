<div class="mb-2">
    <h4  class="modal-title"><b>Producto seleccionado: ({{this.producto.codigoProducto}}) - {{this.producto.nombreProducto}}</b></h4>
</div>
<nav class="navbar navbar-light bg-light navbar-expand-lg">
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav">
            <!-- desde -->
            <li class="nav-item me-2">
                <div class="input-group">
                    <input type="date" [ngModel]="model" class="fechaFormControlDesdeHasta" placeholder="aaaa-mm-dd"
                        (ngModelChange)="model = $event" name="dt" ngbDatepicker #d="ngbDatepicker" value="model"
                        class="form-control form-control-rounded" #id="ngbDatepicker" #searchDate required readonly>

                    <div class="input-group-append">
                        <button id="botonDesde" type="button" class="input-group-btn btn btn-secondary" (click)="toggleDatepicker(d)">
                        <i class="fa fa-calendar" aria-hidden="true"></i></button>
                    </div>
                </div>
            </li>
            <!-- hasta -->
            <li class="nav-item">
                <div class="input-group">
                    <input type="date" class="fechaFormControlDesdeHasta" [ngModel]="model1" placeholder="aaaa-mm-dd"
                        name="dp" value="model1" (ngModelChange)="model1 = $event" class="form-control form-control-rounded"
                        ngbDatepicker #h="ngbDatepicker" #searchDate required readonly>

                    <div class="input-group-append">
                        <button id="botonHasta" type="button" class="input-group-btn btn btn-secondary" (click)="toggleDatepicker(h)"><i
                            class="fa fa-calendar" aria-hidden="true"></i></button>
                    </div>
                </div>
            <li class="nav-item">
                <button (click)="this.onClickBuscar()" class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2"
                    type="submit"><i class="fa fa-search" aria-hidden="true"></i>
                </button>
            </li>            
        </ul>
    </div>
</nav>

<div *ngIf="obteniendoConsolidado" class="border p-3">
    <loading-text-spinner message="Obteniendo resumen consolidado..."
        orientation="vertical" class="list-group-item" size="large" color="primary" textColor="dark">
    </loading-text-spinner>
</div>

<div *ngIf="!limpiarConsulta && !obteniendoConsolidado">
    <div *ngIf="consolidado != null" class="contenedor-der">
        <div *ngFor="let c of consolidado; let i = index">
            <div class="col col-md-12">
                <div class="card border-item">
                    <div class="card-header">
                        <h4 class="modal-title">Fecha: {{c.fechaHora | date: "dd'/'MM'/'y' 'hh:mm a"}}</h4>
                        <div class="text-center mt-2">
                            <a href="{{c.link}}"><i class="fa fa-cloud-download" aria-hidden="true"></i> Descargar Archivo</a>
                        </div>
                    </div>                          
                </div>
            </div>      
        </div>      
    </div>
    <ngb-alert type="warning" *ngIf="this.consolidado==null || this.consolidado?.length==0" class="alert alert warning">
        No existe información para las fechas seleccionadas
    </ngb-alert>    
</div>