<div class="card" id="islas-card">
  <div class="card-header">
    <h4 style="display: inline;" class="card-title">ABM de {{this.obtenerDescripcionSurtidorDispenser()}}.</h4>
    <button *ngIf="this.hasRole(TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.RESPRODUCTO, TipoUsuario.TUTOR)" style="margin-left: 4em;" class="btn btn-primary" type="button" [disabled]="obteniendoSurtidores" (click)="agregarSurtidor()">Agregar nuevo {{this.obtenerDescripcionSurtidorDispenser()}}</button>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-12">
        <div class="spinner-container" *ngIf="obteniendoSurtidores">          
          <ngx-spinner name="spinnerAbastecedora" bdOpacity=0.3bd
            Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
            <p style="color: white">{{messageSurtidores}}</p>
          </ngx-spinner>
        </div>
        <div *ngIf="this.surtidores.length == 0 && !obteniendoSurtidores" class="alert alert-warning">No hay elementos para la aeroplanta seleccionada.</div>
        <table *ngIf="this.surtidores.length > 0 && !obteniendoSurtidores" class="table table-striped table-hover" [formGroup]="tablaSurtidores">
          <thead>
            <td class="text-center"><b>Código</b></td>
            <td class="text-center"><b>Nombre</b></td>
            <td class="text-center"><b>Producto</b></td> 
            <td class="text-center"><b>Aforador utilizado</b></td>
            <td class="text-center"><b>Aforador actual</b></td>
            <td class="text-center"><b>Tanques/grupos relacionados</b></td>
            <td class="text-center"><b>Activa</b></td>
            <td class="text-center"><b>Editar</b></td>
          </thead>
          <tbody>
            <tr formArrayName="surtidores" *ngFor="let s of surtidores; let i = index">
              <td class="text-center">{{  s.codigo }}</td>
              <td class="text-center">{{ s.nombre }}</td>
              <td class="text-center">{{ s.productoActual.codigoProducto }} - {{ s.productoActual.nombreProducto }}
              </td>
              <td class="text-center">
                <button class="btn" (click)="verPicos(s)" placement="bottom" ngbTooltip="Ver aforadores relacionados" container="body">
                  <i class="fa fa-eye" aria-hidden="true"></i>
                </button>
              </td>
              <td class="text-center" *ngIf="s.picos.length == 0">No hay aforadores asignados</td>
              <td class="text-center" *ngIf="s.picos.length == 1">{{ s.picos[0].aforadorActual }}</td>
              <td class="text-center" *ngIf="s.picos.length > 1">Consultar aforadores</td>
              <td class="text-center">
                    <button class="btn" (click)="irATanquesRelacionados(s, s.tanques);"
                  placement="bottom" ngbTooltip="Ver Tanques relacionados" container="body">
                  <i class="fa fa-eye" aria-hidden="true"></i>
                </button>

              </td>
              <td class="text-center">
                <div *ngIf="this.hasRole(TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.RESPRODUCTO, TipoUsuario.TUTOR)" class="form-check form-check-inline form-switch">
                  <input type="checkbox" [formControlName]="i" class="form-check-input" id="{{surtidores[i].id}}"
                    (click)="cambiarEstadoSurtidor(surtidores[i])">
                  <label class="form-check-label" for="{{surtidores[i].id}}"></label>
                </div>
                <div *ngIf="!this.hasRole(TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.RESPRODUCTO, TipoUsuario.TUTOR)" class="form-check form-check-inline form-switch">
                  {{surtidores[i].activo ? "Si" : "No"}}
                </div>  
              </td>
              <td class="text-center">
                <button *ngIf="this.hasRole(TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.RESPRODUCTO, TipoUsuario.TUTOR)" type="button" (click)="editarSurtidor(s)" class="btn btn-primary btn-sm me-1"><i class="fa fa-pencil fa-lg" aria-hidden="true" ngbTooltip="Editar"></i></button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

