import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbCalendar, NgbDatepickerConfig, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { UsuarioService } from 'src/app/account/usuario.service';
import { ClienteService } from 'src/app/cliente/cliente.service';
import { EstadoAutorizacion } from 'src/app/shared/enums/EstadoAutorizacion';
import { TipoSolapaAutorizacion } from 'src/app/shared/enums/TipoSolapaAutorizacion';
import { AutorizacionDTO } from 'src/app/shared/models/cliente/AutorizacionDTO';
import { SharedService } from 'src/app/shared/shared.service';
import { MessageService } from 'src/app/shell/message.service';

@Component({
  selector: 'app-reporte-autorizaciones',
  templateUrl: './reporte-autorizaciones.component.html',
  styleUrls: ['./reporte-autorizaciones.component.scss']
})
export class ReporteAutorizacionesComponent implements OnInit {

  busquedaFormControl = new UntypedFormControl('');  

  solapaAutorizacion: number;
  TipoSolapaAutorizacion = TipoSolapaAutorizacion;

  autorizaciones: AutorizacionDTO[] = [];
  autorizacionesFiltradas: AutorizacionDTO[] = [];
  autorizacionSeleccionada: number = null;  

  obteniendoAutorizaciones: boolean = false;  
  aceptandoAutorizaciones: boolean = false;  
  rechazandoAutorizaciones: boolean = false;  

  change$: Subscription;
  EstadoAutorizacion = EstadoAutorizacion;
  estado: number;

  model: NgbDateStruct;
  model1: NgbDateStruct;
  fechaDesde: Date;
  fechaHasta: Date;

  constructor(
    public modal:NgbModal,
    private usuarioService: UsuarioService,
    private calendar: NgbCalendar,
    private datepickerConfig: NgbDatepickerConfig,
    private spinner: NgxSpinnerService,
    private clienteService: ClienteService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    sharedService: SharedService
  ) { 
    this.route.queryParams.subscribe(params => {
      this.solapaAutorizacion = Number(params.solapaAutorizacion);
    });
    //this.solapaVuelosTams= Number(TipoSolapaVueloTams.Arribos);
    this.change$ = sharedService.aeroplantaSeleccionada.subscribe(_=>{
      this.cambiarDeAeroplanta()
    })
  }

  ngOnInit(): void {
    (this.solapaAutorizacion == TipoSolapaAutorizacion.Pendientes) ? this.estado=TipoSolapaAutorizacion.Pendientes : this.estado = TipoSolapaAutorizacion.Historial;
    
    this.model = this.calendar.getToday();
    this.model1 = this.calendar.getToday();
    let inicio: any = { day: 1, month: 1, year: 2019 };
    this.datepickerConfig.minDate = inicio;
    let fin: any = { day: 31, month: 12, year: 2050 };
    this.datepickerConfig.maxDate = fin;
    this.setearFechaDesdeHasta();
    this.obtenerAutorizaciones();
  }

  ngOnDestroy(): void {
    this.change$.unsubscribe(); 
  }

  cambiarDeAeroplanta() {
    this.ngOnInit();
  }

  mostrarSpinner() {
    this.obteniendoAutorizaciones = true;
    this.spinner.show('spinnerGrafico');
  }
  
  ocultarSpinner() {
    this.obteniendoAutorizaciones = false;
    this.spinner.hide('spinnerGrafico');
  }

  formatDate(year, month, day, hour, minute, seconds): string {
    return year + '-' + month.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + '-' + day.toLocaleString(undefined, { minimumIntegerDigits: 2 })
      + 'T' + hour.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + minute.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + seconds.toLocaleString(undefined, { minimumIntegerDigits: 2 });
  }

  setearFechaDesdeHasta() {
    this.fechaDesde = new Date(this.model.year, this.model.month - 1, this.model.day);
    this.fechaHasta = new Date(this.model1.year, this.model1.month - 1, this.model1.day);
    this.fechaHasta.setHours(23, 59, 59, 99);
  }

  getFechaDesde(){
    return this.formatDate(this.fechaDesde.getFullYear(), this.fechaDesde.getMonth() + 1, this.fechaDesde.getDate(), 0, 0, 0);
  }

  getFechaHasta(){
    return this.formatDate(this.fechaHasta.getFullYear(), this.fechaHasta.getMonth() + 1, this.fechaHasta.getDate(), 23, 59, 59);
  }

  changeDesdeHasta() {
    this.setearFechaDesdeHasta();
    this.obtenerAutorizaciones();
  }

  fechaDesdeMayorQueHasta() {
    let esMayor: boolean;
    let desde = new Date(this.model.year, this.model.month - 1, this.model.day);
    let hasta = new Date(this.model1.year, this.model1.month - 1, this.model1.day);
    (desde > hasta) ? esMayor = true : esMayor = false;
    return esMayor;
  }

  /** servicios */

  async obtenerAutorizaciones() {

    this.mostrarSpinner();
    
    try {
      if (this.estado==TipoSolapaAutorizacion.Historial) {
        this.autorizaciones = await this.clienteService.getAutorizacionesMatriculas(this.getFechaDesde(), this.getFechaHasta(), null);
        this.autorizaciones=this.autorizaciones.filter( a => a.estado!=EstadoAutorizacion.Pendiente );
      }else{
        this.autorizaciones = await this.clienteService.getAutorizacionesMatriculas(this.getFechaDesde(), this.getFechaHasta(), this.estado);
      }
      
      this.autorizaciones.forEach( a => {         
          if(a.usuarioAprobador && a.usuarioAprobador.indexOf(',') > -1) a.usuarioAprobador = JSON.parse(a.usuarioAprobador.replace(/'/g,'"')).nombresCompletos;
          if(a.usuarioSolicitador && a.usuarioSolicitador.indexOf(',') > -1) a.usuarioSolicitador = JSON.parse(a.usuarioSolicitador.replace(/'/g,'"')).nombresCompletos;
      });
      this.autorizacionesFiltradas = this.autorizaciones.sort((a, b) => new Date(b.fechaHora).getTime() - new Date(a.fechaHora).getTime());
      this.filtrarAutorizaciones();
      this.ocultarSpinner();    
    } catch (error) {
      this.messageService.showErrorMessage('Hubo un error al obtener los datos. ' + error.message);      
      this.ocultarSpinner();
    }    
  }

  filtrarAutorizaciones() {    
    this.autorizacionesFiltradas = this.autorizaciones.filter(a => {
      let agregar = true;
      if (this.busqueda.value) {        
        agregar = a.ctA_SGC && a.ctA_SGC.toLocaleLowerCase().indexOf(this.busqueda.value.toLocaleLowerCase()) > -1;
        agregar = agregar || (a.cuentaDescripcion && a.cuentaDescripcion.toLocaleLowerCase().indexOf(this.busqueda.value.toLocaleLowerCase()) > -1);        
        agregar = agregar || (a.matricula && a.matricula.toLocaleLowerCase().indexOf(this.busqueda.value.toLocaleLowerCase()) > -1);        
      }

      if (this.busqueda.value == '') {
        this.autorizacionesFiltradas = this.autorizaciones;
      } 

      return agregar;
    });
  }

  async gestionarAutorizaciones(autorizacion: AutorizacionDTO,index:number, tipoEstado:EstadoAutorizacion) {
    let fechaAprobacion = new Date();
    this.autorizacionSeleccionada=index;
    (tipoEstado==EstadoAutorizacion.Aprobado) ? this.aceptandoAutorizaciones=true : this.rechazandoAutorizaciones=true;      

    let body = new AutorizacionDTO();
    body.idDeDespacho = autorizacion.idDeDespacho;
    body.ctA_SGC = autorizacion.ctA_SGC;
    body.matricula = autorizacion.matricula;
    body.usuarioAprobador = this.obtenerStringAprobador();
    body.fechaHoraAprobacion = this.formatDate(fechaAprobacion.getFullYear(), fechaAprobacion.getMonth() + 1, fechaAprobacion.getDate(), fechaAprobacion.getHours(), fechaAprobacion.getMinutes(), 0);        
    body.estado = tipoEstado;

    try {
      let r = await this.clienteService.putAutorizacionesV1(autorizacion.idDeDespacho, body);
      if (r) {
        r.estado == EstadoAutorizacion.Aprobado ? this.messageService.showSuccessMessage('Autorización Aprobada Con Éxito') : this.messageService.showSuccessMessage('Autorización Rechazada Con Éxito');
      }
      (tipoEstado==EstadoAutorizacion.Aprobado) ? this.aceptandoAutorizaciones=false : this.rechazandoAutorizaciones=false;
      this.autorizacionSeleccionada=null;          
    } catch (error) {
      this.messageService.showErrorMessage('Hubo un error al obtener la autorización.');
      (tipoEstado==EstadoAutorizacion.Aprobado) ? this.aceptandoAutorizaciones=false : this.rechazandoAutorizaciones=false;
      this.autorizacionSeleccionada=null;          
    }            
    this.ngOnInit();     
  }


  obtenerStringAprobador(){
    return '{"Email": "'+this.usuarioService.getEmailUsuario()+'" ,' + '"nombresCompletos": "'+this.usuarioService.getNombresCompletosUsuario()+'" ,' + '"dni": "'+this.usuarioService.getDniUsuario()+'" ,' + '"Id": "'+this.usuarioService.getIdUsuario()+'"}';
  }

  obtenerTipoEstado(e) {
    return EstadoAutorizacion[e];
  }

  /** getters */

  get busqueda() {
    return this.busquedaFormControl;
  }
}
