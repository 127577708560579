import { Component, OnInit } from '@angular/core';
import { FormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { UsuarioService } from 'src/app/account/usuario.service';
import { AerovaleDetalleComponent } from 'src/app/despacho/aerovale-detalle/aerovale-detalle.component';
import { ComprobanteService } from 'src/app/facturacion/comprobante.service';
import { CierreTurno } from 'src/app/shared/models/cierreTurno/CierreTurno';
import { Precierre } from 'src/app/shared/models/cierreTurno/Precierre';
import { Aeroplanta } from 'src/app/shared/models/despacho/Aeroplanta';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { Contenedor } from 'src/app/shared/models/stock/Contenedor';
import { Responsable } from 'src/app/shared/models/stock/Responsable';
import { MessageService } from 'src/app/shell/message.service';
import { AbastecedoraService } from 'src/app/stock/abastecedoras.service';
import { CierreTurnoService } from '../../cierre-turno.service';
import { GenerarInformeAerovalesComponent } from './generar-informe-aerovales/generar-informe-aerovales.component';
import { PrecierreAbastecedoraComponent } from './precierre-abastecedora/precierre-abastecedora.component';
import { AerovaleDetalle } from 'src/app/shared/models/despacho/AerovaleDetalle';
import { AerovalesService } from 'src/app/despacho/aerovales.service';
import { Remito } from 'src/app/shared/models/facturacion/Remito';
import { EstadoCierre } from 'src/app/shared/enums/estadoCierre';
import { ProductoService } from 'src/app/abm/producto/producto.service';
import { Producto } from 'src/app/shared/models/despacho/Producto';
import { TipoExpendedora } from 'src/app/shared/enums/tipoExpendedora';
import { EtapasCierreTurno } from 'src/app/shared/enums/EtapasCierreTurno';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-realizar-cierre-turno',
  templateUrl: './realizar-cierre-turno.component.html',
  styleUrls: ['./realizar-cierre-turno.component.css']
})
export class RealizarCierreTurnoComponent implements OnInit {

  aeroplanta: Aeroplanta;
  abastecedoras: Contenedor[] = [];
  abastecedorasAux: Contenedor[] = [];
  tablaAbastecedoras: UntypedFormGroup;
  obteniendoPrecierre: boolean = false;
  messagePrecierre: string = 'C A R G A N D O    P R E C I E R R E . . .';
  realizandoCierre: boolean = false;
  noContinua: boolean = false;

  hayCierre: CierreTurno;
  pasaPagina: boolean = false;
  cierreRealizoContinuado: CierreTurno;
  codigoAeroplanta: string = '';
  responsable: Responsable;
  change$: Subscription;
  remitosContadoSF:Remito[];
  hayRemitoVencidos: boolean = false;
  mostrarTablaRemitosContado: boolean = false;
  aerovaleDetalle: AerovaleDetalle;
  obteniendoAerovale: boolean = false;
  bloquearBotones: boolean = false;
  consultandoSiHayRemitosSF: boolean = false;
  aerovaleSeleccionado: number = null;
  productos: Producto[]=[];
  cargandoProductos:boolean=false;

  EstadoCierre=EstadoCierre;
  
  constructor(
    private router: Router,
    private activeModal: NgbModal,
    private usuarioService: UsuarioService,
    private productoService: ProductoService,
    private comprobanteService: ComprobanteService,
    private fb: UntypedFormBuilder,
    private spinner: NgxSpinnerService,
    private cierresService: CierreTurnoService,
    private abastecedoraService: AbastecedoraService,
    private messageService: MessageService,
    private aerovaleService: AerovalesService,
    sharedService: SharedService) {
    this.tablaAbastecedoras = this.fb.group({
      abastecedoraFormControl: new UntypedFormControl(),
      aforadorLeidoFormControl: new UntypedFormControl(),
      estadoFormControl: new UntypedFormControl(),
      fechaHoraPrecierreFormControl: new UntypedFormControl(),
    });
    this.change$ = sharedService.aeroplantaSeleccionada.subscribe(_=>{ 
      this.cambiarDeAeroplanta()
    });
  }

  // hooks

  ngOnInit() {
    this.startPage();
  }

  startPage() {
    this.responsable = new Responsable();
    this.responsable.NombreCompleto = this.usuarioService.getNombresCompletosUsuario();
    this.responsable.DNI = this.usuarioService.getDniUsuario();
    this.responsable.UserName = this.usuarioService.getUsername();
    this.codigoAeroplanta = this.usuarioService.getAeroplantaActual().codigoAeroplanta;
    this.remitosContadoSF = null;    
    this.hayRemitoVencidos = false;
    this.mostrarTablaRemitosContado = false;
    this.getProductos();
    this.getEstadoCierre();
    this.cierresService.initStepProgress();
  }

  ngOnDestroy() {
    this.change$.unsubscribe();
  }

  cambiarDeAeroplanta() {
    this.startPage();
  }

  // llamadas al service

  getProductos() {
    this.cargandoProductos=true;
    this.productoService.obtenerProductos()
      .subscribe(result => {
        this.productos = result.filter(p=>p.rubro.aerocombustible || p.rubro.combustible).filter(p=>p.activo);
        this.cargandoProductos=false;
      },()=>this.cargandoProductos=false);
  }

  getAbastecedoras(cierre: CierreTurno) {
    this.noContinua = false;
    this.abastecedoraService.getExpendedorasPorCierre(cierre.id, this.codigoAeroplanta)
      .subscribe(result => {
        this.abastecedoras = result;
        this.abastecedorasAux = result;
        if (this.abastecedoras.length > 0) {
          for (let j = 0; this.hayCierre.precierres.length > j; j++) {
            for (let i = 0; this.abastecedoras.length > i; i++) {
              if (this.abastecedoras[i].codigo == this.hayCierre.precierres[j].codigoExpendedora) {
                let num = this.abastecedorasAux.indexOf(this.abastecedoras[i]);
                this.abastecedorasAux.splice(num, 1);
              }
            }
          }
          let precierreAux: Precierre[] = [];
          for (let j = 0; this.abastecedorasAux.length > j; j++) {
            precierreAux.push(new Precierre());
            precierreAux[j].codigoExpendedora = this.abastecedorasAux[j].codigo;
            precierreAux[j].nombreExpendedora = this.abastecedorasAux[j].nombre;
            precierreAux[j].codigoProducto = this.abastecedorasAux[j].productoActual.codigoProducto;
            precierreAux[j].nombreProducto = this.abastecedorasAux[j].productoActual.nombreProducto;
            precierreAux[j].estadoDeCierre = 1;
            this.noContinua = true;
          }
          for (let p of precierreAux) {
            this.hayCierre.precierres.push(p);
          }
          this.hayCierre.precierres.sort((a , b) => a.nombreExpendedora.toLocaleUpperCase() > b.nombreExpendedora.toLocaleUpperCase() ? 1 : -1);
          this.spinner.hide('spinnerAbastecedoras');
          this.obteniendoPrecierre = false;
        }
        else {
          this.abastecedoras = [];
          this.spinner.hide('spinnerAbastecedoras');
          this.obteniendoPrecierre = false;
        }

      })
  }

  getEstadoCierre() {
    this.obteniendoPrecierre = true;
    this.spinner.show('spinnerAbastecedoras');
    this.cierresService.getCierreTurno(this.codigoAeroplanta)
      .subscribe(result => {
        this.hayCierre = result;
        if (!this.encontroCierres()) {
          this.cierresService.getCierreSegunEstado(this.codigoAeroplanta, EstadoCierre.Abierto)
            .subscribe(result => {
              this.hayCierre = result;
              if(this.encontroCierres() ){
                this.getAbastecedoras(this.hayCierre);
                if(this.hayCierre){
                  this.verificarRemitosContadoSF();
                }
              }else{
                this.messageService.showErrorMessage('Hubo un error al obtener el estado del cierre.');
                this.spinner.hide('spinnerAbastecedoras');
                this.obteniendoPrecierre = false;
              }               
            });
        }else{
          this.getAbastecedoras(this.hayCierre);
          if(this.hayCierre){
            this.verificarRemitosContadoSF();
          }
        }        
      }, (err: ErrorModel) => {
        this.messageService.showErrorMessage('Hubo un error al obtener el estado del cierre.');
        this.spinner.hide('spinnerAbastecedoras');
        this.obteniendoPrecierre = false;
      });
  }

  encontroCierres():boolean{
    return this.hayCierre != null ? true : false;
  }

  verificarRemitosContadoSF() {
    this.consultandoSiHayRemitosSF = true;
    this.mostrarTablaRemitosContado = false;
    this.comprobanteService.getRemitosSinFacturar(this.codigoAeroplanta)
    .subscribe(result => {
      this.remitosContadoSF = result;
      this.consultandoSiHayRemitosSF = false;
      if (this.remitosContadoSF.length>0) {          
        this.mostrarTablaRemitosContado = true;
        this.remarcarRemitos(this.remitosContadoSF); 
        setTimeout(() => {
          let tabla = document.getElementById("tablaRemitos");
          tabla.scrollIntoView();  
        }, 500);
      }
    },()=>{
      this.consultandoSiHayRemitosSF = false;
    });
  }

  validarPrecierres(precierres:Precierre[]) {
    let densidadOk:boolean = false;
    let tempOk:boolean = false;
    let nivel:boolean = false;
    for (const precierre of precierres) {
      if(precierre.tipoExpendedora==TipoExpendedora.ABASTECEDORA_CAMION) {
        let productoSeleccionado = this.productos.find(p => p.codigoProducto==precierre.codigoProducto);
        densidadOk = (precierre.densidad >= productoSeleccionado.densidadMin && precierre.densidad <= productoSeleccionado.densidadMax);
        tempOk = (precierre.temperatura <= 100 && precierre.temperatura > -50);
        //nivel = (precierre.nivel > 0 && precierre.nivel15 > 0);
        if (!densidadOk || !tempOk) { // || !nivel
          !densidadOk ? this.messageService.showErrorMessage(`La Densidad de la Expendedora ${precierre.codigoExpendedora} debe estar \n dentro del rango del producto ${productoSeleccionado.densidadMin} - ${productoSeleccionado.densidadMax}`) : null;
          !tempOk ? this.messageService.showErrorMessage(`La Temperatura de la Expendedora ${precierre.codigoExpendedora} no puede ser mayor a 100° ó menor a -50°`) : null;
          //!nivel ? this.messageService.showErrorMessage(`El nivel de la Expendedora ${precierre.codigoExpendedora} debe ser mayor que 0.`) : null;
          return false;
        }
      }      
    }
   
    return true;
  }

  realizarCierre() {
    if(this.validarPrecierres(this.hayCierre.precierres)) {
      this.realizandoCierre = true;
      if(this.hayCierre.estadoCierre == EstadoCierre.Abierto || this.hayCierre.estadoCierre == EstadoCierre.Cerrado) {
        this.cierresService.postCierreIniciacion(this.codigoAeroplanta, this.responsable)
          .subscribe(result => {
            this.pasaPagina = true;
            this.realizandoCierre = false;
            this.completeStep(Object.keys(EtapasCierreTurno).indexOf('Etp1'));
            this.cierreRealizoContinuado = result;
          }, (err: ErrorModel) => {
            this.messageService.showErrorMessage('Hubo un error al cerrar el turno' + err.description);
            this.realizandoCierre = false;
          });
      }
    }    
  }

  completeStep(index: number): void {
    this.cierresService.updateStepProgress(index, true);
  }

  precerrarAbastecedora(p: Precierre){
    let modalRef = this.activeModal.open(PrecierreAbastecedoraComponent, { centered: true, backdrop: 'static', size: 'lg' });
    modalRef.componentInstance.precierre = p;
    modalRef.componentInstance.cierreId = this.hayCierre.id;
    modalRef.result.then(result => {
      if (result == true) {
        this.getEstadoCierre();
      }
    }, () => {
      console.log("aaa");
    });
  }

  obtenerAerovale(id: string, i:number) {
    this.bloquearBotones = true;
    this.aerovaleDetalle = null;    
    this.aerovaleSeleccionado=i;
    this.aerovaleService.getAerovalePorIdyTipo(id)
      .subscribe(result => {
        this.aerovaleDetalle = result;           
        this.aerovaleSeleccionado= null;
        this.bloquearBotones = false; 
        if(this.aerovaleDetalle != null) {
          this.mostrarAerovale();
        }}, (err: ErrorModel) => {
          this.messageService.showErrorMessage('Hubo un error al obtener el Aerovale' + err.description);   
          this.aerovaleSeleccionado=null;    
          this.bloquearBotones = false;
      });
  }

  mostrarAerovale() {
    let modalRef = this.activeModal.open(AerovaleDetalleComponent, { size: "lg", centered: true })
    modalRef.componentInstance.aerovaleDetalle = this.aerovaleDetalle;  
  }

  modalGenerarInforme() {
    let modalRef = this.activeModal.open(GenerarInformeAerovalesComponent, { centered: true, backdrop: 'static', size: 'lg', windowClass: 'myCustomModalClass' });
    modalRef.componentInstance.cierreTurno = this.hayCierre;
    modalRef.result.then(result => {
      if (result == true) {

      }
    }, () => {

    });

  }

  formatDate(year, month, day, hour, minute, seconds): string {
    return year + '-' + month.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + '-' + day.toLocaleString(undefined, { minimumIntegerDigits: 2 })
      + 'T' + hour.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + minute.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + seconds.toLocaleString(undefined, { minimumIntegerDigits: 2 });
  }

  remarcarRemitos(rem:Remito[]) {
    let fechaHoy = new Date;
    const dias = 2;
    fechaHoy.setDate(fechaHoy.getDate() - dias);
    this.hayRemitoVencidos = false;
    rem.forEach(e => {
      let fechaRemito = new Date(e.fechaHoraCreacion);
      if(fechaRemito < fechaHoy) {
        e.vencido = true;
        this.hayRemitoVencidos = true;
      } else {
        e.vencido = false;        
      }
      e.fechaLimite = this.formatDate(fechaRemito.getFullYear(), fechaRemito.getMonth() + 1, fechaRemito.getDate() + dias, fechaRemito.getHours(), fechaRemito.getMinutes(), 0);      
    });
  }
}
