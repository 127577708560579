import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { StockRoutingModule } from './stock-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { StockIndexComponent } from './stock-index/stock-index.component';
import { TableroComponent } from './tableros/tablero/tablero.component';
import { MovimientosComponent } from './tableros/movimientos/movimientos.component';
import {MovimientoDetalleComponent} from './tableros/movimiento-detalle/movimiento-detalle.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { TableroDetalleComponent } from './tableros/tablero-detalle/tablero-detalle.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GruposTanquesComponent } from './configuraciones/grupos-tanques/grupos-tanques.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NuevoGrupoComponent } from './configuraciones/grupos-tanques/nuevo-grupo/nuevo-grupo.component';
import { NuevaIslaComponent } from './configuraciones/grupos-tanques/nueva-isla/nueva-isla.component';
import { AbastecedorasComponent } from './configuraciones/abastecedoras/abastecedoras.component';
import { TanquesComponent } from './configuraciones/tanques/tanques.component';
import { NuevoTanqueComponent } from './configuraciones/tanques/nuevo-tanque/nuevo-tanque.component';
import { NuevaAbastecedoraComponent } from './configuraciones/abastecedoras/nueva-abastecedora/nueva-abastecedora.component';
import { SurtidoresComponent } from './configuraciones/surtidores/surtidores.component';
import { NuevoSurtidorComponent } from './configuraciones/surtidores/nuevo-surtidor/nuevo-surtidor.component';
import { MedicionesComponent } from './tableros/mediciones/mediciones.component';
import { NuevaMedicionComponent } from './tableros/mediciones/nueva-medicion/nueva-medicion.component';
import { GraduacionesImportComponent } from './configuraciones/tanques/graduaciones-import/graduaciones-import.component';
import { VisorEquivalenciasComponent } from './configuraciones/tanques/visor-equivalencias/visor-equivalencias.component';
import { VisorTanquesRelacionadosComponent } from './configuraciones/surtidores/visor-tanques-relacionados/visor-tanques-relacionados.component';
import { NuevoMovimientoComponent } from './tableros/tablero/nuevo-movimiento/nuevo-movimiento.component';
import { AeroespecialidadesComponent } from './tableros/aeroespecialidades/aeroespecialidades.component';
import { VisorPicosComponent } from './configuraciones/abastecedoras/nueva-abastecedora/visor-picos/visor-picos.component';
import { MedicionesMasivasComponent } from './tableros/mediciones/mediciones-masivas/mediciones-masivas.component';
import { VisorComprobanteCargamentoComponent } from '../shared/visor-comprobante-cargamento/visor-comprobante-cargamento.component';
import { IngresoDuctoComponent } from './listados/ingreso-ducto/ingreso-ducto.component';
import { ModalTransfenrenciaComponent } from './tableros/movimientos/modal-transfenrencia/modal-transfenrencia.component';
import { EmisionRemitoComponent } from './listados/emision-remito/emision-remito.component';
import { CaneriasComponent } from './configuraciones/canerias/canerias.component';
import { NuevaCaneriaComponent } from './configuraciones/canerias/nueva-caneria/nueva-caneria.component';
import { AjusteManualCombustibleComponent } from './listados/ajuste-manual-combustible/ajuste-manual-combustible.component';
import { AjusteManualAeroespecialidadesComponent } from './listados/ajuste-manual-aeroespecialidades/ajuste-manual-aeroespecialidades.component';
import { VisorAerovalesComponent } from '../despacho/visor-aerovales/visor-aerovales.component';
import { EquiposFijosComponent } from './configuraciones/equipos-fijos/equipos-fijos.component';
import { SistemasSeguridadComponent } from './configuraciones/sistemas-seguridad/sistemas-seguridad.component';
import { NuevoEquipoComponent } from './configuraciones/equipos-fijos/nuevo-equipo/nuevo-equipo.component';
import { NuevoSistemaComponent } from './configuraciones/sistemas-seguridad/nuevo-sistema/nuevo-sistema.component';
import { PedidosComponent } from './listados/pedidos/pedidos.component';
import { NuevoPedidoCombustibleComponent } from './listados/pedidos/nuevo-pedido-combustible/nuevo-pedido-combustible.component';
import { ConfiguracionesIndexComponent } from './configuraciones/configuraciones-index/configuraciones-index.component';
import { AeroplantasListaComponent } from './configuraciones/aeroplantas/aeroplantas-lista/aeroplantas-lista.component';
import { AeroplantasDetalleComponent } from './configuraciones/aeroplantas/aeroplantas-detalle/aeroplantas-detalle.component';
import { ListadosIndexComponent } from './listados/listados-index/listados-index.component';
import { TablerosIndexComponent } from './tableros/tableros-index/tableros-index.component';
import { ModalContactosComponent } from './configuraciones/modal-contactos/modal-contactos.component';

@NgModule({
  declarations: [
    StockIndexComponent,
    TableroComponent,
    MovimientosComponent,
    MovimientoDetalleComponent,
    TableroDetalleComponent,
    GruposTanquesComponent,
    NuevoGrupoComponent,
    NuevaIslaComponent,
    AbastecedorasComponent,
    TanquesComponent,
    NuevoTanqueComponent,
    NuevaAbastecedoraComponent,
    SurtidoresComponent,
    NuevoSurtidorComponent,
    MedicionesComponent,
    NuevaMedicionComponent,
    GraduacionesImportComponent,
    VisorEquivalenciasComponent,
    VisorTanquesRelacionadosComponent,
    NuevoMovimientoComponent,
    AeroespecialidadesComponent,
    VisorPicosComponent,
    MedicionesMasivasComponent,
    IngresoDuctoComponent,
    ModalTransfenrenciaComponent,
    EmisionRemitoComponent,
    CaneriasComponent,
    NuevaCaneriaComponent,
    AjusteManualCombustibleComponent,
    AjusteManualAeroespecialidadesComponent,
    EquiposFijosComponent,
    SistemasSeguridadComponent,
    NuevoEquipoComponent,
    NuevoSistemaComponent,
    PedidosComponent,
    NuevoPedidoCombustibleComponent,
    ConfiguracionesIndexComponent,
    AeroplantasListaComponent,
    AeroplantasDetalleComponent,
    ListadosIndexComponent,
    TablerosIndexComponent,
    ModalContactosComponent
  ],
  entryComponents: [
    NuevoGrupoComponent,
    NuevaIslaComponent,
    NuevoTanqueComponent,
    NuevaAbastecedoraComponent,
    NuevoSurtidorComponent,
    NuevaMedicionComponent,
    GraduacionesImportComponent,
    VisorEquivalenciasComponent,
    VisorTanquesRelacionadosComponent,
    VisorComprobanteCargamentoComponent,
    NuevoMovimientoComponent,
    VisorPicosComponent,
    IngresoDuctoComponent,
    ModalTransfenrenciaComponent,
    NuevaCaneriaComponent,
    EmisionRemitoComponent,
    AjusteManualCombustibleComponent,
    AjusteManualAeroespecialidadesComponent
  ],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    HighchartsChartModule,
    StockRoutingModule,
    SharedModule,
    NgxSpinnerModule,
    NgbModule,
    BrowserAnimationsModule,
    DragDropModule
  ],
    providers:[
      DecimalPipe,
      VisorAerovalesComponent
    ]
})

export class StockModule { }
