import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Contacto } from '../models/despacho/Contacto';
import { Ubicacion } from '../models/despacho/Ubicacion';
import { UntypedFormControl, Validators, UntypedFormGroup, AbstractControl } from '@angular/forms';
import { ClienteService } from 'src/app/cliente/cliente.service';
import { ModalConfirmComponent } from '../generic-modals/modal-confirm.component';
import { Cliente } from '../models/despacho/Cliente';
import { CuentaCliente } from '../models/despacho/CuentaCliente';
import { TipoDocumento } from '../enums/tipoDocumento';
import { MessageService } from 'src/app/shell/message.service';
import { ErrorModel } from '../models/ErrorModel';
import { TipoCuenta } from '../enums/tipoCuenta';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-nuevo-cliente',
  templateUrl: './nuevo-cliente.component.html',
  styleUrls: ['./nuevo-cliente.component.css'],
})

export class NuevoClienteComponent implements OnInit {

  @Input() nombreCliente: string;

  public isCollapsedCliente = false;
  public isCollapsedResponsable = true;
  public isCollapsedUbicacion = true;
  
  public env = environment;

  clienteNuevoForm = new UntypedFormGroup({
    razonSocialFormControl: new UntypedFormControl(null, Validators.required),
    tipoDocumentoFormControl: new UntypedFormControl(null, Validators.required),
    cuitFormControl: new UntypedFormControl(null, [ Validators.required]),
    mailFormControl: new UntypedFormControl(null)
  });
      
  //, Validators.pattern('(20|23|24|27|30|33|34)(\\D)?[0-9]{8}(\\D)?[0-9]'),

  clienteNuevo: CuentaCliente;
  responsable: Contacto;
  ubicacion: Ubicacion;

  guardando: boolean = false;

  constructor(
    private clienteService: ClienteService,
    public activeModal: NgbActiveModal,
    public modalService: NgbModal,
    public messageService: MessageService
  ) { }

  ngOnInit() {
    console.log(this.nombreCliente);
    this.razonSocial.setValue(this.nombreCliente);
    this.tipoDocumentoFormControl.setValue(96);

    this.clienteNuevoForm.setValidators(this.validarForm.bind(this));
  }

  validarForm(group: UntypedFormGroup): any{
    let tipoDocumento = group.get("tipoDocumentoFormControl").value;    
    let nroDocumento = group.get("cuitFormControl").value;    

    if (nroDocumento!=null) {
      if(tipoDocumento == TipoDocumento.DNI){              
        if( !this.dniValido(nroDocumento) ){
          return { nroInvalido: true };
        }
      }else{
        if( ! this.cuitValido(nroDocumento) ){
          return { nroInvalido: true };
        }
      }
    }
    
    let email = group.get("mailFormControl").value; 
    if(this.esElectrica && email && email.length > 0) {
        const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        if( !regex.test(email) ){
          return { mailInvalido: true };
        }
    }

    return null;
  }

  dniValido(dni: string): boolean {
    if ((dni.length < 7 || dni.length > 10) || isNaN(Number(dni))){
      return false;
    }
    return true;
  }

  cuitValido(cuil: string): boolean {
    if (cuil.length !== 11) {
      return false;
    }
  
    const [checkDigit, ...rest] = cuil
      .split('')
      .map(Number)
      .reverse();
  
    const total = rest.reduce(
      (acc, cur, index) => acc + cur * (2 + (index % 6)),
      0,
    );
  
    const mod11 = 11 - (total % 11);
  
    if (mod11 === 11) {
      return checkDigit === 0;
    }
  
    if (mod11 === 10) {
      return false;
    }
  
    return checkDigit === mod11;
  }

  guardarCliente() {
    
    this.clienteNuevo = new CuentaCliente();
    this.clienteNuevo.razonSocial = this.razonSocial.value;
    this.clienteNuevo.numeroDocumento = (this.clienteNuevoForm.get('cuitFormControl')).value;
    this.clienteNuevo.tipoDocumento = (this.clienteNuevoForm.get('tipoDocumentoFormControl')).value;
    this.clienteNuevo.email = (this.esElectrica) ? (this.clienteNuevoForm.get('mailFormControl')).value : null;
    this.clienteNuevo.activo = true;

    this.guardando = true;
    this.clienteService.crearClienteContado(this.clienteNuevo)
      .subscribe(cliente => {
        this.messageService.showSuccessMessage('El cliente consumidor final se creó con éxito');
        cliente.tipoCuenta = TipoCuenta.Contado;
        this.activeModal.close(cliente); 
        this.guardando = false;
      }, (err:ErrorModel)=> {
        this.messageService.showErrorMessage(err.description);
        this.guardando = false;
      });
  }

  //acordion
  collapseCliente() {
    this.isCollapsedCliente = false;
    this.isCollapsedResponsable = true;
    this.isCollapsedUbicacion = true;
  }

  collapseResponsable() {
    this.isCollapsedResponsable = false;
    this.isCollapsedCliente = true;
    this.isCollapsedUbicacion = true;
  }

  collapseUbicacion() {
    this.isCollapsedUbicacion = false;
    this.isCollapsedCliente = true;
    this.isCollapsedResponsable = true;
  }

  get razonSocial() {
    return this.clienteNuevoForm.get('razonSocialFormControl');
  }

  get cuit() {
    return this.clienteNuevoForm.get('cuitFormControl');
  }

  get tipoDocumentoFormControl() {
    return this.clienteNuevoForm.get('tipoDocumentoFormControl');
  }

  get mail() {
    return this.clienteNuevoForm.get('mailFormControl');
  }

  get esElectrica() {
    return environment.electrica;
  }
}
