<ul class="nav nav-tabs justify-content-start">
    <li  class="nav-item" *ngIf="hasRole(TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_LN, TipoUsuario.COMERCIAL_AV, TipoUsuario.SOPORTE)">
        <a class="nav-link" routerLink="abmMatriculas" routerLinkActive="active">ABM Matriculas</a>
    </li>
    <li  class="nav-item" *ngIf="hasRole(TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_LN, TipoUsuario.COMERCIAL_AV, TipoUsuario.SOPORTE)">
        <a class="nav-link" [queryParams]="{solapaAutorizacion: TipoSolapaAutorizacion.Pendientes}" routerLink="autorizacionesPendientes" routerLinkActive="active">Autorizaciones Pendientes</a>
    </li>
    <li  class="nav-item" *ngIf="hasRole(TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_LN, TipoUsuario.COMERCIAL_AV, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.TUTOR, TipoUsuario.SOPORTE)">
        <a class="nav-link" [queryParams]="{solapaAutorizacion: TipoSolapaAutorizacion.Historial}" routerLink="autorizacionesHistorial" routerLinkActive="active">Historial de Autorizaciones</a>
    </li>
    <li  class="nav-item" *ngIf="hasRole(TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_LN, TipoUsuario.COMERCIAL_AV, TipoUsuario.SOPORTE)">
        <a class="nav-link" routerLink="preautorizaciones" routerLinkActive="active">Preautorizaciones</a>
    </li>
</ul>
<router-outlet></router-outlet>