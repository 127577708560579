import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MessageService } from 'src/app/shell/message.service';
import { CierreTurnoService } from 'src/app/cierreTurno/cierre-turno.service';
import { Contacto } from 'src/app/shared/models/cierreTurno/Contacto';

@Component({
  selector: 'app-modal-contactos',
  templateUrl: './modal-contactos.component.html',
  styleUrls: ['./modal-contactos.component.scss']
})
export class ModalContactosComponent implements OnInit {

  contactoForm: UntypedFormGroup;

  @Input() contacto: Contacto;  
  editandoContacto: Boolean = false;
  grabando: Boolean = false;

  constructor(private formBuilder:UntypedFormBuilder, public activeModal: NgbActiveModal, public cierreTurnoService: CierreTurnoService, public messageService: MessageService) { }

  ngOnInit(): void {
    this.crearFormulario();
    if (this.contacto) {
      this.editandoContacto=true;
      this.cargarFormulario();
    }else{
      this.editandoContacto=false;
    }

  }
  
  crearFormulario() {
    this.contactoForm = this.formBuilder.group({
      nombreFormControl: ['', Validators.required],     
      numeroFormControl: ['', Validators.required],
      emailFormControl: ['', [Validators.required, Validators.email]] 
    });
  }

  cargarFormulario() {
    this.nombreFormControl.setValue(this.contacto.nombre);
    this.numeroFormControl.setValue(this.contacto.numero);
    this.emailFormControl.setValue(this.contacto.email)
  }

  async agregarContacto() {

    let contacto = new Contacto();
    contacto.nombre = this.nombreFormControl.value;
    contacto.numero = this.numeroFormControl.value;
    contacto.email = this.emailFormControl.value;
    this.grabando = true;

    if (!this.editandoContacto) {      
      try {
        let r = await this.cierreTurnoService.postContactos(contacto);
        if (r!=undefined) {
          this.messageService.showSuccessMessage('El contacto fue creado con éxito');
          this.grabando = false;
          contacto.id = r.id;
          contacto._editando = false;
          this.activeModal.close(contacto);
        }
      } catch (e) {
        this.messageService.showErrorMessage('Ocurrió un error, vuelva a intentar.');
      }
    }else{
      try {
        let r = await this.cierreTurnoService.putContactos(contacto);
        if (r) {
          this.messageService.showSuccessMessage('El contacto fue modificado con éxito');
          this.grabando = false;
          contacto.id = this.contacto.id;
          contacto._editando = true;
          this.activeModal.close(contacto);
        }
      } catch (e) {
        this.messageService.showErrorMessage('Ocurrió un error, vuelva a intentar.');
      }
    }
    
  }

  /** getters */

  get nombreFormControl() {
    return this.contactoForm.get('nombreFormControl');
  }

  get numeroFormControl() {
    return this.contactoForm.get('numeroFormControl');
  }

  get emailFormControl() {
    return this.contactoForm.get('emailFormControl')
  }

}
