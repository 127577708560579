<nav class="navbar navbar-light bg-light navbar-expand-lg mb-3">
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto">
        <li class="nav-item">
          <div class="nav-item" [formGroup]="searchForm">
            <input id="busquedaFormControl" type="text" class="form-control input-sm" placeholder="Búsqueda"
              formControlName="busquedaFormControl" (input)="filtrarByr()" placeholder="Buscar" />
          </div>
        </li>
  
        <li class="nav-item">
          <div class="nav-item" style="padding-left: 5em; padding-top: 0.35em; font-weight: bold;">
            <div>Trabajando bonificaciones y recargos de productos.</div>
          </div>
        </li>
      </ul>
      <ul class="navbar-nav">
        <li *ngIf="!hasRole(TipoUsuario.APROBADORBYR) && usuarioPuedeDelegar()" class="nav-item" style="float:right">
          <button type="button" (click)="delegarNivel()" class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2">
            Delegar</button>
        </li>
        <li *ngIf="false" class="nav-item" style="float:right">
          <button type="button" [disabled]="this.cargandoByrDeLote || this.bonificacionesRecargos==null || this.bonificacionesRecargos.length == 0" (click)="eliminarLote()" class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2"> Eliminar
            lote</button>
        </li>
        <li *ngIf="hasRole(TipoUsuario.APROBADORBYR)" class="nav-item" style="float:right">
          <button type="button" [disabled]="this.cargandoByrDeLote || this.bonificacionesRecargos==null || this.bonificacionesRecargos.length == 0" (click)="aprobarByr()" class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2">
            Aprobar Lote</button>
        </li>
        <li *ngIf="hasRole(TipoUsuario.APROBADORBYR)" class="nav-item" style="float:right">
          <button type="button" [disabled]="this.cargandoByrDeLote || this.bonificacionesRecargos==null || this.bonificacionesRecargos.length == 0" (click)="rechazarByr()" class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2">
            Rechazar Lote</button>
        </li>
      </ul>
    </div>
  </nav>
  
  <div class="col col-md-12">
    <div class="spinner-container" *ngIf="cargandoLotes">
      <ngx-spinner name="spinnerLotes" bdOpacity=0.3bd
        Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
        <p style="color: white">C a r g a n d o . . . </p>
      </ngx-spinner>
    </div>
  
    <div class="row" *ngIf="!cargandoLotes">
  
      <div class="col col-3">
  
        <ngb-alert type="warning" [dismissible]="false" *ngIf="this.lotesByr && this.lotesByr.length == 0">
          No hay lotes con byr pendientes.
        </ngb-alert>
  
        <div class="list-group menu-izq">
          <div *ngFor="let lote of lotesByr; let i=index">
            <button type="button" (click)="mostrarDetalleLote(i)" class="list-group-item list-group-item-action"
              [class.seleccionado]="loteSeleccionado && loteSeleccionado.id == lote.id">
              <div style="text-align: center; width: 100%;">

                  <b>Lote {{ lote.numeroLote }}</b> | Val. Max.: {{lote.valorMaximo}} | Registros: {{lote.cantidadRegistros}}
                  <br />
                  {{ lote.responsable }}

              </div>
            </button>
          </div>
        </div>
      </div>
      <!-- informacion de precios de lote -->
      <div id="detalleNivel" class="col-9 nopadding">
        <ngb-alert type="warning" [dismissible]="false" *ngIf="this.loteSeleccionado == null && this.mensajeAccesoInsuficiente == null">
          No hay ningún lote seleccionado.
        </ngb-alert>
  
        <ngb-alert type="danger" [dismissible]="false" *ngIf="this.mensajeAccesoInsuficiente != null">
          {{ this.mensajeAccesoInsuficiente }}
        </ngb-alert>
  
        <div class="car-body" *ngIf="loteSeleccionado != null">
          <div class="col col-md-12  nopadding">
  
            <div *ngIf="cargandoByrDeLote" class="row">
              <loading-text-spinner class="col-md-12" message="Obteniendo precios del lote..." color="primary">
              </loading-text-spinner>
            </div>
  
          </div>
          <div class="card add-margin" *ngIf="!this.cargandoByrDeLote">
            <div>
  
              <ngb-alert type="warning" [dismissible]="false"
                *ngIf="this.loteSeleccionado != null &&  this.mensajeAccesoInsuficiente == null && this.bonificacionesRecargos.length == 0">
                No hay byr para el lote seleccionado.
              </ngb-alert>
  
              <div id="listadoPrecios" *ngIf="this.loteSeleccionado != null && this.bonificacionesRecargos.length > 0">
                <div class="row">
                  <div class="col-md-3" style="padding-top: 0.5em; padding-left: 2em;">
                    <loading-text-spinner *ngIf="actualizandoPrecios" [error]="errorPrecios" color="primary"
                      textColor="dark" message="Obteniendo pagina {{this.page}}..."></loading-text-spinner>
                  </div>
  
                  <ngb-pagination (pageChange)="onPageChange($event)" [collectionSize]="collectionSize" [maxSize]="8"
                    [(page)]="page" [pageSize]="pageSize" *ngIf="this.bonificacionesRecargosFiltradas!=null"
                    class="col-md-7 d-flex justify-content-center">
                  </ngb-pagination>
                </div>
  
                <table id="tabla" class="table table-sm table-hover">
                  <thead class="text-center letra">
                    <tr>
                      <th scope="col"> Estado </th>
                      <th scope="col" > Clientes </th>
                      <th scope="col" > Productos </th>
                      <th scope="col" > Aeroplantas </th>
                      <th scope="col" > Fecha vigencia</th>
                      <th scope="col"> Valor </th>
                      <th scope="col"> Tipo </th>
                      <th scope="col"> Solicitado </th>
                    </tr>
                  </thead>
                  <tbody class="text-center letra">
                    <tr *ngFor="let p of bonificacionesRecargosFiltradas; let i = index">
          
                      <td>{{ this.obtenerLeyendaEstado(bonificacionesRecargosFiltradas[i].estado.estadoPrecio) }}</td>
          
                      <td >
                        <span *ngFor="let c of bonificacionesRecargosFiltradas[i].clientes">
                          *{{ c.cta_SGC }} &nbsp; 
                        </span>
                      </td>
          
                      <td >
                        <span *ngFor="let p of bonificacionesRecargosFiltradas[i].productos">
                          *{{ p.codigoProducto }} &nbsp; 
                        </span>
                      </td>
          
                      <td> 
                        <span *ngFor="let cod of bonificacionesRecargosFiltradas[i].codigosAeroplantas">
                          *{{ cod }} &nbsp; 
                        </span>
                      </td>
          
                      <td 
                        *ngIf="bonificacionesRecargosFiltradas[i].fechaDesde != null && bonificacionesRecargosFiltradas[i].fechaHasta != null">
                        {{bonificacionesRecargosFiltradas[i].fechaDesde | date: "dd'/'MM'/'yy"}} -
                        {{bonificacionesRecargosFiltradas[i].fechaHasta | date: "dd'/'MM'/'yy"}}</td>
          
                      <td 
                        *ngIf="bonificacionesRecargosFiltradas[i].fechaHasta == null && bonificacionesRecargosFiltradas[i].fechaDesde">
                        -- </td>
          
                      <td> {{bonificacionesRecargosFiltradas[i].valor != null?bonificacionesRecargosFiltradas[i].valor: "--"}}</td>
          
                      <td> {{obtenerLeyendasBonificacion(bonificacionesRecargosFiltradas[i].tipo)}}</td>
          
                      <td> {{bonificacionesRecargosFiltradas[i].solicitadoPor != null?bonificacionesRecargosFiltradas[i].solicitadoPor:
                        "--"}}</td>         
                    </tr>
                  </tbody>
                </table>
  
                <div class="row">
                  <div class="col-md-4" style="padding-top: 1em; padding-left: 2em;">
                  </div>
  
                  <ngb-pagination (pageChange)="onPageChange($event)" [collectionSize]="collectionSize" [maxSize]="10"
                    [(page)]="page" [pageSize]="pageSize"
                    *ngIf="this.bonificacionesRecargosFiltradas!=null && this.bonificacionesRecargosFiltradas.length > 0"
                    class="col-md-8 d-flex justify-content-center">
                  </ngb-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>