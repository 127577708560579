import { Guid } from 'guid-typescript';

export class Producto {
    id: string;
    nombreProducto: string;
    codigoProducto: string;
    unidadMedida: number;
    color: string;
    tipoProducto: number;
}
