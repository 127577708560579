import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { VehiculoService } from 'src/app/cliente/vehiculo.service';
import { ModalVehiculoComponent } from 'src/app/management/matriculas/abm-matriculas/modal-vehiculo/modal-vehiculo.component';
import { isNullOrUndefined } from 'src/app/shared/compat-tools';
import { TipoVehiculo } from 'src/app/shared/enums/tipoVehiculo';
import { ModalConfirmComponent } from 'src/app/shared/generic-modals/modal-confirm.component';
import { AutorizacionDTO } from 'src/app/shared/models/cliente/AutorizacionDTO';
import { VehiculoDTO } from 'src/app/shared/models/cliente/VehiculoDTO';
import { CuentaCliente } from 'src/app/shared/models/despacho/CuentaCliente';
import { NuevoClienteComponent } from 'src/app/shared/nuevo-cliente/nuevo-cliente.component';
import { MessageService } from 'src/app/shell/message.service';
import { ClienteService } from 'src/app/cliente/cliente.service';
import { Guid } from 'guid-typescript';
import { EstadoAutorizacionAev } from 'src/app/shared/enums/EstadoAutorizacionAev';
import { EstadoAutorizacion } from 'src/app/shared/enums/EstadoAutorizacion';
import { TipoCuenta } from 'src/app/shared/enums/tipoCuenta';

export enum TipoSeleccion {
  Matriculas,
  Clientes 
}

export class ClaseCuenta {
  cuenta: string;
  nombre: string;
  limitado: boolean;
}
@Component({
  selector: 'app-modal-seleccion-matriculas',
  templateUrl: './modal-seleccion-matriculas.component.html',
  styleUrls: ['./modal-seleccion-matriculas.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ModalSeleccionMatriculasComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ModalSeleccionMatriculasComponent),
      multi: true
    }
  ]
})
export class ModalSeleccionMatriculasComponent implements OnInit {

  @Input() clientes: CuentaCliente[];
  @Input() aeronaves: VehiculoDTO[];
  @Input() tipo: TipoSeleccion;

  formSelec = this.formBuilder.group({
    aeronaveFormControl: (''),
    clienteFormControl: (''),
    relacionesFormControl: (null),
  });

  modal: any;

  autorizacionDto: AutorizacionDTO;

  TipoSeleccion = TipoSeleccion;
  EstadoAutorizacionAev = EstadoAutorizacionAev;
  EstadoAutorizacion = EstadoAutorizacion;

  //Aeronaves
  cargandoAeronaves: boolean = false;
  errorAeronaves: boolean = false;

  // relaciones en si
  aeronavesCliente: VehiculoDTO[];
  clientesAeronave: ClaseCuenta[] = [];

  cargandoAeronavesCliente: boolean = false;
  errorAeronavesCliente: boolean = false;

  busquedaPorMatriculas: boolean = true;
  busquedaPorClientes: boolean = false;
  forzarBusquedaPorClientes: boolean = false;
  forzarBusquedaPorMatriculas: boolean = false;
  mostrarClienteMatriculas: boolean = false;
  requiereAutorizacion: boolean = false;  
  requiereVerificacion: boolean = false;

  clienteTipo: 'Indefinido' | 'Libre' | 'Limitado' = 'Indefinido';
  matriculaTipo: 'Indefinido' | 'Libre' | 'Limitado' = 'Indefinido';  
  estadoResultado: 'Pendiente' | 'Aprobado' | 'Rechazado' = null;

  autorizando: boolean = false;  
  verificando: boolean = false;
  sePuedeGuardar: boolean = false;

  constructor(private amodal: NgbActiveModal,
    public formBuilder: UntypedFormBuilder,
    private modalService: NgbModal,
    private clienteService: ClienteService,
    private vehiculoService: VehiculoService,
    private messageService: MessageService) { 
    this.modal = amodal;
  }

  ngOnInit(): void {    
    if(this.tipo!=null || this.tipo!=undefined) {
      this.changeTipoSeleccion(this.tipo);
    }
    this.initValidators(true, true, false);
    
  }

  resetScreen(tipo: TipoSeleccion) {
    this.autorizacionDto=null;
    this.forzarBusquedaPorClientes=false;
    this.forzarBusquedaPorMatriculas=false;
    (tipo==null || (tipo!=null && this.busquedaPorMatriculas))? this.clienteFormControl.setValue(null):null;
    (tipo==null || (tipo!=null && this.busquedaPorMatriculas))? this.clienteTipo='Indefinido':null;  
    (tipo==null || (tipo!=null && this.busquedaPorClientes))? this.aeronaveFormControl.setValue(null):null;
    (tipo==null || (tipo!=null && this.busquedaPorClientes))? this.matriculaTipo='Indefinido':null;      
    this.relacionesFormControl.setValue(null);
    this.mostrarClienteMatriculas=false;  
    this.requiereAutorizacion=false;
    this.requiereVerificacion=false;
  }

  changeTipoSeleccion(tipo: TipoSeleccion) {
    this.formSelec.reset();
    this.resetScreen(null);
    if(tipo==TipoSeleccion.Matriculas) { 
      this.busquedaPorMatriculas=true; 
      this.busquedaPorClientes=false;
    }else{
      this.busquedaPorMatriculas=false;
      this.busquedaPorClientes=true;
    }
  }

  initValidators(ae:boolean,cl:boolean,re:boolean) {
    this.formSelec.controls.aeronaveFormControl.clearValidators();    
    this.formSelec.controls.clienteFormControl.clearValidators();
    this.formSelec.controls.relacionesFormControl.clearValidators();
    
    this.formSelec.controls.aeronaveFormControl.setValidators(ae? Validators.required : null);
    this.formSelec.controls.clienteFormControl.setValidators(cl? Validators.required : null);
    this.formSelec.controls.relacionesFormControl.setValidators(re? Validators.required : null);
    
    this.formSelec.controls.aeronaveFormControl.updateValueAndValidity();
    this.formSelec.controls.clienteFormControl.updateValueAndValidity();
    this.formSelec.controls.relacionesFormControl.updateValueAndValidity();
  }

  setOptions() {
    if(!this.forzarBusquedaPorMatriculas && !this.forzarBusquedaPorClientes) {
      if (this.matriculaTipo=='Libre' && this.clienteTipo=='Limitado' || this.matriculaTipo=='Limitado' && this.clienteTipo=='Libre')
        this.requiereAutorizacion=true
      else 
        this.requiereAutorizacion=false;
    }
    if(this.forzarBusquedaPorClientes || this.forzarBusquedaPorMatriculas){
      if (this.matriculaTipo=='Libre' && this.clienteTipo=='Libre')
        this.requiereAutorizacion=false
      else {
        if(this.clienteFormControl.value?.tipoCuenta!=TipoCuenta.Contado)
          this.requiereAutorizacion=true;
        else
          this.requiereAutorizacion=false
      }
    }
  }

  // utiles

  search = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    map(term => term.length < 2 || this.cargandoAeronavesCliente ? [] :
      this.clientes.filter(v => this.containsString(this.clienteFormControl.value, v.ctA_SGC, v.razonSocial)).slice(0, 10))
  )

  /**
   * Determina si una cadena se encuentra dentro de otra
   * @param searchValue Valor a buscar
   * @param searchIn Valores sobre los que buscar searchValue
   */
  containsString(searchValue: string, ...searchIn: string[]): boolean {
    const concat = searchIn.toString();
    return concat.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
  }

  formatter = (x: { razonSocial: string, ctA_SGC: string }) => `${x.razonSocial} - ${x.ctA_SGC}`;

  changeCliente() {  
    if (typeof (this.clienteFormControl.value) === 'object') {
      this.clienteFormControl.value?.limitado ? this.clienteTipo = 'Limitado' : this.clienteTipo = 'Libre';
    }else{
      this.changeValidators();
    }   
  }

  nuevoCliente() {
    const modalRef = this.modalService.open(NuevoClienteComponent);
    modalRef.result.then((cliente) => {
      if (cliente) {
        this.messageService.showSuccessMessage("Cliente generado exitosamente")
        this.clientes.push(cliente);
        this.clienteFormControl.setValue(cliente);
        this.selectCliente(cliente);
      }
    });
  }

  selectCliente(e: any) {
    let selec = (e.item!=undefined) ? e.item : e;
    this.clienteFormControl.setValue(selec);  
    this.changeCliente();
    // lo saco del change porque llama 2 veces a back
    if(this.busquedaPorClientes) {
      this.resetScreen(TipoSeleccion.Clientes);
      this.buscarMatriculasXCliente(selec);
    }
    this.setOptions();
  }

  /** utiles aeronaves */

  changeAeronave(): void {
    let buscaAeronave: VehiculoDTO = this.aeronaveFormControl.value;
    //Esto es por si ingresan el valor abc123 pero existe una aeronave ABC123, entonces elige esta última
    if (typeof (this.aeronaveFormControl.value) === 'string') {
      buscaAeronave = this.aeronaves.find(p => p.matricula.toLocaleLowerCase().trim() == this.aeronaveFormControl.value.toLocaleLowerCase().trim());
    }
    if (buscaAeronave) {
      this.aeronaveFormControl.setValue(buscaAeronave);
      this.aeronaveFormControl.value?.limitado ? this.matriculaTipo = 'Limitado' : this.matriculaTipo = 'Libre';
      if(this.busquedaPorMatriculas) {
        this.resetScreen(TipoSeleccion.Matriculas);
        this.clienteTipo = 'Indefinido';

        this.clientesAeronave = [];
        for (let i = 0; i < buscaAeronave.ctas_SGC.length; i++) {
          const element = buscaAeronave.ctas_SGC[i];
          let encont = this.clientes.find( c => c.ctA_SGC == element);
          if(encont!=undefined) {
            this.clientesAeronave.push(new ClaseCuenta());
            this.clientesAeronave[i].cuenta = element;            
            this.clientesAeronave[i].nombre = encont?.razonSocial;
            this.clientesAeronave[i].limitado = encont?.limitado;
          }          
        }      
        
        if (buscaAeronave.ctas_SGC.length==0 || this.clientesAeronave.length==0) {
          this.mostrarClienteMatriculas=false;
          this.forzarBusquedaPorClientes=true; 
          this.initValidators(true, true, false);
        }else{
          this.mostrarClienteMatriculas=true;
          this.initValidators(true, false, true);
        } 
      }             
    }else{
      this.changeValidators();
    }    
  }

  selectAeronave(e: any) {    
      this.aeronaveFormControl.setValue( (e.item!=undefined) ? e.item : e );
      this.changeAeronave();
      this.setOptions();  
  }

  searchAeronave = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    map(term => term.length < 2 || this.cargandoAeronavesCliente || isNullOrUndefined(this.aeronaves) ? []
      : this.aeronaves.filter(v => v.matricula.toLowerCase().indexOf(this.aeronaveFormControl.value.toLowerCase()) > -1).slice(0, 10))
  )

  formatterAeronave = (x: { matricula: string }) => x.matricula;

  changeClienteMatricula(): void {
    if(this.busquedaPorMatriculas) {
      this.relacionesFormControl.value?.limitado ? this.clienteTipo = 'Limitado' : this.clienteTipo = 'Libre';
    }else{
      this.relacionesFormControl.value?.limitado ? this.matriculaTipo = 'Limitado' : this.matriculaTipo = 'Libre';
    }
    this.changeValidators();     
  }

  nuevoAeronave() {
    const modalRef = this.modalService.open(ModalVehiculoComponent, { backdrop: "static", centered: true });
    modalRef.componentInstance.tipoVehiculoParaFiltrar = TipoVehiculo.Aeronave;
    modalRef.componentInstance.vehiculos = this.aeronaves;    
    modalRef.result.then((vehiculo) => {
      if(vehiculo) {
        this.messageService.showSuccessMessage("Vehiculo creado");
        this.aeronaves.push(vehiculo);
        this.aeronaveFormControl.setValue(vehiculo);
        this.selectAeronave(vehiculo);
      }      
    });
  }

  habilitarBusqueda() {
    this.mostrarClienteMatriculas=false;
    if(this.busquedaPorClientes) {
      this.forzarBusquedaPorMatriculas=true;
      this.matriculaTipo = 'Indefinido';      
    }else{    
      this.forzarBusquedaPorClientes=true;  
      this.clienteTipo = 'Indefinido'; 
    }     
    this.initValidators(true, true, false);
  }

  // servicios
  buscarMatriculasXCliente(cliente:CuentaCliente) {
    this.cargandoAeronavesCliente=true;    
    this.vehiculoService.getCuentasVehiculos(cliente.tipoDocumento, cliente.numeroDocumento)
    .subscribe( resp => {
      this.aeronavesCliente = resp.filter( v => v.ctA_SGC == cliente.ctA_SGC)[0].vehiculos.filter( a => a.tipoVehiculo==TipoVehiculo.Aeronave);
      
      if (this.aeronavesCliente.length==0){
        this.mostrarClienteMatriculas=false;
        this.forzarBusquedaPorMatriculas=true;
        this.initValidators(true, true, false);
      }else{
        this.mostrarClienteMatriculas=true;
        this.initValidators(false, true, true);
      }      
      this.matriculaTipo = 'Indefinido'; // reseteo el cartel por si vuelve a seleccionar otro cliente            
      this.cargandoAeronavesCliente = false;
    }, () => {
      this.errorAeronavesCliente = true;
      this.cargandoAeronavesCliente = false;
    });
  }

  // boton OK
  onClick() {    
    if (this.formSelec.invalid) {
      this.messageService.showErrorMessage("Faltan completar campos");
      return
    }else{
     if(!this.autorizacionDto) {      
      this.autorizacionDto = new AutorizacionDTO();   
      if(this.busquedaPorMatriculas) {
        this.autorizacionDto.matricula = this.aeronaveFormControl.value.matricula;
        if (!this.mostrarClienteMatriculas) {
          this.autorizacionDto.ctA_SGC = this.clienteFormControl.value.ctA_SGC;
          this.autorizacionDto.cuentaDescripcion = this.clienteFormControl.value.razonSocial;            
        }else{
          this.autorizacionDto.ctA_SGC = this.relacionesFormControl.value.cuenta;
          this.autorizacionDto.cuentaDescripcion = this.relacionesFormControl.value.nombre;
        }
      }else{
        this.autorizacionDto.ctA_SGC = this.clienteFormControl.value.ctA_SGC;
        this.autorizacionDto.cuentaDescripcion = this.clienteFormControl.value.razonSocial;
        if(this.mostrarClienteMatriculas) {
          this.autorizacionDto.matricula = this.relacionesFormControl.value.matricula;
        }else{
          this.autorizacionDto.matricula = this.aeronaveFormControl.value.matricula;
        }        
      }   
      this.autorizacionDto.estado = EstadoAutorizacionAev.Libre;    
     }
     this.amodal.close(this.autorizacionDto);    
    }
  }

  cerrar() {
    this.amodal.close(null);
  }
  // peticiones a back async
  async autorizarPeticion() {

    this.autorizando=true;
    this.autorizacionDto = new AutorizacionDTO();
    this.autorizacionDto.idDeDespacho = Guid.create().toString();
    if (this.clienteFormControl.value instanceof ClaseCuenta)
      this.autorizacionDto.ctA_SGC = this.clienteFormControl.value.cuenta
    else  
      this.autorizacionDto.ctA_SGC = this.clienteFormControl.value.ctA_SGC;
    if (typeof(this.aeronaveFormControl.value) == 'string')
      this.autorizacionDto.matricula = this.aeronaveFormControl.value
    else  
      this.autorizacionDto.matricula = this.aeronaveFormControl.value.matricula;            
    this.autorizacionDto.usuarioSolicitador = this.clienteService.obtenerStringSolicitador();
    
    try {
      let r = await this.clienteService.postAutorizacionesV1(this.autorizacionDto);
      if (r) {
        this.autorizando=false;
        r.estado==EstadoAutorizacion.Pendiente? this.estadoResultado='Pendiente':null;
        this.requiereVerificacion=true;        
      }      
    } catch (error) {
      this.autorizando=false;
    }                
  }

  async verificarPeticion() {
    try {
      this.verificando=true;
      let r = await this.clienteService.getAutorizacionesV1(this.autorizacionDto.idDeDespacho);
      if (r) {                
        switch (r.estado) {
          case EstadoAutorizacion.Aprobado:
            this.estadoResultado='Aprobado';            
            break;
          case EstadoAutorizacion.Rechazado:
            this.estadoResultado='Rechazado';            
            break;
          default:
            this.estadoResultado='Pendiente';            
            break;
        }     
        this.verificando=false;   
      }      
    } catch (error) {
      this.verificando=false;
    }      
  }

  /** validaciones */

  addValidationError(control: AbstractControl, validationName: string) {
    let errors = control.errors;
    if (errors == null)
      errors = { [validationName]: true };
    else
      errors[validationName] = true;
    control.setErrors(errors);
    control.markAsDirty();
  }

  changeValidators() {
    if(this.busquedaPorMatriculas) {
      if (!(this.aeronaveFormControl.value instanceof Object)) {
        this.addValidationError(this.aeronaveFormControl, 'aeronaveInvalid');
        //this.aeronaveFormControl.setValue(null);
      }
      if (this.mostrarClienteMatriculas) {
        if (!(this.relacionesFormControl.value instanceof ClaseCuenta)) {
          this.addValidationError(this.relacionesFormControl, 'relacionInvalid');
          //this.relacionesFormControl.setValue(null);
        }          
      } else {
        if (!(this.clienteFormControl.value instanceof Object)) {
          this.addValidationError(this.clienteFormControl, 'clienteInvalid');
          //this.clienteFormControl.setValue(null);
        }
      }       
    }else{
      if (!(this.clienteFormControl.value instanceof Object)) {
        this.addValidationError(this.clienteFormControl, 'clienteInvalid');
        //this.clienteFormControl.setValue(null);
      }
      if(this.mostrarClienteMatriculas) {
        if (!(this.relacionesFormControl.value instanceof Object)) {
          this.addValidationError(this.relacionesFormControl, 'relacionInvalid');
          //this.relacionesFormControl.setValue(null);      
        }
      }else{
        if (!(this.aeronaveFormControl.value instanceof Object)) {
          this.addValidationError(this.aeronaveFormControl, 'aeronaveInvalid');
          //this.aeronaveFormControl.setValue(null);
        }
      }  
    }
  }

  // getters

  get clienteFormControl() {
    return this.formSelec.controls.clienteFormControl;
  }

  get aeronaveFormControl() {
    return this.formSelec.controls.aeronaveFormControl;
  }

  get relacionesFormControl() {
    return this.formSelec.controls.relacionesFormControl;
  }

}
