import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Step } from 'src/app/shared/models/cierreTurno/step';
import { CierreTurnoService } from '../../cierre-turno.service';

@Component({
  selector: 'app-cierre-nav-bar',
  templateUrl: './cierre-nav-bar.component.html',
  styleUrls: ['./cierre-nav-bar.component.scss']
})
export class CierreNavBarComponent implements OnInit {

  steps: Step[] = [];

  currentStepIndex = 0;
  progressWidth = '0%';

  constructor(private cierreTurnoService: CierreTurnoService) { }

  ngOnInit(): void {
    this.cierreTurnoService.steps$.subscribe(steps => {
      this.steps = steps;
    });
    this.calculateProgress();
  }

  calculateProgress(): void {
    const completedSteps = this.steps.filter(step => step.completed).length;
    const totalSteps = this.steps.length;

    this.progressWidth = `${(completedSteps / totalSteps) * 100}%`;
    this.currentStepIndex = completedSteps;
  }

}
