import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { Producto } from '../shared/models/despacho/Producto';
import { HttpErrorHandler } from '../http-error-handler.service';
import { AuthService } from '../core/authentication/auth.service';
import { Injectable } from '@angular/core';
import { Producto as ProductoStock } from '../shared/models/stock/Producto';
import { Medicion } from '../shared/models/stock/Medicion';
import { ContenedorRespuesta } from '../shared/models/stock/ContenedorRespuesta';

@Injectable({
  providedIn: 'root'
})
export class MedicionesService {

  [x: string]: any;

  movimientobaseUrl = environment.apiServer.stockBaseUrl;
  stockbaseUrl = environment.apiServer.stockBaseUrl;
  private medicionesUrl = this.stockbaseUrl + '/MedicionesV1';
  private tanquesUrl= this.stockbaseUrl+'/ContenedoresV1/Tanques';
  private contenedoresUrl= this.stockbaseUrl+'/ContenedoresV1/Mediciones';
  private mediciones: Medicion[];

  constructor(private httpClient: HttpClient, private httpErrorHandler: HttpErrorHandler, private authservice: AuthService) { }

  getTodasLasMedicionesV2(codigoAeroplanta: string, codigoContenedor: string, fechaInicio: string, fechaFin: string): Observable<Medicion[]>{
    if (codigoContenedor != null){
    return this.httpClient.get<Medicion[]>(this.contenedoresUrl + '?fechaDesde=' + fechaInicio + '&fechaHasta=' + fechaFin + '&codigoContenedor=' + codigoContenedor + '&codigoAeroplanta=' + codigoAeroplanta);
  }else{
    return this.httpClient.get<Medicion[]>(this.contenedoresUrl + '?fechaDesde=' + fechaInicio + '&fechaHasta=' + fechaFin + '&codigoAeroplanta=' + codigoAeroplanta);
  }
  }

  crearMedicion(medicion: Medicion): Observable<ContenedorRespuesta> {
    return this.httpClient.post<ContenedorRespuesta>(this.tanquesUrl+"/"+medicion.codigoContenedor+"/Mediciones", medicion)
      .pipe(
        tap(_ => console.log('Creacion de medicion')),
        catchError(this.handleError)
      );
  }

  getConversionLitrosPorVarillado(codigoTanque: string, varillado: string): Observable<Medicion> {
    return this.httpClient.get<Medicion>(this.medicionesUrl + "/tanque/" + codigoTanque + "/varillado/" + varillado)
      .pipe(
        tap(_ => console.log('Creacion de medicion')),
        catchError(this.handleError)
      );
  }

  convertirProducto(p: Producto){
    let prod = new ProductoStock();
    prod.codigoProducto = p.codigoProducto;
    prod.nombreProducto = p.nombreProducto;
    prod.unidadMedida = Number(p.unidadMedida);
    return prod;
  }

  crearMedicionesMasivas(mediciones: Medicion[]){
    return this.httpClient.post<Medicion[]>(this.contenedoresUrl, mediciones);
  }
}
