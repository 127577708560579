<div class="container" *ngIf="obteniendoDatos">
    <div class="mb-3" style="height: 10cm;">
        <ngx-spinner name="spinnerActa" [ngStyle]="{'height': '400px', 'z-index':'0'}" bdOpacity=0.3bd
        Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
        <p style="color: white">C a r g a n d o . . . </p>
        </ngx-spinner>
    </div>
</div>
  
<div id="print" *ngIf="!obteniendoDatos && (datosGeneralesActa != null && datosFacturacionActa != null)">
    <div class="row">
        <div class="col-md-12">
            <div class="p-2 col-md-4">
                <img id="logo-ypf" src="/assets/images/logoypf.png" />
            </div>
            <div class="col-md-12" style="text-align: center;">
                <h3>Acta</h3>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <br/>

            En {{getNombreCiudad()}} a los {{this.calculaUltimoDiaMes()}} días del mes {{mes}} del 
            {{anio}}, siendo las 11:59 p. m. del día de la fecha, 
            se procede a la confección de la presente Acta con motivo de la medición de productos existentes en la 
            Aeroplanta {{nombreAeroplanta}}, de acuerdo con el siguiente detalle:

            <br/><br/>

            <div>
                <table>
                    <tr *ngFor="let p of datosGeneralesActa">
                        <td class="primera">{{p.codigoProducto}} - {{p.nombreProducto}}</td>
                        <td class="segunda" align="right">{{p.cantidad | number: '1.0-0'}}</td>
                        <td class="tercera">{{getDescripcionUnidadMedida(p.unidadMedida)}}</td> 
                    </tr>
                </table>    
            </div>

            <br/><br/>

            A continuación se indica la numeración de los vales con que se inician las operaciones del mes {{calcularNumeroMes(this.mes + 1)}} 
            de {{calcularProximoCierre(this.mes + 1, this.anio)}} para:

            <br/><br/>

            <div>
                <table>
                    <tr *ngFor="let c of datosFacturacionActa">
                        <td class="primera">{{getDescripcionComprobante(c)}}</td>
                        <td class="segunda">{{getNumeroCompletoComprobante(c)}} </td>
                    </tr>
                </table>     
            </div>

            <br/><br/>
            
            Sin objeciones que formular, se firma un ejemplar de un mismo tenor y de común acuerdo.
        </div>
        Se genera este acta el {{fechaHoraActual.getDate()}}/{{calcularNumeroMes(fechaHoraActual.getMonth() + 1)}}/{{fechaHoraActual.getFullYear()}} {{fechaHoraActual | date: "hh:mm a"}}
    </div>
</div>

<button style="float: right; margin-right: 10em;" class="btn btn-outline-primary me-2" (click)="print()"><i class="fa fa-print" aria-hidden="true"></i>
    Imprimir</button>

