import { Component, OnInit, Input } from '@angular/core';
import { Emisor } from 'src/app/shared/models/facturacion/Emisor';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductoService } from 'src/app/abm/producto/producto.service';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { UrlApi } from 'src/app/shared/enums/urlApi';
import { ManagementService } from '../management.service';

@Component({
  selector: 'app-modal-certificado',
  templateUrl: './modal-certificado.component.html',
  styleUrls: ['./modal-certificado.component.css']
})
export class ModalCertificadoComponent implements OnInit {
  @Input() emisorSeleccionado: Emisor;
  constructor(public modal: NgbModal, private fb: UntypedFormBuilder, private managmentService: ManagementService) { }

  fileUpload = { status: '', message: '', filePath: '', length: 0 };
  userFileName: string;
  productoForm: UntypedFormGroup;
  selectedFile: boolean = false;
  error: string;
  guardandoExcel: boolean = false;

  ngOnInit() {
    this.productoForm = this.fb.group({
      name: [''],
      profile: [''],
      inputFile: ['', Validators.required]
    });
    this.userFileName = this.emisorSeleccionado.certificadoNombre;
  }

  onSelectedFile(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.productoForm.get('profile').setValue(file);
      this.productoForm.get('name').setValue(file.name);
      this.userFileName = file.name;
      this.selectedFile = true;
    }
  }

  cargarCertificado() {
    const formData = new FormData();
    formData.append('name', this.productoForm.get('name').value);
    formData.append('profile', this.productoForm.get('profile').value);
    this.guardandoExcel = true;
    this.managmentService.uploadCertificado(formData, UrlApi.precios, this.emisorSeleccionado.id)
      .subscribe(result => {
          this.guardandoExcel = false;
          this.modal.dismissAll();        
      },
        (err: ErrorModel) => { this.error = err.description; this.guardandoExcel = false; });
  }

}
