import { TipoComparativa } from "../../enums/TipoComparativa";

export class Comparativa{
    existenciaAnterior: number;
    entradas: number;
    salidas: number;
    existenciaFinal: number;
    codigoContenedor: string;
    nombreContenedor: string;
    codigoProducto: string;
    nombreProducto: string;
    existenciaReal: number;
    diferencia: number;
    tipoComparativa: TipoComparativa;
}