<ng-container *ngIf="!showForm">
  <nav class="navbar navbar-light bg-light navbar-expand-lg mb-3">
    <form class="form-inline flex-fill" id="formularioFiltros">
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <ul class="navbar-nav me-auto">
        
        <ng-container *ngIf="!obteniendoTanques && !obteniendoAbastecedoras">
          <li class="nav-item me-2">
            <button class="btn btn-outline-primary" type="button" (click)="agregarMedicion()">Agregar</button>
          </li>
          
          <li class="nav-item me-2">
            <button class="btn btn-outline-primary" type="button" (click)="agregarMedicionesMasivas()">Mediciones masivas</button>
          </li>

          <li class="nav-item me-2">
            <form [formGroup]="tanquesForm">
              <select id="tanqueFormControl" class="form-select" formControlName="tanqueFormControl"
                ngbTooltip="Seleccione el tanque a filtrar." (change)="this.buscarDesdeHasta()">
                <option [value]="null" [selected]="true">Seleccione una opción...</option>
                <option *ngFor="let t of tanques" [ngValue]="t">
                  ({{ t.codigo }}) - {{ t.nombre }}
                </option>
                <option *ngFor="let a of abastecedoras" [ngValue]="a">
                  ({{a.codigo}} - {{a.nombre}})
                </option>
              </select>
            </form>
          </li>
        </ng-container>

        <div *ngIf="obteniendoTanques || obteniendoAbastecedoras">
          <loading-text-spinner class="me-4" message="Obteniendo tanques y abastecedoras..." color="primary">
          </loading-text-spinner>
        </div>

        <!-- desde -->
        <li class="nav-item me-2">
          <div class="input-group">

            <input type="date" [ngModel]="modelDesde" class="fechaFormControlDesdeHasta" placeholder="aaaa-mm-dd"
              (ngModelChange)="modelDesde = $event" name="dt" ngbDatepicker #d="ngbDatepicker" value="modelDesde"
              ngbTooltip="Seleccione una fecha de inicio" class="form-control form-control-rounded"
              #searchDate readonly>

            <div class="input-group-append">
              <button id="botonDesde" type="button" class="input-group-btn btn btn-secondary" (click)="d.toggle()">
                <i class="fa fa-calendar" aria-hidden="true"></i></button>
            </div>
          </div>
        </li>

        <!-- hasta -->
        <li class="nav-item">
          <div class="input-group">
            <input type="date" [ngModel]="modelHasta" class="fechaFormControlDesdeHasta" placeholder="aaaa-mm-dd"
              (ngModelChange)="modelHasta = $event" name="dt" ngbDatepicker #h="ngbDatepicker" value="modelHasta"
              ngbTooltip="Seleccione una fecha de fin" class="form-control form-control-rounded"
              #searchDate readonly>

            <div class="input-group-append">
              <button id="botonHasta" type="button" class="input-group-btn btn btn-secondary" (click)="h.toggle()"><i
                  class="fa fa-calendar" aria-hidden="true"></i></button>
            </div>
          </div>
        </li>

        <li class="nav-item">
          <button (click)="this.buscarDesdeHasta()" class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2"
            type="submit"><i class="fa fa-search" aria-hidden="true"></i>
          </button>
        </li>
      </ul>
    </form>
  </nav>

  <ngb-alert type="danger" [dismissible]="false" *ngIf="fechaDesdeMayorQueHasta()==true">
    La fecha inicial debe ser anterior a la fecha final.
  </ngb-alert>

  <ngb-alert [dismissible]="false"
    *ngIf="fechaDesdeMayorQueHasta()==false && !this.obteniendoMediciones && !obteniendoTanques && mediciones!=null && mediciones.length==0">
    No existen mediciones para la busqueda seleccionada
  </ngb-alert>

  <form id="formularioTablaMovimientos">

    <div id="tablaMovimientos">

      <table *ngIf="!this.obteniendoMediciones && this.mediciones!=null && mediciones.length>0"
        id="tablaMovimientosDesdeHasta" class="table table-sm table-striped table-hover text-center">
        <thead>
          <tr>
            <th scope="col">Tanque</th>
            <th scope="col">Fecha / Hora </th>
            <th scope="col">Nivel varillado</th>
            <th scope="col">Cant. 15°</th>
            <th scope="col">Cant. natural</th>
            <th scope="col">Temp.</th>
            <th scope="col">Dens.</th>
            <th scope="col">Nivel agua</th>
            <th scope="col">Operador</th>
          </tr>
        </thead>
        <tbody class="text-center" *ngIf="this.mediciones!=null">
          <tr *ngFor="let medicion of mediciones; let i=index">
            <td>{{medicion.nombreContenedor}}</td>
            <td>{{medicion.fechaHora | date: "d'/'MM'/'y' 'hh:mm a" }}</td>
            <td>{{medicion.varillado}}</td>
            <td>{{medicion.nivel}}</td>
            <td>{{medicion.nivelNatural}}</td>
            <td>{{medicion.temperatura}}</td>
            <td>{{medicion.densidad}}</td>
            <td>{{medicion.nivelAgua}}</td>
            <td>{{medicion.username}}</td>
          </tr>
        </tbody>
      </table>

      <div class="spinner-container" *ngIf="this.obteniendoMediciones">
        <ngx-spinner name="spinnerLista" bdOpacity=0.3bd
          Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
          <p style="color: white"> O b t e n i e n d o M e d i c i o n e s </p>
        </ngx-spinner>
      </div>
    </div>
  </form>
</ng-container>

<div [@fadeIn] *ngIf="showForm">
  <app-mediciones-masivas [tanques]="tanques" [abastecedoras]="abastecedoras" (cancelado)="volver()"></app-mediciones-masivas>
</div>