export class CuentaCliente {
  razonSocial: string;
  numeroDocumento: string;
  tipoDocumento: number;
  ctA_SGC: string;
  tipoCliente: number;
  tipoCuenta: number;
  permiteRanchos: boolean;
  esTrader: boolean;
  ubicacion: string = "";
  esNuevo: boolean = false;
  activo: boolean = true;
  soloPAD: boolean = false;
  limitado: boolean;
  email: string;
}







