import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { HttpErrorHandler } from '../http-error-handler.service';
import { AuthService } from '../core/authentication/auth.service';
import { Injectable } from '@angular/core';
import { GrupoDeTanques } from '../shared/models/stock/GrupoDeTanques';

@Injectable({
    providedIn: 'root'
  })
export class GrupoDeTanquesService{
  [x: string]: any;

    stockbaseUrl = environment.apiServer.stockBaseUrl;
    private aeroplantasUrl = this.stockbaseUrl + '/AeroplantasV1';
    private contenedoresUrl = this.stockbaseUrl + '/ContenedoresV1';

    constructor(private httpClient: HttpClient, private httpErrorHandler: HttpErrorHandler, private authservice: AuthService){}

    getGrupoDeTanquesPorAeroplanta(codigoAeroplanta: string): Observable<GrupoDeTanques[]>{
      return this.httpClient.get<GrupoDeTanques[]>(this.aeroplantasUrl + "/" + codigoAeroplanta + "/GruposTanques")
      .pipe(
        tap(_ => console.log('getGrupoDeTanquesPorAeroplanta')),
          catchError(this.handleError)
        );
    }

    getGrupoDeTanquesyTanquesIndividualesPorAeroplanta(codigoAeroplanta: string): Observable<GrupoDeTanques[]>{
      return this.httpClient.get<GrupoDeTanques[]>(this.aeroplantasUrl + "/" + codigoAeroplanta + "/Tanques")
      .pipe(
        tap(_ => console.log('getGrupoDeTanques+TanquesIndividualesPorAeroplanta')),
          catchError(this.handleError)
        );
    }



    crearGrupoDeTanques(codigoAeroplanta: string, grupo: GrupoDeTanques): Observable<GrupoDeTanques>{
      return this.httpClient.post<GrupoDeTanques>(this.contenedoresUrl + '/Tanques/' + codigoAeroplanta + "/Grupo", grupo)
      .pipe(
        tap(_ => console.log('Creacion de grupo')),
        catchError(this.handleError)
        );
    }

    editarGrupoDeTanques(codigoAeroplanta: string, grupo: GrupoDeTanques): Observable<GrupoDeTanques>{
      return this.httpClient.put<GrupoDeTanques>(this.contenedoresUrl + '/Tanques/' + grupo.codigo + "/Grupo?codigoAeroplanta=" + codigoAeroplanta, grupo)
      .pipe(
        tap(_ => console.log('Edición de grupo')),
        catchError(this.handleError)
        );
    }

    eliminarGrupo(grupo: GrupoDeTanques)
    {
      return this.httpClient.delete<GrupoDeTanques>(this.contenedoresUrl + '/Tanques/' + grupo.codigo+"/Grupo")
      .pipe(
        tap(_ => console.log('Eliminación de grupo')),
        catchError(this.handleError)
        );
    }

    asociarTanquesGrupos(grupos: GrupoDeTanques[], codigoAeroplanta: string): Observable<GrupoDeTanques[]>{
      return this.httpClient.put<GrupoDeTanques[]>(this.contenedoresUrl + "/Tanques/" + codigoAeroplanta + "/AsociarGrupo_Tanques", grupos)
      .pipe(
        tap(_ => console.log('Edición de grupo')),
        catchError(this.handleError)
        );
    }
  }
