<ngx-spinner
  bdColor="rgba(19,123,198,0.7)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">
    <br/>
    Aguarde a que se procese el aerovale y sera redirigido automáticamente.
    <br/>
    No salga de esta pagina.
  </p>
</ngx-spinner>

<div class="modal-header bg-light">
  <h4 class="modal-title">Reemplazar Aerovale:  {{ this.obtenerNumeracionAerovale() }} </h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="this.modal.dismiss()">
      
  </button>
</div>

<div class="modal-body">
  <div *ngIf="cargandoInformacion" class="mb-3" style="height: 10cm;">
    <ngx-spinner name="spinnerGrafico" [ngStyle]="{'height': '400px', 'z-index':'0'}" bdOpacity=0.3bd
      Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
      <p style="color: white">C a r g a n d o . . . </p>
    </ngx-spinner>
  </div>
  <div *ngIf="!this.cargandoInformacion">
    <form [formGroup]="aerovalesForm">
      <div class="row">
        <div class="mb-3 row col-md-12">        
          <div class="col-md-4">
            <img id="logo-ypf" src="../../../assets/images/logoypf.png" />
          </div>
          <div class="col-md-4 text-center">
            <h3>Aerovale</h3>
            <h6>{{ getTipoAerovale() }}</h6>
          </div>        
        </div>
      </div>
      
      <br>
  
      <div class="accordion" id="accordionExample">
        <div class="card">
          <div class="card-header" id="headingOne">
            <h2 class="mb-0 text-center">
              <button id="botones" class="btn btn-block text-center" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                Cliente y Matricula
              </button>
            </h2>
          </div>
      
          <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
            <div class="card-body">
              
              <!-- Clientes -->
  
              <div class="mb-3 row">
                <label for="clienteFormControl" class="col-sm-4 col-form-label">Cliente:</label>
                <div class="col-sm-8">
                  <div class="input-group">
                    <input id="clienteFormControl" type="text" class="form-control" formControlName="clienteFormControl"                    
                      [inputFormatter]="formatterCliente" [resultFormatter]="formatterCliente"
                      title="Campo obligatorio, debe seleccionar o ingresar un cliente." (change)="confirmCliente()"
                      [ngbTypeahead]="searchCliente" (selectItem)="selectCliente($event)" (keydown)="typeaheadKeydown($event)" 
                      #typeaheadInstance="ngbTypeahead"/>
                    <div class="input-group-append">
                      <button type="button" class="input-group-btn btn btn-secondary"
                        (click)="nuevoCliente(clienteFormControl.value)" tabindex="-1">
                        <i class="fa fa-plus-square" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                  <loading-text-spinner *ngIf="cargandoClientes" [error]="errorClientes" message="Obteniendo clientes..."
                    color="primary">
                  </loading-text-spinner>
          
                  <div class="alert alert-danger"
                    *ngIf="clienteFormControl.invalid && clienteFormControl.errors.required && (clienteFormControl.dirty || clienteFormControl.touched)">
                    Requerido
                  </div>
                </div>
              </div>
  
              <!-- Tipo Pago -->
  
              <div class="mb-3 row" *ngIf="this.clienteFormControl.value != null">
                <label for="tipoPagoFormControl" class="col-sm-4 col-form-label">Tipo de pago:</label>
                <div class="col-sm-6">
                  <select id="tipoPagoFormControl" type="text" class="form-select" name="tipoPagoFormControl"
                    formControlName="tipoPagoFormControl" title="Seleccione un tipo de movimiento. Campo requerido.">
                    <option id="optionTipoPago" *ngFor="let tipo of obtenerTipoDePago(this.clienteFormControl.value.tipoCuenta)"
                      [ngValue]="TipoPago[tipo]">
                      {{ tipo }}
                    </option>
                  </select>
                </div>
              </div>
  
              <!-- Vehículo -->
  
              <div class="mb-3 row">
                <label for="vehiculoFormControl" class="col-sm-4 col-form-label">Vehiculo:</label>
                <div class="col-sm-4">
                  <div class="input-group">
                    <ng-template #aeronaveRt let-r="result" let-t="term">
                      <ngb-highlight [result]="r.matricula" [term]="t"></ngb-highlight>
                    </ng-template>
                    <input id="vehiculoFormControl" type="text" class="form-control" formControlName="vehiculoFormControl"
                      [resultTemplate]="aeronaveRt" [inputFormatter]="formatterVehiculo" maxlength="10"
                      title="Campo obligatorio, debe seleccionar una aeronave." [ngbTypeahead]="searchVehiculo"
                      (change)="changeVehiculo()" (selectItem)="controlVehiculoProducto()"/>
                    <div class="input-group-append">
                      <button type="button" class="input-group-btn btn btn-secondary" (click)="nuevoVehiculo(vehiculoFormControl)"
                        tabindex="-1">
                        <i class="fa fa-plus-square" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                  <loading-text-spinner *ngIf="cargandoVehiculosDeCliente" message="Obteniendo vehiculos del cliente..." [error]="errorVehiculos || errorVehiculosDeCliente"
                    color="primary">
                  </loading-text-spinner>
                  <div class="alert alert-danger"
                    *ngIf="vehiculoFormControl.invalid && vehiculoFormControl.errors.required && (vehiculoFormControl.dirty || vehiculoFormControl.touched)">
                    Requerido
                  </div>
                  <div class="alert alert-warning" *ngIf="this.combustibleNoCoincide">
                    La abastecedora y el vehiculo tienen distinto producto.
                  </div>
                </div>
              </div>
  
              <!-- Num Vuelo -->
  
              <div class="mb-3 row" *ngIf="this.aerovaleReemplaza.tipoAerovale == TipoAerovale.Aeroespecialidad">
                <label for="numeroVueloFormControl" class="col-sm-4 col-form-label">
                  Vuelo:
                </label>
                <div class="col-sm-4">
                  <input id="numeroVueloFormControl" type="text" class="form-control mb-2 me-sm-2"  formControlName="numeroVueloFormControl" />
                </div>                
              </div>

            </div>
          </div>
        </div>
  
        <div class="card">
          <div class="card-header" id="headingTwo">
            <h2 class="mb-0 text-center">
              <button id="botones" class="btn btn-block text-center collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                {{ this.aerovaleReemplaza.aeroespecialidades.length>0 ? 'Aeroespecialidades' : 'Despachos' }}
              </button>
            </h2>
          </div>
          <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
            <div class="card-body">
              
              <!-- Operador -->
  
              <div class="mb-3 row">
                <label for="operadorFormControl" class="col-sm-4 col-form-label">Operadores:</label>
                <div class="col-sm-6">
                  <input id="operadorFormControl" type="text" class="form-control" formControlName="operadorFormControl"
                    [inputFormatter]="formatterOperador" [resultFormatter]="formatterOperador"
                    title="Campo obligatorio, debe seleccionar o ingresar un operador." (selectItem)="selectOperador($event)"
                    [ngbTypeahead]="searchOperador" />
          
                  <loading-text-spinner *ngIf="cargandoOperadores" [error]="errorOperadores" message="Obteniendo operadores..."
                    color="primary">
                  </loading-text-spinner>
          
                  <div class="alert alert-danger"
                    *ngIf="operadorFormControl.invalid && operadorFormControl.errors.required && (operadorFormControl.dirty || operadorFormControl.touched)">
                    Requerido
                  </div>
                </div>
              </div>
  
              <!-- COMBUSTIBLES -->
              <ng-container *ngIf="this.aerovaleReemplaza.tipoAerovale != TipoAerovale.Aeroespecialidad">
  
                <!-- Abastecedora -->
  
                <div class="mb-3 row">
                  <label for="abastecedoraFormControl" class="col-sm-4 col-form-label">
                    Abastecedora:</label>
                  <div class="col-sm-6">
                    <select id="abastecedoraFormControl" class="form-select" type="number"
                      ngbTooltip="Seleccione una abastecedora." formControlName="abastecedoraFormControl"
                      (change)="buscarTanques()">
                      <option *ngFor="let abastecedora of abastecedoras" [ngValue]="abastecedora">
                        {{ abastecedora.nombre }}
                      </option>
                    </select>
                    <loading-text-spinner *ngIf="cargandoAbastecedoras" [error]="errorClientes"
                      message="Obteniendo abastecedoras..." color="primary">
                    </loading-text-spinner>
                    <div class="alert alert-danger"
                      *ngIf="(this.abastecedoraFormControl.invalid && this.abastecedoraFormControl.errors.required && (this.abastecedoraFormControl.dirty || this.abastecedoraFormControl.touched))">
                      Debe seleccionar una opcion de abastecedora
                    </div>
                  </div>
                </div>
  
                <!-- Producto -->
            
                <div class="mb-3 row" *ngIf="mostrarAbastecedora">
                  <label for="productoFormControl" class="col-sm-4 col-form-label">Producto:</label>
                  <div class="col-sm-6">
                    <input class="form-control" formControlName="productoFormControl" readonly>
                  </div>
                </div>
            
                <!-- Tanque -->
  
                <div class="mb-3 row"
                  *ngIf="mostrarAbastecedora && this.abastecedoraFormControl.value.tipoExpendedora == TipoExpendedora.SURTIDOR">
                  <label for="tanquesAsociadosFormControl" class="col-sm-4 col-form-label">Tanque de extracción:</label>
                  <div class="col-md-6">
                    <select id="tanquesAsociadosFormControl" class="form-select" title="Seleccione un tanque."
                      *ngIf="!obteniendoTanquesSurtidor && surtidores != null" formControlName="tanquesAsociadosFormControl">
                      <option *ngFor="let tanques of surtidores.tanques" [ngValue]="tanques.codigo">{{ tanques.nombre }}
                      </option>
                    </select>
                    <loading-text-spinner *ngIf="obteniendoTanquesSurtidor" [error]="errorOperadores"
                      message="Obteniendo tanques asociados..." color="primary">
                    </loading-text-spinner>
                    <div class="alert alert-warning" *ngIf="!obteniendoTanquesSurtidor && surtidores != null && surtidores.tanques.length == 0">
                      Se debe asignar al menos un tanque al surtidor para poder realizar el despacho. Avise a su supervisor.
                    </div>
                    <div class="alert alert-danger" *ngIf="aerovalesForm.invalid && this.aerovalesForm.hasError('noSeleccionoTanque')">
                      Debe seleccionar un tanque.
                    </div>
                  </div>
                </div>
            
                <!--Pico -->
  
                <div class="mb-3 row" *ngIf="mostrarAbastecedora">
                  <label for="picosAsociadosFormControl" class="col-sm-4 col-form-label">Aforador utilizado:</label>
                  <div class="col-md-6">
                    <select id="picosAsociadosFormControl" class="form-select" title="Seleccione un pico." formControlName="picosAsociadosFormControl">
                      <option *ngFor="let pico of this.abastecedoraFormControl.value.picos" [ngValue]="pico.id">{{ pico.nombre }}
                      </option>
                    </select>
                    <div class="alert alert-warning" *ngIf="this.abastecedoraFormControl.value.picos.length == 0">
                      Se debe asignar al menos un pico a la abastecedora para poder realizar el despacho. Avise a su supervisor.
                    </div>
                    <div class="alert alert-danger" *ngIf="aerovalesForm.invalid && this.aerovalesForm.hasError('noSeleccionoPico')">
                      Debe seleccionar un pico.
                    </div>
                  </div>
                </div>
  
              </ng-container>
  
              <!-- AEROESPECIALIDAD -->
  
              <ng-container *ngIf="this.aerovaleReemplaza.tipoAerovale == TipoAerovale.Aeroespecialidad">
              
                <!-- Combo Aeroespecialidad -->
  
                <div class="mb-3 row">
                  <label for="aeroespecialidadesSeleccionablesFormControl" class="col-sm-4 col-form-label">
                    *Aeroespecialidades:
                  </label>
                  <div class="col-sm-6">
                    <select id="aeroespecialidadesSeleccionablesFormControl" class="form-select" type="number"
                      ngbTooltip="Seleccione aeroespecialidades para agregar." formControlName="aeroespecialidadesSeleccionablesFormControl"
                      (change)="this.onAeroespecialidadSeleccionada()">
                      <option [ngValue]="null">Lista de aeroespecialidades</option>
                      <option *ngFor="let aeroespecialidad of aeroespecialidadesSeleccionables" [ngValue]="aeroespecialidad">
                        {{ aeroespecialidad.codigoProducto + ' | ' + aeroespecialidad.nombreProducto }}
                      </option>
                    </select>
                    <loading-text-spinner *ngIf="cargandoAeroespecialidades" [error]="errorAeroespecialidades" message="Obteniendo aeroespecialidades..."color="primary">
                    </loading-text-spinner>
                  </div>
                </div>
                  
                <div class="mb-3 row" *ngIf="!cargandoAeroespecialidades">
                  <label *ngIf="(this.aeroespecialidadesSeleccionadasFormControlArray.controls.length > 0)" class="col-sm-5 col-form-label">
                    <b>Aeroespecialidad seleccionadas</b>
                  </label>
                  <div class="col-sm-10" style="border: 1px solid #ccc; padding: 1em;" formArrayName="aeroespecialidadesSeleccionadasFormControlArray">
                    <div *ngFor="let aeroespecialidad of aeroespecialidadesSeleccionadasFormControlArray.controls; let i=index" style="margin-bottom: 1em;">
                      <div [formGroupName]="i">
                        <input type="text" class="label-aeroespecialidad-item" formControlName="nombreProducto" readonly>
                        <input type="hidden" formControlName="codigoProducto" >
                        &nbsp; &nbsp; Cantidad:
                        <input type="text" formControlName="cantidad" (keypress)="onlyNumberKey($event)" style="width: 3em; text-align: right; padding-right: 1em;">
                        <button type="button" (click)="onAeroespecialidadEliminada(i)" class="btn btn-danger ms-3 px-1"><i class="fa fa-trash"></i> Quitar</button>
          
                        <div class="alert alert-danger"
                          *ngIf="!aeroespecialidadesSeleccionadasFormControlArray.controls[i].valid">
                            Debe cargar una cantidad.
                        </div>
                      </div>
                    </div>
                    <div class="alert alert-danger"
                      *ngIf="(this.aeroespecialidadesSeleccionadasFormControlArray.controls.length == 0 && this.aerovaleReemplaza.tipoAerovale == TipoAerovale.Aeroespecialidad)">
                        Debe agregar al menos una aeroespecialidad.
                    </div>
                  </div>
                </div>      
  
              </ng-container>
  
            </div>
          </div>
        </div>
  
        <div class="card">
          <div class="card-header" id="headingThree">
            <h2 class="mb-0 text-center">
              <button id="botones" class="btn btn-block text-center collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                Valores
              </button>
            </h2>
          </div>
          <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
            <div class="card-body">
            
              <!-- leandro: Calendario no abre completo -->
              <div class="mb-3 row">
                <label for="fechaLlegadaFormControl" class="col-sm-4 col-form-label">Fecha y hora de despacho:</label>
                <div #fechaHora class="col-sm-6">
                  <div class="input-group">
                    <input type="date" class="form-control" formControlName="fechaLlegadaFormControl" placeholder="aaaa-mm-dd"
                      name="dp" title="Campo obligatorio." [(ngModel)]="modelFechaLlegada" container="body" ngbDatepicker #l="ngbDatepicker"
                      readonly/>
                    <div class="input-group-append">
                      <button type="button" class="input-group-btn btn btn-secondary" (click)="l.toggle()">
                        <i class="fa fa-calendar" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                  <ngb-timepicker [(ngModel)]="modelHoraLlegada" [meridian]="true" formControlName="horaLlegadaFormControl"
                    [readonlyInputs]="true"></ngb-timepicker>
                  <div class="alert alert-danger" *ngIf="aerovalesForm.invalid && this.aerovalesForm.hasError('fechaLlegadaMayorAhora')">
                    Esta cargando una fecha de llegada en el futuro.
                  </div>
                </div>
              </div>
  
              <!-- Combustible / Aerocombustible / Alije -->
  
              <ng-container *ngIf="this.aerovaleReemplaza.tipoAerovale != TipoAerovale.Aeroespecialidad">
                
                <!-- Aforador Inicial -->
  
                <div class="mb-3 row">
                  <label for="aforadorInicialFormControl" class="col-sm-4 col-form-label">Aforador Inicial</label>
                  <div class="col-sm-6">
                    <input id="aforadorInicialFormControl" type="number" min="0" (keypress)="this.onlyNumberKey($event)" 
                      class="form-control" title="Campo obligatorio, debe ingresar aforador inicial." 
                      formControlName="aforadorInicialFormControl" placeholder="####" (change)="obtenerCantidadDespachada()" />
                  </div>
                  <div class="alert alert-danger" *ngIf="this.aforadorInicialFormControl.invalid && this.aforadorInicialFormControl.errors.required && (this.aforadorInicialFormControl.dirty || this.aforadorInicialFormControl.touched)">
                    Debe completar el campo aforador inicial.
                  </div>
                </div>
            
                <!-- Aforador Final -->
  
                <div class="mb-3 row">
                  <label for="aforadorFinalFormControl" class="col-sm-4 col-form-label">Aforador Final:</label>
                  <div class="col-sm-6">
                    <input id="aforadorFinalFormControl" type="number" min="0" (keypress)="this.onlyNumberKey($event)" 
                      class="form-control" title="Campo obligatorio, debe ingresar aforador final." 
                      formControlName="aforadorFinalFormControl" placeholder="####" (change)="obtenerCantidadDespachada()" />
                  </div>
                  <div class="alert alert-danger" *ngIf="this.aforadorFinalFormControl.invalid && this.aforadorFinalFormControl.errors.required && (this.aforadorFinalFormControl.dirty || this.aforadorFinalFormControl.touched)">
                    Debe completar el campo aforador final.
                  </div>
                  <div class="alert alert-danger" *ngIf="aerovalesForm.invalid && this.aerovalesForm.hasError('aforadorInicialMayorAforadorFinal') && (aforadorInicialFormControl.dirty || aforadorInicialFormControl.touched) && (aforadorFinalFormControl.dirty || aforadorFinalFormControl.touched)">
                    El aforador final debe ser mayor que el aforador inicial.
                  </div>
                </div>
            
                <!-- Temp -->
  
                <div class="mb-3 row">
                  <label for="temperaturaFormControl" class="col-sm-4 col-form-label">Temperatura</label>
                  <div class="col-sm-6">
                    <input title="Campo obligatorio." id="temperaturaFormControl" type="number" class="form-control" formControlName="temperaturaFormControl" 
                      placeholder="grados" (blur)="this.litrosA15Grados()" />
                  </div>
                  <div class="alert alert-danger"
                    *ngIf="(this.temperaturaFormControl.invalid && this.temperaturaFormControl.errors.required && (this.temperaturaFormControl.dirty || this.temperaturaFormControl.touched))">
                    Requerido
                  </div>
                  <div class="alert alert-danger"
                    *ngIf="temperaturaFormControl.invalid && (temperaturaFormControl.errors.temperaturaFueraDeRango) && (temperaturaFormControl.dirty || temperaturaFormControl.touched)">
                    La temperatura esta fuera de rango permitido.
                  </div>
                </div>
            
                <!-- Densidad -->
  
                <div class="mb-3 row">
                  <label for="densidadFormControl" class="col-sm-4 col-form-label">Densidad</label>
                  <div class="col-sm-6">
                    <input id="densidadFormControl" type="number" class="form-control" formControlName="densidadFormControl" 
                      (keypress)="formatDensidad($event)" (blur)="this.litrosA15Grados()"/>
                  </div>
                  <div class="alert alert-danger"
                    *ngIf="(this.densidadFormControl.invalid && this.densidadFormControl.errors.required && (this.densidadFormControl.dirty || this.densidadFormControl.touched))">
                    Requerido
                  </div>  
                  <div class="alert alert-danger"
                    *ngIf="(this.densidadFormControl.dirty || this.densidadFormControl.touched) && !this.validarDensidad(this.densidadFormControl.value)">
                    La densidad debe estar dentro del rango del producto. [{{this.densidadMin}} - {{this.densidadMax}}]
                  </div>                  
                </div>
            
                <!-- Vol. despachado -->
  
                <div class="mb-3 row">
                  <label for="cantidadDespachadaFormControl" class="col-sm-4 col-form-label">Volumen despachado</label>
                  <div class="col-sm-6">
                    <input id="cantidadDespachadaFormControl" type="text" class="form-control" formControlName="cantidadDespachadaFormControl" placeholder="####"  readonly/>
                  </div>
                </div>
            
                <!-- Vol. 15º -->
  
                <div class="mb-3 row">
                  <label for="volumen15GradosFormControl" class="col-sm-4 col-form-label">Volumen 15°</label>
                  <div class="col-sm-6">
                    <input id="volumen15GradosFormControl" type="text" class="form-control" formControlName="volumen15GradosFormControl" placeholder="####" readonly/>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      
      <div class="card-footer d-flex justify-content-center">
        <span class="border">
          <button type="button" (click)="reemplazarAerovale(this.aerovaleReemplaza.tipoAerovale)" class="btn btn-primary" [disabled]="guardandoAerovale">
            <span *ngIf="!guardandoAerovale">Guardar</span>          
            <div *ngIf="guardandoAerovale" class="d-flex align-items-center">
              <div class="spinner-border text-light spinner-border-sm" role="status"></div>
              <span>Guardando...</span>
            </div>
          </button>
        </span>
      </div>
    </form>  
  </div>  
</div>