import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';

@Component({
  selector: 'app-cierre-turno-index',
  templateUrl: './cierre-turno-index.component.html',
  styleUrls: ['./cierre-turno-index.component.css']
})
export class CierreTurnoIndexComponent implements OnInit {

  TipoUsuario = TipoUsuario;

  // etapa1:boolean;
  // etapa2:boolean;
  // etapa3:boolean;
  constructor(public authService: AuthService) { }

  ngOnInit() {
    // this.etapa1=true;
    // this.etapa2=false;
    // this.etapa3=false;
  }


}
