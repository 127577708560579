import { PeriodosFacturacion } from './PeriodosFacturacion';
import { PlazoPagoCliente } from './PlazoPagoCliente';

export class Cuenta{
    id: string;
    activo: boolean;
    moneda: number;
    descripcion: string;
    periodoFacturacion: PeriodosFacturacion;
    ctA_SGC: string;
    tipoCuenta: number;
    email: string;
    notificaPorEmail: boolean;
    tipoEntregaFactura: number;
    agrupacionRemitos: number;
    esNueva: boolean;
    ModificacionPendiente: boolean;
    condicionPago: PlazoPagoCliente;
    soloPAD: boolean; //Booleano para cuenta solo PAD
    ctaSap: string;
    datosAdicionalesFacturacion: string;
    ctA_CTC: string;
}