import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MessageService } from 'src/app/shell/message.service';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Rubro } from 'src/app/shared/models/abm/Rubro';
import { TipoNeto } from 'src/app/shared/enums/tipoNeto';
import { RubroService } from '../rubro.service';
import { RubrosListaComponent } from '../rubros-lista/rubros-lista.component';

@Component({
  selector: 'app-nuevo-rubro',
  templateUrl: './nuevo-rubro.component.html',
  styleUrls: ['./nuevo-rubro.component.css']
})
export class NuevoRubroComponent implements OnInit {
 
  nuevoRubroForm = new UntypedFormGroup({
    codigoFormControl: new UntypedFormControl(null),
    descripcionFormControl: new UntypedFormControl(null),
    combustibleFormControl: new UntypedFormControl(null),
    tipoNetoRubroFormControl: new UntypedFormControl(0),
  });

  activeModal: NgbModal;
  rubrosFiltrados: Rubro[];
  rubros: Rubro[];
  tipoNeto = TipoNeto;
  combustible = true; 
  aerocombustible = false;
  aeroespecialidad = false;
  servicios = false;
  constructor(activeModal: NgbModal,
    public modal: NgbActiveModal,
    private rubroService: RubroService,
    private messageService: MessageService,
    private listaDeRubros: RubrosListaComponent) {
      this.activeModal = activeModal;
  }

  ngOnInit() {
    this.getRubros();
  }

  getRubros() {
    this.rubroService.getRubros()
      .subscribe(result => {
        this.rubros = result;
      });
  }

  keysTiposNeto(): Array<string> {
    var keys = Object.keys(this.tipoNeto);
    keys = keys.slice(keys.length / 2);
    return keys;
  }

  get codigo() {
    return this.nuevoRubroForm.get('codigoFormControl');
  }

  get descripcion() {
    return this.nuevoRubroForm.get('descripcionFormControl');
  }

  get combustibles() {
    return this.nuevoRubroForm.get('combustibleFormControl');
  }

  get tipoNetoRubro() {
    return this.nuevoRubroForm.get('tipoNetoRubroFormControl');
  }


  crearRubro() {
    if (this.camposVacios()) {
      this.messageService.showErrorMessage("Por favor complete todos los campos");
    }
    else
    {
    let r: Rubro = new Rubro();
    r.codigoRubro = this.codigo.value;
    r.nombre = this.descripcion.value;
    r.impuestosRubro = [];
    r.activo = true;
    switch(this.combustibles.value){
      case "combustible":
        r.aeroespecialidad = false;
        r.aerocombustible = false;
        r.combustible = true;
        r.servicios = false;
        break;
      case "aeroespecialidad":
        r.aeroespecialidad = true;
        r.aerocombustible = false;
        r.combustible = false;
        r.servicios = false;
        break;
      case "aerocombustible":
        r.aeroespecialidad = false;
        r.aerocombustible = true;
        r.combustible = false;
        r.servicios = false;
        break;
      case "servicios":
        r.aeroespecialidad = false;
        r.aerocombustible = false;
        r.combustible = false;
        r.servicios = true;
        break;
    }
    r.tipoNeto = this.tipoNetoRubro.value;

    if (this.estaCodigoRubro()) {
      this.messageService.showErrorMessage("El codigo ingresado ya pertenece a otro rubro");
    }
    else {
      this.rubroService.agregarRubro(r)
        .subscribe(result => {
          console.log('Returning ' + r.codigoRubro);
          this.messageService.showSuccessMessage("Se ha agregado el rubro correctamente");
          this.listaDeRubros.ngOnInit();
          this.activeModal.dismissAll();
        }, () => {
        });
    }
  }
  }

  esCampoVacio(campo: any): boolean {
    return (campo == null || (campo != null && campo.length == 0));
  }

  camposVacios() {

    if(this.esCampoVacio(this.codigo.value)) return true;
    if(this.esCampoVacio(this.descripcion.value)) return true;

    if( !this.combustibles.value){
      return true;
    }
  }

  estaCodigoRubro(): boolean {
    let existe: boolean = false;
    for (let entry of this.rubros) {
      if (entry.codigoRubro === this.codigo.value) {
        existe = true;
        break;
      }
    }
    return existe;
  }

}
