import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { MessageService } from 'src/app/shell/message.service';

import { FormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbCalendar, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Usuario } from 'src/app/shared/models/despacho/Usuario';

import { TipoVenta } from 'src/app/shared/enums/tipoVenta';
import { CondicionVenta } from 'src/app/shared/enums/condicionVenta';
import { UsuarioService } from 'src/app/account/usuario.service';
import { TipoEstadoPrecio } from 'src/app/shared/enums/tipoEstadoPrecio';
import { TipoActivoPrecio } from 'src/app/shared/enums/tipoActivoPrecio';
import { ModalConfirmComponent } from 'src/app/shared/generic-modals/modal-confirm.component';

import { TipoObservacionExcelPrecios } from 'src/app/shared/enums/tipoObservacionExcelPrecios';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { TipoPrecioRetroactivo } from 'src/app/shared/enums/TipoPrecioRetroactivo';
import { formatDate } from '@angular/common';
import { PreciosService } from '../../precios.service';
import { LoteBonificacionRecargo } from 'src/app/shared/models/precios/LoteBonificacionRecargo';
import { BonificacionRecargo } from 'src/app/shared/models/precios/BonificacionyRecargo';
import { ModalSeleccionarUsuarioComponent } from '../../niveles-aprobacion/modal-seleccionar-usuario/modal-seleccionar-usuario.component';
import { EstadoPrecio } from 'src/app/shared/models/precios/EstadoPrecio';
import { EstadoCambio } from 'src/app/shared/enums/EstadoCambio';
import { TipoEsBonificacionRecargo } from 'src/app/shared/enums/TipoEsBonificacionRecargo';
import { TipoCantidadPaginado } from 'src/app/shared/enums/TipoCantidadPaginado';
import { tipoNivelUsuarioSeparados } from 'src/app/shared/enums/tipoNivelUsuarioSeparados';

@Component({
  selector: 'app-aprobar-byr-por-lote',
  templateUrl: './aprobar-byr-por-lote.component.html',
  styleUrls: ['./aprobar-byr-por-lote.component.scss']
})
export class AprobarByrPorLoteComponent implements OnInit {
  enumTipoObservacionExcelPrecios = TipoObservacionExcelPrecios;
  TipoUsuario = TipoUsuario;

  lotesByr: LoteBonificacionRecargo[];
  loteSeleccionado: LoteBonificacionRecargo;
  bonificacionesRecargos: BonificacionRecargo[];
  bonificacionesRecargosFiltradas: BonificacionRecargo[];

  noPuedeAprobar: boolean = false;
  fechaAhora: Date;
  fechaAhora2: string;

  error: string;
  cargandoLotes: boolean = true;
  cargandoByrDeLote: boolean = false;
  mensajeAccesoInsuficiente: string = null;

  searchForm = new UntypedFormGroup({
    busquedaFormControl: new UntypedFormControl(''),
  })

  page = 1;
  pageSize = TipoCantidadPaginado.NORMAL;
  collectionSize = 0;
  actualizandoPrecios: boolean = false;
  errorPrecios: boolean = false;

  constructor(
    private preciosService: PreciosService,
    private spinner: NgxSpinnerService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private usuarioService: UsuarioService,
    private authService: AuthService,
    private calendar: NgbCalendar,
  ) {

  }

  ngOnInit() {
    this.fechaAhora = new Date();
    this.getLotes();
  }

  getLotes() {
    this.loteSeleccionado = null;
    this.bonificacionesRecargos = [];
    this.bonificacionesRecargosFiltradas = [];
    this.cargandoLotes = true;
    this.spinner.show("spinnerLotes");

    this.preciosService.getLotesBonificacionesRecargo(TipoEstadoPrecio.Pendiente)
      .subscribe(result => {
       
        this.lotesByr = result;
        this.lotesByr.sort((a , b) => a.numeroLote > b.numeroLote ? 1 : -1) ;

        this.lotesByr.forEach(l => {
          if(l.responsable && l.responsable.indexOf(',') > -1) 
            l.responsable = JSON.parse(l.responsable.replace(/'/g,'"')).NombresCompletos;
          }
        );

        this.spinner.hide('spinnerLotes');
        this.cargandoLotes = false;
      },
        (err: ErrorModel) => {
          this.error = err.description + ' ' + err.message;
          this.spinner.hide('spinnerLotes');
          this.cargandoLotes = false;
          this.errorPrecios = true;
        });
  }

  mostrarDetalleLote(index) {
    this.mensajeAccesoInsuficiente = null;
    this.loteSeleccionado = this.lotesByr[index];

    if (this.usuarioPuedeAccederLote(this.loteSeleccionado)) {
      this.page = 1;
      this.getBonificacionesRecargoPorLote(this.page, this.pageSize, this.loteSeleccionado.id);
    } else {
      this.loteSeleccionado = null;
      this.bonificacionesRecargos = [];
    }
  }

  formatDate(year, month, day, hour, minute): string {
    return year + '-' + month.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + '-' + day.toLocaleString(undefined, { minimumIntegerDigits: 2 })
      + 'T' + hour.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + minute.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':00';
  }

  formatFullDate(year, month, day, hour, minute, seconds): string {
    return year + '-' + month.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + '-' + day.toLocaleString(undefined, { minimumIntegerDigits: 2 })
      + 'T' + hour.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + minute.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + seconds.toLocaleString(undefined, { minimumIntegerDigits: 2 });
  }

  getBonificacionesRecargoPorLote(page: number, size: number, loteId, actualizacion?: boolean) {
    console.log("el id del lote es " + loteId);    

    if (actualizacion){
      this.cargandoByrDeLote = false;
      this.actualizandoPrecios = true;
    }else{
      this.cargandoByrDeLote = true;
    }
      
    this.preciosService.getBonificacionesRecargoPorLotePaginados(page, size, loteId)
      .subscribe(pageList => {
        if (actualizacion){
          this.actualizandoPrecios = false;
        }else{
          this.cargandoByrDeLote = false;
        }

        this.page = pageList.pageNumber;
        this.pageSize = pageList.pageSize;
        this.collectionSize = pageList.totalItemCount;
        this.bonificacionesRecargos = pageList.items;

        this.bonificacionesRecargos.forEach(b => {
          if(b.solicitadoPor && b.solicitadoPor.indexOf(',') > -1) 
            b.solicitadoPor = JSON.parse(b.solicitadoPor.replace(/'/g,'"')).NombresCompletos;
          }
        );
       
        this.filtrarByr();
        this.cargandoByrDeLote = false;
      },
        (err: ErrorModel) => {
          this.bonificacionesRecargos = [];
          this.error = err.description;
          if (actualizacion){
            this.actualizandoPrecios = false;
          }else{
            this.cargandoByrDeLote = false;
          }
        });
  }

  filtrarByr() {
    this.bonificacionesRecargosFiltradas = this.bonificacionesRecargos.filter(a => {
      let agregar = true;
      if (this.busquedaFormControl.value) {

        if (a.nombreCliente != null) {
          agregar = a.nombreCliente && a.nombreCliente.toLocaleLowerCase().indexOf(this.busquedaFormControl.value.toLocaleLowerCase()) > -1;
        } else {
          agregar = false;
        }

        agregar = agregar || (a.codigoProducto && a.codigoProducto.toLocaleLowerCase().indexOf(this.busquedaFormControl.value.toLocaleLowerCase()) > -1);
        agregar = agregar || ((a.valor).toString() && (a.valor).toString().toLocaleLowerCase().indexOf(this.busquedaFormControl.value.toLocaleLowerCase()) > -1);
      }

      return agregar;
    });
  }

  usuarioPuedeAccederLote(lote: LoteBonificacionRecargo) {
    /*
    if(this.authService.hasRole(TipoUsuario.APROBADORBYR)){
      return true;
    }
    */

    var usuarioNiveles = this.preciosService.getNivelUsuarioLogueado();

    console.log("NATIVO: " + usuarioNiveles?.nivelNativo?.maximoPorcentajeAprobacion);
    console.log("MAX LOTE: " + Math.abs(lote.valorMaximo));

    if(usuarioNiveles?.nivelNativo != null)
    {
      if (usuarioNiveles && usuarioNiveles.nivelNativo.maximoPorcentajeAprobacion == 100){
        return true;
      }
  
      if (usuarioNiveles && usuarioNiveles.nivelNativo.maximoPorcentajeAprobacion >= Math.abs(lote.valorMaximo)) {
        return true;
      }
    }
    

    var maxVariacionDelegado = 0;

    if (usuarioNiveles) {
      for (let nivelDelegado of usuarioNiveles.nivelesDelegados) {

        if(nivelDelegado.maximoPorcentajeAprobacion == 100){
          return true;
        }

        if (nivelDelegado.maximoPorcentajeAprobacion >= Math.abs(maxVariacionDelegado)) {
          maxVariacionDelegado = nivelDelegado.maximoPorcentajeAprobacion;
        }
      }
    }

    if (maxVariacionDelegado >= lote.valorMaximo) {
      return true;
    }

    var masimaVAriacionNativa = usuarioNiveles?.nivelNativo ? usuarioNiveles.nivelNativo.maximoPorcentajeAprobacion : 0;
    var maximaVariacionUsuario = masimaVAriacionNativa > maxVariacionDelegado ? masimaVAriacionNativa : maxVariacionDelegado;

    this.mensajeAccesoInsuficiente = "El lote "+ this.loteSeleccionado.numeroLote +" tiene un valor máximo de "+ this.loteSeleccionado.valorMaximo +", y su nivel tiene un valor máximo del "+ maximaVariacionUsuario +". No puede seleccionar el lote.";

    return false;
  }

  delegarNivel() {
    let modalRef = this.modalService.open(ModalSeleccionarUsuarioComponent, { centered: true });
    modalRef.componentInstance.esDelegacion = true;
    modalRef.componentInstance.nivelAprobacion = this.preciosService.getNivelUsuarioLogueado() != null ? this.preciosService.getNivelUsuarioLogueado().nivelNativo : null;
    modalRef.result.then((result: Usuario) => {
      this.messageService.showSuccessLongMessage("Se delego su nivel correctamente al usuario");
    }, () => { });
  }
  usuarioPuedeDelegar() {
    try {
      var usuarioNiveles = this.preciosService.getNivelUsuarioLogueado();

      if(usuarioNiveles?.nivelNativo != null)
      {
        //Solo se puede delegar si tiene el nivel nativo de Comercial
        if (usuarioNiveles && usuarioNiveles.nivelNativo.tipoNivel == tipoNivelUsuarioSeparados.Comercial) {
          return true;
        }
      }

      return false;
    } catch (ex) {
      console.log(ex);
      return false;
    }
  }


  eliminarLote() {

    if (this.loteSeleccionado == null) {
      this.messageService.showErrorMessage("Debe seleccionar un lote para eliminarlo.");
      return;
    }

    if (!this.authService.hasRole(TipoUsuario.PRECIOS)) {
      this.messageService.showErrorMessage("Solo usuarios con rol PRECIOS pueden eliminar lotesByr.");
      return;
    }

    const modalRef = this.modalService.open(ModalConfirmComponent, { centered: true });
    modalRef.componentInstance.titulo = "Se eliminará el lote " + this.loteSeleccionado.numeroLote;
    modalRef.componentInstance.mensaje = "¿Está seguro?";
    modalRef.componentInstance.textoSi = "Si";
    modalRef.componentInstance.textoNo = "Cancelar";
    modalRef.result.then(() => {
      this.cargandoLotes = true;
      this.spinner.show("spinnerLotes");

      this.preciosService.eliminarLote(this.loteSeleccionado.id.toString())
        .subscribe(() => {
          this.cargandoLotes = false;
          this.spinner.hide("spinnerLotes");
          this.messageService.showSuccessLongMessage("Se elimino el lote seleccionado");
          this.getLotes();
        },
          (err: ErrorModel) => {
            this.cargandoLotes = false;
            this.spinner.hide("spinnerLotes");
            this.messageService.showErrorMessage("Error al eliminar el lote.");
          });
    });

  }

  aprobarByr() {
    if (this.loteSeleccionado == null) {
      this.messageService.showErrorMessage("Debe seleccionar un lote para aprobar.");
      return;
    }

    this.accionAprobarByr();
 
  }

  accionAprobarByr(){
    const modalRef = this.modalService.open(ModalConfirmComponent, { centered: true });
    modalRef.componentInstance.titulo = "Se aprobarán todas las bonificaciones y recargos del lote: " + this.loteSeleccionado.numeroLote;
    modalRef.componentInstance.mensaje = "¿Está seguro?";
    modalRef.componentInstance.textoSi = "Si";
    modalRef.componentInstance.textoNo = "Cancelar";
    modalRef.result.then(() => {
      this.cargandoLotes = true;
      this.spinner.show("spinnerLotes");
  
      var estado = new EstadoPrecio();
      estado.responsable = this.preciosService.obtenerStringResponsableV2();
      estado.activo = TipoActivoPrecio.Activo;
      estado.estadoPrecio = TipoEstadoPrecio.Aprobado;

      var fecha = new Date();
      estado.fechaHora = this.formatDate(fecha.getFullYear(), fecha.getMonth() + 1, fecha.getDate(), fecha.getHours(), fecha.getMinutes());
  
      this.preciosService.cambiarEstadoLoteByR(estado, this.loteSeleccionado.id)
        .subscribe(result => {
          this.cargandoLotes = false;
          this.spinner.hide("spinnerLotes");
          this.messageService.showSuccessLongMessage("Se proceso la solicitud de aprobación de los bonificacionesRecargos del lote " + this.loteSeleccionado.numeroLote);
          this.getLotes();
        },
          (err: ErrorModel) => {
            this.cargandoLotes = false;
            this.spinner.hide("spinnerLotes");
            this.messageService.showErrorMessage("Error al aprobar bonificacionesRecargos. " + err.message + err.description);
          });
    });     
  }

  rechazarByr() {

    if (this.loteSeleccionado == null) {
      this.messageService.showErrorMessage("Debe seleccionar un lote para rechazar.");
      return;
    }

    const modalRef = this.modalService.open(ModalConfirmComponent, { centered: true });
    modalRef.componentInstance.titulo = "Se rechazarán todos los bonificacionesRecargos del lote: " + this.loteSeleccionado.numeroLote;
    modalRef.componentInstance.mensaje = "¿Está seguro?";
    modalRef.componentInstance.textoSi = "Si";
    modalRef.componentInstance.textoNo = "Cancelar";
    modalRef.result.then(() => {
      this.cargandoLotes = true;
      this.spinner.show("spinnerLotes");
  
      var estado = new EstadoPrecio();
      estado.responsable = this.preciosService.obtenerStringResponsableV2();
      estado.activo = TipoActivoPrecio.Activo;
      estado.estadoPrecio = TipoEstadoPrecio.Rechazado;
  
      this.preciosService.cambiarEstadoLoteByR(estado, this.loteSeleccionado.id)
        .subscribe(result => {
          this.cargandoLotes = false;
          this.spinner.hide("spinnerLotes");
          this.messageService.showSuccessLongMessage("Se proceso la solicitud para rechazar los bonificacionesRecargos marcados");
          this.getLotes();
        },
          (err: ErrorModel) => {
            this.cargandoLotes = false;
            this.spinner.hide("spinnerLotes");
            this.messageService.showErrorMessage("Error al rechazar bonificacionesRecargos. " + err.message + err.description);
          });
    });    
  }

  descripcionTipoVenta(tipoVenta) {
    if (tipoVenta == TipoVenta.cabotaje) {
      return "Cabotaje";
    } else if (tipoVenta == TipoVenta.internacional) {
      return "Internacional";
    } else {
      return "-";
    }
  }

  descripcionCondicionVenta(condicionVenta) {
    if (condicionVenta == CondicionVenta.contado) {
      return "Contado";
    } else if (condicionVenta == CondicionVenta.cuentaCorriente) {
      return "Cta. Cte.";
    } else {
      return "-";
    }
  }

  hasRole(...roles: TipoUsuario[]) {
    return this.authService.hasRole(...roles);
  }

  get busquedaFormControl() {
    return this.searchForm.get("busquedaFormControl");
  }

  public onPageChange = (pageNumber) => {
    this.bonificacionesRecargosFiltradas = [];
    this.getBonificacionesRecargoPorLote(pageNumber, this.pageSize, this.loteSeleccionado.id, true);
  }

  obtenerLeyendaEstado(estado){
    if(estado == TipoEstadoPrecio.Aprobado){
      return "Aprobado";
    }else if(estado == TipoEstadoPrecio.Rechazado){
      return "Rechazado";
    }else if(estado == TipoEstadoPrecio.Pendiente){
      return "Pendiente";
    }
  }

  obtenerLeyendasBonificacion(bonif: number) {
    if (bonif == 0) {
      return 'Bonificación';
    } else {
      return 'Recargo';
    }
  }
  
}
