import { Component, OnInit, Inject, LOCALE_ID, ViewChild } from '@angular/core';
import { Producto } from 'src/app/shared/models/abm/Producto';
import { ProductoService } from 'src/app/abm/producto/producto.service';
import { Aeroplanta } from 'src/app/shared/models/despacho/Aeroplanta';
import { ComprobanteService } from '../../comprobante.service';
import { Remito } from 'src/app/shared/models/facturacion/Remito';
import { NgxSpinnerService } from 'ngx-spinner';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { RemitosFacturacionComponent } from '../remitos-facturacion/remitos-facturacion.component';
import { NgbModal, NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { Cliente } from 'src/app/shared/models/cliente/Cliente';
import { PreciosService } from 'src/app/precios/precios.service';
import { UsuarioService } from 'src/app/account/usuario.service';

@Component({
  selector: 'app-remitos-lista',
  templateUrl: './remitos-lista.component.html',
  styleUrls: ['./remitos-lista.component.css']
})
export class RemitosListaComponent implements OnInit {
  esperandoCarga: boolean;
  productos: Producto[];
  aeroplantas: Aeroplanta[];
  remitosSinFacturar: Remito[];
  clienteConRemitos: Remito[];
  unique: any[];
  errorClientes: boolean;
  errorAeroplantas: boolean;
  cargandoProductos: boolean = false;
  errorProductos: boolean;
  cargandoAeroplanta: boolean = false;
  clienteSeleccionado: Cliente;
  cliente: Cliente[] = [];
  clientesFiltrados: Cliente[] = [];
  modelDesde: NgbDateStruct;
  aeroplantaFiltrada: Aeroplanta;
  fechaDesde: Date;
  modelCliente: any;
  cargandoClientes: boolean = false;
  seleccionado: boolean = false;

  cuentasSeleccionadas: Cliente;
  aeroplantaSoloPad: boolean = false;

  busquedasCliente = new UntypedFormGroup({
    clienteFormControl: new UntypedFormControl(),
    aeroplantaFormControl: new UntypedFormControl(),
    productoFormControl: new UntypedFormControl(),
    fechaFormControl: new UntypedFormControl()
  })



  constructor(private productoService: ProductoService, private precioService: PreciosService, private facturacionService: ComprobanteService,
    private spinner: NgxSpinnerService, private modalService: NgbModal, private calendar: NgbCalendar,
    private usuarioService: UsuarioService,
    @Inject(LOCALE_ID) public locale: string) { }

  ngOnInit() {
    this.aeroplantaSoloPad = this.usuarioService.getAeroplantaActual().soloPAD;
    this.spinner.show('spinnerGrafico');
    this.esperandoCarga = true;
    this.getProductos();
    this.getAeroplantas();
    this.getClientes();
    this.modelDesde = this.calendar.getToday();
    this.fechaDesde = new Date(this.modelDesde.year, this.modelDesde.month - 1, this.modelDesde.day);
  }

  mostrarDetalle(i: number) {
    this.seleccionado = false;
    // for (let j = 0; this.clientesFiltrados[i].cuentas.length > j; j++) {
    this.cuentasSeleccionadas = this.clientesFiltrados[i];
    // }
    this.seleccionado = true;
  }

  getClientes() {
    this.esperandoCarga = true;
    this.facturacionService.getClientesPrevioPago()
      .subscribe(result => {
        this.cliente = result.sort((a, b) => { if (a.razonSocial > b.razonSocial) { return 1 } else { return -1 } });
        // if(this.aeroplantaSoloPad){
        //   this.cliente = this.cliente.filter(a => a.soloPAD == this.aeroplantaSoloPad);
        // }
        this.clientesFiltrados = this.cliente;
        this.cuentasSeleccionadas = this.cliente[0];
        this.spinner.hide('spinnerGrafico')
        this.esperandoCarga = false;
      })
  }


  getProductos() {
    this.cargandoProductos = true;
    this.productoService.obtenerProductos()
      .subscribe(result => {
        this.productos = result;
        this.cargandoProductos = false;
      }, () => {

      });
  }

  filtroClientes() {
    this.clientesFiltrados = this.cliente.filter(a => {
      let agregar = true;
      if (this.clienteFormControl.value) {
        agregar = a.razonSocial.toLowerCase().indexOf(this.clienteFormControl.value.toLowerCase()) > -1;
        if (a.ctaSap != null) {
          agregar = agregar || a.ctaSap.toLocaleLowerCase().indexOf(this.clienteFormControl.value.toLowerCase()) > -1;
        }
      }
      return agregar;
    });
  }

  getAeroplantas() {
    this.cargandoAeroplanta = true;
    this.precioService.obtenerTodasLasAeroplantas()
      .subscribe(result => {
        this.aeroplantas = result.sort((a, b) => { if (a.nombre > b.nombre) { return 1 } else { return -1 } });
        this.cargandoAeroplanta = false;
      });
  }

  calcularFactura() {
    const modalRef = this.modalService.open(RemitosFacturacionComponent, { centered: true, size: "lg", backdrop: "static" });
    modalRef.result.then(result => {
      this.ngOnInit();
    })
  }

  get clienteFormControl() {
    return this.busquedasCliente.get('clienteFormControl');
  }
  get aeroplantaFormControl() {
    return this.busquedasCliente.get('aeroplantaFormControl');
  }
  get productoFormControl() {
    return this.busquedasCliente.get('productoFormControl');
  }
  get fechaFormControl() {
    return this.busquedasCliente.get('fechaFormControl');
  }
}
