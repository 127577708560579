<div class="modal-header bg-light">
    <h4 class=" modal-title text-center">Cupones</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close(true)">
      <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <ngb-carousel animation="false">
      <ng-template ngbSlide *ngFor="let c of _cupones"> 
        <img [src]="c.pathCuponFisico" style="width: 45em; height: 40em;" class="rounded mx-auto d-block" alt="id imagen {{c.id}}">
        <div class="carousel-caption">
          <h3 class="texto">{{c.tarjeta.nombre}}</h3>
          <h2 class="texto">Lote Nº: {{c.numeroLote}} - Cupon Nº: {{c.numeroCupon}} - Importe: {{c.importe | number: '0.2-2'}}</h2>
        </div>
      </ng-template>
    </ngb-carousel>
</div>
<div class="modal-footer bg-light d-flex justify-content-center">
  <button type="button" class="btn btn-secondary"
    (click)="activeModal.close(true)">Cerrar</button> 
</div>
