<div class="modal-header bg-light">
  <h4 class="modal-title">Equivalencia del tanque: {{tanque.nombre}}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()">
    
  </button>
</div>
<div class="modal-body">
  <div class="spinner-container" *ngIf="obteniendoMediciones">    
    <ngx-spinner name="spinnerEquivalencia" bdOpacity=0.3bd
      Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
      <p style="color: white">{{messageEquivalencias}}</p>
    </ngx-spinner>    
  </div>
  <table class="table table-striped table-hover" *ngIf="!this.obteniendoMediciones">
    <thead>
      <td class="text-center"><b>Altura (mm.)</b></td>
      <td class="text-center"><b>Volumen (Lts.)</b></td>
    </thead>
    <tbody>
      <tr *ngFor="let e of equivalencia">
        <td class="text-center">{{e.altura}}</td>
        <td class="text-center">{{e.volumen}}</td>
      </tr>
    </tbody>
  </table>
</div>
<div class="modal-footer bg-light">
  <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss('Close clicked')">Cancelar</button>
</div>