import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbCalendar, NgbDatepickerConfig, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { AerovalesService } from 'src/app/despacho/aerovales.service';
import { ComprobanteService } from 'src/app/facturacion/comprobante.service';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';
import { Aeroplanta } from 'src/app/shared/models/despacho/Aeroplanta';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { MessageService } from 'src/app/shell/message.service';
import { StockService } from 'src/app/stock/stock.service';

@Component({
  selector: 'app-informacion-cai',
  templateUrl: './informacion-cai.component.html',
  styleUrls: ['./informacion-cai.component.css']
})
export class InformacionCaiComponent implements OnInit {

  @Input() aeroplantaSeleccionada: Aeroplanta;

  model: NgbDateStruct;
  modelFechaInicio: NgbDateStruct;
  guardando: boolean = false;


  caiFormGroup = new UntypedFormGroup({
    aeroplantaFormControl: new UntypedFormControl(null),
    numeroFormControl: new UntypedFormControl(null, [Validators.required]),
    fechaFormControl: new UntypedFormControl(null),
    prefijoVentaFormControl: new UntypedFormControl(null, [Validators.required]),
    prefijoSalidaFormControl: new UntypedFormControl(null, [Validators.required]),
  })

  constructor(private authService: AuthService, private datepickerConfig: NgbDatepickerConfig, private calendar: NgbCalendar, private messageService: MessageService,
    private stockService: StockService, private despachoService: AerovalesService, private facturacionService: ComprobanteService) { }
  TipoUsuario = TipoUsuario;

  ngOnInit() {
    let inicio: any = { day: 1, month: 1, year: 2019 };
    this.datepickerConfig.minDate = inicio;    
    let fin: any = { day: 31, month: 12, year: 2050 };
    this.datepickerConfig.maxDate = fin;

    this.aeroplantaFormControl.setValue(this.aeroplantaSeleccionada);
    this.numeroFormControl.setValue(this.aeroplantaSeleccionada.cai);
    this.prefijoVentaFormControl.setValue(this.aeroplantaSeleccionada.prefijoRemito);
    this.prefijoSalidaFormControl.setValue(this.aeroplantaSeleccionada.prefijoRemitoSalida);
    // fecha y hora inicio.
    var fechaInicio = new Date(this.aeroplantaSeleccionada.fechaVencimientoCAI);
    this.modelFechaInicio = { day: fechaInicio.getDate(), month: fechaInicio.getMonth() + 1, year: fechaInicio.getFullYear() };
    if (this.modelFechaInicio.day == 31 && this.modelFechaInicio.month == 12 && this.modelFechaInicio.year == 1969) {
      this.modelFechaInicio = null;
    }
  }

  formatDate(year, month, day, hour, minute): string {
    return year + '-' + month.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + '-' + day.toLocaleString(undefined, { minimumIntegerDigits: 2 })
      + 'T' + hour.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + minute.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':00';
  }

  ngOnChanges() {
    this.ngOnInit();
  }

  guardarDatos() {
    this.guardando = true;
    if (this.numeroFormControl.valid && this.prefijoSalidaFormControl.valid && this.prefijoVentaFormControl.valid) {
      let aep: Aeroplanta = new Aeroplanta();
      aep = this.aeroplantaSeleccionada;
      aep.cai = this.numeroFormControl.value;
      aep.prefijoRemito = Number(this.prefijoVentaFormControl.value);
      aep.prefijoRemitoSalida = Number(this.prefijoSalidaFormControl.value);
      aep.fechaVencimientoCAI = this.formatDate(
        this.fechaFormControl.value.year, this.fechaFormControl.value.month,
        this.fechaFormControl.value.day, 23, 59);
      this.facturacionService.putDatosAeroplanta(aep)
        .subscribe(result => {
            this.messageService.showSuccessMessage('Se agregaron correctamente los datos a la aeroplanta ' + aep.nombre);
            this.guardando = false;
        }, (err: ErrorModel) => {
          this.messageService.showErrorMessage('Fallo la actualizacion de los datos de la aeroplanta ' + aep.nombre);
          this.guardando = false;
        })
    } else {
      this.messageService.showErrorMessage('Falta completar datos.');
      this.guardando = false;
    }
  }
  
  hasRole(...roles: TipoUsuario[]) {
    return this.authService.hasRole(...roles);
  }

  get aeroplantaFormControl() {
    return this.caiFormGroup.get('aeroplantaFormControl');
  }

  get numeroFormControl() {
    return this.caiFormGroup.get('numeroFormControl');
  }

  get fechaFormControl() {
    return this.caiFormGroup.get('fechaFormControl');
  }

  get prefijoVentaFormControl() {
    return this.caiFormGroup.get('prefijoVentaFormControl');
  }

  get prefijoSalidaFormControl() {
    return this.caiFormGroup.get('prefijoSalidaFormControl');
  }

}
