<ngb-alert *ngIf="errorMessage != ''" type="danger">{{errorMessage}}</ngb-alert>
<form *ngIf="pedidoDetalle && !showOrderModify">
<div class="card">
  <div class="card-header">
    <ng-container *ngIf="visualizarPedido && this.numeroComprobante != null && pedidoDetalle.tipoPedido==TipoPedido.Alije">      
      <label  class="h5 fw-bold">Número Comprobante: {{ numeroComprobante }}</label>
      <br><br>
    </ng-container>

    <label class="h5">Vuelo: {{pedidoDetalle.vuelo ? pedidoDetalle.vuelo : "Sin vuelo" }} - Aeronave: {{pedidoDetalle.aeronaveMatricula ? pedidoDetalle.aeronaveMatricula : "Sin aeronave" }} - Estado: {{ this.obtenerNombreEstado(pedidoDetalle.estado) }}</label>    
    <loading-text-spinner *ngIf="procesandoInformacion" message="Procesando información..." orientation="vertical"
      class="list-group-item" size="large" color="primary" textColor="dark"></loading-text-spinner>
    <ng-container *ngIf="!visualizarPedido || (visualizarPedido && this.numeroComprobante == null)">
      <div *ngIf="hasRole(TipoUsuario.OPERADOR, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO,TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL_AV)"  class="float-end">
        <button *ngIf="pedidoDetalle.estado == 0" class="btn btn-danger btn-sm add-margin" (click)="cerrarPedido()"><i
            class="fa fa-close fa-fw"></i>Cerrar pedido</button>
        <button (click)="anularPedido()" class="btn-sm add-margin"><i class="fa fa-trash" aria-hidden="true"></i></button>
      </div>
      <div class="float-end">
        <button *ngIf="hasRole(TipoUsuario.OPERADOR, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL_AV)  && pedidoDetalle.estado == 0 && pedidoDetalle.tipoPedido != TipoPedido.Alije" class="btn btn-primary btn-sm add-margin"
          (click)="modificarPedido(pedidoDetalle.id)" ngbTooltip="Modificar pedido seleccionado."><i
          class="fa fa-check fa-fw"></i>Modificar pedido</button>
      </div>
      <div class="float-end">
        <button *ngIf="hasRole(TipoUsuario.OPERADOR, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL_AV)  && pedidoDetalle.tipoPedido == TipoPedido.Alije && pedidoDetalle.estado != 1" class="btn btn-primary btn-sm add-margin"
          (click)="onCrearAevDevolucion(pedidoDetalle, $event)" ngbTooltip="Crear aerovale devolución alije."><i
          class="fa fa-check fa-fw"></i>Aerovale devolución alije</button>
      </div>
    </ng-container>

    <div class="card-body">
      <div class="row">
        <div class="mb-3 col-md-12">
          <label class="h5">Detalle del Pedido para: {{pedidoDetalle.clienteRazonSocial}}</label>
        </div>
      </div>
      <div class="row">
        <div class="mb-3 col-md-4">
          <label for="inputPassword4">CUIT:</label>&nbsp;
          <label class="h6">{{pedidoDetalle.clienteCuit}}</label>
        </div>
        <div class="mb-3 col-md-4">
          <label for="inputCity">Aerocombustible: </label>&nbsp;
          <label class="h6">{{pedidoDetalle.producto.nombreProducto}}</label>
        </div>
        <div class="mb-3 col-md-4">
          <label for="inputZip">Cantidad de Carga: </label>&nbsp;
          <label class="h6">{{pedidoDetalle.cantidadEstimada}} L</label>
        </div>
      </div>

      <div class="row">
        <div class="mb-3 col-md-6">
          <label for="cantidadMinima">Fecha / Hora Vuelo: </label>&nbsp;
          <label class="h6">{{pedidoDetalle.fechaHoraVuelo | date: "dd'/'MM'/'y' 'hh:mm a"}}</label>
        </div>
        <div class="mb-3 col-md-4">
          <input class="form-check-input" type="checkbox" id="esPrioridadFormControl"
            [formControl]="esPrioridadFormControl" (change)="cambioPrioridad($event.target.checked)">
          <label class="form-check-label ms-2" for="esPrioridadFormControl" ngbTooltip="Determina si el pedido es una prioridad">
              Prioridad
          </label>   
        </div>
      </div>

      <div *ngIf="mostrarRancho" class="row">
        <div class="mb-3 col-md-12">
          <div class="border">
            <div *ngIf="!cargandoRancho; else tCargandoRancho">
              <ng-container *ngIf="rancho; else noVigente">
                <label class="col-form-label col-sm-4 text-md-center text-sm-start">Rancho: <span
                    class="fw-bold">{{rancho.codigoRancho}}</span></label>
                <label class="col-form-label col-sm-4 text-md-center text-sm-start">Vencimiento: <span
                    [ngClass]="{'text-danger': rancho.vencido}">{{ rancho.fechaVencimiento | date: "dd'/'MM'/'y" }}</span>
                </label>
                <label class="col-form-label col-sm-4 text-md-center text-sm-start">Disponible:
                  {{rancho.volumenDisponible | number}}
                  LTS.</label>
              </ng-container>
              <ng-template #noVigente>
                <label class="col-form-label col-sm-12 text-md-center text-sm-start text-danger">
                  No se encontró un rancho vigente</label>
              </ng-template>
            </div>
            <ng-template #tCargandoRancho>
              <div class="col-form-label">
                <loading-text-spinner message="Obteniendo rancho" content-align="center" color="primary">
                </loading-text-spinner>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>

  <table *ngIf="cantidadCargada>=0" class="table table-hover">
    <thead>
      <tr>
        <th scope="col">Fecha / Hora</th>
        <th scope="col">Abastecedora</th>
        <th scope="col">Carga Estimada</th>
        <th scope="col">Cantidad Cargada</th>
        <th scope="col">Estado</th>
        <th scope="col">Acción</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let precarga of precargas">
        <td>{{precarga.fechaHora | date: "dd/MM/yy h:mm a"}}</td>
        <td>{{precarga.abastecedoraNombre? precarga.abastecedoraNombre : '- Sin Abastecedora -' }}</td>
        <td *ngIf="(precarga.aerovaleId)!='00000000-0000-0000-0000-000000000000'">{{precarga.cargaEstimada}} L</td>
        <td *ngIf="(precarga.aerovaleId)==='00000000-0000-0000-0000-000000000000'">0 L</td>
        <td *ngIf="precarga.cantidadReal>0">{{precarga.cantidadReal}} L</td>
        <td *ngIf="precarga.cantidadReal==0">
          <p class="text-danger">Anulado</p>
        </td>
        <td>{{convertirEstado(precarga.estado)}}</td>
        <td>
          <button *ngIf="showAerovale(precarga)" class="btn" (click)="goToAerovale(precarga.aerovaleId);"
            placement="bottom" ngbTooltip="Ver Aerovale" container="body">
            <i class="fa fa-eye" aria-hidden="true"></i>
          </button>
          <button *ngIf="hasRole(TipoUsuario.OPERADOR, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL_AV) && !showAerovale(precarga)" class="btn" (click)="anularPrecarga(precarga);" placement="bottom"
            ngbTooltip="Anular" container="body">
            <i class="fa fa-trash" aria-hidden="true"></i>
          </button></td>
      </tr>
      <tr>
        <td scope="col"></td>
        <th scope="col">Total: </th>
        <td scope="col"> {{sumaCargasEstimadas() }} L </td>
        <th scope="col">{{ cantidadCargada }} L</th>
        <td scope="col"></td>
        <td scope="col"></td>
      </tr>
      <tr>
        <td></td>
        <th scope="col">Cantidad faltante: </th>
        <td></td>
        <th scope="col">{{ pedidoDetalle.cantidadFaltante }} L</th>
        <td></td>
        <td></td>
      </tr>
    </tbody>
  </table>
</div>
<div class="d-flex justify-content-center">
  <button *ngIf="(!visualizarPedido || (visualizarPedido && this.numeroComprobante == null))
                  && hasRole(TipoUsuario.OPERADOR, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL_AV) 
                  && ((pedidoDetalle.tipoPedido == TipoPedido.Manual && pedidoDetalle.cantidadFaltante > 0)
                  || (pedidoDetalle.tipoPedido != TipoPedido.Alije))" class="btn btn-primary btn-lg add-margin"
    (click)="agregarPrecarga()"><i class="fa fa-plus-square" aria-hidden="true"></i> Agregar Precarga</button>
  <button class="btn btn-secondary btn-lg add-margin" (click)="goBack()"> {{ this.visualizarPedido ? "Volver a pedidos" : "Cerrar" }}</button>    
</div>
</form>

<div [@fadeIn] *ngIf="showOrderModify">
  <app-modificar-pedido  [(pedidoDetalleM)]="pedidoDetalleModificado" (cancelado)="onModificacionCancelada()">
  </app-modificar-pedido>
</div>
