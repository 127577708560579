import { Producto } from './Producto';
import { AerovaleCommon } from './AerovaleCommon';
import { VehiculoDTO } from '../cliente/VehiculoDTO';

export class AerovaleAlije extends AerovaleCommon{
  aeronave: VehiculoDTO;
  aerocombustible: Producto;
  laboratorioId: String;
  codigoPruebaLaboratorio: String;
  fechaHoraPruebaLaboratorio: String;
  fechaHoraLlegada: String;
  temperatura: number;
  densidad: number;
  pruebaAgua: boolean;
  unidad: number;
  volumenNaturales: number;
  aforadorInicio: number;
  aforadorFinal: number;
  varilladoInicial
  varilladoFinal
  resultadoPruebaLaboratorio: number;
  estadoFinal: String;
  codigoAbastecedora: string;
  idPico: string;
  picoId: string;
  volumen15Grados:number;
  servicios: Servicio[];
}

export class Servicio{  
  productoCodigo: string;
  cantidad: number;
  nombreProducto: string;

  constructor(cantidad, codigo){
    this.cantidad = cantidad;
    this.productoCodigo = codigo;
  }
}
