<div class="p-2 add-margin">
  <div class="card">
    <div class="card-header">
      <b>Carga masiva de Ranchos</b>
    </div>
    <div class="card-body">
      <h5 class="card-title">Utilice este importador para cargar masivamente los Ranchos utilizando un archivo de tipo
        Excel <i class="fa fa-file-excel-o" aria-hidden="true"></i></h5>
      <p class="card-text"><b>Importante:</b> Debe tener el formato y ordenamiento de columnas que se muestra abajo</p>

      <table class="border table-condensed text-center letra" style="cursor: pointer; font-size: 13px; border-width: 1px 1px;">
        <tr>
          <td class="border py-2 px-2"><b>Concatenado</b></td>
          <td class="border py-2 px-2"><b>Codigo Pais</b></td>
          <td class="border py-2 px-2"><b>Precio/Lts </b></td>
          <td class="border py-2 px-2"><b>TipoMoneda</b></td>
          <td class="border py-2 px-2"><b>Volumen Lts</b></td>
          <td class="border py-2 px-2"><b>Producto</b></td>
          <td class="border py-2 px-2"><b>Aeroplanta</b></td>
          <td class="border py-2 px-2"><b>Fecha INI</b></td>
          <td class="border py-2 px-2"><b>Fecha VENC</b></td>
          <td class="border py-2 px-2"><b>TipoDocumento</b></td>
          <td class="border py-2 px-2"><b>NumeroDocumento</b></td>
          <td class="border py-2 px-2"><b>SeFactura</b></td>
          <td class="border py-2 px-2"><b>CTA Facturacion</b></td>
          <td class="border py-2 px-2"><b>FOBTotal</b></td>
          <td class="border py-2 px-2"><b>Bandera</b></td>
          <td class="border py-2 px-2"><b>CodigoBandera</b></td>
          <td class="border py-2 px-2"><b>Comentarios</b></td>
          <td class="border py-2 px-2"><b>Codigo Pais Facturacion</b></td>
        </tr>
      </table>
      <br>
      <div *ngIf="fileUpload.status==='error'">
        <div [innerHTML]="fileUpload.message"></div>
      </div>
      <div *ngIf="fileUpload.status==='progress'">
        <div class="progress" style="height: 20px;margin-bottom:20px;">
          <div class="progress-bar" role="progressbar" [style.width.%]="fileUpload.message" aria-valuenow="25"
            aria-valuemin="0" aria-valuemax="100">{{fileUpload.message}}%</div>
        </div>
      </div>
      <form [formGroup]="profileForm">
        <div class="mb-3">
          <div class="input-group">
            <input #inputFile type="file" class="form-control col-md-12" name="profile" (change)="onSelectedFile($event)"
              placeholder="Archivo.xls" id="validatedCustomFile" formControlName="inputFile" required (click)="resetearArchivo()">
            <div class="invalid-feedback">Example invalid custom file feedback</div>
          </div>
        </div>
        <div class="mb-3">
          <button [disabled]="!profileForm.valid || cargando"class="btn btn-success" (click)="cargarRanchos()">
            <span *ngIf="!cargando">Cargar</span>
            <div *ngIf="cargando" class="d-flex align-items-center">
                <div class="spinner-border text-light spinner-border-sm" role="status"></div>
                <span>Cargando...</span>
            </div>
        </button>
          <button type="button" class="btn btn-secondary ms-2" (click)="reset(true, true)">Cancelar</button>
        </div>
      </form>
      <div *ngIf="fileUpload.length == 1; else varios" class="alert alert-success">
        {{this.limpiarErrores()}}
        Se cargaron todos los ranchos correctamente
      </div>
      <ng-template #varios>
        <div *ngIf="fileUpload.length > 1" class="alert alert-warning" role="alert">
          <div *ngFor="let msg of fileUpload">
            <p>{{ msg.description }}</p>
          </div>
          {{this.limpiarErrores()}}
        </div>
      </ng-template>
    </div>
    <div *ngIf="error" class="alert alert-danger" role="alert">{{ error }}</div>
  </div>
</div>
