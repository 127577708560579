import { CalculoBaseImponible } from '../../enums/CalculoBaseImponible';
import { Zona } from '../../enums/zona';

export class parametroImpositivo {
    id: string;
    codigo: number;
    fechaActualizacion: string;
    fechaInicio: string;
    fechaFin: string;
    zona: Zona;
    activa: boolean;
    leyenda: string;
    tipoValor: number; // Alicuota = 0, ValorFijo = 1
    valor: number;
    baseCalculo: CalculoBaseImponible; // Neto = 0, Total = 1, NetoMasImpuesto = 2
    tipoMinimo: number;
    minimo: number;
    productoId: string;
    rubroId: string;
    }
    