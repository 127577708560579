import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { debounce, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ConfiguracionService } from 'src/app/despacho/configuracion.service';
import { Cliente } from 'src/app/shared/models/cliente/Cliente';
import { Contacto } from 'src/app/shared/models/cliente/Contacto';
import { Pais } from 'src/app/shared/models/despacho/Pais';
import { Provincia } from 'src/app/shared/models/facturacion/Provincia';
import { ClienteService } from '../cliente.service';
import { MessageService } from 'src/app/shell/message.service';

@Component({
  selector: 'app-modal-responsable',
  templateUrl: './modal-responsable.component.html',
  styleUrls: ['./modal-responsable.component.css']
})
export class ModalResponsableComponent implements OnInit {
  @Input() clienteResponsable: Cliente;
  @Output() respModificado: EventEmitter<Contacto> = new EventEmitter()
  guardando: boolean = false;

  responsableForm = new UntypedFormGroup({
    nombreFormControl: new UntypedFormControl(null, [Validators.required]),
    telefonoFormControl: new UntypedFormControl(null),
    mailFormControl: new UntypedFormControl(null)
  });


  constructor(public activeModal: NgbModal, private configuracionService: ConfiguracionService, private clienteService: ClienteService,
    private messageService: MessageService) { }

  ngOnInit() {
    if (this.clienteResponsable.responsable != null) {
      this.nombre.setValue(this.clienteResponsable.responsable.nombreCompleto);
      this.telefono.setValue(this.clienteResponsable.responsable.telefono);
      this.email.setValue(this.clienteResponsable.responsable.email);
    }
  }

  guardarResponsable() {
    if (this.responsableForm.valid) {
      this.guardando = true;
      let resp = new Contacto;
      resp.nombreCompleto = this.nombre.value;
      resp.telefono = this.telefono.value;
      resp.email = this.email.value;
      this.messageService.showWarningMessage('Se modificó el responsable correctamente. \n Recuerde guardar los cambios del cliente.', false)

      this.respModificado.emit(resp);
      this.activeModal.dismissAll();

    } else {
      this.messageService.showErrorMessage('Por favor complete los datos.');
    }
  }


  get nombre() {
    return this.responsableForm.get('nombreFormControl');
  }
  get telefono() {
    return this.responsableForm.get('telefonoFormControl');
  }
  get email() {
    return this.responsableForm.get('mailFormControl');
  }
}
