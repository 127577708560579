import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { UsuarioService } from 'src/app/account/usuario.service';
import { TipoPedido } from 'src/app/shared/enums/TipoPedido';
import { Cliente } from 'src/app/shared/models/cliente/Cliente';
import { Aeroplanta } from 'src/app/shared/models/despacho/Aeroplanta';
import { SaldoCuentaDTO } from 'src/app/shared/models/despacho/SaldoCuentaDTO';
import { SharedService } from 'src/app/shared/shared.service';
import { MessageService } from 'src/app/shell/message.service';
import { NuevoPedidoComponent } from '../../../nuevo-pedido/nuevo-pedido.component';
import { PedidoService } from '../../../pedido.service';

@Component({
  selector: 'app-alijes-devolucion-saldos',
  templateUrl: './alijes-devolucion-saldos.component.html',
  styleUrls: ['./alijes-devolucion-saldos.component.scss']
})
export class AlijesDevolucionSaldosComponent implements OnInit {

  /** locales */  
  obteniendoSaldos: boolean = false;
  saldosPorCuenta: SaldoCuentaDTO[] = [];
  change$: Subscription;

  /** entrada */
  @Input() cliente: Cliente;

  constructor(
    private modal: NgbModal,    
    private pedidoService: PedidoService,
    private messageService: MessageService,
    private usuarioService: UsuarioService,
    sharedService: SharedService) { 
      this.change$ = sharedService.aeroplantaSeleccionada.subscribe(_=>{ 
        this.cambiarDeAeroplanta()
      });
    }

  ngOnInit() {
  }

  cambiarDeAeroplanta() {
    this.ngOnChanges();
  }

  ngOnDestroy() {
    this.change$.unsubscribe();
  }

  getCodigoAeroplanta(){
    return this.usuarioService.getAeroplantaActual().codigoAeroplanta;
  }

  ngOnChanges() {
    this.obteniendoSaldos = false;    
    this.obtenerSaldosPorCuenta();   
  }  

  async obtenerSaldosPorCuenta() {
    this.obteniendoSaldos = true;

    try {
      this.saldosPorCuenta = await this.pedidoService.getObtenerSaldosAlijePorCliente(this.cliente.numeroDocumento, this.getCodigoAeroplanta());
      this.obteniendoSaldos = false;
    } catch (error) {
      this.obteniendoSaldos = false;
      this.messageService.showErrorMessage("Error: " + error.message + " descripcion: " + error.descripcion);
    }
    
  }

  generarDevolucion(cuen: SaldoCuentaDTO, item:any) {
    const modalRef = this.modal.open(NuevoPedidoComponent, { centered: true, size: 'lg', windowClass: 'myCustomModalClass', backdrop: "static" });    
    modalRef.componentInstance.tipoModalPedido = TipoPedido.Alije; 
    modalRef.componentInstance.cuentaSaldoAlije = cuen; 
    modalRef.componentInstance.clienteSeleccionado = this.cliente;    
    modalRef.componentInstance.itemSaldoAlije = item; 
  }
}
