<div class="modal-header bg-light">
  <h4 class="modal-title">Nueva Notificacion</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismissAll()">
    
  </button>
</div>
<div class="card-body">
  <form [formGroup]="form">

    <div class="row">
      <div class="mb-3 row col-md-12">
        <label for="clienteFormControl" class="col-sm-5 col-form-label">*Cliente:</label>
        <div class="col-sm-7">
          <input id="clienteFormControl" type="text" class="form-control" formControlName="clienteFormControl"
            placeholder="Cliente" [inputFormatter]="formatter" [(ngModel)]="modelCliente" [resultFormatter]="formatter"
            title="Seleccione un cliente." [ngbTypeahead]="search" (selectItem)="selectCustomer($event)"
            (change)="filtroClientes()" />
        </div>
      </div>

    </div>
    <br>
    <div class="row"  [formGroup]="checkMultiplesDocumentos">

      <div class="col-sm-6">
        <div class="form-check">
          <input (change)="this.seleccionarDocumentos(0, $event.target.checked)" type="checkbox" class="form-check-input" id="exampleCheck1"
            value="0" />
          <label class="form-check-label" for="exampleCheck1">Aerovales</label>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-check">
          <input (change)="this.seleccionarDocumentos(1, $event.target.checked)" type="checkbox" class="form-check-input" id="exampleCheck2"
            value="1" />
          <label class="form-check-label" for="exampleCheck2">Facturas</label>
        </div>
      </div>

    </div>
    <br>
    <div class="row">
      <div class="mb-3 row col-md-12">
        <label for="emailFormControl" class="col-sm-5 col-form-label">*Email:</label>
        <div class="col-sm-6">
          <input type="email" class="form-control" formControlName="emailFormControl" maxlength="50" type="email"
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$">
          <div *ngIf="emailFormControl.invalid && emailFormControl.errors.required &&
                          (emailFormControl.dirty || emailFormControl.touched)" class="alert alert-danger">
            Requerido</div>
          <div
            *ngIf="cantidadCaracteres(this.emailFormControl.value)>2 && emailFormControl.invalid && emailFormControl.errors.pattern && (emailFormControl.dirty || emailFormControl.touched)"
            class="alert alert-danger">
            El Email no tiene el formato correcto </div>
          <!-- <div *ngIf="this.chequearMail()" class="alert alert-danger"> El Mail ya fue registrado</div> -->
        </div>
        <div class="col-sm-1">
        <button [disabled]="this.desactivarBotonAgregarMail()" (click)="this.asignarMail()" >+</button>
      </div>
      </div>
    </div>

    <div *ngIf="this.listaDeEmails!=null && this.listaDeEmails.length>0" class="row">
      <div class="mb-3 row col-12">
        <div class="col-12"><b>Emails asignados</b></div>
      </div>
    </div>
    <form *ngIf="this.listaDeEmails!=null && this.listaDeEmails.length>0" formGroup="emailsForm">
    <div  class="row">
      <div class="mb-3 row col-6" *ngFor="let mail of listaDeEmails; let i=index">
        <div class="col-md-10 col-1">
          <div mdbDraggable class="card">
            <h5 class="card-header primary-color white-text">{{mail}}  </h5>
          </div>
        </div>
        <span> <button type="button" (click)="this.desasignarMail(i)" class="btn btn-danger"><i
          class="fa fa-trash" aria-hidden="true"></i></button> </span>

      </div>
    </div>
  </form>


    <div class="row">
      <!-- Aeroplantas -->
      <div class="mb-3 row col-md-12">
        <label for="observacionesControlSelect" class="col-sm-5 col-form-label">*Aeroplanta</label>

          <div class="col-sm-7"  [formGroup]="checkMultiplesAeroplantas">
            <div ngbDropdown autoClose="outside">
              <button class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2" id="dropdownProductos"
                ngbDropdownToggle>Aeroplantas</button>
              <div class="col-sm-5" ngbDropdownMenu aria-labelledby="dropdownProductos" style="z-index: 50">
                <div class="px-4 py-3" style="z-index: 50">
                  <div *ngFor="let aero of aeroplantasParaCheckear;let i = index">
                    <div class="form-check">
                      <input type="checkbox" name="aeroplantas+{{i}}" class="form-check-input"
                        id="{{ 'dropdownCheck' + aero.nombre}}"
                        (change)="this.seleccionarAeroplanta(aero.codigo, $event.target.checked)">
                      <label class="form-check-label text-nowrap" for="{{ 'dropdownCheck' + aero.nombre}}">
                        {{aero.nombre}}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>



      </div>
    </div>
    <br>
    <div class="card-footer d-flex justify-content-center">
      <span class="border">
        <button [disabled]="!this.activarGuardar()" (click)="this.guardarNotificacion()" class="btn btn-primary" type="button"
         >
          <span>Guardar</span>
          <!-- <div class="d-flex align-items-center">
              <div class="spinner-border text-light spinner-border-sm" role="status"></div>
              <span>Guardando...</span>
            </div> -->
        </button>
      </span>
      <span class="border">
        <button type="button" class="btn btn-secondary" (click)="activeModal.dismissAll()">
          Cancelar
        </button>
      </span>
    </div>
  </form>
</div>
