import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';

@Component({
  selector: 'app-abm-index',
  templateUrl: './abm-index.component.html',
  styleUrls: ['./abm-index.component.css']
})
export class AbmIndexComponent implements OnInit {

  TipoUsuario = TipoUsuario;

  constructor(public authService: AuthService) { }

  ngOnInit() {
  }

}
