<form>

  <div *ngIf="ejecucion">
    <app-detalle-ejecucion *ngIf="this.ejecucionSeleccionada != null" [tipoReporte]="tipoReporte" [ejecucionSeleccionada]="ejecucionSeleccionada" (cancelado)="volviendo()">
    </app-detalle-ejecucion>
  </div>

  <nav class="navbar navbar-light bg-light navbar-expand-lg mb-3" *ngIf="!ejecucion">
    <form class="form-inline flex-fill" id="formularioFiltros">
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <ul class="navbar-nav me-auto">
        <!--DESDE-->
        <div style="width: auto" class="input-group me-2">
          <li class="nav-item">
            <input type="date" [ngModel]="modelDesde" class="fechaFormControlDesdeHasta" placeholder="aaaa-mm-dd"
              (ngModelChange)="modelDesde = $event" name="dt" ngbDatepicker #d="ngbDatepicker" value="modelDesde"
              ngbTooltip="Seleccione una fecha de inicio" class="form-control form-control-rounded"
              #searchDate readonly>
          </li>
          <li class="nav-item me-2">
            <div class="input-group-append">
              <button id="botonDesde" type="button" class="input-group-btn btn btn-secondary" (click)="d.toggle()">
                <i class="fa fa-calendar" aria-hidden="true"></i></button>
            </div>
          </li>
        </div>
        <!--HASTA-->
        <div style="width: auto" class="input-group me-2">
          <li class="nav-item">
            <input type="date" [ngModel]="modelHasta" class="fechaFormControlDesdeHasta" placeholder="aaaa-mm-dd"
              (ngModelChange)="modelHasta = $event" name="dt" ngbDatepicker #h="ngbDatepicker" value="modelHasta"
              ngbTooltip="Seleccione una fecha de fin" class="form-control form-control-rounded"
              #searchDate readonly>
          </li>
          <li class="nav-item me-2">
            <div class="input-group-append">
              <button id="botonHasta" type="button" class="input-group-btn btn btn-secondary" (click)="h.toggle()">
                <i class="fa fa-calendar" aria-hidden="true"></i></button>
            </div>
          </li>
          <!--BOTON BUSCAR-->
          <li class="nav-item">
            <button (click)="buscarDesdeHasta()" [disabled]="fechaDesdeMayorQueHasta()==true"
              class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2" type="submit"><i class="fa fa-search"
                aria-hidden="true"></i></button>
          </li>
        </div>
      </ul>
    </form>
  </nav>
  <ngb-alert type="danger" [dismissible]="false" *ngIf="fechaDesdeMayorQueHasta()==true">
    La fecha inicial debe ser anterior a la fecha final.
  </ngb-alert>
  <ngb-alert [dismissible]="false"
    *ngIf="!fechaDesdeMayorQueHasta() && !obteniendoEjecuciones && ejecuciones!=null && ejecuciones.length==0">
    No existen ejecuciones para la busqueda seleccionada</ngb-alert>

  <form id="formularioTablaEjecuciones" *ngIf="!ejecucion">
    <div class="spinner-container col-md-12" *ngIf="obteniendoEjecuciones">
      <ngx-spinner name="spinnerLista" [ngStyle]="{'height': '400px', 'z-index':'0'}"
        [ngClass]="{'list-group-item list-group-item-action': obteniendoEjecuciones}" bdOpacity=0.3bd
        Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
        <p style="color: white"> O b t e n i e n d o E j e c u c i o n e s . . . </p>
      </ngx-spinner>
    </div>
    <div id="tablaEjecuciones" *ngIf="!obteniendoEjecuciones && ejecuciones != null && ejecuciones.length > 0">
      <table id="tablaEjecucionesDesdeHasta" class="table table-striped table-hover">
        <thead class="text-center">
          <tr>
            <th>Número ejecución</th>
            <th>Tipo ejecución</th>
            <th>Re-Ejecución</th>
            <th>Fecha</th>
            <th *ngIf="!this.tipoReporte">Imprimibles</th>
          </tr>
        </thead>
        <tbody class="text-center">
          <tr style="cursor: pointer;" *ngFor="let e of ejecuciones; let i = index">
            <td (click)="verDetalle(i)">{{e.numeroEjecucion}}</td>
            <td (click)="verDetalle(i)">{{e.tipoEjecucion == 0? 'Manual' : 'Automatica'}}</td>
            <td (click)="verDetalle(i)" *ngIf="e.reEjecucion"><i class="fa fa-check"></i></td>
            <td (click)="verDetalle(i)" *ngIf="!e.reEjecucion"><i class="fa fa-times"></i></td>
            <td (click)="verDetalle(i)">{{e.fechaHora | date: 'dd/MM/yyy HH:mm a'}}</td>
            <td *ngIf="!this.tipoReporte"><button class="btn btn-outline-primary" *ngIf="e.linkDocumentos != null"><a class="fa fa-download" [href]="e.linkDocumentos"> Descargar</a></button></td>
          </tr>
        </tbody>
      </table>
    </div>
  </form>
</form> 