import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UsuarioService } from '../account/usuario.service';
import { HandleError, HttpErrorHandler } from '../http-error-handler.service';
import { isNullOrUndefined } from '../shared/compat-tools';
import { Area } from '../shared/models/abm/Area';
import { SubArea } from '../shared/models/abm/SubArea';
import { Actividad } from '../shared/models/cliente/Actividad';
import { AutorizacionDTO } from '../shared/models/cliente/AutorizacionDTO';
import { Cliente } from '../shared/models/cliente/Cliente';
import { ClienteConCuentasEncabezadoDTO } from '../shared/models/cliente/ClienteConCuentaEncabezado';
import { ClienteEncabezado } from '../shared/models/cliente/ClienteEncabezado';
import { ConfigClienteProvincia } from '../shared/models/cliente/ConfigClienteProvincia';
import { Contacto } from '../shared/models/cliente/Contacto';
import { Cuenta } from '../shared/models/cliente/Cuenta';
import { CuentaRespuesta } from '../shared/models/cliente/CuentaRespuesta';
import { DireccionFiscal } from '../shared/models/cliente/DireccionFiscal';
import { GrupoCliente } from '../shared/models/cliente/GrupoCliente';
import { ListadoModificacionDTO } from '../shared/models/cliente/ListadoModificacionDTO';
import { ModificacionClienteDTO } from '../shared/models/cliente/ModificacionClienteDTO';
import { ModificacionEstadoDTO } from '../shared/models/cliente/ModificacionEstadoDTO';
import { PlazoPagoCliente } from '../shared/models/cliente/PlazoPagoCliente';
import { PreautorizacionDTO } from '../shared/models/cliente/PreautorizacionDTO';
import { Provincia } from '../shared/models/cliente/Provincia';
import { Ubicacion } from '../shared/models/cliente/Ubicacion';
import { ViaDePago } from '../shared/models/cliente/ViaDePago';
import { CuentaCliente } from '../shared/models/despacho/CuentaCliente';
import { Pais } from '../shared/models/despacho/Pais';
import { Ciudad } from '../shared/models/facturacion/Ciudad';
import { CondicionIVA } from '../shared/models/facturacion/CondicionIVA';
import { EnvioDeMailGenerico } from '../shared/models/notificaciones/EnvioDeMailGenerico';
import { SharedService } from '../shared/shared.service';
@Injectable({
  providedIn: 'root'
})
export class ClienteService {

  baseURL = environment.apiServer.clientesBaseUrl;
  notiBase = environment.apiServer.notificacionesBaseUrl;
  facturacionUrl = environment.apiServer.facturacionBaseUrl;
  private clienteURL = this.baseURL + '/ClientesV1';  
  private cuentasURL = this.baseURL + '/CuentasV1';
  private autorizacionesURL = this.baseURL + '/AutorizacionesV1';
  private modificacioensURL = this.baseURL + '/ModificacionesV1';
  private padronesURL = this.facturacionUrl + '/PadronesV1';
  private clienteV2URL = this.baseURL + '/ClientesV2';

  private handleError: HandleError;

  baseUrl2 = environment.apiServer.baseUrl;
  private aerovalesUrl = this.baseUrl2 + '/ConfiguracionesV1/';
  
  private lastUpdated: Date;
  clientes: CuentaCliente[];

  constructor(
    private httpClient: HttpClient, 
    private usuarioService: UsuarioService,
    private httpErrorHandler: HttpErrorHandler,
    private sharedService: SharedService) {
    this.handleError = httpErrorHandler.createHandleError('Clientes');
  }

  getClienteConCuentas(): Promise<ClienteConCuentasEncabezadoDTO[]> {
    return this.httpClient.get<ClienteConCuentasEncabezadoDTO[]>(this.clienteURL + '/Clientes/ConCuentas').toPromise();   
  }

  getClientesActivos(): Observable<CuentaCliente[]> {
    return this.httpClient.get<CuentaCliente[]>(this.clienteURL, {
      params: {
        lastUpdated: this.lastUpdated ? this.sharedService.dateToFormattedString(this.lastUpdated) : ''
      }
    })
      .pipe(
        map(nuevos => {
          if (this.lastUpdated) {
            if (nuevos) {
              const ctasNuevos = nuevos.map(c => c.ctA_SGC);
              let filtered = this.clientes.filter(c => isNullOrUndefined(ctasNuevos.find(ctaNuevo => ctaNuevo == c.ctA_SGC)));
              this.clientes = filtered;
              this.clientes.push(...nuevos);
            }
          } else {
            this.clientes = nuevos;
          }

          this.lastUpdated = new Date();
          
          var aeroplantaSoloPad = this.usuarioService.getAeroplantaActual().soloPAD;

          return this.clientes.filter(c => c.activo && (aeroplantaSoloPad ? c.soloPAD : true));
        })
      );
  }

  activarRanchos(ctasSGC: string[]): Observable<string[]> {
    return this.httpClient.put<string[]>(this.clienteURL + 'ActivarRanchos', ctasSGC)
      .pipe(
       catchError(this.handleError<string[]>('ActivarRanchos', null))
      );
  }

  getClientesEncabezado(): Observable<ClienteEncabezado[]> {
    return this.httpClient.get<ClienteEncabezado[]>(this.clienteURL + '/Clientes/Encabezados').pipe(
      tap(_ => console.log(`Cliente`)),
      catchError(this.handleError('al obtener encabezados', null))
    )
  };  

  crearCliente(cliente: Cliente): Observable<Cliente> {
    return this.httpClient.post<Cliente>(this.clienteURL + '/Clientes', cliente);
  }

  crearClienteContado(clienteNuevo: CuentaCliente): Observable<CuentaCliente> {
    let url = "/Clientes/Contado";
    this.esElectrica ? url = url +  "?esElectrica=true" : null;
    return this.httpClient.post<CuentaCliente>(this.clienteURL + url, clienteNuevo)
      .pipe(
        tap((clienteNuevo: CuentaCliente) => console.log(`added cliente w/ id=${clienteNuevo.ctA_SGC}`)),
      );
  }

  get esElectrica() {
    return environment.electrica;
  }

  modificarCliente(cliente: Cliente, tipoDocumentoViejo): Observable<Cliente> {
    let url = '/Clientes/' + tipoDocumentoViejo + '-' + cliente.numeroDocumento;
    this.esElectrica ? url = url +  "?esElectrica=true" : null;
    return this.httpClient.put<Cliente>(this.clienteURL + url, cliente);
  }

  getClientePorDocumento(tipoDoc:number, numeroDoc:string): Observable<Cliente> {
    return this.httpClient.get<Cliente>(this.clienteURL + '/Clientes/numeroDocumento?tipoDocumento=' + tipoDoc + '&numeroDocumento=' + numeroDoc);
  }

  crearResponsable(resp: Contacto, cliente: Cliente): Observable<Contacto> {
    return this.httpClient.post<Contacto>(this.clienteURL + '/Responsables/' + cliente.tipoDocumento + '-' + cliente.numeroDocumento, resp);
  }

  modificarResponsable(resp: Contacto): Observable<Contacto> {
    return this.httpClient.put<Contacto>(this.clienteURL + '/Responsables', resp);
  }

  getActividades(): Observable<Actividad[]> {
    return this.httpClient.get<Actividad[]>(this.clienteURL + '/Actividades');
  }

  getGrupoCliente(): Observable<GrupoCliente[]> {
    return this.httpClient.get<GrupoCliente[]>(this.clienteURL + '/GruposCliente');
  }

  getCondicionesIVA(): Observable<CondicionIVA[]> {
    return this.httpClient.get<CondicionIVA[]>(this.clienteURL + '/CondicionesIVA');
  }

  postDireccion(direccion: DireccionFiscal, cliente: Cliente): Observable<DireccionFiscal> {
    return this.httpClient.post<DireccionFiscal>(this.clienteURL + '/DireccionFiscal/' + cliente.tipoDocumento + '-' + cliente.numeroDocumento, direccion);
  }
  putDireccion(direccion: DireccionFiscal): Observable<DireccionFiscal> {
    return this.httpClient.put<DireccionFiscal>(this.clienteURL + '/DireccionFiscal', direccion);
  }
  postCondicionIIBB(condicion: ConfigClienteProvincia, cliente: Cliente): Observable<ConfigClienteProvincia> {
    return this.httpClient.post<ConfigClienteProvincia>(this.clienteURL + '/CondicionIIBB/' + cliente.tipoDocumento + '-' + cliente.numeroDocumento, condicion);
  }
  putCondicionIIBB(direccion: ConfigClienteProvincia): Observable<ConfigClienteProvincia> {
    return this.httpClient.put<ConfigClienteProvincia>(this.clienteURL + '/DireccionFiscal', direccion);
  }
  putDireccionEntrega(direccion: Ubicacion): Observable<Ubicacion> {
    return this.httpClient.put<Ubicacion>(this.clienteURL + '/Direcciones', direccion);
  }
  postDireccionEntrega(direccion: Ubicacion, cliente: Cliente): Observable<Ubicacion> {
    return this.httpClient.post<Ubicacion>(this.clienteURL + '/Direcciones/' + cliente.tipoDocumento + '-' + cliente.numeroDocumento, direccion);
  }
  getSubAreas(): Observable<SubArea[]> {
    return this.httpClient.get<SubArea[]>(this.clienteURL + '/SubAreas')
  }
  getAreas(): Observable<Area[]> {
    return this.httpClient.get<Area[]>(this.clienteURL + '/Areas')
  }
  getViasDePago(): Observable<ViaDePago[]> {
    return this.httpClient.get<ViaDePago[]>(this.clienteURL + '/ViasDePago')
  }

  getCondicionesPago(): Observable<PlazoPagoCliente[]> {
    return this.httpClient.get<PlazoPagoCliente[]>(this.cuentasURL + '/CondicionesPago')
  }
  
  getProvinciasPorPais2(codigo: string): Observable<Provincia[]> {
    return this.httpClient.get<Provincia[]>(this.aerovalesUrl + codigo + '/Provincias')
      .pipe(
        map(a => a.map<Provincia>(b => Object.assign(new Provincia(), b))),
        catchError(this.handleError('getProvinciasPorPais', []))
      );
  }

  // se deja de utilizar 07/09/2022
  /*
  validarAFIP(aeroplantaCod: string, cuit: string): Observable<Cliente> {
    return this.httpClient.get<Cliente>(this.clienteURL + '/ValidarAFIP/' + cuit + '?codigoAeroplanta=' + aeroplantaCod);
  }
  */
  
  validarClienteCenit(aeroplantaCod: string, cuit: string, puedoConsultarAFIP:boolean, esExtranjero:boolean): Observable<Cliente> {
    return this.httpClient.get<Cliente>(this.clienteV2URL + '/AltaClientePorCuit/' + cuit + '?codigoAeroplanta=' + aeroplantaCod + '&puedoConsultarAFIP=' + puedoConsultarAFIP + '&esExtranjero=' + esExtranjero);
  }

  /*
  restObtenerCuentaSap(cliente: Cliente): Observable<CuentaRespuesta> {
    return this.httpClient.put<CuentaRespuesta>(this.clienteURL + '/CuentaSAP', cliente); 
  }
  

  obtenerCuentaSap(cliente: Cliente): Observable<CuentaRespuesta> {
    return this.httpClient.put<CuentaRespuesta>(this.clienteV2URL + '/CuentaSAP', cliente); 
  }
*/
  enviarMail(mail: EnvioDeMailGenerico): Observable<any>{
    return this.httpClient.post<any>(this.notiBase + '/NotificacionesV1/Generico', mail, {responseType: 'blob' as 'json'})
  }

  getClientesPorCtaSap(numero: string): Observable<Cliente>{
    return this.httpClient.get<Cliente>(this.cuentasURL + '/Cuentas/'+ numero +'/CtaSgc');
  }

  getClientesEncabezadoConModificacionesPendientes(): Observable<ClienteEncabezado[]> {
    return this.httpClient.get<ClienteEncabezado[]>(this.modificacioensURL).pipe(
      tap(_ => console.log(`getClientesEncabezadoConModificacionesPendientes`)),
      catchError(this.handleError('al obtener getClientesEncabezadoConModificacionesPendientes', null))
    )
  };

  getModificacionesPorCliente(idCliente): Observable<ModificacionClienteDTO> {
    return this.httpClient.get<ModificacionClienteDTO>(this.modificacioensURL + '/' + idCliente).pipe(
      tap(_ => console.log(`getModificacionesPorCliente`)),
      catchError(this.handleError('al obtener getModificacionesPorCliente', null))
    )
  };

  guardarEstadoModificacionesCliente(id, modificaciones: ModificacionEstadoDTO): Observable<ModificacionEstadoDTO> {
    return this.httpClient.put<ModificacionEstadoDTO>(this.clienteURL + "/" + id + "/AprobarRechazarModificaciones" , modificaciones);
  }
      
  getModificacionesParaReporte(fechaDesde, fechaHasta): Observable<ListadoModificacionDTO[]> {
    return this.httpClient.get<ListadoModificacionDTO>(this.modificacioensURL+"/Listado?fechaDesde="+fechaDesde+"&fechaHasta="+fechaHasta).pipe(
      tap(_ => console.log(`getModificacionesParaReporte`)),
      catchError(this.handleError('al obtener getModificacionesParaReporte', null))
    )
  };

  getPercepcionIVA(cuit: string) {
    return this.httpClient.get(this.padronesURL + '/IVA/' + cuit, {responseType: 'text'})
  }

  getAutorizacionesMatriculas(fdesde:string, fhasta:string, estado:number): Promise<AutorizacionDTO[]> {
    if (estado!=null) {
      return this.httpClient.get<AutorizacionDTO[]>(this.autorizacionesURL + "?fechaDesde="+fdesde+"&fechaHasta="+fhasta+"&estado="+estado).toPromise();
    }else{
      return this.httpClient.get<AutorizacionDTO[]>(this.autorizacionesURL + "?fechaDesde="+fdesde+"&fechaHasta="+fhasta).toPromise();
    }    
  }

  getAutorizacionesV1(idDeDespacho:string): Promise<AutorizacionDTO> {
    return this.httpClient.get<AutorizacionDTO>(this.autorizacionesURL + "/" + idDeDespacho).toPromise();
  }

  putAutorizacionesV1(idDeDespacho:string, autorizacion:AutorizacionDTO): Promise<AutorizacionDTO> {
    return this.httpClient.put<AutorizacionDTO>(this.autorizacionesURL + "/" + idDeDespacho, autorizacion).toPromise();
  }
  
  postAutorizacionesV1(autorizacion:AutorizacionDTO): Promise<AutorizacionDTO> {
    return this.httpClient.post<AutorizacionDTO>(this.autorizacionesURL, autorizacion).toPromise();
  }

  obtenerStringSolicitador(){
    return '{"Email": "'+this.usuarioService.getEmailUsuario()+'" ,' + '"nombresCompletos": "'+this.usuarioService.getNombresCompletosUsuario()+'" ,' + '"dni": "'+this.usuarioService.getDniUsuario()+'" ,' + '"Id": "'+this.usuarioService.getIdUsuario()+'"}';
  }

  getPreautorizaciones(fechaDesde:string, fechaHasta:string, codAero:string):Promise<PreautorizacionDTO[]> {
    if (codAero && codAero!='null')
      return this.httpClient.get<PreautorizacionDTO[]>(this.autorizacionesURL + "/Preautorizaciones?fechaDesde="+fechaDesde+"&fechaHasta="+fechaHasta+"&codigoAeroplanta="+codAero).toPromise()
    else  
      return this.httpClient.get<PreautorizacionDTO[]>(this.autorizacionesURL + "/Preautorizaciones?fechaDesde="+fechaDesde+"&fechaHasta="+fechaHasta).toPromise()
  }

  postPreAutorizaciones(autorizacion:PreautorizacionDTO): Promise<PreautorizacionDTO> {
    return this.httpClient.post<PreautorizacionDTO>(this.autorizacionesURL + "/Preautorizaciones", autorizacion).toPromise();
  }

  anularPreAutorizaciones(id:string): Promise<PreautorizacionDTO> {
    return this.httpClient.put<PreautorizacionDTO>(this.autorizacionesURL + "/Preautorizaciones/Anular/" + id, null).toPromise();
  }
}