<div class="container" *ngIf="obteniendoDatos">
  <div class="mb-3" style="height: 10cm;">
    <ngx-spinner name="spinnerGrafico" [ngStyle]="{'height': '400px', 'z-index':'0'}" bdOpacity=0.3bd
      Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
      <p style="color: white">C a r g a n d o . . . </p>
    </ngx-spinner>
  </div>
</div>

<div class="col-12" id="contenedor" *ngIf="!obteniendoDatos">
    <div *ngFor="let entry of mapEntrys; let i = index">
      <label class="ms-1 titulo-tabla"><b>Comparativas {{entry[0]}} </b></label>
      <table class="table table-striped table-hover">
        <thead class="text-center">
          <th>Fecha</th>
          <th>Existencia anterior</th>
          <th>Entradas</th>
          <th>Salidas</th>
          <th>Existencia en libros</th>
          <th>Existencia real</th>
          <th>Deficit/Superavit</th>               
        </thead>
        <tbody class="text-center">
          <tr *ngFor="let m of entry[1]">
            <td style="cursor: pointer;" (click)="abrirComparativaEspecifica(m)">{{m.fechaHoraCierre | date: "dd/MM/yyy HH:mm a"}}</td>
            <td>{{m.existenciaAnterior | number: '1.0-0'}}</td>
            <td>{{m.entradas | number: '1.0-0'}}</td>
            <td>{{m.salidas | number: '1.0-0'}}</td>
            <td>{{m.existenciaLibros | number: '1.0-0'}}</td>
            <td>{{m.existenciaReal | number: '1.0-0'}}</td>
            <td>{{m.diferencia | number: '1.0-0'}}</td>
          </tr>
          <tr style="font-weight: bold;">
            <td>Total</td>
            <td>{{arrayTotales[i][0]}}</td>
            <td>{{arrayTotales[i][1]}}</td>
            <td>{{arrayTotales[i][2]}}</td>
            <td>{{arrayTotales[i][3]}}</td>
            <td>{{arrayTotales[i][4]}}</td>
            <td>{{arrayTotales[i][5]}}</td>
          </tr>
        </tbody>
      </table>
      <button class="btn btn-outline-success" (click)="exportarComparativas(entry[0], entry[1])"><i class="fa fa-file-excel-o" aria-hidden="true"></i>
        Exportar información {{entry[0]}}
      </button>

    </div>
  </div>