import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormArray, FormControl } from '@angular/forms';
import { Aeroplanta } from 'src/app/shared/models/despacho/Aeroplanta';
import { Observable } from 'rxjs';
import { NgbDateNativeAdapter, NgbTypeaheadConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { distinctUntilChanged, debounceTime, map } from 'rxjs/operators';
import { UsuarioService } from 'src/app/account/usuario.service';
import { MessageService } from 'src/app/shell/message.service';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { PreciosService } from 'src/app/precios/precios.service';


@Component({
  selector: 'app-configuracion-inicial',
  templateUrl: './configuracion-inicial.component.html',
  styleUrls: ['./configuracion-inicial.component.css']
})
export class ConfiguracionInicialComponent implements OnInit {

  aeroplantas: Aeroplanta[] = [];
  aeroplantasSeleccionadas: Aeroplanta[] = [];
  configuracionForm: UntypedFormGroup;
  configuracionInicialProcesada: boolean = false;
  guardandoConfiguracion: boolean = false;
  username: string;
  dniUsuario: string;
  noAeroplantas: string[]=['ENO','CUT','DTO','TDL','JNI','SCL','SCE'];

  constructor(
    private precioService: PreciosService,
    private formBuilder: UntypedFormBuilder,
    private ngbDateAdapter: NgbDateNativeAdapter,
    private ng: NgbTypeaheadConfig,
    private usuarioService: UsuarioService,
    private messageService: MessageService,
    private authService: AuthService) { }

  ngOnInit() {
    this.precioService.obtenerTodasLasAeroplantas().subscribe(
      (aeroplantas) => this.aeroplantas = aeroplantas.filter(a => !this.noAeroplantas.includes(a.codigoAeroplanta))
    );

    this.configuracionForm = this.formBuilder.group({
      dniFormControl: ['', [Validators.required]],
      aeroplantaFormControl: [''],
      aeroplantasSeleccionadasFormControlArray: this.formBuilder.array([], Validators.required)
    });

    this.username = this.usuarioService.getUsername();
    this.dniUsuario = this.usuarioService.getDniOperador();
  }

  searchAeroplanta = (text$: Observable<string>) => text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    map(term => (this.aeroplantas == null || term == null || term.length < 2) ? [] : (this.aeroplantas.filter(a =>
      a.nombre.concat(a.codigoAeroplanta).toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10)) )

  )

  aeroplantaFormatter = (result: Aeroplanta) => result.codigoAeroplanta != null && result.nombre != null ? `${result.codigoAeroplanta} - ${result.nombre}` : "";

  onAeroplantaSelectItem(event, input) {
    event.preventDefault();
    if(this.aeroplantasSeleccionadas.some( a => a == event.item )) {
      this.messageService.showErrorMessage("La aeroplanta seleccionada ya se encuentra en la lista");
      this.aeroplantaFormControl.setValue("");
      input.value = '';
      return;
    }

    this.aeroplantasSeleccionadas.push(event.item);

    if (this.aeroplantasSeleccionadasFormControlArray.value != null) {
      this.aeroplantasSeleccionadasFormControlArray.push(this.nuevoItem(event.item))
    }

    this.aeroplantaFormControl.setValue("");
    input.value = '';
  }

  onChangeAeroplanta(){
    if (typeof (this.aeroplantaFormControl.value) === 'string') {
      this.aeroplantaFormControl.setValue(null);
    }
  }

  nuevoItem(item: Aeroplanta): UntypedFormGroup {
    var a = this.formBuilder.group({
      nombreAeroplanta: [item.nombre, Validators.required],
      idAeroplanta: [item.id, Validators.required],
      codigoAeroplanta: [item.codigoAeroplanta, Validators.required]
    })

    return a;
  }

  onAeroplantaEliminada(i: number, aeroplanta: Aeroplanta) {
    this.aeroplantasSeleccionadasFormControlArray.removeAt(i);
    this.aeroplantasSeleccionadas = this.aeroplantasSeleccionadas.filter( a => a.codigoAeroplanta != aeroplanta.codigoAeroplanta);
    console.log("");
  }

  guardarConfiguracionInicial() {
    this.guardandoConfiguracion = true;

    var dni = this.dniFormControl.value;

    this.usuarioService.asignarUsuarioAeroplantas(this.usuarioService.getIdUsuario(), dni, this.aeroplantasSeleccionadas, false)
      .subscribe(UsuarioAccion => {
        this.messageService.showSuccessMessage("Se realizo la asignación correctamente.");
        this.configuracionInicialProcesada = true;
      }, (err) => {
        this.messageService.showErrorMessage("Error al realizar la asignación. " + err.description);
        console.log(err);
        this.guardandoConfiguracion = false;
      });
  }

  onCerrarSesionClick() {
    this.authService.signout();
    location.assign("#");
  }

  clickTodasLasAeroplantas() {
    for (let i = 0; i < this.aeroplantas.length; i++) {
      const element = this.aeroplantas[i];

      this.aeroplantasSeleccionadas.push(element);

      if (this.aeroplantasSeleccionadasFormControlArray.value != null) {
        this.aeroplantasSeleccionadasFormControlArray.push(this.nuevoItem(element))
      }
    }
  }

  get aeroplantaFormControl() {
    return this.configuracionForm.controls.aeroplantaFormControl;
  }

  get aeroplantasSeleccionadasFormControlArray(): UntypedFormArray {
    return this.configuracionForm.controls.aeroplantasSeleccionadasFormControlArray as UntypedFormArray;
  }

  get dniFormControl() {
    return this.configuracionForm.controls.dniFormControl;
  }
}
