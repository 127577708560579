import { TipoCliente } from '../../enums/tipoCliente';

export class CondicionIVA{
    id: string;
    descripcion: string;
    abreviatura: string;
    liquida: boolean;
    discrimina: boolean;
    percibeIVA: boolean;
    activa: boolean;
    codigoAFIP: string;
    tipoCliente: TipoCliente;
  static ConsumidorFinal: number;
}