export const environment = {
    name: 'dev_electrica',  
    production: true,
    electrica: true,    
    logging: {
      console: true,
      appInsights: false
    },
    apiServer: {
      baseUrl: 'https://despacho-dev.electrica.ar/api',
      ranchosBaseUrl: 'https://ranchos-dev.electrica.ar/api',
      tradersBaseUrl: 'https://traders-dev.electrica.ar/api',
      facturacionBaseUrl: 'https://facturacion-dev.electrica.ar/api',
      stockBaseUrl: 'https://stock-dev.electrica.ar/api',
      reportesUrl: 'https://reportes-dev.electrica.ar/api',
      productosUrl:'https://productos-dev.electrica.ar/api',
      clientesBaseUrl:'https://clientes-dev.electrica.ar/api',
      usuariosBaseUrl:'https://usuarios-dev.electrica.ar/api',
      notificacionesBaseUrl: 'https://notificaciones-dev.electrica.ar/api',
      cierreTurnoBaseUrl: 'https://cierreturno-dev.electrica.ar/api',
    },
    signalR: {
      activarFuncionalidad: true,
      signalRUrl: 'https://comunicaciones-dev.electrica.ar/notify'
    },
    grafana: {
      preciosUtilizados: 'https://dev.electrica.ar/grafana/d/j2SbesSnz/precios-utilizados-dev?orgId=1&from=1600190535585&to=1631726535586&theme=light',
      reporteMovimientosProductos:'https://dev.electrica.ar/grafana/d/ZVfVF4A7z/reporte-movimiento-productos-stock-dev?orgId=1'
    },
    frontdoor: {
      timeout: 240000
    },
    
    authentication: {
      authority: 'https://login.microsoftonline.com/038018c3-616c-4b46-ad9b-aa9007f701b5/v2.0',
      client_id: '49d79761-d1f9-427b-86b2-17025bcdc3fd',
      client_secret: 'avY8Q~HsAVn8hCoFR8.XNZdk3DSWkipRH3s2McmE',
      redirect_uri: 'https://dev.electrica.ar/auth-callback',
      post_logout_redirect_uri: 'http://dev.electrica.ar/',
      response_type: 'code',
      loadUserInfo: false,
      scope: 'openid profile email api://49d79761-d1f9-427b-86b2-17025bcdc3fd/PADFMETEST',
      automaticSilentRenew: true,
      silent_redirect_uri: 'https://dev.electrica.ar/silent-refresh.html',
      userinfoEndpoint: 'https://graph.microsoft.com/oidc/userinfo',
      registrationEndpoint: 'https://login.microsoftonline.com/038018c3-616c-4b46-ad9b-aa9007f701b5/v2.0',
      includeIdTokenInSilentRenew: false      
    }
}