<div class="col-12" id="contenedor">
    <div>
      <label class="ms-1"><b>Ultimas mediciones ingresadas</b> Cierre seleccionado: <span *ngIf="mediciones?.fechahoraCierre != null">{{mediciones.fechahoraCierre | date: "dd/MM/yyy HH:mm a" }}</span></label>
      <!--CONTENEDORES-->
      <table class="table table-striped table-hover">
        <thead class="text-center">
          <th>Contenedor</th>
          <th>Cod. Producto</th>
          <th>Nombre Producto</th>
          <th>Volumen 15°</th>
          <th>Volumen natural</th>
          <th>Temperatura</th>
          <th>Densidad</th>
          <th>Medición</th>
        </thead>
        <tbody class="text-center">
          <tr *ngFor="let m of mediciones?.medicionesContenedores; let i = index">
            <td>{{m.nombreContenedor}}</td>
            <td>{{m.codigoProducto}}</td>
            <td>{{m.nombreProducto}}</td>
            <td>{{m.volumen15 | number: '1.0-0'}}</td>
            <td>{{m.volumenNatural | number: '1.0-0'}}</td>
            <td>{{m.temperatura | number: '1.0-2'}}</td>
            <td>{{m.densidad | number: '1.3-3'}}</td>
            <td>
              <span *ngIf="m.medicion != null">{{m.medicion | number: '1.0-0'}}
              </span><span *ngIf="m.medicion == null">--</span>
            </td>
          </tr>
        </tbody>
      </table>
      <button class="btn btn-outline-success" (click)="exportarContenedores()"><i class="fa fa-file-excel-o" aria-hidden="true"></i>
        Exportar información contenedores
      </button>

      <!--AEROESPECIALIDADES-->
      <table class="table table-striped table-hover">
        <thead class="text-center">
          <th>Contenedor</th>
          <th>Cod. Producto</th>
          <th>Nombre Producto</th>
          <th>Cantidad</th>
        </thead>
        <tbody class="text-center">
          <tr *ngFor="let m of mediciones?.medicionesAeroespecialidades; let i = index">
            <td>{{m.nombreContenedor}}</td>
            <td>{{m.codigoProducto}}</td>
            <td>{{m.nombreProducto}}</td>
            <td>{{m.cantidad | number: '1.0-0'}}</td>            
          </tr>
        </tbody>
      </table>
      <button class="btn btn-outline-success" (click)="exportarAeroespecialidades()"><i class="fa fa-file-excel-o" aria-hidden="true"></i>
        Exportar información aeroespecialidades
      </button>
    </div>
  </div>