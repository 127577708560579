<div class="modal-header">
    <h4 class="modal-title">Generar informe</h4>
</div>
<div class="modal-body">
    Esta acción no se puede deshacer. Compruebe que los datos de la resolución sean correctos.
</div>
<div class="modal-footer">
    <button (click)="generarInforme()" [disabled]="grabando" class="btn btn-primary">
        <div *ngIf="!grabando">Aceptar</div>
        <div *ngIf="grabando" class="d-flex align-items-center">
            <div class="spinner-border text-dark spinner-border-sm" role="status"></div>
            <span>Generando...</span>
        </div>
    </button>
    <button (click)="this.activeModal.close(true)" [disabled]="grabando" class="btn btn-secondary">Cancelar</button>
</div>