import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ClienteService } from 'src/app/cliente/cliente.service';
import { ClienteEncabezado } from 'src/app/shared/models/cliente/ClienteEncabezado';
import { AerolineaDestinoDTO } from 'src/app/shared/models/despacho/AerolineaDestinoDTO';
import { AerolineaDTO } from 'src/app/shared/models/despacho/AerolineaDTO';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { AerolineasTamsAbmComponent } from '../aerolineas-tams-abm/aerolineas-tams-abm.component';

@Component({
  selector: 'app-aerolineas-tams-lista',
  templateUrl: './aerolineas-tams-lista.component.html',
  styleUrls: ['./aerolineas-tams-lista.component.scss']
})
export class AerolineasTamsListaComponent implements OnInit {


  listaClientes: ClienteEncabezado[];
  listaClientesFiltrados: ClienteEncabezado[];
  clienteSeleccionado: ClienteEncabezado;

  buscadorFormControl = new UntypedFormControl();
  cargandoLista: boolean;

  constructor(private spinner: NgxSpinnerService,
    private clienteService: ClienteService,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    this.cargarClientes()
  }

  cargarClientes() {
    this.mostrarSpinner();

    this.clienteService.getClientesEncabezado()
    .subscribe(result => {
      this.listaClientes = result;
      this.listaClientesFiltrados = result;
      this.ocultarSpinner();      
    }, (err: ErrorModel) => {    
      console.log(err);
      this.ocultarSpinner();
    });
  }

  mostrarSpinner() {
    this.cargandoLista = true;
    this.spinner.show('spinnerGrafico')
  }

  ocultarSpinner() {
    this.cargandoLista = false;
    this.spinner.show('spinnerGrafico')
  }

  usuarioSeleccionoCliente(c: ClienteEncabezado){
    this.clienteSeleccionado = c;
  }

  filtrarClientes() {
    let textoBuscado = this.buscador.value;

    this.listaClientesFiltrados = this.listaClientes.filter(c => {
      let agregar = true;
      if (textoBuscado && textoBuscado.length>2) {
        agregar = c.razonSocial.toLowerCase().indexOf(textoBuscado.toLowerCase()) > -1;
      }
      return agregar;
    });
  }

  get buscador() {
    return this.buscadorFormControl;
  }
}
