import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of, throwError } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CuentaVehiculo } from '../shared/models/abm/CuentaVehiculo';
import { VehiculoDTO } from '../shared/models/cliente/VehiculoDTO';

@Injectable({
  providedIn: 'root'
})
export class AbmMatriculasService {

  msClientes = environment.apiServer.clientesBaseUrl;
  
  constructor(private http: HttpClient) { }

  getCuentasVehiculos(tipoDocumento: number, numeroDocumento: string): Promise<CuentaVehiculo[]> { 
    return this.http.get<CuentaVehiculo[]>(this.msClientes + `/ClientesV2/CuentasVehiculos?tipoDocumento=${tipoDocumento}&numeroDocumento=${numeroDocumento}`).toPromise();
  }
  
  getCuentasActicasVehiculos(tipoDocumento: number, numeroDocumento: string): Promise<CuentaVehiculo[]> { 
    return this.http.get<CuentaVehiculo[]>(this.msClientes + `/ClientesV2/CuentasActivasVehiculos?tipoDocumento=${tipoDocumento}&numeroDocumento=${numeroDocumento}`).toPromise();
  }

  getVehiculoPorMatricula(matricula: string): Observable<VehiculoDTO>{
    return this.http.get<VehiculoDTO>(this.msClientes + `/VehiculosV1/${matricula}`);
  }

  crearVehiculo(vehiculo: VehiculoDTO){
    return this.http.post<VehiculoDTO>(this.msClientes + `/VehiculosV1`, vehiculo);
  }

  modificarVehiculo(matricula:string, vehiculo: VehiculoDTO){
    return this.http.put<VehiculoDTO>(this.msClientes + `/VehiculosV1/${matricula}`, vehiculo);
  }

  modificarCuentaVehiculo(cuentaVehiculo: CuentaVehiculo): Observable<CuentaVehiculo>{
    return this.http.put<CuentaVehiculo>(this.msClientes + `/CuentasV1/${cuentaVehiculo.ctA_SGC}/Vehiculos`, cuentaVehiculo);
  }

}
