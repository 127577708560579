import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { ClienteService } from '../cliente.service';
import { ClienteEncabezado } from 'src/app/shared/models/cliente/ClienteEncabezado';
import { ModificacionClienteDTO } from 'src/app/shared/models/cliente/ModificacionClienteDTO';
import { EstadoCambio } from 'src/app/shared/enums/EstadoCambio';
import { Cliente } from 'src/app/shared/models/cliente/Cliente';
import { TipoDocumento } from 'src/app/shared/enums/tipoDocumento';
import { ModificacionCuentaDTO } from 'src/app/shared/models/cliente/ModificacionCuentaDTO';
import { Cuenta } from 'src/app/shared/models/cliente/Cuenta';
import { NgxSpinnerService } from 'ngx-spinner';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { Message } from 'src/app/shared/models/Message';
import { MessageService } from 'src/app/shell/message.service';
import { ModificacionEstadoDTO } from 'src/app/shared/models/cliente/ModificacionEstadoDTO';
import { EstadoModificacion } from 'src/app/shared/enums/EstadoModificacion';
import { UtilClientes } from 'src/app/shared/utiles/UtilClientes';
import { Contacto } from 'src/app/shared/models/cliente/Contacto';
import { DireccionFiscal } from 'src/app/shared/models/cliente/DireccionFiscal';
import { Ubicacion } from 'src/app/shared/models/cliente/Ubicacion';
import { Responsable } from 'src/app/shared/models/cliente/Responsable';
import { ModalMotivoRechazoComponent } from './modal-motivo-rechazo/modal-motivo-rechazo.component';
import { Console } from 'console';

@Component({
  selector: 'app-clientes-aprobar-modificaciones',
  templateUrl: './clientes-aprobar-modificaciones.component.html',
  styleUrls: ['./clientes-aprobar-modificaciones.component.css']
})
export class ClientesAprobarModificacionesComponent implements OnInit {

  EstadoCambio = EstadoCambio;
  EstadoModificacion = EstadoModificacion;

  clientes: ClienteEncabezado[] = [];
  clientesFiltrados: ClienteEncabezado[] = [];
  clienteSeleccionado : ClienteEncabezado;

  modificacionesCliente: ModificacionClienteDTO;

  buscadorFormControl = new UntypedFormControl();
  cargandoClientes: boolean;
  cargandoModificacionesClientes: boolean;
  guardandoDatos: boolean;

  seleccionRealizada: { clienteId: string; cuentasId : string[] } = { clienteId: null, cuentasId: [] };

  mostrarModificacionesCliente: boolean = false;
  mostrarModificacionesCuentas: { cuentaId: string, mostrar: boolean } [] = []; 

  constructor(private modalService: NgbModal, 
    private clienteService: ClienteService, 
    private authService: AuthService, 
    private spinner: NgxSpinnerService,
    private messageService: MessageService,
    public utilClientes: UtilClientes) { }

  ngOnInit() {
    this.getClientes();
  }

  getClientes() {
    this.cargandoClientes = true;
    this.spinner.show('spinnerGrafico');

    this.clienteService.getClientesEncabezadoConModificacionesPendientes()
      .subscribe(result => {
        this.cargandoClientes = false;
        this.spinner.hide('spinnerGrafico');
        this.clientes = result;
        this.clientesFiltrados = result;
      }, () => {
        this.cargandoClientes = false;
        this.messageService.showErrorMessage("Error al obtener la lista de modificaciones.");
      });
  }

  filtrarClientes() {
    var texto = this.buscadorFormControl.value;

    this.clientesFiltrados = this.clientes.filter(a => {
      let agregar = true;
      if (texto) {
        agregar = a.razonSocial.toLowerCase().indexOf(texto.toLowerCase()) > -1; // Razón Social
        agregar = agregar || a.numeroDocumento.toLowerCase().indexOf(texto.toLowerCase()) > -1; // CUIT
        if (a.ctaSap != null) {
          agregar = agregar || a.ctaSap.toLowerCase().indexOf(texto.toLowerCase()) > -1; // Nro Cuenta
        }
      }
      return agregar;
    });
  }

  mostrarDetalle(i: number) {
    this.resetearUi();
    this.cargandoModificacionesClientes = true;
    this.spinner.show("spinnerCargaCambios");
    this.clienteSeleccionado = this.clientesFiltrados[i];  
    
    this.clienteService.getModificacionesPorCliente(this.clienteSeleccionado.id)
    .subscribe(result => {
        this.cargandoModificacionesClientes = false;
        this.spinner.hide("spinnerCargaCambios");
        this.modificacionesCliente = result;
        this.iniciarArrayMostrarModificaciones(); 
        this.procesarModificaciones();
      }, () => {
        this.cargandoModificacionesClientes = false;
        this.spinner.hide("spinnerCargaCambios");
        this.messageService.showErrorMessage("Error al obtener los datos de las modificaciones.")
      });
  }

  iniciarArrayMostrarModificaciones(){
    this.mostrarModificacionesCuentas = []; 

    for(let c of this.modificacionesCliente.modificacionesCuenta){
      if(c != null){
        this.mostrarModificacionesCuentas.push({cuentaId: c.id, mostrar: false});
      }      
    } 
  }

  resetearUi(){
    this.seleccionRealizada.clienteId = null;
    this.seleccionRealizada.cuentasId = [];

    this.mostrarModificacionesCliente = false;
    this.mostrarModificacionesCliente = false;
    this.mostrarModificacionesCuentas = [];
  }

  guardarSeleccion(estado: EstadoModificacion){

    if(this.seleccionRealizada.clienteId == null && this.seleccionRealizada.cuentasId.length == 0){
      this.messageService.showErrorMessage("No ha seleccionado nada para " + (estado == EstadoModificacion.Aprobado ? "aprobar" : "rechazar") + ".");
      return;
    }

    if(estado == EstadoModificacion.Rechazado){
      // Pedir motivo texto libre.
      const modalRef = this.modalService.open(ModalMotivoRechazoComponent, { size: 'lg', backdrop: 'static' });
      modalRef.result.then((motivo) => {
        if(motivo == null){
          return;
        }

        this.accionGuardarSeleccion(estado, motivo);
      }).catch((error) => {
        return;
      });
    }else{
      this.accionGuardarSeleccion(estado, null);
    }        
  }

  accionGuardarSeleccion(estado, motivo){
    this.guardandoDatos = true

    var modificaciones = new ModificacionEstadoDTO();
    modificaciones.motivoRechazo = motivo;
    modificaciones.estadoFinal = estado;
    modificaciones.id = this.seleccionRealizada.clienteId;

    var modifCuentas : ModificacionEstadoDTO[] = [];

    for(let id of this.seleccionRealizada.cuentasId){
      var itemCuenta = new ModificacionEstadoDTO();
      itemCuenta.estadoFinal = estado;
      itemCuenta.id = id;

      modifCuentas.push(itemCuenta);
    }

    modificaciones.modificacionesCuentas = modifCuentas;

    this.clienteService.guardarEstadoModificacionesCliente(this.clienteSeleccionado.id, modificaciones)
      .subscribe(rta => {
        this.guardandoDatos = false;   
        this.messageService.showSuccessMessage("Se guardaron los cambios.");   
        this.resetearUi();
        this.getClientes();
        this.clienteSeleccionado = null;
        this.modificacionesCliente = null;
      },
      (err: ErrorModel) => {
        this.messageService.showErrorMessage("Error al guardar datos");
        this.guardandoDatos = false;
      });
  }

  procesarNuevoCliente(estado: EstadoModificacion){

    if(estado == EstadoModificacion.Rechazado){
      // Pedir motivo texto libre.
      const modalRef = this.modalService.open(ModalMotivoRechazoComponent, { size: 'lg', backdrop: 'static' });
      modalRef.result.then((motivo) => {
        if(motivo == null){
          return;
        }

        this.accionProcesarNuevoCliente(estado, motivo);
      }).catch((error) => {
        return;
      });
    }else{
      this.accionProcesarNuevoCliente(estado, null);
    }        
  }

  accionProcesarNuevoCliente(estado, motivo){
    this.guardandoDatos = true

    var modificaciones = new ModificacionEstadoDTO();
    modificaciones.motivoRechazo = motivo;
    modificaciones.estadoFinal = estado;
    modificaciones.id = this.modificacionesCliente.id;

    var modifCuentas : ModificacionEstadoDTO[] = [];

    for(let mc of this.modificacionesCliente.modificacionesCuenta){
      var itemCuenta = new ModificacionEstadoDTO();
      itemCuenta.estadoFinal = estado;
      itemCuenta.id = mc.id;

      modifCuentas.push(itemCuenta);
    }

    modificaciones.modificacionesCuentas = modifCuentas;

    this.clienteService.guardarEstadoModificacionesCliente(this.clienteSeleccionado.id, modificaciones)
      .subscribe(rta => {
        this.guardandoDatos = false;   
        this.messageService.showSuccessMessage("Se guardaron los cambios.");   
        this.resetearUi();
        this.getClientes();
        this.clienteSeleccionado = null;
        this.modificacionesCliente = null;
      },
      (err: ErrorModel) => {
        this.messageService.showErrorMessage("Error al guardar datos");
        this.guardandoDatos = false;
      });
  }

  mostrarOcultarModificacionesCliente(){
    this.mostrarModificacionesCliente = ! this.mostrarModificacionesCliente;
  }

  mostrarOcultarModificacionesCuenta(cuentaId){
    for(let modif of this.mostrarModificacionesCuentas){
      if(modif.cuentaId == cuentaId){
        modif.mostrar = (! modif.mostrar);
      }
    }
  }

  mostrarModificacionesCuenta(cuentaId){
    for(let modif of this.mostrarModificacionesCuentas){
      if(modif.cuentaId == cuentaId){
        return modif.mostrar;
      }
    }

  }

  onClickCliente(idCliente){
    if(this.seleccionRealizada.clienteId == null){
      this.seleccionRealizada.clienteId = idCliente;
    }else{
      this.seleccionRealizada.clienteId = null;
    }
  }

  onClickCuenta(idCuenta){
    if(this.seleccionRealizada.cuentasId.includes(idCuenta)){
      this.seleccionRealizada.cuentasId = this.seleccionRealizada.cuentasId.filter(cId => cId != idCuenta);
    }else{
      this.seleccionRealizada.cuentasId.push(idCuenta);
    }
  }

  obtenerStringCuentasId(){
    return this.seleccionRealizada.cuentasId.join();
  }

  obtenerModificacionesCliente(){
    var modificaciones: { valorViejo: string, valorNuevo: string } [] = []; 

    if(this.modificacionesCliente != null){
      var viejo = this.modificacionesCliente.clienteViejo;
      var nuevo = this.modificacionesCliente.clienteNuevo;

      modificaciones = this.utilClientes.compararClientes(viejo, nuevo);           
    }

    return modificaciones;
  }

  procesarModificaciones(){
    
    if(this.modificacionesCliente != null){

      for(let modifCuenta of this.modificacionesCliente.modificacionesCuenta){

        if(modifCuenta != null){
          var viejo = modifCuenta.cuentaViejo;
          var nuevo = modifCuenta.cuentaNuevo;
  
          modifCuenta.cambios = this.utilClientes.compararCuentas(viejo, nuevo);
        } 
      }      
    }
  }
}
