export enum TipoPadron {
    Ninguno,
    ARBAPercepciones,
    CABA,
    Cordoba,
    LaPlata,
    Misiones,
    ARBAPercepcionesCombustibles,
    PercepcionIVARG17,
    PercepcionIVARG18
}

export enum TipoRegimenARBA {
    General = "G",
    Combustibles = "C"
}
