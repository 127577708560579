<div class="col col-md-12" *ngIf="cargandoDatos">
    <div class="spinner-container">
        <ngx-spinner name="spinnerUpsertGrupo" bdOpacity=0.3bd
            Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
            <p style="color: white">C a r g a n d o . . . </p>
        </ngx-spinner>
    </div>
</div>

<div class="card add-margin" *ngIf="!cargandoDatos">
    <form [formGroup]="grupoForm">
        <div style="align-items: center;" class="card-header d-flex bg-light">
            <h4 class="modal-title flex-grow-1 p-2">Datos del grupo:</h4>
            <button *ngIf="!estaEditandoGrupo()" type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()"></button>
        </div>

        <div class="card-body p-3">

            <!-- titulo -->
            <div class="row">
                <div class="mb-3 row col-12">
                    <label for="tituloFormControl" class="col-2 col-form-label">*Título:</label>
                    <div class="col-8">
                        <input id="tituloFormControl" formControlName="tituloFormControl" type="text"
                            class="form-control" />
                        <div *ngIf="tituloFormControl.invalid && tituloFormControl.errors.required && (tituloFormControl.dirty || tituloFormControl.touched)"
                            class="alert alert-danger">Requerido
                        </div>
                    </div>
                </div>
            </div>
            <br>

            <div *ngIf="this.authService.hasRole(TipoUsuario.SEGURIDADTABLAS, TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.IMPUESTOS, TipoUsuario.PRECIOS, TipoUsuario.APROBADORPRECIOS, TipoUsuario.FACTURACION, TipoUsuario.REFACTURACION)">
                <!-- roles -->
                <div class="row">
                    <div class="mb-3 row col-12">
                        <label for="rolesFormControl" class="col-2 col-form-label">Agregar rol</label>
                        <div class="col-6">
                            <input id="rolesFormControl" type="text" class="form-control"
                                formControlName="rolFormControl" [inputFormatter]="formatterRol"
                                [resultFormatter]="formatterRol" (selectItem)="selectRol($event)"
                                [ngbTypeahead]="searchRol" />
                        </div>
                        <div class="col-1">
                            <button (click)="agregarRol()" type="button" class="btn btn-primary">
                                Agregar
                            </button>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="mb-3 row col-12 ms-4">
                    <loading-text-spinner *ngIf="this.cargandoRoles" message="Obteniendo roles.." color="primary"></loading-text-spinner>   
                    </div>
                </div>

                <div class="row item-border">
                    <div class="mb-3 row col-12">
                        <label class="col-2 col-form-label">Roles asignados</label>
                        <div class="col-8 card-body">
                            <div *ngFor="let r of this.rolesSeleccionados">
                                <div class="d-flex mt-1">
                                    <label class="col-2 col-form-label">{{r.nombre}}</label>
                                    <button type="button" ngbTooltip="Quitar rol asignado"
                                        (click)="borrarRol(r)" class="btn btn-danger ms-2 px-1">
                                        <i class="fa fa-trash"></i>
                                    </button>
                                </div>
                            </div>
                            <div *ngIf='this.rolesSeleccionados?.length === 0'>No se han cargado roles</div>
                        </div>
                    </div>
                </div>
            </div>

            <br>

            <div *ngIf="this.estaEditandoGrupo()">
                <!-- usuarios -->
                <div class="row">
                    <div class="mb-3 row col-12">
                        <label for="usuarioFormControl" class="col-2 col-form-label">Agregar usuario</label>
                        <div class="col-6">
                            <input id="usuarioFormControl" type="text" class="form-control"
                                formControlName="usuarioFormControl" (selectItem)="selectUsuario($event)"[inputFormatter]="formatterUsuario"
                                [resultFormatter]="formatterUsuario" [ngbTypeahead]="searchUsuario" />
                        </div>
                        <div class="col-1">
                            <button (click)="agregarUsuario()" type="button" class="btn btn-primary">
                                Agregar
                            </button>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="mb-3 row col-12 ms-4">
                    <loading-text-spinner *ngIf="this.cargandoUsuarios" message="Obteniendo usuarios.." color="primary"></loading-text-spinner>   
                    </div>
                </div>
                
                <div class="row item-border">
                    <div class="mb-3 row col-12">
                        <label class="col-2 col-form-label">Usuarios asignados</label>
                        <div class="col-8 card-body">
                            <div *ngFor="let u of this.usuariosSeleccionados">
                                <div class="d-flex mt-1">
                                    <label class="col-2 col-form-label">{{u.nombresCompletos}}</label>
                                    <button type="button" ngbTooltip="Quitar usuario asignado"
                                        (click)="borrarUsuario(u)" class="btn btn-danger ms-2 px-1">
                                        <i class="fa fa-trash"></i>
                                    </button>
                                </div>
                            </div>
                            <div *ngIf='this.usuariosSeleccionados?.length === 0'>No se han cargado usuarios</div>
                        </div>
                    </div>
                </div>
            </div>
            <br>

            <div class="row">
                <div class="mb-3 row col-12">
                    <label for="activoFormControl" class="col-form-label col-2">Activo</label>
                    <div class="col-6">
                        <input id="activoFormControl" formControlName="activoFormControl" type="checkbox"
                            class="form-check-input" />
                    </div>
                </div>
            </div>

            
            <div *ngIf="error != null" class="row col-md-12 alert alert-danger">
                {{error}}
            </div>
        </div>
        <div class="card-footer d-flex justify-content-center">
            <span class="border">
                <button type="button" class="btn btn-primary" (click)="guardarCambios()"
                    [disabled]="guardandoDatos">
                    <span *ngIf="!guardandoDatos">Guardar cambios</span>
                    <div *ngIf="guardandoDatos" class="d-flex align-items-center">
                        <div class="spinner-border text-light spinner-border-sm" role="status"></div>
                        <span>Guardando...</span>
                    </div>
                </button>
            </span>
        </div>
    </form>
</div>