import { Component, Input, OnInit } from '@angular/core';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EstadoTelemedicion } from 'src/app/shared/enums/EstadoTelemedicion';
import { ModalConfirmComponent } from 'src/app/shared/generic-modals/modal-confirm.component';
import { CierreTurno } from 'src/app/shared/models/cierreTurno/CierreTurno';
import { ParteDiario } from 'src/app/shared/models/cierreTurno/ParteDiario';
import { MessageService } from 'src/app/shell/message.service';
import { CierreTurnoService } from '../../cierre-turno.service';
import { UsuarioService } from 'src/app/account/usuario.service';
import { Aeroplanta } from 'src/app/shared/models/stock/Aeroplanta';
import { ModalEditarEstadosComponent } from './modal-editar-estados/modal-editar-estados.component';
import { EntidadParteDiario } from 'src/app/shared/enums/EntidadParteDiario';
import { estadoEquipo } from 'src/app/shared/enums/estadoEquipo';
import { EstadoEquipo } from 'src/app/shared/models/stock/EstadoEquipo';
import { StockService } from 'src/app/stock/stock.service';
import { EstadoSistemaSeguridad } from 'src/app/shared/models/stock/EstadoSistemaSeguridad';
import { estadoSistemaSeg } from 'src/app/shared/enums/estadoSistemaSeg';
import { EstadoTanque } from 'src/app/shared/models/cierreTurno/EstadoTanque';
import { EstadoExpendedora } from 'src/app/shared/models/cierreTurno/EstadoExpendedora';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProductoService } from 'src/app/abm/producto/producto.service';
import { Producto } from 'src/app/shared/models/abm/Producto';
import { UntypedFormControl } from '@angular/forms';
import { EstadoServicio as EstadoServicioStock } from 'src/app/shared/models/stock/EstadoServicio';
import { TipoExpendedora } from 'src/app/shared/enums/tipoExpendedora';
import { DatePipe } from '@angular/common';
import { ModalSetFechaComponent } from 'src/app/shared/generic-modals/modal-set-fecha.component';
import { EstadoTanqueUnico } from 'src/app/shared/enums/EstadoTanqueUnico';
import { EstadoServAbastecedora } from 'src/app/shared/enums/EstadoServAbastecedora';
import { EtapasCierreTurno } from 'src/app/shared/enums/EtapasCierreTurno';

@Component({
  selector: 'app-parte-diario-cierre-turno',
  templateUrl: './parte-diario-cierre-turno.component.html',
  styleUrls: ['./parte-diario-cierre-turno.component.scss']
})
export class ParteDiarioCierreTurnoComponent implements OnInit {

  novedadesFormControl = new UntypedFormControl();

  obteniendoTanques: boolean = false;
  obteniendoDetalle: boolean = false;
  cierreFinalizado: boolean = false;
  guardando: boolean = false;
  generando: boolean = false;
  parteDiario: ParteDiario; 
  aeroplanta: Aeroplanta;
  productos:Producto[];

  //EstadoServicio = EstadoServicio;
  EstadoTanqueUnico = EstadoTanqueUnico;
  EstadoServAbastecedora = EstadoServAbastecedora;
  estadoEquipo = estadoEquipo;
  estadoSistemaSeg = estadoSistemaSeg;
  EstadoTelemedicion = EstadoTelemedicion;
  EntidadParteDiario = EntidadParteDiario;

  servicioSeleccionado = null;
  telemedicionSeleccionada = null;
  editandoTanque: boolean = false;

  expendedoraSeleccionada = null;
  editandoExpendedora: boolean = false;

  equipoSeleccionado = null;
  editandoEquipo: boolean = false;

  sistemaSeleccionado = null;
  editandoSistema: boolean = false;

  actualizarParteDiario: boolean = false;
  actualizando: boolean = false;
  
  /** fecha y hora */
  fechaProyeccion:Date;
  fechaHoraReporte:Date;

  modelFecha: NgbDateStruct;
  modelHora: any;

  estadoExpendedoraAux: number[];
  estadoTanqueServicioAux: number[];
  estadoTanqueTelemedicionAux: number[];
  estadoEquipoAux: number[];
  estadoSistemaAux: number[];
  
  message:string="C A R G A N D O   P R O D U C T O S . . .";

  @Input() cierreTurno: CierreTurno;
  @Input() reporteParteDiario: boolean;

  modificarFecha: boolean = false;

  expendedorasValores = [];

  public recargarNavBar:number=0;
  
  constructor(
    private modal: NgbModal,
    private datePipe: DatePipe,
    private spinner: NgxSpinnerService,
    private usuarioService: UsuarioService,
    private productoService: ProductoService,
    private stockService: StockService,
    private cierreService: CierreTurnoService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.cierreFinalizado = false;
    this.aeroplanta = this.usuarioService.getAeroplantaActual();
    if (!this.reporteParteDiario) {
      this.iniciarParteDiario()
    } else {
      this.obtenerParteDiario()
    }
    this.cargarFormulario();
  }

  cargarFormulario() {
    if (!this.reporteParteDiario) {
      this.novedades.enable()
    }else{
      this.novedades.disable()
    }
    if(this.cierreTurno.parteDiario?.novedadesOperativas) {
      this.novedades.setValue(this.cierreTurno.parteDiario.novedadesOperativas);
    }

    /** seteamos fecha reporte, proyeccion y operacion */
    this.fechaHoraReporte = new Date(this.cierreTurno.fechaHoraReportes);
    let fecha = new Date(this.cierreTurno.fechaHoraReportes);
    fecha.setDate(fecha.getDate() + 1);
    this.fechaProyeccion = fecha;
  }

  async iniciarParteDiario() {
    this.obteniendoDetalle = true;
    this.spinner.show('spinnerParteDiario');
    this.productos = await this.productoService.obtenerProductosPromise();
    this.productos = this.productos.filter(p=>p.rubro.aerocombustible||p.rubro.combustible);
    this.message = "C A R G A N D O   P A R T E   D I A R I O . . .";
    try {
      const parte = await this.cierreService.obtenerParteDiario(this.cierreTurno.id);

      this.estadoExpendedoraAux = [];
      for (let x = 0; x < parte.estadosExpendedoras.length; x++) {
        this.estadoExpendedoraAux[x] = parte.estadosExpendedoras[x].estadoServicio;        
      }

      this.estadoTanqueServicioAux = [];
      this.estadoTanqueTelemedicionAux = [];
      for (let x = 0; x < parte.estadosTanques.length; x++) {
        this.estadoTanqueServicioAux[x] = parte.estadosTanques[x].estadoServicio;   
        this.estadoTanqueTelemedicionAux[x] = parte.estadosTanques[x].estadoTelemedicion;        
      }

      this.estadoEquipoAux = [];      
      for (let x = 0; x < parte.equiposFijos.length; x++) {
        this.estadoEquipoAux[x] = parte.equiposFijos[x].estado;        
      }

      this.estadoSistemaAux = [];
      for (let x = 0; x < parte.sistemasSeguridad.length; x++) {
        this.estadoSistemaAux[x] = parte.sistemasSeguridad[x].estado;        
      }

      if (typeof(parte) === 'object') {
        this.parteDiario = parte;
        for (const ss of this.parteDiario.sistemasSeguridad) {
          if (ss.autorizoFS != '' && ss.autorizoFS.includes('{')) {
            ss.autorizoFS=JSON.parse(ss.autorizoFS.replace(/'/g,'"')).nombresCompletos;
          }
        } 
        
        this.parteDiario.estadosTanques.forEach( t => {
          let color = this.productos.find(p => p.codigoProducto==t.codigoProducto).color;
          t.colorProducto = color;
        });
        
        this.parteDiario.estadosExpendedoras.forEach( e => {
          let color = this.productos.find(p => p.codigoProducto==e.codigoProducto).color;
          e.colorProducto = color;
          if (e.autorizoFS != '' && e.autorizoFS.includes('{')) {
            e.autorizoFS=JSON.parse(e.autorizoFS.replace(/'/g,'"')).nombresCompletos;
          }
        });
        
        this.obteniendoDetalle = false;
        this.spinner.hide('spinnerParteDiario');
        this.message = "";
      }  
    } catch (error) {
      this.obteniendoDetalle = false;
      this.spinner.hide('spinnerParteDiario');
      this.messageService.showErrorMessage("Hubo un error al obtener el Parte Diario");
      this.message = "";
    }    
  }

  async obtenerParteDiario() {
    this.obteniendoDetalle = true;
    this.spinner.show('spinnerParteDiario');
    this.productos = await this.productoService.obtenerProductosPromise();
    this.productos = this.productos.filter(p=>p.rubro.aerocombustible||p.rubro.combustible);
    this.message = "C A R G A N D O   P A R T E   D I A R I O . . .";
    this.actualizando ? this.parteDiario = await this.cierreService.obtenerParteDiario(this.cierreTurno.id) : this.parteDiario = this.cierreTurno.parteDiario;
    for (const ss of this.parteDiario.sistemasSeguridad) {
      if (ss.autorizoFS != '' && ss.autorizoFS.includes('{')) {
        ss.autorizoFS=JSON.parse(ss.autorizoFS.replace(/'/g,'"')).nombresCompletos;
      }
    }
    
    this.parteDiario.estadosTanques.forEach( t => {
      let color = this.productos.find(p => p.codigoProducto==t.codigoProducto).color;
      t.colorProducto = color;
    });
    
    this.parteDiario.estadosExpendedoras.forEach( e => {
      let color = this.productos.find(p => p.codigoProducto==e.codigoProducto).color;
      e.colorProducto = color;
      if (e.autorizoFS != '' && e.autorizoFS.includes('{')) {
        e.autorizoFS=JSON.parse(e.autorizoFS.replace(/'/g,'"')).nombresCompletos;
      }
    });
     
    this.obteniendoDetalle = false;
    this.spinner.hide('spinnerParteDiario');
    this.message = "";
    this.actualizando = false;
  }

  guardarClick() {
    const modalRef = this.modal.open(ModalConfirmComponent, { centered: true });
    modalRef.componentInstance.mensaje = "¿Confirma guardar las novedades operativas y demoras/incidencias ingresadas?"; // should be the id
    modalRef.result.then(() => {
      this.spinner.show('spinnerParteDiario');
      this.guardarParteDiario();
      this.actualizarParteDiario = false;
      if (this.actualizando) this.obtenerParteDiario();;
    });
  }

  async guardarParteDiario() {  
    if (this.validarInput()) {      
      this.generando = true;
      try {
        this.parteDiario.novedadesOperativas = this.novedades.value;
        const parte = await this.cierreService.modificarParteDiario(this.parteDiario, false);
        if (typeof(parte) === 'object') {
          this.messageService.showSuccessMessage('El parte diario fue generado exitosamente.');
          this.generando = false;    
          this.completeStep(Object.keys(EtapasCierreTurno).indexOf('Etp4'));          
        }     
      } catch (err) {
        this.messageService.showErrorMessage('Hubo un error al generar el parte diario, por favor reintente');
        this.generando = false;
      }
    }    
  }

  completeStep(index: number): void {    
    this.cierreService.updateStepProgress(index, true);
    this.recargarNavBar++;
  }

  async actualizarParte() {
    this.actualizando = true;
    this.guardarClick();    
  }

  async finalizarTurno() {
    if (this.recargarNavBar==0) { // significa que no hizo cambios en parte diario y finaliza el turno directamente
      this.completeStep(Object.keys(EtapasCierreTurno).indexOf('Etp4'));
    }
    if (this.validarInput()) {
      this.guardando = true;
      try {
        this.parteDiario.novedadesOperativas = this.novedades.value;
        const r1 = await this.cierreService.modificarParteDiario(this.parteDiario, true);

        const r2 = await this.cierreService.finalizarTurno(this.cierreTurno.id);

        this.messageService.showSuccessMessage('El turno fue finalizado exitosamente.');
        this.cierreFinalizado = true;
        this.guardando = false;
        this.completeStep(Object.keys(EtapasCierreTurno).indexOf('Fin'));
      } catch (error) {
        this.messageService.showErrorMessage('Hubo un error al finalizar el turno, por favor reintente');
        this.guardando = false;
      }
    }
  }

  /** utilidades */

  changeValue(index: number, property: string, event: any) {
    let vacio = event.target.value === '';
    vacio ? this.parteDiario.detallesParteDiario[index][property] = null : this.parteDiario.detallesParteDiario[index][property] = parseInt(event.target.value);
    if(property==='salidaEstimada' && !vacio) {
      if (parseInt(event.target.value) > 0) {
        this.parteDiario.detallesParteDiario[index].stockEnDias = this.parteDiario.detallesParteDiario[index].stockOperativo / this.parteDiario.detallesParteDiario[index].salidaEstimada;
      }else{
        this.parteDiario.detallesParteDiario[index].stockEnDias = 0;
      }      
    }
  }

  isVacio(index: number, property: string) {
    return this.parteDiario.detallesParteDiario[index][property] === null;
  }

  validarInput() {    
    if (this.parteDiario.detallesParteDiario.length > 0) {
      for (let i = 0; i < this.parteDiario.detallesParteDiario.length; i++) {
        if (this.parteDiario.detallesParteDiario[i].salidaEstimada == null || 
            this.parteDiario.detallesParteDiario[i].demorasProblemasPropios == null || 
            this.parteDiario.detallesParteDiario[i].demorasLineasAereas == null)
            {
              this.messageService.showErrorMessage('Por favor revise los datos a completar.');
              return false;
            }      
      } 
    } else {
      this.messageService.showErrorMessage('No hay detalle de productos configurados para esta aeroplanta.');
      return false;
    }
    
    return true;    
  }

  cambiarFechaReporte() {
    /*if(this.modificarFecha) {
      let fechaHoraCierre = this.formatDate(this.fecha.value.year, this.fecha.value.month,
        this.fecha.value.day, this.hora.value.hour, this.hora.value.minute);
        console.log(fechaHoraCierre);
    
        const modalRef = this.modal.open(ModalConfirmComponent, { centered: true });
        modalRef.componentInstance.mensaje = "¿Confirma cambiar la fecha del reporte "+this.datePipe.transform(fechaHoraCierre, 'dd/MM/yyyy hh:mm a') +"?"; // should be the id
        modalRef.result.then(() => {
          this.cierreService.putAsignarFechaReporte(this.cierreTurno.id, fechaHoraCierre)
            .subscribe(r=>{
              this.messageService.showSuccessMessage(`La fecha del reporte fue actualizada con exito.`);
            });      
        });
    }*/
    const modalRef = this.modal.open(ModalSetFechaComponent, { centered: true });
      modalRef.componentInstance.setFecha = this.cierreTurno.fechaHoraReportes;
      modalRef.result.then((r) => {
        if(r==false) {
          console.log('nada');
        } else {
          this.fechaHoraReporte = new Date(r);
          let fecha = new Date(r);
          fecha.setDate(fecha.getDate() + 1);
          this.fechaProyeccion = fecha;
          this.cierreService.putAsignarFechaReporte(this.cierreTurno.id, r)
            .subscribe(r=>{
              this.messageService.showSuccessMessage(`La fecha del reporte fue actualizada con exito.`);
            });
        }
      });
  }

  keysEstadoEquipo(): Array<string> {
    var keys = Object.keys(this.estadoEquipo);
    keys = keys.slice(keys.length / 2);
    return keys;
  }

  keysEstadoSistema(): Array<string> {
    var keys = Object.keys(this.estadoSistemaSeg);
    keys = keys.slice(keys.length / 2);
    return keys;
  }

  /*keysEstadoServicio(): Array<string> {
    var keys = Object.keys(this.EstadoServicio);
    keys = keys.slice(keys.length / 2);
    return keys;
  }*/

  keysEstadoTanqueUnico(): Array<string> {
    var keys = Object.keys(this.EstadoTanqueUnico);
    keys = keys.slice(keys.length / 2);
    return keys;
  }

  keysEstadoServAbastecedora(): Array<string> {
    var keys = Object.keys(this.EstadoServAbastecedora);
    keys = keys.slice(keys.length / 2);
    return keys;
  }

  keysEstadoTelemedicion(): Array<string> {
    var keys = Object.keys(this.EstadoTelemedicion);
    keys = keys.slice(keys.length / 2);
    return keys;
  }

  formatDate(year, month, day, hour, minute): string {
    return year + '-' + month.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + '-' + day.toLocaleString(undefined, { minimumIntegerDigits: 2 })
      + 'T' + hour.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + minute.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':00';
  }

  cambiarEstadoServicioTanques(t:EstadoTanque, i:number) {  
      this.abrirModal(t.codigoContenedor, EntidadParteDiario.TanqueServicio, null, i, t.estadoServicio==EstadoTanqueUnico.FueraServicio, t.estadoServicio);
  }

  cambiarEstadoTelemedicionTanques(t:EstadoTanque, i:number) {    
    this.abrirModal(t.codigoContenedor, EntidadParteDiario.TanqueTelemedicion, null, i, t.estadoTelemedicion==EstadoTelemedicion.FueraServicio, t.estadoTelemedicion);    
  }

  cambiarEstadoServicioExpendedoras(e:EstadoExpendedora, i:number) {    
    this.abrirModal(e.codigoExpendedora, EntidadParteDiario.Expendedora, e.tipoExpendedora, i, e.estadoServicio==EstadoServAbastecedora.FueraServicio, e.estadoServicio);    
  }
  
  cambiarEstadoEquipos(id:string, estado: estadoEquipo, i:number) {
    this.abrirModal(id, EntidadParteDiario.EquipoFijo, null, i, estado==estadoEquipo.FueraDeServicio, estado);
  }

  cambiarEstadoSistemas(id:string, estado: estadoSistemaSeg, i:number) {    
    this.abrirModal(id, EntidadParteDiario.SistemasSeguridad, null, i, estado == estadoSistemaSeg.FueraDeServicio, estado);
  }

  abrirModal(id:string, enume: EntidadParteDiario, tipo:TipoExpendedora, index:number, fs:boolean, estado) {    
    let modalRef = this.modal.open(ModalEditarEstadosComponent, { centered: true, backdrop: 'static' });
    modalRef.componentInstance.enume = enume;
    modalRef.componentInstance.id = id;
    modalRef.componentInstance.tipo = tipo;
    modalRef.componentInstance.fs = fs;
    modalRef.componentInstance.estado = estado;
    modalRef.result.then(result => {
      if (result instanceof Object) {
        switch (enume) {
          case EntidadParteDiario.TanqueServicio:
            this.parteDiario.estadosTanques[index].fechaHoraFS = result.fechaHoraFS;
            this.parteDiario.estadosTanques[index].observaciones = result.observaciones;
            break;
          case EntidadParteDiario.TanqueTelemedicion:
            this.parteDiario.estadosTanques[index].fechaHoraFS = result.fechaHoraFS;
            this.parteDiario.estadosTanques[index].observaciones = result.observaciones;
            break;
          case EntidadParteDiario.Expendedora:            
            this.parteDiario.estadosExpendedoras[index].fechaHoraFS = result.fechaHoraFS;
            this.parteDiario.estadosExpendedoras[index].observaciones = result.observaciones;
            this.updatedCierre();
            break;
          case EntidadParteDiario.EquipoFijo:
            this.parteDiario.equiposFijos[index].fechaHoraFS = result.fechaHoraFS;
            this.parteDiario.equiposFijos[index].observaciones = result.observaciones;
            break;
          case EntidadParteDiario.SistemasSeguridad:
            this.parteDiario.sistemasSeguridad[index].fechaHoraFS = result.fechaHoraFS;
            fs ? this.parteDiario.sistemasSeguridad[index].autorizoFS = JSON.parse(result.autorizoFS.replace(/'/g,'"')).nombresCompletos : "";
            this.parteDiario.sistemasSeguridad[index].observaciones = result.observaciones;
            break;
          default: 
            break;
        }
        this.actualizarParteDiario = true;
      }else if (result==false) {
        switch (enume) {
          case EntidadParteDiario.TanqueServicio:
            this.parteDiario.estadosTanques[index].estadoServicio = this.estadoTanqueServicioAux[index];
            break;
          case EntidadParteDiario.TanqueTelemedicion:
            this.parteDiario.estadosTanques[index].estadoTelemedicion = this.estadoTanqueTelemedicionAux[index];
            break;
          case EntidadParteDiario.Expendedora:            
            this.parteDiario.estadosExpendedoras[index].estadoServicio = this.estadoExpendedoraAux[index];
            break;
          case EntidadParteDiario.EquipoFijo:
            this.parteDiario.equiposFijos[index].estado = this.estadoEquipoAux[index];
            break;
          case EntidadParteDiario.SistemasSeguridad:
            this.parteDiario.sistemasSeguridad[index].estado = this.estadoSistemaAux[index];
            break;
          default: 
            break;
        }
      }
    });
  }

  inicializarArreglo() {
    this.expendedorasValores = [];
    this.parteDiario.detallesParteDiario.forEach( d => {
      this.expendedorasValores.push({ "codigo": d.codigoProducto, "cant_serv": 0, "cant_fs": 0, "cant_rep": 0 });
    });
  }

  updatedCierre() {
    this.inicializarArreglo();
    let expendedoras = this.parteDiario.estadosExpendedoras.filter(e=>e.tipoExpendedora==TipoExpendedora.ABASTECEDORA_CAMION);
    for (let i = 0; i < expendedoras.length; i++) {
      const e = expendedoras[i];
      if (e.estadoServicio==EstadoServAbastecedora.EnServicio) {
        this.expendedorasValores.filter(x => x.codigo == e.codigoProducto)[0].cant_serv++ 
      }else if (e.estadoServicio==EstadoServAbastecedora.TallerMecanico) {
        this.expendedorasValores.filter(x => x.codigo == e.codigoProducto)[0].cant_rep++
      }else {
        this.expendedorasValores.filter(x => x.codigo == e.codigoProducto)[0].cant_fs++
      }
    }
    for (let x = 0; x < this.parteDiario.detallesParteDiario.length; x++) {
      for (let y = 0; y < this.expendedorasValores.length; y++) {
        if (this.parteDiario.detallesParteDiario[x].codigoProducto==this.expendedorasValores[y].codigo) {
          this.parteDiario.detallesParteDiario[x].cantAbastecedorasEnServicio=this.expendedorasValores[y].cant_serv;
          this.parteDiario.detallesParteDiario[x].cantAbastecedorasFS=this.expendedorasValores[y].cant_fs;
          this.parteDiario.detallesParteDiario[x].cantAbastecedorasReparacion=this.expendedorasValores[y].cant_rep;
        }
      }
    }
  }

  obtenerEstadoEquipo(estado:number) {
    return estadoEquipo[estado];
  }

  obtenerEstadoSistema(estado:number) {
    return estadoSistemaSeg[estado];
  }

  obtenerEstadoServicioTanque(estado:number) {
    return EstadoTanqueUnico[estado];
  }

  obtenerEstadoServicioExpendedora(estado:number) {
    return EstadoServAbastecedora[estado];
  }

  obtenerEstadoTelemedicion(estado:number) {
    return EstadoTelemedicion[estado];
  }

  get novedades() {
    return this.novedadesFormControl;
  }
}
