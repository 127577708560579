import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PreciosService } from 'src/app/precios/precios.service';
import { TipoNivelAprobacion } from 'src/app/shared/enums/tipoNivelAprobacion';
import { ModalConfirmComponent } from 'src/app/shared/generic-modals/modal-confirm.component';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { NivelAprobacion } from 'src/app/shared/models/precios/NivelAprobacion';
import { MessageService } from 'src/app/shell/message.service';
import { FacturacionService } from '../../facturacion.service';

@Component({
  selector: 'app-modal-nivel-refacturacion',
  templateUrl: './modal-nivel-refacturacion.component.html',
  styleUrls: ['./modal-nivel-refacturacion.component.css']
})
export class ModalNivelRefacturacionComponent implements OnInit {

  @Input() tipoNivel: TipoNivelAprobacion;
  @Input() nivelAprobacionSeleccionado: NivelAprobacion;
  motivosSeleccionados: number[] = [];

  enumTipoNivel = TipoNivelAprobacion;
  form: UntypedFormGroup;

  editando: boolean;
  guardandoNivel: boolean = false;

  constructor(
    private preciosService: PreciosService,
    private facturacionService: FacturacionService,
    private messageService: MessageService,
    private activeModal: NgbActiveModal,
    private modalAceptar: NgbModal) {
  }

  // hooks

  ngOnInit() {
    this.crearFormulario();

    if (this.nivelAprobacionSeleccionado == null) {
      this.editando = false;
      this.activoFormControl.setValue(true);
    } else {
      this.editando = true;
      this.cargarInfoNivelAprobacion();
    }
  }

  // forms

  crearFormulario() {
    this.form = new UntypedFormGroup({
      codigoFormControl: new UntypedFormControl('', Validators.required),
      nombreFormControl: new UntypedFormControl('', Validators.required),
      montoMaximoFormControl: new UntypedFormControl('', Validators.required),
      activoFormControl: new UntypedFormControl(),
      plazoCheckFormControl: new UntypedFormControl(),
      impuestosCheckFormControl: new UntypedFormControl(),
      otrosCheckFormControl: new UntypedFormControl(),
      preciosCheckFormControl: new UntypedFormControl(),
      despachoCheckFormControl: new UntypedFormControl()
    });
  }

  seleccionarDocumentos(motivo: number, event) {
    if (event.target.checked) {
      this.motivosSeleccionados.push(motivo);
    } else {
      let indice = this.motivosSeleccionados.indexOf(motivo);
      if (indice != -1) {
        this.motivosSeleccionados.splice(indice, 1);
      }
    }
    console.log("motivos seleccionadas " + JSON.stringify(this.motivosSeleccionados))
  }

  cargarInfoNivelAprobacion() {
    this.nivelAprobacionSeleccionado.tipoNivel = 2;
    this.codigoFormControl.setValue(this.nivelAprobacionSeleccionado.codigo);
    this.nombreFormControl.setValue(this.nivelAprobacionSeleccionado.nombre);
    this.montoMaximoFormControl.setValue(this.nivelAprobacionSeleccionado.montoMaximoAprobacion);
    this.motivosSeleccionados = this.nivelAprobacionSeleccionado.motivos;
    for (let doc of this.nivelAprobacionSeleccionado.motivos) {
      switch (doc) {
        case 0: this.plazoCheckFormControl.setValue(true);
          break;
        case 1: this.impuestosCheckFormControl.setValue(true);
          break;
        case 2: this.otrosCheckFormControl.setValue(true);
          break;
        case 3: this.preciosCheckFormControl.setValue(true);
          break;
        case 4: this.despachoCheckFormControl.setValue(true);
          break;
      }
    }
  }

  // conexiones al service

  onSubmit() {
    this.guardandoNivel = true;

    if (!this.editando) {
      this.nivelAprobacionSeleccionado = new NivelAprobacion();
    }

    this.nivelAprobacionSeleccionado.tipoNivel = 2;
    this.nivelAprobacionSeleccionado.codigo = this.codigoFormControl.value;
    this.nivelAprobacionSeleccionado.nombre = this.nombreFormControl.value;
    this.nivelAprobacionSeleccionado.montoMaximoAprobacion = this.montoMaximoFormControl.value;
    this.nivelAprobacionSeleccionado.motivos = this.motivosSeleccionados;
    this.nivelAprobacionSeleccionado.activo = true;

    if (!this.editando) {
      this.preciosService.crearNivelAprobacion(this.nivelAprobacionSeleccionado)
        .subscribe(nivelAprobacion => {
          this.guardandoNivel = false;
          this.activeModal.close(nivelAprobacion);
        },
          (err: ErrorModel) => {
            this.messageService.showErrorMessage("Error al guardar el nivel");
            this.guardandoNivel = false;
          });
    } else {
      this.preciosService.editarNivelAprobacion(this.nivelAprobacionSeleccionado)
        .subscribe(nivelAprobacion => {
          this.guardandoNivel = false;
          this.activeModal.close(nivelAprobacion);
        },
          (err: ErrorModel) => {
            this.messageService.showErrorMessage("Error al editar el nivel");
            this.guardandoNivel = false;
          });
      this.guardandoNivel = false;
    }
  }

  // modals

  onCancelar() {
    this.activeModal.dismiss();
  }

  // validaciones

  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0 || event.charCode == 46) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  noPuedeGuardarNivel() {
    return (!(this.form.valid && this.motivosSeleccionados.length > 0) || this.guardandoNivel);
  }

  // getters

  get codigoFormControl() {
    return this.form.controls.codigoFormControl;
  }

  get nombreFormControl() {
    return this.form.controls.nombreFormControl;
  }

  get montoMaximoFormControl() {
    return this.form.controls.montoMaximoFormControl;
  }

  get puedeDesactivarFormControl() {
    return this.form.controls.puedeDesactivarFormControl;
  }

  get activoFormControl() {
    return this.form.controls.activoFormControl;
  }

  get maximaVariacionFormControl() {
    return this.form.controls.maximaVariacionFormControl;
  }

  get maximaBonificacionFormControl() {
    return this.form.controls.maximaBonificacionFormControl;
  }

  get maximoRecargoFormControl() {
    return this.form.controls.maximoRecargoFormControl;
  }

  get documentosFormControl() {
    return this.form.controls.documentosFormControl;
  }
  get plazoCheckFormControl() {
    return this.form.controls.plazoCheckFormControl;
  }
  get impuestosCheckFormControl() {
    return this.form.controls.impuestosCheckFormControl;
  }
  get otrosCheckFormControl() {
    return this.form.controls.otrosCheckFormControl
  }
  get preciosCheckFormControl() {
    return this.form.controls.preciosCheckFormControl
  }
  get despachoCheckFormControl() {
    return this.form.controls.despachoCheckFormControl
  }
}