import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { UsuarioService } from 'src/app/account/usuario.service';
import { CuentasService } from 'src/app/cliente/cuentas.service';
import { Cliente } from 'src/app/shared/models/cliente/Cliente';
import { Ubicacion } from 'src/app/shared/models/cliente/Ubicacion';
import { DireccionEntrega } from 'src/app/shared/models/despacho/DireccionEntrega';
import { Pais } from 'src/app/shared/models/despacho/Pais';
import { Ciudad } from 'src/app/shared/models/facturacion/Ciudad';
import { Provincia } from 'src/app/shared/models/facturacion/Provincia';
import { ConfiguracionService } from '../configuracion.service';




@Component({
  selector: 'app-direccion-entrega',
  templateUrl: './direccion-entrega.component.html',
  styleUrls: ['./direccion-entrega.component.css']
})
export class DireccionEntregaComponent implements OnInit {

  @Input() clienteSeleccionado: Cliente;
  @Output() dirEntrega: EventEmitter<Ubicacion> = new EventEmitter();
  @Output() ciudadId: EventEmitter<string> = new EventEmitter();
  @Output() provVal: EventEmitter<any> = new EventEmitter();
  @Output() ciuVal: EventEmitter<any> = new EventEmitter();
  errorDestinos: boolean = false;
  guardando: boolean = false;

  form = new UntypedFormGroup({
    calleFormControl: new UntypedFormControl(null, [Validators.required]),
    codigoPostalFormControl: new UntypedFormControl(null, [Validators.required]),
    provinciaFormControl: new UntypedFormControl(null, Validators.required),
    paisFormControl: new UntypedFormControl(null),
    modificacionFormControl: new UntypedFormControl(null),
    direccionesFormControl: new UntypedFormControl(null)
  });

  modelPais: any;
  modelProvincia: any;
  modelCiudad: any;
  paises: Pais[];
  provincias: Provincia[];
  ciudades: Ciudad[];
  cargandoPaises: boolean = false;
  cargandoProvincias: boolean = false;
  ciudadBuscada: Ciudad;
  cargandoCiudades: boolean = false;
  
  ciudadvalid: boolean;
  provinciavalid: boolean;
  provinciaFlag: boolean = false;
  ciudadFlag: boolean = false;
  constructor(public activeModal: NgbModal, private configuracionService: ConfiguracionService, private usuarioService: UsuarioService) { }
  
// hooks

  ngOnInit() {
    this.provinciaFormControl.disable();
    this.codigoPostalFormControl.disable();
    this.getPaises();
    
/*
    var direccionAeroplanta = new DireccionEntrega();
    direccionAeroplanta.direccion
    direccionAeroplanta.calle
    direccionAeroplanta.numero
    direccionAeroplanta.

    direccionAeroplanta.setDireccion("Aeroplanta: " + aeroplanta.getCodigoAeroplanta());
    direccionAeroplanta.setCalle("Aeroplanta: " + aeroplanta.getCodigoAeroplanta());
    direccionAeroplanta.setNumero("");
    direccionAeroplanta.setLocalidadCodigoPostal(localidad.getCodigoPostal());
    direccionAeroplanta.setLocalidadNombre(localidad.getNombre());

    if (this.clienteSeleccionado!=null && this.clienteSeleccionado.direccionesEntrega!=null && this.clienteSeleccionado.direccionesEntrega.length>0)
    {
      this.codigoPostalFormControl.setValue(this.clienteSeleccionado.direccionesEntrega[0].ciudad)
      this.direccionesFormControl.setValue(this.clienteSeleccionado.direccionesEntrega[0].direccion)
    }
    */
  }

  ngOnChanges(): void {
    /*
    if (this.clienteSeleccionado!=null && this.clienteSeleccionado.direccionesEntrega!=null && this.clienteSeleccionado.direccionesEntrega.length>0)
    {
    console.log("La ciudad es "+JSON.stringify(this.clienteSeleccionado.direccionesEntrega[0]));
    this.codigoPostalFormControl.setValue(this.clienteSeleccionado.direccionesEntrega[0].ciudad)
    }
    */
/*
    var ciudadAeroplantaId = this.usuarioService.getAeroplantaActual().ciudadId;
    let indiceCiudad = 0;

    if(this.ciudades != null){
      for (let i = 0; this.ciudades.length > i; i++) {
        if (this.ciudades[i].id == ciudadAeroplantaId) {
          indiceCiudad = i;
          break;          
        } 
      }

      this.codigoPostalFormControl.setValue(this.ciudades[indiceCiudad]);
      this.ciudadId.emit(this.ciudades[indiceCiudad].id);
    }    
*/    
  }
  
  onlyDecimalPositivo(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46;
  }
  
  // llamadas al service

  getPaises() {
    this.cargandoPaises = true;
    this.cargandoProvincias = true;
    this.cargandoCiudades = true;
    this.configuracionService.getPaises()
      .subscribe(result => {
        this.paises = result;
        this.cargandoPaises = false;
        this.obtenerPaisSeleccionado();
      }, () => this.errorDestinos = true)
  }
  obtenerPaisSeleccionado() {

      for (let i = 0; this.paises.length > i; i++) {
        if (this.paises[i].nombre == 'Argentina') {
          this.paisFormControl.setValue(this.paises[i]);
          this.getProvinciasPorPais(this.paises[i].codigo);
        }
      }
    }


  getProvinciasPorPais(codigo: string) {
    this.provinciaFormControl.setValue(null);
    this.configuracionService.getProvinciasPorPais(codigo)
      .subscribe(result => {
        this.provincias = result;
        this.cargandoProvincias = false;
        this.obtenerProvinciaSeleccionada();
      }, () => this.errorDestinos = true)
  }
  obtenerProvinciaSeleccionada() {
    var provinciaAeroplantaId = this.usuarioService.getAeroplantaActual().provinciaId.toLowerCase();
    let indiceProvincia = 0;

    for (let i = 0; this.provincias.length > i; i++) {
        if (this.provincias[i].id.toLowerCase() == provinciaAeroplantaId) {
          indiceProvincia = i;
          break;          
        } 
    }

    this.provinciaFormControl.setValue(this.provincias[indiceProvincia]);
    this.getLocalidadPorProvincia(this.provincias[indiceProvincia].codigo);
  }
  getLocalidadPorProvincia(codigo: string) {
    this.ciudades = [];
    this.configuracionService.getCiudadPorProvincia(codigo)
      .subscribe(result => {
        this.ciudades = result;
        this.cargandoCiudades = false;

        var ciudadAeroplantaId = this.usuarioService.getAeroplantaActual().ciudadId.toLowerCase();
        let indiceCiudad = 0;

        for (let i = 0; this.ciudades.length > i; i++) {
            if (this.ciudades[i].id.toLowerCase() == ciudadAeroplantaId) {
              indiceCiudad = i;
              break;          
            } 
        }

        this.codigoPostalFormControl.setValue(this.ciudades[indiceCiudad]);
        this.ciudadId.emit(this.ciudades[indiceCiudad].id);
        if (this.modificacionFormControl.value == true){
            this.codigoPostalFormControl.setValue(" ");
            document.getElementById("codigoPostalFormControl").focus();
            this.changeCiudad();
        }
            
      }, () => this.errorDestinos = true)
  }

  changeProvincia(): void {
    if (typeof (this.provinciaFormControl.value) === 'string') {
      this.provinciaFormControl.setValue(null);
      this.provinciavalid = true;
      this.provinciaFlag = false;
      this.provVal.emit(this.provinciaFlag);
    } else {
      this.provinciavalid = false;
      this.provinciaFlag = true;
      this.provVal.emit(this.provinciaFlag);
    }
  }

  changeCiudad(): void {
    if (typeof (this.codigoPostalFormControl.value) === 'string') {
      this.codigoPostalFormControl.setValue(null);
      this.ciudadvalid = true;
      this.ciudadFlag = false;
      this.ciuVal.emit(this.ciudadFlag);
    } else {
      this.ciudadvalid = false;
      this.ciudadFlag = true;
      this.ciuVal.emit(this.ciudadFlag);
    }
  }

  searchPais = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 || this.cargandoPaises ? [] :
        this.paises.filter(v => this.containsString(this.paisFormControl.value, v.nombre, v.codigo)).slice(0, 10))
    )
  searchProvincia = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 || this.cargandoProvincias ? [] :
        this.provincias.filter(v => this.containsString(this.provinciaFormControl.value, v.nombre, v.codigo)).slice(0, 10))
    )
  searchCiudad = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 || this.cargandoCiudades ? [] :
        this.ciudades.filter(v => this.containsString(this.codigoPostalFormControl.value, v.nombre, v.codigoPostal)).slice(0, 10))
    )
  /**
   * Determina si una cadena se encuentra dentro de otra
   * @param searchValue Valor a buscar
   * @param searchIn Valores sobre los que buscar searchValue
   */
  containsString(searchValue: string, ...searchIn: string[]): boolean {
    const concat = searchIn.toString();
    return concat.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
  }
  selectPais(e: any): void {
    console.log('e:' + e.item);
    this.paisFormControl.setValue(e.item.codigo);
  }
  selectProvincia(e: any): void {
    console.log('e:' + e.item);
    this.provinciaFormControl.setValue(e.item.codigo);
    this.codigoPostalFormControl.setValue(null);
    this.getLocalidadPorProvincia(e.item.codigo);
  }
  selectCiudades(e: any): void {
    this.codigoPostalFormControl.setValue(e.item);
    this.ciudadBuscada = e.item.nombre;

    this.ciudadId.emit(e.item.id);
  }

  formatterPais = (x: { nombre: string, codigo: string }) => `${x.nombre} - ${x.codigo}`;
  formatterProvincia = (x: { nombre: string, codigo: string }) => `${x.nombre} - ${x.codigo}`;
  formatterCiudad = (x: { nombre: string, codigoPostal: string }) => `${x.nombre} - ${x.codigoPostal}`;

  guardarDireccion() {
    let de:Ubicacion=new Ubicacion();
    de.ciudad=this.codigoPostalFormControl.value;

    this.dirEntrega.emit(new Ubicacion());
  }
  esCampoVacio(campo: any): boolean {

    return (campo == null || (campo != null && campo.length == 0));
  }

  desactivarModificar()
  {
    if (this.modificacionFormControl.value===false)
    {this.provinciaFormControl.disable();
    this.codigoPostalFormControl.disable();
    }
    else
    {
    this.provinciaFormControl.enable();
    this.codigoPostalFormControl.enable();
    }
  }



  get calleFormControl() {
    return this.form.get('calleFormControl');
  }
  get pisoFormControl() {
    return this.form.get('pisoFormControl');
  }
  get codigoPostalFormControl() {
    return this.form.get('codigoPostalFormControl');
  }
  get provinciaFormControl() {
    return this.form.get('provinciaFormControl');
  }
  get paisFormControl() {
    return this.form.get('paisFormControl');
  }
  get deptoFormControl() {
    return this.form.get('deptoFormControl');
  }
  get numeroFormControl() {
    return this.form.get('numeroFormControl');
  }
  get direccionesFormControl() {
    return this.form.get('direccionesFormControl');
  }
  get modificacionFormControl() {
    return this.form.get('modificacionFormControl');
  }

}
