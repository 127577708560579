<div class="card add-margin">
  <form [formGroup]="form">
    <div class="card-header">
      <button type="button" class="btn-close float-end" aria-label="Close" (click)="this.onCancelar()"></button>
      <h4 class="card-title">
        <div class="text-center">
          {{
          editando ? "Edición nivel de aprobación" : "Nuevo nivel de aprobación"
          }}
          {{ tipoNivel == enumTipoNivel.Precios ? "Precios" : "" }}
        </div>
      </h4>
    </div>

    <div class="card-body">
      <div class="mb-3-row">
        <label for="codigoFormControl" class="col-sm-3 col-form-label">
          Codigo:</label>
        <input id="codigoFormControl" type="text" class="col-sm-9" formControlName="codigoFormControl"
          [readOnly]="this.editando" />
      </div>

      <br />
      <br />

      <div class="mb-3-row">
        <label for="nombreFormControl" class="col-sm-3 col-form-label">
          Nombre:
        </label>
        <input id="nombreFormControl" type="text" class="col-sm-9" formControlName="nombreFormControl" />
      </div>

      <br />
      <br />
      <!-- Maxima porcentaje aprobación -->
      <div class="row">
        <div class="col-sm-6" [ngStyle]="{
            display:
              tipoNivel == enumTipoNivel.Bonif_y_recargos ? 'block' : 'none'
          }">
          <label for="maximoPorcentajeAprobacionFormControl" class="col-sm-7">
            Max. Porcentaje aprobacion:
          </label>
          <input id="maximoPorcentajeAprobacionFormControl" (keypress)="onlyNumberKey($event)" type="number"
            class="col-sm-5" formControlName="maximoPorcentajeAprobacionFormControl" />
          <div class="alert-danger" *ngIf="
              this.maximoPorcentajeAprobacionFormControl.invalid &&
              this.maximoPorcentajeAprobacionFormControl.errors.required &&
              (this.maximoPorcentajeAprobacionFormControl.dirty ||
                this.maximoPorcentajeAprobacionFormControl.touched)
            ">
            El valor debe ser numérico.
          </div>
        </div>
      </div>
      <!-- Maxima variación -->
      <div class="mb-3-row">
        <div class="mb-3 col-sm-6" [ngStyle]="{
            display: tipoNivel == enumTipoNivel.Precios && !tildoPreciosRetroactivos ? 'block' : 'none'
          }">
          <label for="maximaVariacionFormControl" class="col-sm-7">
            Máximo Variación:
          </label>
          <!-- <div class="col-sm-10"> -->
          <input id="maximaVariacionFormControl" class="col-sm-5" formControlName="maximaVariacionFormControl"
            (keypress)="onlyNumberKey($event)" />
          <div class="alert-danger" *ngIf="
              this.maximaVariacionFormControl.invalid &&
              this.maximaVariacionFormControl.errors.required &&
              (this.maximaVariacionFormControl.dirty ||
                this.maximaVariacionFormControl.touched)
            ">
            El valor debe ser numérico.
          </div>
        </div>
        <!-- </div> -->
      </div>

      <br />
      <br />
      <div class="row">
        <div class="mb-3 col-sm-6" [ngStyle]="{
            display: tipoNivel == enumTipoNivel.Precios ? 'block' : 'none'
          }">
          <div class="col-sm-3" style="display: inline">
            <input type="checkbox" id="puedeDesactivarFormControl" formControlName="puedeDesactivarFormControl" />
          </div><br>
          <label class="col-sm-8 col-form-label" for="puedeDesactivarFormControl">Aprueba desactivación</label>
        </div>
        <div class="mb-3 col-sm-6" [ngStyle]="{
            display: tipoNivel == enumTipoNivel.Precios ? 'block' : 'none'
          }">
          <div class="col-sm-3" style="display: inline">
            <input type="checkbox" (change)="usuarioPulsaPreciosRetroactivos($event.target.checked)"
              id="apruebaRetroactivosFormControl" formControlName="apruebaRetroactivosFormControl" />
          </div><br>
          <label class="col-sm-8 col-form-label" for="apruebaRetroactivosFormControl">Aprueba Precios
            Retroactivos</label>
        </div>
      </div>

      <div class="row" *ngIf="this.editando">
        <div class="mb-3 col-sm-12">
          <div class="col-sm-2" style="display: inline">
            <input type="checkbox" id="activoFormControl" formControlName="activoFormControl"
              [checked]="this.nivelAprobacionSeleccionado.activo" />
          </div>
          <label class="col-sm-6 col-form-label" for="activoFormControl">Activo</label>
        </div>
      </div>
    </div>

    <div class="card-footer d-flex justify-content-center">
      <span class="border">
        <button class="btn btn-primary" type="button" (click)="onSubmit()" [disabled]="guardandoNivel">
          <span *ngIf="!guardandoNivel">Guardar</span>
          <div *ngIf="guardandoNivel" class="d-flex align-items-center">
            <div class="spinner-border text-light spinner-border-sm" role="status"></div>
            <span>Guardando...</span>
          </div>
        </button>
      </span>
      <span class="border">
        <button type="button" class="btn btn-secondary" (click)="onCancelar()">
          Cancelar
        </button>
      </span>
    </div>
  </form>
</div>