import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-control-credito-index',
  templateUrl: './control-credito-index.component.html',
  styleUrls: ['./control-credito-index.component.scss']
})
export class ControlCreditoIndexComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
