import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HandleError } from '../http-error-handler.service';
import { AjusteManualAeroespecialidad } from '../shared/models/stock/AjusteManualAeroespecialidad';
import { AjusteManualCombustible } from '../shared/models/stock/AjusteManualCombustible';
import { ComprobanteSalida } from '../shared/models/stock/ComprobanteSalida';
import { Motivo } from '../shared/models/stock/Motivo';
import { Producto } from '../shared/models/stock/Producto';
import { StockAeroespecialidad } from '../shared/models/stock/StockAeroespecialidad';
import { Transaccion } from '../shared/models/stock/Transaccion';
import { Trasvase } from '../shared/models/stock/Trasvase';

@Injectable({
  providedIn: 'root'
})
export class TransaccionesService {

  stockbaseUrl = environment.apiServer.stockBaseUrl;
  private movimientosURL = this.stockbaseUrl + '/TransaccionesV1';
  private aeroplantasURL = this.stockbaseUrl + '/AeroplantasV1';

  private handleError: HandleError;

  constructor(private httpClient: HttpClient) { }

  getTransacciones(codigo: string, fechaInicio: string, fechaFin: string): Observable<Transaccion[]> {
    return this.httpClient.get<Transaccion[]>(this.movimientosURL + '?codigoAeroplanta=' + codigo + '&fechaDesde=' + fechaInicio + '&fechaHasta=' + fechaFin);
  }

  getTransaccionPorId(id: string): Observable<Transaccion> {
    return this.httpClient.get<Transaccion>(this.movimientosURL + '/' + id)
  }

  getTransaccionPorIdConComprobante(id: string): Observable<ComprobanteSalida> {
    return this.httpClient.get<ComprobanteSalida>(this.movimientosURL + '/' + id + '/Comprobantes')
  }

  postTrasvase(trasvase: Trasvase): Observable<Trasvase> {
    return this.httpClient.post<Trasvase>(this.movimientosURL + '/Trasvasar', trasvase);
  }

  anularMovimiento(id: string){
    return this.httpClient.put(this.movimientosURL + '/' + id + '/Anulacion', '');
  }

  getMotivos(): Observable<Motivo[]> {
    return this.httpClient.get<Motivo[]>(this.movimientosURL + '/Motivos')
  }

  realizarAjusteManualCombustible(ajuste: AjusteManualCombustible): Observable<AjusteManualCombustible> {
    return this.httpClient.post<AjusteManualCombustible>(this.movimientosURL + '/AjusteManual', ajuste);
  }

  getAeroespecialidades(codigoAeroplanta): Observable<StockAeroespecialidad[]> {
    return this.httpClient.get<StockAeroespecialidad[]>(this.aeroplantasURL + '/'+ codigoAeroplanta + '/StockAeroespecialidades')
  }

  realizarAjusteManualAeroespecialidad(codigoAeroplanta, ajuste: AjusteManualAeroespecialidad): Observable<AjusteManualAeroespecialidad> {
    return this.httpClient.put<AjusteManualAeroespecialidad>(this.movimientosURL + '/AjusteManualAeroespecialidad?codigoAeroplanta=' + codigoAeroplanta, ajuste);
  }
}
