import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ComprobanteService } from 'src/app/facturacion/comprobante.service';
import { AeroplantasService } from 'src/app/shared/aeroplantas.service';
import { TipoVenta } from 'src/app/shared/enums/tipoVenta';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { ConsultaPrecioFacturaDTO } from 'src/app/shared/models/precios/ConsultaPrecioFacturaDTO';
import { UtilPrecio } from 'src/app/shared/utiles/UtilPrecios';
import { VisorComprobanteComponent } from 'src/app/shared/visor-comprobante/visor-comprobante.component';
import { MessageService } from 'src/app/shell/message.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-listado-precios-facturados',
  templateUrl: './listado-precios-facturados.component.html',
  styleUrls: ['./listado-precios-facturados.component.css']
})
export class ListadoPreciosFacturadosComponent implements OnInit {
    
  @Input() listadoPrecios :  ConsultaPrecioFacturaDTO[];
  @Input() esperandoCarga: boolean;
  @Input() fechaDesde: string;
  @Input() fechaHasta: string;

  tablaPreciosFacturados = new UntypedFormGroup({ });
  listadoPreciosFiltrados :  ConsultaPrecioFacturaDTO[];
  linkGrafana = environment.grafana.preciosUtilizados;

  obteniendoComprobante: boolean = false;

  busquedaFormControl=new UntypedFormControl();
  
  constructor(
    public utilPrecio: UtilPrecio, 
    public aeroplantaService:AeroplantasService,
    private messageService: MessageService,
    private modal: NgbModal,
    private comprobanteService: ComprobanteService) { 

  }

  ngAfterViewInit() {
    $("#grafana").on("load", function(e){
      console.log("load grafana");
       
      $(this).contents().find('head').children().append("<style> .sidemenu{ display: none !important; } </style>");
      
      $(this).contents().find('head').children().append("<style> div.css-6v8co2{ display: none !important; } button.css-1j9p9hg{ display: none !important; } button.css-m46xa3{ display: none !important; } div.css-umstnt{ display: none !important; } </style>");
    });
  }

  ngOnChanges(): void {
    /*
      this.listadoPreciosFiltrados = this.listadoPrecios;
      this.filtrarPrecios();
    */
  }

  ngOnInit() {
    
  }

  filtrarPrecios() {
    this.listadoPreciosFiltrados = this.listadoPrecios.filter(a => {
      let agregar = true;

      if (this.busqueda.value) {
        agregar = agregar && a.razonSocial.toLowerCase().indexOf(this.busqueda.value.toLowerCase()) > -1;        
        agregar = agregar || (a.numeroDocumento && a.numeroDocumento.toLowerCase().indexOf(this.busqueda.value.toLowerCase()) > -1);
        agregar = agregar || ((a.prefijo.toString() + "-" + a.numeroComprobante.toString()).indexOf(this.busqueda.value) > -1);

      }
      
      return agregar;
    });   

    console.log("a");
  }

  get busqueda() {
    return this.busquedaFormControl;
  }

  obtenerTipoVenta(i:number)
  {
    if(i == 0){
      return "-";
    }
    
    return TipoVenta[i];
  }

  mostrarFactura(id) {
    this.obteniendoComprobante = true;
    this.comprobanteService.obtenerComprobantePorId(id)
      .subscribe(result => {
        let comprobante = result;
        this.obteniendoComprobante = false;
        if (comprobante != null) {
          let modalRef = this.modal.open(VisorComprobanteComponent, { centered: true, size: 'lg' });
          modalRef.componentInstance.facturaObtenida = comprobante;
        }else{
          this.messageService.showErrorMessage('Error al obtener la factura');
        }
      }, (err: ErrorModel) =>{
        this.messageService.showErrorMessage('Error al obtener la factura');
      });
  }

}

