import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ComprobanteService } from 'src/app/facturacion/comprobante.service';
import { CondicionVentaFactura } from '../enums/condicionVentaFactura';
import { TipoMoneda } from '../enums/tipoMoneda';
import { Comprobante } from '../models/facturacion/Comprobante';
import { Factura } from '../models/facturacion/Factura';
import { Remito } from '../models/facturacion/Remito';

@Component({
  selector: 'app-previsualizacion-comprobante',
  templateUrl: './previsualizacion-comprobante.component.html',
  styleUrls: ['./previsualizacion-comprobante.component.css']
})
export class PrevisualizacionComprobanteComponent implements OnInit {

  @Input() remitosSeleccionados: Remito[] = [];
  @Input() facturaCalculada: Factura[];
  @Input() idRefacturacion: string;

  facturaEmitida: Factura[] = [];
  remitosId: string[] = [];
  columnas: any[] = [];
  obteniendoFactura: boolean = false;;
  facturando: boolean = false;
  calcFact: boolean = false;
  exportando: boolean = false;
  elementType = 'url';
  aeroplantaSoloPad: boolean = false;

  facturaAEmitir = new UntypedFormGroup({});


  constructor(public modal: NgbModal, public activeModal: NgbActiveModal, private facturacionService: ComprobanteService) { }
  ngOnInit() {

    this.calcFact = true;
    this.remitosSeleccionados.forEach(element => this.remitosId.push(element.id));

    if (this.facturaCalculada === null) {
      this.obtenerFacturaCalculada(this.remitosId);
    }

    this.columnas = [
      { field: 'Codigo', header: 'Cod.' },
      { field: 'Detalle', header: 'Descripción' },
      { field: 'Cantidad', header: 'Cant.' },
      { field: 'PUnitario', header: 'Valor Unitario' },
      { field: 'Total', header: 'Total' },
    ];

  }

  obtenerFacturaCalculada(remitos: string[]) {
    this.obteniendoFactura = true;
    this.facturacionService.obtenerFacturaCalculada(remitos)
      .subscribe(result => {
        this.facturaCalculada = result;
        //this.spinner.hide('spinnerFactura');
        this.obteniendoFactura = false;
        // this.aeroplanta = this.usuarioService.aeroplantas.filter((a => a.codigoAeroplanta == this.facturaCalculada.codigoAeroplanta));
        // this.aeroplantaSoloPad = this.aeroplanta[0].soloPAD;
      })
  }

  // pantalla

  set_format_cuit(cuit: string) {
    if (cuit.includes("-")){
      return (cuit)
    }else{
      cuit = cuit.substr(0, 2) + - + cuit.substr(2, 8) + - + cuit.substr(10);
    return (cuit)
    }    
  }

  // validaciones

  clienteDiscriminaImpuestos(factura: Factura) {
    if (factura.cliente.condicionIvaDiscrimina) {
      return true;
    } else {
      return false;
    }
  }


  clienteExento(factura: Factura) {
    return (factura.cliente.condicionIvaCodigoAfip == '02');
  }




  // getters

  obtenerPorcentaje(factura, leyenda: string): number {
    for (let reng of factura.renglones) {
      for (let imp of reng.impuestosPercepciones) {
        if (imp.leyenda == leyenda) {
          return imp.valor;
        }
      }
    }
  }

  obtenerDescripcionMoneda(tipoMoneda) {
    return tipoMoneda == 0 ? 'PESOS' : 'DOLARES';
  }

  incluirPorcentajeImpuesto(leyenda: string) {
    if(leyenda.includes('ITC') || leyenda.includes('IDC')){
      return false;
    }else{
      return true;
    }
  }
}
