import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { AerovaleDetalle } from 'src/app/shared/models/despacho/AerovaleDetalle';
import { AerovaleDetalleAlije } from 'src/app/shared/models/despacho/AerovaleDetalleAlije';
import { Aerovale } from 'src/app/shared/models/despacho/Aerovale';
import { Location } from '@angular/common';
import { TipoPago } from 'src/app/shared/enums/tipoPago';
import { DomSanitizer } from '@angular/platform-browser';
import { EstadoAerovale } from 'src/app/shared/enums/estadoAerovale';
import { EstadoPruebaLaboratorio } from 'src/app/shared/enums/estadoPruebaLaboratorio';
import { TipoVuelo } from 'src/app/shared/enums/tipoVuelo';
import { Observacion } from 'src/app/shared/models/despacho/Observacion';
import { TipoAerovale } from 'src/app/shared/enums/tipoAerovale';
import { ComprobanteService } from 'src/app/facturacion/comprobante.service';
import { Comprobante } from 'src/app/shared/models/facturacion/Comprobante';
import { RemitoFox } from 'src/app/shared/models/despacho/RemitoFox';
import { AerovalesService } from '../aerovales.service';
import { SharedService } from 'src/app/shared/shared.service';
import { UsuarioService } from 'src/app/account/usuario.service';
import { Aeroplanta } from 'src/app/shared/models/despacho/Aeroplanta';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { MessageService } from 'src/app/shell/message.service';

@Component({
  selector: 'app-visor-remito-aerovale',
  templateUrl: './visor-remito-aerovale.component.html',
  styleUrls: ['./visor-remito-aerovale.component.css']
})
export class VisorRemitoAerovaleComponent implements OnInit {

  @Input() aerovaleDetalle: AerovaleDetalle;
  @Input() aerovaleDetalleAlije: AerovaleDetalleAlije;
  @Input() aerovale: Aerovale;
  // @Input() remitoObtenido: Comprobante
  @Input() remitoFox: RemitoFox;
  
  mostrarComentario: boolean = false;

  obteniendoAeroplanta: boolean = false;

  enumTipoAerovale = TipoAerovale;
  TipoVuelo = TipoVuelo;
  ObserNormal: Observacion[] = [];
  ObserAnulado: Observacion[] = [];
  esContingencia: boolean=false;
  aeroplantaCodigo: string;
  buscandoComprobante: boolean = false;
  aeroplanta: Aeroplanta[] = [];
  esReemplazo: boolean;
  
  constructor(
    private location: Location,
    private sanitizer: DomSanitizer,
    private facturacionService: ComprobanteService,
    private sharedService: SharedService,
    private aerovaleServices: AerovalesService,
    private comprobanteService: ComprobanteService,
    private usuarioService: UsuarioService,
    private messageService: MessageService
  ) { }

  operadorImagePath;
  clienteImagePath;


  ngOnInit() {
    this.aeroplantaCodigo = this.usuarioService.getAeroplantaActual().codigoAeroplanta;
    this.esContingencia= Number(this.aerovaleDetalle.prefijo)<200
    this.esReemplazo = Number(this.aerovaleDetalle.prefijo) >= 900
    console.log(JSON.stringify(this.remitoFox));
    if (this.aerovaleDetalle) {
      this.generarResourceUrls();
    }
    let j = 0;
    let h = 0;
    for (let i = 0; this.aerovaleDetalle.observaciones.length > i; i++) {
      if (this.aerovaleDetalle.observaciones[i].tipoObservacion == 0) {
        this.ObserNormal[j] = this.aerovaleDetalle.observaciones[i];
        j += j;
      } else {
        this.ObserAnulado[h] = this.aerovaleDetalle.observaciones[i];
        h += h;
      }
    }
    this.obtenerAeroplanta();
  }

  obtenerAeroplanta() {
    this.obteniendoAeroplanta = true;
    this.comprobanteService.getDatosAeroplanta(this.aeroplantaCodigo)
      .subscribe(result => {
        this.aeroplanta = result;
        this.obteniendoAeroplanta = false;
      }, (err: ErrorModel) => {
        this.messageService.showErrorMessage('Hubo un error al obtener los datos de la aeroplanta.')
      });
  }

  print(): void {
    let printContents = document.getElementById('aerovaleRemitoPrint').innerHTML;
    //Se eliminan datos innecesarios para imprimir remitos
    let printResponse = printContents.replace('Imprimir comentario', '');
    let printResponse2 = printResponse.split('src=""').join('');
    let printResponse3 = printResponse2.split('checkbox').join('hidden');
    this.sharedService.print2(printResponse3);
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (this.aerovaleDetalle) {
      this.generarResourceUrls();
    }
  }

  generarResourceUrls() {
    this.operadorImagePath = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,' + this.aerovaleDetalle.firmaOperador);
    this.clienteImagePath = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,' + this.aerovaleDetalle.firmaCliente);
  }


  getTipoPago(tipo: number) {
    return TipoPago[tipo];
  }

  getTipoAerovale(tipo: number) {
    return TipoAerovale[tipo];
  }

  getTipoVuelo(tipo: number){
    return TipoVuelo[tipo];
  }

  mostrarFirma(): boolean {
    let mostrar: boolean = false;
    mostrar = (EstadoAerovale[this.aerovaleDetalle.estado] == EstadoAerovale.Finalizado || 
              EstadoAerovale[this.aerovaleDetalle.estado] == EstadoAerovale.FinalizadoSinFirma || 
              EstadoAerovale[this.aerovaleDetalle.estado] == EstadoAerovale.Sincronizado ||
              EstadoAerovale[this.aerovaleDetalle.estado] == EstadoAerovale.Anulado);
    return mostrar;
  }

  firmoOperador()
  {
    return (this.aerovaleDetalle.firmaOperador != '' || this.aerovaleDetalle.pathFirmaOperador != null);
  }
  firmoCliente()
  {
    return (this.aerovaleDetalle.firmaCliente != '' || this.aerovaleDetalle.pathFirmaCliente != null);
  }

  ImagenFirmaOperador(): string
  {
    let firma : string = ""
    if (this.aerovaleDetalle.firmaOperador != null && this.aerovaleDetalle.firmaOperador != "")
    {firma = this.operadorImagePath;}
    if (this.aerovaleDetalle.pathFirmaOperador != null && this.aerovaleDetalle.pathFirmaOperador != "")
    {firma = this.aerovaleDetalle.pathFirmaOperador; }
    return firma;
  }

  ImagenFirmaCliente(): string
  {
    let firma : string = ""
    if (this.aerovaleDetalle.firmaCliente != null && this.aerovaleDetalle.firmaCliente != "")
    {firma = this.clienteImagePath;}
    if (this.aerovaleDetalle.pathFirmaCliente != null && this.aerovaleDetalle.pathFirmaCliente != "")
    {firma = this.aerovaleDetalle.pathFirmaCliente; }
    return firma;
  }

  goBack() {
    this.location.back(); // <-- go back to previous location on cancel
  }

  getEstadoPruebaLaboratorio(estado: EstadoPruebaLaboratorio): string {
    return EstadoPruebaLaboratorio[estado];
  }

  esAlije() {
    return this.aerovaleDetalle.tipoAerovale === 3;
  }

  obtenerAforadorInicioDeAerovale()
  {
    /* 0: AerovaleAerocombustible | 1: AerovaleAeroespecialidad | 2: AerovaleCombustible | 3: AerovaleAlije */
    let resultado=0;
    if (this.aerovaleDetalle.tipoAerovale===0)
    {
    resultado=this.aerovaleDetalle.detalleAerocombustible.aforadorInicio;
    }
    if (this.aerovaleDetalle.tipoAerovale===2)
    {
      resultado= this.aerovaleDetalle.detalleCombustible.aforadorInicio;
    }
    return resultado;
  }
  obtenerAforadorFinDeAerovale()
  {
    /* 0: AerovaleAerocombustible | 1: AerovaleAeroespecialidad | 2: AerovaleCombustible | 3: AerovaleAlije */
    let resultado = 0;
    if (this.aerovaleDetalle.tipoAerovale===0)
    {
     resultado = this.aerovaleDetalle.detalleAerocombustible.aforadorFinal;
    }
    if (this.aerovaleDetalle.tipoAerovale===2)
    {
      resultado = this.aerovaleDetalle.detalleCombustible.aforadorFinal;
    }
    return resultado;
  }

  esAerovaleAdjuntado()
  {
    let es:boolean= false;

    let nombreArchivoOperador: string='o';
    let nombreArchivoCliente: string='c';
    if (this.aerovaleDetalle.pathFirmaOperador!=null)
  {
    let pos1: number= this.aerovaleDetalle.pathFirmaOperador.indexOf('_');
    let posFinal1: number= this.aerovaleDetalle.pathFirmaOperador.indexOf('?')
    nombreArchivoOperador=this.aerovaleDetalle.pathFirmaOperador.substring(pos1,posFinal1);
  }
  if (this.aerovaleDetalle.pathFirmaCliente!=null)
  {
    let pos2: number= this.aerovaleDetalle.pathFirmaCliente.indexOf('_');
    let posFinal2: number= this.aerovaleDetalle.pathFirmaCliente.indexOf('?')
    nombreArchivoCliente=this.aerovaleDetalle.pathFirmaCliente.substring(pos2,posFinal2);
  }
    es= nombreArchivoCliente.toString()===nombreArchivoOperador.toString();
    return es;
  }

}
