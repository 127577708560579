<form [formGroup]="form" (ngSubmit)="onSubmit()">

  <div class="modal-header bg-light">
    <h4 class="modal-title" *ngIf="this.tipoSurtidor == 0">{{ editando ? "Modificar surtidor" : "Nuevo surtidor" }}</h4>
    <h4 class="modal-title" *ngIf="this.tipoSurtidor == 1">{{ editando ? "Modificar dispenser" : "Nuevo dispenser" }}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()"></button>
  </div>

  <div class="modal-body">

    <div class="row">
      <div class="mb-3 row col-md-12">
        <label for="codigoFormControl" class="col-sm-5 col-form-label">*Codigo:</label>
        <div class="col-sm-7">
          <input class="form-control" formControlName="codigoFormControl" maxlength="50" disabled>
          <div *ngIf="codigoFormControl.invalid && codigoFormControl.errors.required &&
                        (codigoFormControl.dirty || codigoFormControl.touched)" class="alert alert-danger">
            Requerido</div>
          <div *ngIf="this.chequearCodigo()" class="alert alert-danger"> El Código está en uso</div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="mb-3 row col-md-12">
        <label for="nombreFormControl" class="col-sm-5 col-form-label">*Nombre:</label>
        <div class="col-sm-7">
          <input class="form-control" formControlName="nombreFormControl" maxlength="50">
          <div
            *ngIf="nombreFormControl.invalid && nombreFormControl.errors.required && (nombreFormControl.dirty || nombreFormControl.touched)"
            class="alert alert-danger">Requerido</div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="mb-3 row col-md-12">
        <label for="productoFormControl" class="col-md-5 col-form-label">Producto</label>
        <div class="col-md-7">
          <select [compareWith]="compareProductos" id="productoFormControl" class="form-select"
            ngbTooltip="Seleccione el producto." formControlName="productoFormControl" (change)="this.filtrarTanque()">
            <option *ngFor="let producto of productos" [ngValue]="producto">
              ({{ producto.codigoProducto }}) - {{ producto.nombreProducto }}
            </option>
          </select>
          <loading-text-spinner *ngIf="cargandoProductos" [error]="errorProductos" message="Obteniendo productos..."
            color="primary">
          </loading-text-spinner>
          <div class="alert alert-danger"
            *ngIf="(this.productoFormControl.invalid && this.productoFormControl.errors.required && (this.productoFormControl.dirty || this.productoFormControl.touched))">
            Debe seleccionar un producto obligatoriamente.
          </div>
        </div>
      </div>
    </div>

    <div style="padding: 1em; border: 1px solid #ccc;">
      <!-- *ngIf="this.tanquesGenerales!=null && this.tanquesGenerales.length>0" -->
      <div class="row">
        <div class="mb-3 row col-md-12">
          <label for="tanqueGeneralFormControl" class="col-sm-2 col-form-label">*Tanque</label>
          <div class="col-sm-7">
            <select id="tanqueGeneralFormControl" class="form-select" ngbTooltip="Seleccione el tanque."
              formControlName="tanqueGeneralFormControl" [(ngModel)]="tanqueSeleccionado">
              <option *ngFor="let t of this.tanquesGenerales" [ngValue]="t" [disabled]="!this.productoSeleccionado">
                {{ t.nombre }} ({{ t.productoActual.codigoProducto }} - {{ t.productoActual.nombreProducto }})
              </option>
            </select>
            <loading-text-spinner *ngIf="obteniendoTanques" message="Obteniendo tanques y grupos..." color="primary">
            </loading-text-spinner>
          </div>
          <div class="col-sm-3">
            <button (click)="asignarTanqueSeleccionado()" type="button" class="btn btn-primary" [disabled]="!this.productoSeleccionado || this.tanquesGenerales.length == 0|| this.cargandoProductos">Asignar</button>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="mb-3 row col-md-12">
          <div class="col-sm-12"><b>Tanques asignados</b></div>
        </div>
      </div>
      <div *ngIf="this.tanquesDelSurtidor != null && tanquesDelSurtidor.length > 0" class="row">
        <div class="mb-3 row col-md-12" *ngFor="let t of this.tanquesDelSurtidor">
          <div class="col-sm-9">{{t.productoActual.codigoProducto}} - {{t.nombre}}</div>
          <div class="col-sm-3"><button type="button" (click)="desasignarTanque(t)"
              class="btn btn-danger btn-sm col-sm-12"><i class="fa fa-trash col-sm-12" aria-hidden="true"></i></button>
          </div>
        </div>
      </div>


      <div *ngIf="this.tanquesDelSurtidor == null || tanquesDelSurtidor.length == 0" class="row">
        <div class="mb-3 row col-md-12">
          <div class="col-sm-12">No hay tanques asignados.</div>
        </div>
      </div>
    </div>

    <!--PICOS-->
    <div [formGroup]="picoForm" style="padding: 1em; border: 1px solid #ccc; margin-top: 0.5em;">

      <div class="row">
        <div class="mb-3 row col-md-12">
          <label for="nombrePicoFormControl" class="col-sm-2 col-form-label">*Nombre</label>
          <div class="col-sm-7">
            <input class="form-control" formControlName="nombrePicoFormControl" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="mb-3 row col-md-12">
          <label for="aforadorPicoFormControl" class="col-sm-2 col-form-label">*Aforador</label>
          <div class="col-sm-7">
            <input class="form-control" maxlength="10" (keypress)="onlyDecimalPositivo($event)"
              formControlName="aforadorPicoFormControl" />
          </div>
          <div class="col-sm-3">
            <button type="button" (click)="asignarPico()" class="btn btn-primary" [disabled]="!this.productoSeleccionado">Asignar</button>
          </div>
        </div>
      </div>
      <!--CARTER-->
      <div class="row">
        <div class="col-sm-4">
          <div class="mb-3 row">
            <div class="col-sm-8">
              <div class="form-check">
                <input type="radio" class="form-check-input" id="exampleCheck1" value="0"
                  formControlName="tipoPicoFormControl" />
                <label class="form-check-label" for="exampleCheck1">Carter</label>
              </div>
            </div>
          </div>
        </div>
        <!--GATILLO-->
        <div class="col-sm-4">
          <div class="mb-3 row">
            <div class="col-sm-8">
              <div class="form-check">
                <input type="radio" class="form-check-input" id="exampleCheck2" value="1"
                  formControlName="tipoPicoFormControl" />
                <label class="form-check-label" for="exampleCheck2">Gatillo</label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="mb-3 row col-12">
          <div class="col-12"><b>Aforadores asignados</b></div>
        </div>
      </div>
      <div class="row" *ngIf="this.picos != null && this.picos.length > 0">
        <div class="mb-3 row col-12" *ngFor="let pic of this.picos">
          <div class="col-4">{{pic.nombre}} - {{pic.aforadorActual}}</div>
          <label class="col-2" for="checkDefecto">Principal</label>
          <input class="col-3" type="checkbox" (click)="checkearDefecto(pic)" [checked]="pic.porDefecto" id="checkDefecto"/>
          <div class="col-3"><button type="button" (click)="desasignarPico(pic)" class="btn btn-danger btn-sm col-12"><i
                class="fa fa-trash col-12" aria-hidden="true"></i></button></div>
        </div>
      </div>

      <div *ngIf="this.picos == null || this.picos.length == 0" class="row">
        <div class="mb-3 row col-md-12">
          <div class="col-sm-12">No hay aforadores asignados.</div>
        </div>
      </div>
    </div>
    <small class="text-danger"> *Campos obligatorios.</small>
  </div>


  <div class="alert alert-danger" *ngIf="this.tanquesDelSurtidor.length===0 && this.mensajeError===true"> Debe asignarse
    al menos un tanque y/o al menos un grupo de tanques al surtidor </div>
  <div class="alert alert-danger" *ngIf="!this.camposValidados() && this.mensajeError===true"> Deben completarse todos
    los campos </div>

  <div class="modal-footer bg-light">
    <span class="border">
      <button class="btn btn-primary" [disabled]="!this.form.valid || this.picos.length == 0 || guardando" type="submit">
        {{ guardando? "Guardando..." : "Guardar cambios" }}
      </button>
    </span>
    <span class="border">
      <button type="button" class="btn btn-secondary" [disabled]="guardando"
        (click)="activeModal.dismiss('Close clicked')">Cancelar</button>
    </span>
  </div>


</form>
