import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProductoService } from 'src/app/abm/producto/producto.service';
import { Meses } from 'src/app/shared/enums/Meses';
import { Producto } from 'src/app/shared/models/abm/Producto';
import { ResolucionMensual } from 'src/app/shared/models/cierreTurno/ResolucionMensual';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { MessageService } from 'src/app/shell/message.service';
import { ReportesService } from '../../reportes.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VisorInformeResolucionComponent } from './visor-informe-resolucion/visor-informe-resolucion.component';
import { ModalGenerarResolucionComponent } from './modal-generar-resolucion/modal-generar-resolucion.component';
import { ExportExcelServiceService } from 'src/app/shared/exportar-excel/export-excel-service.service';
import { ModalJustificacionResolucionComponent } from './modal-justificacion-resolucion/modal-justificacion-resolucion.component';
import { UsuarioService } from 'src/app/account/usuario.service';
import { Aeroplanta } from 'src/app/shared/models/despacho/Aeroplanta';
import { ComprobanteService } from 'src/app/facturacion/comprobante.service';
import { PreciosService } from 'src/app/precios/precios.service';
import { CierreTurnoService } from 'src/app/cierreTurno/cierre-turno.service';
import { UsuarioDTO } from 'src/app/shared/models/notificaciones/UsuarioDTO';

@Component({
  selector: 'app-resolucion-mensual',
  templateUrl: './resolucion-mensual.component.html',
  styleUrls: ['./resolucion-mensual.component.scss']
})
export class ResolucionMensualComponent implements OnInit {

  fechaDesde: Date;
  fechaHasta: Date;

  meses = Meses;
  cargandoAeroplantas: boolean;
  grabando: boolean;
  productos;
  mostrarTabla: boolean = false;
  contadorAeroplanta: string;
  resoluciones: ResolucionMensual[] = [];
  aeroplantasGeneradas: string[] = [];
  aeroplantasFiltradas;
  terceras: boolean = false;
  cargandoResolucion: boolean = false;
  busqueda: UntypedFormGroup;
  obteniendoResolucion: boolean;
  generada: boolean = false;
  resolucionMensual: ResolucionMensual = new ResolucionMensual();
  aeroplantas: any[];
  aeroplantasSeleccionadas: any[];
  aeroplantasAJustificar;
  puedeGenerarse: boolean;
  chequeandoPermisos: boolean;


  constructor(
    private fb: UntypedFormBuilder,
    private spinner: NgxSpinnerService,
    private messageService: MessageService,
    private productoService: ProductoService,
    private modal: NgbModal,
    private reportesService: ReportesService,
    private activeModal: NgbActiveModal,
    private excelService: ExportExcelServiceService,
    private preciosService: PreciosService,
    private cierreTurnoService: CierreTurnoService,
    private usuarioService: UsuarioService
  ) {
    let fecha = new Date();
    this.busqueda = this.fb.group({
      mesesFormControl: [null, Validators.required],
      anioFormControl: [fecha.getFullYear(), Validators.required]
    });
  }

  ngOnInit(): void {
    this.obtenerAeroplantas();
    this.setProductos();
  }


  obtenerAeroplantas() {
    let aeroplantasAFiltrar = ['TDL', 'JNI', 'ENO', 'DTO', 'CUT', 'SCL', 'SCE'];
    this.cargandoAeroplantas = true;
    this.preciosService.obtenerTodasLasAeroplantas().subscribe(a => {
      this.aeroplantas = a.sort((a, b) => { if (a.nombre > b.nombre) { return 1 } else { return -1 } });
      this.aeroplantas = this.aeroplantas.filter(a => !aeroplantasAFiltrar.includes(a.codigoAeroplanta))
      this.aeroplantas.forEach(aeroplanta => aeroplanta.seleccionada = false);
      this.cargandoAeroplantas = false;
    }, () => {
      this.cargandoAeroplantas = false;
    });

  }

  setProductos() {
    this.productos = {
      205000: "AEROKEROSENE JET A-1",
      202000: "AVGAS 100LL",
      409700: "NAFTA SUPER XXI",
      405200: "INFINIA DIESEL"
    }
  }


  async obtenerResolucion() {
    this.grabando = true;
    this.mostrarLoading();
    this.resolucionMensual.renglonesResolucion = [];
    this.mostrarTabla = true;
  
    const aeroplantaEzeiza = this.aeroplantasFiltradas.some(a => a.codigoAeroplanta === 'EZE');
    const aeroplantaAeroparque = this.aeroplantasFiltradas.some(a => a.codigoAeroplanta === 'AEP');
    if((aeroplantaEzeiza && aeroplantaAeroparque) || (aeroplantaEzeiza && this.aeroplantasFiltradas.length > 1) || (aeroplantaAeroparque && this.aeroplantasFiltradas.length > 1)) {
      this.puedeGenerarse = false;
    } else this.puedeGenerarse = true;

    this.chequeandoPermisos = true;
    for await (let a of this.aeroplantasFiltradas) {
        try {
          let resolucion = await this.reportesService.obtenerResolucionMensual(a.codigoAeroplanta, this.fechaDesde.toDateString(), this.fechaHasta.toDateString());
          this.resoluciones.push(resolucion);
          this.resolucionMensual.ano = resolucion.ano;
          this.resolucionMensual.mes = resolucion.mes;
          this.resolucionMensual.generada = resolucion.generada;
          if(resolucion.generada) this.aeroplantasGeneradas.push(resolucion.renglonesResolucion[0].codigoAeroplanta)
          this.resolucionMensual.renglonesResolucion = this.resolucionMensual.renglonesResolucion.concat(resolucion.renglonesResolucion);
          this.chequearPermisos(this.resolucionMensual.renglonesResolucion);
        } catch(e) {
          this.messageService.showErrorMessage(e.description);
          continue;
        }
    
        
    }

    if (this.resolucionMensual.renglonesResolucion.length > 0) {
      this.completarTotales();
    }
    console.log(this.resolucionMensual)

  }



  async chequearPermisos(renglones) {
    
    let dni = this.usuarioService.getDniOperador();
    let ultima;
    let aeroplanta;
    for await (let renglon of renglones) {
      if (ultima !== renglon.codigoAeroplanta) {
        aeroplanta = await this.cierreTurnoService.getAeroplantas(renglon.codigoAeroplanta);
        ultima = renglon.codigoAeroplanta
      }
     
      if (aeroplanta.responsable) {
        if ((aeroplanta.responsable.dni == dni || aeroplanta.sustituto.dni == dni)) {
          renglon.puedeAceptar = true;
        } else renglon.puedeAceptar = false;
      }
    }

    console.log(renglones)
  }

  keysMeses(): Array<string> {
    var keys = Object.keys(this.meses);
    keys = keys.slice(keys.length / 2);
    return keys;
  }

  obtenerFechaInicioMes(mes: number) {
    return new Date(this.anioFormControl.value, mes, 1);
  }

  obtenerFechaFinMes(mes: number) {
    return new Date(this.anioFormControl.value, mes + 1, 0);
  }

  onChange(e: any) {
    console.log('target: ' + e.target.selectedIndex);
    this.fechaDesde = this.obtenerFechaInicioMes(e.target.selectedIndex - 1);
    this.fechaHasta = this.obtenerFechaFinMes(e.target.selectedIndex - 1);
    console.log('inicio: ' + this.getFechaDesde());
    console.log('fin: ' + this.getFechaHasta());
  }

  getFechaDesde() {
    return this.formatDate(this.fechaDesde.getFullYear(), this.fechaDesde.getMonth() + 1, this.fechaDesde.getDate(), 0, 0, 0);
  }

  getFechaHasta() {
    return this.formatDate(this.fechaHasta.getFullYear(), this.fechaHasta.getMonth() + 1, this.fechaHasta.getDate(), 20, 59, 59);
  }

  formatDate(year, month, day, hour, minute, seconds): string {
    return year + '-' + month.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + '-' + day.toLocaleString(undefined, { minimumIntegerDigits: 2 })
      + 'T' + hour.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + minute.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + seconds.toLocaleString(undefined, { minimumIntegerDigits: 2 });
  }

  mostrarLoading() {
    this.obteniendoResolucion = true;
    this.spinner.show('spinnerGrafico');
  }

  ocultarLoading() {
    this.obteniendoResolucion = false;
    this.spinner.hide('spinnerGrafico');
  }

  completarTotales() {
    for (const item of this.resolucionMensual.renglonesResolucion) {
      item.porcentual = item.porcentual ? item.porcentual : 0;
      item.nombreProducto = this.productos[item.codigoProducto];
    }
    this.resolucionMensual.renglonesResolucion.sort((a, b) => {
      let aeroplanta1 = a.codigoAeroplanta;
      let aeroplanta2 = b.codigoAeroplanta;
      return aeroplanta1.localeCompare(aeroplanta2);
    });
    this.grabando = false;
    this.chequeandoPermisos = false;
    this.cargandoResolucion = false;
    this.ocultarLoading();
  }

  filtrarMovimientos() {
    this.cargandoResolucion = true;
    this.aeroplantasFiltradas = this.aeroplantas.filter(aeroplanta => aeroplanta.seleccionada);
    if(this.aeroplantasFiltradas.length == 0) {
      this.messageService.showErrorMessage('No hay aeroplantas seleccionadas');
      this.ocultarLoading();
      this.cargandoResolucion = false;
      return;
    } else this.obtenerResolucion();
  }

  generarResolucion() {
    let modalRef = this.modal.open(ModalGenerarResolucionComponent, { centered: true, backdrop: 'static', keyboard: false });
    modalRef.componentInstance.codigoAeroplanta = this.resolucionMensual.renglonesResolucion[0].codigoAeroplanta;
    modalRef.componentInstance.fdesde = this.getFechaDesde();
    modalRef.componentInstance.fhasta = this.getFechaHasta();
    modalRef.componentInstance.productos = this.productos;
    if(this.resolucionMensual.renglonesResolucion[0].codigoAeroplanta !== 'AEP' && this.resolucionMensual.renglonesResolucion[0].codigoAeroplanta !== 'EZE') {
      modalRef.componentInstance.esTercera = true;
    }
    modalRef.result.then(recargar => {
      if (!recargar) this.obtenerResolucion()
    });
  }

  async verResolucion() {
    let esTercera = (this.resolucionMensual.renglonesResolucion[0].codigoAeroplanta !== 'AEP' && this.resolucionMensual.renglonesResolucion[0].codigoAeroplanta !== 'EZE')
    let nombreResolucion =  esTercera ? 'TERCERAS' : this.resolucionMensual.renglonesResolucion[0].codigoAeroplanta;
    if(esTercera && !this.terceras) {
      nombreResolucion = "TERCERAS";
      this.seleccionarTerceras();
      await this.obtenerResolucion();
      this.terceras = false;
    }
    let modalRef = this.modal.open(VisorInformeResolucionComponent, { centered: true, size: 'xl', backdrop: 'static', keyboard: false });
    modalRef.componentInstance.esTercera = nombreResolucion == 'TERCERAS' ? true : false;
    modalRef.componentInstance.resoluciones = this.resolucionMensual;
    modalRef.componentInstance.fileName = `Resolución_${nombreResolucion}_${this.resolucionMensual.mes}/${this.resolucionMensual.ano}.pdf`;
  }

  abrirModalJustificacion(renglon) {
    let modalRef = this.modal.open(ModalJustificacionResolucionComponent, { centered: true, size: 'm', backdrop: 'static', keyboard: false });
    modalRef.componentInstance.renglon = renglon;
    modalRef.componentInstance.fecha = this.getFechaHasta();
    modalRef.componentInstance.nombreResponsable = this.usuarioService.getNombresOperador();
  }

  seleccionarTerceras() {
    this.terceras = !this.terceras;
    this.aeroplantas.forEach(a => {
      if(a.codigoAeroplanta !== 'AEP' && a.codigoAeroplanta !== 'EZE') {
        this.terceras ? a.seleccionada = true : a.seleccionada = false;
      } else a.seleccionada = false;
    }) 
  }

  exportarExcel() {
    let length = this.resolucionMensual.renglonesResolucion.length;
    let json = []
    for (let i = 0; i < length; i++) {
      let renglon = this.resolucionMensual.renglonesResolucion[i];
      json.push({
        "Codigo Dependencia": renglon.codigoAeroplanta,
        "Dependencia": renglon.nombreAeroplanta,
        "Producto": renglon.codigoProducto,
        "Fecha Ult. Cierre": renglon.fechaUltimoCierre,
        "Existencia Real": renglon.existenciaReal,
        "Entradas": renglon.entradas,
        "Salidas": renglon.salidas,
        "Existencia Libros": renglon.existenciaAnterior + renglon.entradas - renglon.salidas,
        "Existencia Final": renglon.existenciaReal,
        "Removido": renglon.removido,
        "Sup/Def [lt]": renglon.diferencia,
        "Porcentual": renglon.porcentual.toFixed(3) + "%",
        "Tolerancia": renglon.toleranciaMensualOperativa && renglon.resultadoTolerancia !== null ? renglon.toleranciaMensualOperativa.toFixed(2) + "%" : "Error",
        "Resultado": renglon.toleranciaMensualOperativa && renglon.resultadoTolerancia !== null ? renglon.resultado : "Error",
        "% FT": renglon.toleranciaMensualOperativa && renglon.resultadoTolerancia !== null ? renglon.resultadoTolerancia.toFixed(2) + "%" : "Error",
        "Justificacion": renglon.justificacion
      })
    }

    this.excelService.exportAsExcelFile(json, "resolucion " + this.resolucionMensual.mes + "/" + this.resolucionMensual.ano);
  }

  get mesesFormControl() {
    return this.busqueda.controls.mesesFormControl;
  }

  get anioFormControl() {
    return this.busqueda.controls.anioFormControl;
  }

}
