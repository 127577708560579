<div class="container-fluid">
  <nav class="navbar navbar-light bg-light navbar-expand-lg" style="padding-left: 0; padding-right: 0;">

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <div class="form-inline my-2 my-lg-0">
        <input (input)="filtrarClientes(this.busqueda.value)" id="busquedaFormControl" type="text" class="form-control"
           placeholder="Búsqueda" [formControl]="busquedaFormControl" />
      </div>
      <ul class="navbar-nav me-auto">
        <!-- tipos -->


        <li class="nav-item">
          <button type="button" class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2" (click)="mostrarNuevaNotificacion(content)"> Agregar</button></li>
          <li class="nav-item">
        <button type="button" class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2" (click)="mostrarHistorialNotificaciones(contentHistorial)" > Historial de envios</button></li>
      </ul>
    </div>
  </nav>
<!-- </form> -->
<div class="row">
  <div class="col col-4">
    <div class="list-group py-3  menu-izq">
  <div *ngFor="let cliente of clientesFiltrados; let i=index">
    <button  type="button"
      [class.active]=""
      class="list-group-item list-group-item-action" (click)="mostrarDetalle(cliente)">

      <div class="d-flex w-100 justify-content-between">
        <p >{{ cliente.razonSocial}} </p>
      </div>
    </button>
  </div>
</div>
</div>

<!-- informacion de las notificaciones -->

<!-- <ng-template #contentDetalle let-modal> -->

<!-- </ng-template> -->


<!-- <div class="py-3 col-6"> -->
  <!-- <ngx-spinner name="spinnerDetalle" bdOpacity=0.3bd Color="rgba(193,193,193,0)" size="medium" color="#185db5"
    type="ball-grid-beat" [fullScreen]="false">
    <p style="color: white"> C A R G A N D O . . . </p>
  </ngx-spinner> -->
  <!-- <div class="col-sm-8" *ngIf="this.cargandoClientes">
    <ngx-spinner name="spinnerDetalle" bdOpacity=0.3bd Color="rgba(193,193,193,0)" size="medium" color="#185db5"
    type="ball-grid-beat" [fullScreen]="false">
    <p style="color: white"> C A R G A N D O . . . </p>
  </ngx-spinner>
  </div> -->
  <div *ngIf="this.clienteSeleccionado!=null" id="tablaInformacion" class="col-sm-8">
    <app-notificacion-detalle [cliente]="this.clienteSeleccionado"></app-notificacion-detalle>
  </div>
<!-- </div> -->


<ng-template #content let-modal>
 <app-nueva-notificacion></app-nueva-notificacion>
</ng-template>

<ng-template #contentHistorial let-modal>
  <app-notificaciones-historial [clienteSeleccionado]="this.clienteSeleccionado"></app-notificaciones-historial>
 </ng-template>
</div>

<div class="col col-md-12">
  <div class="spinner-container" *ngIf="this.cargandoClientes">
    <ngx-spinner name="spinnerLista" bdOpacity=0.3bd
      Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
      <p style="color: white">C a r g a n d o . . . </p>
    </ngx-spinner>
  </div>
</div>
