<div class="modal-header bg-light">
    <h4 class="modal-title">{{ this.titulo }}</h4>
    <button type="button" class="btn-close" aria-label="Close" 
      (click)="activeModal.close(false)">
      
    </button>
</div>
<div class="modal-body" [formGroup]="form">
  <!--FECHAFS-->
  <div #fechaHora class="row">
    <div class="mb-3 row col-md-12">
      <label for="fechaFSFormControl" class="col-sm-5 col-form-label">*Fecha cambio de estado:</label>
      <div class="col-sm-7">
        <div class="input-group">
          <input type="date" class="form-control" formControlName="fechaFSFormControl" [ngModel]="model" (ngModelChange)="model = $event"
          placeholder="aaaa-mm-dd" name="dp" title="Campo obligatorio." ngbDatepicker
          #l="ngbDatepicker" readonly />
          <div class="input-group-append">
            <button type="button" class="input-group-btn btn btn-secondary" (click)="l.toggle()">
              <i class="fa fa-calendar" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--HORAFS-->
  <div #fechaHora class="row">
    <div class="mb-3 row col-md-12">
      <label for="fechaFSFormControl" class="col-sm-5 col-form-label">*Hora cambio de estado:</label>
      <div class="col-sm-7">
        <ngb-timepicker [(ngModel)]="horaAct" [meridian]="false" formControlName="horaFSFormControl"
        [readonlyInputs]="true">
        </ngb-timepicker>
      </div>
    </div>
  </div>
  <!--OBSERVACIONES-->
  <div class="row">
    <div class="mb-3 row col-md-12">
      <label for="observacionesFormControl" class="col-sm-5 col-form-label">Observaciones:</label>
      <div class="col-sm-7">
        <!--<input class="form-control" formControlName="observacionesFormControl" maxlength="50">-->
        <textarea id="observacionesFormControl" class="form-control" formControlName="observacionesFormControl"
          maxlength="255" placeholder="Ingrese el motivo del cambio servicio..." rows="6">
        </textarea>
        <div *ngIf="observacionesFormControl.invalid && observacionesFormControl.errors.required && (observacionesFormControl.dirty || observacionesFormControl.touched)"
          class="alert alert-danger">Requerido</div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="this.enume==EntidadParteDiario.SistemasSeguridad && this.fs">
    <!--AUTORIZOFS-->
    <div class="row">
      <div class="mb-3 row col-md-12">
        <label for="usuarioFormControl" class="col-sm-5 col-form-label">*Autorizo fuera servicio:</label>
        <div class="col-sm-7">
          <input id="usuarioFormControl" class="form-control" maxlength="50" type="text" 
          placeholder="Buscar usuario" formControlName="usuarioFormControl" [inputFormatter]="formatterUsuario" [resultFormatter]="formatterUsuario"
          (selectItem)="selectUsuario($event)" [ngbTypeahead]="searchUsuario">
          <loading-text-spinner *ngIf="obteniendoUsuarios" [error]="errorUsuarios" message="Obteniendo usuarios..."
            color="primary">
          </loading-text-spinner>
          <div class="alert alert-danger"
            *ngIf="usuarioFormControl.touched && usuarioSeleccionado == null">
            Requerido
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="mb-3 row col-md-12">
        <label class="col-sm-5 col-form-label">Usuario seleccionado:</label>
        <div class="col-sm-7">
          <div class="row" *ngIf="usuarioSeleccionado">
            <label class="col-10 col-form-label">{{usuarioSeleccionado.nombresCompletos}}</label>
            <button type="button" ngbTooltip="Quitar usuario asignado" class="btn btn-danger ms-2 px-1" (click)="borrarUsuario()">
              <i class="fa fa-trash"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
<div class="modal-footer justify-content-center">
    <button class="btn btn-primary me-2" id="aceptar" [disabled]="!form.valid || grabando" (click)="onClick()">
      <span *ngIf="!grabando">Guardar</span>
      <div *ngIf="grabando" class="d-flex align-items-center">
        <div class="spinner-border text-light spinner-border-sm" role="status"></div>
        <span>Guardando...</span>
      </div>
    </button>
    <button type="button" class="btn btn-secondary" (click)="this.activeModal.close(false)">Cancelar</button>
</div>  