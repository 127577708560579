import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbCalendar,NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Aeroplanta } from 'src/app/shared/models/despacho/Aeroplanta';
import { Aerovale } from 'src/app/shared/models/despacho/Aerovale';
import { Cliente } from 'src/app/shared/models/despacho/Cliente';
import { Email } from 'src/app/shared/models/notificaciones/Email';
import { NotificacionesService } from '../../notificaciones.service';

@Component({
  selector: 'app-notificaciones-historial',
  templateUrl: './notificaciones-historial.component.html',
  styleUrls: ['./notificaciones-historial.component.css']
})
export class NotificacionesHistorialComponent implements OnInit {

  @HostBinding('class.changed')

  @Input() clienteSeleccionado:Cliente;

  tablaComprobantes: UntypedFormGroup;
  aeroplanta: Aeroplanta;
  emails: Email[]=[];
  emailsFiltrados: Email[]=[];

  modelDesde: any;
  modelHasta: any;
  fechaDesde:Date;
  fechaHasta:Date;
  mensajeSpinner: string = "D e s c a r g a n d o";
  obteniendoEmails = true;

  busquedaFormControl=new UntypedFormControl();
  fechaFormControlDesdeHasta = new UntypedFormControl();


  constructor(
    private spinner: NgxSpinnerService,
    public activeModal:NgbModal,
    private fb: UntypedFormBuilder,
    private calendar:NgbCalendar,
    private notificacionService:NotificacionesService) {
  }

  ngOnInit() {

    this.tablaComprobantes = this.fb.group({
      aerovalesFiltrados: new UntypedFormArray([])
    });

    this.modelDesde = this.calendar.getToday();
    this.modelHasta = this.calendar.getToday();

    this.reinicioFechaInicioFechaFin();

    this.mensajeSpinner = "C a r g a n d o . . .";


    setTimeout(() => {
      this.spinner.show('spinnerLista');
    });

   this.getMailsPorCliente();
  }

  reinicioFechaInicioFechaFin() {
    this.fechaDesde = new Date(this.modelDesde.year, this.modelDesde.month - 1, this.modelDesde.day);
    this.fechaHasta = new Date(this.modelHasta.year, this.modelHasta.month - 1, this.modelHasta.day);
    this.fechaHasta.setHours(23, 59, 59, 99);
  }


  filtrarEmails() {
    this.emailsFiltrados = this.emails.filter(e => {
      let agregar = true;
      if (this.busqueda.value) {
        agregar = e.codigoAeroplanta.toLowerCase().indexOf(this.busqueda.value.toLowerCase()) > -1;
        agregar = agregar || (e.codigoAeroplanta &&
          e.codigoAeroplanta.toLowerCase().indexOf(this.busqueda.value.toLowerCase()) > -1);
         agregar = agregar || (e.tipoNotificacion && e.tipoNotificacion.toLowerCase().indexOf(this.busqueda.value.toLowerCase()) > -1);
         agregar = agregar || (e.destinatarios && e.destinatarios.indexOf(this.busqueda.value.toLowerCase()) > -1);
      }
      // if (this.estados.some(b => b.seleccionado))
      //   agregar = agregar && this.estados.some(
      //     b => (b.seleccionado && b.valor == a.estado) ||
      //       (b.seleccionado && b == this.finalizado && this.finalizadoSinFirma.valor == a.estado));
      // if (this.tipos.some(b => b.seleccionado))
      //   agregar = agregar && this.tipos.some(
      //     b => (b.seleccionado && b.tipo == a.tipoAerovale));
      return agregar;
    });

  }

  // llamadas al service

  getMailsPorCliente() {
    this.reinicioFechaInicioFechaFin();
    this.obteniendoEmails = true;

    this.spinner.show('spinnerLista');
    this.notificacionService.obtenerHistorialEnvios
    (this.clienteSeleccionado.tipoDocumento,
      this.clienteSeleccionado.numeroDocumento,
      this.fechaDesde,this.fechaHasta)
      .subscribe(result => {
        this.emails=result;
        this.emailsFiltrados=this.emails.sort((a, b)=> new Date(b.fechaHoraEnvio).getTime() - new Date(a.fechaHoraEnvio).getTime());
        this.spinner.hide('spinnerLista');
        this.obteniendoEmails = false;
      }, () => {
        this.obteniendoEmails = false;
      });
  }


  // getters

  get busqueda() {
    return this.busquedaFormControl;
  }



}
