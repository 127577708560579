<div class="col col-md-12" *ngIf="this.cargandoLista">
  <div class="spinner-container" >
    <ngx-spinner name="spinnerGrafico" bdOpacity=0.3bd
      Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
      <p style="color: white">C a r g a n d o . . . </p>
    </ngx-spinner>
  </div>
</div>
<!-- lista de notificaciones -->
<div *ngIf="!this.cargandoLista">
  <div *ngFor="let nf of notificacionesFiltradas; let i=index">
    <button [ngClass]="{'desactivado': !nf.activo}" type="button" class="list-group-item list-group-item-action" (click)="mostrarDetalle(i)">
      <div class="d-flex w-100 justify-content-between">
        <p >{{ nf.titulo}} </p>
      </div>
    </button>
  </div>
</div>