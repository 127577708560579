export enum UrlApi {
    usuarios = "/UsuariosV1",
    clientes = "/ClientesV1",
    aeronaves = "/VehiculosV1",
    ranchos = "/RanchosV1",
    ranchosV2 = "/RanchosV2",
    laboratorios = "/LaboratoriosV1",
    traders = "/TradersV1",
    aeroplantas = "/AeroplantasV1",
    configuracion = "/ConfiguracionesV1",
    reportes = "/ReportesV1/",
    precios = "/ProductosV1",
    impuestos = "/ImpuestoV1",    
    consumos = "/ConsumosRanchosV1",    

    /* aok.pad.facturacion */
    padrones = "/PadronesV1",
    facturacionConfiguracion = "/ConfiguracionesV1",
    facturacionProvincias = "/ProvinciasV1",
    facturacionParametrosImpositivos = "/ParametrosImpositivosV1",
    emisores= "/EmisoresV1",

    /* aok.pad.stock */
    stockMedicionesTanques = "/ContenedoresV1",
}
