import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { TipoNivelAprobacion } from 'src/app/shared/enums/tipoNivelAprobacion';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { NivelAprobacion } from 'src/app/shared/models/precios/NivelAprobacion';
import { MessageService } from 'src/app/shell/message.service';
import { PreciosService } from '../precios.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Usuario } from 'src/app/shared/models/despacho/Usuario';
import { switchMap, tap } from 'rxjs/operators';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ExportExcelServiceService } from 'src/app/shared/exportar-excel/export-excel-service.service';

@Component({
  selector: 'app-reporte-niveles',
  templateUrl: './reporte-niveles.component.html',
  styleUrls: ['./reporte-niveles.component.scss']
})
export class ReporteNivelesComponent implements OnInit {

  enumTipoNivel = TipoNivelAprobacion;

  tipoNivelElegido: number;
  niveles: NivelAprobacion[];
  error: string;
  esperandoCarga: boolean= true;

  checkForm = new UntypedFormGroup({
    checkTipoControl: new UntypedFormControl(''),
  })

  constructor(
    private preciosService: PreciosService,
    private spinner: NgxSpinnerService,
    private messageService: MessageService,
    private route:ActivatedRoute,
    private modalService: NgbModal,
    private router: Router, 
    private excelService: ExportExcelServiceService
    ) {

    }

  // hooks

  ngOnInit() {
   this.checkTipoControl.setValue(0);   
  }

  ngAfterViewInit(){
    this.getNivelesAprobacion();
  }

  // llamadas al service

  getNivelesAprobacion(){
    this.tipoNivelElegido = this.checkTipoControl.value;

    this.initNivelesRequest();

    this.preciosService.getNivelesAprobacion(Number(this.checkTipoControl.value))
    .subscribe(result => {
      this.niveles = result;
      this.spinner.hide('spinnerGrafico');
      this.esperandoCarga = false;
    },
    (err: ErrorModel) => {
      this.niveles = [];
      this.error = err.description;
      this.esperandoCarga = false;
    });
  }

  // form

  initNivelesRequest(){
    this.niveles = [];
    this.esperandoCarga = true;
    this.spinner.show("spinnerGrafico");
  }

  print(): void {
    var divToPrint = document.getElementById('detalleNivel');
    var htmlToPrint = '' +
      '<style type="text/css">' +
      'table td, table th  {' +      
      'text-align: center;' +
      'border:1px solid #000;' +
      'padding:0.5em;' +
      '}' +
      'table  {' +     
      'border-collapse: collapse;' +
      'font-size: 10pt;' +
      '}' +
      '.rotulo-nivel{' +
      'padding-top: 0.75em; padding-left: 1em; margin-top: 0.75em; background-color: #ccc; padding-bottom: 0.1em; font-weight: bold; text-decoration: underline;' +
      '}' +
      '.titulo-niveles  {' +
      'font-size: 16pt;  font-weight: bold;  line-height: 1em;' +
      '}' +
      '#noPrint {' +
      'display: none;' +
      '}' +
      '#boton-excel {' +
      'display: none;' +      
      '}'+
      '</style>';
    htmlToPrint += divToPrint.outerHTML;
    let newWin = window.open("");
    newWin.document.write(htmlToPrint);
    newWin.print();
    newWin.close();
  }

  exportarNivel(nivel: NivelAprobacion) {
    if(nivel.usuariosNivel.length == 0){
      this.messageService.showErrorMessage("El nivel no tiene usuarios asignados");
      return;
    }
    
    this.excelService.exportAsExcelFile(this.armarArrayUsuariosNivel(nivel), 'Niveles ' + nivel.nombre);
  }

  armarArrayUsuariosNivel(nivel: NivelAprobacion): ReporteNiveles[] {
    let nivelesExcel: ReporteNiveles[] = [];
    
    for (let n of nivel.usuariosNivel){
      let aux = new ReporteNiveles();
      aux.nombreCompleto = n.nombreCompleto;
      aux.otorgadoPorNombre = n.otorgadoPorNombre;
      aux.fechaDesdeAsignado = n.fechaDesdeAsignado;
      aux.fechaFinAsignado = n.fechaFinAsignado.includes("0001-01-01") ? "-" : n.fechaFinAsignado;
      nivelesExcel.push(aux);
    }

    return nivelesExcel;
  }

  get checkTipoControl() {
    return this.checkForm.get("checkTipoControl");
  }

}

export class ReporteNiveles {
  nombreCompleto : string;
  fechaDesdeAsignado: string;
  fechaFinAsignado: string; 
  otorgadoPorNombre: string; 
}
