import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Producto } from '../shared/models/despacho/Producto';

@Injectable({
  providedIn: 'root'
})
export class ProductosService {

  baseUrl = environment.apiServer.baseUrl;
  private productosUrl = this.baseUrl + '/ProductosV1/';

  constructor(private httpClient: HttpClient) {
  }

  getAerocombustible(): Observable<Producto[]> {
    return this.httpClient.get<Producto[]>(this.productosUrl + 'Aerocombustibles');
  }

  getAeroespecialidadesParaAeroplantaActual(codigoAeroplanta): Observable<Producto[]> {
    return this.httpClient.get<Producto[]>(this.productosUrl + 'Aeroespecialidades?codigoAeroplanta='+codigoAeroplanta);
  }
}
