import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, FormGroup, FormControl } from '@angular/forms';
import { ProductoService } from 'src/app/abm/producto/producto.service';
import { TipoVenta } from 'src/app/shared/enums/tipoVenta';
import { NgbCalendar, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalExportacionComponent } from '../modal-exportacion/modal-exportacion.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { formatDate } from '@angular/common';
import { Precio } from 'src/app/shared/models/abm/Precio';
import { CondicionVenta } from 'src/app/shared/enums/condicionVenta';
import { TipoEstadoPrecio } from 'src/app/shared/enums/tipoEstadoPrecio';
import { PrecioPorFechaComponent } from '../precio-por-fecha/precio-por-fecha.component';
import { ModalHistoricoComponent } from '../modal-historico/modal-historico.component';
import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { MessageService } from 'src/app/shell/message.service';
import { Moneda } from 'src/app/shared/models/abm/Moneda';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';
import { ModalCorreccionComponent } from '../modal-correccion/modal-correccion.component';
import { AeroplantasService } from 'src/app/shared/aeroplantas.service';
import { TipoMoneda } from 'src/app/shared/enums/tipoMoneda';
import { ItemMultifiltroComponent } from 'src/app/shared/multifiltro/item-multifiltro/item-multifiltro.component';
import { ItemMultiFiltro } from 'src/app/shared/multifiltro/ItemMultifiltro';
import { EstadoPrecio } from 'src/app/shared/models/precios/EstadoPrecio';
import { TipoActivoPrecio } from 'src/app/shared/enums/tipoActivoPrecio';
import { PreciosService } from '../../precios.service';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { ModalConfirmComponent } from 'src/app/shared/generic-modals/modal-confirm.component';
import { UsuarioService } from 'src/app/account/usuario.service';
import { Aeroplanta } from 'src/app/shared/models/stock/Aeroplanta';
import { TipoCantidadPaginado } from 'src/app/shared/enums/TipoCantidadPaginado';

@Component({
  selector: 'app-detalle-precios',
  templateUrl: './detalle-precios.component.html',
  styleUrls: ['./detalle-precios.component.css']
})
export class DetallePreciosComponent implements OnInit {

  TipoUsuario = TipoUsuario;

  constructor(private productoService: ProductoService, private modalService: NgbModal,
    private messageService: MessageService,
    private calendar: NgbCalendar,
    public authService: AuthService,
    private spinner: NgxSpinnerService,
    public aeroplantaService: AeroplantasService,
    private preciosService: PreciosService,
    private usuarioService: UsuarioService) {
      // texto
    }

  TEXTO_SIN_ELEMENTO = "-";

  tiposVentaMultiFiltro: ItemMultiFiltro[] = [];
  aeroplantasMultiFiltro: ItemMultiFiltro[] = [];
  lotesMultiFiltro: ItemMultiFiltro[] = [];
  codigosMultiFiltro: ItemMultiFiltro[] = [];
  productosMultiFiltro: ItemMultiFiltro[] = [];
  monedasMultiFiltro: ItemMultiFiltro[] = [];
  clientesMultiFiltro: ItemMultiFiltro[] = [];
  condicionesVentaMultiFiltro: ItemMultiFiltro[] = [];
  entregasMultiFiltro: ItemMultiFiltro[] = [];
  areasMultiFiltro: ItemMultiFiltro[] = [];
  segmentosMultiFiltro: ItemMultiFiltro[] = [];

  //Vigentes = 0, Vencidos = 1, Futuros = 2
  vigencias = [
    { codigo: 0, nombre: "Vigentes", seleccionado: true },
    { codigo: 1, nombre: "Vencidos", seleccionado: false },
    { codigo: 2, nombre: "Futuros", seleccionado: false }
  ];
  estados = [
    { codigo: 1, nombre: "Pendiente", seleccionado: false },
    { codigo: 2, nombre: "Aprobado", seleccionado: true },
    { codigo: 3, nombre: "Rechazado", seleccionado: false },
    //{ codigo: 4, nombre: "AprobadoRetroactivo", seleccionado: false }
  ];

  niveles: any[] = [
    { id: "1", nombre: "1 - Producto/Vigencia/Moneda/Aeroplanta/Area de Negocio/Segmento/Cliente/Tipo operación/Direccion de entrega", seleccionado: false },
    { id: "2", nombre: "2 - Producto/Vigencia/Moneda/Aeroplanta/Area de Negocio/Segmento/Cliente/Tipo operación", seleccionado: false },
    { id: "3", nombre: "3 - Producto/Vigencia/Moneda/Aeroplanta/Area de Negocio/Segmento/Cliente", seleccionado: false },
    { id: "4", nombre: "4 - Producto/Vigencia/Moneda/Aeroplanta/Area de Negocio/Segmento", seleccionado: false },
    { id: "5", nombre: "5 - Producto/Vigencia/Moneda/Aeroplanta/Area de Negocio", seleccionado: false },
    { id: "6", nombre: "6 - Producto/Vigencia/Moneda/Aeroplanta", seleccionado: false },
  ];


  TipoActivoPrecio = TipoActivoPrecio;
  enumCondicionVenta = CondicionVenta;
  enumEstadoPrecio = TipoEstadoPrecio;
  exportarPrecios: boolean = false;
  errorEnExportacion: boolean = false;
  expPrecios: boolean = false;

  tipoVenta: TipoVenta;
  moneda: Moneda;
  preciosFiltradosMultifiltro: Precio[];
  preciosFiltrados: Precio[];
  preciosEnviar: Precio[] = [];
  listaPrecios: Precio[];
  actualizandoPrecios: boolean = false;
  esperandoCarga: boolean = false;
  messagePrecios: string = "C a r g a n d o . . ."
  actualizando: boolean = false;
  procesandoFiltros:boolean = false;
  paginado: boolean = false;

  searchForm = new FormGroup({
    busqueda: new FormControl(''),
  })

  modelFecha: NgbDateStruct;
  modelHora: any;

  cantPrecios: number = 0;

  page = 1;
  pageSize = TipoCantidadPaginado.VERY_BIG;
  collectionSize = 0;

  //hooks

  ngOnInit() {
    this.searchForm.valueChanges.subscribe(() => this.filtrarPrecios());

    this.modelFecha = this.calendar.getToday();
    const horaActual = new Date();
    this.modelHora = { hour: horaActual.getHours(), minute: horaActual.getMinutes(), second: horaActual.getSeconds() }

    if(this.bloquearNiveles()) {
        this.niveles[5].seleccionado = true;
    }
    this.getPreciosPage(this.page, this.pageSize);    
  }

  filtrarPreciosMulti() {
    if (this.preciosFiltrados != null) {

      this.procesandoFiltros = true;

      this.preciosFiltradosMultifiltro = this.preciosFiltrados.filter(p => {
        let mostrar = true;

        if (this.tiposVentaMultiFiltro.some(elemento => elemento.seleccionado)) {
          mostrar = mostrar && (
            this.tiposVentaMultiFiltro.filter(elemento => elemento.seleccionado).some(elementoAux => Number(elementoAux.id) == p.tipoVenta)
          );
        }

        if (this.aeroplantasMultiFiltro.some(elemento => elemento.seleccionado)) {
          mostrar = mostrar && (
            this.aeroplantasMultiFiltro.filter(elemento => elemento.seleccionado).some(elementoAux => elementoAux.id == p.codigoAeroplanta)
          );
        }

        if (this.lotesMultiFiltro.some(elemento => elemento.seleccionado)) {
          mostrar = mostrar && (
            this.lotesMultiFiltro.filter(elemento => elemento.seleccionado).some(elementoAux => elementoAux.id == p.numeroLote)
          );
        }

        if (this.codigosMultiFiltro.some(elemento => elemento.seleccionado)) {
          mostrar = mostrar && (
            this.codigosMultiFiltro.filter(elemento => elemento.seleccionado).some(elementoAux => elementoAux.id == p.producto.codigoProducto)
          );
        }

        if (this.productosMultiFiltro.some(elemento => elemento.seleccionado)) {
          mostrar = mostrar && (
            this.productosMultiFiltro.filter(elemento => elemento.seleccionado).some(elementoAux => elementoAux.id == p.producto.nombreProducto)
          );
        }

        if (this.monedasMultiFiltro.some(elemento => elemento.seleccionado)) {
          mostrar = mostrar && (
            this.monedasMultiFiltro.filter(elemento => elemento.seleccionado).some(elementoAux => elementoAux.id == p.moneda.nombre)
          );
        }

        if (this.clientesMultiFiltro.some(elemento => elemento.seleccionado)) {
          mostrar = mostrar && (
            this.clientesMultiFiltro.filter(elemento => elemento.seleccionado).some(elementoAux => p.cliente != null ? elementoAux.id == p.cliente.razonSocial : elementoAux.id == ItemMultifiltroComponent.SIMBOLO_VALOR_NULO)
          );
        }

        if (this.condicionesVentaMultiFiltro.some(elemento => elemento.seleccionado)) {
          mostrar = mostrar && (
            this.condicionesVentaMultiFiltro.filter(elemento => elemento.seleccionado).some(elementoAux => Number(elementoAux.id) == p.condicionVenta)
          );
        }

        if (this.entregasMultiFiltro.some(elemento => elemento.seleccionado)) {
          mostrar = mostrar && (
            this.entregasMultiFiltro.filter(elemento => elemento.seleccionado).some(elementoAux => elementoAux.id == (p.direccionEntrega != null ? p.direccionEntrega : ItemMultifiltroComponent.SIMBOLO_VALOR_NULO))
          );
        }

        if (this.areasMultiFiltro.some(elemento => elemento.seleccionado)) {
          mostrar = mostrar && (
            this.areasMultiFiltro.filter(elemento => elemento.seleccionado).some(elementoAux => elementoAux.id == (p.areaNegocio != null ? p.areaNegocio.nombreArea : ItemMultifiltroComponent.SIMBOLO_VALOR_NULO))
          );
        }

        if (this.segmentosMultiFiltro.some(elemento => elemento.seleccionado)) {
          mostrar = mostrar && (
            this.segmentosMultiFiltro.filter(elemento => elemento.seleccionado).some(elementoAux => elementoAux.id == (p.segmento != null ? p.segmento.nombreSegmento : ItemMultifiltroComponent.SIMBOLO_VALOR_NULO))
          );
        }

        return mostrar;

      });

      this.procesandoFiltros = false;
      var a = this.preciosFiltradosMultifiltro;
    }
  }

  cargarListasFiltrosMulti(listaPrecios: Precio[]) {
    this.filtrarPreciosMulti();
  }

  getDescripcionTipoVenta(tipoVenta: TipoVenta) {
    if (tipoVenta == TipoVenta.cabotaje) {
      return "Cabotaje";
    } else if (tipoVenta == TipoVenta.internacional) {
      return "Internacional";
    } else {
      return "Sin tipo venta.";
    }
  }

  getDescripcionCondicionVenta(condicionVenta: CondicionVenta) {
    if (condicionVenta == CondicionVenta.contado) {
      return "CONTADO";
    } else if (condicionVenta == CondicionVenta.cuentaCorriente) {
      return "CTA CTE";
    } else {
      return "Sin condición venta.";
    }
  }

  getDescripcionEntregas(entrega: number) {
    return entrega;
    /*
    if(entrega == CondicionVenta.contado){
      return "CONTADO";
    }else if(entrega == CondicionVenta.cuentaCorriente){
      return "CTA CTE";
    }else{
      return "Indeterminado";
    }
    */
  }

  getDescripcionAeroplantas(codigoAeroplanta: string) {
    try{
      return this.aeroplantaService.obtenerDescripcionAeroplantaPrecios(codigoAeroplanta);
    }catch(Ex){
      return ".";
    }    
  }

  getPreciosPage(page: number, size: number, actualizacion?: boolean): void {
    this.cantPrecios = 0;
    (actualizacion) ? this.actualizandoPrecios = true : this.esperandoCarga = true;    
    this.spinner.show('spinnerGrafico');    

    let nivelesFiltrados = [];
    this.niveles.forEach(n => n.seleccionado? nivelesFiltrados.push(Number(n.id)) : null);
    let estadosFiltrados = [];
    this.estados.forEach(e => e.seleccionado? estadosFiltrados.push(e.codigo) : null);
    let vigenciasFiltradas = [];
    this.vigencias.forEach(v => v.seleccionado? vigenciasFiltradas.push(v.codigo) : null);

    this.productoService.getPreciosPaginados(page, size, nivelesFiltrados, estadosFiltrados, vigenciasFiltradas, this.fechaVigencia)
      .subscribe(p=>{
        this.cantPrecios = p.count;
        p.items.forEach(p=>{
          if (p.aprobadoUserName && p.aprobadoUserName.indexOf(',') > -1) p.aprobadoUserName = JSON.parse(p.aprobadoUserName.replace(/'/g, '"')).nombresCompletos;

          if (p.importoUserName && p.importoUserName.indexOf(',') > -1) p.importoUserName = JSON.parse(p.importoUserName.replace(/'/g, '"')).nombresCompletos;
        });

        if(this.authService.hasRole(TipoUsuario.SUPERVISOR, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.MANDATARIO)){
          var aeroplantaActual: Aeroplanta = this.usuarioService.getAeroplantaActual();
          p.items = p.items.filter(p => p.codigoAeroplanta == aeroplantaActual.codigoAeroplanta);
        }

        this.preciosFiltrados = p.items;
        this.listaPrecios = p.items;
        this.page = p.pageNumber;
        this.pageSize = p.pageSize;
        this.collectionSize = p.count;

        this.paginado = p.totalItemCount >= 10000 || p.hasPreviousPage ? true : false;

        this.spinner.hide('spinnerGrafico');
        (actualizacion) ? this.actualizandoPrecios = false : this.esperandoCarga = false;
        this.cargarListasFiltrosMulti(this.listaPrecios);
      }, ()=>{
        this.spinner.hide('spinnerGrafico');
        (actualizacion) ? this.actualizandoPrecios = false : this.esperandoCarga = false;
        this.messageService.showErrorMessage("Error al obtener los precios en vigencia.")
      }); 
  }
  
  public onPageChange = (pageNumber) => {
    this.actualizandoPrecios = true;
    this.preciosFiltrados = null;
    this.listaPrecios = null;    
    this.getPreciosPage(pageNumber, this.pageSize, true);
  }

  // form

  keysTipoVenta() {
    var keys = Object.keys(this.tipoVenta);
    keys.shift();
    return keys.slice(keys.length / 2);
  }

  onRefreshClick() {
    this.preciosFiltrados = null;
    this.listaPrecios = null;  
    this.preciosFiltradosMultifiltro = [];  
    this.getPreciosPage(1, this.pageSize);
  }

  exportarPantalla() {
    this.errorEnExportacion = false;
    this.expPrecios = true;

    var listaIds = this.preciosFiltradosMultifiltro.map(p => p.id);

    if (listaIds.length == 0) {
      this.messageService.showErrorMessage("No hay precios filtrados para exportar.");
      this.expPrecios = false;
      return;
    }

    this.productoService.exportarListadoPrecios(listaIds)
      .subscribe((res: Blob) => {
        const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
        saveAs(blob, "ListadoDePrecios");
        this.expPrecios = false;
      }, () => {
        this.errorEnExportacion = true;
        this.expPrecios = false;
      });
  }

  obtenerPreciosPorFecha() {
    const modalRef = this.modalService.open(PrecioPorFechaComponent, { centered: true, size: "lg", windowClass: 'myCustomModalClass', backdrop: "static" });
    modalRef.result.then((result => {
      this.ngOnInit();
    }))
  }

  recargarForm() {
    this.exportarPrecios = false;
    this.ngOnInit();
  }

  exportarPreciosAprobados() {
    // this.esperandoCarga = true;
    // this.spinner.show('spinnerGrafico');
    this.exportarPrecios = true;
  }

  obtenerListaIdsFiltrados() {
    return this.preciosFiltrados.map(p => p.id);
  }

  filtrarPrecios() {

    if (this.vigencias != null && this.preciosFiltrados != null) {
      this.procesandoFiltros = true;

      this.preciosFiltrados = this.listaPrecios.filter(a => {
        let agregar = true;
        if (this.busquedas.value) {

          if (a.cliente != null) {
            agregar = a.cliente.razonSocial && a.cliente.razonSocial.toLocaleLowerCase().indexOf(this.busquedas.value.toLocaleLowerCase()) > -1;
          } else {
            agregar = false;
          }

          agregar = agregar || (a.producto.nombreProducto && a.producto.nombreProducto.toLocaleLowerCase().indexOf(this.busquedas.value.toLocaleLowerCase()) > -1);
          agregar = agregar || (a.producto.codigoProducto && a.producto.codigoProducto.toLocaleLowerCase().indexOf(this.busquedas.value.toLocaleLowerCase()) > -1);
          agregar = agregar || (a.precioContado.toString() && a.precioContado.toString().toLocaleLowerCase().indexOf(this.busquedas.value.toLocaleLowerCase()) > -1);
          agregar = agregar || (a.numeroLote && ("l" + a.numeroLote).toString().toLocaleLowerCase().indexOf(this.busquedas.value.toLocaleLowerCase()) > -1);
        }

        if (this.busquedas.value == '') {
          this.preciosFiltrados = this.listaPrecios;
        }

        if (this.vigencias.some(e => e.seleccionado)) {

          let fechaHora = this.formatDate(this.modelFecha.year, this.modelFecha.month, this.modelFecha.day, this.modelHora.hour, this.modelHora.minute);

          agregar = agregar && (
            (this.vigencias[0].seleccionado == true
              && formatDate(a.fechaHasta, 'yyyy/MM/dd', 'en') >= formatDate(fechaHora, 'yyyy/MM/dd', 'en') && formatDate(a.fechaDesde, 'yyyy/MM/dd', 'en') <= formatDate(fechaHora, 'yyyy/MM/dd', 'en'))
            ||
            (this.vigencias[1].seleccionado == true
              && formatDate(a.fechaHasta, 'yyyy/MM/dd', 'en') < formatDate(fechaHora, 'yyyy/MM/dd', 'en')
            )
            ||
            (this.vigencias[2].seleccionado == true
              && formatDate(a.fechaDesde, 'yyyy/MM/dd', 'en') > formatDate(fechaHora, 'yyyy/MM/dd', 'en')
            )

          )
        }

        if (this.estados.some(e => e.seleccionado)) {
          agregar = agregar && (
            (this.estados[0].seleccionado == true
              && (a.estadoPrecio == TipoEstadoPrecio.Pendiente || a.estadoPrecio == TipoEstadoPrecio.AprobadoRetroactivo))
            ||
            (this.estados[1].seleccionado == true
              && a.estadoPrecio == TipoEstadoPrecio.Aprobado)
            ||
            (this.estados[2].seleccionado == true
              && a.estadoPrecio == TipoEstadoPrecio.Rechazado)
          );
        }

        if(this.hasRole(TipoUsuario.SUPERVISOR, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.MANDATARIO, 
          TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.RESPRODUCTO, TipoUsuario.TUTOR)) {
          agregar = agregar && a.nivelPrecio == 6;
        } else {
          if (this.niveles.some(e => e.seleccionado)) {
            agregar = agregar && (
              (this.niveles[0].seleccionado == true && a.nivelPrecio == 1)
              ||
              (this.niveles[1].seleccionado == true && a.nivelPrecio == 2)
              ||
              (this.niveles[2].seleccionado == true && a.nivelPrecio == 3)
              ||
              (this.niveles[3].seleccionado == true && a.nivelPrecio == 4)
              ||
              (this.niveles[4].seleccionado == true && a.nivelPrecio == 5)
              ||
              (this.niveles[5].seleccionado == true && a.nivelPrecio == 6)
            );
          }
        } 
        return agregar;
      });

      this.procesandoFiltros = false;
      this.cargarListasFiltrosMulti(this.listaPrecios);
    }
  }

  defaultCheckedNivel(nivel){
    if(this.hasRole(TipoUsuario.SUPERVISOR, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.MANDATARIO, 
      TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.RESPRODUCTO, TipoUsuario.TUTOR) && nivel.id=='6') {
      return true;
      }
  }

  bloquearNiveles(){
    if(this.hasRole(TipoUsuario.SUPERVISOR, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.MANDATARIO, 
      TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.RESPRODUCTO, TipoUsuario.TUTOR)) {
      return true;
      }
  }

  formatDate(year, month, day, hour, minute): string {
    return year + '-' + month.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + '-' + day.toLocaleString(undefined, { minimumIntegerDigits: 2 })
      + 'T' + hour.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + minute.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':00';
  }
  
  mostrarHistorial(precioSeleccionado: Precio) {
    let modalRef = this.modalService.open(ModalHistoricoComponent, { centered: true, size: 'lg' });
    modalRef.componentInstance.precio = precioSeleccionado;
    modalRef.result.then((result) => { }, () => { });
  }

  mostrarModalCorreccion(precioSeleccionado: Precio) {
    let modalRef = this.modalService.open(ModalCorreccionComponent, { centered: true, size: 'lg' });
    modalRef.componentInstance.precioSeleccionado = precioSeleccionado;
    modalRef.result.then((result) => { }, () => { });
  }

  obtenerDescripcionMoneda(moneda: Moneda){
    if(moneda.tipoMoneda == TipoMoneda.PES){
      return "ARP";      
    }else{
      return moneda.nombre;
    }
  }

  recargarLotes(items: ItemMultiFiltro[]){
    this.lotesMultiFiltro = items;
    this.filtrarPreciosMulti();
  }

  recargarCodigos(items: ItemMultiFiltro[]){
    this.codigosMultiFiltro = items;
    this.filtrarPreciosMulti();
  }

  recargarProductos(items: ItemMultiFiltro[]){
    this.productosMultiFiltro = items;
    this.filtrarPreciosMulti();
  }

  recargarMonedas(items: ItemMultiFiltro[]){
    this.monedasMultiFiltro = items;
    this.filtrarPreciosMulti();
  }

  recargarClientes(items: ItemMultiFiltro[]){
    this.clientesMultiFiltro = items;
    this.filtrarPreciosMulti();
  }

  recargarTiposVenta(items: ItemMultiFiltro[]){
    this.tiposVentaMultiFiltro = items;
    this.filtrarPreciosMulti();
  }

  recargarEntregas(items: ItemMultiFiltro[]){
    this.entregasMultiFiltro = items;
    this.filtrarPreciosMulti();
  }

  recargarAreas(items: ItemMultiFiltro[]){
    this.areasMultiFiltro = items;
    this.filtrarPreciosMulti();
  }

  recargarSegmentos(items: ItemMultiFiltro[]){
    this.segmentosMultiFiltro = items;
    this.filtrarPreciosMulti();
  }

  recargarAeroplantas(items: ItemMultiFiltro[]){
    this.aeroplantasMultiFiltro = items;
    this.filtrarPreciosMulti();
  }

  hasRole(...roles: TipoUsuario[]) {
    return this.authService.hasRole(...roles);
  }

  agregarPrecios(precioSeleccionado: Precio) {
    if (!precioSeleccionado.seleccionadoUi) {
      precioSeleccionado.seleccionadoUi = true;
      this.preciosEnviar.push(precioSeleccionado);
    } else {
      const index: number = this.preciosEnviar.indexOf(precioSeleccionado);
      if (index !== -1) {
        this.preciosEnviar.splice(index, 1);
      }
      precioSeleccionado.seleccionadoUi = false;
    }

  }

  getCheckedItemList() {
    var listaIdsSeleccionados = [];

    for (var i = 0; i < this.preciosEnviar.length; i++) {
      if(this.preciosFiltradosMultifiltro.filter(p => p.id == this.preciosEnviar[i].id).length != 0){
        listaIdsSeleccionados.push(this.preciosEnviar[i].id);
      }      
    }

    return listaIdsSeleccionados;
  }

  desactivarPrecios() {
    var listaIdsSeleccionados = this.getCheckedItemList();

    if (listaIdsSeleccionados.length == 0) {
      this.messageService.showErrorMessage('No se seleccionó ningún precio para desactivar.');
    } else {
      const modalRef = this.modalService.open(ModalConfirmComponent, { centered: true });
      modalRef.componentInstance.titulo = "Atención"
      modalRef.componentInstance.mensaje = "Se solicitara la desactivación de " + listaIdsSeleccionados.length + " precios. ¿Está seguro?";
      modalRef.componentInstance.textoSi = "Si";
      modalRef.componentInstance.textoNo = "Cancelar";
      modalRef.result.then(() => {
        this.accionDesactivarPrecios(listaIdsSeleccionados);
      });
    }
  }

  accionDesactivarPrecios(listaIdsSeleccionados){
    var estado = new EstadoPrecio();
    estado.responsable = this.preciosService.obtenerStringResponsableV2();
    estado.estadoPrecio = TipoEstadoPrecio.Aprobado;
    estado.activo = TipoActivoPrecio.PendienteDesactivacion;

    this.actualizando = true;

    this.preciosService.cambiarEstadoPrecio(estado, listaIdsSeleccionados)
      .subscribe(nivelAprobacion => {
        this.messageService.showSuccessMessage('Se proceso la solicitud de desactivación de precios correctamente.');
        this.actualizando = false;
        this.getPreciosPage(1, this.pageSize);
      },
        (err: ErrorModel) => {
          this.messageService.showErrorMessage('Hubo un problema al desactivar los precios, por favor intente nuevamente.')
        });
  }

  // getters

  get busquedas() {
    return this.searchForm.get("busqueda");
  }

  get vigenciaForm() {
    return this.searchForm.get("vigenciaForm");
  }

  get estadoForm() {
    return this.searchForm.get("estadoForm");
  }

  get nivelForm() {
    return this.searchForm.get("nivelForm");
  }

  get fechaVigencia() {
    return this.formatDate(this.modelFecha.year, this.modelFecha.month, this.modelFecha.day, this.modelHora.hour, this.modelHora.minute);
  }
}