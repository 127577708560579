import { TipoPico } from "../../enums/tipoPico";

export class Pico {
    id: string;
    nombre : string;
    tipoPico: TipoPico;
    aforadorActual: number;
    activo: boolean;
    porDefecto: boolean;
    aforadorFinalManual: number;
}