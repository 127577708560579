import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ClienteService } from 'src/app/cliente/cliente.service';
import { Aeroplanta } from 'src/app/shared/models/despacho/Aeroplanta';
import { ClienteEncabezado } from 'src/app/shared/models/cliente/ClienteEncabezado';

//import { AeroplantasService } from 'src/app/trader/aeroplantas.service';

import { NotificacionesService } from '../../notificaciones.service';
import { TipoNovedadEncabezadoDTO } from 'src/app/shared/models/notificaciones/TipoNovedadEncabezadoDTO';
import { NotificacionSistemaDTO } from 'src/app/shared/models/notificaciones/NotificacionSistemaDTO';
import { MessageService } from 'src/app/shell/message.service';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { UsuarioService } from 'src/app/account/usuario.service';
import { Usuario } from 'src/app/shared/models/despacho/Usuario';
import { GrupoEncabezado } from 'src/app/shared/models/notificaciones/GrupoEncabezado';
import { TipoNovedadDTO } from 'src/app/shared/models/notificaciones/TipoNovedadDTO';
import { OperadorLogico } from 'src/app/shared/enums/OperadorLogico';
import { FiltroSeleccionado } from 'src/app/shared/models/notificaciones/FiltroSeleccionado';
import { Filtro } from 'src/app/shared/models/notificaciones/Filtro';

@Component({
  selector: 'app-nueva-notificacion-sistema',
  templateUrl: './nueva-notificacion-sistema.component.html',
  styleUrls: ['./nueva-notificacion-sistema.component.css']
})
export class NuevaNotificacionSistemaComponent implements OnInit {

  @Input() notificacionDuplicar: NotificacionSistemaDTO;

  activeModal: NgbModal;

  notificacionForm: UntypedFormGroup;
  notificacionSistema: NotificacionSistemaDTO;

  // variables
  tipoNovedadPorRoles: TipoNovedadEncabezadoDTO[] = [];
  tipoNovedades: TipoNovedadDTO;
  etiquetasPorTipoNovedad: string[] = [];
  cargandoTipoNovedad: boolean = false;
  cargandoEtiquetas: boolean = false;
  cargandoUsuarios: boolean = false;
  cargandoGrupos: boolean = false;
  cargandoFiltros: boolean = false;
  cargandoUsuariosAccion: boolean = false;
  guardandoDatos: boolean = false;
  listaDeEmails: string[] = [];  
  listaDeUsuarios: Usuario[] = []; 
  listaDeGrupos: GrupoEncabezado[] = [];   
  usuariosSeleccionados: Usuario[] = []; 
  gruposSeleccionados: GrupoEncabezado[] = [];  

  /**
  * Filtros
  */
  listaDeClaves: Filtro[] = [];  
  operadorLogico = OperadorLogico;
  listaDeFiltros: FiltroSeleccionado[] = [];

  constructor(activeModal: NgbModal,
    private formBuilder: UntypedFormBuilder,
    private notificacionService:NotificacionesService,
    private messageService: MessageService,
    private usuarioService:UsuarioService,
    private changeDetector: ChangeDetectorRef) 
    {
      this.activeModal = activeModal;
    }


  ngOnInit() {
    this.usuariosSeleccionados = [];
    this.gruposSeleccionados = [];
    this.crearFormulario();
    this.obtenerDatosNotificacionSistema();
    if(this.notificacionDuplicar){
      this.cargarFormulario()
    }    
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  crearFormulario() {
    this.notificacionForm = this.formBuilder.group({
      tituloFormControl: ['', Validators.required],
      tipoNovedadFormControl: ['', Validators.required],
      emailFormControl: [''],
      mensajeFormControl:  ['', Validators.required],
      usuarioFormControl:  [''],
      grupoFormControl:  [''],
      clavesFormControl: [null],
      operadorFormControl: [null],
      valorFormControl: [null],
      usuariosCheck: new UntypedFormArray([])
    });
  }
  
  cargarFormulario() {
    this.tituloFormControl.setValue(null);
    this.tipoNovedadFormControl.setValue(this.notificacionDuplicar.tipoNovedad);    
    this.mensajeFormControl.setValue(this.notificacionDuplicar.mensaje);
    this.clavesFormControl.setValue(null);
    this.operadorFormControl.setValue(null);
    this.valorFormControl.setValue(null);
    this.llenarListaFiltros();
    this.llenarListaEmails();
    this.llenarListaUsuarios();
    this.llenarListaGrupos();
  }

  llenarListaEmails() {  
    this.listaDeEmails=[];  
    for (let i = 0; i < this.notificacionDuplicar.emails.length; i++) {
      this.listaDeEmails.push(this.notificacionDuplicar.emails[i]);      
    } 
  }
  
  llenarListaFiltros() {  
    this.listaDeFiltros=[];  
    if(this.notificacionDuplicar.filtrosSeleccionados!=null) {
      for (let i = 0; i < this.notificacionDuplicar.filtrosSeleccionados.length; i++) {
        this.listaDeFiltros.push(this.notificacionDuplicar.filtrosSeleccionados[i]);      
      } 
    }
  }

  llenarListaUsuarios() {  
    this.usuariosSeleccionados=[];  
    for (let i = 0; i < this.notificacionDuplicar.usuarios.length; i++) {
      this.usuariosSeleccionados.push(this.notificacionDuplicar.usuarios[i]);      
    } 
  }

  llenarListaGrupos() {  
    this.gruposSeleccionados=[];  
    for (let i = 0; i < this.notificacionDuplicar.gruposUsuarios.length; i++) {
      this.gruposSeleccionados.push(this.notificacionDuplicar.gruposUsuarios[i]);      
    } 
  }

  // llamadas asíncronas

  async obtenerDatosNotificacionSistema() {
    
    try{
      this.cargandoTipoNovedad = true; 
      let novedadesSinFiltrar = await this.notificacionService.getTipoNovedadPorRolesPromise(this.usuarioService.getRoles());
      this.tipoNovedadPorRoles = novedadesSinFiltrar.filter(novedad => novedad.descripcion !== 'Saldos')
      this.cargandoTipoNovedad = false;
  
      this.refrescarNovedades();

      this.cargandoUsuarios = true; 
      this.listaDeUsuarios = await this.usuarioService.obtenerTodosLosUsuariosPromise();
      this.cargandoUsuarios = false; 

      this.cargandoGrupos = true; 
      this.listaDeGrupos = await this.notificacionService.getGruposPromise();
      this.cargandoGrupos = false; 

    }catch(error){
      this.cargandoEtiquetas = false;
      this.cargandoTipoNovedad = false; 
      this.cargandoUsuarios = false; 
      this.cargandoGrupos = false; 
      this.messageService.showErrorMessage("Error: " + error.message + " descripcion: " + error.descripcion);
    }
  }

  async refrescarNovedades() {

    try {
      this.cargandoEtiquetas = true;
      this.cargandoFiltros = true;
      this.cargandoUsuariosAccion = true;
      
      if(this.tipoNovedadFormControl.value!='') {
        this.clavesFormControl.setValue(null);
        this.operadorFormControl.setValue(null);
        this.valorFormControl.setValue(null);
        this.tipoNovedades = await this.notificacionService.getDatosPorTipoNovedadPromise(this.tipoNovedadFormControl.value.id)
      } else {
        this.tipoNovedades = await this.notificacionService.getDatosPorTipoNovedadPromise(this.tipoNovedadPorRoles[0].id)
      }
      
      this.etiquetasPorTipoNovedad = this.tipoNovedades.etiquetas;
      this.listaDeClaves = this.tipoNovedades.filtros;
        
      this.iniciarFormUsuariosAccion(); // usuariosAccionFormArray

      this.cargandoEtiquetas = false;  
      this.cargandoFiltros = false;  
      this.cargandoUsuariosAccion = false;  
    } catch (error) {
      this.cargandoEtiquetas = false;
      this.cargandoFiltros = false;
      this.cargandoUsuariosAccion = false;
      this.messageService.showErrorMessage("Error: " + error.message + " descripcion: " + error.descripcion);
    }    
  }

  iniciarFormUsuariosAccion() {    
    this.usuariosAccionFormArray.clear();
    if(this.notificacionDuplicar!=undefined) {
      const resultado = this.tipoNovedades.usuariosAccion.map( item => this.notificacionDuplicar.usuariosAccionSeleccionado.includes(item) );
      resultado.forEach( elem => this.usuariosAccionFormArray.push(new UntypedFormControl(elem)) );
    } else {
      this.tipoNovedades.usuariosAccion.forEach( () => this.usuariosAccionFormArray.push(new UntypedFormControl(false)) );
    }    
  }

  // form

  compareTipoNovedad(n1: any, n2: any): boolean {  
    return n1 && n2 ? n1.id == n2.id : n1 == n2;
  }

  keysOperadorLogico(): Array<string> {
    var keys = Object.keys(this.operadorLogico);
    keys = keys.slice(keys.length / 2);
    if(this.claveSinValores()){
      return keys;
    } else {
      return keys.filter(k => k == "Igual" || k == "Distinto");
    }
  }

  obtenerValorOperadorLogico(op){
    return Number(this.operadorLogico[op]);
  }

  obtenerNombreOperador(i) {
    return this.operadorLogico[i];
  }

  filtrarEtiquetas() {
    let tipoNovedadId: string = this.tipoNovedadFormControl.value;
    this.cargandoEtiquetas = true;
    this.notificacionService.getEtiquetasPorTipoNovedad(tipoNovedadId)
      .subscribe(result => {              
        this.etiquetasPorTipoNovedad = result;              
        this.cargandoEtiquetas = false;
      }, () => { 
        this.cargandoEtiquetas = false;        
      }
    );
  }

  agregarMailDestinatario() {
    if(this.emailFormControl.value != null && this.emailFormControl.value != '')  {
      if(this.listaDeEmails.some( m => m == this.emailFormControl.value )) {
        this.messageService.showErrorMessage("El mail ingresado ya esta en la lista");
        return;
      }
      this.listaDeEmails.push(this.emailFormControl.value);
      this.emailFormControl.setValue(null);
    }  
  }

  borrarMailDestinatario(mail) {
    this.listaDeEmails = this.listaDeEmails.filter( x => x != mail );
  }

  /** Filtros */

  agregarFiltroSeleccionado() {
    let nuevoFiltro: any;
    let controlarCampoValor:boolean;
    if(this.mostrarInputValor()) {
      nuevoFiltro = { clave: this.clavesFormControl.value, operadorLogico: this.operadorFormControl.value, valor: this.valorFormControl.value, activo: true }
      controlarCampoValor=true;
    } else {
      nuevoFiltro = { clave: this.clavesFormControl.value, operadorLogico: this.operadorFormControl.value, valor: null, activo: true };
      controlarCampoValor=false;
    }
    if (this.validarInput(controlarCampoValor)) {
      this.listaDeFiltros.push(nuevoFiltro);
    } else {
      this.messageService.showErrorMessage("Por favor revise los campos a completar.")
    }
  }

  borrarFiltroSeleccionado(filtro) {
    this.listaDeFiltros = this.listaDeFiltros.filter( f => f != filtro);
  }

  validarInput(controlarCampoValor:boolean) {
    if(!controlarCampoValor) {
      return (this.clavesFormControl.value != '' && this.clavesFormControl.value != null) && (this.operadorFormControl.value != null)
    } else {
      return (this.clavesFormControl.value != '' && this.clavesFormControl.value != null) && (this.operadorFormControl.value != null) && (this.valorFormControl.value != null && this.valorFormControl.value != '')
    }
  }

  mostrarInputValor() {
    if(this.operadorFormControl.value!=null) {
      return (this.operadorFormControl.value!=OperadorLogico.Verdadero && this.operadorFormControl.value!=OperadorLogico.Falso)      
    } else {
      return true
    }
  }

  claveSinValores() {
    var filtro = this.listaDeClaves.find(f => f.clave == this.clavesFormControl.value);
    if(filtro){
      return filtro.valoresPosibles.length == 0;
    }
    return true;
  }

  obtenerValoresClave(){
    var filtro = this.listaDeClaves.find(f => f.clave == this.clavesFormControl.value);
    if(filtro){
      return filtro.valoresPosibles;
    }
  }

  limpiarCamposOpValor() {
    this.operadorFormControl.reset();
    this.valorFormControl.reset();
  }
  
  /** usuarios */
  
  agregarUsuarioDestinatario() {
    if (typeof (this.usuarioFormControl.value) === 'string' || (this.usuarioFormControl.value==null)) {
      this.usuarioFormControl.setValue(null);
    } else { 
      if(this.usuariosSeleccionados.some( u => u.id == this.usuarioFormControl.value.id )) {
        this.messageService.showErrorMessage("El usuario ingresado ya esta en la lista");
        return;
      }
      this.usuariosSeleccionados.push(this.usuarioFormControl.value);
      this.usuarioFormControl.setValue(null);
    }
  }

  borrarUsuarioDestinatario(usuario) {
    this.usuariosSeleccionados = this.usuariosSeleccionados.filter( x => x.id != usuario.id );
  }

  formatterUsuario = (op: { nombresCompletos: string, userName: string }) => {
    return (op != null && op.nombresCompletos != null) ? `${op.nombresCompletos}` : "";
    //return `${op.nombresCompletos}`
  } 
  
  searchUsuario = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    map(term => {
      return this.listaDeUsuarios == null || term.length < 2 ? [] : this.listaDeUsuarios.filter(op => this.containsString(this.usuarioFormControl.value, op.nombresCompletos)).slice(0, 10)
    })
  )

  selectUsuario(event: any): void {
    this.usuarioFormControl.setValue(event.item);
  }

    /** Grupos */

  agregarGrupoDestinatario() {
    if (typeof (this.grupoFormControl.value) === 'string' || (this.grupoFormControl.value==null)) {
      this.grupoFormControl.setValue(null);
    } else {
      if(this.gruposSeleccionados.some( g => g.id == this.grupoFormControl.value.id )) {
        this.messageService.showErrorMessage("El grupo ingresado ya esta en la lista");
        return;
      }       
      this.gruposSeleccionados.push(this.grupoFormControl.value);
      this.grupoFormControl.setValue(null);           
    }
  }

  borrarGrupoDestinatario(grupo) {
    this.gruposSeleccionados = this.gruposSeleccionados.filter( x => x.id != grupo.id );
  }

  formatterGrupo = (op: { titulo: string }) => {
    return (op != null && op.titulo != null) ? `${op.titulo}` : "";
  } 
  
  searchGrupo = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    map(term => { 
      return this.listaDeGrupos == null || term.length < 2 ? [] : this.listaDeGrupos.filter(op => this.containsString(this.grupoFormControl.value, op.titulo)).slice(0, 10)
    })
  )

  selectGrupo(event: any): void {
    this.grupoFormControl.setValue(event.item);
  }

  containsString(searchValue: string, ...searchIn: string[]): boolean {
    const concat = searchIn.toString();
    return concat.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
  }

  agregarEtiqueta(etiqueta) {       
    var cursorPos=(document.getElementById("mensaje") as HTMLInputElement).selectionStart;    
    var nuevoMsje=this.mensajeFormControl.value.slice(0, cursorPos)+' {'+etiqueta+'} '+this.mensajeFormControl.value.slice(cursorPos)
    this.mensajeFormControl.setValue(nuevoMsje);
  }

  validarDatosCargados() {
    
    const usuariosAccion = this.notificacionForm.value.usuariosCheck
      .map((checked, i) => checked ? this.tipoNovedades.usuariosAccion[i] : null)
      .filter(v => v !== null);
    
    if(this.listaDeEmails.length==0 && usuariosAccion.length == 0 && this.usuariosSeleccionados.length == 0
      && this.gruposSeleccionados.length == 0) {
      this.messageService.showErrorMessage('Debe ingresar al menos un destinatario');
      return false;
    }

    return true;    
  }

  guardarNotificacion() {    
    if(!this.notificacionForm.valid){
      this.messageService.showErrorMessage('Revise los campos incompletos');
      this.notificacionForm.markAllAsTouched();
      return;
    }else if(!this.validarDatosCargados() ){    
      return;
    }    

    const selectedusuarios = this.notificacionForm.value.usuariosCheck
      .map((checked, i) => checked ? this.tipoNovedades.usuariosAccion[i] : null)
      .filter(v => v !== null);

    this.guardandoDatos = true;
    let nuevaNotificacion = new NotificacionSistemaDTO;
    nuevaNotificacion.titulo = this.tituloFormControl.value;
    nuevaNotificacion.tipoNovedad = this.tipoNovedadFormControl.value;
    nuevaNotificacion.activo = true; // siempre activo
    nuevaNotificacion.emails = this.listaDeEmails;
    nuevaNotificacion.usuarios = this.usuariosSeleccionados;
    nuevaNotificacion.gruposUsuarios = this.gruposSeleccionados;
    nuevaNotificacion.mensaje = this.mensajeFormControl.value;
    nuevaNotificacion.filtrosSeleccionados = this.listaDeFiltros;
    nuevaNotificacion.usuariosAccionSeleccionado = selectedusuarios;
    
    this.notificacionService.crearNotificacionSistema(nuevaNotificacion)
      .subscribe(result => {
        console.log('Entro crear ' + result);
        this.messageService.showSuccessMessage('Notificación creada con éxito.');
        this.guardandoDatos = false;
        this.activeModal.dismissAll();
      }, (err: ErrorModel) => { 
        this.messageService.showErrorMessage('Hubo un error al crear la notificación. '+err.message+' - '+err.description);
        this.guardandoDatos = false;
      });
  }

  /** getters */
  
  get tituloFormControl() {
    return this.notificacionForm.get('tituloFormControl');
  }

  get tipoNovedadFormControl() { 
    return this.notificacionForm.get('tipoNovedadFormControl'); 
  }

  get emailFormControl() { 
    return this.notificacionForm.get('emailFormControl'); 
  }

  get mensajeFormControl() { 
    return this.notificacionForm.get('mensajeFormControl'); 
  }

  get usuarioFormControl() {
    return this.notificacionForm.get('usuarioFormControl');
  }

  get grupoFormControl() {
    return this.notificacionForm.get('grupoFormControl');
  }

  get clavesFormControl() {
    return this.notificacionForm.get('clavesFormControl');
  }  

  get operadorFormControl() {
    return this.notificacionForm.get('operadorFormControl');
  }  

  get valorFormControl() {
    return this.notificacionForm.get('valorFormControl');
  }  

  get usuariosAccionFormArray(): UntypedFormArray {
    return this.notificacionForm.controls.usuariosCheck as UntypedFormArray;
  }
}
