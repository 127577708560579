export enum TipoMovimiento {
  Ninguno = 0,
  FAA = 1,
  FAB = 6,
  NCA = 3,
  NCB = 8,
  NDA = 2,
  NDB = 7,
  REM = 9,
  RDV = 99,
}
