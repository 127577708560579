<div class="modal-header bg-light">
    <h4 class="modal-title">CBU</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="cancelar()"></button>
  </div>
  <div class="modal-body">
    <form [formGroup]="agregarCBUForm">
      <table id="tabla" class="table table-sm table-striped table-hover">
        <thead class="text-center">
          <tr>
            <th>CBU</th>
            <th>Tipo Banco Interlocutor</th>
            <th>En uso</th>
          </tr>
        </thead>
        <tbody>
            <tr *ngFor="let listaCbu of this.clienteCBU.cbUs; let i = index" class="text-center">                
                <td [contentEditable]="usuarioModificaCampos()" (keypress)="onlyNumberKey($event)"
                (blur)="actualizar(i, $event )">
                {{listaCbu.cbu}}</td>
                <td>{{ listaCbu.tipoBancoInterlocutor }}</td>                
                <td><input type="radio" class="form-check-input" (click)="checkearDefecto(listaCbu)" 
                  name="enUsoCheckFormControl"  [checked]="listaCbu.enUso"/></td>            
            </tr>
        </tbody>
      </table>
      <div class="modal-body" *ngIf="this.clienteCBU.cbUs.length == 0">
        <div class="alert alert-warning">No hay CBU cargados para el cliente seleccionado.</div>
      </div>
    </form>
      <a ngbTooltip="Agregar CBU"
            (click)="agregarCBU()"><i class="fa fa-plus-square fa-2x text-primary ps-2"></i></a>

      <!--Nuevo CBU-->
      <div [formGroup]="agregarCBUForm" *ngIf="agregarCBUNuevo" style="padding: 1em; border: 1px solid #ccc;">

        <div class="row">
          <div class="mb-3 row col-md-12">
            <label for="nuevoCBUFormControl" class="col-sm-2 col-form-label">*CBU</label>
            <div class="col-sm-7">
              <input class="form-control" formControlName="nuevoCBUFormControl" maxlength="22" (keypress)="onlyNumberKey($event)"/>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="mb-3 row col-md-12">
            <label for="nuevoTipoBancoInterlocutorFormControl" class="col-sm-2 col-form-label">*Tipo Banco Interlocutor</label>
            <div class="col-sm-7">
              <input class="form-control"
                formControlName="nuevoTipoBancoInterlocutorFormControl" (keypress)="onlyNumberKey($event)"/>
            </div>
            <div class="col-sm-3">
              <button type="button" (click)="asignarCBU()" class="btn btn-primary">Asignar</button>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="mb-3 row col-12">
            <div class="col-12"><b>CBU Asignados</b></div>
          </div>
        </div>
        <div class="row" *ngIf="this.nuevosCBU != null && this.nuevosCBU.length > 0">
          <div class="mb-3 row col-12" *ngFor="let nc of this.nuevosCBU">
            <div class="col-4">{{nc.cbu}} - {{nc.tipoBancoInterlocutor}}</div>
            <div class="col-5">
              <label class="col-3" for="checkDefecto">En Uso</label>
              <input class="col-3" type="radio" (click)="checkearDefecto(nc)" [value]="false" [checked]="nc.enUso" id="checkDefecto"/>
            </div>
            <div class="col-3"><button type="button" (click)="desasignarCBU(nc)" class="btn btn-danger btn-sm col-12"><i
                  class="fa fa-trash col-12" aria-hidden="true"></i></button></div>
          </div>
        </div>

        <div *ngIf="this.nuevosCBU == null || this.nuevosCBU.length == 0" class="row">
          <div class="mb-3 row col-md-12">
            <div class="col-sm-12">No hay CBU nuevo.</div>
          </div>
        </div>
      </div>
      
    
    <!-- <small class="text text-danger">*Campos obligatorios</small> -->
  </div>
  <div *ngIf="this.usuarioHabilitado()" class="modal-footer bg-light justify-content-center">
    <button class="btn btn-primary me-2" (click)="guardarCBU()" [disabled]="this.guardando">
      <span *ngIf="!guardando">Guardar</span>
      <div *ngIf="guardando" class="d-flex align-items-center">
        <div class="spinner-border text-light spinner-border-sm" role="status"></div>
        <span>Guardando...</span>
      </div>
    </button>
    <button class="btn btn-secondary" (click)="cancelar()">Cancelar</button>
  </div>
  
