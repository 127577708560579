<div class="col col-md-12">
  <div class="spinner-container" *ngIf="descargandoInformacion">
    <ngx-spinner name="spinnerGrafico" bdOpacity=0.3bd
      Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
      <p style="color: white">C a r g a n d o . . . </p>
    </ngx-spinner>    
  </div>
</div>
<div class="border border-dark" *ngIf="!descargandoInformacion">
  <form [formGroup]="informacionCliente">
    <div class="row">
      <!--RAZON SOCIAL-->
      <div class="mb-3 col-12 mt-2">
        <div class="mb-3 row">
          <div class="col-2">
            <label for="razonSocialFormControl" class="float-end col-form-label">*Razón social:</label>
          </div>
          <div class="col-10">
            <input class="form-control" formControlName="razonSocialFormControl" id="razonSocialFormControl"
              placeholder="Razón Social"/>
            <div
              *ngIf="razonSocial.invalid && razonSocial.errors.required && (razonSocial.dirty || razonSocial.touched)"
              class="alert alert-danger">
              Requerido
            </div>
          </div>
        </div>
      </div>

      <!--COndicion IVA cuando viene de afip y soy usuario abm clientes-->
      <div class="mb-3 col-6">
        <div class="mb-3 row">
          <div class="col-4">
            <label for="condicionIVAFormControl" class="float-end col-form-label">*Condicion IVA:</label>
          </div>
          <div class="col-8">
            <select class="form-select" formControlName="condicionIVAFormControl" id="condicionIVAFormControl">              
              <ng-container *ngIf="this.condIva.value===null && this.clienteAMostrar.condicionIva===null">
                <option value="null" selected disabled>No registrada por AFIP</option>
              </ng-container>
              <ng-container *ngIf="this.condIva.value!=null">
                <option value="null" [ngValue]="null" selected disabled>Seleccione una Condicion de IVA</option>
                <option *ngFor="let con of condicionIva" [ngValue]="con">
                  {{ con.descripcion }}
                </option>
              </ng-container>
            </select>
            <div *ngIf="condIva.invalid && condIva.errors.required && (condIva.dirty || condIva.touched)"
              class="alert alert-danger">
              Requerido
            </div>
            <loading-text-spinner *ngIf="this.obteniendoCondicionIVA" message="Obteniendo condiciones de IVA..."
              color="primary">
            </loading-text-spinner>
          </div>
        </div>
      </div>

      <!--AREA-->
      <div class="mb-3 col-6">
        <div class="mb-3 row">
          <div class="col-4">
            <label for="areaFormControl" class="float-end col-form-label">*Area:</label>
          </div>
          <div class="col-8">
            <select class="form-select" formControlName="areaFormControl" id="areaFormControl" (change)="filtrar()">
              <option *ngFor="let a of areas" [ngValue]="a">
                {{a.descripcion}}
              </option>
            </select>
            <loading-text-spinner *ngIf="this.cargandoAreas" message="Obteniendo areas..." color="primary">
            </loading-text-spinner>
          </div>
        </div>
      </div>

      <!--Tipo Documento-->
      <div class="mb-3 col-6">
        <div class="mb-3 row">
          <div class="col-4">
            <label for="tipoDocumentoFormControl" class="float-end col-form-label">*Tipo documento:</label>
          </div>
          <div class="col-8">
            <select class="form-select" (change)="resetValidators($event)" formControlName="tipoDocumentoFormControl" id="tipoDocumentoFormControl">
              <option *ngFor="let doc of keysDocumentos()" [ngValue]="documentos[doc]">
                {{ doc }}
              </option>
            </select>
            <div *ngIf="tipoDoc.invalid && tipoDoc.errors.required && (tipoDoc.dirty || tipoDoc.touched)"
              class="alert alert-danger">
              Requerido
            </div>
          </div>
        </div>
      </div>

      <!--SUBAREA-->
      <div class="mb-3 col-6">
        <div class="mb-3 row">
          <div class="col-4">
            <label for="subAreaFormControl" class="float-end col-form-label" style="text-align: right;">*Segmento:</label>
          </div>
          <div class="col-8">
            <select class="form-select" formControlName="subAreaFormControl" id="subAreaFormControl">
              <option *ngFor="let s of subAreas" [ngValue]="s">
                {{s.descripcion}}
              </option>
            </select>
            <loading-text-spinner *ngIf="this.cargandoSegmentos" message="Obteniendo sub-areas..." color="primary">
            </loading-text-spinner>
          </div>
        </div>
      </div>

      <!--NUmero Documento-->
      <div class="mb-3 col-6">
        <div class="mb-3 row">
          <div class="col-4">
            <label for="numeroDocumentoFormControl" class="float-end col-form-label" style="text-align: right;">*Número documento:</label>
          </div>
          <div class="col-8">
            <input class="form-control" appOnlyNumber formControlName="numeroDocumentoFormControl" id="numeroDocumentoFormControl"
              placeholder="Numero" (blur)="this.cuitExiste()"/>
            <div
              *ngIf="numDocumento.invalid && numDocumento.errors.required && (numDocumento.dirty || numDocumento.touched)"
              class="alert alert-danger">
              Requerido
            </div>
            <div *ngIf="numDocumento.invalid  && (numDocumento.dirty || numDocumento.touched) && (this.tipoDoc.value === TipoDocumento.DNI)"
              class="alert alert-danger">
              El número de DNI no tiene el formato correcto.
            </div>
            <div *ngIf="numDocumento.invalid  && (numDocumento.dirty || numDocumento.touched) && (this.tipoDoc.value === TipoDocumento.CUIT || this.tipoDoc.value === TipoDocumento.CUIL)"
              class="alert alert-danger">
              El número de CUIT/CUIL no tiene el formato correcto.
            </div>
            <div *ngIf="this.tipoDoc.value == TipoDocumento.CUIT && this.estaCUIT" class="alert alert-danger">
              El CUIT ingresado ya existe.
            </div>
          </div>
        </div>
      </div>
      <!--EsPACIO EN BLANCO-->
      <div class="mb-3 col-6">
      </div>

      <!--Direccion Fiscal-->
      <div class="mb-3 col-6">
        <div class="mb-3 row">
          <div class="col-4">
            <label for="direccionFiscalFormControl" class="float-end col-form-label">*Dirección fiscal:</label>
          </div>
          <div class="col-8">
            <button class="btn btn-primary" type="button" id="direccionFiscalFormControl"
              (click)="mostrarDireccionFiscal()"> Datos de dirección
            </button>
          </div>
        </div>
      </div>

      <!--Actividad PAD-->
      <div class="mb-3 col-6">
        <div class="mb-3 row">
          <div class="col-4">
            <label for="actividadFormControl" class="float-end col-form-label">*Actividad:</label>
          </div>
          <div class="col-8">
            <select class="form-select" id="actividadFormControl" formControlName="actividadFormControl">
              <option *ngFor="let act of keysActividades()" [ngValue]="actividadCliente[act]">
                {{ act }}
              </option>
            </select>
            <loading-text-spinner *ngIf="this.obteniendoActividad" message="Obteniendo actividades..." color="primary">
            </loading-text-spinner>
            <div *ngIf="actividad.invalid && actividad.errors.required && (actividad.dirty || actividad.touched)"
              class="alert alert-danger">
              Requerido
            </div>
          </div>
        </div>
      </div>
      
      <!--Cuenta SAP-->
      <div class="mb-3 col-6">
        <div class="mb-3 row">
          <div class="col-4">
            <label for="cuentaSAPFormControl" class="float-end col-form-label">*Cuenta SAP:</label>
          </div>
          <div class="col-4">
            <input class="form-control" id="cuentaSAPFormControl" formControlName="cuentaSAPFormControl"/>
            <div *ngIf="SAPCuenta.invalid && SAPCuenta.errors.required && (SAPCuenta.dirty || SAPCuenta.touched)"
              class="alert alert-danger">
              Requerido
            </div>
          </div>
        </div>
      </div>
      
      <!--BLANCO-->
      <div style="margin-left: -9em; margin-top: 1.2em;" class="col-4">
        <div class="col-6">
          <button class="btn btn-success" type="button" id="cuentaSAPFormControlButton" style="font-size: small;"
            (click)="obtenerCuenta()" [disabled]="!this.usuarioModificaCampos()">
            <span *ngIf="!obteniendoCuenta">Actualizar Cuentas</span>
            <div *ngIf="obteniendoCuenta" class="d-flex align-items-center">
              <div class="spinner-border text-light spinner-border-sm" role="status"></div>
              <span>Actualizando...</span>
            </div>
          </button>
        </div>
      </div>
      
      <!--Cuenta CTC-->
      <div class="mb-3 col-6">
        <div class="mb-3 row">
          <div class="col-4">
            <label for="cuentaCTCFormControl" class="float-end col-form-label">*Cuenta CTC:</label>
          </div>
          <div class="col-4">
            <input class="form-control" id="cuentaCTCFormControl" formControlName="cuentaCTCFormControl"/>
            <div *ngIf="CTCCuenta.invalid && CTCCuenta.errors.required && (CTCCuenta.dirty || CTCCuenta.touched)"
              class="alert alert-danger">
              Requerido
            </div>
          </div>
        </div>
      </div>

      <!--Agrupación SERC-->
      <div class="mb-3 col-6">
        <div class="mb-3 row">
          <div class="col-4">
            <label for="agrupacionSercFormControl" class="float-end col-form-label">Agrupacion SERC:</label>
          </div>
          <div class="col-8">
            <select class="form-select" id="agrupacionSercFormControl" formControlName="agrupacionSercFormControl">
              <option [ngValue]="null">Sin agrupacion SERC</option>
              <option *ngFor="let agrupacion of agrupacionesSerc" [ngValue]="agrupacion">
                {{ agrupacion.codigo }} - {{ agrupacion.descripcion }} 
              </option>
            </select>
          </div>
        </div>
      </div>

      <!--Actividad AFIP-->
      <div class="mb-3 col-6" *ngIf="this.clienteAFIP != null">
        <div class="mb-3 row">
          <div class="col-4">
            <label for="actividadFormControl" class="float-end col-form-label">Actividad AFIP:</label>
          </div>
          <div class="col-8">
            <label class="col-form-label">{{ this.clienteAFIP.actividadAFIP?
              this.clienteAFIP.actividadAFIP.descripcionActividad : []}}</label>
          </div>
        </div>
      </div>
      <div class="mb-3 col-6" *ngIf="this.clienteAFIP == null">
        
      </div>

      <!--Actividad AFIP cuadro vacio-->
      <div class="mb-3 col-8">
        <div class="mb-3 row">
          <div class="col-12">

          </div>
        </div>
      </div>

      <div *ngIf="false" class="mb-3 col-8">
        <div class="mb-3 row">
          <div class="col-4">
            <label for="grupoClienteFormControl" class="float-end col-form-label">Segmento:</label>
          </div>
          <div class="col-8">
            <select class="form-select" id="actividadFormControl" formControlName="grupoClienteFormControl">
              <option *ngFor="let grupo of grupoClientes" [ngValue]="grupo">
                {{ grupo.descripcion }}
              </option>
            </select>
            <loading-text-spinner *ngIf="this.obteniendoGrupo" message="Obteniendo grupos de clientes..."
              color="primary">
            </loading-text-spinner>
          </div>
        </div>
      </div>

      <!--Direcciones de entrega-->
      <div class="mb-3 col-4">
        <div class="col-12 justify-content-center" style="display: flex;">
          <label class="col-form-label">Direcciones de entrega:</label>
        </div>
        <div class="col-12 justify-content-center" style="display: flex;">
          <button class="btn btn-primary" type="button" id="direccionEntregaFormControl"
            (click)="mostrarDireccionEntrega()"> Ver / Modificar
          </button>
          <a ngbTooltip="Agregar direccion de entrega" *ngIf="usuarioModificaCampos()"
            (click)="agregarDireccionEntrega()"><i class="fa fa-plus-square fa-2x text-primary ps-2" style="text-align: center;"></i></a>
        </div>
      </div>
      
      <!--Perc IVA-->
      <div class="mb-3 col-8">
        <div class="mb-3 row">
          <div class="col-4">
            <label class="float-end col-form-label">Perc. IVA: </label>
          </div>  
          <div class="col-4">
            <label class="col-form-label"><b>{{ this.percepcionIVA }}</b></label>
            <loading-text-spinner *ngIf="this.obteniendoPercepcionIVA" message="Obteniendo Perc.IVA..." color="primary">
            </loading-text-spinner>
          </div>
        </div>
      </div>

      <!--Comerciales-->
      <div class="mb-3 col-4">
        <div class="col-12 justify-content-center" style="display: flex;">
          <label class="float-end col-form-label">*Comerciales:</label>
        </div>
        <div class="col-12 justify-content-center" style="display: flex;">
          <button class="btn btn-primary" type="button" id="comercialesFormControl" (click)="mostrarComercial()"> Ver /
            Modificar
          </button>
        </div>
      </div>

      <!--Condicion IIBB-->
      <div class="mb-3 col-8">
        <div class="mb-3 row">
          <div class="col-4">
            <label for="condicionIIBBFormControl" class="float-end col-form-label">*Condición IIBB:</label>
          </div>
          <div class="col-8">
            <button class="btn btn-primary" type="button" id="condicionIIBBFormControl" (click)="mostrarIIBB()"> Ver /
              modificar
            </button>
          </div>
        </div>
      </div>

      <!--Responsable-->
      <div class="mb-3 col-4">
        <div class="col-12 justify-content-center" style="display: flex;">
          <label for="responsableFormControl" class="float-end col-form-label">*Responsable:</label>
        </div>
        <div class="col-12 justify-content-center" style="display: flex;">
          <button class="btn btn-primary" type="button" id="responsableFormControl" (click)="mostrarResponsable()"> Ver
            / Modificar
          </button>
        </div>
      </div>

      <!--CBU-->
      <div class="mb-3 col-8">
        <div class="mb-3 row">
          <div class="col-4">
            <label for="CBUFormControl" class="float-end col-form-label">CBU:</label>
          </div>
          <div class="col-8">
            <button class="btn btn-primary" type="button" id="CBUFormControl" (click)="mostrarCBU()"> Ver /
              modificar
            </button>
          </div>
        </div>
      </div>

      <!--Via de Pago-->
      <div class="mb-3 col-4">
          <div class="mb-3 row">
            <div class="col-4">
              <label for="viaDePagoFormControl" class="float-end col-form-label">Via de pago:</label>
            </div>
            <div class="col-8">
              <select class="form-select" id="viaDePagoFormControl" [compareWith]='customCompareViaPago'  formControlName="viaDePagoFormControl">
                <option [ngValue]="null" selected>Sin via de pago</option>
                <option *ngFor="let via of viasDePago" [ngValue]="via">
                  {{ via.descripcion }}
                </option>
              </select>
              <loading-text-spinner *ngIf="this.obteniendoViaDePago" message="Obteniendo vias de pago..." color="primary">
              </loading-text-spinner>
            </div>
          </div>
      </div>

      <!--Cliente Observado-->
      <div class="mb-3 col-1"></div>
      <div class="mb-3 col-3">
        <input type="checkbox" class="form-check-input" id="exampleCheck1"
          formControlName="clienteObservadoFormControl" />
        <label class="form-check-label ms-2" for="exampleCheck1">Cliente Observado</label>
      </div>

      <!--Activo-->
      <div class="mb-3 col-3">
        <input type="checkbox" class="form-check-input" id="exampleCheck2" formControlName="activoFormControl" />
        <label class="form-check-label ms-2" for="exampleCheck2">Activo</label>
      </div>
      <div class="mb-3 col-5"></div>

      <div *ngIf="usuarioModificaCampos() && !esElectrica" class="mb-3 col-10 offset-1 mt-2"
        style="border: 1px solid #ccc; padding: 1em;">
        <div class="mb-3 row">
          <div class="col-md-11" style="font-weight: bold;">Subir archivos adjuntos:</div>
          <div class="col-md-1"></div>
        </div>
        <div class="mb-3 row">
          <div class="col-md-1"></div>
          <div class="col-md-10">
            <app-upload-file [url]="this.clientesBaseUrl" (archivoSubido)="onArchivoSubido($event)"></app-upload-file>
          </div>
          <div class="col-md-1"></div>
        </div>
        <div class="mb-3 row" style="margin-bottom: 1em;">
          <div class="col-md-1"></div>
          <div class="col-md-10" style="font-weight: bold;">Lista de adjuntos a subir:</div>
          <div class="col-md-1"></div>
        </div>
        <div class="mb-3 row" *ngIf="archivosSubidos && archivosSubidos.length > 0">
          <div class="col-md-1"></div>
          <div class="col-md-10">
            <span style="margin-bottom: 0.5em;" *ngFor="let filename of archivosSubidos">{{filename}}<br /></span>
          </div>
          <div class="col-md-1"></div>
        </div>

        <div class="mb-3 row" *ngIf="archivosSubidos && archivosSubidos.length == 0">
          <div class="col-md-1"></div>
          <div class="col-md-10">
            No hay archivos adjuntados para enviar con las modificaciones.
          </div>
          <div class="col-md-1"></div>
        </div>
      </div>

      <div *ngIf="usuarioModificaCampos() && !this.creandoCliente() && !esElectrica" class="mb-3 col-12 mt-2">
        <div class="mb-3 row">
          <div class="col-1"></div>
          <div class="col-10">
            <label for="detalleModificacionFormControl" class="float-start col-form-label">*Detalle de modificaciones
              realizadas (obligatorio):</label>
          </div>
        </div>

        <div class="mb-3 row">
          <div class="col-1"></div>
          <div class="col-10">
            <textarea id="detalleModificacionFormControl" formControlName="detalleModificacionFormControl" type="text"
              style="min-width: 100%" rows="6" mdbInput placeholder="Detalle de modificaciones realizadas"></textarea>
            <div
              *ngIf="this.detalleModificacionFormControl.invalid && this.detalleModificacionFormControl.errors.required && (this.detalleModificacionFormControl.dirty || this.detalleModificacionFormControl.touched)"
              class="alert alert-danger">
              Requerido
            </div>
          </div>
        </div>
      </div>

      <!--BOTONES-->
      <div class="row col-md-12" *ngIf="clienteAMostrar.modificacionPendiente">
        <div class="col-md-8 offset-md-2 alert alert-danger">
          El cliente posee cambios pendientes de aprobar y no puede ser modificado hasta que se aprueben o rechazen
          dichos cambios.
        </div>
      </div>
      <div class="row col-md-12" *ngIf="errorGuardar">
        <div class="col-md-8 offset-md-2 alert alert-danger" [innerHtml]="errorGuardar">

        </div>
      </div>
      <div class="row col-md-12">
        <div class="mb-3 col-5"></div>
        <div class="mb-3 col-2">
          <button class="btn btn-primary" (click)="guardarCliente()"
            [disabled]="(guardandoCliente || clienteAMostrar.modificacionPendiente || desactivarBotonGuardarPorRoles()) && !esElectrica">

            <span *ngIf="!guardandoCliente">Guardar Cliente</span>
            <div *ngIf="guardandoCliente" class="d-flex align-items-center">
              <div class="spinner-border text-light spinner-border-sm" role="status"></div>
              <span>Guardando...</span>
            </div>
          </button>
        </div>
      </div>

    </div>
    <small class="text-danger">*Campos obligatorios.</small>

    <!--PANEL DESPLEGABLE-->
    <div id="contenedorcuentas" class="accordion" *ngIf="clienteAMostrar.cuentas.length > 0"
      formArrayName="listaCuentasFormControlArray">
      <div class="card panel panel-default" *ngFor="let cuen of this.clienteAMostrar.cuentas; let index = index">
        <div [formGroupName]="index">
          <div class="panel-heading justify-content-center" style="display: flex;"
            [ngClass]="{lightblue: cuen.activo==true, lightred: cuen.activo==false}">

            <h6 class="panel-title" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapseOne' + index"
              aria-expanded="false" [attr.aria-controls]="'collapseOne' + index">
              Cuenta: {{cuen.ctA_SGC}} - {{this.obtenerTipoCuenta(cuen.tipoCuenta)}} -
              [{{this.obtenerMoneda(cuen.moneda)}}]
            </h6>

          </div>
          <div id="collapseOne{{index}}" class="panel-collapse collapse" data-parent="#contenedorcuentas">
            <div class="panel-body">
              <div class="row">
                <!--NUmero Cuenta-->
                <div class="mb-3 col-6 mt-2">
                  <div class="mb-3 row">
                    <div class="col-5">
                      <label for="numeroCuentaFormControl" class="float-end col-form-label">Número Cuenta:</label>
                    </div>
                    <div class="col-7">
                      <input class="form-control" formControlName="numeroCuentaFormControl"
                        id="numeroCuentaFormControl{{index}}" placeholder="Numero" [value]="cuen.ctA_SGC"
                        [readOnly]="true" />
                    </div>
                  </div>
                </div>
                <!--Condicion Venta-->
                <div class="mb-3 col-6 mt-2">
                  <div class="mb-3 row">
                    <div class="col-5">
                      <label for="condicionVentaFormControl" class="float-end col-form-label">Condición venta:</label>
                    </div>
                    <div class="col-7">
                      <select class="form-select" id="condicionVentaFormControl" (change)="guardarCondicion(index)"
                        formControlName="condicionVentaFormControl"  [(ngModel)]="cuen.tipoCuenta">
                        <option *ngFor="let tcuen of keysTipoCuenta()" [ngValue]="obtenerValueTipoCuenta(tcuen)">
                          {{ tcuen }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                <!--MONEDA-->
                <div class="mb-3 col-6">
                  <div class="mb-3 row">
                    <div class="col-5">
                      <label for="monedaFormControl" class="float-end col-form-label">Moneda:</label>
                    </div>
                    <div class="col-7">
                      <select class="form-select" id="monedaFormControl" formControlName="monedaFormControl" 
                      (change)="guardarMoneda(index)" [(ngModel)]="cuen.moneda">
                        <option *ngFor="let mon of keysMonedas()" [ngValue]="obtenerValueMoneda(mon)">
                          {{ mon }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                <!--ENTREGA FACTURA-->
                <div class="mb-3 col-6">
                  <div class="mb-3 row">
                    <div class="col-5">
                      <label for="entregaFacturaFormControl" class="float-end col-form-label">Tipo de entrega de
                        factura:</label>
                    </div>
                    <div class="col-7">
                      <select class="form-select" id="entregaFacturaFormControl" (change)="guardarEntrega(index)"
                        formControlName="entregaFacturaFormControl"  [(ngModel)]="cuen.tipoEntregaFactura">
                        <option *ngFor="let entrega of keysTipoEntrega()" [disabled]="!usuarioModificaCampos()" [ngValue]="obtenerValueTipoEntrega(entrega)">
                          {{ entrega }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                <!--Identificador-->
                <div class="mb-3 col-6 mt-2">
                  <div class="mb-3 row">
                    <div class="col-5">
                      <label for="identificadorFormControl" class="float-end col-form-label">*Identificador:</label>
                    </div>
                    <div class="col-7">
                      <input class="form-control" formControlName="identificadorFormControl"
                        id="identificadorFormControl" placeholder="Numero" [value]="cuen.descripcion"/>
                    </div>
                  </div>
                </div>
                <!--Email-->
                <div class="mb-3 col-6 mt-2">
                  <div class="mb-3 row">
                    <div class="col-5">
                      <label for="emailFormControl" class="float-end col-form-label">Email:</label>
                    </div>
                    <div class="col-7">
                      <input class="form-control" formControlName="emailFormControl" id="emailFormControl"
                        [value]="cuen.email" />
                    </div>
                  </div>
                </div>
                <!--Plazo Pago-->
                <div class="mb-3 col-6 mt-2">
                  <div class="mb-3 row">
                    <div class="col-5">
                      <label for="plazoPagoFormControl" class="float-end col-form-label">Plazo Pago :</label>
                    </div>
                    <div class="col-7">
                      <select class="form-select" formControlName="plazoPagoFormControl" id="plazoPagoFormControl"
                      (change)="usuarioSeleccionoPlazoPago(index)" [(ngModel)]="cuen.condicionPago" [compareWith]='customCompareCondicionPago'>
                        <option *ngFor="let p of condicionesPago" [ngValue]="p">
                          ({{ p.codigo }}) {{ p.descripcion }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <!--Cuenta SAP-->
                <div class="mb-3 col-6 mt-2">
                  <div class="mb-3 row">
                    <div class="col-5">
                      <label for="ctaSapCuentaFormControl" class="float-end col-form-label">Cuenta SAP:</label>
                    </div>
                    <div class="col-7">
                      <input class="form-control" id="ctaSapCuentaFormControl" formControlName="ctaSapCuentaFormControl"
                        [value]="cuen.ctaSap" />
                      <span style="color: red; display: block; font-size: 10pt;">*Si el campo está vacio se utilizara la
                        Cuenta SAP del cliente</span>
                    </div>
                  </div>
                </div>
                <!--Periodo de Facturacion-->
                <div class="mb-3 col-6">
                  <div class="mb-3 row">
                    <div class="col-5">
                      <label for="periodoFormControl" class="float-end col-form-label">Período de facturación:</label>
                    </div>
                    <div class="col-7">
                      <select class="form-select" id="periodoFormControl" formControlName="periodoFormControl"
                        [(ngModel)]="cuen.periodoFacturacion.periodo"
                        (change)="usuarioSeleccionoPeriodo(index)">
                        <option [ngValue]="1">Diario</option>
                        <option [ngValue]="7">Semanal</option>
                        <option [ngValue]="15">Quincenal</option>
                        <option [ngValue]="30">Mensual</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="mb-3 col-6"
                  *ngIf="cuen.periodoFacturacion == null || !(cuen.periodoFacturacion.periodo == 7)"></div>
                <div class="mb-3 col-6"
                  *ngIf="cuen.periodoFacturacion != null && (cuen.periodoFacturacion.periodo == 7)">
                  <div class="mb-3 row">
                    <div class="col-6">
                      <label for="diasFormControl" class="float-end col-form-label">Dia:</label>
                    </div>
                    <div class="col-6">
                      <select class="form-select" id="diasFormControl" formControlName="diasFormControl"
                        (change)="usuarioSeleccionoDiaEjecucion(index)"
                        [(ngModel)]="cuen.periodoFacturacion.diaEjecucion">
                        <option [ngValue]="1">Domingo</option>
                        <option [ngValue]="2">Lunes</option>
                        <option [ngValue]="3">Martes</option>
                        <option [ngValue]="4">Miercoles</option>
                        <option [ngValue]="5">Jueves</option>
                        <option [ngValue]="6">Viernes</option>
                        <option [ngValue]="7">Sabado</option>
                      </select>
                    </div>
                  </div>
                </div>
                <!--ESPACIO EN BLANCO-->
                <div class="mb-3 col-1">
                </div>
                <div class="mb-3 col-2">
                  <input type="checkbox" class="form-check-input" id="exampleCheckCuenta"
                    formControlName="activaCuentaFormControl" [checked]="cuen.activo" />
                  <label class="form-check-label ms-2" for="exampleCheckCuenta">Activo</label>
                </div>
                <div class="mb-3 col-2">
                  <input type="checkbox" class="form-check-input" id="soloPADFormControl"
                    formControlName="soloPADFormControl" [value]="cuen.soloPAD"
                    [checked]="cuen.soloPAD"/>
                  <label for="soloPADFormControl" class="form-check-label ms-2">Solo PAD</label>
                </div>
                <!--NOTIFICACION-->
                <div class="mb-3 col-3" style="visibility: hidden;">
                  <input type="checkbox" class="form-check-input" id="exampleCheck3"
                    formControlName="notificacionFormControl" [checked]="cuen.notificaPorEmail" />
                  <label class="form-check-label" for="exampleCheck3">Notificación por mail</label>
                </div>
                <!--AGRUPACION REMITOS-->
                <div class="mb-3 col-4">
                  <div class="mb-3 row">
                    <div class="col-5">
                      <label for="agrupacionRemitosFormControl" class="float-end col-form-label">Agrupación de
                        remitos:</label>
                    </div>
                    <div class="col-7">
                      <select class="form-select" id="agrupacionRemitosFormControl" (change)="guardarAgrupacion(index)"
                        formControlName="agrupacionRemitosFormControl" 
                        [(ngModel)]="cuen.agrupacionRemitos">
                        <option *ngFor="let agrup of keysAgrupacion()" [ngValue]="obtenerValueAgrupacionRemitos(agrup)">
                          {{ agrup }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <!--DATOS FACTURACION - Lo ocultamos porque falta el backend
                <div class="mb-3 col-1"></div>
                <div class="mb-3 col-8">
                  <div class="mb-3 row">
                    <div class="col-12">
                      <label for="datosAdicionalesFacturacionFormControl" class="float-start col-form-label">Datos adicionales para facturación:</label>
                    </div>
                  </div>
          
                  <div class="mb-3 row">
                    <div class="col-12">
                      <textarea id="datosAdicionalesFacturacionFormControl" formControlName="datosAdicionalesFacturacionFormControl" type="text"
                        style="min-width: 100%" rows="4" mdbInput placeholder=""></textarea>
                    </div>
                  </div>

                  <div class="mb-3 row">
                    <div class="col-12" style="color: red;">
                      Atención: Los datos aqui escritos saldran impresos en la factura
                    </div>
                  </div>
                </div>-->

                <!--BOTONES-->
                <div class="row col-md-12" *ngIf="cuen.ModificacionPendiente">
                  <div class="col-md-8 .offset-md-2 alert alert-danger">
                    La cuenta posee cambios pendientes de aprobar y no puede ser modificado hasta que se aprueben o
                    rechazen dichos
                    cambios.
                  </div>
                </div>
                <div class="row col-md-12">
                  <div class="mb-3 col-5"></div>
                  <div class="mb-3 col-2">
                    <button class="btn btn-primary" (click)="guardarCuenta(index)"
                      [disabled]="(!usuarioModificaCampos() || clienteAMostrar.modificacionPendiente) && !esElectrica">Guardar
                      Cuenta</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <!--PANEL NUEVA CUENTA-->
  <div class="panel panel-default" *ngIf="usuarioModificaCampos() && !clienteAMostrar.modificacionPendiente">
    <div class="panel-heading bg-primary justify-content-center" style="display: flex;" (click)="nuevaCuenta()">
      <h6 class="panel-title">
        + Agregar nueva cuenta
      </h6>
    </div>
  </div>
</div>