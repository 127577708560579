  <div *ngIf="titulo" class="modal-header bg-light">
    <h4 class="modal-title">{{titulo}}</h4>
  </div>

  <div class="modal-body" [innerHTML]="mensaje">
  </div>

  <div class="modal-footer bg-light">
    
    <span class="border">
      <button type="button" class="btn btn-primary" (click)="close()">Ok</button>
    </span>
    <span class="border" *ngIf="webService">
      <button type="button" class="btn btn-secondary" (click)="abrirClienteContado()">Consumidor Final</button>
    </span>
    <span class="border" *ngIf="webService">
      <button type="button" class="btn btn-info" (click)="enviarMail()">Enviar datos por mail</button>
    </span>
  </div>