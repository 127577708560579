<div id="notificaciones-encabezado">
    <div id="titulo">
        Listado de notificaciones
    </div>    

    <button type="button" class="btn-close mt-1 float-end" aria-label="Close" (click)="activeModal.dismiss()">
        
    </button>

    <div id="botones">
        <button type="button" class="btn btn-sm me-2" [ngClass]="notificacionesVisualizadas == TipoNotificacionesVisualizadas.Todas ? 'btn-primary' : 'btn-secondary'" (click)="onUsuarioSeleccionoFiltrarNotificaciones(TipoNotificacionesVisualizadas.Todas)">Todas</button>
        <button type="button" class="btn btn-sm" [ngClass]="notificacionesVisualizadas == TipoNotificacionesVisualizadas.NoLeidas ? 'btn-primary' : 'btn-secondary'" (click)="onUsuarioSeleccionoFiltrarNotificaciones(TipoNotificacionesVisualizadas.NoLeidas)">No leidas</button>
    </div>
</div>

<div id="notificaciones-contenido">
    <loading-text-spinner *ngIf="this.obteniendoNotificaciones" message="Obteniendo notificaciones.." color="primary" class="ms-4"></loading-text-spinner>
        
    <div *ngIf="!this.obteniendoNotificaciones" 
        id="scroll-infinito"
        infinite-scroll
        [infiniteScrollDistance]="distance"
        [infiniteScrollThrottle]="throttle"
        [scrollWindow]="false"
        [infiniteScrollDisabled]="this.obteniendoNotificacionesScroll"
        (scrolled)="onScrollNotificaciones()">
        <div *ngFor="let n of notificaciones"  class="notificacion-item" (click)="usuarioSeleccionoNotificacion(n)">
            <div class="titulo">
                {{n.titulo}}<br/>
                <span class="fecha">Creada: {{n.fechaHora | date: "d'/'MM'/'y' 'HH:mm"}} hs.</span>
            </div>
            <div class="leido">
                <i *ngIf="!n.fechaHoraLectura" class="fa fa-eercast" style="color:#7cd994" aria-hidden="true"></i>  
            </div>                           

            <div style="clear: both;"></div>    
        </div>
        <loading-text-spinner *ngIf="this.obteniendoNotificacionesScroll" message="Cargando mas datos.." color="primary" class="ms-4"></loading-text-spinner>
    </div>    
</div>