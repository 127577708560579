import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { UsuarioService } from 'src/app/account/usuario.service';
import { ComprobanteService } from 'src/app/facturacion/comprobante.service';
import { Aeroplanta } from 'src/app/shared/models/despacho/Aeroplanta';
import { RemitoFox } from 'src/app/shared/models/despacho/RemitoFox';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { MessageService } from 'src/app/shell/message.service';
import { AerovalesService } from '../aerovales.service';

@Component({
  selector: 'app-modal-creacion-fox',
  templateUrl: './modal-creacion-fox.component.html',
  styleUrls: ['./modal-creacion-fox.component.css']
})
export class ModalCreacionFoxComponent implements OnInit {
  numeracionFox = new UntypedFormGroup({
    prefijoFormControl: new UntypedFormControl(null, [Validators.required]),
    numeroFormControl: new UntypedFormControl(null, [Validators.required]),
  });

  @Input() remitoFox: RemitoFox;
  guardando: boolean = false;
  obteniendoAeroplanta: boolean = false;

  fechaDelCai: NgbDateStruct;
  fechaActual: Date;
  fechaVencida: boolean = false;

  aeroplanta: Aeroplanta[] = [];
  aeroplantaCodigo: string;

  constructor(private aerovaleService: AerovalesService, public activeModal: NgbActiveModal, private comprobanteService: ComprobanteService,
    private messageService: MessageService, private usuarioService: UsuarioService, private spinner: NgxSpinnerService, private calendar: NgbCalendar) { }

  ngOnInit() {
    this.aeroplantaCodigo = this.usuarioService.getAeroplantaActual().codigoAeroplanta;
    this.obtenerAeroplanta();
  }

  obtenerAeroplanta() {
    this.obteniendoAeroplanta = true;
    this.spinner.show('spinnerLista');
    this.comprobanteService.getDatosAeroplanta(this.aeroplantaCodigo)
      .subscribe(result => {
        this.aeroplanta = result;
        this.verificarFecha();
        this.spinner.hide('spinnerLista');
        this.obteniendoAeroplanta = false;
      }, (err: ErrorModel) => {
        this.messageService.showErrorMessage('Hubo un error al obtener los datos de la aeroplanta.')
      });
  }

  verificarFecha() {
    let fecha: Date = new Date(this.aeroplanta[0].fechaVencimientoCAI);
    let fechaHoy = this.calendar.getToday();
    this.fechaActual = new Date(fechaHoy.year, fechaHoy.month - 1, fechaHoy.day);
    if (fecha < this.fechaActual) {
      this.fechaVencida = true;
    } else {
      this.fechaVencida = false;
    }
  }

  setearNumeracion() {
    this.guardando = true;
    if (this.numeracionFox.valid) {
      this.remitoFox.numeroFox = Number(this.numeroFormControl.value);
      this.remitoFox.prefijoFox = Number(this.prefijoFormControl.value);
      this.remitoFox.cai = this.aeroplanta[0].cai;
      this.comprobanteService.putNumeracionFox(this.remitoFox)
        .subscribe(result => {
          this.messageService.showSuccessMessage('La numeración se adjunto al remito correctamente.');
          this.guardando = false;
          this.activeModal.close(true);
        }, (err: ErrorModel) => {
          err.description
          this.messageService.showErrorMessage(err.description);
          this.guardando = false;
        });
    } else {
      this.messageService.showErrorMessage('Por favor complete todos los datos antes de continuar.');
      this.guardando = false;
    }

  }

  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46;
  }

  get prefijoFormControl() {
    return this.numeracionFox.get('prefijoFormControl');
  }
  get numeroFormControl() {
    return this.numeracionFox.get('numeroFormControl');
  }
}
