import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { saveAs } from 'file-saver';
import { ProductoService } from 'src/app/abm/producto/producto.service';
import { AerovaleDetalleComponent } from 'src/app/despacho/aerovale-detalle/aerovale-detalle.component';
import { AerovalesService } from 'src/app/despacho/aerovales.service';
import { ExportExcelServiceService } from 'src/app/shared/exportar-excel/export-excel-service.service';
import { CierreTurno } from 'src/app/shared/models/cierreTurno/CierreTurno';
import { ClienteCierre } from 'src/app/shared/models/cierreTurno/ClienteCierre';
import { ExportarAerovales } from 'src/app/shared/models/cierreTurno/exportarAerovales';
import { ReporteMovimiento } from 'src/app/shared/models/cierreTurno/ReporteMovimiento';
import { AerovaleDetalle } from 'src/app/shared/models/despacho/AerovaleDetalle';
import { StockService } from 'src/app/stock/stock.service';
import { MessageService } from 'src/app/shell/message.service';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';

@Component({
  selector: 'app-generar-informe-aerovales',
  templateUrl: './generar-informe-aerovales.component.html',
  styleUrls: ['./generar-informe-aerovales.component.css']
})
export class GenerarInformeAerovalesComponent implements OnInit {
  @Input() cierreTurno: CierreTurno;

  ordenamientoForm = new UntypedFormGroup({
    clienteAerovaleFormControl: new UntypedFormControl()
  });

  movimientosCierre: ReporteMovimiento[] = [];
  reporteMovimientosFiltrado: ReporteMovimiento[] = [];
  reporteMovimientosAnulados: ReporteMovimiento[] = [];
  reportesFiltrados: ReporteMovimiento[] = [];
  cargando: boolean = false;
  exportando: boolean = false;
  aerovalesId: string[] = [];
  aerovalesIdUnicos: string[] = [];
  clientes = [];
  contenedores = [];
  ultimoOrden: number = 0;

  arregloExport: ExportarAerovales[] = [];

  // ver aerovale
  aerovaleDetalle: AerovaleDetalle;
  obteniendoAerovale: boolean = false;
  aerovaleSeleccionado: number = null;

  constructor(public modal: NgbActiveModal,
    private stockService: StockService,
    private aerovaleService: AerovalesService,
    private spinner: NgxSpinnerService,
    private activeModal: NgbModal,
    private messageService: MessageService,
    private excelService: ExportExcelServiceService) { }


  ngOnInit() {
    this.movimientosCierre = [];
    this.reporteMovimientosFiltrado = [];
    this.reporteMovimientosAnulados = [];
    this.getReporteMovimiento();
  }

  getReporteMovimiento() {
    this.cargando = true;
    this.spinner.show('spinnerGrafico');
    this.stockService.getMovimientos(this.cierreTurno)
      .subscribe(result => {
        this.movimientosCierre = result;
        this.reporteMovimientosFiltrado = this.movimientosCierre.filter(a => a.comprobante != null && a.comprobante.remito != null && a.comprobante.remito.esAnulacion == false);
        this.reporteMovimientosAnulados = this.movimientosCierre.filter(a => a.comprobante != null && a.comprobante.remito != null && a.comprobante.remito.esAnulacion == true);
        for (let movimiento of this.reporteMovimientosFiltrado) {
          if (movimiento.comprobante.remito.aerovaleDTO != null) {
            movimiento.comprobante.remito.clienteCierre = JSON.parse(movimiento.comprobante.remito.aerovaleDTO);
          } else {
            movimiento.comprobante.remito.clienteCierre = new ClienteCierre();
            movimiento.comprobante.remito.clienteCierre.ClienteNombre = '-----';
            movimiento.comprobante.remito.clienteCierre.ClienteCUIT = '-----';
            movimiento.comprobante.remito.clienteCierre.Cuenta_SGC = '-----';
          }
        }
        for (let movimiento of this.reporteMovimientosAnulados) {
          if (movimiento.comprobante.remito.aerovaleDTO != null) {
            movimiento.comprobante.remito.clienteCierre = JSON.parse(movimiento.comprobante.remito.aerovaleDTO);
          } else {
            movimiento.comprobante.remito.clienteCierre = new ClienteCierre();
            movimiento.comprobante.remito.clienteCierre.ClienteNombre = '-----';
            movimiento.comprobante.remito.clienteCierre.ClienteCUIT = '-----';
            movimiento.comprobante.remito.clienteCierre.Cuenta_SGC = '-----';
          }
        }
        this.clienteAerovaleFormControl.setValue(0);
        this.ordenar(0);
        this.cargarClientes();
        this.cargarContenedores();
        this.filtrar();
        this.cargando = false;
        this.spinner.hide('spinnerGrafico');
      }, () => {
        this.cargando = false;
        this.spinner.hide('spinnerGrafico');
      });
  }

  cargarClientes(){
    for(let m of this.reporteMovimientosFiltrado){
      if(m.comprobante.remito.clienteCierre != null){
        if(!this.clientes.find(c => c.cierre.ClienteNombre == m.comprobante.remito.clienteCierre.ClienteNombre)){
          this.clientes.push({
            cierre: m.comprobante.remito.clienteCierre,
            seleccionado: true
          })
        }
      }
    }
  }

  cargarContenedores(){
    this.contenedores = [];
    for(let m of this.reporteMovimientosFiltrado){
      if(!this.contenedores.find(c => c.name == m.codigoContenedor + '-' + m.nombreContenedor)){
        this.contenedores.push({
          name: m.codigoContenedor + '-' + m.nombreContenedor,
          seleccionado: true
        })
      }
    }
  }

  revisarAnulados(mov: ReporteMovimiento): boolean {
    for (let anulado of this.reporteMovimientosAnulados) {
      if (anulado.comprobante.remito.aerovaleId == mov.comprobante.remito.aerovaleId) {
        return true;
      }
    }
    return false;
  }

  /** Visualizar comprobantes */
  verAerovale(id: string, i:number) {
    this.aerovaleDetalle = null;    
    this.obteniendoAerovale = true;    
    this.aerovaleSeleccionado=i;
    this.aerovaleService.getAerovalePorIdyTipo(id)
      .subscribe(result => {
        this.aerovaleDetalle = result;           
        this.aerovaleSeleccionado= null;  
        this.obteniendoAerovale = false;
        if(this.aerovaleDetalle != null) {
          this.mostrarAerovale();
        }}, (err: ErrorModel) => {
          this.messageService.showErrorMessage('Hubo un error al obtener el Aerovale' + err.description);   
          this.aerovaleSeleccionado=null;  
          this.obteniendoAerovale = false;  
        });
  }

  mostrarAerovale() {
    let modalRef = this.activeModal.open(AerovaleDetalleComponent, { size: "lg", centered: true })
    modalRef.componentInstance.aerovaleDetalle = this.aerovaleDetalle;  
  }

  ordenar(i: number) {
    this.ultimoOrden = i;
    if (i == 0) {
      this.reportesFiltrados.sort((a, b) => {
      let numAAerovale = a.comprobante.remito.aerovalePrefijo +"-"+ a.comprobante.remito.aerovaleNumero;
      let numBAerovale = b.comprobante.remito.aerovalePrefijo +"-"+ b.comprobante.remito.aerovaleNumero;
      if (numAAerovale < numBAerovale ) 
      { return 1 } 
      if (numAAerovale > numBAerovale)
      { return -1 } 
      else { return 0 }
      });
    } else {
      this.reportesFiltrados.sort((a, b) => { if (a.comprobante.remito.clienteCierre.ClienteNombre > b.comprobante.remito.clienteCierre.ClienteNombre) { return 1 } else { return -1 } });
    }
  }

  filtrar(){
    this.reportesFiltrados = this.reporteMovimientosFiltrado.filter(
      m => this.clientes.find(c => c.cierre.ClienteNombre == m.comprobante.remito.clienteCierre.ClienteNombre && c.seleccionado)
      && this.contenedores.find(c => c.name == m.codigoContenedor + '-' + m.nombreContenedor && c.seleccionado));
    this.ordenar(this.ultimoOrden);  
  }

  seleccionarTodosClientes(e){
    if(e.target.checked){
      for(let c of this.clientes){
        c.seleccionado = true;
      }
    } else {
      for(let c of this.clientes){
        c.seleccionado = false;
      }
    }
  }

  seleccionarTodosContenedores(e){
    if(e.target.checked){
      for(let c of this.contenedores){
        c.seleccionado = true;
      }
    } else {
      for(let c of this.contenedores){
        c.seleccionado = false;
      }
    }
  }

  exportarExcel() {
    let p: any = [];
    let num: number = 0;
    this.exportando = true;
    this.arregloExport = [];
    for (let i = 0; this.reportesFiltrados.length > i; i++) {  
      if(!this.revisarAnulados(this.reportesFiltrados[i]))
      {
        this.arregloExport.push(new ExportarAerovales());
        this.arregloExport[num].Aerovale = this.reportesFiltrados[i].comprobante.remito.aerovalePrefijo + '-' + this.reportesFiltrados[i].comprobante.remito.aerovaleNumero;
        this.arregloExport[num].Cliente = this.reportesFiltrados[i].comprobante.remito.clienteCierre.ClienteNombre + '-' + this.reportesFiltrados[i].comprobante.remito.clienteCierre.ClienteCUIT;
        this.arregloExport[num].Cuenta = this.reportesFiltrados[i].comprobante.remito.clienteCierre.Cuenta_SGC;
        this.arregloExport[num].Titulo = this.reportesFiltrados[i].titulo;
        this.arregloExport[num].Contenedor = this.reportesFiltrados[i].codigoContenedor + '-' + this.reportesFiltrados[i].nombreContenedor;
        this.arregloExport[num].Producto = this.reportesFiltrados[i].codigoProducto + '-' + this.reportesFiltrados[i].nombreProducto;
        this.arregloExport[num].Cantidad = this.reportesFiltrados[i].salidaNatural? this.reportesFiltrados[i].salidaNatural : this.reportesFiltrados[i].entradaNatural? this.reportesFiltrados[i].entradaNatural : 0;
        this.arregloExport[num].Cantidad15 = this.reportesFiltrados[i].salida15? this.reportesFiltrados[i].salida15 : this.reportesFiltrados[i].entrada15? this.reportesFiltrados[i].entrada15 : 0;
        this.arregloExport[num].CaudalObservado = this.reportesFiltrados[i].comprobante.remito.caudalObservado? this.reportesFiltrados[i].comprobante.remito.caudalObservado : 0;
        this.arregloExport[num].PresionDiferencial = this.reportesFiltrados[i].comprobante.remito.presionDiferencial? this.reportesFiltrados[i].comprobante.remito.presionDiferencial : 0;
        this.arregloExport[num].PresionPuntaManguera = this.reportesFiltrados[i].comprobante.remito.presionPuntaManguera? this.reportesFiltrados[i].comprobante.remito.presionPuntaManguera : 0;
        this.arregloExport[num].Anulado = 'No';
        num = num + 1;
      }
    }
    for (let j = 0; this.reporteMovimientosAnulados.length > j; j++) {
      if(this.reportesFiltrados.find(r => r.comprobante.remito.aerovaleId == this.reporteMovimientosAnulados[j].comprobante.remito.aerovaleId)){
        this.arregloExport.push(new ExportarAerovales());
        this.arregloExport[num].Aerovale = this.reporteMovimientosAnulados[j].comprobante.remito.aerovalePrefijo + '-' + this.reporteMovimientosAnulados[j].comprobante.remito.aerovaleNumero;
        this.arregloExport[num].Cliente = this.reporteMovimientosAnulados[j].comprobante.remito.clienteCierre.ClienteNombre + '-' + this.reporteMovimientosAnulados[j].comprobante.remito.clienteCierre.ClienteCUIT;
        this.arregloExport[num].Cuenta = this.reporteMovimientosAnulados[j].comprobante.remito.clienteCierre.Cuenta_SGC;
        this.arregloExport[num].Titulo = this.reporteMovimientosAnulados[j].titulo;
        this.arregloExport[num].Contenedor = this.reportesFiltrados[j].codigoContenedor + '-' + this.reportesFiltrados[j].nombreContenedor;
        this.arregloExport[num].Producto = this.reporteMovimientosAnulados[j].codigoProducto + '-' + this.reporteMovimientosAnulados[j].nombreProducto;
        this.arregloExport[num].Cantidad = this.reporteMovimientosAnulados[j].entradaNatural? this.reporteMovimientosAnulados[j].entradaNatural : this.reporteMovimientosAnulados[j].salidaNatural? this.reporteMovimientosAnulados[j].salidaNatural : 0;
        this.arregloExport[num].Cantidad15 = this.reporteMovimientosAnulados[j].entrada15? this.reporteMovimientosAnulados[j].entrada15 : this.reporteMovimientosAnulados[j].salida15? this.reporteMovimientosAnulados[j].salida15 : 0;
        this.arregloExport[num].CaudalObservado = this.reporteMovimientosAnulados[j].comprobante.remito.caudalObservado? this.reporteMovimientosAnulados[j].comprobante.remito.caudalObservado : 0;
        this.arregloExport[num].PresionDiferencial = this.reporteMovimientosAnulados[j].comprobante.remito.presionDiferencial? this.reporteMovimientosAnulados[j].comprobante.remito.presionDiferencial : 0;
        this.arregloExport[num].PresionPuntaManguera = this.reporteMovimientosAnulados[j].comprobante.remito.presionPuntaManguera? this.reporteMovimientosAnulados[j].comprobante.remito.presionPuntaManguera : 0;
        this.arregloExport[num].Anulado = 'Si';
        num = num + 1;
      }
      
    }
    //this.arregloExport.sort((a, b) => { if (a.Aerovale < b.Aerovale) { return 1 } else { return -1 } }); El orden queda definido en el filtro
    this.excelService.exportAsExcelFile(this.arregloExport, 'AerovalesDelCierreActual');
    this.exportando = false;
  }

  export()
  {
    this.aerovalesId = [];
    this.reportesFiltrados.forEach((value, index) => {
      this.aerovalesId.push(this.reportesFiltrados[index].comprobante.remito.aerovaleId);
    });

    this.aerovalesIdUnicos = [...new Set(this.aerovalesId)];
    this.aerovaleService.exportarAerovalesPorId(this.aerovalesIdUnicos)
      .subscribe((result: Blob) =>{
        const blob = new Blob([result], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'});
        saveAs(blob, "Aerovales.xlsx");
        this.exportando = false;
      },() =>{
        console.log("No se encontraron aerovales para descargar");
      });
  }

  get clienteAerovaleFormControl() {
    return this.ordenamientoForm.controls.clienteAerovaleFormControl;
  }

}