import { Injectable } from '@angular/core';
import { HandleError, HttpErrorHandler } from 'src/app/http-error-handler.service';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { Cliente } from 'src/app/shared/models/facturacion/Cliente';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { NotificacionesCliente } from 'src/app/shared/models/notificaciones/NotificacionCliente';
import { NotificacionesComprobantes } from 'src/app/shared/models/notificaciones/NotificacionesComprobantes';
import { Email } from 'src/app/shared/models/notificaciones/Email';
import { NotificacionSistemaEncabezadoDTO } from 'src/app/shared/models/notificaciones/NotificacionSistemaEncabezadoDTO';
import { NotificacionSistemaDTO } from 'src/app/shared/models/notificaciones/NotificacionSistemaDTO';
import { TipoNovedadEncabezadoDTO } from 'src/app/shared/models/notificaciones/TipoNovedadEncabezadoDTO';
import { HistorialNotificacionDTO } from 'src/app/shared/models/notificaciones/HistorialNotificacionDTO';
import { NotificacionUsuario } from 'src/app/shared/models/notificaciones/NotificacionUsuario';
import { NotificacionUsuarioEncabezado } from 'src/app/shared/models/notificaciones/NotificacionUsuarioEncabezado';
import { UsuarioService } from 'src/app/account/usuario.service';
import { Grupo } from 'src/app/shared/models/notificaciones/Grupo';
import { GrupoEncabezado } from 'src/app/shared/models/notificaciones/GrupoEncabezado';
import { TipoNovedadDTO } from 'src/app/shared/models/notificaciones/TipoNovedadDTO';
import { UsuarioDTO } from 'src/app/shared/models/notificaciones/UsuarioDTO';

export enum TipoNotificacionesVisualizadas{
  Todas,
  NoLeidas
}
@Injectable({
  providedIn: 'root'
})
export class NotificacionesService {

  private handleError: HandleError;
  private clientesUrl= environment.apiServer.clientesBaseUrl;
  clientes: Cliente[];
  lastUpadted = new Date();
  notificacionesUrl = environment.apiServer.notificacionesBaseUrl;

  constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler, private usuarioService: UsuarioService, private datepipe: DatePipe) {
    this.handleError = httpErrorHandler.createHandleError('Productos');
  }

  obtenerClientes(): Observable<Cliente[]> {
    return this.http.get<Cliente[]>(this.clientesUrl + '/ClientesV1')
      .pipe(
        tap(clientes => {
          this.clientes = clientes;
        },
          catchError(this.handleError('Obtener Clientes', new Cliente()))
        ));
  }

  obtenerNotificaciones(): Observable<NotificacionesCliente[]>
  {
    return this.http.get<NotificacionesCliente[]>(this.notificacionesUrl+"/NotificacionesV1")
  }

  crearNotificacion(notificacionCliente: NotificacionesCliente): Observable<NotificacionesCliente>
  {
    return this.http.post<NotificacionesCliente>(this.notificacionesUrl+"/NotificacionesV1/",notificacionCliente)
  }

  obtenerHistorialEnvios(tipoDocumento:number,numeroDocumento:string,fechaDesde:Date,fechaHasta:Date):Observable<Email[]>
  {
    return this.http.get<Email[]>(this.notificacionesUrl+"/NotificacionesV1/HistorialEnvios?"+"tipoDocumento="+tipoDocumento
    +"&numeroDocumento="+numeroDocumento
    , {
      params: {
        fechaDesde: fechaDesde.toISOString(),
        fechaHasta: fechaHasta.toISOString(),
        }
    });
  }

  /** Notificaciones Sistemas */

  enviarFactura(factura: NotificacionesComprobantes): Observable<NotificacionesComprobantes>
  { let url = "/NotificacionesV1/EnviarFactura?vieneLogicApp=false"; 
    (this.esElectrica) ? url=url+"&esElectrica=true" : null;
    return this.http.post<NotificacionesComprobantes>(this.notificacionesUrl + url, factura)
  }

  // GetNotificaciones(rol); es un PUT porque necesita body con list<Rol> => url: /api/NotificacionesSistemasV1 => return list<NotificacionSistemaEncabezadoDTO>
  getNotificacionesSistemasEncabezado(listaRoles: number[]): Observable<NotificacionSistemaEncabezadoDTO[]> {
    return this.http.put<NotificacionSistemaEncabezadoDTO[]>(this.notificacionesUrl+"/NotificacionesSistemasV1", listaRoles)
  }

  // GetNotificacion(Id); => url: /api/NotificacionesSistemasV1/{idNotificacion} => return NotificacionSistemaDTO
  getNotificacionesSistemasPorId(id: string): Observable<NotificacionSistemaDTO> {
    return this.http.get<NotificacionSistemaDTO>(this.notificacionesUrl +"/NotificacionesSistemasV1/" + id);
  }

  // GetTipoNovedad(rol); es un PUT porque necesita body con list<Rol> => url: /api/NotificacionesSistemasV1/TiposNovedades => return list<TipoNovedadDTO>
  getTipoNovedadPorRoles(listaRoles: number[]): Observable<TipoNovedadEncabezadoDTO[]> {
    return this.http.put<TipoNovedadEncabezadoDTO[]>(this.notificacionesUrl +"/NotificacionesSistemasV1/TiposNovedades", listaRoles);
  }
  
  // GetEtiquetasPorTipoNovedad(TipoNovedad); => url: /api/NotificacionesSistemasV1/Etiquetas => return list<string>
  getEtiquetasPorTipoNovedad(tipoNovedadId: string): Observable<string[]> {
    return this.http.get<string[]>(this.notificacionesUrl +"/NotificacionesSistemasV1/Etiquetas?tipoNovedadId=" + tipoNovedadId);
  }

  // Post y put de notificaciones => url post: /api/NotificacionesSistemasV1
  crearNotificacionSistema(notificacionSistema: NotificacionSistemaDTO): Observable<NotificacionSistemaDTO> {
    return this.http.post<NotificacionSistemaDTO>(this.notificacionesUrl+"/NotificacionesSistemasV1/",notificacionSistema)
  }
  
  //  => url put: /api/NotificacionesSistemasV1/{idNotificacion} => recibir y retornar NotificacionSistemaDTO
  modificarNotificacionSistema(id:string, notificacionSistema: NotificacionSistemaDTO): Observable<NotificacionSistemaDTO> {
    return this.http.put<NotificacionSistemaDTO>(this.notificacionesUrl+"/NotificacionesSistemasV1/" + id, notificacionSistema)
  }
  
  // GetHistorialEnvio(idNotificacion) => url get: /api/NotificacionesSistemasV1/Historial/{idNotificacion} => return HistorialNotificacionDTO
  getHistorialEnvio(id:string): Observable<HistorialNotificacionDTO[]> {
    return this.http.get<HistorialNotificacionDTO[]>(this.notificacionesUrl+"/NotificacionesSistemasV1/Historial/" + id)
  }

  // PROMISES
  getNotificacionesSistemasPorIdPromise(id: string): Promise<NotificacionSistemaDTO> {
    return this.http.get<NotificacionSistemaDTO>(this.notificacionesUrl +"/NotificacionesSistemasV1/" + id).toPromise();
  }
  
  getTipoNovedadPorRolesPromise(listaRoles: number[]): Promise<TipoNovedadEncabezadoDTO[]> {
    return this.http.put<TipoNovedadEncabezadoDTO[]>(this.notificacionesUrl +"/NotificacionesSistemasV1/TiposNovedades", listaRoles).toPromise();
  }
  
  //https://notificaciones-dev.aeroplantas.com.ar/api/NotificacionesSistemasV1/TipoNovedad?tipoNovedadId=43b908c2-f338-4a85-934e-175d1bda3335
  getDatosPorTipoNovedadPromise(tipoNovedadId: string): Promise<TipoNovedadDTO> {
    return this.http.get<TipoNovedadDTO>(this.notificacionesUrl +"/NotificacionesSistemasV1/TipoNovedad?tipoNovedadId=" + tipoNovedadId).toPromise();
  }

  getNotificacionesUsuario(page: number, pageSize: number, tipoNotificacionesMostradas: TipoNotificacionesVisualizadas): Observable<NotificacionUsuarioEncabezado[]>{
    let soloNoLeidas = (tipoNotificacionesMostradas == TipoNotificacionesVisualizadas.NoLeidas);
    let usuarioId = this.usuarioService.getIdUsuario();
    return this.http.get<NotificacionUsuarioEncabezado[]>(this.notificacionesUrl+"/NotificacionesUsuarioV1?usuarioId="+usuarioId+"&page="+page+"&pageSize="+pageSize+"&noLeidas=" + soloNoLeidas);
  }

  getNotificacionPorId(idNotificacion): Observable<NotificacionUsuario>{ 
    return this.http.get<NotificacionUsuario>(this.notificacionesUrl+"/NotificacionesUsuarioV1/"+ idNotificacion);
  }

  getCantidadNotificacionesNoLeidas(): Observable<number>{
    let usuarioId = this.usuarioService.getIdUsuario();
    return this.http.get<number>(this.notificacionesUrl+"/NotificacionesUsuarioV1/cantidad?usuarioId="+usuarioId+"&noLeidas=" + false);    
  }

  getGrupos(): Observable<GrupoEncabezado[]>{
    return this.http.get<GrupoEncabezado[]>(this.notificacionesUrl+"/GruposUsuariosV1");
  }
  
  getGruposPromise(): Promise<GrupoEncabezado[]>{
    return this.http.get<GrupoEncabezado[]>(this.notificacionesUrl+"/GruposUsuariosV1").toPromise();
  }

  getGrupoPorId(id):Observable<Grupo>{
    return this.http.get<Grupo>(this.notificacionesUrl + "/GruposUsuariosV1/" + id);
  }

  crearGrupo(grupo: Grupo){
    return this.http.post<Grupo>(this.notificacionesUrl + "/GruposUsuariosV1/", grupo);
  }

  modificarGrupo(grupo: Grupo){
    return this.http.put<Grupo>(this.notificacionesUrl + "/GruposUsuariosV1/" + grupo.id, grupo);
  }
  
  //NotificacionesV1/PDFsRancho?codigoRancho={codigoRancho} con el usuariodto de body
  descargarPdfAerovales(codigoRancho:string, usuario:UsuarioDTO){
    return this.http.put(this.notificacionesUrl + `/NotificacionesV1/PDFsRancho?codigoRancho=${codigoRancho}`, usuario, {responseType: 'text'});    
  }  

  get esElectrica() {
    return environment.electrica
  }
}
