<div id="contenedor">
    <div id="spinner" *ngIf="this.obteniendoDatos" class="ms-4" style="margin-bottom: 2rem; margin-top: 2rem">
        <loading-text-spinner message="Obteniendo detalle notificacion..." color="primary"></loading-text-spinner>
    </div>    

    <div *ngIf="!this.obteniendoDatos">
        <div id="notificacion-titulo">
            <div id="titulo">{{this.notificacion.titulo}}</div>
            <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()">
                
            </button>
        </div>
        <div id="notificacion-detalle">
            <div id="detalle-fechas">
                <div>
                    Creada: {{notificacion.fechaHora | date: "d'/'MM'/'y' 'HH:mm"}} hs. | 
                    Entregada: {{notificacion.fechaHoraVisualizacion | date: "d'/'MM'/'y' 'HH:mm"}} hs.                
                </div>
                <div *ngIf="notificacion.fechaHoraLectura">
                    Leida: {{notificacion.fechaHoraLectura | date: "d'/'MM'/'y' 'HH:mm"}} hs.
                </div>
            </div>
            {{notificacion.mensaje}}
        </div>
    </div>
    <div style="clear: both;"></div>
</div>