import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { TipoSolapaRancho } from 'src/app/shared/enums/TipoSolapaRancho';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';

@Component({
  selector: 'app-rancho-index',
  templateUrl: './rancho-index.component.html',
  styleUrls: ['./rancho-index.component.css']
})
export class RanchoIndexComponent implements OnInit {

  TipoUsuario = TipoUsuario;
  TipoSolapaRancho = TipoSolapaRancho;

  constructor(public authService: AuthService) { }

  ngOnInit() {
  }


  esComercial(): boolean{
   return this.authService.hasRole(TipoUsuario.COMERCIAL);
  }

  esJefeAeroplanta(): boolean{
    return this.authService.hasRole(TipoUsuario.JEFEDEAEROPLANTA);
   }

}
