<div class="card">
    <div *ngIf="!this.reporteParteDiario" class="card-header">
        <app-cierre-nav-bar
            *appRefreshPage="recargarNavBar"
        ></app-cierre-nav-bar>
    </div>
  
    <div *ngIf="!this.cierreFinalizado" class="card-body">
        
        <div class="row">
            <div class="mb-3 col-md-12">
                <div class="row">
                    <div class="col-md-2">
                        <img id="logo-ypf" src="/assets/images/logoypf.png" />
                    </div>
                    <div class="col-md-8 text-center">
                        <h3>Aeroplanta {{ this.aeroplanta?.nombre }}</h3>
                    </div>

                    <div class="col-md-2">
                        Fecha Reporte: <b> {{ this.fechaHoraReporte | date: "d'/'MM'/'y'" }} </b>
                        <button *ngIf="!this.reporteParteDiario" class="btn btn-primary" 
                            [disabled]="obteniendoDetalle"
                            (click)="cambiarFechaReporte()">
                            <i ngbTooltip="Cambiar la fecha del reporte" class="fa fa-pencil" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="spinner-container" *ngIf="this.obteniendoDetalle">
            <ngx-spinner name="spinnerParteDiario" bdOpacity=0.3bd
                Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
                <p style="color: white">{{message}}</p>
            </ngx-spinner>
        </div>

        <ng-container *ngIf="!this.obteniendoDetalle && this.parteDiario!=undefined">
            <ng-container *ngIf="this.parteDiario?.detallesParteDiario.length > 0">
                <!-- primera linea -->
                <div id="columnas" class="row col-md-12">
                    <div class="mb-3 col-md-6">                
                        <table id="ancho" style="font-size: 12px;" class="table text-center">

                            <tr class="titulo bordes">
                                <th class="bordes" colspan="3">Información para ruteo</th> 
                            </tr>

                            <tr class="titulo bordes">
                                <th class="bordes" rowspan="2">Producto</th>            
                                <th class="bordes" rowspan="2">Vacío (lt)</th>  
                                <th class="bordes" colspan="1">Pedido</th>                        
                            </tr>

                            <tr class="titulo bordes">
                                <th class="bordes">Volumen Solicitado (lt)</th>                        
                            </tr>

                            <tbody>
                                <tr class="bordes" *ngFor="let d of this.parteDiario.detallesParteDiario">
                                    <td class="bordes"
                                        [ngStyle]="{'background-color':d.colorProducto, 'color': '#ffff', 'font-weight': 'bold'}">
                                        {{d.nombreProducto}}</td>
                                    <td class="bordes">{{d.vacio | number: '0.0-0'}}</td>
                                    <td class="bordes">{{d.cantidadEnPedidos | number: '0.0-0'}}</td>
                                </tr>
                            </tbody>
                        </table>                 
                    </div>

                    <div class="mb-3 col-md-6" [ngStyle]="{}">
                        <table id="ancho" style="font-size: 12px;" class="table text-center">

                            <tr class="titulo bordes">
                                <th class="bordes" colspan="4">Proyección al {{ this.fechaProyeccion | date: "d'/'MM'/'y'" }}</th> 
                            </tr>

                            <tr class="titulo bordes">
                                <th class="bordes" rowspan="2">Producto</th>            
                                <th class="bordes" rowspan="2">Stock Operativo (lt)</th>  
                                <th class="bordes" rowspan="2">Salida estimada (lt)</th>                        
                                <th class="bordes" rowspan="2">Stock (Dias)</th>                        
                            </tr>

                            <tbody>
                                <tr class="bordes" *ngFor="let d of this.parteDiario.detallesParteDiario; let i=index">
                                    <td class="bordes"
                                        [ngStyle]="{'background-color':d.colorProducto, 'color': '#ffff', 'font-weight': 'bold'}">
                                        {{d.nombreProducto}}</td>
                                    <td class="bordes">{{d.stockOperativo | number: '0.0-0'}}</td>
                                    <td class="bordes">
                                        <ng-container *ngIf="!reporteParteDiario">
                                            <input class="form-control" appOnlyNumber
                                                (keyup)="changeValue(i, 'salidaEstimada', $event)" 
                                                [value]="d.salidaEstimada">
                                            <div class="alert alert-danger" *ngIf="isVacio(i, 'salidaEstimada')">
                                                Requerido
                                            </div>
                                        </ng-container>                                        
                                        <ng-container *ngIf="reporteParteDiario">
                                            {{d.salidaEstimada | number: '0.0-0'}}
                                        </ng-container> 
                                    </td>                                          
                                    <td class="bordes">{{d.stockEnDias==0 ? '-' :  d.stockEnDias | number: '1.2-2'}}</td>                                        
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <!-- segunda linea -->
                <div class="row col-md-12">
                    <div class="mb-3 col-md-12">
                        <div class="bordes">
                            <label style="margin-bottom: 0;" class="col-md-12 titulo"><b>Novedades operativas</b></label> 
                            <label *ngIf="!reporteParteDiario" style="margin-bottom: 0;" class="col-md-12 titulo">(Demoras, Reclamos, Reprogramaciones, Cancelaciones, Notas Recibidas, Inconvenientes en facturación, NOTAM activo, Visitas Recibidas, etc.)</label> 
                            <ng-container *ngIf="!reporteParteDiario">
                                <textarea 
                                    [formControl]="novedadesFormControl" 
                                    name="novedades" id="novedades" 
                                    style="width: 100%; border: 0;" 
                                    rows="3" placeholder="Observación...">
                                </textarea>
                            </ng-container>
                            <ng-container *ngIf="reporteParteDiario">
                                {{ this.parteDiario.novedadesOperativas }}
                            </ng-container> 
                        </div>                
                    </div>
                </div>

                <!-- tercera linea -->
                <div [class.block]="reporteParteDiario" id="columnas" class="row col-md-12">
                    <div class="mb-3 col-md-6">                
                        <table [class.ancho]="reporteParteDiario" id="ancho" style="font-size: 12px;" class="table text-center">

                            <tr class="titulo bordes">
                                <th class="bordes" colspan="8">Operaciones del día {{ this.fechaHoraReporte | date: "d'/'MM'/'y'" }}</th> 
                            </tr>

                            <tr class="titulo bordes">
                                <th class="bordes">Producto</th>      
                                <th class="bordes">Operaciones intoplane</th>
                                <th class="bordes">Remitos Cargamento</th>                                
                                <th class="bordes">Operaciones granel</th>
                                <th class="bordes">Consumo interno</th>
                                <th class="bordes">Total operaciones</th>
                                <th class="bordes">Volumen despachado (lt)</th>                    
                                <th class="bordes">Volumen ingresado (lt)</th>                    
                            </tr>

                            <tbody>
                                <tr class="bordes" *ngFor="let d of this.parteDiario.detallesParteDiario">
                                    <td class="bordes"
                                        [ngStyle]="{'background-color':d.colorProducto, 'color': '#ffff', 'font-weight': 'bold'}">
                                        {{d.nombreProducto}}</td>
                                    <td class="bordes">{{d.cantidadRemitoNoGranel | number: '0.0-0'}}</td>
                                    <td class="bordes">{{d.cantidadRemitoSalida | number: '0.0-0'}}</td>
                                    <td class="bordes">{{d.cantidadRemitoGranel | number: '0.0-0'}}</td>
                                    <td class="bordes">{{d.cantidadRemitoConsumoInterno | number: '0.0-0'}}</td>
                                    <td class="bordes">{{d.cantidadRemitoNoGranel + d.cantidadRemitoSalida + d.cantidadRemitoGranel + d.cantidadRemitoConsumoInterno | number: '0.0-0'}}</td>
                                    <td class="bordes">{{d.volumenDespachado | number: '0.0-0'}}</td>
                                    <td class="bordes">{{d.volumenIngresado | number: '0.0-0'}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div [class.block]="reporteParteDiario" class="mb-3 col-md-6">
                        <table [class.ancho]="reporteParteDiario" id="ancho" style="font-size: 12px;" class="table text-center">
                            <tr class="titulo bordes">
                                <th class="bordes" colspan="6">Resumen de novedades</th> 
                            </tr>

                            <tr class="titulo bordes">
                                <th class="bordes" rowspan="2">Producto</th>            
                                <th class="bordes" rowspan="2">UUAA operativas</th>  
                                <th class="bordes" rowspan="2">UUAA F/S Correctivo/Preventivo</th>
                                <th class="bordes" rowspan="2">UUAA F/S por reparación mayor</th>
                                <th class="bordes" colspan="2">Demoras/Incidencias</th> 
                            </tr>

                            <tr class="titulo bordes">
                                <th class="bordes">Por problemas propios</th>
                                <th class="bordes">Por problemas de las Líneas Aéreas</th>
                            </tr>

                            <tbody>
                                <tr class="bordes" *ngFor="let d of this.parteDiario.detallesParteDiario; let i=index">
                                    <td class="bordes"
                                        [ngStyle]="{'background-color':d.colorProducto, 'color': '#ffff', 'font-weight': 'bold'}">
                                        {{d.nombreProducto}}</td>
                                    <td class="bordes">{{ d.cantAbastecedorasEnServicio }}</td>
                                    <td class="bordes">{{ d.cantAbastecedorasFS }}</td>
                                    <td class="bordes">{{ d.cantAbastecedorasReparacion }}</td>
                                    <td class="bordes">
                                        <ng-container *ngIf="!reporteParteDiario">
                                            <input class="form-control" appOnlyNumber (keyup)="changeValue(i, 'demorasProblemasPropios', $event)" 
                                                [value]="d.demorasProblemasPropios">
                                            <div class="alert alert-danger" *ngIf="isVacio(i, 'demorasProblemasPropios')">
                                                Requerido
                                            </div>
                                            <!--{{d.demorasProblemasPropios | number: '0.0-0'}}-->
                                        </ng-container>                                        
                                        <ng-container *ngIf="reporteParteDiario">
                                            {{ d.demorasProblemasPropios }}
                                        </ng-container> 
                                    </td>
                                    <td class="bordes">
                                        <ng-container *ngIf="!reporteParteDiario">
                                            <input class="form-control" appOnlyNumber (keyup)="changeValue(i, 'demorasLineasAereas', $event)" 
                                                [value]="d.demorasLineasAereas">
                                            <div class="alert alert-danger" *ngIf="isVacio(i, 'demorasLineasAereas')">
                                                Requerido
                                            </div>    
                                            <!--{{d.demorasLineasAereas | number: '0.0-0'}}-->
                                        </ng-container>                                        
                                        <ng-container *ngIf="reporteParteDiario">
                                            {{ d.demorasLineasAereas }}
                                        </ng-container> 
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </ng-container>
            <ngb-alert class="alert-warning" *ngIf="this.parteDiario && this.parteDiario?.detallesParteDiario.length == 0">
                No hay detalle de productos configurados para esta aeroplanta.
            </ngb-alert>
            
            <!-- cuarta linea -->
            <div [class.block]="reporteParteDiario" id="columnas" class="row col-md-12">
                <div class="mb-3 col-md-6">                
                    <table [class.ancho]="reporteParteDiario" id="ancho" style="font-size: 12px;" class="table text-center">
                                    
                        <tr class="titulo bordes">
                            <th class="bordes" colspan="8">Tanques</th> 
                        </tr>

                        <tr class="titulo bordes">
                            <th class="bordes">TK</th>      
                            <th class="bordes">Producto</th>
                            <th class="bordes">Cap. (lt)</th>
                            <th class="bordes">Estado</th>
                            <th class="bordes">Telemedición</th>
                            <th class="bordes">Existencia (lt)</th>
                            <th class="bordes">Fecha cambio de estado</th>
                            <th class="bordes">Motivo</th>
                        </tr>

                        <tbody>
                            <tr class="bordes" *ngFor="let t of this.parteDiario.estadosTanques; let i=index">
                                <td class="bordes"
                                    [ngStyle]="{'background-color':t.colorProducto, 'color': '#ffff', 'font-weight': 'bold'}">
                                    {{t.codigoContenedor}}</td>
                                <td class="bordes">{{t.nombreProducto}}</td>
                                <td class="bordes">{{t.capacidad | number: '0.0-0'}}</td>
                                <td class="bordes">
                                    <loading-text-spinner *ngIf="servicioSeleccionado==i && editandoTanque" message="Guardando cambios..."
                                        color="primary">
                                    </loading-text-spinner>
                                    <ng-container *ngIf="!reporteParteDiario && (servicioSeleccionado!=i && editandoTanque || !editandoTanque)">
                                        <select type="text" class="form-select" id="estadoServicio"
                                            style="font-size: 12px;"
                                            [disabled]="servicioSeleccionado!=i && editandoTanque "
                                            [ngClass]="{'verde':t.estadoServicio==EstadoTanqueUnico.EnServicio, 'rojo':t.estadoServicio==EstadoTanqueUnico.FueraServicio}"
                                            (change)="cambiarEstadoServicioTanques(t, i)" [(ngModel)]="t.estadoServicio" >
                                            <option id="estadoServicio" *ngFor="let estado of keysEstadoTanqueUnico()" [ngValue]="EstadoTanqueUnico[estado]">
                                                {{ estado }}
                                            </option>
                                        </select>
                                    </ng-container> 
                                    <ng-container *ngIf="reporteParteDiario">
                                        {{ EstadoTanqueUnico[t.estadoServicio] }}
                                    </ng-container>   
                                </td>
                                <td class="bordes">
                                    <loading-text-spinner *ngIf="telemedicionSeleccionada==i && editandoTanque" message="Guardando cambios..."
                                        color="primary">
                                    </loading-text-spinner>
                                    <ng-container *ngIf="!reporteParteDiario && (telemedicionSeleccionada!=i && editandoTanque || !editandoTanque)">
                                        <select type="text" class="form-select" id="estadoTelemedicion"
                                            style="font-size: 12px;"
                                            [disabled]="telemedicionSeleccionada!=i && editandoTanque || this.reporteParteDiario"
                                            [ngClass]="{'verde':t.estadoTelemedicion==EstadoTelemedicion.EnServicio, 'rojo':t.estadoTelemedicion==EstadoTelemedicion.FueraServicio}"
                                            (change)="cambiarEstadoTelemedicionTanques(t, i)" [(ngModel)]="t.estadoTelemedicion" >
                                            <option id="estadoTelemedicion" *ngFor="let estado of keysEstadoTelemedicion()" [ngValue]="EstadoTelemedicion[estado]">
                                                {{ estado }}
                                            </option>
                                        </select>
                                    </ng-container>
                                    <ng-container *ngIf="reporteParteDiario">
                                        {{ EstadoTelemedicion[t.estadoTelemedicion] }}
                                    </ng-container>  
                                </td>
                                <td class="bordes">{{t.existenciaAlCierre | number: '0.0-0'}}</td>
                                <td class="bordes">{{t.fechaHoraFS | date: "d'/'MM'/'y' 'hh:mm a"}}</td>
                                <td class="bordes">
                                    <ng-container *ngIf="t.observaciones != '' ">
                                        <button
                                            class="btn btn-sm"
                                            [popoverTitle]="titulo"
                                            [ngbPopover]="contenido">
                                            <i ngbTooltip="Ver motivos" class="fa fa-eye" aria-hidden="true"></i>    
                                        </button>
                                    
                                        <ng-template #titulo>Motivo:</ng-template>
                                        <ng-template #contenido>{{ t.observaciones }}</ng-template>
                                    </ng-container>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div [class.block]="reporteParteDiario" class="mb-3 col-md-6">                
                    <table [class.ancho]="reporteParteDiario" id="ancho" style="font-size: 12px;" class="table text-center">
                                    
                        <tr class="titulo bordes">
                            <th class="bordes" colspan="6">Unidades abastecedoras/surtidores</th> 
                        </tr>

                        <tr class="titulo bordes">
                            <th class="bordes">Equipo</th>      
                            <th class="bordes">Producto</th>
                            <th class="bordes">Cap. (lt)</th>
                            <th class="bordes">Estado</th>
                            <th class="bordes">Fecha cambio de estado</th>
                            <th class="bordes">Motivo</th>                        
                        </tr>

                        <tbody>
                            <tr class="bordes" *ngFor="let e of this.parteDiario.estadosExpendedoras; let i=index">
                                <td class="bordes"
                                    [ngStyle]="{'background-color':e.colorProducto, 'color': '#ffff', 'font-weight': 'bold'}">
                                    {{e.codigoExpendedora}}</td>
                                <td class="bordes">{{e.nombreProducto}}</td>
                                <td class="bordes">{{e.capacidad | number: '0.0-0'}}</td>
                                <td class="bordes">
                                    <loading-text-spinner *ngIf="expendedoraSeleccionada==i && editandoExpendedora" message="Guardando cambios..."
                                        color="primary">
                                    </loading-text-spinner>
                                    <ng-container *ngIf="!reporteParteDiario && (expendedoraSeleccionada!=i && editandoExpendedora || !editandoExpendedora)">
                                        <select type="text" class="form-select" id="estadoServicio"
                                            style="font-size: 12px;"
                                            [disabled]="expendedoraSeleccionada!=i && editandoExpendedora || this.reporteParteDiario"
                                            [ngClass]="{'verde':e.estadoServicio==EstadoServAbastecedora.EnServicio, 'rojo':e.estadoServicio==EstadoServAbastecedora.FueraServicio}"
                                            (change)="cambiarEstadoServicioExpendedoras(e, i)" [(ngModel)]="e.estadoServicio" >
                                            <option id="estadoServicio" *ngFor="let estado of keysEstadoServAbastecedora()" [ngValue]="EstadoServAbastecedora[estado]">
                                                {{ estado }}
                                            </option>
                                        </select>
                                    </ng-container>
                                    <ng-container *ngIf="reporteParteDiario">
                                        {{ EstadoServAbastecedora[e.estadoServicio] }}
                                    </ng-container> 
                                </td>
                                <td class="bordes">{{e.fechaHoraFS | date: "d'/'MM'/'y' 'hh:mm a"}}</td>
                                <td class="bordes">
                                    <ng-container *ngIf="e.observaciones != '' ">
                                        <button
                                            class="btn btn-sm"
                                            [popoverTitle]="titulo"
                                            [ngbPopover]="contenido">
                                            <i ngbTooltip="Ver motivos" class="fa fa-eye" aria-hidden="true"></i>    
                                        </button>
                                    
                                        <ng-template #titulo>Motivo:</ng-template>
                                        <ng-template #contenido>{{ e.observaciones }}</ng-template>
                                    </ng-container>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <!-- quinta linea -->
            <ng-container *ngIf="this.parteDiario?.equiposFijos.length > 0">                
                <div class="row col-md-12">
                    <div class="mb-3 col-md-12">                
                        <table class="table text-center">
                                        
                            <tr class="titulo bordes">
                                <th class="bordes" colspan="4">Equipos Fijos de Planta</th> 
                            </tr>

                            <tr class="titulo bordes">
                                <th class="bordes">Equipo</th>                        
                                <th class="bordes">Estado</th>
                                <th class="bordes">Fecha cambio de estado</th>
                                <th class="bordes">Motivo</th>
                            </tr>

                            <tbody>
                                <tr class="bordes" *ngFor="let ef of this.parteDiario.equiposFijos; let i=index">
                                    <td class="bordes">{{ef.nombre}}</td>
                                    <td class="bordes">
                                        <loading-text-spinner *ngIf="equipoSeleccionado==i && editandoEquipo" message="Guardando cambios..."
                                            color="primary">
                                        </loading-text-spinner>
                                        <ng-container *ngIf="!reporteParteDiario && (equipoSeleccionado!=i && editandoEquipo || !editandoEquipo)">
                                            <select type="text" class="form-select" id="estadoEquipo"
                                                [disabled]="equipoSeleccionado!=i && editandoEquipo || this.reporteParteDiario"
                                                [ngClass]="{'verde':ef.estado==estadoEquipo.EnServicio, 'rojo':ef.estado==estadoEquipo.FueraDeServicio}"
                                                (change)="cambiarEstadoEquipos(ef.idStock, ef.estado, i)" [(ngModel)]="ef.estado" >
                                                <option id="estadoEquipo" *ngFor="let estado of keysEstadoEquipo()" [ngValue]="estadoEquipo[estado]">
                                                    {{ estado }}
                                                </option>
                                            </select>
                                        </ng-container>
                                        <ng-container *ngIf="reporteParteDiario">
                                            {{ estadoEquipo[ef.estado] }}
                                        </ng-container> 
                                    </td>
                                    <td class="bordes">{{ef.fechaHoraFS | date: "d'/'MM'/'y' 'hh:mm a"}}</td>
                                    <td class="bordes">
                                        <ng-container *ngIf="ef.observaciones != '' ">
                                            <button
                                                class="btn btn-sm"
                                                [popoverTitle]="titulo"
                                                [ngbPopover]="contenido">
                                                <i ngbTooltip="Ver motivos" class="fa fa-eye" aria-hidden="true"></i>    
                                            </button>
                                        
                                            <ng-template #titulo>Motivo:</ng-template>
                                            <ng-template #contenido>{{ ef.observaciones }}</ng-template>
                                        </ng-container>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </ng-container>
            <ngb-alert class="alert-warning" *ngIf="this.parteDiario?.equiposFijos.length == 0 ">
                No hay equipos fijos configurados para esta aeroplanta.
            </ngb-alert>

            <!-- sexta linea -->
            <ng-container *ngIf="this.parteDiario?.sistemasSeguridad.length > 0">
                <div class="row col-md-12">
                    <div class="mb-3 col-md-12">                
                        <table class="table text-center">
                                        
                            <tr class="titulo bordes">
                                <th class="bordes" colspan="5">Sistema de protección de seguridad (SPS)</th> 
                            </tr>

                            <tr class="titulo bordes">
                                <th class="bordes">Equipo</th>                        
                                <th class="bordes">Estado</th>
                                <th class="bordes">Fecha cambio de estado</th>
                                <th class="bordes">Autorizador</th>
                                <th class="bordes">Motivo</th>
                            </tr>

                            <tbody>
                                <tr class="bordes" *ngFor="let ss of this.parteDiario.sistemasSeguridad; let i=index">
                                    <td class="bordes">{{ss.nombre}}</td>
                                    <td class="bordes">
                                        <loading-text-spinner *ngIf="sistemaSeleccionado==i && editandoSistema" message="Guardando cambios..."
                                            color="primary">
                                        </loading-text-spinner> 
                                        <ng-container *ngIf="!reporteParteDiario && (sistemaSeleccionado!=i && editandoSistema || !editandoSistema)">
                                            <select type="text" class="form-select" id="estadoSistemaSeg"
                                                [disabled]="sistemaSeleccionado!=i && editandoSistema || this.reporteParteDiario"
                                                [ngClass]="{'verde':ss.estado==estadoSistemaSeg.EnServicio, 'rojo':ss.estado==estadoSistemaSeg.FueraDeServicio}"
                                                (change)="cambiarEstadoSistemas(ss.idStock, ss.estado, i)" [(ngModel)]="ss.estado" >
                                                <option id="estadoSistemaSeg" *ngFor="let estado of keysEstadoSistema()" [ngValue]="estadoSistemaSeg[estado]">
                                                    {{ estado }}
                                                </option>
                                            </select>
                                        </ng-container>
                                        <ng-container *ngIf="reporteParteDiario">
                                            {{ estadoSistemaSeg[ss.estado] }}
                                        </ng-container> 
                                    </td>
                                    <td class="bordes">{{ss.fechaHoraFS | date: "d'/'MM'/'y' 'hh:mm a"}}</td>
                                    <td class="bordes">{{ss.autorizoFS}}</td>
                                    <td class="bordes">
                                        <ng-container *ngIf="ss.observaciones != '' ">
                                            <button
                                                class="btn btn-sm"
                                                [popoverTitle]="titulo"
                                                [ngbPopover]="contenido">
                                                <i ngbTooltip="Ver motivos" class="fa fa-eye" aria-hidden="true"></i>    
                                            </button>
                                        
                                            <ng-template #titulo>Motivo:</ng-template>
                                            <ng-template #contenido>{{ ss.observaciones }}</ng-template>
                                        </ng-container>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </ng-container>
            <ngb-alert class="alert-warning" *ngIf="this.parteDiario?.equiposFijos.length == 0 ">
                No hay sistemas de seguridad configurados para esta aeroplanta.
            </ngb-alert>

            <!-- septima linea -->
            <div class="row col-md-12">
                <div class="mb-3 col-md-12">                
                    <table class="table text-center">
                        <tr class="titulo bordes">
                            <th class="bordes"></th>
                            <th class="bordes">Detalle</th>
                            <th class="bordes">Número de Contacto</th>
                            <th class="bordes">Correo Electrónico</th>                        
                        </tr>
                        <tbody>
                            <tr>
                                <td class="bordes">Operador Responsable</td>
                                <td class="bordes">{{this.cierreTurno?.responsable}}</td>
                                <td class="bordes"></td>
                                <td class="bordes"></td>
                            </tr>                        
                            <tr>
                                <td class="bordes">Firma Operadora</td>
                                <td class="bordes">{{this.parteDiario.firmaOperadora?.nombre}}</td>
                                <td class="bordes">{{this.parteDiario.firmaOperadora?.numero}}</td>
                                <td class="bordes">{{this.parteDiario.firmaOperadora?.email}}</td>
                            </tr>                        
                            <tr>
                                <td class="bordes">Referente Técnico</td>
                                <td class="bordes">{{this.parteDiario.referenteTecnico?.nombre}}</td>
                                <td class="bordes">{{this.parteDiario.referenteTecnico?.numero}}</td>
                                <td class="bordes">{{this.parteDiario.referenteTecnico?.email}}</td>
                            </tr>                        
                            <tr>
                                <td class="bordes">Referente Gestión</td>
                                <td class="bordes">{{this.parteDiario.referenteGestion?.nombre}}</td>
                                <td class="bordes">{{this.parteDiario.referenteGestion?.numero}}</td>
                                <td class="bordes">{{this.parteDiario.referenteGestion?.email}}</td>
                            </tr>                        
                            <tr>
                                <td class="bordes">Referente Comercial</td>
                                <td class="bordes">{{this.parteDiario.referenteComercial?.nombre}}</td>
                                <td class="bordes">{{this.parteDiario.referenteComercial?.numero}}</td>
                                <td class="bordes">{{this.parteDiario.referenteComercial?.email}}</td>
                            </tr>                        
                            <tr>
                                <td class="bordes">Ruteador</td>
                                <td class="bordes">{{this.parteDiario.ruteador?.nombre}}</td>
                                <td class="bordes">{{this.parteDiario.ruteador?.numero}}</td>
                                <td class="bordes">{{this.parteDiario.ruteador?.email}}</td>
                            </tr>                        
                        </tbody>
                    </table>
                </div>
            </div>

            <!-- octava linea -->
            <div class="row col-md-12">
                <div class="mb-3 col-md-12">                
                    <table class="table text-center">
                        <tr class="titulo bordes">
                            <th class="bordes">ESTA PLANILLA TIENE CARÁCTER DE DECLARACION JURADA</th>                      
                        </tr>
                    </table>
                </div>
            </div>
        </ng-container>        
        <ngb-alert class="alert-warning" *ngIf="!this.obteniendoDetalle && this.parteDiario==undefined">
            Error: El parte diario no fue generado con éxito.
        </ngb-alert>        
        <ngb-alert class="alert-danger text-center" *ngIf="this.actualizarParteDiario">
            El parte diario ha sido modificado, se necesita actualizar.
        </ngb-alert> 
    </div>  
     
    <div *ngIf="!this.cierreFinalizado && !this.reporteParteDiario && this.parteDiario!=undefined && this.parteDiario?.detallesParteDiario.length > 0" class="card-footer">
      <div class="float-end">
        <!-- <button style="margin-right: 4em;" class="btn btn-primary" type="button" (click)="this.anterior()">Anterior</button> -->
        <button class="btn btn-primary" type="button" (click)="finalizarTurno()" [disabled]="guardando || actualizarParteDiario || actualizando">
          <span *ngIf="!guardando">Finalizar turno</span>
          <div *ngIf="guardando" class="d-flex align-items-center">
            <div class="spinner-border text-light spinner-border-sm" role="status"></div>
            <span>Finalizando...</span>
          </div>
        </button>
      </div>
      <div *ngIf="!actualizarParteDiario" class="float-end me-2">        
        <button class="btn btn-primary" type="button" (click)="guardarClick()" [disabled]="generando || guardando || actualizando">
          <span *ngIf="!generando">Guardar Parte Diario</span>
          <div *ngIf="generando" class="d-flex align-items-center">
            <div class="spinner-border text-light spinner-border-sm" role="status"></div>
            <span>Guardando...</span>
          </div>
        </button>
      </div>
      <div *ngIf="actualizarParteDiario" class="float-end me-2">        
        <button class="btn btn-primary" type="button" (click)="actualizarParte();" [disabled]="generando || guardando">
          <span *ngIf="!actualizando">Guardar y Actualizar Parte Diario</span>
          <div *ngIf="actualizando" class="d-flex align-items-center">
            <div class="spinner-border text-light spinner-border-sm" role="status"></div>
            <span>Actualizando...</span>
          </div>
        </button>
      </div>
    </div>
    <div *ngIf="this.cierreFinalizado">
        <label style="margin-left: 1em;"><h5><b>El cierre de turno se finalizó correctamente, puede verificar los datos en la pestaña reportes.</b></h5></label>
    </div>
</div>
