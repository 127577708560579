import { Component, OnInit } from '@angular/core';
import { Usuario } from 'src/app/shared/models/despacho/Usuario';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbTypeaheadConfig } from '@ng-bootstrap/ng-bootstrap';
import { UsuarioService } from 'src/app/account/usuario.service';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { MessageService } from 'src/app/shell/message.service';
import { Aeroplanta } from 'src/app/shared/models/despacho/Aeroplanta';
import { Guid } from 'guid-typescript';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-set-aeroplantas',
  templateUrl: './set-aeroplantas.component.html',
  styleUrls: ['./set-aeroplantas.component.css']
})
export class SetAeroplantasComponent implements OnInit {

  TipoUsuario = TipoUsuario;
  
  usuariosPendientesValidacion: Usuario[] = [];
  todosLosUsuarios: Usuario[] = [];
  usuarioSeleccionado: Usuario;

  usuarioForm: UntypedFormGroup;
  guardandoAsignacion: boolean = false;
  procesandoValidacion: boolean = false;
  guardandoEliminacion: boolean = false;

  aeroplantaActual: Aeroplanta;

  cargandoUsuariosPendientes: boolean = false;
  cargandoTodosLosUsuarios: boolean = false;

  change$: Subscription;

  constructor(private formBuilder: UntypedFormBuilder,
    private authService : AuthService,
    private ng: NgbTypeaheadConfig,
    private usuarioService: UsuarioService,
    private messageService: MessageService,
    sharedService: SharedService) { 
      this.change$ = sharedService.aeroplantaSeleccionada.subscribe(_=>{
        this.cambiarDeAeroplanta()
      })
    }

  ngOnInit() {

    this.aeroplantaActual = this.usuarioService.getAeroplantaActual();

    this.actualizarUsuariosDeAeroplanta();

    this.actualizarUsuariosPendientesValidacion();

    this.usuarioForm = this.formBuilder.group({
      usuarioFormControl: ['', Validators.required],
    });
  }

  ngOnDestroy(): void {
    this.change$.unsubscribe(); 
  }

  cambiarDeAeroplanta() {
    this.aeroplantaActual = this.usuarioService.getAeroplantaActual();    
    this.actualizarUsuariosDeAeroplanta();
    this.actualizarUsuariosPendientesValidacion();
  }

  searchUsuario = (text$: Observable<string>) => text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    map(term => term.length < 2 ? [] : this.filtrarUsuariosDeAeroplanta(false)
      .filter(u => (u.nombresCompletos==null?"":u.nombresCompletos).concat(u.dni).toLowerCase().indexOf(term.toLowerCase()) > -1)
      .slice(0, 10)
    )
  )

  usuarioFormatter = (result: Usuario) => result.nombresCompletos != null ? `${result.nombresCompletos} ( ${result.dni ? result.dni : ""} )` : "";

  onUsuarioSelectItem(event) {
    this.usuarioSeleccionado = event.item;

  }

  onChangeUsuario(){
    if (typeof (this.usuarioFormControl.value) === 'string') {
      this.usuarioSeleccionado = null;
    }
  }

  filtrarUsuariosDeAeroplanta(devolverUsuariosIncluidos: boolean){
    if(devolverUsuariosIncluidos){
      return this.todosLosUsuarios.filter(u => u.aeroplantas.find(a => a.codigoAeroplanta == this.aeroplantaActual.codigoAeroplanta && a.validada));
    }else{
      return this.todosLosUsuarios.filter(u => ! u.aeroplantas.find(a => a.codigoAeroplanta == this.aeroplantaActual.codigoAeroplanta));
    }
  }

  guardarAsignacionUsuarioAeroplanta(input){
    this.guardandoAsignacion = true;

    let usuario = this.usuarioSeleccionado;

    this.usuarioService.asignarUsuarioAeroplanta(usuario, this.aeroplantaActual)
      .subscribe(UsuarioAccion => {
        this.messageService.showSuccessMessage("Se realizo la asignación correctamente.");
        this.usuarioFormControl.setValue("");
        input.value = '';

        this.actualizarUsuariosDeAeroplanta();
        this.guardandoAsignacion = false;
      }, (err) => {
        this.messageService.showErrorMessage("Error al realizar la asignación.");
        console.log(err);
        this.guardandoAsignacion = false;
      });
  }

  validarAsignacionUsuarioAeroplanta(usuario){
    this.procesandoValidacion = true;

    this.usuarioService.asignarUsuarioAeroplanta(usuario, this.aeroplantaActual)
      .subscribe(UsuarioAccion => {
        this.messageService.showSuccessMessage("Se realizo la validación correctamente.");

        this.procesandoValidacion = false;
        this.usuariosPendientesValidacion = this.usuariosPendientesValidacion.filter( u => u.id != usuario.id);

        this.actualizarUsuariosDeAeroplanta();
      }, (err) => {
        this.messageService.showErrorMessage("Error al validar la asignación.");
        console.log(err);
        this.procesandoValidacion = false;
      });
  }

  eliminarAsignacionUsuarioAeroplanta(usuarioId){
    this.guardandoEliminacion = true;

    this.usuarioService.eliminarUsuarioDeAeroplanta(usuarioId, this.aeroplantaActual)
    .subscribe(UsuarioAccion => {
      this.messageService.showSuccessMessage("Se realizo la eliminacion correctamente.");

      this.actualizarUsuariosDeAeroplanta();
      this.guardandoEliminacion = false;
    }, (err) => {
      this.messageService.showErrorMessage("Error al eliminar la asignación.");
      console.log(err);
      this.guardandoEliminacion = false;
    });
  }

  descartarAsignacionUsuarioAeroplanta(usuarioId){

    this.procesandoValidacion = true;

    this.usuarioService.eliminarUsuarioDeAeroplanta(usuarioId, this.aeroplantaActual)
    .subscribe(UsuarioAccion => {
      this.procesandoValidacion = false;
      this.usuariosPendientesValidacion = this.usuariosPendientesValidacion.filter( u => u.id != usuarioId);

      this.actualizarUsuariosDeAeroplanta();

    }, (err) => {
      this.messageService.showErrorMessage("Error al descartar la asignación.");
      console.log(err);
      this.procesandoValidacion = false;
    });
  }

  actualizarUsuariosDeAeroplanta(){
    this.cargandoTodosLosUsuarios = true;
    this.usuarioService.obtenerTodosLosUsuarios().subscribe(
      (usuarios) => {
        this.todosLosUsuarios = usuarios;
        this.cargandoTodosLosUsuarios = false;
      },
      (err) => {
        this.messageService.showErrorMessage("Error al obtener todos los usuarios.");
        console.log(err);
        this.cargandoTodosLosUsuarios = false;
      }
    );
  }

  actualizarUsuariosPendientesValidacion(){
    this.cargandoUsuariosPendientes = true;
    this.usuarioService.obtenerUsuariosPendientesValidacion(this.aeroplantaActual.codigoAeroplanta).subscribe(
      (usuarios) => {
        this.usuariosPendientesValidacion = usuarios;
        this.cargandoUsuariosPendientes = false;
      },
      (err) => {
        this.messageService.showErrorMessage("Error al obtener usuarios pendientes de validación.");
        console.log(err);
        this.cargandoUsuariosPendientes = false;
      }
    );
  }

  usuarioSeleccionadoValido(){
    if(this.usuarioSeleccionado == null)
      return false;

    return true;
  }

  obtenerIdUsuarioLogueado(): string
  {
    return this.usuarioService.getIdUsuario();
  }

  hasRole(...roles: TipoUsuario[]) {
    return this.authService.hasRole(...roles);
  }

  get usuarioFormControl() {
    return this.usuarioForm.controls.usuarioFormControl;
  }
}
