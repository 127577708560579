import { Guid } from 'guid-typescript';
import { Contenedor } from './Contenedor';
import { Producto } from './Producto';
import { Pico } from '../despacho/Pico'
import { Medicion } from './Medicion';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export class Abastecedora extends Contenedor {
  // id: string;
  // aeroplantaCodigo: string;
  // codigo: string;
  // nombre: string;
  // capacidad: number;
  // productoActual: Producto;
  picos: Pico[];
  patente: string;
  aforadorActual: number;
  stockActual: number;
  // activo: boolean;

  //atributos para las mediciones masivas.
  fechaProvisoria: NgbDateStruct;
  horaCarga: any;
  mediciones: Medicion;
}
