import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CalculosService {

  archivoDeDensidad: any;

  constructor() { 
    this.archivoDeDensidad = this.leerArchivo();
  }

  leerArchivo(): string {
    let archivoTXT = new XMLHttpRequest();
    let fileRuta: string = 'assets/json/1.txt';
    archivoTXT.open("GET", fileRuta, false);
    archivoTXT.send(null);
    let response: any = archivoTXT.responseText;
    return response;
  }
  
  // calculo volumen 15 grados

  convertirLitrosNaturalesEn15Grados(temperatura, densidad, aforadorInicial, aforadorFinal) {

    if (temperatura != null && densidad != null && aforadorInicial != null && aforadorFinal != null) {
      var litrosNaturales = (aforadorFinal - aforadorInicial);

      //return this.convertirEn15Grados(temperatura, densidad, litrosNaturales);
      return this.obtenerCTLNuevo(temperatura, densidad, litrosNaturales);
    }
    
  }

  /*
  convertirEn15Grados(temperatura, densidad, litrosNaturales) {
    let volumen15Grados: number = 0;
   
    if (temperatura != null && densidad != null && litrosNaturales != null || temperatura != '' && densidad != '' && litrosNaturales != '' ) {
      let vcf: Big = 890;
      let densidadEn15GradosFinal = this.obtenerDensidadEn15GradosNuevaFormula(temperatura, densidad);
      console.log("DENSIDAD 15: " + densidadEn15GradosFinal);

      if (densidadEn15GradosFinal == null || isNaN(densidadEn15GradosFinal)) { return 0; }
      else {
        switch (true) {
          case densidadEn15GradosFinal < 770.8: {
            //calcular factor1;
            vcf = this.calcularVcf(this.obtenerFactor1(densidadEn15GradosFinal), temperatura);
            break;
          }
          case (densidadEn15GradosFinal < 787.8): {
            //calcular factor2;
            vcf = this.calcularVcf(this.obtenerFactor2(densidadEn15GradosFinal), temperatura);
            break;
          }
          case (densidadEn15GradosFinal < 832.3): {
            //calcular factor3;
            vcf = this.calcularVcf(this.obtenerFactor3(densidadEn15GradosFinal), temperatura);
            break;
          }
          default: {
            //calcular factor4;
            vcf = this.calcularVcf(this.obtenerFactor4(densidadEn15GradosFinal), temperatura);
            break;
          }
        }
      }

      vcf = vcf.toFixed(4); // 4 decimales
      let res : number = parseFloat(litrosNaturales) * vcf;
      res = Math.round(res); 
      volumen15Grados = res;

      return volumen15Grados;
    }
  }

  obtenerDensidadEn15Grados(temperatura: number, densidad: number): number {

    let indiceTemperatura: number = this.obtenerIndiceTemperatura(temperatura);
    let indiceDensidad: number = this.obtenerIndiceDensidad(densidad, indiceTemperatura);

    if (indiceDensidad < 0 || indiceTemperatura < 0) { return 0; }

    return this.getItem(indiceTemperatura, indiceDensidad);
  }

  obtenerDensidadEn15GradosNuevaFormula(temperatura: number, densidad: number) {
    let densidadFinal: number = densidad * 1000;
    console.log("densidadFinal : " + densidadFinal);

    let deltaTemp: number = temperatura - 15;
    console.log("deltaTemp : " + deltaTemp);

    let HYC: number = 1 - 0.000023 * deltaTemp - 0.00000002 * deltaTemp * deltaTemp;
    console.log("HYC : " + HYC);

    let RHO_BASE: number = densidadFinal * HYC;
    let RHO15 = RHO_BASE;
    let alfa;

    console.log("RHO_BASE : " + RHO_BASE);
    console.log("RHO15 -1 : " + RHO15);

    for (let i = 0; i < 18; i++) {
      if (RHO15 >= 839) {
        alfa = 186.9696 / RHO15 / RHO15 + 0.4862 / RHO15;
      } else if (RHO15 >= 788) {
        alfa = 594.5418 / RHO15 / RHO15;
      } else if (RHO15 > 770) {
        alfa = -0.00336312 + 2680.3206 / RHO15 / RHO15;
      } else {
        alfa = 346.4228 / RHO15 / RHO15 + 0.4388 / RHO15;
      }
      
      console.log("alfa -" + i + " : " + alfa);

      let vcf = Math.exp(-alfa * deltaTemp - 0.8 * alfa * alfa * deltaTemp * deltaTemp);
      console.log("vcf -" + i + " : " + vcf);

      RHO15 = RHO_BASE / vcf;
      console.log("RHO15 -" + i + " : " + RHO15);
    }

    console.log(RHO15);
    return RHO15;
  }

  obtenerIndiceDensidad(densidad: any, indiceTemperatura: number): number {
    let tabla: any = new Array();
    tabla = JSON.parse(this.archivoDeDensidad);

    let indiceDensidad: number = -1;

    for (var i = 0; i < tabla[0].length; i++) {
      if (tabla[0][i] === Number(densidad)) {
        indiceDensidad = i;
        break;
      }
    }
    return indiceDensidad;
  }

  obtenerIndiceTemperatura(temperatura: any): number {
    let tabla: any = new Array();
    tabla = JSON.parse(this.archivoDeDensidad);

    let indiceTemperatura: number = -1;
    let i = 0;

    for (var _i = 0; _i < tabla.length; _i++) {
      if (tabla[_i][0] === Number(temperatura)) {
        indiceTemperatura = _i;
        break;
      }
    }
    
    return indiceTemperatura == -1 ? i : indiceTemperatura;
  }

  getItem(fila: number, col: number): number {
    console.log("INDICE TEMPERATURA: " + fila);
    console.log("INDICE DENSIDAD: " + col);
    let tabla: any = new Array();
    tabla = JSON.parse(this.archivoDeDensidad);

    if (tabla.length <= fila) {
      return null;
    }

    if (tabla[fila].length <= col) {
      return null;
    }

    console.log("DENSIDAD 15: " + tabla[fila][col]);

    return tabla[fila][col];
  }

  calcularVcf(factor: Big, temperatura: number): Big {
    let vcf : Big = Big(Math.exp(-factor * (temperatura - 15) * (1 + 0.8 * factor * (temperatura - 15))));

    return vcf;
  }
  */

  // obtencion de factores

  /*
  obtenerFactor1(densidadFinal:Big): Big {
    let factor: Big = Big(346.4228 / Math.pow(densidadFinal, 2) + (0.4388 / densidadFinal));
    factor = factor.toFixed(9); // 9 decimales
    return factor;
  }

  obtenerFactor2(densidadFinal: Big): Big {
    let factor: Big = Big(-0.00336312 + (2680.3206 / Math.pow(densidadFinal, 2)));
    factor = factor.toFixed(9); // 9 decimales
    return factor;
  }
  obtenerFactor3(densidadFinal: Big): Big {
    let factor : Big = Big(594.5418 / Math.pow(densidadFinal, 2));    
    factor = factor.toFixed(9); // 9 decimales
    return factor;
  }
  obtenerFactor4(densidadFinal: Big): Big {
    let factor: Big = Big(186.9696 / Math.pow(densidadFinal, 2) + (0.4862 / densidadFinal));
    factor = factor.toFixed(9); // 9 decimales
    return factor;
  }
  */

  obtenerCTLNuevo(temperatura, densidad, volumen) {
    if (temperatura != null && densidad != null && volumen != null && temperatura != '' && densidad != '' && volumen != '') {
      let x0 = 1.0007*(densidad*1000)/1000- 0.001;
      let d0 = Number(x0.toFixed(4));
      let a0 = ((temperatura-((-0.148759+(-0.267408+(1.08076+(1.269056+(-4.089591+(-1.871251+(7.438081+-3.536296*temperatura/630)*temperatura/630)*temperatura/630)*temperatura/630)*temperatura/630)*temperatura/630)*temperatura/630)*temperatura/630))*1.8+32)-60.0068749;               
      let b0 = ((15-((-0.148759+(-0.267408+(1.08076+(1.269056+(-4.089591+(-1.871251+(7.438081+-3.536296*15/630)*15/630)*15/630)*15/630)*15/630)*15/630)*15/630)*15/630))*1.8+32)-60.0068749;               
            
      let v0 = (330.301 / (d0 * 1000) + 0) * (1 / (d0 * 1000));
      let v1 = (d0 * 1000) * (1 + ((Math.exp((0.01374979547 / 2) * v0 * (1 + 0.8 * (0.01374979547 / 2) * v0)) - 1) / (1 + (0.01374979547 / 2) * v0 * (1 + 1.6 * (0.01374979547 / 2) * v0) * 2)));
      let v = (330.301 / v1) * 1;
      let A = Math.exp(-(a0)*(v / v1)*(1+0.8*(v / v1)*((a0)+0.01374979547)));
      let B = Math.exp(-(b0)*(v / v1)*(1+0.8*(v / v1)*((b0)+0.01374979547)));      
      
      let x1 = A / B;
      let calculo = Number(x1.toFixed(5));      
      let resultado = Number(volumen) * calculo;
      return Math.round(resultado);
    } else {
      return 0;
    }
  }
}
