import { Ciudad } from "../facturacion/Ciudad";
import { Emisor } from "../facturacion/Emisor";
import { Ubicacion } from "./Ubicacion";

export class Aeroplanta {
    id: string;
    codigoAeroplanta: string;
    codigo: string;
    nombre: string;
    ciudadId: string;
    prefijo: number;
    ciudad: Ciudad;
    proximoNumeroRemito: number;
    proximoNumeroPedido: number;
    cai: string;
    fechaVencimientoCAI: string;
    prefijoRemito: number;
    prefijoRemitoSalida: number;
    provinciaId: string;
    emisor: Emisor;
    emisorId: string;
    ubicacion: Ubicacion;
    habilitarFacturacion: boolean; 
    mandatarioSAP: string;
    comercial: string;
    numeroCentroEmisor: string;
    numeroAeroplanta: string;
    soloPAD: boolean;
}