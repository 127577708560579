import { TipoSurtidor } from '../../enums/TipoSurtidor';
import { Pico } from '../despacho/Pico';
import { Producto } from './Producto';
import { TanqueGeneral } from './TanqueGeneral';

export class Expendedora  {
    codigo: string;
    nombre: string    
    picos: Pico[];
    productoActual: Producto;
    tipoExpendedora: number;
    activo: boolean;
  }
