
<div [formGroup]="form" class="mb-3 col-md-12" *ngIf="true"><!--this.clienteSeleccionado!=null-->
  <div class="mb-3 row" *ngIf="false">
    <div class="col-md-4">
      <label for="direccionesFormControl" class="col-form-label">
        <span>*</span>Direcciones:</label>
    </div>
    <div class="col-md-6">
      <div class="input-group mb-2">
        <select id="direccionesFormControl" class="form-select" title="Seleccione una dirección."
          formControlName="direccionesFormControl">
          <option *ngFor="let direccion of this.clienteSeleccionado.direccionesEntrega" [ngValue]="direccion"> {{direccion.calle}}
          </option>
        </select>
      </div>
      <!-- <loading-text-spinner *ngIf="cargandoAbastecedoras" [error]="errorOperadores"
        message="Obteniendo abastecedoras..." color="primary">
      </loading-text-spinner> -->

    </div>
  </div>

  <div class="mb-3 row">
    <div class="col-md-4">
      <label for="modificacionFormControl" class="col-form-label">
        <span>*</span>Modificar Localidad de Despacho:</label>
    </div>
    <div class="col-md-6">
      <div class="input-group mb-2">
       <input formControlName="modificacionFormControl" (change)="this.desactivarModificar()"  type="checkbox" value="true">
      </div>
      <!-- <loading-text-spinner *ngIf="cargandoAbastecedoras" [error]="errorOperadores"
        message="Obteniendo abastecedoras..." color="primary">
      </loading-text-spinner> -->

    </div>
  </div>

  <!--PROVINCIA-->
  <div class="mb-3 row">
    <div class="col-md-4">
      <label class="col-form-label" for="provinciaFormControl"><span
        *ngIf="esCampoVacio(this.provinciaFormControl.value)">*</span>Provincia:</label>
    </div>
    <div class="col-md-6" >
      <input id="provinciaFormControl" type="text" class="form-control" formControlName="provinciaFormControl"
        placeholder="Seleccione una opcion..." [inputFormatter]="formatterProvincia" [(ngModel)]="modelProvincia"
        [resultFormatter]="formatterProvincia" [ngbTypeahead]="searchProvincia"
        (selectItem)="selectProvincia($event)" (change)="changeProvincia()" />
      <div *ngIf="this.provinciavalid && provinciaFormControl.invalid && provinciaFormControl.errors.required && (provinciaFormControl.dirty || provinciaFormControl.touched)"
        class="alert alert-danger">
        Requerido
      </div>
      <loading-text-spinner *ngIf="this.cargandoProvincias" message="Obteniendo provincias..." color="primary">
      </loading-text-spinner>
    </div>
  </div>

  <div class="mb-3 row">
    <div class="col-md-4">
      <label class="col-form-label" for="codigoPostalFormControl">*Ciudad:</label>
    </div>
    <div class="col-md-6">
      <input class="form-control" id="codigoPostalFormControl" formControlName="codigoPostalFormControl"
        placeholder="Seleccione una opcion..." [inputFormatter]="formatterCiudad" [(ngModel)]="modelCiudad"
        [resultFormatter]="formatterCiudad" [ngbTypeahead]="searchCiudad" (selectItem)="selectCiudades($event)" 
        (change)="changeCiudad()" />
      <div
        *ngIf="this.ciudadvalid && codigoPostalFormControl.invalid && codigoPostalFormControl.errors.required && (codigoPostalFormControl.dirty || codigoPostalFormControl.touched)"
        class="alert alert-danger">
        Requerido
      </div>
      <loading-text-spinner *ngIf="this.cargandoCiudades" message="Obteniendo ciudades..." color="primary">
      </loading-text-spinner>
    </div>
  </div>


</div>
