<div class="modal-header bg-light">
  <div class="modal-title">
    <label class="h5">Fecha :
      {{this.transaccionSeleccionada.fechaHora | date: "d'/'MM'/'y' 'hh:mm a" }}
    </label>
    <br>
    <label class="h5">Tipo de movimiento:
      {{this.obtenerTipoMovimiento(this.transaccionSeleccionada.tipoTransaccion)}}
    </label>
  </div>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismissAll()">
    
  </button>
</div>
<ngx-spinner name="spinnerDetalleMovimiento" [ngStyle]="{'height': '400px', 'z-index':'0'}"
  [ngClass]="{'list-group-item list-group-item-action': obteniendoDetalleMovimiento}" bdOpacity=0.3bd
  Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
  <p style="color: white"> O b t e n i e n d o D E T A L L E . . . </p>
</ngx-spinner>
<div class="modal-body" *ngIf="!obteniendoDetalleMovimiento">
  <div *ngFor="let mov of movimientoSeleccionado.movimientos" class="col col-md-6"
    style="display: inline-block; border: solid;">
    <table class="table table-sm table-striped table-hover">
      <thead>
        <tr>
          <th>Contenedor: <span>{{mov.contenedor.nombre}}</span>
            <span class="float-end"
              *ngIf="mov.cantidad < 0 && movimientoSeleccionado.tipoTransaccion != TipoMovimientoStock.AjusteManual && movimientoSeleccionado.tipoTransaccion != TipoMovimientoStock.Rebombeo">Origen</span>
            <span class="float-end"
              *ngIf="mov.cantidad > 0 && movimientoSeleccionado.tipoTransaccion != TipoMovimientoStock.AjusteManual && movimientoSeleccionado.tipoTransaccion != TipoMovimientoStock.Rebombeo">Destino</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="mov.contenedor.productoActual != null">Producto: <span
            class="float-end">{{mov.contenedor.productoActual.nombreProducto}}</span></tr>
        <tr *ngIf="mov.contenedor.productoActual == null">Producto: <span
            class="float-end">{{mov.producto.nombreProducto}}</span></tr>
        <tr>Capacidad: <span class="float-end">{{mov.contenedor.capacidad | number: '1.0-0'}}</span></tr>
        <tr>Cantidad: <span class="float-end">{{mov.cantidad | number: '1.0-0'}}</span></tr>
        <tr>Stock: <span class="float-end">{{mov.stock | number: '1.0-0'}}</span></tr>
        <tr *ngIf="mov.contenedor.productoActual != null && movimientoSeleccionado.tipoTransaccion != TipoMovimientoStock.Rebombeo">Densidad: <span
            class="float-end">{{mov.densidad.toFixed(3)}}</span>
        </tr>
        <tr *ngIf="mov.contenedor.productoActual != null && movimientoSeleccionado.tipoTransaccion != TipoMovimientoStock.Rebombeo">Temperatura:
          <span class="float-end">{{mov.temperatura}}</span>
        </tr>
        <tr *ngIf="mov.contenedor.productoActual != null && movimientoSeleccionado.tipoTransaccion != TipoMovimientoStock.Rebombeo">Cantidad 15°:
          <span class="float-end">{{mov.cantidad15 | number: '1.0-0'}}</span>
        </tr>
        <tr *ngIf="this.movimientoSeleccionado.tipoTransaccion === TipoMovimientoStock.Despacho && mov.contenedor.tipoContenedor === TipoMovimientoStock.Rebombeo">Caudal
          observado: <span class="float-end">{{this.movimientoSeleccionado.comprobante.caudalObservado}}</span>
        </tr>
        <tr *ngIf="this.movimientoSeleccionado.tipoTransaccion === TipoMovimientoStock.Despacho && mov.contenedor.tipoContenedor === TipoMovimientoStock.Rebombeo">Presión
          punta manguera: <span
            class="float-end">{{this.movimientoSeleccionado.comprobante.presionPuntaManguera}}</span></tr>
        <tr *ngIf="this.movimientoSeleccionado.tipoTransaccion === TipoMovimientoStock.Despacho && mov.contenedor.tipoContenedor === TipoMovimientoStock.Rebombeo">Presión
          diferencial: <span class="float-end">{{this.movimientoSeleccionado.comprobante.presionDiferencial}}</span>
        </tr>
      </tbody>
    </table>
  </div>
  <div *ngIf="this.movimientoSeleccionado.tipoTransaccion === TipoMovimientoStock.AjusteManual">
    <label><b>Motivo:</b>
      {{movimientoSeleccionado.comprobante.motivoObservacion}}</label><br>
    <label><b>Observación:</b>
      {{movimientoSeleccionado.observacion}}</label>
  </div>
  <div>
    <label *ngIf="this.movimientoSeleccionado.tipoTransaccion === TipoMovimientoStock.Despacho || this.movimientoSeleccionado.tipoTransaccion === TipoMovimientoStock.Alije"><b>Comprobante asociado:</b><br>
      Aerovale:
      {{movimientoSeleccionado.comprobante.aerovalePrefijo}}-{{movimientoSeleccionado.comprobante.aerovaleNumero}}
    </label>
    <label *ngIf="this.movimientoSeleccionado.tipoTransaccion === TipoMovimientoStock.Recepcion"><b>Comprobante asociado:</b><br>
      Remito cargamento:
      {{movimientoSeleccionado.comprobante.prefijoRemito}}-{{movimientoSeleccionado.comprobante.numeroRemito}}
    </label>
  </div>
  <div class="row">
    <div *ngIf="this.movimientoSeleccionado.tipoTransaccion === TipoMovimientoStock.Despacho || this.movimientoSeleccionado.tipoTransaccion === TipoMovimientoStock.Alije">
      <button *ngIf="!this.obteniendoAerovale" (click)="mostrarAerovale()" class="btn btn-outline-secondary mt-2 mb-2"
        type="button">
        <i class="fa fa-eye" aria-hidden="true"></i> Ver aerovale</button>
      <loading-text-spinner *ngIf="this.obteniendoAerovale" message="Obteniendo aerovale..." color="primary">
      </loading-text-spinner>
    </div>    
    <div *ngIf="this.movimientoSeleccionado.tipoTransaccion === TipoMovimientoStock.Recepcion">
      <button *ngIf="!this.obteniendoAerovale" (click)="mostrarComprobanteCargamento(0)"
        class="btn btn-outline-secondary mt-2 mb-2 ms-2" type="button">
        <i class="fa fa-eye" aria-hidden="true"></i> Ver Remito Cargamento</button>
      <loading-text-spinner *ngIf="this.obteniendoAerovale" message="Obteniendo remito cargamento..." color="primary">
      </loading-text-spinner>
    </div>
    <div *ngIf="this.movimientoSeleccionado.tipoTransaccion === TipoMovimientoStock.Recepcion">
      <button *ngIf="!this.obteniendoAerovale" (click)="mostrarComprobanteCargamento(1)"
        class="btn btn-outline-secondary mt-2 mb-2 ms-2" type="button">
        <i class="fa fa-eye" aria-hidden="true"></i> Ver Recibo Cargamento</button>
      <loading-text-spinner *ngIf="this.obteniendoAerovale" message="Obteniendo recibo cargamento..." color="primary">
      </loading-text-spinner>
    </div>
    <div *ngIf="this.movimientoSeleccionado.tipoTransaccion === TipoMovimientoStock.Salida">
      <button *ngIf="!this.obteniendoAerovale" (click)="mostrarComprobanteCargamento(2)"
        class="btn btn-outline-secondary mt-2 mb-2 ms-2" type="button">
        <i class="fa fa-eye" aria-hidden="true"></i> Ver Remito Salida</button>
      <loading-text-spinner *ngIf="this.obteniendoAerovale" message="Obteniendo remito cargamento..." color="primary">
      </loading-text-spinner>
    </div>
    <div class="float-end" *ngIf="this.hasRole(TipoUsuario.OPERADOR, TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL_AV) && this.puedoAnularMovimiento()">
      <button [disabled]="anulandoMovimiento" *ngIf="this.movimientoSeleccionado.movimientos[0].movimientoAnuladoId == null" (click)="anularMovimiento()" class="btn btn-danger mt-2 mb-2 ms-2">
      <span *ngIf="!anulandoMovimiento">Anular Movimiento</span>
      <div *ngIf="anulandoMovimiento" class="d-flex align-items-center">
        <div class="spinner-border text-light spinner-border-sm" role="status"></div>
        <span>Anulando...</span>
      </div>
      </button>
    </div>
  </div>
</div>
<div class="modal-footer bg-light">
  <button type="button" class="btn btn-secondary" (click)="activeModal.dismissAll('Close clicked')">Cancelar</button>
</div>
