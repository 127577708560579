<div class="modal-header bg-light" >
    <h4 class="modal-title">Crear notificación</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close(false)"></button>
</div>
<div class="modal-body" [formGroup]="crearNotificacionForm" >
    <!--NOMBRE-->
    <div *ngIf="mailsNotificacion">
        <div *ngFor="let mail of mailsNotificacion; let i = index">
            <p>{{mail}}<button [disabled]="grabando" class="btn btn-sm" (click)="borrarMail(i)"><i class="fa fa-trash"></i></button></p>
        </div>
    </div>
    <div class="form-row">
        <div class="form-group row col-md-12" *ngIf="authService.hasRole(TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_LN, TipoUsuario.COMERCIAL_AV, TipoUsuario.APROBADORBYR)">
            <label for="emailFormControl" class="col-sm-5 col-form-label">Email: </label>
            <div class="col-sm-7">
                <input [disabled]="grabando" class="form-control col-sm-9" style="display: inline!important; width: 85%" formControlName="emailFormControl" maxlength="50">
                <button [disabled]="grabando" class="btn btn-sm col-sm-1" style="display: inline!important; width: 15%;" (click)="agregarMail()"><i class="fa fa-plus"></i></button>
            </div> <br> <br>
            <label for="montoFormControl" class="col-sm-5 col-form-label">Monto: </label>
            <div class="col-sm-7">
                <input [disabled]="grabando" type="number" min="0" oninput="validity.valid||(value='');" class="form-control col-sm-11" formControlName="montoFormControl" maxlength="50">
            </div>
        </div>
    </div>
</div>
<div class="modal-footer justify-content-center">
    <button  *ngIf="authService.hasRole(TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_LN, TipoUsuario.COMERCIAL_AV, TipoUsuario.APROBADORBYR)" class="btn btn-primary mr-2" id="aceptar" [disabled]="!this.mailsNotificacion.length" (click)="agregarNotificacion();">
        <span *ngIf="!grabando">Guardar</span>
        <div *ngIf="grabando" class="d-flex align-items-center">
            <div class="spinner-border text-light spinner-border-sm" role="status"></div>
            <span>Guardando...</span>
        </div>
    </button>
    <button [disabled]="grabando" type="button" class="btn btn-secondary" (click)="this.activeModal.close(false)">Cancelar</button>
</div>