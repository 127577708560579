import { Producto } from './Producto';
import { AerovaleCommon } from './AerovaleCommon';
import { AeroEspecialidadItem } from './AeroespecialidadItem';
import { VehiculoDTO } from '../cliente/VehiculoDTO';

export class AerovaleAeroespecialidad extends AerovaleCommon{
  vehiculo: VehiculoDTO;
  vuelo: string;
  combustible: Producto;
  aeroespecialidades: AeroEspecialidadItem[];
}
