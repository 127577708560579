<div class="card" id="holdeoCuenta-card">
  <div class="card-header">
    <h4 style="display: inline;" class="card-title">Agregar nuevo {{this.obtenerTipo(tipo)}}.</h4>
    <button type="button" class="btn-close float-end" aria-label="Close" (click)="modal.close(false)"></button>
  </div>
  <div class="card-body" [formGroup]="nuevoHoldeoForm">
    <!--PARA CUENTAS DE CLIENTE-->
    <!--BUSQUEDA DE CLIENTE + CUENTA DEL CLIENTE SELECCIONADO-->
    <ng-container *ngIf="tipo == TipoHoldeo.CuentaCliente">
      <div class="d-flex flex-row mb-3 align-items-center">
        <div class="col-3">
          <label class="col-fome-label">Razon social:</label>
        </div>
        <div class="col-9">
          <input *ngIf="!this.esperandoCarga" id="clienteFormControl" type="text" class="form-control"
            formControlName="clienteFormControl" [inputFormatter]="formatter" [(ngModel)]="modelCliente"
            placeholder="Ingresar un cliente"
            [resultFormatter]="formatter" title="Campo obligatorio, debe seleccionar o ingresar un cliente."
            [ngbTypeahead]="search" (selectItem)="selectCustomer($event)" />
          <loading-text-spinner *ngIf="this.esperandoCarga" message="Obteniendo Clientes..." color="primary">
          </loading-text-spinner>
        </div>
      </div>
      <div class="d-flex flex-row mb-3 align-items-center">
        <div class="col-3 mt-2">
          <label class="col-fome-label">Cuenta del cliente:</label>
        </div>
        <div class="col-9 mt-2" *ngIf="cuentas.length > 0">
          <select class="form-select" formControlName="cuentasFormControl">
            <option *ngFor="let c of cuentas" [ngValue]="c">{{c.ctA_SGC}}</option>
          </select>
        </div>
      </div>
    </ng-container>
    <!--HOLDEO DE AEROVALE-->
    <!--BUSQUEDA DE AEROVALE-->
    <ng-container *ngIf="tipo == TipoHoldeo.Aerovale">
      <div class="d-flex flex-row mb-3 align-items-center">
        <div class="col-3">
          <label class="col-form-label">N° de aerovale:</label>
        </div>
        <div class="col-2">
          <input class="form-control" formControlName="prefijoFormControl">
        </div>
        <label> - </label>
        <div class="col-4">
          <input class="form-control" formControlName="numeroFormControl">
        </div>
        <div class="col-2 px-2">
          <button class="btn btn-primary" (click)="buscarAerovale()" [disabled]="obteniendoAerovale">
            <span *ngIf="!obteniendoAerovale">Buscar</span>
            <div *ngIf="obteniendoAerovale" class="d-flex align-items-center">
              <div class="spinner-border text-light spinner-border-sm" role="status"></div>
              <span>Buscando...</span>
            </div>
          </button>
        </div>
      </div>
      <ngb-alert *ngIf="this.errorAerovale">El aerovale buscado no existe..</ngb-alert>
      <button *ngIf="aerovaleEncabezado != null" class="list-group-item list-group-item-action mt-2">
        <div class="d-flex w-100 justify-content-between">
          <p><b>Aev Nº:
              {{ CompletarPrefijo(aerovaleEncabezado.prefijo) }}{{
              CompletarNumero(this.aerovaleEncabezado.numeroAerovale) }}</b></p>
          <p *ngIf="aerovaleEncabezado.volumen != null">{{ aerovaleEncabezado.volumen | number : '0.0-0'}} LTS</p>
        </div>
        <div class="d-flex w-100 justify-content-between">
          <h5 class="mb-1">{{ aerovaleEncabezado.vuelo }} / {{ aerovaleEncabezado.matricula }} </h5>
          <small>{{ aerovaleEncabezado.estado }}</small>
        </div>
        <p class="mb-1"> {{ aerovaleEncabezado.clienteRazonSocial }} <br> {{ aerovaleEncabezado.nombreProducto }} </p>
        <small>{{ aerovaleEncabezado.fechaHoraFinDespacho | date: "d'/'MM'/'y' 'hh:mm a" }}</small>
      </button>
    </ng-container>
    <!--TANTO PARA CUENTA COMO PARA AEROVALE-->
    <!-- MOTIVO -->
    <div class="d-flex flex-row mb-3">
      <div class="col-3">
        <label class="col-form-label">Motivo:</label>
      </div>
      <div class="col-9">
        <div class="input-group me-2">
          <textarea [rows]="3" placeholder="Ingrese un motivo de holdeo" class="form-control"
            formControlName="motivoFormControl"></textarea>
        </div>
      </div>
    </div>
    <!--DESDE-->
    <div class="d-flex flex-row mb-3 align-items-center">
      <div class="col-3">
        <label class="col-fome-label">Fecha Desde:</label>
      </div>
      <div class="col-9">
        <div class="input-group me-2">
          <input type="date" [ngModel]="modelDesde" class="fechaFormControlDesde"
            formControlName="fechaFormControlDesde" placeholder="aaaa-mm-dd" (ngModelChange)="modelDesde = $event"
            name="dt" ngbDatepicker #d="ngbDatepicker" value="modelDesde" ngbTooltip="Seleccione una fecha de inicio"
            class="form-control form-control-rounded" #searchDate readonly>
          <div class="input-group-append">
            <button id="botonDesde" type="button" class="input-group-btn btn btn-secondary" (click)="d.toggle()">
              <i class="fa fa-calendar" aria-hidden="true"></i></button>
          </div>
        </div>
      </div>
    </div>
    <!--HASTA-->
    <div class="d-flex flex-row mb-3 align-items-center">
      <div class="col-3">
        <label class="col-fome-label">Fecha Hasta:</label>
      </div>
      <div class="col-9">
        <div class="input-group me-2">
          <input type="date" [ngModel]="modelHasta" class="fechaFormControlHasta"
            formControlName="fechaFormControlHasta" placeholder="aaaa-mm-dd" (ngModelChange)="modelHasta = $event"
            name="dt" ngbDatepicker #h="ngbDatepicker" value="modelHasta" ngbTooltip="Seleccione una fecha de fin"
            class="form-control form-control-rounded" #searchDate readonly>
          <div class="input-group-append">
            <button id="botonHasta" type="button" class="input-group-btn btn btn-secondary" (click)="h.toggle()">
              <i class="fa fa-calendar" aria-hidden="true"></i></button>
          </div>
        </div>
      </div>
    </div>
    <!--HOLDEO FIN DE MES SOLO AEROVALE Y CUENTA-->
    <div class="d-flex flex-row mb-3 align-items-center" *ngIf="tipo == TipoHoldeo.Aerovale || tipo == TipoHoldeo.CuentaCliente">
      <div class="col-4">
        <label class="col-form-label">Holdeo fin de mes</label>
      </div>
      <div class="col-8">
        <input type="checkbox" class="form-check-input" formControlName="holdeoFinDeMesFormControl" id="holdeoFinDeMesFormControl">
      </div>
    </div>
    <ngb-alert type="danger" [dismissible]="false" *ngIf="fechaDesdeMayorQueHasta()==true">
      La fecha inicial debe ser anterior a la fecha final.
    </ngb-alert>
  </div>
  <div class="card-footer justify-content-center">
    <button class="btn btn-primary me-2" id="aceptar" [disabled]="guardandoHoldeo" (click)="guardarholdeo()">
      <span *ngIf="!guardandoHoldeo">Guardar</span>
      <div *ngIf="guardandoHoldeo" class="d-flex align-items-center">
        <div class="spinner-border text-light spinner-border-sm" role="status"></div>
        <span>Guardando...</span>
      </div>
    </button>
    <button type="button" class="btn btn-secondary" (click)="this.modal.close(false)">Cancelar</button>
  </div>
</div>