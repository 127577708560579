import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { NgbActiveModal, NgbCalendar, NgbDateParserFormatter, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Guid } from 'guid-typescript';
import { UsuarioService } from 'src/app/account/usuario.service';
import { estadoEquipo } from 'src/app/shared/enums/estadoEquipo';
import { EquipoFijo } from 'src/app/shared/models/stock/EquipoFijo';
import { EstadoEquipo } from 'src/app/shared/models/stock/EstadoEquipo';
import { MessageService } from 'src/app/shell/message.service';
import { StockService } from '../../../stock.service';

@Component({
  selector: 'app-nuevo-equipo',
  templateUrl: './nuevo-equipo.component.html',
  styleUrls: ['./nuevo-equipo.component.scss']
})
export class NuevoEquipoComponent implements OnInit {

  @Input() equipo: EquipoFijo;
  @Input() editar: boolean;
  @Input() cambioEstado: boolean;
  @Input() nuevoEstado: number;
  
  equiposForm = new UntypedFormGroup({
    nombreFormControl: new UntypedFormControl(''),
    observacionesFormControl: new UntypedFormControl(''),
    //estadoFormControl: new FormControl(''),
    fechaFSFormControl: new UntypedFormControl(''),
    horaFSFormControl: new UntypedFormControl('')
  })
  
  grabando: boolean = false;
  estEquipo = estadoEquipo;
  model: NgbDateStruct;
  horaAct: any;
  cambioFueraServicio: boolean;
  editando: boolean;
  nuevo: boolean;

  constructor(public activeModal: NgbActiveModal, private usuarioService: UsuarioService, private stockService: StockService, 
    private calendar: NgbCalendar, private messageService: MessageService) { }

  ngOnInit(): void {
    var horaActual = new Date();
    this.model = this.calendar.getToday();
    this.horaAct = { hour: horaActual.getHours(), minute: horaActual.getMinutes(), second: horaActual.getSeconds() };
    this.editando = this.editar;
    
    if(this.equipo != null) {
      !this.editar && this.nuevoEstado == 1 ? this.cambioFueraServicio = true : false;
    } else {
      !this.editar ? this.nuevo = true : false;
    }

    if(this.nuevo) {
      this.equiposForm.controls.nombreFormControl.addValidators([Validators.required, Validators.maxLength(20)]);
      //this.equiposForm.controls.estadoFormControl.addValidators([Validators.required]);
    }

    if(this.editando) {
      this.equiposForm.controls.nombreFormControl.addValidators([Validators.required, Validators.maxLength(20)]);
      this.equiposForm.controls.nombreFormControl.setValue(this.equipo.nombre);
    }

    if(this.cambioFueraServicio) {
      this.equiposForm.controls.fechaFSFormControl.addValidators([Validators.required]);
      this.equiposForm.controls.horaFSFormControl.addValidators([Validators.required]);
    }
    
  }
  
  agregarEquipo(){

    if(this.nuevo) {
      this.grabando = true;
      let equipo = new EquipoFijo();
      let est = new EstadoEquipo();
      let codAeroplanta = this.usuarioService.getAeroplantaActual().codigoAeroplanta;

      est.estado = 0 //this.estadoFormControl.value;
      est.responsable = this.usuarioService.getNombresCompletosUsuario();
      est.observaciones = '';
      est.fechaHoraUltimoCambio = "2020-01-15T21:00:00.00";
      est.fechaHoraFS = null;
      est.id = Guid.EMPTY;
      equipo.nombre = this.nombreFormControl.value;
      equipo.ultimoEstado = est;
      equipo.id = Guid.EMPTY;

      this.stockService.crearEquipoFijo(codAeroplanta, equipo)
        .subscribe(result => {
          this.messageService.showSuccessMessage('El equipo fue creado con exito.');
          this.grabando = false;
          this.activeModal.close(true);
        }, () => {
          this.messageService.showErrorMessage('Ocurrio un error, vuelva a intentar.');
          this.grabando = false;
        })
    }

    if(this.editando) {
      this.grabando = true;
      this.equipo.nombre = this.nombreFormControl.value;

      this.stockService.editarEquipoFijo(this.equipo.id, this.equipo)
        .subscribe(result => {
          this.messageService.showSuccessMessage('El equipo fue actualizado con exito.');
          this.grabando = false;
          this.activeModal.close(true);
        }, () => {
          this.messageService.showErrorMessage('Ocurrio un error, vuelva a intentar.');
          this.grabando = false;
        })

    }

    if(this.cambioEstado && !this.cambioFueraServicio) {
      this.grabando = true;
      this.equipo.ultimoEstado.estado = this.nuevoEstado;
      this.equipo.ultimoEstado.observaciones = this.observacionesFormControl.value;
      this.equipo.ultimoEstado.responsable = this.usuarioService.getNombresCompletosUsuario();

      const fecha = this.fechaFSFormControl.value;
      const hora = this.horaFSFormControl.value;
      this.equipo.ultimoEstado.fechaHoraFS = this.formatDate(fecha['year'], fecha['month'], fecha['day'], hora['hour'], hora['minute']);
      
      this.stockService.cambiarEstadoEquipo(this.equipo.id, this.equipo.ultimoEstado)
        .subscribe(result => {
          this.messageService.showSuccessMessage('El equipo fue actualizado con exito.');
          this.grabando = false;
          this.activeModal.close(true);
        }, () => {
          this.messageService.showErrorMessage('Ocurrio un error, vuelva a intentar.');
          this.grabando = false;
        })
    }
    
    if(this.cambioFueraServicio) {
      this.grabando = true;
      this.equipo.ultimoEstado.estado = 1;
      this.equipo.ultimoEstado.observaciones = this.observacionesFormControl.value;
      this.equipo.ultimoEstado.responsable = this.usuarioService.getNombresCompletosUsuario();
      
      const fecha = this.fechaFSFormControl.value;
      const hora = this.horaFSFormControl.value;
      this.equipo.ultimoEstado.fechaHoraFS = this.formatDate(fecha['year'], fecha['month'], fecha['day'], hora['hour'], hora['minute']);
      
      this.stockService.cambiarEstadoEquipo(this.equipo.id, this.equipo.ultimoEstado)
        .subscribe(result => {
          this.messageService.showSuccessMessage('El equipo fue actualizado con exito.');
          this.grabando = false;
          this.activeModal.close(true);
        }, () => {
          this.messageService.showErrorMessage('Ocurrio un error, vuelva a intentar.');
          this.grabando = false;
        })

    }

  }

  formatDate(year, month, day, hour, minute): string {
    return year + '-' + month.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + '-' + day.toLocaleString(undefined, { minimumIntegerDigits: 2 })
      + 'T' + hour.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + minute.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':00';
  }

  obtenerEstado(est) {
    return Number(this.estEquipo[est]);
  }

  keysEstados(): Array<string> {
    var keys = Object.keys(estadoEquipo);
    keys = keys.slice(keys.length / 2);
    return keys;
  }

  get nombreFormControl() {
    return this.equiposForm.get('nombreFormControl');
  }

  //get estadoFormControl() {
  //  return this.equiposForm.get('estadoFormControl');
  //}

  get observacionesFormControl() {
    return this.equiposForm.get('observacionesFormControl');
  }

  get fechaFSFormControl() {
    return this.equiposForm.get('fechaFSFormControl');
  }

  get horaFSFormControl() {
    return this.equiposForm.get('horaFSFormControl');
  }

}
