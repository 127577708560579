<div *ngIf="obteniendoFiltro" style="margin-left: 2em; margin-top: 1em; float: left;">
    <loading-text-spinner message="Obteniendo filtros..." color="primary"></loading-text-spinner>
</div>
<div *ngIf="!obteniendoFiltro" class="radio-inline" style="display: block; margin-top: 1.5em;">
    <span style="margin-left: 5em; margin-right: 5em;">Opciones de visualización</span>

    <input id="filtroNatural" name="tipoFiltro" type="radio" style="margin-right: 1em;" [checked]="true" (click)="filtrarNatural()"/>
    <label for="filtroNatural" class="radio-label" name="tipoFiltro"  style="margin-right: 5em;"> Mostrar natural</label>

    <input id="filtro15" name="tipoFiltro" type="radio" style="margin-right: 1em;" (click)="filtrar15()"/>
    <label for="filtro15" class="radio-label" style="margin-right: 5em;">Mostrar 15º</label>

    <input type="checkbox" class="form-check-input" [(ngModel)]="soloPAD" id="soloPADFormControl"
        [disabled]="!checkEnabled" style="margin-right: 1em;" checked (change)="changeSoloPAD()"/>
    <label class="form-check-label" for="soloPADFormControl">Solo PAD</label>
</div>

<div id="tablaStock" *ngIf="!obteniendoFiltro">
    <table>
        <thead>
            <tr *ngIf="this.listaProductos?.length > 0">
                <th></th>
                <th *ngFor="let p of this.listaProductos">{{ p.codigoProducto }}<br/>{{ p.nombreProducto }}</th>
            </tr>
            
        </thead>

        <tbody>
            <tr *ngFor="let a of this.stockAeroplantasFiltradas">
                <td class="nombre-aeroplanta">{{a.aeroplanta}}</td>
                <td *ngFor="let p of this.listaProductos" class="datos" 
                    [ngClass]="{ 'destacado': (p.tipoProducto == TipoProducto.Aerocombustible || p.tipoProducto == TipoProducto.Combustible)}">
                    {{ this.renderizarStockPorProducto(p.codigoProducto, a.stock) }}
                </td>
            </tr>
        </tbody>
    </table>
    <div *ngIf="obteniendoStockContenedores" style="margin-left: 2em; margin-top: 1em; float: left;">
        <loading-text-spinner message="Obteniendo datos..." color="primary"></loading-text-spinner>
    </div>
</div>