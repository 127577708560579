<div class="modal-header bg-light">
  <h4 class="modal-title justify-content-center" *ngIf="editable==false">
    Nuevo Impuesto
  </h4>
  <h4 class="modal-title justify-content-center" *ngIf="editable==true">
    Detalle de Impuesto
  </h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismissAll()"></button>
</div>

<!-- SI NO ES IDC O ITC-->
<div class="modal-body" *ngIf="!(codigoImpuestoSeleccionado == 4 || codigoImpuestoSeleccionado == 5)">
  <form [formGroup]="nuevoImpuestoForm">
    <div class="row">
      <!-- codigo -->
      <div class="mb-3 col-md-6" *ngIf="editable==false">
        <div class="col-md-4">
          <label for="codigoControlInput" class="col-form-label">Codigo:</label>
        </div>
        <div class="col-md-8">
          <select type="text" class="form-select" name="tipoID" formControlName="codigoFormControl"
            title="Seleccione un impuesto." (change)="this.changeTipoImpuesto()">
            <option id="optionUnidadMedida" *ngFor="let codigo of this.listaCodigos()"
              [ngValue]="codigoImpuesto[codigo]">
              {{ codigo }}
            </option>
          </select>
        </div>
      </div>

      <div class="mb-3 col-md-6" *ngIf="editable==true">
        <div class="col-md-4">
          <label for="codigoControlInput" class="col-form-label">Codigo:</label>
        </div>
        <div class="col-md-8">
          <select type="text" class="form-select" name="tipoID" formControlName="codigoFormControl"
            title="Seleccione un impuesto." disabled="editable" (change)="this.changeTipoImpuesto()">
            <option id="optionUnidadMedida" *ngFor="let codigo of this.listaCodigos()"
              [ngValue]="codigoImpuesto[codigo]">
              {{ codigo }}
            </option>
          </select>
        </div>
      </div>

      <div class="mb-3 col-md-6">
        <div class="col-md-4">
          <label for="leyendaControlInput" class="col-form-label"> Leyenda: </label>
        </div>
        <div class="col-md-8">
          <input id="leyendaControlInput" type="text" class="form-control mb-2 me-sm-2"
            title="Campo obligatorio, debe ingresar un número de leyenda." formControlName="leyendaFormControl"
            placeholder="###" />
        </div>
      </div>
    </div>

    <div class="row">
      <!-- tipo de valor si es iva -->
      <div class="mb-3 col-md-6" *ngIf="this.codigo.value!=0">
        <div class="col-md-6">
          <label for="codigoControlInput" class="col-form-label"> Tipo de valor:</label>
        </div>
        <div class="col-md-8">
          <select type="text" class="form-select" formControlName="tipoProductoFormControl"
            title="Seleccione un tipo de valor.">
            <option id="optionUnidadMedida" *ngFor="let tipo of  keysTiposValor()" [ngValue]="tipoDeValor[tipo]">
              {{ tipo }}
            </option>
          </select>
        </div>
      </div>
      <div class="mb-3 col-md-6" *ngIf="this.codigo.value==0">
        <div class="col-md-6">
          <label for="codigoControlInput" class="col-form-label"> Tipo de valor:</label>
        </div>
        <div class="col-md-8">
          <select type="text" class="form-select" formControlName="tipoProductoFormControl"
            title="Seleccione un tipo de valor." disabled>
            <option id="optionUnidadMedida" *ngFor="let tipo of  keysTiposValor()" [ngValue]="tipoDeValor[tipo]">
              {{ tipo }}
            </option>
          </select>
        </div>
      </div>

      <div class="mb-3 col-md-6" *ngIf="this.codigo.value!=0">
        <div class="col-md-6">
          <label for="valorControlInput" class="col-form-label"> Valor: </label>
        </div>
        <div class="col-md-8">
          <input id="valorControlInput" type="text" step="0.01" 
            (change)="controlarCaracteres($event)" (keypress)="onlyDecimalNumberKey($event)"
            class="form-control mb-2 me-sm-2" title="Campo obligatorio, debe ingresar un valor."
            formControlName="valorFormControl" placeholder="###" />
            <div *ngIf="errorValorFormControl" class="alert alert-danger">
              El valor ingresado no es decimal correcto. Verifique 
            </div>   
        </div>
      </div>

      <div class="mb-3 col-md-6" *ngIf="this.codigo.value==0">
        <div class="col-md-6">
          <label for="ivaControlInput" class="col-form-label"> Valor: </label>
        </div>
        <div class="col-md-8">
          <select id="ivaControlInput" formControlName="ivaControlInput" class="form-select">
            <option [value]="10.5">10.5%</option>
            <option [value]="21">21%</option>
            <option [value]="27">27%</option>
          </select>
        </div>
      </div>
    </div>

    <div class="row">
      <!-- Base de calculo -->
      <!-- <div class="mb-3 col-md-6">
        <div class="col-md-6">
          <label for="baseCalculoControlInput" class="col-form-label">*Base de Calculo:</label>
        </div>
        <div class="col-md-8">
          <select type="text" class="form-control" formControlName="baseFormControl"
            title="Seleccione una base de calculo.">
            <option id="baseCalculoControlInput" *ngFor="let base of  keysBaseCalculo()"
              [ngValue]="baseDeCalculo[base]">
              {{ base }}
            </option>
          </select>
        </div>
      </div> -->

      <!-- importe minimo -->
      <div class="mb-3 col-md-6">
        <div class="col-md-6">
          <label for="importeMinimoFormControl" class="col-form-label"> Importe mínimo: </label>
        </div>
        <div class="col-md-8">
          <input id="importeMinimoFormControl" type="text" step="0.01" class="form-control mb-2 me-sm-2"
            (change)="controlarCaracteres($event)" (keypress)="onlyDecimalNumberKey($event)" formControlName="importeMinimoFormControl"
            title="Campo obligatorio, debe ingresar un importe minimo" name="importeMinimoFormControl" />
            <div *ngIf="errorValorFormControl" class="alert alert-danger">
              El importe ingresado no es decimal correcto. Verifique 
            </div>
        </div>
      </div>
    </div>
    <small>
      <ul class="col-md-12 list-unstyled text-danger">
        <li>*B1) Total facturado-IVA-IVA percecpion.</li>
        <li>*B2) Total facturado-IVA-IVA percepcion-ITC-Internos (incluye TGO, H, GLP, GNC, FF de gas).</li>
        <li>*B3) Total facturado-IVA discriminado-IVA percecpion discriminado.</li>
        <li>*B4) Total facturado.</li>
      </ul>
    </small>
  </form>
</div>

<!-- SI ES ITC O IDC -->
<div class="modal-body" *ngIf="(codigoImpuestoSeleccionado == 4 || codigoImpuestoSeleccionado == 5)">
  <form [formGroup]="nuevoImpuestoForm">
    <div class="row">
      <!-- codigo -->
      <div class="mb-3 col-md-6" *ngIf="editable==false">
        <div class="col-md-4">
          <label for="codigoControlInput" class="col-form-label">Codigo:</label>
        </div>
        <div class="col-md-8">
          <select type="text" class="form-select" name="tipoID" formControlName="codigoFormControl"
            title="Seleccione un impuesto." (change)="this.changeTipoImpuesto()">
            <option id="optionUnidadMedida" *ngFor="let codigo of this.listaCodigos()"
              [ngValue]="codigoImpuesto[codigo]">
              {{ codigo }}
            </option>
          </select>
        </div>
      </div>

      <div class="mb-3 col-md-6" *ngIf="editable==true">
        <div class="col-md-4">
          <label for="codigoControlInput" class="col-form-label">Codigo:</label>
        </div>
        <div class="col-md-8">
          <select type="text" class="form-select" name="tipoID" formControlName="codigoFormControl"
            title="Seleccione un impuesto." disabled="editable" (change)="this.changeTipoImpuesto()">
            <option id="optionUnidadMedida" *ngFor="let codigo of this.listaCodigos()"
              [ngValue]="codigoImpuesto[codigo]">
              {{ codigo }}
            </option>
          </select>
        </div>
      </div>

      <div class="mb-3 col-md-6">
        <div class="col-md-4">
          <label for="leyendaControlInput" class="col-form-label"> Leyenda: </label>
        </div>
        <div class="col-md-8">
          <input id="leyendaControlInput" type="text" class="form-control mb-2 me-sm-2"
            title="Campo obligatorio, debe ingresar un número de leyenda." formControlName="leyendaFormControl"
            placeholder="###" />
        </div>
      </div>
      <!-- siempre es valor fijo y aca se setea cual es el valor-->
      <div class="mb-3 col-md-6">
        <div class="col-md-6">
          <label for="valorControlInput" class="col-form-label"> Valor: </label>
        </div>
        <div class="col-md-8">
          <input id="valorControlInput" type="text" step="0.01" 
            (change)="controlarCaracteres($event)" (keypress)="onlyDecimalNumberKey($event)"
            class="form-control mb-2 me-sm-2" title="Campo obligatorio, debe ingresar un valor."
            formControlName="valorFormControl" placeholder="###" />
            <div id="validation" *ngIf="errorValorFormControl" class="invalid-feedback">
              El valor ingresado no es decimal correcto. Verifique
            </div>           
        </div>
      </div>
      <!-- Zona en la cual se va a cobrar el impuesto.-->
      <div class="mb-3 col-md-6">
        <div class="col-md-6">
          <label for="zonasInput" class="col-form-label"> Zona:</label>
        </div>
        <div class="col-md-8">
          <select type="text" class="form-select" formControlName="zonaFormControl"
            title="Seleccione una base de calculo.">
            <option id="zonasInput" *ngFor="let zona of keysZonas()" [ngValue]="zonaProducto[zona]">
              {{ zona }}
            </option>
          </select>
        </div>
      </div>

      <!-- Fecha Hora Inicio del impuesto-->
      <div class="mb-3 col-md-6">
        <div class="col-md-12">
          <label for="fechaInicioFormControl" class="col-form-label">Fecha y hora inicial: </label>
        </div>
        <div #fechaHoraInicio class="col-md-8">
          <div class="input-group mb-2">
            <input type="date" class="form-control" formControlName="fechaInicioFormControl" placeholder="aaaa-mm-dd"
              name="dp" title="Campo obligatorio." [(ngModel)]="modelFechaInicio" ngbDatepicker #di="ngbDatepicker"
              readonly>
            <div class="input-group-append">
              <button type="button" class="input-group-btn btn btn-secondary" (click)="di.toggle()"><i
                  class="fa fa-calendar" aria-hidden="true"></i></button>
            </div>
          </div>
          <ngb-timepicker [(ngModel)]="timeFechaInicio" [meridian]="true" formControlName="horaInicioFormControl">
          </ngb-timepicker>
        </div>
      </div>

      <!-- Fecha Hora Final del impuesto-->
      <div class="mb-3 col-md-6">
        <div class="col-md-12">
          <label for="fechaFinFormControl" class="col-form-label">Fecha y hora Final: </label>
        </div>
        <div #fechaHoraFin class="col-md-8">
          <div class="input-group mb-2">
            <input type="date" class="form-control" formControlName="fechaFinFormControl" placeholder="aaaa-mm-dd"
              name="dp" title="Campo obligatorio." [(ngModel)]="modelFechaFin" ngbDatepicker #dv="ngbDatepicker"
              (ngModelChange)="fechaDesdeMayorQueHasta()" readonly>
            <div class="input-group-append">
              <button type="button" class="input-group-btn btn btn-secondary" (click)="dv.toggle()"><i
                  class="fa fa-calendar" aria-hidden="true"></i></button>
            </div>
          </div>
          <ngb-timepicker [(ngModel)]="timeFechaFin" [meridian]="true" formControlName="horaFinFormControl">
          </ngb-timepicker>
        </div>
      </div>
      <ngb-alert type="danger" [dismissible]="false" *ngIf="fechaDesdeMayorQueHasta()==true">
        La fecha incial debe ser menor a la fecha final.
      </ngb-alert>
    </div>
  </form>
</div>

<div class="modal-footer d-flex justify-content-center  bg-light">
  <button [disabled]="grabando" class="btn btn-primary m-r2" type="button"
    (click)="grabandoImpuesto(this.editable)">
    <span *ngIf="!grabando">Guardar</span>
    <div *ngIf="grabando" class="d-flex align-items-center">
      <div class="spinner-border text-light spinner-border-sm" role="status"></div>
      <span>Guardando...</span>
    </div>
  </button>
</div>