import { Injectable } from '@angular/core';
import {
  Aborter,
  BlobURL,
  BlockBlobURL,
  ContainerURL,
  StorageURL,
  AnonymousCredential,
  uploadBrowserDataToBlockBlob
} from '@azure/storage-blob'

@Injectable({
  providedIn: 'root'
})
export class AzureBlobUploadService {

  aborter: Aborter = Aborter.none;

  uploadFileToBlobStorage(
    file: File,
    containerUri: string,
    sas: string,
    progress?: (progress: number) => void,
    completed?: (val) => void): Aborter {
    this.aborter = Aborter.none;
    const anonymousCredential = new AnonymousCredential();

    // en el pipeline pueden darse opciones como httpClient, keepAliveOptions, logger, retryOptions, telemetry
    const pipeline = StorageURL.newPipeline(anonymousCredential, {});
    const containerURL = new ContainerURL(`${containerUri}?${sas}`, pipeline);
    const blobName = file.name;
    const blobURL = BlobURL.fromContainerURL(containerURL, blobName);
    const blockBlobURL = BlockBlobURL.fromBlobURL(blobURL);
    uploadBrowserDataToBlockBlob(this.aborter, file, blockBlobURL, {
      maxSingleShotSize: 1024 * 1024 * 4, // Tamaño del bloque a subir
      parallelism: 1, // Cuantas peticiones http se hacen a la vez
      progress: (prog) => {
        progress(Math.round(prog.loadedBytes * 100 / file.size));
      }
    })
      .catch(() => { }) //Esto se ejecuta cuando se aborta
      .then((val) => completed(val));
    return this.aborter;
  }

  abort() {
    this.aborter.abort();
  }
}