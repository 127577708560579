import { Injectable } from '@angular/core';
import { TipoPadron, TipoRegimenARBA } from '../shared/enums/tiposPadron';
import { CargaPadron } from '../shared/models/padrones/CargaPadron';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { of, Observable } from 'rxjs';
import { UrlApi } from '../shared/enums/urlApi';
import { BlobUploadCommonResponse } from '@azure/storage-blob';
import { ErrorModel } from '../shared/models/ErrorModel';
import { Sas } from '../shared/models/padrones/Sas';
import { tap } from 'rxjs/operators';
import { AzureBlobUploadService } from '../shared/azure-blob-upload.service';


@Injectable({ providedIn: 'root' })
export class PadronesService {

    private sas: Sas;

    facturacionBaseUrl = environment.apiServer.facturacionBaseUrl;
    loteCargas: CargaPadron[] = [];

    constructor(private azureBlobUpload: AzureBlobUploadService, private http: HttpClient) { }

    cargarPadron(file: File, tipoPadron: TipoPadron) {
        const carga: CargaPadron = new CargaPadron(file.name, tipoPadron);
        this.loteCargas = this.loteCargas.filter(c => c.tipoPadron !== tipoPadron);
        this.loteCargas.push(carga);
        this.obtenerSas().subscribe((sas: Sas) => {
            let aborter = this.azureBlobUpload.uploadFileToBlobStorage(file, sas.containerUri, sas.sas,
                (pro) => {
                    carga.progress = pro;
                },
                (val: BlobUploadCommonResponse) => {
                    if (val) {
                        carga.progress = 100;
                        let urlTipoPadron = tipoPadron;
                        
                        let url = this.facturacionBaseUrl + UrlApi.padrones + `/${urlTipoPadron}/encolar-padron/` + encodeURIComponent(file.name);
                        this.http.post<number>(url, null).subscribe(
                            (rta) => {
                                carga.finalizar(`El archivo se procesó correctamente, tenga en cuenta que pueden pasar varios minutos hasta que se vea reflejado en el sistema.`);
                            },
                            (error: ErrorModel) => {
                                carga.error = error.description;
                            }
                        );
                    }
                });
            carga.setAborter(aborter);
        });
    }

    cancelarCarga(carga: CargaPadron) {
        carga.cancelarCarga();
    }

    obtenerSas(): Observable<Sas> {
        /* verificar si está vencida antes de pedir uno nuevo */
        if (this.sas) {
            const decodedUri = decodeURIComponent(this.sas.sas);
            const fechaSas = new Date(decodedUri.substr(decodedUri.indexOf('&se=') + 4, 20));
            if (fechaSas > new Date())
                return of(this.sas);
        }
        const url = this.facturacionBaseUrl + UrlApi.facturacionConfiguracion + '/SAS/';
        return this.http.get<Sas>(url).pipe(tap((sas: Sas) => this.sas = sas));
    }
}