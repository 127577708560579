import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PadronesService } from 'src/app/facturacion/padrones.service';
import { EstadoPadron } from 'src/app/shared/enums/estadoPadron';
import { CargaPadron } from 'src/app/shared/models/padrones/CargaPadron';
import { TipoPadron } from 'src/app/shared/enums/tiposPadron';
import { Usuario } from 'src/app/shared/models/despacho/Usuario';
import { NotificacionesService, TipoNotificacionesVisualizadas } from 'src/app/abm/notificacion/notificaciones.service';
import { UsuarioService } from 'src/app/account/usuario.service';
import { NotificacionUsuario } from 'src/app/shared/models/notificaciones/NotificacionUsuario';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificacionUsuarioEncabezado } from 'src/app/shared/models/notificaciones/NotificacionUsuarioEncabezado';
import { NotificacionDetalleComponent } from '../notificacion-detalle/notificacion-detalle.component';
@Component({
  selector: 'app-notificaciones-usuario',
  templateUrl: './notificaciones-usuario.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./notificaciones-usuario.component.css']
})
export class NotificacionesUsuarioComponent implements OnInit {

  TipoNotificacionesVisualizadas = TipoNotificacionesVisualizadas;

  throttle = 500;
  distance = 2;
  page = 1;
  pageSize = 20;

  notificaciones: NotificacionUsuarioEncabezado[];
  obteniendoNotificaciones: boolean;
  obteniendoNotificacionesScroll: boolean;
  notificacionesVisualizadas: TipoNotificacionesVisualizadas = TipoNotificacionesVisualizadas.Todas;

  constructor(  private notificacionesService: NotificacionesService,
                private modalService: NgbModal, 
                public activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.obtenerNotificaciones(this.page, false);
  }

  onUsuarioSeleccionoFiltrarNotificaciones(tipoNotificacionesMostradas: TipoNotificacionesVisualizadas){
    this.notificacionesVisualizadas = tipoNotificacionesMostradas;
    this.page = 1;
    this.obtenerNotificaciones(this.page, false);
  }

  obtenerNotificaciones(page: number, isScroll: boolean){
    if( !isScroll ){
      this.obteniendoNotificaciones = true;
    }else{
      this.obteniendoNotificacionesScroll = true;
    }
    

    this.notificacionesService.getNotificacionesUsuario(page, this.pageSize, this.notificacionesVisualizadas)
      .subscribe(
        (notificaciones: NotificacionUsuarioEncabezado[]) => {        
          if( !isScroll ){
            this.notificaciones = notificaciones;
            this.obteniendoNotificaciones = false;
          }else{
            this.notificaciones = this.notificaciones.concat(notificaciones);
            this.obteniendoNotificacionesScroll = false;
          }
        },
        (error: ErrorModel) => {
          console.log(error);
          if( !isScroll ){
            this.obteniendoNotificaciones = false;
          }else{
            this.obteniendoNotificacionesScroll = false;
          }
        }
      );
  }

  usuarioSeleccionoNotificacion(n: NotificacionUsuarioEncabezado){
    let  ahora = new Date();    
    n.fechaHoraLectura = this.formatDate(ahora.getFullYear(), ahora.getMonth(), ahora.getDate(), ahora.getHours, ahora.getMinutes);    

    const modalRef = this.modalService.open(NotificacionDetalleComponent, { size: 'lg', windowClass: 'modalNotificacionDetalle', backdrop: 'static' });
    modalRef.componentInstance.encabezado = n;
  }

  onScrollNotificaciones(){
    this.obtenerNotificaciones(++this.page, true);
  }

  formatDate(year, month, day, hour, minute): string {
    return year + '-' + month.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + '-' + day.toLocaleString(undefined, { minimumIntegerDigits: 2 })
      + 'T' + hour.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + minute.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':00';
  }

}
