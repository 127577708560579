<nav class="navbar navbar-light bg-light navbar-expand-lg" *ngIf="tipoNuevoAerovale == null">
  <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <form class="form-inline my-2 my-lg-0">
      <input id="busquedaFormControl" type="text" class="form-control" [formControl]="busquedaFormControl"
        (input)="filtrarAerovales()" placeholder="Cliente" />
    </form>
    <ul class="navbar-nav me-auto">
      <!-- estados -->
      <!-- tipos button -->
      <li class="nav-item">
        <div ngbDropdown autoClose="outside">
          <button class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2" id="dropdownForm1"
            ngbDropdownToggle>Tipos</button>
          <div ngbDropdownMenu aria-labelledby="dropdownForm1">
            <div class="px-4 py-3">
              <div *ngFor="let tipo of tipos">
                <div class="form-check">
                  <input type="checkbox" class="form-check-input" id="{{ 'dropdownCheck' + tipo.valor}}"
                    [(ngModel)]="tipo.seleccionado" (change)="filtrarAerovales()">
                  <label class="form-check-label text-nowrap" for="{{ 'dropdownCheck' + tipo.valor}}">
                    {{ tipo.descripcion }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

      </li>

           <!-- desde -->
           <li class="nav-item me-2">
            <div class="input-group">

              <input type="date" [ngModel]="model" class="fechaFormControlDesdeHasta" placeholder="aaaa-mm-dd"
                (ngModelChange)="model = $event" name="dt" ngbDatepicker #d="ngbDatepicker" value="model"
                class="form-control form-control-rounded" #searchDate required readonly>

              <div class="input-group-append">
                <button id="botonDesde" type="button" class="input-group-btn btn btn-secondary" (click)="d.toggle()">
                  <i class="fa fa-calendar" aria-hidden="true"></i></button>
              </div>
            </div>
          </li>
          <!-- hasta -->
          <li class="nav-item">
            <div class="input-group">
              <input type="date" class="fechaFormControlDesdeHasta" [ngModel]="model1" placeholder="aaaa-mm-dd" name="dp"
                value="model1" (ngModelChange)="model1 = $event" class="form-control form-control-rounded" ngbDatepicker
                #h="ngbDatepicker" #searchDate required readonly>

              <div class="input-group-append">
                <button id="botonHasta" type="button" class="input-group-btn btn btn-secondary" (click)="h.toggle()"><i
                    class="fa fa-calendar" aria-hidden="true"></i></button>
              </div>
            </div>
          <li class="nav-item">

            <button  class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2" type="submit"><i
                class="fa fa-search" aria-hidden="true"></i></button>

          </li>

    </ul>
  </div>
  </nav>
<div class="row">
    <div class="card-body">
      <table  class="table table-striped table-hover" [formGroup]="tablaComprobantes">
        <thead>
          <td class="text-center"><b>N Remito</b></td>
          <td class="text-center"><b>Fecha</b></td>
          <td class="text-center"><b>Cliente</b></td>
          <td class="text-center"><b>Importe</b></td>
          <td class="text-center"><b>Accion</b></td>


       </thead>
        <tbody>
          <tr formArrayName="surtidores" *ngFor="let s of this.surtidores let i = index">
            <td class="text-center">{{  s.codigo }}</td>
            <td class="text-center">{{ s.nombre }}</td>
            <td class="text-center"> Aerolineas Argentinas </td>
            <td class="text-center">$100</td>
            <td class="text-center">
              <button type="button" class="btn btn-primary btn-sm me-1"><i class="fa fa-pencil fa-lg" aria-hidden="true" ngbTooltip="Editar"></i></button>
            </td>
          </tr>
        </tbody>
      </table>

    </div>

  </div>
