import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gestion-precios',
  templateUrl: './gestion-precios.component.html',
  styleUrls: ['./gestion-precios.component.scss']
})
export class GestionPreciosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
