import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TipoPico } from 'src/app/shared/enums/tipoPico';
import { Pico } from 'src/app/shared/models/despacho/Pico';
import { Abastecedora } from 'src/app/shared/models/stock/Abastecedora';

@Component({
  selector: 'app-visor-picos',
  templateUrl: './visor-picos.component.html',
  styleUrls: ['./visor-picos.component.css']
})
export class VisorPicosComponent implements OnInit {

  @Input() nombreContenedor: string;
  @Input() picos: Pico[];

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
    
  }

  obtenerNombrePico(tipo: number){
    return TipoPico[tipo];
  }

}
