import { Contenedor } from './Contenedor';
import { ContenedorTanque } from './ContenedorTanque';
import { ContenedorAbastecedora } from './ContenedorAbastecedora';
import { Pico } from '../despacho/Pico';

export class DetalleStockPorContenedor {
  contenedor: Contenedor;
  aforador: number;
  stock: number;
  pico: Pico[];
  warning: string;
  stock15: number;
  }
