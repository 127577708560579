import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Abastecedora } from 'src/app/shared/models/stock/Abastecedora';
import { MessageService } from 'src/app/shell/message.service';
import { UntypedFormArray, FormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { UsuarioService } from 'src/app/account/usuario.service';
import { Aeroplanta } from 'src/app/shared/models/despacho/Aeroplanta';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { Guid } from 'guid-typescript';
import { FacturaCierreCaja } from 'src/app/shared/models/cierreTurno/FacturaCierreCaja';
import { ComprobanteService } from 'src/app/facturacion/comprobante.service';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { ModalConfirmComponent } from 'src/app/shared/generic-modals/modal-confirm.component';
import { DocumentosCierreCaja } from 'src/app/shared/models/cierreTurno/DocumentosCierreCaja';
import { PagoContado } from 'src/app/shared/models/facturacion/PagoContado';
import { VisorComprobanteComponent } from 'src/app/shared/visor-comprobante/visor-comprobante.component';
import { Comprobante } from 'src/app/shared/models/facturacion/Comprobante';
import { DecimalPipe } from '@angular/common';
import { SharedService } from 'src/app/shared/shared.service';


@Component({
  selector: 'app-cierre-caja-cierre-turno',
  templateUrl: './cierre-caja-cierre-turno.component.html',
  styleUrls: ['./cierre-caja-cierre-turno.component.css']
})
export class CierreCajaCierreTurnoComponent implements OnInit {

  efectivoForm;

  abastecedoras: Abastecedora[] = [];
  tablaAbastecedora: UntypedFormGroup;
  aeroplanta: Aeroplanta;
  messageAbastecedoras: string = "C a r g a n d o . . ."

  facturasContado: FacturaCierreCaja[] = [];
  facturasSeleccionadas: FacturaCierreCaja[] = [];

  obteniendoAbastecedoras: boolean = false;

  obteniendoFacturas: boolean = false;
  eliminando: boolean = false;
  change$: Subscription;
  guardando: boolean = false;
  comprobante: Comprobante;

  documentos: DocumentosCierreCaja;
  totalCuponesFactura: number = 0;
  totalEfectivoTeoricoFacturas: number = 0;
  diferenciaDeCaja: number = 0;

  constructor(private messageService: MessageService,
    private modalService: NgbModal,
    private facturacionService: ComprobanteService,
    private usuarioService: UsuarioService,
    private spinner: NgxSpinnerService,
    private activeModal: NgbModal,
    private decimalPipe: DecimalPipe,
    sharedService: SharedService) {
      this.change$ = sharedService.aeroplantaSeleccionada.subscribe(_=>{ 
        this.recargarPagina()
      });
  }

  crearForm(){
    this.efectivoForm = new UntypedFormGroup({
      totalIngresadoEfectivo: new UntypedFormControl(),
      facturasIngresadoEfectivoForm: new UntypedFormArray([])
    });
  }

  ngOnInit() {
    this.crearForm();

    this.documentos = new DocumentosCierreCaja();
    this.totalIngresadoEfectivo.setValue(0);
    this.totalEfectivoTeoricoFacturas = 0;
    this.diferenciaDeCaja = 0;
    this.abastecedoras = [];
    this.getFacturasContado();
  }

  ngOnDestroy() {
    this.change$.unsubscribe();
  }

  getFacturasContado() {
    this.obteniendoFacturas = true;
    this.spinner.show('spinnerLista');
    this.facturacionService.getFacturasContado(this.usuarioService.getAeroplantaActual().codigoAeroplanta)
      .subscribe(result => {
        this.facturasContado = result;
        this.spinner.hide('spinnerLista');
        this.obteniendoFacturas = false;
        if (this.facturasContado != null) { this.iniciarFormFacturasEfectivo(); }
      }, (err: ErrorModel) => {
        this.spinner.hide('spinnerLista');
        this.obteniendoFacturas = false;
      });
  }

  iniciarFormFacturasEfectivo(){
    this.efectivoForm.controls.facturasIngresadoEfectivo = new UntypedFormArray([]);

    for (let f of this.facturasContado) {
      var subForm =  new UntypedFormGroup({
        efectivo: new UntypedFormControl(),
        idFactura: new UntypedFormControl(f.id)
      });
      this.facturasIngresadoEfectivoForm.push(subForm);
    }
  }  

  calcularTotalFacturas(){
    var totalEfectivo = 0;

    for (let i = 0; i < this.facturasIngresadoEfectivoForm.controls.length; i++) {
      var efectivoFactura = this.facturasIngresadoEfectivoForm.controls[i].get("efectivo").value;

      if(efectivoFactura != null){
        totalEfectivo += Number(efectivoFactura);
      }      
    }

    if(isNaN(totalEfectivo)){
      this.totalIngresadoEfectivo.setValue("0");
    }else{
      var result = this.decimalPipe.transform(totalEfectivo, '1.2-2');
      this.totalIngresadoEfectivo.setValue(result);
    }

    this.calcularDiferencia();
  }

  agregarFactura(i: number, event) {
    let y: number = 0;
    if (event.target.checked === true) {
      this.facturasSeleccionadas.push(this.facturasContado[i]);
      this.facturasContado[i].checked = true;
      this.totalEfectivoTeoricoFacturas += this.facturasContado[i].total - this.totalCupones(this.facturasContado[i].pago);
      console.log(this.totalEfectivoTeoricoFacturas);
      this.calcularDiferencia();
    }
    if (event.target.checked === false) {
      this.facturasIngresadoEfectivoForm.controls[i].get("efectivo").setValue("0")      

      y = this.facturasSeleccionadas.indexOf(this.facturasContado[i]);
      this.facturasSeleccionadas.splice(y, 1);
      this.facturasContado[i].checked = false;
      this.totalEfectivoTeoricoFacturas = this.totalEfectivoTeoricoFacturas - (this.facturasContado[i].total - this.totalCupones(this.facturasContado[i].pago));
      console.log(this.totalEfectivoTeoricoFacturas);      

      this.calcularTotalFacturas();
    }
    
  }

  calcularDiferencia() {
    var totalEfectivo = this.parsearCadenaValorCurrency(this.totalIngresadoEfectivo.value);
    var totalFacturas = this.totalEfectivoTeoricoFacturas;
    this.diferenciaDeCaja = totalEfectivo  - totalFacturas;
    
  }

  totalCupones(pago: PagoContado[]): number {
    let tot: number = 0;
    for (let p of pago) {
      tot += p.totalCupones;
    }
    return tot;
  }

  totalEfectivo(totalFactura, pago: PagoContado[]): number {
    return totalFactura - this.totalCupones(pago) ;
  }

  recargarPagina() {
    this.ngOnInit()
  }

  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46;
  }

  parsearCadenaValorCurrency(cadena){
    return parseFloat(cadena.toString().replaceAll(".","").replace(",","."));
  }

  cerrarCaja() {
    if (this.facturasSeleccionadas.length > 0) {
      this.guardando = true;
      this.documentos.facturasIds = [];
      this.documentos.codigoAeroplanta = this.usuarioService.getAeroplantaActual().codigoAeroplanta;
      for (let fact of this.facturasSeleccionadas) {
        this.documentos.facturasIds.push(fact.id);
      }
      this.documentos.totalIngresoEfectivo = this.parsearCadenaValorCurrency(this.totalIngresadoEfectivo.value);
      this.documentos.responsable = this.usuarioService.getNombresCompletosUsuario();
      let modalRef = this.activeModal.open(ModalConfirmComponent, { centered: true, backdrop: 'static' });
      modalRef.componentInstance.mensaje = 'Hay una diferencia de: $' + this.diferenciaDeCaja.toFixed(2) + '. ¿Desea continuar con el cierre?';
      modalRef.componentInstance.titulo = 'Ciere de caja';
      modalRef.result.then(() => {
        this.facturacionService.postCierreCaja(this.documentos)
          .subscribe(result => {
            this.messageService.showSuccessMessage('La caja se cerro correctamente.');            
            this.guardando = false;
            this.facturasSeleccionadas.length = 0;
            this.ngOnInit();
          }, (err: ErrorModel) => {
            this.guardando = false;
            this.messageService.showErrorMessage('Hubo un error al cerrar la caja, por favor reintente');
          });
      }, (err: ErrorModel) => {
        this.guardando = false;
        this.messageService.showErrorMessage('Se canceló el cierre de turno');
      });
    } else {
      this.messageService.showErrorMessage('Debe seleccionar facturas para cerrar la caja.')
    }
  }

  mostrarFacrtura(factura: FacturaCierreCaja) {
    factura.buscarComprobante = true;
    this.facturacionService.obtenerComprobantePorId(factura.id)
      .subscribe(result => {
        this.comprobante = result;
        factura.buscarComprobante = false;
        if (this.comprobante != null) {
          let modalRef = this.activeModal.open(VisorComprobanteComponent, { centered: true, size: 'lg' });
          modalRef.componentInstance.facturaObtenida = this.comprobante;
        }else{
          factura.buscarComprobante = false;
          this.messageService.showErrorMessage('Error al obtener la factura');
        }
      }, (err: ErrorModel) =>{
        factura.buscarComprobante = false;
        this.messageService.showErrorMessage('Error al obtener la factura');
      });
  }

  get totalIngresadoEfectivo() {
    return this.efectivoForm.get('totalIngresadoEfectivo');
  }

  get facturasIngresadoEfectivoForm(): UntypedFormArray {
    return this.efectivoForm.controls.facturasIngresadoEfectivoForm as UntypedFormArray;
  }

  onlyDecimalPositivo(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46;
  }

}
