import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';
import { Shell } from '../shell/shell.service';
import { authGuard } from '../core/authentication/auth.guard';
import { TipoUsuario } from '../shared/enums/tipoUsuario';
import { StockIndexComponent } from './stock-index/stock-index.component';
import { TableroComponent } from './tableros/tablero/tablero.component';
import { MovimientosComponent } from './tableros/movimientos/movimientos.component';
import { GruposTanquesComponent } from './configuraciones/grupos-tanques/grupos-tanques.component';
import { AbastecedorasComponent } from './configuraciones/abastecedoras/abastecedoras.component';
import { TanquesComponent } from './configuraciones/tanques/tanques.component';
import { SurtidoresComponent } from './configuraciones/surtidores/surtidores.component';
import { MedicionesComponent } from './tableros/mediciones/mediciones.component';
import { AeroespecialidadesComponent } from './tableros/aeroespecialidades/aeroespecialidades.component';
import { CaneriasComponent} from './configuraciones/canerias/canerias.component';
import { EquiposFijosComponent } from './configuraciones/equipos-fijos/equipos-fijos.component';
import { SistemasSeguridadComponent } from './configuraciones/sistemas-seguridad/sistemas-seguridad.component';
import { PedidosComponent } from './listados/pedidos/pedidos.component';
import { ConfiguracionesIndexComponent } from './configuraciones/configuraciones-index/configuraciones-index.component';
import { AuthService } from '../core/authentication/auth.service';
import { AeroplantasListaComponent } from './configuraciones/aeroplantas/aeroplantas-lista/aeroplantas-lista.component';
import { TablerosIndexComponent } from './tableros/tableros-index/tableros-index.component';
import { ListadosIndexComponent } from './listados/listados-index/listados-index.component';



const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'stock', component: StockIndexComponent, canActivate: [authGuard], data: { roles: [TipoUsuario.OPERADOR, TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.APROBADORBYR, TipoUsuario.SOPORTE] }, children: [
        { path: 'tableros', component: TablerosIndexComponent, canActivate: [authGuard], 
        data : { roles: [TipoUsuario.SUPERVISOR, TipoUsuario.OPERADOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.COMERCIAL, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.APROBADORBYR, TipoUsuario.SOPORTE] },
        children: [
          {
            path: 'dashboard', component: TableroComponent, canActivate: [authGuard], data: { roles: [TipoUsuario.OPERADOR, TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.APROBADORBYR, TipoUsuario.SOPORTE]},
          },
          {
            path: 'aeroespecialidades', component: AeroespecialidadesComponent, canActivate: [authGuard], data: { roles: [TipoUsuario.OPERADOR, TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.APROBADORBYR, TipoUsuario.SOPORTE] },
          },
          {
            path: 'movimientos', component: MovimientosComponent, canActivate: [authGuard], data: { roles: [TipoUsuario.OPERADOR, TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.SOPORTE] },
          },
          {
            path: 'mediciones', component: MedicionesComponent, canActivate: [authGuard], data: { roles: [TipoUsuario.OPERADOR, TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.SOPORTE] }        
          }]
        },
        { path: 'listados', component: ListadosIndexComponent, canActivate: [authGuard], 
        data : { roles: [TipoUsuario.SUPERVISOR, TipoUsuario.OPERADOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.COMERCIAL, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.SOPORTE] },
        children: [
          {
            path: 'pedidos', component: PedidosComponent, canActivate: [authGuard], data: { roles: [TipoUsuario.OPERADOR, TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.SOPORTE] }
          }]
        },
        { path: 'configuracion', component: ConfiguracionesIndexComponent, canActivate: [authGuard], 
          data : { roles: [TipoUsuario.SUPERVISOR, TipoUsuario.OPERADOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.COMERCIAL, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.SOPORTE] },
          children: [
            {
              path: 'grupos-tanques', component: GruposTanquesComponent, canActivate: [authGuard], data: { roles: [TipoUsuario.OPERADOR, TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.SOPORTE] }
            },
            {
              path: 'abastecedoras', component: AbastecedorasComponent, canActivate: [authGuard], data: { roles: [TipoUsuario.OPERADOR, TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.SOPORTE] }
            },
            {
              path: 'surtidores', component: SurtidoresComponent, canActivate: [authGuard], data: { roles: [TipoUsuario.OPERADOR, TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.SOPORTE] }
            },
            {
              path: 'tanques', component: TanquesComponent, canActivate: [authGuard], data: { roles: [TipoUsuario.OPERADOR, TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.SOPORTE] }
            },
            {
              path: 'dispenser', component: SurtidoresComponent, canActivate: [authGuard], data: { roles: [TipoUsuario.OPERADOR, TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.SOPORTE] }
            },
            {
              path: 'canerias', component: CaneriasComponent, canActivate: [authGuard], data: { roles: [TipoUsuario.OPERADOR, TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.SOPORTE] }
            },
            {
              path: 'equipos-fijos', component: EquiposFijosComponent, canActivate: [authGuard], data: { roles: [TipoUsuario.OPERADOR, TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.SOPORTE] }
            },
            {
              path: 'sistemas-seguridad', component: SistemasSeguridadComponent, canActivate: [authGuard], data: { roles: [TipoUsuario.OPERADOR, TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.SOPORTE] }
            },
            {
              path: 'aeroplantas', component: AeroplantasListaComponent, canActivate: [authGuard], data: { roles: [TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.JEFEDEAEROPLANTA] }
            }
          ]
        },
      ]
    },
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class StockRoutingModule { 
  constructor(
    private router:Router,
    private authService: AuthService) {
    this.router.events.subscribe((event) => {
      if(event['url'] && (event['url'] == '/stock' || event['url'] == '/stock/tableros')) {
        this.router.navigate(['/stock/tableros/dashboard'])
      } else if(event['url'] && event['url'] == '/stock/listados') {
        this.router.navigate(['/stock/listados/pedidos'])
      } else if(event['url'] && event['url'] == '/stock/configuracion') {
        this.router.navigate(['/stock/configuracion/grupos-tanques'])
      }
    });
  }
}
