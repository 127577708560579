import { Component, OnInit } from '@angular/core';
import { UsuarioService } from 'src/app/account/usuario.service';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { MessageService } from 'src/app/shell/message.service';
import { PreciosService } from '../precios.service';

@Component({
  selector: 'app-precios-index',
  templateUrl: './precios-index.component.html',
  styleUrls: ['./precios-index.component.css']
})
export class PreciosIndexComponent implements OnInit {

  TipoUsuario = TipoUsuario;
  cargandoNiveles: boolean;
  
  constructor(    
    private preciosService: PreciosService,
    private usuarioService: UsuarioService,
    private messageService: MessageService,
    private authService: AuthService
    ) { }

  ngOnInit() {
    
    this.cargandoNiveles = true;

    this.preciosService.getNivelesUsuario(this.usuarioService.getUsername())
    .subscribe(result => {
      //this.messageService.showSuccessLongMessage("Se cargaron los niveles del usuario");
      console.log("Se cargaron los niveles del usuario");
      this.cargandoNiveles = false;
    },
    (err: ErrorModel) => { 
      //this.messageService.showErrorMessage("Error al cargar los niveles del usuario");
      console.log("Error al cargar los niveles del usuario");
      this.cargandoNiveles = false;
    });
  }

  hasRole(...roles: TipoUsuario[]) {
    return this.authService.hasRole(...roles);
  }

}
