<nav class="navbar navbar-light bg-light navbar-expand-lg">
    <form class="form-inline flex-fill" id="formularioFiltros">
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <ul class="navbar-nav ms-2 me-auto">
            <!--buscador -->
            <li class="nav-item me-2">
                <div class="nav-item">                
                    <input id="busquedaFormControl" type="text" class="form-control" placeholder="Buscador"
                        [formControl]="busquedaFormControl" (input)="filtrarMovimientos()" />
                </div>
            </li>

            <!--PRODUCTO-->
            <li class="nav-item">
                <div ngbDropdown autoClose="outside">
                    <button [disabled]="cargandoProductos" class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2" id="dropdownProductos"
                        ngbDropdownToggle>Productos
                    </button>
                    <div *ngIf="!cargandoProductos" ngbDropdownMenu aria-labelledby="dropdownProductos">
                        <div class="px-4 py-3">
                            <div *ngFor="let p of productosParaCheckear;let i = index">
                                <div class="form-check">
                                    <input type="checkbox" name="productos+{{i}}" class="form-check-input"
                                        id="{{ 'dropdownCheck' + p.nombre}}" [(ngModel)]="p.seleccionado"
                                        (change)="filtrarMovimientos($event)">
                                    <label class="form-check-label text-nowrap" for="{{ 'dropdownCheck' + p.nombre}}">
                                        {{p.nombre}}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <loading-text-spinner *ngIf="cargandoProductos" 
                        message="Obteniendo productos..."color="primary">
                    </loading-text-spinner>
                </div>
            </li>

            <li class="nav-item me-2">
                <div class="input-group">                                 
                    <input type="date" [ngModel]="modelDesde" class="fechaFormControlDesdeHasta" placeholder="aaaa-mm-dd"
                        (ngModelChange)="modelDesde = $event" name="dt" ngbDatepicker #d="ngbDatepicker" value="model"
                        class="form-control form-control-rounded" #id="ngbDatepicker" #searchDate required readonly>

                    <div class="input-group-append">
                        <button  id="botonDesde" type="button" class="input-group-btn btn btn-secondary" (click)="toggleDatepicker(d)">
                            <i class="fa fa-calendar" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </li>

            <li class="nav-item me-2">
                <div class="input-group">                                 
                    <input type="date" [ngModel]="modelHasta" class="fechaFormControlDesdeHasta" placeholder="aaaa-mm-dd"
                        (ngModelChange)="modelHasta = $event" name="dt" ngbDatepicker #h="ngbDatepicker" value="model"
                        class="form-control form-control-rounded" #id="ngbDatepicker" #searchDate required readonly>

                    <div class="input-group-append">
                        <button id="botonDesde" type="button" class="input-group-btn btn btn-secondary" (click)="toggleDatepicker(h)">
                            <i class="fa fa-calendar" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </li>

            <li class="nav-item me-2">
                <button 
                    [disabled]="bloquerBusqueda"
                    class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2"
                    (click)="filtrarMovimientosPorFecha()"><i class="fa fa-search" aria-hidden="true"></i>
                </button>
            </li>    
            
            <li class="nav-item">
                <div class="input-group">                                 
                    <button class="btn btn-primary"
                        [disabled]="obteniendoPedidos || cargandoProductos" (click)="abmPedidos(null)">Nuevo Pedido
                    </button>
                </div>
            </li>
        </ul>
    </form>
</nav>

<div class="col container-fluid">    
    <div class="spinner-container" *ngIf="obteniendoPedidos">
        <ngx-spinner name="spinnerLista" bdOpacity=0.3bd
            Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
            <p style="color: white"> C a r g a n d o . . . </p>
        </ngx-spinner>
    </div>

    <ngb-alert [dismissible]="false"
        *ngIf="!obteniendoPedidos && pedidosFiltrados.length==0">
        No existen movimientos para la busqueda seleccionada
    </ngb-alert>
    
    <table class="table table-striped" *ngIf="!obteniendoPedidos && pedidosFiltrados.length > 0">
        <thead class="text-center letra">                
            <th>Fecha</th>
            <th>Producto</th>
            <th>Cantidad</th>
            <th>Ult. Estado</th>
            <th>Acción</th>
        </thead>        
        <tbody>                
            <tr *ngFor="let p of pedidosFiltrados; let i=index">          
                <td class="text-center">{{p.fechaHora | date:'dd/MM/yyyy hh:mm a'}}</td>      
                <td class="text-center">{{p.producto.codigoProducto}} - {{p.producto.nombreProducto}}</td>
                <td class="text-center">{{p.cantidad | number: '1.0-0'}}</td>
                <td class="text-center">
                    <ng-container *ngIf="p.ultimoEstado!=null">
                        <select type="text" class="form-select" id="estadoServicio"
                            [ngClass]="{'verde':p.ultimoEstado?.estado==EstadoPedido.Abierto, 'rojo':p.ultimoEstado?.estado==EstadoPedido.Cerrado}"
                            (change)="cambiarEstado(p, i)" [(ngModel)]="p.ultimoEstado.estado" >
                            <option id="estadoServicio" *ngFor="let estado of keysEstadoPedido()" [ngValue]="EstadoPedido[estado]">
                                {{ estado }}
                            </option>
                        </select>
                        <div *ngIf="pedidoSeleccionado==i && cambiandoEstado">
                            <div class="spinner-border text-primary spinner-border-sm" role="status"></div>                            
                            <span>{{msgEstado}}...</span>
                        </div>
                    </ng-container>
                    <div *ngIf="p.ultimoEstado==null" class="estilo alert-warning">Sin Estado.</div>
                </td>
        
                <td class="text-center">                 
                    <button  
                        ngbTooltip="Editar pedido combustible"
                        (click)="abmPedidos(p)"    
                        [disabled]="obteniendoPedidos || cargandoProductos"                
                        class="btn btn-md btn-warning">
                        <span class="fa fa-pencil"></span>
                    </button>                   
                </td>
            </tr>             
        </tbody>                                
    </table>
</div>