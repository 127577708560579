import { Component, OnInit } from '@angular/core';
import { NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { MessageService } from 'src/app/shell/message.service';
import { FacturacionService } from '../facturacion.service';

@Component({
  selector: 'app-simular-facturacion',
  templateUrl: './simular-facturacion.component.html',
  styleUrls: ['./simular-facturacion.component.css']
})
export class SimularFacturacionComponent implements OnInit {

  modelFecha: NgbDateStruct;
  fecha: Date;
  procesandoDatos: boolean = false;
  mensaje: string = null;

  constructor(
    private calendar: NgbCalendar,
    private facturacionService: FacturacionService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.modelFecha = this.calendar.getToday();
    this.fecha = new Date(this.modelFecha.year, this.modelFecha.month - 1, this.modelFecha.day);
  }

  consultarFacturacionSimulada(){
    this.procesandoDatos = true;

    this.fecha = new Date(this.modelFecha.year, this.modelFecha.month - 1, this.modelFecha.day);
    let desde: string = this.formatDate(this.fecha.getUTCFullYear(), this.fecha.getMonth() + 1, this.fecha.getDate(), 23, 0);

    this.facturacionService.consultarFacturacionSimulada(desde)
    .subscribe(listaResultado => {
      if (listaResultado != null) {
        this.facturacionService.emitirFacturacionSimulada(desde, listaResultado)
        .subscribe(listaResultado => {
          if (listaResultado != null) {
            this.messageService.showSuccessMessage("Se emitio simulación de facturación.");
            this.procesandoDatos = false;
            this.mensaje = "Se ha finalizado el proceso.";
          }else{
            this.messageService.showErrorMessage("Se recibio respuesta nula en consulta facturacion.");
            this.procesandoDatos = false;
          }          
        }, (error) => {
          this.messageService.showErrorMessage(error.message + ": " + error.description);
          this.procesandoDatos = false;
        });
      }else{
        this.messageService.showErrorMessage("Se recibio respuesta nula en consulta facturacion.");
        this.procesandoDatos = false;
      }      
    }, (error) => {
      this.messageService.showErrorMessage(error.message + ": " + error.description);
      this.procesandoDatos = false;
    });
  }

  formatDate(year, month, day, hour, minute): string {
    return year + '-' + month.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + '-' + day.toLocaleString(undefined, { minimumIntegerDigits: 2 })
      + 'T' + hour.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + minute.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':00';
  }
}
