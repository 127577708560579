<div class="modal-header">
  <h5 class="modal-title justify-content-center">Busqueda de precios validos por fecha.</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="this.modal.close()">
    
  </button>
</div>
<div class="modal-body">
  
    <div class="mb-3 row ms-4 me-4">

        <form class="form-inline flex-fill" id="formularioFiltrosPorFecha" class="col-md-5">
        <div class="row">
          <div [formGroup]="searchForm" class="col-md-6">
            <input  id="busquedaPorFechaFormControl" type="text" class="form-control input-sm" placeholder="Búsqueda"
              formControlName="busqueda" (input)="filtrarPrecios()" placeholder="Buscar"/>              
          </div>          

          <div ngbDropdown autoClose="outside" class="col-md-6">
            <button class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2" id="dropdownFormNivel"
              ngbDropdownToggle> Nivel </button>
            <div ngbDropdownMenu aria-labelledby="dropdownForm2">
              <div class="px-4 py-3">
                <div *ngFor="let nivel of niveles">
                  <div class="form-check">
                    <input type="checkbox" class="custom-control-input" id="{{ 'dropdownCheckNivel' + nivel.nombre}}" 
                      [(ngModel)]="nivel.seleccionado" name="{{nivel.nombre}}"
                      (change)="filtrarPrecios()" >
                    <label class="custom-control-label text-nowrap" style="text-align: left;" for="{{ 'dropdownCheckNivel' + nivel.nombre}}">
                      {{ nivel.nombre}}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </form>

        <div class="col-md-7">
          <div class="input-group">

            <input type="date" name="modelFecha" [(ngModel)]="modelFecha" placeholder="aaaa-mm-dd" name="dt" ngbDatepicker #d="ngbDatepicker"
              ngbTooltip="Seleccione una fecha" 
              #searchDate readonly>

            <div class="input-group-append">
              <button id="botonFecha" type="button" class="input-group-btn btn btn-secondary" (click)="d.toggle()">
                <i class="fa fa-calendar" aria-hidden="true"></i></button>
            </div>

            <div class="me-4 ms-4">
              <ngb-timepicker  name="modelHora" [spinners]="false" [(ngModel)]="modelHora"></ngb-timepicker>
            </div>
          
            <button class="" type="button" class="btn btn-outline-primary" (click)="obtenerPrecios()" [disabled]="esperandoCarga">Obtener precios</button>
          </div>              
        </div>
    </div>        
  
  
<div class="col col-md-12 mb-4">
  <div class="spinner-container" *ngIf="esperandoCarga">
    <ngx-spinner name="spinnerGrafico" bdOpacity=0.3bd
      Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
      <p style="color: white">{{messagePrecios}}</p>
    </ngx-spinner>
  </div>
  <div id="tablaDetalleFecha" class="tabla-precios" *ngIf="!esperandoCarga">
    <table id="tablaFecha" class="table table-sm table-hover">
      <thead class="text-center">
        <tr>
          <th scope="col"> Nivel </th>
          <th scope="col"> Lote </th>
          <th scope="col"> Codigo </th>
          <th scope="col"> Producto </th>
          <th scope="col"> Desde-Hasta</th>
          <th scope="col"> Moneda </th>
          <th scope="col"> Cliente </th>
          <th scope="col"> Tipo Venta </th>
          <th scope="col"> Precio </th>
          <th scope="col"> Entrega </th>
          <th scope="col"> Area Negociación </th>
          <th scope="col"> Segmento </th>
          <th scope="col"> Aeroplanta</th>
        </tr>
      </thead>
      <tbody class="text-center">
        <tr *ngFor="let p of preciosFiltrados; let i = index">

          <td>{{ preciosFiltrados[i].nivelPrecio }}</td>

          <td>L{{ preciosFiltrados[i].numeroLote }}</td>

          <td *ngIf="preciosFiltrados[i].producto.codigoProducto != null">
            {{preciosFiltrados[i].producto.codigoProducto}} </td>
          <td *ngIf="preciosFiltrados[i].producto.codigoProducto == null"> -- </td>

          <td *ngIf="preciosFiltrados[i].producto.nombreProducto != null">
            {{preciosFiltrados[i].producto.nombreProducto}} </td>
          <td *ngIf="preciosFiltrados[i].producto.nombreProducto == null"> -- </td>

          <td *ngIf="preciosFiltrados[i].fechaDesde != null && preciosFiltrados[i].fechaHasta != null">
            {{preciosFiltrados[i].fechaDesde | date: "dd'/'MM'/'yy"}} -
            {{preciosFiltrados[i].fechaHasta | date: "dd'/'MM'/'yy"}}</td>

          <td *ngIf="preciosFiltrados[i].fechaHasta == null && preciosFiltrados[i].fechaDesde"> -- </td>

          <td *ngIf="preciosFiltrados[i].moneda != null"> {{preciosFiltrados[i].moneda.nombre}} </td>
          <td *ngIf="preciosFiltrados[i].moneda == null"> -- </td>

          <td *ngIf="preciosFiltrados[i].cliente != null"> {{preciosFiltrados[i].cliente.razonSocial}}</td>
          <td *ngIf="preciosFiltrados[i].cliente == null"> -- </td>

          <td *ngIf="preciosFiltrados[i].tipoVenta == null || preciosFiltrados[i].tipoVenta == 0"> - </td>
          <td *ngIf="preciosFiltrados[i].tipoVenta != null && preciosFiltrados[i].tipoVenta == 2"> Internacional</td>
          <td *ngIf="preciosFiltrados[i].tipoVenta != null && preciosFiltrados[i].tipoVenta == 1"> Cabotaje </td>

          <td *ngIf="preciosFiltrados[i].precioContado != null"> {{preciosFiltrados[i].precioContado}} </td>
          <td *ngIf="preciosFiltrados[i].precioContado == null"> -- </td>

          <td *ngIf="preciosFiltrados[i].direccionEntrega != null"> {{preciosFiltrados[i].direccionEntrega}}</td>
          <td *ngIf="preciosFiltrados[i].direccionEntrega == null"> -- </td>

          <td *ngIf="preciosFiltrados[i].areaNegocio != null"> {{preciosFiltrados[i].areaNegocio.nombreArea}} </td>
          <td *ngIf="preciosFiltrados[i].areaNegocio == null"> -- </td>

          <td *ngIf="preciosFiltrados[i].segmento != null"> {{preciosFiltrados[i].segmento.nombreSegmento}} </td>
          <td *ngIf="preciosFiltrados[i].segmento == null"> -- </td>

          <td>{{preciosFiltrados[i].codigoAeroplanta}}</td>          
        </tr>
      </tbody>
    </table>

    <div *ngIf="listaPrecios!=null && listaPrecios.length == 0">
      No se obtuvo ningun precio para el día seleccionado
    </div>
  </div>
</div>
</div>