import { Component, Inject, LOCALE_ID, OnInit, ViewEncapsulation } from '@angular/core';
import {  UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal, NgbCalendar, NgbDatepickerConfig, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';

import { CondicionVenta } from 'src/app/shared/enums/condicionVenta';
import { TipoEstadoPrecio } from 'src/app/shared/enums/tipoEstadoPrecio';
import { Precio } from 'src/app/shared/models/abm/Precio';
import { ProductoService } from 'src/app/abm/producto/producto.service';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';

@Component({
  selector: 'app-precio-por-fecha',
  templateUrl: './precio-por-fecha.component.html',
  styleUrls: ['./precio-por-fecha.component.css']
})
export class PrecioPorFechaComponent implements OnInit {
  [x: string]: any;
  enumCondicionVenta = CondicionVenta;
  enumEstadoPrecio = TipoEstadoPrecio;

  preciosFiltrados: Precio[];
  listaPrecios: Precio[];

  actualizandoPrecios: boolean = false;
  esperandoCarga: boolean = false;
  messagePrecios: string = "C a r g a n d o . . ."
  searchForm = new UntypedFormGroup({
    busqueda: new UntypedFormControl(''),
  })

  modelFecha: NgbDateStruct;
  modelHora: any;

  niveles = [
    { nombre: "1 - Precio Base/Condicion vta/area/segmento/Cliente/Tipo venta/Dir.entrega", seleccionado: false },
    { nombre: "2 - Precio Base/Condicion vta/Area/Segmento/Cliente/Tipo Venta", seleccionado: false },
    { nombre: "3 - Precio Base/Condicion vta/Area/Segmento/Cliente", seleccionado: false },
    { nombre: "4 - Precio Base/Condicion vta/Area/Segmento", seleccionado: false },
    { nombre: "5 - Precio Base/Condicion vta/Area", seleccionado: false },
    { nombre: "6 - Precio Base/Condicion vta", seleccionado: false },
    { nombre: "7 - Precio Base", seleccionado: false }
  ];

  constructor(
    public modal: NgbActiveModal,
    private productoService: ProductoService,
    private spinner: NgxSpinnerService,
    private calendar: NgbCalendar,
    private datepickerConfig: NgbDatepickerConfig,
    @Inject(LOCALE_ID) public locale: string) { }

  ngOnInit() {
    this.searchForm.valueChanges.subscribe(() => this.filtrarPrecios());

    this.modelFecha = this.calendar.getToday();

    let inicio: any = { day: 1, month: 1, year: 2019 };
    this.datepickerConfig.minDate = inicio;

    var horaActual = new Date();
    this.modelHora = { hour: horaActual.getHours(), minute: horaActual.getMinutes(), second: horaActual.getSeconds() };
    //this.horaFormControl.setValue(horaActual.getUTCHours());
  }

  formatDate(year, month, day, hour, minute): string {
    return year + '-' + month.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + '-' + day.toLocaleString(undefined, { minimumIntegerDigits: 2 })
      + 'T' + hour.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + minute.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':00';
  }

  obtenerPrecios() {

    let fechaHora = this.formatDate(this.modelFecha.year, this.modelFecha.month, this.modelFecha.day, this.modelHora.hour, this.modelHora.minute);

    this.spinner.show('spinnerGrafico')
    this.esperandoCarga = true;

    this.productoService.getPreciosPorFecha(fechaHora)
      .subscribe(result => {
        if(result != null){
          this.preciosFiltrados = result;
          this.listaPrecios = result;
        }else{
          this.preciosFiltrados = [];
          this.listaPrecios = [];
        }

        this.spinner.hide('spinnerGrafico');
        this.esperandoCarga = false;
        this.filtrarPrecios();
      },
      (err: ErrorModel) => {
       // this.error = err.description;
        this.spinner.hide('spinnerGrafico');
        this.esperandoCarga = false;
      });
  }

  filtrarPrecios() {

    if (this.preciosFiltrados != null) {
      this.preciosFiltrados = this.listaPrecios.filter(a => {
        let agregar = true;
        if (this.busquedas.value) {

          if (a.cliente != null) {
            agregar = a.cliente.razonSocial && a.cliente.razonSocial.toLocaleLowerCase().indexOf(this.busquedas.value.toLocaleLowerCase()) > -1;
          } else {
            agregar = false;
          }

          agregar = agregar || (a.producto.nombreProducto && a.producto.nombreProducto.toLocaleLowerCase().indexOf(this.busquedas.value.toLocaleLowerCase()) > -1);
          agregar = agregar || (a.producto.codigoProducto && a.producto.codigoProducto.toLocaleLowerCase().indexOf(this.busquedas.value.toLocaleLowerCase()) > -1);
          agregar = agregar || (a.precioContado.toString() && a.precioContado.toString().toLocaleLowerCase().indexOf(this.busquedas.value.toLocaleLowerCase()) > -1);
          agregar = agregar || (a.numeroLote && ("l"+a.numeroLote).toString().toLocaleLowerCase().indexOf(this.busquedas.value.toLocaleLowerCase()) > -1);
        }

        if (this.busquedas.value == '') {
          this.preciosFiltrados = this.listaPrecios;
        }

        if (this.niveles.some(e => e.seleccionado)) {
          agregar = agregar && (
            (this.niveles[0].seleccionado == true && a.nivelPrecio == 1)
            ||
            (this.niveles[1].seleccionado == true && a.nivelPrecio == 2)
            ||
            (this.niveles[2].seleccionado == true && a.nivelPrecio == 3)
            ||
            (this.niveles[3].seleccionado == true && a.nivelPrecio == 4)
            ||
            (this.niveles[4].seleccionado == true && a.nivelPrecio == 5)
            ||
            (this.niveles[5].seleccionado == true && a.nivelPrecio == 6)
            ||
            (this.niveles[6].seleccionado == true && a.nivelPrecio == 7)
          );
        }

        return agregar;
      });
    }

  }

  get busquedas() {
    return this.searchForm.get("busqueda");
  }

  get nivelForm() {
    return this.searchForm.get("nivelForm");
  }

  get fechaFormControl() {
    return this.searchForm.controls.fechaFormControl;
  }

  get horaFormControl() {
    return this.searchForm.controls.horaFormControl;
  }

}
