<form [formGroup]="form" (ngSubmit)="onSubmit()">

  <div class="modal-header bg-light">
    <h4 class="modal-title">Ingreso por ducto</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close(false)"></button>
  </div>

  <div class="modal-body">

    <div class="row">
      <div class="mb-3 row col-md-12">
        <label for="terminalFormControl" class="col-md-5 col-form-label">Terminal</label>
        <div class="col-md-7">
          <select [compareWith]="compareTerminales" id="terminalFormControl" class="form-select"
            ngbTooltip="Seleccione la terminal." formControlName="terminalFormControl">
            <option *ngFor="let terminal of terminales" [ngValue]="terminal">
              ({{ terminal.codigoTerminal }}) - {{ terminal.nombre }}
            </option>
          </select>
          <loading-text-spinner *ngIf="cargandoTerminales" message="Obteniendo terminales..." color="primary">
          </loading-text-spinner>
          <div class="alert alert-danger"
            *ngIf="(this.terminalFormControl.invalid && this.terminalFormControl.errors.required && (this.terminalFormControl.dirty || this.terminalFormControl.touched))">
            Debe seleccionar una terminal obligatoriamente.
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="mb-3 row col-md-12">
        <label for="ductoFormControl" class="col-md-5 col-form-label">Ducto</label>
        <div class="col-md-7">
          <select [compareWith]="compareDuctos" id="ductoFormControl" class="form-select"
            ngbTooltip="Seleccione el ducto." formControlName="ductoFormControl" (change)="this.filtrarTanques()">
            <option *ngFor="let ducto of ductos" [ngValue]="ducto">
              ({{ ducto.nombre }}) - {{ ducto.producto.nombreProducto }}
            </option>
          </select>
          <loading-text-spinner *ngIf="cargandoDuctos || cargandoContenedores" message="Obteniendo ductos..."
            color="primary"></loading-text-spinner>
          <div class="alert alert-danger"
            *ngIf="(this.ductoFormControl.invalid && this.ductoFormControl.errors.required && (this.ductoFormControl.dirty || this.ductoFormControl.touched))">
            Debe seleccionar un ducto obligatoriamente.
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="mb-3 row col-md-12">
        <label for="certificadoFormControl" class="col-sm-5 col-form-label">*Nro. certificado analisis:</label>
        <div class="col-sm-7">
          <input class="form-control" formControlName="certificadoFormControl" maxlength="50">
          <div *ngIf="certificadoFormControl.invalid && certificadoFormControl.errors.required &&
                        (certificadoFormControl.dirty || certificadoFormControl.touched)" class="alert alert-danger">
            Requerido</div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="mb-3 row col-md-12">
        <label for="prefijoRemitoFromControl" class="col-sm-5 col-form-label">*Prefijo remito:</label>
        <div class="col-sm-7">
          <input class="form-control" formControlName="prefijoRemitoFromControl" maxlength="50"
            (keypress)="onlyNumberKey($event)">
          <div *ngIf="prefijoRemitoFromControl.invalid && prefijoRemitoFromControl.errors.required &&
            (prefijoRemitoFromControl.dirty || prefijoRemitoFromControl.touched)" class="alert alert-danger">Requerido
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="mb-3 row col-md-12">
        <label for="nroRemitoFromControl" class="col-sm-5 col-form-label">*Nro. remito:</label>
        <div class="col-sm-7">
          <input class="form-control" formControlName="nroRemitoFromControl" maxlength="50"
            (keypress)="onlyNumberKey($event)">
          <div *ngIf="nroRemitoFromControl.invalid && nroRemitoFromControl.errors.required &&
            (nroRemitoFromControl.dirty || nroRemitoFromControl.touched)" class="alert alert-danger">Requerido</div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="mb-3 row col-md-12">
        <label for="cantidad15FromControl" class="col-sm-5 col-form-label">*Cantidad 15º total:</label>
        <div class="col-sm-7">
          <input class="form-control" formControlName="cantidad15FromControl" maxlength="50"
            (keypress)="onlyNumberKey($event)">
          <div *ngIf="cantidad15FromControl.invalid && cantidad15FromControl.errors.required &&
            (cantidad15FromControl.dirty || cantidad15FromControl.touched)" class="alert alert-danger">Requerido</div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="mb-3 row col-md-12">
        <label for="densidadFormControl" class="col-sm-5 col-form-label">*Densidad:</label>
        <div class="col-sm-7">
          <input class="form-control" formControlName="densidadFormControl" maxlength="50"
          (keypress)="formatDensidad($event)">
          <div *ngIf="densidadFormControl.invalid && densidadFormControl.errors.required &&
                (densidadFormControl.dirty || densidadFormControl.touched)" class="alert alert-danger">Requerido</div>
          <div class="alert alert-danger"
            *ngIf="(this.densidadFormControl.dirty || this.densidadFormControl.touched) && (this.productoSeleccionado && !this.validarDensidad(this.densidadFormControl.value))">
            La densidad debe estar entre el rango [ {{this.productoSeleccionado.densidadMin}} ,
            {{this.productoSeleccionado.densidadMax}} ]</div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="mb-3 row col-md-12">
        <label for="cantidad15TurbinaFromControl" class="col-sm-5 col-form-label">*Cantidad 15º turbina:</label>
        <div class="col-sm-7">
          <input class="form-control" formControlName="cantidad15TurbinaFromControl" maxlength="50"
            (keypress)="onlyNumberKey($event)">
          <div *ngIf="cantidad15TurbinaFromControl.invalid && cantidad15TurbinaFromControl.errors.required &&
            (cantidad15TurbinaFromControl.dirty || cantidad15TurbinaFromControl.touched)" class="alert alert-danger">
            Requerido</div>
        </div>
      </div>
    </div>

    <div style="padding: 1em; border: 1px solid #ccc;">

      <div class="row" *ngIf="this.contenedoresFiltrados!=null">
        <div class="mb-3 row col-md-12">
          <label for="tanqueGeneralFormControl" class="col-md-12 col-form-label">*Contenedores <span *ngIf="this.ductoFormControl.value != null && this.ductoFormControl.value.producto != null">de producto {{this.ductoFormControl.value.producto.nombreProducto}}</span></label>
          <div class="col-md-9">
            <select id="contenedorFormControl" class="form-select" ngbTooltip="Seleccione el contenedor."
              formControlName="contenedorFormControl">
              <option *ngFor="let c of contenedoresFiltrados" [ngValue]="c">
                {{ c.nombre }} ({{ c.productoActual.codigoProducto }} - {{ c.productoActual.nombreProducto }})
              </option>
            </select>
          </div>
          <div class="col-sm-3">
            <button (click)="agregarContenedorSeleccionado()" type="button" class="btn btn-primary">Agregar</button>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="mb-3 row col-md-12">
          <div class="col-sm-12"><b>Ingreso en contenedores</b></div>
        </div>
      </div>
      <div *ngIf="(this.detalleFormControlArray.controls.length > 0)" class="row"
        formArrayName="detalleFormControlArray">
        <div class="mx-auto mb-0 row col-md-12" style="border: 0.5px solid #ccc; padding: 1em;"
          *ngFor="let item of detalleFormControlArray.controls; let i=index">
          <div [formGroupName]="i">
            <button type="button" (click)="onDetalleEliminado(i)" class="btn btn-danger ms-3 px-1"
              style="float: right;"><i class="fa fa-trash"></i> Quitar</button>

            <div class="mx-auto row">
              <div class="mb-3 row col-md-12">
                <label for="codigoTanque" class="col-sm-6 col-form-label">*Contenedor:</label>
                <div class="col-sm-6">
                  <input class="form-control" formControlName="codigoTanque" maxlength="50" readonly>
                  <input class="form-control" formControlName="nombreTanque" maxlength="50" readonly>
                </div>
              </div>
            </div>

            <div class="mx-auto row">
              <div class="mb-3 row col-md-12">
                <label for="cantidad15" class="col-sm-4 col-form-label p-0 mx-3">*Cantidad 15º:</label>
                <div class="col-sm-4 p-0">
                  <input class="form-control" formControlName="cantidad15" maxlength="50"
                    (keypress)="onlyNumberKey($event)">
                </div>
              </div>
            </div>

            <div class="alert alert-danger" *ngIf="!detalleFormControlArray.controls[i].valid">
              Debe cargar datos para este contenedor.
            </div>

          </div>
        </div>
      </div>


      <div class="alert alert-danger" *ngIf="this.detalleFormControlArray.controls.length == 0">
        Debe agregar al menos un contenedor.
      </div>
    </div>

    <div class="row">
      <div class="mb-3 row col-md-12 mt-3">
        <div class="col-sm-12">Comentarios</div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <textarea class="form-control col-md-12" formControlName="comentarioFormControl" rows='5'></textarea>
      </div>
    </div>

    <div class="row">
      <div class="mb-3 mt-3">
        <label for="remitoFisicoFormControl" class="form-label">Remito físico</label>
        <input class="form-control form-control-sm" id="remitoFisicoFormControl" type="file" (change)="onSelectedFileRemitoFisico($event.target.files)" formControlName="remitoFisicoFormControl">
      </div>
      <div *ngIf="procesandoRemitoFisico" class="d-flex align-items-center">
        <div class="spinner-border spinner-border-sm" role="status"></div><span>&nbsp; &nbsp; Procesando imagen...</span>
      </div>      
    </div>
  </div>

  <div class="modal-footer bg-light">
    <div class="alert alert-danger" *ngIf="error != null">
      {{ error }}
    </div>

    <span class="border">
      <button class="btn btn-primary" [disabled]="!this.form.valid || this.guardandoDatos" type="submit">
        {{ this.guardandoDatos? "Guardando..." : "Guardar cambios" }}
      </button>
    </span>

    <span class="border">
      <button type="button" class="btn btn-secondary" [disabled]="this.guardandoDatos"
        (click)="activeModal.close(false)">Cancelar</button>
    </span>
  </div>


</form>
