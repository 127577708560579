<div class="card">
  <div class="card-header">
    <nav class="navbar navbar-light bg-light navbar-expand-lg">
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav">
          <!-- desde -->
          <li class="nav-item me-2">
            <div class="input-group">

              <input type="date" [ngModel]="model" class="fechaFormControlDesdeHasta" placeholder="aaaa-mm-dd"
                (ngModelChange)="model = $event" name="dt" ngbDatepicker #d="ngbDatepicker" value="model"
                class="form-control form-control-rounded" #searchDate required readonly>

              <div class="input-group-append">
                <button id="botonDesde" type="button" class="input-group-btn btn btn-secondary" (click)="d.toggle()">
                  <i class="fa fa-calendar" aria-hidden="true"></i></button>
              </div>
            </div>
          </li>
          <!-- hasta -->
          <li class="nav-item">
            <div class="input-group">
              <input type="date" class="fechaFormControlDesdeHasta" [ngModel]="model1" placeholder="aaaa-mm-dd"
                name="dp" value="model1" (ngModelChange)="model1 = $event" class="form-control form-control-rounded"
                ngbDatepicker #h="ngbDatepicker" #searchDate required readonly>

              <div class="input-group-append">
                <button id="botonHasta" type="button" class="input-group-btn btn btn-secondary" (click)="h.toggle()"><i
                    class="fa fa-calendar" aria-hidden="true"></i></button>
              </div>
            </div>
          <li class="nav-item">
            <button (click)="this.buscarDesdeHasta()" class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2"
              type="submit"><i class="fa fa-search" aria-hidden="true"></i>
            </button>
          </li>
        </ul>
      </div>
    </nav>
  </div>

  <div class="col col-md-12">
    <div class="spinner-container" *ngIf="obteniendoCierres">
      <ngx-spinner name="spinnerGrafico" bdOpacity=0.3bd
        Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
        <p style="color: white">C a r g a n d o . . . </p>
      </ngx-spinner>
    </div>

    <ngb-alert class="alert alert-warning mt-2" *ngIf="this.cierresCaja.length == 0 && !obteniendoCierres">No hay cierres de caja para la fecha seleccionada.</ngb-alert>
    <div class="row" *ngIf="this.cierresCaja.length > 0">
      <div class="col col-3">
        <div class="list-group py-3  menu-izq">
          <div *ngFor="let cierre of cierresCaja; let i=index">
            <button type="button" [class.active]="" class="list-group-item list-group-item-action">
              <div class="d-flex w-100 justify-content-between" (click)="mostrarDetalle(i)">
                <ul class="list-unstyled">                 
                  <li>Cierre: {{ cierre.numero }} - {{ cierre.fechaHora | date: "dd'/'MM'/'y hh:mm a"}} </li>
                  <li>{{ cierre.responsable }} </li>
                </ul>
              </div>
            </button>
          </div>
        </div>
      </div>
      <div class="col col-9" *ngIf="this.cierreSeleccionado != null">
        <app-detalle-cierre-caja [cierreSeleccionado]="cierreSeleccionado"></app-detalle-cierre-caja>
      </div>
    </div>

  
</div>