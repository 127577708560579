import { Directive, Input, OnChanges, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[appRefreshPage]'
})
export class RefreshPageDirective implements OnChanges {

  @Input() appRefreshPage: number;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef
  ) { 
    this.viewContainerRef.createEmbeddedView( templateRef );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['appRefreshPage']) {
      this.viewContainerRef.clear();
      this.viewContainerRef.createEmbeddedView( this.templateRef );
    }
  }

}
