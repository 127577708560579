<ngx-spinner name="spinner" size="large" bdColor="rgba(255,255,255,1)" color="#185db5" type="ball-grid-beat"
  [fullScreen]="true">
  <div class="h2 text-center" style="color: black; margin-top: 25vh">Cargando datos del usuario</div>
</ngx-spinner>

<app-navbar></app-navbar>
<main role="main">
  <div class="container-fluid flex-wrap flex-md-nowrap pt-3 pb-2 mb-3 border-bottom">
    <div id="cartelMensajes">
      <app-messages ></app-messages>
  </div>
    <div [@routerFade]="prepareRoute(outlet)">
      <router-outlet #outlet="outlet"></router-outlet>
    </div>
  </div>


</main>
