import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { UsuarioService } from 'src/app/account/usuario.service';
import { CierreTurnoService } from 'src/app/cierreTurno/cierre-turno.service';
import { TipoMovimientoStock } from 'src/app/shared/enums/tipoMovimientoStock';
import { TipoReporteMovimientos } from 'src/app/shared/enums/TipoReporteMovimientos';
import { ReporteMovimientoDetalleBalance7 } from 'src/app/shared/models/cierreTurno/ReporteMovimientoDetalleBalance7';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { MessageService } from 'src/app/shell/message.service';
import { DatePipe, DecimalPipe } from '@angular/common';
import { ComprobanteSapPipe } from 'src/app/shared/pipes/comprobante-sap.pipe';
import { FacturacionService } from 'src/app/facturacion/facturacion.service';
@Component({
  selector: 'app-reporte-detalle',
  templateUrl: './reporte-detalle.component.html',
  styleUrls: ['./reporte-detalle.component.scss']
})
export class ReporteDetalleComponent implements OnInit {

  TipoMovimientoStock = TipoMovimientoStock;

  @Input() listaIdsCierreTurno: string[];
  @Input() tipoReporte: number;

  @Input() nombreProducto: string;
  @Input() codigoProducto: string;
  @Input() codigoAeroplanta: string;
  @Input() fechaDesde: string;
  @Input() fechaHasta: string;
  @Input() incluirAjustes: boolean;
  @Input() tipoNatural: boolean;
  @Input() tipoTransaccion: number;
  @Input() esAnulacion: boolean;
  @Input() concepto: string;

  movimientosDetalle: ReporteMovimientoDetalleBalance7[];
  obteniendoDatos: boolean;

  totalConcepto: number;
  aeroplanta:any;

  constructor(private spinner: NgxSpinnerService,
    private cierreTurnoService: CierreTurnoService,
    public activeModal: NgbActiveModal, 
    private messageService: MessageService, 
    public usuarioService: UsuarioService,
    private datePipe: DatePipe,
    private decimalPipe: DecimalPipe,
    private facturacionService: FacturacionService,
    private comprobante: ComprobanteSapPipe) { }

  ngOnInit(): void {    
    this.mostrarLoading();
    this.facturacionService.consutarPorCodigoAeroplanta(this.usuarioService.getAeroplantaActual().codigoAeroplanta)
      .subscribe(r=>{
        this.aeroplanta=r;
        this.obtenerMovimientosPorTipo();
      });
  }
  
  obtenerMovimientosPorTipo(){
    if(this.tipoReporte == TipoReporteMovimientos.Fecha){
      this.obtenerDetalleMovimientosPorFecha();
    }else{
      this.obtenerDetalleMovimientosPorCierreTurno();
    }
  }

  obtenerDetalleMovimientosPorFecha() {
    this.cierreTurnoService.obtenerReporteMovimientoDetalleFecha(this.codigoProducto, this.codigoAeroplanta, this.tipoNatural, this.tipoTransaccion, this.esAnulacion, this.fechaDesde, this.fechaHasta)
      .subscribe(result => {
        this.movimientosDetalle = result;
        this.calcularTotalConcepto(this.movimientosDetalle);
        this.ocultarLoading();
      }, (err: ErrorModel) => {
        this.messageService.showErrorMessage('Hubo un error al obtener los datos. ' + err.description);
        this.movimientosDetalle = null;
        this.ocultarLoading();
      });
  }

  obtenerDetalleMovimientosPorCierreTurno(){
    this.cierreTurnoService.obtenerReporteMovimientoDetalleCierreTurno(this.listaIdsCierreTurno, this.codigoProducto, this.codigoAeroplanta, this.tipoNatural, this.tipoTransaccion, this.esAnulacion)
      .subscribe(result => {
        this.movimientosDetalle = result;
        this.calcularTotalConcepto(this.movimientosDetalle);
        this.ocultarLoading();
      }, (err: ErrorModel) => {
        this.messageService.showErrorMessage('Hubo un error al obtener los datos. ' + err.description);
        this.movimientosDetalle = null;
        this.ocultarLoading();
      });
  }

  calcularTotalConcepto(detalle: ReporteMovimientoDetalleBalance7[]){
    this.totalConcepto = 0;
    for (let i = 0; i < detalle.length; i++) {
      this.totalConcepto += detalle[i].cantidad;      
    }
  }

  mostrarLoading(){
    this.obteniendoDatos = true;
    this.spinner.show('spinnerGrafico');
  }

  ocultarLoading(){
    this.obteniendoDatos = false;
    this.spinner.hide('spinnerGrafico');
  }

  exportarMovimientosDetalle() {
    var dataArray=[
      ["Concepto: " + this.concepto + " | " + "Producto: " + this.codigoProducto + " - " + this.nombreProducto + "(" + (this.tipoNatural ? 'Natural' : '15º') + ")"],      
      ["Fecha desde: " + this.datePipe.transform(this.fechaDesde, "dd/MM/yyy HH:mm a") + " | Fecha hasta: " + this.datePipe.transform(this.fechaHasta, "dd/MM/yyy HH:mm a")],
      ["Dependencia: " + this.usuarioService.getAeroplantaActual().codigoAeroplanta + " - " + this.usuarioService.getAeroplantaActual().nombre ],
      [],
      (this.tipoTransaccion == TipoMovimientoStock.Despacho) ? ["Comprobante", "Aerovale", "Fecha","Detalle","Cantidad"] : ["Comprobante","Fecha","Detalle","Cantidad"]
    ];

    for (let i=0; i < this.movimientosDetalle.length; i++) {
      var md = this.movimientosDetalle[i];
      var fila = (this.tipoTransaccion == TipoMovimientoStock.Despacho) ? [md.numeroComprobante, md.numeroAerovale, this.datePipe.transform(md.fechaHora, "dd/MM/yyy HH:mm a"), md.detalle, this.decimalPipe.transform(md.cantidad, '1.0-0')] : [md.numeroComprobante, this.datePipe.transform(md.fechaHora, "dd/MM/yyy HH:mm a"), md.detalle, this.decimalPipe.transform(md.cantidad, '1.0-0')];
      dataArray.push(fila);
    }

    var filaFooter = (this.tipoTransaccion == TipoMovimientoStock.Despacho) ? ["", "", "", "Total Concepto", this.decimalPipe.transform(this.totalConcepto, '1.0-0')] : ["", "", "Total Concepto", this.decimalPipe.transform(this.totalConcepto, '1.0-0')];
    dataArray.push(filaFooter);

    var filename = this.usuarioService.getAeroplantaActual().codigoAeroplanta + " - " + this.concepto + " - " + this.codigoProducto + " - " + this.nombreProducto + "(" + (this.tipoNatural ? 'Natural' : '15º') + ")";
    this.cierreTurnoService.exportAsExcelFile(dataArray, filename);
  }

  comprobanteSAP(nro_envio) { 
    const cp = nro_envio ? this.comprobante.transform(Number(this.aeroplanta[0]?.numeroAeroplanta), nro_envio) : '-';
    return  cp;    
  }
  
}
