import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { catchError } from 'rxjs/operators';
import { UsuarioService } from 'src/app/account/usuario.service';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { ResultadoPruebaDeAgua } from 'src/app/shared/enums/resultadoPruebaDeAgua';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';
import { ModalConfirmComponent } from 'src/app/shared/generic-modals/modal-confirm.component';
import { ModalOkComponent } from 'src/app/shared/generic-modals/modal-ok.component';
import { ActividadAFIP } from 'src/app/shared/models/cliente/AcividadAFIP';
import { Ciudad } from 'src/app/shared/models/cliente/Ciudad';
import { Cliente } from 'src/app/shared/models/cliente/Cliente';
import { ClienteEncabezado } from 'src/app/shared/models/cliente/ClienteEncabezado';
import { Cuenta } from 'src/app/shared/models/cliente/Cuenta';
import { Provincia } from 'src/app/shared/models/cliente/Provincia';
import { Ubicacion } from 'src/app/shared/models/cliente/Ubicacion';
import { Pais } from 'src/app/shared/models/despacho/Pais';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { CondicionIVA } from 'src/app/shared/models/facturacion/CondicionIVA';
import { MessageService } from 'src/app/shell/message.service';
import { ClienteService } from '../cliente.service';

@Component({
  selector: 'app-modal-web-service',
  templateUrl: './modal-web-service.component.html',
  styleUrls: ['./modal-web-service.component.css']
})
export class ModalWebServiceComponent implements OnInit {

  aeroplantaCod: string;
  @Input() clienteVerificar: ClienteEncabezado[];
  @Output() clienteObtenido: EventEmitter<Cliente> = new EventEmitter<Cliente>();
  @Output() cerrarInformacion: EventEmitter<boolean> = new EventEmitter<boolean>();

  clienteAFIP: Cliente;
  guardando: boolean = false;
  esta: boolean = false;
  mensajeError: string = '';
  maximoNumero: number = 11;

  cuitFormControl = new UntypedFormControl(null, [
    Validators.required,
    Validators.pattern('(20|23|24|27|30|33|34)(\\D)?[0-9]{8}(\\D)?[0-9]'),
    this.validarFormatoCuit && this.validarCuit
  ]);
  constructor(public activeModal: NgbModal, public modal: NgbActiveModal, private usuarioService: UsuarioService, private clienteService: ClienteService, private messageService: MessageService,
    private authService: AuthService) { }

  ngOnInit() {
    this.aeroplantaCod = this.usuarioService.getAeroplantaActual().codigoAeroplanta;
  }

  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  validarFormatoCuit(): boolean {
    const regexCuit = /^(20|23|24|27|30|33|34)([0-9]{9}|[0-9]{8}[0-9]{1})$/g;
    let p = regexCuit.test(this.cuit.value)
    return p;
  }

  validarCuit(control: AbstractControl): { [key: string]: any } | null {
    if (control.value == null || control.value.length === 0) {
      return null; // don't validate empty values to allow optional controls
    }
    let esCuit = false;
    let cuit = control.value
    var vec = new Array(10);
    let cuit_rearmado = "";
    for (let i = 0; i < cuit.length; i++) {
      let caracter = cuit.charAt(i);
      if (caracter.charCodeAt(0) >= 48 && caracter.charCodeAt(0) <= 57) {
        cuit_rearmado += caracter;
      }
    }
    cuit = cuit_rearmado;
    if (cuit.length != 11) {  // si no estan todos los digitos
      esCuit = false;
    } else {
      let x = 0;
      let dv = 0
      // Multiplico los dígitos.
      vec[0] = cuit.charAt(0) * 5;
      vec[1] = cuit.charAt(1) * 4;
      vec[2] = cuit.charAt(2) * 3;
      vec[3] = cuit.charAt(3) * 2;
      vec[4] = cuit.charAt(4) * 7;
      vec[5] = cuit.charAt(5) * 6;
      vec[6] = cuit.charAt(6) * 5;
      vec[7] = cuit.charAt(7) * 4;
      vec[8] = cuit.charAt(8) * 3;
      vec[9] = cuit.charAt(9) * 2;

      // Suma cada uno de los resultado.
      for (let i = 0; i <= 9; i++) {
        x += vec[i];
      }
      dv = (11 - (x % 11)) % 11;
      if (dv == cuit.charAt(10)) {
        esCuit = true;
      }
    }

    return control.valid && esCuit ? null : { 'cuit': { value: control.value } };
  }

  buscarCliente() {
    this.guardando = true;
    if (this.estaCliente()) {
      this.messageService.showErrorMessage('El cliente ya se encuentra agregado.');
      this.guardando = false;
    } else {
      this.clienteService.validarClienteCenit(this.aeroplantaCod, this.cuit.value, this.rolesQuePuedenConsultarAFIP(), false)
        .subscribe(result => {
          this.clienteAFIP = result;
          this.clienteAFIP.tipoPersona = null;          
          this.clienteObtenido.emit(this.clienteAFIP);
          this.modal.close();
          this.guardando = false;
        }, (err:ErrorModel) => {                    
          this.messageService.showErrorLongMessage(err.description);
          this.modal.close();
          this.guardando = false;
          this.cerrarInformacion.emit(true);
        })          
    }
  }

  usuarioModificaCampos(): boolean {
    return this.authService.hasRole(TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.APROBADORBYR);
  }

  rolesQuePuedenConsultarAFIP(): boolean {
    return this.authService.hasRole(TipoUsuario.SUPERVISOR, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO);    
  }

  estaCliente(): boolean {
    let esta: boolean = false;
    try{
      for (let cli of this.clienteVerificar) {
        if (this.cuit.value == cli.numeroDocumento) {
          esta = true;
        }
      }
      return esta;
    }catch(exc){
      console.log(exc);
      return false;
    }    
  }

  get cuit() {
    return this.cuitFormControl;
  }

}
