<div class="p-2 add-margin">
  <div class="card">
    <div class="card-header">
      <b>Carga de graduacion para tanque {{ tanque.codigo }} {{ tanque.nombre}}</b>
      <b>Tipo de medición: {{ tanque.tipoMedicion }}</b>
      <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()">
        
      </button>
    </div>
    <div class="card-body">
      <h5 class="card-title">Utilice este importador para cargar la relación de altura de tanque con el valor
        correspondiente a su volúmen utilizando un archivo de tipo Excel <i class="fa fa-file-excel-o"
          aria-hidden="true"></i></h5>
      <p class="card-text"><b>Importante:</b>Debe tener el formato y ordenamiento de columnas que se estableció en la
        muestra.</p>
      <table class="table-bordered table-condensed text-center letra" style="border: solid; border-width: 2px;">
        <tr>
          <td class="py-2 px-2"><b>Altura</b></td>
          <td class="py-2 px-2"><b>Volumen</b></td> 
        </tr>
      </table>
      <br>
      <div *ngIf="fileUpload.status==='error'">
        <div [innerHTML]="fileUpload.message"></div> 
      </div>
      <div *ngIf="fileUpload.status==='progress'">
        <div class="progress" style="height: 20px;margin-bottom:20px;">
          <div class="progress-bar" role="progressbar" [style.width.%]="fileUpload.message" aria-valuenow="25"
            aria-valuemin="0" aria-valuemax="100">{{fileUpload.message}}%</div>
        </div>
      </div>
      <form [formGroup]="profileForm" (ngSubmit)="onSubmit()">
        <div class="mb-3">
          <div class="custom-file">
            <input type="file" class="custom-file-input" name="profile" (change)="onSelectedFile($event)"
              id="validatedCustomFile" formControlName="inputFile" required>
            <label class="custom-file-label" for="validatedCustomFile">{{ userFileName }}</label>
            <div class="invalid-feedback">Example invalid custom file feedback</div>
          </div>
        </div>
        <div class="mb-3">
          <button class="btn btn-primary" type="submit" [disabled]="!selectedFile"
            class="btn btn-success">Cargar</button>
          <button type="button" class="btn btn-secondary ms-2" (click)="reset(true, true)">Cancelar</button>
        </div>
      </form>
      <loading-text-spinner *ngIf="cargando" message="Cargando archivo ..." color="primary">
      </loading-text-spinner>
      <div *ngIf="!cargando">
        <!-- <p> {{this.fileUpload|json}}</p> -->
        <div *ngIf="fileUpload.length>0" class="alert alert-warning" role="alert">
          <div *ngFor="let msg of fileUpload">
            <p>{{msg.message +": "+ msg.description }}</p>
          </div>
        </div>
        <div *ngIf="error" class="alert alert-danger" role="alert">{{ error }}</div>
      </div>
      <div>
        <small>*La altura debe expresarse en MM.</small>
      </div>
      <div>
        <small>*El volumen debe expresarse en Lts.</small>
      </div>
    </div>
  </div>
</div>
