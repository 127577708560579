<div class="spinner-container" *ngIf="obteniendoDatos">        
  <ngx-spinner name="spinnerGrafico" bdOpacity=0.3bd
    Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
    <p style="color: white">C a r g a n d o . . . </p>
  </ngx-spinner>        
</div>

<div class="row contenedor-form" *ngIf="!obteniendoDatos" [formGroup]="formTipoReporte">
  <div class="col-md-12 mb-4">
    <b>Seleccionar tipo de movimientos a obtener:</b>
  </div>

  <div class="col-md-2 offset-md-1">
    <input type="radio" class="form-check-input me-1" id="tipoFecha" [value]="TipoReporteMovimientos.Fecha"
      formControlName="tipoReporteFormControl" />
    <label class="form-check-label" for="tipoFecha">Fecha</label>
  </div>

  <div class="col-md-2">
    <input type="radio" class="form-check-input me-1" id="tipoCierreTurno" [value]="TipoReporteMovimientos.CierreTurno"
      formControlName="tipoReporteFormControl" />
    <label class="form-check-label" for="tipoCierreTurno">Cierre turno</label>
  </div>

  <div class="col-md-2">
    <input type="checkbox" class="form-check-input me-1" id="incluirAjustes"
      formControlName="incluirAjustesFormControl" />
    <label class="form-check-label" for="incluirAjustes">Incluir ajustes</label>
  </div>

  <div class="col-md-2">
    <button (click)="buscarReporteMovimientos()" class="btn btn-primary">Obtener movimientos</button>
  </div>
</div>

<div class="col-12" id="contenedor" *ngIf="this.movimientosNaturales != null && this.movimientos15Grados != null">

  <div class="row contenedor-form">
    <div class="col-md-3 mb-2">
      <b> Seleccionar que datos incluir en el listado</b>
    </div>
    <div class="col-md-2 offset-md-1">
      <input type="checkbox" class="form-check-input me-1" id="tipoNaturalFormControl"
        (change)="this.usuarioTildoNatural($event.target.checked)" />
      <label class="form-check-label" for="tipoNaturalFormControl">Natural</label>
    </div>

    <div class="col-md-2">
      <input type="checkbox" class="form-check-input me-1" id="tipo15GradosFormControl"
        (change)="this.usuarioTildo15Grados($event.target.checked)" checked />
      <label class="form-check-label" for="tipo15GradosFormControl">15º</label>
    </div>
  </div>

  <div *ngIf="this.mostrarTablaNatural">

    <div class="d-flex justify-content-between fondo">
      <label class="encabezado">Listado movimientos Natural</label>
      <button class="btn btn-outline-success me-2"
        (click)="exportarListados(TipoReporteBalance7.ContenedorNatural)">
        <i class="fa fa-file-excel-o" aria-hidden="true"></i>
        Exportar
      </button>
    </div>

    <table class="table table-striped table-hover">
      <thead class="text-center">
        <th>Codigo</th>
        <th>Nombre producto</th>
        <th>Saldo inicial</th>
        <th>Entrada</th>
        <th>Salida</th>
        <th>Saldo final</th>
      </thead>
      <tbody class="text-center">
        <tr *ngFor="let m of movimientosNaturales" (click)="obtenerMovimientosPorTipo(m)" style="cursor: pointer;">
          <td>{{m.codigoProducto}}</td>
          <td>{{m.nombreProducto}}</td>
          <td>{{m.anterior | number: '1.0-0'}}</td>
          <td>{{m.entradas | number: '1.0-0'}}</td>
          <td>{{m.salidas| number: '1.0-0'}}</td>
          <td>{{m.final | number: '1.0-0'}}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <div *ngIf="this.mostrarTabla15Grados">
    <div class="d-flex justify-content-between fondo">
      <label class="encabezado">Listado movimientos 15º</label>
      <button class="btn btn-outline-success me-2"
        (click)="exportarListados(TipoReporteBalance7.Contenedor15)">
        <i class="fa fa-file-excel-o" aria-hidden="true"></i>
        Exportar
      </button>
    </div>

    <table class="table table-striped table-hover">
      <thead class="text-center">
        <th>Codigo</th>
        <th>Nombre producto</th>
        <th>Saldo inicial</th>
        <th>Entrada</th>
        <th>Salida</th>
        <th>Saldo final</th>
      </thead>
      <tbody class="text-center">
        <tr *ngFor="let m of movimientos15Grados" (click)="obtenerMovimientosPorTipo(m)" style="cursor: pointer;">
          <td>{{m.codigoProducto}}</td>
          <td>{{m.nombreProducto}}</td>
          <td>{{m.anterior | number: '1.0-0'}}</td>
          <td>{{m.entradas | number: '1.0-0'}}</td>
          <td>{{m.salidas| number: '1.0-0'}}</td>
          <td>{{m.final | number: '1.0-0'}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>