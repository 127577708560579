<div class="col col-md-12" *ngIf="cargandoDetalle">
    <div class="spinner-container">
        <ngx-spinner name="spinnerDetalle" bdOpacity=0.3bd Color="rgba(193,193,193,0)" size="medium" color="#185db5"
            type="ball-grid-beat" [fullScreen]="false">
            <p style="color: white">C a r g a n d o . . . </p>
        </ngx-spinner>
    </div>
</div>

<div class="card add-margin" *ngIf="!cargandoDetalle">
    <form [formGroup]="aeroplantaForm">
        <div class="card-header">
            <div class="row col-12">
                <h4 class="card-title">Aeroplanta: {{ this.aeroplantaSeleccionada.nombre }}</h4>
            </div>
        </div>
        <div class="card-body">

            <div *ngIf="this.authService.hasRole(TipoUsuario.JEFEDEAEROPLANTA)"  class="d-flex flex-row mb-3">
                <label for="tipoAeroplantaFormControl" class="col-2 col-form-label">Responsable:</label>

                <div class="form-check form-check-inline p-2 col-4">
                    <select id="responsableFormControl" class="form-select"
                        ngbTooltip="Seleccione un responsable" formControlName="responsableFormControl">
                        <option *ngFor="let r of usuariosResponsableProducto" [ngValue]="r">
                            {{r.nombresCompletos}}
                        </option>
                    </select>
                    <loading-text-spinner *ngIf="cargandoResponsables" message="Obteniendo usuarios.." color="primary">
                    </loading-text-spinner>
                </div>

                <label for="tipoAeroplantaFormControl" class="col-1 col-form-label">Suplente:</label>
                <div class="form-check form-check-inline p-2 col-4">
                    <select id="suplenteFormControl" class="form-select"
                        ngbTooltip="Seleccione un suplente" formControlName="suplenteFormControl">
                        <option *ngFor="let r of usuariosResponsableProducto" [ngValue]="r">
                            {{r.nombresCompletos}}
                        </option>
                    </select>
                    <loading-text-spinner *ngIf="cargandoResponsables" message="Obteniendo usuarios.." color="primary">
                    </loading-text-spinner>
                </div>
            </div>

            <br>


            <div class="d-flex flex-row mb-3">
                <label for="tipoAeroplantaFormControl" class="col-2 col-form-label">*Tipo aeroplanta:</label>

                <div class="form-check form-check-inline p-2 col-1">
                    <input class="form-check-input" type="radio" name="tipoAeroplantaFormControl" id="checkPropia"
                        [value]="TipoAeroplanta.Propias" formControlName="tipoAeroplantaFormControl">
                    <label class="form-check-label" for="checkPropia">Propias</label>
                </div>
                <div class="form-check p-2">
                    <input class="form-check-input" type="radio" name="tipoAeroplantaFormControl" id="checkTercera"
                        [value]="TipoAeroplanta.Terceras" formControlName="tipoAeroplantaFormControl">
                    <label class="form-check-label" for="checkTercera">Terceras</label>
                </div>
            </div>

            <br>

            <!--Detalle Productos -->
            <div style="padding: 1em; border: 1px solid #ccc;">
                <div class="row">
                    <div class="mb-3 row col-12">
                        <label for="productoFormControl" class="col-12 col-form-label">Seleccione un producto:</label>
                        <div class="col-5">
                            <select id="productoFormControl" *ngIf="!this.cargandoProductos" class="form-select"
                                ngbTooltip="Seleccione un producto" formControlName="productoFormControl">
                                <option *ngFor="let p of productos" [ngValue]="p">
                                    ({{ p.codigoProducto }}) {{ p.nombreProducto }}
                                </option>
                            </select>
                            <loading-text-spinner *ngIf="cargandoProductos" message="Obteniendo productos.."
                                color="primary">
                            </loading-text-spinner>
                        </div>
                        <div class="col-3">
                            <button (click)="agregarProductoSeleccionada()" [disabled]="this.cargandoProductos"
                                type="button" class="btn btn-primary">Agregar
                            </button>
                        </div>
                    </div>
                </div>

                <nav class="navbar navbar-light bg-light navbar-expand-lg add-margin mb-1">
                    <div class="col-12 text-center">
                        <h5 class="mt-1">Detalle Productos</h5>
                    </div>
                </nav>
                <div class="alert alert-danger" *ngIf="this.detalleProductosFormControlArray.controls.length == 0">
                    No hay detalle productos configurados</div>
                <div *ngIf="(this.detalleProductosFormControlArray.controls.length > 0)"
                    class="row d-flex justify-content-around" formArrayName="detalleProductosFormControlArray">

                    <div class="mb-3 row col-5 ms-1 me-1" style="border: 1px solid #ccc; padding: 1em;"
                        *ngFor="let dp of detalleProductosSeleccionados; let i=index">
                        <div [formGroupName]="i">

                            <div class="row">
                                <div class="col-12">
                                    <div class="col-12 row" style="justify-content: center;">
                                        <label class="col-10 col-form-label me-2">
                                            <b>({{dp.codigoProducto}}) - {{dp.nombreProducto}}</b>
                                        </label>
                                        <button type="button" (click)="eliminarDetalle(i)"
                                            class="btn btn-danger ms-2 px-1 col-1">
                                            <i class="fa fa-trash" ngbTooltip="Borrar detalle del producto"></i>
                                        </button>
                                    </div>
                                    <div class="col-12 d-flex flex-row mt-2">
                                        <label class="col-4 col-form-label" for="codigoTerminal">*Terminal</label>
                                        <select id="codigoTerminal" *ngIf="!this.cargandoProductos" class="form-select"
                                            ngbTooltip="Seleccione una terminal" formControlName="codigoTerminal">
                                            <option *ngFor="let t of terminales" [ngValue]="t.codigoTerminal">
                                                <p>{{ t.codigoLetras }} - {{ t.nombre }}</p>
                                            </option>
                                        </select>
                                    </div>
                                    <input type="hidden" formControlName="codigoProducto">
                                    <div class="col-12 d-flex flex-row mt-2">
                                        <label class="col-8 col-form-label" for="distanciaEnKM">*Distancia en
                                            Kilometros</label>
                                        <input type="text" appOnlyNumber formControlName="distanciaEnKM"
                                            class="form-control" (change)="onChangeDistancia(i)" [maxlength]="6">
                                    </div>
                                    <div class="col-12 d-flex flex-row mt-2">
                                        <label class="col-8 col-form-label" for="toleranciaMensualOperativa">*Tolerancia
                                            mensual operativa (±)</label>
                                        <div class="input-group" style="padding-left: 0; padding-right: 0;">
                                            <input class="form-control" type="text" [min]="0" [max]="99.99"
                                                [maxlength]="6" appOnlyDecimal
                                                formControlName="toleranciaMensualOperativa">
                                            <span class="input-group-text">%</span>
                                        </div>
                                    </div>
                                    <div class="col-12 d-flex flex-row mt-2">
                                        <label class="col-8 col-form-label" for="diasPromedio">*Promedio de dias</label>
                                        <input type="text" appOnlyNumber formControlName="diasPromedio"
                                            class="form-control" [maxlength]="6">
                                    </div>
                                    <div class="col-12 d-flex flex-row mt-2">
                                        <label class="col-8 col-form-label" for="diasDemora">*Dias demora:</label>
                                        <input type="text" appOnlyNumber formControlName="diasDemora"
                                            class="form-control" [maxlength]="6">
                                    </div>
                                    <div class="col-12 d-flex flex-row mt-2">
                                        <label class="col-8 col-form-label" for="stockSanitario">*Stock
                                            sanitario</label>
                                        <input type="text" appOnlyNumber formControlName="stockSanitario"
                                            class="form-control" [maxlength]="9">
                                    </div>
                                </div>
                            </div>

                            <div class="mt-1 alert alert-danger"
                                *ngIf="!detalleProductosFormControlArray.controls[i].valid">
                                (*) Datos obligatorios requeridos.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <br>
            <div class="col text-end mb-2">
                <button class="me-4 btn btn-primary" (click)="abmContacto(null)">Agregar contacto</button>
            </div>
            <div class="row col-12">
                <label class="col-3 col-form-label" for="firmaOperadorFormControl">Firma Operador</label>
                <div class="col-8">
                    <select id="firmaOperadorFormControl" *ngIf="!this.cargandoContactos" class="form-select"
                        ngbTooltip="Seleccione un operador" formControlName="firmaOperadorFormControl"
                        [compareWith]="compareContactos">
                        <option *ngFor="let c of contactos" [ngValue]="c">
                            {{ c.nombre }}
                        </option>
                    </select>
                    <div class="alert alert-danger"
                        *ngIf="(this.firmaOperadorFormControl.invalid && this.firmaOperadorFormControl.errors.required && (this.firmaOperadorFormControl.dirty || this.firmaOperadorFormControl.touched))">
                        Debe ingresar la firma del operador.
                    </div>
                </div>
                <div *ngIf="this.firmaOperadorFormControl.value!=null" class="col-1">
                    <button ngbTooltip="Editar contacto"
                        (click)="abmContacto(this.firmaOperadorFormControl.value, TipoResponsable._firmaOperadora)"
                        [disabled]="this.cargandoContactos" class="btn btn-md btn-warning">
                        <span class="fa fa-pencil"></span>
                    </button>
                </div>
            </div>

            <div class="row col-12 mt-2">
                <label class="col-3 col-form-label" for="referenteTecnicoFormControl">Referente Técnico</label>
                <div class="col-8">
                    <select id="referenteTecnicoFormControl" *ngIf="!this.cargandoContactos" class="form-select"
                        ngbTooltip="Seleccione un referente técnico" formControlName="referenteTecnicoFormControl"
                        [compareWith]="compareContactos">
                        <option *ngFor="let c of contactos" [ngValue]="c">
                            {{ c.nombre }}
                        </option>
                    </select>
                    <div class="alert alert-danger"
                        *ngIf="(this.referenteTecnicoFormControl.invalid && this.referenteTecnicoFormControl.errors.required && (this.referenteTecnicoFormControl.dirty || this.referenteTecnicoFormControl.touched))">
                        Debe ingresar un referente técnico.
                    </div>
                </div>
                <div *ngIf="this.referenteTecnicoFormControl.value!=null" class="col-1">
                    <button ngbTooltip="Editar contacto"
                        (click)="abmContacto(this.referenteTecnicoFormControl.value, TipoResponsable._referenteTecnico)"
                        [disabled]="this.cargandoContactos" class="btn btn-md btn-warning">
                        <span class="fa fa-pencil"></span>
                    </button>
                </div>
            </div>

            <div class="row col-12 mt-2">
                <label class="col-3 col-form-label" for="referenteGestionFormControl">Referente Gestión</label>
                <div class="col-8">
                    <select id="referenteGestionFormControl" *ngIf="!this.cargandoContactos" class="form-select"
                        ngbTooltip="Seleccione un referente gestión" formControlName="referenteGestionFormControl"
                        [compareWith]="compareContactos">
                        <option *ngFor="let c of contactos" [ngValue]="c">
                            {{ c.nombre }}
                        </option>
                    </select>
                    <div class="alert alert-danger"
                        *ngIf="(this.referenteGestionFormControl.invalid && this.referenteGestionFormControl.errors.required && (this.referenteGestionFormControl.dirty || this.referenteGestionFormControl.touched))">
                        Debe ingresar un referente gestión.
                    </div>
                </div>
                <div *ngIf="this.referenteGestionFormControl.value!=null" class="col-1">
                    <button ngbTooltip="Editar contacto"
                        (click)="abmContacto(this.referenteGestionFormControl.value, TipoResponsable._referenteGestion)"
                        [disabled]="this.cargandoContactos" class="btn btn-md btn-warning">
                        <span class="fa fa-pencil"></span>
                    </button>
                </div>
            </div>

            <div class="row col-12 mt-2">
                <label class="col-3 col-form-label" for="referenteComercialFormControl">Referente Comercial</label>
                <div class="col-8">
                    <select id="referenteComercialFormControl" *ngIf="!this.cargandoContactos" class="form-select"
                        ngbTooltip="Seleccione un referente comercial" formControlName="referenteComercialFormControl"
                        [compareWith]="compareContactos">
                        <option *ngFor="let c of contactos" [ngValue]="c">
                            {{ c.nombre }}
                        </option>
                    </select>
                    <div class="alert alert-danger"
                        *ngIf="(this.referenteComercialFormControl.invalid && this.referenteComercialFormControl.errors.required && (this.referenteComercialFormControl.dirty || this.referenteComercialFormControl.touched))">
                        Debe ingresar un referente comercial.
                    </div>
                </div>
                <div *ngIf="this.referenteComercialFormControl.value!=null" class="col-1">
                    <button ngbTooltip="Editar contacto"
                        (click)="abmContacto(this.referenteComercialFormControl.value, TipoResponsable._referenteComercial)"
                        [disabled]="this.cargandoContactos" class="btn btn-md btn-warning">
                        <span class="fa fa-pencil"></span>
                    </button>
                </div>
            </div>

            <div class="row col-12 mt-2">
                <label class="col-3 col-form-label" for="ruteadorFormControl">Ruteador</label>
                <div class="col-8">
                    <select id="ruteadorFormControl" *ngIf="!this.cargandoContactos" class="form-select"
                        ngbTooltip="Seleccione un ruteador" formControlName="ruteadorFormControl"
                        [compareWith]="compareContactos">
                        <option *ngFor="let c of contactos" [ngValue]="c">
                            {{ c.nombre }}
                        </option>
                    </select>
                    <div class="alert alert-danger"
                        *ngIf="(this.ruteadorFormControl.invalid && this.ruteadorFormControl.errors.required && (this.ruteadorFormControl.dirty || this.ruteadorFormControl.touched))">
                        Debe ingresar un ruteador.
                    </div>
                </div>
                <div *ngIf="this.ruteadorFormControl.value!=null" class="col-1">
                    <button ngbTooltip="Editar contacto"
                        (click)="abmContacto(this.ruteadorFormControl.value, TipoResponsable._ruteador)"
                        [disabled]="this.cargandoContactos" class="btn btn-md btn-warning">
                        <span class="fa fa-pencil"></span>
                    </button>
                </div>
            </div>

            <div class="d-flex flex-row col-12 mt-2 mb-2">
                <label class="col-3 col-form-label" for="horarioOperadorFormControl">Horario Operacion</label>
                <input type="text" class="form-control" formControlName="horarioOperacionFormControl" />
                <div class="col-4"></div>
            </div>

            <div class="card-footer d-flex justify-content-center">
                <span class="border">
                    <button type="button" class="btn btn-primary" (click)="onClick()" [disabled]="guardando">
                        <span *ngIf="!guardando">Guardar cambios</span>
                        <div *ngIf="guardando" class="d-flex align-items-center">
                            <div class="spinner-border text-light spinner-border-sm" role="status"></div>
                            <span>Guardando...</span>
                        </div>
                    </button>
                </span>
            </div>
        </div>
    </form>
</div>