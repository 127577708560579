import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Comprobante } from 'src/app/shared/models/facturacion/Comprobante';
import { ComprobanteService } from '../comprobante.service';
import { ComprobanteEncabezado } from 'src/app/shared/models/facturacion/ComprobanteEncabezado';
import { ModalConfirmComponent } from 'src/app/shared/generic-modals/modal-confirm.component';

// import {  ConfirmDialogModule } from "../confirm-dialog/confirm-dialog.module.ts";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalOkComponent } from 'src/app/shared/generic-modals/modal-ok.component';
@Component({
  selector: 'app-credit-memo-panel',
  templateUrl: './credit-memo-panel.component.html',
  styleUrls: ['./credit-memo-panel.component.css']
})
export class CreditMemoPanelComponent implements OnInit {

  msgError: boolean = false;
  // facturaObtenida: Comprobante;
  existe: boolean;
  emitiendoNota: boolean = false;
  remitosLiberados: ComprobanteEncabezado[];
  @Input() facturaObtenida: Comprobante;

  @Output() notaCreditoChange = new EventEmitter<Comprobante>();


  constructor(private comprobanteService: ComprobanteService, public modal: NgbModal) { }

  ngOnInit() {
    this.existe = false;
    this.msgError = false;
    this.remitosLiberados = this.facturaObtenida.factura.remitos;
    console.log(this.facturaObtenida)
  }

  emitirNotaCredito(comprobante: Comprobante) {
    this.emitiendoNota = true;
    if (comprobante.factura.estado === 3) //si la factura está anulada
    {
      let modalRefConfirm = this.modal.open(ModalOkComponent, { centered: true });
      modalRefConfirm.componentInstance.mensaje = "No puede emitir una nota de credito de una factura ya anulada";
      modalRefConfirm.result.then(() => {
      });
    }
    else {
      
      console.log("Emitir Nota de Credito");
      this.comprobanteService.emitirNotaCredito(comprobante.prefijo, comprobante.numeroComprobante, comprobante.tipoMovimiento)
        .subscribe(result => {
          //envio nota de crédito para usar en el otro component
          if (result) {
            this.notaCreditoChange.emit(result);
            if (this.remitosLiberados.length > 0){
              this.existe = true;
              this.emitiendoNota = false;
            }else{
              this.emitiendoNota = false;
              this.modal.dismissAll();
            }
          } else {
            this.msgError = true;
            this.emitiendoNota = false;
            error => console.log("anda el error", error)
          }
        });
    }

  }


}

