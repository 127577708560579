import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ProductoService } from 'src/app/abm/producto/producto.service';
import { UsuarioService } from 'src/app/account/usuario.service';
import { TipoContenedor } from 'src/app/shared/enums/tipoContenedor';
import { AeroEspecialidadItem } from 'src/app/shared/models/despacho/AeroespecialidadItem';
import { Producto } from 'src/app/shared/models/despacho/Producto';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { Conductor } from 'src/app/shared/models/stock/Conductor';
import { Contenedor } from 'src/app/shared/models/stock/Contenedor';
import { DetalleAeroespecialidad, RemitoSalida } from 'src/app/shared/models/stock/RemitoSalida';
import { Responsable } from 'src/app/shared/models/stock/Responsable';
import { SalidaDetalleCombustible } from 'src/app/shared/models/stock/SalidaDetalleCombustible';
import { StockAeroespecialidad } from 'src/app/shared/models/stock/StockAeroespecialidad';
import { Terminal } from 'src/app/shared/models/stock/Terminal';
import { Transportista } from 'src/app/shared/models/stock/Transportista';
import { SharedService } from 'src/app/shared/shared.service';
import { MessageService } from 'src/app/shell/message.service';
import { StockService } from '../../stock.service';
import { CalculosService } from 'src/app/shared/calculos.service';

@Component({
  selector: 'app-emision-remito',
  templateUrl: './emision-remito.component.html',
  styleUrls: ['./emision-remito.component.css']
})
export class EmisionRemitoComponent implements OnInit {

  enumTipoContendor = TipoContenedor;

  cargandoContenedores: boolean = false;
  guardandoDatos: boolean = false;
  error: string;
  esMayor: boolean = false;

  contenedores: Contenedor[] = [];
  contenedoresFiltrados: Contenedor[];
  contenedoresSeleccionados: Contenedor[] = [];

  aeroespecialidades: StockAeroespecialidad[] = [];
  aeroespecialidadesFiltradas: StockAeroespecialidad[];
  aeroespecialidadesSeleccionadas: StockAeroespecialidad[] = [];
  cargandoAeroespecialidades: boolean = false;

  transportistas: Transportista[] = [];
  cargandoTransportistas: boolean;
  errorTransportistas: boolean;
  modelTransportista: any;

  conductores: Conductor[] = [];
  cargandoConductores: boolean;
  errorConductores: boolean;
  modelConductor: any;

  patentes: string[] = [];

  terminales: Terminal[];
  cargandoTerminales: boolean;
  errorTerminales: boolean;
  modelTerminal: any;
  aeroplantaSoloPad: boolean;

  productos: Producto[] = [];
  productoSeleccionado: Producto;

  mostrarCombustible: boolean;
  mostrarAeroespecialidades: boolean;
  validarAforador:boolean = false;
  sumaCombustible: number = 0;

  form: UntypedFormGroup;
  listaDetalleFormControl: UntypedFormGroup[] = [];
  decimalRegex = /^[0-9]+(.[0-9]{0,4})?$/;
  negativeDecimalRegex = /^[+-]?[0-9]+(.[0-9]{0,4})?$/;
  positiveInteger = /^\+?(0|[1-9]\d*)$/;

  constructor(private messageService: MessageService,
    private formBuilder: UntypedFormBuilder,
    public activeModal: NgbActiveModal,
    private stockService: StockService,
    private usuarioService: UsuarioService,
    private productoService: ProductoService,
    private sharedService: SharedService,
    private calculoService: CalculosService) { }

  ngOnInit() {
    this.aeroplantaSoloPad = this.usuarioService.getAeroplantaActual().soloPAD;
    this.getProductos();
    this.crearFormulario();
    this.obtenerContenedores();
    this.obtenerTerminals();
    this.obtenerTransportistas();
    this.obtenerConductores();
    this.obtenerAeroespecialidades();

    this.mostrarCombustible = true;
    this.mostrarAeroespecialidades = false;
  }

  crearFormulario() {
    this.form = this.formBuilder.group({
      nroOnuFromControl: ['', Validators.required],
      certificadoFormControl: ['', Validators.required],
      cantidadFromControl: [''], //, Validators.required
      cantidad15FromControl: [''], //, Validators.required
      temperaturaFromControl: [''], // ['', [Validators.required, this.temperaturaValidator]],
      densidadFormControl: [''], //Validators.required],
      contenedorFormControl: [''],
      aeroespecialidadFormControl: [''],
      patenteFormControl: [''],
      cuitTransportistaFromControl: ['', Validators.required],
      nombreTransportistaFromControl: ['', Validators.required],
      domicilioTransportistaFromControl: ['', Validators.required],
      documentoConductorFromControl: ['', Validators.required],
      nombreConductorFromControl: ['', Validators.required],
      precintoDesdeFromControl: ['', Validators.required],
      precintoHastaFromControl: ['', Validators.required],
      capacidadFromControl: ['', Validators.required],
      terminalDestinoFormControl: ['', Validators.required],
      detalleFormControlArray: this.formBuilder.array([]),
      aeroespecialidadesFormControlArray: this.formBuilder.array([]),
      comentarioFormControl: [''],
      comentarioOnuFormControl: [''],
      prSupFromControl: ['',Validators.required],
      prefijoFoxFormControl: [this.aeroplantaSoloPad? 0:'', Validators.required],
      numeroFoxFormControl: [this.aeroplantaSoloPad? 0:'', Validators.required]
    });

    this.form.setValidators(this.formValidator.bind(this));
  }

  formValidator(group: UntypedFormGroup): any {
    if (group) {
      if (this.patentes.length == 0) {
        return { alMenosUnaPatenteValidator: true }
      }

      return null;
    }
  }

  calcularCantidad(i: number) {
    this.esMayor = false;
    this.detalleFormControlArray.controls[i].get('cantidad').setValue(this.detalleFormControlArray.controls[i].value.aforadorFinal - this.detalleFormControlArray.controls[i].value.aforadorInicial);
    this.esMayor = this.controlarCantidad(this.detalleFormControlArray.controls[i].value.aforadorInicial, this.detalleFormControlArray.controls[i].value.aforadorFinal);
    this.validarAforador = this.esMayor;
    if (!isNaN(this.detalleFormControlArray.controls[i].value.cantidad)) { this.litrosA15GradosPorElemento(i); }
  }

  controlarCantidad(inicio: number, fin: number): boolean {
    let p: boolean = Number(inicio) > Number(fin) ? true : false;
    return p
  }

  usuarioTildoCombustible(checked){
    if(checked){
      this.mostrarCombustible = true;
    }else{
      this.mostrarCombustible = false;
    }
  }

  usuarioTildoAeroespecialidades(checked){
    if(checked){
      this.mostrarAeroespecialidades = true;
    }else{
      this.mostrarAeroespecialidades = false;
    }
  }

  obtenerAeroespecialidades() {
    this.cargandoAeroespecialidades = true;
    this.stockService.getAeroespecialidades(this.usuarioService.getAeroplantaActual().codigoAeroplanta)
      .subscribe(result => {
        this.aeroespecialidades = result;
        if (this.aeroespecialidades.length > 0) {
          this.aeroespecialidadFormControl.setValue(this.aeroespecialidades[0]);
        }
        this.cargandoAeroespecialidades = false;
      }, (err: ErrorModel) => {
        this.messageService.showErrorMessage('Hubo un error al obtener las aeroespecialidades. ' + err.description);
        this.cargandoAeroespecialidades = false;
      });
  }

  obtenerContenedores() {
    this.cargandoContenedores = true;

    this.stockService.getTodosLosContenedores(this.usuarioService.getAeroplantaActual().codigoAeroplanta)
      .subscribe(listaContenedores => {
        this.contenedores = listaContenedores.filter(c => c.tipoContenedor == TipoContenedor.Abastecedora || c.tipoContenedor == TipoContenedor.Tanque);
        this.cargandoContenedores = false;
        this.filtrarContenedores();
      }, () => { this.cargandoContenedores = false; });
  }

  obtenerTerminals(): void {
    this.cargandoTerminales = true;
    this.stockService.getTerminales()
      .subscribe(terminales => {
        terminales = terminales.map(a => Object.assign(new Terminal(), a));
        this.terminales = terminales.sort((a, b) => { if (a.nombre > b.nombre) { return 1 } else { return -1 } });
        this.cargandoTerminales = false;
      }, () => this.errorTerminales = true);
  }

  obtenerTransportistas(): void {
    this.cargandoTransportistas = true;
    this.stockService.getTransportistas()
      .subscribe(transportistas => {
        transportistas = transportistas.map(a => Object.assign(new Transportista(), a));
        this.transportistas = transportistas;
        this.cargandoTransportistas = false;
      }, () => this.errorTransportistas = true);
  }

  obtenerConductores(): void {
    this.cargandoConductores = true;
    this.stockService.getConductores()
      .subscribe(conductores => {
        conductores = conductores.map(a => Object.assign(new Conductor(), a));
        this.conductores = conductores;
        this.cargandoConductores = false;
      }, () => this.errorConductores = true);
  }

  getProductos() {
    this.productoService.obtenerProductos()
      .subscribe(result => {
        this.productos = result;
      }, () => {

      });
  }

  filtrarContenedores() {
    if (this.contenedoresFiltrados != null) {
      if (this.contenedoresSeleccionados.length > 0) {
        var codigoProducto = this.contenedoresSeleccionados[0].productoActual.codigoProducto;

        let contenedoresAux = this.contenedores;
        this.contenedoresFiltrados = contenedoresAux.filter(c => c.productoActual.codigoProducto == codigoProducto);
      } else {
        this.contenedoresFiltrados = this.contenedores;
      }
    } else {
      this.contenedoresFiltrados = this.contenedores;
    }
  }

  agregarContenedorSeleccionado() {
    if (this.contenedorFormControl.value) {
      this.detalleFormControlArray.push(this.nuevoDetalleItem());
      this.contenedoresSeleccionados.push(this.contenedorFormControl.value);

      if (this.contenedorFormControl.value.tipoContenedor == TipoContenedor.Abastecedora) {
        let y = this.contenedoresSeleccionados.indexOf(this.contenedorFormControl.value)
        this.detalleFormControlArray.controls[y].get('picoInicialFormControl').setValue(this.contenedorFormControl.value.picos[0])
      }

      this.contenedores = this.contenedores.filter(c => c.codigo != this.contenedorFormControl.value.codigo);

      this.filtrarContenedores();

      if (this.contenedoresFiltrados.length > 0) {
        this.contenedorFormControl.setValue(this.contenedoresFiltrados[0]);
      } else {
        this.contenedorFormControl.setValue(null);
      }
    }
  }

  agregarAeroespeciliadadSeleccionada() {
    if (this.aeroespecialidadFormControl.value) {
      this.aeroespecialidadesSeleccionadas.push(this.aeroespecialidadFormControl.value);
      this.aeroespecialidadesFormControlArray.push(this.nuevaAeroespecialidad());
      let p = this.aeroespecialidades.indexOf(this.aeroespecialidadFormControl.value);
      this.aeroespecialidades.splice(p, 1);

      if (this.aeroespecialidades.length > 0) {
        this.aeroespecialidadFormControl.setValue(this.aeroespecialidades[0]);
      } else {
        this.aeroespecialidadFormControl.setValue(null);
      }
    }
  }

  nuevaAeroespecialidad(): UntypedFormGroup {
    return this.formBuilder.group({
      codigoAeroespecialidad: [this.aeroespecialidadFormControl.value.aeroespecialidad.codigoProducto, Validators.required],
      nombreAeroespecilidad: [this.aeroespecialidadFormControl.value.aeroespecialidad.nombreProducto, Validators.required],
      cantidad: ['', Validators.required],
    });
  }

  onContenedorSeleccionadoEliminado(i) {
    var codigoContenedor = this.detalleFormControlArray.controls[i].value.codigoTanque;
    var contenedor = this.contenedoresSeleccionados.filter(c => c.codigo == codigoContenedor);

    this.detalleFormControlArray.removeAt(i);
    this.contenedoresSeleccionados = this.contenedoresSeleccionados.filter(c => c.codigo != contenedor[0].codigo);
    this.contenedores.push(contenedor[0]);
    this.filtrarContenedores();
    if (this.contenedoresFiltrados.length > 0) {
      this.contenedorFormControl.setValue(this.contenedoresFiltrados[0]);
    }
  }

  eliminarAeroespecialidad(i: number){    
    var codigoAep = this.aeroespecialidadesFormControlArray.value[i].codigoAeroespecialidad;
    var aero = this.aeroespecialidadesSeleccionadas.filter(x => x.aeroespecialidad.codigoProducto == codigoAep);
    this.aeroespecialidades.push(aero[0]);
    this.aeroespecialidadesFormControlArray.removeAt(i);
    let p = this.aeroespecialidadesSeleccionadas.indexOf(aero[0]);
    this.aeroespecialidadesSeleccionadas.splice(p, 1);
    if (this.aeroespecialidades.length > 0) {
      this.aeroespecialidadFormControl.setValue(this.aeroespecialidades[0]);
    }
  }

  nuevoDetalleItem(): UntypedFormGroup {
    return this.formBuilder.group({
      codigoTanque: [this.contenedorFormControl.value.codigo, Validators.required],
      nombreTanque: [{value: this.contenedorFormControl.value.nombre, disabled: true}, Validators.required],
      cantidad: ['', this.contenedorFormControl.value.tipoContenedor == TipoContenedor.Tanque ? Validators.required : null],
      cantidad15: ['', Validators.required],
      aforadorInicial: ['', this.contenedorFormControl.value.tipoContenedor == TipoContenedor.Abastecedora ? Validators.required : null],
      aforadorFinal: ['', this.contenedorFormControl.value.tipoContenedor == TipoContenedor.Abastecedora ? Validators.required : null],
      picoInicialFormControl: ['', this.contenedorFormControl.value.tipoContenedor == TipoContenedor.Abastecedora ? Validators.required : null],
    })
  }

  agregarPatente() {
    if (this.patenteFormControl.value != null) {
      this.patentes.push(this.patenteFormControl.value);
      this.patenteFormControl.setValue(null);
    }
  }

  onEliminarPatente(patente) {
    this.patentes = this.patentes.filter(p => p != patente);
  }

  searchTerminal = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => this.terminales == null || term.length < 2 || this.cargandoTerminales ? [] :
        this.terminales.filter(op => this.containsString(this.terminalDestinoFormControl.value, op.nombre, op.codigoTerminal)).slice(0, 10))
    )

  formatterTerminal = (x: { nombre: string, codigoTerminal: string }) => `${x.nombre} - ${x.codigoTerminal}`;

  onTerminalChange(terminal): void {

  }

  searchTransportista = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => this.transportistas == null || term.length < 2 || this.cargandoTransportistas ? [] :
        this.transportistas.filter(t => this.containsString(this.cuitTransportistaFromControl.value, t.cuit, t.nombre)).slice(0, 10))
    )

  formatterTransportistaResult = (x: { nombre: string, cuit: string }) => ` ${x.cuit == null ? 'SIN CUIT' : x.cuit} - ${x.nombre}`;

  formatterTransportistaInput = (x: { nombre: string, cuit: string }) => `${x.cuit}`;

  selectTransportista(event: any): void {
    var transportista = event.item;
    this.cuitTransportistaFromControl.setValue(transportista.cuit);
    this.domicilioTransportistaFromControl.setValue(transportista.domicilio);
    this.nombreTransportistaFromControl.setValue(transportista.nombre);
  }

  searchConductor = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => this.conductores == null || term.length < 2 || this.cargandoConductores ? [] :
        this.conductores.filter(t => this.containsString(this.documentoConductorFromControl.value, t.nombre, t.documento)).slice(0, 10))
    )

  formatterConductorResult = (x: { nombre: string, documento: string }) => ` ${x.documento == null ? 'SIN DOC.' : x.documento} - ${x.nombre}`;

  formatterConductorInput = (x: { nombre: string, documento: string }) => `${x.documento}`;

  selectConductor(event: any): void {
    var conductor = event.item;
    this.documentoConductorFromControl.setValue(conductor.documento);
    this.nombreConductorFromControl.setValue(conductor.nombre);
  }
  containsString(searchValue: string, ...searchIn: string[]): boolean {
    const concat = searchIn.toString();
    return concat.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
  }

  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  onlyDecimalNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode == 44 || event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57);
  }

  accionarForm(){
    Object.keys(this.form.controls).forEach(key => {
      this.form.get(key).markAsDirty();
    });
  }

  formatDensidad(event) {
    if (event.charCode == 8 || event.charCode == 0) return null;

    if (event.charCode >= 48 && event.charCode <= 57) {
      this.densidadFormControl.setValue(this.sharedService.formatDensidad(event));
    }
    return false;
  }

  onSubmit() {

    this.accionarForm();
   
    this.guardandoDatos = true;

    var remitoSalida = new RemitoSalida();
    remitoSalida.codigoAeroplanta = this.usuarioService.getAeroplantaActual().codigoAeroplanta;
    remitoSalida.prefijoFOX = Number(this.prefijoFoxFormControl.value);
    remitoSalida.numeroFOX = Number(this.numeroFoxFormControl.value);
    remitoSalida.numeroONU = this.nroOnuFromControl.value;
    remitoSalida.comentarioOnu = this.comentarioOnuFormControl.value;
    remitoSalida.comentario = this.comentarioFormControl.value;
    remitoSalida.prSup = this.prSupFromControl.value;
    remitoSalida.numeroCertificadoAnalisis = this.certificadoFormControl.value;
    remitoSalida.codigoTerminalDestino = this.terminalDestinoFormControl.value.codigoTerminal;    
    remitoSalida.capacidadCamion = Number(this.capacidadFromControl.value);
    remitoSalida.precintoDesde = this.precintoDesdeFromControl.value;
    remitoSalida.precintoHasta = this.precintoHastaFromControl.value;
    remitoSalida.patentes = this.patentes;
    remitoSalida.conductor = this.armarConductor();
    remitoSalida.transportista = this.armarTransportista();
    remitoSalida.temperatura = Number(this.temperaturaFromControl.value);
    remitoSalida.densidad = Number(this.densidadFormControl.value);

    remitoSalida.responsable = this.armarResponsable();

    if(this.mostrarCombustible) {
      remitoSalida.salidaCombustibleDetalles = this.armarDetalleIngreso();
    } else {
      remitoSalida.salidaCombustibleDetalles = null;
    }
    

    if(this.mostrarAeroespecialidades) {
      remitoSalida.detalleAeroespecialidades = this.procesarAeroespecialidades();
    } else {
      remitoSalida.detalleAeroespecialidades = null;
    }
    

    if(!this.form.valid || this.validarAforador){
      this.error = "Revise los campos incompletos";
      this.guardandoDatos = false;
      return ;
    }else if( !this.datosValidos() ){    
      // El mensaje de error se setea en datosValidos()
      this.guardandoDatos = false;      
      return;
    }        
    
    this.stockService.emisionRemitoSalida(remitoSalida)
      .subscribe(rta => {
        this.guardandoDatos = false;
        this.activeModal.close(true);
        this.sumaCombustible = 0;
      },
        (err: ErrorModel) => {
          this.error = err.description;
          this.guardandoDatos = false;
          this.sumaCombustible = 0;
        });

  }

  procesarAeroespecialidades() {
    let listaAeroespecialidades = new Array<DetalleAeroespecialidad>();

    if (this.form != null) {
      for (let i = 0; i < this.aeroespecialidadesFormControlArray.length; i++) {
        let cantidad = this.aeroespecialidadesFormControlArray.value[i].cantidad;
        let codigo = this.aeroespecialidadesFormControlArray.value[i].codigoAeroespecialidad;

        let item = new DetalleAeroespecialidad();
        item.cantidad = Number(cantidad);
        item.codigoProducto = codigo;
        
        listaAeroespecialidades.push(item);
      }
    }

    return listaAeroespecialidades;
  }
  
  armarResponsable() {
    var r = new Responsable();
    r.UserName = this.usuarioService.getUsername();
    r.NombreCompleto = this.usuarioService.getNombresCompletosUsuario();
    r.DNI = this.usuarioService.getDniUsuario();

    return r;
  }

  armarDetalleIngreso() {
    var listaDetalle: SalidaDetalleCombustible[] = [];
    var array = (this.form.controls.detalleFormControlArray as UntypedFormArray);

    for (let i = 0; i < this.detalleFormControlArray.controls.length; i++) {
      let codigoTanque = this.detalleFormControlArray.controls[i].value.codigoTanque;
      let cantidad = this.detalleFormControlArray.controls[i].value.cantidad;
      let cantidad15 = this.detalleFormControlArray.controls[i].value.cantidad15;
      let aforadorInicial = this.detalleFormControlArray.controls[i].value.aforadorInicial;
      let aforadorFinal = this.detalleFormControlArray.controls[i].value.aforadorFinal;
      let pico = this.detalleFormControlArray.controls[i].value.picoInicialFormControl.id;

      var detalle = new SalidaDetalleCombustible();
      detalle.codigoContenedor = codigoTanque;
      detalle.volumen = Number(cantidad);
      detalle.volumen15 = Number(cantidad15);
      detalle.aforadorInicial = Number(aforadorInicial);
      detalle.aforadorFinal = Number(aforadorFinal);
      detalle.picoId = pico;

      listaDetalle.push(detalle);
      this.sumaCombustible += Number(cantidad);
    }
    return listaDetalle;
  }

  armarConductor() {
    var c = new Conductor();
    c.documento = this.obtenerCampoDocumentoConductor();
    c.nombre = this.nombreConductorFromControl.value;

    return c;
  }

  armarTransportista() {
    var t = new Transportista();
    t.cuit = this.obtenerCampoCuitTransportista();
    t.nombre = this.nombreTransportistaFromControl.value;
    t.domicilio = this.domicilioTransportistaFromControl.value;

    return t;
  }

  obtenerCampoCuitTransportista() {
    if (typeof (this.cuitTransportistaFromControl.value) === 'string') {
      return this.cuitTransportistaFromControl.value;
    } else {
      return this.cuitTransportistaFromControl.value.cuit;
    }
  }

  obtenerCampoDocumentoConductor() {
    if (typeof (this.documentoConductorFromControl.value) === 'string') {
      return this.documentoConductorFromControl.value;
    } else {
      return this.documentoConductorFromControl.value.documento;
    }
  }

  datosValidos() {
    this.error = null;
    
    if (this.patentes.length == 0) {
      this.error = "Debe agregar una patente.";
      return false;
    }

    let guionExp = /\-/gi
    if (!this.cuitValido(this.obtenerCampoCuitTransportista().replace(guionExp, ""))) {
      this.error = "El campo cuit del transportista no es valido.";
      return false;
    }

    let puntoExp = /\./gi
    if (!this.positiveInteger.test(this.obtenerCampoDocumentoConductor().replace(puntoExp, ""))) {
      this.error = "El campo documento conductor solo debe tener numeros.";
      return false;
    }

    if(!this.mostrarCombustible && !this.mostrarAeroespecialidades){
      this.error = "Debe seleccionar al menos un dato a cargar.";
      return false;
    }

    if(this.mostrarCombustible){
      if ((this.contenedoresSeleccionados.length == 0) ) {
        this.error = "Debe agregar un contenedor.";
        return false;
      }

      if ((!this.positiveInteger.test(this.cantidadFromControl.value))) {
        this.error = "El campo cantidad debe ser entero valido.";
        return false;
      }
  
      if ((!this.negativeDecimalRegex.test(this.temperaturaFromControl.value))) {
        this.error = "El campo temperatura debe ser decimal valido.";
        return false;
      }
  
      if ((!this.decimalRegex.test(this.densidadFormControl.value))) {
        this.error = "El campo densidad debe ser decimal valido.";
        return false;
      }
  
      if(!this.temperaturaValidator(this.temperaturaFromControl.value)) {
        this.error = "La temperatura esta fuera de rango permitido.";
        return false;
      }

      if ((!this.validarDensidad(this.densidadFormControl.value))) {
        this.error = "La densidad debe estar entre el rango [" + this.productoSeleccionado.densidadMin + "," + this.productoSeleccionado.densidadMax + "]";
        return false;
      }
    }
  
    if(this.mostrarAeroespecialidades){      
      if ((this.aeroespecialidadesFormControlArray.length == 0) ) {
        this.error = "Debe agregar al menos una aeroespecialidad.";
        return false;
      }
    }
    
    if(this.sumaCombustible != this.cantidadFromControl.value){
      console.log(this.sumaCombustible + "  " + this.cantidadFromControl.value)
      this.error = "La suma de volumen de los contenedores debe igualar la cantidad total.";
      this.sumaCombustible = 0;
      return false;
    }
    
    return true;
  }

  validarDensidad(densidad) {
    try {
      if (densidad != null) {
        let esValido = true;

        if (this.contenedoresSeleccionados.length > 0) {
          var codigoProducto = this.contenedoresSeleccionados[0].productoActual.codigoProducto;
          this.productoSeleccionado = this.productos.find(element => element.codigoProducto === codigoProducto);

          esValido = (densidad >= this.productoSeleccionado.densidadMin && densidad <= this.productoSeleccionado.densidadMax);

        }

        return esValido;
      }
    } catch (ex) {
      console.log("Excepcion validacion densidad.");
    }
  }

  litrosA15GradosTotal() {
    let res: number = this.calculoService.obtenerCTLNuevo(this.temperaturaFromControl.value, this.densidadFormControl.value, this.cantidadFromControl.value);
    this.cantidad15FromControl.setValue(res);
  }

  litrosA15GradosPorElemento(i) {
    var cantidad = this.detalleFormControlArray.controls[i].value.cantidad;
    let res: number = this.calculoService.obtenerCTLNuevo(this.temperaturaFromControl.value, this.densidadFormControl.value, cantidad);
    this.detalleFormControlArray.controls[i].get('cantidad15').setValue(res);
  }

  litrosA15GradosTodosLosElementos() {
    this.cantidad15FromControl.enable();
    this.litrosA15GradosTotal();

    for (let i = 0; i < this.detalleFormControlArray.controls.length; i++) {
      this.litrosA15GradosPorElemento(i);
    }
    this.cantidad15FromControl.disable();
  }

  temperaturaValidator(valor) {
    var temperatura = Number(valor);

    if (temperatura > 100 || temperatura <= -50) {
      return false;
    }

    return true;
  }

  compareTerminales(d1: any, d2: any): boolean {
    return d1 && d2 ? d1.id === d2.id : d1 === d2;
  }

  cuitValido(cuil: string): boolean {
    if (cuil.length !== 11) {
      return false;
    }

    const [checkDigit, ...rest] = cuil
      .split('')
      .map(Number)
      .reverse();

    const total = rest.reduce(
      (acc, cur, index) => acc + cur * (2 + (index % 6)),
      0,
    );

    const mod11 = 11 - (total % 11);

    if (mod11 === 11) {
      return checkDigit === 0;
    }

    if (mod11 === 10) {
      return false;
    }

    return checkDigit === mod11;
  }

  get nroOnuFromControl() {
    return this.form.controls.nroOnuFromControl;
  }

  get comentarioOnuFormControl() {
    return this.form.controls.comentarioOnuFormControl;
  }

  get comentarioFormControl() {
    return this.form.controls.comentarioFormControl;
  }

  get prSupFromControl() {
    return this.form.controls.prSupFromControl;
  }

  get certificadoFormControl() {
    return this.form.controls.certificadoFormControl;
  }

  get cantidadFromControl() {
    return this.form.controls.cantidadFromControl;
  }

  get cantidad15FromControl() {
    return this.form.controls.cantidad15FromControl;
  }

  get temperaturaFromControl() {
    return this.form.controls.temperaturaFromControl;
  }

  get densidadFormControl() {
    return this.form.controls.densidadFormControl;
  }

  get contenedorFormControl() {
    return this.form.controls.contenedorFormControl;
  }
  
  get patenteFormControl() {
    return this.form.controls.patenteFormControl;
  }

  get cuitTransportistaFromControl() {
    return this.form.controls.cuitTransportistaFromControl;
  }

  get nombreTransportistaFromControl() {
    return this.form.controls.nombreTransportistaFromControl;
  }

  get domicilioTransportistaFromControl() {
    return this.form.controls.domicilioTransportistaFromControl;
  }

  get documentoConductorFromControl() {
    return this.form.controls.documentoConductorFromControl;
  }

  get nombreConductorFromControl() {
    return this.form.controls.nombreConductorFromControl;
  }

  get precintoDesdeFromControl() {
    return this.form.controls.precintoDesdeFromControl;
  }

  get precintoHastaFromControl() {
    return this.form.controls.precintoHastaFromControl;
  }

  get capacidadFromControl() {
    return this.form.controls.capacidadFromControl;
  }

  get terminalDestinoFormControl() {
    return this.form.controls.terminalDestinoFormControl;
  }

  get detalleFormControlArray(): UntypedFormArray {
    return this.form.controls.detalleFormControlArray as UntypedFormArray;
  }

  get prefijoFoxFormControl() {
    return this.form.controls.prefijoFoxFormControl;
  }

  get numeroFoxFormControl() {
    return this.form.controls.numeroFoxFormControl;
  }

  get aeroespecialidadFormControl() {
    return this.form.controls.aeroespecialidadFormControl;
  }

  get aeroespecialidadesFormControlArray(): UntypedFormArray {
    return this.form.controls.aeroespecialidadesFormControlArray as UntypedFormArray;
  }
  
}