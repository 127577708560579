import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { UsuarioService } from 'src/app/account/usuario.service';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';
import { Usuario } from 'src/app/shared/models/despacho/Usuario';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { Grupo } from 'src/app/shared/models/notificaciones/Grupo';
import { GrupoEncabezado } from 'src/app/shared/models/notificaciones/GrupoEncabezado';
import { Rol } from 'src/app/shared/models/notificaciones/Rol';
import { MessageService } from 'src/app/shell/message.service';
import { NotificacionesService } from '../../notificaciones.service';

@Component({
  selector: 'app-upsert-form-grupo',
  templateUrl: './upsert-form-grupo.component.html',
  styleUrls: ['./upsert-form-grupo.component.scss']
})

export class UpsertFormGrupoComponent implements OnInit {

  TipoUsuario = TipoUsuario;

  @Input() grupoEncabezadoSeleccionado: GrupoEncabezado;
  @Output() usuarioGuardoGrupo: EventEmitter<Grupo>= new EventEmitter<Grupo>();

  grupoSeleccionado: Grupo;
  listaUsuarios: Usuario[] = [];
  usuariosSeleccionados: Usuario[] = [];
  listaRoles: Rol[] = [];
  rolesSeleccionados: Rol[] = [];

  error: string;
  cargandoDatos: boolean;
  guardandoDatos: boolean;
  cargandoUsuarios: boolean;
  cargandoRoles: boolean;


  grupoForm = new UntypedFormGroup({
    tituloFormControl: new UntypedFormControl(null, Validators.required),
    rolFormControl: new UntypedFormControl(null),
    usuarioFormControl: new UntypedFormControl(null),
    activoFormControl: new UntypedFormControl(null)
  });

  constructor(private usuarioService: UsuarioService,
    private notificacionesService: NotificacionesService,
    private spinner: NgxSpinnerService,
    public activeModal: NgbActiveModal,
    public authService: AuthService,
    private messageService: MessageService) { }

  ngOnInit(): void {
    if(!this.estaEditandoGrupo()){      
      this.obtenerRoles();
      this.iniciarDatosFormulario();
    }   
  }

  ngOnChanges(){
    if (this.estaEditandoGrupo()) {
      this.mostrarSpinner();
      this.obtenerUsuarios();
      this.procesarRolesGrupos();
    }
  }

  async procesarRolesGrupos() {

    this.cargandoRoles = true;
    this.listaRoles = await this.usuarioService.obtenerTodosLosRolesPromise();
    this.cargandoRoles = false;

    this.obtenerGrupoSeleccionado(this.grupoEncabezadoSeleccionado.id);
  }

  iniciarDatosFormulario(){
    this.activoFormControl.setValue(true);
  }

  estaEditandoGrupo() {
    if (this.grupoEncabezadoSeleccionado) {
      return true;
    } else {
      return false;
    }
  }

  obtenerUsuarios() {
    this.cargandoUsuarios = true;
    this.usuarioService.obtenerTodosLosUsuarios(true, false)
      .subscribe(usuarios => {
        this.listaUsuarios = usuarios;
        this.cargandoUsuarios = false;
      },
        (err: ErrorModel) => {
          console.log(err);
          this.cargandoUsuarios = false;
        });
  }

  obtenerRoles() {
    this.cargandoRoles = true;
    this.usuarioService.obtenerTodosLosRoles()
      .subscribe(roles => {
        this.listaRoles = roles;
        this.cargandoRoles = false;
      },
        (err: ErrorModel) => {
          console.log(err);
          this.cargandoRoles = false;
        });
  }

  obtenerGrupoSeleccionado(id) {
    this.notificacionesService.getGrupoPorId(id)
      .subscribe(
        grupo => {
          this.grupoSeleccionado = grupo;
          this.cargarDatosDeGrupo();
          this.ocultarSpinner();
        },
        (err: ErrorModel) => {
          console.error(err);
          this.ocultarSpinner();
        }
      );
  }

  cargarDatosDeGrupo() {
    this.usuariosSeleccionados = this.grupoSeleccionado.usuarios;
    
    this.tituloFormControl.setValue(this.grupoSeleccionado.titulo);
    this.activoFormControl.setValue(this.grupoSeleccionado.activo);

    this.rolesSeleccionados = this.grupoSeleccionado.roles.map(
      rolGrupo => this.listaRoles.find(
        rol => {
          let claves = Object.keys(TipoUsuario).filter((x) => Number.isNaN(Number(x)));
          return rol.nombre.replace("-", "_").toUpperCase() == claves[rolGrupo];
        })
    );

    console.log(this.usuariosSeleccionados);
    console.log(this.rolesSeleccionados);
  }

  agregarUsuario() {    
    if ( typeof this.usuarioFormControl.value === 'string' || this.usuarioFormControl.value == null) {
      this.usuarioFormControl.setValue(null);
    }else{
      if(this.usuariosSeleccionados.some( u => u.id == this.usuarioFormControl.value.id)){
        this.messageService.showErrorMessage("El usuario ingresado ya esta en la lista");
        return;
      }

      this.error = null;
      this.usuariosSeleccionados.push(this.usuarioFormControl.value);
      this.usuarioFormControl.setValue(null);
    }
  }

  borrarUsuario(usuario) {
    this.usuariosSeleccionados = this.usuariosSeleccionados.filter(x => x.id != usuario.id);
  }

  formatterUsuario = (u: { nombresCompletos: string }) => `${u.nombresCompletos}`;

  searchUsuario = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => this.listaUsuarios == null || term.length < 2 || this.cargandoDatos ? [] :
        this.listaUsuarios.filter(u => this.containsString(this.usuarioFormControl.value, u.nombresCompletos)).slice(0, 10))
    )

  selectUsuario(event: any): void {
    this.usuarioFormControl.setValue(event.item);
  }

  containsString(searchValue: string, ...searchIn: string[]): boolean {
    const concat = searchIn.toString();
    return concat.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
  }

  agregarRol() {
    if ( typeof this.rolFormControl.value === 'string' || this.rolFormControl.value == null) {
      this.rolFormControl.setValue(null);
    }else{
      if(this.rolesSeleccionados.some( r => r.id == this.rolFormControl.value.id)){
        this.messageService.showErrorMessage("El rol ingresado ya esta en la lista");
        return;
      }

      this.error = null;
      this.rolesSeleccionados.push(this.rolFormControl.value);
      this.rolFormControl.setValue(null);
    }
  }

  borrarRol(rol) {
    this.rolesSeleccionados = this.rolesSeleccionados.filter(x => x.id != rol.id);
  }

  formatterRol = (op: { nombre: string }) => `${op.nombre}`;

  searchRol = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => this.listaRoles == null || term.length < 2 || this.cargandoDatos ? [] :
        this.listaRoles.filter(op => this.containsString(this.rolFormControl.value, op.nombre)).slice(0, 10))
    )

  selectRol(event: any): void {
    this.rolFormControl.setValue(event.item);
  }

  mostrarSpinner() {
    this.spinner.show('spinnerUpsertGrupo');
    this.cargandoDatos = true;
  }

  ocultarSpinner() {
    this.spinner.hide('spinnerUpsertGrupo');
    this.cargandoDatos = false;
  }

  guardarCambios() {
    this.accionarForm();

    if(this.formularioValido()){
      this.guardandoDatos = true;

      let g = new Grupo();
      g.titulo = this.tituloFormControl.value;
      g.activo = this.activoFormControl.value;
      g.roles = this.rolesSeleccionados.map( r => TipoUsuario[r.nombre.replace("-", "_").toUpperCase()] );
      g.usuarios = this.usuariosSeleccionados;

      if(this.estaEditandoGrupo()){
        g.id = this.grupoSeleccionado.id;
        this.notificacionesService.modificarGrupo(g)
          .subscribe(grupo => {
            this.messageService.showSuccessMessage("Se modifico el grupo exitosamente.");
            this.usuarioGuardoGrupo.emit(grupo);
            this.guardandoDatos = false;
          }, (err: ErrorModel) => {
            this.error = err.message + ". " + err.description;
            this.guardandoDatos = false;
          });
      }else{
        g.roles.push(TipoUsuario.SEGURIDADTABLAS, TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.IMPUESTOS, TipoUsuario.PRECIOS, TipoUsuario.APROBADORPRECIOS, TipoUsuario.FACTURACION, TipoUsuario.REFACTURACION);

        this.notificacionesService.crearGrupo(g)
          .subscribe( grupo => {
            this.messageService.showSuccessMessage("Se creo el grupo exitosamente.");
            this.guardandoDatos = false;
            this.activeModal.close(grupo);
          }, (err: ErrorModel) => {
            this.error = err.message + ". " + err.description;
            this.guardandoDatos = false;
          });
      }
    }
  }

  formularioValido(){
    if(this.estaEditandoGrupo()){
      if(this.usuariosSeleccionados.length == 0){
        this.error = "Debe agregar al menos un usuario.";
        return false;
      }
    }

    if( this.authService.hasRole(TipoUsuario.SEGURIDADTABLAS, TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.IMPUESTOS, TipoUsuario.PRECIOS, TipoUsuario.APROBADORPRECIOS, TipoUsuario.FACTURACION, TipoUsuario.REFACTURACION) ){
      if(this.rolesSeleccionados.length == 0){
        this.error = "Debe agregar al menos un rol.";
        return false;
      }
    }

    return true;
  }

  accionarForm() {
    Object.keys(this.grupoForm.controls).forEach(key => {
      this.grupoForm.get(key).markAsDirty();
    });
  }

  /** getters */

  get tituloFormControl() {
    return this.grupoForm.get('tituloFormControl');
  }

  get rolFormControl() {
    return this.grupoForm.get('rolFormControl');
  }

  get usuarioFormControl() {
    return this.grupoForm.get('usuarioFormControl');
  }
  get activoFormControl() {
    return this.grupoForm.get('activoFormControl');
  }

}
