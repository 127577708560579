import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-adjuntos',
  templateUrl: './modal-adjuntos.component.html',
  styleUrls: ['./modal-adjuntos.component.scss']
})
export class ModalAdjuntosComponent implements OnInit {

    // datos de entrada
    @Input() archivosAdjuntos: string[];

    constructor(public activeModal: NgbModal) { }

  ngOnInit(): void {
  }

}
