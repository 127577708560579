import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NuevaAbastecedoraComponent } from './nueva-abastecedora/nueva-abastecedora.component';
import { Abastecedora } from 'src/app/shared/models/stock/Abastecedora';
import { MessageService } from 'src/app/shell/message.service';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { UsuarioService } from 'src/app/account/usuario.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AbastecedoraService } from '../../abastecedoras.service';
import { Subscription } from 'rxjs';
import { VisorPicosComponent } from './nueva-abastecedora/visor-picos/visor-picos.component';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-abastecedoras',
  templateUrl: './abastecedoras.component.html',
  styleUrls: ['./abastecedoras.component.css']
})
export class AbastecedorasComponent implements OnInit {

  TipoUsuario = TipoUsuario;

  abastecedoras: Abastecedora[] = [];
  tablaAbastecedora: UntypedFormGroup;
  messageAbastecedoras: string = "C a r g a n d o . . ."

  obteniendoAbastecedoras: boolean = false;
  change$: Subscription;
  aeroplantaCod: string;

  constructor( private authService: AuthService, 
    private messageService: MessageService,
    private modalService: NgbModal,
    private abastecedoraService: AbastecedoraService,
    private fb: UntypedFormBuilder,
    private usuarioService: UsuarioService,
    private spinner: NgxSpinnerService,
    sharedService: SharedService) {
      this.tablaAbastecedora = this.fb.group({
        abastecedoras: new UntypedFormArray([])
      });
      this.change$ = sharedService.aeroplantaSeleccionada.subscribe(_=>{
        this.recargarPagina()
      })
     }

  // hooks

  ngOnInit() {
    this.aeroplantaCod=this.usuarioService.getAeroplantaActual().codigoAeroplanta;
    this.getAbastecedoras();
    this.abastecedoras = [];
  }

  ngOnDestroy(): void {
    this.change$.unsubscribe();   
  }
  
  // llamadas al service

  getAbastecedoras(){
    this.spinner.show('spinnerAbastecedora');
    this.obteniendoAbastecedoras = true;
    this.abastecedoraService.getAbastecedoras(this.aeroplantaCod)
      .subscribe(result => {
        this.abastecedoras = result;
        if (this.abastecedoras.length > 0)
        {
          this.addCheckboxes();
          this.spinner.hide('spinnerAbastecedora');
          this.obteniendoAbastecedoras = false;
        }
        else
        {
          this.abastecedoras = [];
          this.spinner.hide('spinnerAbastecedora');
          this.obteniendoAbastecedoras = false;
        }

      })
  }

  // form

  agregarAbastecedora(arrayAbastecedora: Abastecedora[]){
    let modalRef = this.modalService.open(NuevaAbastecedoraComponent, { centered: true, backdrop: 'static' });
    modalRef.componentInstance.arrayAbastecedora = arrayAbastecedora;
    modalRef.result.then((result: Abastecedora) => {
      this.getAbastecedoras();
      if (result) this.messageService.showSuccessMessage('Se creó la abastecedora correctamente.');
    }, () => { this.messageService.showErrorMessage('Hubo un problema en la creación, por favor pruebe de nuevo.') });
  }


  editarAbastecedora(abastecedora: Abastecedora){
    let modalRef = this.modalService.open(NuevaAbastecedoraComponent, { centered: true, backdrop: 'static' });
    modalRef.componentInstance.abastecedora = abastecedora;
    modalRef.result.then((result: Abastecedora) => {
      this.messageService.showSuccessMessage('Se editó la abastecedora correctamente.');
    }, () => {});
  }

  private addCheckboxes() {
    // reinicio el formulario
    this.tablaAbastecedora.setControl('abastecedoras', this.fb.array([]));
    this.abastecedoras.map((o, i) => {
      const control = new UntypedFormControl(i === 0); // if first item set to true, else false
      control.setValue(o.activo);
      (this.tablaAbastecedora.controls.abastecedoras as UntypedFormArray).push(control);
    });
  }

  actualizarAbastecedora(abast: Abastecedora){
    abast.activo ? abast.activo = false : abast.activo = true;
    this.abastecedoraService.editarAbastecedora(this.aeroplantaCod ,abast)
      .subscribe(result => {
        result.activo ? this.messageService.showSuccessMessage('Se activó la abastecedora correctamente.') : this.messageService.showErrorMessage('Se desactivó la abastecedora correctamente.');
      })
  }

  recargarPagina() {
    this.aeroplantaCod =this.usuarioService.getAeroplantaActual().codigoAeroplanta;
    this.getAbastecedoras();
  }

  verPicos(abastecedora: Abastecedora){
    let modalRef = this.modalService.open(VisorPicosComponent, {centered: true, backdrop: 'static'});
    modalRef.componentInstance.nombreContenedor = abastecedora.nombre;
    modalRef.componentInstance.picos = abastecedora.picos;
  }

  hasRole(...roles: TipoUsuario[]) {
    return this.authService.hasRole(...roles);
  }

}
