import { ComponentFactoryResolver, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HandleError, HttpErrorHandler } from '../http-error-handler.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Comprobante } from '../shared/models/facturacion/Comprobante';
import { catchError, map, tap } from 'rxjs/operators';
import { AuthService } from '../core/authentication/auth.service';
import { Rubro } from '../shared/models/despacho/Rubro';
import { AlicuotaIva } from '../shared/models/despacho/AlicuotaIva';
import { NotaDebito } from '../shared/models/facturacion/NotaDebito';
import { AlicuotaTipoBaseIIBB } from '../shared/models/facturacion/AlicuotaTipoBaseIIBB';
import { Remito } from '../shared/models/facturacion/Remito';
import { Factura } from '../shared/models/facturacion/Factura';
import { Cliente } from '../shared/models/cliente/Cliente';
import { Tarjeta } from '../shared/models/facturacion/Tarjeta';
import { FacturaRespuesta } from '../shared/models/facturacion/FacturaRespuesta';
import { EmitirFactura } from '../shared/models/facturacion/EmitirFactura';
import { ImporteFactura } from '../shared/models/facturacion/ImporteFactura';
import { ExportarRemitos } from '../shared/models/facturacion/ExportarRemitos';
import { Holdeo } from '../shared/models/facturacion/Holdeo';
import { Aerovale } from '../shared/models/despacho/Aerovale';
import { EjecucionFacturacion } from '../shared/models/facturacion/EjecucionFacturacion';
import { RemitoFox } from '../shared/models/despacho/RemitoFox';
import { Aeroplanta } from '../shared/models/despacho/Aeroplanta';
import { FacturaCierreCaja } from '../shared/models/cierreTurno/FacturaCierreCaja';
import { DocumentosCierreCaja } from '../shared/models/cierreTurno/DocumentosCierreCaja';
import { CierreCaja } from '../shared/models/cierreTurno/CierreCaja';
import { RemitoEncabezado } from '../shared/models/facturacion/remitoEncabezado';
import { ModificacionDto } from '../shared/models/precios/ModificacionDto';
import { ComprobanteEncabezado } from '../shared/models/facturacion/ComprobanteEncabezado';
import { saveAs } from 'file-saver';
import { RetiroEfectivo } from '../shared/models/cierreTurno/RetiroEfectivo';
import { isNullOrUndefined } from '../shared/compat-tools';
import { CondicionVenta } from '../shared/enums/condicionVenta';

@Injectable({
  providedIn: 'root'
})
export class ComprobanteService {

  prefijo: number;
  numero: number;

  baseUrl = environment.apiServer.facturacionBaseUrl;
  clientesBaseUrl = environment.apiServer.clientesBaseUrl
  productosUrl = environment.apiServer.productosUrl;
  despachoUrl = environment.apiServer.baseUrl;
  notificacionUrl = environment.apiServer.notificacionesBaseUrl;
  private comprobanteUrl = this.baseUrl + '/ComprobantesV1';
  private parametrosURL = this.baseUrl + '/FacturacionV1/AlicuotaIIBBTipoBaseImponible';
  private creditoUrl = this.baseUrl + '/FacturacionV1/NotaCredito';
  private debitoUrl = this.baseUrl + '/FacturacionV1/NotaDebito';
  private remitosSinFacturarUrl = this.baseUrl + '/FacturacionV1/';
  private pagosUrl = this.baseUrl + '/PagosV1';
  private rubrosUrl = this.productosUrl + '/RubrosV1';
  private alicuotasUrl = this.baseUrl + '/ConfiguracionesV1/AlicuotasIva';
  private clientesUrl = this.clientesBaseUrl + '/ClientesV1/';

  private handleError: HandleError;


  constructor(private httpClient: HttpClient, private httpErrorHandler: HttpErrorHandler, private authservice: AuthService) {
    this.handleError = httpErrorHandler.createHandleError("comprobantes")
  }


  obtenerComprobantePorId(idComprobante: string): Observable<Comprobante> {
    return this.httpClient.get<Comprobante>(this.comprobanteUrl + '/' + idComprobante,
      {

      })
      .pipe(

        map(a => a == null ? null : Object.assign(new Comprobante(), a)),
        tap(_ => console.log(`fetched Comprobante id=${idComprobante}`)),
        catchError(this.handleError('Obtener comprobante', null))
      );
  }

  obtenerComprobantePorPrefijoNumeroTipo(prefijo: number, numero: number, tipoMovimiento: number): Observable<Comprobante> {
    return this.httpClient.get<Comprobante>(this.comprobanteUrl,
      {
        params: {
          prefijo: prefijo.toString(),
          numero: numero.toString(),
          tipoMovimiento: tipoMovimiento.toString()
        }
      })
      .pipe(

        map(a => a == null ? null : Object.assign(new Comprobante(), a)),
        tap(_ => console.log(`fetched numero= ${numero}`)),
        // catchError(this.handleError('', null))
      );
  }
  
  obtenerComprobantesPorFiltro(page, pageSize, body):Promise<ComprobanteEncabezado[]> {
    return this.httpClient.post<ComprobanteEncabezado[]>(this.comprobanteUrl+`/ObtenerComprobantesPorFiltro?page=${page}&pageSize=${pageSize}`, body).toPromise();    
  }

  emitirNotaCredito(prefijo: number, numero: number, tipoMovimiento: number): Observable<Comprobante> {
    return this.httpClient.post<Comprobante>(this.creditoUrl + "?prefijo=" + prefijo + "&numero=" + numero + "&tipoMovimiento=" + tipoMovimiento,
      {
        params: {
          prefijo: prefijo.toString(),
          numero: numero.toString(),
          tipoMovimiento: tipoMovimiento.toString()
        }
      }
    )
      .pipe(
        map(a => a == null ? null : Object.assign(new Comprobante(), a)),
        tap(_ => console.log(`fetched numero= ${numero}`)),
        catchError(this.handleError('Obtener nota credito', null))
      );
  }

  getRubros(): Observable<Rubro[]> {
    return this.httpClient.get<Rubro[]>(this.rubrosUrl)
  }

  getAlicuotasIva(): Observable<AlicuotaIva> {
    return this.httpClient.get<AlicuotaIva>(this.alicuotasUrl)
  }

  getParametrosImpositivos(cuentaCTC: string, codigoAeroplanta: string): Observable<AlicuotaTipoBaseIIBB> {
    return this.httpClient.get<AlicuotaTipoBaseIIBB>(this.parametrosURL + "?CTC=" + cuentaCTC + "&codigoAeroplanta=" + codigoAeroplanta)
  }

  emitirNotaDebito(renglones: NotaDebito): Observable<Comprobante> {

    return this.httpClient.post<Comprobante>(this.debitoUrl, renglones)
      .pipe(
        map(a => a == null ? null : Object.assign(new Comprobante(), a)),
        tap(_ => console.log(`fetched numero= ${renglones.numero}`)),
        catchError(this.handleError('Emitir nota de débito', null))
      );
  }

  obtenerComprobanteIdSegunAerovale(idAerovale: string): Observable<ImporteFactura> {
    return this.httpClient.get<ImporteFactura>(this.comprobanteUrl + "/comprobanteId/" + idAerovale, {
      params: {
        conEstado: 'true'
      }
    })
  }

  obtenerRemitosSinFacturarPorSGC(SGC: string, fecha: string): Observable<Remito[]> {
    return this.httpClient.get<Remito[]>(this.comprobanteUrl + "/RemitosSinFacturar/" + SGC + '/filters?fechaHasta=' + fecha)
  }
  obtenerRemitosSinFacturarPorSGCYAeroplanta(SGC: string, aeroplantaCodigo: string, fecha: string): Observable<Remito[]> {
    return this.httpClient.get<Remito[]>(this.comprobanteUrl + "/RemitosSinFacturar/" + SGC + '/filters?codigoAeroplanta=' + aeroplantaCodigo + '&fechaHasta=' + fecha)
  }

  obtenerRemitosSoloSinFacturar(estadoRemito: number, fechaDesde: string, fechaHasta: string): Observable<RemitoEncabezado[]> {
    return this.httpClient.get<RemitoEncabezado[]>(this.baseUrl + "/FacturacionV1?estadoRemito=" + estadoRemito + '&fechaDesde=' + fechaDesde + '&fechaHasta=' + fechaHasta);
  }

  getRemitosSinFacturarTodos(): Observable<Remito[]> {
    return this.httpClient.get<Remito[]>(this.comprobanteUrl + '/RemitosSinFacturar')
  }

  obtenerFacturaCalculada(remitos: string[]): Observable<Factura[]> {
    return this.httpClient.post<Factura[]>(this.baseUrl + '/FacturacionV1/IdRemitosAFacturar', remitos);
  }

  getClientesPrevioPago(): Observable<Cliente[]> {
    return this.httpClient.get<Cliente[]>(this.clientesUrl + 'CuentasCorrientesPrevioPago');
  }
  
 
  getClientes(): Observable<Cliente[]> {
    return this.httpClient.get<Cliente[]>(this.clientesUrl + 'CuentasCorrientes');
  }

  emitirFactura(factura: EmitirFactura[], ctaCte: boolean): Observable<Factura[]> {
    return this.httpClient.put<Factura[]>(this.baseUrl + '/FacturacionV1/EmitirFacturas?esCtaCte=' + ctaCte, factura)
  }
  getTarjetas(): Observable<Tarjeta[]> {
    return this.httpClient.get<Tarjeta[]>(this.pagosUrl + '/Tarjetas');
  }

  obtenerImporteAFacturar(listaDeIdsAerovale: string[]): Observable<FacturaRespuesta> {
    return this.httpClient.post<FacturaRespuesta>(this.baseUrl + '/FacturacionV1/Total', listaDeIdsAerovale)
      .pipe(
        map(a => a == null ? null : Object.assign(new Factura(), a))
      );
  }

  facturar(facturaId: string, emitirFactura: EmitirFactura) {
    return this.httpClient.put<FacturaRespuesta>(this.baseUrl + '/FacturacionV1/Facturar?facturaId=' + facturaId, emitirFactura)
      .pipe(
        map(a => a == null ? null : Object.assign(new Factura(), a))
      );
  }

  exportarRemitosDeFactura(prefijo:number,numero:number,tipoMovimiento:number) {
    const url = `${environment.apiServer.facturacionBaseUrl}/ComprobantesV1/Remitos/Exportar?prefijo=${prefijo.toString()}&numero=${numero.toString()}&tipoMovimiento=${tipoMovimiento.toString()}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });
    return this.httpClient.get(url, { headers: headers, responseType: 'blob' as 'json' })
  }

  exportarRemitos(busqueda: ExportarRemitos) {
    const url = `${environment.apiServer.facturacionBaseUrl}/ComprobantesV1/Remitos/Exportar/Filtros`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });
    return this.httpClient.put(url, busqueda, { headers: headers, responseType: 'blob' as 'json' })
  }

  exportarFacturas(busqueda: ExportarRemitos) {
    const url = `${environment.apiServer.facturacionBaseUrl}/ComprobantesV1/Facturas/Exportar/Filtros`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });
    return this.httpClient.put(url, busqueda, { headers: headers, responseType: 'blob' as 'json' })
  }

  getHoldeo(tipo: number): Observable<Holdeo[]> {
    return this.httpClient.get<Holdeo[]>(this.baseUrl + '/HoldeosV1?estado=' + tipo);
  }

  guardarHoldeo(holdeo: Holdeo): Observable<Holdeo> {
    return this.httpClient.post<Holdeo>(this.baseUrl + '/HoldeosV1', holdeo);
  }

  putHoldeo(holdeo: Holdeo): Observable<Holdeo> {
    return this.httpClient.put<Holdeo>(this.baseUrl + '/HoldeosV1/' + holdeo.id, holdeo);
  }

  buscarAerovale(prefijo: string, numero: string): Observable<Aerovale> {
    return this.httpClient.get<Aerovale>(this.despachoUrl + '/AerovalesV2/Encabezado?prefijo=' + prefijo + '&numeroAerovale=' + numero);
  }

  getEjecuciones(fechadesde: string, fechaHasta: string, tipoReporte: boolean): Observable<EjecucionFacturacion[]> {
    return this.httpClient.get<EjecucionFacturacion[]>(this.baseUrl + '/FacturacionV1/EjecucionesFacturacion?fechaDesde=' + fechadesde + '&fechaHasta=' + fechaHasta + '&soloConError=' + tipoReporte);
  }

  getEjecucionesPorId(id: string, tipoReporte: boolean): Observable<EjecucionFacturacion> {
    return this.httpClient.get<EjecucionFacturacion>(this.baseUrl + '/FacturacionV1/EjecucionesFacturacion/' + id + '?soloConError=' + tipoReporte);
  }

  getRemitoAerovale(id: string): Observable<RemitoFox> {
    return this.httpClient.get<RemitoFox>(this.baseUrl + '/ComprobantesV1/Remito/aerovaleId=' + id);
  }

  putDatosAeroplanta(aep: Aeroplanta): Observable<Aeroplanta> {
    return this.httpClient.put<Aeroplanta>(this.baseUrl + '/AeroplantasV1', aep);
  }

  getDatosAeroplanta(aepCodigo: string): Observable<Aeroplanta[]> {
    if (aepCodigo != null) {
      return this.httpClient.get<Aeroplanta[]>(this.baseUrl + '/AeroplantasV1?codigoAeroplanta=' + aepCodigo);
    } else {
      return this.httpClient.get<Aeroplanta[]>(this.baseUrl + '/AeroplantasV1');
    }
  }

  putNumeracionFox(remito: RemitoFox): Observable<RemitoFox> {
    return this.httpClient.put<RemitoFox>(this.baseUrl + '/ComprobantesV1/RemitoFOX', remito)
  }

  getFacturasContado(aeroplantaCod: string): Observable<FacturaCierreCaja[]> {
    return this.httpClient.get<FacturaCierreCaja[]>(this.baseUrl + '/CierresCajasV1/FacturasContado?codigoAeroplanta=' + aeroplantaCod);
  }

  postCierreCaja(documentos: DocumentosCierreCaja): Observable<DocumentosCierreCaja> {
    return this.httpClient.post<DocumentosCierreCaja>(this.baseUrl + '/CierresCajasV1', documentos);
  }

  getCierresCaja(aeroplantaCodigo: string, desde: string, hasta: string): Observable<CierreCaja[]> {
    if (desde != null && hasta != null) {
      return this.httpClient.get<CierreCaja[]>(this.baseUrl + '/CierresCajasV1?codigoAeroplanta=' + aeroplantaCodigo + '&fechaDesde=' + desde + '&fechaHasta=' + hasta);
    } else {
      return this.httpClient.get<CierreCaja[]>(this.baseUrl + '/CierresCajasV1?codigoAeroplanta=' + aeroplantaCodigo);
    }    
  }

  getRetiros(aeroplantaCodigo: string, desde: string, hasta: string): Observable<RetiroEfectivo[]> {
    if (desde != null && hasta != null) {
      return this.httpClient.get<RetiroEfectivo[]>(this.baseUrl + '/CierresCajasV1/RetirosEfectivo?codigoAeroplanta=' + aeroplantaCodigo + '&fechaDesde=' + desde + '&fechaHasta=' + hasta);
    } else {
      return this.httpClient.get<RetiroEfectivo[]>(this.baseUrl + '/CierresCajasV1/RetirosEfectivo?codigoAeroplanta=' + aeroplantaCodigo);
    }    
  }

  getRetiroPorId(id: string): Observable<RetiroEfectivo> {
    return this.httpClient.get<RetiroEfectivo>(this.baseUrl + '/CierresCajasV1/RetiroEfectivo/' + id);    
  }

  getRemitosVencidos(): Observable<Comprobante[]> {
    return this.httpClient.get<Comprobante[]>(this.baseUrl + '/ComprobantesV1/RemitosVencidos');
  }

  getFacturasCC(tipoPago: number, estadoFactura: number, fechaDesde: string, fechaHasta: string): Observable<Comprobante[]> {
    return this.httpClient.get<Comprobante[]>(this.baseUrl + '/ComprobantesV1/Facturas?tipoPago=' + tipoPago
      + '&estadoFactura=' + estadoFactura + '&fechaDesde=' + fechaDesde + '&fechaHasta=' + fechaHasta + '&sinRefacturacion=true');
  }

  getFacturasSegunPrecio(id: string): Observable<ComprobanteEncabezado[]> {
    return this.httpClient.get<ComprobanteEncabezado[]>(this.baseUrl + '/FacturacionV1/FacturasPorPrecio?precioId=' + id);
  }

  descargarFactura(comprobante: Comprobante) {
    return this.httpClient.get(this.notificacionUrl + '/NotificacionesV1/DescargarFactura?facturaId=' + comprobante.id
    , {  responseType: 'blob' as 'json' });
    
  }

  postRetiroEfectivo(nuevoRetiro: RetiroEfectivo): Observable<RetiroEfectivo>{
    return this.httpClient.post<RetiroEfectivo>(this.baseUrl + '/CierresCajasV1/RetiroDeEfectivo', nuevoRetiro);
  }

  putAeroplanta(aeroplanta: Aeroplanta): Observable<Aeroplanta> {
    return this.httpClient.put<Aeroplanta>(this.baseUrl + '/AeroplantasV1/Abm', aeroplanta)
  }

  getRemitosSinFacturar(codigoAeroplanta: string): Observable<Remito[]> {
    return this.httpClient.get<Remito[]>(this.remitosSinFacturarUrl + 'ObtenerRemitosContadoSinFacturar?codigoAeroplanta=' + codigoAeroplanta)
  }
}
