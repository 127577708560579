<form [formGroup]="form" (ngSubmit)="onSubmit()">

  <div class="modal-header bg-light">
    <h4 class="modal-title">Nueva medición</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()"></button>
  </div>

  <div class="modal-body">

    <div class="row">
      <div class="mb-3 row col-md-12">
        <label for="tanqueFormControl" class="col-sm-5 col-form-label">*Tanque</label>
        <div class="col-sm-7">
          <select id="tanqueFormControl" class="form-select" ngbTooltip="Seleccione el tanque."  formControlName="tanqueFormControl" (change)="onSeleccionoTanque($event)">
            <option *ngFor="let t of tanques" [ngValue]="t">
              {{ t.codigo }} - {{ t.nombre }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="mb-3 row col-md-12">

        <label for="fechaFormControl" class="col-sm-5 col-form-label">*Fecha y hora medición:</label>
        <div #fechaHora class="col-sm-7">
          <div class="input-group mb-2">
            <input type="date" class="form-control" formControlName="fechaFormControl" placeholder="aaaa-mm-dd"
              name="dp" title="Campo obligatorio." [(ngModel)]="modelFechaMedicion" ngbDatepicker #l="ngbDatepicker"
              readonly />
            <div class="input-group-append">
              <button type="button" class="input-group-btn btn btn-secondary" (click)="l.toggle()">
                <i class="fa fa-calendar" aria-hidden="true"></i>
              </button>
            </div>
          </div>
          <ngb-timepicker [(ngModel)]="modelHoraLlegada" [meridian]="true" formControlName="horaFormControl" [readonlyInputs]="true"></ngb-timepicker>
          <div class="alert alert-danger" *ngIf="form.invalid && this.form.hasError('fechaMedicionMayorAhora')">
            Esta cargando una fecha de llegada en el futuro.
          </div>
        </div>

      </div>
    </div>

    <div class="row" *ngIf="this.tanqueGraduacion">
      <div class="mb-3 d-flex col-md-12">
        <label for="nivelVarilladoFormControl" class="col-md-5 col-form-label">*Nivel varillado:</label>
        <div class="input-group">
          <input class="form-control" formControlName="nivelVarilladoFormControl" maxlength="50" (blur)="onBlurNivelVarillado($event.target)" (keypress)="this.onlyDecimalPositivo($event)">
          <span class="input-group-text">Mm.</span>          
          <div *ngIf="nivelVarilladoFormControl.invalid && nivelVarilladoFormControl.errors.required"
            class="col-md-12 alert alert-danger">Requerido</div>
        </div>
      </div>
    </div>

    <div class="row mb-4">
      <div class="mb-3 col-md-6">
        <label for="cantidadNaturalFormControl" class="col-sm-12 col-form-label">*Cant. natural:</label>
        <div class="input-group">
          <input class="form-control" [readonly]="this.tanqueFormControl.value.tieneGraduacion" formControlName="cantidadNaturalFormControl">
          <span class="input-group-text">lts.</span>          
        </div>
      </div>

      <div class="mb-3 col-md-6">
        <label for="cantidad15FormControl" class="col-sm-12 col-form-label">*Cant. 15°:</label>
        <div class="input-group">
          <input class="form-control" readonly formControlName="cantidad15FormControl">
          <span class="input-group-text">lts.</span>          
        </div>
      </div>

      <loading-text-spinner *ngIf="calculandoCantidadNeta" class="col-md-12" class="col-md-12" message="Calculando cantidades por varillado..."color="primary"></loading-text-spinner>
      <div *ngIf="!calculandoCantidadNeta && !medicionesParaTanque" class="alert alert-warning"> No se encontraron mediciones para el tanque</div>
    </div>

    <div class="row">
      <div class="mb-3 d-flex col-md-12">
        <label for="temperaturaFormControl" class="col-md-5 col-form-label">*Temperatura:</label>
        <div class="input-group">
          <input class="form-control" formControlName="temperaturaFormControl" maxlength="10" (blur)="onBlurTemperatura($event.target)" (keypress)="this.onlyDecimalPositivo($event)">
          <span class="input-group-text">ºC</span>          
          <div *ngIf="temperaturaFormControl.invalid && temperaturaFormControl.errors.required"
            class="col-md-12 alert alert-danger">Requerido</div>
            <div *ngIf="(this.temperaturaFormControl.value>100 || this.temperaturaFormControl.value<-50) "
            class="col-md-12 alert alert-warning">Debe elegir una temperatura entre -50 y 100 grados</div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="mb-3 d-flex col-md-12">
        <label for="densidadFormControl" class="col-md-5 col-form-label">*Densidad:</label>
        <div class="input-group">
          <input class="form-control" formControlName="densidadFormControl" maxlength="10" (input)="onBlurDensidad($event.target)" (keypress)="formatDensidad($event)">          
          <div class="input-group-text">Gr/cm</div>          
          <div *ngIf="densidadFormControl.invalid && densidadFormControl.errors.required "
            class="col-md-12 alert alert-danger">Requerido</div>
            <div *ngIf="!this.validarDensidad(this.densidadFormControl.value)"
            class="col-md-12 alert alert-danger">La densidad debe estar en el rango [{{this.esDensidadMin}} , {{this.esDensidadMax}}]</div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="mb-3 row col-md-12">
        <label for="nivelDeAguaFormControl" class="col-md-5 col-form-label">*Nivel de agua:</label>
        <div class="col-md-7">
          <input class="form-control" formControlName="nivelDeAguaFormControl" maxlength="10" (keypress)="this.onlyDecimalPositivo($event)">
          <div *ngIf="nivelDeAguaFormControl.invalid && nivelDeAguaFormControl.errors.required"
            class="col-md-12 alert alert-danger">Requerido</div>
        </div>
      </div>
    </div>

    <div class="modal-footer bg-light">
      <span class="border">
        <button class="btn btn-primary" [disabled]="!form.valid || guardando" type="submit">
          {{ guardando? "Guardando..." : "Guardar cambios" }}
        </button>
      </span>
      <span class="border">
        <button type="button" class="btn btn-secondary" [disabled]="guardando" (click)="activeModal.dismiss('Close clicked')">Cancelar</button>
      </span>
    </div>
  </div>
</form>
