export class ExportarRemitos
{
  codigosAeroplantas: string [];
  codigosProductos: string [];
  condicionVenta: number [];
  estados: number [];
  tipoDocumento: number;
  numeroDocumento: string;
  ctas_sgc: string[];
  fechaDesde:string; // en back es dateTime
  fechaHasta:string; // en back es dateTime
  ordenamiento:number;
}
