import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TipoMovimiento } from 'src/app/shared/models/facturacion/TipoMovimiento';
import { Contenedor } from 'src/app/shared/models/stock/Contenedor';
import { DetalleStockPorContenedor } from 'src/app/shared/models/stock/DetalleStockPorContenedor';
import { StockService } from '../../../stock.service';

@Component({
  selector: 'app-nuevo-movimiento',
  templateUrl: './nuevo-movimiento.component.html',
  styleUrls: ['./nuevo-movimiento.component.css']
})
export class NuevoMovimientoComponent implements OnInit {

  contenedores: Contenedor;
  contenedoresDeUnProducto: DetalleStockPorContenedor[] = [];
  contenedoresDeUnProductoDestino: DetalleStockPorContenedor[] = [];
  guardandoMovimiento: boolean= false;
  tipoMovimiento: TipoMovimiento;

  movimientoForm = new UntypedFormGroup({
    tipoMovimientoFormControl: new UntypedFormControl({ value: '', disabled: true }, Validators.required),
    remitoFormControl: new UntypedFormControl(null, [Validators.required, Validators.pattern('[0,9]{1,3}')]),
    origenFormControl: new UntypedFormControl(null, Validators.required),
    destinoFormControl: new UntypedFormControl(null, Validators.required),
    cantidadFormControl: new UntypedFormControl(null, [Validators.required]),
    temperaturaFormControl: new UntypedFormControl(),
    aforadorInicialFormControl: new UntypedFormControl(null, Validators.required),
    aforadorFinalFormControl: new UntypedFormControl(null, Validators.required),
    formGraficoStock: new UntypedFormControl(),
    motivoFormControl: new UntypedFormControl(null, [Validators.max(254), Validators.required])
  })

  constructor(stockService: StockService) { }

  ngOnInit() {
  }

  onlyDecimalNumberKey(event) {
    let charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57))
      return false;
    return true;
  }

  chequearDisponibilidadOrigen() {
    // if (this.movimientoCarga.value == 1) { //Transferencia.
    //   for (let i = 0; this.contenedoresDeUnProducto.length > i; i++) {
    //     if (this.contenedoresDeUnProducto[i].contenedor.id == this.origen.value) {
    //       let aux = 0;
    //       aux = this.contenedoresDeUnProducto[i].stock;
    //       if (aux < this.cantidadLts.value) {
    //         this.origenSobrecarga = aux - this.cantidadLts.value;
    //         this.esMayorOrigen = true;
    //       } else {
    //         this.origenSobrecarga = 0;
    //         this.esMayorOrigen = false;
    //       }
    //     }
    //     if (this.contenedoresDeUnProductoDestino[i].contenedor.id == this.destino.value) {
    //       let aux = 0;
    //       aux = this.contenedoresDeUnProductoDestino[i].contenedor.capacidad - this.contenedoresDeUnProductoDestino[i].stock;
    //       if (aux < this.cantidadLts.value) {
    //         this.destinoSobrecarga = this.cantidadLts.value - aux;
    //         this.esMayorDestino = true;
    //       } else {
    //         this.destinoSobrecarga = 0;
    //         this.esMayorDestino = false;
    //       }
    //     }
    //   }
    // }
    // if (this.movimientoCarga.value == 0) { //Ajuste Manual.
    //   for (let i = 0; this.contenedoresDeUnProducto.length > i; i++) {
    //     if (this.contenedoresDeUnProducto[i].contenedor.id == this.origen.value) {
    //       let aux = 0;
    //       aux = this.contenedoresDeUnProducto[i].contenedor.capacidad;
    //       if (aux < this.cantidadLts.value) {
    //         this.esAjusteMayor = true;
    //         this.sobrecarga = this.cantidadLts.value - aux;
    //       } else {
    //         this.sobrecarga = 0;
    //         this.esAjusteMayor = false;
    //       }
    //     }
    //   }
    // }
    // if (this.movimientoCarga.value == 2) { //Rebombeo.
    //   for (let i = 0; this.contenedoresDeUnProducto.length > i; i++) {
    //     if (this.contenedoresDeUnProducto[i].contenedor.id == this.origen.value) {
    //       let aux = 0;
    //       aux = this.contenedoresDeUnProducto[i].stock;
    //       if (aux < this.cantidadLts.value) {
    //         this.esRebombeoMayor = true;
    //       } else {
    //         this.esRebombeoMayor = false;
    //       }
    //     }
    //   }
    //   if (this.aforadorInicial.value != null && this.aforadorFinal.value != null) {
    //     this.cantidadLts.setValue((this.aforadorFinal.value - this.aforadorInicial.value));
    //   }
    // }
  }

  filtrarContenedores()
  {

  }

  getDetalleContenedores(aeroplantaCOD: string, producotoCodigo: string) {
    // this.stockService.getDetalleProducto(aeroplantaCOD, producotoCodigo)
    //   .subscribe(result => {
    //     this.contenedoresDeUnProducto = result;
    //     this.contenedoresDeUnProductoDestino = result;
    //     this.cargandoDestinos = false;
    //     this.cargandoOrigen = false;
    //     this.destino.enable();
    //     this.movimientoCarga.enable();
    //   }, () => this.errorDestinos = true);
  }

  generarMovimiento()
  {

  }

  limpiarForm()
  {

  }

  keys(): Array<string> {
    var keys = Object.keys(this.tipoMovimiento);
    return keys.slice(keys.length / 2);
  }

  ocultarElementos() {
    // let movimientoCapturado = this.movimientoCarga.value;
    // switch (movimientoCapturado) {
    //   case 0: //Ajuste manual.
    //     document.getElementById("labelAforadorFinal").style.display = "none";
    //     document.getElementById("aforadorFinalFormControl").style.display = "inline";
    //     document.getElementById("labelDestino").style.display = "none";
    //     document.getElementById("destinoFormControl").style.display = "none";
    //     document.getElementById("remitoFormControl").style.display = "inline";
    //     document.getElementById("labelNumeroRemito").style.display = "inline";
    //     document.getElementById("labelAforadorInicial").style.display = "none";
    //     document.getElementById("aforadorInicialFormControl").style.display = "none";
    //     document.getElementById("aforadorInicialFormControl").style.display = "none";
    //     document.getElementById("destinoAjusteFormControl").style.display = "inline";
    //     document.getElementById("origenFormControl").style.display = "none";
    //     document.getElementById("motivoFormControl").style.display = "inline";
    //     document.getElementById("labelmotivoFormControl").style.display = "inline";
    //     document.getElementById("temperaturaAjusteFormControl").style.display = "inline";
    //     document.getElementById("labelAjusteNumeroRemito").style.display = "inline";
    //     document.getElementById("labelNumeroRemito").style.display = "none";
    //     document.getElementById("labelAjusteAforadorFinal").style.display = "inline";
    //     //Se deshabilitan controles para campos que no son necesarios en este movimiento.
    //      this.numeroRemito.clearValidators();
    //      this.aforadorFinal.clearValidators();
    //      this.destino.clearValidators();
    //      this.aforadorInicial.clearValidators();
    //      this.aforadorInicial.updateValueAndValidity();
    //      this.destino.updateValueAndValidity();
    //      this.aforadorFinal.updateValueAndValidity();
    //      this.numeroRemito.updateValueAndValidity();
    //     //Se habilitan controles para los campos obligatorios.
    //      this.aforadorInicial.enable();
    //      this.cantidadLts.enable();
    //      this.motivo.setValidators([Validators.max(254), Validators.required]);
    //      this.motivo.updateValueAndValidity();

    //     this.getDetalleContenedores(this.aeroplantaCodActual, this.codProductoModal);
    //     break;
    //   case 2: //rebombeo.
    //     document.getElementById("remitoFormControl").style.display = "none";
    //     document.getElementById("labelNumeroRemito").style.display = "none";
    //     document.getElementById("labelDestino").style.display = "none";
    //     document.getElementById("destinoFormControl").style.display = "none";
    //     document.getElementById("labelAforadorInicial").style.display = "inline";
    //     document.getElementById("aforadorInicialFormControl").style.display = "inline";
    //     document.getElementById("labelAforadorFinal").style.display = "inline";
    //     document.getElementById("aforadorFinalFormControl").style.display = "inline";
    //     document.getElementById("destinoAjusteFormControl").style.display = "none";
    //     document.getElementById("origenFormControl").style.display = "inline";
    //     document.getElementById("motivoFormControl").style.display = "none";
    //     document.getElementById("labelmotivoFormControl").style.display = "none";
    //     document.getElementById("temperaturaAjusteFormControl").style.display = "inline";
    //     document.getElementById("labelAjusteNumeroRemito").style.display = "none";
    //     document.getElementById("labelAjusteAforadorFinal").style.display = "none";
    //     //Controles que no son necesarios en este movimiento.
    //     // this.cantidadLts.clearValidators();
    //     // this.cantidadLts.disable();
    //     // this.cantidadLts.updateValueAndValidity();
    //     // this.motivo.clearValidators();
    //     // this.motivo.updateValueAndValidity();
    //     // this.destino.clearValidators();
    //     // this.destino.updateValueAndValidity();
    //     // this.numeroRemito.clearValidators();
    //     // this.numeroRemito.updateValueAndValidity();
    //     //Habilitación de controles necesarios.
    //     // this.aforadorInicial.disable();
    //     // this.aforadorFinal.setValidators([Validators.required]);
    //     // this.aforadorFinal.updateValueAndValidity();
    //     // this.aforadorInicial.setValidators([Validators.required]);
    //     // this.aforadorInicial.updateValueAndValidity();

    //     this.getAbastecedoras();
    //     break;
    //   case 1: //transferencia.
    //     document.getElementById("remitoFormControl").style.display = "inline";
    //     document.getElementById("labelNumeroRemito").style.display = "none";
    //     document.getElementById("labelDestino").style.display = "inline";
    //     document.getElementById("destinoFormControl").style.display = "inline";
    //     document.getElementById("labelAforadorInicial").style.display = "none";
    //     document.getElementById("aforadorInicialFormControl").style.display = "none";
    //     document.getElementById("labelAforadorFinal").style.display = "none";
    //     document.getElementById("aforadorFinalFormControl").style.display = "none";
    //     document.getElementById("destinoAjusteFormControl").style.display = "none";
    //     document.getElementById("origenFormControl").style.display = "inline";
    //     document.getElementById("motivoFormControl").style.display = "none";
    //     document.getElementById("labelmotivoFormControl").style.display = "none";
    //     document.getElementById("temperaturaFormControl").style.display = "inline";
    //     document.getElementById("labelAjusteNumeroRemito").style.display = "inline";
    //     document.getElementById("labelAjusteAforadorFinal").style.display = "none";
    //     //Se deshabilitan los controles innecesarios.
    //     // this.numeroRemito.clearValidators();
    //     // this.numeroRemito.updateValueAndValidity();
    //     // this.aforadorFinal.clearValidators();
    //     // this.aforadorFinal.updateValueAndValidity();
    //     // this.aforadorInicial.clearValidators();
    //     // this.aforadorInicial.updateValueAndValidity();
    //     // this.motivo.clearValidators();
    //     // this.motivo.updateValueAndValidity();
    //     //Se habilitan controles.
    //     // this.cantidadLts.enable();
    //     // this.aforadorInicial.enable();
    //     // this.destino.setValidators([Validators.required]);
    //     // this.destino.updateValueAndValidity();
    //     // this.cantidadLts.setValidators([Validators.required]);
    //     // this.destino.updateValueAndValidity();

    //     this.filtrarContenedores();
    //     break;
    // }
  }

  getTanques() {
    let aux: DetalleStockPorContenedor[] = [];
    let j: number = 0;
    aux = this.contenedoresDeUnProductoDestino;
    this.contenedoresDeUnProductoDestino = [];
    for (let i = 0; aux.length > i; i++) {
      if (aux[i].contenedor.tipoContenedor == 1 && aux[i].contenedor.estadoContenedor == 1) {
        this.contenedoresDeUnProductoDestino[j] = aux[i];
        j = j + 1;
      }
    }
    // this.cargandoDestinos = false;
    // if (this.contenedoresDeUnProductoDestino.length == 0) {
    //   this.tanqueRecibiendo = true;
    // } else {
    //   this.tanqueRecibiendo = false;
    // }
  }

  getContenedores(aeroplantaCodActual: string) {
    // this.stockService.getContenedores(aeroplantaCodActual)
    //   .subscribe(result => {
    //     this.contenedores = result
    //   },
    //     () => {
    //       // this.spinner.hide('spinnerLista');
    //       // this.contenedores = null;
    //       // this.obteniendoStockContenedores = false;
    //       // this.esperandoCarga = false;
    //     })
  }

  getAbastecedoras() {
    let aux: DetalleStockPorContenedor[] = [];
    let j: number = 0;
    aux = this.contenedoresDeUnProducto;
    this.contenedoresDeUnProducto = [];
    for (let i = 0; aux.length > i; i++) {
      if (aux[i].contenedor.tipoContenedor == 4) {
        this.contenedoresDeUnProducto[j] = aux[i];
        j = j + 1;
      }
    }
  }




}
