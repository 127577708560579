<ng-container *ngIf="!error; else errorTemplate">
  <div *ngIf="orientation != 'vertical'" class="{{_color}}" [ngClass]="{'d-flex align-items-center': true, 
            'justify-content-center': contentAlign === 'center'}">
    <div [ngClass]="{'spinner-border ': true, 'spinner-border-sm': size != 'large'}" role="status"></div>
    <div class="ps-2 {{_textColor}}">{{ message }}</div>
  </div>

  <div *ngIf="orientation == 'vertical'" class="{{_color}}" [ngClass]="{'d-flex flex-column align-items-center': true, 
  'justify-content-center': contentAlign === 'center'}">
    <div class="text-center">
      <div [ngClass]="{'spinner-border text-center': true, 'spinner-border-sm': size != 'large'}" role="status"></div>
    </div>
    <div class="text-center {{_textColor}}">{{ message }}</div>
  </div>
</ng-container>

<ng-template #errorTemplate>
  <div class="d-flex align-items-center">
    <i class="fa fa-times text-danger"></i>
    <div class="ps-2 text-dark">
      Se ha producido un error
    </div>
  </div>
</ng-template>