<div class="col-md-8 offset-md-2">
  <div style="padding: 1rem 1rem; border-bottom: 1px solid #dee2e6; border-top-left-radius: calc(0.3rem - 1px); border-top-right-radius: calc(0.3rem - 1px);" class="modal-header">
    <h5 class="modal-title justify-content-center">Carga de precios.</h5>
  </div>
  <div style="padding: 1rem" class="modal-body">
    <form [formGroup]="productoForm">
      <div *ngIf="obteniendoAeroplantas" class="row">
        <loading-text-spinner message="Obteniendo aeroplantas..." color="primary"></loading-text-spinner>
      </div>
  
      <div class="row">
        <div class="col-md-4">
          <label for="nivelFormControl" id="labelNivel" class="col-form-label">
            Nivel:
          </label>
        </div>
        <div class="col col-md-6">
          <div class="input-group mb-4">
            <select id="nivelFormControl" type="text" class="form-control" formControlName="nivelFormControl"
              title="Seleccione un nivel" >
               <option *ngFor="let n of niveles" [value]="n.id" [selected]="niveles[0]">
                {{ n.nombre }}
              </option>
            </select>
          </div>
        </div>
        <div class="col col-2">
          <div class="col col-2">
            <button class="btn btn-primary me-2" id="descargar-plantilla" (click)="descargarPlantilla()">
              Plantilla
            </button>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4">
          <label for="nivelFormControl" id="labelNivel" class="col-form-label">
            Archivo:
          </label>
        </div>
        <div *ngIf="fileUpload != null && fileUpload.status==='error'">
          <div [innerHTML]="fileUpload.message"></div>
        </div>
        <div class="mb-3" class="col-md-6">
      
          <div class="custom-file" class="col-md-12">
      
            <input #inputFile type="file" class="form-control col-md-12" name="profile" (change)="onSelectedFile($event)"
              placeholder="Archivo.xls" id="validatedCustomFile" formControlName="inputFile" required (click)="resetearArchivo()">
            <label class="custom-file-label" for="validatedCustomFile">{{ userFileName }}</label>
            <div class="invalid-feedback">Example invalid custom file feedback</div>
          </div>
        </div>
      
      </div>
      <div *ngIf="fileUpload != null && fileUpload.status==='progress'">
        <div class="progress" style="height: 20px;margin-bottom:20px;">
          <div class="progress-bar" role="progressbar" [style.width.%]="fileUpload.message" aria-valuenow="25"
            aria-valuemin="0" aria-valuemax="100">{{fileUpload.message}}%</div>
        </div>
      </div>
      <div *ngIf="fileUpload != null && fileUpload.length>0" class="alert alert-warning" role="alert">
        <div *ngFor="let msg of fileUpload">
          <p>{{ msg.description }}</p>
        </div>
      </div>
  
      <div id="contenedor-mensaje-procesamiento" *ngIf="mensajeProcesamiento != null" [innerHTML]="mensajeProcesamiento">
        
      </div>
      <p *ngIf="mensajeProcesamiento != null">
        <ngb-progressbar 
          type="success" 
          [max]="porcentajeMax"
          [value]="porcentajeCarga"
          [striped]="true"
          [animated]="true">
          {{ porcentajeCarga / porcentajeMax | percent:'0.2-2'}}
        </ngb-progressbar>
      </p>
  
      <div id="contenedor-errores-procesamiento" *ngIf="mensajeErrorProcesamiento.length > 0" [innerHTML]="mensajeErrorProcesamiento">
  
      </div>
  
      <div id="contenedor-mensaje-procesamiento" *ngIf="mensajeFinProcesamiento != null" [innerHTML]="mensajeFinProcesamiento">
        
      </div>
  
      <div *ngIf="mensajeError">
        <p style="color: red; font-weight: bold;">{{ mensajeError }}</p>
      </div>
    </form>
  </div>
  <div class="modal-footer justify-content-center">
    <button class="btn btn-primary me-2" id="aceptar" [disabled]="!productoForm.valid || subiendoPrecios || obteniendoAeroplantas" (click)="cargarPrecios()">
      <span *ngIf="!subiendoPrecios">Cargar</span>
      <div *ngIf="subiendoPrecios" class="d-flex align-items-center">
        <div class="spinner-border text-light spinner-border-sm" role="status"></div>
        <span>Cargando...</span>
      </div>
    </button>
  
    <button *ngIf="this.bloqueCompletado > 1" type="button" class="btn btn-secondary ms-2" (click)="usuarioCancelaCarga()">Cancelar carga realizada</button>
  
  </div>
</div>
  