<div class="card" id="islas-card">
  <div class="card-header">
    <h4 style="display: inline;" class="card-title">ABM de tanques.</h4>
    <button *ngIf="this.hasRole(TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.RESPRODUCTO, TipoUsuario.TUTOR)" style="margin-left: 4em;" class="btn btn-primary" type="button" [disabled]="obteniendoTanques" (click)="agregarTanque()">Agregar nuevo tanque</button>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="spinner-container" *ngIf="obteniendoTanques">    
        <ngx-spinner name="spinnerTanque" bdOpacity=0.3bd
          Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
          <p style="color: white">{{messageTanques}}</p>
        </ngx-spinner>
      </div>
      <div *ngIf="!obteniendoTanques" class="col-md-12">
        <div *ngIf="this.tanques===null || this.tanques.length == 0" class="alert alert-warning">No hay tanques cargados para la aeroplanta seleccionada.</div>
        <table class="table table-striped table-hover" *ngIf="this.tanques.length > 0" [formGroup]="tablaTanques">
          <thead>
            <td class="text-center"><b>Código</b></td>
            <td class="text-center"><b>Nombre</b></td>
            <td class="text-center"><b>Tipo</b></td>
            <td class="text-center"><b>Producto</b></td>
            <td class="text-center"><b>Vol. máximo</b></td>
            <td class="text-center"><b>Vol. inextraible</b></td>
            <td class="text-center"><b>Tolerancia max.</b></td>
            <td class="text-center"><b>Min. reposo hs.</b></td>
            <td class="text-center"><b>Número certificado</b></td>
            <td class="text-center"><b>Activa</b></td>
            <td class="text-center"><b>Acción</b></td>
          </thead>
          <tbody>
            <tr formArrayName="tanques" *ngFor="let t of tanques; let i = index">
              <td class="text-center">{{  tanques[i].codigo }}</td>
              <td class="text-center">{{ tanques[i].nombre }}</td>
              <td class="text-center">{{ getDescripcionTipoTanque(tanques[i].formaTanque) }}</td>
              <td class="text-center">{{ tanques[i].productoActual.codigoProducto }} - {{ tanques[i].productoActual.nombreProducto }}</td>
              <td class="text-center">{{ tanques[i].capacidad}}</td>
              <td class="text-center">{{ tanques[i].volumenInextraible}}</td>
              <td class="text-center">{{ tanques[i].toleranciaMaxima}}</td>
              <td class="text-center">{{ tanques[i].minimoHorasReposo}}</td>
              <td class="text-center">{{ tanques[i].numeroCertificado}}</td>
              <td class="text-center">
                <div *ngIf="this.hasRole(TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.RESPRODUCTO, TipoUsuario.TUTOR, TipoUsuario.COMERCIAL)" class="form-check form-check-inline form-switch">
                  <input type="checkbox" [formControlName]="i" class="form-check-input"
                     id="{{tanques[i].id}}" (click)="actualizarTaques(tanques[i])">
                  <label class="form-check-label" for="{{tanques[i].id}}"></label>
                </div>
                <div *ngIf="!this.hasRole(TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.RESPRODUCTO, TipoUsuario.TUTOR, TipoUsuario.COMERCIAL)" class="form-check form-check-inline form-switch">
                    {{tanques[i].activo ? "Si" : "No"}}
                </div>
              </td>
              <td>
                <button *ngIf="this.hasRole(TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.RESPRODUCTO, TipoUsuario.TUTOR)" type="button" (click)="editarTanque(tanques[i])" class="btn btn-primary btn-sm me-1"><i class="fa fa-pencil fa-lg" aria-hidden="true" ngbTooltip="Editar"></i></button>
                <button *ngIf="this.hasRole(TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.RESPRODUCTO, TipoUsuario.TUTOR)" type="button" (click)="subirMediciones(tanques[i])" class="btn btn-success btn-sm me-1"><i class="fa fa-file-excel-o fa-lg" style="color:white;" aria-hidden="true" ngbTooltip="Importar excel equivalencias"></i></button>
                <button type="button" *ngIf="tanques[i].tieneGraduacion" (click)="verEquivalencias(tanques[i])" class="btn btn-secondary btn-sm"><i class="fa fa-table fa-lg" aria-hidden="true" ngbTooltip="Ver equivalencias"></i></button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

