import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { fadeAnimationTrigger } from 'src/app/shared/animations';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';

@Component({
  selector: 'app-despacho-index',
  templateUrl: './despacho-index.component.html',
  styleUrls: ['./despacho-index.component.scss'],
  animations:[ fadeAnimationTrigger ]
})
export class DespachoIndexComponent implements OnInit {

  TipoUsuario = TipoUsuario;
  
  constructor(private authService: AuthService) { }

  ngOnInit() {
  }

  hasRole(...roles: TipoUsuario[]) {
    return this.authService.hasRole(...roles);
  }
}
