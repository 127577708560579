import { Pipe, PipeTransform } from '@angular/core';
import { CondicionVentaFactura } from '../enums/condicionVentaFactura';

@Pipe({
  name: 'condicionVenta'
})
export class CondicionVentaPipe implements PipeTransform {

  transform(condicion: number): unknown {
    if (condicion === CondicionVentaFactura.contado) { 
      return "Contado"; 
    }else if(condicion === CondicionVentaFactura.cuentaCorriente){ 
      return "Cuenta Corriente"; 
    }else if(condicion === CondicionVentaFactura.previoPago){ 
      return "Previo Pago"; 
    }else{ 
      return "-"; 
    }
  }

}
