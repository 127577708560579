import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { CierreTurnoRoutingModule } from './cierre-turno-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HighchartsChartModule } from 'highcharts-angular';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SharedModule } from '../shared/shared.module';
import { CierreTurnoIndexComponent } from './cierre-turno-index/cierre-turno-index.component';
import { RealizarCierreTurnoComponent } from './cierre-diario/realizar-cierre-turno/realizar-cierre-turno.component';
import { CargaDatosCierreTurnoComponent } from './cierre-diario/carga-datos-cierre-turno/carga-datos-cierre-turno.component';
import { ComparativasCierreTurnoComponent } from './cierre-diario/comparativas-cierre-turno/comparativas-cierre-turno.component';
import { ReimputadorDocumentosCierreTurnoComponent } from './reimputador-documentos-cierre-turno/reimputador-documentos-cierre-turno.component';
import { RetiroEfectivoCierreTurnoComponent } from './retiro-efectivo-cierre-turno/retiro-efectivo-cierre-turno.component';
import { CierreCajaCierreTurnoComponent } from './cierre-caja-cierre-turno/cierre-caja-cierre-turno.component';
import { StockModule } from '../stock/stock.module';
import { GenerarInformeAerovalesComponent } from './cierre-diario/realizar-cierre-turno/generar-informe-aerovales/generar-informe-aerovales.component';
import { MovimientosComponent } from '../stock/tableros/movimientos/movimientos.component';
import { CierresCajaComponent } from './cierres-caja/cierres-caja.component';
import { ListadorFacturasComponent } from '../shared/listador-facturas/listador-facturas.component';
import { ReportesCierreTurnoComponent } from './reportes-cierre-turno/reportes-cierre-turno.component';
import { DetalleReporteCierreComponent } from './reportes-cierre-turno/detalle-reporte-cierre/detalle-reporte-cierre.component';
import { DettaleMovimientosCierreComponent } from './reporte-movimientos-cierre/detalle-movimientos-cierre/detalle-movimientos-cierre.component';
import { ReportesIndexComponent } from './reportes-index/reportes-index.component';
import { ReporteAerovaleCierreComponent } from './reporte-aerovale-cierre/reporte-aerovale-cierre.component';
import { ReporteRanchoCierreComponent } from './reporte-rancho-cierre/reporte-rancho-cierre.component';
import { ReporteMovimientoProductosCierreComponent } from './reporte-movimiento-productos-cierre/reporte-movimiento-productos-cierre.component';
import { ReporteCorrelatividadAforadoresCierreComponent } from './reporte-correlatividad-aforadores-cierre/reporte-correlatividad-aforadores-cierre.component';
import { DetalleCierreCajaComponent } from './cierres-caja/detalle-cierre-caja/detalle-cierre-caja.component';
import { SetFechaReimputacionComponent } from './reimputador-documentos-cierre-turno/set-fecha-reimputacion/set-fecha-reimputacion.component';
import { PrecierreAbastecedoraComponent } from './cierre-diario/realizar-cierre-turno/precierre-abastecedora/precierre-abastecedora.component';
import { ReporteRetiroEfectivoComponent } from './reporte-retiro-efectivo/reporte-retiro-efectivo.component';
import { ReporteBalance7Component } from './reporte-balance7/reporte-balance7.component';
import { UltimasMedicionesComponent } from './reporte-balance7/ultimas-mediciones/ultimas-mediciones.component';
import { ComparativasComponent } from './reporte-balance7/comparativas/comparativas.component';
import { ReportesMovimientosComponent } from './reporte-balance7/reportes-movimientos/reportes-movimientos.component';
import { GenerarActaComponent } from './reporte-balance7/generar-acta/generar-acta.component';
import { ModalComparativaComponent } from './reporte-balance7/comparativas/modal-comparativa/modal-comparativa.component';
import { SafePipeMov } from '../shared/pipes/safePipeMov';
import { ReporteDetalleComponent } from './reporte-balance7/reportes-movimientos/reporte-detalle/reporte-detalle.component';
import { ReporteAgrupadoPorTipoComponent } from './reporte-balance7/reportes-movimientos/reporte-agrupado-por-tipo/reporte-agrupado-por-tipo.component';
import { AerovalePrefijoPipe } from '../shared/pipes/aerovale-prefijo.pipe';
import { AerovaleNumeroPipe } from '../shared/pipes/aerovale-numero.pipe';
import { RemitoNumeroPipe } from '../shared/pipes/remito-numero.pipe';
import { RemitoPrefijoPipe } from '../shared/pipes/remito-prefijo.pipe';
import { ModalVerRemitosContadoComponent } from './cierre-diario/realizar-cierre-turno/modal-ver-remitos-contado/modal-ver-remitos-contado.component';
import { ParteDiarioCierreTurnoComponent } from './cierre-diario/parte-diario-cierre-turno/parte-diario-cierre-turno.component';
import { ModalEditarEstadosComponent } from './cierre-diario/parte-diario-cierre-turno/modal-editar-estados/modal-editar-estados.component';
import { ComprobanteSapPipe } from '../shared/pipes/comprobante-sap.pipe';
import { CierreNavBarComponent } from './cierre-diario/cierre-nav-bar/cierre-nav-bar.component';

@NgModule({
    declarations: [
      CierreTurnoIndexComponent,
      RealizarCierreTurnoComponent,
      CargaDatosCierreTurnoComponent,
      ComparativasCierreTurnoComponent,
      ReimputadorDocumentosCierreTurnoComponent,
      RetiroEfectivoCierreTurnoComponent,
      CierreCajaCierreTurnoComponent,
      GenerarInformeAerovalesComponent,
      CierresCajaComponent,
      ReportesCierreTurnoComponent,
      DetalleReporteCierreComponent,
      DettaleMovimientosCierreComponent,
      ReportesIndexComponent,
      ReporteAerovaleCierreComponent,
      ReporteRanchoCierreComponent,
      ReporteMovimientoProductosCierreComponent,
      ReporteCorrelatividadAforadoresCierreComponent,
      DetalleCierreCajaComponent,
      SetFechaReimputacionComponent,
      PrecierreAbastecedoraComponent,
      ReporteRetiroEfectivoComponent,
      ReporteBalance7Component,
      UltimasMedicionesComponent,
      ComparativasComponent,
      SafePipeMov,
      ReportesMovimientosComponent,
      GenerarActaComponent,
      ModalComparativaComponent,
      ReporteAgrupadoPorTipoComponent,
      ReporteDetalleComponent,
      ModalVerRemitosContadoComponent,
      ParteDiarioCierreTurnoComponent,
      ModalEditarEstadosComponent,
      CierreNavBarComponent,
    ],
    entryComponents: [
      GenerarInformeAerovalesComponent,
      MovimientosComponent,
      ListadorFacturasComponent,
      SetFechaReimputacionComponent,
      PrecierreAbastecedoraComponent,
      ReporteAgrupadoPorTipoComponent,
      ReporteDetalleComponent
    ],
    imports: [
      ReactiveFormsModule,
      FormsModule,
      CommonModule,
      HighchartsChartModule,
      CierreTurnoRoutingModule,
      SharedModule,
      NgxSpinnerModule,
      NgbModule,
      BrowserAnimationsModule,
      DragDropModule,
      StockModule
    ],
    providers:[
      DecimalPipe,
      AerovalePrefijoPipe,
      AerovaleNumeroPipe,
      RemitoPrefijoPipe,
      RemitoNumeroPipe,
      ComprobanteSapPipe
    ]
})
export class CierreTurnoModule { }
