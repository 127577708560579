import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from '../core/authentication/auth.guard';
import { Shell } from '../shell/shell.service';
import { ControlCreditoIndexComponent } from './control-credito-index/control-credito-index.component';
import { ReporteSaldosComponent } from './reporte-saldos/reporte-saldos.component';
import { TipoUsuario } from '../shared/enums/tipoUsuario';

const routes: Routes = [
  Shell.childRoutes([
    { path: 'controlCredito', redirectTo: 'controlCredito/saldos', pathMatch: 'full' },
    {
      path: "controlCredito",
      component: ControlCreditoIndexComponent,
      canActivate: [authGuard],
      data : { roles: [TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_LN, TipoUsuario.COMERCIAL_AV, TipoUsuario.CONTROLCREDITO, TipoUsuario.APROBADORBYR] }, //TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.TUTOR, TipoUsuario.CONTROLCREDITO
      children: [
        {
          path: 'saldos',
          component: ReporteSaldosComponent,
          canActivate: [authGuard],
          data: { }
        }
      ]
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ControlCreditoRoutingModule { 
  
}
