<form [formGroup]="clienteNuevoForm">

  <div class="accordion" id="accordionExample">

    <div class="card">
      <div class="modal-header bg-light">
        <h4 class="modal-title">Agregar Cliente</h4>
      </div>

      <div id="collapseOne" [ngbCollapse]="isCollapsedCliente">
        <div class="card-body">

          <div class="row">

            <div class="col">
              <div class="mb-3 col-md-12" style="padding-right: 1.5em;">
                <label for="razonSocialFormControl" class="col-form-label">Razon Social:</label>
                <input id="razonSocial" class="form-control" type="text" formControlName="razonSocialFormControl" placeholder="Nombre de la empresa">
                <div *ngIf="razonSocial.invalid && razonSocial.errors.required && (razonSocial.dirty || razonSocial.touched)"
                  class="alert alert-danger">
                  Requerido
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="mb-3 row col-md-12">
              <label for="tipoDocumentoFormControl" class="col-sm-5 col-form-label">Tipo documento:</label>

              <div class="col-sm-7">

                <select id="tipoDocumentoFormControl" class="form-select mb-2 me-sm-2" type="number"
                  formControlName="tipoDocumentoFormControl" placeholder="Seleccione">
                  <option [ngValue]="96">DNI</option>
                  <option *ngIf="!esElectrica" [ngValue]="80">CUIT</option>
                  <option *ngIf="!esElectrica" [ngValue]="86">CUIL</option>
                </select>
                <div
                  *ngIf="tipoDocumentoFormControl.invalid && tipoDocumentoFormControl.errors.required && (tipoDocumentoFormControl.dirty || tipoDocumentoFormControl.touched)"
                  class="alert alert-danger">
                  Requerido
                </div>
              </div>

            </div>
          </div>

          <div class="row">
            <div class="mb-3 row col-md-12">
              <label for="cuitFormControl" class="col-sm-5 col-form-label">Nro:</label>
              <div class="col-sm-7">
                <input id="cuitFormControl" type="text" class="form-control mb-2 me-sm-2"
                  formControlName="cuitFormControl" placeholder="xx-xxxxxxxxx-x">
                <div *ngIf="cuit.invalid && cuit.errors.required && (cuit.dirty || cuit.touched)"
                  class="alert alert-danger">Requerido</div>
                  <div class="alert alert-danger" *ngIf="clienteNuevoForm.invalid && this.clienteNuevoForm.hasError('nroInvalido')">
                    El nro no es valido.
                  </div>
              </div>
            </div>
          </div>

          <!-- SE AGREGA CAMPO EMAIL PARA MOVILIDAD ELECTRICA - ENVIAR FACTURA A CLIENTE EVENTUALES "DE PASO"-->
          <div *ngIf="esElectrica" class="row">
            <div class="mb-3 row col-md-12">
              <label for="mailFormControl" class="col-sm-5 col-form-label">Email:</label>
              <div class="col-sm-7">
                <input class="form-control" formControlName="mailFormControl">
                <div class="alert alert-danger" *ngIf="clienteNuevoForm.invalid && this.clienteNuevoForm.hasError('mailInvalido')">
                  El Email no tiene el formato correcto
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer bg-light">
    <span class="border">
      <button class="btn btn-primary" [disabled]="!clienteNuevoForm.valid || guardando" (click)="guardarCliente()">
        {{ guardando? "Guardando..." : "Guardar" }}
      </button>
    </span>
    <span class="border">
      <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss('Close clicked')">Cancelar</button>
    </span>
  </div>
</form>
