<div class="modal-header bg-light">
  <h4 class="modal-title">Agregar Predespacho</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()"></button>
</div>

<div class="modal-body">
  <form [formGroup]="precargaForm" (ngSubmit)="agregarPrecarga()">
    <!-- fecha precarga -->
    <div class="row">
      <div class="mb-3 row col-md-12">
        <label for="fechaControlDatePicker" class="col-sm-5 col-form-label">Fecha:</label>
        <div id="fecha" class="input-group col-sm-7">
          <input class="form-control" placeholder="yyyy-mm-dd" formControlName="fechaFormControl" [(ngModel)]="model"
            ngbDatepicker #d="ngbDatepicker" readonly>

          <div class="input-group-append">
            <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button"><i class="fa fa-calendar"
                aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- hora precarga -->
    <div class="row">
      <div class="mb-3 row col-md-12">
        <label for="horaFormControl" class="col-sm-5 col-form-label">Hora:</label>
        <div #fechaHora class="col-sm-7">
          <ngb-timepicker [(ngModel)]="time" [meridian]="true" formControlName="horaFormControl"
            (ngModelChange)="validarFecha($event)"></ngb-timepicker>
          <div *ngIf="(fecha.invalid && fecha.errors.fechaValida && (fecha.dirty || fecha.touched))"
            class="alert alert-danger">Fecha y hora incorrectas</div>
          <div *ngIf="(fecha.invalid && fecha.errors.fechaSeleccionadaFutura && (fecha.dirty || fecha.touched))"
            class="alert alert-danger">La fecha y hora de la precarga deben ser igual o mayor a la del pedido y a la
            actual</div>
        </div>
      </div>
    </div>

    <!-- abastecedora -->
    <div class="row">
      <div class="mb-3 row col-md-12">
        <label for="abastecedoraFormControl" class="col-sm-5 col-form-label">*Abastecedora:</label>
        <div class="col-sm-7">
          <div class="input-group mb-2">
            <select id="abastecedoraFormControl" class="form-select" type="number" ngbTooltip="Seleccione una abastecedora."
              formControlName="abastecedoraFormControl">
              <option value="null"> Seleccione una opción </option>
              <option [ngValue]="false">Sin abastecedora</option>
              <option *ngFor="let abastecedora of abastecedoras" [ngValue]="abastecedora.codigo">{{abastecedora.nombre}}
              </option>
            </select>
          </div>
          <div *ngIf="cargandoAbastecedoras" class="d-flex align-items-center">
            <div class="spinner-border text-primary spinner-border-sm" role="status"></div>
            <div class="p-2">Obteniendo abastecedoras</div>
          </div>
          <div
            *ngIf="abastecedora.invalid && abastecedora.errors.required && (abastecedora.dirty || abastecedora.touched)"
            class="alert alert-danger">Requerido</div>
        </div>
      </div>
    </div>

    <!-- cantidad -->
    <div class="row">
      <div class="mb-3 row col-md-12">
        <label for="cantidadEstimadaControlInput" class="col-sm-5 col-form-label">*Cantidad Estimada:</label>
        <div class="col-sm-7">
          <input id="cantidadEstimadaControlInput" type="text" class="form-control mb-2 me-sm-2" (keypress)="onlyNumberKey($event)"
            formControlName="cantidadEstimadaFormControl" ngbTooltip="Cantidad mayor a 0." placeholder="{{this.pedido.cantidadFaltante | number}} L">
          <div
            *ngIf="cantidadEstimada.invalid && cantidadEstimada.errors.required && (cantidadEstimada.dirty || cantidadEstimada.touched)"
            class="alert alert-danger">Requerido</div>
          <div *ngIf="this.pedido.tipoPedido==TipoPedido.Tams && cantidadEstimada.invalid && (cantidadEstimada.errors.min || cantidadEstimada.errors.superaCantidad)
                   && (cantidadEstimada.dirty || cantidadEstimada.touched)" class="alert alert-danger">La cantidad debe
            ser mayor/igual a cero </div>
          <div *ngIf="this.pedido.tipoPedido!=TipoPedido.Tams && cantidadEstimada.invalid && (cantidadEstimada.errors.min || cantidadEstimada.errors.superaCantidad)
                   && (cantidadEstimada.dirty || cantidadEstimada.touched)" class="alert alert-danger">La cantidad debe
            estar entre 1 y {{cantidadMaxima}} </div>
        </div>
      </div>
    </div>

    <div *ngIf="rancho" class="row">
      <div class="mb-3 col-md-12">
        <div class="border">
          <label class="col-form-label col-sm-4 text-md-center text-sm-start">Rancho: <span
              class="fw-bold">{{rancho.codigoRancho}}</span></label>
          <label class="col-form-label col-sm-4 text-md-center text-sm-start">Vencimiento: <span
              [ngClass]="{'text-danger': vencida()}">{{ rancho.fechaVencimiento | date: "dd'/'MM'/'y" }}</span>
          </label>
          <label class="col-form-label col-sm-4 text-md-center text-sm-start">Disponible:
            {{rancho.volumenDisponible | number}}
            litros</label>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="p-2">
<small class="text-danger ">*Campos obligatorios.</small>
</div>
<div class="modal-footer bg-light">
  <span class="border">
    <button class="btn btn-primary" [disabled]="!precargaForm.valid || guardandoPrecarga" type="submit"
      (click)="agregarPrecarga()">
      <span *ngIf="!guardandoPrecarga">Guardar</span>
      <div *ngIf="guardandoPrecarga" class="d-flex align-items-center">
        <div class="spinner-border text-light spinner-border-sm" role="status"></div>
        <span>Guardando...</span>
      </div>
    </button>
  </span>
  <span class="border">
    <button class="btn btn-secondary" [disabled]="guardandoPrecarga"
      (click)="activeModal.dismiss()">Cancelar</button>
  </span>
</div>
