<div class="modal-header">
  <h5 class="modal-title justify-content-center">Carga de certificado AFIP.</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="this.modal.dismissAll()">
    
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="productoForm">
    <div class="row">
      <div class="col col-2"></div>
      <div class="col col-4">
        <label class="pt-2">Seleccionar certificado:</label>
      </div>
      <div class="mb-3">
        <div class="custom-file">
          <input type="file" class="custom-file-input" name="profile" (change)="onSelectedFile($event)" placeholder="Archivo.xls"
            id="validatedCustomFile" formControlName="inputFile" required>
          <label class="custom-file-label" for="validatedCustomFile">{{ userFileName }}</label>
          <div class="invalid-feedback">Example invalid custom file feedback</div>
        </div>
      </div>
      <div class="col col-2"></div>
    </div>
    <div *ngIf="fileUpload.length>0" class="alert alert-warning" role="alert">
      <div *ngFor="let msg of fileUpload">
        <p>{{ msg.description }}</p>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer justify-content-center">
  <button class="btn btn-primary me-2" id="aceptar" [disabled]="!productoForm.valid || guardandoExcel" (click)="cargarCertificado()">
    <span *ngIf="!guardandoExcel">Cargar</span>
    <div *ngIf="guardandoExcel" class="d-flex align-items-center">
      <div class="spinner-border text-light spinner-border-sm" role="status"></div>
      <span>Cargando...</span>
    </div>
  </button>
  <button type="button" class="btn btn-secondary" (click)="this.modal.dismissAll()">Cancelar</button>
</div>