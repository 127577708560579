import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CondicionVenta } from 'src/app/shared/enums/condicionVenta';
import { TipoObservacionExcelPrecios } from 'src/app/shared/enums/tipoObservacionExcelPrecios';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { PrecioConsulta } from 'src/app/shared/models/precios/PrecioConsulta';
import { MessageService } from 'src/app/shell/message.service';
import { PreciosService } from '../../precios.service';
import html2canvas from 'html2canvas';
import { ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';
import { saveAs } from 'file-saver';
import { Precio } from 'src/app/shared/models/precios/Precio';
import { formatDate } from '@angular/common';
import { ModalConfirmComponent } from 'src/app/shared/generic-modals/modal-confirm.component';
@Component({
  selector: 'app-modal-resumen-precios',
  templateUrl: './modal-resumen-precios.component.html',
  styleUrls: ['./modal-resumen-precios.component.css']
})
export class ModalResumenPreciosComponent implements OnInit {

  @Input() name ;
  @Input() profile ;
  @Input() nivelPrecio ;
  @Input() preciosConsulta : PrecioConsulta[] ;

  @ViewChild('contenedorPreciosLote', {static: true}) contenedorPreciosLote: ElementRef;

  enumTipoObservacionExcelPrecios = TipoObservacionExcelPrecios;
  enumCondicionVenta = CondicionVenta;

  fileUpload = { status: '', message: '', filePath: '', length: 0 };
  userFileName: string;
  selectedFile: boolean = false;
  error: string = null;
  errorExportacion: string;
  guardandoExcel: boolean = false;
  descargandoExcel: boolean = false;
  mostrarResumenCompleto: boolean = false;

  constructor(public modal: NgbActiveModal,
              private preciosService: PreciosService,
              private messageService: MessageService,
              private modalService:NgbModal) { }

  ngOnInit() {

  }



  // llamadas al service

  tomarImagenResumen(formData){

    let component = this;
    let data = formData;

    html2canvas(this.contenedorPreciosLote.nativeElement).then(function(canvas) {
      canvas.toBlob(function(blob){
          var imgData = component.createBlob(canvas.toDataURL("image/jpeg"));
          data.append('nameComparativa', "imgComparativa.jpg");
          data.append('profileComparativa', imgData, "imagen.jpg");

          component.preciosService.upload(data)
            .subscribe(result => {
                if (Array.isArray(result)) {
                  component.guardandoExcel = false;
                }

                if(result){
                  component.fileUpload = result;
                  component.guardandoExcel = false;
                  component.messageService.showSuccessLongMessage("Se proceso la solicitud de guardar los precios correctamente.");
                  component.modal.close();
                }

              },
                (err: ErrorModel) => {
                  component.error = err.description + ' ' + err.message;
                  component.guardandoExcel = false;
                }
            );
      },'image/png');
    });
  }

  esPrecioRetroactivo(precio: Precio){
    //{{precioNuevo.precio.fechaDesde < AHORA}}
    let fechaDesde = new Date(precio.fechaDesde);
    var ahora = new Date();

    if(fechaDesde < ahora){
      return true;
    }

    return false;
  }

  descargarExcelComparativa(){
    this.descargandoExcel = true;
    this.errorExportacion = null;

    const formData = new FormData();
    formData.append('name', this.name);
    formData.append('profile', this.profile);
    formData.append('nivelPrecio', this.nivelPrecio);

    this.preciosService.consultaExportar(formData)
      .subscribe((result: Blob) => {
        const blob = new Blob([result], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
        saveAs(blob, "comparativa.xlsx");
        this.descargandoExcel = false;
      },
        (err: ErrorModel) => {
          this.errorExportacion = err.description + ' ' + err.message;
          this.descargandoExcel = false;
        }
      );
  }

  // form

  createBlob(dataURL) {
    var BASE64_MARKER = ';base64,';
    if (dataURL.indexOf(BASE64_MARKER) == -1) {
      var parts = dataURL.split(',');
      var contentType = parts[0].split(':')[1];
      var raw = decodeURIComponent(parts[1]);
      return new Blob([raw], { type: contentType });
    }
    var parts = dataURL.split(BASE64_MARKER);
    var contentType = parts[0].split(':')[1];
    var raw = window.atob(parts[1]);
    var rawLength = raw.length;

    var uInt8Array = new Uint8Array(rawLength);

    for (var i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: contentType });
  }

  cargarPrecios() {

   console.log("Los precios consulta son"+JSON.stringify(this.preciosConsulta))
        // Si hay algun precio retroactivo en la lista , mostrar confirmacion "Se estan cargando precios retroactivos, esta seguro?"
    // recorremos this.preciosConsulta y verificamos que para alguno p:
    //  esPrecioRetroactivo(p.precio) es true

    // despues siempre mostrar un cartel de "Se cargaran X (this.prciosConsulta.lenght) registros, desea proceder?"

    let indice=this.preciosConsulta.findIndex(p=>this.esPrecioRetroactivo(p.precio)===true)

    if (indice!=-1){
      console.log("hay retroactivos")
      let modalRef = this.modalService.open(ModalConfirmComponent, { centered: true });
      modalRef.componentInstance.mensaje="Se están cargando precios retroactivos, ¿Está seguro?"
      modalRef.componentInstance.textoSi="Si"
      modalRef.componentInstance.textoNo="No"
      modalRef.result.then((result) => {
        this.accionCargar();
      }, () => { });

      this.error = null;
    } else {
      this.accionCargar();
    }
  }

  accionCargar(){
    let modalRef = this.modalService.open(ModalConfirmComponent, { centered: true });
      modalRef.componentInstance.mensaje="Desea proceder con la carga de " + this.preciosConsulta.length + " registros?";
      modalRef.componentInstance.textoSi="Si"
      modalRef.componentInstance.textoNo="No"
      modalRef.result.then((result) => {
        const formData = new FormData();
      formData.append('name', this.name);
      formData.append('profile', this.profile);

      formData.append('nivelPrecio', this.nivelPrecio);
      formData.append('responsable', this.preciosService.obtenerStringResponsableV2());
      this.guardandoExcel = true;
      
      this.mostrarResumenCompleto = true;

      setTimeout(()=>{ this.tomarImagenResumen(formData); }, 1000);      

      }, () => { });

    console.log("no hay retroactivos")    
  }

  // validaciones

  hayErroresEnPrecios(){

    var errores = this.preciosConsulta.filter(p => p.tipoObservacion == TipoObservacionExcelPrecios.Error );
    return errores.length > 0;
  }

  calcularPreciosConErrores(lista: PrecioConsulta[]){
    return lista.filter(p => p.tipoObservacion == TipoObservacionExcelPrecios.Error ).length;
  }

  calcularRetroactivosConConflicto(lista: PrecioConsulta[]){
    return lista.filter(p => this.esPrecioRetroactivo(p.precio) && p.tipoObservacion == TipoObservacionExcelPrecios.Conflicto ).length;
  }

  calcularRetroactivosSinConflicto(lista: PrecioConsulta[]){
    return lista.filter(p => this.esPrecioRetroactivo(p.precio) && p.tipoObservacion == TipoObservacionExcelPrecios.SinConflicto ).length;
  }

  calcularNoRetroactivosConConflicto(lista: PrecioConsulta[]){
    return lista.filter(p => (!this.esPrecioRetroactivo(p.precio)) && p.tipoObservacion == TipoObservacionExcelPrecios.Conflicto ).length;
  }

  calcularNoRetroactivosSinConflicto(lista: PrecioConsulta[]){
    return lista.filter(p => (!this.esPrecioRetroactivo(p.precio)) && p.tipoObservacion == TipoObservacionExcelPrecios.SinConflicto ).length;
  }

  usuarioCambioResumen(event){
    if(event.target.checked){
      this.mostrarResumenCompleto = true;
    }else{
      this.mostrarResumenCompleto = false;
    }
  }

}
