import { Component, HostBinding, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbCalendar, NgbDatepickerConfig, NgbDateStruct, NgbModal, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { UsuarioService } from 'src/app/account/usuario.service';
import { AerovalesService } from 'src/app/despacho/aerovales.service';
import { TipoAerovale } from 'src/app/shared/enums/tipoAerovale';
import { Aeroplanta } from 'src/app/shared/models/despacho/Aeroplanta';
import { Surtidor } from 'src/app/shared/models/stock/Surtidor';
import { SharedService } from 'src/app/shared/shared.service';
import { MessageService } from 'src/app/shell/message.service';
import { SurtidorService } from 'src/app/stock/surtidor.service';

@Component({
  selector: 'app-remitos-no-asociados-factura',
  templateUrl: './remitos-no-asociados-factura.component.html',
  styleUrls: ['./remitos-no-asociados-factura.component.css']
})
export class RemitosNoAsociadosFacturaComponent implements OnInit {

  @HostBinding('class.changed')

  tablaComprobantes: UntypedFormGroup;
  aeroplanta: Aeroplanta;
  surtidores: Surtidor[];


  fechaHasta = new UntypedFormControl();
  fechaFormControlDesdeHasta = new UntypedFormControl();
  mensajeErrorExportar="";
  model: NgbDateStruct;
  model1: NgbDateStruct;
  time: NgbTimeStruct;
  startDate: Date;
  endDate: Date;
  buscando: boolean = false;
  mensajeSpinner: string = "D e s c a r g a n d o";
  obteniendoAerovales = true;
  procesandoExport: boolean = false;
  codigoAeroplanta: string;
  cambiandoAeroplanta: boolean = false;
  pendiente = { valor: "Pendiente", descripcion: "Pendiente", seleccionado: false };
  finalizado = { valor: "Finalizado", descripcion: "Finalizado", seleccionado: false };
  finalizadoSinFirma = { valor: "FinalizadoSinFirma", descripcion: "Finalizado sin firma", seleccionado: false };
  estadoSincronizado = { valor: "Sincronizado", descripcion: "Sincronizado", seleccionado: false };
  anulado = { valor: "Anulado", descripcion: "Anulado", seleccionado: false };

  notaCredito = { tipo: 0, valor: "notaCredito", descripcion: "Nota de credito", seleccionado: false };
  notaDebito = { tipo: 1, valor: "notaDebito", descripcion: "Nota de debito", seleccionado: false };

  tipos = [
    this.notaCredito, this.notaDebito
  ];

  estados = [
    this.pendiente,
    this.finalizado,
    this.estadoSincronizado,
    this.anulado
  ];

  busquedaFormControl = new UntypedFormControl();
  change$: Subscription;
  errorAerovales: boolean = false;

  tipoNuevoAerovale: TipoAerovale;
  enumTipoAerovale = TipoAerovale;
  idComprobanteAsociado: string;
  botonAnular: boolean = false;


  constructor(private datepickerConfig2: NgbDatepickerConfig,
    private messageService: MessageService,    
    private usuarioService: UsuarioService,
    private calendar: NgbCalendar,
    private spinner: NgxSpinnerService,
    private surtidorService: SurtidorService,
    private fb: UntypedFormBuilder,
    sharedService: SharedService) {
      this.change$ = sharedService.aeroplantaSeleccionada.subscribe(_=>{
        this.cambiarDeAeroplanta();
      });
  }

  ngOnInit() {
    this.tablaComprobantes = this.fb.group({
      abastecedoras: new UntypedFormArray([])
    });
    this.getSurtidores();

    this.codigoAeroplanta=this.usuarioService.getAeroplantaActual().codigoAeroplanta;
    this.idComprobanteAsociado = '';
    this.botonAnular = false;
    this.mensajeSpinner = "C a r g a n d o . . .";

    this.model = this.calendar.getToday();
    this.model1 = this.calendar.getToday();
    let inicio: any = { day: 1, month: 1, year: 2019 };
    this.datepickerConfig2.minDate = inicio;
    let fin: any = { day: this.model.day, month: this.model.month, year: this.model.year };
    this.model1 = this.calendar.getToday();
    this.datepickerConfig2.maxDate = fin;
    setTimeout(() => {
      this.spinner.show('spinnerLista');
    });
    this.reinicioFechaInicioFechaFin();
  }

  get busqueda() {
    return this.busquedaFormControl;
  }

  ngOnDestroy() {
    this.change$.unsubscribe();
  }

  addValidationError(control: AbstractControl, validationName: string) {
    let errors = control.errors;
    if (errors == null)
      errors = { [validationName]: true };
    else
      errors[validationName] = true;
    control.setErrors(errors);
    control.markAsDirty();
  }

  /**
   * Convierte una NgbDateStruct y una NgbTimeStruct en un Date
   */
  dateFromDateTimeStruct(date: NgbDateStruct, hour: NgbTimeStruct): Date {
    if (date == null || hour == null)
      return null;
    let dateReturn = new Date();
    dateReturn.setDate(date.day);
    dateReturn.setMonth(date.month - 1);
    dateReturn.setFullYear(date.year);
    dateReturn.setHours(hour.hour);
    dateReturn.setMinutes(hour.minute);
    return dateReturn;
  }

  fechaDesdeMayorQueHasta() {
    let esMayor: boolean = false;
    this.startDate = new Date(this.model.year, this.model.month - 1, this.model.day);
    this.endDate = new Date(this.model1.year, this.model1.month - 1, this.model1.day);
    if (this.startDate > this.endDate) { esMayor = true; }
    return esMayor;
  }


  reinicioFechaInicioFechaFin() {
    this.startDate = new Date(this.model.year, this.model.month - 1, this.model.day);
    this.endDate = new Date(this.model1.year, this.model1.month - 1, this.model1.day);
    this.endDate.setHours(23, 59, 59, 99);
  }


  mostrarResultados() {
    let mostrar: Boolean;
    mostrar = false;
    mostrar = (this.startDate < this.endDate);
    return mostrar;
  }

  limpiarCampos() {
    this.spinner.hide("spinnerLista");
    this.spinner.hide("spinnerDetalle");
    this.buscando = false;
    this.busquedaFormControl.reset();
    this.model = this.calendar.getToday();
    this.model1 = this.calendar.getToday();
    //limpio filtro estados
    for (let estado of this.estados) {
      estado.seleccionado = false;
    }
    //limpio filtro tipos
    for (let tipo of this.tipos) {
      tipo.seleccionado = false;
    }
  }

  cambiarDeAeroplanta() {
    this.cambiandoAeroplanta = false;
    this.limpiarCampos();
    this.cambiandoAeroplanta = true;
    this.ngOnInit();
  }

  private addCheckboxes() {
    // reinicio el formulario
    this.tablaComprobantes.setControl('surtidores', this.fb.array([]));
    this.surtidores.map((o, i) => {
      const control = new UntypedFormControl(i === 0); // if first item set to true, else false
      control.setValue(o.activo);
      (this.tablaComprobantes.controls.surtidores as UntypedFormArray).push(control);
    });
  }



  // llamadas al service

  getSurtidores(){
    //this.obteniendoSurtidores = true;
    this.spinner.show('spinnerAbastecedora');
    this.aeroplanta = this.usuarioService.getAeroplantaActual();
    this.surtidorService.getSurtidoresPorAeroplanta(this.aeroplanta.codigoAeroplanta)
      .subscribe(result => {
        this.surtidores = result.filter(a => a.tipoExpendedora==1);
        this.addCheckboxes();
        this.spinner.hide('spinnerAbastecedora');
        //this.obteniendoSurtidores = false;
      },
      () => {
        this.messageService.showErrorMessage("Error al obtener surtidores.");
        //this.obteniendoSurtidores = false;
        this.spinner.hide('spinnerAbastecedora');
        this.addCheckboxes();
      })
  }


}
