<div class="card-header mt-3" *ngIf="!this.obteniendo">
  <label class=""><b>Ejecuciones detalle N° {{this.ejecucionSeleccionada.numeroEjecucion}} -
      {{this.ejecucionSeleccionada.tipoEjecucion == 0? 'Manual' : 'Automatica'}}.</b></label>
  <button class="btn btn-primary float-end" (click)="goBack()">Volver</button>
</div>
<div class="card-body">
  <div class="spinner-container col-md-12" *ngIf="obteniendo">
    <ngx-spinner name="spinnerLista" [ngStyle]="{'height': '400px', 'z-index':'0'}"
      [ngClass]="{'list-group-item list-group-item-action': obteniendo}" bdOpacity=0.3bd Color="rgba(193,193,193,0)"
      size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
      <p style="color: white"> O b t e n i e n d o E j e c u c i o n e s . . . </p>
    </ngx-spinner>
  </div>
  <div *ngIf="!this.obteniendo">
    <!--SOLO PARA AUTOMATICA Y LISTA LOS CLIENTES QUE SE SELECCIONARON EN LA EJECUCION -->
    <div *ngIf="this.ejecucionSeleccionada.tipoEjecucion == 1">
      <div class="border border-dark mt-4" style="margin-bottom: 1em; padding-left: 1em;">
        <label style="padding: 0.5em 1em;">
          <p><b>Clientes seleccionados en la ejecución:</b> <button style="margin-left: 3em;" class="btn btn-primary" (click)="this.toggleMostrarTablaCliente()">{{mostrarTablaClientesSeleccionados ? "Ocultar" : "Ver"}} detalle</button></p>
        </label>
        <table class="table table-sm table-striped table-hover" *ngIf="mostrarTablaClientesSeleccionados">
          <tbody>
            <tr *ngFor="let j of this.ejecucion.mensajeCortado">
              <td *ngIf="j.startsWith('Periodo')"><b>{{j}}</b></td>
              <td *ngIf="j.startsWith(' Periodo')"><b>{{j}}</b></td>
              <td *ngIf="j.startsWith(' Remitos') || j.startsWith('Remitos')"><b>{{j}}</b></td>
              <td
                *ngIf="!j.startsWith('Periodo') && !j.startsWith(' Periodo') && !j.startsWith(' Remitos') && !j.startsWith('Remitos')">
                {{j}}</td>
              <!-- <td *ngIf="!j.startsWith(' Periodo')">{{j}}</td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!--COMIENZA EL DETALLE POR CLIENTE TANTO PARA AUTOMATICA COMO LA MANUAL-->
    <div *ngFor="let ejecucionesCliente of ejecucion.ejecuciones; let i = index" class="mt-2">
      <p><b>Cliente: </b>{{ejecucionesCliente.cliente.razonSocial}} - {{ejecucionesCliente.cliente.sgc}}</p>
      <div class="border border-dark">
        <label class="col-form-label ms-2"><b>Remitos:</b></label>
        <!-- DETALLE DE REMITOS -->
        <table class="table table-sm table-striped table-hover text-center"
          *ngIf="ejecucionesCliente.ejecuciones[0].remitos.length > 0">
          <thead>
            <tr>
              <th>Numero</th>
              <th>Aeroplanta</th>
              <th>Producto</th>
              <th>Cantidad</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let ejecuciones of ejecucionesCliente.ejecuciones; let h = index">
              <ng-container *ngFor="let remito of ejecucionesCliente.ejecuciones[h].remitos; let g = index">
                <tr *ngFor="let remi of ejecucion.ejecuciones[i].ejecuciones[h].remitos[g].detalles">
                  <td>
                    {{CompletarPrefijo(ejecucion.ejecuciones[i].ejecuciones[h].remitos[g].prefijo)}}-{{CompletarNumero(ejecucion.ejecuciones[i].ejecuciones[h].remitos[g].numeroComprobante)}}
                  </td>
                  <td>{{ejecucion.ejecuciones[i].ejecuciones[h].remitos[g].codigoAeroplanta}}</td>
                  <td>{{remi.producto}}</td>
                  <td>{{remi.cantidad}}</td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
        <ngb-alert class="alert alert-warning" *ngIf="ejecucionesCliente.ejecuciones[0].remitos.length == 0">
          No hay remitos utilizados.
        </ngb-alert>
      </div>
      <div class="border border-dark mt-2">
        <label class="col-form-label ms-2"><b>Criterios de separación:</b></label>
        <table class="table table-sm table-striped table-hover text-center">
          <tbody>
            <tr *ngFor="let eje of ejecucionesCliente.mensajeAgrupacionCliente2">
              <td>{{eje}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="border border-dark mt-2">
        <label class="col-form-label ms-2"><b>Criterios impositivos:</b></label>
        <table class="table table-sm table-striped table-hover text-center">
          <tbody *ngFor="let fac of ejecucionesCliente.ejecuciones; let p = index ">
            <ng-container *ngIf="ejecucionesCliente.ejecuciones[p].mensajeAgrupacion2.length > 0">
              <tr *ngFor="let ejecuciones of ejecucionesCliente.ejecuciones[p].mensajeAgrupacion2">
                <td>{{ejecuciones}}</td>
              </tr>
            </ng-container>
          </tbody>
        </table>
        <ng-container *ngFor="let fac of ejecucionesCliente.ejecuciones; let p = index ">
          <ngb-alert class="alert alert-warning"
            *ngIf="ejecucionesCliente.ejecuciones[p].mensajeAgrupacion2.length == 0">
            No hay criterios impositivos para la separación de las facturas.
          </ngb-alert>
        </ng-container>
      </div>
      <div class="border border-dark mt-2">
        <label class="col-form-label ms-2"><b>Facturas emitidas:</b></label>
        <table class="table table-sm table-striped table-hover text-center"
          *ngIf="verificarNoEmitidas(ejecucionesCliente.ejecuciones)">
          <thead>
            <tr>
              <th>Factura</th>
              <th>Neto</th>
              <th>IVA</th>
              <th>Total</th>
              <th>Ver</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let ejecuciones of ejecucionesCliente.ejecuciones">
              <ng-container *ngIf="ejecuciones.estadoFactura != 4 && ejecuciones.estadoFactura != 0">
                <td>
                  {{CompletarPrefijo(ejecuciones.factura.prefijo)}}-{{CompletarNumero(ejecuciones.factura.numeroComprobante)}}
                </td>
                <td>{{ejecuciones.factura.netoTotal}}</td>
                <td>{{ejecuciones.factura.ivaTotal}}</td>
                <td>{{ejecuciones.factura.total}}</td>
                <td *ngIf="!obteniendoComprobante"><button class="btn btn-primary mb-2" type="button"
                    (click)="mostrarFacrtura(ejecuciones.factura)" [disabled]="obteniendoComprobante"><i class="fa fa-eye"></i>
                  </button>
                </td>
                <td *ngIf="this.obteniendoComprobante" style="border-top: none;"
                  [ngClass]="[ejecuciones.factura.loadIcon ? 'fa fa-spinner fa-spin' : 'fa fa-eye']">
                </td>
              </ng-container>
            </tr>
          </tbody>
        </table>
        <ngb-alert class="alert alert-warning" *ngIf="!verificarNoEmitidas(ejecucionesCliente.ejecuciones)">
          No hay facturas emitidas.
        </ngb-alert>
      </div>

      <div class="border border-dark mt-2">
        <label class="col-form-label ms-2"><b>Facturas rechazadas:</b></label>
        <table class="table table-sm table-striped table-hover text-center"
          *ngIf="verificarRechazadas(ejecucionesCliente.ejecuciones)">
          <thead>
            <tr>
              <th width="25%">Remitos</th>
              <th width="75%">Mensaje</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let ejecuciones of ejecucionesCliente.ejecuciones">
              <ng-container *ngIf="ejecuciones.estadoFactura == 4">
                <tr *ngFor="let remitos of ejecuciones.remitos">
                  <td>
                    <ul class="list-unstyled">
                      <li>{{CompletarPrefijo(remitos.prefijo)}}-{{CompletarNumero(remitos.numeroComprobante)}}</li>
                    </ul>
                  </td>
                  <td>
                    <span *ngIf="remitoAusenteEnErrores(ejecuciones.mensajeError2, remitos)">
                      {{ejecuciones.mensajeError}}
                    </span>
                    <span *ngIf="!remitoAusenteEnErrores(ejecuciones.mensajeError2, remitos)">
                      <span *ngFor="let msj2 of ejecuciones.mensajeError2">
                        <div>{{buscarMensajeError(msj2, remitos)}}</div>
                      </span>
                    </span>                    
                  </td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
        <ngb-alert class="alert alert-warning" *ngIf="!verificarRechazadas(ejecucionesCliente.ejecuciones)">
          No hay facturas rechazadas.
        </ngb-alert>
      </div>
    </div>
  </div>
</div>