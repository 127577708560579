<nav class="navbar navbar-light bg-light navbar-expand-lg">
  <form class="form-inline row" [formGroup]="comprobanteForm">
    <div class="col-auto">
      <input type="number" (keypress)="onlyNumberKey($event)" type="text" class="form-control me-sm-2"
        formControlName="prefijoFormControl" placeholder="Prefijo" name="prefijo" required />
    </div>

    <div class="col-auto">
      <input type="number" (keypress)="onlyNumberKey($event)" type="text" class="form-control me-sm-2"
        formControlName="numeroFormControl" placeholder="Numero" name="numero" required />
    </div>

    <div class="col-auto">
      <select class="form-select me-sm-2" type="number" name="tipoMovimientoName"
        formControlName="tipoMovimientoFormControl">
        <option *ngFor="let tipo of tipos; let index = index" [value]="tipo.id">
          {{ tipo.label }}
        </option>
      </select>
    </div>

    <div class="col-auto">
      <button class="form-control" (click)="obtenerComprobantePorPrefijoNumeroTipo()"
        class="btn btn-outline-primary me-sm-2" type="submit"> <i class="fa fa-search"></i></button>
    </div>

    <div class="col-auto" *ngIf="this.facturaObtenida != null &&
    this.facturaObtenida.factura.estado != EstadoFactura.Anulado &&
    this.facturaObtenida.factura.condicionVenta == CondicionVenta.contado && 
    this.enFecha && this.facturaObtenida.codigoAeroplanta == this.aeroplantaActual">
      <button class="btn btn-outline-primary me-2" (click)="crearNotaCredito()">Nota Crédito
      </button>
    </div>

    <div class="col-auto">
      <button class="btn btn-outline-success me-sm-2" (click)="this.reiniciarCampos()">Limpiar</button>
    </div>

  </form>
</nav>

<div class="container-fluid">
  <div class="spinner-container" *ngIf="this.cargando">
    <ngx-spinner name="spinnerGrafico" bdOpacity=0.3bd
      Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
      <p style="color: white">C o n s u l t a n d o . . . </p>
    </ngx-spinner>    
  </div>
  <div class="row">
    <div class="col-1"></div>
    <div class="col-10" *ngIf="facturaObtenida">
      <app-visor-comprobante [facturaObtenida]="facturaObtenida"></app-visor-comprobante>
    </div>
    <div class="col-1"></div>
  </div>
</div>
<div class="abs-center" *ngIf="mensajeError!=''">
  <ngb-alert type="warning"> {{this.mensajeError}}</ngb-alert>
</div>