<ul class="nav nav-tabs justify-content-start">
    <li class="nav-item">
      <a class="nav-link" *ngIf="this.hasRole(TipoUsuario.SUPERVISOR, TipoUsuario.OPERADOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.COMERCIAL, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.FACTURACION, TipoUsuario.REFACTURACION, TipoUsuario.CONSAEROVALES, TipoUsuario.CONSPRECIOS, TipoUsuario.SOPORTE, TipoUsuario.APROBADORBYR)" 
        routerLink="vuelosIndex" routerLinkActive="active"><b>Vuelos</b></a>
    </li>
    <li  class="nav-item" >
      <a class="nav-link" *ngIf="this.hasRole(TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.APROBADORBYR)" 
        routerLink="aerolineasTams" routerLinkActive="active"><b>Lineas Aereas</b></a>
    </li>
    <li  class="nav-item" >
      <a class="nav-link" *ngIf="this.hasRole(TipoUsuario.TUTOR, TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.APROBADORBYR)" 
        routerLink="destinosTams" routerLinkActive="active"><b>Destinos</b></a>
    </li>
    <li  class="nav-item" >
      <a class="nav-link" *ngIf="this.hasRole(TipoUsuario.TUTOR, TipoUsuario.JEFEDEAEROPLANTA)" 
        routerLink="screenConfiguracion" routerLinkActive="active"><b>Configuración</b></a>
    </li>
    <li  class="nav-item" >
      <a class="nav-link" *ngIf="this.hasRole(TipoUsuario.SUPERVISOR, TipoUsuario.OPERADOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.COMERCIAL, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.FACTURACION, TipoUsuario.REFACTURACION, TipoUsuario.CONSAEROVALES, TipoUsuario.CONSPRECIOS)" 
        routerLink="screenMonitor" routerLinkActive="active"><b>Monitor de Pedidos</b></a>
    </li>
</ul>
<router-outlet></router-outlet>