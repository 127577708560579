<div class="modal-header bg-light">
    <h4 class="modal-title" >{{ !editandoContacto ? 'Nuevo contacto' : 'Editar contacto' }}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close(false)"></button>
  </div>
  <div class="modal-body" [formGroup]="contactoForm">
    <!--NOMBRE-->
    <div class="row">
      <div class="mb-3 row col-md-12">
        <label for="nombreFormControl" class="col-sm-5 col-form-label">Nombre:</label>
        <div class="col-sm-7">
          <input class="form-control" formControlName="nombreFormControl" maxlength="50">
          <div
            *ngIf="nombreFormControl.invalid && nombreFormControl.errors.required && (nombreFormControl.dirty || nombreFormControl.touched)"
              class="alert alert-danger">Requerido
          </div>
        </div>
      </div>
    </div>

    <div class="row">
        <div class="mb-3 row col-md-12">
          <label for="numeroFormControl" class="col-sm-5 col-form-label">Telefono:</label>
          <div class="col-sm-7">
            <input class="form-control" formControlName="numeroFormControl" maxlength="50">
            <div
              *ngIf="numeroFormControl.invalid && numeroFormControl.errors.required && (numeroFormControl.dirty || numeroFormControl.touched)"
                class="alert alert-danger">Requerido
            </div>
          </div>
        </div>
      </div>
    
    <div class="row">
      <div class="mb-3 row col-md-12">
        <label for="emailFormControl" class="col-sm-5 col-form-label">Email: </label>
        <div class="col-sm-7">
          <input class="form-control" formControlName="emailFormControl" maxlength="50">
          <div
            *ngIf="emailFormControl.invalid && emailFormControl.errors.required && (emailFormControl.dirty || emailFormControl.touched)"
              class="alert alert-danger">Requerido
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer justify-content-center">
    <button class="btn btn-primary me-2" id="aceptar" [disabled]="!contactoForm.valid" (click)="agregarContacto()">
      <span *ngIf="!grabando">Guardar</span>
      <div *ngIf="grabando" class="d-flex align-items-center">
        <div class="spinner-border text-light spinner-border-sm" role="status"></div>
        <span>Guardando...</span>
      </div>
    </button>
    <button type="button" class="btn btn-secondary" (click)="this.activeModal.close(false)">Cancelar</button>
  </div>
  