import { DatePipe } from '@angular/common';
import { Component, HostBinding, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbCalendar, NgbDatepickerConfig, NgbDateStruct, NgbModal, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { UsuarioService } from 'src/app/account/usuario.service';
import { ModalMotivoRechazoComponent } from 'src/app/cliente/clientes-aprobar-modificaciones/modal-motivo-rechazo/modal-motivo-rechazo.component';
import { FechasService } from 'src/app/shared/fechas.service';
import { Aeroplanta } from 'src/app/shared/models/despacho/Aeroplanta';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { Comprobante } from 'src/app/shared/models/facturacion/Comprobante';
import { Factura } from 'src/app/shared/models/facturacion/Factura';
import { RefacturacionFactura } from 'src/app/shared/models/facturacion/RefacturacionFactura';
import { TuplaRefacturacion } from 'src/app/shared/models/facturacion/TuplaRefacturacion';
import { TipoMovimientoPipe } from 'src/app/shared/pipes/tipo-movimiento.pipe';
import { SharedService } from 'src/app/shared/shared.service';

import { MessageService } from 'src/app/shell/message.service';
import { ModalObservacionRefacturacionComponent } from '../aprobacion-refacturacion/modal-observacion-refacturacion/modal-observacion-refacturacion.component';
import { ComprobanteService } from '../comprobante.service';
import { FacturacionService } from '../facturacion.service';

@Component({
  selector: 'app-correccion-precios',
  templateUrl: './correccion-precios.component.html',
  styleUrls: ['./correccion-precios.component.css']
})
export class CorreccionPreciosComponent implements OnInit {

  @HostBinding('class.changed')

  facturas: RefacturacionFactura[] = [];
  facturasFiltrados: RefacturacionFactura[] = [];
  tituloFactura: string;

  facturasSeleccionadas: RefacturacionFactura[] = [];

  tablaComprobantes: UntypedFormGroup;
  aeroplanta: Aeroplanta;

  facturaCalculada: Factura[];
  facturaObtenida: Comprobante;

  previsualizacion: boolean = false;

  fechaFormControlDesdeHasta = new UntypedFormControl();
  busquedaFormControl = new UntypedFormControl();

  modelDesde: NgbDateStruct;
  modelHasta: NgbDateStruct;

  startDate: Date;
  endDate: Date;

  buscando: boolean = false;
  mensajeSpinner: string = "D e s c a r g a n d o";
  obteniendoFacturas = true;

  codigoAeroplanta: string;
  cambiandoAeroplanta: boolean = false;
  previsualizando: boolean = false;

  change$: Subscription;

  aceptandoRefacturacion: boolean = false;
  rechazandoRefacturacion: boolean = false;
  itemSeleccionado: number = null;

  constructor(private datepickerConfig2: NgbDatepickerConfig,
    private messageService: MessageService,
    private usuarioService: UsuarioService,
    private calendar: NgbCalendar,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private fb: UntypedFormBuilder,
    public facturacionService: FacturacionService,
    private comprobanteService: ComprobanteService,
    private fechaService: FechasService,
    private datePipe: DatePipe,
    private tipoMovimientoPipe: TipoMovimientoPipe,
    sharedService: SharedService) {
      this.change$ = sharedService.aeroplantaSeleccionada.subscribe(_=>{
        this.cambiarDeAeroplanta();
      });
  }

  ngOnInit() {

    this.tablaComprobantes = this.fb.group({
      facturasFiltrados: new UntypedFormArray([])
    });

    this.codigoAeroplanta = this.usuarioService.getAeroplantaActual().codigoAeroplanta;
    this.mensajeSpinner = "C a r g a n d o . . .";

    this.modelDesde = this.calendar.getToday();
    this.modelHasta = this.calendar.getToday();
    let inicio: any = { day: 1, month: 1, year: 2019 };
    this.datepickerConfig2.minDate = inicio;
    let fin: any = { day: this.modelDesde.day, month: this.modelDesde.month, year: this.modelDesde.year };
    this.modelHasta = this.calendar.getToday();
    this.datepickerConfig2.maxDate = fin;
    setTimeout(() => {
      this.spinner.show('spinnerLista');
    });
    this.reinicioFechaInicioFechaFin();

    this.getFacturasCC(this.startDate, this.endDate);
  }

  ngOnDestroy() {
    this.change$.unsubscribe();
    this.facturasFiltrados = [];
  }

  // form

  fechaDesdeMayorQueHasta() {
    let esMayor: boolean = false;
    this.startDate = new Date(this.modelDesde.year, this.modelDesde.month - 1, this.modelDesde.day);
    this.endDate = new Date(this.modelHasta.year, this.modelHasta.month - 1, this.modelHasta.day);
    if (this.startDate > this.endDate) { esMayor = true; }
    return esMayor;
  }

  reinicioFechaInicioFechaFin() {
    this.startDate = new Date(this.modelDesde.year, this.modelDesde.month - 1, this.modelDesde.day);
    this.endDate = new Date(this.modelHasta.year, this.modelHasta.month - 1, this.modelHasta.day);
    this.endDate.setHours(23, 59, 59, 99);
  }

  buscarDesdeHasta() {
    this.mensajeSpinner = "C a r g a n d o . . .";
    this.buscando = true;
    this.reinicioFechaInicioFechaFin();
    if (this.mostrarResultados() === true) {
      this.getFacturasCC(this.startDate, this.endDate);
    }
    else {
      this.facturasFiltrados.splice(0, this.facturasFiltrados.length);
    }
    this.buscando = false;
  }

  mostrarResultados() {
    let mostrar: Boolean;
    mostrar = false;
    mostrar = (this.startDate < this.endDate);
    return mostrar;
  }

  limpiarCampos() {
    this.spinner.hide("spinnerLista");
    this.spinner.hide("spinnerDetalle");
    this.buscando = false;
    this.busquedaFormControl.reset();
    this.modelDesde = this.calendar.getToday();
    this.modelHasta = this.calendar.getToday();
  }

  cambiarDeAeroplanta() {
    this.cambiandoAeroplanta = false;
    this.limpiarCampos();
    this.cambiandoAeroplanta = true;
    this.ngOnInit();
  }

  filtrarFacturas() {
    this.facturasFiltrados = this.facturas.filter(a => {
      let agregar = true;
      if (this.busqueda.value) {
        agregar = a.factura.razonSocialCliente.toLowerCase().indexOf(this.busqueda.value.toLowerCase()) > -1;

        agregar = agregar || (this.facturacionService.formatearComprobante(a.factura.prefijo,a.factura.numeroComprobante).indexOf(this.busqueda.value) > -1);

        agregar = agregar || (this.datePipe.transform(a.fechaHora, "d/MM/y").indexOf(this.busqueda.value) > -1);

        agregar = agregar || (this.tipoMovimientoPipe.transform(a.factura.tipoMovimiento).indexOf(this.busqueda.value) > -1);


      }
      return agregar;
    });
  }

  verObservaciones() {
    let modalRef = this.modalService.open(ModalObservacionRefacturacionComponent, { centered: true, backdrop: 'static' });
    modalRef.result.then(() => {
      console.log("S");
    }, () => { });
  }

  changeValue(i: number, event){
    if(event.target.checked){
      this.facturasFiltrados[i].esRefacturacionTotal = false;
    }else{
      this.facturasFiltrados[i].esRefacturacionTotal = true;
    }
  }

  // llamadas al service

  getFacturasCC(fechaInicio: Date, fechaFin: Date) {
    this.obteniendoFacturas = true;
    this.spinner.show('spinnerGrafico');
    let desde: string = this.fechaService.convertirDateUtcALocal(fechaInicio.getUTCFullYear(), fechaInicio.getMonth() + 1, fechaInicio.getDate(), 0, 0);
    let hasta: string = this.fechaService.convertirDateUtcALocal(fechaFin.getUTCFullYear(), fechaFin.getMonth() + 1, fechaFin.getDate(), 23, 59);
    this.facturacionService.getCorreccionesDePrecios(desde, hasta)
      .subscribe(result => {
        this.facturas = result;
        this.spinner.hide('spinnerGrafico');
        this.obteniendoFacturas = false;
        this.filtrarFacturas();
      }, () => {
        this.obteniendoFacturas = false;
      });
  }

  verComparativasFacturas(content, factura: RefacturacionFactura, index) {
    this.previsualizando = true;
    this.itemSeleccionado = index;
    factura.factura.loadIcon = true;
    this.facturaObtenida = factura.factura;
    this.tituloFactura = 'Factura: ' + factura.factura.prefijo + '-' + factura.factura.numeroComprobante + ' Fecha: ' + new Date().toLocaleDateString();
    this.comprobanteService.obtenerComprobantePorPrefijoNumeroTipo(this.facturaObtenida.prefijo, this.facturaObtenida.numeroComprobante, this.facturaObtenida.tipoMovimiento)
      .subscribe(result => {
        this.facturaObtenida = result;
        if (result != null) {
          this.previsualizacion = false;
          this.facturacionService.getNuevoComprobante(factura.id, factura.esRefacturacionTotal)
            .subscribe(result => {
              this.facturaCalculada = result;
              console.log(result);
              this.previsualizacion = true;
              this.previsualizando = false;
              this.itemSeleccionado = -1;
              const modalRef = this.modalService.open(content, { windowClass: 'custom-modal', centered: true, size: 'lg' });
            })
        }
        else {
          this.facturaObtenida = null; //se limpia el comprobante.
          this.previsualizando = false;
          this.itemSeleccionado = -1;
        }
      },
        err => {
          // Entra aquí si el servicio entrega un código http de error EJ: 404,
          //500
          this.previsualizando = false;
          this.itemSeleccionado = -1;
          this.facturaObtenida = null; //se limpia el comprobante.
        });
  }


  obtenerFacturaCalculada(remitos: string[]) {
    this.previsualizacion = false;
    this.comprobanteService.obtenerFacturaCalculada(remitos)
      .subscribe(result => {
        this.facturaCalculada = result;
        this.previsualizacion = true;
      })

  }

  obtenerNuevoComprobante(id: string) {
    this.previsualizacion = false;
    this.facturacionService.getNuevoComprobante(id, true)
      .subscribe(result => {
        this.facturaCalculada = result;
        this.previsualizacion = true;
      })

  }

  obtenerComprobantePorPrefijoNumeroTipo() {
    //this.mensajeError = "";
    setTimeout(() => {

      this.spinner.show('spinnerLista');
      setTimeout(() => {
        /** spinner ends after 2 seconds */
        this.spinner.hide('spinnerLista');
      }, 4000);
    });

    this.comprobanteService.obtenerComprobantePorPrefijoNumeroTipo(this.facturaObtenida.prefijo, this.facturaObtenida.numeroComprobante, this.facturaObtenida.tipoMovimiento)
      .subscribe(result => {
        this.facturaObtenida = result;
        if (result != null) {
          //this.mensajeError = "";
        }
        else {
          this.facturaObtenida = null; //se limpia el comprobante.
          //this.mensajeError = "No se encontraron comprobantes con busqueda seleccionada. " + this.prefijo.value + '-' + this.numero.value;
        }

      },
        err => {
          // Entra aquí si el servicio entrega un código http de error EJ: 404,
          //500
          this.facturaObtenida = null; //se limpia el comprobante.
          //this.mensajeError = "No se encontraron comprobantes con busqueda seleccionada. " + this.prefijo.value + '-' + this.numero.value;
        });

  }


  seleccionarFactura(factura: RefacturacionFactura, event) {
    if (event.target.checked) {
      this.facturasSeleccionadas.push(factura);
    } else {
      var indice = this.facturasSeleccionadas.indexOf(factura);
      if (indice != -1) {
        this.facturasSeleccionadas.splice(indice, 1);
      }
    }
  }


  aceptarRefacturacion() {
    this.aceptandoRefacturacion = true;

    let refacturacion = new RefacturacionFactura();
    let tupla: TuplaRefacturacion[] = [];

    for (let fact of this.facturasSeleccionadas) {
      let t = new TuplaRefacturacion();
      t.esRefacturacionTotal = fact.esRefacturacionTotal;
      t.refacturacionFacturaId = fact.id;
      tupla.push(t);
    }
    refacturacion.tupla = tupla;
    refacturacion.responsable = this.obtenerStringUsuarioSolicitador();
    refacturacion.estado = 0 // candidata
    refacturacion.estadoFinal = 1 // pendiente
    this.facturacionService.actualizarRefacturacion(refacturacion)
      .subscribe(result => {
        this.messageService.showSuccessMessage("Se ha realizado correctamente la refacturacion de la factura ");
        this.aceptandoRefacturacion = false;
        this.ngOnInit();
      }, (err: ErrorModel) => {
        this.messageService.showErrorMessage("Hubo un error al realizar la refacturacion " + err.description + ' ' + err.message);
        this.aceptandoRefacturacion = false;
      });

  }

  rechazarRefacturacion() {
    
    if (this.facturasSeleccionadas.length == 0) {
      this.messageService.showErrorMessage('No se seleccionó ninguna factura para rechazar.');
      return;
    } else {
      const modalRef = this.modalService.open(ModalMotivoRechazoComponent, { size: 'lg', backdrop: 'static' });
      modalRef.result.then((motivo) => {
        if (motivo == null) {
          return;
        }
        this.accionRechazar(motivo);
      }).catch((error) => {
        return;
      });
    }
  }

  accionRechazar(motivo) {
    this.rechazandoRefacturacion = true;

    let refacturacion = new RefacturacionFactura();
    let tupla: TuplaRefacturacion[] = [];

    for (let fact of this.facturasSeleccionadas) {
      let t = new TuplaRefacturacion();
      t.esRefacturacionTotal = fact.esRefacturacionTotal;
      t.refacturacionFacturaId = fact.id;
      tupla.push(t);
    }
    refacturacion.tupla = tupla;
    refacturacion.responsable = this.obtenerStringUsuarioSolicitador();
    refacturacion.estado = 0 // candidata
    refacturacion.estadoFinal = 4 // rechazada
    refacturacion.motivoRechazo = motivo;

    this.facturacionService.actualizarRefacturacion(refacturacion)
      .subscribe(result => {
        this.messageService.showSuccessMessage("Se ha realizado correctamente la refacturacion de la factura ");
        this.rechazandoRefacturacion = false;
        this.ngOnInit();
      }, (err: ErrorModel) => {
        this.messageService.showErrorMessage("Hubo un error al realizar la refacturacion. " + err.description + ' ' + err.message);
        this.rechazandoRefacturacion = false;
      });
  }


  // getters

  obtenerStringUsuarioSolicitador() {
    return this.facturacionService.obtenerStringSolicitador();
  }

  get busqueda() {
    return this.busquedaFormControl;
  }

}
