import { Directive, ElementRef, HostListener, inject } from '@angular/core';

@Directive({
  selector: '[appOnlyDecimal]'
})
export class OnlyDecimalDirective {
  
  er = inject(ElementRef);

  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent) {
    if (['Delete', 'Backspace', 'Tab', 'Escape', 'Enter'].indexOf(e.key) !== -1 ||
        // Permitir Ctrl+A
        (e.key === 'a' && e.ctrlKey === true) ||
        // Permitir Ctrl+C
        (e.key === 'c' && e.ctrlKey === true) ||
        // Permitir Ctrl+V
        (e.key === 'v' && e.ctrlKey === true) ||
        // Permitir Ctrl+X
        (e.key === 'x' && e.ctrlKey === true)) {
      return;
    }

    const inputValue: string = this.er.nativeElement.value;
    const currentCursorPosition: number = this.er.nativeElement.selectionStart;

    // Asegurarse de que solo se ingresen números decimales positivos
    if (
      (isNaN(Number(e.key)) && e.key !== '.' && e.key !== ',') || // Solo números y punto
      (e.key === '.' && inputValue.includes('.')) || // Solo un punto decimal
      (e.key === ',' && inputValue.includes(',')) || // Solo un coma decimal
      (currentCursorPosition === 0 && e.key === '-') || // No permitir el signo al principio
      (inputValue.includes('-') && e.key === '-') // No permitir otro signo si ya hay uno
    ) {
      e.preventDefault();
    }
  }
}
