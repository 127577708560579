import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AeroplantasService } from '../../aeroplantas.service';
import { ItemMultiFiltro } from '../ItemMultifiltro';

@Component({
  selector: 'app-item-multifiltro',
  templateUrl: './item-multifiltro.component.html',
  styleUrls: ['./item-multifiltro.component.css']
})

export class ItemMultifiltroComponent implements OnInit {  
  @Input() encabezadoColumna: string;
  @Input() elementos: ItemMultiFiltro[] ; 
  @Output() elementosChange = new EventEmitter<ItemMultiFiltro[]>();  
  @Input() nombreAtributo: string;   
  @Input() public decoratorFn: Function; 

  //@Input() lista: any[];  
  private _lista: any[]; 
  @Input() set lista(value: any[]) {    
    this._lista = value;
    this.cargarListaItems();
 }

  idElemento: string;  
  public static SIMBOLO_VALOR_NULO : string = "-";

  constructor(private aeroplantaService: AeroplantasService) { 
    
  }

  ngOnInit() {
    this.idElemento = "dropdown" + this.nombreAtributo;
    
    this.cargarListaItems();
  }

  filtrar(){
    this.elementosChange.emit(this.elementos);
  }

  cargarListaItems(){
    try{
      this.elementos = [];
      
      var self = this;
      var listaProcesada = this._lista.map(e => {
        try{
            let elementos = self.nombreAtributo.split(".");
            
            if(elementos.length == 1){
              return e[self.nombreAtributo];
            }else{
              return e[elementos[0]][elementos[1]];
            }
        }catch(e){
          //console.log(e);
          return null;
        }        
      }).filter((value, index, self) => self.indexOf(value) === index);

      if(listaProcesada.length > 0 && !isNaN(listaProcesada[0])){
        listaProcesada.sort((a, b) => {
          if(a == null) return -1;
          if(b == null) return 1;

          return (Number(a) - Number(b));
        });
      }else{
        listaProcesada.sort((a, b) => {
          if(a == null) return -1;
          if(b == null) return 1;

          return (a.localeCompare(b));
        });        
      }
              
      listaProcesada.forEach( (value) => {
        if (value != null) {
            this.elementos.push({ id: value, nombre: this.decoratorFn != null ? this.decoratorFn(value) : value, seleccionado: false });
        } else {
          this.elementos.push({ id: ItemMultifiltroComponent.SIMBOLO_VALOR_NULO, nombre: "Sin dato", seleccionado: false });
        }

      }, this);
      
    }catch(e){
      console.log(e);
    }    
  }  
}
