import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductoService } from 'src/app/abm/producto/producto.service';
import { UsuarioService } from 'src/app/account/usuario.service';
import { CalculosService } from 'src/app/shared/calculos.service';
import { TipoContenedor } from 'src/app/shared/enums/tipoContenedor';
import { TipoExpendedora } from 'src/app/shared/enums/tipoExpendedora';
import { CierreTurno } from 'src/app/shared/models/cierreTurno/CierreTurno';
import { Precierre } from 'src/app/shared/models/cierreTurno/Precierre';
import { Pico } from 'src/app/shared/models/despacho/Pico';
import { Producto } from 'src/app/shared/models/despacho/Producto';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { AjusteManualCombustible } from 'src/app/shared/models/stock/AjusteManualCombustible';
import { Contenedor } from 'src/app/shared/models/stock/Contenedor';
import { Motivo } from 'src/app/shared/models/stock/Motivo';
import { Responsable } from 'src/app/shared/models/stock/Responsable';
import { Trasvase } from 'src/app/shared/models/stock/Trasvase';
import { SharedService } from 'src/app/shared/shared.service';
import { MessageService } from 'src/app/shell/message.service';
import { StockService } from 'src/app/stock/stock.service';
import { CierreTurnoService } from 'src/app/cierreTurno/cierre-turno.service';

@Component({
  selector: 'app-precierre-abastecedora',
  templateUrl: './precierre-abastecedora.component.html',
  styleUrls: ['./precierre-abastecedora.component.css']
})
export class PrecierreAbastecedoraComponent implements OnInit {
  @Input() precierre: Precierre;
  @Input() cierreId: string;

  TipoExpendedora = TipoExpendedora;

  decimalRegex = /^[0-9]+(.[0-9]{0,4})?$/;
  negativeDecimalRegex = /^[+-]?[0-9]+(.[0-9]{0,4})?$/;
  positiveInteger = /^\+?(0|[1-9]\d*)$/;

  aeroplantaCod: string;
  guardandoPrecierre: boolean = false;
  mensajeError: string;

  obteniendoProductos: boolean;
  productos: Producto[] = [];
  productoSeleccionado: Producto;

  picosAbastecedora: Pico[] = [];
  obteniendoPicos: boolean;
  tipoExpendedora: number = null;
  numeroDensidad: number = 0.000;
  desactivarUI: boolean = false;

  precierreForm = new UntypedFormGroup({
    cantidadFormControl: new UntypedFormControl(),
    densidadFormControl: new UntypedFormControl(),
    temperaturaFormControl: new UntypedFormControl(),
    cantidad15FormControl: new UntypedFormControl(),
    picosFormArray: new UntypedFormArray([]),
  });

  constructor(public activeModal: NgbActiveModal,
    private cierreTurnoService: CierreTurnoService,
    private stockService: StockService,
    private usuarioService: UsuarioService,
    private messageService: MessageService,
    private productoService: ProductoService,
    private formBuilder: UntypedFormBuilder,
    private sharedService: SharedService,
    private calculoService: CalculosService) { }

  ngOnInit() {

    this.getProductos();
    this.cargarPicos();
    this.aeroplantaCod = this.usuarioService.getAeroplantaActual().codigoAeroplanta;
    if (this.estaEditando())
    {
      this.temperaturaFormControl.setValue(this.precierre.temperatura);
      this.densidadFormControl.setValue(this.precierre.densidad);  
    }
  }

  estaEditando(){
    return this.precierre.id != null ? true : false;
  }

  recalcularVolumenNatural() {
    var volumenLts = Number(this.cantidadFormControl.value);
    var dens = Number(this.densidadFormControl.value);
    var temp = Number(this.temperaturaFormControl.value);

    if (volumenLts != null && dens != null && temp != null) {
      let vol15 = this.calculoService.obtenerCTLNuevo(temp, dens, volumenLts);
      if (!isNaN(vol15)) {			  
        this.cantidad15FormControl.setValue(Math.round(vol15));
      } else {
        this.cantidad15FormControl.setValue(0);
      }
	  
    }
  }

  // resetForm() {
  //   this.temperaturaFormControl.setValue(this.precierre.temperatura);
  //   this.densidadFormControl.setValue(this.precierre.densidad);
  //   this.cantidadFormControl.setValue(this.precierre.nivel);
  //   this.cantidad15FormControl.setValue(this.precierre.nivel15);
  //   this.mensajeError = null;
  // }

  armarResponsable() {
    var r = new Responsable();
    r.UserName = this.usuarioService.getUsername();
    r.NombreCompleto = this.usuarioService.getNombresCompletosUsuario();
    r.DNI = this.usuarioService.getDniUsuario();

    return r;
  }

  validarDensidad(densidad) {
    try {
      if (densidad != null) {
        let esValido = true;
        var codigoProductoAbastecedora = this.precierre.codigoProducto;

        this.productoSeleccionado = this.productos.find(element => element.codigoProducto === codigoProductoAbastecedora);

        esValido = (densidad >= this.productoSeleccionado.densidadMin && densidad <= this.productoSeleccionado.densidadMax);

        return esValido;
      }
    } catch (ex) {
      console.log("Excepcion validacion densidad.");
    }
  }

  onlyDecimalPositivo(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46;
  }

  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0 ) ? null : (event.charCode >= 48 && event.charCode <= 57);
  }

  onlyDecimal(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode == 45 || event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57);
  }

  formatDensidad(event) {
    if (event.charCode == 8 || event.charCode == 0) return null;

    if (event.charCode >= 48 && event.charCode <= 57) {
      this.densidadFormControl.setValue(this.sharedService.formatDensidad(event));
    }
    this.recalcularVolumenNatural();
    return false;
  }

  guardarPrecierreAbastecedora() {

    this.mensajeError = null;

    if (!this.validarFormulario()) {
      return;
    }

    var picos: Pico[] = [];

    for (let picoForm of this.precierreForm.value.picosFormArray) {
      var pico = new Pico();
      pico.id = picoForm.idPico;
      pico.nombre = picoForm.nombrePico;
      pico.aforadorFinalManual = picoForm.aforadorPico;

      picos.push(pico);
    }

    this.guardandoPrecierre = true;

    let precierre = new Precierre();
    precierre.responsableDTO = this.armarResponsable();
    precierre.densidad = Number(this.densidadFormControl.value);
    precierre.temperatura = Number(this.temperaturaFormControl.value);
    precierre.nivel = this.cantidadFormControl.value != null ? Number(this.cantidadFormControl.value) : null;
    precierre.nivel15 = this.cantidad15FormControl.value != null ? Number(this.cantidad15FormControl.value) : null;
    precierre.codigoExpendedora = this.precierre.codigoExpendedora;
    precierre.nombreExpendedora = this.precierre.nombreExpendedora;
    precierre.codigoProducto = this.precierre.codigoProducto;
    precierre.nombreProducto = this.precierre.nombreProducto;
    precierre.picos = picos;

    if(this.estaEditando()){
      precierre.id = this.precierre.id;
      this.cierreTurnoService.actualizarPrecierreAbastecedora(this.cierreId, precierre)
      .subscribe(result => {
        this.messageService.showSuccessMessage('Se actualizo de manera correcta el precierre');
        this.guardandoPrecierre = false;
        this.activeModal.close(true);
      }, (error: ErrorModel) => {
        this.mensajeError = error.description;
        this.guardandoPrecierre = false;
      });

    }else{
      this.cierreTurnoService.precerrarAbastecedora(this.cierreId, precierre)
      .subscribe(result => {
        this.messageService.showSuccessMessage('Se generó de manera correcta el precierre');
        this.guardandoPrecierre = false;
        this.activeModal.close(true);
      }, (error: ErrorModel) => {
        this.mensajeError = error.description;
        this.guardandoPrecierre = false;
      });
    }

    
  }

  cargarPicos() {
    this.obteniendoPicos = true;

    var codAeroplanta = this.usuarioService.getAeroplantaActual().codigoAeroplanta;
    this.stockService.getExpendedorasPorAeroplanta(codAeroplanta).subscribe(result => {
      var expendedoraActual = result.filter(e => e.codigo == this.precierre.codigoExpendedora)[0];
      this.picosAbastecedora = expendedoraActual.picos;
      this.obteniendoPicos = false;

      this.tipoExpendedora = expendedoraActual.tipoExpendedora;

      this.picosAbastecedora.forEach((pico, index) => {
        this.picosFormArray.push(this.nuevoItemPicoForm(pico, index));
      });
      
      this.desactivarUI = expendedoraActual.activo==false;

      if(expendedoraActual.activo==false) {
        this.cantidadFormControl.setValue(0);
        this.cantidadFormControl.disable();
        this.recalcularVolumenNatural();
      }else if(this.estaEditando) {
        this.cantidadFormControl.setValue(this.precierre.nivel);
        this.cantidadFormControl.enable();
        this.cantidad15FormControl.setValue(this.precierre.nivel15);
      }
    }, () => {
      this.obteniendoPicos = false;
      this.mensajeError = "Error al obtener picos de abastecedora.";
    });
  }

  nuevoItemPicoForm(pico: Pico, index: number) {

    return this.formBuilder.group({
      idPico: [pico.id, Validators.required],
      nombrePico: [pico.nombre, Validators.required],
      aforadorPico: [this.estaEditando() ? this.precierre.picos[index].aforadorFinalManual : "", Validators.required],
    })
  }

  getProductos() {
    this.obteniendoProductos = true;
    this.productoService.obtenerProductos()
      .subscribe(result => {
        this.productos = result;
        this.obteniendoProductos = false;
      }, () => {
        this.obteniendoProductos = false;
        this.mensajeError = "Error al obtener productos para validar densidad.";
      });
  }

  validarFormulario() {
    if (this.tipoExpendedora == TipoExpendedora.ABASTECEDORA_CAMION) {

      if (!this.decimalRegex.test(this.densidadFormControl.value)) {
        this.mensajeError = "El campo densidad debe ser decimal valido.";
        return false;
      }

      if (!this.validarDensidad(this.densidadFormControl.value)) {
        this.mensajeError = "La densidad debe estar entre el rango [" + this.productoSeleccionado.densidadMin + "," + this.productoSeleccionado.densidadMax + "]";
        return false;
      }

      if (!this.negativeDecimalRegex.test(this.temperaturaFormControl.value)) {
        this.mensajeError = "El campo temperatura debe ser decimal valido.";
        return false;
      }

      if (this.temperaturaFormControl.value <= -50 || this.temperaturaFormControl.value > 100) {
        this.mensajeError = "El campo temperatura tiene un valor fuera del rango natural.";
        return false;
      }

      if (!this.positiveInteger.test(this.cantidadFormControl.value)) {
        this.mensajeError = "El campo cantidad debe ser decimal valido.";
        return false;
      }
    }

    for (let picoForm of this.precierreForm.value.picosFormArray) {
      if (!this.positiveInteger.test(picoForm.aforadorPico)) {
        this.mensajeError = "El aforador del pico " + picoForm.nombrePico + " debe ser un valor numerico positivo.";
        return false;
      }
    }

    return true;
  }

  obtenerDescripcionExpendedora() {
    if (this.tipoExpendedora == TipoExpendedora.ABASTECEDORA_CAMION) {
      return "Abastecedora";
    } else if (this.tipoExpendedora == TipoExpendedora.SURTIDOR) {
      return "Surtidor";
    } else if (this.tipoExpendedora == TipoExpendedora.DISPENSER) {
      return "Dispenser";
    } else {
      return "Expendedora";
    }
  }

  obtenerEntidadCartel() {
    let entidad:string="inactiva";
    if (this.tipoExpendedora == TipoExpendedora.SURTIDOR || this.tipoExpendedora == TipoExpendedora.DISPENSER) {
      entidad=" inactivo"
    }
    return entidad;
  }

  get cantidadFormControl() {
    return this.precierreForm.get('cantidadFormControl');
  }

  get densidadFormControl() {
    return this.precierreForm.get('densidadFormControl');
  }

  get cantidad15FormControl() {
    return this.precierreForm.get('cantidad15FormControl');
  }

  get temperaturaFormControl() {
    return this.precierreForm.get('temperaturaFormControl');
  }

  get picosFormArray(): UntypedFormArray {
    return this.precierreForm.controls.picosFormArray as UntypedFormArray;
  }

}
