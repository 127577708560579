import { Component, OnInit, Input, NgModule, Output, EventEmitter } from '@angular/core';
import { Comprobante } from 'src/app/shared/models/facturacion/Comprobante';
import { ComprobanteService } from '../comprobante.service';
import { Rubro } from 'src/app/shared/models/despacho/Rubro';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, Validators } from '@angular/forms';
import { NotaDebito } from 'src/app/shared/models/facturacion/NotaDebito';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlicuotaTipoBaseIIBB } from 'src/app/shared/models/facturacion/AlicuotaTipoBaseIIBB';
import { renglonNDebito } from 'src/app/shared/models/facturacion/RenglonNDebito';
import { TipoIvaAfip } from 'src/app/shared/enums/TipoIvaAfip';
import { ImpuestoPercepcion } from 'src/app/shared/models/facturacion/ImpuestoPercepcion';
import { TipoCodigoParametroImpositivo } from 'src/app/shared/enums/TipoCodigoParametroImpositivo';
import { MessageService } from 'src/app/shell/message.service';
import { DecimalPipe } from '@angular/common';
import { TipoNeto } from 'src/app/shared/enums/tipoNeto';
import { HandleError } from 'src/app/http-error-handler.service';
@Component({
  selector: 'app-debit-memo-panel',
  templateUrl: './debit-memo-panel.component.html',
  styleUrls: ['./debit-memo-panel.component.css'],
  providers: [DecimalPipe]
})
export class DebitMemoPanelComponent implements OnInit {

  msgError: boolean = false;

  @Input() facturaObtenida: Comprobante;

  @Output() notaDebitoChange = new EventEmitter<Comprobante>();

  constructor(private comprobanteService: ComprobanteService,
    private formBuilder: UntypedFormBuilder,
    public modal: NgbModal,
    private messageService: MessageService) { }



  // errorMessage: string = "";

  parametrosImpositivos: AlicuotaTipoBaseIIBB = new AlicuotaTipoBaseIIBB();
  rubros: Rubro[] = [];
  alicuotas: Array<{ id: number, text: string }>;
  renglonForm: UntypedFormGroup;
  renglonFormValueChanges$;
  notaDeb: NotaDebito;
  valido: boolean;
  emitiendoNota: boolean = false;

  tipoIvaAfip = TipoIvaAfip;
  notaDebitoPrueba: Comprobante;
  idIVA: string;
  idRubro: string;
  totalPercepciones: number;
  totalNeto: number;
  totalIva: number;
  totalImpInternos: number;
  totalCo2: number;
  totalTotal: number;
  sumatoria = [
    { field: 'Neto', header: 'NETO' },
    { field: 'IVA', header: 'IVA' },
    { field: 'Imp.Int.', header: 'IMP.INT.' },
    { field: 'CO2', header: 'CO2' },
    { field: 'PercepIIBB', header: 'Perc. IIBB' },
    { field: 'Total', header: 'TOTAL' },
  ];
  handleError: HandleError;

  ngOnInit() {

    this.getRubros();
    this.getAlicuotasIva();
    this.getParametrosImpositivos(this.facturaObtenida.cliente.ctc, this.facturaObtenida.codigoAeroplanta);
    this.notaDeb = new NotaDebito();
    this.totalNeto = 0;
    this.msgError = false;
    this.totalIva = 0;
    this.totalImpInternos = 0;
    this.totalCo2 = 0;
    this.totalPercepciones = 0
    this.totalTotal = 0;
    this.renglonForm = this.formBuilder.group({
      renglonesDinamicos: this.formBuilder.array([
        // load first row at start
        this.armarNuevoRenglonDinamico()
      ])
    });
    // this.valido = true;

    this.renglonFormValueChanges$ = this.renglonForm.controls['renglonesDinamicos'].valueChanges;
    this.renglonFormValueChanges$.subscribe(formGroup => this.inputVisualRenglonModificado(formGroup))

  }

  /**
   * unsubscribe listener
   */
  ngOnDestroy(): void {
    this.renglonFormValueChanges$.unsubscribe();
  }

  // Operaciones

  /**
   * Create form unit
   */
  private armarNuevoRenglonDinamico() {
    return this.formBuilder.group({
      rubroId: [TipoNeto.NetoConceptosFinancieros, [Validators.required]],
      detalle: ['', [Validators.required]],
      neto: ['', [Validators.required]],
      alicuotaIvaId: [this.tipoIvaAfip.Veintiuno, [Validators.required]],
      impuestoInterno: [],
      percepcionesIngresosBrutos: [],
      co2: [],
    });

  }

  agregarNuevoRenglonDinamico() {
    if (this.renglonForm.valid) {
      const control = <UntypedFormArray>this.renglonForm.controls['renglonesDinamicos'];
      control.push(this.armarNuevoRenglonDinamico());
    }
    else {
      this.messageService.clear();      
      this.messageService.showWarningLongMessage("No se podrá agregar un nuevo renglón a la nota de débito hasta que este renglón este completo.");
    }
  }

  eliminarRenglonesDinamicos(i: number) {
    const control = <UntypedFormArray>this.renglonForm.controls['renglonesDinamicos'];
    if (control.length == 1) {
      this.eliminarTodosRenglones()
    }
    else {
      control.removeAt(i);
    }
  }

  eliminarTodosRenglones() {
    const control = <UntypedFormArray>this.renglonForm.controls['renglonesDinamicos'];
    while (control.length) {
      control.removeAt(control.length - 1);
    }
    control.clearValidators();
    control.push(this.armarNuevoRenglonDinamico());
  }

  setRubroId(renglonesNotaDeb: renglonNDebito[]) {
    for (let i in renglonesNotaDeb) {
      for (let j in this.rubros) {
        if (parseInt(renglonesNotaDeb[i].rubroId) == this.rubros[j].tipoNeto) {
          renglonesNotaDeb[i].rubroId = this.rubros[j].id;
        }
      }
    }
  }

  public inputVisualRenglonModificado(formGroup: any) {
    // recalcular todos los totales
    const control = <UntypedFormArray>this.renglonForm.controls['renglonesDinamicos'];

    this.totalPercepciones = 0;
    this.totalNeto = 0;
    this.totalIva = 0;
    this.totalImpInternos = 0;
    this.totalCo2 = 0;
    this.totalTotal = 0;

    for (let i in formGroup) {
      let neto = parseFloat(formGroup[i].neto);
      if (!isNaN(neto)) {
        this.totalNeto += neto;
        formGroup[i].neto = parseFloat(formGroup[i].neto);
      }

      let subtotalIva = (((this.obtenerValorIva(formGroup[i].alicuotaIvaId) * parseFloat(formGroup[i].neto)) / 100));
      if (!isNaN(subtotalIva)) {
        this.totalIva += subtotalIva;
      }

      let impInterno = parseFloat(formGroup[i].impuestoInterno)
      if (!isNaN(impInterno)) {
        this.totalImpInternos += impInterno;
        formGroup[i].impuestoInterno = parseFloat(formGroup[i].impuestoInterno);
      }

      let co2 = parseFloat(formGroup[i].co2)
      if (!isNaN(co2)) {
        this.totalCo2 += co2;
        formGroup[i].co2 = parseFloat(formGroup[i].co2);
      }
      //pregunto si el rubro es combustible para usar la alicuota de combustible o la de defecto.
      if (formGroup[i].rubroId == 1) {
        switch (this.parametrosImpositivos.tipoBaseImponibleIIBB) {
          case (0): {  //Solo neto.
            if (this.parametrosImpositivos.baseCalculoMinimoImponible < this.totalNeto && this.parametrosImpositivos.importeMinimoPercepcion < ((this.totalNeto * this.parametrosImpositivos.alicuotaIIBBCombustible) / 100)) {
              this.totalPercepciones += (formGroup[i].neto * this.parametrosImpositivos.alicuotaIIBBCombustible) / 100;
              formGroup[i].percepcionesIngresosBrutos = (formGroup[i].neto * this.parametrosImpositivos.alicuotaIIBBCombustible) / 100;
              this.totalTotal = this.totalCo2 + this.totalImpInternos + this.totalNeto + this.totalIva + this.totalPercepciones;
            } else {
              this.totalTotal = this.totalCo2 + this.totalImpInternos + this.totalNeto + this.totalIva;
            }
            break;
          }
          case (1): { //Total Factura
            if (this.parametrosImpositivos.baseCalculoMinimoImponible < (this.totalCo2 + this.totalImpInternos + this.totalNeto + this.totalIva) && this.parametrosImpositivos.importeMinimoPercepcion < ((this.totalCo2 + this.totalImpInternos + this.totalNeto + this.totalIva * this.parametrosImpositivos.alicuotaIIBBCombustible) / 100)) {
              this.totalTotal = this.totalCo2 + this.totalImpInternos + this.totalNeto + this.totalIva;
              this.totalPercepciones += (this.totalTotal * this.parametrosImpositivos.alicuotaIIBBCombustible) / 100;
              this.totalTotal = this.totalCo2 + this.totalImpInternos + this.totalNeto + this.totalIva + this.totalPercepciones;
            } else {
              this.totalTotal = this.totalCo2 + this.totalImpInternos + this.totalNeto + this.totalIva;
            }
            break;
          }
          case (2): { //Neto + Impuesto Interno.
            if (this.parametrosImpositivos.baseCalculoMinimoImponible < this.totalNeto + this.totalImpInternos && this.parametrosImpositivos.importeMinimoPercepcion < (((this.totalNeto + this.totalImpInternos) * this.parametrosImpositivos.alicuotaIIBBCombustible) / 100)) {
              this.totalPercepciones += ((formGroup[i].neto + formGroup[i].impuestoInterno) * this.parametrosImpositivos.alicuotaIIBBCombustible) / 100;
              formGroup[i].percepcionesIngresosBrutos = ((formGroup[i].neto + formGroup[i].impuestoInterno) * this.parametrosImpositivos.alicuotaIIBBCombustible) / 100;
              this.totalTotal = this.totalCo2 + this.totalImpInternos + this.totalNeto + this.totalIva + this.totalPercepciones;
            } else {
              this.totalTotal = this.totalCo2 + this.totalImpInternos + this.totalNeto + this.totalIva;
            }
            break;
          }
          case (3): { //Neto + Impuesto Interno + CO2
            if (this.parametrosImpositivos.baseCalculoMinimoImponible < this.totalNeto + this.totalImpInternos + this.totalCo2 && this.parametrosImpositivos.importeMinimoPercepcion < (((this.totalNeto + this.totalImpInternos + this.totalCo2) * this.parametrosImpositivos.alicuotaIIBBCombustible) / 100)) {
              this.totalPercepciones += ((formGroup[i].neto + formGroup[i].impuestoInterno + formGroup[i].co2) * this.parametrosImpositivos.alicuotaIIBBCombustible) / 100;
              formGroup[i].percepcionesIngresosBrutos = ((formGroup[i].neto + formGroup[i].impuestoInterno + formGroup[i].co2) * this.parametrosImpositivos.alicuotaIIBBCombustible) / 100;
              this.totalTotal = this.totalCo2 + this.totalImpInternos + this.totalNeto + this.totalIva + this.totalPercepciones;
            } else {
              this.totalTotal = this.totalCo2 + this.totalImpInternos + this.totalNeto + this.totalIva;
            }
            break;
          }
        }
      } else {
        switch (this.parametrosImpositivos.tipoBaseImponibleIIBB) {
          case (0): { //Solo Neto
            if (this.parametrosImpositivos.baseCalculoMinimoImponible < this.totalNeto && this.parametrosImpositivos.importeMinimoPercepcion < ((this.totalNeto * this.parametrosImpositivos.alicuotaIIBBDefecto) / 100)) {
              this.totalPercepciones += (formGroup[i].neto * this.parametrosImpositivos.alicuotaIIBBDefecto) / 100;
              formGroup[i].percepcionesIngresosBrutos = (formGroup[i].neto * this.parametrosImpositivos.alicuotaIIBBDefecto) / 100;
              this.totalTotal = this.totalCo2 + this.totalImpInternos + this.totalNeto + this.totalIva + this.totalPercepciones;
            } else {
              this.totalTotal = this.totalCo2 + this.totalImpInternos + this.totalNeto + this.totalIva;
            }
            break;
          }
          case (1): { //Total Factura
            if (this.parametrosImpositivos.baseCalculoMinimoImponible < (this.totalCo2 + this.totalImpInternos + this.totalNeto + this.totalIva) && this.parametrosImpositivos.importeMinimoPercepcion < ((this.totalCo2 + this.totalImpInternos + this.totalNeto + this.totalIva * this.parametrosImpositivos.alicuotaIIBBDefecto) / 100)) {
              this.totalTotal = this.totalCo2 + this.totalImpInternos + this.totalNeto + this.totalIva;
              this.totalPercepciones += (this.totalTotal * this.parametrosImpositivos.alicuotaIIBBDefecto) / 100;
              this.totalTotal = this.totalCo2 + this.totalImpInternos + this.totalNeto + this.totalIva + this.totalPercepciones;
            } else {
              this.totalTotal = this.totalCo2 + this.totalImpInternos + this.totalNeto + this.totalIva;
            }
            break;
          }
          case (2): { //Neto + Impuesto Interno
            if (this.parametrosImpositivos.baseCalculoMinimoImponible < this.totalNeto + this.totalImpInternos && this.parametrosImpositivos.importeMinimoPercepcion < (((this.totalNeto + this.totalImpInternos) * this.parametrosImpositivos.alicuotaIIBBDefecto) / 100)) {
              this.totalPercepciones += ((formGroup[i].neto + formGroup[i].impuestoInterno) * this.parametrosImpositivos.alicuotaIIBBDefecto) / 100;
              formGroup[i].percepcionesIngresosBrutos = ((formGroup[i].neto + formGroup[i].impuestoInterno) * this.parametrosImpositivos.alicuotaIIBBDefecto) / 100;
              this.totalTotal = this.totalCo2 + this.totalImpInternos + this.totalNeto + this.totalIva + this.totalPercepciones;
            } else {
              this.totalTotal = this.totalCo2 + this.totalImpInternos + this.totalNeto + this.totalIva;
            }
            break;
          }
          case (3): { //Neto + Impuesto Interno + CO2
            if (this.parametrosImpositivos.baseCalculoMinimoImponible < this.totalNeto + this.totalImpInternos + this.totalCo2 && this.parametrosImpositivos.importeMinimoPercepcion < (((this.totalNeto + this.totalImpInternos + this.totalCo2) * this.parametrosImpositivos.alicuotaIIBBDefecto) / 100)) {
              this.totalPercepciones += ((formGroup[i].neto + formGroup[i].impuestoInterno + formGroup[i].co2) * this.parametrosImpositivos.alicuotaIIBBDefecto) / 100;
              formGroup[i].percepcionesIngresosBrutos = ((formGroup[i].neto + formGroup[i].impuestoInterno + formGroup[i].co2) * this.parametrosImpositivos.alicuotaIIBBDefecto) / 100;
              this.totalTotal = this.totalCo2 + this.totalImpInternos + this.totalNeto + this.totalIva + this.totalPercepciones;
            } else {
              this.totalTotal = this.totalCo2 + this.totalImpInternos + this.totalNeto + this.totalIva;
            }
            break;
          }
        }
      }
    }
    this.notaDeb.renglones = formGroup;
  }


  obtenerValorIva(indexEnumIva) {
    if (indexEnumIva == this.tipoIvaAfip.Cero) {
      return 0;
    } else if (indexEnumIva == this.tipoIvaAfip.DiezPuntoCinco) {
      return 10.5;
    } else if (indexEnumIva == this.tipoIvaAfip.Veintiuno) {
      return 21;
    } else if (indexEnumIva == this.tipoIvaAfip.Veinticiete) {
      return 27;
    } else {
      return 0;
    }
  }


  obtenerTipoNeto(t: number)
  {
    let aux=TipoNeto[t];
    return aux.slice(4, aux.length - 1);
  }

  getRubros() {
    this.comprobanteService.getRubros()
      .subscribe(result => {
        this.rubros = result;
      });
  }

  getAlicuotasIva() {
    const tiposIva: Array<{ id: number, text: string }> = [
      { id: TipoIvaAfip.Cero, text: '0 %' },
      { id: TipoIvaAfip.DiezPuntoCinco, text: '10.5 %' },
      { id: TipoIvaAfip.Veintiuno, text: '21 %' },
      { id: TipoIvaAfip.Veinticiete, text: '27 %' },
    ];

    this.alicuotas = tiposIva;
  }

  getParametrosImpositivos(cuentaCTC: string, codigoAeroplanta: string) {

    this.comprobanteService.getParametrosImpositivos(cuentaCTC, codigoAeroplanta)
      .subscribe(result => {
        (this.parametrosImpositivos = result);
      });
  }

  obtenerCodigoParametroImpositivo(indexCodigo) {
    if (indexCodigo == TipoCodigoParametroImpositivo.CO2) {
      return "CO2";
    } else if (indexCodigo == TipoCodigoParametroImpositivo.IVA) {
      return "IVA";
    } else if (indexCodigo == TipoCodigoParametroImpositivo.IMPINT) {
      return "IMP. INT.";
    } else {
      return "OTRO";
    }
  }

  generarArrayImpuestosPercepciones(renglon): ImpuestoPercepcion[] {
    let listaImpuestos: ImpuestoPercepcion[] = [];

    let impCo2 = new ImpuestoPercepcion();
    impCo2.codigo = TipoCodigoParametroImpositivo.CO2;
    impCo2.leyenda = this.obtenerCodigoParametroImpositivo(TipoCodigoParametroImpositivo.CO2);
    impCo2.baseCalculo = 0; // Neto = 0, Total = 1, NetoMasImpuestoInterno = 2, NetoMasImpuestoInternoMasTasas = 3,
    impCo2.tipoValor = 1; // Alicuota = 0, ValorFijo = 1,
    impCo2.valor = renglon.co2;

    listaImpuestos.push(impCo2);

    let impIva = new ImpuestoPercepcion();
    impIva.codigo = TipoCodigoParametroImpositivo.IVA;
    impIva.leyenda = this.obtenerCodigoParametroImpositivo(TipoCodigoParametroImpositivo.IVA);
    impIva.baseCalculo = 0; // Neto = 0, Total = 1, NetoMasImpuestoInterno = 2, NetoMasImpuestoInternoMasTasas = 3,
    impIva.tipoValor = 0; // Alicuota = 0, ValorFijo = 1,
    impIva.valor = this.obtenerValorIva(renglon.alicuotaIvaId);

    listaImpuestos.push(impIva);

    let impInt = new ImpuestoPercepcion();
    impInt.codigo = TipoCodigoParametroImpositivo.IMPINT;
    impInt.leyenda = this.obtenerCodigoParametroImpositivo(TipoCodigoParametroImpositivo.IMPINT);
    impInt.baseCalculo = 0; // Neto = 0, Total = 1, NetoMasImpuestoInterno = 2, NetoMasImpuestoInternoMasTasas = 3,
    impInt.tipoValor = 1; // Alicuota = 0, ValorFijo = 1,
    impInt.valor = renglon.impuestoInterno;

    listaImpuestos.push(impInt);

    return listaImpuestos;
  }

  emitirNotaDebito() {
    this.messageService.clear();
    let i: number;
    if (this.renglonForm.valid) {
      this.emitiendoNota = true;
      for (i = 0; i < this.notaDeb.renglones.length; i++) {
        if (this.notaDeb.renglones[i].impuestoInterno === null) {
          this.notaDeb.renglones[i].impuestoInterno = 0;
        }
        if (this.notaDeb.renglones[i].co2 === null) {
          this.notaDeb.renglones[i].co2 = 0;
        }

        this.notaDeb.renglones[i].precioBase = this.notaDeb.renglones[i].neto;
        this.notaDeb.renglones[i].impuestosPercepciones = this.generarArrayImpuestosPercepciones(this.notaDeb.renglones[i]);
      }
      this.setRubroId(this.notaDeb.renglones);
      this.notaDeb.numero = this.facturaObtenida.numeroComprobante;
      this.notaDeb.prefijo = this.facturaObtenida.prefijo;
      this.notaDeb.tipoMovimiento = this.facturaObtenida.tipoMovimiento;
      console.log(this.notaDeb.numero, this.notaDeb.prefijo, this.notaDeb.tipoMovimiento);
       this.comprobanteService.emitirNotaDebito(this.notaDeb)
        .subscribe(result => {
          if (result) {
            this.notaDebitoPrueba = result;
            this.messageService.showSuccessMessage("Se creo la Nota de Debito " + this.pad(result.prefijo, 4) + "-" + this.pad(result.numeroComprobante, 8) + " correctamente");
            this.emitiendoNota = false;
            this.notaDebitoChange.emit(this.notaDebitoPrueba);
            this.modal.dismissAll();
          } else {
              this.emitiendoNota = false;
          }
        });
    }
    else {
      this.messageService.showErrorMessage("No se podrá emitir la nota de débito hasta que el renglón este completo.");
      this.emitiendoNota = false;
    };
  }

  // validaciones y otros

  pad(num: number, size: number): string {
    let s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }

  onlyDecimalNumberKey(event) {
    let charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57))
      return false;
    return true;
  }

}
