<div *ngIf="!this.facturaCalculada" class="spinner-container">
  <ngx-spinner name="spinnerFactura"
    [ngClass]="{'list-group-item list-group-item-action': obteniendoFactura && this.calcFact}" bdOpacity=0.3bd
    Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
    <p style="color: white"> O b t e n i e n d o D E T A L L E . . . </p>
  </ngx-spinner>
</div>
<div class="modal-body" style="padding: 0 !important" *ngIf="!this.obteniendoFactura && this.facturaEmitida.length == 0">
  <div *ngFor="let factura of facturaCalculada; let t = index">
    <form [formGroup]="facturaAEmitir">
          <div>
            <div *ngIf="factura!=null || factura.tipoMovimiento==9" id="invoicingPrint"  [ngClass]="{'facturaPrint' : aeroplantaSoloPad}"
              class="card list-group facturaf">
              <div class="card-body" id="facturaPrint">
                <div class="container">
                  <div class="row justify-content-sm-center">
                    <div class="col-sm-auto border text-center line-small">
                      <p class="h4">{{factura.tipoMovimiento | tipoMovimiento }}</p>
                      <small>Cod.{{'0'+(factura.tipoMovimiento)}}</small>
                    </div>
                  </div>
                  <div *ngIf="factura.fechaAnulacion != null" class="me-auto">

                  </div>
                  <div class="justify-content-md-center d-flex">
                    <div id="emisorData" class="col border">
                      <ul class="list-unstyled js">
                        <li class="letra">
                          <img id="logo-ypf" src="/assets/images/logoypf.png" />
                        </li>
                        <li class="letra">{{factura.emisor.razonSocial}}</li>
                        <li class="letra">
                          {{factura.emisor.direccion}},&nbsp;{{factura.emisor.ciudad.nombre}}&nbsp;({{factura.emisor.ciudad.codigoPostal}})
                        </li>
                        <li class="letra">
                          {{factura.emisor.ciudad.provincia.nombre}},&nbsp;{{factura.emisor.ciudad.provincia.pais}}
                        </li>
                        <li class="letra">T.E.: {{factura.emisor.telefono}}</li>
                        <li class="letra">{{factura.emisor.email}}</li>
                      </ul>
                    </div>
                    <div id="comprobanteInfo" class="col border" *ngIf="this.factura!=null">
                      <ul class="list-unstyled js">
                        <li class="text-end letra">{{ factura.tipoMovimiento | nombreTipoComprobante:factura.factura.condicionVenta }} N°:
                          <b>{{factura.prefijo | facturaPrefijo }}-{{ factura.numeroComprobante | facturaNumero }}</b>
                        </li>
                        <li class="text-end letra">Original</li>
                        <li *ngIf="factura && factura" class="text-end letra">Fecha:
                          {{factura.fechaEmision | date:"dd/MM/yyyy"}}</li>
                        <li class="text-end letra">F.VTO: {{factura.fechaVencimiento | date:"dd/MM/yyyy"}}</li>
                        <li class="text-end letra">CUIT: {{factura.emisor.numeroDocumento}}
                        </li>
                        <li class="text-end letra">IIBB CM {{factura.emisor.numeroIIBB}}</li>
                        <li class="text-end letra">Inicio Actividades:
                          {{factura.emisor.fechaInicioActividades | date:"dd/MM/yyyy"}}</li>
                        <li class="text-end letra">{{factura.emisor.condicionIVA}}</li>
                      </ul>
                    </div>
                  </div>
                  <!--<div class="border justify-content-md-center p-2" id="aeroplantaInfo">
                    <div class="letra text-center">
                      Aeroplanta: {{factura.nombreAeroplanta}} ({{factura.codigoAeroplanta}})
                    </div>
                    <div class="letra text-center">
                      {{factura.ciudadAeroplanta}} - {{factura.provinciaAeroplanta}}
                    </div>
                  </div>-->
                  <div id="compradorInfo" class="justify-content-md-start border">
                    <ul class="list-unstyled js mb-2">
                      <li class="text-start letra">SRES: {{factura.cliente.razonSocial}} <b
                          *ngIf="clienteExento(factura)">IVA
                          exento exportación</b></li>
                      <li class="text-start letra">DOMICILIO: {{factura.cliente.direccion}} -
                        {{factura.cliente.nombreCiudad}} ({{factura.cliente.codigoPostalCiudad}})</li>
                      <li class="text-start letra">NRO. CUENTA: {{factura.cliente.sgc}}</li>
                      <br>
                      <li>
                        <div style="column-count: 3;" class="justify-content-between">
                          <p class="text-start ms-2 letra">{{factura.cliente.condicionIva}}</p>
                          <p class="text-center letra">CUIT: {{set_format_cuit(factura.cliente.numeroDocumento)}}</p>
                          <p class="text-end me-2 letra">IIBB N°: {{factura.cliente.numeroIIBB}}</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <div id="condicionPago" class="border" *ngIf="this.factura!=null">
                      <div class="row">
                        <div class="col p-1 ms-3 letra text-start">Condicion venta:
                          {{factura.factura.condicionVenta | condicionVenta }}
                        </div>
                        <div class="col p-1 me-3 letra text-end">P. Pago: {{factura.cliente.plazoPago != 0 ? factura.cliente.plazoPago : '--'}} días
                        </div>
                      </div>
                    </div>
                    <div id="detalle" class="justify-content-md-start border">
                      <!-- Listo renglones de facturas y nota de creditos y notas de debito-->
                      <table
                        *ngIf="factura!=null && this.factura.tipoMovimiento!=9  "
                        class="table table-sm table-striped; text-center">
                        <thead>
                          <tr class="letra">
                            <th style="width: 10%;">Cod.</th>
                            <th style="width: 50%;">Descripcion</th>
                            <th style="width: 10%;">Cant.</th>
                            <th style="width: 10%;">UM</th>
                            <th style="width: 10%;">Valor Unitario</th>
                            <th style="width: 10%;">Total</th>
                          </tr>
                        </thead>
                        <tbody *ngFor="let renglon of factura.factura.renglones" class="letra">
                          <tr>
                            <td>{{renglon.productoCodigo}}</td>
                            <td>{{renglon.productoNombre}}</td>
                            <td>{{ renglon.cantidad | number: '0.4' }}</td>
                            <td>{{renglon.abreviacionUnidadMedida}}</td>
                            <td>{{ (this.clienteDiscriminaImpuestos(factura) ? renglon.precioBase : renglon.precio) |
                              number:
                              '0.4' }}
                            </td>
                            <td>
                              {{ renglon.cantidad * (this.clienteDiscriminaImpuestos(factura) ? renglon.precioBase :
                              renglon.precio) |
                              number: '0.2'}}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <!-- listo renglones de un remito ya facturados que por ahora no se va a usar -->
                      <table *ngIf="factura!=null && factura.tipoMovimiento==9"
                        class="table table-sm table-striped; text-center">
                        <thead>
                          <tr>
                            <th scope="col" *ngFor="let col of columnas" class="letra">{{col.header}}</th>
                          </tr>
                        </thead>
                        <tbody *ngFor="let renglon of factura.renglones" class="letra">
                          <tr>
                            <td>{{renglon.producto.codigoProducto}}</td>
                            <td>{{renglon.producto.nombreProducto}}</td>
                            <td>{{ renglon.cantidad | number: '0.1-4' }}</td>
                            <td>{{renglon.abreviacionUnidadMedida}}</td>
                            <td> - </td><!-- {{ renglon.precioBase | number: '0.1-2' }}</td> -->
                            <td> - </td>
                            <td> {{ renglon.subtotalRenglon | number: '0.2' }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div id="total" *ngIf="factura!=null && factura.tipoMovimiento!=9" class="border">
                    <!-- cartel de anulado -->
                    <div *ngIf="factura!=null && factura.factura.estado === 3">
                      <div id="impuestos" class="me-4">
                        <br>
                        <div class="row">
                          <div class="col-6">
                            <!-- <div class="position-absolute letra-leyenda borde-Subtotal p-2 me-1" *ngIf="string != ''">
                              {{string}}
                            </div> -->
                          </div>
                          <div class="col p-2 letraImpuesto borde-Neto" style="border-right: none;"
                            *ngIf="this.clienteDiscriminaImpuestos(factura)"><b>IMPORTE NETO:</b></div>
                          <div class="col p-2 mpalabra borde-Neto" style="border-left: none;"
                            *ngIf="this.clienteDiscriminaImpuestos(factura)">
                            <b>{{factura.tipoMoneda | tipoMoneda }} &nbsp; &nbsp;
                              {{factura.factura.netoTotal | number: '0.2' }} </b>
                          </div>
                        </div>
                        <div class="img-fluid" style="position: absolute;" align="left">
                          <img id="logo-ypf" src="/assets/images/Anulado.png" />
                        </div>
                        <div class="row" *ngFor="let imp of factura.factura.impuestos">
                          <div class="col-6"></div>
                          <div class="col p-2 letraImpuesto borde-Impuesto" style="border-right: none;"                          
                            *ngIf="this.clienteDiscriminaImpuestos(factura)">{{ imp.leyenda}} {{ (incluirPorcentajeImpuesto(imp.leyenda) ? (obtenerPorcentaje(factura.factura, imp.leyenda) | number: '2.2-2') : "") }}:</div>
                          <div class="col p-2 mpalabra borde-Impuesto" style="border-left: none;"
                            *ngIf="this.clienteDiscriminaImpuestos(factura)">{{imp.valor | number: '0.2' }}</div>
                        </div>
                        <div class="row">
                          <div class="col-6"></div>
                          <div class="col p-2 letraImpuesto borde-Subtotal" style="border-right: none;"><b>SUBTOTAL:</b>
                          </div>
                          <div class="col p-2 mpalabra borde-Subtotal" style="border-left: none;">
                            <b>{{factura.factura.subtotal | number: '0.2' }}</b>
                          </div>
                        </div>
                        <div class="row" *ngFor="let perc of factura.factura.percepciones">
                          <div class="col-6"></div>
                          <div class="col p-2 letraImpuesto borde-Percepciones" style="border-right: none;"
                            *ngIf="this.clienteDiscriminaImpuestos(factura)">{{ perc.leyenda}} {{obtenerPorcentaje(factura.factura, perc.leyenda) | number: '2.2-2'}}:</div>
                          <div class="col p-2 mpalabra borde-Percepciones" style="border-left: none;"
                            *ngIf="this.clienteDiscriminaImpuestos(factura)">{{perc.valor | number: '0.2' }}</div>
                        </div>
                        <br>
                        <div class="row">
                          <div class="col-6"></div>
                          <div class="col p-2 letraImpuesto"
                            style="border: solid; border-width: 1px; border-right: none;">
                            <b>TOTAL:</b>
                          </div>
                          <div class="col p-2 mpalabra" style="border: solid; border-width: 1px; border-left: none;">
                            <b>{{factura.tipoMoneda | tipoMoneda }} &nbsp; &nbsp;
                              {{factura.factura.total | number: '0.2' }}</b>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-6 letra">
                            SON {{obtenerDescripcionMoneda(factura.tipoMoneda)}}&nbsp;{{factura.totalEnTexto}}
                          </div>
                        </div>
                        <br>
                        <div class="row ms-1">
                          <div class="col-8 letra" style="border: solid; border-width: 1px;">
                            Observaciones: {{factura.observaciones}}
                          </div>
                        </div>
                        <br>
                      </div>
                    </div>
                    <!-- Si no esta anulado -->
                    <div *ngIf="factura!=null && factura.factura.estado != 3">
                      <div id="impuestos" class="me-4">
                        <br>
                        <div class="row">
                          <div class="col-6">
                            <!--div class="position-absolute letra-leyenda borde-Subtotal p-2 me-1" *ngIf="string != ''">
                              {{string}}
                            </div-->
                          </div>
                          <div class="col p-2 letraImpuesto borde-Neto" style="border-right: none;"
                            *ngIf="this.clienteDiscriminaImpuestos(factura)"><b>IMPORTE NETO:</b></div>
                          <div class="col p-2 mpalabra borde-Neto" style="border-left: none;"
                            *ngIf="this.clienteDiscriminaImpuestos(factura)">
                            <b>{{factura.tipoMoneda | tipoMoneda }} &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                              &nbsp; &nbsp;
                              &nbsp; &nbsp; &nbsp; &nbsp;
                              {{factura.factura.netoTotal | number: '0.2' }} </b>
                          </div>
                        </div>
                        <div class="row" *ngFor="let imp of factura.factura.impuestos">
                          <div class="col-6"></div>
                          <div class="col p-2 letraImpuesto borde-Impuesto" style="border-right: none;"
                            *ngIf="this.clienteDiscriminaImpuestos(factura)">{{ imp.leyenda}} {{ (incluirPorcentajeImpuesto(imp.leyenda) ? (obtenerPorcentaje(factura.factura, imp.leyenda) | number: '2.2-2') : "") }}:</div>
                          <div class="col p-2 mpalabra borde-Impuesto" style="border-left: none;"
                            *ngIf="this.clienteDiscriminaImpuestos(factura)">{{imp.valor | number: '0.2' }}</div>
                        </div>
                        <div class="row">
                          <div class="col-6"></div>
                          <div class="col p-2 letraImpuesto borde-Subtotal" style="border-right: none;"><b>SUBTOTAL:</b>
                          </div>
                          <div class="col p-2 mpalabra borde-Subtotal" style="border-left: none;">
                            <b>{{factura.factura.subtotal | number: '0.2' }}</b>
                          </div>
                        </div>
                        <div class="row" *ngFor="let perc of factura.factura.percepciones">
                          <div class="col-6"></div>
                          <div class="col p-2 letraImpuesto borde-Percepciones" style="border-right: none;"
                            *ngIf="this.clienteDiscriminaImpuestos(factura)">{{ perc.leyenda}} {{obtenerPorcentaje(factura.factura, perc.leyenda) | number: '2.2-2'}}:</div>
                          <div class="col p-2 mpalabra borde-Percepciones" style="border-left: none;"
                            *ngIf="this.clienteDiscriminaImpuestos(factura)">{{perc.valor | number: '0.2' }}</div>
                        </div>
                        <br>
                        <div class="row">
                          <div class="col-6"></div>
                          <div class="col p-2 letraImpuesto"
                            style="border: solid; border-width: 1px; border-right: none;">
                            <b>TOTAL:</b>
                          </div>
                          <div class="col p-2 mpalabra" style="border: solid; border-width: 1px; border-left: none;">
                            <b>{{factura.tipoMoneda | tipoMoneda }} &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                              &nbsp; &nbsp;
                              &nbsp; &nbsp; &nbsp; &nbsp;
                              {{factura.factura.total | number: '0.2' }}</b>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-6 letra">
                            SON {{obtenerDescripcionMoneda(factura.tipoMoneda)}}&nbsp;{{factura.totalEnTexto}}
                          </div>
                        </div>
                        <br>
                        <br>
                      </div>
                    </div>
                  </div>
                  <div id="observacionesInfo" class="justify-content-md-start border"
                    *ngIf="factura!=null && factura.factura.tipoMovimiento!=9 && 
                    factura.observaciones != null && factura.observaciones.length > 0">
                    <div class="itemObservacion text-start ms-2 letra"
                      *ngIf="factura.observaciones != null && factura.observaciones.length > 0">
                      <b>Comentarios:</b><br />
                      {{ factura.observaciones}}
                    </div>
                  </div>
                  <div class="border p-2" *ngIf="factura.factura.leyendas!=null && factura.factura.leyendas.length >0">
                    <div class="letra" *ngFor="let leyenda of factura.factura.leyendas">
                      <div *ngIf="leyenda.frame == 1 && leyenda.activo">{{leyenda.descripcion}}</div>
                    </div>
                  </div>
                  <div id="fechasCae" class="border" *ngIf="this.factura.factura!=null && this.factura.factura.cae != null">
                    <div class="row">
                      <div class="col p-1 ms-4 letra text-start">C.A.E.: {{factura.factura.cae.cae}}
                      </div>
                      <div class="col p-1 me-4 letra text-end">Fecha Vto.:
                        {{factura.factura.cae.vencimientoCAE | date:"dd/MM/yyyy"}}
                      </div>
                    </div>
                  </div>
                  <div class="border text-center">
                    <div *ngIf="this.factura.factura!=null && this.factura.tipoMovimiento!=9 && this.factura.factura.cae != null" id="codigoBarras">
                      <div class="row" >
                        <div class="col-md-6">
                            <qrcode [elementType]="elementType"  [width]="200" [qrdata]="factura.factura.codigoQr" [level]="'S'"></qrcode>
                        </div>
                        <div class="col-md-6">
                          <ngx-barcode6 class="" [bc-value]="factura.factura.codigoBarras"
                            [bc-display-value]="factura.factura.codigoBarras" [bc-element-type]="'svg'" [bc-font-size]="10"
                            [bc-height]="30" [bc-width]="0.9"></ngx-barcode6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

    </form>
  </div>
</div>
