import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { switchMap, tap } from 'rxjs/operators';
import { CondicionVenta } from 'src/app/shared/enums/condicionVenta';
import { TipoActivoPrecio } from 'src/app/shared/enums/tipoActivoPrecio';
import { TipoDesactivacion } from 'src/app/shared/enums/tipoDesactivacion';
import { TipoEstadoPrecio } from 'src/app/shared/enums/tipoEstadoPrecio';
import { ExportExcelServiceService } from 'src/app/shared/exportar-excel/export-excel-service.service';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { EstadoPrecio } from 'src/app/shared/models/precios/EstadoPrecio';
import { PrecioInactivo } from 'src/app/shared/models/precios/PrecioInactivo';
import { PrecioLote } from 'src/app/shared/models/precios/PrecioLote';
import { MessageService } from 'src/app/shell/message.service';
import { PreciosService } from '../precios.service';

@Component({
  selector: 'app-historial-desactivacion',
  templateUrl: './historial-desactivacion.component.html',
  styleUrls: ['./historial-desactivacion.component.scss']
})
export class HistorialDesactivacionComponent implements OnInit {
  esperandoCarga: boolean = false;
  messagePrecios: string = "C a r g a n d o . . ."
  preciosFiltrados: PrecioInactivo[];
  listaPrecios: PrecioInactivo[];

  enumCondicionVenta = CondicionVenta;
  enumTipoDesactivacion = TipoDesactivacion;
  enumTipoActivoPrecio = TipoActivoPrecio;
  actualizando: boolean = false;

  modelDesde: NgbDateStruct;
  modelHasta: NgbDateStruct;
  fechaDesde: Date;
  fechaHasta: Date;

  searchForm = new UntypedFormGroup({
    busqueda: new UntypedFormControl(''),
  });

  constructor(
    private preciosService: PreciosService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private calendar: NgbCalendar, 
    private excelService: ExportExcelServiceService,
    private datePipe: DatePipe,
    private messageService: MessageService) {

  }

  // hooks

  ngOnInit() {
    this.modelDesde = this.calendar.getToday();
    this.fechaDesde = new Date(this.modelDesde.year, this.modelDesde.month - 1, this.modelDesde.day);
    this.modelHasta = this.calendar.getToday();
    this.fechaHasta = new Date(this.modelHasta.year, this.modelHasta.month - 1, this.modelHasta.day);

    this.getPrecios();
  }

  getPrecios() {

    this.initPreciosRequest();

    this.preciosService.getPreciosInactivos(this.stringFechaDesde(), this.stringFechaHasta())
      .subscribe(result => {

        result = this.procesarResult(result);

        for (let i = 0; result.length > i; i++) {
          this.preciosFiltrados.push(result[i]);
          this.listaPrecios.push(result[i]);
        }

        this.spinner.hide('spinnerGrafico');
        this.esperandoCarga = false;
        this.filtrarPrecios();
      },
        (err: ErrorModel) => {
          this.listaPrecios = [];
          this.preciosFiltrados = [];
          this.esperandoCarga = false;
      });
  }

  stringFechaDesde(){
    this.fechaDesde = new Date(this.modelDesde.year, this.modelDesde.month - 1, this.modelDesde.day);    
    let desde: string = this.formatDate(this.fechaDesde.getUTCFullYear(), this.fechaDesde.getMonth() + 1, this.fechaDesde.getDate(), 0, 0);    

    return desde;
  }

  stringFechaHasta(){
    this.fechaHasta = new Date(this.modelHasta.year, this.modelHasta.month - 1, this.modelHasta.day);
    let hasta: string = this.formatDate(this.fechaHasta.getUTCFullYear(), this.fechaHasta.getMonth() + 1, this.fechaHasta.getDate(), 23, 59);

    return hasta;
  }

  formatDate(year, month, day, hour, minute): string {
    return year + '/' + month.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + '/' + day.toLocaleString(undefined, { minimumIntegerDigits: 2 })
      + 'T' + hour.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + minute.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':00';
  }

  onRefreshClick() {
    this.getPrecios();
  }

  initPreciosRequest() {
    this.listaPrecios = [];
    this.preciosFiltrados = [];

    this.esperandoCarga = true;
    this.spinner.show('spinnerGrafico');
  }

  procesarResult(result: PrecioInactivo[]) {
    result.forEach(p => {
      if(p.solicitoDesactivarUserName && p.solicitoDesactivarUserName.indexOf(',') > -1) p.solicitoDesactivarUserName = JSON.parse(p.solicitoDesactivarUserName.replace(/'/g,'"')).nombresCompletos;

      if(p.desactivoUserName && p.desactivoUserName.indexOf(',') > -1) p.desactivoUserName = JSON.parse(p.desactivoUserName.replace(/'/g,'"')).nombresCompletos;
    });

    //return result.filter(p => p.estadoPrecio.estadoPrecio == TipoEstadoPrecio.Aprobado && p.estadoPrecio.activo == TipoActivoPrecio.PendienteDesactivacion);
    return result;
  }

  filtrarPrecios() {

    if (this.preciosFiltrados != null) {
      this.preciosFiltrados = this.listaPrecios.filter(a => {
        let agregar = true;
        if (this.busquedas.value) {

          if (a.cliente != null) {
            agregar = a.cliente.razonSocial && a.cliente.razonSocial.toLocaleLowerCase().indexOf(this.busquedas.value.toLocaleLowerCase()) > -1;
          } else {
            agregar = false;
          }

          agregar = agregar || (a.producto.nombreProducto && a.producto.nombreProducto.toLocaleLowerCase().indexOf(this.busquedas.value.toLocaleLowerCase()) > -1);
          agregar = agregar || (a.producto.codigoProducto && a.producto.codigoProducto.toLocaleLowerCase().indexOf(this.busquedas.value.toLocaleLowerCase()) > -1);
          agregar = agregar || (a.precioContado.toString() && a.precioContado.toString().toLocaleLowerCase().indexOf(this.busquedas.value.toLocaleLowerCase()) > -1);
          agregar = agregar || (a.numeroLote && ("l" + a.numeroLote).toString().toLocaleLowerCase().indexOf(this.busquedas.value.toLocaleLowerCase()) > -1);

        }

        if (this.busquedas.value == '') {
          this.preciosFiltrados = this.listaPrecios;
        }
        return agregar;
      });
    }
  }

  fechaDesdeMayorQueHasta() {
    let esMayor: boolean = false;
    this.fechaDesde = new Date(this.modelDesde.year, this.modelDesde.month - 1, this.modelDesde.day);
    this.fechaHasta = new Date(this.modelHasta.year, this.modelHasta.month - 1, this.modelHasta.day);
    if (this.fechaDesde > this.fechaHasta) { esMayor = true; }
    return esMayor;
  }

  // getters
  get busquedas() {
    return this.searchForm.get('busqueda');
  }

  exportarReporte() {
    this.excelService.exportAsExcelFile(this.armarArrayReporteDesactivacion(), 'Reporte desactivacion precios');
  }

  armarArrayReporteDesactivacion(): ReporteDesactivacion[] {    
    var datos: ReporteDesactivacion[] = [];

    for(var p of this.preciosFiltrados){
      var r = new ReporteDesactivacion();
      r.nivel = p.nivelPrecio;
      r.lote = p.numeroLote;
      r.codigoProducto = p.producto.codigoProducto;
      r.nombreProducto = p.producto.nombreProducto;
      r.desde = this.datePipe.transform(p.fechaDesde, 'dd-MM-yyyy HH:mm:ss');
      r.hasta =  this.datePipe.transform(p.fechaHasta, 'dd-MM-yyyy HH:mm:ss');
      r.moneda = p.moneda!=null ? p.moneda.nombre : "-";
      r.cliente = p.cliente!=null ? p.cliente.razonSocial : "-";
      r.tipoVenta = p.tipoVenta == 0 ? "-" : p.tipoVenta == 1 ? "Cabotaje" : "Internacional";
      r.precio = p.precioContado;
      r.entrega = p.direccionEntrega != null ? p.direccionEntrega.toString() : "-";
      r.area = p.areaNegocio != null ? p.areaNegocio.nombreArea : "-" ;
      r.segmento = p.segmento != null ? p.segmento.nombreSegmento : "-" ;
      r.aeroplanta = p.codigoAeroplanta;
      r.fechaSolicito = this.datePipe.transform(p.solicitoDesactivarFecha, 'dd-MM-yyyy HH:mm:ss');
      r.usuarioSolicito = p.solicitoDesactivarUserName;
      r.fechaDesactivo = this.datePipe.transform(p.desactivoFecha, 'dd-MM-yyyy HH:mm:ss');
      r.usuarioDesactivo = p.desactivoUserName;

      datos.push(r);
    }

    return datos;
  }

  print(): void {
    var divToPrint = document.getElementById('tablaDetalle');
    var htmlToPrint = '' +
      '<style type="text/css">' +
      'table td, table th  {' +      
      'text-align: center;' +
      'border:1px solid #000;' +
      'padding:0.5em;' +
      '}' +
      'table  {' +     
      'border-collapse: collapse;' +
      'font-size: 10pt;' +
      '}' +
      'button{' +
      'display: none;' +
      '}' +
      'label  {' +
      'margin-top: 0.5cm;' +
      'display: inline-block;' +
      '}' +
      '#noPrint {' +
      'display: none;' +
      '}' +
      '#tituloPrint {' +
      'display: block;' +
      'font-size: 14pt;' +
      'margin-bottom: 1.5em;' +
      '}'+
      '</style>';
    htmlToPrint += divToPrint.outerHTML;
    let newWin = window.open("");
    newWin.document.write(htmlToPrint);
    newWin.print();
    newWin.close();
  }
}

export class ReporteDesactivacion {
  nivel: number;
  lote: string;
  codigoProducto: string;
  nombreProducto: string;
  desde: string;
  hasta: string;
  moneda: string;
  cliente: string;
  tipoVenta: string;
  precio: number;
  entrega: string;
  area: string;
  segmento: string;
  aeroplanta: string;
  fechaSolicito: string;
  usuarioSolicito: string;
  fechaDesactivo: string;
  usuarioDesactivo: string;
}
