<ul class="nav nav-tabs justify-content-start">
  <li  class="nav-item" *ngIf="hasRole(TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_LN, TipoUsuario.COMERCIAL_AV, TipoUsuario.APROBADORBYR)"><a
    class="nav-link" routerLink="solicitudRefacturacion" routerLinkActive="active"><b>Refacturacion manual</b></a>
</li>


<li  class="nav-item" *ngIf="hasRole(TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_LN, TipoUsuario.COMERCIAL_AV, TipoUsuario.APROBADORBYR)"><a
    class="nav-link" routerLink="correcionPreciosRefacturacion" routerLinkActive="active"><b>Refacturacion por corrección
    de precios</b></a>
</li>
<li
  class="nav-item" *ngIf="hasRole(TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO)"><a class="nav-link" routerLink="despachoRefacturacion" routerLinkActive="active"><b>Refacturacion por Correción de despachos</b></a>
</li>
<li  class="nav-item" *ngIf="hasRole(TipoUsuario.FACTURACION)"><a
  class="nav-link" routerLink="revisionRefacturacion" routerLinkActive="active"><b>Revisión de Refacturacion</b></a>
</li>
<li  class="nav-item" *ngIf="hasRole(TipoUsuario.REFACTURACION)"><a
  class="nav-link" routerLink="aprobacionRefacturacion" routerLinkActive="active"><b>Aprobacion de Refacturacion</b></a>
</li>

<li  class="nav-item" *ngIf="hasRole(TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_LN, TipoUsuario.COMERCIAL_AV, TipoUsuario.APROBADORBYR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.TUTOR, TipoUsuario.FACTURACION, TipoUsuario.REFACTURACION, TipoUsuario.CONSPRECIOS, TipoUsuario.SUPERVISOR, TipoUsuario.SEGURIDADTABLAS)"><a
  class="nav-link" routerLink="generacionRefacturacion" routerLinkActive="active"><b>Historial de Refacturacion</b></a>
</li>

<li  class="nav-item" *ngIf="hasRole(TipoUsuario.SEGURIDADTABLAS)"><a
  class="nav-link" routerLink="nivelesRefacturacion" routerLinkActive="active"><b>Niveles de Refacturación</b></a>
</li>


</ul>
<router-outlet></router-outlet>
