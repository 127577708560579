<nav class="navbar navbar-light bg-light navbar-expand-lg">
  <div class="collapse navbar-collapse">
    <button [disabled]="bloquear" class="navbar-toggler" type="button" data-toggle="collapse"
      data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
      aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <ul class="navbar-nav me-auto">
      <!--buscador -->
      <li class="nav-item mr-2">
        <div class="nav-item">
          <input [disabled]="bloquear" id="busquedaFormControl" type="text" class="form-control"
            [formControl]="busquedaFormControl" placeholder="Buscador" />
        </div>
      </li>

      <li class="nav-item">
        <button [disabled]="bloquear" (click)="buscarSaldos()" class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2"><i
            class="fa fa-search" aria-hidden="true"></i>
        </button>
      </li>

      <!--<li class="nav-item">
        <select id="estadoCuentaFormControl" (change)="filtrarEstado()" [formControl]="estadoCuentaFormControl"
          class="form-control mr-2" placeholder="Estado">
          <option value="0">Todo</option>
          <option value="1">Habilitadas</option>
          <option value="2">Excedidas</option>
          <option value="3">Bloqueadas</option>
        </select>
      </li>-->

      <li class="nav-item">
        <div ngbDropdown autoClose="outside">
          <button [disabled]="bloquear" type="button" class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2"
            id="dropdownAreas" ngbDropdownToggle>Estado</button>
          <div ngbDropdownMenu aria-labelledby="dropdownAreas">
            <div class="px-4 py-3">
              <div *ngFor="let estado of estadosFiltros; let i = index;">
                <div class="form-check">
                  <input type="checkbox" name="Estados+{{i}}" class="form-check-input"
                    id="{{ 'dropdownCheck' + estado.descripcion}}" [(ngModel)]="estado.seleccionado">
                  <label class="form-check-label text-nowrap" for="{{ 'dropdownCheck' + estado.descripcion}}">
                    {{ estado.descripcion }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>

      <li class="nav-item">
        <div ngbDropdown autoClose="outside">
          <button [disabled]="bloquear" type="button" class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2"
            id="dropdownAreas" ngbDropdownToggle>Área</button>
          <div ngbDropdownMenu aria-labelledby="dropdownAreas">
            <div class="px-4 py-3">
              <div *ngFor="let area of areaFiltros; let i = index;">
                <div class="form-check">
                  <input type="checkbox" name="areas+{{i}}" class="form-check-input"
                    id="{{ 'dropdownCheck' + area.descripcion}}" [(ngModel)]="area.seleccionado">
                  <label class="form-check-label text-nowrap" for="{{ 'dropdownCheck' + area.descripcion}}">
                    {{ area.descripcion }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>

      <li class="nav-item">
        <div ngbDropdown autoClose="outside">
          <button [disabled]="bloquear" type="button" class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2"
            id="dropdownAreas" ngbDropdownToggle>Segmento</button>
          <div ngbDropdownMenu aria-labelledby="dropdownAreas">
            <div class="px-4 py-3">
              <div *ngFor="let segmento of segmentosFiltros; let i = index;">
                <div class="form-check">
                  <input type="checkbox" name="segmentos+{{i}}" class="form-check-input"
                    id="{{ 'dropdownCheck' + segmento.descripcion}}" [(ngModel)]="segmento.seleccionado">
                  <label class="form-check-label text-nowrap" for="{{ 'dropdownCheck' + segmento.descripcion}}">
                    {{ segmento.descripcion }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>

      <li class="nav-item">
        <div ngbDropdown autoClose="outside">
          <button [disabled]="bloquear" type="button" class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2"
            id="dropdownAreas" ngbDropdownToggle>Comerciales</button>
          <div ngbDropdownMenu aria-labelledby="dropdownAreas">
            <div class="px-4 py-3">
              <div *ngFor="let comercial of comercialesFiltros; let i = index;">
                <div class="form-check">
                  <input type="checkbox" name="comerciales+{{i}}" class="form-check-input"
                    id="{{ 'dropdownCheck' + comercial.id}}" [(ngModel)]="comercial.seleccionado">
                  <label class="form-check-label text-nowrap" for="{{ 'dropdownCheck' + comercial.id}}">
                    {{ comercial.nombresCompletos }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>

      <li class="nav-item">
        <button [disabled]="bloquear" class="btn btn-primary my-2 my-lg-0 mx-lg-2" (click)="aplicarFiltros()">Aplicar filtros</button>
      </li>
    </ul>
    <div class="float-end">
      <button [disabled]="bloquear" class="btn btn-outline-success" (click)="descargarExcel(saldosFiltrados)">
        <span *ngIf="!descargandoClientes">Descargar</span>
        <div *ngIf="descargandoClientes" class="d-flex align-items-center">
          <div class="spinner-border text-dark spinner-border-sm" role="status"></div>
        </div>
      </button>
    </div>
  </div>
</nav>

<div class="col container-fluid">
  <div class="spinner-container" *ngIf="this.ocultarTabla">
    <ngx-spinner name="spinnerLista" bdOpacity=0.3bd
      Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
      <p style="color: white"> C a r g a n d o . . . </p>
    </ngx-spinner>
  </div>

  <table class="table table-responsive table-striped">
    <thead *ngIf="!this.ocultarTabla">
      <tr style="z-index: 50">
        <th scope="col">
          <p class="text-center text-primary text-nowrap">
            Cliente
          </p>
        </th>

        <th scope="col">
          <p class="text-center text-primary text-nowrap">
            Limite SERC
          </p>
        </th>

        <th scope="col">
          <p class="text-center text-primary text-nowrap">
            Saldo SAP
          </p>
        </th>

        <th scope="col">
          <p class="text-center text-primary text-nowrap">
            Pendiente PAD
          </p>
        </th>

        <th scope="col">
          <p class="text-center text-primary text-nowrap">
            Total Deuda
          </p>
        </th>

        <th scope="col">
          <p class="text-center text-primary text-nowrap">
            Estado de Cuenta
          </p>
        </th>

        <th scope="col">
          <p class="ml-3 text-center text-primary text-nowrap">
            Notificaciones
          </p>
        </th>

        <th scope="col" class="columnaBloquear"> </th>
        <th scope="col">
          <p class="text-center text-primary text-nowrap ml-1">
            Actualizar
          </p>
        </th>

      </tr>
    </thead>

    <tbody id="scroll-infinito" infiniteScroll [infiniteScrollDistance]="distance" [infiniteScrollThrottle]="throttle"
      [scrollWindow]="false" [infiniteScrollDisabled]="this.obteniendoSaldosScroll || this.stopScroll" (scrolled)="onScrollSaldos()">
      <ng-container *ngIf="!this.ocultarTabla">
        <tr *ngFor="let cliente of saldosFiltrados; let i = index">
          <td scope="row">
            {{cliente.clienteRazonSocial}} <button class="btn btn-sm" *ngIf="clickeado !== cliente.clienteCuit"
              (click)="mostrarDatos(cliente.clienteCuit)"><i class="fa fa-eye"></i></button>
            <button class="btn btn-sm" *ngIf="clickeado === cliente.clienteCuit" (click)="mostrarDatos(' ')"><i
                class="fa fa-eye-slash"></i></button><br />
            <div *ngIf="clickeado === cliente.clienteCuit">
              <small><b>{{tipoDocumento[cliente.tipoDocumento]}}</b> {{cliente.clienteCuit}}<br />
                <b>Area: </b>{{cliente.area}} <br>
                <b>Segmento: </b>{{cliente.subArea}} <br>
                <b>Comercial: </b>{{cliente.comerciales}}
              </small>
            </div>
          </td>
          
          <td>
            <div class="text-center"> ${{cliente.limite | number: '0.2-2'}}<br>
              <small>Últ. act.: {{cliente.fechaHoraLecturaLimite |
                date:'short'}}</small>
            </div>
          </td>

          <td>
            <div class="text-center"> ${{cliente.saldo | number: '0.2-2'}} <br>
              <small>Últ. act.: {{cliente.fechaHoraLecturaSaldo | date:'short'}}</small>
            </div>
          </td>
          <td class="text-center">${{cliente.saldoPAD | number: '0.2-2'}} <br>
            <button *ngIf="cliente.saldoPAD > 0" [disabled]="bloquear" ngbTooltip="Ver aerovales" class="btn btn-outline-secondary btn-sm"
              (click)="verAerovales(cliente, i)">
              <i *ngIf="!cargandoPad || seleccionado != i" class="fa small fa-search" aria-hidden="true"></i>
              <div *ngIf="cargandoPad && seleccionado == i" class="d-flex align-items-center">
                <div class="spinner-border text-dark spinner-border-sm" role="status"></div>
              </div>
            </button>
          </td>

          <td class="text-center">${{cliente.saldo + cliente.saldoPAD | number: '0.2-2'}} <br>
          </td>

          <td>
            <div class="text-center" *ngIf="cliente?.ultimoEstado.estadoCliente == '0' && !cliente?.Excedido">
              ${{cliente.limite - cliente.saldo - cliente.saldoPAD | number: '0.2-2'}} <br>
              <small class="text-success">Habilitada</small><br>
              <small *ngIf="cliente.excedido">Excedida</small>
            </div>
            <div class="text-center" *ngIf="cliente?.ultimoEstado.estadoCliente == '1'">
              ${{cliente.limite - cliente.saldo - cliente.saldoPAD | number: '0.2-2'}} <br>
              <small class="text-danger"> Bloqueada</small><br>
              <small *ngIf="cliente.excedido">Excedida</small>
            </div>
          </td>

          <td *ngIf="cliente.notificacionSaldo" class="text-center text-nowrap mails">
            <div class="mr-3">
              <i class="fa large fa-usd"></i>
              <input
                onkeypress="return (event.charCode != 8 && event.charCode == 0 || (event.charCode >= 48 && event.charCode <= 57))"
                type="number"
                class="form-control d-inline mb-2 ml-3 form-control-sm" [(ngModel)]="cliente.monto">
              <button *ngIf="authService.hasRole(TipoUsuario.COMERCIAL)" (click)="actualizarMonto(cliente, i)" class="btn btn-sm"  [class.vacio]="!cliente.monto">
                <i *ngIf="!actualizandoMonto" class="fa fa-save"></i>
                <div *ngIf="actualizandoMonto && seleccionado == i" class="d-flex align-items-center">
                  <div class="spinner-border text-dark spinner-border-sm" role="status"></div>
                </div>
              </button>
            </div>
            <div class="row ml-2 justify-content-center"><button [disabled]="bloquear"
                class="btn btn-outline-primary btn-sm text-nowrap d-block" (click)="agregarMailNotificacion(cliente)">
                <i class="text-primary mx-2 fa fa-envelope"></i>
                <small>Mails de notificacion</small>
              </button>
            </div>
          </td>
          
          <td *ngIf="!cliente.notificacionSaldo" class="text-center text-nowrap">
            <button *ngIf="authService.hasRole(TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_LN, TipoUsuario.COMERCIAL_AV, TipoUsuario.APROBADORBYR)" [disabled]="bloquear" class="btn btn-success" (click)="agregarNotificacion(cliente)">Crear
              notificación</button>
          </td>

          <td class="text-center columnaBloquear" >
            <span style="margin-left: 1.5em" id='Historial'><button  id="botonBloquear" [disabled]="bloquear"
                *ngIf="cliente?.ultimoEstado.estadoCliente != EstadoCliente.Bloqueado && authService.hasRole(TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_LN, TipoUsuario.COMERCIAL_AV, TipoUsuario.APROBADORBYR)"
                class="btn btn-outline-danger ml-2 " (click)="cambiarEstado(cliente)">
                Bloquear
              </button>
              <button [disabled]="bloquear" *ngIf="cliente?.ultimoEstado.estadoCliente == EstadoCliente.Bloqueado && authService.hasRole(TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_LN, TipoUsuario.COMERCIAL_AV, TipoUsuario.APROBADORBYR)"
                class="btn btn-outline-success" (click)="cambiarEstado(cliente)">Desbloquear
              </button> &nbsp;
              <button *ngIf="authService.hasRole(TipoUsuario.COMERCIAL)" [disabled]="bloquear" id="historial" class="text-center" ngbTooltip="Ver historial"
                class="btn btn-outline-secondary btn-sm" (click)="verHistorial(cliente, i)">
                <i *ngIf="!cargandoHistorial || seleccionado != i" class="fa small fa-search" aria-hidden="true"></i>
                <div *ngIf="cargandoHistorial && seleccionado == i" class="d-flex align-items-center">
                  <div class="spinner-border text-dark spinner-border-sm" role="status"></div>
                </div>
              </button>
            </span>
          </td>

          <td class="text-center"><button [disabled]="bloquear" (click)="actualizarSaldo(cliente, i)"
              ngbTooltip="Actualizar saldos" class="btn-sm btn btn-outline-primary text-nowrap">
              <i *ngIf="!actualiza || seleccionado != i" class="fa fa-refresh small"></i>
              <div *ngIf="actualiza && seleccionado == i" class="d-flex align-items-center">
                <div class="spinner-border text-dark spinner-border-sm" role="status"></div>
              </div>
            </button>
          </td>
        </tr>
      </ng-container>
      <div style="justify-content: center;" *ngIf="this.obteniendoSaldosScroll"
        class="d-flex align-items-center text-primary">
        <div class="spinner-border" role="status"></div><span>Cargando mas datos..</span>
      </div>
    </tbody>
  </table>
</div>