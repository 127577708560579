import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UsuarioService } from 'src/app/account/usuario.service';
import { RefacturacionFactura } from 'src/app/shared/models/facturacion/RefacturacionFactura';
import { TuplaRefacturacion } from 'src/app/shared/models/facturacion/TuplaRefacturacion';
import { MessageService } from 'src/app/shell/message.service';
import { environment } from 'src/environments/environment';
import { FacturacionService } from '../../facturacion.service';

@Component({
  selector: 'app-correccion-despacho',
  templateUrl: './correccion-despacho.component.html',
  styleUrls: ['./correccion-despacho.component.css']
})
export class CorreccionDespachoComponent implements OnInit {

  @Input() refacturacion: RefacturacionFactura;

  facturacionBaseUrl = environment.apiServer.facturacionBaseUrl;

  guardando: boolean = false;
  archivoSubido : string;
  error: string;
  // forms
  form: UntypedFormGroup;
  checkMultiplesMotivos: UntypedFormGroup;

  //lista de selección
  listaDeMotivosSeleccionados: any[]=[];

  plazo = { nombre: "Plazo", codigo: 0 };
  erroresImpositivos = { nombre: "Errores Impositivos", codigo: 1 };
  otros={ nombre: "Otros", codigo: 2 };
  motivosParaCheckear = [this.plazo, this.erroresImpositivos,this.otros];

   constructor(
    private formBuilder: UntypedFormBuilder,
    public activeModal: NgbActiveModal,
    private changeDetector: ChangeDetectorRef,
    private facturacionService: FacturacionService,
    private fb: UntypedFormBuilder,
    private usuarioService:UsuarioService,
    private messageService:MessageService) {
   }

  // hooks

  ngOnInit() {
    console.log("url "+this.facturacionBaseUrl);
    console.log("la refacturacion que llega es  "+JSON.stringify(this.refacturacion))
    this.crearFormulario();
    this.inicializarComponente();
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  // form

  crearFormulario(){
    this.form = this.formBuilder.group({
      comentariosFormControl: ['', Validators.required],
    });

  }



  inicializarComponente(){
    this.checkMultiplesMotivos = this.formBuilder.group({
      nombreCondicion: this.fb.array([])
    });
    this.guardando = false;
  }




 // llamadas al service

  onSubmit() {

    if (this.form.valid)
    {
    this.guardando=true;

    let refacturacion=new RefacturacionFactura();
    //refacturacion.motivo=this.refacturacion.motivo;
    refacturacion.detalle=this.comentariosFormControl.value;
    //refacturacion.plazo=this.refacturacion.plazo;
    refacturacion.estado=0;
    refacturacion.aerovaleId=this.refacturacion.aerovaleId;
    refacturacion.responsable=this.obtenerStringUsuarioSolicitador();
    refacturacion.usuarioSolicitador=this.obtenerStringUsuarioSolicitador();






    this.facturacionService.emitirRefacturacionPorDespacho(refacturacion)
    .subscribe(result => {
      this.messageService.showSuccessMessage("Se ha realizado correctamente la refacturacion de la factura ");
      this.guardando=false;
      this.ngOnInit();
      this.activeModal.close();
   }, () => {
    this.messageService.showErrorMessage("Hubo un error al realizar la refacturacion");
    this.guardando=false;
    this.ngOnInit();

   });

  }

  }



  // validaciones

   puedeSolicitarRefactura(): boolean
   {
   return this.form.valid;
   }



  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46;
  }

  // getters

  // obtenerPrefijoNumeroDeFactura(){
  //   return "{'Prefijo': '"+this.factura.prefijo+"' ," + "'Número': '"+this.factura.numeroComprobante+"'}";
  // }

  obtenerStringUsuarioSolicitador(){
    return this.facturacionService.obtenerStringSolicitador();
  }

  get comentariosFormControl(){
    return this.form.controls.comentariosFormControl;
  }

}
