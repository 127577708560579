import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbCalendar, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ComprobanteService } from 'src/app/facturacion/comprobante.service';
import { ExportExcelServiceService } from 'src/app/shared/exportar-excel/export-excel-service.service';
import { ModalConfirmComponent } from 'src/app/shared/generic-modals/modal-confirm.component';
import { ModalOkComponent } from 'src/app/shared/generic-modals/modal-ok.component';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { ComprobanteEncabezado } from 'src/app/shared/models/facturacion/ComprobanteEncabezado';
import { ModificacionDto } from 'src/app/shared/models/precios/ModificacionDto';
import { FacturaNumeroPipe } from 'src/app/shared/pipes/factura-numero.pipe';
import { FacturaPrefijoPipe } from 'src/app/shared/pipes/factura-prefijo.pipe';
import { NombreTipoComprobantePipe } from 'src/app/shared/pipes/nombre-tipo-comprobante.pipe';
import { TipoMovimientoPipe } from 'src/app/shared/pipes/tipo-movimiento.pipe';
import { VisorComprobanteComponent } from 'src/app/shared/visor-comprobante/visor-comprobante.component';
import { MessageService } from 'src/app/shell/message.service';

@Component({
  selector: 'app-precio-factura',
  templateUrl: './precio-factura.component.html',
  styleUrls: ['./precio-factura.component.css']
})
export class PrecioFacturaComponent implements OnInit {
  @Input() precioBuscado: ModificacionDto;
  obteniendoFacturas: boolean = false;
  previsualizando: boolean = false;
  exportando: boolean = false;
  encabezadoFactura: ComprobanteEncabezado[] = [];
  exportarLista: ListaPreciosExcel[] = [];
  constructor(private comprobanteService: ComprobanteService, private messageService: MessageService, public modal: NgbModal, private spinner: NgxSpinnerService,
    public activeModal: NgbActiveModal, private excelService: ExportExcelServiceService,
    private facturaPrefijoPipe: FacturaPrefijoPipe, 
    private facturaNumeroPipe: FacturaNumeroPipe,
    private tipoMovimientoPipe: TipoMovimientoPipe,
    private nombreTipoComprobantePipe: NombreTipoComprobantePipe,
    ) { }

  ngOnInit() {
    this.obtenerFacturas();
  }

  obtenerFacturas() {
    this.obteniendoFacturas = true;
    this.spinner.show('spinnerGrafico');
    this.comprobanteService.getFacturasSegunPrecio(this.precioBuscado.precio.id)
      .subscribe(result => {
        this.encabezadoFactura = result;
        this.spinner.hide('spinnerGrafico');
        this.obteniendoFacturas = false;
      }, (err: ErrorModel) => {
        this.messageService.showErrorMessage('Hubo un problema al obtener las facturas relacionadas al precio. ' + err.message);
        this.spinner.hide('spinnerGrafico');
        this.obteniendoFacturas = false;
      })
  }

  verFactura(factura: ComprobanteEncabezado) {
    this.previsualizando = true;
    factura.loadIcon = true;
    this.comprobanteService.obtenerComprobantePorId(factura.id)
      .subscribe(result => {
        factura.loadIcon = false;
        this.previsualizando = false;
        let modalRef = this.modal.open(VisorComprobanteComponent, { centered: true, size: 'lg' });
        modalRef.componentInstance.facturaObtenida = result;
      }, (err: ErrorModel) => {
        factura.loadIcon = false;
        this.previsualizando = false;
        this.messageService.showErrorMessage('Hubo un error al obtener el comprobante para mostrar. ' + err.message);
      })
  }

  formatDate(year, month, day, hour, minute): string {
    return year + '-' + month.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + '-' + day.toLocaleString(undefined, { minimumIntegerDigits: 2 })
      + 'T' + hour.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + minute.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':00';
  }

  exportarExcel() {
    let modalRef = this.modal.open(ModalOkComponent, { centered: true, backdrop: 'static' });
    modalRef.componentInstance.mensaje = 'La cantidad de facturas afectadas por el cambio de precio pueden variar a futuro.';
    modalRef.componentInstance.titulo = 'Facturas afectadas por el cambio de precio';
    modalRef.result.then(() => {

      let day = new Date();
      this.exportando = true;
      this.exportarLista = [];
      for (let i = 0; this.encabezadoFactura.length > i; i++) {
        let arreglo: ListaPreciosExcel = new ListaPreciosExcel();
        arreglo.NumeroFactura = this.facturaPrefijoPipe.transform(this.encabezadoFactura[i].prefijo) + '-' + this.facturaNumeroPipe.transform(this.encabezadoFactura[i].numeroComprobante);
        arreglo.Cliente = this.encabezadoFactura[i].razonSocialCliente;
        arreglo.FechaEmision = this.encabezadoFactura[i].fechaEmision;
        arreglo.TipoMovimiento = this.nombreTipoComprobantePipe.transform(this.encabezadoFactura[i].tipoMovimiento) + ' ' + this.tipoMovimientoPipe.transform(this.encabezadoFactura[i].tipoMovimiento);
        arreglo.Aeroplanta = this.encabezadoFactura[i].codigoAeroplanta;
        this.exportarLista.push(arreglo);
      }
      this.excelService.exportAsExcelFile(this.exportarLista, 'Lote: ' + this.precioBuscado.precio.numeroLote + ' Aeroplanta: ' + this.precioBuscado.precio.codigoAeroplanta + ' Fecha: ' + this.formatDate(day.getUTCFullYear(), day.getMonth() + 1, day.getDate(), day.getHours(), day.getMinutes()));
      this.exportando = false;
    }
    )
  }
}


export class ListaPreciosExcel {
  NumeroFactura: string;
  Cliente: string;
  FechaEmision: string;
  TipoMovimiento: string;
  Aeroplanta: string;
}
