import { Component, forwardRef, OnDestroy, Output, EventEmitter, Input, ChangeDetectorRef } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  UntypedFormControl,
  NG_VALIDATORS
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ModalConfirmComponent } from '../../../shared/generic-modals/modal-confirm.component';
import { NgbModal, NgbDateStruct, NgbDatepickerConfig, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { UsuarioService } from 'src/app/account/usuario.service';
import { MessageService } from 'src/app/shell/message.service';
import { isNullOrUndefined } from 'src/app/shared/compat-tools';
import { NuevoClienteComponent } from '../../../shared/nuevo-cliente/nuevo-cliente.component';
import { ClienteService } from 'src/app/cliente/cliente.service';
import { PedidoService } from '../../pedido.service';
import { AerovalesService } from '../../aerovales.service';
import { Cliente } from 'src/app/shared/models/despacho/Cliente';
import { Producto } from 'src/app/shared/models/despacho/Producto';
import { Abastecedora } from 'src/app/shared/models/despacho/Abastecedora';
import { TipoVehiculo } from 'src/app/shared/enums/tipoVehiculo';
import { TipoAerovale } from 'src/app/shared/enums/tipoAerovale';
import { TipoPago } from 'src/app/shared/enums/tipoPago';
import { Observacion } from 'src/app/shared/models/despacho/Observacion';
import { TipoProducto } from 'src/app/shared/enums/tipoProducto';
import { Operador } from 'src/app/shared/models/despacho/Operador';
import { SurtidorService } from 'src/app/stock/surtidor.service';
import { Surtidor } from 'src/app/shared/models/stock/Surtidor';
import { Tanque } from 'src/app/shared/models/stock/Tanque';
import { CuentaCliente } from 'src/app/shared/models/despacho/CuentaCliente';
import { Pico } from 'src/app/shared/models/despacho/Pico';
import { TipoExpendedora } from 'src/app/shared/enums/tipoExpendedora';
import { TipoCuenta } from 'src/app/shared/enums/tipoCuenta';
import { ModalVehiculoComponent } from 'src/app/management/matriculas/abm-matriculas/modal-vehiculo/modal-vehiculo.component';
import { VehiculoService } from 'src/app/cliente/vehiculo.service';
import { VehiculoDTO } from 'src/app/shared/models/cliente/VehiculoDTO';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
export interface AerovaleCommonFormValues {
  clienteFormControl: Cliente;
  operadorFormControl: Operador;
  vehiculoFormControl: VehiculoDTO;
  numeroAerovaleFormControl: string;
  productoFormControl: Producto;
  abastecedoraFormControl: Abastecedora;
  fechaLlegadaFormControl: string;
  horaLlegadaFormControl: string;
  comentariosFormControl: string;
  tipoPagoFormControl: TipoPago;
  observacionesFormControl: Observacion;
  tanquesAsociadosFormControl: Tanque;
  picosAsociadosFormControl: Pico;
}

@Component({
  selector: 'aerovale-common-form',
  templateUrl: './aerovale-common.component.html',
  styleUrls: ['./aerovale-common.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AerovaleCommonComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AerovaleCommonComponent),
      multi: true
    }
  ]
})
export class AerovaleCommonComponent implements ControlValueAccessor, OnDestroy {

  @Input() tipoAerovale : TipoAerovale;
  @Output() formularioValido = new EventEmitter();
  @Output() objetCommonForm = new EventEmitter();

  enumTipoAerovale = TipoAerovale;

  form: UntypedFormGroup;
  subscriptions: Subscription[] = [];

  combustibles: Producto[];
  cargandoCombustibles: boolean;

  clientes: CuentaCliente[];
  cargandoClientes: boolean;
  errorClientes: boolean;

  operadores: Operador[];
  cargandoOperadores: boolean;
  errorOperadores: boolean;

  todosLosVehiculos: VehiculoDTO[];
  vehiculosDeCliente: VehiculoDTO[];
  cargandoAeronaves: boolean = true;
  cargandoAutomotores: boolean = true;
  cargandoVehiculosDeCliente: boolean;
  errorVehiculos: boolean;
  errorVehiculosDeCliente: boolean;

  abastecedoras: Abastecedora[];
  cargandoAbastecedoras: boolean;

  modelHoraLlegada: any;
  modelFechaLlegada: NgbDateStruct;

  tipoPago = TipoPago;
  TipoAerovale = TipoAerovale;

  observaciones: Observacion[];

  modelOperador: any;

  surtidores: Surtidor;
  obteniendoTanquesSurtidor: boolean = false;
  combustibleNoCoincide: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private datepickerConfig: NgbDatepickerConfig,
    private calendar: NgbCalendar,
    private messageService: MessageService,
    private usuarioService: UsuarioService,
    private modalService: NgbModal,
    private pedidoService: PedidoService,
    private aerovaleService: AerovalesService,
    private clienteService: ClienteService,    
    private vehiculosService: VehiculoService,
    private surtidorService: SurtidorService,
    private changeDetector: ChangeDetectorRef) {
  }

  // hooks

  ngOnInit() {
    this.crearFormulario();
    this.inicializarComponente();
    this.getAbastecedoras();
    this.getClientes();
    //this.getOperadores();
    this.getOperadoresConRoles();
    this.getVehiculos();
    this.getCombustibles();
    this.getObservacionesDespacho();
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }


  // form

  crearFormulario(){
    this.form = this.formBuilder.group({
      clienteFormControl: ['', Validators.required],
      operadorFormControl: ['', Validators.required],
      vehiculoFormControl: ['', (this.tipoAerovale != TipoAerovale.Aeroespecialidad ? Validators.required : null) ],
      numeroAerovaleFormControl: ['', [Validators.required, this.nroAerovaleValidator]],
      productoFormControl: [''],
      abastecedoraFormControl: ['', (this.tipoAerovale != TipoAerovale.Aeroespecialidad ? Validators.required : null) ],
      fechaLlegadaFormControl: ['', Validators.required],
      horaLlegadaFormControl: ['', Validators.required],
      comentariosFormControl: [''],
      tipoPagoFormControl: ['', Validators.required],
      observacionesFormControl: [''],
      tanquesAsociadosFormControl: [''],
      picosAsociadosFormControl: ['']
    });

    this.form.setValidators(this.aerovaleCommonValidator.bind(this));

    this.objetCommonForm.emit(this.form);
  }


  seleccionarPicoDefecto() {
    this.abastecedoraFormControl.value.picos.forEach(function (value) {
      if (value.porDefecto) {
        this.picoAsociado.setValue(value);
        this.picoAsociado.updateValueAndValidity();
      }
    }, this);
  }

  aerovaleCommonValidator(group: UntypedFormGroup): any{
    if (group) {

      let fechaLlegada = group.get("fechaLlegadaFormControl").value;
      let horaLlegada = group.get("horaLlegadaFormControl").value;

      if(fechaLlegada && horaLlegada){
        var fecha = new Date(fechaLlegada.year, fechaLlegada.month-1,  fechaLlegada.day, horaLlegada.hour, horaLlegada.minute);
        var ahora = new Date();
        if(fecha > ahora){
          return { fechaLlegadaMayorAhora: true };
        }
      }

      let abastecedora = group.get("abastecedoraFormControl").value;

      if(abastecedora != null && abastecedora.tipoExpendedora == 1){
        let tanque = group.get("tanquesAsociadosFormControl").value;
        if(tanque == null){
          return { noSeleccionoTanque: true };
        }
      }

      if(this.tipoAerovale && this.tipoAerovale != TipoAerovale.Aeroespecialidad){
        let pico = group.get("picosAsociadosFormControl").value;
        if(pico == null){
          return { noSeleccionoPico: true };
        }
      }

      return null;
    }
  }

  nroAerovaleValidator(control: UntypedFormControl){
    if(control.value){
      let nroCompleto = control.value;
      nroCompleto = nroCompleto.padStart(8, '0');
      let prefijo = nroCompleto.substring(0, 3);


      if(prefijo >= 200){
        return { nroAerovaleFueraDeRango: true };
      }
    }

    return null;
  }

  inicializarComponente(){
    this.modelFechaLlegada = this.calendar.getToday();
    var horaActual = new Date();
    this.modelHoraLlegada = { hour: horaActual.getHours(), minute: horaActual.getMinutes(), second: horaActual.getSeconds() };

    let inicio: any = { day: 1, month: 1, year: 2019 };
    this.datepickerConfig.minDate = inicio;
    let fin: any = { day: this.modelFechaLlegada.day, month: this.modelFechaLlegada.month , year: this.modelFechaLlegada.year };
    this.datepickerConfig.maxDate = fin;

    this.tipoPagoFormControl.setValue(TipoPago.Contado);

    this.observacionesFormControl.setValue("");

    this.form.valueChanges.subscribe(val => {
      this.formularioValido.emit(this.form.valid);
    });

    this.subscriptions.push(
      this.form.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      })
    );
  }

  filtrarAbastecedorasPorTipoDeCombustible(tipoProducto: TipoProducto): Abastecedora[] {
    let filtrados: Abastecedora[] = [];
    for (let a of this.abastecedoras) {
      if (a.activo && a.producto.tipoProducto === tipoProducto) {
        filtrados.push(a);
      }
    }
    return filtrados;
  }

  esCampoVacio(campo: any): boolean {
    return (campo == null || (campo != null && campo.length == 0));
  }

  searchOperador = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    map(term => this.operadores == null || term.length < 2 || this.cargandoOperadores ? [] :
      this.operadores.filter(op => this.containsString(this.operadorFormControl.value, op.nombresCompletos, op.dni)).slice(0, 10))
  )

  formatterOperador = (op: { nombresCompletos: string, dni: string }) => `${op.nombresCompletos} - ${op.dni}`;


  formatter = (x: { razonSocial: string, ctA_SGC: string }) => `${x.razonSocial} - ${x.ctA_SGC == null ? 'SIN CTA SGC' : x.ctA_SGC}`;

  formatterVehiculo = (x: { matricula: string }) => x.matricula;

  search = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    map(term => this.clientes == null || term.length < 2 || this.cargandoClientes ? [] :
      this.clientes.filter(v => this.containsString(this.clienteFormControl.value, v.ctA_SGC, v.razonSocial)).slice(0, 10))
  )

  selectCustomer(e: any): void {
    this.clienteFormControl.setValue(e.item);
    this.tipoPagoFormControl.setValue(this.clienteFormControl.value.tipoCuenta);
    this.getVehiculosByCliente((e.item as unknown as CuentaCliente));
  }

  confirmCustomer(): void {
    if (typeof (this.clienteFormControl.value) === 'string') {
      this.nuevoCliente(this.clienteFormControl.value);
    }
  }

  selectOperador(event: any): void {
    this.operadorFormControl.setValue(event.item);
  }

  containsString(searchValue: string, ...searchIn: string[]): boolean {
    const concat = searchIn.toString();
    return concat.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
  }

  nuevoCliente(clientText): void {
    const modalRef = this.modalService.open(NuevoClienteComponent);
    if (typeof (clientText) === 'string') {
      modalRef.componentInstance.nombreCliente = clientText; // should be the id
    }
    modalRef.result.then((cliente) => {
      if (cliente) {
        this.clientes.push(cliente);
        this.clienteFormControl.setValue(cliente);
      }      
    }).catch((error) => {
      this.clienteFormControl.setValue(null);
    });
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }

    if (value === null) {
      this.form.reset();
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  // communicate the inner form validation to the parent form
  validate(_: UntypedFormControl) {
    return this.form.valid ? null : { aerovaleCommon: { valid: false } };
  }

  controlVehiculoProducto() {
    if (this.abastecedoraFormControl.value instanceof Object && this.vehiculoFormControl.value instanceof Object) {      
      if(this.vehiculoFormControl.value.codigoProducto != this.abastecedoraFormControl.value.producto.codigoProducto)
        this.combustibleNoCoincide = true;
      else 
        this.combustibleNoCoincide = false;
    }
  }

  // llamadas al service

  buscarTanques(){

    this.tanqueAsociado.setValue(null);

    if(this.abastecedoraFormControl.value.tipoExpendedora == 1){ // Surtidor
      this.obteniendoTanquesSurtidor = true;
      this.surtidorService.getSurtidoresPorCodigo(this.abastecedoraFormControl.value.codigo)
        .subscribe(result => {
          this.surtidores = result;
          this.obteniendoTanquesSurtidor = false;
        })
    }
    this.seleccionarPicoDefecto();
    this.tanqueAsociado.updateValueAndValidity();
    this.productoFormControl.setValue(this.abastecedoraFormControl.value.producto.nombreProducto);
    this.controlVehiculoProducto();
  }


  getClientes(): void {
    this.cargandoClientes = true;
    this.clienteService.getClientesActivos()
      .subscribe(clientes => {
        clientes = clientes.map(a => Object.assign(new CuentaCliente(), a));
        this.clientes = clientes;
        this.cargandoClientes = false;
      }, () => this.errorClientes = true);
  }

  getOperadores(): void {
    this.cargandoOperadores = true;

    this.aerovaleService.getOperadores(0, this.usuarioService.getAeroplantaActual().codigoAeroplanta)
      .subscribe(operadores => {
        operadores = operadores.map(a => Object.assign(new Operador(), a));
        this.operadores = operadores;
        this.cargandoOperadores = false;
      }, () => this.errorOperadores = true);
  }

  getOperadoresConRoles(): void {
    this.cargandoOperadores = true;
    var roles = this.usuarioService.getRolesEmitirAerovales();
    this.aerovaleService.getOperadoresConRoles(this.operadores, roles, this.usuarioService.getAeroplantaActual().codigoAeroplanta)
      .subscribe(result => {
        this.cargandoOperadores = true;
        this.operadores = result.map(a => Object.assign(new Operador(), a));
        //this.operadores = operadores;
        this.cargandoOperadores = false;
      }
    );
  }
  getCombustibles(): void {
    this.pedidoService.getAerocombustible()
      .subscribe(combustibles => {
        this.combustibles = combustibles;
        if (this.productoFormControl.value != null) {
          this.cargandoAbastecedoras = true;
          this.getAbastecedoras();
        }
      });
  }

  getAbastecedoras(): void {
    this.cargandoAbastecedoras = true;
    this.aerovaleService.getTodasLasExpendedoras(this.usuarioService.getAeroplantaActual().codigoAeroplanta)
      .subscribe(result => {   
        this.tipoAerovale == TipoAerovale.Alije ? this.abastecedoras = result.filter(a => a.tipoExpendedora == TipoExpendedora.ABASTECEDORA_CAMION) : this.abastecedoras = result;       
        this.abastecedoras = this.filtrarAbastecedorasPorTipoDeCombustible(this.obtenerTipoProductoPorTipoAerovale());
        this.cargandoAbastecedoras = false;
      });
  }

  getObservacionesDespacho() {
    this.aerovaleService.getMotivosAnulacion(0).subscribe(observaciones => {
      this.observaciones = observaciones;
    });
  }

  getVehiculos(): void {
    this.todosLosVehiculos = [];    
    if(this.tipoAerovale != TipoAerovale.Combustible){
      this.cargandoAeronaves = true;
      this.vehiculosService.getAeronaves()
        .subscribe( a => {
          this.todosLosVehiculos = this.todosLosVehiculos.concat(a);
          this.cargandoAeronaves = false;
        }, ()=> {
          this.cargandoAeronaves = false;
          this.errorVehiculos = true;
        })
      }
    if(this.tipoAerovale == TipoAerovale.Combustible || this.tipoAerovale == TipoAerovale.Aeroespecialidad){
      this.cargandoAutomotores = true;
      this.vehiculosService.getAutomotores()
      .subscribe( a => {
        this.todosLosVehiculos = this.todosLosVehiculos.concat(a);
        this.cargandoAutomotores = false;
      }, ()=> {
        this.cargandoAutomotores = false;
        this.errorVehiculos = true;
      })
    }
  }

  getVehiculosByCliente(cuenta: CuentaCliente): void {
    this.cargandoVehiculosDeCliente = true;
    this.vehiculosService.getCuentasVehiculos(cuenta.tipoDocumento, cuenta.numeroDocumento)
      .subscribe( resp => {
        if (this.obtenerTipoVehiculoPorTipoAerovale() != TipoVehiculo.Indistinto) {
          this.vehiculosDeCliente = resp.filter( v => v.ctA_SGC == cuenta.ctA_SGC)[0].vehiculos.filter( a => a.tipoVehiculo==this.obtenerTipoVehiculoPorTipoAerovale());
        }else{
          this.vehiculosDeCliente = resp.filter( v => v.ctA_SGC == cuenta.ctA_SGC)[0].vehiculos;
        }        
        if (typeof (this.vehiculoFormControl.value) != 'string')
          this.verificarVehiculo();
        this.cargandoVehiculosDeCliente = false;
      }, 
      () => this.errorVehiculosDeCliente = true);
  }

  verificarVehiculo() {
    if (this.vehiculoFormControl.value) {
      if (this.clienteFormControl.value && this.clienteFormControl.value.id &&
        this.vehiculosDeCliente && this.vehiculosDeCliente.filter(value => value.matricula.toLowerCase().trim() === this.vehiculoFormControl.value.matricula.toLowerCase().trim()).length == 0) {
        const modalRef = this.modalService.open(ModalConfirmComponent, { centered: true });
        modalRef.componentInstance.mensaje = "El vehiculo no pertenece al cliente, ¿desea asignarlo de todas formas?"; // should be the id
        modalRef.result.then(() => {
        },
          () => {
            this.vehiculoFormControl.setValue(null);
          }
        );
      }
    }
  }

  //setea el aerocombustible de acuerdo a la aeronave seleccionada
  changeVehiculo(): void {
    if ((this.tipoAerovale==TipoAerovale.Aeroespecialidad && this.vehiculoFormControl.value.length>0) 
        || this.tipoAerovale!=TipoAerovale.Aeroespecialidad) {
          let encuentraVehiculo = this.vehiculoFormControl.value;
          //Esto es por si ingresan el valor abc123 pero existe una aeronave ABC123, entonces elige esta última
          if (typeof (this.vehiculoFormControl.value) === 'string') {
            encuentraVehiculo = this.todosLosVehiculos.filter(v => this.vehiculoHabilitado(v) ).find(p => p.matricula.toLowerCase().trim() == this.vehiculoFormControl.value.toLowerCase().trim());
          }

          //Si no encuentro una aeronave, ofrecemos crear una
          if (!encuentraVehiculo) {
            this.nuevoVehiculo(this.vehiculoFormControl.value);
          }else {
            this.vehiculoFormControl.setValue(encuentraVehiculo);
            this.verificarVehiculo();
          }
    }
  }

  selectVehiculo(e: any): void {    
    this.vehiculoFormControl.setValue(e.item);
    this.controlVehiculoProducto();
    if (typeof (e.item) === 'string') {
      this.changeVehiculo();      
    }      
  }

  nuevoVehiculo(matriculaText: any): void {
    const modalRef = this.modalService.open(ModalVehiculoComponent, { backdrop: "static", centered: true });
    if (typeof (matriculaText) === 'string') {
      modalRef.componentInstance.matriculaIngresada = matriculaText.toUpperCase();
    }
    modalRef.componentInstance.tipoVehiculoParaFiltrar = this.obtenerTipoVehiculoPorTipoAerovale();
    modalRef.componentInstance.vehiculos = this.todosLosVehiculos;    
    modalRef.result.then((vehiculo) => {
      if (vehiculo) {
        vehiculo = Object.assign(new VehiculoDTO, vehiculo);
        this.messageService.showSuccessMessage("Vehiculo creado");
        this.todosLosVehiculos.push(vehiculo);
        this.vehiculoFormControl.setValue(vehiculo);
      }      
    });
  }

  obtenerTipoVehiculoPorTipoAerovale(): TipoVehiculo{
    if(this.tipoAerovale == TipoAerovale.Aerocombustible || this.tipoAerovale == TipoAerovale.Alije){
      return TipoVehiculo.Aeronave;
    }else if(this.tipoAerovale == TipoAerovale.Combustible){
      return TipoVehiculo.Automotor;
    }
  }

  obtenerTipoProductoPorTipoAerovale(): TipoProducto{
    if(this.tipoAerovale == TipoAerovale.Aerocombustible || this.tipoAerovale == TipoAerovale.Alije){
      return TipoProducto.Aerocombustible;
    }else if(this.tipoAerovale == TipoAerovale.Combustible){
      return TipoProducto.Combustible;
    }else if(this.tipoAerovale == TipoAerovale.Aeroespecialidad){
      return TipoProducto.Aeroespecialidad;
    }else{
      return TipoProducto.Indeterminado;
    }
  }

  searchVehiculo = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    map(term => term.length < 2 || this.cargandoVehiculosDeCliente || isNullOrUndefined(this.vehiculosDeCliente) ? []
      : this.vehiculosDeCliente.filter(v => this.vehiculoHabilitado(v) && (v.matricula.toLowerCase().indexOf(this.vehiculoFormControl.value.toLowerCase()) > -1) ).slice(0, 10))
  )

  vehiculoHabilitado(v : VehiculoDTO){
    if(this.tipoAerovale == TipoAerovale.Aerocombustible || this.tipoAerovale == TipoAerovale.Alije){
      if(v.tipoVehiculo == TipoVehiculo.Aeronave){
        return true;
      }else{
        return false;
      }
    }else if(this.tipoAerovale == TipoAerovale.Combustible){
      if(v.tipoVehiculo == TipoVehiculo.Automotor){
        return true;
      }else{
        return false;
      }
    }else{
      return true;
    }
  }

  formatDate(year, month, day, hour, minute): string {
    return year + '-' + month.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + '-' + day.toLocaleString(undefined, { minimumIntegerDigits: 2 })
      + 'T' + hour.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + minute.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':00';
  }

  /* -------------------------------------------------------------------------------------------------------- */

  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  keysTipoDePago(): Array<string> {
    var keys = Object.keys(this.tipoPago);
    return keys.slice(keys.length / 2);
  }

  obtenerTipoDePago(tipoCuentaCliente: number): Array<string> {

    if(!tipoCuentaCliente) return [];

    let lista: Array<string> = this.keysTipoDePago();
    
    lista = lista.filter(e => TipoCuenta[e] == TipoCuenta.Contado || TipoCuenta[e] == tipoCuentaCliente);

    return lista;
  }

  /* -------------------------------------------------------------------------------------------------------- */

  get value(): AerovaleCommonFormValues {
    return this.form.value;
  }

  set value(value: AerovaleCommonFormValues) {
    this.form.setValue(value);
    this.onChange(value);
    this.onTouched();
  }

  get clienteFormControl() {
    return this.form.controls.clienteFormControl;
  }

  get vehiculoFormControl() {
    return this.form.controls.vehiculoFormControl;
  }

  get numeroAerovaleFormControl() {
    return this.form.controls.numeroAerovaleFormControl;
  }

  get productoFormControl() {
    return this.form.controls.productoFormControl;
  }

  get abastecedoraFormControl() {
    return this.form.controls.abastecedoraFormControl;
  }

  get fechaLlegadaFormControl() {
    return this.form.controls.fechaLlegadaFormControl;
  }

  get horaLlegadaFormControl() {
    return this.form.controls.horaLlegadaFormControl;
  }

  get comentariosFormControl() {
    return this.form.controls.comentariosFormControl;
  }

  get tipoPagoFormControl() {
    return this.form.controls.tipoPagoFormControl;
  }

  get observacionesFormControl() {
    return this.form.controls.observacionesFormControl;
  }

  get operadorFormControl() {
    return this.form.controls.operadorFormControl;
  }

  get tanqueAsociado(){
    return this.form.controls.tanquesAsociadosFormControl;
  }

  get picoAsociado() {
    return this.form.controls.picosAsociadosFormControl;
  }

  /* -------------------------------------------------------------------------------------------------------- */



}
