import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';

@Component({
  selector: 'app-listados-index',
  templateUrl: './listados-index.component.html',
  styleUrls: ['./listados-index.component.scss']
})
export class ListadosIndexComponent implements OnInit {

  TipoUsuario = TipoUsuario;
  
  constructor(private authService: AuthService) { }

  ngOnInit(): void {
  }

  hasRole(...roles: TipoUsuario[]) {
    return this.authService.hasRole(...roles);
  }

}
