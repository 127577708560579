import { HttpClient,HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { HttpErrorHandler } from '../http-error-handler.service';
import { AuthService } from '../core/authentication/auth.service';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
  })
export class IslaService{
  [x: string]: any;

    stockbaseUrl = environment.apiServer.stockBaseUrl;
    private aeroplantasUrl = this.stockbaseUrl + '/AeroplantasV1';

    constructor(private httpClient: HttpClient, private httpErrorHandler: HttpErrorHandler, private authservice: AuthService){}


    getIslasPorAeroplanta(codigoAeroplanta: string): Observable<string[]>{
      return this.httpClient.get<string[]>(this.aeroplantasUrl + "/" + codigoAeroplanta + "/Islas")
      .pipe(
        tap(_ => console.log('getIslasPorAeroplanta')),
          catchError(this.handleError)
        );
    }

    crearIsla(isla: string, codigoAeroplanta: string): Observable<string>{
      var headers={
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    }

      const json = JSON.stringify(isla);

      return this.httpClient.post<string>(this.aeroplantasUrl + "/" + codigoAeroplanta + "/Isla", json, headers)
      .pipe(
        tap(_ => console.log('Creacion de isla')),
        catchError(this.handleError)
        );
    }

    eliminarIsla(isla: string, codigoAeroplanta: string): Observable<string>{
      return this.httpClient.delete<string>(this.aeroplantasUrl + "/" + codigoAeroplanta + "/Isla/" + isla)
      .pipe(
        tap(_ => console.log('Eliminación de isla')),
        catchError(this.handleError)
        );
    }
  }
