export class ExportarAerovales {
    Aerovale: string;
    Cliente: string;
    Cuenta: string;
    Titulo: string;
    Contenedor: string;
    Producto: string;
    Cantidad: number;
    Cantidad15: number;
    CaudalObservado: number;
    PresionDiferencial: number;
    PresionPuntaManguera: number;
    Anulado: string;
}