import { EstadoPedido } from '../../enums/estadoPedido';
import { Aerovale } from './Aerovale';
import { UsuarioAerovale } from './UsuarioAerovale';

export class Precarga {
    id : string;
    codigoAbastecedora : string;
    abastecedoraNombre : string;
    cargaEstimada : number;
    fechaHora: string;
    estado : number;
    aerovaleId : string;
    cantidadReal : number;
    supervisorDTO: UsuarioAerovale;

  }
