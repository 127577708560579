import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { routerFade } from '../shared/animations';
import { RouterOutlet } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from '../core/authentication/auth.service';
import { SignalRService } from '../shared/signal-r.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.css'],
  animations: [routerFade]
})
export class ShellComponent implements OnInit {

  procesandoLoginSubscription: Subscription;
  usuarioAutenticadoSubscription: Subscription;

  constructor(private spinner: NgxSpinnerService, 
    public authService: AuthService,
    private signalRService: SignalRService) { 
    this.procesandoLoginSubscription = this.authService.procesandoLogin$.subscribe(procesandoLogin => {

      if(procesandoLogin) {
        this.spinner.show('spinner');
      }else{
        this.spinner.hide('spinner');
      }
    });

  }
  
  ngOnInit() {
    if(environment.signalR.activarFuncionalidad){
      this.signalRService.conectarServidorSignalR();
    }    
  }

  // Agregar un observer a authService->autenticacionCompleta para mostrar spinner cuando es false y ocultar spinner cuando es true
  // O hacerlo a través del navbar

  prepareRoute(outlet: RouterOutlet) {
    // 'outlet.activatedRouteData' creo que significa que se está accediendo a un route
    return outlet && outlet.activatedRouteData
  }
}
