import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClienteEncabezado } from 'src/app/shared/models/cliente/ClienteEncabezado';
import { ModalBusquedaMatriculaComponent } from './modal-busqueda-matricula/modal-busqueda-matricula.component';
@Component({
  selector: 'app-abm-matriculas',
  templateUrl: './abm-matriculas.component.html',
  styleUrls: ['./abm-matriculas.component.scss']
})
export class AbmMatriculasComponent implements OnInit {

  clienteSeleccionado : ClienteEncabezado;
  clienteBuscado:string;
  matriculaBuscada:string='';
  cargandoLista:boolean;

  busquedaFormControl = new UntypedFormControl();

  constructor(private modalService:NgbModal
  ) { }

  ngOnInit(): void {
  }

  usuarioSeleccionoCliente(cliente: ClienteEncabezado) {
    this.clienteSeleccionado = cliente;
  }

  errorDeBusqueda(e:boolean) {
    e ? this.matriculaBuscada = '' : '';
  }

  cargandoListaClientes(c:boolean) {
    this.cargandoLista = c; 
  }

  buscarMatricula() {
    const modalRef = this.modalService.open(ModalBusquedaMatriculaComponent, { backdrop: "static", centered: true });
    modalRef.result.then((matricula) => {
      (matricula) ? this.matriculaBuscada = matricula : this.matriculaBuscada = '';
    });       
  }

  cancelarBusqueda() {
    this.matriculaBuscada = '';
  }

  filtrarClientes() {
    this.clienteBuscado = this.busqueda.value;
  }

  get busqueda() {
    return this.busquedaFormControl;
  }

}
