<div class="card mt-2" *ngIf="this.cierreSeleccionado != null" id="invoicingPrint">
  <div class="card-header">
    <label><b>Cierre Seleccionado: {{cierreSeleccionado.fechaHoraCierre | date: "d'/'MM'/'y' 'hh:mm a"}}</b></label>
    <div *ngIf="!generandoReporte">
      <button class="btn btn-outline-primary ms-2" (click)="cambiar(0)"
        [disabled]="this.cierreSeleccionado.precierres.length == 0"
        *ngIf="this.cierreSeleccionado.estadoCierre != 1">Reporte Cierre</button>
      <button class="btn btn-outline-primary ms-2" (click)="cambiar(1)"
        [disabled]="this.movimientosCierre.length == 0">Reporte Stock</button>
      <button class="btn btn-outline-primary ms-2" (click)="cambiar(2)"
        [disabled]="this.movimientosCierre.length == 0">Reporte Aerovale</button>
      <button class="btn btn-outline-primary ms-2" (click)="cambiar(3)"
        [disabled]="this.movimientosCierre.length == 0">Reporte Ranchos</button>
      <button class="btn btn-outline-primary ms-2" (click)="cambiar(4)"
        [disabled]="this.reporteMovimientoProd.length == 0">Reporte movimientos producto</button>
      <button class="btn btn-outline-primary ms-2" (click)="cambiar(5)"
        [disabled]="this.reporteCorrelatividad.length == 0">Reporte correlatividad aforadores</button>
      <button class="btn btn-outline-primary ms-2" (click)="cambiar(6)"
        [disabled]="!this.mostrarParteDiario">Reporte parte diario</button>
    </div>
    <div *ngIf="generandoReporte">
      <loading-text-spinner class="col-md-12" class="col-md-12" message="Obteniendo reportes..." color="primary">
      </loading-text-spinner>
    </div>
  </div>

  <div class="col-12" *ngIf="generandoReporte && this.cierreSeleccionado.estadoCierre == 2">
    <div class="container">
      <div class="mb-3" style="height: 10cm;">
        <ngx-spinner name="spinnerLista" [ngStyle]="{'height': '400px', 'z-index':'0'}" bdOpacity=0.3bd
          Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
          <p style="color: white">C A R G A N D O . . .</p>
        </ngx-spinner>
      </div>
    </div>
  </div>

  <div class="card-body" *ngIf="this.reporteSeleccionado == 0 && this.cierreSeleccionado.estadoCierre >= EstadoCierre.Cerrado">
    <div class="col-12">
      <button style="width: inherit;" class="btn btn-outline-primary btn-block accordion-head collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#precierres" 
          aria-expanded="false" aria-controls="precierres">Precierres realizados <i class="fa fa-angle-down" aria-hidden="true"></i>
      </button>
      <div class="border border-solid collapse" id="precierres">
        <!--ABASTECEDORAS-->
        <table class="table table-striped table-hover">
          <thead class="text-center">
            <th>Abastecedora</th>
            <th>Estado</th>
            <th>Fecha/Hora precierre</th>
            <th>Volumen natural</th>
            <th>Volumen 15°</th>
            <th>Picos/Aforador</th>
            <th>Temperatura</th>
            <th>Densidad</th>
          </thead>
          <tbody class="text-center">
            <tr *ngFor="let exp of cierreSeleccionado.precierres; let i = index">
              <td>{{exp.nombreExpendedora}}</td>
              <td> Cerrada </td>
              <td> {{exp.fechaHoraPrecierre | date: "d'/'MM'/'y' 'hh:mm a"}} </td>
              <td> 
                <span *ngIf="exp.nivel">{{exp.nivel | number: '0.0-0'}}</span> 
                <span *ngIf="!exp.nivel">----</span> 
              </td>
              <td> 
                <span *ngIf="exp.nivel15">{{exp.nivel15 | number: '0.0-0'}}</span> 
                <span *ngIf="!exp.nivel15">----</span> 
              </td>
              <ng-container *ngIf="exp.picos != null">
                <td>
                  <ul *ngFor="let a of exp.picos" class="list-unstyled">
                    <li> {{a.nombre}} - {{a.aforadorFinalManual | number: '0.0-0'}}</li>
                  </ul>
                </td>
              </ng-container>
              <ng-container *ngIf="exp.picos == null || exp.picos.length == 0">
                <td> ---- </td>
              </ng-container>
              <td> 
                <span *ngIf="exp.temperatura">{{exp.temperatura}}</span> 
                <span *ngIf="!exp.temperatura">----</span> 
              </td>
              <td> 
                <span *ngIf="exp.densidad">{{exp.densidad}}</span> 
                <span *ngIf="!exp.densidad">----</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!--LITROS DESPACHADOS-->
    <div class="col-12 mt-2" *ngIf="comparativaAbastecedora.length > 0">
      <button style="width: inherit;" class="btn btn-outline-primary btn-block accordion-head collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#comAbastecedora" 
        aria-expanded="false" aria-controls="comAbastecedora">Comparativas abastecedora <i class="fa fa-angle-down" aria-hidden="true"></i>        
      </button>
      <div class="border border-solid collapse" id="comAbastecedora">
        <table class="table table-striped table-hover text-center">
          <thead>
            <tr>
              <th>Abastecedora</th>
              <th>Producto</th>
              <th>Salida por archivo</th>
              <th>Salida por aforador</th>
              <th>Diferencia</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let aba of comparativaAbastecedora">
              <td>{{aba.nombreExpendedora}}</td>
              <td>{{aba.nombreProducto}}</td>
              <td>{{aba.salidaArchivo | number: '0.0-0'}}</td>
              <td>{{aba.aforadorFinal - aba.aforadorInicial | number: '0.0-0'}}</td>
              <td>{{aba.diferenciaArchivoAforador | number: '0.0-0'}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!--VARILLADO TANQUE-->
    <div class="col-12 mt-2">
      <button style="width: inherit;" class="btn btn-outline-primary btn-block accordion-head collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#mediciones" 
        aria-expanded="false" aria-controls="mediciones">Mediciones ingresadas <i class="fa fa-angle-down" aria-hidden="true"></i>
      </button>    
      <div class="border border-solid collapse" id="mediciones">
        <table class="table table-striped table-hover text-center">
          <thead>
            <tr>
              <th>Contenedor</th>
              <th>Producto</th>
              <th>Volumen 15°</th>
              <th>Volumen Natural</th>
              <th>Medicion</th>
              <th>Densidad</th>
              <th>Temperatura</th> 
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let mediciones of this.cierreSeleccionado.medicionesTanques">
              <td>{{mediciones.nombreTanque}}</td>
              <td>{{mediciones.nombreProducto}}</td>
              <td>{{mediciones.nivel | number: '0.0-0'}}</td>
              <td>{{mediciones.nivelNatural | number: '0.0-0'}}</td>
              <td>{{mediciones.varillado | number: '0.0-0'}}</td>
              <td>{{mediciones.densidad}}</td>
              <td>{{mediciones.temperatura}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!--AEROESPECIALIDADES-->
    <div class="col-12 mt-2">
      <button style="width: inherit;" class="btn btn-outline-primary btn-block accordion-head collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#aerIngresadas" 
        aria-expanded="false" aria-controls="aerIngresadas">Aeroespecialidades ingresadas <i class="fa fa-angle-down" aria-hidden="true"></i>
      </button>           
      <div class="border border-solid collapse" id="aerIngresadas">
        <table class="table table-striped table-hover text-center">
          <thead>
            <tr>
              <th>Codigo</th>
              <th>Nombre</th>
              <th>Cantidad</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let aeroespe of this.cierreSeleccionado.aeroespecialidades">
              <td>{{aeroespe.codigoAeroespecialidad}}</td>
              <td>{{aeroespe.nombreAeroespecialidad}}</td>
              <td>{{aeroespe.stock}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

      <div *ngFor="let dist of distintosProducto; let i=index">
        <!--TANQUES NATURAL-->
        <div class="col-12 mt-2">
          <button style="width: inherit;" [ngClass]="{'accordion-head collapsed': natural[i] }" class="btn btn-outline-primary btn-block" type="button" (click)="natural[i]=!natural[i]"
            aria-expanded="false" aria-controls="{{dist}}">Comparativas {{dist}} natural <i style="float: right; font-size: 1.4em;" class="fa fa-angle-down" aria-hidden="true"></i>
          </button>
          <div class="border border-solid" id="{{dist}}" [ngbCollapse]="natural[i]">
            <table class="table table-striped table-hover text-center">
              <thead>
                <tr>
                  <th>Tanque</th>
                  <th>Existencia anterior natural</th>
                  <th>Entradas</th>
                  <th>Salidas</th>
                  <th>Existencia en libros natural</th>
                  <th>Existencia real natural</th>
                  <th>Deficit/Superavit</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let tn of this.arregloDistintoProdNatural(dist)">
                  <!-- <ng-container *ngIf="dist == tn.codigoProducto + ' - ' + tn.nombreProducto"> -->
                  <td>{{tn.nombreContenedor}}</td>
                  <td>{{tn.existenciaAnterior | number: '0.0-0'}}</td>
                  <td>{{tn.entradas | number: '0.0-0'}}</td>
                  <td>{{tn.salidas | number: '0.0-0'}}</td>
                  <td>{{tn.existenciaFinal | number: '0.0-0'}}</td>
                  <td>{{tn.existenciaReal | number: '0.0-0'}}</td>
                  <td>{{tn.diferencia | number: '0.0-0'}}</td>
                  <!-- </ng-container> -->
                </tr>
                <tr>
                  <td><b>TOTAL</b></td>
                  <td>{{sumar(dist, 'existenciaAnterior') | number: '0.0-0'}}</td>
                  <td>{{sumar(dist, 'entradas') | number: '0.0-0'}}</td>
                  <td>{{sumar(dist, 'salidas') | number: '0.0-0'}}</td>
                  <td>{{sumar(dist, 'existenciaFinal') | number: '0.0-0'}}</td>
                  <td>{{sumar(dist, 'existenciaReal') | number: '0.0-0'}}</td>
                  <td>{{sumar(dist, 'diferencia') | number: '0.0-0'}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!--TANQUES 15-->
        <div class="col-12 mt-2">
          <button style="width: inherit;" [ngClass]="{'accordion-head collapsed': grado15[i] }" class="btn btn-outline-primary btn-block" type="button" (click)="grado15[i]=!grado15[i]"
            aria-expanded="false" aria-controls="{{dist}}">Comparativas {{dist}} 15° <i style="float: right; font-size: 1.4em;" class="fa fa-angle-down" aria-hidden="true"></i>
          </button>
          <div class="border border-solid" id="{{dist}}" [ngbCollapse]="grado15[i]">
            <table class="table table-striped table-hover text-center">
              <thead>
                <th>Tanque</th>
                <th>Existencia anterior 15°</th>
                <th>Entradas</th>
                <th>Salidas</th>
                <th>Existencia en libros 15°</th>
                <th>Existencia real 15°</th>
                <th>Deficit/Superavit</th>
              </thead>
              <tbody>
                <tr *ngFor="let t15 of this.arregloDistintoProd15(dist)">
                  <!-- <ng-container *ngIf="dist == t15.codigoProducto + ' - ' + t15.nombreProducto"> -->
                  <td>{{t15.nombreContenedor}}</td>
                  <td>{{t15.existenciaAnterior | number: '0.0-0'}}</td>
                  <td>{{t15.entradas | number: '0.0-0'}}</td>
                  <td>{{t15.salidas | number: '0.0-0'}}</td>
                  <td>{{t15.existenciaFinal | number: '0.0-0'}}</td>
                  <td>{{t15.existenciaReal | number: '0.0-0'}}</td>
                  <td>{{t15.diferencia | number: '0.0-0'}}</td>
                  <!-- </ng-container> -->
                </tr>
                <tr>
                  <td><b>TOTAL</b></td>
                  <td>{{sumar2(dist, 'existenciaAnterior') | number: '0.0-0'}}</td>
                  <td>{{sumar2(dist, 'entradas') | number: '0.0-0'}}</td>
                  <td>{{sumar2(dist, 'salidas') | number: '0.0-0'}}</td>
                  <td>{{sumar2(dist, 'existenciaFinal') | number: '0.0-0'}}</td>
                  <td>{{sumar2(dist, 'existenciaReal') | number: '0.0-0'}}</td>
                  <td>{{sumar2(dist, 'diferencia') | number: '0.0-0'}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    
    <!--Aeroespecialidades-->
    <div class="col-12 mt-2">
      <button style="width: inherit;" class="btn btn-outline-primary btn-block accordion-head collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#comAero" 
        aria-expanded="false" aria-controls="comAero">Comparativa aeroespecialidades <i class="fa fa-angle-down" aria-hidden="true"></i>
      </button>               
      <div class="border border-solid collapse" id="comAero">
        <table class="table table-striped table-hover text-center"
          *ngIf="this.comparativaAeroespecialidades.length > 0">
          <thead>
            <th>Codigo</th>
            <th>Aeroespecialidad</th>
            <th>Inicial</th>
            <th>Entradas</th>
            <th>Salidas</th>
            <th>Existencia libros</th>
            <th>Existencia ingresada</th>
            <th>Diferencia</th>
          </thead>
          <tbody>
            <tr *ngFor="let aeroesp of this.comparativaAeroespecialidades">
              <td>{{aeroesp.codigoProducto}}</td>
              <td>{{aeroesp.nombreProducto}}</td>
              <td>{{aeroesp.existenciaAnterior}}</td>
              <td>{{aeroesp.entradas}}</td>
              <td>{{aeroesp.salidas}}</td>
              <td>{{aeroesp.existenciaFinal}}</td>
              <td>{{aeroesp.existenciaReal}}</td>
              <td>{{aeroesp.diferencia}}</td>
            </tr>
          </tbody>
        </table>
        <ngb-alert class="alert-warning" *ngIf="this.comparativaAeroespecialidades.length == 0">No hay
          aeroespecialidades en el cierre de turno.</ngb-alert>
      </div>
    </div>

    <!--Total productos a 15º-->    
    <div class="col-12 mt-2">
      <button style="width: inherit;" class="btn btn-outline-primary btn-block accordion-head collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#tp15" 
        aria-expanded="false" aria-controls="tp15">Totales Productos 15° <i class="fa fa-angle-down" aria-hidden="true"></i>
      </button>   
      <div class="border border-solid collapse" id="tp15">        
        <table class="table table-striped table-hover text-center"
          *ngIf="this.totalProductos15.length > 0">
          <tr class="bordes">
            <th class="bordes" rowspan="2">Producto</th>            
            <th class="bordes" rowspan="2">Existencia anterior</th>  
            <th class="bordes" colspan="2">Entradas</th>
            <th class="bordes" colspan="4">Salidas</th> 
            <th class="bordes" rowspan="2">Existencia libros</th>
            <th class="bordes" rowspan="2">Existencia real</th>
            <th class="bordes" rowspan="2">Déficit/Superavit</th>
          </tr>
          <tr class="bordes">
            <th class="bordes">Cargamento</th>
            <th class="bordes">Alijes</th>
            <th class="bordes">Aerovales</th>
            <th class="bordes">Contado</th>
            <th class="bordes">Remitos</th>
            <th class="bordes">Interno</th>
          </tr>
          <tbody>
            <tr *ngFor="let tp of this.totalProductos15">
              <td>{{tp.codigoProducto}} - {{tp.nombreProducto}}</td>              
              <td>{{tp.existenciaAnterior | number: '0.0-0' }}</td>
              <td>{{tp.entradasCargamento | number: '0.0-0' }}</td>
              <td>{{tp.entradasAlije | number: '0.0-0' }}</td>
              <td>{{tp.salidasCtaCte | number: '0.0-0' }}</td>
              <td>{{tp.salidasContado | number: '0.0-0' }}</td>
              <td>{{tp.salidasRemito | number: '0.0-0' }}</td>
              <td>{{tp.salidasConsumoInterno | number: '0.0-0' }}</td>
              <td>{{tp.existenciaFinal | number: '0.0-0' }}</td>
              <td>{{tp.existenciaReal | number: '0.0-0' }}</td>
              <td>{{tp.diferencia | number: '0.0-0'}}</td>
            </tr>
          </tbody>
        </table>
        <ngb-alert class="alert-warning" *ngIf="this.totalProductos15.length == 0">No hay
          totales por productos a 15° en el cierre de turno.
        </ngb-alert>
      </div>
    </div>

    <!--Total productos natural-->
    <div class="col-12 mt-2">
      <button style="width: inherit;" class="btn btn-outline-primary btn-block accordion-head collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#tpNat" 
        aria-expanded="false" aria-controls="tpNat">Totales Productos Natural <i class="fa fa-angle-down" aria-hidden="true"></i>
      </button>   
      <div class="border border-solid collapse" id="tpNat">        
        <table class="table table-striped table-hover text-center"
          *ngIf="this.totalProductosNatural.length > 0">
          <tr class="bordes">
            <th class="bordes" rowspan="2">Producto</th>            
            <th class="bordes" rowspan="2">Existencia anterior</th>  
            <th class="bordes" colspan="2">Entradas</th>
            <th class="bordes" colspan="4">Salidas</th> 
            <th class="bordes" rowspan="2">Existencia libros</th>
            <th class="bordes" rowspan="2">Existencia real</th>
            <th class="bordes" rowspan="2">Déficit/Superavit</th>
          </tr>
          <tr class="bordes">
            <th class="bordes">Cargamento</th>
            <th class="bordes">Alijes</th>
            <th class="bordes">Aerovales</th>
            <th class="bordes">Contado</th>
            <th class="bordes">Remitos</th>
            <th class="bordes">Interno</th>
          </tr>
          <tbody>
            <tr *ngFor="let tp of this.totalProductosNatural">
              <td>{{tp.codigoProducto}} - {{tp.nombreProducto}}</td>              
              <td>{{tp.existenciaAnterior | number: '0.0-0' }}</td>
              <td>{{tp.entradasCargamento | number: '0.0-0' }}</td>
              <td>{{tp.entradasAlije | number: '0.0-0' }}</td>
              <td>{{tp.salidasCtaCte | number: '0.0-0' }}</td>
              <td>{{tp.salidasContado | number: '0.0-0' }}</td>
              <td>{{tp.salidasRemito | number: '0.0-0' }}</td>
              <td>{{tp.salidasConsumoInterno | number: '0.0-0' }}</td>
              <td>{{tp.existenciaFinal | number: '0.0-0' }}</td>
              <td>{{tp.existenciaReal | number: '0.0-0' }}</td>
              <td>{{tp.diferencia | number: '0.0-0'}}</td>
            </tr>
          </tbody>
        </table>
        <ngb-alert class="alert-warning" *ngIf="this.totalProductosNatural.length == 0">No hay
          totales por productos a natural en el cierre de turno.
        </ngb-alert>
      </div>
    </div>
  </div>
  <app-dettale-movimientos-cierre *ngIf="this.reporteSeleccionado == 1" 
    [generandoReporte]="this.generandoReporte"
    [cierreSeleccionado]="this.cierreSeleccionado"
    [reporteSeleccionado]="reporteSeleccionado" 
    [movimientosCierre]="movimientosCierre" 
    [ejecutarStock]="ejecutarStock" 
    (termino)="resetearExportaciones()">
  </app-dettale-movimientos-cierre>
  <app-reporte-aerovale-cierre *ngIf="this.reporteSeleccionado == 2" [reporteSeleccionado]="reporteSeleccionado" [ejecutarAerovales]="ejecutarAerovales"
    [cierreSeleccionado]="this.cierreSeleccionado" [movimientosCierre]="movimientosCierre" (termino)="resetearExportaciones()">
  </app-reporte-aerovale-cierre>
  <app-reporte-rancho-cierre *ngIf="this.reporteSeleccionado == 3" [reporteSeleccionado]="reporteSeleccionado" [ejecutarRanchos]="ejecutarRanchos"
    [cierreSeleccionado]="this.cierreSeleccionado" [movimientosCierre]="movimientosCierre" (termino)="resetearExportaciones()">
  </app-reporte-rancho-cierre>
  <app-reporte-movimiento-productos-cierre *ngIf="this.reporteSeleccionado == 4" [ejecutarMovimientosProducto]="ejecutarMovimientosProducto"
    [reporteSeleccionado]="reporteSeleccionado" [reporteMovimientoProd]="reporteMovimientoProd"
    [cierreSeleccionado]="this.cierreSeleccionado" (termino)="resetearExportaciones()">
  </app-reporte-movimiento-productos-cierre>
  <app-reporte-correlatividad-aforadores-cierre *ngIf="this.reporteSeleccionado == 5" [ejecutarAforadores]="ejecutarAforadores"
    [reporteSeleccionado]="reporteSeleccionado" [cierreSeleccionado]="this.cierreSeleccionado" (termino)="resetearExportaciones()"
    [reporteCorrelatividad]="reporteCorrelatividad">
  </app-reporte-correlatividad-aforadores-cierre>
  <app-parte-diario-cierre-turno *ngIf="this.reporteSeleccionado == 6" [cierreTurno]="this.cierreSeleccionado" [reporteParteDiario]="true"
    (termino)="resetearExportaciones()">
  </app-parte-diario-cierre-turno>
  <div class="card-footer">
    <button *ngIf="cierreSeleccionado.id != null && !generandoReporte" styleSheetFile="assets/css/print.css,assets/css/bootstrap.min.css"
      (click)="print()" printTitle="" printSectionId="invoicingPrint" useExistingCss="true" class="btn btn-primary"
      ngxPrint style="margin-top: 0.5em; float: right;">
      <i class="fa fa-print" aria-hidden="true"></i> Imprimir 
    </button>
    <button *ngIf="cierreSeleccionado.id != null && this.reporteSeleccionado == 0" (click)="exportarPrecierres()"
      class="btn btn-primary me-2 my-2">
      <i class="fa fa-file-excel-o" aria-hidden="true"></i> Exportar precierres
    </button>
    <button *ngIf="cierreSeleccionado.id != null && this.reporteSeleccionado == 0"
      (click)="exportarComparativaAbastecedora()" class="btn btn-primary me-2 my-2">
      <i class="fa fa-file-excel-o" aria-hidden="true"></i> Exportar comparativas abastecedoras 
    </button>
    <button *ngIf="cierreSeleccionado.id != null && this.reporteSeleccionado == 0" (click)="exportarVarillado()"
      class="btn btn-primary me-2 my-2">
      <i class="fa fa-file-excel-o" aria-hidden="true"></i> Exportar varillado 
    </button>
    <button *ngIf="cierreSeleccionado.id != null && this.reporteSeleccionado == 0" (click)="exportarComparativa15()"
      class="btn btn-primary me-2 my-2">
      <i class="fa fa-file-excel-o" aria-hidden="true"></i> Exportar tanques 15° 
    </button>
    <button *ngIf="cierreSeleccionado.id != null && this.reporteSeleccionado == 0" (click)="exportarComparativaNatural()"
      class="btn btn-primary me-2 my-2">
      <i class="fa fa-file-excel-o" aria-hidden="true"></i> Exportar tanque natural 
    </button>
    <button *ngIf="cierreSeleccionado.id != null && this.reporteSeleccionado == 0" (click)="exportarComparativasAeroespecilidad()"
      class="btn btn-primary me-2 my-2">
      <i class="fa fa-file-excel-o" aria-hidden="true"></i> Exportar comparativa aeroespecialidad 
    </button>
    <button *ngIf="cierreSeleccionado.id != null && this.reporteSeleccionado == 0" (click)="exportarProducto(TipoComparativa.TanquesNaturales)"
      class="btn btn-primary me-2 my-2">
      <i class="fa fa-file-excel-o" aria-hidden="true"></i> Exportar total producto natural
    </button>
    <button *ngIf="cierreSeleccionado.id != null && this.reporteSeleccionado == 0" (click)="exportarProducto(TipoComparativa.Tanque15)"
      class="btn btn-primary me-2 my-2">
      <i class="fa fa-file-excel-o" aria-hidden="true"></i> Exportar total producto 15°
    </button>
    <button *ngIf="cierreSeleccionado.id != null && !generandoReporte && this.reporteSeleccionado != 0 
      && this.reporteSeleccionado != 2 && this.reporteSeleccionado != 6" (click)="exportar()"
      class="btn btn-primary me-2 my-2">
      <i class="fa fa-file-excel-o" aria-hidden="true"></i> Exportar 
    </button>
  </div>
</div>