import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-motivos-observaciones',
  templateUrl: './motivos-observaciones.component.html',
  styleUrls: ['./motivos-observaciones.component.css']
})
export class MotivosObservacionesComponent implements OnInit {

  @Input() motivos: string;
  constructor(public activeModal:NgbActiveModal) { }

  ngOnInit() {
  }

}
