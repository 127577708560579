import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbCalendar, NgbDatepickerConfig, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ProductoService } from 'src/app/abm/producto/producto.service';
import { UsuarioService } from 'src/app/account/usuario.service';
import { CalculosService } from 'src/app/shared/calculos.service';
import { TipoAerovale } from 'src/app/shared/enums/tipoAerovale';
import { TipoCuenta } from 'src/app/shared/enums/tipoCuenta';
import { TipoExpendedora } from 'src/app/shared/enums/tipoExpendedora';
import { TipoPago } from 'src/app/shared/enums/tipoPago';
import { TipoProducto } from 'src/app/shared/enums/tipoProducto';
import { TipoVehiculo } from 'src/app/shared/enums/tipoVehiculo';
import { ModalConfirmComponent } from 'src/app/shared/generic-modals/modal-confirm.component';
import { Abastecedora } from 'src/app/shared/models/despacho/Abastecedora';
import { AerovaleDetalle } from 'src/app/shared/models/despacho/AerovaleDetalle';
import { CuentaCliente } from 'src/app/shared/models/despacho/CuentaCliente';
import { Operador } from 'src/app/shared/models/despacho/Operador';
import { Producto } from 'src/app/shared/models/despacho/Producto';
import { Surtidor } from 'src/app/shared/models/stock/Surtidor';
import { NuevoClienteComponent } from 'src/app/shared/nuevo-cliente/nuevo-cliente.component';
import { SharedService } from 'src/app/shared/shared.service';
import { MessageService } from 'src/app/shell/message.service';
import { SurtidorService } from 'src/app/stock/surtidor.service';
import { isNullOrUndefined } from 'src/app/shared/compat-tools';
import { Laboratorio } from 'src/app/shared/models/despacho/Laboratorio';
import { AerovalesService } from '../../aerovales.service';
import { ClienteService } from 'src/app/cliente/cliente.service';
import { Cliente } from 'src/app/shared/models/despacho/Cliente';
import { NgxSpinnerService } from 'ngx-spinner';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { Usuario } from 'src/app/shared/models/despacho/Usuario';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';
import { ResultadoPruebaDeAgua } from 'src/app/shared/enums/resultadoPruebaDeAgua';
import { ResultadoPruebaLab } from 'src/app/shared/enums/resultadoPruebaLab';
import { LaboratorioService } from '../../Laboratorio.service';
import { AerovaleDetalleAlije } from 'src/app/shared/models/despacho/AerovaleDetalleAlije';
import { EstadoPruebaLaboratorio } from 'src/app/shared/enums/estadoPruebaLaboratorio';
import { AerovaleDetalleAerocombustible } from 'src/app/shared/models/despacho/AerovaleDetalleAerocombustible';
import { CargaArchivo } from 'src/app/shared/models/cliente/CargaArchivo';
import { Sas } from 'src/app/shared/models/padrones/Sas';
import { TipoBlobStorage } from 'src/app/shared/enums/TipoBlobStorage';
import { AzureBlobUploadService } from 'src/app/shared/azure-blob-upload.service';
import { StockService } from 'src/app/stock/stock.service';
import { BlobUploadCommonResponse } from '@azure/storage-blob';
import { VehiculoService } from 'src/app/cliente/vehiculo.service';
import { VehiculoDTO } from 'src/app/shared/models/cliente/VehiculoDTO';
import { ModalVehiculoComponent } from 'src/app/management/matriculas/abm-matriculas/modal-vehiculo/modal-vehiculo.component';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-aerovale-updated-alije',
  templateUrl: './aerovale-updated-alije.component.html',
  styleUrls: ['./aerovale-updated-alije.component.scss']
})
export class AerovaleUpdatedAlijeComponent implements OnInit {

  @Input() aerovaleReemplaza: AerovaleDetalle;
  @Input() fileAerovaleFisico: File;

  aerovalesForm: UntypedFormGroup;
  guardandoAerovale: boolean = false;  
  
  /** common */
  clientes: CuentaCliente[];
  operadores:Operador[]=[];
  abastecedoras: Abastecedora[]=[];
  productos: Producto[]=[];
  todosLosVehiculos: VehiculoDTO[];
  vehiculosDeCliente: VehiculoDTO[];
  surtidores: Surtidor;

  cargandoInformacion: boolean = false;
  cargandoClientes: boolean;  
  cargandoOperadores: boolean;
  cargandoAbastecedoras: boolean;    
  cargandoVehiculos: boolean;
  cargandoVehiculosDeCliente: boolean;
  obteniendoTanquesSurtidor: boolean = false;

  errorClientes: boolean;
  errorOperadores: boolean;
  errorDestinos:boolean=false;
  errorVehiculos:boolean=false;
  errorVehiculosDeCliente: boolean;

  modelFechaPruebaLaboratorio: NgbDateStruct;
  modelFechaInicioAlije: NgbDateStruct;
  modelFechaFinAlije: NgbDateStruct;
  modelFechaLlegada: NgbDateStruct;
  modelHoraInicioAlije: any;
  modelHoraFinAlije: any;
  modelHoraLlegada: any;

  /** Alije */
  laboratorios: Laboratorio[];
  cargandoLaboratorios: boolean;
  errorLaboratorios: boolean;

  TipoAerovale = TipoAerovale;
  TipoExpendedora = TipoExpendedora;
  TipoPago = TipoPago;
  ResultadoPruebaDeAgua = ResultadoPruebaDeAgua;
  ResultadoPruebaLaboratorio = ResultadoPruebaLab;

  firmaCliente: any;
  firmaOperador: any;

  esValidaDensidad: boolean;
  densidadMin: number;
  densidadMax: number;

  combustibleNoCoincide: boolean = false;

  aerovaleCreadoCorrectamente: boolean = false;
  readonly entidadesTotales = 7;
  entidadesDescargadas = 0;
  private entidadesDescargadas$ = new Subject<number>();
  private subscription: Subscription;

  tiempo = environment.frontdoor.timeout;

  constructor(public modal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private datepickerConfig: NgbDatepickerConfig,
    private calendar: NgbCalendar,
    private aerovaleService:AerovalesService,
    private usuarioService:UsuarioService,
    private modalService: NgbModal,
    private messageService:MessageService,
    private clienteService: ClienteService,
    private productoService: ProductoService,    
    private laboratorioService: LaboratorioService,
    private surtidorService: SurtidorService,
    private calculoService: CalculosService,
    private sharedService: SharedService,
    private spinner: NgxSpinnerService,
    private azureBlobUpload: AzureBlobUploadService,
    private stockService:StockService,
    private vehiculosService: VehiculoService) { }

ngOnInit() {    

  this.modelFechaLlegada = this.calendar.getToday();
  let inicio: any = { day: 1, month: 1, year: 2019 };
  this.datepickerConfig.minDate = inicio;
  let fin: any = { day: this.modelFechaLlegada.day, month: this.modelFechaLlegada.month , year: this.modelFechaLlegada.year };
  this.datepickerConfig.maxDate = fin;

  this.crearFormulario();
  this.iniciarComponentes();
}

crearFormulario(){
  this.aerovalesForm = this.fb.group({
    clienteFormControl: [null, Validators.required],
    operadorFormControl: [null, Validators.required],
    vehiculoFormControl: [null, Validators.required],
    productoFormControl: [null],
    abastecedoraFormControl: [null, Validators.required],
    fechaLlegadaFormControl: [null, Validators.required],
    horaLlegadaFormControl: [null, Validators.required],    
    tipoPagoFormControl: [null],    
    tanquesAsociadosFormControl: [null],
    picosAsociadosFormControl: [null, Validators.required],
    laboratorioFormControl: ['', Validators.required],
    fechaPruebaLaboratorioFormControl: ['', Validators.required],
    fechaInicioAlijeFormControl: ['', Validators.required],
    horaInicioAlijeFormControl: ['', Validators.required],
    fechaFinAlijeFormControl: ['', Validators.required],
    horaFinAlijeFormControl: ['', Validators.required],
    resultadoPruebaLaboratorioFormControl: ['', [Validators.required, this.pruebaDeLaboratorioValidator]], 
    varilladoInicialFormControl: ['', Validators.required],
    varilladoFinalFormControl: ['', [Validators.required, Validators.min(1)]],
    cantidadAlijeFormControl: ['', Validators.required],
    cantidadAlije15FormControl: ['', Validators.required],
    aforadorInicialFormControl: ['', Validators.required],
    aforadorFinalFormControl: ['', Validators.required],        
    temperaturaFormControl: ['', [Validators.required, this.temperaturaValidator]],
    densidadFormControl: ['', Validators.required], // entre valores
    resultadoPruebaDeAguaFormControl: ['', [Validators.required, this.pruebaDeAguaValidator]],  
  });

  this.aerovalesForm.setValidators(this.aerovaleValidator.bind(this));
}

iniciarComponentes() {

  this.mostrarSpinner2();
  
  this.entidadesDescargadas = 0;

  this.subscription = this.entidadesDescargadas$.subscribe( () => {
    this.entidadesDescargadas++; 
    if(this.entidadesDescargadas == this.entidadesTotales){
      this.precargarDatos();
    }
  }); 

  this.getClientes();
  this.getVehiculos();
  this.getOperadoresConRoles();
  this.getAbastecedoras();
  this.getLaboratorios();
  this.getProductos();  
}

precargarDatos() {
  this.vehiculoFormControl.setValue(this.aerovaleReemplaza.vehiculo);
  
  let fechaHoraLlegada: Date;
  let fechaHoraInicio: Date;
  let fechaHoraFin: Date;
  let fechaPrueba: Date;

  fechaHoraLlegada = new Date(this.aerovaleReemplaza.fechaHoraLlegada);
  this.modelFechaLlegada = { day: fechaHoraLlegada.getDate(), month: fechaHoraLlegada.getMonth() + 1, year: fechaHoraLlegada.getFullYear() };
  this.modelHoraLlegada = { hour: fechaHoraLlegada.getHours(), minute: fechaHoraLlegada.getMinutes(), second: fechaHoraLlegada.getSeconds() };
  
  fechaHoraInicio = new Date(this.aerovaleReemplaza.fechaHoraInicioDespacho);
  this.modelFechaInicioAlije = { day: fechaHoraInicio.getDate(), month: fechaHoraInicio.getMonth() + 1, year: fechaHoraInicio.getFullYear() };
  this.modelHoraInicioAlije = { hour: fechaHoraInicio.getHours(), minute: fechaHoraInicio.getMinutes(), second: fechaHoraInicio.getSeconds() };
  
  fechaHoraFin = new Date(this.aerovaleReemplaza.fechaHoraFinDespacho);
  this.modelFechaFinAlije = { day: fechaHoraFin.getDate(), month: fechaHoraFin.getMonth() + 1, year: fechaHoraFin.getFullYear() };
  this.modelHoraFinAlije = { hour: fechaHoraFin.getHours(), minute: fechaHoraFin.getMinutes(), second: fechaHoraFin.getSeconds() };
  
  fechaPrueba = new Date(this.aerovaleReemplaza.detalleAerocombustible.aerovaleDetalleAlije.fechaPruebaLaboratorio);
  this.modelFechaPruebaLaboratorio = { day: fechaPrueba.getDate(), month: fechaPrueba.getMonth() + 1, year: fechaPrueba.getFullYear() };
 
  this.tipoPagoFormControl.setValue(this.aerovaleReemplaza.tipoPago);

  this.aforadorInicialFormControl.setValue(this.aerovaleReemplaza.detalleAerocombustible.aforadorInicio);
  this.aforadorFinalFormControl.setValue(this.aerovaleReemplaza.detalleAerocombustible.aforadorFinal);
  this.temperaturaFormControl.setValue(this.aerovaleReemplaza.detalleAerocombustible.temperatura);
  this.densidadFormControl.setValue(this.aerovaleReemplaza.detalleAerocombustible.densidad);
  this.varilladoInicialFormControl.setValue(this.aerovaleReemplaza.detalleAerocombustible.aerovaleDetalleAlije.varilladoInicio);
  this.varilladoFinalFormControl.setValue(this.aerovaleReemplaza.detalleAerocombustible.aerovaleDetalleAlije.varilladoFinal);
  this.resultadoPruebaLaboratorioFormControl.setValue(this.aerovaleReemplaza.detalleAerocombustible.aerovaleDetalleAlije.estadoPruebaLaboratorio);
  this.resultadoPruebaDeAguaFormControl.setValue(this.aerovaleReemplaza.detalleAerocombustible.pruebaAgua);
  this.picosAsociadosFormControl.setValue(this.aerovaleReemplaza.detalleAerocombustible.picoId);
  this.obtenerCantidadAlijada();
  this.ocultarSpinner2();
}

mostrarSpinner2() {
  this.spinner.show('spinnerGrafico');
  this.cargandoInformacion = true;
}

ocultarSpinner2() {
  this.spinner.hide('spinnerGrafico');
  this.cargandoInformacion = false;
}

aerovaleValidator(group: UntypedFormGroup): any{
  if (group) {
    let varilladoInicial = group.get("varilladoInicialFormControl").value;
    let varilladoFinal = group.get("varilladoFinalFormControl").value;

    if(varilladoInicial != null && varilladoFinal != null){
      if( varilladoInicial >= varilladoFinal ){
        return { varilladoInicialMayorVarilladoFinal: true };
      }
    }

    var ahora = new Date();     

    let fechaInicio = group.get("fechaInicioAlijeFormControl").value;
    let horaInicio = group.get("horaInicioAlijeFormControl").value;

    if(fechaInicio && horaInicio){
      var fecha = new Date(fechaInicio.year, fechaInicio.month-1,  fechaInicio.day, horaInicio.hour, horaInicio.minute);
      if(fecha > ahora){
        return { fechaInicioMayorAhora: true };
      }
    }  

    let fechaFin = group.get("fechaFinAlijeFormControl").value;
    let horaFin = group.get("horaFinAlijeFormControl").value;

    if(fechaFin && horaFin){
      var fecha = new Date(fechaFin.year, fechaFin.month-1,  fechaFin.day, horaFin.hour, horaFin.minute);
      if(fecha > ahora){
        return { fechaFinMayorAhora: true };
      }
    }  

    if(fechaInicio && horaInicio && fechaFin && horaFin){
      var inicio = new Date(fechaInicio.year, fechaInicio.month-1,  fechaInicio.day, horaInicio.hour, horaInicio.minute);
      var fin = new Date(fechaFin.year, fechaFin.month-1,  fechaFin.day, horaFin.hour, horaFin.minute);
      if(inicio >= fin){
        return { fechaInicioMayorFin: true };
      }
    }  

    let fechaLlegada = group.get("fechaLlegadaFormControl").value;
    let horaLlegada = group.get("horaLlegadaFormControl").value;

    if(fechaLlegada && horaLlegada){
      var fecha = new Date(fechaLlegada.year, fechaLlegada.month-1,  fechaLlegada.day, horaLlegada.hour, horaLlegada.minute);
      if(fecha > ahora){
        return { fechaLlegadaMayorAhora: true };
      }
    }

    let abastecedora = group.get("abastecedoraFormControl").value;
    if(abastecedora != null && abastecedora.tipoExpendedora == TipoExpendedora.SURTIDOR){
      let tanque = group.get("tanquesAsociadosFormControl").value;
      if(tanque == null){
        return { noSeleccionoTanque: true };
      }
    }

    let aforadoInicial = group.get("aforadorInicialFormControl").value;
    let aforadorFinal = group.get("aforadorFinalFormControl").value;

    if (aforadoInicial != null && aforadorFinal != null) {
      if (aforadoInicial > aforadorFinal) {
        return { aforadorInicialMayorAforadorFinal: true };
      }
    }

    let pico = group.get("picosAsociadosFormControl").value;
    if(pico == null){
      return { noSeleccionoPico: true };
    }    

    return null;
  }
}

/** Utilidades */

getTipoAerovale() {
  if (this.aerovaleReemplaza!=null && this.aerovaleReemplaza!=undefined) {
    return TipoAerovale[this.aerovaleReemplaza.tipoAerovale];
  }else{
    return null;
  }
}

onlyNumberKey(event) {
  return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
}

onlyDecimalPositivo(event) {
  return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46;
}

keysTipoDePago(): Array<string> {
  var keys = Object.keys(this.TipoPago);
  return keys.slice(keys.length / 2);
}

keysResultadosPruebaLaboratorio(): Array<string> {
  var keys = Object.keys(this.ResultadoPruebaLaboratorio);
  return keys.slice(keys.length / 2);
}

obtenerTipoDePago(tipoCuentaCliente: number): Array<string> {
  if(!tipoCuentaCliente) return [];
  let lista: Array<string> = this.keysTipoDePago();
  lista = lista.filter(e => TipoCuenta[e] == TipoCuenta.Contado || TipoCuenta[e] == tipoCuentaCliente);
  return lista;
}

obtenerNumeracionAerovale() {
  if (this.aerovaleReemplaza) {
    return this.aerovaleReemplaza.prefijo+'-'+this.aerovaleReemplaza.numeroAerovale
  }else{
    return null
  }  
}

obtenerCantidadAlijada() {
  let res: number = this.varilladoFinalFormControl.value - this.varilladoInicialFormControl.value;
  if (this.varilladoFinalFormControl.value < this.varilladoInicialFormControl.value) { res = 0; }
  this.cantidadAlijeFormControl.setValue(res);    
  if(this.temperaturaFormControl.value != null && this.densidadFormControl.value != null) {
    this.obtenerCantidadAlijada15();
  }
}

obtenerCantidadAlijada15() {
  let res: number = this.calculoService.obtenerCTLNuevo(this.temperaturaFormControl.value, this.densidadFormControl.value, this.cantidadAlijeFormControl.value);
  if(!isNaN(res)){
    this.cantidadAlije15FormControl.setValue(res);
  }else{
    this.cantidadAlije15FormControl.setValue(0);
  }
  return res;    
}

pruebaDeLaboratorioValidator(control: UntypedFormControl) { (1)
  if(control.value == ResultadoPruebaLab.Aprobado){
    return null;
  }
  
  return { aprobadoRequired: true };
}

pruebaDeAguaValidator(control: UntypedFormControl) {
  if(control.value == ResultadoPruebaDeAgua.Negativa){
    return null;
  }
  
  return { negativoRequired: true };
}

formatDate(year, month, day, hour, minute): string {
  return year + '-' + month.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + '-' + day.toLocaleString(undefined, { minimumIntegerDigits: 2 })
    + 'T' + hour.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + minute.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':00';
}

/** Servicios */

getClientes(): void {
  this.cargandoClientes = true;
  this.clienteService.getClientesActivos()
    .subscribe(clientes => {
      clientes = clientes.map(a => Object.assign(new CuentaCliente(), a));
      this.clientes = clientes;
      this.cargandoClientes = false;
      this.entidadesDescargadas$.next();
      if(this.clientes.length>0) {        
        this.clienteFormControl.setValue(this.clientes.find(c => c.ctA_SGC == this.aerovaleReemplaza.cliente.numeroCuenta));
        this.getVehiculosByCliente(this.aerovaleReemplaza.cliente);
      }
    }, () => this.errorClientes = true);
}

getProductos() {
  this.productoService.obtenerProductos()
    .subscribe(result => {
      this.productos = result;
      this.entidadesDescargadas$.next();
    }, () => {

    });
}

getOperadores(): void {
  this.cargandoOperadores = true;
  this.aerovaleService.getOperadores(0, this.usuarioService.getAeroplantaActual().codigoAeroplanta)
    .subscribe(result => {
      this.operadores = result.map(a => Object.assign(new Operador(), a));
      //this.operadores = operadores;
      this.cargandoOperadores = false;
      if(this.operadores.length>0) {        
        this.operadorFormControl.setValue(this.operadores.find(o => o.nombresCompletos.toLowerCase().trim() == this.aerovaleReemplaza.operadorDTO.nombreCompleto.toLowerCase().trim()));
      }
    }
  );
}
getOperadoresConRoles(): void {
  this.cargandoOperadores = true;
  var roles = this.usuarioService.getRolesEmitirAerovales();
  this.aerovaleService.getOperadoresConRoles(this.operadores, roles, this.usuarioService.getAeroplantaActual().codigoAeroplanta)
    .subscribe(result => {
      this.cargandoOperadores = true;
      this.operadores = result.map(a => Object.assign(new Operador(), a));
      //this.operadores = operadores;
      this.cargandoOperadores = false;
      this.entidadesDescargadas$.next();
      if(this.operadores.length>0) {        
        this.operadorFormControl.setValue(this.operadores.find(o => o.nombresCompletos.toLowerCase().trim() == this.aerovaleReemplaza.operadorDTO.nombreCompleto.toLowerCase().trim()));
      }
    }
  );
}

getAbastecedoras(): void {
  this.cargandoAbastecedoras = true;
  this.aerovaleService.getTodasLasExpendedoras(this.usuarioService.getAeroplantaActual().codigoAeroplanta)
    .subscribe(result => {
      this.aerovaleReemplaza.tipoAerovale == TipoAerovale.Alije ? this.abastecedoras = result.filter(a => a.tipoExpendedora == TipoExpendedora.ABASTECEDORA_CAMION) : this.abastecedoras = result;       
      this.abastecedoras = this.filtrarAbastecedorasPorTipoDeCombustible(this.obtenerTipoProductoPorTipoAerovale());
      this.cargandoAbastecedoras = false;
      this.entidadesDescargadas$.next();
      if(this.abastecedoras.length>0) {
        this.abastecedoraFormControl.setValue(this.abastecedoras.find(a => a.codigo == this.aerovaleReemplaza.abastecedora.codigo));
        this.productoFormControl.setValue(this.abastecedoraFormControl.value.productoActual.nombreProducto);
        if(this.abastecedoraFormControl.value.tipoExpendedora == TipoExpendedora.SURTIDOR) {
          this.obteniendoTanquesSurtidor = true;
          this.surtidorService.getSurtidoresPorCodigo(this.aerovaleReemplaza.abastecedora.codigo)
            .subscribe(result => {
              this.surtidores = result;
              this.obteniendoTanquesSurtidor = false;
              this.tanquesAsociadosFormControl.setValue( this.aerovaleReemplaza.detalleAerocombustible.codigoTanque );
            });          
        }
      }
    });
}

getLaboratorios(): void {
  this.cargandoLaboratorios = true;
  this.laboratorioService.getLaboratorios()
    .subscribe(laboratorios => {
      laboratorios = laboratorios.map(l => Object.assign(new Laboratorio(), l));
      this.laboratorios = laboratorios;
      this.entidadesDescargadas$.next();
      if(this.laboratorios.length>0) {
        let valor = this.laboratorios.find( l => l.id == this.aerovaleReemplaza.detalleAerocombustible.aerovaleDetalleAlije.laboratorioId );
        this.laboratorioFormControl.setValue( valor );
      }
      this.cargandoLaboratorios = false;
    }, () => this.errorLaboratorios = true);
}

buscarTanques(){

  this.tanquesAsociadosFormControl.setValue(null);

  if(this.abastecedoraFormControl.value.tipoExpendedora == TipoExpendedora.SURTIDOR){ 
    this.obteniendoTanquesSurtidor = true;
    this.surtidorService.getSurtidoresPorCodigo(this.abastecedoraFormControl.value.codigo)
      .subscribe(result => {
        this.surtidores = result;
        this.obteniendoTanquesSurtidor = false;
      })
  }
  this.seleccionarPicoDefecto();
  this.tanquesAsociadosFormControl.updateValueAndValidity();
  this.productoFormControl.setValue(this.abastecedoraFormControl.value.producto.nombreProducto);
  this.validarDensidad(this.densidadFormControl.value)
  this.controlVehiculoProducto();
}

seleccionarPicoDefecto() {
  this.abastecedoraFormControl.value.picos.forEach(function (value) {
    if (value.porDefecto) {
      this.picosAsociadosFormControl.setValue(value);
      this.picosAsociadosFormControl.updateValueAndValidity();
    }
  }, this);
}

obtenerTipoProductoPorTipoAerovale(): TipoProducto{
  return TipoProducto.Aerocombustible;
}

filtrarAbastecedorasPorTipoDeCombustible(tipoProducto: TipoProducto): Abastecedora[] {
  let filtrados: Abastecedora[] = [];
  for (let a of this.abastecedoras) {
    if (a.activo && a.producto.tipoProducto === tipoProducto) {
      filtrados.push(a);
    }
  }
  return filtrados;
}

/**
 * Determina si una cadena se encuentra dentro de otra
 * @param searchValue Valor a buscar
 * @param searchIn Valores sobre los que buscar searchValue
 */
 containsString(searchValue: string, ...searchIn: string[]): boolean {
  const concat = searchIn.toString();
  return concat.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
}

getVehiculos(): void {
  this.cargandoVehiculos = true;
  this.vehiculosService.getAeronaves()
    .subscribe( a => {
      this.todosLosVehiculos = a;
      this.cargandoVehiculos = false;
      this.entidadesDescargadas$.next();
    }, ()=> {
      this.cargandoVehiculos = false;
      this.errorVehiculos = true
    })  
}

getVehiculosByCliente(cte: Cliente): void {
  this.cargandoVehiculosDeCliente = true;
  this.vehiculosService.getCuentasVehiculos(cte.tipoDocumento, cte.nroDocumento)
    .subscribe( resp => {
      this.vehiculosDeCliente = resp.filter( v => v.ctA_SGC == cte.numeroCuenta)[0].vehiculos.filter( a => a.tipoVehiculo==TipoVehiculo.Aeronave);
      if (typeof (this.vehiculoFormControl.value) != 'string' && !this.cargandoInformacion){
        this.verificarVehiculo();
      }
      this.cargandoVehiculosDeCliente = false;
      this.entidadesDescargadas$.next();
    }, () => {
      this.cargandoVehiculosDeCliente = false;
      this.errorVehiculosDeCliente = true;
    });
}

/** Input Operador */

searchOperador = (text$: Observable<string>) =>
text$.pipe(
  debounceTime(200),
  distinctUntilChanged(),
  map(term => this.operadores == null || term.length < 2 || this.cargandoOperadores ? [] :
    this.operadores.filter(op => this.containsString(this.operadorFormControl.value, op.nombresCompletos, op.dni)).slice(0, 10))
);

formatterOperador = (op: { nombresCompletos: string, dni: string }) => `${op.nombresCompletos} - ${op.dni}`;

selectOperador(event: any): void {
  this.operadorFormControl.setValue(event.item);
}

/** Input Clientes */

searchCliente = (text$: Observable<string>) =>
text$.pipe(
  debounceTime(200),
  distinctUntilChanged(),
  map(term => this.clientes == null || term.length < 2 || this.cargandoClientes ? [] :
    this.clientes.filter(v => this.containsString(this.clienteFormControl.value, v.ctA_SGC, v.razonSocial)).slice(0, 10))
)

confirmCliente(): void {
  if (typeof (this.clienteFormControl.value) === 'string') {
    this.nuevoCliente(this.clienteFormControl.value);
  }
}

formatterCliente = (x: { razonSocial: string, ctA_SGC: string }) => `${x.razonSocial} - ${x.ctA_SGC == null ? 'SIN CTA SGC' : x.ctA_SGC}`;

selectCliente(event: any): void {
  this.clienteFormControl.setValue(event.item);
}

nuevoCliente(clientText): void {
  const modalRef = this.modalService.open(NuevoClienteComponent);
  if (typeof (clientText) === 'string') {
    modalRef.componentInstance.nombreCliente = clientText; // should be the id
  }
  modalRef.result.then((cliente) => {
    this.clientes.push(cliente);
    this.clienteFormControl.setValue(cliente);
  }).catch((error) => {
    this.clienteFormControl.setValue(null);
  });
}

/** Input vehiculo o automotor */

//setea el aerocombustible de acuerdo a la aeronave seleccionada
changeVehiculo(): void {
  let encuentraVehiculo = this.vehiculoFormControl.value;
  //Esto es por si ingresan el valor abc123 pero existe una aeronave ABC123, entonces elige esta última
  if (typeof (this.vehiculoFormControl.value) === 'string') {
    encuentraVehiculo = this.todosLosVehiculos.filter(v => this.vehiculoHabilitado(v) ).find(p => p.matricula.toLowerCase().trim() == this.vehiculoFormControl.value.toLowerCase().trim());
  }

  //Si no encuentro una aeronave, ofrecemos crear una
  if (!encuentraVehiculo) {
    this.nuevoVehiculo(this.vehiculoFormControl.value);
  }else {
    this.vehiculoFormControl.setValue(encuentraVehiculo);
    this.verificarVehiculo();
  }
}

vehiculoHabilitado(v : VehiculoDTO){
  if(v.tipoVehiculo == TipoVehiculo.Aeronave){
    return true;
  }else{
    return false;
  }  
}

verificarVehiculo() {
  if (this.vehiculoFormControl.value) {
    if (this.clienteFormControl.value && this.clienteFormControl.value.id &&
      this.vehiculosDeCliente && this.vehiculosDeCliente.filter(value => value.matricula.toLowerCase().trim() === this.vehiculoFormControl.value.matricula.toLowerCase().trim()).length == 0) {
      const modalRef = this.modalService.open(ModalConfirmComponent, { centered: true });
      modalRef.componentInstance.mensaje = "El vehiculo no pertenece al cliente, ¿desea asignarlo de todas formas?"; // should be the id
      modalRef.result.then(() => {
      },
        () => {
          this.vehiculoFormControl.setValue(null);
        }
      );
    }
  }
}

nuevoVehiculo(matriculaText: any): void {
  const modalRef = this.modalService.open(ModalVehiculoComponent, { backdrop: "static", centered: true });
  if (typeof (matriculaText) === 'string') {
    modalRef.componentInstance.matriculaIngresada = matriculaText.toUpperCase();
  }
  modalRef.componentInstance.tipoVehiculoParaFiltrar = TipoVehiculo.Aeronave;
  modalRef.componentInstance.vehiculos = this.todosLosVehiculos;    
  modalRef.result.then((vehiculo) => {
    this.messageService.showSuccessMessage("Vehiculo creado");
    this.todosLosVehiculos.push(vehiculo);
    this.vehiculoFormControl.setValue(vehiculo);
  });
}

formatterVehiculo = (x: { matricula: string }) => x.matricula;

searchVehiculo = (text$: Observable<string>) =>
text$.pipe(
  debounceTime(200),
  distinctUntilChanged(),
  map(term => term.length < 2 || this.cargandoVehiculosDeCliente || isNullOrUndefined(this.vehiculosDeCliente) ? []
    : this.vehiculosDeCliente.filter(v => this.vehiculoHabilitado(v) && (v.matricula.toLowerCase().indexOf(this.vehiculoFormControl.value.toLowerCase()) > -1) ).slice(0, 10))
)

/** Validaciones */

controlVehiculoProducto() {
  if (this.abastecedoraFormControl.value instanceof Object && this.vehiculoFormControl.value instanceof Object && !this.cargandoAbastecedoras && !this.cargandoVehiculos) {      
    if(this.vehiculoFormControl.value.codigoProducto != this.abastecedoraFormControl.value.producto.codigoProducto)
      this.combustibleNoCoincide = true;
    else 
      this.combustibleNoCoincide = false;
  }
}

esCampoVacio(campo: any): boolean {
  return (campo == null || (campo != null && campo.length == 0));
}

validarDensidad(densidad) {
  if (densidad!=null){
    let productoSeleccionado = this.productos.find( p => p.codigoProducto===this.abastecedoraFormControl.value.producto.codigoProducto);
    let esValido = true;
    esValido = (densidad >= productoSeleccionado.densidadMin && densidad <= productoSeleccionado.densidadMax);
    this.densidadMin = productoSeleccionado.densidadMin;
    this.densidadMax = productoSeleccionado.densidadMax;
    return esValido;
  }
}

formatDensidad(event) {
  if (event.charCode == 8 || event.charCode == 0) return null;

  if (event.charCode >= 48 && event.charCode <= 57) {
    this.densidadFormControl.setValue(this.sharedService.formatDensidad(event));
  }
  return false;
}

temperaturaValidator(control: UntypedFormControl) {
  if (control.value > 100) {
    return { temperaturaFueraDeRango: true };
  }

  if (control.value <= -50) {
    return { temperaturaFueraDeRango: true };
  }

  return null;
}

cantidadDeCaracteres(n: any): number {
  if (n != null) {
    return n.length;
  }
  return 0;
}

obtenerUsuarioLogueado() {
  var u = new Usuario();
  u.dni = this.usuarioService.getDniUsuario();
  u.nombreCompleto = this.usuarioService.getNombresCompletosUsuario();
  u.userName = this.usuarioService.getUsername();
  u.tipoUsuario = TipoUsuario[this.usuarioService.getTipoUsuario()];

  return u;
}

obtenerUsuario(usuario: Operador) {
  var u = new Usuario();
  u.dni = usuario.dni
  //Se agrega a "nombreCompleto" el nombre del usuario logueado con el fin de que aparezca como responsable en la tabla de Movimientos 
  //u.nombreCompleto = this.usuarioService.getNombresCompletosUsuario();
  u.nombreCompleto = usuario.nombresCompletos;
  u.userName = usuario.userName;
  u.tipoUsuario = usuario.tipoUsuario;

  return u;
}

accionarForm() {
  Object.keys(this.aerovalesForm.controls).forEach(key => {
    this.aerovalesForm.get(key).markAsDirty();
  });
}

reemplazarAerovale(tipoAev:TipoAerovale) {

  this.messageService.clear();

  if (!this.validarDensidad(this.densidadFormControl.value)) {
    this.messageService.showErrorMessage("La densidad debe estar dentro del rango del producto. ["+ this.densidadMin +"-"+ this.densidadMax + "]");
    return;
  }  

  this.accionarForm();

  if (this.aerovalesForm.valid) {
    try {
      this.aerovaleCreadoCorrectamente = false;
      this.spinner.show();
      //Si pasa un minuto se esconde el cartel azul (spinner)
      setTimeout(() => {
        this.spinner.hide();
        this.guardandoAerovale = false;
        if (!this.aerovaleCreadoCorrectamente) {
          this.messageService.showErrorMessage("No se pudo procesar el aerovale, por favor vuelva a intentar");
        }        
      },  this.tiempo); // frontdoor
      let aerovale = new AerovaleDetalle();
  
      let cuentaCliente: CuentaCliente = this.clienteFormControl.value;
      let clienteDespacho: Cliente = new Cliente();
      clienteDespacho.numeroCuenta = cuentaCliente.ctA_SGC;
      clienteDespacho.razonSocial = cuentaCliente.razonSocial;
      clienteDespacho.tipoDocumento = cuentaCliente.tipoDocumento;
      clienteDespacho.nroDocumento = cuentaCliente.numeroDocumento;
      clienteDespacho.condicionIva = cuentaCliente.tipoCliente;
      clienteDespacho.tipoPago = cuentaCliente.tipoCuenta;
  
      aerovale.origen = this.aerovaleReemplaza.origen;
      aerovale.cliente = clienteDespacho;
      aerovale.tipoPago = this.tipoPagoFormControl.value;
      aerovale.estado = "Anulado";
      aerovale.aeroespecialidades = [];
      aerovale.remanentes = this.aerovaleReemplaza.remanentes;
      
      aerovale.fechaHoraLlegada = this.formatDate(
        this.fechaLlegadaFormControl.value.year, this.fechaLlegadaFormControl.value.month, this.fechaLlegadaFormControl.value.day,
        this.horaLlegadaFormControl.value.hour, this.horaLlegadaFormControl.value.minute);      
      
      aerovale.fechaHoraInicioDespacho = this.formatDate(
        this.fechaInicioAlijeFormControl.value.year, this.fechaInicioAlijeFormControl.value.month, this.fechaInicioAlijeFormControl.value.day,
        this.horaInicioAlijeFormControl.value.hour, this.horaInicioAlijeFormControl.value.minute);
      
      aerovale.fechaHoraFinDespacho = this.formatDate(
        this.fechaFinAlijeFormControl.value.year, this.fechaFinAlijeFormControl.value.month, this.fechaFinAlijeFormControl.value.day,
        this.horaFinAlijeFormControl.value.hour, this.horaFinAlijeFormControl.value.minute);
        
      aerovale.vehiculo = this.vehiculoFormControl.value;
  
      aerovale.operador = null;
      aerovale.supervisor = null;
      aerovale.operadorDTO = this.obtenerUsuario(this.operadorFormControl.value);
      aerovale.supervisorDTO = this.obtenerUsuarioLogueado();

      aerovale.volumen = this.cantidadAlijeFormControl.value;
      aerovale.producto = this.abastecedoraFormControl.value.producto;
      aerovale.abastecedora = this.abastecedoraFormControl.value;
  
      let detalle = new AerovaleDetalleAerocombustible();
        detalle.temperatura = this.temperaturaFormControl.value;
        detalle.densidad = this.densidadFormControl.value;
        detalle.pruebaAgua = true; // negativa
        detalle.aforadorInicio = this.aforadorInicialFormControl.value;
        detalle.aforadorFinal = this.aforadorFinalFormControl.value;
        detalle.volumen15Grados = this.cantidadAlije15FormControl.value;         
        if(typeof (this.picosAsociadosFormControl.value) === 'string') {
          detalle.picoId = this.picosAsociadosFormControl.value
        } else {
          detalle.picoId = this.picosAsociadosFormControl.value.id
        }   
        if(this.abastecedoraFormControl.value.tipoExpendedora == TipoExpendedora.SURTIDOR) {
          detalle.codigoTanque = this.tanquesAsociadosFormControl.value;
        }
        
      aerovale.detalleAerocombustible = detalle;
      
      let detalleAlije = new AerovaleDetalleAlije();
        detalleAlije.codigoLaboratorio = this.laboratorioFormControl.value.codigoLaboratorio;
        detalleAlije.estadoPruebaLaboratorio = EstadoPruebaLaboratorio.APROBADA;
        detalleAlije.fechaPruebaLaboratorio = this.formatDate(
          this.fechaPruebaLaboratorioFormControl.value.year, this.fechaPruebaLaboratorioFormControl.value.month,
          this.fechaPruebaLaboratorioFormControl.value.day, 23, 59);
        detalleAlije.laboratorioId = this.laboratorioFormControl.value.id;
        detalleAlije.varilladoInicio = this.varilladoInicialFormControl.value;
        detalleAlije.varilladoFinal = this.varilladoFinalFormControl.value;
      aerovale.detalleAerocombustible.aerovaleDetalleAlije = detalleAlije;

      aerovale.tipoAerovale = tipoAev;
      aerovale.servicios =  this.aerovaleReemplaza.servicios;
      aerovale.ciudadIdAerovale = this.aerovaleReemplaza.origen.ciudadId;
  
      console.log(JSON.stringify(aerovale));
      if(this.fileAerovaleFisico) {
        this.guardandoAerovale = true;
        const carga: CargaArchivo = new CargaArchivo(this.fileAerovaleFisico.name);
        this.stockService.obtenerSas(TipoBlobStorage.firmaoperador, true).subscribe((sas: Sas) => {
          let aborter = this.azureBlobUpload.uploadFileToBlobStorage(this.fileAerovaleFisico, sas.containerUri, sas.sas,
            (pro) => {
              carga.progress = pro;
            },
            (val: BlobUploadCommonResponse) => {
                if (val) {
                  carga.progress = 100;                  
                  aerovale.firmaOperador = "";
                  aerovale.firmaCliente = "";
                  aerovale.pathFirmaOperador = this.fileAerovaleFisico.name;
                  aerovale.pathFirmaCliente = this.fileAerovaleFisico.name;
                  
                  this.aerovaleService.putReemplazarAerovales(this.aerovaleReemplaza.id, aerovale)
                  .subscribe(respuesta => {
                    if (respuesta.warnings != null && respuesta.warnings.length > 0) {
                      for (let entry of respuesta.warnings) {                
                        this.messageService.showWarningMessage(entry, true);
                      }
                    }
                    if(respuesta) {
                      this.messageService.showSuccessMessage("El Aerovale fue reemplazo con éxito. Nro.: "+respuesta.prefijo+"-"+respuesta.numeroAerovale);
                      this.ocultarSpinner(true);
                      this.modal.close(true);
                    }                    
                  }, (err:ErrorModel) => {
                    this.messageService.showErrorMessage("Error al reemplazar el aev. " + err.description);
                    this.ocultarSpinner(false);
                  });
                }
            });  
            carga.setAborter(aborter);            
        },(error)=> {
          // si falla el obtenerSas
          this.messageService.showErrorMessage("No se pudo procesar el aerovale, por favor vuelva a intentar");
          this.ocultarSpinner(false);
        }); 
      }else{
        // al momento de abrir la modal valido el archivo fisico, pero cierro la llamada por las dudas
        this.messageService.showErrorMessage("El archivo no se capturo correctamente, vuelva intentarlo.");
        this.spinner.hide();
        this.ocultarSpinner(false);
      }       
    } catch (error) {
      this.messageService.showErrorMessage("Error en proceso "+error);
      this.ocultarSpinner(false);
    }
  }
}

ocultarSpinner(result:boolean) {
  this.guardandoAerovale = false;
  this.spinner.hide();
  result ? this.aerovaleCreadoCorrectamente = true : this.aerovaleCreadoCorrectamente = false;
}

 /** getters */

 get mostrarAbastecedora() {
  return (this.abastecedoraFormControl.value != null && this.abastecedoras.length>0);
}

 get numeroAerovaleFormControl() {
  return this.aerovalesForm.get('numeroAerovaleFormControl');
}

get clienteFormControl() {
  return this.aerovalesForm.get('clienteFormControl');
}

get operadorFormControl() {
  return this.aerovalesForm.get('operadorFormControl');
}

get vehiculoFormControl() {
  return this.aerovalesForm.get('vehiculoFormControl');
}

get productoFormControl() {
  return this.aerovalesForm.get('productoFormControl');
}

get tipoPagoFormControl() {
  return this.aerovalesForm.get('tipoPagoFormControl');
}

get tipoVueloFormControl() {
  return this.aerovalesForm.get('tipoVueloFormControl');
}

get destinoFormControl() {
  return this.aerovalesForm.get('destinoFormControl');
}

get laboratorioFormControl() {
  return this.aerovalesForm.get('laboratorioFormControl');
}

get fechaPruebaLaboratorioFormControl() {
  return this.aerovalesForm.get('fechaPruebaLaboratorioFormControl');
}

get fechaInicioAlijeFormControl() {
  return this.aerovalesForm.get('fechaInicioAlijeFormControl');
}
get horaInicioAlijeFormControl() {
  return this.aerovalesForm.get('horaInicioAlijeFormControl');
}
get fechaFinAlijeFormControl() {
  return this.aerovalesForm.get('fechaFinAlijeFormControl');
}
get horaFinAlijeFormControl() {
  return this.aerovalesForm.get('horaFinAlijeFormControl');
}

get resultadoPruebaLaboratorioFormControl() {
  return this.aerovalesForm.get('resultadoPruebaLaboratorioFormControl');
}
 
get varilladoInicialFormControl() {
  return this.aerovalesForm.get('varilladoInicialFormControl');
}
 
get varilladoFinalFormControl() {
  return this.aerovalesForm.get('varilladoFinalFormControl');
}

get aforadorInicialFormControl() {
  return this.aerovalesForm.get('aforadorInicialFormControl');
}

get aforadorFinalFormControl() {
  return this.aerovalesForm.get('aforadorFinalFormControl');
}

get cantidadAlijeFormControl() {
  return this.aerovalesForm.get('cantidadAlijeFormControl');
}

get cantidadAlije15FormControl() {
  return this.aerovalesForm.get('cantidadAlije15FormControl');
}

get temperaturaFormControl() {
  return this.aerovalesForm.get('temperaturaFormControl');
}
get densidadFormControl() {
  return this.aerovalesForm.get('densidadFormControl');
}

get fechaLlegadaFormControl() {
  return this.aerovalesForm.get('fechaLlegadaFormControl');
}

get horaLlegadaFormControl() {
  return this.aerovalesForm.get('horaLlegadaFormControl')
}

get abastecedoraFormControl() {
  return this.aerovalesForm.get('abastecedoraFormControl');
}

get tanquesAsociadosFormControl() {
  return this.aerovalesForm.get('tanquesAsociadosFormControl');
}

get picosAsociadosFormControl() {
  return this.aerovalesForm.get('picosAsociadosFormControl');
}

get tipoPicoFormControl() {
  return this.aerovalesForm.get('tipoPicoFormControl');
}

get resultadoPruebaDeAguaFormControl() {
  return this.aerovalesForm.get('resultadoPruebaDeAguaFormControl');
}


}
