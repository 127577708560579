<nav class="navbar navbar-light bg-light navbar-expand-lg">
  <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <ul class="navbar-nav">
    <!-- desde -->
    <li class="nav-item me-2">
      <div class="input-group">

        <input type="date" [ngModel]="model" class="fechaFormControlDesdeHasta" placeholder="aaaa-mm-dd"
          (ngModelChange)="model = $event" name="dt" ngbDatepicker #d="ngbDatepicker" value="model"
          class="form-control form-control-rounded" #searchDate required readonly>

        <div class="input-group-append">
          <button id="botonDesde" type="button" class="input-group-btn btn btn-secondary" (click)="d.toggle()">
            <i class="fa fa-calendar" aria-hidden="true"></i></button>
        </div>
      </div>
    </li>
    <!-- hasta -->
    <li class="nav-item">
      <div class="input-group">
        <input type="date" class="fechaFormControlDesdeHasta" [ngModel]="model1" placeholder="aaaa-mm-dd" name="dp"
          value="model1" (ngModelChange)="model1 = $event" class="form-control form-control-rounded" ngbDatepicker
          #h="ngbDatepicker" #searchDate required readonly>

        <div class="input-group-append">
          <button id="botonHasta" type="button" class="input-group-btn btn btn-secondary" (click)="h.toggle()"><i
              class="fa fa-calendar" aria-hidden="true"></i></button>
        </div>
      </div>
    </li>
    <li class="nav-item">
      <button class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2" type="submit" (click)="buscarDesdeHasta()"><i class="fa fa-search"
          aria-hidden="true"></i></button>
    </li>
  </ul>
</nav>

<div class="col col-md-12">
  <div class="spinner-container" *ngIf="esperandoCarga">
    <ngx-spinner name="spinnerGrafico" [ngStyle]="{'height': '400px', 'z-index':'0'}" bdOpacity=0.3bd
      Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
      <p style="color: white">C a r g a n d o . . . </p>
    </ngx-spinner>    
  </div>

<ngb-alert class="alert-warning mt-2" *ngIf="!esperandoCarga && this.cierresObtenidos.length == 0">No hay cierres de turno para la fecha solicitada.</ngb-alert>
  <!-- </form> -->
  <div class="row">
    <div class="col col-3">
      <div class="list-group py-3  menu-izq">
        <div *ngFor="let cierre of cierresObtenidos; let i=index">
          <button type="button" [class.active]="" class="list-group-item list-group-item-action">
            <div class="d-flex w-100 justify-content-between" (click)="mostrarDetalle(i)">
              <ul class="list-unstyled">
                <li>{{ this.obtenerFechaDeCierre(cierre) }}</li>
                <li>{{ cierre.responsable }} </li>
              </ul>
            </div>
          </button>
        </div>
      </div>
    </div>
    <div class="col col-9" *ngIf="this.cierreSeleccionado != null">
      <app-detalle-reporte-cierre [cierreSeleccionado]="cierreSeleccionado"></app-detalle-reporte-cierre>
    </div>
  </div>
</div>