<div class="modal-header bg-light">
  <h4 class="modal-title" class="text-center"><b>Clientes</b></h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()">
    
  </button>
</div>

<div class="modal-body">
<table *ngIf="this.clientes!=null && this.clientes.length > 0" class="table table-striped table-hover">
  <thead>
    <td class="text-center"><b>CTA SGC</b></td>
    <td class="text-center"><b>RAZÓN SOCIAL</b></td>
  </thead>
  <tbody>
    <tr *ngFor="let a of clientes; let i = index">
      <td class="text-center">{{  a.cta_SGC != null?a.cta_SGC: "--" }}</td>
      <td class="text-center" >{{a.razonSocial != null?a.razonSocial: "--"}}</td>
   </tr>
  </tbody>
</table>
</div>





