import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { AerovaleDetalleComponent } from 'src/app/despacho/aerovale-detalle/aerovale-detalle.component';
import { AerovalesService } from 'src/app/despacho/aerovales.service';
import { ExportExcelServiceService } from 'src/app/shared/exportar-excel/export-excel-service.service';
import { CierreTurno } from 'src/app/shared/models/cierreTurno/CierreTurno';
import { MovimientoAforador } from 'src/app/shared/models/cierreTurno/MovimientoAforador';
import { AerovaleDetalle } from 'src/app/shared/models/despacho/AerovaleDetalle';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { AerovaleNumeroPipe } from 'src/app/shared/pipes/aerovale-numero.pipe';
import { AerovalePrefijoPipe } from 'src/app/shared/pipes/aerovale-prefijo.pipe';
import { RemitoNumeroPipe } from 'src/app/shared/pipes/remito-numero.pipe';
import { RemitoPrefijoPipe } from 'src/app/shared/pipes/remito-prefijo.pipe';
import { MessageService } from 'src/app/shell/message.service';

@Component({
  selector: 'app-reporte-correlatividad-aforadores-cierre',
  templateUrl: './reporte-correlatividad-aforadores-cierre.component.html',
  styleUrls: ['./reporte-correlatividad-aforadores-cierre.component.css']
})
export class ReporteCorrelatividadAforadoresCierreComponent implements OnInit {
  @Input() reporteSeleccionado: number;
  @Input() cierreSeleccionado: CierreTurno;
  @Input() reporteCorrelatividad: MovimientoAforador[];
  @Output() termino: EventEmitter<boolean> = new EventEmitter();

  @Input()
  set ejecutarAforadores(b: boolean) {
    if (b) {
      this.exportar();
    }
    this.termino.emit(true);
  }

  cargando: boolean = false;
  expendedoras = [];

  /** Ver Aerovale */
  aerovaleDetalle: AerovaleDetalle;
  obteniendoAerovale: boolean = false;
  aerovaleSeleccionado: number = null;

  constructor(
    private spinner: NgxSpinnerService, 
    private activeModal: NgbModal,
    private messageService: MessageService, 
    private aerovaleService: AerovalesService,
    private excelService: ExportExcelServiceService,
    private aerovalePrefijoPipe: AerovalePrefijoPipe,
    private aerovaleNumeroPipe: AerovaleNumeroPipe,
    private remitoPrefijoPipe: RemitoPrefijoPipe,
    private remitoNumeroPipe: RemitoNumeroPipe) { }

  ngOnInit() {
    this.cargando = true;
    this.spinner.show('spinnerGrafico');
    for (let prod of this.reporteCorrelatividad) {
      if (!this.expendedoras.find(a => a.name == prod.codigoExpendedora + '-' + prod.nombreExpendedora + '-' + prod.nombrePico)) {
        this.expendedoras.push({
          name: prod.codigoExpendedora + '-' + prod.nombreExpendedora + '-' + prod.nombrePico,
          seleccionado: true
        })
      }
    }
    this.spinner.hide('spinnerGrafico');
    this.cargando = false;
  }

  seleccionarTodos(e){
    if(e.target.checked){
      for(let e of this.expendedoras){
        e.seleccionado = true;
      }
    } else {
      for(let e of this.expendedoras){
        e.seleccionado = false;
      }
    }
  }

  obtenerAerovale(id: string, i:number) {
    this.aerovaleDetalle = null;
    this.obteniendoAerovale = true;    
    this.aerovaleSeleccionado=i;
    this.aerovaleService.getAerovalePorIdyTipo(id)
      .subscribe(result => {
        this.aerovaleDetalle = result;           
        this.aerovaleSeleccionado= null;  
        this.obteniendoAerovale = false;
        if(this.aerovaleDetalle != null) {
          this.mostrarAerovale();
        }}, (err: ErrorModel) => {
          this.messageService.showErrorMessage('Hubo un error al obtener el Aerovale' + err.description);   
          this.aerovaleSeleccionado=null;    
          this.obteniendoAerovale = false;
      });
  }

  mostrarAerovale() {
    let modalRef = this.activeModal.open(AerovaleDetalleComponent, { size: "lg", centered: true })
    modalRef.componentInstance.aerovaleDetalle = this.aerovaleDetalle;  
  }

  arregloDistintoExp(dist: string): MovimientoAforador[] {
    let comp: MovimientoAforador[] = [];
    for (let p of this.reporteCorrelatividad) {
      if (p.codigoExpendedora + '-' + p.nombreExpendedora + '-' + p.nombrePico == dist) {
        // comp.push(new Comparativa());
        comp.push(p)
      }
    }
    return comp
  }

  armarArrayExcel() {
    var listaReporte: ReporteCorrelatividadAforadorCierre[] = [];

    for (let r of this.reporteCorrelatividad) {
      var item = new ReporteCorrelatividadAforadorCierre();
      item.contenedor = r.codigoExpendedora + "-" + r.nombreExpendedora;
      item.producto = r.codigoProducto + "-" + r.nombreProducto;
      item.fecha = r.fechaHora;
      item.titulo = r.titulo;
      item.nombreAforador = r.nombrePico;
      item.aforadorInicial = r.aforadorInicial;
      item.aforadorFinal = r.aforadorFinal;

      if(r.comprobante == null){
        item.comprobante = "-";
      }else{
        if(r.comprobante.remito != null){ 
          item.comprobante = "AEV-" + this.aerovalePrefijoPipe.transform(r.comprobante.remito.aerovalePrefijo)+"-"+this.aerovaleNumeroPipe.transform(r.comprobante.remito.aerovaleNumero);
        }else {
          item.comprobante = "-";
        }
      } 

      listaReporte.push(item)
    }

    return listaReporte;
  }

  exportar() {
    this.excelService.exportAsExcelFile(this.armarArrayExcel(), 'Reporte movimientos cierre');
  }
}
export class ReporteCorrelatividadAforadorCierre {
  contenedor: string;
  producto: string;
  fecha: string;
  titulo: string;
  nombreAforador: string;
  aforadorInicial: number;
  aforadorFinal: number;
  comprobante: string;
}

