  <table class="table table-striped" *ngIf="!(cargandoContenedores || cargandoProductos)">
    <thead>
      <tr>
        <th scope="col">Tipo</th>
        <th scope="col">Nombre/Patente</th>
        <th scope="col">Producto</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let c of contenedores">
        <td>{{ this.getDescripcionTipoContenedor(c.tipoContenedor) }}</td>
        <td>{{ c.codigo }} | {{ c.nombre }}</td>
        <td>
          <select (change)="onProductoSeleccionado($event.target, c)" id="productoFormControl" class="form-select" ngbTooltip="Seleccione un producto." [(ngModel)]="c.productoActual" [compareWith]="compararProductos">
            <ng-container  *ngFor="let p of productos">
              <option [ngValue]="p">
                {{ p.codigoProducto }} {{ p.nombreProducto }}
              </option>
            </ng-container>
        </select>
        </td>
      </tr>
    </tbody>
  </table>

<div *ngIf="cargandoContenedores || cargandoProductos" class="position-relative" style="height: 100px">
  <ngx-spinner name="spinnerLista" bdOpacity=0.3bd Color="rgba(193,193,193,0)" size="medium" color="#185db5"
    type="ball-grid-beat" [fullScreen]="false">
    <p style="color: white"> C a r g a n d o . . . </p>
  </ngx-spinner>
</div>
