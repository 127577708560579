<ul class="nav nav-tabs justify-content-start">
  <li class="nav-item" *ngIf="this.hasRole(TipoUsuario.COMERCIAL, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.SUPERVISOR, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.OPERADOR, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.APROBADORBYR, TipoUsuario.ADMCLIENTES, TipoUsuario.IMPUESTOS, TipoUsuario.SEGURIDADTABLAS, TipoUsuario.FACTURACION, TipoUsuario.REFACTURACION, TipoUsuario.CONSPRECIOS, TipoUsuario.CONSAEROVALES, TipoUsuario.SOPORTE)">
    <a class="nav-link" routerLink="reporteRemitos" routerLinkActive="active"><b>Reporte remitos</b></a>
  </li>
  <li class="nav-item" *ngIf="this.hasRole(TipoUsuario.COMERCIAL, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.SUPERVISOR, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.OPERADOR, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.APROBADORBYR, TipoUsuario.ADMCLIENTES, TipoUsuario.IMPUESTOS, TipoUsuario.SEGURIDADTABLAS, TipoUsuario.FACTURACION, TipoUsuario.REFACTURACION, TipoUsuario.CONSPRECIOS, TipoUsuario.CONSAEROVALES, TipoUsuario.SOPORTE)">
    <a class="nav-link" routerLink="reporteFacturas" routerLinkActive="active"><b>Reporte facturas</b></a>
  </li>
  <li class="nav-item" *ngIf="this.hasRole(TipoUsuario.COMERCIAL, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.SUPERVISOR, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.OPERADOR, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.APROBADORBYR, TipoUsuario.ADMCLIENTES, TipoUsuario.IMPUESTOS, TipoUsuario.SEGURIDADTABLAS, TipoUsuario.FACTURACION, TipoUsuario.REFACTURACION, TipoUsuario.CONSPRECIOS, TipoUsuario.CONSAEROVALES, TipoUsuario.SOPORTE)">
    <a class="nav-link" routerLink="reporteRemitosVencidos" routerLinkActive="active"><b>Reporte remitos vencidos</b></a>
  </li>
  <li class="nav-item" *ngIf="this.hasRole(TipoUsuario.COMERCIAL, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.SUPERVISOR, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.OPERADOR, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.APROBADORBYR, TipoUsuario.ADMCLIENTES, TipoUsuario.IMPUESTOS, TipoUsuario.SEGURIDADTABLAS, TipoUsuario.FACTURACION, TipoUsuario.REFACTURACION, TipoUsuario.CONSPRECIOS, TipoUsuario.CONSAEROVALES, TipoUsuario.SOPORTE)">
    <a class="nav-link" routerLink="buscarComprobantes" routerLinkActive="active"><b>Buscar Comprobante</b></a>
  </li>
  <li class="nav-item" *ngIf="this.hasRole(TipoUsuario.COMERCIAL, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.SUPERVISOR, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.OPERADOR, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.APROBADORBYR, TipoUsuario.ADMCLIENTES, TipoUsuario.IMPUESTOS, TipoUsuario.SEGURIDADTABLAS, TipoUsuario.FACTURACION, TipoUsuario.REFACTURACION, TipoUsuario.CONSPRECIOS, TipoUsuario.CONSAEROVALES, TipoUsuario.SOPORTE)">
    <a class="nav-link" routerLink="reporteEjecuciones" [queryParams]="{si: false}" routerLinkActive="active"><b>Ejecuciones</b></a>
  </li>
  <li class="nav-item" *ngIf="this.hasRole(TipoUsuario.COMERCIAL, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.SUPERVISOR, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.OPERADOR, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.APROBADORBYR, TipoUsuario.ADMCLIENTES, TipoUsuario.IMPUESTOS, TipoUsuario.SEGURIDADTABLAS, TipoUsuario.FACTURACION, TipoUsuario.REFACTURACION, TipoUsuario.CONSPRECIOS, TipoUsuario.CONSAEROVALES, TipoUsuario.SOPORTE)">
    <a class="nav-link" routerLink="reporteEjecucionesErrores" [queryParams]="{si: true}" routerLinkActive="active"><b>Ejecuciones con errores</b></a>
  </li>
</ul>
<router-outlet></router-outlet>