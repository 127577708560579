<nav class="navbar navbar-light bg-light navbar-expand-lg">
    <form class="form-inline flex-fill" id="formularioFiltros">
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <ul class="navbar-nav me-auto">
            <!--buscador -->
            <li class="nav-item ms-3">
                <div class="nav-item">                
                    <input id="busquedaFormControl" type="text" class="form-control" placeholder="Buscador"
                        [formControl]="busquedaFormControl" (input)="filtrarMovimientos()" />
                </div>
            </li>

            <!--boton producto-->
            <li class="nav-item ms-2">
                <div ngbDropdown autoClose="outside">
                    <button class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2" id="dropdownProductos"
                        ngbDropdownToggle>Productos</button>
                    <div ngbDropdownMenu aria-labelledby="dropdownProductos">
                        <div class="px-4 py-3">
                            <div *ngFor="let prod of productosParaCheckear; let i = index">
                                <div class="form-check">
                                    <input type="checkbox" name="productos+{{i}}" class="form-check-input"
                                        id="{{ 'dropdownCheck' + prod.nombre}}" [(ngModel)]="prod.seleccionado"
                                        (change)="filtrarMovimientos($event)">
                                    <label class="form-check-label text-nowrap" for="{{ 'dropdownCheck' + prod.nombre}}">
                                        {{prod.nombre}}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </li>

            <ng-container *ngIf="this.solapaRanchosDestino == TipoSolapaRancho.ListadoRanchoConsumidos">
            <!-- fecha de vigencias (SOLO PARA RANCHOS CONSUMIDOS)-->
                <li class="nav-item ms-2">
                    <div style="align-items: center;" class="input-group">   
                        <label class="me-2" >Vigente al</label>             
                        <input type="date" [ngModel]="modelDesde" class="fechaFormControlDesdeHasta" placeholder="aaaa-mm-dd"
                            (ngModelChange)="modelDesde = $event" name="dt" ngbDatepicker #d="ngbDatepicker" value="model"
                            class="form-control form-control-rounded" #id="ngbDatepicker" #searchDate required readonly>

                        <div class="input-group-append">
                            <button id="botonDesde" type="button" class="input-group-btn btn btn-secondary" (click)="toggleDatepicker(d)">
                                <i class="fa fa-calendar" aria-hidden="true"></i></button>
                        </div>
                    </div>
                </li>

                <li class="nav-item">
                    <button class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2"
                        (click)="filtrarMovimientosPorFecha()"><i class="fa fa-search" aria-hidden="true"></i>
                    </button>
                </li>
            </ng-container>

            <!--boton Modificar Lote (COMERCIAL)-->
            <!--<li class="nav-item" *ngIf="this.solapaRanchosDestino == TipoSolapaRancho.ListadoRanchoSolicitados">
                <button class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2" id="modificarLote" (click)="eliminarLote()">
                    Eliminar Lote</button>                
            </li>-->

            <!--boton exportar excel (COMERCIAL)-->
            <li class="nav-item">
                <button class="btn btn-outline-success my-2 my-lg-0 mx-lg-2" (click)="exportarListadoRanchosExcel()">
                    <i class="fa fa-file-excel-o p-1"></i>Exportar
                </button>
            </li>

            <li class="nav-item" *ngIf="this.solapaRanchosDestino == TipoSolapaRancho.ListadoRanchoConsumidos">
                <div class="form-check" style="margin-left: 2em; margin-top: 0.5em;">
                    <input type="checkbox" class="form-check-input" name="mostrarDesactivadosFormControl" 
                        id="mostrarDesactivadosFormControl" (change)="filtrarMovimientos()"
                        [(ngModel)]="this.mostrarDesactivado">
                    <label class="form-check-label" for="mostrarDesactivadosFormControl">Mostrar desactivados</label>
                </div>
            </li>
        </ul>
    </form>
</nav>

<div class="col container-fluid">
    
    <div class="spinner-container" *ngIf="obteniendoRanchos">
        <ngx-spinner name="spinnerLista" bdOpacity=0.3bd
            Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
            <p style="color: white"> C a r g a n d o . . . </p>
        </ngx-spinner>
    </div>

    <ngb-alert [dismissible]="false" *ngIf="!obteniendoRanchos && ranchosFiltrados!=null && ranchosFiltrados.length==0">
        No existen movimientos para la busqueda seleccionada
    </ngb-alert>

    <div class="table-responsive">
        <table class="table table-striped" *ngIf="this.ranchos!=null && ranchosFiltrados.length > 0 && !obteniendoRanchos">
            <thead class="text-center letra">                
                <th style="width: 8%;">Concatenado</th>
                <th style="width: 8%;" *ngIf="this.solapaRanchosDestino == TipoSolapaRancho.ListadoRanchoConsumidos">Nro.Rancho</th>
                <th style="width: 10%;">Cliente</th>
                <th style="width: 4%;">Aeroplanta</th>
                <th>Bandera</th>
                <th>Destino</th>
                <th style="width: 8%;">Producto</th>
                <th>Volumen</th>
                <th style="width: 5%;">F.Desde</th>
                <th style="width: 5%;">F.Hasta</th>
                <th>Moneda</th>
                <th>Precio</th>
                <th style="width: 4%;">Lote</th>
                <th>Cta. Fact.</th>
                <th style="width: 4%;">Se Factura</th>
                <th>FOB</th>
                <th style="width: 5%">Comentarios</th>
                <th style="width: 5%" *ngIf="this.solapaRanchosDestino == TipoSolapaRancho.ListadoRanchoConsumidos">Adjuntos</th>
                <th style="width: 5%;">País Facturación</th>
                <th *ngIf="this.solapaRanchosDestino == TipoSolapaRancho.ListadoRanchoConsumidos">Acción</th>
                <th>{{ this.solapaRanchosDestino == TipoSolapaRancho.ListadoRanchoConsumidos ? 'Saldo' : 'Acción' }} </th>            
            </thead>        
            <tbody *ngIf="this.ranchos!=null" 
                    id="scroll-infinito" 
                    infinite-scroll 
                    [infiniteScrollDistance]="distance"
                    [infiniteScrollThrottle]="throttle" 
                    [scrollWindow]="false"
                    [infiniteScrollDisabled]="this.obteniendoRanchosScroll || this.stopScroll" 
                    (scrolled)="onScrollRanchos()"
                    >
                    
                    <tr [ngClass]="{'remarcar': r.estado==TipoEstado.Anulado }"                 
                        *ngFor="let r of ranchosFiltrados; let i=index"
                        class="letra">          
                        <td style="width: 8%;" class="text-center">{{r.concatenado}}</td>      
                        <td class="text-center" style="width: 8%;" *ngIf="this.solapaRanchosDestino == TipoSolapaRancho.ListadoRanchoConsumidos">
                            {{r.codigoRancho}}
                        </td>
                        <td class="text-center" style="width: 10%;">{{r.cliente.razonSocial}}</td>
                        <td class="text-center" style="width: 4%;">{{r.aeroplanta.codigoAeroplanta}}</td>
                        <td class="text-center">{{r.codigoBandera}} - {{r.bandera}}</td>
                        <td class="text-center">{{r.destino.nombre}}</td>
                        <td class="text-center" style="width: 8%;">{{r.producto.codigoProducto}} - {{ obtenerDescripcionProducto(r.producto.codigoProducto) }}</td>
                        <td class="text-center">{{r.volumen | number: '1.0-0'}} LTS</td>
                        <td class="text-center" style="width: 5%;">{{r.fechaInicio | date: "dd'/'MM'/'y"}}</td>
                        <td class="text-center" style="width: 5%;">{{r.fechaVencimiento | date: "dd'/'MM'/'y"}}</td>
                        <td class="text-center">{{r.moneda}}</td>
                        <td class="text-center">{{r.precio | number: "1.5-5"}}</td>
                        <td class="text-center" style="width: 4%;">{{r.numeroLote}}</td>
                        <td class="text-center">{{r.ctA_ParaFacturar != "" ? r.ctA_ParaFacturar : "--"}}</td>
                        <td class="text-center" style="width: 4%;">
                            <i *ngIf="r.seFactura" class="fa fa-check-circle" aria-hidden="true"></i>
                            <div *ngIf="!r.seFactura">--</div>
                        </td>
                        <td class="text-center">{{r.fob }}</td>
                        <td style="width: 5%" class="text-center">
                            <ng-container *ngIf="r.comentarios">
                                <button
                                    class="btn btn-sm"
                                    [popoverTitle]="titulo"
                                    [ngbPopover]="contenido">
                                    <i ngbTooltip="Ver Comentarios" class="fa fa-eye" aria-hidden="true"></i>    
                                </button>
                            
                                <ng-template #titulo>Comentarios:</ng-template>
                                <ng-template #contenido>{{ r.comentarios }}</ng-template>
                            </ng-container>
                            <div *ngIf="!r.comentarios">--</div>
                        </td>
                        <td style="width: 5%" *ngIf="this.solapaRanchosDestino == TipoSolapaRancho.ListadoRanchoConsumidos" class="text-center">
                            <ng-container *ngIf="r.archivosAdjuntos.length >=1">
                                <button
                                    class="btn btn-sm"
                                    [popoverTitle]="titulo"
                                    [ngbPopover]="contenido"
                                    (click)="verAdjuntos(r.archivosAdjuntos)">
                                    <i ngbTooltip="Ver Adjuntos" class="fa fa-eye" aria-hidden="true"></i>    
                                </button>                        
                            </ng-container>
                            <div *ngIf="r.archivosAdjuntos.length === 0">--</div>
                        </td>
                        <td class="text-center" style="width: 5%;">
                            {{r.nombrePaisFacturacion }}
                        </td>
                        <td *ngIf="this.solapaRanchosDestino == TipoSolapaRancho.ListadoRanchoConsumidos">
                            <button
                                *ngIf="this.solapaRanchosDestino == TipoSolapaRancho.ListadoRanchoConsumidos && r.estado==TipoEstado.Aprobado"
                                [disabled]="!this.hasRole(TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.APROBADORBYR)"
                                class="btn btn-sm btn-danger" (click)="desactivarRanchoPorRanchoID(r)">
                                <span class="fa fa-pencil" ngbTooltip="Desactivar Rancho"></span>
                            </button>
                        </td> 
                        <td class="text-center">
                            <button *ngIf="this.solapaRanchosDestino == TipoSolapaRancho.SolicitudRancho"
                                class="btn btn-sm" [ngClass]="{'btn-success': r.volumen != r.volumenDisponible, 'btn-secondary': r.volumen == r.volumenDisponible}" (click)="asignarNumeroDeRancho(r)">
                                <i ngbTooltip="Asignar Numero de Rancho" class="fa fa-pencil" aria-hidden="true"></i>
                            </button>
                            <button
                                *ngIf="this.solapaRanchosDestino == TipoSolapaRancho.ListadoRanchoSolicitados && r.estado==TipoEstado.Pendiente"
                                class="btn btn-sm" [ngClass]="{'btn-success': r.volumen != r.volumenDisponible, 'btn-secondary': r.volumen == r.volumenDisponible}" (click)="eliminarRanchoPorRanchoID(r)">
                                <span class="fa fa-trash" ngbTooltip="Eliminar Rancho"></span>
                            </button>
                            <button style="margin-left: -0.7em;" *ngIf="this.solapaRanchosDestino == TipoSolapaRancho.ListadoRanchoConsumidos"
                                class="btn btn-sm my-2 my-lg-0" [ngClass]="{'btn-success': r.volumen != r.volumenDisponible, 'btn-secondary': r.volumen == r.volumenDisponible}" (click)="verBitacoraRancho(r)">
                                {{r.volumenDisponible}}
                                <span class="fa fa-eye" ngbTooltip="Ver la bitacora"></span>
                            </button>                    
                        </td>                    
                    </tr>
                <div style="justify-content: center;" *ngIf="this.obteniendoRanchosScroll" class="d-flex align-items-center text-primary">
                    <div class="spinner-border" role="status"></div><span>Cargando mas datos..</span>
                </div>              
            </tbody>                                
        </table>
    </div>
</div>