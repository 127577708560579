import { Pipe, PipeTransform } from '@angular/core';
import { CondicionVenta } from '../enums/condicionVenta';

@Pipe({
  name: 'nombreTipoComprobante'
})
export class NombreTipoComprobantePipe implements PipeTransform {

  transform(tipo:number, condicion?:CondicionVenta): string {
    switch (tipo) {
      case (1):
      case (6):
      case (19): {
        if (condicion == CondicionVenta.contado) {
          return 'FACTURA/RECIBO';
        } else {
          return 'FACTURA';
        }
      }
      case (2):
      case (7):
      case (20): {
        return 'NOTA DE DEBITO';
      }
      case (3):
      case (8):
      case (21): {
        return 'NOTA DE CREDITO';
      }
      case (9): {
        return 'REMITO';
      }
      case (99): {
        return 'Documento devolucion';
      }
      default: {
        return 'No encontrado'
      }
    }
  }
}
