import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { TipoNivelAprobacion } from 'src/app/shared/enums/tipoNivelAprobacion';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { NivelAprobacion } from 'src/app/shared/models/precios/NivelAprobacion';
import { UsuarioNivelAprobacion } from 'src/app/shared/models/precios/UsuarioNivelAprobacion';
import { MessageService } from 'src/app/shell/message.service';
import { PreciosService } from '../precios.service';
import { FormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbCalendar, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Usuario } from 'src/app/shared/models/despacho/Usuario';
import { Lote } from 'src/app/shared/models/precios/Lote';
import { ModalSeleccionarUsuarioComponent } from '../niveles-aprobacion/modal-seleccionar-usuario/modal-seleccionar-usuario.component';
import { PrecioLote } from 'src/app/shared/models/precios/PrecioLote';
import { TipoVenta } from 'src/app/shared/enums/tipoVenta';
import { CondicionVenta } from 'src/app/shared/enums/condicionVenta';
import { EstadoPrecio } from 'src/app/shared/models/precios/EstadoPrecio';
import { UsuarioService } from 'src/app/account/usuario.service';
import { TipoEstadoPrecio } from 'src/app/shared/enums/tipoEstadoPrecio';
import { UsuarioNiveles } from 'src/app/shared/models/precios/UsuarioNiveles';
import { TipoActivoPrecio } from 'src/app/shared/enums/tipoActivoPrecio';
import { ModalConfirmComponent } from 'src/app/shared/generic-modals/modal-confirm.component';
import { ModalCambiarFechaDesdeComponent } from './modal-cambiar-fecha-desde/modal-cambiar-fecha-desde.component';
import { TipoObservacionExcelPrecios } from 'src/app/shared/enums/tipoObservacionExcelPrecios';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { TipoPrecioRetroactivo } from 'src/app/shared/enums/TipoPrecioRetroactivo';
import { formatDate } from '@angular/common';
import { Precio } from 'src/app/shared/models/precios/Precio';
import { TipoCantidadPaginado } from 'src/app/shared/enums/TipoCantidadPaginado';
import { tipoNivelUsuarioSeparados } from 'src/app/shared/enums/tipoNivelUsuarioSeparados';
@Component({
  selector: 'app-lotes',
  templateUrl: './lotes.component.html',
  styleUrls: ['./lotes.component.css']
})
export class LotesComponent implements OnInit {

  enumTipoObservacionExcelPrecios = TipoObservacionExcelPrecios;
  enumCondicionVenta = CondicionVenta;
  TipoUsuario = TipoUsuario;

  lotes: Lote[];
  loteSeleccionado: Lote;
  precios: PrecioLote[];
  preciosFiltrados: PrecioLote[];

  noPuedeAprobar: boolean = false;
  fechaAhora: Date;
  fechaAhora2: string;

  error: string;
  cargandoLotes: boolean = true;
  cargandoPreciosDeLote: boolean = false;
  mensajeAccesoInsuficiente: string = null;

  searchForm = new UntypedFormGroup({
    busquedaFormControl: new UntypedFormControl(''),
  })

  usuarioApruebaRetroactivos: boolean;

  page = 1;
  pageSize = TipoCantidadPaginado.NORMAL;
  collectionSize = 0;
  actualizandoPrecios: boolean = false;
  errorPrecios: boolean = false;

  constructor(
    private preciosService: PreciosService,
    private spinner: NgxSpinnerService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private usuarioService: UsuarioService,
    private authService: AuthService,
    private calendar: NgbCalendar
  ) {

  }

  obtenerLotes() {
    if(this.authService.hasRole(TipoUsuario.PRECIOS)){
      this.getLotes(TipoPrecioRetroactivo.Todos);
      return;
    }

    if (this.usuarioApruebaRetroactivos) {
      this.getLotes(TipoPrecioRetroactivo.Retroactivos);
    } else {
      this.getLotes(TipoPrecioRetroactivo.NoRetroactivos);
    }
  }

  ngOnInit() {
    this.usuarioApruebaRetroactivos = this.usuarioPuedeAprobarPreciosRetroactivos();
    this.fechaAhora = new Date();
    this.obtenerLotes();
  }

  getLotes(tipoPrecio : TipoPrecioRetroactivo) {
    this.loteSeleccionado = null;
    this.precios = [];
    this.preciosFiltrados = [];
    this.cargandoLotes = true;
    this.spinner.show("spinnerLotes");

    this.preciosService.getLotes(tipoPrecio)
      .subscribe(result => {
       
        this.lotes = result;
        this.lotes.sort((a , b) => a.numeroLote > b.numeroLote ? 1 : -1) ;

        //this.lotes.forEach(l => {if(l.responsable && l.responsable.indexOf(',') > -1) l.responsable = JSON.parse(l.responsable.replace(/'/g,'"')).nombresCompletos});

        this.spinner.hide('spinnerLotes');
        this.cargandoLotes = false;
      },
        (err: ErrorModel) => {
          this.error = err.description + ' ' + err.message;
          this.spinner.hide('spinnerLotes');
          this.cargandoLotes = false;
          this.errorPrecios = true;
        });
  }

  mostrarDetalleLote(index) {
    this.mensajeAccesoInsuficiente = null;
    this.loteSeleccionado = this.lotes[index];

    if (this.usuarioPuedeAccederLote(this.loteSeleccionado)) {
      this.page = 1;
      this.getPreciosPorLote(this.loteSeleccionado.id, this.page, this.pageSize);
    } else {
      this.loteSeleccionado = null;
      this.precios = [];
    }
  }

  formatDate(year, month, day, hour, minute): string {
    return year + '-' + month.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + '-' + day.toLocaleString(undefined, { minimumIntegerDigits: 2 })
      + 'T' + hour.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + minute.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':00';
  }

  formatFullDate(year, month, day, hour, minute, seconds): string {
    return year + '-' + month.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + '-' + day.toLocaleString(undefined, { minimumIntegerDigits: 2 })
      + 'T' + hour.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + minute.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + seconds.toLocaleString(undefined, { minimumIntegerDigits: 2 });
  }

  getPreciosPorLote(loteId, page: number, size: number, actualizacion?: boolean) {
    console.log("el id del lote es " + loteId);    

    if (actualizacion){
      this.cargandoPreciosDeLote = false;
      this.actualizandoPrecios = true;
    }else{
      this.cargandoPreciosDeLote = true;
    }
      
    this.preciosService.getPreciosPorLotePaginados(page, size, loteId)
      .subscribe(pageList => {
        if (actualizacion){
          this.actualizandoPrecios = false;
        }else{
          this.cargandoPreciosDeLote = false;
        }

        this.page = pageList.pageNumber;
        this.pageSize = pageList.pageSize;
        this.collectionSize = pageList.totalItemCount;
        this.precios = pageList.items;
       
        this.filtrarPrecios();
        this.cargandoPreciosDeLote = false;
      },
        (err: ErrorModel) => {
          this.precios = [];
          this.error = err.description;
          if (actualizacion){
            this.actualizandoPrecios = false;
          }else{
            this.cargandoPreciosDeLote = false;
          }
        });
  }

  filtrarPrecios() {
    if (this.preciosFiltrados != null) {
      this.preciosFiltrados = this.precios.filter(p => {
        let agregar = true;

        if (this.busquedaFormControl.value) {
          if (p.cliente != null) {
            agregar = p.cliente.razonSocial && p.cliente.razonSocial.toLocaleLowerCase().indexOf(this.busquedaFormControl.value.toLocaleLowerCase()) > -1;
          } else {
            agregar = false;
          }

          agregar = agregar || (p.nombreProducto && p.nombreProducto.toLocaleLowerCase().indexOf(this.busquedaFormControl.value.toLocaleLowerCase()) > -1);
          agregar = agregar || (p.codigoProducto && p.codigoProducto.toLocaleLowerCase().indexOf(this.busquedaFormControl.value.toLocaleLowerCase()) > -1);
          agregar = agregar || (p.precioContado.toString() && p.precioContado.toString().toLocaleLowerCase().indexOf(this.busquedaFormControl.value.toLocaleLowerCase()) > -1);
          agregar = agregar || (p.codigoAeroplanta && p.codigoAeroplanta.toLocaleLowerCase().indexOf(this.busquedaFormControl.value.toLocaleLowerCase()) > -1);
        }

        return agregar;
      });
    }
  }

  usuarioPuedeAccederLote(lote: Lote) {

    if(this.authService.hasRole(TipoUsuario.PRECIOS)){
      return true;
    }

    var usuarioNiveles = this.preciosService.getNivelUsuarioLogueado();

    console.log("NATIVO: " + usuarioNiveles?.nivelNativo?.maximoVariacion);
    console.log("MAX LOTE: " + Math.abs(lote.variacionMaxima));

    if (this.usuarioApruebaRetroactivos) {
      return true;
    }

    if(usuarioNiveles?.nivelNativo != null)
    {
      if (usuarioNiveles && usuarioNiveles.nivelNativo.maximoVariacion == 100){
        return true;
      }
  
      if (usuarioNiveles && usuarioNiveles.nivelNativo.maximoVariacion >= Math.abs(lote.variacionMaxima)) {
        return true;
      }
    }
    

    var maxVariacionDelegado = 0;

    if (usuarioNiveles) {
      for (let nivelDelegado of usuarioNiveles.nivelesDelegados) {

        if(nivelDelegado.maximoVariacion == 100){
          return true;
        }

        if (nivelDelegado.maximoVariacion >= Math.abs(maxVariacionDelegado)) {
          maxVariacionDelegado = nivelDelegado.maximoVariacion;
        }
      }
    }

    if (maxVariacionDelegado >= lote.variacionMaxima) {
      return true;
    }

    var masimaVAriacionNativa = usuarioNiveles?.nivelNativo ? usuarioNiveles.nivelNativo.maximoVariacion : 0;
    var maximaVariacionUsuario = masimaVAriacionNativa > maxVariacionDelegado ? masimaVAriacionNativa : maxVariacionDelegado;

    this.mensajeAccesoInsuficiente = "El lote "+ this.loteSeleccionado.numeroLote +" tiene una variación del "+ this.loteSeleccionado.variacionMaxima +"%, y su nivel tiene una variación máxima del "+ maximaVariacionUsuario +"%. No puede seleccionar el lote.";

    return false;
  }

  esPrecioRetroactivo(precio: Precio) {
    // {{precio.fechaDesde < loteSeleccionado.fechaPublicacion}}
    let fechaDesde = new Date(precio.fechaDesde);
    let fechaPublicacion = new Date(this.loteSeleccionado.fechaPublicacion);

    if (fechaDesde < fechaPublicacion) {
      return true;
    }

    return false;
  }

  delegarNivel() {
    let modalRef = this.modalService.open(ModalSeleccionarUsuarioComponent, { centered: true });
    modalRef.componentInstance.esDelegacion = true;
    modalRef.componentInstance.nivelAprobacion = this.preciosService.getNivelUsuarioLogueado() != null ? this.preciosService.getNivelUsuarioLogueado().nivelNativo : null;
    modalRef.result.then((result: Usuario) => {
      
    }, () => { });
  }

  eliminarLote() {

    if (this.loteSeleccionado == null) {
      this.messageService.showErrorMessage("Debe seleccionar un lote para eliminarlo.");
      return;
    }

    if (!this.authService.hasRole(TipoUsuario.PRECIOS)) {
      this.messageService.showErrorMessage("Solo usuarios con rol PRECIOS pueden eliminar lotes.");
      return;
    }

    const modalRef = this.modalService.open(ModalConfirmComponent, { centered: true });
    modalRef.componentInstance.titulo = "Se eliminará el lote " + this.loteSeleccionado.numeroLote;
    modalRef.componentInstance.mensaje = "¿Está seguro?";
    modalRef.componentInstance.textoSi = "Si";
    modalRef.componentInstance.textoNo = "Cancelar";
    modalRef.result.then(() => {
      this.cargandoLotes = true;
      this.spinner.show("spinnerLotes");

      this.preciosService.eliminarLote(this.loteSeleccionado.id.toString())
        .subscribe(() => {
          this.cargandoLotes = false;
          this.spinner.hide("spinnerLotes");
          this.messageService.showSuccessLongMessage("Se elimino el lote seleccionado");
          this.obtenerLotes();
        },
          (err: ErrorModel) => {
            this.cargandoLotes = false;
            this.spinner.hide("spinnerLotes");
            this.messageService.showErrorMessage("Error al eliminar el lote.");
          });
    });

  }

  aprobarPrecios() {
    if (this.loteSeleccionado == null) {
      this.messageService.showErrorMessage("Debe seleccionar un lote para aprobar.");
      return;
    }

    var fecha = new Date();
    var stringFecha: string = this.formatFullDate(fecha.getUTCFullYear(), fecha.getMonth() + 1, fecha.getDate(), fecha.getHours(), fecha.getMinutes(), fecha.getSeconds());
    
    if(!this.usuarioApruebaRetroactivos){
      this.cargandoLotes = true;
      this.spinner.show("spinnerLotes");

      this.preciosService.obtenerCantidadPreciosRetroactivosPorLote(this.loteSeleccionado.id, stringFecha)
      .subscribe(cantidadRetroactivos => {
        this.cargandoLotes = false;
        this.spinner.hide("spinnerLotes");

        if(cantidadRetroactivos > 0){
          this.messageService.showErrorMessage("El lote posee precios cuya fecha de inicio es menor a la fecha actual, no se puede aprobar.");
          return;
        }
        
        this.accionAprobarPrecios();

      }, (err) => {
        this.cargandoLotes = false;
        this.spinner.hide("spinnerLotes");
        this.messageService.showErrorMessage("Error al obtener cantidad registros retroactivos del lote. " + err.message + err.description);
      });      
    }else{
      this.accionAprobarPrecios();
    }

    if(!this.usuarioApruebaRetroactivos && this.lotePoseeAlgunPrecioRetroactivo()){
      this.messageService.showErrorMessage("El lote posee un precio retroactivo y no se puede aprobar.");
      return;
    }    
  }

  accionAprobarPrecios(){
    const modalRef = this.modalService.open(ModalConfirmComponent, { centered: true });
    modalRef.componentInstance.titulo = "Se aprobarán todos los precios del lote: " + this.loteSeleccionado.numeroLote;
    modalRef.componentInstance.mensaje = "¿Está seguro?";
    modalRef.componentInstance.textoSi = "Si";
    modalRef.componentInstance.textoNo = "Cancelar";
    modalRef.result.then(() => {
      this.cargandoLotes = true;
      this.spinner.show("spinnerLotes");
  
      var estado = new EstadoPrecio();
      estado.responsable = this.preciosService.obtenerStringResponsableV2();
      estado.activo = TipoActivoPrecio.Activo;
  
      if (this.usuarioApruebaRetroactivos) {
        estado.estadoPrecio = TipoEstadoPrecio.AprobadoRetroactivo;
      } else {
        estado.estadoPrecio = TipoEstadoPrecio.Aprobado;
      }
      var fecha = new Date();
      estado.fechaHora = this.formatDate(fecha.getFullYear(), fecha.getMonth() + 1, fecha.getDate(), fecha.getHours(), fecha.getMinutes());
  
      this.preciosService.cambiarEstadoLote(estado, this.loteSeleccionado.id)
        .subscribe(result => {
          this.cargandoLotes = false;
          this.spinner.hide("spinnerLotes");
          this.messageService.showSuccessLongMessage("Se proceso la solicitud de aprobación de los precios del lote " + this.loteSeleccionado.numeroLote);
          this.obtenerLotes();
        },
          (err: ErrorModel) => {
            this.cargandoLotes = false;
            this.spinner.hide("spinnerLotes");
            this.messageService.showErrorMessage("Error al aprobar precios. " + err.message + err.description);
          });
    });     
  }

  rechazarPrecios() {

    if (this.loteSeleccionado == null) {
      this.messageService.showErrorMessage("Debe seleccionar un lote para rechazar.");
      return;
    }

    const modalRef = this.modalService.open(ModalConfirmComponent, { centered: true });
    modalRef.componentInstance.titulo = "Se rechazarán todos los precios del lote: " + this.loteSeleccionado.numeroLote;
    modalRef.componentInstance.mensaje = "¿Está seguro?";
    modalRef.componentInstance.textoSi = "Si";
    modalRef.componentInstance.textoNo = "Cancelar";
    modalRef.result.then(() => {
      this.cargandoLotes = true;
      this.spinner.show("spinnerLotes");
  
      var estado = new EstadoPrecio();
      estado.responsable = this.preciosService.obtenerStringResponsableV2();
      estado.activo = TipoActivoPrecio.Activo;
      estado.estadoPrecio = TipoEstadoPrecio.Rechazado;
  
      this.preciosService.cambiarEstadoLote(estado, this.loteSeleccionado.id)
        .subscribe(result => {
          this.cargandoLotes = false;
          this.spinner.hide("spinnerLotes");
          this.messageService.showSuccessLongMessage("Se proceso la solicitud para rechazar los precios del lote " + this.loteSeleccionado.numeroLote);
          this.obtenerLotes();
        },
          (err: ErrorModel) => {
            this.cargandoLotes = false;
            this.spinner.hide("spinnerLotes");
            this.messageService.showErrorMessage("Error al rechazar precios. " + err.message + err.description);
          });
    });    
  }

  descripcionTipoVenta(tipoVenta) {
    if (tipoVenta == TipoVenta.cabotaje) {
      return "Cabotaje";
    } else if (tipoVenta == TipoVenta.internacional) {
      return "Internacional";
    } else {
      return "-";
    }
  }

  descripcionCondicionVenta(condicionVenta) {
    if (condicionVenta == CondicionVenta.contado) {
      return "Contado";
    } else if (condicionVenta == CondicionVenta.cuentaCorriente) {
      return "Cta. Cte.";
    } else {
      return "-";
    }
  }

  cambiarFechaDesde(precio: PrecioLote) {
    let modalRef = this.modalService.open(ModalCambiarFechaDesdeComponent, { centered: true, size: 'lg' });
    modalRef.componentInstance.precio = precio;
    modalRef.result.then(
      (precio: PrecioLote) => {
        this.messageService.showSuccessLongMessage("Se modifico la fecha desde correctamente.");
        this.getPreciosPorLote(this.loteSeleccionado.id, this.page, this.pageSize);
      },
      () => { }
    );
  }

  hasRole(...roles: TipoUsuario[]) {
    return this.authService.hasRole(...roles);
  }

  get busquedaFormControl() {
    return this.searchForm.get("busquedaFormControl");
  }

  usuarioPuedeAprobarPreciosRetroactivos() {

    try {
      var usuarioNiveles = this.preciosService.getNivelUsuarioLogueado();

      if(usuarioNiveles?.nivelNativo != null)
      {
        if (usuarioNiveles && usuarioNiveles.nivelNativo.apruebaRetroactivos) {
          return true;
        }
      }
      

      if (usuarioNiveles) {
        for (let nivelDelegado of usuarioNiveles.nivelesDelegados) {
          if (nivelDelegado.apruebaRetroactivos) {
            return true;
          }
        }
      }

      return false;
    } catch (ex) {
      console.log(ex);
      return false;
    }
  }

  usuarioPuedeDelegar() {
    try {
      var usuarioNiveles = this.preciosService.getNivelUsuarioLogueado();

      if(usuarioNiveles?.nivelNativo != null)
      {
        //Solo se puede delegar si tiene el nivel nativo de Precio
        if (usuarioNiveles && usuarioNiveles.nivelNativo.tipoNivel == tipoNivelUsuarioSeparados.Precio) {
          return true;
        }
      }

      return false;
    } catch (ex) {
      console.log(ex);
      return false;
    }
  }

  public onPageChange = (pageNumber) => {
    this.preciosFiltrados = [];
    this.getPreciosPorLote(this.loteSeleccionado.id, pageNumber, this.pageSize, true);
  }

  public lotePoseeAlgunPrecioRetroactivo(){
    // Llamar a backend consultando
    return false;
  }
}
