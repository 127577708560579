import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ExportExcelServiceService } from 'src/app/shared/exportar-excel/export-excel-service.service';
import { Comparativa } from 'src/app/shared/models/cierreTurno/Comparativa';

@Component({
  selector: 'app-modal-comparativa',
  templateUrl: './modal-comparativa.component.html',
  styleUrls: ['./modal-comparativa.component.scss']
})
export class ModalComparativaComponent implements OnInit {

  @Input() comparativas: Comparativa[];
  @Input() fechaCierre: string;
  @Input() titulo: string;

  constructor( public activeModal: NgbActiveModal, private excelService: ExportExcelServiceService ) { }

  ngOnInit(): void {

  }

  exportarComparativas(producto: string) { 
    this.excelService.exportAsExcelFile(this.armarArrayExcel(), 'Mediciones '+ producto);
  }
  
  armarArrayExcel() {
    let excelMovimiento: ExcelComparativa[] = [];

    this.comparativas = this.comparativas.sort((a, b) => a.nombreContenedor.localeCompare(b.nombreContenedor));

    for(var i=0; i < this.comparativas.length; i++){
      var item = new ExcelComparativa();
      item.nombreContenedor = this.comparativas[i].nombreContenedor;
      item.existenciaAnterior = this.comparativas[i].existenciaAnterior;
      item.entradas = this.comparativas[i].entradas;
      item.salidas = this.comparativas[i].salidas;
      item.existenciaLibros = this.comparativas[i].existenciaFinal;
      item.existenciaReal = this.comparativas[i].existenciaReal;
      item.diferencia = this.comparativas[i].diferencia;      
      excelMovimiento.push(item);
    }
    return excelMovimiento;
  }
}

export class ExcelComparativa {
  nombreContenedor: string;
  existenciaAnterior: number;
  entradas: number;
  salidas: number;
  existenciaLibros: number;
  existenciaReal: number;
  diferencia: number;
}