import { ViewChild } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BlobUploadCommonResponse } from '@azure/storage-blob';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StringValueToken } from 'html2canvas/dist/types/css/syntax/tokenizer';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, Subscription } from 'rxjs';
import { NotificacionesService } from 'src/app/abm/notificacion/notificaciones.service';
import { UsuarioService } from 'src/app/account/usuario.service';
import { VisorAerovalesComponent } from 'src/app/despacho/visor-aerovales/visor-aerovales.component';
import { ComprobanteService } from 'src/app/facturacion/comprobante.service';
import { MessageService } from 'src/app/shell/message.service';
import { StockService } from 'src/app/stock/stock.service';
import { environment } from 'src/environments/environment';
import { AgregarCuponComponent } from '../agregar-cupon/agregar-cupon.component';
import { AzureBlobUploadService } from '../azure-blob-upload.service';
import { CondicionIVA } from '../enums/condicionIVA';
import { TipoBlobStorage } from '../enums/TipoBlobStorage';
import { CargaArchivo } from '../models/cliente/CargaArchivo';
import { Aerovale } from '../models/despacho/Aerovale';
import { ErrorModel } from '../models/ErrorModel';
import { Cliente } from '../models/facturacion/Cliente';
import { Comprobante } from '../models/facturacion/Comprobante';
import { CuponPagoTarjeta } from '../models/facturacion/CuponPagoTarjeta';
import { EmitirFactura } from '../models/facturacion/EmitirFactura';
import { TipoTarjeta } from '../models/facturacion/TipoTarjeta';
import { Sas } from '../models/padrones/Sas';
import { NotificacionesComprobantes } from '../models/notificaciones/NotificacionesComprobantes';
import { SeleccionClienteFacturacionComponent } from '../seleccion-cliente-facturacion/seleccion-cliente-facturacion.component';
import { VisorComprobanteComponent } from '../visor-comprobante/visor-comprobante.component';

@Component({
  selector: 'app-importe-factura',
  templateUrl: './importe-factura.component.html',
  styleUrls: ['./importe-factura.component.css']
})
export class ImporteFacturaComponent implements OnInit {

  @Input() aerovaleAfacturar: Aerovale;

  calculandoImporte: boolean = false;

  importeTotal: number;

  facturando:boolean;
  enviandoMail:boolean;
  listaAerovales:string[]=[];
  emitirFactura: EmitirFactura;
  facturaId: string="";

  error: string;

  // Datos para verificar si se debe identificar el cliente.
  montoMaximoElectronico: number;
  montoMaximoEfectivo: number;
  clientePredeterminadoCtaSgc: string;
  clienteParaFacturar: Cliente;
  totalFactura: number;

  segundosEntreReintentos = 30;
  maximaCantidadReintentos = 3;
  reintentosRealizados = 0;
  mostrarEsperaEntreReintentosFacturacion: boolean;
  segundosRestantes: number;
  idInterval;

  mostrarMensajeFacturacionEnPruebas: boolean = false;

  importeFacturaForm = new UntypedFormGroup({
    listaCuponesFormControl: new UntypedFormControl(''),
    comentariosFormControl: new UntypedFormControl('',[Validators.required]),
    })

  cantidadArchivoSubidos: number = 0;
  private cantidadArchivoSubidos$ = new Subject<number>();
  private subscription: Subscription;
  private subirArchivos: boolean  = false;
  imagenes: File[] = [];
  mensaje: string = null;

  constructor(private messageService: MessageService,
    private modalService:NgbModal,
    public activeModal: NgbActiveModal,
    private comprobanteService: ComprobanteService,
    private notificacionesService: NotificacionesService,
    private spinner: NgxSpinnerService,
    private usuarioService: UsuarioService,
    private stockService: StockService,
    private azureBlobUpload: AzureBlobUploadService) { }

  ngOnInit() {
    this.reintentosRealizados = 0;
    this.segundosRestantes = this.segundosEntreReintentos;

    this.emitirFactura=new EmitirFactura();
    this.emitirFactura.cupones=[];
    this.listaAerovales.push(this.aerovaleAfacturar.id);
    this.obtenerImporteAFacturar();

    this.mostrarMensajeFacturacionEnPruebas = this.usuarioService.getAeroplantaActual().habilitarFacturacion && (! this.usuarioService.getAeroplantaActual().soloPAD);
    this.subscriptionUploadFile();
  }

  subscriptionUploadFile() {
    this.cantidadArchivoSubidos = 0;

    this.subscription = this.cantidadArchivoSubidos$.subscribe(()=>{
      this.cantidadArchivoSubidos++;
      if(this.cantidadArchivoSubidos==this.imagenes.length) {
        this.conectarConWSFacturacion();
      }
    });
  }

  modalAgregarCupon(){
    let modalRef = this.modalService.open(AgregarCuponComponent, {centered: true, backdrop: 'static'});
    modalRef.componentInstance.listaCupones=this.emitirFactura.cupones;
    modalRef.componentInstance.nuevoCupon.subscribe(result => {

      let cupon:CuponPagoTarjeta=new CuponPagoTarjeta();
      cupon.tarjetaId=result.tarjeta.id;
      cupon.tarjeta=result.tarjeta;
      cupon.numeroCupon=Number(result.numeroCupon);
      cupon.numeroLote=Number(result.numeroLote);
      cupon.numeroTarjeta=Number(result.numeroTarjeta);
      cupon.importe=Number(result.importe);
      cupon.pathCuponFisico=result.pathCuponFisico;
      
      var totalCupones = this.obtenerTotalEnCupones() + cupon.importe;
      if(totalCupones > this.importeTotal){
        this.error = "El ultimo cupon sobrepaso el total de la factura. Revisar los importes de los cupones.";

        return;
      }

      this.emitirFactura.cupones.push(cupon);
      this.imagenes.push(result._file);
    })
  }

  desasignarCupon(cupon) {
    const index: number = this.emitirFactura.cupones.indexOf(cupon);
    if (index !== -1) {
      this.emitirFactura.cupones.splice(index, 1);
      this.imagenes.splice(index, 1); // check delete file
    }
  }

  mostrarSpinner() {
    this.calculandoImporte = true;
    this.spinner.show('spinnerImporteFactura');
  }

  ocultarSpinner() {
    this.calculandoImporte = false;
    this.spinner.hide('spinnerImporteFactura');
  }

  obtenerImporteAFacturar()
  {    
    this.mostrarSpinner();
    this.comprobanteService.obtenerImporteAFacturar(this.listaAerovales)
         .subscribe(result => {
            this.ocultarSpinner();

            this.importeTotal = result.total;
            this.facturaId=result.facturaId;

            this.maximaCantidadReintentos = result.afipCantidadIntentosCAEA;
            this.segundosEntreReintentos = result.afipTiempoReintentoCAEA;

            this.montoMaximoElectronico = result.montoMaximoPagoElectronico;
            this.montoMaximoEfectivo = result.montoMaximoPagoContado;
            this.clientePredeterminadoCtaSgc = result.ctaSgc_ClienteCF;
            this.totalFactura = result.total;
          }, (err: ErrorModel) => {
            this.ocultarSpinner();
            this.messageService.showErrorMessage(err.message + " " + err.description);
            this.activeModal.close();
          } );
  }


  facturar()
  {
    if(this.seRequiereFacturarClienteEspecifico(this.obtenerTotalEnCupones(), this.totalFactura)){
      // Se muestra modal para identificar cliente.
      // Al volver el cliente, se llama a facturar agregando el cliente a emitirFactura.
      // Si no se identifica al cliente, no se asigna cliente en emitirFactura.
      let modalRef = this.modalService.open(SeleccionClienteFacturacionComponent, { centered: true, backdrop: 'static' });
      modalRef.result.then((cliente: Cliente) => {
        if(cliente){
          this.emitirFactura.cliente = cliente;
          this.prepararFaturacion();
        }
      });
    }else{
      this.prepararFaturacion();
    }
  }

  prepararFaturacion() {        
    if(this.imagenes.length>0) {
      let i=0;
      this.uploadFileBlobStorage(this.imagenes[i], i);
    } else {
      this.conectarConWSFacturacion();
    }
  }

  uploadFileBlobStorage(file: File, i: number) {    
    this.mensaje = `Subiendo ${i+1} archivo...`;
    const carga: CargaArchivo = new CargaArchivo(file.name);
    this.stockService.obtenerSas(TipoBlobStorage.files, false).subscribe((sas: Sas) => {
    let aborter = this.azureBlobUpload.uploadFileToBlobStorage(file, sas.containerUri, sas.sas,
      (pro) => {
        carga.progress = pro;
      },
      (val: BlobUploadCommonResponse) => {
        if (val) {
          carga.progress = 100;
          this.cantidadArchivoSubidos$.next();
          i++;
          if(this.imagenes[i]!=undefined) {
            this.uploadFileBlobStorage(this.imagenes[i], i);
          }        
        }
      });  
      carga.setAborter(aborter);            
    }, (error)=> {
      this.messageService.showErrorMessage("Error al obtener el SAS del blob storage.");
      this.mensaje = null;
    }); 
  }

  conectarConWSFacturacion(){
    this.mensaje = `Generando factura...`;
    this.subscription.unsubscribe();
    this.facturando=true;
    this.error = null;

    this.emitirFactura.observaciones = this.comentariosFormControl.value;
    this.comprobanteService.facturar(this.facturaId, this.emitirFactura)
      .subscribe(result => {
        setTimeout(()=>{
        }, 3000);
        this.facturando=false;
        this.mensaje = null;
        this.messageService.showSuccessMessage("Se facturo el aerovale correctamente.");
        //this.visorAerovales.ngOnInit();
        if(this.esElectrica) {
          this.reenviarCorreo(result)
        }else{
          this.activeModal.close();
          this.visorFactura(result);
        }
      }, (err: ErrorModel) => {

        this.facturando=false;
        this.mensaje = null;
        if(err.code == "50201"){
          if(this.reintentosRealizados < this.maximaCantidadReintentos){
            this.mostrarEsperaEntreReintentosFacturacion = true;

            this.idInterval = setInterval(() => {
              this.segundosRestantes--; 
            }, 1000);

            setTimeout(()=>{
              this.reintentosRealizados++;
              this.mostrarEsperaEntreReintentosFacturacion = false;              
              clearInterval(this.idInterval);
              this.segundosRestantes = this.segundosEntreReintentos;
              this.conectarConWSFacturacion();
            }, this.segundosEntreReintentos * 1000);
            return;
          }
        }

        this.error = err.message + " " + err.description;

       } );
  }

  visorFactura(comprobante:Comprobante)
  {
    this.modalService.dismissAll();
    const modalRef = this.modalService.open(comprobante, { centered: true, size: 'lg' });
    modalRef.componentInstance.facturaObtenida=comprobante;
  }

  reenviarCorreo(facturaObtenida: Comprobante){
    this.enviandoMail = true;
    const comprobanteNotificaciones = new NotificacionesComprobantes();
    console.log(facturaObtenida.emisor);
    comprobanteNotificaciones.facturaId = facturaObtenida.id;
    comprobanteNotificaciones.numeroDocumento = facturaObtenida.cliente.numeroDocumento;
    comprobanteNotificaciones.codigoAeroplanta = facturaObtenida.codigoAeroplanta;
    comprobanteNotificaciones.tipoMovimiento = facturaObtenida.tipoMovimiento;
    comprobanteNotificaciones.prefijo = facturaObtenida.prefijo;
    comprobanteNotificaciones.numero = facturaObtenida.numeroComprobante;
    this.notificacionesService.enviarFactura(comprobanteNotificaciones)
      .subscribe((result:any) => {
        this.messageService.showSuccessLongMessage('El comprobante se envió al correo: ' + result.item1);
        this.enviandoMail = false;
        this.activeModal.close();
      }, (err: ErrorModel) => {
        this.messageService.showErrorLongMessage(err.description);
        this.enviandoMail = false;
        this.activeModal.close();
      });
  }

  seRequiereFacturarClienteEspecifico(importeCupones, importeTotal){

    if(this.clienteParaFacturar != null) return false;

        if(this.aerovaleAfacturar.cliente.numeroCuenta == this.clientePredeterminadoCtaSgc){
            if(importeCupones > 0){
                if(this.montoMaximoElectronico == 0){
                    return false;
                }

                if(importeTotal > this.montoMaximoElectronico){
                    return true;
                }
            }else{
                if(this.montoMaximoEfectivo == 0){
                    return false;
                }

                if(importeTotal > this.montoMaximoEfectivo){
                    return true;
                }
            }
        }

        return false;
  }



  // getters

  obtenerTotalEnCupones(): number
  {
    let total:number=0;
    if (this.emitirFactura!=null && this.emitirFactura.cupones.length>0)
    {
      this.emitirFactura.cupones.forEach(function(a){total += Number(a.importe);});
    }
    return total;
  }

  obtenerTotalEnEfectivo():number
  {
    let total:number=0;

    total = this.importeTotal - this.obtenerTotalEnCupones();

    if (total<0){
      total=0;
    }

    return total;
  }

  obtenerCondicionIVA(c:number):string
  {
    return CondicionIVA[c];
  }

  ngOnDestroy() {
    if (this.idInterval) {
      clearInterval(this.idInterval);
    }
  }

  get comentariosFormControl() {
    return this.importeFacturaForm.get('comentariosFormControl');
  }

  get esElectrica() {
    return environment.electrica
  }

}

