import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MessageService } from 'src/app/shell/message.service';
import { TipoAerovale } from 'src/app/shared/enums/tipoAerovale';
import { AerovaleCombustible } from 'src/app/shared/models/despacho/AerovaleCombustible';
import { Cliente } from 'src/app/shared/models/despacho/Cliente';
import { AerovalesService } from '../aerovales.service';
import { UsuarioService } from 'src/app/account/usuario.service';
import { AerovaleAeroespecialidad } from 'src/app/shared/models/despacho/AerovaleAeroespecialidad';
import { AeroEspecialidadItem } from 'src/app/shared/models/despacho/AeroespecialidadItem';
import { AerovaleAlije, Servicio } from 'src/app/shared/models/despacho/AerovaleAlije';
import { UnidadMedida } from 'src/app/shared/enums/unidadMedida';
import { DecimalPipe } from '@angular/common';
import { Guid } from 'guid-typescript';
import { AerovaleCommon } from 'src/app/shared/models/despacho/AerovaleCommon';
import { Operador } from 'src/app/shared/models/despacho/Operador';
import { CuentaCliente } from 'src/app/shared/models/despacho/CuentaCliente';
import { CondicionIVA } from 'src/app/shared/enums/condicionIVA';
import { TipoPago } from 'src/app/shared/enums/tipoPago';
import { Subscription } from 'rxjs';
import { UsuarioAerovale } from 'src/app/shared/models/despacho/UsuarioAerovale';
import { ProductoService } from 'src/app/abm/producto/producto.service';
import { SharedService } from 'src/app/shared/shared.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { TipoProducto } from 'src/app/shared/enums/tipoProducto';
import { Producto } from 'src/app/shared/models/abm/Producto';
import { TipoServicio } from 'src/app/shared/enums/tipoServicio';

@Component({
  selector: 'app-nuevo-aerovale-v2',
  templateUrl: './nuevo-aerovale-v2.component.html',
  styleUrls: ['./nuevo-aerovale-v2.component.css'],
  providers: [DecimalPipe]
})
export class NuevoAerovaleComponentV2 implements OnInit {

  @Input() tipoAerovale: TipoAerovale;

  @Output() cancelado = new EventEmitter();
  @Output() nuevoAerovaleCreado = new EventEmitter();

  enumTipoAerovale = TipoAerovale;

  ciudadAerovaleSeleccionada: string;

  esValidaDensidad: boolean = true;
  esDensidadMin: any;
  esDensidadMax: any;
  provinciaFlag: boolean = true;
  ciudadFlag: boolean = true;

  aerovaleForm: UntypedFormGroup;
  commonFormValido: boolean;
  combustibleFormValido: boolean;
  aeroespecialidadFormValido: boolean;
  alijeFormValido: boolean;

  procesandoFirmaCliente: boolean;
  procesandoFirmaOperador: boolean;
  guardandoAerovale: boolean;

  firmaCliente: any;
  firmaOperador: any;

  change$: Subscription;

  productos: Producto[]=[];
  productoSeleccionado: Producto;

  objectCommonForm: UntypedFormGroup;
  objetCombustibleForm: UntypedFormGroup;
  objetAeroespecialidadForm: UntypedFormGroup;
  objetAlijeForm: UntypedFormGroup;

  productoServicioAlije: Producto = null;

  aerovaleCreadoCorrectamente: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private aerovaleService: AerovalesService,
    private usuarioService: UsuarioService,
    private messageService: MessageService,
    private decimalPipe: DecimalPipe,
    private changeDetector: ChangeDetectorRef,
    private productoService: ProductoService,
    private sharedService: SharedService,
    private spinner: NgxSpinnerService) { 
      this.change$ = sharedService.aeroplantaSeleccionada.subscribe(_=>{ 
        this.recargarPagina()
      });
  }

  // hooks

  ngOnInit() {
    this.getProductos();
    this.procesandoFirmaCliente = false;
    this.procesandoFirmaOperador = false;
    this.crearFormulario();
  }

  ngOnDestroy() {
    this.change$.unsubscribe();
  }

  recargarPagina() {
    this.aerovaleForm.reset();
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  crearFormulario() {
    this.aerovaleForm = this.formBuilder.group({
      aerovaleCommon: [],
      aerovaleCombustible: [],
      aerovaleAeroespecialidad: [],
      aerovaleAlije: [],
      firmaOperadorFormControl: [],
    });
  }

  cancelar() {
    this.cancelado.emit();
  }

  getDescripcionAerovale() {
    if (this.tipoAerovale == TipoAerovale.Aerocombustible) {
      return "Aerocombustible.";
    } else if (this.tipoAerovale == TipoAerovale.Combustible) {
      return "Combustible.";
    } else if (this.tipoAerovale == TipoAerovale.Aeroespecialidad) {
      return "Aeroespecialidad.";
    } else if (this.tipoAerovale == TipoAerovale.Alije) {
      return "Alije.";
    }
  }

  getProductos() {
    this.productoService.obtenerProductos()
      .subscribe(result => {
        this.productos = result;

        this.productoServicioAlije = this.existeProductoServicio();

        if(this.productoServicioAlije == null){
          this.messageService.showErrorMessage("No existe producto SERVICIO ALIJE, por lo que no se permite realizar un aerovale de alije.");
          return;
        }

      }, () => {

      });
  }

  existeProductoServicio(){
    return this.productos.find(p => p.tipoProducto == TipoProducto.Servicios && p.tipoServicio == TipoServicio.Alije);
  }

  accionarForm(){
    if (this.tipoAerovale == TipoAerovale.Aerocombustible) {

    } else if (this.tipoAerovale == TipoAerovale.Combustible) {
      this.accionarCommonForm();
      this.accionarCombustibleForm();
    } else if (this.tipoAerovale == TipoAerovale.Aeroespecialidad) {
      this.accionarCommonForm();
      this.accionarAeroespecialidadForm();
    } else if (this.tipoAerovale == TipoAerovale.Alije) {
      this.accionarCommonForm();
      this.accionarAlijeForm();
    }
  }

  obtenerObjectCommonForm(form){
    this.objectCommonForm = form;
  }

  obtenerObjectCombustibleForm(form){
    this.objetCombustibleForm = form;
  }

  obtenerObjectAeroespecialidadForm(form){
    this.objetAeroespecialidadForm = form;
  }

  obtenerObjectAlijeForm(form){
    this.objetAlijeForm = form;
  }

  accionarCommonForm(){
    Object.keys(this.objectCommonForm.controls).forEach(key => {
      this.objectCommonForm.get(key).markAsDirty();
    });
  }

  accionarAeroespecialidadForm(){
    Object.keys(this.objetAeroespecialidadForm.controls).forEach(key => {
      this.objetAeroespecialidadForm.get(key).markAsDirty();
    });
  }

  accionarCombustibleForm(){
    Object.keys(this.objetCombustibleForm.controls).forEach(key => {
      this.objetCombustibleForm.get(key).markAsDirty();
    });
  }

  accionarAlijeForm(){
    Object.keys(this.objetAlijeForm.controls).forEach(key => {
      this.objetAlijeForm.get(key).markAsDirty();
    });
  }

  onSubmit() {

    if (!this.datosValidos()) {
      this.accionarForm();
      this.messageService.showErrorMessage("Revise los campos incompletos");
      return;
    }
    
    if(!this.provinciaFlag){
      this.messageService.showErrorMessage("El valor de la provincia o la ciudad no puede ser vacio");
      return;
    }
    if(!this.ciudadFlag){
      this.messageService.showErrorMessage("El valor de la provincia o la ciudad no puede ser vacio");
      return;
    }
    if (!this.densidadValida() && this.tipoAerovale != TipoAerovale.Aeroespecialidad) {
      this.messageService.showErrorMessage("La densidad debe estar entre el rango [" + this.productoSeleccionado.densidadMin + "," + this.productoSeleccionado.densidadMax + "]");
      return;
    }

    if (this.firmaOperadorFormControl.value != null) {

      let extensionFirmaOperador: string = this.obtenerExtensionDeArchivo(this.firmaOperadorFormControl.value);


      if (!this.esFormatoImagen(extensionFirmaOperador)) {
        this.messageService.showErrorMessage("El formato del archivo de la firma del operador debe ser tipo imagen.");
        return;
      }
    }

    this.guardandoAerovale = true;

    if (this.tipoAerovale == TipoAerovale.Aerocombustible) {
      this.guardarAerovaleAerocombustible();
    } else if (this.tipoAerovale == TipoAerovale.Combustible) {
      this.guardarAerovaleCombustible();
    } else if (this.tipoAerovale == TipoAerovale.Aeroespecialidad) {
      this.guardarAerovaleAeroespecialidad();
    } else if (this.tipoAerovale == TipoAerovale.Alije) {
      this.guardarAerovaleAlije();
    }
  }

  guardarAerovaleAerocombustible() {

  }

  guardarAerovaleCombustible() {
    try{
        this.messageService.clear();

        this.aerovaleCreadoCorrectamente = false;

        //Si pasa un minuto se esconde el cartel azul (spinner)
        setTimeout(() => {
            this.spinner.hide();
            this.guardandoAerovale = false;
            if (!this.aerovaleCreadoCorrectamente) {
              this.messageService.showErrorMessage("No se pudo procesar el aerovale, por favor vuelva a intentar");
            }        
        }, 61000);

        let aerovale = new AerovaleCombustible();

        this.fillAerovaleCommon(aerovale);

        aerovale.automotor = this.aerovaleCommonForm.vehiculoFormControl;

        if (this.aerovaleCommonForm.tanquesAsociadosFormControl!=null){
          if(this.aerovaleCommonForm.abastecedoraFormControl.tipoExpendedora == 1){
            aerovale.codigoTanque = this.aerovaleCommonForm.tanquesAsociadosFormControl;
          }
        }
        if (this.aerovaleCommonForm.picosAsociadosFormControl!=null){
          aerovale.picoId = this.aerovaleCommonForm.picosAsociadosFormControl.id;
        }

        aerovale.codigoAbastecedora = this.aerovaleCommonForm.abastecedoraFormControl.codigo;

        aerovale.combustible = this.aerovaleCommonForm.abastecedoraFormControl.producto;

        // Aeroespecialidades no van en aev combustible
        //aerovale.aeroespecialidades = this.procesarAeroespecialidades();
        aerovale.aeroespecialidades = [];

        this.spinner.show();
        this.guardandoAerovale = true;

        this.aerovaleService.CrearAerovaleCombustible(aerovale)
          .subscribe(aerovaleRta => {
            if (aerovaleRta) {

              if (aerovaleRta.id != null) {

                aerovale.aforadorInicio = this.aerovaleCombustibleForm.aforadorInicialFormControl;
                aerovale.aforadorFinal = this.aerovaleCombustibleForm.aforadorFinalFormControl;
                aerovale.volumen = aerovale.aforadorFinal - aerovale.aforadorInicio;
                aerovale.Temperatura= this.aerovaleCombustibleForm.temperaturaFormControl;
                aerovale.Densidad = this.aerovaleCombustibleForm.densidadFormControl;
                aerovale.Volumen15grados = this.aerovaleCombustibleForm.volumen15GradosFormControl;

                if( this.firmaOperador){
                  aerovale.estado = "Finalizado";
                } else {
                  aerovale.estado = "FinalizadoSinFirma";
                }

                aerovale.id = aerovaleRta.id;

                this.aerovaleService.ActualizarAerovaleCombustible(aerovale, this.firmaOperador, this.firmaOperador)
                  .subscribe(modificado => {
                    if (modificado) {
                      this.nuevoAerovaleCreado.emit(aerovaleRta);
                      this.aerovaleForm.reset();

                      if (aerovaleRta.warnings != null && aerovaleRta.warnings.length > 0) {
                        for (let entry of aerovaleRta.warnings) {
                          this.messageService.showWarningMessage(entry, true);
                        }
                      }
                    }
                    this.aerovaleCreadoCorrectamente = true;
                    this.spinner.hide();
                  }, (error: ErrorModel)=>{
                    this.guardandoAerovale = false;
                    this.spinner.hide();
                    this.messageService.showErrorMessage("Se ha producido un error al actualizar el aerovale. " + error.description);
                  }
                  );
              }
            } else {
              this.guardandoAerovale = false;
              this.spinner.hide();
              this.messageService.showErrorMessage("Se ha producido un error al actualizar el aerovale.");
            }
          }, (error: ErrorModel)=>{
              this.guardandoAerovale = false;
              this.spinner.hide();
              this.messageService.showErrorMessage("Se ha producido un error al crear el aerovale. " + error.description);
            }
          );
    }catch(error){
      // si falla el armado de la clase del aerovale
      this.spinner.hide();
      this.guardandoAerovale = false;
      this.messageService.showErrorMessage("No se pudo procesar el aerovale, por favor vuelva a intentar");
    }
  }

  guardarAerovaleAeroespecialidad() {
    try{
      this.aerovaleCreadoCorrectamente = false;

       //Si pasa un minuto se esconde el cartel azul (spinner)
       setTimeout(() => {
        this.spinner.hide();
        this.guardandoAerovale = false;
        if (!this.aerovaleCreadoCorrectamente) {
          this.messageService.showErrorMessage("No se pudo procesar el aerovale, por favor vuelva a intentar");
        }        
      }, 61000);

      let aerovale = new AerovaleAeroespecialidad();

      this.fillAerovaleCommon(aerovale);

      aerovale.vehiculo = this.aerovaleCommonForm.vehiculoFormControl;

      aerovale.vuelo = this.aerovaleAeroespecialidadForm.vueloFormControl;

      this.spinner.show();
      this.guardandoAerovale = true;

      this.aerovaleService.CrearAerovaleAeroespecialidad(aerovale)
        .subscribe(aerovaleRta => {
          if (aerovaleRta) {

            aerovale.aeroespecialidades = this.procesarAeroespecialidades();
            
            if (aerovaleRta.id != null) {
              if( this.firmaOperador){
                aerovale.estado = "Finalizado";
              } else {
                aerovale.estado = "FinalizadoSinFirma";
              }

              aerovale.id = aerovaleRta.id;

              this.aerovaleService.ActualizarAerovaleAeroespecialidad(aerovale, this.firmaOperador, this.firmaOperador)
                .subscribe(modificado => {
                  this.nuevoAerovaleCreado.emit(aerovaleRta);
                  this.aerovaleForm.reset();

                if (aerovaleRta.warnings != null && aerovaleRta.warnings.length > 0) {
                  for (let entry of aerovaleRta.warnings) {
                    this.messageService.showWarningMessage(entry, true);
                  }
                }

                this.spinner.hide();
              }
              );
          }
          
        } else {
          this.guardandoAerovale = false;
          this.spinner.hide();
          this.messageService.showErrorMessage("Se ha producido un error al crear el aerovale");
        }
      });
    } catch(error){
      // si falla el armado de la clase del aerovale
      this.spinner.hide();
      this.guardandoAerovale = false;
      this.messageService.showErrorMessage("No se pudo procesar el aerovale, por favor vuelva a intentar");
    }
  }

  guardarAerovaleAlije() {
    if(this.productoServicioAlije == null){
      this.messageService.showErrorMessage("No existe producto SERVICIO ALIJE, por lo que no se permite realizar un aerovale de alije.");
      return;
    }

    try {
      this.aerovaleCreadoCorrectamente = false;

      //Si pasa un minuto se esconde el cartel azul (spinner)
      setTimeout(() => {
        this.spinner.hide();
        this.guardandoAerovale = false;
        if (!this.aerovaleCreadoCorrectamente) {
          this.messageService.showErrorMessage("No se pudo procesar el aerovale, por favor vuelva a intentar");
        }        
      }, 61000);

      let aerovale = new AerovaleAlije();

      this.fillAerovaleCommon(aerovale);

      aerovale.aeronave = this.aerovaleCommonForm.vehiculoFormControl;
      /*if (aerovale.aeronave.aerocombustible == null) {
        aerovale.aeronave.aerocombustible = Guid.EMPTY;
      }*/
    
      aerovale.codigoAbastecedora = this.aerovaleCommonForm.abastecedoraFormControl.codigo;
      aerovale.aerocombustible = this.aerovaleCommonForm.abastecedoraFormControl.productoActual;

      aerovale.laboratorioId = this.aerovaleAlijeForm.laboratorioFormControl;
      aerovale.fechaHoraLlegada = aerovale.fechaHoraInicioDespacho;
      aerovale.fechaHoraInicioDespacho = this.formatDate(this.aerovaleAlijeForm.fechaInicioAlijeFormControl.year,this.aerovaleAlijeForm.fechaInicioAlijeFormControl.month, this.aerovaleAlijeForm.fechaInicioAlijeFormControl.day, this.aerovaleAlijeForm.horaInicioAlijeFormControl.hour,this.aerovaleAlijeForm.horaInicioAlijeFormControl.minute);
      aerovale.fechaHoraFinDespacho = this.formatDate(this.aerovaleAlijeForm.fechaFinAlijeFormControl.year,this.aerovaleAlijeForm.fechaFinAlijeFormControl.month, this.aerovaleAlijeForm.fechaFinAlijeFormControl.day, this.aerovaleAlijeForm.horaFinAlijeFormControl.hour,this.aerovaleAlijeForm.horaFinAlijeFormControl.minute);
      aerovale.fechaHoraPruebaLaboratorio = this.formatDate(this.aerovaleAlijeForm.fechaPruebaLaboratorioFormControl.year, this.aerovaleAlijeForm.fechaPruebaLaboratorioFormControl.month, this.aerovaleAlijeForm.fechaPruebaLaboratorioFormControl.day, 0, 0);
      aerovale.codigoPruebaLaboratorio = this.decimalPipe.transform(aerovale.prefijo.toString(), '3.0-0') + this.decimalPipe.transform(aerovale.numeroAerovale.toString(), '5.0-0');
      aerovale.resultadoPruebaLaboratorio = this.aerovaleAlijeForm.resultadoPruebaDeAguaFormControl;
      aerovale.pruebaAgua = this.aerovaleAlijeForm.resultadoPruebaLaboratorioFormControl;

      aerovale.unidad = UnidadMedida.Litros;    

      if (this.aerovaleCommonForm.picosAsociadosFormControl!=null){
        aerovale.picoId = this.aerovaleCommonForm.picosAsociadosFormControl.id;
      }

      aerovale.temperatura = this.aerovaleAlijeForm.temperaturaFormControl;
      aerovale.densidad = this.aerovaleAlijeForm.densidadFormControl;

      aerovale.servicios = [new Servicio(1, this.productoServicioAlije.codigoProducto)];

        this.spinner.show();
        this.guardandoAerovale = true;

        this.aerovaleService.CrearAerovaleAlije(aerovale)
        .subscribe(aerovaleRta => {
          if (aerovaleRta) {

            if (aerovaleRta.id != null) {

              aerovale.aforadorInicio = this.aerovaleAlijeForm.aforadorInicialFormControl;
              aerovale.aforadorFinal = this.aerovaleAlijeForm.aforadorFinalFormControl;
              aerovale.varilladoInicial = this.aerovaleAlijeForm.varilladoInicialFormControl;
              aerovale.varilladoFinal = this.aerovaleAlijeForm.varilladoFinalFormControl;
              aerovale.volumenNaturales = aerovale.varilladoFinal - aerovale.varilladoInicial;
              aerovale.volumen15Grados = this.aerovaleAlijeForm.cantidadAlije15FormControl;

              if( this.firmaOperador){
                aerovale.estadoFinal = "Finalizado";
              } else {
                aerovale.estadoFinal = "FinalizadoSinFirma";
              }

              aerovale.id = aerovaleRta.id;
              
              this.aerovaleService.ActualizarAerovaleAlije(aerovale, this.firmaOperador, this.firmaOperador)
                .subscribe(modificado => {
                  this.nuevoAerovaleCreado.emit(aerovale);
                  this.aerovaleForm.reset();

                  if (aerovaleRta.warnings != null && aerovaleRta.warnings.length > 0) {
                    for (let entry of aerovaleRta.warnings) {
                      this.messageService.showWarningMessage(entry, true);
                    }
                  }
                  this.aerovaleCreadoCorrectamente = true;
                  //this.spinner.hide();
                }, (error)=> {
                  // si falla el PUT de aerovales
                  this.spinner.hide();
                  this.messageService.showErrorMessage("No se pudo procesar el aerovale, por favor vuelva a intentar");
                  this.guardandoAerovale = false;
                });
            }
          } else {
            this.guardandoAerovale = false;
            this.spinner.hide();
            this.messageService.showErrorMessage("Se ha producido un error al crear el aerovale");
          }
        }, (error)=> {
          // si falla el POST de aerovales
          this.spinner.hide();
          this.messageService.showErrorMessage("No se pudo procesar el aerovale, por favor vuelva a intentar");
          this.guardandoAerovale = false;        
        });
      } catch (error) {
        // si falla el armado de la clase del aerovale
          this.spinner.hide();
          this.guardandoAerovale = false;
          this.messageService.showErrorMessage("No se pudo procesar el aerovale, por favor vuelva a intentar");
      }    
  }

  procesarAeroespecialidades() {
    let listaAeroespecialidades = new Array<AeroEspecialidadItem>();

    if (this.aerovaleAeroespecialidadForm != null) {
      for (let i = 0; i < this.aerovaleAeroespecialidadForm.aeroespecialidadesSeleccionadasFormControlArray.length; i++) {
        let cantidad = this.aerovaleAeroespecialidadForm.aeroespecialidadesSeleccionadasFormControlArray[i].cantidad;
        let nombre = this.aerovaleAeroespecialidadForm.aeroespecialidadesSeleccionadasFormControlArray[i].nombreProducto;
        let codigo = this.aerovaleAeroespecialidadForm.aeroespecialidadesSeleccionadasFormControlArray[i].productoCodigo;

        let item = new AeroEspecialidadItem(cantidad, nombre, codigo);
        listaAeroespecialidades.push(item);
      }
    }

    return listaAeroespecialidades;
  }

  obtenerUsuarioLogueado() {
    var u = new UsuarioAerovale();
    u.dni = this.usuarioService.getDniOperador();
    u.nombreCompleto = this.usuarioService.getNombresOperador();
    u.userName = this.usuarioService.getUsername();

    return u;
  }

  obtenerUsuarioCargadoEnForm(usuario: Operador) {
    var u = new UsuarioAerovale();
    u.dni = usuario.dni
    u.nombreCompleto = usuario.nombresCompletos;
    u.userName = usuario.userName;

    return u;
  }

  fillAerovaleCommon(aerovale: AerovaleCommon) {

    let nroCompleto = this.aerovaleCommonForm.numeroAerovaleFormControl;
    nroCompleto = nroCompleto.padStart(8, '0');

    var cuentaCliente: CuentaCliente = this.aerovaleCommonForm.clienteFormControl;
    var clienteDespacho: Cliente = new Cliente();
    clienteDespacho.numeroCuenta = cuentaCliente.ctA_SGC;
    clienteDespacho.razonSocial = cuentaCliente.razonSocial;
    clienteDespacho.tipoDocumento = cuentaCliente.tipoDocumento;
    clienteDespacho.nroDocumento = cuentaCliente.numeroDocumento;
    clienteDespacho.condicionIva = CondicionIVA.ConsumidorFinal;
    clienteDespacho.tipoPago = TipoPago.Contado;

    aerovale.cliente = clienteDespacho;

    aerovale.operadorDto = this.obtenerUsuarioCargadoEnForm(this.aerovaleCommonForm.operadorFormControl);
    aerovale.supervisorDto = this.obtenerUsuarioLogueado();

    aerovale.prefijo = nroCompleto.substring(0, 3);
    aerovale.numeroAerovale = nroCompleto.substring(3, 8);
    aerovale.tipoPago = this.aerovaleCommonForm.tipoPagoFormControl;
    aerovale.codigoAeroplanta = this.usuarioService.getAeroplantaActual().codigoAeroplanta;
    aerovale.datos_App = "{\"estadoCronometro\":1,\"progresoUi\":1,\"ranchoGuid\":\"\"}"
    aerovale.estado = "DespachoIniciado";
    aerovale.syncronized = false;
    aerovale.observaciones = [];
    if (this.aerovaleCommonForm.observacionesFormControl != null) {
      aerovale.observaciones.push(this.aerovaleCommonForm.observacionesFormControl);
    }
    aerovale.comentarios = this.aerovaleCommonForm.comentariosFormControl;
    aerovale.ciudadIdAerovale = this.ciudadAerovaleSeleccionada;
    aerovale.fechaHoraInicioDespacho = this.formatDate(this.aerovaleCommonForm.fechaLlegadaFormControl.year, this.aerovaleCommonForm.fechaLlegadaFormControl.month, this.aerovaleCommonForm.fechaLlegadaFormControl.day, this.aerovaleCommonForm.horaLlegadaFormControl.hour, this.aerovaleCommonForm.horaLlegadaFormControl.minute);
    aerovale.fechaHoraFinDespacho = aerovale.fechaHoraInicioDespacho;

    //console.log("los parametros son "+"t "+this.aerovaleAlijeForm.temperaturaFormControl.value+" d "+this.densidad.value+" ai "+this.aforadorInicialFormControl.value+" af "+this.aforadorFinalFormControl.value)
     //this.aerovaleAlijeForm.volumen15GradosFormControl.setValue(res);

  //aerovale.volumen15Grados = res;

  }
  receiveMessage($event) {
    this.provinciaFlag = $event;
    this.ciudadFlag = $event;
  }
  onFormularioCommonValido(esValido) {
    this.commonFormValido = esValido;
  }

  onFormularioCombustibleValido(esValido) {
    this.combustibleFormValido = esValido;
  }

  onFormularioAeroespecialidadValido(esValido) {
    this.aeroespecialidadFormValido = esValido;
  }

  onFormularioAlijeValido(esValido) {
    this.alijeFormValido = esValido;
  }

  datosValidos() {
    if (this.tipoAerovale == TipoAerovale.Aerocombustible) {

    } else if (this.tipoAerovale == TipoAerovale.Combustible) {
      if (this.commonFormValido && this.combustibleFormValido) {
        return true;
      }
    } else if (this.tipoAerovale == TipoAerovale.Aeroespecialidad) {
      if (this.commonFormValido && this.aeroespecialidadFormValido) {
        return true;
      }
    } else if (this.tipoAerovale == TipoAerovale.Alije) {
      if (this.commonFormValido && this.alijeFormValido) {
        return true;
      }
    }

    return false;
  }

  validarDensidad(event){
      this.productoSeleccionado = this.productos.find(element => element.codigoProducto===this.aerovaleCommonForm.abastecedoraFormControl.producto.codigoProducto);
      this.esValidaDensidad = true;
      this.esValidaDensidad = (event >= this.productoSeleccionado.densidadMin && event <= this.productoSeleccionado.densidadMax);
      this.esDensidadMin = this.productoSeleccionado.densidadMin;
      this.esDensidadMax = this.productoSeleccionado.densidadMax;
  }

  densidadValida() {

    if (this.tipoAerovale!=TipoAerovale.Aeroespecialidad)
    {
      this.productoSeleccionado = this.productos.find(element => element.codigoProducto===this.aerovaleCommonForm.abastecedoraFormControl.producto.codigoProducto);
      let esValido = true;

      if (this.tipoAerovale == TipoAerovale.Aerocombustible) {
        // Esta en otro componente
      } else if (this.tipoAerovale == TipoAerovale.Combustible) {
        var densidad = this.aerovaleCombustibleForm.densidadFormControl;
      } else if (this.tipoAerovale == TipoAerovale.Alije) {
        var densidad = this.aerovaleAlijeForm.densidadFormControl;
      }

      if (densidad != null){
        esValido = (densidad >= this.productoSeleccionado.densidadMin && densidad <= this.productoSeleccionado.densidadMax);
      }
      return esValido;
    }
    else
    return true; // por si es aeroespecialidad
   }

  formatDate(year, month, day, hour, minute): string {
    return year + '-' + month.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + '-' + day.toLocaleString(undefined, { minimumIntegerDigits: 2 })
      + 'T' + hour.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + minute.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':00';
  }

  onSelectedFileFirmaCliente(files: any[]) {
    if (files && files.length > 0) {
      this.procesandoFirmaCliente = true;
      this.guardandoAerovale = true;
      let file = files[0];

      this.sharedService.resizeImage(file, 800).then(blob => {
        this.firmaCliente = blob;
        this.procesandoFirmaCliente = false;
        this.guardandoAerovale = false;
      }, err => {
        this.procesandoFirmaCliente = false;
        this.guardandoAerovale = false;
      });
/*
      this.ng2ImgMax.resizeImage(file, 800, 10000).subscribe(result => {
        this.firmaCliente = result;
        this.procesandoFirmaCliente = false;
        this.guardandoAerovale = false;
      },
        error => {
          this.procesandoFirmaCliente = false;
          this.guardandoAerovale = false;
        });
*/        
    }
  }

  onSelectedFileFirmaOperador(files: any[]) {
    if (files && files.length > 0) {
      this.procesandoFirmaOperador = true;
      this.guardandoAerovale = true;
      let file = files[0];

      this.sharedService.resizeImage(file, 800).then(blob => {
        this.firmaOperador = blob;
        this.procesandoFirmaOperador = false;
        this.guardandoAerovale = false;
      }, err => {
        this.procesandoFirmaOperador = false;
        this.guardandoAerovale = false;
      });
/*          
      this.ng2ImgMax.resizeImage(file, 800, 10000).subscribe(result => {
        this.firmaOperador = result;
        this.procesandoFirmaOperador = false;
        this.guardandoAerovale = false;
      },
        error => {
          this.procesandoFirmaOperador = false;
          this.guardandoAerovale = false;
        });
*/        
    }
  }

  toFormData<T>(formValue: T) {
    const formData = new FormData();

    for (const key of Object.keys(formValue)) {
      const value = formValue[key];
      formData.append(key, value);
    }

    return formData;
  }

  esFormatoImagen(extension:string)
  {
    let es:boolean= false;
    es= (extension.toLowerCase()==='jpeg' || extension.toLowerCase()==='jpg'
     || extension.toLowerCase()==='png' || extension.toLowerCase()==='jpe' ||
     extension.toLowerCase()==='jfi' || extension.toLowerCase()==='jfif');
    return es;
  }

  obtenerExtensionDeArchivo(firma: string): string {
    if (firma!=null){
      let extension = firma.split('.').pop();
      console.log("la extension que obtengo es " + extension);
      return extension;
    }else{
      return "";
    }
  }

  setCiudadAerovaleSeleccionada(event) {
    this.ciudadAerovaleSeleccionada = event;
  }

   /* -------------------------------------------------------------------------------------------------------- */

   // getters

  get aerovaleCommonForm() {
    return this.aerovaleForm.value.aerovaleCommon;
  }

  get aerovaleCombustibleForm() {
    return this.aerovaleForm.value.aerovaleCombustible;
  }

  get aerovaleAeroespecialidadForm() {
    return this.aerovaleForm.value.aerovaleAeroespecialidad;
  }

  get aerovaleAlijeForm() {
    return this.aerovaleForm.value.aerovaleAlije;
  }

  get firmaOperadorFormControl() {
    return this.aerovaleForm.controls.firmaOperadorFormControl;
  }

  /* -------------------------------------------------------------------------------------------------------- */
}
