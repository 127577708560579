import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, AbstractControl, FormGroup, FormControl } from '@angular/forms';
import { UnidadMedida } from 'src/app/shared/enums/unidadMedida';
import { TipoProducto } from 'src/app/shared/enums/tipoProducto';
import { NgbModal, NgbDateStruct, NgbCalendar, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { parametroImpositivo } from 'src/app/shared/models/abm/ParametroImpositivo';
import { ProductoService } from '../producto.service';
import { MessageService } from 'src/app/shell/message.service';
import { CodigoImpuesto } from 'src/app/shared/enums/codigoImpuesto';
import { TipoValor } from 'src/app/shared/enums/tipoValor';
import { BaseCalculo } from 'src/app/shared/enums/baseCalculo';
import { Producto } from 'src/app/shared/models/abm/Producto';
import { Zona } from 'src/app/shared/enums/zona';
import { CalculoBaseImponible } from 'src/app/shared/enums/CalculoBaseImponible';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';


@Component({
  selector: 'app-nuevo-impuesto',
  templateUrl: './nuevo-impuesto.component.html',
  styleUrls: ['./nuevo-impuesto.component.css']
})
export class NuevoImpuestoComponent implements OnInit {

  nuevoImpuestoForm = new FormGroup({
    codigoFormControl: new FormControl({value: null}),
    tipoProductoFormControl: new UntypedFormControl(null),
    leyendaFormControl: new UntypedFormControl(null),
    baseFormControl: new UntypedFormControl(null),
    valorFormControl: new UntypedFormControl(null),
    importeMinimoFormControl: new UntypedFormControl(null),
    activoFormControl: new UntypedFormControl(null),
    zonaFormControl: new UntypedFormControl(null),
    fechaInicioFormControl: new UntypedFormControl(null),
    horaInicioFormControl: new UntypedFormControl(null),
    fechaFinFormControl: new UntypedFormControl(null),
    horaFinFormControl: new UntypedFormControl(null),
    ivaControlInput: new UntypedFormControl(null)
  });

  unidadMedida = UnidadMedida;
  startDate: Date;
  endDate: Date;
  codigoImpuesto = CodigoImpuesto;
  modelFechaInicio: NgbDateStruct;
  timeFechaInicio: NgbTimeStruct;
  modelFechaFin: NgbDateStruct;
  timeFechaFin: NgbTimeStruct;
  tipoDeValor = TipoValor;
  baseDeCalculo = CalculoBaseImponible;
  tipoProducto = TipoProducto;
  zonaProducto = Zona;
  activeModal: NgbModal;
  codigoImpuestoSeleccionado: number;
  errorValorFormControl: boolean = false;
  grabando: boolean = false;

  TipoImpuesto = CodigoImpuesto;

  @ViewChild('fechaHora', {static: true}) fechaHoraElement: ElementRef;
  @ViewChild('fechaHoraVuelo', {static: true}) fechaHoraVueloEl: ElementRef;
  @Input() productoSeleccionado: Producto;
  @Input() impuestoSeleccionado: parametroImpositivo;
  @Input() editable: boolean;
  @Output() nuevoImpuesto = new EventEmitter<boolean>();
  editando: boolean = false;
  constructor(private calendar: NgbCalendar, activeModal: NgbModal,
    private messageService: MessageService, private productoService: ProductoService) {
      this.activeModal= activeModal;
  }

  ngOnInit() {
    if (this.editable === true) // se abre ventana para editar
    {
      this.editando = true;
      this.codigo.setValue(this.impuestoSeleccionado.codigo);
      this.tipoValor.setValue(this.impuestoSeleccionado.tipoValor);
      this.leyenda.setValue(this.impuestoSeleccionado.leyenda);
      if(this.codigo.value == 0){
        this.ivas.setValue(this.impuestoSeleccionado.valor);
        this.valor.setValue(0);
      }else{
        this.valor.setValue(this.impuestoSeleccionado.valor);
      }
      this.base.setValue(this.impuestoSeleccionado.baseCalculo);
      this.importeMinimo.setValue(this.impuestoSeleccionado.minimo);
      this.zona.setValue(this.impuestoSeleccionado.zona);
      // //fecha y hora inicio.
      var horaInicio = new Date(this.impuestoSeleccionado.fechaInicio);
      var fechaInicio = new Date(this.impuestoSeleccionado.fechaInicio);
      this.modelFechaInicio = { day: fechaInicio.getDate(), month: fechaInicio.getMonth() + 1, year: fechaInicio.getFullYear() };
      this.timeFechaInicio = { hour: horaInicio.getHours(), minute: horaInicio.getMinutes(), second: horaInicio.getSeconds() };
      // //fecha y hora Fin.
      var horaFin = new Date(this.impuestoSeleccionado.fechaFin);
      var fechaFin = new Date(this.impuestoSeleccionado.fechaFin);
      this.modelFechaFin = { day: fechaFin.getDate(), month: fechaFin.getMonth() + 1, year: fechaFin.getFullYear() };
      this.timeFechaFin = { hour: horaFin.getHours(), minute: horaFin.getMinutes(), second: horaFin.getSeconds() };
      this.codigoImpuestoSeleccionado = this.codigo.value;
    }
    else // se abre para crear
    {
      this.editando = false;
      this.codigo.setValue(CodigoImpuesto.Iva);
      this.tipoValor.setValue(0);
      this.valor.setValue(0);
      this.base.setValue(3);
      this.importeMinimo.setValue(0);
      this.ivas.setValue(21);
      // //fecha y hora inicio.
      var horaInicio = new Date();
      var fechaInicio = new Date();
      this.modelFechaInicio = { day: fechaInicio.getDate(), month: fechaInicio.getMonth() + 1, year: fechaInicio.getFullYear() };
      this.timeFechaInicio = { hour: horaInicio.getHours(), minute: horaInicio.getMinutes(), second: horaInicio.getSeconds() };
      // //fecha y hora Fin.
      var horaFin = new Date();
      var fechaFin = new Date();
      this.modelFechaFin = { day: fechaFin.getDate(), month: fechaFin.getMonth() + 1, year: fechaFin.getFullYear() };
      this.timeFechaFin = { hour: horaFin.getHours(), minute: horaFin.getMinutes(), second: horaFin.getSeconds() };
      this.zona.setValue(1);
      this.codigoImpuestoSeleccionado = this.codigo.value;
    }

  }

  //#region Validación de fechas

  fechaDesdeMayorQueHasta() {
    let esMayor: boolean = false;
    this.startDate = new Date(this.modelFechaInicio.year, this.modelFechaInicio.month - 1, this.modelFechaInicio.day, this.timeFechaInicio.hour, this.timeFechaInicio.minute);
    this.endDate = new Date(this.modelFechaFin.year, this.modelFechaFin.month - 1, this.modelFechaFin.day, this.timeFechaFin.hour, this.timeFechaFin.minute);
    if (this.startDate > this.endDate) { esMayor = true; }
    return esMayor;
  }

  /**
  * Convierte una NgbDateStruct y una NgbTimeStruct en un Date
  */
  dateFromDateTimeStruct(date: NgbDateStruct, hour: NgbTimeStruct): Date {
    if (date == null || hour == null)
      return null;
    let dateReturn = new Date();
    dateReturn.setDate(date.day);
    dateReturn.setMonth(date.month - 1);
    dateReturn.setFullYear(date.year);
    dateReturn.setHours(hour.hour);
    dateReturn.setMinutes(hour.minute);
    return dateReturn;
  }

  changeTipoImpuesto(){
    this.codigoImpuestoSeleccionado = this.codigo.value;
  }

  keysTiposAlicutotasIVA(): Array<string>
  {
    let keys: any = [0, 10.5, 21, 27];
    return keys;
  }


  keysCodigosImpuesto(): Array<string> {
    var keys = Object.keys(this.codigoImpuesto);
    keys.shift();
    keys = keys.slice(keys.length / 2);
    keys.splice(1, 1);
    keys.splice(1, 1);
    keys.splice(1, 1);
    keys.splice(4, 1);
    keys.splice(3, 1);
    return keys;
  }

  keysTiposValor(): Array<string> {
    var keys = Object.keys(this.tipoDeValor);
    keys.shift();
    keys = keys.slice(keys.length / 2);
    return keys;
  }

  keysBaseCalculo(): Array<string> {
    var keys = Object.keys(this.baseDeCalculo);
    keys.shift();
    keys = keys.slice(keys.length / 2);
    return keys;
  }

  keysZonas(): Array<string> {
    var keys = Object.keys(this.zonaProducto);
    keys.shift();
    keys = keys.slice(keys.length / 2);
    keys.splice(0,1);
    return keys;
  }


  keysTipos(): Array<string> {
    var keys = Object.keys(this.tipoProducto);
    keys.shift();
    keys = keys.slice(keys.length / 2);
    keys.shift();
    return keys;
  }

  grabandoImpuesto(editando:boolean) {

    if (this.camposVacios() || this.fechaDesdeMayorQueHasta() || this.errorValorFormControl==true) {
      this.messageService.showErrorMessage("Por favor controle todos los campos");
      this.grabando = false;
    } else {
      this.grabando = true;
      let p: parametroImpositivo = new parametroImpositivo();
      //VALORES EN COMUN PARA TODOS LOS IMPUESTOS
      editando ? p.id = this.impuestoSeleccionado.id : null
      p.codigo = this.codigo.value;
      p.activa = true;
      p.leyenda = this.leyenda.value;
      p.productoId = this.productoSeleccionado.id;
      p.rubroId = this.productoSeleccionado.rubro.id;
      let actual: NgbDateStruct;
      actual = this.calendar.getToday();
      let hora: Date = new Date();
      p.fechaActualizacion = this.formatDate(
        actual.year, actual.month,
        actual.day, hora.getHours(), hora.getMinutes());
  
      if(this.codigo.value != CodigoImpuesto.ITC && this.codigo.value != CodigoImpuesto.IDC){
        //VALORES PARA CUANDO EL IMPUESTO A CREAR ES DISTINTO A ITC/IDC
        p.tipoValor = this.tipoValor.value;
        if(p.codigo == 0){
          p.valor = parseFloat(this.ivas.value);
        }else{
          p.valor = parseFloat(this.valor.value);
        }
        p.baseCalculo = this.base.value;
        p.zona = 0;
        p.minimo = parseFloat(this.importeMinimo.value);
    
      }else{
        //VALORES PARA CUANDO EL IMPUESTO A CREAR ES IGUAL A ITC/IDC
        p.tipoValor = 1;
        p.valor = parseFloat(this.valor.value);
        p.baseCalculo = 3;
        p.zona = this.zona.value;
        p.minimo = 0;
        p.fechaInicio = this.formatDate(
          this.fechaInicial.value.year, this.fechaInicial.value.month,
          this.fechaInicial.value.day, this.horaInicial.value.hour, this.horaInicial.value.minute);
        p.fechaFin = this.formatDate(
          this.fechaFinal.value.year, this.fechaFinal.value.month,
          this.fechaFinal.value.day, this.horaFinal.value.hour, this.horaFinal.value.minute);
      }

      if(editando) {
        this.productoService.modificarImpuesto(p)
        .subscribe(result => {
          this.messageService.showSuccessMessage("Se ha modificado el impuesto correctamente");
          this.nuevoImpuesto.emit(true);
          this.grabando = false;
          this.activeModal.dismissAll();
        }, (error:ErrorModel) => {
          this.messageService.showErrorMessage(error.description + " - " + error.message);
          this.grabando = false;
        });
      } else {
        this.productoService.agregarImpuesto(p)
        .subscribe(result => {
          this.messageService.showSuccessMessage("Se ha agregado el impuesto correctamente");
          this.activo.setValue(true);
          // this.codigo.setValue();
          this.productoSeleccionado.impuestosProducto.push(p);
          let a = this.listaDeCodigosNoSeleccionados();
          this.codigo.setValue(this.codigoImpuesto[a[0]]);
          console.log(this.codigo.value);
          this.codigoImpuestoSeleccionado = parseInt(this.codigo.value);
          console.log(this.codigoImpuestoSeleccionado);
          this.leyenda.setValue('');
          this.tipoValor.setValue(0);
          this.valor.setValue(0);
          this.base.setValue(3);
          this.importeMinimo.setValue(0);
          // //fecha y hora inicio.
          var horaInicio = new Date();
          var fechaInicio = new Date();
          this.modelFechaInicio = { day: fechaInicio.getDate(), month: fechaInicio.getMonth() + 1, year: fechaInicio.getFullYear() };
          this.timeFechaInicio = { hour: horaInicio.getHours(), minute: horaInicio.getMinutes(), second: horaInicio.getSeconds() };
          // //fecha y hora Fin.
          var horaFin = new Date();
          var fechaFin = new Date();
          this.modelFechaFin = { day: fechaFin.getDate(), month: fechaFin.getMonth() + 1, year: fechaFin.getFullYear() };
          this.timeFechaFin = { hour: horaFin.getHours(), minute: horaFin.getMinutes(), second: horaFin.getSeconds() };
          this.zona.setValue(1);
          this.ivas.setValue(21);
          this.nuevoImpuesto.emit(true);
          this.grabando = false;
          this.activeModal.dismissAll();
        }, (error:ErrorModel) => {
          this.messageService.showErrorMessage(error.description + " - " + error.message);
          this.grabando = false;
        });
      }
    }
  }

  onlyDecimalNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46;
  }

  controlarCaracteres(event) {
    this.errorValorFormControl = false;
    for (let i = 0; i < event.target.value.length; i++) {
      let caracter = event.target.value.charAt(i);      
      if (caracter != "." && !(parseInt(caracter) >= 0 && parseInt(caracter) <= 9)) {
        this.errorValorFormControl = true;
        break
      }
    }
  }

  formatDate(year, month, day, hour, minute): string {
    return year + '-' + month.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + '-' + day.toLocaleString(undefined, { minimumIntegerDigits: 2 })
      + 'T' + hour.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + minute.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':00';
  }

  esCampoVacio(campo: any): boolean {
    return (campo == null || (campo != null && campo.length == 0));
  }

  camposVacios() {
    return this.esCampoVacio(this.codigo.value) ||
      this.esCampoVacio(this.leyenda.value) ||
      this.esCampoVacio(this.valor.value) ||
      this.esCampoVacio(this.importeMinimo.value);
  }

  estaCodigoImpuesto(): boolean {
    let esta: boolean = false;
    for (let entry of this.productoSeleccionado.impuestosProducto) {
      if (entry.codigo === this.codigo.value) {
        esta = true;
        break;
      }
    }
    return esta;
  }

  listaDeCodigosNoSeleccionados(): Array<string> {
    let lista: string[] = [];
    let listaTotal: string[]=this.keysCodigosImpuesto();
    let listaResultado: string[]=[];

    for (let i of listaTotal) {
      if (!this.estaCodigoSeleccionado(Number(this.codigoImpuesto[i])))
      { lista.push(this.codigoImpuesto[i]); }

    }
    for (let i of lista) {
    listaResultado.push(CodigoImpuesto[i]);
    }
    return listaResultado;
  }

  estaCodigoSeleccionado(cod: number): boolean {
    let listaTotal =this.productoSeleccionado.impuestosProducto;
    for (let entry of listaTotal) {
      if (entry.codigo === cod && entry.codigo != 6 && entry.codigo != 4 && entry.codigo != 5 ) // si está el codigo y es distinto de otro
      {
        return true;
        break;
      }
    }
    return false;
  }

  listaCodigos(): Array<string> // la lista de codigos segun el modal sea para modificar o para agregar
  {
    if (!this.editable)
    {
      return this.listaDeCodigosNoSeleccionados(); // si el modal se abre para agregar
    }
    else
    {
      return this.keysCodigosImpuesto(); // si el modal se abre para modificar
    }

  }

  get codigo() {
    return this.nuevoImpuestoForm.get('codigoFormControl');
  }
  get leyenda() {
    return this.nuevoImpuestoForm.get('leyendaFormControl');
  }
  get tipoValor() {
    return this.nuevoImpuestoForm.get('tipoProductoFormControl');
  }
  get valor() {
    return this.nuevoImpuestoForm.get('valorFormControl');
  }
  get base() {
    return this.nuevoImpuestoForm.get('baseFormControl');
  }
  get importeMinimo() {
    return this.nuevoImpuestoForm.get('importeMinimoFormControl');
  }
  get activo() {
    return this.nuevoImpuestoForm.get('activoFormControl');
  }
  get zona(){
    return this.nuevoImpuestoForm.get('zonaFormControl');
  }
  get fechaInicial(){
    return this.nuevoImpuestoForm.get('fechaInicioFormControl');
  }
  get horaInicial(){
    return this.nuevoImpuestoForm.get('horaInicioFormControl');
  }
  get fechaFinal(){
    return this.nuevoImpuestoForm.get('fechaFinFormControl');
  }
  get horaFinal(){
    return this.nuevoImpuestoForm.get('horaFinFormControl');
  }
  get ivas(){
    return this.nuevoImpuestoForm.get('ivaControlInput');
  }
}
