import { Component, OnInit } from '@angular/core';
import { ParametrosImpositivosService } from '../../parametros-impositivos.service';
import { Provincia } from 'src/app/shared/models/facturacion/Provincia';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-provincia-v2',
  templateUrl: './modal-provincia-v2.component.html',
  styleUrls: ['./modal-provincia-v2.component.css']
})
export class ModalProvinciaComponentV2 implements OnInit {

  provincias: Provincia[];
  provinciaSeleccionada: Provincia;
  obteniendoProvincias: boolean = true;

  constructor(private parametrosService: ParametrosImpositivosService,
    private activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.parametrosService.obtenerProvincias('0303'/*ARG*/).subscribe(
      (provincias: Provincia[]) => {
        const codigosProvExistentes = this.parametrosService.listadoProvinciasEncabezados.value.map(p => p.provinciaCodigo);
        this.provincias = provincias.filter(p => !codigosProvExistentes.includes(p.codigo));
        this.provincias.sort((a,b) => (a.nombre > b.nombre) ? 1 : -1);
        this.obteniendoProvincias = false;
      }
    );
  }

  onVolverClicked() {
    this.activeModal.dismiss();
  }

  onCrearClicked() {
    this.activeModal.close(this.provinciaSeleccionada);
  }
}
