import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { AerovaleDetalle } from 'src/app/shared/models/despacho/AerovaleDetalle';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { Holdeo } from 'src/app/shared/models/facturacion/Holdeo';
import { MessageService } from 'src/app/shell/message.service';
import { ComprobanteService } from '../../comprobante.service';
import { NuevoHoldeoComponent } from '../nuevo-holdeo/nuevo-holdeo.component';
import { AerovalesService } from 'src/app/despacho/aerovales.service';
import { AerovaleDetalleComponent } from 'src/app/despacho/aerovale-detalle/aerovale-detalle.component';
@Component({
  selector: 'app-holdeo-aerovales',
  templateUrl: './holdeo-aerovales.component.html',
  styleUrls: ['./holdeo-aerovales.component.css']
})
export class HoldeoAerovalesComponent implements OnInit {
  @Output() volver = new EventEmitter();

  obteniendo: boolean = false;
  cuentasHoldeadas: Holdeo[] = [];
  desactivandoCuenta: boolean = false;
  obteniendoAerovale: boolean = false;
  aerovaleDetalle: AerovaleDetalle;
  aerovaleSeleccionado: number;
  cuentaSeleccionada: number;

  constructor(
    private modal: NgbModal, 
    private spinner: NgxSpinnerService, 
    private comprobantesService: ComprobanteService, 
    private aerovaleService: AerovalesService, 
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.getHoldeoCuenta();
  }

  getHoldeoCuenta() {
    this.spinner.show('spinnerLista');
    this.obteniendo = true;
    this.comprobantesService.getHoldeo(4)
      .subscribe(result => {
        this.cuentasHoldeadas = result.filter(a => a.tipoHoldeo == 2);
        this.cuentasHoldeadas = this.cuentasHoldeadas.filter(b => b.activo == true);
        this.spinner.hide('spinnerLista');
        this.obteniendo = false;
      });
  }

  nuevoHoldeoAerovle() {
    let modalRef = this.modal.open(NuevoHoldeoComponent, { centered: true, backdrop: 'static' });
    modalRef.componentInstance.tipo = 2;
    modalRef.result.then(result => {
      result == true ? this.ngOnInit() : null;
    })
  }

  quitarCuentas(i: number){
    this.desactivandoCuenta = true;
    this.cuentaSeleccionada = i;    
    this.cuentasHoldeadas[i].activo ? this.cuentasHoldeadas[i].activo = false : this.cuentasHoldeadas[i].activo = true;
    this.comprobantesService.putHoldeo(this.cuentasHoldeadas[i])
      .subscribe(result => {
        result.activo ? this.messageService.showSuccessMessage('El holdeo del aerovale '+ this.extraerNroAerovale(this.cuentasHoldeadas[i].ctA_Descripcion) + ' fue activado con exito') : this.messageService.showErrorMessage('El holdeo del aerovale ' + this.extraerNroAerovale(this.cuentasHoldeadas[i].ctA_Descripcion) + ' fue desactivado con exito');
        let y = this.cuentasHoldeadas.indexOf(this.cuentasHoldeadas[i])
        if (y != -1){
          this.cuentasHoldeadas.splice(y,1);
        }
        this.desactivandoCuenta = false;
        this.cuentaSeleccionada = null;
      }, (err: ErrorModel) => {
        this.messageService.showErrorMessage('Hubo un error al actualizar el estado del aerovale '+ this.cuentasHoldeadas[i].ctA_Descripcion);
        this.desactivandoCuenta = false;
        this.cuentaSeleccionada = null;
      });
  }

  extraerNroAerovale(cadena:string) {
    let nroAerovale:string="";
    let encont:boolean=false;
    for (let i = 0; i < cadena.length; i++) {
      if (cadena[i].endsWith("/") && !encont) {
        encont=true;
      }
      if(encont){
        nroAerovale=nroAerovale.concat(cadena.charAt(i+1)).trim();
      }
    }
    if(nroAerovale.length > 0) {return nroAerovale} else {return cadena};    
  }

  obtenerAerovale(id:string, i:number) {
    this.aerovaleDetalle = null;
    this.aerovaleSeleccionado = i;
    this.obteniendoAerovale = true;
    this.aerovaleService.getAerovalePorIdyTipo(id)
      .subscribe(result => {
        this.aerovaleDetalle = result;
        this.aerovaleSeleccionado = null;
        this.obteniendoAerovale = false;        
        if(this.aerovaleDetalle) {
          this.mostrarAerovale();
        }}, (err: ErrorModel) => {
          this.messageService.showErrorMessage('Hubo un error al obtener el Aerovale' + err.description);        
          this.aerovaleSeleccionado = null;
          this.obteniendoAerovale = false;
        });
  }

  mostrarAerovale() {
    let modalRef = this.modal.open(AerovaleDetalleComponent, { size: "lg", centered: true});
    modalRef.componentInstance.aerovaleDetalle = this.aerovaleDetalle;
  }

  goBack(){
    this.volver.emit(true);
  }
}
