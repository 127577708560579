import { Cliente } from "./Cliente";
import { Emisor } from "./Emisor";
import { DecimalPipe, NumberSymbol } from '@angular/common';
import { Cae } from './Cae';
import { Comprobante } from './Comprobante';
import { Remito } from './Remito';
import { Nota } from './Nota';
import {Renglon} from './Renglon';
import { ComprobanteEncabezado } from './ComprobanteEncabezado';
import { ImpuestoPercepcion } from './ImpuestoPercepcion';
import { PagoContado } from './PagoContado';
import { Leyenda } from './Leyenda';
export class Factura extends Comprobante {

  id: string // necesario cuando se tiene que mandar una lista de ids
  condicionVenta: number;
  cae: Cae;
  estado: number;
  netoTotal: number;
  subtotal: number;
  total: number;
  codigoBarras: string;
  remitos: ComprobanteEncabezado[];
  nota: Nota;
  renglones: Renglon[];
  impuestos: ImpuestoPercepcion[];
  percepciones: ImpuestoPercepcion[];
  pago: PagoContado[];
  numerosAerovales: string;
  patentes: string;
  ciudadAeroplanta: string;
  leyendas: Leyenda[];
  codigoQr: string;
  cotizacionMoneda: number;
  ranchos: string;
  linkPDFOriginal: string;
}
