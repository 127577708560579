<div class="card add-margin">
  <form [formGroup]="this.form">

    <h4 class="card-header card-title ">
      <div class="text-center col-sm-9">{{ esDelegacion ? "Delegar usuario a nivel" : "Agregar usuario a nivel"}}<br/><b>{{nivelAprobacion != null ? nivelAprobacion.nombre : ""}}</b></div>
    </h4>

    <div class="card-body">

      <div class="row">
        <div class="mb-3 col-sm-12">
          <div *ngIf="errorDeNivel" class="alert alert-danger">
            No posee un nivel nativo asignado para poder delegarlo.
          </div>
        </div>
      </div>

      <div class="row">
        <div class="mb-3 col-sm-12">
          <label for="usuarioFormControl" class="col-sm-10 col-form-label">Usuario:</label>
          <div class="col-sm-10">
            <input id="usuarioFormControl" type="text" class="form-control mb-2" formControlName="usuarioFormControl"
            [inputFormatter]="formatterUsuario" [resultFormatter]="formatterUsuario"
            title="Campo obligatorio, debe seleccionar o ingresar un operador." 
            (selectItem)="selectUsuario($event)"
            (change)="confirmUsuario()"
            [ngbTypeahead]="searchUsuario" />
  
            <loading-text-spinner *ngIf="cargandoUsuarios" message="Obteniendo usuarios..." color="primary"> </loading-text-spinner>
            
            <div *ngIf="errorUsuarios" class="d-flex align-items-center">
              <i class="fa fa-times text-danger"></i>
              <div class="ps-2 text-dark">
                Se ha producido un error
              </div>
            </div>
    
            <div *ngIf="usuarioFormControl.invalid && usuarioFormControl.errors.required && (usuarioFormControl.dirty || usuarioFormControl.touched)" class="alert alert-danger">
              Campo requerido
            </div>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="this.esDelegacion===true">
        <div class="mb-3 col-sm-12">
          <label for="fechaDesdeFormControl" class="col-sm-4 col-form-label">Desde:</label>
          <div class="col-sm-10">
            <div class="input-group">
            
              <input type="date" 
              formControlName="fechaDesdeFormControl"       
              class="form-control form-control-rounded mb-2" 
              placeholder="aaaa-mm-dd"  
              ngbDatepicker
              [(ngModel)]="modelFechaDesde" 
              #desde="ngbDatepicker"
              readonly>

              <div class="input-group-append">
                <button id="botonDesde" type="button" class="input-group-btn btn btn-secondary" (click)="desde.toggle()">
                  <i class="fa fa-calendar" aria-hidden="true"></i></button>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div class="row" *ngIf="this.esDelegacion===true">
        <div class="mb-3 col-sm-12">
          <label for="fechaHastaFormControl" class="col-sm-4 col-form-label">Hasta:</label>
          <div class="col-sm-10">
            <div class="input-group">
          
              <input type="date" 
              formControlName="fechaHastaFormControl"       
              class="form-control form-control-rounded mb-2" 
              placeholder="aaaa-mm-dd"  
              ngbDatepicker
              [(ngModel)]="modelFechaHasta" 
              #hasta="ngbDatepicker"
              readonly>
    
              <div class="input-group-append">
                <button id="botonHasta" type="button" class="input-group-btn btn btn-secondary" (click)="hasta.toggle()"><i
                    class="fa fa-calendar" aria-hidden="true"></i></button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="alert alert-danger" *ngIf="form.invalid && this.form.hasError('fechaDesdeMayorHasta')">
        La fecha Desde no puede ser mayor a la fecha Hasta.
      </div>

      <div class="row">
        <div class="mb-3 col-sm-12" *ngIf="this.esDelegacion===true">
          <label for="detalleFormControl" class="col-sm-12 col-form-label">Motivo:</label>
          <div class="col-sm-12">
            <div class="input-group mb-2">
              <textarea id="detalleFormControl" formControlName="detalleFormControl" type="text"
                style="min-width: 100%" rows="6" mdbInput></textarea>
            </div>
          </div>
          <div *ngIf="detalleFormControl.invalid && detalleFormControl.errors.required && (detalleFormControl.dirty || detalleFormControl.touched)" class="alert alert-danger">
            Campo requerido
          </div>
        </div>
      </div>
    </div>

    <div class="card-footer d-flex justify-content-center">
      <span class="border">
        <button class="btn btn-primary" type="button" (click)="onSubmit()"
          [disabled]="(!form.valid) || guardandoUsuarioNivel">
          <span *ngIf="!guardandoUsuarioNivel">Guardar</span>
          <div *ngIf="guardandoUsuarioNivel" class="d-flex align-items-center">
            <div class="spinner-border text-light spinner-border-sm" role="status"></div>
            <span>Guardando...</span>
          </div>
        </button>
      </span>
      <span class="border">
        <button type="button" class="btn btn-secondary" (click)="onCancelar()">Cancelar</button>
      </span>
    </div>
  </form>
</div>