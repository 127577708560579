import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Surtidor } from 'src/app/shared/models/stock/Surtidor';
import { Tanque } from 'src/app/shared/models/stock/Tanque';

@Component({
  selector: 'app-visor-tanques-relacionados',
  templateUrl: './visor-tanques-relacionados.component.html',
  styleUrls: ['./visor-tanques-relacionados.component.css']
})
export class VisorTanquesRelacionadosComponent implements OnInit {

  @Input() listaDeTanques: Tanque[];
  @Input() surtidor: Surtidor;
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }
}
