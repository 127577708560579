import { DatePipe } from '@angular/common';
import { Component, HostBinding, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbCalendar, NgbDatepickerConfig, NgbDateStruct, NgbModal, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { start } from 'repl';
import { Subscription } from 'rxjs';
import { UsuarioService } from 'src/app/account/usuario.service';
import { AerovalesService } from 'src/app/despacho/aerovales.service';
import { TipoAerovale } from 'src/app/shared/enums/tipoAerovale';
import { FechasService } from 'src/app/shared/fechas.service';
import { ModalConfirmComponent } from 'src/app/shared/generic-modals/modal-confirm.component';
import { Aeroplanta } from 'src/app/shared/models/despacho/Aeroplanta';
import { Aerovale } from 'src/app/shared/models/despacho/Aerovale';
import { AerovaleDetalle } from 'src/app/shared/models/despacho/AerovaleDetalle';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { Comprobante } from 'src/app/shared/models/facturacion/Comprobante';
import { Factura } from 'src/app/shared/models/facturacion/Factura';
import { RemitoEncabezado } from 'src/app/shared/models/facturacion/remitoEncabezado';
import { SharedService } from 'src/app/shared/shared.service';
import { MessageService } from 'src/app/shell/message.service';
import { ModalObservacionRefacturacionComponent } from '../aprobacion-refacturacion/modal-observacion-refacturacion/modal-observacion-refacturacion.component';
import { ComprobanteService } from '../comprobante.service';
import { CorreccionDespachoComponent } from './correccion-despacho/correccion-despacho.component';

@Component({
  selector: 'app-despacho-refacturacion',
  templateUrl: './despacho-refacturacion.component.html',
  styleUrls: ['./despacho-refacturacion.component.css']
})
export class DespachoRefacturacionComponent implements OnInit {


  @HostBinding('class.changed')

  tablaComprobantes: UntypedFormGroup;
  aeroplanta: Aeroplanta;
  remitos: RemitoEncabezado[] = [];
  remitosFiltrados: RemitoEncabezado[] = [];


  facturaCalculada: Factura[];
  facturaObtenida: Comprobante;
  remitosId: string[] = [];
  aerovaleDetalle: AerovaleDetalle;
  previsualizacion: boolean = false;

  aerovaleActual: Aerovale;
  fechaHasta = new UntypedFormControl();
  fechaFormControlDesdeHasta = new UntypedFormControl();
  mensajeErrorExportar = "";
  model: NgbDateStruct;
  model1: NgbDateStruct;
  time: NgbTimeStruct;
  startDate: Date;
  endDate: Date;
  buscando: boolean = false;
  mensajeSpinner: string = "D e s c a r g a n d o";
  obteniendoAerovales = true;
  procesandoExport: boolean = false;
  codigoAeroplanta: string;
  cambiandoAeroplanta: boolean = false;
  pendiente = { valor: "Pendiente", descripcion: "Pendiente", seleccionado: false };
  finalizado = { valor: "Finalizado", descripcion: "Finalizado", seleccionado: false };
  finalizadoSinFirma = { valor: "FinalizadoSinFirma", descripcion: "Finalizado sin firma", seleccionado: false };
  estadoSincronizado = { valor: "Sincronizado", descripcion: "Sincronizado", seleccionado: false };
  anulado = { valor: "Anulado", descripcion: "Anulado", seleccionado: false };

  notaCredito = { tipo: 0, valor: "notaCredito", descripcion: "Nota de credito", seleccionado: false };
  notaDebito = { tipo: 1, valor: "notaDebito", descripcion: "Nota de debito", seleccionado: false };

  tipos = [
    this.notaCredito, this.notaDebito
  ];

  estados = [
    this.pendiente,
    this.finalizado,
    this.estadoSincronizado,
    this.anulado
  ];

  busquedaFormControl = new UntypedFormControl();
  change$: Subscription;
  errorAerovales: boolean = false;

  tipoNuevoAerovale: TipoAerovale;
  enumTipoAerovale = TipoAerovale;
  idComprobanteAsociado: string;
  botonAnular: boolean = false;

  aerovaleSeleccionado: AerovaleDetalle;
  cargandoArovale: boolean = false;
  itemSeleccionado: number = null;

  constructor(private datepickerConfig2: NgbDatepickerConfig,
    private messageService: MessageService,
    private aerovaleService: AerovalesService,
    private usuarioService: UsuarioService,
    private calendar: NgbCalendar,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private activeModal: NgbModal,
    private fb: UntypedFormBuilder,
    private facturacionService: ComprobanteService,
    private fechaService: FechasService,
    private datePipe: DatePipe,
    sharedService: SharedService) {
      this.change$ = sharedService.aeroplantaSeleccionada.subscribe(_=>{
        this.cambiarDeAeroplanta();
      });
  }

  ngOnInit() {

    this.tablaComprobantes = this.fb.group({
      remitosFiltradosForm: new UntypedFormArray([])
    });
    this.addCheckboxes();
    
    this.codigoAeroplanta = this.usuarioService.getAeroplantaActual().codigoAeroplanta;
    this.idComprobanteAsociado = '';
    this.botonAnular = false;
    this.mensajeSpinner = "C a r g a n d o . . .";

    this.model = this.calendar.getToday();
    this.model1 = this.calendar.getToday();
    let inicio: any = { day: 1, month: 1, year: 2019 };
    this.datepickerConfig2.minDate = inicio;
    let fin: any = { day: this.model.day, month: this.model.month, year: this.model.year };
    this.model1 = this.calendar.getToday();
    this.datepickerConfig2.maxDate = fin;
    setTimeout(() => {
      this.spinner.show('spinnerGrafico');
    });
    this.reinicioFechaInicioFechaFin();
    this.getRemitosSoloSinFacturar();
  }


  filtrarRemitos() {
    this.remitosFiltrados = this.remitos.filter(a => {
      let agregar = true;
      if (this.busqueda.value) {
        agregar = a.clienteRazonSocial.toLowerCase().indexOf(this.busqueda.value.toLowerCase()) > -1;

        agregar = agregar || (a.prefijoNumeroAerovale.indexOf(this.busqueda.value) > -1);

        agregar = agregar || (this.datePipe.transform(a.fechaHoraCreacion, "d/MM/y").indexOf(this.busqueda.value) > -1);
      }

      return agregar;
    });
  }


  get busqueda() {
    return this.busquedaFormControl;
  }

  getRemitosSoloSinFacturar() {
    this.obteniendoAerovales = true;
    this.spinner.show('spinnerGrafico');
    let desde: string = this.fechaService.convertirDateUtcALocal(this.startDate.getUTCFullYear(), this.startDate.getMonth() + 1, this.startDate.getDate(), 0, 0);
    let hasta: string = this.fechaService.convertirDateUtcALocal(this.endDate.getUTCFullYear(), this.endDate.getMonth() + 1, this.endDate.getDate(), 23, 59);
    this.facturacionService.obtenerRemitosSoloSinFacturar(2, desde, hasta)
      .subscribe(result => {
        //this.remitos= result.sort((a, b) => new Date(b.fechaHoraCreacion).getTime() - new Date(a.fechaEmision).getTime());;
        this.remitos = result.sort((a, b) => new Date(b.fechaHoraCreacion).getTime() - new Date(a.fechaHoraCreacion).getTime());;
        this.remitosFiltrados = this.remitos;
        this.spinner.hide('spinnerGrafico');
        this.obteniendoAerovales = false;
        this.filtrarRemitos();
      }, () => {
        this.errorAerovales = true;
        this.obteniendoAerovales = false;
      });
  }



  ngOnDestroy() {
    this.change$.unsubscribe();
    this.remitosFiltrados = [];
  }

  addValidationError(control: AbstractControl, validationName: string) {
    let errors = control.errors;
    if (errors == null)
      errors = { [validationName]: true };
    else
      errors[validationName] = true;
    control.setErrors(errors);
    control.markAsDirty();
  }

  /**
   * Convierte una NgbDateStruct y una NgbTimeStruct en un Date
   */
  dateFromDateTimeStruct(date: NgbDateStruct, hour: NgbTimeStruct): Date {
    if (date == null || hour == null)
      return null;
    let dateReturn = new Date();
    dateReturn.setDate(date.day);
    dateReturn.setMonth(date.month - 1);
    dateReturn.setFullYear(date.year);
    dateReturn.setHours(hour.hour);
    dateReturn.setMinutes(hour.minute);
    return dateReturn;
  }

  fechaDesdeMayorQueHasta() {
    let esMayor: boolean = false;
    this.startDate = new Date(this.model.year, this.model.month - 1, this.model.day);
    this.endDate = new Date(this.model1.year, this.model1.month - 1, this.model1.day);
    if (this.startDate > this.endDate) { esMayor = true; }
    return esMayor;
  }


  reinicioFechaInicioFechaFin() {
    this.startDate = new Date(this.model.year, this.model.month - 1, this.model.day);
    this.endDate = new Date(this.model1.year, this.model1.month - 1, this.model1.day);
    this.endDate.setHours(23, 59, 59, 99);
  }

  buscarDesdeHasta() {
    this.mensajeErrorExportar = "";
    this.remitosFiltrados = null;
    this.aerovaleActual = null;
    this.mensajeSpinner = "C a r g a n d o . . .";
    this.buscando = true;
    this.reinicioFechaInicioFechaFin();
    this.getRemitosSoloSinFacturar();

    this.remitos.splice(0, this.remitos.length);

    //this.buscando = false;
  }


  limpiarCampos() {
    this.remitosFiltrados = [];
    this.spinner.hide("spinnerLista");
    this.spinner.hide("spinnerDetalle");
    this.buscando = false;
    this.busquedaFormControl.reset();
    this.model = this.calendar.getToday();
    this.model1 = this.calendar.getToday();
    //limpio filtro estados
    for (let estado of this.estados) {
      estado.seleccionado = false;
    }
    //limpio filtro tipos
    for (let tipo of this.tipos) {
      tipo.seleccionado = false;
    }
  }

  cambiarDeAeroplanta() {
    this.cambiandoAeroplanta = false;
    this.limpiarCampos();
    this.cambiandoAeroplanta = true;
    this.ngOnInit();
  }

  private addCheckboxes() {
    // reinicio el formulario
    this.tablaComprobantes.setControl('remitosFiltradosForm', this.fb.array([]));
    this.remitosFiltrados.map((o, i) => {
      const control = new UntypedFormControl(i === 0); // if first item set to true, else false
      control.setValue(o);
      (this.tablaComprobantes.controls.remitosFiltradosForm as UntypedFormArray).push(control);
    });
  }

  // llamadas al service

  obtenerAerovale(remito: RemitoEncabezado, content, index) {
    remito.loadIcon = true;
    this.cargandoArovale = true;
    this.aerovaleSeleccionado = null;
    this.itemSeleccionado = index;
    this.aerovaleService.getAerovalePorIdyTipo(remito.aerovaleId)
      .subscribe(result => {
        this.aerovaleSeleccionado = result;        
        remito.loadIcon = false;
        this.cargandoArovale = false;
        this.itemSeleccionado = -1;
        const modalRef = this.modalService.open(content, { centered: true, size: 'lg' });
        // modalRef.componentInstance.aerovaleDetalle = this.aerovaleSeleccionado;
      }, (err: ErrorModel) => {
        remito.loadIcon = false;
        this.cargandoArovale = false;
        this.itemSeleccionado = -1;
        this.messageService.showErrorMessage('Hubo un error al obtener el aerovale. ' + err.description + ' ' + err.message)
      });
  }



  obtenerComprobanteIdPorAerovaleId(id: string) {
    console.log("obtener si tiene un comprobante asociado");

  }

  obtenerComprobantePorId(id: string) {
    console.log("obtener el comprobante según id");

  }


  verObservaciones() {
    let modalRef = this.modalService.open(ModalObservacionRefacturacionComponent, { centered: true, backdrop: 'static' });
    //modalRef.componentInstance.factura = this.factura;
    modalRef.result.then(() => {
      console.log("S");
    }, () => { });
  }

  verComparativasFacturas(content) {
    const modalRef = this.modalService.open(content, { windowClass: 'custom-modal', centered: true, size: 'lg' });
  }

  verComparativasAerovales(content) {
    console.log("El aerovale tiene" + JSON.stringify(this.aerovaleDetalle));
    let modalRef = this.activeModal.open(content, { size: "lg", centered: true })
    //modalRef.componentInstance.aerovaleDetalle = this.aerovaleDetalle;

  }


  irACorreccionDespacho(s) {
    let modalRef = this.modalService.open(CorreccionDespachoComponent, { centered: true, backdrop: 'static' });
    modalRef.componentInstance.refacturacion = s;
    modalRef.result.then((result) => {
      this.buscarDesdeHasta();
    }, () => { });
  }



}
