import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { EstadoPadron } from 'src/app/shared/enums/estadoPadron';
import { TipoPadron, TipoRegimenARBA } from 'src/app/shared/enums/tiposPadron';
import { CargaPadron } from 'src/app/shared/models/padrones/CargaPadron';
import { PadronesService } from '../padrones.service';

@Component({
  selector: 'app-importacion-padrones',
  templateUrl: './importacion-padrones.component.html',
  styleUrls: ['./importacion-padrones.component.css']
})
export class ImportacionPadronesComponent implements OnInit {

  tiposPadron: [TipoPadron, string][] = [
    [TipoPadron.CABA, "CABA"],
    [TipoPadron.ARBAPercepciones, "ARBA Percepciones"],
    [TipoPadron.PercepcionIVARG17, "Percepción IVA RG 17"],
    [TipoPadron.PercepcionIVARG18, "Percepción IVA RG 18"]
    // [TipoPadron.Cordoba, "Córdoba"],
    // [TipoPadron.LaPlata, "La Plata"],
    // [TipoPadron.Misiones, "Misiones"]
  ];
  selectedTipoPadron: [TipoPadron, string];
  mensajeError: string;
  mensajeOk: string;

  profileForm: UntypedFormGroup;
  file: File;
  EstadoPadron = EstadoPadron;
  TipoRegimenARBA = TipoRegimenARBA;
  TipoPadron = TipoPadron;
  preparandoCarga: boolean;
  tipoRegimenARBA: TipoRegimenARBA;

  onSelectedFile(event) {
    if (event.target.files.length > 0) {
      this.file = event.target.files[0];
    }
  }

  constructor(private fb: UntypedFormBuilder,
    private padronesService: PadronesService
  ) { }

  ngOnInit() {
    this.profileForm = this.fb.group({
      inputFile: [''],
      tipoRegimenARBA: ['']
    });
    this.selectedTipoPadron = this.tiposPadron[0];
  }

  get loteCargas(): CargaPadron[] {
    return this.padronesService.loteCargas;
  }

  onSubmit() {
    if (this.profileForm.value.tipoRegimenARBA === TipoRegimenARBA.Combustibles) {
      this.selectedTipoPadron[0] = TipoPadron.ARBAPercepcionesCombustibles;
    }
    this.padronesService.cargarPadron(this.file, this.selectedTipoPadron[0]);
    this.reset();
  }

  onSelectedTipoPadron(e: [TipoPadron, string]) {
    this.selectedTipoPadron = e;
    this.reset();
  }

  onCancelarCarga(cargaPadron: CargaPadron) {
    this.padronesService.cancelarCarga(cargaPadron);
    this.reset();
  }

  get cargandoPadron(): CargaPadron {
    return this.padronesService.loteCargas.find(cp => cp.tipoPadron == this.selectedTipoPadron[0]
      && (cp.estado === EstadoPadron.Preparando || cp.estado === EstadoPadron.Subiendo || cp.estado === EstadoPadron.Importando));
  }

  get cargaPadronTerminada(): CargaPadron {
    const carga = this.padronesService.loteCargas.find(cp => cp.tipoPadron == this.selectedTipoPadron[0]
      && (cp.estado === EstadoPadron.Cancelado || cp.estado === EstadoPadron.Error || cp.estado === EstadoPadron.Finalizado));
    if (carga)
      this.mensajeError = carga.error;
    return carga;
  }

  get fileName(): string {
    if (this.file) {
      return this.file.name;
    }
    return '';
  }

  /**
  * Limpiar pantalla
  */
  reset() {
    this.file = null;
    this.profileForm.reset();
    if (this.selectedTipoPadron[0] === TipoPadron.ARBAPercepciones ||
      this.selectedTipoPadron[0] === TipoPadron.ARBAPercepcionesCombustibles) {
      this.profileForm.patchValue({ tipoRegimenARBA: TipoRegimenARBA.General })
    }
  }
}
