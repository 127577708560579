import { Component, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResolucionMensual } from 'src/app/shared/models/cierreTurno/ResolucionMensual';
import { ReportesService } from 'src/app/reportes/reportes.service';
import { VisorInformeResolucionComponent } from '../visor-informe-resolucion/visor-informe-resolucion.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'src/app/shell/message.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-modal-generar-resolucion',
  templateUrl: './modal-generar-resolucion.component.html',
  styleUrls: ['./modal-generar-resolucion.component.scss']
})
export class ModalGenerarResolucionComponent {
  constructor(
    public activeModal: NgbActiveModal,
    public modal: NgbModal,
    private reportesService: ReportesService, 
    private messageService: MessageService) { }

  @Input() codigoAeroplanta: string;
  @Input() fdesde: string;
  @Input() fhasta: string;
  @Input() productos;
  @Input() esTercera: boolean;
  grabando: boolean = false;

  generarInforme() {
    let nombreResolucion = (this.codigoAeroplanta !== 'AEP' && this.codigoAeroplanta !== 'EZE') ? 'TERCERAS' : this.codigoAeroplanta;
    this.grabando = true;
    this.reportesService.guardarResolucionMensual(this.codigoAeroplanta, this.fdesde, this.fhasta).subscribe(res => {
      let modalRef = this.modal.open(VisorInformeResolucionComponent, { centered: true, size: 'xl', backdrop: 'static', keyboard: false });
      let resolucion = res[0];
      
      if(this.esTercera) {
        resolucion.renglonesResolucion = [];
        let resolucionArray = res;
        resolucionArray.forEach(res => {
          resolucion.renglonesResolucion = resolucion.renglonesResolucion.concat(res.renglonesResolucion);
        })
      }
      
      for (const item of resolucion.renglonesResolucion) {
        item.porcentual = item.porcentual ? item.porcentual : 0;
        item.nombreProducto = this.productos[item.codigoProducto];
      }
      resolucion.renglonesResolucion.sort((a, b) => {
        let aeroplanta1 = a.codigoAeroplanta;
        let aeroplanta2 = b.codigoAeroplanta;
        return aeroplanta1.localeCompare(aeroplanta2);
      });
      console.log(resolucion)
      modalRef.componentInstance.resoluciones = resolucion;
      modalRef.componentInstance.fileName = `Resolución_${nombreResolucion}_${res[0].mes}/${res[0].ano}.pdf`
      modalRef.componentInstance.esTercera = nombreResolucion == 'TERCERAS' ? true : false;
      this.grabando = false;
      modalRef.result.then(() => {
        this.activeModal.close(false);
      });
      }, (error) => {
        this.grabando = false;
        this.messageService.showErrorMessage(error.description);
        this.activeModal.close(false);
      })
  }

  
}
