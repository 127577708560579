<!-- Full screen modal -->
<div id="monitorPedidos">
    <div *ngIf="this.pedidos" class="row">
        <div class="mb-3 col-md-12">
            <div class="footer border row">
                <div id="segundos" class="col-md-1">
                    <label class="col-form-label">Segundos: {{ this.segundos }} </label>
                </div>
                <div id="paleta" class="col-md-9">
                    <ul class="lista list-group">
                        <li *ngFor="let item of this.configuracion.configuracionesTiempo"> 
                            <span [ngStyle]="{'color':item.color}">
                                <i class="fa fa-square" aria-hidden="true"></i>
                            </span>
                            {{item.minutos}} min antes de partida                        
                        </li>
                        <li> 
                            <span [ngStyle]="{'color': this.configuracion.colorPrecargado}">
                                <i class="fa fa-square" aria-hidden="true"></i>
                            </span>
                            Precargado
                        </li>
                        <li> 
                            <span [ngStyle]="{'color': this.configuracion.colorCargado}">
                                <i class="fa fa-square" aria-hidden="true"></i>
                            </span>
                            Cargado
                        </li>
                        <li> 
                            <span [ngStyle]="{'color': this.configuracion.colorPrioridad}">
                                <i class="fa fa-square" aria-hidden="true"></i>
                            </span>
                            Prioridad
                        </li>
                        <li *ngIf="this.configuracion.permiteActualizacionPedidos">
                            <span><i style="color: green;" class="fa fa-check" aria-hidden="true"></i></span>
                            Actualización
                        </li>
                        <li *ngIf="this.configuracion.permiteCreacionPedidos">
                            <span><i style="color: green;" class="fa fa-check" aria-hidden="true"></i></span>
                            Creación
                        </li>
                    </ul>   
                </div>
                <div id="hora" class="col-md-2">
                    <label class="col-form-label">Fecha: {{ this.fechaHoy | date:"dd'/'MM'/'yy HH:mm:ss" }} </label>               
                </div>    
                
            </div>
        </div>
    </div>
    <div *ngIf="this.pedidos" class="row">        
        <!--<div *ngIf="!obteniendoPedidos" class="col-md-12" style="justify-content: center;">        
            <loading-text-spinner message="Actualizando pedidos de TAMS..." style="background-color: #0000;"
                orientation="vertical" class="list-group-item" size="large" color="primary" textColor="white">
            </loading-text-spinner>
        </div>-->        
        <table class="table table-border">
            <thead class="text-center">
                <th>Vuelo</th>
                <th>Estado TAMS</th>
                <th>Matrícula</th>
                <th>Hora Llegada</th>
                <th>Hora Partida</th>
                <th>Destino</th>
                <th>Estado Pedido</th>
                <th>Posición</th>
                <th>Cantidad Cargada</th>            
                <th>Minutos</th>            
            </thead>
            <ng-container *ngIf="obteniendoPedidos">
                <ngx-spinner
                    bdColor="rgba(19,123,198,0.7)"
                    size="medium"
                    color="#fff"                
                    type="ball-scale-multiple">
                    <p style="font-size: 20px; color: white">
                        <br/>
                            Actualizando pedidos de TAMS.
                        <br/>
                        Espere un momento por favor.
                    </p>
                </ngx-spinner>
            </ng-container>
            <ng-container *ngIf="!obteniendoPedidos">
                <tbody class="text-center">
                    <tr *ngFor="let p of pedidosAux"
                        [ngStyle]="{'background-color':p.color}"
                    >
                        <td>{{ p.vuelo ? p.vuelo : "-" }}</td>
                        <td>{{ p.estadoTams ? p.estadoTams : "-"}}</td>
                        <td>{{ p.matricula ? p.matricula.toUpperCase() : "-"}}</td>
                        <td>{{ p.fechaHoraEstimada | date: "dd'/'MM'/'y hh:mm a" }}</td>
                        <td>{{ p.fechaHoraVuelo | date: "dd'/'MM'/'y hh:mm a" }}</td> 
                        <td>{{ p.destino }}</td>        
                        <td>{{ this.obtenerTipoEstado(p.estado) + this.transformarTexto(p.estado, p.texto)}}</td>
                        <td>{{ p.posicion ? p.posicion : "Sin posición"}}</td>
                        <td>{{ p.cantidadCargada + " / " + p.cantidadTotal }}</td>                 
                        <td>{{ p.minutos }}</td>                 
                    </tr>
                </tbody>
            </ng-container>            
        </table>                         
    </div>          
</div>        

<!-- ful screen -->