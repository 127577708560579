<ngb-alert type="danger" [dismissible]="false" *ngIf="msgError">
  Se produjo un error al emitir la nota de débito.
</ngb-alert>
<div class="modal-header">
  <h5>Emisión de nota de débito.</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="this.modal.dismissAll()"></button>
</div>
<div class="modal-body">
  <form id="formDebito" ng-hide="formVisible" *ngIf="!this.msgError">
    <div [formGroup]="renglonForm" (ngSubmit)="agregarNuevoRenglonDinamico()" ng-hide="formVisible" class="row"
      novalidate>
      <form ngbDropdown autoClose="outside" class="row justify-content-center">
        <div class="mb-3 text-center pe-1">
          <label for="rubroId" class="fw-bold letra">*Rubro</label>
          <div [formGroup]="renglonForm" novalidate>
            <div formArrayName="renglonesDinamicos">
              <div *ngFor="let unit of renglonForm.get('renglonesDinamicos')['controls']; let i=index">
                <div [formGroupName]="i">
                  <select formControlName="rubroId" class="form-select letra2 ms-2" title="Campo requerido" required>
                    <option *ngFor="let r of rubros" [value]="r" [selected]="rubros[1]" ngDefaultControl>
                      {{ this.obtenerTipoNeto(r.tipoNeto)}}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mb-3 text-center pe-2">
          <label class="fw-bold letra">*Detalle</label>
          <div [formGroup]="renglonForm" novalidate>
            <div formArrayName="renglonesDinamicos">
              <div *ngFor="let unit of renglonForm.get('renglonesDinamicos')['controls']; let i=index">
                <div [formGroupName]="i">
                  <input type="detalle" class="form-control letra2 ms-2" id="formGroupDetalle" formControlName="detalle"
                    size="16" placeholder="Detalle" title="Campo requerido" required>
                  <small *ngIf="!renglonForm.valid" id="mensajeDetalle" class="text-danger"
                    style="word-wrap: break-word;"></small>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mb-3 text-center pe-2">
          <label class="fw-bold letra">*Neto</label>
          <div [formGroup]="renglonForm" novalidate>
            <div formArrayName="renglonesDinamicos">
              <div *ngFor="let unit of renglonForm.get('renglonesDinamicos')['controls']; let i=index">
                <div [formGroupName]="i">
                  <input (keypress)="onlyDecimalNumberKey($event)" type="neto" class="form-control letra2 ms-2"
                    id="formGroupNeto" size="8" formControlName="neto" placeholder="Neto" title="Campo requerido"
                    required>
                  <small *ngIf="!renglonForm.valid" id="mensajeNeto" class="text-danger"
                    style="word-wrap: break-word;"></small>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mb-3 text-center pe-2">
          <label class="fw-bold letra">*IVA</label>
          <div [formGroup]="renglonForm" novalidate>
            <div formArrayName="renglonesDinamicos">
              <div *ngFor="let unit of renglonForm.get('renglonesDinamicos')['controls']; let i=index">
                <div [formGroupName]="i">
                  <select id="formIva" formControlName="alicuotaIvaId" class="form-select letra2 ms-2"
                    title="Campo requerido" required>
                    <option class="form-control letra2" *ngFor="let a of alicuotas; let index = index"
                      [value]="a.id" ngDefaultControl>
                      {{ a.text }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mb-3 text-center pe-2">
          <label class="fw-bold letra">Imp. Int.</label>
          <div [formGroup]="renglonForm" novalidate>
            <div formArrayName="renglonesDinamicos">
              <div *ngFor="let unit of renglonForm.get('renglonesDinamicos')['controls']; let i=index">
                <div [formGroupName]="i">
                  <input (keypress)="onlyDecimalNumberKey($event)" formControlName="impuestoInterno" size="5"
                    class="form-control letra2 ms-1" placeholder="Imp Int">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mb-3 text-center pe-2">
          <label class="fw-bold letra">CO2</label>
          <div [formGroup]="renglonForm" novalidate>
            <div formArrayName="renglonesDinamicos">
              <div *ngFor="let unit of renglonForm.get('renglonesDinamicos')['controls']; let i=index">
                <div [formGroupName]="i">
                  <input (keypress)="onlyDecimalNumberKey($event)" class="form-control letra2" size="5"
                    formControlName="co2" placeholder="CO2">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mb-3 text-center pe-2">
          <label class="fw-bold letra"> Borrar </label>
          <div [formGroup]="renglonForm" novalidate>
            <div formArrayName="renglonesDinamicos">
              <div *ngFor="let unit of renglonForm.get('renglonesDinamicos')['controls']; let i=index">
                <div [formGroupName]="i">
                  <a ngbTooltip="Eliminar renglon" (click)="eliminarRenglonesDinamicos(i)"><i
                      class="fa fa-minus-square fa-2x text-primary"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="mb-3">
      <button class="btn btn-outline-primary" id="agregar" (click)="agregarNuevoRenglonDinamico()">Añadir</button>
    </div>
    <div class="mb-3">
      <div class="row justify-content-center">
        <table class="table table-sm table-striped  border broder-dark; text-center" style="width: 550px;">
          <thead>
            <tr>
              <th scope="col" *ngFor="let sum of sumatoria" class="letra">{{sum.header}}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{totalNeto | number: '0.1-2' }}</td>
              <td>{{totalIva | number: '0.1-2' }}</td>
              <td>{{totalImpInternos | number: '0.1-2' }}</td>
              <td>{{totalCo2 | number: '0.1-2' }}</td>
              <td>{{totalPercepciones | number: '0.1-2' }}</td>
              <td>{{totalTotal | number: '0.1-2' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <small class="text-danger">*Campos obligatorios.</small>
  </form>
</div>
<div class="modal-footer justify-content-md-center">
  <button class="btn btn-outline-primary" (click)="emitirNotaDebito()" [disabled]="emitiendoNota">
    <span *ngIf="!emitiendoNota">Emitir</span>
    <div *ngIf="emitiendoNota" class="d-flex align-items-center">
      <div class="spinner-border text-light spinner-border-sm" role="status"></div>
      <span>Emitiendo...</span>
    </div>
  </button>
  <button (click)="this.modal.dismissAll()" class="btn btn-outline-secondary" type="button">Cancelar</button>
</div>
