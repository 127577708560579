import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CuponPagoTarjeta } from '../models/facturacion/CuponPagoTarjeta';

@Component({
  selector: 'app-visor-cupon',
  templateUrl: './visor-cupon.component.html',
  styleUrls: ['./visor-cupon.component.scss']
})
export class VisorCuponComponent implements OnInit {

  @Input() cupones: CuponPagoTarjeta[];
  _cupones: CuponPagoTarjeta[];
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this._cupones=this.cupones.filter(c=> c.pathCuponFisico != '');
    console.log(this._cupones);
  }

}
