<nav class="navbar navbar-light bg-light navbar-expand-lg mb-3">
  <form class="form-inline flex-fill" id="formularioFiltros">
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <ul class="navbar-nav me-auto">
      
      <!--PRODUCTO-->
      <li class="nav-item">
        <div ngbDropdown autoClose="outside">
          <button class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2" id="dropdownProductos"
            ngbDropdownToggle>Productos</button>
          <div ngbDropdownMenu aria-labelledby="dropdownProductos">
            <div class="px-4 py-3">
              <div *ngFor="let prod of productosParaCheckear;let i = index">
                <div class="form-check">
                  <input type="checkbox" name="productos+{{i}}" class="form-check-input"
                    id="{{ 'dropdownCheck' + prod.nombre}}" [(ngModel)]="prod.seleccionado"
                    (change)="filtrarMovimientos($event)">
                  <label class="form-check-label text-nowrap" for="{{ 'dropdownCheck' + prod.nombre}}">
                    {{prod.nombre}}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>

      <!--Movimientos-->
      <li class="nav-item">
        <div ngbDropdown autoClose="outside">
          <button class="btn btn-outline-primary me-2" id="dropdownTipoMovimiento"
            ngbDropdownToggle>Movimientos</button>
          <div ngbDropdownMenu aria-labelledby="dropdownTipoMovimiento" id="origen">
            <div class="px-4 py-3">
              <div *ngFor="let movi of tipoMovimientos; let i = index">
                <div class="form-check">
                  <input type="checkbox" name="tipoMovimieto{{i}}" class="form-check-input"
                    id="{{ 'dropdownCheck' + movi.nombre}}" [(ngModel)]="movi.seleccionado"
                    (ngModelChange)="movi.seleccionado = $event" (change)="filtrarMovimientos($event)">
                  <label class="form-check-label text-nowrap" for="{{ 'dropdownCheck' + movi.nombre}}">
                    {{movi.nombre}}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>

      <!-- desde -->
      <li class="nav-item me-2">
        <div class="input-group" style="flex-wrap: nowrap">

          <input type="date" [ngModel]="modelDesde" class="fechaFormControlDesdeHasta" placeholder="aaaa-mm-dd"
            (ngModelChange)="modelDesde = $event" name="dt" ngbDatepicker #d="ngbDatepicker" value="modelDesde"
            ngbTooltip="Seleccione una fecha de inicio" class="form-control form-control-rounded"
            #searchDate readonly>

          <div class="input-group-append">
            <button id="botonDesde" type="button" class="input-group-btn btn btn-secondary" (click)="d.toggle()">
              <i class="fa fa-calendar" aria-hidden="true"></i></button>
          </div>
        </div>
      </li>
     
      <!-- hasta -->
      <li class="nav-item">
        <div class="input-group" style="flex-wrap: nowrap">
          <input type="date" [ngModel]="modelHasta" class="fechaFormControlDesdeHasta" placeholder="aaaa-mm-dd"
            (ngModelChange)="modelHasta = $event" name="dt" ngbDatepicker #h="ngbDatepicker" value="modelHasta"
            ngbTooltip="Seleccione una fecha de fin" class="form-control form-control-rounded"
            #searchDate readonly>

          <div class="input-group-append">
            <button id="botonHasta" type="button" class="input-group-btn btn btn-secondary" (click)="h.toggle()"><i
                class="fa fa-calendar" aria-hidden="true"></i></button>
          </div>
        </div>
      </li>

      <li class="nav-item">
        <button (click)="this.buscarDesdeHasta()" class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2"
          type="submit"><i class="fa fa-search" aria-hidden="true"></i>
        </button>
      </li>

      <div class="container-fluid d-flex">
        <li class="nav-item" *ngIf="this.hasRole(TipoUsuario.OPERADOR, TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL_AV)">
          <button class="btn btn-primary" type="button" (click)="nuevoIngresoDucto()">Ingreso Ducto</button>
        </li>
        <li class="nav-item" *ngIf="this.hasRole(TipoUsuario.OPERADOR, TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL_AV)">
          <button class="btn btn-primary ms-2" type="button" (click)="nuevoIngresoTrasvase()">Nuevo Trasvase</button>
        </li>
        <li class="nav-item" *ngIf="this.hasRole(TipoUsuario.OPERADOR, TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL_AV)">
          <button class="btn btn-primary ms-2" type="button" (click)="emisionDeRemito()">Emisión Remito</button>
        </li>
        <li class="nav-item" *ngIf="this.hasRole(TipoUsuario.TUTOR)">
          <button class="btn btn-primary ms-2" type="button" (click)="ajusteManualCombustible()">Ajuste Combustible</button>
        </li>
        <li class="nav-item" *ngIf="this.hasRole(TipoUsuario.OPERADOR, TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL_AV)">
          <button class="btn btn-primary ms-2" type="button" (click)="ajusteManualAeroespecialidad()">Ajuste Aeroespecialiad</button>
        </li>
      </div>
    </ul>
  </form>
</nav>
<ngb-alert type="danger" [dismissible]="false" *ngIf="fechaDesdeMayorQueHasta()==true">
  La fecha inicial debe ser anterior a la fecha final.
</ngb-alert>

<ngb-alert [dismissible]="false"
  *ngIf="!fechaDesdeMayorQueHasta() && !obteniendoMovimientos && transaccionesFiltrados!=null && transaccionesFiltrados.length==0">
  No existen movimientos para la busqueda seleccionada</ngb-alert>

<form id="formularioTablaMovimientos">
  <div class="col-md-12">
    <div *ngIf="obteniendoMovimientos" class="spinner-container">
      <ngx-spinner name="spinnerLista" bdOpacity=0.3bd
        Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
        <p style="color: white"> O b t e n i e n d o M o v i m i e n t o s </p>
      </ngx-spinner>
    </div>
    <div *ngIf="!obteniendoMovimientos" id="tablaMovimientos">
      <table id="tablaMovimientosDesdeHasta" class="table table-striped table-hover text-center"
        *ngIf="this.transaccion!=null && transaccionesFiltrados.length > 0">
        <thead class="">
          <tr>
            <th scope="col" style="width: 20%;">Fecha / Hora </th>
            <th scope="col">Tipo de movimiento </th>
            <th scope="col" style="width: 20%;">Producto</th>
            <th scope="col">Titulo</th>
            <th scope="col">Responsable</th>
          </tr>
        </thead>
        <tbody class="" *ngIf="this.transaccion!=null">
          <tr style="cursor: pointer;" *ngFor="let trans of transaccionesFiltrados; let i=index"
            (click)="mostrarDetalle(i,content)">
            <td style="width: 20%;">{{trans.fechaHora | date: "d'/'MM'/'y' 'hh:mm a" }}</td>
            <td>{{this.obtenerTipoMovimiento(trans.tipoTransaccion)}}</td>
            <td style="width: 20%;">
              <ul class="list-unstyled" *ngFor="let prod of trans.productos">
                <li>{{prod.codigoProducto}}-{{prod.nombreProducto}}</li>
              </ul>
            </td>
            <td>{{trans.titulo}}</td>
            <td>{{trans.responsable}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</form>
<ng-template #content let-modal>
  <app-movimiento-detalle *ngIf="detalleDeMovimiento" [transaccionSeleccionada]='transaccionSeleccionada' (resultado)="recargarPagina()">
  </app-movimiento-detalle>
</ng-template>
