<div class="modal-header bg-light">
  <h4 class="modal-title">Nueva cuenta</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismissAll()"></button>
</div>
<div class="modal-body" [formGroup]="nuevaCuentaForm">
  <div class="row">
    <!--NUmero Cuenta-->
    <div class="mb-3 col-6 mt-2">
      <div class="mb-3 row">
        <div class="col-5">
          <label for="numeroCuentaFormControl" class="float-end col-form-label">*Número Cuenta:</label>
        </div>
        <div class="col-7">
          <input class="form-control" formControlName="numeroCuentaFormControl" id="numeroCuentaFormControl"
            placeholder="Numero" (keypress)="onlyNumberAndLetterKey($event)" (blur)="checkerDisponibilidad()" maxlength="10" />
          <div
            *ngIf="numeroCuenta.invalid && numeroCuenta.errors.required && (numeroCuenta.dirty || numeroCuenta.touched)"
            class="alert alert-danger">
            Requerido
          </div>
          <div *ngIf="this.existeCuenta" class="alert alert-danger">
            La cuenta ya existe, debe ingresar otra.
          </div>
          <div *ngIf="this.cuentaMalSeteada" class="alert alert-danger">
            No puede cambiar el numero de la cuenta si comienza con PAD.
          </div>
          <loading-text-spinner *ngIf="this.verificandoCuenta" message="Verificando cuenta..." color="primary">
          </loading-text-spinner>
        </div>
      </div>
    </div>
    <!--Condicion Venta-->
    <div class="mb-3 col-6 mt-2">
      <div class="mb-3 row">
        <div class="col-5">
          <label for="condicionVentaFormControl" class="float-end col-form-label">*Condición venta:</label>
        </div>
        <div class="col-7">
          <select class="form-select" id="condicionVentaFormControl" formControlName="condicionVentaFormControl">
            <option *ngFor="let tcuen of keysTipoCuenta()">
              {{ tcuen }}
            </option>
          </select>
          <div
            *ngIf="condicionVenta.invalid && condicionVenta.errors.required && (condicionVenta.dirty || condicionVenta.touched)"
            class="alert alert-danger">
            Requerido
          </div>
        </div>
      </div>
    </div>
    <!--MONEDA-->
    <div class="mb-3 col-6">
      <div class="mb-3 row">
        <div class="col-4">
          <label for="monedaFormControl" class="float-end col-form-label">*Moneda:</label>
        </div>
        <div class="col-8">
          <select class="form-select" id="monedaFormControl" formControlName="monedaFormControl" required>
            <option *ngFor="let mon of keysMonedas()">
              {{ mon }}
            </option>
          </select>
          <div *ngIf="moneda.invalid && moneda.errors.required && (moneda.dirty || moneda.touched)"
            class="alert alert-danger">
            Requerido
          </div>
        </div>
      </div>
    </div>
    <!--ENTREGA FACTURA-->
    <div class="mb-3 col-6">
      <div class="mb-3 row">
        <div class="col-5">
          <label for="entregaFacturaFormControl" class="float-end col-form-label">Tipo de entrega de
            factura:</label>
        </div>
        <div class="col-7">
          <select class="form-select" id="entregaFacturaFormControl" formControlName="entregaFacturaFormControl">
            <option *ngFor="let tipoEntrega of keysTipoEntrega()">
              {{ tipoEntrega }}
            </option>
          </select>
          <div
            *ngIf="entregaFactura.invalid && entregaFactura.errors.required && (entregaFactura.dirty || entregaFactura.touched)"
            class="alert alert-danger">
            Requerido
          </div>
        </div>
      </div>
    </div>
    <!--Identificador-->
    <div class="mb-3 col-6 mt-2">
      <div class="mb-3 row">
        <div class="col-5">
          <label for="identificadorFormControl" class="float-end col-form-label">*Identificador:</label>
        </div>
        <div class="col-7">
          <input class="form-control" formControlName="identificadorFormControl" id="identificadorFormControl"
            placeholder="Numero" />
          <div
            *ngIf="identificador.invalid && identificador.errors.required && (identificador.dirty || identificador.touched)"
            class="alert alert-danger">
            Requerido
          </div>
        </div>
      </div>
    </div>
    <!--Email-->
    <div class="mb-3 col-6 mt-2">
      <div class="mb-3 row">
        <div class="col-5">
          <label for="emailFormControl" class="float-end col-form-label">Email:</label>
        </div>
        <div class="col-7">
          <input class="form-control" formControlName="emailFormControl" id="emailFormControl" />
        </div>
      </div>
    </div>
    <!--Plazo Pago-->
    <div class="mb-3 col-6 mt-2">
      <div class="mb-3 row">
        <div class="col-5">
          <label for="plazoPagoFormControl" class="float-end col-form-label">Plazo Pago:</label>
        </div>
        <div class="col-7">
          <select class="form-select" formControlName="plazoPagoFormControl" id="plazoPagoFormControl">
            <option *ngFor="let p of condicionesPago" [ngValue]="p">
              ({{ p.codigo }}) {{ p.descripcion }}
            </option>
          </select>
        </div>
      </div>
    </div>
    
    <!--NOTIFICACION-->
    <!--
    <div class="mb-3 col-3"></div>
    <div class="mb-3 col-3">
      <input type="checkbox" class="form-check-input" id="exampleCheck3" formControlName="notificacionFormControl" />
      <label class="form-check-label" for="exampleCheck3">Notificación por mail</label>
    </div>
    -->

    <!--PERIODO FACTURACION-->
    <div class="mb-3 col-6">
      <div class="mb-3 row">
        <div class="col-6">
          <label for="periodoFormControl" class="float-end col-form-label">Período de facturación:</label>
        </div>
        <div class="col-6">
          <select class="form-select" id="periodoFormControl" formControlName="periodoFormControl">
            <option value="Diario">Diario</option>
            <option value="Semanal">Semanal</option>
            <option value="Quincenal">Quincenal</option>
            <option value="Mensual">Mensual</option>
          </select>
        </div>
      </div>
    </div>
    <div class="mb-3 col-6" *ngIf="this.periodo.value != 'Semanal'"></div>
    <div class="mb-3 col-6" *ngIf="this.periodo.value == 'Semanal'">
      <div class="mb-3 row">
        <div class="col-6">
          <label for="diasFormControl" class="float-end col-form-label">Dia:</label>
        </div>
        <div class="col-6">
          <select class="form-select" id="diasFormControl" formControlName="diasFormControl">
            <option [value]="1">Domingo</option>
            <option [value]="2">Lunes</option>
            <option [value]="3">Martes</option>
            <option [value]="4">Miercoles</option>
            <option [value]="5">Jueves</option>
            <option [value]="6">Viernes</option>
            <option [value]="7">Sabado</option>
          </select>
        </div>
      </div>
    </div>
    <!--AGRUPACION REMITOS-->
    <div class="mb-3 col-6">
      <div class="mb-3 row">
        <div class="col-5">
          <label for="agrupacionRemitosFormControl" class="float-end col-form-label">Agrupación de
            remitos:</label>
        </div>
        <div class="col-7">
          <select class="form-select" id="agrupacionRemitosFormControl" formControlName="agrupacionRemitosFormControl"
            required>
            <option *ngFor="let agrup of keysAgrupacion()">
              {{ agrup }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <!--CHECK SOLO PAD-->
    <div class="mb-3 col-3"></div>
    <div class="col-3">
      <input type="checkbox" class="form-check-input" id="soloPADRemitosFormControl"  formControlName="soloPADRemitosFormControl" [value]="false" />
      <label for="soloPADRemitosFormControl" class="form-check-label ms-2">Solo PAD</label>
    </div>

    <!--Cuenta SAP-->
    <div class="mb-3 col-6">
      <div class="mb-3 row">
        <div class="col-5">
          <label for="ctaSapCuentaFormControl" class="float-end col-form-label">Cuenta SAP:</label>
        </div>
        <div class="col-7">
          <input class="form-control" formControlName="ctaSapCuentaFormControl" id="ctaSapCuentaFormControl" />
        </div>
      </div>
    </div>

  </div>
  <small class="text text-danger">*Campos obligatorios.</small>
  <div *ngIf="error != null" class="alert alert-danger">
    {{error}}
  </div>
</div>
<div class="modal-footer bg-light justify-content-center">
  <button class="btn btn-primary me-2" [disabled]="creandoCuenta" (click)="crearCuenta()">
    <span *ngIf="!creandoCuenta">Guardar</span>
    <div *ngIf="creandoCuenta" class="d-flex align-items-center">
      <div class="spinner-border text-light spinner-border-sm" role="status"></div>
      <span>Guardando...</span>
    </div>
  </button>
  <button class="btn btn-secondary" (click)="activeModal.dismissAll()">Cancelar</button>
</div>