import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlCreditoRoutingModule } from './control-credito-routing.module';
import { ControlCreditoIndexComponent } from './control-credito-index/control-credito-index.component';
import { ReporteSaldosComponent } from './reporte-saldos/reporte-saldos.component';
import { ReportePendientesPadComponent } from './reporte-pendientes-pad/reporte-pendientes-pad.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ModalNotificacionComponent } from './modal-notificacion/modal-notificacion.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ModalCrearNotificacionComponent } from './modal-crear-notificacion/modal-crear-notificacion.component';
import { ModalHistorialEstadosComponent } from './modal-historial-estados/modal-historial-estados.component';
import { ModalActualizarEstadoComponent } from './modal-actualizar-estado/modal-actualizar-estado.component';

@NgModule({
  declarations: [
    ControlCreditoIndexComponent,
    ReporteSaldosComponent,
    ReportePendientesPadComponent,
    ModalNotificacionComponent,
    ModalCrearNotificacionComponent,
    ModalHistorialEstadosComponent,
    ModalActualizarEstadoComponent
  ],
  imports: [
    CommonModule,
    NgxSpinnerModule,
    ControlCreditoRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    InfiniteScrollModule
  ]
})
export class ControlCreditoModule { }
