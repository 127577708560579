import { Producto } from '../abm/Producto';
import { Comprobante } from './Comprobante';
import { Movimiento } from './Movimiento';

export class Transaccion{
    id: string;
    movimientos: Movimiento[];
    tipoTransaccion: number;
    responsable: string;
    productos: Producto[];    
    codigoAeroplanta: string;
    fechaHora: string;
    titulo: string;
    observacion: string;
    comprobante: Comprobante;
}