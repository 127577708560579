<div class="card" *ngIf="!this.pasarEtapa">
  <div class="card-header">
    <app-cierre-nav-bar></app-cierre-nav-bar>
  </div>

  <div class="card-body">
    <!--ABASTECEDORAS-->
    <div class="col-12">
      <button style="width: 100%;" class="btn btn-outline-primary btn-block accordion-head collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#comAforador" 
        aria-expanded="false" aria-controls="comAforador">Comparativas aforadores <i class="fa fa-angle-down" aria-hidden="true"></i>
      </button>
      <div class="border border-solid collapse" id="comAforador">
        <table class="table table-striped table-hover text-center">
          <thead>
            <tr>
              <th>Expendedora</th>
              <th>Producto</th>
              <th>Aforador inicial</th>
              <th>Aforador final</th>
              <th>Diferencia entre aforadores</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let aba of comparativaAbastecedora">
              <td>{{aba.nombreExpendedora}} / {{aba.picoNombre}}</td>
              <td>{{aba.codigoProducto}}-{{aba.nombreProducto}}</td>
              <td>{{aba.aforadorInicial | number: '0.0-0'}}</td>
              <td>{{aba.aforadorFinal | number: '0.0-0'}}</td>
              <td>{{aba.diferenciaAforadores | number: '0.0-0'}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!--LITROS DESPACHADOS-->
    <div class="col-12 mt-2">      
      <button style="width: 100%;" class="btn btn-outline-primary btn-block accordion-head collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#comSalida" 
        aria-expanded="false" aria-controls="comSalida">Comparativas salidas <i class="fa fa-angle-down" aria-hidden="true"></i>
      </button>
      <div class="border border-solid collapse" id="comSalida">
        <table class="table table-striped table-hover text-center">
          <thead>
            <tr>
              <th>Expendedora</th>
              <th>Producto</th>
              <th>Salida por archivo</th>
              <th>Salida por aforador</th>
              <th>Diferencia</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let aba of comparativaAbastecedora">
              <td>{{aba.nombreExpendedora}} / {{aba.picoNombre}}</td>
              <td>{{aba.codigoProducto}}-{{aba.nombreProducto}}</td>
              <td>{{aba.salidaArchivo | number: '0.0-0'}}</td>
              <td>{{aba.aforadorFinal - aba.aforadorInicial | number: '0.0-0'}}</td>
              <td>{{aba.diferenciaArchivoAforador | number: '0.0-0'}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
 
    <div *ngFor="let dist of distintosProducto; let i=index">
      <!--TANQUES NATURAL-->
      <div class="col-12 mt-2">
        <button style="width: 100%;" [ngClass]="{'accordion-head collapsed': natural[i] }" class="btn btn-outline-primary btn-block" type="button" (click)="natural[i]=!natural[i]" 
          aria-expanded="false" aria-controls="{{dist}}">Comparativas {{dist}} natural <i style="float: right; font-size: 1.4em;" class="fa fa-angle-down" aria-hidden="true"></i>
        </button>
        <div class="border border-solid collapse" id="{{dist}}" [ngbCollapse]="natural[i]">          
          <table class="table table-striped table-hover text-center">
            <thead>
              <tr>
                <th>Contenedor</th>
                <th>Existencia anterior natural</th>
                <th>Entradas</th>
                <th>Salidas</th>
                <th>Existencia en libros natural</th>
                <th>Existencia real natural</th>
                <th>Deficit/Superavit</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let tn of this.comparativaTanqueNatural">
                <ng-container *ngIf="dist == tn.codigoProducto + ' - ' + tn.nombreProducto">
                  <td>{{tn.nombreContenedor}}</td>
                  <td>{{tn.existenciaAnterior | number: '0.0-0'}}</td>
                  <td>{{tn.entradas | number: '0.0-0'}}</td>
                  <td>{{tn.salidas | number: '0.0-0'}}</td>
                  <td>{{tn.existenciaFinal | number: '0.0-0'}}</td>
                  <td>{{tn.existenciaReal | number: '0.0-0'}}</td>
                  <td>{{tn.diferencia | number: '0.0-0'}}</td>
                </ng-container>
              </tr>
              <tr>
                <td><b>TOTAL</b></td>
                <td>{{sumar(dist, 'existenciaAnterior') | number: '0.0-0'}}</td>
                <td>{{sumar(dist, 'entradas') | number: '0.0-0'}}</td>
                <td>{{sumar(dist, 'salidas') | number: '0.0-0'}}</td>
                <td>{{sumar(dist, 'existenciaFinal') | number: '0.0-0'}}</td>
                <td>{{sumar(dist, 'existenciaReal') | number: '0.0-0'}}</td>
                <td>{{sumar(dist, 'diferencia') | number: '0.0-0'}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!--TANQUES 15-->
      <div class="col-12 mt-2">
        <button style="width: 100%;" [ngClass]="{'accordion-head collapsed': grado15[i] }" class="btn btn-outline-primary btn-block" type="button" (click)="grado15[i]=!grado15[i]"
          aria-expanded="false" aria-controls="{{dist}}">Comparativas {{dist}} 15° <i style="float: right; font-size: 1.4em;" class="fa fa-angle-down" aria-hidden="true"></i>
        </button>
        <div class="border border-solid" id="{{dist}}" [ngbCollapse]="grado15[i]">          
          <table class="table table-striped table-hover text-center">
            <thead>
              <th>Contenedor</th>
              <th>Existencia anterior 15°</th>
              <th>Entradas</th>
              <th>Salidas</th>
              <th>Existencia en libros 15°</th>
              <th>Existencia real 15°</th>
              <th>Deficit/Superavit</th>
            </thead>
            <tbody>
              <tr *ngFor="let t15 of this.comparativasTanques15">
                <ng-container *ngIf="dist == t15.codigoProducto + ' - ' + t15.nombreProducto">
                  <td>{{t15.nombreContenedor}}</td>
                  <td>{{t15.existenciaAnterior | number: '0.0-0'}}</td>
                  <td>{{t15.entradas | number: '0.0-0'}}</td>
                  <td>{{t15.salidas | number: '0.0-0'}}</td>
                  <td>{{t15.existenciaFinal | number: '0.0-0'}}</td>
                  <td>{{t15.existenciaReal | number: '0.0-0'}}</td>
                  <td>{{t15.diferencia | number: '0.0-0'}}</td>
                </ng-container>
              </tr>
              <tr>
                <td><b>TOTAL</b></td>
                <td>{{sumar2(dist, 'existenciaAnterior') | number: '0.0-0'}}</td>
                <td>{{sumar2(dist, 'entradas') | number: '0.0-0'}}</td>
                <td>{{sumar2(dist, 'salidas') | number: '0.0-0'}}</td>
                <td>{{sumar2(dist, 'existenciaFinal') | number: '0.0-0'}}</td>
                <td>{{sumar2(dist, 'existenciaReal') | number: '0.0-0'}}</td>
                <td>{{sumar2(dist, 'diferencia') | number: '0.0-0'}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!--Aeroespecialidades-->
    <div class="col-12 mt-2">
      <button style="width: 100%;" class="btn btn-outline-primary btn-block accordion-head collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#comAero" 
        aria-expanded="false" aria-controls="comAero">Comparativas aeroespecialidades <i class="fa fa-angle-down" aria-hidden="true"></i>
      </button>   
      <div class="border border-solid collapse" id="comAero">        
        <table class="table table-striped table-hover text-center"
          *ngIf="this.comparativaAeroespecialidades.length > 0">
          <thead>
            <th>Codigo</th>
            <th>Aeroespecialidad</th>
            <th>Inicial</th>
            <th>Entradas</th>
            <th>Salidas</th>
            <th>Existencia libros</th>
            <th>Existencia ingresada</th>
            <th>Diferencia</th>            
          </thead>
          <tbody>
            <tr *ngFor="let aeroesp of this.comparativaAeroespecialidades">
              <td>{{aeroesp.codigoProducto}}</td>
              <td>{{aeroesp.nombreProducto}}</td>
              <td>{{aeroesp.existenciaAnterior}}</td>
              <td>{{aeroesp.entradas}}</td>
              <td>{{aeroesp.salidas}}</td>
              <td>{{aeroesp.existenciaFinal}}</td>              
              <td>{{aeroesp.existenciaReal}}</td>
              <td>{{aeroesp.diferencia}}</td>
            </tr>
          </tbody>
        </table>
        <ngb-alert class="alert-warning" *ngIf="this.comparativaAeroespecialidades.length == 0">No hay
          aeroespecialidades en el cierre de turno.</ngb-alert>
      </div>
    </div>

    <!--Total productos a 15º-->
    <div class="col-12 mt-2">
      <button style="width: 100%;" class="btn btn-outline-primary btn-block accordion-head collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#tp15" 
        aria-expanded="false" aria-controls="tp15">Totales Productos 15° <i class="fa fa-angle-down" aria-hidden="true"></i>
      </button>   
      <div class="border border-solid collapse" id="tp15">        
        <table class="table table-striped table-hover text-center"
          *ngIf="this.totalProductos15.length > 0">
          <tr class="bordes">
            <th class="bordes" rowspan="2">Producto</th>            
            <th class="bordes" rowspan="2">Existencia anterior</th>  
            <th class="bordes" colspan="2">Entradas</th>
            <th class="bordes" colspan="4">Salidas</th> 
            <th class="bordes" rowspan="2">Existencia libros</th>
            <th class="bordes" rowspan="2">Existencia real</th>
            <th class="bordes" rowspan="2">Déficit/Superavit</th>
          </tr>
          <tr class="bordes">
            <th class="bordes">Cargamento</th>
            <th class="bordes">Alijes</th>
            <th class="bordes">Aerovales</th>
            <th class="bordes">Contado</th>
            <th class="bordes">Remitos</th>
            <th class="bordes">Interno</th>
          </tr>
          <tbody>
            <tr *ngFor="let tp of this.totalProductos15">
              <td>{{tp.codigoProducto}} - {{tp.nombreProducto}}</td>              
              <td>{{tp.existenciaAnterior | number: '0.0-0' }}</td>
              <td>{{tp.entradasCargamento | number: '0.0-0' }}</td>
              <td>{{tp.entradasAlije | number: '0.0-0' }}</td>
              <td>{{tp.salidasCtaCte | number: '0.0-0' }}</td>
              <td>{{tp.salidasContado | number: '0.0-0' }}</td>
              <td>{{tp.salidasRemito | number: '0.0-0' }}</td>
              <td>{{tp.salidasConsumoInterno | number: '0.0-0' }}</td>
              <td>{{tp.existenciaFinal | number: '0.0-0' }}</td>
              <td>{{tp.existenciaReal | number: '0.0-0' }}</td>
              <td>{{tp.diferencia | number: '0.0-0'}}</td>
            </tr>
          </tbody>
        </table>
        <ngb-alert class="alert-warning" *ngIf="this.totalProductos15.length == 0">No hay
          totales por productos a 15° en el cierre de turno.
        </ngb-alert>
      </div>
    </div>

    <!--Total productos natural-->
    <div class="col-12 mt-2">
      <button style="width: 100%;" class="btn btn-outline-primary btn-block accordion-head collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#tpNat" 
        aria-expanded="false" aria-controls="tpNat">Totales Productos Natural <i class="fa fa-angle-down" aria-hidden="true"></i>
      </button>   
      <div class="border border-solid collapse" id="tpNat">        
        <table class="table table-striped table-hover text-center"
          *ngIf="this.totalProductosNatural.length > 0">
          <tr class="bordes">
            <th class="bordes" rowspan="2">Producto</th>            
            <th class="bordes" rowspan="2">Existencia anterior</th>  
            <th class="bordes" colspan="2">Entradas</th>
            <th class="bordes" colspan="4">Salidas</th> 
            <th class="bordes" rowspan="2">Existencia libros</th>
            <th class="bordes" rowspan="2">Existencia real</th>
            <th class="bordes" rowspan="2">Deficit/Superavit</th>
          </tr>
          <tr class="bordes">
            <th class="bordes">Cargamento</th>
            <th class="bordes">Alijes</th>
            <th class="bordes">Aerovales</th>
            <th class="bordes">Contado</th>
            <th class="bordes">Remitos</th>
            <th class="bordes">Interno</th>
          </tr>
          <tbody>
            <tr *ngFor="let tp of this.totalProductosNatural">
              <td>{{tp.codigoProducto}} - {{tp.nombreProducto}}</td>              
              <td>{{tp.existenciaAnterior | number: '0.0-0' }}</td>
              <td>{{tp.entradasCargamento | number: '0.0-0' }}</td>
              <td>{{tp.entradasAlije | number: '0.0-0' }}</td>
              <td>{{tp.salidasCtaCte | number: '0.0-0' }}</td>
              <td>{{tp.salidasContado | number: '0.0-0' }}</td>
              <td>{{tp.salidasRemito | number: '0.0-0' }}</td>
              <td>{{tp.salidasConsumoInterno | number: '0.0-0' }}</td>
              <td>{{tp.existenciaFinal | number: '0.0-0' }}</td>
              <td>{{tp.existenciaReal | number: '0.0-0' }}</td>
              <td>{{tp.diferencia | number: '0.0-0' }}</td>
            </tr>
          </tbody>
        </table>
        <ngb-alert class="alert-warning" *ngIf="this.totalProductosNatural.length == 0">No hay
          totales por productos a natural en el cierre de turno.
        </ngb-alert>
      </div>
    </div>
  </div>
  <div class="card-footer">
    <div class="float-end">
      <!-- <button style="margin-right: 4em;" class="btn btn-primary" type="button" (click)="this.anterior()">Anterior</button> -->
      <button style="width: 100%;" class="btn btn-primary" type="button" (click)="continuarCierreTurno()" [disabled]="guardando">
        <span *ngIf="!guardando">Continuar cierre de turno</span>
        <div *ngIf="guardando" class="d-flex align-items-center">
          <div class="spinner-border text-light spinner-border-sm" role="status"></div>
          <span>Continuando cierre...</span>
        </div>
      </button>
    </div>
  </div>
</div>

<div *ngIf="this.pasarEtapa">
  <app-parte-diario-cierre-turno [cierreTurno]="cierreTurnoContinuo"></app-parte-diario-cierre-turno>
</div>