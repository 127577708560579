<div class="card" *ngIf="this.aeroplantaSeleccionada != null" [formGroup]="aeroplantaFormGroup">
  <div class="card-header">
    <h5>Aeroplanta: {{editando ? this.aeroplantaSeleccionada.nombre : 'Nueva Aeroplanta'}}</h5>
  </div>
  <div class="card-body">
    <!--NOMBRE-->
    <div class="mb-3 col-12">
      <div class="mb-3 row">
        <div class="col-4">
          <label>*Nombre:</label>
        </div>
        <div class="col-8">
          <input class="form-control" formControlName="nombreFormControl" required>
        </div>
      </div>
    </div>

    <!--CODIGO-->
    <div class="mb-3 col-12">
      <div class="mb-3 row">
        <div class="col-4">
          <label class="col-form-label" for="codigoFormControl">*Codigo:</label>
        </div>
        <div class="col-8">
          <input class="form-control" formControlName="codigoFormControl" required>
        </div>
      </div>
    </div>

    <!--NUMERO AEROPLANTA-->
    <div class="mb-3 col-12">
      <div class="mb-3 row">
        <div class="col-4">
          <label class="col-form-label" for="numeroAeroplantaFormControl">*Numero aeroplanta:</label>
        </div>
        <div class="col-8">
          <input class="form-control" formControlName="numeroAeroplantaFormControl" (keypress)="onlyNumberKey($event)" value="this.aeroplantaSeleccionada.numeroAeroplanta" required>
        </div>
      </div>
    </div>

    <!--CTA SGC DEL MANDATARIO-->
    <div class="mb-3 col-12">
      <div class="mb-3 row">
        <div class="col-4">
          <label class="col-form-label" for="mandatarioFormControl">*Cuenta mandatario:</label>
        </div>
        <div class="col-8">
          <input class="form-control" formControlName="mandatarioFormControl" (keypress)="onlyNumberKey($event)" required>
        </div>
      </div>
    </div>
    <!--NUMERO CENTRO EMISOR-->
    <div class="mb-3 col-12">
      <div class="mb-3 row">
        <div class="col-4">
          <label class="col-form-label" for="numeroCentroEmisorFormControl">*Numero centro emisor:</label>
        </div>
        <div class="col-8">
          <input class="form-control" formControlName="numeroCentroEmisorFormControl" (keypress)="onlyNumberKey($event)" required>
        </div>
      </div>
    </div>

    <!--EMISOR-->
    <div class="mb-3 col-12">
      <div class="mb-3 row">
        <div class="col-4">
          <label class="col-form-label" for="emisorFormControl">*Emisor:</label>
        </div>
        <div class="col-8">
          <select name="" id="emisorFormControl" [compareWith]="compararEmisor" class="form-select" formControlName="emisorFormControl">
            <option *ngFor="let emisor of emisores" [ngValue]="emisor">{{emisor.razonSocial}}</option>
          </select>
          <loading-text-spinner *ngIf="this.obteniendoEmisores" message="Obteniendo emisores..." color="primary">
          </loading-text-spinner>
        </div>
      </div>
    </div>

    <!--COMERCIAL-->
    <div class="mb-3 col-12">
      <div class="mb-3 row">
        <div class="col-4">
          <label class="col-form-label" for="comercialFormControl">*Comercial:</label>
        </div>
        <div class="col-8">
          <select id="comercialFormControl" class="form-select" formControlName="comercialFormControl">
            <option *ngFor="let comercial of usuariosComerciales">{{comercial.nombresCompletos}}</option>
          </select>
          <loading-text-spinner *ngIf="this.obteniendoComerciales" message="Obteniendo comerciales..." color="primary">
          </loading-text-spinner>
        </div>
      </div>
    </div>
    <!--DIRECCION-->
    <div class="mb-3 col-12">
      <div class="mb-3 row">
        <div class="col-4">
          <label class="col-form-label" for="direccionFormControl">*Dirección:</label>
        </div>
        <div class="col-8">
          <input id="direccionFormControl" type="text" class="form-control" formControlName="direccionFormControl" />
        </div>
      </div>
    </div>
    <!--DIRECCION COMPLEMENTO-->
    <div class="mb-3 col-12">
      <div class="mb-3 row">
        <div class="col-4">
          <label class="col-form-label" for="direccionComplementoFormControl">Direccion Complemento:</label>
        </div>
        <div class="col-8">
          <input id="direccionComplementoFormControl" type="text" class="form-control"
            formControlName="direccionComplementoFormControl" />
        </div>
      </div>
    </div>


    <!--PAIS-->
    <div class="mb-3 col-12">
      <div class="mb-3 row">
        <div class="col-4">
          <label class="col-form-label" for="paisFormControl">País:</label>
        </div>
        <div class="col-8">
          <input id="paisFormControl" type="text" class="form-control" formControlName="paisFormControl"
            placeholder="Seleccione una opcion..." [inputFormatter]="formatterPais" [(ngModel)]="modelPais"
            [resultFormatter]="formatterPais" [ngbTypeahead]="searchPais" (selectItem)="selectPais($event)" readonly />
          <loading-text-spinner *ngIf="this.cargandoPaises" message="Obteniendo paises..." color="primary">
          </loading-text-spinner>
        </div>
      </div>
    </div>
    <!--PROVINCIA-->
    <div class="mb-3 col-12">
      <div class="mb-3 row">
        <div class="col-4">
          <label class="col-form-label" for="provinciaFormControl">*Provincia:</label>
        </div>
        <div class="col-8">
          <input id="provinciaFormControl" type="text" class="form-control" formControlName="provinciaFormControl"
            placeholder="Seleccione una opcion..." [inputFormatter]="formatterProvincia" [(ngModel)]="modelProvincia"
            [resultFormatter]="formatterProvincia" [ngbTypeahead]="searchProvincia"
            (selectItem)="selectProvincia($event)" />
          <div *ngIf="provincia.invalid && provincia.errors.required && (provincia.dirty || provincia.touched)"
            class="alert alert-danger">
            Requerido
          </div>
          <loading-text-spinner *ngIf="this.cargandoProvincias" message="Obteniendo provincias..." color="primary">
          </loading-text-spinner>
        </div>
      </div>
    </div>
    <!--CODIGO POSTAL-->
    <div class="mb-3 col-12">
      <div class="mb-3 row">
        <div class="col-4">
          <label class="col-form-label" for="codigoPostalFormControl">*Ciudad:</label>
        </div>
        <div class="col-8">
          <input class="form-control" id="codigoPostalFormControl" formControlName="codigoPostalFormControl"
            placeholder="Seleccione una opcion..." [inputFormatter]="formatterCiudad" [(ngModel)]="modelCiudad"
            [resultFormatter]="formatterCiudad" [ngbTypeahead]="searchCiudad" (selectItem)="selectCiudades($event)" />
          <div
            *ngIf="codigoPostal.invalid && codigoPostal.errors.required && (codigoPostal.dirty || codigoPostal.touched)"
            class="alert alert-danger">
            Requerido
          </div>
          <loading-text-spinner *ngIf="this.cargandoCiudades" message="Obteniendo ciudades..." color="primary">
          </loading-text-spinner>
        </div>
      </div>
    </div>
    <!--SOLO PAD-->
    <div class="mb-3 col-3">
      <input type="checkbox" class="form-check-input" id="soloPADFormControl" formControlName="soloPADFormControl"
        [checked]="this.aeroplantaSeleccionada.soloPAD" />
      <label class="form-check-label" for="soloPADFormControl">Solo PAD</label>
    </div>
    <small class="text-danger">*Campos obligatorios.</small>
  </div>
  <div class="card-footer d-flex justify-content-center" *ngIf="this.hasRole(TipoUsuario.SEGURIDADTABLAS, TipoUsuario.JEFEDEAEROPLANTA)">
    <button [disabled]="guardando" class="btn btn-primary me-2" (click)="guardar()"> <span *ngIf="!guardando">Guardar</span>
      <div *ngIf="guardando" class="d-flex align-items-center">
        <div class="spinner-border text-light spinner-border-sm" role="status"></div>
        <span>Guardando...</span>
      </div>
    </button>
  </div>  
</div>