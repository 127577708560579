<loading-text-spinner *ngIf="obteniendoProvincia" [error]="errorProvincia" message="Obteniendo datos de provincia..."
  orientation="vertical" class="list-group-item" size="large" color="primary" textColor="dark">
</loading-text-spinner>

<div *ngIf="provinciaParamImpositivo && !obteniendoProvincia">
    <p style="font-weight: bold; font-size: 1.25em;">
        Parametros impositivos de: {{ provinciaParamImpositivo.provinciaNombre }}
        <button class="btn btn-primary pull-right" type="button" (click)="onAgregarCiudad()">
            Agregar ciudad
          </button>
    </p>
    
    <app-parametros-impositivos-agrupados [provincia]="provinciaParamImpositivo" [alicuotasIIBB]="alicuotasIIBB" [ingresosBrutos]="0" [ciudad]="null" [parametros]="provinciaParamImpositivo.parametros">    
    </app-parametros-impositivos-agrupados>

    <div #anchorsCiudades *ngFor="let ciudad of provinciaParamImpositivo.ciudades; let i = index">
        <app-parametros-impositivos-agrupados 
            [provincia]="provinciaParamImpositivo" 
            [ciudad]="ciudad" 
            [parametros]="ciudad.parametros">
        </app-parametros-impositivos-agrupados>
    </div>    
</div>