import { TipoUsuario } from '../../enums/tipoUsuario';
import { Abastecedora } from './Abastecedora';
import { Aeroplanta } from './Aeroplanta';
import { UsuarioAeroplanta } from '../abm/UsuarioAeroplanta';

export class Usuario {
    id: string;
    nombreCompleto: string;
    nombresCompletos: string;
    userName: string;
    tipoUsuario: TipoUsuario;
    dni : string;
    telefono: string;
    cargo : string;
    zonaHoraria : string;
    ultimaAeroplanta: Aeroplanta;
    ultimaAbastecedora: Abastecedora;
    email: string;
    password: string;
    token?: string;

    estado: number;
    roles: number[];
    sRoles: string;
    pathFirma: string;

    aeroplantas: UsuarioAeroplanta[];

    getNameTipoUsuario() {
        return TipoUsuario[this.tipoUsuario];
    }
}

