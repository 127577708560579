import { Component, OnInit } from '@angular/core';
import { ManagementService } from '../management.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { Producto } from 'src/app/shared/models/despacho/Producto';
import { UsuarioService } from 'src/app/account/usuario.service';
import { Guid } from 'guid-typescript';
import { MessageService } from 'src/app/shell/message.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-set-aeroespecialidades',
  templateUrl: './set-aeroespecialidades.component.html',
  styleUrls: ['./set-aeroespecialidades.component.css']
})
export class SetAeroespecialidadesComponent implements OnInit {

  cargandoAeroespecialidades: boolean = true;
  actualizandoAeroespecialidades: boolean = false;
  form: UntypedFormGroup;
  aeroespecialidades: Producto[];
  change$: Subscription;
  obteniendoPedidos: boolean;
  page: number;

  constructor(private managementService: ManagementService, 
    private formBuilder: UntypedFormBuilder,
    private spinner: NgxSpinnerService, 
    private usuarioService: UsuarioService, 
    private messageService: MessageService,
    sharedService: SharedService) {
      this.form = this.formBuilder.group({
        aeroespecialidades: new UntypedFormArray([])
      });
      this.change$ = sharedService.aeroplantaSeleccionada.subscribe(_=>{
        this.reload()
      })
  }

  ngOnInit() {
    this.cargandoAeroespecialidades = true;
    setTimeout(() => {
      this.spinner.show('spinnerLista');
    });

    this.managementService.getAllAeroespecialidades(this.usuarioService.getAeroplantaActual().codigoAeroplanta)
      .subscribe(result => {
        this.aeroespecialidades = result;
        this.addCheckboxes();
        this.spinner.hide('spinnerLista');
        this.cargandoAeroespecialidades = false;
      });
  }

  ngOnDestroy(): void {
    this.change$.unsubscribe();
  }

  private addCheckboxes() {
    // reinicio el formulario
    this.form.setControl('aeroespecialidades', this.formBuilder.array([]));
    this.aeroespecialidades.map((o, i) => {
      const control = new UntypedFormControl(i === 0); // if first item set to true, else false
      control.setValue(o.activo);
      (this.form.controls.aeroespecialidades as UntypedFormArray).push(control);
    });
  }

  cancelar() {
    this.form = this.formBuilder.group({
      aeroespecialidades: new UntypedFormArray([])
    });
    this.ngOnInit();
  }

  submit() {
    this.actualizandoAeroespecialidades = true;
    const selectedCodes = this.form.value.aeroespecialidades
      .map((v, i) => v ? this.aeroespecialidades[i].codigoProducto : null)
      .filter(v => v !== null);
    console.log(selectedCodes);
    this.spinner.show('spinnerDetalle');
    //calling to webapi
    var codigoAeroplanta = this.usuarioService.getAeroplantaActual().codigoAeroplanta;
    console.log('AeroplantaId ' + codigoAeroplanta);
    this.managementService.setAeroespecialidades(selectedCodes, codigoAeroplanta)
      .subscribe(response => {
        console.log(response);
        this.spinner.hide('spinnerDetalle');
        this.actualizandoAeroespecialidades = false;
        this.messageService.showSuccessMessage("Se han actualizado las aeroespecialidades");
      });
  }

  reload() {
    this.aeroespecialidades = [];
    setTimeout(() => {
      this.spinner.show('spinnerLista');
    });
    //let a = this.cambioAeroplanta$;

    //this.usuarioService.setAeroplanta(this.usuarioService.getAeroplantaActual().codigoAeroplanta);
    this.cargandoAeroespecialidades = true;
    this.managementService.getAllAeroespecialidades(this.usuarioService.getAeroplantaActual().codigoAeroplanta)
      .subscribe(result => {
        this.aeroespecialidades = result;
        this.addCheckboxes();
        this.spinner.hide('spinnerLista');
        this.cargandoAeroespecialidades = false;
      });
  }
}
