import { Component, Inject, Input, LOCALE_ID, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ComprobanteService } from 'src/app/facturacion/comprobante.service';
import { EjecucionFacturacion } from 'src/app/shared/models/facturacion/EjecucionFacturacion';
import { MessageService } from 'src/app/shell/message.service';

@Component({
  selector: 'app-ejecuciones-facturacion',
  templateUrl: './ejecuciones-facturacion.component.html',
  styleUrls: ['./ejecuciones-facturacion.component.css']
})
export class EjecucionesFacturacionComponent implements OnInit {

  tipoReporte: any;

  modelDesde: NgbDateStruct;
  modelHasta: NgbDateStruct;
  fechaDesde: Date;
  fechaHasta: Date;
  obteniendoEjecuciones: boolean = false;
  ejecuciones: EjecucionFacturacion[] = [];
  ejecucionSeleccionada: EjecucionFacturacion;
  ejecucion: boolean = false;

  constructor(private calendar: NgbCalendar, @Inject(LOCALE_ID) public locale: string, private facturacionService: ComprobanteService,
    private spinner: NgxSpinnerService, private messageService: MessageService, private route: ActivatedRoute) { }

  ngOnInit() {
    let param = this.route.snapshot.queryParamMap.get('si');
    if(param == 'false'){
      this.tipoReporte = false;
    }else{
      this.tipoReporte = true;
    }
    this.modelDesde = this.calendar.getToday();
    this.fechaDesde = new Date(this.modelDesde.year, this.modelDesde.month - 1, this.modelDesde.day);
    this.modelHasta = this.calendar.getToday();
    this.fechaHasta = new Date(this.modelHasta.year, this.modelHasta.month - 1, this.modelHasta.day);
    this.buscarDesdeHasta();
  }

  ngOnChanges() {
    this.ngOnInit();
  }

  fechaDesdeMayorQueHasta() {
    let esMayor: boolean = false;
    this.fechaDesde = new Date(this.modelDesde.year, this.modelDesde.month - 1, this.modelDesde.day);
    this.fechaHasta = new Date(this.modelHasta.year, this.modelHasta.month - 1, this.modelHasta.day);
    if (this.fechaDesde > this.fechaHasta) { esMayor = true; }
    return esMayor;
  }

  formatDate(year, month, day, hour, minute): string {
    return year + '-' + month.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + '-' + day.toLocaleString(undefined, { minimumIntegerDigits: 2 })
      + 'T' + hour.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + minute.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':00';
  }

  verDetalle(i: number) {
    this.ejecucionSeleccionada = this.ejecuciones[i];
    this.ejecucion = true;
  }

  buscarDesdeHasta() {
    this.spinner.show('spinnerLista');
    this.fechaDesde = new Date(this.modelDesde.year, this.modelDesde.month - 1, this.modelDesde.day);
    this.fechaHasta = new Date(this.modelHasta.year, this.modelHasta.month - 1, this.modelHasta.day);
    let desde: string = this.formatDate(this.fechaDesde.getUTCFullYear(), this.fechaDesde.getMonth() + 1, this.fechaDesde.getDate(), 0, 0);
    let hasta: string = this.formatDate(this.fechaHasta.getUTCFullYear(), this.fechaHasta.getMonth() + 1, this.fechaHasta.getDate(), 23, 59);
    this.obteniendoEjecuciones = true;
    this.ejecuciones = [];
    this.facturacionService.getEjecuciones(desde, hasta, this.tipoReporte)
      .subscribe(result => {
        this.ejecuciones = result;
        this.spinner.hide('spinnerLista');
        this.obteniendoEjecuciones = false;
      });
  }

  volviendo(){
    this.ejecucion = false;
    this.ngOnInit();
  }
}
