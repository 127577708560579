import { Component } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClienteService } from 'src/app/cliente/cliente.service';
import { ModalEnvioMailComponent } from 'src/app/cliente/modal-envio-mail/modal-envio-mail.component';
import { NuevoClienteComponent } from '../nuevo-cliente/nuevo-cliente.component';

@Component({
  selector: 'modal-ok',
  templateUrl: './modal-ok.component.html',
  styleUrls: ['./modal-ok.component.css']
})
export class ModalOkComponent {

  titulo: string;
  mensaje: string;
  webService: boolean;

  //Si no se pasa el activeModal, da error que no existe "dismiss" al seleccionar NO
  constructor(private activeModal: NgbActiveModal, public modal: NgbModal, private clienteService: ClienteService) { }

  close()
  {
    this.activeModal.close('Si')
  }

  dismiss()
  {
    this.activeModal.dismiss('NO clicked')
  }

  abrirClienteContado(){
    this.activeModal.dismiss('NO clicked');
    this.modal.open(NuevoClienteComponent, {centered: true, backdrop: 'static'});
  }

  enviarMail(){
    this.activeModal.dismiss('NO clicked');
    this.modal.open(ModalEnvioMailComponent, {centered: true, backdrop: 'static'});
  }
}