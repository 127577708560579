<div class="modal-header bg-light">
  <h4 class="modal-title">Nueva Notificacion de Sistema</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismissAll()"></button>
</div>

<div class="modal-body">
  <form [formGroup]="notificacionForm">
    <div class="row">
      <div class="mb-3 row col-12">
        <label for="tituloFormControl" class="col-4 col-form-label">*Título Notificación:</label>
        <div class="col-8">
          <input id="tituloFormControl" formControlName="tituloFormControl" type="text" class="form-control" placeholder="Título o Asunto"/>
          <div
            *ngIf="tituloFormControl.invalid && tituloFormControl.errors.required && (tituloFormControl.dirty || tituloFormControl.touched)"
            class="alert alert-danger">Requerido
          </div>               
        </div>
      </div>        
    </div>
    <br>
    <div class="row">
      <div class="mb-3 row col-12">
        <label for="tipoNovedadFormControl" class="col-4 col-form-label">*Tipo de Novedad:</label>
        <div class="col-8">
          <select class="form-select" [compareWith]="compareTipoNovedad" formControlName="tipoNovedadFormControl" id="tipoNovedadFormControl"
            title="Seleccione el Tipo de Novedad" (change)="refrescarNovedades()">              
            <option *ngFor="let tipo of tipoNovedadPorRoles" [ngValue]="tipo">
              {{ tipo.descripcion }}
            </option>
          </select>
          <loading-text-spinner *ngIf="this.cargandoTipoNovedad" message="Obteniendo tipos novedades.."
            color="primary">
          </loading-text-spinner>
        </div>
      </div>
    </div>

    <br>

    <!-- Filtros -->
    <div class="row">
      <div class="mb-3 row col-12">
        <label for="clavesFormControl" class="col-4 col-form-label">Claves</label>
        <label for="operadorFormControl" class="col-3 col-form-label">Operadores</label>
        <label *ngIf="this.mostrarInputValor()" for="valorFormControl" class="col-3 col-form-label">Valor</label>
      </div>
      <div class="mb-3 row col-12" *ngIf="!this.cargandoFiltros">
        <div class="col-4">
          <select class="form-select" id="clavesFormControl" formControlName="clavesFormControl" (change)="limpiarCamposOpValor()">
            <option [value]="null" disabled>
              Seleccione una clave
            </option>
            <option *ngFor="let c of listaDeClaves">
              {{ c.clave }}
            </option>
          </select>
        </div>
        <div class="col-3">
          <select class="form-select" id="operadorFormControl" formControlName="operadorFormControl">
            <option [value]="null" disabled>
              Seleccione un operador
            </option>
            <option *ngFor="let op of keysOperadorLogico()" [ngValue]="obtenerValorOperadorLogico(op)">
              {{ op }}
            </option>
          </select>
        </div>
        <div class="col-3" *ngIf="this.claveSinValores() && this.mostrarInputValor()">
          <input type="text" placeholder="Ingrese un valor" class="form-control" id="valorFormControl" formControlName="valorFormControl">
        </div>
        <div class="col-3" *ngIf="!this.claveSinValores()">
          <select class="form-select" id="valorFormControl" formControlName="valorFormControl">
            <option [value]="null" disabled>
              Seleccione un valor
            </option>
            <option *ngFor="let valor of obtenerValoresClave()">
              {{ valor }}
            </option>
          </select>
        </div>   
        <div class="col-1">
          <button (click)="agregarFiltroSeleccionado()" type="button" class="btn btn-primary">
            Agregar
          </button>
        </div>         
      </div>
      <loading-text-spinner *ngIf="this.cargandoFiltros" message="Obteniendo filtros..."
        color="primary">
      </loading-text-spinner> 
    </div>     
    
    <div class="row item-border">
      <div class="mb-3 p-2 row col-12">
        <label class="col-3 col-form-label">Filtros asignados</label>          
          <div id="etiqueta-card" *ngFor="let list of this.listaDeFiltros" class="col-3">
              <div class="shadow p-2 bg-body-tertiary rounded border">
                <div style="justify-content: space-between; align-items: center;" class="col d-flex">
                  <div class="col-md-10">
                    <h6>{{list.clave}} {{this.obtenerNombreOperador(list.operadorLogico)}} {{list.valor}}</h6>
                  </div>
                  <div class="float-end">
                    <button class="btn btn-sm btn-outline-danger" ngbTooltip="Quitar Filtro" (click)="borrarFiltroSeleccionado(list)">
                      <i class="fa fa-trash"></i>
                    </button> 
                  </div>                     
                </div>
              </div>                    
          </div>
          <div *ngIf='this.listaDeFiltros?.length === 0' class="alert alert-warning" role="alert">No se han cargado filtros</div>
      </div>
    </div>

    <br>
      
    <!-- email -->      
    <div class="row">
      <div class="mb-3 row col-12">
        <label for="emailFormControl" class="col-4 col-form-label">Agregar email</label>
        <div class="col-6">
          <input type="email" class="form-control" formControlName="emailFormControl" 
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$">          
          <div
            *ngIf="emailFormControl.invalid && emailFormControl.errors.pattern && (emailFormControl.dirty || emailFormControl.touched)"
            class="alert alert-danger">El Email no tiene el formato correcto 
          </div>          
        </div>
        <div class="col-1">
          <button [disabled]="emailFormControl.invalid" (click)="agregarMailDestinatario()" type="button" class="btn btn-primary">
            Agregar
          </button>
        </div>        
      </div>
    </div>

    <div class="row item-border">
      <div class="mb-3 p-2 row col-12">
        <label class="col-4 col-form-label">Emails asignados</label>          
        <div class="col-8 card-body">
          <div *ngFor="let list of this.listaDeEmails">
            <div class="d-flex mt-1">
              <label class="col-8 col-form-label">{{list}}</label>
              <button type="button" ngbTooltip="Quitar mail asignado" (click)="borrarMailDestinatario(list)" class="btn btn-danger ms-2 px-1">
                <i class="fa fa-trash"></i>
              </button>
            </div>
          </div>
          <div *ngIf='this.listaDeEmails?.length === 0' class="alert alert-warning" role="alert">No se han cargado mails</div>
        </div>  
      </div>
    </div>

    <br>

    <!-- Grupos -->      
    <div class="row">
      <div class="mb-3 row col-12">
        <label for="grupoFormControl" class="col-4 col-form-label">Agregar grupo</label>
        <div class="col-6">
          <input id="grupoFormControl" type="text" class="form-control" formControlName="grupoFormControl"
            [inputFormatter]="formatterGrupo" [resultFormatter]="formatterGrupo"
            (selectItem)="selectGrupo($event)" [ngbTypeahead]="searchGrupo" />    

            <loading-text-spinner *ngIf="this.cargandoGrupos" message="Obteniendo grupos.."
              color="primary" class="ms-4">
            </loading-text-spinner>            
        </div>
        <div class="col-1">
          <button (click)="agregarGrupoDestinatario()" type="button" class="btn btn-primary">
            Agregar
          </button>
        </div>
      </div>
    </div>

    <div class="row item-border">
      <div class="mb-3 p-2 row col-12">
        <label class="col-4 col-form-label">Grupos asignados</label>          
        <div class="col-8 card-body">
          <div *ngFor="let grupo of this.gruposSeleccionados">
            <div class="d-flex mt-1">
              <label class="col-8 col-form-label">{{grupo.titulo}}</label>
              <button type="button" ngbTooltip="Quitar grupo asignado" (click)="borrarGrupoDestinatario(grupo)" class="btn btn-danger ms-2 px-1">
                <i class="fa fa-trash"></i>
              </button>
            </div>
          </div>
          <div *ngIf='this.gruposSeleccionados?.length === 0' class="alert alert-warning" role="alert">No se han cargado grupos</div>
        </div>  
      </div>
    </div>

    <br>

    <!-- usuarios -->      
    <div class="row">
      <div class="mb-3 row col-12">
        <label for="usuarioFormControl" class="col-4 col-form-label">Agregar usuario</label>
        <div class="col-6">
          <input id="usuarioFormControl" type="text" class="form-control" formControlName="usuarioFormControl"
            [inputFormatter]="formatterUsuario" [resultFormatter]="formatterUsuario"
            (selectItem)="selectUsuario($event)" [ngbTypeahead]="searchUsuario" />   
            
          <loading-text-spinner *ngIf="this.cargandoUsuarios" message="Obteniendo usuarios.."
            color="primary" class="ms-4">
          </loading-text-spinner>
            
        </div>
        <div class="col-1">
          <button (click)="agregarUsuarioDestinatario()" type="button" class="btn btn-primary">
            Agregar
          </button>
        </div>
      </div>
    </div>

    <div class="row item-border">
      <div class="mb-3 p-2 row col-12">
        <label class="col-4 col-form-label">Usuarios asignados</label>          
        <div class="col-8 card-body">
          <div *ngFor="let usuario of this.usuariosSeleccionados">
            <div class="d-flex mt-1">
              <label class="col-8 col-form-label">{{usuario.nombresCompletos}}</label>
              <button type="button" ngbTooltip="Quitar usuario asignado" (click)="borrarUsuarioDestinatario(usuario)" class="btn btn-danger ms-2 px-1">
                <i class="fa fa-trash"></i>
              </button>
            </div>
          </div>
          <div *ngIf='this.usuariosSeleccionados?.length === 0' class="alert alert-warning" role="alert">No se han cargado usuarios</div>
        </div>  
      </div>
    </div>

    <br>
    <!--Mensaje de Notificación-->
    <div class="row">
      <div class="mb-3 row col-12 mt-2">
        <label for="mensajeFormControl" class="col-4 col-form-label">*Mensaje de Notificación:</label>
        <div class="col-7">
          <textarea id="mensaje" class="form-control" formControlName="mensajeFormControl" rows='5'></textarea>            
          <div
            *ngIf="mensajeFormControl.invalid && mensajeFormControl.errors.required && (mensajeFormControl.dirty || mensajeFormControl.touched)"
            class="alert alert-danger">Requerido</div>
          </div>
      </div>
    </div>

    <br>      
    <div class="row">
      <div class="mb-3 row col-12 mt-2">
        <label for="etiquetasFormControl" class="col-4 col-form-label"><b>*Etiquetas:</b></label>
      </div>
    </div>  
    <div class="row">
      <div class="mb-3 row col-12 ms-2">
        <div id="etiqueta-card" *ngFor="let item of etiquetasPorTipoNovedad" 
          class="card card-header me-1 mt-1 border">
          <div class="col d-flex">
            <div class="col-md-10">
              <h6>{{item}}</h6>
            </div>
            <div class="float-end">
              <button class="btn btn-sm btn-outline-primary" (click)="agregarEtiqueta(item)">
                <i class="fa fa-plus"></i>
              </button> 
            </div>                     
          </div>
        </div>
      
        <loading-text-spinner *ngIf="this.cargandoEtiquetas" message="Obteniendo etiquetas.."
          color="primary" class="ms-4">
        </loading-text-spinner>
      
        <div *ngIf='!this.cargandoEtiquetas && this.etiquetasPorTipoNovedad?.length === 0' class="alert alert-warning" role="alert">No se han cargado Etiquetas</div>
      </div>
    </div>

    <br>

    <div class="row item-border">
      <div class="mb-3 d-flex col-12">
        <label class="form-check-label me-4" formArrayName="usuariosCheck" *ngFor="let item of usuariosAccionFormArray.controls; let i = index">
          <input type="checkbox" class="form-check-input " [formControlName]="i">
            {{this.tipoNovedades.usuariosAccion[i]}}
        </label>

        <loading-text-spinner *ngIf="this.cargandoUsuariosAccion" message="Obteniendo usuarios acción..."
          color="primary">
        </loading-text-spinner>  

        <div *ngIf='this.tipoNovedades?.usuariosAccion?.length === 0' class="alert alert-warning" role="alert">No se han cargado Usuarios Acción</div>
      </div>                  
    </div>

    <br>


    <div class="card-footer d-flex justify-content-center">
      <span class="border">
        <button type="button" class="btn btn-primary" (click)="guardarNotificacion()" [disabled]="guardandoDatos">
          <span *ngIf="!guardandoDatos">Guardar</span>          
          <div *ngIf="guardandoDatos" class="d-flex align-items-center">
            <div class="spinner-border text-light spinner-border-sm" role="status"></div>
            <span>Guardando...</span>
          </div>
        </button>
      </span>
    </div>    
  </form>  
</div>