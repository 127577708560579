/**
 * IMPORTAR EXCEL
 * 
 * Al cargar el exel el comercial, se dispara una notificación a los usuarios con ROL ADUANA informando que hay ranchos pendientes de gestionar.
 * El excel a diferencia de lo que hace ahora, crearia una solicitud de rancho. Tiene todos los datos actuales menos el nº rancho.
 * Estos seudo ranchos cargados (solicitudes) estan pendientes de que aduana los apruebe y les asigne un nº de rancho.
 * Recien ahi, a los aprobados con nº de rancho los puede visualizar el comercial.         
 * 
 * VALIDACION DE RANCHOS CON WS AFIP ( ADUANA == Rol: DESPACHANTE )
 * 
 * Al ingresar un rancho en el input, debe primero "Validar". Esto conecta con un ws de afip que indica si el nº de rancho es valido.
 * Recien si es valido, permitir darle a "Guardar". Y allí se asigna efectivamente el nº de rancho.                                                                  
 * Esta validación es para verificar que el usuario de aduana no le error al tipear el nº de rancho.  
 * Si el rancho no se logra validar, se le hará un warning al usuario, pero no se  le impedira guardar. 
 * 
 * MARCAR RECONOCIDOS / DESCONOCIDOS (SOLO ADUANA == Rol: DESPACHANTE)
 * 
 * A todos los consumos (remitos) de cada rancho, despues de un tiempo aduana los marca como reconocidos o no reconocidos.
 * Puede ser que un tiempo despues de consumido en un aerovale, al revisar afip esta transacción se da cuenta que hay algo que no concuerda y lo desconoce.
 * 
 * ELIMINAR RANCHOS
 * 
 * El usuario comercial podrá eliminar individualmente o masivamente la solicitudes de rancho que el envio al usuario de Aduanas, 
 * siempre y cuando, Aduanas no haya asignado un número de Rancho.
 */

import { Component, Input, OnInit } from '@angular/core';
import { ProductoService } from 'src/app/abm/producto/producto.service';
import { Producto } from 'src/app/shared/models/abm/Producto';
import { NgbCalendar, NgbDatepickerConfig, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RanchoService } from '../rancho.service';
import { UsuarioService } from 'src/app/account/usuario.service';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';
import { UntypedFormControl } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { RanchoV2 } from 'src/app/shared/models/ranchos/RanchoV2';
import { ModalBitacoraRanchoComponent } from './modal-bitacora-rancho/modal-bitacora-rancho.component';
import { ModalAsignarRanchoComponent } from './modal-asignar-rancho/modal-asignar-rancho.component';
import { ActivatedRoute } from '@angular/router';
import { switchMap, tap } from 'rxjs/operators';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { TipoSolapaRancho } from 'src/app/shared/enums/TipoSolapaRancho';
import { DatePipe, DecimalPipe } from '@angular/common';
import { CierreTurnoService } from 'src/app/cierreTurno/cierre-turno.service';
import { TipoEstadoRancho } from 'src/app/shared/enums/TipoEstadoRancho';
import { ModalConfirmComponent } from 'src/app/shared/generic-modals/modal-confirm.component';
import { MessageService } from 'src/app/shell/message.service';
import { ModalAdjuntosComponent } from './modal-adjuntos/modal-adjuntos.component';
import { Usuario } from 'src/app/shared/models/ranchos/Usuario';
@Component({
  selector: 'app-ranchos-solicitudes',
  templateUrl: './ranchos-solicitudes.component.html',
  styleUrls: ['./ranchos-solicitudes.component.scss']
})
export class RanchosSolicitudesComponent implements OnInit {

  modelDesde: NgbDateStruct;
  fechaDesde: Date;

  solapaRanchosDestino: number;

  TipoEstado = TipoEstadoRancho;
  TipoUsuario = TipoUsuario;
  TipoSolapaRancho = TipoSolapaRancho;

  busquedaFormControl = new UntypedFormControl();
  obteniendoRanchos: boolean = false;
  obteniendoRanchosScroll: boolean = false;
  descargando: boolean = false;

  ranchos: RanchoV2[] = [];
  ranchosFiltrados: RanchoV2[] = [];

  productos: Producto[] = [];
  productosParaCheckear: any[] = [];
  codigoAeroplanta: string;

  throttle = 500;
  distance = 2;
  page = 1;
   pageSize = 20;

  mostrarDesactivado:boolean=true;
  stopScroll:boolean;

  constructor(private calendar: NgbCalendar,
    private ranchoService: RanchoService,
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private activeModal: NgbModal,
    private route: ActivatedRoute,
    private productoService: ProductoService,
    private datePipe: DatePipe,
    private decimalPipe: DecimalPipe,
    private cierreTurnoService: CierreTurnoService,
    private usuarioService: UsuarioService,
    private messageService: MessageService) {
      
      this.route.queryParams.pipe(
        tap(params => {
          this.solapaRanchosDestino= Number(params['solapaRanchosDestino']);
          this.getTodosLosProductos();
          this.initRequest(false);
          this.setearFechas();
        }),
        switchMap(params => {
              if(this.solapaRanchosDestino==TipoSolapaRancho.ListadoRanchoConsumidos) {
                return this.ranchoService.getRanchosConsumidos(
                  this.page, this.pageSize,
                  this.getCodigoAeroplanta(),
                  this.formatDate(this.fechaDesde.getFullYear(), this.fechaDesde.getMonth()+1, this.fechaDesde.getDate(), 23, 59)                  
                )
              } else {
                return this.ranchoService.getRanchosPendientes(
                  this.page, this.pageSize,
                  this.getCodigoAeroplanta()
                )
              }
            })
      ).subscribe(result => {
        this.procesarResult(result, false);        
      }, (err: ErrorModel) => {
        this.procesarError(err, false);
      });
    }

  initRequest(isScroll: boolean) {
    if( !isScroll ){
      this.mostrarSpinner();
    }else{
      this.mostrarSpinnerScroll();
    }
  }

  setearFechas() {
    this.modelDesde = this.calendar.getToday();
    this.fechaDesde = new Date(this.modelDesde.year, this.modelDesde.month-1, this.modelDesde.day);
  }

  procesarResult(r, isScroll: boolean) {    
    r?.length==0 ? this.stopScroll=true : this.stopScroll=false;
    console.log('stop scrolling: ', this.stopScroll);
    if( !isScroll ){      
      this.ranchos = r;
      this.ranchosFiltrados = r; 
      this.ocultarSpinner();
    }else{
      this.ranchos = this.ranchos.concat(r);
      this.filtrarMovimientos();
      this.ocultarSpinnerScroll();
    }
  }

  procesarError(error, isScroll: boolean) {
    if( !isScroll ){
      this.messageService.showErrorMessage('Hubo un error al obtener los datos. ' + error.description);
      this.ocultarSpinner();
    }else{
      this.messageService.showErrorMessage('Hubo un error al obtener los datos en el scroll. ' + error.description);
      this.ocultarSpinnerScroll();
    }
  }

  ngOnInit(): void {
  }

  filtrarMovimientosPorFecha() {    
    this.obtenerRanchosReseteandoScroll();
  }

  filtrarMovimientos() {
    this.ranchosFiltrados = this.ranchos.filter(a => {
      let agregar = true;
      if (this.busqueda.value) {
        agregar = a.cliente && a.cliente.razonSocial.toLocaleLowerCase().indexOf(this.busqueda.value.toLocaleLowerCase()) > -1;        
        agregar = agregar || (a.producto.codigoProducto && a.producto.codigoProducto.toLocaleLowerCase().indexOf(this.busqueda.value.toLocaleLowerCase()) > -1);        
        agregar = agregar || (a.aeroplanta.codigoAeroplanta && a.aeroplanta.codigoAeroplanta.toLocaleLowerCase().indexOf(this.busqueda.value.toLocaleLowerCase()) > -1);
        agregar = agregar || (a.destino.nombre && a.destino.nombre.toLocaleLowerCase().indexOf(this.busqueda.value.toLocaleLowerCase()) > -1);
        agregar = agregar || (a.numeroLote.toString() && a.numeroLote.toString().toLocaleLowerCase().indexOf(this.busqueda.value.toLocaleLowerCase()) > -1);
        if (a.codigoRancho != null) {
          agregar = agregar || (a.codigoRancho && a.codigoRancho.toLocaleLowerCase().indexOf(this.busqueda.value.toLocaleLowerCase()) > -1);
        }         
      }

      if(this.productosParaCheckear.some(b => b.seleccionado))
        agregar = agregar && this.productosParaCheckear.some(
          b => (b.seleccionado && b.codigo == a.producto.codigoProducto))   

      if(!this.mostrarDesactivado) {
        agregar = agregar && a.estado!=this.TipoEstado.Desactivado;
      }

      if (this.busqueda.value == '') {
        this.ranchosFiltrados = this.ranchos;
      }  

      return agregar;
    });
  }

  /** 
  * Utilidades 
  */

  mostrarSpinner() {
    this.obteniendoRanchos = true;
    this.spinner.show('spinnerLista');    
  }
  
  ocultarSpinner() {
    this.obteniendoRanchos = false;
    this.spinner.hide('spinnerLista');    
  }

  mostrarSpinnerScroll() {
    this.obteniendoRanchosScroll = true; 
  }
  
  ocultarSpinnerScroll() {
    this.obteniendoRanchosScroll = false;    
  }

  formatDate(year, month, day, hour, minute): string {
    return year + '-' + month.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + '-' + day.toLocaleString(undefined, { minimumIntegerDigits: 2 })
      + 'T' + hour.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + minute.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':00';
  }

  toggleDatepicker(e: any){
    e.toggle();
  }

  hasRole(...roles: TipoUsuario[]) {
    return this.authService.hasRole(...roles);
  }

  getCodigoAeroplanta(){
    /**
     * Aduana y comerciales pueden ver todas las aeroplantas en los listados de ranchos solicitados y solicitudes de ranchos
     */
    if(this.solapaRanchosDestino!=TipoSolapaRancho.ListadoRanchoConsumidos) {
      if(!this.hasRole(TipoUsuario.DESPACHANTE, TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.APROBADORBYR)) {
        return this.usuarioService.getAeroplantaActual().codigoAeroplanta;
      } else {
        return ""
      }
    } else {
      return this.usuarioService.getAeroplantaActual().codigoAeroplanta;
    }    
  }

  exportarListadoRanchosExcel() {
    let tipo = "";
    switch (this.solapaRanchosDestino) {
      case TipoSolapaRancho.SolicitudRancho:
        tipo="Solicitud de ranchos";
        break;    
      case TipoSolapaRancho.ListadoRanchoSolicitados:
        tipo="Listado de ranchos solicitados";
        break;
      case TipoSolapaRancho.ListadoRanchoConsumidos:
        tipo="Listado de ranchos consumidos";
        break;    
      default: tipo="";
        break;
    }
    
    if(this.ranchosFiltrados.length==0) {
      this.messageService.showErrorMessage("No hay información para exportar en "+tipo);
      return
    }

    let dataArray=[
      ["Tipo Reporte: " + tipo],      
      (this.solapaRanchosDestino==TipoSolapaRancho.ListadoRanchoConsumidos) ? ["Vigencia al: " + this.datePipe.transform(this.fechaDesde, "dd/MM/yyyy")] : null,      
      [],
      (this.solapaRanchosDestino==TipoSolapaRancho.ListadoRanchoConsumidos) ?
        ["Concatenado", "Nro.Rancho", "Cliente", "Aeroplanta", "Bandera", "Destino", "Producto", "Volumen", "F.Desde", "F.Hasta", "Moneda", "Precio", "Lote", "Cta. Fact.", "Se factura", "FOB", "Comentarios", "Adjuntos", "Pais Facturacion", "Estado"]
        :
        ["Concatenado", "Cliente", "Aeroplanta", "Bandera", "Destino", "Producto", "Volumen", "F.Desde", "F.Hasta", "Moneda", "Precio", "Lote", "Cta. Fact.", "Se factura", "FOB", "Comentarios", "Pais Facturacion", "Estado"]
    ];

    let totalVolumen = 0;
    for (let i=0; i < this.ranchosFiltrados.length; i++) {
      let r = this.ranchosFiltrados[i];
      let linea:string="";
      for (let i = 0; i < r.archivosAdjuntos.length; i++) {
        linea = linea.concat(r.archivosAdjuntos[i]).concat("\n");        
      }
      (this.solapaRanchosDestino==TipoSolapaRancho.ListadoRanchoConsumidos) ?
        dataArray.push([r.concatenado, r.codigoRancho, r.cliente.razonSocial, r.aeroplanta.codigoAeroplanta, r.codigoBandera+" - "+r.bandera, r.destino.nombre, r.producto.codigoProducto+" - "+this.obtenerDescripcionProducto(r.producto.codigoProducto), this.decimalPipe.transform(r.volumen, '1.0-0'), this.datePipe.transform(r.fechaInicio, "dd/MM/yyy"), this.datePipe.transform(r.fechaVencimiento, "dd/MM/yyy"), r.moneda,  this.decimalPipe.transform(r.precio, "1.5-5"), r.numeroLote.toString(), r.ctA_ParaFacturar, r.seFactura ? "SI" : "NO ", r.fob, r.comentarios, linea, r.nombrePaisFacturacion, this.obtenerTipoEstado(r.estado)])
        :
        dataArray.push([r.concatenado, r.cliente.razonSocial, r.aeroplanta.codigoAeroplanta, r.codigoBandera+" - "+r.bandera, r.destino.nombre, r.producto.codigoProducto+" - "+this.obtenerDescripcionProducto(r.producto.codigoProducto), this.decimalPipe.transform(r.volumen, '1.0-0'), this.datePipe.transform(r.fechaInicio, "dd/MM/yyy"), this.datePipe.transform(r.fechaVencimiento, "dd/MM/yyy"), r.moneda, this.decimalPipe.transform(r.precio, "1.5-5"), r.numeroLote.toString(), r.ctA_ParaFacturar, r.seFactura ? "SI" : "NO ", r.fob, r.comentarios, r.nombrePaisFacturacion, this.obtenerTipoEstado(r.estado)]);
      totalVolumen += this.ranchosFiltrados[i].volumen;      
    }

    (this.solapaRanchosDestino==TipoSolapaRancho.ListadoRanchoConsumidos) ? 
      dataArray.push(["", "", "", "", "", "", "Total Volumen", this.decimalPipe.transform(totalVolumen, '1.0-0')])
      :
      dataArray.push(["", "", "", "", "", "Total Volumen", this.decimalPipe.transform(totalVolumen, '1.0-0')])
    let filename = tipo;
    this.cierreTurnoService.exportAsExcelFile(dataArray, filename);
  }

  /**
   * Servicios
   */

  getTodosLosProductos() {
    this.productos = [];
    this.productoService.obtenerProductos()
      .subscribe(result => {
        this.productos = result; 
        this.productos = this.productos.filter(p => p.rubro.aerocombustible || p.rubro.combustible);
        this.productos = this.productos.filter(a => a.activo)
        this.productosParaCheckear = [];
        for (let prod of this.productos) {          
          this.productosParaCheckear.push({ "nombre": prod.nombreProducto, "codigo": prod.codigoProducto, "seleccionado": false });
        }
      });
  }

  obtenerDescripcionProducto(codigo:string) {
    if(this.productos!=null){
      for (let i = 0; i < this.productos.length; i++) {
        if(this.productos[i].codigoProducto == codigo) {
          return this.productos[i].nombreProducto;
          break;
        }
      }
    }
  }

  obtenerTipoEstado(estado) {
    return TipoEstadoRancho[estado];
  }
  
  eliminarLote(lote:string) {
    // solicitar el numero de lote o confirmar
  } 

  eliminarRanchoPorRanchoID(r:RanchoV2) {
    const modalRef = this.activeModal.open(ModalConfirmComponent, { centered: true });
    modalRef.componentInstance.titulo = "Se eliminará la solicitud del rancho";
    modalRef.componentInstance.mensaje = "¿Está seguro?";
    modalRef.componentInstance.textoSi = "Aceptar";
    modalRef.componentInstance.textoNo = "Cancelar";
    modalRef.result.then(() => {      
      this.ranchoService.eliminarRanchosInvidual(r.id)
        .subscribe(result => {
          this.messageService.showSuccessMessage("La solicitud de rancho se elimino correctamente");
          this.obtenerRanchosReseteandoScroll();
        },
        (err: ErrorModel) => {
          this.messageService.showErrorMessage("Se produjo un error al eliminar el rancho");
        });
    });
  } 

  desactivarRanchoPorRanchoID(r:RanchoV2) {
    const modalRef = this.activeModal.open(ModalConfirmComponent, { centered: true });
    modalRef.componentInstance.titulo = "Se desactivará el rancho";
    modalRef.componentInstance.mensaje = "¿Está seguro?";
    modalRef.componentInstance.textoSi = "Aceptar";
    modalRef.componentInstance.textoNo = "Cancelar";
    modalRef.result.then(() => {      
      this.ranchoService.putDesactivarRanchos(r.id, this.armarUsuario())
        .subscribe(result => {
          this.messageService.showSuccessMessage("El rancho fue desactivado correctamente");
          this.obtenerRanchosReseteandoScroll();
        },
        (err: ErrorModel) => {
          this.messageService.showErrorMessage("Se produjo un error al desactivar el rancho");
        });
    });
  } 

  onScrollRanchos(){
    this.initRequest(true);

    this.fechaDesde = new Date(this.modelDesde.year, this.modelDesde.month-1, this.modelDesde.day);
    if(this.solapaRanchosDestino==TipoSolapaRancho.ListadoRanchoConsumidos){
      this.ranchoService.getRanchosConsumidos(
        ++this.page, this.pageSize,
        this.getCodigoAeroplanta(),
        this.formatDate(this.fechaDesde.getFullYear(), this.fechaDesde.getMonth()+1, this.fechaDesde.getDate(), 23, 59)        
        )
        .subscribe(res => {
            this.procesarResult(res, true);   
          }, (err: ErrorModel) => {
            this.procesarError(err, true);
          }
        );      
      } else {
        this.ranchoService.getRanchosPendientes(
          ++this.page, this.pageSize,
          this.getCodigoAeroplanta())
          .subscribe(res => {
              this.procesarResult(res, true);   
            }, (err: ErrorModel) => {
              this.procesarError(err, true);
            }
          );      
      }    
  }

  /** 
   * Modales
   */

  asignarNumeroDeRancho(r:RanchoV2) {
    let modalRef = this.activeModal.open(ModalAsignarRanchoComponent, { centered: true, backdrop: 'static' });
    modalRef.componentInstance.rancho = r;
    modalRef.componentInstance.nombreProducto = this.obtenerDescripcionProducto(r.producto.codigoProducto);
    modalRef.result.then( (ranchoAfip) => {
      if (ranchoAfip) {
          this.ranchos = this.ranchos.filter( r => r.id != ranchoAfip.id)
          this.ranchosFiltrados = this.ranchos;
        }
      })
  }

  verAdjuntos(archivosAdjuntos:string[]) {
    let modalRef = this.activeModal.open(ModalAdjuntosComponent, { centered: true, size: "xl", backdrop: 'static' });
    modalRef.componentInstance.archivosAdjuntos = archivosAdjuntos;
  }

  obtenerRanchosReseteandoScroll(){
    this.page = 1;

    this.initRequest(false);

    this.fechaDesde = new Date(this.modelDesde.year, this.modelDesde.month-1, this.modelDesde.day);
    
    if(this.solapaRanchosDestino==TipoSolapaRancho.ListadoRanchoConsumidos){
      this.ranchoService.getRanchosConsumidos(
        this.page, this.pageSize,
        this.getCodigoAeroplanta(),
        this.formatDate(this.fechaDesde.getFullYear(), this.fechaDesde.getMonth()+1, this.fechaDesde.getDate(), 23, 59)        
        )
        .subscribe(res => {
            this.procesarResult(res, false);   
          }, (err: ErrorModel) => {
            this.procesarError(err, false);
          }
        );      
      } else {
        this.ranchoService.getRanchosPendientes(
          this.page, this.pageSize,
          this.getCodigoAeroplanta())
          .subscribe(res => {
              this.procesarResult(res, false);   
            }, (err: ErrorModel) => {
              this.procesarError(err, false);
            }
          );      
    } 
  }
  
  verBitacoraRancho(r:RanchoV2) {
    let modalRef = this.activeModal.open(ModalBitacoraRanchoComponent, { centered: true, size: "lg", windowClass: 'myCustomModalClass', backdrop: "static" });
    modalRef.componentInstance.rancho = r;
  }

  armarUsuario() : Usuario {
    let usuario = {
                  id: this.usuarioService.getIdUsuario(),
                  nombresCompletos: this.usuarioService.getNombresCompletosUsuario(),
                  dni: this.usuarioService.getDniUsuario(),
                  email: this.usuarioService.getEmailUsuario()
                }      
    return usuario;
  }

  /** 
   * Getters
   */

  get busqueda() {
    return this.busquedaFormControl;
  }
}
