import { Component, HostBinding, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbCalendar, NgbDatepickerConfig, NgbDateStruct, NgbModal, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { UsuarioService } from 'src/app/account/usuario.service';
import { EstadoRefacturacion } from 'src/app/shared/enums/estadoRefacturacion';
import { Motivo } from 'src/app/shared/enums/Motivo';
import { FechasService } from 'src/app/shared/fechas.service';
import { Aerovale } from 'src/app/shared/models/despacho/Aerovale';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { Comprobante } from 'src/app/shared/models/facturacion/Comprobante';
import { Factura } from 'src/app/shared/models/facturacion/Factura';
import { RefacturacionFactura } from 'src/app/shared/models/facturacion/RefacturacionFactura';
import { MessageService } from 'src/app/shell/message.service';
import { ModalObservacionRefacturacionComponent } from '../aprobacion-refacturacion/modal-observacion-refacturacion/modal-observacion-refacturacion.component';
import { ComprobanteService } from '../comprobante.service';
import { FacturacionService } from '../facturacion.service';
import { ItemMultiFiltro } from 'src/app/shared/multifiltro/ItemMultifiltro';
import { DatePipe } from '@angular/common';
import { SharedService } from 'src/app/shared/shared.service';
import { TipoMovimientoPipe } from 'src/app/shared/pipes/tipo-movimiento.pipe';

@Component({
  selector: 'app-refacturacion-historial',
  templateUrl: './refacturacion-historial.component.html',
  styleUrls: ['./refacturacion-historial.component.css']
})
export class RefacturacionHistorialComponent implements OnInit {

  @HostBinding('class.changed')

  facturaPrefijoMultiFiltro: ItemMultiFiltro[] = [];
  facturaNroMultiFiltro: ItemMultiFiltro[] = [];
  tipoMultiFiltro: ItemMultiFiltro[] = [];
  clienteMultiFiltro: ItemMultiFiltro[] = [];
  estadoMultiFiltro: ItemMultiFiltro[] = [];
  motivoMultifiltro: ItemMultiFiltro[] = [];
  solicitadoMultiFiltro: ItemMultiFiltro[] = [];
  revisadoMultiFiltro: ItemMultiFiltro[] = [];
  aprobadoMultiFiltro: ItemMultiFiltro[] = [];

  tablaComprobantes: UntypedFormGroup;
  tituloFactura: string;

  facturaCalculada: Factura[];
  facturaObtenida: Comprobante;
  previsualizacion: boolean = false;

  aerovalesFiltrados: Aerovale[] = [];

  facturas: RefacturacionFactura[] = [];
  facturasFiltradas: RefacturacionFactura[] = [];
  facturasFiltradosMultifiltro: RefacturacionFactura[];
  previsualizando: boolean = false;

  fechaFormControlDesdeHasta = new UntypedFormControl();
  modelDesde: NgbDateStruct;
  modelHasta: NgbDateStruct;
  startDate: Date;
  endDate: Date;
  buscando: boolean = false;
  mensajeSpinner: string = "D e s c a r g a n d o";
  obteniendoHistorial = true;
  procesandoExport: boolean = false;
  codigoAeroplanta: string;
  cambiandoAeroplanta: boolean = false;
  candidata = { valor: 0, descripcion: "Candidata", seleccionado: false };
  pendiente = { valor: 1, descripcion: "Pendiente", seleccionado: false };
  revisado = { valor: 2, descripcion: "Revisado", seleccionado: false };
  aprobado = { valor: 3, descripcion: "Aprobado", seleccionado: false };
  rechazado = { valor: 4, descripcion: "Rechazado", seleccionado: false };

  notaCredito = { tipo: 0, valor: "notaCredito", descripcion: "Nota de credito", seleccionado: false };
  notaDebito = { tipo: 1, valor: "notaDebito", descripcion: "Nota de debito", seleccionado: false };

  tipos = [
    this.notaCredito, this.notaDebito
  ];

  estados = [
    this.candidata,
    this.pendiente,
    this.revisado,
    this.aprobado,
    this.rechazado
  ];


  busquedaFormControl = new UntypedFormControl();
  change$: Subscription;
  errorAerovales: boolean = false;
  botonAnular: boolean = false;
  itemSeleccionado: number = null;

  constructor(private formateadorDeFechas: NgbDatepickerConfig,
    private usuarioService: UsuarioService,
    private calendar: NgbCalendar,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private fb: UntypedFormBuilder,
    private comprobanteService: ComprobanteService,
    public facturacionService: FacturacionService,
    private fechaService: FechasService,
    private messageService: MessageService,
    private datePipe: DatePipe,
    private tipoMovimientoPipe: TipoMovimientoPipe,
    sharedService: SharedService) {
      this.change$ = sharedService.aeroplantaSeleccionada.subscribe(_=>{
        this.cambiarDeAeroplanta();
      });
  }

  // hooks

  ngOnInit() {

    this.tablaComprobantes = this.fb.group({
      aerovalesFiltrados: new UntypedFormArray([])
    });
    this.addCheckboxes();

    this.codigoAeroplanta = this.usuarioService.getAeroplantaActual().codigoAeroplanta;

    this.botonAnular = false;
    this.mensajeSpinner = "C a r g a n d o . . .";

    this.modelDesde = this.calendar.getToday();
    this.modelHasta = this.calendar.getToday();

    this.dosMesesAtras(this.modelDesde.month);



    let inicio: any = { day: 1, month: 1, year: 2019 };
    this.formateadorDeFechas.minDate = inicio;
    let fin: any = { day: this.modelHasta.day, month: this.modelHasta.month, year: this.modelHasta.year };
    this.modelHasta = this.calendar.getToday();
    this.formateadorDeFechas.maxDate = fin;

    this.modelDesde = this.calendar.getToday();
    setTimeout(() => {
      this.spinner.show('spinnerGrafico');
    });
    this.reinicioFechaInicioFechaFin();
    this.getHistorialporRango(this.startDate, this.endDate);
  }

  ngOnDestroy() {
    this.change$.unsubscribe();
    this.aerovalesFiltrados = [];
  }



  // form

  filtrarFacturas() {
    this.facturasFiltradas = this.facturas.filter(a => { 
      let agregar = true;
      if (this.busqueda.value) {
        agregar = a.factura.razonSocialCliente.toLowerCase().indexOf(this.busqueda.value.toLowerCase()) > -1;

        agregar = agregar || (this.datePipe.transform(a.fechaHora, "d/MM/y").indexOf(this.busqueda.value) > -1);

      }
      if (this.estados.some(b => b.seleccionado))
        agregar = agregar && this.estados.some(
          b => (b.seleccionado && b.valor == a.estado));

      return agregar;
    });

    this.filtrarFacturasMulti();
  }



  fechaDesdeMayorQueHasta() {
    let esMayor: boolean = false;
    this.startDate = new Date(this.modelDesde.year, this.modelDesde.month - 1, this.modelDesde.day);
    this.endDate = new Date(this.modelHasta.year, this.modelHasta.month - 1, this.modelHasta.day);
    if (this.startDate > this.endDate) { esMayor = true; }
    return esMayor;
  }


  reinicioFechaInicioFechaFin() {
    this.startDate = new Date(this.modelDesde.year, this.modelDesde.month - 1, this.modelDesde.day);
    this.endDate = new Date(this.modelHasta.year, this.modelHasta.month - 1, this.modelHasta.day);
    this.endDate.setHours(23, 59, 59, 99);
  }

  buscarDesdeHasta() {
    this.mensajeSpinner = "C a r g a n d o . . .";
    this.buscando = true;
    this.reinicioFechaInicioFechaFin();
    this.getHistorialporRango(this.startDate, this.endDate);
  }


  limpiarCampos() {
    this.aerovalesFiltrados = [];
    this.spinner.hide("spinnerGrafico");
    this.spinner.hide("spinnerDetalle");
    this.buscando = false;
    this.busquedaFormControl.reset();
    this.modelDesde = this.calendar.getToday();
    this.modelHasta = this.calendar.getToday();
    //limpio filtro estados
    for (let estado of this.estados) {
      estado.seleccionado = false;
    }
    //limpio filtro tipos
    for (let tipo of this.tipos) {
      tipo.seleccionado = false;
    }
  }

  cambiarDeAeroplanta() {
    this.cambiandoAeroplanta = false;
    this.limpiarCampos();
    this.cambiandoAeroplanta = true;
    this.ngOnInit();
  }

  private addCheckboxes() {
    // reinicio el formulario
    this.tablaComprobantes.setControl('aerovalesFiltrados', this.fb.array([]));
    this.aerovalesFiltrados.map((o, i) => {
      const control = new UntypedFormControl(i === 0); // if first item set to true, else false
      control.setValue(o);
      (this.tablaComprobantes.controls.aerovalesFiltrados as UntypedFormArray).push(control);
    });
  }


  dosMesesAtras(mes) {

    if (mes == 1) {
      this.modelDesde.month = 11;
      this.modelDesde.year -= 1;
    }
    if (mes == 2) {
      this.modelDesde.month = 12;
      this.modelDesde.year -= 1;
    }
    if (mes != 1 && mes != 2) {
      this.modelDesde.month -= 2;
    }
  }



  // llamadas al service


  obtenerFacturaCalculada(remitos: string[]) {
    this.previsualizacion = false;
    this.comprobanteService.obtenerFacturaCalculada(remitos)
      .subscribe(result => {
        this.facturaCalculada = result;
        this.previsualizacion = true;
      })

  }

  verObservaciones(detalle: string) {
    let modalRef = this.modalService.open(ModalObservacionRefacturacionComponent, { centered: true, backdrop: 'static' });
    modalRef.componentInstance.detalle = detalle;
  }


  verComparativasFacturas(content, s: RefacturacionFactura, index) {
    this.previsualizando = true;
    this.itemSeleccionado = index;
    this.tituloFactura = 'Factura: ' + s.factura.prefijo + '-' + s.factura.numeroComprobante + ' Fecha: ' + new Date().toLocaleDateString();
    this.comprobanteService.obtenerComprobantePorPrefijoNumeroTipo(s.factura.prefijo, s.factura.numeroComprobante, s.factura.tipoMovimiento)
      .subscribe(result => {
        this.facturaObtenida = result;
        if (result != null) {
          this.facturacionService.getNuevoComprobante(s.id, s.esRefacturacionTotal)
            .subscribe(result => {
              this.facturaCalculada = result;
              this.previsualizando = false;
              this.itemSeleccionado = -1;

              const modalRef = this.modalService.open(content, { windowClass: 'custom-modal', centered: true, size: 'lg' });
            }, (err: ErrorModel) =>{
              this.previsualizando = false;
              this.itemSeleccionado = -1;
              this.messageService.showErrorMessage('Hubo un error al obtener la comparativa. ' + err.description + ' ' + err.message);
            });
        }
        else {
          this.previsualizando = false;
          this.itemSeleccionado = -1;
          s.factura.loadIcon = false;
          this.facturaObtenida = null; //se limpia el comprobante.
        }
      }, (err: ErrorModel) =>{
        this.previsualizando = false;
        this.itemSeleccionado = -1;
        this.messageService.showErrorMessage('Hubo un error al obtener la comparativa. ' + err.description + ' ' + err.message);
        });
  }



  getHistorialporRango(fechaInicio: Date, fechaFin: Date) {
    this.obteniendoHistorial = true;
    this.aerovalesFiltrados = [];
    this.spinner.show('spinnerGrafico');
    let desde: string = this.fechaService.convertirDateUtcALocal(fechaInicio.getUTCFullYear(), fechaInicio.getMonth() + 1, fechaInicio.getDate(), 0, 0);
    let hasta: string = this.fechaService.convertirDateUtcALocal(fechaFin.getUTCFullYear(), fechaFin.getMonth() + 1, fechaFin.getDate(), 23, 59);
    this.facturacionService.getHistorialRefacturacionesPorFechaYEstado(0, desde, hasta)
      .subscribe(result => {
        this.facturas = result;
        this.facturasFiltradas = this.facturas;
        this.facturasFiltradas.map(f => {
          if (f.motivoRechazo != null) {
            if (f.detalle == null) {
              f.detalle = f.motivoRechazo;
            } else {
              f.detalle = f.detalle + "\n\n" + "Motivo Rechazo: " + f.motivoRechazo
            }
          }

          return f;
        });
        this.spinner.hide('spinnerGrafico');
        this.obteniendoHistorial = false;

        this.filtrarFacturasMulti();
      }, () => {
        this.errorAerovales = true;
        this.obteniendoHistorial = false;
      });
  }

  filtrarFacturasMulti(){
    if (this.facturasFiltradas != null) {

      this.facturasFiltradosMultifiltro = this.facturasFiltradas.filter(f => {
        let mostrar = true;

        if (this.facturaPrefijoMultiFiltro.some(elemento => elemento.seleccionado)) {
          mostrar = mostrar && (
            this.facturaPrefijoMultiFiltro.filter(elemento => elemento.seleccionado).some(elementoAux => elementoAux.id == f.factura.prefijo.toString())
          );
        }
        
        if (this.facturaNroMultiFiltro.some(elemento => elemento.seleccionado)) {
          mostrar = mostrar && (
            this.facturaNroMultiFiltro.filter(elemento => elemento.seleccionado).some(elementoAux => elementoAux.id == f.factura.numeroComprobante.toString())
          );
        }

        if (this.tipoMultiFiltro.some(elemento => elemento.seleccionado)) {
          mostrar = mostrar && (
            this.tipoMultiFiltro.filter(elemento => elemento.seleccionado).some(elementoAux => Number(elementoAux.id) == f.factura.tipoMovimiento)
          );
        }

        if (this.clienteMultiFiltro.some(elemento => elemento.seleccionado)) {
          mostrar = mostrar && (
            this.clienteMultiFiltro.filter(elemento => elemento.seleccionado).some(elementoAux => elementoAux.id == f.factura.razonSocialCliente)
          );
        }

        if (this.estadoMultiFiltro.some(elemento => elemento.seleccionado)) {
          mostrar = mostrar && (
            this.estadoMultiFiltro.filter(elemento => elemento.seleccionado).some(elementoAux => Number(elementoAux.id) == f.estado)
          );
        }

        if (this.motivoMultifiltro.some(elemento => elemento.seleccionado)) {
          mostrar = mostrar && (
            this.motivoMultifiltro.filter(elemento => elemento.seleccionado).some(elementoAux => Number(elementoAux.id) == f.motivo)
          );
        }

        if (this.solicitadoMultiFiltro.some(elemento => elemento.seleccionado)) {
          mostrar = mostrar && (
            this.solicitadoMultiFiltro.filter(elemento => elemento.seleccionado).some(elementoAux => elementoAux.id == f.usuarioSolicitador)
          );
        }

        if (this.revisadoMultiFiltro.some(elemento => elemento.seleccionado)) {
          mostrar = mostrar && (
            this.revisadoMultiFiltro.filter(elemento => elemento.seleccionado).some(elementoAux => elementoAux.id == f.usuarioRevisador)
          );
        }

        if (this.aprobadoMultiFiltro.some(elemento => elemento.seleccionado)) {
          mostrar = mostrar && (
            this.aprobadoMultiFiltro.filter(elemento => elemento.seleccionado).some(elementoAux => elementoAux.id == f.usuarioAprobador)
          );
        }
/*
        if (this.aeroplantasMultiFiltro.some(elemento => elemento.seleccionado)) {
          mostrar = mostrar && (
            this.aeroplantasMultiFiltro.filter(elemento => elemento.seleccionado).some(elementoAux => elementoAux.id == p.codigoAeroplanta)
          );
        }

        if (this.lotesMultiFiltro.some(elemento => elemento.seleccionado)) {
          mostrar = mostrar && (
            this.lotesMultiFiltro.filter(elemento => elemento.seleccionado).some(elementoAux => elementoAux.id == p.numeroLote)
          );
        }

        if (this.codigosMultiFiltro.some(elemento => elemento.seleccionado)) {
          mostrar = mostrar && (
            this.codigosMultiFiltro.filter(elemento => elemento.seleccionado).some(elementoAux => elementoAux.id == p.producto.codigoProducto)
          );
        }

        if (this.productosMultiFiltro.some(elemento => elemento.seleccionado)) {
          mostrar = mostrar && (
            this.productosMultiFiltro.filter(elemento => elemento.seleccionado).some(elementoAux => elementoAux.id == p.producto.nombreProducto)
          );
        }

        if (this.monedasMultiFiltro.some(elemento => elemento.seleccionado)) {
          mostrar = mostrar && (
            this.monedasMultiFiltro.filter(elemento => elemento.seleccionado).some(elementoAux => elementoAux.id == p.moneda.nombre)
          );
        }

        if (this.clientesMultiFiltro.some(elemento => elemento.seleccionado)) {
          mostrar = mostrar && (
            this.clientesMultiFiltro.filter(elemento => elemento.seleccionado).some(elementoAux => p.cliente != null ? elementoAux.id == p.cliente.razonSocial : elementoAux.id == ItemMultifiltroComponent.SIMBOLO_VALOR_NULO)
          );
        }

        if (this.condicionesVentaMultiFiltro.some(elemento => elemento.seleccionado)) {
          mostrar = mostrar && (
            this.condicionesVentaMultiFiltro.filter(elemento => elemento.seleccionado).some(elementoAux => Number(elementoAux.id) == p.condicionVenta)
          );
        }

        if (this.entregasMultiFiltro.some(elemento => elemento.seleccionado)) {
          mostrar = mostrar && (
            this.entregasMultiFiltro.filter(elemento => elemento.seleccionado).some(elementoAux => elementoAux.id == (p.direccionEntrega != null ? p.direccionEntrega : ItemMultifiltroComponent.SIMBOLO_VALOR_NULO))
          );
        }

        if (this.areasMultiFiltro.some(elemento => elemento.seleccionado)) {
          mostrar = mostrar && (
            this.areasMultiFiltro.filter(elemento => elemento.seleccionado).some(elementoAux => elementoAux.id == (p.areaNegocio != null ? p.areaNegocio.nombreArea : ItemMultifiltroComponent.SIMBOLO_VALOR_NULO))
          );
        }

        if (this.segmentosMultiFiltro.some(elemento => elemento.seleccionado)) {
          mostrar = mostrar && (
            this.segmentosMultiFiltro.filter(elemento => elemento.seleccionado).some(elementoAux => elementoAux.id == (p.segmento != null ? p.segmento.nombreSegmento : ItemMultifiltroComponent.SIMBOLO_VALOR_NULO))
          );
        }
*/
        return mostrar;

      });

      var a = this.facturasFiltradosMultifiltro;
    }
  }

  getDescripcionTipo(tipo: number) {
    switch (tipo) {
      case (1):
      case (3):
      case (2): {
        return 'A'
      }
      case (6):
      case (8):
      case (7): {
        return 'B';
      }
      case (9): {
        return 'R';
      }
      case (19):
      case (20):
      case (21): {
        return 'E'
      }
    }
  }

  // getters

  obtenerNuevoComprobante(id: string) {
    this.previsualizacion = false;
    this.facturacionService.getNuevoComprobante(id, true)
      .subscribe(result => {
        this.facturaCalculada = result;
        this.previsualizacion = true;
      })
  }

  obtenerMotivoRefacturacion(numero: string) {
    return Motivo[numero];
  }

  obtenerEstadoRefacturacion(numero: string) {
    return EstadoRefacturacion[numero];
  }

  recargarPrefijos(items: ItemMultiFiltro[]){
    this.facturaPrefijoMultiFiltro = items;
    this.filtrarFacturasMulti();
  }

  recargarNumeros(items: ItemMultiFiltro[]){
    this.facturaNroMultiFiltro = items;
    this.filtrarFacturasMulti();
  }

  recargarTipos(items: ItemMultiFiltro[]){
    this.tipoMultiFiltro = items;
    this.filtrarFacturasMulti();
  }

  recargarClientes(items: ItemMultiFiltro[]){
    this.clienteMultiFiltro = items;
    this.filtrarFacturasMulti();
  }

  recargarEstados(items: ItemMultiFiltro[]){
    this.estadoMultiFiltro = items;
    this.filtrarFacturasMulti();
  }

  recargarMotivos(items: ItemMultiFiltro[]){
    this.motivoMultifiltro = items;
    this.filtrarFacturasMulti();
  }

  recargarSolicitados(items: ItemMultiFiltro[]){
    this.solicitadoMultiFiltro = items;
    this.filtrarFacturasMulti();
  }

  recargarRevisados(items: ItemMultiFiltro[]){
    this.revisadoMultiFiltro = items;
    this.filtrarFacturasMulti();
  }

  recargarAprobados(items: ItemMultiFiltro[]){
    this.aprobadoMultiFiltro = items;
    this.filtrarFacturasMulti();
  }
  get busqueda() {
    return this.busquedaFormControl;
  }
}
