import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormArray, FormArrayName, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Guid } from 'guid-typescript';
import { Subscription } from 'rxjs';
import { UsuarioService } from 'src/app/account/usuario.service';
import { CalculosService } from 'src/app/shared/calculos.service';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { Abastecedora } from 'src/app/shared/models/stock/Abastecedora';
import { Medicion } from 'src/app/shared/models/stock/Medicion';
import { Tanque } from 'src/app/shared/models/stock/Tanque';
import { MessageService } from 'src/app/shell/message.service';
import { MedicionesService } from '../../../mediciones.service';
import { StockService } from '../../../stock.service';
import { Producto } from 'src/app/shared/models/abm/Producto';
import { ProductoService } from 'src/app/abm/producto/producto.service';
import { SharedService } from 'src/app/shared/shared.service';
import { ChangeDetectorRef } from '@angular/core';
import { isNumber } from 'highcharts';
import { isNullOrUndefined } from 'src/app/shared/compat-tools';

@Component({
  selector: 'app-mediciones-masivas',
  templateUrl: './mediciones-masivas.component.html',
  styleUrls: ['./mediciones-masivas.component.css']
})
export class MedicionesMasivasComponent implements OnInit {
  @Input() tanques: Tanque[];
  @Input() abastecedoras: Abastecedora[];
  @Output() cancelado: EventEmitter<boolean> = new EventEmitter();

  esValidaDensidad: boolean = true;
  esDensidadTanqueMin: any[]=[];
  esDensidadTanqueMax: any[]=[];
  esDensidadMin: any[]=[];
  esDensidadMax: any[]=[];
  cadenaTanqueDensidad: string[]=[];
  cadenaAbastecedoraDensidad: string[]=[];

  tanqueGraduacion: boolean = false;

  modelDesde: NgbDateStruct;
  calculandoCantidadNeta: boolean = false;
  medicionesParaTanque: boolean = false;
  aprobarDensidad: boolean = true;
  guardandoMediciones: boolean = false;
  productos: Producto[]=[];
  productoSeleccionado: Producto;
  fechaLimite = new Date();

  tablaTanques: UntypedFormGroup;
  horaCarga: UntypedFormControl;
  fechaProvisorio: UntypedFormControl;

  change$: Subscription;
  aprobado: boolean = false;

  pastedText: string;
  tablaAbastecedora: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder, 
    private stockService: StockService, 
    private usuarioService: UsuarioService,
    private messageService: MessageService, 
    private sharedService: SharedService, 
    private productoService: ProductoService, 
    private medicionesService: MedicionesService,
    private cdref: ChangeDetectorRef, 
    private calculoService: CalculosService) {
      this.change$ = sharedService.aeroplantaSeleccionada.subscribe(_=>{
        this.recargarPagina()
      })
      this.tablaTanques = this.fb.group({
        tanques: new UntypedFormArray([]),
        hora: new UntypedFormArray([]),
        //densidadFormControl: new FormControl()
      });
      this.tablaAbastecedora = this.fb.group({
        abastecedoras: new UntypedFormArray([]),
        horaAbastecedora: new UntypedFormArray([])
      })
  }

  ngOnInit() {
    this.getProductos();
    this.tanques.forEach((value, index) => {
      this.tanques[index].mediciones = new Medicion();
      this.tanques[index].mediciones.codigoContenedor = this.tanques[index].codigo;
      this.tanques[index].mediciones.nombreContenedor = this.tanques[index].nombre;
      this.tanques[index].mediciones.tipoContenedor = 1;
      this.tanques[index].mediciones.varillado = 0;
      this.tanques[index].mediciones.nivel = 0;
      this.tanques[index].mediciones.nivelNatural = 0;
      this.tanques[index].mediciones.temperatura = 0;
      this.tanques[index].mediciones.densidad = 0;
      this.tanques[index].mediciones.nivelAgua = 0;
      var fecha = new Date();
      this.tanques[index].fechaProvisoria = { day: fecha.getUTCDate(), month: fecha.getUTCMonth() + 1, year: fecha.getFullYear() };
      var horaActual = new Date();
      this.tanques[index].horaCarga = { hour: horaActual.getUTCHours() - 3, minute: horaActual.getMinutes(), second: horaActual.getSeconds() };
      this.cadenaTanqueDensidad[index] = this.tanques[index].mediciones.densidad.toString();
      this.validarDensidadCambios(this.cadenaTanqueDensidad[index], index);
    })

    this.abastecedoras.forEach
      ((value, index) => {
        this.abastecedoras[index].mediciones = new Medicion();
        this.abastecedoras[index].mediciones.codigoContenedor = this.abastecedoras[index].codigo;
        this.abastecedoras[index].mediciones.nombreContenedor = this.abastecedoras[index].nombre;
        this.abastecedoras[index].mediciones.tipoContenedor = 4;
        this.abastecedoras[index].mediciones.varillado = 0;
        this.abastecedoras[index].mediciones.nivel = 0;
        this.abastecedoras[index].mediciones.nivelNatural = 0;
        this.abastecedoras[index].mediciones.temperatura = 0;
        this.abastecedoras[index].mediciones.densidad = 0;
        this.abastecedoras[index].mediciones.nivelAgua = 0;
        var fecha = new Date();
        this.abastecedoras[index].fechaProvisoria = { day: fecha.getUTCDate(), month: fecha.getUTCMonth() + 1, year: fecha.getFullYear() };
        var horaActual = new Date();
        this.abastecedoras[index].horaCarga = { hour: horaActual.getUTCHours() - 3, minute: horaActual.getMinutes(), second: horaActual.getSeconds() };
        this.cadenaAbastecedoraDensidad[index] = this.abastecedoras[index].mediciones.densidad.toString();
        this.validarDensidadCambiosAbastecedora(this.cadenaAbastecedoraDensidad[index], index);
      })


    this.tablaTanques.setControl('tanques', this.fb.array([]));
    this.tanques.map((o, i) => {
      const control = new UntypedFormControl(i === 0); // if first item set to true, else false
      control.setValue(o.fechaProvisoria);
      (this.tablaTanques.controls.tanques as UntypedFormArray).push(control);
    });
    this.tablaTanques.setControl('hora', this.fb.array([]));
    this.tanques.map((o, i) => {
      const control = new UntypedFormControl(i === 0); // if first item set to true, else false
      control.setValue(o.horaCarga);
      (this.tablaTanques.controls.hora as UntypedFormArray).push(control);
    });

    this.tablaAbastecedora.setControl('abastecedoras', this.fb.array([]));
    this.abastecedoras.map((o, i) => {
      const control = new UntypedFormControl(i === 0); // if first item set to true, else false
      control.setValue(o.fechaProvisoria);
      (this.tablaAbastecedora.controls.abastecedoras as UntypedFormArray).push(control);
    });
    this.tablaAbastecedora.setControl('horaAbastecedora', this.fb.array([]));
    this.abastecedoras.map((o, i) => {
      const control = new UntypedFormControl(i === 0); // if first item set to true, else false
      control.setValue(o.horaCarga);
      (this.tablaAbastecedora.controls.horaAbastecedora as UntypedFormArray).push(control);
    });  
  }
  //Se agrega la función ngAfterContentChecked con el fin de evitar cartel de error en consola llamado ExpressionChangedAfterItHasBeenCheckedError
  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  ngOnDestroy() {
    this.change$.unsubscribe();
  }

  getProductos() {
    this.productoService.obtenerProductos()
      .subscribe(result => {
        this.productos = result;
      }, () => {

      });
  }

  recargarPagina() {
    this.ngOnInit();
  }

  cancelar() {
    this.cancelado.emit(true);
  }

  changeValue(id: number, property: string, event: any) {
    if (this.tanques[id].mediciones == null) {
      this.tanques[id].mediciones = new Medicion();
    }
    this.tanques[id].mediciones[property] = event.target.value;

  }

  changeValue2(id: number, property: string, event: any) {
    if (this.abastecedoras[id].mediciones == null) {
      this.abastecedoras[id].mediciones = new Medicion();
    }
    this.abastecedoras[id].mediciones[property] = event.target.value;

    if (property == 'varillado') {
      this.abastecedoras[id].mediciones.nivelNatural = event.target.value;
    }

  }

  verificarGraduacion(i: number) {
    if (!this.tanques[i].tieneGraduacion) {
      this.tanques[i].mediciones.varillado = 0;
      //this.tablaTanques.controls.nivelVarilladoFormControl.setValidators(null);
      this.tanqueGraduacion = false;
    } else {
      this.tanques[i].mediciones.varillado = 0;
      //this.tablaTanques.controls.nivelVarilladoFormControl.setValidators([Validators.required]);
      this.tanqueGraduacion = true;
    }
    this.tablaTanques.controls.nivelVarilladoFormControl.updateValueAndValidity();
  }
   
  validarDensidadCambiosAbastecedora(densidad: string, index: number) {
    if (densidad != null){
        this.productoSeleccionado = this.productos.find(element => element.codigoProducto===this.abastecedoras[index].productoActual.codigoProducto);
        this.esValidaDensidad = true;
        if (this.productoSeleccionado){
          this.esValidaDensidad = (Number(densidad) >= this.productoSeleccionado.densidadMin && Number(densidad) <= this.productoSeleccionado.densidadMax);
          this.esDensidadMin[index] = this.productoSeleccionado.densidadMin;
          this.esDensidadMax[index] = this.productoSeleccionado.densidadMax;
        }
        return this.esValidaDensidad;
    }
  }
  
  validarDensidadCambios(densidad: string, index: number) {
    if (densidad != null){
        this.productoSeleccionado = this.productos.find(element => element.codigoProducto===this.tanques[index].productoActual.codigoProducto);
        this.esValidaDensidad = true;
        if (this.productoSeleccionado) {
          this.esValidaDensidad = (Number(densidad) >= this.productoSeleccionado.densidadMin && Number(densidad) <= this.productoSeleccionado.densidadMax);
          this.esDensidadTanqueMin[index] = this.productoSeleccionado.densidadMin;
          this.esDensidadTanqueMax[index] = this.productoSeleccionado.densidadMax;
        }
        return this.esValidaDensidad;
    } else return false;
  }
  //Formatea la dendisdad de los tanques
  formatDensidadAbastecedora(event, i: number) {
    if (event.charCode == 8 || event.charCode == 0) return null;

    if (event.charCode >= 48 && event.charCode <= 57) {
      this.cadenaAbastecedoraDensidad[i] = this.sharedService.formatDensidad(event);
      this.abastecedoras[i].mediciones.densidad = Number(this.cadenaAbastecedoraDensidad[i]);
      this.validarDensidadCambiosAbastecedora(this.cadenaAbastecedoraDensidad[i], i);
    }
    this.recalcularVolumenNaturalAbastecedora(this.abastecedoras[i]);
    return false;
  }

  onPaste(event: ClipboardEvent, i: number) {
    this.pastedText = event.clipboardData.getData('text');
    this.tanques[i].mediciones.densidad = Number(this.pastedText);
    if (!this.validarDensidadCambios(this.pastedText, i)){
      this.cadenaTanqueDensidad[i] = this.pastedText;
      return false;
    }
    else return true; 
  }

  onPasteAbastecedora(event: ClipboardEvent, i: number) {
    this.pastedText = event.clipboardData.getData('text');
    this.abastecedoras[i].mediciones.densidad = Number(this.pastedText);
    if (!this.validarDensidadCambiosAbastecedora(this.pastedText, i)){
      this.cadenaAbastecedoraDensidad[i] = this.pastedText;
      return false;
    }
    else return true; 
  }
  //Formatea la dendisdad de los tanques
  formatDensidad(event, i: number) {
    if (event.charCode == 8 || event.charCode == 0) return null;

    if (event.charCode >= 48 && event.charCode <= 57) {
      this.cadenaTanqueDensidad[i] = this.sharedService.formatDensidad(event);
      this.validarDensidadCambios(this.cadenaTanqueDensidad[i], i);
      this.tanques[i].mediciones.densidad = Number(this.cadenaTanqueDensidad[i]);
    }
    this.recalcularVolumenNatural(this.tanques[i]);
    return false;
  }
  
  onBlurDensidad(target, i: number) { 
    this.recalcularVolumenNatural(this.tanques[i]);
  }

  onBlurDensidadAbastecedora(target, i: number) { 
    this.recalcularVolumenNaturalAbastecedora(this.abastecedoras[i]);
  }

  validarTemperatura(temp: string): boolean {
    let valida = true;
    if (parseInt(temp) > 100) {
      valida = false;
    }

    if (parseInt(temp) < -50) {
      valida = false;
    }
    return valida;
  }

  recalcularVolumenes(t: Tanque) {
    if (t != null && t.mediciones.varillado.toString() != "" && t.mediciones.densidad.toString() != '' && t.mediciones.temperatura.toString() != '') {
      this.medicionesParaTanque = true;

      var tanque = t;
      var varillado = t.mediciones.varillado.toString();

      var dens = t.mediciones.densidad.toString();
      var temp = t.mediciones.temperatura.toString();
      this.calculandoCantidadNeta = true;
      if (tanque != null && varillado != '' && varillado != '0'){
        this.stockService.getConversionLitrosPorVarillado(tanque.codigo, varillado)
          .subscribe(volumenLts => {
            t.mediciones.nivelNatural = parseInt(volumenLts);

            if (dens != "" && temp != "") {
              t.mediciones.nivel = this.calculoService.obtenerCTLNuevo(temp, dens, volumenLts);

            }

            this.calculandoCantidadNeta = false;
          },
            () => {
              t.mediciones.nivelNatural = t.mediciones.varillado;
              this.calculandoCantidadNeta = false;
              this.medicionesParaTanque = false;
            });
      }
    }
  }

  recalcularVolumenNatural(t: Tanque) {
    var volumenLts = Number(t.mediciones.nivelNatural);
    var dens = Number(t.mediciones.densidad);
    var temp = Number(t.mediciones.temperatura);

    if (volumenLts != null && dens != null && temp != null) {
      t.mediciones.nivel = this.calculoService.obtenerCTLNuevo(temp, dens, volumenLts);
    }
  }
  recalcularVolumenNaturalAbastecedora(a: Abastecedora) {
    var volumenLts = Number(a.mediciones.nivelNatural);
    var dens = Number(a.mediciones.densidad);
    var temp = Number(a.mediciones.temperatura);

    if (volumenLts != null && dens != null && temp != null) {
      a.mediciones.nivel = this.calculoService.obtenerCTLNuevo(temp, dens, volumenLts);
    }
  }

  recalcularVolumen15Abastecedora(t: Abastecedora) {
    var volumenLts = Number(t.mediciones.varillado);
    var dens = Number(t.mediciones.densidad);
    var temp = Number(t.mediciones.temperatura);

    if (volumenLts != null && dens != null && temp != null) {
      t.mediciones.nivel = this.calculoService.obtenerCTLNuevo(temp, dens, volumenLts);
    }
  }



  formatDate(year, month, day, hour, minute): Date {
    return new Date(year + '-' + month.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + '-' + day.toLocaleString(undefined, { minimumIntegerDigits: 2 })
      + 'T' + hour.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + minute.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':00');
  }

  validarMedicion(varillado, nivel, nivelNatural, densidad, temp, nivelAgua){
    if(isNumber(varillado) && isNumber(nivel) && isNumber(nivelNatural) && isNumber(densidad) && isNumber(temp) && isNumber(nivelAgua) &&
         densidad > 0 && !isNullOrUndefined(temp)){
          return true;
        }
    return false;
  }

  guardarMedicionesMasivas() {
    let stringMensaje: string = '';
    let medicionValida: boolean = false;
    this.guardandoMediciones = true;
    let medMasivas: Medicion[] = [];
    this.tanques.forEach((value, index) => {
      let fechaMaxTanque = this.formatDate(this.tanques[index].fechaProvisoria.year, this.tanques[index].fechaProvisoria.month, this.tanques[index].fechaProvisoria.day, this.tanques[index].horaCarga.hour, this.tanques[index].horaCarga.minute);
      if (this.tanques[index].mediciones.varillado > 0 && this.tanques[index].mediciones.nivel > 0 && this.tanques[index].mediciones.nivelNatural > 0 && this.tanques[index].mediciones.densidad > 0 && this.tanques[index].mediciones.temperatura && !isNaN(this.tanques[index].mediciones.nivelAgua)) {
        if (this.validarDensidadCambios(this.cadenaTanqueDensidad[index], index) && this.validarTemperatura(this.tanques[index].mediciones.temperatura.toString()) && fechaMaxTanque.getTime() < new Date().getTime()) {
          this.tanques[index].mediciones.fechaHora = this.formatDate(this.tanques[index].fechaProvisoria.year, this.tanques[index].fechaProvisoria.month, this.tanques[index].fechaProvisoria.day, this.tanques[index].horaCarga.hour - 3, this.tanques[index].horaCarga.minute);
          this.tanques[index].mediciones.varillado = Number(this.tanques[index].mediciones.varillado);
          this.tanques[index].mediciones.nivelNatural = Number(this.tanques[index].mediciones.nivelNatural);
          this.tanques[index].mediciones.nivel = Number(this.tanques[index].mediciones.nivel);          
          this.tanques[index].mediciones.temperatura = Number(this.tanques[index].mediciones.temperatura);
          this.tanques[index].mediciones.densidad = Number(this.tanques[index].mediciones.densidad);
          this.tanques[index].mediciones.nivelAgua = Number(this.tanques[index].mediciones.nivelAgua);
          this.tanques[index].mediciones.username = this.usuarioService.getUsernameByName();
          medMasivas.push(this.tanques[index].mediciones);
          medicionValida = true;
        } else {
          stringMensaje += 'El tanque ' + this.tanques[index].codigo + ' tiene errores en su carga. ';
        }
      }else {
        stringMensaje += 'El tanque ' + this.tanques[index].codigo + ' tiene errores en su carga. ';
      }
    });
    this.abastecedoras.forEach((value, index) => {
      let fechaMaxAbastecedora = this.formatDate(this.abastecedoras[index].fechaProvisoria.year, this.abastecedoras[index].fechaProvisoria.month, this.abastecedoras[index].fechaProvisoria.day, this.abastecedoras[index].horaCarga.hour, this.abastecedoras[index].horaCarga.minute);
      if (this.abastecedoras[index].mediciones.varillado > 0 && this.abastecedoras[index].mediciones.nivel > 0 && this.abastecedoras[index].mediciones.nivelNatural > 0 && this.abastecedoras[index].mediciones.densidad > 0 && this.abastecedoras[index].mediciones.temperatura && !isNaN(this.abastecedoras[index].mediciones.nivelAgua)) {
        if (this.validarDensidadCambiosAbastecedora(this.cadenaAbastecedoraDensidad[index], index) && this.validarTemperatura(this.abastecedoras[index].mediciones.temperatura.toString()) && fechaMaxAbastecedora.getTime() < new Date().getTime()) {
          this.abastecedoras[index].mediciones.fechaHora = this.formatDate(this.abastecedoras[index].fechaProvisoria.year, this.abastecedoras[index].fechaProvisoria.month, this.abastecedoras[index].fechaProvisoria.day, this.abastecedoras[index].horaCarga.hour - 3, this.abastecedoras[index].horaCarga.minute);
          this.abastecedoras[index].mediciones.varillado = Number(this.abastecedoras[index].mediciones.varillado);
          this.abastecedoras[index].mediciones.nivelNatural = Number(this.abastecedoras[index].mediciones.nivelNatural);
          this.abastecedoras[index].mediciones.nivel = Number(this.abastecedoras[index].mediciones.nivel);          
          this.abastecedoras[index].mediciones.temperatura = Number(this.abastecedoras[index].mediciones.temperatura);
          this.abastecedoras[index].mediciones.densidad = Number(this.abastecedoras[index].mediciones.densidad);
          this.abastecedoras[index].mediciones.nivelAgua = Number(this.abastecedoras[index].mediciones.nivelAgua);
          this.abastecedoras[index].mediciones.username = this.usuarioService.getUsernameByName();
          medMasivas.push(this.abastecedoras[index].mediciones);
          medicionValida = true;
        } else {
          stringMensaje += 'La abastecedora ' + this.abastecedoras[index].codigo + ' tiene errores en su carga.  ';
        }
      }else {
        stringMensaje += 'La abastecedora ' + this.abastecedoras[index].codigo + ' tiene errores en su carga. ';
      }
    });
    if (medicionValida) {
      if (medMasivas.length > 0) {
        this.medicionesService.crearMedicionesMasivas(medMasivas)
          .subscribe(result => {
            this.messageService.showSuccessMessage('Se crearon las mediciones correctamente.');
            this.guardandoMediciones = false;
            this.cancelado.emit(true);
          },
          (error: ErrorModel) => {
            this.messageService.showErrorMessage(error.message)
            this.guardandoMediciones = false;
          });
      } else {
        this.messageService.showErrorMessage('No hay mediciones para guardar, revise que esten completos los campos.')
        this.guardandoMediciones = false;
      }
    } else {
      this.messageService.showErrorMessage(stringMensaje);
      this.guardandoMediciones = false;
    }
  }
  /*
  get densidadFormControl() {
    return this.tablaTanques.value.controls.tanques.mediciones.densidad;
  }*/
}
