import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal, NgbDatepickerConfig, NgbDateStruct, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { CierreTurno } from 'src/app/shared/models/cierreTurno/CierreTurno';

@Component({
  selector: 'app-set-fecha-reimputacion',
  templateUrl: './set-fecha-reimputacion.component.html',
  styleUrls: ['./set-fecha-reimputacion.component.css']
})
export class SetFechaReimputacionComponent implements OnInit {
  @Input() fechaHoraMaximaCerrado: string;
  @Input() fechaHoraMinimaCerrado: string;
  @Input() fechaHoraMinimaAbierto: string;
  @Input() fechaSugerida: string;
  @Input() cierreDeTurno: CierreTurno;
  @Input() tipoImputacion: number;

  //Fecha de seleccion
  modelFechaInicial: NgbDateStruct;
  timeInicial: NgbTimeStruct;
  //Tope maximo y minimo a reimputar a turno cerrado
  //modelFechaMaximaCerrado: NgbDateStruct;
  //timeMaximaCerrado: NgbTimeStruct;
  //modelFechaMinimaCerrado: NgbDateStruct;
  //timeMinimaCerrado: NgbTimeStruct;
  //Tope minimo a reimputar a turno abierto
  //modelFechaMinimaAbierto: NgbDateStruct;
  //timeMinimaAbierto: NgbTimeStruct;

  fechaElegida: Date;
  fechaMinimaTope: Date;
  fechaMaximaTope: Date;
  fechaMinimaTopeAbierto: Date;

  fechaAEnviar: string;

  reimputarForm = new UntypedFormGroup({
    fechaReimputarFormControl: new UntypedFormControl(''),
    horaReimputarFormControl: new UntypedFormControl('')
  });

  constructor(private datepickerConfig: NgbDatepickerConfig, public activeModal: NgbActiveModal) { }

  ngOnInit() {
    var horaActual = new Date();
    this.horaInicio.setValue(horaActual.getUTCHours());

    //fecha de seleccion
    var fec = new Date(this.fechaSugerida);
    this.modelFechaInicial = { day: fec.getDate(), month: fec.getMonth() + 1, year: fec.getFullYear() }
    var hora = new Date(fec.setMinutes(fec.getMinutes()));
    this.timeInicial = { hour: hora.getHours(), minute: hora.getMinutes(), second: hora.getSeconds() };
    this.fechaInicio.setValue(this.modelFechaInicial);
    this.horaInicio.setValue(this.timeInicial);

    //fecha maxima del turno cerrado
    /*var fecMaximaCerrado = new Date(this.fechaHoraMaximaCerrado);
    this.modelFechaMaximaCerrado = { day: fecMaximaCerrado.getDate(), month: fecMaximaCerrado.getMonth() + 1, year: fecMaximaCerrado.getFullYear() }
    var horaMaximaCerrado = new Date(fecMaximaCerrado.setMinutes(fecMaximaCerrado.getMinutes()));
    this.timeMaximaCerrado = { hour: horaMaximaCerrado.getHours(), minute: horaMaximaCerrado.getMinutes(), second: horaMaximaCerrado.getSeconds() };*/
    this.fechaMaximaTope = new Date(this.fechaHoraMaximaCerrado);

    //fecha minima del turno cerrado
    /*var fecMinimaCerrado = new Date(this.fechaHoraMinimaCerrado);
    this.modelFechaMinimaCerrado = { day: fecMinimaCerrado.getDate(), month: fecMinimaCerrado.getMonth() + 1, year: fecMinimaCerrado.getFullYear() }
    var horaMinimaCerrado = new Date(fecMinimaCerrado.setMinutes(fecMinimaCerrado.getMinutes()));
    this.timeMinimaCerrado = { hour: horaMinimaCerrado.getHours(), minute: horaMinimaCerrado.getMinutes(), second: horaMinimaCerrado.getSeconds() };*/
    this.fechaMinimaTope = new Date(this.fechaHoraMinimaCerrado);

    //fecha minima del turno abierto
    /*var fecMinimaAbierto = new Date(this.fechaHoraMinimaAbierto);
    this.modelFechaMinimaAbierto = { day: fecMinimaAbierto.getDate(), month: fecMinimaAbierto.getMonth() + 1, year: fecMinimaAbierto.getFullYear() }
    var horaMinimaAbierto = new Date(fecMinimaAbierto.setMinutes(fecMinimaAbierto.getMinutes()));
    this.timeMinimaAbierto = { hour: horaMinimaAbierto.getHours(), minute: horaMinimaAbierto.getMinutes(), second: horaMinimaAbierto.getSeconds() };*/
    this.fechaMinimaTopeAbierto = new Date(this.fechaHoraMinimaAbierto);

    console.log(this.tipoImputacion);
  }

  fechaDesdeMayorQueHasta() {
    let esMayor: boolean = false;
    this.fechaElegida = new Date(this.fechaInicio.value.year, this.fechaInicio.value.month - 1, this.fechaInicio.value.day, this.horaInicio.value.hour, this.horaInicio.value.minute);    
    if (this.tipoImputacion == 0) {
      //this.fechaMaximaTope = this.dateFromDateTimeStruct(this.modelFechaMaximaCerrado, this.timeMaximaCerrado);
      //this.fechaMinimaTope = this.dateFromDateTimeStruct(this.modelFechaMinimaCerrado, this.timeMinimaCerrado);
      if (this.fechaElegida >= this.fechaMaximaTope) { esMayor = true; }
      if (this.fechaElegida <= this.fechaMinimaTope) { esMayor = true; }
    } else {
      //this.fechaMinimaTopeAbierto = this.dateFromDateTimeStruct(this.modelFechaMinimaAbierto, this.timeMinimaAbierto);
      if(this.fechaElegida <= this.fechaMinimaTopeAbierto) { esMayor = true}
    }
    return esMayor;
  }

  /**
   * Convierte una NgbDateStruct y una NgbTimeStruct en un Date
   */
  /*dateFromDateTimeStruct(date: NgbDateStruct, hour: NgbTimeStruct): Date {
    if (date == null || hour == null)
      return null;
    let dateReturn = new Date();
    dateReturn.setDate(date.day);
    dateReturn.setMonth(date.month - 1);
    dateReturn.setFullYear(date.year);
    dateReturn.setHours(hour.hour);
    dateReturn.setMinutes(hour.minute);
    return dateReturn;
  }*/

  aceptarFecha(){
    this.fechaAEnviar = this.formatDate(
      this.fechaInicio.value.year, this.fechaInicio.value.month,
      this.fechaInicio.value.day, this.horaInicio.value.hour, this.horaInicio.value.minute);

    //this.fechaAEnviar = this.formatDate(this.fechaElegida.getFullYear(), this.fechaElegida.getMonth() + 1 , this.fechaElegida.getDate(), this.fechaElegida.getHours(), this.fechaElegida.getMinutes());
    this.activeModal.close(this.fechaAEnviar);
  }

  formatDate(year, month, day, hour, minute): string {
    return year + '-' + month.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + '-' + day.toLocaleString(undefined, { minimumIntegerDigits: 2 })
      + 'T' + hour.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + minute.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':00';
  }

  get horaInicio() {
    return this.reimputarForm.get('horaReimputarFormControl');
  }

  get fechaInicio() {
    return this.reimputarForm.get('fechaReimputarFormControl');
  }
}
