import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, UntypedFormArray } from '@angular/forms';
import { NgbModal, NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { ProductoService } from '../producto.service';
import { Producto } from 'src/app/shared/models/abm/Producto';
import { parametroImpositivo } from 'src/app/shared/models/abm/ParametroImpositivo';
import { BaseCalculo } from 'src/app/shared/enums/baseCalculo';
import { TipoValor } from 'src/app/shared/enums/tipoValor';
import { CodigoImpuesto } from 'src/app/shared/enums/codigoImpuesto';
import { Zona } from 'src/app/shared/enums/zona';
import { MessageService } from 'src/app/shell/message.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CalculoBaseImponible } from 'src/app/shared/enums/CalculoBaseImponible';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';


@Component({
  selector: 'app-impuestos',
  templateUrl: './impuestos.component.html',
  styleUrls: ['./impuestos.component.css']
})
export class ImpuestosComponent implements OnInit {

  @Input() impuestos: parametroImpositivo[] = [];
  @Input() productoSeleccionado: Producto;
  impuestoSeleccionado: parametroImpositivo;
  codigoimpuesto: CodigoImpuesto;
  cargandoInfoImpuesto: boolean = false;

  TipoUsuario = TipoUsuario;

  constructor(private modalService: NgbModal, private productoService: ProductoService, private calendar: NgbCalendar, private messageService: MessageService,
    private fb: UntypedFormBuilder, private spinner: NgxSpinnerService, public authService: AuthService) {
      this.impuestosForm = this.fb.group({
        impuestosArray : new UntypedFormArray([])
      })
    }

  impuestosForm: UntypedFormGroup;
  impuestosArray: parametroImpositivo[];

  ngOnInit() {
    this.spinner.show('spinnerGrafico');
    this.cargandoInfoImpuesto = true;
    this.obtenerProducto();
  }

  ngOnChanges(): void {
    setTimeout(() => {
      this.spinner.show('spinnerLista');
    });
    this.ngOnInit();
  }

  actualizarImpuesto(impuesto: parametroImpositivo){
   if(impuesto.activa == true){
      impuesto.activa = false;
    }else{
      impuesto.activa = true;
    }
    let actual: NgbDateStruct;
    actual = this.calendar.getToday();
    let hora: Date = new Date();
    impuesto.fechaActualizacion = this.formatDate(
      actual.year, actual.month,
      actual.day, hora.getHours(), hora.getMinutes());

      this.productoService.modificarImpuesto(impuesto)
        .subscribe(result => {
          this.messageService.clear();
          let resp: parametroImpositivo = result;
          if (resp.activa == true){
            this.messageService.showSuccessMessage("Se ha activado el impuesto correctamente");
          }else{
            this.messageService.showErrorMessage("Se ha desactivado el impuesto correctamente");
          }
        }, () => {
        });
  }

  recargar(event){
    let resp = event;
    if (resp){
      this.obtenerProducto();
    }
  }

  formatDate(year, month, day, hour, minute): string {
    return year + '-' + month.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + '-' + day.toLocaleString(undefined, { minimumIntegerDigits: 2 })
      + 'T' + hour.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + minute.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':00';
  }

  obtenerProducto() {
    this.productoService.obtenerProducto(this.productoSeleccionado.id)
      .subscribe(result => {
        this.impuestos = result.impuestosProducto;
        this.impuestosArray = result.impuestosProducto.reverse();
        this.addCheckboxes();
        this.spinner.hide('spinnerGrafico');
        this.cargandoInfoImpuesto = false;
      }, () => {
      });
  }

  private addCheckboxes() {
    // reinicio el formulario
    this.impuestosForm.setControl('impuestosArray', this.fb.array([]));
    this.impuestosArray.map((o, i) => {
      const control = new UntypedFormControl(i === 0); // if first item set to true, else false
      control.setValue(o.activa);
      (this.impuestosForm.controls.impuestosArray as UntypedFormArray).push(control);
    });
  }

  mostrarNuevoImpuesto(content) {
    this.modalService.open(content, { centered: true, size: 'lg', backdrop: 'static' });
  }

  mostrarDetalleImpuesto(i: number, content) {
    this.modalService.open(content, { centered: true, size: 'lg', backdrop: 'static' });
    this.impuestoSeleccionado = this.impuestos[i];
  }

  get codigo() {
    return this.impuestosForm.get('activoFormControl');
  }


  obtenerCodigoImpuesto(c: CodigoImpuesto): string {
    return CodigoImpuesto[c];
  }


  obtenerTipoValor(n: TipoValor): string {
   return TipoValor[n];
  }


  obtenerBaseCalculo(n: CalculoBaseImponible): string {
    return CalculoBaseImponible[n];
  }

  obtenerZona(n: Zona): string{
    return Zona[n];
  }


}


