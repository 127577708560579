import { TipoPersona } from "../../enums/TipoPersona";

export class ClienteEncabezado{
    id: string;
    razonSocial: string;
    numeroDocumento: string;
    tipoDocumento: number;
    condicionPrecio: number;
    ctaSap: string;
    enNuevo: boolean;
    esTrader: boolean;
    permiteRanchos: boolean;
    percepcionIVADif: number;
    fechaPercepcionIVADif: string;
    clienteObservado: boolean;
    tipoPersona: TipoPersona;
    cta_SGC:string;
}
