import { UsuarioNivelAprobacion } from "./UsuarioNivelAprobacion";

export class NivelAprobacion {
   activo: boolean;

   codigo: string;
   nombre: string;
   tipoNivel: number;

   maximoPorcentajeAprobacion: number; // reemplaza  // maximoBonificaciones: number; maximoRecargos: number;
   maximoVariacion: number; // reemplaza maximaVariacionPrecio
   puedeDesactivar: boolean;
   apruebaRetroactivos: boolean;


   // esto se usara para refacturacion
   //montoMaximoAprobacion: number;
   //motivos: Number[]; // lista de motivos


   // por ahora se descartan

   // nivelJerarquico: number;
   // maximoBonificaciones: number;
   // maximoRecargos: number;
   usuariosNivel: UsuarioNivelAprobacion[];
   // apruebaRetroactivos: boolean;


   //para refacturacion

   montoMaximoAprobacion: number;
   motivos: number[];
}
