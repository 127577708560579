import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { UsuarioService } from 'src/app/account/usuario.service';
import { ComprobanteService } from 'src/app/facturacion/comprobante.service';
import { Cano } from 'src/app/shared/models/stock/Cano';
import { StockService } from 'src/app/stock/stock.service';
import { Comprobante } from '../models/facturacion/Comprobante';
import { Factura } from '../models/facturacion/Factura';
import { VisorComprobanteCargamentoComponent } from '../visor-comprobante-cargamento/visor-comprobante-cargamento.component';
import { VisorComprobanteComponent } from '../visor-comprobante/visor-comprobante.component';

@Component({
  selector: 'app-listador-facturas',
  templateUrl: './listador-facturas.component.html',
  styleUrls: ['./listador-facturas.component.css']
})
export class ListadorFacturasComponent implements OnInit {

  @Input() listaFacturas:any[]; // este se va a usar para traerse
  //una lista de facturas de afuera

  facturaObtenida: Comprobante;

  canerias: Cano[] = [];
  tablaCanerias: UntypedFormGroup;
  obteniendoCanerias: boolean = false;
  messageCanerias: string = 'C A R G A N D O    F A C T U R A S . . .';

  constructor(
    private activeModal: NgbModal,
    private usuarioService: UsuarioService,
    private fb: UntypedFormBuilder,
    private stockService: StockService,
    private spinner: NgxSpinnerService,
    private facturacionService:ComprobanteService) {
    this.tablaCanerias = this.fb.group({
      caneria: new UntypedFormArray([])
    });
  }


  ngOnInit() {
    this.getCañerias();
    this.obtenerComprobanteFacturaEjemplo();
  }

  getCañerias() {
    this.spinner.show('spinnerCanerias');
    this.obteniendoCanerias = true;
    this.stockService.getCanerias(this.usuarioService.getAeroplantaActual().codigoAeroplanta)
      .subscribe(result => {
        this.canerias = result.sort((a, b) => { if (a.fechaModificacion < b.fechaModificacion) { return 1 } else { return -1 } });
        this.addCheckboxes();
        this.spinner.hide('spinnerCanerias');
        this.obteniendoCanerias = false;
      }, () => {
        this.obteniendoCanerias = false;
        this.spinner.hide('spinnerCanerias');
      })
  }

  private addCheckboxes() {
    // reinicio el formulario
    this.tablaCanerias.setControl('caneria', this.fb.array([]));
    this.canerias.map((o, i) => {
      const control = new UntypedFormControl(i === 0); // if first item set to true, else false
      control.setValue(o.activo);
      (this.tablaCanerias.controls.caneria as UntypedFormArray).push(control);
    });
  }


  verFactura(content) {
    let modalRef = this.activeModal.open(content, { centered: true, backdrop: 'static' });
    modalRef.componentInstance.facturaObtenida = this.facturaObtenida;
    modalRef.result.then(result => {


    }, () => {

    });
  }

  // traigo una factura ejemplo

  obtenerComprobanteFacturaEjemplo() {
    //this.mensajeError = "";
    setTimeout(() => {

      this.spinner.show('spinnerLista');
      setTimeout(() => {
        /** spinner ends after 2 seconds */
        this.spinner.hide('spinnerLista');
      }, 4000);
    });

    this.facturacionService.obtenerComprobantePorPrefijoNumeroTipo(1, 80, 1)
      .subscribe(result => {
        this.facturaObtenida = result;
        if (result != null) {
          //this.mensajeError = "";
        }
        else {
          this.facturaObtenida = null; //se limpia el comprobante.
          //this.mensajeError = "No se encontraron comprobantes con busqueda seleccionada. " + this.prefijo.value + '-' + this.numero.value;
        }

      },
        err => {
          // Entra aquí si el servicio entrega un código http de error EJ: 404,
          //500
          this.facturaObtenida = null; //se limpia el comprobante.
          //this.mensajeError = "No se encontraron comprobantes con busqueda seleccionada. " + this.prefijo.value + '-' + this.numero.value;
        });

  }

}
