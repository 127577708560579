import { Component, OnInit } from '@angular/core';
import { NgbCalendar, NgbDatepickerConfig, NgbDateStruct, NgbModal, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { UsuarioService } from 'src/app/account/usuario.service';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { ComprobanteService } from 'src/app/facturacion/comprobante.service';
import { CierreCaja } from 'src/app/shared/models/cierreTurno/CierreCaja';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { SharedService } from 'src/app/shared/shared.service';
import { MessageService } from 'src/app/shell/message.service';


@Component({
  selector: 'app-cierres-caja',
  templateUrl: './cierres-caja.component.html',
  styleUrls: ['./cierres-caja.component.css']
})
export class CierresCajaComponent implements OnInit {

  model: NgbDateStruct;
  model1: NgbDateStruct;
  fechaDesde: Date;
  fechaHasta: Date;
  change$: Subscription;

  cierreSeleccionado: CierreCaja;

  cierresCaja: CierreCaja[] = [];

  obteniendoCierres: boolean = false;

  constructor(private datepickerConfig2: NgbDatepickerConfig,
    private comprobanteService: ComprobanteService,
    private usuarioService: UsuarioService,
    private calendar: NgbCalendar,
    private spinner: NgxSpinnerService,
    private messageService: MessageService,
    sharedService: SharedService) {
      this.change$ = sharedService.aeroplantaSeleccionada.subscribe(_=>{ 
        this.cambiarDeAeroplanta()
      });
  }

  ngOnInit() {
    this.model = this.calendar.getToday();
    this.model1 = this.calendar.getToday();
    let inicio: any = { day: 1, month: 1, year: 2019 };
    this.datepickerConfig2.minDate = inicio;
    let fin: any = { day: this.model.day, month: this.model.month, year: this.model.year };
    this.model1 = this.calendar.getToday();
    this.datepickerConfig2.maxDate = fin;
    this.buscarDesdeHasta();
  }

  ngOnChanges(): void {
    this.ngOnInit();
  }

  cambiarDeAeroplanta() {
    this.ngOnInit();
  }

  ngOnDestroy() {
    this.change$.unsubscribe();
  }

  formatDate(year, month, day, hour, minute): string {
    return year + '-' + month.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + '-' + day.toLocaleString(undefined, { minimumIntegerDigits: 2 })
      + 'T' + hour.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + minute.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':00';
  }

  buscarDesdeHasta() {
    this.cierresCaja = [];
    this.reinicioFechaInicioFechaFin();
    this.buscarCierresPorFecha(this.usuarioService.getAeroplantaActual().codigoAeroplanta, this.fechaDesde, this.fechaHasta);
  }

  reinicioFechaInicioFechaFin() {
    this.fechaDesde = new Date(this.model.year, this.model.month - 1, this.model.day);
    this.fechaHasta = new Date(this.model1.year, this.model1.month - 1, this.model1.day);
    this.fechaHasta.setHours(23, 59, 59, 99);
  }

  buscarCierresPorFecha(codigoAeroplanta: string, fechaInicio: Date, fechaFin: Date) {
    this.obteniendoCierres = true;
    this.spinner.show('spinnerGrafico');
    let desde: string = this.formatDate(fechaInicio.getUTCFullYear(), fechaInicio.getMonth() + 1, fechaInicio.getDate(), 0, 0);
    let hasta: string = this.formatDate(fechaFin.getUTCFullYear(), fechaFin.getMonth() + 1, fechaFin.getDate(), 23, 59);
    this.comprobanteService.getCierresCaja(codigoAeroplanta, desde, hasta)
      .subscribe(result => {
        this.cierresCaja = result;
        this.obteniendoCierres = false;
        this.spinner.hide('spinnerGrafico');
      }, (err: ErrorModel) => {
        this.messageService.showErrorMessage('Hubo un error al obtener los cierres. ' + err.description);
        this.cierresCaja = [];
        this.obteniendoCierres = false;
        this.spinner.hide('spinnerGrafico');
      });
  }

  mostrarDetalle(i: number) {
    this.cierreSeleccionado = this.cierresCaja[i];
  }
}
