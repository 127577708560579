<ul class="nav nav-tabs justify-content-start">
  <li  class="nav-item"><a
    class="nav-link" routerLink="holdeoAeroplantas" routerLinkActive="active"><b>Holdeo general</b></a>
</li>
<li  class="nav-item"><a
    class="nav-link" routerLink="holdeoCuentas" routerLinkActive="active"><b>Holdeo de cuentas</b></a>
</li>
<li
  class="nav-item"><a class="nav-link" routerLink="holdeoAerovales" routerLinkActive="active"><b>Holdeo de aerovales </b></a>
</li>
</ul>
<router-outlet></router-outlet>
