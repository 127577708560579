import { Guid } from 'guid-typescript';

export class Aeropuerto {
    cabotaje: boolean;
    ciudadId: Guid;
    ciudadNombre: string;
    codigoAeropuerto: string;
    direccion: string;
    direccionComplemento: string;    
    id: Guid;
    nombre: string;
    paisId: Guid;
    codigoPais: string;
}
