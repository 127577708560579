
import { Component, OnInit } from '@angular/core';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';
import { AuthService } from 'src/app/core/authentication/auth.service';
@Component({
  selector: 'app-stock-index',
  templateUrl: './stock-index.component.html',
  styleUrls: ['./stock-index.component.css']
})
export class StockIndexComponent implements OnInit {
  TipoUsuario = TipoUsuario;
  constructor(private authService: AuthService) { }

  ngOnInit() {
  }
  hasRole(...roles: TipoUsuario[]) {
    return this.authService.hasRole(...roles);
  }
}
