import { Component, Inject, Input, LOCALE_ID, NgModule, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbCalendar, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { Cliente } from 'src/app/shared/models/cliente/Cliente';
import { ComprobanteService } from '../../comprobante.service';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { formatDate } from '@angular/common'
import { Holdeo } from 'src/app/shared/models/facturacion/Holdeo';
import { UsuarioService } from 'src/app/account/usuario.service';
import { MessageService } from 'src/app/shell/message.service';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { Cuenta } from 'src/app/shared/models/cliente/Cuenta';
import { Aerovale } from 'src/app/shared/models/despacho/Aerovale';
import { TipoHoldeo } from 'src/app/shared/enums/TipoHoldeo';

@Component({
  selector: 'app-nuevo-holdeo',
  templateUrl: './nuevo-holdeo.component.html',
  styleUrls: ['./nuevo-holdeo.component.css']
})
export class NuevoHoldeoComponent implements OnInit {
  @Input() tipo: TipoHoldeo;

  modelDesde: NgbDateStruct;
  modelHasta: NgbDateStruct;
  fechaDesde: Date;
  fechaHasta: Date;
  clientes: Cliente[];
  errorAerovale: boolean = false;
  esperandoCarga: boolean = false;
  modelCliente: any;
  cuentas: Cuenta[] = [];
  obteniendoCuentas: boolean = false;
  guardandoHoldeo: boolean = false;
  aerovaleEncabezado: Aerovale;
  obteniendoAerovale: boolean = false;
  puedeGuardar: boolean = false;
  TipoHoldeo=TipoHoldeo;
  // isChecked: boolean;
  nuevoHoldeoForm = new UntypedFormGroup({
    clienteFormControl: new UntypedFormControl(null),
    cuentasFormControl: new UntypedFormControl(null),
    fechaFormControlDesde: new UntypedFormControl(''),
    fechaFormControlHasta: new UntypedFormControl(''),
    prefijoFormControl: new UntypedFormControl(''),
    numeroFormControl: new UntypedFormControl(''),
    motivoFormControl: new UntypedFormControl(''),
    holdeoFinDeMesFormControl: new UntypedFormControl(null)
  });

  constructor(private calendar: NgbCalendar, private facturacionService: ComprobanteService, public modal: NgbActiveModal, @Inject(LOCALE_ID) public locale: string,
    private usuarioService: UsuarioService, private messageService: MessageService) { }

  ngOnInit() {
    this.getClientes();
    this.holdeoFinDeMesFormControl.setValue(false);
    this.modelDesde = this.calendar.getToday();
    this.fechaDesde = new Date(this.modelDesde.year, this.modelDesde.month - 1, this.modelDesde.day);
    this.modelHasta = this.calendar.getToday();
    this.fechaHasta = new Date(this.modelHasta.year, this.modelHasta.month - 1, this.modelHasta.day);
  }

  getClientes() {
    this.esperandoCarga = true;
    this.facturacionService.getClientesPrevioPago()
      .subscribe(result => {
        this.clientes = result;
        this.esperandoCarga = false;
      });
  }


  buscarCuentas() {
    this.cuentas = [];
    this.obteniendoCuentas = true;
    for (let cli of this.clientes) {
      if (cli.id == this.cliente.value.id) {
        for (let cuen of cli.cuentas) {
          this.cuentas.push(cuen);
        }
      }
    }
    this.cuenta.setValue(this.cuentas[0]);
    this.obteniendoCuentas = false;
  }

  CompletarPrefijo(prefijo: number) {
    let p = prefijo.toString()
    return p.padStart(3, '0')
  }
  CompletarNumero(numero: number) {
    let n = numero.toString()
    return n.padStart(5, '0')
  }

  obtenerTipo(t: number) {
    switch (t) {
      case 0:
        return 'holdeo general';
      case 1:
        return 'holdeo de cuenta';
      case 2:
        return 'holdeo de aerovale';
    }
  }

  formatter = (x: { razonSocial: string, ctaSap: string }) => `${x.razonSocial}`;

  selectCustomer(e: any): void {
    this.cliente.setValue(e.item);
    this.buscarCuentas();
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => this.clientes == null || term.length < 2 || this.esperandoCarga ? [] :
        this.clientes.filter(v => this.containsString(this.cliente.value, v.razonSocial, v.numeroDocumento)).slice(0, 10))
    )

  /**
   * Determina si una cadena se encuentra dentro de otra
   * @param searchValue Valor a buscar
   * @param searchIn Valores sobre los que buscar searchValue
   */
  containsString(searchValue: string, ...searchIn: string[]): boolean {
    const concat = searchIn.toString();
    return concat.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
  }

  formatDate(year: number, month: number, day: number, hour: number, minute: number): string {
    return formatDate(new Date(year, month - 1, day, hour, minute, 0), 'y-MM-ddTHH:mm:ss', this.locale);
  }

  buscarAerovale() {
    this.aerovaleEncabezado = null;
    this.errorAerovale = false;
    this.obteniendoAerovale = true;
    this.facturacionService.buscarAerovale(this.prefijoFormControl.value, this.numeroFormControl.value)
      .subscribe(result => {
        this.aerovaleEncabezado = result;
        this.obteniendoAerovale = false;
      }, (err: ErrorModel) => {
        this.errorAerovale = true;
        this.obteniendoAerovale = false;
      })
  }

  guardarholdeo() {

    if (this.checkearDatos()) {
      this.guardandoHoldeo = true;
      let h = new Holdeo();
      if (this.tipo == TipoHoldeo.CuentaCliente) {
        h.ctA_SGC = this.cuenta.value.ctA_SGC;
        h.ctA_Descripcion = this.cuenta.value.descripcion;
      }
      if (this.tipo == TipoHoldeo.Aerovale) {
        h.aerovaleId = this.aerovaleEncabezado.id;
        h.ctA_Descripcion = this.aerovaleEncabezado.clienteRazonSocial;
        h.ctA_Descripcion += ' / ' +this.aerovaleEncabezado.prefijo + '-' + this.aerovaleEncabezado.numeroAerovale;
      }
      h.holdearFinDeMes = this.holdeoFinDeMesFormControl.value;
      h.detalle = this.motivoFormControl.value;
      h.fechaDesde = this.formatDate(this.fechaInicial.value.year, this.fechaInicial.value.month, this.fechaInicial.value.day, 0, 0);
      h.fechaHasta = this.formatDate(this.fechaFinal.value.year, this.fechaFinal.value.month, this.fechaFinal.value.day, 23, 59);
      h.tipoHoldeo = this.tipo;
      h.responsable = this.usuarioService.getNombresCompletosUsuario();
      h.activo = true;
      console.log("holdeo "+JSON.stringify(h));
      this.facturacionService.guardarHoldeo(h)
        .subscribe(result => {
          if (result) {
            this.messageService.showSuccessMessage('Se creó correctamente el ' + this.obtenerTipo(h.tipoHoldeo));
            this.guardandoHoldeo = false;
            this.modal.close(true);
          }
        }, (err: ErrorModel) => {
          this.messageService.showErrorMessage('Se produjo un error al crear el ' + this.obtenerTipo(h.tipoHoldeo));
          this.guardandoHoldeo = false;
        });
    } else {
      this.messageService.showErrorMessage('Por favor complete todos los datos antes de guardar.');
    }
  }

  checkearDatos() {
    if (this.fechaDesdeMayorQueHasta() != true) {
      if (this.tipo == TipoHoldeo.TodaFacturacion) {
        this.puedeGuardar = true;
        return this.puedeGuardar;
      }
      if (this.tipo == TipoHoldeo.CuentaCliente) {
        if (this.cliente.value != null && this.cuenta.value != null) {
          this.puedeGuardar = true;
          return this.puedeGuardar;
        }
      }
      if (this.aerovaleEncabezado != null) {
        this.puedeGuardar = true;
        return this.puedeGuardar;
      }
    } else {
      this.messageService.showErrorMessage('La fecha inicial debe ser anterior a la fecha final.');
      this.puedeGuardar = false;
    }
  }

  fechaDesdeMayorQueHasta() {
    let esMayor: boolean = false;
    this.fechaDesde = new Date(this.modelDesde.year, this.modelDesde.month - 1, this.modelDesde.day);
    this.fechaHasta = new Date(this.modelHasta.year, this.modelHasta.month - 1, this.modelHasta.day);
    if (this.fechaDesde > this.fechaHasta) { esMayor = true; }
    return esMayor;
  }

  get cliente() {
    return this.nuevoHoldeoForm.get('clienteFormControl');
  }

  get cuenta() {
    return this.nuevoHoldeoForm.get('cuentasFormControl');
  }

  get fechaInicial() {
    return this.nuevoHoldeoForm.get('fechaFormControlDesde');
  }

  get fechaFinal() {
    return this.nuevoHoldeoForm.get('fechaFormControlHasta');
  }

  get prefijoFormControl() {
    return this.nuevoHoldeoForm.get('prefijoFormControl');
  }

  get numeroFormControl() {
    return this.nuevoHoldeoForm.get('numeroFormControl');
  }

  get motivoFormControl(){
    return this.nuevoHoldeoForm.get('motivoFormControl');
  }

  get holdeoFinDeMesFormControl(){
    return this.nuevoHoldeoForm.get('holdeoFinDeMesFormControl');
  }
}
