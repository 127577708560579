import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UsuarioService } from 'src/app/account/usuario.service';
import { RemitosRanchos } from 'src/app/shared/models/ranchos/RemitosRanchos';
import { RanchoService } from '../../../rancho.service';
import { EstadoConsumo } from 'src/app/shared/enums/EstadoConsumo';
import { MessageService } from 'src/app/shell/message.service';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { ConsumoRancho } from 'src/app/shared/models/ranchos/ConsumoRancho';
import { DecimalPipe } from '@angular/common';
import { AerovalesService } from 'src/app/despacho/aerovales.service';
import { AerovaleDetalle } from 'src/app/shared/models/despacho/AerovaleDetalle';
import { AerovaleDetalleComponent } from 'src/app/despacho/aerovale-detalle/aerovale-detalle.component';
import { Usuario } from 'src/app/shared/models/ranchos/Usuario';
@Component({
  selector: 'app-modal-marcar-rancho',
  templateUrl: './modal-marcar-rancho.component.html',
  styleUrls: ['./modal-marcar-rancho.component.scss']
})
export class ModalMarcarRanchoComponent implements OnInit {

  // entradas
  @Input() codigoRancho: string;
  @Input() aerovale: RemitosRanchos;
  @Input() consumoRanchoid: string;

  // locales
  marcando: boolean = false;
  reconocido: boolean = false;
  desconocido: boolean = false;
  observacionesFormControl = new UntypedFormControl();
  consumo: ConsumoRancho;
  EstadoConsumo = EstadoConsumo;

  // ver aerovale
  aerovaleDetalle: AerovaleDetalle;
  obteniendoAerovale: boolean = false;

  constructor(public modal: NgbActiveModal,
    private activeModal: NgbModal,
    private ranchoService: RanchoService,
    private usuarioService: UsuarioService,
    private messageService: MessageService,
    private decimalPipe: DecimalPipe,
    private aerovaleService: AerovalesService) { }

  ngOnInit(): void {
  }

  verAerovale(id: string) {
    this.aerovaleDetalle = null;    
    this.obteniendoAerovale = true;        
    this.aerovaleService.getAerovalePorIdyTipo(id)
      .subscribe(result => {
        this.aerovaleDetalle = result;                   
        this.obteniendoAerovale = false;
        if(this.aerovaleDetalle != null) {
          this.mostrarAerovale();
        }}, (err: ErrorModel) => {
          this.messageService.showErrorMessage('Hubo un error al obtener el Aerovale' + err.description);             
          this.obteniendoAerovale = false;  
        });
  }

  mostrarAerovale() {
    if(this.aerovaleDetalle) {
      let modalRef = this.activeModal.open(AerovaleDetalleComponent, { size: "lg", centered: true })
      modalRef.componentInstance.aerovaleDetalle = this.aerovaleDetalle;  
    }    
  }

  marcandoConsumo(estado: number){  
    this.marcando = true;  
    estado==EstadoConsumo.Reconocido ? this.reconocido = true : this.desconocido = true
    this.ranchoService.putActualizarEstadoConsumosRanchosV2(this.consumoRanchoid, estado, this.armarUsuario())
    .subscribe(result => {
      this.consumo = result;
      this.messageService.showSuccessMessage("El estado del consumo se actualizo correctamente."+"\n"+"Rancho: "+this.consumo.codigoRancho);
      estado==EstadoConsumo.Reconocido ? this.reconocido = false : this.desconocido = false;
      this.marcando = false;  
      this.modal.close(this.consumo);
    },
    (err: ErrorModel) => {
      this.messageService.showErrorMessage('Hubo un error al actualizar el estado del consumo. ' + err.description);
      estado==EstadoConsumo.Reconocido ? this.reconocido = false : this.desconocido = false;
      this.marcando = false;
    });
  }

  armarUsuario() : Usuario {
    let usuario = {
                  id: this.usuarioService.getIdUsuario(),
                  nombresCompletos: this.usuarioService.getNombresCompletosUsuario(),
                  dni: this.usuarioService.getDniUsuario(),
                  email: this.usuarioService.getEmailUsuario()
                }      
    return usuario;
  }

  cerrarModal() {
    this.modal.close(null);
  }

  get observaciones()  { 
    return this.observacionesFormControl;
  }
}
