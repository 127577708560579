<nav class="navbar navbar-light bg-light navbar-expand-lg mb-3">
  <form class="form-inline flex-fill" id="formularioFiltros">
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <ul class="navbar-nav me-auto flex-row">
      <div class="nav-item" [formGroup]="searchForm">
        <input id="busquedaFormControl" type="text" class="form-control input-sm" style="width: 10em;"
          formControlName="busqueda" (input)="filtrarByR()" placeholder="Buscar" />
      </div>

      <div *ngIf="this.tipoBonificacionRecargo == TipoBonificacionRecargo.Expendedora" class="nav-item">
        <div ngbDropdown autoClose="outside">
          <button class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2" id="dropdownForm0"
            ngbDropdownToggle>Expendedora</button>
          <div ngbDropdownMenu aria-labelledby="dropdownForm0">
            <div class="px-4 py-3">
              <div *ngFor="let expendedora of expendedorasParaCheckear">
                <div class="form-check">
                  <input type="checkbox" class="form-check-input" id="{{ 'dropdownCheck' + expendedora.codigo}}"
                    [(ngModel)]="expendedora.seleccionado" name="{{expendedora.codigo}}" (change)="filtrarByR()">
                  <label class="form-check-label text-nowrap" for="{{ 'dropdownCheck' + expendedora.codigo}}">
                    {{ expendedora.nombre}}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="this.tipoBonificacionRecargo == TipoBonificacionRecargo.Producto" class="nav-item">
        <div ngbDropdown autoClose="outside">
          <button class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2" id="dropdownForm1"
            ngbDropdownToggle>Producto</button>
          <div ngbDropdownMenu aria-labelledby="dropdownForm1">
            <div class="px-4 py-3">
              <div *ngFor="let prod of productosParaCheckear">
                <div class="form-check">
                  <input type="checkbox" class="form-check-input" id="{{ 'dropdownCheck' + prod.codigo}}"
                    [(ngModel)]="prod.seleccionado" name="{{prod.codigo}}" (change)="filtrarByR()">
                  <label class="form-check-label text-nowrap" for="{{ 'dropdownCheck' + prod.codigo}}">
                    {{ prod.nombre}} - {{prod.codigo}}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="this.tipoBonificacionRecargo == TipoBonificacionRecargo.Producto" class="nav-item">
        <div ngbDropdown autoClose="outside">
          <button class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2" id="dropdownForm1"
            ngbDropdownToggle>Aeroplanta</button>
          <div ngbDropdownMenu aria-labelledby="dropdownForm1">
            <div class="px-4 py-3">
              <div *ngFor="let aeroplanta of checkAeroplanta">
                <div class="form-check">
                  <input type="checkbox" class="form-check-input" id="{{ 'dropdownCheck' + aeroplanta.codigo}}"
                    [(ngModel)]="aeroplanta.seleccionado" name="{{aeroplanta.codigo}}" (change)="filtrarByR()">
                  <label class="form-check-label text-nowrap" for="{{ 'dropdownCheck' + aeroplanta.codigo}}">
                    {{ aeroplanta.codigo}}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div *ngIf="this.tipoBonificacionRecargo == TipoBonificacionRecargo.Producto" ngbDropdown autoClose="outside">
        <button class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2" id="dropdownForm2" ngbDropdownToggle> Vigencias al
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownForm2">
          <div class="px-4 py-3">
            <div *ngFor="let vigencia of vigencias">
              <div class="form-check">
                <input type="checkbox" class="form-check-input" id="{{ 'dropdownCheck' + vigencia.nombre}}"
                  [(ngModel)]="vigencia.seleccionado" name="{{vigencia.nombre}}" (change)="filtrarByR()">
                <label class="form-check-label text-nowrap" for="{{ 'dropdownCheck' + vigencia.nombre}}">
                  {{ vigencia.nombre}}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="this.tipoBonificacionRecargo == TipoBonificacionRecargo.Producto" class="">
        <div class="input-group">
          <input type="date" name="modelFecha" [(ngModel)]="modelFecha" placeholder="aaaa-mm-dd" name="dt" ngbDatepicker
            #d="ngbDatepicker" ngbTooltip="Seleccione una fecha" (ngModelChange)="filtrarByR()" #searchDate readonly>

          <div class="input-group-append">
            <button id="botonFecha" type="button" class="input-group-btn btn btn-secondary" (click)="d.toggle()">
              <i class="fa fa-calendar" aria-hidden="true"></i></button>
          </div>
        </div>
      </div>


      <button class="navbar-right btn btn-sm p-0 border-0 ps-3" [disabled]="esperandoCarga" (click)="onRefreshClick()">
        <i class="fa fa-refresh fa-2x me-3 text-primary" ngbTooltip="Actualizar lista"></i>
      </button>
    </ul>
  </form>

  <div class="mt-2 float-end">
    <button *ngIf="!hasRole(TipoUsuario.APROBADORBYR)" type="button" [disabled]="esperandoCarga || errorNiveles" (click)="delegarNivelNativoByR()" class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2">Delegar</button>
    <button type="button" [disabled]="esperandoCarga || errorNiveles" (click)="aprobarByR()" class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2">Aprobar</button>
    <button type="button" [disabled]="esperandoCarga || errorNiveles" (click)="rechazarByr()" class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2">Rechazar</button>
  </div>
</nav>

<div class="abs-center" *ngIf="errorNiveles">
  <ngb-alert type="warning"> No se puede interactuar con el menú debido a que el usuario no tiene niveles asignados.
  </ngb-alert>
</div>


<form class="mb-3">
  <div class="col col-md-12">
    <div class="spinner-container" *ngIf="esperandoCarga">
      <ngx-spinner name="spinnerGrafico" bdOpacity=0.3bd
        Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
        <p style="color: white">{{messageByR}}</p>
      </ngx-spinner>
    </div>
    <div id="tablaDetalle" class="tabla-precios" *ngIf="!esperandoCarga">
      <table id="tabla" class="table table-sm table-hover">
        <thead class="text-center letra">
          <tr>
            <th scope="col"> Estado </th>
            <th scope="col" *ngIf="this.tipoBonificacionRecargo == TipoBonificacionRecargo.Producto" > Clientes </th>
            <th scope="col" *ngIf="this.tipoBonificacionRecargo == TipoBonificacionRecargo.Producto" > Productos </th>
            <th scope="col" *ngIf="this.tipoBonificacionRecargo == TipoBonificacionRecargo.Producto" > Aeroplantas </th>
            <th scope="col" *ngIf="this.tipoBonificacionRecargo == TipoBonificacionRecargo.Producto" > Fecha vigencia</th>
            <th scope="col" *ngIf="this.tipoBonificacionRecargo == TipoBonificacionRecargo.Expendedora" > Expendedoras </th>
            <th scope="col"> Valor </th>
            <th scope="col"> Tipo </th>
            <th scope="col"> Solicitado </th>
            <th scope="col"> Seleccionar </th>
          </tr>
        </thead>
        <tbody class="text-center letra">
          <tr *ngFor="let p of bonificacionyRecargoFiltrado; let i = index">

            <td>{{ this.obtenerLeyendaEstado(bonificacionyRecargoFiltrado[i].estado.estadoPrecio) }}</td>

            <td *ngIf="this.tipoBonificacionRecargo == TipoBonificacionRecargo.Producto">
              <span *ngFor="let c of bonificacionyRecargoFiltrado[i].clientes">
                *{{ c.cta_SGC }} &nbsp; 
              </span>
            </td>

            <td *ngIf="this.tipoBonificacionRecargo == TipoBonificacionRecargo.Producto">
              <span *ngFor="let p of bonificacionyRecargoFiltrado[i].productos">
                *{{ p.codigoProducto }} &nbsp; 
              </span>
            </td>

            <td *ngIf="this.tipoBonificacionRecargo == TipoBonificacionRecargo.Producto"> 
              <span *ngFor="let cod of bonificacionyRecargoFiltrado[i].codigosAeroplantas">
                *{{ cod }} &nbsp; 
              </span>
            </td>

            <td 
              *ngIf="this.tipoBonificacionRecargo == TipoBonificacionRecargo.Producto && bonificacionyRecargoFiltrado[i].fechaDesde != null && bonificacionyRecargoFiltrado[i].fechaHasta != null">
              {{bonificacionyRecargoFiltrado[i].fechaDesde | date: "dd'/'MM'/'yy"}} -
              {{bonificacionyRecargoFiltrado[i].fechaHasta | date: "dd'/'MM'/'yy"}}</td>

            <td 
              *ngIf="this.tipoBonificacionRecargo == TipoBonificacionRecargo.Producto && bonificacionyRecargoFiltrado[i].fechaHasta == null && bonificacionyRecargoFiltrado[i].fechaDesde">
              -- </td>

            <td *ngIf="this.tipoBonificacionRecargo == TipoBonificacionRecargo.Expendedora">             
              <span *ngFor="let e of bonificacionyRecargoFiltrado[i].expendedoras">
                *{{ e.nombreExpendedora }} &nbsp; 
              </span>
            </td>

            <td> {{bonificacionyRecargoFiltrado[i].valor != null?bonificacionyRecargoFiltrado[i].valor: "--"}}</td>

            <td> {{obtenerLeyendasBonificacion(bonificacionyRecargoFiltrado[i].tipo)}}</td>

            <td> {{bonificacionyRecargoFiltrado[i].solicitadoPor != null?bonificacionyRecargoFiltrado[i].solicitadoPor:
              "--"}}</td>

              <td>
                <input type="checkbox"
                  [disabled]="desactivarCheckboxAprobarByrSurtidor(bonificacionyRecargoFiltrado[i])"
                  [checked]="bonificacionyRecargoFiltrado[i].seleccionadoUi"
                  (click)="onTildaByR(bonificacionyRecargoFiltrado[i])" ç
                  id="{{bonificacionyRecargoFiltrado[i].id}}" />
              </td>

          </tr>
        </tbody>
      </table>
    </div>
  </div>
</form>
