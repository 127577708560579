import { TipoActivoPrecio } from "../../enums/tipoActivoPrecio";
import { ClienteEncabezado } from "../cliente/ClienteEncabezado";
import { Cliente } from "./Cliente";
import { EstadoPrecio } from "./EstadoPrecio";
import { Expendedora } from "./Expendedora";
import { ProductoDetalle } from "./ProductoDetalle";

export class BonificacionRecargo{
    id: string;
    nombreCliente: string;
    ctA_SGC: string;
    codigoProducto: string;
    nombreProducto: string;
    codigoAeroplanta: string;
    fechaDesde: string;
    fechaHasta: string;
    valor: number;
    tipo: number;
    motivo: string;
    solicitadoPor: string;
    aprobadoPor: string;
    seleccionadoUi: boolean;
    fechaPublicacion: string;
    estado: EstadoPrecio;
    activo: TipoActivoPrecio;

    tipoBonificacionRecargo: number; // 0-> Producto, 1-> Expendedora
    expendedoras: Expendedora[];
    clientes: Cliente[] ;
    codigosAeroplantas: string[];
    productos: ProductoDetalle[];

}
