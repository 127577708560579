import { Component, NgModule, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule, UntypedFormBuilder, PatternValidator } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { EnvioDeMailGenerico } from 'src/app/shared/models/notificaciones/EnvioDeMailGenerico';
import { MessageService } from 'src/app/shell/message.service';
import { ClienteService } from '../cliente.service';

@Component({
  selector: 'app-modal-envio-mail',
  templateUrl: './modal-envio-mail.component.html',
  styleUrls: ['./modal-envio-mail.component.css']
})
export class ModalEnvioMailComponent implements OnInit {

  envioMail: UntypedFormGroup;
  arrayMails: string[] = [];
  enviandoMail: boolean = false;


  constructor(public activeModal: NgbModal, private fb: UntypedFormBuilder, private clienteService: ClienteService, private messageService: MessageService) {
    this.envioMail = fb.group({
      mail: new UntypedFormControl(null, [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')])
    })
  }


  ngOnInit() {
  }

  enviarMail() {
    this.enviandoMail = true;
    if(this.arrayMails.length > 0){
    let mail = new EnvioDeMailGenerico();
    mail.Emails = [];
    mail.Emails = this.arrayMails;
    mail.asuntoCorreo = 'Información para alta de nuevo';
    mail.cuerpoHtml = "<p>Se debera enviar la informacion detalla debajo al E-mail de YPF mail@mail.com.ar:</p><br><ul><li>Constancia de inscripción de AFIP.</li><li>Constancia de inscipción para la percepción de IIBB para las provincias que se encuentre inscripto.</li><li>Constancia de inscripción percepcion de IVA diferencia indicando alicuota y fecha de caducidad.</li></ul>"
    this.clienteService.enviarMail(mail)
      .subscribe(result => {
        let p = result;
        this.messageService.showSuccessMessage('El mail fue enviado con exito.');
        this.enviandoMail = false;
        this.activeModal.dismissAll();
      }, (err: ErrorModel) => {
        this.messageService.showErrorMessage('Hubo un error al enviar el mail, verifique las casillas ingresadas.');
        this.enviandoMail = false;
      });
    }else{
      this.messageService.showErrorMessage('Debe agregar al menos un mail para enviar.');
      this.enviandoMail = false;
    }
  }

  agregarMail() {
    if (this.mail.value) {
      this.arrayMails.push(this.mail.value);
      this.mail.setValue(null);
    }
  }

  desasignarMail(mail: string){
    const index: number = this.arrayMails.indexOf(mail);
    if (index !== -1) {
      this.arrayMails.splice(index, 1);
    }
  }

  get mail() {
    return this.envioMail.get('mail');
  }

}
