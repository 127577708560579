import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-motivo-rechazo',
  templateUrl: './modal-motivo-rechazo.component.html',
  styleUrls: ['./modal-motivo-rechazo.component.css']
})
export class ModalMotivoRechazoComponent implements OnInit {

  form: UntypedFormGroup;
  
  constructor(public modal: NgbActiveModal, private fb: UntypedFormBuilder) { }

  ngOnInit() {
    this.form = this.fb.group({
      motivoFormControl: new UntypedFormControl(null, [Validators.required])
    });
  }

  get motivoFormControl() {
    return this.form.get('motivoFormControl');
  }

  rechazar(){
    this.modal.close(this.motivoFormControl.value);
  }
}
