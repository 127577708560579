<div class="col col-md-12">
  <div class="spinner-container" *ngIf="cargandoInfoImpuesto">
    <ngx-spinner name="spinnerGrafico" bdOpacity=0.3bd
      Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
      <p style="color: white">C a r g a n d o . . . </p>
    </ngx-spinner>
  </div>
</div>
<div class="card add-margin" *ngIf="!this.cargandoInfoImpuesto">
  <div class="card-header">
    <h4 class="card-title">
      Impuestos
    </h4>
  </div>
  <div>
    <ngb-alert class="mt-2" type="warning" [dismissible]="false" *ngIf="this.impuestos.length == 0">
      No existen impuestos cargados para el producto seleccionado.
    </ngb-alert>
    <form [formGroup]="impuestosForm" *ngIf="this.impuestos.length > 0">
      <div id="tablaImpuestos">
        <table id="tablaMovimientosDesdeHasta" class="table table-xl table-striped table-hover menu-izq">
          <thead class="text-center letra">
            <tr>
              <th scope="col" style="width: 7%"> Código </th>
              <th scope="col"> Leyenda </th>
              <th scope="col" style="width: 11%;"> Tipo de valor</th>
              <th scope="col"> Valor </th>
              <th scope="col"> Base de cálculo </th>
              <th scope="col"> Importe mínimo </th>
              <th scope="col" > Fecha inicio </th>
              <th scope="col"> Fecha fin </th>
              <th scope="col" style="width: 11%;"> Actualización</th>
              <th scope="col"> Zona </th>
              <th scope="col" style="width: 7%"> Activo </th>
            </tr>
          </thead>

          <tbody class="text-center">
            <tr formArrayName="impuestosArray" style="cursor: pointer; font-size: 13px;"
              *ngFor="let impuesto of impuestosArray; let i=index ">
              <td (click)="mostrarDetalleImpuesto(i,contentEditable)" style="width: 7%"> {{this.obtenerCodigoImpuesto(impuestosArray[i].codigo)}} </td>
              <td (click)="mostrarDetalleImpuesto(i,contentEditable)"> {{impuestosArray[i].leyenda}}</td>
              <td (click)="mostrarDetalleImpuesto(i,contentEditable)" style="width: 11%;"> {{this.obtenerTipoValor(impuestosArray[i].tipoValor)}}</td>
              <td (click)="mostrarDetalleImpuesto(i,contentEditable)"> {{impuestosArray[i].valor | number: '0.2'}}</td>
              <td (click)="mostrarDetalleImpuesto(i,contentEditable)"> {{this.obtenerBaseCalculo(impuestosArray[i].baseCalculo)}} </td>
              <td (click)="mostrarDetalleImpuesto(i,contentEditable)"> {{impuestosArray[i].minimo}} </td>
              <td (click)="mostrarDetalleImpuesto(i,contentEditable)"  *ngIf="impuestosArray[i].codigo == 5 || impuestosArray[i].codigo == 4">
                {{impuestosArray[i].fechaInicio | date: 'dd/MM/yyyy'}}
              </td>
              <td (click)="mostrarDetalleImpuesto(i,contentEditable)" *ngIf="!(impuestosArray[i].codigo == 5 || impuestosArray[i].codigo == 4)"> ----- </td>
              <td (click)="mostrarDetalleImpuesto(i,contentEditable)" *ngIf="impuestosArray[i].codigo == 5 || impuestosArray[i].codigo == 4">
                {{impuestosArray[i].fechaFin | date: 'dd/MM/yyyy'}} </td>
              <td (click)="mostrarDetalleImpuesto(i,contentEditable)" *ngIf="!(impuestosArray[i].codigo == 5 || impuestosArray[i].codigo == 4)"> ----- </td>
              <td (click)="mostrarDetalleImpuesto(i,contentEditable)" style="width: 11%;"> {{impuestosArray[i].fechaActualizacion | date: 'dd/MM/yyyy'}} </td>
              <td (click)="mostrarDetalleImpuesto(i,contentEditable)" *ngIf="impuestosArray[i].codigo == 5 || impuestosArray[i].codigo == 4">
                {{this.obtenerZona(impuestosArray[i].zona)}} </td>
              <td (click)="mostrarDetalleImpuesto(i,contentEditable)" *ngIf="!(impuestosArray[i].codigo == 5 || impuestosArray[i].codigo == 4)"> --- </td>
              <td style="width: 7%">
                <div class="form-check form-switch">
                  <input *ngIf="authService.hasRole(TipoUsuario.IMPUESTOS)" type="checkbox" [formControlName]="i" role="switch" class="form-check-input"
                    (click)="actualizarImpuesto(impuestosArray[i])" id="{{impuestosArray[i].id}}">
                  <label *ngIf="authService.hasRole(TipoUsuario.IMPUESTOS)" class="form-check-label" for="{{impuestosArray[i].id}}"></label>
                  <span *ngIf="!authService.hasRole(TipoUsuario.IMPUESTOS)">{{impuestosArray[i].activa ? "Si" : "No"}}</span>
                </div>
              </td>
            </tr>
          </tbody>

        </table>
      </div>
    </form>
  </div>
  <div class="card-footer">
    <div class="sm-8">
      <button *ngIf="authService.hasRole(TipoUsuario.IMPUESTOS)" type="button" class="btn btn-primary my-2 my-lg-0 mx-lg-2-"
        (click)="mostrarNuevoImpuesto(contentNuevo)"> Agregar</button>
    </div>
  </div>
</div>

<ng-template #contentEditable let-modal>
  <app-nuevo-impuesto [productoSeleccionado]="productoSeleccionado" [impuestoSeleccionado]="impuestoSeleccionado"
    [editable]="true" (nuevoImpuesto)="recargar($event)"></app-nuevo-impuesto>
</ng-template>

<ng-template #contentNuevo let-modal>
  <app-nuevo-impuesto [productoSeleccionado]="productoSeleccionado" [impuestoSeleccionado]="impuestoSeleccionado"
    [editable]="false" (nuevoImpuesto)="recargar($event)"></app-nuevo-impuesto>
</ng-template>
