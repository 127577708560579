<div class="col col-md-12" *ngIf="this.cargandoDatos">
    <div class="spinner-container">
        <ngx-spinner name="spinnerGrafico" [ngStyle]="{'height': '400px', 'z-index':'0'}" bdOpacity=0.3bd
            Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
            <p style="color: white">C a r g a n d o . . . </p>
        </ngx-spinner>
    </div>
</div>

<div *ngIf="!this.cargandoDatos" class="border">
    <div class="modal-header bg-light">
        <div class="row col-12">
            <div class="col-11">
                <h5 class="p-2 modal-title">Cliente: {{ this.aerolineaDTOSeleccionada.razonSocial }}   |   Destinos para la aeroplanta: {{ this.getNombreAeroplanta() | uppercase }} </h5>
            </div>
            <div class="col-1">   
                <button style="margin-top: 0.2em;" type="button" class="btn btn-primary" (click)="agregarDestinos()" [disabled]="this.cuentas.length==0">
                    Agregar
                </button>                    
            </div>
        </div>                                                 
    </div>

    <div class="p-4 modal-body">        
        <ngb-alert [dismissible]="false" *ngIf="!this.cargandoDatos && this.listaDestinosMostrar.length==0">
            No existen destinos para la aeroplanta seleccionada
        </ngb-alert>

        <table class="table table-striped" *ngIf="!this.cargandoDatos && this.listaDestinosMostrar.length > 0">
            <thead class="text-center">                
                <th>Codigo</th>        
                <th>Descripcion</th>        
                <th>Cuenta</th>        
                <th>Acción</th>                    
            </thead>        
            <tbody>
                <tr *ngFor="let d of listaDestinosMostrar; let i=index"
                    class="letra">          
                    <td style="width: 20%;" class="text-center">{{ d.codigoAeropuerto }}</td>      
                    <td style="width: 60%;" class="text-center">
                        <ng-container *ngIf="!cargandoAeropuertos">
                            {{ obtenerNombreAeropuerto(d.codigoAeropuerto) }}
                        </ng-container>                        
                        <loading-text-spinner *ngIf="cargandoAeropuertos" message="Obteniendo aeropuerto..."
                            color="primary"></loading-text-spinner>
                    </td>
                    <td style="width: 20%;" class="text-center">
                        <select *ngIf="!cargandoCuentas" class="form-select" 
                            id="destinosCuenta"                            
                            [(ngModel)]="d.cuenta"
                            (blur)="actualizarCuenta(i, 'cuenta', $event)">
                            <option *ngFor="let item of cuentas" [value]="item.ctA_SGC">
                                {{item.ctA_SGC}}
                            </option>
                        </select>
                        <loading-text-spinner *ngIf="cargandoCuentas" message="Obteniendo cuentas..."
                            color="primary"></loading-text-spinner>                        
                    </td> 
                    <td class="text-center">
                        <button                                
                            class="btn btn-sm btn-danger" (click)="eliminarDestino(d)">
                            <span class="fa fa-trash" ngbTooltip="Eliminar Destino"></span>
                        </button>
                    </td>                       
                </tr>
            </tbody>                                
        </table>
    </div>
    
    <div class="card-footer p-2 d-flex justify-content-center">
        <span class="border">
            <button type="button" class="btn btn-primary" (click)="guardarCambios()"
                [disabled]="guardandoDatos">
                <span *ngIf="!guardandoDatos">Guardar cambios</span>
                <div *ngIf="guardandoDatos" class="d-flex align-items-center">
                    <div class="spinner-border text-light spinner-border-sm" role="status"></div>
                    <span>Guardando...</span>
                </div>
            </button>
        </span>
    </div>
</div>