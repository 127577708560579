import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';
import { Shell } from '../shell/shell.service';
import { TipoUsuario } from '../shared/enums/tipoUsuario';
import { AbmIndexComponent } from './abm-index/abm-index.component';
import { ProductosComponent } from './producto/productos/productos.component';
import { NotificacionesIndexComponent } from './notificacion/notificaciones-index/notificaciones-index.component';
import { RubrosComponent } from './rubro/rubros/rubros.component';
import { ImpuestosMasivosComponent } from './impuesto/impuestos-masivos/impuestos-masivos.component';
import { PreciosComponent } from './precio/precios/precios.component'
import { GruposListaComponent } from './notificacion/grupos/grupos-lista/grupos-lista.component';
import { NotificacionesClienteComponent } from './notificacion/notificaciones-clientes/notificaciones-cliente.component';
import { NotificacionesSistemaComponent } from './notificacion/notificaciones-sistema/notificaciones-sistema.component';
import { AuthService } from '../core/authentication/auth.service';
import { authGuard } from '../core/authentication/auth.guard';


const routes: Routes = [
  Shell.childRoutes([
    { path: 'abm', redirectTo: 'abm/productos', pathMatch: 'full' },
    {
      path: 'abm', component: AbmIndexComponent, canActivate: [authGuard],
       data: { roles: [TipoUsuario.COMERCIAL, TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.OPERADOR, TipoUsuario.OPERADOR_3,TipoUsuario.OPERADORDIRECTO, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.APROBADORBYR, TipoUsuario.PRODUCTOS, TipoUsuario.IMPUESTOS, TipoUsuario.FACTURACION, TipoUsuario.REFACTURACION, TipoUsuario.PRECIOS, TipoUsuario.APROBADORPRECIOS, TipoUsuario.CONSPRECIOS, TipoUsuario.SEGURIDADTABLAS, TipoUsuario.SOPORTE] }, children: [
        {
          path: 'productos', component: ProductosComponent,
           canActivate: [authGuard], data: { roles: [TipoUsuario.COMERCIAL, TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.OPERADOR, TipoUsuario.OPERADOR_3,TipoUsuario.OPERADORDIRECTO, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.APROBADORBYR, TipoUsuario.PRODUCTOS, TipoUsuario.IMPUESTOS, TipoUsuario.FACTURACION, TipoUsuario.REFACTURACION, TipoUsuario.CONSPRECIOS, TipoUsuario.SOPORTE]},
        },
        {
          path: 'precios', component: PreciosComponent,
           canActivate: [authGuard], data: { roles: [TipoUsuario.COMERCIAL, TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.SOPORTE] },
        },        
        {
          path: 'rubros', component: RubrosComponent,
           canActivate: [authGuard], data: { roles: [TipoUsuario.RESPRODUCTO, TipoUsuario.PRODUCTOS, TipoUsuario.SOPORTE, TipoUsuario.OPERADORDIRECTO, TipoUsuario.APROBADORBYR] }
        },
        {
          path: 'notificaciones',
          component: NotificacionesIndexComponent,
           canActivate: [authGuard],
            data: { roles: [TipoUsuario.SEGURIDADTABLAS,TipoUsuario.OPERADORDIRECTO, TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.APROBADORBYR, TipoUsuario.IMPUESTOS, TipoUsuario.PRECIOS, TipoUsuario.APROBADORPRECIOS, TipoUsuario.FACTURACION, TipoUsuario.REFACTURACION, TipoUsuario.SOPORTE]},
            children: [
              {
                path: 'Cliente',
                component: NotificacionesClienteComponent,
                canActivate: [authGuard],
                //data: { roles: [TipoUsuario.COMERCIAL, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.SUPERVISOR] }
              },
              {
                path: 'Sistema',
                component: NotificacionesSistemaComponent,
                canActivate: [authGuard],
                //data: { roles: [TipoUsuario.COMERCIAL, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.SUPERVISOR] }
              },
              {
                path: 'abm-grupos',
                component: GruposListaComponent,
              }//data: { roles: [TipoUsuario.COMERCIAL, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.SUPERVISOR] }
            ]
          }
        ,
        {
          path: 'impuestos', component: ImpuestosMasivosComponent,
           canActivate: [authGuard], data: { roles: [TipoUsuario.IMPUESTOS] }
        },
         ]},
  ])
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})

export class AbmRoutingModule {
  constructor( 
    private authService: AuthService,
    private router: Router ) 
    {
      this.router.events.subscribe((event) => {
        if(event['url'] && event['url'] == '/abm' && this.authService.hasRole(TipoUsuario.SEGURIDADTABLAS, TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.APROBADORBYR, TipoUsuario.IMPUESTOS, TipoUsuario.PRECIOS, TipoUsuario.APROBADORPRECIOS, TipoUsuario.FACTURACION, TipoUsuario.REFACTURACION)) {
          this.router.navigate(['/abm/notificaciones']);
        }        
      });
    }
 }
