export class Cliente {
  condicionIva : number;
  nroDocumento: string;
  tipoDocumento: number;
  domicilio: string;
  esNuevo: boolean;
  esTrader: boolean;
  // id: string;
  ctc: string;
  ctA_SGC : string;
  numeroCuenta: string;
  permiteRancho: boolean;
  razonSocial: string;
  responsable: string;
  tipoPago: number;
  ubicacion: string;
  nombreLocalidad: string;
  codigoPostalLocalidad: string;
  numeroIIBB: number;
  cuit: string;
  numeroDocumento: string;
  condicionPrecio: number;
  direccion: string;
  nombreCiudad: string;
  codigoPostalCiudad: string;
  nombreProvincia: string;
  direccionDirFiscal: string;
  nombreCiudadDirFiscal: string;
  nombreProvinciaDirFiscal: string;
  codigoPostalCiudadFiscal: string;
  condicionIvaCodigoAfip: string;
  condicionIvaDiscrimina: boolean;
  sgc: string;
  plazoPago: number;
}







