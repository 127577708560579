import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { AuthService } from '../authentication/auth.service';
import { Observable } from 'rxjs';
import { Usuario } from 'src/app/shared/models/ranchos/Usuario';
import { UsuarioService } from 'src/app/account/usuario.service';

@Injectable()

export class TokenInterceptor implements HttpInterceptor {

    constructor(
        public auth: AuthService,
        private usuarioService: UsuarioService
        ) { } intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!request.url.includes('blob.core.windows')) {
            request = request.clone({
                setHeaders: {
                    Authorization: `${this.auth.authorizationHeaderValue}`,
                    //username: this.auth.usernameHeaderValue
                    username: this.armarUsuario()
                }
            });
        }
        return next.handle(request);
    }

    armarUsuario() {
        let r = new Usuario();
        r.id = this.usuarioService.getIdUsuario();
        r.nombresCompletos = this.usuarioService.getNombresCompletosUsuario();
        r.dni = this.usuarioService.getDniUsuario();
        r.email = this.usuarioService.getEmailUsuario();
    
        return JSON.stringify(r);
    }
}