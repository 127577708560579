import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormControl } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { TipoNeto } from 'src/app/shared/enums/tipoNeto';
import { RubroService } from '../rubro.service';
import { Rubro } from 'src/app/shared/models/abm/Rubro';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';
@Component({
  selector: 'app-rubros-lista',
  templateUrl: './rubros-lista.component.html',
  styleUrls: ['./rubros-lista.component.css']
})
export class RubrosListaComponent implements OnInit {

  //se utilizan para el check de activos/inactivos
  activos = { nombre: "Activo", seleccionado: false };
  inactivos = { nombre: "Inactivo", seleccionado: false };
  estados = [this.activos, this.inactivos];
  TipoUsuario = TipoUsuario;

  rubros: Rubro[];
  rubrosFiltrados: Rubro[];
  tipoNeto = TipoNeto;
  rubroSeleccionado: Rubro;
  netoCombustible = {nombre: "NetoCombustible", seleccionado: false}
  tiposRubro = [this.netoCombustible , this.netoCombustible];
  busquedaFormControl = new UntypedFormControl();
  esperandoCarga: boolean= true;

  constructor(private rubroService: RubroService, private modalService: NgbModal,
     private spinner: NgxSpinnerService, public authService: AuthService) { }

  ngOnInit() {
    this.spinner.show('spinnerGrafico');
    this.getRubros();
    this.cargarCheckTipos();
  }

  ngOnChanges(): void {
    this.spinner.show('spinnerGrafico');
    this.getRubros();
    this.cargarCheckTipos();
  }

  keysTipos(): Array<string> {
    var keys = Object.keys(this.tipoNeto);
    keys = keys.slice(keys.length / 2);
    return keys;
  }

  cargarCheckTipos() {
    this.tiposRubro = [];
    let i = 0;
    for (let tipo of this.keysTipos()) {
      let t: string = tipo;
      this.tiposRubro.push({"nombre": t, "seleccionado": false });
    }
  }

  filtrarRubros() {
    this.rubrosFiltrados = this.rubros.filter(a => {
      let agregar = true;
      if (this.busqueda.value) {
        agregar = a.nombre.toLowerCase().indexOf(this.busqueda.value.toLowerCase()) > -1;
        agregar = agregar || a.codigoRubro.toLowerCase().indexOf(this.busqueda.value.toLowerCase()) > -1;
      }
      if (this.estados.some(e => e.seleccionado)) {
        agregar = agregar &&
          (
            (this.activos.seleccionado === true && a.activo === this.rubroSeleccionado.activo) ||
            (this.inactivos.seleccionado === true && a.activo !== this.rubroSeleccionado.activo)
          );
      }
      if (this.tiposRubro.some(t => t.seleccionado)) {
        agregar = agregar &&
          (
            (this.tiposRubro[0].seleccionado === true && a.tipoNeto === 0)
          ||     (this.tiposRubro[1].seleccionado === true && a.tipoNeto === 1)
          ||     (this.tiposRubro[2].seleccionado === true && a.tipoNeto === 2)
          ||     (this.tiposRubro[3].seleccionado === true && a.tipoNeto === 3)
          ||     (this.tiposRubro[4].seleccionado === true && a.tipoNeto === 4)
          ||     (this.tiposRubro[5].seleccionado === true && a.tipoNeto === 5)
            );
      }
      return agregar;
    });
  }

  getRubros() {
    this.rubroService.obtenerRubros()
      .subscribe(result => {
        this.rubros = result;
        this.rubrosFiltrados = this.rubros;
        this.rubroSeleccionado = this.rubros[0]; //incializo con la primera fila selecccionada
        this.spinner.hide('spinnerGrafico');
        this.esperandoCarga=false;
      }, () => {

      });
  }
  mostrarDetalle(i: number) {
    this.rubroSeleccionado = this.rubrosFiltrados[i];
  }
  mostrarNuevoRubro(content) {
    this.modalService.open(content, { centered: true, size: 'lg', backdrop: 'static' });
  }
  get busqueda() {
    return this.busquedaFormControl;
  }

}
