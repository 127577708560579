import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';
import { Shell } from '../shell/shell.service';
import { authGuard } from '../core/authentication/auth.guard';
import { RanchoIndexComponent } from './rancho-index/rancho-index.component';
import { TipoUsuario } from '../shared/enums/tipoUsuario';
import { RanchosImportComponent } from './ranchos-import/ranchos-import.component';
import { RanchosSolicitudesComponent } from './ranchos-solicitudes/ranchos-solicitudes.component';
import { AuthService } from '../core/authentication/auth.service';
import { TipoSolapaRancho } from '../shared/enums/TipoSolapaRancho';

const routes: Routes = [
  Shell.childRoutes([
    { path: 'ranchos', redirectTo: 'ranchos/importRanchos', pathMatch: 'full' },
    { path: 'ranchos', component: RanchoIndexComponent, canActivate: [authGuard], data : { roles: [TipoUsuario.COMERCIAL, TipoUsuario.OPERADOR, TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.APROBADORBYR, TipoUsuario.DESPACHANTE, TipoUsuario.FACTURACION, TipoUsuario.REFACTURACION, TipoUsuario.CONSAEROVALES, TipoUsuario.CONSPRECIOS, TipoUsuario.SOPORTE] },
      children:[
        { path: 'importRanchos', component: RanchosImportComponent, canActivate: [authGuard], data : { roles: [TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.APROBADORBYR] }},
        { path: 'SolicitudRancho', component: RanchosSolicitudesComponent, canActivate: [authGuard], data : { roles: [TipoUsuario.COMERCIAL, TipoUsuario.DESPACHANTE] }},        
        { path: 'ListadoRanchoSolicitados', component: RanchosSolicitudesComponent, canActivate: [authGuard], data : { roles: [TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.APROBADORBYR, TipoUsuario.SOPORTE] }},        
        { path: 'ListadoRanchoConsumidos', component: RanchosSolicitudesComponent, canActivate: [authGuard], data : { roles: [TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.APROBADORBYR, TipoUsuario.DESPACHANTE, TipoUsuario.OPERADOR, TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.FACTURACION, TipoUsuario.REFACTURACION, TipoUsuario.CONSAEROVALES, TipoUsuario.CONSPRECIOS, TipoUsuario.SOPORTE] }},    
      ]
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class RanchoRoutingModule { 
  constructor(private authService: AuthService, private router: Router) {
    this.router.events.subscribe((event) => {
      if (event['url'] && event['url'] == '/ranchos') {
        this.redireccionar();
      }
    });
  }

  redireccionar() {
    if (this.authService.hasRole(TipoUsuario.COMERCIAL, TipoUsuario.DESPACHANTE)) {
      this.router.navigate(['/ranchos/SolicitudRancho'], {queryParams : {solapaRanchosDestino:TipoSolapaRancho.SolicitudRancho}});
    } else if (this.authService.hasRole(TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.APROBADORBYR)) {
      this.router.navigate(['/ranchos/importRanchos']);
    } else {
      this.router.navigate(['/ranchos/ListadoRanchoConsumidos'], {queryParams : {solapaRanchosDestino:TipoSolapaRancho.ListadoRanchoConsumidos}});
    }
  }
}

  

