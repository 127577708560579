import { inject } from '@angular/core';
import { Router, CanActivateFn } from '@angular/router';
import { AuthService } from './auth.service';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';

export const authGuard: CanActivateFn = (route, state) => 
{
  const router = inject(Router);
  const authService = inject(AuthService);  
  
  if (authService.isAuthenticated) {
    let rolesString = authService.userRoles;
    if (route.data.roles && rolesString?.every(r => route.data.roles.indexOf(TipoUsuario[r]) === -1)) {
      router.navigate(['/']);
      console.error("Acceso denegado");
      return false;
    }

    // authorized so return true
    return true;
  }

  // not logged in so redirect to login page with the return url
  router.navigate(['/login'], { queryParams: { returnUrl: state.url } }); //, 
  return false;
}
