<form [formGroup]="form" (ngSubmit)="onSubmit()">

  <div class="modal-header">
    <h4 class="modal-title">Creando nueva isla.</h4>
  </div>
  
  <div class="modal-body">
    
      <div class="row">
        <div class="mb-3 row col-md-12">
          <label for="nombreFormControl" class="col-sm-5 col-form-label">Nombre de la isla:</label>
          <div class="col-sm-7">
            <input class="form-control" formControlName="nombreFormControl" maxlength="10">
            <div *ngIf="nombreFormControl.invalid && nombreFormControl.errors.required && (nombreFormControl.dirty || nombreFormControl.touched)"
              class="alert alert-danger">Requerido</div>              
          </div>
        </div>
      </div>
    
    <div class="modal-footer">
      <span class="border">
        <button class="btn btn-primary" [disabled]="!form.valid || guardando" type="submit">
          {{ guardando ? "Guardando..." : "Guardar cambios" }}
        </button>
      </span>
      <span class="border">
        <button type="button" class="btn btn-secondary" [disabled]="guardando" (click)="activeModal.dismiss('Close clicked')">Cancelar</button>
      </span>
    </div>
  </div>
</form>
  