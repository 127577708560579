import { Component, OnInit } from '@angular/core';
import { Tanque } from 'src/app/shared/models/stock/Tanque';
import { NgbDateStruct, NgbTimeStruct, NgbCalendar, NgbDatepickerConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Guid } from 'guid-typescript';
import { UsuarioService } from 'src/app/account/usuario.service'
import { UntypedFormControl, UntypedFormGroup, UntypedFormBuilder} from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { Medicion } from 'src/app/shared/models/stock/Medicion';
import { NuevaMedicionComponent } from './nueva-medicion/nueva-medicion.component';
import { TanqueService } from '../../tanques.service';
import { MedicionesService } from '../../mediciones.service';
import { MessageService } from 'src/app/shell/message.service';
import { fadeAnimationTrigger } from 'src/app/shared/animations';
import { Abastecedora } from 'src/app/shared/models/stock/Abastecedora';
import { AbastecedoraService } from '../../abastecedoras.service';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-mediciones',
  templateUrl: './mediciones.component.html',
  styleUrls: ['./mediciones.component.css'],
  animations: [fadeAnimationTrigger]
})
export class MedicionesComponent implements OnInit {

  tanques: Tanque[] = [];
  aeroplantaID: Guid;
  obteniendoMediciones: boolean = false;
  obteniendoTanques: boolean = false;
  modelDesde: NgbDateStruct;
  modelHasta: NgbDateStruct;
  time: NgbTimeStruct;
  fechaDesde: Date;
  fechaHasta: Date;
  mediciones: Medicion[] = [];
  fechaFormControlDesdeHasta = new UntypedFormControl();
  errorMediciones = false;
  change$: Subscription;  
  showForm: boolean = false;
  tanquesForm: UntypedFormGroup;
  obteniendoAbastecedoras: boolean = false;
  abastecedoras: Abastecedora[];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private medicionesService: MedicionesService,
    private tanquesService: TanqueService,
    private calendar: NgbCalendar,
    private usuarioService: UsuarioService,
    private datepickerConfig: NgbDatepickerConfig ,
    private spinner: NgxSpinnerService,
    private messageService: MessageService,
    private modalService: NgbModal,
    private abastecedoraService: AbastecedoraService,
    sharedService: SharedService) {
      this.change$ = sharedService.aeroplantaSeleccionada.subscribe(_=>{
        this.recargarPagina()
      })
    }

    // hooks

  ngOnInit() {
    this.getTodosLosTanques();
    this.getTodosLasAbastecedoras();

    this.tanquesForm = this.formBuilder.group({
      tanqueFormControl: [null]
    });

    this.mediciones = [];
    this.errorMediciones = false;
    this.aeroplantaID = Guid.parse(this.usuarioService.getAeroplantaActual().id);
    this.modelDesde = this.calendar.getToday();
    this.fechaDesde = new Date(this.modelDesde.year, this.modelDesde.month - 1, this.modelDesde.day);
    this.modelHasta = this.calendar.getToday();
    this.fechaHasta = new Date(this.modelHasta.year, this.modelHasta.month - 1, this.modelHasta.day);
    this.reinicioFechaInicioFechaFin();

    this.datepickerConfig.minDate = { day: 1, month: 1, year: 2019 };
    this.datepickerConfig.maxDate = { day: this.modelHasta.day, month: this.modelHasta.month, year: this.modelHasta.year };    
  }

  ngOnDestroy() {
    this.change$.unsubscribe();
    this.mediciones = [];
  }

  // form

  volver(){
    this.showForm = false;
    this.recargarPagina();
  }

  recargarPagina() {    
    this.errorMediciones = false;
    this.limpiarCampos();
    this.getTodosLosTanques();
    this.getTodosLasAbastecedoras();
  }

  limpiarCampos()
  {
    this.spinner.hide("spinnerLista");
    this.spinner.hide("spinnerDetalle");
    this.modelDesde = this.calendar.getToday();
    this.modelHasta = this.calendar.getToday();
  }

  formatDate(year, month, day, hour, minute): string {
    return year + '/' + month.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + '/' + day.toLocaleString(undefined, { minimumIntegerDigits: 2 })
      + 'T' + hour.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + minute.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':00';
  }


  // llamadas al service

  getTodosLosTanques() {
    this.obteniendoTanques = true;
    this.tanques = [];
    this.tanquesService.getTanquesPorAeroplanta(this.usuarioService.getAeroplantaActual().codigoAeroplanta)
      .subscribe(result => {
        this.tanques = result;
        this.obteniendoTanques = false;
        if (this.tanques.length > 0)
        {
          this.buscarDesdeHasta();
        }
        else
        {
          this.mediciones = [];
          this.tanqueFormControl.setValue(null);
          this.messageService.showErrorMessage("La aeroplanta "+this.usuarioService.getAeroplantaActual().codigoAeroplanta+" no posee tanques registrados");
          this.obteniendoMediciones = false;
          this.spinner.hide('spinnerLista');
        }

      });
  }

  getTodosLasAbastecedoras(){
    this.obteniendoAbastecedoras = true;
    this.abastecedoraService.getAbastecedoras(this.usuarioService.getAeroplantaActual().codigoAeroplanta)
      .subscribe(result => {
        this.abastecedoras = result;
        this.obteniendoAbastecedoras = false;
      })
  }

  buscarDesdeHasta() {
    this.obteniendoMediciones = true;
    let contenedor: string;
    this.tanqueFormControl.value? contenedor = this.tanqueFormControl.value.codigo : contenedor = null;

    this.mediciones = [];
    this.reinicioFechaInicioFechaFin();
    return this.getTodasLasMediciones(this.usuarioService.getAeroplantaActual().codigoAeroplanta,
     contenedor, this.fechaDesde, this.fechaHasta);
  }

  getTodasLasMediciones(aeroplantaCod: string, tanqueSeleccionado: string, fechaDesde: Date, fechaHasta: Date) {
    this.spinner.show('spinnerLista');
    let desde: string = this.formatDate(fechaDesde.getUTCFullYear(), fechaDesde.getMonth() + 1, fechaDesde.getDate(), 0, 0);
    let hasta: string = this.formatDate(fechaHasta.getUTCFullYear(), fechaHasta.getMonth() + 1, fechaHasta.getDate(), 23, 59);
    this.medicionesService.getTodasLasMedicionesV2(aeroplantaCod, tanqueSeleccionado, desde, hasta)
      .subscribe(result => {
        this.mediciones = result.sort((a,b) => new Date(b.fechaHora).getTime() - new Date(a.fechaHora).getTime());
        this.mediciones.forEach((value, index) => {
          if (this.isJsonString(value.username))
            this.mediciones[index].username = JSON.parse(value.username).nombresCompletos.toString(); 
          else
          this.mediciones[index].username = value.username;
        })
        this.obteniendoMediciones = false;
        this.spinner.hide('spinnerLista');
      },
        () => {
          this.errorMediciones = true;
          this.obteniendoMediciones = false;
          this.spinner.hide('spinnerLista');
          }
        )
  }
  //Verificar si el string es de un formato json valido
  isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

  // validaciones

  reinicioFechaInicioFechaFin()
  {
    this.fechaDesde = new Date(this.modelDesde.year, this.modelDesde.month - 1, this.modelDesde.day);
    this.fechaHasta = new Date(this.modelHasta.year, this.modelHasta.month - 1, this.modelHasta.day);
    this.fechaHasta.setHours(23, 59, 59, 99);
  }

  fechaDesdeMayorQueHasta() {
    let esMayor: boolean = false;
    this.fechaDesde = new Date(this.modelDesde.year, this.modelDesde.month - 1, this.modelDesde.day);
    this.fechaHasta = new Date(this.modelHasta.year, this.modelHasta.month - 1, this.modelHasta.day);
    if (this.fechaDesde > this.fechaHasta) { esMayor = true; }
    return esMayor;
  }

  // modals

  agregarMedicion(){
    let modalRef = this.modalService.open(NuevaMedicionComponent, { centered: true });
    modalRef.componentInstance.tanques = this.tanques;
    modalRef.result.then((result: Medicion) => {
      this.buscarDesdeHasta();
    }, () => { });
  }

  agregarMedicionesMasivas(){
    this.showForm = true;
  }

  // getters

  get tanqueFormControl(){
    return this.tanquesForm.controls.tanqueFormControl;
  }

}

