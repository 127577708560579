<div class="card add-margin">

  <div class="text-center card-header">
    <button type="button" class="btn-close float-end" aria-label="Close" (click)="this.activeModal.dismiss()"></button>
    <h4 class="card-title "> {{ editando ? 'Edición de bonificación o recargo por surtidor' : 'Nueva
    bonificación o recargo por surtidor.'}} </h4>
    
  </div>

  <div class="card-body">
    <form [formGroup]="form">

      <!-- EXPENDEDORA SURTIDOR -->
      <div *ngIf="this.editando===false" class="mb-3 col-12">
        <div class="mb-3 row">
          <div class="col-md-4">
            <label for="expendedoraFormControl" class="col-form-label">Agregar surtidor:</label>
          </div>
          <div class="col-md-8">
            <div class="input-group mb-2">
              <input #input id="expendedoraFormControl" type="text" class="form-control"
                formControlName="expendedoraFormControl" [inputFormatter]="formatter" [resultFormatter]="formatter"
                title="Campo obligatorio, debe seleccionar o ingresar al menos una expendedora." [ngbTypeahead]="search"
                (selectItem)="agregarExpendedora($event); input.value=''" />
            </div>
            <loading-text-spinner *ngIf="cargandoExpendedoras" [error]="errorExpendedoras"
              message="Obteniendo surtidores..." color="primary">
            </loading-text-spinner>
          </div>
        </div>
      </div>

      <div *ngIf="this.editando===false" id="contenedorExpendedoras">
        <div class="row">
          <div class="col-md-12 ms-2 me-2">
            <div><b>Surtidores asociados a la bonificacion/recargo:</b></div>
          </div>
        </div>
        <div *ngIf="this.expendedorasSeleccionadas != null && expendedorasSeleccionadas.length > 0" class="row">
          <div class="col-md-12 row ms-2 me-2 mt-2" *ngFor="let e of this.expendedorasSeleccionadas">
            <div class="col-md-9">{{e.codigoExpendedora}}</div>
            <div class="col-sm-3"><button type="button" (click)="desasignarExpendedora(e)"
                class="btn btn-danger btn-sm col-sm-12"><i class="fa fa-trash col-sm-12"
                  aria-hidden="true"></i></button>
            </div>
          </div>
        </div>
        <div *ngIf="this.expendedorasSeleccionadas != null && expendedorasSeleccionadas.length == 0" class="row">
          <div class="col-md-12 ms-2 me-2">
            No hay surtidores asociados.
          </div>
        </div>
      </div>

      <!--TIPO-->
      <div *ngIf="this.editando===false" class="mb-3 col-12">
        <div class="mb-3 row">
          <div class="col-md-4">
            <label for="productoFormControl" class="col-form-label">Tipo:</label>
          </div>
          <div class="col-md-8">
            <div class="input-group mb-2">
              <select id="tipoFormControl" class="form-select" ngbTooltip="Seleccione el tipo."
                formControlName="tipoFormControl"
                [value]="this.BonifRecargoSeleccionada ? this.BonifRecargoSeleccionada.tipo : 0">
                <option value=0>
                  Bonificación
                </option>
                <option value=1>
                  Recargo
                </option>
              </select>
            </div>
            <div *ngIf="tipo.invalid && tipo.errors.required &&(tipo.dirty || tipo.touched)" class="alert alert-danger">
              Requerido
            </div>
          </div>
        </div>
      </div>

      <!--VALOR-->
      <div class="mb-3 col-12">
        <div class="mb-3 row">
          <div class="col-md-4">
            <label for="valorFormControl" class="col-form-label">*Valor:</label>
          </div>
          <div class="col-md-8">
            <div class="input-group mb-2">
              <input tupe="text" class="form-control" formControlName="valorFormControl"
                (keypress)="onlyDecimalNumberKey($event)" />
            </div>
            <div *ngIf="valor.invalid && valor.errors.required &&(valor.dirty || valor.touched)"
              class="alert alert-danger">
              Requerido
            </div>
          </div>
        </div>
      </div>

      <!-- Motivo-->
      <div class="mb-3 col-md-12">
        <div class="mb-3 row">
          <label for="motivoFormControl" class="col-sm-4 col-form-label">*Motivo: </label>
          <div #fechaHoraVuelo class="col-sm-7">
            <div class="input-group mb-2">
              <textarea formControlName="motivoFormControl" style="background-color:white;color:black;" rows="4"
                cols="70">
              </textarea>
            </div>
            <div *ngIf="motivo.invalid && motivo.errors.required &&(motivo.dirty || motivo.touched)"
              class="alert alert-danger">
              Requerido
            </div>
          </div>
        </div>
      </div>



      <small class="text-danger">*Campos obligatorios.</small>
    </form>

    <div *ngIf="errorValidacion != null" class="alert alert-danger">
      {{errorValidacion}}
    </div>
  </div>
  <div class="card-footer d-flex justify-content-center">
    <button class="btn btn-primary me-2" type="button" [disabled]="guardadoBoR" (click)="guardarBoR()">
      <span *ngIf="!guardadoBoR">Guardar</span>
      <div *ngIf="guardadoBoR" class="d-flex align-items-center">
        <div class="spinner-border text-light spinner-border-sm" role="status"></div>
        <span>Guardando...</span>
      </div>
    </button>
    <button type="button" class="btn btn-secondary" [disabled]="guardadoBoR"
      (click)="activeModal.dismiss()">Cancelar</button>
  </div>
</div>