<nav class="navbar navbar-light bg-light navbar-expand-lg" *ngIf="tipoNuevoAerovale == null">
  <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <form class="form-inline my-2 my-lg-0 me-2">
      <input id="busquedaFormControl" type="text" class="form-control" [formControl]="busquedaFormControl"
        (input)="filtrarRemitos()" placeholder="Factura / Fecha / Cliente" />
    </form>
    <ul class="navbar-nav me-auto">
      <!-- desde -->
      <li class="nav-item me-2">
        <div class="input-group">

          <input type="date" [ngModel]="model" class="fechaFormControlDesdeHasta" placeholder="aaaa-mm-dd"
            (ngModelChange)="model = $event" name="dt" ngbDatepicker #d="ngbDatepicker" value="model"
            class="form-control form-control-rounded" #searchDate required readonly>

          <div class="input-group-append">
            <button id="botonDesde" type="button" class="input-group-btn btn btn-secondary" (click)="d.toggle()">
              <i class="fa fa-calendar" aria-hidden="true"></i></button>
          </div>
        </div>
      </li>
      <!-- hasta -->
      <li class="nav-item">
        <div class="input-group">
          <input type="date" class="fechaFormControlDesdeHasta" [ngModel]="model1" placeholder="aaaa-mm-dd" name="dp"
            value="model1" (ngModelChange)="model1 = $event" class="form-control form-control-rounded" ngbDatepicker
            #h="ngbDatepicker" #searchDate required readonly>
          <div class="input-group-append">
            <button id="botonHasta" type="button" class="input-group-btn btn btn-secondary" (click)="h.toggle()"><i
                class="fa fa-calendar" aria-hidden="true"></i></button>
          </div>
        </div>
      <li class="nav-item">
        <button (click)="buscarDesdeHasta()" class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2" type="submit"><i
            class="fa fa-search" aria-hidden="true"></i></button>
      </li>
    </ul>
  </div>
</nav>
<div class="row mt-3">
  <div class="col col-md-12">
    <div class="spinner-container" *ngIf="this.obteniendoAerovales">    
      <ngx-spinner name="spinnerGrafico" bdOpacity=0.3bd
        Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
        <p style="color: white">C a r g a n d o . . . </p>
      </ngx-spinner>
    </div>
    <div class="abs-center" *ngIf="this.obteniendoAerovales===false && this.remitosFiltrados.length==0">
      <ngb-alert type="warning"> No se encontraron remitos registradas para el rango de fechas seleccionado.</ngb-alert>
    </div>
    <div class="card-body" *ngIf="this.obteniendoAerovales===false && this.remitosFiltrados.length>0">
      <table class="table table-striped table-hover" [formGroup]="tablaComprobantes">
        <thead class="text-center">
          <td><b>Aerovale</b></td>
          <td><b>Prefijo/Numero Aerovale</b></td>
          <td><b>Fecha</b></td>
          <td><b>Cliente</b></td>
          <td><b>Accion</b></td>
        </thead>
        <tbody class="text-center">
          <tr formArrayName="remitosFiltradosForm" *ngFor="let s of this.remitosFiltrados let i = index">
            <td> 
              <button class="btn" (click)="obtenerAerovale(s, contentAerovale, i)" ngbTooltip="Ver aerovale">
                <i *ngIf="!cargandoArovale || cargandoArovale && itemSeleccionado!=i" class="fa fa-eye" aria-hidden="true"></i>
                <div *ngIf="cargandoArovale && itemSeleccionado==i" class="spinner-border text-primary spinner-border-sm" role="status"></div>
              </button> 
            </td>
            <td>{{s.prefijoNumeroAerovale}}</td>
            <td>{{ s.fechaHoraCreacion | date: "d'/'MM'/'y' 'hh:mm a" }}</td>
            <td>{{ s.clienteRazonSocial }}
            </td>
            <td>
              <button type="button" class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2"
                (click)="irACorreccionDespacho(s)">Solicitar
                Refacturación</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <ng-template #contentAerovale let-modal>
    <app-aerovale-detalle *ngIf="this.aerovaleSeleccionado!=null" [aerovaleDetalle]='this.aerovaleSeleccionado'>
    </app-aerovale-detalle>
  </ng-template>