import { Component, OnInit, Input, SimpleChange, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { UnidadMedida } from 'src/app/shared/enums/unidadMedida';
import { TipoProducto } from 'src/app/shared/enums/tipoProducto';
import { Producto } from 'src/app/shared/models/abm/Producto';
import { ProductoService } from '../producto.service';
import { MessageService } from 'src/app/shell/message.service';
import { ProductosListaComponent } from '../productos-lista/productos-lista.component';
import { Rubro } from 'src/app/shared/models/abm/Rubro';
import { TipoPrecio } from 'src/app/shared/enums/TipoPrecio';
import { NgxSpinnerService } from 'ngx-spinner';
import { TipoCombustible } from 'src/app/shared/enums/tipoCombustible';
import { SharedService } from 'src/app/shared/shared.service';
import { TipoAerocombustible } from 'src/app/shared/enums/tipoAerocombustible';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';
import { TipoServicio } from 'src/app/shared/enums/tipoServicio';


@Component({
  selector: 'app-informacion',
  templateUrl: './informacion.component.html',
  styleUrls: ['./informacion.component.css']
})
export class InformacionComponent implements OnInit {

  TipoUsuario = TipoUsuario;

  esaero: boolean;
  informacionForm = new UntypedFormGroup({
    codigoFormControl: new UntypedFormControl({value: null, disabled: true}, Validators.required),
    tipoProductoFormControl: new UntypedFormControl({value: null, disabled: true}, Validators.required),
    descripcionFormControl: new UntypedFormControl(null, [Validators.required]),
    activoFormControl: new UntypedFormControl(true),
    unidadMedidaFormControl: new UntypedFormControl(null, [Validators.required]),
    densidadMaximaFormControl: new UntypedFormControl(null),
    densidadMinimaFormControl: new UntypedFormControl(null),
    porcentajeAlijeFormControl: new UntypedFormControl(null),
    tipoPrecioFormControl: new UntypedFormControl(null, [Validators.required]),
    tipoCombustibleFormControl: new UntypedFormControl(null, [Validators.required]),
    tipoAerocombustibleFormControl: new UntypedFormControl(null, [Validators.required]),    
    tipoServicioFormControl: new UntypedFormControl(null),
    peligrosidadFormControl: new UntypedFormControl(null, [Validators.required]),
    contableFormControl: new UntypedFormControl(null, [Validators.required]),
    partidaFormControl: new UntypedFormControl(null, [Validators.required]),
    productoSAPFormControl: new UntypedFormControl(null, [Validators.required]),
    unidadSAPFormControl: new UntypedFormControl(null, [Validators.required]),
    negocioGerencialFormControl: new UntypedFormControl(null, [Validators.required]),
    unidadStockFormControl: new UntypedFormControl(null, [Validators.required]),
    unidadComercialFormControl: new UntypedFormControl(null, [Validators.required]),
    unidadProduccionFormControl: new UntypedFormControl(null, [Validators.required]),
    grupoEnvaseFormControl: new UntypedFormControl(null, [Validators.required]),
    controlCalidadFormControl: new UntypedFormControl(null),
    controlStockFormControl: new UntypedFormControl(null),
    productoAprobadoFormControl: new UntypedFormControl({value: null, disabled: true}),
    aplicatIDCFormControl: new UntypedFormControl(null),
    aplicaITCFormControl: new UntypedFormControl(null),
    impuestoAprobadoFormControl: new UntypedFormControl({value: null, disabled: true})
  });
  unidadMedida = UnidadMedida;
  densidadErronea: boolean = false;
  tipoProducto = TipoProducto;
  tipoPrecio = TipoPrecio;
  tipoCombustible = TipoCombustible;
  tipoAerocombustible = TipoAerocombustible;
  tipoServicio = TipoServicio;
  errorRubros: boolean = false;
  modificandoProducto: boolean = false;
  rubros: Rubro[];
  rubroSeleccionado: Rubro;
  cargandoRubros: boolean;
  cargandoInfoProducto: boolean = false;
  @Input() productoSeleccionado: Producto;

  mostrarCampoTipoCombustible: boolean = false;
  mostrarCampoTipoAerocombustible: boolean = false;
  mostrarCampoTipoServicio: boolean = false;

  colores = [
    {
      name: 'NEGRO',
      value: '#000000'
    },
    {
      name: 'ROJO',
      value: '#EF3D42'
    },
    {
      name: 'VERDE',
      value: '#009D57'
    },
    {
      name: 'AMARILLO',
      value: '#FFF200'
    },
    {
      name: 'AZUL',
      value: '#0079C2'
    }
  ];

  selectedColor = '#000000';



  constructor(public authService: AuthService, private productoService: ProductoService, private sharedService: SharedService,
    private messageService: MessageService, private listaDeProductos: ProductosListaComponent, private spinner: NgxSpinnerService) {
  }

  // hooks

  ngOnInit() {
    this.spinner.show('spinnerGrafico');
    this.cargandoRubros = true;
    this.getRubros();
    this.codigo.setValue(this.productoSeleccionado.codigoProducto);
    this.descripcion.setValue(this.productoSeleccionado.nombreProducto);
    this.unidadDeMedida.setValue(this.productoSeleccionado.unidadMedida);
    this.activo.setValue(this.productoSeleccionado.activo);
    this.densidadMaxima.setValue(this.productoSeleccionado.densidadMax);
    this.densidadMinima.setValue(this.productoSeleccionado.densidadMin);
    this.combustible.setValue(this.productoSeleccionado.tipoCombustible);
    this.aerocombustible.setValue(this.productoSeleccionado.tipoAerocombustible);
    console.log(this.productoSeleccionado.tipoAerocombustible);
    this.tipoDePrecio.setValue(this.productoSeleccionado.tipoPrecio);
    this.selectedColor = this.productoSeleccionado.color;


    this.peligrosidad.setValue(this.productoSeleccionado.peligrosidad);
    this.contable.setValue(this.productoSeleccionado.agrupacionContable);
    this.partida.setValue(this.productoSeleccionado.partida);
    this.productoSAP.setValue(this.productoSeleccionado.productoSAP);
    this.unidadSAP.setValue(this.productoSeleccionado.unidadMedidaSAP);
    this.negocioGerencial.setValue(this.productoSeleccionado.unidadNegocioGerencial);
    this.unidadStock.setValue(this.productoSeleccionado.unidadMedidaStock);
    this.unidadComercial.setValue(this.productoSeleccionado.unidadMedidaComercial);
    this.unidadProduccion.setValue(this.productoSeleccionado.unidadMedidaProduccion);
    this.grupoEnvase.setValue(this.productoSeleccionado.grupoEnvase);
    this.controlStock.setValue(this.productoSeleccionado.controlStock);
    this.controlCalidad.setValue(this.productoSeleccionado.controlCalidad);
    this.productoAprobado.setValue(this.productoSeleccionado.aprobadoProducto);
    this.aplicaIDC.setValue(this.productoSeleccionado.aplicaIDC);
    this.aplicaITC.setValue(this.productoSeleccionado.aplicaITC);
    this.impuestoAprobado.setValue(this.productoSeleccionado.aprobadoImpuestos);
    this.porcentajeAlije.setValue(this.productoSeleccionado.porcentajeAlije);
    this.servicios.setValue(this.productoSeleccionado.tipoServicio);
  }

  ngOnChanges(change: SimpleChanges) {
    this.ngOnInit();
  }

  // llamdas al service

  getRubros() {
    this.cargandoInfoProducto = true;
    this.productoService.getRubros()
      .subscribe(result => {
        this.rubros = result;
        this.obtenerRubroSeleccionado();
        this.spinner.hide('spinnerGrafico')
        this.cargandoInfoProducto = false;
      }, () => this.errorRubros = true);
  }

  formatDensidad(event, i: number) {
    if (event.charCode == 8 || event.charCode == 0) return null;

    if (event.charCode >= 48 && event.charCode <= 57) {
      if (i == 0) {
        this.densidadMinima.setValue(this.sharedService.formatDensidad(event));
      } else {
        this.densidadMaxima.setValue(this.sharedService.formatDensidad(event));
      }
    }
    return false;
  }

  modificarProducto() {
    if (this.informacionForm.valid) {
      let p: Producto = new Producto();
      p.codigoProducto = this.codigo.value;
      this.modificandoProducto = true;
      p.nombreProducto = this.descripcion.value;
      p.unidadMedida = this.unidadDeMedida.value;
      p.tipoPrecio = this.tipoDePrecio.value;
      p.tipoCombustible = this.combustible.value;
      p.tipoAerocombustible = this.aerocombustible.value;
      p.tipoServicio = this.servicios.value;
      p.color = this.selectedColor;
      p.rubroId = this.tipoDeProducto.value.id;
      p.activo = this.activo.value;
      p.id = this.productoSeleccionado.id;
      //solo para aero
      p.densidadMin = parseFloat(this.densidadMinima.value);
      p.densidadMax = parseFloat(this.densidadMaxima.value);
      p.porcentajeAlije = Number(this.porcentajeAlije.value);
      //solo para combustible
      if (this.tipoDeProducto.value.aerocombustible) { p.tipoProducto = 1 };
      if (this.tipoDeProducto.value.aeroespecialidad) { p.tipoProducto = 2 };
      if (this.tipoDeProducto.value.combustible) { p.tipoProducto = 3 };
      if (this.tipoDeProducto.value.servicios) { p.tipoProducto = 4 };

      p.peligrosidad = this.peligrosidad.value;
      p.agrupacionContable = this.contable.value;
      p.partida = this.partida.value;
      p.productoSAP = this.productoSAP.value;
      p.unidadMedidaSAP = this.unidadSAP.value;
      p.unidadNegocioGerencial = this.negocioGerencial.value;
      p.unidadMedidaStock = this.unidadStock.value;
      p.unidadMedidaComercial = this.unidadComercial.value;
      p.unidadMedidaProduccion = this.unidadProduccion.value;
      p.grupoEnvase = this.grupoEnvase.value;
      p.controlCalidad = this.controlCalidad.value;
      p.controlStock = this.controlStock.value;
      p.aprobadoProducto = this.productoAprobado.value;
      p.aplicaITC = this.aplicaITC.value;
      p.aplicaIDC = this.aplicaIDC.value;
      p.aprobadoImpuestos = this.impuestoAprobado.value;
      console.log("Producto " + JSON.stringify(p))
      this.productoService.modificarProducto(p)
        .subscribe(result => {
          this.messageService.showSuccessMessage("Se ha modificado el producto correctamente");
          this.listaDeProductos.ngOnInit();
          this.listaDeProductos.esperandoCarga = false;
          this.modificandoProducto = false;
        }, () => {

        });
    }
    else {
      this.messageService.showErrorMessage('Por favor revise los campos a completar.');
      this.modificandoProducto = false;
    }
  }

  // validaciones
  densidadMenor() {
    if (parseFloat(this.densidadMinima.value) > parseFloat(this.densidadMaxima.value)) {
      this.densidadErronea = true;
    } else {
      this.densidadErronea = false;
    }
  }

  obtenerTipoCombustible(): string {
    return TipoCombustible[this.combustible.value];
  }

  obtenerTipoAerocombustible(): string {
    return TipoAerocombustible[this.aerocombustible.value];
  }

  onlyDecimalNumberKey(event) {
    let charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57))
      return false;
    return true;
  }

  esCampoVacio(campo: any): boolean {

    return (campo == null || (campo != null && campo.length == 0));
  }

  campoInicializadoEnVacio(campo: any): boolean {

    return (campo != null && campo === '');
  }


  camposVacios() {
    return this.esCampoVacio(this.codigo.value) || this.esCampoVacio(this.descripcion.value);
  }


  esAerocombustible() {
    if (this.tipoDeProducto.value.aerocombustible) {
      this.esaero = true;
    } else {
      this.esaero = false;
    }
  }

  // form

  onChange(value) {
    this.selectedColor = value;
  }

  obtenerPosicionRubroSeleccionado(): number {
    let i = 0;
    i = this.rubros.findIndex(r => r.id == this.rubroSeleccionado.id);
    return i;
  }

  obtenerRubroSeleccionado() {
    this.productoService.getRubroPorId(this.productoSeleccionado.rubro.id)
      .subscribe(result => {
        this.rubroSeleccionado = result;
        let i = this.obtenerPosicionRubroSeleccionado();
        this.tipoDeProducto.setValue(this.rubros[i]);
        if (this.rubros[i].aerocombustible) {
          this.esaero = true;
          this.mostrarCampoTipoAerocombustible = true;
        } else {
          this.esaero = false;
          this.mostrarCampoTipoAerocombustible = false;
        }
        this.cargandoRubros = false;


        if(this.rubros[i].combustible){
          this.mostrarCampoTipoCombustible = true;
        }else{
          this.mostrarCampoTipoCombustible = false;
        }
        if(this.rubros[i].servicios){
          this.mostrarCampoTipoServicio = true;
        }else{
          this.mostrarCampoTipoServicio = false;
        }
      });
  }

  deshabilitarProducto() {
    if (this.productoSeleccionado.aprobadoProducto) {
      this.informacionForm.get('productoAprobadoFormControl')?.disable();
    } else {
      this.informacionForm.get('productoAprobadoFormControl')?.enable();
    }

  }

  deshabilitarImpuesto() {
    if (this.productoSeleccionado.aprobadoImpuestos) {
      this.informacionForm.get('impuestoAprobadoFormControl')?.disable();
    } else {
      this.informacionForm.get('impuestoAprobadoFormControl')?.enable();
    }
  }


  keysUnidadDeMedida(): Array<string> {
    var keys = Object.keys(this.unidadMedida);
    return keys.slice(keys.length / 2);
  }

  keysTipos(): Array<string> {
    var keys = Object.keys(this.tipoProducto);
    keys.shift();
    keys = keys.slice(keys.length / 2);
    keys.shift();
    return keys;
  }

  keysTipoPrecio(): Array<string> {
    var keys = Object.keys(this.tipoPrecio);
    return keys.slice(keys.length / 2);
  }

  keysTipoCombustible(): Array<string> {
    var keys = Object.keys(this.tipoCombustible);
    return keys.slice(keys.length / 2);
  }

  keysTipoAerocombustible(): Array<string> {
    var keys = Object.keys(this.tipoAerocombustible);
    return keys.slice(keys.length / 2);
  }
  
  keysTipoServicio(): Array<string> {
    var keys = Object.keys(this.tipoServicio);
    return keys.slice(keys.length / 2);
  }

  esAeroespecialidad() {
    return this.tipoDeProducto.value.aeroespecialidad;
  }

  esServicio() {
    return this.tipoDeProducto.value.servicios;
  }

  // getters

  obtenerUnidadMedida(unidad: number) {
    return UnidadMedida[unidad];
  }

  obtenerTipoPrecio(precio: number) {
    return TipoPrecio[precio];
  }

  obtenerCombustible(comb: number) {
    return TipoCombustible[comb];
  }

  obtenerAerocombustible(aerocomb: number) {
    return TipoAerocombustible[aerocomb];
  }
 
  obtenerServicio(serv: number) {
    return TipoServicio[serv];
  }

  get codigo() {
    return this.informacionForm.get('codigoFormControl');
  }
  get descripcion() {
    return this.informacionForm.get('descripcionFormControl');
  }
  get unidadDeMedida() {
    return this.informacionForm.get('unidadMedidaFormControl');
  }
  get tipoDeProducto() {
    return this.informacionForm.get('tipoProductoFormControl');
  }
  get densidadMaxima() {
    return this.informacionForm.get('densidadMaximaFormControl');
  }
  get densidadMinima() {
    return this.informacionForm.get('densidadMinimaFormControl');
  }
  get activo() {
    return this.informacionForm.get('activoFormControl');
  }
  get tipoDePrecio() {
    return this.informacionForm.get('tipoPrecioFormControl');
  }
  get combustible() {
    return this.informacionForm.get('tipoCombustibleFormControl');
  }
  get aerocombustible() {
    return this.informacionForm.get('tipoAerocombustibleFormControl');
  }
  get peligrosidad() {
    return this.informacionForm.get('peligrosidadFormControl');
  }
  get contable() {
    return this.informacionForm.get('contableFormControl');
  }
  get partida() {
    return this.informacionForm.get('partidaFormControl');
  }
  get productoSAP() {
    return this.informacionForm.get('productoSAPFormControl');
  }
  get unidadSAP() {
    return this.informacionForm.get('unidadSAPFormControl');
  }
  get negocioGerencial() {
    return this.informacionForm.get('negocioGerencialFormControl');
  }
  get unidadStock() {
    return this.informacionForm.get('unidadStockFormControl');
  }
  get unidadComercial() {
    return this.informacionForm.get('unidadComercialFormControl');
  }
  get unidadProduccion() {
    return this.informacionForm.get('unidadProduccionFormControl');
  }
  get grupoEnvase() {
    return this.informacionForm.get('grupoEnvaseFormControl');
  }
  get controlCalidad() {
    return this.informacionForm.get('controlCalidadFormControl');
  }
  get controlStock() {
    return this.informacionForm.get('controlStockFormControl');
  }
  get productoAprobado() {
    return this.informacionForm.get('productoAprobadoFormControl');
  }
  get aplicaITC() {
    return this.informacionForm.get('aplicaITCFormControl');
  }
  get aplicaIDC() {
    return this.informacionForm.get('aplicatIDCFormControl');
  }
  get impuestoAprobado() {
    return this.informacionForm.get('impuestoAprobadoFormControl');
  }
  get porcentajeAlije() {
    return this.informacionForm.get('porcentajeAlijeFormControl');
  }
  get servicios() {
    return this.informacionForm.get('tipoServicioFormControl');
  }

  ocultarInputGeneral(){
    if(this.authService.hasRole(TipoUsuario.RESPRODUCTO, TipoUsuario.JEFEDEAEROPLANTA)){
      return false;
    }else{
      return true;
    }
  }
}
