import { Component, OnInit } from '@angular/core';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { NuevoEquipoComponent } from './nuevo-equipo/nuevo-equipo.component';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EquipoFijo } from 'src/app/shared/models/stock/EquipoFijo';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators, ValidationErrors, AbstractControl } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { StockService } from '../../stock.service';
import { UsuarioService } from 'src/app/account/usuario.service';
import { MessageService } from 'src/app/shell/message.service';
import { EstadoEquipo } from 'src/app/shared/models/stock/EstadoEquipo';
import { estadoEquipo } from 'src/app/shared/enums/estadoEquipo';
import { Guid } from 'guid-typescript';


@Component({
  selector: 'app-equipos-fijos',
  templateUrl: './equipos-fijos.component.html',
  styleUrls: ['./equipos-fijos.component.scss']
})
export class EquiposFijosComponent implements OnInit {

  TipoUsuario = TipoUsuario;

  equipos: EquipoFijo[] = [];
  obteniendoEquipos: boolean = false;
  tablaEquipos: UntypedFormGroup;
  messageEquipos: string = 'C A R G A N D O    E Q U I P O S . . .';
  estEquipo = estadoEquipo;
  guardandoEstado: boolean[] = [];
  

  constructor(private authService: AuthService,
     private activeModal: NgbModal,
     private messageService: MessageService,
     private fb: UntypedFormBuilder,
     private spinner: NgxSpinnerService,
     private stockService: StockService,
     private usuarioService: UsuarioService) {
      this.tablaEquipos = this.fb.group({
        equiposForm: new UntypedFormArray([])
      });
      }

  ngOnInit(): void {
    this.getEquipos();
    this.equipos.forEach(() => this.guardandoEstado.push(false));
  }

  private setFormControls() {
    // reinicio el formulario
    this.tablaEquipos.setControl('equiposForm', this.fb.array([]));
    this.equipos.map((e, i) => {
      const control = new UntypedFormControl('', [Validators.required]);
      if(e.ultimoEstado != null){
        control.setValue(e.ultimoEstado.estado);
      }
      (this.tablaEquipos.controls.equiposForm as UntypedFormArray).push(control);
    });
  }

  getEquipos(){
    this.spinner.show('spinnerEquipos');
    this.obteniendoEquipos = true;
    this.stockService.obtenerEquiposFijos(this.usuarioService.getAeroplantaActual().codigoAeroplanta)
      .subscribe(result => {
        this.equipos = result.filter(e => e.id != null && e.nombre != null && e.ultimoEstado != null);
        this.setFormControls();
        this.spinner.hide('spinnerEquipos');
        this.obteniendoEquipos = false;
      }, () => {
        this.spinner.hide('spinnerEquipos');
        this.obteniendoEquipos = false;
      })

  }

  obtenerEstado(est) {
    return Number(this.estEquipo[est]);
  }

  keysEstados(): Array<string> {
    var keys = Object.keys(estadoEquipo);
    keys = keys.slice(keys.length / 2);
    return keys;
  }

  compareEstados(n1: any, n2: any): boolean {  
    return n1 && n2 ? n1.id == n2.id : n1 == n2;
  }

  mismoEstado(e: EquipoFijo): boolean {
    return e.ultimoEstado.estado == this.tablaEquipos.controls.equiposForm.value[this.equipos.findIndex(x => x.id == e.id)];
  }

  cambiarEstadoEquipo(e: EquipoFijo, i: number, nuevoEstado){
    this.guardandoEstado[i] = true;
    let equipoId = e.id;
    let est = new EstadoEquipo();
    est.id = Guid.EMPTY;
    est.estado = nuevoEstado;
    est.responsable = this.usuarioService.getNombresCompletosUsuario();
    est.fechaHoraUltimoCambio = "2020-01-15T21:00:00.00";
    est.fechaHoraFS = null;
    est.observaciones = null;
    this.stockService.cambiarEstadoEquipo(equipoId, est)
      .subscribe(result => {
        this.messageService.showSuccessMessage('El estado del equipo fue actualizado con exito.');
        this.equipos[i].ultimoEstado.estado = nuevoEstado;
        this.guardandoEstado[i] = false;
      }, () => {
        this.messageService.showErrorMessage('Ocurrio un error, vuelva a intentar.');
        this.guardandoEstado[i] = false;
      })
  }

  hasRole(...roles: TipoUsuario[]) {
    return this.authService.hasRole(...roles);
  }

  abrirModal(editar: boolean, i: number, equipo?: EquipoFijo) {
    
    let nuevoEstado;
    let cambioEstado = false;
    
    if(equipo != null) {
      nuevoEstado = this.tablaEquipos.controls.equiposForm.value[this.equipos.findIndex(x => x.id == equipo.id)];
    }
    
    if(!editar && equipo != null){
      cambioEstado = true;
    }
    
    let modalRef = this.activeModal.open(NuevoEquipoComponent, { centered: true, backdrop: 'static' });
    modalRef.componentInstance.equipo = equipo;
    modalRef.componentInstance.editar = editar;
    modalRef.componentInstance.nuevoEstado = nuevoEstado;
    modalRef.componentInstance.cambioEstado = cambioEstado;
    modalRef.result.then(result => {
      if (result == true) {
        this.getEquipos();
      }
    }, () => {
      
    });

  }

}
