import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { UsuarioService } from 'src/app/account/usuario.service';
import { ClienteService } from 'src/app/cliente/cliente.service';
import { AeropuertoService } from 'src/app/despacho/aeropuerto.service';
import { PedidoService } from 'src/app/despacho/pedido.service';
import { TipoPedido } from 'src/app/shared/enums/TipoPedido';
import { Cuenta } from 'src/app/shared/models/cliente/Cuenta';
import { AerolineaDestinoDTO } from 'src/app/shared/models/despacho/AerolineaDestinoDTO';
import { AerolineaDTO } from 'src/app/shared/models/despacho/AerolineaDTO';
import { AerolineaEncabezadoDTO } from 'src/app/shared/models/despacho/AerolineaEncabezadoDTO';
import { Aeropuerto } from 'src/app/shared/models/despacho/Aeropuerto';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { SharedService } from 'src/app/shared/shared.service';
import { MessageService } from 'src/app/shell/message.service';
import { ModalDestinosTamsComponent } from './modal-destinos-tams/modal-destinos-tams.component';

@Component({
  selector: 'app-destinos-tams-abm',
  templateUrl: './destinos-tams-abm.component.html',
  styleUrls: ['./destinos-tams-abm.component.scss']
})
export class DestinosTamsAbmComponent implements OnInit {

  @Input() aerolineaDTOSeleccionada: AerolineaEncabezadoDTO;  
  cuentas: Cuenta[];

  cargandoDatos: boolean = false;
  cargandoCuentas: boolean = false;
  cargandoAeropuertos: boolean = false;
  guardandoDatos: boolean = false;
  change$: Subscription;
  listaDestinos: AerolineaDestinoDTO[]=[];
  listaDestinosMostrar: AerolineaDestinoDTO[]=[];
  listaDestinosOtraAeroplanta: AerolineaDestinoDTO[]=[];
  listaAeropuertos: Aeropuerto[];   

  constructor(
    private usuarioService: UsuarioService,
    private spinner: NgxSpinnerService,
    private modal:NgbModal,
    private pedidoService: PedidoService,
    private messageService: MessageService,
    private aeropuertoService: AeropuertoService,
    private clienteService:ClienteService,
    sharedService: SharedService
    ) { 
      this.change$ = sharedService.aeroplantaSeleccionada.subscribe(_=>{
        this.cambiarDeAeroplanta();
      });
    }

  ngOnInit(): void {    
    this.getAeropuertos();
  }

  cambiarDeAeroplanta() {
    this.ngOnChanges();
  }

  ngOnDestroy() {
    this.change$.unsubscribe();
  }

  ngOnChanges() {
    this.listaDestinos=[];
    this.listaDestinosMostrar=[];
    this.listaDestinosOtraAeroplanta=[];
    this.obtenerCuentas();
    this.obtenerAerolinea();
  }

  getAeropuertos() {
    this.cargandoAeropuertos=true;
    this.aeropuertoService.getAeropuertos()
      .subscribe(res => {
        this.listaAeropuertos = res;                
        this.cargandoAeropuertos=false;
      }, ()=> {
        this.cargandoAeropuertos=false;
      });
  }  

  obtenerNombreAeropuerto(codArpto:string) {
    let l = this.listaAeropuertos.find( a => a.codigoAeropuerto == codArpto);
    if(l) { return l.nombre } else { return null }
  }

  obtenerAerolinea() {
    this.mostrarSpinner();
    this.pedidoService.getAerolinea(this.aerolineaDTOSeleccionada.id)
      .subscribe(resp => {
        this.listaDestinos = resp.destinos;
        this.listaDestinosMostrar = this.listaDestinos.filter( d => d.codigoAeroplanta == this.getCodigoAeroplanta());
        this.listaDestinosOtraAeroplanta = this.listaDestinos.filter( d => d.codigoAeroplanta != this.getCodigoAeroplanta());
        this.ocultarSpinner();
      }, ()=> {
        this.ocultarSpinner();
      })
  }

  obtenerCuentas( ) {
    this.cuentas = [];    
    this.cargandoCuentas = true;
    this.clienteService.getClientePorDocumento(this.aerolineaDTOSeleccionada.tipoDocumento, this.aerolineaDTOSeleccionada.cuit)
      .subscribe(resp => {
        this.cuentas = resp.cuentas.filter( c => c.activo == true );
        this.cargandoCuentas = false;
      }, ()=>{
        this.cargandoCuentas = false;
      });   
  }

  eliminarDestino(d:AerolineaDestinoDTO) {
    this.listaDestinosMostrar = this.listaDestinosMostrar.filter( x => x != d);
  }

  mostrarSpinner() {
    this.cargandoDatos = true;
    this.spinner.show('spinnerGrafico')
  }

  ocultarSpinner() {
    this.cargandoDatos = false;
    this.spinner.hide('spinnerGrafico')
  }

  guardarCambios() {

    this.guardandoDatos = true;
    let aerolineaDTO = new AerolineaDTO;

    aerolineaDTO.id = this.aerolineaDTOSeleccionada.id;
    aerolineaDTO.cuit = this.aerolineaDTOSeleccionada.cuit;
    aerolineaDTO.razonSocial = this.aerolineaDTOSeleccionada.razonSocial;
    aerolineaDTO.codigoAerolinea = this.aerolineaDTOSeleccionada.codigoAerolinea.toUpperCase();
    aerolineaDTO.cuentaNacional = this.aerolineaDTOSeleccionada.cuentaNacional;
    aerolineaDTO.cuentaInternacional = this.aerolineaDTOSeleccionada.cuentaInternacional;
    aerolineaDTO.destinos = this.listaDestinosOtraAeroplanta.concat(this.listaDestinosMostrar);
        
    this.pedidoService.putAerolineas(this.aerolineaDTOSeleccionada.id, aerolineaDTO)
    .subscribe(result => {      
      this.messageService.showSuccessMessage('Destino modificado con éxito.');
      this.guardandoDatos = false;
    }, (err: ErrorModel) => { 
      this.messageService.showErrorMessage('Hubo un error al modificar el Destino.');
      this.guardandoDatos = false;
    });    
  }

  getCodigoAeroplanta(){
    return this.usuarioService.getAeroplantaActual().codigoAeroplanta;
  }

  getNombreAeroplanta(){
    return this.usuarioService.getAeroplantaActual().nombre;
  }

  agregarDestinos() {
    const modalRef = this.modal.open(ModalDestinosTamsComponent, { centered: true });      
    modalRef.componentInstance.cuentas = this.cuentas;
    modalRef.componentInstance.destinos = this.listaDestinosMostrar;
    modalRef.componentInstance.destinosOtrasAeroplantas = this.listaDestinosOtraAeroplanta;
    modalRef.componentInstance.aerolineaSeleccionada = this.aerolineaDTOSeleccionada;
    modalRef.componentInstance.codAeroplanta = this.getCodigoAeroplanta();
    modalRef.componentInstance.nombreAeroplanta = this.getNombreAeroplanta();
  }

  actualizarCuenta(index: number, property: string, event: any) {
    if (property == 'cuenta') {
      const editField = event.target.value;
      this.listaDestinosMostrar[index].cuenta = editField;
    }
  }
}
