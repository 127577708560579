<ul class="nav nav-tabs justify-content-start">
    <li class="nav-item" *ngIf="authService.hasRole(TipoUsuario.OPERADOR, TipoUsuario.OPERADOR_3, TipoUsuario.SUPERVISOR, TipoUsuario.TUTOR, TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.FACTURACION, TipoUsuario.REFACTURACION, TipoUsuario.SOPORTE, TipoUsuario.OPERADORDIRECTO, TipoUsuario.JEFEDEAEROPLANTA)">
        <a class="nav-link" 
            [queryParams]="{ 
                notas: false,
                condicion: CondicionVenta.contado 
            }" 
            routerLink="contado" routerLinkActive="active"><b>Contado</b></a>
    </li>
    <li class="nav-item" *ngIf="authService.hasRole(TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.FACTURACION, TipoUsuario.REFACTURACION, TipoUsuario.SOPORTE)">
        <a class="nav-link" 
            [queryParams]="{ 
                notas: false,
                condicion: CondicionVenta.cuentaCorriente 
            }" 
            routerLink="ctacte" routerLinkActive="active"><b>Cuenta Corriente</b></a>
    </li>
    <li class="nav-item" *ngIf="authService.hasRole(TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.FACTURACION, TipoUsuario.REFACTURACION, TipoUsuario.SOPORTE, TipoUsuario.OPERADOR, TipoUsuario.OPERADOR_3, TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.TUTOR, TipoUsuario.OPERADORDIRECTO)">
        <a class="nav-link" 
            [queryParams]="{ 
                notas: true,
                condicion: null
            }" 
            routerLink="notas" routerLinkActive="active"><b>Notas D/C</b></a>
    </li>
</ul>
<router-outlet></router-outlet>