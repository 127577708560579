import { formatDate } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbCalendar, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { filter, switchMap, tap } from 'rxjs/operators';
import { ProductoService } from 'src/app/abm/producto/producto.service';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { TipoActivoPrecio } from 'src/app/shared/enums/tipoActivoPrecio';
import { TipoBonificacionRecargo } from 'src/app/shared/enums/tipoBonificacionRecargo';
import { TipoEstadoPrecio } from 'src/app/shared/enums/tipoEstadoPrecio';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';
import { Producto } from 'src/app/shared/models/abm/Producto';
import { Aeroplanta } from 'src/app/shared/models/despacho/Aeroplanta';
import { Usuario } from 'src/app/shared/models/despacho/Usuario';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { BonificacionRecargo } from 'src/app/shared/models/precios/BonificacionyRecargo';
import { EstadoPrecio } from 'src/app/shared/models/precios/EstadoPrecio';
import { Expendedora } from 'src/app/shared/models/precios/Expendedora';
import { MessageService } from 'src/app/shell/message.service';
import { StockService } from 'src/app/stock/stock.service';
import { ModalSeleccionarUsuarioComponent } from '../../niveles-aprobacion/modal-seleccionar-usuario/modal-seleccionar-usuario.component';
import { PreciosService } from '../../precios.service';


@Component({
  selector: 'app-aprobar-byr',
  templateUrl: './aprobar-byr.component.html',
  styleUrls: ['./aprobar-byr.component.css']
})
export class AprobarByrComponent implements OnInit {

  TipoBonificacionRecargo = TipoBonificacionRecargo;
  tipoBonificacionRecargo: TipoBonificacionRecargo;
  
  esperandoCarga: boolean = false;
  errorNiveles: boolean = false;
  messageByR: string = 'C A R G A N D O . . .';
  aeroplantas: Aeroplanta[] = [];
  checkAeroplanta: any[] = [];
  productosParaCheckear: any[] = [];
  productos: Producto[];
  expendedoras: Expendedora[];
  expendedorasParaCheckear: any[] = [];

  enumTipoEstadoPrecio = TipoEstadoPrecio;

  vigencias = [
    { nombre: "Vigentes", seleccionado: true },
    { nombre: "Vencidos", seleccionado: false },
    { nombre: "Futuros", seleccionado: false }
  ];

  searchForm = new UntypedFormGroup({
    busqueda: new UntypedFormControl(''),
  });

  bonificacionyRecargo: BonificacionRecargo[];
  bonificacionyRecargoFiltrado: BonificacionRecargo[];
  bonificacionyRecargoEnviar: BonificacionRecargo[] = [];


  modelFecha: NgbDateStruct;
  modelHora: any;

  TipoUsuario=TipoUsuario;

  constructor(private spinner: NgxSpinnerService, private calendar: NgbCalendar, private preciosService: PreciosService,
    private productoService: ProductoService, private messageService: MessageService, private modalService: NgbModal,
    private route:ActivatedRoute, private stockService: StockService, private authService: AuthService) { 
      this.route.queryParams.pipe(
        tap(params => {this.tipoBonificacionRecargo = params['tipoBonificacionRecargo']; this.initBonificacionesRequest()}),
        switchMap(params => this.preciosService.getBonificacionesyRecargosV1(TipoEstadoPrecio.Pendiente))
      ).subscribe(result => {
        this.bonificacionyRecargo = result.filter(b => b.tipoBonificacionRecargo == this.tipoBonificacionRecargo);

        this.bonificacionyRecargo.forEach(b => {
          if(b.solicitadoPor && b.solicitadoPor.indexOf(',') > -1) b.solicitadoPor = JSON.parse(b.solicitadoPor.replace(/'/g,'"')).NombresCompletos;
        });

        this.bonificacionyRecargoFiltrado = this.bonificacionyRecargo;
        this.filtrarByR();
        this.spinner.hide('spinnerGrafico');
        this.esperandoCarga = false;
      },
      (err: ErrorModel) => {
        this.esperandoCarga = false;
        this.spinner.hide("spinnerGrafico");
        this.messageService.showErrorMessage( "Error al obtener ByR." );
        this.bonificacionyRecargo=[];
        this.bonificacionyRecargoFiltrado = this.bonificacionyRecargo;
  
        this.filtrarByR();
      });
  }

  initBonificacionesRequest(){
    this.esperandoCarga = true;
    this.spinner.show('spinnerGrafico');
    this.bonificacionyRecargo = [];
    this.bonificacionyRecargoFiltrado = [];
    this.bonificacionyRecargoEnviar = [];
  }

  getBonificacionesyRecargos(){
    this.initBonificacionesRequest();

    this.preciosService.getBonificacionesyRecargosV1(TipoEstadoPrecio.Pendiente)
    .subscribe(result => {
      this.bonificacionyRecargo = result.filter(b => b.tipoBonificacionRecargo == this.tipoBonificacionRecargo);

      this.bonificacionyRecargo.forEach(b => {
        if(b.solicitadoPor && b.solicitadoPor.indexOf(',') > -1) b.solicitadoPor = JSON.parse(b.solicitadoPor.replace(/'/g,'"')).NombresCompletos;
      });

      this.bonificacionyRecargoFiltrado = this.bonificacionyRecargo;
      this.filtrarByR();
      this.spinner.hide('spinnerGrafico');
      this.esperandoCarga = false;
    },
    (err: ErrorModel) => {
      this.esperandoCarga = false;
      this.spinner.hide("spinnerGrafico");
      this.messageService.showErrorMessage( "Error al obtener ByR." );
      this.bonificacionyRecargo=[];
      this.bonificacionyRecargoFiltrado = this.bonificacionyRecargo;

      this.filtrarByR();
    });
  }

  // hooks

  ngOnInit() {
    if(this.tipoBonificacionRecargo == TipoBonificacionRecargo.Producto){
      this.getTodasLasAeroplantas();
      this. getTodosLosProductos();
    }else if(this.tipoBonificacionRecargo == TipoBonificacionRecargo.Expendedora){
      this.getTodasLasExpendedoras();
    }
    
    this.searchForm.valueChanges.subscribe(() => this.filtrarByR());

    this.modelFecha = this.calendar.getToday();

    var horaActual = new Date();
    this.modelHora = { hour: horaActual.getHours(), minute: horaActual.getMinutes(), second: horaActual.getSeconds() };
  }

  // llamadas al service

  getTodasLasAeroplantas() {
    this.preciosService.obtenerTodasLasAeroplantas()
      .subscribe(result => {
        this.aeroplantas = result;
        for (let a of this.aeroplantas) {
          this.checkAeroplanta.push({ "codigo": a.codigoAeroplanta, "seleccionado": false });
        }
      })
  }

  getTodosLosProductos() {
    console.log('Obteniendo todos los productos.');
    this.productos = [];
    this.productoService.obtenerProductos()
      .subscribe(result => {
        this.productos = result;
        this.productosParaCheckear = [];
        for (let prod of this.productos) {
          this.productosParaCheckear.push({ "nombre": prod.nombreProducto, "codigo": prod.codigoProducto, "seleccionado": false });
        }
      });
  }

  getTodasLasExpendedoras() {
    console.log('Obteniendo todas las expendedoras.');
    this.expendedoras = [];
    this.stockService.getSurtidores(null)
      .subscribe(result => {
        var surtidores = result;

        this.expendedoras = surtidores.map(s => {
          var e = new Expendedora();
          e.codigoExpendedora = s.codigo;
          e.nombreExpendedora = s.nombre;
          return e;
        });

        this.expendedoras.sort( (a,b) => a.nombreExpendedora.localeCompare(b.nombreExpendedora) )

        this.productosParaCheckear = [];
        for (let exp of this.expendedoras) {
          this.expendedorasParaCheckear.push({ "nombre": exp.nombreExpendedora, "codigo": exp.codigoExpendedora, "seleccionado": false });
        }
      });
  }


  aprobarByR(){
    var listaIdsSeleccionados = this.getCheckedItemList();

    if(listaIdsSeleccionados.length == 0 ){
      this.messageService.showErrorMessage("Debe seleccionar al menos una bonificación o recargo de la lista para aprobar.");
      return;
    }

    this.esperandoCarga = true;
    this.spinner.show("spinnerGrafico");

    var estado = new EstadoPrecio();
    estado.responsable = this.preciosService.obtenerStringResponsableV2();
    estado.activo = TipoActivoPrecio.Activo;
    estado.estadoPrecio = TipoEstadoPrecio.Aprobado;

    this.preciosService.cambiarEstadoByR(estado, listaIdsSeleccionados)
    .subscribe(nivelAprobacion => {
      this.esperandoCarga = false;
      this.spinner.hide("spinnerGrafico");
      this.messageService.showSuccessLongMessage( "Se aprobaron las bonificaciones y recargos marcados" );
      this.getBonificacionesyRecargos();
    },
    (err: ErrorModel) => {
      this.esperandoCarga = false;
      this.spinner.hide("spinnerGrafico");
      this.messageService.showErrorMessage( "Error al aprobar bonificación y recargo." );
    });
  }

  rechazarByr(){
    var listaIdsSeleccionados = this.getCheckedItemList();

    if(listaIdsSeleccionados.length == 0 ){
      this.messageService.showErrorMessage("Debe seleccionar al menos una bonificación o recargo de la lista para rechazar.");
      return;
    }

    this.esperandoCarga = true;
    this.spinner.show("spinnerGrafico");

    var estado = new EstadoPrecio();
    estado.responsable = this.preciosService.obtenerStringResponsableV2();
    estado.activo = TipoActivoPrecio.Activo;
    estado.estadoPrecio = TipoEstadoPrecio.Rechazado;

    this.preciosService.cambiarEstadoByR(estado, listaIdsSeleccionados)
    .subscribe(nivelAprobacion => {
      this.esperandoCarga = false;
      this.spinner.hide("spinnerGrafico");
      this.messageService.showSuccessLongMessage( "Se rechazaron las bonificaciones y recargos marcados" );
      this.getBonificacionesyRecargos();
    },
    (err: ErrorModel) => {
      this.esperandoCarga = false;
      this.spinner.hide("spinnerGrafico");
      this.messageService.showErrorMessage( "Error al rechazar bonificación y recargo" );
    });
  }

  // form

  filtrarByR(){
    if(this.tipoBonificacionRecargo == TipoBonificacionRecargo.Producto){
      this.filtrarByRProducto();
    }else{
      this.filtrarByRSurtidor();
    }
  }

  filtrarByRProducto() {
    this.bonificacionyRecargoFiltrado = this.bonificacionyRecargo.filter(a => {
      let agregar = true;
      if (this.busquedas.value) {

        if (a.nombreCliente != null) {
          agregar = a.nombreCliente && a.nombreCliente.toLocaleLowerCase().indexOf(this.busquedas.value.toLocaleLowerCase()) > -1;
        } else {
          agregar = false;
        }

        agregar = agregar || (a.codigoProducto && a.codigoProducto.toLocaleLowerCase().indexOf(this.busquedas.value.toLocaleLowerCase()) > -1);
        agregar = agregar || ((a.valor).toString() && (a.valor).toString().toLocaleLowerCase().indexOf(this.busquedas.value.toLocaleLowerCase()) > -1);
      }

      if (this.checkAeroplanta.some(b => b.seleccionado))
        agregar = agregar && this.checkAeroplanta.some(
          b => (b.seleccionado && b.codigo === a.codigoAeroplanta));

      if (this.productosParaCheckear.some(b => b.seleccionado))
        agregar = agregar && this.productosParaCheckear.some(
          b => (b.seleccionado && b.codigo === a.codigoProducto));

      if (this.vigencias.some(e => e.seleccionado)) {

        let fechaHora = new Date(this.formatDate(this.modelFecha.year, this.modelFecha.month, this.modelFecha.day, this.modelHora.hour, this.modelHora.minute));

        agregar = agregar && (
          (this.vigencias[0].seleccionado == true
            && formatDate(a.fechaHasta, 'yyyy/MM/dd', 'en') >= formatDate(fechaHora, 'yyyy/MM/dd', 'en') && formatDate(a.fechaDesde, 'yyyy/MM/dd', 'en') <= formatDate(fechaHora, 'yyyy/MM/dd', 'en'))
          ||
          (this.vigencias[1].seleccionado == true
            && formatDate(a.fechaHasta, 'yyyy/MM/dd', 'en') < formatDate(fechaHora, 'yyyy/MM/dd', 'en')
          )
          ||
          (this.vigencias[2].seleccionado == true
            && formatDate(a.fechaDesde, 'yyyy/MM/dd', 'en') > formatDate(fechaHora, 'yyyy/MM/dd', 'en')
          )

        )
      }

      return agregar;
    });

  }

  filtrarByRSurtidor() {
    this.bonificacionyRecargoFiltrado = this.bonificacionyRecargo.filter(b => {
      let agregar = true;
      if (this.busquedas.value) {        
        agregar = agregar && ( b.expendedoras.some( 
          e => e.nombreExpendedora.toLocaleLowerCase().indexOf(this.busquedas.value.toLocaleLowerCase()) > -1
          ) 
        );        
      }

      if (this.expendedorasParaCheckear.some(e => e.seleccionado))
        agregar = agregar&& this.expendedorasParaCheckear.some(
          e => ( e.seleccionado && ( b.expendedoras.some( e2 => 
            e2.codigoExpendedora.toLocaleLowerCase().indexOf(e.codigo.toLocaleLowerCase()) > -1) 
          ) )
        );
      
      return agregar;
    });
  }

  onTildaByR(ByRSeleccionado: BonificacionRecargo) {
    if (!ByRSeleccionado.seleccionadoUi) {
      ByRSeleccionado.seleccionadoUi = true;
      this.bonificacionyRecargoEnviar.push(ByRSeleccionado);
    } else {
      const index: number = this.bonificacionyRecargoEnviar.indexOf(ByRSeleccionado);
      if (index !== -1) {
        this.bonificacionyRecargoEnviar.splice(index, 1);
      }
    }
  }

  getCheckedItemList(){
    var listaIdsSeleccionados = [];

    for (var i = 0; i < this.bonificacionyRecargoEnviar.length; i++) {
      listaIdsSeleccionados.push(this.bonificacionyRecargoEnviar[i].id);
    }

    return listaIdsSeleccionados;
  }

  formatDate(year, month, day, hour, minute): string {
    return year + '-' + month.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + '-' + day.toLocaleString(undefined, { minimumIntegerDigits: 2 })
      + 'T' + hour.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + minute.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':00';
  }

  onRefreshClick() {
    this.getBonificacionesyRecargos();
  }

  delegarNivelNativoByR(){
      let modalRef = this.modalService.open(ModalSeleccionarUsuarioComponent, { centered: true });
      modalRef.componentInstance.esDelegacion=true;      
      modalRef.componentInstance.nivelAprobacion = this.preciosService.getNivelUsuarioLogueado() != null ? this.preciosService.getNivelUsuarioLogueado().nivelNativo : null;
      modalRef.result.then((result: Usuario) => {
        this.messageService.showSuccessLongMessage("Se delego su nivel correctamente al usuario");
      }, () => { });
    }

  // getters

  get busquedas() {
    return this.searchForm.get("busqueda");
  }

  obtenerLeyendaEstado(estado){
    if(estado == TipoEstadoPrecio.Aprobado){
      return "Aprobado";
    }else if(estado == TipoEstadoPrecio.Rechazado){
      return "Rechazado";
    }else if(estado == TipoEstadoPrecio.Pendiente){
      return "Pendiente";
    }
  }

  obtenerLeyendasBonificacion(bonif: number) {
    if (bonif == 0) {
      return 'Bonificación';
    } else {
      return 'Recargo';
    }
  }

  desactivarCheckboxAprobarByrSurtidor(byr: BonificacionRecargo){
    if(byr.estado.estadoPrecio != TipoEstadoPrecio.Pendiente){
      return true;
    }

    var usuarioNiveles = this.preciosService.getNivelUsuarioLogueado();
    if(!usuarioNiveles) {
      usuarioNiveles = null;
      this.errorNiveles = true;
    }
 

    if(usuarioNiveles && usuarioNiveles.nivelNativo != null)
    {
      if (usuarioNiveles && usuarioNiveles.nivelNativo.maximoPorcentajeAprobacion == 100){
        return false;
      }
  
      if (usuarioNiveles && usuarioNiveles.nivelNativo.maximoPorcentajeAprobacion >= Math.abs(byr.valor)) {
        return false;
      }
    }
    

    var maxVariacionDelegado = 0;

    if (usuarioNiveles) {
      for (let nivelDelegado of usuarioNiveles.nivelesDelegados) {

        if(nivelDelegado.maximoPorcentajeAprobacion == 100){
          return false;
        }

        if (nivelDelegado.maximoPorcentajeAprobacion >= Math.abs(maxVariacionDelegado)) {
          maxVariacionDelegado = nivelDelegado.maximoPorcentajeAprobacion;
        }
      }
    }

    if (maxVariacionDelegado >= byr.valor) {
      return false;
    }

    return true;
  }

  hasRole(...roles: TipoUsuario[]) {
    return this.authService.hasRole(...roles);
  }

  
}
