import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'remitoPrefijo'
})
export class RemitoPrefijoPipe implements PipeTransform {

  transform(value: number): string {
    if (value !== undefined && value !== null) {
      return value.toString().padStart(4, '0');
    } else {
      return "";
    }
  }
}
