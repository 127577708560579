import { TipoActivoPrecio } from "../../enums/tipoActivoPrecio";
import { TipoEstadoPrecio } from "../../enums/tipoEstadoPrecio";

export class EstadoPrecio{
    id: string;
    responsable: string;
    fechaHora: string;
    activo: TipoActivoPrecio;
    estadoPrecio: TipoEstadoPrecio; // 0 indeterminado, 1 pendiente, 2 aprobado, 3 rechazado
    motivoRechazo: string;
}
