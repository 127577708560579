import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ComprobanteService } from 'src/app/facturacion/comprobante.service';
import { ExportExcelServiceService } from 'src/app/shared/exportar-excel/export-excel-service.service';
import { CierreCaja } from 'src/app/shared/models/cierreTurno/CierreCaja';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { Comprobante } from 'src/app/shared/models/facturacion/Comprobante';
import { PagoContado } from 'src/app/shared/models/facturacion/PagoContado';
import { Renglon } from 'src/app/shared/models/facturacion/Renglon';
import { VisorComprobanteComponent } from 'src/app/shared/visor-comprobante/visor-comprobante.component';
import { MessageService } from 'src/app/shell/message.service';

@Component({
  selector: 'app-detalle-cierre-caja',
  templateUrl: './detalle-cierre-caja.component.html',
  styleUrls: ['./detalle-cierre-caja.component.css']
})
export class DetalleCierreCajaComponent implements OnInit {
  @Input() cierreSeleccionado: CierreCaja;

  obteniendoFacturas: boolean = false;
  comprobante: Comprobante;
  totalDeFacturas: number = 0;
  diferencia: number = 0;


  constructor(private facturacionService: ComprobanteService, private messageService: MessageService,
    private activeModal: NgbModal, private excelService: ExportExcelServiceService) { }

  ngOnInit() {
    this.obteniendoFacturas = true;
    this.totalDeFacturas = 0;
    this.diferencia = 0;
    this.cierreSeleccionado.facturas.forEach((value, index) => {
      this.totalDeFacturas += this.cierreSeleccionado.facturas[index].total - this.totalCupones(this.cierreSeleccionado.facturas[index].pago);
    });
    this.diferencia = this.cierreSeleccionado.totalEfectivoIngresado - this.totalDeFacturas;
    this.obteniendoFacturas = false;
  }

  totalCupones(pago: PagoContado[]): number {
    let tot: number = 0;
    for (let p of pago) {
      tot += p.totalCupones;
    }
    return tot;
  }

  ngOnChanges() {
    this.ngOnInit();
  }

  mostrarFacrtura(factura: any) {
    factura.buscarComprobante = true;
    this.facturacionService.obtenerComprobantePorId(factura.id)
      .subscribe(result => {
        this.comprobante = result;
        factura.buscarComprobante = false;
        if (this.comprobante != null) {
          let modalRef = this.activeModal.open(VisorComprobanteComponent, { centered: true, size: 'lg' });
          modalRef.componentInstance.facturaObtenida = this.comprobante;
        } else {
          factura.buscarComprobante = false;
          this.messageService.showErrorMessage('Error al obtener la factura');
        }
      }, (err: ErrorModel) => {
        factura.buscarComprobante = false;
        this.messageService.showErrorMessage('Error al obtener la factura');
      });
  }

  obtenerProductos(renglones: Renglon[]){
    let string: string = '';
    for(let prod of renglones){
      string += prod.productoCodigo + '-' + prod.productoNombre + '. '
    }
    return string;
  }

  armarArrayExcel() {
    let excelCaja: ExcelCaja[] = [];
    for (let c of this.cierreSeleccionado.facturas) {
      var item = new ExcelCaja();
      item.Cliente = c.cliente;
      item.Factura = c.prefijo + '-' + c.numeroComprobante;
      item.Fecha = c.fechaEmision;
      item.PagoConTarjeta = this.totalCupones(c.pago);
      item.PagoEfectivo = c.pago[0].totalPago - this.totalCupones(c.pago);
      item.Producto = this.obtenerProductos(c.renglones);
      item.Usuario = c.responsable;
      excelCaja.push(item);
    }
    return excelCaja;
  }

  exportar() {
    this.excelService.exportAsExcelFile(this.armarArrayExcel(), 'Cierre de caja ' + this.cierreSeleccionado.fechaHora + ' Responsable: ' + this.cierreSeleccionado.responsable);
  }

  print(): void {
    var divToPrint = document.getElementById('invoicingPrint');
    var htmlToPrint = '' +
      '<style type="text/css">' +
      'table, table td, table th  {' +
      'border:1px solid #000;' +
      'text-align: center;' +
      'padding:0.5em;' +
      '}' +
      'button{' +
      'display: none;' +
      '}' +
      'label  {' +
      'margin-top: 0.5cm;' +
      'display: inline-block;' +
      '}' +
      '#noPrint {' +
      'display: none;' +
      '}' +
      '</style>';
    htmlToPrint += divToPrint.outerHTML;
    let newWin = window.open("");
    newWin.document.write(htmlToPrint);
    newWin.print();
    newWin.close();
  }

}

export class ExcelCaja {
  Cliente: string;
  Factura: string;
  Producto: string;
  Fecha: string;
  PagoConTarjeta: number;
  PagoEfectivo: number;
  Usuario: string;
}
