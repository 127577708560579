<nav class="navbar navbar-light bg-light navbar-expand-lg">
  <form class="form-inline flex-fill" id="formularioFiltros">
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <ul class="navbar-nav me-auto flex-row">
      <div class="nav-item" [formGroup]="searchForm">
        <input id="busquedaFormControl" type="text" class="form-control input-sm" placeholder="Búsqueda"
          formControlName="busqueda" (input)="filtrarByR()" placeholder="Buscar" />
      </div>
      <div class="nav-item">
        <div ngbDropdown autoClose="outside">
          <button class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2" id="dropdownForm1"
            ngbDropdownToggle>Estados</button>
          <div ngbDropdownMenu aria-labelledby="dropdownForm1">
            <div class="px-4 py-3">
              <div *ngFor="let estado of estados">
                <div class="form-check">
                  <input type="checkbox" class="form-check-input" id="{{ 'dropdownCheck' + estado.nombre}}"
                    [(ngModel)]="estado.seleccionado" name="{{estado.nombre}}" >
                  <label class="form-check-label text-nowrap" for="{{ 'dropdownCheck' + estado.nombre}}">
                    {{ estado.nombre}}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- </div> -->

      <div class="nav-item">
        <div ngbDropdown autoClose="outside">
          <button class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2" id="dropdownForm1"
            ngbDropdownToggle>Producto</button>
          <div ngbDropdownMenu aria-labelledby="dropdownForm1">
            <div class="px-4 py-3">
              <div *ngFor="let prod of productosParaCheckear">
                <div class="form-check">
                  <input type="checkbox" class="form-check-input" id="{{ 'dropdownCheck' + prod.codigo}}"
                    [(ngModel)]="prod.seleccionado" name="{{prod.codigo}}" >
                  <label class="form-check-label text-nowrap" for="{{ 'dropdownCheck' + prod.codigo}}">
                    {{ prod.nombre}} - {{prod.codigo}}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="nav-item">
        <div ngbDropdown autoClose="outside">
          <button class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2" id="dropdownForm1"
            ngbDropdownToggle>Aeroplanta</button>
          <div ngbDropdownMenu aria-labelledby="dropdownForm1">
            <div class="px-4 py-3">
              <div *ngFor="let aeroplanta of checkAeroplanta">
                <div class="form-check">
                  <input type="checkbox" class="form-check-input" id="{{ 'dropdownCheck' + aeroplanta.codigo}}"
                    [(ngModel)]="aeroplanta.seleccionado" name="{{aeroplanta.codigo}}" >
                  <label class="form-check-label text-nowrap" for="{{ 'dropdownCheck' + aeroplanta.codigo}}">
                    {{ aeroplanta.codigo}}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div ngbDropdown autoClose="outside">
        <button class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2" id="dropdownForm2" ngbDropdownToggle> Vigencias al
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownForm2">
          <div class="px-4 py-3">
            <div *ngFor="let vigencia of vigencias">
              <div class="form-check">
                <input type="checkbox" class="form-check-input" id="{{ 'dropdownCheck' + vigencia.nombre}}"
                  [(ngModel)]="vigencia.seleccionado" name="{{vigencia.nombre}}" >
                <label class="form-check-label text-nowrap" for="{{ 'dropdownCheck' + vigencia.nombre}}">
                  {{ vigencia.nombre}}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="">
        <div class="input-group">
          <input type="date" class="form-control" name="modelFecha" [(ngModel)]="modelFecha" placeholder="aaaa-mm-dd" name="dt" ngbDatepicker class="form-control form-control-rounded"
            #d="ngbDatepicker" ngbTooltip="Seleccione una fecha" #searchDate readonly>

          <div class="input-group-append">
            <button id="botonFecha" type="button" class="input-group-btn btn btn-secondary" (click)="d.toggle()">
              <i class="fa fa-calendar" aria-hidden="true"></i></button>
          </div>
        </div>
      </div>


      <button class="btn btn-primary ms-2" [disabled]="esperandoCarga" (click)="onRefreshClick()">
        <i class="fa fa-refresh fa-1x me-1" ngbTooltip="Actualizar lista"></i> Actualizar busqueda
      </button>
    </ul>
  </form>
</nav>

<div class="row mt-1">
  <div class="col-md-12">
    <div *ngIf="habilitarBotones" class="ms-2 float-end">
      <button type="button" class="btn btn-outline-primary" [disabled]="esperandoCarga" (click)="importarByR()">Importar ByR</button>
    </div>

    <div *ngIf="false" class="ms-2 float-end">
      <button type="button" class="btn btn-outline-primary" [disabled]="esperandoCarga" (click)="agregarBonificacionORecargoProducto()">Nueva ByR producto</button>
    </div>

    <div *ngIf="habilitarBotones" class="ms-2 float-end">
      <button type="button" class="btn btn-outline-primary" [disabled]="esperandoCarga" (click)="agregarBonificacionORecargoSurtidor()">Nueva ByR surtidor</button>
    </div>
  </div>
</div>

<form class="mb-3">
  <div class="col col-md-12">
    <div class="spinner-container" *ngIf="esperandoCarga">
      <ngx-spinner name="spinnerGrafico" bdOpacity=0.3bd
        Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
        <p style="color: white">{{messageByR}}</p>
      </ngx-spinner>
    </div>    
    
    <div id="tablaDetalle" class="tabla-precios" *ngIf="!esperandoCarga ">

    <ngb-pagination (pageChange)="onPageChange($event)" [collectionSize]="collectionSize" [maxSize]="10" [(page)]="page"
      [pageSize]="pageSize" *ngIf="this.bonificacionyRecargoFiltrado!=null && this.bonificacionyRecargoFiltrado.length > 0" 
      class="d-flex justify-content-center">
    </ngb-pagination>

    <div class="alert alert-warning" *ngIf="!actualizandoByr && this.bonificacionyRecargo.length == 0">No se obtuvieron bonificaciones o recargos para el criterio seleccionado.</div>

    <table id="tabla" class="table table-sm table-hover" *ngIf="this.bonificacionyRecargo.length > 0">
      <thead class="text-center letra">
        <tr>
          <th scope="col"> Estado </th>
          <th scope="col"> Cliente </th>
          <th scope="col"> Producto </th>
          <th scope="col"> Aeroplanta </th>
          <th scope="col"> Surtidor </th>
          <th scope="col"> Fecha vigencia</th>
          <th scope="col"> Valor </th>
          <th scope="col"> Tipo </th>
          <th scope="col"> Solicitado </th>
          <th scope="col"> Aprobado </th>
          <th scope="col"> Motivo </th>
          <th scope="col"> Activo </th>
        </tr>
      </thead>
      <tbody class="text-center letra">
        <tr *ngFor="let p of bonificacionyRecargoFiltrado; let i = index">

          <td>{{ this.obtenerLeyendaEstado(bonificacionyRecargoFiltrado[i].estado) }}</td>

          <td class="text-center">
              <button *ngIf="p.clientes!=null" (click)="this.verClientes(p.clientes)" class="btn" placement="bottom" ngbTooltip="Ver Clientes" container="body">
                <i class="fa fa-eye" aria-hidden="true"></i>
              </button>
              <div *ngIf="p.clientes===null">--</div>
          </td>

          <td class="text-center">
            <button *ngIf="p.productos!=null" (click)="this.verProductos(p.productos)" class="btn" placement="bottom" ngbTooltip="Ver Productos" container="body">
              <i class="fa fa-eye" aria-hidden="true"></i>
            </button>
            <div *ngIf="p.productos===null">--</div>
          </td>

          <td class="text-center">
            <button *ngIf="p.codigosAeroplantas!=null" (click)="this.verAeroplantas(p.codigosAeroplantas)" class="btn" placement="bottom" ngbTooltip="Ver Aeroplantas" container="body">
              <i class="fa fa-eye" aria-hidden="true"></i>
            </button>
            <div *ngIf="p.codigosAeroplantas===null">--</div>
          </td>

          <td class="text-center">
            <button *ngIf="p.expendedoras!=null" (click)="this.verSurtidores(p.expendedoras)" class="btn" placement="bottom" ngbTooltip="Ver Surtidores" container="body">
              <i class="fa fa-eye" aria-hidden="true"></i>
            </button>
            <div *ngIf="p.expendedoras===null">--</div>
          </td>

          <td *ngIf="p.expendedoras==null">
            {{bonificacionyRecargoFiltrado[i].fechaDesde | date: 'dd/MM/yyyy'}} -
            {{bonificacionyRecargoFiltrado[i].fechaHasta | date: 'dd/MM/yyyy'}}
          </td>
          
          <td *ngIf="p.expendedoras!=null">
          </td>

          <td> {{bonificacionyRecargoFiltrado[i].valor != null?bonificacionyRecargoFiltrado[i].valor: "--"}}</td>

          <td>{{ this.obtenerLeyendasBonificacion(bonificacionyRecargoFiltrado[i].tipo) }}</td>

          <td> {{bonificacionyRecargoFiltrado[i].solicitadoPor != null?bonificacionyRecargoFiltrado[i].solicitadoPor:"--"}}</td>

          <td > {{bonificacionyRecargoFiltrado[i].aprobadoPor != null?bonificacionyRecargoFiltrado[i].aprobadoPor:"--"}}</td>

          <td class="text-center">
            <button *ngIf="p.motivo!=null" (click)="this.verMotivos(p.motivo)" class="btn" placement="bottom" ngbTooltip="Ver Motivo" container="body">
              <i class="fa fa-eye" aria-hidden="true"></i>
            </button>
            <div *ngIf="p.motivo===null">--</div>
          </td>

          <td class="justify-content-center">
            <div style="cursor: pointer;" class="form-check form-switch">
              <input class="form-check-input"[checked]="bonificacionyRecargoFiltrado[i].activo" type="checkbox"
                id="{{bonificacionyRecargoFiltrado[i].id}}" (click)="desactivarBoR(bonificacionyRecargoFiltrado[i], i)">
              <label style="cursor: pointer;" class="form-check-label" for="{{bonificacionyRecargoFiltrado[i].id}}"></label>
            </div>
          </td>            
        </tr>
      </tbody>
    </table>

      <div class="row">
        <div class="col-md-3" style="padding-top: 1em;">
        </div>              

        <ngb-pagination (pageChange)="onPageChange($event)" [collectionSize]="collectionSize" [maxSize]="10" [(page)]="page"
          [pageSize]="pageSize" *ngIf="this.bonificacionyRecargoFiltrado!=null && this.bonificacionyRecargoFiltrado.length > 0" 
          class="col-md-6 d-flex justify-content-center">
        </ngb-pagination>
      </div>
    </div>
  </div>
</form>
