import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { EstadoCliente } from 'src/app/shared/enums/EstadoCliente';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SaldoCliente } from 'src/app/shared/models/controlCredito/SaldoCliente';
import { ControlCreditoService } from '../control-credito.service';
import { UsuarioService } from 'src/app/account/usuario.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { MessageService } from 'src/app/shell/message.service';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { TipoUsuario } from '../../shared/enums/tipoUsuario';
@Component({
  selector: 'app-modal-actualizar-estado',
  templateUrl: './modal-actualizar-estado.component.html',
  styleUrls: ['./modal-actualizar-estado.component.scss']
})
export class ModalActualizarEstadoComponent implements OnInit {

  @Input() cliente: SaldoCliente;
  observaciones: string;
  cambiarEstadoForm: FormGroup;
  enviando: boolean = false;
  TipoUsuario = TipoUsuario;
  constructor(public controlCreditoService: ControlCreditoService, public authService: AuthService, public formBuilder: FormBuilder, public activeModal: NgbActiveModal,
    public usuarioService: UsuarioService, private spinner: NgxSpinnerService, public messageService: MessageService) { }

  ngOnInit(): void {
    this.crearFormulario();
  }

  crearFormulario() {
    this.cambiarEstadoForm = this.formBuilder.group({
      observacionesFormControl: [""]
    });
  }

  cambiarEstadoCliente() {
    let date = new Date();
    this.enviando = true;
    this.spinner.show('spinnerEstado');
    let responsable = {
      nombresCompletos: this.usuarioService.getNombresCompletosUsuario(),
      dni: this.usuarioService.getDniUsuario(),
      email: this.usuarioService.getEmailUsuario()
    }
    let jsonEstado = {
      responsable: JSON.stringify(responsable),
      fechaHora: date,
      estadoCliente: this.cliente.ultimoEstado.estadoCliente,
      observaciones: this.observacionesFormControl.value
    }
    if(this.cliente.ultimoEstado.estadoCliente == EstadoCliente.Bloqueado) {
      jsonEstado.estadoCliente = EstadoCliente.Habilitado;
      this.controlCreditoService.putEstadoCuenta(jsonEstado, this.cliente.clienteId).subscribe(clienteEstado => {
        this.cliente.ultimoEstado = clienteEstado;
        this.spinner.hide('spinnerEstado');
        this.enviando = false;
        this.activeModal.close();
      }, (err: ErrorModel) => {
        this.messageService.showErrorMessage('Hubo un error al obtener los datos. ' + err.description);
      })
    } else {
      jsonEstado.estadoCliente = EstadoCliente.Bloqueado
      this.controlCreditoService.putEstadoCuenta(jsonEstado, this.cliente.clienteId).subscribe(clienteEstado => {
        this.cliente.ultimoEstado = clienteEstado;
        this.spinner.hide('spinnerEstado');
        this.enviando = false;
        this.activeModal.close();
      }, (err: ErrorModel) => {
        this.messageService.showErrorMessage('Hubo un error al obtener los datos. ' + err.description);
      })
    }
  }

  get observacionesFormControl() {
    return this.cambiarEstadoForm.get('observacionesFormControl');
  }

}
