import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { UsuarioService } from 'src/app/account/usuario.service';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { estadoSistemaSeg } from 'src/app/shared/enums/estadoSistemaSeg';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';
import { EstadoSistemaSeguridad } from 'src/app/shared/models/stock/EstadoSistemaSeguridad';
import { SistemaSeguridad } from 'src/app/shared/models/stock/SistemaSeguridad';
import { MessageService } from 'src/app/shell/message.service';
import { StockService } from '../../stock.service';
import { NuevoSistemaComponent } from './nuevo-sistema/nuevo-sistema.component';

@Component({
  selector: 'app-sistemas-seguridad',
  templateUrl: './sistemas-seguridad.component.html',
  styleUrls: ['./sistemas-seguridad.component.scss']
})
export class SistemasSeguridadComponent implements OnInit {

  TipoUsuario = TipoUsuario;

  sistemas: SistemaSeguridad[] = [];
  obteniendoSistemas: boolean = false;
  tablaSistemas: UntypedFormGroup;
  messageSistemas: string = 'C A R G A N D O    S I S T E M A S . . .';
  estSistema = estadoSistemaSeg;
  guardandoEstado: boolean[] = [];

  constructor(private authService: AuthService,
    private activeModal: NgbModal,
    private messageService: MessageService,
    private fb: UntypedFormBuilder,
    private spinner: NgxSpinnerService,
    private stockService: StockService,
    private usuarioService: UsuarioService) {
     this.tablaSistemas = this.fb.group({
       sistemasForm: new UntypedFormArray([])
     });
     }

  ngOnInit(): void {
    this.getSistemas();
    this.sistemas.forEach(() => this.guardandoEstado.push(false));
  }

  private setFormControls() {
    // reinicio el formulario
    this.tablaSistemas.setControl('sistemasForm', this.fb.array([]));
    this.sistemas.map((s, i) => {
      const control = new UntypedFormControl('', [Validators.required]);
      if(s.ultimoEstado != null){
        control.setValue(s.ultimoEstado.estado);
      }
      (this.tablaSistemas.controls.sistemasForm as UntypedFormArray).push(control);
    });
  }

  getSistemas(){
    this.spinner.show('spinnerSistemas');
    this.obteniendoSistemas = true;
    this.stockService.obtenerSistemasSeguridad(this.usuarioService.getAeroplantaActual().codigoAeroplanta)
      .subscribe(result => {
        this.sistemas = result.filter(s => s.id != null && s.nombre != null && s.ultimoEstado != null)
        this.setFormControls();
        this.spinner.hide('spinnerSistemas');
        this.obteniendoSistemas = false;
      }, () => {
        this.spinner.hide('spinnerSistemas');
        this.obteniendoSistemas = false;
      })

  }

  obtenerEstado(est) {
    return Number(this.estSistema[est]);
  }

  keysEstados(): Array<string> {
    var keys = Object.keys(estadoSistemaSeg);
    keys = keys.slice(keys.length / 2);
    return keys;
  }

  compareEstados(n1: any, n2: any): boolean {  
    return n1 && n2 ? n1.id == n2.id : n1 == n2;
  }

  mismoEstado(s: SistemaSeguridad): boolean {
    return s.ultimoEstado.estado == this.tablaSistemas.controls.sistemasForm.value[this.sistemas.findIndex(x => x.id == s.id)];
  }

  cambiarEstadoSistema(s: SistemaSeguridad, i: number, nuevoEstado){
    this.guardandoEstado[i] = true;
    let sistemaId = s.id;
    let est = new EstadoSistemaSeguridad();
    est.id = Guid.EMPTY;
    est.estado = nuevoEstado;
    est.responsable = this.usuarioService.getNombresCompletosUsuario();
    est.fechaHoraUltimoCambio = "2020-01-15T21:00:00.00";
    est.fechaHoraFS = null;
    est.observaciones = null;
    est.autorizoFS = null;
    this.stockService.cambiarEstadoSistemaSeguridad(sistemaId, est)
      .subscribe(result => {
        this.messageService.showSuccessMessage('El estado del sistema de seguridad fue actualizado con exito.');
        this.sistemas[i].ultimoEstado.estado = nuevoEstado;
        this.guardandoEstado[i] = false;
      }, () => {
        this.messageService.showErrorMessage('Ocurrio un error, vuelva a intentar.');
        this.guardandoEstado[i] = false;
      })
  }

  hasRole(...roles: TipoUsuario[]) {
    return this.authService.hasRole(...roles);
  }

  abrirModal(editar: boolean, i: number, sistema?: SistemaSeguridad) {
    
    let nuevoEstado;
    let cambioEstado = false;
    
    if(sistema != null) {
      nuevoEstado = this.tablaSistemas.controls.sistemasForm.value[this.sistemas.findIndex(x => x.id == sistema.id)];
    }

    if(!editar && sistema != null) {
      cambioEstado = true;
    }
    
    let modalRef = this.activeModal.open(NuevoSistemaComponent, { centered: true, backdrop: 'static' });
    modalRef.componentInstance.sistema = sistema;
    modalRef.componentInstance.editar = editar;
    modalRef.componentInstance.nuevoEstado = nuevoEstado;
    modalRef.componentInstance.cambioEstado = cambioEstado;
    modalRef.result.then(result => {
      if (result == true) {
        this.getSistemas();
      }
    }, () => {
      
    });

  }

}
