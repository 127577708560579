import { Guid } from 'guid-typescript';
import { Cliente } from '../despacho/Cliente';
import { TipoVenta } from '../../enums/tipoVenta';
import { Ubicacion } from '../despacho/Ubicacion';
import { CondicionVenta } from '../../enums/condicionVenta';
import { TipoEstadoPrecio } from '../../enums/tipoEstadoPrecio';
import { Area } from '../abm/Area';
import { Moneda } from '../abm/Moneda';

export class PrecioExcel {
    id: string;
    nivel: number;
    indice: number;
    total: number;
    responsable: string;
    numeroAeroplanta: string;

    filaExcel: string[];   
    observacion: string;
}