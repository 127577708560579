<div class="modal-header bg-light">
  <h4 class="modal-title">Reporte de aerovales</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close(false)"></button>
</div>
<div class="modal-body">
  <nav class="navbar navbar-light bg-light navbar-expand-lg">
    <form class="form-inline flex-fill">
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
        
      <ul class="navbar-nav nav pull-right">
        <li class="nav-item">
          <button (click)="descargarExcel()" class="btn btn-outline-success">Descargar</button>
        </li>
      </ul>
    </form>
  </nav>

  <div class="col container">
    <table class="table table-striped table-responsive">
      <thead>
        <tr>
          <th scope="col">
            <p class="text-center text-primary">
              Aerovale
            </p>
          </th>

          <th scope="col">
            <p class="text-center text-primary">
              Matrícula
            </p>
          </th>

          <th scope="col">
            <p class="text-center text-primary">
              Producto código
            </p>
          </th>

          <th scope="col">
            <p class="text-center text-primary">
              Producto nombre
            </p>
          </th>

          <th scope="col">
            <p class="text-center text-primary ">
              Unidad de medida
            </p>
          </th>

          <th scope="col">
            <p class="text-center text-primary">
              Cantidad
            </p>
          </th>

          <th scope="col">
            <p class="text-center text-primary">
              Tipo operación
            </p>
          </th>
          <th scope="col">
            <p class="text-center text-primary">
              Rancho
            </p>
          </th>
        </tr>
      </thead>

      <tbody>
          <tr *ngFor="let producto of aerovales">
            <th class="text-center">{{producto.prefijo}}-{{producto.numeroAerovale}}</th>
            <td class="text-center">{{producto.matricula}}</td>
            <td class="text-center">{{producto.codigoProducto}}</td>
            <td class="text-center">{{producto.nombreProducto}}</td>
            <td class="text-center"><p>{{unidadMedida[producto.unidadMedida]}}</p></td>
            <td class="text-center">{{producto.volumenNaturales}}</td>
            <td class="text-center">
              <p>{{tipoVuelo[producto.tipoVuelo]}}</p>
            </td>
            <td class="text-center">
              {{producto.rancho}}
            </td>
            <td></td>
          </tr>
      </tbody>
    </table>
  </div>
</div>