<div class="modal-header bg-light">
  <h4 class="modal-title">Aforadores de abastecedora/surtidor: {{this.nombreContenedor}}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()">
    
  </button>
</div>
<div class="modal-body">
  <div *ngIf="picos == null ||picos.length ==0">
    <div class="alert alert-warning">
      No hay aforadores asignados para la abastecedora actual.
    </div>
  </div>
  <table class="table table-striped table-hover"
    *ngIf="this.picos != null && this.picos.length > 0">
    <thead class="text-center">
      <tr>
        <th>Nombre</th>
        <th>Aforador</th>
        <th>Tipo</th>
        <th>Principal</th>
      </tr>
    </thead>
    <tbody class="text-center">
      <tr *ngFor="let p of this.picos">
        <td>{{ p.nombre }}</td>
        <td>{{ p.aforadorActual }}</td>
        <td>{{ obtenerNombrePico(p.tipoPico) }}</td>
        <td *ngIf="p.porDefecto"><i class="fa fa-check"></i></td>
        <td *ngIf="!p.porDefecto"><i class="fa fa-times"></i></td>
      </tr>
    </tbody>
  </table>
</div>
<div class="modal-footer bg-light">
  <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss('Close clicked')">Cancelar</button>
</div>