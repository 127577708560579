<div class="spinner-container" *ngIf="obteniendoDatos">
  <ngx-spinner name="spinnerGrafico" bdOpacity=0.3bd
      Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
      <p style="color: white">C a r g a n d o . . . </p>
  </ngx-spinner>
</div>

<div *ngIf="!obteniendoDatos">
  <div class="modal-header bg-light">
    <h4 class="modal-title">Historial de Envios</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="this.activeModal.dismiss()"></button>
  </div>

  <div class="modal-body">
    <div class="col-12" id="contenedor" *ngIf="this.historialNotificacionDTO != null">      
        <table class="table table-striped table-hover">
          <thead class="text-center">
            <th>Fecha/Hora</th>
            <th>Destinatario</th>
            <th>Cuerpo</th>            
          </thead>
          <tbody class="text-center">
            <tr *ngFor="let h of historialNotificacionDTO">
              <td>{{h.fechaHora | date: "d'/'MM'/'y' 'hh:mm a"}}</td>
              <td>{{h.destinatarios  }}</td>
              <td>{{h.cuerpo}}</td>
            </tr>            
          </tbody>
        </table>      
    </div>
  </div>
</div>
