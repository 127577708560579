<nav class="navbar navbar-light bg-light navbar-expand-lg">
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    
    <!--Buscar cuentas -->
    <div class="form-inline me-2">
      <input id="busquedaFormControl" type="text" class="form-control" placeholder="Cuenta/Descripción/Matrícula"
        [formControl]="busquedaFormControl" (input)="filtrarAutorizaciones()"/>
    </div>

    <ul class="navbar-nav me-auto">
      <!-- desde -->
      <li class="nav-item me-2">
        <div class="input-group">
          <input type="date" [ngModel]="model" class="fechaFormControlDesdeHasta" placeholder="aaaa-mm-dd"
            (ngModelChange)="model = $event" name="dt" ngbDatepicker #d="ngbDatepicker" value="model"
            class="form-control form-control-rounded" #searchDate required readonly>
          <div class="input-group-append">
            <button id="botonDesde" type="button" class="input-group-btn btn btn-secondary" (click)="d.toggle()">
              <i class="fa fa-calendar" aria-hidden="true"></i></button>
          </div>
        </div>
      </li>
      <!-- hasta -->
      <li class="nav-item">
        <div class="input-group">
          <input type="date" class="fechaFormControlDesdeHasta" [ngModel]="model1" placeholder="aaaa-mm-dd" name="dp"
            value="model1" (ngModelChange)="model1 = $event" class="form-control form-control-rounded" ngbDatepicker
            #h="ngbDatepicker" #searchDate required readonly>
          <div class="input-group-append">
            <button id="botonHasta" type="button" class="input-group-btn btn btn-secondary" (click)="h.toggle()"><i
                class="fa fa-calendar" aria-hidden="true"></i></button>
          </div>
        </div>
      </li>
      <li class="nav-item">
          <button 
            [disabled]="fechaDesdeMayorQueHasta()==true" 
            (click)="changeDesdeHasta()" 
            class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2" type="button">
            <i class="fa fa-search" aria-hidden="true"></i>
          </button>
      </li>
    </ul>
  </div>
</nav>
<div class="col col-md-12">
  <div class="spinner-container" *ngIf="this.obteniendoAutorizaciones">
    <ngx-spinner name="spinnerGrafico" bdOpacity=0.3bd
      Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
      <p style="color: white">C a r g a n d o . . . </p>
    </ngx-spinner>
  </div>
  
  <ngb-alert type="warning" *ngIf="!this.obteniendoAutorizaciones && this.autorizaciones.length==0 && fechaDesdeMayorQueHasta()==false"> 
    No se encontraron autorizaciones para el rango de fechas seleccionado.
  </ngb-alert>  

  <ngb-alert type="danger" [dismissible]="false" *ngIf="fechaDesdeMayorQueHasta()==true">
    La fecha inicial debe ser anterior a la fecha final.
  </ngb-alert>

  <div class="row" *ngIf="!this.obteniendoAutorizaciones && this.autorizaciones.length>0 && fechaDesdeMayorQueHasta()==false">
    <div class="card-body">
      <table class="table table-hover" *ngIf="this.autorizacionesFiltradas.length>0">
        <thead class="text-center">
          <td><b>Fecha</b></td>
          <td><b>Usuario solicitante</b></td>
          <td><b>Cuenta descripción</b></td>
          <td><b>Matricula</b></td>            
          <td *ngIf="this.solapaAutorizacion==TipoSolapaAutorizacion.Pendientes"><b>Selección</b></td>
          <ng-container *ngIf="this.solapaAutorizacion!=TipoSolapaAutorizacion.Pendientes">
            <td><b>Usuario aprobador</b></td>
            <td><b>Fecha aprobación</b></td>            
            <td><b>Estado</b></td>
          </ng-container>
        </thead>
        <tbody class="text-center letra">
          <tr *ngFor="let a of this.autorizacionesFiltradas let i = index"
              [ngClass]="{ 'aprobados': a.estado == EstadoAutorizacion.Aprobado, 'rechazados' : a.estado == EstadoAutorizacion.Rechazado }">
              <td>{{ a.fechaHora | date: "d'/'MM'/'y' 'hh:mm a" }}</td>
              <td>{{ a.usuarioSolicitador }}</td>
              <td>{{ a.ctA_SGC + " " + a.cuentaDescripcion }}</td>
              <td>{{ a.matricula }}</td>            
              <td *ngIf="this.solapaAutorizacion==TipoSolapaAutorizacion.Pendientes">
                <button type="button" (click)="gestionarAutorizaciones(a, i, EstadoAutorizacion.Aprobado)" class="btn btn-success me-2"
                  [disabled]="aceptandoAutorizaciones">
                  <span *ngIf="!aceptandoAutorizaciones || aceptandoAutorizaciones && autorizacionSeleccionada!=i">Aceptar</span>
                  <div *ngIf="autorizacionSeleccionada==i && aceptandoAutorizaciones" class="d-flex align-items-center">
                    <div class="spinner-border text-light spinner-border-sm" role="status"></div>
                    <span>Aceptando...</span>
                  </div>
                </button>
      
                <button type="button" (click)="gestionarAutorizaciones(a, i, EstadoAutorizacion.Rechazado)" class="btn btn-danger"
                  [disabled]="rechazandoAutorizaciones">
                  <span *ngIf="!rechazandoAutorizaciones || rechazandoAutorizaciones && autorizacionSeleccionada!=i">Rechazar</span>
                  <div *ngIf="autorizacionSeleccionada==i && rechazandoAutorizaciones" class="d-flex align-items-center">
                    <div class="spinner-border text-light spinner-border-sm" role="status"></div>
                    <span>Rechazando...</span>
                  </div>
                </button>                  
              </td>
              <ng-container *ngIf="this.solapaAutorizacion!=TipoSolapaAutorizacion.Pendientes">
                <td>{{ a.usuarioAprobador }}</td>
                <td>{{ a.fechaHoraAprobacion | date: "d'/'MM'/'y' 'hh:mm a" }}</td>            
                <td>{{ this.obtenerTipoEstado(a.estado) }}</td>            
              </ng-container>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
