import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { AerovaleDetalleComponent } from 'src/app/despacho/aerovale-detalle/aerovale-detalle.component';
import { AerovalesService } from 'src/app/despacho/aerovales.service';
import { AerovaleDetalle } from 'src/app/shared/models/despacho/AerovaleDetalle';
import { Remito } from 'src/app/shared/models/facturacion/Remito';

@Component({
  selector: 'app-modal-ver-remitos-contado',
  templateUrl: './modal-ver-remitos-contado.component.html',
  styleUrls: ['./modal-ver-remitos-contado.component.scss']
})
export class ModalVerRemitosContadoComponent implements OnInit {
  @Input() remitosContado:Remito[];

  ordenamientoForm = new UntypedFormGroup({
    clienteAerovaleFormControl: new UntypedFormControl()
  });

  movimientosCierre: Remito[] = [];
  reporteMovimientosFiltrado: Remito[] = [];
  reporteMovimientosAnulados: Remito[] = [];
  obteniendoAerovale: boolean = false;
  exportando: boolean = false;


  constructor(public modal: NgbActiveModal,
    private spinner: NgxSpinnerService,
    private aerovaleService: AerovalesService,
    private activeModal: NgbModal) { }


  ngOnInit(): void {
    this.reporteMovimientosFiltrado = [];
    this.reporteMovimientosAnulados = [];
    this.clienteAerovaleFormControl.setValue(0);
    this.ordenar(0);
  }

  ordenar(i: number) {
    if (i == 0) {
      this.remitosContado.sort((a, b) => { if (a.remitoPrefijo + a.remitoNumero > b.remitoPrefijo + b.remitoNumero) { return 1 } else { return -1 } });
    } else {
      this.remitosContado.sort((a, b) => { if (a.clienteNombre < b.clienteNombre) { return 1 } else { return -1 } });
    }
  }

  mostrarAerovale(i: number) {
    this.obteniendoAerovale = true;
    this.remitosContado[i].booleanAux = true;
    this.aerovaleService.getAerovalePorIdyTipo(this.remitosContado[i].aerovaleId)
      .subscribe(result => {
        let aerovaleDetalle: AerovaleDetalle = result;
        this.remitosContado[i].booleanAux = false;
        this.obteniendoAerovale = false;
        let modalRef = this.activeModal.open(AerovaleDetalleComponent, { size: "lg", centered: true })
        modalRef.componentInstance.aerovaleDetalle = aerovaleDetalle;
      });
  }

  get clienteAerovaleFormControl() {
    return this.ordenamientoForm.controls.clienteAerovaleFormControl;
  }

}
