export enum TipoVehiculoMatriculas{
    Aeronave = 0, 
    Automotor = 1
}

export class VehiculoDTO {
    id:string;
    activo:boolean;
    matricula:string;    
    tipoVehiculo:number;
    limitado:boolean
    codigoProducto: string;
    ctas_SGC: string[];
    nuevo:boolean;

    _editado: boolean;
}