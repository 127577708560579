import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {HandleError, HttpErrorHandler} from '../http-error-handler.service';
import {Aeropuerto} from '../shared/models/despacho/Aeropuerto';
import {Observable} from 'rxjs';
import {catchError, tap, map} from 'rxjs/operators';
import {Pais} from '../shared/models/despacho/Pais';
import { Provincia } from '../shared/models/facturacion/Provincia';
import { Ciudad } from '../shared/models/facturacion/Ciudad';

@Injectable({
  providedIn: 'root'
})
export class ConfiguracionService {

  baseUrl = environment.apiServer.baseUrl;
  private aerovalesUrl = this.baseUrl + '/ConfiguracionesV1/';
  private handleError: HandleError;

  constructor(private http: HttpClient, private httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError('Configuracion');
  }

  getPaises(): Observable<Pais[]> {
    return this.http.get<Pais[]>(this.aerovalesUrl + 'Paises')
      .pipe(
        map(a => a.map<Pais>(b => Object.assign(new Pais(), b))),
        catchError(this.handleError('getPaises', []))
      );
  }

  getProvincias(): Observable<Provincia[]> {
    return this.http.get<Provincia[]>(this.aerovalesUrl + 'Provincias')
      .pipe(
        map(a => a.map<Provincia>(b => Object.assign(new Provincia(), b))),
        catchError(this.handleError('getProvincias', []))
      );
  }

  getProvinciasPorPais(codigo: string): Observable<Provincia[]> {
    return this.http.get<Provincia[]>(this.aerovalesUrl + codigo + '/Provincias')
      .pipe(
        map(a => a.map<Provincia>(b => Object.assign(new Provincia(), b))),
        catchError(this.handleError('getProvinciasPorPais', []))
      );
  }

  getCiudadPorProvincia(codigo: string): Observable<Ciudad[]> {
    return this.http.get<Ciudad[]>(this.aerovalesUrl + codigo + '/Ciudades')
      .pipe(
        map(a => a.map<Ciudad>(b => Object.assign(new Ciudad(), b))),
        catchError(this.handleError('getCiudadesPorPais', []))
      );
  }

  getCiudad(): Observable<Ciudad[]> {
    return this.http.get<Ciudad[]>(this.aerovalesUrl + 'Ciudades')
      .pipe(
        map(a => a.map<Ciudad>(b => Object.assign(new Ciudad(), b))),
        catchError(this.handleError('getCiudades', []))
      );
  }
}
