<button type="button" class="btn btn-outline-primary me-2 mt-2"
    [disabled]="descargandoDatos" (click)="descargarDatos('Clientes')">
    <span *ngIf="!descargandoClientes">Clientes</span>          
        <div *ngIf="descargandoClientes" class="d-flex align-items-center">
            <div class="spinner-border text-blue spinner-border-sm" role="status"></div>
            <span>Descargando Clientes...</span>
        </div>    
</button>
<button type="button" class="btn btn-outline-primary me-2 mt-2"
    [disabled]="descargandoDatos" (click)="descargarDatos('Cuentas')">
    <span *ngIf="!descargandoCuentas">Cuentas</span>
        <div *ngIf="descargandoCuentas" class="d-flex align-items-center">
            <div class="spinner-border text-blue spinner-border-sm" role="status"></div>
            <span>Descargando Cuentas...</span>
        </div>
</button>        
<button type="button" class="btn btn-outline-primary me-2 mt-2"
    [disabled]="descargandoDatos" (click)="descargarDatos('Direcciones')">
    <span *ngIf="!descargandoDirecciones">Direcciones</span>
        <div *ngIf="descargandoDirecciones" class="d-flex align-items-center">
            <div class="spinner-border text-blue spinner-border-sm" role="status"></div>
            <span>Descargando Direcciones...</span>
        </div>
</button>
<button type="button" class="btn btn-outline-primary me-2 mt-2"
    [disabled]="descargandoDatos" (click)="descargarDatos('Cbus')">
    <span *ngIf="!descargandoCbus">CBU</span>
        <div *ngIf="descargandoCbus" class="d-flex align-items-center">
            <div class="spinner-border text-blue spinner-border-sm" role="status"></div>
            <span>Descargando CBU...</span>
        </div>
</button>
<button type="button" class="btn btn-outline-primary mt-2"
    [disabled]="descargandoDatos" (click)="descargarDatos('Iibb')">
    <span *ngIf="!descargandoIibb">IIBB</span>
        <div *ngIf="descargandoIibb" class="d-flex align-items-center">
            <div class="spinner-border text-blue spinner-border-sm" role="status"></div>
            <span>Descargando IIBB...</span>
        </div>
</button>
      
    
      
    
    
      
    