<div class="modal-header bg-light">
    <h5 class="modal-title ">Cierre seleccionado: {{fechaCierre | date: "dd/MM/yyy HH:mm a"}}</h5>
    <button type="button" class="btn-close" aria-label="Close" data-dismiss="modal" (click)="activeModal.close(false)">
      
    </button>
</div>

<div class="modal-body">

    <div class="col-12" id="contenedor">
        <label class="ms-1"><b>Comparativa: {{titulo}}</b></label>

        <div>
        <table class="table table-striped table-hover">
            <thead class="text-center">
            <th>Tanque</th>
            <th>Existencia anterior</th>
            <th>Entradas</th>
            <th>Salidas</th>
            <th>Existencia en libros</th>
            <th>Existencia real</th>
            <th>Deficit/Superavit</th>               
            </thead>
            <tbody class="text-center">
            <tr *ngFor="let c of comparativas">
                <td>{{c.nombreContenedor}}</td>
                <td>{{c.existenciaAnterior | number: '1.0-0'}}</td>
                <td>{{c.entradas | number: '1.0-0'}}</td>
                <td>{{c.salidas | number: '1.0-0'}}</td>
                <td>{{c.existenciaFinal | number: '1.0-0'}}</td>
                <td>{{c.existenciaReal | number: '1.0-0'}}</td>
                <td>{{c.diferencia | number: '1.0-0'}}</td>
            </tr>
            </tbody>
        </table>
        <button class="btn btn-outline-success" (click)="exportarComparativas(titulo)"><i class="fa fa-file-excel-o" aria-hidden="true"></i>
            Exportar información {{titulo}}
        </button>

        </div>
    </div>
</div>

<div class="modal-footer bg-light">
    <button class="btn btn-secondary" id="cancelar" (click)="activeModal.close(false)" data-dismiss="modal">Cancelar</button>
</div>