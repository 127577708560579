import { Component, OnInit } from '@angular/core';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-facturacion-index',
  templateUrl: './facturacion-index.component.html',
  styleUrls: ['./facturacion-index.component.css']
})
export class FacturacionIndexComponent implements OnInit {
  TipoUsuario = TipoUsuario;

  constructor(
    private authService: AuthService, 
    private router: Router) { }

  ngOnInit() {
    //this.router.navigate(['/facturacion/parametrosV2']);
  }

  hasRole(...roles: TipoUsuario[]) {
    return this.authService.hasRole(...roles);
  }
}
