<div class="modal-header bg-light">
  <h4 class="modal-title" class="text-center"><b>Aeroplantas</b></h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()">
    
  </button>
</div>

<div class="modal-body">
<table *ngIf="this.aeroplantas!=null && this.aeroplantas.length > 0" class="table table-striped table-hover">
  <thead>
    <td class="text-center"><b>Código</b></td>
    <td class="text-center"><b>Ciudad</b></td>
  </thead>
  <tbody>
    <tr *ngFor="let a of aeroplantas; let i = index">
      <td class="text-center">{{  a.codigoAeroplanta }}</td>
      <td class="text-center"> {{a.nombre}}</td>
   </tr>
  </tbody>
</table>
</div>





