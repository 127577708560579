import { Component, forwardRef, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  UntypedFormGroup,
  UntypedFormBuilder,
  ControlValueAccessor,
  Validators,
  NG_VALIDATORS,
  UntypedFormControl
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/shared/shared.service';
import { CalculosService } from 'src/app/shared/calculos.service';


export interface AerovaleCombustibleFormValues {
  aforadorInicialFormControl: string;
  aforadorFinalFormControl: string;
  cantidadDespachadaFormControl: string;
  volumen15GradosFormControl: string;
}

@Component({
  selector: 'aerovale-combustible-form',
  templateUrl: './aerovale-combustible.component.html',
  styleUrls: ['./aerovale-combustible.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AerovaleCombustibleComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AerovaleCombustibleComponent),
      multi: true
    }
  ]
})

export class AerovaleCombustibleComponent implements ControlValueAccessor, OnDestroy {
  @Output() formularioValido = new EventEmitter();
  @Output() objetCombustibleForm = new EventEmitter();
  @Output() envioDensidad = new EventEmitter();
  @Input() esValidaDensidad: boolean;
  @Input() esDensidadMin: any;
  @Input() esDensidadMax: any;

  form: UntypedFormGroup;
  subscriptions: Subscription[] = [];

  constructor(private formBuilder: UntypedFormBuilder,
              private calculoService: CalculosService, 
              private sharedService: SharedService) {
    console.log("a");
  }

  ngOnInit() {
    this.crearFormulario();

    this.inicializarComponente();
  }

  crearFormulario(){
    this.form = this.formBuilder.group(
      {
        aforadorInicialFormControl: ['', [Validators.required]],
        aforadorFinalFormControl: ['', [Validators.required]],
        cantidadDespachadaFormControl: ['', Validators.required],
        temperaturaFormControl:['', [ Validators.required, this.temperaturaValidator]],
        densidadFormControl:['', Validators.required],
        volumen15GradosFormControl:['', Validators.required],
      }
    );

    this.form.setValidators(this.aerovaleValidator);
    this.objetCombustibleForm.emit(this.form);
  }

  aerovaleValidator(group: UntypedFormGroup): any{
    if (group) {
      if(group.get("aforadorInicialFormControl").value >= group.get("aforadorFinalFormControl").value){
        return { aforadorInicialMayorAforadorFinal: true };
      }

      return null;
    }
  }

  temperaturaValidator(control: UntypedFormControl){
    var temperatura = control.value;

    if (temperatura > 100) {
      return { temperaturaFueraDeRango: true };
    }

    if (temperatura <= -50) {
      return { temperaturaFueraDeRango: true };
    }

    return null;
  }

  formatDensidad(event) {
    if (event.charCode == 8 || event.charCode == 0) return null;
  
    if (event.charCode >= 48 && event.charCode <= 57) {
      this.densidad.setValue(this.sharedService.formatDensidad(event));
      this.validarDensidad(this.densidad.value);
    }
    this.litrosA15Grados();
    return false;
  }

  validarDensidad(densidad) {
    if (densidad != null) {
      this.envioDensidad.emit(densidad);
    }
  }

  inicializarComponente(){
    this.subscriptions.push(
      this.form.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      })
    );

    this.form.valueChanges.subscribe(val => {
      this.formularioValido.emit(this.form.valid);
    });
  }

  obtenerCantidadDespachada() {
    let res: number = this.aforadorFinalFormControl.value - this.aforadorInicialFormControl.value;
    if (this.aforadorFinalFormControl.value < this.aforadorInicialFormControl.value) { res = 0; }
    this.cantidadDespachadaFormControl.setValue(res);
  }

  litrosA15Grados() {
    let res:number=this.calculoService.convertirLitrosNaturalesEn15Grados(this.temperaturaFormControl.value,this.densidad.value,this.aforadorInicialFormControl.value,this.aforadorFinalFormControl.value);
    this.volumen15GradosFormControl.setValue(res);
  }
  
  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  onlyDecimalPositivo(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46;
  }

  /* -------------------------------------------------------------------------------------------------------- */

  get aforadorInicialFormControl() {
    return this.form.controls.aforadorInicialFormControl;
  }

  get aforadorFinalFormControl() {
    return this.form.controls.aforadorFinalFormControl;
  }

  get cantidadDespachadaFormControl() {
    return this.form.controls.cantidadDespachadaFormControl;
  }

  get volumen15GradosFormControl() {
    return this.form.controls.volumen15GradosFormControl;
  }

  get temperaturaFormControl() {
    return this.form.controls.temperaturaFormControl;
  }

  get densidad() {
    return this.form.controls.densidadFormControl;
  }

  /* -------------------------------------------------------------------------------------------------------- */

  get value(): AerovaleCombustibleFormValues {
    return this.form.value;
  }

  set value(value: AerovaleCombustibleFormValues) {
    this.form.setValue(value);
    this.onChange(value);
    this.onTouched();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }

    if (value === null) {
      this.form.reset();
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  validate(_: UntypedFormControl) {
    return this.form.valid ? null : { aerovaleCombustible: { valid: false } };
  }
}
