<nav class="navbar navbar-light bg-light">
  <form class="form-inline flex-fill">
    <div>
        <button [disabled]="authService.hasRole(TipoUsuario.FINANZAS)" 
            type="button" class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2" (click)="nuevoEmisor()">
            Agregar
        </button>
    </div>
    <!-- <input type="search" class="form-control ms-sm-3 col-md-3" placeholder="Buscar emisor" (input)="buscarEmisor()" /> -->
  </form>
</nav>
<div class="col col-md-12">
  <div class="spinner-container" *ngIf="esperandoCarga">
    <ngx-spinner name="spinnerGrafico" bdOpacity=0.3bd
      Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
      <p style="color: white">C a r g a n d o . . . </p>
    </ngx-spinner>
  </div>
  <div class="mb-3 row" *ngIf="!esperandoCarga">
    <div class="col-4">
      <div class="list-group py-3 menu-izq">
        <div *ngFor="let emi of emisor; let i=index">
          <button type="button" [class.active]="" class="list-group-item list-group-item-action"
            (click)="getEmisorPorId(emi.id)">
            <div class="d-flex w-100 justify-content-between">
              <p>{{ emi.razonSocial}} </p>
            </div>
          </button>
        </div>
      </div>
    </div>
    <div id="tablaInformacion" class="col-sm-8 mt-3">
      <div>
        <app-set-emisor [emisorSeleccionado]="emisorSeleccionado"></app-set-emisor>
      </div>
      <br>
    </div>
  </div>
</div>
