<div class="modal-header">
  <h5>Emisión de nota de crédito.</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="this.modal.dismissAll()"></button>
</div>
<div class="modal-body">
  <div *ngIf="!existe && !msgError">
    <p>
      Al confirmar la nota de crédito, la factura seleccionada será anulada,
      se emitirá una nota de crédito identica a la factura y los remitos
      relacionados seran liberados para que puedan volver a ser facturados.
    </p>
    <br>
    <ngb-alert type="danger" [dismissible]="false" *ngIf="msgError">
      Se produjo un error al emitir la nota de credito.
    </ngb-alert>
  </div>
  <div *ngIf="existe">
    <p>La nota de crédito se emitio de manera exitosa.</p>
    <div class="row justify-content-center">
      <div class="row justify-content-center recuadroRemitos border rounded border-info" id="remitosLiberados">
        <ul class="list-unstyled p-1">
          <li class="fw-bold">Remitos liberados: {{this.remitosLiberados.length}}</li>
          <li *ngFor="let remito of this.remitosLiberados" class="text-center fw-bold">{{remito.prefijo}} -
            {{remito.numeroComprobante}}</li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer justify-content-md-center">
  <button id="crear" class="btn btn-outline-primary" type="button" [disabled]="emitiendoNota || existe" (click)="emitirNotaCredito(facturaObtenida)">
    <span *ngIf="!emitiendoNota">Emitir</span>
    <div *ngIf="emitiendoNota" class="d-flex align-items-center">
      <div class="spinner-border text-light spinner-border-sm" role="status"></div>
      <span>Emitiendo...</span>
    </div>
  </button>
  <button (click)="this.modal.dismissAll()" class="btn btn-outline-secondary" type="button">Cancelar</button>
</div>
