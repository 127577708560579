import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { UsuarioService } from 'src/app/account/usuario.service';
import { TipoPedido } from 'src/app/shared/enums/TipoPedido';
import { TipoSolapaVueloTams } from 'src/app/shared/enums/tipoSolapaVueloTams';
import { PedidoDetalle } from 'src/app/shared/models/despacho/PedidoDetalle';
import { VuelosTamsDTO } from 'src/app/shared/models/despacho/VuelosTamsDTO';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { SharedService } from 'src/app/shared/shared.service';
import { MessageService } from 'src/app/shell/message.service';
import { NuevoPedidoComponent } from '../../nuevo-pedido/nuevo-pedido.component';
import { PedidoDetalleComponent } from '../../pedido-detalle/pedido-detalle.component';
import { PedidoService } from '../../pedido.service';

@Component({
  selector: 'app-vuelos-tams',
  templateUrl: './vuelos-tams.component.html',
  styleUrls: ['./vuelos-tams.component.scss']
})
export class VuelosTamsComponent implements OnInit {

  busquedaFormControl = new UntypedFormControl();
  tipoMovimientoFormControl = new UntypedFormControl();

  obteniendoDatos : boolean = false;
  change$: Subscription;

  solapaVuelosTams: number;
  tipoVuelosTams= TipoSolapaVueloTams;

  // pedidos
  pedidoDetalle: PedidoDetalle;
  obteniendoPedido: boolean = false;
  pedidoSeleccionado: number = null;  

  tipoMovimientos = [{codigo:"A", nombre:"Arribos"},{codigo:"D", nombre:"Partidas"}];
  vuelos: VuelosTamsDTO[]=[];  
  vuelosFiltrados: VuelosTamsDTO[]=[];
  
  constructor(
    private spinner: NgxSpinnerService,
    private modal: NgbModal,
    private pedidosService: PedidoService,
    private usuarioService: UsuarioService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    sharedService: SharedService
  ) { 
    
    this.route.queryParams.subscribe(params => {
      this.solapaVuelosTams = Number(params.solapaVuelosTams);
    });
    //this.solapaVuelosTams= Number(TipoSolapaVueloTams.Arribos);
    this.change$ = sharedService.aeroplantaSeleccionada.subscribe(_=>{
      this.cambiarDeAeroplanta();
    });
    
  }

  ngOnInit(): void { 
    console.log("!martin");
    if (this.solapaVuelosTams == TipoSolapaVueloTams.Partidas)
    {
      this.tipoMovimiento.setValue("D");
    }else
    {
      this.tipoMovimiento.setValue("A"); 
    }
    this.obtenerVuelosTams();
  }

  ngOnDestroy(): void {
    this.change$.unsubscribe(); 
  }

  cambiarDeAeroplanta() {
    this.ngOnInit();
  }

  getCodigoAeroplanta(){
    return this.usuarioService.getAeroplantaActual().codigoAeroplanta;
  }

  ngOnChanges() {
    this.obtenerVuelosTams()
  }

  obtenerVuelosTams() {
    this.mostrarSpinner();
    console.log("0 es Arribos y 1 es Partidas: ", this.tipoMovimiento.value);
    this.pedidosService.getObtenerPedidosTams(this.tipoMovimiento.value, this.getCodigoAeroplanta())
      .subscribe(resp => {
        this.vuelos = resp;   
        this.vuelosFiltrados = this.vuelos;
        this.ocultarSpinner();
      }, 
      (err: ErrorModel) => {        
        this.ocultarSpinner();
        this.vuelos=[];
        this.vuelosFiltrados=[];
        this.messageService.showErrorMessage(err.description);
      });
  }

  filtrarVuelos() {
    this.vuelosFiltrados = this.vuelos.filter(a => {
      let agregar = true;
      if (this.busqueda.value) {
        agregar = a.aerolinea && a.aerolinea.toLocaleLowerCase().indexOf(this.busqueda.value.toLocaleLowerCase()) > -1;        
        agregar = agregar || (a.nro && a.nro.toLocaleLowerCase().indexOf(this.busqueda.value.toLocaleLowerCase()) > -1);
        agregar = agregar || (a.matricula && a.matricula.toLocaleLowerCase().indexOf(this.busqueda.value.toLocaleLowerCase()) > -1);
      }

      if (this.busqueda.value == '') {
        this.vuelosFiltrados = this.vuelos;
      }  

      return agregar;
    });    
  }

  mostrarSpinner() {
    this.obteniendoDatos = true;
    this.spinner.show('spinnerGrafico');
  }
  
  ocultarSpinner() {
    this.obteniendoDatos = false;
    this.spinner.hide('spinnerGrafico');
  }

  crearPedido(item:any) {
    const modalRef = this.modal.open(NuevoPedidoComponent, { centered: true, size: 'lg', windowClass: 'modalNuevoPedido', backdrop: "static" });    
    modalRef.componentInstance.tipoModalPedido = TipoPedido.Tams;
    modalRef.componentInstance.vueloTams = item;
    modalRef.result.then(result => {
      if (result) {
        this.ngOnChanges();
      }
    });
  }

  /** Visualizar Pedidos */
  mostrarPedido(id:string, i:number) {
    this.pedidoSeleccionado=i;
    this.obteniendoPedido=true;
    const modalRef = this.modal.open(PedidoDetalleComponent, { centered: true, size: "lg", windowClass: 'modalPedidoDetalle', backdrop: "static"})
    modalRef.componentInstance.pedidoId = id;    
    modalRef.componentInstance.visualizarPedido = true;  
    this.pedidoSeleccionado=null;
    this.obteniendoPedido=false;
  }

  /** getters */
  get busqueda() {
    return this.busquedaFormControl;
  }
  get tipoMovimiento() {
    return this.tipoMovimientoFormControl;
  }

}
