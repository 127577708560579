<div class="card">
  <div class="card-header">
    <label><b>Fecha/Hora de reimputación</b></label>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close(false)">
      
    </button>
  </div>
  <div class="card-body" [formGroup]="reimputarForm">
    <!--HORA REIMPUTACION-->
    <div class="mb-3 col-md-12">
      <div class="mb-3 row">
        <div class="col-md-6">
          <label for="fechaInicioDespachoFormControl" class="col-form-label" style="display: block;">
            Fecha y hora a reimputar:
          </label>
        </div>
        <div #fechaHora class="col-md-6">
          <div class="input-group mb-2">
            <input id="fechaInicioDespachoFormControl" type="date" class="form-control"
              formControlName="fechaReimputarFormControl" placeholder="aaaa-mm-dd" name="dp"
              title="Campo obligatorio." [(ngModel)]="modelFechaInicial" ngbDatepicker #d="ngbDatepicker" readonly />
            <div class="input-group-append">
              <button type="button" class="input-group-btn btn btn-secondary" (click)="d.toggle()">
                <i class="fa fa-calendar" aria-hidden="true"></i>
              </button>
            </div>
          </div>
          <ngb-timepicker [(ngModel)]="timeInicial" [meridian]="true" formControlName="horaReimputarFormControl"
            [readonlyInputs]="true">
          </ngb-timepicker>
          <ngb-alert type="danger" [dismissible]="false" *ngIf="fechaDesdeMayorQueHasta()==true && this.tipoImputacion == 0">
            La fecha a imputar debe estar entre {{this.fechaHoraMinimaCerrado | date: "d'/'MM'/'y' 'hh:mm a"}} y {{this.fechaHoraMaximaCerrado | date: "d'/'MM'/'y' 'hh:mm a"}}
          </ngb-alert>
          <ngb-alert type="danger" [dismissible]="false" *ngIf="fechaDesdeMayorQueHasta()==true && this.tipoImputacion == 1">
            La fecha a imputar debe ser superior a {{this.fechaHoraMinimaAbierto | date: "d'/'MM'/'y' 'hh:mm a"}}
          </ngb-alert>
        </div>
      </div>
    </div>
  </div>
  <div class="card-footer">
    <button class="btn btn-primary me-2" (click)="this.aceptarFecha()" [disabled]="fechaDesdeMayorQueHasta()==true">Aceptar</button>
    <button class="btn btn-secondary" (click)="this.activeModal.close(false)">Cancelar</button>
  </div>
</div>