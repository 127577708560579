<div class="card add-margin">
  <h4 class="card-header card-title ">
    <div class="text-center"> {{ editando ? 'Edición de bonificación o recargo de producto' : 'Nueva bonificación o recargo de producto.'}}
      <button type="button" class="btn-close" aria-label="Close" (click)="this.activeModal.dismiss()">
      </button>
    </div>
  </h4>
  <div class="card-body">
    <form [formGroup]="form">

      <!--CLIENTE-->
      <div *ngIf="this.editando===false" class="mb-3 col-12">
        <div class="mb-3 row">
          <div class="col-md-4">
            <label for="clienteFormControl" class="col-form-label">*Cliente:</label>
          </div>
          <div class="col-md-8">
            <div class="input-group mb-2">
              <input id="clienteFormControl" type="text" class="form-control" formControlName="clienteFormControl"
                [inputFormatter]="formatter" [(ngModel)]="modelCliente" [resultFormatter]="formatter"
                title="Campo obligatorio, debe seleccionar o ingresar un cliente." [ngbTypeahead]="search"
                (selectItem)="selectCustomer($event)" />
                <span><button class="btn-primary" (click)="asignarCliente()">+</button></span>
            </div>
            <loading-text-spinner *ngIf="cargandoClientes" [error]="errorClientes" message="Obteniendo clientes..."
              color="primary">
            </loading-text-spinner>
            <div *ngIf="clienteFormControl.invalid && clienteFormControl.errors.required &&(clienteFormControl.dirty || clienteFormControl.touched)"
              class="alert alert-danger">
              Requerido
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="this.editando===false && this.listaDeClientesSeleccionados.length!=0" class="col-md-12 ms-2 me-2">
        <div><b>Clientes asociados a la bonificacion/recargo:</b></div>
      </div>

    <div *ngIf="this.editando===false && this.listaDeClientesSeleccionados != null && listaDeClientesSeleccionados.length > 0 " class="row">
      <div class="col-md-12 row ms-2 me-2 mt-2" *ngFor="let e of this.listaDeClientesSeleccionados let i=index">
        <div class="col-md-9">{{e.razonSocial}}</div>
        <div class="col-sm-3"><button type="button" (click)="desasignarCliente(i)"
            class="btn btn-danger btn-sm col-sm-12"><i class="fa fa-trash col-sm-12" aria-hidden="true"></i></button>
        </div>
      </div>
    </div>
    <div class="mb-3 row" *ngIf="this.listaDeClientesSeleccionados != null && this.listaDeClientesSeleccionados.length == 0" class="row">
      <div class="col-md-9 offset-md-3" style="color:red">
        <b>No hay clientes seleccionados.</b>
       </div>
    </div>
    <br>

      <!--Aeroplanta-->
      <div *ngIf="this.editando===false" class="mb-3 col-12">
        <div class="mb-3 row">
          <div class="col-md-4">
            <label for="aeroplantaFormControl" class="col-form-label">*Aeroplanta:</label>
          </div>
          <div class="col-md-8">
            <div class="input-group mb-2">
              <input id="aeroplantaFormControl" type="text" class="form-control" formControlName="aeroplantaFormControl"
                [inputFormatter]="formatterAeroplanta" [(ngModel)]="modelAeroplanta"
                [resultFormatter]="formatterAeroplanta"
                title="Campo obligatorio, debe seleccionar o ingresar un cliente." [ngbTypeahead]="searchAeroplanta"
                (selectItem)="selectAeroplanta($event)" />
                <span><button class="btn-primary" (click)="asignarAeroplanta()">+</button></span>
            </div>
            <loading-text-spinner *ngIf="cargandoAeroplantas" [error]="errorAeroplantas"
              message="Obteniendo aeroplantas..." color="primary">
            </loading-text-spinner>
            <div *ngIf="aeroplantaFormControl.invalid && aeroplantaFormControl.errors.required &&(aeroplantaFormControl.dirty || aeroplantaFormControl.touched)"
              class="alert alert-danger">
              Requerido
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="this.editando===false && this.listaDeClientesSeleccionados.length!=0" class="col-md-12 ms-2 me-2">
        <div><b>Aeroplantas asociadas a la bonificacion/recargo:</b></div>
      </div>

      <div *ngIf="this.editando===false && this.listaDeAeroplantasSeleccionadas != null && this.listaDeAeroplantasSeleccionadas.length > 0" class="row">
        <div class="col-md-12 row ms-2 me-2 mt-2" *ngFor="let e of this.listaDeAeroplantasSeleccionadas let i= index">
          <div class="col-md-9">{{e.nombre}}</div>
          <div class="col-sm-3"><button type="button" (click)="desasignarAeroplanta(i)"
              class="btn btn-danger btn-sm col-sm-12"><i class="fa fa-trash col-sm-12" aria-hidden="true"></i></button>
          </div>
        </div>
      </div>
      <div *ngIf="this.listaDeAeroplantasSeleccionadas != null && this.listaDeAeroplantasSeleccionadas.length == 0" class="row">
        <div class="col-md-9 offset-md-3" style="color:red">
          <b>No hay aeroplantas seleccionadas.</b>
         </div>
      </div>
      <br>

      <!--PRODUCTO-->
      <div *ngIf="this.editando===false" class="mb-3 col-12">
        <div class="mb-3 row">
          <div class="col-md-4">
            <label for="productoFormControl" class="col-form-label">*Producto:</label>
          </div>
          <div class="col-md-8">
            <div class="input-group mb-2">
              <input id="productoFormControl" type="text" class="form-control" formControlName="productoFormControl"
                [inputFormatter]="formatterProducto" [(ngModel)]="modelProducto" [resultFormatter]="formatterProducto"
                title="Campo obligatorio, debe seleccionar o ingresar un cliente." [ngbTypeahead]="searchProducto"
                (selectItem)="selectProducto($event)" />
                <span><button class="btn-primary" (click)="asignarProducto()">+</button></span>
            </div>
            <loading-text-spinner *ngIf="cargandoProductos" [error]="errorProductos" message="Obteniendo productos..."
              color="primary">
            </loading-text-spinner>
            <div *ngIf="productoFormControl.invalid && productoFormControl.errors.required &&(productoFormControl.dirty || productoFormControl.touched)"
              class="alert alert-danger">
              Requerido
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="this.editando===false && this.listaDeProductosSeleccionados.length!=0" class="col-md-12 ms-2 me-2">
        <div><b>Productos asociados a la bonificacion/recargo:</b></div>
      </div>

      <div *ngIf="this.editando===false && this.listaDeProductosSeleccionados != null && this.listaDeProductosSeleccionados.length > 0" class="row">
        <div class="col-md-12 row ms-2 me-2 mt-2" *ngFor="let e of this.listaDeProductosSeleccionados let i= index">
          <div class="col-md-9">{{e.nombreProducto}}</div>
          <div class="col-sm-3"><button type="button" (click)="desasignarProducto(i)"
              class="btn btn-danger btn-sm col-sm-12"><i class="fa fa-trash col-sm-12" aria-hidden="true"></i></button>
          </div>
        </div>
      </div>
      <div *ngIf="this.listaDeProductosSeleccionados != null && this.listaDeProductosSeleccionados.length == 0" class="row">
        <div class="col-md-9 offset-md-3" style="color:red">
          <b>No hay Productos seleccionados.</b>
         </div>
      </div>
      <br>

      <!--TIPO-->
      <div *ngIf="this.editando===false" class="mb-3 col-12">
        <div class="mb-3 row">
          <div class="col-md-4">
            <label for="productoFormControl" class="col-form-label">Tipo:</label>
          </div>
          <div class="col-md-8">
            <div class="input-group mb-2">
              <select id="tipoFormControl" class="form-select"
                ngbTooltip="Seleccione el tipo." formControlName="tipoFormControl" [value]="this.seleccionadaBoR ? this.seleccionadaBoR.tipo : 0">
                <option value=0>
                  Bonificación
                </option>
                <option value=1>
                  Recargo
                </option>
              </select>
            </div>
            <div *ngIf="tipo.invalid && tipo.errors.required &&(tipo.dirty || tipo.touched)"
              class="alert alert-danger">
              Requerido
            </div>
          </div>
        </div>
      </div>

      <!--VALOR-->
      <div class="mb-3 col-12">
        <div class="mb-3 row">
          <div class="col-md-4">
            <label for="valorFormControl" class="col-form-label">*Valor:</label>
          </div>
          <div class="col-md-8">
            <div class="input-group mb-2">
              <input tupe="text" class="form-control" formControlName="valorFormControl" (keypress)="onlyDecimalNumberKey($event)"/>
            </div>
            <div *ngIf="valor.invalid && valor.errors.required &&(valor.dirty || valor.touched)"
              class="alert alert-danger">
              Requerido
            </div>
          </div>
        </div>
      </div>

      <!-- fecha y hora Inicio -->
      <div class="mb-3 col-md-12">
        <div class="mb-3 row">
          <label for="fechaFormControl" class="col-sm-4 col-form-label" style="display: block;">
            Fecha inicio:
          </label>
          <div #fechaHora class="col-sm-7">
            <div class="input-group mb-2">
              <input type="date" class="form-control" formControlName="fechaFormControl" placeholder="aaaa-mm-dd"
                name="dp" title="Campo obligatorio." [(ngModel)]="model" ngbDatepicker #d="ngbDatepicker"
                (ngModelChange)="validarFecha($event)" readonly>
              <div class="input-group-append">
                <button type="button" class="input-group-btn btn btn-secondary" (click)="d.toggle()"><i
                    class="fa fa-calendar" aria-hidden="true"></i></button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- fecha y hora FIN -->
      <div class="mb-3 col-md-12">
        <div class="mb-3 row">
          <label for="fechaFinFormControl" class="col-sm-4 col-form-label">Fecha fin: </label>
          <div #fechaHoraVuelo class="col-sm-7">
            <div class="input-group mb-2">
              <input type="date" class="form-control" formControlName="fechaFinFormControl" placeholder="aaaa-mm-dd"
                name="dp" title="Campo obligatorio." [(ngModel)]="modelVuelo" ngbDatepicker #dv="ngbDatepicker"
                (ngModelChange)="validarFechaFin()" readonly>
              <div class="input-group-append">
                <button type="button" class="input-group-btn btn btn-secondary" (click)="dv.toggle()"><i
                    class="fa fa-calendar" aria-hidden="true"></i></button>
              </div>
            </div>
            <div *ngIf="
                    (fechaFin.invalid && fechaFin.errors.fechaFin && (fechaFin.dirty || fechaFin.touched))"
              class="alert alert-danger">La fecha fin debe ser menor que la incial</div>
            <div
              *ngIf="(fechaFin.invalid && fechaFin.errors.fechaValida && (fechaFin.dirty || fechaFin.touched))"
              class="alert alert-danger">La fecha no es válida</div>
          </div>
        </div>
      </div>

      <!-- Motivo-->
      <div class="mb-3 col-md-12">
        <div class="mb-3 row">
          <label for="motivoFormControl" class="col-sm-4 col-form-label">*Motivo: </label>
          <div #fechaHoraVuelo class="col-sm-7">
            <div class="input-group mb-2">
              <textarea formControlName="motivoFormControl" style="background-color:white;color:black;" rows="4" cols="70">
              </textarea>
            </div>
            <div *ngIf="motivo.invalid && motivo.errors.required &&(motivo.dirty || motivo.touched)"
              class="alert alert-danger">
              Requerido
            </div>
          </div>
        </div>
      </div>



      <small class="text-danger">*Campos obligatorios.</small>
    </form>
  </div>
  <div class="card-footer d-flex justify-content-center">
    <button class="btn btn-primary me-2" type="button" [disabled]="this.guardadoBoR && this.camposCompletos()===false" (click)="guardarBoR()">
      <span *ngIf="!guardadoBoR">Guardar</span>
      <div *ngIf="guardadoBoR" class="d-flex align-items-center">
        <div class="spinner-border text-light spinner-border-sm" role="status"></div>
        <span>Guardando...</span>
      </div>
    </button>
    <button type="button" class="btn btn-secondary" [disabled]="guardadoBoR"
      (click)="activeModal.dismiss()">Cancelar</button>
  </div>
</div>
