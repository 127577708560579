import { Component, OnInit, Inject, LOCALE_ID } from '@angular/core';
import { NgbActiveModal, NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Producto } from 'src/app/shared/models/abm/Producto';
import { ProductoService } from '../../producto/producto.service';
import { Aeroplanta } from 'src/app/shared/models/despacho/Aeroplanta';

import { TipoVenta } from 'src/app/shared/enums/tipoVenta';
import { Area } from 'src/app/shared/models/abm/Area';
import { Segmento } from 'src/app/shared/models/abm/Segmento';
import { CondicionVenta } from 'src/app/shared/enums/condicionVenta';
import { ProductosService } from 'src/app/despacho/productos.service';
import { FiltroPrecio } from 'src/app/shared/models/abm/FiltroPrecio';
import { Moneda } from 'src/app/shared/models/abm/Moneda';
import { Precio } from 'src/app/shared/models/abm/Precio';
import { formatDate } from '@angular/common'
import { Cliente } from 'src/app/shared/models/abm/Cliente';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { MessageService } from 'src/app/shell/message.service';
import { Ubicacion } from 'src/app/shared/models/abm/Ubicacion';
import { PreciosService } from 'src/app/precios/precios.service';

@Component({
  selector: 'app-modal-consulta',
  templateUrl: './modal-consulta.component.html',
  styleUrls: ['./modal-consulta.component.css']
})
export class ModalConsultaComponent implements OnInit {

  consultaFormGroup = new UntypedFormGroup({
    productoFormControl: new UntypedFormControl(),
    clienteFormControl: new UntypedFormControl(),
    aeroplantaFormControl: new UntypedFormControl(),
    ventaFormControl: new UntypedFormControl(),
    entregaFormControl: new UntypedFormControl(),
    condicionFormControl: new UntypedFormControl(),
    monedaFormControl: new UntypedFormControl(),
    areaFormControl: new UntypedFormControl(),
    segmentoFormControl: new UntypedFormControl(),
    fechaFormControl: new UntypedFormControl()
  })

  modelDesde: NgbDateStruct;
  productos: Producto[];
  clientes: Cliente[];
  aeroplantas: Aeroplanta[];
  tipoVenta = TipoVenta;
  condicionVenta = CondicionVenta;
  moneda: Moneda[];
  precios: Precio[];
  productoCodigo: string;
  area: Area[];
  ubicacion: Ubicacion[];
  segmento: Segmento[];
  cuentaCliente: string;
  cargandoProductos: boolean = true;
  cargandoClientes: boolean = true;
  filtrarPrecios: FiltroPrecio;
  cargandoAeroplantas: boolean = true;
  cargandoAreas: boolean = true;
  cargandoSegmentos: boolean = true;
  cargandoUbicaciones: boolean = true;
  errorDestinos: boolean = false;
  fechaDesde: Date;
  sinCliente: Cliente = { id: "0", razonSocial: "Sin Cliente", cta_SGC: "0" }
  cargandoMonedas: boolean = true;
  modelCliente: any;
  modelProducto: any;

  constructor(public modal: NgbActiveModal,
    private productoService: ProductoService, 
    private precioService: PreciosService, 
    private calendar: NgbCalendar,
    private productosService: ProductosService,
    @Inject(LOCALE_ID) public locale: string,
    private messageService: MessageService) { }

  ngOnInit() {
    this.getProductos();
    this.getClientes();
    this.getAeroplantas();
    this.getAreas();
    this.getSegmentos();
    this.getMonedas();
    this.getUbicaciones();
    this.modelDesde = this.calendar.getToday();
    this.fechaDesde = new Date(this.modelDesde.year, this.modelDesde.month - 1, this.modelDesde.day);
    this.filtrarPrecios = new FiltroPrecio();
    this.cliente.disable();
    this.ventas.disable();
    this.aeroplanta.disable();
    this.entregas.disable();
    this.condicionesVenta.disable();
    this.areas.disable();
    this.segmentos.disable();
  }

  keysTipoVenta(): Array<string> {
    var keys = Object.keys(this.tipoVenta);
    keys.shift();
    return keys.slice(keys.length / 2);
  }

  keysCondicionVenta() {
    var keys = Object.keys(this.condicionVenta);
    keys.shift();
    return keys.slice(keys.length / 2);
  }

  getMonedas() {
    this.productoService.obtenerMonedas()
      .subscribe(result => {
        console.log("Obteniendo Monedas.");
        this.moneda = result;
        this.cargandoMonedas = false;
      }, () => this.errorDestinos = true);
  }

  getProductos() {
    this.productoService.obtenerProductos()
      .subscribe(result => {
        console.log("Obteniendo productos");
        this.productos = result;
        this.cargandoProductos = false;
      }, () => this.errorDestinos = true);
  }

  getClientes() {
    this.productoService.getClientes()
      .subscribe(result => {
        console.log("Obteniendo Clientes");
        result.push(this.sinCliente)
        this.clientes = result;
        this.cargandoClientes = false;
      }, () => this.errorDestinos = true)
  }

  getAeroplantas() {
    this.precioService.obtenerTodasLasAeroplantas()
      .subscribe(result => {
        this.aeroplantas = result;
        this.cargandoAeroplantas = false;
      }, () => this.errorDestinos = true)
  }

  getAreas() {
    this.productoService.getAreas()
      .subscribe(result => {
        console.log("Obteniendo areas");
        this.area = result;
        this.cargandoAreas = false;
      }, () => this.errorDestinos = true);
  }

  getUbicaciones() {
    this.productoService.getUbicaciones()
      .subscribe(result => {
        console.log("Obteniendo Ubicaciones.");
        this.ubicacion = result;
        this.cargandoUbicaciones = false;
      }, () => this.errorDestinos = true)
  }

  getSegmentos() {
    this.productoService.getSegmentos()
      .subscribe(result => {
        console.log("Obteniendo segmentos");
        this.segmento = result;
        this.cargandoSegmentos = false;
      }, () => this.errorDestinos = true);
  }

  consultarPreciosBase() {

    this.filtrarPrecios.productoCodigo = this.productoCodigo;
    this.filtrarPrecios.monedaId = this.monedas.value;
    this.filtrarPrecios.fecha = this.formatDate(this.fechas.value.year, this.fechas.value.month, this.fechas.value.day,
      23, 59);

    if (this.areas.value == null || this.areas.value == "null") {
      this.filtrarPrecios.areaCodigo = null;
      if (this.areas.value == "null") {
        this.segmentos.enable();
      }
    } else {
      this.filtrarPrecios.areaCodigo = this.areas.value;
      this.segmentos.enable();
    }

    if (this.cliente.value == null || this.cliente.value == "0" || this.cuentaCliente == "0") {
      this.filtrarPrecios.clienteCta_SGC = null;
      if (this.cliente.value == "0" || this.cuentaCliente == "0") {
        this.condicionesVenta.enable();
      }
    } else {
      this.filtrarPrecios.clienteCta_SGC = this.cuentaCliente;
      this.condicionesVenta.enable();
    }

    if (this.aeroplanta.value == null || this.aeroplanta.value == "null") {
      this.filtrarPrecios.aeroplantaCodigo = null;
      if (this.aeroplanta.value == "null") {
        this.cliente.enable();
      }
    } else {
      this.filtrarPrecios.aeroplantaCodigo = this.aeroplanta.value;
      this.cliente.enable();
    }
    if (this.condicionesVenta.value == null) {
      this.filtrarPrecios.condicionVenta = 0;
    } else {
      this.filtrarPrecios.condicionVenta = parseInt(this.condicionesVenta.value);
      this.ventas.enable();
    }
    if (this.segmentos.value == null || this.segmentos.value == "null") {
      this.filtrarPrecios.segmentoCodigo = null;
      if (this.segmentos.value == "null") {
        this.entregas.enable();
      }
    } else {
      this.filtrarPrecios.segmentoCodigo = this.segmentos.value;
      this.entregas.enable();
    }

    if (this.ventas.value == null) {
      this.filtrarPrecios.tipoVenta = 0;
    } else {
      this.filtrarPrecios.condicionVenta = this.ventas.value;
      this.areas.enable();
    }
    if (this.entregas.value == null) {
      this.filtrarPrecios.direccionEntrega = null;
    } else {
      this.filtrarPrecios.direccionEntrega = this.entregas.value;
    }

    if (this.filtrarPrecios.productoCodigo != null && this.filtrarPrecios.monedaId != null) {
      this.aeroplanta.enable();
      this.productoService.consultarPrecioBase(this.filtrarPrecios)
        .subscribe(result => {
          console.log("Obteniendo filtro de precios");
          this.precios = result;
        }, () => this.messageService.showErrorMessage("Error al obtener el precio.")
        );
    }
  }

  formatter = (x: { razonSocial: string, cta_SGC: string }) => `${x.razonSocial} - ${x.cta_SGC}`;
  formatterProducto = (x: { nombreProducto: string, codigoProducto: string }) => `${x.nombreProducto} - ${x.codigoProducto}`;

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 || this.cargandoClientes ? [] :
        this.clientes.filter(v => this.containsString(this.cliente.value, v.cta_SGC, v.razonSocial)).slice(0, 10))
    )
  searchProducto = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 || this.cargandoProductos ? [] :
        this.productos.filter(v => this.containsString(this.producto.value, v.nombreProducto, v.codigoProducto)).slice(0, 10))
    )

  /**
   * Determina si una cadena se encuentra dentro de otra
   * @param searchValue Valor a buscar
   * @param searchIn Valores sobre los que buscar searchValue
   */
  containsString(searchValue: string, ...searchIn: string[]): boolean {
    const concat = searchIn.toString();
    return concat.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
  }
  selectCustomer(e: any): void {
    console.log('e:' + e.item);
    this.cliente.setValue(e.item.cta_SGC);
    this.cuentaCliente = e.item.cta_SGC;
    this.consultarPreciosBase();
  }

  selectProducto(e: any): void {
    console.log('e:' + e.item);
    this.producto.setValue(e.item.codigoProducto);
    this.productoCodigo = e.item.codigoProducto
    this.consultarPreciosBase();
  }


  formatDate(year: number, month: number, day: number, hour: number, minute: number): string {
    return formatDate(new Date(year, month - 1, day, hour, minute, 0), 'y-MM-ddTHH:mm:ss', this.locale);
  }

  get areas() {
    return this.consultaFormGroup.get('areaFormControl');
  }

  get ventas() {
    return this.consultaFormGroup.get('ventaFormControl');
  }

  get condicionesVenta() {
    return this.consultaFormGroup.get('condicionFormControl');
  }

  get monedas() {
    return this.consultaFormGroup.get('monedaFormControl');
  }

  get fechas() {
    return this.consultaFormGroup.get('fechaFormControl');
  }

  get producto() {
    return this.consultaFormGroup.get('productoFormControl');
  }

  get cliente() {
    return this.consultaFormGroup.get('clienteFormControl');
  }

  get aeroplanta() {
    return this.consultaFormGroup.get('aeroplantaFormControl');
  }

  get segmentos() {
    return this.consultaFormGroup.get('segmentoFormControl');
  }

  get entregas() {
    return this.consultaFormGroup.get('entregaFormControl');
  }
}
