import { NgModule } from "@angular/core";
import { Routes, RouterModule, Router, NavigationExtras } from "@angular/router";
import { Shell } from "../shell/shell.service";
import { authGuard } from "../core/authentication/auth.guard";
import { TipoUsuario } from "../shared/enums/tipoUsuario";
import { FacturacionIndexComponent } from "./facturacion-index/facturacion-index.component";
import { ImportacionPadronesComponent } from "./importacion-padrones/importacion-padrones.component";
import { ComprobanteComponent } from "./reportes/comprobante/comprobante.component";
import { ParametrosImpositivosIndexComponent } from "./parametros-impositivos-v2/parametros-impositivos-index.component";
import { RemitoComponent } from './Remitos/remito/remito.component';
import { AprobacionRefacturacionComponent } from "./aprobacion-refacturacion/aprobacion-refacturacion.component";
import { CorreccionPreciosComponent } from "./correccion-precios/correccion-precios.component";
import { HoldeoAeroplantaComponent } from "./holdeo/holdeo-aeroplanta/holdeo-aeroplanta.component";
import { HoldeoCuentasComponent } from "./holdeo/holdeo-cuentas/holdeo-cuentas.component";
import { HoldeoAerovalesComponent } from "./holdeo/holdeo-aerovales/holdeo-aerovales.component";
import { RefacturacionIndexComponent } from "./refacturacion-index/refacturacion-index.component";
import { DespachoRefacturacionComponent } from "./despacho-refacturacion/despacho-refacturacion.component";


import { HoldeoPrincipalComponent } from "./holdeo/holdeo-principal/holdeo-principal.component";
import { ReporteFacturasComponent } from "./reportes/reporte-facturas/reporte-facturas.component";
import { RevisionRefacturacionComponent } from "./revision-refacturacion/revision-refacturacion.component";

import { NivelesRefacturacionComponent } from "./niveles-refacturacion/niveles-refacturacion.component";
import { SimularFacturacionComponent } from "./simular-facturacion/simular-facturacion.component";
import { ReporteRemitosVencidosComponent } from "./reportes/reporte-remitos-vencidos/reporte-remitos-vencidos.component";
import { ReportadorRemitosComponent } from "./reportes/reporte-remitos/reportador-remitos/reportador-remitos.component";
import { EjecucionesFacturacionComponent } from "./reportes/ejecuciones/ejecuciones-facturacion/ejecuciones-facturacion.component";
import { ReportesIndexComponent } from "./reportes/reportes-index/reportes-index.component";
import { RefacturacionManualComponent } from "./refacturacion-manual/refacturacion-manual.component";
import { RefacturacionHistorialComponent } from "./refacturacion-historial/refacturacion-historial.component";
import { AuthService } from "../core/authentication/auth.service";
import { DocumentosIndexComponent } from "./documentos/documentos-index/documentos-index.component";
import { VisorDocumentosComponent } from "./documentos/visor-documentos/visor-documentos.component";
import { CondicionVenta } from "../shared/enums/condicionVenta";
import { query } from "@angular/animations";

const routes: Routes = [
  Shell.childRoutes([
    { path: 'facturacion/Reportes', redirectTo: 'facturacion/Reportes/reporteRemitos', pathMatch: 'full' },
    { path: 'facturacion/refacturacion', redirectTo: 'facturacion/refacturacion/solicitudRefacturacion', pathMatch: 'full' },
    { path: 'facturacion/holdeoPrincipal', redirectTo: 'facturacion/holdeoPrincipal/holdeoAeroplantas', pathMatch: 'full' },
    {
      path: 'facturacion', component: FacturacionIndexComponent, canActivate: [authGuard], children: [
        {
          path: "parametrosV2",
          component: ParametrosImpositivosIndexComponent,
          canActivate: [authGuard],
        },
        {
          path: "padrones",
          component: ImportacionPadronesComponent,
          canActivate: [authGuard]
        },
        {
          path: 'remitos',
          component: RemitoComponent,
          canActivate: [authGuard]
        },
        {
          path: 'holdeoPrincipal',
          component: HoldeoPrincipalComponent,
          canActivate: [authGuard],
          children: [
            {
              path: 'holdeoCuentas',
              component: HoldeoCuentasComponent,
              canActivate: [authGuard],
            },
            {
              path: 'holdeoAerovales',
              component: HoldeoAerovalesComponent,
              canActivate: [authGuard],
            },
            {
              path: 'holdeoAeroplantas',
              component: HoldeoAeroplantaComponent,
              canActivate: [authGuard],
            },

          ]
        },
        {
          path: 'refacturacion',
          component: RefacturacionIndexComponent,
          canActivate: [authGuard],
          children: [
            {
              path: 'generacionRefacturacion',
              component: RefacturacionHistorialComponent,
              canActivate: [authGuard],
            },
            {
              path: 'solicitudRefacturacion',
              component: RefacturacionManualComponent,
              canActivate: [authGuard],
            },
            {
              path: 'nivelesRefacturacion', component: NivelesRefacturacionComponent,
              canActivate: [authGuard],
            },
            {
              path: 'revisionRefacturacion',
              component: RevisionRefacturacionComponent,
              canActivate: [authGuard],
            },
            {
              path: 'aprobacionRefacturacion',
              component: AprobacionRefacturacionComponent,
              canActivate: [authGuard],
            },
            {
              path: 'correcionPreciosRefacturacion',
              component: CorreccionPreciosComponent,
              canActivate: [authGuard],
            },
            {
              path: 'despachoRefacturacion',
              component: DespachoRefacturacionComponent,
              canActivate: [authGuard],
            },
            {
              path: 'nivelesRefacturacion',
              component: NivelesRefacturacionComponent,
              canActivate: [authGuard],
            },
          ]
        },
        {
          path: 'reportes', component: ReportesIndexComponent, canActivate: [authGuard], children: [
            // { path: 'Reportes', redirectTo: 'cierreTurno/reporteCierre', pathMatch: 'full' },
            {
              path: 'reporteRemitos',
              component: ReportadorRemitosComponent,
              canActivate: [authGuard]
            },
            {
              path: 'reporteFacturas',
              component: ReporteFacturasComponent,
              canActivate: [authGuard],
            },
            {
              path: 'reporteRemitosVencidos',
              component: ReporteRemitosVencidosComponent,
              canActivate: [authGuard],
            },
            {
              path: 'buscarComprobantes',
              component: ComprobanteComponent,
              canActivate: [authGuard],
            },
            {
              path: 'reporteEjecuciones',
              component: EjecucionesFacturacionComponent,
              canActivate: [authGuard],
            },
            {
              path: 'reporteEjecucionesErrores',
              component: EjecucionesFacturacionComponent,
              canActivate: [authGuard],
            },
          ]
        },
        {
          path: 'documentos', component: DocumentosIndexComponent, canActivate: [authGuard], children: 
          [            
            {
              path: 'contado',
              component: VisorDocumentosComponent,
              canActivate: [authGuard]
            },
            {
              path: 'ctacte',
              component: VisorDocumentosComponent,
              canActivate: [authGuard],
            },
            {
              path: 'notas',
              component: VisorDocumentosComponent,
              canActivate: [authGuard],
            }
          ]
        }
      ]
    },
  ]),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class FacturacionRoutingModule { 
  constructor( private authService: AuthService, private router: Router ) {
    this.router.events.subscribe((event) => {
      if(event['url'] && event['url'] == '/facturacion') {
        this.redireccionarFacturacion();
      }else if(event['url'] && event['url'] == '/facturacion/refacturacion') {
        this.redireccionarRefacturacion();
      }else if(event['url'] && event['url'] == '/facturacion/documentos') {
        this.redireccionarDocumentos();
      }
  });
  }


  redireccionarFacturacion() {
    if(this.authService.hasRole(TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_LN, TipoUsuario.COMERCIAL_AV)){
      this.router.navigate(['/facturacion/refacturacion/solicitudRefacturacion']);
    }else if(this.authService.hasRole(TipoUsuario.IMPUESTOS, TipoUsuario.APROBADORBYR)){
      this.router.navigate(['/facturacion/parametrosV2']);
    }else if(this.authService.hasRole(TipoUsuario.FACTURACION)){
      this.router.navigate(['/facturacion/remitos']);
      //this.router.navigate(['/facturacion/refacturacion/despachoRefacturacion']);
    }else if(this.authService.hasRole(TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO)){ 
        this.router.navigate(['/facturacion/refacturacion/despachoRefacturacion']);
    }else if(this.authService.hasRole(TipoUsuario.REFACTURACION, TipoUsuario.CONSPRECIOS, TipoUsuario.SEGURIDADTABLAS)){
      this.router.navigate(['/facturacion/refacturacion/generacionRefacturacion']);
    }else if(this.authService.hasRole(TipoUsuario.OPERADOR, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL_AV, TipoUsuario.ADMCLIENTES, TipoUsuario.CONSAEROVALES, TipoUsuario.SOPORTE)){
      this.router.navigate(['/facturacion/reportes']);
    }
  }

  redireccionarRefacturacion() {  
    if(this.authService.hasRole(TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_LN, TipoUsuario.COMERCIAL_AV, TipoUsuario.APROBADORBYR)){
      this.router.navigate(['/facturacion/refacturacion/solicitudRefacturacion']);
    }else if(this.authService.hasRole(TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO,TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.SOPORTE)){
      this.router.navigate(['/facturacion/refacturacion/despachoRefacturacion']);
    }else if(this.authService.hasRole(TipoUsuario.REFACTURACION, TipoUsuario.CONSPRECIOS, TipoUsuario.SEGURIDADTABLAS)){
      this.router.navigate(['/facturacion/refacturacion/generacionRefacturacion']);
    }else if(this.authService.hasRole(TipoUsuario.FACTURACION)){
      this.router.navigate(['/facturacion/refacturacion/revisionRefacturacion']);
    }
  }
  
  redireccionarDocumentos() {
    const nex: NavigationExtras = {
      queryParams: { notas: false, condicion: CondicionVenta.contado }
    }
    this.router.navigate(['/facturacion/documentos/contado'], nex);
  }
}
