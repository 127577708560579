import { Component, forwardRef, OnDestroy, Output, EventEmitter, Input, ChangeDetectorRef, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ClienteService } from 'src/app/cliente/cliente.service';
import { ParametrosImpositivosService } from 'src/app/facturacion/parametros-impositivos.service';
import { TipoCliente } from '../enums/tipoCliente';
import { TipoDocumento } from '../enums/tipoDocumento';
import { CuentaCliente } from '../models/despacho/CuentaCliente';
import { Localidad } from '../models/despacho/Localidad';
import { Cliente } from '../models/facturacion/Cliente';
import { Provincia } from '../models/facturacion/Provincia';

@Component({
  selector: 'app-seleccion-cliente-facturacion',
  templateUrl: './seleccion-cliente-facturacion.component.html',
  styleUrls: ['./seleccion-cliente-facturacion.component.css']
})
export class SeleccionClienteFacturacionComponent implements OnInit {
  modelCliente: any;
  modelProvincia: any;
  modelLocalidad: any;
  
  formBuscador: UntypedFormGroup;
  formCarga: UntypedFormGroup;
  
  @Input() ctaSgcClientePredeterminado: string;

  clientes: CuentaCliente[];
  cargandoClientes: boolean;
  errorClientes: string;

  provincias: Provincia[];
  cargandoProvincias: boolean;
  errorProvincias: string;

  localidades: Localidad[];
  localidadesFiltradas: Localidad[] = [];
  cargandoLocalidades: boolean;
  errorLocalidades: string;

  mostrarBuscadorClientes: boolean = true;
  mostrarCargaDatosClientes: boolean;

  errorGeneral: string;

  constructor(  private clienteService: ClienteService,
                private parametroService: ParametrosImpositivosService,
                private formBuilder: UntypedFormBuilder,
                public activeModal: NgbActiveModal,) { }

  ngOnInit() {
    this.obtenerClientes();
    this.obtenerProvincias();
    this.obtenerLocalidades();

    this.formBuscador = this.formBuilder.group({
      clienteFormControl: ['', Validators.required],
    });

    this.formCarga = this.formBuilder.group({
      razonSocialFormControl:['', Validators.required],
      tipoDocumentoFormControl: ['', Validators.required],
      nroDocumentoFormControl: ['', Validators.required],
      provinciaFormControl: ['', Validators.required],
      localidadFormControl: ['', Validators.required],
      domicilioFormControl: ['', Validators.required],
    });

    this.formCarga.setValidators(this.validarCliente.bind(this));

  }

  obtenerClientes(): void {
    this.cargandoClientes = true;
    this.clienteService.getClientesActivos()
      .subscribe(clientes => {
        clientes = clientes.map(a => Object.assign(new CuentaCliente(), a)).filter(c => (c.ctA_SGC != this.ctaSgcClientePredeterminado) && (c.tipoCliente == TipoCliente.ConsumidorFinal) );
        this.clientes = clientes;
        this.cargandoClientes = false;
      }, () => {this.errorClientes = "Error al obtener clientes."; this.cargandoClientes = false;});
  }

  formatterCliente = (x: { razonSocial: string, ctA_SGC: string }) => `${x.razonSocial} - ${x.ctA_SGC == null ? 'SIN CTA SGC' : x.ctA_SGC}`;

  searchCliente = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    map(term => this.clientes == null || term.length < 2 || this.cargandoClientes ? [] :
      this.clientes.filter(v => this.containsString(this.clienteFormControl.value, v.ctA_SGC, v.razonSocial, v.numeroDocumento)).slice(0, 10))
  )

  inputClienteTextChange(): void {
    let buscaCliente = this.clienteFormControl.value;
    if (typeof (this.clienteFormControl.value) === 'string') {
      this.clienteFormControl.setValue(null);
    }
  }

  seleccionoCliente(e: any): void {
    this.clienteFormControl.setValue(e.item);
  }

  obtenerProvincias(): void {
    this.cargandoProvincias = true;
    this.parametroService.obtenerProvincias('0303')
      .subscribe(provincias => {
        provincias = provincias.map(a => Object.assign(new Provincia(), a));
        this.provincias = provincias;
        this.cargandoProvincias = false;
      }, () =>{this.errorProvincias = "Error al obtener provincias."; this.cargandoProvincias = false;});
  }

  formatterProvincia = (x: { nombre: string, codigo: string }) => `${x.nombre} ${x.codigo == null ? '' : (x.codigo)}`;

  searchProvincia = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    map(term => this.provincias == null || term.length < 2 || this.cargandoProvincias ? [] :
      this.provincias.filter(v => this.containsString(this.provinciaFormControl.value, v.nombre, v.codigo)).slice(0, 10))
  )

  inputProvinciaTextChange(): void {
    let buscaProvincia = this.provinciaFormControl.value;
    if (typeof (this.provinciaFormControl.value) === 'string') {
      this.provinciaFormControl.setValue(null);
      this.localidadesFiltradas = [];
    }
  }

  seleccionoProvincia(e: any): void {
    this.provinciaFormControl.setValue(e.item);
    this.localidadesFiltradas = this.localidades.filter( l => l.provinciaId == this.provinciaFormControl.value.id);
  }


  obtenerLocalidades(): void {
    this.cargandoLocalidades = true;
    this.parametroService.obtenerLocalidades()
      .subscribe(localidades => {
        localidades = localidades.map(a => Object.assign(new Localidad(), a));
        this.localidades = localidades;
        this.cargandoLocalidades = false;
      }, () => {this.errorClientes = "Error al obtener localidades."; this.cargandoLocalidades = false;});
  }

  formatterLocalidad = (x: { nombre: string, codigo: string }) => `${x.nombre} ${x.codigo == null ? '' : (x.codigo)}`;

  searchLocalidad = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    map(term => this.localidadesFiltradas == null || term.length < 2 || this.cargandoLocalidades ? [] :
      this.localidadesFiltradas.filter(v => this.containsString(this.localidadFormControl.value, v.nombre)).slice(0, 10))
  )

  inputLocalidadTextChange(): void {
    let buscaLocalidad = this.localidadFormControl.value;
    if (typeof (this.localidadFormControl.value) === 'string') {
      this.localidadFormControl.setValue(null);
    }
  }

  seleccionoLocalidad(e: any): void {
    this.localidadFormControl.setValue(e.item);
  }

  containsString(searchValue: string, ...searchIn: string[]): boolean {
    const concat = searchIn.toString();
    return concat.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
  }

  onSeleccionoCliente(){
    var cuentaCliente : CuentaCliente = this.clienteFormControl.value;

    var cliente = new Cliente();
    cliente.tipoDocumento = cuentaCliente.tipoDocumento;
    cliente.numeroDocumento = cuentaCliente.numeroDocumento;
    cliente.razonSocial = cuentaCliente.razonSocial;
    cliente.sgc = cuentaCliente.ctA_SGC;

    this.activeModal.close(cliente);
  }

  onDatosCargadosCliente(){
    var cliente = new Cliente();
    cliente.tipoDocumento = this.tipoDocumentoFormControl.value;
    cliente.numeroDocumento = this.nroDocumentoFormControl.value;
    cliente.razonSocial = this.razonSocialFormControl.value;
    cliente.nombreCiudad = this.localidadFormControl.value.nombre;
    cliente.nombreProvincia = this.provinciaFormControl.value.nombre;
    cliente.codigoPostalCiudad = this.localidadFormControl.value.codigoPostal;
    cliente.direccion = this.domicilioFormControl.value;
    
    this.activeModal.close(cliente);
  }

  onCargarDatosCliente(){
    this.mostrarCargaDatosClientes = true;
    this.mostrarBuscadorClientes = false;
  }

  validarCliente(group: UntypedFormGroup): any{
    let tipoDocumento = group.get("tipoDocumentoFormControl").value;    
    let nroDocumento = group.get("nroDocumentoFormControl").value;    

    if(tipoDocumento == TipoDocumento.DNI){              
      if( isNaN(Number(nroDocumento)) ){
        return { nroInvalido: true };
      }
    }else{
      if( ! this.cuitValido(nroDocumento) ){
        return { nroInvalido: true };
      }
    }      
  
    return null;
  }

  obtenerLeyendaDocumento(){
    var tipo = this.tipoDocumentoFormControl.value;

    if(tipo == TipoDocumento.DNI){
      return "DNI";
    }else if(tipo == TipoDocumento.CUIT){
      return "CUIT";
    }else if(tipo == TipoDocumento.CUIL){
      return "CUIL";
    }else{
      return "";
    }
  }

  cuitValido(cuil: string): boolean {
    if (cuil.length !== 11) {
      return false;
    }
  
    const [checkDigit, ...rest] = cuil
      .split('')
      .map(Number)
      .reverse();
  
    const total = rest.reduce(
      (acc, cur, index) => acc + cur * (2 + (index % 6)),
      0,
    );
  
    const mod11 = 11 - (total % 11);
  
    if (mod11 === 11) {
      return checkDigit === 0;
    }
  
    if (mod11 === 10) {
      return false;
    }
  
    return checkDigit === mod11;
  }

  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  get clienteFormControl() {
    return this.formBuscador.controls.clienteFormControl;
  }

  get razonSocialFormControl() {
    return this.formCarga.controls.razonSocialFormControl;
  }

  get nroDocumentoFormControl() {
    return this.formCarga.controls.nroDocumentoFormControl;
  }

  get tipoDocumentoFormControl() {
    return this.formCarga.controls.tipoDocumentoFormControl;
  }

  get provinciaFormControl() {
    return this.formCarga.controls.provinciaFormControl;
  }

  get localidadFormControl() {
    return this.formCarga.controls.localidadFormControl;
  }

  get domicilioFormControl() {
    return this.formCarga.controls.domicilioFormControl;
  }
}
