import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Aeroplanta } from 'src/app/shared/models/despacho/Aeroplanta';
import { Cliente } from 'src/app/shared/models/precios/Cliente';

@Component({
  selector: 'app-clientes-byr',
  templateUrl: './clientes-byr.component.html',
  styleUrls: ['./clientes-byr.component.css']
})
export class ClientesByrComponent implements OnInit {

  [x: string]: any;


  @Input() clientes: Cliente[];


  busquedaFormControl = new UntypedFormControl();


  cargandoClientes:boolean=true;
  constructor(public activeModal:NgbActiveModal){
  }

  ngOnInit() {

  }

}
