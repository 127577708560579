/*
Ingreso como Supervisor
--------------------------
NO tiene opcion de cliente extranjero.
  Puede crear nuevo cliente, ingresa cuit valido.
  Se va a buscar a Cenit los datos del cliente con el Flag de "puedoConsultarAFIP=true"
  Si los datos llegan con una CtaSAP, significa que existe en CENIT, de lo contrario los trajo de AFIP (ctaSAP = null) y los cargamos automaticamente.
  Esto le permite al SUPERVISOR obtener una CtaSAP y generarla en automatico
  Si en cualquiera de los dos casos no lelga información, debo cerrar la modal de información.

	Si los datos llegan bien, tiene que darle a "obtener cuenta sap".
		Si llega mal no puede continuar.
		Si llega bien puede continuar una carga basica. Solo puede cargar el responsable. El resto de los datos llegan de los ws a los que llamo y no puede editarlos.
		
		Estos clientes creados por el supervisor, a los que llegaron las dos llamadas a ws ok, se crean y aprueban directamente y no pasan por el proceso de aprobación.
		Se crear el cliente con una sola cuenta contado. No se puede editar ni agragar cuentas.

  Si el supervisor ingrea a ver los datos del cliente, solo puede ver y no se permite editar nada. Quedan los botones de guardar desactivados y no puede guardar ningun cambio.		

Ingreso como Comercial
----------------------
Nuevo cliente extranjero:
	carga datos donde el cuit no importa. Lo importante es Perona y Pais. En ese modal no debe aparecer Pais argentina.
	En la ventana de cliente se cargan los datos a mano.
	En "Condicion IVA" quedan No categorizado e iva excento exportación solamente. Ocultar el resto de las opciones.
	En "Tipo documento" dejar solo "Extranjero".
	Carga los datos y obtiene cuenta SAP.
	Si falla cuenta sap, se muestra mensaje "Fallo conesion cuenta sap, no podrá crear cliente". Sin cuenta sap no dejara guardar el cliente.
	Si obtiene cuenta sap bien, termina de cargar el resto de los datos.
	ESta obligado a subir adjunto.
	Y esta obligado a crear una cuenta.
	Por ahora en nueva cuenta, ocultar checkbox "Notificacion por mail".
		En "Identificador" cargar por defecto razon social social.
		
Nuevo cliente:
  Solo puede crear cliente que vienen de Cenit y solicita con el Flag de "puedoConsultarAFIP=false"
	No puede obtener cuenta SAP
  Si falla la primera instancia de Cenit, debo cerrar la modal de información.
	Si llega bien puede continuar cargando todos los datos a mano. Tiene acceso a modificar cualquier dato salvo el cuit y cuenta sap. 
  Al estar editando no puede editar el "Numero cuenta". El resto de los datos lo puede modificar en la edicion
	Estos clientes y cuentas creados SI pasan al proceso de aprobación.
	Esta obligado a subir un adjunto y a crear una cuenta.

Ingreso como ABM Clientes
----------------------
Nuevo cliente:
  Solo puede crear cliente que vienen de Cenit y solicita con el Flag de "puedoConsultarAFIP=false"
	No puede obtener cuenta SAP
  Si falla la primera instancia de Cenit, debo cerrar la modal de información.
	Si llega bien puede continuar cargando todos los datos a mano. Tiene acceso a modificar cualquier dato salvo el cuit y cuenta sap. Al estar editando no puede editar el "Numero cuenta". El resto de los datos lo puede modificar en la edicion
	Estos clientes y cuentas creados NO pasan al proceso de aprobación.
	Esta obligado a subir un adjunto y a crear una cuenta.
*/

import { Component, EventEmitter, Inject, Input, LOCALE_ID, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbCalendar, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { AgrupacionRemitos } from 'src/app/shared/enums/agrupacionRemitos';
import { TipoCuenta } from 'src/app/shared/enums/tipoCuenta';
import { TipoDocumento } from 'src/app/shared/enums/tipoDocumento';
import { TipoEntregaFactura } from 'src/app/shared/enums/tipoEntregaFactura';
import { TipoMoneda } from 'src/app/shared/enums/tipoMoneda';
import { Area } from 'src/app/shared/models/abm/Area';
import { Actividad } from 'src/app/shared/models/cliente/Actividad';
import { Cliente } from 'src/app/shared/models/cliente/Cliente';
import { ClienteEncabezado } from 'src/app/shared/models/cliente/ClienteEncabezado';
import { GrupoCliente } from 'src/app/shared/models/cliente/GrupoCliente';
import { CondicionIVA } from 'src/app/shared/models/facturacion/CondicionIVA';
import { MessageService } from 'src/app/shell/message.service';
import { ClienteService } from '../cliente.service';
import { ModalComercialesComponent } from '../modal-comerciales/modal-comerciales.component';
import { ModalCondicionIIBBComponent } from '../modal-condicion-iibb/modal-condicion-iibb.component';
import { ModalDireccionEntregaComponent } from '../modal-direccion-entrega/modal-direccion-entrega.component';
import { ModalDireccionFiscalComponent } from '../modal-direccion-fiscal/modal-direccion-fiscal.component';
import { ModalNuevaCuentaComponent } from '../modal-nueva-cuenta/modal-nueva-cuenta.component';
import { ModalResponsableComponent } from '../modal-responsable/modal-responsable.component';
import { formatDate } from '@angular/common'
import { CuentasService } from '../cuentas.service';
import { Cuenta } from 'src/app/shared/models/cliente/Cuenta';
import { SubArea } from 'src/app/shared/models/abm/SubArea';
import { PeriodosFacturacion } from 'src/app/shared/models/cliente/PeriodosFacturacion';
import { ConfigClienteProvincia } from 'src/app/shared/models/cliente/ConfigClienteProvincia';
import { AlicuotaDiferencial } from 'src/app/shared/models/cliente/AlicuotaDiferencial';
import { Provincia } from 'src/app/shared/models/cliente/Provincia';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { ActividadCliente } from 'src/app/shared/enums/ActividadCliente';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { UsuarioService } from 'src/app/account/usuario.service';
import { ModalOkComponent } from 'src/app/shared/generic-modals/modal-ok.component';
import { Responsable } from 'src/app/shared/models/stock/Responsable';
import { environment } from 'src/environments/environment';
import { Subject, Subscription } from 'rxjs';
import { ModalCBUComponent } from '../modal-cbu/modal-cbu.component';
import { ViaDePago } from 'src/app/shared/models/cliente/ViaDePago';
import { PlazoPagoCliente } from 'src/app/shared/models/cliente/PlazoPagoCliente';
import { Ubicacion } from 'src/app/shared/models/cliente/Ubicacion';
import { SharedService } from 'src/app/shared/shared.service';
@Component({
  selector: 'app-clientes-informacion',
  templateUrl: './clientes-informacion.component.html',
  styleUrls: ['./clientes-informacion.component.css']
})
export class ClientesInformacionComponent implements OnInit {
  @Input() clienteSeleccionadoParaEditar: ClienteEncabezado;
  @Input() todosLosClientesEncabezado: ClienteEncabezado[];
  @Input() clienteAFIP: Cliente;
  @Input() clienteExtranjero: Cliente;

  @Output() clienteCreado: EventEmitter<Cliente> = new EventEmitter<Cliente>();
  @Output() terminoCargaClientes: EventEmitter<boolean> = new EventEmitter<boolean>();

  readonly entidadesTotales = 9;
  entidadesDescargadas = 0;
  private entidadesDescargadas$ = new Subject<number>();
  private subscription: Subscription;
  change$: Subscription;

  clientesBaseUrl = environment.apiServer.clientesBaseUrl;

  usuarioYaCreoCuenta: boolean = false;

  areas: Area[];
  subAreas: SubArea[];
  subAreasFiltradas: SubArea[] = [];
  clienteAMostrar: Cliente;
  condicionIva: CondicionIVA[] = [];
  documentos = TipoDocumento;
  TipoDocumento = TipoDocumento;
  tipoMonedas = TipoMoneda;
  tipoCuenta = TipoCuenta;
  tipoEntregaFacturaEnum = TipoEntregaFactura;
  agrupacionRemitosEnum = AgrupacionRemitos;
  cargandoAreas: boolean;
  cargandoSegmentos: boolean;
  descargandoInformacion: boolean = false;
  actividades: Actividad[];
  grupoClientes: GrupoCliente[];
  obteniendoGrupo: boolean = false;
  obteniendoActividad: boolean = false;
  obteniendoViaDePago: boolean = false;
  obteniendoPlazosPago: boolean = false;
  obteniendoCondicionIVA: boolean = false;
  modelDesde: NgbDateStruct;
  fechaDesde: Date;
  guardandoCliente: boolean = false;
  estaCUIT: boolean = false;
  perFacturacion: PeriodosFacturacion[];
  provincias: Provincia[];

  obteniendoCuenta: boolean = false;
  cuentaCreada: boolean = true;
  actividadCliente = ActividadCliente;
  puedeCrearCuenta: boolean = false;
  viaDePago: ViaDePago;
  viasDePago: ViaDePago[];
  agrupacionesSerc;
  condicionesPago: PlazoPagoCliente[];
  obteniendoPercepcionIVA: boolean = false;
  percepcionIVA: string;
  cuit:string;
  
  informacionCliente = new UntypedFormGroup({
    razonSocialFormControl: new UntypedFormControl({value: {value: null, disabled: !this.usuarioModificaCampos()}, disabled: !this.usuarioModificaCampos() && !this.esElectrica}, [Validators.required]),
    condicionIVAFormControl: new UntypedFormControl({value: null, disabled: !this.usuarioModificaCampos()}),
    areaFormControl: new UntypedFormControl({value: null, disabled: !this.usuarioModificaCampos()}),
    numeroDocumentoFormControl: new UntypedFormControl({value: null}, [Validators.required]),
    subAreaFormControl: new UntypedFormControl({value: null, disabled: !this.usuarioModificaCampos()}),
    tipoDocumentoFormControl: new UntypedFormControl({value: null, disabled: !this.usuarioModificaCampos()}),
    actividadFormControl: new UntypedFormControl({value: null, disabled: !this.usuarioModificaCampos()}),
    viaDePagoFormControl: new UntypedFormControl({value: null, disabled: !this.usuarioModificaCampos()}),
    agrupacionSercFormControl: new UntypedFormControl({value: null, disabled: !this.usuarioModificaCampos()}),
    clienteObservadoFormControl: new UntypedFormControl(null),
    activoFormControl: new UntypedFormControl(null),
    grupoClienteFormControl: new UntypedFormControl({value: null, disabled: !this.usuarioModificaCampos()}),
    cuentaSAPFormControl: new UntypedFormControl({value: null, disabled: true}), 
    cuentaCTCFormControl: new UntypedFormControl({value: null, disabled: true}), 
    detalleModificacionFormControl: new UntypedFormControl(null),
    listaCuentasFormControlArray: new UntypedFormArray([])
  });

  archivosSubidos: string[] = [];

  tipoDocumentoAlIngresar: number ;

  _change: SimpleChanges;

  constructor(private activeModal: NgbModal, 
    private clienteService: ClienteService, 
    private spinner: NgxSpinnerService,
    private messageService: MessageService, 
    @Inject(LOCALE_ID) public locale: string, 
    private cuentaService: CuentasService,
    private authService: AuthService, 
    private usuarioService: UsuarioService,
    sharedService: SharedService) { 
      this.change$ = sharedService.aeroplantaSeleccionada.subscribe(_=>{ 
        this.cambiarDeAeroplanta()
      });
    }

  ngOnInit() {
    this.activo.setValue(true);
    this.observado.setValue(false);
    this.percepcionIVA = ""; 
  }

  cambiarDeAeroplanta() {
    this.ngOnChanges(this._change);
  }

  getCodigoAeroplanta(){
    return this.usuarioService.getAeroplantaActual().codigoAeroplanta;
  }

  ngOnDestroy() {
    this.change$.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    this._change=changes;

    if (changes.clienteSeleccionadoParaEditar?.currentValue != null) {
      this.clienteAMostrar = null;
      this.cuit = this.clienteSeleccionadoParaEditar.numeroDocumento;
      this.iniciarComponente();
    }else if(changes.clienteAFIP?.currentValue != null){
      this.clienteAMostrar = null;
      this.cuit = this.clienteAFIP.numeroDocumento;
      this.iniciarComponente();
    }else if(changes.clienteExtranjero?.currentValue != null){
      this.clienteAMostrar = null;
      this.iniciarComponente();
    }
  }

  resetValidators(){
    this.informacionCliente.controls.numeroDocumentoFormControl.clearValidators();
    console.log(this.tipoDoc.value);
    if (this.tipoDoc.value == TipoDocumento.CUIT || this.tipoDoc.value == TipoDocumento.CUIL) { 
        this.informacionCliente.controls.numeroDocumentoFormControl.setValidators([Validators.required, Validators.pattern('(20|23|24|27|30|33|34)(\\D)?[0-9]{8}(\\D)?[0-9]'),
                                                                            this.validarFormatoCuit && this.validarCuit]) 
      } else if (this.tipoDoc.value == TipoDocumento.DNI) {
        this.informacionCliente.controls.numeroDocumentoFormControl.setValidators([Validators.required, this.validarDni]);
      }
    this.informacionCliente.controls.numeroDocumentoFormControl.updateValueAndValidity();
  }

  validarFormatoCuit(): boolean {
    const regexCuit = /^(20|23|24|27|30|33|34)([0-9]{9}|[0-9]{8}[0-9]{1})$/g;
    let p = regexCuit.test(this.numDocumento.value)
    return p;
  }

  validarDni(control: AbstractControl): { [key: string]: any } | null {          
    if (control.value == null || control.value.length === 0) {
      return null; // don't validate empty values to allow optional controls
    }
    let esDni = false;
    let dni = control.value;
    (dni.length > 9) ? esDni = false :  esDni = true;      
    return control.valid && esDni ? null : { 'dni': { value: control.value } };
  }

  validarCuit(control: AbstractControl): { [key: string]: any } | null {          
    if (control.value == null || control.value.length === 0) {
      return null; // don't validate empty values to allow optional controls
    }
    let esCuit = false;
    let cuit = control.value
    var vec = new Array(10);
    let cuit_rearmado = "";
    for (let i = 0; i < cuit.length; i++) {
      let caracter = cuit.charAt(i);
      if (caracter.charCodeAt(0) >= 48 && caracter.charCodeAt(0) <= 57) {
        cuit_rearmado += caracter;
      }
    }
    cuit = cuit_rearmado;
    if (cuit.length != 11) {  // si no estan todos los digitos
      esCuit = false;
    } else {
      let x = 0;
      let dv = 0
      // Multiplico los dígitos.
      vec[0] = cuit.charAt(0) * 5;
      vec[1] = cuit.charAt(1) * 4;
      vec[2] = cuit.charAt(2) * 3;
      vec[3] = cuit.charAt(3) * 2;
      vec[4] = cuit.charAt(4) * 7;
      vec[5] = cuit.charAt(5) * 6;
      vec[6] = cuit.charAt(6) * 5;
      vec[7] = cuit.charAt(7) * 4;
      vec[8] = cuit.charAt(8) * 3;
      vec[9] = cuit.charAt(9) * 2;

      // Suma cada uno de los resultado.
      for (let i = 0; i <= 9; i++) {
        x += vec[i];
      }
      dv = (11 - (x % 11)) % 11;
      if (dv == cuit.charAt(10)) {
        esCuit = true;
      }
    }

    return control.valid && esCuit ? null : { 'cuit': { value: control.value } };
  }

  iniciarComponente(){
    this.resetearDatosComponente();

    this.descargandoInformacion = true;
    this.spinner.show('spinnerGrafico');
    
    this.entidadesDescargadas = 0;

    // Verificamos que luego de descargarse todas las entidades, se procese la accion sobre el cliente
    this.subscription = this.entidadesDescargadas$.subscribe( () => {
      this.entidadesDescargadas++; 
      if(this.entidadesDescargadas == this.entidadesTotales){
        //this.spinner.hide('spinnerGrafico');
        //this.descargandoInformacion = false;

        this.procesarAccionCliente();        
      }
    });    

    // Descarga de entidades
    this.getPeriodosFacturacion();
    this.getCondicinesIVA_V2();
    this.getProvinciasPorPais_v2();
    this.getSubAreas_v2();
    this.getGrupoCliente_v2();
    this.getViasDePago();
    this.getCondicionesPago();
    this.getPadronesIVA_v1();
  }

  procesarAccionCliente(){

    this.subscription.unsubscribe();

    if(this.creandoCliente()){
      if (this.creandoClienteAfip()) {
        
        this.iniciarDatosComponentesParaClienteAfip();

      } else if(this.creandoClienteExtranjero()){

        this.iniciarDatosComponentesParaClienteExtranjero();

      }

      // comun para cliente extranjero y cliente afip
      this.crearCondicionesIIBB();

      if(!this.usuarioModificaCampos())
        this.asignarComercialDefectoEnCliente();
      
      this.clienteAMostrar.activo = true;
      this.modelDesde = { day: 1, month: 1, year: 1901 };

      this.spinner.hide('spinnerGrafico');
      this.descargandoInformacion = false;

      if(!this.usuarioModificaCampos()) {
        let i_1 = this.obtenerArea();
        this.area.setValue(this.areas[i_1]);
  
        let i_2 = this.obtenerSubArea();
        this.subArea.setValue(this.subAreas[i_2]);
      }

      this.actividad.setValue(ActividadCliente.OTRO);

      this.clienteAMostrar.cbUs = [];

      if (!this.usuarioModificaCampos()) {
        this.desactivarEntorno();
      }

      this.tipoDocumentoAlIngresar = this.deepCopy(this.clienteAMostrar.tipoDocumento);
    }else{
      this.getClientesPorDocumento_v2();      
    }  

    this.detalleModificacionFormControl.setValue("");    
  }

  resetearDatosComponente(){
    this.usuarioYaCreoCuenta = false;
    this.cuentaCreada = false;
    this.puedeCrearCuenta = false;
    this.areas = [];
    this.subAreas = [];
    this.subAreasFiltradas = [];

    this.resetearFormulario();
  }

  resetearFormulario(){
    //this.porcentajeIva.setValue("");    

    this.informacionCliente.controls.listaCuentasFormControlArray = new UntypedFormArray([]);
  }

  iniciarDatosComponentesParaClienteExtranjero(){
    this.razonSocial.setValue(this.clienteExtranjero.razonSocial);    
    this.numDocumento.setValue(this.clienteExtranjero.numeroDocumento);
    this.SAPCuenta.setValue(this.clienteExtranjero.ctaSap);
    this.CTCCuenta.setValue(this.clienteExtranjero.ctA_CTC);
    this.tipoDoc.setValue(this.clienteExtranjero.tipoDocumento);

    this.clienteAMostrar = this.clienteExtranjero;
    this.clienteAMostrar.cuentas = [];
    this.clienteAMostrar.subArea = null;
    this.clienteAMostrar.responsable = null;
    this.clienteAMostrar.comerciales = null;
    this.clienteAMostrar.grupoCliente = null;
    this.clienteAMostrar.actividadPAD = 0;
    this.clienteAMostrar.activo = true;
    this.clienteAMostrar.clienteObservado = false;
    this.clienteAMostrar.direccionesEntrega = null;
    this.clienteAMostrar.grupoCliente = null;
    this.clienteAMostrar.percepcionIVADif = 0;
    this.clienteAMostrar.tipoDocumento = 1;
    
    if (this.clienteExtranjero.direccionFiscal != null) {
      if (this.clienteAMostrar.direccionFiscal.numero == null || this.clienteAMostrar.direccionFiscal.numero == "") {
        this.clienteAMostrar.direccionFiscal.numero = "0";
      }
      if (this.clienteAMostrar.direccionFiscal.piso == null || this.clienteAMostrar.direccionFiscal.piso == "") {
        this.clienteAMostrar.direccionFiscal.piso = "0";
      }
      if (this.clienteAMostrar.direccionFiscal.departamento == null || this.clienteAMostrar.direccionFiscal.departamento == "") {
        this.clienteAMostrar.direccionFiscal.departamento = "0";
      }
    }
  }

  iniciarDatosComponentesParaClienteAfip(){
    this.clienteAMostrar = this.clienteAFIP;

    this.razonSocial.setValue(this.clienteAFIP.razonSocial);
    
    if(this.clienteAFIP.ctaSap) {
      this.SAPCuenta.setValue(this.clienteAFIP.ctaSap);
      if (this.authService.hasRole(TipoUsuario.SUPERVISOR, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO)) {
        this.cuentaService.obtenerProximoCuenta()
        .subscribe(result => {
          this.crearCuentaProvisoria(this.clienteAFIP.razonSocial, result);        
        });
      }      
    }else{
      this.SAPCuenta.setValue(null);
    }

    this.CTCCuenta.setValue(this.clienteAFIP.ctA_CTC);

    this.numDocumento.setValue(this.clienteAFIP.numeroDocumento);
    
    if(this.clienteAFIP.condicionIva != null){
      let i = this.obtenerCondIva();
      this.condIva.setValue(this.condicionIva[i]);
    }else{
      this.condIva.setValue(null);
    }    

    if (this.clienteAFIP.direccionFiscal != null) {
      if (this.clienteAMostrar.direccionFiscal.numero == null || this.clienteAMostrar.direccionFiscal.numero == "") {
        this.clienteAMostrar.direccionFiscal.numero = "0";
      }
      if (this.clienteAMostrar.direccionFiscal.piso == null || this.clienteAMostrar.direccionFiscal.piso == "") {
        this.clienteAMostrar.direccionFiscal.piso = "0";
      }
      if (this.clienteAMostrar.direccionFiscal.departamento == null || this.clienteAMostrar.direccionFiscal.departamento == "") {
        this.clienteAMostrar.direccionFiscal.departamento = "0";
      }
    }
    
    if(this.clienteAFIP.tipoDocumento){
      this.tipoDoc.setValue(this.clienteAFIP.tipoDocumento);
    }else{
      this.clienteAMostrar.tipoDocumento = TipoDocumento.CUIT; 
      this.tipoDoc.setValue(TipoDocumento.CUIT);
    }
    this.clienteAMostrar.activo = true;
    this.clienteAMostrar.clienteObservado = false;
  }

  asignarComercialDefectoEnCliente() {
    this.usuarioService.obtenerComercialPorAeroplanta(this.getCodigoAeroplanta())
      .subscribe(result => {
        this.clienteAMostrar.comerciales = [];
        this.clienteAMostrar.comerciales.push(result.userName);
        this.spinner.hide('spinnerGrafico');
        this.descargandoInformacion = false;
        this.terminoCargaClientes.emit(true);
      }, (err: ErrorModel) => {
        this.spinner.hide('spinnerGrafico');
        this.descargandoInformacion = false;
        this.terminoCargaClientes.emit(true);
      });
  }

  getProvinciasPorPais_v2() {
    this.clienteService.getProvinciasPorPais2('0303')
      .subscribe(result => {
        this.provincias = result.filter(x => x.codigo != "EXT");//Se saca la provincia Extranjero

        this.entidadesDescargadas$.next();
      });
  }

  crearCondicionesIIBB() {
    this.clienteAMostrar.configuracionProvincialImp = [];
    for (let i = 0; this.provincias.length > i; i++) {
      let aux = new ConfigClienteProvincia();
      aux.alicuotaDiferencial = new AlicuotaDiferencial();
      aux.alicuotaDiferencial = null;
      aux.condicionIIBB = 1;
      aux.numeroIIBB = '';
      aux.provincia = this.provincias[i];
      this.clienteAMostrar.configuracionProvincialImp.push(aux);
    }
  }

  crearCuentaProvisoria(rs:string, proximaCtaSGC:string) {
    let cuentaProvisioria: Cuenta = new Cuenta();
    cuentaProvisioria.ctA_SGC = proximaCtaSGC;
    cuentaProvisioria.activo = true;
    cuentaProvisioria.agrupacionRemitos = 1;
    cuentaProvisioria.moneda = 0;
    cuentaProvisioria.tipoCuenta = 1;
    cuentaProvisioria.tipoEntregaFactura = 1;
    cuentaProvisioria.email = '';
    cuentaProvisioria.ctaSap = '';
    cuentaProvisioria.soloPAD = false;
    cuentaProvisioria.periodoFacturacion = this.deepCopy(this.perFacturacion[0]);
    cuentaProvisioria.esNueva = true;
    cuentaProvisioria.condicionPago = this.deepCopy(this.condicionesPago[0]);
    cuentaProvisioria.descripcion = rs;
    this.puedeCrearCuenta = false;
    // cuentaProvisioria.periodosFacturacion = 1;
    for (let i = 0; i < this.listaCuentasFormControlArray.controls.length; i++) {
      var cuentaFormControls = this.listaCuentasFormControlArray.controls[i];

      this.usuarioModificaCampos() ? cuentaFormControls.get('numeroCuentaFormControl').enable() : cuentaFormControls.get('numeroCuentaFormControl').disable()
    }

    this.clienteAMostrar.cuentas.push(cuentaProvisioria);
    this.listaCuentasFormControlArray.push(this.nuevoFormCuentaItem(cuentaProvisioria));
    this.usuarioYaCreoCuenta = true;
    this.cuentaCreada = true;
  }

  getClientesPorDocumento_v2() {
    this.clienteAMostrar = null;

    this.clienteService.getClientePorDocumento(this.clienteSeleccionadoParaEditar.tipoDocumento, this.clienteSeleccionadoParaEditar.numeroDocumento)
      .subscribe(result => {

        this.clienteAMostrar = result;
        this.razonSocial.setValue(this.clienteAMostrar.razonSocial);
        this.numDocumento.setValue(this.clienteAMostrar.numeroDocumento);
        this.tipoDoc.setValue(this.clienteAMostrar.tipoDocumento);
        this.actividad.setValue(this.clienteAMostrar.actividadPAD);
        this.viaPago.setValue(this.clienteAMostrar.viaDePago);
        this.SAPCuenta.setValue(this.clienteAMostrar.ctaSap);
        this.CTCCuenta.setValue(this.clienteAMostrar.ctA_CTC);
        this.activo.setValue(this.clienteAMostrar.activo);
        this.observado.setValue(this.clienteAMostrar.clienteObservado);
        //this.porcentajeIva.setValue(this.clienteAMostrar.percepcionIVADif);
        this.subArea.setValue(this.clienteAMostrar.subArea);
        this.area.setValue(this.clienteAMostrar.subArea?.area);
        /*var fecha = new Date(this.clienteAMostrar.fechaPercepcionIVADif);
        this.modelDesde = { day: fecha.getUTCDate(), month: fecha.getUTCMonth() + 1, year: fecha.getFullYear() };
        */
        let p = this.obtenerGrupo();
        this.grupoCliente.setValue(this.grupoClientes[p]);
        this.agrupacionSerc.setValue(this.grupoClientes[p]);
        let vp = this.obtenerViaDePago();
        //this.viaPago.setValue(this.viasDePago[vp]);

        let s = this.obtenerSubArea();
        this.subArea.setValue(this.subAreas[s]);
        for (let i = 0; this.areas.length > i; i++) {
          if (this.areas[i].codigo == this.subArea.value.area.codigo) {
            this.area.setValue(this.areas[i]);
          }
        }

        if (this.clienteAMostrar.condicionIva == null) {
          this.clienteAMostrar.condicionIva = new CondicionIVA();
          this.clienteAMostrar.condicionIva.descripcion = "RESPONSABLE INSCRIPTO";
        } else {
          let i = this.obtenerCondIva();
          this.condIva.setValue(this.condicionIva[i]);
        }

        this.informacionCliente.controls.listaCuentasFormControlArray = new UntypedFormArray([]);
        if (this.clienteAMostrar.cuentas != null) {
          for (let c of this.clienteAMostrar.cuentas) {
            c.esNueva = false;

            if(c.periodoFacturacion == null){
              c.periodoFacturacion =  this.deepCopy(this.perFacturacion[0]);
            }

            if(c.condicionPago == null){
              c.condicionPago = this.deepCopy(this.condicionesPago[0]);
            }

            this.listaCuentasFormControlArray.push(this.nuevoFormCuentaItem(c));
          }
        }

        if (!this.usuarioModificaCampos()) {
          this.desactivarEntorno();
        }
        
        this.subAreas = this.subAreasFiltradas.filter(a => a.area?.id == this.subArea.value?.area?.id);        
        this.puedeCrearCuenta = true;
                
        if(this.clienteAMostrar.comerciales == null || this.clienteAMostrar.comerciales.length == 0){
          if (!this.usuarioModificaCampos()) {
            this.asignarComercialDefectoEnCliente();
          }else{
            this.spinner.hide('spinnerGrafico');
            this.descargandoInformacion = false;
            this.terminoCargaClientes.emit(true);
          }
        }else{
          this.spinner.hide('spinnerGrafico');
          this.descargandoInformacion = false;
          this.terminoCargaClientes.emit(true);
        }        

        this.tipoDocumentoAlIngresar = this.deepCopy(this.clienteAMostrar.tipoDocumento);

        this.resetValidators();
      });
  }

  deepCopy(objeto){
    return JSON.parse(JSON.stringify(objeto));
  }

  desactivarEntorno(){
    for (let i = 0; i < this.listaCuentasFormControlArray.controls.length; i++) {
      var cuentaFormControls = this.listaCuentasFormControlArray.controls[i];      
      this.informacionCliente.controls['clienteObservadoFormControl'].disable();
      this.informacionCliente.controls['activoFormControl'].disable();
      this.listaCuentasFormControlArray.controls[i].get('numeroCuentaFormControl').disable();
      this.listaCuentasFormControlArray.controls[i].get('notificacionFormControl').disable();
      this.listaCuentasFormControlArray.controls[i].get('ctaSapCuentaFormControl').disable();        
      this.listaCuentasFormControlArray.controls[i].get('activaCuentaFormControl').disable();
      this.listaCuentasFormControlArray.controls[i].get('periodoFormControl').disable();
      this.listaCuentasFormControlArray.controls[i].get('diasFormControl').disable();
    }
  }

  nuevoFormCuentaItem(c: Cuenta) {
    var subForm =  new UntypedFormGroup({
      numeroCuentaFormControl: new UntypedFormControl(c == null ? null : c.ctA_SGC),
      condicionVentaFormControl: new UntypedFormControl({value: null, disabled: !this.usuarioModificaCampos()}),
      monedaFormControl: new UntypedFormControl({value: c == null ? null : c.moneda, disabled: !this.usuarioModificaCampos()}),
      entregaFacturaFormControl: new UntypedFormControl({value: c == null ? null : c.tipoEntregaFactura, disabled: !this.usuarioModificaCampos()}),
      identificadorFormControl: new UntypedFormControl({value: c == null ? null : c.descripcion, disabled: !this.usuarioModificaCampos()}),
      emailFormControl: new UntypedFormControl({value: c == null ? null : c.email, disabled: !this.usuarioModificaCampos() && !this.esElectrica}),
      ctaSapCuentaFormControl: new UntypedFormControl(c == null ? null : c.ctaSap),
      ctA_CTCCuentaFormControl: new UntypedFormControl(c == null ? null: c.ctA_CTC),
      periodoFormControl: new UntypedFormControl({value: c == null ? null : this.obtenerIndexPeriodo(c.periodoFacturacion), disabled: !this.usuarioModificaCampos()}),
      notificacionFormControl: new UntypedFormControl(c == null ? null : c.notificaPorEmail),
      agrupacionRemitosFormControl: new UntypedFormControl({value: c == null ? null : c.agrupacionRemitos, disabled: !this.usuarioModificaCampos()}),
      activaCuentaFormControl: new UntypedFormControl(c == null ? null : c.activo),
      diasFormControl: new UntypedFormControl({value: c == null ? null : c.periodoFacturacion.diaEjecucion, disabled: !this.usuarioModificaCampos()}),
      plazoPagoFormControl: new UntypedFormControl({value: c == null ? null : (typeof c.condicionPago == "number" ? null : c.condicionPago), disabled: !this.usuarioModificaCampos()}),
      soloPADFormControl: new UntypedFormControl(c == null ? null : c.soloPAD),
      datosAdicionalesFacturacionFormControl: new UntypedFormControl(c == null ? null : c.datosAdicionalesFacturacion),
    });

    subForm.controls.plazoPagoFormControl;

    return subForm;
  }

  cuitExiste() {
    this.estaCUIT = false;
    if (this.numDocumento.value != null && this.creandoCliente()) {
      for (let cli of this.todosLosClientesEncabezado) {
        if (this.numDocumento.value == cli.numeroDocumento) {
          this.estaCUIT = true
        }
      }
    }
  }

  obtenerSubArea() {
    let i = 0;
    let p = 0;
    if (this.clienteAMostrar.subArea != null) {
      for (let c of this.subAreas) {
        if (this.clienteAMostrar.subArea.descripcion == c.descripcion) {
          p = i;
        }
        i++;
      }
      return p;
    } else {
      for (let c of this.subAreas) {
        if (c.descripcion == 'AEROPMANDATARIOS') {
          p = i;
        }
        i++
      }
      return p;
    }
  }

  obtenerArea() {
    let i = 0;
    let p = 0;
    if (this.clienteAMostrar.subArea?.area != null) {
      for (let c of this.areas) {
        if (this.clienteAMostrar.subArea?.area.descripcion == c.descripcion) {
          p = i;
        }
        i++;
      }
      return p;
    } else {
      for (let c of this.areas) {
        if (c.descripcion == 'Aeroplantas') {
          p = i;
        }
        i++
      }
      return p;
    }
  }

  obtenerViaDePago() {
    let i = 0;
    let p = 0;
    if (this.clienteAMostrar.viaDePago != null) {
      for (let vp of this.viasDePago) {
        if (this.clienteAMostrar.viaDePago.descripcion == vp.descripcion) {
          p = i;
        }
        i++;
      }
      return p;
    }
  }

  obtenerCuenta() {
    this.obteniendoCuenta = true;
    let completo = true;
    let string = "";

    if (this.numDocumento.value == null || this.numDocumento.value == 0) {
      string += 'Falta agregar el numero de documento del cliente.';
      completo = false;
    }

    if (completo) {
      this.clienteService.validarClienteCenit(this.getCodigoAeroplanta(), this.numDocumento.value, this.rolesQuePuedenConsultarAFIP(), (this.creandoClienteExtranjero() || this.clienteParaEditarEsExtranjero) ? true : false)
        .subscribe(result => {
          if (result != null) {
            this.SAPCuenta.setValue(result.ctaSap);
            this.CTCCuenta.setValue(result.ctA_CTC);
            if(result.ctaSap!=null) {
              this.messageService.showSuccessMessage('Numero de cuenta obtenida con éxito.');
            }else{
              this.messageService.showErrorMessage('Numero de cuenta no encontrado.');
            }
            this.obteniendoCuenta = false;
          }          
        }, (err: ErrorModel) => {
          this.messageService.showErrorMessage('No se pudo obtener la cuenta desde CENIT.');
          this.obteniendoCuenta = false;
        })
    } else {
      this.messageService.showErrorMessageClosed('Por favor revise los datos a completar.' + string, true);
      this.obteniendoCuenta = false;
    }
  }

  getSubAreas_v2() {
    this.subAreas = [];
    this.subAreasFiltradas = [];
    this.cargandoSegmentos = true;
    this.clienteService.getSubAreas()
      .subscribe(result => {
        this.subAreas = result;
        this.subAreasFiltradas = result;
        this.getAreas_v2();

        this.entidadesDescargadas$.next();
      })
  }

  getAreas_v2() {
    this.areas = [];
    this.cargandoAreas = true;
    this.clienteService.getAreas()
      .subscribe(result => {
        this.areas = result;
              
        this.cargandoAreas = false;
        this.cargandoSegmentos = false;

        this.entidadesDescargadas$.next();
      })
  }

  getViasDePago(){
    this.viasDePago = [];
    this.obteniendoViaDePago = true;
    this.clienteService.getViasDePago()
      .subscribe(result => {
        this.viasDePago = result;       
        this.obteniendoViaDePago = false;
        this.entidadesDescargadas$.next();
      })
  }

  getAgrupacionSerc(gruposCliente) {
    this.agrupacionesSerc = gruposCliente.filter(c=>c.codigo == 811 || c.codigo == 814);
  }

  getCondicionesPago(){
    this.condicionesPago = [];
    this.obteniendoPlazosPago = true;
    this.clienteService.getCondicionesPago()
      .subscribe(result => {
        this.condicionesPago = result;   
        this.condicionesPago = this.condicionesPago.sort(function(a, b) {
          return a.codigo.localeCompare(b.codigo, undefined, {
            numeric: true,
            sensitivity: 'base'
          });
        }); 
        this.obteniendoPlazosPago = false;
        this.entidadesDescargadas$.next();
      }, (err: ErrorModel) => {
        this.obteniendoPlazosPago = false;
      });
  }

  getPadronesIVA_v1() {        
    this.percepcionIVA = "";
    this.obteniendoPercepcionIVA = true;
    this.clienteService.getPercepcionIVA(this.cuit)
      .subscribe(result => {     
        //console.log(result);   
        this.percepcionIVA = result;
        this.obteniendoPercepcionIVA = false;
        this.entidadesDescargadas$.next();
      }, (err: ErrorModel) => {
      this.obteniendoPercepcionIVA = false;
    });      
  }

  filtrar() {
    this.subAreas = this.subAreasFiltradas.filter(a => a.area.id == this.area.value.id);
    this.subArea.setValue(this.subAreas[0]);
  }

  keysDocumentos(): Array<string> {
    var keys = Object.keys(this.documentos);
    keys = keys.slice(keys.length / 2);
    if(this.creandoClienteExtranjero()){
      keys = keys.slice(3); // Se toma del tercer elemento en adelante (solo Extranjero)
    }else if(this.creandoClienteAfip()){
      keys = keys.slice(1, 3); // Se toma del primer elemento en adelante (CUIT y CUIL)
    }else if( ! this.creandoCliente() ){ // Editando cliente
      if(this.clienteParaEditarEsExtranjero()){
        keys = keys.slice(3); // Se toma del tercer elemento en adelante (solo Extranjero)
      }else{
        keys = keys.slice(0, 3); // Se toma del primer elemento en adelante (CUIT y CUIL)
      }
    }
    
    return keys;
  }

  keysActividades(): Array<string> {
    var keys = Object.keys(this.actividadCliente);
    keys = keys.slice(keys.length / 2);
    return keys;
  }

  keysViaDePago(): Array<string> {
    var keys = Object.keys(this.viaDePago);
    keys = keys.slice(keys.length / 2);
    return keys;
  }

  keysMonedas(): Array<string> {
    var keys = Object.keys(this.tipoMonedas);
    keys = keys.slice(keys.length / 2);
    return keys;
  }

  keysTipoCuenta(): Array<string> {
    var keys = Object.keys(this.tipoCuenta);
    keys = keys.slice(keys.length / 2);
    return keys;
  }

  obtenerValueTipoCuenta(tipo){
    return Number(this.tipoCuenta[tipo]);
  }

  obtenerValueMoneda(moneda){
    return Number(this.tipoMonedas[moneda]);
  }

  obtenerValueTipoEntrega(entrega){
    return Number(this.tipoEntregaFacturaEnum[entrega]);
  }

  obtenerValueAgrupacionRemitos(agrup){
    return Number(this.agrupacionRemitosEnum[agrup]);
  }

  obtenerMoneda(moneda: number) {
    return TipoMoneda[moneda];
  }

  obtenerTipoCuenta(cuenta: number) {
    switch (cuenta) {
      case 1:
        return 'Contado';
        break;
      case 2:
        return 'Cuenta Corriente';
        break;
      case 3:
        return 'Previo Pago';
        break;
      case 4:
        return 'Donacion';
        break;
      case 5:
        return 'Consumo Interno';
        break;
      default:
        return 'Contado';
        break;
    }
  }

  getGrupoCliente_v2() {
    this.obteniendoGrupo = true;
    this.clienteService.getGrupoCliente()
      .subscribe(result => {
        this.grupoClientes = result;
        this.obteniendoGrupo = false;
        this.entidadesDescargadas$.next();
        this.getAgrupacionSerc(result);
      })
  }

  getCondicinesIVA_V2() {
    this.obteniendoCondicionIVA = true;
    this.clienteService.getCondicionesIVA()
      .subscribe(result => {
        this.condicionIva = [];
        let r: CondicionIVA[];
        r = result;
        
        for (let i = 0; r.length > i; i++) {
            this.condicionIva.push(r[i]);
        }        
        
        if(this.creandoClienteExtranjero() || (!this.creandoCliente() && this.clienteParaEditarEsExtranjero())){
          //En "Condicion IVA" quedan No categorizado e iva excento exportación solamente. Ocultar el resto de las opciones.
          this.condicionIva = this.condicionIva.filter(c => c.codigoAFIP=="07" || c.codigoAFIP=="00");
          if (this.creandoClienteExtranjero() && this.clienteAMostrar?.condicionIva==null) {
            this.condIva.setValue(this.condicionIva.find( c => c.codigoAFIP=="07"));
          }
        }

        this.obteniendoCondicionIVA = false;

        this.entidadesDescargadas$.next();
      });
  }

  obtenerCondIva() {
    let i = 0;
    let p = 0;
    for (let c of this.condicionIva) {
      if (this.clienteAMostrar.condicionIva?.descripcion == c.descripcion) {
        p = i;
      }
      i++;
    }
    this.obteniendoCondicionIVA = false;
    return p;
  }

  obtenerGrupo() {
    let i = 0;
    let p = 0;
    if (this.clienteAMostrar.grupoCliente != null) {
      for (let  c of this.grupoClientes) {
        if (this.clienteAMostrar.grupoCliente?.descripcion == c.descripcion) {
          p = i;
        }
        i++;
      }
      return p;
    } else {
      return 0;
    }
  }

  mostrarDireccionFiscal() {
    let modalRef = this.activeModal.open(ModalDireccionFiscalComponent, { backdrop: 'static', centered: true });
    modalRef.componentInstance.clienteSeleccionado = this.clienteAMostrar;
    modalRef.componentInstance.creandoCliente = this.creandoCliente();
    modalRef.componentInstance.dirFiscal.subscribe(result => {
      var nuevaDireccionFiscal: Ubicacion = result;
      var anteriorDireccionFiscal: Ubicacion = this.clienteAMostrar.direccionFiscal;

      if( ! this.creandoCliente() && ! ( this.clienteAMostrar.tipoDocumento == TipoDocumento.Extranjero) && this.direccionEnArgentina(anteriorDireccionFiscal) && ! this.direccionEnArgentina(nuevaDireccionFiscal) ){
        // Solo puede cambiar pais si esta editando. En la creacion el campo pais de direccion fiscal esta read only.
        // Editando cliente argentino y cliente argentino pasa a ser extranjero.
        this.clienteSeleccionadoParaEditar.tipoDocumento = TipoDocumento.Extranjero;        
        this.tipoDoc.setValue(TipoDocumento.Extranjero);
        this.condicionIva = this.condicionIva.filter(c => c.codigoAFIP=="07" || c.codigoAFIP=="00");        
        this.condIva.setValue(this.condicionIva[0]);
      }

      this.clienteAMostrar.direccionFiscal = result;
    });
  }

  direccionEnArgentina(ubicacion: Ubicacion){
    if( !( this.clienteAMostrar.tipoDocumento == TipoDocumento.Extranjero) && ubicacion == null) return true; // es cliente argentino creado recientemento probablemente, y todavia no tiene direccion fiscal.

    return ubicacion?.ciudad?.provincia?.pais?.codigo == "0303";
  }

  mostrarIIBB() {
    let modalRef = this.activeModal.open(ModalCondicionIIBBComponent, { backdrop: 'static', centered: true, size: 'lg', windowClass: 'myCustomModalClass' });
    modalRef.componentInstance.clienteIIBB = this.clienteAMostrar; 
    modalRef.componentInstance.condicionIIBB.subscribe(result => {
      this.clienteAMostrar.configuracionProvincialImp = result;
    });
  }

  mostrarCBU() {
    let modalRef = this.activeModal.open(ModalCBUComponent, { backdrop: 'static', centered: true, size: 'lg' });
    modalRef.componentInstance.clienteCBU = this.clienteAMostrar;
    modalRef.componentInstance.todosCBU.subscribe(result => {
      this.clienteAMostrar.cbUs = result;
      this.clienteAMostrar.cbUs.sort((a , b) => a.tipoBancoInterlocutor - b.tipoBancoInterlocutor);
    });
  }

  mostrarResponsable() {
    let modalRef = this.activeModal.open(ModalResponsableComponent, { backdrop: 'static', centered: true });
    modalRef.componentInstance.clienteResponsable = this.clienteAMostrar;
    modalRef.componentInstance.respModificado.subscribe(result => {
      this.clienteAMostrar.responsable = result;
    });
  }

  mostrarDireccionEntrega() {
    let modalRef = this.activeModal.open(ModalDireccionEntregaComponent, { backdrop: 'static', centered: true });
    modalRef.componentInstance.clienteDireccionEntrega = this.clienteAMostrar;
    modalRef.componentInstance.editando = true;
    /*modalRef.componentInstance.dirEntrega.subscribe(result => {
      this.clienteAMostrar.direccionesEntrega = result;
    })*/
  }

  agregarDireccionEntrega() {
    let modalRef = this.activeModal.open(ModalDireccionEntregaComponent, { backdrop: 'static', centered: true });
    modalRef.componentInstance.clienteDireccionEntrega = this.clienteAMostrar;
    modalRef.componentInstance.editando = false;
    modalRef.componentInstance.dirEntrega.subscribe(result => {
      this.clienteAMostrar.direccionesEntrega.push(result);
    })
  }

  nuevaCuenta() {
    if (this.usuarioYaCreoCuenta) {
      this.messageService.showErrorMessage("Se puede crear de a una cuenta por vez.");
      return;
    }

    let modalRef = this.activeModal.open(ModalNuevaCuentaComponent, { backdrop: 'static', centered: true, size: 'lg' });
    modalRef.componentInstance.cliente = this.clienteAMostrar;
    modalRef.componentInstance.cuentaNueva.subscribe(result => {
      this.listaCuentasFormControlArray.push(this.nuevoFormCuentaItem(result));
      this.clienteAMostrar.cuentas.push(result);
      this.usuarioYaCreoCuenta = true;
    });
  }

  mostrarComercial() {
    let modalRef = this.activeModal.open(ModalComercialesComponent, { backdrop: 'static', centered: true });
    modalRef.componentInstance.clienteSeleccionado = this.clienteAMostrar;
    modalRef.componentInstance.comercialAgregado.subscribe(result => {
      this.clienteAMostrar.comerciales.push(result);
    })
  }

  formatDate(year: number, month: number, day: number, hour: number, minute: number): string {
    return formatDate(new Date(year, month - 1, day, hour, minute, 0), 'y-MM-ddTHH:mm:ss', this.locale);
  }

  guardarCuenta(i: number) {
    var cuentaForm = this.listaCuentasFormControlArray.controls[i].value;

    if (this.validarFormCuentas(i)) {

      let cuen = new Cuenta();
      cuen.id = this.clienteAMostrar.cuentas[i].id;
      if (cuentaForm.numeroCuentaFormControl == null) { cuen.ctA_SGC = this.clienteAMostrar.cuentas[i].ctA_SGC } else { cuen.ctA_SGC = cuentaForm.numeroCuentaFormControl };
      if (cuentaForm.identificadorFormControl == null) { cuen.descripcion = this.clienteAMostrar.cuentas[i].descripcion } else { cuen.descripcion = cuentaForm.identificadorFormControl; }
      if (cuentaForm.emailFormControl == null) { cuen.email = this.clienteAMostrar.cuentas[i].email } else { cuen.email = cuentaForm.emailFormControl; }
      if (cuentaForm.ctaSapCuentaFormControl == null) { cuen.ctaSap = this.clienteAMostrar.cuentas[i].ctaSap } else { cuen.ctaSap = cuentaForm.ctaSapCuentaFormControl; }
      if (cuentaForm.notificacionFormControl == null) { cuen.notificaPorEmail = this.clienteAMostrar.cuentas[i].notificaPorEmail } else { cuen.notificaPorEmail = cuentaForm.notificacionFormControl; }
      if (cuentaForm.activaCuentaFormControl == null) { cuen.activo = this.clienteAMostrar.cuentas[i].activo } else { cuen.activo = cuentaForm.activaCuentaFormControl; }
      if (cuentaForm.soloPADFormControl == null) { cuen.soloPAD = this.clienteAMostrar.cuentas[i].soloPAD } else { cuen.soloPAD = cuentaForm.soloPADFormControl }
      if (cuentaForm.plazoPagoFormControl == null) { cuen.condicionPago = this.clienteAMostrar.cuentas[i].condicionPago } else { cuen.condicionPago = cuentaForm.plazoPagoFormControl; }
      if (cuentaForm.datosAdicionalesFacturacionFormControl == null) { cuen.datosAdicionalesFacturacion = this.clienteAMostrar.cuentas[i].datosAdicionalesFacturacion } else { cuen.datosAdicionalesFacturacion = cuentaForm.datosAdicionalesFacturacionFormControl }
      cuen.moneda = this.clienteAMostrar.cuentas[i].moneda;
      cuen.periodoFacturacion = this.clienteAMostrar.cuentas[i].periodoFacturacion;
      cuen.tipoCuenta = this.clienteAMostrar.cuentas[i].tipoCuenta;
      cuen.tipoEntregaFactura = this.clienteAMostrar.cuentas[i].tipoEntregaFactura;
      cuen.agrupacionRemitos = this.clienteAMostrar.cuentas[i].agrupacionRemitos
      this.listaCuentasFormControlArray.controls[i].get('numeroCuentaFormControl').disable();
      this.puedeCrearCuenta = true;
      this.clienteAMostrar.cuentas[i] = cuen;
    }
  }

  validarFormCuentas(i) {
    var cuentaForm = this.listaCuentasFormControlArray.controls[i].value;
    var cuenta = this.clienteAMostrar.cuentas[i];
    if (cuentaForm.plazoPagoFormControl == null && cuenta.condicionPago == null ) {
      this.messageService.showErrorMessage("No selecciono valor para el campo plazo pago.");
      return false;
    }

    return true;
  }

  guardarCondicion(i: number) {
    var cuentaForm = this.listaCuentasFormControlArray.controls[i].value;

    this.clienteAMostrar.cuentas[i].tipoCuenta = cuentaForm.condicionVentaFormControl;
  }

  guardarMoneda(i: number) {
    var cuentaForm = this.listaCuentasFormControlArray.controls[i].value;
    this.clienteAMostrar.cuentas[i].moneda = cuentaForm.monedaFormControl;
  }

  guardarEntrega(i: number) {
    var cuentaForm = this.listaCuentasFormControlArray.controls[i].value;
    this.clienteAMostrar.cuentas[i].tipoEntregaFactura = cuentaForm.entregaFacturaFormControl;
  }

  guardarAgrupacion(i: number) {
    var cuentaForm = this.listaCuentasFormControlArray.controls[i].value;
    this.clienteAMostrar.cuentas[i].agrupacionRemitos = cuentaForm.agrupacionRemitosFormControl;
  }

  usuarioSeleccionoPeriodo(indexCuenta: number) {
    var cuentaForm = this.listaCuentasFormControlArray.controls[indexCuenta].value;

    if (cuentaForm.periodoFormControl == 7) {
      var diaEjecucion = (cuentaForm.diasFormControl == null ? 1 : cuentaForm.diasFormControl);

      this.clienteAMostrar.cuentas[indexCuenta].periodoFacturacion = this.deepCopy(this.perFacturacion.find(p => p.periodo == Number(cuentaForm.periodoFormControl) && p.diaEjecucion == diaEjecucion));
    } else {
      this.clienteAMostrar.cuentas[indexCuenta].periodoFacturacion = this.deepCopy(this.perFacturacion.find(p => p.periodo == Number(cuentaForm.periodoFormControl)));
    }
  }

  usuarioSeleccionoDiaEjecucion(indexCuenta: number) {
    var cuentaForm = this.listaCuentasFormControlArray.controls[indexCuenta].value;
    cuentaForm.periodoFormControl = 7;

    this.usuarioSeleccionoPeriodo(indexCuenta);
  }

  usuarioSeleccionoPlazoPago(indexCuenta: number){
    var cuentaForm = this.listaCuentasFormControlArray.controls[indexCuenta].value;
    this.clienteAMostrar.cuentas[indexCuenta].condicionPago == this.condicionesPago.find(p => p.codigo == cuentaForm.plazoPagoFormControl.codigo);
  }

  getPeriodosFacturacion() {
    this.cuentaService.getPeriodosFacturacion()
      .subscribe(result => {
        this.perFacturacion = result;
        this.entidadesDescargadas$.next();
      });
  }

  obtenerIndexPeriodo(periodo: PeriodosFacturacion) {
    if (periodo == null) {
      return 0;
    }

    if (periodo.periodo == 1) {
      return 0;
    }
    if (periodo.periodo == 7) {
      return 1;
    }
    if (periodo.periodo == 15) {
      return 2;
    }
    if (periodo.periodo == 30) {
      return 3;
    }

    return 0;

  }

  obtenerIndexDiasEjecucion(periodo: PeriodosFacturacion) {

    if (periodo == null) {
      return 0;
    }

    return periodo.diaEjecucion - 1;
  }

  obtenerCond(cond: string) {
    switch (cond) {
      case 'Indeterminado':
        return 0;
      case 'Contado':
        return 1;
      case 'CuentaCorriente':
        return 2
      case 'PrevioPago':
        return 3
      case 'Donacion':
        return 4
      case 'ConsumoInterno':
        return 5
    }
  }

  obtenerAgrup(agrup: string) {
    switch (agrup) {
      case 'DireccionEntrega':
        return 0;
      case 'Aeroplanta':
        return 1;
      case 'Provincia':
        return 2
    }
  }

  obtenerEntre(entrega: string) {
    switch (entrega) {
      case 'Email':
        return 0;
      case 'Fisico':
        return 1;
      case 'Ambos':
        return 2
    }
  }

  obtenerMon(mon: string) {
    switch (mon) {
      case 'PES':
        return 0;
      case 'USD':
        return 1;
    }
  }

  guardarCliente() {
    let completo = true;
    let string = "";    

    if (this.clienteAMostrar.cuentas == null || this.clienteAMostrar.cuentas.length == 0) {
      string += ' Falta agregar una cuenta al cliente.';
      completo = false;
    }

    if (this.clienteAMostrar.direccionFiscal == null || this.clienteAMostrar.direccionFiscal.direccion == null ||  this.clienteAMostrar.direccionFiscal.numero == null) {
      string += ' Falta agregar la direccion Fiscal del cliente.';
      completo = false;
    }

    if(!this.esElectrica) {
      if (this.clienteAMostrar.configuracionProvincialImp == null) {
        string += ' Falta agregar la condicion de IIBB del cliente';
        completo = false;
      }
      if (this.clienteAMostrar.comerciales == null || this.clienteAMostrar.comerciales.length == 0) {
        string += ' Falta agregar un comercial al cliente.';
        completo = false;
      }

      if (this.clienteAMostrar.responsable == null || this.clienteAMostrar.responsable.nombreCompleto == '' || this.clienteAMostrar.responsable.nombreCompleto == '') {
        string += ' Falta agregar un responsable al cliente.';
        completo = false;
      }
    }

    if (this.condIva.value == null || this.condIva.value == '') {
      string += ' Falta agregar la condicion de Iva.';
      completo = false;
    }

    if ((!this.esElectrica) && (this.SAPCuenta.value == null || this.SAPCuenta.value == '')) {
      string += ' Falta agregar la cuenta SAP al cliente.';
      completo = false;
    }

    if (this.subArea.value == null || this.subArea.value == '') {
      string += ' Falta agregar el SubArea correspondiente al cliente.';
      completo = false;
    }

    if (this.area.value == '' || this.area.value == null) {
      string += ' Falta agregar el Área correspondiente al cliente.';
      completo = false;
    }

    if (!this.creandoCliente() && !this.esElectrica && (this.detalleModificacionFormControl.value == null || this.detalleModificacionFormControl.value.length == 0)) {
      string += 'Falta agregar un motivo de modificación.';
      completo = false;
    }

    if (this.actividad.value == null) {
      string += 'Falta agregar actividad PAD.';
      completo = false;
    }

    // Agregar validacion manual de todos los campos que falten validar obligatorios

    if (this.informacionCliente.valid && completo) {
      this.guardandoCliente = true;
      /** 
       * CLIENTES PAD AEROPLANTAS
       */
      if(!this.esElectrica) {
        let cli = new Cliente();
        cli.usuarioCreo = this.armarUsuario();
  
        if (this.creandoCliente() && this.authService.hasRole(TipoUsuario.SUPERVISOR, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO)) {
          cli.usuarioAprobo = this.armarUsuario(); // solo en la creación
        }
        cli.detalle = this.detalleModificacionFormControl.value;
        // se agrega ADMCLIENTES
        if (this.authService.hasRole(TipoUsuario.ADMCLIENTES)) {
          cli.usuarioAprobo = this.armarUsuario(); // creación y modificacion
        }      
        cli.razonSocial = this.razonSocial.value;
        cli.tipoDocumento = this.tipoDoc.value;
        cli.numeroDocumento = this.numDocumento.value;
        cli.tipoPersona = this.clienteAMostrar.tipoPersona;
        cli.condicionIva = this.condIva.value;
        cli.actividadPAD = parseInt(this.actividad.value);
        cli.grupoCliente = this.agrupacionSerc.value;
        cli.ctaSap = this.SAPCuenta.value;
        cli.ctA_CTC = this.CTCCuenta.value;
        //cli.percepcionIVADif = parseFloat(this.porcentajeIva.value);
        //cli.fechaPercepcionIVADif = this.formatDate(this.fecha.value.year, this.fecha.value.month, this.fecha.value.day, 0, 0);
        cli.activo = this.activo.value;
        cli.clienteObservado = this.observado.value;
        cli.direccionFiscal = this.clienteAMostrar.direccionFiscal;
        cli.direccionesEntrega = this.clienteAMostrar.direccionesEntrega;
        if(cli.direccionesEntrega!=null) {
          if (cli.direccionFiscal.piso == '' || cli.direccionFiscal.piso == null) {
            cli.direccionFiscal.piso = '0';
          }
          if (cli.direccionFiscal.departamento == '' || cli.direccionFiscal.departamento == null) {
            cli.direccionFiscal.departamento = '0';
          }          
        }
        cli.comerciales = this.clienteAMostrar.comerciales;
        cli.configuracionProvincialImp = this.clienteAMostrar.configuracionProvincialImp;
        cli.cbUs = this.clienteAMostrar.cbUs;
        if(this.area.value!=null) {
          cli.subArea = this.subArea.value;
          cli.subArea.area = this.area.value;        
        }      
        cli.viaDePago = this.viaPago.value;
        cli.cuentas = this.clienteAMostrar.cuentas;
        cli.responsable = this.clienteAMostrar.responsable;
  
        for (let i = 0; i < this.listaCuentasFormControlArray.controls.length; i++) {
          this.listaCuentasFormControlArray.controls[i].get('numeroCuentaFormControl').disable();
        }
  
        cli.archivosAdjuntos = this.archivosSubidos;
  
        if (this.creandoCliente()) {
          this.clienteService.crearCliente(cli)
            .subscribe(result => {
              this.clienteCreado.emit(result);
              if (!result.esNuevo) {
                this.messageService.showErrorMessage('El cliente con el documento: ' + result.numeroDocumento + ' ya existe;');
                this.puedeCrearCuenta = true;
                this.clienteAMostrar = result;
              } else {
                this.messageService.showSuccessMessage('Cliente creado con éxito.');
                this.puedeCrearCuenta = true;
              }
              this.guardandoCliente = false;
              this.clienteAMostrar = result;
            }, (err: ErrorModel) => {
              this.messageService.showErrorMessage('Hubo un error al crear el cliente.');
              this.guardandoCliente = false;
            });
        } else {
          this.clienteService.modificarCliente(cli, this.clienteAMostrar.tipoDocumento)
            .subscribe(result => {
              this.messageService.showSuccessMessage('Cliente modificado con éxito.');
              this.guardandoCliente = false;
              this.clienteAMostrar = result;
            }, (err: ErrorModel) => {
              this.messageService.showErrorMessage('Hubo un error al modificar el cliente.');
              this.guardandoCliente = false;
            })
        }
      }else{
        /**
         * CLIENTES DE ELECTRICA - MELEC
         */
         let cli = new Cliente();
         cli.usuarioCreo = this.armarUsuario();
         cli.razonSocial = this.razonSocial.value;
         cli.tipoDocumento = this.tipoDoc.value;
         cli.numeroDocumento = this.numDocumento.value;
         cli.condicionIva = this.condIva.value;
         cli.cuentas = this.clienteAMostrar.cuentas;
         cli.activo = this.activo.value;
         cli.direccionFiscal = this.clienteAMostrar.direccionFiscal;
         cli.direccionesEntrega = this.clienteAMostrar.direccionesEntrega;
         this.clienteService.modificarCliente(cli, this.tipoDoc.value)
            .subscribe(result => {
              this.messageService.showSuccessMessage('Cliente modificado con éxito.');
              this.guardandoCliente = false;
              this.clienteAMostrar = result;
            }, (err: ErrorModel) => {
              this.messageService.showErrorMessage('Hubo un error al modificar el cliente.');
              this.guardandoCliente = false;
            })
      }      
    } else {
      this.messageService.showErrorMessageClosed('Por favor revise los datos a completar. ' + string, true);
      this.guardandoCliente = false;
    }
  }

  keysTipoEntrega(): Array<string> {
    var keys = Object.keys(this.tipoEntregaFacturaEnum);
    keys = keys.slice(keys.length / 2);
    return keys;
  }

  keysAgrupacion(): Array<string> {
    var keys = Object.keys(this.agrupacionRemitosEnum);
    keys = keys.slice(keys.length / 2);
    return keys;
  }

  usuarioModificaCampos(): boolean {
    return this.authService.hasRole(TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.APROBADORBYR, TipoUsuario.ADMCLIENTES);
  }

  desactivarBotonGuardarPorRoles(){
    if(this.authService.hasRole(TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.APROBADORBYR, TipoUsuario.ADMCLIENTES)){
      return false;
    }

    if (this.supervisorCreandoClienteCompleto()) {
      return false;
    }

    return true;
  }
/*
  desactivarBotonCuentaSAP(){    
    return this.SAPCuenta.value != null;
  }
*/
  armarUsuario() {
    var r = new Responsable();
    r.UserName = this.usuarioService.getUsername();
    r.NombreCompleto = this.usuarioService.getNombresCompletosUsuario();
    r.DNI = this.usuarioService.getDniUsuario();

    return JSON.stringify(r);
  }

  supervisorCreandoClienteCompleto() {
    if (this.creandoCliente() && this.authService.hasRole(TipoUsuario.SUPERVISOR, TipoUsuario.OPERADOR_3 )) {
      if (this.creandoClienteAfip()) {
        if (this.SAPCuenta.value != null && this.SAPCuenta.value != '') {
          return true;
        } 
      } 
    } 

    return false;
  }

  onArchivoSubido(event) {
    this.archivosSubidos.push(event);
  }

  creandoCliente(){
    return this.clienteSeleccionadoParaEditar == null;
  }

  creandoClienteAfip(){
    return this.clienteAFIP != null;
  }

  creandoClienteExtranjero(){
    return this.clienteExtranjero != null;
  }

  clienteParaEditarEsExtranjero(){
    return this.clienteSeleccionadoParaEditar.tipoDocumento == TipoDocumento.Extranjero;
  }

  obtenerTextoPlazoPago(c: Cuenta){
    return "(" + c.condicionPago.codigo + ") " + c.condicionPago.descripcion;
  }

  customCompareCondicionPago(c1: PlazoPagoCliente, c2: PlazoPagoCliente) {
    return c1?.id == c2?.id;
  }

  customCompareViaPago(v1: ViaDePago, v2: ViaDePago) {
    return v1?.codigo == v2?.codigo;
  }

  rolesQuePuedenConsultarAFIP(): boolean {
    return this.authService.hasRole(TipoUsuario.SUPERVISOR, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO);    
  }
  // getters

  get razonSocial() {
    return this.informacionCliente.get('razonSocialFormControl');
  }
  get numDocumento() {
    return this.informacionCliente.get('numeroDocumentoFormControl');
  }
  get condIva() {
    return this.informacionCliente.get('condicionIVAFormControl');
  }
  get area() {
    return this.informacionCliente.get('areaFormControl');
  }
  get subArea() {
    return this.informacionCliente.get('subAreaFormControl');
  }
  get tipoDoc() {
    return this.informacionCliente.get('tipoDocumentoFormControl');
  }
  get actividad() {
    return this.informacionCliente.get('actividadFormControl');
  }
  get viaPago() {
    return this.informacionCliente.get('viaDePagoFormControl');
  }

  get agrupacionSerc() {
    return this.informacionCliente.get('agrupacionSercFormControl')
  }
  
  get grupoCliente() {
    return this.informacionCliente.get('grupoClienteFormControl');
  }
  get SAPCuenta() {
    return this.informacionCliente.get('cuentaSAPFormControl');
  }
  get CTCCuenta() {
    return this.informacionCliente.get('cuentaCTCFormControl');
  }
  get activo() {
    return this.informacionCliente.get('activoFormControl');
  }
  get observado() {
    return this.informacionCliente.get('clienteObservadoFormControl');
  }
  /*get porcentajeIva() {
    return this.informacionCliente.get('porcentajeIVAFormControl');
  }
  get fecha() {
    return this.informacionCliente.get('fechaFormControl');
  }*/
  get detalleModificacionFormControl() {
    return this.informacionCliente.get('detalleModificacionFormControl');
  }

  get listaCuentasFormControlArray(): UntypedFormArray {
    return this.informacionCliente.controls.listaCuentasFormControlArray as UntypedFormArray;
  }

  get esElectrica() {
    return environment.electrica
  }
}
