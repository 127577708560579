<ul class="nav nav-tabs justify-content-start">
    <li *ngIf="hasRole(TipoUsuario.OPERADOR, TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN)"
        class="nav-item"><a class="nav-link" routerLink="grupos-tanques" routerLinkActive="active">Islas y grupos</a>
    </li>
    <li *ngIf="hasRole(TipoUsuario.OPERADOR, TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN)"
        class="nav-item"><a class="nav-link" routerLink="tanques" routerLinkActive="active">Tanques</a>
    </li>
    <li *ngIf="hasRole(TipoUsuario.OPERADOR, TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN)"
        class="nav-item"><a class="nav-link" routerLink="abastecedoras" routerLinkActive="active">Abastecedoras</a>
    </li>
    <li *ngIf="hasRole(TipoUsuario.OPERADOR, TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN)"
        class="nav-item"><a class="nav-link" routerLink="surtidores" [queryParams]="{ tipoSurtidor: 0 }" routerLinkActive="active">Surtidores</a> 
    </li>
    <li *ngIf="hasRole(TipoUsuario.OPERADOR, TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN)"
        class="nav-item"><a class="nav-link" routerLink="dispenser" [queryParams]="{ tipoSurtidor: 1 }" routerLinkActive="active">Dispenser</a>
    </li>
    <li *ngIf="hasRole(TipoUsuario.OPERADOR, TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN)"
        class="nav-item"><a class="nav-link" routerLink="canerias" routerLinkActive="active">Cañerias</a>
    </li>
    <li *ngIf="hasRole(TipoUsuario.OPERADOR, TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN)"
        class="nav-item"><a class="nav-link" routerLink="equipos-fijos" routerLinkActive="active">Equipos fijos</a>
    </li>
    <li *ngIf="hasRole(TipoUsuario.OPERADOR, TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN)"
        class="nav-item"><a class="nav-link" routerLink="sistemas-seguridad" routerLinkActive="active">Sistemas de seguridad</a>
    </li>
    <li *ngIf="hasRole(TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.JEFEDEAEROPLANTA)"
        class="nav-item"><a class="nav-link" class="nav-link" routerLink="aeroplantas" routerLinkActive="active">Aeroplantas</a>
    </li>
</ul>

<router-outlet></router-outlet>