import { Component, OnInit, Input, Output } from '@angular/core';
import { AerovaleDetalle } from 'src/app/shared/models/despacho/AerovaleDetalle';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { AerovalesService } from '../aerovales.service';
import { Observacion } from 'src/app/shared/models/despacho/Observacion';
import { ModalOkComponent } from 'src/app/shared/generic-modals/modal-ok.component';
import { UsuarioService } from 'src/app/account/usuario.service';
import { Usuario } from 'src/app/shared/models/despacho/Usuario';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { MessageService } from 'src/app/shell/message.service';

@Component({
  selector: 'app-modal-anulacion-aerovale',
  templateUrl: './modal-anulacion-aerovale.component.html',
  styleUrls: ['./modal-anulacion-aerovale.component.css']
})
export class ModalAnulacionAerovaleComponent implements OnInit {

  @Input() detalle: AerovaleDetalle;

  anularForm: UntypedFormGroup;
  motivos: Observacion[] = [];
  anulando: boolean = false;
  cargandoObservaciones: boolean = false;
  errorObservaciones: boolean = false;

  constructor(public modal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private aerovaleService: AerovalesService,
    private messageService: MessageService,
    private modalAceptar: NgbModal,
    private usuarioService: UsuarioService) { }

  // hooks

  ngOnInit() {
    this.anularForm = this.fb.group({
      motivoFormControl: new UntypedFormControl(null, [Validators.required]),
      descripcionFormControl: new UntypedFormControl(null, [Validators.required])
    });
    this.llenarMotivos();
  }

  // form


  completarPrefijo(prefijo: string) {
    return (prefijo+"").padStart(3, '0')
  }

  completarNumero(numero: string) {
    return (numero+"").padStart(5, '0')
  }

  llenarMotivos() {
    this.cargandoObservaciones = true;
    this.aerovaleService.getMotivosAnulacion(1)
      .subscribe(result => {
        this.motivos = result;
        this.cargandoObservaciones = false
      },
        () => this.errorObservaciones = true);
  }

  armarUsuario(){
    let u = new Usuario();
    u.id = this.usuarioService.getIdUsuario();
    u.nombreCompleto = this.usuarioService.getNombresCompletosUsuario();
    u.userName = this.usuarioService.getUsername();
    u.dni = this.usuarioService.getDniUsuario();

    return u;
  }

  // llamada al service

  anularAerovale() {
    this.anulando = true;
    if (this.anularForm.valid) {
      this.detalle.comentarios = this.comentarios.value;
      this.detalle.observacionId = this.observacion.value;
      
      let usuario = this.armarUsuario();
      
      this.aerovaleService.anularAerovale(this.detalle.id, this.detalle.comentarios, this.detalle.observacionId, usuario)
        .subscribe(result => {
          if (result) {
            const modalRef = this.modalAceptar.open(ModalOkComponent, { centered: true });
            modalRef.componentInstance.mensaje = "El aerovale fue anulado correctamente";
            modalRef.result.then(() => {              
              this.modal.close();
              this.anularForm.reset();            
            });
          } else {
            const modalRef = this.modalAceptar.open(ModalOkComponent, { centered: true });
            modalRef.componentInstance.mensaje = "El aerovale no pudo ser anulado";
            modalRef.result.then(() => {
            });
          }
          this.anulando = false;
        }, (err:ErrorModel)=> {
          this.anulando = false;
          this.messageService.showErrorMessage(err.message);
        });
    }
  }

  // getters

  get comentarios() {
    return this.anularForm.get('descripcionFormControl');
  }

  get observacion() {
    return this.anularForm.get('motivoFormControl');
  }

}
