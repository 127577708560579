<form [formGroup]="form">

  <div class="modal-header">
    <h4 class="modal-title">{{ this.editandoVehiculo ? 'Editar' : 'Agregar' }} Vehiculo</h4>
  </div>
  
  <div class="modal-body">  
    <div class="row">
      <div class="mb-3 row col-md-12">
        <label class="col-sm-12 col-form-label">Tipo de vehiculo:</label>
        <div class="btn-group btn-group-toggle col-sm-8">
          <fieldset [disabled]="this.editandoVehiculo || bloquearSeleccionVehiculo()" (change)="changeTipoVehiculo()">                                  
              <span class="me-4"><input type="radio" id="tipoAeronave" [value]="0" formControlName="tipoVehiculoFormControl"/> Aeronave</span>
              <input type="radio" id="tipoAutomotor" [value]="1" formControlName="tipoVehiculoFormControl"/> Automotor                
          </fieldset>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="mb-3 row col-md-12">
        <label for="matriculaFormControl" class="col-sm-5 col-form-label">Matrícula:</label>
        <div class="col-sm-7">
          <input [readonly]="this.editandoVehiculo || this.desactivarInput" class="form-control" placeholder="Matrícula" 
          formControlName="matriculaFormControl" maxlength="10">
          <div *ngIf="matriculaFormControl.invalid && matriculaFormControl.errors.required && (matriculaFormControl.dirty || matriculaFormControl.touched)"
              class="alert alert-danger" role="alert">Requerido</div>
            <div *ngIf="matriculaFormControl.invalid && matriculaFormControl.errors.pattern && (matriculaFormControl.dirty || matriculaFormControl.touched)"
              class="alert alert-danger" role="alert">Se aceptan sólo letras y números</div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="mb-3 row col-md-12">
        <label for="productoFormControl" class="col-sm-5 col-form-label">Producto:</label>
        <div class="col-sm-7">
          <select id="productoFormControl" class="form-select" formControlName="productoFormControl"> 
            <option *ngFor="let p of productosFiltrados" [ngValue]="p">
              {{p.nombreProducto}}</option>
          </select>
          <loading-text-spinner 
            *ngIf="cargandoProductos" message="Obteniendo productos" color="primary">
          </loading-text-spinner>
          <div
            *ngIf="productoFormControl.invalid && productoFormControl.errors.required && (productoFormControl.dirty || productoFormControl.touched)"
            class="alert alert-danger" role="alert">
            Requerido
          </div>
        </div>
      </div>
    </div>
    <label *ngIf="matriculaRepetida()" class="alert alert-warning">
      La matricula ingresada se encuentra en uso, se sobreescribiran los datos del vehiculo.
    </label>
  </div>
    
  <div class="modal-footer">
    <div *ngIf="error != null" class="alert-danger">
      {{ error }}
    </div>
    <span class="border">
      <button class="btn btn-primary" [disabled]="guardando || !form.valid" (click)="guardarCambios()">
        {{ guardando? "Guardando..." : "Guardar" }}
      </button>
    </span>
    <span class="border">
      <button type="button" class="btn btn-secondary" [disabled]="guardando" (click)="activeModal.close()">Cancelar</button>
    </span>
  </div>
</form>
    