<div class="card add-margin" *ngIf="this.rubroSeleccionado!=null">

  <h4 class="card-header card-title ">Informacion </h4>
  <div class="card-body">
    <form [formGroup]="informacionForm">
      <div class="row">
        <!-- codigo -->
        <div class="mb-3 col-md-6">
          <div class="mb-3 row">
            <div class="col-md-4">
              <label for="codigoControlInput" class="col-form-label"> <span
                  *ngIf="campoInicializadoEnVacio(this.codigo.value)">*</span> Codigo:</label>
            </div>
            <div class="col-md-4">
              <input id="codigoControlInput" type="text" class="form-control mb-2 me-sm-2"
                title="El campo codigo no se puede modificar" formControlName="codigoFormControl" placeholder="###"
                [value]="this.rubroSeleccionado.codigoRubro"/>
            </div>
          </div>
        </div>


        <!-- Nombre -->
        <div class="mb-3 col-md-6">
          <div class="mb-3 row">
            <div class="col-md-4">
              <label for="descripcionControlInput" class="col-form-label"> <span
                  *ngIf="campoInicializadoEnVacio(this.descripcion.value)">*</span> Nombre: </label>
            </div>
            <div class="col-md-8">
              <input id="descripcionControlInput" type="text" class="form-control mb-2 me-sm-2"
                title="Campo obligatorio, debe ingresar un nombre del producto."
                formControlName="descripcionFormControl" placeholder="###" [value]="this.rubroSeleccionado.nombre" />
            </div>
          </div>
        </div>


        <!-- Tipo Neto-->
        <div class="mb-3 col-md-6">
          <div class="mb-3 row">
            <div class="col-md-4">
              <label for="tipoNetoControlInput" class="col-form-label">Tipo Neto</label>
            </div>
            <div class="col-md-8">
              <select type="text" class="form-select" name="tipoID" formControlName="tipoNetoRubroFormControl"
                title="Seleccione un tipo de Neto. Campo requerido.">
                <option id="optionTipoNeto" *ngFor="let neto of keysTiposNeto()" [ngValue]="tipoNeto[neto]">
                  {{ neto }}
                </option>
              </select>
            </div>
          </div>
        </div>

      </div>

      <!-- Combustible -->
      <!-- <form [formGroup]="checkForm"> -->
      <div class="row">
        <div class="col-sm-3">
          <div class="mb-3 row">
            <div class="col-sm-8">
              <div class="form-check">
                <input type="radio" class="form-check-input" id="exampleCheck1" value="combustible"
                  formControlName="combustibleFormControl" [checked]="this.rubroSeleccionado.combustible" />
                <label class="form-check-label" for="exampleCheck1">Combustible</label>
              </div>
            </div>
          </div>
        </div>
        <!--Aerocombustible-->
        <div class="col-sm-3">
          <div class="mb-3 row">
            <div class="col-sm-8">
              <div class="form-check">
                <input type="radio" class="form-check-input" id="exampleCheck2" value="aerocombustible"
                  formControlName="combustibleFormControl" [checked]="this.rubroSeleccionado.aerocombustible" />
                <label class="form-check-label" for="exampleCheck2">Aerocombustible</label>
              </div>
            </div>
          </div>
        </div>
        <!--Aeroespecialidad-->
        <div class="col-sm-3">
          <div class="mb-3 row">
            <div class="col-sm-8">
              <div class="form-check">
                <div class="form-check">
                  <input type="radio" class="form-check-input" id="exampleCheck3" value="aeroespecialidad"
                    formControlName="combustibleFormControl" [checked]="this.rubroSeleccionado.aeroespecialidad" />
                  <label class="form-check-label" for="exampleCheck3">Aeroespecialidad</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--Servicios-->
        <div class="col-sm-3">
          <div class="mb-3 row">
            <div class="col-sm-8">
              <div class="form-check">
                <div class="form-check">
                  <input type="radio" class="form-check-input" id="exampleCheck4" value="servicios"
                    formControlName="combustibleFormControl" [checked]="this.rubroSeleccionado.servicios" />
                  <label class="form-check-label" for="exampleCheck4">Servicios</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="card-footer d-flex justify-content-center">
      <span class="border">
        <button *ngIf="authService.hasRole(TipoUsuario.OPERADORDIRECTO) === false" class="btn btn-primary" type="button" (click)="modificarRubro()" [disabled]="modificandoRubro">
          <span *ngIf="!modificandoRubro">Modificar</span>
          <div *ngIf="modificandoRubro" class="d-flex align-items-center">
            <div class="spinner-border text-light spinner-border-sm" role="status"></div>
            <span>Modificando...</span>
          </div>
        </button>
      </span>
    </div>
    <!-- </div> -->
    <!-- </form> -->
    <!-- </div> -->
  </div>