<form [formGroup]="form" (ngSubmit)="onSubmit()">

  <div class="modal-header bg-light">
    <h4 class="modal-title">{{ editando ? "Modificar tanque" : "Nuevo tanque" }}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()"></button>
  </div>

  <div class="modal-body">

    <div class="row">
      <div class="mb-3 row col-md-12">
        <label for="codigoFormControl" class="col-md-5 col-form-label">*Codigo:</label>
        <div class="col-md-7">
          <input class="form-control" formControlName="codigoFormControl" maxlength="50" disabled>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="mb-3 row col-md-12">
        <label for="nombreFormControl" class="col-md-5 col-form-label">*Nombre:</label>
        <div class="col-md-7">
          <input class="form-control" formControlName="nombreFormControl" maxlength="50">
          <div
            *ngIf="nombreFormControl.invalid && nombreFormControl.errors.required && (nombreFormControl.dirty || nombreFormControl.touched)"
            class="alert alert-danger">Requerido</div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="mb-3 row col-md-12">
        <label for="productoFormControl" class="col-md-5 col-form-label">Producto:</label>
        <div class="col-md-7">
          <select [compareWith]="compareProductos" id="productoFormControl" class="form-select"
            ngbTooltip="Seleccione el producto." formControlName="productoFormControl" (change)="validarCambioProductoTanque()">
            <option *ngFor="let producto of productos" [ngValue]="producto" >
              ({{ producto.codigoProducto }}) - {{ producto.nombreProducto }}
            </option>
          </select>
          <loading-text-spinner *ngIf="cargandoProductos || cargandoTanques || cargandoSurtidores" [error]="errorProductos" message="Obteniendo productos..."
            color="primary">
          </loading-text-spinner>
          <div class=" alert alert-danger"
            *ngIf="(this.productoFormControl.invalid && this.productoFormControl.errors.required && (this.productoFormControl.dirty || this.productoFormControl.touched))">
            Debe seleccionar un producto obligatoriamente.
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="mb-3 row col-md-12">
        <label for="capacidadFormControl" class="col-md-5 col-form-label">*Capacidad:</label>
        <div class="input-group col-md-7">
          <input class="form-control" formControlName="capacidadFormControl" maxlength="12"
            (keypress)="this.onlyDecimalPositivo($event)" (input)="this.capacidadMayorQueVolumenInextraible();">
          <div class="input-group-append">
            <div class="input-group-text">Lts.</div>
          </div>
          <div
            *ngIf="capacidadFormControl.invalid && capacidadFormControl.errors.required && (capacidadFormControl.dirty || codigoFormControl.touched)"
            class="col-md-12 alert alert-danger">Requerido</div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="mb-3 row col-md-12">
        <label for="tipoTanqueFormControl" class="col-md-5 col-form-label">Tipo de tanque:</label>
        <div class="col-md-7">
          <select type="text" class="form-select" name="tipoTanqueFormControl" formControlName="tipoTanqueFormControl">
            <option *ngFor="let tipo of keysTipoTanque()" [ngValue]="enumTipoTanque[tipo]">
              {{ getDescripcionTipoTanque(enumTipoTanque[tipo]) }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="mb-3 row col-md-12">
        <label for="numeroCertificadoFormControl" class="col-md-5 col-form-label">*Número de certificado:</label>
        <div class="col-md-7">
          <input class="form-control" formControlName="numeroCertificadoFormControl" maxlength="50">
          <div
            *ngIf="numeroCertificadoFormControl.invalid && numeroCertificadoFormControl.errors.required && (numeroCertificadoFormControl.dirty || numeroCertificadoFormControl.touched)"
            class="alert alert-danger">Requerido</div>
          <div *ngIf="this.chequearCertificado()" class="alert alert-danger">El certificado esta en uso.</div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="mb-3 row col-md-12">
        <label for="volumenInextraibleControl" class="col-md-5 col-form-label">*Volúmen inextraible:</label>
        <div class="input-group col-md-7">
          <input class="form-control" formControlName="volumenInextraibleControl" maxlength="10"
            (keypress)="this.onlyDecimalPositivo($event);" (input)="this.capacidadMayorQueVolumenInextraible();">
            <span class="input-group-text">Lts.</span>
          <div
            *ngIf="volumenInextraibleControl.invalid && volumenInextraibleControl.errors.required && (volumenInextraibleControl.dirty || volumenInextraibleControl.touched)"
            class="col-md-12 alert alert-danger">Requerido</div>
            <div
            *ngIf="!this.capacidadMayor"
            class="col-md-12 alert alert-danger">Supera la capacidad del tanque</div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="mb-3 row col-md-12">
        <label for="toleranciaMaximaFormControl" class="col-md-5 col-form-label">*Tolerancia máxima:</label>
        <div class="input-group col-md-7">
          <input class="form-control" formControlName="toleranciaMaximaFormControl" maxlength="10"
            (keypress)="this.onlyDecimalPositivo($event)">
            <span class="input-group-text">Lts.</span>
          <div
            *ngIf="toleranciaMaximaFormControl.invalid && toleranciaMaximaFormControl.errors.required && (toleranciaMaximaFormControl.dirty || toleranciaMaximaFormControl.touched)"
            class="col-md-12 alert alert-danger">Requerido</div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="mb-3 row col-md-12">
        <label for="minimoHorasReposoFormControl" class="col-md-5 col-form-label">*Mínimo reposo:</label>
        <div class="input-group col-md-7">
          <input class="form-control" formControlName="minimoHorasReposoFormControl" maxlength="10"
            (keypress)="this.onlyDecimalPositivo($event)">
            <span class="input-group-text">Hs.</span>
          <div
            *ngIf="minimoHorasReposoFormControl.invalid && minimoHorasReposoFormControl.errors.required && (minimoHorasReposoFormControl.dirty || minimoHorasReposoFormControl.touched)"
            class="col-md-12 alert alert-danger">Requerido</div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="d-flex flex-row mb-3">
        <label for="llenoVacioFormControl" class="col-md-5 col-form-label">*Tipo medicion:</label>

        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="inlineRadioOptions" id="checkLLeno" value="1" formControlName="llenoVacioFormControl">
          <label class="form-check-label" for="checkLLeno">Lleno</label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="inlineRadioOptions" id="checkVacio" value="0" formControlName="llenoVacioFormControl">
          <label class="form-check-label" for="checkVacio">Vacio</label>
        </div>
      </div>
    </div>

  </div>
  <div class="modal-footer bg-light">
    <span class="border">
      <button class="btn btn-primary" [disabled]="!form.valid || !capacidadMayor || guardando" type="submit">
        {{ guardando? "Guardando..." : "Guardar cambios" }}
      </button>
    </span>
    <span class="border">
      <button type="button" class="btn btn-secondary" [disabled]="guardando"
        (click)="activeModal.dismiss('Close clicked')">Cancelar</button>
    </span>
  </div>
</form>
