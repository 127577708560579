<div class="modal-header bg-light">
  <h4 class="modal-title">Corrección de despacho </h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()"></button>
</div>
<form [formGroup]="form">
  <div class="modal-body">
    <div class="row">
      <div class="mb-3 row col-md-12">
        <label for="comentariosFormControl" class="col-md-5 col-form-label">*Comentarios:</label>
        <div class="col-md-7">
          <textarea formControlName="comentariosFormControl" rows="4" cols="27">
          </textarea>
        </div>
      </div>
    </div>
    <small class="text-danger"> *Campos obligatorios.</small>
  </div>
  <div class="modal-footer bg-light">
    <div *ngIf="error != null" class="alert alert-danger">{{error}}</div>
    <button class="btn btn-primary me-2" id="aceptar" [disabled]="!this.puedeSolicitarRefactura() || this.guardando"
      (click)="this.onSubmit()">
      <span *ngIf="!guardando">Guardar</span>
      <div *ngIf="guardando" class="d-flex align-items-center">
        <div class="spinner-border text-light spinner-border-sm" role="status"></div>
        <span>Guardando...</span>
      </div>
    </button>
    <button type="button" class="btn btn-secondary" [disabled]="guardando"
      (click)="activeModal.dismiss('Close clicked')">Cancelar</button>
  </div>
</form>