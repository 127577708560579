<div *ngIf="this.facturaObtenida===null">
  <ngx-spinner name="spinnerLista" bdOpacity=0.3bd Color="rgba(193,193,193,0)" size="medium" color="#185db5"
    [ngStyle]="{'height': this.facturaObtenida? '130px': '0px', 'z-index':'0'}" type="ball-grid-beat"
    [fullScreen]="false">
    <p style="color: white"> Obteniendo Comprobante . . .
  </ngx-spinner>
</div>
<div *ngIf="facturaObtenida!=null || facturaObtenida.tipoMovimiento==9" id="invoicingPrint"
  class="card list-group facturaf">
  <div>
    <ngx-spinner name="spinnerExportar" bdOpacity=0.3bd Color="rgba(193,193,193,0)" size="large" color="#185db5"
      [ngStyle]="{'height': this.facturaObtenida? '180px': '0px', 'z-index':'0'}" type="ball-grid-beat"
      [fullScreen]="false">
      <p style="color: white"> Exportando Comprobante . . .</p>
    </ngx-spinner>
  </div>
  <div class="card-body" [ngClass]="{'facturaPrint' : this.mostrarMensajeFacturacionEnPruebas}">
    <div class="container">
      <div class="row justify-content-sm-center">
        <div class="col-sm-auto border text-center line-small">
          <p class="h3">{{facturaObtenida.tipoMovimiento | tipoMovimiento }}</p>
          <small>Cod.{{'0'+(facturaObtenida.tipoMovimiento)}}</small>
        </div>
      </div>
      <div *ngIf="this.facturaObtenida.fechaAnulacion != null" class="me-auto">

      </div>
      <div class="justify-content-md-center d-flex">
        <div id="emisorData" class="col border">
          <ul class="list-unstyled js">
            <li class="letra">
              <img id="logo-ypf" src="../../../assets/images/logoypf.png" />
            </li>
            <li class="letra">{{facturaObtenida.emisor.razonSocial}}</li>
            <li class="letra">
              {{facturaObtenida.emisor.direccion}},&nbsp;{{facturaObtenida.emisor.ciudad.nombre}}&nbsp;({{facturaObtenida.emisor.ciudad.codigoPostal}})
            </li>
            <li class="letra">
              {{facturaObtenida.emisor.ciudad.provincia.nombre}},&nbsp;{{facturaObtenida.emisor.ciudad.provincia.pais}}
            </li>
            <li class="letra">T.E.: {{facturaObtenida.emisor.telefono}}</li>
            <li class="letra" *ngIf="!esElectrica">{{facturaObtenida.emisor.email}}</li>
          </ul>
        </div>
        <div id="comprobanteInfo" class="col border"
          *ngIf="this.facturaObtenida!=null && this.facturaObtenida.factura!=null">
          <ul class="list-unstyled js">
            <li class="text-end letra">{{facturaObtenida.tipoMovimiento | nombreTipoComprobante:facturaObtenida.factura.condicionVenta}} N°:
              <b>{{ facturaObtenida.prefijo | facturaPrefijo }}-{{ facturaObtenida.numeroComprobante | facturaNumero }}</b>
            </li>
            <li class="text-end letra">Original</li>
            <li *ngIf="facturaObtenida && facturaObtenida.factura" class="text-end letra">Fecha:
              {{facturaObtenida.fechaEmision | date:"dd/MM/yyyy"}}</li>
            <li class="text-end letra">F.VTO: {{facturaObtenida.fechaVencimiento| date:"dd/MM/yyyy"}}</li>
            <li class="text-end letra">CUIT: {{facturaObtenida.emisor.numeroDocumento}}</li>
            <li class="text-end letra">IIBB CM {{facturaObtenida.emisor.numeroIIBB}}</li>
            <li class="text-end letra">Inicio Actividades:
              {{facturaObtenida.emisor.fechaInicioActividades | date:"dd/MM/yyyy"}}</li>
            <li class="text-end letra">{{facturaObtenida.emisor.condicionIVA}}</li>
          </ul>
        </div>
      </div>
      <div *ngIf="!esElectrica" class="border justify-content-md-center p-2" id="aeroplantaInfo">
        <div class="letra text-center">
          Aeroplanta: {{facturaObtenida.nombreAeroplanta}} ({{facturaObtenida.codigoAeroplanta}})
        </div>
        <div class="letra text-center">
          {{facturaObtenida.ciudadAeroplanta}} - {{facturaObtenida.provinciaAeroplanta}}
        </div>
      </div>
      <div id="compradorInfo" class="justify-content-md-start border">
        <ul class="list-unstyled js mb-2">
          <li style="column-count: 2;" class="mt-1">
            <p class="text-start ms-2 letra">SRES: {{facturaObtenida.cliente.razonSocial}} <b
                *ngIf="clienteExento()">IVA
                exento exportación</b></p>
            <p class="text-end me-2 letra" *ngIf="this.facturaObtenida.factura.ranchos != ''">Rancho:
              {{facturaObtenida.factura.ranchos}}</p>
          </li>
          <li class="text-start letra">DOMICILIO: {{facturaObtenida.cliente.direccionDirFiscal ?
            facturaObtenida.cliente.direccionDirFiscal : facturaObtenida.cliente.direccion}} -
            {{facturaObtenida.cliente.direccionDirFiscal ? facturaObtenida.cliente.nombreCiudadDirFiscal :
            facturaObtenida.cliente.nombreCiudad}}
            ({{facturaObtenida.cliente.direccionDirFiscal ? facturaObtenida.cliente.codigoPostalCiudadFiscal :
            facturaObtenida.cliente.codigoPostalCiudad}})</li>
          <li class="text-start letra">NRO. CUENTA: {{facturaObtenida.cliente.sgc}}</li>
          <br>
          <li>
            <div style="column-count: 3;" class="justify-content-between">
              <p class="text-start ms-2 letra">{{facturaObtenida.cliente.condicionIva}}</p>
              <p class="text-center letra">{{leyendaTipoDocumento(facturaObtenida.cliente.tipoDocumento)}}:
                {{formatearNroDocumento(facturaObtenida.cliente.tipoDocumento,
                facturaObtenida.cliente.numeroDocumento)}}</p>
              <p class="text-end me-2 letra">IIBB N°: {{facturaObtenida.cliente.numeroIIBB}}</p>
            </div>
          </li>
        </ul>
      </div>
      <div>
        <div id="condicionPago" class="border" *ngIf="this.facturaObtenida.factura!=null">
          <div class="row">
            <div class="col p-1 ms-3 letra text-start">Condicion venta:
              {{ facturaObtenida.factura.condicionVenta | condicionVenta }}
            </div>
            <div class="col p-1 me-3 letra text-end">P. Pago: {{ facturaObtenida.factura.condicionVenta == 2 ?
              facturaObtenida.cliente.plazoPago + " dias" : "--"}}
            </div>
          </div>
        </div>
        <div id="detalle" class="justify-content-md-start border">
          <!-- listo renglones de nota de debitos -->
          <table
            *ngIf="this.facturaObtenida!=null && this.facturaObtenida.tipoMovimiento==2 || this.facturaObtenida.tipoMovimiento==7"
            class="table table-sm table-striped; text-center">
            <thead>
              <tr>
                <th scope="col" *ngFor="let col of columnas" class="letra">{{col.header}}</th>
              </tr>
            </thead>
            <tbody *ngFor="let renglon of facturaObtenida.factura.renglones" class="letra">
              <tr>
                <td></td>
                <!-- {{renglon.rubro.nombre}} -->
                <td>{{renglon.detalle}}</td>
                <td>{{ renglon.cantidad | number:'1.0-0' }}</td>
                <td>{{renglon.abreviacionUnidadMedida}}</td>
                <td>{{ renglon.precioBase | number: '0.4-4' }}</td>
                <td>{{ renglon.cantidad * renglon.precioBase | number: '0.2-2'}}</td>
              </tr>
            </tbody>
          </table>
          <!-- Listo renglones de facturas y nota de creditos -->
          <table
            *ngIf="this.facturaObtenida.factura!=null && facturaObtenida.tipoMovimiento!=2 && this.facturaObtenida.tipoMovimiento!=7 && this.facturaObtenida.tipoMovimiento!=9  "
            class="table table-sm table-striped; text-center">
            <thead>
              <tr class="letra">
                <th style="width: 10%;">Cod.</th>
                <th style="width: 50%;">Descripcion</th>
                <th style="width: 10%;">Cant.</th>
                <th style="width: 10%;">UM</th>
                <th style="width: 10%;">Valor Unitario</th>
                <th style="width: 10%;">Total</th>
              </tr>
            </thead>
            <tbody *ngFor="let renglon of facturaObtenida.factura.renglones" class="letra">
              <tr>
                <td>{{renglon.productoCodigo}}</td>
                <td>{{renglon.productoNombre}}</td>
                <td>{{ renglon.cantidad | number:'1.0-0' }}</td>
                <td>{{renglon.abreviacionUnidadMedida}}</td>
                <td>{{ (this.clienteDiscriminaImpuestos() ? renglon.precioBase : renglon.precio) | number: '0.4-4' }}
                </td>
                <td>
                  {{ renglon.cantidad * (this.clienteDiscriminaImpuestos() ? renglon.precioBase : renglon.precio) |
                  number: '0.2-2'}}
                </td>
              </tr>
            </tbody>
          </table>
          <!-- listo renglones de un remito ya facturados que por ahora no se va a usar -->
          <table *ngIf="this.facturaObtenida!=null && facturaObtenida.tipoMovimiento==9"
            class="table table-sm table-striped; text-center">
            <thead>
              <tr>
                <th scope="col" *ngFor="let col of columnas" class="letra">{{col.header}}</th>
              </tr>
            </thead>
            <tbody *ngFor="let renglon of facturaObtenida.factura.renglones" class="letra">
              <tr>
                <td>{{renglon.producto.codigoProducto}}</td>
                <td>{{renglon.producto.nombreProducto}}</td>
                <td>{{ renglon.cantidad | number: '1.0-0' }}</td>
                <td>{{renglon.abreviacionUnidadMedida}}</td>
                <td> - </td><!-- {{ renglon.precioBase | number: '0.1-2' }}</td> -->
                <td> - </td>
                <td> {{ renglon.subtotalRenglon | number: '0.2-2' }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div id="total" *ngIf="this.facturaObtenida.factura!=null && this.facturaObtenida.tipoMovimiento!=9"
        class="border">
        <!-- cartel de anulado -->
        <div
          *ngIf="this.facturaObtenida.factura!=null && this.facturaObtenida.factura.estado === EstadoFactura.Anulado">
          <div id="impuestos" class="me-4">
            <br>
            <div class="row">
              <div class="col-6">
                <div class="letra-leyenda borde-Subtotal p-2 me-1" *ngIf="string != ''">{{string}}
                </div>
              </div>
              <div class="col p-2 letraImpuesto borde-Neto" style="border-right: none;"
                *ngIf="this.clienteDiscriminaImpuestos()"><b>IMPORTE NETO:</b></div>
              <div class="col p-2 mpalabra borde-Neto" style="border-left: none;"
                *ngIf="this.clienteDiscriminaImpuestos()">
                <b>{{facturaObtenida.tipoMoneda | tipoMoneda }} &nbsp; &nbsp;
                  {{facturaObtenida.factura.netoTotal | number: '0.2-2' }} </b>
              </div>
            </div>
            <div class="img-fluid position-absolute" align="left">
              <img id="logo-ypf" src="/assets/images/Anulado.png" />
            </div>
            <div class="row" *ngFor="let imp of facturaObtenida.factura.impuestos">
              <div class="col-6"></div>
              <div class="col p-2 letraImpuesto borde-Impuesto" style="border-right: none;"
                *ngIf="this.clienteDiscriminaImpuestos()">{{ imp.leyenda}} {{ (incluirPorcentajeImpuesto(imp.leyenda) ?
                (obtenerPorcentaje(imp.leyenda) | number: '2.2-2') : "") }}:</div>
              <div class="col p-2 mpalabra borde-Impuesto" style="border-left: none;"
                *ngIf="this.clienteDiscriminaImpuestos()">{{imp.valor | number: '0.2-2' }}</div>
            </div>
            <div class="row">
              <div class="col-6"></div>
              <div class="col p-2 letraImpuesto borde-Subtotal" style="border-right: none;"><b>SUBTOTAL:</b></div>
              <div class="col p-2 mpalabra borde-Subtotal" style="border-left: none;">
                <b>{{facturaObtenida.factura.subtotal | number: '0.2' }}</b>
              </div>
            </div>
            <div class="row" *ngFor="let perc of facturaObtenida.factura.percepciones">
              <div class="col-6"></div>
              <div class="col p-2 letraImpuesto borde-Percepciones" style="border-right: none;"
                *ngIf="this.clienteDiscriminaImpuestos()">{{ perc.leyenda}} {{obtenerPorcentaje(perc.leyenda) | number:
                '2.2-2'}}:</div>
              <div class="col p-2 mpalabra borde-Percepciones" style="border-left: none;"
                *ngIf="this.clienteDiscriminaImpuestos()">{{perc.valor | number: '0.2-2' }}</div>
            </div>
            <br>
            <div class="row">
              <div class="col-6"></div>
              <div class="col p-2 letraImpuesto" style="border: solid; border-width: 1px; border-right: none;">
                <b>TOTAL:</b>
              </div>
              <div class="col p-2 mpalabra" style="border: solid; border-width: 1px; border-left: none;">
                <b>{{facturaObtenida.tipoMoneda | tipoMoneda }} &nbsp; &nbsp;
                  {{facturaObtenida.factura.total | number: '0.2-2' }}</b>
              </div>
            </div>
            <div class="row">
              <div class="col-6 letra">
                SON {{this.monedaPalabra}}&nbsp;{{facturaObtenida.totalEnTexto}}
              </div>
              <div class="col-6 letra" *ngIf="this.facturaObtenida.tipoMoneda == 1">
                <b>Cotización:</b> {{this.facturaObtenida.factura.cotizacionMoneda | number: '0.5'}}
              </div>
            </div>
            <br>
            <div class="row ms-1">
              <div class="col-8 letra" style="border: solid; border-width: 1px;">
                Observaciones: {{facturaObtenida.factura.observaciones}}
              </div>
            </div>
            <br>
          </div>
        </div>
        <!-- Si no esta anulado -->
        <div *ngIf="this.facturaObtenida.factura!=null && this.facturaObtenida.factura.estado != 3">
          <div id="impuestos" class="me-4">
            <br>
            <div class="row">

              <div class="col-6">
                <div style="white-space: pre-line;" class="letra-leyenda borde-Subtotal p-2 me-1" *ngIf="string != ''">{{string}}
                </div>
              </div>

              <div class="col p-2 letraImpuesto borde-Neto" style="border-right: none;"
                *ngIf="this.clienteDiscriminaImpuestos()">
                <b>IMPORTE NETO:</b>
                <br><br>
                <div *ngFor="let imp of facturaObtenida.factura.impuestos">{{ imp.leyenda}} {{
                  (incluirPorcentajeImpuesto(imp.leyenda) ?
                  (obtenerPorcentaje(imp.leyenda) | number: '2.2-2') : "") }}:</div>
                <br>
                <b class="letraImpuesto" >SUBTOTAL:</b>
                <br><br>
                <div *ngFor="let perc of facturaObtenida.factura.percepciones">
                  <div class="letraImpuesto" *ngIf="this.clienteDiscriminaImpuestos()">{{
                    perc.leyenda}} {{obtenerPorcentaje(perc.leyenda) |
                    number: '2.2-2'}}:

                  </div>
                  <br>
                </div>
              </div>

              <div class="col p-2 mpalabra borde-Neto" style="border-left: none;"
                *ngIf="this.clienteDiscriminaImpuestos()">
                <b>{{facturaObtenida.tipoMoneda | tipoMoneda }} &nbsp; &nbsp;
                  {{facturaObtenida.factura.netoTotal | number: '0.2-2' }} </b>
                <br><br>
                <div *ngFor="let imp of facturaObtenida.factura.impuestos">{{imp.valor | number: '0.2-2' }}</div>
                <br>
                <b>{{facturaObtenida.factura.subtotal | number: '0.2-2' }}</b>
                <br><br>
                <div *ngFor="let perc of facturaObtenida.factura.percepciones">
                  <div style="border-left: none;" *ngIf="this.clienteDiscriminaImpuestos()">{{perc.valor | number: '0.2-2' }}</div>
                  <br>
                </div>
              </div>
            </div>

          </div>

          <div class="row">
            <div class="row">
              <div class="col-6"></div>
              <div class="col p-2 letraImpuesto" style="border: solid; border-width: 1px; border-right: none;">
                <b>TOTAL:</b>
              </div>
              <div class="col p-2 mpalabra" style="border: solid; border-width: 1px; border-left: none;">
                <b>{{ facturaObtenida.tipoMoneda | tipoMoneda }} &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp;
                  {{facturaObtenida.factura.total | number: '0.2-2' }}</b>
              </div>
            </div>
            <br>
            <div class="row">
              <div class="col-6 letra">
                SON {{this.monedaPalabra}}&nbsp;{{facturaObtenida.totalEnTexto}}
              </div>
              <div class="col-6 letra" *ngIf="this.facturaObtenida.tipoMoneda == 1">
                <b>Cotización:</b> {{this.facturaObtenida.factura.cotizacionMoneda | number: '0.5'}}
              </div>
            </div>
            <br>
          </div>
        </div>
      </div>
      <div id="observacionesInfo" class="justify-content-md-start border"
        *ngIf="this.facturaObtenida.factura!=null && this.facturaObtenida.tipoMovimiento!=9">
        <div class="itemObservacion ms-2 text-start letra" *ngIf="this.facturaObtenida.factura.condicionVenta == 1">
          <b>Metodo de pago:</b><br />
          <div
            *ngIf="this.facturaObtenida.factura.pago.length > 0 && this.facturaObtenida.factura.pago[0]!=null && this.facturaObtenida.factura.pago[0].cupones.length > 0">
            <b>Cupones:</b><br />
            <div *ngFor="let cupon of facturaObtenida.factura.pago[0].cupones">
              {{ cupon.tarjeta.nombre + " &nbsp; &nbsp; ...-" + cupon.numeroTarjeta + " &nbsp; &nbsp; " +
              cupon.numeroLote
              + "/" + cupon.numeroCupon + " &nbsp; &nbsp; " }}
              {{cupon.importe | number: '0.2-2' }}
            </div>
          </div>
          <b>Efectivo:</b><br />
          <div>
            {{efectivo | number: '0.2-2'}}
          </div>
        </div>
        <ul class="list-unstyled js">
          <li>
            <div class="itemObservacion text-start ms-2 letra"
            *ngIf="facturaObtenida.factura.numerosAerovales != null && facturaObtenida.factura.numerosAerovales != '' && !esElectrica">
              <b>Aerovales incluidos:</b><br />
              {{ this.facturaObtenida.factura.numerosAerovales}}
            </div>
          </li>
          <li>
            <div class="itemObservacion text-start ms-2 letra"
              *ngIf="facturaObtenida.factura.patentes != null && facturaObtenida.factura.patentes != ''">
              <b>Matriculas involucradas:</b><br />
              {{ this.facturaObtenida.factura.patentes}}
            </div>
          </li>
          <li>
            <div class="itemObservacion text-start ms-2 letra"
              *ngIf="facturaObtenida.observaciones != null && facturaObtenida.observaciones.length > 0">
              <b>Comentarios:</b><br />
              {{ this.facturaObtenida.observaciones}}
            </div>
          </li>
          <li>
            <div class="itemObservacion text-start ms-2 letra" *ngIf="facturaObtenida.factura.nota != null">
              <b>Referencia:</b><br />
              {{ this.facturaObtenida.factura.nota.facturasRelacionadasString}}
            </div>
          </li>
        </ul>
      </div>
      <div class="border p-2" style="max-height: 100px!important; overflow: hidden!important; text-align: left;"
        *ngIf="this.facturaObtenida!=null && this.facturaObtenida.factura!=null && this.facturaObtenida.factura.leyendas!=null && this.facturaObtenida.factura.leyendas.length >0">
        <div style="overflow: hidden;"class="letra" *ngFor="let leyenda of facturaObtenida.factura.leyendas">
          <div *ngIf="leyenda.frame == 1 && leyenda.activo">{{leyenda.descripcion}}</div>
        </div>
      </div>
      <div id="fechasCae" class="border"
        *ngIf="this.facturaObtenida.factura!=null && this.facturaObtenida.factura.cae != null">
        <div class="row">
          <div class="col p-1 ms-4 letra text-start">C.A.E.: {{facturaObtenida.factura.cae.cae}}
          </div>
          <div class="col p-1 me-4 letra text-end">Fecha Vto.:
            {{facturaObtenida.factura.cae.vencimientoCAE | date:"dd/MM/yyyy"}}
          </div>
        </div>
      </div>
      <div class="border text-center">
        <div
          *ngIf="this.facturaObtenida.factura!=null && this.facturaObtenida.tipoMovimiento!=9 && this.facturaObtenida.factura.cae != null"
          id="codigoBarras">
          <div class="row">
            <div class="col-md-6">
              <qrcode [elementType]="elementType" style="display: inline-block;" [qrdata]="facturaObtenida.factura.codigoQr"
                [width]="200" [level]="'M'"></qrcode>
            </div>
            <div class="col-md-6">
              <ngx-barcode6 class="" [bc-value]="facturaObtenida.factura.codigoBarras"
                [bc-display-value]="facturaObtenida.factura.codigoBarras" [bc-element-type]="'svg'" [bc-font-size]="10"
                [bc-height]="30" [bc-width]="0.9"></ngx-barcode6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="mb-3 mt-2">
  <div id="botones" class="mb-3 justify-content-between ms-2" *ngIf="this.botones===true">
    
    <button *ngIf="facturaObtenida.id != null" styleSheetFile="assets/css/print.css,assets/css/bootstrap.min.css"
      printTitle="{{this.facturaObtenida.prefijo}}-{{this.facturaObtenida.numeroComprobante}} {{this.facturaObtenida.fechaHoraCreacion}}"
      printSectionId="invoicingPrint" useExistingCss="true" class="btn btn-sm btn-outline-secondary ms-2 mt-1" ngxPrint>
      <i class="fa fa-print" aria-hidden="true"></i> Imprimir 
    </button>
    
    <button type="button" [disabled]="this.facturaObtenida.factura!=null && facturaObtenida.factura.remitos.length===0"
      class="btn mt-1 btn-sm btn-outline-success mx-2" (click)="onClickExport(facturaObtenida)">
      <i class="fa fa-file-excel-o" aria-hidden="true"></i> Exportar remitos
    </button>
    
    <button type="button" class="btn mt-1 btn-sm btn-outline-danger" (click)="onClickView()">
      Ver impuestos
    </button>
    
    <button class="btn mt-1 btn-sm btn-outline-primary ms-2" *ngIf="this.facturaObtenida.factura.linkPDFOriginal == null" 
      (click)="onClickDownload(facturaObtenida)" >  
      <span *ngIf="!descargando">Descargar comprobante</span>
      <div *ngIf="descargando" class="d-flex align-items-center">
        <div class="spinner-border text-light spinner-border-sm" role="status"></div>
        <span>Descargando...</span>
      </div>
    </button>

    <a href="{{this.facturaObtenida.factura.linkPDFOriginal}}"
      *ngIf="this.facturaObtenida.factura.linkPDFOriginal != null">
      <button class="btn mt-1 btn-sm btn-outline-primary ms-2">
        <span>Descargar comprobante original</span>
      </button>
    </a>
    
    <button class="btn mt-1 btn-sm btn-outline-primary ms-2 mt-2" (click)="reenviarCorreo()">
      <span *ngIf="!reenviando">Reenviar comprobante por correo</span>
      <div *ngIf="reenviando" class="d-flex align-items-center">
        <div class="spinner-border text-light spinner-border-sm" role="status"></div>
        <span>Reenviando...</span>
      </div>
    </button>

  </div>
</div>