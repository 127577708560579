import { AerolineaDestinoDTO } from "./AerolineaDestinoDTO";

export class AerolineaDTO {
    id : string;
    cuit : string;
    razonSocial : string;
    codigoAerolinea : string;
    cuentaNacional : string;
    cuentaInternacional : string;
    destinos : AerolineaDestinoDTO[];    
}
