<div class="p-2 add-margin">
  <div class="card">
    <div class="card-header">
      <b>Carga masiva de Clientes</b>
    </div>
    <div class="card-body">
      <h5 class="card-title">Utilice este importador para cargar masivamente los Clientes utilizando un archivo de tipo Excel <i class="fa fa-file-excel-o" aria-hidden="true"></i></h5>
      <p class="card-text"><b>Importante:</b>Debe tener el formato y ordenamiento de columnas que se estableció en la muestra.</p>

      <table class="table-bordered table-condensed table-responsive text-center letra" style="border: solid; border-width: 2px;">
        <tr>
          <td class="py-2 px-2"><b>LUG_FACT</b></td>
          <td class="py-2 px-2"><b>CUIT </b></td>
          <td class="py-2 px-2"><b> CTC </b></td>
          <td class="py-2 px-2"><b>FLAG_FOT</b></td>
          <td class="py-2 px-2"><b>FLAG_CIF </b></td>
          <td class="py-2 px-2"><b>CTA_SAP</b></td>
          <td class="py-2 px-2"><b>CTA_SGC </b></td>
          <td class="py-2 px-2"><b> CBU </b></td>
          <td class="py-2 px-2"><b>UN_NEG </b></td>
          <td class="py-2 px-2"><b>D_E </b></td>
          <td class="py-2 px-2"><b>TIPO_OP</b></td>
          <td class="py-2 px-2"><b> COD_OP </b></td>
          <td class="py-2 px-2"><b>T_D_E </b></td>
          <td class="py-2 px-2"><b>PLAZO </b></td>
          <td class="py-2 px-2"><b>T_PAGO </b></td>
          <td class="py-2 px-2"><b> Z_UCO </b></td>
          <td class="py-2 px-2"><b> AREA  </b></td>
          <td class="py-2 px-2"><b> SUB_AREA </b></td>
          <td class="py-2 px-2"><b> RCE</b></td>
          <td class="py-2 px-2"><b> ZI</b></td>
          <td class="py-2 px-2"><b> NOMBRE_CUENTA </b></td>
          <td class="py-2 px-2"><b> COD_PROV</b></td>
          <td class="py-2 px-2"><b> COD_PAIS</b></td>
          <td class="py-2 px-2"><b> NUM_IIBB</b></td>
          <td class="py-2 px-2"><b> EX_IIBB_PER</b></td>
          <td class="py-2 px-2"><b> CONV_MULT</b></td>
          <td class="py-2 px-2"><b> REG_EX_IIBB </b></td>
          <td class="py-2 px-2"><b> TASA_DIFERENCIAL_DE_INGRESO </b></td>
          <td class="py-2 px-2"><b> VIGENCIA</b></td>
          <td class="py-2 px-2"><b> T_DIF_IVA </b></td>
          <td class="py-2 px-2"><b> FEC_EXP_TDI </b></td>
          <td class="py-2 px-2"><b> T_DIF_IVA_P </b></td>
          <td class="py-2 px-2"><b> FEC_EXP_TDIP </b></td>
          <td class="py-2 px-2"><b> CAT_IVA</b></td>
          <td class="py-2 px-2"><b> DIR_ABREVIADA </b></td>
          <td class="py-2 px-2"><b> CTA_MADRE</b></td>
          <td class="py-2 px-2"><b> T_C_CTA_MADRE </b></td>
          <td class="py-2 px-2"><b> CALLE </b></td>
          <td class="py-2 px-2"><b>  N_KM </b></td>
          <td class="py-2 px-2"><b> PISO </b></td>
          <td class="py-2 px-2"><b> DPTO</b></td>
          <td class="py-2 px-2"><b> COD_LOC</b></td>
          <td class="py-2 px-2"><b>COD_POS </b></td>

        </tr>
      </table>
      <br>
         <!-- Debe tener el formato y ordenamiento de columnas que se estableció en la muestra</p> -->
      <div *ngIf="fileUpload.status==='error'">
        <div [innerHTML]="fileUpload.message"></div>
      </div>
      <div *ngIf="fileUpload.status==='progress'">
        <div class="progress" style="height: 20px;margin-bottom:20px;">
          <div class="progress-bar" role="progressbar" [style.width.%]="fileUpload.message" aria-valuenow="25"
            aria-valuemin="0" aria-valuemax="100">{{fileUpload.message}}%</div>
        </div>
      </div>
      <form [formGroup]="profileForm">
        <div class="mb-3">
          <input type="file" class="form-control" name="profile" (change)="onSelectedFile($event)"
            id="validatedCustomFile" formControlName="inputFile" required>
          <label class="form-label mt-2 ms-2" for="validatedCustomFile">{{ userFileName }}</label>
          <div class="invalid-feedback">Example invalid custom file feedback</div>
        </div>
        <div class="mb-3">
          <button type="button" [disabled]="!selectedFile" class="btn btn-primary" (click)="onSubmit()">Cargar</button>
          <button type="button" class="btn btn-secondary ms-2" (click)="reset(true, true)">Cancelar</button>
        </div>
      </form>
      <div *ngIf="fileUpload.length>0" class="alert alert-warning" role="alert">
        <div *ngFor="let msg of fileUpload">
          <p>{{ msg.description }}</p>
        </div>
      </div>
      <div *ngIf="error" class="alert alert-danger" role="alert">{{ error }}</div>
    </div>
  </div>
</div>
