<div class="modal-header bg-light">
    <h4 class="modal-title">Mails de notificación</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close(false)"></button>
</div>
<div class="modal-body" [formGroup]="mailNotificacionForm">
    <!--NOMBRE-->
    <div *ngIf="mailsNotificacion">
        <div *ngFor="let mail of mailsNotificacion; let i = index">
            <p>{{mail}}<button *ngIf="authService.hasRole(TipoUsuario.COMERCIAL)" [disabled]="grabando" class="btn btn-sm" (click)="borrarMail(i)"><i class="fa fa-trash"></i></button></p>
        </div>
    </div>
    <div class="form-row">
        <div class="form-group row col-md-12" *ngIf="authService.hasRole(TipoUsuario.COMERCIAL)">
            <label for="emailFormControl" class="col-sm-5 col-form-label">Email: </label>
            <div class="col-sm-7">
                <input class="form-control col-sm-11" style="display: inline!important; width: 85%" formControlName="emailFormControl" maxlength="50">
                <button [disabled]="grabando" class="btn btn-sm col-sm-1" style="display: inline!important;width: 15%" (click)="agregarMail()"><i class="fa fa-plus"></i></button>
                <div *ngIf="emailFormControl.invalid && emailFormControl.errors.required && (emailFormControl.dirty || emailFormControl.touched)"
                    class="alert alert-danger">Requerido
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer justify-content-center">
    <button *ngIf="authService.hasRole(TipoUsuario.COMERCIAL)" class="btn btn-primary mr-2" id="aceptar" [disabled]="!this.mailsNotificacion.length" (click)="actualizar()">
        <span *ngIf="!grabando">Guardar</span>
        <div *ngIf="grabando" class="d-flex align-items-center">
            <div class="spinner-border text-light spinner-border-sm" role="status"></div>
            <span>Guardando...</span>
        </div>
    </button>
    <button [disabled]="grabando" type="button" class="btn btn-secondary" (click)="this.cerrarModal()">Cancelar</button>
</div>