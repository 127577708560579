<nav class="navbar navbar-light bg-light">
  <form class="d-flex align-items-center" [formGroup]="busquedaForm">
    <div class="col-md-5 ms-3">
      <button type="button" [disabled]="obteniendoParametros || parametroSeleccionado" class="btn btn-primary" (click)="onAgregarProvincia()">
          Agregar provincia
      </button>
    </div>
    <input type="search" class="form-control col-md-3 ms-1" placeholder="Buscar provincia"
      formControlName="busquedaFormControl" (input)="filtrarProvincias()" />
  </form>
</nav>

<div class="row">
  <div class="col col-3">
    <div class="list-group py-3 menu-izq">
      <app-listado-provincias [textoBusqueda]="textoBusquedaProvincia"></app-listado-provincias>
    </div>
  </div>
  <div class="col col-9">
    <div class="list-group py-3 contenedor-der">
      <app-listado-parametros-impositivos></app-listado-parametros-impositivos>
    </div>
  </div>
</div>