import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { PedidoService } from '../pedido.service';
import { PedidoDetalle } from '../../shared/models/despacho/PedidoDetalle';
import { ModalConfirmComponent } from '../../shared/generic-modals/modal-confirm.component';
import { ModalOkComponent } from '../../shared/generic-modals/modal-ok.component';
import { NuevaPrecargaComponent } from '../nueva-precarga/nueva-precarga.component';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Guid } from "guid-typescript";
import { Precarga } from '../../shared/models/despacho/Precarga';
import { EstadoPedido } from 'src/app/shared/enums/estadoPedido';
import { AerovaleDetalleComponent } from '../aerovale-detalle/aerovale-detalle.component';
import { AerovalesService } from '../aerovales.service';
import { MessageService } from 'src/app/shell/message.service';
import { UsuarioService } from 'src/app/account/usuario.service';
import { Aeroplanta } from 'src/app/shared/models/despacho/Aeroplanta';
import { RanchoService } from 'src/app/rancho/rancho.service';
import { Rancho } from 'src/app/shared/models/ranchos/Rancho';
import { isNullOrUndefined } from 'src/app/shared/compat-tools';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { fadeAnimationTrigger } from 'src/app/shared/animations';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { NuevoAerovaleComponent } from '../nuevo-aerovale/nuevo-aerovale.component';
import { TipoPedido } from 'src/app/shared/enums/TipoPedido';
import { UntypedFormControl, FormGroup } from '@angular/forms';



@Component({
  selector: 'app-pedido-detalle',
  templateUrl: './pedido-detalle.component.html',
  styleUrls: ['./pedido-detalle.component.css'],
  animations: [fadeAnimationTrigger]
})
export class PedidoDetalleComponent implements OnInit, OnChanges {

  TipoUsuario = TipoUsuario;
  TipoPedido = TipoPedido;
  EstadoPedido = EstadoPedido;

  @Input() pedidoDetalle: PedidoDetalle;
  @Output() pedidoAnulado = new EventEmitter();
  @Output() precargasChanged = new EventEmitter();
  @Output() anulando = new EventEmitter();
  @Output() volver = new EventEmitter();
  @Output() pedidoCerradoSuccess = new EventEmitter();
  @Output() pedidoDetalleModificado: PedidoDetalle = new PedidoDetalle();

  //visualizar desde tams y alijes
  @Input() visualizarPedido: boolean = false;
  @Input() pedidoId: string;
  //nº comprobante de alije
  @Input() numeroComprobante: string;

  pedidoDetallePrioridad: PedidoDetalle;

  rancho: Rancho;
  cargandoRancho: boolean;
  errorMessage: string = '';
  procesandoInformacion: boolean = false;
  showOrderModify: boolean = false;

  esPrioridadFormControl = new UntypedFormControl(null);

  constructor(
    private pedidoService: PedidoService,
    private aerovaleService: AerovalesService,
    private modalService: NgbModal,
    private modal: NgbActiveModal,
    private messageService: MessageService,
    private usuarioService: UsuarioService,
    private ranchoService: RanchoService,
    private authService: AuthService
  ) { }


  // hooks

  ngOnInit() {
    if(this.visualizarPedido && this.numeroComprobante != null) {
        this.getPedido(this.pedidoId);
    } else
    {
      if(this.pedidoDetalle == null)
        this.getPedido(this.pedidoId);
      else
        this.getPedido(this.pedidoDetalle.id);        
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.mostrarRancho) {
      this.obtenerRancho();
    }
  }

  // form

  onModificacionCancelada(){
    if( this.visualizarPedido || this.numeroComprobante != null) 
      this.modal.close();
    this.volver.emit();
  }

  goBack() {
    if( this.visualizarPedido || this.numeroComprobante != null) 
    {
      this.modal.close()
    } else {
      this.volver.emit();
    }    
  }

  cambioPrioridad() {
    this.pedidoDetallePrioridad = new PedidoDetalle();
    this.pedidoDetallePrioridad.id = this.pedidoDetalle.id;
    this.pedidoDetallePrioridad.codigoAeroplanta = this.usuarioService.getAeroplantaActual().codigoAeroplanta;
    this.pedidoDetallePrioridad.cliente = this.pedidoDetalle.cliente;
    this.pedidoDetallePrioridad.aeronaveId = this.pedidoDetalle.aeronaveId;
    this.pedidoDetallePrioridad.aeronaveMatricula = this.pedidoDetalle.aeronaveMatricula;
    this.pedidoDetallePrioridad.codigoAerocombustible = this.pedidoDetalle.codigoAerocombustible;
    this.pedidoDetallePrioridad.vuelo = this.pedidoDetalle.vuelo;
    this.pedidoDetallePrioridad.cantidadEstimada = this.pedidoDetalle.cantidadEstimada;      
    this.pedidoDetallePrioridad.fechaHoraEstimada = this.pedidoDetalle.fechaHoraEstimada;
    this.pedidoDetallePrioridad.fechaHoraVuelo = this.pedidoDetalle.fechaHoraVuelo;
    this.pedidoDetallePrioridad.posicion = this.pedidoDetalle.posicion;
    this.pedidoDetallePrioridad.destinoId = this.pedidoDetalle.destinoId;
    this.pedidoDetallePrioridad.esPrioridad = this.esPrioridad.value;
    
    this.pedidoService.ModificarPedido(this.pedidoDetallePrioridad)
          .subscribe(pedido => {

            if (pedido) {
              this.messageService.showSuccessMessage("El pedido se modificó correctamente.");
              //this.guardandoPedido = false;
            }
            else {
              //this.guardandoPedido = false;
              this.messageService.showErrorMessage("Se ha producido un error al generar el pedido");
            }
          });
  }

  showAerovale(p: Precarga) {
    if (p.aerovaleId != null) {
      let i = Guid.parse(p.aerovaleId);
      if (!i.isEmpty()) {
        return true;
      }
    }
    else {
      return false;
    }
  }

  goToAerovale(aerovaleId: string) {
    this.aerovaleService.getAerovalePorIdyTipo(aerovaleId)
      .subscribe(aerovaleDetalle => {
        const modalRef = this.modalService.open(AerovaleDetalleComponent, { centered: true, size: 'lg' });
        modalRef.componentInstance.aerovaleDetalle = aerovaleDetalle;
      });
  }

  convertirEstado(e: EstadoPedido) {
      return EstadoPedido[e];
  }

  sumaCargasEstimadas(): number {
    let suma = 0;

    for (let entry of this.pedidoDetalle.precargas) {
      if (entry.aerovaleId != '00000000-0000-0000-0000-000000000000')
        suma += entry.cargaEstimada;
    }
    return suma;
  }


  // llamadas al service

  modificarPedido(pedidoId: string){
    this.pedidoService.getPedidoPorId(pedidoId)
     .subscribe(result => {
       this.showOrderModify = true;
       this.pedidoDetalle = null;
       this.pedidoDetalleModificado = result;
       this.pedidoDetalleModificado.precargas = this.pedidoDetalleModificado.precargas.filter(a => a.estado != EstadoPedido.Anulado)
     })
 }

 onCrearAevDevolucion(pedido: PedidoDetalle, event: MouseEvent) {
  const modalRef = this.modalService.open(NuevoAerovaleComponent, { centered: true, size: "lg", backdrop: 'static', windowClass: 'fullScreenModalWindow' });
  modalRef.componentInstance.pedidoDevolucion = pedido;
  modalRef.result.then((aerovale) => {
    this.messageService.showSuccessLongMessage("Aerovale creado exitosamente.");
  }
  ).catch(() => { });
  event.stopPropagation();
}

  cerrarPedido(): void {
    const modalRef = this.modalService.open(ModalConfirmComponent, { centered: true });
    modalRef.componentInstance.mensaje = "Se dispone a cerrar este pedido ¿Esta seguro?"; // should be the id
    modalRef.componentInstance.textoSi = "Si";
    modalRef.componentInstance.textoNo = "No";
    modalRef.result.then(() => {
      this.procesandoInformacion = true;

      this.pedidoService.cerrarPedido(this.pedidoDetalle.id)
        .subscribe(
          rta => {
            this.procesandoInformacion = false;
            this.goBack();
            this.pedidoCerradoSuccess.emit();
          },
          (error: ErrorModel) => {
            this.errorMessage = 'Error al cerrar el pedido: ' + error.description;
            this.procesandoInformacion = false;
            setTimeout(() => {
              this.errorMessage = '';
            }, 4000);
          }
        );
    },
      () => {

      }
    );
  }

  getPedido(pedidoId: string) {
    this.pedidoService.getPedidoPorId(pedidoId)
      .subscribe(result => {
        this.pedidoDetalle = result;
        this.esPrioridad.setValue(this.pedidoDetalle.esPrioridad);
        this.pedidoDetalle.precargas = this.pedidoDetalle.precargas.filter(a => a.estado != 2)
      });
  }


  agregarPrecarga(): void {
    const modalRef = this.modalService.open(NuevaPrecargaComponent);
    modalRef.componentInstance.pedido = this.pedidoDetalle;
    modalRef.componentInstance.cantidadSugerida = this.pedidoDetalle.precargas.length > 0 ? 0 : this.pedidoDetalle.cantidadEstimada;
    modalRef.componentInstance.cantidadMaxima = this.pedidoDetalle.cantidadFaltante;
    modalRef.componentInstance.rancho = this.rancho;
    modalRef.result.then((precarga) => {
      precarga.estado = EstadoPedido.Abierto;
      this.pedidoDetalle.precargas.push(precarga);
      this.pedidoDetalle.precargas[this.pedidoDetalle.precargas.length - 1].cantidadReal = precarga.cargaEstimada;
      this.precargasChanged.emit(this.pedidoDetalle.cantidadFaltante);
      this.getPedido(this.pedidoDetalle.id);
      this.messageService.showSuccessMessage("Precarga generada con éxito");
    }).catch((error) => {

    });
  }


  anularPrecarga(precarga: Precarga): void {
    const modalRef = this.modalService.open(ModalConfirmComponent, { centered: true });
    modalRef.componentInstance.mensaje = "Se perderá la información de la precarga de forma permanente, ¿Desea anular esta precarga?"; // should be the id
    modalRef.componentInstance.textoSi = "Si";
    modalRef.componentInstance.textoNo = "No";
    modalRef.result.then(() => {

      this.anulando.emit();
      this.pedidoService.anularPrecarga(precarga.id)
        .subscribe(rta => {
          this.pedidoDetalle.precargas = this.pedidoDetalle.precargas.filter(a => a.id !== precarga.id);
          this.precargasChanged.emit(this.pedidoDetalle.cantidadFaltante);
          this.getPedido(this.pedidoDetalle.id);
          this.messageService.showSuccessMessage("Precarga anulada exitosamente");
        });
    },
      () => {

      }
    );
  }

  obtenerRancho() {
    this.cargandoRancho = true;
    let aeroplanta: Aeroplanta = this.usuarioService.getAeroplantaActual();
    this.ranchoService.getRanchoPorCUIT(
      aeroplanta.codigoAeroplanta,
      this.pedidoDetalle.destino.codigoPais,
      this.pedidoDetalle.codigoAerocombustible,
      this.pedidoDetalle.cliente.numeroDocumento,
      this.pedidoDetalle.fechaHoraEstimada).subscribe(
        rancho => this.rancho = rancho,
        err => this.messageService.showErrorMessage(err),
        () => this.cargandoRancho = false
      );
  }

  anularPedido(): void {    
    const modalRef = this.modalService.open(ModalConfirmComponent, { centered: true });
    modalRef.componentInstance.mensaje = "Se perderá la información del pedido de forma permanente, ¿Desea anular este pedido?"; // should be the id
    modalRef.componentInstance.textoSi = "Si";
    modalRef.componentInstance.textoNo = "No";
    modalRef.result.then(() => {

      this.anulando.emit();
      this.pedidoService.anularPedido(this.pedidoDetalle.id)
        .subscribe(rta => {
          this.pedidoAnulado.emit();
          this.goBack();
        });
    },
      () => {

      }
    );
  }

  obtenerNombreEstado(estado) {
    return EstadoPedido[estado];
  }

  // getters

  get esPrioridad() {
    return this.esPrioridadFormControl;
  }

  get precargas() {
    return this.pedidoDetalle.precargas.sort((a, b) => {
      return <any>new Date(a.fechaHora) - <any>new Date(b.fechaHora);
    });
  }

  get mostrarRancho(): boolean {
    return !isNullOrUndefined(this.pedidoDetalle) &&
      this.pedidoDetalle.cliente.permiteRancho &&
      !isNullOrUndefined(this.pedidoDetalle.destino) &&
      this.pedidoDetalle.destino.cabotaje == false;
  }

  get cantidadCargada() {
    let acumulado = 0;
    acumulado = this.pedidoDetalle.cantidadEstimada - this.pedidoDetalle.cantidadFaltante;
    return acumulado;
  }

  hasRole(...roles: TipoUsuario[]) {
    return this.authService.hasRole(...roles);
  }
}
