<div class="card">
  <div class="card-header">
    <label class="col-form-label ms-2"><b>Retiro de efectivo</b></label>    
  </div>
  <div class="card-body">
    <div class="row">
      <div class="py-3 col-12">
        <form id="formularioTablaMovimientos">
          <div class="col-md-12">
            <ngx-spinner name="spinnerLista" [ngStyle]="{'height': '400px', 'z-index':'0'}"
              [ngClass]="{'list-group-item list-group-item-action': obteniendoCierres}" bdOpacity=0.3bd
              Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
              <p style="color: white"> O b t e n i e n d o C I E R R E S </p>
            </ngx-spinner>
            <div class="alert alert-danger" *ngIf="!obteniendoCierres && this.cierresCaja.length == 0">No se encontraron cierres de
              caja.</div>
            <div id="tablaMovimientos" *ngIf="!obteniendoCierres && this.cierresCaja.length > 0">
              <table id="tablaMovimientosDesdeHasta" class="table table-striped table-hover text-center">
                <thead>
                  <tr>
                    <th>Fecha Cierre de Caja</th>
                    <th>Numero Cierre de Caja</th>
                    <th>Aeroplanta</th>
                    <th>Supervisor</th>
                    <th>Efectivo</th>
                    <th>Controlada</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let c of cierresCaja; let i = index" style="cursor: pointer;">
                    <td>{{c.fechaHora | date: 'dd/MM/yyyy HH:mm'}}</td>
                    <td>{{c.numero}}</td>
                    <td>{{c.aeroplanta.nombre}}</td>
                    <td>{{c.responsable}}</td>
                    <td>{{c.totalEfectivoIngresado}}</td>
                    <td>
                      <div class="form-check">
                        <input (click)="agregarCaja(i, $event)" type="checkbox" class="custom-control-input"
                          id="customCheck{{i}}">
                        <label class="custom-control-label" for="customCheck{{i}}"></label>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </form>
      </div>
      <br>

    </div>
  </div>
  <form *ngIf="!obteniendoCierres && this.cierresCaja.length != 0">
    <div style="text-align: right;">
      <label class="me-2" type="text">
        <b>Total ingresado: </b>
      </label>
      <input type="text" [value]="this.totalCajas | number:'0.2-2'" readonly />
    </div>

    <div class="col-12" style="text-align: right; display: inline-flex;">
      <label class="col-9 me-2" type="text">
        <b>Cargar escaneo de Recibo:
        </b>
      </label>
      <div class="col-3">
        <app-upload-file class="d-flex" [url]="this.facturacionBaseURL" (archivoSubido)="onArchivoSubido($event)"></app-upload-file>
      </div>
    </div>

  </form>
  <div class="card-footer">
    <div style="text-align: right;">
      <button class="btn btn-primary" type="button" (click)="realizarRetiro()"
        [disabled]="this.obteniendoCierres || this.realizandoCierre || this.cierresCajaSeleccionada.length == 0">
        <span *ngIf="!realizandoCierre">Realizar retiro</span>
        <div *ngIf="realizandoCierre" class="d-flex align-items-center">
          <div class="spinner-border text-light spinner-border-sm" role="status"></div>
          <span>Realizando retiro...</span>
        </div>
      </button>
    </div>
  </div>
</div>