<div class="card">
  <div class="card-header">
    <h5 class="text-center mb-0">Información Fiscal</h5>
  </div>
  <div class="card-body">
    <form [formGroup]="informacionFiscal">
      <div class="row">
        <div class="col">
          <div class="mb-3"><label>*Razón Social:</label>
            <input id="razonSocial" class="form-control" type="text" formControlName="razonSocial" required>
          </div>
          <div *ngIf="rSocial.invalid && rSocial.errors.required && (rSocial.dirty || rSocial.touched)"
            class="alert alert-danger">
            Requerido
          </div>
        </div>
        <div class="col">
          <div class="mb-3"><label>*Condicion Iva:</label>
            <input id="condicionIVA" type="text" class="form-control" formControlName="condicionIVA"
              placeholder="Seleccione una opcion..." [inputFormatter]="formatterCondicion" [(ngModel)]="modelCondicion"
              [resultFormatter]="formatterCondicion" title="Seleccione una condicion." [ngbTypeahead]="searchCondicion"
              (selectItem)="selectCondicion($event)" required />
          </div>
          <loading-text-spinner *ngIf="cargandoCondiciones" [error]="errorDestinos"
            message="Obteniendo condiciones de IVA..." color="primary">
          </loading-text-spinner>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="mb-3"><label>Documento:</label>
            <select id="tipoDocumento" class="form-select" name="Seleecione" (change)="limpiarCampoNumero()"
              formControlName="tipoDocumento">
              <!-- <option value="96">DNI</option> -->
              <option value="80">CUIT</option>
              <option value="86">CUIL</option>
            </select>
          </div>
        </div>
        <div class="col" *ngIf="this.documento.value===80 || 'this.documento.value===86'">
          <div class="mb-3"><label>*Número:</label>
            <input id="numeroDocumento" (keypress)="onlyNumberKey($event)" class="form-control" type="text"
              [maxLength]="maximoNumero" formControlName="numeroDocumento" (blur)="this.validarCuit()" required />
          </div>
          <div *ngIf="numeroDoc.invalid && numeroDoc.errors.required && (numeroDoc.dirty || numeroDoc.touched)"
            class="alert alert-danger">
            Requerido
          </div>
          <div
            *ngIf="this.emisorSeleccionado===null && !this.validarCuit() && this.documento.value===80 || this.documento.value===86"
            class="alert alert-danger">
            El número de CUIT no tiene el formato correcto.
          </div>
          <div
            *ngIf="this.emisorSeleccionado===null && this.estaDocumento() && this.documento.value===80 || this.documento.value===86"
            class="alert alert-danger">
            El número de cuit o cuil. ya existe en otro emisor.
          </div>
        </div>
        <!-- si es dni -->
        <div class="col" *ngIf="this.documento.value===96">
          <div class="mb-3"><label>*Número:</label>
            <input id="numeroDocumento" (keypress)="onlyNumberKey($event)" class="form-control" type="text"
              [maxLength]="maximoNumero" formControlName="numeroDocumento" (blur)="this.validarDNI()" required />
          </div>
          <div *ngIf="numeroDoc.invalid && numeroDoc.errors.required && (numeroDoc.dirty || numeroDoc.touched)"
            class="alert alert-danger">
            Requerido
          </div>
          <div *ngIf="this.documento.value===96 && this.estaDocumento() && this.emisorSeleccionado===null"
            class="alert alert-danger">
            El número de dni ya existe en otro emisor.
          </div>
          <div *ngIf="this.validarDNI()===false" class="alert alert-danger">
            El número de DNI no tiene el formato correcto.
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="mb-3"><label>País:</label>
            <input id="selecPais" type="text" class="form-control" formControlName="selecPais"
              placeholder="Seleccione una opcion..." [inputFormatter]="formatterPais" [(ngModel)]="modelPais"
              [resultFormatter]="formatterPais" title="Seleccione un pais."
              (change)="getProvinciasPorPais(this.pais.value.codigo)" [ngbTypeahead]="search"
              (selectItem)="selectPaises($event)" (blur)="getProvinciasPorPais(this.pais.value.codigo)" disabled />
          </div>
          <loading-text-spinner *ngIf="cargandoPaises" [error]="errorDestinos" message="Obteniendo paises..."
            color="primary">
          </loading-text-spinner>
        </div>
        <div class="col">
          <div class="mb-3"><label>*Provincia:</label>
            <input id="selecProvincia" type="text" class="form-control" formControlName="selecProvincia"
              placeholder="Seleccione una opcion..." [inputFormatter]="formatterProvincia" [(ngModel)]="modelProvincia"
              [resultFormatter]="formatterProvincia" title="Seleccione una provincia."
              (change)="getLocalidadPorProvincia(this.provincia.value.codigo)" [ngbTypeahead]="searchProvincia"
              (selectItem)="selectProvincias($event)" (blur)="getLocalidadPorProvincia(this.provincia.value.codigo)" />
          </div>
          <loading-text-spinner *ngIf="cargandoProvincias" [error]="errorDestinos" message="Obteniendo provincias..."
            color="primary">
          </loading-text-spinner>
        </div>
        <div class="col">
          <div class="mb-3"><label>*Localidad:</label>
            <input id="selecLocalidad" type="text" class="form-control" formControlName="selecLocalidad"
              placeholder="Seleccione una opcion..." [inputFormatter]="formatterCiudad" [(ngModel)]="modelCiudad"
              [resultFormatter]="formatterCiudad" title="Seleccione una ciudad."
              (change)="getLocalidadPorProvincia(this.provincia.value)" [ngbTypeahead]="searchCiudad"
              (selectItem)="selectCiudades($event)" />
          </div>
          <loading-text-spinner *ngIf="cargandoCiudades" [error]="errorDestinos" message="Obteniendo ciudades..."
            color="primary">
          </loading-text-spinner>
        </div>
      </div>
      <div class="row">

        <div class="mb-3"><label>Código Postal:</label>
          <input id="codPostal" class="form-control" type="text" (keypress)="onlyNumberKey($event)"
            formControlName="codigoPostal" disabled>
        </div>
        <div class="col">
          <div class="mb-3"><label>*Dirección:</label>
            <input id="direccion" class="form-control" type="text" formControlName="direccion" required>
          </div>
          <div *ngIf="direccion.invalid && direccion.errors.required && (direccion.dirty || direccion.touched)"
            class="alert alert-danger">
            Requerido
          </div>
        </div>
        <div class="col">
          <div class="mb-3"><label>*Telefono:</label>
            <input id="telefono" class="form-control" type="text" formControlName="telefono">
          </div>
          <div *ngIf="telefono.invalid && telefono.errors.required && (telefono.dirty || telefono.touched)"
            class="alert alert-danger">
            Requerido
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="mb-3"><label>Email:</label>
            <input id="email" class="form-control" type="text" formControlName="email">
          </div>
        </div>
        <div class="col">
          <div class="mb-3"><label>*Cliente consumidor final:</label>
            <input id="clienteRazonSocial" type="text" class="form-control" formControlName="clienteRazonSocial"
              [inputFormatter]="formatterCliente" [(ngModel)]="modelCliente" [resultFormatter]="formatterCliente"
              title="Campo obligatorio, debe seleccionar o ingresar un cliente." [ngbTypeahead]="searchCliente"
              (selectItem)="selectCustomer($event)" required />
          </div>
          <loading-text-spinner *ngIf="cargandoClientes" [error]="errorDestinos" message="Obteniendo provincias..."
            color="primary">
          </loading-text-spinner>
          <div *ngIf="cliente.invalid && cliente.errors.required && (cliente.dirty || cliente.touched)"
            class="alert alert-danger">
            Requerido
          </div>
        </div>
      </div>
    </form>
  </div>
  <small class="text-danger">&nbsp;*Campos obligatorios.</small>
</div>
<!--IMPRESOR FISCAL-->
<div class="card">
  <div class="card-header">
    <h5 class="text-center mb-0">Datos de imprenta</h5>
  </div>
  <div class="card-body" [formGroup]="datosImprentaGroup">
    <div class="row">
      <div class="col-6">
        <div class="col-6">
          <label class="col-form-label">*Razon social:</label>
        </div>
        <input class="form-control" formControlName="razonSocialImpresor" required>
        <div
          *ngIf="razonSocialImpresor.invalid && razonSocialImpresor.errors.required && (razonSocialImpresor.dirty || razonSocialImpresor.touched)"
          class="alert alert-danger">
          Requerido
        </div>
      </div>
      <div class="col-6">
        <div class="col-4">
          <label class="col-form-label">*CUIT:</label>
        </div>
        <input class="form-control" formControlName="cuitImpresor" (keypress)="onlyNumberKey($event)" required>
        <div
          *ngIf="cuitImpresor.invalid && cuitImpresor.errors.required && (cuitImpresor.dirty || cuitImpresor.touched)"
          class="alert alert-danger">
          Requerido
        </div>
        <div *ngIf="cuitImpresor.invalid && this.cuitImpresor.value != '' && (cuitImpresor.dirty || cuitImpresor.touched)" class="alert alert-danger">
          El número de CUIT no tiene el formato correcto.
        </div>
      </div>
    </div>
  </div>
  <small class="text-danger">&nbsp;*Campos obligatorios.</small>
</div>

<div class="card">
  <div class="card-header">
    <h5 class="text-center mb-0">Información Impositiva</h5>
  </div>
  <div class="card-body" [formGroup]="informacionImpositiva">
    <div class="row">
      <div class="col">
        <div class="mb-3">
          <label>Inicio de Actividades:&nbsp;</label>
          <div class="input-group mb-2">
            <input id="inicioActividades" class="form-control" ngbDatepicker #d="ngbDatepicker" type="date"
              formControlName="fechaActividades" [(ngModel)]="model" readonly required>
            <div class="input-group-append">
              <button type="button" class="input-group-btn btn btn-secondary" (click)="d.toggle()"><i
                  class="fa fa-calendar" aria-hidden="true"></i></button>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="mb-3">
          <label>*Numero IIBB:&nbsp;</label>
          <input id="numIIBB" type="text" class="form-control" (keypress)="onlyNumberKey($event)"
            formControlName="numeroIIBB" required>
        </div>
        <div *ngIf="numeroIIBB.invalid && numeroIIBB.errors.required && (numeroIIBB.dirty || numeroIIBB.touched)"
          class="alert alert-danger">
          Requerido
        </div>
      </div>
      <div class="col">
        <div class="mb-3">
          <label>Tipo de Operación:&nbsp;</label>
          <select id="tipoOperacion" class="form-select" formControlName="tipoOperacion">
            <option value="0">Productos y Servicios</option>
            <option value="1">Otros</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="mb-3">
          <label>Pago Maximo de Contado:&nbsp;</label>
          <input id="pagoMaximoContado" type="text" class="form-control" style="width: 150px;"
            (keypress)="onlyNumberKey($event)" formControlName="pagoMaximoContado">
        </div>
      </div>
      <div class="col">
        <div class="mb-3">
          <label>Pago Máximo Medio Electrónico:&nbsp;</label>
          <input id="pagoMaximoElectronico" type="text" class="form-control" style="width: 150px;"
            (keypress)="onlyNumberKey($event)" formControlName="pagoMaximoElectronico">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="mb-3">
          <label>Agente de Percepción de IVA?</label>
          <div>
            <div class="form-check d-inline-flex" style="margin: 0px;">
              <input class="form-check-input" type="radio" id="formCheck-1" formControlName="percIVA" value="true">
              <label class="form-check-label" for="formCheck-1">Si&nbsp;</label>
            </div>
            <div class="form-check d-inline-flex">
              <input class="form-check-input" type="radio" id="formCheck-2" formControlName="percIVA" value="false">
              <label class="form-check-label" for="formCheck-2">No</label>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="mb-3">
          <label>Agente de Percepción IIBB?:</label>
          <div>
            <div class="form-check d-inline-flex me-auto">
              <input class="form-check-input" type="radio" id="formCheck-3" formControlName="percIIBB" value="true">
              <label class="form-check-label" for="formCheck-3">Si&nbsp;</label>
            </div>
            <div class="form-check d-inline-flex me-auto">
              <input class="form-check-input" type="radio" id="formCheck-4" formControlName="percIIBB" value="false">
              <label class="form-check-label" for="formCheck-4">No</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="mb-3">
          <label>Alicuota Percepción IVA Productos:&nbsp;&nbsp;</label>
          <input type="text" id="alicuotaPercIVAProd" class="form-control" style="width: 150px;"
            formControlName="alicuotaIVA">
        </div>
      </div>
      <div class="col">
        <div class="mb-3">
          <label>Alicuota Percepción IVA Otros:&nbsp;</label>
          <input type="text" id="alicuotaPercIVAOtros" class="form-control" formControlName="alicuotaIVAOtros"
            style="width: 150px;">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="mb-3">
          <label>Alicuota Percepción IVA No Categorizado:&nbsp;&nbsp;</label>
          <input type="text" id="alicuotaPercIVANoCat" class="form-control" style="width: 150px;"
            formControlName="alicuotaIVANoCat">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="mb-3">
          <label>Leyendas de Factura:&nbsp; &nbsp;</label>
          <button class="btn btn-primary" type="button" (click)="agregarLeyendas(content)"
            [disabled]="this.emisorSeleccionado == null">Agregar o Modificar</button>
        </div>
      </div>
      <div *ngIf="!authService.hasRole(TipoUsuario.FINANZAS)" class="col">
        <label>Certificado AFIP:&nbsp;</label>
        <div class="mb-3">
          <button class="btn btn-primary" type="button" (click)="agregarCertificado(content2)"
            [disabled]="this.emisorSeleccionado == null">Agregar o Modificar</button>
        </div>
      </div>
    </div>
  </div>
  <small class="text-danger">&nbsp;*Campos obligatorios.</small>
  <div class="card-footer bg-light d-flex justify-content-center" *ngIf="this.emisorSeleccionado != null">
    <button class="btn btn-primary me-2" id="aceptar"      
      [disabled]="!informacionFiscal.valid && !informacionImpositiva.valid || actualizandoEmisor || authService.hasRole(TipoUsuario.FINANZAS)"
      (click)="guardarEmisor()">
      <span *ngIf="!actualizandoEmisor">Actualizar</span>
      <div *ngIf="actualizandoEmisor" class="d-flex align-items-center">
        <div class="spinner-border text-light spinner-border-sm" role="status"></div>
        <span>Actualizando...</span>
      </div>
    </button>
  </div>
  <div class="card-footer bg-light d-flex justify-content-center" *ngIf="this.emisorSeleccionado == null">
    <button class="btn btn-primary me-2" id="aceptar"      
      [disabled]="!informacionFiscal.valid && !informacionImpositiva.valid || actualizandoEmisor || authService.hasRole(TipoUsuario.FINANZAS)"
      (click)="crearEmisor()">
      <span *ngIf="!actualizandoEmisor">Crear</span>
      <div *ngIf="actualizandoEmisor" class="d-flex align-items-center">
        <div class="spinner-border text-light spinner-border-sm" role="status"></div>
        <span>Creando...</span>
      </div>
    </button>
  </div>
</div>
<ng-template #content let-modal>
  <app-modal-leyendas [emisorSeleccionado]="emisorSeleccionado">
  </app-modal-leyendas>
</ng-template>
<ng-template #content2 let-modal>
  <app-modal-certificado [emisorSeleccionado]="emisorSeleccionado">
  </app-modal-certificado>
</ng-template>