
<div class="modal-header bg-light">
    <h4 class="modal-title">Archivos adjuntos</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismissAll()">
      
    </button>
</div>

<div class="modal-body">
    <div *ngIf="archivosAdjuntos.length > 0">
        <div class="col-md-12 bloque-datos" style="font-weight: bold; margin-top: 0.75em">
          Listado de adjuntos:
        </div>

        <div class="col-md-11 offset-md-1 bloque-datos" style="padding-top: 0em">
        <div style="margin-top: 0.5em;" *ngFor="let archivo of archivosAdjuntos"><a href="{{archivo}}"><i class="fa fa-cloud-download" aria-hidden="true"></i> {{archivo}}</a></div>
    </div>
</div>