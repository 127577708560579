import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, NgModel, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { UsuarioService } from 'src/app/account/usuario.service';
import { EstadoPedidoMonitoreo } from 'src/app/shared/enums/EstadoPedidoMonitoreo';
import { MonitoreoConfiguracion } from 'src/app/shared/models/despacho/MonitoreoConfiguracion';
import { MonitoreoConfiguracionTiempo } from 'src/app/shared/models/despacho/MonitoreoConfiguracionTiempo';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { SharedService } from 'src/app/shared/shared.service';
import { MessageService } from 'src/app/shell/message.service';
import { PedidoService } from '../../pedido.service';

@Component({
  selector: 'app-screen-configuracion',
  templateUrl: './screen-configuracion.component.html',
  styleUrls: ['./screen-configuracion.component.scss']
})
export class ScreenConfiguracionComponent implements OnInit {

  configuracionForm= new UntypedFormGroup({
    minutosFormControl: new UntypedFormControl(null, [
      Validators.required,
      Validators.min(10),
      Validators.max(500),
    ]),
    permiteActualizacionFormControl: new UntypedFormControl(false),
    permiteCreacionFormControl: new UntypedFormControl(false)
  });

  change$: Subscription;
  configuracion:MonitoreoConfiguracion;
  configuracionTiempo:MonitoreoConfiguracionTiempo[] = [];
  EstadoPedidoMonitoreo = EstadoPedidoMonitoreo;

  colores = [    
    {
      name: 'AZUL',
      value: '#0000FF'   
    },
    {
      name: 'CELESTE',
      value: '#00AAE4'   
    },
    {
      name: 'VERDE',
      value: '#00FF00'   
    },
    {
      name: 'AMARILLO',
      value: '#FFFF00'   
    },   
    {
      name: 'MAGENTA',
      value: '#FF00FF'   
    },
    {
      name: 'CIAN',
      value: '#00FFFF'
    },
    {
      name: 'VIOLETA',
      value: '#8800FF'     
    },
    {
      name: 'NARANJA',
      value: '#FF8800'  
    },
    {
      name: 'ROSA',
      value: '#FFC0CB'  
    },
    {
      name: 'BORDO',
      value: '#800040'  
    },
    {
      name: 'ROJO',
      value: '#FF0000'
    },
    {
      name: 'MARRON',
      value: '#804000'  
    }
  ];
  
  modal:NgbActiveModal;
  guardandoDatos: boolean = false;
  obteniendoConfiguracion: boolean = false;
  editarColorPrecargado: boolean = false;
  editarColorCargado: boolean = false;
  editarColorPrioridad: boolean = false;
  selectedColor = "#0000FF";
  selectedColorPrecargado: string;
  selectedColorCargado: string;
  selectedColorPrioridad: string;

  constructor(
    private messageService:MessageService,
    private usuarioService:UsuarioService,
    private pedidoService:PedidoService,
    sharedService: SharedService
    ) {
      this.change$ = sharedService.aeroplantaSeleccionada.subscribe(_=>{
        this.cambiarDeAeroplanta();
      });
    }

  ngOnInit(): void {
    this.obteniendoConfiguracion=true;
    this.selectedColor = "#0000FF";
    this.pedidoService.getMonitoreoConfiguracion(this.getCodigoAeroplanta())
      .subscribe( c => {
        this.configuracion=c;
        this.selectedColorPrecargado=c.colorPrecargado;
        this.selectedColorCargado=c.colorCargado;
        this.selectedColorPrioridad=c.colorPrioridad;
        this.permiteActualizacionFormControl.setValue(this.configuracion.permiteActualizacionPedidos);
        this.permiteCreacionFormControl.setValue(this.configuracion.permiteCreacionPedidos);
        this.configuracionTiempo=this.configuracion.configuracionesTiempo.sort( (a,b) => a.minutos - b.minutos );
        this.obteniendoConfiguracion=false;
      });
    
  }

  cambiarDeAeroplanta() {
    this.ngOnInit();
  }

  ngOnDestroy(): void {
    this.change$.unsubscribe(); 
  }

  onChange(value, tipo:EstadoPedidoMonitoreo) {
    if(tipo!=null){
      if(this.configuracion.configuracionesTiempo.every( c => c.color!=value )) {         
        if(tipo==EstadoPedidoMonitoreo.Precargado) {      
          this.selectedColorPrecargado=value;
          this.configuracion.colorPrecargado=value;
          this.editarColorPrecargado=false;
        }
        if(tipo==EstadoPedidoMonitoreo.Cargado) {      
          this.selectedColorCargado=value;
          this.configuracion.colorCargado=value;
          this.editarColorCargado=false;
        }
        if(tipo==EstadoPedidoMonitoreo.Prioridad) {      
          this.selectedColorPrioridad=value;
          this.configuracion.colorPrioridad=value;
          this.editarColorPrioridad=false;
        }
      } else {
        this.messageService.showWarningMessage("El color ya se encuentra configurado.", false);
        switch (tipo)
        {
          case EstadoPedidoMonitoreo.Precargado:
            this.editarColorPrecargado=false;
            break;
          case EstadoPedidoMonitoreo.Cargado:
            this.editarColorCargado=false;
            break;
          case EstadoPedidoMonitoreo.Prioridad:
            this.editarColorCargado=false;
            break;
        } 
      }
    } else {
      this.selectedColor=value;
    }    
  }

  borrarConfiguracion(item) {
    this.configuracion.configuracionesTiempo = this.configuracion.configuracionesTiempo.filter(x => x!=item);
  }

  obtenerNombreColor(value) {
    return this.colores.filter( c => c.value == value )[0].name
  }

  getCodigoAeroplanta(){
    return this.usuarioService.getAeroplantaActual().codigoAeroplanta;
  }

  agregarNuevaConfiguracion() {
    if(this.minutosFormControl.valid) {
      if(this.configuracion.configuracionesTiempo.some( c => c.color == this.selectedColor)) {
        this.messageService.showErrorMessage("El color seleccionado ya se encuentra configurado");
        return;
      } else {
        if(this.configuracion.colorPrecargado == this.selectedColor) {
          this.messageService.showErrorMessage("El color seleccionado ya se encuentra configurado como Precargado");
          return;
        } else if(this.configuracion.colorCargado == this.selectedColor){
          this.messageService.showErrorMessage("El color seleccionado ya se encuentra configurado como Cargado");
          return;
        } else if(this.configuracion.colorPrioridad == this.selectedColor){
          this.messageService.showErrorMessage("El color seleccionado ya se encuentra configurado como Prioritario");
          return;
        }
      }
      let nuevaConfiguracion = new MonitoreoConfiguracionTiempo();
      nuevaConfiguracion.minutos=this.minutosFormControl.value;
      nuevaConfiguracion.detalle="Minutos antes de la partida "+this.minutosFormControl.value;
      nuevaConfiguracion.color=this.selectedColor;      
      this.configuracion.configuracionesTiempo.push(nuevaConfiguracion);
    } else {
      this.messageService.showErrorMessage("Por favor revise los campos a completar.");      
    }
    
  }

  modificarConfiguracion(estado:EstadoPedidoMonitoreo) {
    switch (estado)
    {
      case EstadoPedidoMonitoreo.Precargado:
        this.editarColorPrecargado=true;
        break;
      case EstadoPedidoMonitoreo.Cargado:
        this.editarColorCargado=true;
        break;
      case EstadoPedidoMonitoreo.Prioridad:
        this.editarColorPrioridad=true;
        break;
    }    
  }

  guardarConfiguracion() {
    this.guardandoDatos = true;
    this.configuracion.permiteActualizacionPedidos=this.permiteActualizacionFormControl.value;
    this.configuracion.permiteCreacionPedidos=this.permiteCreacionFormControl.value;
    this.pedidoService.putMonitoreoConfiguracion(this.configuracion)
      .subscribe(result => {
        this.messageService.showSuccessMessage('Configuración modificada con éxito.');
        this.guardandoDatos = false;
      }, (err: ErrorModel) => { 
        this.messageService.showErrorMessage('Hubo un error al modificar la configuración.');
        this.guardandoDatos = false;
      });       
  }

  get minutosFormControl() {
    return this.configuracionForm.get('minutosFormControl');
  }

  get permiteActualizacionFormControl() {
    return this.configuracionForm.get('permiteActualizacionFormControl');
  }

  get permiteCreacionFormControl() {
    return this.configuracionForm.get('permiteCreacionFormControl');
  }
}
