import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NuevoTanqueComponent } from './nuevo-tanque/nuevo-tanque.component';
import { MessageService } from 'src/app/shell/message.service';
import { Tanque } from 'src/app/shared/models/stock/Tanque';
import { GraduacionesImportComponent } from './graduaciones-import/graduaciones-import.component';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Aeroplanta } from 'src/app/shared/models/despacho/Aeroplanta';
import { UsuarioService } from 'src/app/account/usuario.service';
import { TanqueService } from '../../tanques.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormaTanque } from 'src/app/shared/enums/formaTanque';
import { VisorEquivalenciasComponent } from './visor-equivalencias/visor-equivalencias.component';
import { Subscription } from 'rxjs';
import { Guid } from 'guid-typescript';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-tanques',
  templateUrl: './tanques.component.html',
  styleUrls: ['./tanques.component.css']
})
export class TanquesComponent implements OnInit {

  TipoUsuario = TipoUsuario;

  tanques: Tanque[] = []
  tablaTanques: UntypedFormGroup;
  aeroplanta: Aeroplanta;
  obteniendoTanques: boolean;
  messageTanques: string = "C a r g a n d o . . .";
  enumTipoTanque = FormaTanque;

  change$: Subscription;

  constructor( private authService: AuthService, 
    private messageService: MessageService,
    private modalService: NgbModal,
    private fb: UntypedFormBuilder,
    private usuarioService: UsuarioService,
    private tanquesService: TanqueService,
    private spinner: NgxSpinnerService,
    sharedService: SharedService) {
      this.tablaTanques = this.fb.group({
        tanques: new UntypedFormArray([])
      });
      this.change$ = sharedService.aeroplantaSeleccionada.subscribe(_=>{
        this.recargarPagina()
      })
    }

  ngOnInit() {
    this.obteniendoTanques = true;
    this.tanques = [];
    this.aeroplanta = this.usuarioService.getAeroplantaActual();
    this.spinner.show('spinnerTanque');
    this.getTanques();
  }

  ngOnDestroy(): void {
    this.change$.unsubscribe(); 
  }

  recargarPagina() {
    this.ngOnInit();
  }

  // llamadas al service

  getTanques(){
    this.tanquesService.getTanquesPorAeroplanta(this.aeroplanta?.codigoAeroplanta)
      .subscribe(result => {
        this.tanques = result;
        this.addCheckboxes();
        this.spinner.hide('spinnerTanque');
        this.obteniendoTanques = false;
      }),
      () => {
        this.obteniendoTanques = false;
        this.spinner.hide('spinnerTanque');
      }
  }

  actualizarTaques(t: Tanque){
    t.activo ? t.activo = false : t.activo = true;
    this.tanquesService.editarTanque(this.aeroplanta.codigoAeroplanta, t)
      .subscribe(result => {
        result.activo ? this.messageService.showSuccessMessage('El tanque se activo correctamente.') : this.messageService.showErrorMessage('El tanque se desactivo correctamente.');
      });
  }

  // modals

  agregarTanque(){
    let modalRef = this.modalService.open(NuevoTanqueComponent, { centered: true, backdrop: 'static' });
    modalRef.componentInstance.aeroplanta = this.aeroplanta;
    modalRef.componentInstance.arrayTanques = this.tanques;
    modalRef.result.then((result: Tanque) => {
      this.getTanques();
    }, () => { });
  }

  editarTanque(tanque: Tanque){
    let modalRef = this.modalService.open(NuevoTanqueComponent, { centered: true, backdrop: 'static' });
    modalRef.componentInstance.tanque = tanque;
    modalRef.componentInstance.aeroplanta = this.aeroplanta;
    modalRef.result.then((result: Tanque) => {
    }, () => { this.getTanques();  });
  }

  verEquivalencias(tanque: Tanque){
    let modalRef = this.modalService.open(VisorEquivalenciasComponent, { centered: true, backdrop: 'static' });
    modalRef.componentInstance.tanque = tanque;
  }

  subirMediciones(tanque: Tanque){
    let modalRef = this.modalService.open(GraduacionesImportComponent, { centered: true, size: 'lg' });
    modalRef.componentInstance.tanque = tanque;
    modalRef.result.then((result: Tanque) => {
    }, () => { });
  }

  private addCheckboxes() {
    // reinicio el formulario
    this.tablaTanques.setControl('tanques', this.fb.array([]));
    this.tanques.map((o, i) => {
      const control = new UntypedFormControl(i === 0); // if first item set to true, else false
      control.setValue(o.activo);
      (this.tablaTanques.controls.tanques as UntypedFormArray).push(control);
    });
  }

  keysTipoTanque(): Array<string> {
    var keys = Object.keys(this.enumTipoTanque);
    return keys.slice(keys.length / 2);
  }

  getDescripcionTipoTanque(tipoTanque: number) : String{
    let tiposTanque: Array<string> = this.keysTipoTanque();
    return tiposTanque[tipoTanque];
}

// validaciones
  onlyDecimalPositivo(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46;
  }

  hasRole(...roles: TipoUsuario[]) {
    return this.authService.hasRole(...roles);
  }
}
