<div class="col-12 mt-2" *ngIf="cargando && this.cierreSeleccionado != null">
  <div class="container">
    <div class="mb-3" style="height: 10cm;">
      <ngx-spinner name="spinnerGrafico" [ngStyle]="{'height': '400px', 'z-index':'0'}" bdOpacity=0.3bd
        Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
        <p style="color: white">C a r g a n d o . . . </p>
      </ngx-spinner>
    </div>
  </div>
</div>

<div class="card-body" *ngIf="this.cierreSeleccionado != null && !cargando && this.reporteSeleccionado == 4">
  <!-- productos -->
  <div ngbDropdown class="noImprimir" autoClose="outside">
    <button class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2" id="dropdownForm1"
      ngbDropdownToggle>Seleccionar Producto</button>
    <div ngbDropdownMenu aria-labelledby="dropdownForm1" style="z-index: 50">
      <div class="px-4 py-3" style="z-index: 50">
        <div class="form-check mb-2">
          <input type="checkbox" checked class="custom-control-input" id="Todos"
            (change)="seleccionarTodos($event)">
          <label class="custom-control-label text-nowrap" for="Todos">
            Todos los productos
          </label>
        </div>
        <div *ngFor="let pro of productos">
          <div class="form-check">
            <input type="checkbox" class="custom-control-input" id={{pro.name}}
              [(ngModel)]="pro.seleccionado">
            <label class="custom-control-label text-nowrap" for={{pro.name}}>
              {{ pro.name }}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 mt-2" *ngFor="let dist of productos">
    <div class="border border-solid saltoPagImp" *ngIf="dist.seleccionado">
      <label class="ms-1"><b>Movimientos realizados del producto: {{dist.name}}</b></label>
      <!--ABASTECEDORAS-->
      <table class="table table-striped table-hover letra">
        <thead class="text-center">
          <tr>
            <th>Producto</th>
            <th>Fecha</th>
            <th>Titulo</th>
            <th>Entrada natural</th>
            <th>Salida natural</th>
            <th>Entrada 15°</th>
            <th>Salida 15°</th>
            <th>Stock natural</th>
            <th>Stock 15°</th>
            <th>Comprobante</th>
          </tr>
        </thead>
        <tbody class="text-center">
          <tr *ngFor="let mov of this.arregloDistintoProd(dist.name)">
            <td>{{mov.nombreProducto}}</td>
            <td>{{mov.fechaHora | date: 'dd/MM/yyyy hh:mm a'}}</td>
            <td>{{mov.titulo}}</td>
            <td>{{mov.entradaNatural? mov.entradaNatural : 0}}</td>
            <td>{{mov.salidaNatural? mov.salidaNatural : 0}}</td>
            <td>{{mov.entrada15? mov.entrada15 : 0}}</td>
            <td>{{mov.salida15? mov.salida15 : 0}}</td>
            <td>{{mov.stock? mov.stock : 0}}</td>
            <td>{{mov.stock15? mov.stock15 : 0}}</td>
            <td *ngIf="mov.comprobante == null">-----</td>
            <ng-container *ngIf="mov.comprobante != null">
              <td
                *ngIf="mov.comprobante.remito == null && mov.comprobante.remitoCargamento == null && mov.comprobante.remitoSalida == null && mov.comprobante.remitoAeroespecialidad == null">
                -----</td>              
              <td *ngIf="mov.comprobante.remito != null">
                AEV-{{mov.comprobante.remito.aerovalePrefijo | aerovalePrefijo }}-{{mov.comprobante.remito.aerovaleNumero | aerovaleNumero}}</td>
              <td *ngIf="mov.comprobante.remitoCargamento != null">
                REM-{{mov.comprobante.prefijo | remitoPrefijo }}-{{mov.comprobante.numeroRemito | remitoNumero }}
              </td>
              <td *ngIf="mov.comprobante.remitoAeroespecialidad != null">
                REM-{{mov.comprobante.prefijo | remitoPrefijo }}-{{mov.comprobante.numeroRemito | remitoNumero }}
              </td>
              <td *ngIf="mov.comprobante.remitoSalida != null">
                REM-{{mov.comprobante.remitoSalida.prefijoFOX | remitoPrefijo }}-{{mov.comprobante.remitoSalida.numeroFOX | remitoNumero }}</td>
            </ng-container>
          </tr>
          <tr>
            <td><b>TOTAL</b></td>
            <td>{{this.cierreSeleccionado.fechaHora | date: 'dd/MM/yyyy HH:mm a'}}</td>
            <td><ul class="list-unstyled"><li>Stock Natural Incial: {{this.primerItem.stock}}</li><li>Stock 15 Incial: {{this.primerItem.stock15? this.primerItem.stock15 : 0}}</li></ul></td>
            <td>{{sumar(dist, 'entradaNatural') | number: '0.0-0'}}</td>
            <td>{{sumar(dist, 'salidaNatural') | number: '0.0-0'}}</td>
            <td>{{sumar(dist, 'entrada15') | number: '0.0-0'}}</td>
            <td>{{sumar(dist, 'salida15') | number: '0.0-0'}}</td>
            <td>{{this.ultimoItem.stock? this.ultimoItem.stock : 0}}</td>
            <td>{{this.ultimoItem.stock15? this.ultimoItem.stock15 : 0 }}</td>
            <td>--------</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- <div class="row">
    <div class="col-md-12">
      <button style="float:right;" *ngIf="cierreSeleccionado.id != null" (click)="exportar()" class="btn btn-primary">
        <i class="fa fa-file-excel-o" aria-hidden="true"></i> Exportar 
      </button>
    </div>
  </div> -->
</div>