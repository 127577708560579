<div class="col col-md-12">
  <div class="spinner-container" *ngIf="cargandoClientes">
    <ngx-spinner name="spinnerGrafico" bdOpacity=0.3bd
      Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
      <p style="color: white">C a r g a n d o . . . </p>
    </ngx-spinner>
  </div>
</div>
<div *ngIf="!cargandoClientes">
  <div *ngFor="let cli of clientesFiltrados; let i=index">
    <button type="button" [class.active]="" class="list-group-item list-group-item-action" (click)="mostrarDetalle(i)">
      <div class="d-flex w-100 justify-content-between">
        <p>{{ cli.razonSocial }} </p>
      </div>
    </button>
  </div>
</div>