import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ComprobanteService } from 'src/app/facturacion/comprobante.service';
import { CuponPagoTarjeta } from '../models/facturacion/CuponPagoTarjeta';
import { Tarjeta } from '../models/facturacion/Tarjeta';
import { SharedService } from '../shared.service';

@Component({
  selector: 'app-agregar-cupon',
  templateUrl: './agregar-cupon.component.html',
  styleUrls: ['./agregar-cupon.component.css']
})
export class AgregarCuponComponent implements OnInit {

  @Input() listaCupones: CuponPagoTarjeta[];
  @Output() nuevoCupon: EventEmitter<CuponPagoTarjeta> = new EventEmitter<CuponPagoTarjeta>();

  cargandoTarjetas: boolean = false;
  errorProductos: boolean;
  tarjetas: Tarjeta[];
  creandoCaneria: boolean = false;
  file: File;
  error: string = null;

  cuponesForm = new UntypedFormGroup({
    tarjetaFormControl: new UntypedFormControl('', [Validators.required]),
    numeroTarjetaFormControl: new UntypedFormControl('', [Validators.required]),
    loteCuponFormControl: new UntypedFormControl('', [Validators.required]),
    numeroCuponFormControl: new UntypedFormControl('', [Validators.required]),
    importeFormControl: new UntypedFormControl('', [Validators.required])
  });

  constructor(public activeModal: NgbActiveModal, 
    private comprobanteService: ComprobanteService,
    private sharedService: SharedService) { }

  ngOnInit() {
    this.getTarjetas();
  }

  getTarjetas() {
    this.cargandoTarjetas = true;
    this.comprobanteService.getTarjetas()
      .subscribe(result => {
        this.tarjetas = result;
        this.cargandoTarjetas = false;
      }, (error) => {
        this.cargandoTarjetas = false;
      })
  }

  checkCupones() {
    this.error=null;
    
    if(!this.file) {
      this.error="Debe seleccionar un archivo adjunto del cupón.";
    }

    if (this.estaNumeroCuponEnTarjeta(this.loteCuponFormControl.value, this.numeroCuponFormControl.value, this.numeroTarjetaFormControl.value)) {
      this.error="Este número de cupón ya ha sido cargado.";
    }

    return this.error==null;
  }

  agregarCupon() {
    if(this.checkCupones()) {
      let c = new CuponPagoTarjeta();
      c.tarjeta = this.tarjetaFormControl.value;
      c.numeroTarjeta = this.numeroTarjetaFormControl.value;
      c.numeroLote = this.loteCuponFormControl.value;
      c.numeroCupon = this.numeroCuponFormControl.value;
      c.importe = this.importeFormControl.value;
      c.pathCuponFisico = this.file.name;
      c._file = this.file;
      this.nuevoCupon.emit(c);
      this.activeModal.close(true);
    }
  }

  get fileName(): string {
    if (this.file) {
      return this.file.name;
    }
    return '';
  }

  onSelectedFile(event) {
    const selectedFile: File = event.target.files[0];
    if (selectedFile) {
      const date=new Date();
      const now = date.getFullYear() + ("0" + (date.getMonth() + 1)).slice(-2) + ("0" + date.getDate()).slice(-2) + ("0" + date.getHours() ).slice(-2) + ("0" + date.getMinutes()).slice(-2) + ("0" + date.getSeconds()).slice(-2)
      const newName: string = `JPEG_CUPON_${now}.${selectedFile.name.split('.')[1]}`; // Cambia esto al nuevo nombre que desees
      const modifiedFile = new File([selectedFile], newName, { type: selectedFile.type });

      this.sharedService.resizeImage(modifiedFile, 1500).then(blob => {
        this.file = new File([blob], modifiedFile.name, { type: 'image/jpeg' });
      });
    }
  }

  // validaciones

  estaNumeroCuponEnTarjeta(numeroLote: number, numeroCupon: number, numeroTarjeta: number): boolean
  {

    let element:CuponPagoTarjeta;
    const encontre= this.listaCupones.some(element=> element.numeroCupon==numeroCupon && element.numeroLote==numeroLote && element.numeroTarjeta==numeroTarjeta)
    console.log("Al corroborar si el numeroCupon"+JSON.stringify(numeroCupon)+" numeroTarjeta "+JSON.stringify(numeroCupon)+" lo encontré? "+encontre);
    return encontre;
  }

  get tarjetaFormControl() {
    return this.cuponesForm.get('tarjetaFormControl');
  }
  get numeroTarjetaFormControl() {
    return this.cuponesForm.get('numeroTarjetaFormControl');
  }
  get loteCuponFormControl() {
    return this.cuponesForm.get('loteCuponFormControl');
  }
  get numeroCuponFormControl() {
    return this.cuponesForm.get('numeroCuponFormControl');
  }
  get importeFormControl() {
    return this.cuponesForm.get('importeFormControl');
  }
}