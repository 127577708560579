import { DatePipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbCalendar, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, Subscription } from 'rxjs';
import { UsuarioService } from 'src/app/account/usuario.service';
import { AerovalesService } from 'src/app/despacho/aerovales.service';
import { PreciosService } from 'src/app/precios/precios.service';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { Comprobante } from 'src/app/shared/models/facturacion/Comprobante';
import { ComprobanteEncabezado } from 'src/app/shared/models/facturacion/ComprobanteEncabezado';
import { FacturaNumeroPipe } from '../../../shared/pipes/factura-numero.pipe';
import { FacturaPrefijoPipe } from '../../../shared/pipes/factura-prefijo.pipe';
import { MessageService } from 'src/app/shell/message.service';
import { ComprobanteService } from '../../comprobante.service';
import { FacturacionService } from '../../facturacion.service';
import { saveAs } from 'file-saver';
import { Renglon } from 'src/app/shared/models/facturacion/Renglon';
import { ImpuestoPercepcion } from 'src/app/shared/models/facturacion/ImpuestoPercepcion';
import { VisorImpuestoComponent } from 'src/app/shared/visor-impuesto/visor-impuesto.component';
import { NotificacionesService } from 'src/app/abm/notificacion/notificaciones.service';
import { NotificacionesComprobantes } from 'src/app/shared/models/notificaciones/NotificacionesComprobantes';
import { TipoMovimientoPipe } from '../../../shared/pipes/tipo-movimiento.pipe';
import { NombreTipoComprobantePipe } from '../../../shared/pipes/nombre-tipo-comprobante.pipe';
import { isNullOrUndefined } from 'src/app/shared/compat-tools';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';

export class filtroDto {
  "fechaDesde": string;
  "fechaHasta": string;
  "codigosAeroplantas": any[];
  "condicionVenta": number;
  "esNota": boolean;
}

@Component({
  selector: 'app-visor-documentos',
  templateUrl: './visor-documentos.component.html',
  styleUrls: ['./visor-documentos.component.scss']
})
export class VisorDocumentosComponent {

  spinner = inject(NgxSpinnerService);
  aerovalesService = inject(AerovalesService);
  comprobanteService = inject(ComprobanteService);
  facturacionService = inject(FacturacionService);
  precioService = inject(PreciosService);
  calendar = inject(NgbCalendar);
  router = inject(ActivatedRoute);
  messageService = inject(MessageService);
  usuarioService = inject(UsuarioService);
  facturaPrefijoPipe = inject(FacturaPrefijoPipe);
  facturaNumeroPipe = inject(FacturaNumeroPipe);
  datePipe = inject(DatePipe);
  tipoMovimientoPipe = inject(TipoMovimientoPipe);
  nombreTipoComprobantePipe = inject(NombreTipoComprobantePipe);
  activeModal = inject(NgbModal);
  notificacionesService = inject(NotificacionesService);
  authService = inject(AuthService);

  modelDesde: NgbDateStruct;
  modelHasta: NgbDateStruct;
  facturas: ComprobanteEncabezado[]=[];
  facturasFiltradas: ComprobanteEncabezado[]=[];
  facturaObtenida: Comprobante = null;
  showLista: boolean = true;
  showDetalle: boolean = true;
  showScroll: boolean = true;

  readonly filtrosTotales: number = 1;
  filtrosDescargados: number = 0;
  private filtrosDescargados$ = new Subject<number>();
  cargandoFiltros: boolean = false;
  subscription: Subscription;
  aeroplantaParaCheckear: any[] = [];
  arrayCodigosAeroplantas: string[];

  aeroplantas: any[] = [];
  codigo: string;

  params: any = null;
  messageChange: string = null;

  busquedaFormControl = new FormControl;
  aeroplantaFormControl = new FormControl(null);

  throttle = 500;
  distance = 2;
  page = 1;
  pageSize = 10;
  stopScroll: boolean = false;

  ngOnInit() {
    this.subscription = this.filtrosDescargados$.subscribe(_=>{
      if(this.filtrosDescargados==this.filtrosTotales) {
        this.cargandoFiltros = false;
      }
    })
    this.initData();
    this.router.queryParams.subscribe(params => {
      this.params=params;
      this.initialCharge();
    });    
  }

  async initialCharge() {
    try {
      this.page = 1;
      this.facturaObtenida=null;
      this.changeSpinner(true, false);
      const body = this.obtenerBody();  
      this.aeroplantaParaCheckear = [];   
      this.cargarFiltros();
      const data = await this.comprobanteService.obtenerComprobantesPorFiltro(this.page, this.pageSize, body);
      if(data) {
        this.processResult(data, false);
      }
    } catch (error) {
      this.aeroplantaParaCheckear = [];   
      this.cargarFiltros();
      this.processError(error);
    }
  }

  initData() {
    this.filtrosDescargados = 0;
    this.cargandoFiltros = false;
    this.modelDesde = this.calendar.getToday();
    this.modelHasta = this.modelDesde;
  }

  //utils
  trackByFn(index, item) {
    return index;
  }

  clickEvent(event: Event) {
    event.stopPropagation(); // Detener la propagación del evento click button popover con el button lista
  }

  /**
  * @param show "true" para mostrar spinner lista o "false" para ocultar
  * @param detalle "true" para mostrar spinner detalle o "false" para ocultar
  * @param scroll (optional) "true" para mostrar scroll o "false" para ocultar
  */
  changeSpinner(show:boolean, detalle:boolean, scroll?:boolean) {
    this.showLista = show;
    this.showDetalle = detalle;
    this.showScroll = scroll;

    (this.showLista) ? this.spinner.show('spinnerLista') : this.spinner.hide('spinnerLista');
    (this.showDetalle) ? this.spinner.show('spinnerDetalle') : this.spinner.hide('spinnerDetalle');      
  }

  initRequest(isScroll: boolean) {
    if( !isScroll ){
      this.changeSpinner(true, false);
    }else{
      this.changeSpinner(false, false, true);
    }
  }
  
  processResult(data: ComprobanteEncabezado[], isScroll: boolean) {
    this.stopScroll = data?.length==0;
    if(this.params.condicion) {
      if(this.params.condicion == '1' && this.authService.hasRole(TipoUsuario.OPERADOR, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.SUPERVISOR)) {
        data = data.filter(a => this.arrayCodigosAeroplantas.includes(a.codigoAeroplanta));
      }
    } else if(this.authService.hasRole(TipoUsuario.OPERADOR, TipoUsuario.OPERADOR_3, TipoUsuario.TUTOR, TipoUsuario.OPERADORDIRECTO, TipoUsuario.SUPERVISOR)) {
      data = data.filter(a => (this.params.condicion == null && this.arrayCodigosAeroplantas.includes(a.codigoAeroplanta) && (a.codigoAeroplanta !== 'SCL' && a.codigoAeroplanta !== 'SCE')));
    }
    if(isScroll) {
      this.facturas = this.facturas.concat(data);
      this.facturas.sort((a, b) => new Date(b.fechaEmision).getTime() - new Date(a.fechaEmision).getTime());
      this.filtrarComprobantes();    
    } else {      
      this.facturas=data;
      this.facturasFiltradas=data;      
    }
    this.changeSpinner(false, false, false);
    this.cargandoFiltros = false;
  }

  processError(error: ErrorModel) {    
    this.changeSpinner(false, false, false); 
    this.messageService.showErrorMessage('Hubo un error al obtener los datos. ' + error.description);
  }

  async onScrollDown() {
    try {
      this.initRequest(true);
      const body = this.obtenerBody();
      const data = await this.comprobanteService.obtenerComprobantesPorFiltro(++this.page, this.pageSize, body);
      if(data) {
          this.processResult(data, true)
        }
    } catch (error) {
      this.processError(error);      
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  cargarFiltros() {
    this.cargandoFiltros = true;
    this.getAeroplantas();
  }

  filtrarComprobantes() {
    this.facturasFiltradas = this.facturas.filter(f => {
      let agregar = true;
      if (this.busquedaFormControl.value != '' && !isNullOrUndefined(this.busquedaFormControl.value)) {        
        agregar = f.razonSocialCliente && f.razonSocialCliente.toLowerCase().indexOf(this.busquedaFormControl.value.toLowerCase()) > -1;

        agregar = agregar || (this.facturacionService.formatearComprobante(this.facturaPrefijoPipe.transform(f.prefijo),this.facturaNumeroPipe.transform(f.numeroComprobante)).indexOf(this.busquedaFormControl.value) > -1);
      }

      return agregar;
    });
  }
  
  formatDate(year, month, day, hour, minute): string {
    return year + '-' + month.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + '-' + day.toLocaleString(undefined, { minimumIntegerDigits: 2 })
      + 'T' + hour.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + minute.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':00';
  }

  getAeroplantas() {
    let aeroplantasUsuario = this.usuarioService.getAeroplantasCurrentUser()
    aeroplantasUsuario = aeroplantasUsuario.filter(a => a.validada == true);
    this.arrayCodigosAeroplantas = aeroplantasUsuario.map(a => a.codigoAeroplanta);
    this.precioService.obtenerTodasLasAeroplantas()
      .subscribe(result => {
        this.aeroplantas = result.filter(a=>a.codigoAeroplanta!='SCL' && a.codigoAeroplanta!='SCE').sort((a, b) => { if (a.nombre > b.nombre) { return 1 } else { return -1 } });
        if(this.params.condicion) {
          if(this.params.condicion == '1' && this.authService.hasRole(TipoUsuario.OPERADOR, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.SUPERVISOR)) {
            this.aeroplantas = this.aeroplantas.filter(a => this.arrayCodigosAeroplantas.includes(a.codigoAeroplanta));
          }
        } else if(this.params.condicion == null && this.authService.hasRole(TipoUsuario.OPERADOR, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.SUPERVISOR)) {
          this.aeroplantas = this.aeroplantas.filter(a => this.arrayCodigosAeroplantas.includes(a.codigoAeroplanta));
        }
        this.filtrosDescargados++;
        this.filtrosDescargados$.next();
        for (let a of this.aeroplantas) {
          this.aeroplantaParaCheckear.push({ "codigo": a.codigoAeroplanta, "nombre": a.nombre, "seleccionado": false });
        }
      }, (error) => {
        this.filtrosDescargados++;
        this.filtrosDescargados$.next();
      });
  }

  aeroplantasSeleccionada(){
    if(this.aeroplantaParaCheckear.some(a => a.seleccionado)){
      return this.aeroplantaParaCheckear.filter(a => a.seleccionado).map(a => a.codigo);
    }else{
      return null;
    }
  }

  fechaDesdeMayorQueHasta() {
    return new Date(this.fechaDesde) > new Date(this.fechaHasta);
  }

  onClick(item:any) {
    this.changeSpinner(false, true);
    const comp = `${this.nombreTipoComprobantePipe.transform(item.tipoMovimiento, this.condicionVta)} 
                  ${this.tipoMovimientoPipe.transform(item.tipoMovimiento)} 
                  ${this.facturaPrefijoPipe.transform(item.prefijo)} 
                  ${this.facturaNumeroPipe.transform(item.numeroComprobante)} `
    this.messageChange = `Cargando ${comp} ...`;
    this.comprobanteService.obtenerComprobantePorId(item.id)
      .subscribe(result => {
        this.facturaObtenida = result; //obtengo el comprobante segun id
        this.changeSpinner(false, false);
      }, ()=>{
        this.changeSpinner(false, false);
      });
  }

  /**
   * Botones Panel Derecho
   * @param f factura seleccionada en la lista
   */

  onClickExport(f:Comprobante) {
    this.messageChange = 'Exportando Comprobante . . .';
    this.changeSpinner(false, true);
    this.comprobanteService.exportarRemitosDeFactura(f.prefijo,f.numeroComprobante,f.tipoMovimiento)
      .subscribe((res: Blob) => {
        const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
        saveAs(blob, "factura" + f.prefijo + "-" + f.numeroComprobante + ".xlsx");
        this.changeSpinner(false, false);
      }, ()=> {
        this.changeSpinner(false, false);
      });
  }

  onClickView(f:Comprobante) {
    let renglones: Renglon[] = [];
    let impuestos: ImpuestoPercepcion[] = [];
    
    f.factura.renglones.forEach((value, index) => {
      renglones.push(f.factura.renglones[index]);
      f.factura.renglones[index].impuestosPercepciones.forEach((value2, index2) => {
        impuestos.push(f.factura.renglones[index].impuestosPercepciones[index2]);
      });
    });
    let modalRef = this.activeModal.open(VisorImpuestoComponent, { size: 'lg', centered: true, backdrop: 'static' })
    modalRef.componentInstance.renglones = renglones;
  }

  onClickDownload(f:Comprobante) {
    this.messageChange = 'Descargando Comprobante . . .';
    this.changeSpinner(false, true);
    this.comprobanteService.descargarFactura(f)
      .subscribe((res: Blob) => {
        const blob = new Blob([res], { type: 'application/pdf' });
        saveAs(blob, f.prefijo + '-' + f.numeroComprobante + ' ' + f.fechaHoraCreacion + '.pdf');
        this.changeSpinner(false, false);
      }, () => {
        this.changeSpinner(false, false);
      });
  }

  resendEmail() {
    let email:any;
    this.messageChange = 'Reeviando Comprobante por Correo . . .';
    this.changeSpinner(false, true);
    const comprobante = new NotificacionesComprobantes();
    comprobante.facturaId = this.facturaObtenida.id;
    comprobante.numeroDocumento = this.facturaObtenida.cliente.numeroDocumento;
    comprobante.codigoAeroplanta = this.facturaObtenida.codigoAeroplanta;
    comprobante.tipoMovimiento = this.facturaObtenida.tipoMovimiento;
    comprobante.prefijo = this.facturaObtenida.prefijo;
    comprobante.numero = this.facturaObtenida.numeroComprobante;
    this.notificacionesService.enviarFactura(comprobante)
      .subscribe((result) => {
        email = result;
        this.messageService.showSuccessLongMessage('El comprobante se reenvío a los correos: ' + email?.item1);
        this.changeSpinner(false, false);
      }, (err: ErrorModel) => {
        this.messageService.showErrorLongMessage(err.description);
        this.changeSpinner(false, false);
      });
  }

  obtenerBody(): filtroDto {
    const body = new filtroDto();
    body.fechaDesde = this.fechaDesde;
    body.fechaHasta = this.fechaHasta;
    body.codigosAeroplantas = this.aeroplantasSeleccionada();
    body.condicionVenta = parseInt(this.params.condicion);
    body.esNota = this.params.notas == 'true';
    return body;
  }

  // getters 

  get fechaDesde() {
    return this.formatDate(this.modelDesde.year, this.modelDesde.month, this.modelDesde.day, 0, 0);
  }

  get fechaHasta() {
    return this.formatDate(this.modelHasta.year, this.modelHasta.month, this.modelHasta.day, 23, 59);
  }

  get condicionVta() {
    return this.params.condicion;
  }
}
