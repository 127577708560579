import { Component, inject, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { AuthService } from './core/authentication/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [MessageService]
})
export class AppComponent implements OnInit {

  constructor() { }

  ngOnInit() {    
    
  }
}
