import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Guid } from 'guid-typescript';
import { ModalConfirmComponent } from 'src/app/shared/generic-modals/modal-confirm.component';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { NivelAprobacion } from 'src/app/shared/models/precios/NivelAprobacion';
import { UsuarioNivelAprobacion } from 'src/app/shared/models/precios/UsuarioNivelAprobacion';
import { MessageService } from 'src/app/shell/message.service';
import { PreciosService } from '../../precios.service';


@Component({
  selector: 'app-detalle-nivel',
  templateUrl: './detalle-nivel.component.html',
  styleUrls: ['./detalle-nivel.component.css']
})
export class DetalleNivelComponent implements OnInit {

  private _nivelSeleccionado: NivelAprobacion;
  error: string;
  esperandoEliminacion: boolean = false;

  usuariosAsignados: UsuarioNivelAprobacion[] = [];
  cargandoInformacion: boolean;

  constructor(private preciosService: PreciosService, private messageService: MessageService,
     private modalAceptar: NgbModal) { }

  ngOnInit() {
  }

  eliminarLinea(index: number) {
    const modalRef = this.modalAceptar.open(ModalConfirmComponent, { centered: true });
    modalRef.componentInstance.titulo = "Se eliminará el Usuario "+this.obtenerTipoDeUsuario(index)+": "+this.usuariosAsignados[index].nombreCompleto;
    modalRef.componentInstance.mensaje = "¿Está seguro?";
    modalRef.componentInstance.textoSi = "Si";
    modalRef.componentInstance.textoNo = "No";
    modalRef.result.then(() => {
    this.esperandoEliminacion = true;
    let delegado: string = this.nivelSeleccionado.usuariosNivel[index].idDelegado;
    let usuario: string = this.nivelSeleccionado.usuariosNivel[index].idUsuario;
    let nativo: string = this.nivelSeleccionado.usuariosNivel[index].idNativo;
    this.preciosService.eliminarUsuarioNivel(delegado, usuario, nativo)
      .subscribe(result => {
        this.messageService.showSuccessLongMessage('El usuario fue eliminado con éxito.');
        if (index !== -1) {
          this.nivelSeleccionado.usuariosNivel.splice(index, 1);
        }
        this.esperandoEliminacion = false;
      },
      (err: ErrorModel) => {
        this.messageService.showErrorMessage('Error al eliminar el nivel del usuario.');
        this.esperandoEliminacion = false;
      });
    });
  }



//getters y setters

 obtenerTipoDeUsuario(i:number): string
 {
  let aux="Delegado";
  console.log(this.usuariosAsignados[i].idDelegado);
  if (this.usuariosAsignados[i].idDelegado===Guid.EMPTY)
    {aux="Nativo";
    }
    return aux;
  }

 @Input() set nivelSeleccionado(value: NivelAprobacion) {
    this._nivelSeleccionado = value;
    this.usuariosAsignados = this.nivelSeleccionado.usuariosNivel;
  }

  get nivelSeleccionado(): NivelAprobacion {
    return this._nivelSeleccionado;
  }

}
