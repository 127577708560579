import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UsuarioService } from 'src/app/account/usuario.service';
import { SaldoCliente } from 'src/app/shared/models/controlCredito/SaldoCliente';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { MessageService } from 'src/app/shell/message.service';
import { ControlCreditoService } from '../control-credito.service';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { TipoUsuario } from '../../shared/enums/tipoUsuario';

@Component({
  selector: 'app-modal-notificacion',
  templateUrl: './modal-notificacion.component.html',
  styleUrls: ['./modal-notificacion.component.scss']
})
export class ModalNotificacionComponent implements OnInit {

  mailNotificacionForm: FormGroup;
  mailsNotificacion: string[] = [];
  @Input() cliente: SaldoCliente;
  @Input() idNotificacionSaldos: string;
  editandoContacto: boolean = false;
  grabando: boolean = false;
  TipoUsuario = TipoUsuario;
  
  constructor(private formBuilder: FormBuilder, public authService: AuthService, public activeModal: NgbActiveModal, public messageService: MessageService,
    private controlCreditoService: ControlCreditoService, private usuarioService: UsuarioService) { }

  ngOnInit(): void {
    this.crearFormulario();
    let mails = this.cliente.notificacionSaldo.emails.map(mail => mail);
    this.mailsNotificacion = mails;
  }

  crearFormulario() {
    this.mailNotificacionForm = this.formBuilder.group({
      emailFormControl: ['', [Validators.email]]
    });
  }

  borrarMail(i) {
    this.mailsNotificacion.splice(i, 1);
  }

  actualizar() {
    this.grabando = true;
    if (this.mailsNotificacion.length > 0) {
      if (this.mailsNotificacion.length > 0) {
        //chequear esto
        let notificacion = {
          "Activo": true,
          "Emails": this.mailsNotificacion,
          "TipoNovedad": {
            "Id": this.idNotificacionSaldos,
            "Descripcion": "Saldos"
          },
          "Mensaje": "El cliente " + this.cliente.clienteRazonSocial + " supero el monto asignado de " + this.cliente.monto,
          "Titulo": "Cliente excedido",
          "Usuarios": [{
            "Id": this.usuarioService.getIdUsuario(),
            "NombresCompleto": this.usuarioService.getNombresCompletosUsuario(),
            "DNI": this.usuarioService.getDniUsuario(),
            "Email": this.usuarioService.getEmailUsuario()
          }],
          "FiltrosSeleccionados": this.cliente.notificacionSaldo.filtrosSeleccionados
        }
        this.controlCreditoService.actualizarNotificacion(notificacion, this.cliente.notificacionSaldo.id).subscribe(notificacion => {
          this.cliente.notificacionSaldo = notificacion;
          this.activeModal.close(false);
          this.grabando = false;
        }, (err: ErrorModel) => {
          this.messageService.showErrorMessage('Hubo un error al obtener los datos. ' + err.description);
          this.activeModal.close(false);
          this.grabando = false;
        });
      }
    }
  }

  agregarMail() {
    if (this.emailFormControl.valid && this.emailFormControl.value) {
      this.mailsNotificacion.push(this.emailFormControl.value);
      this.emailFormControl.setValue('');
    }
  }

  cerrarModal() {
    this.activeModal.close(false);
  }

  /** getters */

  get emailFormControl() {
    return this.mailNotificacionForm.get('emailFormControl')
  }

}