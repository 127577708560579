import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HandleError, HttpErrorHandler } from 'src/app/http-error-handler.service';
import { Observable } from 'rxjs';
import { HttpClient} from '@angular/common/http';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { DatePipe } from '@angular/common';
import { tap, catchError} from 'rxjs/operators';
import { parametroImpositivo } from 'src/app/shared/models/abm/ParametroImpositivo';
import { Rubro } from 'src/app/shared/models/abm/Rubro';

@Injectable({
  providedIn: 'root'
})
export class RubroService {

  baseUrl = environment.apiServer.productosUrl;
  private handleError: HandleError;
  private rubrosUrl = this.baseUrl + '/RubrosV1';
  private rubros: Rubro[];

  constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler, private authService: AuthService, private datepipe: DatePipe) {
    this.handleError = httpErrorHandler.createHandleError('Rubros');
  }

  obtenerRubros(): Observable<Rubro[]> {
    return this.http.get<Rubro[]>(this.baseUrl + '/RubrosV1/')
      .pipe(
        tap(rubros => {
          this.rubros = rubros;
        },
          catchError(this.handleError('Obtener Rubros', new Rubro()))
        ));
  }

  agregarRubro(rubro: Rubro): Observable<Rubro> {
    return this.http.post<Rubro>(this.baseUrl + '/RubrosV1/', rubro)
      .pipe(
        tap(_ => console.log(` rubro id=${rubro.id}`)),
        catchError(this.handleError('al crear rubro', null))
      );
  }

  modificarRubro(rubro: Rubro): Observable<string> {
    return this.http.put<Rubro>(this.baseUrl + '/RubrosV1/', rubro)
      .pipe(
        tap(_ => console.log(` rubro id=${rubro.codigoRubro}`)),
        catchError(this.handleError('al modificar Rubro', null))
      );
  }

  obtenerRubro(id: string): Observable<Rubro> {
    return this.http.get<Rubro>(this.baseUrl + '/RubrosV1/' + id)
      .pipe(
        tap(_ => console.log(` rubro id=${id}`)),
        catchError(this.handleError('al obtener rubro', null))
      );
  }

  agregarImpuesto(impuesto: parametroImpositivo): Observable<parametroImpositivo> {
    return this.http.post<parametroImpositivo>(this.baseUrl + '/RubrosV1/ParametroImpositivo', impuesto)
      .pipe(
        tap(_ => console.log(` impuesto id=${impuesto.id}`)),
        catchError(this.handleError('al crear impuesto', null))
      );
  }

  modificarImpuesto(impuesto: parametroImpositivo): Observable<parametroImpositivo> {
    return this.http.put<parametroImpositivo>(this.baseUrl + '/RubrosV1/ParametroImpositivo', impuesto)
      .pipe(
        tap(_ => console.log(` impuesto id=${impuesto.id}`)),
        catchError(this.handleError('al crear impuesto', null))
      );
  }

  getRubros(): Observable<Rubro[]> {
    return this.http.get<Rubro[]>(this.rubrosUrl)
  }

  getRubroPorId(id: string): Observable<Rubro> {
    return this.http.get<Rubro>(this.rubrosUrl + '/' + id);
  }

}

