import { TipoSurtidor } from '../../enums/TipoSurtidor';
import { Pico } from '../despacho/Pico';
import { Expendedora } from './Expendedora';
import { Producto } from './Producto';
import { TanqueGeneral } from './TanqueGeneral';

export class Surtidor extends Expendedora{
    id: string;
    tanques: TanqueGeneral[];
    aforadorActual: number;
    codigoAeroplanta: string;
    tipoSurtidor: TipoSurtidor;
  }
