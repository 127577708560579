export class ResolucionMensual {
    id: string;
    ano: string;
    mes: string;
    generada: boolean;
    renglonesResolucion: Renglones[];
    informeHtml?: string;
}

export class Renglones {
    aeroplantaId: string;
    nombreResponsable: string;
    codigoAeroplanta: string;
    nombreAeroplanta: string;
    codigoProducto: string;
    nombreProducto: string;
    fechaUltimoCierre: string;
    existenciaAnterior: number;
    entradas: number;
    salidas: number;
    existenciaFinal: number;
    existenciaReal: number;
    diferencia: number;
    toleranciaMensualOperativa: number;
    removido:number;
    supDef:number;
    porcentual:number;
    resultado:string;
    resultadoTolerancia:number;
    aceptado: boolean;
    justificacion?: string;
}