import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { HttpErrorHandler } from '../http-error-handler.service';
import { AuthService } from '../core/authentication/auth.service';
import { Injectable } from '@angular/core';
import { Surtidor } from '../shared/models/stock/Surtidor';
import { Contenedor } from '../shared/models/stock/Contenedor';

@Injectable({
  providedIn: 'root'
})
export class SurtidorService {
  [x: string]: any;

  stockbaseUrl = environment.apiServer.stockBaseUrl;

  constructor(private httpClient: HttpClient, private authservice: AuthService) { }


  getSurtidoresPorAeroplanta(codigoAeroplanta: string): Observable<Surtidor[]> {
    return this.httpClient.get<Surtidor[]>(this.stockbaseUrl  + '/AeroplantasV1/Surtidores?codigoAeroplanta=' + codigoAeroplanta)
      .pipe(
        tap(_ => console.log('getSurtidoresPorAeroplanta')),
        catchError(this.handleError)
      );
  }

  getSurtidoresPorCodigo(codigoSurtidor: string): Observable<Surtidor> {
    return this.httpClient.get<Surtidor>(this.stockbaseUrl + '/SurtidoresV1/' + codigoSurtidor)
      .pipe(
        tap(_ => console.log('getSurtidoresPorCodigo')),
        catchError(this.handleError)
      );
  }

  crearSurtidor(surtidor: Surtidor): Observable<Surtidor> {
    console.log("entré a crear surtidor" + surtidor);
    return this.httpClient.post<Surtidor>(this.stockbaseUrl + '/SurtidoresV1/', surtidor);
  }

  editarSurtidor(surtidor: Surtidor): Observable<Surtidor> {
    return this.httpClient.put<Surtidor>(this.stockbaseUrl + '/SurtidoresV1/', surtidor);
  }

  obtenerProximoCodigoDeSurtidor(codigoAeroplanta: string, tipoDeContenedor: number){
    return this.httpClient.get(this.stockbaseUrl + '/SurtidoresV1/ProximoCodigoSurtidor/' + tipoDeContenedor + '/' + codigoAeroplanta, {responseType: 'text'})
      .pipe(
        tap(_ => console.log('getProximoCodigoSurtidor')),
        catchError(this.handleError)
      );
  }

  obtenerProximoCodigoDeTanque(codigoAeroplanta: string, tipoDeContenedor: number): Observable<Contenedor> {
    return this.httpClient.get<Contenedor>(this.stockbaseUrl + '/ContenedoresV1/ProximoCodigoContenedor/' + tipoDeContenedor + '/' + codigoAeroplanta)
      .pipe(
        tap(_ => console.log('getProximoCodigoSurtidor')),
        catchError(this.handleError)
      );
  }
}
