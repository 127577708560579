import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UploadFileService } from 'src/app/management/upload-file.service';
import { UrlApi } from 'src/app/shared/enums/urlApi';
import { ModalConfirmComponent } from 'src/app/shared/generic-modals/modal-confirm.component';
import { Tanque } from 'src/app/shared/models/stock/Tanque';

@Component({
  selector: 'app-graduaciones-import',
  templateUrl: './graduaciones-import.component.html',
  styleUrls: ['./graduaciones-import.component.css']
})
export class GraduacionesImportComponent implements OnInit {

  @Input() tanque : Tanque;

  userFileName: string;
  profileForm: UntypedFormGroup;
  error: string;
  fileUpload = {status: '', message: '', filePath: '', length:0 };
  selectedFile: boolean = false;
  archivoCargado: boolean = false;
  cargando: boolean;

  constructor(private fb: UntypedFormBuilder,
    private fileUploadService: UploadFileService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.profileForm = this.fb.group({
      name: [''],
      profile: [''],
      inputFile: ['']
    });
  }

  // selección de archivo

  onSelectedFile(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.profileForm.get('profile').setValue(file);
      this.profileForm.get('name').setValue(file.name);
      this.userFileName = file.name;
      this.selectedFile = true;
    }
  }

 // llamadas al service

  onSubmit() {
    if(this.tanque.tieneGraduacion){
      const modalRef = this.modalService.open(ModalConfirmComponent, { centered: true });
      modalRef.componentInstance.mensaje = "El tanque ya tiene graduaciones cargadas, ¿desea sobrescribir las graduaciones existentes?";
      modalRef.result.then(() => {
          this.uploadFile();
        },
        () => { console.log('se canceló') }
      );
    }else{
      this.uploadFile();
    }
  }

  uploadFile(){
    this.cargando = true;
    const formData = new FormData();
    formData.append('name', this.profileForm.get('name').value);
    formData.append('profile', this.profileForm.get('profile').value);
    formData.append('codigoTanque', this.tanque.codigo);

    this.fileUploadService.upload(formData, UrlApi.stockMedicionesTanques).subscribe(
      res => { this.fileUpload = res; this.cargando = false; },
      (err) => { this.error = err; this.cargando = false; }
    );
  }

  //form

    /**
   * Limpiar pantalla
   * @param limpiarMensajes Determina si se limpia el div de mensajes
   */

  reset(limpiarMensajes: boolean = false, limpiarInput: boolean = false) {
    this.cargando = false;
    this.archivoCargado = true;
    if (limpiarInput) {
      this.archivoCargado = false;
      this.userFileName = '';
    }
    if (limpiarMensajes)
      this.fileUpload = { status: '', message: '', filePath: '', length: 0 };

      this.profileForm.get('profile').setValue('');
      this.profileForm.get('name').setValue('');
      this.profileForm.get('inputFile').setValue('');

    this.activeModal.dismiss();
  }

}

