import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UsuarioService } from 'src/app/account/usuario.service';
import { IslaService } from 'src/app/stock/islas.service';


@Component({
  selector: 'app-nueva-isla',
  templateUrl: './nueva-isla.component.html',
  styleUrls: ['./nueva-isla.component.css']
})
export class NuevaIslaComponent implements OnInit {

  isla: string;
  guardando: boolean;
  form: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public activeModal: NgbActiveModal,
    private islaService: IslaService,
    private usuarioService: UsuarioService) {

  }

  ngOnInit() {
    this.crearFormulario();
    this.inicializarComponente();
  }

  crearFormulario(){
    this.form = this.formBuilder.group({
      nombreFormControl: ['', Validators.required],
    });
  }

  inicializarComponente(){
    this.guardando = false;
  }

  onSubmit() {
    this.guardando = true;

    this.isla = this.nombreFormControl.value;

    this.islaService.crearIsla(this.isla, this.usuarioService.getAeroplantaActual().codigoAeroplanta)
    .subscribe(isla => {
      this.guardando = false;
      this.activeModal.close(isla);
    });
  }

  // getters

  get nombreFormControl(){
    return this.form.controls.nombreFormControl;
  }
}
