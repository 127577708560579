import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Expendedora } from '../models/precios/Expendedora';

@Component({
  selector: 'app-lista-expendedoras',
  templateUrl: './lista-expendedoras.component.html',
  styleUrls: ['./lista-expendedoras.component.css']
})
export class ListaExpendedorasComponent implements OnInit {

  [x: string]: any;


  @Input() aeroplantas: Expendedora[];



  busquedaFormControl = new UntypedFormControl();


  cargandoClientes:boolean=true;
  constructor(public activeModal:NgbActiveModal)//, private aeroplantaService:AeroplantasService) { }
  {

  }
  ngOnInit() {
  }

}
