
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClienteService } from 'src/app/cliente/cliente.service';
import { TipoCuenta } from 'src/app/shared/enums/tipoCuenta';
import { CuentaVehiculo } from 'src/app/shared/models/abm/CuentaVehiculo';
import { ClienteEncabezado } from 'src/app/shared/models/cliente/ClienteEncabezado';
import { MessageService } from 'src/app/shell/message.service';
import { AbmMatriculasService } from 'src/app/management/abm-matriculas.service';
import { ModalVehiculoComponent } from '../modal-vehiculo/modal-vehiculo.component';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { VehiculoDTO, TipoVehiculoMatriculas } from 'src/app/shared/models/cliente/VehiculoDTO';
@Component({
  selector: 'app-cliente-detalle',
  templateUrl: './cliente-detalle.component.html',
  styleUrls: ['./cliente-detalle.component.scss']
})
export class ClienteDetalleComponent implements OnInit {

  @Input() cliente: ClienteEncabezado;

  TipoVehiculo = TipoVehiculoMatriculas;

  obteniendoCuentas: boolean = false
  agregandoCuenta: boolean = false
  guardandoCambios: boolean = false
  cuentas: CuentaVehiculo[] = [];
  TipoCuenta = TipoCuenta;

  form: UntypedFormGroup;

  errorInput: boolean = false;
  errorMensage: string = '';

  constructor(
    private modal: NgbModal,    
    private abmMatriculasService: AbmMatriculasService,
    private messageService: MessageService,
    private formBuilder: UntypedFormBuilder,
    private modalService: NgbModal
    ) { }

  ngOnInit(): void {
  }

  ngOnChanges() { 
    this.form = this.formBuilder.group({
      cuentasArrayFormControl: this.formBuilder.array([])
    });

    this.obtenerCuentas();   
  }  

  cargandoDatos(){
    return this.obteniendoCuentas;
  }

  async obtenerCuentas() {
    this.obteniendoCuentas = true;

    try {      
      this.cuentas = await this.abmMatriculasService.getCuentasActicasVehiculos(this.cliente.tipoDocumento, this.cliente.numeroDocumento);
      this.cuentas.forEach( c => {
        c.vehiculos.sort( (a,b) => a.matricula > b.matricula ? 1 : -1); 
        c._local_modificada = false;       
        this.cuentasArrayFormControl.push(this.nuevaCuentaFormItem(c));        
      });
      this.obteniendoCuentas = false;
    } catch (error) {
      this.obteniendoCuentas = false;
      this.messageService.showErrorMessage("Error: " + error.message + " descripcion: " + error.descripcion);
    }    
  }

  nuevaCuentaFormItem(cuenta: CuentaVehiculo): UntypedFormGroup {
    return this.formBuilder.group({
      limitada: [cuenta.limitado],
      limitadaGranel: [cuenta.limitadoGranel],
      matricula: [''],
      ctaSgc: [cuenta.ctA_SGC]
    })
  }

  onCambioLimitada(cuenta: CuentaVehiculo, granel:boolean){
    granel ? cuenta.limitadoGranel = !cuenta.limitadoGranel : cuenta.limitado = !cuenta.limitado;
    cuenta._local_modificada = true;
  }

  seEditoVehiculo(cuenta: CuentaVehiculo) {
    return cuenta?.vehiculos.some(v => v._editado==true);
  }

  onMatriculaAgregada(cuenta: CuentaVehiculo, index: number){
    if(this.cuentasArrayFormControl.value){
      setTimeout(() => {
        this.errorMensage = '';
      }, 5000);

      let matricula = this.cuentasArrayFormControl.controls[index].value.matricula;

      if(matricula == ""){
        this.errorMensage = "Debe ingresar un texto para crear matricula"
        return;
      }else if (cuenta.vehiculos.find( v=> v.matricula==matricula.toUpperCase())) {
        this.errorMensage = "La matricula ingresada ya se encuentra cargada"
        return;
      }

      this.agregandoCuenta = true;

      this.abmMatriculasService.getVehiculoPorMatricula(matricula.toUpperCase())
        .subscribe(v => {
          if(v){
            cuenta.vehiculos.push(v);
            cuenta._local_modificada = true;
            this.cuentasArrayFormControl.controls[index].get("matricula").setValue("");
            this.agregandoCuenta = false;
          }else{
            this.agregandoCuenta = false;
            this.mostrarModalCreacionVehiculo(cuenta, matricula, index);
          }
        },
        e => {
          this.agregandoCuenta = false;
          this.mostrarModalCreacionVehiculo(cuenta, matricula, index);
        });
    }
  }

  mostrarModalCreacionVehiculo(cuenta: CuentaVehiculo, matricula: string, index){
    const modalRef = this.modalService.open(ModalVehiculoComponent, { backdrop: "static", centered: true });
    modalRef.componentInstance.matriculaIngresada = matricula.toUpperCase();
    modalRef.componentInstance.desactivarInput = true; 
    modalRef.result.then((vehiculo) => {
      if(vehiculo) {
        this.messageService.showSuccessMessage("Vehiculo creado");
        cuenta.vehiculos.push(vehiculo);
        cuenta._local_modificada = true;      
        this.cuentasArrayFormControl.controls[index].get("matricula").setValue("");
      }      
    }).finally(() => {
      this.cuentasArrayFormControl.controls[index].get("matricula").setValue("");
    });
  }

  onMatriculaEliminada(cuentaVehiculo: CuentaVehiculo, vehiculo: VehiculoDTO, pos: number){    
    this.cuentas.find(c => c.ctA_SGC == cuentaVehiculo.ctA_SGC).vehiculos = this.cuentas.find(c => c.ctA_SGC == cuentaVehiculo.ctA_SGC).vehiculos.filter(v => v.matricula !== vehiculo.matricula);
    cuentaVehiculo._local_modificada = true;
  }

  onMatriculaEditar(cuenta: CuentaVehiculo, vehiculo: VehiculoDTO, index){     
    const modalRef = this.modalService.open(ModalVehiculoComponent, { backdrop: "static", centered: true });
    modalRef.componentInstance.vehiculoEditar = vehiculo; 
    modalRef.result.then((vehiculo) => {
      if(vehiculo) {
        this.messageService.showSuccessMessage("Vehiculo modificado");        
        cuenta.vehiculos[index]._editado = true;
      }      
    });    
  }

  guardarCambios(cuentaVehiculo: CuentaVehiculo){
    this.guardandoCambios = true;

    this.abmMatriculasService.modificarCuentaVehiculo(cuentaVehiculo)
      .subscribe((cuenta) => {
        this.messageService.showSuccessMessage("Se guardaron los cambios para la cuenta " + cuentaVehiculo.ctA_SGC);
        cuentaVehiculo._local_modificada = false;
        cuentaVehiculo.vehiculos.forEach(v=>v._editado=false);
        this.guardandoCambios = false;
      },
      (err) => {
        this.messageService.showErrorLongMessage(err.description);
        this.guardandoCambios = false;
      });
  }

  get cuentasArrayFormControl(): UntypedFormArray {
    return this.form.controls.cuentasArrayFormControl as UntypedFormArray;
  }

}
