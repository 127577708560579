
  <nav class="navbar navbar-light bg-light navbar-expand-lg mb-3">
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <div class="form-inline my-2 my-lg-0">
        <input id="busquedaFormControl" type="text" class="form-control" (input)="filtrarProductos()"
          placeholder="Búsqueda" [formControl]="busquedaFormControl" placeholder="Codigo/Nombre"/>
      </div>
      <ul class="navbar-nav me-auto">
        <!-- tipos -->
        <li class="nav-item">
          <div ngbDropdown autoClose="outside">
            <button class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2" id="dropdownFormTipos"
              ngbDropdownToggle>Tipos</button>
            <div ngbDropdownMenu aria-labelledby="dropdownFormTipos">
              <div class="px-4 py-3">
                <div *ngFor="let tipo of tiposRubro">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="{{ 'dropdownCheck' + tipo.nombre}}"
                      [(ngModel)]="tipo.seleccionado" (ngModelChange)="tipo.seleccionado = $event"
                      (change)="filtrarProductos()">
                    <label class="form-check-label text-nowrap" for="{{ 'dropdownCheck' + tipo.nombre}}">
                      {{ tipo.nombre}}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li class="nav-item">
          <div ngbDropdown autoClose="outside">
            <button class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2" id="dropdownForm2"
              ngbDropdownToggle>Estados</button>
            <div ngbDropdownMenu aria-labelledby="dropdownForm2">
              <div class="px-4 py-3">
                <div *ngFor="let estado of estados">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="{{ 'dropdownCheck' + estado.nombre}}"
                      [(ngModel)]="estado.seleccionado" (ngModelChange)="estado.seleccionado = $event"
                      (change)="filtrarProductos()">
                    <label class="form-check-label text-nowrap" for="{{ 'dropdownCheck' + estado.nombre}}">
                      {{ estado.nombre}}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li class="nav-item">
          <button *ngIf="authService.hasRole(TipoUsuario.RESPRODUCTO, TipoUsuario.JEFEDEAEROPLANTA)" type="button" class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2"
            (click)="mostrarNuevoProducto(content)"> Agregar</button></li>
            <li class="nav-item">
              <button type="button" class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2"
                (click)="exportarProductos()"> Exportar</button></li>

            <!-- tipos button -->
      </ul>
    </div>
  </nav>

  <div class="col col-md-12">
    <div class="spinner-container" *ngIf="esperandoCarga">
      <ngx-spinner name="spinnerGrafico" bdOpacity=0.3bd
        Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
        <p style="color: white">C a r g a n d o . . . </p>
      </ngx-spinner>
    </div>


  <!-- </form> -->
  <div class="row">
    <div class="col col-3">
      <div class="list-group py-3  menu-izq">
        <div *ngFor="let producto of productosFiltrados; let i=index">
          <button type="button" [class.active]="" class="list-group-item list-group-item-action"
            (click)="mostrarDetalle(i)">

            <div class="d-flex w-100 justify-content-between">
              <p>{{ producto.nombreProducto}} </p>
            </div>
          </button>
        </div>
      </div>
    </div>
    <!-- informacion del producto -->
    <div id="tablaInformacion" class="col-9">
      <div *ngIf="productoSeleccionado!=null">
        <app-informacion [productoSeleccionado]='productoSeleccionado'></app-informacion>
      </div>
      <br>
      <div class="car-body" *ngIf="productoSeleccionado!=null">
        <app-impuestos [productoSeleccionado]='productoSeleccionado'></app-impuestos>
      </div>
    </div>

    <ng-template #content let-modal>
      <app-nuevo-producto></app-nuevo-producto>
    </ng-template>
</div>
