<nav class="navbar navbar-light bg-light navbar-expand-lg">
  <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <form class="form-inline my-2 my-lg-0">
      <input id="busquedaFormControl" type="text" class="form-control" [formControl]="busquedaFormControl"
        (input)="filtrarFacturas()" placeholder="Cliente / fecha" />
    </form>
    <ul class="navbar-nav me-auto">
      <!-- estados -->
      <li class="nav-item">
        <div ngbDropdown autoClose="outside">
          <button class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2" id="dropdownForm1"
            ngbDropdownToggle>Estado</button>
          <div ngbDropdownMenu aria-labelledby="dropdownForm1">
            <div class="px-4 py-3">
              <div *ngFor="let estado of estados">
                <div class="form-check">
                  <input type="checkbox" class="form-check-input" id="{{ 'dropdownCheck' + estado.valor}}"
                    [(ngModel)]="estado.seleccionado" (change)="filtrarFacturas()">
                  <label class="form-check-label text-nowrap" for="{{ 'dropdownCheck' + estado.valor}}">
                    {{ estado.descripcion }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

      </li>

      <!-- desde -->
      <li class="nav-item me-2">
        <div class="input-group">

          <input type="date" [ngModel]="modelDesde" class="fechaFormControlDesdeHasta" placeholder="aaaa-mm-dd"
            (ngModelChange)="modelDesde = $event" name="dt" ngbDatepicker #d="ngbDatepicker" value="modelDesde"
            class="form-control form-control-rounded" #searchDate required readonly>

          <div class="input-group-append">
            <button id="botonDesde" type="button" class="input-group-btn btn btn-secondary" (click)="d.toggle()">
              <i class="fa fa-calendar" aria-hidden="true"></i></button>
          </div>
        </div>
      </li>
      <!-- hasta -->
      <li class="nav-item">
        <div class="input-group">
          <input type="date" class="fechaFormControlDesdeHasta" [ngModel]="modelHasta" placeholder="aaaa-mm-dd"
            name="dp" value="modelHasta" (ngModelChange)="modelHasta = $event" class="form-control form-control-rounded"
            ngbDatepicker #h="ngbDatepicker" #searchDate required readonly>

          <div class="input-group-append">
            <button id="botonHasta" type="button" class="input-group-btn btn btn-secondary" (click)="h.toggle()"><i
                class="fa fa-calendar" aria-hidden="true"></i></button>
          </div>
        </div>
      <li class="nav-item">

        <button (click)="buscarDesdeHasta()" class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2" type="submit"><i
            class="fa fa-search" aria-hidden="true"></i></button>

      </li>
      <!-- <li class="nav-item">

            <button  class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2" type="submit">Descargar</button>

          </li> -->

    </ul>
  </div>
</nav>
<div class="col col-md-12">
  <div class="spinner-container" *ngIf="this.obteniendoHistorial">
    <ngx-spinner name="spinnerGrafico" bdOpacity=0.3bd
      Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
      <p style="color: white">C a r g a n d o . . . </p>
    </ngx-spinner>
  </div>
  <div class="abs-center" *ngIf="this.obteniendoHistorial===false && this.facturasFiltradas.length==0">
    <ngb-alert type="warning"> No se encontraron facturas registradas para el rango de fechas seleccionado.
    </ngb-alert>
  </div>
  <div class="row mt-3">
    <div class="card-body" *ngIf="this.facturasFiltradas.length>0">
      <table class="table table-striped table-hover" [formGroup]="tablaComprobantes">
        <thead class="text-center">
          <td>
            <app-item-multifiltro [lista]="facturasFiltradas" [elementos]="facturaPrefijoMultiFiltro" (elementosChange)="recargarPrefijos($event)" [nombreAtributo]="'factura.prefijo'" [encabezadoColumna]="'Prefijo'"></app-item-multifiltro>
          </td>
          <td>
            <app-item-multifiltro [lista]="facturasFiltradas" [elementos]="facturaNroMultiFiltro" (elementosChange)="recargarNumeros($event)" [nombreAtributo]="'factura.numeroComprobante'" [encabezadoColumna]="'Nro.'"></app-item-multifiltro>
          </td>
          <td>
            <app-item-multifiltro [decoratorFn]="getDescripcionTipo" [lista]="facturasFiltradas" [elementos]="tipoMultiFiltro" (elementosChange)="recargarTipos($event)" [nombreAtributo]="'factura.tipoMovimiento'" [encabezadoColumna]="'Tipo'"></app-item-multifiltro>
          </td>
          <td><b>Fecha/Hora última acción</b></td>
          <td>
            <app-item-multifiltro [lista]="facturasFiltradas" [elementos]="clienteMultiFiltro" (elementosChange)="recargarClientes($event)" [nombreAtributo]="'factura.razonSocialCliente'" [encabezadoColumna]="'Cliente'"></app-item-multifiltro>
          </td>
          <td>
            <app-item-multifiltro [decoratorFn]="obtenerEstadoRefacturacion" [lista]="facturasFiltradas" [elementos]="estadoMultiFiltro" (elementosChange)="recargarEstados($event)" [nombreAtributo]="'estado'" [encabezadoColumna]="'Estado'"></app-item-multifiltro>
          </td>
          <td><b>Importe</b></td>
          <td>
            <app-item-multifiltro [decoratorFn]="obtenerMotivoRefacturacion" [lista]="facturasFiltradas" [elementos]="motivoMultifiltro" (elementosChange)="recargarMotivos($event)" [nombreAtributo]="'motivo'" [encabezadoColumna]="'Motivo'"></app-item-multifiltro>
          </td>
          <td>
            <app-item-multifiltro [lista]="facturasFiltradas" [elementos]="solicitadoMultiFiltro" (elementosChange)="recargarSolicitados($event)" [nombreAtributo]="'usuarioSolicitador'" [encabezadoColumna]="'Solicitante'"></app-item-multifiltro>
          </td>
          <td>
            <app-item-multifiltro [lista]="facturasFiltradas" [elementos]="revisadoMultiFiltro" (elementosChange)="recargarRevisados($event)" [nombreAtributo]="'usuarioRevisador'" [encabezadoColumna]="'Revisador'"></app-item-multifiltro>
          </td>
          <td>
            <app-item-multifiltro [lista]="facturasFiltradas" [elementos]="aprobadoMultiFiltro" (elementosChange)="recargarAprobados($event)" [nombreAtributo]="'usuarioAprobador'" [encabezadoColumna]="'Aprobador'"></app-item-multifiltro>
          </td>
          <td><b>Adjunto</b></td>
          <td><b>Observacion</b></td>
          <td><b>Comprobante</b></td>
        </thead>
        <tbody class="text-center">
          <tr formArrayName="aerovalesFiltrados" *ngFor="let s of this.facturasFiltradosMultifiltro let i = index">
            <td style="text-align: right;">{{ s.factura.prefijo | facturaPrefijo }}</td>
            <td style="text-align: left;">{{ s.factura.numeroComprobante | facturaNumero }}</td>
            <td>{{ s.factura.tipoMovimiento | tipoMovimiento }}</td>
            <td>{{ s.fechaHora | date: "d'/'MM'/'y' 'hh:mm a"}}</td>
            <td>{{ s.factura.razonSocialCliente }}
            </td>
            <td>{{this.obtenerEstadoRefacturacion(s.estado) }}
            </td>
            <td>{{ s.factura.tipoMoneda | tipoMoneda }} {{ s.factura.total | number:'0.2-2'}}</td>
            <td>{{ this.obtenerMotivoRefacturacion(s.motivo) }}</td>
            <td class="texto-chico">{{s.usuarioSolicitador}}</td>
            <td class="texto-chico">{{s.usuarioRevisador}}</td>
            <td class="texto-chico">{{s.usuarioAprobador}}</td>
            <td> <button *ngIf="s.pathAdjunto!=null" class="btn" placement="bottom" ngbTooltip="Descargar Adjunto"
                container="body">
                <a class="fa fa-download" [href]="s.pathAdjunto"></a>
              </button>
              <div *ngIf="s.pathAdjunto===null">--</div>
            </td>
            <td>
              <button *ngIf="s.detalle!=null" (click)="this.verObservaciones(s.detalle)" class="btn" placement="bottom"
                ngbTooltip="Ver Observaciones" container="body">
                <i class="fa fa-eye" aria-hidden="true"></i>
              </button>
              <div *ngIf="s.detalle===null">--</div>
            </td>
            <td>
              <button class="btn" (click)="verComparativasFacturas(content,s, i)" ngbTooltip="Ver comparativas">
                <i *ngIf="!previsualizando || previsualizando && itemSeleccionado!=i" class="fa fa-eye" aria-hidden="true"></i>
                <div *ngIf="previsualizando && itemSeleccionado==i" class="spinner-border text-primary spinner-border-sm" role="status"></div>
              </button> 
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <ng-template #content let-modal style="overflow-y:scroll">
    <div class="row">
      <div class="col-md-6" style="text-align:center">
        <b>Factura original</b>
      </div>
      <div class="col-md-6" style="text-align:center">
        <b>Nuevo Comprobante</b>
      </div>
    </div>
    <div class="row" id="print">
      <app-visor-comprobante [botones]="false" *ngIf="this.facturaObtenida!=null" class="col-md-6"
        [facturaObtenida]='this.facturaObtenida'></app-visor-comprobante>
      <app-previsualizacion-comprobante *ngIf="this.facturaCalculada!=null" class="col-md-6"
        [facturaCalculada]='this.facturaCalculada'></app-previsualizacion-comprobante>
    </div>
    <div class="col-4">
      <button styleSheetFile="assets/css/printRefacturacion.css, assets/css/bootstrap.min.css" printSectionId="print"
        useExistingCss="true" class="btn btn-sm btn-outline-secondary ms-1 mb-1" printTitle="{{tituloFactura}}" ngxPrint>
        <i class="fa fa-print" aria-hidden="true"></i> Imprimir </button>
    </div>
  </ng-template>
</div>