import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ProductoService } from 'src/app/abm/producto/producto.service';
import { TipoVenta } from 'src/app/shared/enums/tipoVenta';
import { NgbCalendar, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { formatDate } from '@angular/common';
import { Precio } from 'src/app/shared/models/abm/Precio';
import { CondicionVenta } from 'src/app/shared/enums/condicionVenta';
import { TipoEstadoPrecio } from 'src/app/shared/enums/tipoEstadoPrecio';
import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { MessageService } from 'src/app/shell/message.service';
import { Moneda } from 'src/app/shared/models/abm/Moneda';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';
import { AeroplantasService } from 'src/app/shared/aeroplantas.service';
import { TipoMoneda } from 'src/app/shared/enums/tipoMoneda';
import { ItemMultifiltroComponent } from 'src/app/shared/multifiltro/item-multifiltro/item-multifiltro.component';
import { ItemMultiFiltro } from 'src/app/shared/multifiltro/ItemMultifiltro';
import { EstadoPrecio } from 'src/app/shared/models/precios/EstadoPrecio';
import { TipoActivoPrecio } from 'src/app/shared/enums/tipoActivoPrecio';
import { PreciosService } from '../../precios.service';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { ModalConfirmComponent } from 'src/app/shared/generic-modals/modal-confirm.component';
import { ModalSubirExcelModificacionPreciosComponent } from '../modal-subir-excel-modificacion-precios/modal-subir-excel-modificacion-precios.component';
import { TipoCantidadPaginado } from 'src/app/shared/enums/TipoCantidadPaginado';

@Component({
  selector: 'app-actualizacion-precios',
  templateUrl: './actualizacion-precios.component.html',
  styleUrls: ['./actualizacion-precios.component.scss']
})
export class ActualizacionPreciosComponent implements OnInit {

  TipoUsuario = TipoUsuario;

  constructor(private productoService: ProductoService, private modalService: NgbModal,
    private messageService: MessageService,
    private http: HttpClient,
    private calendar: NgbCalendar,
    public authService: AuthService,
    private spinner: NgxSpinnerService,
    public aeroplantaService: AeroplantasService,
    private preciosService: PreciosService,
    private cdRef:ChangeDetectorRef) { }

  TEXTO_SIN_ELEMENTO = "-";

  tiposVentaMultiFiltro: ItemMultiFiltro[] = [];
  aeroplantasMultiFiltro: ItemMultiFiltro[] = [];
  lotesMultiFiltro: ItemMultiFiltro[] = [];
  codigosMultiFiltro: ItemMultiFiltro[] = [];
  productosMultiFiltro: ItemMultiFiltro[] = [];
  monedasMultiFiltro: ItemMultiFiltro[] = [];
  clientesMultiFiltro: ItemMultiFiltro[] = [];
  condicionesVentaMultiFiltro: ItemMultiFiltro[] = [];
  entregasMultiFiltro: ItemMultiFiltro[] = [];
  areasMultiFiltro: ItemMultiFiltro[] = [];
  segmentosMultiFiltro: ItemMultiFiltro[] = [];

  vigencias = [
    { codigo: 0, nombre: "Vigentes", seleccionado: true },
    { codigo: 1, nombre: "Vencidos", seleccionado: false },
    { codigo: 2, nombre: "Futuros", seleccionado: false }
  ];
  estados = [
    { codigo: 1, nombre: "Pendiente", seleccionado: false },
    { codigo: 2, nombre: "Aprobado", seleccionado: true },
    { codigo: 3, nombre: "Rechazado", seleccionado: false },
  ];

  niveles: any[] = [
    { id: "1", nombre: "1 - Producto/Vigencia/Moneda/Aeroplanta/Area de Negocio/Segmento/Cliente/Tipo operación/Direccion de entrega", seleccionado: true },
    { id: "2", nombre: "2 - Producto/Vigencia/Moneda/Aeroplanta/Area de Negocio/Segmento/Cliente/Tipo operación", seleccionado: false },
    { id: "3", nombre: "3 - Producto/Vigencia/Moneda/Aeroplanta/Area de Negocio/Segmento/Cliente", seleccionado: false },
    { id: "4", nombre: "4 - Producto/Vigencia/Moneda/Aeroplanta/Area de Negocio/Segmento", seleccionado: false },
    { id: "5", nombre: "5 - Producto/Vigencia/Moneda/Aeroplanta/Area de Negocio", seleccionado: false },
    { id: "6", nombre: "6 - Producto/Vigencia/Moneda/Aeroplanta", seleccionado: false },
  ];


  TipoActivoPrecio = TipoActivoPrecio;
  enumCondicionVenta = CondicionVenta;
  enumEstadoPrecio = TipoEstadoPrecio;
  errorEnExportacion: boolean = false;

  tipoVenta: TipoVenta;
  moneda: Moneda;
  preciosFiltradosMultifiltro: Precio[];
  preciosFiltrados: Precio[];
  listaPrecios: Precio[];
  esperandoCarga: boolean = false;
  messagePrecios: string = "C a r g a n d o . . ."
  procesandoFiltros:boolean = false;
  actualizandoPrecios: boolean = false;
  paginado: boolean = false;
  
  modelFecha: NgbDateStruct;
  modelHora: any;

  cantPrecios: number = 0;

  page = 1;
  pageSize = TipoCantidadPaginado.VERY_BIG;
  collectionSize = 0;

  //hooks

  ngOnInit() {
    this.modelFecha = this.calendar.getToday();
    const horaActual = new Date();
    this.modelHora = { hour: horaActual.getHours(), minute: horaActual.getMinutes(), second: horaActual.getSeconds() }

    this.getPreciosPage(this.page, this.pageSize); 
  }

  filtrarPreciosMulti() {
    if (this.preciosFiltrados != null) {

      this.procesandoFiltros = true;

      this.preciosFiltradosMultifiltro = this.preciosFiltrados.filter(p => {
        let mostrar = true;

        if (this.tiposVentaMultiFiltro.some(elemento => elemento.seleccionado)) {
          mostrar = mostrar && (
            this.tiposVentaMultiFiltro.filter(elemento => elemento.seleccionado).some(elementoAux => Number(elementoAux.id) == p.tipoVenta)
          );
        }

        if (this.aeroplantasMultiFiltro.some(elemento => elemento.seleccionado)) {
          mostrar = mostrar && (
            this.aeroplantasMultiFiltro.filter(elemento => elemento.seleccionado).some(elementoAux => elementoAux.id == p.codigoAeroplanta)
          );
        }

        if (this.lotesMultiFiltro.some(elemento => elemento.seleccionado)) {
          mostrar = mostrar && (
            this.lotesMultiFiltro.filter(elemento => elemento.seleccionado).some(elementoAux => elementoAux.id == p.numeroLote)
          );
        }

        if (this.codigosMultiFiltro.some(elemento => elemento.seleccionado)) {
          mostrar = mostrar && (
            this.codigosMultiFiltro.filter(elemento => elemento.seleccionado).some(elementoAux => elementoAux.id == p.producto.codigoProducto)
          );
        }

        if (this.productosMultiFiltro.some(elemento => elemento.seleccionado)) {
          mostrar = mostrar && (
            this.productosMultiFiltro.filter(elemento => elemento.seleccionado).some(elementoAux => elementoAux.id == p.producto.nombreProducto)
          );
        }

        if (this.monedasMultiFiltro.some(elemento => elemento.seleccionado)) {
          mostrar = mostrar && (
            this.monedasMultiFiltro.filter(elemento => elemento.seleccionado).some(elementoAux => elementoAux.id == p.moneda.nombre)
          );
        }

        if (this.clientesMultiFiltro.some(elemento => elemento.seleccionado)) {
          mostrar = mostrar && (
            this.clientesMultiFiltro.filter(elemento => elemento.seleccionado).some(elementoAux => p.cliente != null ? elementoAux.id == p.cliente.razonSocial : elementoAux.id == ItemMultifiltroComponent.SIMBOLO_VALOR_NULO)
          );
        }

        if (this.condicionesVentaMultiFiltro.some(elemento => elemento.seleccionado)) {
          mostrar = mostrar && (
            this.condicionesVentaMultiFiltro.filter(elemento => elemento.seleccionado).some(elementoAux => Number(elementoAux.id) == p.condicionVenta)
          );
        }

        if (this.entregasMultiFiltro.some(elemento => elemento.seleccionado)) {
          mostrar = mostrar && (
            this.entregasMultiFiltro.filter(elemento => elemento.seleccionado).some(elementoAux => elementoAux.id == (p.direccionEntrega != null ? p.direccionEntrega : ItemMultifiltroComponent.SIMBOLO_VALOR_NULO))
          );
        }

        if (this.areasMultiFiltro.some(elemento => elemento.seleccionado)) {
          mostrar = mostrar && (
            this.areasMultiFiltro.filter(elemento => elemento.seleccionado).some(elementoAux => elementoAux.id == (p.areaNegocio != null ? p.areaNegocio.nombreArea : ItemMultifiltroComponent.SIMBOLO_VALOR_NULO))
          );
        }

        if (this.segmentosMultiFiltro.some(elemento => elemento.seleccionado)) {
          mostrar = mostrar && (
            this.segmentosMultiFiltro.filter(elemento => elemento.seleccionado).some(elementoAux => elementoAux.id == (p.segmento != null ? p.segmento.nombreSegmento : ItemMultifiltroComponent.SIMBOLO_VALOR_NULO))
          );
        }

        return mostrar;

      });

      this.procesandoFiltros = false;
      var a = this.preciosFiltradosMultifiltro;
    }
  }

  cargarListasFiltrosMulti(listaPrecios: Precio[]) {
    this.filtrarPreciosMulti();
  }

  getDescripcionTipoVenta(tipoVenta: TipoVenta) {
    if (tipoVenta == TipoVenta.cabotaje) {
      return "Cabotaje";
    } else if (tipoVenta == TipoVenta.internacional) {
      return "Internacional";
    } else {
      return "Sin tipo venta.";
    }
  }

  getDescripcionCondicionVenta(condicionVenta: CondicionVenta) {
    if (condicionVenta == CondicionVenta.contado) {
      return "CONTADO";
    } else if (condicionVenta == CondicionVenta.cuentaCorriente) {
      return "CTA CTE";
    } else {
      return "Sin condición venta.";
    }
  }

  getDescripcionEntregas(entrega: number) {
    return entrega;
    /*
    if(entrega == CondicionVenta.contado){
      return "CONTADO";
    }else if(entrega == CondicionVenta.cuentaCorriente){
      return "CTA CTE";
    }else{
      return "Indeterminado";
    }
    */
  }

  getPreciosPage(page: number, size: number, actualizacion?: boolean): void {
    this.cantPrecios = 0;
    (actualizacion) ? this.actualizandoPrecios = true : this.esperandoCarga = true;    
    this.spinner.show('spinnerGrafico');    

    let nivelesFiltrados = [];
    this.niveles.forEach(n => n.seleccionado? nivelesFiltrados.push(Number(n.id)) : null);
    let estadosFiltrados = [];
    this.estados.forEach(e => e.seleccionado? estadosFiltrados.push(e.codigo) : null);
    let vigenciasFiltradas = [];
    this.vigencias.forEach(v => v.seleccionado? vigenciasFiltradas.push(v.codigo) : null);

    this.productoService.getPreciosPaginados(page, size, nivelesFiltrados, estadosFiltrados, vigenciasFiltradas, this.fechaVigencia)
      .subscribe(p=>{
        this.cantPrecios = p.count;
        p.items.forEach(p=>{
          if (p.aprobadoUserName && p.aprobadoUserName.indexOf(',') > -1) p.aprobadoUserName = JSON.parse(p.aprobadoUserName.replace(/'/g, '"')).nombresCompletos;

          if (p.importoUserName && p.importoUserName.indexOf(',') > -1) p.importoUserName = JSON.parse(p.importoUserName.replace(/'/g, '"')).nombresCompletos;
        });

        this.preciosFiltrados = p.items;
        this.listaPrecios = p.items;
        this.page = p.pageNumber;
        this.pageSize = p.pageSize;
        this.collectionSize = p.count;

        this.paginado = p.totalItemCount >= 10000 || p.hasPreviousPage ? true : false;

        this.spinner.hide('spinnerGrafico');
        (actualizacion) ? this.actualizandoPrecios = false : this.esperandoCarga = false;
        this.cargarListasFiltrosMulti(this.listaPrecios);
      }, ()=>{
        this.spinner.hide('spinnerGrafico');
        (actualizacion) ? this.actualizandoPrecios = false : this.esperandoCarga = false;
        this.messageService.showErrorMessage("Error al obtener los precios en vigencia.")
      }); 
  }

  public onPageChange = (pageNumber) => {
    this.actualizandoPrecios = true;
    this.preciosFiltrados = null;
    this.listaPrecios = null;    
    this.getPreciosPage(pageNumber, this.pageSize, true);
  }

  // form

  keysTipoVenta() {
    var keys = Object.keys(this.tipoVenta);
    keys.shift();
    return keys.slice(keys.length / 2);
  }

  onRefreshClick() {
    this.preciosFiltrados = null;
    this.listaPrecios = null;    
    this.preciosFiltradosMultifiltro = [];
    this.getPreciosPage(1, this.pageSize);
  }

  recargarForm() {
    this.ngOnInit();
  }

  obtenerListaIdsFiltrados() {
    return this.preciosFiltrados.map(p => p.id);
  }

  seleccionDeNivelesValida(event){    
    
    this.cdRef.detectChanges();
  
    if ( ! this.niveles.some(e => e.seleccionado) ) { // Si no quedo ningun nivel seleccionado
      // Vuelvo a seleccionar el tildado. Selecion invalida.
      event.seleccionado = true;
      return false;
    }

    if( this.niveles.filter(e => e.seleccionado).length > 1){ // Si hay mas de un nivel seleccionado
      // Dejo seleccionado el tildado y destildo el anterior. Seleccion valida.
      this.niveles.forEach(n => n.seleccionado = false);
      event.seleccionado = true;
      return true;
    }

    return true; // No deberia llegar aca
  }

  formatDate(year, month, day, hour, minute): string {
    return year + '-' + month.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + '-' + day.toLocaleString(undefined, { minimumIntegerDigits: 2 })
      + 'T' + hour.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + minute.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':00';
  }

  obtenerDescripcionMoneda(moneda: Moneda){
    if(moneda.tipoMoneda == TipoMoneda.PES){
      return "ARP";      
    }else{
      return moneda.nombre;
    }
  }

  recargarLotes(items: ItemMultiFiltro[]){
    this.lotesMultiFiltro = items;
    this.filtrarPreciosMulti();
  }

  recargarCodigos(items: ItemMultiFiltro[]){
    this.codigosMultiFiltro = items;
    this.filtrarPreciosMulti();
  }

  recargarProductos(items: ItemMultiFiltro[]){
    this.productosMultiFiltro = items;
    this.filtrarPreciosMulti();
  }

  recargarMonedas(items: ItemMultiFiltro[]){
    this.monedasMultiFiltro = items;
    this.filtrarPreciosMulti();
  }

  recargarClientes(items: ItemMultiFiltro[]){
    this.clientesMultiFiltro = items;
    this.filtrarPreciosMulti();
  }

  recargarTiposVenta(items: ItemMultiFiltro[]){
    this.tiposVentaMultiFiltro = items;
    this.filtrarPreciosMulti();
  }

  recargarEntregas(items: ItemMultiFiltro[]){
    this.entregasMultiFiltro = items;
    this.filtrarPreciosMulti();
  }

  recargarAreas(items: ItemMultiFiltro[]){
    this.areasMultiFiltro = items;
    this.filtrarPreciosMulti();
  }

  recargarSegmentos(items: ItemMultiFiltro[]){
    this.segmentosMultiFiltro = items;
    this.filtrarPreciosMulti();
  }

  recargarAeroplantas(items: ItemMultiFiltro[]){
    this.aeroplantasMultiFiltro = items;
    this.filtrarPreciosMulti();
  }

  hasRole(...roles: TipoUsuario[]) {
    return this.authService.hasRole(...roles);
  }

  descargarExcel() {
    this.errorEnExportacion = false;

    var listaIds = this.preciosFiltradosMultifiltro.map(p => p.id);

    if (listaIds.length == 0) {
      this.messageService.showErrorMessage("No hay precios filtrados para descargar.");
      return;
    }

    this.productoService.descargarExcelActualizacionPrecios(listaIds)
      .subscribe((res: Blob) => {
        const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
        saveAs(blob, "ListadoDePrecios");
      }, () => {
        this.errorEnExportacion = true;
      });
  }

  importarPrecios(){
    const modalRef = this.modalService.open(ModalSubirExcelModificacionPreciosComponent, { centered: true, size: "lg", backdrop: "static" }); 
    
    modalRef.result.then(result => {
      if (result == true) {
        this.ngOnInit();
      }
    });
  }

  getDescripcionAeroplantas(codigoAeroplanta: string) {
    try{
      return this.aeroplantaService.obtenerDescripcionAeroplantaPrecios(codigoAeroplanta);
    }catch(Ex){
      return ".";
    }    
  }

  get fechaVigencia() {
    return this.formatDate(this.modelFecha.year, this.modelFecha.month, this.modelFecha.day, this.modelHora.hour, this.modelHora.minute);
  }
}