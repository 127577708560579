import { HttpClient, HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Guid } from 'guid-typescript';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UsuarioService } from '../account/usuario.service';
import { HandleError, HttpErrorHandler } from '../http-error-handler.service';
import { TipoOperacionExcelPrecios } from '../shared/enums/tipoOperacionExcelPrecios';
import { UrlApi } from '../shared/enums/urlApi';
import { Precio } from '../shared/models/abm/Precio';
import { Usuario } from '../shared/models/despacho/Usuario';
import { BonificacionRecargo } from '../shared/models/precios/BonificacionyRecargo';

import { EstadoPrecio } from '../shared/models/precios/EstadoPrecio';
import { Lote } from '../shared/models/precios/Lote';
import { NivelAprobacion } from '../shared/models/precios/NivelAprobacion';
import { NivelDelegado } from '../shared/models/precios/NivelDelegado';
import { PrecioConsulta } from '../shared/models/precios/PrecioConsulta';
import { PrecioLote } from '../shared/models/precios/PrecioLote';
import { UsuarioNivelAprobacion } from '../shared/models/precios/UsuarioNivelAprobacion';
import { UsuarioNiveles } from '../shared/models/precios/UsuarioNiveles';
import {Precio as PrecioDto} from '../shared/models/precios/Precio';
import { ActualizarFecha } from '../shared/models/precios/ActualizarFecha';
import { Aeroplanta } from '../shared/models/despacho/Aeroplanta';
import { ModificacionDto } from '../shared/models/precios/ModificacionDto';
import { TipoEstadoPrecio } from '../shared/enums/tipoEstadoPrecio';
import { TipoPrecioRetroactivo } from '../shared/enums/TipoPrecioRetroactivo';
import { PrecioExcel } from '../shared/models/precios/PrecioExcel';
import { PagedPrecioLote } from '../shared/models/precios/PagedPrecioLote';
import { PagedByr } from '../shared/models/precios/PagedByr';
import { PrecioDesactivacion } from '../shared/models/abm/PrecioDesactivacion';
import { PrecioInactivo } from '../shared/models/precios/PrecioInactivo';
import { MultiFiltroPrecioByrDto } from '../shared/models/precios/MultiFiltroPrecioByrDto';
import { LoteBonificacionRecargo } from '../shared/models/precios/LoteBonificacionRecargo';

@Injectable({
  providedIn: 'root'
})
export class PreciosService {

  usuariosBaseUrl = environment.apiServer.usuariosBaseUrl;
  productosBaseUrl = environment.apiServer.productosUrl;
  private aeroplantasUrl = environment.apiServer.baseUrl + '/AeroplantasV2/'

  private usuariosUrl = this.usuariosBaseUrl + "/UsuariosV1";
  private nivelesUrl = this.usuariosBaseUrl + "/NivelesV1";

  private preciosUrl = this.productosBaseUrl + "/preciosV2";
  private bonifyRecargoUrl = this.productosBaseUrl + "/BonificacionesRecargosV1";
  private modificacionesUrl = this.productosBaseUrl + "/ModificacionesV1";
  private preciosV3Url = this.productosBaseUrl + "/preciosV3";
  private bonifyRecargoV2Url = this.productosBaseUrl + "/BonificacionesRecargosV2";

  private handleError: HandleError;

  private nivelesUsuario : UsuarioNiveles;
  private aeroplantas : Aeroplanta[];

  constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler, private usuarioService: UsuarioService ) {
     this.handleError = httpErrorHandler.createHandleError('Error');
  }

  obtenerTodasLasAeroplantas(): Observable<Aeroplanta[]> {
    if (this.aeroplantas)
      return of(this.aeroplantas);
    return this.http.get<Aeroplanta[]>(this.aeroplantasUrl).pipe(
      tap((aeroplantas) => {
        this.aeroplantas = aeroplantas;
      }));
  }

  obtenerTodasLasAeroplantasPromise(): Promise<Aeroplanta[]> {
    return this.http.get<Aeroplanta[]>(this.aeroplantasUrl).toPromise();
  }

  getNivelesAprobacion(tipoNivelAprobacion): Observable<NivelAprobacion[]> {
    return this.http.get<NivelAprobacion[]>(this.nivelesUrl + "/NivelesAprobacion?tipoNivel=" + tipoNivelAprobacion)
      .pipe(
        tap(() => {
        },
          catchError(this.handleError('Obtener Aerovales'))
        ));
  }

  crearNivelAprobacion(nivelAprobacion : NivelAprobacion): Observable<NivelAprobacion>{
    return this.http.post<NivelAprobacion>(this.nivelesUrl + '/nivelAprobacion', nivelAprobacion)
    .pipe(
      tap(_ => console.log('Creacion de nivel aprobacion')),
      catchError(this.handleError('Al crear  nivel aprobacion', null))
      );
  }

  editarNivelAprobacion(nivelAprobacion : NivelAprobacion): Observable<NivelAprobacion>{
    return this.http.put<NivelAprobacion>(this.nivelesUrl + '/nivelAprobacion', nivelAprobacion)
    .pipe(
      tap(_ => console.log('Edición de nivel aprobacion')),
      catchError(this.handleError('Al editar nivel aprobacion', null))
      );
  }

  getUsuariosPorRol(rolUsuarios): Observable<Usuario[]> {
    return this.http.get<Usuario[]>(this.usuariosUrl + "?rol=" + rolUsuarios)
      .pipe(
        tap(() => {
        },
          catchError(this.handleError('Obtener usuarios'))
        ));
  }

  getUsuariosPorNivel(tipoNivel): Observable<Usuario[]> {
    return this.http.get<Usuario[]>(this.usuariosUrl + "/usuariosAprobacion?tipoNivel="+tipoNivel)
      .pipe(
        tap(() => {
        },
          catchError(this.handleError('Obtener usuarios'))
        ));
  }

  getLotes(enumRetroactivos: number): Observable<Lote[]> {
    console.log(this.preciosUrl + "/Lotes/PreciosSinAprobar?retroactivos="+enumRetroactivos);
    return this.http.get<Lote[]>(this.preciosUrl + "/Lotes/PreciosSinAprobar?retroactivos="+enumRetroactivos)
      .pipe(
        tap(() => {
        },
          catchError(this.handleError('Obtener lotes'))
        ));
  }

  getPreciosPorLotePaginados(page : number, pageSize : number, loteId): Observable<PagedPrecioLote> {
      return this.http.get<PagedPrecioLote>(this.preciosUrl + "/Lotes/"+loteId+"/Precios", {
        params: {
          page: page.toString(),
          pageSize: pageSize.toString()
        }});
  }

  getPreciosPorLote(loteId): Observable<PrecioLote[]> {
    return this.http.get<PrecioLote[]>(this.preciosUrl + "/Lotes/"+loteId+"/Precios")
      .pipe(
        tap(() => {
        },
          catchError(this.handleError('Obtener precios por lote'))
        ));
  }

  asignarNivelNativoUsuario(id, nivel: NivelAprobacion){
    return this.http.post<NivelAprobacion>(this.usuariosUrl + '/' + id + '/nivelNativo', nivel)
    .pipe(
      tap(_ => console.log('Asignacion de nivel a usuario')),
      catchError(this.handleError('Al asignar nivel a usuario', null))
      );
  }

  asignarNivelDelegadoUsuario(id, nivelDelegado: NivelDelegado){
    return this.http.post<NivelAprobacion>(this.usuariosUrl + '/' + id + '/nivelDelegado', nivelDelegado)
    .pipe(
      tap(_ => console.log('Asignacion de nivel delegado a usuario')),
      catchError(this.handleError('Al asignar nivel delegado a usuario', null))
      );
  }

  eliminarLote(idLote): Observable<boolean> {
    return this.http.delete<boolean>(this.preciosUrl + '/lotes/' + idLote)
      .pipe(
        tap(_ => console.log(`eliminando lote id=${idLote}`)),
        catchError(this.handleError('Al eliminar lote', idLote))
      );
  }

  cambiarEstadoPrecio(estado: EstadoPrecio, listaIds : String[]){
    let input = new FormData();
    input.append('estado', JSON.stringify(estado))
    input.append('idPrecios', JSON.stringify(listaIds));

    return this.http.put<NivelAprobacion>(this.preciosUrl + '/cambiarEstado', input)
    .pipe(
      tap(_ => console.log('Cambiando estado precio')),
      catchError(this.handleError('Al cambiar estado de precio', null))
      );
  }

  cambiarEstadoLote(estado: EstadoPrecio, idLote : string){
    return this.http.put(this.preciosV3Url + '/cambiarEstado/' + idLote, estado)
    .pipe(
      tap(_ => console.log('Cambiando estado precio')),
      catchError(this.handleError('Al cambiar estado de precio', null))
      );
  }

  cambiarEstadoModificacion(estado: EstadoPrecio, listaIds : String[]){
    let input = new FormData();
    input.append('estado', JSON.stringify(estado))
    input.append('idModificaciones', JSON.stringify(listaIds));

    return this.http.put<NivelAprobacion>(this.modificacionesUrl + '/cambiarEstado', input)
    .pipe(
      tap(_ => console.log('Cambiando estado modificacion')),
      catchError(this.handleError('Al cambiar estado de modificacion', null))
      );
  }
/*  
  getPreciosVigentes(): Observable<PrecioLote[]> {
    return this.http.get<PrecioLote[]>(this.preciosUrl + "/vigentes")
      .pipe(
        tap(() => {
        },
          catchError(this.handleError('Obtener precios vigentes'))
        ));
  }
*/

  getPreciosAprobadosVigentes(): Observable<PrecioDesactivacion[]> {
    return this.http.get<PrecioDesactivacion[]>(this.preciosV3Url + "/Vigentes")
  }

  getPreciosInactivos(fechaDesde, fechaHasta): Observable<PrecioInactivo[]> {
    return this.http.get<PrecioInactivo[]>(this.preciosV3Url + "/HistorialDesactivacion?fechaDesde=" + fechaDesde + "&fechaHasta=" + fechaHasta)
  }

  getPreciosModificacionPendiente(enumRetroactivos: number): Observable<ModificacionDto[]> {
    return this.http.get<ModificacionDto[]>(this.modificacionesUrl + "?estado=" + TipoEstadoPrecio.Indeterminado + "&retroactivos=" + enumRetroactivos)
      .pipe(
        tap(() => {
        },
          catchError(this.handleError('Obtener precios pendiente modificacion'))
        ));
  }

  getNivelesUsuario(username:string){
    return this.http.get<UsuarioNiveles>(this.usuariosUrl + "/" + encodeURIComponent(username) + "/Niveles")
      .pipe(
        tap(niveles => { this.nivelesUsuario = niveles; } ,
        catchError(this.handleError('Obtener niveles de usuario.'))
        ));
  }

  getNivelUsuarioLogueado(){
    return this.nivelesUsuario;
  }

  obtenerStringResponsable(){
    return '{"username": "'+this.usuarioService.getUsername()+'" ,' + '"nombresCompletos": "'+this.usuarioService.getNombresCompletosUsuario()+'" ,' + '"dni": "'+this.usuarioService.getDniUsuario()+'" ,' + '"codigoUsuario": "'+this.usuarioService.getIdUsuario()+'"}';
  }

  obtenerStringResponsableV2(){
    return '{"Email": "'+this.usuarioService.getEmailUsuario()+'" ,' + '"nombresCompletos": "'+this.usuarioService.getNombresCompletosUsuario()+'" ,' + '"dni": "'+this.usuarioService.getDniUsuario()+'" ,' + '"Id": "'+this.usuarioService.getIdUsuario()+'"}';
  }

  eliminarUsuarioNivel(delegado: string, usuario: string, nativo: string): Observable<any>{
    return this.http.delete(this.usuariosUrl + '/Nivel?idDelegado=' + delegado + '&idUsuario=' + usuario + '&idNativo=' + nativo, {responseType: 'text'})
    .pipe(
      tap(() => {
      },
        catchError(this.handleError('Eliminar usuario.'))
      ));
  }

  upload(formData) {
    return this.http.post<any>(this.productosBaseUrl + '/preciosV2/ExcelArchivo', formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map(event => this.getEventMessage(event, formData)),
      //catchError(this.handleError('al cargar ' + resultado))

    );
  }

  uploadV2(loteGuid, estado) {
    return this.http.post<any>(this.productosBaseUrl + '/preciosV3/cargarPrecio/' + loteGuid + '?estadoPrecioCargado=' + estado, null);
  }
  
  uploadConsulta(formData) {
    return this.http.post<PrecioConsulta[]>(this.productosBaseUrl + '/preciosV2/ExcelArchivo/Consulta', formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map(event => this.getEventMessage(event, formData)),
      //catchError(this.handleError('al cargar ' + resultado))

    );
  }

  consultaExportar(formData) {
    return this.http.post(this.productosBaseUrl + '/preciosV2/ExcelArchivo/ConsultaExportar', formData, {responseType: 'blob' as 'json'}
    ).pipe(
      //map(event => this.getEventMessage(event, formData)),
      //catchError(this.handleError('al cargar ' + resultado))
    );
  }

  consultaExportarV2(guidLote: Guid) {
    return this.http.post(this.productosBaseUrl + '/preciosV3/ExcelArchivo/ConsultaExportar?idLote=' + guidLote, null, {responseType: 'blob' as 'json'});
  }
  

  uploadByr(formData) {
    return this.http.post<any[]>(this.productosBaseUrl + '/BonificacionesRecargosV1/ExcelArchivo', formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map(event => this.getEventMessage(event, formData)),
      //catchError(this.handleError('al cargar ' + resultado))

    );
  }

  private getEventMessage(event: HttpEvent<any>, formData) {

    switch (event.type) {

      case HttpEventType.UploadProgress:
        return this.fileUploadProgress(event);

      case HttpEventType.Response:
        return this.apiResponse(event);

     }
  }

  private fileUploadProgress(event) {
    const percentDone = Math.round(100 * event.loaded / event.total);
    return { status: 'progress', message: percentDone };
  }

  private apiResponse(event) {
    console.log('apiresponse:' + event.body);
    return event.body;
  }

  getBonificacionesyRecargos(page : number, pageSize : number, estados: number[], codigosAeroplanta: string[], codigosProducto: string[], vigencias: number[], fechaVigencia: string, loteId: string): Observable<PagedByr>{
    var filtro = new MultiFiltroPrecioByrDto();
    filtro.codigosAeroplantas = codigosAeroplanta;
    filtro.codigosProductos = codigosProducto;
    filtro.estados = estados;
    filtro.vigencias = vigencias;
    filtro.fechaVigencia = fechaVigencia;
    filtro.loteId = loteId;
    
    return this.http.put<PagedByr>(this.productosBaseUrl + '/BonificacionesRecargosV2?page=' + page + '&pageSize=' + pageSize, filtro);
  }

  getLotesBonificacionesRecargo(estado: number){
    return this.http.get<LoteBonificacionRecargo[]>(this.bonifyRecargoV2Url + "/lotes?estadoLote="+estado)
      .pipe(
        tap(() => {
        },
          catchError(this.handleError('Obtener lotes byr'))
        ));
  }

  getBonificacionesRecargoPorLotePaginados(page : number, pageSize : number, loteId: string){
    return this.getBonificacionesyRecargos(page, pageSize, null, null, null, null, null, loteId);
  }

  getBonificacionesyRecargosV1(estado: number): Observable<BonificacionRecargo[]>{
    var parametro = "";
    if(estado != null) parametro = "?estado=" + estado;
    return this.http.get<BonificacionRecargo[]>(this.productosBaseUrl + '/BonificacionesRecargosV1' + parametro)
    .pipe(
      tap(() => {
      },
        catchError(this.handleError('Obtener bonificaciones y recargo.'))
      ));
  }

  postBonificacionesyRecargos(bonificacion: BonificacionRecargo): Observable<BonificacionRecargo[]>{
    return this.http.post<BonificacionRecargo[]>(this.productosBaseUrl +  '/BonificacionesRecargosV1',bonificacion)
    .pipe(
      tap(() => {
      },
        catchError(this.handleError('Obtener bonificaciones y recargo.'))
      ));
  }

  putBonificacionesyRecargos(bonificacion: BonificacionRecargo): Observable<BonificacionRecargo[]>{
    return this.http.put<BonificacionRecargo[]>(this.productosBaseUrl +  '/BonificacionesRecargosV1',bonificacion)
    .pipe(
      tap(() => {
      },
        catchError(this.handleError('Obtener bonificaciones y recargo.'))
      ));
  }

  cambiarEstadoByR(estado: EstadoPrecio, listaIds : String[]){
    let input = new FormData();
    input.append('estado', JSON.stringify(estado))
    input.append('idByRs', JSON.stringify(listaIds));

    return this.http.put<NivelAprobacion>(this.bonifyRecargoUrl + '/cambiarEstado', input)
    .pipe(
      tap(_ => console.log('Cambiando estado ByR')),
      catchError(this.handleError('Al cambiar estado de ByR', null))
      );
  }

  // /BonificacionesRecargosV1/cambiarEstado
  // Se manda por querystring el loteId y por body el estado.
  cambiarEstadoLoteByR(estado: EstadoPrecio, loteId : String){
    return this.http.put(this.bonifyRecargoV2Url + '/cambiarEstado?loteId=' + loteId, estado)
    .pipe(
      tap(_ => console.log('Cambiando estado lote ByR')),
      catchError(this.handleError('Al cambiar estado de lote ByR', null))
      );
  }

  actualizarFechaDesdePrecio(precioId, dto: ActualizarFecha){
    return this.http.put<PrecioDto>(this.preciosUrl + '/'+precioId, dto)
    .pipe(
      tap(_ => console.log('Cambiando fecha desde')),
      catchError(this.handleError('Al cambiar fechaDesde', null))
    );
  }

  actualizarPreciosPorId(precioId: Guid, dto: ActualizarFecha ){
    return this.http.put<Precio>(this.preciosUrl + '/'+precioId,dto )
    .pipe(
      tap(_ => console.log('Actualizando precio'))
      );
  }

  subirPrecioLista(precios: PrecioExcel[]){
    return this.http.post<PrecioConsulta[]>(this.preciosV3Url + '/cargarPrecio', precios);
  }

  obtenerCantidadPreciosRetroactivosPorLote(loteId, fecha){
    return this.http.get<number>(this.preciosV3Url + "/Lote/RetroactivosPendientes?loteId=" + loteId + "&fechaHora=" + fecha);
  }

  
}
