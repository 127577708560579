<ul class="nav nav-tabs justify-content-start">
  <li *ngIf="hasRole(TipoUsuario.SUPERVISOR, TipoUsuario.OPERADOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.COMERCIAL, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.SOPORTE, TipoUsuario.APROBADORBYR)" class="nav-item">
    <a class="nav-link" routerLink="pedidos" routerLinkActive="active">Pedidos</a>
  </li>
  <li *ngIf="hasRole(TipoUsuario.SUPERVISOR, TipoUsuario.OPERADOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.COMERCIAL, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO,TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.FACTURACION, TipoUsuario.APROBADORBYR, TipoUsuario.REFACTURACION, TipoUsuario.CONSAEROVALES, TipoUsuario.CONSPRECIOS, TipoUsuario.FINANZAS, TipoUsuario.SOPORTE)"
    class="nav-item"><a class="nav-link" routerLink="aerovales" routerLinkActive="active">Aerovales</a>
  </li>
  <li *ngIf="hasRole(TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.COMERCIAL, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO,TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.SOPORTE, TipoUsuario.APROBADORBYR)"
    class="nav-item"><a class="nav-link" routerLink="alijes" routerLinkActive="active">Alijes</a>
  </li>
  <li *ngIf="hasRole(TipoUsuario.SUPERVISOR, TipoUsuario.OPERADOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.COMERCIAL, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO,TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.FACTURACION, TipoUsuario.REFACTURACION, TipoUsuario.CONSAEROVALES, TipoUsuario.CONSPRECIOS, TipoUsuario.SOPORTE, TipoUsuario.APROBADORBYR)"
    class="nav-item"><a class="nav-link" routerLink="tams" routerLinkActive="active">Tams</a>
  </li>
</ul>

<router-outlet></router-outlet>