import { Component, inject } from '@angular/core';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { CondicionVenta } from 'src/app/shared/enums/condicionVenta';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';

@Component({
  selector: 'app-documentos-index',
  templateUrl: './documentos-index.component.html',
  styleUrls: ['./documentos-index.component.scss']
})
export class DocumentosIndexComponent {

  authService = inject(AuthService);
  CondicionVenta=CondicionVenta;
  TipoUsuario = TipoUsuario;
  
}
