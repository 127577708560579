import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { ModalResumenPreciosComponent } from '../modal-resumen-precios/modal-resumen-precios.component';
import { PreciosService } from '../../precios.service';


@Component({
  selector: 'app-modal-exportacion',
  templateUrl: './modal-exportacion.component.html',
  styleUrls: ['./modal-exportacion.component.css']
})
export class ModalExportacionComponent implements OnInit {

  constructor(public modal: NgbActiveModal,
              private fb: UntypedFormBuilder,
              private precioService: PreciosService,
              private modalService: NgbModal) { }

  fileUpload = { status: '', message: '', filePath: '', length: 0 };
  userFileName: string;
  selectedFile: boolean = false;
  error: string;
  guardandoExcel: boolean = false;
  niveles: any[] = [
    { id:"1", nombre: "1 - Producto/Vigencia/Moneda/Aeroplanta/Area de Negocio/Segmento/Cliente/Tipo operación/Direccion de entrega"},
    { id:"2", nombre: "2 - Producto/Vigencia/Moneda/Aeroplanta/Area de Negocio/Segmento/Cliente/Tipo operación"},
    { id:"3", nombre: "3 - Producto/Vigencia/Moneda/Aeroplanta/Area de Negocio/Segmento/Cliente"},
    { id:"4", nombre: "4 - Producto/Vigencia/Moneda/Aeroplanta/Area de Negocio/Segmento"},
    { id:"5", nombre: "5 - Producto/Vigencia/Moneda/Aeroplanta/Area de Negocio"},
    { id:"6", nombre: "6 - Producto/Vigencia/Moneda/Aeroplanta"},

  ];


  productoForm = new UntypedFormGroup({
    nivelFormControl: new UntypedFormControl(),
  });

  // hooks

  ngOnInit() {
    this.productoForm = this.fb.group({
      name: [''],
      profile: [''],
      inputFile: ['', Validators.required],
      nivelFormControl: new UntypedFormControl(1)
    });
    this.nivel.setValue(6);
  }

  // form

  onSelectedFile(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.productoForm.get('profile').setValue(file);
      this.productoForm.get('name').setValue(file.name);
      this.userFileName = file.name;
      this.selectedFile = true;
    }
  }

  // llamadas al service

  cargarPrecios() {
    console.log(this.nivel.value);
    this.error = null;

    const formData = new FormData();
    formData.append('name', this.productoForm.get('name').value);
    formData.append('profile', this.productoForm.get('profile').value);
    formData.append('nivelPrecio', this.nivel.value);
    this.guardandoExcel = true;
    this.precioService.uploadConsulta(formData)
      .subscribe(result => {
        if(! result){
          return;
        }
       if(result) this.fileUpload = result;

        if (Array.isArray(result)) {
          this.guardandoExcel = false;
          // llamar al modal para mostrar resumen
          let modalRef = this.modalService.open(ModalResumenPreciosComponent, { centered: true, size: "lg", windowClass: 'myCustomModalClass', backdrop: "static" });
          modalRef.componentInstance.name = this.productoForm.get('name').value;
          modalRef.componentInstance.profile = this.productoForm.get('profile').value;
          modalRef.componentInstance.nivelPrecio =  this.nivel.value;
          modalRef.componentInstance.preciosConsulta =  result;
          modalRef.result.then((result) => { this.modal.close() }, () => { this.modal.close() });
        }
      },
        (err: ErrorModel) => {
          this.error = err.description;
          this.guardandoExcel = false;
        }
      );
  }


  // getters

  get nivel() {
    return this.productoForm.get('nivelFormControl');
  }

  descargarPlantilla() {
    var nivel =  this.nivel.value;
    window.open('/assets/excel-niveles/PreciosNivel'+nivel+'.xlsx', '_blank');
  }

}
