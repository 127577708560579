import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductoService } from 'src/app/abm/producto/producto.service';
import { UsuarioService } from 'src/app/account/usuario.service';
import { Producto } from 'src/app/shared/models/abm/Producto';
import { Cano } from 'src/app/shared/models/stock/Cano';
import { Responsable } from 'src/app/shared/models/stock/Responsable';
import { MessageService } from 'src/app/shell/message.service';
import { StockService } from '../../../stock.service';

@Component({
  selector: 'app-nueva-caneria',
  templateUrl: './nueva-caneria.component.html',
  styleUrls: ['./nueva-caneria.component.css']
})
export class NuevaCaneriaComponent implements OnInit {
  @Input() caneria: Cano;

  editando: boolean = false;
  cargandoProductos: boolean = false;
  errorProductos: boolean;
  productos: Producto[];
  creandoCaneria: boolean = false;

  caneriasForm = new UntypedFormGroup({
    volumenFormControl: new UntypedFormControl('', [Validators.required]),
    productoFormControl: new UntypedFormControl('', [Validators.required]),
    comentarioFormControl: new UntypedFormControl(''),
    codigoFormControl: new UntypedFormControl('', [Validators.required]),
    nombreFormControl: new UntypedFormControl('', [Validators.required])
  })
  constructor(public activeModal: NgbActiveModal, private productosService: ProductoService, private usuarioService: UsuarioService, private stockService: StockService,
    private messageService: MessageService) { }

  ngOnInit() {
    this.getProductos();
    if (this.caneria != null) {
      this.editando = true;
      this.cargarDatosDeCaneria()
    } else {
      this.editando = false;
      this.cargarDatosDefecto();
    }
  }

  cargarDatosDeCaneria() {
    this.codigoFormControl.setValue(this.caneria.codigo);
    this.nombreFormControl.setValue(this.caneria.nombre);
    this.volumenFormControl.setValue(this.caneria.stock);
    this.comentarioFormControl.setValue(this.caneria.descripcion);
  }
  cargarDatosDefecto() {
    this.volumenFormControl.setValue(0);
  }
  getProductos() {
    this.cargandoProductos = true;
    this.productosService.obtenerProductos()
      .subscribe(productos => {
        this.productos = productos;
        // se filtran por aerocombustibles y por combustibles
        this.productos = this.productos.filter(p => p.rubro.aerocombustible || p.rubro.combustible);
        this.productos = this.productos.filter(a => a.activo)
        if (!this.editando) {
          this.productoFormControl.setValue(this.productos[0]);
        } else {
          this.productos.forEach((value, index) =>{
            if(this.productos[index].codigoProducto == this.caneria.producto.codigoProducto){
              this.productoFormControl.setValue(this.productos[index]);
            }
          })
        }
        this.cargandoProductos = false;
      }, () => this.errorProductos = true);

  }

  agregarStockCanerias() {
    this.creandoCaneria = true;
    let c = new Cano();

    c.codigo = this.codigoFormControl.value;
    c.nombre = this.nombreFormControl.value;
    c.producto = this.productoFormControl.value;
    c.stock = parseFloat(this.volumenFormControl.value);
    c.codigoAeroplanta = this.usuarioService.getAeroplantaActual().codigoAeroplanta;
    c.descripcion = this.comentarioFormControl.value;


    c.responsable = this.usuarioService.getNombresCompletosUsuario();
    if (!this.editando) {
      c.activo = true;
      this.stockService.crearCaneria(c)
        .subscribe(result => {
          this.messageService.showSuccessMessage('La cañeria fue creada con éxito.');
          this.creandoCaneria = false;
          this.activeModal.close(true);
        }, () => {
          this.messageService.showErrorMessage('Hubo un problema al crear la cañeria, por favor reintente.');
          this.creandoCaneria = false;
        });
    } else {
      c.activo = this.caneria.activo;
      this.stockService.editarCaneria(c)
        .subscribe(result => {
          this.messageService.showSuccessMessage('La cañeria fue editada con éxito.');
          this.creandoCaneria = false;
          this.activeModal.close(true);
        }, () => {
          this.messageService.showErrorMessage('Hubo un problema al editar la cañeria, por favor reintente.');
          this.creandoCaneria = false;
        });
    }
  }

  get volumenFormControl() {
    return this.caneriasForm.get('volumenFormControl');
  }
  get productoFormControl() {
    return this.caneriasForm.get('productoFormControl');
  }
  get comentarioFormControl() {
    return this.caneriasForm.get('comentarioFormControl');
  }
  get codigoFormControl() {
    return this.caneriasForm.get('codigoFormControl');
  }
  get nombreFormControl() {
    return this.caneriasForm.get('nombreFormControl');
  }
}
