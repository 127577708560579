<nav class="navbar navbar-light bg-light navbar-expand-lg mb-3">
  <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <h4 class="fw-bold aling-middle">
    Aeroespecialidades.
  </h4>
</nav>
<form class="mb-3">
  <div class="tablaAeroespecialidades col-12">
    <div class="spinner-container" *ngIf="esperandoCarga">      
      <ngx-spinner name="spinnerGrafico" bdOpacity=0.3bd
        Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
        <p style="color: white">C A R G A N D O . . . </p>
      </ngx-spinner>      
    </div>
    <div class="alert alert-warning" *ngIf="!esperandoCarga && this.aeroespecialidades.length == 0">No hay stock de aeroespecialidades para la aeroplanta.</div>
    <table class="table table-striped table-hover" *ngIf="!esperandoCarga && this.aeroespecialidades.length > 0">
      <thead>
        <tr>
          <th scope="col">Código</th>
          <th scope="col">Nombre</th>
          <th scope="col">Cantidad</th>
        </tr>
      </thead>
      <tbody>
         <tr *ngFor="let aep of aeroespecialidades">
          <th>{{aep.aeroespecialidad.codigoProducto}}</th>
          <th>{{aep.aeroespecialidad.nombreProducto}}</th>
          <th>{{aep.cantidadActual}}</th>
         </tr>
      </tbody>
    </table>
  </div>
</form>