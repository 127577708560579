import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ComprobanteService } from 'src/app/facturacion/comprobante.service';
import { ControlCreditoService } from '../control-credito.service';
import { saveAs } from 'file-saver';
import { SaldoCliente } from 'src/app/shared/models/controlCredito/SaldoCliente';
import { UnidadMedida } from 'src/app/shared/enums/unidadMedida';
import { TipoVuelo } from 'src/app/shared/enums/tipoVuelo';

@Component({
  selector: 'app-reporte-pendientes-pad',
  templateUrl: './reporte-pendientes-pad.component.html',
  styleUrls: ['./reporte-pendientes-pad.component.scss']
})
export class ReportePendientesPadComponent implements OnInit {

  @Input() aerovales;
  pendiente = { codigo: "Pendiente", descripcion: "Pendiente", seleccionado: false };
  finalizado = { codigo: "Finalizado", descripcion: "Finalizado", seleccionado: false };
  finalizadoSinFirma = { codigo: "FinalizadoSinFirma", descripcion: "Finalizado sin firma", seleccionado: false };
  unidadMedida = UnidadMedida;
  tipoVuelo = TipoVuelo;
  areas = [
    this.pendiente,
    this.finalizado,
    this.finalizadoSinFirma
  ];
  errorEnExportacion: boolean = false;

  constructor(private controlCreditoService: ControlCreditoService, public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  descargarExcel() {
      let aerovalesReporte = this.aerovales;
      aerovalesReporte.forEach(aerovale => {
      aerovale.aerovale = aerovale.numeroAerovale ? `${aerovale.prefijo}-${aerovale.numeroAerovale}` : "";
      aerovale.productoCodigo = aerovale.codigoProducto;
      aerovale.productoNombre = aerovale.nombreProducto;
      aerovale.cantidad = aerovale.volumenNaturales;
      aerovale.unidadDeMedida = UnidadMedida[aerovale.unidadMedida]
      aerovale.tipoOperacion = TipoVuelo[aerovale.tipoVuelo]
      //switch para mandar el valor correcto del enum en el reporte
      
    })
    this.errorEnExportacion = false;
    this.controlCreditoService.descargarExcelActualizacionAerovales(aerovalesReporte)
      .subscribe((res: Blob) => {
        const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
        saveAs(blob, "ListadoDeAerovales");
      }, () => {
        this.errorEnExportacion = true;
        console.log("error")
      });
  }

}
