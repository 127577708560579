<nav class="navbar navbar-light bg-light navbar-expand-lg">
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav">
            <li class="nav-item me-2">
                <input id="busquedaFormControl" type="text" class="form-control" [formControl]="busquedaFormControl"
                    placeholder="Razón social" (input)="filtrarClientes()" />
            </li>                   
        </ul>
    </div>
</nav>

<div class="row">  
    <div class="col col-3">
        <div class="list-group py-3 menu-izq">
            <app-alijes-devolucion-lista 
                [clienteBuscado]="this.clienteBuscado"                
                (cuentasSeleccionadas)="usuarioSeleccionoCliente($event)"
            ></app-alijes-devolucion-lista>            
        </div>
    </div>
    <!-- informacion de saldos en cuentas corrientes -->
    <div class="col col-9 py-3">
        <div *ngIf="this.clienteSeleccionado!=null" id="tablaInformacion">
            <app-alijes-devolucion-saldos 
                [cliente]="this.clienteSeleccionado"            
            ></app-alijes-devolucion-saldos>
        </div>
    </div>
</div>