import { Producto } from "../despacho/Producto";
import { Responsable } from "./Responsable";

export class Cano{
  id:string;
  codigo: string;
  nombre: string;
  producto: Producto;
  stock: number;
  descripcion: string;
  activo: boolean;
  fechaModificacion:string;
  responsable: string;
  codigoAeroplanta: string;
  esta: boolean;
}
