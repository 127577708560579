import { Component, OnInit } from '@angular/core';
import { NgbCalendar, NgbDatepickerConfig, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { EstadoCambio } from 'src/app/shared/enums/EstadoCambio';
import { TipoModificacionCliente } from 'src/app/shared/enums/TipoModificacionCliente';
import { ListadoModificacionClienteDTO } from 'src/app/shared/models/cliente/ListadoModificacionClienteDTO';
import { ListadoModificacionDTO } from 'src/app/shared/models/cliente/ListadoModificacionDTO';
import { UtilClientes } from 'src/app/shared/utiles/UtilClientes';
import { Contacto } from 'src/app/shared/models/cliente/Contacto';
import { DireccionFiscal } from 'src/app/shared/models/cliente/DireccionFiscal';
import { Ubicacion } from 'src/app/shared/models/cliente/Ubicacion';
import { formatDate } from '@angular/common';
import { Responsable } from 'src/app/shared/models/cliente/Responsable';
import { Cuenta } from 'src/app/shared/models/cliente/Cuenta';
import { ListadoModificacionCuentaDTO } from 'src/app/shared/models/cliente/ListadoModificacionCuentaDTO';
import { NgxSpinnerService } from 'ngx-spinner';
import { ClienteService } from '../cliente.service';
import { MessageService } from 'src/app/shell/message.service';
import { EstadoModificacion } from 'src/app/shared/enums/EstadoModificacion';
import { SharedService } from 'src/app/shared/shared.service';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-reporte-clientes',
  templateUrl: './reporte-clientes.component.html',
  styleUrls: ['./reporte-clientes.component.css']
})
export class ReporteClientesComponent implements OnInit {

  EstadoCambio = EstadoCambio;
  EstadoModificacion = EstadoModificacion;
  TipoModificacion = TipoModificacionCliente;

  obteniendoInformacion = true;
  modelDesde: NgbDateStruct;
  modelHasta: NgbDateStruct;
  fechaDesde: Date;
  fechaHasta: Date;
  modificaciones: ListadoModificacionDTO[] = [];
  modificacionesFiltradas: ListadoModificacionDTO[] = [];

  mostrarModificaciones: { cuentaId: string, mostrar: boolean } [] = []; 

  buscadorFormControl = new UntypedFormControl();

  estados: any[] = [
    { "nombre": 'Pendiente', "codigo": 0, "seleccionado": false },
    { "nombre": 'Aprobado', "codigo": 1, "seleccionado": false },
    { "nombre": 'Rechazado', "codigo": 2, "seleccionado": false }
  ];

  constructor(  private datepickerConfig: NgbDatepickerConfig, 
                private calendar: NgbCalendar,
                public utilClientes: UtilClientes,
                private spinner: NgxSpinnerService,
                private clienteService: ClienteService,
                private messageService: MessageService,
                private sharedService: SharedService
    ) { }

  ngOnInit() {
    this.modelDesde = this.calendar.getToday();
    this.modelHasta = this.calendar.getToday();
    this.reinicioFechaInicioFechaFin();

    let inicio: any = { day: 1, month: 1, year: 2019 };
    this.datepickerConfig.minDate = inicio;
    this.datepickerConfig.maxDate = this.calendar.getToday();
  }

  reinicioFechaInicioFechaFin() {
    this.fechaDesde = new Date(this.modelDesde.year, this.modelDesde.month - 1, this.modelDesde.day);
    this.fechaHasta = new Date(this.modelHasta.year, this.modelHasta.month - 1, this.modelHasta.day);
    this.fechaHasta.setHours(23, 59, 59, 99);
  }

  obtenerModificacionesCliente(modifCliente: ListadoModificacionClienteDTO){
    var modificaciones: { valorViejo: string, valorNuevo: string } [] = []; 

    if(modifCliente != null){
      var viejo = modifCliente.clienteViejo;
      var nuevo = modifCliente.clienteNuevo;

      modificaciones = this.utilClientes.compararClientes(viejo, nuevo);   
    }

    return modificaciones;
  }

  compararCuentas(modifCuenta: ListadoModificacionCuentaDTO){
    var modificaciones: { valorViejo: string, valorNuevo: string } [] = [];     
    
    if(modifCuenta != null){
      var cuentaViejo = modifCuenta.cuentaViejo;
      var cuentaNuevo = modifCuenta.cuentaNuevo;

      modificaciones = this.utilClientes.compararCuentas(cuentaViejo, cuentaNuevo);      
    }    

    return modificaciones;
  }

  fechaDesdeMayorQueHasta() {
    let esMayor: boolean = false;
    this.fechaDesde = new Date(this.modelDesde.year, this.modelDesde.month - 1, this.modelDesde.day);
    this.fechaHasta = new Date(this.modelHasta.year, this.modelHasta.month - 1, this.modelHasta.day);
    if (this.fechaDesde > this.fechaHasta) { esMayor = true; }
    return esMayor;
  }

  buscarDesdeHasta() {
    this.modificaciones = [];
    this.modificacionesFiltradas = [];
    this.reinicioFechaInicioFechaFin();
    return this.obtenerModificaciones(this.fechaDesde, this.fechaHasta);
  }

  obtenerModificaciones(fechaDesde: Date, fechaHasta: Date) {
    let desde: string = this.formatDate(fechaDesde.getUTCFullYear(), fechaDesde.getMonth() + 1, fechaDesde.getDate(), 0, 0);
    let hasta: string = this.formatDate(fechaHasta.getUTCFullYear(), fechaHasta.getMonth() + 1, fechaHasta.getDate(), 23, 59);
    this.obteniendoInformacion = true;
    this.spinner.show('spinnerReporteClientes');
    this.clienteService.getModificacionesParaReporte(desde, hasta)
      .subscribe(result => {
        result.forEach(m => {
          
          if(m.usuarioModificacion && m.usuarioModificacion.indexOf(',') > -1) m.usuarioModificacion = JSON.parse(m.usuarioModificacion.replace(/'/g,'"')).NombreCompleto;

          if(m.usuarioAprobador && m.usuarioAprobador.indexOf(',') > -1) m.usuarioAprobador = JSON.parse(m.usuarioAprobador.replace(/'/g,'"')).NombresCompletos;          
        });

        this.modificaciones = result.sort((a, b) => new Date(b.fechaModificacion).getTime() - new Date(a.fechaModificacion).getTime());
        this.modificacionesFiltradas = result.sort((a, b) => new Date(b.fechaModificacion).getTime() - new Date(a.fechaModificacion).getTime());

        this.iniciarArrayMostrarModificaciones(); 

        this.filtrarModificaciones();
        this.obteniendoInformacion = false;
        this.spinner.hide('spinnerReporteClientes');
      },
        () => {
          this.messageService.showErrorMessage("Error al obtener modificaciones de clientes.");
          this.obteniendoInformacion = false;
          this.spinner.hide('spinnerLista');
          this.modificaciones = [];
          this.modificacionesFiltradas = [];
        }
      )
  }

  formatDate(year, month, day, hour, minute): string {
    return year + '-' + month.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + '-' + day.toLocaleString(undefined, { minimumIntegerDigits: 2 })
      + 'T' + hour.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + minute.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':00';
  }

  filtrarModificaciones() {
    this.obteniendoInformacion = true;
    this.modificacionesFiltradas = this.modificaciones.filter(m => {
      let agregar = true;      

      var texto = this.buscadorFormControl.value
      if (texto) {        
        agregar = m.usuarioModificacion.toLowerCase().indexOf(texto.toLowerCase()) > -1;
        
        if(m.usuarioAprobador){
          agregar = agregar || m.usuarioAprobador.toLowerCase().indexOf(texto.toLowerCase()) > -1;
        }      

        if(m.modificacionCliente && m.modificacionCliente.clienteNuevo){
          agregar = agregar || m.modificacionCliente.clienteNuevo.razonSocial.toLowerCase().indexOf(texto.toLowerCase()) > -1;
          agregar = agregar || m.modificacionCliente.clienteNuevo.numeroDocumento.toLowerCase().indexOf(texto.toLowerCase()) > -1;
        }

        if(m.modificacionCuenta && m.modificacionCuenta.cuentaNuevo){
          agregar = agregar || m.modificacionCuenta.razonSocial.toLowerCase().indexOf(texto.toLowerCase()) > -1;
          agregar = agregar || m.modificacionCuenta.cuentaNuevo.ctA_SGC.toLowerCase().indexOf(texto.toLowerCase()) > -1;
          agregar = agregar || m.modificacionCuenta.cuentaNuevo.descripcion.toLowerCase().indexOf(texto.toLowerCase()) > -1;
        }
      }

      if (this.estados.some(c => c.seleccionado))
        agregar = agregar && this.estados.some(
          e => (e.seleccionado && e.codigo === m.estado));

      return agregar;
    });
    this.obteniendoInformacion = false;
  }

  iniciarArrayMostrarModificaciones(){
    this.mostrarModificaciones = []; 

    for(let c of this.modificaciones){
      if(c != null){
        this.mostrarModificaciones.push({cuentaId: c.id, mostrar: false});
      }      
    } 
  }

  mostrarModificacionesCuenta(cuentaId){
    for(let modif of this.mostrarModificaciones){
      if(modif.cuentaId == cuentaId){
        return modif.mostrar;
      }
    }
  }

  mostrarOcultarModificacionesCuenta(cuentaId){
    for(let modif of this.mostrarModificaciones){
      if(modif.cuentaId == cuentaId){
        modif.mostrar = (! modif.mostrar);
      }
    }
  }

  obtenerDescripcionEstadosSeleccionados(){
    if( this.estados.some( e => e.seleccionado) ){
      var rta = "";

      for(let e of this.estados){
        if(e.seleccionado){
          rta += e.nombre.toUpperCase() + " + ";
        }        
      }

      return rta.substring(0, rta.length-3);
    }else{
      return "PENDIENTE + APROBADO + RECHAZADO"
    }
  }

  obtenerNombreArchivo(path: string){
    return path.substring(43, path.indexOf('?'));
  }
}
