import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReportesRoutingModule } from './reportes-routing.module';
import { ReportsIndexComponent } from './reports-index/reports-index.component';
import { StockIndexComponent } from './stock/stock-index/stock-index.component';
import { StockActualComponent } from './stock/stock-actual/stock-actual.component';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConsolidadoComponent } from './informes/consolidado/consolidado.component';
import { ConsolidadoListaComponent } from './informes/consolidado/consolidado-lista/consolidado-lista.component';
import { ConsolidadoDetalleComponent } from './informes/consolidado/consolidado-detalle/consolidado-detalle.component';
import { InformesIndexComponent } from './informes/informes-index/informes-index.component';
import { ResolucionMensualComponent } from './informes/resolucion-mensual/resolucion-mensual.component';
import { ModalGenerarResolucionComponent } from './informes/resolucion-mensual/modal-generar-resolucion/modal-generar-resolucion.component';
import { VisorInformeResolucionComponent } from './informes/resolucion-mensual/visor-informe-resolucion/visor-informe-resolucion.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ModalJustificacionResolucionComponent } from './informes/resolucion-mensual/modal-justificacion-resolucion/modal-justificacion-resolucion.component';

@NgModule({
  declarations: [
    ReportsIndexComponent,
    StockIndexComponent,
    StockActualComponent,
    ConsolidadoComponent,
    ConsolidadoListaComponent,
    ConsolidadoDetalleComponent,
    InformesIndexComponent,
    ResolucionMensualComponent,
    ModalGenerarResolucionComponent,
    VisorInformeResolucionComponent,
    ModalJustificacionResolucionComponent],
  imports: [
    ReactiveFormsModule,
    CommonModule,
    ReportesRoutingModule,
    NgbModule,
    FormsModule,
    SharedModule,
    PdfViewerModule
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class ReportesModule { }
