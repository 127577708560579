export class Aeroespecialidad {
    id: string;
    nombreProducto: string;
    cantidad: number;
    productoCodigo: string;

    constructor(cantidad, nombre, codigo){        
        this.cantidad = cantidad;
        this.nombreProducto = nombre;
        this.productoCodigo = codigo;
    }
}