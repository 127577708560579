import { Localidad } from './Localidad';
import { Ciudad } from './Ciudad';
import { Leyenda } from './Leyenda';

export class Emisor{
  id: string;
  razonSocial: string;
  tipoDocumento: number;
  numeroDocumento: string;
  certificadoNombre: string;
  condicionIVAId: string;
  direccion: string;
  telefono: string;
  email: string;
  numeroIIBB: string;
  localidad: Localidad;
  leyendas: Leyenda;
  esAgentePercepcionIIBB: boolean;
  esAgentePercepcionIVA: boolean;
  alicuotaPercepcionIVAProductos: number;
  alicuotaPercepcionIVAOtros: number;
  alicuotaPercepcionIVANoCategorizado: number;
  condicionIVA: string;
  fechaInicioActividades: string;
  ciudad: Ciudad;
  montoMaximoPagoContado: number;
  montoMaximoPagoElectronico: number;
  tipoOperacion: number;
  ctaSgc_ClienteCF: string;
  imprentaRazonSocial: string;
  imprentaCUIT: string;
}