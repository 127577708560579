import { Component, OnInit, Input } from '@angular/core';
import * as Highcharts from 'highcharts';
import { StockService } from '../../stock.service';
import { DetalleStockPorContenedor } from 'src/app/shared/models/stock/DetalleStockPorContenedor';
import { UsuarioService } from 'src/app/account/usuario.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Cano } from 'src/app/shared/models/stock/Cano';
import { Contenedor } from 'src/app/shared/models/stock/Contenedor';
import { Producto } from 'src/app/shared/models/stock/Producto';
import { SharedService } from 'src/app/shared/shared.service';



@Component({
  selector: 'app-tablero-detalle',
  templateUrl: './tablero-detalle.component.html',
  styleUrls: ['./tablero-detalle.component.css'],

})
export class TableroDetalleComponent implements OnInit {

  listaDetalleStock: DetalleStockPorContenedor[] = [];
  listaDetalleStockFiltrado: DetalleStockPorContenedor[] = [];
  contenedoresParaCheckear: any = [
    { "nombre": 'Tanque', "codigo": 1, "seleccionado": false },
    // { "nombre": 'Poliducto', "codigo": 2, "seleccionado": false },
    { "nombre": 'Abastecedora', "codigo": 4, "seleccionado": false },
    { "nombre": 'Cañeria', "codigo": 99, "seleccionado": false }
  ]
  @Input() productoSeleccionado: Producto; // es el producto que me seleccionan desde tablero
  @Input() canerias: Cano[];
  graficasProducto: Highcharts.Options[] = [];
  Highcharts = Highcharts;
  change$: Subscription;
  cambiandoAeroplanta: boolean = false;
  obteniendoDetalleDeProducto: boolean = true;
  armandoGrafico: boolean = true;

  formDetalle = new UntypedFormGroup({
    formBotones: new UntypedFormControl(''),
    formGraficoStock: new UntypedFormControl('')
  });

  constructor(private stockService: StockService,
    private formBuilder: UntypedFormBuilder, 
    private usuarioService: UsuarioService,
    private spinner: NgxSpinnerService,
    sharedService: SharedService) {
      this.change$ = sharedService.aeroplantaSeleccionada.subscribe(_=>{
        this.cambiarDeAeroplanta()
      })
    }    

  // hooks

  ngOnInit() {
    this.obteniendoDetalleDeProducto = true;
    this.spinner.show('spinnerLista');

    if (this.armandoGrafico) {
      this.formDetalle = this.formBuilder.group({
        graficosArray: this.formBuilder.array([
          // load first row at start
          this.armarNuevoGrafico()
        ])
      });
    }

    this.obtenerDetalleProducto(this.usuarioService.getAeroplantaActual().codigoAeroplanta, this.productoSeleccionado.codigoProducto);
  }

  ngOnDestroy() {
    this.change$.unsubscribe();
    this.listaDetalleStock = [];
  }

  // form

  // Inicio funciones grafico Stock.

  private armarNuevoGrafico() {
    return this.formBuilder.group({
      formGraficoStock: []
    })
  }

  llenarLista() {
    this.graficasProducto = [];
    for (let i = 0; i < this.listaDetalleStockFiltrado.length; i++) {
      if (this.listaDetalleStockFiltrado[i].contenedor.capacidad > 0) {
        var datos = [
          { name: "Vacio", y: this.listaDetalleStockFiltrado[i].contenedor.capacidad - this.listaDetalleStockFiltrado[i].stock, color: '#919192' },
          { name: "Stock", y: this.listaDetalleStockFiltrado[i].stock, color: this.listaDetalleStockFiltrado[i].contenedor.productoActual.color }
        ];
        var war: any = this.listaDetalleStockFiltrado[i].warning;
        let graficaProducto: Highcharts.Options = {
          chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                enabled: false
              },
              showInLegend: true
            }
          },
          title: {
            text: this.listaDetalleStockFiltrado[i].contenedor.nombre
          },
          subtitle: {
            text: war
          },
          credits: {
            enabled: false
          },
          series: [
            {
              name: 'Lts',
              data: datos,
              type: 'pie'
            }

          ]
        };
        if (i != 0 && this.obteniendoDetalleDeProducto) {
          const control = <UntypedFormArray>this.formDetalle.controls['graficosArray'];
          control.push(this.armarNuevoGrafico());
        }
        this.graficasProducto.push(graficaProducto);
      }
    }
    this.spinner.hide('spinnerLista');
    this.obteniendoDetalleDeProducto = false;
  }

  goBack() {
    this.productoSeleccionado = null;
    this.listaDetalleStock = [];
  }

  cambiarDeAeroplanta() {
    this.cambiandoAeroplanta = false;
    this.limpiarCampos();
    this.cambiandoAeroplanta = true;
    this.goBack();
  }

  limpiarCampos() {
    this.spinner.hide("spinnerLista");
    this.listaDetalleStock = [];

  }

  // llamadas al service

  obtenerDetalleProducto(codAeroplanta: string, codProducto: string) {
    this.stockService.getDetalleProducto(codAeroplanta, codProducto)
      .subscribe(result => {
        this.listaDetalleStock = result.filter(a => a.contenedor.capacidad > 0 && a.contenedor.tipoContenedor != 2);
        this.agregarCanerias();
        this.listaDetalleStockFiltrado = result.filter(a => a.contenedor.capacidad > 0 && a.contenedor.tipoContenedor != 2);
        this.listaDetalleStock = this.listaDetalleStock.sort((a,b) => a.contenedor.tipoContenedor - b.contenedor.tipoContenedor);
        this.listaDetalleStockFiltrado = this.listaDetalleStockFiltrado.sort((c,d) => c.contenedor.tipoContenedor - d.contenedor.tipoContenedor);
        this.filtrarMovimientos();
      })
  }

  agregarCanerias(){
    this.canerias.forEach((value, index) => {
      if(this.canerias[index].producto.codigoProducto == this.productoSeleccionado.codigoProducto){
        let newStock = new DetalleStockPorContenedor();
        newStock.stock = this.canerias[index].stock;
        newStock.aforador = null;
        newStock.stock15 = this.canerias[index].stock;
        newStock.warning = '';
        newStock.pico = null;
        newStock.contenedor = new Contenedor();
        newStock.contenedor.productoActual = this.productoSeleccionado;
        newStock.contenedor.nombre = this.canerias[index].nombre;
        newStock.contenedor.capacidad = this.canerias[index].stock;
        newStock.contenedor.codigo = this.canerias[index].codigo;
        newStock.contenedor.tipoContenedor = 99;
        this.listaDetalleStock.push(newStock);
      }
    });

  }

  //filtro
  filtrarMovimientos() {
    this.obteniendoDetalleDeProducto = true;
    this.listaDetalleStockFiltrado = this.listaDetalleStock.filter(a => {
      let agregar = true;
      if (this.contenedoresParaCheckear.some(to => to.seleccionado))
        agregar = agregar && this.contenedoresParaCheckear.some(
          to => (to.seleccionado && to.codigo === a.contenedor.tipoContenedor));
      return agregar;
    });
    this.llenarLista()
  }

}
