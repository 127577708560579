<form>

  <div class="modal-header bg-light">
    <h4 class="modal-title">Bitácora</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()"></button>
  </div>

  <div class="modal-body">




    <div class="row">
      <div>
        <ul class="list-group">
         <li class="list-group-item">Factura generada el dia 09/12/2019</li>
         <li class="list-group-item">Solicitud de Refacturación por el usuario Juan Perez el dia 09/12/2019 </li>
         <li class="list-group-item">Motivo de la solicitud: bla bla bla</li>
         <li class="list-group-item">Editado por el usuario Luis Lopez el dia 10/12/2019</li>
         <li class="list-group-item">Aprobado/Rechazado por el usuario Luis Lopez el dia 11/12/2019</li>
        </ul>
      </div>
    </div>



  </div>
  <div class="modal-footer bg-light">
    <span class="border">
      <button type="button" class="btn btn-secondary"
        (click)="activeModal.dismiss('Close clicked')">Cancelar</button>
    </span>
  </div>
</form>

