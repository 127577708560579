import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Aeroplanta } from 'src/app/shared/models/despacho/Aeroplanta';
import { Cliente } from 'src/app/shared/models/despacho/Cliente';
import { Producto } from 'src/app/shared/models/despacho/Producto';

@Component({
  selector: 'app-productos-byr',
  templateUrl: './productos-byr.component.html',
  styleUrls: ['./productos-byr.component.css']
})
export class ProductosByrComponent implements OnInit {

  [x: string]: any;


  @Input() productos: Producto[];


  busquedaFormControl = new UntypedFormControl();


  cargandoClientes: boolean = true;
  constructor(public activeModal: NgbActiveModal)//, private aeroplantaService:AeroplantasService) { }
  {

  }

  ngOnInit() {
  }


}
