<ul class="nav nav-tabs justify-content-start">

    <li class="nav-item">
      <a class="nav-link" routerLink="importacionMasiva" routerLinkActive="active"><b>Importación masiva</b></a>
    </li>
  
    <li class="nav-item">
      <a class="nav-link" routerLink="actualizacionPrecios" routerLinkActive="active"><b>Actualización de precios</b></a>
    </li>
</ul>
<router-outlet></router-outlet>