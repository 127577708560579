import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { UsuarioService } from 'src/app/account/usuario.service';
import { EntidadParteDiario } from 'src/app/shared/enums/EntidadParteDiario';
import { estadoEquipo } from 'src/app/shared/enums/estadoEquipo';
import { estadoSistemaSeg } from 'src/app/shared/enums/estadoSistemaSeg';
import { EstadoEquipo } from 'src/app/shared/models/stock/EstadoEquipo';
import { EstadoSistemaSeguridad } from 'src/app/shared/models/stock/EstadoSistemaSeguridad';
import { MessageService } from 'src/app/shell/message.service';
import { StockService } from 'src/app/stock/stock.service';
import { Usuario } from 'src/app/shared/models/despacho/Usuario';
import { EstadoServicio as EstadoServicioStock } from 'src/app/shared/models/stock/EstadoServicio';
import { TipoExpendedora } from 'src/app/shared/enums/tipoExpendedora';
import { EstadoTelemedicion } from 'src/app/shared/enums/EstadoTelemedicion';
import { EstadoServAbastecedora } from 'src/app/shared/enums/EstadoServAbastecedora';
import { EstadoTanqueUnico } from 'src/app/shared/enums/EstadoTanqueUnico';

@Component({
  selector: 'app-modal-editar-estados',
  templateUrl: './modal-editar-estados.component.html',
  styleUrls: ['./modal-editar-estados.component.scss']
})
export class ModalEditarEstadosComponent implements OnInit {

  @Input() enume:EntidadParteDiario;
  @Input() id:string;
  @Input() tipo:TipoExpendedora;
  @Input() fs: boolean;
  @Input() estado: estadoEquipo;

  titulo:string="";
  EntidadParteDiario=EntidadParteDiario;

  form = new UntypedFormGroup({
    observacionesFormControl: new UntypedFormControl('', [Validators.required]),
    fechaFSFormControl: new UntypedFormControl('', [Validators.required]),
    horaFSFormControl: new UntypedFormControl(''),
    usuarioFormControl: new UntypedFormControl(null)
  });

  grabando:boolean=false;
  model: NgbDateStruct;
  horaAct: any;
  obteniendoUsuarios:boolean=false;
  usuarios: Usuario[] = [];
  usuarioSeleccionado: Usuario = null;

  constructor(public activeModal: NgbActiveModal, 
    private usuarioService: UsuarioService, 
    private stockService: StockService, 
    private calendar: NgbCalendar, 
    private messageService: MessageService) { }

  ngOnInit(): void {
    switch (this.enume) {
      case EntidadParteDiario.TanqueServicio:
        this.titulo="Editar Servicio de Tanque";
        break;
      case EntidadParteDiario.TanqueTelemedicion:
        this.titulo="Editar Telemedición de Tanque";
        break;
      case EntidadParteDiario.Expendedora:
        this.titulo="Editar Servicio de Expendedora";
        break;
      case EntidadParteDiario.EquipoFijo:
        this.titulo="Editar Servicio de Equipo Fijo";
        break;
      case EntidadParteDiario.SistemasSeguridad:
        this.titulo="Editar Servicio de Sistema de seguridad";
        break;    
      default:
        this.titulo="Sin Título";
        break;
    }
    let horaActual = new Date();
    this.model = this.calendar.getToday();
    this.horaAct = { hour: horaActual.getHours(), minute: horaActual.getMinutes(), second: horaActual.getSeconds() };

    this.getUsuarios();
  }

  onClick() {
    this.grabando = true;
    if(this.enume==EntidadParteDiario.EquipoFijo){
      let estado = new EstadoEquipo();
      estado.estado = this.estado;
      estado.responsable = this.usuarioService.getNombresCompletosUsuario();
      estado.fechaHoraUltimoCambio = "2020-01-15T21:00:00.00";
      const fecha = this.fechaFSFormControl.value;
      const hora = this.horaFSFormControl.value;
      estado.fechaHoraFS = this.formatDate(fecha['year'], fecha['month'], fecha['day'], hora['hour'], hora['minute']);
      estado.observaciones = this.observacionesFormControl.value;
      this.stockService.cambiarEstadoEquipo(this.id, estado)
        .subscribe(result => {
          this.messageService.showSuccessMessage('El Servicio del Equipo fue actualizado con exito.');
          this.grabando = false;
          this.activeModal.close(result);
        }, () => {
          this.messageService.showErrorMessage('Ocurrio un error, vuelva a intentar.');
          this.grabando = false;
        })
    }
    
    if(this.enume==EntidadParteDiario.SistemasSeguridad) {
      let estado = new EstadoSistemaSeguridad();
      estado.estado = this.estado;
      estado.observaciones = this.observacionesFormControl.value;
      estado.responsable = this.usuarioService.getNombresCompletosUsuario();
      if(this.fs) estado.autorizoFS = JSON.stringify(this.usuarioSeleccionado);
      
      const fecha = this.fechaFSFormControl.value;
      const hora = this.horaFSFormControl.value;
      estado.fechaHoraFS = this.formatDate(fecha['year'], fecha['month'], fecha['day'], hora['hour'], hora['minute']);
      
      this.stockService.cambiarEstadoSistemaSeguridad(this.id, estado)
        .subscribe(result => {
          this.messageService.showSuccessMessage('El Servicio del Sistema fue actualizado con exito.');
          this.grabando = false;
          this.activeModal.close(result);
        }, () => {
          this.messageService.showErrorMessage('Ocurrio un error, vuelva a intentar.');
          this.grabando = false;
        })
    }

    if(this.enume==EntidadParteDiario.Expendedora) {
      let estado = new EstadoServicioStock();
      estado.estado = this.estado;
      estado.observaciones = this.observacionesFormControl.value;
      estado.responsable = this.usuarioService.getNombresCompletosUsuario();
      estado.fechaHoraUltimoCambio = "2020-01-15T21:00:00.00";

      const fecha = this.fechaFSFormControl.value;
      const hora = this.horaFSFormControl.value;
      estado.fechaHoraFS = this.formatDate(fecha['year'], fecha['month'], fecha['day'], hora['hour'], hora['minute']);
      
      this.stockService.cambiarEstadoServicioExpendedora(this.id, estado, this.tipo)
        .subscribe(result => {
          this.messageService.showSuccessMessage('El Servicio de Expendedora fue actualizado con exito.');
          this.grabando = false;
          this.activeModal.close(result);
        }, () => {
          this.messageService.showErrorMessage('Ocurrio un error, vuelva a intentar.');
          this.grabando = false;
        })
    }

    if(this.enume==EntidadParteDiario.TanqueServicio) {
      let estado = new EstadoServicioStock();
      estado.estado = this.estado;
      estado.observaciones = this.observacionesFormControl.value;
      estado.responsable = this.usuarioService.getNombresCompletosUsuario();
      estado.fechaHoraUltimoCambio = "2020-01-15T21:00:00.00";

      const fecha = this.fechaFSFormControl.value;
      const hora = this.horaFSFormControl.value;
      estado.fechaHoraFS = this.formatDate(fecha['year'], fecha['month'], fecha['day'], hora['hour'], hora['minute']);
      
      this.stockService.cambiarEstadoServicioTanque(this.id, estado)
        .subscribe(result => {
          this.messageService.showSuccessMessage('El Servicio de Tanque fue actualizado con exito.');
          this.grabando = false;
          this.activeModal.close(result);
        }, () => {
          this.messageService.showErrorMessage('Ocurrio un error, vuelva a intentar.');
          this.grabando = false;
        })
    }

    if(this.enume==EntidadParteDiario.TanqueTelemedicion) {
      let estado = new EstadoServicioStock();
      estado.estado = this.estado;
      estado.observaciones = this.observacionesFormControl.value;
      estado.responsable = this.usuarioService.getNombresCompletosUsuario();
      estado.fechaHoraUltimoCambio = "2020-01-15T21:00:00.00";

      const fecha = this.fechaFSFormControl.value;
      const hora = this.horaFSFormControl.value;
      estado.fechaHoraFS = this.formatDate(fecha['year'], fecha['month'], fecha['day'], hora['hour'], hora['minute']);
      
      this.stockService.cambiarEstadoTelemedicionTanque(this.id, estado)
        .subscribe(result => {
          this.messageService.showSuccessMessage('La Telemedición de Tanque fue actualizado con exito.');
          this.grabando = false;
          this.activeModal.close(result);
        }, () => {
          this.messageService.showErrorMessage('Ocurrio un error, vuelva a intentar.');
          this.grabando = false;
        })
    }
   
  }

  formatDate(year, month, day, hour, minute): string {
    return year + '-' + month.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + '-' + day.toLocaleString(undefined, { minimumIntegerDigits: 2 })
      + 'T' + hour.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + minute.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':00';
  }
    
  searchUsuario = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => this.usuarios == null || term.length < 2 || this.obteniendoUsuarios ? [] :
        this.usuarios.filter(u => this.containsString(this.usuarioFormControl.value, u.nombresCompletos)).slice(0, 10))
    );
  
  formatterUsuario = (u: Usuario) => `${u.nombresCompletos}`;
    
  selectUsuario(event: any): void {
      this.usuarioFormControl.setValue(event.item);
      this.usuarioSeleccionado = this.usuarioFormControl.value;
    }

  getUsuarios() {
    this.obteniendoUsuarios = true;
    this.usuarioService.obtenerTodosLosUsuarios(true, false)
      .subscribe(result => {
        this.usuarios = result;
        this.obteniendoUsuarios = false;
      }, () => {
        this.obteniendoUsuarios = false;
        this.messageService.showErrorMessage('Error al obtener usuarios.');
      })
  }

  containsString(searchValue: string, ...searchIn: string[]): boolean {
    const concat = searchIn.toString();
    return concat.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
  }

  borrarUsuario() {
    this.usuarioFormControl.setValue(null);
    this.usuarioSeleccionado = null;
  }

  get observacionesFormControl() {
    return this.form.get('observacionesFormControl');
  }

  get fechaFSFormControl() {
    return this.form.get('fechaFSFormControl');
  }

  get horaFSFormControl() {
    return this.form.get('horaFSFormControl');
  }

  get usuarioFormControl() {
    return this.form.get('usuarioFormControl');
  }
}
