import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { StockService } from '../../../stock.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Tanque } from 'src/app/shared/models/stock/Tanque';
import { TipoTanque } from 'src/app/shared/enums/tipoTanque';
import { ProductoService } from 'src/app/abm/producto/producto.service';
import { Producto } from 'src/app/shared/models/abm/Producto';
import { Aeroplanta } from 'src/app/shared/models/despacho/Aeroplanta';
import { TanqueService } from '../../../tanques.service';
import { GrupoDeTanquesService } from '../../../grupos.service';
import { SurtidorService } from '../../../surtidor.service';
import { UsuarioService } from 'src/app/account/usuario.service';
import { GrupoDeTanques } from 'src/app/shared/models/stock/GrupoDeTanques';
import { Surtidor } from 'src/app/shared/models/stock/Surtidor';
import { MessageService } from 'src/app/shell/message.service';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';


@Component({
  selector: 'app-nuevo-tanque',
  templateUrl: './nuevo-tanque.component.html',
  styleUrls: ['./nuevo-tanque.component.css']
})
export class NuevoTanqueComponent implements OnInit {

  @Input() tanque: Tanque;
  @Input() aeroplanta: Aeroplanta;
  @Input() arrayTanques: Tanque[];

  codigoAutogenerado: string;
  productos: Producto[] = [];
  cargandoProductos: boolean;
  errorProductos: boolean;
  existeCodigo: boolean = false;
  existeCertificado: boolean = false;

  grupoTanques: GrupoDeTanques[] = [];
  surtidores: Surtidor[] = [];
  enumTipoTanque = TipoTanque;
  guardando: boolean;
  editando: boolean;
  cargandoTanques: boolean;
  cargandoSurtidores: boolean;
  productoNoEditable: boolean;
  capacidadMayor: boolean = true;

  form: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public activeModal: NgbActiveModal,
    private stockService: StockService,
    private productoService: ProductoService,
    private tanqueService: TanqueService,
    private changeDetector: ChangeDetectorRef,
    private grupoService: GrupoDeTanquesService,
    private surtidorService: SurtidorService,
    private usuarioService: UsuarioService,
    private messageService: MessageService) {

  }

  // hooks

  ngOnInit() {
    this.crearFormulario();
    this.cargarProductos();
    this.inicializarComponente();
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  // form

  crearFormulario() {
    this.form = this.formBuilder.group({
      codigoFormControl: ['', Validators.required],
      nombreFormControl: ['', Validators.required],
      capacidadFormControl: ['', Validators.required],
      productoFormControl: ['', Validators.required],
      tipoTanqueFormControl: ['', Validators.required],
      numeroCertificadoFormControl: ['', Validators.required],
      volumenInextraibleControl: ['', Validators.required],
      toleranciaMaximaFormControl: ['', Validators.required],
      minimoHorasReposoFormControl: ['', Validators.required],
      llenoVacioFormControl: ['', Validators.required]
    });
  }


  inicializarComponente() {
    if (this.tanque == null) {
      this.editando = false;
      this.obtenerProximoCodigoSurtidor();
      this.cargarDatosDefecto();
    } else {
      this.editando = true;
      this.cargarDatosTanque();
      this.getSurtidores();
      this.getGrupoTanques();
    }

    this.guardando = false;
    this.errorProductos = false;
  }

  cargarDatosDefecto() {
    this.tipoTanqueFormControl.setValue(this.enumTipoTanque.CilindroVertical);
  }

  cargarDatosTanque() {
    this.codigoFormControl.setValue(this.tanque.codigo);
    this.codigoFormControl.disable();
    this.nombreFormControl.setValue(this.tanque.nombre);
    this.productoFormControl.setValue(this.tanque.productoActual);
    this.capacidadFormControl.setValue(this.tanque.capacidad);
    this.tipoTanqueFormControl.setValue(this.tanque.formaTanque);
    this.numeroCertificadoFormControl.setValue(this.tanque.numeroCertificado);
    this.volumenInextraibleControl.setValue(this.tanque.volumenInextraible);
    this.toleranciaMaximaFormControl.setValue(this.tanque.toleranciaMaxima);
    this.minimoHorasReposoFormControl.setValue(this.tanque.minimoHorasReposo);
    if (this.tanque.porVacio) {
      this.llenoVacioFormControl.setValue("0");
    }
    else {
      this.llenoVacioFormControl.setValue("1");
    }
  }

  // llamadas al service

  getGrupoTanques() {
    this.cargandoTanques = true;
    this.grupoService.getGrupoDeTanquesPorAeroplanta(this.usuarioService.getAeroplantaActual().codigoAeroplanta)
      .subscribe(result => {
        this.grupoTanques = result;
        this.cargandoTanques = false;
      })
  }

  getSurtidores() {
    this.cargandoSurtidores = true;
    this.surtidorService.getSurtidoresPorAeroplanta(this.usuarioService.getAeroplantaActual().codigoAeroplanta)
      .subscribe(result => {
        this.surtidores = result;
        this.cargandoSurtidores = false;
      })
  }

  cargarProductos(): void {
    this.cargandoProductos = true;
    this.productoService.obtenerProductos()
      .subscribe(productos => {
        this.productos = productos;
        this.productos = this.productos.filter(p => p.rubro.aerocombustible || p.rubro.combustible);
        this.productos = this.productos.filter(a => a.activo)
        if (!this.editando) { this.productoFormControl.setValue(this.productos[0]); };
        this.cargandoProductos = false;
      }, () => this.errorProductos = true);
  }

  onSubmit() {
    this.verificarTipoMedicion(),
    this.guardando = true;

    if (!this.editando) {
      this.tanque = new Tanque();
    }

    this.tanque.nombre = this.nombreFormControl.value;
    this.tanque.productoActual = this.stockService.convertirProducto(this.productoFormControl.value);
    this.tanque.codigo = this.codigoFormControl.value;
    this.tanque.capacidad = parseFloat(this.capacidadFormControl.value);
    this.tanque.formaTanque = this.tipoTanqueFormControl.value;
    this.tanque.numeroCertificado = this.numeroCertificadoFormControl.value;
    this.tanque.volumenInextraible = parseFloat(this.volumenInextraibleControl.value);
    this.tanque.toleranciaMaxima = parseFloat(this.toleranciaMaximaFormControl.value);
    this.tanque.minimoHorasReposo = parseFloat(this.minimoHorasReposoFormControl.value);
    this.tanque.porVacio = this.esVacio(parseInt(this.llenoVacioFormControl.value));

    if (!this.editando) {
      this.tanque.activo = true;
      this.tanqueService.crearTanque(this.aeroplanta.codigoAeroplanta, this.tanque)
        .subscribe(tanque => {
          this.messageService.showSuccessMessage('Se creó correctamente el tanque.');
          this.activeModal.close(tanque);
        });
    } else {
      this.tanqueService.editarTanque(this.aeroplanta.codigoAeroplanta, this.tanque)
        .subscribe(tanque => {
          this.messageService.showSuccessMessage('Se editó correctamente el tanque.');
          this.activeModal.close(tanque);
        });
    }
  }

  obtenerProximoCodigoSurtidor() {
    this.surtidorService.obtenerProximoCodigoDeTanque(this.aeroplanta.codigoAeroplanta, 1)
      .subscribe(result => {
        this.codigoAutogenerado = result.codigo;
        this.codigoFormControl.setValue(this.codigoAutogenerado);
      });
  }

  // form

  keysTipoTanque(): Array<string> {
    var keys = Object.keys(this.enumTipoTanque);
    return keys.slice(keys.length / 2);
  }

  getDescripcionTipoTanque(tipoTanque: number): String {
    let tiposTanque: Array<string> = this.keysTipoTanque();

    return tiposTanque[tipoTanque];
  }

  esVacio(numero: number): boolean {
    let es: boolean = false;
    if (numero === 0) {
      es = true;
    }
    return es;
  }

  convertirVacio(v: boolean): number {
    let numero: number = 0;
    if (v === false) {
      numero = 1;
    }
    return numero;
  }

  // validaciones

  chequearCodigo() {
    this.existeCodigo = false;
    if (!this.editando) {
      for (let i = 0; this.arrayTanques.length > i; i++) {
        if (this.arrayTanques[i].codigo == this.codigoFormControl.value) {
          this.existeCodigo = true;
          this.codigoFormControl.setErrors({ 'incorrect': true });
        }
      }
    }
    return this.existeCodigo;
  }

  chequearCertificado() {
    this.existeCodigo = false;
    if (!this.editando) {
      for (let i = 0; this.arrayTanques.length > i; i++) {
        if (this.arrayTanques[i].numeroCertificado == this.numeroCertificadoFormControl.value) {
          this.existeCodigo = true;
          this.numeroCertificadoFormControl.setErrors({ 'incorrect': true });
        }
      }
    }
    return this.existeCodigo;
  }

  validarCambioProductoTanque() {
    this.productoNoEditable = false;
    if (this.editando) {
      this.surtidores.forEach((value, index) => {
        this.surtidores[index].tanques.forEach((value2, index2) => {
          if (this.surtidores[index].tanques[index2].codigo == this.tanque.codigo) {
            this.productoNoEditable = true;
          }
        });
      });

      this.grupoTanques.forEach((value, index) => {
        this.grupoTanques[index].tanques.forEach((value2, index2) => {
          if (this.grupoTanques[index].tanques[index2].codigo == this.tanque.codigo)
            this.productoNoEditable = true;
        });
      });
      if (this.productoNoEditable) {
        this.messageService.showErrorMessage('No se puede cambiar el producto porque el tanque esta en un grupo o en surtidores.')
        this.productoFormControl.setValue(this.tanque.productoActual);
      }
    }
  }

  compareProductos(p1: any, p2: any): boolean {
    return p1 && p2 ? p1.codigoProducto === p2.codigoProducto : p1 === p2;
  }


  onlyDecimalPositivo(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46;
  }

  capacidadMayorQueVolumenInextraible() {
    if(Number(this.capacidadFormControl.value) < Number(this.volumenInextraibleControl.value)) {
      this.capacidadMayor = false;
    } else this.capacidadMayor = true;
  }

  verificarTipoMedicion() {
   let codigoTanque: string = this.codigoFormControl.value;
   let tipoMedicionEsVacio: boolean = this.llenoVacioFormControl.value == 0 ? false : true;
   this.stockService.verificarTipoMedicionTanque(codigoTanque, tipoMedicionEsVacio).subscribe(() => {},
   (errorModel: ErrorModel) => {
      this.messageService.showWarningMessage('El tipo de medición no coincide con la lista de equivalencias.', false);
   });
  }

  // getters

  get codigoFormControl() {
    return this.form.controls.codigoFormControl;
  }

  get nombreFormControl() {
    return this.form.controls.nombreFormControl;
  }

  get productoFormControl() {
    return this.form.controls.productoFormControl;
  }

  get capacidadFormControl() {
    return this.form.controls.capacidadFormControl;
  }

  get tipoTanqueFormControl() {
    return this.form.controls.tipoTanqueFormControl;
  }

  get numeroCertificadoFormControl() {
    return this.form.controls.numeroCertificadoFormControl;
  }

  get volumenInextraibleControl() {
    return this.form.controls.volumenInextraibleControl;
  }

  get toleranciaMaximaFormControl() {
    return this.form.controls.toleranciaMaximaFormControl;
  }

  get minimoHorasReposoFormControl() {
    return this.form.controls.minimoHorasReposoFormControl;
  }

  get activoFormControl() {
    return this.form.controls.activoFormControl;
  }

  get llenoVacioFormControl() {
    return this.form.controls.llenoVacioFormControl;
  }
}
