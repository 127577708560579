export class PreautorizacionDTO {
    id:	                string;
    ctA_SGC:	        string;
    cuentaDescripcion:	string;
    matricula:	        string;
    usuarioResponsable:	string;
    fechaHora:	        string;
    fechaHoraDesde:	    string;
    fechaHoraHasta:	    string;
    fechaHoraAnulacion:	string;
    codigoAeroplanta:	string;
}