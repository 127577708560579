<div class="modal-header">
    <h4 class="modal-title">Justificacion</h4>
</div>
<div class="modal-body">
    <form [formGroup]="form">  
        <div>
            <textarea formControlName="justificacionFormControl" cols="55" rows="4">
            </textarea>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button (click)="guardarJustificacion(false)" [disabled]="this.justificacionFormControl.value" class="btn btn-success">Aceptar sin justificar</button>
    <button (click)="guardarJustificacion(true)" [disabled]="!this.justificacionFormControl.value" class="btn btn-primary">Aceptar</button>
    <button (click)="this.activeModal.close(false)" class="btn btn-secondary">Cancelar</button>
</div>