<form (ngSubmit)="onSubmit()">

  <div class="modal-header bg-light">
    <h4 class="modal-title">{{ editando ? "Modificar abastecedora" : "Nueva abastecedora" }}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()"></button>
  </div>

  <div class="modal-body">

    <div [formGroup]="form">
      <div class="row">
        <div class="mb-3 row col-md-12">
          <label for="codigoFormControl" class="col-md-5 col-form-label">*Codigo:</label>
          <div class="col-md-7">
            <input *ngIf="this.codigoAutogenerado!=null" class="form-control" formControlName="codigoFormControl" value="this.codigoFormControl.value" maxlength="50" disabled>
            <div
              *ngIf="codigoFormControl.invalid && codigoFormControl.errors.required && (codigoFormControl.dirty || codigoFormControl.touched)"
              class="alert alert-danger">Requerido</div>
            </div>
        </div>
      </div>

      <div class="row">
        <div class="mb-3 row col-md-12">
          <label for="nombreFormControl" class="col-md-5 col-form-label">*Nombre:</label>
          <div class="col-md-7">
            <input class="form-control" formControlName="nombreFormControl" maxlength="50">
            <div
              *ngIf="nombreFormControl.invalid && nombreFormControl.errors.required && (nombreFormControl.dirty || nombreFormControl.touched)"
              class="alert alert-danger">Requerido</div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="mb-3 row col-md-12">
          <label for="patenteFormControl" class="col-md-5 col-form-label">*Patente:</label>
          <div class="col-md-7">
            <input class="form-control" formControlName="patenteFormControl" maxlength="50">
            <div
              *ngIf="patenteFormControl.invalid && patenteFormControl.errors.required && (patenteFormControl.dirty || patenteFormControl.touched)"
              class="alert alert-danger">Requerido</div>
            <div *ngIf="this.chequearPatente()" class="alert alert-danger">La patente esta en uso.</div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="mb-3 row col-md-12">
          <label for="productoFormControl" class="col-md-5 col-form-label">Producto</label>
          <div class="col-md-7">
            <select [compareWith]="compareProductos" id="productoFormControl" class="form-select"
              ngbTooltip="Seleccione el producto." formControlName="productoFormControl">
              <option *ngFor="let producto of productos" [ngValue]="producto">
                ({{ producto.codigoProducto }}) - {{ producto.nombreProducto }}
              </option>
            </select>
            <loading-text-spinner *ngIf="cargandoProductos" [error]="errorProductos" message="Obteniendo productos..."
              color="primary">
            </loading-text-spinner>
            <div class="alert alert-danger"
              *ngIf="(this.productoFormControl.invalid && this.productoFormControl.errors.required && (this.productoFormControl.dirty || this.productoFormControl.touched))">
              Debe seleccionar un producto obligatoriamente.
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="mb-3 row col-md-12">
          <label for="capacidadFormControl" class="col-md-5 col-form-label">*Capacidad:</label>
          <div class="col-md-7 input-group">
            <input class="form-control" formControlName="capacidadFormControl" maxlength="12"
              (keypress)="this.onlyDecimalPositivo($event)">
              <span class="input-group-text">Lts.</span>
            <div
              *ngIf="capacidadFormControl.invalid && capacidadFormControl.errors.required && (capacidadFormControl.dirty || codigoFormControl.touched)"
              class="col-md-12 alert alert-danger">Requerido</div>
          </div>

        </div>
      </div>
    </div>

    <!--PICOS-->
    <div [formGroup]="picoForm" style="padding: 1em; border: 1px solid #ccc;">

      <div class="row">
        <div class="mb-3 row col-md-12">
          <label for="nombrePicoFormControl" class="col-sm-2 col-form-label">*Nombre</label>
          <div class="col-sm-7">
            <input class="form-control" formControlName="nombrePicoFormControl" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="mb-3 row col-md-12">
          <label for="aforadorPicoFormControl" class="col-sm-2 col-form-label">*Aforador</label>
          <div class="col-sm-7">
            <input class="form-control" (keypress)="onlyDecimalPositivo($event)"
              formControlName="aforadorPicoFormControl" />
          </div>
          <div class="col-sm-3">
            <button type="button" (click)="asignarPico()" class="btn btn-primary">Asignar</button>
          </div>
        </div>
      </div>
      <!--CARTER-->
      <div class="row">
        <div class="col-sm-4">
          <div class="mb-3 row">
            <div class="col-sm-8">
              <div class="form-check">
                <input type="radio" class="form-check-input" id="exampleCheck1" value="0"
                  formControlName="tipoPicoFormControl" />
                <label class="form-check-label" for="exampleCheck1">Carter</label>
              </div>
            </div>
          </div>
        </div>
        <!--GATILLO-->
        <div class="col-sm-4">
          <div class="mb-3 row">
            <div class="col-sm-8">
              <div class="form-check">
                <input type="radio" class="form-check-input" id="exampleCheck2" value="1"
                  formControlName="tipoPicoFormControl" />
                <label class="form-check-label" for="exampleCheck2">Gatillo</label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="mb-3 row col-12">
          <div class="col-12"><b>Aforadores asignados</b></div>
        </div>
      </div>
      <div class="row" *ngIf="this.picos != null && this.picos.length > 0">
        <div class="mb-3 row col-12" *ngFor="let pic of this.picos">
          <div class="col-4">{{pic.nombre}} - {{pic.aforadorActual}}</div>
          <label class="col-2" for="checkDefecto">Principal</label>
          <input class="col-3" type="checkbox" (click)="checkearDefecto(pic)" [checked]="pic.porDefecto" id="checkDefecto"/>
          <div class="col-3"><button type="button" (click)="desasignarPico(pic)" class="btn btn-danger btn-sm col-12"><i
                class="fa fa-trash col-12" aria-hidden="true"></i></button></div>
        </div>
      </div>

      <div *ngIf="this.picos == null || this.picos.length == 0" class="row">
        <div class="mb-3 row col-md-12">
          <div class="col-sm-12">No hay aforadores asignados.</div>
        </div>
      </div>
    </div>

    <small class="text-danger"> *Campos obligatorios.</small>
  </div>
  <div class="modal-footer bg-light">
    <span class="border">
      <button class="btn btn-primary" [disabled]="!form.valid || guardando || this.picos.length == 0" type="submit">
        {{ guardando? "Guardando..." : "Guardar cambios" }}
      </button>
    </span>
    <span class="border">
      <button type="button" class="btn btn-secondary" [disabled]="guardando"
        (click)="activeModal.dismiss('Close clicked')">Cancelar</button>
    </span>
  </div>
</form>
