import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { UsuarioService } from 'src/app/account/usuario.service';
import { ComprobanteService } from 'src/app/facturacion/comprobante.service';
import { MessageService } from 'src/app/shell/message.service';
import { TransaccionesService } from 'src/app/stock/transacciones.service';
import { ReporteMovimiento } from '../models/cierreTurno/ReporteMovimiento';
import { Aeroplanta } from '../models/despacho/Aeroplanta';
import { ErrorModel } from '../models/ErrorModel';
import { Comprobante } from '../models/facturacion/Comprobante';
import { ComprobanteSalida } from '../models/stock/ComprobanteSalida';
import { Transaccion } from '../models/stock/Transaccion';

@Component({
  selector: 'app-visor-comprobante-cargamento',
  templateUrl: './visor-comprobante-cargamento.component.html',
  styleUrls: ['./visor-comprobante-cargamento.component.css']
})
export class VisorComprobanteCargamentoComponent implements OnInit {
  @Input() movimiento: Transaccion;
  @Input() comp: number;
  @Input() comprobanteEnviado: ReporteMovimiento;

  operadorImagePath;
  clienteImagePath;

  obteniendoComprobante: boolean = false;
  obteniendoAeroplanta: boolean = false;

  aeroplantaActual: Aeroplanta;
  aeroplanta: Aeroplanta[] = [];

  comprobante: ComprobanteSalida = null;
  constructor(private transaccionService: TransaccionesService, private spinner: NgxSpinnerService, private sanitizer: DomSanitizer, private usuarioService: UsuarioService,
    private comprobanteService: ComprobanteService, private messageService: MessageService) { }

  ngOnInit() {
    this.obteniendoComprobante = true;
    this.aeroplantaActual = this.usuarioService.getAeroplantaActual();
    this.obtenerCiudad(this.aeroplantaActual.ciudadId);
    this.obtenerAeroplanta();
    this.spinner.show('spinnerComprobante');
    if(this.movimiento != null){
    this.transaccionService.getTransaccionPorIdConComprobante(this.movimiento.id)
      .subscribe(result => {
        this.comprobante = result;
        this.generarResourceUrls();
        this.spinner.hide('spinnerComprobante');
        this.obteniendoComprobante = false;
      });
    }else{
      this.transaccionService.getTransaccionPorIdConComprobante(this.comprobanteEnviado.transaccionId)
      .subscribe(result => {
        this.comprobante = result;
        this.movimiento = new Transaccion();
        this.movimiento.fechaHora = this.comprobanteEnviado.fechaHora;
        this.generarResourceUrls();
        this.spinner.hide('spinnerComprobante');
        this.obteniendoComprobante = false;
      });
    }
  }

  obtenerAeroplanta() {
    this.obteniendoAeroplanta = true;
    this.comprobanteService.getDatosAeroplanta(this.aeroplantaActual.codigoAeroplanta)
      .subscribe(result => {
        this.aeroplanta = result;
        this.obteniendoAeroplanta = false;
      }, (err: ErrorModel) => {
        this.messageService.showErrorMessage('Hubo un error al obtener los datos de la aeroplanta.')
      });
  }

  ImagenFirmaOperador(): string{
    let firma : string = ""

    if (this.comprobante.remitoAeroespecialidad != null &&  this.comprobante.remitoAeroespecialidad.reciboCargamento.firmaOperador != null){
      firma = this.comprobante.remitoAeroespecialidad.reciboCargamento.firmaOperador;
    }

    if (this.comprobante.remitoCargamento != null && this.comprobante.remitoCargamento.reciboCargamento.firmaOperador != null){
      firma = this.comprobante.remitoCargamento.reciboCargamento.firmaOperador; 
    }

    return firma;
  }

  CompletarPrefijo(prefijo: number) {
    let p = prefijo;
    return p.toString().padStart(5, '0')
  }
  CompletarNumero(numero: number) {
    let n = numero;
    return n.toString().padStart(8, '0')
  }

  ImagenFirmaConductor(): string{
    let firma : string = ""

    if (this.comprobante.remitoAeroespecialidad != null &&  this.comprobante.remitoAeroespecialidad.reciboCargamento.firmaConductor != null){
      firma = this.comprobante.remitoAeroespecialidad.reciboCargamento.firmaConductor;
    }

    if (this.comprobante.remitoCargamento != null && this.comprobante.remitoCargamento.reciboCargamento.firmaConductor != null){
      firma = this.comprobante.remitoCargamento.reciboCargamento.firmaConductor; 
    }

    return firma;
  }

  ImagenRemito(): string{
    let imgRemito : string = "";

    if (this.comprobante.remitoAeroespecialidad != null &&  this.comprobante.remitoAeroespecialidad.pathRemitoFisico != null){
      imgRemito = this.comprobante.remitoAeroespecialidad.pathRemitoFisico; 
    }

    if (this.comprobante.remitoCargamento != null && this.comprobante.remitoCargamento.pathRemitoFisico != null){
      imgRemito = this.comprobante.remitoCargamento.pathRemitoFisico; 
    }
    
    return imgRemito;
  }

  generarResourceUrls() {
    if(this.comprobante.remitoCargamento && this.comprobante.remitoCargamento.reciboCargamento != null){
      this.operadorImagePath = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,' + this.comprobante.remitoCargamento.reciboCargamento.firmaOperador);
      this.clienteImagePath = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,' + this.comprobante.remitoCargamento.reciboCargamento.firmaConductor);
    }    
  }

  obtenerCiudad(id: string){

  }

  set_format_cuit(cuit: string) {
    cuit = cuit.substr(0, 2) + - + cuit.substr(2, 8) + - + cuit.substr(10);
    return (cuit)
  }


}
