<div class="modal-header bg-light">
  <h5 class="modal-title ">Ajuste manual de combustible</h5>
  <button type="button" class="btn-close" aria-label="Close" data-dismiss="modal" (click)="activeModal.close(false)"></button>
</div>
<div class="modal-body" [formGroup]="ajusteForm">
  <div class="mb-3 col-12">
    <div class="mb-3 row">
    <loading-text-spinner *ngIf="obteniendoProductos" message="Obteniendo productos..." color="primary"></loading-text-spinner>
    </div>
  </div>
  <div class="mb-3 col-12">
    <div class="mb-3 row">
      <label for="origenFormControl" id="origenFormControl" class="col-4 col-form-label">* Contenedor:</label>
      <div class="col-8">
        <div class="input-group mb-2">
          <select id="origenFormControl" type="text" class="form-select" (change)="onUsuarioSeleccionoContenedor()"
            title="Seleccione el origen desde el cual enviar el combustible." formControlName="origenFormControl">
            <option [ngValue]="c" *ngFor="let c of contenedores">
              {{c.nombre}}
            </option>
          </select>
        </div>
        <loading-text-spinner *ngIf="obteniendoContenedores" [error]="error"
          message="Obteniendo contenedores..." color="primary">
        </loading-text-spinner>
      </div>
    </div>
  </div>

  <div class="mb-3 col-12">
    <div class="mb-3 row" *ngIf="contenedorSeleccionado != null">
      <label for="origenFormControl" id="origenFormControl" class="col-4 col-form-label"> Stock :</label>
      <div class="col-8">
        <div class="p-2" style="width: 35%!important; background-color: #eee;">
          {{ contenedorSeleccionado.stockActual }}
        </div>        
      </div>
    </div>
  </div>

  <div class="mb-3 col-12">
    <div class="mb-3 row" *ngIf="contenedorSeleccionado != null">
      <label for="origenFormControl" id="origenFormControl" class="col-4 col-form-label"> Stock 15:</label>
      <div class="col-8">
        <div class="p-2" style="width: 35%!important; background-color: #eee;">
          {{ contenedorSeleccionado.stockActual15 }}
        </div>        
      </div>
    </div>
  </div>

  <div class="mb-3 col-md-12">
    <div class="mb-3 row">
      <label for="motivoFormControl" class="col-md-4 col-form-label">* Motivo:</label>
      <div class="col-md-8">
        <select id="motivoFormControl" class="form-select"
          ngbTooltip="Seleccione un motivo." formControlName="motivoFormControl" >
          <option *ngFor="let m of motivos" [ngValue]="m">
            ({{ m.descripcion }}
          </option>
        </select>
        <loading-text-spinner *ngIf="obteniendoMotivos" message="Obteniendo motivos..." color="primary">
        </loading-text-spinner>
        <div class="alert alert-danger"
          *ngIf="(this.motivoFormControl.invalid && this.motivoFormControl.errors.required && (this.motivoFormControl.dirty || this.motivoFormControl.touched))">
          Debe seleccionar una terminal obligatoriamente.
        </div>
      </div>
    </div>
  </div>

  <!-- CANTIDAD-->
  <div class="mb-3 col-12">
    <div class="mb-3 row">
      <label for="cantidadFormControl" id="labelCantidad" class="col-4 col-form-label">* Cantidad:</label>
      <div class="col-8">
        <input class="form-control" formControlName="cantidadFormControl" (keypress)="this.onlyDecimal($event)"
          (blur)="recalcularVolumenNatural()">
      </div>
    </div>
  </div>
  <!--TEMPERATURA-->
  <div class="mb-3 col-12">
    <div class="mb-3 row">
      <label for="temperaturaFormControl" id="labelDensidad" class="col-4 col-form-label">* Temperatura:</label>
      <div class="col-8">
        <input class="form-control" formControlName="temperaturaFormControl"
          (blur)="recalcularVolumenNatural()">
          <div *ngIf="temperaturaFormControl.invalid && temperaturaFormControl.errors.required &&
            (temperaturaFormControl.dirty || temperaturaFormControl.touched)" class="alert alert-danger">Requerido
          </div>
          <div class="alert alert-danger"
            *ngIf="temperaturaFormControl.invalid && (temperaturaFormControl.errors.temperaturaFueraDeRango) && (temperaturaFormControl.dirty || temperaturaFormControl.touched)">
            La temperatura esta fuera de rango permitido.
          </div>
      </div>
    </div>
  </div>
  <!--DENSIDAD-->
  <div class="mb-3 col-12">
    <div class="mb-3 row">
      <label for="densidadFormControl" id="labelDensidad" class="col-4 col-form-label">* Densidad:</label>
      <div class="col-8">
        <input class="form-control" formControlName="densidadFormControl" (keypress)="formatDensidad($event)"
          (input)="recalcularVolumenNatural()">
        <div class="alert alert-danger letra"
        *ngIf="(this.densidadFormControl.dirty || this.densidadFormControl.touched) && (!this.validarDensidad(this.densidadFormControl.value))">
        La densidad debe estar entre el rango [ {{this.productoSeleccionado.densidadMin}} , {{this.productoSeleccionado.densidadMax}} ]</div>
      </div>
    </div>
  </div>
  <!--VOLUMEN 15°-->
  <div class="mb-3 col-12">
    <div class="mb-3 row">
      <label for="volumen15FormControl" id="labelDensidad" class="col-4 col-form-label">Cantidad 15°:</label>
      <div class="col-8">
        <input class="form-control" formControlName="volumen15FormControl">
      </div>
    </div>
  </div>

  <div class="mb-3 col-12">
    <div class="mb-3 row">
      <div class="col-12 col-form-label">Comentario</div>
    </div>
  </div>

  <div class="mb-3 col-12">
    <div class="mb-3 row">
      <textarea class="form-control col-form-label col-md-12" formControlName="comentarioFormControl" rows='5'></textarea>
    </div>
  </div>
</div>
<div class="modal-footer bg-light">
  <div class="alert alert-danger" *ngIf="error != null">
    {{ error }}
  </div>
  <div class="mb-3 col-12">
    <div class="d-flex justify-content-md-center">
      <button class="btn btn-primary me-2" id="aceptar" [disabled]="obteniendoProductos || !ajusteForm.valid || guardandoMovimiento"
        (click)="guardarAjusteManual()">
        <span *ngIf="!guardandoMovimiento">Guardar</span>
        <div *ngIf="guardandoMovimiento" class="d-flex align-items-center">
          <div class="spinner-border text-light spinner-border-sm" role="status"></div>
          <span>Guardando...</span>
        </div>
      </button>
      <button class="btn btn-secondary" id="cancelar" (click)="activeModal.close(false)"
        [disabled]="guardandoMovimiento" data-dismiss="modal">Cancelar</button>
    </div>
  </div>
</div>