import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { Producto } from 'src/app/shared/models/abm/Producto';
import { StockService } from '../../stock.service';
import { NgbDateStruct, NgbCalendar, NgbDatepickerConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Guid } from 'guid-typescript';
import { UsuarioService } from 'src/app/account/usuario.service';
import { ModalTransfenrenciaComponent } from './modal-transfenrencia/modal-transfenrencia.component';
import { UntypedFormControl } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { ProductoService } from 'src/app/abm/producto/producto.service';
import { Transaccion } from 'src/app/shared/models/stock/Transaccion';
import { Contenedor } from 'src/app/shared/models/stock/Contenedor';
import { TipoMovimientoStock } from 'src/app/shared/enums/tipoMovimientoStock';
import { TransaccionesService } from '../../transacciones.service';
import { IngresoDuctoComponent } from '../../listados/ingreso-ducto/ingreso-ducto.component';
import { IngresoDucto } from 'src/app/shared/models/stock/IngresoDucto';
import { MessageService } from 'src/app/shell/message.service';
import { EmisionRemitoComponent } from '../../listados/emision-remito/emision-remito.component';
import { AjusteManualCombustibleComponent } from '../../listados/ajuste-manual-combustible/ajuste-manual-combustible.component';
import { AjusteManualAeroespecialidadesComponent } from '../../listados/ajuste-manual-aeroespecialidades/ajuste-manual-aeroespecialidades.component';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-movimientos',
  templateUrl: './movimientos.component.html',
  styleUrls: ['./movimientos.component.css']
})
export class MovimientosComponent implements OnInit {
  productos: Producto[] = [];
  obteniendoMovimientos = true;
  modelDesde: NgbDateStruct;
  modelHasta: NgbDateStruct;
  fechaDesde: Date;
  fechaHasta: Date;
  contenedores: Contenedor[];
  transaccion: Transaccion[] = [];
  transaccionesFiltrados: Transaccion[];
  productosParaCheckear: any[] = [];
  contenedoresParaCheckear: any[] = [];
  fechaFormControlDesdeHasta = new UntypedFormControl();
  errorMovimientos = false;
  change$: Subscription;  
  detalleDeMovimiento: boolean = false;
  transaccionSeleccionada: Transaccion;
  tipoMovimientos: any[] = [
    { "nombre": 'Despacho', "codigo": 0, "seleccionado": false },
    { "nombre": 'Ajuste Manual', "codigo": 2, "seleccionado": false },
    { "nombre": 'Transferencia', "codigo": 3, "seleccionado": false },
    { "nombre": 'Rebombeo', "codigo": 4, "seleccionado": false },
    { "nombre": 'Alije', "codigo": 5, "seleccionado": false },
    { "nombre": 'Recepción', "codigo": 6, "seleccionado": false },
    { "nombre": 'Salida', "codigo": 8, "seleccionado": false }
  ];

  TipoUsuario = TipoUsuario;
  
  constructor(private authService: AuthService, 
    private messageService: MessageService, 
    private stockService: StockService, 
    private calendar: NgbCalendar, 
    private usuarioService: UsuarioService,
    private datepickerConfig: NgbDatepickerConfig, 
    private spinner: NgxSpinnerService, 
    @Inject(LOCALE_ID) public locale: string,
    private modalService: NgbModal, 
    private productoService: ProductoService, 
    private transaccionesService: TransaccionesService,
    sharedService: SharedService) {
      this.change$ = sharedService.aeroplantaSeleccionada.subscribe(_=>{
        this.recargarPagina()
      })
    }

  // hooks

  ngOnInit() {
    this.transaccionesFiltrados = [];
    this.transaccion = [];
    this.spinner.show('spinnerLista');
    this.errorMovimientos = false;
    this.getTodosLosProductos();
    this.cargarCheckTipoOrigen();
    this.modelDesde = this.calendar.getToday();
    this.fechaDesde = new Date(this.modelDesde.year, this.modelDesde.month - 1, this.modelDesde.day);
    this.modelHasta = this.calendar.getToday();
    this.fechaHasta = new Date(this.modelHasta.year, this.modelHasta.month - 1, this.modelHasta.day);
    this.reinicioFechaInicioFechaFin();

    this.datepickerConfig.minDate = { day: 1, month: 1, year: 2019 };    
    this.datepickerConfig.maxDate = { day: 31, month: 12, year: 2050 };
    this.getTodosLosMovimientos(this.usuarioService.getAeroplantaActual().codigoAeroplanta, this.fechaDesde, this.fechaHasta);
  }

  // form

  formatDate(year, month, day, hour, minute): string {
    return year + '-' + month.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + '-' + day.toLocaleString(undefined, { minimumIntegerDigits: 2 })
      + 'T' + hour.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + minute.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':00';
  }

  cargarCheckTipoOrigen() {    
    this.stockService.getTodosLosContenedores(this.usuarioService.getAeroplantaActual().codigoAeroplanta)
      .subscribe(result => {
        this.contenedores = result;
        for (let cont of this.contenedores) {
          this.contenedoresParaCheckear.push({ "nombre": cont.nombre, "codigo": cont.codigo, "seleccionado": false });
        }
      })
  }

  buscarDesdeHasta() {
    this.transaccion = [];
    this.transaccionesFiltrados = [];
    this.obteniendoMovimientos = true;
    this.reinicioFechaInicioFechaFin();
    return this.getTodosLosMovimientos(this.usuarioService.getAeroplantaActual().codigoAeroplanta, this.fechaDesde, this.fechaHasta);
  }

  nuevoIngresoDucto() {
    let modalRef = this.modalService.open(IngresoDuctoComponent, { centered: true, backdrop: 'static' });
    modalRef.result.then((result: IngresoDucto) => {
      if (result) {
        this.messageService.showSuccessMessage('Se realizo el ingreso por ducto correctamente.');
        this.recargarPagina();
      }

    });
  }

  getTodosLosMovimientos(aeroplantaCod: string, fechaDesde: Date, fechaHasta: Date) {
    let desde: string = this.formatDate(fechaDesde.getUTCFullYear(), fechaDesde.getMonth() + 1, fechaDesde.getDate(), 0, 0);
    let hasta: string = this.formatDate(fechaHasta.getUTCFullYear(), fechaHasta.getMonth() + 1, fechaHasta.getDate(), 23, 59);
    this.obteniendoMovimientos = true;
    this.spinner.show('spinnerLista');
    this.transaccionesService.getTransacciones(aeroplantaCod, desde, hasta)
      .subscribe(result => {
        this.transaccion = result.sort((a, b) => new Date(b.fechaHora).getTime() - new Date(a.fechaHora).getTime());
        this.transaccionesFiltrados = result.sort((a, b) => new Date(b.fechaHora).getTime() - new Date(a.fechaHora).getTime());
        this.filtrarMovimientos();
        this.obteniendoMovimientos = false;
        this.spinner.hide('spinnerLista');
      },
        () => {
          this.errorMovimientos = true;
          this.obteniendoMovimientos = false;
          this.spinner.hide('spinnerLista');
          this.transaccion = [];
          this.transaccionesFiltrados = [];
        }
      )

  }

  obtenerTipoMovimiento(tipo: number) {
    return TipoMovimientoStock[tipo];
  }

  filtrarMovimientos() {
    this.obteniendoMovimientos = true;
    this.transaccionesFiltrados = this.transaccion.filter(a => {
      let agregar = true;
      if (this.productosParaCheckear.some(b => b.seleccionado))
        agregar = agregar && this.productosParaCheckear.some(
          b => (b.seleccionado && true === this.obtenerFiltroPorProducto(a, b.codigo)));

      if (this.contenedoresParaCheckear.some(to => to.seleccionado))
        agregar = agregar && this.contenedoresParaCheckear.some(
          to => (to.seleccionado && this.obtenerFiltroPorMovimiento(a, to.codigo)));

      if (this.tipoMovimientos.some(c => c.seleccionado))
        agregar = agregar && this.tipoMovimientos.some(
          c => (c.seleccionado && c.codigo === a.tipoTransaccion));

      return agregar;
    });
    this.obteniendoMovimientos = false;
  }

  obtenerFiltroPorProducto(a: Transaccion, t: string): boolean {
    let aux: boolean = false;
    for (let i = 0; a.productos.length > i; i++) {
      if (a.productos[i] != null) {
        if (a.productos[i].codigoProducto == t) {
          aux = true;
        }
      }
    }
    return aux
  }

  obtenerFiltroPorMovimiento(a: Transaccion, t: string): boolean {
    let aux: boolean = false;
    for (let i = 0; a.movimientos.length > i; i++) {
      if (a.movimientos[i].contenedor.codigo == t) {
        aux = true;
      }
    }
    return aux
  }

  reinicioFechaInicioFechaFin() {
    this.fechaDesde = new Date(this.modelDesde.year, this.modelDesde.month - 1, this.modelDesde.day);
    this.fechaHasta = new Date(this.modelHasta.year, this.modelHasta.month - 1, this.modelHasta.day);
    this.fechaHasta.setHours(23, 59, 59, 99);
  }

  fechaDesdeMayorQueHasta() {
    let esMayor: boolean = false;
    this.fechaDesde = new Date(this.modelDesde.year, this.modelDesde.month - 1, this.modelDesde.day);
    this.fechaHasta = new Date(this.modelHasta.year, this.modelHasta.month - 1, this.modelHasta.day);
    if (this.fechaDesde > this.fechaHasta) { esMayor = true; }
    return esMayor;
  }
  recargarPagina() {
    this.productosParaCheckear = [];    
    this.errorMovimientos = false;

    setTimeout(() => {
      this.spinner.show('spinnerLista');
    });

    this.obteniendoMovimientos = true;
    this.ngOnInit();
    this.limpiarCampos();
  }

  ngOnDestroy() {
    this.change$.unsubscribe();
    this.transaccionesFiltrados = [];
    this.transaccion = [];
  }

  keysMovimientos(): Array<string> {
    var keys = Object.keys(this.tipoMovimientos);
    keys = keys.slice(keys.length / 2);
    return keys;
  }

  limpiarCampos() {
    this.transaccionesFiltrados = [];
    this.spinner.hide("spinnerLista");
    this.spinner.hide("spinnerDetalle");
    this.modelDesde = this.calendar.getToday();
    this.modelHasta = this.calendar.getToday();
    // limpio filtro check tipo origen
    for (let tipo of this.contenedoresParaCheckear) {
      tipo.seleccionado = false;
    }
    for (let tipo3 of this.productosParaCheckear) {
      tipo3.seleccionado = false;
    }
  }

  mostrarDetalle(i: number, content) {
    this.detalleDeMovimiento = true;
    let modalRef = this.modalService.open(content, { centered: true, size: 'lg' });
    this.transaccionSeleccionada = this.transaccionesFiltrados[i];
  }


  nuevoIngresoTrasvase() {
    let modalRef = this.modalService.open(ModalTransfenrenciaComponent, { centered: true, backdrop: 'static' });
    modalRef.componentInstance.resultado.subscribe(result => {
      if (result == true) {
        this.recargarPagina();
      }
    });
  }

  emisionDeRemito() {
    let modalRef = this.modalService.open(EmisionRemitoComponent, { centered: true, backdrop: 'static', size: 'lg' });
    modalRef.result.then((result: boolean) => {
      if (result) {
        this.messageService.showSuccessMessage('Se realizo el remito correctamente.');
        this.recargarPagina();
      }
    });
  }

  ajusteManualCombustible() {
    let modalRef = this.modalService.open(AjusteManualCombustibleComponent, { centered: true, backdrop: 'static' });
    modalRef.result.then((result: boolean) => {
      if (result) {
        this.messageService.showSuccessMessage('Se realizo el ajuste manual correctamente.');
        this.recargarPagina();
      }
    });
  }

  ajusteManualAeroespecialidad(){
    let modalRef = this.modalService.open(AjusteManualAeroespecialidadesComponent, { centered: true, backdrop: 'static' });
    modalRef.result.then((result: boolean) => {
      if (result) {
        this.recargarPagina();
      }
    });
  }
  // llamadas al service

  getTodosLosProductos() {
    this.productos = [];
    this.productoService.obtenerProductos()
      .subscribe(result => {
        this.productos = result;
        this.productosParaCheckear = [];
        for (let prod of this.productos) {
          this.productosParaCheckear.push({ "nombre": prod.nombreProducto, "codigo": prod.codigoProducto, "seleccionado": false });
        }
      });
  }

  hasRole(...roles: TipoUsuario[]) {
    return this.authService.hasRole(...roles);
  }
}
