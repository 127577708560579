import { Component, OnInit, OnDestroy, Output, EventEmitter, ViewEncapsulation, NgModule } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { UsuarioService } from 'src/app/account/usuario.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfirmComponent } from 'src/app/shared/generic-modals/modal-confirm.component';
import { Usuario } from 'src/app/shared/models/despacho/Usuario';
import { Aeroplanta } from 'src/app/shared/models/despacho/Aeroplanta';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { ModalAsociarAeroplantaComponent } from 'src/app/shared/components/modal-asociar-aeroplanta/modal-asociar-aeroplanta.component';
import { CommonModule } from '@angular/common';
import { isDevMode } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EstadoUsuario } from 'src/app/shared/enums/estadoUsuario';
import { AccionLogin } from 'src/app/shared/enums/AccionLogin';
import { MessageService } from '../message.service';
import { NotificacionesUsuarioComponent } from '../notificaciones-usuario/notificaciones-usuario.component';
import { SignalRService } from 'src/app/shared/signal-r.service';
import { NotificacionesService } from 'src/app/abm/notificacion/notificaciones.service';
import { SharedService } from 'src/app/shared/shared.service';
/*
@NgModule({
  imports: [CommonModule]
})
*/
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {
    
  /** Evento para 'avisar' al shell que se terminó la carga de aeroplantas */
  @Output() cargaTerminada: EventEmitter<boolean> = new EventEmitter<boolean>();

  TipoUsuario = TipoUsuario;

  enumAccionLogin = AccionLogin;
  seteandoAeroplanta: boolean = false
  usuario: Usuario;
  aeroplantas: Aeroplanta[];
  nombresOperador: string;
  tipoUsuario: string;
  isAuthenticated: boolean;
  accionLogin: AccionLogin;

  isAuthenticatedSubscription: Subscription;
  usuarioSubscription: Subscription;
  accionLoginSubscription: Subscription;

  notificacionesNoLeidas: number = 0;
  notificacionNavbarSubscription: Subscription;

  entornoProduccion: boolean = (environment.name == "production");

  navBarForm = new UntypedFormGroup({
    aeroplantaFormControl: new UntypedFormControl(null, Validators.required)
  });

  constructor(  public authService: AuthService, 
                private usuarioService: UsuarioService, 
                private notificacionesService: NotificacionesService,
                private modalService: NgbModal, 
                private messageService: MessageService,
                private signalRService: SignalRService) {

    this.accionLoginSubscription = this.authService.authAccionLogin$.subscribe(accionLogin => {
      if (accionLogin)
        this.accionLogin = accionLogin;
    });

    this.notificacionNavbarSubscription = this.signalRService.cantidadNuevasNotificaciones$().subscribe(notificacion => {
      if(usuarioService.getIdUsuario() && notificacion.usuarios.includes(usuarioService.getIdUsuario())){
        this.notificacionesNoLeidas = Number(notificacion.detalle);
      }
    });
  }

  ngOnInit() {

    this.isAuthenticatedSubscription = this.authService.authNavStatus$.subscribe(status => {
      this.isAuthenticated = status;
    });

    this.usuarioSubscription = this.usuarioService.usuario$.subscribe(usr => {
      if (usr == null) return;

      this.cargaTerminada.next(false);

      this.usuario = usr;
      
      this.iniciarDatosNavbar();
    });

    if (this.authService.isAuthenticated() && this.usuario != null) {
      this.iniciarDatosNavbar();
    }
  }

  iniciarDatosNavbar(){
    this.nombresOperador = this.usuario.nombresCompletos;
    //this.tipoUsuario = this.getDescripcionTipoUsuario(this.usuario.tipoUsuario);
    let string="";
    for (const item of this.usuarioService.getRoles()) {
      string=string.concat(this.getDescripcionTipoUsuario(item)).concat("\n");
    }
    this.tipoUsuario = string;
    this.procesarAeroplantas(this.usuario.aeroplantas);
    this.obtenerCantidadNotificacionesNoLeidas();
  }

  get nombreOperador() {
    if (this.usuarioService.getNombresOperador())
      return this.usuarioService.getNombresOperador();
    else if (this.nombresOperador)
      return this.nombresOperador;
    return '';
  }

  get idAeroplantaActual() {
    const userAep = this.usuarioService.getAeroplantaActual();
    return userAep ? userAep.id : null;
  }

  procesarAeroplantas(aeroplantas) {

    this.aeroplantas = aeroplantas.filter(a => a.validada);
    this.aeroplantas.sort((a, b) => (a.codigoAeroplanta > b.codigoAeroplanta) ? 1 : -1);

    if (this.aeroplantas.length == 0) {
      this.messageService.showErrorMessage("No posee ninguna aeroplanta asociada.");
    } else {
      if (!this.seteandoAeroplanta) {
        this.aeroplantaControl.patchValue(this.aeroplantas[0].codigoAeroplanta);
        this.usuarioService.setAeroplantaInicial(this.aeroplantas[0]);
      }
    }

    this.cargaTerminada.next(true);
  }

  obtenerCantidadNotificacionesNoLeidas(){
    this.notificacionesService.getCantidadNotificacionesNoLeidas()
      .subscribe(cantidadNoLeidas => {
        this.notificacionesNoLeidas = cantidadNoLeidas
      });
  }
  
  setAeroplanta() {
    const aepSeleccionada = this.aeroplantaControl.value;
    this.aeroplantaControl.setValue(aepSeleccionada);
    this.usuarioService.setAeroplanta(aepSeleccionada);    
  }

  showAeroplants() {
    return true;
  }

  get aeroplantaControl() {
    return this.navBarForm.get('aeroplantaFormControl');
  }

  signout() {
    const modalRef = this.modalService.open(ModalConfirmComponent, { centered: true });
    modalRef.componentInstance.mensaje = "¿Realmente desea cerrar sesión?"; // should be the id
    modalRef.componentInstance.textoSi = "Cerrar sesión";
    modalRef.componentInstance.textoNo = "Volver";
    modalRef.result.then(() => {
      this.authService.signout();
      location.assign("#");
    },
      () => {
      }
    );
  }

  getDescripcionTipoUsuario(n) {
    return TipoUsuario[n];
  }

  verNotificaciones(){
    this.notificacionesNoLeidas = 0;
    this.modalService.open(NotificacionesUsuarioComponent, { size: 'lg', windowClass: 'modalNotificaciones', backdrop: 'static' })   
  }

  get mostrarMenu(): boolean {
    return this.isAuthenticated && this.aeroplantas != null;
  }

  ngOnDestroy() {
    // prevent memory leak when component is destroyed
    this.isAuthenticatedSubscription.unsubscribe();
    this.usuarioSubscription.unsubscribe();
  }
}
