export class TotalProducto
{
    codigoProducto: string;
    nombreProducto: string;
    existenciaAnterior: number;
    entradasCargamento: number;
    entradasAlije: number;
    salidasCtaCte: number;
    salidasContado: number;
    salidasConsumoInterno: number;
    salidasRemito: number;
    existenciaFinal: number;
    existenciaReal: number;
    diferencia: number;
    tipoComparativa: number; // 0 => Contenedor15; 1 => ContenedorNatural; 2 => Aeroespecialidad
}