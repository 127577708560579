import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductoService } from 'src/app/abm/producto/producto.service';
import { UsuarioService } from 'src/app/account/usuario.service';
import { TipoContenedor } from 'src/app/shared/enums/tipoContenedor';
import { ModalConfirmComponent } from 'src/app/shared/generic-modals/modal-confirm.component';
import { Producto } from 'src/app/shared/models/despacho/Producto';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { AjusteManualCombustible } from 'src/app/shared/models/stock/AjusteManualCombustible';
import { Contenedor } from 'src/app/shared/models/stock/Contenedor';
import { Motivo } from 'src/app/shared/models/stock/Motivo';
import { Responsable } from 'src/app/shared/models/stock/Responsable';
import { SharedService } from 'src/app/shared/shared.service';
import { MessageService } from 'src/app/shell/message.service';
import { StockService } from '../../stock.service';
import { TransaccionesService } from '../../transacciones.service';
import { CalculosService } from 'src/app/shared/calculos.service';
@Component({
  selector: 'app-ajuste-manual-combustible',
  templateUrl: './ajuste-manual-combustible.component.html',
  styleUrls: ['./ajuste-manual-combustible.component.css']
})
export class AjusteManualCombustibleComponent implements OnInit {

  decimalRegex = /^[0-9]+(.[0-9]{0,4})?$/;
  negativeDecimalRegex = /^[+-]?[0-9]+(.[0-9]{0,4})?$/;
  positiveInteger = /^\+?(0|[1-9]\d*)$/;
  cantidadRegex = /^[-]?[0-9]+(.[0-9]{0,2})?$/;

  aeroplantaCod: string;
  guardandoMovimiento: boolean = false;
  error: string;

  obteniendoContenedores: boolean = false;
  contenedores: Contenedor[] = [];
  contenedorSeleccionado: Contenedor;

  obteniendoProductos: boolean;
  productos: Producto[] = [];
  productoSeleccionado: Producto;

  obteniendoMotivos: boolean;
  motivos: Motivo[] = [];

  ajusteForm = new UntypedFormGroup({
    origenFormControl: new UntypedFormControl("", Validators.required),
    cantidadFormControl: new UntypedFormControl("", Validators.required),
    densidadFormControl: new UntypedFormControl("", Validators.required),
    volumen15FormControl: new UntypedFormControl(Validators.required),
    temperaturaFormControl: new UntypedFormControl("", [Validators.required, this.temperaturaValidator]),
    motivoFormControl: new UntypedFormControl("", Validators.required),
    comentarioFormControl: new UntypedFormControl()
  });

  constructor(public activeModal: NgbActiveModal,
    private stockService: StockService,
    private usuarioService: UsuarioService,
    private transaccionesService: TransaccionesService,
    private messageService: MessageService,
    private productoService: ProductoService,
    private sharedService: SharedService,
    private modalService: NgbModal,
    private calculoService: CalculosService) { }              

  // hooks

  ngOnInit() {
    this.temperaturaFormControl.setValue(0);
    this.densidadFormControl.setValue('0.000');
    this.cantidadFormControl.setValue(0);
    this.volumen15FormControl.setValue(0);
    this.aeroplantaCod = this.usuarioService.getAeroplantaActual().codigoAeroplanta;
    this.getContenedores();
    this.getProductos();
    this.getMotivos();
  }

  // form

  recalcularVolumenNatural() {
    var volumenLts = Number(this.cantidadFormControl.value);
    var dens = Number(this.densidadFormControl.value);
    var temp = Number(this.temperaturaFormControl.value);

    if (volumenLts != null && dens != null && temp != null) {
      let valor = this.calculoService.obtenerCTLNuevo(temp, dens, volumenLts);
      if (!isNaN(valor)) {
        this.volumen15FormControl.setValue(Math.round(valor));
      }else{
        this.volumen15FormControl.setValue(0);
      }
    }
  }

  resetForm() {
    this.temperaturaFormControl.setValue(0);
    this.densidadFormControl.setValue('0.000');
    this.cantidadFormControl.setValue(0);
    this.volumen15FormControl.setValue(0);
    this.error = null;
  }

  armarResponsable() {
    var r = new Responsable();
    r.UserName = this.usuarioService.getUsername();
    r.NombreCompleto = this.usuarioService.getNombresCompletosUsuario();
    r.DNI = this.usuarioService.getDniUsuario();

    return r;
  }

  formatDensidad(event) {
    if (event.charCode == 8 || event.charCode == 0) return null;

    if (event.charCode >= 48 && event.charCode <= 57) {
      this.densidadFormControl.setValue(this.sharedService.formatDensidad(event));
    }
    this.recalcularVolumenNatural();
    return false;
  }


  // llamadas al service

  guardarAjusteManual() {
    if( ! this.validarFormulario() ){
      return;
    }

    var cantidad = this.contenedorSeleccionado.stockActual + Number(this.cantidadFormControl.value);
    var cantidad15 = this.contenedorSeleccionado.stockActual15 + Number(this.volumen15FormControl.value);


    let modalRef = this.modalService.open(ModalConfirmComponent, { centered: true, backdrop: 'static' });
    modalRef.componentInstance.mensaje = "La cantidad ingresada: " + this.cantidadFormControl.value + " Lts. se " + (Number(this.cantidadFormControl.value) >= 0 ? 'sumará al' : "restará del") + " stock actual de " + this.contenedorSeleccionado.stockActual + " Lts. ¿Este es el ajuste que desea realizar?"
    modalRef.result.then(() => {
      var capacidad = this.contenedorSeleccionado.capacidad;
     
      if(capacidad < cantidad){
        let modalRef = this.modalService.open(ModalConfirmComponent, { centered: true, backdrop: 'static' });
        modalRef.componentInstance.mensaje = "El stock del contenedor quedará en " + cantidad + " Litros y excede su capacidad que es de " + capacidad + " Litros. ¿Esta seguro de continuar?"
        modalRef.result.then(() => {
          this.ejecutarAccionAjusteManual(cantidad, cantidad15);
        });
      }else{
        this.ejecutarAccionAjusteManual(cantidad, cantidad15);
      }    
    });    
  }

  ejecutarAccionAjusteManual(cantidad, cantidad15){
    this.guardandoMovimiento = true;
    let ajuste = new AjusteManualCombustible();
    ajuste.ResponsableDTO = this.armarResponsable();
    ajuste.codigoContenedor = this.origenFormControl.value.codigo;
    ajuste.densidad = Number(this.densidadFormControl.value);
    ajuste.temperatura = Number(this.temperaturaFormControl.value);
    ajuste.cantidad = cantidad;
    ajuste.cantidad15 = cantidad15;
    ajuste.observacion = this.comentarioFormControl.value;
    ajuste.motivoId = this.motivoFormControl.value.id;

    this.transaccionesService.realizarAjusteManualCombustible(ajuste)
      .subscribe(result => {
        this.guardandoMovimiento = false;
        this.activeModal.close(true);
      }, (e: ErrorModel) => {
        this.messageService.showErrorMessage('Hubo un error al crear el ajuste manual, intentelo en un momento.')
        this.error = e.description;
        this.guardandoMovimiento = false;
      });
  }

  getContenedores() {
    this.obteniendoContenedores = true;
    this.stockService.getTodosLosContenedores(this.aeroplantaCod)
      .subscribe(result => {
        this.contenedores = result.filter(c => c.tipoContenedor == TipoContenedor.Abastecedora || c.tipoContenedor == TipoContenedor.Tanque)
          .sort((a, b) => a.nombre > b.nombre ? 1 : -1);
        this.origenFormControl.setValue(this.contenedores[0]);
        this.contenedorSeleccionado = this.contenedores[0];
        this.obteniendoContenedores = false;
      }, () => {
        this.obteniendoContenedores = false;
        this.error = "Error al obtener contenedores.";
      });
  }

  getProductos() {
    this.obteniendoProductos = true;
    this.productoService.obtenerProductos()
      .subscribe(result => {
        this.productos = result;
        this.obteniendoProductos = false;
      }, () => {
        this.obteniendoProductos = false;
        this.error = "Error al obtener productos para validar densidad.";
      });
  }

  getMotivos() {
    this.obteniendoMotivos = true;
    this.transaccionesService.getMotivos()
      .subscribe(result => {
        this.motivos = result;
        this.motivoFormControl.setValue(this.motivos[0]);
        this.obteniendoMotivos = false;
      }, () => {
        this.obteniendoMotivos = false;
        this.error = "Error al obtener motivos.";
      });
  }

  // validaciones

  onlyDecimalPositivo(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46;
  }

  onlyDecimal(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode == 45 || event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57);
  }

  validarDensidad(densidad) {
    try {
      if (densidad != null) {
        let esValido = true;
        let p = this.origenFormControl.value;
        this.productoSeleccionado = this.productos.find(element => element.codigoProducto === this.origenFormControl.value.productoActual.codigoProducto);
        esValido = (densidad >= this.productoSeleccionado.densidadMin && densidad <= this.productoSeleccionado.densidadMax);

        return esValido;
      }
    } catch (ex) {
      console.log("Excepcion validacion densidad.");
    }
  }

  temperaturaValidator(control: UntypedFormControl) {
    var temperatura = control.value;

    if (temperatura > 100) {
      return { temperaturaFueraDeRango: true };
    }

    if (temperatura <= -50) {
      return { temperaturaFueraDeRango: true };
    }

    return null;
  }

  validarFormulario() {
    if (!this.decimalRegex.test(this.densidadFormControl.value)) {
      this.error = "El campo densidad debe ser decimal valido.";
      return false;
    }

    if (!this.validarDensidad(this.densidadFormControl.value)) {
      this.error = "La densidad debe estar entre el rango [" + this.productoSeleccionado.densidadMin + "," + this.productoSeleccionado.densidadMax + "]";
      return false;
    }

    if (!this.negativeDecimalRegex.test(this.temperaturaFormControl.value)) {
      this.error = "El campo temperatura debe ser decimal valido.";
      return;
    }

    if (!this.cantidadRegex.test(this.cantidadFormControl.value)) {
      this.error = "El campo cantidad debe ser un numero entero o decimal valido.";
      return ;
    }

    if (!this.cantidadRegex.test(this.volumen15FormControl.value)) {
      this.error = "El campo cantidad 15º debe ser un numero entero o decimal valido.";
      return ;
    }

    return true;
  }

  onUsuarioSeleccionoContenedor(){ 
    this.contenedorSeleccionado = this.origenFormControl.value;
  }
  // getters

  get origenFormControl() {
    return this.ajusteForm.get('origenFormControl');
  }

  get cantidadFormControl() {
    return this.ajusteForm.get('cantidadFormControl');
  }

  get densidadFormControl() {
    return this.ajusteForm.get('densidadFormControl');
  }

  get volumen15FormControl() {
    return this.ajusteForm.get('volumen15FormControl');
  }

  get temperaturaFormControl() {
    return this.ajusteForm.get('temperaturaFormControl');
  }

  get comentarioFormControl() {
    return this.ajusteForm.get('comentarioFormControl');
  }

  get motivoFormControl() {
    return this.ajusteForm.get('motivoFormControl');
  }
}
