<nav class="navbar navbar-light bg-light navbar-expand-lg mb-3">
    <form class="form-inline flex-fill" id="formularioFiltros">
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <ul class="navbar-nav me-auto flex-row">
        <div class="nav-item" [formGroup]="searchForm">
          <input id="busquedaFormControl" type="text" class="form-control input-sm" placeholder="Cliente / producto / lote"
            formControlName="busqueda" (input)="filtrarPrecios()" placeholder="Buscar" />
        </div>
  
        <div class="input-group me-2 ms-4" style="width: auto;">
            <li class="nav-item">
              <input type="date" [ngModel]="modelDesde" class="fechaFormControlDesdeHasta" placeholder="aaaa-mm-dd"
                (ngModelChange)="modelDesde = $event" name="dt" ngbDatepicker #d="ngbDatepicker" value="modelDesde"
                ngbTooltip="Seleccione una fecha de inicio" class="form-control form-control-rounded"
                #searchDate readonly>
            </li>
            <li class="nav-item me-2">
              <div class="input-group-append">
                <button id="botonDesde" type="button" class="input-group-btn btn btn-secondary" (click)="d.toggle()">
                  <i class="fa fa-calendar" aria-hidden="true"></i></button>
              </div>
            </li>
          </div>
          <div class="input-group me-2" style="width: auto;">
            <li class="nav-item">
              <input type="date" [ngModel]="modelHasta" class="fechaFormControlDesdeHasta" placeholder="aaaa-mm-dd"
                (ngModelChange)="modelHasta = $event" name="dt" ngbDatepicker #h="ngbDatepicker" value="modelHasta"
                ngbTooltip="Seleccione una fecha de fin" class="form-control form-control-rounded"
                #searchDate readonly>
            </li>
            <li class="nav-item me-2">
              <div class="input-group-append">
                <button id="botonHasta" type="button" class="input-group-btn btn btn-secondary" (click)="h.toggle()">
                  <i class="fa fa-calendar" aria-hidden="true"></i></button>
              </div>
            </li>
            <li class="nav-item">
              <button (click)="onRefreshClick()" [disabled]="fechaDesdeMayorQueHasta()==true || this.esperandoCarga"
                class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2" type="submit"><i class="fa fa-search"
                  aria-hidden="true"></i></button>
            </li>
        </div>
      </ul>
    </form>

    <div class="mt-2 float-end">
      <button type="button" class="btn btn-outline-primary me-2" (click)="print()">
        <span *ngIf="!this.actualizando">Imprimir</span>                        
      </button>

      <button type="button" class="btn btn-outline-primary me-2" (click)="exportarReporte()">
        <span *ngIf="true">Excel</span>
            <div *ngIf="false" class="d-flex align-items-center">
              <div class="spinner-border text-light spinner-border-sm" role="status"></div>
              <span>Generando excel...</span>
            </div>
      </button>
    </div>
  </nav>
  
  <form class="mb-3">
    <div class="col col-md-12">
      <div class="spinner-container" *ngIf="esperandoCarga">
        <ngx-spinner name="spinnerGrafico" bdOpacity=0.3bd
          Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
          <p style="color: white">{{messagePrecios}}</p>
        </ngx-spinner>
      </div>
  
      <div id="tablaDetalle" class="tabla-precios" *ngIf="!esperandoCarga">
        <div id="tituloPrint">
          Historial de desactivaciones entre {{this.fechaDesde  | date: "dd'/'MM'/'yy"}} y {{this.fechaHasta  | date: "dd'/'MM'/'yy"}}:
        </div>
        <table id="tabla" class="table table-sm table-hover">
          <thead class="text-center letra">
            <tr>
              <th scope="col"> Nivel </th>
              <th scope="col"> Lote </th>
              <th scope="col"> Codigo </th>
              <th scope="col"> Producto </th>
              <th scope="col"> Desde-Hasta</th>
              <th scope="col"> Moneda </th>
              <th scope="col"> Cliente </th>
              <th scope="col"> Tipo Venta </th>
              <th scope="col"> Precio </th>
              <th scope="col"> Entrega </th>
              <th scope="col"> Area </th>
              <th scope="col"> Segmento </th>
              <th scope="col"> Aeroplanta</th>
              <th scope="col"> Fecha/Usuario solicito</th>
              <th scope="col"> Fecha/Usuario desactivo</th>
            </tr>
          </thead>
          <tbody class="text-center letra">
            <tr *ngFor="let p of preciosFiltrados; let i = index">
  
              <td>{{ preciosFiltrados[i].nivelPrecio }}</td>
  
              <td>L{{ preciosFiltrados[i].numeroLote }}</td>
  
              <td> {{preciosFiltrados[i].producto.codigoProducto != null?preciosFiltrados[i].producto.codigoProducto: "--"}}</td>
  
              <td> {{preciosFiltrados[i].producto.nombreProducto != null?preciosFiltrados[i].producto.nombreProducto: "--"}}</td>
  
              <td *ngIf="preciosFiltrados[i].fechaDesde != null && preciosFiltrados[i].fechaHasta != null">
                {{preciosFiltrados[i].fechaDesde | date: "dd'/'MM'/'yy HH:mm:ss"}} -
                {{preciosFiltrados[i].fechaHasta | date: "dd'/'MM'/'yy HH:mm:ss"}}</td>
  
              <td *ngIf="preciosFiltrados[i].fechaHasta == null && preciosFiltrados[i].fechaDesde"> -- </td>
  
              <td> {{preciosFiltrados[i].moneda != null?preciosFiltrados[i].moneda.nombre: "--"}}</td>
  
              <td> {{preciosFiltrados[i].cliente != null?preciosFiltrados[i].cliente.razonSocial: "--"}}</td>
  
              <td *ngIf="preciosFiltrados[i].tipoVenta == null || preciosFiltrados[i].tipoVenta == 0"> - </td>
              <td *ngIf="preciosFiltrados[i].tipoVenta != null && preciosFiltrados[i].tipoVenta == 2"> Internacional</td>
              <td *ngIf="preciosFiltrados[i].tipoVenta != null && preciosFiltrados[i].tipoVenta == 1"> Cabotaje </td>
  
              <td> {{preciosFiltrados[i].precioContado != null?preciosFiltrados[i].precioContado: "--"}}</td>
  
              <td> {{preciosFiltrados[i].direccionEntrega != null?preciosFiltrados[i].direccionEntrega: "--"}}</td>
  
              <td> {{preciosFiltrados[i].areaNegocio != null?preciosFiltrados[i].areaNegocio.nombreArea: "--"}}</td>
  
              <td> {{preciosFiltrados[i].segmento != null?preciosFiltrados[i].segmento.nombreSegmento: "--"}}</td>
  
              <td>{{preciosFiltrados[i].codigoAeroplanta}}</td>
  
              <td>{{preciosFiltrados[i].solicitoDesactivarFecha | date: "d'/'MM'/'y' 'hh:mm a"}} - {{preciosFiltrados[i].solicitoDesactivarUserName}}</td>
  
              <td *ngIf="preciosFiltrados[i].desactivoFecha != null">
                {{preciosFiltrados[i].desactivoFecha | date: "d'/'MM'/'y' 'hh:mm a"}} - {{preciosFiltrados[i].desactivoUserName}}</td>
              <td *ngIf="preciosFiltrados[i].desactivoFecha == null">--</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </form>
  