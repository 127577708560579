<div class="row pt-2">
  <div class="col-3">
    <div class="list-group">
      <button class="list-group-item list-group-item-action" *ngFor="let tipoPadron of tiposPadron"
        [ngClass]="{active: tipoPadron[0] === selectedTipoPadron[0]}" (click)="onSelectedTipoPadron(tipoPadron)">
        {{ tipoPadron[1] }}
      </button>
    </div>
  </div>
  <div class="col-9">
    <div class="card">
      <div class="card-header">
        <b>Carga del padrón de {{ selectedTipoPadron[1] }}</b>
      </div>
      <div class="card-body">
        <div *ngIf="cargandoPadron; else cargarPadron">
          <span>{{ cargandoPadron.fileName }} - {{cargandoPadron.estado}}</span>
          <ng-container *ngIf="cargandoPadron.estado === EstadoPadron.Subiendo">
            <span> - {{ cargandoPadron.progress }}%</span>
            <span *ngIf="cargandoPadron.segundosRestantes >= 60">
              ({{ cargandoPadron.segundosRestantes / 60  | number: '1.0-0'}}</span>
            <span *ngIf="cargandoPadron.segundosRestantes <= 60">
              ({{ cargandoPadron.segundosRestantes | number: '1.0-0'}}</span>
            <span> {{ cargandoPadron.segundosRestantes > 60? 'minuto/s' : 'segundo/s' }} restantes) </span>
          </ng-container>
          <div class="progress" style="height: 20px;margin-bottom:20px;">
            <div
              *ngIf="cargandoPadron.estado == EstadoPadron.Preparando || cargandoPadron.estado == EstadoPadron.Importando"
              class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
              [style.width.%]="cargandoPadron.estado == EstadoPadron.Preparando? 0 : 100" aria-valuenow="25"
              aria-valuemin="0" aria-valuemax="100"></div>
            <div *ngIf="cargandoPadron.estado == EstadoPadron.Subiendo"
              class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" [style.width.%]="cargandoPadron.progress"
              aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
          <button type="button" *ngIf="cargandoPadron.estado === EstadoPadron.Subiendo" class="btn btn-danger btn-sm"
            (click)="onCancelarCarga(cargandoPadron)">Cancelar</button>
        </div>
        <ng-template #cargarPadron>
          <form [formGroup]="profileForm">
            <div>
              <input *ngIf="selectedTipoPadron[0] != TipoPadron.PercepcionIVARG18" accept=".txt" type="file" class="form-control" name="profile" (change)="onSelectedFile($event)"
                id="validatedCustomFile" formControlName="inputFile" required>
              <input *ngIf="selectedTipoPadron[0] === TipoPadron.PercepcionIVARG18" accept=".csv" type="file" class="form-control" name="profile" (change)="onSelectedFile($event)"
                id="validatedCustomFile" formControlName="inputFile" required>
                <label class="mt-2 ms-2 form-label" for="validatedCustomFile">{{ fileName }}</label>
              <div class="invalid-feedback">Example invalid custom file feedback</div>                
            </div>
            <div class="mb-3" *ngIf="selectedTipoPadron[0] === TipoPadron.ARBAPercepciones || selectedTipoPadron[0] === TipoPadron.ARBAPercepcionesCombustibles">
              <div class="form-check form-check-inline">
                <input type="radio" formControlName="tipoRegimenARBA" 
                  class="form-check-input" id="radioRegimenGeneral" [value]="TipoRegimenARBA.General" />
                <label class="form-check-label" for="radioRegimenGeneral">Régimen general</label>
              </div>
              <div class="form-check form-check-inline">
                <input type="radio" formControlName="tipoRegimenARBA" 
                  class="form-check-input" id="radioRegimenCombustibles" [value]="TipoRegimenARBA.Combustibles" />
                <label class="form-check-label" for="radioRegimenCombustibles">Régimen de combustibles</label>
              </div>
            </div>        
            <div class="mb-3">
              <button type="button" [disabled]="!file || preparandoCarga" class="btn btn-success" (click)="onSubmit()">Cargar</button>
              <button type="button" class="btn btn-secondary ms-2" (click)="reset(true, true)">Cancelar</button>
            </div>
          </form>
        </ng-template>
        <ngb-alert [dismissible]="false" type="success" *ngIf="cargaPadronTerminada && cargaPadronTerminada.finalizada">
          {{ cargaPadronTerminada.mensajeOk }}</ngb-alert>
        <ngb-alert [dismissible]="false" type="danger" *ngIf="cargaPadronTerminada && cargaPadronTerminada.error">Error
          al cargar el archivo {{ cargaPadronTerminada.fileName }}: {{ cargaPadronTerminada.error }}</ngb-alert>
        <ngb-alert [dismissible]="false" type="danger"
          *ngIf="cargaPadronTerminada && cargaPadronTerminada.estado == EstadoPadron.Cancelado">Carga anulada por el
          usuario</ngb-alert>
      </div>
    </div>
  </div>
</div>