import { Component, OnInit, Input } from '@angular/core';
import { ManagementService } from '../management.service';
import { ConfiguracionService } from 'src/app/despacho/configuracion.service';
import { Pais } from 'src/app/shared/models/despacho/Pais';
import { Provincia } from 'src/app/shared/models/facturacion/Provincia';
import { Ciudad } from 'src/app/shared/models/facturacion/Ciudad';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Emisor } from 'src/app/shared/models/facturacion/Emisor';
import { TipoDocumento } from 'src/app/shared/enums/tipoDocumento';
import { NgbDateStruct, NgbModal, } from '@ng-bootstrap/ng-bootstrap';
import { MessageService } from 'src/app/shell/message.service';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ClienteService } from 'src/app/cliente/cliente.service';
import { CondicionIVA } from 'src/app/shared/models/facturacion/CondicionIVA';
import { NgxSpinnerService } from 'ngx-spinner';
import { isEmptyObject } from 'jquery';
import { ListEmisorComponent } from '../list-emisor/list-emisor.component';
import { CuentaCliente } from 'src/app/shared/models/despacho/CuentaCliente';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';

@Component({
  selector: 'app-set-emisor',
  templateUrl: './set-emisor.component.html',
  styleUrls: ['./set-emisor.component.css']
})
export class SetEmisorComponent implements OnInit {
  paises: Pais[];
  provincias: Provincia[];
  ciudades: Ciudad[];
  emisor: Emisor[];
  tipoDocumento = TipoDocumento;
  model: NgbDateStruct;
  actualizandoEmisor: boolean = false;
  initCheckIIBB: boolean;
  esAgentePercepcionIIBB: boolean;
  initCheckIVA: boolean;
  esAgentePercepcionIVA:boolean;
  nuevosEmisores: Emisor = new Emisor();
  modelPais: any;
  modelProvincia: any;
  modelCiudad: any;
  paisBuscado: string;
  provinciaBuscada: Provincia;
  ciudadBuscada: Ciudad;
  cargandoPaises: boolean = false;
  cargandoProvincias: boolean = false;
  cargandoCiudades: boolean = false;
  cargandoCondiciones: boolean = false;
  errorDestinos: boolean = false;
  cargandoClientes: boolean = false;
  clientes: CuentaCliente[];
  modelCliente: any;
  errorClientes: boolean = false;
  condicionIVA: CondicionIVA[];
  modelCondicion: any;
  maximoNumero: number = 11;
  TipoUsuario = TipoUsuario;

  @Input() emisorSeleccionado: Emisor;

  informacionFiscal = new UntypedFormGroup({
    razonSocial: new UntypedFormControl([null, [Validators.required]]),
    telefono: new UntypedFormControl(),
    tipoDocumento: new UntypedFormControl(),
    numeroDocumento: new UntypedFormControl([null, [
      Validators.required,
      Validators.pattern('(20|23|24|27|30|33|34)(\\D)?[0-9]{8}(\\D)?[0-9]'),
      this.validarCuit
    ]]),
    selecPais: new UntypedFormControl(),
    selecLocalidad: new UntypedFormControl(),
    selecProvincia: new UntypedFormControl(Validators.required),
    direccion: new UntypedFormControl([null, [Validators.required]]),
    codigoPostal: new UntypedFormControl(Validators.required),
    email: new UntypedFormControl(),
    condicionIVA: new UntypedFormControl(),
    clienteRazonSocial: new UntypedFormControl([null, [Validators.required]])
  });

  datosImprentaGroup = new UntypedFormGroup({
    razonSocialImpresor: new UntypedFormControl(Validators.required),
    cuitImpresor: new UntypedFormControl(null, [
      Validators.required,
      Validators.pattern('(20|23|24|27|30|33|34)(\\D)?[0-9]{8}(\\D)?[0-9]'),
      this.validarCuit2
    ]),
  });

  informacionImpositiva = new UntypedFormGroup({
    fechaActividades: new UntypedFormControl([null, [Validators.required]]),
    numeroIIBB: new UntypedFormControl([null, [Validators.required]]),
    tipoOperacion: new UntypedFormControl(),
    pagoMaximoContado: new UntypedFormControl(),
    pagoMaximoElectronico: new UntypedFormControl(),
    percIVA: new UntypedFormControl(),
    percIIBB: new UntypedFormControl(),
    alicuotaIVA: new UntypedFormControl(),
    alicuotaIVAOtros: new UntypedFormControl(),
    alicuotaIVANoCat: new UntypedFormControl(),
    certificadoNombre: new UntypedFormControl()
  });

  constructor(
    private configuracionService: ConfiguracionService, 
    private managmentService: ManagementService,
    private spinner: NgxSpinnerService, 
    private modal: NgbModal, 
    private messageService: MessageService,
    private clienteService: ClienteService, 
    private listaDeEmisores: ListEmisorComponent,
    public authService: AuthService) { }

  ngOnInit() {
    this.spinner.show('spinnerGrafico');
    this.getPaises();
    this.getEmisores();
    this.getClientes();
    this.getCondicionesIVA();
    if (this.emisorSeleccionado == null) {
      this.nuevosEmisores.ciudad = new Ciudad();
      this.nuevosEmisores.ciudad.provincia = new Provincia();
      this.nuevosEmisores.ciudad.provincia.pais = 'Argentina';
      this.nuevosEmisores.ciudad.provincia.codigo = 'BSAS';
      this.nuevosEmisores.ciudad.codigoPostal = '1106';
      this.rSocial.setValue(null);
      this.numeroDoc.setValue(0);
      this.direccion.setValue(null);
      this.codigoPostal.setValue(null);
      this.telefono.setValue(null);
      this.cliente.setValue(null);
      this.numeroIIBB.setValue('0');
      this.pagoMaximoContado.setValue(0);
      this.pagoMaximoElectronico.setValue(0);
      this.alicuotaIVA.setValue(0);
      this.alicuotaIVAOtros.setValue(0);
      this.alicuotaIVANoCat.setValue(0);
      this.tipoOperacion.setValue(0);
      this.documento.setValue(80);
      this.initCheckIIBB = false;
      this.initCheckIVA = false;
      this.percIIBB.setValue(false);
      this.percIVA.setValue(false);
      this.emails.setValue("");
      var fechas = new Date();
      this.model = { day: fechas.getUTCDate(), month: fechas.getUTCMonth() + 1, year: fechas.getFullYear() };
    } else {
      this.rSocial.setValue(this.emisorSeleccionado.razonSocial);
      this.numeroDoc.setValue(this.emisorSeleccionado.numeroDocumento);
      this.direccion.setValue(this.emisorSeleccionado.direccion);
      this.codigoPostal.setValue(this.emisorSeleccionado.ciudad.codigoPostal);
      this.telefono.setValue(this.emisorSeleccionado.telefono);
      this.numeroIIBB.setValue(this.emisorSeleccionado.numeroIIBB);
      this.pagoMaximoContado.setValue(this.emisorSeleccionado.montoMaximoPagoContado);
      this.pagoMaximoElectronico.setValue(this.emisorSeleccionado.montoMaximoPagoElectronico);
      this.alicuotaIVA.setValue(this.emisorSeleccionado.alicuotaPercepcionIVAProductos);
      this.alicuotaIVAOtros.setValue(this.emisorSeleccionado.alicuotaPercepcionIVAOtros);
      this.alicuotaIVANoCat.setValue(this.emisorSeleccionado.alicuotaPercepcionIVANoCategorizado);
      this.tipoOperacion.setValue(this.emisorSeleccionado.tipoOperacion);
      this.initCheckIIBB = this.emisorSeleccionado.esAgentePercepcionIIBB;
      console.log("Inicio CHeck ibb "+this.initCheckIIBB)
      this.initCheckIVA = this.emisorSeleccionado.esAgentePercepcionIVA;
      console.log("Inicio CHeck IVA "+this.initCheckIVA)
      this.percIIBB.setValue(this.emisorSeleccionado.esAgentePercepcionIIBB);
      this.percIVA.setValue(this.emisorSeleccionado.esAgentePercepcionIVA);
      this.emails.setValue(this.emisorSeleccionado.email);
      var fecha = new Date(this.emisorSeleccionado.fechaInicioActividades);
      this.model = { day: fecha.getUTCDate(), month: fecha.getUTCMonth() + 1, year: fecha.getFullYear() };
      this.documento.setValue(this.emisorSeleccionado.tipoDocumento);
      this.razonSocialImpresor.setValue(this.emisorSeleccionado.imprentaRazonSocial);
      this.cuitImpresor.setValue(this.emisorSeleccionado.imprentaCUIT);
    }
  }

  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  keysTipoDocumento(): Array<string> {
    var keys = Object.keys(this.tipoDocumento);
    keys = keys.slice(keys.length / 2);
    return keys;
  }

  ngOnChanges() {
    this.ngOnInit();
  }

  getCondicionesIVA() {
    this.cargandoCondiciones = true;
    this.managmentService.getCondicionesIVA()
      .subscribe(result => {
        this.condicionIVA = result;
        this.obtenerCondicionIVA();
        this.cargandoCondiciones = false;
      })
  }
  obtenerCondicionIVA() {
    if (this.emisorSeleccionado != null) {
      for (let i = 0; this.condicionIVA.length > i; i++) {
        if (this.condicionIVA[i].id == this.emisorSeleccionado.condicionIVAId) {
          this.condicionesIVA.setValue(this.condicionIVA[i]);
        }
      }
    } else {
      this.condicionesIVA.setValue(null);
    }
  }

  getEmisores() {

    this.managmentService.getEmisores()
      .subscribe(result => {
        console.log("this.emisor: ");
        console.log(this.emisor);
        this.emisor = result;

      })
  }

  getClientes() {
    this.clienteService.getClientesActivos()
      .subscribe(clientes => {
        clientes = clientes.map(a => Object.assign(new CuentaCliente(), a));
        this.clientes = clientes;
        this.cargandoClientes = false;
        this.obtenerClienteCF();
      }, () => this.errorClientes = true
      );
  }
  obtenerClienteCF() {
    if (this.emisorSeleccionado != null) {
      for (let i = 0; this.clientes.length > i; i++) {
        if (this.clientes[i].ctA_SGC == this.emisorSeleccionado.ctaSgc_ClienteCF) {
          this.cliente.setValue(this.clientes[i]);
        }
      }
    } else {
      this.cliente.setValue(null);
    }
  }

  getPaises() {
    this.cargandoPaises = true;
    this.cargandoProvincias = true;
    this.cargandoCiudades = true;
    this.configuracionService.getPaises()
      .subscribe(result => {
        this.paises = result;
        this.cargandoPaises = false;
        this.obtenerPaisSeleccionado();
      }, () => this.errorDestinos = true)
  }
  obtenerPaisSeleccionado() {
    if (this.emisorSeleccionado != null) {
      for (let i = 0; this.paises.length > i; i++) {
        if (this.paises[i].nombre == this.emisorSeleccionado.ciudad.provincia.pais) {
          this.pais.setValue(this.paises[i]);
          this.getProvinciasPorPais(this.paises[i].codigo);
        }
      }
    } else {
      for (let i = 0; this.paises.length > i; i++) {
        if (this.paises[i].nombre == this.nuevosEmisores.ciudad.provincia.pais) {
          this.pais.setValue(this.paises[i]);
          this.getProvinciasPorPais(this.paises[i].codigo);
        }
      }
    }
  }

  getProvinciasPorPais(codigo: string) {
    this.provincia.setValue(null);
    this.configuracionService.getProvinciasPorPais(codigo)
      .subscribe(result => {
        this.provincias = result;
        this.cargandoProvincias = false;
        this.obtenerProvinciaSeleccionada();
      }, () => this.errorDestinos = true)
  }
  obtenerProvinciaSeleccionada() {
    this.ciudad.setValue(null);
    if (this.emisorSeleccionado != null) {
      for (let i = 0; this.provincias.length > i; i++) {
        if (this.provincias[i].codigo == this.emisorSeleccionado.ciudad.provincia.codigo) {
          this.provincia.setValue(this.provincias[i]);
          this.getLocalidadPorProvincia(this.provincias[i].codigo);
        }
      }
    } else {
      for (let i = 0; this.provincias.length > i; i++) {
        if (this.provincias[i].codigo == this.nuevosEmisores.ciudad.provincia.codigo) {
          this.provincia.setValue(this.provincias[i]);
          this.getLocalidadPorProvincia(this.provincias[i].codigo);
        }
      }
    }
  }

  getLocalidadPorProvincia(codigo: string) {
    this.ciudades = [];
    this.configuracionService.getCiudadPorProvincia(codigo)
      .subscribe(result => {
        this.ciudades = result;
        this.cargandoCiudades = false;
        this.obtenerLocalidadSeleccionada();
      }, () => this.errorDestinos = true)
  }

  obtenerLocalidadSeleccionada() {
    if (this.emisorSeleccionado != null) {
      for (let i = 0; this.ciudades.length > i; i++) {
        if (this.ciudades[i].codigoPostal == this.emisorSeleccionado.ciudad.codigoPostal) {
          this.ciudad.setValue(this.ciudades[i]);
          this.codigoPostal.setValue(this.ciudades[i].codigoPostal);
        }
      }
    } else {
      for (let i = 0; this.ciudades.length > i; i++) {
        if (this.ciudades[i].codigoPostal == this.nuevosEmisores.ciudad.codigoPostal) {
          this.ciudad.setValue(this.ciudades[i]);
          this.codigoPostal.setValue(this.ciudades[i].codigoPostal);
        }
      }
    }
    this.spinner.hide('spinnerGrafico');
  }

  agregarLeyendas(content) {
    this.modal.open(content, { centered: true, size: 'lg', backdrop: 'static' });
  }

  agregarCertificado(content2) {
    this.modal.open(content2, { centered: true, size: 'lg', backdrop: 'static' });
  }

  formatDate(year, month, day, hour, minute): string {
    return year + '-' + month.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + '-' + day.toLocaleString(undefined, { minimumIntegerDigits: 2 })
      + 'T' + '00:00:00';
  }

  guardarEmisor() {
    if (this.informacionFiscal.valid && this.informacionImpositiva.valid && this.datosImprentaGroup.valid) {
      this.actualizandoEmisor = true;
      this.emisorSeleccionado.razonSocial = this.rSocial.value;
      this.emisorSeleccionado.tipoDocumento = this.documento.value;
      this.emisorSeleccionado.numeroDocumento = this.numeroDoc.value;
      this.emisorSeleccionado.direccion = this.direccion.value;
      this.emisorSeleccionado.telefono = this.telefono.value;
      this.emisorSeleccionado.ciudad.id = this.ciudad.value.id;
      this.emisorSeleccionado.ciudad.nombre = this.ciudad.value.nombre;
      this.emisorSeleccionado.ciudad.codigoPostal = this.ciudad.value.codigoPostal;
      this.emisorSeleccionado.ciudad.provincia.id = this.provincia.value.id;
      this.emisorSeleccionado.ciudad.provincia.codigo = this.provincia.value.codigo;
      this.emisorSeleccionado.ciudad.provincia.nombre = this.provincia.value.nombre;
      this.emisorSeleccionado.ciudad.provincia.pais = this.pais.value.nombre;
      this.emisorSeleccionado.email = this.emails.value;

      this.emisorSeleccionado.fechaInicioActividades = this.formatDate(this.inicioAct.value.year, this.inicioAct.value.month,
        this.inicioAct.value.day, this.inicioAct.value.hour, this.inicioAct.value.minute);
      this.emisorSeleccionado.numeroIIBB = this.numeroIIBB.value;
      this.emisorSeleccionado.tipoOperacion = this.tipoOperacion.value;
      this.emisorSeleccionado.montoMaximoPagoContado = parseFloat(this.pagoMaximoContado.value);
      this.emisorSeleccionado.montoMaximoPagoElectronico = parseFloat(this.pagoMaximoElectronico.value);
      if (this.percIIBB.value == "true" || this.percIIBB.value == true) {
        this.emisorSeleccionado.esAgentePercepcionIIBB = true;
      } else {
        this.emisorSeleccionado.esAgentePercepcionIIBB = false;
      }
      if (this.percIVA.value == "true" || this.percIVA.value == true) {
        this.emisorSeleccionado.esAgentePercepcionIVA = true;
      } else {
        this.emisorSeleccionado.esAgentePercepcionIVA = false;
      }
      this.emisorSeleccionado.condicionIVAId = this.condicionesIVA.value.id;
      this.emisorSeleccionado.alicuotaPercepcionIVAProductos = parseFloat(this.alicuotaIVA.value);
      this.emisorSeleccionado.alicuotaPercepcionIVAOtros = parseFloat(this.alicuotaIVAOtros.value);
      this.emisorSeleccionado.alicuotaPercepcionIVANoCategorizado = parseFloat(this.alicuotaIVANoCat.value);

      this.emisorSeleccionado.imprentaRazonSocial = this.razonSocialImpresor.value;
      this.emisorSeleccionado.imprentaCUIT = this.cuitImpresor.value;
      this.managmentService.actualizarEmisor(this.emisorSeleccionado)
        .subscribe(result => {
          if (!isEmptyObject({ result })) {
            this.actualizandoEmisor = false;
            this.messageService.clear();
            this.messageService.showSuccessMessage("Se actualizo el emisor correctamente");
            this.listaDeEmisores.ngOnInit();
          }
        });
    } else {
      this.actualizandoEmisor = false;
      this.messageService.showErrorMessage("Faltan completar datos.");
    }
  }

  crearEmisor() {
    if (this.informacionFiscal.valid && this.informacionImpositiva.valid && this.datosImprentaGroup.valid) {
      this.actualizandoEmisor = true;
      this.nuevosEmisores.razonSocial = this.rSocial.value;
      this.nuevosEmisores.tipoDocumento = this.documento.value;
      this.nuevosEmisores.numeroDocumento = this.numeroDoc.value;
      this.nuevosEmisores.ciudad.id = this.ciudad.value.id;
      this.nuevosEmisores.ciudad.nombre = this.ciudad.value.nombre;
      this.nuevosEmisores.ciudad.codigoPostal = this.ciudad.value.codigoPostal;
      this.nuevosEmisores.ciudad.provincia.id = this.provincia.value.id;
      this.nuevosEmisores.ciudad.provincia.codigo = this.provincia.value.codigo;
      this.nuevosEmisores.ciudad.provincia.nombre = this.provincia.value.nombre;
      this.nuevosEmisores.ciudad.provincia.pais = this.pais.value.nombre;
      this.nuevosEmisores.email = this.emails.value;
      this.nuevosEmisores.direccion = this.direccion.value;
      this.nuevosEmisores.telefono = this.telefono.value;
      this.nuevosEmisores.condicionIVAId = this.condicionesIVA.value.id;
      this.nuevosEmisores.fechaInicioActividades = this.formatDate(this.inicioAct.value.year, this.inicioAct.value.month,
        this.inicioAct.value.day, this.inicioAct.value.hour, this.inicioAct.value.minute);
      this.nuevosEmisores.numeroIIBB = this.numeroIIBB.value;
      this.nuevosEmisores.tipoOperacion = this.tipoOperacion.value;
      this.nuevosEmisores.montoMaximoPagoContado = parseFloat(this.pagoMaximoContado.value);
      this.nuevosEmisores.montoMaximoPagoElectronico = parseFloat(this.pagoMaximoElectronico.value);
      if (this.percIIBB.value == "true" || this.percIIBB.value == true) {
        this.nuevosEmisores.esAgentePercepcionIIBB = true;
      } else {
        this.nuevosEmisores.esAgentePercepcionIIBB = false;
      }
      if (this.percIVA.value == "true" || this.percIVA.value == true) {
        this.nuevosEmisores.esAgentePercepcionIIBB = true;
      } else {
        this.nuevosEmisores.esAgentePercepcionIIBB = false;
      }
      this.nuevosEmisores.alicuotaPercepcionIVAProductos = parseFloat(this.alicuotaIVA.value);
      this.nuevosEmisores.alicuotaPercepcionIVAOtros = parseFloat(this.alicuotaIVAOtros.value);
      this.nuevosEmisores.alicuotaPercepcionIVANoCategorizado = parseFloat(this.alicuotaIVANoCat.value);

      this.nuevosEmisores.numeroIIBB = this.numeroIIBB.value;
      this.nuevosEmisores.ctaSgc_ClienteCF = this.cliente.value.numeroCuenta;
      
      this.nuevosEmisores.imprentaRazonSocial = this.razonSocialImpresor.value;
      this.nuevosEmisores.imprentaCUIT = this.cuitImpresor.value;
      this.managmentService.crearEmisor(this.nuevosEmisores)
        .subscribe(result => {
          this.actualizandoEmisor = true;
          this.messageService.showSuccessMessage("Se creo el emisor correctamente");
          this.listaDeEmisores.ngOnInit();
        });
    } else {
      this.actualizandoEmisor = false;
      this.messageService.showErrorMessage("Faltan completar datos.");
    }
  }

  formatterPais = (x: { nombre: string }) => `${x.nombre}`;
  formatterProvincia = (y: { nombre: string }) => `${y.nombre}`;
  formatterCiudad = (x: { nombre: string, codigoPostal: string }) => `${x.nombre} - ${x.codigoPostal}`;
  formatterCliente = (x: { razonSocial: string, numeroCuenta: string }) => `${x.razonSocial} - ${x.numeroCuenta}`;
  formatterCondicion = (y: { descripcion: string }) => `${y.descripcion}`;

  selectPaises(e: any): void {
    this.pais.setValue(e.item.nombre);
    this.paisBuscado = e.item.nombre;
  }
  selectProvincias(e: any): void {
    this.provincia.setValue(e.item.nombre);
    this.provinciaBuscada = e.item.nombre;
  }
  selectCiudades(e: any): void {
    this.ciudad.setValue(e.item.nombre);
    this.ciudadBuscada = e.item.nombre;
    this.codigoPostal.setValue(e.item.codigoPostal);
  }
  selectCustomer(e: any): void {
    this.cliente.setValue(e.item);

  }
  selectCondicion(e: any): void {
    this.condicionesIVA.setValue(e.item);
  }

  searchProvincia = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 || this.cargandoProvincias ? [] :
        this.provincias.filter(v => this.containsString(this.provincia.value, v.nombre)).slice(0, 10))
    )

  searchCiudad = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 || this.cargandoCiudades ? [] :
        this.ciudades.filter(v => this.containsString(this.ciudad.value, v.nombre)).slice(0, 10))
    )

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 || this.cargandoPaises ? [] :
        this.paises.filter(v => this.containsString(this.pais.value, v.nombre)).slice(0, 10))
    )

  searchCondicion = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 || this.cargandoCondiciones ? [] :
        this.condicionIVA.filter(v => this.containsString(this.condicionesIVA.value, v.descripcion)).slice(0, 10))
    )

  searchCliente = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 || this.cargandoClientes ? [] :
        this.clientes.filter(v => this.containsString(this.cliente.value, v.ctA_SGC, v.razonSocial)).slice(0, 10))
    )

  /**
   * Determina si una cadena se encuentra dentro de otra
   * @param searchValue Valor a buscar
   * @param searchIn Valores sobre los que buscar searchValue
   */
  containsString(searchValue: string, ...searchIn: string[]): boolean {
    const concat = searchIn.toString();
    return concat.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
  }

  validarCuit(): boolean {
    const regexCuit = /^(20|23|24|27|30|33|34)([0-9]{9}|[0-9]{8}[0-9]{1})$/g;
    let p = regexCuit.test(this.numeroDoc.value)
    return p;
  }

  validarCuit2(control: AbstractControl): { [key: string]: any } | null {
    if (control.value == null || control.value.length === 0) {
      return null; // don't validate empty values to allow optional controls
    }
    let esCuit = false;
    let cuit = control.value
    var vec = new Array(10);
    let cuit_rearmado = "";
    for (let i = 0; i < cuit.length; i++) {
      let caracter = cuit.charAt(i);
      if (caracter.charCodeAt(0) >= 48 && caracter.charCodeAt(0) <= 57) {
        cuit_rearmado += caracter;
      }
    }
    cuit = cuit_rearmado;
    if (cuit.length != 11) {  // si no estan todos los digitos
      esCuit = false;
    } else {
      let x = 0;
      let dv = 0
      // Multiplico los dígitos.
      vec[0] = cuit.charAt(0) * 5;
      vec[1] = cuit.charAt(1) * 4;
      vec[2] = cuit.charAt(2) * 3;
      vec[3] = cuit.charAt(3) * 2;
      vec[4] = cuit.charAt(4) * 7;
      vec[5] = cuit.charAt(5) * 6;
      vec[6] = cuit.charAt(6) * 5;
      vec[7] = cuit.charAt(7) * 4;
      vec[8] = cuit.charAt(8) * 3;
      vec[9] = cuit.charAt(9) * 2;

      // Suma cada uno de los resultado.
      for (let i = 0; i <= 9; i++) {
        x += vec[i];
      }
      dv = (11 - (x % 11)) % 11;
      if (dv == cuit.charAt(10)) {
        esCuit = true;
      }
    }

    return control.valid && esCuit ? null : { 'cuit': { value: control.value } };
  }
  validarDNI(): boolean {
    return this.numeroDoc.value.toString().length === '8';
  }

  limpiarCampoNumero() {
    this.numeroDoc.setValue("0");
    // if (this.documento.value === '96') //dni
    // {
    //   this.maximoNumero = 8;
    // }
    // else //cuit o cuil
    // {
    //   this.maximoNumero = 11;
    // }
  }


  estaDocumento(): boolean {
    let esta: boolean = false;
    if (this.emisor != null) {

      for (let entry of this.emisor) {
        if (entry.numeroDocumento === this.numeroDoc.value) {
          esta = true;
          break;
        }
      }
      return esta;
    }
    else {
      return esta;
    }

  }
  

  get rSocial() {
    return this.informacionFiscal.get('razonSocial');
  }

  get documento() {
    return this.informacionFiscal.get('tipoDocumento');
  }

  get numeroDoc() {
    return this.informacionFiscal.get('numeroDocumento');
  }

  get pais() {
    return this.informacionFiscal.get('selecPais');
  }

  get provincia() {
    return this.informacionFiscal.get('selecProvincia');
  }

  get ciudad() {
    return this.informacionFiscal.get('selecLocalidad');
  }

  get direccion() {
    return this.informacionFiscal.get('direccion');
  }

  get codigoPostal() {
    return this.informacionFiscal.get('codigoPostal');
  }

  get telefono() {
    return this.informacionFiscal.get('telefono');
  }

  get inicioAct() {
    return this.informacionImpositiva.get('fechaActividades');
  }

  get numeroIIBB() {
    return this.informacionImpositiva.get('numeroIIBB');
  }

  get tipoOperacion() {
    return this.informacionImpositiva.get('tipoOperacion');
  }
  get pagoMaximoContado() {
    return this.informacionImpositiva.get('pagoMaximoContado');
  }
  get pagoMaximoElectronico() {
    return this.informacionImpositiva.get('pagoMaximoElectronico');
  }
  get percIVA() {
    return this.informacionImpositiva.get('percIVA');
  }
  get percIIBB() {
    return this.informacionImpositiva.get('percIIBB');
  }
  get alicuotaIVA() {
    return this.informacionImpositiva.get('alicuotaIVA');
  }
  get alicuotaIVAOtros() {
    return this.informacionImpositiva.get('alicuotaIVAOtros');
  }
  get alicuotaIVANoCat() {
    return this.informacionImpositiva.get('alicuotaIVANoCat');
  }
  get certificado() {
    return this.informacionImpositiva.get('certificadoNombre');
  }
  get emails() {
    return this.informacionFiscal.get('email');
  }
  get condicionesIVA() {
    return this.informacionFiscal.get('condicionIVA');
  }
  get cliente() {
    return this.informacionFiscal.get('clienteRazonSocial');
  }
  get razonSocialImpresor(){
    return this.datosImprentaGroup.get('razonSocialImpresor');
  }
  get cuitImpresor(){
    return this.datosImprentaGroup.get('cuitImpresor');
  }
}