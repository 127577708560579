import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VehiculoDTO } from 'src/app/shared/models/cliente/VehiculoDTO';
import { AbmMatriculasService } from 'src/app/management/abm-matriculas.service';
import { TipoVehiculo } from 'src/app/shared/enums/tipoVehiculo';
import { Producto } from 'src/app/shared/models/abm/Producto';
import { ProductoService } from 'src/app/abm/producto/producto.service';
import { TipoProducto } from 'src/app/shared/enums/tipoProducto';
@Component({
  selector: 'app-modal-vehiculo',
  templateUrl: './modal-vehiculo.component.html',
  styleUrls: ['./modal-vehiculo.component.scss']
})
export class ModalVehiculoComponent implements OnInit {

  @Input() matriculaIngresada : string;
  @Input() tipoVehiculoParaFiltrar: number;
  @Input() vehiculoEditar:VehiculoDTO;
  @Input() vehiculos: VehiculoDTO[];
  @Input() desactivarInput: boolean;

  guardando: boolean = false;
  error: string = null;
  TipoVehiculo=TipoVehiculo;
  productos: Producto[];
  productosFiltrados: Producto[];
  cargandoProductos:boolean=false;
  editandoVehiculo:boolean;

  form = this.formBuilder.group({
    matriculaFormControl: [ '', [Validators.required, Validators.pattern('\\w*')] ],
    tipoVehiculoFormControl: [ '', Validators.required],
    productoFormControl: [ '', Validators.required],
  });

  constructor(
    private abmMatriculasService: AbmMatriculasService,
    public activeModal: NgbActiveModal,
    public modalService: NgbModal,
    private formBuilder: UntypedFormBuilder,
    private productoService: ProductoService
  ) { }

  ngOnInit() {
    this.editandoVehiculo=false;
    if(this.vehiculoEditar==undefined) {
      this.matriculaFormControl.setValue(this.matriculaIngresada);    
      if(this.tipoVehiculoParaFiltrar != undefined){
        this.tipoVehiculoFormControl.setValue(this.tipoVehiculoParaFiltrar);
      }            
    }else{
      this.matriculaFormControl.setValue(this.vehiculoEditar.matricula);    
      this.tipoVehiculoParaFiltrar=this.vehiculoEditar.tipoVehiculo;
      this.tipoVehiculoFormControl.setValue(this.vehiculoEditar.tipoVehiculo);  
      this.editandoVehiculo=true; 
    }
    this.getProductos();  
  }

  getProductos() {
    this.cargandoProductos = true;
    this.productoService.obtenerProductos()
      .subscribe(productos => {
        productos = productos.map(p => Object.assign(new Producto(), p)).filter(p=>p.rubro.aerocombustible || p.rubro.combustible).filter(a=>a.activo);
        this.productos = productos;        
        this.productosFiltrados = productos;               
        this.cargandoProductos = false;
        this.changeTipoVehiculo();
        if(this.editandoVehiculo) {
          this.productoFormControl.setValue(this.productosFiltrados.find(p=>p.codigoProducto==this.vehiculoEditar.codigoProducto));
        }
      });
  }

  changeTipoVehiculo() {
    if(this.tipoVehiculoFormControl.value===TipoVehiculo.Aeronave || this.tipoVehiculoFormControl.value===TipoVehiculo.Automotor) {
      this.productosFiltrados=this.productos.filter(t => t.tipoProducto==this.obtenerTipoProductoPorTipoVehiculo(this.tipoVehiculoFormControl.value));
      if(this.productosFiltrados.length>0) {
        this.productoFormControl.setValue(this.productosFiltrados[0]);
      }
    }      
  }

  obtenerTipoProductoPorTipoVehiculo(tipo:TipoVehiculo):TipoProducto {
    if (tipo==TipoVehiculo.Aeronave)  
      return TipoProducto.Aerocombustible
    else 
      return TipoProducto.Combustible;
  }

  guardarCambios() {
    if( ! this.datosValidos() ) return;

    this.error = null;
    this.guardando = true;

    if(!this.editandoVehiculo) {
      let vehiculo = new VehiculoDTO();
      vehiculo.matricula = this.matriculaFormControl.value.toUpperCase();
      vehiculo.tipoVehiculo = Number(this.tipoVehiculoFormControl.value);
      vehiculo.codigoProducto = this.productoFormControl.value.codigoProducto;
      vehiculo.activo = true;
      
      this.abmMatriculasService.crearVehiculo(vehiculo)
        .subscribe(vehiculo => {
          this.activeModal.close(vehiculo);
          this.guardando = false;
        },
        (err) => {
          this.error = err.descripcion;
          this.guardando = false;
        });
    }else{
      this.vehiculoEditar.codigoProducto=this.productoFormControl.value.codigoProducto;
      this.abmMatriculasService.modificarVehiculo(this.vehiculoEditar.matricula, this.vehiculoEditar)
        .subscribe(vehiculo=> {
          this.activeModal.close(vehiculo);
          this.guardando = false;
        },
        (err) => {
          this.error = err.descripcion;
          this.guardando = false;
        });
    }
    
  }

  matriculaRepetida(){
    if(this.vehiculos && this.matriculaFormControl.value){
      if(this.vehiculos.find(v => v.matricula == this.matriculaFormControl.value.toUpperCase()))
      return true;
    }
    return false;
  }

  tipoVehiculoOK(tipo:TipoVehiculo) {
    if (tipo != null || tipo != undefined) {
      return tipo==TipoVehiculo.Aeronave || tipo==TipoVehiculo.Automotor;
    }else{
      return false;
    }
  }

  bloquearSeleccionVehiculo() {
    return this.tipoVehiculoParaFiltrar==TipoVehiculo.Aeronave || this.tipoVehiculoParaFiltrar==TipoVehiculo.Automotor;
  }

  datosValidos(){
    if(this.matriculaFormControl.value == ""){
      this.error = "Ingrese la matricula en el campo correspondiente";
      return false;
    }

    if(!this.tipoVehiculoOK(this.tipoVehiculoFormControl.value)){
      this.error = "Seleccione el tipo de vehiculo";      
      return false;
    }

    return true;
  }

  get matriculaFormControl(){
    return this.form.controls.matriculaFormControl;
  }

  get tipoVehiculoFormControl(){
    return this.form.controls.tipoVehiculoFormControl;
  }

  get productoFormControl(){
    return this.form.controls.productoFormControl;
  }
}
