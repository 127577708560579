<div class="modal-header bg-light">
    <div class="modal-title">
    <h4>Cambiar estado</h4>
    <p style="font-size:15px">{{cliente.clienteRazonSocial}}</p></div>
    <button type="button" class="btn-close" aria-label="Close" [disabled]="enviando" (click)="activeModal.close(false)"></button>
</div>
<div class="modal-body" [formGroup]="cambiarEstadoForm">
    <ngx-spinner name="spinnerEstado" [ngStyle]="{'height': '400px', 'z-index':'0'}" bdOpacity=0.3bd
        Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
        <p style="color: white"> C a r g a n d o . . . </p>
    </ngx-spinner>
    <div class="form-row">
        <div class="form-group row col-md-12" *ngIf="authService.hasRole(TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_LN, TipoUsuario.COMERCIAL_AV, TipoUsuario.APROBADORBYR)">
            <label for="observacionesFormControl" class="col-sm-5 col-form-label">Observaciones: </label>
            <div class="col-sm-7">
                <textarea class="form-control col-sm-11" style="display: inline!important;"
                    formControlName="observacionesFormControl"></textarea>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer justify-content-center">
    <button *ngIf="authService.hasRole(TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_LN, TipoUsuario.COMERCIAL_AV, TipoUsuario.APROBADORBYR)" class="btn btn-primary mr-2" id="aceptar" (click)="cambiarEstadoCliente();">
        Aceptar
    </button>
    <button type="button" class="btn btn-secondary" (click)="this.activeModal.close(false)">Cancelar</button>
</div>