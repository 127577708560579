import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpErrorHandler, HandleError } from '../http-error-handler.service';
import { AuthService } from '../core/authentication/auth.service';
import { DatePipe } from '@angular/common';
import { Observable, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UrlApi } from '../shared/enums/urlApi';
import { Consolidado } from '../shared/models/cierreTurno/Consolidado';
import { CierreTurnoService } from '../cierreTurno/cierre-turno.service';
import { ResolucionMensual } from '../shared/models/cierreTurno/ResolucionMensual';
import { Justificacion } from '../shared/models/cierreTurno/Justificacion';

@Injectable({
  providedIn: 'root'
})
export class ReportesService
{
  cierreTurnoBase = environment.apiServer.cierreTurnoBaseUrl;
  private cierreURL = this.cierreTurnoBase + '/CierresTurnosV1/';
  private resolucionURL = this.cierreTurnoBase + '/ResolucionV1/';
  baseUrl = environment.apiServer.reportesUrl;
  private handleError: HandleError;

  constructor(private http: HttpClient, 
    httpErrorHandler: HttpErrorHandler)
  {
    this.handleError = httpErrorHandler.createHandleError('Reportes');
  }

  obtenerReporteConsoldiado(codigoProducto:string, fdesde: string, fhasta:string): Observable<Consolidado[]> {
    return this.http.get<Consolidado[]>(this.cierreURL + 'Consolidado?codigoProducto='+codigoProducto + '&fechaDesde=' + fdesde + '&fechaHasta=' + fhasta);
  }

  obtenerResolucionMensual(codigoAeroplanta:string, fdesde: string, fhasta: string): Promise<ResolucionMensual> {
    return this.http.post<ResolucionMensual>(this.resolucionURL + `Resolucion?CodigoAeroplanta=${codigoAeroplanta}&fechaDesde=${fdesde}&fechaHasta=${fhasta}`, null).toPromise();
  }
  
  guardarResolucionMensual(codigoAeroplanta:string, fdesde: string, fhasta: string): Observable<ResolucionMensual[]> {
    return this.http.put<ResolucionMensual[]>(this.resolucionURL + `Resolucion?codigoAeroplanta=${codigoAeroplanta}&fechaDesde=${fdesde}&fechaHasta=${fhasta}`, null);
  }

  justificarRenglonResolucion(justificacion: Justificacion): Observable<Justificacion> {
    return this.http.put<Justificacion>(this.resolucionURL + 'Renglon', justificacion)
  }
}