import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { Holdeo } from 'src/app/shared/models/facturacion/Holdeo';
import { MessageService } from 'src/app/shell/message.service';
import { ComprobanteService } from '../../comprobante.service';
import { NuevoHoldeoComponent } from '../nuevo-holdeo/nuevo-holdeo.component';

@Component({
  selector: 'app-holdeo-cuentas',
  templateUrl: './holdeo-cuentas.component.html',
  styleUrls: ['./holdeo-cuentas.component.css']
})
export class HoldeoCuentasComponent implements OnInit {

  @Output() volver = new EventEmitter();

  cuentasHoldeadas: Holdeo[];
  obteniendo: boolean = false;
  desactivandoCuenta: boolean = false;
  cuentaSeleccionada: number;

  constructor(private activeModal: NgbModal, private comprobantesService: ComprobanteService, private spinner: NgxSpinnerService,
    private messageService: MessageService) { }

  ngOnInit() {
    this.getHoldeoCuenta();
  }

  agregarCuenta(){
    let modalRef = this.activeModal.open(NuevoHoldeoComponent, {centered: true, backdrop: 'static'});
    modalRef.componentInstance.tipo = 1;
    modalRef.result.then(result =>{
      result == true ? this.ngOnInit() : null; 
    });
  }

  getHoldeoCuenta(){
    this.spinner.show('spinnerLista');
    this.obteniendo = true;
    this.comprobantesService.getHoldeo(4)
      .subscribe(result => {
        this.cuentasHoldeadas = result.filter(a => a.tipoHoldeo == 1);
        this.cuentasHoldeadas = this.cuentasHoldeadas.filter(b => b.activo == true);
        this.spinner.hide('spinnerLista');
        this.obteniendo = false;
      });
  }

  quitarCuentas(i: number){
    this.desactivandoCuenta = true;
    this.cuentaSeleccionada = i;
    this.cuentasHoldeadas[i].activo ? this.cuentasHoldeadas[i].activo = false : this.cuentasHoldeadas[i].activo = true;
    this.comprobantesService.putHoldeo(this.cuentasHoldeadas[i])
      .subscribe(result => {
        result.activo ? this.messageService.showSuccessMessage('El holdeo de la cuenta '+ this.cuentasHoldeadas[i].ctA_SGC + ' fue activado con exito') : this.messageService.showErrorMessage('El holdeo de la cuenta ' + this.cuentasHoldeadas[i].ctA_SGC + ' fue desactivado con exito');
        let y = this.cuentasHoldeadas.indexOf(this.cuentasHoldeadas[i])
        if (y != -1){
          this.cuentasHoldeadas.splice(y,1);
        }     
        console.log(this.cuentasHoldeadas);
        this.desactivandoCuenta = false;
        this.cuentaSeleccionada = null;
      }, (err: ErrorModel) => {
        this.messageService.showErrorMessage('Hubo un error al actualizar el estado de la cuenta '+ this.cuentasHoldeadas[i].ctA_SGC);
        this.desactivandoCuenta = false;
        this.cuentaSeleccionada = null;
      });
  }

  goBack(){
    this.volver.emit(true);
  }
}
