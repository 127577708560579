<form [formGroup]="form" id="islas-card">

    <div class="modal-header bg-light">
      <h4 class="modal-title">{{ editando ? "Editar" : "Nuevo" }} Pedido Combustible</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close(false)"></button>
    </div>
  
    <div class="modal-body">

        <!-- Inicio del despacho -->
        <div class="mb-3 row">
          <label for="fechaFormControl" class="col-sm-4 col-form-label">Fecha y hora:</label>
          <div #fechaHora class="col-sm-6">
            <div class="input-group">
              <input type="date" class="form-control" formControlName="fechaFormControl" placeholder="aaaa-mm-dd"
                name="dp" title="Campo obligatorio." container="body" [(ngModel)]="modelFecha" ngbDatepicker #d="ngbDatepicker"
                readonly/>
              <div class="input-group-append">
                <button type="button" class="input-group-btn btn btn-secondary" (click)="d.toggle()">
                  <i class="fa fa-calendar" aria-hidden="true"></i>
                </button>
              </div>
            </div>
            <ngb-timepicker [(ngModel)]="modelHora" [meridian]="true" formControlName="horaFormControl"
              [readonlyInputs]="true"></ngb-timepicker>
            <div class="alert alert-danger"
              *ngIf="form.invalid && this.form.hasError('fechaInicioMayorAhora')">
              Esta cargando una fecha de inicio de despacho en el futuro.
            </div>
          </div>
        </div>

        <div class="row">
          <div class="mb-3 row col-md-12">
            <label for="productoFormControl" class="col-md-4 col-form-label">Producto:</label>
            <div class="col-md-8">
                <select [compareWith]="compareProductos" id="productoFormControl" class="form-select"
                  ngbTooltip="Seleccione el producto." formControlName="productoFormControl">
                  <option value='' selected disabled>Seleccione un producto...</option>
                  <option *ngFor="let producto of _productos" [ngValue]="producto">
                      ({{ producto.codigoProducto }}) - {{ producto.nombreProducto }}
                  </option>
                </select>
                <div class="alert alert-danger"
                  *ngIf="(this.productoFormControl.invalid && this.productoFormControl.errors.required && (this.productoFormControl.dirty || this.productoFormControl.touched))">
                  Debe seleccionar un producto obligatoriamente.
                </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="mb-3 row col-md-12">
            <label for="cantidadFormControl" class="col-md-4 col-form-label">Cantidad:</label>
            <div class="col-md-8">
                <input class="form-control" formControlName="cantidadFormControl" maxlength="10" 
                  appOnlyNumber>
                <div class="alert alert-danger"
                  *ngIf="(this.cantidadFormControl.invalid && this.cantidadFormControl.errors.required && (this.cantidadFormControl.dirty || this.cantidadFormControl.touched))">
                  Ingrese una cantidad válida
                </div>
                <div class="alert alert-danger" *ngIf="form.invalid && (this.cantidadFormControl.dirty || this.cantidadFormControl.touched) && this.form.hasError('cantidadMayorACeroValidator')">
                  La cantidad debe ser mayor a cero
                </div>
            </div>
          </div>
        </div>
    
    </div><!-- Del modal body -->
  
    <div class="modal-footer bg-light">
 
      <span class="border">
        <button type="button" [disabled]="this.guardandoDatos" class="btn btn-primary" 
          (click)="onClick()">
          <span *ngIf="!guardandoDatos">Guardar</span>
            <div *ngIf="guardandoDatos" class="d-flex align-items-center">
              <div class="spinner-border text-light spinner-border-sm" role="status"></div>
              <span>Guardando...</span>
            </div>
        </button>
      </span>
  
      <span class="border">
        <button type="button" class="btn btn-secondary" [disabled]="this.guardandoDatos"
          (click)="activeModal.close(false)">Cancelar</button>
      </span>
    </div>
  </form>