import { Component, OnInit } from '@angular/core';
import { EstadoUsuario } from 'src/app/shared/enums/estadoUsuario';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { AccionLogin } from 'src/app/shared/enums/AccionLogin';
import { UsuarioService } from 'src/app/account/usuario.service';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent implements OnInit {

  enumAccionLogin = AccionLogin;
  accionLoginSubscription: Subscription;
  accionLogin: AccionLogin;

  username: String;
  nombreCompleto: String;
  email: String;
  dni: String;
  roles: String;

  TipoUsuario = TipoUsuario;

  constructor(public authService: AuthService, private usuarioService: UsuarioService) { 
    this.accionLoginSubscription = this.authService.authAccionLogin$.subscribe(accionLogin => {
      if(accionLogin != null) 
        this.accionLogin = accionLogin;
        this.username = usuarioService.getUsername();
        this.nombreCompleto = usuarioService.getNombresCompletosUsuario();
        //this.email = usuarioService.getEmailUsuario();
        this.dni = usuarioService.getDniUsuario();        
        this.roles = usuarioService.getMostrarRoles();
    });
  }

  obtenerNombreRoles(n:number) {
    return TipoUsuario[n]
  }

  ngOnInit() {
  }

  onCerrarSesionClick() {
    this.authService.signout();
    location.assign("#");
  }

}
