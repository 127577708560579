import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { UploadFileService } from '../upload-file.service';
import { UrlApi } from 'src/app/shared/enums/urlApi';

@Component({
  selector: 'app-usuarios-import',
  templateUrl: './usuarios-import.component.html',
  styleUrls: ['./usuarios-import.component.css']
})
export class UsuariosImportComponent implements OnInit {

  userFileName: string;
  profileForm: UntypedFormGroup;
  error: string;
  fileUpload = {status: '', message: '', filePath: '', length: 0 };
  selectedFile: boolean = false;
  archivoCargado: boolean = false;
  cargando: boolean;

  constructor(private fb: UntypedFormBuilder, private fileUploadService: UploadFileService) { }

  ngOnInit() {
    this.profileForm = this.fb.group({
      name: [''],
      profile: [''],
      inputFile: ['']
    });
  }

  onSelectedFile(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.profileForm.get('profile').setValue(file);
      this.profileForm.get('name').setValue(file.name);
      this.userFileName = file.name;
      this.selectedFile = true;
    }
  }

  onSubmit() {
    const formData = new FormData();
    formData.append('name', this.profileForm.get('name').value);
    formData.append('profile', this.profileForm.get('profile').value);

    this.fileUploadService.upload(formData, UrlApi.usuarios).subscribe(
      res => this.fileUpload = res,
      err => this.error = err
    );
  }

   /**
   * Limpiar pantalla
   * @param limpiarMensajes Determina si se limpia el div de mensajes
   */

  reset(limpiarMensajes: boolean = false, limpiarInput: boolean = false) {
    this.cargando = false;
    this.archivoCargado = true;
    if (limpiarInput) {
      this.archivoCargado = false;
      this.userFileName = '';
      // this.profileForm.get('profileControl').reset();
    }
    if (limpiarMensajes)
      this.fileUpload = { status: '', message: '', filePath: '', length: 0 };

      this.profileForm.get('profile').setValue('');
      this.profileForm.get('name').setValue('');
      this.profileForm.get('inputFile').setValue('');
  }


}
