import { Responsable } from "./Responsable";

export class Trasvase{
    codigoContenedorOrigen: string;
    volumen: number;
    volumen15: number;
    aforadorInicialOrigen: number;
    aforadorFinalOrigen: number;
    temperatura: number;
    densidad: number;
    codigoContenedorDestino: string;
    responsable: Responsable;
    picoId: string;
}