import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { NgbCalendar, NgbDatepickerConfig, NgbDateStruct, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { UsuarioService } from 'src/app/account/usuario.service';
import { ComprobanteService } from 'src/app/facturacion/comprobante.service';
import { CierreCaja } from 'src/app/shared/models/cierreTurno/CierreCaja';
import { RetiroEfectivo } from 'src/app/shared/models/cierreTurno/RetiroEfectivo';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { SharedService } from 'src/app/shared/shared.service';
import { MessageService } from 'src/app/shell/message.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-retiro-efectivo-cierre-turno',
  templateUrl: './retiro-efectivo-cierre-turno.component.html',
  styleUrls: ['./retiro-efectivo-cierre-turno.component.css']
})
export class RetiroEfectivoCierreTurnoComponent implements OnInit {

  fechaFormControlDesdeHasta = new UntypedFormControl();
  mensajeErrorExportar = "";
  model: NgbDateStruct;
  model1: NgbDateStruct;
  time: NgbTimeStruct;
  startDate: Date;
  endDate: Date;
  buscando: boolean = false;
  mensajeSpinner: string = "D e s c a r g a n d o";
  codigoAeroplanta: string;
  cambiandoAeroplanta: boolean = false;
  obteniendoCierres: boolean = false;
  cierresCaja: CierreCaja[] = [];
  cierresCajaSeleccionada: CierreCaja[] = [];
  totalCajas: number;
  realizandoCierre: boolean = false;

  busquedaFormControl = new UntypedFormControl();
  change$: Subscription;
  link: string;

  facturacionBaseURL = environment.apiServer.facturacionBaseUrl


  constructor(
    private usuarioService: UsuarioService,    
    private spinner: NgxSpinnerService,
    private comprobanteService: ComprobanteService,
    private messageService: MessageService,
    sharedService: SharedService) {
      this.change$ = sharedService.aeroplantaSeleccionada.subscribe(_=>{ 
        this.cambiarDeAeroplanta()
      });
  }

  // hooks

  ngOnInit() {
    this.codigoAeroplanta = this.usuarioService.getAeroplantaActual().codigoAeroplanta;
    this.mensajeSpinner = "C a r g a n d o . . .";
    this.totalCajas = 0;    
    setTimeout(() => {
      this.spinner.show('spinnerLista');
    });
    this.buscarCierres(this.usuarioService.getAeroplantaActual().codigoAeroplanta);
  }
  
  ngOnChanges(): void {
    this.ngOnInit();
  }

  ngOnDestroy() {
    this.change$.unsubscribe();
  }
  // form

  formatDate(year, month, day, hour, minute): string {
    return year + '-' + month.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + '-' + day.toLocaleString(undefined, { minimumIntegerDigits: 2 })
      + 'T' + hour.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + minute.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':00';
  }

  agregarCaja(i: number, event) {
    let y: number = 0;
    if (event.target.checked === true) {
      this.cierresCajaSeleccionada.push(this.cierresCaja[i]);
      this.totalCajas = this.totalCajas + this.cierresCaja[i].totalEfectivoIngresado;
    }
    if (event.target.checked === false && y !== -1) {
      y = this.cierresCajaSeleccionada.indexOf(this.cierresCaja[i]);
      this.cierresCajaSeleccionada.splice(y, 1);
      this.totalCajas = this.totalCajas - this.cierresCaja[i].totalEfectivoIngresado;
    }
  }
  // llamadas al service

  buscarCierres(codigoAeroplanta: string) {
    this.obteniendoCierres = true;
    this.spinner.show('spinnerLista');
    this.comprobanteService.getCierresCaja(codigoAeroplanta, null, null)    
      .subscribe(result => {
        this.cierresCaja = result.filter(r => r.retiroEfectivoId == "00000000-0000-0000-0000-000000000000" );
        this.obteniendoCierres = false;
        this.spinner.hide('spinnerLista');
      }, (err: ErrorModel) => {
        this.messageService.showErrorMessage('Hubo un error al obtener los cierres. ' + err.description);
        this.cierresCaja = [];
        this.obteniendoCierres = false;
        this.spinner.hide('spinnerLista');
      });      
  }

  realizarRetiro() {
    if (this.cierresCajaSeleccionada.length != 0) {
      this.realizandoCierre = true;
      let nuevoRetiro = new RetiroEfectivo();
      nuevoRetiro.cierresCajasIds = [];
      this.cierresCajaSeleccionada.map(x => nuevoRetiro.cierresCajasIds.push(x.id));
      var fecha = new Date();
      nuevoRetiro.fechaRetiro = this.formatDate(fecha.getFullYear(), fecha.getMonth() + 1, fecha.getDate(), fecha.getHours(), fecha.getMinutes());
      nuevoRetiro.responsable = this.usuarioService.getNombresCompletosUsuario();
      nuevoRetiro.linkRecibo = this.link;
      this.comprobanteService.postRetiroEfectivo(nuevoRetiro)
        .subscribe(result => {
          this.realizandoCierre = false;
          this.buscarCierres(this.usuarioService.getAeroplantaActual().codigoAeroplanta);
          this.totalCajas = 0;
          this.cierresCajaSeleccionada= [];
        }, (err: ErrorModel) => {
          this.messageService.showErrorMessage('Hubo un error al crear el retiro de efectivo. ' + err.message);
          this.realizandoCierre = false;
          this.totalCajas = 0;
          this.cierresCajaSeleccionada= [];
        });
    } else {
      this.messageService.showErrorMessage('Debe de haber al menos una caja tildada para realizar el retiro.')
    }
  }

  onArchivoSubido(event) {
    this.link = event;
  }


  // validaciones


  addValidationError(control: AbstractControl, validationName: string) {
    let errors = control.errors;
    if (errors == null)
      errors = { [validationName]: true };
    else
      errors[validationName] = true;
    control.setErrors(errors);
    control.markAsDirty();

  }

  mostrarResultados() {
    let mostrar: Boolean;
    mostrar = false;
    mostrar = (this.startDate < this.endDate);
    return mostrar;
  }



  fechaDesdeMayorQueHasta() {
    let esMayor: boolean = false;
    this.startDate = new Date(this.model.year, this.model.month - 1, this.model.day);
    this.endDate = new Date(this.model1.year, this.model1.month - 1, this.model1.day);
    if (this.startDate > this.endDate) { esMayor = true; }
    return esMayor;
  }

  // form

  /**
 * Convierte una NgbDateStruct y una NgbTimeStruct en un Date
 */
  dateFromDateTimeStruct(date: NgbDateStruct, hour: NgbTimeStruct): Date {
    if (date == null || hour == null)
      return null;
    let dateReturn = new Date();
    dateReturn.setDate(date.day);
    dateReturn.setMonth(date.month - 1);
    dateReturn.setFullYear(date.year);
    dateReturn.setHours(hour.hour);
    dateReturn.setMinutes(hour.minute);
    return dateReturn;
  }


  cambiarDeAeroplanta() {
    this.cambiandoAeroplanta = false;
    this.cambiandoAeroplanta = true;
    this.ngOnInit();
  }


  // getters

  get busqueda() {
    return this.busquedaFormControl;
  }

}
