<div class="modal-header bg-light">
  <h4 class="modal-title">Consultar cliente extranjero</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="modal.close()"></button>
</div>
<div class="modal-body" [formGroup]="clienteExtrajero">
  <div class="row mt-2">
    <div class="col col-4">
      <label class="col-form-label" for="cuitFormControl">*CUIT:</label>
    </div>
    <div class="col col-5">
      <input class="form-control" formControlName="cuitFormControl" required />
      <div *ngIf="cuitFormControl.invalid && cuitFormControl.errors.required && (cuitFormControl.dirty || cuitFormControl.touched)" class="alert alert-danger">
        Requerido
      </div>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col col-4">
      <label class="col-form-label">*Persona:</label>
    </div>
    <div class="col-8">
      <select class="form-select" id="tipoPersonaFormControl" formControlName="tipoPersonaFormControl">
        <option value="null" [ngValue]="null" selected disabled>Seleccione un tipo persona</option>
        <option *ngFor="let per of keysTipoPersona()" [ngValue]="tipoPersona[per]">
          {{per}}
        </option>
      </select>
      <div *ngIf="tipoPersonaFormControl.invalid && tipoPersonaFormControl.errors.required && (tipoPersonaFormControl.dirty || tipoPersonaFormControl.touched)" class="alert alert-danger">
        Requerido
      </div>
    </div>
  </div>
  <!--PAIS-->
  <div class="row mt-2">
    <div class="col-4">
      <label class="col-form-label" for="paisFormControl">*País:</label>
    </div>
    <div class="col-8">
      <input id="paisFormControl" type="text" class="form-control" formControlName="paisFormControl"
        placeholder="Seleccione una opcion..." [inputFormatter]="formatterPais" [(ngModel)]="modelPais"
        [resultFormatter]="formatterPais" [ngbTypeahead]="searchPais" (change)="changePais()" (selectItem)="selectPais($event)" required />
      <div *ngIf="paisFormControl.invalid && paisFormControl.errors.required && (paisFormControl.dirty || paisFormControl.touched)" class="alert alert-danger">
        Requerido
      </div>
      <div *ngIf="invalidPais" class="alert alert-danger">
        Debe ingresar un país válido.
      </div>
      <loading-text-spinner *ngIf="this.cargandoPaises" message="Obteniendo paises..." color="primary">
      </loading-text-spinner>
    </div>
  </div>
</div>
<div class="modal-footer bg-light justify-content-center">
  <button class="btn btn-primary me-2" (click)="buscarClienteExtranjero()" [disabled]="consultando || clienteExtrajero.invalid">
    <span *ngIf="!consultando">Consultar</span>
    <div *ngIf="consultando" class="d-flex align-items-center">
      <div class="spinner-border text-light spinner-border-sm" role="status"></div>
      <span>Consultando...</span>
    </div>
  </button>
  <button class="btn btn-secondary" (click)="modal.close()">Cancelar</button>
</div>