import { Injectable } from '@angular/core';
import { Message } from '../shared/models/Message';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { TipoMensaje } from '../shared/enums/tipoMensaje';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  messages: Message[] = [];
  success = new Subject<string>();
  successMessage: string;
  error = new Subject<string>();  
  errorMessage: string;
  warning = new Subject<string>();
  warningMessage: string;

  normalMessageLength = 5000;
  longMessageLength = 12000;

  constructor(private toastrService: ToastrService) {
    this.success.subscribe((message) => this.successMessage = message);
    this.error.subscribe((message) => this.errorMessage = message);
    this.warning.subscribe((message) => this.warningMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null);
    this.error.pipe(debounceTime(5000)).subscribe(() => this.errorMessage = null);
    //this.warning.pipe(debounceTime(5000)).subscribe(() => this.warningMessage = null);
  }

  /*
  add(message: string, tipo: TipoMensaje) {
    window.scrollTo(0, 0);    
    if(tipo == TipoMensaje.success){
      this.toastrService.success('', message, {
        timeOut: this.normalMessageLength,
        closeButton: true,
      });
    }else{
      this.messages.push(new Message(message, tipo, this.messages.length));
    }    
  }
  */

  showSuccessMessage(message: string) {
    this.toastrService.success('', message, {
      timeOut: this.normalMessageLength,
      closeButton: true,
    });
  }

  showSuccessLongMessage(message: string) {
    this.toastrService.success('', message, {
      timeOut: this.longMessageLength,
      closeButton: true,
    });
  }

  showWarningMessage(message: string, cerrar:boolean) {
    this.toastrService.warning('', message, {
      timeOut: this.normalMessageLength,
      closeButton: true,
      disableTimeOut: cerrar,
    });
  }
  
  showWarningLongMessage(message: string) {
    this.toastrService.warning('', message, {
      timeOut: this.longMessageLength,
      closeButton: true
    });
  }

  showErrorMessage(message: string) {
    this.toastrService.error('', message, {
      timeOut: this.normalMessageLength,
      closeButton: true,
    });
  }

  showErrorMessageClosed(message: string, disabled: boolean) {
    this.toastrService.error('', message, {
      timeOut: this.normalMessageLength,
      closeButton: true,
      disableTimeOut: disabled,
    });
  }

  showErrorLongMessage(message: string) {
    this.toastrService.error('', message, {
      timeOut: this.longMessageLength,
      closeButton: true,
    });
  }

  clear() {
    this.messages = [];
  }

  close(message: Message) {
    this.messages.splice(this.messages.indexOf(message), 1);
  }

}