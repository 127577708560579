<ngb-alert *ngIf="this.stockContenedores.length==0 && !this.obteniendoStockContenedores && !esperandoCarga"> no hay
  productos registrados
  para esta aeroplanta </ngb-alert>
<form class="mb-3">
  <div *ngIf="detalle">
    <app-tablero-detalle *ngIf="productoSeleccionado!=null" [productoSeleccionado]='productoSeleccionado' [canerias]="this.canerias">
    </app-tablero-detalle>
  </div>

  <div>
    <nav class="navbar navbar-light bg-light navbar-expand-lg mb-3">
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <h4 class="fw-bold aling-middle" *ngIf="!detalle && this.stockContenedores.length>0 && !esperandoCarga && !obteniendoStockContenedores">
        Todos los productos
      </h4>
    </nav>

    <form class="mb-3">
      <div class="col col-md-12">

        <div class="spinner-container" *ngIf="esperandoCarga">
          <ngx-spinner name="spinnerGrafico" bdOpacity=0.3bd
            Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
            <p style="color: white">{{messageStock}}</p>
          </ngx-spinner>          
        </div>

        <div [formGroup]="formGrafico" *ngIf="!detalle && this.stockContenedores.length>0 && !esperandoCarga && !obteniendoStockContenedores">
          <div formArrayName="graficosArray">
            <div id="controlDinamico" *ngFor="let u of formGrafico.get('graficosArray')['controls']; let index=index;"
              class="col col-md-4" style="display: inline-block;">
              <div [formGroupName]="index" *ngIf="!esperandoCarga">
                <div class="mb-3 card">
                  <div class="card-body">
                    <highcharts-chart [Highcharts]="Highcharts" [options]="graficasProducto[index]"
                      id="formGraficoStock" style="width: 100%; height: 400px; display: block;">
                    </highcharts-chart>
                  </div>
                  <div class="container text-center card-footer">
                    <button type="button" id="detalle" class="btn btn-secondary btn-sm" [disabled]="esperandoCarga"
                      ngDefaultControl (click)="mostrarDetalle(index)"><i>Detalle</i></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</form>


