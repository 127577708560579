<div class="modal-header bg-light">
  <h4 class="modal-title">Condición IIBB</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismissAll()"></button>
</div>
<div class="modal-body">
  <form [formGroup]="tablaIIBB">
    <table id="tabla" class="table table-sm table-striped table-hover">
      <thead class="text-center">
        <tr>
          <th>Provincia</th>
          <th>Condicion IIBB</th>
          <th>Numero IIBB</th>
          <th>Alicuota Dif.</th>
          <th>Fecha Desde</th>
          <th>Fecha Hasta</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let k of this.distintosIIBB; let i = index" class="text-center">
          <td>{{ this.distintosIIBB[i].provincia?.nombre }}</td>
          <td style="width: 16%;" style="width: 16%;"><select class="form-select" [disabled]="!usuarioModificaCampos()" [(ngModel)]="this.distintosIIBB[i].condicionIIBB" [ngModelOptions]="{standalone: true}"
              (blur)="actualizar(i, 'condicionIIBB', $event)">
              <option *ngFor="let con of keysCondicionIIBB()" [ngValue]="condIIBB[con]">{{con}}</option>
            </select></td>
          <td>
            <input type="text" class="form-control" [disabled]="!usuarioModificaCampos()"             
              (blur)="actualizar(i, 'numeroIIBB', $event)" maxlength="15"
              [value]="this.distintosIIBB[i].numeroIIBB">
          </td>
          <td [contentEditable]="usuarioModificaCampos()" (keyup)="changeValue2(i, 'alicuota', $event)"
            (blur)="actualizar2(i, 'alicuota', $event)">
            {{ this.distintosIIBB[i].alicuotaDiferencial ? this.distintosIIBB[i].alicuotaDiferencial.alicuota:0}}</td>
          <td style="width: 13%;" formArrayName="fechaDesdeFormControl">
            <input type="date" name="fd" ngbDatepicker #d="ngbDatepicker" class="form-control" [readOnly]="!usuarioModificaCampos()"
              formControlName="{{i}}" [(ngModel)]="this.distintosIIBB[i].fechaDesde" value="this.distintosIIBB[i].fechaDesde" Datepicker
              (blur)="actualizar2(i, 'fechaDesde', $event)"/>
          </td>
          <td style="width: 13%;" formArrayName="fechaHastaFormControl">
            <input type="date" name="fh" ngbDatepicker #h="ngbDatepicker" class="form-control" [readOnly]="!usuarioModificaCampos()"
              formControlName="{{i}}" [(ngModel)]="this.distintosIIBB[i].fechaHasta" value="this.distintosIIBB[i].fechaHasta" Datepicker
              (blur)="actualizar2(i, 'fechaHasta', $event)"/>
          </td>
        </tr>
      </tbody>
    </table>
  </form>
  <!-- <small class="text text-danger">*Campos obligatorios</small> -->
</div>
<div *ngIf="this.usuarioHabilitado()" class="modal-footer bg-light justify-content-center">
  <button class="btn btn-primary me-2" (click)="guardarCondicion()" [disabled]="this.guardando">
    <span *ngIf="!guardando">Guardar</span>
    <div *ngIf="guardando" class="d-flex align-items-center">
      <div class="spinner-border text-light spinner-border-sm" role="status"></div>
      <span>Guardando...</span>
    </div>
  </button>
  <button class="btn btn-secondary" (click)="this.activeModal.dismissAll();">Cancelar</button>
</div>
