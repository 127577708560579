import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { RubroService } from '../rubro.service';
import { MessageService } from 'src/app/shell/message.service';
import { RubrosListaComponent } from '../rubros-lista/rubros-lista.component';
import { Rubro } from 'src/app/shared/models/abm/Rubro';
import { TipoNeto } from 'src/app/shared/enums/tipoNeto';


@Component({
  selector: 'app-informacion-rubro',
  templateUrl: './informacion-rubro.component.html',
  styleUrls: ['./informacion-rubro.component.css']
})
export class InformacionRubroComponent implements OnInit {

  informacionForm = new UntypedFormGroup({
    codigoFormControl: new UntypedFormControl({value: null, disabled: true}, Validators.required),
    tipoNetoRubroFormControl: new UntypedFormControl(null),
    descripcionFormControl: new UntypedFormControl(null),
    combustibleFormControl: new UntypedFormControl(null)
  });

  tipoNeto = TipoNeto;
  rubros: Rubro[];
  modificandoRubro:boolean = false;

  @Input() rubroSeleccionado: Rubro;

  constructor(private rubroService: RubroService,
     private messageService: MessageService,
     private listaDeRubros: RubrosListaComponent)
  {

  }

  ngOnInit() {
    this.tipoNetoRubro.setValue(this.rubroSeleccionado.tipoNeto);
    this.codigo.setValue(this.rubroSeleccionado.codigoRubro);
    this.descripcion.setValue(this.rubroSeleccionado.nombre);
    if (this.rubroSeleccionado.aerocombustible){
      this.combustibleFormControl.setValue("aerocombustible")
    } else if (this.rubroSeleccionado.aeroespecialidad){
      this.combustibleFormControl.setValue("aeroespecialidad")
    } else if (this.rubroSeleccionado.combustible){
      this.combustibleFormControl.setValue("combustible")
    } else if (this.rubroSeleccionado.servicios){
      this.combustibleFormControl.setValue("servicios")
    }
  }

  getRubros() {
    this.rubroService.getRubros()
    .subscribe(result => {
      this.rubros = result;
      this.obtenerRubroSeleccionado();
     }, );
  }

  obtenerPosicionRubroSeleccionado(): number
  {
    let i=0;
    for (let rubro of this.rubros) {
      if (rubro.id === this.rubroSeleccionado.id)
      {
        return i;
      }
      i++;
     }
     return i;
  }

  obtenerRubroSeleccionado()
  {
    this.rubroService.getRubroPorId(this.rubroSeleccionado.id)
    .subscribe(result => {
      this.rubroSeleccionado = result;
     }, );
  }

  ngOnChanges(): void {
    //this.combustibleFormControl.setValue(false);
   this.ngOnInit();
  }

  keysTiposNeto(): Array<string> {
    var keys = Object.keys(this.tipoNeto);
    keys = keys.slice(keys.length / 2);
    return keys;
  }

  modificarRubro()
  {
    this.modificandoRubro = true;
    let r : Rubro = new Rubro();
    r.codigoRubro = this.codigo.value;
    if (this.codigo.value === null)
    {
      r.codigoRubro = this.rubroSeleccionado.codigoRubro;
      this.codigo.setValue(r.codigoRubro);
    }
    r.nombre = this.descripcion.value;
    if (this.descripcion.value === null)
    {
      r.nombre = this.rubroSeleccionado.nombre;
      this.descripcion.setValue(r.nombre);
    }
    r.impuestosRubro = [];
    r.activo = true; //No se puede modificar este valor, siempre true.
    r.id = this.rubroSeleccionado.id;
    r.tipoNeto = this.tipoNetoRubro.value;
    switch(this.combustibleFormControl.value){
      case "combustible":
        r.aeroespecialidad = false;
        r.aerocombustible = false;
        r.combustible = true;
        r.servicios = false;
        break;
      case "aeroespecialidad":
        r.aeroespecialidad = true;
        r.aerocombustible = false;
        r.combustible = false;
        r.servicios = false;
        break;
      case "aerocombustible":
        r.aeroespecialidad = false;
        r.aerocombustible = true;
        r.combustible = false;
        r.servicios = false;
        break;
      case "servicios":
        r.aeroespecialidad = false;
        r.aerocombustible = false;
        r.combustible = false;
        r.servicios = true;
        break;
    }
    if (this.camposVacios()){
      this.messageService.showErrorMessage("Por favor complete todos los campos");
      this.modificandoRubro = false;
    }
    else
    {
      this.rubroService.modificarRubro(r)
      .subscribe(result => {
        console.log('Returning ' + result);
        this.messageService.showSuccessMessage("Se ha modificado el rubro correctamente");
        this.modificandoRubro = false;
      }, () => {

      });
    }
  }

  esCampoVacio(campo: any): boolean {

    return (campo == null || (campo != null && campo.length == 0));
  }

  campoInicializadoEnVacio(campo: any): boolean {

    return (campo != null && campo === '' ) ;
  }
  camposVacios()
  {
    return this.esCampoVacio(this.codigo.value) || this.esCampoVacio(this.descripcion.value);
  }
  get codigo() {
    return this.informacionForm.get('codigoFormControl');
  }
  get descripcion() {
    return this.informacionForm.get('descripcionFormControl');
  }
  get combustibleFormControl() {
    return this.informacionForm.get('combustibleFormControl');
  }
  get tipoNetoRubro() {
    return this.informacionForm.get('tipoNetoRubroFormControl');
  }
}
