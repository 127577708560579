import { Component, OnInit, Inject, LOCALE_ID } from '@angular/core';
import { Producto } from 'src/app/shared/models/abm/Producto';
import { ProductoService } from 'src/app/abm/producto/producto.service';
import { Aeroplanta } from 'src/app/shared/models/despacho/Aeroplanta';
import { NgxSpinnerService } from 'ngx-spinner';
import { formatDate } from '@angular/common';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbDateStruct, NgbCalendar, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { TipoPago } from 'src/app/shared/enums/tipoPago';
import { CondicionVenta } from 'src/app/shared/enums/condicionVenta';
import { ExportarRemitos } from 'src/app/shared/models/facturacion/ExportarRemitos';
import { saveAs } from 'file-saver';
import { ClienteService } from 'src/app/cliente/cliente.service';
import { ClienteEncabezado } from 'src/app/shared/models/cliente/ClienteEncabezado';
import { ComprobanteService } from '../../comprobante.service';
import { RanchoService } from 'src/app/rancho/rancho.service';
import { Rancho } from 'src/app/shared/models/ranchos/Rancho';
import { PreciosService } from 'src/app/precios/precios.service';

@Component({
  selector: 'app-reporte-facturas',
  templateUrl: './reporte-facturas.component.html',
  styleUrls: ['./reporte-facturas.component.css']
})
export class ReporteFacturasComponent implements OnInit {

  esperandoCarga: boolean = true;
  productos: Producto[];
  aeroplantas: Aeroplanta[];
  cargandoProductos: boolean = false;
  cargandoAeroplanta: boolean = false;
  cliente: ClienteEncabezado[] = [];
  ranchos: Rancho[] = [];
  ranchosFiltrados: Rancho[] = [];
  clientesFiltrados: ClienteEncabezado[] = [];
  modelDesde: NgbDateStruct;
  modelHasta: NgbDateStruct;
  fechaDesde: Date;
  fechaHasta: Date;
  modelCliente: any;
  modelRancho: any;
  cargandoClientes: boolean = false;
  tipoPago = TipoPago;
  productosParaCheckear: any[] = [];
  aeroplantasParaCheckear: any[] = [];
  condicionesDeVentaParaCheckear: any[] = [];
  original = { nombre: "Original", codigo: "1" };
  refacturada = { nombre: "Refacturada", codigo: "0" };
  estadosParaCheckear = [this.original, this.refacturada];
  ordenCliente = { nombre: "Cliente", codigo: "0" };
  ordenCuenta = { nombre: "Cuenta", codigo: "1" };
  ordenParaCheckear = [this.ordenCliente, this.ordenCuenta];
  labelPosition: 'Cliente' | 'Cuenta' = 'Cliente';
  condicionesVenta: CondicionVenta;

  //inputs para el botón descargar
  listaDeAeroplantasSeleccionadas: string[] = [];
  listaDeProductosSeleccionados: string[] = [];
  listaDeEstadosSeleccionados: number[] = [];
  listaDeCondicionesDeVentaSeleccionados: number[] = [];
  listaDeOrdenes: any[] = [];


  // formularios para seleccion múltiple
  checkMultiplesProductos: UntypedFormGroup;
  checkMultiplesAeroplantas: UntypedFormGroup;
  checkMultiplesCondicionesDeVenta: UntypedFormGroup;
  checkMultiplesEstados: UntypedFormGroup;
  // form check seleccion simple
  checkOrden: UntypedFormGroup;


  condiciones: any[] = [    
    { "nombre": 'Contado', "codigo": 1, "seleccionado": false },
    { "nombre": 'Cuenta Corriente', "codigo": 2, "seleccionado": false },
    { "nombre": 'PrevioPago', "codigo": 3, "seleccionado": false },
    { "nombre": 'Donacion', "codigo": 4, "seleccionado": false },
    { "nombre": 'Consumo Interno', "codigo": 5, "seleccionado": false },
  ];

  busquedasCliente = new UntypedFormGroup({
    clienteFormControl: new UntypedFormControl(),
    ranchoFormControl: new UntypedFormControl(),
    aeroplantaFormControl: new UntypedFormControl(),
    productoFormControl: new UntypedFormControl(),
  })

  fechaForm = new UntypedFormGroup({
    fechaDesdeFormControl: new UntypedFormControl(),
    fechaHastaFormControl: new UntypedFormControl(),
  })

  errorEnExportacion: boolean;

  constructor(private productoService: ProductoService, 
    private precioService: PreciosService,    
    private facturacionService: ComprobanteService,
    private spinner: NgxSpinnerService, 
    private calendar: NgbCalendar,
    private datepickerConfig: NgbDatepickerConfig,
    @Inject(LOCALE_ID) public locale: string, private fb: UntypedFormBuilder,
    private clienteService: ClienteService, private ranchoService: RanchoService) { }


  // hooks

  ngOnInit() {
    this.spinner.show('spinnerReporte');
    this.esperandoCarga = true;

    this.checkMultiplesProductos = this.fb.group({
      nombreProducto: this.fb.array([])
    });

    this.checkMultiplesAeroplantas = this.fb.group({
      nombreAeroplanta: this.fb.array([])
    });

    this.checkMultiplesEstados = this.fb.group({
      numeroEstado: this.fb.array([])
    });

    this.checkMultiplesCondicionesDeVenta = this.fb.group({
      nombreCondicion: this.fb.array([])
    });
    this.checkOrden = this.fb.group({
      nombreOrden: new UntypedFormControl()
    });

    this.getProductos();
    this.getAeroplantas();
    this.getClientes();
    this.getRanchos();
    this.getCondicionesDeVenta();
    this.checkOrdenForm.setValue(1);
    this.modelDesde = this.calendar.getToday();
    this.fechaDesde = new Date(this.modelDesde.year, this.modelDesde.month - 1, this.modelDesde.day);
    this.modelHasta = this.calendar.getToday();
    this.fechaHasta = new Date(this.modelHasta.year, this.modelHasta.month - 1, this.modelHasta.day);
    
    let inicio: any = {day: 1, month: 1, year: 2019 };
    let fin: any = { day: this.modelHasta.day, month: this.modelHasta.month, year: this.modelHasta.year};

    this.datepickerConfig.minDate = inicio;    
    this.datepickerConfig.maxDate = fin;
  }

  ngOnChanges(): void {
    this.ngOnInit();
  }

  // form

  getCondicionesDeVenta() {
    this.cargandoProductos = true;
    for (let prod of this.condiciones) {
      this.condicionesDeVentaParaCheckear.push({ "nombre": prod.nombre, "codigo": prod.codigo });
    }
  }

  formatDate(year, month, day, hour, minute): string {
    return year + '-' + month.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + '-' + day.toLocaleString(undefined, { minimumIntegerDigits: 2 })
      + 'T' + hour.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + minute.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':00';
  }

  formatter = (x: { razonSocial: string, ctA_SGC: string }) => `${x.razonSocial}`;

  formatterRancho = (x: { codigoRancho: string }) => `${x.codigoRancho}`;

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 || this.cargandoClientes ? [] :
        this.cliente.filter(v => this.containsString(this.clienteFormControl.value, v.ctaSap, v.razonSocial)).slice(0, 10))

    )

  searchRancho = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 || this.cargandoClientes ? [] :
        this.ranchos.filter(v => this.containsString(this.ranchoFormControl.value, v.codigoRancho)).slice(0, 10))

    )

  selectCustomer(e: any): void {
    this.filtroClientes();
  }
  selectRancho(e: any): void {
    this.filtroRanchos();
  }
  /**
  * Determina si una cadena se encuentra dentro de otra
  * @param searchValue Valor a buscar
  * @param searchIn Valores sobre los que buscar searchValue
  */
  containsString(searchValue: string, ...searchIn: string[]): boolean {
    const concat = searchIn.toString();
    return concat.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
  }

  filtroClientes() {
    this.clientesFiltrados = this.cliente.filter(a => {
      let agregar = true;
      if (this.clienteFormControl.value != null) {
        agregar = a.razonSocial.toLowerCase().indexOf(this.clienteFormControl.value.razonSocial) > -1;
        agregar = a.ctaSap === this.clienteFormControl.value.ctaSap;
      } else {
        this.clientesFiltrados = this.cliente;
      }
      return agregar;
    });
  }

  filtroRanchos() {
    this.ranchosFiltrados = this.ranchos.filter(a => {
      let agregar = true;
      if (this.ranchoFormControl.value != null) {
        agregar = a.codigoRancho.toLowerCase().indexOf(this.ranchoFormControl.value.codigoRancho) > -1;
        // agregar = a.===this.clienteFormControl.value.ctaSap;
      } else {
        this.ranchosFiltrados = this.ranchos;
      }
      return agregar;
    });
  }

  // obtencion de seleccion de checks

  seleccionarAeroplanta(codigoAeroplanta: string, isChecked: boolean) {
    const listaFormArray = <UntypedFormArray>this.checkMultiplesAeroplantas.controls.nombreAeroplanta;

    if (isChecked) {
      listaFormArray.push(new UntypedFormControl(codigoAeroplanta));
      this.listaDeAeroplantasSeleccionadas.push(codigoAeroplanta);
    } else {
      let index = listaFormArray.controls.findIndex(x => x.value == codigoAeroplanta)
      this.listaDeAeroplantasSeleccionadas.splice(index, 1);
      listaFormArray.removeAt(index);
    }
  }

  seleccionarProducto(codigoProducto: string, isChecked: boolean) {
    const listaFormArray = <UntypedFormArray>this.checkMultiplesProductos.controls.nombreProducto;

    if (isChecked) {
      listaFormArray.push(new UntypedFormControl(codigoProducto));
      this.listaDeProductosSeleccionados.push(codigoProducto);
    } else {
      let index = listaFormArray.controls.findIndex(x => x.value == codigoProducto)
      this.listaDeProductosSeleccionados.splice(index, 1);
      listaFormArray.removeAt(index);
    }
  }

  seleccionarEstados(codigoEstado: any, isChecked: boolean) {
    const listaFormArray = <UntypedFormArray>this.checkMultiplesEstados.controls.numeroEstado;

    if (isChecked) {
      listaFormArray.push(new UntypedFormControl(codigoEstado));
      this.listaDeEstadosSeleccionados.push(Number(codigoEstado));
    } else {
      let index = listaFormArray.controls.findIndex(x => x.value == codigoEstado)
      this.listaDeEstadosSeleccionados.splice(index, 1);
      listaFormArray.removeAt(index);
    }
  }

  seleccionarCondicionDeVenta(codigoCondicion: any, isChecked: boolean) {
    const listaFormArray = <UntypedFormArray>this.checkMultiplesCondicionesDeVenta.controls.nombreCondicion;

    if (isChecked) {
      listaFormArray.push(new UntypedFormControl(codigoCondicion));
      this.listaDeCondicionesDeVentaSeleccionados.push(codigoCondicion);
    } else {
      let index = listaFormArray.controls.findIndex(x => x.value == codigoCondicion)
      listaFormArray.removeAt(index);
      this.listaDeCondicionesDeVentaSeleccionados.splice(index, 1);
    }

  }

  seleccionarOrden(codigoOrden: any, isChecked: boolean) {
    const listaFormArray = <UntypedFormArray>this.checkOrden.controls.nombreOrden;
    listaFormArray.push(new UntypedFormControl(codigoOrden));
    let index = listaFormArray.controls.findIndex(x => x.value == codigoOrden)
    this.listaDeOrdenes = listaFormArray.value;
  }


  // validaciones

  fechaDesdeMayorQueHasta() {
    let esMayor: boolean = false;
    this.fechaDesde = new Date(this.modelDesde.year, this.modelDesde.month - 1, this.modelDesde.day);
    this.fechaHasta = new Date(this.modelHasta.year, this.modelHasta.month - 1, this.modelHasta.day);
    if (this.fechaDesde > this.fechaHasta) { esMayor = true; }
    return esMayor;
  }


  // llamadas al service

  getClientes() {
    this.esperandoCarga = true;
    this.clienteService.getClientesEncabezado()
      .subscribe(result => {
        this.cliente = result.sort((a, b) => { if (a.razonSocial > b.razonSocial) { return 1 } else { return -1 } });
        this.clientesFiltrados = this.cliente;

      })
  }

  getRanchos() {
    this.esperandoCarga = true;
    this.ranchoService.getRanchos("", "", "EZE") // se deja seteado eze hasta que haya un endpoint con aeroplantas seleccionadas
      .subscribe(result => {
        this.ranchos = result;
        this.ranchosFiltrados = result;
      })
  }


  getProductos() {
    this.cargandoProductos = true;
    this.productoService.obtenerProductos()
      .subscribe(result => {
        this.productos = result;
        this.productosParaCheckear = [];
        for (let prod of this.productos) {
          this.productosParaCheckear.push({ "nombre": prod.nombreProducto, "codigo": prod.codigoProducto, "seleccionado": false });
        }
        this.cargandoProductos = false;
      }, () => {

      });
  }

  getAeroplantas() {
    this.cargandoAeroplanta = true;
    this.precioService.obtenerTodasLasAeroplantas()
      .subscribe(result => {
        this.aeroplantas = result.sort((a, b) => { if (a.nombre > b.nombre) { return 1 } else { return -1 } });
        for (let aero of this.aeroplantas) {
          this.aeroplantasParaCheckear.push({ "nombre": aero.nombre, "codigo": aero.codigoAeroplanta });
        }
        this.cargandoAeroplanta = false;
        this.esperandoCarga = false;
        this.spinner.hide('spinnerReporte')

      });
  }

  descargar() {
    this.errorEnExportacion = false;
    this.fechaDesde = new Date(this.modelDesde.year, this.modelDesde.month - 1, this.modelDesde.day);
    this.fechaHasta = new Date(this.modelHasta.year, this.modelHasta.month - 1, this.modelHasta.day);
    this.fechaHasta.setHours(23, 59, 59, 99);
    let busquedaAExportar: ExportarRemitos;
    busquedaAExportar = new ExportarRemitos();
    busquedaAExportar.codigosAeroplantas = this.listaDeAeroplantasSeleccionadas;
    busquedaAExportar.codigosProductos = this.listaDeProductosSeleccionados;
    busquedaAExportar.condicionVenta = this.listaDeCondicionesDeVentaSeleccionados;
    busquedaAExportar.estados = this.listaDeEstadosSeleccionados;


    if (this.clienteFormControl.value != null) {
      busquedaAExportar.tipoDocumento = this.clienteFormControl.value.tipoDocumento;
      busquedaAExportar.numeroDocumento = this.clienteFormControl.value.numeroDocumento;

    }

    busquedaAExportar.fechaDesde = this.formatDate(this.fechaDesdeForm.value.year, this.fechaDesdeForm.value.month, this.fechaDesdeForm.value.day, 0, 0);
    busquedaAExportar.fechaHasta = this.formatDate(this.fechaHastaForm.value.year, this.fechaHastaForm.value.month, this.fechaHastaForm.value.day, 23, 59);
    busquedaAExportar.ordenamiento = Number(this.checkOrdenForm.value);


    this.facturacionService.exportarFacturas(busquedaAExportar) // cambiar por endpoint para exportar facturas
      .subscribe((res: Blob) => {
        const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
        saveAs(blob, "ListadoDeFacturas.xls");
      }, () => {
        this.errorEnExportacion = true;
      });
  }

  //getters forms

  get fechaDesdeString() {
    return this.formatDate(this.fechaDesdeForm.value.year, this.fechaDesdeForm.value.month, this.fechaDesdeForm.value.day, 0, 0);
  }
  get fechaHastaString() {
    return this.formatDate(this.fechaHastaForm.value.year, this.fechaHastaForm.value.month, this.fechaHastaForm.value.day, 23, 59);
  }

  get clienteFormControl() {
    return this.busquedasCliente.get('clienteFormControl');
  }
  get ranchoFormControl() {
    return this.busquedasCliente.get('ranchoFormControl');
  }
  get aeroplantaFormControl() {
    return this.busquedasCliente.get('aeroplantaFormControl');
  }
  get productoFormControl() {
    return this.busquedasCliente.get('productoFormControl');
  }
  get fechaDesdeForm() {
    return this.fechaForm.get('fechaDesdeFormControl');
  }
  get fechaHastaForm() {
    return this.fechaForm.get('fechaHastaFormControl');
  }
  get checkOrdenForm() {
    return this.checkOrden.get('nombreOrden');
  }
}
