<nav class="navbar navbar-light bg-light navbar-expand-lg">
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav">
            <!--buscador -->
            <li class="nav-item me-2">
                <div class="nav-item">                
                    <input id="busquedaFormControl" type="text" class="form-control" placeholder="Buscador"
                        [formControl]="busquedaFormControl" (input)="filtrarProductos()" />
                </div>
            </li>
        </ul>
    </div>
</nav>
<div class="row">  
    <div class="col col-3">
        <div class="list-group py-3 menu-izq">
            <app-consolidado-lista              
                [productoBuscado]="this.productoBuscado"
                (productoSeleccionado)="usuarioSeleccionoProducto($event)"
            ></app-consolidado-lista>            
        </div>
    </div>
    <!-- informacion de saldos en cuentas corrientes -->
    <div class="col col-9 py-3">
        <div *ngIf="this.productoSeleccionado!=null" id="tablaInformacion">
            <app-consolidado-detalle
                [producto]="this.productoSeleccionado"         
            ></app-consolidado-detalle>
        </div>
    </div>
</div>