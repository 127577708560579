import { parametroImpositivo } from './ParametroImpositivo';

export class Rubro {
  id: string;
  codigoRubro: string;
  nombre: string;
  activo: boolean;
  tipoNeto: number;// NetoConceptosFinancieros = 0, NetoCombustibles = 1, NetoLubricantes = 2, NetoMercaderia = 3, NetoCigarrillos = 4, NetoNoGravado = 5
  combustible: boolean;
  aerocombustible: boolean;
  aeroespecialidad: boolean;
  servicios: boolean;
  impuestosRubro: parametroImpositivo[];
}

