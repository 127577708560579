import { ChangeDetectorRef, Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { EstadoCierre } from 'src/app/shared/enums/estadoCierre';
import { TipoComparativa } from 'src/app/shared/enums/TipoComparativa';
import { ExportExcelServiceService } from 'src/app/shared/exportar-excel/export-excel-service.service';
import { CierreTurno } from 'src/app/shared/models/cierreTurno/CierreTurno';
import { Comparativa } from 'src/app/shared/models/cierreTurno/Comparativa';
import { ComparativaExpendedora } from 'src/app/shared/models/cierreTurno/ComparativaExpendedora';
import { MovimientoAforador } from 'src/app/shared/models/cierreTurno/MovimientoAforador';
import { ParteDiario } from 'src/app/shared/models/cierreTurno/ParteDiario';
import { ReporteMovimiento } from 'src/app/shared/models/cierreTurno/ReporteMovimiento';
import { TotalProducto } from 'src/app/shared/models/cierreTurno/TotalProducto';
import { MessageService } from 'src/app/shell/message.service';
import { StockService } from 'src/app/stock/stock.service';
import { CierreTurnoService } from '../../cierre-turno.service';

@Component({
  selector: 'app-detalle-reporte-cierre',
  templateUrl: './detalle-reporte-cierre.component.html',
  styleUrls: ['./detalle-reporte-cierre.component.scss']
})
export class DetalleReporteCierreComponent implements OnInit {
  
  @Input() cierreSeleccionado: CierreTurno;

  constructor(
    private messageService: MessageService, 
    
    private stockService: StockService, 
    private cierreService: CierreTurnoService,
    
    private excelService: ExportExcelServiceService,
    private spinner: NgxSpinnerService, 
    private changeDetector: ChangeDetectorRef) { }

  EstadoCierre = EstadoCierre;

  comparativasTanques15: Comparativa[] = [];
  comparativaTanqueNatural: Comparativa[] = [];
  comparativaAeroespecialidades: Comparativa[] = [];
  comparativaAbastecedora: ComparativaExpendedora[] = [];
  distintosProducto: string[] = [];
  cargando: boolean = false;
  movimientosCierre: ReporteMovimiento[] = [];
  reporteSeleccionado: number;
  //buscandoDetalle: boolean = false;
  //buscandoDetalleProducto: boolean = false;
  //buscandoDetalleCorrelatividad: boolean = false;
  generandoReporte: boolean = false;
  ejecutarAforadores: boolean = false;
  ejecutarMovimientosProducto: boolean = false;
  ejecutarRanchos: boolean = false;
  ejecutarAerovales: boolean = false;
  ejecutarStock: boolean = false;
  // cargando: boolean = false;

  reporteMovimientoProd: ReporteMovimiento[] = [];
  reporteCorrelatividad: MovimientoAforador[] = [];

  totalProductos15: TotalProducto[] = [];
  totalProductosNatural: TotalProducto[] = [];
  TipoComparativa = TipoComparativa;

  natural: boolean[]=[];
  grado15: boolean[]=[];
  
  mostrarParteDiario: boolean = false;
  
  ngOnInit() {
    
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }
  
  ngOnChanges() {
    this.reporteSeleccionado = 0;
    this.cargando = true;
    this.reporteMovimientoProd = [];
    this.reporteCorrelatividad = [];
    this.movimientosCierre = [];
    this.distintosProducto = [];
    this.comparativasTanques15 = this.cierreSeleccionado.comparativas.filter(a => a.tipoComparativa == TipoComparativa.Tanque15);
    this.comparativasTanques15.sort((a , b) => a.nombreContenedor.toLocaleUpperCase() > b.nombreContenedor.toLocaleUpperCase() ? 1 : -1);
    this.comparativaTanqueNatural = this.cierreSeleccionado.comparativas.filter(a => a.tipoComparativa == TipoComparativa.TanquesNaturales);
    this.comparativasTanques15.sort((a , b) => a.nombreContenedor.toLocaleUpperCase() > b.nombreContenedor.toLocaleUpperCase() ? 1 : -1);
    this.comparativaAeroespecialidades = this.cierreSeleccionado.comparativas.filter(a => a.tipoComparativa == TipoComparativa.Aeroespecialidades);
    this.comparativaAeroespecialidades.sort((a , b) => a.codigoProducto.toLocaleUpperCase() > b.codigoProducto.toLocaleUpperCase() ? 1 : -1);
    this.comparativaAbastecedora = this.cierreSeleccionado.comparativasExpendedoras;
    this.comparativaAbastecedora.sort((a , b) => a.nombreExpendedora.toLocaleUpperCase() > b.nombreExpendedora.toLocaleUpperCase() ? 1 : -1);
    this.totalProductos15 = this.cierreSeleccionado.totalesProductos.filter( p => p .tipoComparativa == TipoComparativa.Tanque15);
    this.totalProductos15.sort((a , b) => a.nombreProducto.toLocaleUpperCase() > b.nombreProducto.toLocaleUpperCase() ? 1 : -1);
    this.totalProductosNatural = this.cierreSeleccionado.totalesProductos.filter( p => p .tipoComparativa == TipoComparativa.TanquesNaturales);
    this.totalProductosNatural.sort((a , b) => a.nombreProducto.toLocaleUpperCase() > b.nombreProducto.toLocaleUpperCase() ? 1 : -1);
    for (let com of this.cierreSeleccionado.comparativas) {
      if (com.tipoComparativa != TipoComparativa.Aeroespecialidades) {
        if (!this.distintosProducto.find(a => a == com.codigoProducto + ' - ' + com.nombreProducto)) {
          this.distintosProducto.push(com.codigoProducto + ' - ' + com.nombreProducto);
          this.natural.push(true);
          this.grado15.push(true);
        }
      }
    }
    if (this.cierreSeleccionado.estadoCierre == 2) {
      this.reporteSeleccionado = 1;      
      this.mostrarSpinner();
    }
    this.cierreSeleccionado.precierres.sort((a , b) => a.nombreExpendedora.toLocaleUpperCase() > b.nombreExpendedora.toLocaleUpperCase() ? 1 : -1);
    this.cierreSeleccionado.medicionesTanques.sort((a , b) => a.nombreTanque.toLocaleUpperCase() > b.nombreTanque.toLocaleUpperCase() ? 1 : -1);
    this.cierreSeleccionado.aeroespecialidades.sort((a , b) => a.codigoAeroespecialidad.toLocaleUpperCase() > b.codigoAeroespecialidad.toLocaleUpperCase() ? 1 : -1);
    /*this.buscarDetalleMovimientos();
    this.buscarMovimientosProducto();
    this.buscarCorrelatividadAforadores();*/
    this.generarReporteMovimientos();    
    this.mostrarParteDiario = this.cierreSeleccionado.parteDiario!=null;
    this.cargando = false;
  }

  mostrarSpinner() {
    this.generandoReporte = true;
    this.spinner.show('spinnerLista');
  }

  ocultarSpinner() {
    this.generandoReporte = false;
    this.spinner.hide('spinnerLista');
  }

  async generarReporteMovimientos() {
    try {
      this.mostrarSpinner();

      this.movimientosCierre = await this.stockService.getMovimientosAsync(this.cierreSeleccionado);
  
      this.reporteMovimientoProd = await this.stockService.getReporteMovimientoProductoAsync(this.cierreSeleccionado, this.cierreSeleccionado.aeroplanta.codigo);

      this.reporteCorrelatividad = await this.stockService.getReporteCorrelatividadAsync(this.cierreSeleccionado, this.cierreSeleccionado.aeroplanta.codigo);

      this.ocultarSpinner();   

    } catch (error) {    
      this.ocultarSpinner();
      this.messageService.showErrorMessage("Error: " + error.message + " descripcion: " + error.descripcion);
    }
  }

  /*buscarDetalleMovimientos() {
    this.buscandoDetalle = true;
    this.stockService.getMovimientos(this.cierreSeleccionado)
      .subscribe(result => {
        this.movimientosCierre = result;
        this.buscandoDetalle = false;
        if (!this.buscandoDetalle && !this.buscandoDetalleProducto && !this.buscandoDetalleCorrelatividad) {
          this.spinner.hide('spinnerLista');
        }
      }, () => {
        this.buscandoDetalle = false;
        if (!this.buscandoDetalle && !this.buscandoDetalleProducto && !this.buscandoDetalleCorrelatividad) {
          this.spinner.hide('spinnerLista');
        }
      });
  }*/

  /*buscarMovimientosProducto() {
    this.buscandoDetalleProducto = true;
    this.stockService.getReporteMovimientoProducto(this.cierreSeleccionado, this.cierreSeleccionado.aeroplanta.codigo)
      .subscribe(result2 => {
        this.reporteMovimientoProd = result2;
        this.buscandoDetalleProducto = false;
        if (!this.buscandoDetalle && !this.buscandoDetalleProducto && !this.buscandoDetalleCorrelatividad) {
          this.spinner.hide('spinnerLista');
        }
      }, () => {
        this.buscandoDetalleProducto = false;
        if (!this.buscandoDetalle && !this.buscandoDetalleProducto && !this.buscandoDetalleCorrelatividad) {
          this.spinner.hide('spinnerLista');
        }
      });
  }*/

  /*buscarCorrelatividadAforadores() {
    this.buscandoDetalleCorrelatividad = true;
    this.stockService.getReporteCorrelatividad(this.cierreSeleccionado, this.cierreSeleccionado.aeroplanta.codigo)
      .subscribe(result => {
        this.reporteCorrelatividad = result;
        this.buscandoDetalleCorrelatividad = false;
        if (!this.buscandoDetalle && !this.buscandoDetalleProducto && !this.buscandoDetalleCorrelatividad) {
          this.spinner.hide('spinnerLista');
        }
      }, () => {
        this.buscandoDetalleCorrelatividad = false;
        if (!this.buscandoDetalle && !this.buscandoDetalleProducto && !this.buscandoDetalleCorrelatividad) {
          this.spinner.hide('spinnerLista');
        }
      });
  }*/

  arregloDistintoProdNatural(dist: string): Comparativa[] {
    let comp: Comparativa[] = [];
    for (let p of this.comparativaTanqueNatural) {
      if (p.codigoProducto + ' - ' + p.nombreProducto == dist) {
        // comp.push(new Comparativa());
        comp.push(p)
      }
    }
    comp.sort((a , b) => a.nombreContenedor.toLocaleUpperCase() > b.nombreContenedor.toLocaleUpperCase() ? 1 : -1);
    return comp
  }

  arregloDistintoProd15(dist: string): Comparativa[] {
    let comp: Comparativa[] = [];
    for (let p of this.comparativasTanques15) {
      if (p.codigoProducto + ' - ' + p.nombreProducto == dist) {
        // comp.push(new Comparativa());
        comp.push(p)
      }
    }
    comp.sort((a , b) => a.nombreContenedor.toLocaleUpperCase() > b.nombreContenedor.toLocaleUpperCase() ? 1 : -1);
    return comp
  }

  cambiar(i: number) {
    this.reporteSeleccionado = i;
  }

  sumar(dist: string, otro: string): number {
    let cant: number = 0;
    for (let com of this.comparativaTanqueNatural) {
      if (dist == com.codigoProducto + ' - ' + com.nombreProducto) {
        cant += com[otro];
      }
    }
    return cant;
  }

  print(): void {
    var divToPrint = document.getElementById('invoicingPrint');
    var htmlToPrint = '' +
      '<style type="text/css">' +
      'table, td {' +
      'border:1px solid #000;' +
      'border-collapse: collapse;' +
      'text-align: center;' +
      //'padding: 0.5em;' +
      'font-size: 14px;' +
      '}' +
      '.saltoPagImp {' +
      'page-break-inside: avoid;' +
      '}' +
      '.resaltado td{' +
      'font-weight: bold;' +
      'border:1px dotted #000;' +
      '}' +
      'button{' +
      'display: none;' +
      '}' +
      'label  {' +
      'margin-top: 0.5cm;' +
      'display: inline-block;' +
      '}' +
      '.noImprimir {' +
      'display: none;' +
      '}' +
      'input {' +
      'display: none;' +
      '}' +
      '</style>';
    htmlToPrint += divToPrint.outerHTML;
    let newWin = window.open("");
    newWin.document.write(htmlToPrint);
    newWin.print();
    newWin.close();
  }

  sumar2(dist: string, otro: string): number {
    let cant: number = 0;
    for (let com of this.comparativasTanques15) {
      if (dist == com.codigoProducto + ' - ' + com.nombreProducto) {
        cant += com[otro];
      }
    }
    return cant;
  }

  entero(i: number): number {
    return Math.round(i);
  }

  armarArrayPrecierre(): ReportePreccierres[] {
    let precierre: ReportePreccierres[] = [];
    for (let pre of this.cierreSeleccionado.precierres) {
      var aux = new ReportePreccierres();
      aux.Abastecedora = pre.nombreExpendedora;
      aux.Estado = 'Cerrada';
      aux.FechaHoraPrecierre = pre.fechaHoraPrecierre;
      aux.Volumen15 = pre.nivel15;
      aux.VolumenNatural = pre.nivel;
      for (let pico of pre.picos) {
        aux.PicosAforador = pico.nombre + '-' + pico.aforadorFinalManual;
      }
      precierre.push(aux);
    }
    return precierre;
  }

  armarArrayComparativaAbastecedora(): ReporteComparativaAbastecedora[] {
    let abast: ReporteComparativaAbastecedora[] = [];
    for (let a of this.comparativaAbastecedora) {
      let aux = new ReporteComparativaAbastecedora();
      aux.Abastecedora = a.nombreExpendedora;
      aux.Producto = a.nombreProducto;
      aux.SalidaArchivo = a.salidaArchivo;
      aux.SalidaAforador = a.aforadorFinal - a.aforadorInicial;
      aux.Diferencia = a.diferenciaArchivoAforador;
      abast.push(aux);
    }
    return abast;
  }

  armarVarillado(): ReporteVarillado[] {
    let vari: ReporteVarillado[] = [];
    for (let v of this.cierreSeleccionado.medicionesTanques) {
      let aux = new ReporteVarillado();
      aux.Contenedor = v.nombreTanque;
      aux.Producto = v.nombreProducto;
      aux.Volumen15 = v.nivel;
      aux.VolumenNatural = v.nivelNatural;
      aux.Varillado = v.varillado;
      aux.Densidad = v.densidad;
      aux.Temperatura = v.temperatura;
      vari.push(aux);
    }
    return vari;
  }

  armarArrayTanque15(): ReporteComparativa15[] {
    let tan15: ReporteComparativa15[] = [];
    for (let t15 of this.comparativasTanques15){
      let aux = new ReporteComparativa15();
      aux.Tanque = t15.nombreContenedor;
      aux.ExistenciaAnterior = t15.existenciaAnterior;
      aux.Entradas = t15.entradas;
      aux.Salidas = t15.salidas;
      aux.ExistenciaLibro = t15.existenciaFinal;
      aux.ExisteciaReal = t15.existenciaReal;
      aux.DeficitSuperavit = t15.diferencia;
      tan15.push(aux);
    }
    return tan15;
  }

  armarArrayTanqueNat(): RerporteComparativaNatural[] {
    let tanNat: RerporteComparativaNatural[] = [];
    for (let tn of this.comparativaTanqueNatural){
      let aux = new RerporteComparativaNatural();
      aux.Tanque = tn.nombreContenedor;
      aux.ExistenciaAnterior = tn.existenciaAnterior;
      aux.Entradas = tn.entradas;
      aux.Salidas = tn.salidas;
      aux.ExistenciaLibro = tn.existenciaFinal;
      aux.ExisteciaReal = tn.existenciaReal;
      aux.DeficitSuperavit = tn.diferencia;
      tanNat.push(aux);
    }
    return tanNat;
  }

  armarArrayAeroespecialidad(): ReporteAeroespecialidad[] {
    let aeroesp: ReporteAeroespecialidad[] = [];
    for (let a of this.comparativaAeroespecialidades){
      let aux = new ReporteAeroespecialidad();
      aux.Codigo = a.codigoProducto;
      aux.Aeroespecialidad = a.nombreProducto;
      aux.Entradas = a.entradas;
      aux.Salidas = a.salidas;
      aux.ExistenciaLibro = a.existenciaFinal;
      aux.ExistenciaIngresada = a.existenciaReal;      
      aeroesp.push(aux);
    }
    return aeroesp;
  }

  armarTotalProducto(tipo:TipoComparativa): TotalProducto[] {
    let totales: TotalProducto[] = [];
    let totalaux: TotalProducto[] = [];
    (tipo==TipoComparativa.Tanque15) ? totalaux = this.totalProductos15 : totalaux = this.totalProductosNatural;
    for (let a of totalaux) {
      let aux = new TotalProducto();
      aux.codigoProducto = a.codigoProducto;
      aux.nombreProducto = a.nombreProducto;
      aux.existenciaAnterior = a.existenciaAnterior;
      aux.entradasCargamento = a.entradasCargamento;
      aux.entradasAlije = a.entradasAlije;
      aux.salidasCtaCte = a.salidasCtaCte;   
      aux.salidasContado = a.salidasContado;   
      aux.salidasRemito = a.salidasRemito;   
      aux.salidasConsumoInterno = a.salidasConsumoInterno;   
      aux.existenciaFinal = a.existenciaFinal;
      aux.existenciaReal = a.existenciaReal;

      totales.push(aux);
    }
    return totales;
  }

  exportar() {
    switch (this.reporteSeleccionado) {
      case 1:
        this.ejecutarStock = true;
        break;
      case 2:
        this.ejecutarAerovales = true;
        break;
      case 3:
        this.ejecutarRanchos = true;
        break;
      case 4:
        this.ejecutarMovimientosProducto = true;
        break;
      case 5:
        this.ejecutarAforadores = true;
        break;
    }
  }

  exportarPrecierres() {
    this.excelService.exportAsExcelFile(this.armarArrayPrecierre(), 'Precierres' + this.cierreSeleccionado.fechaHoraCierre);
  }

  exportarComparativaAbastecedora() {
    this.excelService.exportAsExcelFile(this.armarArrayComparativaAbastecedora(), 'Comparativa abastecedoras' + this.cierreSeleccionado.fechaHoraCierre);
  }

  exportarVarillado() {
    this.excelService.exportAsExcelFile(this.armarVarillado(), 'Varillado de tanques' + this.cierreSeleccionado.fechaHoraCierre)
  }
/*
  exportarComparativa15() {
    this.excelService.exportAsExcelFile(this.comparativasTanques15, 'Tanques 15°' + this.cierreSeleccionado.fechaHoraCierre);
  }

  exportarComparativaNatural() {
    this.excelService.exportAsExcelFile(this.comparativaTanqueNatural, 'Tanques natural' + this.cierreSeleccionado.fechaHoraCierre);
  }

  exportarProducto(tipo:TipoComparativa) {
    let nombre = (tipo==TipoComparativa.TanquesNaturales) ? 'Natural' : '15°';
    this.excelService.exportAsExcelFile(this.armarTotalProducto(tipo), 
      `Totales por productos ${nombre}` + this.cierreSeleccionado.fechaHoraCierre) 
  }
*/  

  exportarComparativa15() {
    this.excelService.exportAsExcelFile(this.armarArrayTanque15(), 'Tanques 15°' + this.cierreSeleccionado.fechaHora);
  }

  exportarComparativaNatural() {
    this.excelService.exportAsExcelFile(this.armarArrayTanqueNat(), 'Tanques natural' + this.cierreSeleccionado.fechaHora);
  }

  exportarComparativasAeroespecilidad() {
    this.excelService.exportAsExcelFile(this.armarArrayAeroespecialidad(), 'Aeroespecialidades' + this.cierreSeleccionado.fechaHora);
  }


  resetearExportaciones() {
    this.ejecutarAforadores = false;
    this.ejecutarAerovales = false;
    this.ejecutarMovimientosProducto = false;
    this.ejecutarRanchos = false;
    this.ejecutarStock = false;
  }

}

export class ReportePreccierres {
  Abastecedora: string;
  Estado: string;
  FechaHoraPrecierre: string;
  VolumenNatural: number;
  Volumen15: number;
  PicosAforador: string;
}

export class ReporteComparativaAbastecedora {
  Abastecedora: string;
  Producto: string;
  SalidaArchivo: number;
  SalidaAforador: number;
  Diferencia: number;
}

export class ReporteVarillado {
  Contenedor: string;
  Producto: string;
  Volumen15: number;
  VolumenNatural: number;
  Varillado: number;
  Densidad: number;
  Temperatura: number;
}

export class RerporteComparativaNatural {
  Tanque: string;
  ExistenciaAnterior: number;
  Entradas: number;
  Salidas: number;
  ExistenciaLibro: number;
  ExisteciaReal: number;
  DeficitSuperavit: number;
}
export class ReporteComparativa15 {
  Tanque: string;
  ExistenciaAnterior: number;
  Entradas: number;
  Salidas: number;
  ExistenciaLibro: number;
  ExisteciaReal: number;
  DeficitSuperavit: number;
}
export class ReporteAeroespecialidad {
  Codigo: string;
  Aeroespecialidad: string;
  Entradas: number;
  Salidas: number;
  ExistenciaLibro: number;
  ExistenciaIngresada: number;
}