<div class="row">
  <div class="card-body">
    <div class="row">

      <div class="mb-3 row col-md-2"></div>

      <div class="mb-3 row col-md-8">

        <div class="input-group col-md-4">
          <input type="date" [ngModel]="modelFecha" class="fechaFormControl" placeholder="aaaa-mm-dd"
            (ngModelChange)="modelFecha = $event" name="dt" ngbDatepicker #d="ngbDatepicker" value="modelFecha"
            class="form-control form-control-rounded" #searchDate required readonly>

          <div class="input-group-append">
            <button id="botonFecha" type="button" class="input-group-btn btn btn-secondary" (click)="d.toggle()">
              <i class="fa fa-calendar" aria-hidden="true"></i></button>
          </div>
        </div>

        <div class="col-md-2">
          <button class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2"
            (click)="consultarFacturacionSimulada()">Enviar</button>
        </div>

      </div>
    </div>

    <div class="row">
      
      <div class="mb-3 row col-md-2"></div>
      
      <div class="mb-3 col-md-8" *ngIf="procesandoDatos">
        <loading-text-spinner message="Procesando datos..." color="primary"></loading-text-spinner>
      </div>

      <div class="mb-3 col-md-8" *ngIf="mensaje != null" class="">
        {{mensaje}}
      </div>

    </div>
  </div>
</div>