import { Aborter } from '@azure/storage-blob';
import { EstadoUpload } from '../../enums/EstadoUpload';

export class CargaArchivo {

    private _progress: number = 0;
    private _tiempoUltimoBloque: Date;
    private _segundosRestantes: number = 0;
    private _aborter: Aborter;

    tiempoRestante: number;
    fileName: string;
    cancelada: boolean;
    finalizada: boolean;
    error: string;
    _mensajeOk: string;

    constructor(filename: string) {
        this.fileName = filename;
    }

    setAborter(aborter: Aborter) {
        this._aborter = aborter;
    }

    get segundosRestantes(): number {
        return this._segundosRestantes;
    }

    get progress(): number {
        return this._progress;
    }

    set progress(prog) {
        if (this._tiempoUltimoBloque) {
            const proAnterior = this._progress;
            const diferenciaTiempo = (new Date()).valueOf() - this._tiempoUltimoBloque.valueOf(); //cuanto tardó en subir el ultimo bloque
            /**
             * (100 - prog) Porcentaje restante
             * (prog - proAnterior) Tamaño del bloque recien subido, en porcentaje
             * (100 - prog) / (prog - proAnterior) cuantos bloques faltan
             * (100 - prog) / (prog - proAnterior) * diferenciaTiempo Cuanto tiempo falta (en milisegundos)...
             * ...calculado en base a cuanto tardó en subir el último bloque
             */
            this._segundosRestantes = Math.round((this._segundosRestantes + ((100 - prog) / (prog - proAnterior) * diferenciaTiempo) / 1000) / 2);
        }
        this._tiempoUltimoBloque = new Date();
        this._progress = prog;
    }

    cancelarCarga() {
        this._aborter.abort();
        this.cancelada = true;
    }

    finalizar(mensaje: string) {
        this._mensajeOk = mensaje;
        this.finalizada = true;
        this.progress = 100;
    }

    get mensajeOk(): string {
        return this._mensajeOk;
    }

    get estado(): EstadoUpload {
        if (this.error)
            return EstadoUpload.Error;
        if (this.finalizada)
            return EstadoUpload.Finalizado;
        if (this.cancelada)
            return EstadoUpload.Cancelado;
        if (!this._aborter)
            return EstadoUpload.Preparando;
        if (this.progress < 100)
            return EstadoUpload.Subiendo;
        if (this.progress == 100)
            return EstadoUpload.Importando;
    }
}