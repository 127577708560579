<nav class="navbar navbar-light bg-light navbar-expand-lg ">
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <!--Filtrar Clientes-->
        <div class="form-inline me-2">
            <input id="busquedaFormControl" type="text" class="form-control" placeholder="Buscador"
                [formControl]="buscadorFormControl" (input)="filtrarClientes()"/>
        </div>
        <!--
        <ul class="navbar-nav me-auto">                
            <li class="nav-item float-end me-2">
            <button type="button" class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2" (click)="crearNuevaAerolinea()"> Agregar</button>
            </li>
        </ul>-->
    </div>
</nav>

<div class="row">  
    <div class="col col-3">
        <div class="list-group py-3 menu-izq">
            <div class="col col-md-12" *ngIf="this.cargandoLista">
                <div class="spinner-container" >
                    <ngx-spinner name="spinnerGrafico" [ngStyle]="{'height': '400px', 'z-index':'0'}" bdOpacity=0.3bd
                        Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
                        <p style="color: white">C a r g a n d o . . . </p>
                    </ngx-spinner>
                </div>
            </div>
            <!-- lista de clientes -->
            <div *ngIf="!this.cargandoLista">
                <div *ngFor="let c of listaClientesFiltrados; let i=index">
                    <button type="button" class="list-group-item list-group-item-action" (click)="usuarioSeleccionoCliente(c)">
                    <div class="d-flex w-100 justify-content-between">
                        <p >{{ c.razonSocial }} </p>
                    </div>
                    </button>
                </div>
            </div>        
        </div>
    </div>
    <!-- informacion de las lineas aereas -->
    <div class="col col-9 py-3">
        <div *ngIf="this.clienteSeleccionado != null" id="tablaInformacion">
            <app-aerolineas-tams-abm [clienteEncabezadoSeleccionado]="this.clienteSeleccionado"></app-aerolineas-tams-abm>
        </div>
    </div>
</div>
    
<ng-template #content let-modal>
    <app-aerolineas-tams-abm></app-aerolineas-tams-abm>
</ng-template>