import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { TipoBaseCalculoParametroImpositivo } from '../enums/TipoBaseCalculoParametroImpositivo';
import { TipoCodigoParametroImpositivo } from '../enums/TipoCodigoParametroImpositivo';
import { TipoValorParametroImpositivo } from '../enums/TipoValorParametroImpositivo';
import { ParametroImpositivoDetalle } from '../models/facturacion/ParametroImpositivoDetalle';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-parametro-impositivo',
  templateUrl: './parametro-impositivo.component.html',
  styleUrls: ['./parametro-impositivo.component.css']
})
export class ParametroImpositivoComponent implements OnInit {

  @Input() parametroImpositivo: ParametroImpositivoDetalle;
  @Input() listaEnumCodigosCargados: string[];
  @Input() esProvincia: boolean;

  parametroImpositivoForm: UntypedFormGroup;

  guardandoParametroImp: boolean = false;

  enumTipoBaseParamImp = TipoBaseCalculoParametroImpositivo;
  enumTipoCodigoParamImp = TipoCodigoParametroImpositivo;
  enumTipoValorParamImp = TipoValorParametroImpositivo;

  soloMostrarLeyenda: boolean = true;
  soloMostrarCamposBasicos: boolean = false;
  mostrarCampoValor: boolean = true;

  constructor(
    private activeModal: NgbActiveModal, 
    public fb: UntypedFormBuilder) { }

  ngOnInit() {

    this.iniciarForm();

    if(this.parametroImpositivo){
      this.cargarInfoParametro();
    }
  }

  iniciarForm(){
    this.parametroImpositivoForm = this.fb.group({
      tipoCodigoFormControl: ['', Validators.required],
      leyendaFormControl: ['', Validators.required],
    });
  }

  cargarInfoParametro(){
    this.tipoCodigoFormControl.setValue(this.parametroImpositivo.codigo);
    this.leyendaFormControl.setValue(this.parametroImpositivo.leyenda);
    this.iniciarUi();
  }

  onCrearParametroImpositivo(){
    if( ! this.parametroImpositivo ){
      this.parametroImpositivo = new ParametroImpositivoDetalle();
      this.parametroImpositivo.activa = true;
    }

    this.parametroImpositivo.codigo = this.tipoCodigoFormControl.value;
    this.parametroImpositivo.leyenda = this.leyendaFormControl.value;

    this.activeModal.close(this.parametroImpositivo);
  }

  onCancelar(){
    this.activeModal.dismiss();
  }

  iniciarUi(){
    if(this.tipoCodigoFormControl.value == TipoCodigoParametroImpositivo.CO2 ||
      this.tipoCodigoFormControl.value == TipoCodigoParametroImpositivo.IVA ||
      this.tipoCodigoFormControl.value == TipoCodigoParametroImpositivo.IMPINT
      ){
        this.soloMostrarLeyenda = true;
        this.soloMostrarCamposBasicos = false;
    }else if(this.tipoCodigoFormControl.value == TipoCodigoParametroImpositivo.PERIIBB){
        this.soloMostrarLeyenda = false;
        this.soloMostrarCamposBasicos = false;
    }else {
        this.soloMostrarLeyenda = false;
        this.soloMostrarCamposBasicos = true;      
    } 
  }

  onUsuarioSeleccionoCodigo(){  
    if(this.tipoCodigoFormControl.value == TipoCodigoParametroImpositivo.CO2 ||
      this.tipoCodigoFormControl.value == TipoCodigoParametroImpositivo.IVA ||
      this.tipoCodigoFormControl.value == TipoCodigoParametroImpositivo.IMPINT
      ){
        this.soloMostrarLeyenda = true;
        this.soloMostrarCamposBasicos = false;
    }else if(this.tipoCodigoFormControl.value == TipoCodigoParametroImpositivo.PERIIBB){
        // logiga IB
        this.soloMostrarLeyenda = false;
        this.soloMostrarCamposBasicos = false;
    }else {
        this.soloMostrarLeyenda = false;
        this.soloMostrarCamposBasicos = true;
    }    
  }


  keysTipoCodigoParamImp(): Array<string> {
    // PROVINCIA: PERIIBB,CO2, IVA, IMPINT, PERIVA
    // CIUDAD: PERMUN, OTRO

    var keys = Object.keys(this.enumTipoCodigoParamImp);
    keys = keys.slice(keys.length / 2);

    if(this.esProvincia){
      keys = keys.filter(item => 
        item.toString() == keys[this.enumTipoCodigoParamImp.PERIIBB] ||
        item.toString() == keys[this.enumTipoCodigoParamImp.CO2] ||
        item.toString() == keys[this.enumTipoCodigoParamImp.IVA] ||
        item.toString() == keys[this.enumTipoCodigoParamImp.IMPINT] ||
        item.toString() == keys[this.enumTipoCodigoParamImp.PERIVA]
      );   
    }else{
      keys = keys.filter(item => 
        item.toString() == keys[this.enumTipoCodigoParamImp.PERMUN]
      );
    }
    
    keys = keys.filter(item => 
      this.listaEnumCodigosCargados.indexOf(item.toString()) == -1
    ); 

    if( this.parametroImpositivo ){
      if(keys.indexOf(TipoCodigoParametroImpositivo[this.parametroImpositivo.codigo]) == -1){
        keys.push(TipoCodigoParametroImpositivo[this.parametroImpositivo.codigo]);
      }      
    }

    return keys;
  }

  keysTipoValorParamImp(): Array<string> {
    var keys = Object.keys(this.enumTipoValorParamImp);
    keys = keys.slice(keys.length / 2);
    return keys;
  }


  // ---------------------------------------------------------

  get tipoCodigoFormControl() {
    return this.parametroImpositivoForm.controls.tipoCodigoFormControl;
  }

  get leyendaFormControl() {
    return this.parametroImpositivoForm.controls.leyendaFormControl;
  }
}
