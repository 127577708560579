import { Guid } from 'guid-typescript';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Tanque } from './Tanque';
import { Ducto } from './Ducto';
import { TanqueGeneral } from './TanqueGeneral';
import { DetalleIngresoDucto } from './DetalleIngresoDucto';
import { Responsable } from './Responsable';

export class IngresoDucto {
  codigoTerminal: string;
  codigoDucto: string;
  
  Num_certificado_analisis: string;
  prefijo: number;
  numeroRemito: number;
  temperatura: number;
  densidad: number;
  cantidadTurbina: number;
  cantidad15Turbina: number;

  recepcionCombustibleDetalles: DetalleIngresoDucto[];
  ResponsableDTO: Responsable;

  cantidad: number;
  cantidad15: number;

  comentario: string;

  nombreFirmaOperador: string;
  nombreFirmaConductor:	string;
  nombreRemitoFisico:	string;

}
