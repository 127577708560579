import { Aeroplanta } from './Aeroplanta';
import { Abastecedora } from './Abastecedora';

export class Operador {
    usuarioId: string;
    nombresCompletos: string;
    telefono : string;
    email : string;
    cargo : string;
    ultimaAeroplanta : Aeroplanta;
    tipoUsuario : number;
    dni: string;
    zonaHoraria : string;
    ultimaAbastecedora : Abastecedora;
    userName: string;
}
