import { Ciudad } from './Ciudad';
import { Provincia } from './Provincia';
export class Ubicacion {
    id: string;
    codigoPostal: string;
    entrega: string;
    codigoUbicacion: number;
    tipoUbicacion: number;
    ciudad: Ciudad;
    direccion: string;
    direccionComplemento: string;
    esPrincipal: boolean;
    piso: string;
    departamento: string;
    numero: string;
    calle: string;
} 