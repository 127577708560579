<div [formGroup]="form">

  <div class="mb-3 col-md-6" *ngIf="tipoAerovale == enumTipoAerovale.Aeroespecialidad">
    <div class="mb-3 row">
      <label for="vueloFormControl" class="col-sm-4 col-form-label">
        Vuelo:
      </label>
      <div class="col-sm-6">
        <input id="vueloFormControl" type="text" class="form-control mb-2 me-sm-2"  formControlName="vueloFormControl" />
      </div>
    </div>
  </div>

  <div class="col-md-6">
    <div class="mb-3">
      <div class="mb-3 row">
        <label for="aeroespecialidadesSeleccionablesFormControl" class="col-sm-4 col-form-label">
          *Aeroespecialidades:
        </label>
        <div class="col-sm-6">
          <select id="aeroespecialidadesSeleccionablesFormControl" class="form-select" ngbTooltip="Seleccione aeroespecialidades para agregar." 
            formControlName="aeroespecialidadesSeleccionablesFormControl" (change)="this.onAeroespecialidadSeleccionada()">
            <option [ngValue]="null">Lista de aeroespecialidades</option>
            <option *ngFor="let aeroespecialidad of aeroespecialidadesSeleccionables" [ngValue]="aeroespecialidad">
              {{ aeroespecialidad.codigoProducto + ' | ' + aeroespecialidad.nombreProducto }}
            </option>
          </select>
          <loading-text-spinner *ngIf="cargandoAeroespecialidades" [error]="errorAeroespecialidades" message="Obteniendo aeroespecialidades..."color="primary">
          </loading-text-spinner>
        </div>
      </div>
    </div>

    <div class="mb-3" *ngIf="!cargandoAeroespecialidades">
      <div class="mb-3 row">
        <label *ngIf="(this.aeroespecialidadesSeleccionadasFormControlArray.controls.length > 0)" class="col-sm-5 col-form-label">
          <b>Aeroespecialidad seleccionadas</b>
        </label>
        <div class="col-sm-10" style="border: 1px solid #ccc; padding: 1em;" formArrayName="aeroespecialidadesSeleccionadasFormControlArray">
          <div *ngFor="let aeroespecialidad of aeroespecialidadesSeleccionadasFormControlArray.controls; let i=index" style="margin-bottom: 1em;">
            <div [formGroupName]="i">
              <input type="text" class="label-aeroespecialidad-item" formControlName="nombreProducto" readonly>
              <input type="hidden" formControlName="productoCodigo" >
              &nbsp; &nbsp; Cantidad:
              <input type="text" formControlName="cantidad" (keypress)="onlyNumberKey($event)" style="width: 3em; text-align: right; padding-right: 1em;">
              <button type="button" (click)="onAeroespecialidadEliminada(i)" class="btn btn-danger ms-3 px-1"><i class="fa fa-trash"></i> Quitar</button>

              <div class="alert alert-danger"
                *ngIf="!aeroespecialidadesSeleccionadasFormControlArray.controls[i].valid">
                  Debe cargar una cantidad.
              </div>
            </div>
          </div>
          <div class="alert alert-danger"
            *ngIf="(this.aeroespecialidadesSeleccionadasFormControlArray.controls.length == 0 && tipoAerovale == enumTipoAerovale.Aeroespecialidad)">
              Debe agregar al menos una aeroespecialidad.
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


