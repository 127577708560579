import { Remanente } from './Remanente';
import { Aeronave } from './Aeronave';
import { Aeropuerto } from './Aeropuerto';
import { Aerovale } from './Aerovale';
import { Aerocombustible } from './Aerocombustible';
import { Pedido } from './Pedido';
export class AerovaleAerocombustible extends Aerovale {
  fechaHoraLlegada: string;
  vuelo: string;
  tipoVuelo: string;
  destino: Aeropuerto;
  temperatura: number;
  densidad: number;
  pruebaAgua: boolean;
  rancho: string;
  codigoAbastecedora: string;
  aerocombustible: Aerocombustible;
  aforadorInicio: number;
  aforadorFinal: number;
  volumenNaturales: number;
  volumen15grados: number;
  esAlije: boolean;
  esGranel: boolean;
  recarga: boolean;
  aeronave: Aeronave;
  pedido: Pedido;
  remanentes: Remanente[];
  estado: string;
  codigoAutorizacion: string;
  codigoTanque: string;
  ciudadIdAerovale: string;
  picoId: string;
  presionPuntaManguera: number;
  presionDiferencial: number;
  caudalObservado: number;

  idPico: string;
  aforadorInicialEditado: boolean;   
  aforadorFinalEditado: boolean;

  esDevolucion: boolean; // Se setea en true cuando creamos un aerovale de devolucin de alije desde un pedido.
}
