import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'loading-text-spinner',
  templateUrl: './loading-text-spinner.component.html',
  styleUrls: ['./loading-text-spinner.component.css']
})

export class LoadingTextSpinnerComponent implements OnInit {

  /** Mensaje a mostrar */
  @Input() message: string;

  /** Orientación:  'horizontal' (spinner y texto en la misma línea) o 'vertical' (spinner arriba y texto abajo) */
  @Input() orientation: string;

  /** Tamaño del spinner, pequeño por defecto. Puede setearse como 'large'*/
  @Input() size: 'large';

  /** Si no se setea como 'center', el contenido aparece alineado a la izquierda*/
  @Input('content-align') contentAlign: string;

  /** Color del spinner y texto. Se utilizan las clases de bootstrap. Ej. 'primary', 'success', etc.*/
  @Input() color: string;
  _color: string;

  /** Color del texto. Se utilizan las clases de bootstrap. Ej. 'primary', 'success', etc.*/
  @Input() textColor: string;
  _textColor: string;

  /** Color del texto. Se utilizan las clases de bootstrap. Ej. 'primary', 'success', etc.*/
  @Input() error: boolean = false;

  constructor() { }

  ngOnInit() {
    this._color = this.color ? 'text-' + this.color : '';
    this._textColor = this.textColor ? 'text-' + this.textColor : '';
  }

}
