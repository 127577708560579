<div class="modal-header bg-light">
  <h5 class="modal-title justify-content-center">Historial</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="this.onCerrar()">
    
  </button>
</div>


<div class="modal-body">
  <div class="row">
    <div class="col-md-1"></div>
    <div class="col-md-2">Producto</div>
    <div class="col-md-3"><b>{{precio.producto.codigoProducto}} - {{precio.producto.nombreProducto}}</b></div>
    <div class="col-md-2">Desde/Hasta</div>
    <div class="col-md-3" *ngIf="precio.fechaDesde != null && precio.fechaHasta != null">
      <b>{{precio.fechaDesde | date: "dd'/'MM'/'yy"}} - {{precio.fechaHasta | date: "dd'/'MM'/'yy"}}</b>
    </div>
    <div class="col-md-3" *ngIf="precio.fechaHasta == null && precio.fechaDesde">
      <b>--</b>
    </div>
    <div class="col-md-1"></div>
  </div>

  <div class="row">
    <div class="col-md-1"></div>
    <div class="col-md-2">Lote</div>
    <div class="col-md-3"><b>{{precio.numeroLote}}</b></div>
    <div class="col-md-2">Cliente</div>
    <div class="col-md-3"><b>{{precio.cliente!=null ? (precio.cliente.razonSocial): "-"}}</b></div>
    <div class="col-md-1"></div>
  </div>
</div>

<div class="row">
  <div class="col-md-1"></div>
  <div class="col-md-10 p-2" id="contenedor-historico">
    <div *ngFor="let h of this.arregloHistorico">{{h}}</div>
  </div>
  <div class="col-md-1"></div>
</div>
<div class="modal-footer bg-light mt-2">
  <span class="border">
    <button type="button" class="btn btn-secondary" (click)="onCerrar()">Cancelar</button>
  </span>
</div>
