<div class="spinner-container" *ngIf="obteniendoDatos">        
  <ngx-spinner name="spinnerGrafico" bdOpacity=0.3bd
    Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
    <p style="color: white">C a r g a n d o . . . </p>
  </ngx-spinner>        
</div>

<div *ngIf="!obteniendoDatos">
  <div class="modal-header bg-light">
    <h4 class="modal-title">Producto: {{this.codigoProducto}} - {{this.nombreProducto}} ({{this.tipoNatural ? "Natural" : "15º"}})</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="this.activeModal.dismiss()">
      
    </button>
  </div>

  <div class="modal-body">
    Fecha desde: {{ this.fechaDesde | date: "dd/MM/yyy HH:mm a"  }} | Fecha hasta: {{ this.fechaHasta  | date: "dd/MM/yyy HH:mm a" }}
    <br/>
    Dependencia: {{ this.obtenerCodigoAeroplanta() + " - " + this.usuarioService.getAeroplantaActual().nombre}}
    <div class="col-12" id="contenedor" *ngIf="this.movimientosPorTipo != null">
      <button class="btn btn-outline-success" style="float: right; margin-bottom: 0.25em;" (click)="exportarMovimientos()">
        <i class="fa fa-file-excel-o" aria-hidden="true"></i>
        Exportar
      </button>

      <div>
        <table class="table table-striped table-hover">
          <thead class="text-center">
            <th>Concepto</th>
            <th>Saldo inicial</th>
            <th>Entradas</th>
            <th>Salidas</th>
            <th>Saldo final</th>
          </thead>
          <tbody class="text-center">
            <tr class="resaltado">
              <td></td>
              <td>{{this.saldoInicial}}</td>
              <td></td>
              <td></td>
              <td>{{this.saldoFinal}}</td>
            </tr>
            <tr *ngFor="let mt of movimientosPorTipo" (click)="obtenerMovimientosPorTipoDetalle(mt)" style="cursor: pointer;">
              <td style="text-align: left;">{{ obtenerDescripcionTipoTransaccion(mt.tipoTransaccion, mt.esAnulacion) }}</td>
              <td></td>
              <td>{{mt.entradas | number: '1.0-0'}}</td>
              <td>{{mt.salidas | number: '1.0-0'}}</td>
              <td></td>
            </tr>
            <tr class="resaltado">
              <td></td>
              <td>TOTALES</td>
              <td>{{this.totalEntradas | number: '1.0-0'}}</td>
              <td>{{this.totalSalidas | number: '1.0-0'}}</td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>