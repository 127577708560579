<nav class="navbar navbar-light bg-light navbar-expand-lg">
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <!--Buscar cuetnas -->
      <div class="form-inline me-2">
        <input id="busquedaFormControl" type="text" class="form-control" placeholder="Cuenta/Descripción/Matrícula"
          [formControl]="busquedaFormControl" (input)="filtrarPreautorizaciones()"/>
      </div>
  
      <ul class="navbar-nav me-auto">
        <!-- desde -->
        <li class="nav-item me-2">
          <div class="input-group">
            <input type="date" [ngModel]="model" class="fechaFormControlDesdeHasta" placeholder="aaaa-mm-dd"
              (ngModelChange)="model = $event" name="dt" ngbDatepicker #d="ngbDatepicker" value="model"
              class="form-control form-control-rounded" #searchDate required readonly>
            <div class="input-group-append">
              <button id="botonDesde" type="button" class="input-group-btn btn btn-secondary" (click)="d.toggle()">
                <i class="fa fa-calendar" aria-hidden="true"></i></button>
            </div>
          </div>
        </li>
        <!-- hasta -->
        <li class="nav-item">
          <div class="input-group">
            <input type="date" class="fechaFormControlDesdeHasta" [ngModel]="model1" placeholder="aaaa-mm-dd" name="dp"
              value="model1" (ngModelChange)="model1 = $event" class="form-control form-control-rounded" ngbDatepicker
              #h="ngbDatepicker" #searchDate required readonly>
            <div class="input-group-append">
              <button id="botonHasta" type="button" class="input-group-btn btn btn-secondary" (click)="h.toggle()"><i
                  class="fa fa-calendar" aria-hidden="true"></i></button>
            </div>
          </div>
        </li>
        <li class="nav-item me-2">
            <button 
              [disabled]="fechaDesdeMayorQueHasta()==true" 
              (click)="changeDesdeHasta()" 
              class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2" type="button">
              <i class="fa fa-search" aria-hidden="true"></i>
            </button>
        </li>
        <!-- Aeroplantas -->
        <li class="nav-item me-2">
          <select class="form-select" id="aeroplantaFormControl" name="aeroplantaFormControl"
            [formControl]="aeroplantaFormControl">
            <option [value]="null" defaultSelected>Todas las aeroplantas</option>
            <option *ngFor="let aer of aeroplantas" [value]="aer.codigoAeroplanta">
              {{aer.nombre}}
            </option>
          </select>
          <loading-text-spinner *ngIf="cargandoAeroplanta" message="Obteniendo aeroplantas" color="primary">
          </loading-text-spinner>
        </li>
      </ul>
      <div class="float-end">
        <button 
          [disabled]="cargandoAeroplanta"
          (click)="crearPreautorizacion()" 
          class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2" type="button">
          Crear Preautorización
        </button>
      </div>
    </div>
  </nav>
  <div class="col col-md-12">
    <div class="spinner-container" *ngIf="this.obteniendoPreAutorizaciones">
      <ngx-spinner name="spinnerGrafico" bdOpacity=0.3bd
        Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
        <p style="color: white">C a r g a n d o . . . </p>
      </ngx-spinner>
    </div>
    
    <ngb-alert type="warning" *ngIf="!this.obteniendoPreAutorizaciones && this.preauto.length==0 && fechaDesdeMayorQueHasta()==false">
      No se encontraron preautorizaciones para el rango de fechas seleccionado.
    </ngb-alert>  
  
    <ngb-alert type="danger" [dismissible]="false" *ngIf="fechaDesdeMayorQueHasta()==true">
      La fecha inicial debe ser anterior a la fecha final.
    </ngb-alert>
  
    <div class="row" *ngIf="!this.obteniendoPreAutorizaciones && this.preauto.length>0 && fechaDesdeMayorQueHasta()==false">
      <div class="card-body">
        <table class="table table-striped table-hover" *ngIf="this.preautoFiltradas.length>0">
          <thead class="text-center">
            <td><b>Fecha</b></td>            
            <td><b>Cuenta descripción</b></td>
            <td><b>Matricula</b></td>
            <td><b>Aeroplanta</b></td>
            <td><b>Fecha Desde</b></td>
            <td><b>Fecha Hasta</b></td>
            <td><b>Fecha Anulado</b></td>
            <td><b>Responsable</b></td>
            <td><b>Acción</b></td>
          </thead>
          <tbody class="text-center letra">
            <tr *ngFor="let a of this.preautoFiltradas let i = index">
                <td>{{ a.fechaHora | date: "d'/'MM'/'y' 'hh:mm a" }}</td>
                <td>{{ a.ctA_SGC + " " + a.cuentaDescripcion }}</td>
                <td>{{ a.matricula }}</td>
                <td>{{ a.codigoAeroplanta }}</td>
                <td>{{ a.fechaHoraDesde | date: "d'/'MM'/'y' 'hh:mm a"  }}</td>
                <td>{{ a.fechaHoraHasta | date: "d'/'MM'/'y' 'hh:mm a"  }}</td>
                <td>{{ a.fechaHoraAnulacion | date: "d'/'MM'/'y' 'hh:mm a"  }}</td>
                <td>{{ a.usuarioResponsable }}</td>
                <td>
                  <button *ngIf="a.fechaHoraAnulacion==null" type="button" class="btn btn-danger" (click)="anularPreautorizacion(a.id, i)"
                    [disabled]="anulando">
                    <span *ngIf="!anulando || anulando && preautorizacionSeleccionada!=i">Anular <i class="fa fa-trash" aria-label="true"></i></span>
                    <div *ngIf="preautorizacionSeleccionada==i && anulando" class="d-flex align-items-center">
                      <div class="spinner-border text-light spinner-border-sm" role="status"></div>
                      <span>Anulando...</span>
                    </div>
                  </button>
                </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>