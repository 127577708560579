import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { Transaccion } from 'src/app/shared/models/stock/Transaccion';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TipoMovimientoStock } from 'src/app/shared/enums/tipoMovimientoStock';
import { AerovaleDetalleComponent } from 'src/app/despacho/aerovale-detalle/aerovale-detalle.component';
import { AerovalesService } from 'src/app/despacho/aerovales.service';
import { AerovaleDetalle } from 'src/app/shared/models/despacho/AerovaleDetalle';
import { NgxSpinnerService } from 'ngx-spinner';
import { TransaccionesService } from '../../transacciones.service';
import { VisorComprobanteCargamentoComponent } from 'src/app/shared/visor-comprobante-cargamento/visor-comprobante-cargamento.component';
import { MessageService } from 'src/app/shell/message.service';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { ModalConfirmComponent } from 'src/app/shared/generic-modals/modal-confirm.component';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';

@Component({
  selector: 'app-movimiento-detalle',
  templateUrl: './movimiento-detalle.component.html',
  styleUrls: ['./movimiento-detalle.component.css']
})
export class MovimientoDetalleComponent implements OnInit {
  @Output() resultado: EventEmitter<boolean> = new EventEmitter();

  TipoUsuario = TipoUsuario;
  TipoMovimientoStock = TipoMovimientoStock;
  
  aerovaleDetalle: AerovaleDetalle;
  obteniendoAerovale: boolean;
  anulandoMovimiento: boolean = false;

  @Input() transaccionSeleccionada: Transaccion; // es el movimiento que me seleccionan desde tablero
  obteniendoDetalleMovimiento: boolean = false;
  movimientoSeleccionado: Transaccion;
  constructor(private authService: AuthService, 
    public activeModal: NgbModal, private aerovaleServie: AerovalesService, private transaccionService: TransaccionesService,
    private spinner: NgxSpinnerService, private messageService: MessageService) { }

  ngOnInit() {
    this.spinner.show('spinnerDetalleMovimiento');
    this.obtenerTransaccionPorId();
  }


  // form

  mostrarAerovale() {
    this.activeModal.dismissAll();
    let modalRef = this.activeModal.open(AerovaleDetalleComponent, { size: "lg", centered: true })
    modalRef.componentInstance.aerovaleDetalle = this.aerovaleDetalle;  
  }

  mostrarComprobanteCargamento(i: number) {
    this.activeModal.dismissAll();
    let modalRef = this.activeModal.open(VisorComprobanteCargamentoComponent, { size: "lg", centered: true })
    modalRef.componentInstance.movimiento = this.movimientoSeleccionado;
    modalRef.componentInstance.comp = i;
  }

  obtenerTipoMovimiento(tipo: number) {
    return TipoMovimientoStock[tipo];
  }

  esRebombeo(m: Transaccion): boolean {
    return (m.tipoTransaccion === 4);
  }

  puedoAnularMovimiento():boolean{
    return !this.movimientoSeleccionado.titulo.includes('Anulacion') && this.movimientoSeleccionado.tipoTransaccion!=0 && this.movimientoSeleccionado.tipoTransaccion != 5;
  }

  // llamadas al service

  obtenerTransaccionPorId() {
    this.obteniendoDetalleMovimiento = true;
    this.transaccionService.getTransaccionPorId(this.transaccionSeleccionada.id)
      .subscribe(result => {
        this.movimientoSeleccionado = result;
        this.movimientoSeleccionado.movimientos.sort((a, b) => a.cantidad - b.cantidad);
        if (this.movimientoSeleccionado.tipoTransaccion == 4 && this.movimientoSeleccionado.movimientos.length > 1) {
          this.movimientoSeleccionado.movimientos.splice(0, 1);
        }
        this.movimientoSeleccionado.tipoTransaccion == 0 || this.movimientoSeleccionado.tipoTransaccion == 5 ? this.obtenerAerovale(this.movimientoSeleccionado.comprobante.aerovaleId) : this.aerovaleDetalle = null;
        this.spinner.hide('spinnerDetalleMovimiento');
        this.obteniendoDetalleMovimiento = false;
      })
  }

  obtenerAerovale(id: string) {
    this.obteniendoAerovale = true;
    this.aerovaleDetalle = null;
    this.aerovaleServie.getAerovalePorIdyTipo(id)
      .subscribe(result => {
        this.aerovaleDetalle = result;       
        this.obteniendoAerovale = false;
      });
  }


  anularMovimiento() {
    let modalRef = this.activeModal.open(ModalConfirmComponent, { centered: true, backdrop: 'static' });
    modalRef.componentInstance.mensaje = '¿Esta seguro que desea anular el movimiento seleciconado?';
    modalRef.result.then(result => {
      if (result == 'Si') {
        this.anulandoMovimiento = true;
        this.transaccionService.anularMovimiento(this.transaccionSeleccionada.id)
          .subscribe(result => {
            this.messageService.showSuccessMessage('El movimiento fue anulado correctamente.');
            this.anulandoMovimiento = false;
            this.resultado.emit(true);
            this.activeModal.dismissAll();
          }, (err: ErrorModel) => {
            this.messageService.showErrorMessage('Hubo un error al anular el movimiento, por favor reintentelo.');
            this.anulandoMovimiento = false;
          })
      }

    })
  }

  hasRole(...roles: TipoUsuario[]) {
    return this.authService.hasRole(...roles);
  }
}
