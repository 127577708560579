import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { of, Observable } from 'rxjs';
import { UrlApi } from '../shared/enums/urlApi';
import { tap, catchError } from 'rxjs/operators';
import { Producto } from '../shared/models/despacho/Producto';
import { HandleError, HttpErrorHandler } from 'src/app/http-error-handler.service';
import { AuthService } from '../core/authentication/auth.service';
import { Injectable } from '@angular/core';
import { Tanque } from '../shared/models/stock/Tanque';
import { Equivalencia } from '../shared/models/stock/Equivalencia';
import { Contenedor } from '../shared/models/stock/Contenedor';

@Injectable({
    providedIn: 'root'
  })
export class TanqueService{
  // [x: string]: any;

    stockbaseUrl = environment.apiServer.stockBaseUrl;
    private tanquesUrl = this.stockbaseUrl + '/ContenedoresV1/Tanques/';
    private aeroplantasURL = this.stockbaseUrl + '/AeroplantasV1/'
    private handleError: HandleError

    constructor(private httpClient: HttpClient, private httpErrorHandler: HttpErrorHandler, private authservice: AuthService){
      this.handleError = httpErrorHandler.createHandleError('Tanques')
    }


    getTanquesLibresPorAeroplanta(codigoAeroplanta: string): Observable<Tanque[]>{
      return this.httpClient.get<Tanque[]>(this.aeroplantasURL + codigoAeroplanta + '/TanquesUnicosLibres')
      .pipe(
        tap(_ => console.log('getTanquesPorAeroplanta')),
          catchError(this.handleError('Al obtener tanques', null))
        );
    }

    getTanquesPorAeroplanta(codigoAeroplanta: string): Observable<Tanque[]>{
      return this.httpClient.get<Tanque[]>(this.aeroplantasURL + codigoAeroplanta + '/TanquesUnicos')
      .pipe(
        tap(_ => console.log('getTanquesPorAeroplanta')),
          catchError(this.handleError('Al obtener tanques', null))
        );
    }

    getAsyncTanquesPorAeroplanta(codigoAeroplanta: string): Promise<Tanque[]>{
      return this.httpClient.get<Tanque[]>(this.aeroplantasURL + codigoAeroplanta + '/TanquesUnicos').toPromise();
    }

    crearTanque(codigoAeroplanta: string, tanque: Tanque): Observable<Tanque>{
      return this.httpClient.post<Tanque>(this.tanquesUrl + codigoAeroplanta + '/Unico', tanque)
      .pipe(
        tap(_ => console.log('Creacion de tanque')),
        catchError(this.handleError('Al crear tanques', null))
        );
    }

    editarTanque(codigoAeroplanta: string, tanque: Tanque): Observable<Tanque>{
      return this.httpClient.put<Tanque>(this.tanquesUrl + tanque.codigo + '/Unico?codigoAeroplanta=' + codigoAeroplanta, tanque)
      .pipe(
        tap(_ => console.log('Edición de tanque')),
        catchError(this.handleError('Al editar tanques', null))
        );
    }

    getGraduaciones(codigo: string): Observable<Equivalencia[]>{
      return this.httpClient.get<Equivalencia[]>(this.tanquesUrl + codigo + '/Equivalencias')
      .pipe(
        tap(_ => console.log('Obtención de equivalencias')),
        catchError(this.handleError('Al obtener equivalencias', null))
        );
    }

    obtenerProximoCodigoDeTanque(codigoAeroplanta:string, tipoDeContenedor: number): Observable<Contenedor>
    {
      return this.httpClient.get<Contenedor>(this.stockbaseUrl + '/ContenedoresV1/ProximoCodigoContenedor/'+tipoDeContenedor+'/'+ codigoAeroplanta)
      .pipe(
        tap(_ => console.log('getProximoCodigoTanque')),

        );

    }
  }
