import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contenedor-multifiltro',
  templateUrl: './contenedor-multifiltro.component.html',
  styleUrls: ['./contenedor-multifiltro.component.css']
})
export class ContenedorMultifiltroComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
