<div class="modal-header bg-light">
  <h5 class="modal-title ">Precierre de {{obtenerDescripcionExpendedora()}}: {{precierre.nombreExpendedora}}</h5>
  <button type="button" class="btn-close" aria-label="Close" data-dismiss="modal" (click)="activeModal.close(false)"></button>
</div>
<div class="modal-body" [formGroup]="precierreForm">

  <!-- PICOS CON AFORADORES-->
  <div class="mb-3 col-10 offset-1" *ngIf="obteniendoProductos">
    <div class="mb-3 row">
      <loading-text-spinner message="Obteniendo productos..." color="primary"></loading-text-spinner>
    </div>
  </div>

  <div class="mb-3 col-10 offset-1" *ngIf="obteniendoPicos">
    <div class="mb-3 row">
      <loading-text-spinner message="Obteniendo expendedora..." color="primary"></loading-text-spinner>
    </div>
  </div>
  
  <div class="mb-3 col-12" formArrayName="picosFormArray" *ngIf="!obteniendoPicos">
    <div *ngFor="let pico of picosFormArray.controls; let i=index">
      <div [formGroupName]="i" class="mb-3 row col-12">
        <input type="hidden" formControlName="idPico">
        
        <label for="cantidadFormControl" id="labelCantidad" class="col-4 col-form-label">
          Aforador manual para pico: {{ picosAbastecedora[i].nombre}}
        </label>
        
        <div class="col-8">
          <input [readonly]="estaEditando()" class="form-control" formControlName="aforadorPico" (keypress)="this.onlyNumberKey($event)">
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="tipoExpendedora != null && tipoExpendedora == TipoExpendedora.ABASTECEDORA_CAMION">
    <!-- CANTIDAD-->
    <div class="mb-3 col-12">
      <div class="mb-3 row">
        <label for="cantidadFormControl" id="labelCantidad" class="col-4 col-form-label">Cantidad:</label>
        <div class="col-8">
          <input 
            class="form-control" formControlName="cantidadFormControl"
            (keypress)="this.onlyDecimalPositivo($event)"
            (input)="recalcularVolumenNatural()">                        
            <ngb-alert class="alert-danger" *ngIf="desactivarUI">
              {{ obtenerDescripcionExpendedora() +" "+ obtenerEntidadCartel() }} - Volumen Natural en cero
            </ngb-alert>
        </div>        
      </div>
    </div>
    <!--TEMPERATURA-->
    <div class="mb-3 col-12">
      <div class="mb-3 row">
        <label for="temperaturaFormControl" id="labelDensidad" class="col-4 col-form-label">Temperatura:</label>
        <div class="col-8">
          <input class="form-control" formControlName="temperaturaFormControl" (keypress)="onlyDecimal($event)"
            (input)="recalcularVolumenNatural()">
        </div>
      </div>
    </div>
    <!--DENSIDAD-->
    <div class="mb-3 col-12">
      <div class="mb-3 row">
        <label for="densidadFormControl" id="labelDensidad" class="col-4 col-form-label">Densidad:</label>
        <div class="col-8">
          <input class="form-control" id="densidadFormControl" formControlName="densidadFormControl" (keypress)="formatDensidad($event)"
            (input)="recalcularVolumenNatural()">
        </div>
      </div>
    </div>
    <!--VOLUMEN 15°-->
    <div class="mb-3 col-12">
      <div class="mb-3 row">
        <label for="cantidad15FormControl" id="labelDensidad" class="col-4 col-form-label">Cantidad 15°:</label>
        <div class="col-8">
          <input class="form-control" formControlName="cantidad15FormControl" readonly>
          <ngb-alert class="alert-danger" *ngIf="desactivarUI">
            {{ obtenerDescripcionExpendedora() +" "+ obtenerEntidadCartel() }} - Volumen 15º en cero
          </ngb-alert>
        </div>
      </div>
    </div>
  </div>
  
</div>

<div class="modal-footer bg-light">
  <div class="alert alert-danger" *ngIf="mensajeError != null">
    {{ mensajeError }}
  </div>
  <div class="mb-3 col-12">
    <div class="mb-1 d-flex justify-content-center">
      <button class="btn btn-primary me-2" id="aceptar" [disabled]="!precierreForm.valid || obteniendoProductos || obteniendoPicos || guardandoPrecierre"
        (click)="guardarPrecierreAbastecedora()">
        <span *ngIf="!guardandoPrecierre">Guardar</span>
        <div *ngIf="guardandoPrecierre" class="d-flex align-items-center">
          <div class="spinner-border text-light spinner-border-sm" role="status"></div>
          <span>Guardando...</span>
        </div>
      </button>
      <button class="btn btn-secondary" id="cancelar" (click)="activeModal.close(false)"
        [disabled]="guardandoPrecierre" data-dismiss="modal">Cancelar</button>
    </div>
  </div>
</div>