import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbCalendar, NgbDatepickerConfig, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { UsuarioService } from 'src/app/account/usuario.service';
import { ComprobanteService } from 'src/app/facturacion/comprobante.service';
import { Aeroplanta } from 'src/app/shared/models/despacho/Aeroplanta';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { MessageService } from 'src/app/shell/message.service';
import { StockService } from 'src/app/stock/stock.service';
import { ManagementService } from '../management.service';

@Component({
  selector: 'app-set-cai-aeroplanta',
  templateUrl: './set-cai-aeroplanta.component.html',
  styleUrls: ['./set-cai-aeroplanta.component.css']
})
export class SetCaiAeroplantaComponent implements OnInit {
  model: any;

  aeroplantas: Aeroplanta[];
  aeroplantasFacturacion: Aeroplanta[];
  aeroplantasStock: Aeroplanta[];
  modelFechaInicio: NgbDateStruct;
  obteniendo: boolean = false;
  guardando: boolean = false;
  aeroplantaSeleccionada: Aeroplanta;

  constructor(private calendar: NgbCalendar, private datepickerConfig: NgbDatepickerConfig, private despachoService: ManagementService,
    private spinner: NgxSpinnerService, private messageService: MessageService, private stockService: StockService, private comprobanteService: ComprobanteService) { }

  ngOnInit() {
    this.getAeroplantas();
  }

  getAeroplantas() {
    this.obteniendo = true;
    this.spinner.show('spinnerGrafico');
    this.despachoService.getTodasLasAeroplantas()
      .subscribe(result => {
        this.aeroplantas = result.sort((a, b) => { if (a.nombre > b.nombre) { return 1 } else { return -1 } });
        this.getAeroplantasFacturacion();
      });
  }

  getAeroplantasFacturacion(){
    this.comprobanteService.getDatosAeroplanta(null)
      .subscribe(result => {
        this.aeroplantasFacturacion = result;
        for(let despacho of this.aeroplantas){
          for(let facturacion of this.aeroplantasFacturacion){
            if(despacho.codigoAeroplanta == facturacion.codigoAeroplanta){
              despacho.cai = facturacion.cai;
              despacho.fechaVencimientoCAI = facturacion.fechaVencimientoCAI;
            }
          }
        }
        this.getAeroplantasStock();
      });

  }

  getAeroplantasStock(){
    this.stockService.getAeroplantas(null)
      .subscribe(result => {
        this.aeroplantasStock = result;
        for(let despacho of this.aeroplantas){
          for(let stock of this.aeroplantasStock){
            if(despacho.codigoAeroplanta == stock.codigoAeroplanta){
              despacho.prefijoRemitoSalida = stock.prefijoRemitoSalida;
            }
          }
        }
        this.spinner.show('hide');
        this.obteniendo = false;
      })
  }

  mostrarDetalle(i: number) {
    this.aeroplantaSeleccionada = this.aeroplantas[i];
  }
}
