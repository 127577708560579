import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import internal from 'stream';
import { CalculoBaseImponible } from '../enums/CalculoBaseImponible';
import { ImpuestoPercepcion } from '../models/facturacion/ImpuestoPercepcion';
import { Renglon } from '../models/facturacion/Renglon';

@Component({
  selector: 'app-visor-impuesto',
  templateUrl: './visor-impuesto.component.html',
  styleUrls: ['./visor-impuesto.component.css']
})
export class VisorImpuestoComponent implements OnInit {
  @Input() renglones: Renglon[];
  contador = 0;
  constructor(public activeModal: NgbActiveModal ) { }

  ngOnInit() {
    console.log(this.renglones);
  }

  obtenerBaseCalculo(cal: number){
    return CalculoBaseImponible[cal];
  }

  incrementarContador(total: number){
      this.contador++;
      if (total < this.contador) {
        this.contador = 1;
      }
      return this.contador;
  }

}
