<div class="spinner-container" *ngIf="calculandoImporte">
  <ngx-spinner name="spinnerImporteFactura" bdOpacity=0.3bd
    Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
    <p style="color: white">Calculando importe de factura...</p>
  </ngx-spinner>
</div>

<form *ngIf="!calculandoImporte">

  <div class="modal-header bg-light">
    <h4 class="modal-title">Facturar Aerovale de contado</h4>

    <button type="button" class="btn-close" aria-label="Close" (click)="this.activeModal.close()">
      
    </button>
  </div>

  <div class="modal-body" [formGroup]="importeFacturaForm">

    <div class="row">
      <div class="mb-3 row col-md-12" style="color: red" *ngIf="this.mostrarMensajeFacturacionEnPruebas">
          Atención: Este valor no indica el importe a cobrar al cliente.
      </div>
    </div>

    <div class="row">
      <div class="mb-3 row col-md-12">
        <label for="importeFormControl" class="col-sm-5 col-form-label">Importe a facturar</label>
        <div class="col-sm-3">
          {{ this.importeTotal | number: '0.2'}}
        </div>
      </div>
    </div>



    <div style="padding: 1em; border: 1px solid #ccc;">
      <!-- *ngIf="this.tanquesGenerales!=null && this.tanquesGenerales.length>0" -->
      <div class="row">
        <div class="mb-3 row col-md-12">
          <label for="tanqueGeneralFormControl" class="col-sm-2 col-form-label">Cupones</label>


          <div class="col-sm-3">
            <button (click)="this.modalAgregarCupon()" type="button" class="btn btn-primary"> Agregar</button>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="mb-3 row col-12">
          <div class="col-12"><b>Cupones agregados</b></div>
        </div>
      </div>

      <div *ngIf="this.emitirFactura != null && this.emitirFactura.cupones.length > 0" class="row">
        <div class="mb-3 row col-md-12" *ngFor="let c of this.emitirFactura.cupones">
          <div class="col-sm-10">Tarjeta: <b>{{c.tarjeta.nombre}} &nbsp; {{c.numeroTarjeta}}</b> - Cupón: <b>{{c.numeroLote}}/{{c.numeroCupon}}</b>  -  <b>${{c.importe}}</b></div>
          <div class="col-sm-2"><button type="button" (click)="desasignarCupon(c)"
              class="btn btn-danger btn-sm col-sm-12"><i class="fa fa-trash col-sm-12" aria-hidden="true"></i></button>
          </div>
        </div>
      </div>
    </div>

    <loading-text-spinner 
      *ngIf="this.mensaje!=null" message={{this.mensaje}}
      orientation="vertical" class="list-group-item mt-2" size="large" color="primary" textColor="dark">
    </loading-text-spinner>

    <div style="padding: 1em; border: 1px solid #ccc; margin-top: 0.5em;">

      <div class="row">
        <div class="mb-3 row col-md-12">
          <label  class="col-sm-5 col-form-label">Total cupones:</label>
          <div class="col-sm-3">
            {{ this.obtenerTotalEnCupones() | number: '0.2'}}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="mb-3 row col-md-12">
          <label class="col-sm-5 col-form-label">Total efectivo:</label>
          <div class="col-sm-3">
            {{ this.obtenerTotalEnEfectivo() | number: '0.2'}}
          </div>
        </div>
      </div>

      <!--COMENTARIO-->
      <div class="row">
        <div class="mb-3 row col-md-12">
          <label class="col-sm-5 col-form-label">Comentarios:</label>
          <div class="col-sm-7">
            <textarea class="form-control" formControlName="comentariosFormControl" rows='5'></textarea>
          </div>
        </div>
      </div>

    </div>

    <div class="alert alert-danger" *ngIf="error != null">
      {{error}}
    </div>

  </div>

  <div class="modal-footer bg-light">
    <span class="border">
      
      <button (click)="facturar()" class="btn btn-primary" [disabled]="this.facturando || this.enviandoMail" type="submit">
        <span *ngIf="!this.facturando && !this.enviandoMail">Facturar</span>
        <div *ngIf="this.facturando || this.enviandoMail" class="d-flex align-items-center">
          <div class="spinner-border text-light spinner-border-sm" role="status"></div>
          <span>{{ this.facturando ? 'Facturando...' : 'Enviando correo...' }}</span>
        </div>
      </button>

    </span>
    <span class="border">
      <button type="button" class="btn btn-secondary" (click)="this.activeModal.close()">Cancelar</button>

    </span>

    <div style="color: red" *ngIf="mostrarEsperaEntreReintentosFacturacion">
      Fallo la conexión con AFIP. Aguarde mientras reintentamos conectarnos.
      <br/>
      <b>Reintentaremos conexión en {{this.segundosRestantes}} segundos.</b>
    </div>
  </div>


</form>
