export class AutorizacionDTO {
    id:	string;
    idDeDespacho: string;
    ctA_SGC: string;  
    cuentaDescripcion: string;
    matricula: string;
    usuarioSolicitador: string;
    fechaHora: string;
    usuarioAprobador: string;
    fechaHoraAprobacion: string;
    estado: number;    

    seleccionadoUi: boolean;
}