import { Component, OnInit, forwardRef, EventEmitter, Output, Input } from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  UntypedFormGroup,
  UntypedFormBuilder,
  ControlValueAccessor,
  Validators,
  NG_VALIDATORS,
  UntypedFormControl,
  UntypedFormArray
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { Producto } from 'src/app/shared/models/despacho/Producto';
import { ProductosService } from '../../productos.service';
import { TipoAerovale } from 'src/app/shared/enums/tipoAerovale';
import { UsuarioService } from 'src/app/account/usuario.service';

@Component({
  selector: 'aerovale-aeroespecialidad-form',
  templateUrl: './aerovale-aeroespecialidad.component.html',
  styleUrls: ['./aerovale-aeroespecialidad.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AerovaleAeroespecialidadComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AerovaleAeroespecialidadComponent),
      multi: true
    }
  ]
})

export class AerovaleAeroespecialidadComponent implements OnInit {

  @Input() tipoAerovale : TipoAerovale;
  @Output() formularioValido = new EventEmitter();
  @Output() objetAeroespecialidadForm = new EventEmitter();

  enumTipoAerovale = TipoAerovale;

  nuevaAeroespecialidadItem(item: Producto): UntypedFormGroup {
    return this.formBuilder.group({
      nombreProducto: [item.nombreProducto, Validators.required],
      //idAeroespecialidad: [item.id, Validators.required] ,
      cantidad: ['', Validators.required],
      productoCodigo: [item.codigoProducto],
    })
 }

  form: UntypedFormGroup;
  subscriptions: Subscription[] = [];

  aeroespecialidadesSeleccionables: Producto[];
  arreglo : any[] = [];
  cargandoAeroespecialidades: boolean;
  errorAeroespecialidades: boolean;

  constructor(
    private productoService: ProductosService,
    private formBuilder: UntypedFormBuilder,
    private usuariosService: UsuarioService
    ) {

  }

  ngOnInit() {
    this.crearFormulario();

    this.inicializarComponente();

    this.getAeroespecialidades();
  }

  crearFormulario(){
    this.form = this.formBuilder.group(
      {
        vueloFormControl: [''],
        aeroespecialidadesSeleccionablesFormControl: [null],
        aeroespecialidadesSeleccionadasFormControlArray: this.formBuilder.array([], (this.tipoAerovale == TipoAerovale.Aeroespecialidad ? Validators.required : null))
      }
    );

    this.objetAeroespecialidadForm.emit(this.form);
  }

  inicializarComponente(){
    this.subscriptions.push(
      this.form.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      })
    );

    this.form.valueChanges.subscribe(val => {
      this.formularioValido.emit(this.form.valid);
    });
  }

  getAeroespecialidades(): void {
    this.cargandoAeroespecialidades = true;

    this.productoService.getAeroespecialidadesParaAeroplantaActual(this.usuariosService.getAeroplantaActual().codigoAeroplanta)
      .subscribe(aeroespecialidades => {
        this.aeroespecialidadesSeleccionables = aeroespecialidades;
        this.cargandoAeroespecialidades = false;

      }, () => {
        this.errorAeroespecialidades = true;
        this.cargandoAeroespecialidades = false;
      });
  }

  onAeroespecialidadSeleccionada(){
    if(this.aeroespecialidadesSeleccionablesFormControl.value){
      this.aeroespecialidadesSeleccionadasFormControlArray.push(this.nuevaAeroespecialidadItem(this.aeroespecialidadesSeleccionablesFormControl.value));
      let p = this.aeroespecialidadesSeleccionables.indexOf(this.aeroespecialidadesSeleccionablesFormControl.value);
      this.arreglo.push(this.aeroespecialidadesSeleccionables[p]);
      this.aeroespecialidadesSeleccionables.splice(p, 1);      
    }
  }

  onAeroespecialidadEliminada(i:number) {
    let aero = this.arreglo.filter(x => x.codigoProducto == this.aeroespecialidadesSeleccionadasFormControlArray.value[i].productoCodigo); 
    this.aeroespecialidadesSeleccionables.push(aero[0]);
    let pos = this.arreglo.findIndex(x => x.codigoProducto == this.aeroespecialidadesSeleccionadasFormControlArray.value[i].productoCodigo);
    this.arreglo.slice(pos, 1);
    this.aeroespecialidadesSeleccionadasFormControlArray.removeAt(i);    
  }

  esCampoVacio(campo: any): boolean {
    return (campo == null || (campo != null && campo.length == 0));
  }

  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  /* -------------------------------------------------------------------------------------------------------- */

  get vueloFormControl() {
    return this.form.controls.vueloFormControl;
  }

  get aeroespecialidadesSeleccionablesFormControl() {
    return this.form.controls.aeroespecialidadesSeleccionablesFormControl;
  }

  get aeroespecialidadesSeleccionadasFormControlArray() : UntypedFormArray {
    return this.form.controls.aeroespecialidadesSeleccionadasFormControlArray as UntypedFormArray;
  }

  /* -------------------------------------------------------------------------------------------------------- */

  get value(): AerovaleAeroespecialidadFormValues {
    return this.form.value;
  }

  set value(value: AerovaleAeroespecialidadFormValues) {
    this.form.setValue(value);
    this.onChange(value);
    this.onTouched();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }

    if (value === null) {
      this.form.reset();
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  validate(_: UntypedFormControl) {
    return this.form.valid ? null : { aerovaleAeroespecialidad: { valid: false } };
  }
}

export interface AerovaleAeroespecialidadFormValues {

}
