import { Component, Input, OnInit } from '@angular/core';
import { AgrupacionRemitos } from 'src/app/shared/enums/agrupacionRemitos';
import { CondicionIVA } from 'src/app/shared/enums/condicionIVA';
import { TipoCuenta } from 'src/app/shared/enums/tipoCuenta';
import { TipoDocumento } from 'src/app/shared/enums/tipoDocumento';
import { TipoEntregaFactura } from 'src/app/shared/enums/tipoEntregaFactura';
import { TipoMoneda } from 'src/app/shared/enums/tipoMoneda';
import { ModificacionClienteDTO } from 'src/app/shared/models/cliente/ModificacionClienteDTO';
import { UtilClientes } from 'src/app/shared/utiles/UtilClientes';

@Component({
  selector: 'app-datos-nuevo-cliente',
  templateUrl: './datos-nuevo-cliente.component.html',
  styleUrls: ['./datos-nuevo-cliente.component.css']
})
export class DatosNuevoClienteComponent implements OnInit {

  @Input() modificacionesCliente: ModificacionClienteDTO;

  constructor(public utilClientes: UtilClientes) { }

  ngOnInit() {
    var m = this.modificacionesCliente;
  }

 
}
