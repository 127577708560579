import { Input, NgModule } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';
import { Shell } from '../shell/shell.service';
import { PedidoDetalleComponent } from './pedido-detalle/pedido-detalle.component';
import { AerovaleDetalleComponent } from './aerovale-detalle/aerovale-detalle.component';
import { VisorAerovalesComponent } from './visor-aerovales/visor-aerovales.component';
import { DespachoIndexComponent } from './despacho-index/despacho-index.component';
import { authGuard } from '../core/authentication/auth.guard';
import { TipoUsuario } from '../shared/enums/tipoUsuario';
import { PrecargasComponent } from './precargas/precargas.component';
import { AlijesSaldosComponent } from './alijes/alijes-saldos/alijes-saldos.component';
import { AlijesReportesComponent } from './alijes/alijes-reportes/alijes-reportes.component';
import { VuelosTamsComponent } from './tams/vuelos-tams/vuelos-tams.component';
import { TamsIndexComponent } from './tams/tams-index/tams-index.component';
import { AerolineasTamsListaComponent } from './tams/aerolineas/aerolineas-tams-lista/aerolineas-tams-lista.component';
import { DestinosTamsListaComponent } from './tams/destinos/destinos-tams-lista/destinos-tams-lista.component';
import { ScreenMonitorComponent } from './tams/screen-monitor/screen-monitor.component';
import { ScreenConfiguracionComponent } from './tams/screen-configuracion/screen-configuracion.component';
import { AlijesIndexComponent } from './alijes/alijes-index/alijes-index.component';
import { AuthService } from "../core/authentication/auth.service";
import { VuelosIndexComponent } from './tams/vuelos-tams/vuelos-index/vuelos-index.component';
import { TipoSolapaVueloTams } from '../shared/enums/tipoSolapaVueloTams';

const routes: Routes = [
  Shell.childRoutes([
    //{ path: 'despachos', redirectTo: 'despachos/pedidos', pathMatch: 'full' },
    { 
      path: 'despachos', component: DespachoIndexComponent, canActivate: [authGuard], data : { roles: [TipoUsuario.SUPERVISOR, TipoUsuario.OPERADOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.COMERCIAL, TipoUsuario.OPERADOR_3, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.FACTURACION, TipoUsuario.REFACTURACION, TipoUsuario.CONSAEROVALES, TipoUsuario.CONSPRECIOS, TipoUsuario.FINANZAS, TipoUsuario.SOPORTE] }, 
      children: [
        { path: 'aerovales', component: VisorAerovalesComponent, canActivate: [authGuard], data : { roles: [TipoUsuario.SUPERVISOR, TipoUsuario.OPERADOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.COMERCIAL, TipoUsuario.OPERADOR_3, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.FACTURACION, TipoUsuario.REFACTURACION, TipoUsuario.CONSAEROVALES, TipoUsuario.CONSPRECIOS, TipoUsuario.FINANZAS, TipoUsuario.SOPORTE] }  },
        { path: 'pedidos', component: PrecargasComponent, canActivate: [authGuard], data : { roles: [TipoUsuario.SUPERVISOR, TipoUsuario.OPERADOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.COMERCIAL, TipoUsuario.OPERADOR_3, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.SOPORTE] } },
        { path: 'alijes', component: AlijesIndexComponent, canActivate: [authGuard], data : { roles: [TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.COMERCIAL, TipoUsuario.OPERADOR_3, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.SOPORTE] },  
          children: [
            {
              path: 'alijesSaldos',
              component: AlijesSaldosComponent,
              canActivate: [authGuard]
            },
            {
              path: 'alijesReportes',
              component: AlijesReportesComponent,
              canActivate: [authGuard]      
            },
          ]
        },      
        { path: 'tams', 
            component: TamsIndexComponent, 
            canActivate: [authGuard], 
            data : { roles: [TipoUsuario.SUPERVISOR, TipoUsuario.OPERADOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.COMERCIAL, TipoUsuario.OPERADOR_3, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.FACTURACION, TipoUsuario.REFACTURACION, TipoUsuario.CONSAEROVALES, TipoUsuario.CONSPRECIOS, TipoUsuario.SOPORTE] },
            children: [
              {
                path: 'vuelosIndex',
                component: VuelosIndexComponent,
                canActivate: [authGuard],
                data: { },
                children:[
                  { path: 'partidas', component: VuelosTamsComponent, canActivate: [authGuard],data: { } },
                  { path: 'arribos', component: VuelosTamsComponent, canActivate: [authGuard],data: { } }
                ]
              },
              {
                path: 'aerolineasTams',
                component: AerolineasTamsListaComponent,
                canActivate: [authGuard],
                data: { }          
              },
              {
                path: 'destinosTams',
                component: DestinosTamsListaComponent,
                canActivate: [authGuard],
                data: { }          
              },
              {
                path: 'screenConfiguracion',
                component: ScreenConfiguracionComponent,
                canActivate: [authGuard],
                data: { }          
              },
              {
                path: 'screenMonitor',
                component: ScreenMonitorComponent,
                canActivate: [authGuard],
                data: { }
              }
            ]},
      ]},
    { path: 'pedido/:id', component: PedidoDetalleComponent, canActivate: [authGuard] },    
    { path: 'aerovale/:id/:tipo', component: AerovaleDetalleComponent, canActivate: [authGuard] }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class DespachoRoutingModule {

  constructor( 
    private authService: AuthService,
    private router: Router ) 
    {
      this.router.events.subscribe((event) => {
        if(event['url'] && event['url'] == '/despachos' && this.authService.hasRole(TipoUsuario.SUPERVISOR, TipoUsuario.OPERADOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.COMERCIAL, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.APROBADORBYR, TipoUsuario.SOPORTE)) {
          this.router.navigate(['/despachos/pedidos']);
        } else if(event['url'] && event['url'] == '/despachos/tams') {
          this.router.navigate(['/despachos/tams/vuelosIndex/partidas'], {queryParams : {solapaVuelosTams: TipoSolapaVueloTams.Partidas}});
        } else if(event['url'] && event['url'] == '/despachos/alijes') {
          this.router.navigate(['/despachos/alijes/alijesSaldos']);
        }else if(event['url'] && event['url'] == '/despachos/alijes' && this.authService.hasRole(TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.COMERCIAL, TipoUsuario.RESPRODUCTO, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.APROBADORBYR, TipoUsuario.SOPORTE)) {
          this.router.navigate(['/despachos/alijes/alijesReportes']);
        }else if(event['url'] && event['url'] == '/despachos' && this.authService.hasRole(TipoUsuario.FACTURACION, TipoUsuario.REFACTURACION, TipoUsuario.CONSAEROVALES, TipoUsuario.FINANZAS, TipoUsuario.SOPORTE)) {
          this.router.navigate(['/despachos/aerovales']);
        }
      });
    }
 }

