<div class="card" id="islas-card">
    <div class="card-header">
      <h4 style="display: inline;" class="card-title">ABM Sistemas de seguridad.</h4>
      <button *ngIf="this.hasRole(TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.RESPRODUCTO, TipoUsuario.TUTOR)" style="margin-left: 4em;" class="btn btn-primary" type="button" [disabled]="this.obteniendoSistemas"
        (click)="abrirModal(false, null)">Agregar nuevo sistema</button>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="spinner-container" *ngIf="this.obteniendoSistemas">            
            <ngx-spinner name="spinnerSistemas" bdOpacity=0.3bd
              Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
              <p style="color: white">{{messageSistemas}}</p>
            </ngx-spinner>          
          </div>
          <div *ngIf="this.sistemas.length == 0 && !obteniendoSistemas" class="alert alert-warning">No hay sistemas de seguridad
            para la aeroplanta seleccionada.</div>
          
          <table *ngIf="this.sistemas.length > 0 && !this.obteniendoSistemas" class="table table-striped table-hover col-md-6"
            [formGroup]="tablaSistemas">
            <thead>
              <th class="text-center">Nombre</th>
              <th class="text-center">Editar</th>
              <th class="text-center">Estado actual</th>
              <th class="text-center">Acción</th>
            </thead>
            <tbody>
              <tr formArrayName="sistemasForm" *ngFor="let s of sistemas; let i = index">
                <td class="text-center">{{s.nombre}}</td>
                <td class="text-center">
                  <button type="button" class="btn btn-primary btn-sm me-1" (click)="abrirModal(true, i, s)"><i class="fa fa-pencil fa-lg"
                      aria-hidden="true" ngbTooltip="Editar"></i></button>
                </td>
                <td class="text-center">                  
                  <select [formControlName]="i" class="form-select" ngbTooltip="Seleccione el estado.">
                    <option *ngFor="let estado of keysEstados()" [ngValue]="obtenerEstado(estado)">
                      {{estado}}
                    </option>
                  </select>
                  <div class="alert alert-danger"
                    *ngIf="(this.sistemas[i].invalid && this.sistemas[i].errors.required && (this.sistemas[i].dirty || this.sistemas[i].touched))">
                    Debe seleccionar un estado obligatoriamente.
                  </div>                  
                </td>
                <td class="text-center">
                  <button type="button" [disabled]="mismoEstado(sistemas[i])" class="btn btn-primary btn-sm me-1" aria-hidden="true" (click)="abrirModal(false, i, sistemas[i])">
                    <span *ngIf="!guardandoEstado[i]">Guardar</span>
                      <div *ngIf="guardandoEstado[i]" class="d-flex align-items-center">
                        <div class="spinner-border text-light spinner-border-sm" role="status"></div>
                        <span>Guardando...</span>
                      </div>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>  
        </div>
      </div>
    </div>
