import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { PedidoService } from 'src/app/despacho/pedido.service';
import { AerolineaEncabezadoDTO } from 'src/app/shared/models/despacho/AerolineaEncabezadoDTO';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';

@Component({
  selector: 'app-destinos-tams-lista',
  templateUrl: './destinos-tams-lista.component.html',
  styleUrls: ['./destinos-tams-lista.component.scss']
})
export class DestinosTamsListaComponent implements OnInit {

  listaAerolineas: AerolineaEncabezadoDTO[];
  listaAerolineasFiltrados: AerolineaEncabezadoDTO[];
  aerolineaSeleccionada: AerolineaEncabezadoDTO;

  buscadorFormControl = new UntypedFormControl();
  cargandoLista: boolean;

  constructor(
    private spinner: NgxSpinnerService,
    private pedidoService: PedidoService
  ) { }

  ngOnInit(): void {
    this.cargarAerolineas();
  }

  cargarAerolineas() {
    this.mostrarSpinner();

    this.pedidoService.getAerolineasEncabezado()
    .subscribe(result => {
      this.listaAerolineas = result;
      this.listaAerolineasFiltrados = result;
      this.ocultarSpinner();      
    }, (err: ErrorModel) => {    
      console.log(err);
      this.ocultarSpinner();
    });
  }

  mostrarSpinner() {
    this.cargandoLista = true;
    this.spinner.show('spinnerGrafico')
  }

  ocultarSpinner() {
    this.cargandoLista = false;
    this.spinner.show('spinnerGrafico')
  }

  usuarioSeleccionoAerolinea(a: AerolineaEncabezadoDTO){
    this.aerolineaSeleccionada = a;
  }

  filtrarAerolineas() {
    let textoBuscado = this.buscador.value;

    this.listaAerolineasFiltrados = this.listaAerolineas.filter(a => {
      let agregar = true;
      if (textoBuscado && textoBuscado.length>2) {
        agregar = a.razonSocial.toLowerCase().indexOf(textoBuscado.toLowerCase()) > -1;
      }
      return agregar;
    });
  }

  get buscador() {
    return this.buscadorFormControl;
  }

}
