import { Component, OnInit, Inject, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ReportesService } from '../reportes.service';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';

@Component({
  selector: 'app-reports-index',
  templateUrl: './reports-index.component.html',
  styleUrls: ['./reports-index.component.css']
})
export class ReportsIndexComponent implements OnInit{

  TipoUsuario = TipoUsuario;

  constructor( private reportesServices : ReportesService, private authService: AuthService ) {
  }

  ngOnInit() {

  }

  hasRole(...roles: TipoUsuario[]) {
    return this.authService.hasRole(...roles);
  }
}
