<nav class="navbar navbar-light bg-light navbar-expand-lg" *ngIf="tipoNuevoAerovale == null">
  <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <form class="form-inline my-2 my-lg-0">
      <input id="busquedaFormControl" type="text" class="form-control" [formControl]="busquedaFormControl"
        (input)="filtrarAerovales()" placeholder="Búsqueda" />
    </form>
    <ul class="navbar-nav me-auto">
      <!-- estados -->
      <li class="nav-item">       
        <div ngbDropdown autoClose="outside">
          <button class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2" id="dropdownForm1"
            ngbDropdownToggle>Estados</button>
            
          <div ngbDropdownMenu aria-labelledby="dropdownForm1">
            <div class="px-4 py-3">
              <div *ngFor="let estado of estados">
                <div class="form-check">
                  <input type="checkbox" class="form-check-input" id="{{ 'dropdownCheck' + estado.valor}}"
                    [(ngModel)]="estado.seleccionado" (change)="filtrarAerovales()">
                  <label class="form-check-label text-nowrap" for="{{ 'dropdownCheck' + estado.valor}}">
                    {{ estado.descripcion }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
      <!-- tipos button -->
      <li class="nav-item">
        <div ngbDropdown autoClose="outside">
          <button class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2" id="dropdownForm1"
            ngbDropdownToggle>Tipos</button>
          <div ngbDropdownMenu aria-labelledby="dropdownForm1">
            <div class="px-4 py-3">
              <div *ngFor="let tipo of tipos">
                <div class="form-check">
                  <input type="checkbox" class="form-check-input" id="{{ 'dropdownCheck' + tipo.valor}}"
                    [(ngModel)]="tipo.seleccionado" (change)="filtrarAerovales()">
                  <label class="form-check-label text-nowrap" for="{{ 'dropdownCheck' + tipo.valor}}">
                    {{ tipo.descripcion }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

      </li>
    </ul>
    <ul class="navbar-nav">
      <!-- desde -->
      <li class="nav-item me-2">
        <div class="input-group">

          <input type="date" [ngModel]="model" class="fechaFormControlDesdeHasta" placeholder="aaaa-mm-dd"
            (ngModelChange)="model = $event" name="dt" ngbDatepicker #d="ngbDatepicker" value="model"
            class="form-control form-control-rounded" #searchDate required readonly>

          <div class="input-group-append">
            <button id="botonDesde" type="button" class="input-group-btn btn btn-secondary" (click)="d.toggle()">
              <i class="fa fa-calendar" aria-hidden="true"></i></button>
          </div>
        </div>
      </li>
      <!-- hasta -->
      <li class="nav-item">
        <div class="input-group">
          <input type="date" class="fechaFormControlDesdeHasta" [ngModel]="model1" placeholder="aaaa-mm-dd" name="dp"
            value="model1" (ngModelChange)="model1 = $event" class="form-control form-control-rounded" ngbDatepicker
            #h="ngbDatepicker" #searchDate required readonly>

          <div class="input-group-append">
            <button id="botonHasta" type="button" class="input-group-btn btn btn-secondary" (click)="h.toggle()"><i
                class="fa fa-calendar" aria-hidden="true"></i></button>
          </div>
        </div>
      <li class="nav-item">

        <button (click)="buscarDesdeHasta()" class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2" type="submit"><i
            class="fa fa-search" aria-hidden="true"></i></button>

      </li>

      <li *ngIf="authService.hasRole(TipoUsuario.SUPERVISOR, TipoUsuario.OPERADOR, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO,TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.TUTOR, TipoUsuario.MANDATARIO)" class="nav-item">
        <button type="button" (click)="crearNuevoAerovale()" id="aerovaleContingenciaId"
          class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2">Aerovale contingencia</button>
      </li>
      <!-- exportar -->
      <li class="nav-item">
        <button (click)="export()" class="btn btn-outline-primary my-2 my-lg-0 mx-lg-2"><i
            class="fa fa-file-excel-o fa-lg" aria-hidden="true"></i> Descargar </button>
      </li>
    </ul>

  </div>

</nav>

<ngb-alert type="danger" [dismissible]="false" *ngIf="fechaDesdeMayorQueHasta()==true">
  La fecha inicial debe ser anterior a la fecha final.
</ngb-alert>

<div class="abs-center">
  <ngb-alert type="warning" *ngIf="this.mensajeErrorExportar!=''">
    {{this.mensajeErrorExportar}}
  </ngb-alert>
</div>

<div class="abs-center"
  *ngIf="tipoNuevoAerovale == null && !obteniendoAerovales && (!aerovales || aerovales.length < 1)">
  <ngb-alert type="warning"> No se encontraron aerovales registrados para el rango de fechas seleccionado.</ngb-alert>
</div>
<div class="row" *ngIf="tipoNuevoAerovale == null">
  <div class="col col-4">
    <div class="list-group py-3  menu-izq">
      <div *ngIf="obteniendoAerovales">
        <ngx-spinner name="spinnerLista" [ngStyle]="{'height': '180px', 'z-index':'0'}"
          [ngClass]="{'list-group-item list-group-item-action': obteniendoAerovales}" bdOpacity=0.3bd
          Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
          <p style="color: white"> {{ this.mensajeSpinner}} </p>
        </ngx-spinner>
      </div>
      <div *ngIf="procesandoExport && obteniendoAerovales">
        <ngx-spinner name="spinnerLista" [ngStyle]="{'height': '180px', 'z-index':'0'}"
          [ngClass]="{'list-group-item list-group-item-action': procesandoExport}" bdOpacity=0.3bd
          Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
          <p style="color: white"> {{ this.mensajeSpinner}} </p>
        </ngx-spinner>
      </div>
      <div *ngIf="errorAerovales" class="list-group-item list-group-item-action">
        <i class="fa fa-times text-danger"></i><span class="ps-2">Se ha producido un error al obtener los
          aerovales</span>
      </div>
      <div *ngFor="let aerovale of aerovalesFiltrados">
        <button [@fadeIn] type="button" (click)="onSelect(aerovale)"
          [class.active]="aerovaleDetalle!=null && (aerovale.id == aerovaleDetalle.id)"
          class="list-group-item list-group-item-action">

          <div class="d-flex w-100 justify-content-between">
            <p><b>Aev Nº:
                {{ aerovale.prefijo | aerovalePrefijo }}{{ aerovale.numeroAerovale | aerovaleNumero }}</b></p>
            <p *ngIf="aerovale.volumen != null">{{ aerovale.volumen | number : '0.0-0'}} LTS</p>
          </div>
          <div class="d-flex w-100 justify-content-between">
            <h5 class="mb-1">{{ aerovale.vuelo }} / {{ aerovale.matricula }} </h5>
            <small>{{ aerovale.estado }}</small>
          </div>
          <p class="mb-1"> {{ aerovale.clienteRazonSocial }} <br> {{ aerovale.nombreProducto }} </p>
          <small>{{ aerovale.fechaHoraFinDespacho | date: "d'/'MM'/'y' 'hh:mm a" }}</small>
        </button>
      </div>
    </div>
  </div>
  <div class="py-3 col-6">
    <div *ngIf="!aerovaleDetalle" class="spinner-container">
      <ngx-spinner name="spinnerDetalle" bdOpacity=0.3bd Color="rgba(193,193,193,0)" size="medium" color="#185db5"
        type="ball-grid-beat" [fullScreen]="false">
        <p style="color: white"> {{this.mensajeSpinner}} </p>
      </ngx-spinner>
    </div>
    <div *ngIf="aerovaleDetalle">
      <app-aerovale-detalle 
        [aerovaleDetalle]="aerovaleDetalle"
        [capturarAerovale]="capturarAerovale" 
        (fileAerovaleFisico)="usuarioCapturoAerovale($event)"
        ></app-aerovale-detalle>
    </div>
  </div>

  <div class="mt-3 ms-0 me-0 ps-0 pe-0 col-2" *ngIf="this.obteniendoComprobante || this.buscandoComprobante">
    <ngx-spinner name="spinnerLista" [ngStyle]="{'height': '180px', 'z-index':'0'}"
      [ngClass]="{'list-group-item list-group-item-action': obteniendoComprobante}" bdOpacity=0.3bd
      Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
      <p style="color: white"> {{ this.mensajeSpinner}} </p>
    </ngx-spinner>
  </div>

  <div *ngIf="!this.obteniendoComprobante && aerovaleDetalle" class="mt-3 ms-0 me-0 ps-0 pe-0 col-2">

    <div class="navbar navbar-light bg-light btn-group-vertical">
      <button *ngIf="this.rolesParaAnularOk() && this.logicaParaMostrarBotonAnularAerovaleOk()" (click)="anularAerovale(aerovaleDetalle)"
      class="btn btn-outline-secondary mb-2" type="button"><i class="fa fa-ban" aria-hidden="true"></i>Anular</button>
    </div>
   
    <nav *ngIf="aerovaleDetalle && remitoFox != null" class="navbar navbar-light bg-light btn-group-vertical">
      
      <button *ngIf="(!sincronizado(aerovaleDetalle)) && sincronizable(aerovaleDetalle) && ! aeroplantaSoloPad()"
        (click)="sincronizar(aerovaleDetalle)" class="btn btn-outline-success mb-2" type="button">
        <span *ngIf="sincronizado(aerovaleDetalle)" class="navbar-text">
          <i class="fa fa-check" aria-hidden="true">Sincronizado</i>
        </span>
        <i class="fa fa-refresh" aria-hidden="true"></i> Marcar como Sincronizado</button>

      <!-- mostrar solo cuando se tiene un numero de factura, sino ocultar -->
      <button *ngIf="authService.hasRole(TipoUsuario.SUPERVISOR, TipoUsuario.OPERADOR, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.MANDATARIO, TipoUsuario.TUTOR, TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.FINANZAS, TipoUsuario.SOPORTE, TipoUsuario.APROBADORBYR) && this.verificarEstadoFactura()" (click)="mostrarFactura(content)" class="btn btn-outline-secondary mb-2"
        type="button">
        <i class="fa fa-eye" aria-hidden="true"></i>Ver factura</button>
        
        <!-- *ngIf="aerovaleDetalle.detalleAerocombustible.tipoVuelo == 2" -->
      <button (click)="verRemito()" *ngIf="authService.hasRole(TipoUsuario.SUPERVISOR, TipoUsuario.OPERADOR, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.TUTOR, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.SOPORTE) && this.remitoFox != null || this.remitoObtenido != null"
        class="btn btn-outline-secondary mb-2" type="button"><i class="fa fa-eye" aria-hidden="true"></i>Ver
        Remito</button>

      <button *ngIf="(aerovaleDetalle.estado=='Finalizado' || aerovaleDetalle.estado=='FinalizadoSinFirma' || aerovaleDetalle.estado=='Sincronizado')"
        (click)="print()" class="btn btn-outline-secondary mb-2" type="button"><i class="fa fa-print"
          aria-hidden="true"></i> Imprimir</button>
          
      <button
        *ngIf="authService.hasRole(TipoUsuario.SUPERVISOR, TipoUsuario.OPERADOR, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.TUTOR, TipoUsuario.MANDATARIO, TipoUsuario.COMERCIAL_AV)  && this.mostrarBotonFacturarAerovale && aerovaleDetalle.tipoPago == 1 && aeroplantaPuedeFacturar"
        (click)="irAImporteDeFactura()" class="btn btn-outline-secondary mb-2" type="button">
        <i class="fa fa-dollar" aria-hidden="true"></i>Facturar aerovale</button>

      <button
        *ngIf="aerovaleDetalle.tipoAerovale == TipoAerovale.Aerocombustible && (aerovaleDetalle.estado=='Finalizado' || aerovaleDetalle.estado=='FinalizadoSinFirma' || aerovaleDetalle.estado=='Sincronizado')"
        (click)="auditoriaAforadores()" class="btn btn-outline-secondary mb-2" type="button">
        Auditoria aforadores
      </button>
      
      <button *ngIf="aerovaleDetalle.estado=='Anulado' && aerovaleDetalle.idAerovaleReemplaza == null"
        (click)="modificarAerovale(aerovaleDetalle)"
        class="btn btn-outline-secondary mb-2" type="button"><i class="fa fa-pencil" aria-hidden="true"></i>
        Reemplazar Aerovale</button>   

      <button         
        (click)="auditoriaAutorizaciones()" class="btn btn-outline-secondary mb-2" type="button">
        Auditoria autorizaciones
      </button>
    </nav>
  </div>
</div>

<div [@fadeIn]
  *ngIf="tipoNuevoAerovale == TipoAerovale.Combustible || tipoNuevoAerovale == TipoAerovale.Aeroespecialidad || tipoNuevoAerovale == TipoAerovale.Alije ">
  <app-nuevo-aerovale-v2 [tipoAerovale]="tipoNuevoAerovale" (nuevoAerovaleCreado)="onNuevoAerovaleCreado($event)"
    (cancelado)="onNuevoAerovaleCancelado()">
  </app-nuevo-aerovale-v2>
</div>

<div [@fadeIn] *ngIf="tipoNuevoAerovale == TipoAerovale.Aerocombustible">
  <app-nuevo-aerovale (nuevoAerovaleCreado)="onNuevoAerovaleCreado($event)" (cancelado)="onNuevoAerovaleCancelado()">
  </app-nuevo-aerovale>
</div>

<ng-template #content let-modal>
  <app-visor-comprobante [facturaObtenida]='this.facturaObtenida'></app-visor-comprobante>
</ng-template>





