import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Leyenda } from 'src/app/shared/models/facturacion/Leyenda';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ManagementService } from '../management.service';
import { MessageService } from 'src/app/shell/message.service';
import { LeyendaEmisor } from 'src/app/shared/enums/LeyendaEmisor';

@Component({
  selector: 'app-modal-leyendas',
  templateUrl: './modal-leyendas.component.html',
  styleUrls: ['./modal-leyendas.component.css']
})
export class ModalLeyendasComponent implements OnInit {
  @Input() emisorSeleccionado;
  leyendaSeleccionada: Leyenda;
  actualizandoLeyenda: boolean = false;
  pos: number = 0;
  posi: number;
  any: any;
  nuevo: boolean = false;
  leyendasEmisor = LeyendaEmisor;
  descLength: number = 255;
  descValue: string;

  detalleLeyenda = new UntypedFormGroup({
    checkActivo: new UntypedFormControl(),
    selecPosicion: new UntypedFormControl(),
    inputOrden: new UntypedFormControl(),
    selecFactura: new UntypedFormControl(),
    textDescripcion: new UntypedFormControl([null, [Validators.required]])
  });
  constructor(public modal: NgbModal, private managmentService: ManagementService, private messageService: MessageService) { }

  ngOnInit() {
    if (this.emisorSeleccionado.leyendas.length > 0) {
      this.leyendaSeleccionada = this.emisorSeleccionado.leyendas[0];
      this.check.setValue(this.leyendaSeleccionada.activo);
      this.posicion.setValue(this.leyendaSeleccionada.frame);
      this.orden.setValue(this.leyendaSeleccionada.orden);
      this.factura.setValue(this.leyendaSeleccionada.tipoFactura);
      this.descripcion.setValue(this.leyendaSeleccionada.descripcion);
    } else {
      this.nuevaLeyenda();
    }
  }

  ngOnChanges() {
    this.ngOnInit();
  }
  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  modificarLeyenda(i: number) {
    this.nuevo = false;
    this.leyendaSeleccionada = this.emisorSeleccionado.leyendas[i];
    this.check.setValue(this.leyendaSeleccionada.activo);
    this.posicion.setValue(this.leyendaSeleccionada.frame);
    this.orden.setValue(this.leyendaSeleccionada.orden);
    this.factura.setValue(this.leyendaSeleccionada.tipoFactura);
    this.descripcion.setValue(this.leyendaSeleccionada.descripcion);
    this.pos = i;
  }

  modificar() {
    if (this.detalleLeyenda.valid) {
      this.actualizandoLeyenda = true;
      this.emisorSeleccionado.leyendas[this.pos].activo = this.check.value;
      this.emisorSeleccionado.leyendas[this.pos].posicion = parseInt(this.posicion.value);
      this.emisorSeleccionado.leyendas[this.pos].frame = parseInt(this.posicion.value);
      this.emisorSeleccionado.leyendas[this.pos].orden = parseInt(this.orden.value);
      this.emisorSeleccionado.leyendas[this.pos].tipoFactura = parseInt(this.factura.value);
      this.emisorSeleccionado.leyendas[this.pos].descripcion = this.descripcion.value;
      this.managmentService.actualizarEmisor(this.emisorSeleccionado)
        .subscribe(result => {
          this.any = result;
          this.actualizandoLeyenda = false;
          this.messageService.showSuccessMessage('La leyenda se actualizo correctamente')
          this.ngOnInit();
        });
    } else {
      this.messageService.showErrorMessage('Faltan completar datos')
    }
  }
  nuevaLeyenda() {
    this.nuevo = true;
    this.check.setValue(false);
    this.posicion.setValue(0);
    this.orden.setValue(0);
    this.factura.setValue(0);
    this.descripcion.setValue(null);
  }

  obtenerTipoFactura(tipo: number) {
    return LeyendaEmisor[tipo];
  }


  crearNuevaLeyenda() {
    if (this.detalleLeyenda.valid) {
      let nuevaLeyenda: Leyenda = new Leyenda();
      this.posi = this.emisorSeleccionado.leyendas.length + 1;
      nuevaLeyenda.activo = this.check.value;
      nuevaLeyenda.frame = parseInt(this.posicion.value);
      nuevaLeyenda.tipoFactura = parseInt(this.factura.value);
      nuevaLeyenda.descripcion = this.descripcion.value;
      nuevaLeyenda.orden = parseInt(this.orden.value);
      this.emisorSeleccionado.leyendas.push(nuevaLeyenda);
      this.managmentService.actualizarEmisor(this.emisorSeleccionado)
        .subscribe(result => {
          this.any = result;
          this.actualizandoLeyenda = false;
          this.messageService.showSuccessMessage('La leyenda se creo correctamente')
          this.ngOnInit();
        });
    } else {
      this.messageService.showErrorMessage('Faltan completar datos')
    }
  }

  keysLeyendas(): Array<string> {
    var keys = Object.keys(this.leyendasEmisor);
    keys = keys.slice(keys.length / 2);
    return keys;
  }

  get check() {
    return this.detalleLeyenda.get('checkActivo');
  }
  get posicion() {
    return this.detalleLeyenda.get('selecPosicion');
  }
  get orden() {
    return this.detalleLeyenda.get('inputOrden');
  }
  get factura() {
    return this.detalleLeyenda.get('selecFactura');
  }
  get descripcion() {
    return this.detalleLeyenda.get('textDescripcion');
  }
}
