<div class="card" id="islas-card">
  <div class="card-header">
    <label class="col-form-label ms-2"><b>Cierre de caja</b></label>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-12">
        <div class="spinner-container" *ngIf="obteniendoFacturas">
          <ngx-spinner name="spinnerLista" bdOpacity=0.3bd
            Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
            <p style="color: white"> O b t e n i e n d o C I E R R E </p>
          </ngx-spinner>      
        </div>
        <div *ngIf="this.facturasContado.length == 0 && !obteniendoFacturas" class="alert alert-warning">No hay
          facturas de contado para la aeroplanta seleccionada.</div>
        <table class="table table-striped table-hover text-center"  [formGroup]="efectivoForm"
          *ngIf="this.facturasContado.length > 0 && !obteniendoFacturas">
          <thead>
            <th>Cliente</th>
            <th>Factura</th>
            <th>Producto</th>
            <th>Fecha/Hora</th>
            <th>Pago con tarjeta</th>
            <th>Importe en Efectivo</th>
            <th>Total FC</th>
            <th>Usuario</th>
            <th>Ver</th>
            <th>Controlada</th>
          </thead>
          <tbody formArrayName="facturasIngresadoEfectivoForm">
            <tr *ngFor="let factura of this.facturasContado; let i = index">
              <td>{{factura.cliente}}</td>
              <td>{{factura.prefijo}} - {{factura.numeroComprobante}}</td>
              <td>
                <ul *ngFor="let prod of factura.renglones" class="list-unstyled">
                  <li>{{prod.productoCodigo}} - {{prod.productoNombre}}</li>
                </ul>
              </td>
              <td>{{ factura.fechaEmision | date: "dd/MM/yyy HH:mm a" }}</td>
              <td>{{totalCupones(factura.pago) | number: '0.2-2'}}</td>
              <td>{{totalEfectivo(factura.total, factura.pago) | number: '0.2-2'}}</td>
              <td>{{factura.total | number: '0.2-2'}}</td>
              <td>{{factura.responsable}}</td>
              <td>
                <button class="btn btn-primary mb-2" type="button"
                    (click)="mostrarFacrtura(factura)" [disabled]="factura.buscarComprobante">
                    <span *ngIf="!factura.buscarComprobante"><i class="fa fa-eye" aria-hidden="true"></i></span>
                    <div *ngIf="factura.buscarComprobante" class="d-flex align-items-center">
                      <div class="spinner-border text-light spinner-border-sm" role="status"></div>
                      <span><i class="fa fa-eye" aria-hidden="true"></i></span>
                    </div>
                  </button>
              </td>
              <td>
                <div class="form-check">
                  <input type="checkbox" class="custom-control-input" (click)="agregarFactura(i, $event)"
                    id="customCheck{{i}}" [checked]="factura.checked">
                  <label class="custom-control-label" for="customCheck{{i}}"></label>
                  <br/>
                  
                  <div [formGroupName]="i" [class.hide]="!factura.checked" [class.show]="factura.checked">
                    <input type="hidden" formControlName="idFactura">

                    <input type="text" class="form-control" id="factura-{{i}}"
                    formControlName="efectivo"
                    (keypress)="this.onlyDecimalPositivo($event)"
                    (keyup)="calcularTotalFacturas()"/>
                  </div>
                  

                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div style="text-align: right;" [formGroup]="efectivoForm">
      <label style="margin-right: 5em;" type="text">
        <b>Total ingreso en efectivo: </b>
      </label>
      <input type="text" id="myfile" name="myfile" 
        formControlName="totalIngresadoEfectivo" readonly>
    </div>

    <div style="text-align: right;">
      <label style="margin-right: 5em;" type="text">
        <b>Total ingreso calculado por pad: </b>
      </label>
      <input type="text" [value]="this.totalEfectivoTeoricoFacturas | number: '0.2-2'" readonly>
    </div>

    <div style="text-align: right;">
      <label style="margin-right: 5em;" type="text">
        <b>Diferencia: </b>
      </label>
      <input type="text" [value]="this.diferenciaDeCaja | number: '0.2-2'" readonly>
    </div>
  </div>

  <div class="card-footer" style="text-align: right;">
    <button class="btn btn-primary" type="button" (click)="cerrarCaja()" [disabled]="guardando">
      <span *ngIf="!guardando">Cerrar caja</span>
      <div *ngIf="guardando" class="d-flex align-items-center">
        <div class="spinner-border text-light spinner-border-sm" role="status"></div>
        <span>Cerrando...</span>
      </div>
    </button>
  </div>
</div>