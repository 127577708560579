<div class="card add-margin">
  <div class="card-header">
    <div class="row">
      <div class="col-sm-10">
        <h4 class="card-title">Modificar pedido N°: {{CompletarPrefijo(pedidoDetalleModificado.prefijo)}}-{{CompletarNumero(pedidoDetalleModificado.numeroPedido)}}.</h4>
      </div>
      <!--
      <div *ngIf="this.pedidoDetalleModificado.tipoPedido==TipoPedido.Manual" class="col-sm-2">
        <button class="btn btn-secondary float-end" (click)="seleccionClienteMatricula()">Cambiar Cliente-Matricula</button>
      </div>
      -->
    </div>
  </div>
  <div class="card-body">
    <form [formGroup]="pedidosForm" (ngSubmit)="onSubmit()">
      <div class="row">
        <!-- cliente -->
        <div class="mb-3 col-md-6">
          <div class="mb-3 row">
            <label for="clienteRazonSocial" class="col-sm-4 col-form-label">*Cliente:</label>
            <div class="col-sm-8">
              <div class="input-group mb-2">
                <input id="clienteRazonSocial" type="text" class="form-control" formControlName="clienteRazonSocial"
                  [attr.disabled]="isDisabled ? true : null"
                  [inputFormatter]="formatter" [(ngModel)]="modelCliente" [resultFormatter]="formatter" title="Campo obligatorio, debe seleccionar o ingresar un cliente."
                  (change)="confirmCustomer()" [ngbTypeahead]="search" (selectItem)="selectCustomer($event)" [readOnly]="tieneAerovales()"/>
                  <ng-container *ngIf="!isDisabled">
                    <div class="input-group-append">
                      <button type="button" class="input-group-btn btn btn-secondary" (click)="nuevoCliente(modelCliente)" [disabled]="tieneAerovales()"
                        tabindex=-1><i class="fa fa-plus-square" aria-hidden="true"></i></button>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="isDisabled">
                    <div class="input-group-append">
                      <button type="button" class="input-group-btn btn btn-primary" 
                        (click)="seleccionClienteMatricula(TipoSeleccion.Clientes)"
                        tabindex=-1><i class="fa fa-pencil" aria-hidden="true"></i>
                      </button>
                    </div>
                  </ng-container>
              </div>
              <loading-text-spinner *ngIf="cargandoClientes" [error]="errorClientes" message="Obteniendo clientes..." color="primary">
              </loading-text-spinner>

              <div *ngIf="cliente.invalid && cliente.errors.required && (cliente.dirty || cliente.touched)"
                class="alert alert-danger">
                Requerido
              </div>
            </div>
          </div>
        </div>
        <!-- aeronave -->
        <div class="mb-3 col-md-6">
          <div class="mb-3 row">
            <label for="aeronaveMatricula" class="col-form-label col-sm-4 text-center"><span *ngIf="this.pedidoDetalleModificado.tipoPedido==TipoPedido.Manual">*</span>Aeronave:</label>
            <div class="col-sm-8">
              <div class="input-group mb-2">
                <ng-template #aeronaveRt let-r="result" let-t="term">
                  <ngb-highlight [result]="r.matricula" [term]="t"></ngb-highlight>
                </ng-template>

                <input id="aeronaveMatricula" type="text" class="form-control" formControlName="aeronaveMatricula" 
                  [(ngModel)]="modelAero" (keypress)="anular($event)"
                  [resultTemplate]="aeronaveRt" [inputFormatter]="formatterAeronave" maxlength="10" title="Campo obligatorio, debe seleccionar una aeronave."
                  [ngbTypeahead]="searchAeronave" (change)="changeAeronave()" (selectItem)="selectAeronave($event)" [readOnly]="tieneAerovales()"/>
                  <ng-container *ngIf="!isDisabled">
                    <div class="input-group-append">
                      <button type="button" class="input-group-btn btn btn-secondary" (click)="nuevoAeronave(modelAeronave)" [disabled]="tieneAerovales()"
                        tabindex=-1><i class="fa fa-plus-square" aria-hidden="true"></i></button>                        
                    </div>
                  </ng-container>
                  <ng-container *ngIf="isDisabled">
                    <div class="input-group-append">
                      <button type="button" class="input-group-btn btn btn-primary" 
                        (click)="seleccionClienteMatricula(TipoSeleccion.Matriculas)"
                        tabindex=-1><i class="fa fa-pencil" aria-hidden="true"></i>
                      </button>
                    </div>
                  </ng-container>
              </div>
              <loading-text-spinner *ngIf="cargandoAeronavesCliente" message="Obteniendo aeronaves del cliente..." color="primary" [error]="errorAeronavesCliente">
              </loading-text-spinner>
              <loading-text-spinner *ngIf="cargandoAeronaves" [error]="errorAeronaves" message="Obteniendo aeronaves..." color="primary">
              </loading-text-spinner>

              <div *ngIf="aeronave.invalid && aeronave.errors.required && (aeronave.dirty || aeronave.touched)"
                class="alert alert-danger">
                Requerido
              </div>
              <div *ngIf="aeronave.invalid && aeronave.errors.existeAeronave && (aeronave.dirty || aeronave.touched)"
                class="alert alert-danger">
                La aeronave no existe
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="cliente.value && cliente.value.esTrader" class="row">
        <div class="mb-3 col-md-12">
          <div class="border form-input">
            <div *ngIf="!cargandoAutorizacion; else tCargandoAutorizacion">
              <label *ngIf="autorizacionTrader == null"
                class="col-form-label col-sm-12 text-md-center text-sm-start">Seleccione una aeronave y/o aerocombustible
                para verificar su autorización</label>
              <label *ngIf="autorizacionTrader" class="col-form-label col-sm-12 text-md-center text-sm-start">Trader
                autorizado</label>
              <label *ngIf="autorizacionTrader == false"
                class="col-form-label col-sm-12 text-md-center text-sm-start text-danger">
                Trader no autorizado</label>
            </div>
            <ng-template #tCargandoAutorizacion>
              <div class="col-form-label">
                <loading-text-spinner message="Obteniendo autorización" content-align="center" color="primary"
                  textColor="dark">
                </loading-text-spinner>
              </div>
            </ng-template>
          </div>
        </div>
      </div>

      <div class="row">
        <!-- aerocombustible -->
        <div class="mb-3 col-md-6">
          <div class="mb-3 row">
            <label for="aerocombustible" class="col-sm-4 col-form-label">*Aerocombustible:</label>
            <div class="col-sm-8">
              <select id="aerocombustibleSelect" class="form-select" type="number" title="Campo obligatorio, debe seleccionar un aerocombustible."
                formControlName="aerocombustible" (change)="changeAerocombustible($event)" [disabled]="this.pedidoDetalleModificado.tipoPedido==TipoPedido.Tams">
                <option *ngFor="let aerocombustible of this.aerocombustibles" [ngValue]="aerocombustible"
                  [selected]="aerocombustible.codigoProducto == this.pedidoDetalleModificado.producto.codigoProducto">                
                  {{aerocombustible.nombreProducto}}
                </option>
              </select>
              <loading-text-spinner *ngIf="cargandoAerocombustibles" [error]="errorAerocombustibles" message="Obteniendo aerocombustibles"
                color="primary">
              </loading-text-spinner>
              <div class="alert alert-warning" *ngIf="this.combustibleNoCoincide">
                La abastecedora y el vehiculo tienen distinto producto.
              </div>
             </div>
          </div>
        </div>
        <!-- numero de vuelo -->
        <div class="mb-3 col-md-6">
          <div class="mb-3 row">
            <label for="vuelo" class="col-form-label col-sm-4 text-center">*Número de Vuelo:</label>
            <div class="col-sm-8">
              <input id="vuelo" type="text" class="form-control mb-2 me-sm-2" title="Campo obligatorio, debe ingresar un número de vuelo."
                formControlName="vuelo" placeholder="XY ####" ngbTooltip="Permite FFAA, SANI, PRIV, YPF, GOB." style="text-transform:uppercase">
              <div
                *ngIf="numeroVuelo.invalid && numeroVuelo.errors.required && (numeroVuelo.dirty || numeroVuelo.touched)"
                class="alert alert-danger">
                Requerido
              </div>
              <div
                *ngIf="numeroVuelo.invalid && numeroVuelo.errors.pattern && (numeroVuelo.dirty || numeroVuelo.touched)"
                class="alert alert-danger">
                El número de vuelo no tiene el formato correcto
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="mb-3 col-md-6">
          <div class="mb-3 row">
            <label class="col-form-label col-sm-4">Tipo de vuelo: </label>
            <section class="btn-group col-sm-8" (change)="changeTipoVuelo($event)">
              <input type="radio" class="btn-check" name="tipoVueloFormControl" id="tvc" formControlName="tipoVueloFormControl" [value]="1">
              <label class="btn btn-outline-primary" for="tvc">Cabotaje</label>
              <input type="radio" class="btn-check" name="tipoVueloFormControl" id="tvi" formControlName="tipoVueloFormControl" [value]="2">
              <label class="btn btn-outline-primary" for="tvi">Internacional</label>
            </section>
          </div>
        </div>

        <div class="mb-3 col-md-6">
          <div class="mb-3 row">
            <label class="col-form-label col-sm-4 text-md-center text-sm-start">Destino: </label>
            <div class="col-sm-8">
              <ng-template #aeropuertoRt let-r="result" let-t="term">
                <ngb-highlight [result]="r.codigoAeropuerto + ' ' + r.nombre" [term]="t"></ngb-highlight>
              </ng-template>
              <input id="destinoFormControl" type="text" class="form-control" formControlName="destinoFormControl"
                [resultTemplate]="aeropuertoRt" [inputFormatter]="formatterAeropuerto" [ngbTypeahead]="searchAeropuerto" [(ngModel)]="modelDestino"
                placeholder="Destino" (selectItem)="selectAeropuerto($event)">
              <loading-text-spinner *ngIf="cargandoAeropuertos" [error]="errorDestinos" message="Obteniendo destinos..." color="primary">
              </loading-text-spinner>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="mostrarRancho" class="row">
        <div class="mb-3 col-md-12">
          <div class="border">
            <div *ngIf="!cargandoRancho; else tCargandoRancho">
              <ng-container *ngIf="rancho; else noVigente">
                <label class="col-form-label col-sm-4 text-md-center text-sm-start">Rancho: <span
                    class="fw-bold">{{rancho.codigoRancho}}</span></label>
                <label class="col-form-label col-sm-4 text-md-center text-sm-start">Vencimiento: <span
                    [ngClass]="{'text-danger': rancho.vencido}">{{ rancho.fechaVencimiento | date: "dd'/'MM'/'y" }}</span>
                </label>
                <label class="col-form-label col-sm-4 text-md-center text-sm-start">Disponible:
                  {{rancho.volumenDisponible | number}}
                  LTS.</label>
              </ng-container>
              <ng-template #noVigente>
                <label class="col-form-label col-sm-12 text-md-center text-sm-start text-danger">
                  No se encontró un rancho vigente</label>
              </ng-template>
            </div>
            <ng-template #tCargandoRancho>
              <div class="col-form-label">
                <loading-text-spinner message="Obteniendo rancho" content-align="center" color="primary">
                </loading-text-spinner>
              </div>
            </ng-template>
          </div>
        </div>
      </div>

      <div class="row">
        <!-- cantidad estimada -->
        <div class="mb-3 col-md-6">
          <div class="mb-3 row">
            <label for="cantidadEstimada" class="col-form-label col-sm-4">*Cantidad Estimada:</label>
            <div class="col-sm-4">
              <div class="input-group mb-2">
                <input id="cantidadEstimada" type="number" class="form-control"
                  formControlName="cantidadEstimada" (keypress)="onlyNumberKey($event)">
                <span class="input-group-text">Lts.</span>
              </div>
              <div
                *ngIf="this.pedidoDetalleModificado.tipoPedido!=TipoPedido.Tams && cantidadEstimada.invalid && cantidadEstimada.errors.min && (cantidadEstimada.dirty || cantidadEstimada.touched)"
                class="alert alert-danger">La cantidad debe ser mayor a 0
              </div>
              <div
                *ngIf="this.pedidoDetalleModificado.tipoPedido==TipoPedido.Tams && cantidadEstimada.invalid && cantidadEstimada.errors.min && (cantidadEstimada.dirty || cantidadEstimada.touched)"
                class="alert alert-danger">La cantidad debe ser mayor/igual a 0
              </div>
              <div
                *ngIf="cantidadEstimada.invalid && cantidadEstimada.errors.required && (cantidadEstimada.dirty || cantidadEstimada.touched)"
                class="alert alert-danger">Requerido</div>
            </div>
            <div class="col-sm-4"></div>
          </div>
        </div>       
        <!-- posicion -->
        <div class="mb-3 col-md-6">
          <div class="mb-3 row">
            <label for="posicion" class="col-form-label col-sm-4 text-center">Posición:</label>
            <div class="col-sm-8">
              <input id="posicion" type="text" class="form-control" formControlName="posicion">
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <!-- fecha y hora de operacion -->
        <div class="mb-3 col-md-6">
          <div class="mb-3 row">
            <label for="fechaFormControl" class="col-sm-4 col-form-label" style="display: block;">
              *Fecha y hora estimada de llegada del avión:
              <br/>
              <span style="color: red; display: block; font-size: 14px;">Atención: No se podrán crear predespachos antes de esta hora.</span>
            </label>
            <div #fechaHora class="col-sm-5">
              <div class="input-group mb-2">
                <input type="date" class="form-control" formControlName="fechaFormControl" placeholder="aaaa-mm-dd" name="dp" title="Campo obligatorio."
                  [(ngModel)]="model" ngbDatepicker #d="ngbDatepicker" (ngModelChange)="validarFecha($event)" readonly>
                <div class="input-group-append">
                  <button type="button" class="input-group-btn btn btn-secondary" (click)="d.toggle()"><i
                      class="fa fa-calendar" aria-hidden="true"></i></button>
                </div>
              </div>
              <ngb-timepicker [(ngModel)]="time" [meridian]="true" formControlName="horaFormControl"
                (ngModelChange)="validarFecha($event)"></ngb-timepicker>
              <!-- <div *ngIf="(fecha.invalid && fecha.errors.fechaFutura && (fecha.dirty || fecha.touched))"
                class="alert alert-danger">La fecha y hora estimados deben ser posterior a la actual</div> -->
              <div *ngIf="(fecha.invalid && fecha.errors.fechaValida && (fecha.dirty || fecha.touched))"
                class="alert alert-danger">La fecha y hora no son válidas</div>
            </div>
          </div>
        </div>
        <!-- fecha y hora de vuelo -->
        <div class="mb-3 col-md-6">
          <div class="mb-3 row">
            <label for="fechaVueloFormControl" class="col-sm-4 col-form-label">*Fecha y hora del vuelo: </label>
            <div #fechaHoraVuelo class="col-sm-5">
              <div class="input-group mb-2">
                <input type="date" class="form-control" formControlName="fechaVueloFormControl" placeholder="aaaa-mm-dd" name="dp" title="Campo obligatorio."
                  [(ngModel)]="modelVuelo" ngbDatepicker #dv="ngbDatepicker" (ngModelChange)="validarFechaVuelo()" readonly>
                <div class="input-group-append">
                  <button type="button" class="input-group-btn btn btn-secondary" (click)="dv.toggle()"><i
                      class="fa fa-calendar" aria-hidden="true"></i></button>
                </div>
              </div>
              <ngb-timepicker [(ngModel)]="timeVuelo" [meridian]="true" formControlName="horaVueloFormControl"
                (ngModelChange)="validarFechaVuelo(null, $event)"></ngb-timepicker>
              <div *ngIf="(fechaVuelo.invalid && fechaVuelo.errors.fechaVuelo && (fechaVuelo.dirty || fechaVuelo.touched))"
                class="alert alert-danger">La fecha y hora del vuelo debe ser posterior a la estimada</div>
              <div *ngIf="(fechaVuelo.invalid && fechaVuelo.errors.fechaValida && (fechaVuelo.dirty || fechaVuelo.touched))"
                class="alert alert-danger">La fecha y hora no son válidas</div>
            </div>
          </div>
        </div>
      </div>

      <small class="text-danger">*Campos obligatorios.</small>
      <div *ngIf="this.error != null" class="text-danger">
        {{this.error}}
      </div>

      <div class="card-footer d-flex justify-content-center">
        <span class="border">
          <button class="btn btn-primary" [disabled]="pedidosForm.invalid || guardandoPedido || !noEstaCargandoInfo()" type="submit">
            <span *ngIf="!guardandoPedido">Guardar</span>
            <div *ngIf="guardandoPedido" class="d-flex align-items-center">
              <div class="spinner-border text-light spinner-border-sm" role="status"></div>
              <span>Guardando...</span>
            </div>
          </button>
        </span>
        <span class="border">
          <button type="button" class="btn btn-secondary" [disabled]="guardandoPedido"
            (click)="goBack()">Cancelar</button>
        </span>
      </div>
    </form>
  </div>
</div>
