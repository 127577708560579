<div class="card">
  <div class="card-header">
    <div class="row">
      <h5 class="col-11">Facturas afectadas por el cambio de precios</h5>
      <button type="button" class="btn-close" aria-label="Close" (click)="this.activeModal.close()">
        
      </button>
    </div>
  </div>
  <div class="card-body">
    <div class="col col-md-12">
      <div class="spinner-container" *ngIf="obteniendoFacturas">
        <ngx-spinner name="spinnerGrafico" bdOpacity=0.3bd
          Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
          <p style="color: white">C A R G A N D O . . .</p>
        </ngx-spinner>
      </div>
      <form *ngIf="!obteniendoFacturas">
        <div class="alert alert-warning" *ngIf="this.encabezadoFactura.length == 0">El cambio de precio no afecta
          ninguna factura en este momento.</div>
        <table class="table table-striped table-hover text-center" *ngIf="this.encabezadoFactura.length > 0">
          <thead>
            <th>Número</th>
            <th>Cliente</th>
            <th>Emisión</th>
            <th>Tipo movimiento</th>
            <th>Aeroplanta</th>
            <th>Ver</th>
          </thead>
          <tbody *ngFor="let fact of encabezadoFactura">
            <td>{{fact.prefijo | facturaPrefijo}}-{{fact.numeroComprobante | facturaNumero}}</td>
            <td>{{fact.razonSocialCliente}}</td>
            <td>{{fact.fechaEmision | date: 'dd/MM/yyyy'}}</td>
            <td>{{fact.tipoMovimiento | nombreTipoComprobante }} {{fact.tipoMovimiento | tipoMovimiento }}</td>
            <td>{{fact.codigoAeroplanta}}</td>
            <td *ngIf="!previsualizando"> <button (click)="this.verFactura(fact)" class="btn" placement="bottom"
                ngbTooltip="Ver Factura/Refactura" container="body">
                <i class="fa fa-eye" aria-hidden="true"></i>
              </button>
            </td>
            <td *ngIf="this.previsualizando" style="border-top: none;"
              [ngClass]="[fact.loadIcon ? 'fa fa-spinner fa-spin' : 'fa fa-eye']"></td>
          </tbody>
        </table>
      </form>
    </div>
  </div>
  <div class="card-footer text-center">
    <button class="btn btn-success me-2" [disabled]="this.obteniendoFacturas" (click)="exportarExcel()"><i class="fa fa-file-excel-o"></i>Excel</button>
    <button class="btn btn-primary" (click)="this.activeModal.close()">Cancel
    </button>
  </div>
</div>