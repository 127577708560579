import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { UsuarioService } from 'src/app/account/usuario.service';
import { Producto } from 'src/app/shared/models/despacho/Producto';
import { Cano } from 'src/app/shared/models/stock/Cano';
import { MessageService } from 'src/app/shell/message.service';
import { Canerias } from 'src/app/shared/models/stock/Canerias';
import { NuevaCaneriaComponent } from './nueva-caneria/nueva-caneria.component';
import { StockService } from '../../stock.service';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';
import { AuthService } from 'src/app/core/authentication/auth.service';

@Component({
  selector: 'app-canerias',
  templateUrl: './canerias.component.html',
  styleUrls: ['./canerias.component.css']
})
export class CaneriasComponent implements OnInit {

  TipoUsuario = TipoUsuario;
  
  canerias: Cano[] = [];
  tablaCanerias: UntypedFormGroup;
  obteniendoCanerias: boolean = false;
  messageCanerias: string = 'C A R G A N D O    C A Ñ E R I A S . . .';

  constructor(private authService: AuthService, 
    private messageService: MessageService,
    private activeModal: NgbModal,
    private usuarioService: UsuarioService,
    private fb: UntypedFormBuilder,
    private stockService: StockService,
    private spinner: NgxSpinnerService) {
    this.tablaCanerias = this.fb.group({
      caneria: new UntypedFormArray([])
    });
  }


  // hooks

  ngOnInit() {
    this.getCañerias();
  }

  // llamadas al service

  getCañerias() {
    this.spinner.show('spinnerCanerias');
    this.obteniendoCanerias = true;
    this.stockService.getCanerias(this.usuarioService.getAeroplantaActual().codigoAeroplanta)
      .subscribe(result => {
        this.canerias = result.sort((a, b) => { if (a.fechaModificacion < b.fechaModificacion) { return 1 } else { return -1 } });
        this.addCheckboxes();
        this.spinner.hide('spinnerCanerias');
        this.obteniendoCanerias = false;
      }, () => {
        this.obteniendoCanerias = false;
        this.spinner.hide('spinnerCanerias');
      })
  }


  // form

  private addCheckboxes() {
    // reinicio el formulario
    this.tablaCanerias.setControl('caneria', this.fb.array([]));
    this.canerias.map((o, i) => {
      const control = new UntypedFormControl(i === 0); // if first item set to true, else false
      control.setValue(o.activo);
      (this.tablaCanerias.controls.caneria as UntypedFormArray).push(control);
    });
  }

  cambiarEstadoCaneria(cano: Cano) {
    cano.activo ? cano.activo = false : cano.activo = true;
    cano.codigoAeroplanta = this.usuarioService.getAeroplantaActual().codigoAeroplanta;
    this.stockService.editarCaneria(cano)
      .subscribe(result => {
        if (result.activo == true) {
          this.messageService.showSuccessMessage('Se activo la cañeria correctamente.')
        } else {
          this.messageService.showErrorMessage('Se desactivo la cañeria correctamente.')
        }
      })
  }

  agregarCaneria() {
    let modalRef = this.activeModal.open(NuevaCaneriaComponent, { centered: true, backdrop: 'static' });
    modalRef.result.then(result => {
      if (result == true) {
        this.getCañerias();
      }
    }, () => {

    });

  }

  editarCaneria(can: Cano) {
    let modalRef = this.activeModal.open(NuevaCaneriaComponent, { centered: true, backdrop: 'static' });
    modalRef.componentInstance.caneria = can;
    modalRef.result.then(result => {
      if (result == true) {
        this.getCañerias();
      }
    }, () => {

    });
  }

  hasRole(...roles: TipoUsuario[]) {
    return this.authService.hasRole(...roles);
  }
}
