import { Component, ElementRef, Inject, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { PreciosService } from '../../precios.service';
import { read, utils, WorkBook, WorkSheet } from 'xlsx'
import { Aeroplanta } from 'src/app/shared/models/stock/Aeroplanta';
import { StockService } from 'src/app/stock/stock.service';
import { MessageService } from 'src/app/shell/message.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-modal-importar-byr',
  templateUrl: './modal-importar-byr.component.html',
  styleUrls: ['./modal-importar-byr.component.css']
})

export class ModalImportarByrComponent implements OnInit {
  
  @ViewChild('inputFile')
  inputFileForm: ElementRef;

  @Inject(LOCALE_ID) public locale: string;

  constructor(public modal: NgbActiveModal,
    private messageService: MessageService,
    private stockService: StockService,
    private fb: UntypedFormBuilder,
    private precioService: PreciosService) { }

  aeroplantasStock: Aeroplanta[] = [];

  positiveIntegerRegex = /^(0|[1-9]\d*)$/;
  decimalRegex = /^[0-9]+(.[0-9]{0,4})?$/;

  fileUpload = { status: '', message: '', filePath: '', length: 0 };
  userFileName: string;
  selectedFile: boolean = false;
  errorConexionBackend: string;
  guardandoExcel: boolean = false;

  errorDatosExcel: string;
  listaNombreColumnasValidas = ["Clientes", "Aeroplantas", "Productos", "FechaDesde", "FechaHasta", "Valor", "Tipo", "Motivo"] ;        
  readonly CAMPO_AEROPLANTA : number = 1;

  obteniendoAeroplantas: boolean = false;
  errorObtenerAeroplantas: string;
  cantidadFilasValidas: number;

  fileForm = new UntypedFormGroup({

  });

  // hooks

  ngOnInit() {
    this.fileForm = this.fb.group({
      name: [''],
      profile: [''],
      inputFile: ['', Validators.required]
    });

    this.obteniendoAeroplantas = true;
    this.stockService.obtenerAeroplantasStock().subscribe(result => {
      this.aeroplantasStock = result;

      this.obteniendoAeroplantas = false;
    },
      (err: ErrorModel) => {
        this.errorObtenerAeroplantas = err.message;
        this.obteniendoAeroplantas = false;
      }
    );
  }

  // form

  onSelectedFile(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.fileForm.get('profile').setValue(file);
      this.fileForm.get('name').setValue(file.name);
      this.userFileName = file.name;
      this.selectedFile = true;
    }
  }

  reset(limpiarMensajes: boolean = false, limpiarInput: boolean = false) {
    this.selectedFile = true;
    if (limpiarInput) {
      this.selectedFile = false;
      this.userFileName = '';
    }
    if (limpiarMensajes)
      this.fileUpload = { status: '', message: '', filePath: '', length: 0 };
  }

  descargarPlantilla() {
    window.open('/assets/excel-upload-byr/upload-byr.xlsx', '_blank');
  }

  // llamadas al service

  uploadFile() {

    this.errorConexionBackend = null;

    const formData = new FormData();
    formData.append('name', this.fileForm.get('name').value);
    formData.append('profile', this.fileForm.get('profile').value);
    this.guardandoExcel = true;

    this.precioService.uploadByr(formData)
      .subscribe(result => {
        if (!result) {
          return;
        }
        if (result) this.fileUpload = result;

        if (Array.isArray(result)) {
          
          this.guardandoExcel = false;

          if( ! this.hayErroresBackend(result) ){
            this.messageService.showSuccessMessage("Se proceso el excel correctamente.");            
            this.modal.close() ;
          }
        }
      },
        (err: ErrorModel) => {
          this.errorConexionBackend = (err.description ? err.description : "Error al subir el archivo.");
          this.guardandoExcel = false;
        }
      );
  }

  hayErroresBackend(result: any[]){
    if(result[0].code == "200"){
      return false;
    }else{
      return true;
    }    
  }

  mostrarErroresBackend(result: any[]){
    var errores = "";
    for(var i=0 ; i < result.length; i++){
      var error = result[i].description;
      errores += error + " | " ;
    }

    this.errorDatosExcel = errores;
  }

  calcularCantidadFilasValidas(rows: any[][]){
    // Busca la primera fila con todas las columnas vacias.
    for (var indexRow = 0; indexRow < rows.length; indexRow++) {   
      for(var indexCol = 0; indexCol < this.getCantidadColumnasValidas(); indexCol++){
        if(rows[indexRow][indexCol] != null){
          break;
        }
      }

      if(indexCol == this.getCantidadColumnasValidas()){
        return indexRow;
      }
    }

    return indexRow;
  }

  procesarExcel(){
    const archivo = this.fileForm.get('profile').value;

    if (archivo) {
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        /* read workbook */
        const bstr: string = e.target.result;
        const wb: WorkBook = read(bstr, { type: 'binary' });

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: WorkSheet = wb.Sheets[wsname];

        /* save data */
        let rows: any[][] = utils.sheet_to_json(ws, { header: 1 });
        
        this.cantidadFilasValidas = this.calcularCantidadFilasValidas(rows);
        
        if (this.cantidadFilasValidas == 0 || this.cantidadFilasValidas == 1) {
          this.errorDatosExcel = "El archivo no posee ByR para cargar.";
          this.reset();
          return ;
        } else {         
          if( ! this.validarColumnas(rows[0]) ){ 
            return ;
          }

          rows = this.validarFilasIncompletas(rows);

          if(rows == null){
            return ;
          }

          if( ! this.validarFechasLegibles(rows)){
           return ;
          }

          // Puedo validar las aeroplantas con nombre, pero no puedo modificar el excel que se sube.
          //var registros : any[][] = [];
          
          for (var i = 1; i < this.cantidadFilasValidas; i++) {  
            var registro: any[];

            registro = this.convertirValoresEnStringValidandoAeroplantas(rows[i], i);

            if(registro != null){
              //registros.push(registro);
            }else{
              return ;
            }            
          }
          //El codigo de productos pueden tener letras Eche/AOK
          //if( ! this.validarCodigosProductosNumericos(rows)){
          //  return ;
          // }

          if( ! this.validarValorByR(rows)){
            return ;
           }

           if( ! this.validarTipoByR(rows)){
            return ;
           }

          this.uploadFile();
        }
      }

      reader.readAsBinaryString(this.fileForm.get('profile').value);
    }else{
      this.errorDatosExcel = "No se pudo leer el archivo excel cargado.";
      return ;
    }
  }

  validarColumnas(row: any[]){
    // Valido todo el excel ya que cualquier nivel sube el mismo formato de exce    
      
    var cantColumnasValidad = this.getCantidadColumnasValidas();

    if(cantColumnasValidad != this.listaNombreColumnasValidas.length){
      this.errorDatosExcel = "Error en encabezado de columna del excel. <br/>Se esperaban " + this.listaNombreColumnasValidas.length + " columnas pero el excel tiene " + cantColumnasValidad + " columnas" ;
      return false;      
    }

    for(var i = 0; i < cantColumnasValidad; i++){
      if(row[i] != this.listaNombreColumnasValidas[i]){
        this.errorDatosExcel = "Error en encabezado de columna del excel. Se esperaba '" + this.listaNombreColumnasValidas[i] + "' pero se encontro '" + row[i] +"' en la columna " + i ;
        return false;
      }
    }

    return true;
  }

  getCantidadColumnasValidas(){
    return this.listaNombreColumnasValidas.length;
  }

  validarFilasIncompletas(rows: any[][]){

    var filasIncompletas: any[][] = [];
    var filasCompletas: any[][] = [];

    filasCompletas.push(rows[0]);

    for (var indexRow = 1; indexRow < this.cantidadFilasValidas; indexRow++) {   

      var columnasVacias = 0;

      for(var indexCol = 0 ; indexCol < this.getCantidadColumnasValidas(); indexCol++){

        if(rows[indexRow][indexCol] == null || rows[indexRow][indexCol].toString().trim() == ""){
          columnasVacias++;
          break;
        }  
      }

      if(columnasVacias == this.getCantidadColumnasValidas()){
        // Fin de lectura de datos.
        break;
      }else if (columnasVacias == 0){
        filasCompletas.push(rows[indexRow]);
      }else{
        filasIncompletas.push(rows[indexRow]);
      }
    }

    if(filasIncompletas.length > 0){
      this.errorDatosExcel = "El excel posee lineas incompletas." ;
      return null;
    }

    return filasCompletas;
  }

  //No se utiliza porque lso productos pueden tener letras Eche/AOK
  validarCodigosProductosNumericos(rows: any[][]){
    var columnaCodigoProducto = 2;

    var stringSalida = "";

    for (var indexRow = 1; indexRow < this.cantidadFilasValidas; indexRow++) {  
      var stringCodigos =  rows[indexRow][columnaCodigoProducto];
      var camposCodigos: any[] = stringCodigos.toString().split(";");

      for(var i=0 ; i < camposCodigos.length; i++){
        if (!this.positiveIntegerRegex.test(camposCodigos[i].toString().trim())) {
          this.errorDatosExcel = "Hay un error en el campo producto, fila: " + (indexRow+1) + ". El codigo producto debe ser numerico. Se encontro: " + camposCodigos[i];
          return false;
        }
      }      
    }

    return true;
  }

  validarValorByR(rows: any[][]){
    var columnaValor = 5;

    for (var indexRow = 1; indexRow < this.cantidadFilasValidas; indexRow++) {  
      var valor = rows[indexRow][columnaValor];

      if (!this.decimalRegex.test(valor)) {
        this.errorDatosExcel = "Hay un error en el campo valor, fila: " + (indexRow+1) + ". El valor debe ser decimal positivo. Se encontro: " + valor;
        return false;
      }

      if(Number(valor) <= 0 || Number(valor) > 100){
        this.errorDatosExcel = "Hay un error en el campo valor, fila: " + (indexRow+1) + ". El valor debe ser mayor a cero y menor o igual a cien. Se encontro: " + valor;
        return false;
      }
    }

    return true;
  }

  validarTipoByR(rows: any[][]){
    var columnaTipoByR = 6;

    for (var indexRow = 1; indexRow < this.cantidadFilasValidas; indexRow++) {  
      var tipoByR = rows[indexRow][columnaTipoByR];

      if (tipoByR != "Recargo" && tipoByR != "Bonificacion") {
        this.errorDatosExcel = "Hay un error en el campo tipo, fila: " + (indexRow+1) + ". El valor debe ser 'Bonificacion' o 'Recargo' textual. Se encontro: " + tipoByR;
        return false;
      }
    }

    return true;
  }

  validarFechasLegibles(rows: any[][]){
    var columnaFechadesde = 3;

    for (var indexRow = 1; indexRow < this.cantidadFilasValidas; indexRow++) {  
      var fechadesde = rows[indexRow][columnaFechadesde];
      var fechaHasta = rows[indexRow][columnaFechadesde + 1];
      var fechaHoraRegex = /^\d{2}\/\d{2}\/\d{4} +\d{2}:\d{2}:\d{2} *(am|pm)?$/;

      if(fechadesde != null){
        if( ! fechaHoraRegex.test(fechadesde) ){
          this.errorDatosExcel = "Hay un error en el formato de fecha desde, fila: " + (indexRow+1) + ". El formato esperado es dd/mm/yyyy hh:mm:ss [am/pm opcional] como por ejemplo 18/06/2021 05:45 am y se encontro " + fechadesde +"." ;
          return false;      
        }
      }

      if(fechaHasta != null){
        if( ! fechaHoraRegex.test(fechaHasta) ){
          this.errorDatosExcel = "Hay un error en el formato de fecha hasta, fila: " + (indexRow+1) + ". El formato esperado es dd/mm/yyyy hh:mm:ss [am/pm opcional] como por ejemplo 18/06/2021 05:45 am y se encontro " + fechaHasta +"." ;
          return false;      
        }
      }

      if( ! this.validarComposicionFecha(fechadesde, "desde", indexRow) ){
        return false;
      }
      
      if( ! this.validarComposicionFecha(fechaHasta, "hasta", indexRow) ){
        return false;
      }

      if( ! this.fechaDesdeMenorFechaHasta(fechadesde.toString(), fechaHasta.toString(), indexRow)){
        return false;
      }
    }

    return true;
  }

  fechaDesdeMenorFechaHasta(desde: string, hasta: string, indexRow){
    var fDesde = this.convertirEnDate(desde);
    var fHasta = this.convertirEnDate(hasta);

    if(fDesde < fHasta){
      return true;
    }else{
      this.errorDatosExcel = "Hay un error en las fechas, fila: " + (indexRow+1) + ". La fecha desde: " + desde + " debe ser menor a la fecha hasta: " + hasta ;
      return false;
    }
  }

  convertirEnDate(fecha: string){
    var fechaArray: any[] = fecha.split(/[\/\s\:]/g);

    var fechaDate = new Date(fechaArray[2], fechaArray[1]-1, fechaArray[0]);

    if( fechaArray.length >= 7 && fechaArray[6] == "pm" && Number(fechaArray[3]) < 12 ){
      fechaDate.setHours(Number(fechaArray[3]) + 12, fechaArray[4], fechaArray[5]);
    }else if( fechaArray.length >= 7 && fechaArray[6] == "pm" && Number(fechaArray[3]) == 12 ){
      fechaDate.setHours(fechaArray[3], fechaArray[4], fechaArray[5]);
    }else if( fechaArray.length >= 7 && fechaArray[6] == "am" && Number(fechaArray[3]) == 12 ){
      fechaDate.setHours(0, fechaArray[4], fechaArray[5]);
    }else{
      fechaDate.setHours(Number(fechaArray[3]), fechaArray[4], fechaArray[5]);
    }

    return fechaDate;
  }

  validarComposicionFecha(fecha: String, tipoFecha: String, indexRow: number){
    var day = fecha.substring(0, 2);
    var month = fecha.substring(3, 5);
    var year = fecha.substring(6, 10);

    if(Number(year) < 2021 || Number(year) > 2100){
      this.errorDatosExcel = "Hay un error en el formato de fecha " + tipoFecha + ", fila: " + (indexRow+1) + ". Revisar el año, se encontro: " + year +"." ;
      return false;
    }

    if(Number(month) < 1 || Number(month) > 12){
      this.errorDatosExcel = "Hay un error en el formato de fecha " + tipoFecha + ", fila: " + (indexRow+1) + ". Revisar el mes, se encontro: " + month +"." ;
      return false;
    }

    if(Number(day) < 1){
      this.errorDatosExcel = "Hay un error en el formato de fecha " + tipoFecha + ", fila: " + (indexRow+1) + ". Revisar el día, se encontro: " + day +"." ;
      return false;
    }

    switch(Number(month)){
      case 1: case 3: case 5: case 7: case 8: case 10: case 12:
        if(Number(day) > 31){
          this.errorDatosExcel = "Hay un error en el formato de fecha " + tipoFecha + ", fila: " + (indexRow+1) + ". El mes tiene 31 días, revisar el dia que se encontro: " + day +"." ;
          return false;
        }
        break;
      case 4: case 6: case 9: case 11:
        if(Number(day) > 30){
          this.errorDatosExcel = "Hay un error en el formato de fecha " + tipoFecha + ", fila: " + (indexRow+1) + ". El mes tiene 30 días, revisar el dia que se encontro: " + day +"." ;
          return false;
        }
        break;
      case 2:
        if(Number(day) > 29){
          this.errorDatosExcel = "Hay un error en el formato de fecha " + tipoFecha + ", fila: " + (indexRow+1) + ". El mes puede tener hasta 29 días, revisar el dia que se encontro: " + day +"." ;
          return false;
        }
        break;
    }

    return true;
  }

  convertirValoresEnStringValidandoAeroplantas(fila: any[], indexRow){
    var registro = fila.map(e => e.toString());

    return this.convertirAeroplantaCodigoEnNumero(registro, indexRow);
  }

  convertirAeroplantaCodigoEnNumero(registro: any[], indexRow){

    var stringAeroplantas = registro[this.CAMPO_AEROPLANTA];
    var camposAeroplantas: any[] = stringAeroplantas.toString().split(";");

    var arraySalida = [];

    for(var i=0 ; i < camposAeroplantas.length; i++){
      var codigo = camposAeroplantas[i].toString().trim();
      if( isNaN(codigo) ){
        // aeroplanta es un codigo texto
        var aeroplanta = this.aeroplantasStock.filter(a => a.codigoAeroplanta == codigo)[0];
  
        if(aeroplanta != null){
          arraySalida.push(aeroplanta.numeroAeroplanta);
        }else{
          if (codigo == "TODOS") // DEBE ACEPTAR LA PALABRA TODOS Eche/AOK
          {
            arraySalida.push(codigo);
          }else{
            this.errorDatosExcel = "Hay un error en una aeroplanta cargada, fila: " + (indexRow+1) + ".No se encontro la aeroplanta con codigo " + codigo +"." ;
          return null;
          }
        }
      }else{
        arraySalida.push(camposAeroplantas[i]);
      }
    }

    registro[this.CAMPO_AEROPLANTA] = arraySalida.join(";");  
    
    return registro;
  }

  resetearArchivo(){
    this.inputFileForm.nativeElement.value = "";
    this.userFileName = '';
    this.errorDatosExcel = null;
    this.fileUpload = { status: '', message: '', filePath: '', length: 0 };
  }
}
