import { Component, OnInit } from '@angular/core';
import { ParametrosImpositivos } from 'src/app/shared/models/facturacion/ParametrosImpositivos';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ModalProvinciaComponentV2 } from './modal-provincia/modal-provincia-v2.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ParametrosImpositivosService } from '../parametros-impositivos.service';

@Component({
  selector: 'app-parametros-impositivos-index',
  templateUrl: 'parametros-impositivos-index.component.html',
  styleUrls: ['./parametros-impositivos-index.component.css']
})
export class ParametrosImpositivosIndexComponent implements OnInit {

  obteniendoParametros: boolean;
  parametroSeleccionado: ParametrosImpositivos;

  textoBusquedaProvincia:string;

  busquedaForm = new UntypedFormGroup({
    busquedaFormControl: new UntypedFormControl('')
  })
  
  constructor(
    private modalService: NgbModal,
    private parametrosService: ParametrosImpositivosService
  ) { }

  ngOnInit() {

  }

  onAgregarProvincia() {
    this.modalService.open(ModalProvinciaComponentV2, { centered: true, backdrop: 'static' })
      .result.then((provincia) => {
        this.parametrosService.crearParametroImpositivoEncabezado(provincia);
      }).catch(() => { });
  }

  filtrarProvincias(){
    let textoBusqueda :string = this.busquedaForm.controls.busquedaFormControl.value;
    this.textoBusquedaProvincia = textoBusqueda;
  }
}
