import { Pico } from '../despacho/Pico';
import { Producto } from './Producto';

export class Contenedor  {
    id: string;
    codigo: string;
    nombre: string;
    capacidad: number;
    productoActual: Producto;
    estadoContenedor: number;
    ultimoCambioEstado: Date;
    aforadorActual: number;
    tipoContenedor: number;
    picos: Pico[];
    aeroplantaCodigo: string;
    volumenInextraible: number;
    numCertificado: string;
    activo: boolean;
    porVacio: boolean;
    stockActual: number;
    stockActual15: number;
  }
