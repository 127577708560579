import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, FormGroup, Validators } from '@angular/forms';
import { ProductoService } from 'src/app/abm/producto/producto.service';
import { UsuarioService } from 'src/app/account/usuario.service';
import { FacturacionService } from 'src/app/facturacion/facturacion.service';
import { Producto } from 'src/app/shared/models/abm/Producto';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { MessageService } from 'src/app/shell/message.service';
@Component({
  selector: 'app-simulador-precio',
  templateUrl: './simulador-precio.component.html',
  styleUrls: ['./simulador-precio.component.scss']
})
export class SimuladorPrecioComponent implements OnInit {

  productoFormControl= new UntypedFormControl(null, [Validators.required]);  

  obteniendoPrecio: boolean = false;
  obteniendoProductos: boolean = false;
  productos:Producto[]=[];
  precio: string = '0';

  constructor(  
    private messageService:MessageService,
    private productoService: ProductoService,
    private facturacionService: FacturacionService,
    private usuarioService:UsuarioService  
  ) { }

  ngOnInit(): void {
    this.obteniendoProductos = true;
    this.productoService.obtenerProductos()
      .subscribe(r=>{
        this.productos = r.filter( r=> r.rubro.aerocombustible==true || r.rubro.combustible==true || r.rubro.aeroespecialidad==true);
        this.productos = r.filter( r=> r.activo==true);
        this.productos = this.productos.sort( (a,b) => a.nombreProducto > b.nombreProducto ? 1 : -1);
        this.obteniendoProductos = false;
      },()=>{
        this.messageService.showErrorMessage("Hubo un error al obtener los productos.");
        this.obteniendoProductos = false;
      });
  }

  async consultarPrecio() {
    
    try {
      this.obteniendoPrecio = true;
      this.precio = await this.facturacionService.simuladorPrecio(this.getCodigoAeroplanta(), null, this.producto.value);  
    } catch (err) {
      this.messageService.showErrorMessage("No se encontraron precios para los datos solicitados.");
      this.precio = '0';
      this.obteniendoPrecio = false;
    } finally {
      this.obteniendoPrecio = false;
    }    

  }

  getCodigoAeroplanta(){
    return this.usuarioService.getAeroplantaActual().codigoAeroplanta;
  }

  get producto() {
    return this.productoFormControl;
  }
}
