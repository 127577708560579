<div class="card" *ngIf="this.aeroplantaSeleccionada != null" [formGroup]="caiFormGroup">
  <div class="card-header">
    <h5>Aeroplanta: {{this.aeroplantaSeleccionada.nombre}}</h5>
  </div>
  <div class="card-body">
    <!--PREFIJO REMITO VENTA-->
    <div class="col-12 mt-2">
      <div class="row">
        <div class="col-4">
          <label>Prefijo remito venta:</label>
        </div>
        <div class="col-4">
          <input class="form-control" formControlName="prefijoVentaFormControl">
        </div>
        <div class="col-4"></div>
      </div>
    </div>
    <!--PREFIJO REMITO SALIDA-->
    <div class="col-12 mt-2">
      <div class="row">
        <div class="col-4">
          <label>Prefijo remito salida:</label>
        </div>
        <div class="col-4">
          <input class="form-control" formControlName="prefijoSalidaFormControl">
        </div>
        <div class="col-4"></div>
      </div>
    </div>
    <!--NUMERO CAI-->
    <div class="col-12 mt-2">
      <div class="row">
        <div class="col-4">
          <label>Numero de CAI:</label>
        </div>
        <div class="col-4">
          <input class="form-control" formControlName="numeroFormControl">
        </div>
        <div class="col-4"></div>
      </div>
    </div>
    <!--FECHA CAI-->
    <div class="col-12 mt-2 mb-2">
      <div class="row">
        <div class="col-4">
          <label>Fecha vencimiento:</label>
        </div>
        <div class="col-4">
          <div class="input-group">

            <input type="date" [(ngModel)]="modelFechaInicio" class="fechaFormControlDesdeHasta"
              placeholder="aaaa-mm-dd" formControlName="fechaFormControl" (ngModelChange)="model = $event" name="dt"
              ngbDatepicker value="model" class="form-control form-control-rounded"
              #d="ngbDatepicker" #searchDate required readonly>

            <div class="input-group-append">
              <button id="botonDesde" type="button" class="input-group-btn btn btn-secondary" (click)="d.toggle()">
                <i class="fa fa-calendar" aria-hidden="true"></i></button>
            </div>
          </div>
        </div>
        <div class="col-4"></div>
      </div>
    </div>
  </div>
  <div class="card-footer" *ngIf="this.hasRole(TipoUsuario.IMPUESTOS)">
    <button class="btn btn-primary float-end" (click)="guardarDatos()" [disabled]="guardando">
      <span *ngIf="!guardando">Guardar</span>
      <div *ngIf="guardando" class="d-flex align-items-center">
        <div class="spinner-border text-light spinner-border-sm" role="status"></div>
        <span>Guardando...</span>
      </div>
    </button>
  </div>
</div>