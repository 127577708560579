import { TipoTarjeta } from './TipoTarjeta';

export class CuponPagoTarjeta {
  tarjetaId:string;
  tarjeta: TipoTarjeta;
  numeroTarjeta: number;
  numeroLote: number;
  numeroCupon: number;
  importe: number;
  moneda: string;
  pathCuponFisico: string;
  
  _file: File;
}
