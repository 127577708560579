import { Component, OnInit, Input, LOCALE_ID, Inject, ViewChild, ElementRef } from '@angular/core';
import { NgbActiveModal, NgbDateStruct, NgbDatepickerConfig, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap'
import { UntypedFormGroup, UntypedFormControl, AbstractControl } from '@angular/forms';
import { Validators } from '@angular/forms';
import { PedidoService } from '../pedido.service';
import { Abastecedora } from '../../shared/models/despacho/Abastecedora';
import { Precarga } from '../../shared/models/despacho/Precarga';
import { PedidoDetalle } from '../../shared/models/despacho/PedidoDetalle';
import { formatDate } from '@angular/common';
import { Guid } from 'guid-typescript';
import { Rancho } from 'src/app/shared/models/ranchos/Rancho';
import { UsuarioService } from 'src/app/account/usuario.service';
import { TipoPedido } from 'src/app/shared/enums/TipoPedido';

@Component({
  selector: 'app-nueva-precarga',
  templateUrl: './nueva-precarga.component.html',
  styleUrls: ['./nueva-precarga.component.css']
})

export class NuevaPrecargaComponent implements OnInit {

  @ViewChild('fechaHora', {static: true}) fechaHoraElement: ElementRef;
  @Input() public pedido: PedidoDetalle;

  @Input() public model: NgbDateStruct;
  date: {
    year: number,
    month: number,
    day: number
  };
  @Input() time = {
    hour: 0,
    minute: 0,
    second: 0
  };

  @Input() rancho: Rancho;
  @Input() cantidadMaxima: number;
  precargaForm = new UntypedFormGroup({
    fechaFormControl: new UntypedFormControl(null, Validators.required),
    horaFormControl: new UntypedFormControl(null, Validators.required),
    abastecedoraFormControl: new UntypedFormControl({ value: null, disabled: true }, Validators.required),
    cantidadEstimadaFormControl: new UntypedFormControl(null)
  });

  abastecedoras: Abastecedora[];
  cargandoAbastecedoras: boolean = true;
  guardandoPrecarga: boolean = false;
  codigoAeroplanta: string;
  TipoPedido = TipoPedido;
  
  constructor(@Inject(LOCALE_ID) public locale: string,
    private datepickerConfig: NgbDatepickerConfig,
    public activeModal: NgbActiveModal,
    private pedidoService: PedidoService,
    private usuarioService: UsuarioService) { }

  // hooks

  ngOnInit() {
    if(this.pedido.tipoPedido==TipoPedido.Tams) {
      this.precargaForm.controls.cantidadEstimadaFormControl.setValidators([Validators.required, Validators.min(0)]);
      this.cantidadEstimada.setValue(0);
    } else {
      this.precargaForm.controls.cantidadEstimadaFormControl.setValidators([Validators.required, Validators.min(1)])
    }
    this.codigoAeroplanta=this.usuarioService.getAeroplantaActual().codigoAeroplanta;
    this.getPedido(this.pedido.id);
    //this.cantidadEstimada.setValidators([Validators.required, Validators.min(1)]);
    let actual = new Date();
    let fechaPedido = new Date(this.pedido.fechaHoraEstimada);
    this.model = { year: fechaPedido.getFullYear(), month: fechaPedido.getMonth() + 1, day: fechaPedido.getDate() };
    let horaPedido = this.getFechaPedido();
    this.time.hour = horaPedido.getHours();
    this.time.minute = horaPedido.getMinutes();
    if (actual > fechaPedido) {
      let sumaCincoMinutos = new Date(actual.getTime() + 5*60000) //suma 5 minutos respetando la cantidad de minutos por hora
      this.model = { year: sumaCincoMinutos.getFullYear(), month: sumaCincoMinutos.getMonth() + 1, day: sumaCincoMinutos.getDate() };
      this.time.hour = sumaCincoMinutos.getHours();
      this.time.minute = sumaCincoMinutos.getMinutes();
      
    }
    this.getAbastecedoras();
    this.datepickerConfig.minDate = this.dateToDateTimeStruct(actual > fechaPedido ? actual : fechaPedido); 
    let fin: any = { day: this.model.day, month: this.model.month, year: this.model.year+1 };   
    this.datepickerConfig.maxDate = fin;
  }

  // llamadas al service


  getAbastecedoras(): void {
    this.pedidoService.getAbastecedoras(this.codigoAeroplanta)
      .subscribe(abastecedoras => {
        this.abastecedoras = abastecedoras.filter(a => a.activo ===true && a.producto.codigoProducto === this.pedido.codigoAerocombustible);
        this.cargandoAbastecedoras = false;
        this.abastecedora.enable();
      });
  }

  agregarPrecarga() {
    if (this.precargaForm.valid) {
      let precarga:Precarga=new Precarga();
      precarga.fechaHora=  this.formatDate(this.fecha.value.year, this.fecha.value.month, this.fecha.value.day,
        this.hora.value.hour, this.hora.value.minute);
      if (this.abastecedora.value) {
        precarga.codigoAbastecedora = this.abastecedora.value;
        precarga.abastecedoraNombre = this.getName(this.abastecedora.value);
      }
      precarga.cargaEstimada = +this.cantidadEstimada.value;
      precarga.aerovaleId = Guid.EMPTY;
      this.guardandoPrecarga = true;
      this.pedidoService.AgregarPrecarga(this.pedido.id, precarga)
        .subscribe(idPrecarga => {
          if (idPrecarga != "") {
            precarga.id = idPrecarga;
            this.activeModal.close(precarga);
          } else { this.guardandoPrecarga = false; }
        }, (error) => {
          this.guardandoPrecarga = false;
        });
    }
  }

  //validadores

  validarFecha(timeStruct: NgbTimeStruct) {
    const dateActual = new Date();
    let fechaPrecarga = this.dateFromDateTimeStruct(this.model, this.time);
    let fped = this.pedido.fechaHoraEstimada;
    let fechaPedido = new Date(fped);
    this.fecha.updateValueAndValidity();
    if (fechaPrecarga <= fechaPedido || fechaPrecarga <= dateActual) {
      this.addValidationError(this.fecha, 'fechaSeleccionadaFutura');
    }
    else {
      this.fecha.updateValueAndValidity();
    }

  }

  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : (event.charCode >= 48 && event.charCode <= 57);
  }

  /**
   * Agrega un error de validación en el AbstractControl y lo marca como Dirty
   */
  addValidationError(control: AbstractControl, validationName: string) {
    let errors = control.errors;
    if (errors == null)
      errors = { [validationName]: true };
    else
      errors[validationName] = true;
    control.setErrors(errors);
    control.markAsDirty();
  }

  // Conversores

  /**
* Convierte una NgbDateStruct y una NgbTimeStruct en un Date
*/
  dateFromDateTimeStruct(date: NgbDateStruct, hour: NgbTimeStruct): Date {
    if (date == null || hour == null)
      return null;
    let dateReturn = new Date();
    dateReturn.setDate(date.day);
    dateReturn.setMonth(date.month - 1);
    dateReturn.setFullYear(date.year);
    dateReturn.setHours(hour.hour);
    dateReturn.setMinutes(hour.minute);
    return dateReturn;
  }

  formatDate(year: number, month: number, day: number, hour: number, minute: number): string {
    return formatDate(new Date(year, month - 1, day, hour, minute, 0), 'y-MM-ddTHH:mm:ss', this.locale);
  }

  dateToDateTimeStruct(dateParam: Date): NgbDateStruct {
    return { day: dateParam.getDate(), month: dateParam.getMonth() + 1, year: dateParam.getFullYear() };
  }

  vencida(): boolean {
    return new Date(this.rancho.fechaVencimiento) < new Date();
  }

  //otros metodos

  getName(codigoAbastecedora): string {
    var nombre = '';
    this.abastecedoras.forEach(element => {
      if (codigoAbastecedora == element.codigo)
        nombre = element.nombre;
    });
    return nombre;
  }

  getFechaPedido() {
    let fped = this.pedido.fechaHoraEstimada;
    let fechaPedido = new Date(fped);
    return fechaPedido;
  }

  getPedido(pedidoId: string) {
    this.pedidoService.getPedidoPorId(pedidoId)
     .subscribe(result => {
       this.pedido = result;
     });
 }

   //gets para controles de formulario

   get fecha() {
    return this.precargaForm.get('fechaFormControl');
  }

   get hora() {
    return this.precargaForm.get('horaFormControl');
  }

  get abastecedora() {
    return this.precargaForm.get('abastecedoraFormControl');
  }

  get cantidadEstimada() {
    return this.precargaForm.get('cantidadEstimadaFormControl');
  }


}
