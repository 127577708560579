import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';
@Component({
  selector: 'app-notificaciones-index',
  templateUrl: './notificaciones-index.component.html',
  styleUrls: ['./notificaciones-index.component.css']
})
export class NotificacionesIndexComponent implements OnInit {
  TipoUsuario = TipoUsuario;

  ngOnInit(){
    //this.router.navigate(['/abm/notificaciones/Cliente']);
    this.router.navigate(['/abm/notificaciones/Sistema']);
  }
constructor(private router:Router, public authService: AuthService){

}

}
