import { Injectable } from "@angular/core";
import { TipoDocumento } from "../enums/tipoDocumento";
import { TipoEsBonificacionRecargo } from "../enums/TipoEsBonificacionRecargo";
import { TipoMoneda } from "../enums/tipoMoneda";
import { TipoVenta } from "../enums/tipoVenta";

@Injectable({
  providedIn: 'root'
})
export class UtilPrecio {

  obtenerDescripcionTipoDocumento(tipoDoc) {
    if (tipoDoc == TipoDocumento.DNI) {
      return "DNI:";
    } else if (tipoDoc == TipoDocumento.CUIL) {
      return "CUIL:";
    } else if (tipoDoc == TipoDocumento.CUIT) {
      return "CUIT:";
    } else if (tipoDoc == TipoDocumento.Extranjero) {
      return "EXTRANJERO:";
    } else {
      return "-";
    }
  }

  obtenerDescripcionMoneda(tipoMoneda) {
    if (tipoMoneda == TipoMoneda.PES) {
      return "Pesos $";
    } else if (tipoMoneda == TipoMoneda.USD) {
      return "Dolares USD";
    } else {
      return "-";
    }
  }

  obtenerDescripcionTipoVenta(tipoVenta){
    if(tipoVenta == TipoVenta.cabotaje){
      return "Cabotaje";
    }else if(tipoVenta == TipoVenta.internacional){
      return "Internacional";
    }else{
      return "-";
    }
  }

  obtenerDescripcionBonificacionRecrgo(bonifRecargo) {
    if (bonifRecargo == TipoEsBonificacionRecargo.Bonificacion) {
      return "Bonif.";
    } else if (bonifRecargo == TipoEsBonificacionRecargo.Recargo) {
      return "Recargo";
    } else {
      return "-";
    }
  }

}

