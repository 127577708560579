import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpErrorHandler, HandleError } from '../http-error-handler.service';
import { UrlApi } from '../shared/enums/urlApi';

@Injectable({
  providedIn: 'root'
})
export class UploadFileService {

  // comentario
  
  baseUrl = environment.apiServer.baseUrl;
  ranchosBaseUrl = environment.apiServer.ranchosBaseUrl;
  stockBaseUrl = environment.apiServer.stockBaseUrl;
  clientesBaseUrl = environment.apiServer.clientesBaseUrl;

  private handleError: HandleError;

  constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError('Importador');
  }

  getapiUrl(resource: UrlApi) {
    switch (resource) {
      case UrlApi.usuarios:
        return this.baseUrl + '/UsuariosV1/Masivos';
      case UrlApi.clientes:
        return this.clientesBaseUrl + '/ClientesV1/Masivos';
      case UrlApi.aeronaves:
        return this.baseUrl + '/VehiculosV1/Masivos';
      case UrlApi.ranchos:
        return this.ranchosBaseUrl + '/RanchosV1/Masivos';
      case UrlApi.laboratorios:
          return this.baseUrl + '/LaboratoriosV1/Masivos';
      case UrlApi.stockMedicionesTanques:
        return this.stockBaseUrl + '/ContenedoresV1/Tanques/Graduaciones';
    }
  }

  upload(formData, importador : UrlApi) {
    let resultado = importador.toString().substring(1,importador.toString().length - 2);
    return this.http.post<any>(this.getapiUrl(importador), formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map(event => this.getEventMessage(event, formData)),
      catchError(this.handleError('al cargar ' + resultado))

    );
  }
  upload2(formData, importador: UrlApi) : Promise<any> {
    return this.http.post<any>(this.getapiUrl(importador), formData).toPromise();
  }

  private getEventMessage(event: HttpEvent<any>, formData) {

    switch (event.type) {

      case HttpEventType.UploadProgress:
        return this.fileUploadProgress(event);

      case HttpEventType.Response:
        return this.apiResponse(event);

      default:
        return `File "${formData.get('profile').name}" surprising upload event: ${event.type}.`;
    }
  }

  private fileUploadProgress(event) {
    const percentDone = Math.round(100 * event.loaded / event.total);
    return { status: 'progress', message: percentDone };
  }

  private apiResponse(event) {
    console.log('apiresponse:' + event.body);
    return event.body;
  }
}
