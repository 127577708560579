import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { ImpuestoService } from '../impuesto.service';
import { UrlApi } from 'src/app/shared/enums/urlApi';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';

@Component({
  selector: 'app-impuestos-masivos',
  templateUrl: './impuestos-masivos.component.html',
  styleUrls: ['./impuestos-masivos.component.css']
})
export class ImpuestosMasivosComponent implements OnInit {

  constructor(private fb: UntypedFormBuilder, private impuestoService: ImpuestoService) { }
  impuestoForm = new UntypedFormGroup({
    seleccionImpuesto : new UntypedFormControl()
  })
  

  fileUpload = { status: '', message: '', filePath: '', length: 0 };
  userFileName: string;
  ITCForm: UntypedFormGroup;
  selectedFile: boolean = false;
  error: string;
  guardandoExcel: boolean = false;

  ngOnInit() {
    this.ITCForm = this.fb.group({
      name: [''],
      profile: [''],
      inputFile: ['', Validators.required]
    });
    this.impuesto.setValue(5);
  }

  onSelectedFile(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.ITCForm.get('profile').setValue(file);
      this.ITCForm.get('name').setValue(file.name);
      this.userFileName = file.name;
      this.selectedFile = true;
    }
  }
  cargarITC() {
    let imp = this.impuesto.value;
    const formData = new FormData();
    formData.append('name', this.ITCForm.get('name').value);
    formData.append('profile', this.ITCForm.get('profile').value);

    this.impuestoService.uploadImpuestoMasivos(formData, UrlApi.impuestos, imp)
      .subscribe( result => {
        if (Array.isArray(result)) {
          this.guardandoExcel = false;
        }
        this.fileUpload = result;
      },
        (err: ErrorModel) => { this.error = err.description; this.guardandoExcel = false; });
  }

    /**
   * Limpiar pantalla
   * @param limpiarMensajes Determina si se limpia el div de mensajes
   */

  reset(limpiarMensajes: boolean = false, limpiarInput: boolean = false) {
    this.guardandoExcel = false;
    // this.archivoCargado = true;
    if (limpiarInput) {
      // this.archivoCargado = false;
      this.userFileName = '';
      // this.ITCForm.get('profileControl').reset();
    }
    if (limpiarMensajes)
      this.fileUpload = { status: '', message: '', filePath: '', length: 0 };

      this.ITCForm.get('profile').setValue('');
      this.ITCForm.get('name').setValue('');
      this.ITCForm.get('inputFile').setValue('');
  }

  get impuesto(){
    return this.impuestoForm.get('seleccionImpuesto');
  }
}
