import { Component, OnInit } from "@angular/core";
import { ManagementService } from "../management.service";
import { Emisor } from "src/app/shared/models/facturacion/Emisor";
import { NgxSpinnerService } from "ngx-spinner";
import { AuthService } from "src/app/core/authentication/auth.service";
import { TipoUsuario } from "src/app/shared/enums/tipoUsuario";

@Component({
  selector: "app-list-emisor",
  templateUrl: "./list-emisor.component.html",
  styleUrls: ["./list-emisor.component.css"],
})
export class ListEmisorComponent implements OnInit {
  emisor: Emisor[];
  emisorSeleccionado: Emisor;
  esperandoCarga: boolean = true;
  TipoUsuario=TipoUsuario;
  
  constructor(
    private managmentService: ManagementService,
    private spinner: NgxSpinnerService,
    public authService: AuthService
  ) {}

  ngOnInit() {
    this.spinner.show("spinnerGrafico");
    this.getEmisores();
  }

  getEmisores() {
    this.esperandoCarga = true;
    this.managmentService.getEmisores().subscribe((result) => {
      this.emisor = result;
      this.emisorSeleccionado = result[0];
      this.esperandoCarga = false;
      this.spinner.hide("spinnerGrafico");
    });
  }

  getEmisorPorId(id: string) {
    this.managmentService.getEmisorPorId(id).subscribe((result) => {
      this.emisorSeleccionado = result;
    });
  }

  nuevoEmisor() {
    this.emisorSeleccionado = null;
  }
}
