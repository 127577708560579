<nav class="navbar navbar-light bg-light navbar-expand-lg mb-3">
  <h5>ABM aeroplanta.</h5>
  <div *ngIf="this.hasRole(TipoUsuario.SEGURIDADTABLAS)">
    <button class="btn btn-primary ms-2 float-end" (click)="nuevaAeroplanta()">Nueva aeroplanta</button>
  </div>
</nav>

<div class="col col-md-12">
  <div class="spinner-container" *ngIf="obteniendo">
    <ngx-spinner name="spinnerGrafico" bdOpacity=0.3bd
      Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
      <p style="color: white">C a r g a n d o . . . </p>
    </ngx-spinner>
  </div>
</div>

<!--SELECT AEROPLANTA-->
<div class="row" *ngIf="!obteniendo">
  <div class="col col-3">
    <div class="list-group py-3 menu-izq">
      <!-- <button type="button" [class.active]="" class="btn btn-primary" (click)="nuevaAeroplanta()">
        <div class="texto py-3">
          <b>Nueva aeroplanta</b>
        </div>
      </button> -->
      <div class="text-center" *ngFor="let aep of aeroplantas; let i=index">
        <button type="button" [class.active]="" class="list-group-item list-group-item-action"
          (click)="mostrarDetalle(i)">

          <div class="d-flex w-100 justify-content-between">
            <p>{{ aep.nombre}} </p>
          </div>
        </button>
      </div>
    </div>
  </div>
  <div class="col col-9 border-dark" *ngIf="this.aeroplantaSeleccionada != null">
    <app-detalle-abm-aeroplanta [editando]="editando" [aeroplantaSeleccionada]="aeroplantaSeleccionada"></app-detalle-abm-aeroplanta>
  </div>
</div>