import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ProvinciaParametroImpositivo } from 'src/app/shared/models/facturacion/ProvinciaParametroImpositivo';
import { CiudadParametroImpositivo } from 'src/app/shared/models/facturacion/CiudadParametroImpositivo';
import { ParametroImpositivoDetalle } from 'src/app/shared/models/facturacion/ParametroImpositivoDetalle';
import { TipoValorParametroImpositivo } from 'src/app/shared/enums/TipoValorParametroImpositivo';
import { TipoBaseCalculoParametroImpositivo } from 'src/app/shared/enums/TipoBaseCalculoParametroImpositivo';
import { TipoCodigoParametroImpositivo } from 'src/app/shared/enums/TipoCodigoParametroImpositivo';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ParametroImpositivoComponent } from 'src/app/shared/parametro-impositivo/parametro-impositivo.component';
import { ParametrosImpositivosService } from '../../parametros-impositivos.service';
import { MessageService } from 'src/app/shell/message.service';
import { AlicuotasIIBB } from 'src/app/shared/models/facturacion/AlicuotasIIBB';
import { CalculoBaseImponible } from 'src/app/shared/enums/CalculoBaseImponible';

@Component({
  selector: 'app-parametros-impositivos-agrupados',
  templateUrl: './parametros-impositivos-agrupados.component.html',
  styleUrls: ['./parametros-impositivos-agrupados.component.css']
})
export class ParametrosImpositivosAgrupadosComponent implements OnInit {

  @Input() provincia: ProvinciaParametroImpositivo;
  @Input() ciudad: CiudadParametroImpositivo;
  @Input() parametros: ParametroImpositivoDetalle[];
  @Input() ingresosBrutos: number;
  @Input() alicuotasIIBB: AlicuotasIIBB[];

  enumTipoValorParametroImpositivo = TipoValorParametroImpositivo;
  enumTipoBaseCalculoParametroImpositivo = TipoBaseCalculoParametroImpositivo;
  enumTipoCodigoParametroImpositivo = TipoCodigoParametroImpositivo;

  guardandoDatos: boolean = false;
  guardandoIIBB: boolean = false;

  calculo = CalculoBaseImponible;

  labelCiudad: string;

  constructor(
    private modalService: NgbModal,
    private parametrosService: ParametrosImpositivosService,
    private messageService: MessageService) { }

  ngOnInit() {
    if (this.ciudad == null) {
      this.labelCiudad = "Impuestos y percepciones provinciales."
    } else {
      this.labelCiudad = this.ciudad.ciudadNombre;
    }
  }

  obtenerListaEnumCodigosCargados() {
    let lista: string[] = [];

    for (let i in this.parametros) {
        lista.push(TipoCodigoParametroImpositivo[this.parametros[i].codigo]);
    }

    return lista;
  }

  keysBasoCalculo(): Array<string> {
    var keys = Object.keys(this.calculo);
    keys = keys.slice(keys.length / 2);
    return keys;
  }

  cambiarNombre(id: number, property: string, event: any) {
    this.alicuotasIIBB[id][property] = event.target.value;
  }

  cambiarValor(id: number, property: string, event: any) {
    if (event.target.value == '') {
      this.alicuotasIIBB[id][property] = 0;
    } else {
      this.alicuotasIIBB[id][property] = Number(event.target.value);
    }

  }
  cambiarEnumerado(id: number, property: string, event: any) {
    this.alicuotasIIBB[id][property] = event.target.selectedIndex;
  }

  agregarParametroImpostivo() {
    const modalRef = this.modalService.open(ParametroImpositivoComponent, { centered: true, backdrop: 'static', size: 'lg' })
    modalRef.componentInstance.esProvincia = this.ciudad ? false : true;
    modalRef.componentInstance.listaEnumCodigosCargados = this.obtenerListaEnumCodigosCargados();
    modalRef.result.then((param: ParametroImpositivoDetalle) => {
      this.guardandoDatos = true;

      let provinciaId = this.ciudad ? "" : this.provincia.provinciaId;
      let ciudadId = this.ciudad ? this.ciudad.ciudadId : "";

      this.parametrosService.crearParametroImpositivo(provinciaId, ciudadId, param)
        .subscribe(rta => {
          if (rta != "") {
            console.log("-> " + rta);
          }
          this.guardandoDatos = false;
        }, (error) => {
          this.messageService.showErrorMessage(error.message + ": " + error.description);
          this.guardandoDatos = false;
        });
    }).catch(() => {
      this.guardandoDatos = false;
    });
  }

  editarParametroImp(param: ParametroImpositivoDetalle) {
    const modalRef = this.modalService.open(ParametroImpositivoComponent, { centered: true, backdrop: 'static', size: 'lg' });
    modalRef.componentInstance.parametroImpositivo = param;
    modalRef.componentInstance.esProvincia = this.ciudad ? false : true;
    modalRef.componentInstance.listaEnumCodigosCargados = this.obtenerListaEnumCodigosCargados();
    modalRef.result.then((param: ParametroImpositivoDetalle) => {
      this.guardandoDatos = true;

      this.parametrosService.modificarParametroImpositivo(param, true)
        .subscribe(rta => {
          if (rta != "") {
            console.log("-> " + rta);
          }
          this.guardandoDatos = false;
        }, (error) => {
          this.messageService.showErrorMessage(error.message + ": " + error.description);
          this.guardandoDatos = false;
        });
    }).catch(() => {
      this.guardandoDatos = false;
    });
  }

  onParametroEstadoActivoChange(param: ParametroImpositivoDetalle, event) {
    let nuevoEstado = event.target.checked;
    param.activa = nuevoEstado;

    this.parametrosService.modificarParametroImpositivo(param, false)
      .subscribe(rta => {
        if (rta) {
          this.messageService.showSuccessMessage("Se " + (nuevoEstado ? "activo" : "desactivo") + " el parametro impositivo.");
        }
      }, (error) => {
        this.messageService.showErrorMessage(error.message + ": " + error.description);
      });
  }

  getDescripcionTipoValorParametroImpositivo(tipoValorParametroImpositivo) {
    if (tipoValorParametroImpositivo == TipoValorParametroImpositivo.Alicuota) {
      return "Alicuota";
    } else if (tipoValorParametroImpositivo == TipoValorParametroImpositivo.ValorFijo) {
      return "Valor Fijo";
    } else {
      return "-";
    }
  }

  getDescripcionTipoBaseCalculoParametroImpositivo(tipoBaseCalculoParametroImpositivo) {
    if (tipoBaseCalculoParametroImpositivo == TipoBaseCalculoParametroImpositivo.Neto) {
      return "Neto";
    } else if (tipoBaseCalculoParametroImpositivo == TipoBaseCalculoParametroImpositivo.NetoMasImpuestoInterno) {
      return "Neto + imp.<br/>interno";
    } else if (tipoBaseCalculoParametroImpositivo == TipoBaseCalculoParametroImpositivo.NetoMasImpuestoInternoMasTasas) {
      return "Neto + imp.<br/>interno + tasas";
    } else if (tipoBaseCalculoParametroImpositivo == TipoBaseCalculoParametroImpositivo.Total) {
      return "Total";
    } else {
      return "-";
    }
  }

  guardarIIBB() {
    let completoNombre: boolean = true;
    let completoAlicuota: boolean = true;
    this.guardandoIIBB = true;
    this.alicuotasIIBB.forEach((value, index) => {
      if (value.nombreAlicuota == '') {
        completoNombre = false;
      };
    });
    if (completoNombre) {
      this.parametrosService.actualizarAlicuotasIIBB(this.alicuotasIIBB, this.provincia.provinciaCodigo)
        .subscribe(result => {
          this.messageService.showSuccessMessage('Se actualizó correctamente la información de IIBB.');
          this.guardandoIIBB = false;
        }, () => {
          this.messageService.showErrorMessage('Hubo un error al actualizas la información de IIBB, por favor reintente.');
          this.guardandoIIBB = false;
        })
    } else {
      this.messageService.showErrorMessage('Por favor revise el nombre de las alicuotas, no pueden ser vacias.');
      this.guardandoIIBB = false;
    }
  }

  // validaciones
  onlyDecimalPositivo(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46;
  }
}
