<div class="modal-header">
    <h4 class="modal-title">Buscador de matrícula</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="this.activeModal.close()">
        
    </button>
</div>

<div class="modal-body">

    <div class="row">
        <div class="mb-3 row col-md-12">
        <label for="matriculaFormControl" class="col-sm-5 col-form-label">Matrícula:</label>
        <div class="col-sm-7">
            <input class="form-control" placeholder="ABC123" 
            [formControl]="matriculaFormControl" maxlength="10" >
            <div *ngIf="matriculaFormControl.invalid && matriculaFormControl.errors.required && (matriculaFormControl.dirty || matriculaFormControl.touched)"
                class="alert alert-danger">Requerido</div>
        </div>
        </div>
    </div>
</div>

<div class="modal-footer">        
    <button class="btn btn-primary" [disabled]="matriculaFormControl.invalid" (click)="onClick()">
        Aceptar
    </button>    
</div>   