import { Component, OnInit } from '@angular/core';
import { ClienteService } from 'src/app/cliente/cliente.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { MessageService } from 'src/app/shell/message.service';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';

@Component({
  selector: 'app-descargar-datos',
  templateUrl: './descargar-datos.component.html',
  styleUrls: ['./descargar-datos.component.scss']
})
export class DescargarDatosComponent implements OnInit {

  descargandoDatos:boolean=false;
  descargandoClientes:boolean=false;
  descargandoCuentas:boolean=false;
  descargandoDirecciones:boolean=false;
  descargandoCbus:boolean=false;
  descargandoIibb:boolean=false;

  constructor(
    private clienteService: ClienteService, 
    private messageService: MessageService,
    private http: HttpClient) { }

  ngOnInit(): void {
  }

  descargarDatos(nombre: string){
    this.descargandoDatos=true;
    
    switch(nombre){
      case 'Clientes':
        this.descargandoClientes=true;
        break;
      case 'Cuentas':
        this.descargandoCuentas=true;
        break;
      case 'Direcciones':
        this.descargandoDirecciones=true;
        break;
      case 'Cbus':
        this.descargandoCbus=true;
        break;
      case 'Iibb':
        this.descargandoIibb=true;
        break;        
    }
    const url =  `${this.clienteService.baseURL}/ExportarV1/` + nombre;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });
    // Process the file download
    this.http.get(url, { headers: headers, responseType: 'blob' as 'json' })
      .subscribe((res: Blob) => {
        const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' })
        saveAs(blob, 'ListadoDe' + nombre);
        this.descargandoDatos=false;
        this.descargandoClientes=false;
        this.descargandoCuentas=false;
        this.descargandoDirecciones=false;
        this.descargandoCbus=false;
        this.descargandoIibb=false;
      }, (err:ErrorModel)=> {
        this.messageService.showErrorMessage(err.message);
        this.descargandoDatos=false;
        this.descargandoClientes=false;
        this.descargandoCuentas=false;
        this.descargandoDirecciones=false;
        this.descargandoCbus=false;
        this.descargandoIibb=false;
      })
  }
}