import { Rubro } from './Rubro';
import { parametroImpositivo } from './ParametroImpositivo';

export class Producto {
  id: string;
  codigoProducto: string;
  nombreProducto: string;
  precio: number;
  tipoPrecio: number; // 0: Final | 1: Base
  impuestosProducto: parametroImpositivo[];
  rubro: Rubro;
  rubroId: string;
  activo:boolean;
  densidad: number;
  unidadMedida: number;
  tipoProducto: number;
  tipoCombustible: number;
  tipoAerocombustible: number;
  tipoServicio: number;
  densidadMin: number;
  densidadMax: number;
  porcentajeAlije: number;
  color: string;
  aplicaITC: boolean;
  aplicaIDC: boolean;
  peligrosidad: string;
  agrupacionContable: string;
  partida: string;
  productoSAP: string;
  unidadMedidaSAP: string;
  unidadNegocioGerencial: string;
  unidadMedidaStock: string;
  unidadMedidaComercial: string;
  unidadMedidaProduccion: string;
  grupoEnvase: string;
  controlCalidad: boolean;
  controlStock: boolean;
  aprobadoProducto: boolean;
  aprobadoImpuestos: boolean;
  esUtilizable:boolean;
}




