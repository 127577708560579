<div class="modal-header bg-light">
  <h4 class="modal-title" *ngIf="nuevo">Nuevo equipo</h4>
  <h4 class="modal-title" *ngIf="editando">Editar equipo</h4>
  <h4 class="modal-title" *ngIf="cambioFueraServicio">Equipo fuera de servicio</h4>
  <h4 class="modal-title" *ngIf="cambioEstado && !cambioFueraServicio">Cambio estado de equipo</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close(false)"></button>
</div>
<div class="modal-body" [formGroup]="equiposForm">
  <!--NOMBRE-->
  <div class="row" *ngIf="editando || nuevo">
    <div class="mb-3 row col-md-12">
      <label for="nombreFormControl" class="col-sm-5 col-form-label">*Nombre:</label>
      <div class="col-sm-7">
        <input class="form-control" formControlName="nombreFormControl" maxlength="50">
        <div
        *ngIf="nombreFormControl.invalid && nombreFormControl.errors.required && (nombreFormControl.dirty || nombreFormControl.touched)"
          class="alert alert-danger">Requerido</div>
        <div
        *ngIf="nombreFormControl.invalid && nombreFormControl.errors.maxLength && (nombreFormControl.dirty || nombreFormControl.touched)"
          class="alert alert-danger">Maximo 20 caracteres</div>  
      </div>
    </div>
  </div>
  <!--ESTADO-->
  <div class="row" *ngIf="nuevo">
    <div class="mb-3 row col-md-12">
      <label for="estadoFormControl" class="col-sm-5 col-form-label">Estado inicial:</label>
      <div class="col-sm-7">
        <input class="form-control" maxlength="50" disabled placeholder="EnServicio">
      </div>
    </div>
  </div>
  <!--FECHAFS-->
  <div #fechaHora class="row" *ngIf="cambioEstado">
    <div class="mb-3 row col-md-12">
      <label for="fechaFSFormControl" class="col-sm-5 col-form-label">*Fecha cambio de estado:</label>
      <div class="col-sm-7">
        <div class="input-group">
          <input type="date" class="form-control" formControlName="fechaFSFormControl" [ngModel]="model" (ngModelChange)="model = $event"
          placeholder="aaaa-mm-dd" name="dp" title="Campo obligatorio." ngbDatepicker
          #l="ngbDatepicker" readonly />
          <div class="input-group-append">
            <button type="button" class="input-group-btn btn btn-secondary" (click)="l.toggle()">
              <i class="fa fa-calendar" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--HORAFS-->
  <div #fechaHora class="row" *ngIf="cambioEstado ">
    <div class="mb-3 row col-md-12">
      <label for="fechaFSFormControl" class="col-sm-5 col-form-label">*Hora estado:</label>
      <div class="col-sm-7">
        <ngb-timepicker [(ngModel)]="horaAct" [meridian]="false" formControlName="horaFSFormControl"
        [readonlyInputs]="true">
        </ngb-timepicker>
      </div>
    </div>
  </div>
  <!--OBSERVACIONES-->
  <div class="row" *ngIf="cambioEstado">
    <div class="mb-3 row col-md-12">
      <label for="observacionesFormControl" class="col-sm-5 col-form-label">Observaciones:</label>
      <div class="col-sm-7">
        <input class="form-control" formControlName="observacionesFormControl" maxlength="50">
        <div
        *ngIf="observacionesFormControl.invalid && observacionesFormControl.errors.required && (observacionesFormControl.dirty || observacionesFormControl.touched)"
          class="alert alert-danger">Requerido</div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer justify-content-center">
  <button class="btn btn-primary me-2" id="aceptar" [disabled]="!equiposForm.valid || grabando" (click)="agregarEquipo()">
    <span *ngIf="!grabando">Guardar</span>
    <div *ngIf="grabando" class="d-flex align-items-center">
      <div class="spinner-border text-light spinner-border-sm" role="status"></div>
      <span>Guardando...</span>
    </div>
  </button>
  <button type="button" class="btn btn-secondary" (click)="this.activeModal.close(false)">Cancelar</button>
</div>
