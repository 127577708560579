import { DatePipe, DecimalPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgbCalendar, NgbDatepickerConfig, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { CierreTurnoService } from 'src/app/cierreTurno/cierre-turno.service';
import { ReportesService } from 'src/app/reportes/reportes.service';
import { Producto } from 'src/app/shared/models/abm/Producto';
import { Consolidado } from 'src/app/shared/models/cierreTurno/Consolidado';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { MessageService } from 'src/app/shell/message.service';

@Component({
  selector: 'app-consolidado-detalle',
  templateUrl: './consolidado-detalle.component.html',
  styleUrls: ['./consolidado-detalle.component.scss']
})
export class ConsolidadoDetalleComponent implements OnInit {

  @Input() producto:Producto;

  consolidado:Consolidado[];

  model: NgbDateStruct;
  model1: NgbDateStruct;
  fechaDesde: Date;
  fechaHasta: Date;

  obteniendoConsolidado:boolean = false;
  limpiarConsulta:boolean = false;

  constructor(
    private cierreTurnoService: CierreTurnoService,
    private reportesService: ReportesService,
    private calendar: NgbCalendar,
    private datepickerConfig: NgbDatepickerConfig,
    private decimalPipe:DecimalPipe) { }

  ngOnInit(): void {
    console.log(this.producto);
    this.resetFechas();
  }

  obtenerReporteConsolidado() {
    this.consolidado = null;
    this.limpiarConsulta = false;
    this.obteniendoConsolidado = true;
    this.reportesService.obtenerReporteConsoldiado(this.producto.codigoProducto, this.getFechaDesde(), this.getFechaHasta())
      .subscribe(resp =>{
        this.consolidado = resp;
        this.obteniendoConsolidado = false;
      }, ()=>{
        this.obteniendoConsolidado = false;
      });
  }
      
  ngOnChanges() {
    this.limpiarConsulta = true;
    this.resetFechas();
  } 

  onClickBuscar() {    
    this.setearFechaDesdeHasta();
    this.obtenerReporteConsolidado();
  }

  resetFechas() {
    this.model = this.calendar.getToday();
    this.model1 = this.calendar.getToday();
    let inicio: any = { day: 1, month: 1, year: 2019 };
    this.datepickerConfig.minDate = inicio;
    let fin: any = { day: this.model.day, month: this.model.month, year: this.model.year };
    this.model1 = this.calendar.getToday();
    this.datepickerConfig.maxDate = fin;
  } 

  setearFechaDesdeHasta() {
    this.fechaDesde = new Date(this.model.year, this.model.month - 1, this.model.day);
    this.fechaHasta = new Date(this.model1.year, this.model1.month - 1, this.model1.day);
    this.fechaHasta.setHours(23, 59, 59, 99);
  }

  formatDate(year, month, day, hour, minute, seconds): string {
    return year + '-' + month.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + '-' + day.toLocaleString(undefined, { minimumIntegerDigits: 2 })
      + 'T' + hour.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + minute.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + seconds.toLocaleString(undefined, { minimumIntegerDigits: 2 });
  }

  getFechaDesde(){
    return this.formatDate(this.fechaDesde.getFullYear(), this.fechaDesde.getMonth() + 1, this.fechaDesde.getDate(), 0, 0, 0);
  }

  getFechaHasta(){
    return this.formatDate(this.fechaHasta.getFullYear(), this.fechaHasta.getMonth() + 1, this.fechaHasta.getDate(), 23, 59, 59);
  }

  toggleDatepicker(e: any) {
    e.toggle();
  }
}
