import { Cae } from './Cae';
import { Cliente } from '../despacho/Cliente';
import { Renglon } from '../facturacion/Renglon';
import { Emisor } from './Emisor';
import { Factura } from './Factura';
import { Producto } from '../despacho/Producto';
import { renglonNDebito } from './RenglonNDebito';
import { RemitoAeroespecialidad } from '../stock/RemitoAeroespecialidad';
import { RemitoCargamento } from '../stock/RemitoCargamento';
import { RemitoSalida } from '../stock/RemitoSalida';
import { Comprobante as ComprobanteStock } from "../stock/Comprobante";
import { ActaManual } from '../stock/ActaManual';

export class Comprobante {
  id: string;
  prefijo: number;
  numeroComprobante: number;
  numeroRemito: number;
  tipoMovimiento: number;
  fechaHoraCreacion: string;
  fechaAnulacion: string;
  fechaVencimiento: string;
  fechaEmision: string;
  codigoAeroplanta: string;
  renglones: Renglon[];
  emisor: Emisor;
  cliente: Cliente;
  factura: Factura;
  producto: Producto[];
  cae: Cae;
  nombreAeroplanta: string;
  localidadAeroplanta: string;
  comprobantesRelacionados: Producto[];
  renglonDebito: renglonNDebito[];
  observaciones: string;
  ciudadAeroplanta: string;
  tipoMoneda: number;
  totalEnTexto: string;
  provinciaAeroplanta: string;
  netoTotal: number;
  ivaTotal: number;
  total: number;
  actaManual: ActaManual;
  razonSocialCliente: string;

  //para reporte de movimiento de stock
  verComprobante: boolean;
  remito: ComprobanteStock;
  remitoAeroespecialidad: RemitoAeroespecialidad;
  remitoCargamento: RemitoCargamento;
  remitoSalida: RemitoSalida;

  //Para carga de datos
  loadIcon: boolean;
}
