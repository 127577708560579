<nav class="navbar navbar-light bg-light navbar-expand-lg mb-3">
  <form class="form-inline flex-fill" id="formularioFiltros">
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <ul class="navbar-nav me-auto flex-row">
      <div class="nav-item" [formGroup]="searchForm">
        <input id="busquedaFormControl" type="text" class="form-control input-sm" placeholder="Búsqueda"
          formControlName="busqueda" (input)="filtrarPrecios()" placeholder="Buscar" />
      </div>


      <button class=" navbar-right btn btn-sm p-0 border-0 ps-3" [disabled]="esperandoCarga" (click)="onRefreshClick()">
        <i class="fa fa-refresh fa-2x me-3 text-primary" ngbTooltip="Actualizar lista"></i>
      </button>

    </ul>
  </form>

  <div class="mt-2 float-end">
    <button type="button" class="btn btn-outline-primary" (click)="desactivarPrecios()" [disabled]="actualizando">
      <span *ngIf="!this.actualizando">Aprobar desactivación</span>
          <div *ngIf="this.actualizando" class="d-flex align-items-center">
            <div class="spinner-border text-light spinner-border-sm" role="status"></div>
            <span>Desactivando...</span>
          </div>
    </button>

    <button type="button" class="btn btn-outline-primary ms-1" (click)="rechazarDesactivarPrecios()" [disabled]="actualizando">
      <span *ngIf="!this.actualizando">Rechazar desactivación</span>
          <div *ngIf="this.actualizando" class="d-flex align-items-center">
            <div class="spinner-border text-light spinner-border-sm" role="status"></div>
            <span>Realizando petición...</span>
          </div>
    </button>
  </div>
</nav>

<form class="mb-3">
  <div class="col col-md-12">
    <div class="spinner-container" *ngIf="esperandoCarga">
      <ngx-spinner name="spinnerGrafico" bdOpacity=0.3bd
        Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
        <p style="color: white">{{messagePrecios}}</p>
      </ngx-spinner>
    </div>

    <ngb-alert type="danger" [dismissible]="false" *ngIf="this.mensajeAccesoInsuficiente != null && !esperandoCarga">
      {{ this.mensajeAccesoInsuficiente }}
    </ngb-alert>

    <div id="tablaDetalle" class="tabla-precios" *ngIf="this.mensajeAccesoInsuficiente == null && !esperandoCarga">
      <table id="tabla" class="table table-sm table-hover">
        <thead class="text-center letra">
          <tr>
            <th scope="col"> Nivel </th>
            <th scope="col"> Lote </th>
            <th scope="col"> Codigo </th>
            <th scope="col"> Producto </th>
            <th scope="col"> Desde-Hasta</th>
            <th scope="col"> Moneda </th>
            <th scope="col"> Cliente </th>
            <th scope="col"> Tipo Venta </th>
            <th scope="col"> Precio </th>
            <th scope="col"> Entrega </th>
            <th scope="col"> Area </th>
            <th scope="col"> Segmento </th>
            <th scope="col"> Aeroplanta</th>
            <th scope="col"> Fecha/Usuario importación</th>
            <th scope="col"> Fecha/Usuario aprobación</th>
            <th scope="col">Seleccionar</th>
          </tr>
        </thead>
        <tbody class="text-center letra">
          <tr *ngFor="let p of preciosFiltrados; let i = index">

            <td>{{ preciosFiltrados[i].nivelPrecio }}</td>

            <td>L{{ preciosFiltrados[i].numeroLote }}</td>

            <td> {{preciosFiltrados[i].producto.codigoProducto != null?preciosFiltrados[i].producto.codigoProducto: "--"}}</td>

            <td> {{preciosFiltrados[i].producto.nombreProducto != null?preciosFiltrados[i].producto.nombreProducto: "--"}}</td>

            <td *ngIf="preciosFiltrados[i].fechaDesde != null && preciosFiltrados[i].fechaHasta != null">
              {{preciosFiltrados[i].fechaDesde | date: "dd'/'MM'/'yy HH:mm:ss"}} -
              {{preciosFiltrados[i].fechaHasta | date: "dd'/'MM'/'yy HH:mm:ss"}}</td>

            <td *ngIf="preciosFiltrados[i].fechaHasta == null && preciosFiltrados[i].fechaDesde"> -- </td>

            <td> {{preciosFiltrados[i].moneda != null?preciosFiltrados[i].moneda.nombre: "--"}}</td>

            <td> {{preciosFiltrados[i].cliente != null?preciosFiltrados[i].cliente.razonSocial: "--"}}</td>

            <td *ngIf="preciosFiltrados[i].tipoVenta == null || preciosFiltrados[i].tipoVenta == 0"> - </td>
            <td *ngIf="preciosFiltrados[i].tipoVenta != null && preciosFiltrados[i].tipoVenta == 2"> Internacional</td>
            <td *ngIf="preciosFiltrados[i].tipoVenta != null && preciosFiltrados[i].tipoVenta == 1"> Cabotaje </td>

            <td> {{preciosFiltrados[i].precioContado != null?preciosFiltrados[i].precioContado: "--"}}</td>

            <td> {{preciosFiltrados[i].direccionEntrega != null?preciosFiltrados[i].direccionEntrega: "--"}}</td>

            <td> {{preciosFiltrados[i].areaNegocio != null?preciosFiltrados[i].areaNegocio.nombreArea: "--"}}</td>

            <td> {{preciosFiltrados[i].segmento != null?preciosFiltrados[i].segmento.nombreSegmento: "--"}}</td>

            <td>{{preciosFiltrados[i].codigoAeroplanta}}</td>

            <td>{{preciosFiltrados[i].importoFecha | date: "dd'/'MM'/'yy"}} - {{preciosFiltrados[i].importoUserName}}</td>

            <td *ngIf="preciosFiltrados[i].aprobadoFecha != null">
              {{preciosFiltrados[i].aprobadoFecha | date: "dd'/'MM'/'yy"}} - {{preciosFiltrados[i].aprobadoUserName}}</td>
            <td *ngIf="preciosFiltrados[i].aprobadoFecha == null">--</td>

            <td class="justify-content-center">
                <input [checked]="preciosFiltrados[i].seleccionadoUi" (click)="agregarPrecios(preciosFiltrados[i])" type="checkbox" id="{{preciosFiltrados[i].id}}" >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</form>
