import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule, Router } from '@angular/router';
import { Shell } from '../shell/shell.service';
import { authGuard } from '../core/authentication/auth.guard';
import { TipoUsuario } from '../shared/enums/tipoUsuario';
import { PreciosComponent } from './precio/precios/precios.component';
import { PreciosIndexComponent } from './precios-index/precios-index.component';
import { DetallePreciosComponent } from './precio/detalle-precios/detalle-precios.component';
import { PreciosDesactivacionComponent } from './precios-desactivacion/precios-desactivacion.component';
import { NivelesAprobacionComponent } from './niveles-aprobacion/niveles-aprobacion.component';
import { LotesComponent } from './lotes/lotes.component';
import { ListadoByrComponent } from './bonificacion-recargo/listado-byr/listado-byr.component';
import { AprobarByrComponent } from './bonificacion-recargo/aprobar-byr/aprobar-byr.component';
import { AuthService } from '../core/authentication/auth.service';
import { VigenciasListadosIndexComponent } from './vigencias-listados-index/vigencias-listados-index.component';
import { PrecioConsulta } from '../shared/models/precios/PrecioConsulta';
import { DescargaPreciosComponent } from './precio/descarga-precios/descarga-precios.component';
import { AprobarModificacionComponent } from './aprobar-modificacion/aprobar-modificacion.component';
import { GestionPreciosComponent } from './gestion-precios/gestion-precios.component';
import { ImportacionMasivaComponent } from './gestion-precios/importacion-masiva/importacion-masiva.component';
import { HistorialDesactivacionComponent } from './historial-desactivacion/historial-desactivacion.component';
import { ActualizacionPreciosComponent } from './gestion-precios/actualizacion-precios/actualizacion-precios.component';
import { AprobarByrPorLoteComponent } from './bonificacion-recargo/aprobar-byr-por-lote/aprobar-byr-por-lote.component';
import { ReporteNivelesComponent } from './reporte-niveles/reporte-niveles.component';
import { SimuladorPrecioComponent } from './simulador-precio/simulador-precio.component';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'precios', component: PreciosIndexComponent, canActivate: [authGuard],
       data: { roles: [TipoUsuario.SUPERVISOR, TipoUsuario.OPERADOR, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.MANDATARIO, TipoUsuario.PRECIOS, TipoUsuario.APROBADORPRECIOS, TipoUsuario.SEGURIDADTABLAS, TipoUsuario.APROBADORBYR, TipoUsuario.COMERCIAL, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.COMERCIAL_LN, TipoUsuario.APROBADORBYR, TipoUsuario.COMERCIAL_AV, TipoUsuario.FACTURACION, TipoUsuario.REFACTURACION, TipoUsuario.CONSPRECIOS, TipoUsuario.SOPORTE] }, children: [
        {
          path: 'vigenciasListados', component: VigenciasListadosIndexComponent,
          canActivate: [authGuard], data: { roles:
            [TipoUsuario.MANDATARIO, TipoUsuario.PRECIOS, TipoUsuario.APROBADORPRECIOS, TipoUsuario.SEGURIDADTABLAS, TipoUsuario.APROBADORBYR, TipoUsuario.COMERCIAL, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.COMERCIAL_LN, TipoUsuario.APROBADORBYR, TipoUsuario.COMERCIAL_AV, TipoUsuario.FACTURACION, TipoUsuario.REFACTURACION, TipoUsuario.CONSPRECIOS, TipoUsuario.SOPORTE] },
          children: [
            {
              path: 'precios',
              component: PreciosComponent,
              canActivate: [authGuard],
              data: { roles: [TipoUsuario.MANDATARIO, TipoUsuario.PRECIOS, TipoUsuario.APROBADORPRECIOS, TipoUsuario.SEGURIDADTABLAS, TipoUsuario.APROBADORBYR, TipoUsuario.COMERCIAL, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.COMERCIAL_LN, TipoUsuario.APROBADORBYR, TipoUsuario.COMERCIAL_AV, TipoUsuario.FACTURACION, TipoUsuario.REFACTURACION, TipoUsuario.CONSPRECIOS, TipoUsuario.SOPORTE] }
            },
            {
              path: 'preciosReporte', component: DescargaPreciosComponent,
              canActivate: [authGuard], data: { roles: [TipoUsuario.PRECIOS, TipoUsuario.APROBADORPRECIOS, TipoUsuario.SEGURIDADTABLAS, TipoUsuario.APROBADORBYR, TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_LN, TipoUsuario.APROBADORBYR, TipoUsuario.COMERCIAL_AV, TipoUsuario.FACTURACION, TipoUsuario.REFACTURACION, TipoUsuario.RESPRODUCTO, TipoUsuario.CONSPRECIOS, TipoUsuario.SOPORTE]},
            }
          ]
        },
        {
          path: 'gestionPrecios', component: GestionPreciosComponent,
          canActivate: [authGuard], data: { roles: [TipoUsuario.PRECIOS] },
          children: [
            {
              path: 'importacionMasiva',
              component: ImportacionMasivaComponent,
              canActivate: [authGuard],
              data: { roles: [TipoUsuario.PRECIOS] }
            },
            {
              path: 'actualizacionPrecios',
              component: ActualizacionPreciosComponent,
              canActivate: [authGuard],
              data: { roles: [TipoUsuario.PRECIOS] }
            }
          ]
        },
        {
          path: 'historialDesactivacion', component: HistorialDesactivacionComponent,
          canActivate: [authGuard], data: { roles: [TipoUsuario.APROBADORPRECIOS, TipoUsuario.PRECIOS, TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_LN, TipoUsuario.COMERCIAL_AV, TipoUsuario.APROBADORBYR] },
        },
        {
          path: 'aprobacionDesactivacion', component: PreciosDesactivacionComponent,
          canActivate: [authGuard], data: { roles: [TipoUsuario.APROBADORPRECIOS ]},
        },
        {
          path: 'nivelesAprobacionPrecios', component: NivelesAprobacionComponent,
          canActivate: [authGuard], data: { roles: [TipoUsuario.SEGURIDADTABLAS] },
        },
        {
          path: 'nivelesAprobacionByr', component: NivelesAprobacionComponent,
          canActivate: [authGuard], data: { roles: [TipoUsuario.SEGURIDADTABLAS] },
        },
        {
          path: 'lotes', component: LotesComponent,
          canActivate: [authGuard], data: { roles: [TipoUsuario.APROBADORPRECIOS, TipoUsuario.PRECIOS] },
        },
        {
          path: 'aprobarModificacion', component: AprobarModificacionComponent,
          canActivate: [authGuard], data: { roles: [TipoUsuario.APROBADORPRECIOS] },
        },        
        {
          path: 'listadoByR', component: ListadoByrComponent,
          canActivate: [authGuard], data: { roles: [TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.APROBADORBYR] },
        },
        {
          path: 'aprobarByRProductos', component: AprobarByrPorLoteComponent,
          canActivate: [authGuard], data: { roles: [TipoUsuario.APROBADORBYR] },
        },
        {
          path: 'aprobarByRExpendedora', component: AprobarByrComponent,
          canActivate: [authGuard], data: { roles: [TipoUsuario.APROBADORBYR] },
        },
        {
          path: 'reporteNiveles', component: ReporteNivelesComponent,
          canActivate: [authGuard], data: { roles: [TipoUsuario.APROBADORPRECIOS, TipoUsuario.PRECIOS, TipoUsuario.SEGURIDADTABLAS, TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN, TipoUsuario.APROBADORBYR, TipoUsuario.SOPORTE] },
        },    
        {
          path: 'simuladorPrecio', component: SimuladorPrecioComponent,
          canActivate: [authGuard], data: { roles: [TipoUsuario.SUPERVISOR, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.OPERADOR] },
        }  
      ]},
  ])
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})

export class PreciosRoutingModule {
  constructor( private authService: AuthService, private router: Router ) {
    this.router.events.subscribe((event) => {
      if(event['url'] && event['url'] == '/precios') {
        this.redireccionar();
     }else if(event['url'] && event['url'] == '/precios/gestionPrecios') {
      this.redireccionarGestionPrecios();
    }
  });
  }

  redireccionar() {
    if(this.authService.hasRole(TipoUsuario.MANDATARIO, TipoUsuario.PRECIOS, TipoUsuario.APROBADORPRECIOS, TipoUsuario.SEGURIDADTABLAS, TipoUsuario.APROBADORBYR, TipoUsuario.COMERCIAL, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.TUTOR, TipoUsuario.RESPRODUCTO, TipoUsuario.COMERCIAL_LN, TipoUsuario.APROBADORBYR, TipoUsuario.COMERCIAL_AV, TipoUsuario.FACTURACION, TipoUsuario.REFACTURACION, TipoUsuario.CONSPRECIOS, TipoUsuario.SOPORTE)){
      this.router.navigate(['/precios/vigenciasListados/precios']);
    }else if(this.authService.hasRole(TipoUsuario.APROBADORPRECIOS)){
      this.router.navigate(['/precios/lotes']);
    }else if(this.authService.hasRole(TipoUsuario.PRECIOS)){
        this.router.navigate(['/precios/pedidosDesactivacion'], {queryParams : {tipoDesactivacion:0}});
    }else if(this.authService.hasRole(TipoUsuario.APROBADORPRECIOS)){
      this.router.navigate(['/precios/aprobacionDesactivacion'], {queryParams : {tipoDesactivacion:1}});
    }else if(this.authService.hasRole(TipoUsuario.SEGURIDADTABLAS)){
      this.router.navigate(['/precios/nivelesAprobacionPrecios'], {queryParams : {tipoNivel:0}});
    }else if(this.authService.hasRole(TipoUsuario.COMERCIAL)){
      this.router.navigate(['/precios/listadoByR']);
    }else if(this.authService.hasRole(TipoUsuario.APROBADORBYR)){
      this.router.navigate(['/precios/aprobarByRProductos']);
    }else if(this.authService.hasRole(TipoUsuario.SEGURIDADTABLAS)){
      this.router.navigate(['/precios/nivelesAprobacionByr'], {queryParams : {tipoNivel:1}});    
    }else if(this.authService.hasRole(TipoUsuario.SUPERVISOR, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.OPERADOR)){
      this.router.navigate(['/precios/simuladorPrecio']);
    }
  }

  redireccionarGestionPrecios() {  
    if(this.authService.hasRole(TipoUsuario.PRECIOS)){
      this.router.navigate(['/precios/gestionPrecios/importacionMasiva']);
    }  
  }
}
