import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { Holdeo } from 'src/app/shared/models/facturacion/Holdeo';
import { MessageService } from 'src/app/shell/message.service';
import { ComprobanteService } from '../../comprobante.service';
import { NuevoHoldeoComponent } from '../nuevo-holdeo/nuevo-holdeo.component';

@Component({
  selector: 'app-holdeo-aeroplanta',
  templateUrl: './holdeo-aeroplanta.component.html',
  styleUrls: ['./holdeo-aeroplanta.component.css']
})
export class HoldeoAeroplantaComponent implements OnInit {
  @Output() volver = new EventEmitter();

  desactivandoHoldeo: boolean = false;

  cuentasHoldeadas: Holdeo[] = [];
  obteniendo: boolean = false;
  holdeoSeleccionado: number;
  constructor(private spinner: NgxSpinnerService, private comprobantesService: ComprobanteService, private modal: NgbModal,
    private messageService: MessageService) { }

  ngOnInit() {
    this.getHoldeoCuenta();
  }

  getHoldeoCuenta() {
    this.spinner.show('spinnerLista');
    this.obteniendo = true;
    this.comprobantesService.getHoldeo(4)
      .subscribe(result => {
        this.cuentasHoldeadas = result.filter(a => a.tipoHoldeo == 0);
        this.cuentasHoldeadas = this.cuentasHoldeadas.filter(b => b.activo == true);
        console.log(this.cuentasHoldeadas);
        this.spinner.hide('spinnerLista');
        this.obteniendo = false;
      });
  }

  agregarAeroplanta(){
    let modalRef = this.modal.open(NuevoHoldeoComponent, {centered: true, backdrop: 'static'});
    modalRef.componentInstance.tipo = 0;
    modalRef.result.then(result => {
      result == true ? this.ngOnInit() : null
    });
  }

  quitarHoldeo(i: number){
    this.desactivandoHoldeo = true;
    this.holdeoSeleccionado = i;
    this.cuentasHoldeadas[i].activo ? this.cuentasHoldeadas[i].activo = false : this.cuentasHoldeadas[i].activo = true;
    this.comprobantesService.putHoldeo(this.cuentasHoldeadas[i])
      .subscribe(result => {
        result.activo ? this.messageService.showSuccessMessage('El holdeo General fue activado con exito') : this.messageService.showErrorMessage('El holdeo General fue desactivado con exito');
        let y = this.cuentasHoldeadas.indexOf(this.cuentasHoldeadas[i])
        if (y != -1){
          this.cuentasHoldeadas.splice(y,1);
        }     
        console.log(this.cuentasHoldeadas);
        this.desactivandoHoldeo = false;
        this.holdeoSeleccionado = null;
      }, (err: ErrorModel) => {
        this.messageService.showErrorMessage('Hubo un error al actualizar el estado del holdeo General');
        this.desactivandoHoldeo = false;
        this.holdeoSeleccionado = null;
      });
  }

  goBack(){
    this.volver.emit(true);
  }

}
