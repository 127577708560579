import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Remito } from 'src/app/shared/models/facturacion/Remito';
import { ComprobanteService } from '../../comprobante.service';
import { Factura } from 'src/app/shared/models/facturacion/Factura';
import { UntypedFormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { EmitirFactura } from 'src/app/shared/models/facturacion/EmitirFactura';
import { Comprobante } from 'src/app/shared/models/facturacion/Comprobante';
import { ImpuestoPercepcion } from 'src/app/shared/models/facturacion/ImpuestoPercepcion';
import { VisorImpuestoComponent } from 'src/app/shared/visor-impuesto/visor-impuesto.component';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { TipoMoneda } from 'src/app/shared/enums/tipoMoneda';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { MessageService } from 'src/app/shell/message.service';
import { throwError } from 'rxjs/internal/observable/throwError';

@Component({
  selector: 'app-remitos-facturacion',
  templateUrl: './remitos-facturacion.component.html',
  styleUrls: ['./remitos-facturacion.component.css']
})
export class RemitosFacturacionComponent implements OnInit {
  @Input() remitosSeleccionados: Remito[];
  facturaCalculada: Factura[] = [];
  facturaEmitida: Factura[] = [];
  remitosId: string[] = [];
  columnas: any[] = [];
  efectivo: number;
  esDolar: boolean = false;
  monedaPalabra: string;
  obteniendoFactura: boolean = false;;
  string: string = '';
  facturando: boolean = false;
  elementType = 'url';
  calcFact: boolean = false;
  emitida: boolean = false;
  exportando: boolean = false;
  error: string;

  constructor(
    public modal: NgbModal, 
    public activeModal: NgbActiveModal, 
    private facturacionService: ComprobanteService, 
    private spinner: NgxSpinnerService, 
    private httpClient: HttpClient,
    private messageService:MessageService
  ) { }

  baseUrl = environment.apiServer.facturacionBaseUrl;
  private comprobanteUrl = this.baseUrl + '/ComprobantesV1';
  private exportarUrl = this.comprobanteUrl + '/Remitos/Exportar';
  facturaAEmitir = new UntypedFormGroup({});
  ngOnInit() {
    this.spinner.show('spinnerFactura');
    this.calcFact = true;
    for (let i = 0; i < this.remitosSeleccionados.length; i++) {
      this.remitosId.push(this.remitosSeleccionados[i].remitoId);
    }
    this.obtenerFacturaCalculada(this.remitosId);

    this.columnas = [
      { field: 'Codigo', header: 'Cod.' },
      { field: 'Detalle', header: 'Descripción' },
      { field: 'Cantidad', header: 'Cant.' },
      { field: 'PUnitario', header: 'Valor Unitario' },
      { field: 'Total', header: 'Total' },
    ];
    
  }


  clienteExento(factura: Factura) {
    if (factura.cliente.condicionIvaCodigoAfip == '02') {
      return true
    } else {
      return false
    }
  }

  obtenerPorcentaje(leyenda: string, factura: Factura): number{
    for(let reng of factura.renglones){
      for(let imp of reng.impuestosPercepciones){
        if(imp.leyenda == leyenda){
          return imp.valor;
        }
      }
    }
  }

  obtenerFacturaCalculada(remitos: string[]) {
    this.obteniendoFactura = true;
    this.facturacionService.obtenerFacturaCalculada(remitos)
      .subscribe(result => {
        if(result!=null && result.length>0) {
          this.facturaCalculada = result;
        }
        else{
          this.error = "Error al facturar: No se encontró precio para la factura seleccionada";
        }
        this.spinner.hide('spinnerFactura');
        this.obteniendoFactura = false;
      }, (err: ErrorModel)=> {
        if(err.description) {
          this.messageService.showErrorMessage(err.description)
        } else this.messageService.showErrorMessage("Hubo un error al calcular la factura. Por favor revise que tenga precios cargados y la cuenta del cliente.");
        this.spinner.hide('spinnerFactura');
        this.obteniendoFactura = false;
        this.activeModal.close();
      })
  }

  clienteDiscriminaImpuestos(factura: Factura) {
    if (factura.cliente.condicionIvaDiscrimina) {
      return true;
    } else {
      return false;
    }
  }

  aceptarFactura() {
    this.facturando = true;
    this.calcFact = false;
    let facturaId: EmitirFactura[] = [];
    for (let i = 0; this.facturaCalculada.length > i; i++) {
      facturaId.push(new EmitirFactura());
      facturaId[i].facturaId = this.facturaCalculada[i].id;
    }
    this.facturacionService.emitirFactura(facturaId, true)
      .subscribe(result => {
        this.facturaEmitida = result;
        this.emitida = true;
        this.facturando = false;
      },(err:ErrorModel) => {
        this.messageService.showErrorMessage('Error al emitir factura ' + err.description);
        this.facturando = false;
      }
    )
  }

  renglones(factura: Factura): string {
    this.string = '';
    for (let i = 0; factura.leyendas.length > i; i++) {
      if (factura.leyendas[i].frame == 0 && factura.leyendas[i].activo) {
        this.string = `${this.string}` + `${factura.leyendas[i].descripcion}` + '\n';
      }
    }
    return this.string;
  }

  imputacionFacturaRemito(facturaObtenida: Factura) {
    //Vuelve a la parte superior al comprobante.
    this.exportando = true;
    let ele = document.getElementById('facturaPrint');
    ele.scrollTop = 0;
    ele.scrollLeft = 0;
    this.importarFacturaremitos(facturaObtenida.prefijo, facturaObtenida.numeroComprobante, facturaObtenida.tipoMovimiento);
  }

  verImpuestos(facturaObtenida: Factura) {
    let impuestos: ImpuestoPercepcion[] = [];
    facturaObtenida.renglones.forEach((value, index) => {
      facturaObtenida.renglones[index].impuestosPercepciones.forEach((value2, index2) => {
        impuestos.push(facturaObtenida.renglones[index].impuestosPercepciones[index2]);
      });
    });
    let modalRef = this.modal.open(VisorImpuestoComponent, { centered: true, backdrop: 'static' });
    modalRef.componentInstance.impuestos = impuestos;
    modalRef.result.then(result => {
      if (result != true) {
        this.activeModal.close();
      }
    })
  }

  importarFacturaremitos(prefijo: number, numero: number, tipoMovimiento: number) {
    this.spinner.show('spinnerExportar');
    const url = `${this.exportarUrl}?prefijo=${prefijo.toString()}&numero=${numero.toString()}&tipoMovimiento=${tipoMovimiento.toString()}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });
    // Process the file downloaded
    this.httpClient.get(url, { headers: headers, responseType: 'blob' as 'json' })
      .subscribe((res: Blob) => {
        const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
        saveAs(blob, "factura" + prefijo + "-" + numero + ".xlsx");
        this.exportando = false;
        this.spinner.hide('spinnerExportar');
      });

  }
}
