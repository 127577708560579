import { Guid } from 'guid-typescript';
import { ProductoAjusteManual } from './ProductoAjusteManual';

export class AjusteManual{
    contenedorId: Guid;
    codigoAeroplanta: string;
    descripcion: string;
    nroRemitoCargamento: string;
    aforador: number;
    productosDeposito: ProductoAjusteManual[] = [];
}