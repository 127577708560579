import { AerovaleDetalleAlije } from './AerovaleDetalleAlije';

export class AerovaleDetalleAerocombustible {
	vuelo: string;
	tipoVuelo: number;
	destino: string;
	temperatura: number;
	densidad: number;
	pruebaAgua: boolean;
	rancho: string;
	aforadorInicio: number;
  aforadorFinal: number;
  aforadorInicialEditado: boolean;
  aforadorFinalEditado: boolean;
  volumen15Grados: number;
  esAlije: boolean;
  esGranel: boolean;
  recarga: boolean;
  pedidoId: string;
  aerovaleDetalleAlije: AerovaleDetalleAlije;
  codigoAutorizacion: string;
  codigoAeropuerto: string;
  nombreCiudadAeropuerto: string;
  esDevolucion: boolean;
  presionPuntaManguera: number;
  presionDiferencial: number;
  caudalObservado: number;
  codigoTanque: string;
  picoId: string; 
}
