import { Directive, ElementRef, HostListener, inject } from '@angular/core';

@Directive({
  selector: '[appOnlyNumber]'
})
export class OnlyNumberDirective {

  er = inject(ElementRef);

  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent) {
    if (['Delete', 'Backspace', 'Tab', 'Escape', 'Enter'].indexOf(e.key) !== -1 ||
        // Permitir Ctrl+A
        (e.key === 'a' && e.ctrlKey === true) ||
        // Permitir Ctrl+C
        (e.key === 'c' && e.ctrlKey === true) ||
        // Permitir Ctrl+V
        (e.key === 'v' && e.ctrlKey === true) ||
        // Permitir Ctrl+X
        (e.key === 'x' && e.ctrlKey === true)) {
      return;
    }

    // Asegurarse de que solo se ingresen números
    if ((e.key === '-' && this.er.nativeElement.value.includes('-')) ||
        (isNaN(Number(e.key)) && !['-', 'ArrowLeft', 'ArrowRight'].includes(e.key))) {
      e.preventDefault();
    }
  }

}
