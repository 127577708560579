<div class="modal-header">Nueva configuración</div>
<div class="modal-body">
    <div>Seleccione una provincia:</div>
    <select [disabled]="obteniendoProvincias" class="form-select" [(ngModel)]="provinciaSeleccionada">
        <option *ngFor="let provincia of provincias" [ngValue]="provincia">{{provincia.nombre}}</option>
    </select>
    <loading-text-spinner textColor="dark" color="primary" *ngIf="obteniendoProvincias" message="Obteniendo provincias"></loading-text-spinner>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" [disabled]="!provinciaSeleccionada" (click)="onCrearClicked()">
        Agregar provincia</button>
    <button type="button" class="btn btn-secondary" (click)="onVolverClicked()">Volver</button>
</div>