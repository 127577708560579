export enum ActividadCliente {
    OTRO,
    EESS,
    Mayorista,
    Distribuidor,
    TransporteCarga,
    TransportePasajeros,
    SectorPrimario,
    SectorIndustrial,
    CooperativaElectrica,
    RedXXI
}