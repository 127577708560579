<nav class="navbar navbar-light bg-light navbar-expand-lg ">
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <div class="form-inline me-2">
      <input id="buscadorFormControl" [formControl]="buscadorFormControl" type="text" class="form-control"
        (input)="filtrarClientes()" placeholder="Razon social/cuenta/cuit" />
    </div>
  </div>
</nav>
<div class="row">
  <div class="col col-3">
    <div class="list-group py-3 menu-izq">

      <div class="col col-md-12">
        <div class="spinner-container" *ngIf="cargandoClientes">
          <ngx-spinner name="spinnerGrafico" bdOpacity=0.3bd
            Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
            <p style="color: white">C a r g a n d o . . . </p>
          </ngx-spinner>          
        </div>
      </div>
      <div *ngIf="!cargandoClientes">
        <div *ngFor="let cli of clientesFiltrados; let i=index">
          <button type="button" [class.active]="" class="list-group-item list-group-item-action"
            (click)="mostrarDetalle(i)">
            <div class="d-flex w-100 justify-content-between">
              <p>{{ cli.razonSocial }} </p>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="col col-9 py-3">

    <div class="col col-md-12">
      <div class="spinner-container" *ngIf="cargandoModificacionesClientes">
        <div class="mb-3" style="height: 10cm;">
          <ngx-spinner name="spinnerCargaCambios" bdOpacity=0.3bd
            Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
            <p style="color: white">C a r g a n d o . . . </p>
          </ngx-spinner>
        </div>
      </div>
    </div>   

    <div *ngIf="!cargandoModificacionesClientes">
      <div *ngIf="modificacionesCliente != null">

        <div class="row">
          <div class="col-md-6 titulo" *ngIf="modificacionesCliente.estadoCambio == EstadoCambio.Nuevo">Alta de cliente pendiente de <b>Aprobar</b></div>
          <div class="col-md-6 titulo" *ngIf="modificacionesCliente.estadoCambio != EstadoCambio.Nuevo">Modificaciones en cliente pendientes para <b>Aprobar</b></div>
          <div class="col-md-6" *ngIf="guardandoDatos" style="padding-left: 4em;">
            <loading-text-spinner message="Guardando datos..." color="primary"></loading-text-spinner>
          </div>   
        </div>

        <div *ngIf="modificacionesCliente.estadoCambio == EstadoCambio.Nuevo">
          <div class="row botones-aprobacion">
            <div class="col-md-3 offset-md-6"><button type="button" (click)="procesarNuevoCliente(EstadoModificacion.Rechazado)" class="btn btn-danger">Rechazar Alta de cliente</button></div>
            <div class="col-md-3"><button type="button" (click)="procesarNuevoCliente(EstadoModificacion.Aprobado)" class="btn btn-primary"> Aprobar Alta de cliente</button></div>            
          </div>
          
          <div *ngIf="modificacionesCliente.archivosAdjuntos && modificacionesCliente.archivosAdjuntos.length > 0">
            <div class="col-md-12 bloque-datos" style="font-weight: bold; margin-top: 0.75em">
              Listado de adjuntos:
            </div>
  
            <div class="col-md-11 offset-md-1 bloque-datos" style="padding-top: 0em">
              <div style="margin-top: 0.5em;" *ngFor="let archivo of modificacionesCliente.archivosAdjuntos"><a href="{{archivo}}"><i class="fa fa-cloud-download" aria-hidden="true"></i> {{archivo}}</a></div>
            </div>
          </div>          

          <div class="col-md-10 offset-md-1">
            <app-datos-nuevo-cliente [modificacionesCliente]="modificacionesCliente"></app-datos-nuevo-cliente>                        
          </div>
        </div>
        
        <div *ngIf="modificacionesCliente.estadoCambio != EstadoCambio.Nuevo">

          <div class="row botones-aprobacion">
            <div class="col-md-4 offset-md-4 titulo"><button type="button" (click)="guardarSeleccion(EstadoModificacion.Rechazado)" class="btn btn-danger">Rechazar cambios seleccionados</button></div>
            <div class="col-md-4 titulo"><button type="button" (click)="guardarSeleccion(EstadoModificacion.Aprobado)" class="btn btn-primary"> Aprobar cambios seleccionados</button></div>            
          </div>
          
          <div *ngIf="modificacionesCliente.archivosAdjuntos && modificacionesCliente.archivosAdjuntos.length > 0">
            <div class="col-md-12 bloque-datos" style="font-weight: bold; margin-top: 0.75em">
              Listado de adjuntos:
            </div>
  
            <div class="col-md-11 offset-md-1 bloque-datos" style="padding-top: 0em">
              <div style="margin-top: 0.5em;" *ngFor="let archivo of modificacionesCliente.archivosAdjuntos"><a href="{{archivo}}"><i class="fa fa-cloud-download" aria-hidden="true"></i> {{archivo}}</a></div>
            </div>
          </div>          

          <div  class="col-md-12 bloque-datos">
            <b>Motivos de modificacion:</b>
            <br/>
            <div id="detalle-motivo-modificacion">
              {{ modificacionesCliente.detalle }}
            </div>            
          </div>

          <div class="row col-md-11 offset-md-1 cliente">          
            <div class="col-md-7  titulo">Cliente: {{modificacionesCliente.clienteNuevo.razonSocial}} ({{modificacionesCliente.clienteNuevo.numeroDocumento}})</div>       

            <div class="col-md-5" *ngIf="modificacionesCliente.estadoCambio == EstadoCambio.NoHayCambios">
                No hay cambios en información del cliente.
            </div>

            <div class="row col-md-5" *ngIf="modificacionesCliente.estadoCambio == EstadoCambio.Modifico">              
              <div class="col-md-10 text-end"><button type="button" (click)="mostrarOcultarModificacionesCliente()" class="btn btn-primary btn-sm">{{mostrarModificacionesCliente ? "Ocultar" : "Ver"}} modificaciones</button></div>
              <label class="checkbox-inline col-md-1"> <input (click)="onClickCliente(modificacionesCliente.id)" type="checkbox" value="modificacionesCliente.id" id="modificacionesCliente.id"></label>
            </div>

            <div *ngIf="mostrarModificacionesCliente" class="row col-md-9 offset-md-2 contenedor-modificacion">
              <table id="modificacion-cliente" class="table">
                <tr>
                  <th class="">Datos viejos</th>
                  <th class="">Datos nuevos</th>
                </tr>
                <tr *ngFor="let m of obtenerModificacionesCliente()">
                  <td class="" [innerHTML]="m.valorViejo"></td>
                  <td class="" [innerHTML]="m.valorNuevo"></td>
                </tr>
              </table>
            </div>
          </div>
          
          <div class="row col-md-11 offset-md-1" *ngFor="let modifCuenta of modificacionesCliente.modificacionesCuenta">
            <div *ngIf="modifCuenta != null" class=" row col-md-12 cuenta">
              <div class="col-md-6 offset-md-1">Cuenta: {{ modifCuenta.cuentaNuevo.ctA_SGC}} - {{ modifCuenta.cuentaNuevo.descripcion}}</div>    
              <div class="row col-md-5">
                <div class="col-md-10 text-end"><button type="button" (click)="mostrarOcultarModificacionesCuenta(modifCuenta.id)" class="btn btn-primary btn-sm">{{mostrarModificacionesCuenta(modifCuenta.id) ? "Ocultar" : "Ver"}}  modificaciones</button></div>
                <label class="checkbox-inline col-md-2"> <input (click)="onClickCuenta(modifCuenta.id)" type="checkbox" value="modifCuenta.id" id="modifCuenta.id"></label>
              </div>  
              <div *ngIf="modifCuenta != null && mostrarModificacionesCuenta(modifCuenta.id)" class="row col-md-9 offset-md-2 contenedor-modificacion">
                <table id="modificacion-cliente" class="table">
                  <tr>
                    <th class="">Datos viejos</th>
                    <th class="">Datos nuevos</th>
                  </tr>
                  <tr *ngFor="let c of modifCuenta.cambios">
                    <td class="" [innerHTML]="c.valorViejo"></td>
                    <td class="" [innerHTML]="c.valorNuevo"></td>
                  </tr>
                </table>
              </div>  
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>