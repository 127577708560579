import { Component, OnInit, EventEmitter, Output, Input, ViewChild, forwardRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl, NG_VALUE_ACCESSOR, FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { TipoVuelo } from 'src/app/shared/enums/tipoVuelo';
import { Aeropuerto } from 'src/app/shared/models/despacho/Aeropuerto';
import { TipoPago } from 'src/app/shared/enums/tipoPago';
import { UnidadMedida } from 'src/app/shared/enums/unidadMedida';
import { Cliente } from 'src/app/shared/models/despacho/Cliente';
import { Aeronave } from 'src/app/shared/models/despacho/Aeronave';
import { ModalConfirmComponent } from '../../shared/generic-modals/modal-confirm.component';
import { NuevoClienteComponent } from '../../shared/nuevo-cliente/nuevo-cliente.component';
import { isNullOrUndefined } from 'src/app/shared/compat-tools';
import { NgbModal, NgbDateStruct, NgbDatepickerConfig, NgbCalendar, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Abastecedora } from 'src/app/shared/models/despacho/Abastecedora';
import { AerovaleAerocombustible } from 'src/app/shared/models/despacho/AerovaleAerocombustible';
import { UsuarioService } from 'src/app/account/usuario.service';
import { PedidoService } from '../pedido.service';
import { AeropuertoService } from '../aeropuerto.service';
import { MessageService } from 'src/app/shell/message.service';
import { Rancho } from 'src/app/shared/models/ranchos/Rancho';
import { RanchoService } from 'src/app/rancho/rancho.service';
import { Observacion } from 'src/app/shared/models/despacho/Observacion';
import { Guid } from 'guid-typescript';
import { ResultadoPruebaDeAgua } from 'src/app/shared/enums/resultadoPruebaDeAgua';
import { Operador } from 'src/app/shared/models/despacho/Operador';
import { SurtidorService } from 'src/app/stock/surtidor.service';
import { Surtidor } from 'src/app/shared/models/stock/Surtidor';
import { UsuarioAerovale } from 'src/app/shared/models/despacho/UsuarioAerovale';
import { CuentaCliente } from 'src/app/shared/models/despacho/CuentaCliente';
import { Aerocombustible } from 'src/app/shared/models/despacho/Aerocombustible';
import { TipoPico } from 'src/app/shared/enums/tipoPico';
import { ProductoService } from 'src/app/abm/producto/producto.service';
import { Producto } from 'src/app/shared/models/despacho/Producto';
import { SharedService } from 'src/app/shared/shared.service';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { NgxSpinnerService } from 'ngx-spinner';
import { CalculosService } from 'src/app/shared/calculos.service';
import { AerovalesService } from '../aerovales.service'; 
import { TipoCuenta } from 'src/app/shared/enums/tipoCuenta';
import { Pedido } from 'src/app/shared/models/despacho/Pedido';
import { PedidoDetalle } from 'src/app/shared/models/despacho/PedidoDetalle';
import { Precarga } from 'src/app/shared/models/despacho/Precarga';
import { ClienteService } from 'src/app/cliente/cliente.service';
import { VehiculoService } from 'src/app/cliente/vehiculo.service';
import { TipoVehiculo } from 'src/app/shared/enums/tipoVehiculo';
import { VehiculoDTO } from 'src/app/shared/models/cliente/VehiculoDTO';
import { ModalVehiculoComponent } from 'src/app/management/matriculas/abm-matriculas/modal-vehiculo/modal-vehiculo.component';
import { TipoExpendedora } from 'src/app/shared/enums/tipoExpendedora';
@Component({
  selector: 'app-nuevo-aerovale',
  templateUrl: './nuevo-aerovale.component.html',
  styleUrls: ['./nuevo-aerovale.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NuevoAerovaleComponent),
      multi: true,
    }
  ]
})
export class NuevoAerovaleComponent implements OnInit {

  @Output() cancelado = new EventEmitter();
  @Output() nuevoAerovaleCreado = new EventEmitter();  
  @Input() pedidoDevolucion: PedidoDetalle;

  ciudadAerovaleSeleccionada: string;

  enumResultadoPruebaDeAgua = ResultadoPruebaDeAgua;

  procesandoFirmaCliente: boolean;
  procesandoFirmaOperador: boolean;

  surtidores: Surtidor;
  obteniendoTanquesSurtidor: boolean = false;
  esDensidadMin: any;
  esDensidadMax: any;

  archivoDeDensidad: any;
  clientes: CuentaCliente[];
  operadores: Operador[];
  //aeronavesCliente: Vehiculo[];
  aeronavesCliente: VehiculoDTO[];
  abastecedoras: Abastecedora[];
  aeropuertos: Aeropuerto[];
  aerocombustibles: Producto[];
  cargandoAeropuertos: boolean;
  tipoPago = TipoPago;
  TipoVuelo = TipoVuelo;
  unidadMedida = UnidadMedida;
  firmasForm = new UntypedFormGroup({
    firmaClienteFormControl: new UntypedFormControl(null),
    // firmaOperadorFormControl: new FormControl(null)
  });
  aerovalesForm = new UntypedFormGroup({
    numeroAerovaleFormControl: new UntypedFormControl(null, [
      Validators.required,
      this.nroAerovaleValidator
    ]),
    clienteFormControl: new UntypedFormControl(null, Validators.required),
    aeronaveFormControl: new UntypedFormControl(null, Validators.required),//, { validators: [Validators.required, this.existeAeronave] }),
    aerocombustibleFormControl: new UntypedFormControl(null),
    abastecedoraFormControl: new UntypedFormControl({ value: null }, Validators.required),
    numeroVueloFormControl: new UntypedFormControl(null, [
      Validators.required,
      Validators.minLength(3),
      Validators.pattern('(([A-Za-z]{2}|[A-Za-z]\\d|\\d[A-Za-z])([1-9]|\\s)(\\d{1,4}[A-Za-z]?)|(((\W|^)FFAA(\W|$))|((\W|^)MANT(\W|$))|((\W|^)mant(\W|$))|((\W|^)GOB(\W|$))|((\W|^)PRIV(\W|$))|((\W|^)SANI(\W|$))|((\W|^)ffaa(\W|$))|((\W|^)gob(\W|$))|((\W|^)priv(\W|$))|((\W|^)sani(\W|$))|((\W|^)YPF(\W|$))|((\W|^)ypf(\W|$))))')
    ]),
    temperaturaFormControl: new UntypedFormControl(null, [Validators.required, this.temperaturaValidator]),
    densidadFormControl: new UntypedFormControl(null, [Validators.required]),
    fechaLlegadaFormControl: new UntypedFormControl(null, [Validators.required]),
    horaLlegadaFormControl: new UntypedFormControl(null, [Validators.required]),
    fechaInicioDespachoFormControl: new UntypedFormControl(null, [Validators.required]),
    horaInicioDespachoFormControl: new UntypedFormControl(null, [Validators.required]),
    fechaFinDespachoFormControl: new UntypedFormControl(null, [Validators.required]),
    horaFinDespachoFormControl: new UntypedFormControl(null, [Validators.required]),
    posicionFormControl: new UntypedFormControl(null),
    tipoVueloFormControl: new UntypedFormControl(null),
    destinoFormControl: new UntypedFormControl(null, [Validators.nullValidator]),
    tipoPagoFormControl: new UntypedFormControl(null),
    unidadMedidaFormControl: new UntypedFormControl(null, Validators.required),
    pruebaDeAguaFormControl: new UntypedFormControl(null, [Validators.required, this.pruebaDeAguaValidator]),
    aforadorInicialFormControl: new UntypedFormControl(null, Validators.required),
    aforadorFinalFormControl: new UntypedFormControl(null, [Validators.required, Validators.min(1)]),
    volumenDespachadoFormControl: new UntypedFormControl(null),
    volumen15FormControl: new UntypedFormControl(null),
    recargaFormControl: new UntypedFormControl(null),
    granelFormControl: new UntypedFormControl(null),
    observacionFormControl: new UntypedFormControl(null),
    comentariosFormControl: new UntypedFormControl(null),
    operadorFormControl: new UntypedFormControl(null, Validators.required),
    presionMangueraFormControl: new UntypedFormControl(null),
    presionDiferencialFormControl: new UntypedFormControl(null),
    caudalFormControl: new UntypedFormControl(null),
    tanquesAsociadosFormControl: new UntypedFormControl(null),
    picosAsociadosFormControl: new UntypedFormControl(null, Validators.required),
    tipoPicoFormControl: new FormControl(<string|null>(null), Validators.required),
  });
  
  cargandoClientes: boolean;
  errorClientes: boolean;
  cargandoOperadores: boolean;
  errorOperadores: boolean;
  provinciaFlag: boolean = true;
  ciudadFlag: boolean = true;
  cargandoAerocombustibles: boolean;
  errorAerocombustibles: boolean;
  aeronaves: any;
  cargandoAeronaves: boolean = true;
  errorAeronaves: boolean;
  cargandoAeronavesCliente: boolean;
  cargandoAbastecedoras: boolean;
  errorAeronavesCliente: boolean;
  errorDestinos: boolean;
  cargandoRancho: boolean;
  rancho: Rancho;
  cargandoAutorizacion: any;
  autorizacionesService: any;
  autorizacionTrader: any;
  nombreArchivoFirmaCliente: string;
  nombreArchivoFirmaOperador: string;
  error: string;
  fileUpload = { status: '', message: '', filePath: '', length: 0 };
  archivoCargado: boolean = false;
  cargadoCorrectamente = false;
  cargando: boolean = false;
  modelLlegada: NgbDateStruct;
  modelCliente: any;
  modelOperador: any;
  modelInicioDespacho: NgbDateStruct;
  modelFinDespacho: NgbDateStruct;
  guardandoAerovale: boolean;
  observaciones: Observacion[];
  timeVuelo: any;
  time: any;
  timeFin: any;
  destinovalid: boolean;

  fileFirmaCliente: any;
  fileFirmaOperador: any;

  change$: Subscription;
  aeroplantaID: Guid;

  productos: Producto[] = [];
  productoSeleccionado: Producto;

  esAerovaleGranel: boolean = false;
  clienteNoEditable = false;
  combustibleNoCoincide: boolean = false;
  TipoExpendedora = TipoExpendedora;

  aerovaleCreadoCorrectamente: boolean = false;

  constructor(private datepickerConfig: NgbDatepickerConfig, 
    private spinner: NgxSpinnerService,
    private calendar: NgbCalendar, 
    private aerovaleService: AerovalesService,
    private pedidoService: PedidoService, 
    private clienteService: ClienteService,
    private ranchoService: RanchoService, 
    private aeropuertoService: AeropuertoService, 
    private messageService: MessageService, 
    private usuarioService: UsuarioService, 
    private modalService: NgbModal, 
    private surtidorService: SurtidorService, 
    private productoService: ProductoService, 
    private sharedService: SharedService, 
    private calculoService: CalculosService,
    private modal: NgbActiveModal, 
    private vehiculosService: VehiculoService) { 
      this.change$ = sharedService.aeroplantaSeleccionada.subscribe(_=>{ 
        this.recargarPagina()
      });
    }


  // hooks

  ngOnInit() {
    this.getProductos();
    this.aerovalesForm.setValidators(this.aerovaleValidator);

    this.getAbastecedoras();
    var horaActual = new Date();
    this.horaInicio.setValue(horaActual.getUTCHours());
    this.horaFin.setValue(horaActual.getUTCHours());
    this.horaLlegada.setValue(horaActual);

    this.procesandoFirmaCliente = false;
    this.procesandoFirmaOperador = false;

    this.archivoDeDensidad = this.leerArchivo();
    this.volumenDespachado.setValue(0);
    this.aforadorInicial.setValue(0);
    this.aforadorFinal.setValue(0);
    this.tipoDePago.setValue(1);
    this.guardandoAerovale = false;
    this.modelInicioDespacho = this.calendar.getToday();
    this.modelFinDespacho = this.calendar.getToday();
    this.modelLlegada = this.calendar.getToday();

    var hora = new Date(horaActual.setMinutes(horaActual.getMinutes()));
    this.time = { hour: hora.getHours(), minute: hora.getMinutes(), second: hora.getSeconds() };
    this.timeFin = { hour: hora.getHours(), minute: hora.getMinutes(), second: hora.getSeconds() };
    this.timeVuelo = { hour: horaActual.getHours(), minute: horaActual.getMinutes(), second: horaActual.getSeconds() };
    this.getClientes();
    //this.getOperadores();
    this.getOperadoresConRoles();
    this.getAeronaves();
    this.getAerocombustibles();
    this.getAeropuertos();
    this.obtenerObservacionesDespacho();
    this.tipoVuelo.setValue(1);
    this.pruebaDeAgua.setValue(0);
    this.recarga.setValue(0);
    this.granelFormControl.setValue(0);
    this.unidadDeMedida.setValue(1);
    let inicio: any = { day: 1, month: 1, year: 2019 };
    this.datepickerConfig.minDate = inicio;
    let fin: any = { day: this.modelInicioDespacho.day, month: this.modelInicioDespacho.month, year: this.modelInicioDespacho.year };
    this.datepickerConfig.maxDate = fin;
    this.observacion.setValue("");

  }

  ngOnDestroy() {
    this.change$.unsubscribe();
  }
  
  recargarPagina() {
    this.aerovalesForm.reset();
    this.ngOnInit();
  }

  accionarForm() {
    Object.keys(this.aerovalesForm.controls).forEach(key => {
      this.aerovalesForm.get(key).markAsDirty();
    });
  }

  usuarioCambioEsGranel(esGranel: boolean) {   
    if (this.aerovalesForm) { 
      if (esGranel) {
        this.aerovalesForm.controls.numeroVueloFormControl.clearValidators();
        this.aerovalesForm.controls.aeronaveFormControl.clearValidators();
      } else {
        this.aerovalesForm.controls.numeroVueloFormControl.setValidators(
          [
            Validators.required,
            Validators.minLength(3),
            Validators.pattern('(([A-Za-z]{2}|[A-Za-z]\\d|\\d[A-Za-z])([1-9]|\\s)(\\d{1,4}[A-Za-z]?)|((\W|^)MANT(\W|$))|((\W|^)mant(\W|$))|(((\W|^)FFAA(\W|$))|((\W|^)GOB(\W|$))|((\W|^)PRIV(\W|$))|((\W|^)SANI(\W|$))|((\W|^)ffaa(\W|$))|((\W|^)gob(\W|$))|((\W|^)priv(\W|$))|((\W|^)sani(\W|$))|((\W|^)YPF(\W|$))|((\W|^)ypf(\W|$))))')
          ]
        );
        this.aerovalesForm.controls.aeronaveFormControl.setValidators(Validators.required);        
      }
      this.aerovalesForm.controls.numeroVueloFormControl.updateValueAndValidity();
      this.aerovalesForm.controls.aeronaveFormControl.updateValueAndValidity();
    }
  }
 
  aerovaleValidator(group: UntypedFormGroup): any {
    if (group) {
      let aforadoInicial = group.get("aforadorInicialFormControl").value;
      let aforadorFinal = group.get("aforadorFinalFormControl").value;

      if (aforadoInicial != null && aforadorFinal != null) {
        if (aforadoInicial >= aforadorFinal) {
          return { aforadorInicialMayorAforadorFinal: true };
        }
      }

      var ahora = new Date();

      let fechaLlegada = group.get("fechaLlegadaFormControl").value;
      let horaLlegada = group.get("horaLlegadaFormControl").value;

      if (fechaLlegada && horaLlegada) {
        var fecha = new Date(fechaLlegada.year, fechaLlegada.month - 1, fechaLlegada.day, horaLlegada.hour, horaLlegada.minute);
        var ahora = new Date();
        if (fecha > ahora) {
          return { fechaLlegadaMayorAhora: true };
        }
      }

      let fechaInicio = group.get("fechaInicioDespachoFormControl").value;
      let horaInicio = group.get("horaInicioDespachoFormControl").value;

      if (fechaInicio && horaInicio) {
        var fecha = new Date(fechaInicio.year, fechaInicio.month - 1, fechaInicio.day, horaInicio.hour, horaInicio.minute);
        if (fecha > ahora) {
          return { fechaInicioMayorAhora: true };
        }
      }

      let fechaFin = group.get("fechaFinDespachoFormControl").value;
      let horaFin = group.get("horaFinDespachoFormControl").value;

      if (fechaFin && horaFin) {
        var fecha = new Date(fechaFin.year, fechaFin.month - 1, fechaFin.day, horaFin.hour, horaFin.minute);
        if (fecha > ahora) {
          return { fechaFinMayorAhora: true };
        }
      }

      if (fechaInicio && horaInicio && fechaFin && horaFin) {
        var inicio = new Date(fechaInicio.year, fechaInicio.month - 1, fechaInicio.day, horaInicio.hour, horaInicio.minute);
        var fin = new Date(fechaFin.year, fechaFin.month - 1, fechaFin.day, horaFin.hour, horaFin.minute);
        if (inicio >= fin) {
          return { fechaInicioMayorFin: true };
        }
      }

      return null;
    }
  }

  temperaturaValidator(control: UntypedFormControl) {
    var temperatura = control.value;

    if (temperatura > 100) {
      return { temperaturaFueraDeRango: true };
    }

    if (temperatura <= -50) {
      return { temperaturaFueraDeRango: true };
    }

    return null;
  }

  pruebaDeAguaValidator(control: UntypedFormControl) {
    if (control.value == ResultadoPruebaDeAgua.Negativa) {
      return null;
    }

    return { negativoRequired: true };
  }

  /**
 * Agrega un error de validación en el AbstractControl y lo marca como Dirty
 */
  addValidationError(control: AbstractControl, validationName: string) {
    let errors = control.errors;
    if (errors == null)
      errors = { [validationName]: true };
    else
      errors[validationName] = true;
    control.setErrors(errors);
    control.markAsDirty();
  }

  nroAerovaleValidator(control: AbstractControl) {
    if (control.value) {
      let nroCompleto = control.value;
      nroCompleto = nroCompleto.padStart(8, '0');
      let prefijo = nroCompleto.substring(0, 3);

      if (prefijo >= 200) {
        return { nroAerovaleFueraDeRango: true };
      }
    }

    return null;
  }

  horaLlegadaValida() {
    if (this.horaLlegada.value != null) {
      return (this.horaLlegada.value.hour >= 0 && this.horaLlegada.value.hour <= 23);
    }
    else {
      return false;
    }
  }
  minutosLlegadaValida() {
    if (this.horaLlegada.value != null) {
      return (this.horaLlegada.value.minute >= 0 && this.horaLlegada.value.minute <= 60);
    }
    else {
      return false;
    }
  }

  fechaInicioMenorIgualActual(): boolean {
    if (this.horaInicio.value != null) {
      let seleccionada: Date = new Date(this.fechaInicio.value.year, this.fechaInicio.value.month - 1,
        this.fechaInicio.value.day, this.horaInicio.value.hour, this.horaInicio.value.minute);
      let actual: Date = new Date();
      return (seleccionada <= actual);
    }
    else { return false; }
  }
  horaInicioValida() {
    if (this.horaInicio.value != null) {
      return (this.horaInicio.value.hour >= 0 && this.horaInicio.value.hour <= 23);
    }
    else { return false; }
  }
  minutosInicioValida() {
    if (this.horaInicio.value != null) {
      return (this.horaInicio.value.minute >= 0 && this.horaInicio.value.minute <= 60);
    }
    else { return false; }
  }

  fechaFinMenorIgualActual(): boolean {
    if (this.horaFin.value != null) {
      let seleccionada: Date = new Date(this.fechaFin.value.year, this.fechaFin.value.month - 1,
        this.fechaFin.value.day, this.horaFin.value.hour, this.horaFin.value.minute);
      let actual: Date = new Date();
      return (seleccionada <= actual);
    }
    else {
      return false;
    }
  }

  horaFinValida() {
    if (this.horaFin.value != null) {
      return (this.horaFin.value.hour >= 0 && this.horaFin.value.hour <= 23);
    }
    else {
      return false;
    }

  }

  minutosFinValida() {
    if (this.horaFin.value != null) {
      return (this.horaFin.value.minute >= 0 && this.horaFin.value.minute <= 60);
    }
    else { return false; }
  }

  fechaFinMayorIgualFechaInicio(): boolean {
    let ffin: Date = new Date(this.fechaFin.value.year, this.fechaFin.value.month - 1,
      this.fechaFin.value.day, this.horaFin.value.hour, this.horaFin.value.minute);
    let finicio: Date = new Date(this.fechaInicio.value.year, this.fechaInicio.value.month - 1,
      this.fechaInicio.value.day, this.horaInicio.value.hour, this.horaInicio.value.minute);
    return (ffin >= finicio);
  }

  controlVehiculoProducto() {
    if (this.abastecedora.value instanceof Object && this.aeronave.value instanceof Object) {      
      if(this.aeronave.value.codigoProducto != this.abastecedora.value.producto.codigoProducto)
        this.combustibleNoCoincide = true;
      else 
        this.combustibleNoCoincide = false;
    }
  }

  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : (event.charCode >= 48 && event.charCode <= 57);
  }

  onlyDecimalPositivo(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46;
  }

  cantidadDeCaracteres(n: any): number {
    if (n != null) {
      return n.length;
    }
    return 0;
  }

  esCampoVacio(campo: any): boolean {

    return (campo == null || (campo != null && campo.length == 0));
  }

  // form

  changeDestino(): void {
    if (typeof (this.destino.value) === 'string') {
      this.destino.setValue(null);
      this.destinovalid = true;
    } else {
      this.destinovalid = false;
    }
  }
  vaciarDestino(): void{
    //Vacía el campo Destino cuando se cambia de Tipo de Vuelo
    this.destino.setValue(null);
    this.rancho = null;
    this.destinovalid = true;
  }
  verificarRancho(){
    if (this.destino.value === ''){
      this.rancho = null;
    }
  }
  //setea el aerocombustible de acuerdo a la aeronave seleccionada
  changeAeronave(): void {
    //Esto es por si ingresan el valor abc123 pero existe una aeronave ABC123, entonces elige esta última
    if (typeof (this.modelAeronave) === 'string') {
      let aeronaveBuscadaPorMatriculaTexto = this.aeronaves.find(p => p.matricula.toLowerCase().trim() == this.modelAeronave.toLowerCase().trim());
      if (!aeronaveBuscadaPorMatriculaTexto) {
        this.nuevoAeronave(this.modelAeronave as string);
        return;
      } else {
        this.aeronave.setValue(aeronaveBuscadaPorMatriculaTexto);
      }
    }

    this.verificarAeronave();    
    this.getAutorizacionTrader();
  }

  selectAeronave(e: any): void {
    this.aeronave.setValue(e.item);
    this.controlVehiculoProducto();
    if (typeof (e.item) === 'string') {
      this.changeAeronave();
    }
  }


  //#region Validación de aeronave
  existeAeronave(control: AbstractControl): { [key: string]: any } | null {
    if (control.value == null || control.value.length === 0) {
      return null; // don't validate empty values to allow optional controls
    }
    const esVehiculo = control.value instanceof Object;
    return control.valid && esVehiculo ? null : { 'existeAeronave': { value: control.value } };
  }


  nuevoAeronave(aeronaveText: any): void {    
    const modalRef = this.modalService.open(ModalVehiculoComponent, { backdrop: "static", centered: true });
    if (typeof (aeronaveText) === 'string') {
      modalRef.componentInstance.matriculaIngresada = aeronaveText.toUpperCase();
    }
    modalRef.componentInstance.tipoVehiculoParaFiltrar = TipoVehiculo.Aeronave;
    modalRef.componentInstance.vehiculos = this.aeronaves;
    modalRef.result.then((vehiculo) => {
      if(vehiculo) {
        this.messageService.showSuccessMessage("Vehiculo creado");
        this.aeronaves.push(vehiculo);
        this.aeronave.setValue(vehiculo);  
      }    
    });
  }

  nuevoCliente(clientText): void {
    const modalRef = this.modalService.open(NuevoClienteComponent);
    if (typeof (clientText) === 'string') {
      modalRef.componentInstance.nombreCliente = clientText; // should be the id
    }
    modalRef.result.then((cliente) => {
      if (cliente) {
        this.clientes.push(cliente);
        this.cliente.setValue(cliente);        
      }      
    }).catch((error) => {
      this.cliente.setValue(null);
    });
  }

  selectCustomer(e: any): void {
    this.cliente.setValue(e.item);
    if (e.item.esTrader) {
      this.aeronavesCliente = [];
    } else {
      this.tipoDePago.setValue(this.cliente.value.tipoCuenta);
      this.getAeronavesByCliente((e.item as unknown as CuentaCliente));
    }
    this.getRancho();
    this.getAutorizacionTrader();
  }

  confirmCustomer(): void {
    if (typeof (this.cliente.value) === 'string') {
      this.nuevoCliente(this.cliente.value);
    }
  }

  selectOperador(event: any): void {
    this.operadorFormControl.setValue(event.item);
  }

  selectAeropuerto(e: any): void {
    this.destino.setValue(e.item);
    this.getRancho();    
  }

  cancelar() {
    if(this.pedidoDevolucion){
      this.modalService.dismissAll();
    }else{
      this.cancelado.emit();
    }    
  }

  // llamadas al service

  getProductos() {
    this.productoService.obtenerProductos()
      .subscribe(result => {
        this.productos = result;
      }, () => {

      });
  }

  async crearAerovale() {
    try{
      this.messageService.clear();

      this.aerovaleCreadoCorrectamente = false;
      
      //Si pasa un minuto se esconde el cartel azul (spinner)
      setTimeout(() => {
        this.spinner.hide();
        this.guardandoAerovale = false;
        if (!this.aerovaleCreadoCorrectamente) {
          this.messageService.showErrorMessage("No se pudo procesar el aerovale, por favor vuelva a intentar");
        }        
      }, 61000);

      if(!this.provinciaFlag){
        this.messageService.showErrorMessage("El valor de la provincia o la ciudad no puede ser vacio");
        return;
      }
      if(!this.ciudadFlag){
        this.messageService.showErrorMessage("El valor de la provincia o la ciudad no puede ser vacio");
        return;
      }
      if (!this.densidadValida()) {
        this.messageService.showErrorMessage("La densidad debe estar entre el rango [" + this.productoSeleccionado.densidadMin + "," + this.productoSeleccionado.densidadMax + "]");
        return;
      }
      if (this.pruebaDeAgua.value == 0) {
        this.messageService.showErrorMessage("No se ha podido generar el aerovale: revise la prueba de agua");
        return;
      }

      if (new Date(this.fechaFin.value.year, this.fechaFin.value.month, this.fechaFin.value.day, this.horaFin.value.hour, this.horaFin.value.minute) <= new Date(this.fechaInicio.value.year, this.fechaInicio.value.month, this.fechaInicio.value.day, this.horaInicio.value.hour, this.horaInicio.value.minute)) {
        this.messageService.showErrorMessage("La fecha/hora de fin de despacho debe ser posterior a la fecha/hora de inicio de despacho.");
        return;
      }
      if ((this.destino.value === "" || this.destino.value === null) && this.tipoVuelo.value === TipoVuelo.Internacional) {
        this.messageService.showErrorMessage("Debe completar el destino si el TipoVuelo es Internacional.");
        return;
      }

      if (this.abastecedora.value.tipoExpendedora == TipoExpendedora.SURTIDOR && this.tanqueAsociado.value == null) { // abastecedora surtidor
        this.messageService.showErrorMessage("Debe completar el tanque de extracción del combustible.");
        return;
      }

      if (this.picosAsociadosFormControl.value == null) {
        this.messageService.showErrorMessage("Debe seleccionar un aforador de la abastecedora.");
        return;
      }

      if (this.tipoPicoFormControl.value == null) {
        this.messageService.showErrorMessage("Debe seleccionar el tipo de pico (CARTER/GATILLO).");
        return;
      }

      if (this.tipoPicoFormControl.value == TipoPico.Carter) {
        if (this.presionManguera.value == null || this.presionManguera.value == "") {
          this.messageService.showErrorMessage("Debe completar el campo presion punta manguera.");
          return;
        }else {
          if (Number(this.presionManguera.value) < 0 || Number(this.presionManguera.value) > 50) {
            this.messageService.showErrorMessage("La presión de punta de manguera debe estar en el rango de 0 a 50 Psi.");
            return;
          }
        }

        if (this.presionDiferencial.value == null || this.presionDiferencial.value == "") {
          this.messageService.showErrorMessage("Debe completar el campo presion diferencial.");
          return;
        }else {
          if (Number(this.presionDiferencial.value) < 0 || Number(this.presionDiferencial.value) > 15) {
            this.messageService.showErrorMessage("La presión diferencial debe estar en el rango de 0 a 15 Psi.");
            return;
          }
        }

        if (this.caudal.value == null || this.caudal.value == "") {
          this.messageService.showErrorMessage("Debe completar el campo caudal observado.");
          return;
        }else {
          if (Number(this.caudal.value) < 0 || Number(this.caudal.value) > 5000) {
            this.messageService.showErrorMessage("El caudal debe estar en el rango de 0 a 5000 Lts/Min.");
            return;
          }
        }
      }else if (this.tipoPicoFormControl.value == TipoPico.Gatillo){
        this.presionDiferencial.setValue(null);
        this.presionManguera.setValue(null);
        this.caudal.setValue(null);
      }

      if (this.pedidoDevolucion && this.firmaCliente.value == null)
        {
          this.messageService.showErrorMessage("El formato del archivo del remito fisico debe ser tipo imagen.");
          return;
        }

      if (this.firmaCliente.value != null) {
        let extensionFirmaCliente = this.obtenerExtensionDeArchivo(this.firmaCliente.value);
        if (!this.esFormatoImagen(extensionFirmaCliente)) {
          this.messageService.showErrorMessage("El formato del archivo de la firma del cliente debe ser tipo imagen.");
          return;
        }
      }

      if (this.pedidoDevolucion) {
        if (this.aforadorFinal.value - this.aforadorInicial.value > this.pedidoDevolucion.cantidadEstimada) {
          this.messageService.showErrorMessage("El volumen despachado supera la cantidad estimada del pedido.");
          return;
        }            
      }

      if(this.tipoVuelo.value !== TipoVuelo.Cabotaje && !this.rancho) {
        console.log(this.aeronave.value)
        this.messageService.showErrorMessage("No se puede crear un aerovale para un vuelo internacional sin número de rancho");
        return;
      }

      this.accionarForm();

      if (this.aerovalesForm.valid && this.pruebaDeAgua.value == 1) {
       
        this.spinner.show(); 
        let aerovale = new AerovaleAerocombustible();

        var cuentaCliente: CuentaCliente = this.cliente.value;
        var clienteDespacho: Cliente = new Cliente();
        clienteDespacho.numeroCuenta = cuentaCliente.ctA_SGC;
        clienteDespacho.razonSocial = cuentaCliente.razonSocial;
        clienteDespacho.tipoDocumento = cuentaCliente.tipoDocumento;
        clienteDespacho.nroDocumento = cuentaCliente.numeroDocumento;
        clienteDespacho.condicionIva = cuentaCliente.tipoCliente;
        clienteDespacho.tipoPago = cuentaCliente.tipoCuenta;

        aerovale.cliente = clienteDespacho;

        console.log("El operador es " + this.operadorFormControl.value);
        aerovale.operadorDTO = this.obtenerUsuarioCargadoEnForm(this.operadorFormControl.value);

        aerovale.supervisorDTO = this.obtenerUsuarioLogueado();

        let nroCompleto = this.numeroAerovale.value;
        nroCompleto = nroCompleto.padStart(8, '0');
        aerovale.prefijo = nroCompleto.substring(0, 3);
        aerovale.numeroAerovale = nroCompleto.substring(3, 8);

        aerovale.tipoVuelo = this.tipoVuelo.value;
        aerovale.destino = this.destino.value;

        if (this.esAerovaleGranel) {
          aerovale.vuelo = "";
        } else {
          aerovale.vuelo = this.numeroVuelo.value.toUpperCase();
        }

        aerovale.tipoPago = this.tipoDePago.value;
        if (this.rancho != null) {
          aerovale.rancho = this.rancho.codigoRancho;
        }     

        aerovale.fechaHoraLlegada = this.formatDate(
          this.fechaLlegada.value.year, this.fechaLlegada.value.month,
          this.fechaLlegada.value.day, this.horaLlegada.value.hour, this.horaLlegada.value.minute);

        aerovale.fechaHoraInicioDespacho = this.formatDate(
          this.fechaInicio.value.year, this.fechaInicio.value.month,
          this.fechaInicio.value.day, this.horaInicio.value.hour, this.horaInicio.value.minute);

        aerovale.fechaHoraFinDespacho = this.formatDate(
          this.fechaFin.value.year, this.fechaFin.value.month,
          this.fechaFin.value.day, this.horaFin.value.hour, this.horaFin.value.minute);

        aerovale.recarga = this.recarga.value;
        aerovale.esGranel = this.granelFormControl.value;
        aerovale.codigoAbastecedora = this.abastecedora.value.codigo;

        aerovale.ciudadIdAerovale = this.ciudadAerovaleSeleccionada;
        //seteando valores a la aeronave

        if (this.aeronave.value != null) {
          let aeronave = new Aeronave();
          aerovale.aeronave = new Aeronave();
          aerovale.aeronave = this.aeronave.value;
          if (aerovale.aeronave.aerocombustible == null) {
            aerovale.aeronave.aerocombustible = Guid.EMPTY;
          }
        }

        //aerovale.aeroplantaId = this.usuarioService.getAeroplantaActual().id;
        aerovale.codigoAeroplanta = this.usuarioService.getAeroplantaActual().codigoAeroplanta;
        aerovale.esAlije = false;
        // ver que hacer con estos datos
        aerovale.datos_app = "{\"estadoCronometro\":1,\"progresoUi\":1,\"ranchoGuid\":\"\"}"
        aerovale.estado = "Precargado";


        aerovale.aerocombustible = new Aerocombustible();
        aerovale.aerocombustible.codigoProducto = this.abastecedora.value.producto.codigoProducto;
        //aerovale.aerocombustible.id = this.abastecedora.value.producto.id;
        aerovale.aerocombustible.nombreProducto = this.abastecedora.value.producto.nombreProducto;
        aerovale.aerocombustible.tipoProducto = this.abastecedora.value.producto.tipoProducto;
        aerovale.aerocombustible.unidadMedida = this.abastecedora.value.producto.unidadMedida;
        aerovale.aerocombustible.esUtilizable = false;

        aerovale.presionPuntaManguera = this.presionManguera.value == null ? 0 : this.presionManguera.value;
        aerovale.presionDiferencial = this.presionDiferencial.value == null ? 0 : this.presionDiferencial.value;
        aerovale.caudalObservado = this.caudal.value == null ? 0 : this.caudal.value;

        if (this.abastecedora.value.tipoExpendedora == TipoExpendedora.SURTIDOR) {
          aerovale.codigoTanque = this.tanqueAsociado.value;
        }

        aerovale.picoId = this.picosAsociadosFormControl.value.id;

        aerovale.observaciones = [];
        if (this.observacion.value != null) {
          aerovale.observaciones.push(this.observacion.value);
        }

        aerovale.comentarios = this.comentarios.value;

        //se eliminan los vehiculos que tiene el cliente para que el DTO no vaya tan cargado a backend,
        // porque sino no funcion
        this.cliente.value.vehiculos = [];

        if(this.pedidoDevolucion){        ;
          // Antes del post llamar a crear precarga con datos minimos (como en tablet) y con el guid de la precarga, asignarlo al aerovale
          // Ver tablet.
          let precarga = new Precarga();
          precarga.cargaEstimada = this.pedidoDevolucion.cantidadEstimada;

          let precargaId = await this.aerovaleService.CrearPrecargaDesdePedido(this.pedidoDevolucion.id, precarga);
          aerovale.esDevolucion =true;

          this.guardandoAerovale = true;
          this.spinner.show();

          this.aerovaleService.CrearAerovaleAerocombustibleDesdePrecarga(precargaId, aerovale)
            .subscribe(aerovaleRta => {
              this.procesarRespuestaCreacionAerovale(aerovale, aerovaleRta);
              //this.spinner.hide();
              this.aerovaleCreadoCorrectamente = true;
            }, (error)=> {
              // si falla CrearAerovaleAerocombustibleDesdePrecarga
              this.spinner.hide();
              this.messageService.showErrorMessage("No se pudo procesar el aerovale, por favor vuelva a intentar");
              this.guardandoAerovale = false;
            });
            
        }else{
          this.guardandoAerovale = true;
          this.spinner.show();
          this.aerovaleService.CrearAerovaleAerocombustible(aerovale)
          .subscribe(aerovaleRta => {
            this.procesarRespuestaCreacionAerovale(aerovale, aerovaleRta);
            //this.spinner.hide();
            this.aerovaleCreadoCorrectamente = true;
          }, (error)=> {
            // si falla CrearAerovaleAerocombustible
            this.spinner.hide();
            this.messageService.showErrorMessage("No se pudo procesar el aerovale, por favor vuelva a intentar");
            this.guardandoAerovale = false;
          });
        } 
            
      } else {
        this.messageService.showErrorMessage("Revise los campos incompletos");
        return;
      }
  }catch(error){
    // si falla el armado de la clase del aerovale
    this.spinner.hide();
    this.guardandoAerovale = false;
    this.messageService.showErrorMessage("No se pudo procesar el aerovale, por favor vuelva a intentar");
    }
  }

  procesarRespuestaCreacionAerovale(aerovale, aerovaleRta){
    if (aerovaleRta) {

      if (aerovaleRta.warnings != null && aerovaleRta.warnings.length > 0) {
        for (let entry of aerovaleRta.warnings) {

          this.messageService.showWarningMessage(entry, false);
        }
      }
      if (aerovaleRta.id != null) {
        aerovale.estado = aerovaleRta.estado;

        aerovale.temperatura = this.temperaturaEnGrados.value;
        aerovale.densidad = Number(this.densidad.value);
        aerovale.pruebaAgua = this.pruebaDeAgua.value;
        aerovale.aforadorInicio = this.aforadorInicial.value;
        aerovale.aforadorFinal = this.aforadorFinal.value;
        aerovale.volumenNaturales = this.aforadorFinal.value - this.aforadorInicial.value;
        aerovale.volumen15grados = this.volumen15Grados.value;

        if (this.firmaCliente.value != null) {
          aerovale.estadoFinal = "Finalizado";
        } else {
          aerovale.estadoFinal = "FinalizadoSinFirma";
          this.fileFirmaCliente = null;
          this.fileFirmaOperador = null
        }

        aerovale.id = aerovaleRta.id;
        console.log(JSON.stringify(aerovale));
        this.spinner.show();
        this.aerovaleService.ActualizarAerovaleAerocombustible(aerovale, this.fileFirmaCliente, this.fileFirmaCliente)
          .subscribe(modificado => {
            if(this.pedidoDevolucion){
              this.modal.close(aerovale);
              //this.spinner.hide();
            }else{
              this.nuevoAerovaleCreado.emit(aerovale);
              //this.spinner.hide();
            }                  
          }, (error: ErrorModel) => {
            this.messageService.showErrorMessage("Error al finalizar el aev. " + error.description);
            this.guardandoAerovale = false;
            this.spinner.hide();
          });
      }
    } else {
      this.guardandoAerovale = false;
      this.spinner.hide();
      this.messageService.showErrorMessage("Se ha producido un error al crear el aerovale");
    }
  }

  getClientes(): void {
    this.clienteService.getClientesActivos()
      .subscribe(clientes => {
        clientes = clientes.map(a => Object.assign(new CuentaCliente(), a));
        this.clientes = clientes;
        this.cargandoClientes = false;
      }, () => this.errorClientes = true);
  }

  getOperadores(): void {
    this.cargandoOperadores = true;
    this.aerovaleService.getOperadores(0, this.usuarioService.getAeroplantaActual().codigoAeroplanta)
      .subscribe(operadores => {
        operadores = operadores.map(a => Object.assign(new Operador(), a));
        this.operadores = operadores;
        this.cargandoOperadores = false;

        if(this.pedidoDevolucion) this.cargarDatosPedidoDevolucion();
      }, () => this.errorOperadores = true);
  }

  getOperadoresConRoles(): void {
    this.cargandoOperadores = true;
    var roles = this.usuarioService.getRolesEmitirAerovales();
    this.aerovaleService.getOperadoresConRoles(this.operadores, roles, this.usuarioService.getAeroplantaActual().codigoAeroplanta)
      .subscribe(result => {
        this.cargandoOperadores = true;
        this.operadores = result.map(a => Object.assign(new Operador(), a));
        //this.operadores = operadores;
        this.cargandoOperadores = false;
        if(this.pedidoDevolucion) this.cargarDatosPedidoDevolucion();
      }, () => this.errorOperadores = true);
  }

  cargarDatosPedidoDevolucion(){
    // desde this.pedidoDevolucion
    this.cliente.setValue(this.clientes.filter(c => c.ctA_SGC == this.pedidoDevolucion.cliente.numeroCuenta)[0]);
    if(this.pedidoDevolucion.vuelo) this.numeroVuelo.setValue(this.pedidoDevolucion.vuelo);
    if(this.pedidoDevolucion.aeronaveMatricula) this.aeronave.setValue(this.aeronaves.filter(ae => ae.matricula == this.pedidoDevolucion.aeronaveMatricula)[0]);
    this.destino.setValue(this.pedidoDevolucion.destino);

    this.clienteNoEditable = true;
  }

  getAerocombustibles(): void {
    this.pedidoService.getAerocombustible()
      .subscribe(aerocombustibles => {
        this.aerocombustibles = aerocombustibles;
        this.aerocombustible.enable();
        this.cargandoAerocombustibles = false;
        if (this.aerocombustible.value != null) {
          // this.cargandoAbastecedoras = true;
          this.getAbastecedoras();
        }
      }, () => this.errorAerocombustibles = true);
  }

  getAeronaves(): void {
    this.vehiculosService.getAeronaves()
      .subscribe( a => {
        this.aeronaves = a;
        this.cargandoAeronaves = false;
      }, ()=> {
        this.cargandoAeronaves = false;
        this.errorAeronaves = true
      })
  }

  getAeronavesByCliente(cuenta: CuentaCliente): void {
    this.cargandoAeronavesCliente = true;
    this.vehiculosService.getCuentasVehiculos(cuenta.tipoDocumento, cuenta.numeroDocumento)
      .subscribe( resp => {
        this.aeronavesCliente = resp.filter( v => v.ctA_SGC == cuenta.ctA_SGC)[0].vehiculos.filter( a => a.tipoVehiculo==TipoVehiculo.Aeronave);
        if (typeof (this.aeronave.value) != 'string')
          this.verificarAeronave();
        this.cargandoAeronavesCliente = false;
      }, 
      () => this.errorAeronavesCliente = true);
  }

  verificarAeronave() {
    if (this.aeronave.value) {
      if (this.cliente.value && this.cliente.value.id && !this.cliente.value.esTrader &&
        this.aeronavesCliente && this.aeronavesCliente.filter(value => value.matricula === this.aeronave.value.matricula).length == 0) {
        const modalRef = this.modalService.open(ModalConfirmComponent, { centered: true });
        modalRef.componentInstance.mensaje = "La aeronave no pertenece al cliente, ¿desea asignarla de todas formas?"; // should be the id
        modalRef.result.then(() => {
        },
          () => {
            this.aeronave.setValue(null);
          }
        );
      }
    }
  }

  getAbastecedoras(): void {
    this.cargandoAbastecedoras = true;
    this.aerovaleService.getTodasLasExpendedoras(this.usuarioService.getAeroplantaActual().codigoAeroplanta)
      .subscribe(abastecedoras => {
        this.abastecedoras = abastecedoras;
        this.abastecedoras = this.abastecedoras.filter(a => a.producto != null && a.producto.tipoProducto === 1 && a.activo === true);

        if(this.pedidoDevolucion){
          this.abastecedoras = this.abastecedoras.filter(ab => ab.producto.codigoProducto == this.pedidoDevolucion.codigoAerocombustible);
          if(this.abastecedoras.length > 0){
            let abastecedora = this.abastecedoras[0];
            this.abastecedora.setValue(abastecedora);
            this.productoSeleccionado = abastecedora.producto;  
            this.aerocombustible.setValue(abastecedora.producto.nombreProducto);     
          }                       
        }        
        
        this.cargandoAbastecedoras = false;
      });
  }

  filtrarAbastecedorasPorTipoDeCombustible(tipoCombustible: number): Abastecedora[] {
    let filtrados: Abastecedora[] = [];
    for (let a of this.abastecedoras) {
      if (a.producto.tipoProducto === tipoCombustible) {
        filtrados.push(a);
      }
    }
    return filtrados;
  }

  getAeropuertos(): void {
    this.cargandoAeropuertos = true;
    this.aeropuertoService.getAeropuertos().subscribe(aeropuertos => {
      this.aeropuertos = aeropuertos;
      this.cargandoAeropuertos = false;
    }, () => this.errorDestinos = true)
  }

  obtenerAeropuertoSegunTipoVuelo(cabotaje: boolean): Aeropuerto[] {
    let lista: Aeropuerto[] = [];
    for (let entry of this.aeropuertos) {
      if (entry.cabotaje === cabotaje) { lista.push(entry) }
    }
    return lista;
  }

  getRancho(): void {
    if (this.consultarRancho) {
      this.cargandoRancho = true;
      this.ranchoService.getRanchoPorCUIT(
        this.usuarioService.getAeroplantaActual().codigoAeroplanta,
        this.destino.value.codigoPais,
        this.abastecedora.value.productoActual.codigoProducto,        
        this.cliente.value.numeroDocumento,        
        this.formatDate(
          this.fechaFin.value.year, this.fechaFin.value.month,
          this.fechaFin.value.day, this.horaFin.value.hour, this.horaFin.value.minute)
      ).subscribe(rancho => {
        this.rancho = rancho;
        this.cargandoRancho = false;
      })
    }
    else {
      this.rancho = null;
    }
  }

  getAutorizacionTrader() {
    if (!this.cargandoAutorizacion) {
      if (this.cliente.value && this.cliente.value.id && this.cliente.value.esTrader) {
        if (this.aerocombustible.value && this.aeronave.value && this.aeronave.value.id) {
          this.cargandoAutorizacion = true;
          this.autorizacionesService.getAutorizacion(
            this.usuarioService.getAeroplantaActual().codigoAeroplanta,
            this.cliente.value.numeroCuenta,
            this.aerocombustible.value.codigoProducto,
            this.aeronave.value.matricula
          ).subscribe(
            (autorizacion) => {
              this.autorizacionTrader = autorizacion ? autorizacion : false;
              if (!autorizacion) {
                const modalRef = this.modalService.open(ModalConfirmComponent, { centered: true });
                modalRef.componentInstance.mensaje = "No se encontró autorización para la aeronave seleccionada, ¿desea continuar de todas formas?"; // should be the id
                modalRef.result.then(
                  () => { },
                  () => {
                    this.aeronave.setValue(null);
                    this.aerocombustible.enable();
                    this.autorizacionTrader = null;
                  }
                );
              }
              this.cargandoAutorizacion = false;
            }
          );
        }
      }
      this.autorizacionTrader = null;
    }
  }

  obtenerObservacionesDespacho() {
    this.aerovaleService.getMotivosAnulacion(0).subscribe(observaciones => {
      this.observaciones = observaciones;
    });

  }

  formatDensidad(event) {
    if (event.charCode == 8 || event.charCode == 0) return null;

    if (event.charCode >= 48 && event.charCode <= 57) {
      this.densidad.setValue(this.sharedService.formatDensidad(event));
    }
    return false;
  }


  get vencida(): boolean {
    return new Date(this.rancho.fechaVencimiento) < new Date();
  }

  obtenerUsuarioLogueado() {
    var u = new UsuarioAerovale();
    u.dni = this.usuarioService.getDniOperador();
    u.nombreCompleto = this.usuarioService.getNombresOperador();
    u.userName = this.usuarioService.getUsername();

    return u;
  }

  // parte donde se suben las firmas
  onSelectedFileFirmaCliente(files: any[]) {
    if (files && files.length > 0) {
      this.guardandoAerovale = true;
      this.procesandoFirmaCliente = true;
      let file = files[0];

      this.sharedService.resizeImage(file, 800).then(blob => {
        this.fileFirmaCliente = blob;
        this.procesandoFirmaCliente = false;
        this.guardandoAerovale = false;
      }, err => {
        this.procesandoFirmaCliente = false;
        this.guardandoAerovale = false;
      });
/*
      this.ng2ImgMax.resizeImage(file, 800, 10000).subscribe(result => {
        this.fileFirmaCliente = result;
        this.procesandoFirmaCliente = false;
        this.guardandoAerovale = false;
      },
        error => {
          this.procesandoFirmaCliente = false;
          this.guardandoAerovale = false;
        });
*/        
    }
  }

  onSelectedFileFirmaOperador(files: any[]) {
    if (files && files.length > 0) {
      this.guardandoAerovale = true;
      this.procesandoFirmaOperador = true;
      let file = files[0];

      this.sharedService.resizeImage(file, 800).then(blob => {
        this.fileFirmaOperador = blob;
        this.procesandoFirmaOperador = false;
        this.guardandoAerovale = false;
      }, err => {       
        this.procesandoFirmaOperador = false;
        this.guardandoAerovale = false;
      });
/*
      this.ng2ImgMax.resizeImage(file, 800, 10000).subscribe(result => {
        this.fileFirmaOperador = result;
        this.procesandoFirmaOperador = false;
        this.guardandoAerovale = false;
      },
        error => {
          this.procesandoFirmaOperador = false;
          this.guardandoAerovale = false;
        });
*/        
    }
  }

  reset(limpiarMensajes: boolean = false, limpiarInput: boolean = false) {
    this.cargando = false;
    this.archivoCargado = true;
    this.error = '';
    this.cargadoCorrectamente = false;
    if (limpiarInput) {
      this.archivoCargado = false;
      //this.userFileName = '';
      this.firmasForm.get('profile').setValue('');
      this.firmasForm.get('name').setValue('');
      this.firmasForm.get('inputFile').setValue('');
    }
    if (limpiarMensajes)
      this.fileUpload = { status: '', message: '', filePath: '', length: 0 };

  }

  searchOperador = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => this.operadores == null || term.length < 2 || this.cargandoOperadores ? [] :
        this.operadores.filter(op => this.containsString(this.operadorFormControl.value, op.nombresCompletos, op.dni)).slice(0, 10))
    )

  formatterOperador = (op: { nombresCompletos: string, dni: string }) => `${op.nombresCompletos} - ${op.dni}`;

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => this.clientes == null || term.length < 2 || this.cargandoClientes ? [] :
        this.clientes.filter(v => this.containsString(this.cliente.value, v.ctA_SGC, v.razonSocial, v.numeroDocumento)).slice(0, 10))
    )

  /**
   * Determina si una cadena se encuentra dentro de otra
   * @param searchValue Valor a buscar
   * @param searchIn Valores sobre los que buscar searchValue
   */
  containsString(searchValue: string, ...searchIn: string[]): boolean {
    const concat = searchIn.toString();
    return concat.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
  }

  formatter = (x: { razonSocial: string, ctA_SGC: string }) => `${x.razonSocial} - ${x.ctA_SGC}`;

  searchAeronave = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 || this.cargandoAeronavesCliente || isNullOrUndefined(this.aeronavesCliente) ? []
        : this.aeronavesCliente.filter(v => v.matricula.toLowerCase().indexOf(this.aeronave.value.toLowerCase()) > -1).slice(0, 10))
    )

  formatterAeronave = (x: { matricula: string }) => x.matricula;

  searchAeropuerto = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 || this.cargandoAeropuertos ? []
        : this.aeropuertos.filter(v => v.cabotaje == (this.tipoVuelo.value === TipoVuelo.Cabotaje) &&
          (v.codigoAeropuerto + ' - ' + v.nombre + ' - ' + v.ciudadNombre).toLowerCase().indexOf(this.destino.value.toLowerCase()) > -1).slice(0, 20))
    )

  formatterAeropuerto = (x: { nombre: string, codigoAeropuerto: string }) => x.codigoAeropuerto + ' - ' + x.nombre;


  admiteRancho(): boolean {
    let admite: boolean = false;
    admite = this.aeronave.value != null && !this.aeronave.value.Cabotaje;
    return admite;
  }

  esFormatoImagen(extension: string) {
    let es: boolean = false;
    es = (extension === 'jpeg' || extension === 'jpg' || extension === 'png' || extension === 'jpe' || extension === 'jfi' || extension === 'jfif');
    return es;
  }

  obtenerExtensionDeArchivo(firma: string): string {
    let extension = firma.split('.').pop();
    console.log("la extension que obtengo es " + extension);
    return extension;
  }

  obtenerResultadoRancho(): string {
    let resultado: string = "No hay ranchos"
    if (this.rancho != null) {
      resultado = this.rancho.codigoRancho;
    }
    return resultado;
  }
  //#endregion

  //#region Gets de este component

  //#region conversion de volumen en 15 grados

  validarDensidad(densidad) {
    if (densidad != null) {
      this.productoSeleccionado = this.productos.find(element => element.codigoProducto === this.abastecedora.value.producto.codigoProducto);
      let esValido = true;
      esValido = (densidad >= this.productoSeleccionado.densidadMin && densidad <= this.productoSeleccionado.densidadMax);
      this.esDensidadMin = this.productoSeleccionado.densidadMin;
      this.esDensidadMax = this.productoSeleccionado.densidadMax;
      return esValido;
    }
  }

  densidadValida(){
    if (this.densidad != null){
      this.productoSeleccionado = this.productos.find(element => element.codigoProducto === this.abastecedora.value.producto.codigoProducto);
      let esValido = true;
      esValido = (this.densidad.value >= this.productoSeleccionado.densidadMin && this.densidad.value <= this.productoSeleccionado.densidadMax);
      return esValido;
    }
  }

  leerArchivo(): string {
    let archivoTXT = new XMLHttpRequest();
    let fileRuta: string = 'assets/json/1.txt';
    archivoTXT.open("GET", fileRuta, false);
    archivoTXT.send(null);
    let response: any = archivoTXT.responseText;
    return response;
  }

  litrosA15Grados() {
    let res: number = this.calculoService.convertirLitrosNaturalesEn15Grados(this.temperaturaEnGrados.value, this.densidad.value, this.aforadorInicial.value, this.aforadorFinal.value);
    if(!isNaN(res)){
      this.volumen15Grados.setValue(res);
    }else{
      this.volumen15Grados.setValue(0);
    }
    return res;
  }

  usuarioCambioComboPico(event){
    var pico = this.picosAsociadosFormControl.value;

    this.tipoPicoFormControl.setValue(pico.tipoPico);
  }

  buscarTanques_y_Picos() {
    if (this.abastecedora.value.tipoExpendedora == TipoExpendedora.SURTIDOR) {
      this.obteniendoTanquesSurtidor = true;
      this.surtidorService.getSurtidoresPorCodigo(this.abastecedora.value.codigo)
        .subscribe(result => {
          this.surtidores = result;
          this.obteniendoTanquesSurtidor = false;
        })
    } else if (this.abastecedora.value.tipoExpendedora != TipoExpendedora.SURTIDOR) { // Camion
      this.tanqueAsociado.setValue(null);
      this.surtidores = null;
    }

    for (let pico of this.abastecedora.value.picos) {
      if (pico.porDefecto) {
        this.picosAsociadosFormControl.setValue(pico);
        this.tipoPicoFormControl.setValue(pico.tipoPico);
      }
    }

    this.tanqueAsociado.updateValueAndValidity();
    this.aerocombustible.setValue(this.abastecedora.value.producto.nombreProducto);
    this.controlVehiculoProducto();
    this.getRancho();
  }


  getItem(fila: number, col: number): number {
    let tabla: any = new Array();
    tabla = JSON.parse(this.archivoDeDensidad);

    if (tabla.length <= fila) {
      return null;
    }

    if (tabla[fila].length <= col) {
      return null;
    }

    return tabla[fila][col];
  }

  calcularVcf(factor: number, temperatura: number) {
    let vcf: number = Math.exp(-factor * (temperatura - 15) * (1 + 0.8 * factor * (temperatura - 15)));
    return vcf;
  }

  // obtencion de factores

  obtenerFactor1(densidadFinal: number): number {
    let factor: number = 346.4228 / Math.pow(densidadFinal, 2) + (0.4388 / densidadFinal);// math elevado al cuadrado

    return factor;
  }

  obtenerFactor2(densidadFinal: number): number {
    let factor: number = 0.00336312 + (2680.3206 / Math.pow(densidadFinal, 2));// math elevado al cuadrado
    return factor;
  }
  obtenerFactor3(densidadFinal: number): number {
    let factor: number = (594.5418 / Math.pow(densidadFinal, 2));
    return factor;
  }
  obtenerFactor4(densidadFinal: number): number {
    let factor: number = (186.9696 / Math.pow(densidadFinal, 2) + (0.4862 / densidadFinal))
    return factor;
  }

  // getters

  get consultarRancho() {
    return (this.cliente.value instanceof CuentaCliente && this.aerocombustible.value
      && this.tipoVuelo.value == TipoVuelo.Internacional && this.destino.value instanceof Aeropuerto);
  }

  get numeroAerovale() {
    return this.aerovalesForm.get('numeroAerovaleFormControl');
  }

  get cliente() {
    return this.aerovalesForm.get('clienteFormControl');
  }

  get operadorFormControl() {
    return this.aerovalesForm.get('operadorFormControl');
  }

  get modelAeronave() {
    return this.aeronave.value;
  }

  get tipoVuelo() {
    return this.aerovalesForm.get('tipoVueloFormControl');
  }

  get destino() {
    return this.aerovalesForm.get('destinoFormControl');
  }

  get numeroVuelo() {
    return this.aerovalesForm.get('numeroVueloFormControl');
  }

  get aerocombustible() {
    return this.aerovalesForm.get('aerocombustibleFormControl');
  }
  get tipoDePago() {
    return this.aerovalesForm.get('tipoPagoFormControl');
  }
  get unidadDeMedida() {
    return this.aerovalesForm.get('unidadMedidaFormControl');
  }
  get temperaturaEnGrados() {
    return this.aerovalesForm.get('temperaturaFormControl');
  }
  get densidad() {
    return this.aerovalesForm.get('densidadFormControl');
  }
  get pruebaDeAgua() {
    return this.aerovalesForm.get('pruebaDeAguaFormControl');
  }
  get aforadorInicial() {
    return this.aerovalesForm.get('aforadorInicialFormControl');
  }
  get aforadorFinal() {
    return this.aerovalesForm.get('aforadorFinalFormControl');
  }
  get volumenDespachado() {
    return this.aerovalesForm.get('volumenDespachadoFormControl');
  }
  get volumen15Grados() {
    return this.aerovalesForm.get('volumen15FormControl');
  }
  get recarga() {
    return this.aerovalesForm.get('recargaFormControl');
  }
  get granelFormControl() {
    return this.aerovalesForm.get('granelFormControl');
  }
  get fechaLlegada() {
    return this.aerovalesForm.get('fechaLlegadaFormControl');
  }
  get horaLlegada() {
    return this.aerovalesForm.get('horaLlegadaFormControl')
  }
  get fechaInicio() {
    return this.aerovalesForm.get('fechaInicioDespachoFormControl');
  }
  get horaInicio() {
    return this.aerovalesForm.get('horaInicioDespachoFormControl');
  }
  get fechaFin() {
    return this.aerovalesForm.get('fechaFinDespachoFormControl');
  }
  get horaFin() {
    return this.aerovalesForm.get('horaFinDespachoFormControl');
  }

  get firmaCliente() {
    return this.firmasForm.get('firmaClienteFormControl');
  }

  get firmaOperador() {
    return this.firmasForm.get('firmaOperadorFormControl');
  }

  get aeronave() {
    return this.aerovalesForm.get('aeronaveFormControl');
  }

  get abastecedora() {
    return this.aerovalesForm.get('abastecedoraFormControl');
  }

  get observacion() {
    return this.aerovalesForm.get('observacionFormControl');
  }

  get comentarios() {
    return this.aerovalesForm.get('comentariosFormControl');
  }

  get presionManguera() {
    return this.aerovalesForm.get('presionMangueraFormControl');
  }

  get presionDiferencial() {
    return this.aerovalesForm.get('presionDiferencialFormControl');
  }

  get caudal() {
    return this.aerovalesForm.get('caudalFormControl');
  }

  get tanqueAsociado() {
    return this.aerovalesForm.get('tanquesAsociadosFormControl');
  }

  get picosAsociadosFormControl() {
    return this.aerovalesForm.get('picosAsociadosFormControl');
  }

  get tipoPicoFormControl() {
    return this.aerovalesForm.get('tipoPicoFormControl');
  }


  /*
    get gatillo() {
      return this.aerovalesForm.get('gatilloFormControl');
    }
  */
  obtenerVolumenDespachado() {
    let res: number = this.aforadorFinal.value - this.aforadorInicial.value;
    if (this.aforadorFinal.value < this.aforadorInicial.value) { res = 0; }
    this.volumenDespachado.setValue(res);

    //this.convertirLitrosNaturalesEn15Grados();
    this.litrosA15Grados();
  }

  obtenerUsuarioCargadoEnForm(usuario: Operador) {
    var u = new UsuarioAerovale();
    u.dni = usuario.dni
    u.nombreCompleto = usuario.nombresCompletos;
    u.userName = usuario.userName;

    return u;
  }

  keysTipoDePago(): Array<string> {
    var keys = Object.keys(this.tipoPago);
    return keys.slice(keys.length / 2);
  }

  //metodo para obtener los tipos de pago que usa cada cliente
  obtenerTipoDePago(tipoCuentaCliente: number): Array<string> {

    if(!tipoCuentaCliente) return [];

    let lista: Array<string> = this.keysTipoDePago();
    
    lista = lista.filter(e => TipoCuenta[e] == TipoCuenta.Contado || TipoCuenta[e] == tipoCuentaCliente);

    return lista;
  }

  keysUnidadDeMedida(): Array<string> {
    var keys = Object.keys(this.unidadMedida);
    return keys.slice(keys.length / 2);
  }

  formatDate(year, month, day, hour, minute): string {
    return year + '-' + month.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + '-' + day.toLocaleString(undefined, { minimumIntegerDigits: 2 })
      + 'T' + hour.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + minute.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':00';
  }


  setCiudadAerovaleSeleccionada(event) {
    this.ciudadAerovaleSeleccionada = event;
  }
}
