import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from 'src/app/core/authentication/auth.service';

@Component({
  selector: 'app-modal-asociar-aeroplanta',
  templateUrl: './modal-asociar-aeroplanta.component.html',
  styleUrls: ['./modal-asociar-aeroplanta.component.css']
})
export class ModalAsociarAeroplantaComponent implements OnInit {

  @Input() mensaje: string;

  constructor(private authService: AuthService) { }

  ngOnInit() {
  }

  onCerrarSesionClick() {
    this.authService.signout();
    location.assign("#");
  }

}