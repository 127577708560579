import { Component, Input, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificacionSistemaDTO } from 'src/app/shared/models/notificaciones/NotificacionSistemaDTO';
import { TipoNovedadEncabezadoDTO } from 'src/app/shared/models/notificaciones/TipoNovedadEncabezadoDTO';
import { NotificacionesService } from '../../notificaciones.service';
import { UsuarioService } from 'src/app/account/usuario.service';
import { MessageService } from 'src/app/shell/message.service';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { Usuario } from 'src/app/shared/models/despacho/Usuario';
import { Observable } from 'rxjs/internal/Observable';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Grupo } from 'src/app/shared/models/notificaciones/Grupo';
import { GrupoEncabezado } from 'src/app/shared/models/notificaciones/GrupoEncabezado';
import { OperadorLogico } from 'src/app/shared/enums/OperadorLogico';
import { FiltroSeleccionado } from 'src/app/shared/models/notificaciones/FiltroSeleccionado';
import { TipoNovedadDTO } from 'src/app/shared/models/notificaciones/TipoNovedadDTO';
import { Filtro } from 'src/app/shared/models/notificaciones/Filtro';
@Component({
  selector: 'app-notificacion-detalle-sistema',
  templateUrl: './notificacion-detalle-sistema.component.html',
  styleUrls: ['./notificacion-detalle-sistema.component.css']
})
export class NotificacionDetalleSistemaComponent implements OnInit {

  // formulario
  notificacionForm = new UntypedFormGroup({
    tituloFormControl: new UntypedFormControl(null, [Validators.required]),
    tipoNovedadFormControl: new UntypedFormControl(null, [Validators.required]),
    /*emailFormControl: new FormControl(null, [
      Validators.required,
      Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')
    ]),*/   
    emailFormControl: new UntypedFormControl(null), 
    usuarioFormControl: new UntypedFormControl(null), 
    grupoFormControl: new UntypedFormControl(null), 
    mensajeFormControl: new UntypedFormControl(null, [Validators.required]),
    activoFormControl: new UntypedFormControl(null),
    clavesFormControl: new UntypedFormControl(null),
    operadorFormControl: new UntypedFormControl(null),
    valorFormControl: new UntypedFormControl(null),
    usuariosCheck: new UntypedFormArray([])
  });

  // entradas
  @Input() notificacionSistema: NotificacionSistemaDTO;
  @Output() notificacionDuplicar: EventEmitter<NotificacionSistemaDTO> = new EventEmitter;

  // variables
  tipoNovedadPorRoles: TipoNovedadEncabezadoDTO[] = [];
  tipoNovedades: TipoNovedadDTO;
  etiquetasPorTipoNovedad: string[] = [];
  cargandoDetalle: boolean = false;
  cargandoEtiquetas: boolean = false;
  cargandoFiltros: boolean = false;
  cargandoUsuariosAccion: boolean = false;
  guardandoDatos: boolean = false;
  listaDeEmails: string[] = [];  
  
  listaDeUsuarios: Usuario[] = []; 
  usuariosSeleccionados: Usuario[] = []; 

  listaDeGrupos: GrupoEncabezado[] = []; 
  gruposSeleccionados: GrupoEncabezado[] = []; 

  /**
   * Filtros
   */
  listaDeClaves: Filtro[] = [];
  operadorLogico = OperadorLogico;
  listaDeFiltros: FiltroSeleccionado[] = [];
  
  constructor(
    activeModal: NgbModal,
    private notificacionService:NotificacionesService,
    private usuarioService: UsuarioService,
    private spinner: NgxSpinnerService,
    private messageService: MessageService
  ) {}

  ngOnInit() {     

  }

  cargarDatos() {
    this.tituloFormControl.setValue(this.notificacionSistema.titulo);
    this.tipoNovedadFormControl.setValue(this.notificacionSistema.tipoNovedad);
    this.mensajeFormControl.setValue(this.notificacionSistema.mensaje);    
    this.activoFormControl.setValue(this.notificacionSistema.activo);  
    this.llenarListaFiltros(); 
    this.llenarListaEmails();
    this.llenarListaUsuarios();
    this.llenarListaGrupos();
  }

  ngOnChanges() {
    this.guardandoDatos = false;
    this.clavesFormControl.setValue(null);
    this.operadorFormControl.setValue(null);
    this.valorFormControl.setValue(null);
    this.obtenerNotificacionSistemaPorId(this.notificacionSistema.id);
  }
  
  mostrarSpinner() {
    this.spinner.show('spinnerDetalle');
    this.cargandoDetalle = true;  
  }
  
  ocultarSpinner() {
    this.spinner.hide('spinnerDetalle');
    this.cargandoDetalle = false;  
  }

  llenarListaEmails() {  
    this.listaDeEmails=[];  
    for (let i = 0; i < this.notificacionSistema.emails.length; i++) {
      this.listaDeEmails.push(this.notificacionSistema.emails[i]);      
    } 
  }

  llenarListaFiltros() {  
    this.listaDeFiltros=[];  
    if(this.notificacionSistema.filtrosSeleccionados!=null) {
      for (let i = 0; i < this.notificacionSistema.filtrosSeleccionados.length; i++) {
        this.listaDeFiltros.push(this.notificacionSistema.filtrosSeleccionados[i]);      
      } 
    }
  }

  llenarListaUsuarios() {  
    this.usuariosSeleccionados=[];  
    for (let i = 0; i < this.notificacionSistema.usuarios.length; i++) {
      this.usuariosSeleccionados.push(this.notificacionSistema.usuarios[i]);      
    } 
  }

  llenarListaGrupos() {  
    this.gruposSeleccionados=[];  
    if(this.notificacionSistema.gruposUsuarios!=null) {
      for (let i = 0; i < this.notificacionSistema.gruposUsuarios.length; i++) {
        this.gruposSeleccionados.push(this.notificacionSistema.gruposUsuarios[i]);      
      } 
    }
  }

  // form

  agregarMailDestinatario() {
    if(this.emailFormControl.value != null && this.emailFormControl.value != '')  {
      if(this.listaDeEmails.some( m => m == this.emailFormControl.value )) {
        this.messageService.showErrorMessage("El mail ingresado ya esta en la lista");
        return;
      }
      this.listaDeEmails.push(this.emailFormControl.value);
      this.emailFormControl.setValue(null);
    }
  }

  borrarMailDestinatario(mail) {
    this.listaDeEmails = this.listaDeEmails.filter( x => x != mail );
  }

  /** Filtros */

  agregarFiltroSeleccionado() {
    let nuevoFiltro: any;
    let controlarCampoValor:boolean;
    if(this.mostrarInputValor()) {
      nuevoFiltro = { clave: this.clavesFormControl.value, operadorLogico: this.operadorFormControl.value, valor: this.valorFormControl.value, activo: true }
      controlarCampoValor=true;
    } else {
      nuevoFiltro = { clave: this.clavesFormControl.value, operadorLogico: this.operadorFormControl.value, valor: null, activo: true };
      controlarCampoValor=false;
    }
    if (this.validarInput(controlarCampoValor)) {
      this.listaDeFiltros.push(nuevoFiltro);
    } else {
      this.messageService.showErrorMessage("Por favor revise los campos a completar.")
    }
  }

  borrarFiltroSeleccionado(filtro) {
    this.listaDeFiltros = this.listaDeFiltros.filter( f => f != filtro);
  }

  validarInput(controlarCampoValor:boolean) {
    if(!controlarCampoValor) {
      return (this.clavesFormControl.value != '' && this.clavesFormControl.value != null) && (this.operadorFormControl.value != null)
    } else {
      return (this.clavesFormControl.value != '' && this.clavesFormControl.value != null) && (this.operadorFormControl.value != null) && (this.valorFormControl.value != null && this.valorFormControl.value != '')
    }
  }

  mostrarInputValor() {
    if(this.operadorFormControl.value!=null) {
      return (this.operadorFormControl.value!=OperadorLogico.Verdadero && this.operadorFormControl.value!=OperadorLogico.Falso)      
    } else {
      return true
    }
  }

  claveSinValores() {
    var filtro = this.listaDeClaves.find(f => f.clave == this.clavesFormControl.value);
    if(filtro){
      return filtro.valoresPosibles.length == 0;
    }
    return true;
  }

  obtenerValoresClave(){
    var filtro = this.listaDeClaves.find(f => f.clave == this.clavesFormControl.value);
    if(filtro){
      return filtro.valoresPosibles;
    }
  }

  limpiarCamposOpValor() {
    this.operadorFormControl.reset();
    this.valorFormControl.reset();
  }

  /** Usuarios */

  agregarUsuarioDestinatario() {
    if (typeof (this.usuarioFormControl.value) === 'string' || (this.usuarioFormControl.value==null)) {
      this.usuarioFormControl.setValue(null);
    } else { 
      if(this.usuariosSeleccionados.some( u => u.id == this.usuarioFormControl.value.id )) {
        this.messageService.showErrorMessage("El usuario ingresado ya esta en la lista");
        return;
      }
      this.usuariosSeleccionados.push(this.usuarioFormControl.value);
      this.usuarioFormControl.setValue(null);
    }
  }

  borrarUsuarioDestinatario(usuario) {
    this.usuariosSeleccionados = this.usuariosSeleccionados.filter( x => x.id != usuario.id );
  }

  formatterUsuario = (op: { nombresCompletos: string, userName: string }) => `${op.nombresCompletos}`;
  
  searchUsuario = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    map(term => this.listaDeUsuarios == null || term.length < 2 || this.cargandoDetalle ? [] :
      this.listaDeUsuarios.filter(op => this.containsString(this.usuarioFormControl.value, op.nombresCompletos)).slice(0, 10))
  )

  selectUsuario(event: any): void {
    this.usuarioFormControl.setValue(event.item);
  }
 
  /** Grupos */

  agregarGrupoDestinatario() {
    if (typeof (this.grupoFormControl.value) === 'string' || (this.grupoFormControl.value==null)) {
      this.grupoFormControl.setValue(null);
    } else {
      if(this.gruposSeleccionados.some( g => g.id == this.grupoFormControl.value.id )) {
        this.messageService.showErrorMessage("El grupo ingresado ya esta en la lista");
        return;
      }       
      this.gruposSeleccionados.push(this.grupoFormControl.value);
      this.grupoFormControl.setValue(null);           
    }
  }

  borrarGrupoDestinatario(grupo) {
    this.gruposSeleccionados = this.gruposSeleccionados.filter( x => x.id != grupo.id );
  }

  formatterGrupo = (op: { titulo: string }) => `${op.titulo}`;
  
  searchGrupo = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    map(term => this.listaDeGrupos == null || term.length < 2 || this.cargandoDetalle ? [] :
      this.listaDeGrupos.filter(op => this.containsString(this.grupoFormControl.value, op.titulo)).slice(0, 10))
  )

  selectGrupo(event: any): void {
    this.grupoFormControl.setValue(event.item);
  }

  /** Usuarios Accion */

  containsString(searchValue: string, ...searchIn: string[]): boolean {
    const concat = searchIn.toString();
    return concat.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
  }

  agregarEtiqueta(etiqueta) {       
    var cursorPos=(document.getElementById("mensaje") as HTMLInputElement).selectionStart;    
    var nuevoMsje=this.mensajeFormControl.value.slice(0, cursorPos)+' {'+etiqueta+'} '+this.mensajeFormControl.value.slice(cursorPos)
    this.mensajeFormControl.setValue(nuevoMsje);
  }

  // llamadas al servicio

  async obtenerNotificacionSistemaPorId(id:string) {
    
    try{
      this.mostrarSpinner();

      this.notificacionSistema = await this.notificacionService.getNotificacionesSistemasPorIdPromise(id);
  
      this.tipoNovedadPorRoles = await this.notificacionService.getTipoNovedadPorRolesPromise(this.usuarioService.getRoles())
  
      this.refrescarNovedades(false);

      this.listaDeUsuarios = await this.usuarioService.obtenerTodosLosUsuariosPromise();

      this.listaDeGrupos = await this.notificacionService.getGruposPromise();

      this.cargarDatos();
      this.ocultarSpinner();
    }catch(error){
      this.ocultarSpinner();
      this.messageService.showErrorMessage("Error: " + error.message + " descripcion: " + error.descripcion);
    }
  }

  async refrescarNovedades(usuarioCambioTipoNovedad:boolean) {

    try {
      this.cargandoEtiquetas = true;
      this.cargandoFiltros = true;
      this.cargandoUsuariosAccion = true;
      
      if(usuarioCambioTipoNovedad) {
        this.clavesFormControl.setValue(null);
        this.operadorFormControl.setValue(null);
        this.valorFormControl.setValue(null);
        this.tipoNovedades = await this.notificacionService.getDatosPorTipoNovedadPromise(this.tipoNovedadFormControl.value.id)
      } else {
        this.tipoNovedades = await this.notificacionService.getDatosPorTipoNovedadPromise(this.notificacionSistema.tipoNovedad.id)
      }
      
      this.etiquetasPorTipoNovedad = this.tipoNovedades.etiquetas;
      this.listaDeClaves = this.tipoNovedades.filtros;      
      
      this.iniciarFormUsuariosAccion(); // usuariosAccionFormArray

      this.cargandoEtiquetas = false;  
      this.cargandoFiltros = false;  
      this.cargandoUsuariosAccion = false;  
    } catch (error) {
      this.cargandoEtiquetas = false;
      this.cargandoFiltros = false;
      this.cargandoUsuariosAccion = false;
      this.messageService.showErrorMessage("Error: " + error.message + " descripcion: " + error.descripcion);
    }    
  }

  iniciarFormUsuariosAccion() {
    this.usuariosAccionFormArray.clear();
    const resultado = this.tipoNovedades.usuariosAccion.map( item => this.notificacionSistema.usuariosAccionSeleccionado.includes(item) );
    resultado.forEach( elem => this.usuariosAccionFormArray.push(new UntypedFormControl(elem)) );    
  }  

  validarDatosModificados() {
    
    const usuariosAccion = this.notificacionForm.value.usuariosCheck
      .map((checked, i) => checked ? this.tipoNovedades.usuariosAccion[i] : null)
      .filter(v => v !== null);
    
    if(this.listaDeEmails.length==0 && usuariosAccion.length == 0 && this.usuariosSeleccionados.length == 0
      && this.gruposSeleccionados.length == 0) {
      this.messageService.showErrorMessage('Debe ingresar al menos un destinatario');
      return false;
    }

    return true;    
  }

  duplicarNotificacion() {
    this.notificacionDuplicar.emit(this.notificacionSistema)
  }

  guardarCambios() {
    if(!this.notificacionForm.valid){
      this.messageService.showErrorMessage('Revise los campos incompletos');
      this.notificacionForm.markAllAsTouched();
      return;
    }else if(!this.validarDatosModificados() ){    
      return;
    }    

    const selectedusuarios = this.notificacionForm.value.usuariosCheck
      .map((checked, i) => checked ? this.tipoNovedades.usuariosAccion[i] : null)
      .filter(v => v !== null);

    this.guardandoDatos = true;
    let nuevaNotificacion = new NotificacionSistemaDTO;
    nuevaNotificacion.titulo = this.tituloFormControl.value;
    nuevaNotificacion.tipoNovedad = this.tipoNovedadFormControl.value;
    nuevaNotificacion.activo = this.activoFormControl.value;
    nuevaNotificacion.emails = this.listaDeEmails;
    nuevaNotificacion.usuarios = this.usuariosSeleccionados;
    nuevaNotificacion.gruposUsuarios = this.gruposSeleccionados;
    nuevaNotificacion.mensaje = this.mensajeFormControl.value;
    nuevaNotificacion.filtrosSeleccionados = this.listaDeFiltros;
    nuevaNotificacion.usuariosAccionSeleccionado = selectedusuarios;

    this.notificacionService.modificarNotificacionSistema(this.notificacionSistema.id, nuevaNotificacion)
      .subscribe(result => {
        console.log('Entro modificar ' + result);
        this.messageService.showSuccessMessage('Notificación modificada con éxito.');
        this.guardandoDatos = false;
        this.cargarDatosGuardados(nuevaNotificacion);
      }, (err: ErrorModel) => {         
        this.messageService.showErrorMessage('Hubo un error al modificar la notificación. '+err.message+' - '+err.description);
        this.guardandoDatos = false;
      });
  }
  /*Se cargan los datos guardados para que aparezcan al presionar Duplicar */ 
  cargarDatosGuardados(nuevaNotificacion){
    this.notificacionSistema.tipoNovedad = nuevaNotificacion.tipoNovedad; 
    this.notificacionSistema.emails = nuevaNotificacion.emails;
    this.notificacionSistema.usuarios = nuevaNotificacion.usuarios;
    this.notificacionSistema.gruposUsuarios = nuevaNotificacion.gruposUsuarios;
    this.notificacionSistema.filtrosSeleccionados = nuevaNotificacion.filtrosSeleccionados;
    this.notificacionSistema.usuariosAccionSeleccionado = nuevaNotificacion.usuariosAccionSeleccionado;
  }
  /** Utils */

  compareTipoNovedad(n1: any, n2: any): boolean {  
    return n1 && n2 ? n1.id == n2.id : n1 == n2;
  }

  keysOperadorLogico(): Array<string> {
    var keys = Object.keys(this.operadorLogico);
    keys = keys.slice(keys.length / 2);
    if(this.claveSinValores()){
      return keys;
    } else {
      return keys.filter(k => k == "Igual" || k == "Distinto");
    }
  }

  obtenerValorOperadorLogico(op){
    return Number(this.operadorLogico[op]);
  }

  obtenerNombreOperador(i) {
    return this.operadorLogico[i];
  }

  /** getters */
  
  get tituloFormControl() {
    return this.notificacionForm.get('tituloFormControl');
  }

  get tipoNovedadFormControl() { 
    return this.notificacionForm.get('tipoNovedadFormControl'); 
  }

  get emailFormControl() { 
    return this.notificacionForm.get('emailFormControl'); 
  }

  get mensajeFormControl() { 
    return this.notificacionForm.get('mensajeFormControl'); 
  }

  get activoFormControl() { 
    return this.notificacionForm.get('activoFormControl'); 
  }

  get usuarioFormControl() {
    return this.notificacionForm.get('usuarioFormControl');
  }

  get grupoFormControl() {
    return this.notificacionForm.get('grupoFormControl');
  }  

  get clavesFormControl() {
    return this.notificacionForm.get('clavesFormControl');
  }  

  get operadorFormControl() {
    return this.notificacionForm.get('operadorFormControl');
  }  

  get valorFormControl() {
    return this.notificacionForm.get('valorFormControl');
  }  

  get usuariosAccionFormArray(): UntypedFormArray {
    return this.notificacionForm.controls.usuariosCheck as UntypedFormArray;
  }
}