import { style, trigger, state, transition, animate, query, animateChild, group } from '@angular/animations';

export const fadeAnimationTrigger = [ // the fade-in/fade-out animation.
  // the fade-in/fade-out animation.
  trigger('fadeIn', [

    // the "in" style determines the "resting" state of the element when it is visible.
    state('in', style({ opacity: 1 })),

    // fade in when created. this could also be written as transition('void => *')
    transition(':enter', [
      style({ opacity: 0 }),
      animate(200)
    ]),

    // fade out when destroyed. this could also be written as transition('void => *')
    // transition(':leave',
    //   animate(600, style({opacity: 0})))
  ])
]

export const routerFade = [
  trigger('routerFade', [
    state('in', style({ opacity: 1 })),
    transition('* <=> *', [
      style({ opacity: 0 }),
      animate(150)
    ]),
  ])]

