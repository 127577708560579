<!-- <div class="modal-header">
  <h5 class="modal-title justify-content-center">Generacion de Informe</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="this.modal.close()">
    
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="productoForm">
    <div class="row">
      <div class="col col-2"></div>
      <div class="col col-8">
        <label class="pt-2">Seleccionar el informe que desea generar:</label>
      </div>
      <select id="productoFormControl" class="form-control" ngbTooltip="Seleccione el producto.">
        <option *ngFor="let f of filtros " [ngValue]="f">
          {{f}}
        </option>
      </select>
      <div class="col col-2"></div>
    </div>
    <div *ngIf="fileUpload.status==='progress'">
      <div class="progress" style="height: 20px;margin-bottom:20px;">
        <div class="progress-bar" role="progressbar" [style.width.%]="fileUpload.message" aria-valuenow="25"
          aria-valuemin="0" aria-valuemax="100">{{fileUpload.message}}%</div>
      </div>
    </div>
    <div *ngIf="fileUpload.length>0" class="alert alert-warning" role="alert">
      <div *ngFor="let msg of fileUpload">
        <p>{{ msg.description }}</p>
      </div>
    </div>
    <div *ngIf="error">
      <p style="color: red; font-weight: bold;"> {{error}} </p>
    </div>
  </form>
</div>
<div class="modal-footer justify-content-center">
  <button class="btn btn-primary me-2" id="aceptar" [disabled]="!productoForm.valid || guardandoExcel">
    <span *ngIf="!guardandoExcel">Generar Informe</span>
    <div *ngIf="guardandoExcel" class="d-flex align-items-center">
      <div class="spinner-border text-light spinner-border-sm" role="status"></div>
      <span>Cargando...</span>
    </div>
  </button>
  <button type="button" class="btn btn-secondary btn-sm mt-2" (click)="this.modal.close()">Cancelar</button>
</div> -->

<div class="card">
  <div class="card-header">
    <h5 class="card-title">Generación de informe</h5>
    <button type="button" class="btn-close float-end" aria-label="Close" (click)="this.modal.close()">
    </button>
  </div>
  <div class="card-body">
    <div class="col-12">
      <div class="spinner-container" *ngIf="cargando">
        <ngx-spinner name="spinnerGrafico" bdOpacity=0.3bd
          Color="rgba(193,193,193,0)" size="medium" color="#185db5" type="ball-grid-beat" [fullScreen]="false">
          <p style="color: white">C a r g a n d o . . . </p>
        </ngx-spinner>
      </div>
      <div class="alert alert-danger" *ngIf="!cargando && (this.reporteMovimientosAnulados.length == 0 && this.reporteMovimientosFiltrado.length == 0)">
        No hay aerovales generados en el turno actual.
      </div>
      <div *ngIf="!cargando && (this.reporteMovimientosAnulados.length > 0 || this.reporteMovimientosFiltrado.length > 0)">
        <div class="input-group">
          <label class="col-5 ms-1"></label>
          <div class="mb-3" id="noPrint" [formGroup]="ordenamientoForm">
              <input type="radio" class="form-check-input" id="checkCliente" [value]="1" (click)="ordenar(1)"
                formControlName="clienteAerovaleFormControl" />
              <label class="form-check-label letra" for="checkCliente">Cliente</label>
              <input type="radio" class="form-check-input ms-4" id="checkAerovle" [value]="0" (click)="ordenar(0)"
                formControlName="clienteAerovaleFormControl" />
              <label class="form-check-label letra" for="checkAerovle">Aerovale</label>
          </div>
        </div>
        <span class="justify-content-start">
          <div ngbDropdown autoClose="outside" class="form-check form-check-inline">
            <button class="btn btn-outline-primary my-2 my-lg-0" id="dropdownForm1"
              ngbDropdownToggle>Seleccionar Cliente</button>
            <div ngbDropdownMenu aria-labelledby="dropdownForm1" style="z-index: 50">
              <div class="px-4 py-3" style="z-index: 50">
                <div class="form-check mb-2">
                  <input type="checkbox" checked class="form-check-input" id="TodosCli"
                    (change)="seleccionarTodosClientes($event)" (change)="filtrar()">
                  <label class="form-check-label text-nowrap" for="TodosCli">
                    Todos los clientes
                  </label>
                </div>
                <div *ngFor="let c of clientes">
                  <div class="form-check">
                    <input type="checkbox" class="custom-control-input" id={{c.cierre.ClienteNombre}}
                      [(ngModel)]="c.seleccionado" (change)="filtrar()">
                    <label class="custom-control-label text-nowrap" for={{c.cierre.ClienteNombre}}>
                      {{ c.cierre.ClienteNombre }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div ngbDropdown autoClose="outside" style="display: inline; margin-right: 1em;">
            <button class="btn btn-outline-primary my-2 my-lg-0" id="dropdownForm1"
              ngbDropdownToggle>Seleccionar Contenedor</button>
            <div ngbDropdownMenu aria-labelledby="dropdownForm1" style="z-index: 50">
              <div class="px-4 py-3" style="z-index: 50">
                <div class="form-check mb-2">
                  <input type="checkbox" checked class="custom-control-input" id="TodosCon"
                    (change)="seleccionarTodosContenedores($event)" (change)="filtrar()">
                  <label class="custom-control-label text-nowrap" for="TodosCon">
                    Todos los contenedores
                  </label>
                </div>
                <div *ngFor="let con of contenedores">
                  <div class="form-check">
                    <input type="checkbox" class="custom-control-input" id={{con.name}}
                      [(ngModel)]="con.seleccionado" (change)="filtrar()">
                    <label class="custom-control-label text-nowrap" for={{con.name}}>
                      {{ con.name }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </span>

        <!--AEROVALES-->
        <table class="table table-striped table-hover letra">
          <thead class="text-center">
            <tr>
              <th>Aerovale</th>
              <th>Cliente</th>
              <th>Cuenta</th>
              <th>Titulo</th>
              <th>Contenedor</th>
              <th>Producto</th>
              <th>Cantidad</th>
              <th>Cantidad 15°</th>
              <th>Caudal Observado</th>
              <th>Presion Diferencial</th>
              <th>Presion Punta Manguera</th>
              <th>Ver Aerovales</th>
            </tr>
          </thead>
          <tbody class="text-center">
            <tr *ngFor="let mov of reportesFiltrados; let i = index"
              [ngClass]="{'resaltado': this.revisarAnulados(mov)}">
              <td>{{mov.comprobante.remito.aerovalePrefijo}}-{{mov.comprobante.remito.aerovaleNumero}}</td>
              <td>
                {{mov.comprobante.remito.clienteCierre.ClienteNombre}}-{{mov.comprobante.remito.clienteCierre.ClienteCUIT}}
              </td>
              <td>{{mov.comprobante.remito.clienteCierre.Cuenta_SGC}}</td>
              <td>{{mov.titulo? mov.titulo : "--"}}</td>
              <td>{{mov.codigoContenedor}}-{{mov.nombreContenedor}}</td>
              <td>{{mov.codigoProducto}}-{{mov.nombreProducto}}</td>
              <td>{{mov.salidaNatural? mov.salidaNatural : mov.entradaNatural? mov.entradaNatural : 0}}</td>
              <td>{{mov.salida15? mov.salida15 : mov.entrada15? mov.entrada15 : 0}}</td>
              <td>{{mov.comprobante.remito.caudalObservado? mov.comprobante.remito.caudalObservado : 0}}</td>
              <td>{{mov.comprobante.remito.presionDiferencial? mov.comprobante.remito.presionDiferencial : 0}}</td>
              <td>{{mov.comprobante.remito.presionPuntaManguera? mov.comprobante.remito.presionPuntaManguera : 0}}</td>
              <td>
                <button *ngIf="mov.comprobante?.remito?.aerovaleId != null" class="btn" (click)="verAerovale(mov.comprobante?.remito?.aerovaleId, i)" ngbTooltip="Ver aerovale">
                  <i *ngIf="!obteniendoAerovale || obteniendoAerovale && aerovaleSeleccionado!=i" class="fa fa-eye" aria-hidden="true"></i>
                  <div *ngIf="aerovaleSeleccionado==i && obteniendoAerovale">
                      <div class="spinner-border text-primary spinner-border-sm" role="status"></div>
                      <span><i class="fa fa-eye" aria-hidden="true"></i></span>
                  </div>
                </button>  
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="card-footer">
    <button class="btn btn-primary me-2" type="button" (click)="exportarExcel()" [disabled]="cargando || exportando || (this.reporteMovimientosAnulados.length == 0 && this.reporteMovimientosFiltrado.length == 0)"><i class="fa fa-file-excel-o" aria-hidden="true"></i> Descargar resumen </button>
      <button type="button" (click)="export()" class="btn btn-primary me-2" [disabled]="cargando || exportando || (this.reporteMovimientosAnulados.length == 0 && this.reporteMovimientosFiltrado.length == 0)"><i class="fa fa-file-excel-o" aria-hidden="true"></i> Descargar detalle de aerovales </button>
    <button type="button" class="btn btn-secondary" (click)="this.modal.close()">Cancelar</button>
  </div>
</div>