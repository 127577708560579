import { Component, OnInit, Input } from '@angular/core';
import { Comprobante } from 'src/app/shared/models/facturacion/Comprobante';
import { ComprobanteService } from '../../comprobante.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreditMemoPanelComponent } from 'src/app/facturacion/credit-memo-panel/credit-memo-panel.component';
import { UsuarioService } from 'src/app/account/usuario.service';
import { CondicionVenta } from 'src/app/shared/enums/condicionVenta';
import { EstadoFactura } from 'src/app/shared/enums/EstadoFactura';
@Component({
  selector: 'app-comprobante',
  templateUrl: './comprobante.component.html',
  styleUrls: ['./comprobante.component.css']
})
export class ComprobanteComponent implements OnInit {
  @Input() NotaDebitoCreada: Comprobante;
  busquedaFormControl = new UntypedFormControl();
  tipos: any[] = [
    { id: 1, label: "FAA" },
    { id: 6, label: "FAB" },
    { id: 19, label: "FAE" },
    { id: 3, label: "NCA" },
    { id: 8, label: "NCB" },
    { id: 21, label: "NCE" },
    { id: 2, label: "NDA" },
    { id: 7, label: "NDB" },
    { id: 20, label: "NDE" }//,
    // { id: 9, label: "REM" },
    // { id: 99, label: "RDV" }
  ];
  cargando: boolean = false;
  facturaRecibida: Comprobante;
  facturaObtenida: Comprobante;
  mensajeError: string = "";
  enFecha: boolean = false;
  aeroplantaActual: string;

  CondicionVenta=CondicionVenta;
  EstadoFactura=EstadoFactura;
  constructor(private comprobanteService: ComprobanteService,
    private spinner: NgxSpinnerService,
    private modal: NgbModal,
    private usuarioService: UsuarioService) { }

  comprobanteForm = new UntypedFormGroup({
    prefijoFormControl: new UntypedFormControl(null),
    numeroFormControl: new UntypedFormControl(null),
    tipoMovimientoFormControl: new UntypedFormControl(null),
  });

  ngOnInit() {
    this.aeroplantaActual = this.usuarioService.getAeroplantaActual().codigoAeroplanta;
    this.mensajeError = "";
    this.tipoMovimiento.setValue(1);
  }

  // Operaciones

  crearNotaDebito(content) {
    this.modal.open(content, { centered: true, size: 'lg', backdrop: 'static' });
  }

  crearNotaCredito() {
    let modalRef = this.modal.open(CreditMemoPanelComponent, { centered: true, size: 'lg' });
    modalRef.componentInstance.facturaObtenida = this.facturaObtenida;
    modalRef.componentInstance.notaCreditochange$.subscribe(result => {
      this.facturaRecibida = result;
      this.prefijo.setValue(this.facturaRecibida.prefijo);
      this.numero.setValue(this.facturaRecibida.numeroComprobante)
      this.tipoMovimiento.setValue(this.facturaRecibida.tipoMovimiento)
      this.obtenerComprobantePorPrefijoNumeroTipo();
    })
  }

  mostrarOcularSpinner(val) {
    if (val) {
      this.spinner.show('spinnerGrafico');
      this.cargando = true;
    } else {
      this.spinner.hide('spinnerGrafico');
      this.cargando = false;
    }
  }

  obtenerComprobantePorPrefijoNumeroTipo() {
    this.mensajeError = "";
    this.mostrarOcularSpinner(true);
    this.comprobanteService.obtenerComprobantePorPrefijoNumeroTipo(this.prefijo.value, this.numero.value, this.tipoMovimiento.value)
      .subscribe(result => {
        (this.facturaObtenida = result);
        if (result != null) {
          this.mensajeError = "";
          this.comprobarFecha();
          this.mostrarOcularSpinner(false);
        }
        else {
          this.facturaObtenida = null; //se limpia el comprobante.
          this.mensajeError = "No se encontraron comprobantes con busqueda seleccionada. " + this.prefijo.value + '-' + this.numero.value;
          this.mostrarOcularSpinner(false);
        }
      },
      err => {
        // Entra aquí si el servicio entrega un código http de error EJ: 404,
        //500
        this.facturaObtenida = null; //se limpia el comprobante.
        this.mensajeError = "No se encontraron comprobantes con busqueda seleccionada. " + this.prefijo.value + '-' + this.numero.value;
        this.mostrarOcularSpinner(false);
      });
  }

  facturaDebito(event) {
    this.facturaRecibida = event;
    this.prefijo.setValue(this.facturaRecibida.prefijo);
    this.numero.setValue(this.facturaRecibida.numeroComprobante);
    this.tipoMovimiento.setValue(this.facturaRecibida.tipoMovimiento);
    this.obtenerComprobantePorPrefijoNumeroTipo();
  }

  //validaciones

  admiteNotaDeCredito(): boolean {
    let es: boolean = false;
    if (this.facturaObtenida != null) {
      es = ((this.facturaObtenida.tipoMovimiento === 1 || this.facturaObtenida.tipoMovimiento === 6) && this.facturaObtenida.fechaAnulacion == null);
    }
    return es;
  }
  admiteNotaDeDebito(): boolean {
    let es: boolean = false;
    if (this.facturaObtenida != null) {
      es = ((this.facturaObtenida.tipoMovimiento === 1 || this.facturaObtenida.tipoMovimiento === 6) && this.facturaObtenida.fechaAnulacion == null);
    }
    return es;
  }

  reiniciarCampos() {
    this.comprobanteForm.reset();
    this.mensajeError = "";
    this.facturaObtenida = null;
    this.tipoMovimiento.setValue(1);
  }

  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  comprobarFecha() {
    let date = new Date();
    let fechaFactura = new Date(this.facturaObtenida.fechaHoraCreacion);
    date.setUTCDate(date.getDate() - 1);

    this.enFecha = fechaFactura > date;
    return this.enFecha;
  }

  //getters

  get prefijo() {
    return this.comprobanteForm.get('prefijoFormControl');
  }

  get numero() {
    return this.comprobanteForm.get('numeroFormControl');
  }

  get tipoMovimiento() {
    return this.comprobanteForm.get('tipoMovimientoFormControl');
  }
}
