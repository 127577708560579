<div class="modal-header bg-light">
  <h4 class="modal-title">Solicitud de Refacturación manual </h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()"></button>
</div>
<form [formGroup]="form">
  <div class="modal-body">
    <div class="row">
      <div class="mb-3 row col-md-12">
        <label class="col-sm-5 col-form-label" for="motivoFormControl">*Motivo:</label>
        <div class="col-sm-7">
          <select id="motivoFormControl" class="form-select" ngbTooltip="Seleccione un motivo."
            formControlName="motivoFormControl">
            <option *ngFor="let motivo of motivosParaCheckear;let i = index" [ngValue]="motivo">
              {{ motivo.nombre }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <br>

    <div class="row" *ngIf="this.seleccionoPlazo()===true">
      <div class="mb-3 row col-md-12">
        <label class="col-sm-5 col-form-label">*Plazo días:</label>
        <div class="col-sm-7">
          <input type="number" (keypress)="onlyNumberKey($event)" min="0" formControlName="plazoFormControl"
            class="form-control" maxlength="50">
        </div>
      </div>
    </div>
    <br *ngIf="this.seleccionoPlazo()===true">

    <div class="mb-3 col-10 offset-1 mt-2" style="border: 1px solid #ccc; padding: 1em;">
      <div class="mb-3 row">
        <div class="col-md-11" style="font-weight: bold;">Subir archivo adjunto:</div>
        <div class="col-md-1"></div>
      </div>
      <div class="mb-3 row">
        <div class="col-md-1"></div>
        <div class="col-md-10">
          <app-upload-file [url]="this.clientesBaseUrl" (archivoSubido)="onArchivoSubido($event)"></app-upload-file>
        </div>
        <div class="col-md-1"></div>
      </div>
    </div>
    <div class="row">
      <div class="mb-3 row col-md-12">
        <label for="comentariosFormControl" class="col-md-5 col-form-label">*Comentarios:</label>
        <div class="col-md-7">
          <textarea formControlName="comentariosFormControl" rows="4" cols="27">

          </textarea>
        </div>
      </div>
    </div>
  </div>
  <small class="text-danger"> *Campos obligatorios.</small>
  <div class="modal-footer bg-light">
    <div *ngIf="error != null" class="alert alert-danger">{{error}}</div>
    <button class="btn btn-primary me-2" id="aceptar" [disabled]="!this.puedeSolicitarRefactura() || this.guardando"
      (click)="this.onSubmit()">
      <span *ngIf="!guardando">Guardar</span>
      <div *ngIf="guardando" class="d-flex align-items-center">
        <div class="spinner-border text-light spinner-border-sm" role="status"></div>
        <span>Guardando...</span>
      </div>
    </button>
    <button type="button" class="btn btn-secondary" [disabled]="guardando"
      (click)="activeModal.dismiss('Close clicked')">Cancelar</button>
  </div>
</form>