<div *ngIf="titulo" class="modal-header bg-light">
  <h4 class="modal-title">{{titulo}}</h4>
</div>

<div class="modal-body">
  {{mensaje}}
</div>

<div class="modal-footer bg-light">
  <span class="border">
    <button type="button" class="btn btn-{{ danger? 'danger' : 'primary' }}" (click)="close()">{{textoSi}}</button>
  </span>
  <span class="border">
    <button type="button" class="btn btn-secondary" ngbAutofocus  (click)="dismiss()">{{textoNo}}</button>
  </span>
</div>