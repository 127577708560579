import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { UsuarioService } from 'src/app/account/usuario.service';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { Grupo } from 'src/app/shared/models/notificaciones/Grupo';
import { GrupoEncabezado } from 'src/app/shared/models/notificaciones/GrupoEncabezado';
import { NotificacionesService } from '../../notificaciones.service';
import { UpsertFormGrupoComponent } from '../upsert-form-grupo/upsert-form-grupo.component';

@Component({
  selector: 'app-grupos-lista',
  templateUrl: './grupos-lista.component.html',
  styleUrls: ['./grupos-lista.component.scss']
})
export class GruposListaComponent implements OnInit {

  TipoUsuario = TipoUsuario;

  listaGrupos: GrupoEncabezado[];
  listaGruposFiltrados: GrupoEncabezado[];
  grupoSeleccionado: GrupoEncabezado;

  buscadorFormControl = new UntypedFormControl();
  cargandoLista: boolean;

  constructor(  private modalService: NgbModal,
                private notificacionesService: NotificacionesService,
                private spinner: NgxSpinnerService,
                public authService: AuthService,
                private usuarioService: UsuarioService) { }

  ngOnInit(): void {
    this.cargarGrupos(); 
  }

  cargarGrupos(){
    this.mostrarSpinner();    

    this.notificacionesService.getGrupos()
      .subscribe(grupos => {
        this.listaGrupos = this.filtrarGruposPorRoles(grupos);
        this.listaGrupos = grupos;
        this.filtrarGruposPorTextoBusqueda();
        this.ocultarSpinner();
      },
      (err: ErrorModel) => {
        console.log(err);
        this.ocultarSpinner();
      });
  }

  mostrarSpinner() {
    this.spinner.show('spinnerGrafico');
    this.cargandoLista = true;
  }
  
  ocultarSpinner() {    
    this.spinner.hide('spinnerGrafico');
    this.cargandoLista = false;
  }

  filtrarGruposPorRoles(listaGruposObtenidos: GrupoEncabezado[]){
    return listaGruposObtenidos.filter(g => {
      g.roles.some( numberRoleGrupo => {
        return this.usuarioService.getRoles().includes(numberRoleGrupo);
      })
    });
  }

  filtrarGruposPorTextoBusqueda(){
    let textoBuscado = this.buscador.value;

    this.listaGruposFiltrados = this.listaGrupos.filter(g => {
      let agregar = true;
      if (textoBuscado && textoBuscado.length>2) {
        agregar = g.titulo.toLowerCase().indexOf(textoBuscado.toLowerCase()) > -1;
      }
      return agregar;
    });
  }

  crearNuevoGrupo(){
    const modalRef = this.modalService.open(UpsertFormGrupoComponent, { centered: true, size: 'lg', backdrop: "static" });
    modalRef.result.then((grupo) => {
      if(grupo){
        this.grupoSeleccionado = null;
        this.cargarGrupos();
      }
    });
  }

  usuarioSeleccionoGrupo(g: Grupo){
    this.grupoSeleccionado = g;
  }

  usuarioGuardoGrupo(){
    this.grupoSeleccionado = null;
    this.cargarGrupos();
  }



  get buscador() {
    return this.buscadorFormControl;
  }
}
