import { Component, Input, OnInit } from '@angular/core';
import { TipoComparativa } from 'src/app/shared/enums/TipoComparativa';
import { ExportExcelServiceService } from 'src/app/shared/exportar-excel/export-excel-service.service';
import { Balance7ComparativaEncabezadoDTO } from 'src/app/shared/models/cierreTurno/Balance7ComparativaEncabezadoDTO';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';
import { CierreTurnoService } from '../../cierre-turno.service';
import { Comparativa } from 'src/app/shared/models/cierreTurno/Comparativa';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComparativaComponent } from './modal-comparativa/modal-comparativa.component';
import { MessageService } from 'src/app/shell/message.service';

@Component({
  selector: 'app-comparativas',
  templateUrl: './comparativas.component.html',
  styleUrls: ['./comparativas.component.scss']
})
export class ComparativasComponent implements OnInit {

  @Input() comparativas:Balance7ComparativaEncabezadoDTO[];

  obteniendoDatos: boolean ;
  mapComparativasPorProducto = new Map();

  mapEntrys: any;
  arrayTotales: number[][];

  constructor(private messageService: MessageService,
    private excelService: ExportExcelServiceService, 
    private cierreTurnoService: CierreTurnoService,
    private spinner: NgxSpinnerService,
    private activeModal: NgbModal) { }

  ngOnInit(): void {  
    // Agrupar comparativas por product    

    for(var i=0; i < this.comparativas.length; i++){
      var comparativa = this.comparativas[i];
    
      if(this.mapComparativasPorProducto.has( this.armarKeyMovimiento(comparativa) )){
        var comparativasDelProducto : Balance7ComparativaEncabezadoDTO[] = this.mapComparativasPorProducto.get(this.armarKeyMovimiento(comparativa));
        comparativasDelProducto.push(comparativa);
        this.mapComparativasPorProducto.set(this.armarKeyMovimiento(comparativa), comparativasDelProducto);
      }else{
        var nuevoArray : Balance7ComparativaEncabezadoDTO[] = [comparativa];
        this.mapComparativasPorProducto.set(this.armarKeyMovimiento(comparativa), nuevoArray);
      }
    }
    
    this.mapEntrys = Array.from(this.mapComparativasPorProducto.entries());
    
    for(let i=0 ; i < this.mapEntrys.length; i++){
      this.mapEntrys[i][1] = this.mapEntrys[i][1].sort((a, b) => new Date(a.fechaHoraCierre).getTime() - new Date(b.fechaHoraCierre).getTime());
    }

    this.arrayTotales = [];

    for(let i=0 ; i < this.mapEntrys.length; i++){
      var primeraExistenciaAnterior: number;
      var sumatoriaEntradas: number = 0;
      var sumatoriaSalidas: number = 0;
      var ultimaExistenciaReal: number;
      var sumatoriaDeficitSuperhabit: number = 0;

      for(let j=0 ; j < this.mapEntrys[i][1].length; j++){
        if(j == 0){
          primeraExistenciaAnterior = this.mapEntrys[i][1][j].existenciaAnterior;
        }
        
        if(j == (this.mapEntrys[i][1].length -1) ){
          ultimaExistenciaReal = this.mapEntrys[i][1][j].existenciaReal;
        }
    
        sumatoriaEntradas += this.mapEntrys[i][1][j].entradas;
        sumatoriaSalidas += this.mapEntrys[i][1][j].salidas;
        sumatoriaDeficitSuperhabit += this.mapEntrys[i][1][j].diferencia;
      }

      this.arrayTotales[i] = [];
      this.arrayTotales[i][0] = Math.round(primeraExistenciaAnterior);
      this.arrayTotales[i][1] = Math.round(sumatoriaEntradas);
      this.arrayTotales[i][2] = Math.round(sumatoriaSalidas);
      this.arrayTotales[i][3] = Math.round(primeraExistenciaAnterior+sumatoriaEntradas-sumatoriaSalidas);
      this.arrayTotales[i][4] = Math.round(ultimaExistenciaReal);
      this.arrayTotales[i][5] = Math.round(sumatoriaDeficitSuperhabit);
    }
  }

  armarKeyMovimiento(c: Balance7ComparativaEncabezadoDTO){
    if(c.tipoComparativa == TipoComparativa.TanquesNaturales || c.tipoComparativa == TipoComparativa.Tanque15){
      return "(" + c.codigoProducto + ") " + c.nombreProducto.toUpperCase() + " " + this.getDescripcionTipoComparativa(c.tipoComparativa);
    }else if(c.tipoComparativa == TipoComparativa.Aeroespecialidades){
      return "(" + c.codigoProducto + ") " + c.nombreProducto.toUpperCase() + " - " + this.getDescripcionTipoComparativa(c.tipoComparativa);
    }else{
      return "TipoComparativa desconocido";
    }    
  }

  exportarComparativas(keyMovimiento: string, comparativas:Balance7ComparativaEncabezadoDTO[]) { 
    this.excelService.exportAsExcelFile(this.armarArrayExcel(keyMovimiento, comparativas), 'Mediciones '+ keyMovimiento);
  }
  /*
  armarArrayExcel(keyMovimiento: string) {
    let excelMovimiento: ExcelComparativa[] = [];

    this.comparativas = this.comparativas.sort((a, b) => a.codigoProducto.localeCompare(b.codigoProducto) || a.tipoComparativa - b.tipoComparativa  || a.fechaHoraCierre.localeCompare(b.fechaHoraCierre));

    var comparativasFiltradas = this.comparativas.filter(c => this.armarKeyMovimiento(c) == keyMovimiento);

    for(var i=0; i < comparativasFiltradas.length; i++){
      var item = new ExcelComparativa();
      item.comparativa = keyMovimiento;
      item.fechaHoraCierre = this.comparativas[i].fechaHoraCierre;
      item.existenciaAnterior = this.comparativas[i].existenciaAnterior;
      item.entradas = this.comparativas[i].entradas;
      item.salidas = this.comparativas[i].salidas;
      item.existenciaLibros = this.comparativas[i].existenciaLibros;
      item.existenciaReal = this.comparativas[i].existenciaReal;
      item.diferencia = this.comparativas[i].diferencia;      
      excelMovimiento.push(item);
    }
    return excelMovimiento;
  }
*/

armarArrayExcel(keyMovimiento: string, comparativas:Balance7ComparativaEncabezadoDTO[]) {
  let excelMovimiento: ExcelComparativa[] = [];

  this.comparativas = comparativas.sort((a, b) => a.codigoProducto.localeCompare(b.codigoProducto) || a.tipoComparativa - b.tipoComparativa  || a.fechaHoraCierre.localeCompare(b.fechaHoraCierre));

  //var comparativasFiltradas = this.comparativas.filter(c => this.armarKeyMovimiento(c) == keyMovimiento);

  for(var i=0; i < comparativas.length; i++){
    var item = new ExcelComparativa();
    item.comparativa = keyMovimiento;
    item.fechaHoraCierre = this.comparativas[i].fechaHoraCierre;
    item.existenciaAnterior = this.comparativas[i].existenciaAnterior;
    item.entradas = this.comparativas[i].entradas;
    item.salidas = this.comparativas[i].salidas;
    item.existenciaLibros = this.comparativas[i].existenciaLibros;
    item.existenciaReal = this.comparativas[i].existenciaReal;
    item.diferencia = this.comparativas[i].diferencia;      
    excelMovimiento.push(item);
  }
  return excelMovimiento;
}

  getDescripcionTipoComparativa(tipoComparativa: number){
    var descripcion: string;

    if(tipoComparativa == TipoComparativa.TanquesNaturales){
      descripcion = "natural";
    }else if(tipoComparativa == TipoComparativa.Tanque15){
      descripcion = "15º";
    }else if(tipoComparativa == TipoComparativa.Aeroespecialidades){
      descripcion = "Aeroespecialidades";
    }else{
      descripcion = "TipoComparativa desconocido";
    } 

    return descripcion.toUpperCase();
  }

  abrirComparativaEspecifica(comparativa: Balance7ComparativaEncabezadoDTO){
    this.mostrarLoading();
    
    this.cierreTurnoService.getComparativaDetalleBalance7(comparativa.cierreTurnoId, comparativa.codigoProducto, comparativa.tipoComparativa)
      .subscribe(result => {
        var comparativas: Comparativa[];
        comparativas = result;
        this.ocultarLoading();
        
        let modalRef = this.activeModal.open(ModalComparativaComponent, { centered: true, size: 'lg' });
        modalRef.componentInstance.comparativas = comparativas;
        modalRef.componentInstance.fechaCierre = comparativa.fechaHoraCierre;
        modalRef.componentInstance.titulo = this.armarKeyMovimiento(comparativa);
        modalRef.result.then(() => {
          
        });

      }, (err: ErrorModel) => {
        this.messageService.showErrorMessage('Hubo un error al obtener los datos. ' + err.description);
        this.ocultarLoading();
      });
  }

  
  mostrarLoading(){
    this.obteniendoDatos = true;
    this.spinner.show('spinnerGrafico');
  }

  ocultarLoading(){
    this.obteniendoDatos = false;
    this.spinner.hide('spinnerGrafico');
  }
}

export class ExcelComparativa {
  comparativa: string;
  fechaHoraCierre: string;
  existenciaAnterior: number;
  entradas: number;
  salidas: number;
  existenciaLibros: number;
  existenciaReal: number;
  diferencia: number;
}