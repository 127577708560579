import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, Input} from '@angular/core';
import { PedidoService } from '../pedido.service';
import { NgbDateStruct, NgbModal, NgbDatepickerConfig, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormControl, AbstractControl, UntypedFormBuilder, FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map} from 'rxjs/operators';
import { Producto } from '../../shared/models/despacho/Producto';
import { PedidoDetalle } from '../../shared/models/despacho/PedidoDetalle';
import { Validators } from '@angular/forms';
import { ClienteService } from 'src/app/cliente/cliente.service';
import { NuevoClienteComponent } from '../../shared/nuevo-cliente/nuevo-cliente.component';
import { ModalConfirmComponent } from '../../shared/generic-modals/modal-confirm.component';
import { MessageService } from 'src/app/shell/message.service';
import { TipoVuelo } from 'src/app/shared/enums/tipoVuelo';
import { AeropuertoService } from '../aeropuerto.service';
import { Aeropuerto } from 'src/app/shared/models/despacho/Aeropuerto';
import { RanchoService } from '../../rancho/rancho.service';
import { UsuarioService } from 'src/app/account/usuario.service';
import { Rancho } from 'src/app/shared/models/ranchos/Rancho';
import { Autorizacion } from 'src/app/shared/models/traders/Autorizacion';
import { Guid } from 'guid-typescript';
import { Precarga } from 'src/app/shared/models/despacho/Precarga';
import { TipoVehiculo } from 'src/app/shared/enums/tipoVehiculo';
import { CuentaCliente } from 'src/app/shared/models/despacho/CuentaCliente';
import { Cliente } from 'src/app/shared/models/despacho/Cliente';
import { isNullOrUndefined } from 'src/app/shared/compat-tools';
import { TipoPedido } from 'src/app/shared/enums/TipoPedido';
import { VehiculoService } from 'src/app/cliente/vehiculo.service';
import { VehiculoDTO } from 'src/app/shared/models/cliente/VehiculoDTO';
import { ModalVehiculoComponent } from 'src/app/management/matriculas/abm-matriculas/modal-vehiculo/modal-vehiculo.component';
import { ModalSeleccionMatriculasComponent } from '../modal-seleccion-matriculas/modal-seleccion-matriculas.component';
import { AutorizacionDTO } from 'src/app/shared/models/cliente/AutorizacionDTO';

export enum TipoSeleccion {
  Matriculas,
  Clientes 
}
@Component({
  selector: 'app-modificar-pedido',
  templateUrl: './modificar-pedido.component.html',
  styleUrls: ['./modificar-pedido.component.css']
})
export class ModificarPedidoComponent implements OnInit {

  @ViewChild('fechaHora', {static: true}) fechaHoraElement: ElementRef;
  @ViewChild('fechaHoraVuelo', {static: true}) fechaHoraVueloEl: ElementRef;
  @Output() cancelado = new EventEmitter();
  @Output() volver = new EventEmitter();
  @Input() get pedidoDetalleM() {
    return this.pedidoDetalleModificado
  }
  @Output() pedidoDetalleChange = new EventEmitter();
  set pedidoDetalleM(val) {
    this.pedidoDetalleModificado = val;
  }

  //visualizar desde tams y alijes
  @Input() visualizarPedido: boolean = false;
  //nº comprobante de alije
  @Input() numeroComprobante: string;

  error: string ;
  pedidoDetalleModificado: PedidoDetalle;
  model: NgbDateStruct;
  modelCliente: any;
  modelAero: any;
  modelDestino: any;
  time: NgbTimeStruct;
  modelVuelo: NgbDateStruct;
  timeVuelo: NgbTimeStruct;
  clientes: CuentaCliente[] = [];
  aerocombustibles: Producto[] = [];
  aeronaves: VehiculoDTO[] = [];
  aeronavesCliente: VehiculoDTO[];
  pedidoDetalle: PedidoDetalle;
  combustibleCargado: number;
  hayAerovales: boolean;

  cargandoClientes: boolean = true;
  guardandoPedido: boolean = false;
  cargandoAeronavesCliente: boolean;
  cargandoAerocombustibles: boolean = true;
  cargandoAeronaves: boolean = true;
  combustibleNoCoincide: boolean = false;
  aeropuertos: Aeropuerto[];
  cargandoAeropuertos: boolean;
  cargandoRancho: boolean = true;
  cargandoAutorizacion: boolean = false;
  rancho: Rancho;
  autorizacionTrader: Autorizacion | boolean = null;

  errorClientes: boolean = false;
  errorAeronaves: boolean = false;
  errorAerocombustibles: boolean = false;
  errorAeronavesCliente: boolean = false;
  errorDestinos: boolean = false;
  pedidosForm: UntypedFormGroup;
  precarga: Precarga;
  inicioForm: boolean = true;

  TipoPedido = TipoPedido;
  cargoModalSeleccion: boolean = false;
  isDisabled: boolean = false;
  TipoSeleccion = TipoSeleccion;

  constructor(private datepickerConfig: NgbDatepickerConfig,
    private pedidoService: PedidoService,
    private clienteService: ClienteService,    
    private vehiculosService: VehiculoService,
    private modalService: NgbModal,
    private messageService: MessageService,
    private aeropuertoService: AeropuertoService,
    private ranchoService: RanchoService,
    private usuarioService: UsuarioService,
    private fb: UntypedFormBuilder) { }

  ngOnInit() {

    this.inicioForm = true; // representa la primera vez que se inicia el form
    this.getClientes();
    this.pedidosForm = this.fb.group({
      id: Guid.EMPTY,

      clienteId: Guid.EMPTY,
      clienteRazonSocial: [Validators.required],

      aeronaveId: Guid.EMPTY,
      aeronaveMatricula: new FormControl({value: null, disabled: false}),
      aerocombustibleId: Guid.EMPTY,
      aerocombustible: new FormControl({value: null, disabled: true}, Validators.required),

      vuelo: [null, [
        Validators.required,
        Validators.minLength(3),
        Validators.pattern('(([A-Za-z]{2}|[A-Za-z]\\d|\\d[A-Za-z])([1-9]|\\s)(\\d{1,4})|(((\W|^)FFAA(\W|$))|((\W|^)GOB(\W|$))|((\W|^)PRIV(\W|$))|((\W|^)SANI(\W|$))|((\W|^)ffaa(\W|$))|((\W|^)gob(\W|$))|((\W|^)priv(\W|$))|((\W|^)sani(\W|$))|((\W|^)YPF(\W|$))|((\W|^)ypf(\W|$))))')
      ]],

      cantidadEstimada: [Validators.required],      
      fechaFormControl: new UntypedFormControl(null, [Validators.required]),
      horaFormControl: new UntypedFormControl(null, [Validators.required]),
      fechaVueloFormControl: new UntypedFormControl(null, [Validators.required]),
      horaVueloFormControl: new UntypedFormControl(null, [Validators.required]),

      posicion: null,
      estado: null,
      destinoId: Guid.EMPTY,
      cantidadFaltante: null,
      destinoNombre: null,
      numeroPedido: null,
      prefijo: null,
      codigoAerocombustible: null,
      tipoVueloFormControl: new UntypedFormControl(null),
      destinoFormControl: new UntypedFormControl(null)
    });
    // //fecha y hora del vuelo.
    var hora = new Date(this.pedidoDetalleModificado.fechaHoraVuelo);
    var fechavuelo = new Date(this.pedidoDetalleModificado.fechaHoraVuelo);
    this.modelVuelo = { day: fechavuelo.getUTCDate(), month: fechavuelo.getUTCMonth() + 1, year: fechavuelo.getFullYear() };
    this.timeVuelo = { hour: hora.getHours(), minute: hora.getMinutes(), second: hora.getSeconds() };
    // //fecha y hora de la carga.
    var fecha = new Date(this.pedidoDetalleModificado.fechaHoraEstimada);
    var horaActual = new Date(this.pedidoDetalleModificado.fechaHoraEstimada);
    this.model = { day: fecha.getUTCDate(), month: fecha.getUTCMonth() + 1, year: fecha.getFullYear() };
    this.time = { hour: horaActual.getHours(), minute: horaActual.getMinutes(), second: horaActual.getSeconds() };
    let fin: any = { day: this.model.day, month: this.model.month + 1, year: this.model.year };
    this.datepickerConfig.maxDate = fin;

    this.pedidosForm.patchValue(this.pedidoDetalleModificado);

    if (this.pedidoDetalleModificado.destino != null) {
      if (this.pedidoDetalleModificado.destino.cabotaje) {
        this.tipoVuelo.setValue(TipoVuelo.Cabotaje);
      } else {
        this.tipoVuelo.setValue(TipoVuelo.Internacional);
      }
    } else {
      this.tipoVuelo.setValue(TipoVuelo.Cabotaje);
    }

    this.pedidoDetalleModificado.tipoPedido==TipoPedido.Manual ? this.pedidosForm.controls.aeronaveMatricula.setValidators(Validators.required) : this.pedidosForm.controls.aeronaveMatricula.setValidators(null);
    this.pedidoDetalleModificado.tipoPedido==TipoPedido.Tams ? this.pedidosForm.controls.cantidadEstimada.setValidators([Validators.required, Validators.min(0)]) : this.pedidosForm.controls.cantidadEstimada.setValidators([Validators.required, Validators.min(1)]);
    this.pedidoDetalleModificado.tipoPedido==TipoPedido.Manual ? this.isDisabled = true : this.isDisabled = false;
    this.pedidoDetalleModificado.tipoPedido==TipoPedido.Manual ? this.pedidosForm.controls.aeronaveMatricula.disable() : this.pedidosForm.controls.aeronaveMatricula.enable();

    this.fechaInicial(this.time);
    this.fechaInicial(this.timeVuelo);

  }

  fechaInicial(timeStruct: NgbTimeStruct) {
    let fechaEstimada = this.dateFromDateTimeStruct(this.model, this.time);
    if (timeStruct) {
      let horaStruct: number = timeStruct.hour;
      let minutesStruct: number = timeStruct.minute;
      horaStruct = horaStruct == 0 ? 12 : (horaStruct > 12 ? horaStruct - 12 : horaStruct);
      this.fechaHoraElement.nativeElement.querySelector('[aria-label="Hours"]').value = horaStruct.toLocaleString(undefined, { minimumIntegerDigits: 2 });
      this.fechaHoraElement.nativeElement.querySelector('[aria-label="Minutes"]').value = minutesStruct.toLocaleString(undefined, { minimumIntegerDigits: 2 });
    }

    if (fechaEstimada == null || fechaEstimada.toString() == 'Invalid Date') {
      this.addValidationError(this.fecha, 'fechaValida');
    }
    else {
      this.fecha.updateValueAndValidity();
      if (fechaEstimada <= new Date()){
        this.fecha.updateValueAndValidity();
        this.validarFechaVuelo(fechaEstimada);
      }
    }
  }

  tieneAerovales(): boolean {
    if (this.pedidoDetalleModificado.precargas.length > 0) {
      for (let i = 0; i < this.pedidoDetalleModificado.precargas.length; i++) {
        if (this.pedidoDetalleModificado.precargas[i].aerovaleId != null) {
          this.hayAerovales = true;
          this.aeronave.clearValidators();
          this.cliente.clearValidators();
          this.aerocombustible.clearValidators();
          this.pedidosForm.updateValueAndValidity();
          //this.aerocombustible.disable();
          this.pedidosForm.get('aerocombustible').disable();
          return true;
        }
      }
    } else {
      return false;
    }
  }

  anular(e) {
    let tecla = (document.getElementById('aeronaveMatricula')) ? e.keyCode : e.which;
    return (tecla != 13);
  }

  goBack() {
    this.cancelado.emit();
  }

  CompletarPrefijo(prefijo: number) {
    let p = prefijo.toString()
    return p.padStart(3, '0')
  }
  CompletarNumero(numero: number) {
    let n = numero.toString()
    return n.padStart(5, '0')
  }


  //#region Validación de aeronave
  existeAeronave(control: AbstractControl): { [key: string]: any } | null {
    if (control.value == null || control.value.length === 0) {
      return null; // don't validate empty values to allow optional controls
    }
    const esVehiculo = control.value instanceof Object;
    return control.valid && esVehiculo ? null : { 'existeAeronave': { value: control.value } };
  }

  //#endregion

  //#region Validación de fechas

  /**
   * Valida la fecha del pedido y, si es correcta, la del vuelo
   */
  validarFecha(timeStruct: NgbTimeStruct) {
    if (!this.fecha.dirty && !this.hora.dirty)
      return;
    let fechaEstimada = this.dateFromDateTimeStruct(this.model, this.time);

    /** Esto está porque el timepicker si le ponés dos veces mal la hora (por ej. 57), se queda mal en el input */
    if (timeStruct) {
      let horaStruct: number = timeStruct.hour;
      let minutesStruct: number = timeStruct.minute;
      horaStruct = horaStruct == 0 ? 12 : (horaStruct > 12 ? horaStruct - 12 : horaStruct);
    }

    if (fechaEstimada == null || fechaEstimada.toString() == 'Invalid Date') {
      this.addValidationError(this.fecha, 'fechaValida');
    }
    else {
      this.fecha.updateValueAndValidity();
      if (fechaEstimada <= new Date()){
        this.fecha.updateValueAndValidity();
        this.validarFechaVuelo(fechaEstimada);
      }
    }
  }

  /**
   * Valida la fecha del vuelo
   * @param fechaEstimada Fecha estimada del pedido
   */
  validarFechaVuelo(fechaEstimada?: Date, timeStruct?: NgbTimeStruct) {
    if (!this.fecha.dirty && !this.hora.dirty && !this.fechaVuelo.dirty && !this.horaVuelo.dirty)
      return;
    if (timeStruct) {
      let horaStruct = timeStruct.hour;
      let minutesStruct = timeStruct.minute;
      horaStruct = horaStruct == 0 ? 12 : (horaStruct > 12 ? horaStruct - 12 : horaStruct);
      this.fechaHoraVueloEl.nativeElement.querySelector('[aria-label="Hours"]').value = horaStruct.toLocaleString(undefined, { minimumIntegerDigits: 2 });
      this.fechaHoraVueloEl.nativeElement.querySelector('[aria-label="Minutes"]').value = minutesStruct.toLocaleString(undefined, { minimumIntegerDigits: 2 });
    }
    this.fechaVuelo.updateValueAndValidity(); //sacar cualquier cosa
    let fechaVuelo = this.dateFromDateTimeStruct(this.modelVuelo, this.timeVuelo);
    if (fechaEstimada == null)
      fechaEstimada = this.dateFromDateTimeStruct(this.model, this.time);
    if (fechaEstimada == null || fechaEstimada.toString() == 'Invalid Date') {
      this.addValidationError(this.fecha, 'fechaValida');
    }
    if (fechaVuelo == null || fechaVuelo.toString() == 'Invalid Date') {
      this.addValidationError(this.fechaVuelo, 'fechaValida');
    }
    if (this.fechaVuelo.valid && this.fecha.valid) {

      if (fechaVuelo > fechaEstimada) {
        this.fechaVuelo.updateValueAndValidity();
      }
      else {
        this.addValidationError(this.fechaVuelo, 'fechaVuelo');
      }
    }
  }
  /**
   * Agrega un error de validación en el AbstractControl y lo marca como Dirty
   */
  addValidationError(control: AbstractControl, validationName: string) {
    let errors = control.errors;
    if (errors == null)
      errors = { [validationName]: true };
    else
      errors[validationName] = true;
    control.setErrors(errors);
    control.markAsDirty();
  }
  /**
   * Convierte una NgbDateStruct y una NgbTimeStruct en un Date
   */
  dateFromDateTimeStruct(date: NgbDateStruct, hour: NgbTimeStruct): Date {
    if (date == null || hour == null)
      return null;
    let dateReturn = new Date();
    dateReturn.setDate(date.day);
    dateReturn.setMonth(date.month - 1);
    dateReturn.setFullYear(date.year);
    dateReturn.setHours(hour.hour);
    dateReturn.setMinutes(hour.minute);
    return dateReturn;
  }

  //#endregion

  //#region Typeahead

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 || this.cargandoClientes ? [] :
        this.clientes.filter(v => this.containsString(this.cliente.value, v.ctA_SGC, v.razonSocial)).slice(0, 10))
    )

  /**
   * Determina si una cadena se encuentra dentro de otra
   * @param searchValue Valor a buscar
   * @param searchIn Valores sobre los que buscar searchValue
   */
  containsString(searchValue: string, ...searchIn: string[]): boolean {
    const concat = searchIn.toString();
    return concat.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
  }

  // revisar porque estaba numeroCuenta y no ctA_SGC, mapea Cliente y busca por CuentaCliente....??? 
  formatter = (x: { razonSocial: string, ctA_SGC: string }) => `${x.razonSocial} - ${x.ctA_SGC}`;

  searchAeronave = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 || this.cargandoAeronavesCliente || isNullOrUndefined(this.aeronavesCliente) ? []
        : this.aeronavesCliente.filter(v => v.matricula.toLowerCase().indexOf(this.aeronave.value.toLowerCase()) > -1).slice(0, 10))
    )

  formatterAeronave = (x: { matricula: string }) => x.matricula;

  searchAeropuerto = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 || this.cargandoAeropuertos ? []
        : this.aeropuertos.filter(v => v.cabotaje == (this.tipoVuelo.value === TipoVuelo.Cabotaje) &&
          (v.codigoAeropuerto + ' - ' + v.nombre + ' - ' + v.ciudadNombre).toLowerCase().indexOf(this.destino.value.toLowerCase()) > -1).slice(0, 20))
    )
  formatterAeropuerto = (x: { nombre: string, codigoAeropuerto: string }) => x.codigoAeropuerto + ' - ' + x.nombre;

  //#endregion


  formatDate(year, month, day, hour, minute): string {
    return year + '-' + month.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + '-' + day.toLocaleString(undefined, { minimumIntegerDigits: 2 })
      + 'T' + hour.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + minute.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':00';
  }

  //setea el aerocombustible de acuerdo a la aeronave seleccionada
  changeAeronave(): void {
    let buscaAeronave = this.aeronave.value;
    //Esto es por si ingresan el valor abc123 pero existe una aeronave ABC123, entonces elige esta última
    if (typeof (this.modelAeronave) === 'string') {
      buscaAeronave = this.aeronaves.find(p => p.matricula.toLowerCase().trim() == this.modelAeronave.toLowerCase().trim());
    }

    //Si no encuentro una aeronave, ofrecemos crear una
    if (!buscaAeronave) {
      this.nuevoAeronave(this.modelAeronave as string);
      //this.aerocombustible.enable();
      this.pedidosForm.get('aerocombustible').enable();
      //this.combustibleNoCoincide = false;
    }else {
      this.aeronave.setValue(buscaAeronave);
      /*this.combustibleNoCoincide = false;
      if (!this.aeronave.value.codigoProducto) {
        this.aerocombustible.enable();
        this.combustibleNoCoincide = false;
      }
      else {
        this.aerocombustible.disable();
        this.combustibleNoCoincide = true;
      }*/
      //Si no, me fijo si no es del cliente seleccionado, si está seguro de querer utilizarlo
      this.verificarAeronave();
    }
  }

  nuevoAeronave(aeronaveText: any): void {
    const modalRef = this.modalService.open(ModalVehiculoComponent, { backdrop: "static", centered: true });
    if (typeof (aeronaveText) === 'string') {
      modalRef.componentInstance.matriculaIngresada = aeronaveText.toUpperCase();
    }
    modalRef.componentInstance.tipoVehiculoParaFiltrar = TipoVehiculo.Aeronave;
    modalRef.componentInstance.vehiculos = this.aeronaves;    
    modalRef.result.then((vehiculo) => {
      if(vehiculo) this.messageService.showSuccessMessage("Vehiculo creado");
      this.aeronaves.push(vehiculo);
      this.aeronave.setValue(vehiculo);
    }); 
  }

  verificarAeronave() {
    if (this.aeronave.value) {
      if (this.cliente.value && this.cliente.value.id && !this.cliente.value.esTrader &&
        this.aeronavesCliente && this.aeronavesCliente.filter(value => value.codigoProducto === this.aeronave.value.codigoProducto).length == 0) {
        const modalRef = this.modalService.open(ModalConfirmComponent, { centered: true });
        modalRef.componentInstance.mensaje = "La aeronave no pertenece al cliente, ¿desea asignarla de todas formas?"; // should be the id
        modalRef.result.then(() => {
          this.aerocombustible.setValue(this.aerocombustibles.find(a => a.codigoProducto == this.aeronave.value.codigoProducto));
        },
          () => {
            this.aeronave.setValue(null);
            //this.aerocombustible.enable();
            this.pedidosForm.get('aerocombustible').enable();
          }
        );
      }
    }else{
      this.messageService.showWarningMessage("El pedido no posee una aeronave asignada", false);
    }
  }

  /*
  verificarAeronave() {
    if (this.aeronave.value) {
      if (this.cliente.value && this.cliente.value.id && !this.cliente.value.esTrader &&
        this.aeronavesCliente && this.aeronavesCliente.filter(value => value.aerocombustible === this.aeronave.value.codigoProducto).length == 0) {
        if (!this.inicioForm) // si no es la primera vez que inicio el form  muestro cartel
        {

          const modalRef = this.modalService.open(ModalConfirmComponent, { centered: true });

          modalRef.componentInstance.mensaje = "La aeronave no pertenece al cliente, ¿desea asignarla de todas formas?"; // should be the id
          modalRef.result.then(() => {

            this.aerocombustible.setValue(this.aerocombustibles.find(a => a.codigoProducto == this.aeronave.value.codigoProducto));
          },
            () => {

              this.aeronave.setValue(null);
              this.aerocombustible.enable();
            }
          );
        }
        else { //si es la primera vez que inició el form
          this.inicioForm = false;
        }
      }
      else {
        //Se puede sacar esta parte del if
        //this.aerocombustible.setValue(this.aerocombustibles.find(a => a.codigoProducto == this.aeronave.value.aerocombustible.codigoProducto));
        this.aerocombustible.setValue(this.aerocombustibles.find(a => a.codigoProducto == this.aeronave.value.codigoProducto));
      };
    }
  }*/

  controlVehiculoProducto() {
    if (this.aerocombustible.value instanceof Object && this.aeronave.value instanceof Object && !this.cargandoAerocombustibles && !this.cargandoAeronaves) {      
      if(this.aeronave.value.codigoProducto != this.aerocombustible.value.codigoProducto)
        this.combustibleNoCoincide = true;
      else 
        this.combustibleNoCoincide = false;
    }
  }  

  changeAerocombustible(e): void {
    this.getRancho();
    this.controlVehiculoProducto();
  }

  changeTipoVuelo(e: any): void {
    this.destino.reset();
  }

  selectCustomer(e: any): void {
    this.cliente.setValue(e.item);
    if (e.item.esTrader) {
      this.aeronavesCliente = [];
    } else {
      this.getAeronavesByCliente(e.item as unknown as CuentaCliente);      
    }
    this.getRancho();
  }

  selectAeropuerto(e: any): void {
    this.destino.setValue(e.item);
    this.mostrarRancho;
    this.getRancho();
  }
  selectAeronave(e: any): void {
    // #region Se agrega para EDGE ya que no funcionaba el (change) en el input de aeronave
    // cuando se seleccionaba una aeronave de la lista de predicciones
    const isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    if (isIEOrEdge) {
      this.aeronave.setValue(e.item);
      this.changeAeronave();
    }
    //#endregion
  }

  confirmCustomer(): void {
    setTimeout(() => {
      let buscaCliente = this.cliente.value;
      if (typeof (this.cliente.value) === 'string') {
        this.nuevoCliente(this.cliente.value);
      }
    });
  }

  nuevoCliente(clientText): void {
    const modalRef = this.modalService.open(NuevoClienteComponent);
    if (typeof (clientText) === 'string') {
      modalRef.componentInstance.nombreCliente = clientText; // should be the id
    }
    modalRef.result.then((cliente) => {
      this.messageService.showSuccessMessage("Cliente generado exitosamente")
      this.clientes.push(cliente);

      this.cliente.setValue(cliente);
    }).catch((error) => {

    });
  }

  cancelar() {
    this.cancelado.emit();
  }

  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : (event.charCode >= 48 && event.charCode <= 57);
  }

  //Llamadas al service

  onSubmit() {

    //** Esto es para que valide la fecha, por si no se "tocó" porque queda como válida */
    this.fechaVuelo.markAsDirty();
    this.fecha.markAsDirty();
    this.validarFecha(this.time);

    if (this.pedidosForm.valid) {
      this.guardandoPedido = true;
      this.pedidoDetalle = new PedidoDetalle();
      this.pedidoDetalle.id = this.pedidoDetalleModificado.id;
      this.pedidoDetalle.esPrioridad = this.pedidoDetalleModificado.esPrioridad;
      this.pedidoDetalle.codigoAeroplanta = this.usuarioService.getAeroplantaActual().codigoAeroplanta;

      this.pedidoDetalle.ctA_SGC = this.cliente.value.ctA_SGC
      //this.pedidoDetalle.cliente = this.cliente.value;
      if (this.aeronave.value) {
        this.pedidoDetalle.aeronaveId = this.aeronave.value.id;
        this.pedidoDetalle.aeronaveMatricula = this.aeronave.value.matricula;
      }       
      this.pedidoDetalle.codigoAerocombustible = this.aerocombustible.value.codigoProducto.toString();
      this.pedidoDetalle.vuelo = this.numeroVuelo.value.toUpperCase();;
      this.pedidoDetalle.cantidadEstimada = this.cantidadEstimada.value;      
      this.pedidoDetalle.fechaHoraEstimada = this.formatDate(
        this.fecha.value.year, this.fecha.value.month,
        this.fecha.value.day, this.hora.value.hour, this.hora.value.minute);
      this.pedidoDetalle.fechaHoraVuelo = this.formatDate(this.fechaVuelo.value.year, this.fechaVuelo.value.month,
        this.fechaVuelo.value.day, this.horaVuelo.value.hour, this.horaVuelo.value.minute);
      this.pedidoDetalle.posicion = this.posicion.value;
      if (this.destino.value && this.destino.value.id) {
        this.pedidoDetalle.destinoId = this.destino.value.id;
      }

      let ranchoCodigo = this.rancho ? this.rancho.codigoRancho : null;

      if (!this.hayAerovales) {
        this.pedidoService.ModificarPedido(this.pedidoDetalle)
          .subscribe(pedido => {
            if (pedido) {
              this.messageService.showSuccessMessage("El pedido se modificó correctamente.");
              this.pedidosForm.reset();
              this.guardandoPedido = false;
              this.cancelado.emit();
            }
            else {
              this.guardandoPedido = false;
              this.messageService.showErrorMessage("Se ha producido un error al generar el pedido");
            }
          });
      } else {
        this.pedidoService.ModificarPedidoConAerovales(this.pedidoDetalle, ranchoCodigo)
          .subscribe(pedido => {
            if (pedido) {
              this.messageService.showSuccessMessage("El pedido se modificó correctamente.");
              this.pedidosForm.reset();
              this.guardandoPedido = false;
              this.cancelado.emit();
            }
            else {
              this.guardandoPedido = false;
              this.messageService.showErrorMessage("Se ha producido un error al generar el pedido");
            }
          });
      }
    }
  }

  getClientes() {
    this.clienteService.getClientesActivos()
      .subscribe(clientes => {
        clientes = clientes.map(a => Object.assign(new CuentaCliente(), a));
        this.clientes = clientes;
        this.cargandoClientes = false;        
        //this.modelCliente = this.pedidoDetalleModificado.cliente;
        this.cliente.setValue(this.clientes.find(c=>c.ctA_SGC==this.pedidoDetalleModificado.cliente.numeroCuenta));
        this.getAeronaves();
        this.getAeropuertos();
      }, () => this.errorClientes = true);
  }

  clienteSeleccionado(cli: CuentaCliente) {
    if (cli !== null && cli !== undefined) {
      if (cli.esTrader) {
        this.aeronavesCliente = [];
      } else {
        this.getAeronavesByCliente((cli as CuentaCliente));
      }
      this.getRancho();
      //this.getAutorizacionTrader();
    }
  }

  seleccionClienteMatricula(tipo:TipoSeleccion) {
    this.cargoModalSeleccion = true;
    const modalRef = this.modalService.open(ModalSeleccionMatriculasComponent, { centered: true });
    modalRef.componentInstance.clientes = this.clientes;
    modalRef.componentInstance.aeronaves = this.aeronaves;
    modalRef.componentInstance.tipo = tipo;
    modalRef.result.then((autorizacion:AutorizacionDTO) => {
      if (autorizacion) {
        this.cliente.setValue(this.clientes.find(c=>c.ctA_SGC==autorizacion.ctA_SGC));
        this.aeronave.setValue(this.aeronaves.find(a=>a.matricula.toLowerCase().trim() == autorizacion.matricula.toLowerCase().trim()));
        this.aerocombustible.setValue(this.aerocombustibles.find(a=>a.codigoProducto==this.aeronave.value.codigoProducto));
      }      
    });
  }

  getAerocombustibles(): void {
    this.pedidoService.getAerocombustible()
      .subscribe(aerocombustibles => {
        this.aerocombustible.setValue(this.pedidoDetalleModificado.producto);
        this.cargandoAerocombustibles = false;
        this.aerocombustibles = aerocombustibles;
        this.aeronave.value != undefined ? this.changeAeronave() : null;
      }, () => this.errorAerocombustibles = true);
  }

  getAeronaves() {
    this.vehiculosService.getAeronaves()
      .subscribe( a=> {
        this.aeronaves = a;
        this.cargandoAeronaves = false;
        if(this.pedidoDetalleModificado.aeronave!=null) {
          this.modelAero = this.pedidoDetalleModificado.aeronave;
          this.aeronave.setValue(this.pedidoDetalleModificado.aeronave.matricula);
        }        
        this.getAerocombustibles();        
        //this.changeAeronave();
        //this.pedidoDetalleModificado.cliente
        if (this.cliente.value) {
          this.clienteSeleccionado(this.cliente.value);
        }        
      }, () => this.errorAeronaves = true)
  }

  getAeronavesByCliente(item: any) {
    this.cargandoAeronavesCliente = true;    
    this.vehiculosService.getCuentasVehiculos(item.tipoDocumento, item.numeroDocumento)
      .subscribe( resp => {
        this.aeronavesCliente = resp.filter( v => v.ctA_SGC == item.ctA_SGC)[0].vehiculos.filter( a => a.tipoVehiculo==TipoVehiculo.Aeronave);      
        if (typeof (this.aeronave.value) != 'string')
          this.verificarAeronave();
        this.cargandoAeronavesCliente = false;
      }, () => {
        this.errorAeronavesCliente = true;
        this.cargandoAeronavesCliente = false;
      });
  }

  getAeropuertos(): void {
    this.cargandoAeropuertos = true;
    this.aeropuertoService.getAeropuertos().subscribe(aeropuertos => {
      this.aeropuertos = aeropuertos;
      this.cargandoAeropuertos = false;
      this.modelDestino = this.pedidoDetalleModificado.destino;
      if (this.pedidoDetalleModificado.destino != null) {
        this.destino.setValue(this.pedidoDetalleModificado.destino);
      }
    }, () => this.errorDestinos = true)
  }

  getRancho(): void {
    if (this.mostrarRancho) {
      this.cargandoRancho = true;
      this.ranchoService.getRanchoPorCUIT(
        this.usuarioService.getAeroplantaActual().codigoAeroplanta,
        (this.destino.value as Aeropuerto).codigoPais,
        (this.aerocombustible.value as Producto).codigoProducto,
        (this.cliente.value as Cliente).numeroDocumento,
        this.formatDate(
          this.fechaVuelo.value.year, this.fechaVuelo.value.month,
          this.fechaVuelo.value.day, this.horaVuelo.value.hour, this.horaVuelo.value.minute)
      ).subscribe(result => {
        this.rancho = result;
        this.cargandoRancho = false;
      })
    }
    else {
      this.rancho = null;
    }
  }

  noEstaCargandoInfo() {
    return  this.cargandoAeropuertos==false && this.cargandoAeronavesCliente==false &&
            this.cargandoAeronaves==false && this.cargandoAerocombustibles==false && this.cargandoClientes==false
  }

  //#endregion

  //Getters

  get mostrarRancho() {
    return (this.cliente.value && this.aerocombustible.value
      && this.tipoVuelo.value == TipoVuelo.Internacional && this.destino.value instanceof Aeropuerto)
  }

  get modelAeronave() {
    return this.aeronave.value;
  }

  get cliente() {
    return this.pedidosForm.get('clienteRazonSocial');
  }

  get aeronave() {
    return this.pedidosForm.get('aeronaveMatricula');
  }

  get aerocombustible() {
    return this.pedidosForm.get('aerocombustible');
  }

  get numeroVuelo() {
    return this.pedidosForm.get('vuelo');
  }

  get cantidadEstimada() {
    return this.pedidosForm.get('cantidadEstimada');
  }

  get fecha() {
    return this.pedidosForm.get('fechaFormControl');
  }

  get hora() {
    return this.pedidosForm.get('horaFormControl');
  }

  get fechaVuelo() {
    return this.pedidosForm.get('fechaVueloFormControl');
  }

  get horaVuelo() {
    return this.pedidosForm.get('horaVueloFormControl');
  }

  get posicion() {
    return this.pedidosForm.get('posicion');
  }

  get tipoVuelo() {
    return this.pedidosForm.get('tipoVueloFormControl');
  }

  get destino() {
    return this.pedidosForm.get('destinoFormControl');
  }

  //#endregion
}

