import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { TipoSolapaVueloTams } from 'src/app/shared/enums/tipoSolapaVueloTams';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';

@Component({
  selector: 'app-vuelos-index',
  templateUrl: './vuelos-index.component.html',
  styleUrls: ['./vuelos-index.component.scss']
})
export class VuelosIndexComponent implements OnInit {

  TipoUsuario = TipoUsuario;
  TipoSolapaVueloTams = TipoSolapaVueloTams;

  constructor(public authService: AuthService) { }

  ngOnInit(): void {
  }

}
